import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const DkgPipelinesDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkglobaladmin/admintraining/commontraining/dkg-pipelines" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/dkg-pipelines') ? 'active' : ''}`}>Overview</Link></li>
                    <li><Link to="/dkglobaladmin/admintraining/commontraining/dkgpipelines/new-cv" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/dkgpipelines/new-cv') ? 'active' : ''}`}>New CV’s</Link></li>
                    <li><Link to="/dkglobaladmin/admintraining/commontraining/dkgpipelines/screening" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/dkgpipelines/screening') ? 'active' : ''}`}>Screening</Link></li>
                    <li><Link to="/dkglobaladmin/admintraining/commontraining/dkgpipelines/reminders" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/dkgpipelines/reminders') ? 'active' : ''}`}>Reminders</Link></li>
                    <li><Link to="/dkglobaladmin/admintraining/commontraining/dkgpipelines/recruiters" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/dkgpipelines/recruiters') ? 'active' : ''}`}>Recruiters</Link></li>
                    <li><Link to="/dkglobaladmin/admintraining/commontraining/dkgpipelines/account-m" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/dkgpipelines/account-m') ? 'active' : ''}`}>Account M</Link></li>
                    <li><Link to="/dkglobaladmin/admintraining/commontraining/dkgpipelines/job-offers" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/dkgpipelines/job-offers') ? 'active' : ''}`}>Job Offers</Link></li>
                    <li><Link to="/dkglobaladmin/admintraining/commontraining/dkgpipelines/escalated" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/dkgpipelines/escalated') ? 'active' : ''}`}>Escalated</Link></li>
                    <li><Link to="/dkglobaladmin/admintraining/commontraining/dkgpipelines/on-hold" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/dkgpipelines/on-hold') ? 'active' : ''}`}>On Hold</Link></li>
                    <li><Link to="/dkglobaladmin/admintraining/commontraining/dkgpipelines/closed" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/dkgpipelines/closed') ? 'active' : ''}`}>Closed</Link></li>
                </Route>
            </ul>
        </React.Fragment>
    )
}

export default DkgPipelinesDetailLeftPanel;

