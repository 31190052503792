import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData } from "../../services";

export const getteamMembses = createAsyncThunk(
  "TeamMembers/process/list",
  async (thunkAPI) => {
    const res = await GetData(true, "/teamsmembers");
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

const usersSlice = createSlice({
 name: "teamsMembers",
  initialState: {   
    teammemberlist: [],
    isSuccess: false,
    isUpdate: false,
    isLoading: false,
    isError: false
  },
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.isUpdate = false;
    },
  },
  extraReducers: {

     [getteamMembses.pending]: (state) => {
       state.isLoading = true;
      state.isError = false;
    },
    [getteamMembses.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.teammemberlist = payload.result;
    },
    [getteamMembses.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },
    
  },
});
export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;