import React from 'react';
import { Link } from "react-router-dom";

const SendoutsLeftPanel = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <>
            <div className="dk-sendoutsLeftpanel">
                <ul>
                    <li><Link to="/kpis/sendouts/summary" className={`${(segment3 === 'summary') ? 'active' : ''}`}>Summary <span className="dk-badgeNo">0</span></Link></li>
                    {/* <li><Link to="/kpis/sendouts/stats-report" className={`${(segment3 === 'stats-report') ? 'active' : ''}`}>Stats Report</Link></li>
                    <li><Link to="/kpis/sendouts/stats-details" className={`${(segment3 === 'stats-details') ? 'active' : ''}`}>Stats Details</Link></li> */}
                    <li><Link to="/kpis/sendouts/week-1" className={`${(segment3 === 'week-1') ? 'active' : ''}`}>Week 1 <span className="dk-badgeNo">0</span></Link></li>
                    <li><Link to="/kpis/sendouts/week-2" className={`${(segment3 === 'week-2') ? 'active' : ''}`}>Week 2 <span className="dk-badgeNo">0</span></Link></li>
                    <li><Link to="/kpis/sendouts/week-3" className={`${(segment3 === 'week-3') ? 'active' : ''}`}>Week 3 <span className="dk-badgeNo">0</span></Link></li>
                    <li><Link to="/kpis/sendouts/week-4" className={`${(segment3 === 'week-4') ? 'active' : ''}`}>Week 4 <span className="dk-badgeNo">0</span></Link></li>
                    {/* <li><Link to="/kpis/sendouts/case-details" className={`${(segment3 === 'case-details') ? 'active' : ''}`}>Case Details</Link></li> */}
                </ul>
            </div>
        </>
    )
}

export default SendoutsLeftPanel
