import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from '../../../../../ui/submitButton'
// import CheckboxDummy from './checkbox/CheckboxDummy'
import ProjectListModal from './ProjectList'
import { addProjectCompany, clearState, fetchCompanyProject, updateDataValue, fetchByProjectId } from "../../../../../../slice/companies/clients/clientSlice";
import { showSuccess, showError } from "../../../../../../slice/utils/message/messageSlice";
import $ from 'jquery'

const ProjectTab = ({ id, projectlist, defaultval }) => {
    const dispatch = useDispatch();
    const { isLoading, isfetch, companyProjectDetails, isError, message, isInsert } = useSelector(state => state.companies)

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [deValue, setDeValue] = useState(defaultval);

    const [details, setDetails] = useState('');

    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (!projectName) {
            dispatch(showError({ msg: 'Fields are required.' }))
        } else {
            if (!loading) {
                setSuccess(false);
                setLoading(true);
                dispatch(addProjectCompany({ companyId: id, name: projectName, description: '' }))
            }
        }
    }
    const handleChange = e => {
        const { name, value } = e.target
        setProjectName(value)
    }


    useEffect(() => {
        if (isError) {
            dispatch(clearState())
            dispatch(showError({ msg: message }))
            setLoading(false);
        }
        if (isInsert) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: message }))
            dispatch(fetchCompanyProject({ id: id }))
            setLoading(false);
            setSuccess(false);
            setProjectName('')
        }
        if (!isfetch) {
            dispatch(clearState())
            setDetails(companyProjectDetails.description)
        }

    }, [isError, isInsert, isfetch])

    // function isEmpty(obj) {
    //     for (var i in obj) {
    //         return false;
    //     }
    //     return true;
    // }
    const [activeId, setActiveId] = useState('active');
    const [pid, setPid] = useState(0)

    const selectList = (e) => {
        setDeValue("NotEmpty")
        setPid(e.target.dataset.pid);
        $('.activeTab').removeClass('activeTab')
        //const name = e.target.dataset.name;
        const id = e.target.dataset.id;
        if (e.target.className != e.target.dataset.id) {
            $('.' + id).removeClass('activeTab')
        } else {
            $('.' + id).addClass('activeTab')
        }
        dispatch(fetchByProjectId({ id: e.target.dataset.pid }))

    }
    const textAreaFun = e => {
        const { name, value } = e.target
        dispatch(updateDataValue({ id: pid, key: 'description', value: value }))
        setDetails(value)
    }
    return (
        <>
            <div className="dkg-projecttab-container">
                <div className="d-md-flex dkg-project-tab">
                    <div className="dkg-project-cols cols_2 first_cols">
                        <div className="dkg-project-header">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="title">Add New Project</div>
                            </div>
                        </div>
                        <div className="dkg-projecttab-Sec">
                            <ul className='dkg-saveproject-cols'>
                                <div className="dkg-savedprojectCon">
                                    <input type='text' className='form-control dkg-project-input' value={projectName} id="projects" name="project" onChange={handleChange} />
                                    <div className="dkg-saveproject-btnCon">
                                        <SubmitButton txt="ADD NEW" className="dkg-saved-project-btn" loading={loading} success={success} onClick={handleButtonClick} />
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="dkg-project-cols cols_2 second_cols">
                        <div className="dkg-project-header">
                            <div className="d-flex align-items-center justify-content-between">
                                <i className="far fa-edit" style={{ opacity: "0" }}></i>
                                <div className="title">List of Projects</div>
                                {/* <i className="fas fa-plus"></i> */}
                                {/* <CheckboxDummy /> */}
                                <ProjectListModal list={projectlist} id={id} />
                            </div>
                        </div>
                        <div className="dkg-projecttab-Sec">
                            <ul>
                                {
                                    projectlist.map((item, index) =>
                                        <li className={activeId + item.id} data-id={activeId + item.id} data-pid={item.id} data-name={item.name} onClick={selectList}>{item.name}</li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="dkg-project-cols cols_2 thrid_cols">
                        <div className="dkg-project-header">
                            <div className="d-flex align-items-center justify-content-between">
                                <i className="far fa-edit" style={{ opacity: "0" }}></i>
                                <div className="title">Project Description</div>
                                {/* <i className="fas fa-plus"></i> */}
                            </div>
                        </div>
                        <div className="dkg-projecttab-Sec">
                            <ul className="responbilityBullets test1">
                                <input type='text' className='form-control dkg-project-input dkg-input-project-desc' value={deValue == 'empty' ? "" : details} onChange={textAreaFun} />
                                {/* <textarea onChange={textAreaFun}>{details}</textarea> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectTab