import React from 'react';
import './casestatus.scss';

const CaseStatusDropDwn = ({ caseStatus, value }) => {
    let bgColor = "";
 
    switch (caseStatus) {
        
        case '1st Level':
            bgColor = '#715f8f'
            break;       
        case '2nd Level':
            bgColor = '#57496d'
            break;       

        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor }}>
                {
                    (value == "Blank") ? "" : value
                }
            </div>
        </>
    )
}

export default CaseStatusDropDwn;