import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const FaqTraining = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    const segment4 = pathname.split("/")[4];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalAdminTrainingMainMenu dkg-training-faqNavbar">
                <Route>
                    <ul>
                        <li><Link to="/trainingmodule/training-faq/dashboard" className={(segment3 == 'dashboard') ? 'active' : ''}>Dashboard</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'schedule') ? '' : ''}`}>Shedule</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'model-2') ? '' : ''}`}>Common</Link></li>
                        {/* <li><Link to="#" className={`${(segment3 == 'model-3') ? '' : ''}`}>Common</Link></li> */}
                        <li><Link to="#" className={`${(segment3 == 'status') ? '' : ''}`}>Recruitment</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'status') ? '' : ''}`}>Accounting</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'status') ? '' : ''}`}>Clients</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'status') ? '' : ''}`}>Advertisement</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'employees') ? '' : ''}`}>Employees</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'legal') ? '' : ''}`}>Legal</Link></li>

                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default FaqTraining;