import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Modal, Tab, Nav, Dropdown } from "react-bootstrap";
import "./comment.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  commentList,
  addComment,
  // commentStage,
  // commentFunSubject,
  commentDelete,
  commentDetails,
  updateComments,
  clearState,
} from "../../../slice/comments/commentSlice";
import { updateCommentCount } from "../../../slice/candidates/list/listSlice";
import { updateTaskCommentCount } from "../../../slice/task/taskSlice";
import { updatePaymentTaskCommentCount } from "../../../slice/task/paymentTaskSlice";
import { updateAssignCommentCount } from "../../../slice/kpis/assignKpisReportSlice";
import { updateQualifyCommentCount } from "../../../slice/kpis/qualifiedKpisReportSlice";
import { updateReportCommentCount } from "../../../slice/kpis/reportKpiReportSlice";
import { updateSendoutCommentCount } from "../../../slice/kpis/sendoutKpiReportSlice";
import { updateJobOfferCommentCount } from "../../../slice/kpis/joboffersKpiReportSlice";
import { updateRefusedCommentCount } from "../../../slice/kpis/refusedKpiReportSlice";
import { updateJoinedCommentCount } from "../../../slice/kpis/joinedKpiReportSlice";
import { updateCreditCommentCount } from "../../../slice/kpis/creditKpisReportSlice";
import { updateSalesBookCommentCount } from "../../../slice/accounting/businessBookSlice";
import { updateJobofferCommentCount } from "../../../slice/accounting/joboffer";
import { updateAccountJoinedCommentCount } from "../../../slice/accounting/joined";
import { updateIvoiceBookCommentCount } from "../../../slice/accounting/invoiceBooks";
import { updateAccountBankingCommentCount } from "../../../slice/accounting/accountBanking";
import { updateLiveJobsCommentCount } from "../../../slice/jobs/jobsSlice";
import CaseOther from "../../../components/pages/candidate/pipeline/dropdown/other";
import CaseStatus from "../../../components/pages/candidate/pipeline/dropdown/caseStatus";

import CaseStatusList from '../../ui/dropdownList/CaseStatusList';
import CaseProgressList from '../../ui/dropdownList/CaseProgressList';
import CaseStageList from '../../ui/dropdownList/CaseStageList';

import CommentLoader from "../../ui/commentLoader/";
import Delete from "../../ui/delete/";
import moment from "moment-timezone";
import { Markup } from "interweave";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Submit from "../../ui/submitButton";
//import draftToHtml from "draftjs-to-html";
//import EditorPopup from "./EditorPopup";
//import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus } from '@fortawesome/free-solid-svg-icons';
//import { Editor } from "react-draft-wysiwyg";
//export const useMountEffect = (fun) => useEffect(fun, []);
// const UseFocus = () => {
//   const htmlElRef = useRef(null);
//   const setFocus = () => {
//     htmlElRef.current && htmlElRef.current.focusEditor();
//   };
//   return [htmlElRef, setFocus];
// };

const CommentPopup = ({ refTable, refId, count, title, commentHandleClose, candidateStatus, candidateProgess, candidateStage }) => {
  const dispatch = useDispatch();
  const {
    isCommentLoading,
    isCommentInsert,
    comments,
    details,
    isSuccess,
    isUpdate,
    isInsert,
    isDelete,
    // isError,
    // message,
    //commentsubjectlist,
    //isSubject,
    //commentstage,

  } = useSelector((state) => state.comments);

  const { userInfo } = useSelector(state => state.auth);
  const [List, setList] = useState([]);
  //const [input1Ref, setInput1Focus] = UseFocus();
  //const [caseStage, setCaseStage] = useState([]);
  //const [commentSubject, setCommentSubject] = useState([]);
  //const [value, setValue] = useState("");
  const [show, setShow] = useState(true);
  const [statusValue, setStatusValue] = useState(candidateStatus)
  const [statusProgress, setStatusProgress] = useState(candidateProgess)
  const [stageValue, setStageValue] = useState(candidateStage)

  const [defaultsubject, setSubject] = useState('')
  const [parentId, setParentId] = useState("0");
  const [btnTxt, setBtnTxt] = useState("SAVE");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [rowId, setRowId] = useState(0);
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [commentCount, setCommentCount] = useState(0);
  const [actionType, setActionType] = useState('Add')

  const [dataId, setDataId] = useState(0);
  const [actinonBtnType, setActinonBtnType] = useState('list')
  //const [data, setData] = useState([]);
  //useMountEffect(setInput1Focus);

  let description =
    details.details !== undefined && details.details !== null
      ? details.details
      : "";
  let description_2 =
    details.details !== undefined && details.details !== null
      ? details.details
      : "";
  // const [editorState_2, setEditorState_2] = useState(EditorState.createEmpty());
  //const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const html_2 = description_2;
  const contentBlock_2 = htmlToDraft(html_2);

  const html = description;
  //const contentBlock = htmlToDraft(html);

  // const contentState_2 = ContentState.createFromBlockArray(
  //   contentBlock_2.contentBlocks
  // );
  // const contentState = ContentState.createFromBlockArray(
  //   contentBlock.contentBlocks
  // );

  // useEffect(() => {
  //   setEditorState_2(EditorState.createEmpty());
  //   if (description_2 !== "") {
  //     setEditorState_2(EditorState.createWithContent(contentState_2));
  //   }
  // }, [description_2]);

  // useEffect(() => {
  //   setEditorState(EditorState.createEmpty());
  //   if (description !== "") {
  //     setEditorState(EditorState.createWithContent(contentState));
  //   }
  // }, [description]);

  // const handleEditorChange = (state) => {
  //   setEditorState(state);
  //   convertContentToHTML();
  // };

  // const handleEditorChange_2 = (state) => {
  //   setEditorState_2(state);
  //   convertContentToHTML_2();
  // };
  // const convertContentToHTML_2 = () => {
  //   let currentContentAsHTML_2 = draftToHtml(
  //     convertToRaw(editorState_2.getCurrentContent())
  //   );
  //   setValue(currentContentAsHTML_2);
  // };

  // const convertContentToHTML = () => {
  //   let currentContentAsHTML = draftToHtml(
  //     convertToRaw(editorState.getCurrentContent())
  //   );
  //   setValue(currentContentAsHTML);
  // };

  const handleClose = () => {
    setDataId(0);
    //setShow(false);
    commentHandleClose()
  };

  const handleShow = () => {
    setDataId(0);
    setShow(true);
  };

  useEffect(() => {
    if (show) {
      setCommentCount(count);
      setDataId(refId);
      dispatch(commentList({ ref: refTable, refId }));
      //dispatch(commentStage({}));
    }
  }, [show, isDelete]);

  // useEffect(() => {
  //   if (isSubject) {
  //     setCommentSubject(commentsubjectlist)
  //   }
  // }, [isSubject])

  useEffect(() => {
    if (!isCommentLoading) {
      setList(comments);
      //setCaseStage(commentstage)
    }
  }, [isCommentLoading]);

  // const handleChange = (e) => {
  //   setValue(e.target.value);
  // };

  const [showText, setShowText] = useState(false);

  const [texAreaData, setTexAreaData] = useState('');

  const submitHandleSubmit = async () => {
    try {
      setLoading(true);
      if (rowId === 0) {
        await dispatch(
          addComment({ ref: refTable, refId, parentId, details: texAreaData, caseStatus: statusValue, caseProgress: statusProgress, caseStage: stageValue, subject: defaultsubject, date: moment().tz("Europe/Dublin").format("YYYY-MM-DD hh:mm:ss") })
        );

      } else {
        await dispatch(updateComments({ id: rowId, details: texAreaData, caseStatus: statusValue, caseProgress: statusProgress, caseStage: stageValue, subject: defaultsubject, date: moment().tz("Europe/Dublin").format("YYYY-MM-DD hh:mm:ss") }));
      }
    } catch (e) {
      console.log(e);
    }
  };

 
  useEffect(() => {
    if (isInsert) {
      dispatch(clearState());
      setBtnTxt("SAVE");
      setStageValue('')
      setStatusValue('')
      setStatusProgress('')
      setTexAreaData("")
      setSubject('')
      setShowText(false);
      setShowPopup(false);
      setLoading(false);
      //setEditorState(EditorState.createEmpty());
      //setValue("");
      setList(comments);
      //setEditorState("");
      // setEditorState_2("");

      if (parentId === "0" && dataId > 0) {
        var today = new Date();
        var date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        var time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        var dateTime = date + " " + time;

        if (refTable === "tasks") {
          dispatch(
            updateTaskCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "payment_tasks") {
          dispatch(
            updatePaymentTaskCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "assigned_kpi_reports") {
          dispatch(
            updateAssignCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "qualified_kpi_reports") {
          dispatch(
            updateQualifyCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "jobspec_kpi_reports") {
          dispatch(
            updateReportCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "sendouts_kpi_reports") {
          dispatch(
            updateSendoutCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "joboffers_kpi_reports") {
          dispatch(
            updateJobOfferCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "refused_kpi_reports") {
          dispatch(
            updateRefusedCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "joined_kpi_reports") {
          dispatch(
            updateJoinedCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "credit_kpi_reports") {
          dispatch(
            updateCreditCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        }
        else if (refTable === "accounting_sales_books") {
          dispatch(
            updateSalesBookCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        }
        else if (refTable === "account_joineds") {
          dispatch(
            updateAccountJoinedCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        }
        else if (refTable === "account_joboffers") {
          dispatch(
            updateJobofferCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        }
        else if (refTable === "account_interviews") {
          dispatch(
            updateSalesBookCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        }
        else if (refTable === "accounting_invoice_books") {
          dispatch(
            updateIvoiceBookCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "accounting_bankings") {
          dispatch(
            updateAccountBankingCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "livejobs") {
          dispatch(
            updateLiveJobsCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        } else {
          dispatch(
            updateCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        }
        setCommentCount(Number(commentCount) + 1);
      }
      // setDataId(0)
      setParentId("0");
    }
    if (isDelete) {
      dispatch(clearState());
      setLoading(false);
      setShowText(false);
      setShowPopup(false);
      setList(comments);
      if (parentId === "0" && dataId > 0) {
        if (refTable === "tasks") {
          dispatch(
            updateTaskCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
            })
          );
        } else if (refTable === "payment_tasks") {
          dispatch(
            updatePaymentTaskCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "assigned_kpi_reports") {
          dispatch(
            updateAssignCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "qualified_kpi_reports") {
          dispatch(
            updateQualifyCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "jobspec_kpi_reports") {
          dispatch(
            updateReportCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "sendouts_kpi_reports") {
          dispatch(
            updateSendoutCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "joboffers_kpi_reports") {
          dispatch(
            updateJobOfferCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "refused_kpi_reports") {
          dispatch(
            updateRefusedCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "joined_kpi_reports") {
          dispatch(
            updateJoinedCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "credit_kpi_reports") {
          dispatch(
            updateCreditCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        }
        else if (refTable === "accounting_sales_books") {
          dispatch(
            updateSalesBookCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        }
        else if (refTable === "account_joineds") {
          dispatch(
            updateAccountJoinedCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        }
        else if (refTable === "account_joboffers") {
          dispatch(
            updateJobofferCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        }
        else if (refTable === "account_interviews") {
          dispatch(
            updateSalesBookCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        }
        else if (refTable === "accounting_invoice_books") {
          dispatch(
            updateIvoiceBookCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "accounting_bankings") {
          dispatch(
            updateAccountBankingCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "livejobs") {
          dispatch(
            updateLiveJobsCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        } else {
          dispatch(
            updateCommentCount({ id: dataId, value: Number(commentCount) - 1 })
          );
        }
        // setDataId(0)
        setCommentCount(Number(commentCount) - 1);
      }
      setParentId("0");
      setRowId(0);
      setIsShowDelete(false);
    }
    if (isUpdate) {
      
      dispatch(clearState());
      setShowText(false);
      setShowPopup(false);
      setLoading(false);
      setParentId("0");
      setRowId(0);
      setActionType("Add")
      setBtnTxt("SAVE");
      //setEditorState("");
      //setEditorState_2("");
      setList(comments);
    }

  }, [isInsert, isDelete, isCommentInsert, isUpdate]);

  const deleteComment = (e) => {
    setRowId(e.target.dataset.id);
    setParentId(e.target.dataset.parentid);
    setIsShowDelete(true);
  };

  const handleCloseDelete = () => {
    setRowId(0);
    setIsShowDelete(false);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      dispatch(commentDelete({ id: rowId }));
    } catch (e) {
      console.log(e);
    }
  };

  // const [editTxtComments, setEditTxtComments] = useState(false);
  const [newCaseStatus, setNewCaseStatus] = useState('');
  const [newCaseProgress, setNewCaseProgress] = useState('');
  const [newCaseStage, setNewCaseStage] = useState('');
  const [createdDt, setCreatedAt] = useState('');

  const onItemClick = async (id, columnId, value) => {
    setNewCaseStatus(value)
    setNewCaseProgress('')
    setNewCaseStage('')
    setStatusValue(value)
  };
  const onItemProgressClick = async (id, columnId, value) => {
    setNewCaseProgress(value)
    setNewCaseStage('')
    setStatusProgress(value)
  };
  const onItemStageClick = async (id, columnId, value) => {
    setNewCaseStage(value)
    setStageValue(value)
  };

  const editComment = (e) => {
    setActionType("Edit")
    //setActinonBtnType("Edit")
    setNewCaseStatus(e.target.dataset.status)
    setNewCaseProgress(e.target.dataset.progress)
    setNewCaseStage(e.target.dataset.stage)
    setCreatedAt(e.target.dataset.created)
    setStatusValue(e.target.dataset.status)
    setStatusProgress(e.target.dataset.progress)
    setStageValue(e.target.dataset.stg)
    setSubject(e.target.dataset.sub)
    setRowId(e.target.dataset.id);
    setShowText(true)
    //setEditTxtComments(true)
    setTexAreaData(e.target.dataset.value)
    setCommentHeading("Update Comment");
    // setShowPopup(true);
    dispatch(commentDetails({ id: e.target.dataset.id }));
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      setParentId(details.parentId);
      //setValue(details.details);
      setBtnTxt("Update");
    }
  }, [isSuccess]);


  const replyComment = (e) => {
    setShowText(true)
    setCommentHeading("Reply Comment");
    //setShowPopup(true);
  };
  const [commentHeading, setCommentHeading] = useState("Add New Comment");
  //const [isCommentPopup, setIsCommentPopup] = useState(false);

  // const showAddComment = () => {
  //   setIsCommentPopup(true);
  // };
  // const handleToggle = () => {
  //   setIsCommentPopup(false);
  // };
  const [showPopup, setShowPopup] = useState(false);
  const handleClosePopup = () => {
    setShowPopup(false);
    setLoading(false);

  };
  // const handleShowPopup = () => {
  //   setShowPopup(true);
  //   setLoading(false);
  //   setValue("");
  //   setCommentHeading("Add New Comment");
  // };

  const onClick = async () => {
    setActionType("Add")
    //setActinonBtnType("Add")
    setBtnTxt("SAVE")
    setTexAreaData("")
    setStatusValue(candidateStatus)
    setStatusProgress(candidateProgess)
    setStageValue(candidateStage)

    // setEditorState(EditorState.createEmpty())
    if (showText === false) {
      setShowText(true);
      //setTexAreaData("")
    }
    else { setShowText(false) };
  };

  const cancleBtnFun = () => {
    setShowText(false)
    setActionType("Add")
    setTexAreaData("")
  }

  // const getSubjectfun = (e) => {
  //   const value = e.target.dataset.value;
  //   setSubject(value)
  // }
  // const updateStagecomment = async (e) => {
  //   const id = e.target.dataset.id;
  //   const key = e.target.dataset.key;
  //   const value = e.target.dataset.value;
  //   setStageValue(value)
  //   await dispatch(commentFunSubject({ id }))

  // }
  return (
    <>
      {isShowDelete ? (
        <Delete
          id={rowId}
          handleClose={handleCloseDelete}
          deleteItem={deleteItem}
          loading={loading}
          success={success}
        />
      ) : null}

      {/* {count === 0 ? (
        <span className="commentBoxCon zeroComment" onClick={handleShow}>
          <span className="numCon zeroCommentCount">
            {refTable === "livejobs" ? "" : count}
          </span>
        </span>
      ) : (
        <span className="commentBoxCon" onClick={handleShow}>
          <span className={refTable === "livejobs" ? "activenumCon" : "numCon"}>
            {count}
          </span>
        </span>
      )} */}

      <Modal
        className="dk-commentModalMain right"
        show={show}
        onHide={handleClose}

      >
        <Modal.Header closeButton>
          {
            (actionType === "Add") ? <Link to="#" className='addMonthBtn' onClick={onClick}>
              <i className="fas fa-plus"></i>
            </Link>
              : null
          }

          <Modal
            show={showPopup}
            onHide={handleClosePopup}
            centered
            className="dkg-comment-editorPopup-234"

          >
            <Modal.Header
              closeButton
              className="dkg-comment-editorPopup-Header"
            >
              <Modal.Title>{commentHeading}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="dkg-comment-editorPopup-Body">
            </Modal.Body>
          </Modal>

          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showText ?
            <>
              <div className="dkg-addnew-scrollBox">
                <ul className="dkg-addnew-comments-list">
                  <li>
                    <div className="dkg-addnew-comment-main-level">
                      <div className="dkg-addnew-comment-avatar">
                        <div className="tooltipMain">
                          <img alt={userInfo.firstName} src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + userInfo.profileImage} className="avatarImg" />
                          <div className="tooltipText"> {userInfo.firstName}</div>
                        </div>
                      </div>
                      <div className="dkg-addnew-comment-box">
                        <div className="dkg-addnew-comment-head d-flex align-items-center justify-content-between">
                          <span className="d-flex">
                            <div className="date"><i className="far fa-calendar-alt"></i>{moment().tz("Europe/Dublin").format("DD MMM YY")}</div>
                            <div className="date"><i className="far fa-clock"></i>{moment().tz("Europe/Dublin").format("hh:mm")}</div>
                          </span>
                          <div className="d-flex headIcons">
                            <i className="fas fa-reply" title="Reply" data-id="123682" style={{ cursor: "not-allowed" }}></i><i className="far fa-edit" title="Edit" data-id="123682" style={{ cursor: "not-allowed" }}></i><i className="far fa-trash-alt" title="Delete" data-parentid="0" data-id="123682" style={{ cursor: "not-allowed" }}></i>
                          </div>
                        </div>
                        {
                          (refTable === "candidates") ? <>

                            {
                              (actionType === "Add") ? <> {/*****Add comment***** */}
                                <div className="dkg-comment-sub-header">
                                  <div className="dkg-commsub-dropdwonCon">
                                    <div className="dkg-stage-dropdown">
                                      <div className="dkg-stage-dropdown-toogle" >
                                        <CaseStatus value={candidateStatus} />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="dkg-commsub-dropdwonCon">
                                    <div className="dkg-stage-dropdown">
                                      <div className="dkg-stage-dropdown-toogle">
                                        <CaseOther caseStatus={candidateStatus} value={candidateProgess} />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="dkg-commsub-dropdwonCon">
                                    <div className="dkg-stage-dropdown">
                                      <div className="dkg-stage-dropdown-toogle">
                                        <CaseOther caseStatus={candidateStatus} value={candidateStage} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </> : <> {/*****edit and reply***** */}

                                {
                                  (moment(createdDt).tz("Europe/Dublin").format(" YYYY") >= 2024) ? <>
                                    <div className="dkg-comment-sub-header editComments">
                                      <div className="dkg-commsub-dropdwonCon">
                                        <div className="dkg-stage-dropdown">
                                          <div className="dkg-stage-dropdown-toogle">
                                            <div className="dk-caseStatusDropCon">
                                              <Dropdown id={`caseStatus-1`}>
                                                <Dropdown.Toggle variant="" className="dk-caseStatusfliterbtn">
                                                  <CaseStatus value={newCaseStatus} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="caseStatusMenu">
                                                  <CaseStatusList id=''
                                                    columnId="caseStatus" onItemClick={onItemClick}
                                                  />
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="dkg-commsub-dropdwonCon">
                                        <div className="dkg-stage-dropdown">
                                          <div className="dkg-stage-dropdown-toogle">

                                            <div className="dk-caseStatusDropCon">
                                              <Dropdown id={`case_progress`}>
                                                <Dropdown.Toggle variant="" className="dk-caseStatusfliterbtn">
                                                  <CaseOther caseStatus={newCaseStatus} value={newCaseProgress} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="caseStatusMenu">
                                                  <CaseProgressList caseStatus={newCaseStatus} id='' columnId="case_progress" onItemClick={onItemProgressClick} />
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="dkg-commsub-dropdwonCon">
                                        <div className="dkg-stage-dropdown">
                                          <div className="dkg-stage-dropdown-toogle">
                                            {/* <CaseOther caseStatus={newCaseStatus} value={newCaseStage} /> */}
                                            <div className="dk-caseStatusDropCon">
                                              <Dropdown id={`case_stage`}>
                                                <Dropdown.Toggle variant="" className="dk-caseStatusfliterbtn">
                                                  <CaseOther caseStatus={newCaseStatus} value={newCaseStage} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="caseStatusMenu">
                                                  <CaseStageList caseStatus={newCaseStatus} id='' columnId="case_stage" onItemClick={onItemStageClick} />
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                    : null
                                }

                              </>
                            }
                          </>
                            : null
                        }

                        <div className="dkg-addnew-comment-content 1">
                          {/* for comment editor */}
                          <textarea rows="4" className="form-control w-100 dkg-addnew-comment-textArea" value={texAreaData} onChange={(e) => setTexAreaData(e.target.value)} />
                          {/* end for comment editor */}
                          {/* <Editor
                            editorState={editorState}
                            onEditorStateChange={handleEditorChange}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="dkg-submitbtnCon">
                      <Link to='#' onClick={() => cancleBtnFun()} className="dkg-cancel-btn">Cancel</Link>
                      {/* for comment editor */}
                      <Submit
                        txt={btnTxt}
                        loading={loading}
                        success={success}
                        onClick={submitHandleSubmit}
                        position="justify-content-center"
                        className="dk-sendBtn"
                      />
                      {/* end for comment editor */}
                      <div className="dk-commentSubmitBtn">
                        {/* <Submit
                          txt={btnTxt}
                          loading={loading}
                          success={success}
                          onClick={handleSubmit}
                          position="justify-content-center"
                          className="dk-sendBtn"
                        /> */}
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="dkg-pedefind-commentCon d-none">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="dkg-comment-one">
                    <div className="row dkg-pre-maincomment-Row">
                      <div className="col-md-3 col-12 dkg-pre-maincomment-sideMenu pr-0">
                        <Nav variant="pills" className="flex-column dkg-precomment-navpills">
                          <Nav.Item className="dkg-pre-maincomment-Navitem">
                            <Nav.Link eventKey="dkg-comment-four" className="dkg-pre-maincomment-Navlink">Sent Emai</Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="dkg-pre-maincomment-Navitem">
                            <Nav.Link eventKey="dkg-comment-five" className="dkg-pre-maincomment-Navlink">Sent C</Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="dkg-pre-maincomment-Navitem">
                            <Nav.Link eventKey="dkg-comment-six" className="dkg-pre-maincomment-Navlink">Project Fille</Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="dkg-pre-maincomment-Navitem">
                            <Nav.Link eventKey="dkg-comment-seven" className="dkg-pre-maincomment-Navlink">Sent Another Repl</Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="dkg-pre-maincomment-Navitem">
                            <Nav.Link eventKey="dkg-comment-eight" className="dkg-pre-maincomment-Navlink">DK GLobal Recruitment</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                      <div className="col-md-9 col-12 dkg-pre-maincomment-tabconCol pl-0">
                        <Tab.Content className="dkg-pre-comment-tabContent">
                          <Tab.Pane eventKey="dkg-comment-one" className="dkg-pre-comment-tabpane">

                          </Tab.Pane>
                          <Tab.Pane eventKey="dkg-comment-two" className="dkg-pre-comment-tabpane">

                          </Tab.Pane>
                          <Tab.Pane eventKey="dkg-comment-three" className="dkg-pre-comment-tabpane">

                          </Tab.Pane>
                          <Tab.Pane eventKey="dkg-comment-four" className="dkg-pre-comment-tabpane">

                          </Tab.Pane>
                          <Tab.Pane eventKey="dkg-comment-five" className="dkg-pre-comment-tabpane">

                          </Tab.Pane>
                          <Tab.Pane eventKey="dkg-comment-six" className="dkg-pre-comment-tabpane">

                          </Tab.Pane>
                          <Tab.Pane eventKey="dkg-comment-seven" className="dkg-pre-comment-tabpane">

                          </Tab.Pane>
                          <Tab.Pane eventKey="dkg-comment-eight" className="dkg-pre-comment-tabpane">

                          </Tab.Pane>
                          <Tab.Pane eventKey="dkg-comment-nine" className="dkg-pre-comment-tabpane">

                          </Tab.Pane>
                          <Tab.Pane eventKey="dkg-comment-ten" className="dkg-pre-comment-tabpane">

                          </Tab.Pane>
                          <div className="dkg-predefinedbtnCon">
                            <Link to='#' className="dkg-edit-btn dkg-edit-bg mr-2">Edit</Link>
                            <Link to='#' className="dkg-edit-btn dkg-save-bg mr-2 ml-2">Save</Link>
                            {/* <Submit
                              txt="Save"
                              position="justify-content-center"
                              className="dk-sendBtn"
                            /> */}
                            <Link to='#' className="dkg-use-btn dkg-use-bg ml-2">Use</Link>
                          </div>
                        </Tab.Content>
                      </div>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </>
            :
            <>

              <div className="comments-container 22">
                <div className="scrollBox">
                  <ul className="comments-list">
                    {isCommentLoading ? (
                      <CommentLoader />
                    ) : (
                      <React.Fragment>
                        {List.map((item, index) => (
                          <li>
                            <div className="comment-main-level">
                              <div className="comment-avatar">
                                <div className="tooltipMain">
                                  <img
                                    alt={
                                      item.userInfo.firstName +
                                      " " +
                                      item.userInfo.lastName
                                    }
                                    src={
                                      process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                      item.userInfo.profileImage
                                    }
                                    className="avatarImg"
                                  />
                                  <div className="tooltipText">
                                    {item.userInfo.firstName +
                                      " " +
                                      item.userInfo.lastName}
                                  </div>
                                </div>
                              </div>
                              <div className="comment-box">
                                <div className="comment-head dkg-comm-read-header d-flex align-items-center justify-content-between">
                                  <div className="dkg-comment-header-rightCon" style={{ height: "38px" }}>
                                    <span className="d-flex">
                                      <div className="date">
                                        <i className="far fa-calendar-alt"></i>

                                        {moment(item.updatedAt).tz("Europe/Dublin").format("DD MMM YY")}
                                      </div>
                                      <div className="date">
                                        <i className="far fa-clock"></i>
                                        {moment(item.updatedAt).tz("Europe/Dublin").format("h:mm")}
                                      </div>
                                    </span>
                                    {/* <div className="d-flex headIcons">
                                      <i
                                        className="fas fa-reply"
                                        title="Reply"
                                        data-id={item.id}
                                        onClick={(e) => {
                                          setParentId(e.target.dataset.id);
                                          setInput1Focus();
                                          replyComment(e.target.dataset.id);
                                        }}
                                      ></i>
                                      <i
                                        className="far fa-edit"
                                        title="Edit"
                                        data-id={item.id}
                                        data-value={item.details}
                                        data-stg={item.caseStage}
                                        data-sub={item.subject}
                                        onClick={editComment}
                                      ></i>
                                      <i
                                        className="far fa-trash-alt"
                                        title="Delete"
                                        data-parentid={item.parentId}
                                        data-id={item.id}
                                        onClick={deleteComment}
                                      ></i>
                                    </div> */}
                                    <div className='dkg-comment-readdropdwn-Con d-flex'>
                                      <div className="Date">
                                        <i className="fas fa-reply"
                                          title="Reply"
                                          data-id={item.id}
                                          onClick={(e) => {
                                            setParentId(e.target.dataset.id);
                                            //setInput1Focus();
                                            replyComment(e.target.dataset.id);
                                          }}
                                        ></i>
                                      </div>
                                      <div className="Date">
                                        <i
                                          className="far fa-edit"
                                          title="Edit"
                                          data-id={item.id}
                                          data-stg={item.caseStage}
                                          data-sub={item.subject}
                                          data-value={item.details}
                                          data-status={item.caseStatus}
                                          data-progress={item.caseProgress}
                                          data-stage={item.caseStage}
                                          data-created={item.createdAt}
                                          onClick={editComment}
                                        ></i>
                                      </div>
                                      <div className="Date">
                                        <i
                                          className="far fa-trash-alt"
                                          title="Delete"
                                          data-parentid={item.parentId}
                                          data-id={item.id}
                                          onClick={deleteComment}
                                        ></i>
                                      </div>
                                      <Dropdown className='dkg-comment-readdropdwn d-none'>
                                        <Dropdown.Toggle variant="" className='dkg-comment-readdropdwn-toggle'>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='dkg-comment-readdropdwn-menu'>
                                          <Dropdown.Item href="#" className='dkg-comment-readdropdwn-item'>
                                            <i className="fas fa-reply"
                                              title="Reply"
                                              data-id={item.id}
                                              onClick={(e) => {
                                                setParentId(e.target.dataset.id);
                                                //setInput1Focus();
                                                replyComment(e.target.dataset.id);
                                              }}
                                            ></i>
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#" className='dkg-comment-readdropdwn-item'>
                                            <i
                                              className="far fa-edit"
                                              title="Edit"
                                              data-id={item.id}
                                              data-stg={item.caseStage}
                                              data-sub={item.subject}
                                              data-value={item.details}
                                              data-status={item.caseStatus}
                                              data-progress={item.caseProgress}
                                              data-stage={item.caseStage}
                                              data-created={item.createdAt}
                                              onClick={editComment}
                                            ></i>
                                          </Dropdown.Item>
                                          <Dropdown.Item href="#" className='dkg-comment-readdropdwn-item'>
                                            <i
                                              className="far fa-trash-alt"
                                              title="Delete"
                                              data-parentid={item.parentId}
                                              data-id={item.id}
                                              onClick={deleteComment}
                                            ></i>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                                {
                                  (refTable === "candidates") ?
                                    <>
                                      {
                                        (moment(item.createdAt).tz("Europe/Dublin").format(" YYYY") >= 2024) ? <>
                                          <div className="dkg-comm-read-subheader">
                                            <div className="dkg-comm-read-subject"><CaseStatus value={item.caseStatus} /></div>
                                            <div className="dkg-comm-read-subject"><CaseOther caseStatus={item.caseStatus} value={item.caseProgress} /></div>
                                            <div className="dkg-comm-read-subject"><CaseOther caseStatus={item.caseStatus} value={item.caseStage} /></div>
                                          </div>
                                        </>
                                          :
                                          null
                                      }
                                    </>
                                    : null
                                }

                                <div className="comment-content 2">
                                  <Markup content={item.details} />
                                </div>
                              </div>
                            </div>
                            {item.children.length !== 0 ? (
                              <React.Fragment>
                                <ul className="comments-list-reply">
                                  {item.children.map((item, index) => (
                                    <li>
                                      <div className="comment-main-level">
                                        <div className="comment-avatar">
                                          <div className="tooltipMain">
                                            <img
                                              alt={
                                                item.userInfo.firstName +
                                                " " +
                                                item.userInfo.lastName
                                              }
                                              src={
                                                process.env
                                                  .REACT_APP_IMG_CLOUD_BASE_URL +
                                                item.userInfo.profileImage
                                              }
                                              className="avatarImg"
                                            />
                                            <div className="tooltipText">
                                              {item.userInfo.firstName +
                                                " " +
                                                item.userInfo.lastName}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="comment-box">
                                          <div className="comment-head d-flex align-items-center justify-content-between">
                                            <h6 className="comment-name d-none">
                                              <Link to="Maria Ciocas"></Link>
                                            </h6>
                                            <span className="d-flex">
                                              <div className="date">
                                                <i className="far fa-calendar-alt"></i>
                                                {moment(item.updatedAt)
                                                  .tz("Europe/Dublin")
                                                  .format("DD MMM YY")}
                                              </div>
                                              <div className="date">
                                                <i className="far fa-clock"></i>
                                                {moment(item.updatedAt)
                                                  .tz("Europe/Dublin")
                                                  .format("h:mm")}
                                              </div>
                                            </span>
                                            <div className="d-flex headIcons">
                                              <i
                                                className="far fa-edit"
                                                title="Edit"
                                                data-id={item.id}
                                                data-stg={item.caseStage}
                                                data-sub={item.subject}
                                                data-status={item.caseStatus}
                                                data-progress={item.caseProgress}
                                                data-stage={item.caseStage}
                                                data-created={item.createdAt}
                                                onClick={editComment}
                                              ></i>
                                              <i
                                                className="far fa-trash-alt"
                                                title="Delete"
                                                data-parentid={item.parentId}
                                                data-id={item.id}
                                                onClick={deleteComment}
                                              ></i>
                                            </div>
                                          </div>
                                          <div className="comment-content 3">
                                            <Markup content={item.details} />
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </React.Fragment>
                            ) : null}
                          </li>
                        ))}
                      </React.Fragment>
                    )}
                  </ul>
                </div>
                <div className="dk-commentReplyBox">
                  {/* <Editor
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />

              <div className="dk-commentSubmitBtn">
                <Submit
                  txt={btnTxt}
                  loading={loading}
                  success={success}
                  onClick={handleSubmit}
                  position="justify-content-center"
                  className="dk-sendBtn"
                />
              </div> */}
                </div>
              </div>
              {/* <div className="comments-container"> */}
              {/* <div className="scrollBox">
                  <ul className="comments-list">
                    {isCommentLoading ? (
                      <CommentLoader />
                    ) : (
                      <React.Fragment>
                        {List.map((item, index) => (
                          <li>
                            <div className="comment-main-level">
                              <div className="comment-avatar">
                                <div className="tooltipMain">
                                  <img
                                    alt={
                                      item.userInfo.firstName +
                                      " " +
                                      item.userInfo.lastName
                                    }
                                    src={
                                      process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                      item.userInfo.profileImage
                                    }
                                    className="avatarImg"
                                  />
                                  <div className="tooltipText">
                                    {item.userInfo.firstName +
                                      " " +
                                      item.userInfo.lastName}
                                  </div>
                                </div>
                              </div>
                              <div className="comment-box">
                                <div className="comment-head d-flex align-items-center justify-content-between">
                                  <h6 className="comment-name d-none">
                                    <Link to="Maria Ciocas"></Link>
                                  </h6>
                                  <span className="d-flex">
                                    <div className="date">
                                      <i className="far fa-calendar-alt"></i>
                                      {moment(item.updatedAt)
                                        .tz("Europe/Dublin")
                                        .format("DD MMM YY")}
                                    </div>
                                    <div className="date">
                                      <i className="far fa-clock"></i>
                                      {moment(item.updatedAt)
                                        .tz("Europe/Dublin")
                                        .format("h:mm")}
                                    </div>
                                  </span>
                                  <div className="d-flex headIcons">
                                    <i
                                      className="fas fa-reply"
                                      title="Reply"
                                      data-id={item.id}
                                      onClick={(e) => {
                                        setParentId(e.target.dataset.id);
                                        setInput1Focus();
                                        replyComment(e.target.dataset.id);
                                      }}
                                    ></i>
                                    <i
                                      className="far fa-edit"
                                      title="Edit"
                                      data-id={item.id}
                                      onClick={editComment}
                                    ></i>
                                    <i
                                      className="far fa-trash-alt"
                                      title="Delete"
                                      data-parentid={item.parentId}
                                      data-id={item.id}
                                      onClick={deleteComment}
                                    ></i>
                                  </div>
                                </div>
                                <div className="comment-content">
                                  <Markup content={item.details} />
                                </div>
                              </div>
                            </div>
                            {item.children.length !== 0 ? (
                              <React.Fragment>
                                <ul className="comments-list-reply">
                                  {item.children.map((item, index) => (
                                    <li>
                                      <div className="comment-main-level">
                                        <div className="comment-avatar">
                                          <div className="tooltipMain">
                                            <img
                                              alt={
                                                item.userInfo.firstName +
                                                " " +
                                                item.userInfo.lastName
                                              }
                                              src={
                                                process.env
                                                  .REACT_APP_IMG_CLOUD_BASE_URL +
                                                item.userInfo.profileImage
                                              }
                                              className="avatarImg"
                                            />
                                            <div className="tooltipText">
                                              {item.userInfo.firstName +
                                                " " +
                                                item.userInfo.lastName}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="comment-box">
                                          <div className="comment-head d-flex align-items-center justify-content-between">
                                            <h6 className="comment-name d-none">
                                              <Link to="Maria Ciocas"></Link>
                                            </h6>
                                            <span className="d-flex">
                                              <div className="date">
                                                <i className="far fa-calendar-alt"></i>
                                                {moment(item.updatedAt)
                                                  .tz("Europe/Dublin")
                                                  .format("DD MMM YY")}
                                              </div>
                                              <div className="date">
                                                <i className="far fa-clock"></i>
                                                {moment(item.updatedAt)
                                                  .tz("Europe/Dublin")
                                                  .format("h:mm")}
                                              </div>
                                            </span>
                                            <div className="d-flex headIcons">
                                              <i
                                                className="far fa-edit"
                                                title="Edit"
                                                data-id={item.id}
                                                onClick={editComment}
                                              ></i>
                                              <i
                                                className="far fa-trash-alt"
                                                title="Delete"
                                                data-parentid={item.parentId}
                                                data-id={item.id}
                                                onClick={deleteComment}
                                              ></i>
                                            </div>
                                          </div>
                                          <div className="comment-content">
                                            <Markup content={item.details} />
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </React.Fragment>
                            ) : null}
                          </li>
                        ))}
                      </React.Fragment>
                    )}
                  </ul>
                </div> */}
              {/* <div className="dk-commentReplyBox">
              <Editor
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />

              <div className="dk-commentSubmitBtn">
                <Submit
                  txt={btnTxt}
                  loading={loading}
                  success={success}
                  onClick={handleSubmit}
                  position="justify-content-center"
                  className="dk-sendBtn"
                />
              </div>
            </div> */}
              {/* </div> */}
            </>}


        </Modal.Body>
      </Modal>
    </>
  );
};

export default CommentPopup;
