module.exports = {
  SYSTEM_CONSTANTS: {
    SYSTEM_ID: {
      Id: "123456",
    },
    SYSTEM_EMAIL: {
      Email: "system@dkmasterbox.com",
    },
    awsS3: {
      Url: "https://master-upload.s3.amazonaws.com/",
    },
    DEFAULT_YEAR: {
      yearId: 292,
    },
    MAIL_BOX: {
      categoryId: 544,
    },
    DEFAULT_MONTHS: {
      monthsId: 94,
    },
    BUSINESS_BOOK_INTERVIEWS_STATUS: {
      caseStatusID: 333,
      caseProgressID: 334,
      CaseStageID: 335,

      JobOfferCaseStatusID: 336,
      JobOfferCaseProgressID: 337,
      JobOfferCaseStageID: 338,

      JoinedCaseStatusID: 339,
      JoinedCaseProgressID: 340,
      JoinedCaseStageID: 341,

      finalFeeCaseStatusId: 343,
      finalFeeCaseProgId: 344,
      finalFeeCaseStageId: 345,
      resionId: 346,

      qualifiedCaseStatusId: 348,
      qualifiedCaseProgId: 349,
      qualifiedCaseStageId: 350,
      qualifiedResionId: 351,

      caseProgressColor: "#316a67",
    },
    INVOICE_SALES_BOOK: {
      caseStatusID: 318,
      caseProgressID: 319,
      caseStageID: 320,
    },
    ACCOUNTING_BANKING: {
      categoryId: 325,
      serviceId: 326,
      businessNameId: 327,
      vatNoId: 328,
      plateformId: 329,
    },
    KPI_REPORT_ASSIGN: {
      monthsId: 94,
      progressId: 252,
      stageId: 253,
      comapnyId: 246,
      projectId: 247,
      roleType: 248,
      languageId: 250,
      cvSourceId: 251,
      categoryyId: 322,
    },
    KPI_REPORT_QUALIFIED: {
      progressId: 254,
      stageId: 255,
    },
    KPI_REPORT_JOBSPEC: {
      progressId: 256,
      stageId: 257,
    },
    KPI_REPORT_SENDOUT: {
      progressId: 231,
      stageId: 232,
    },
    KPI_REPORT_JOBOFFER: {
      progressId: 258,
      stageId: 259,
    },
    KPI_REPORT_REFUSED: {
      progressId: 260,
      stageId: 261,
    },
    KPI_REPORT_JOINED: {
      progressId: 262,
      stageId: 263,
    },
    KPI_REPORT_CREDIT: {
      progressId: 264,
      stageId: 265,
    },
    CANDIDATE_SCREANNING_FORM: {
      //second form
      JobDescription: {
        firstFilterId: 428,
        secondFilterId: 429,
        thirdFilterId: 430,
      },
      SalaryBenifit: {
        firstFilterId: 431,
        secondFilterId: 432,
        thirdFilterId: 433,
      },
      JobRequirnment: {
        firstFilterId: 434,
        secondFilterId: 435,
        thirdFilterId: 436,
      },
      ReloactionQuery: {
        firstFilterId: 437,
        secondFilterId: 438,
        thirdFilterId: 439,
      },
      ProjectStartDate: {
        firstFilterId: 440,
        secondFilterId: 441,
        thirdFilterId: 442,
      },
      JoiningDocument: {
        firstFilterId: 443,
        secondFilterId: 444,
        thirdFilterId: 445,
      },
      InterviewProcess: {
        firstFilterId: 446,
        secondFilterId: 447,
        thirdFilterId: 448,
      },
      FullResumePreview: {
        firstFilterId: 449,
        secondFilterId: 450,
        thirdFilterId: 451,
      },
      OtherJobInterview: {
        firstFilterId: 452,
        secondFilterId: 453,
        thirdFilterId: 454,
      },
      ContactPreference: {
        //10th block
        firstFilterId: 457,
        secondFilterId: 455,
        thirdFilterId: 456,
      },
      eleventh: {
        //11th block
        firstFilterId: 512,
        secondFilterId: 513,
        thirdFilterId: 514,
      },
      twelveth: {
        //12th block
        firstFilterId: 515,
        secondFilterId: 516,
        thirdFilterId: 517,
      },
      thirteen: {
        //13 block
        firstFilterId: 518,
        secondFilterId: 519,
        thirdFilterId: 520,
      },
      fourteen: {
        //14 block
        firstFilterId: 521,
        secondFilterId: 522,
        thirdFilterId: 523,
      },
      fifteen: {
        //15 block
        firstFilterId: 524,
        secondFilterId: 525,
        thirdFilterId: 526,
      },
      //end
      KeyQualification: {
        firstFilterId: 411,
        secondFilterId: 413,
        thirdFilterId: 470,
        fourFilterId: 412,
        fiveFilterId: 471,
      },
      KeyQualify: {
        firstFilterId: 272,
        secondFilterId: 414,
        thirdFilterId: 415,
      },
      Motivation: {
        firstFilterId: 503,
        secondFilterId: 496,
        thirdFilterId: 504,
      },
      screningForm: {
        firstDropdownId: 497,
        secondDropdownId: 498,
        thirdDropdownId: 499,
      },

      CvSource: {
        firstFilterId: 222,
      },

      fiveBlock: {
        firstDropdownId: 278,
        secondDropdownId: 277,
        thirdDropdownId: 279,
      },
      sixBlock: {
        firstDropdownId: 407,
        secondDropdownId: 408,
        thirdDropdownId: 409,
      },
      sevenBlock: {
        firstDropdownId: 353,
        secondDropdownId: 354,
        thirdDropdownId: 355,
        fourthDropdownId: 501,
      },
      eightBlock: {
        firstDropdownId: 459,
        secondDropdownId: 460,
        thirdDropdownId: 461,
      },
      /*****new block***** */
      ninethBlock: {
        firstDropdownId: 472,
        secondDropdownId: 473,
        thirdDropdownId: 474,
      },
      tenthBlock: {
        firstDropdownId: 475,
        secondDropdownId: 476,
        thirdDropdownId: 477,
      },
      eleventhBlock: {
        firstDropdownId: 478,
        secondDropdownId: 479,
        thirdDropdownId: 480,
      },
      twelvethBlock: {
        firstDropdownId: 481,
        secondDropdownId: 482,
        thirdDropdownId: 483,
        fourthDropdownId: 500,
      },
      thirteenBlock: {
        firstDropdownId: 488,
        secondDropdownId: 489,
        thirdDropdownId: 490,
      },
      fourteenBlock: {
        firstDropdownId: 485,
        secondDropdownId: 486,
        thirdDropdownId: 487,
      },
      fifteenBlock: {
        firstDropdownId: 527,
        secondDropdownId: 528,
        thirdDropdownId: 529,
        fourDropdownId: 493,
        fiveDropdownId: 491,
        sixDropdownId: 492,
      },
      /*********** */
    },
    DOC_CATEGORY: {
      ID: 551,
    },
    PAYMENT_TYPE: {
      ID: 163,
    },
    TASK_PAYMENT_MONTH: {
      ID: 165,
    },
    TASK_PAY_TYPE: {
      ID: 164,
    },
    COMMENT: {
      StageId: "534",
      //SubjectId: ''
    },
    CANDIDATES: {
      CaseStatusID: "7",
      ProfilesStatusID: "462",
      CaseProgressID: "8",
      CaseStageID: "9",

      CaseStatusColor: {
        NewCase: "#5c737d",
        Selected: "#26596d-11",
        Screening: "#715f8f",
        Processing: "#57496d",
        RAProcess: "#715f8f",
        Queries: "#26596d",
        Sendout: "#316a67",
        JobOffer: "#629bd3",
        Joined: "#4768b0",
        RefusedOffer: "#a84d4d",
        Blacklist: "#3C3939",
        Invoice: "#39485d",
        Closed: "#da3d5e",
        NotReachable: "#d58b5d",
        ClosedL1: "#da3d5e",
        ClosedL2: "#d0021b",
        QualifiedColor: "#57496d",
        DatabaseColor: "#26596d",
        SendoutColor: "#02a786",
        Credit: "#470000",
        jobJoining: "#4768b0",
        jobInvoicing: "#470000",
      },
      flagType: {
        Blank: "#d0cece",
        Grey: "#d3d3d3",
        Red: "#E2445B",
        Orange: "#D58B5D",
        Blue: "#2E75B6",
        Green: "#3A9469",
      },
      profileIndicatorType: {
        Blank: "#d3d3d3",
        Green: "green",
      },
      profileStatus: {
        Blank: "#d3d3d3",
        Green: "#048204",
        Orange: "#d58b5d",
        Blue: "#3a76b4",
        Red: "#d60101",
      },
      motivationStatus: {
        Blank: "#d3d3d3",
        Green: "#048204",
        Orange: "#d58b5d",
        Red: "#d60101",
      },

      CategoryID: "10",
      CVSourceID: "11",
      NationalityID: "12",
      VisaStatusID: "13",
      GenderID: "17",
      NativeID: "14",
      FluentID: "14",
      IntermediatID: "14",
      IndustryID: "19",
      careerLevelID: "16",
      CityID: "82",
      CountryID: "83",
      Education: "90",
      CasePriorit: "91",
      LatestJob: "89",
      Profile: {
        Skills: {
          TechSkills: {
            id: "15",
            box: "3",
          },
          JobProfileSkills: {
            id: "121",
            box: "3",
          },
        },
      },
    },
    RESOURCE: {
      CategoryId: "28",
      CoverageId: "29",
      PaidUnpaidId: "30",
      SourceTypeId: "31",
      StatusId: "32",
      LogingsCategoryId: "48",
      LogingsSourceTypeId: "49",
    },
    ADS: {
      JobBoardId: "52",
      CompanyId: "53",
      LanguageId: "54",
      RoleTypeId: "55",
    },
    CAMPAIGNS: {
      JobBoardId: "52",
      CompanyId: "53",
      LanguageId: "54",
      RoleTypeId: "55",
      CategoryId: "56",
      ProjectId: "57",
    },
    QUALIFY: {
      CATEGORY_ID_QUALIFY: "617005c27a79b5156417a9a2",
      FREQUENCY_LIST: "617007f87a79b5156417a9b6",
    },
    INDUSTRY: {
      ID: "61b0b08a1a49ae6e340f05be",
    },
    BUSINESS: {
      ID: "61b0b3421a49ae6e340f0605",
    },
    DEFAULT_USER: {
      ID: "617be3d5aaf3db31780da338",
    },
    Apps_Tracker: {
      JOBBOARD_LIST: {
        ELJ: "515",
        MLV: "519",
        HIPO: "520",
        DK_WEBSITE: "518",
        LINKEDIN: "521",
        INDEED: "517",
        EJOBS: "516",
        FACEBOOK: "522",
        DIRECT_EMAIL: "523",
        LEARN4GOOD: "524",
        EUROJOBS: "525",
        CAREERS4A: "526",
        JOBFIND: "527",
        BAKECA: "528",
        BESTJOBS: "529",
        PROFESIA_CZ: "530",
        PROFESIA_SK: "531",
        JOOBLE: "532",
        NET_EMPREGOS: "533",
        PRACUJ_PL: "534",
        PURE_CHAT: "535",
        CAREER_BUILDER: "536",
        MIN_JOB: "537",
        SOF_TEST: "538",
      },
      USERS_IN_TRACKER: [1, 2, 3, 4, 6, 7, 8],
    },
    JOBS: {
      JobId: "58",
      CategoryID: "322",
      CompId: "246",
      CompanyId: "532",
      ProjectId: "247",
      RoleTypeId: "248",
      LocalityId: "63",
      NoOfJobsId: "64",
      CountryId: "321",
      CityId: "323",
      LanguagesId: "250",
      currencyId: "395",
      JobProfileId: "67",
      WorkPermitId: "68",
      WorkPlaceId: "70",
      WorkDaysId: "71",
      ShiftsWorkId: "72",
      ContractTypeId: "73",
      SalaryTypeId: "74",
      AvailabilityId: "75",
      OutsideofEUId: "76",
      FlightTicketId: "77",
      TaxifromAirportId: "78",
      AccommodationtId: "79",
      RelocationBudgetId: "80",
      ExperiencetId: "139",
      IndustryId: "398",
      BusinessId: "397",
      TypeId: "322",
      TaxId: "87",
      VatId: "88",
      Level: "396",
      jobInterrviewId: "385",
      jobInterviewMethodId: "386",
      itSkillsId: "380",
      jobsSkillsId: "381",
      sendoutMethodId: "383",
      sendoutStepId: "384",
      jobStatusId: "356",
      RelocationId: "531",
      workTypeId: "530",
    },
    CANDIDATE_DOCS_TYPE: {
      ID: 114,
    },
    jobseekerAccount: {
      statusID: 546,
      stageID: 547,
      progressID: 548,
      situationID: 567,
    },
  },
};
