import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const DkGlobalSubmenu = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    const segment4 = pathname.split("/")[4];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/recruitment/dkglobal/dkg-team" className={(segment3 == 'dkg-team') ? 'active' : ''}>DKG Team</Link></li>
                        <li><Link to="/recruitment/dkglobal/structure" className={`${(segment3 == 'structure') ? 'active' : ''}`}>Structure</Link></li>
                        <li><Link to="/recruitment/dkglobal/process" className={`${(segment3 == 'process') ? 'active' : ''}`}>Process</Link></li>
                        <li><Link to="/recruitment/dkglobal/pipelines" className={`${(segment3 == 'pipelines') ? 'active' : ''}`}>Pipelines</Link></li>
                        <li><Link to="/recruitment/dkglobal/team_1" className={`${(segment3 == 'team_1') ? 'active' : ''}`}>Team 1</Link></li>
                        <li><Link to="/recruitment/dkglobal/team_2" className={`${(segment3 == 'team_2') ? 'active' : ''}`}>Team 2</Link></li>
                        <li><Link to="/recruitment/dkglobal/team_3" className={`${(segment3 == 'team_3') ? 'active' : ''}`}>Team 3</Link></li>
                        <li><Link to="/recruitment/dkglobal/team_4" className={`${(segment3 == 'team_4') ? 'active' : ''}`}>Team 4</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default DkGlobalSubmenu;
