import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import CategoryDropdown from '../../dropdown/CategoryDropdown';
import CoverageDropdown from '../../dropdown/CoverageDropdown';
import PayementDropdown from '../../dropdown/PayementDropdown';
import SourceTypeDropdown from '../../dropdown/SourceTypeDropdown';
import StatusDropdown from '../../dropdown/StatusDropdown';
import Submit from "../../../../ui/submitButton";
import UserList from "../../../../ui/userList";
import Dropdown from 'react-bootstrap/Dropdown';
import { addItResource, clearState } from "../../../../../slice/resources/ItResourcesSlice";
import { loadingStart, clearLoading } from "../../../../../slice/utils/loader/linearSlice";
import './addnew.scss'

const AddNew = ({ hidePopup }) => {
    const dispatch = useDispatch();
    const { resourceCategoryList, resourceCoverageList, resourcePaidUnpaidList, resourceSourceTypeList, resourceStatusList } = useSelector(state => state.common)
    const { isSuccess, isInsert, msg, isError } = useSelector(state => state.itResource)

    const [category, setCategory] = useState('');
    const [coverage, setCoverage] = useState('');
    const [paidUnpaid, setPaidUnpaid] = useState('');
    const [sourceType, setSourceType] = useState('');
    const [status, setStatus] = useState('');
    const [sourceName, setSourceName] = useState('');
    const [location, setLocation] = useState('');

    const [userImgName, setUserImgName] = useState('')
    const [userId, setUserId] = useState(0)
    const [userImg, setUserImg] = useState('')
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setUserId(0)
    }
    const handleShow = () => setShow(true);

    const selectUser = (e) => {
        setUserImgName(e.target.alt)
        setUserImg(e.target.src)
        setUserId(e.target.dataset.id)
    }

    const handleChangeSelect = (event, data) => {
        const { name, value } = data
        switch (name) {
            case "status":
                setStatus(value)
                break;
            case "category":
                setCategory(value)
                break;
            case "sourceType":
                setSourceType(value)
                break;
            case "paidUnpaid":
                setPaidUnpaid(value)
                break;
            case "coverage":
                setCoverage(value)
        }
    }
    useEffect(() => {
        if (isError) {
            setUserId('0')
            dispatch(showError({ msg: msg }))
            setShow(false);
            dispatch(clearState())
            dispatch(clearLoading())
        }
        if (isInsert) {
            setUserId('0')
            setShow(false);
            setLoading(false);
            setSuccess(true);
            setSourceName('')
            setLocation('')
            dispatch(showSuccess({ msg: msg }))
            dispatch(clearState())
            dispatch(clearLoading())
        }
    }, [isError, isInsert])

    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (!sourceName || !location || !category || userId === 0 || !coverage || !paidUnpaid || !sourceType || !status) {
            dispatch(showError({ msg: 'All fields required' }))
        } else {
            setSuccess(false);
            setLoading(true);
            dispatch(addItResource({ uid: userId, category, coverage, isPaid: paidUnpaid, source: sourceType, status, name: sourceName, location }))
        }
    }

    return (
        <>
            <Link to="#" onClick={handleShow}><i className="fas fa-plus"></i> Add New</Link>
            <Modal
                show={show} onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                className="dk-addnewResModal"
                centered
            >
                <Modal.Header closeButton className="dk-addnewResModalHder">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add New Resource
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-addnewResModalBdy">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="form-group">
                                <label className="dk-mlmangerLabel">Manager</label>
                                <div className="useFilterCon">
                                    <input type="hidden" id="userId" value={userId} />
                                    <Dropdown className="dk-resuserfilter-dropdown">
                                        <Dropdown.Toggle variant="" id="dropdown-basic" className="dk-resuser-dropdown-toggle">
                                            {
                                                (userId !== 0) ? <img src={userImg} className="select-user-img" /> : <i className="fas fa-user"></i>
                                            }
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dk-resuserfliter-DropMenu">
                                            <div className="d-flex">
                                                <UserList onClick={selectUser} />
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Status</label>
                                <div className="dk-resDropDown">
                                    <StatusDropdown list={resourceStatusList} type="status" placeholder="Select" onChange={handleChangeSelect} name='status' />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Name of Source</label>
                                <div className="dk-resDropDown">
                                    <input type="text" className='form-control' value={sourceName} onChange={(e) => setSourceName(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Category</label>
                                <div className="dk-resDropDown">
                                    <CategoryDropdown list={resourceCategoryList} type="category" placeholder="Select" onChange={handleChangeSelect} name='category' />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Source Type</label>
                                <div className="dk-resDropDown">
                                    <SourceTypeDropdown list={resourceSourceTypeList} type="sourceType" placeholder="Select" onChange={handleChangeSelect} name='sourceType' />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Paid/Unpaid</label>
                                <div className="dk-resDropDown">
                                    <PayementDropdown list={resourcePaidUnpaidList} type="paidUnpaid" placeholder="Select" onChange={handleChangeSelect} name='paidUnpaid' />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Coverage</label>
                                <div className="dk-resDropDown">
                                    <CoverageDropdown list={resourceCoverageList} type="coverage" placeholder="Select" onChange={handleChangeSelect} name='coverage' />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Location</label>
                                <div className="dk-resDropDown">
                                    <input type="text" className='form-control' value={location} onChange={(e) => setLocation(e.target.value)} />
                                </div>
                            </div>
                            <div className="dk-sbmitbtnCon">
                                {/* <Link to="#" className="dk-sbmitbtn btn">Save</Link> */}
                                <Submit className="dk-sbmitbtn btn" txt="Save" loading={loading} success={success} onClick={handleButtonClick} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddNew
