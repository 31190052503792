import React from 'react';
import MarianaTwoProjectEightLeftPanel from "../leftpanel/marianatwo/MarianaTwoProjectEightLeftPanel";

const MarianaTwoProjectEight = () => {
    return (
        <>
            <div className="dk-moduleProjects-main">
                <div className="dk-moduleTraining-cols">
                    <MarianaTwoProjectEightLeftPanel />
                    <div className="dk-moduleTraining-rightPanel">
                        <div className="title">Summary</div>
                        <div className="dk-moduleTraining-rightCols">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MarianaTwoProjectEight;
