import React from "react";

const LocationTab = () => {
  return (
    <>
      <div className="dkg-jopspec-pageheader">
        <div className="dkg-jopspec-page-title">Location</div>
      </div>
    </>
  );
};

export default LocationTab;
