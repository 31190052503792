import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import { getSubCategories, getSubCatDetails, clearState } from '../../../../../../../slice/relocations'
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import $ from 'jquery'

const Step1 = (props) => {

    const dispatch = useDispatch();
    const { isLoading, subCategoryReqList, subCategoryDetails, isDetails } = useSelector(state => state.clientRelocation);

    const [data, setData] = useState([]);
    const [tabName, setTabName] = useState('');
    const [showDetailsTab, setShowDetailsTab] = useState(true);
    const [defaultTitles, setDefaultTitles] = useState('Overview');
    const [overviewData_demands, setOverviewData_demands] = useState('');

    useEffect(() => {
        if (!isLoading && subCategoryReqList && subCategoryReqList.length > 0) {
            setData(subCategoryReqList)
            setOverviewData_demands(subCategoryReqList[0].description)
        }
        else {
            setData([])
            setOverviewData_demands('')
        }

    }, [isLoading])

    useEffect(() => {

        if (isDetails) {
            setOverviewData_demands(subCategoryDetails.description)
        }
    }, [isDetails])


    const changeTabName = async (tab) => {
        setTabName(tab)
        switch (tab) {
            case 'details':
                $('.tab_1').addClass('activeSubTab')
                $('.tab_2').removeClass('activeSubTab')
                $('.tab_3').removeClass('activeSubTab')
                break;
            case 'audio':
                $('.tab_2').addClass('activeSubTab')
                $('.tab_1').removeClass('activeSubTab')
                $('.tab_3').removeClass('activeSubTab')
                break;
            case 'video':
                $('.tab_3').addClass('activeSubTab')
                $('.tab_2').removeClass('activeSubTab')
                $('.tab_1').removeClass('activeSubTab')
                break;
            // default:
            //     setActiveSubCat('activeSubTab')
            //     break;
        }

        setShowDetailsTab(false)
        $('.addnewClass').addClass('activesSubTab')
        dispatch(getSubCategories({ categoryId: props.catIdds, keys: tab }))
    }

    const subCategoryFunction = async (id, catId, data) => {
        if (defaultTitles != "Overview") {
            $('.dk-tabs-right-navlink-13').removeClass('addActiveClass')
            $('.xyz' + id).addClass('addActiveClass')
        }
        setShowDetailsTab(true)
        setOverviewData_demands("")
        dispatch(getSubCatDetails({ id }))
    }

    let overviewDetails_demands = overviewData_demands !== undefined && overviewData_demands !== null ? overviewData_demands : "";
    const html_demands = overviewDetails_demands;
    const contentBlock_demands = htmlToDraft(html_demands);
    const contentState_demands = ContentState.createFromBlockArray(
        contentBlock_demands.contentBlocks
    );
    const [editorOverviewState_demands, setEditorState_demands] = useState(EditorState.createEmpty());
    useEffect(() => {
        setEditorState_demands(EditorState.createEmpty());
        if (overviewDetails_demands !== "") {
            setEditorState_demands(EditorState.createWithContent(contentState_demands));
        }
    }, [overviewDetails_demands]);


    useEffect(() => {
        setDefaultTitles(props.pagetitle)
        //setEditorState_demands(EditorState.createEmpty())
        if (props.pagetitle != "Overview") {
            $('.tab_1').addClass('activeSubTab')
            $('.tab_2').removeClass('activeSubTab')
            $('.tab_3').removeClass('activeSubTab')
            setShowDetailsTab(false)
            $('.addnewClass').addClass('activesSubTab')
            dispatch(getSubCategories({ categoryId: props.catIdds, keys: 'details' }))
        }

    }, [props.pagetitle])

    return (
        <>
            <div className='dkg-payment-qt-rightpanel'>
                <div className="dkg-tabs-rightPanel-12">
                    <div className="title">{defaultTitles}</div>
                    <div className="dk-tabs-rightCols-12">
                        <div className="dk-tabs-right-detail-12">
                            <Tab.Container id="left-tabs-example" >

                                {
                                    (defaultTitles === "Overview") ? <>
                                    </>
                                        :
                                        <>
                                            <Nav variant="" className='dk-tabs-right-nav-12'>
                                                <Nav.Item className='dk-tabs-right-navitem-12'>
                                                    <Link to="#" className={(tabName === 'details') ? `dk-tabs-right-navlink-12 tab_1 ` : `dk-tabs-right-navlink-12 tab_1 activeSubTab`} onClick={() => changeTabName('details')}>Details</Link>
                                                </Nav.Item>
                                                <Nav.Item className='dk-tabs-right-navitem-12'>
                                                    <Link to="#" className={(tabName === 'audio') ? `dk-tabs-right-navlink-12 tab_2 ` : `dk-tabs-right-navlink-12 tab_2 `} onClick={() => changeTabName('audio')}>Audio</Link>
                                                </Nav.Item>
                                                <Nav.Item className='dk-tabs-right-navitem-12'>
                                                    <Link to="#" className={(tabName === 'video') ? `dk-tabs-right-navlink-12 tab_3 ` : `dk-tabs-right-navlink-12 tab_3 `} onClick={() => changeTabName('video')}>Video</Link>
                                                </Nav.Item>
                                            </Nav>
                                        </>
                                }

                                <div className={`dk-tabs-right-tabpane-12 addnewClass dkg-hidewithoutselct`}>
                                    <div className="dk-rightDetail-panel-13">
                                        <div className="dk-getStartedRight-detail-13">
                                            <Nav variant="" className='dk-tabs-right-nav-13 flex-column'>
                                                {
                                                    data.map((item, key) =>
                                                        <Nav.Item className='dk-tabs-right-navitem-13'>
                                                            {
                                                                (key == 0) ?
                                                                    <Link to="#" className={`dk-tabs-right-navlink-13 addActiveClass xyz` + item.id} onClick={() => subCategoryFunction(item.id, item.categoryId, item.subCategoryName)}> {item.subCategoryName}  </Link>
                                                                    :
                                                                    <Link to="#" className={`dk-tabs-right-navlink-13 xyz` + item.id} onClick={() => subCategoryFunction(item.id, item.categoryId, item.subCategoryName)}> {item.subCategoryName}  </Link>
                                                            }
                                                        </Nav.Item>
                                                    )
                                                }
                                            </Nav>

                                            {
                                                showDetailsTab ?
                                                    <Tab.Content className='dk-tabs-right-tabcontent-13 active-1'>

                                                        <div className="dkg-scriptview-text">
                                                            <Editor
                                                                editorState={editorOverviewState_demands}
                                                                wrapperClassName="wrapper-class"
                                                                editorClassName="editor-class"
                                                                toolbarClassName="toolbar-class"

                                                            />
                                                        </div>
                                                    </Tab.Content>
                                                    :
                                                    <>
                                                        {/* this is for default active tab data */}
                                                        {
                                                            data.length > 0 ?
                                                                <Tab.Content className='dk-tabs-right-tabcontent-13 active-2'>
                                                                    <div className="dkg-scriptview-text">
                                                                        <Editor
                                                                            editorState={editorOverviewState_demands}
                                                                            wrapperClassName="wrapper-class"
                                                                            editorClassName="editor-class"
                                                                            toolbarClassName="toolbar-class"
                                                                        />
                                                                    </div>
                                                                </Tab.Content>
                                                                : null
                                                        }
                                                    </>
                                            }

                                        </div>
                                    </div>
                                </div>

                                {
                                    (defaultTitles === "Overview") ? <>
                                        <Tab.Content className='dk-tabs-right-tabcontent-13 active-2'>
                                            <div className="dkg-scriptview-text">
                                                <Editor
                                                    editorState={editorOverviewState_demands}
                                                    wrapperClassName="wrapper-class"
                                                    editorClassName="editor-class"
                                                    toolbarClassName="toolbar-class"
                                                />

                                            </div>
                                        </Tab.Content>
                                    </>
                                        :
                                        null
                                }

                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step1;
