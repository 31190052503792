import React from 'react';
import Header from "../elements/header";
// import ClientsInfoMainMenu from "../elements/header/navbar/mainMenu/clientsinfomenu/ClientsInfoMainMenu";

const LegalModuleLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header mainheaderLink="Dashboard -" moduleLink="/dashboard/recruitment" linkcolor="#2e97b6" mainheaderTitle=" legal" textcolor="#258FB4" />
            {/* <ClientsInfoMainMenu /> */}
            {children}
        </React.Fragment>
    )
}
export default LegalModuleLayout;