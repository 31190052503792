import React, { useState, useEffect, useRef } from "react";
import { Route, Link } from "react-router-dom";
// import Submenu from "../subMenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const BudgetMenu = (props) => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    const [show, setShowMenu] = React.useState();

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setShowMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);


    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK Global CRM";
    }, [pathname]);
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-recruitmentNavbar dkg-marketingNavbarBg" ref={ref}>
                <button className="mobileNav-btn" onClick={() => setShowMenu(!show)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <Route>
                    <ul className={`mobileNav ${show ? "show" : ""}`}>
                        <button className="dk-closeBtn" onClick={() => setShowMenu(false)}>
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                        <li><Link to="/budget/summary" className={`${(segment2 === 'summary') ? 'active' : ''}`}>Budget</Link></li>
                        <li><Link to="/budget/performance" className={`${(segment2 === 'performance') ? 'active' : ''}`}>Performance</Link></li>
                        {/* <li><Link to="#/budget/policies" className={`${(segment2 === 'policies') ? 'active' : ''}`}>Budget 2</Link></li>
                        <li><Link to="#/budget/salaries" className={`${(segment2 === 'salaries') ? 'active' : ''}`}>Budget 3</Link></li>
                        <li><Link to="#/budget/income-tax" className={`${(segment2 === 'income-tax') ? 'active' : ''}`}>Budget 4</Link></li>
                        <li><Link to="#/budget/benefits" className={`${(segment2 === 'benefits') ? 'active' : ''}`}>Budget 5</Link></li>
                        <li><Link to="#/budget/relocation" className={`${(segment2 === 'relocation') ? 'active' : ''}`}>Budget 6</Link></li>
                        <li><Link to="#/budget/interviews" className={`${(segment2 === 'interviews') ? 'active' : ''}`}>Budget 8</Link></li>
                        <li><Link to="#/budget/profiling" className={`${(segment2 === 'profiling') ? 'active' : ''}`}>Budget 9</Link></li> */}
                    </ul>
                </Route>
            </div>
            {/* <Submenu path={pathname} /> */}
        </React.Fragment>
    );
}

export default BudgetMenu;
