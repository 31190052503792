import React from 'react';
//import NavTabs from "../NavTabs";
import { Tab, Nav } from 'react-bootstrap';
import '../kpijobspecs/kipdash.scss';
import '../kpijobspecs/summary.scss';
import KPISummary from '../kpijobspecs/Summary';
import KpiJobSpecsLeftPanel from '../kpijobspecs/KpiJobSpecsLeftPanel';

const KpiDashboard = (props) => {
    return (
        <>
            {/* <div className="dk-jobsSpecSubmenuCon">
                <div className="dk-kpiNav">
                    <NavTabs />
                </div>
            </div> */}
            <div className="container-fluid dk-kipdashMainCon">
                <Tab.Container id="left-tabs-example" defaultActiveKey="summary">
                    <div className="row">
                        <div className="col-md-12 dk-kpidashMainCon">
                            <div className="dashsideMenuCon">

                            </div>
                            <div className="d-flex">
                                <KpiJobSpecsLeftPanel />
                                <div className="dk-kpiRightPanel">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="summary">
                                            <div className="dk-tabPaneCon" style={{ marginTop: '44px' }}>
                                                <KPISummary />
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default KpiDashboard;