import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PostData, PutData } from "../../../services/";

export const candidateList = createAsyncThunk(
    'candidate/list/new-cv',
    async ({ page, limit, search, filter, sort }, thunkAPI) => {
        const res = await PostData(true, '/candidates/list/new-cv', { page, limit, search, filter, sort })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateTable = createAsyncThunk(
    'candidate/list/update',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/candidates/profile/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const addCandidateManually = createAsyncThunk(
    'candidate/list/add-manual',
    async (thunkAPI) => {
        const res = await PostData(true, '/candidates/profile/add-manual', {})
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const newCvSlice = createSlice({
    name: 'newcvList',
    initialState: {
        dataList: [],
        isSuccess: false,
        isLoading: false,
        isError: false,
        message: ''
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.message = '';
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            candidateList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            candidateList.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            candidateList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addCandidateManually.pending, (state) => {
            });
        builder.addCase(
            addCandidateManually.fulfilled, (state, { payload }) => {
                state.dataList.unshift(payload.result)
                state.isSuccess = true;
            });
        builder.addCase(
            addCandidateManually.rejected, (state, action) => {
                state.isSuccess = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateTable.pending, (state) => {
            });
        builder.addCase(
            updateTable.fulfilled, (state, { payload }) => {
                let mainArray = state.dataList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result[0].id) {
                        return (payload.result[0])
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.dataList = finalResult
            });
        builder.addCase(
            updateTable.rejected, (state, action) => {
                state.isError = true;
                // state.message = action.error.message;
            });
    }
});
export const { clearState } = newCvSlice.actions;
export default newCvSlice.reducer;