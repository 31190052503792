import React, { useEffect, useState } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'
import "./dropdownFilter.scss";

const StatusFilter = ({ list, selectOtherFilter, jobSpecFilter }) => {

    const [userList, setUserList] = useState([]);

    useEffect(() => {
        const result = [];
        const map = new Map();
        for (const item of list) {
            if (!map.has(item.original.lang)) {
                map.set(item.original.lang, true);
                result.push({
                    name: item.original.lang,
                    count: list.filter(function (val) {
                        return val.original.lang === item.original.lang;
                    }).length
                });
            }
        }
        result.sort(function (a, b) {
            return b.count - a.count;
        });
        setUserList(result)
    }, [list])

    return (
        <React.Fragment>
            <DropdownButton className="StatusdropdownFilter" id="dropdown-basic-button" title={jobSpecFilter}>
                {
                    userList && userList.map((item, index) =>
                        <Dropdown.Item href="#" data-type="language" data-value={item.name} onClick={selectOtherFilter}>
                         {item.name}
                        <span className="dropdown-filter-count" style={{background:"#fff",color:"#4d4a4a"}} data-type="language" data-value={item.name} onClick={selectOtherFilter}>
                            {item.count}
                        </span>
                        </Dropdown.Item>
                    )
                }
            </DropdownButton>
        </React.Fragment>
    )
}

export default StatusFilter;