import React, { useState } from 'react';
import "./compainespolicy.scss";
import { Link } from "react-router-dom";
import AddnewModal from "./modal/addnew/AddnewModal";
import ReorderModal from "./modal/reorder/ReorderModal";
import ViewModal from "./modal/view/ViewModal";
import EditModal from "./modal/edit/EditModal";
import DeleteModal from "../../../ui/Modal/deletemodal/DeleteDummy";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FaEye, FaEuroSign } from "react-icons/fa";
import Dropdown from 'react-bootstrap/Dropdown';
import TItleIcon from "../../../../assets/images/company.png";
import KeytermsModal from './modal/info/KeytermsModal'
import AddressModal from './modal/info/AddressModal'

const Policies = () => {
    const [addNewResorce, setaddNewResorce] = useState(false);
    const [isReorder, setisReorder] = useState(false);
    const [isViewModal, setisViewModal] = useState(false);
    const [isEditModal, setisEditModal] = useState(false);
    const [isDeleteModal, setisDeleteModal] = useState(false);
    const [iskeytermsModal, setIskeytermsModal] = useState(false);
    const [isaddressModal, setIsaddressModal] = useState(false);
    const showAddNewResource = () => {
        setaddNewResorce(true)
    }
    const hideAddNewResource = () => {
        setaddNewResorce(false)
    }
    const showReorder = () => {
        setisReorder(true)
    }
    const hideReorder = () => {
        setisReorder(false)
    }
    const showviewModal = () => {
        setisViewModal(true)
    }
    const hideviewModal = () => {
        setisViewModal(false)
    }
    const showeditModal = () => {
        setisEditModal(true)
    }
    const hideeditModal = () => {
        setisEditModal(false)
    }
    const showDeletemodal = () => {
        setisDeleteModal(true)
    }
    const hideDeletemodal = () => {
        setisDeleteModal(false)
    }
    const showkeytermsmodal = () => {
        setIskeytermsModal(true)
    }
    const hidekeytermsmodal = () => {
        setIskeytermsModal(false)
    }
    const showadressmodal = () => {
        setIsaddressModal(true)
    }
    const hideadressmodal = () => {
        setIsaddressModal(false)
    }
    return (
        <React.Fragment>
            {
                (addNewResorce) ? <AddnewModal onHide={hideAddNewResource} /> : null
            }
            {
                (isReorder) ? <ReorderModal onHide={hideReorder} /> : null
            }
            {
                (isViewModal) ? <ViewModal onHide={hideviewModal} /> : null
            }
            {
                (isEditModal) ? <EditModal onHide={hideeditModal} /> : null
            }
            {
                (isDeleteModal) ? <DeleteModal onHide={hideDeletemodal} /> : null
            }
            {
                (iskeytermsModal) ? <KeytermsModal onHide={hidekeytermsmodal} /> : null
            }
            {
                (isaddressModal) ? <AddressModal onHide={hideadressmodal} /> : null
            }
            <div className="container-fluid dkg-comppolicy-PageCont" style={{ marginTop: "160px" }}>
                <div className="row">
                    <div className="col-12 col-md-12 text-center">
                        <div className="row align-items-center">
                            <div className="col-md-4 col-sm-12 dk-dkClileftGap">
                                <div className="dk-cliLeftheaderCont d-flex pl-0 mr-2">
                                    <input id="srchterm" type="text" className="form-control searchTxtBox" name="search" placeholder="Search..." />
                                    <button className="btn dk-mbclientsrchInput legitRipple" type="button">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                                <div className="dk-ClieAddNewBtn pr-0" onClick={showAddNewResource}>
                                    <Link to="#"><i className="fa fa-plus" aria-hidden="true"></i></Link>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="dk-clntsPageTitle">
                                    <h2> <img src={TItleIcon} className="img-fluid" alt="" />Client List</h2>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12 dkg-clientlist-rightCon">
                                <div className="d-flex justify-content-end">
                                    <button className="dk-reloadBtn mr-2"><FontAwesomeIcon icon={faRefresh} /></button>
                                    <button className="dk-reloadBtn" onClick={showReorder}>
                                        <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                    </button>
                                    <div className='dkg-clientList-dropCon'>
                                        <Dropdown className='dkg-clientList-dropdown'>
                                            <Dropdown.Toggle variant="" className='dkg-clientList-dropdown-toggle'>
                                                Status
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dkg-clientList-dropdownMenu'>
                                                <Dropdown.Item href="#" style={{ backgroundColor: "#31a886", color: "#fff" }}>Active
                                                    <span className='dropdown-filter-count'>8</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ backgroundColor: "#dc3545", color: "#fff" }}>Inactive
                                                    <span className='dropdown-filter-count'>12</span>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="dkg-compolicytableCon table-responsive">
                            <table className="table dkg-compolicytable">
                                <thead>
                                    <tr>
                                        <th>
                                            {/* <div className="dk-cliLeftheaderCont d-flex">
                                                <input id="srchterm" type="text" className="form-control searchTxtBox" name="search" placeholder="Search by Text" />
                                                <button className="btn dk-mbclientsrchInput legitRipple" type="button">
                                                    <i className="fa fa-search"></i>
                                                </button>
                                            </div> */}
                                            SNo.
                                        </th>
                                        <th>Status</th>
                                        <th>Name of Client</th>
                                        <th>COUNTRY</th>
                                        <th>CITY</th>
                                        <th>CATEGORY</th>
                                        <th>CONTRACT</th>
                                        <th>KEY TERMS</th>
                                        <th>ADDRESS</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            01
                                        </td>
                                        <td>
                                            <div className="dk-satus-SlectDropDwn">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="" style={{ backgroundColor: "#31a886", color: "#fff" }}>
                                                        Active
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#" style={{ backgroundColor: "#31a886", color: "#fff" }}>Active</Dropdown.Item>
                                                        <Dropdown.Item href="#" style={{ backgroundColor: "#d8345f", color: "#fff" }}>Inactive</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </td>
                                        <td>
                                            Lydia Stavropoulou
                                        </td>
                                        <td>
                                            India
                                        </td>
                                        <td>
                                            Noida

                                        </td>
                                        <td>
                                            Category 1
                                        </td>
                                        <td>
                                            Contract 1
                                        </td>
                                        <td>
                                            <div className='dkg-reports-cols' onClick={showkeytermsmodal}>
                                                <i className="fa fa-info" aria-hidden="true"></i>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-reports-cols' onClick={showadressmodal}>
                                                <i className="fa fa-info" aria-hidden="true"></i>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-CliDetailAction">
                                                {/* <Link className="dk-view-btn mr-2" to="#">
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </Link> */}
                                                <Link className="dk-edit-btn mr-2" to="#" onClick={showeditModal}>
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </Link>
                                                <Link className="dk-delete-btn" to="#" onClick={showDeletemodal}>
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Policies;

