import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { FaUserAlt } from "react-icons/fa";

const index = () => {
    return (
        <>
            <Dropdown className='dkg-candfilter-dropdown15 mr-2'>
                <Dropdown.Toggle variant="success" className='dkg-candfilter-dropdown-toggle15'>
                    <FaUserAlt style={{ color: "#d3d3d3" }} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='dkg-candfilter-dropdown-menu15'>
                    <Dropdown.Item href="#/action-1" className='dkg-candfilter-dropdown-item15'>
                        <FaUserAlt style={{ color: "green" }} />
                        <span className="proflieCount">Motivated </span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className='dkg-candfilter-dropdown-item15'>
                        <FaUserAlt style={{ color: "#d58b5d" }} />
                        <span className="proflieCount">Doubtful </span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className='dkg-candfilter-dropdown-item15' >
                        <FaUserAlt style={{ color: "red" }} />
                        <span className="proflieCount">Unmotivated</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1" className='dkg-candfilter-dropdown-item15'>
                        <FaUserAlt style={{ color: "#d3d3d3" }} />
                        <span className="proflieCount">Blank </span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default index;