import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'
import styled from 'styled-components';

const MonthsFilter = ({ list, monthsFilterText, onSelectMonthFilter, tableType }) => {
    const DropdownCss = styled.div`
    .dropdown{
        display: flex;
        justify-content: center;
        align-items: center;
        .dropdown-toggle{
            background-color: #fff !important;
            border: 1px solid #ddd !important;
            color: #333;
            font-size: 12px;
            padding: 7px 5px;
            text-align: center;
            text-transform: uppercase;
            width: 100%;
            min-width: 130px;
            max-width: 130px;
            box-shadow: none !important;
            margin: 0;
            &::after {
                display: inline-block !important;
                font-family: ' Font Awesome 5 Free';
                font-style: normal;
                font-weight: 700;
                margin: 0 0 0 10px;
                font-size: 13px;
                position: absolute;
                top: 16px;
                text-decoration: none;
                border-top: 0.33em solid;
                border-right: 0.33em solid transparent;
                border-bottom: 0;
                border-left: 0.33em solid transparent;
            }
            &.btn-primary:not([disabled]):not(.disabled):active,
               &.btn-primary:not([disabled]):not(.disabled).active, 
               .show>.btn-primary.dropdown-toggle {
                background-color: #fff !important;
                border: 1px solid #ddd !important;
                 color: #333 !important;
               }
        }
        .dropdown-menu{
            min-width: 120px;
            border: none;
            border-radius: 0;
            padding: 0;
            width: 100%;
            background-color: #eaeaea;
            transform: inherit !important;
            top: 37px !important;
            box-shadow: 0 9px 16px 0 rgb(9 50 131 / 20%);
            height: calc(100vh - 368px);
            overflow-y: auto;
            
            .dropdown-item{
                align-items: center;
                background-color: #eaeaea;
                color: #333 !important;
                border: none;
                border-bottom: 1px solid transparent;
                display: flex;
                font-size: 13px;
                position: relative;
                text-transform: capitalize;
                width: 100%; 
                padding: 5px 7px;
                border-bottom: 1px solid #ddd;
                &:last-child{
                    border-bottom: none;
                }
                &:hover{
                    background-color: #f1f1f1;
                }
                span.dropdown-filter-count {
                    align-items: center;
                    background-color: #3c4c62;
                    border-radius: 2px;
                    color: #fff;
                    display: flex;
                    font-size: 11px;
                    justify-content: center;
                    padding: 1px 0;
                    position: absolute;
                    right: 4px;
                    text-align: center;
                    top: 6px;
                    width: 20px;
                }                  
            }
        }
    }
    .crossUserFilter {
        right: -2px  !important;
    }
`;

    return (
        <React.Fragment>
            <DropdownCss>
                <DropdownButton className='dk-cli-status-dropdown' title={monthsFilterText} onSelect={onSelectMonthFilter}>
                    <Dropdown.Item eventKey="Jan" href="#" className="dk-activeBtn"> Jan
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.months === 'Jan';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Feb" href="#" className="dk-inActiveBtn">Feb
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.months === 'Feb';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Mar" href="#" className="dk-inActiveBtn">Mar
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.months === 'Mar';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Apr" href="#" className="dk-inActiveBtn">Apr
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.months === 'Apr';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="May" href="#" className="dk-inActiveBtn">May
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.months === 'May';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Jun" href="#" className="dk-inActiveBtn">Jun
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.months === 'Jun';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Jul" href="#" className="dk-inActiveBtn">Jul
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.months === 'Jul';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Aug" href="#" className="dk-inActiveBtn">Aug
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.months === 'Aug';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Sep" href="#" className="dk-inActiveBtn">Sep
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.months === 'Sep';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Oct" href="#" className="dk-inActiveBtn">Oct
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.months === 'Oct';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Nov" href="#" className="dk-inActiveBtn">Nov
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.months === 'Nov';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Dec" href="#" className="dk-inActiveBtn">Dec
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.months === 'Dec';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>


                </DropdownButton>

            </DropdownCss>
        </React.Fragment>
    )
}

export default MonthsFilter;