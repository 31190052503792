import React from 'react'
import PaymentsArchive from '../../tasks/payments/archive'


const PaymentTasksArchived = () => {
  return (
    <>
      <div className="dkg-payment-suMM-mainCon" style={{ marginTop: "160px" }}>
        <PaymentsArchive />
      </div>
    </>

  )
}

export default PaymentTasksArchived;