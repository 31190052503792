import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./rightmodalfilter.scss";
import { Modal, Nav, Tab } from "react-bootstrap";
import { MdInsertDriveFile } from "react-icons/md";

const RightModalFilter = ({ list, selectUserFilter, selectOtherFilter }) => {
  const [raUserList, setRaUserList] = useState([]);
  const [rcUserList, setRcUserList] = useState([]);
  const [amUserList, setAmUserList] = useState([]);
  const [cpList, setCpList] = useState([]);
  const [csList, setCsList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [langList, setLangList] = useState([]);
  const [cvSourceList, setCvSourceList] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    const raResult = [];
    const ramap = new Map();
    for (const item of list) {
      if (!ramap.has(item.original.recAdminInfo.uid)) {
        ramap.set(item.original.recAdminInfo.uid, true);
        raResult.push({
          id: item.original.recAdminInfo.uid,
          name: item.original.recAdminInfo.firstName,
          userImg: item.original.recAdminInfo.profileImage,
          count: list.filter(function (val) {
            return (
              val.original.recAdminInfo.uid === item.original.recAdminInfo.uid
            );
          }).length,
        });
      }
    }
    raResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setRaUserList(raResult);

    const rcResult = [];
    const rcmap = new Map();
    for (const item of list) {
      if (!rcmap.has(item.original.recInfo.uid)) {
        rcmap.set(item.original.recInfo.uid, true);
        rcResult.push({
          id: item.original.recInfo.uid,
          name: item.original.recInfo.firstName,
          userImg: item.original.recInfo.profileImage,
          count: list.filter(function (val) {
            return val.original.recInfo.uid === item.original.recInfo.uid;
          }).length,
        });
      }
    }
    rcResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setRcUserList(rcResult);

    const amResult = [];
    const ammap = new Map();
    for (const item of list) {
      if (!ammap.has(item.original.amInfo.uid)) {
        ammap.set(item.original.amInfo.uid, true);
        amResult.push({
          id: item.original.amInfo.uid,
          name: item.original.amInfo.firstName,
          userImg: item.original.amInfo.profileImage,
          count: list.filter(function (val) {
            return val.original.amInfo.uid === item.original.amInfo.uid;
          }).length,
        });
      }
    }
    amResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setAmUserList(amResult);

    const cpResult = [];
    const cpmap = new Map();
    for (const item of list) {
      if (!cpmap.has(item.original.case_progress)) {
        cpmap.set(item.original.case_progress, true);
        cpResult.push({
          name:
            item.original.case_progress !== ""
              ? item.original.case_progress
              : "Blank",
          count: list.filter(function (val) {
            return val.original.case_progress === item.original.case_progress;
          }).length,
        });
      }
    }
    cpResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setCpList(cpResult);

    const csResult = [];
    const csmap = new Map();
    for (const item of list) {
      if (!csmap.has(item.original.case_stage)) {
        csmap.set(item.original.case_stage, true);
        csResult.push({
          name:
            item.original.case_stage !== ""
              ? item.original.case_stage
              : "Blank",
          count: list.filter(function (val) {
            return val.original.case_stage === item.original.case_stage;
          }).length,
        });
      }
    }
    csResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setCsList(csResult);

    const companyResult = [];
    const companymap = new Map();
    for (const item of list) {
      if (!companymap.has(item.original.jobDetail.company)) {
        companymap.set(item.original.jobDetail.company, true);
        companyResult.push({
          name:
            item.original.jobDetail.company !== ""
              ? item.original.jobDetail.company
              : "Blank",
          count: list.filter(function (val) {
            return (
              val.original.jobDetail.company === item.original.jobDetail.company
            );
          }).length,
        });
      }
    }
    companyResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setCompanyList(companyResult);

    const projectResult = [];
    const projectmap = new Map();
    for (const item of list) {
      if (!projectmap.has(item.original.jobDetail.project)) {
        projectmap.set(item.original.jobDetail.project, true);
        projectResult.push({
          name: item.original.jobDetail.project
            ? item.original.jobDetail.project
            : "Blank",
          count: list.filter(function (val) {
            return (
              val.original.jobDetail.project === item.original.jobDetail.project
            );
          }).length,
        });
      }
    }
    projectResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setProjectList(projectResult);

    const roleResult = [];
    const rolemap = new Map();
    for (const item of list) {
      if (!rolemap.has(item.original.jobDetail.role)) {
        rolemap.set(item.original.jobDetail.role, true);
        roleResult.push({
          name:
            item.original.jobDetail.role !== ""
              ? item.original.jobDetail.role
              : "Blank",
          count: list.filter(function (val) {
            return val.original.jobDetail.role === item.original.jobDetail.role;
          }).length,
        });
      }
    }
    roleResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setRoleList(roleResult);

    const langResult = [];
    const langmap = new Map();
    for (const item of list) {
      if (!langmap.has(item.original.jobDetail.lang)) {
        langmap.set(item.original.jobDetail.lang, true);
        langResult.push({
          name:
            item.original.jobDetail.lang !== ""
              ? item.original.jobDetail.lang
              : "Blank",
          count: list.filter(function (val) {
            return val.original.jobDetail.lang === item.original.jobDetail.lang;
          }).length,
        });
      }
    }
    langResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setLangList(langResult);

    const cvSourceResult = [];
    const cvSourcemap = new Map();
    for (const item of list) {
      if (!cvSourcemap.has(item.original.cv_source)) {
        cvSourcemap.set(item.original.cv_source, true);
        cvSourceResult.push({
          name:
            item.original.cv_source !== "" ? item.original.cv_source : "Blank",
          count: list.filter(function (val) {
            return val.original.cv_source === item.original.cv_source;
          }).length,
        });
      }
    }
    cvSourceResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setCvSourceList(cvSourceResult);
  }, [list]);

  return (
    <React.Fragment>
      <button className="dk-reoderBtn" onClick={handleShow}>
        <i className="fa fa-filter"></i>
      </button>
      <div className="dk-jobsMainModalCon">
        <Modal
          show={show}
          onHide={handleClose}
          dialogClassName="dk-pipenwqrightFliterModal"
        >
          <Modal.Header closeButton className="dkmb-Fliterheader">
            <Modal.Title className="dkmb-FliterTitle">
              SEARCH & REPORT
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="dkmb-Fliterbody">
            <Tab.Container
              id="dk-jobsRightFilterModal"
              defaultActiveKey="rec-admin"
            >
              <div className="row ml-0 mr-0">
                <div className="col-md-4 pl-0 pr-0">
                  <Nav variant="pills" className="flex-column dk-leftSideMenu">
                    {/* <Nav.Item>
                                            <Nav.Link eventKey="recruiters">
                                                <i className="fa fa-user mr-2" aria-hidden="true"></i>RT
                                            </Nav.Link>
                                        </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link eventKey="rec-admin">
                        <i className="fa fa-user mr-2" aria-hidden="true"></i>RA
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="rc">
                        <i className="fa fa-user mr-2" aria-hidden="true"></i>RC
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="account-manager">
                        <i className="fa fa-user mr-2" aria-hidden="true"></i>AM
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="flags">
                        <i className="fa fa-flag mr-2" aria-hidden="true"></i>
                        Flags
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="category">
                        <i
                          className="fa fa-language mr-2"
                          aria-hidden="true"
                        ></i>
                        Category
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="status">
                        <i className="fa fa-check mr-2" aria-hidden="true"></i>
                        Status
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="progress">
                        <i className="fas fa-chart-line mr-2"></i>Progress
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="casestage">
                        <i className="far fa-check-square mr-2"></i>Case Stage
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="company">
                        <i
                          className="fa fa-building mr-2"
                          aria-hidden="true"
                        ></i>
                        Company
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="project">
                        <MdInsertDriveFile className="mr-3" />
                        Project
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="roletype">
                        <i className="fa fa-user mr-2" aria-hidden="true"></i>
                        Role Type
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="languages">
                        <i
                          className="fa fa-language mr-2"
                          aria-hidden="true"
                        ></i>
                        Languages
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="resources">
                        <i
                          className="fa fa-sitemap mr-2"
                          aria-hidden="true"
                        ></i>
                        Resources
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="bydate">
                        <i
                          className="fa fa-calendar mr-2"
                          aria-hidden="true"
                        ></i>
                        By Date
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-md-8 pl-0 pr-0">
                  <Tab.Content>
                    {/* <Tab.Pane eventKey="recruiters">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-Recuriter">
                                                    <ul>
                                                        {rtUserList.map((item, index) => (
                                                            <li key={`rtFilter${index}`}>
                                                                <Link to="#" data-type="RTID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} alt={item.name} className="recImg" title={item.name} data-type="RTID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                                                    <span className="recuriterNo" data-type="RTID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                        {item.count}
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane> */}
                    <Tab.Pane eventKey="rec-admin">
                      <div className="dk-rightMainContent">
                        <div className="dk-Recuriter">
                          <ul>
                            {raUserList.map((item, index) => (
                              <li key={`raFilter${index}`}>
                                <Link
                                  to="#"
                                  data-type="RAID"
                                  data-id={item.id}
                                  data-img={item.userImg}
                                  onClick={selectUserFilter}
                                >
                                  <img
                                    src={
                                      process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                      item.userImg
                                    }
                                    alt={item.name}
                                    className="recImg"
                                    title={item.name}
                                    data-type="RAID"
                                    data-id={item.id}
                                    data-img={item.userImg}
                                    onClick={selectUserFilter}
                                  />
                                  <span
                                    className="recuriterNo"
                                    data-type="RAID"
                                    data-id={item.id}
                                    data-img={item.userImg}
                                    onClick={selectUserFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="rc">
                      <div className="dk-rightMainContent">
                        <div className="dk-Recuriter">
                          <ul>
                            {rcUserList.map((item, index) => (
                              <li key={`rcFilter${index}`}>
                                <Link
                                  to="#"
                                  data-type="RCID"
                                  data-id={item.id}
                                  data-img={item.userImg}
                                  onClick={selectUserFilter}
                                >
                                  <img
                                    src={
                                      process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                      item.userImg
                                    }
                                    alt={item.name}
                                    className="recImg"
                                    title={item.name}
                                    data-type="RCID"
                                    data-id={item.id}
                                    data-img={item.userImg}
                                    onClick={selectUserFilter}
                                  />
                                  <span
                                    className="recuriterNo"
                                    data-type="RCID"
                                    data-id={item.id}
                                    data-img={item.userImg}
                                    onClick={selectUserFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="account-manager">
                      <div className="dk-rightMainContent">
                        <div className="dk-Recuriter">
                          <ul>
                            {amUserList.map((item, index) => (
                              <li key={`amFilter${index}`}>
                                <Link
                                  to="#"
                                  data-type="AMID"
                                  data-id={item.id}
                                  data-img={item.userImg}
                                  onClick={selectUserFilter}
                                >
                                  <img
                                    src={
                                      process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                      item.userImg
                                    }
                                    alt={item.name}
                                    className="recImg"
                                    title={item.name}
                                    data-type="AMID"
                                    data-id={item.id}
                                    data-img={item.userImg}
                                    onClick={selectUserFilter}
                                  />
                                  <span
                                    className="recuriterNo"
                                    data-type="AMID"
                                    data-id={item.id}
                                    data-img={item.userImg}
                                    onClick={selectUserFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="flags">
                      <div className="dk-rightMainContent">
                        <div className="dk-flagFilter">
                          <ul>
                            <li>
                              <Link
                                to="#"
                                data-type="FlagID"
                                data-id="1"
                                data-img="#d0cece"
                                onClick={selectUserFilter}
                              >
                                <i
                                  className="fa fa-flag mr-2"
                                  aria-hidden="true"
                                  style={{ color: "#d0cecd" }}
                                  data-type="FlagID"
                                  data-id="1"
                                  data-img="#d0cece"
                                  onClick={selectUserFilter}
                                ></i>
                                <span
                                  className="text-orange"
                                  data-type="FlagID"
                                  data-id="1"
                                  data-img="#d0cece"
                                  onClick={selectUserFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return val.original.flag_type === 1;
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-type="FlagID"
                                data-id="2"
                                data-img="green"
                                onClick={selectUserFilter}
                              >
                                <i
                                  className="fa fa-flag mr-2"
                                  aria-hidden="true"
                                  style={{ color: "green" }}
                                  data-type="FlagID"
                                  data-id="2"
                                  data-img="green"
                                  onClick={selectUserFilter}
                                ></i>
                                <span
                                  className="text-orange"
                                  data-type="FlagID"
                                  data-id="2"
                                  data-img="green"
                                  onClick={selectUserFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return val.original.flag_type === 2;
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-type="FlagID"
                                data-id="3"
                                data-img="red"
                                onClick={selectUserFilter}
                              >
                                <i
                                  className="fa fa-flag mr-2"
                                  aria-hidden="true"
                                  style={{ color: "red" }}
                                  data-id="3"
                                  data-img="red"
                                  onClick={selectUserFilter}
                                ></i>
                                <span
                                  className="text-orange"
                                  data-id="3"
                                  data-img="red"
                                  onClick={selectUserFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return val.original.flag_type === 3;
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-type="FlagID"
                                data-id="4"
                                data-img="eaa03d"
                                onClick={selectUserFilter}
                              >
                                <i
                                  className="fa fa-flag mr-2"
                                  aria-hidden="true"
                                  style={{ color: "#eaa03d" }}
                                  data-id="4"
                                  data-img="eaa03d"
                                  onClick={selectUserFilter}
                                ></i>
                                <span
                                  className="text-orange"
                                  data-id="4"
                                  data-img="eaa03d"
                                  onClick={selectUserFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return val.original.flag_type === 4;
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="category">
                      <div className="dk-rightMainContent">
                        <div className="dk-CategoryList">
                          <ul>
                            <li>
                              <Link
                                to="#"
                                data-type="category"
                                data-value="ML"
                                onClick={selectOtherFilter}
                              >
                                ML
                                <span
                                  className="text-orange"
                                  data-value="ML"
                                  onClick={selectOtherFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return val.original.category === "ML";
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-type="category"
                                data-value="IT"
                                onClick={selectOtherFilter}
                              >
                                IT
                                <span
                                  className="text-orange"
                                  data-value="IT"
                                  onClick={selectOtherFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return val.original.category === "IT";
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-type="category"
                                data-value="None"
                                onClick={selectOtherFilter}
                              >
                                None
                                <span
                                  className="text-orange"
                                  data-value="None"
                                  onClick={selectOtherFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return val.original.category === "None";
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="status">
                      <div className="dk-rightMainContent">
                        <div className="dk-satusListFilter">
                          <ul>
                            <li>
                              <Link
                                to="#"
                                data-type="caseStatus"
                                style={{
                                  backgroundColor: "#5c737d",
                                  color: "#fff",
                                }}
                                data-value="New CV"
                                onClick={selectOtherFilter}
                              >
                                New Apps
                                <span
                                  className="text-orange"
                                  data-value="New CV"
                                  onClick={selectOtherFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return (
                                        val.original.caseStatus === "New CV"
                                      );
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-type="caseStatus"
                                style={{
                                  backgroundColor: "#796698",
                                  color: "#fff",
                                }}
                                data-value="Screening"
                                onClick={selectOtherFilter}
                              >
                                Screening
                                <span
                                  className="text-orange"
                                  data-value="Screening"
                                  onClick={selectOtherFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return (
                                        val.original.caseStatus === "Screening"
                                      );
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-type="caseStatus"
                                style={{
                                  backgroundColor: "#55496b",
                                  color: "#fff",
                                }}
                                data-value="Reminders"
                                onClick={selectOtherFilter}
                              >
                                Reminders
                                <span
                                  className="text-orange"
                                  data-value="Reminders"
                                  onClick={selectOtherFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return (
                                        val.original.caseStatus === "Reminders"
                                      );
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-type="caseStatus"
                                style={{
                                  backgroundColor: "#009a9a",
                                  color: "#fff",
                                }}
                                data-value="DKG Process"
                                onClick={selectOtherFilter}
                              >
                                DKG Process{" "}
                                <span
                                  className="text-orange"
                                  data-value="DKG Process"
                                  onClick={selectOtherFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return (
                                        val.original.caseStatus ===
                                        "DKG Process"
                                      );
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-type="caseStatus"
                                style={{
                                  backgroundColor: "#1b6867",
                                  color: "#fff",
                                }}
                                data-value="Client Process"
                                onClick={selectOtherFilter}
                              >
                                Client Process{" "}
                                <span
                                  className="text-orange"
                                  data-value="Client Process"
                                  onClick={selectOtherFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return (
                                        val.original.caseStatus ===
                                        "Client Process"
                                      );
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-type="caseStatus"
                                style={{
                                  backgroundColor: "#599ad5",
                                  color: "#fff",
                                }}
                                data-value="Job Offer"
                                onClick={selectOtherFilter}
                              >
                                Job Offer{" "}
                                <span
                                  className="text-orange"
                                  data-value="Job Offer"
                                  onClick={selectOtherFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return (
                                        val.original.caseStatus === "Job Offer"
                                      );
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-type="caseStatus"
                                style={{
                                  backgroundColor: "#a84d4d",
                                  color: "#fff",
                                }}
                                data-value="Refused Offer"
                                onClick={selectOtherFilter}
                              >
                                Refused Offer{" "}
                                <span
                                  className="text-orange"
                                  data-value="Refused Offer"
                                  onClick={selectOtherFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return (
                                        val.original.caseStatus ===
                                        "Refused Offer"
                                      );
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-type="caseStatus"
                                style={{
                                  backgroundColor: "#df744a",
                                  color: "#fff",
                                }}
                                data-value="Credit"
                                onClick={selectOtherFilter}
                              >
                                Credit{" "}
                                <span
                                  className="text-orange"
                                  data-value="Credit"
                                  onClick={selectOtherFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return (
                                        val.original.caseStatus === "Credit"
                                      );
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-type="caseStatus"
                                style={{
                                  backgroundColor: "#e2445c",
                                  color: "#fff",
                                }}
                                data-value="Closed"
                                onClick={selectOtherFilter}
                              >
                                Closed
                                <span
                                  className="text-orange"
                                  data-value="Closed"
                                  onClick={selectOtherFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return (
                                        val.original.caseStatus === "Closed"
                                      );
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                data-type="caseStatus"
                                style={{
                                  backgroundColor: "#670004",
                                  color: "#fff",
                                }}
                                data-value="Escalated"
                                onClick={selectOtherFilter}
                              >
                                Escalated
                                <span
                                  className="text-orange"
                                  data-value="Escalated"
                                  onClick={selectOtherFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return (
                                        val.original.caseStatus === "Escalated"
                                      );
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="progress">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {cpList.map((item, index) => (
                              <li
                                key={`caseprogress${index}`}
                                data-type="case_progress"
                                data-value={item.name}
                                onClick={selectOtherFilter}
                              >
                                <Link
                                  to="#"
                                  data-type="case_progress"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange"
                                    data-type="case_progress"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="casestage">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {csList.map((item, index) => (
                              <li
                                key={`casestage${index}`}
                                data-type="case_stage"
                                data-value={item.name}
                                onClick={selectOtherFilter}
                              >
                                <Link
                                  to="#"
                                  data-type="case_stage"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange"
                                    data-type="case_stage"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="company">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {companyList.map((item, index) => (
                              <li
                                key={`company${index}`}
                                data-type="company"
                                data-value={item.name}
                                onClick={selectOtherFilter}
                              >
                                <Link
                                  to="#"
                                  data-type="company"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange"
                                    data-type="company"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="project">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {projectList.map((item, index) => (
                              <li
                                key={`project${index}`}
                                data-type="project"
                                data-value={item.name}
                                onClick={selectOtherFilter}
                              >
                                <Link
                                  to="#"
                                  data-type="project"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange"
                                    data-type="project"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="roletype">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList dkg-roletypeFilterlist12">
                          <ul>
                            {roleList.map((item, index) => (
                              <li
                                key={`role${index}`}
                                data-type="role"
                                data-value={item.name}
                                onClick={selectOtherFilter}
                              >
                                <Link
                                  to="#"
                                  data-type="role"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange"
                                    data-type="role"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="languages">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {langList.map((item, index) => (
                              <li
                                key={`lang${index}`}
                                data-type="lang"
                                data-value={item.name}
                                onClick={selectOtherFilter}
                              >
                                <Link
                                  to="#"
                                  data-type="lang"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange"
                                    data-type="lang"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="resources">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {cvSourceList.map((item, index) => (
                              <li
                                key={`cv_source${index}`}
                                data-type="cv_source"
                                data-value={item.name}
                                onClick={selectOtherFilter}
                              >
                                <Link
                                  to="#"
                                  data-type="cv_source"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange"
                                    data-type="cv_source"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default RightModalFilter;
