import React from 'react'
import Modal from 'react-bootstrap/Modal';
import './bonus-info.scss';

const InformationModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='dkg-bonus-infoModal'
            >
                <Modal.Header closeButton className='dkg-bonus-infoModalHeader'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Bonus Structure
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='dkg-bonus-infoModalBody'>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default InformationModal