import React, { useLayoutEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Header from "../elements/header";
import Navbar from "../elements/header/navbar/mainMenu/AdsMenu";
import ProgressBar from "../utils/loader/linear";
import { adsJobboard, adsCompany, adsLanguage, adsRoleType } from "../../slice/layout/layoutSlice";

const Layout = ({ children }) => {
    const dispatch = useDispatch();
    const fetchAdsJobboard = async () => {
        try {
            await dispatch(adsJobboard({}))
        } catch (err) {
            console.log(err)
        }
    }
    const fetchAdsCompany = async () => {
        try {
            await dispatch(adsCompany({}))
        } catch (err) {
            console.log(err)
        }
    }
    const fetchAdsLanguage = async () => {
        try {
            await dispatch(adsLanguage({}))
        } catch (err) {
            console.log(err)
        }
    }
    const fetchAdsRoleType = async () => {
        try {
            await dispatch(adsRoleType({}))
        } catch (err) {
            console.log(err)
        }
    }

    fetchAdsJobboard()
    fetchAdsCompany()
    fetchAdsLanguage()
    fetchAdsRoleType()
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="Recruitment -" moduleLink="/dashboard/recruitment" linkcolor="#3c4c62" mainheaderTitle=" ADS" textcolor="#26596d" />
            <Navbar />
            <ProgressBar />
            {children}
        </React.Fragment>
    )
}
export default Layout;