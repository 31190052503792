import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData } from "../../../services";

export const caseStatusNumbersCounts = createAsyncThunk(
    'candidate/database/summary/caseStatus',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/casestatus', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const categoryNumbersCounts = createAsyncThunk(
    'candidate/database/summary/categoryCount',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/category', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const flagsNumbersCounts = createAsyncThunk(
    'candidate/database/summary/flagsCount',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/flags', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const genderNumbersCounts = createAsyncThunk(
    'candidate/database/summary/genderCount',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/gender', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const nationalityNumbersCounts = createAsyncThunk(
    'candidate/database/summary/nationalityCount',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/nationality', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const cvSourceNumbersCounts = createAsyncThunk(
    'candidate/database/summary/cvsourceCount',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/cvsource', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const languageNumbersCounts = createAsyncThunk(
    'candidate/database/summary/languageCount',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/language', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const companyNumbersCounts = createAsyncThunk(
    'candidate/database/summary/companyCount',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/company', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const recrutersNumbersCounts = createAsyncThunk(
    'candidate/database/summary/recrutersCount',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/recruiters', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)


export const workPermitCounts = createAsyncThunk(
    'candidate/database/summary/workPermitCount',
    async ({ filter }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/work-permit', { filter })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const candidateDatabaseList = createAsyncThunk(
    'candidate/database/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/candidates/database/list')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

const DatabaseSummarySlice = createSlice({
    name: 'databaseCandidatesSummary',
    initialState: {
        categoryCount: [],
        genderCount: [],
        cvSourcerCount: [],
        nationalityCount: [],
        flagsCount: [],
        languageCount: [],
        companyCount: [],
        recrutersCount: [],

        caseStatusCount: [],
        workPermitCount: [],
        candidateDataList: [],
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: ''
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            categoryNumbersCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            categoryNumbersCounts.fulfilled, (state, { payload }) => {
                state.categoryCount = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            categoryNumbersCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            flagsNumbersCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            flagsNumbersCounts.fulfilled, (state, { payload }) => {
                state.flagsCount = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            flagsNumbersCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            genderNumbersCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            genderNumbersCounts.fulfilled, (state, { payload }) => {
                state.genderCount = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            genderNumbersCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });



        builder.addCase(
            recrutersNumbersCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            recrutersNumbersCounts.fulfilled, (state, { payload }) => {
                state.recrutersCount = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            recrutersNumbersCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            cvSourceNumbersCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            cvSourceNumbersCounts.fulfilled, (state, { payload }) => {
                state.cvSourcerCount = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            cvSourceNumbersCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            nationalityNumbersCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            nationalityNumbersCounts.fulfilled, (state, { payload }) => {
                state.nationalityCount = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            nationalityNumbersCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            languageNumbersCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            languageNumbersCounts.fulfilled, (state, { payload }) => {
                state.languageCount = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            languageNumbersCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            companyNumbersCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            companyNumbersCounts.fulfilled, (state, { payload }) => {
                state.companyCount = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            companyNumbersCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            caseStatusNumbersCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            caseStatusNumbersCounts.fulfilled, (state, { payload }) => {
                state.caseStatusCount = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            caseStatusNumbersCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            workPermitCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            workPermitCounts.fulfilled, (state, { payload }) => {
                state.workPermitCount = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            workPermitCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            candidateDatabaseList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            candidateDatabaseList.fulfilled, (state, { payload }) => {
                state.candidateDataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            candidateDatabaseList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
    }
});
export const { clearState } = DatabaseSummarySlice.actions;
export default DatabaseSummarySlice.reducer;