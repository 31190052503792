import React, { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InputGroup,
  FormControl,
  Form,
  Table,
  Row,
  Tab,
  Nav,
} from "react-bootstrap";
import {
  clearState,
  updateJob,
  updateMiniSpecTab,
  updateTitleBlocks,
  addInstructions,
} from "../../../../../../slice/jobs/jobsSlice";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../../slice/utils/message/messageSlice";
import "../../../addnew/addnewjob.scss";
import InterviewDropDwn from "./InterviewDropDwn";

const Interviews = (jobId) => {
  const dispatch = useDispatch();

  const { isDetailsLoading, jobDetails, isUpdate } = useSelector(
    (state) => state.jobs
  );

  const [data, setData] = useState("");
  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setData(jobDetails);
    }
  }, [isDetailsLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobDetails);
    }
  }, [isUpdate]);

  const sampleAd =
    data.miniSpecTab !== undefined && data.miniSpecTab.length
      ? data.miniSpecTab[0].sample_ad
      : "";
  const [sampleAdText, setSampleAdText] = useState(sampleAd);

  useEffect(() => {
    if (sampleAd !== "") {
      setSampleAdText(sampleAd);
    }
  }, [sampleAd]);

  const handleModelChange = (values) => {
    setSampleAdText(values);
    dispatch(
      updateMiniSpecTab({ id: jobId.jobId, key: "sample_ad", value: values })
    );
  };

  const ad_title_1 =
    data.titleBlocks !== undefined && data.titleBlocks.length
      ? data.titleBlocks[0].ad_title_1
      : "";
  const ad_title_2 =
    data.titleBlocks !== undefined && data.titleBlocks.length
      ? data.titleBlocks[0].ad_title_2
      : "";
  const ad_title_3 =
    data.titleBlocks !== undefined && data.titleBlocks.length
      ? data.titleBlocks[0].ad_title_3
      : "";
  const ad_title_4 =
    data.titleBlocks !== undefined && data.titleBlocks.length
      ? data.titleBlocks[0].ad_title_4
      : "";

  const updateSubjectData = (e) => {
    let values = e.target.value;
    let columnId = e.target.dataset.columnid;
    dispatch(
      updateTitleBlocks({ id: jobId.jobId, key: columnId, value: values })
    );
  };

  const [title, setTitle] = useState("");

  const handleButtonClick = async (e) => {
    e.preventDefault();
    if (title == "") {
      dispatch(showError({ msg: "All fields are required." }));
    } else {
      dispatch(addInstructions({ job_id: jobId.jobId, description: title }));
      setTitle("");
    }
  };

  return (
    <>
      <div className="row dk-Adsbox dkg-livejobs-adsboxRow">
        <div className="col-md-12 dkg-livejobs-adsboxCol">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="subJobDetails"
          >
            <div
              className="row samplead ad-description dkg-livejobs-adsboxRow-234"
            // style={{
            //   border: "1px solid #bdbdbd",
            // }}
            >
              <div className="col-md-2 col-sm-2 specsLeftTab dkg-livejobs-adsboxleftSide pl-0 pr-0">
                <Nav variant="pills" className="d-flex edit-header nav nav-d-flex edit-header nav nav-pills dkg-livejobs-adsbox-navpills">
                  <Nav.Item>
                    <Nav.Link eventKey="subJobDetails">Overview</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="1st">1st Interview</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="2nd">2nd Interview</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="3rd">3rd Interview</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="4th">4th Interview</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="5th">5th Interview</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-10 col-sm-10 dkg-livejobs-adsboxRightSide specsLeftTab pl-0 pr-0">
                <Tab.Content>
                  <Tab.Pane eventKey="subJobDetails">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div
                          className="col-md-12 interviewsTable mt-3 p-0"
                          style={{ height: "calc(100vh - 202px)" }}
                        >
                          <div className="dk-ClentViewTabPane summaryTabPane">
                            <div className="dk-Adsbox">
                              <div
                                className="col-md-12 interviewsTable"
                                style={{
                                  height: "calc(100vh - 215px)",
                                  background: "#fff",
                                  border: "1px solid #ccc",
                                }}
                              >
                                <div className="col-md-12 mt-2 p-0">
                                  Overview
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="1st">
                    <div className="dk-ClentViewTabPane summaryTabPane">
                      <div className="row dk-Adsbox mr-2 ml-2">
                        <div className="col-md-12">
                          <h4
                            className="text-center dkg-edit-interview-title"
                          >
                            1st Interview{" "}
                            <button
                              type="button"
                              className="btn dk-createInterview float-right m-0"
                            >
                              Add New
                            </button>
                          </h4>
                        </div>
                        <div
                          className="col-md-12 interviewsTable mt-2 p-0"
                          style={{ height: "calc(100vh - 234px)" }}
                        >
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="test1"
                          >
                            <div className="row mr-0 ml-0 mainSpecTab">
                              <div className="col-md-2 col-sm-2 interviewSubTab dkg-edit-interview-leftSide pl-0 pr-0">
                                <Nav variant="pills" className="flex-row">
                                  <Nav.Item>
                                    <Nav.Link eventKey="test1">Test 1</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="test2">Test 2</Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </div>
                              <div className="col-md-10 col-sm-10">
                                <Tab.Content>
                                  <Tab.Pane eventKey="test1">
                                    <div className="dk-ClentViewTabPane summaryTabPane">
                                      <div className="row dk-Adsbox">
                                        <div
                                          className="col-md-12 interviewsTable"
                                          style={{
                                            height: "calc(100vh - 256px)",
                                            background: "#fff",
                                            border: "1px solid #ccc",
                                            borderLeft: "0",
                                          }}
                                        >
                                          <div className="col-md-12 mt-2">
                                            Job Details
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="test2">
                                    <div className="dk-ClentViewTabPane summaryTabPane">
                                      <div className="row dk-Adsbox">
                                        <div
                                          className="col-md-12 interviewsTable"
                                          style={{
                                            height: "calc(100vh - 256px)",
                                            background: "#fff",
                                            border: "1px solid #ccc",
                                            borderLeft: "0",
                                          }}
                                        >
                                          <div className="col-md-12 mt-2">
                                            Job Details
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </div>
                            </div>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="2nd">
                    <div className="dk-ClentViewTabPane summaryTabPane">
                      <div className="row dk-Adsbox mr-2 ml-2">
                        <div className="col-md-12">
                          <h4
                            className="text-center dkg-edit-interview-title"
                          >
                            2nd Interview{" "}
                            <button
                              type="button"
                              className="btn dk-createInterview float-right m-0"
                            >
                              Add New
                            </button>
                          </h4>
                        </div>
                        <div
                          className="col-md-12 interviewsTable mt-2 p-0"
                          style={{ height: "calc(100vh - 234px)" }}
                        >
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="test1"
                          >
                            <div className="row mr-0 ml-0 mainSpecTab">
                              <div className="col-md-2 col-sm-2 interviewSubTab dkg-edit-interview-leftSide pl-0 pr-0">
                                <Nav variant="pills" className="flex-row">
                                  <Nav.Item>
                                    <Nav.Link eventKey="test1">Test 1</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="test2">Test 2</Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </div>
                              <div className="col-md-10 col-sm-10">
                                <Tab.Content>
                                  <Tab.Pane eventKey="test1">
                                    <div className="dk-ClentViewTabPane summaryTabPane">
                                      <div className="row dk-Adsbox">
                                        <div
                                          className="col-md-12 interviewsTable"
                                          style={{
                                            height: "calc(100vh - 256px)",
                                            background: "#fff",
                                            border: "1px solid #ccc",
                                            borderLeft: "0",
                                          }}
                                        >
                                          <div className="col-md-12 mt-2">
                                            Job Details
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="test2">
                                    <div className="dk-ClentViewTabPane summaryTabPane">
                                      <div className="row dk-Adsbox">
                                        <div
                                          className="col-md-12 interviewsTable"
                                          style={{
                                            height: "calc(100vh - 256px)",
                                            background: "#fff",
                                            border: "1px solid #ccc",
                                            borderLeft: "0",
                                          }}
                                        >
                                          <div className="col-md-12 mt-2">
                                            Job Details
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </div>
                            </div>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="3rd">
                    <div className="dk-ClentViewTabPane summaryTabPane">
                      <div className="row dk-Adsbox mr-2 ml-2">
                        <div className="col-md-12">
                          <h4
                            className="text-center dkg-edit-interview-title"
                          >
                            3rd Interview{" "}
                            <button
                              type="button"
                              className="btn dk-createInterview float-right m-0"
                            >
                              Add New
                            </button>
                          </h4>
                        </div>
                        <div
                          className="col-md-12 interviewsTable mt-2 p-0"
                          style={{ height: "calc(100vh - 234px)" }}
                        >
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="test1"
                          >
                            <div className="row mr-0 ml-0 mainSpecTab">
                              <div className="col-md-2 col-sm-2 interviewSubTab dkg-edit-interview-leftSide pl-0 pr-0">
                                <Nav variant="pills" className="flex-row">
                                  <Nav.Item>
                                    <Nav.Link eventKey="test1">Test 1</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="test2">Test 2</Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </div>
                              <div className="col-md-10 col-sm-10">
                                <Tab.Content>
                                  <Tab.Pane eventKey="test1">
                                    <div className="dk-ClentViewTabPane summaryTabPane">
                                      <div className="row dk-Adsbox">
                                        <div
                                          className="col-md-12 interviewsTable"
                                          style={{
                                            height: "calc(100vh - 256px)",
                                            background: "#fff",
                                            border: "1px solid #ccc",
                                            borderLeft: "0",
                                          }}
                                        >
                                          <div className="col-md-12 mt-2">
                                            Job Details
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="test2">
                                    <div className="dk-ClentViewTabPane summaryTabPane">
                                      <div className="row dk-Adsbox">
                                        <div
                                          className="col-md-12 interviewsTable"
                                          style={{
                                            height: "calc(100vh - 256px)",
                                            background: "#fff",
                                            border: "1px solid #ccc",
                                            borderLeft: "0",
                                          }}
                                        >
                                          <div className="col-md-12 mt-2">
                                            Job Details
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </div>
                            </div>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="4th">
                    <div className="dk-ClentViewTabPane summaryTabPane">
                      <div className="row dk-Adsbox mr-2 ml-2">
                        <div className="col-md-12">
                          <h4
                            className="text-center dkg-edit-interview-title"
                          >
                            4th Interview{" "}
                            <button
                              type="button"
                              className="btn dk-createInterview float-right m-0"
                            >
                              Add New
                            </button>
                          </h4>
                        </div>
                        <div
                          className="col-md-12 interviewsTable mt-2 p-0"
                          style={{ height: "calc(100vh - 234px)" }}
                        >
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="test1"
                          >
                            <div className="row mr-0 ml-0 mainSpecTab">
                              <div className="col-md-2 col-sm-2 interviewSubTab dkg-edit-interview-leftSide pl-0 pr-0">
                                <Nav variant="pills" className="flex-row">
                                  <Nav.Item>
                                    <Nav.Link eventKey="test1">Test 1</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="test2">Test 2</Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </div>
                              <div className="col-md-10 col-sm-10">
                                <Tab.Content>
                                  <Tab.Pane eventKey="test1">
                                    <div className="dk-ClentViewTabPane summaryTabPane">
                                      <div className="row dk-Adsbox">
                                        <div
                                          className="col-md-12 interviewsTable"
                                          style={{
                                            height: "calc(100vh - 256px)",
                                            background: "#fff",
                                            border: "1px solid #ccc",
                                            borderLeft: "0",
                                          }}
                                        >
                                          <div className="col-md-12 mt-2">
                                            Job Details
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="test2">
                                    <div className="dk-ClentViewTabPane summaryTabPane">
                                      <div className="row dk-Adsbox">
                                        <div
                                          className="col-md-12 interviewsTable"
                                          style={{
                                            height: "calc(100vh - 256px)",
                                            background: "#fff",
                                            border: "1px solid #ccc",
                                            borderLeft: "0",
                                          }}
                                        >
                                          <div className="col-md-12 mt-2">
                                            Job Details
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </div>
                            </div>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="5th">
                    <div className="dk-ClentViewTabPane summaryTabPane">
                      <div className="row dk-Adsbox mr-2 ml-2">
                        <div className="col-md-12">
                          <h4
                            className="text-center dkg-edit-interview-title"
                          >
                            5th Interview{" "}
                            <button
                              type="button"
                              className="btn dk-createInterview float-right m-0"
                            >
                              Add New
                            </button>
                          </h4>
                        </div>
                        <div
                          className="col-md-12 interviewsTable mt-2 p-0"
                          style={{ height: "calc(100vh - 234px)" }}
                        >
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="test1"
                          >
                            <div className="row mr-0 ml-0 mainSpecTab">
                              <div className="col-md-2 col-sm-2 interviewSubTab dkg-edit-interview-leftSide pl-0 pr-0">
                                <Nav variant="pills" className="flex-row">
                                  <Nav.Item>
                                    <Nav.Link eventKey="test1">Test 1</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="test2">Test 2</Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </div>
                              <div className="col-md-10 col-sm-10">
                                <Tab.Content>
                                  <Tab.Pane eventKey="test1">
                                    <div className="dk-ClentViewTabPane summaryTabPane">
                                      <div className="row dk-Adsbox">
                                        <div
                                          className="col-md-12 interviewsTable"
                                          style={{
                                            height: "calc(100vh - 256px)",
                                            background: "#fff",
                                            border: "1px solid #ccc",
                                            borderLeft: "0",
                                          }}
                                        >
                                          <div className="col-md-12 mt-2">
                                            Job Details
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="test2">
                                    <div className="dk-ClentViewTabPane summaryTabPane">
                                      <div className="row dk-Adsbox">
                                        <div
                                          className="col-md-12 interviewsTable"
                                          style={{
                                            height: "calc(100vh - 256px)",
                                            background: "#fff",
                                            border: "1px solid #ccc",
                                            borderLeft: "0",
                                          }}
                                        >
                                          <div className="col-md-12 mt-2">
                                            Job Details
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </div>
                            </div>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default Interviews;
