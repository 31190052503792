import React from 'react';
import Header from "../../elements/header";
// import Navbar from '../../elements/header/navbar/mainMenu/dkglobaladmin/BusinessDevelopMenu';

const HrLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="HR" moduleLink="/dashboard/hr" linkcolor="#3c4c62" mainheaderTitle=" - Leaves" textcolor="#5c737d" />
            {/* <Navbar /> */}
            {children}
        </React.Fragment>
    )
}
export default HrLayout;
