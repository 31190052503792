import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import Submit from "../../../../ui/submitButton";
import { Link } from 'react-router-dom'

const DeleteModal = ({ deleteItem, loading, success,handleClose }) => {
   
    const {  isBudgetPerformanceDelete } = useSelector(state => state.performancedata)

    const [show, setShow] = useState(true);

    // const handleClose = () => {
    //     setShow(false);
    // }

    const handleShow = () => {
        setShow(true);
    }

    useEffect(() => {       
        if (isBudgetPerformanceDelete) {
            handleClose()
            setShow(false);
        }
    }, [isBudgetPerformanceDelete])

    return (
        <React.Fragment>  
          
            <Link className="dk-delete-btn" onClick={handleShow} >
                <i className="fa fa-trash " aria-hidden="true"></i>
            </Link>

            <Modal className="dk-mlAddNewModal dk-deleteModal" show={show} onHide={handleClose}>
                <Modal.Body className="dk-mlAddModalBody">
                    <p>Are you sure wants to Delete these records ?</p>
                    <div className="d-flex justify-content-end">
                        <button onClick={handleClose}>No</button>
                        <Submit txt="Yes" loading={loading} success={success} onClick={()=>deleteItem()} position="justify-content-center" className="dk-yesBtn" />
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default DeleteModal;