import React from 'react';
import Modal from 'react-bootstrap/Modal'
import './serchfilter.scss';
import UserFilter from './UserFilter';
import SearchinputDropdwn from './SearchinputDropdwn';

const SearchFilter = ({ hidepopup }) => {
    return (
        <>
            <Modal
                show={true}
                onHide={hidepopup}
                className="dk-srchFilterModal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="srchFilterHeader">
                    <Modal.Title id="contained-modal-title-vcenter">
                        SEARCH PROJECTS
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="srchFilterModalBody">
                    <div className="dk-searchTags">
                        <a href="#">Search Tags <span><i className="fa fa-times" aria-hidden="true"></i></span></a>
                    </div>
                    <div className="dk-searchBox">
                        <input type="text" className="form-control" placeholder="Search Keywords" id="" name="" />
                        <span><i className="fas fa-sync-alt"></i></span>
                    </div>
                    <div className="dk-searchcol">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="col-main">
                                <div className="dk-cicrleOIcon">
                                    <UserFilter />
                                </div>
                            </div>
                            <div className="dk-searchDropdown">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="dk-mialShotDrpDwn">
                                            <SearchinputDropdwn dropdwonTitle="Category" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="dk-mialShotDrpDwn">
                                            <SearchinputDropdwn dropdwonTitle="Company" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="dk-mialShotDrpDwn">
                                            <SearchinputDropdwn dropdwonTitle="Language" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="dk-mialShotDrpDwn">
                                            <SearchinputDropdwn dropdwonTitle="Role Type" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default SearchFilter
