import React from "react";
import "./casedrpdwn1.scss";
import Dropdown from "react-bootstrap/Dropdown";

const Status = ({
  caseStatus,
  statusBgColour,
  statusTxColour,
  candidateId,
  list,
  updateProfile,
  errorClass,
  colKey,
}) => {


  return (
    <>
      <div className="dk-caseDrpDwn">
        <Dropdown className={errorClass}>
          <Dropdown.Toggle
            variant=""
            className="caseStstoggle2"
            style={{
              backgroundColor: statusBgColour,
              borderColor: statusBgColour,
              color: statusTxColour,
            }}

          >
            {caseStatus}
          </Dropdown.Toggle>
          <Dropdown.Menu className="caseStatusDropMenu">
            {list.map((statusItem, index) => (
              <Dropdown.Item
                key={`profile-case-status-id${index}`}
                href="#"
                style={{
                  backgroundColor: statusItem.bgColor,
                  color: statusItem.textColor,
                }}
                data-id={candidateId}
                data-value={statusItem.value}
                data-bgcolor={statusItem.bgColor}
                data-textcolor={statusItem.textColor}
                data-key={colKey}
                onClick={updateProfile}
              >
                {statusItem.value}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default Status;
