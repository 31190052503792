import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import FaqAccordion from "./Accordion";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategory,
  // getQueAns,
  // getOnClickQuesAns,
  // clearState
} from "../../../../../../slice/faqs/newFaqSlice";
//import $ from "jquery";

const Overview = ({ jobId }) => {
  const dispatch = useDispatch();
  const {
    categoryList, iscat
  } = useSelector((state) => state.faqData);

  const [isCompanyName, isSetCompanyName] = useState("");
  const [faqqData, isFaqdata] = useState([]);
  const [faqtitle, setFaqtitle] = useState("");


  useEffect(() => {
    if (!iscat && categoryList && categoryList.length > 0) {
      isFaqdata(categoryList)
      setFaqtitle(categoryList[0].companyName)
    }
  }, [iscat])

  useEffect(() => {
    async function fetchData() {
      try {
        dispatch(getCategory({ id: jobId }))
      } catch (err) {
        // dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <div className="dkg-payment-faq-pageCon">
        <div className="dkg-faq-catgory-activeTitle">
          {faqtitle}
        </div>
        <div className="row dkg-candprescreen-mainRow">
          <div className="col-md-12 p-0 dkg-clientlist-faq-mainCon">
            <Tab.Container defaultActiveKey="first0" >
              <div className="row m-0 dkg-payment-faq-tab-mainCon">
                <div className="dkg-faq-catgory-activeTitle">
                  {isCompanyName}
                </div>
                <div className="col-md-2 pl-0 dkg-payment-faq-tabCon dkg-faq-sideMenuCon-234">
                  <Nav
                    variant="pills"
                    className="flex-column dkg-payment-faq-tab-navpills"
                  >
                    {
                      faqqData.filter((item) => item.status === "Active")
                        .map((cat, index) => {
                          return (
                            <>
                              <Nav.Item className="dkg-payment-faq-tab-nav-item">
                                {
                                  <Nav.Link eventKey={`first` + index} className={`dkg-payment-faq-tab-navlink`} onClick={() => setFaqtitle(cat.companyName)}>{cat.name}</Nav.Link>
                                }
                              </Nav.Item>
                            </>
                          );
                        })

                    }
                  </Nav>
                </div>
                <div className="col-md-10 dkg-payment-faq-tabCon-10 dkg-faq-rightMenuCon-234 pr-0">
                  {
                    <Tab.Content className="dkg-payment-faq-tabcontent ddd">
                      {
                        faqqData.filter((item) => item.status === "Active")
                          .map((cat, index) => {
                            return (
                              <Tab.Pane eventKey={`first` + index}>
                                <FaqAccordion faqdata={cat.quesAns} />
                              </Tab.Pane>
                            );
                          })

                      }
                    </Tab.Content>
                  }
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
