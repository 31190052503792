import React from "react";
import { Route, Link } from "react-router-dom";

const LicenseSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/my-files/licenses/license1" className={`${(segment3 == 'license1') ? 'active' : ''}`}>License 1</Link></li>
                        <li><Link to="#/my-files/licenses/license2" className={`${(segment3 == 'license2') ? 'active' : ''}`}>License 2</Link></li>
                        <li><Link to="#/my-files/licenses/license3" className={`${(segment3 == 'license3') ? 'active' : ''}`}>License 3</Link></li>
                        <li><Link to="#/my-files/licenses/license4" className={`${(segment3 == 'license4') ? 'active' : ''}`}>License 4</Link></li>
                        <li><Link to="#/my-files/licenses/license5" className={`${(segment3 == 'license5') ? 'active' : ''}`}>License 5</Link></li>
                        <li><Link to="#/my-files/licenses/license6" className={`${(segment3 == 'license6') ? 'active' : ''}`}>License 6</Link></li>
                        <li><Link to="#/my-files/licenses/license7" className={`${(segment3 == 'license7') ? 'active' : ''}`}>License 7</Link></li>
                        <li><Link to="#/my-files/licenses/license8" className={`${(segment3 == 'license8') ? 'active' : ''}`}>License 8</Link></li>
                        <li><Link to="#/my-files/licenses/license9" className={`${(segment3 == 'license9') ? 'active' : ''}`}>License 9</Link></li>
                        <li><Link to="#/my-files/licenses/license10" className={`${(segment3 == 'license10') ? 'active' : ''}`}>License 10</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default LicenseSubmenu;
