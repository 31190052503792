import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav } from "react-bootstrap";
import Step1 from "./Step1";
import {
  getCategories,
  getSubCategories,
  getdefaultSubCategories,
} from "../../../../../../../slice/relocations";
import { clientscomp } from "../../../../../../../slice/candidatePrecreeningForm/scripts";
import { showError } from "../../../../../../utils/messages/messageSlice";
import $ from "jquery";

const ClientRelocaton = () => {
  const dispatch = useDispatch();
  const { companyDetails } = useSelector((state) => state.companies);
  const { clientAllData } = useSelector((state) => state.screeningFormScript);
  const { isSuccess, isLoading, categoryReqList } = useSelector(
    (state) => state.clientRelocation
  );
  const [data, setData] = useState([]);
  const [catActiveRow, setCatActiveRow] = useState("active");
  const [defaultOverview, setDefaultOverview] = useState(true);
  const [othersTab, setOthersTab] = useState(false);

  useEffect(() => {
    if (!isLoading && categoryReqList && categoryReqList.length > 0) {
      setData(categoryReqList);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        setOthersTab(false);
        await dispatch(
          clientscomp({
            id: companyDetails.clientId,
            clientname: companyDetails.name,
          })
        );
        await dispatch(getCategories({}));
        await dispatch(getdefaultSubCategories({ keys: "Overview" }));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  const selectCat = async (catid, catName) => {
    if (catName == "Overview") {
      setCatActiveRow("active");
      setDefaultOverview(true);
      setOthersTab(false);
      await dispatch(getSubCategories({ categoryId: catid, keys: "Overview" }));
    } else {
      setCatActiveRow("");
      setDefaultOverview(false);
      setOthersTab(true);
      $(".tab_1").addClass("activeSubTab");
      $(".tab_2").removeClass("activeSubTab");
      $(".tab_3").removeClass("activeSubTab");
      $(".addnewClass").addClass("activesSubTab");
      await dispatch(getSubCategories({ categoryId: catid, keys: "details" }));
    }
  };

  const result = data.filter((element) => {
    const categoryName = element.categoryName;
    const activeProjectArray = clientAllData[7].some(
      (item) => item.isShowInJob === 0 && item.relocationName === categoryName
    );
    return activeProjectArray;
  });

  return (
    <>
      <div
        className="dkg-payment-qt-pageCon dkg-comman-training-pageCon dkg-comman-training-234"
        style={{ marginTop: "160px" }}
      >
        <Tab.Container defaultActiveKey="question-pt-new-overview">
          <div className="row m-0 dkg-payment-qt-tab-mainCon">
            <div className="col-md-2 pl-0 dkg-payment-qt-tabCon">
              <Nav
                variant="pills"
                className="flex-column dkg-payment-qt-tab-navpills"
              >
                {result.map((item, i) =>
                  i === 0 ? (
                    <>
                      <Nav.Item
                        className="dkg-payment-qt-tab-nav-item"
                        key={i}
                        onClick={() => selectCat(item.id, item.categoryName)}
                      >
                        <Nav.Link
                          eventKey={item.categoryName + i}
                          className={
                            `dkg-payment-qt-tab-navlink addCattClass ` +
                            catActiveRow
                          }
                        >
                          {item.categoryName}
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  ) : (
                    <>
                      <Nav.Item
                        className="dkg-payment-qt-tab-nav-item"
                        key={i}
                        onClick={() => selectCat(item.id, item.categoryName)}
                      >
                        <Nav.Link
                          eventKey={item.categoryName + i}
                          className="dkg-payment-qt-tab-navlink"
                        >
                          {item.categoryName}
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  )
                )}
              </Nav>
            </div>
            {defaultOverview === true ? (
              <>
                <div className="col-md-10 dkg-payment-rt-tabCon-10 ">
                  <Tab.Content className="dkg-payment-qt-tabcontent">
                    {result.map((item, i) =>
                      item.categoryName === "Overview" ? (
                        <Tab.Pane
                          eventKey={item.categoryName + i}
                          className={`dkg-payment-qt-tabpane active show`}
                        >
                          <Step1
                            pagetitle={item.categoryName}
                            catIdds={item.id}
                          />
                        </Tab.Pane>
                      ) : null
                    )}
                  </Tab.Content>
                </div>
              </>
            ) : null}

            {othersTab === true ? (
              <>
                <div className="col-md-10 dkg-payment-rt-tabCon-10 ">
                  <Tab.Content className="dkg-payment-qt-tabcontent">
                    {result.map((item, i) => (
                      <Tab.Pane
                        eventKey={item.categoryName + i}
                        className={`dkg-payment-qt-tabpane`}
                      >
                        <Step1
                          pagetitle={item.categoryName}
                          catIdds={item.id}
                        />
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </div>
              </>
            ) : null}
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default ClientRelocaton;
