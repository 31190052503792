import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./dbarchive.scss";
import { Dropdown } from 'react-bootstrap';
import DummySelectPiker from '../../../ui/SelectPicker/DummySelectPiker'
import Recuiter from '../../../../assets/images/deepak.png'
import { ImUsers, ImFileText2 } from "react-icons/im";
import RightModalFilter from "./RightModalFilter";
import UserFilter from './userfilter/UserFilter';
import FlagFilter from './flagfilter/FlagFilter';
import CheckboxDummy from "./checkbox/CheckboxDummy";
import CaseStatusDropDwn from './dropdown/CaseStatusDropDwn';
import StatusTransDropDwn from './dropdown/StatusTransDropDwn';
import PipelinePagination from './pagination/PipelinePagination';
// import DuplicateCheckModal from './modal/DuplicateCheck';
import UserfilterNumber from "./userfilter/UserfilterNumber";
import FlagfilterNumber from './flagfilter/FlagfilterNumber';
// import CandidateProfileModal from './modal/candidateprofile/CandidateProfile';
import CommentPopup from '../../../ui/Modal/comment/CommentPopup'
import LeftMenuUserNumber from "./userfilter/LeftMenuUserNumber";


const NewCV = () => {
    const [isRightFilter, setIsRightFilter] = useState(false);
    // const [isDuplicateCheck, setIsDuplicateCheck] = useState(false);
    const [isCandiadateProfile, setIsCandiadateProfile] = useState(false);
    const [isCommentOpen, setIsCommentOpen] = useState(false);
    const showrightFilter = () => {
        setIsRightFilter(true)
    }
    const hiderightFooter = () => {
        setIsRightFilter(false)
    }
    // const showDuplicateCheckModal = () => {
    //     setIsDuplicateCheck(true);
    // }
    // const hideDuplicateCheckModal = () => {
    //     setIsDuplicateCheck(false);
    // }
    // const showCandidateProfileModal = () => {
    //     setIsCandiadateProfile(true);
    // }
    // const hideCandidateProfileModal = () => {
    //     setIsCandiadateProfile(false);
    // }
    const showCommentModal = () => {
        setIsCommentOpen(true);
    }
    const hideCommentModal = () => {
        setIsCommentOpen(false);
    }
    const status = [
        {
            key: 'newCV', value: 'new_cv', description: '2', text: 'New CV',
            style: {
                color: '#fff',
                backgroundColor: '#5c737d'
            }
        },
        {
            key: 'selected', value: 'selected', description: '0', text: 'Selected',
            style: {
                color: '#fff',
                backgroundColor: '#55496b'
            }
        },
        {
            key: 'dkg_process', value: 'dkg_process', description: '2', text: 'DKG Process',
            style: {
                color: '#fff',
                backgroundColor: '#409a9b'
            }
        },
        {
            key: 'client_process', value: 'client_process', description: '2', text: 'Client Process',
            style: {
                color: '#fff',
                backgroundColor: '#1b6867'
            }
        },
        {
            key: 'joboffer', value: 'joboffer', description: '2', text: 'Job Offer',
            style: {
                color: '#fff',
                backgroundColor: '#599ad5'
            }
        },
        {
            key: 'placement', value: 'placement', description: '2', text: 'Placement',
            style: {
                color: '#fff',
                backgroundColor: '#4267b2'
            }
        },
        {
            key: 'onHold', value: 'onHold', description: '2', text: 'On HOld',
            style: {
                color: '#fff',
                backgroundColor: '#7f5347'
            }
        },
        {
            key: 'refOffer', value: 'refusedOfffer', description: '2', text: 'Refused Offer',
            style: {
                color: '#fff',
                backgroundColor: '#a84d4d'
            }
        },
        {
            key: 'credit', value: 'credit', description: '2', text: 'Credit',
            style: {
                color: '#fff',
                backgroundColor: '#df744a'
            }
        },
        {
            key: 'closed', value: 'closed', description: '2', text: 'Closed',
            style: {
                color: '#fff',
                backgroundColor: '#e2445c'
            }
        },
        {
            key: 'escalated', value: 'escalated', description: '2', text: 'Escalated',
            style: {
                color: '#fff',
                backgroundColor: '#670004'
            }
        },
    ]

    return (
        <>
            {
                (isRightFilter) ? <RightModalFilter onHide={hiderightFooter} /> : null
            }
            {/* {
                (isDuplicateCheck) ? <DuplicateCheckModal onHide={hideDuplicateCheckModal} /> : null
            } */}
            {/* {
                (isCandiadateProfile) ? <CandidateProfileModal hidePopup={hideCandidateProfileModal} /> : null
            } */}
            {
                (isCommentOpen) ? <CommentPopup hideCommentPopup={hideCommentModal} /> : null
            }
            <div className="container-fluid dk-dbarchiveMain">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row dk-dbarchivePageHead">
                            <div className="col-md-4">
                                <div className="dk-dbarchiveLeft">
                                    <div className="dk-mailshotsSearch mr-2">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search" />
                                            <div className="input-group-append">
                                                <button className="btn btn-secondary" type="button">
                                                    <i className="fa fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={showrightFilter} className="dk-cricleBtn mr-2">
                                        <i className="fa fa-filter"></i>
                                    </button>
                                    <div className="filterIcon mr-2">
                                        <FlagfilterNumber />
                                    </div>
                                    <UserfilterNumber />
                                </div>
                            </div>
                            <div className="col-md-4 pl-0 pr-0">
                                <div className="dk-dbarchiveCetre">
                                    <h2>ARCHIVED RECORDS</h2>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="dk-pipelineright">
                                    <div className="dk-btnCicleCon">
                                        <div className="mr-2">
                                            <button className="dk-reoderBtn">
                                                <i className="fas fa-sync-alt"></i>
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <LeftMenuUserNumber />
                                        </div>
                                        {/* <div className="mr-2">
                                            <button className="dk-reoderBtn " onClick={showDuplicateCheckModal}>
                                                <ImUsers />
                                            </button>
                                        </div> */}
                                    </div>
                                    <div className="dk-qulaifyRightFilter">
                                        <DummySelectPiker options={status} placeholder="Status" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dk-dbarchiveTableCon table-responsive">
                            <table className="table dk-dbarchiveTable">
                                <thead>
                                    <tr>
                                        <th>ID & Preview</th>
                                        <th>Created Date</th>
                                        <th>AM</th>
                                        <th>Rec</th>
                                        <th>Category</th>
                                        <th>Candidate Name</th>
                                        <th>Company</th>
                                        <th>Job Language</th>
                                        <th>Case Status</th>
                                        <th>Case Progress</th>
                                        <th>Case Stage</th>
                                        <th>Comment</th>
                                        <th>Progress</th>
                                        <th>Full Fee</th>
                                        <th>Refuse/Cr</th>
                                        <th>Bonus</th>
                                        <th>Final Fee</th>
                                        <th>Project</th>
                                        <th>Role Type</th>
                                        <th>CV Source</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="pipleFilterCols">
                                                <span className="dk-chevron-rightIcon">
                                                    <i className="fa fa-chevron-right" ></i>
                                                    38395
                                                </span>
                                                <span className="dk-checkinputIcon">
                                                    <CheckboxDummy />
                                                </span>
                                                <span className="openFlagOptions">
                                                    <div className="dk-pipeFlagFilterCon dk-circleDropdown">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" className="dk-fliterbtn">
                                                                <span className=""></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dk-pipleuserFilterMenu">
                                                                <div className="d-flex">
                                                                    <Link to="#" className="gray"></Link>
                                                                    <Link to="#" className="yellow"></Link>
                                                                    <Link to="#" className="red"></Link>
                                                                    <Link to="#" className="green"></Link>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </span>
                                                <span className="openFlagOptions">
                                                    <FlagFilter />
                                                </span>
                                            </div>
                                        </td>
                                        <td>15-Oct, 16:08</td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>None</td>
                                        <td>Katarzyna Jarzebinska</td>
                                        <td>Tech Mahindra Lisbon</td>
                                        <td>English</td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Closed</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Job Spec Sent</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Not Reachable</div>
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon" onClick={showCommentModal}>0</span>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="progressChart">
                                                <i className="fas fa-chart-line"></i>
                                            </span>
                                        </td>
                                        <td>1400</td>
                                        <td>400</td>
                                        <td>300</td>
                                        <td>0</td>
                                        <td>Confidential</td>
                                        <td>QM</td>
                                        <td>Indeed</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="pipleFilterCols">
                                                <span className="dk-chevron-rightIcon">
                                                    <i className="fa fa-chevron-right"></i>
                                                    38395
                                                </span>
                                                <span className="dk-checkinputIcon">
                                                    <CheckboxDummy />
                                                </span>
                                                <span className="openFlagOptions">
                                                    <div className="dk-pipeFlagFilterCon dk-circleDropdown">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" className="dk-fliterbtn">
                                                                <span className=""></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dk-pipleuserFilterMenu">
                                                                <div className="d-flex">
                                                                    <Link to="#" className="gray"></Link>
                                                                    <Link to="#" className="yellow"></Link>
                                                                    <Link to="#" className="red"></Link>
                                                                    <Link to="#" className="green"></Link>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </span>
                                                <span className="openFlagOptions">
                                                    <FlagFilter />
                                                </span>
                                            </div>
                                        </td>
                                        <td>15-Oct, 16:08</td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>None</td>
                                        <td>Katarzyna Jarzebinska</td>
                                        <td>Tech Mahindra Lisbon</td>
                                        <td>English</td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Closed</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Job Spec Sent</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Not Reachable</div>
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon" onClick={showCommentModal}>0</span>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="progressChart">
                                                <i className="fas fa-chart-line"></i>
                                            </span>
                                        </td>
                                        <td>1400</td>
                                        <td>400</td>
                                        <td>300</td>
                                        <td>0</td>
                                        <td>Confidential</td>
                                        <td>QM</td>
                                        <td>Indeed</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="pipleFilterCols">
                                                <span className="dk-chevron-rightIcon">
                                                    <i className="fa fa-chevron-right"></i>
                                                    38395
                                                </span>
                                                <span className="dk-checkinputIcon">
                                                    <CheckboxDummy />
                                                </span>
                                                <span className="openFlagOptions">
                                                    <div className="dk-pipeFlagFilterCon dk-circleDropdown">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" className="dk-fliterbtn">
                                                                <span className=""></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dk-pipleuserFilterMenu">
                                                                <div className="d-flex">
                                                                    <Link to="#" className="gray"></Link>
                                                                    <Link to="#" className="yellow"></Link>
                                                                    <Link to="#" className="red"></Link>
                                                                    <Link to="#" className="green"></Link>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </span>
                                                <span className="openFlagOptions">
                                                    <FlagFilter />
                                                </span>
                                            </div>
                                        </td>
                                        <td>15-Oct, 16:08</td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>None</td>
                                        <td>Katarzyna Jarzebinska</td>
                                        <td>Tech Mahindra Lisbon</td>
                                        <td>English</td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Closed</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Job Spec Sent</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Not Reachable</div>
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon" onClick={showCommentModal}>0</span>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="progressChart">
                                                <i className="fas fa-chart-line"></i>
                                            </span>
                                        </td>
                                        <td>1400</td>
                                        <td>400</td>
                                        <td>300</td>
                                        <td>0</td>
                                        <td>Confidential</td>
                                        <td>QM</td>
                                        <td>Indeed</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="pipleFilterCols">
                                                <span className="dk-chevron-rightIcon">
                                                    <i className="fa fa-chevron-right"></i>
                                                    38395
                                                </span>
                                                <span className="dk-checkinputIcon">
                                                    <CheckboxDummy />
                                                </span>
                                                <span className="openFlagOptions">
                                                    <div className="dk-pipeFlagFilterCon dk-circleDropdown">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" className="dk-fliterbtn">
                                                                <span className=""></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dk-pipleuserFilterMenu">
                                                                <div className="d-flex">
                                                                    <Link to="#" className="gray"></Link>
                                                                    <Link to="#" className="yellow"></Link>
                                                                    <Link to="#" className="red"></Link>
                                                                    <Link to="#" className="green"></Link>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </span>
                                                <span className="openFlagOptions">
                                                    <FlagFilter />
                                                </span>
                                            </div>
                                        </td>
                                        <td>15-Oct, 16:08</td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>None</td>
                                        <td>Katarzyna Jarzebinska</td>
                                        <td>Tech Mahindra Lisbon</td>
                                        <td>English</td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Closed</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Job Spec Sent</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Not Reachable</div>
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon" onClick={showCommentModal}>0</span>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="progressChart">
                                                <i className="fas fa-chart-line"></i>
                                            </span>
                                        </td>
                                        <td>1400</td>
                                        <td>400</td>
                                        <td>300</td>
                                        <td>0</td>
                                        <td>Confidential</td>
                                        <td>QM</td>
                                        <td>Indeed</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="pipleFilterCols">
                                                <span className="dk-chevron-rightIcon">
                                                    <i className="fa fa-chevron-right"></i>
                                                    38395
                                                </span>
                                                <span className="dk-checkinputIcon">
                                                    <CheckboxDummy />
                                                </span>
                                                <span className="openFlagOptions">
                                                    <div className="dk-pipeFlagFilterCon dk-circleDropdown">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" className="dk-fliterbtn">
                                                                <span className=""></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dk-pipleuserFilterMenu">
                                                                <div className="d-flex">
                                                                    <Link to="#" className="gray"></Link>
                                                                    <Link to="#" className="yellow"></Link>
                                                                    <Link to="#" className="red"></Link>
                                                                    <Link to="#" className="green"></Link>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </span>
                                                <span className="openFlagOptions">
                                                    <FlagFilter />
                                                </span>
                                            </div>
                                        </td>
                                        <td>15-Oct, 16:08</td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>None</td>
                                        <td>Katarzyna Jarzebinska</td>
                                        <td>Tech Mahindra Lisbon</td>
                                        <td>English</td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Closed</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Job Spec Sent</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Not Reachable</div>
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon" onClick={showCommentModal}>0</span>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="progressChart">
                                                <i className="fas fa-chart-line"></i>
                                            </span>
                                        </td>
                                        <td>1400</td>
                                        <td>400</td>
                                        <td>300</td>
                                        <td>0</td>
                                        <td>Confidential</td>
                                        <td>QM</td>
                                        <td>Indeed</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="pipleFilterCols">
                                                <span className="dk-chevron-rightIcon">
                                                    <i className="fa fa-chevron-right"></i>
                                                    38395
                                                </span>
                                                <span className="dk-checkinputIcon">
                                                    <CheckboxDummy />
                                                </span>
                                                <span className="openFlagOptions">
                                                    <div className="dk-pipeFlagFilterCon dk-circleDropdown">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" className="dk-fliterbtn">
                                                                <span className=""></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dk-pipleuserFilterMenu">
                                                                <div className="d-flex">
                                                                    <Link to="#" className="gray"></Link>
                                                                    <Link to="#" className="yellow"></Link>
                                                                    <Link to="#" className="red"></Link>
                                                                    <Link to="#" className="green"></Link>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </span>
                                                <span className="openFlagOptions">
                                                    <FlagFilter />
                                                </span>
                                            </div>
                                        </td>
                                        <td>15-Oct, 16:08</td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>None</td>
                                        <td>Katarzyna Jarzebinska</td>
                                        <td>Tech Mahindra Lisbon</td>
                                        <td>English</td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Closed</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Job Spec Sent</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Not Reachable</div>
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon" onClick={showCommentModal}>0</span>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="progressChart">
                                                <i className="fas fa-chart-line"></i>
                                            </span>
                                        </td>
                                        <td>1400</td>
                                        <td>400</td>
                                        <td>300</td>
                                        <td>0</td>
                                        <td>Confidential</td>
                                        <td>QM</td>
                                        <td>Indeed</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="pipleFilterCols">
                                                <span className="dk-chevron-rightIcon">
                                                    <i className="fa fa-chevron-right"></i>
                                                    38395
                                                </span>
                                                <span className="dk-checkinputIcon">
                                                    <CheckboxDummy />
                                                </span>
                                                <span className="openFlagOptions">
                                                    <div className="dk-pipeFlagFilterCon dk-circleDropdown">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" className="dk-fliterbtn">
                                                                <span className=""></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dk-pipleuserFilterMenu">
                                                                <div className="d-flex">
                                                                    <Link to="#" className="gray"></Link>
                                                                    <Link to="#" className="yellow"></Link>
                                                                    <Link to="#" className="red"></Link>
                                                                    <Link to="#" className="green"></Link>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </span>
                                                <span className="openFlagOptions">
                                                    <FlagFilter />
                                                </span>
                                            </div>
                                        </td>
                                        <td>15-Oct, 16:08</td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>None</td>
                                        <td>Katarzyna Jarzebinska</td>
                                        <td>Tech Mahindra Lisbon</td>
                                        <td>English</td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Closed</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Job Spec Sent</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Not Reachable</div>
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon" onClick={showCommentModal}>0</span>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="progressChart">
                                                <i className="fas fa-chart-line"></i>
                                            </span>
                                        </td>
                                        <td>1400</td>
                                        <td>400</td>
                                        <td>300</td>
                                        <td>0</td>
                                        <td>Confidential</td>
                                        <td>QM</td>
                                        <td>Indeed</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="pipleFilterCols">
                                                <span className="dk-chevron-rightIcon">
                                                    <i className="fa fa-chevron-right"></i>
                                                    38395
                                                </span>
                                                <span className="dk-checkinputIcon">
                                                    <CheckboxDummy />
                                                </span>
                                                <span className="openFlagOptions">
                                                    <div className="dk-pipeFlagFilterCon dk-circleDropdown">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" className="dk-fliterbtn">
                                                                <span className=""></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dk-pipleuserFilterMenu">
                                                                <div className="d-flex">
                                                                    <Link to="#" className="gray"></Link>
                                                                    <Link to="#" className="yellow"></Link>
                                                                    <Link to="#" className="red"></Link>
                                                                    <Link to="#" className="green"></Link>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </span>
                                                <span className="openFlagOptions">
                                                    <FlagFilter />
                                                </span>
                                            </div>
                                        </td>
                                        <td>15-Oct, 16:08</td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>None</td>
                                        <td>Katarzyna Jarzebinska</td>
                                        <td>Tech Mahindra Lisbon</td>
                                        <td>English</td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Closed</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Job Spec Sent</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Not Reachable</div>
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon" onClick={showCommentModal}>0</span>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="progressChart">
                                                <i className="fas fa-chart-line"></i>
                                            </span>
                                        </td>
                                        <td>1400</td>
                                        <td>400</td>
                                        <td>300</td>
                                        <td>0</td>
                                        <td>Confidential</td>
                                        <td>QM</td>
                                        <td>Indeed</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="pipleFilterCols">
                                                <span className="dk-chevron-rightIcon">
                                                    <i className="fa fa-chevron-right"></i>
                                                    38395
                                                </span>
                                                <span className="dk-checkinputIcon">
                                                    <CheckboxDummy />
                                                </span>
                                                <span className="openFlagOptions">
                                                    <div className="dk-pipeFlagFilterCon dk-circleDropdown">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" className="dk-fliterbtn">
                                                                <span className=""></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dk-pipleuserFilterMenu">
                                                                <div className="d-flex">
                                                                    <Link to="#" className="gray"></Link>
                                                                    <Link to="#" className="yellow"></Link>
                                                                    <Link to="#" className="red"></Link>
                                                                    <Link to="#" className="green"></Link>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </span>
                                                <span className="openFlagOptions">
                                                    <FlagFilter />
                                                </span>
                                            </div>
                                        </td>
                                        <td>15-Oct, 16:08</td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>None</td>
                                        <td>Katarzyna Jarzebinska</td>
                                        <td>Tech Mahindra Lisbon</td>
                                        <td>English</td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Closed</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Job Spec Sent</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Not Reachable</div>
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon" onClick={showCommentModal}>0</span>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="progressChart">
                                                <i className="fas fa-chart-line"></i>
                                            </span>
                                        </td>
                                        <td>1400</td>
                                        <td>400</td>
                                        <td>300</td>
                                        <td>0</td>
                                        <td>Confidential</td>
                                        <td>QM</td>
                                        <td>Indeed</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="pipleFilterCols">
                                                <span className="dk-chevron-rightIcon">
                                                    <i className="fa fa-chevron-right"></i>
                                                    38395
                                                </span>
                                                <span className="dk-checkinputIcon">
                                                    <CheckboxDummy />
                                                </span>
                                                <span className="openFlagOptions">
                                                    <div className="dk-pipeFlagFilterCon dk-circleDropdown">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" className="dk-fliterbtn">
                                                                <span className=""></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dk-pipleuserFilterMenu">
                                                                <div className="d-flex">
                                                                    <Link to="#" className="gray"></Link>
                                                                    <Link to="#" className="yellow"></Link>
                                                                    <Link to="#" className="red"></Link>
                                                                    <Link to="#" className="green"></Link>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </span>
                                                <span className="openFlagOptions">
                                                    <FlagFilter />
                                                </span>
                                            </div>
                                        </td>
                                        <td>15-Oct, 16:08</td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>None</td>
                                        <td>Katarzyna Jarzebinska</td>
                                        <td>Tech Mahindra Lisbon</td>
                                        <td>English</td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Closed</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Job Spec Sent</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Not Reachable</div>
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon" onClick={showCommentModal}>0</span>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="progressChart">
                                                <i className="fas fa-chart-line"></i>
                                            </span>
                                        </td>
                                        <td>1400</td>
                                        <td>400</td>
                                        <td>300</td>
                                        <td>0</td>
                                        <td>Confidential</td>
                                        <td>QM</td>
                                        <td>Indeed</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="pipleFilterCols">
                                                <span className="dk-chevron-rightIcon">
                                                    <i className="fa fa-chevron-right"></i>
                                                    38395
                                                </span>
                                                <span className="dk-checkinputIcon">
                                                    <CheckboxDummy />
                                                </span>
                                                <span className="openFlagOptions">
                                                    <div className="dk-pipeFlagFilterCon dk-circleDropdown">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" className="dk-fliterbtn">
                                                                <span className=""></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dk-pipleuserFilterMenu">
                                                                <div className="d-flex">
                                                                    <Link to="#" className="gray"></Link>
                                                                    <Link to="#" className="yellow"></Link>
                                                                    <Link to="#" className="red"></Link>
                                                                    <Link to="#" className="green"></Link>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </span>
                                                <span className="openFlagOptions">
                                                    <FlagFilter />
                                                </span>
                                            </div>
                                        </td>
                                        <td>15-Oct, 16:08</td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>None</td>
                                        <td>Katarzyna Jarzebinska</td>
                                        <td>Tech Mahindra Lisbon</td>
                                        <td>English</td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Closed</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Job Spec Sent</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Not Reachable</div>
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon" onClick={showCommentModal}>0</span>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="progressChart">
                                                <i className="fas fa-chart-line"></i>
                                            </span>
                                        </td>
                                        <td>1400</td>
                                        <td>400</td>
                                        <td>300</td>
                                        <td>0</td>
                                        <td>Confidential</td>
                                        <td>QM</td>
                                        <td>Indeed</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="pipleFilterCols">
                                                <span className="dk-chevron-rightIcon">
                                                    <i className="fa fa-chevron-right"></i>
                                                    38395
                                                </span>
                                                <span className="dk-checkinputIcon">
                                                    <CheckboxDummy />
                                                </span>
                                                <span className="openFlagOptions">
                                                    <div className="dk-pipeFlagFilterCon dk-circleDropdown">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" className="dk-fliterbtn">
                                                                <span className=""></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dk-pipleuserFilterMenu">
                                                                <div className="d-flex">
                                                                    <Link to="#" className="gray"></Link>
                                                                    <Link to="#" className="yellow"></Link>
                                                                    <Link to="#" className="red"></Link>
                                                                    <Link to="#" className="green"></Link>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </span>
                                                <span className="openFlagOptions">
                                                    <FlagFilter />
                                                </span>
                                            </div>
                                        </td>
                                        <td>15-Oct, 16:08</td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>None</td>
                                        <td>Katarzyna Jarzebinska</td>
                                        <td>Tech Mahindra Lisbon</td>
                                        <td>English</td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Closed</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Job Spec Sent</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Not Reachable</div>
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon" onClick={showCommentModal}>0</span>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="progressChart">
                                                <i className="fas fa-chart-line"></i>
                                            </span>
                                        </td>
                                        <td>1400</td>
                                        <td>400</td>
                                        <td>300</td>
                                        <td>0</td>
                                        <td>Confidential</td>
                                        <td>QM</td>
                                        <td>Indeed</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="pipleFilterCols">
                                                <span className="dk-chevron-rightIcon">
                                                    <i className="fa fa-chevron-right"></i>
                                                    38395
                                                </span>
                                                <span className="dk-checkinputIcon">
                                                    <CheckboxDummy />
                                                </span>
                                                <span className="openFlagOptions">
                                                    <div className="dk-pipeFlagFilterCon dk-circleDropdown">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" className="dk-fliterbtn">
                                                                <span className=""></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dk-pipleuserFilterMenu">
                                                                <div className="d-flex">
                                                                    <Link to="#" className="gray"></Link>
                                                                    <Link to="#" className="yellow"></Link>
                                                                    <Link to="#" className="red"></Link>
                                                                    <Link to="#" className="green"></Link>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </span>
                                                <span className="openFlagOptions">
                                                    <FlagFilter />
                                                </span>
                                            </div>
                                        </td>
                                        <td>15-Oct, 16:08</td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-tableImgCricle">
                                                <UserFilter />
                                            </div>
                                        </td>
                                        <td>None</td>
                                        <td>Katarzyna Jarzebinska</td>
                                        <td>Tech Mahindra Lisbon</td>
                                        <td>English</td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Closed</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Job Spec Sent</div>
                                        </td>
                                        <td className="caseDiv">
                                            <div style={{ backgroundColor: "rgb(218, 61, 94)" }}>Not Reachable</div>
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon" onClick={showCommentModal}>0</span>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="progressChart">
                                                <i className="fas fa-chart-line"></i>
                                            </span>
                                        </td>
                                        <td>1400</td>
                                        <td>400</td>
                                        <td>300</td>
                                        <td>0</td>
                                        <td>Confidential</td>
                                        <td>QM</td>
                                        <td>Indeed</td>
                                    </tr>
                                </tbody>
                                <tfoot className="newCVtFoot">
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>15360 €</td>
                                        <td>15360 €</td>
                                        <td>0 €</td>
                                        <td>0 €</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <PipelinePagination />
        </>
    )
}

export default NewCV;
