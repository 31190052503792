import React from 'react';
import NativeDatePicker from '../../../ui/nativedatepicker/DatePicker';
import './projectsummary.scss';

const ProjectSummary = () => {
    return (
        <>
            <div className="dk-projectsSummary-main">
                <div className="dk-projectsSummary">
                    <div className="dk-projectSummary-head">
                        <div className="d-flex">
                            <div className="dk-summaryDate-filter">
                                <label htmlFor="">From</label>
                                <NativeDatePicker />
                            </div>
                            <div className="dk-summaryDate-filter">
                                <label htmlFor="">To</label>
                                <NativeDatePicker />
                            </div>
                            <div className="dk-summaryDate-filterBtn">
                                <label htmlFor=""></label>
                                <button>Go</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="dk-projectSummaryCols">
                                <div className="title">DEEPAK</div>
                                <div className="dk-projectSummary-panel">

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dk-projectSummaryCols">
                                <div className="title">MARIANA</div>
                                <div className="dk-projectSummary-panel">

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dk-projectSummaryCols">
                                <div className="title">ELIS</div>
                                <div className="dk-projectSummary-panel">

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dk-projectSummaryCols">
                                <div className="title">NEW HIRE</div>
                                <div className="dk-projectSummary-panel">

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dk-projectSummaryCols">
                                <div className="title">NEW HIRE</div>
                                <div className="dk-projectSummary-panel">

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dk-projectSummaryCols">
                                <div className="title">NEW HIRE</div>
                                <div className="dk-projectSummary-panel">

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectSummary;