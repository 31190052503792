import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { useSelector } from "react-redux";
import './view.scss'
import Dropdown from 'react-bootstrap/Dropdown'

const View = ({ hidePopup }) => {
    const { details } = useSelector(state => state.loginsResource)

    const [sourceType, setSourceType] = useState(details.sourceType);
    const [category, setCategory] = useState(details.category);
    const [portalName, setPortalName] = useState(details.sourceName);
    const [userName, setUserName] = useState(details.userName);
    const [password, setPassword] = useState(details.password);
    const [webSiteUrl, setWebSiteUrl] = useState(details.websiteLink);

    return (
        <>
            <Modal
                show={true}
                onHide={hidePopup}
                aria-labelledby="contained-modal-title-vcenter"
                className="dk-viewResModal"
                centered
            >
                <Modal.Header closeButton className="dk-viewResModalHder">
                    <Modal.Title id="contained-modal-title-vcenter">
                        View
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-viewResModalBdy">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            {/* <div className="form-group">
                                <label>Source Type</label>
                                <div className="dk-resDropDown">
                                    <input type="text" className="form-control" defaultValue={sourceType} readOnly />
                                </div>
                            </div> */}
                            <div className="form-group">
                                <label>Category</label>
                                <div className="dk-resDropDown">
                                    <input type="text" className="form-control" defaultValue={category} readOnly />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Name of Source</label>
                                <input type="text" className="form-control" defaultValue={portalName} readOnly />
                            </div>
                            <div className="form-group">
                                <label>User Name</label>
                                <div className="dk-resDropDown">
                                    <input type="text" className="form-control" defaultValue={userName} readOnly />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <div className="dk-resDropDown">
                                    <input type="text" className="form-control" defaultValue={password} readOnly />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Website Link</label>
                                <div className="dk-resDropDown">
                                    <input type="text" className="form-control" defaultValue={webSiteUrl} readOnly />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default View
