import React from 'react'
import { Link } from 'react-router-dom'
import {Nav,Tab} from 'react-bootstrap'
import  './live-spec.scss'

const index = () => {
  return (
    <>
      <div className="dkg-livespec-mainCon">
    
      </div>
    </>
  )
}

export default index