import React, { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import {
  clearState,
  updateJob,
} from "../../../../../../../slice/jobs/jobsSlice";
import SubmitButton from "../../../../../../ui/submitButton";
import { showSuccess } from "../../../../../../../slice/utils/message/messageSlice";
import MessageBox from "../../../../../../utils/joditEditor/MessageBox";

const EditorComp = ({ jobId, name, details, jobbid }) => {
  const dispatch = useDispatch();
  const { isUpdate } = useSelector((state) => state.jobs);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [editorDescription, setEditorDescription] = useState("");
  useEffect(() => {
    setEditorDescription(details);
  }, [details]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      dispatch(showSuccess({ msg: "Record updated successfully." }));
      setSuccess(false);
      setLoading(false);
    }
  }, [isUpdate]);

  const handleButtonClick = async () => {
    setSuccess(true);
    setLoading(true);
    dispatch(updateJob({ id: jobId, key: name, value: editorDescription }));
  };
  return (
    <>
      <MessageBox
        value={editorDescription}
        onChange={(value) => setEditorDescription(value)}
      />

      <div className="dkg-savebtnCon">
        <SubmitButton
          txt="SAVE"
          loading={loading}
          success={success}
          className="dkg-create-payment-taskbtn"
          onClick={handleButtonClick}
        />
      </div>
    </>
  );
};

export default EditorComp;
