import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav } from 'react-bootstrap';
import Step1 from './Step1'
import { getCategories, getSubCategories, clearState } from '../../../../slice/candidatePrecreeningForm/scripts'
import { showSuccess, showError, clearMessage } from '../../../utils/messages/messageSlice'
import $ from 'jquery'

const DkgInterviews = () => {
    const dispatch = useDispatch();
    const { isSuccess, isLoading, isCatUpdate, isError, isDelete, isReoder, isSubCatUpdate, isSubReoder, isCatInsert, categoryList } = useSelector(state => state.screeningFormScript);
    const [data, setData] = useState([]);
    const [catRowId, setCatRowId] = useState('');
    const [catRowName, setCatRowName] = useState('');
    const [activeEditTab, setActiveEditTab] = useState(false);


    useEffect(() => {
        if (!isLoading && categoryList && categoryList.length > 0) {
            setData(categoryList)
        }
    }, [isLoading])

    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(getCategories({}))
            } catch (err) {
                dispatch(showError({ msg: "Somthings went wrong" }))
            }
        }
        fetchData()
    }, [dispatch])

    const selectCat = async (catid, catName) => {
        $('.activeSubTab').removeClass('activeSubTab')
        $('.activesSubTab').removeClass('activesSubTab')
        setCatRowId(catid)
        setCatRowName(catName)
        setActiveEditTab(true)
        // await dispatch(getSubCategories({ categoryId: catid, keys: 'Overview' }))
    }

    return (
        <>
            <div className='dkg-payment-qt-pageCon dkg-clientinfo-training-demands-pageCon'>
                <Tab.Container defaultActiveKey="question-pt-new-overview">
                    <div className='row m-0 dkg-payment-qt-tab-mainCon'>
                        <div className='col-md-2 pl-0 dkg-payment-qt-tabCon dkg-clientinfo-sedmenu'>
                            <Nav variant="pills" className="flex-column dkg-payment-qt-tab-navpills">
                                {
                                    data.map((item, i) =>
                                        <Nav.Item className='dkg-payment-qt-tab-nav-item' key={i} onClick={() => selectCat(item.id, item.categoryName)} >
                                            <Nav.Link eventKey={item.categoryName + i} className='dkg-payment-qt-tab-navlink'>{item.categoryName}</Nav.Link>
                                        </Nav.Item>
                                    )
                                }
                            </Nav>
                        </div>
                        <div className='col-md-10 dkg-payment-rt-tabCon-10 dkg-clientinfo-rightCon'>
                            <Tab.Content className='dkg-payment-qt-tabcontent'>
                                {
                                    data.map((item, i) =>
                                        <Tab.Pane eventKey={item.categoryName + i} className='dkg-payment-qt-tabpane'>
                                            <Step1 pagetitle={item.categoryName} catIdds={item.id} />
                                        </Tab.Pane>
                                    )
                                }

                            </Tab.Content>

                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default DkgInterviews;
