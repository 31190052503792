import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/businessoverview/BusinessOverviewMenu';

const BusinessOverviewLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="BUSINESS OVERVIEW" moduleLink="/dashboard/manage-ment" linkcolor="#884c5e" mainheaderTitle=" - CASE STATS" textcolor="#884c5e" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default BusinessOverviewLayout;