import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
// import './addnew-payment.scss'

import Dropdown from 'react-bootstrap/Dropdown'
import SubmitButton from "../../../../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import 'date-fns';

const AddfileModal = ({ isDelete }) => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };


    return (
        <>
            {/* <Link to="#" onClick={handleShow}><i className="fa fa-plus" aria-hidden="true"></i></Link> */}
            <Link to="#" onClick={handleShow} className="dkg-addnew-btn">Add New <i className="fa fa-plus ml-2" aria-hidden="true"></i></Link>
            <Modal show={show} onHide={handleClose} className="dkg-addnew-paymenttask-modal-21">
                <Modal.Header closeButton className="dkg-addnew-payment-modalheader-21">
                    <Modal.Title>Add New File</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-addnew-payment-modalbody-21">
                    <div className='row'>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>File Name</label>
                                <input type='text' className='form-control' name='filename' />
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>File Type</label>
                                <div className='dkg-payment-task-drop12'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" className="form-control">
                                            Select
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu >
                                            <Dropdown.Item className="form-control">JPEG</Dropdown.Item>
                                            <Dropdown.Item className="form-control">PNG</Dropdown.Item>
                                            <Dropdown.Item className="form-control"> GIF</Dropdown.Item>
                                            <Dropdown.Item className="form-control">PDF</Dropdown.Item>
                                            <Dropdown.Item className="form-control">SVG</Dropdown.Item>
                                            <Dropdown.Item className="form-control">MP4</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Attachment File</label>
                                <input type="file" id="imageInputAPI" className="form-control w-100 dkg-payment-attach-input" />
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='dkg-create-payment-taskbtncon'>
                                <SubmitButton txt="ADD" className="dkg-create-payment-taskbtn" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddfileModal;