import React from 'react';
import TrainingStatus from "../../TrainingStatus";

const SheduleStatus = () => {
    return (
        <>
            <div className="dk-trainingShedule-statusMain">
                <TrainingStatus />
            </div>
        </>
    )
}

export default SheduleStatus;
