import React, { useState } from "react";
// import './moreinfo.scss';
import { FaInfoCircle  } from "react-icons/fa";


const MoreInfoPopover = () => {
    const [display, setDisplay] = useState("notdisplayed");
    const showButton = e => {
        e.preventDefault();
        setDisplay("displayed");
    };

    const hideButton = e => {
        e.preventDefault();
        setDisplay("notdisplayed");
    };
    return (
        <>
            {/* <div className='dk-prerightpopoverCon'> */}
                {/* <div className="productbox dk-prescreen-info-icon">
                    
                </div> */}
                <span className='dkg-infoicon' onMouseEnter={e => showButton(e)} onMouseLeave={e => hideButton(e)}><FaInfoCircle />
                    <div className={display}>
                        <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                         It has survived not only five centuries, but also the leap into electronic typesetting
                        </p>
                    </div>
                </span>
            {/* </div> */}
        </>
    )
}

export default MoreInfoPopover;
