import React from 'react'
import Pagination from '@material-ui/lab/Pagination';


const index = () => {
    return (
        <>
            <div className="dk-resPaginationCon pagination">
                <div className="dk-resPagination">
                    <div className="dk-resLeftPag">
                        <div className="dk-texCounter">Showing  1 to 1 (of 6 entries)</div></div>
                    <div className="dk-resrightPag pagination-btns">
                        <Pagination count={5} shape="rounded" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default index