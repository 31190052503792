import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown'

const AmFilter = ({ list, id, img, selectUserFilter }) => {
    const TaskForDropdown = styled.div`
        .dropdown{
            display: flex;
            justify-content: center;
            align-items: center;
            .dropdown-toggle{
                box-shadow: none;
                padding: 0 !important;
                margin: 0;
                border-radius: 50%;
                height: 35px;
                width: 35px;
                line-height: 30px;
                font-size: 0.81rem;
                cursor: pointer;
                position: relative;
                color: #fff !important;
                background-color: #3C4C62 !important;
                border: 1px solid #808080 !important;
                &.btn-primary:not([disabled]):not(.disabled):active,
               &.btn-primary:not([disabled]):not(.disabled).active, 
               .show>.btn-primary.dropdown-toggle {
                background-color: #3C4C62 !important;
                border: 1px solid #808080 !important;
                color: #333 !important;
               }
                img{
                    border-radius: 50%;
                height: 35px;
                width: 35px;
                }
                &:after{
                    display: none;
                }
            }
            .dropdown-menu{
                padding: 0 5px;
                left: 45px !important;
                top: -5px !important;
                -webkit-transform: inherit !important;
                background-color: #234158;
                transform: inherit !important;
                display: flex !important;
                align-items: center;
                width: -moz-fit-content;
                width: fit-content;
                min-width: -moz-fit-content;
                min-width: fit-content;
                &:after{
                    ${'' /* content: "";
                    width: 0;
                    height: 0;
                    border-color: transparent #ccc transparent transparent;
                    border-style: solid;
                    border-width: 8.5px 12px 8.5px 0;
                    line-height: 0;
                    _border-color: #000 #ccc #000 #000;
                    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                    position: absolute;
                    left: -12px;
                    top: 13px; */}
                    display: none;
                }
                .dropdown-item{
                    display: block;
                    width: 100%;
                    padding: 5px !important;
                    clear: both;
                    font-weight: 400;
                    color: #212529;
                    text-align: inherit;
                    white-space: nowrap;
                    background-color: transparent;
                    border: 0;
                    img {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                    }
                }
            }
        }
    `;

    const [userList, setUserList] = useState([]);

    useEffect(() => {
        const result = [];
        const map = new Map();
        for (const item of list) {
            if (!map.has(item.original.amInfo.uid)) {
                map.set(item.original.amInfo.uid, true);
                result.push({
                    id: item.original.amInfo.uid,
                    name: item.original.amInfo.firstName,
                    userImg: item.original.amInfo.profileImage,
                    count: list.filter(function (val) {
                        return val.original.amInfo.uid === item.original.amInfo.uid;
                    }).length
                });
            }
        }
        result.sort(function (a, b) {
            return b.count - a.count;
        });
        setUserList(result)
    }, [list])

    return (
        <>
            <TaskForDropdown>
                <Dropdown>
                    <Dropdown.Toggle variant="success">
                        {
                            (id === '0') ? <span className='workplanrecimg'>AM</span> : <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + img} />
                        }

                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dk-workplanrecDropdownMenu">
                        <div className="d-flex">
                            {
                                userList && userList.map((item, index) =>
                                    <Dropdown.Item href="#">
                                        <div className="tooltipMain" data-type="am" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                            <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} className="dkg-recruiter-img" alt={item.name} data-id={item.id} data-type="am" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                            <span className="dk-taskCount" data-type="am" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                {item.count}
                                            </span>
                                            <div className="tooltipText">{item.name}</div>
                                        </div>
                                    </Dropdown.Item>
                                )
                            }
                        </div>
                    </Dropdown.Menu>
                </Dropdown>

            </TaskForDropdown>
        </>
    )
}

export default AmFilter;