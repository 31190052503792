import React from 'react';
import './casestatus.scss';

const FinalFeeCaseStatus = ({ value }) => {
    let bgColor = "";
    switch (value) {
 
        case 'Final Fee':
            bgColor = '#81906c'
            break;
       
        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor }}>{value}</div>
        </>
    )
}

export default FinalFeeCaseStatus;