import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const OnboardingTraining = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalAdminTrainingMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/dkglobaladmin/onboardingadmintraining/onboardingdashboard" className={(segment3 == 'onboardingdashboard') ? 'active' : ''}>Dashboard</Link></li>
                        <li><Link to="/dkglobaladmin/admintraining/onboarding/welcome" className={`${(segment3 == 'onboarding') ? 'active' : ''}`}>Onboarding</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'onboarding') ? '' : ''}`}>Job Offer</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'onboarding') ? '' : ''}`}>Contracts</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'onboarding') ? '' : ''}`}>Documents</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'onboarding') ? '' : ''}`}>Refrences</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'onboarding') ? '' : ''}`}>Work Station</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'onboarding') ? '' : ''}`}>Tech Check</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'onboarding') ? '' : ''}`}>Accounts</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'onboarding') ? '' : ''}`}>Instructions</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default OnboardingTraining;