import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./myacc-contact.scss";
import FacebookIcon from "../../../../../assets/images/facebook.png";
import InstagramIcon from "../../../../../assets/images/instagram.png";
import LinkedinIcon from "../../../../../assets/images/linkedin.png";
import YoutubeIcon from "../../../../../assets/images/youtube.png";
import TwitterIcon from "../../../../../assets/images/dkglobal.png";
import { Nav, Tab } from "react-bootstrap";
import OtherProject from "./other-projects/OtherProject";
import CompanyTab from "./company/CompanyTab";
import SocialmediaTab from "./social-media/SocialmediaTab";
import OurServices from "./services/OurServices";
import { getCMS, submitQuery, clearState } from "../../../../../slice/cmsSlice";
import {
  showError,
  clearMessage,
  showSuccess,
} from "../../../../../slice/utils/message/messageSlice";
const MyaccContact = () => {
  const dispatch = useDispatch();
  const { isSuccess, cmsList, isError, isInsert, msg, isLoading } = useSelector(
    (state) => state.cmsdata
  );
  const { userInfo } = useSelector((state) => state.auth);
  const [JobInstuctiondata, setJobInstuctiondata] = useState("");

  const [candidateName, setCandidateName] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [candidatePhone, setCandidatePhone] = useState("");
  const [candidateSubject, setCandidateSubject] = useState("");
  const [candidateMessage, setCandidateMessage] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    dispatch(getCMS({}));
  }, []);

  useEffect(() => {
    if (isSuccess && cmsList && cmsList.length > 0) {
      setJobInstuctiondata(cmsList[1]);
    }
  }, [isSuccess]);

  console.log(JobInstuctiondata);
  console.log(cmsList[1]);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      dispatch(showError({ msg: msg }));
      setLoading(false);
      setSuccess(false);
    }
    if (isInsert && !isLoading) {
      //setChecked(false);
      setCandidateName("");
      setCandidateEmail("");
      setCandidatePhone("");
      setCandidateSubject("");
      setCandidateMessage("");
      dispatch(clearState());
      dispatch(showSuccess({ msg: msg }));
      setLoading(false);
      setSuccess(false);
    }
  }, [isError, isInsert]);

  const SubmitBtn = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);

    if (
      !checked ||
      !candidateName ||
      !candidateEmail ||
      !candidatePhone ||
      !candidateSubject ||
      !candidateMessage
    ) {
      dispatch(showError({ msg: "All fields are required." }));
      setLoading(false);
    } else {
      try {
        setLoading(true);
        await dispatch(
          submitQuery({
            canid: userInfo.canId,
            terms: checked,
            name: candidateName,
            from: candidateEmail,
            phone: candidatePhone,
            subject: candidateSubject,
            message: candidateMessage,
          })
        );
      } catch (err) {
        setSuccess(false);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="dkg-contact-mainCon">
        <div className="dkg-contact-main">
          <Tab.Container id="left-tabs-example" defaultActiveKey="dkg-company">
            <div className="row justify-content-center dkg-contact-mainRow">
              <div className="col-md-2 dkg-contact-main-sideCol">
                <Nav
                  variant="pills dkg-aboutus-navpills"
                  className="flex-column"
                >
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-company"
                      className="dkg-aboutus-navlink"
                    >
                      Company Info
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-services"
                      className="dkg-aboutus-navlink"
                    >
                      Our Services
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-logos"
                      className="dkg-aboutus-navlink"
                    >
                      Social Media
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-social"
                      className="dkg-aboutus-navlink"
                    >
                      Our Projects
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-contact-us"
                      className="dkg-aboutus-navlink"
                    >
                      Contact us
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-10 col-10 dkg-contact-mainCol">
                <Tab.Content className="dkg-aboutus-tabcontent">
                  <Tab.Pane
                    eventKey="dkg-company"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Company Info</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon dkg-company-tabpane-Con">
                      <CompanyTab comapnydetails={JobInstuctiondata} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-services"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header d-none">
                      <h1 className="dkg-contact-title">Our Services</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon dkg-aboutus-servicesCon">
                      <OurServices servicedetails={JobInstuctiondata} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-logos"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-aboutus-header d-none">
                      <h1 className="dkg-contact-title">Social Media</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon dkg-socail-media-mainCon">
                      <SocialmediaTab socialmediadetails={JobInstuctiondata} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-social"
                    className="dkg-aboutus-tabpane"
                    style={{
                      height: "calc(100vh - 210px )",
                      overflowY: "hidden",
                    }}
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Our Projects</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon dkg-otherprojects-main">
                      <OtherProject otherProjectdetails={JobInstuctiondata} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-contact-us"
                    className="dkg-aboutus-tabpane dkg-contactus-tabpane"
                  >
                    <div className="dkg-aboutus-header">
                      <h1 className="dkg-contact-title">Contact us</h1>
                    </div>
                    <div className="dkg-aboutus-mainCon dkg-contactus-mainCon">
                      <div className="dkg-other-project-header">
                        <p className="dkg-otherprject-title">
                          {JobInstuctiondata != ""
                            ? JobInstuctiondata.sendusOverview
                            : ""}
                        </p>
                      </div>
                      <div className="dkg-contactus-cardCon">
                        <div className="card dkg-contact-card">
                          <div className="row align-items-center dkg-contact-cardRow">
                            <div className="col-12 col-md-5 dkg-contact-card-leftCol">
                              <div className="contact_detail dkg-contact-card-detail">
                                <h3 className="dkg-card-detail-title">
                                  {JobInstuctiondata != ""
                                    ? JobInstuctiondata.sendusCompany
                                    : ""}
                                </h3>
                                <ul className="dkg-contact-card-ul">
                                  <li className="dkg-contact-card-li">
                                    <i className="fa fa-map-marker"></i>
                                    <Link to="#">
                                      {JobInstuctiondata != ""
                                        ? JobInstuctiondata.sendusAddress
                                        : ""}
                                    </Link>
                                  </li>
                                  <li className="dkg-contact-card-li">
                                    <i className="fa fa-envelope"></i>
                                    <Link to="#">
                                      {JobInstuctiondata != ""
                                        ? JobInstuctiondata.sendusEmail
                                        : ""}
                                    </Link>
                                  </li>
                                  <li className="dkg-contact-card-li">
                                    <i className="fa fa-phone"></i>
                                    <Link to="#">
                                      {" "}
                                      {JobInstuctiondata != ""
                                        ? JobInstuctiondata.sendusPhone
                                        : ""}
                                    </Link>
                                  </li>
                                </ul>
                                <div className="dkg-contact_social d-none">
                                  <Link target="_blank">
                                    <img src={FacebookIcon} alt="facebook" />
                                  </Link>
                                  <Link target="_blank">
                                    <img src={InstagramIcon} alt="instagram" />
                                  </Link>
                                  <Link target="_blank">
                                    <img src={LinkedinIcon} alt="linkedin" />
                                  </Link>
                                  <Link target="_blank">
                                    <img src={YoutubeIcon} alt="youtube" />
                                  </Link>
                                  <Link target="_blank">
                                    <img src={TwitterIcon} alt="twitter" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-7 dkg-contact-card-rightCol">
                              <form>
                                <div className="row">
                                  <div className="col-12 col-md-9">
                                    <h2>Get In Touch</h2>
                                    <div className="form-group">
                                      <input
                                        placeholder="Your Name"
                                        className="form-control"
                                        value={candidateName}
                                        onChange={(e) =>
                                          setCandidateName(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="form-group">
                                      <input
                                        placeholder="Your Email"
                                        className="form-control"
                                        value={candidateEmail}
                                        onChange={(e) =>
                                          setCandidateEmail(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="form-group">
                                      <input
                                        placeholder="Your Phone"
                                        className="form-control"
                                        value={candidatePhone}
                                        onChange={(e) =>
                                          setCandidatePhone(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="form-group">
                                      <input
                                        placeholder="Subject"
                                        className="form-control"
                                        value={candidateSubject}
                                        onChange={(e) =>
                                          setCandidateSubject(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <textarea
                                    placeholder="Message"
                                    className="form-control"
                                    value={candidateMessage}
                                    onChange={(e) =>
                                      setCandidateMessage(e.target.value)
                                    }
                                  ></textarea>
                                </div>
                                <p>
                                  <input
                                    type="checkbox"
                                    className="cusbox"
                                    defaultChecked={checked}
                                    onChange={() =>
                                      setChecked((state) => !state)
                                    }
                                  />
                                  I agree to the
                                  <Link
                                    to="/js-account/settings/terms-of-use"
                                    className=""
                                  >
                                    Terms of Use
                                  </Link>
                                  and
                                  <Link
                                    to="/js-account/settings/privacy"
                                    className=""
                                  >
                                    Privacy policy
                                  </Link>
                                </p>
                                <button
                                  loading={loading}
                                  success={success}
                                  type="button"
                                  className="btn btn-light"
                                  position="justify-content-center"
                                  onClick={SubmitBtn}
                                >
                                  Submit
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default MyaccContact;
