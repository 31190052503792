import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import "../responsiveMenu.scss";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import './mainmenusubmenuc.scss';
import { useDispatch, useSelector } from "react-redux";
import { paymentCounts, paidPaymentCounts } from "../../../../../../../slice/task/paymentTaskSlice"
import { counts, archivedCounts } from "../../../../../../../slice/task/taskSlice"

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const PayTasksSubmenu = (props) => {
    const dispatch = useDispatch();
    const { isSuccess, isMoved, isArchive, countsProgress, archivedDataCount } = useSelector(state => state.task);
    const { paymentDataCount, paidPaymentDataCount, issSuccess, isTaskUpdate, isDataMoveToPayment } = useSelector(state => state.paymentTask);
    const classes = useStyles();
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    const [toggleMenu, setToggleMenu] = useState(false);
    useEffect(() => {
        setToggleMenu(false)
    }, [pathname])

    useEffect(() => {

        if (isSuccess) {
            const fetchList = async () => {
                try {
                    await dispatch(counts({}))

                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }
        if (isMoved) {
            const fetchList = async () => {
                try {
                    await dispatch(counts({}))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }
        if (isArchive) {
            const fetchList = async () => {
                try {
                    await dispatch(archivedCounts({}))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }

        if (issSuccess) {
            const fetchList = async () => {
                try {
                    await dispatch(paidPaymentCounts({}))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }
        if (isDataMoveToPayment) {
            const fetchList = async () => {
                try {
                    await dispatch(paymentCounts({}))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }
        dispatch(archivedCounts({}))
        dispatch(paidPaymentCounts({}))
        dispatch(paymentCounts({}))
    }, [isSuccess, isMoved, isTaskUpdate, isDataMoveToPayment, issSuccess, isArchive])

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setToggleMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);

    return (
        <React.Fragment>
            <div className="dk-subMenuMain dkg-mainModuleSubmenu res-sub-menu" ref={ref}>
                <IconButton aria-label="delete" className="res-sub-btn" onClick={() => setToggleMenu(!toggleMenu)}>
                    <i className="fas fa-bars"></i>
                </IconButton>
                <Route>
                    <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
                        <li>
                            <Link to="/paytasks/summary" className={(segment2 == 'summary') ? 'active' : ''}>Summary</Link>
                        </li>
                        <li>
                            <Link to="/paytasks/to-pay" className={(segment2 == 'to-pay') ? 'active' : ''}>
                                {
                                    (paymentDataCount) ?
                                        <span className="dk-workplan-badgeCon dk-payments-topay-bgCon">
                                            <span className="dk-workplan-badgenumber payments-topay-bgNo">
                                                {paymentDataCount}
                                            </span>
                                        </span>
                                        :
                                        null
                                }
                                To Pay
                            </Link>
                        </li>
                        <li>
                            <Link to="/paytasks/paid" className={(segment2 == 'paid') ? 'active' : ''}>
                                {
                                    (paidPaymentDataCount) ?

                                        <span className="dk-workplan-badgeCon dk-payments-topay-bgCon" style={{ background: '#3a9469' }}>
                                            <span className="dk-workplan-badgenumber payments-topay-bgNo">
                                                {paidPaymentDataCount}
                                            </span>
                                        </span>
                                        : null
                                }
                                Paid
                            </Link>
                        </li>
                        <li>
                            <Link to="/paytasks/archived" className={(segment2 == 'archived') ? 'active' : ''}>
                                <span className="dk-workplan-badgeCon dk-payments-achived-bgCon" style={{ background: '#5c737d' }}>
                                    <span className="dk-workplan-badgenumber payments-achived-bgNo">
                                        5
                                    </span>
                                </span>
                                Archived
                            </Link>
                        </li>

                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default PayTasksSubmenu;
