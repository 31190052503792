import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const smsCredits = createAsyncThunk(
    'sms/sms_credits',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/sms/credits',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const smsSent = createAsyncThunk(
    'sms/sms_sent',
    async ({ companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/sms/sent/' + companyId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const deleteSentSms = createAsyncThunk(
    'sms/delete',
    async ({ rowId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/sms/' + rowId,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

export const composeSms = createAsyncThunk(
    'sms/compose',
    async ({ userId, number, smsBody, companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/sms/send',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    },
                    body: JSON.stringify({
                        userId, number, smsBody, companyId
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const smsInbox = createAsyncThunk(
    'sms/sms_inbox',
    async ({ companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/sms/inbox/' + companyId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

const smsSlice = createSlice({
    name: 'sms_template',
    initialState: {
        credits: 0,
        sentSmsList: {},
        smsInboxList: {},
        isLoading: false,
        isInsert: false,
        isError: false,
        isUpdate: false,
        isDelete: false,
        errorMessage: '',
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
            state.errorMessage = '';
            return state;
        }
    },
    extraReducers: {
        [smsCredits.pending]: (state) => {
            state.isLoading = true;
        },
        [smsCredits.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.credits = payload.creditRemain;
        },
        [smsCredits.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [smsSent.pending]: (state) => {
            state.isLoading = true;
        },
        [smsSent.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.sentSmsList = payload.data;
        },
        [smsSent.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [deleteSentSms.pending]: (state) => {
        },
        [deleteSentSms.fulfilled]: (state, action) => {
            state.isDelete = true;
            state.sentSmsList = state.sentSmsList.filter((item) => item._id !== action.meta.arg.rowId)
            state.msg = 'Delete Successfully';
        },
        [deleteSentSms.rejected]: (state, { payload }) => {
            state.isDelete = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [composeSms.pending]: (state) => {
        },
        [composeSms.fulfilled]: (state, { payload }) => {
            state.isInsert = true;
        },
        [composeSms.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [smsInbox.pending]: (state) => {
            state.isLoading = true;
        },
        [smsInbox.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.smsInboxList = payload.data;
        },
        [smsInbox.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
    }
});

export const { clearState } = smsSlice.actions;
export default smsSlice.reducer;