import React from 'react';
import Header from "../elements/header";
import Navbar from "../elements/header/navbar/mainMenu/recruitmentfaq/RecruitmentFaqNavbar";

const RecruitmentFaqLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="Recruitment" moduleLink="/dashboard/recruitment" linkcolor="#3c4c62" mainheaderTitle=" - FAQ's" textcolor="#3c4c62" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default RecruitmentFaqLayout;