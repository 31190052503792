import React, { useState } from 'react'
import { Rating } from 'react-simple-star-rating'

const RatingBar = () => {
    const [rating, setRating] = useState(0)
    // Catch Rating value
    const handleRating = (rate) => {
      setRating(rate)

      // other logic
    }
    // Optinal callback functions
    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
  return (
    <>
       <div className='dkg-test-rating-mainCon'>
        <Rating
            onClick={handleRating}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
            /* Available Props */
            className="dkg-test-rating-bar"
        />
       </div>
    </>
  )
}

export default RatingBar;