import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData,DeleteData } from "../../services";

export const getKpiReportsList = createAsyncThunk(
    'assignKpiReports/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/assign-kpi-report')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const addNewTask = createAsyncThunk(
    'assignKpiReports/add-new',
    async ({ caseId}, thunkAPI) => {
        const res = await PostData(true, '/assign-kpi-report', { caseId })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const getPaidPaymentList = createAsyncThunk(
    'assignKpiReports/paid/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/assign-kpi-report/paid')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const archivedData = createAsyncThunk(
    'assignKpiReports/list/archive',
    async (thunkAPI) => {
        const res = await GetData(true, '/assign-kpi-report/archived')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateTask = createAsyncThunk(
    'assignKpiReports/update',
    async ({ id, taskBy,taskFor,status,priority,invoiceNo,paymentType,paymentTo,paymentAmount,paymentNotes,dueTime,dueDate,paymentMonth, payType, attachment }, thunkAPI) => {
        const res = await PutData(true, '/assign-kpi-report/' + id, { taskBy,taskFor,status,priority,invoiceNo,paymentType,paymentTo,paymentAmount,paymentNotes,dueTime,dueDate,paymentMonth, payType, attachment })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateRowById = createAsyncThunk(
    'assignKpiReports/update/assign',
    async ({ id,sendoutOn,months,am,rc,candidateName,progress,stage,company,project,roleType,language,cvSource }, thunkAPI) => {
        const res = await PutData(true, '/assign-kpi-report/' + id, {sendoutOn,months,am,rc,candidateName,progress,stage,company,project,roleType,language,cvSource })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateByKey = createAsyncThunk(
    'assignKpiReports/update/key-value',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/assign-kpi-report/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateProgress = createAsyncThunk(
    'assignKpiReports/update/key-value/progress',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/assign-kpi-report/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

// export const countAllTask = createAsyncThunk(
//     'assignKpiReports/count/all',
//     async ({ status }, thunkAPI) => {
//         const res = await GetData(true, '/assign-kpi-report/count-all')
//         if (res.status === 200) {
//             return res.payload
//         } else {
//             return thunkAPI.rejectWithValue();
//         }
//     }
// );

export const deleteRecords = createAsyncThunk(
    'assignKpiReports/delete',
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, '/assign-kpi-report/'+id )
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskMoveTO = createAsyncThunk(
    'assignKpiReports/count/task-move',
    async ({ id }, thunkAPI) => {
        const res = await PostData(true, '/assign-kpi-report/bulk-move', { id })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const moveRecordsToPaid = createAsyncThunk(
    'assignKpiReports/count/assignKpiReports/move-to-table',
    async ({ id, status }, thunkAPI) => {
        const res = await PostData(true, '/assign-kpi-report/move-to-table', { id, status })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const counts = createAsyncThunk(
    'assignKpiReports/count/by-progress',
    async ({ status }, thunkAPI) => {
        const res = await GetData(true, '/assign-kpi-report/count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const paymentCounts = createAsyncThunk(
    'assignKpiReports/count/payment',
    async (thunkAPI) => {
        const res = await GetData(true, '/assign-kpi-report/payment-count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const paidPaymentCounts = createAsyncThunk(
    'assignKpiReports/count/paidpayment',
    async (thunkAPI) => {
        const res = await GetData(true, '/assign-kpi-report/paid-payment-count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateAssignCommentCount = createAsyncThunk(
    'assignKpiReports/commentlist/update-comment',
    async ({ id, value, commentAt }, thunkAPI) => {
        const res = await PutData(true, '/assign-kpi-report/update-comment/' + id, { value, commentAt })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const stageDataFetch = createAsyncThunk(
    'assignKpiReports/stage/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const progressDataFetch = createAsyncThunk(
    'assignKpiReports/progress/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const monthsDataFetch = createAsyncThunk(
    'assignKpiReports/month/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const companyDataFetch = createAsyncThunk(
    'assignKpiReports/company/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const projectDataFetch = createAsyncThunk(
    'assignKpiReports/project/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


export const roleTypeDataFetch = createAsyncThunk(
    'assignKpiReports/roleType/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const languageDataFetch = createAsyncThunk(
    'assignKpiReports/language/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const categoryDataFetch = createAsyncThunk(
    'assignKpiReports/categorySource/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const cvSourceDataFetch = createAsyncThunk(
    'assignKpiReports/cvSource/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskSummaryList = createAsyncThunk(
    'assignKpiReports/pipeline/summary-list',
    async (thunkAPI) => {
        const res = await GetData(true, '/assign-kpi-report/summary')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const detailsById = createAsyncThunk(
    'assignKpiReports/assign/details',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/assign-kpi-report/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const listSlice = createSlice({
    name: 'assignKpiReport',
    initialState: {
        assignKpiReportList: [],
        assignDetailsList: [],
        paidassignKpiReportList: [],
        taskDataSummaryList: [],
        taskTypeList: [],
        taskPaymentMonthList: [],
        taskPayTypeList: [],
        countsProgress: [],
        paymentDataCount: [],
        paidPaymentDataCount: [],
        tasksDetails: [],
        companyList:[],
        projectList:[],
        categoryList:[],
        roleTypeList:[],
        languageList:[],
        cvSourceList:[],
        progressList:[],
        stageList:[],
        monthsList:[],
        countAll: 0,
        isLoading: false,
        isDetailLoading: false,
        isTaskInsert: false,
        isTaskUpdate: false,
        isTaskDelete: false,
        isUpdate: false,
        iscommentCountUpdate: false,
        isError: false,
        isSuccess: false,
        isDataMoveToPayment: false,
        isAssignInserted: false,
        isMoved: false,
        isArchive: false,
        message: ''
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isDetailLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.issSuccess = false;
            state.isDataMoveToPayment = false;
            state.isAssignInserted = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },
        clearTaskState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.isAssignInserted = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },
        clearDetails: (state) => {
            state.tasksDetails = {}
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            getPaidPaymentList.pending, (state) => {
                state.isLoading = true;
                //state.isUpdate = false;
                state.isDataMoveToPayment = false;
            });
        builder.addCase(
            getPaidPaymentList.fulfilled, (state, { payload }) => {
                state.paidassignKpiReportList = payload.result
                state.isLoading = false;
               // state.isUpdate = true;
                state.isDataMoveToPayment = true;
            });
        builder.addCase(
            getPaidPaymentList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            getKpiReportsList.pending, (state) => {
                state.isLoading = true;
                state.issSuccess = false;
            });
        builder.addCase(
            getKpiReportsList.fulfilled, (state, { payload }) => {
                state.assignKpiReportList = payload.result
                state.isLoading = false;
                state.issSuccess = true;
            });
        builder.addCase(
            getKpiReportsList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            archivedData.pending, (state) => {
                state.isLoading = true;
                state.isArchive = false;
            });
        builder.addCase(
            archivedData.fulfilled, (state, { payload }) => {
                state.assignKpiReportList = payload.result
                state.isArchive = true;
                state.isLoading = false;
            });
        builder.addCase(
            archivedData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addNewTask.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            addNewTask.fulfilled, (state, { payload }) => {
                state.assignKpiReportList.unshift(payload.result)
                state.isLoading = false;
                state.isSuccess = true;
                state.isAssignInserted = true;
            });
        builder.addCase(
            addNewTask.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateRowById.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            updateRowById.fulfilled, (state, action) => {
                // let mainArray = state.assignKpiReportList;
                // let finalResult = mainArray.map((r) => {
                //     if (r.id === payload.result[0].id) {
                //         return (payload.result[0])
                //     } else {
                //         return r
                //     }
                // })
                // state.isLoading = false;
                // state.assignKpiReportList = finalResult;
                state.assignKpiReportList = state.assignKpiReportList.filter((item) => item.id !== action.meta.arg.id)
                state.isUpdate = true

            });
        builder.addCase(
            updateRowById.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            updateTask.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            updateTask.fulfilled, (state, { payload }) => {
                let mainArray = state.assignKpiReportList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result[0].id) {
                        return (payload.result[0])
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.isLoading = false;
                state.assignKpiReportList = finalResult;
            });
        builder.addCase(
            updateTask.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            updateByKey.pending, (state) => {
                state.isTaskUpdate = false
            });
        builder.addCase(
            updateByKey.fulfilled, (state, { payload }) => {
                let mainArray = state.assignKpiReportList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isTaskUpdate = true
                state.assignKpiReportList = finalResult;
                state.message = payload.message
            });
        builder.addCase(
            updateByKey.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateProgress.pending, (state) => {
            });
        builder.addCase(
            updateProgress.fulfilled, (state, action) => {
                state.isUpdate = true                
                state.assignKpiReportList = state.assignKpiReportList.filter((item) => item.id !== action.meta.arg.id)
            });
        builder.addCase(
            updateProgress.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        // builder.addCase(
        //     countAllTask.pending, (state) => {
        //     });
        // builder.addCase(
        //     countAllTask.fulfilled, (state, { payload }) => {
        //         state.countAll = payload.result
        //     });
        // builder.addCase(
        //     countAllTask.rejected, (state, action) => {
        //         state.isLoading = false;
        //         state.isError = true;
        //         state.message = 'Something went wrong';
        //     });

        builder.addCase(
            counts.pending, (state) => {
            });
        builder.addCase(
            counts.fulfilled, (state, { payload }) => {
                state.countsProgress = payload.result
            });
        builder.addCase(
            counts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            paidPaymentCounts.pending, (state) => {

            });
        builder.addCase(
            paidPaymentCounts.fulfilled, (state, { payload }) => {
                state.paidPaymentDataCount = payload.result
                //state.isArchive = true;
            });
        builder.addCase(
            paidPaymentCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            paymentCounts.pending, (state) => {

            });
        builder.addCase(
            paymentCounts.fulfilled, (state, { payload }) => {
                state.paymentDataCount = payload.result
                //state.isArchive = true;
            });
        builder.addCase(
            paymentCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        

        builder.addCase(
            deleteRecords.pending, (state) => {
                state.isDelete = false;
            });
        builder.addCase(
            deleteRecords.fulfilled, (state, { payload }) => {
                state.isDelete = true;
                
            });
        builder.addCase(
            deleteRecords.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            taskMoveTO.pending, (state) => {
            });
        builder.addCase(
            taskMoveTO.fulfilled, (state, { payload }) => {
                state.isTaskDelete = true;
                state.message = payload.message
                state.isMoved = true;
            });
        builder.addCase(
            taskMoveTO.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            moveRecordsToPaid.pending, (state) => {
            });
        builder.addCase(
            moveRecordsToPaid.fulfilled, (state, { payload }) => {
                state.isMoved = true;
                state.message = payload.message
            });
        builder.addCase(
            moveRecordsToPaid.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateAssignCommentCount.pending, (state) => {
                state.iscommentCountUpdate = false
            });
        builder.addCase(
            updateAssignCommentCount.fulfilled, (state, action) => {
                let mainArray = state.assignKpiReportList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === action.meta.arg.id) {
                        return ({ ...r, commentCount: action.meta.arg.value, commentAt: action.meta.arg.commentAt })
                    } else {
                        return r
                    }
                })
                state.assignKpiReportList = finalResult             
                state.iscommentCountUpdate = true
            });
        builder.addCase(
            updateAssignCommentCount.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            stageDataFetch.pending, (state) => {
            });
        builder.addCase(
            stageDataFetch.fulfilled, (state, { payload }) => {
                state.stageList = payload.result
            });
        builder.addCase(
            stageDataFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            monthsDataFetch.pending, (state) => {
            });
        builder.addCase(
            monthsDataFetch.fulfilled, (state, { payload }) => {
                state.monthsList = payload.result
            });
        builder.addCase(
            monthsDataFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            progressDataFetch.pending, (state) => {
            });
        builder.addCase(
            progressDataFetch.fulfilled, (state, { payload }) => {
                state.progressList = payload.result
            });
        builder.addCase(
            progressDataFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            companyDataFetch.pending, (state) => {
            });
        builder.addCase(
            companyDataFetch.fulfilled, (state, { payload }) => {
                state.companyList = payload.result
            });
        builder.addCase(
            companyDataFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            projectDataFetch.pending, (state) => {
            });
        builder.addCase(
            projectDataFetch.fulfilled, (state, { payload }) => {
                state.projectList = payload.result
            });
        builder.addCase(
            projectDataFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            categoryDataFetch.pending, (state) => {
            });
        builder.addCase(
            categoryDataFetch.fulfilled, (state, { payload }) => {
                state.categoryList = payload.result
            });
        builder.addCase(
            categoryDataFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            cvSourceDataFetch.pending, (state) => {
            });
        builder.addCase(
            cvSourceDataFetch.fulfilled, (state, { payload }) => {
                state.cvSourceList = payload.result
            });
        builder.addCase(
            cvSourceDataFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            languageDataFetch.pending, (state) => {
            });
        builder.addCase(
            languageDataFetch.fulfilled, (state, { payload }) => {
                state.languageList = payload.result
            });
        builder.addCase(
            languageDataFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            roleTypeDataFetch.pending, (state) => {
            });
        builder.addCase(
            roleTypeDataFetch.fulfilled, (state, { payload }) => {
                state.roleTypeList = payload.result
            });
        builder.addCase(
            roleTypeDataFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            taskSummaryList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            taskSummaryList.fulfilled, (state, { payload }) => {
                state.taskDataSummaryList = payload.result;
                state.isLoading = false;
            });
        builder.addCase(
            taskSummaryList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            detailsById.pending, (state) => {
                state.isDetailLoading = true
            });
        builder.addCase(
            detailsById.fulfilled, (state, { payload }) => {
                state.isDetailLoading = false
                state.assignDetailsList = payload.result;
            });
        builder.addCase(
            detailsById.rejected, (state, action) => {
                state.isDetailLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
    }
});


export const { clearState, clearTaskState, clearDetails } = listSlice.actions;
export default listSlice.reducer;