import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import "./company-album.scss";
//import Pagetypealbums from "../../../../../../../assets/images/album.jpg";
import ViewalbumsModal from "./modal/albums/ViewalbumsModal";
import { getAlbumCover } from "../../../../../../../slice/clientCompany/clientCompanySlice";

const AlbumsTab = ({ compAlbum }) => {
  const dispatch = useDispatch();
  const { coverAlbumList } = useSelector((state) => state.jobspecmp);

  const [rowwId, setRowwId] = useState(0);
  const [albumname, setalbumname] = useState("");

  const [isUpload, setIsUpload] = useState(false);
  const showuploadModal = (id, name) => {
    setRowwId(id);
    setalbumname(name);
    setIsUpload(true);
  };
  const hideuploadModal = () => {
    setIsUpload(false);
  };

  const [albumcover, setAlbumcover] = useState("");

  useEffect(() => {
    if (compAlbum) {
      setAlbumcover(compAlbum);
      if (compAlbum.length > 0) {
        dispatch(getAlbumCover({ id: compAlbum[0].compId }));
      }
    }
  }, [compAlbum]);

  const mergedData =
    albumcover.length > 0
      ? albumcover.map((item) => {
          const matchingItem = coverAlbumList.find(
            (el) => el.albumId === item.id
          );
          if (matchingItem) {
            return {
              ...item,
              image:
                process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                "/" +
                matchingItem.imageName,
            };
          } else {
            return item;
          }
        })
      : [];

  return (
    <>
      {isUpload ? (
        <ViewalbumsModal
          onHide={hideuploadModal}
          albumimg={coverAlbumList}
          albumnme={albumname}
          albumid={rowwId}
        />
      ) : null}
      <div className="dkg-jopspec-pageheader">
        <div className="dkg-jopspec-page-title">Albums</div>
      </div>
      <div className="dkg-company-album-mainCon">
        <div className="dkg-company-albumCon">
          <div className="dkg-company-mainRow row">
            {mergedData.length > 0 &&
              mergedData.map((data, index) => (
                <div
                  className="col-lg-4 col-md-4 col-sm-12 dkg-company-album-cols"
                  key={index + 1}
                >
                  <div className="dkg-company-album-box">
                    <div className="dkg-company-album-box-header">
                      <h2 className="dkg-album-box-header-title">
                        {data.albumName}
                      </h2>
                    </div>
                    <figure className="dkg-company-album-figure">
                      <div className="dkg-company-album-imgCon">
                        <img
                          src={data.image}
                          className="dkg-company-album-img"
                          alt="album"
                        />
                      </div>
                    </figure>
                    <div className="dkg-company-bottomCon">
                      <button
                        className="btn dkg-upload-mediabtn"
                        onClick={() => showuploadModal(data.id, data.albumName)}
                      >
                        VIEW
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AlbumsTab;
