import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaCheck, FaTimes, FaQuestion } from "react-icons/fa";

const TrickFilter = () => {
    return (
        <>
            <div className='dkg-pipe-trickFilterCon'>
                <Dropdown className="dkg-trickfilter-dropDown14">
                    <Dropdown.Toggle variant="" className="dkg-trickfilter-dropDown-toggle14" >
                        <FaCheck style={{ color: "#6c757d" }} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dkg-trickfilter-dropDown-menu14">
                        <Dropdown.Item href="#" className="dkg-trickfilter-dropDown-item14">
                            <div className="dk-idDropdown-cols">
                                <span>
                                    <FaCheck style={{ color: "#048204" }} />
                                </span>
                                <span className='dkg-tricker'>100% Matched</span>
                                <span className='dkg-tricker'>5</span>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" className="dkg-trickfilter-dropDown-item14">
                            <div className="dk-idDropdown-cols">
                                <span>
                                    <FaCheck style={{ color: "#D58B5D" }} />
                                </span>
                                <span className='dkg-tricker'>75% Matched</span>
                                <span className='dkg-tricker'>10</span>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" className="dkg-trickfilter-dropDown-item14">
                            <div className="dk-idDropdown-cols">
                                <span >
                                    <FaCheck style={{ color: "#3A76B4" }} />
                                </span>
                                <span className='dkg-tricker'>50% Matched</span>
                                <span className='dkg-tricker'>5
                                </span>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" className="dkg-trickfilter-dropDown-item14">
                            <div className="dk-idDropdown-cols">
                                <span>
                                    <FaCheck style={{ color: "#d60101" }} />
                                </span>
                                <span className='dkg-tricker'>0% Matched</span>
                                <span className='dkg-tricker'>4</span>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" className="dkg-trickfilter-dropDown-item14">
                            <div className="dk-idDropdown-cols">
                                <span>
                                    <FaCheck style={{ color: "#d3d3d3" }} />
                                </span>
                                <span className='dkg-tricker'>Blank</span>
                                <span className='dkg-tricker'>0</span>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default TrickFilter