import React from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from "react-redux";
import { caseStatusColor } from "../../../helper/commonFunctions";

const CaseProgressList = ({ caseStatus, id, columnId, onItemClick }) => {

    const { caseProgressList } = useSelector(state => state.common);
    const selectValue = (value, color) => {
        onItemClick(id, columnId, value, color);
    }

    return (
        <React.Fragment>
            {
                caseProgressList.filter(statusItem => statusItem.bgColor === caseStatusColor(caseStatus)).map((filteredItems, index) =>
                    <Dropdown.Item key={`case-progress-id${index}`} href="#" style={{ backgroundColor: '#ffffff', color: '#333333' }} onClick={() => selectValue(filteredItems.value, '')}>
                        {filteredItems.value}
                    </Dropdown.Item>
                )
            }
        </React.Fragment>
    )
}
export default CaseProgressList;