import React from 'react'
import './dkg-clientinfo.scss';
const index = () => {
    return (
        <>
            <div className='dkg-client-info-pageMain container-fluid'>
                <div className='row dkg-ci-summ-mainRow'>
                    <div className='col-12 col-md-12 dkg-ci-summ-mainCol'>
                        <div className='dkg-client-info-topboxs-Con'>
                            <div className='dkg-client-info-topbox-Cols'>
                                <div className='dkg-clientinfo-tableCon'>
                                    <div className='dkg-ci-summ-TitleCon'>
                                        <div className='dkg-ci-summ-Title'>CLIENT STATUS</div>
                                        <div className='dk-totalCvCount'>12</div>
                                    </div>
                                    <table className='dkg-clientinfo-table table table-bordered'>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className='dkg-clintinfo-con-234'>
                                                        <div className='dkg-title'>Active</div>
                                                        <div className='dkg-counter'>12</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='dkg-clintinfo-con-234'>
                                                        <div className='dkg-title'>Inactive</div>
                                                        <div className='dkg-counter'>12</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='dkg-client-info-topbox-Cols'>
                                <div className='dkg-clientinfo-tableCon'>
                                    <div className='dkg-ci-summ-TitleCon'>
                                        <div className='dkg-ci-summ-Title'>CLIENT CATEGORY</div>
                                        <div className='dk-totalCvCount'>12</div>
                                    </div>
                                    <table className='dkg-clientinfo-table table table-bordered'>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className='dkg-clintinfo-con-234'>
                                                        <div className='dkg-title'>ML</div>
                                                        <div className='dkg-counter'>12</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='dkg-clintinfo-con-234'>
                                                        <div className='dkg-title'>IT</div>
                                                        <div className='dkg-counter'>12</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='dkg-client-info-topbox-Cols'>
                                <div className='dkg-clientinfo-tableCon'>
                                    <div className='dkg-ci-summ-TitleCon'>
                                        <div className='dkg-ci-summ-Title'>CLIENT LOCATION</div>
                                        <div className='dk-totalCvCount'>12</div>
                                    </div>
                                    <table className='dkg-clientinfo-table table table-bordered'>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className='dkg-clintinfo-con-234'>
                                                        <div className='dkg-title'>EU</div>
                                                        <div className='dkg-counter'>12</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='dkg-clintinfo-con-234'>
                                                        <div className='dkg-title'>NON-EU</div>
                                                        <div className='dkg-counter'>12</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='dkg-client-info-topbox-Cols'>
                                <div className='dkg-clientinfo-tableCon'>
                                    <div className='dkg-ci-summ-TitleCon'>
                                        <div className='dkg-ci-summ-Title'>CLIENT BUSINESS</div>
                                        <div className='dk-totalCvCount'>12</div>
                                    </div>
                                    <table className='dkg-clientinfo-table table table-bordered'>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className='dkg-clintinfo-con-234'>
                                                        <div className='dkg-title'>BPO</div>
                                                        <div className='dkg-counter'>12</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='dkg-clintinfo-con-234'>
                                                        <div className='dkg-title'>NON-BPO</div>
                                                        <div className='dkg-counter'>12</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='dkg-client-info-topbox-Cols'>
                                <div className='dkg-clientinfo-tableCon'>
                                    <div className='dkg-ci-summ-TitleCon'>
                                        <div className='dkg-ci-summ-Title'>CLIENT LEVELS</div>
                                        <div className='dk-totalCvCount'>12</div>
                                    </div>
                                    <table className='dkg-clientinfo-table table table-bordered'>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className='dkg-clintinfo-con-234'>
                                                        <div className='dkg-title'>Level 1</div>
                                                        <div className='dkg-counter'>12</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='dkg-clintinfo-con-234'>
                                                        <div className='dkg-title'>Level 2</div>
                                                        <div className='dkg-counter'>12</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='dkg-client-info-bottomboxs-Con'>
                            <div className='dkg-client-info-bottombox-Cols'>
                                <div className='dkg-client-info-bottombox-Header'>
                                    <div className='dkg-client-info-title'>Country</div>
                                    <div className='dk-totalCvCount'>12</div>
                                </div>
                                <div className='dkg-client-info-bottombox-Body'></div>
                            </div>
                            <div className='dkg-client-info-bottombox-Cols'>
                                <div className='dkg-client-info-bottombox-Header'>
                                    <div className='dkg-client-info-title'>City/Town</div>
                                    <div className='dk-totalCvCount'>12</div>
                                </div>
                                <div className='dkg-client-info-bottombox-Body'></div>
                            </div>
                            <div className='dkg-client-info-bottombox-Cols'>
                                <div className='dkg-client-info-bottombox-Header'>
                                    <div className='dkg-client-info-title'>Projects Type</div>
                                    <div className='dk-totalCvCount'>12</div>
                                </div>
                                <div className='dkg-client-info-bottombox-Body'></div>
                            </div>
                            <div className='dkg-client-info-bottombox-Cols'>
                                <div className='dkg-client-info-bottombox-Header'>
                                    <div className='dkg-client-info-title'>Roles Type</div>
                                    <div className='dk-totalCvCount'>12</div>
                                </div>
                                <div className='dkg-client-info-bottombox-Body'></div>
                            </div>
                            <div className='dkg-client-info-bottombox-Cols'>
                                <div className='dkg-client-info-bottombox-Header'>
                                    <div className='dkg-client-info-title'>FEE RANKING</div>
                                    <div className='dk-totalCvCount'>12</div>
                                </div>
                                <div className='dkg-client-info-bottombox-Body'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default index