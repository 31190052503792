import React, { useEffect, useState } from "react";
import "../../accmanager.scss";
import Dropdown from "react-bootstrap/Dropdown";

const CommonRCFilter = ({ list, id, img, selectUserFilter }) => {
  const [userList, setUserList] = useState([]);
  const [secondList, setSecondList] = useState([]);
  const [thirdList, setThirdList] = useState([]);
  const [commonList, setCommonList] = useState([]);

  useEffect(() => {

    let types1 = [];
    for (const fulllist of list) {
      for (const userlist of fulllist.original.assignWithjob) {
        types1.push({
          id: userlist.userInfo.uid,
          name: userlist.userInfo.firstName,
          userImg: userlist.userInfo.profileImage,
        });
      }
    }

    const result = [];
    const map = new Map();
    for (const item of types1) {
      if (!map.has(item.id)) {
        map.set(item.id, true);
        result.push({
          id: item.id,
          name: item.name,
          userImg: item.userImg,
          count: types1.filter(function (val) {
            return val.id === item.id;
          }).length,
        });
      }
    }

    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setUserList(result);

    const TwoResult = [];
    const TwoMap = new Map();
    for (const item of list) {
      if (!TwoMap.has(item.original.recRA.uid)) {
        TwoMap.set(item.original.recRA.uid, true);
        TwoResult.push({
          id: item.original.recRA.uid,
          name: item.original.recRA.firstName,
          userImg: item.original.recRA.profileImage,
          count: list.filter(function (val) {
            return val.original.recRA.uid === item.original.recRA.uid;
          }).length
        });
      }
    }
    TwoResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setSecondList(TwoResult)

    const ThreeResult = [];
    const ThreeMap = new Map();
    for (const item of list) {
      if (!ThreeMap.has(item.original.rec3rc.uid)) {
        ThreeMap.set(item.original.rec3rc.uid, true);
        ThreeResult.push({
          id: item.original.rec3rc.uid,
          name: item.original.rec3rc.firstName,
          userImg: item.original.rec3rc.profileImage,
          count: list.filter(function (val) {
            return val.original.rec3rc.uid === item.original.rec3rc.uid;
          }).length
        });
      }
    }
    ThreeResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setThirdList(ThreeResult)

  }, [list]);

  //   const totalCount = userList.reduce(
  //     (total, row) => (total = total + Number(row.count)),
  //     0
  //   );

  // Loop through each object in the array and add the "type" key with the value "xyz"
  const firstUpdatedData = userList.map(item => {
    return { ...item, type: 'RCID' };
  });
  const secondUpdatedData = secondList.map(item => {
    return { ...item, type: 'RAID' };
  });
  // const thirdUpdatedData = thirdList.map(item => {
  //     return { ...item, type: 'RC3Id' };
  // });

  //var allArr = firstUpdatedData.concat(secondUpdatedData,thirdUpdatedData)
  var allArr = secondUpdatedData

  // Create an object to store the sum of counts for each unique user
  const uniqueUsers = {};
  // Iterate through the data array and aggregate the counts
  allArr.forEach(user => {
    const { id, name, userImg, count, type } = user;
    if (uniqueUsers[id]) {
      uniqueUsers[id].count += count;
    } else {
      uniqueUsers[id] = { id, name, userImg, count, type };
    }
  });
  // Convert the uniqueUsers object back to an array of values
  const uniqueRecordsWithSum = Object.values(uniqueUsers);

  //console.log(uniqueRecordsWithSum);
  // console.log(img);
  // console.log(id);

  return (
    <>
      <Dropdown className="dk-AmDropDwn">
        <Dropdown.Toggle variant="" className="dk-AmDropDwnToggle">
          {id === "0" ? (
            <React.Fragment>
              <div className="tooltipMain">
                RC1
                <div className="tooltipText">Recruiter - {uniqueRecordsWithSum.count}</div>
              </div>
            </React.Fragment>
          ) : (
            <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + img} />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dk-AmDropDwnMenu">
          <div className="d-flex">
            {uniqueRecordsWithSum &&
              uniqueRecordsWithSum.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  data-type={item.type}
                  data-id={item.id}
                  data-img={item.userImg}
                  onClick={selectUserFilter}
                >
                  <img
                    src={
                      process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg
                    }
                    data-type={item.type}
                    data-id={item.id}
                    data-img={item.userImg}
                    onClick={selectUserFilter}
                    alt="RA"
                    className="accManImg"
                  />
                  <span
                    className="counterNo"
                    data-type={item.type}
                    data-id={item.id}
                    data-img={item.userImg}
                    onClick={selectUserFilter}
                  >
                    {item.count}
                  </span>
                </Dropdown.Item>
              ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default CommonRCFilter;
