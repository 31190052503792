import React from 'react';
import './myfilesdashboard.scss';

const MyFilesDashboard = () => {
    return (
        <>
            <div className="dk-adminMyFilesDashboard-main">
                <h2>My Files Dashboard</h2>
            </div>
        </>
    )
}

export default MyFilesDashboard;
