import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const PreviewModal = (props) => {

    return (
        <>
            <Modal className="dk-composeMailModal" {...props}>
                <Modal.Header closeButton>
                    <Modal.Title>Preview Modal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-previewModal-main">
                        <div className="dk-previewCols"></div>
                        <div className="dk-previewArrow">
                            <Link to="#"><FontAwesomeIcon icon={faAngleLeft} /> Prev</Link>
                            <Link to="#">Next <FontAwesomeIcon icon={faAngleRight} /></Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PreviewModal;
