import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Modal, Dropdown } from 'react-bootstrap'
import Recruiter from '../../../../../../assets/images/deepak.png';
import './myprofile.scss';
const RightSideFilter = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <React.Fragment>
            <Link to="#" className="dk-resCrcleIcon" onClick={handleShow}><i className="fas fa-filter"></i></Link>
            <Modal show={show} onHide={handleClose} className="dk-myprofileModal">
                <Modal.Header closeButton className="dk-myprofileModal-Header">
                    <Modal.Title>SEARCH</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-myprofileModal-Body">

                </Modal.Body>
                <Modal.Footer className="dk-myprofileModal-Footer">
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}
export default RightSideFilter;