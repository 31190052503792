import React from "react";
import { Route, Link } from "react-router-dom";

const AdsTrainingSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/ads/training/overview" className={`${(segment3 == 'overview') ? 'active' : ''}`}>Overview</Link></li>
                        <li><Link to="/ads/training/dashboard" className={`${(segment3 == 'dashboard') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="/ads/training/work-plan" className={(segment3 == 'work-plan') ? 'active' : ''}>Workplan</Link></li>
                        <li><Link to="/ads/training/training-notifications" className={`${(segment3 == 'training-notifications') ? 'active' : ''}`}>Notification</Link></li>
                        <li><Link to="/ads/training/candidate" className={`${(segment3 == 'candidate') ? 'active' : ''}`}>Candidate</Link></li>
                        <li><Link to="/ads/training/training-jobs" className={`${(segment3 == 'training-jobs') ? 'active' : ''}`}>Jobs</Link></li>
                        <li><Link to="/ads/training/company" className={`${(segment3 == 'company') ? 'active' : ''}`}>Company</Link></li>
                        <li><Link to="/ads/training/kpi" className={`${(segment3 == 'kpi') ? 'active' : ''}`}>KPI</Link></li>
                        <li><Link to="/ads/training/dkg-cloud" className={`${(segment3 == 'dkg-cloud') ? 'active' : ''}`}>DKG Cloud</Link></li>
                        <li><Link to="/ads/training/training-database" className={`${(segment3 == 'training-database') ? 'active' : ''}`}>Database</Link></li>
                        <li><Link to="/ads/training/training-dkglobal" className={`${(segment3 == 'training-dkglobal') ? 'active' : ''}`}>DK Global</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default AdsTrainingSubmenu;
