import React, { useEffect, useState, useMemo, lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import TaskStatusFilter from '../dropdown/TaskStatusFilter';
import TaskTypeFilter from '../dropdown/TaskTypeFilter';
import TaskType from '../dropdown/TaskType'
import CustomDateFormat from "../../../ui/dateFormats/CustomDateFormat";
import EditPaymentModal from '../payments/modal/EditPaidPayment';
import ViewPaymentModal from '../payments/modal/ViewPayment';
import ViewSection from '../modal/tasktitle/TaskTitle';
import CommentModal from '../../../utils/comment/';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useAsyncDebounce } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import { clearState, getPaidPaymentList, deleteTask, taskMoveTO, moveRecordsToPaid, updateByKey, updateProgress, taskTypeFetch, taskDetails } from "../../../../slice/task/paymentTaskSlice";
import TableLoader from '../../../ui/tableLoader';
import Dropdown from 'react-bootstrap/Dropdown';
// import { FaEnvelope, FaCheck, FaExclamationCircle, FaQuestionCircle, FaThumbsUp, FaPauseCircle } from 'react-icons/fa';
// import TaskByFilter from '../tableFilters/users/taskByFilter';
// import TaskForFilter from '../tableFilters/users/taskForFilter';
import '../dropdown/categoryfilter.scss';
import Checkbox from "../utils/checkbox/radioBtn";
import $ from "jquery";
import DeleteModal from "../utils/delete/PaymentDelete";
import { showSuccess } from '../../../../slice/utils/message/messageSlice';
import PaymentStatus from '../utils/dropdown/paymentStatus';
import PriorityStatus from '../utils/dropdown/priorityStatus';
// import StatusDDL from '../utils/dropdown/status';
// import moment from "moment-timezone";
import CandidateProfile from "../../candidate/pipeline/modal/candidateprofile";
/*********************** */
import AddNewPaymentModal from './modal/AddNewPayment'
import TaskByDropdown from './tablefilters/TaskBy';
import TaskForDropdown from './tablefilters/TaskFor';
import PaymentCategoryDropdown from './tablefilters/PaymentCategory'
//import PriorityType from './tablefilters/PriorityType'
import StatusFilterDropdown from './tablefilters/StatusFilterDropdown'
import PayTypeDropdown from './tablefilters/PayType'
import CheckboxDummyCom from './modal/checkbox/CheckboxDummy'
import { FaFileAlt } from "react-icons/fa";
import DocumentModal from "../payments/modal/DocumentModal";
import AdvancedFilter from './tablefilters/rightModalFilter';

/*********************** */
const TableSuspence = lazy(() => import('../../../ui/tableLoader/tableSuspence'));

const useInstance = (instance) => {
    if (instance && instance.getInstanceCallback) {
        instance.getInstanceCallback(instance);
    }
};

function Table({ columns, data, loading, getInstanceCallback, filter, taskByFilterId, taskForFilterId, updateMyData, caseTypeId, casePayTypeId, caseStatusId }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        page,
        prepareRow,
        state,
        setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            updateMyData,
            getInstanceCallback,
            autoResetPage: false,
            autoResetSortBy: false,
            autoResetFilters: false,
            autoResetRowState: false,
            initialState: {
                pageIndex: 0, pageSize: 100, hiddenColumns: ["TASKBY", "TASKFOR", "caseStatus", "statusId", "payTypeId"]
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy, usePagination, (hooks) => hooks.useInstance.push(useInstance)
    )


    useEffect(() => {
        if (taskByFilterId === '0') {
            setTimeout(() => {
                setFilter('TASKBY', '')
            }, 200)
        } else {
            setFilterType('TASKBY')
            doFilter(taskByFilterId);
        }
    }, [taskByFilterId])

    useEffect(() => {
        if (caseStatusId === '0') {
            setTimeout(() => {
                setFilter('status', '')
            }, 200)
        } else {
            setFilterType('status')
            doFilter(caseStatusId);
        }
    }, [caseStatusId])


    useEffect(() => {
        if (casePayTypeId === '0') {
            setTimeout(() => {
                setFilter('payType', '')
            }, 200)
        } else {
            setFilterType('payType')
            doFilter(casePayTypeId);
        }
    }, [casePayTypeId])

    useEffect(() => {
        if (caseTypeId === '0') {
            setTimeout(() => {
                setFilter('caseStatus', '')
            }, 200)
        } else {
            setFilterType('caseStatus')
            doFilter(caseTypeId);
        }
    }, [caseTypeId])

    useEffect(() => {
        if (taskForFilterId === '0') {
            setTimeout(() => {
                setFilter('TASKFOR', '')
            }, 200)
        } else {
            setFilterType('TASKFOR')
            doFilter(taskForFilterId);
        }
    }, [taskForFilterId])

    const handlePageChange = (event, value) => {
        gotoPage(Number(value) - 1);
    };

    const [filterType, setFilterType] = useState('')

    const doFilter = React.useCallback(
        useAsyncDebounce((id) => {
            setFilter(filterType, id)
        }, 200),
        [filterType]
    );

    return (
        <React.Fragment>
            <table className="table table-borderd dkg-payment-table" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                (loading) ?
                                    [...Array(10)].map((e, i) =>
                                        <tr>
                                            <TableLoader colSpan="22" />
                                        </tr>
                                    ) :
                                    <Suspense fallback={<TableSuspence colSpan="22" />}>
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => (
                                                <td
                                                    {...cell.getCellProps([
                                                        {
                                                            className: cell.column.className, // pay attention to this
                                                            style: cell.column.style,
                                                            // set here your other custom props
                                                        },
                                                    ])}
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    </Suspense>
                            )
                        }
                    )}
                </tbody>
                <tfoot>
                    {footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()}>
                            {group.headers.map(column => (
                                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
        </React.Fragment>
    )
}

function debounce(func, wait, immediate) {
    var timeout;
    return (...args) => {
        var context = this;
        var later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isClearAll,
    restClearAllFilter }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState("")

    const onChangeSearchTitle = (e) => {
        const { value: nextValue } = e.target;
        setValue(e.target.value)
        debouncedSave(nextValue);
    };

    const debouncedSave = React.useCallback(
        debounce(nextValue => {
            setGlobalFilter(nextValue)
        }, 1000),
    );

    const clearSearch = () => {
        setValue('')
        setTimeout(() => {
            setGlobalFilter(undefined)
        }, 200)
    }

    useEffect(() => {
        if (isClearAll) {
            clearSearch()
            restClearAllFilter();
        }
    }, [isClearAll])

    return (
        <React.Fragment>
            <input
                value={value}
                className="form-control"
                onChange={onChangeSearchTitle}
                placeholder={`Search ...`}
            />
            {
                (value !== undefined && value.length > 0) ?
                    <div className="input-group-append">
                        <button className="btn btn-secondary" type="button" onClick={clearSearch}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div> : <div className="input-group-append">
                        <button className="btn btn-secondary" type="button">
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
            }
        </React.Fragment>
    )
}

const Paid = () => {
    const dispatch = useDispatch();
    const { isLoading, paidPaymentTaskList, isMoved, isUpdate, message, isTaskUpdate, isTaskInserted, isTaskDelete, taskTypeList, iscommentCountUpdate } = useSelector(state => state.paymentTask);

    const [list, setList] = useState([]);
    const [data, setData] = useState([])

    const [rowId, setRowId] = useState(0)
    const [dataRowId, setDataRowId] = useState(0)
    const [isViewModal, setIsViewModal] = useState(false)
    const [rowData, setRowData] = useState({})

    const { userInfo } = useSelector(state => state.auth);

    useEffect(() => {
        document.title = "Payment Task | Dk Global CRM"
    }, []);

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(getPaidPaymentList({ roletype: userInfo.role }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
    }, [dispatch])

    useEffect(() => {
        if (isMoved) {
            setLoading(false)
            setSuccess(true)
            dispatch(clearState())
            dispatch(getPaidPaymentList({ roletype: userInfo.role }))
            dispatch(showSuccess({ msg: 'Record move to payment successfully' }))
        }
    }, [isMoved])

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                setData(paidPaymentTaskList)
            }, 200)
        }
        if (iscommentCountUpdate) {
            dispatch(clearState())
            setData(paidPaymentTaskList)
        }
        if (isUpdate) {
            setIsActive(false)
        }

    }, [isLoading, isUpdate, isTaskUpdate, iscommentCountUpdate, dispatch])


    useEffect(() => {
        if (isTaskDelete) {
            setLoading(false)
            setSuccess(true)
            dispatch(clearState())
            dispatch(getPaidPaymentList({ roletype: userInfo.role }))
            dispatch(showSuccess({ msg: 'Record deleted successfully' }))
        }
    }, [isTaskDelete])

    useEffect(() => {
        if (isTaskUpdate) {
            dispatch(clearState())
            // setLoading(false)
            // setSuccess(false)
            dispatch(getPaidPaymentList({ roletype: userInfo.role }))
            dispatch(showSuccess({ msg: 'Record updated successfully!' }))
        }
    }, [isTaskUpdate])

    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')
    const [countCheck, setCountCheck] = useState(0)
    const [isActive, setIsActive] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const checkActiveExtraDiv = async (isShowExitra, checkedBoxCount) => {

        if (isShowExitra) {
            setIsActive(true)
            setCountCheck(checkedBoxCount)
        } else {
            setIsActive(false)
            setCountCheck(checkedBoxCount)
        }

    }

    const checkExtraDivBtnTxt = (btnTxt) => {
        setCheckBtnTxt(btnTxt)
    }

    const toggleCheckAll = () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
            setCountCheck(totalCheckboxes)
            $('.chkCandidate').prop('checked', true);
        }
        // if (checkedBoxCount == totalCheckboxes) {
        //     setCheckBtnTxt('Check All')
        //     setCountCheck(0)
        //     $('.chkCandidate').prop('checked', false);
        //     setIsActive(false)
        // }
    }

    const handleToggle = () => {
        $('.chkCandidate').prop('checked', false);
        setIsActive(false);
    };

    const deleteItemBulk = async () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
        } else {
            var canIds = [];
            $.each($(".chkCandidate:checked"), function () {
                canIds.push($(this).val());
            });
            setLoading(true)
            setSuccess(false)
            await dispatch(deleteTask({ id: canIds }))
            $('.chkCandidate').prop('checked', false);
            setIsActive(false);
        }
    }

    const [dataMoveTo, SetDataMoveTo] = useState('')
    const [showView, SetShowView] = useState(false);
    const [showEdit, SetshowEdit] = useState(false);

    const callViewPopup = async (data) => {
        await dispatch(taskDetails({ id: data }))
        SetShowView(true)
    }
    const taskFunction = async (e) => {
        await dispatch(taskDetails({ id: dataRowId }))
        SetshowEdit(true)
    }
    const closeEditModal = () => {
        SetshowEdit(false)

    }


    const [profileCanId, setProfileCanId] = useState(0)
    const [isProfileModal, setIsProfileModal] = useState(false)

    const handleCloseProfile = () => {
        setIsProfileModal(false);
        //SetshowEdit(false)
    }
    const veiwHandleClose = () => {
        SetShowView(false)
    }
    const handleClose = () => {
        SetshowEdit(false)
    }

    const columns = useMemo(() => [
        {
            Header: 'SNo.',
            accessor: 'id',
            Cell: ({ cell }) => {
                return (
                    <span className="dk-checkinputIconCon">
                        {Number(cell.row.id) + 1}
                        <div className='dk-checkinputIcon'>
                            <Checkbox id={cell.row.original.id} checkActiveExtraDiv={checkActiveExtraDiv} checkExtraDivBtnTxt={checkExtraDivBtnTxt} setDataRowId={setDataRowId} />
                            {/* <CheckboxDummyCom /> */}
                        </div>
                    </span>
                )
            }
        },
        {
            Header: 'Created',
            accessor: 'createdAt',
            Cell: ({ cell }) => {
                return <CustomDateFormat date={cell.row.original.createdAt} format='DD MMM YY' />
            }
        },
        {
            Header: 'TASK DUE',
            accessor: 'dueDate',
            Cell: ({ cell }) => {
                return <>
                    <CustomDateFormat date={cell.row.original.dueDate + ' ' + cell.row.original.dueTime} format='DD MMM YY' />
                    {/* {cell.row.original.dueDate}, {cell.row.original.dueTime} */}
                </>
            }
        },
        {
            Header: 'By',
            accessor: 'taskByDetail.firstName',
            Cell: ({ cell }) => {
                return (
                    <div className='dk-payment-imgCon'>
                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.taskByDetail.profileImage} alt='Recruiter' className='dk-payment-img' />
                    </div>
                )
            }
        },
        // {
        //     Header: 'For',
        //     accessor: 'taskForDetail.firstName',
        //     Cell: ({ cell }) => {
        //         return (
        //             <div className='dk-payment-imgCon'>
        //                 {
        //                     <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.taskForDetail.profileImage} alt='Recruiter' className='dk-payment-img' />
        //                 }
        //             </div>
        //         )
        //     }
        // },
        {
            Header: 'STATUS',
            id: 'status',
            accessor: 'status',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value, img) => {
                    updateMyData(id, columnId, value, img)
                };
                return (
                    <React.Fragment>
                        <div className='dkg-paymentstusdropdowncon-14'>
                            <Dropdown className='dk-workplanstatus-Dropdownon12 dkg-payment-statusdropdown-24' id={`caseStatus${cell.row.original.id}`}>
                                <Dropdown.Toggle variant="" className='dk-wpstatus-Dropdown-Toggle dkg-payment-status-dropdown-toggle-24' >
                                    <PaymentStatus value={cell.row.original.status} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='dk-wpstatus-Dropdown-Menu'>
                                    <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'status', 'New Task', '#d58b5d')} href="#" className='dk-wpstatus-Dropdown-Item' style={{ backgroundColor: "#d58b5d", color: "#fff" }}>New Task</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'status', 'Queries', '#625c60')} href="#" className='dk-wpstatus-Dropdown-Item' style={{ backgroundColor: "#625c60", color: "#fff" }}>Queries</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'status', 'Accepted', '#26597d')} href="#" className='dk-wpstatus-Dropdown-Item' style={{ backgroundColor: "#26597d", color: "#fff" }}>Accepted</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'status', 'Partially Paid', '#26597d')} href="#" className='dk-wpstatus-Dropdown-Item' style={{ backgroundColor: "#715f8f", color: "#fff" }}>Partially Paid</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'status', 'Fully Paid', '#3a9469')} href="#" className='dk-wpstatus-Dropdown-Item' style={{ backgroundColor: "#3a9469", color: "#fff" }}>Fully Paid</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </React.Fragment>
                )
            }
        },
        {
            Header: 'PRIORITY',
            className: 'pl-3 pr-3',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value, img) => {
                    updateMyData(id, columnId, value, img)
                };
                return (
                    <React.Fragment>
                        <div className='dkg-paymentstusdropdowncon-14'>
                            <Dropdown className='dk-workplanstatus-Dropdownon12 dkg-payment-statusdropdown-24' id={`priority${cell.row.original.id}`}>
                                <Dropdown.Toggle variant="" className='dk-wpstatus-Dropdown-Toggle dkg-payment-status-dropdown-toggle-24 dkg-pt-priority-textUpperCase' style={{ minWidth: "100px !important", maxWidth: "100px !important" }}>
                                    <PriorityStatus value={cell.row.original.priority} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='dk-wpstatus-Dropdown-Menu dkg-pt-priority-dropmenu-234'>
                                    <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'priority', 'Normal', '#3a9469')} href="#" style={{ backgroundColor: "transparent", color: "#3a9469", }}>Normal</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'priority', 'Urgent', '#d91010')} href="#" style={{ backgroundColor: "transparent", color: "#d91010" }}>Urgent</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </React.Fragment>
                )
            }
        },
        {
            Header: 'CATEGORY',
            accessor: 'paymentType',
            Cell: ({ cell }) => {
                return <>
                    {cell.row.original.paymentType}
                </>
            }
        },
        {
            Header: 'PAY TYPE',
            accessor: 'payType'
        },
        {
            Header: 'PAYMENT TO',
            accessor: 'paymentTo'
        },
        {
            Header: 'MONTH',
            accessor: 'paymentMonth',
        },
        {
            Header: 'INVOICE NO.',
            accessor: 'invoiceNo'
        },
        {
            Header: 'PAY AMOUNT',
            accessor: 'paymentAmount',
            Cell: ({ cell }) => {
                return <>
                    {cell.row.original.paymentAmount} €
                </>
            },
            Footer: (info) => {
                const total = React.useMemo(
                    () =>
                        info.flatRows.reduce((sum, row) => Number(row.original.paymentAmount) + sum, 0),
                    [info.rows]
                )
                return <><span className='totalAmtPaymentTask'>{total.toFixed(2) + ' ' + '€'} </span></>
            },
        },
        {
            Header: 'Comment',
            accessor: 'commentCount',
            className: 'pl-2 pr-2',
            Cell: ({ cell }) => {
                const commentPoupShow = (e) => {
                    if (cell.row.original.taskType === "Candidate") {
                        setRefId(cell.row.original.subject.substr(0, cell.row.original.subject.indexOf(':')))
                        seTitle("Candidates Comments")
                        seTReftbl("candidates")
                    } else {
                        setRefId(cell.row.original.id)
                        seTitle("Payment Tasks Comments")
                        seTReftbl("payment_tasks")
                    }
                    setCommentCount(cell.row.original.commentCount)
                    setCommentPopupShow(true)
                }
                return (
                    <React.Fragment>
                        <div
                            className="d-flex align-items-center justify-content-center grid-table-box"
                            style={{
                                cursor: "unset",
                                background: "none",
                                borderBottom: "0px",
                            }}
                        >

                            {
                                (cell.row.original.commentCount === 0) ? <span className="commentBoxCon zeroComment" onClick={commentPoupShow}>
                                    <span className="numCon zeroCommentCount">
                                        {cell.row.original.commentCount}
                                    </span>
                                </span>
                                    :
                                    <span className="commentBoxCon" onClick={commentPoupShow}>
                                        <span className="numCon">
                                            {cell.row.original.commentCount}
                                        </span>
                                    </span>
                            }
                        </div>
                    </React.Fragment>
                );
            }
        },
        // {
        //     Header: 'PAYMENT NOTES',
        //     accessor: 'paymentNotes',
        //     Cell: ({ cell }) => {
        //         return <>
        //             <span onClick={() => callViewPopup(cell.row.original.id)}>
        //                 {cell.row.original.paymentNotes}
        //             </span>
        //         </>
        //     }
        // },

        {
            Header: 'File',
            accessor: 'attachment',
            Cell: ({ cell }) => {
                return <>
                    {(cell.row.original.attachment != '' || cell.row.original.attachment == null) ? <span className='dkg-invoiceIcon' >

                        <DocumentModal name="taskPayment" canId='' file={process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/' + cell.row.original.attachment} />
                    </span>
                        :
                        "---"
                    }

                </>
            }
        },
        {
            id: 'TASKBY',
            Header: 'TASKBY',
            accessor: 'taskByDetail.uid',
        },
        {
            id: 'caseStatus',
            accessor: 'paymentType',
        },
        {
            id: 'statusId',
            accessor: 'status',
        },
        {
            id: 'payTypeId',
            accessor: 'payType',
        },
        {
            id: 'TASKFOR',
            Header: 'TASKFOR',
            accessor: 'taskForDetail.uid',
        },

    ], [])

    const [instanceData, setInstanceData] = useState('0')
    const reactTableInstance = (instance) => {
        if (instance) {
            setList(instance.flatRows)
            setInstanceData(instance)
        }
    };

    const updateMyData = async (id, columnId, value, img) => {
        switch (columnId) {
            case 'status':
                $(`#caseStatus${id} button .caseDiv`).html(value).css("background-color", img)
                break;

            case 'caseStatus':
                break;

            default:
                break;
        }

        if (columnId === "progress") {
            dispatch(updateProgress({ id, key: columnId, value }))
        } else {
            await dispatch(updateByKey({ id, key: columnId, value }))
            // dispatch(getPaidPaymentList({roletype: userInfo.role}))
        }
        // dispatch(updateByKey({ id, key: columnId, value }))
    }

    const [isClearAll, setIsClearAll] = useState(false)

    const restClearAllFilter = async () => {
        setIsClearAll(false)
    }

    const [taskByFilterId, setTaskByFilterId] = useState('0');
    const [taskByFilterImg, setTaskByFilterImg] = useState('0');

    const [taskForFilterId, setTaskForFilterId] = useState('0');
    const [taskForFilterImg, setTaskForFilterImg] = useState('0');

    const selectUserFilter = async (e) => {
        const type = e.target.dataset.type;
        switch (type) {
            case 'TASKBY':
                setTaskByFilterId(e.target.dataset.id)
                setTaskByFilterImg(e.target.dataset.img)
                // setFilter({ ...filter, [type]: e.target.dataset.id })
                break;
            case 'TASKFOR':
                setTaskForFilterId(e.target.dataset.id)
                setTaskForFilterImg(e.target.dataset.img)
                // setFilter({ ...filter, [type]: e.target.dataset.id })
                break;
        }
    }

    const clearUserFilter = async (e) => {
        const type = e.target.dataset.type;

        switch (type) {
            case 'TASKBY':
                setTaskByFilterId('0')
                setTaskByFilterImg('0')
                break;
            case 'TASKFOR':
                setTaskForFilterId('0')
                setTaskForFilterImg('0')
                break;

        }
    }

    const clearAllFilter = async () => {
        await dispatch(getPaidPaymentList({ roletype: userInfo.role }))
        setTaskByFilterId('0')
        setTaskByFilterImg('0')
        setTaskForFilterId('0')
        setTaskForFilterImg('0')

    }

    const [filterTags, setFilterTags] = useState([]);
    const [taskStatusFilterText, setTaskStatusFilterText] = useState('CATEGORY')
    const [caseStatusFilterText, setCaseStatusFilterText] = useState('STATUS')
    const [paymentTypeFilterText, setpaymentTypeFilterText] = useState('PAY TYPE')
    const [caseTypeId, setCaseTypeId] = useState('0');
    const [caseStatusId, setCaseStatusId] = useState('0')
    const [casePayTypeId, setCasePayTypeId] = useState('0')
    /******comment popup start****** */
    const [title, seTitle] = useState('')
    const [reftbl, seTReftbl] = useState('')
    const [refId, setRefId] = useState(0)
    const [commentsCount, setCommentCount] = useState(0)
    const [commentPopupShow, setCommentPopupShow] = useState(false)
    const commentHandleClose = () => {
        setCommentPopupShow(false)
    }
    /******comment popup end****** */

    const selectStatusFilter = async (e) => {
        const type = e.target.dataset.type;
        const value = e.target.dataset.value;
        let i;
        switch (type) {
            case 'paymentType':
                i = filterTags.findIndex(function (x) { return x.type === 'paymentType' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setCaseTypeId(value)
                setTaskStatusFilterText(value)
                break;
            case 'payType':
                i = filterTags.findIndex(function (x) { return x.type === 'payType' })

                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setCasePayTypeId(value)
                setpaymentTypeFilterText(value)
                break;

        }
    }

    const onSelectStatusFilter = (eventKey) => {
        setCaseStatusFilterText(eventKey)
        setCaseStatusId(eventKey)
    }

    const removeTag = async (e) => {
        const type = e.target.dataset.type;
        setFilterTags(filterTags.filter((item) => item.type !== type))

        switch (type) {
            case 'paymentType':
                setCaseTypeId('0')
                setTaskStatusFilterText('CATEGORY')
                break;

            case 'status':
                setCaseStatusId('0')
                setCaseStatusFilterText('STATUS')
                break;
            case 'payType':
                setCasePayTypeId('0')
                setpaymentTypeFilterText('PAY TYPE')
                break;
            default:
                break;
        }
    }

    const moveRecords = async (e) => {
        SetDataMoveTo(e.target.dataset.moveto)
    }

    useEffect(() => {
        if (dataMoveTo !== '') {
            (async () => {
                var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
                if (checkedBoxCount == 0) {
                } else {
                    var canIds = [];
                    $.each($(".chkCandidate:checked"), function () {
                        canIds.push($(this).val());
                    });
                    await dispatch(moveRecordsToPaid({ id: dataRowId, status: dataMoveTo }))
                    $('.chkCandidate').prop('checked', false);
                    setIsActive(false);
                }
            })();
        }
    }, [dataMoveTo])

    return (
        <>
            {
                (commentPopupShow) ? <CommentModal refTable={reftbl} refId={refId} count={commentsCount} title={title} commentHandleClose={commentHandleClose} candidateStatus='' candidateProgess='' candidateStage='' /> : null
            }
            {
                (showEdit) ? <EditPaymentModal dataRowId={dataRowId} handleClose={handleClose} /> : null
            }
            {
                (showView) ? <ViewPaymentModal dataRowId={dataRowId} veiwHandleClose={veiwHandleClose} /> : null
            }
            <ViewSection
                show={isViewModal}
                onHide={() => setIsViewModal(false)}
                id={rowId}
                data={rowData}
            />
            {
                (isProfileModal) ? <CandidateProfile canid={profileCanId} list={[]} isProfileModal={isProfileModal} handleCloseProfile={handleCloseProfile} /> : null
            }
            <div className='dkg-task-paymentpage12 container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="dk-workplantodo-TopHead">
                            <div className="dk-workplantodoLeftTopHead">
                                <div className="dk-SrchInputBox mr-2">
                                    <div className="input-group">
                                        {
                                            (instanceData !== '0') ? <GlobalFilter
                                                preGlobalFilteredRows={instanceData.preGlobalFilteredRows}
                                                globalFilter={instanceData.state.globalFilter}
                                                setGlobalFilter={instanceData.setGlobalFilter}
                                                isClearAll={isClearAll}
                                                restClearAllFilter={restClearAllFilter}
                                            /> : null
                                        }

                                    </div>
                                </div>
                                <div className='mr-2' style={{ position: "relative" }}>
                                    <TaskByDropdown list={list} id={taskByFilterId} img={taskByFilterImg} selectUserFilter={selectUserFilter} />
                                    {
                                        (taskByFilterId !== '0') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="TASKBY" onClick={clearUserFilter}></i> : null
                                    }

                                </div>
                                {/* <div className='mr-2 taskForCrossBtn' style={{ position: "relative" }}>

                                    <TaskForDropdown list={list} id={taskForFilterId} img={taskForFilterImg} selectUserFilter={selectUserFilter} />
                                    {
                                        (taskForFilterId !== '0') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="TASKFOR" onClick={clearUserFilter}></i> : null
                                    }
                                </div> */}
                                {/* <div className='mr-2' style={{ position: "relative" }}>
                                    <TaskByDropdown />
                                </div> */}
                                {/* <div className='mr-2' style={{ position: "relative" }}>
                                    <TaskForDropdown />
                                </div> */}
                                <div className='mr-2 dkg-addnew-paymentbtn taskForCrossBtn'>
                                    <AddNewPaymentModal isDelete='0' />
                                </div>

                            </div>
                            <div className='dk-workplanHeadtitleCon'>
                                <h2 className='dk-workplanHeadtitle todotitle' style={{ color: '#26597d' }}>PAID</h2>
                            </div>
                            <div className="dk-workplantodoRightTopHead">
                                {/* <div className='mr-2 dkg-addnew-paymentbtn taskForCrossBtn'>
                                    <button className="dk-cricleBtn mr-3" onClick={clearAllFilter}>
                                        <i className="fas fa-sync-alt"></i>
                                    </button>
                                </div> */}
                                <div className="mr-2 dkg-addnew-paymentbtn taskForCrossBtn">
                                    <AdvancedFilter />
                                </div>
                                <div className='dk-categoryfilterCon mr-2'>
                                    <StatusFilterDropdown list={list} caseStatusFilterText={caseStatusFilterText} onSelectStatusFilter={onSelectStatusFilter} tableType={'paid'} />
                                    {
                                        (caseStatusFilterText !== 'STATUS') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="status" onClick={removeTag}></i> : null
                                    }
                                </div>
                                <div className='dk-categoryfilterCon dkg-category-drop-bg mr-2'>
                                    <PaymentCategoryDropdown list={list} taskStatusFilterText={taskStatusFilterText} selectStatusFilter={selectStatusFilter} />
                                    {
                                        (taskStatusFilterText !== 'CATEGORY') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="paymentType" onClick={removeTag}></i> : null
                                    }
                                </div>

                                {/* <div className='dk-categoryfilterCon dkg-tasktype-drop-bg'>
                                    <PayTypeDropdown list={list} paymentTypeFilterText={paymentTypeFilterText} selectStatusFilter={selectStatusFilter} />
                                    {
                                        (paymentTypeFilterText !== 'PAY TYPE') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="payType" onClick={removeTag}></i> : null
                                    }
                                </div> */}


                            </div>
                        </div>
                        <div className='dkg-taskpayment-tableCon-12'>
                            <Table
                                data={data}
                                columns={columns}
                                getInstanceCallback={reactTableInstance}
                                loading={isLoading}
                                updateMyData={updateMyData}
                                taskByFilterId={taskByFilterId}
                                taskForFilterId={taskForFilterId}
                                caseTypeId={caseTypeId}
                                caseStatusId={caseStatusId}
                                casePayTypeId={casePayTypeId}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`batch-actions-menu-wrapper dk-action-popupMain ${isActive ? "activePopup" : ""}`} style={{ width: 'auto' }}>
                <div className="num-of-actions_wrapper">
                    <div className='num-of-actions'>{countCheck}</div>
                </div>
                <div className="batch-actions-title-section pl-0">
                    <div className='dk-rowSelected'>
                        <span>Row Selected</span>
                    </div>
                </div>

                <div className="batch-actions-item dk-checkAll" onClick={taskFunction}>
                    <span onClick={taskFunction}><i className="far fa-edit"></i></span>
                    Edit
                </div>
                <div className="batch-actions-item dk-checkAll" data-moveto="Queries" onClick={moveRecords}>
                    <span data-moveto="Queries" onClick={moveRecords}>
                        <i className="fa fa-arrow-circle-left" data-moveto="Queries" onClick={moveRecords}></i>
                    </span>
                    Move to To Pay
                </div>
                <div className="batch-actions-item dk-checkAll" data-moveto="Archived">
                    <span data-moveto="Archived">
                        <i className="fa fa-arrow-circle-right" data-moveto="Archived"></i>
                    </span>
                    Move to Archived
                </div>
                <DeleteModal loading={loading} success={success} deleteItem={deleteItemBulk} tableName="payment_tasks" />
                <div className="batch-actions-delete-item CloseAll d-flex align-items-center justify-content-center" onClick={handleToggle}>
                    <span><i className="fas fa-times"></i></span>
                </div>
            </div>
        </>
    )
}

export default Paid;
