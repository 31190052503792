import React from 'react';
import { Link } from "react-router-dom";
import './salessendouts.scss';
import TasksLeftPanel from './leftpanel/TasksLeftPanel';
import Dropdown from 'react-bootstrap/Dropdown';

const TasksStatsSummary = () => {
    return (
        <>
            <div className="dk-tasks-containerMain dk-tasksModuleMain-container">
                <TasksLeftPanel />

                <div className="dk-tasks-rightPanel">
                    <div className="dk-sendoutsHead" style={{ marginTop: '15px' }}>
                        <div className="dk-sendouts-yearsDropdown">
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    2022
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#">2021</Dropdown.Item>
                                    <Dropdown.Item href="#">2020</Dropdown.Item>
                                    <Dropdown.Item href="#">2019</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="dk-sendouts-months">
                            <ul>
                                <li><Link to="#">Jan</Link></li>
                                <li><Link to="#">Feb</Link></li>
                                <li><Link to="#">Mar</Link></li>
                                <li><Link to="#">Apr</Link></li>
                                <li><Link to="#">May</Link></li>
                                <li><Link to="#">Jun</Link></li>
                                <li><Link to="#" className="active">Jul</Link></li>
                                <li><Link to="#">Aug</Link></li>
                                <li><Link to="#">Sep</Link></li>
                                <li><Link to="#">Oct</Link></li>
                                <li><Link to="#">Nov</Link></li>
                                <li><Link to="#">Dec</Link></li>
                            </ul>
                            <Link className="addMonthBtn" to="#"><i className="fas fa-plus"></i></Link>
                        </div>
                    </div>
                    <div className="dk-salesSendouts-table">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Updated On</th>
                                    <th>Recruiter</th>
                                    <th>Job Offers</th>
                                    <th>Refused Offer</th>
                                    <th>Credit</th>
                                    <th>Bonus</th>
                                    <th>Net Sale</th>
                                    <th>Sale Target</th>
                                    <th>Up / Down</th>
                                    <th>%</th>
                                    <th>Updated By</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            1
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                    <td>
                                        <div className="dk-text-red">-9000</div>
                                    </td>
                                    <td>
                                        <div className="dk-text-red"><i className="fas fa-arrow-down mr-1"></i> -75</div>
                                    </td>
                                    <td>
                                        <div className="dk-sales-updatedUser">
                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                            <div className="tooltipText">Deepak Kumar</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            2
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                    <td>
                                        <div className="dk-text-red">-9000</div>
                                    </td>
                                    <td>
                                        <div className="dk-text-red"><i className="fas fa-arrow-down mr-1"></i> -75</div>
                                    </td>
                                    <td>
                                        <div className="dk-sales-updatedUser">
                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                            <div className="tooltipText">Deepak Kumar</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            3
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="10750" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="8000" name="" id="" />
                                    </td>
                                    <td>
                                        <div className="dk-text-green">2750</div>
                                    </td>
                                    <td>
                                        <div className="dk-text-green"><i className="fas fa-arrow-up mr-1"></i> 134</div>
                                    </td>
                                    <td>
                                        <div className="dk-sales-updatedUser">
                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                            <div className="tooltipText">Deepak Kumar</div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr className="sumtr">
                                    <td colSpan="3" className="transparent"></td>
                                    <td className="sum countGrossSale">18450 €</td>
                                    <td className="sum countGrossSale">0 €</td>
                                    <td className="sum countGrossSale">0 €</td>
                                    <td className="sum countGrossSale">0 €</td>
                                    <td className="sum countGrossSale">18450 €</td>
                                    <td className="sum countGrossSale">35000 €</td>
                                    <td className="sum countGrossSale">-16550 €</td>
                                    <td className="sum countGrossSale">53 %</td>
                                </tr>
                            </tfoot>

                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TasksStatsSummary
