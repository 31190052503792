import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const StatusTraining = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    const segment4 = pathname.split("/")[4];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dkg-training-shecdule-Navbar">
                <Route>
                    <ul>
                        <li><Link to="/dkglobaladmin/statusadmintraining/statusdashboard" className={(segment3 == 'statusdashboard') ? 'active' : ''}>Dashboard</Link></li>
                        <li><Link to="/dkglobaladmin/admintraining/schedule/overview" className={`${(segment3 == 'schedule') ? 'active' : ''}`}>Inhouse</Link></li>
                        <li><Link to="/dkglobaladmin/admintraining/model-2/overview" className={`${(segment3 == 'model-2') ? 'active' : ''}`}>Partner</Link></li>
                        <li><Link to="/dkglobaladmin/admintraining/model-3/overview" className={`${(segment3 == 'model-3') ? 'active' : ''}`}>Freelance</Link></li>
                        <li><Link to="/dkglobaladmin/admintraining/status" className={`${(segment3 == 'status') ? 'active' : ''}`}>Status</Link></li>
                        <li><Link to="/dkglobaladmin/admintraining/overview" className={`${(segment3 == 'overview') ? 'active' : ''}`}>Overview</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default StatusTraining;