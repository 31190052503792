import React from 'react';
import Header from "../elements/header";
import Navbar from '../elements/header/navbar/mainMenu/logins/LoginsMainMenu'

const LoginsLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header mainheaderLink="Dashboard -" moduleLink="/dashboard/plateform" linkcolor="#3c4c62" mainheaderTitle=" LOGINS" textcolor="#5c737d" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default LoginsLayout;