import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';
import "./deletemodal.css";
import Submit from "../../../../ui/submitButton";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import { clearState } from '../../../../../slice/campaign/campaignSlice';

const DeleteCampaigns = ({ id, handleClose, title, closeFunction }) => {
    const dispatch = useDispatch();
    const { isDelete, isError, errorMessage, msg } = useSelector(state => state.campaignAds)
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setShow(true)
    }, [id])
    useEffect(() => {
        if (isDelete) {
            dispatch(showSuccess({ msg: msg }))
            dispatch(clearState())
            handleClose()
            setSuccess(true);
            setLoading(false);
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }
    }, [isDelete, isError])
    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={handleClose}
                dialogClassName="dk-adstarckDeletePop"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Body className="dk-adstrackDelBody">
                    <p>{title}</p>
                </Modal.Body>
                <Modal.Footer className="dk-adstrackDelFooter">
                    <button className="btn dk-nodelBtn" onClick={handleClose}>No</button>
                    <Submit txt="Yes" loading={loading} success={success} onClick={closeFunction} position="justify-content-center" className="btn dk-yesdelBtn" />
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default DeleteCampaigns;
