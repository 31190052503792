import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import "./documents-modal.scss";
import AWS from "aws-sdk";
import DatePicker from "./datepicker/DatePicker";
import SubmitButton from "../../../../../../ui/submitButton";
import moment from "moment-timezone";
import "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewDocument,
  //filesCategory,
  //clearState,
} from "../../../../../../../slice/cmsSlice";
import {
  //showSuccess,
  showError,
  //clearMessage,
} from "../../../../../../../slice/utils/message/messageSlice";
//import { SYSTEM_CONSTANTS } from "../../../../../../../constants";

const AddNewModal = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { filesCategoryList, isInsert, isLoading } = useSelector(
    (state) => state.cmsdata
  );
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { userInfo } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [categoryS, setCategoryD] = useState("Select");

  const [attachment, setAttachment] = useState("");
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [categoryData, setCategoryData] = useState([]);

  const [selecteddate, setSelectedDate] = useState(
    moment(new Date()).format("DD MMM YYYY")
  );

  const [defaultDt, setDefaultDt] = useState(new Date());
  useEffect(() => {
    if (!isLoading && filesCategoryList.length > 0) {
      setCategoryData(filesCategoryList);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInsert) {
      setLoading(false);
      setSuccess(false);
      setShow(false);
      setFileType("");
      setAttachment("");
      setFile("");
      setFileName("");
    }
  }, [isInsert]);

  const onSelectFile = async (e) => {
    setAttachment(e.target.files[0].name);
    setFileType(e.target.files[0].type);
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!fileName || !selecteddate) {
        dispatch(showError({ msg: "All fileds required" }));
      } else {
        setLoading(true);
        try {
          let s3bucket = new AWS.S3({
            accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
            secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
            Bucket: process.env.REACT_APP_BUCKET_NAME,
          });

          let fileNewName = Date.now() + "-" + attachment;
          s3bucket.createBucket(function () {
            var params = {
              Bucket: process.env.REACT_APP_BUCKET_NAME,
              Key: fileNewName,
              Body: file,
              ContentType: fileType,
            };

            s3bucket.upload(params, function (err, data) {
              if (err) {
                console.log(err);
              } else {
                dispatch(
                  addNewDocument({
                    uid: 2, //deepak
                    canId: userInfo.canId,
                    fileName: "/" + data.key,
                    onlyfileName: fileName,
                    fileType: fileType,
                    fileCategory: categoryS,
                    fileUsedIn: "jobseeker",
                    docDate: selecteddate,
                  })
                );
              }
            });
          });
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      setSuccess(false);
      setLoading(false);
      dispatch(showError({ msg: "Something went wrong! Try again." }));
    }
  };

  const selectedDate = (date) => {
    setSelectedDate(moment(date).format("DD MMM YYYY"));
  };
  return (
    <>
      <Link to="#" className="dkg-add-new-btn" onClick={handleShow}>
        <FaPlus className="mr-2" />
        Add New File{" "}
      </Link>
      <Modal show={show} onHide={handleClose} className="dk-notificationsModal">
        <Modal.Header closeButton className="dk-notificationsModalHeader">
          <Modal.Title>Add New Document</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-addnew-doc-modalbody">
          <div className="dk-warningMain">
            <form action="">
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="form-group">
                    <label className="dkg-noti-label">Date Added</label>
                    <div className="dkg-addnew-date-timeCon">
                      <DatePicker
                        selectedDate={selectedDate}
                        defaultDt={defaultDt}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="dkg-noti-label">File Category</label>
                    <div className="dkg-notification-task-drop12">
                      <Dropdown className="dkg-notification-task-dropdown">
                        <Dropdown.Toggle
                          variant=""
                          className="dkg-noti-task-dropdown-toggle"
                        >
                          {categoryS}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {categoryData.map((item, index) => (
                            <Dropdown.Item
                              key={index}
                              className="form-control"
                              onClick={() => setCategoryD(item.value)}
                            >
                              {item.value}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>

                      {/* <Dropdown className="dkg-notification-task-dropdown">
                        <Dropdown.Toggle
                          variant=""
                          className="dkg-noti-task-dropdown-toggle"
                        ></Dropdown.Toggle>
                        <Dropdown.Menu className="dkg-noti-task-dropdown-menu">
                          <Dropdown.Item className="dkg-noti-task-dropdown-item">
                            Task Type 1
                          </Dropdown.Item>
                          <Dropdown.Item className="dkg-noti-task-dropdown-item">
                            Task Type 2
                          </Dropdown.Item>
                          <Dropdown.Item className="dkg-noti-task-dropdown-item">
                            Task Type 3
                          </Dropdown.Item>
                          <Dropdown.Item className="dkg-noti-task-dropdown-item">
                            Task Type 4
                          </Dropdown.Item>
                          <Dropdown.Item className="dkg-noti-task-dropdown-item">
                            Task Type 5
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="dkg-noti-label">File Name</label>
                    <input
                      type="text"
                      placeholder=""
                      value={fileName}
                      className="form-control dkg-sub-form-control"
                      onChange={(e) => setFileName(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label className="dkg-noti-label">File Type</label>
                    <input
                      type="file"
                      id="imageInputAPI"
                      onChange={onSelectFile}
                      className="form-control dkg-sub-form-control"
                    />
                  </div>
                  <div className="form-group mt-4 ">
                    <div className="dk-notiAddnewBtn-Con">
                      <SubmitButton
                        txt="UPLOAD"
                        loading={loading}
                        success={success}
                        onClick={handleSubmit}
                        className="dk-notiAddnewBtn"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewModal;
