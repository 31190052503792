import React from 'react';
import Modal from 'react-bootstrap/Modal'
import './workflownoupdate.scss'

const WorkFlowNoUpdate = ({ handleClose }) => {
    return (
        <>
            <Modal
                show={true} onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                className="dk-workflow-numupdateModal"
                centered
            >
                <Modal.Header closeButton className='dk-workflow-numupdateModalHeader'>
                    <Modal.Title>
                        Work Flow
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='dk-workflow-numupdateModalBody'>
                    <div className="dk-numupdateCon">
                        <div className="firstInput">
                            <label>Current</label>
                            <input type="text" className="form-control" readonly="" value="34" />
                        </div>
                        <div className="SecondInput">
                            <label>New</label>
                            <input type="text" className="form-control" name="newValue" value="" />
                        </div>
                        <div className="dk-workflowBtnCon">
                            <button className=" btn dk-calcsaveBtn">Save</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default WorkFlowNoUpdate;
