import React from "react";
import { Route, Link } from "react-router-dom";

const SuppliersSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/trainingmodule/suppliers-training/sample-1" className={`${(segment3 == 'sample-1') ? 'active' : ''}`}>Sample 1</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'company') ? '' : ''}`}>Sample 2</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'company') ? '' : ''}`}>Sample 3</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'company') ? '' : ''}`}>Sample 4</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'company') ? '' : ''}`}>Sample 5</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'company') ? '' : ''}`}>Sample 6</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'company') ? '' : ''}`}>Sample 7</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default SuppliersSubmenu;
