import React from 'react';
import { Link } from "react-router-dom";

const MarianaProjectLiveLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="dk-moduleTraining-leftPanel">
                <ul>
                    <li><Link to="/projects/mariana/live/summary" className={`${pathname.match('/projects/mariana/live/summary') ? 'active' : ''}`}>Summary</Link></li>
                    <li><Link to="/projects/mariana/live/project-1" className={`${pathname.match('/projects/mariana/live/project-1') ? 'active' : ''}`}>German</Link></li>
                    <li><Link to="/projects/mariana/live/project-2" className={`${pathname.match('/projects/mariana/live/project-2') ? 'active' : ''}`}>French</Link></li>
                    <li><Link to="/projects/mariana/live/project-3" className={`${pathname.match('/projects/mariana/live/project-3') ? 'active' : ''}`}>Dutch</Link></li>
                    <li><Link to="/projects/mariana/live/project-4" className={`${pathname.match('/projects/mariana/live/project-4') ? 'active' : ''}`}>English</Link></li>
                </ul>
            </div>
        </>
    )
}

export default MarianaProjectLiveLeftPanel;