import React from 'react'
import { Link } from 'react-router-dom'
import './knowleadge-dash.scss'
import CompanyIcon from '../../../../../assets/images/company-rec.png'
import BackgroundIcon from '../../../../../assets/images/background.png'
import VisionIcon from '../../../../../assets/images/vision.png'
import FutureIcon from '../../../../../assets/images/futurescope.png'
import SubscriptionIcon from '../../../../../assets/images/subscription.png'
import AccountknowleadgeIcon from '../../../../../assets/images/account-knowleadge.png'
import UserknowleadgeIcon from '../../../../../assets/images/user-knowleadge.png'
import PermissionknowleadgeIcon from '../../../../../assets/images/permission-knowleadge.png'
import ProtectionknowleadgeIcon from '../../../../../assets/images/protection-knowl.png'
import PervationknowleadgeIcon from '../../../../../assets/images/pervation-knowl.png'
import SrchinfoIcon from '../../../../../assets/images/srch-info-knowl.png'
import TroubleShootIcon from '../../../../../assets/images/trouble-shooting.png'
import QuestknowlIcon from '../../../../../assets/images/quest-knowl.png'
import TalkknowlIcon from '../../../../../assets/images/talk-knowl.png'
import ComplanceIcon from '../../../../../assets/images/complance-know.png'
import PaymentProtectIcon from '../../../../../assets/images/payment-protect.png'

import { FaQuestionCircle } from "react-icons/fa";

const KnowleadgeDashboard = () => {
    return (
        <>
            <div className='dkg-knowleadedash-Main container-fluid'>
                <div className='row dkg-dashboard-main'>
                    <div className='col-12 dkg-knowleadedash-boxCOn'>
                        <h1>KNOWLEDGE BASE </h1>
                        {/* <Link to='#' className='ml-2'><FaQuestionCircle /></Link> */}
                        <div className='row dkg-knowleadedash-boxsRow'>
                            <div className='col-sm-6 col-md-4 col-lg-3 dkg-knowleadedash-box'>
                                <div className='card faq-card'>
                                    <div className='card-body'>
                                        <div className='dkg-know-dash-imgCon'>
                                            <img src={CompanyIcon} className='dkg-know-dash-img' alt='Company' />
                                            <span>COMPANY</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3 dkg-knowleadedash-box'>
                                <div className='card faq-card'>
                                    <div className='card-body'>
                                        <div className='dkg-know-dash-imgCon'>
                                            <img src={BackgroundIcon} className='dkg-know-dash-img' alt='Company' />
                                            <span>BACKGROUND</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3 dkg-knowleadedash-box'>
                                <div className='card faq-card'>
                                    <div className='card-body'>
                                        <div className='dkg-know-dash-imgCon'>
                                            <img src={VisionIcon} className='dkg-know-dash-img' alt='Company' />
                                            <span>VISION</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3 dkg-knowleadedash-box'>
                                <div className='card faq-card'>
                                    <div className='card-body'>
                                        <div className='dkg-know-dash-imgCon'>
                                            <img src={FutureIcon} className='dkg-know-dash-img' alt='Company' />
                                            <span>FUTURE SCOPE</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3 dkg-knowleadedash-box'>
                                <div className='card faq-card'>
                                    <div className='card-body'>
                                        <div className='dkg-know-dash-imgCon'>
                                            <img src={SubscriptionIcon} className='dkg-know-dash-img' alt='Company' />
                                            <span>SUBSCRIPTION</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3 dkg-knowleadedash-box'>
                                <div className='card faq-card'>
                                    <div className='card-body'>
                                        <div className='dkg-know-dash-imgCon'>
                                            <img src={AccountknowleadgeIcon} className='dkg-know-dash-img' alt='Company' />
                                            <span>ACCOUNTS</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3 dkg-knowleadedash-box'>
                                <div className='card faq-card'>
                                    <div className='card-body'>
                                        <div className='dkg-know-dash-imgCon'>
                                            <img src={UserknowleadgeIcon} className='dkg-know-dash-img' alt='Company' />
                                            <span>USERS</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3 dkg-knowleadedash-box'>
                                <div className='card faq-card'>
                                    <div className='card-body'>
                                        <div className='dkg-know-dash-imgCon'>
                                            <img src={PermissionknowleadgeIcon} className='dkg-know-dash-img' alt='Company' />
                                            <span>PERMISSIONS</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3 dkg-knowleadedash-box'>
                                <div className='card faq-card'>
                                    <div className='card-body'>
                                        <div className='dkg-know-dash-imgCon'>
                                            <img src={ProtectionknowleadgeIcon} className='dkg-know-dash-img' alt='Company' />
                                            <span>DATA PROTECTION</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3 dkg-knowleadedash-box'>
                                <div className='card faq-card'>
                                    <div className='card-body'>
                                        <div className='dkg-know-dash-imgCon'>
                                            <img src={PervationknowleadgeIcon} className='dkg-know-dash-img' alt='Company' />
                                            <span>FRAUD PREVENTION</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3 dkg-knowleadedash-box'>
                                <div className='card faq-card'>
                                    <div className='card-body'>
                                        <div className='dkg-know-dash-imgCon'>
                                            <img src={SrchinfoIcon} className='dkg-know-dash-img' alt='Company' />
                                            <span>PAYMENT PROTECTION</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3 dkg-knowleadedash-box'>
                                <div className='card faq-card'>
                                    <div className='card-body'>
                                        <div className='dkg-know-dash-imgCon'>
                                            <img src={SrchinfoIcon} className='dkg-know-dash-img' alt='Company' />
                                            <span>SEARCH INFORMATION</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3 dkg-knowleadedash-box'>
                                <div className='card faq-card'>
                                    <div className='card-body'>
                                        <div className='dkg-know-dash-imgCon'>
                                            <img src={TroubleShootIcon} className='dkg-know-dash-img' alt='Company' />
                                            <span>TROUBLE SHOOTING</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3 dkg-knowleadedash-box'>
                                <div className='card faq-card'>
                                    <div className='card-body'>
                                        <div className='dkg-know-dash-imgCon'>
                                            <img src={QuestknowlIcon} className='dkg-know-dash-img' alt='Company' />
                                            <span>GOT A QUESTION ?</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3 dkg-knowleadedash-box'>
                                <div className='card faq-card'>
                                    <div className='card-body'>
                                        <div className='dkg-know-dash-imgCon'>
                                            <img src={TalkknowlIcon} className='dkg-know-dash-img' alt='Company' />
                                            <span>TALK TO US !</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3 dkg-knowleadedash-box'>
                                <div className='card faq-card'>
                                    <div className='card-body'>
                                        <div className='dkg-know-dash-imgCon'>
                                            <img src={ComplanceIcon} className='dkg-know-dash-img' alt='Company' />
                                            <span>COMPLIANCE</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KnowleadgeDashboard