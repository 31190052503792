import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import SubmitButton from "../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { addNewTask } from "../../../../slice/kpis/assignKpisReportSlice"
import {  showError } from "../../../../slice/utils/message/messageSlice";

const JobIdModal = ({ onHide }) => {
    const dispatch = useDispatch();
    const { isAssignInserted } = useSelector(state => state.assignKpiReport)

    const inputRef = React.useRef(null);

    React.useEffect(()=>{
        inputRef.current.focus();
      }, []);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [caseId, setCaseId] = useState('')
    useEffect(() => {
        if (isAssignInserted) {
            setLoading(false)
            setSuccess(true)
        }
    }, [isAssignInserted])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (!caseId) {
                dispatch(showError({ msg: 'Please enter case id' }))
            } else {
                setLoading(true);
                dispatch(addNewTask({ caseId }))
            }
        }
        catch
        {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }
    return (
        <>
            <Modal className="dk-jobIdModal" centered show={true} onHide={onHide}>
                <Modal.Body>
                    <div className="d-flex">                      
                        <div className="form-group d-flex align-items-center; ">
                            <label htmlFor="" className='mb-0' style={{ minWidth: "105px" , marginTop: "4px" }}>Enter Case ID</label>
                            <label htmlFor="">
                                <input type="text" name="" value={caseId} className="form-control" onChange={(e) => setCaseId(e.target.value)}  ref={inputRef} />
                            </label>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">                        
                        <SubmitButton txt="ADD" loading={loading} success={success} onClick={handleSubmit} className="dk-saveBtn" />
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default JobIdModal;
