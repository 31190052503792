import React from 'react';
import RulesNavbar from '../navtabs/RulesNavbar';
import DkGlobalLeftPanel from "../leftpanel/DkGlobalLeftPanel";


const WorkplanDkglobal = () => {
    return (
        <React.Fragment>
            <div className="dk-dayclsoing-Main dk-dayclsoing-DetailMain">
                {/* <RulesNavbar /> */}
                <div className='dkg-started-content-u234'>
                    <div className="d-flex">
                        <DkGlobalLeftPanel />
                        <div className="dk-trainingReqRight-main">
                            <div className="row">
                                <div className='col-12'>
                                    <div className='dkg-video-boxCon'>
                                        <iframe style={{ width: "640px", height: "400px" }} allow="autoplay; fullscreen; picture-in-picture" allowfullscreen frameborder="0" src="https://player.vimeo.com/video/759034237?h=60a8784542&badge=0&autopause=0&player_id=0&app_id=58479/embed">
                                        </iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default WorkplanDkglobal;

