import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";

const RecDkGlobalLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/recruitment/rectraining/training-dkglobal" className={`${pathname.match('/recruitment/rectraining/training-dkglobal') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/recruitment/rectraining/dkg-teams" className={`${pathname.match('/recruitment/rectraining/dkg-teams') ? 'active' : ''}`}>DKG Teams</Link></li>

                    <li><Link to="/recruitment/rectraining/structure" className={`${pathname.match('/recruitment/rectraining/structure') ? 'active' : ''}`}>Structure</Link></li>

                    <li><Link to="/recruitment/rectraining/process" className={`${pathname.match('/recruitment/rectraining/process') ? 'active' : ''}`}>Process</Link></li>

                    <li><Link to="/recruitment/rectraining/pipelines" className={`${pathname.match('/recruitment/rectraining/pipelines') ? 'active' : ''}`}>Pipelines</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default RecDkGlobalLeftPanel;

