import React from 'react';
import { Link, Route } from "react-router-dom";


const SuppliersLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/commontraining/dkglobal/suppliers/overview" className={`${pathname.match('/commontraining/dkglobal/suppliers/overview') ? 'active' : ''}`}>Overview</Link></li>
                    <li><Link to="#" className={`${pathname.match('/training') ? '' : ''}`}>Established</Link></li>
                    <li><Link to="#" className={`${pathname.match('/training') ? '' : ''}`}>Business</Link></li>
                    <li><Link to="#" className={`${pathname.match('/training') ? '' : ''}`}>Industry</Link></li>
                    <li><Link to="#" className={`${pathname.match('/training') ? '' : ''}`}>Services</Link></li>
                    <li><Link to="#" className={`${pathname.match('/training') ? '' : ''}`}>Clients</Link></li>
                    <li><Link to="#" className={`${pathname.match('/training') ? '' : ''}`}>Suppliers</Link></li>
                    <li><Link to="#" className={`${pathname.match('/training') ? '' : ''}`}>Completion</Link></li>
                    <li><Link to="#" className={`${pathname.match('/training') ? '' : ''}`}>Employees</Link></li>
                    <li><Link to="#" className={`${pathname.match('/training') ? '' : ''}`}>Future Plans</Link></li>
                    <li><Link to="#" className={`${pathname.match('/training') ? '' : ''}`}>Social Media</Link></li>
                    <li><Link to="#" className={`${pathname.match('/training') ? '' : ''}`}>Contact Details</Link></li>
                </Route>
            </ul>
        </React.Fragment>
    )
}

export default SuppliersLeftPanel;

