import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData } from "../../services";


export const getCandidateFaqCategory = createAsyncThunk(
    "candidatefaqsFaq/categoryList",
    async ({ }, thunkAPI) => {
        const res = await GetData(true, "/candidate-faqs-category/list/");
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getOnClickQuesAns = createAsyncThunk(
    "candidatefaqsFaq/onclick-default/categoryList",
    async ({ compid }, thunkAPI) => {
        const res = await GetData(true, "/candidate-faqs-question-answer/tab/list/" + compid);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getdefaultCategory = createAsyncThunk(
    "candidatefaqsFaq/default/categoryList",
    async ({ compid }, thunkAPI) => {
        const res = await GetData(true, "/candidate-faqs-category/default/catlist/" + compid);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getQuesAnsByCompanyId = createAsyncThunk(
    "candidatefaqsFaq/get-by-company-ques-ans",
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, "/candidate-faqs-question-answer/listByCompid/" + id);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getQueAns = createAsyncThunk(
    "candidatefaqsFaq/get-ques-ans",
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, "/candidate-faqs-question-answer/list/" + id);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getdefaultQuesAns = createAsyncThunk(
    "candidatefaqsFaq/default/get-ques-ans",
    async ({ }, thunkAPI) => {
        const res = await GetData(true, "/candidate-faqs-question-answer/default/list/");
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const usersSlice = createSlice({
    name: "CandidatefaqsFaqSlice",
    initialState: {
        CandidateFaqCategoryList: [],
        CandidateFaqQuesAnsList: [],
        isSuccess: false,
        isTabshow: false,
        isLoading: false,
        isSubLoading: false,
        isError: false,
        isData: false,
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSubCatInsert = false;
            state.isSuccess = false;
            state.isTabshow = false;
            state.isError = false;
            state.isData = false;
            state.CandidateFaqQuesAnsList = [];
        },
    },
    extraReducers: {
        [getOnClickQuesAns.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getOnClickQuesAns.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.CandidateFaqQuesAnsList = payload.result;
        },
        [getOnClickQuesAns.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [getdefaultCategory.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getdefaultCategory.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.CandidateFaqCategoryList = payload.result;
        },
        [getdefaultCategory.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [getCandidateFaqCategory.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getCandidateFaqCategory.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.CandidateFaqCategoryList = payload.result;
        },
        [getCandidateFaqCategory.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [getdefaultQuesAns.pending]: (state) => {
            state.isSuccess = false;
            //state.isLoading = true;
            state.isError = false;
        },
        [getdefaultQuesAns.fulfilled]: (state, { payload }) => {
            state.isSuccess = true;
            //state.isLoading = false;
            state.CandidateFaqQuesAnsList = payload.result;
        },
        [getdefaultQuesAns.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            //state.isSuccess = false;
        },

        [getQueAns.pending]: (state) => {
            state.isSuccess = false;
            state.isTabshow = false;
            state.isError = false;
        },
        [getQueAns.fulfilled]: (state, { payload }) => {
            state.isTabshow = true;
            state.isSuccess = true;
            state.CandidateFaqQuesAnsList = payload.result;
        },
        [getQueAns.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isTabshow = false;
            state.isSuccess = false;
        },

        [getQuesAnsByCompanyId.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getQuesAnsByCompanyId.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.CandidateFaqQuesAnsList = payload.result;
        },
        [getQuesAnsByCompanyId.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
    },
});

export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
