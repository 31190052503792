import React, { useState, useEffect } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import './addnewcategory.scss';
import Submit from "../../../../../src/components/ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { categoryAdd, clearState } from "../../../../slice/backendValues/backendValuesSlice";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";

const AddCategoryModal = ({ boxNo, parentId1, parentId2, parentId3, parentId4, parentId5, headingText }) => {

    const dispatch = useDispatch();

    const { isInsert, isError, msg } = useSelector(state => state.backendValues)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [name, setName] = useState('');

    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (!name) {
            dispatch(showError({ msg: 'Please enter category name.' }))
        } else {
            setSuccess(false);
            setLoading(true);
            switch (boxNo) {
                case '1':
                    await dispatch(categoryAdd({ boxNo, parentId: 0, name }))
                    break;

                case '2':
                    await dispatch(categoryAdd({ boxNo, parentId: parentId1, name }))
                    break;

                case '3':
                    await dispatch(categoryAdd({ boxNo, parentId: parentId2, name }))
                    break;

                case '4':
                    await dispatch(categoryAdd({ boxNo, parentId: parentId3, name }))
                    break;

                case '5':
                    await dispatch(categoryAdd({ boxNo, parentId: parentId4, name }))
                    break;
            }
        }
    }

    useEffect(() => {
        setName('')
    }, [show])

    useEffect(() => {
        if (isInsert) {
            dispatch(showSuccess({ msg: 'Category added successfully.' }))
            dispatch(clearState())
            setSuccess(true);
            setLoading(false);
            setShow(false)
        }
        if (isError) {
            dispatch(showError({ msg }))
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }
    }, [isInsert, isError])
    return (
        <div>
            <Link to="#" onClick={handleShow}><i className="fas fa-plus"></i></Link>

            <Modal className="dk-addnewCategoryMain" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton className='dk-addnewCategoryHeader'>
                    <Modal.Title>{headingText}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='dk-addnewCategoryBody pl-0 pr-0'>
                    <form action="">
                        <div className="form-group">
                            <label htmlFor="">Name of category</label>
                            <label htmlFor="">
                                <input type="text" name="" id="" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                            </label>
                        </div>
                        <div className="form-footer mb-0">
                            <button className="closeBtn mr-2">Close</button>
                            <Submit txt="Submit" loading={loading} success={success} onClick={handleButtonClick} position="justify-content-center" />
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddCategoryModal;
