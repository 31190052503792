import React, { useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import "./budget.css";
import "./performance.scss"
import Dropdown from 'react-bootstrap/Dropdown';
import BudgetsAdd from './modal/BudgetsAdd';
import BudgetsEdit from './modal/BudgetsEdit';
import DeleteModal from "./modal/DeleteModal";
import { showSuccess } from "../../../../slice/utils/message/messageSlice";
import { listTable,deleteFunction,clearState,fetchById,updateByKey } from "../../../../slice/costBenifits/advBudgets";
import TableLoader from "../../../ui/tableLoader";
import { cvSourceDataFetch } from "../../../../slice/kpis/assignKpisReportSlice";
import { SYSTEM_CONSTANTS } from "../../../../constants";
import UpdateNumber from "./modal/UpdateNumber";

const Budget = () => {
    const dispatch = useDispatch();
    const { dataList, isLoading, isUpdate, isInsert, isReorder, isSort, isBulkDelete, isPerformanceDelete } = useSelector(state => state.budgetsdata);
    const [list, setList] = useState([]);
    const [type, setType] = useState('Cost')
    const [columnKey, setColumnKey] = useState('')
    const [columnValue, setColumnValue] = useState('0')
    const [rowId, setRowId] = useState('0')
    const [isUpdatePop, setIsUpdatePop] = useState(false)
    const [issDelete, setIssDelete] = useState(false)

    var today = new Date();
    const [yearValue, setYearValue] = useState(today.getFullYear())
    const [monthValue, setMonthValue] = useState(today.getMonth() + 1)
    const changeYears = (data) => {
        setYearValue(data)
    }

    const retrieveData = () => {
        let monthCha=null
        switch(monthValue){
            case 1:
                monthCha="January"
                break
            case 2:
                monthCha="February"
                break
            case 3:
                monthCha="March"
                break
            case 4:
                monthCha="April"
                break
            case 5:
                monthCha="May"
                break
            case 6:
                monthCha="June"
                break
            case 7:
                monthCha="July"
                break
            case 8:
                monthCha="August"
                break
            case 9:
                monthCha="September"
                break
            case 10:
                monthCha="October"
                break
            case 11:
                monthCha="November"
                break
            default:
                monthCha="December"
                break   
        }       
        dispatch(listTable({ year: yearValue, month: monthCha, type: type }))
    }
    useEffect(retrieveData, [yearValue, monthValue, type]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isUpdateNumber, setIsUpdateNumber] = useState(false)

    const clearFilter = () => {
        setType('Cost')
    }

    useEffect(() => {
        if (!isLoading && dataList) {
            setList(dataList)
        }
        if (isUpdate) {
            setList(dataList)
            dispatch(clearState())
            handleClose()
        }
        if (isInsert) {
            setList(dataList)
            dispatch(clearState())
        }
        if (isReorder) {
            setList(dataList)
            dispatch(clearState())
            retrieveData()
        }
        if (isPerformanceDelete) {
            setIssDelete(false)
            setLoading(false)
            dispatch(showSuccess({ msg: 'Delete Successfully.' }))
            dispatch(clearState())
            setList(dataList)
        }
        if (isSort) {
            setList(dataList)
            dispatch(clearState())
        }
        const fetchList = async () => {
            try {
                await dispatch(cvSourceDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.cvSourceId }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();

    }, [isLoading, isBulkDelete, isUpdate, isInsert, isReorder, isPerformanceDelete, isSort])

    const openUpdateValuePop = (id, key, value) => {
        setRowId(id)
        setColumnKey(key)
        setColumnValue(value)
    }

    const functionType = async (type,idd) => {     
        await dispatch(updateByKey({ id:idd, key: 'cost', value:type }))
    }

    const editFunction = async (idd) => {       
        await dispatch(fetchById({id:idd}))
        setIsUpdatePop(true)        
    }
    const handleClose = () => {
        setIsUpdatePop(false)
    }
    const deletehandleClose = () => {
        setIssDelete(false)
    }
    const deleteDataFunction = (idd) => {
        setIssDelete(true)
        setRowId(idd)
    }

    const deletesItem = async () => {        
        await dispatch(deleteFunction({ id: rowId }))                  
        setLoading(true)
        setSuccess(false)
    }

    const showupdateNumber = (ids,col,val) => {
        setRowId(ids)
        setColumnKey(col)
        setColumnValue(val)
        setIsUpdateNumber(true)
    }
    const hideupdateNumber = () => {
        setIsUpdateNumber(false)
    }
    return (
        <React.Fragment>
            {
                (isUpdateNumber) ? <UpdateNumber onHide={hideupdateNumber}  rowId={rowId} columnKey={columnKey} value={columnValue} /> : null
            }
            {
                isUpdatePop ? <BudgetsEdit year={yearValue} month={monthValue}  handleClose={handleClose}/> : null
            }
           {
               issDelete ? <DeleteModal loading={loading} success={success} deleteItem={deletesItem}  handleClose={deletehandleClose} tableName="advertisment_performances" /> : null
           }
            <div className="container-fluid dk-budgetPage-mainCon">
                <div className="row">
                    <h2 className="dk-budgetPageTitle w-100 text-center">
                    MONTHLY RESOURCE BUDGET
                    </h2>
                </div>
                <div className='dkg-budget-top-headerCon'>
                    <div className="dk-budgetTopLeft">
                        {/* <div className="dk-mailshotsSearch mr-2">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search..." />
                            </div>
                        </div> */}
                        <div className='dkg-budget-yearsDropdownCon mr-2'>

                            <Dropdown className='dkg-budget-yearsDropdown'>
                                <Dropdown.Toggle variant="success" className='dkg-budget-years-toggle'>
                                    {yearValue}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='dkg-budget-dropdownMenu'>
                                    <Dropdown.Item onClick={() => changeYears('2023')}>2023</Dropdown.Item>
                                    <Dropdown.Item onClick={() => changeYears('2022')}>2022</Dropdown.Item>
                                    <Dropdown.Item onClick={() => changeYears('2021')}>2021</Dropdown.Item>
                                    <Dropdown.Item onClick={() => changeYears('2020')} >2020</Dropdown.Item>
                                    <Dropdown.Item onClick={() => changeYears('2019')} >2019</Dropdown.Item>
                                    <Dropdown.Item onClick={() => changeYears('2018')} >2018</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>
                        <BudgetsAdd year={yearValue} month={monthValue} />
                    </div>
                    <div className="dk-creTrackTopCon">
                        <div className="dk-creTrackTabsCon">

                            <ul className="dk-budgetYears-Tabs">
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue(1)} className={(monthValue === 1) ? 'dkg-months-link active' : 'dkg-months-link'}>Jan</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue(2)} className={(monthValue === 2) ? 'dkg-months-link active' : 'dkg-months-link'}>Feb</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue(3)} className={(monthValue === 3) ? 'dkg-months-link active' : 'dkg-months-link'}>Mar</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue(4)} className={(monthValue == 4) ? 'dkg-months-link active' : 'dkg-months-link'}>Apr</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue(5)} className={(monthValue == 5) ? 'dkg-months-link active' : 'dkg-months-link'}>May</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue(6)} className={(monthValue == 6) ? 'dkg-months-link active' : 'dkg-months-link'}>Jun</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue(7)} className={(monthValue == 7) ? 'dkg-months-link active' : 'dkg-months-link'}>Jul</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue(8)} className={(monthValue == 8) ? 'dkg-months-link active' : 'dkg-months-link'}>Aug</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue(9)} className={(monthValue == 9) ? 'dkg-months-link active' : 'dkg-months-link'}>Sep</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue(10)} className={(monthValue == 10) ? 'dkg-months-link active' : 'dkg-months-link'}>Oct</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue(11)} className={(monthValue == 11) ? 'dkg-months-link active' : 'dkg-months-link'}>Nov</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue(12)} className={(monthValue == 12) ? 'dkg-months-link active' : 'dkg-months-link'}>Dec</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="dk-budgetTopRight-Con">
                        <div className="dk-budgetTopRight">                            
                            <div className="dk-reSLoginFilter">
                                {
                                    (type != "Cost") ? <i className="fas fa-times-circle" data-type="type" onClick={clearFilter}></i> : null
                                }
                                <Dropdown className='dk-resloginfilterDrop'>
                                    <Dropdown.Toggle variant="" className='dk-resloginfilter-toggle'>
                                        {type}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dk-resloginfilterDropMenu'>
                                        <Dropdown.Item href="#" onClick={() => setType('Fixed')} style={{ backgroundColor: "#31a886", color: "#fff" }}>Fixed</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={() => setType('Variable')} style={{ backgroundColor: "#dc3545", color: "#fff" }}>Variable</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dk-budgetTableCont">
                    <table className="table dk-budgetTable" cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th>SNo.</th>
                                <th>COST</th>
                                <th>Name of Resource</th>
                                <th>SERVICES NOTES</th>
                                <th>Budget</th>
                                <th>SPENT</th>
                                <th>REMAINING</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        {
                            (!isLoading) ?
                                <tbody>
                                    {
                                        list.map((item, index) =>
                                            <tr key={index + 1}>
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    <div className="dk-adsStatusBtn">
                                                        <Dropdown id={`type${index + 1}`}>
                                                            <Dropdown.Toggle variant="" id="dk-adsstatustoggle" style={{ backgroundColor: item.cost == 'Fixed' ? "#31a886" : "#dc3545", color: "#fff" }}>
                                                                {item.cost}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#" onClick={() => functionType('Fixed',item.id)} style={{ backgroundColor: "#31a886", color: "#fff" }}>Fixed</Dropdown.Item>
                                                                <Dropdown.Item href="#" onClick={() => functionType('Variable',item.id)} style={{ backgroundColor: "#dc3545", color: "#fff" }}>Variable</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </td>

                                                <td onClick={() => openUpdateValuePop(item.id, 'resource', item.resource)}>
                                                    {
                                                        item.resource
                                                    }
                                                </td>
                                                <td onClick={() => openUpdateValuePop(item.id, 'serviceNotes', item.serviceNotes)}>
                                                    {
                                                        item.serviceNotes
                                                    }
                                                </td>
                                                <td onClick={() => showupdateNumber(item.id, 'budget', item.budget)}>
                                                    {
                                                        item.budget
                                                    }
                                                    {/* <span className='ml-1'>€</span> */}
                                                </td>
                                                <td onClick={() => showupdateNumber(item.id, 'spent', item.spent)}>
                                                    {
                                                        item.spent
                                                    }
                                                </td>
                                                <td onClick={() => showupdateNumber(item.id, 'remaining', item.remaining)}>
                                                    {
                                                        item.remaining
                                                    }
                                                </td>
                                               
                                                <td>
                                                    <div className="dk-adstractAction">
                                                        <Link className="dk-edit-btn mr-2" to="#" onClick={() => editFunction(item.id)}>
                                                            <i className="fa fa-edit " aria-hidden="true" ></i>
                                                        </Link>
                                                        <Link className="dk-delete-btn" onClick={() => deleteDataFunction(item.id)}>
                                                            <i className="fa fa-trash " aria-hidden="true"></i>
                                                        </Link>                                                     
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                                :
                                <TableLoader colSpan="8" />
                        }

                        <tfoot>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    &nbsp;
                                </td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>                              
                               
                                <td> {
                                    list.reduce((a, v) => a = a + Number(v.budget), 0)
                                }<span className='ml-1'>€</span></td>
                                <td> {
                                    list.reduce((a, v) => a = a + Number(v.spent), 0)
                                }<span className='ml-1'>€</span></td>
                                <td> {
                                    list.reduce((a, v) => a = a + Number(v.remaining), 0)
                                }<span className='ml-1'>€</span></td>
                                <td>&nbsp;</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Budget;
