import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";

const VisaInfoOverViewLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/knowleadge-base/visa-info/overview" className={`${pathname.match('/knowleadge-base/visa-info/overview') ? 'active' : ''}`}>Overview</Link></li>
                    {/* <li><Link to="/visa-info/summary" className={`${pathname.match('/permissions') ? 'active' : ''}`}>Summary</Link></li> */}
                    <li><Link to="/knowleadge-base/visa-info/concerns" className={`${pathname.match('/knowleadge-base/visa-info/concerns') ? 'active' : ''}`}>Concerns</Link></li>
                    <li><Link to="/knowleadge-base/visa-info/permissions" className={`${pathname.match('/permissions') ? 'active' : ''}`}>Permissions</Link></li>
                    <li><Link to="/knowleadge-base/visa-info/all-visa-type" className={`${pathname.match('/all-visa-type') ? 'active' : ''}`}>All Visa Type</Link></li>
                    <li><Link to="/knowleadge-base/visa-info/student-visa" className={`${pathname.match('/student-visa') ? 'active' : ''}`}>Student Visa</Link></li>
                    <li><Link to="#/knowleadge-base/visa-info/work-permit" className={`${pathname.match('/work-permit') ? 'active' : ''}`}>Work Permit</Link></li>
                    <li><Link to="#/knowleadge-base/visa-info/residency" className={`${pathname.match('/residency') ? 'active' : ''}`}>Residency</Link></li>
                    <li><Link to="#/knowleadge-base/visa-info/married" className={`${pathname.match('/married') ? 'active' : ''}`}>Married</Link></li>
                    <li><Link to="#/knowleadge-base/visa-info/situations" className={`${pathname.match('/situations') ? 'active' : ''}`}>Situations</Link></li>
                    <li><Link to="#/knowleadge-base/visa-info/questions" className={`${pathname.match('/questions') ? 'active' : ''}`}>Questions</Link></li>
                    <li><Link to="#/knowleadge-base/visa-info/countries" className={`${pathname.match('/countries') ? 'active' : ''}`}>Countries</Link></li>
                </Route>
            </ul>
        </React.Fragment>
    )
}

export default VisaInfoOverViewLeftPanel;

