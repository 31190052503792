import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import RightFilter from "./rightModalFilter";
import DeleteLog from "./delete/DeleteLog";

// import {
//   getCandidateLogs
// } from "../../../../../../../slice/candidates/list/listSlice";

const UserLogs = (props) => {
  const dispatch = useDispatch();
  const { isLogs, logsList } = useSelector((state) => state.candidates);
  const [pageCount, setPageCount] = useState(1);
  const handlePageChange = () => {};

  return (
    <>
      <div className="dkg-jsacc-login-logs-con">
        <div className="dkg-myreferals-header">
          <div className="dkg-myreferals-headerleftCon">
          <Dropdown className="dkg-referal-status-drpdwn mr-2">
              <Dropdown.Toggle variant="" id="" className="dkg-referal-status-drpdwn-toggle">
              Year 
              </Dropdown.Toggle>
              <Dropdown.Menu className="dkg-referal-status-drpdwn-menu">
                <Dropdown.Item href="#" className="dkg-referal-status-item">2026
                  <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">2025
                  <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">2024
                <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="dkg-referal-status-drpdwn">
              <Dropdown.Toggle variant="" id="" className="dkg-referal-status-drpdwn-toggle">
               Month 
              </Dropdown.Toggle>
              <Dropdown.Menu className="dkg-referal-status-drpdwn-menu">
                <Dropdown.Item href="#" className="dkg-referal-status-item">Jan
                  <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">Feb
                <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">Mar
                 <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">Apr
                 <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">May
                 <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">Jun
                 <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">Jul
                 <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">Aug
                 <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">Sep
                 <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">Oct
                 <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">Nov
                 <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">Dec
                 <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="dkg-myreferals-centreCon">
            <div className="dkg-docs-title">JOBSEEKER A/C LOGS</div>
          </div>
          <div className="dkg-myreferals-rightCon">
            {/* <RightFilter /> */}
            <Dropdown className="dkg-referal-status-drpdwn mr-2">
              <Dropdown.Toggle variant="" id="" className="dkg-referal-status-drpdwn-toggle">
              City/Town 
              </Dropdown.Toggle>
              <Dropdown.Menu className="dkg-referal-status-drpdwn-menu">
                <Dropdown.Item href="#" className="dkg-referal-status-item">City/Town 1
                  <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">City/Town 2
                <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">City/Town 3
                <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="dkg-referal-status-drpdwn">
              <Dropdown.Toggle variant="" id="" className="dkg-referal-status-drpdwn-toggle">
               Country 
              </Dropdown.Toggle>
              <Dropdown.Menu className="dkg-referal-status-drpdwn-menu">
                <Dropdown.Item href="#" className="dkg-referal-status-item">Country 1
                  <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">Country 2
                <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">Country 3
                <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="dkg-jsacc-login-logs-tableCon">
          <table className="table dkg-jsacc-login-logs-table">
            <thead>
              <tr>
                <th>SNo</th>
                <th>Account ID</th>
                <th>Name of Candidate</th>
                <th>Date & Time</th>
                <th>Year</th>
                <th>Month</th>
                <th>Country</th>
                <th>City/Town</th>
                <th>Login From</th>
                <th>Duration</th>
                <th>Browser</th>
                <th>IP Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {logsList.length > 0 ? (
                logsList.map((log, index) => {
                  // Parsing the date
                  const date = new Date(log.logingdateTime);
                  // Formatting the date and time
                  const formattedDate = date.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  });
                  const formattedTime = date.toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                  });

                  return (
                    <tr key={index + 2}>
                      <td>{index + 1}</td>
                      <td>{log.accountId}</td>
                      <td>{log.candidateName}</td>
                      <td>{`${formattedDate} ${formattedTime}`}</td>{" "}
                      {/* Here is the formatted date */}
                      <td>{log.year}</td>
                      <td>{log.month}</td>
                      <td>{log.country}</td>
                      <td>{log.city}</td>
                      <td>{log.deviceType} </td>
                      <td>{log.duration}</td>
                      <td>{log.browser}</td>
                      <td>{log.ipAddress}</td>
                      <td>
                        <div className="dkg-jobseeker-action-cols">
                          <DeleteLog />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>No data found</tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="dk-resPaginationCon pagination">
          <div className="dk-resPagination">
            <div className="dk-resLeftPag">
              <div
                className="dk-texCounter"
                style={{ color: "#fff !imporant" }}
              >
                Showing {1} to 4 (of 5 entries)
              </div>
            </div>
            <div className="dk-resrightPag pagination-btns">
              <Pagination
                count={pageCount}
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserLogs;
