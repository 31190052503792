import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'

const index = () => {
    return (
        <>
            <Dropdown className="dkg-mypipe-cs-dropdown">
                <Dropdown.Toggle variant="" className="dkg-mypipe-cs-dropdown-toggle">
                    <div className="caseDiv" style={{ backgroundColor: "rgb(92, 115, 125)" }}>New Case</div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dkg-mypipe-cs-dropdown-menu">
                    <Dropdown.Item href="#" className='dkg-mypipe-cs-dropdown-item' style={{ backgroundColor: "#5c737d", color: "#fff" }}>New Case</Dropdown.Item>
                    <Dropdown.Item href="#" className='dkg-mypipe-cs-dropdown-item' style={{ backgroundColor: "#796698", color: "#fff" }}>Job Spec</Dropdown.Item>
                    <Dropdown.Item href="#" className='dkg-mypipe-cs-dropdown-item' style={{ backgroundColor: "#55496b", color: "#fff" }}>Calling</Dropdown.Item>
                    <Dropdown.Item href="#" className='dkg-mypipe-cs-dropdown-item' style={{ backgroundColor: "#2d9b99", color: "#fff" }}>interested</Dropdown.Item>
                    <Dropdown.Item href="#" className='dkg-mypipe-cs-dropdown-item' style={{ backgroundColor: "#286967", color: "#fff" }}>Sendouts</Dropdown.Item>
                    <Dropdown.Item href="#" className='dkg-mypipe-cs-dropdown-item' style={{ backgroundColor: "#629bd3", color: "#fff" }}>Job Offer</Dropdown.Item>
                    <Dropdown.Item href="#" className='dkg-mypipe-cs-dropdown-item' style={{ backgroundColor: "#4768b0", color: "#fff" }}>Placement</Dropdown.Item>
                    <Dropdown.Item href="#" className='dkg-mypipe-cs-dropdown-item' style={{ backgroundColor: "#a84d4d", color: "#fff" }}>Refused Offer</Dropdown.Item>
                    <Dropdown.Item href="#" className='dkg-mypipe-cs-dropdown-item' style={{ backgroundColor: "#d9714e", color: "#fff" }}>Credit</Dropdown.Item>
                    <Dropdown.Item href="#" className='dkg-mypipe-cs-dropdown-item' style={{ backgroundColor: "#670004", color: "#fff" }}>Not Reachable</Dropdown.Item>
                    <Dropdown.Item href="#" className='dkg-mypipe-cs-dropdown-item' style={{ backgroundColor: "#39485d", color: "#fff" }}>Rec Admin</Dropdown.Item>
                    <Dropdown.Item href="#" className='dkg-mypipe-cs-dropdown-item' style={{ backgroundColor: "#da3d5e", color: "#fff" }}>Closed</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default index