import React, { useState } from 'react';
import './adscredits.scss';

const AdsCredits = () => {
    return (
        <>
        </>
    )
}

export default AdsCredits;
