import React from 'react';
import RulesleftPanel from './leftpanel/RulesleftPanel';
import './modulerules.scss';

const ModuleRecruitment = () => {
    return (
        <>
            <div className="dk-moduleRules-main">
                <div className="dk-moduleRules-cols">
                    <RulesleftPanel />
                    <div className="dk-moduleRules-rightPanel">
                        <div className="title">Overview</div>
                        <div className="dk-moduleRules-rightCols">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModuleRecruitment;
