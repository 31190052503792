import React, { useState } from 'react';
import FaqKpiLeftPanel from "../leftpanel/FaqKpiLeftPanel";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import MoreDetailModal from '../MoreDetailModal';
import TItleIcon from "../../../../assets/images/kpi.png";
import DkGlobalLeftTabs from "../detaillefttabs/dkglobaldetaillefttabs/DkGlobalLeftTabs";
import '../faqdkglobalcrm.scss';

const KpiSample = () => {
    const [isShowMore, setIsShowMore] = useState(false);
    const showmoreDetails = () => {
        console.log("Show")
        setIsShowMore(true);
    }
    const hidemoreDetails = () => {
        setIsShowMore(false);
    }

    return (
        <React.Fragment>
            {
                (isShowMore) ? <MoreDetailModal handleClose={hidemoreDetails} /> : null
            }

            <div className="dk-backBtnMain dk-mainMenuFaqBtn">
                <h3 className="dk-detailTitle">
                    <img src={TItleIcon} className="img-fluid" alt="" />
                    KPI
                </h3>
            </div>

            <div className="dk-trainingDetailMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <FaqKpiLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain dk-detailTabsMainOverviewMain">
                                <h3 className="title">Sample Pages</h3>
                                <Tabs defaultActiveKey="tabs_1">
                                    <Tab eventKey="tabs_1" title="Details">
                                        <DkGlobalLeftTabs />
                                    </Tab>
                                    <Tab eventKey="tabs_2" title="Samples">
                                        <DkGlobalLeftTabs />
                                    </Tab>
                                    <Tab eventKey="tabs_3" title="Video">
                                        <DkGlobalLeftTabs />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default KpiSample;

