import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const ContractsMenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalfinanceContractsMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/dkglobalfinance/contracts/summary" className={(segment3 == 'summary') ? 'active' : ''}>Summary</Link></li>
                        <li><Link to="/dkglobalfinance/contracts/dkgstaff/2022" className={`${(segment3 == 'dkgstaff') ? 'active' : ''}`}>Clients</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'teams') ? '' : ''}`}>Suppliers</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'job-boards') ? '' : ''}`}>Job Boards</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'employees') ? '' : ''}`}>Employees</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'others') ? '' : ''}`}>Others</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'samples') ? '' : ''}`}>Samples</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default ContractsMenu;