import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const ChatsDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="#" className={`${pathname.match('/training/commontraining') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/business') ? 'active' : ''}`}>Chat Tools</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/industry') ? 'active' : ''}`}>Shedule</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/services') ? 'active' : ''}`}>Chat Type</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/clients') ? 'active' : ''}`}>Logins</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/suppliers') ? 'active' : ''}`}>Length</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/completion') ? 'active' : ''}`}>Greetings</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Forwarding</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Bookmarks</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Stay Careful</Link></li>


                </Route>
            </ul>
        </React.Fragment>
    )
}

export default ChatsDetailLeftPanel;

