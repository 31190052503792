import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const StatusDropdown = () => {
  return (
    <>
        <Dropdown className="dk-trackingTblDropdown">
            <Dropdown.Toggle variant="" id="dropdown-basic">
                <span style={{background:'#26597d'}}>To Do</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item href="#" style={{background:'#26597d', color:'#fff'}}>To Do</Dropdown.Item>
                <Dropdown.Item href="#" style={{background:'#d58b5d', color:'#fff'}}>In Progress</Dropdown.Item>
                <Dropdown.Item href="#" style={{background:'#3a9469', color:'#fff'}}>Completed</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </>
  )
}

export default StatusDropdown;