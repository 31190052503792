import React, { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { updateValue,clearState } from "../../../../../slice/companies/clients/clientSlice";
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import './companysumm.scss'
import SubmitButton from '../../../../ui/submitButton'

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const CompanySummary = ({id,details}) => {
    const { isUpdate } = useSelector(state => state.companies);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
      const [editorDescription, setEditorDescription] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
     let description =  editorDescription !== undefined && editorDescription !== null ? editorDescription : "";  
    const html = description;
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
    );
    
    useEffect(() => {
    setEditorDescription(details)    
    setEditorState(EditorState.createEmpty());
    if (description !== "") {
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [description,details]);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

     const editorStateChange = async (state) => {
        setEditorState(state); 
     };

    const handleButtonClick = async () => {  

    let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));  
   // let currentContentAsHTML = editorState.getCurrentContent().getPlainText();  
 
    setSuccess(true);
    setLoading(true);
    if(currentContentAsHTML=="<p></p>\n"){
      dispatch(updateValue({ id, key: 'companyDescription', value: '' }));
    }else{
      dispatch(updateValue({ id, key: 'companyDescription', value: currentContentAsHTML })); 
    }

   }


     useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setSuccess(false);
      setLoading(false);
      setShow(false)
    }
  }, [isUpdate]);

    return (
        <>

            <Link to="#" onClick={handleShow}>
                <i className="fa fa-plus" aria-hidden="true"></i>
            </Link>
            <Modal show={show} onHide={handleClose} className="dkg-listview-compysumm-Modal" centered>
                <Modal.Header closeButton className="dkg-listview-compysumm-ModalHeader">
                    <Modal.Title>Add  New Company Summary</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-listview-compysumm-ModalBody">
                    <div className="dkg-listview-company-editor12">
                        <Editor
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            editorState={editorState}
                            onEditorStateChange={editorStateChange}
                            toolbar={{
                              options: [
                                "inline",
                                "fontSize",
                                "fontFamily",
                                "list",
                                "textAlign",
                                "colorPicker",
                                "link",
                                "image",
                              ],
                              inline: { inDropdown: false },
                              list: { inDropdown: false },
                              textAlign: { inDropdown: false },
                              link: { inDropdown: false },
                              history: { inDropdown: false },
                            }}
                            
                        />
                    </div>
                    <div className="dkg-compaysbt-btnCon">
                        <SubmitButton txt="SUBMIT" className="dkg-create-payment-taskbtn" loading={loading} success={success}  onClick={handleButtonClick} />
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default CompanySummary