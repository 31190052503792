import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown'

const SquareDropdown = ({id,dataVal,dataKey,changeColorType}) => {

    const [color, setColor] = useState(dataVal)
    const colorType =(colr)=>{
        setColor(colr)
        changeColorType(id,colr,dataKey)
    }

useEffect(() => {
      setColor(dataVal)
  }, [dataVal]);


    return (
        <>
            <div className="dkg-square-dropdown-Con">
                <Dropdown className="dkg-square-dropdown">
                    <Dropdown.Toggle variant="" className="dkg-square-dropdown-toggle">
                        <div className="dkg-squreIcon" style={{ backgroundColor: color }}></div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dkg-square-dropdown-Menu">
                        <Dropdown.Item href="#" className="dkg-square-dropdown-item" onClick={() =>colorType("#048204")} >
                            <div className="dkg-squreicon-item" style={{ backgroundColor: "#048204" }}></div>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" className="dkg-square-dropdown-item" onClick={() =>colorType("#F1BF42")} >
                            <div className="dkg-squreicon-item" style={{ backgroundColor: "#F1BF42" }}></div>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" className="dkg-square-dropdown-item" onClick={() =>colorType("#d60101")} >
                            <div className="dkg-squreicon-item" style={{ backgroundColor: "#d60101" }}></div>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" className="dkg-square-dropdown-item" onClick={() =>colorType("#eaeaea")} >
                            <div className="dkg-squreicon-item" style={{ backgroundColor: "#eaeaea" }}></div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default SquareDropdown