import React from 'react'
// import Submenu from '../../../elements/header/navbar/subMenu/menu/businessbook/2022/BusinessBookSubmenu'

const index = () => {
  return (
    <>
    <div className="dkg-casestatus-mainpageCon" style={{marginTop: "180px"}}>
    </div>
    </>
  )
}

export default index