import React, { useState, useEffect } from "react";

const Summary = () => {
  return (
    <>
      <div className="container-fluid dk-jobsSummMainPageCon">
        <div
          className="row"
          style={{ marginRight: "-10px", marginLeft: "-10px" }}
        >
          <div className="col-md-12 p-0">
            <div className="dk-jobsuMMBoxCon">

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
