import React from 'react';
import './dkglobalcandidates.scss';
import CandidatesLeftPanel from "./leftpanel/CandidatesLeftPanel";

const DkGlobalCandidates = () => {
    return (
        <>
            <div className="dk-candidatesDetailMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <CandidatesLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                <h3 className="title">Overview</h3>
                                <div className="dk-trainingInformation-cols">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid similique dolore laboriosam reprehenderit sapiente at iusto? Soluta tempora eveniet suscipit quae aliquid provident rem exercitationem ipsa eos aspernatur? Necessitatibus, optio?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default DkGlobalCandidates;
