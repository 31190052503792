import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import './dbsummary.scss';
import CaseStatusFilter from './StatusDropdown';
import { Tags } from "./Tags";
import { categoryNumbersCounts, flagsNumbersCounts, genderNumbersCounts, cvSourceNumbersCounts, recrutersNumbersCounts, nationalityNumbersCounts, languageNumbersCounts, companyNumbersCounts, caseStatusNumbersCounts, workPermitCounts } from '../../../../../src/slice/candidates/database/databaseSlice'

const DBSummary = () => {
    const dispatch = useDispatch();
    const { categoryCount, flagsCount, genderCount, cvSourcerCount, nationalityCount, languageCount, companyCount, recrutersCount, caseStatusCount, workPermitCount } = useSelector(state => state.candidateDatabase)

    const [filterTags, setFilterTags] = useState([]);
    const [tagLength, setTagLength] = useState(0);
    const [caseStatusFilterDDLText, setCaseStatusFilterDDLText] = useState('Case Status')

    const [filter, setFilter] = useState({});
    const [candidateFilter, setCandidateFilter] = useState({});
    const [companyfilter, setCompanyfilter] = useState({});

    const [isCategoryFilter, setIsCategoryFilter] = useState(false);
    const [categoryFilterText, setCategoryFilterText] = useState('');

    const [isFlagFilter, setIsFlagFilter] = useState(false);
    const [flagFilterText, setFlagFilterText] = useState('');

    const [isGenderFilter, setIsGenderFilter] = useState(false);
    const [genderFilterText, setGenderFilterText] = useState('');

    const [isWorkPermitFilter, setIsWorkPermitFilter] = useState(false);
    const [workPermitFilterText, setWorkPermitFilterText] = useState('');

    const [isRecruiterFilter, setIsRecruiterFilter] = useState(false);
    const [recruiterFilterText, setRecruiterFilterText] = useState('');

    const [isCompanyFilter, setIsCompanyFilter] = useState(false);
    const [companyFilterText, setCompanyFilterText] = useState('');

    const [isCVSourceFilter, setIsCVSourceFilter] = useState(false);
    const [cvSourceFilterText, setCVSourceFilterText] = useState('');

    const [isNationalityFilter, setIsNationalityFilter] = useState(false);
    const [nationalityFilterText, setNationalityFilterText] = useState('');

    const [isLanguageFilter, setIsLanguageFilter] = useState(false);
    const [languageFilterText, setLanguageFilterText] = useState('');

    const [removeFilter, setRemoveFilter] = useState(0);

    const getRequestParams = (filter) => {
        let params = {};
        params["filter"] = filter;
        return params;
    }

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    useEffect(() => {
        async function fetchData() {
            try {
                
                const params = getRequestParams(filter);
                dispatch(caseStatusNumbersCounts(params))
                dispatch(categoryNumbersCounts(params))
                dispatch(flagsNumbersCounts(params))
                dispatch(genderNumbersCounts(params))
                dispatch(cvSourceNumbersCounts(params))
                dispatch(languageNumbersCounts(params))
                dispatch(nationalityNumbersCounts(params))
                dispatch(companyNumbersCounts(params))
                dispatch(recrutersNumbersCounts(params))
                dispatch(workPermitCounts(params))
            } catch (err) {
                console.log(err)
            }
        }
        if (isEmpty(filter)) {
            fetchData()
        }
    }, [filter])

    useEffect(() => {
        if (removeFilter > 0 && isEmpty(filter)) {
            try {
                const params = getRequestParams(filter);
                dispatch(caseStatusNumbersCounts(params))
                dispatch(categoryNumbersCounts(params))
                dispatch(flagsNumbersCounts(params))
                dispatch(genderNumbersCounts(params))
                dispatch(cvSourceNumbersCounts(params))
                dispatch(languageNumbersCounts(params))
                dispatch(nationalityNumbersCounts(params))
                dispatch(companyNumbersCounts(params))
                dispatch(recrutersNumbersCounts(params))
                dispatch(workPermitCounts(params))
            } catch (err) {
                console.log(err)
            }
        }
    }, [removeFilter])

    const getFilterRequestParams = (candidateFilter, companyfilter) => {
        let params = {};
        params["filter"] = {
            "candidateFilter": candidateFilter,
            "companyfilter": companyfilter
        }
        return params;
    }

    const selectFilter = async (e) => {
        const type = e.target.dataset.type;
        const value = e.target.dataset.value;
        let i;
        switch (type) {
            case 'caseStatus':
                i = filterTags.findIndex(function (x) { return x.type === 'caseStatus' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setCaseStatusFilterDDLText(value)
                setCandidateFilter({ ...candidateFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'company':
                setIsCompanyFilter(true)
                setCompanyFilterText(value)
                i = filterTags.findIndex(function (x) { return x.type === type })
                if (i === -1) {
                    filterTags.push({ type, value })
                }
                setCompanyfilter({ ...companyfilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'lang':
                setIsLanguageFilter(true)
                setLanguageFilterText(value)
                i = filterTags.findIndex(function (x) { return x.type === type })
                if (i === -1) {
                    filterTags.push({ type, value })
                }
                setCompanyfilter({ ...companyfilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'category':
                setIsCategoryFilter(true)
                setCategoryFilterText(value)
                setCandidateFilter({ ...candidateFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'flag_type':
                setIsFlagFilter(true)
                setFlagFilterText(e.target.dataset.text)
                setCandidateFilter({ ...candidateFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'gender':
                setIsGenderFilter(true)
                setGenderFilterText(value)
                setCandidateFilter({ ...candidateFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'visaStatus':
                setIsWorkPermitFilter(true)
                setWorkPermitFilterText(value)
                setCandidateFilter({ ...candidateFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'nationality':
                setIsNationalityFilter(true)
                setNationalityFilterText(value)
                setCandidateFilter({ ...candidateFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'cv_source':
                setIsCVSourceFilter(true)
                setCVSourceFilterText(value)
                setCandidateFilter({ ...candidateFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'rec_id':
                setIsRecruiterFilter(true)
                setRecruiterFilterText(e.target.dataset.text)
                setCandidateFilter({ ...candidateFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            default:
                i = filterTags.findIndex(function (x) { return x.type === type })
                if (i === -1) {
                    filterTags.push({ type, value })
                }
                setCandidateFilter({ ...candidateFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;
        }
        setTagLength(tagLength + 1)
        setFilterTags(filterTags)
    }

    useEffect(() => {
        if (!isEmpty(filter)) {
            const params = getFilterRequestParams(candidateFilter, companyfilter);
            dispatch(caseStatusNumbersCounts(params))
            dispatch(categoryNumbersCounts(params))
            dispatch(flagsNumbersCounts(params))
            dispatch(genderNumbersCounts(params))
            dispatch(cvSourceNumbersCounts(params))
            dispatch(languageNumbersCounts(params))
            dispatch(nationalityNumbersCounts(params))
            dispatch(companyNumbersCounts(params))
            dispatch(recrutersNumbersCounts(params))
            dispatch(workPermitCounts(params))
        }
    }, [filter, removeFilter])


    const removeTag = async (e) => {
        const type = e.target.dataset.type
        switch (type) {
            case 'caseStatus':
                setCaseStatusFilterDDLText('Case Status')
                delete candidateFilter[type];
                delete filter[type];
                setCandidateFilter(candidateFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            case 'company':
                setIsCompanyFilter(false)
                setCompanyFilterText('')
                delete companyfilter[type];
                delete filter[type];
                setCompanyfilter(companyfilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            case 'lang':
                setIsLanguageFilter(false)
                setLanguageFilterText('')
                delete companyfilter[type];
                delete filter[type];
                setCompanyfilter(companyfilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            case 'category':
                setIsCategoryFilter(false)
                setCategoryFilterText('')
                await delete candidateFilter[type];
                await delete filter[type];
                setCandidateFilter(candidateFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            case 'flag_type':
                setIsFlagFilter(false)
                setFlagFilterText('')
                delete candidateFilter[type];
                delete filter[type];
                setCandidateFilter(candidateFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            case 'gender':
                setIsGenderFilter(false)
                setGenderFilterText('')
                delete candidateFilter[type];
                delete filter[type];
                setCandidateFilter(candidateFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            case 'visaStatus':
                setIsWorkPermitFilter(false)
                setWorkPermitFilterText('')
                delete candidateFilter[type];
                delete filter[type];
                setCandidateFilter(candidateFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            case 'rec_id':
                setIsRecruiterFilter(false)
                setRecruiterFilterText('')
                delete candidateFilter[type];
                delete filter[type];
                setCandidateFilter(candidateFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            case 'cv_source':
                setIsCVSourceFilter(false)
                setCVSourceFilterText('')
                delete candidateFilter[type];
                delete filter[type];
                setCandidateFilter(candidateFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            case 'nationality':
                setIsNationalityFilter(false)
                setNationalityFilterText('')
                delete candidateFilter[type];
                delete filter[type];
                setCandidateFilter(candidateFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            default:
                delete candidateFilter[type];
                delete filter[type];
                setCandidateFilter(candidateFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;
        }
        setTagLength(tagLength - 1)
        setFilterTags(filterTags.filter((item) => item.type !== type))
    }

    return (
        <>
            <div className="dk-dbummaryMain container-fluid">
                <div className='dk-db-mo-pagetitleCon d-none'>
                    <h3 className="dk-dbsumm-mo-title">MAIN DKG DATABASE</h3>
                </div>
                <div className="dk-canSummPageHeader d-flex justify-content-between">
                    <div className="dk-cansUMMLeft dkg-mo-leftCon d-flex d-none">
                        <Tags filterTags={filterTags} length={tagLength} removeTag={removeTag} />
                    </div>
                    <h3 className="title">MAIN DKG DATABASE</h3>
                    <div className="dk-rightCols">
                        <CaseStatusFilter list={caseStatusCount} caseStatusFilterDDLText={caseStatusFilterDDLText} selectOtherFilter={selectFilter} />
                        {
                            (caseStatusFilterDDLText !== 'Case Status') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="caseStatus" onClick={removeTag}></i> : null
                        }
                    </div>
                </div>
                <div className="row dkg-dbsummbox-mainCon">
                    <div className="col-md-12">
                        <div className="d-flex dkg-db-motop-boxCon">
                            <div className="dk-summaryCols">
                                <div className={`${(!isCategoryFilter) ? 'dk-toptitle' : 'dk-toptitle-filterBg'}`}>
                                    {
                                        (!isCategoryFilter) ?
                                            <div className="dk-colstitle">CATEGORY</div>
                                            :
                                            <div className='dk-dbsuMM-filterHeader'>
                                                {categoryFilterText}
                                                <span><i className='fa fa-times-circle  ml-2' data-type="category" onClick={removeTag}></i></span>
                                            </div>
                                    }
                                    <span className="dk-totalCvCount">
                                        {
                                            categoryCount.reduce(function (tot, arr) {
                                                return tot + arr.count;
                                            }, 0)
                                        }
                                    </span>
                                </div>
                                <li data-type="category" data-value="ML" onClick={selectFilter}>
                                    <div data-type="category" data-value="ML" onClick={selectFilter}>
                                        <i className="fas mr-2 fa-language" aria-hidden="true" data-type="category" data-value="ML" onClick={selectFilter}></i>ML
                                    </div>
                                    <span className="mlCount" data-type="category" data-value="ML" onClick={selectFilter}>
                                        {
                                            categoryCount.filter(items => items.category === "ML").map((item, index) =>
                                                <span className="recNoCount" data-type="category" data-value="ML" onClick={selectFilter}>{item.count}</span>
                                            )
                                        }
                                    </span>
                                </li>
                                <li data-type="category" data-value="IT" onClick={selectFilter}>
                                    <div data-type="category" data-value="IT" onClick={selectFilter}>
                                        <i className="fas mr-2 fa-tv" aria-hidden="true" data-type="category" data-value="IT" onClick={selectFilter}></i>IT
                                    </div>
                                    <span className="mlCount" data-type="category" data-value="IT" onClick={selectFilter}>
                                        {
                                            categoryCount.filter(items => items.category === "IT").map((item, index) =>
                                                <span className="recNoCount" data-type="category" data-value="IT" onClick={selectFilter}>{item.count}</span>
                                            )
                                        }
                                    </span>
                                </li>
                                <li data-type="category" data-value="None" onClick={selectFilter}>
                                    <div data-type="category" data-value="None" onClick={selectFilter}>
                                        <i className="far mr-2 fa-circle" aria-hidden="true" data-type="category" data-value="None" onClick={selectFilter}></i>None
                                    </div>
                                    <span className="mlCount" data-type="category" data-value="None" onClick={selectFilter}>
                                        {
                                            categoryCount.filter(items => items.category === "None").map((item, index) =>
                                                <span className="recNoCount" data-type="category" data-value="None" onClick={selectFilter}>{(item.count)}</span>
                                            )
                                        }
                                    </span>
                                </li>
                            </div>
                            <div className="dk-summaryCols">
                                <div className={`${(!isFlagFilter) ? 'dk-toptitle' : 'dk-toptitle-filterBg'}`}>
                                    <div className='dk-dbsuMM-filterHeader'>
                                        {
                                            (!isFlagFilter) ?
                                                <div className="dk-colstitle">FLAGS</div>
                                                :
                                                <div className='dk-dbsuMM-filterHeader'>
                                                    {flagFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="flag_type" onClick={removeTag}></i></span>
                                                </div>
                                        }
                                    </div>
                                    <span className="dk-totalCvCount">
                                        {
                                            flagsCount.reduce(function (tot, arr) {
                                                return tot + arr.count;
                                            }, 0)
                                        }
                                    </span>
                                </div>
                                <li data-type="flag_type" data-text="Blank" data-value="1" onClick={selectFilter}>
                                    <div data-type="flag_type" data-text="Blank" data-value="1" onClick={selectFilter}>
                                        <i className="far mr-2 fa-flag" data-type="flag_type" data-text="Blank" data-value="1" onClick={selectFilter}></i>Blank
                                    </div>
                                    <span className="mlCount" data-type="flag_type" data-text="Blank" data-value="1" onClick={selectFilter}>
                                        {
                                            flagsCount.filter(items => items.flag_type === 1).map((item, index) =>
                                                <span className="recNoCount" data-type="flag_type" data-text="Blank" data-value="1" onClick={selectFilter}>{(item.count)}</span>
                                            )
                                        }
                                    </span>
                                </li>
                                <li data-type="flag_type" data-text="Red" data-value="3" onClick={selectFilter}>
                                    <div data-type="flag_type" data-text="Red" data-value="3" onClick={selectFilter}>
                                        <i className="fas mr-2 fa-flag text-red" data-type="flag_type" data-text="Red" data-value="3" onClick={selectFilter}></i>Red
                                    </div>
                                    <span className="mlCount" data-type="flag_type" data-text="Red" data-value="3" onClick={selectFilter}>
                                        {
                                            flagsCount.filter(items => items.flag_type === 3).map((item, index) =>
                                                <span className="recNoCount" data-type="flag_type" data-text="Red" data-value="3" onClick={selectFilter}>{(item.count)}</span>
                                            )
                                        }
                                    </span>
                                </li>
                                <li data-type="flag_type" data-text="Green" data-value="4" onClick={selectFilter}>
                                    <div data-type="flag_type" data-text="Green" data-value="4" onClick={selectFilter}>
                                        <i className="fas fa-flag mr-2 text-green" data-type="flag_type" data-text="Green" data-value="4" onClick={selectFilter}></i>Green
                                    </div>
                                    <span className="mlCount" data-type="flag_type" data-text="Green" data-value="4" onClick={selectFilter}>
                                        {
                                            flagsCount.filter(items => items.flag_type === 4).map((item, index) =>
                                                <span className="recNoCount" data-type="flag_type" data-text="Green" data-value="4" onClick={selectFilter}>{(item.count)}</span>
                                            )
                                        }
                                    </span>
                                </li>
                            </div>
                            <div className="dk-summaryCols">
                                <div className={`${(!isGenderFilter) ? 'dk-toptitle' : 'dk-toptitle-filterBg'}`}>
                                    <div className='dk-dbsuMM-filterHeader'>
                                        {
                                            (!isGenderFilter) ?
                                                <div className="dk-colstitle">GENDER</div>
                                                :
                                                <div className='dk-dbsuMM-filterHeader'>
                                                    {genderFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="gender" onClick={removeTag}></i></span>
                                                </div>
                                        }
                                    </div>
                                    <span className="dk-totalCvCount">
                                        {
                                            genderCount.reduce(function (tot, arr) {
                                                return tot + arr.count;
                                            }, 0)
                                        }
                                    </span>
                                </div>
                                <li data-type="gender" data-value="Male" onClick={selectFilter}>
                                    <div data-type="gender" data-text="Blank" data-value="Male" onClick={selectFilter}>
                                        <i className="fas mr-2 fa-male" data-type="gender" data-text="Blank" data-value="Male" onClick={selectFilter}></i>Male
                                    </div>
                                    <span className="mlCount" data-type="gender" data-text="Blank" data-value="Male" onClick={selectFilter}>
                                        {
                                            genderCount.filter(items => items.gender === "Male").map((item, index) =>
                                                <span className="recNoCount" data-type="gender" data-text="Blank" data-value="Male" onClick={selectFilter}>{(item.count)}</span>
                                            )
                                        }
                                    </span>
                                </li>
                                <li data-type="gender" data-value="Female" onClick={selectFilter}>
                                    <div data-type="gender" data-value="Female" onClick={selectFilter}>
                                        <i className="fas mr-2 fa-female" data-type="gender" data-value="Female" onClick={selectFilter}></i>Female
                                    </div>
                                    <span className="mlCount" data-type="gender" data-value="Female" onClick={selectFilter}>
                                        {
                                            genderCount.filter(items => items.gender === "Female").map((item, index) =>
                                                <span className="recNoCount" data-type="gender" data-value="Female" onClick={selectFilter}>{(item.count)}</span>
                                            )
                                        }
                                    </span>
                                </li>
                                <li data-type="gender" data-value="Not Confirmed" onClick={selectFilter}>
                                    <div data-type="gender" data-value="Not Confirmed" onClick={selectFilter}>
                                        <i className="fas mr-2 fa-transgender-alt" data-type="gender" data-value="Not Confirmed" onClick={selectFilter}></i>Not Confirmed
                                    </div>
                                    <span className="mlCount" data-type="gender" data-value="Not Confirmed" onClick={selectFilter}>
                                        {
                                            genderCount.filter(items => items.gender === "Not Confirmed").map((item, index) =>
                                                <span className="recNoCount" data-type="gender" data-value="Not Confirmed" onClick={selectFilter}>{(item.count)}</span>
                                            )
                                        }
                                    </span>
                                </li>
                            </div>
                            <div className="dk-summaryCols">
                                <div className="dk-toptitle">
                                    <div className="dk-colstitle">SPECS</div>
                                    <span className="dk-totalCvCount">
                                        0
                                    </span>
                                </div>
                                <li>
                                    <div><i className="far mr-2 fa-file-alt" aria-hidden="true"></i>Job Specs</div>
                                    <span className="mlCount">0</span>
                                </li>
                                <li>
                                    <div><i className="far mr-2 fa-file-alt" aria-hidden="true"></i>Job Sendouts</div>
                                    <span className="mlCount">0</span>
                                </li>
                                <li>
                                    <div><i className="far mr-2 fa-file-alt" aria-hidden="true"></i>Placements</div>
                                    <span className="mlCount">0</span>
                                </li>
                            </div>
                            <div className="dk-summaryCols">
                                <div className={`${(!isWorkPermitFilter) ? 'dk-toptitle' : 'dk-toptitle-filterBg'}`}>
                                    {
                                        (!isWorkPermitFilter) ?
                                            <div className="dk-colstitle">EU - NON EU</div>
                                            :
                                            <div className='dk-dbsuMM-filterHeader'>
                                                {workPermitFilterText}
                                                <span><i className='fa fa-times-circle  ml-2' data-type="visaStatus" onClick={removeTag}></i></span>
                                            </div>
                                    }
                                    <span className="dk-totalCvCount">
                                        {
                                            workPermitCount.reduce(function (tot, arr) {
                                                return tot + arr.count;
                                            }, 0)
                                        }
                                    </span>
                                </div>
                                <li data-type="visaStatus" data-value="EU National" onClick={selectFilter}>
                                    <div data-type="visaStatus" data-value="EU National" onClick={selectFilter}>
                                        <i className="fas mr-2 fa-user" aria-hidden="true" data-type="visaStatus" data-value="EU National" onClick={selectFilter}></i>EU
                                    </div>
                                    {
                                        workPermitCount.filter(items => items.visaStatus === "EU National").map((item, index) =>
                                            <span className="mlCount" data-type="visaStatus" data-value="EU National" onClick={selectFilter}>{(item.count)}</span>
                                        )
                                    }
                                </li>
                                <li data-type="visaStatus" data-value="Non - EU" onClick={selectFilter}>
                                    <div data-type="visaStatus" data-value="Non - EU" onClick={selectFilter}>
                                        <i className="fas mr-2 fa-user" aria-hidden="true" data-type="visaStatus" data-value="Non - EU" onClick={selectFilter}></i>Non - EU
                                    </div>
                                    {
                                        workPermitCount.filter(items => items.visaStatus === "Non - EU").map((item, index) =>
                                            <span className="mlCount" data-type="visaStatus" data-value="EU National" onClick={selectFilter}>{(item.count)}</span>
                                        )
                                    }
                                </li>
                                <li data-type="visaStatus" data-value="Not Confirmed" onClick={selectFilter}>
                                    <div data-type="visaStatus" data-value="Not Confirmed" onClick={selectFilter}>
                                        <i className="far mr-2 fa-window-close" aria-hidden="true" data-type="visaStatus" data-value="Not Confirmed" onClick={selectFilter}></i>Not Confirmed
                                    </div>
                                    {
                                        workPermitCount.filter(items => items.visaStatus === "Not Confirmed").map((item, index) =>
                                            <span className="mlCount" data-type="visaStatus" data-value="EU National" onClick={selectFilter}>{(item.count)}</span>
                                        )
                                    }
                                </li>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="d-flex dkg-db-motop-boxCon">
                            <div className="dk-summaryCols xl">
                                <div className={`${(!isRecruiterFilter) ? 'dk-toptitle' : 'dk-toptitle-filterBg'}`}>
                                    {
                                        (!isRecruiterFilter) ?
                                            <div className="dk-colstitle">RECRUITERS</div>
                                            :
                                            <div className='dk-dbsuMM-filterHeader'>
                                                {recruiterFilterText}
                                                <span><i className='fa fa-times-circle  ml-2' data-type="rec_id" onClick={removeTag}></i></span>
                                            </div>
                                    }
                                    <span className="dk-totalCvCount">
                                        {recrutersCount.reduce((total, currentValue) => total = total + currentValue.count, 0)}
                                    </span>
                                </div>
                                <div className="dk-summaryList">
                                    {
                                        recrutersCount.map((data, index) => (
                                            <li key={index + 1} data-type="rec_id" data-text={data.recInfo.firstName + " " + data.recInfo.lastName} data-value={data.recInfo.id} onClick={selectFilter}>
                                                <div data-type="rec_id" data-text={data.recInfo.firstName + " " + data.recInfo.lastName} data-value={data.recInfo.id} onClick={selectFilter}>
                                                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + data.recInfo.profileImage} className="summary-rec-img" data-type="rec_id" data-text={data.recInfo.firstName + " " + data.recInfo.lastName} data-value={data.recInfo.id} onClick={selectFilter} />
                                                    {data.recInfo.firstName + " " + data.recInfo.lastName}
                                                </div>
                                                <span data-type="rec_id" data-text={data.recInfo.firstName + " " + data.recInfo.lastName} data-value={data.recInfo.id} onClick={selectFilter}>{data.count}</span>
                                            </li>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="dk-summaryCols xl dk-listing">
                                <div className={`${(!isCompanyFilter) ? 'dk-toptitle' : 'dk-toptitle-filterBg'}`}>
                                    {
                                        (!isCompanyFilter) ?
                                            <div className="dk-colstitle">COMPANIES</div>
                                            :
                                            <div className='dk-dbsuMM-filterHeader'>
                                                {companyFilterText}
                                                <span><i className='fa fa-times-circle  ml-2' data-type="company" onClick={removeTag}></i></span>
                                            </div>
                                    }
                                    <span className="dk-totalCvCount">
                                        {companyCount.reduce((total, currentValue) => total = total + currentValue.count, 0)}
                                    </span>
                                </div>
                                <div className="dk-summaryList">
                                    {
                                        companyCount.map((data, index) => (
                                            <li key={index + 1} data-type="company" data-value={data.jobDetail.company} onClick={selectFilter} >{data.jobDetail.company}
                                                <span data-type="company" data-value={data.jobDetail.company} onClick={selectFilter}>{data.count}</span>
                                            </li>
                                        ))
                                    }
                                </div>

                            </div>
                            <div className="dk-summaryCols xl dk-listing">
                                <div className={`${(!isCVSourceFilter) ? 'dk-toptitle' : 'dk-toptitle-filterBg'}`}>
                                    {
                                        (!isCVSourceFilter) ?
                                            <div className="dk-colstitle">CV SOURCES</div>
                                            :
                                            <div className='dk-dbsuMM-filterHeader'>
                                                {cvSourceFilterText}
                                                <span><i className='fa fa-times-circle  ml-2' data-type="cv_source" onClick={removeTag}></i></span>
                                            </div>
                                    }
                                    <span className="dk-totalCvCount">
                                        {cvSourcerCount.reduce((total, currentValue) => total = total + currentValue.count, 0)}
                                    </span>
                                </div>
                                <div className="dk-summaryList">
                                    {
                                        cvSourcerCount.map((data, index) => (
                                            <li key={index + 1} data-type="cv_source" data-value={data.cv_source} onClick={selectFilter}>{data.cv_source}
                                                <span data-type="cv_source" data-value={data.cv_source} onClick={selectFilter}>{data.count}</span>
                                            </li>
                                        ))
                                    }
                                </div>

                            </div>
                            <div className="dk-summaryCols xl dk-listing">
                                <div className={`${(!isNationalityFilter) ? 'dk-toptitle' : 'dk-toptitle-filterBg'}`}>
                                    {
                                        (!isNationalityFilter) ?
                                            <div className="dk-colstitle">NATIONALITY</div>
                                            :
                                            <div className='dk-dbsuMM-filterHeader'>
                                                {nationalityFilterText}
                                                <span><i className='fa fa-times-circle  ml-2' data-type="nationality" onClick={removeTag}></i></span>
                                            </div>
                                    }
                                    <span className="dk-totalCvCount">
                                        {nationalityCount.reduce((total, currentValue) => total = total + currentValue.count, 0)}
                                    </span>
                                </div>
                                <div className="dk-summaryList">
                                    {
                                        nationalityCount.map((data, index) => (
                                            <li key={index + 1} data-type="nationality" data-value={data.nationality} onClick={selectFilter}> {data.nationality}
                                                <span data-type="nationality" data-value={data.nationality} onClick={selectFilter}>{data.count}</span>
                                            </li>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="dk-summaryCols xl dk-listing">
                                <div className={`${(!isLanguageFilter) ? 'dk-toptitle' : 'dk-toptitle-filterBg'}`}>
                                    {
                                        (!isLanguageFilter) ?
                                            <div className="dk-colstitle">Language</div>
                                            :
                                            <div className='dk-dbsuMM-filterHeader'>
                                                {languageFilterText}
                                                <span><i className='fa fa-times-circle  ml-2' data-type="lang" onClick={removeTag}></i></span>
                                            </div>
                                    }
                                    <span className="dk-totalCvCount">
                                        {languageCount.reduce((total, currentValue) => total = total + currentValue.count, 0)}
                                    </span>
                                </div>
                                <div className="dk-summaryList">

                                    {
                                        languageCount.map((data, index) => (
                                            <li key={index + 1} data-type="lang" data-value={data.jobDetail.lang} onClick={selectFilter}>{data.jobDetail.lang}
                                                <span data-type="lang" data-value={data.jobDetail.lang} onClick={selectFilter}>{data.count}</span>
                                            </li>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DBSummary;
