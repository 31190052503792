import React, { useEffect, useState } from 'react';
import './holidays.scss';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import TypesModal from './TypesModal';
import ReasonModal from './ReasonModal';
import HolidayDetailModal from './HolidayDetailModal';


const options = [
    { key: 1, text: 'Choice 1', value: 1 },
    { key: 2, text: 'Choice 2', value: 2 },
    { key: 3, text: 'Choice 3', value: 3 },
]

const HolidaysModal = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <React.Fragment>
            <button className="dk-calenderBtn" onClick={handleShow}><i className="far fa-file-alt"></i></button>

            <Modal className="dk-holidayModalMain" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div className="dk-holidayModal-left">
                            <Dropdown className="dk-dateDropdown">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    2021
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">2021</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown className="dk-globalUserDropdown">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <i className="fas fa-user"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dk-recruiterDropdown">
                                    <div className="d-flex">
                                        <Dropdown.Item href="#/action-1">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-1">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" className="img-fluid" alt="" />
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-1">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5b4dce328e872.png" className="img-fluid" alt="" />
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-1">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5bd2c15416.png" className="img-fluid" alt="" />
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-1">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-1">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" className="img-fluid" alt="" />
                                        </Dropdown.Item>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>
                        <h3 className="title">DAYS OFF SUMMARY</h3>
                        <div className="dk-holidayModal-right">
                            <TypesModal />
                            <ReasonModal />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="dk-holidaysModal">
                    <div className="dk-mdHolidayTableCont">
                        <table className="table dk-holdayPopupTable table-bordered">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>User</th>
                                    <th>Status</th>
                                    <th>Form</th>
                                    <th>To</th>
                                    <th>Type</th>
                                    <th>Reason</th>
                                    <th>Total Days</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody id="reportJanuary">
                                <tr id="trJanuary">
                                    <td rowspan="1" className="trJanuary janHoldyPopBg" style={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}>January</td>
                                </tr>
                            </tbody>
                            <tbody id="reportFebruary">
                                <tr id="trFebruary">
                                    <td rowspan="1" className="trFebruary febHoldyPopBg">February</td>
                                </tr>
                            </tbody>
                            <tbody id="reportMarch">
                                <tr id="trMarch">
                                    <td rowspan="1" className="trMarch marHoldyPopBg">March</td>
                                </tr>
                            </tbody>
                            <tbody id="reportApril">
                                <tr id="trApril">
                                    <td rowspan="1" className="trApril aprHoldyPopBg">April</td>
                                </tr>
                            </tbody>
                            <tbody id="reportMay">
                                <tr id="trMay">
                                    <td rowspan="1" className="trMay mayHoldyPopBg">May</td>
                                </tr>
                            </tbody>
                            <tbody id="reportJune" className="removeBorder">
                                <tr id="trJune">
                                    <td rowspan="4" className="trJune junHoldyPopBg">June</td>
                                    <td className="dk-userImg">
                                        <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" alt="" />
                                    </td>
                                    <td>
                                        <Dropdown className="dk-statusDropdown">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                Approved
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#" style={{ background: '#4c9c23' }}>Approved</Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ background: '#f3ae36' }}>In Review</Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ background: '#fe467c' }}>Refused</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    <td>11-Jun-2021</td>
                                    <td>11-Jun-2021</td>
                                    <td>Full Day</td>
                                    <td>Personal</td>
                                    <td>1</td>
                                    <td>
                                        <div className="dk-action">
                                            <HolidayDetailModal />
                                            <button><i className="far fa-trash-alt"></i></button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dk-userImg">
                                        <img src="https://www.dkmasterbox.com/assets/upload/user_image/1523633534-luiza.jpg" alt="" />
                                    </td>
                                    <td>
                                        <Dropdown className="dk-statusDropdown">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                Approved
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#" style={{ background: '#4c9c23' }}>Approved</Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ background: '#f3ae36' }}>In Review</Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ background: '#fe467c' }}>Refused</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    <td>11-Jun-2021</td>
                                    <td>11-Jun-2021</td>
                                    <td>Full Day</td>
                                    <td>Personal</td>
                                    <td>1</td>
                                    <td>
                                        <div className="dk-action">
                                            <HolidayDetailModal />
                                            <button><i className="far fa-trash-alt"></i></button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dk-userImg">
                                        <img src="https://www.dkmasterbox.com/assets/upload/user_image/5b4dce328e872.png" alt="" />
                                    </td>
                                    <td>
                                        <Dropdown className="dk-statusDropdown">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                Approved
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#" style={{ background: '#4c9c23' }}>Approved</Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ background: '#f3ae36' }}>In Review</Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ background: '#fe467c' }}>Refused</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    <td>11-Jun-2021</td>
                                    <td>11-Jun-2021</td>
                                    <td>Full Day</td>
                                    <td>Personal</td>
                                    <td>1</td>
                                    <td>
                                        <div className="dk-action">
                                            <HolidayDetailModal />
                                            <button><i className="far fa-trash-alt"></i></button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dk-userImg">
                                        <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" alt="" />
                                    </td>
                                    <td>
                                        <Dropdown className="dk-statusDropdown">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                Approved
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#" style={{ background: '#4c9c23' }}>Approved</Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ background: '#f3ae36' }}>In Review</Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ background: '#fe467c' }}>Refused</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    <td>11-Jun-2021</td>
                                    <td>11-Jun-2021</td>
                                    <td>Full Day</td>
                                    <td>Personal</td>
                                    <td>1</td>
                                    <td>
                                        <div className="dk-action">
                                            <HolidayDetailModal />
                                            <button><i className="far fa-trash-alt"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                            <tbody id="reportJuly" className="removeBorder">
                                <tr id="trJuly">
                                    <td rowspan="4" className="trJuly julHoldyPopBg">July</td>
                                    <td className="dk-userImg">
                                        <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" alt="" />
                                    </td>
                                    <td>
                                        <Dropdown className="dk-statusDropdown">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                Approved
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#" style={{ background: '#4c9c23' }}>Approved</Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ background: '#f3ae36' }}>In Review</Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ background: '#fe467c' }}>Refused</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    <td>11-Jun-2021</td>
                                    <td>11-Jun-2021</td>
                                    <td>Full Day</td>
                                    <td>Personal</td>
                                    <td>1</td>
                                    <td>
                                        <div className="dk-action">
                                            <HolidayDetailModal />
                                            <button><i className="far fa-trash-alt"></i></button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dk-userImg">
                                        <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                    </td>
                                    <td>
                                        <Dropdown className="dk-statusDropdown">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="InReview">
                                                In Review
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#" style={{ background: '#4c9c23' }}>Approved</Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ background: '#f3ae36' }}>In Review</Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ background: '#fe467c' }}>Refused</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    <td>11-Jun-2021</td>
                                    <td>11-Jun-2021</td>
                                    <td>Full Day</td>
                                    <td>Personal</td>
                                    <td>1</td>
                                    <td>
                                        <div className="dk-action">
                                            <HolidayDetailModal />
                                            <button><i className="far fa-trash-alt"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody id="reportAugust" className="removeBorder">
                                <tr id="trAugust">
                                    <td rowspan="4" className="trAugust augHoldyPopBg">August</td>
                                    <td className="dk-userImg">
                                        <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" alt="" />
                                    </td>
                                    <td>
                                        <Dropdown className="dk-statusDropdown">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="InReview">
                                                In Review
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#" style={{ background: '#4c9c23' }}>Approved</Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ background: '#f3ae36' }}>In Review</Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ background: '#fe467c' }}>Refused</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    <td>11-Jun-2021</td>
                                    <td>11-Jun-2021</td>
                                    <td>Full Day</td>
                                    <td>Personal</td>
                                    <td>1</td>
                                    <td>
                                        <div className="dk-action">
                                            <HolidayDetailModal />
                                            <button><i className="far fa-trash-alt"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                            <tbody id="reportSeptember">
                                <tr id="trSeptember">
                                    <td rowspan="1" className="trSeptember sepHoldyPopBg">September</td>
                                </tr>
                            </tbody>
                            <tbody id="reportOctober">
                                <tr id="trOctober">
                                    <td rowspan="1" className="trOctober octHoldyPopBg">October</td>
                                </tr>
                            </tbody>
                            <tbody id="reportNovember">
                                <tr id="trNovember">
                                    <td rowspan="1" className="trNovember novHoldyPopBg">November</td>
                                </tr>
                            </tbody>
                            <tbody id="reportDecember">
                                <tr id="trDecember">
                                    <td rowspan="1" className="trDecember decHoldyPopBg">December</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default HolidaysModal;
