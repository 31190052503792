import React from 'react';
import '../moduleprojects.scss';

const LuizaSummary = () => {
    return (
        <>
            <div className="dk-summaryProjectMain">

            </div>
        </>
    )
}

export default LuizaSummary;
