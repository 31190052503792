import React from 'react';
import Modal from 'react-bootstrap/Modal';

const TrackingComments = ({ hidePopup }) => {
  return (
    <>
    <Modal className="dk-trackingCommentsModal" show={true} onHide={hidePopup}>
        <Modal.Header closeButton>
        <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
    </Modal>
    </>
  )
}

export default TrackingComments;
