import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import CategoryDropdown from '../../dropdown/CategoryDropdown';
import SourceTypeDropdown from '../../dropdown/SourceTypeDropdown';
import Submit from "../../../../ui/submitButton";
import { updateDetails, clearState } from "../../../../../slice/resources/Logins";
import { loadingStart, clearLoading } from "../../../../../slice/utils/loader/linearSlice";
import './edit.scss'

const Edit = ({ hidePopup, showeditpopup }) => {
    const dispatch = useDispatch();
    const { resourceLoginCategoryList, resourceLoginSourceTypeList } = useSelector(state => state.common)
    const { details, msg, isError, isUpdate } = useSelector(state => state.loginsResource)

    const [sourceType, setSourceType] = useState(details.sourceType);
    const [category, setCategory] = useState(details.category);
    const [portalName, setPortalName] = useState(details.sourceName);
    const [userName, setUserName] = useState(details.userName);
    const [password, setPassword] = useState(details.password);
    const [webSiteUrl, setWebSiteUrl] = useState(details.websiteLink);
    const [rowId, setRowId] = useState(details.id)
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const handleChangeSelect = (event, data) => {
        const { name, value } = data
        switch (name) {
            case "category":
                setCategory(value)
                break;
            case "sourceType":
                setSourceType(value)
                break;
        }
    }
    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: msg }))
            setShow(false);
            dispatch(clearState())
            dispatch(clearLoading())
        }
        if (isUpdate) {
            setShow(false);
            setLoading(false);
            setSuccess(true);
            dispatch(showSuccess({ msg: msg }))
            dispatch(clearState())
            dispatch(clearLoading())
        }
    }, [isError, isUpdate])

    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (!category || !userName || !portalName || !password || !webSiteUrl) {
            dispatch(showError({ msg: 'All fields required' }))
        } else {
            setSuccess(false);
            setLoading(true);
            dispatch(updateDetails({ rowId, category, userName, sourceName: portalName, password, sourceType, websiteLink: webSiteUrl }))
        }
    }

    const [copyUserNameText, setCopyUserNameText] = useState(false);
    const [copyText, setCopyText] = useState(false);
    const textUserNameRef = useRef(null);
    const textPwdRef = useRef(null);

    const copyUserName = (e) => {
        textUserNameRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        setCopyUserNameText(true)
        setCopyText(false)
    };

    const copyPassword = (e) => {
        textPwdRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        setCopyText(true)
        setCopyUserNameText(false)

    };
    useEffect(() => {
        if (copyText) {
            dispatch(showSuccess({ msg: "Copied" }))
            dispatch(clearState())
        }
    }, [copyText])

    useEffect(() => {
        if (copyUserNameText) {
            dispatch(showSuccess({ msg: "Copied" }))
            dispatch(clearState())
        }
    }, [copyUserNameText])

    const openUrl = () => {
        window.open(webSiteUrl, "_blank")
    }
    return (
        <>
            <Modal
                show={showeditpopup}
                onHide={hidePopup}
                aria-labelledby="contained-modal-title-vcenter"
                className="dk-editResModal"
                centered
            >
                <Modal.Header closeButton className="dk-editResModalHder">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-addnewResModalBdy">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            {/* <div className="form-group">
                                <label>Source Type</label>
                                <div className="dk-resDropDown">
                                    <SourceTypeDropdown list={resourceLoginSourceTypeList} type="sourceType" placeholder={sourceType} onChange={handleChangeSelect} name='sourceType' />
                                </div>
                            </div> */}
                            <div className="form-group">
                                <label>Category</label>
                                <div className="dk-resDropDown">
                                    <CategoryDropdown list={resourceLoginCategoryList} type="category" placeholder={category} onChange={handleChangeSelect} name='category' />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Name of Source</label>
                                <input type="text" className='form-control' value={portalName} onChange={(e) => setPortalName(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>User Name</label>
                                <div className="dk-inputBoxCon">
                                    <input type="text" className='form-control' value={userName} ref={textUserNameRef} onChange={(e) => setUserName(e.target.value)} />
                                    <span><button onClick={copyUserName}>Copy</button></span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <div className="dk-inputBoxCon">
                                    <input type="text" className='form-control' value={password} ref={textPwdRef} onChange={(e) => setPassword(e.target.value)} />
                                    <span><button onClick={copyPassword}>Copy</button></span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Website Link</label>
                                <div className="dk-inputBoxCon">
                                    <input type="text" className='form-control' value={webSiteUrl} onChange={(e) => setWebSiteUrl(e.target.value)} />
                                    <span><button onClick={openUrl}>Open</button></span>
                                </div>
                            </div>
                            <div className="dk-sbmitbtnCon">
                                <Submit className="dk-sbmitbtn btn" txt="Save" loading={loading} success={success} onClick={handleButtonClick} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Edit
