import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const WorkplanSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/recruitment/workplan/summary" className={`${(segment3 == 'summary') ? 'active' : ''}`}>Summary</Link></li>
                        {/* <li><Link to="/recruitment/workplan/workflow" className={`${(segment3 == 'workflow') ? 'active' : ''}`}>Workflow</Link></li> */}
                        <li><Link to="/recruitment/workplan/to-do" className={`${(segment3 == 'to-do') ? 'active' : ''}`}><span className="dk-workplan-badgeCon dk-todobgCon"><span className="dk-workplan-badgenumber todobgNo">3</span></span>To Do</Link></li>
                        <li><Link to="/recruitment/workplan/in-progress" className={`${(segment3 == 'in-progress') ? 'active' : ''}`}><span className="dk-workplan-badgeCon dk-inprogressbgCon"><span className="dk-workplan-badgenumber inprogressbgNo">5</span></span>In Progress</Link></li>
                        <li><Link to="/recruitment/workplan/completed" className={`${(segment3 == 'completed') ? 'active' : ''}`}><span className="dk-workplan-badgeCon dk-completedbgCon"><span className="dk-workplan-badgenumber completedbgNo">2</span></span>Completed</Link></li>
                        <li><Link to="/recruitment/workplan/archived" className={`${(segment3 == 'archived') ? 'active' : ''}`}>Archived</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    )
}

export default WorkplanSubmenu;
