import React from 'react'
import SavedAds from '../../jobs/livejobs/modal/saved_ads/SavedAds'
import  './savednew.scss'
const SavednewAds = () => {
  return (
    <>
    <div className="dkg-savads-mainpageCon">
        <SavedAds />
    </div>
    </>
  )
}

export default SavednewAds