import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './loginsnavbar.scss';

const LoginsMainMenu = () => {
    const pathname = window.location.pathname;
    // const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    // const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK Global CRM";
    }, [pathname]);

    // const { allBadge } = useSelector(state => state.tasks);
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-logins-navbar">
                <Route>
                    <ul>
                        <li><Link to="/logins/summary" className={`${(segment2 === 'summary') ? 'active' : ''}`}>Summary</Link></li>
                        <li><Link to="/logins/resouces-login" className={`${pathname.match('/resouces-login') ? 'active' : ''}`}>All Logins</Link></li>
                        {/* <li><Link to="/logins/office-365" className={`${pathname.match('/office-365') ? 'active' : ''}`}>Office 365</Link></li>
                        <li><Link to="/logins/dkg-crm" className={`${pathname.match('/dkg-crm') ? 'active' : ''}`}>DKG CRM</Link></li>
                        <li><Link to="/logins/job-portals" className={`${pathname.match('/job-portals') ? 'active' : ''}`}>Job Portals</Link></li>
                        <li><Link to="/logins/clients-portal" className={`${pathname.match('/clients-portal') ? 'active' : ''}`}>Clients Portal</Link></li>
                        <li><Link to="/logins/social-media" className={(segment2 === "social-media") ? 'active' : ''}>Social Media</Link></li>
                        <li><Link to="/logins/email-acc" className={`${pathname.match('/email-acc') ? 'active' : ''}`}>Email A/C</Link></li>
                        <li><Link to="/logins/software" className={`${pathname.match('/software') ? 'active' : ''}`}>IT Support</Link></li>
                        <li><Link to="/logins/domains" className={`${pathname.match('/domains') ? 'active' : ''}`}>IT Services</Link></li> */}
                        {/* <li><Link to="/logins/communications" className={`${pathname.match('/communications') ? 'active' : ''}`}>Communications</Link></li> */}
                        {/* <li><Link to="/logins/others" className={`${pathname.match('/others') ? 'active' : ''}`}>Others</Link></li> */}
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default LoginsMainMenu;