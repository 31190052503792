import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import { getSubCategories, getSubCatDetails, clearState } from "../../../slice/candidatePrecreeningForm/scripts";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import $ from 'jquery'

const Step1 = (props) => {

    const dispatch = useDispatch();
    const { isSuccess, isTabActive, isError, isSubCatUpdate, isLoading, subCategoryDetails, isSubReoder, isDelete, subCategoryList, subDetails, isData } = useSelector(state => state.screeningFormScript);

    const [categoryId, setCategoryId] = useState(props.catIdds);
    const [tabName, setTabName] = useState('');
    const [subCategoryName, setSubCategoryName] = useState('');

    const [data, setData] = useState([]);
    const [details, setDesDetails] = useState('');

    const [catRowId, setCatRowId] = useState('');
    const [activeSubCat, setActiveSubCat] = useState('');
    const [defaultId, setDefaultId] = useState('');
    const [detailsSubtab, setDetailsSubtab] = useState('');
    const [overviewData, setOverviewData] = useState('');
    const [showDetailsTab, setShowDetailsTab] = useState(false);
    const [overviewDescription, setOverviewDescription] = useState('');
    const [activeEditTab, setActiveEditTab] = useState(false);

    useEffect(() => {
        if (!isLoading && subCategoryList && subCategoryList.length > 0) {
            setData(subCategoryList)
            setOverviewData(subCategoryList[0].description)
            // setOverviewDescription(subCategoryList[0].description)             
        }
        else {
            setData([])
        }

    }, [isLoading])

    useEffect(() => {

        if (isData) {
            setOverviewData(subDetails.description)
        }
    }, [isData])


    const changeTabName = async (tab) => {
        setTabName(tab)
        switch (tab) {
            case 'details':
                $('.tab_1').addClass('activeSubTab')
                $('.tab_2').removeClass('activeSubTab')
                $('.tab_3').removeClass('activeSubTab')
                break;
            case 'audio':
                $('.tab_2').addClass('activeSubTab')
                $('.tab_1').removeClass('activeSubTab')
                $('.tab_3').removeClass('activeSubTab')
                break;
            case 'video':
                $('.tab_3').addClass('activeSubTab')
                $('.tab_2').removeClass('activeSubTab')
                $('.tab_1').removeClass('activeSubTab')
                break;
            // default:
            //     setActiveSubCat('activeSubTab')
            //     break;   
        }
        setActiveSubCat('activeSubTab')
        setShowDetailsTab(false)

        $('.addnewClass').addClass('activesSubTab')
        dispatch(getSubCategories({ categoryId: props.catIdds, keys: tab }))
    }

    const subCategoryFunction = async (id, catId, data) => {
        if (props.pagetitle != "Overview") {
            $('.dk-tabs-right-navlink-13').removeClass('addActiveClass')
            $('.xyz' + id).addClass('addActiveClass')
        }
        setSubCategoryName(data)
        setShowDetailsTab(true)
        setOverviewData("")
        dispatch(getSubCatDetails({ id }))

        // if (props.pagetitle != "Overview") {
        //     $('.dk-tabs-right-navlink-13').removeClass('addActiveClass')
        //     $('.xyz' + id).addClass('addActiveClass')
        // }

        // setShowDetailsTab(true)
        // setEditorDescription("")
        // setSubCategoryName(data)
        // setCategoryId(catId)
        // setCatRowId(id)
        // dispatch(getSubCatDetails({ id }))
        // setActiveEditTab(true)

    }

    let overviewDetails = overviewData !== undefined && overviewData !== null ? overviewData : "";
    const html_1 = overviewDetails;
    const contentBlock_1 = htmlToDraft(html_1);
    const contentState_1 = ContentState.createFromBlockArray(
        contentBlock_1.contentBlocks
    );
    const [editorOverviewState, setEditorState_1] = useState(EditorState.createEmpty());
    useEffect(() => {
        setEditorState_1(EditorState.createEmpty());
        if (overviewDetails !== "") {
            setEditorState_1(EditorState.createWithContent(contentState_1));
        }
    }, [overviewDetails]);

    // let description = details !== undefined && details !== null ? details : ""; 
    // const html = description;
    // const contentBlock = htmlToDraft(html);
    // const contentState = ContentState.createFromBlockArray(
    //     contentBlock.contentBlocks
    // );
    // const [editorState, setEditorState] = useState(EditorState.createEmpty());
    // useEffect(() => {
    //     setEditorState(EditorState.createEmpty());
    //     if (description !== "") {
    //         setEditorState(EditorState.createWithContent(contentState));
    //     }
    // }, [description]);



    return (
        <>
            <div className='dkg-payment-qt-rightpanel'>
                <div className="dkg-tabs-rightPanel-12">
                    <div className="title">{props.pagetitle}</div>
                    <div className="dk-tabs-rightCols-12">
                        <div className="dk-tabs-right-detail-12">
                            <Tab.Container id="left-tabs-example" >

                                {
                                    (props.pagetitle === "Overview") ? <>
                                    </>
                                        :
                                        <>
                                            <Nav variant="" className='dk-tabs-right-nav-12'>
                                                <Nav.Item className='dk-tabs-right-navitem-12'>
                                                    <Link to="#" className={(tabName === 'details') ? `dk-tabs-right-navlink-12 tab_1 ` : `dk-tabs-right-navlink-12 tab_1 `} onClick={() => changeTabName('details')}>Details</Link>
                                                </Nav.Item>
                                                <Nav.Item className='dk-tabs-right-navitem-12'>
                                                    <Link to="#" className={(tabName === 'audio') ? `dk-tabs-right-navlink-12 tab_2 ` : `dk-tabs-right-navlink-12 tab_2 `} onClick={() => changeTabName('audio')}>Audio</Link>
                                                </Nav.Item>
                                                <Nav.Item className='dk-tabs-right-navitem-12'>
                                                    <Link to="#" className={(tabName === 'video') ? `dk-tabs-right-navlink-12 tab_3 ` : `dk-tabs-right-navlink-12 tab_3 `} onClick={() => changeTabName('video')}>Video</Link>
                                                </Nav.Item>
                                            </Nav>
                                        </>
                                }

                                <div className={`dk-tabs-right-tabpane-12 addnewClass dkg-hidewithoutselct`}>
                                    <div className="dk-rightDetail-panel-13">
                                        <div className="dk-getStartedRight-detail-13">
                                            <Nav variant="" className='dk-tabs-right-nav-13 flex-column'>
                                                {
                                                    data.map((item, key) =>
                                                        <Nav.Item className='dk-tabs-right-navitem-13'>
                                                            {
                                                                (key == 0) ?
                                                                    <Link to="#" className={`dk-tabs-right-navlink-13 addActiveClass xyz` + item.id} onClick={() => subCategoryFunction(item.id, item.categoryId, item.subCategoryName)}>
                                                                        {item.subCategoryName}  </Link>
                                                                    :
                                                                    <Link to="#" className={`dk-tabs-right-navlink-13 xyz` + item.id} onClick={() => subCategoryFunction(item.id, item.categoryId, item.subCategoryName)}>
                                                                        {item.subCategoryName}  </Link>
                                                            }
                                                        </Nav.Item>
                                                    )
                                                }
                                            </Nav>

                                            {
                                                showDetailsTab ?
                                                    <Tab.Content className='dk-tabs-right-tabcontent-13'>

                                                        <div className="dkg-scriptview-text">
                                                            <Editor
                                                                editorState={editorOverviewState}
                                                                wrapperClassName="wrapper-class"
                                                                editorClassName="editor-class"
                                                                toolbarClassName="toolbar-class"

                                                            />
                                                        </div>
                                                    </Tab.Content>
                                                    :
                                                    <>
                                                        {/* this is for default active tab data */}
                                                        {
                                                            data.length > 0 ?
                                                                <Tab.Content className='dk-tabs-right-tabcontent-13'>
                                                                    <div className="dkg-scriptview-text">
                                                                        <Editor
                                                                            editorState={editorOverviewState}
                                                                            wrapperClassName="wrapper-class"
                                                                            editorClassName="editor-class"
                                                                            toolbarClassName="toolbar-class"
                                                                        />
                                                                    </div>
                                                                </Tab.Content>
                                                                : null
                                                        }
                                                    </>
                                            }

                                        </div>
                                    </div>
                                </div>

                                {
                                    (props.pagetitle === "Overview") ? <>
                                        <Tab.Content className='dk-tabs-right-tabcontent-13'>
                                            <div className="dkg-scriptview-text">
                                                <Editor
                                                    editorState={editorOverviewState}
                                                    wrapperClassName="wrapper-class"
                                                    editorClassName="editor-class"
                                                    toolbarClassName="toolbar-class"
                                                />

                                            </div>
                                        </Tab.Content>
                                    </>
                                        :
                                        null
                                }


                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Step1;
