import React from "react";
import $ from "jquery";

const RadioBtn = ({ id, checkActiveExtraDiv, setDataRowId }) => {
    const toggleCheckbox = () => {
        setDataRowId(id)
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount > 0) {
            checkActiveExtraDiv(true, checkedBoxCount)
        } else {
            checkActiveExtraDiv(false, 0)
        }
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
    }

    return <input type="radio" className="chkCandidate" name="chkCandidate[]" value={id} data-id={id} onChange={toggleCheckbox} style={{ marginLeft: "0.5rem" }} />;
};

export default RadioBtn;