import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'
import styled from 'styled-components';

const ProgressFilter = ({ list, ProgressStatusFilterText, onSelectStatusFilter, tableType }) => {
    const DropdownCss = styled.div`
    .dropdown{
        display: flex;
        justify-content: center;
        align-items: center;
        .dropdown-toggle{
            background-color: #fff !important;
            border: 1px solid #ddd !important;
            color: #333;
            font-size: 12px;
            padding: 7px 5px;
            text-align: center;
            text-transform: uppercase;
            width: 100%;
            min-width: 130px;
            max-width: 130px;
            box-shadow: none !important;
            margin: 0;
            &::after {
                display: inline-block !important;
                font-family: ' Font Awesome 5 Free';
                font-style: normal;
                font-weight: 700;
                margin: 0 0 0 10px;
                font-size: 13px;
                position: absolute;
                top: 16px;
                text-decoration: none;
                border-top: 0.33em solid;
                border-right: 0.33em solid transparent;
                border-bottom: 0;
                border-left: 0.33em solid transparent;
            }
            &.btn-primary:not([disabled]):not(.disabled):active,
               &.btn-primary:not([disabled]):not(.disabled).active, 
               .show>.btn-primary.dropdown-toggle {
                background-color: #fff !important;
                border: 1px solid #ddd !important;
                 color: #333 !important;
               }
        }
        .dropdown-menu{
            min-width: 120px;
            border: none;
            border-radius: 0;
            padding: 0;
            width: 100%;
            background-color: #fff;
            transform: inherit !important;
            top: 37px !important;
            box-shadow: 0 9px 16px 0 rgb(9 50 131 / 20%);
            .dropdown-item{
                align-items: center;
                background-color: #fff;
                border: none;
                border-bottom: 1px solid transparent;
                display: flex;
                font-size: 13px;
                position: relative;
                text-transform: capitalize;
                width: 100%; 
                padding: 5px 7px;
                span.dropdown-filter-count {
                    align-items: center;
                    background-color: #3c4c62;
                    border-radius: 2px;
                    color: #fff;
                    display: flex;
                    font-size: 11px;
                    justify-content: center;
                    padding: 1px 0;
                    position: absolute;
                    right: 4px;
                    text-align: center;
                    top: 6px;
                    width: 20px;
                }                  
            }
        }
    }
    .crossUserFilter {
        right: -2px  !important;
    }
`;

    return (
        <React.Fragment>
            <DropdownCss>

                <DropdownButton className='dk-cli-status-dropdown' title={ProgressStatusFilterText} onSelect={onSelectStatusFilter}>
                    <Dropdown.Item eventKey="In Progress" href="#" className="dk-activeBtn" style={{ background: '#d58b5d', color: '#fff' }} > In Progress
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.progress === 'In Progress';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Completed" href="#" className="dk-inActiveBtn" style={{ background: '#da3d5e', color: '#fff' }}>Completed
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.progress === 'Completed';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>


                </DropdownButton>

            </DropdownCss>
        </React.Fragment>
    )
}

export default ProgressFilter;