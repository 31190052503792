import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { InputGroup, FormControl, Button, Table } from 'react-bootstrap';
import { clearState, updateMiniSpecTab, updateTitleBlocks, addInstructions } from "../../../../../../../slice/jobs/jobsSlice";
import { showSuccess, showError, clearMessage } from "../../../../../../../slice/utils/message/messageSlice";

const Cvsearch = (jobId) => {
    const dispatch = useDispatch();

    const { isDetailsLoading, jobDetails, isUpdate } = useSelector(state => state.jobs);

    const [data, setData] = useState('');
    useEffect(() => {
        if (jobDetails && !isDetailsLoading) {
            setData(jobDetails);
        }
    }, [isDetailsLoading])

    useEffect(() => {
        if (isUpdate) {
            dispatch(clearState())
            setData(jobDetails);
        }
    }, [isUpdate])

    const sampleAd = data.miniSpecTab !== undefined && data.miniSpecTab.length ? data.miniSpecTab[0].sample_ad : "";
    const [sampleAdText, setSampleAdText] = useState(sampleAd)


    useEffect(() => {
        if (sampleAd !== '') {
            setSampleAdText(sampleAd);
        }
    }, [sampleAd])

    const handleModelChange = (values) => {
        setSampleAdText(values);
        dispatch(updateMiniSpecTab({ id: jobId.jobId, key: 'sample_ad', value: values }));
    }

    const [title, setTitle] = useState('');

    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (title == "") {
            dispatch(showError({ msg: 'All fields are required.' }))
        } else {
            dispatch(addInstructions({ job_id: jobId.jobId, description: title }))
            setTitle('')
        }
    }


    return (
        <>
            <div className="row dk-Adsbox">
                <div className="col-md-12">
                    <div className="col-md-12 p-0 resourcesTable">
                        <h3>CV Search <i className="fa fa-plus" aria-hidden="true"></i></h3>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Name of Portal</th>
                                    <th>Subscription</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Text</td>
                                    <td>Text</td>
                                    <td><i className="fa fa-trash" aria-hidden="true"></i></td>
                                </tr>
                                <tr>
                                    <td>Text</td>
                                    <td>Text</td>
                                    <td><i className="fa fa-trash" aria-hidden="true"></i></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cvsearch
