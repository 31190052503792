import React from 'react';
//import "./casedrpdwn.scss";
import Dropdown from 'react-bootstrap/Dropdown'

const Status = ({ caseStatus, jobId, list, updateStatus }) => {
    let bgcolur;
    let txtColor;

    if (caseStatus == "Active") {
        bgcolur = "#02a786"
        txtColor = '#fff'
    }
    else if (caseStatus == "Inactive") {
        bgcolur = "#b55355"
        txtColor = '#fff'
    }
    else if (caseStatus == "Qualify") {
        bgcolur = "#d58b5d"
        txtColor = '#fff'
    }
    else if (caseStatus == "On Hold") {
        bgcolur = "#8b572a"
        txtColor = '#fff'
    }
    else if (caseStatus == "Closed") {
        bgcolur = "#da3d5e"
        txtColor = '#fff'
    }
    else {
        bgcolur = "#da3d5e"
        txtColor = '#fff'
    }
    return (
        <>

            <div className="dk-simpleDrpDwnCon">
                <Dropdown className="dk-simpleDrpDwn">
                    <Dropdown.Toggle variant="" className="dk-simpleDrpDwnToggle" style={{ backgroundColor: bgcolur, borderColor: bgcolur, color: txtColor }}>
                        {caseStatus}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dk-simpleDrpDwnMenu">
                        {
                            list.map((statusItem, index) =>
                                <Dropdown.Item key={`profile-case-status-id${index}`} href="#" style={{ backgroundColor: statusItem.bgColor, color: statusItem.textColor }} data-id={jobId} data-value={statusItem.value} data-key="status" onClick={updateStatus}>{statusItem.value}</Dropdown.Item>
                            )
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default Status;
