import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import './comment.scss';
import Deepak from '../../../../assets/images/deepak.png';
import Ajay from '../../../../assets/images/ajay.jpg'


const CommentPopup = ({ hideCommentPopup }) => {
    return (
        <>
            <Modal className="dk-commentModalMain right" show={true} onHide={hideCommentPopup}>
                <Modal.Header closeButton>
                    <Modal.Title>TASK COMMENTS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="comments-container">
                        <div className="scrollBox">
                            <ul className="comments-list">
                                <li>
                                    <div className="comment-main-level">
                                        <div className="comment-avatar">
                                            <img src={Deepak} className="avatarImg" alt="comment-avatar" />
                                        </div>
                                        <div className="comment-box">
                                            <div className="comment-head d-flex align-items-center justify-content-between">
                                                <h6 className="comment-name d-none">
                                                    <Link to="Maria Ciocas"></Link>
                                                </h6>
                                                <span className="d-flex">
                                                    <div className="date"><i className="far fa-calendar-alt"></i>
                                                    </div>
                                                    <div className="date"><i className="far fa-clock"></i>
                                                    </div>
                                                </span>
                                                <div className="d-flex headIcons">
                                                    <i className="fas fa-reply" title="Reply"></i>
                                                    <i className="far fa-edit" title="Edit"></i>
                                                    <i className="far fa-trash-alt" title="Delete"></i>
                                                </div>
                                            </div>
                                            <div className="comment-content">
                                                5th
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="comments-list-reply">
                                        <li>
                                            <div className="comment-main-level">
                                                <div className="comment-avatar">
                                                    <img src={Ajay} className="avatarImg" alt="comment-avatar" />
                                                </div>
                                                <div className="comment-box">
                                                    <div className="comment-head d-flex align-items-center justify-content-between">
                                                        <h6 className="comment-name d-none">
                                                            <Link to="Maria Ciocas"></Link>
                                                        </h6>
                                                        <span className="d-flex">
                                                            <div className="date"><i className="far fa-calendar-alt"></i>

                                                            </div>
                                                            <div className="date"><i className="far fa-clock"></i>
                                                            </div>
                                                        </span>
                                                        <div className="d-flex headIcons">
                                                            <i className="far fa-edit" title="Edit"></i>
                                                            <i className="far fa-trash-alt" title="Delete"></i>
                                                        </div>
                                                    </div>
                                                    <div className="comment-content">
                                                        adsg
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="comment-main-level">
                                        <div className="comment-avatar">
                                            <img src={Deepak} className="avatarImg" alt="comment-avatar" />
                                        </div>
                                        <div className="comment-box">
                                            <div className="comment-head d-flex align-items-center justify-content-between">
                                                <h6 className="comment-name d-none">
                                                    <Link to="Maria Ciocas"></Link>
                                                </h6>
                                                <span className="d-flex">
                                                    <div className="date"><i className="far fa-calendar-alt"></i>
                                                    </div>
                                                    <div className="date"><i className="far fa-clock"></i>
                                                    </div>
                                                </span>
                                                <div className="d-flex headIcons">
                                                    <i className="fas fa-reply" title="Reply"></i>
                                                    <i className="far fa-edit" title="Edit"></i>
                                                    <i className="far fa-trash-alt" title="Delete"></i>
                                                </div>
                                            </div>
                                            <div className="comment-content">
                                                fgjh
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="comments-list-reply">
                                        <li>
                                            <div className="comment-main-level">
                                                <div className="comment-avatar">
                                                    <img src={Ajay} className="avatarImg" alt="comment-avatar" />
                                                </div>
                                                <div className="comment-box">
                                                    <div className="comment-head d-flex align-items-center justify-content-between">
                                                        <h6 className="comment-name d-none">
                                                            <Link to="Maria Ciocas"></Link>
                                                        </h6>
                                                        <span className="d-flex">
                                                            <div className="date"><i className="far fa-calendar-alt"></i>

                                                            </div>
                                                            <div className="date"><i className="far fa-clock"></i>
                                                            </div>
                                                        </span>
                                                        <div className="d-flex headIcons">
                                                            <i className="far fa-edit" title="Edit"></i>
                                                            <i className="far fa-trash-alt" title="Delete"></i>
                                                        </div>
                                                    </div>
                                                    <div className="comment-content">
                                                        2nd
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="dk-commentReplyBox">
                            <textarea name="comment" id="" className="form-control" ></textarea>
                            <div className="dk-commentSubmitBtn">
                                <button >UPDATE</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CommentPopup;
