import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
//import UserFilter from './UserFilter';
//import SelectCategory from '../../dropdown'
import './edit.scss';
import UserList from "../../../../../../ui/userList";
import Dropdown from 'react-bootstrap/Dropdown';
import { showSuccess, showError, clearMessage } from "../../../../../../../slice/utils/message/messageSlice";
//import { loadingStart, loadingEnd, clearLoading } from "../../../../../../../slice/utils/loader/linearSlice";
import { updateProjects, clearState } from "../../../../../../../slice/projects/projctsSlice";
//import { companyDataFetch, projectDataFetch, roleTypeDataFetch, languageDataFetch, cvSourceDataFetch, categoryDataFetch } from "../../../../../../../slice/kpis/assignKpisReportSlice";
//import { SYSTEM_CONSTANTS } from "../../../../../../../constants";
import SelectDropsDown from "../../../../../../pages/sendouts/utils/searchDropdown";

const EditModal = ({ rowId, hidePopup, showeditpopup }) => {
    const dispatch = useDispatch();
    const { projectDetails } = useSelector(state => state.projects)
    //const { candidateCategoryList } = useSelector(state => state.common);

    const [show, setShow] = useState(false);

    const [userImg, setUserImg] = useState(process.env.REACT_APP_IMG_CLOUD_BASE_URL + projectDetails.userInfo.profileImage)
    const [userImgName, setUserImgName] = useState(projectDetails.userInfo.firstName)

    const { companyList, projectList, roleTypeList, languageList, cvSourceList, categoryList } = useSelector(state => state.assignKpiReport)

    const [userId, setUserId] = useState(projectDetails.userInfo.id)
    const [projectTitle, setProjectTitle] = useState(projectDetails.title)
    const [totalCandidate, setTotalCandidate] = useState(projectDetails.candidatesCount)
    const [category, setCategory] = useState(projectDetails.category)
    const [company, setCompany] = useState(projectDetails.companies)
    const [roleType, setRoleType] = useState(projectDetails.roleType)
    const [language, setLanguage] = useState(projectDetails.language)
    const [project, setProject] = useState(projectDetails.project)

    const selectUser = (e) => {
        setUserImgName(e.target.alt)
        setUserImg(e.target.src)
        setUserId(e.target.dataset.id)
    }

    const submitData = () => {
        if (userId == '0') {
            dispatch(showError({ msg: 'All fields required' }))
        } else {
            dispatch(updateProjects({ uid: userId, title: projectTitle, category: category, companies: company, project: project, language: language, roleType: roleType, rowId }))
        }
    }

    // const handleChangeSelect = (event, data) => {
    //     const { name, value } = data
    //     switch (name) {
    //         case "category":
    //             setCategory(value)
    //             break;
    //     }
    // }

    const updateData = async (e) => {

        const key = e.target.dataset.key;
        const value = e.target.dataset.value;

        if (key === 'roleType') {
            setRoleType(value)
        }
        if (key === 'project') {
            setProject(value)
        }
        if (key === 'company') {
            setCompany(value)
        }
        if (key === 'language') {
            setLanguage(value)
        }
        if (key === 'category') {
            setCategory(value)
        }
        // if (key === 'cvSource') {
        //     setCvSource(value)
        // }
    }
    
    return (
        <>
            <Modal show={showeditpopup} onHide={hidePopup} className="dk-editProjectModal" centered >

                <Modal.Header closeButton className="editProjectHeader">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Project
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="editProjectBody">
                    <div className="row">
                        <div className="col-md-2">
                            <div className="form-group">
                                <label>Recruiter</label>
                                <input type="hidden" id="userId" value={userId} />
                                <Dropdown className='dk-userFilterDrpDwn'>
                                    <Dropdown.Toggle variant="success" className='projectsFilter mt-1'>

                                        {
                                            (userId !== '0') ? <img src={userImg} className="select-user-img" title={userImgName} /> : <i className="fas fa-user"></i>
                                        }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='projectsFilterMenu'>
                                        <div className="d-flex">
                                            <UserList onClick={selectUser} />
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div className="form-group">
                                <label className="centerTitle">Project Title</label>
                                <input type="text" className="form-control" defaultValue={projectTitle} onChange={(e) => setProjectTitle(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>No of Candidates</label>
                                <input type="text" className="form-control" defaultValue={totalCandidate} readOnly />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Company</label>
                                {/* <input type="text" className="form-control" defaultValue={company} onChange={(e) => setCompany(e.target.value)} /> */}
                                <SelectDropsDown txt={company} list={companyList} updateProfile={updateData} column="company" className={(category == '' ? "errorBorder" : null)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Language</label>
                                {/* <input type="text" className="form-control" defaultValue={language} onChange={(e) => setLanguage(e.target.value)} /> */}
                                <SelectDropsDown txt={language} list={languageList} updateProfile={updateData} column="language" className={(category == '' ? "errorBorder" : null)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Project</label>
                                {/* <input type="text" className="form-control" defaultValue={project} onChange={(e) => setProject(e.target.value)} /> */}
                                <SelectDropsDown txt={project} list={projectList} updateProfile={updateData} column="project" className={(category == '' ? "errorBorder" : null)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Role Type </label>
                                {/* <input type="text" className="form-control" defaultValue={roleType} onChange={(e) => setRoleType(e.target.value)} /> */}
                                <SelectDropsDown txt={roleType} list={roleTypeList} updateProfile={updateData} column="roleType" className={(category == '' ? "errorBorder" : null)} />

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Category</label>
                                <div className="dk-projectDropDwn">
                                    {/* <SelectCategory list={candidateCategoryList} type="category" placeholder={category} onChange={handleChangeSelect} name='category' /> */}
                                    <SelectDropsDown txt={category} list={categoryList} updateProfile={updateData} column="category" className={(category == '' ? "errorBorder" : null)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="dk-project-btnCon">
                                {/* <Link to="#">UPDATE</Link> */}
                                <button className="dk-submitBtn" onClick={submitData}>UPDATE</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditModal
