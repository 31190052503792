import React from 'react'
import { Link } from 'react-router-dom'
import UserFilter from '../recfilter/UserFilter'
import RecruiterFilter from '../recfilter/RecruiterFilter'
import Recruiter from '../../../../../assets/images/deepak.png';
import MailChip from '../../../../../assets/images/mailchip.png';
import AddNewModal from '../modal/addnew/AddNew'
import EditModal from '../modal/edit/Edit'
import DeleteModal from '../modal/delete/Delete'

const newhiretwo = () => {
    return (
        <>
            {/* <div className='container-fluid dkg-recutierdb-pageCon'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='dk-recutierdb-head'>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="dk-recutierdbLeftCon">
                                    <div className="dk-dkgHead-seach mr-2">
                                        <input type="text" className="form-control" placeholder="Search..." value="" />
                                    </div>
                                    <div className="dk-resuserFilterCon">
                                        <UserFilter />
                                    </div>
                                    <div className="mailshotIcon ml-2">
                                        <Link to="#"><i className="fa fa-search" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                                <h3 className="dkg-recutierdb-title"> Mariana Database</h3>
                                <div className="dk-recutierdbRightCon">
                                    <AddNewModal />
                                </div>
                            </div>
                        </div>
                        <div className='dkg-recutierdbTableCon table-responsive'>
                            <table className='table dkg-recutierdbTable table-bordered'>
                                <thead>
                                    <tr>
                                        <th>Sno</th>
                                        <th>Date</th>
                                        <th>Recruiter</th>
                                        <th>Candidates</th>
                                        <th>Sent Via</th>
                                        <th>Title</th>
                                        <th>Category</th>
                                        <th>Company</th>
                                        <th>Language</th>
                                        <th>Project</th>
                                        <th>Role Type</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            1
                                        </td>
                                        <td>
                                            28 May 2022 , 08:00
                                        </td>
                                        <td>
                                            <div className='dkg-rec-db-imgCon-24'>
                                                <img src={Recruiter} className="recruiter_img" alt='Recruiter' />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-db-candidateName'>
                                                Deepak Kumar
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-mailchipCon'>
                                                <img src={MailChip} className="dkg-mailchip-img" alt="Sent Via" />
                                            </div>
                                        </td>
                                        <td>
                                            Test title
                                        </td>
                                        <td>IT</td>
                                        <td>dkglobal</td>
                                        <td>English</td>
                                        <td>dkgcrm</td>
                                        <td>software</td>
                                        <td>
                                            <div className="dkg-dbaction-cols">
                                                <Link className="dk-upload" to="#">
                                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                                </Link>
                                                <EditModal />
                                                <DeleteModal />
                                                <Link className="dk-report" to="#">
                                                    <i className="fas fa-file-alt"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            2
                                        </td>
                                        <td>
                                            28 May 2022 , 08:00
                                        </td>
                                        <td>
                                            <div className='dkg-rec-db-imgCon-24'>
                                                <img src={Recruiter} className="recruiter_img" alt='Recruiter' />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-db-candidateName'>
                                                Deepak Kumar
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-mailchipCon'>
                                                <img src={MailChip} className="dkg-mailchip-img" alt="Sent Via" />
                                            </div>
                                        </td>
                                        <td>
                                            Test title
                                        </td>
                                        <td>IT</td>
                                        <td>dkglobal</td>
                                        <td>English</td>
                                        <td>dkgcrm</td>
                                        <td>software</td>
                                        <td>
                                            <div className="dkg-dbaction-cols">
                                                <Link className="dk-upload" to="#">
                                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                                </Link>
                                                <EditModal />
                                                <DeleteModal />
                                                <Link className="dk-report" to="#">
                                                    <i className="fas fa-file-alt"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            3
                                        </td>
                                        <td>
                                            28 May 2022 , 08:00
                                        </td>
                                        <td>
                                            <div className='dkg-rec-db-imgCon-24'>
                                                <img src={Recruiter} className="recruiter_img" alt='Recruiter' />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-db-candidateName'>
                                                Deepak Kumar
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-mailchipCon'>
                                                <img src={MailChip} className="dkg-mailchip-img" alt="Sent Via" />
                                            </div>
                                        </td>
                                        <td>
                                            Test title
                                        </td>
                                        <td>IT</td>
                                        <td>dkglobal</td>
                                        <td>English</td>
                                        <td>dkgcrm</td>
                                        <td>software</td>
                                        <td>
                                            <div className="dkg-dbaction-cols">
                                                <Link className="dk-upload" to="#">
                                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                                </Link>
                                                <EditModal />
                                                <DeleteModal />
                                                <Link className="dk-report" to="#">
                                                    <i className="fas fa-file-alt"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            4
                                        </td>
                                        <td>
                                            28 May 2022 , 08:00
                                        </td>
                                        <td>
                                            <div className='dkg-rec-db-imgCon-24'>
                                                <img src={Recruiter} className="recruiter_img" alt='Recruiter' />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-db-candidateName'>
                                                Deepak Kumar
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-mailchipCon'>
                                                <img src={MailChip} className="dkg-mailchip-img" alt="Sent Via" />
                                            </div>
                                        </td>
                                        <td>
                                            Test title
                                        </td>
                                        <td>IT</td>
                                        <td>dkglobal</td>
                                        <td>English</td>
                                        <td>dkgcrm</td>
                                        <td>software</td>
                                        <td>
                                            <div className="dkg-dbaction-cols">
                                                <Link className="dk-upload" to="#">
                                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                                </Link>
                                                <EditModal />
                                                <DeleteModal />
                                                <Link className="dk-report" to="#">
                                                    <i className="fas fa-file-alt"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            5
                                        </td>
                                        <td>
                                            28 May 2022 , 08:00
                                        </td>
                                        <td>
                                            <div className='dkg-rec-db-imgCon-24'>
                                                <img src={Recruiter} className="recruiter_img" alt='Recruiter' />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-db-candidateName'>
                                                Deepak Kumar
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-mailchipCon'>
                                                <img src={MailChip} className="dkg-mailchip-img" alt="Sent Via" />
                                            </div>
                                        </td>
                                        <td>
                                            Test title
                                        </td>
                                        <td>IT</td>
                                        <td>dkglobal</td>
                                        <td>English</td>
                                        <td>dkgcrm</td>
                                        <td>software</td>
                                        <td>
                                            <div className="dkg-dbaction-cols">
                                                <Link className="dk-upload" to="#">
                                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                                </Link>
                                                <EditModal />
                                                <DeleteModal />
                                                <Link className="dk-report" to="#">
                                                    <i className="fas fa-file-alt"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default newhiretwo