import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { FaCalendarAlt, FaClock, FaRegClock, FaTimesCircle, FaMinus } from 'react-icons/fa';
import { MdSnooze } from "react-icons/md";
import Dropdown from 'react-bootstrap/Dropdown';
import PushDateTimePicker from './PushDateTimePicker';
import './pushnotification.scss';

const PushNotification = () => {
    const [showDetail, toggleShow] = React.useState(true);
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        show(true);
    }
    return (
        <>
            {show ?
                <div className="dk-pushNotification-main">
                    <div className="dk-pushNotificationCols">
                        <div className="dk-notificationHead">
                            <div className="dk-notification-text">
                                <small><FaClock /> 5 min ago</small>
                            </div>
                            <div className="title">
                                Sender user <span>"TASK ALERT"</span>
                                <small className="dk-miniIcons" onClick={() => toggleShow(!showDetail)}><FaMinus /></small>
                            </div>
                            {/* <Link to="#" onClick={handleClose} className="dk-closePopup"><IoMdClose /></Link> */}
                        </div>
                        {showDetail &&
                            <div className="dk-userDetail">
                                <div>
                                    <p>
                                        Lorem, ipsum dolor sit amet consectetur adipisicing elit Lorem, ipsum dolor sit.
                                    </p>
                                </div>
                                <div className="dk-remindCols">
                                    <div className="dk-snoozeCols">
                                        {/* <span className="snoozeIco"><MdSnooze /></span>
                                    <input type="text" placeholder="Snooze" name="" id="" className="form-control" />
                                    <PushDateTimePicker /> */}
                                        <button><FaTimesCircle className="mr-2" /> Remove</button>
                                    </div>
                                    <Dropdown className="dk-remindItems">
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                            <FaRegClock className="mr-2" /> Remind Later
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#">5 Min Later</Dropdown.Item>
                                            <Dropdown.Item href="#">10 Min Later</Dropdown.Item>
                                            <Dropdown.Item href="#">15 Min Later</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        }
                    </div>

                </div>
                : null}
        </>
    )
}

export default PushNotification;
