import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
//import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
//import './createnew.scss';
import UserList from "../../../../ui/userList";
//import SelectCategory from '../dropdown';
import Submit from '../../../../ui/submitButton';
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import { addProjects, clearState } from "../../../../../slice/projects/projctsSlice";
//import { loadingStart, loadingEnd, clearLoading } from "../../../../../slice/utils/loader/linearSlice";
import { companyDataFetch, projectDataFetch, roleTypeDataFetch, languageDataFetch, cvSourceDataFetch, categoryDataFetch } from "../../../../../slice/kpis/assignKpisReportSlice";
import { SYSTEM_CONSTANTS } from "../../../../../constants";
import SelectDropsDown from "../../../../pages/sendouts/utils/searchDropdown";

const CreateNew = ({ hideModal, hidePopup, showAddpopup, canIdList, allCandidate }) => {
    const dispatch = useDispatch();
    const { projectDetails, isInsert, isError, msg } = useSelector(state => state.projects)
    const { companyList, projectList, roleTypeList, languageList, cvSourceList, categoryList } = useSelector(state => state.assignKpiReport)
    //const { candidateCategoryList } = useSelector(state => state.common);
    const { userInfo } = useSelector(state => state.auth);
 
    const [show, setShow] = useState(false);
    const [userId, setUserId] = useState(userInfo.id)
    const [userImg, setUserImg] = useState(process.env.REACT_APP_IMG_CLOUD_BASE_URL + userInfo.profileImage)
    const [userImgName, setUserImgName] = useState('')

    const [projectTitle, setProjectTitle] = useState('')
    const [totalCandidate, setTotalCandidate] = useState(allCandidate)
  //  const [totalCandidate, setTotalCandidate] = useState(0)
    const [category, setCategory] = useState('')
    const [company, setCompany] = useState('')
    const [roleType, setRoleType] = useState('')
    const [language, setLanguage] = useState('')
    const [project, setProject] = useState('')
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);  
    const [showModel, setShowModel] = useState(false);  

    const selectUser = (e) => {
        setUserImgName(e.target.alt)
        setUserImg(e.target.src)
        setUserId(e.target.dataset.id)
    }

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(companyDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.comapnyId }))
                await dispatch(projectDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.projectId }))
                await dispatch(roleTypeDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.roleType }))
                await dispatch(languageDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.languageId }))
              //  await dispatch(cvSourceDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.cvSourceId }))
              await dispatch(categoryDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.categoryyId }))

            } catch (err) {
                console.log(err)
            }
        }
        fetchList();      
    }, [])

    const handleChangeSelect = (event, data) => {
        const { name, value } = data
        switch (name) {
            case "category":
                setCategory(value)
                break;
        }
    }

    useEffect(() => {
        if(isInsert){
            clearState();
            setSuccess(true);
            setLoading(false);
            setUserId('0')
            setProject('')
            setCompany('')
            setLanguage('')
            setProject('')
            setProjectTitle('')
            setRoleType('')
            setCategory('ML')
            dispatch(showSuccess({ msg: 'Project created successfully' }))
        }
    }, [isInsert])

    const submitData = (e) => {
        e.preventDefault()

        if (!projectTitle) {
            dispatch(showError({ msg: 'Project title fields is required' }))
        } else {
            
            if (!loading) {
                setSuccess(false);
                setLoading(true);
                if (canIdList === "") {                    
                    dispatch(addProjects({ uid: userId, title: projectTitle, category: category, companies: company, project: project, language: language, roleType: roleType, canId: canIdList }))
                } else {
                     dispatch(addProjects({ uid: userId, title: projectTitle, category: category, companies: company, project: project, language: language, roleType: roleType, canId: canIdList }))
                }
            }
        }
    }

    const updateData = async (e) => {

        const key = e.target.dataset.key;
        const value = e.target.dataset.value;

        if (key === 'roleType') {
            setRoleType(value)
        }
        if (key === 'project') {
            setProject(value)
        }
        if (key === 'company') {
            setCompany(value)
        }
        if (key === 'language') {
            setLanguage(value)
        }
        if (key === 'category') {
            setCategory(value)
        }
        // if (key === 'cvSource') {
        //     setCvSource(value)
        // }
    }

    return (
        <>
            <Modal show={true} onHide={hideModal}
                className="dk-creteNewProjectModal"
                centered
            >
                <Modal.Header closeButton className="creteNewHeader">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add New Project
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="creteNewBody">
                    <div className="row">
                        <div className="col-md-2">
                            <div className="form-group">
                                <label>Recruiter</label>
                                <input type="hidden" id="userId" value={userId} />
                                <Dropdown className='dk-userFilterDrpDwn'>
                                    <Dropdown.Toggle variant="" id="dropdown-basic" className='projectsFilter'>
                                        {
                                            // (userId !== '0') ? <img src={userImg} className="select-user-img" /> : <i className="fas fa-user"></i>
                                            <img src={userImg} className="select-user-img" /> 
                                        }
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className='projectsFilterMenu'>
                                        <div className="d-flex">
                                            <UserList onClick={selectUser} />
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div className="form-group">
                                <label className="centerTitle">Project Title</label>
                                <input type="text" className="form-control" defaultValue={projectTitle} onChange={(e) => setProjectTitle(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>No of Candidates</label>
                                <input type="number" className="form-control" defaultValue={totalCandidate} readOnly />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Company</label>
                                {/* <input type="text" className="form-control" defaultValue={company} onChange={(e) => setCompany(e.target.value)} /> */}
                                <SelectDropsDown txt={company} list={companyList} updateProfile={updateData} column="company" className={(category == '' ? "errorBorder" : null)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Language</label>
                                {/* <input type="text" className="form-control" defaultValue={language} onChange={(e) => setLanguage(e.target.value)} /> */}
                                <SelectDropsDown txt={language} list={languageList} updateProfile={updateData} column="language" className={(category == '' ? "errorBorder" : null)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Project</label>
                                <SelectDropsDown txt={project} list={projectList} updateProfile={updateData} column="project" className={(category == '' ? "errorBorder" : null)} />
                                {/* <input type="text" className="form-control" defaultValue={project} onChange={(e) => setProject(e.target.value)} /> */}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Role Type </label>
                                {/* <input type="text" className="form-control" defaultValue={roleType} onChange={(e) => setRoleType(e.target.value)} /> */}
                                <SelectDropsDown txt={roleType} list={roleTypeList} updateProfile={updateData} column="roleType" className={(category == '' ? "errorBorder" : null)} />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Category</label>
                                <div className="dk-projectDropDwn">
                                    {/* <SelectCategory list={candidateCategoryList} type="category" placeholder={category} onChange={handleChangeSelect} name='category' /> */}
                                    <SelectDropsDown txt={category} list={categoryList} updateProfile={updateData} column="category" className={(category == '' ? "errorBorder" : null)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="dk-project-btnCon">
                                <Submit txt="ADD" loading={loading} success={success} onClick={submitData} className="dk-submitBtn" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateNew;
