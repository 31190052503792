import React, { useEffect, useState } from 'react';
import { Modal, Dropdown } from 'react-bootstrap'
import { clearState,getList, updateDetails } from "../../../../../slice/accounting/businessBookSlice";
import SubmitButton from "../../../../ui/submitButton";
import { showSuccess, showError } from "../../../../../slice/utils/message/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import { SYSTEM_CONSTANTS } from "../../../../../constants";
import SelectDropsDown from "../../../../pages/sendouts/utils/searchDropdown";
import { companyDataFetch, projectDataFetch, roleTypeDataFetch, languageDataFetch, cvSourceDataFetch } from "../../../../../slice/kpis/assignKpisReportSlice";
const EditDetails = ({ onHide,currentYear,currentType }) => {
    const dispatch = useDispatch();
    const { tasksDetails, isUpdate } = useSelector(state => state.businessBook);
    const {companyList, projectList, roleTypeList, languageList, cvSourceList } = useSelector(state => state.assignKpiReport)

    const [type, setType] = useState(currentType)
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [category, setCategory] = useState('')
    const [caseId, setCaseId] = useState(tasksDetails.caseId)
    const [jobId, setJobId] = useState(tasksDetails.jobId)
    const [year, setYear] = useState(tasksDetails.year)
    const [month, setMonth] = useState(tasksDetails.month)
    const [accountManager, setAccountManager] = useState(tasksDetails.accountManager)
    const [recruiters, setRecruiters] = useState(tasksDetails.recruiters)
    const [company, setCompany] = useState(tasksDetails.company)
    const [language, setLanguage] = useState(tasksDetails.language)
    const [candidateName, setCandidateName] = useState(tasksDetails.candidateName)
    const [caseStatus, setCaseStatus] = useState(tasksDetails.caseStatus)
    const [caseStage, setCaseStage] = useState(tasksDetails.caseStage)
    const [progress, setProgress] = useState(tasksDetails.progress)
    const [invoiceNo, setInvoiceNo] = useState(tasksDetails.invoiceNo)
    const [project, setProject] = useState(tasksDetails.project)
    const [roleType, setRoleType] = useState(tasksDetails.roleType)
    const [cvSource, setCvSource] = useState(tasksDetails.cvSource)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };


    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (!caseId) {
                dispatch(showError({ msg: 'All fileds required' }))
            } else {
                setLoading(true);

                try {
                    dispatch(updateDetails({ id: tasksDetails.id, caseId, jobId, year, month, accountManager, recruiters, company, language, candidateName, caseStatus, caseStage, progress, invoiceNo, project, roleType, cvSource }))
                } catch (err) {
                    console.log(err)
                }
            }
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }
    useEffect(() => {
        const fetchList = async () => {
            try {

                await dispatch(companyDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.comapnyId }))
                await dispatch(projectDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.projectId }))
                await dispatch(roleTypeDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.roleType }))
                await dispatch(languageDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.languageId }))
                await dispatch(cvSourceDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.cvSourceId }))

            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
        if (isUpdate) {
            dispatch(clearState())
            dispatch(getList({year:currentYear,type:currentType}))
            setSuccess(false);
            setLoading(false);
            dispatch(showSuccess({ msg: 'Record Updated successfully' }))
        }

    }, [isUpdate])

    // const onItemClick = async (id, columnId, value, img) => {
    //    // console.log(columnId + "--" + value + "--" + img)
    //     //  updateMyData(id, columnId, value, img)
    //     setCaseStatus(value)
    // };

    const updateProfile = async (e) => {

        const key = e.target.dataset.key;
        const value = e.target.dataset.value;

        if (key === 'roleType') {
            setRoleType(value)
        }
        if (key === 'project') {
            setProject(value)
        }
        if (key === 'company') {
            setCompany(value)
        }
        if (key === 'language') {
            setLanguage(value)
        }
        if (key === 'cvSource') {
            setCvSource(value)
        }
    }

    return (
        <>
            <Modal className="dk-yearsAddNewTask-modal right" show={true} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>UPDATE SALES BOOK</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Case ID</label>
                                <label htmlFor="">
                                    <input type="text" name="" id="" className="form-control" value={caseId} onChange={(e) => setCaseId(e.target.value)} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Job ID</label>
                                <label htmlFor="">
                                    <input type="text" name="" id="" className="form-control" value={jobId} onChange={(e) => setJobId(e.target.value)} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Month</label>
                                <label htmlFor="">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" className="form-control">
                                            {month}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu >
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#884c5e", color: "#fff" }} onClick={() => setMonth("January")} >January</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#26596d", color: "#fff" }} onClick={() => setMonth("February")} >February</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#5a4c72", color: "#fff" }} onClick={() => setMonth("March")} >March</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#3c4c62", color: "#fff" }} onClick={() => setMonth("April")} >April</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#5c737d", color: "#fff" }} onClick={() => setMonth("May")} >May</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#4c5f59", color: "#fff" }} onClick={() => setMonth("June")} >June</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#01796f", color: "#fff" }} onClick={() => setMonth("July")} >July</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#144773", color: "#fff" }} onClick={() => setMonth("August")} >August</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#396a95", color: "#fff" }} onClick={() => setMonth("September")} >September</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#535255", color: "#fff" }} onClick={() => setMonth("October")} >October</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#b55355", color: "#fff" }} onClick={() => setMonth("November")} >November</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#3c97b6", color: "#fff" }} onClick={() => setMonth("December")} >December</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Company</label>
                                <label htmlFor="">
                                    {/* <input type="text" name="" id="" className="form-control" value={company} onChange={(e) => setCompany(e.target.value)} /> */}
                                    <SelectDropsDown txt={company} list={companyList} updateProfile={updateProfile} column="company" className={(category == '' ? "errorBorder" : null)} />
                                </label>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Language</label>
                                <label htmlFor="">
                                    {/* <input type="text" name="" id="" className="form-control" value={language} onChange={(e) => setLanguage(e.target.value)} /> */}
                                    <SelectDropsDown txt={language} list={languageList} updateProfile={updateProfile} column="language" className={(category == '' ? "errorBorder" : null)} />
                                </label>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Name of Candidate</label>
                                <label htmlFor="">
                                    <input type="text" name="" id="" className="form-control" value={candidateName} onChange={(e) => setCandidateName(e.target.value)} />
                                </label>
                            </div>
                        </div>
                        {/*                       
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Invoice No</label>
                                <label htmlFor="">
                                    <input type="text" name="" id="" className="form-control" value={invoiceNo} onChange={(e) => setInvoiceNo(e.target.value)} />
                                </label>
                            </div>
                        </div> */}
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Project</label>
                                <label htmlFor="">
                                    {/* <input type="text" name="" id="" className="form-control" value={project} onChange={(e) => setProject(e.target.value)} /> */}
                                    <SelectDropsDown txt={project} list={projectList} updateProfile={updateProfile} column="project" className={(category == '' ? "errorBorder" : null)} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Role Type</label>
                                <label htmlFor="">
                                    {/* <input type="text" name="" id="" className="form-control" value={roleType} onChange={(e) => setRoleType(e.target.value)} /> */}
                                    <SelectDropsDown txt={roleType} list={roleTypeList} updateProfile={updateProfile} column="roleType" className={(category == '' ? "errorBorder" : null)} />
                                </label>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">CV Source</label>
                                <label htmlFor="">
                                    {/* <input type="text" name="" id="" className="form-control" value={cvSource} onChange={(e) => setCvSource(e.target.value)} /> */}
                                    <SelectDropsDown txt={cvSource} list={cvSourceList} updateProfile={updateProfile} column="cvSource" className={(category == '' ? "errorBorder" : null)} />
                                </label>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className="form-group">
                                <label htmlFor=""></label>
                                <label htmlFor="">
                                    <div className='form-group justify-content-center mt-2'>
                                        <SubmitButton txt="UPDATE" loading={loading} success={success} onClick={handleSubmit} className="dkaddBtn" />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default EditDetails;
