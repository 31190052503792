import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ViewFeedback from "./viewfeedback/ViewFeedback";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import './itteam.scss';
import RightTabs from './RightTabs';


const TeamMarketer = () => {
    const [ViewFeedbackModal, setViewFeebackstate] = useState(false)
    const showViewFeedbackModal = () => {
        setViewFeebackstate(true)
    }
    const hideViewFeedbackModal = () => {
        setViewFeebackstate(false)
    }
    return (
        <>
            {
                ViewFeedbackModal ?
                    <ViewFeedback handleClose={hideViewFeedbackModal} /> : null
            }

            <div className="dk-itTeamMain dk-itTeamOtherMain">
                {/* <div className="dk-itTeamHead">
                    <div className="d-flex align-items-center justify-content-center">
                        <h3 className="title relativeTitle">Developer</h3>
                    </div>
                </div> */}

                <div className="dk-itTeamTaskMain">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                        <div className="d-flex w-100">
                            <div className="dk-itTeamTabs">
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_1">Instructions</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_2">Bugs</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_3">Tables</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_4">Popups</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_5">Corners</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_6">Scrollbar</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_7">Colours</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_8">Pagination</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_9">Dropdowns</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_10">Fonts & Text</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>

                            <Tab.Content>
                                <Tab.Pane eventKey="tabs_1">
                                    <div className="dk-itTeamTabsPanel">
                                        <h3 className="title">Instructions</h3>
                                        <RightTabs />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_2">
                                    <div className="dk-itTeamTabsPanel">
                                        <h3 className="title">Instructions</h3>
                                        <RightTabs />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_3">
                                    <div className="dk-itTeamTabsPanel">
                                        <h3 className="title">Instructions</h3>
                                        <RightTabs />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_4">
                                    <div className="dk-itTeamTabsPanel">
                                        <h3 className="title">Instructions</h3>
                                        <RightTabs />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_5">
                                    <div className="dk-itTeamTabsPanel">
                                        <h3 className="title">Instructions</h3>
                                        <RightTabs />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_6">
                                    <div className="dk-itTeamTabsPanel">
                                        <h3 className="title">Instructions</h3>
                                        <RightTabs />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_7">
                                    <div className="dk-itTeamTabsPanel">
                                        <h3 className="title">Instructions</h3>
                                        <RightTabs />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_8">
                                    <div className="dk-itTeamTabsPanel">
                                        <h3 className="title">Instructions</h3>
                                        <RightTabs />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_9">
                                    <div className="dk-itTeamTabsPanel">
                                        <h3 className="title">Instructions</h3>
                                        <RightTabs />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_10">
                                    <div className="dk-itTeamTabsPanel">
                                        <h3 className="title">Instructions</h3>
                                        <RightTabs />
                                    </div>
                                </Tab.Pane>

                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </div>
            </div>
        </>
    )
}

export default TeamMarketer;
