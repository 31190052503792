import React, { useState, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./listviewdetial.scss";
import { Modal, Dropdown, Tab, Nav } from "react-bootstrap";
import Contacts from "../../detailview/contacts/Contacts";
import JobDetails from "../../detailview/job-details/JobDetails";
import FAQOverview from "./faq/Overview";
//import LiveJobTable from '../../detailview/livejobs/LiveJobCompany'
//import ClientJobs from '../../detailview/jobs/Jobs';
//import Agreements from '../../detailview/agreements/Agreements';
//import Invoices from '../../detailview/invoices/Invoices';
//import Hiringfee from '../../detailview/hiringfee/Hiringfee';
// import Social from '../../detailview/social/Social';
// import Interviews from '../../detailview/interviews/Interviews';
// import Sendouts from '../../detailview/sendouts/Sendouts'
//import Preferences from '../../detailview/preferences/Preferences';
import LikeDisLike from "../../detailview/like-dislike/LikeDisLike";
import {
  updateValue,
  fetechById,
  fetchCompanyProject,
  fetchCompanyRole,
  jobsType,
  jobsIndustry,
  jobsBusiness,
  jobsCountry,
  jobsCity,
} from "../../../../../slice/companies/clients/clientSlice";
import {
  getdefaultCategory,
  getdefaultQuesAns,
} from "../../../../../slice/faqs/newFaqSlice";
import SimpleDropDwn from "./SimpleDropDwn";
import SelectAccountManagerDropDwn from "../../../../pages/candidate/pipeline/modal/candidateprofile/AccountManagerDropDwn";
//import NativeDatePicker from '../../../../ui/nativedatepicker/DatePicker';
//import CaseStatusRenderDiv from '../CaseStatusRenderDiv';
import CompanySummaryModal from "./CompanySummary";
//import PerformanceModal from './PerformanceModal'
import ProjectTab from "./projects/ProjectTab";
import RoleTypeTab from "./projects/RoleTypeTab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";

import Facebook from "../../../../../assets/images/facebook.png";
import LinkedIn from "../../../../../assets/images/linkedin.png";
import Instagram from "../../../../../assets/images/instagram.png";
import Websiteicon from "../../../../../assets/images/web.png";
import Youtube from "../../../../../assets/images/youtube.png";

import ClientCompany from "./tab/company/ClientCompany";
import ClientProjects from "./tab/projects/ClientProjects";
import ClientRoletype from "./tab/roletype/ClientRoletype";
import ClientLanguage from "./tab/language/ClientLanguage";
import ClientSendouts from "./tab/sendouts/ClientSendout";
import ClientInterview from "./tab/interviews/ClientInterviews";
import ClientJoboffers from "./tab/joboffers/ClientJobOffer";
import ClientRelocation from "./tab/relocation/ClientRelocaton";
import ClientPortals from "./tab/portals/ClientPortals";

// import second from '../../../../../assets'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const DetialViewPoup = ({ onHide, id, clientidd, list }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    isProfileLoading,
    isLoading,
    companyDetails,
    companyProjectList,
    companyRoleList,
    jobsTypeList,
    jobsBusinessList,
    jobsIndustryList,
    jobsCityList,
    jobsCountryList,
    isUpdate,
    newProjectList,
  } = useSelector((state) => state.companies);

  //const [companyidd, setCompanyidd] = useState('');
  const [client_idd, setClientidd] = useState("");
  const [clientName, setClientName] = useState("");
  const [foundedOn, setFoundedOn] = useState("");
  const [category, setCategory] = useState("");
  const [cityName, setCityName] = useState("");
  const [countryName, setCountryName] = useState("");
  const [industryName, setIndustryName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [incomeTax, setIncomeTax] = useState("");
  const [vatNo, setVatNo] = useState("");
  const [headOffice, setHeadOffice] = useState("");
  const [feeType, setTotalEmp] = useState("");
  const [collaboration, setCollaboration] = useState("");
  const [renewal, setRenewal] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [website1, setWebsite1] = useState("");
  const [website2, setWebsite2] = useState("");
  const [cvUpoad, setCvUpoad] = useState("");
  const [address, setAddress] = useState("");
  const [accountManagerName, setAccountManagerName] = useState("");
  const [accountManagerImg, setAccountManagerImg] = useState("");
  const [accountManagerIdd, setAccountManagerIdd] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [satusValue, setSatusValue] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [projectList, setProjectList] = useState([]);

  const updateMyData = (e) => {
    dispatch(
      updateValue({
        id: candidateId,
        key: e.target.dataset.columnid,
        value: e.target.value,
      })
    );
  };

  const [open, setOpen] = React.useState(false);

  // const [showingCountOf, setShowingCountOf] = useState(0);
  // const [showingCount, setShowingCount] = useState(0);
  const [prevId, setPrevId] = useState(0);
  const [nextId, setNextId] = useState(0);
  const [prevIndex, setPrevIndex] = useState(false);
  const [nextIndex, setNextIndex] = useState(false);
  const [candidateId, setCandidateId] = useState(0);

  useEffect(() => {
    if (candidateId > 0) {
      findIndexOfProfile();
      dispatch(fetchCompanyProject({ id: candidateId }));
      dispatch(fetchCompanyRole({ id: candidateId }));

      // dispatch(fetechById({ id: candidateId }))

      // dispatch(getdefaultCategory({ compid: candidateId }));
      // dispatch(getdefaultQuesAns({ compid: candidateId }));

      dispatch(jobsCity());
      dispatch(jobsCountry());
      dispatch(jobsIndustry());
      dispatch(jobsBusiness());
      dispatch(jobsType());
    }

    dispatch(fetechById({ id: clientidd })); //calling in listView.js
  }, [candidateId]);

  useEffect(() => {
    if (isProfileLoading) {
      setOpen(true);
    }
    if (isUpdate) {
      dispatch(fetechById({ id: candidateId }));
    }
    if (!isProfileLoading && companyDetails) {
      setOpen(false);
      if (!isEmpty(companyDetails)) {
        //setCompanyidd(companyDetails.id)
        setClientidd(companyDetails.clientId);
        setClientName(companyDetails.name);
        setFoundedOn(companyDetails.foundedOn);
        setCategory(companyDetails.type);
        setCityName(companyDetails.city);
        setCountryName(companyDetails.country);
        setIndustryName(companyDetails.industry);
        setBusinessName(companyDetails.business);
        setIncomeTax(companyDetails.incomeTax);
        setVatNo(companyDetails.vatNo);
        setHeadOffice(companyDetails.headOffice);
        setTotalEmp(companyDetails.feeType);
        setCollaboration(companyDetails.collaboration);
        setRenewal(companyDetails.renewal);
        setPhone(companyDetails.phone1);
        setEmail(companyDetails.email1);
        setWebsite1(companyDetails.website1);
        setWebsite2(companyDetails.website2);
        setCvUpoad(companyDetails.cvURL);
        setAddress(companyDetails.address);

        setAccountManagerName(companyDetails.amInfo.firstName);
        setAccountManagerImg(companyDetails.amInfo.profileImage);
        setAccountManagerIdd(companyDetails.amInfo.am_id);
        setCompanyDescription(companyDetails.companyDescription);
      } else {
        setClientidd("");
      }

      switch (companyDetails.inStatus) {
        case 0:
          setBgColor("#dc3545");
          setSatusValue("Inactive");
          break;
        case 1:
          setBgColor("#3a9469");
          setSatusValue("Active");
          break;
        case 2:
          setBgColor("#eaa03d");
          setSatusValue("Qualifying");
          break;
      }
    }
  }, [isProfileLoading, isUpdate]);

  function isEmpty(obj) {
    for (var i in obj) {
      return false;
    }
    return true;
  }

  const fetchDetails = async () => {
    await setCandidateId(clientidd);
  };
  const updateProfile = async (e) => {
    // const id = e.target.dataset.id;
    const key = e.target.dataset.key;
    const value = e.target.dataset.value;
    setAccountManagerName(e.target.dataset.name);
    setAccountManagerImg(e.target.dataset.img);

    dispatch(updateValue({ id: candidateId, key, value }));
  };

  const onItemClick = (eventkey) => {
    let data = eventkey.split("=");

    switch (data[0]) {
      case "0":
        setBgColor("#dc3545");
        setSatusValue("Inactive");
        break;
      case "1":
        setBgColor("#368836");
        setSatusValue("Active");
        break;
      case "2":
        setBgColor("#eaa03d");
        setSatusValue("Qualifying");
        break;
    }
    dispatch(updateValue({ id: data[2], key: data[3], value: data[0] }));
  };

  // const prevRecord = () => {
  //     setCandidateId(prevId);
  // };

  // const nextRecord = () => {
  //     setCandidateId(nextId);
  // };

  const findIndexOfProfile = () => {
    var index;

    list.findIndex(function (entry, i) {
      if (entry.original.clientId == candidateId) {
        index = i;
        return true;
      }
    });
    // setShowingCountOf(index + 1);
    //setShowingCount(list.length);

    // console.log(index)
    //console.log(list)
    // console.log(list[index - 1])

    if (!isEmpty(list[index - 1])) {
      setPrevId(list[index - 1].original.clientId);
      setPrevIndex(true);
    } else {
      setPrevIndex(false);
    }
    if (!isEmpty(list[index + 1])) {
      setNextId(list[index + 1].original.clientId);
      setNextIndex(true);
    } else {
      setNextIndex(false);
    }
  };

  return (
    <React.Fragment>
      <div className="dk-cliDetViewPopup">
        <Modal
          show={true}
          onHide={onHide}
          dialogClassName="dk-detviewmodal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          onEntered={fetchDetails}
        >
          <Modal.Header closeButton className="dk-detViewheader">
            <Modal.Title
              className="dk-detViewTitle"
              id="example-custom-modal-styling-title"
            >
              <div className="dkg-clientid-con" style={{ fontSize: "14px" }}>
                JOB ID - {client_idd}{" "}
              </div>
              <div className="modalTitleCon">
                {/* <i className="fa fa-angle-left mr-3" aria-hidden="true"></i> 1 of 1
<i className="fa fa-angle-right ml-3" aria-hidden="true"></i> */}
                {/* {prevIndex ? (
                                <Link to="#" onClick={prevRecord}>
                                    <i className="fa fa-angle-left mr-3"></i>
                                </Link>
                            ) : null}
                            {showingCountOf ? showingCountOf : 1} of{" "}
                            {showingCount !== 0 ? showingCount : 1}
                            {nextIndex ? (
                                <Link to="#" onClick={nextRecord}>
                                    <i className="fa fa-angle-right ml-3"></i>
                                </Link>
                            ) : null} */}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Backdrop
              className={classes.backdrop}
              open={open}
              onClick={() => {
                setOpen(false);
              }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <div className="container-fluid dk-cliDetVPageCon dklistViewPopContent pl-0 pr-0">
              <div className="dk-clientDetViewTopSec">
                <div className="row">
                  <div className="col-md-12 col-sm-12 dk-DetViewBottomSecCon">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="details"
                    >
                      <div className="row">
                        <div
                          className="col-md-2 col-sm-12 dk-ClieDetViewSideMenu dkg-clientifo-navpills-Con"
                          id="dk-cliDetailsV"
                        >
                          <Nav variant="pills" className="flex-row">
                            <Nav.Item>
                              <Nav.Link eventKey="details">Overview</Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                                                            <Nav.Link eventKey="dkg-livejobs-tab">Jobs</Nav.Link>
                                                        </Nav.Item> */}
                            <Nav.Item>
                              <Nav.Link eventKey="job-files">Files</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="dkg-clientlist-company">
                                Company
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="dkg-newprojects">
                                Projects
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="dkg-newroletype">
                                Role Type
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="dkg-portal">Portal</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="sendouts">Sendouts</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="interviews">
                                Interviews
                              </Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
        <Nav.Link eventKey="like-dislike">Like/Dislike</Nav.Link>
    </Nav.Item> */}
                            <Nav.Item>
                              <Nav.Link eventKey="dkg-joboffer">
                                Job Offer
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="dkg-relocation">
                                Relocation
                              </Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                                                        <Nav.Link eventKey="dkg-invoices">Invoices</Nav.Link>
                                                    </Nav.Item> */}
                            <Nav.Item>
                              <Nav.Link eventKey="dkg-newlanguage">
                                Invoice
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="dkg-role-type">FAQ</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                        <div className="col-md-10 col-sm-12 dk-ClieDetViewMainCont dkg-clientifo-rightpanel-Con">
                          <Tab.Content>
                            <Tab.Pane eventKey="details">
                              <div className="dk-ClentViewTabPane dkg-client-info-overmainCon">
                                <div className="row">
                                  <div className="col-xl-5 col-md-5 col-sm-12 pr-4">
                                    <div className="dk-cliDetilFormCon">
                                      <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>Status</label>
                                            <div className="dk-cliDorpDwon">
                                              <Dropdown>
                                                <Dropdown.Toggle>
                                                  <div
                                                    className="statusDiv"
                                                    style={{
                                                      backgroundColor: bgColor,
                                                      color: "#fff",
                                                    }}
                                                  >
                                                    {satusValue}
                                                  </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="caseStatusMenu">
                                                  <Dropdown.Item
                                                    eventKey={`1=#368836=${client_idd}=inStatus`}
                                                    href="#"
                                                    className="dk-activeBtn"
                                                    onSelect={onItemClick}
                                                    style={{
                                                      backgroundColor:
                                                        "#368836",
                                                      color: "#fff",
                                                    }}
                                                  >
                                                    Active
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    eventKey={`0=#dc3545=${client_idd}=inStatus`}
                                                    href="#"
                                                    className="dk-inActiveBtn"
                                                    onSelect={onItemClick}
                                                    style={{
                                                      backgroundColor:
                                                        "#dc3545",
                                                      color: "#fff",
                                                    }}
                                                  >
                                                    Inactive
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    eventKey={`2=#eaa03d=${client_idd}=inStatus`}
                                                    href="#"
                                                    className=""
                                                    style={{
                                                      backgroundColor:
                                                        "#eaa03d",
                                                      color: "#fff",
                                                    }}
                                                    onSelect={onItemClick}
                                                  >
                                                    Qualifying
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 pl-0">
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            {/* <label className='dk-smalllabel dkg-dv-rightlabel'>Client ID</label>
                                                                                    <input type="text" className="form-control" defaultValue={client_idd} readOnly /> */}
                                            <label>Client Name</label>
                                            <input
                                              type="text"
                                              data-columnid="name"
                                              className="form-control"
                                              defaultValue={clientName}
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>Geography</label>
                                            <input
                                              type="text"
                                              onBlur={updateMyData}
                                              data-columnid="collaboration"
                                              className="form-control"
                                              defaultValue={collaboration}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 pl-0">
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>Category</label>
                                            <SimpleDropDwn
                                              id={client_idd}
                                              categoryList={jobsTypeList}
                                              text={category}
                                              columnId="type"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label className="">Country</label>
                                            <SimpleDropDwn
                                              id={client_idd}
                                              categoryList={jobsCountryList}
                                              text={countryName}
                                              columnId="country"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6 pl-0">
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>City/Town</label>
                                            <SimpleDropDwn
                                              id={client_idd}
                                              categoryList={jobsCityList}
                                              text={cityName}
                                              columnId="city"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>Industry</label>
                                            <SimpleDropDwn
                                              id={client_idd}
                                              categoryList={jobsIndustryList}
                                              text={industryName}
                                              columnId="industryItemId"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 pl-0">
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>Business</label>
                                            <SimpleDropDwn
                                              id={client_idd}
                                              categoryList={jobsBusinessList}
                                              text={businessName}
                                              columnId="businessItemId"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label>Contract</label>
                                            <input
                                              type="text"
                                              onBlur={updateMyData}
                                              data-columnid="incomeTax"
                                              className="form-control"
                                              defaultValue={incomeTax}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 pl-0">
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            {/* <label className="dk-smalllabel dkg-dv-rightlabel">VAT No.</label>
                                    <input type="text" onBlur={updateMyData} data-columnid="vatNo" className="form-control" defaultValue={vatNo} /> */}
                                            <label>Renewal</label>
                                            <input
                                              type="text"
                                              onBlur={updateMyData}
                                              data-columnid="renewal"
                                              className="form-control"
                                              defaultValue={renewal}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                          <div className="form-group dk-Candinate-fromGroup d-flex mb-0">
                                            <label>Fee Type</label>
                                            <input
                                              type="text"
                                              onBlur={updateMyData}
                                              data-columnid="feeType"
                                              className="form-control"
                                              defaultValue={feeType}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 pl-0">
                                          {/* <div className="form-group dk-Candinate-fromGroup d-flex mb-0">
                                                                                    <label className="dk-smalllabel dkg-dv-rightlabel">Status</label>
                                                                                    <div className="dk-cliDorpDwon">
                                                                                        <Dropdown >
                                                                                            <Dropdown.Toggle>
                                                                                                <div className="statusDiv" style={{ backgroundColor: bgColor, color: "#fff" }}>{satusValue}</div>
                                                                                            </Dropdown.Toggle>
                                                                                            <Dropdown.Menu className="caseStatusMenu" >
                                                                                                <Dropdown.Item eventKey={`1=#368836=${client_idd}=inStatus`} href="#" className="dk-activeBtn" onSelect={onItemClick} style={{ backgroundColor: "#368836", color: "#fff" }}>Active</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey={`0=#dc3545=${client_idd}=inStatus`} href="#" className="dk-inActiveBtn" onSelect={onItemClick} style={{ backgroundColor: "#dc3545", color: "#fff" }}>Inactive</Dropdown.Item>
                                                                                                <Dropdown.Item eventKey={`2=#eaa03d=${client_idd}=inStatus`} href="#" className="" style={{ backgroundColor: "#eaa03d", color: "#fff" }} onSelect={onItemClick}>Qualifying</Dropdown.Item>
                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown>
                                                                                    </div>
                                                                                </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-2 col-md-2 col-sm-12 pl-0 pr-0">
                                    <div className="dk-cliDetViewCntreCon">
                                      <div className="dk-cliRecImgPopMainCon">
                                        <SelectAccountManagerDropDwn
                                          name={accountManagerName}
                                          img={accountManagerImg}
                                          canId={accountManagerIdd}
                                          updateProfile={updateProfile}
                                          className="mr-2"
                                        />
                                      </div>
                                      <div className="dk-ClientimgUpload d-flex mb-0">
                                        <div className="dk-recuploadImg">
                                          <input type="file" name="" id="" />
                                          <span>
                                            <img
                                              src={
                                                companyDetails
                                                  ? process.env
                                                      .REACT_APP_IMG_CLOUD_BASE_URL +
                                                    companyDetails.logo
                                                  : ""
                                              }
                                              className="img-fluid"
                                              alt=""
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className="dkg-clientsocail-media">
                                        <div className="dkg-socail-media-item">
                                          <img
                                            src={Websiteicon}
                                            alt="Socail Media"
                                            className="dkg-socail-media-img"
                                          />
                                        </div>
                                        <div className="dkg-socail-media-item">
                                          <img
                                            src={Facebook}
                                            alt="Socail Media"
                                            className="dkg-socail-media-img"
                                          />
                                        </div>
                                        <div className="dkg-socail-media-item">
                                          <img
                                            src={LinkedIn}
                                            alt="Socail Media"
                                            className="dkg-socail-media-img"
                                          />
                                        </div>
                                        <div className="dkg-socail-media-item">
                                          <img
                                            src={Instagram}
                                            alt="Socail Media"
                                            className="dkg-socail-media-img"
                                          />
                                        </div>
                                        <div className="dkg-socail-media-item">
                                          <img
                                            src={Youtube}
                                            alt="Socail Media"
                                            className="dkg-socail-media-img"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-5 col-md-5 col-sm-12 pl-4">
                                    <div className="dk-cliDetilFormCon">
                                      <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                          <div className="row">
                                            <div className="col-md-6 pr-0">
                                              <div className="form-group dk-Candinate-fromGroup d-flex">
                                                <label className="dk-smalllabel">
                                                  Founded
                                                </label>
                                                <input
                                                  type="text"
                                                  onBlur={updateMyData}
                                                  data-columnid="foundedOn"
                                                  className="form-control"
                                                  defaultValue={foundedOn}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="form-group dk-Candinate-fromGroup d-flex ">
                                                <label className="">
                                                  Head Office
                                                </label>
                                                <input
                                                  type="text"
                                                  onBlur={updateMyData}
                                                  data-columnid="headOffice"
                                                  className="form-control"
                                                  defaultValue={headOffice}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-6 pr-0">
                                              <div className="form-group dk-Candinate-fromGroup d-flex">
                                                <label className="dk-smalllabel">
                                                  Tax No
                                                </label>
                                                <input
                                                  type="text"
                                                  onBlur={updateMyData}
                                                  data-columnid="phone1"
                                                  className="form-control"
                                                  defaultValue={phone}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="form-group dk-Candinate-fromGroup d-flex">
                                                {/* <label className="">Email</label>
                                            <input type="text" onBlur={updateMyData} data-columnid="email1" className="form-control" defaultValue={email} /> */}
                                                <label className="">
                                                  VAT No.
                                                </label>
                                                <input
                                                  type="text"
                                                  onBlur={updateMyData}
                                                  data-columnid="vatNo"
                                                  className="form-control"
                                                  defaultValue={vatNo}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label className="dk-smalllabel">
                                              Address
                                            </label>
                                            <input
                                              type="text"
                                              onBlur={updateMyData}
                                              data-columnid="address"
                                              className="form-control dkg-input-left"
                                              defaultValue={address}
                                            />
                                            <span className="dk-clViewAddonIcon">
                                              <i
                                                className="fa fa-map-marker"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </div>
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label className="dk-smalllabel">
                                              Website 1
                                            </label>
                                            <input
                                              type="text"
                                              onBlur={updateMyData}
                                              data-columnid="website1"
                                              className="form-control dkg-input-left"
                                              defaultValue={website1}
                                            />
                                            <span className="dk-clViewAddonIcon">
                                              <i
                                                className="fa fa-globe"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </div>
                                          <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label className="dk-smalllabel">
                                              Website 2
                                            </label>
                                            <input
                                              type="text"
                                              onBlur={updateMyData}
                                              data-columnid="website2"
                                              className="form-control dkg-input-left"
                                              defaultValue={website2}
                                            />
                                            <span className="dk-clViewAddonIcon">
                                              <i
                                                className="fa fa-globe"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </div>
                                          <div className="form-group dk-Candinate-fromGroup d-flex mb-0">
                                            <label className="dk-smalllabel">
                                              CV Upload
                                            </label>
                                            <input
                                              type="text"
                                              onBlur={updateMyData}
                                              data-columnid="cvURL"
                                              className="form-control dkg-input-left"
                                              defaultValue={cvUpoad}
                                            />
                                            <span className="dk-clViewAddonIcon">
                                              <i
                                                className="fa fa-globe"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <Contacts />
                              </div>
                            </Tab.Pane>
                            {/* <Tab.Pane eventKey="contacts">
        <div className="dk-ClentViewTabPane">
            <Contacts />
            <Social id={client_idd} />
        </div>
    </Tab.Pane> */}
                            <Tab.Pane eventKey="projects">
                              <div className="dk-ClentViewTabPane dkg-desc-tabpane-234">
                                <div className="dkg-desc-sidmenu-tabCon">
                                  <Tab.Container
                                    id="left-tabs-example"
                                    defaultActiveKey="company"
                                  >
                                    <div className="row dkg-desc-sidmenu-tabRow m-0">
                                      <div className="col-md-2 dkg-desc-sidmenu-Col-2 pl-0 pr-0">
                                        <Nav
                                          variant="pills"
                                          className="flex-column dkg-desc-sidmenu-navpills"
                                        >
                                          <Nav.Item className="dkg-desc-sidmenu-navitem">
                                            <Nav.Link
                                              eventKey="company"
                                              className="dkg-desc-sidmenu-navlink"
                                            >
                                              Company
                                            </Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item className="dkg-desc-sidmenu-navitem">
                                            <Nav.Link
                                              eventKey="projectss"
                                              className="dkg-desc-sidmenu-navlink"
                                            >
                                              Projects
                                            </Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item className="dkg-desc-sidmenu-navitem">
                                            <Nav.Link
                                              eventKey="role-type"
                                              className="dkg-desc-sidmenu-navlink"
                                            >
                                              Role Type
                                            </Nav.Link>
                                          </Nav.Item>
                                        </Nav>
                                      </div>
                                      <div className="col-md-10 dkg-desc-sidmenu-Col-9">
                                        <Tab.Content className="dkg-desc-sidmenu-tabContent">
                                          <Tab.Pane
                                            eventKey="company"
                                            className="dkg-desc-sidmenu-tabpane"
                                          >
                                            <div className="row mt-3">
                                              <div className="col-md-12 col-sm-12">
                                                <div className="dk-cliDetTabPaneBlkCon">
                                                  <div className="dk-cliDetTitle">
                                                    <h2>
                                                      COMPANY SUMMARY
                                                      <span className="dv-detail-plusicon-con ml-2">
                                                        <CompanySummaryModal
                                                          id={client_idd}
                                                          details={
                                                            companyDescription
                                                          }
                                                        />
                                                      </span>
                                                    </h2>
                                                  </div>
                                                  <div className="dk-cliDeteContent">
                                                    {
                                                      <div
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            companyDescription,
                                                        }}
                                                      ></div>
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Tab.Pane>
                                          <Tab.Pane
                                            eventKey="projectss"
                                            className="dkg-desc-sidmenu-tabpane"
                                          >
                                            <ProjectTab
                                              id={candidateId}
                                              projectlist={companyProjectList}
                                              defaultval="empty"
                                            />
                                          </Tab.Pane>
                                          <Tab.Pane
                                            eventKey="role-type"
                                            className="dkg-desc-sidmenu-tabpane"
                                          >
                                            <RoleTypeTab
                                              id={candidateId}
                                              projectlist={companyRoleList}
                                              defaultval="empty"
                                            />
                                          </Tab.Pane>
                                        </Tab.Content>
                                      </div>
                                    </div>
                                  </Tab.Container>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane
                              eventKey="dkg-clientlist-company"
                              className="dkg-client-tabpane-567"
                            >
                              <ClientCompany client_idd={clientidd} />
                            </Tab.Pane>
                            <Tab.Pane
                              eventKey="dkg-newprojects"
                              className="dkg-client-tabpane-567"
                            >
                              <ClientProjects />
                            </Tab.Pane>
                            <Tab.Pane
                              eventKey="dkg-newroletype"
                              className="dkg-client-tabpane-567"
                            >
                              <ClientRoletype />
                            </Tab.Pane>
                            <Tab.Pane
                              eventKey="dkg-newlanguage"
                              className="dkg-client-tabpane-567"
                            >
                              <ClientLanguage />
                            </Tab.Pane>
                            <Tab.Pane
                              eventKey="sendouts"
                              className="dkg-client-tabpane-567"
                            >
                              <ClientSendouts />
                            </Tab.Pane>
                            <Tab.Pane
                              eventKey="interviews"
                              className="dkg-client-tabpane-567"
                            >
                              <ClientInterview />
                            </Tab.Pane>
                            <Tab.Pane
                              eventKey="dkg-joboffer"
                              className="dkg-client-tabpane-567"
                            >
                              <ClientJoboffers />
                            </Tab.Pane>
                            <Tab.Pane
                              eventKey="dkg-relocation"
                              className="dkg-client-tabpane-567"
                            >
                              <ClientRelocation />
                            </Tab.Pane>
                            <Tab.Pane
                              eventKey="dkg-portal"
                              className="dkg-client-tabpane-567"
                            >
                              <ClientPortals />
                            </Tab.Pane>
                            <Tab.Pane eventKey="dkg-role-type">
                              <div className="dk-ClentViewTabPane dkg-clientlist-faqtabpane">
                                {/* <Hiringfee /> */}
                                <FAQOverview companyid={client_idd} />
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="like-dislike">
                              <div className="dk-ClentViewTabPane">
                                <LikeDisLike />
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="job-files">
                              <div className="dk-ClentViewTabPane">
                                {/* <Agreements /> */}
                                <JobDetails
                                  companyid={client_idd}
                                  fileList={newProjectList}
                                />
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="dkg-livejobs-tab">
                              {/* <div className="dk-ClentViewTabPane">
                                                            <LiveJobTable companyName={compname} />
                                                        </div> */}
                            </Tab.Pane>

                            <Tab.Pane
                              eventKey="dkg-invoices"
                              className="dkg-client-tabpane-567"
                            >
                              <ClientCompany client_idd={clientidd} />
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </div>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default memo(DetialViewPoup);
