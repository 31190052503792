import React from 'react';

const RecRecruitmentConsultant = () => {
    return (
        <>

        </>
    )
}

export default RecRecruitmentConsultant;