import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";

const FaqKpiLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/knowleadge/kpi/overview" className={`${pathname.match('/knowleadge/kpi/overview') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/knowleadge/kpi/sample-1" className={`${pathname.match('/knowleadge/kpi/sample-1') ? 'active' : ''}`}>Sample 1</Link></li>

                    <li><Link to="/knowleadge/kpi/sample-2" className={`${pathname.match('/knowleadge/kpi/sample-2') ? 'active' : ''}`}>Sample 2</Link></li>

                    <li><Link to="/knowleadge/kpi/sample-3" className={`${pathname.match('/knowleadge/kpi/sample-3') ? 'active' : ''}`}>Sample 3</Link></li>

                    {/* <li><Link to="#/training/admins/create-new-task" className={`${pathname.match('/training/admins/create-new-task') ? 'active' : ''}`}>Create New Task</Link></li>

                    <li><Link to="#/training/admins/follow-workplan" className={`${pathname.match('/training/admins/follow-workplan') ? 'active' : ''}`}>Follow Workplan</Link></li> */}

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default FaqKpiLeftPanel;

