import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const FlagFilter = () => {
    return (
        <React.Fragment>
            <Dropdown className='dkg-mypipeflag-dropdown14'>
                <Dropdown.Toggle variant="" className="dkg-mypipeflag-dropdown-toggle14">
                    <i className='fas fa-flag'></i>

                </Dropdown.Toggle>
                <Dropdown.Menu className='dkg-mypipeflag-dropdown-menu14'>
                    <div className="d-flex">
                        <Dropdown.Item href="#" className='dkg-mypipeflag-dropdown-item'>
                            <i className="fas fa-flag" style={{ color: "#3A9469" }} ></i>
                            <span className="recNoCount" >
                                On Time
                            </span>
                            <span className="recNoCount">5</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" className='dkg-mypipeflag-dropdown-item'>
                            <i className="fas fa-flag" style={{ color: "#D58B5D" }}></i>
                            <span className="recNoCount">
                                Delayed
                            </span>
                            <span className="recNoCount">8</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" className='dkg-mypipeflag-dropdown-item'>
                            <i className="fas fa-flag" style={{ color: "#E2445B" }}></i>
                            <span className="recNoCount">
                                Stopped
                            </span>
                            <span className="recNoCount">15</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" className='dkg-mypipeflag-dropdown-item'>
                            <i className="fas fa-flag" style={{ color: "#d3d3d3" }} ></i>
                            <span className="recNoCount"  >
                                Blank
                            </span>
                            <span className="recNoCount">5</span>
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    )
}
export default FlagFilter;