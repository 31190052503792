import React from 'react'
import Payments from '../../tasks/payments/Payments'


const PaymentTasksTopay = () => {
    return (
        <>
            <div className="dkg-payment-suMM-mainCon" style={{ marginTop: "160px" }}>
                <Payments />
            </div>
        </>

    )
}

export default PaymentTasksTopay