import 'date-fns';
import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import './candprofiledatepicker.scss'

const DatePicker = (props) => {
    // The first commit of Material-UI
    const [selectedDate, setSelectedDate] = React.useState(new Date(props.date));
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    useEffect(() => {
        setSelectedDate(new Date(props.date))
    }, [props.date])

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} className="dk-candprofile-DatePicker">
            <Grid container alignItems="center" justify="center" className='dk-nativedatepicker-grid dk-candprofile-DatePicker-grid'>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd MMM yy"
                    margin="normal"
                    id="date-picker-inline dk-startDatenativepicker dk-candprofile-DateImePicker"
                    className="dk-nativedatepicker"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    autoOk={true}
                    onClose={() => props.updateDates(`${props.candidateId}==${selectedDate}==${props.column}`)}
                    error={false}
                    helperText={null}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
}
export default DatePicker;