import React from 'react';
import { Link } from 'react-router-dom';

const KpiJobSpecsLeftPanel = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    return (
        <>
            <div className="dk-kpiLeftPanel">
                <ul>
                    <li><Link to="/kpis/candidates/dashboard" className={`${pathname.match('/kpis/candidates/dashboard') ? 'active' : ''}`}>SUMMARY</Link></li>
                    <li><Link to="/kpis/candidates/new-case" className={`${pathname.match('/kpis/candidates/new-case') ? 'active' : ''}`}>CASE STATS</Link></li>
                    <li><Link to="/kpis/candidates/job-sepcs" className={`${pathname.match('/kpis/candidates/job-sepcs') ? 'active' : ''}`}>CASE REPORT</Link></li>
                    <li><Link to="/kpis/candidates/sendouts" className={`${pathname.match('/kpis/candidates/sendouts') ? 'active' : ''}`}>CASE DETAILS</Link></li>
                    {/* <li><Link to="/kpis/candidates/interviews" className={`${pathname.match('/kpis/candidates/interviews') ? 'active' : ''}`}>Interviews</Link></li>
                    <li><Link to="/kpis/candidates/job-offers" className={`${pathname.match('/kpis/candidates/job-offers') ? 'active' : ''}`}>Job Offers</Link></li>
                    <li><Link to="/kpis/candidates/refused" className={`${pathname.match('/kpis/candidates/refused') ? 'active' : ''}`}>Refused</Link></li>
                    <li><Link to="/kpis/candidates/joined" className={`${pathname.match('/kpis/candidates/joined') ? 'active' : ''}`}>Joined</Link></li>
                    <li><Link to="/kpis/candidates/invoiced" className={`${pathname.match('/kpis/candidates/invoiced') ? 'active' : ''}`}>Invoiced</Link></li>
                    <li><Link to="/kpis/candidates/credits" className={`${pathname.match('/kpis/candidates/credits') ? 'active' : ''}`}>Credits</Link></li> */}
                </ul>
            </div>
        </>
    )
}

export default KpiJobSpecsLeftPanel;