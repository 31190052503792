import React from 'react';
import { Link } from 'react-router-dom'
import { DropdownButton, Dropdown, Tab, Nav } from 'react-bootstrap';
import './clientdetails.scss'
import DetailViewTab from './DetailViewTab'
import { FaProjectDiagram, FaCriticalRole } from "react-icons/fa";
import { MdOutlineSummarize } from "react-icons/md";

const ClientsDetails = () => {
    return (
        <>
            <div className='container-fluid dkg-companyclient-detMain'>
                <div className="row">
                    <div className="col-md-12">
                        <div className="dk-clients-details-ViewPagN">
                            <div className='dk-clientsid-con'>
                                <Link to="#">ID - 1233</Link>
                            </div>
                            <ul>
                                <li className="dk-detListHover">
                                    <Link to="#">
                                        <i className="fa fa-fast-backward" aria-hidden="true"></i>
                                    </Link>
                                </li>
                                <li className="dk-detListHover">
                                    <Link to="#">
                                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                    </Link>
                                </li>
                                <li aria-current="page">
                                    <Link to="#" className="dk-NoCounttext">1 of 100 records</Link>
                                </li>
                                <li className="dk-detListHover">
                                    <Link to="#">
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </Link>
                                </li>
                                <li className="dk-detListHover">
                                    <Link to="#">
                                        <i className="fa fa-fast-forward" aria-hidden="true"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12 dkg-ClientDetailSecCon">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="overview-tab">
                            <div className="row">
                                <div className="col-md-2 col-sm-12 dk-ClieDetViewSideMenu-12 pl-0">
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link eventKey="overview-tab" style={{ borderTop: "1px solid #dadada" }}>
                                                <span className='dkg-tab-icon mr-2'>
                                                    <MdOutlineSummarize />
                                                </span>
                                                Overview
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link eventKey="live-jobs">
                                                <span className='dkg-tab-icon mr-2'>
                                                    <i className="fa fa-address-book" aria-hidden="true"></i>
                                                </span>
                                                Contacts</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link eventKey="projects">
                                                <span className='dkg-tab-icon mr-2'>
                                                    <FaProjectDiagram />
                                                </span>
                                                Projects</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link eventKey="contacts">
                                                <span className='dkg-tab-icon mr-2'>
                                                    <FaCriticalRole />
                                                </span>Role Type
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link eventKey="socialm"><span className='dkg-tab-icon mr-2'>
                                                <i className="fa fa-language" aria-hidden="true"></i>
                                            </span>Languages</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link style={{ color: "#037db2" }}>workflow</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link eventKey="jobstatus">Jobs Status</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link eventKey="livejobs">Live Jobs</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link eventKey="closed-jobs">Closed Jobs</Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link eventKey="job-specs">Job Specs</Nav.Link>
                                        </Nav.Item> */}
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link eventKey="open-job">Open Job</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link style={{ color: "#037db2" }}>Social M</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link eventKey="thirteen">Platform</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link eventKey="fiveteen">Salaries</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link eventKey="sixteen">Benefits</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link eventKey="seventeen">Relocation</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link eventKey="fourteen">Interviews</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='dkg-clidetails12'>
                                            <Nav.Link eventKey="eighteen">Likes/Dislikes</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                <div className="col-md-10 col-sm-12 dk-ClieDetViewMainCont-12">
                                    <Tab.Content className='dkg-clientdet-tabcontent-12'>
                                        <Tab.Pane eventKey="overview-tab" className="dk-ClentViewTabPane-12">
                                            <DetailViewTab />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="live-jobs">
                                            <div className="dk-ClentViewTabPane-12">
                                                Live Jobs
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="contacts">
                                            <div className="dk-ClentViewTabPane-12">
                                                Contacts
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="projects">
                                            <div className="dk-ClentViewTabPane-12">
                                                Projects
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="socialm">
                                            <div className="dk-ClentViewTabPane-12">
                                                Social
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="workflow">
                                            <div className="dk-ClentViewTabPane-12">
                                                WorkFlow
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="jobstatus">
                                            <div className="dk-ClentViewTabPane-12">
                                                Job Status
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="livejobs">
                                            <div className="dk-ClentViewTabPane-12">
                                                Live Jobs
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="closed-jobs">
                                            <div className="dk-ClentViewTabPane-12">
                                                Closed Jobs
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="job-specs">
                                            <div className="dk-ClentViewTabPane-12">
                                                Job Specs
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="open-job">
                                            <div className="dk-ClentViewTabPane-12">
                                                Open Job
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="interviews">
                                            <div className="dk-ClentViewTabPane-12">
                                                Interviews
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="preferences">
                                            <div className="dk-ClentViewTabPane-12">
                                                Preferences
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="invoices">
                                            <div className="dk-ClentViewTabPane-12">
                                                Invoices
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="hiringfee">
                                            <div className="dk-ClentViewTabPane-12">
                                                Hiringfee
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="agreements">
                                            <div className="dk-ClentViewTabPane-12">
                                                Agreements
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClientsDetails