import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'

import Dropdown from 'react-bootstrap/Dropdown'
import DatePicker from './DatePicker';
import Recruiter from '../../../../../assets/images/deepak.png';
import SubmitButton from "../../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import 'date-fns';
import AWS from 'aws-sdk'
import { StaticDatePicker } from '@mui/x-date-pickers';


const AddNewPayment = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };
    return (
        <>
            <Link to="#" onClick={handleShow}><i className="fa fa-plus" aria-hidden="true"></i></Link>
            <Modal show={show} onHide={handleClose} className="dkg-addnew-paymenttask-modal-21">
                <Modal.Header closeButton className="dkg-addnew-payment-modalheader-21">
                    <Modal.Title>Create New Payment Task</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-addnew-payment-modalbody-21">
                    <div className='row'>
                        <div className='col-md-6 pr-2'>
                            <div className='form-group d-flex align-items-center'>
                                <label style={{ minWidth: "135px" }}>Task By</label>
                                <div className='dkg-taskby-u12 dkg-move-centre'>
                                    <div className='dkg-taskby-imgCon'>
                                        <img src="https://d3eppau9to224z.cloudfront.net/users/images/1580424792.jpeg" alt="Task By" className='dkg-taskby-img' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 pl-2'>
                            <div className='form-group'>
                                <label style={{ minWidth: "135px" }}>Task For</label>
                                <div className='dkg-taskby-u12 dkg-move-centre'>
                                    <div className='dkg-taskby-imgCon'>
                                        <img src={Recruiter} alt="Task By" className='dkg-taskby-img' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Task Due On</label>
                                <div className='dkg-addnew-date-timeCon'>
                                    <div className='w-100'>
                                        <DatePicker />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Status</label>
                                <input type="text" className="form-control" style={{ backgroundColor: "#d58b5d", color: "#fff" }} defaultValue={"New Task"} />
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Priority</label>
                                <div className='dkg-payment-task-drop12'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" className="form-control">
                                            Select
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu >
                                            <Dropdown.Item className="form-control">Category 1</Dropdown.Item>
                                            <Dropdown.Item className="form-control">Category 2</Dropdown.Item>
                                            <Dropdown.Item className="form-control">Category 3</Dropdown.Item>
                                            <Dropdown.Item className="form-control">Category 4</Dropdown.Item>
                                            <Dropdown.Item className="form-control">Category 5</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Category</label>
                                <div className='dkg-payment-task-drop12'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" className="form-control">
                                            Select
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu >
                                            <Dropdown.Item className="form-control">Category 1</Dropdown.Item>
                                            <Dropdown.Item className="form-control">Category 2</Dropdown.Item>
                                            <Dropdown.Item className="form-control">Category 3</Dropdown.Item>
                                            <Dropdown.Item className="form-control">Category 4</Dropdown.Item>
                                            <Dropdown.Item className="form-control">Category 5</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Pay Type</label>
                                <div className='dkg-payment-task-drop12'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" className="form-control">
                                            Select
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu >
                                            <Dropdown.Item className="form-control" > Pay Type 1</Dropdown.Item>
                                            <Dropdown.Item className="form-control" > Pay Type 2</Dropdown.Item>
                                            <Dropdown.Item className="form-control" > Pay Type 3</Dropdown.Item>
                                            <Dropdown.Item className="form-control" > Pay Type 4</Dropdown.Item>
                                            <Dropdown.Item className="form-control" > Pay Type 5</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 col-12 pl-0 pr-0'>
                        <div className='form-group'>
                            <label className=''>Payment To</label>
                            <input type="text" className="form-control" />
                        </div>
                    </div>
                    <div className='col-md-12 col-12 pl-0 pr-0'>
                        <div className='form-group'>
                            <label className=''>INV Month</label>
                            <div className='dkg-payment-task-drop12'>
                                <Dropdown>
                                    <Dropdown.Toggle variant="" className="form-control">
                                        Select
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu >
                                        <Dropdown.Item className="form-control">JAN</Dropdown.Item>
                                        <Dropdown.Item className="form-control">FEB</Dropdown.Item>
                                        <Dropdown.Item className="form-control">MAR</Dropdown.Item>
                                        <Dropdown.Item className="form-control">APR</Dropdown.Item>
                                        <Dropdown.Item className="form-control">MAY</Dropdown.Item>
                                        <Dropdown.Item className="form-control">JUN</Dropdown.Item>
                                        <Dropdown.Item className="form-control">JUL</Dropdown.Item>
                                        <Dropdown.Item className="form-control">AUG</Dropdown.Item>
                                        <Dropdown.Item className="form-control">SEP</Dropdown.Item>
                                        <Dropdown.Item className="form-control">OCT</Dropdown.Item>
                                        <Dropdown.Item className="form-control">NOV</Dropdown.Item>
                                        <Dropdown.Item className="form-control">DEC</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 col-12 pl-0 pr-0'>
                        <div className='form-group'>
                            <label className=''>INV Number</label>
                            <input type="text" className="form-control" />
                        </div>
                    </div>
                    <div className='col-md-12 col-12 pl-0 pr-0'>
                        <div className='form-group'>
                            <label className=''>Pay Amount</label>
                            <div className='d-flex w-100'>
                                <input type="text" className="form-control" />
                                <span className='dkg-euro-btnCon'><button className='dkg-euro-btn'>€</button></span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 col-12 pl-0 pr-0'>
                        <div className='form-group'>
                            <label className=''>Attachment File</label>
                            <input type="file" id="imageInputAPI" className="form-control w-100 dkg-payment-attach-input" />
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='dkg-create-payment-taskbtncon'>
                            <SubmitButton txt="CREATE TASK" className="dkg-create-payment-taskbtn" />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddNewPayment