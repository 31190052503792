import React, { useEffect, useState } from "react";
import "../../accmanager.scss";
import Dropdown from "react-bootstrap/Dropdown";

const AMFilter = ({ list, id, img, selectUserFilter }) => {
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.recAM.uid)) {
        map.set(item.original.recAM.uid, true);
        result.push({
          id: item.original.recAM.uid,
          name: item.original.recAM.firstName,
          userImg: item.original.recAM.profileImage,
          count: list.filter(function (val) {
            return val.original.recAM.uid === item.original.recAM.uid;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setUserList(result);
  }, [list]);
  const totalCount = userList.reduce(
    (total, row) => (total = total + Number(row.count)),
    0
  );
  return (
    <>
      <Dropdown className="dk-AmDropDwn">
        <Dropdown.Toggle variant="" className="dk-AmDropDwnToggle">
          {id === "0" ? (
            <React.Fragment>
              <div className="tooltipMain">
                RC
                <div className="tooltipText">RC - {totalCount}</div>
              </div>
            </React.Fragment>
          ) : (
            <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + img} />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dk-AmDropDwnMenu">
          <div className="d-flex">
            {userList &&
              userList.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  data-type="rc"
                  data-id={item.id}
                  data-img={item.userImg}
                  onClick={selectUserFilter}
                >
                  <img
                    src={
                      process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg
                    }
                    data-type="rc"
                    data-id={item.id}
                    data-img={item.userImg}
                    onClick={selectUserFilter}
                    alt="rc"
                    className="accManImg"
                  />
                  <span
                    className="counterNo"
                    data-type="rc"
                    data-id={item.id}
                    data-img={item.userImg}
                    onClick={selectUserFilter}
                  >
                    {item.count}
                  </span>
                </Dropdown.Item>
              ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default AMFilter;
