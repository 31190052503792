import React, { useState } from "react";

const PophoverIcon = (props) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <>
      <div className="dkg-pophover-iconCon">
        <span className="dkg-info-icon mr-2">
          <label
            className="dkg-pophover-label"
            onMouseOver={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
          >
            {props.dkglabeltitle}
          </label>
          {/* <i className="fa fa-info" aria-hidden="true"></i> */}
        </span>
        {isShown && <div className="dkg-pophoverMenu">{props.hovertitle}</div>}
      </div>
    </>
  );
};

export default PophoverIcon;
