import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import Submenu from '../../subMenu/ExpIncomeSubmenu';
import './acc-exp-income.scss';

const AccountingMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    console.log("Exp Income Seqment2" + segment2);
    console.log("Exp Income Seqment3" + segment3);

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-acc-expincome-Navbar">
                <Route>
                    <ul>
                        <li><Link to="/dkglobalfinance/accounting/dashboard" className={(segment3 == 'dashboard') ? 'active' : ''}>Dashboard</Link></li>
                        {/* <li><Link to="/dkglobalfinance/accounting/income/summary" className={(segment3 == 'income') ? 'active' : ''}>Income</Link></li> */}
                        <li><Link to="/dkglobalfinance/accounting/expense/summary" className={(segment3 == 'expense') ? 'white-active' : ''}>Registration</Link></li>
                        <li><Link to="/dkglobalfinance/accounting/profit-loss/summary" className={(segment3 == 'profit-loss') ? 'active' : ''}>Rec License</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-1') ? '' : ''}`}>Resident Cert</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-2') ? '' : ''}`}>Tax Clearance</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-3') ? '' : ''}`}>Directors Doc</Link></li>
                    </ul>
                </Route>
            </div>
            <Submenu path={pathname} />
        </React.Fragment>
    );
}

export default AccountingMenu;