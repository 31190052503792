import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import "../responsiveMenu.scss";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import './mainmenusubmenuc.scss';
import { counts, archivedCounts } from "../../../../../../../slice/task/taskSlice"
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const IntSpecSubmenu = (props) => {
    const dispatch = useDispatch();
    const { isSuccess, isMoved, isArchive, countsProgress, archivedDataCount } = useSelector(state => state.task);

    const classes = useStyles();
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    // console.log("Dashboard Departments :" + segment2);

    const [toggleMenu, setToggleMenu] = useState(false);
    useEffect(() => {
        setToggleMenu(false)
    }, [pathname])

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setToggleMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(counts({}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
    }, [])

    useEffect(() => {
        if (isSuccess) {
            const fetchList = async () => {
                try {
                    await dispatch(counts({}))

                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }
        if (isMoved) {
            const fetchList = async () => {
                try {
                    await dispatch(counts({}))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }
        if (isArchive) {
            const fetchList = async () => {
                try {
                    await dispatch(archivedCounts({}))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }
        dispatch(archivedCounts({}))
    }, [isSuccess, isMoved, isArchive])

    return (
        <React.Fragment>
            <div className="dk-subMenuMain dkg-mainModuleSubmenu res-sub-menu" ref={ref}>
                <IconButton aria-label="delete" className="res-sub-btn" onClick={() => setToggleMenu(!toggleMenu)}>
                    <i className="fas fa-bars"></i>
                </IconButton>
                <Route>
                    <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
                        <li><Link to="/int-spec/summary" className={`${(segment2 == 'summary') ? 'active' : ''}`}>Summary</Link></li>
                        <li><Link to="#/int-spec/live-spec" className={(segment2 == 'live-spec') ? 'active' : ''}>Int Spec 1</Link></li>
                        <li><Link to="#/int-spec/active-spec" className={`${(segment2 == 'active-spec') ? 'active' : ''}`}>Int Spec 2</Link></li>
                        <li><Link to="#/int-spec/saved-spec" className={`${(segment2 == 'saved-spec') ? 'active' : ''}`}>Int Spec 3</Link></li>
                        <li><Link to="#/int-spec/create-spec" className={`${(segment2 == 'create-spec') ? 'active' : ''}`}>Int Spec 4</Link></li>
                        <li><Link to="#/int-spec/live-archived" className={`${(segment2 == 'live-archived') ? 'active' : ''}`}>Int Spec 5</Link></li>
                        <li><Link to="#/int-spec/saved-archived" className={`${(segment2 == 'saved-archived') ? 'active' : ''}`}>Int Spec 6</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default IntSpecSubmenu;
