import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData,DeleteData } from "../../services";

export const getBankingList = createAsyncThunk(
    'bankingAccountbooks/list',
    async ({year},thunkAPI) => {
        const res = await GetData(true, '/accounting/banking/'+year)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const addNewTask = createAsyncThunk(
    'bankingAccountbooks/add-new',
    async ({ transaction, year, month, category, paidOut, paidIn, ballance, vatNo, services, business,plateform}, thunkAPI) => {
        const res = await PostData(true, '/accounting/banking', {transaction, year, month, category, paidOut, paidIn, ballance, vatNo, services, business,plateform})
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const updateDetails = createAsyncThunk(
    'bankingAccountbooks/update',
    async ({ id,transaction, year, month, category, paidOut, paidIn, ballance, vatNo, services, business,plateform }, thunkAPI) => {
        const res = await PutData(true, '/accounting/banking/' + id, { transaction, year, month, category, paidOut, paidIn, ballance, vatNo, services, business,plateform })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateByKey = createAsyncThunk(
    'bankingAccountbooks/update/key-value',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/accounting/banking/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const deleteFun = createAsyncThunk(
    'bankingAccountbooks/count/delete',
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, '/accounting/banking/'+id )
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const moveRecordsToPaid = createAsyncThunk(
    'bankingAccountbooks/count/bankingAccountbooks/move-to-table',
    async ({ id, status }, thunkAPI) => {
        const res = await PostData(true, '/accounting/banking/move-to-table', { id, status })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateAccountBankingCommentCount = createAsyncThunk(
    'bankingAccountbooks/comment-list/update-comment',
    async ({ id, value, commentAt }, thunkAPI) => {
        const res = await PutData(true, '/accounting/banking/update-comment/' + id, { value, commentAt })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskDetails = createAsyncThunk(
    'bankingAccountbooks/task/details',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/accounting/banking/detail/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const fetchCaseStatus = createAsyncThunk(
    'bankingAccountbooks/caseStatus/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const fetchCaseProgress = createAsyncThunk(
    'bankingAccountbooks/caseProgress/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const fetchCaseStage = createAsyncThunk(
    'bankingAccountbooks/caseStage/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const plateformDataFetch = createAsyncThunk(
    'bankingAccountbooks/plateform/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const categoryDataFetch = createAsyncThunk(
    'bankingAccountbooks/category/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const servicesDataFetch = createAsyncThunk(
    'bankingAccountbooks/service/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const businessNameDataFetch = createAsyncThunk(
    'bankingAccountbooks/business/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const vatNoDataFetch = createAsyncThunk(
    'bankingAccountbooks/valNo/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const listSlice = createSlice({
    name: 'bankinglist',
    initialState: {
        bankingBookList: [],
        paidsalesBookList: [],
        taskDataSummaryList: [],
        taskTypeList: [],
        taskPaymentMonthList: [],
        taskPayTypeList: [],
        countsProgressList: [],
        countsStageList: [],
        paymentDataCount: [],
        paidPaymentDataCount: [],
        caseStatusList:[],
        caseProgressList:[],
        caseStageList:[],
        categoryList:[],
        plateformList:[],
        servicesList:[],
        businessNameList:[],
        vatNoList:[],
        tasksDetails: {},
        countAll: 0,
        isLoading: false,
        isbankingLoading: false,
        isDetailLoading: false,
        isTaskInsert: false,
        isTaskUpdate: false,
        isTaskDelete: false,
        isUpdate: false,
        iscommentCountUpdate: false,
        isError: false,
        isSuccess: false,
        isDataMoveToPayment: false,
        isTaskInserted: false,
        isMoved: false,
        isArchive: false,
        message: ''
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isbankingLoading = false;
            state.isDetailLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.issSuccess = false;
            state.isDataMoveToPayment = false;
            state.isTaskInserted = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },
        clearTaskState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isbankingLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.isTaskInserted = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },
        clearDetails: (state) => {
            state.tasksDetails = {}
        }
    },
    extraReducers: (builder) => {
       
        builder.addCase(
            getBankingList.pending, (state) => {
                state.isLoading = true;
                state.isbankingLoading = true;
                state.issSuccess = false;
            });
        builder.addCase(
            getBankingList.fulfilled, (state, { payload }) => {
                state.bankingBookList = payload.result
                state.isLoading = false;
                state.isbankingLoading = false;
                state.issSuccess = true;
            });
        builder.addCase(
            getBankingList.rejected, (state, action) => {
                state.isLoading = false;
                state.isbankingLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

       

        builder.addCase(
            addNewTask.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            addNewTask.fulfilled, (state, { payload }) => {
                state.bankingBookList.unshift(payload.result)
                state.isLoading = false;
                state.isSuccess = true;
                state.isTaskInserted = true;
            });
        builder.addCase(
            addNewTask.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

       
        builder.addCase(
            updateDetails.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            updateDetails.fulfilled, (state, { payload }) => {
                let mainArray = state.bankingBookList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result[0].id) {
                        return (payload.result[0])
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.isLoading = false;
                state.bankingBookList = finalResult;
            });
        builder.addCase(
            updateDetails.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            updateByKey.pending, (state) => {
                state.isUpdate = false
                state.isLoading = false;
            });
        builder.addCase(
            updateByKey.fulfilled, (state, { payload }) => {
                let mainArray = state.bankingBookList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.bankingBookList = finalResult;
                state.message = payload.message
            });
        builder.addCase(
            updateByKey.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });    
            

        builder.addCase(
            deleteFun.pending, (state) => {
            });
        builder.addCase(
            deleteFun.fulfilled, (state, { payload }) => {
                state.isTaskDelete = true;
                state.isMoved = true;
            });
        builder.addCase(
            deleteFun.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
       
         builder.addCase(
            moveRecordsToPaid.pending, (state) => {
            });
        builder.addCase(
            moveRecordsToPaid.fulfilled, (state, { payload }) => {
                state.isMoved = true;
                state.message = payload.message
            });
        builder.addCase(
            moveRecordsToPaid.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateAccountBankingCommentCount.pending, (state) => {
                state.iscommentCountUpdate = false
            });
        builder.addCase(
            updateAccountBankingCommentCount.fulfilled, (state, action) => {
                let mainArray = state.bankingBookList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === action.meta.arg.id) {
                        return ({ ...r, commentCount: action.meta.arg.value, commentAt: action.meta.arg.commentAt })
                    } else {
                        return r
                    }
                })
                state.bankingBookList = finalResult              
                state.iscommentCountUpdate = true
            });
        builder.addCase(
            updateAccountBankingCommentCount.rejected, (state, action) => {
                state.isError = true;
            });



        builder.addCase(
            taskDetails.pending, (state) => {
                state.isDetailLoading = true
            });
        builder.addCase(
            taskDetails.fulfilled, (state, { payload }) => {
                state.isDetailLoading = false
                state.tasksDetails = payload.result;
            });
        builder.addCase(
            taskDetails.rejected, (state, action) => {
                state.isDetailLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            fetchCaseStatus.pending, (state) => {
            });
        builder.addCase(
            fetchCaseStatus.fulfilled, (state, { payload }) => {
                state.caseStatusList = payload.result
            });
        builder.addCase(
            fetchCaseStatus.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            fetchCaseProgress.pending, (state) => {
            });
        builder.addCase(
            fetchCaseProgress.fulfilled, (state, { payload }) => {
                state.caseProgressList = payload.result
            });
        builder.addCase(
            fetchCaseProgress.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            fetchCaseStage.pending, (state) => {
            });
        builder.addCase(
            fetchCaseStage.fulfilled, (state, { payload }) => {
                state.caseStageList = payload.result
            });
        builder.addCase(
            fetchCaseStage.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        
        builder.addCase(
            plateformDataFetch.pending, (state) => {
            });
        builder.addCase(
            plateformDataFetch.fulfilled, (state, { payload }) => {
                state.plateformList = payload.result
            });
        builder.addCase(
            plateformDataFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            }); 

        builder.addCase(
            categoryDataFetch.pending, (state) => {
            });
        builder.addCase(
            categoryDataFetch.fulfilled, (state, { payload }) => {
                state.categoryList = payload.result
            });
        builder.addCase(
            categoryDataFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            }); 

        builder.addCase(
            servicesDataFetch.pending, (state) => {
            });
        builder.addCase(
            servicesDataFetch.fulfilled, (state, { payload }) => {
                state.servicesList = payload.result
            });
        builder.addCase(
            servicesDataFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            }); 

        builder.addCase(
            businessNameDataFetch.pending, (state) => {
            });
        builder.addCase(
            businessNameDataFetch.fulfilled, (state, { payload }) => {
                state.businessNameList = payload.result
            });
        builder.addCase(
            businessNameDataFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });     

        builder.addCase(
            vatNoDataFetch.pending, (state) => {
            });
        builder.addCase(
            vatNoDataFetch.fulfilled, (state, { payload }) => {
                state.vatNoList = payload.result
            });
        builder.addCase(
            vatNoDataFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });      
    }
});


export const { clearState, clearTaskState, clearDetails } = listSlice.actions;
export default listSlice.reducer;