import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaCheck, FaTimes, FaQuestion } from "react-icons/fa";
import { profileStatus } from "../../../../../../helper/commonFunctions";

const TrickFilter = ({ list, id, img, selectUserFilter }) => {

    return (
        <>
            <div className='dkg-top-right-pipe-trickFilter'>
                <Dropdown className="dk-pipeline-idDropdownMain">
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                        {
                            (id === '-1') ? <FaCheck /> : <FaCheck style={{ color: img }} />
                        }
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='dk-pipe-lhstricknoDropMenu'>
                        <Dropdown.Item key="2" href="#" data-type="PSID" data-id="1" data-img="#048204" onClick={selectUserFilter}>
                            <div className="dk-idDropdown-cols" data-type="PSID" data-id="1" data-img="#048204" onClick={selectUserFilter}>
                                <span data-type="PSID" data-id="1" data-img="#048204" onClick={selectUserFilter}>
                                    <FaCheck style={{ color: "#048204" }} data-type="PSID" data-id="1" data-img="#048204" onClick={selectUserFilter} />
                                </span>
                                <span className='dkg-tricker' data-type="PSID" data-id="1" data-img="#048204" onClick={selectUserFilter}>Top Priority</span>
                                <span className='dkg-tricker' data-type="PSID" data-id="1" data-img="#048204" onClick={selectUserFilter}>
                                    {
                                        list.filter(function (val) {
                                            return val.original.profileStatus === 1;
                                        }).length
                                    }

                                </span>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item key="3" href="#" data-type="PSID" data-id="2" data-img="#D58B5D" onClick={selectUserFilter}>
                            <div className="dk-idDropdown-cols" data-type="PSID" data-id="2" data-img="#D58B5D" onClick={selectUserFilter}>
                                <span data-type="PSID" data-id="2" data-img="#D58B5D" onClick={selectUserFilter}>
                                    <FaCheck style={{ color: "#D58B5D" }} data-type="PSID" data-id="2" data-img="#D58B5D" onClick={selectUserFilter} />
                                </span>
                                <span className='dkg-tricker' data-type="PSID" data-id="2" data-img="#D58B5D" onClick={selectUserFilter}>Mid Priority</span>
                                <span className='dkg-tricker' data-type="PSID" data-id="2" data-img="#D58B5D" onClick={selectUserFilter}>
                                    {
                                        list.filter(function (val) {
                                            return val.original.profileStatus === 2;
                                        }).length
                                    }

                                </span>
                            </div>
                        </Dropdown.Item>
                        {/* <Dropdown.Item key="4" href="#" data-type="PSID" data-id="3" data-img="#3A76B4" onClick={selectUserFilter}>
                            <div className="dk-idDropdown-cols" data-type="PSID" data-id="3" data-img="#3A76B4" onClick={selectUserFilter}>
                                <span data-type="PSID" data-id="3" data-img="#3A76B4" onClick={selectUserFilter}>
                                    <FaCheck style={{ color: "#3A76B4" }} data-type="PSID" data-id="3" data-img="#3A76B4" onClick={selectUserFilter} />
                                </span>
                                <span className='dkg-tricker' data-type="PSID" data-id="3" data-img="#3A76B4" onClick={selectUserFilter}>50% Matched</span>
                                <span className='dkg-tricker' data-type="PSID" data-id="3" data-img="#3A76B4" onClick={selectUserFilter}>
                                    {
                                        list.filter(function (val) {
                                            return val.original.profileStatus === 3;
                                        }).length
                                    }

                                </span>
                            </div>
                        </Dropdown.Item> */}
                        <Dropdown.Item key="5" href="#" data-type="PSID" data-id="4" data-img="#d60101" onClick={selectUserFilter}>
                            <div className="dk-idDropdown-cols" data-type="PSID" data-id="4" data-img="#d60101" onClick={selectUserFilter}>
                                <span data-type="PSID" data-id="4" data-img="#d60101" onClick={selectUserFilter}>
                                    <FaCheck style={{ color: "#d60101" }} data-type="PSID" data-id="4" data-img="#d60101" onClick={selectUserFilter} />
                                </span>
                                <span className='dkg-tricker' data-type="PSID" data-id="4" data-img="#d60101" onClick={selectUserFilter}> Low Priority</span>
                                <span className='dkg-tricker' data-type="PSID" data-id="4" data-img="#d60101" onClick={selectUserFilter}>
                                    {
                                        list.filter(function (val) {
                                            return val.original.profileStatus === 4;
                                        }).length
                                    }
                                </span>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item key="1" href="#" data-type="PSID" data-id="0" data-img="#d3d3d3" onClick={selectUserFilter}>
                            <div className="dk-idDropdown-cols" data-type="PSID" data-id="0" data-img="#d3d3d3" onClick={selectUserFilter}>
                                <span data-type="PSID" data-id="0" data-img="#d3d3d3" onClick={selectUserFilter}>
                                    <FaCheck style={{ color: "#d3d3d3" }} data-type="PSID" data-id="0" data-img="#d3d3d3" onClick={selectUserFilter} />
                                </span>
                                <span className='dkg-tricker' data-type="PSID" data-id="0" data-img="#d3d3d3" onClick={selectUserFilter}>Blank</span>
                                <span className='dkg-tricker' data-type="PSID" data-id="0" data-img="#d3d3d3" onClick={selectUserFilter}>
                                    {
                                        list.filter(function (val) {
                                            return val.original.profileStatus === 0;
                                        }).length
                                    }
                                </span>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default TrickFilter