import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const listTable = createAsyncThunk(
    'teamMember/list',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/teams/',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const fethById = createAsyncThunk(
    'teamMember/singleRecord',
    async ({ teamId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/teams/' + teamId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)
export const updateTeamDetails = createAsyncThunk(
    'teamMember/update',
    async ({ id, userId, name, jobTitle, location, joinDate, level, nationality, status }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/teams/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        userId, name, jobTitle, location, joinDate, level, nationality, status
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)
export const teamDelete = createAsyncThunk(
    'teamMember/delete',
    async ({ rowId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/teams/' + rowId,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

export const reorderTeam = createAsyncThunk(
    'teamMember/reorder',
    async ({ ids }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/teams/reorder/',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        ids
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const addNewTeam = createAsyncThunk(
    'teamMember/add',
    async ({ userId, name, jobTitle, location, joinDate, level, nationality, status, companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/teams',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        userId, name, jobTitle, location, joinDate, level, nationality, status, companyId
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)
const teamSlice = createSlice({
    name: 'teamMember',
    initialState: {
        dataList: {},
        teamDetails: {},
        isSuccess: false,
        isInsert: false,
        isUpdate: false,
        isDelete: false,
        isLoading: false,
        isError: false,
        isReoder: false,
        msg: ''
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
            state.isError = false;
            state.isReoder = false;
            state.msg = '';
        }
    },
    extraReducers: {
        [listTable.pending]: (state) => {
            state.isLoading = true;
        },
        [listTable.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.dataList = payload.result;
        },
        [listTable.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        [addNewTeam.pending]: (state) => {
            state.isLoading = true;
        },
        [addNewTeam.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = true;
            state.dataList.unshift(payload.result)
            state.msg = payload.message
        },
        [addNewTeam.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message
        },
        [fethById.pending]: (state) => {
            state.isLoading = true;
        },
        [fethById.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.teamDetails = payload.result;
        },
        [fethById.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
        },
        [updateTeamDetails.pending]: (state) => {
            state.isLoading = true;
        },
        [updateTeamDetails.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.dataList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.dataList = finalResult
            state.msg = payload.message
        },
        [updateTeamDetails.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message
        },
        [teamDelete.pending]: (state) => {

        },
        [teamDelete.fulfilled]: (state, action) => {
            state.isDelete = true;
            state.dataList = state.dataList.filter((item) => item.id !== action.meta.arg.rowId)
            state.msg = 'Delete Successfully';
        },
        [teamDelete.rejected]: (state, { payload }) => {
            state.isDelete = false;
            state.isError = true;
            state.msg = payload.msgBody
        },
        [reorderTeam.pending]: (state) => {
            state.isloading = true;
            state.isReoder = false;
        },
        [reorderTeam.fulfilled]: (state, { payload }) => {
            state.isloading = false;
            state.isReoder = true;
            state.msg = "Reorder successfully.";
        },
        [reorderTeam.rejected]: (state, { payload }) => {
            state.isloading = false;
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
        },
    }
})
export const { clearState } = teamSlice.actions
export default teamSlice.reducer;
