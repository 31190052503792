import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import SubmitButton from "../../../../../ui/submitButton";
import { clearState, addByCaseId,getList } from "../../../../../../slice/accounting/businessBookSlice";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../../../slice/utils/message/messageSlice";

const JobIdModal = ({ onHide,currentYear,currentType }) => {
    const dispatch = useDispatch();    
    const {  isUpdate, isTaskInserted} = useSelector(state => state.businessBook);
    const inputRef = React.useRef(null);
   // const [year, setYear] = useState(currentYear) 
    //const [type, setType] = useState(currentType)

    React.useEffect(()=>{
        inputRef.current.focus();
      }, []);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [show, setShow] = useState(true)

    const [caseId, setCaseId] = useState('')
    // useEffect(() => {
    //     if (isTaskInserted) {
    //         setLoading(false)
    //         setSuccess(true)
    //     }
    // }, [isTaskInserted])

    useEffect(() => {

        if (isTaskInserted) {
            setCaseId('')           
            setSuccess(false);
            setLoading(false);
        }

    }, [isTaskInserted])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {           
            if (!caseId) {
                dispatch(showError({ msg: 'Please enter case id' }))
            } else {
                setLoading(true);
                dispatch(addByCaseId({ caseId }))
            }
        }
        catch
        {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }
    return (
        <>
            <Modal className="dk-jobIdModal" centered show={show} onHide={onHide}>
                <Modal.Body>
                    <div className="d-flex">

                        <div className="form-group d-flex align-items-center; ">
                            <label htmlFor="" className='mb-0' style={{ minWidth: "105px", marginTop: "4px" }}>Enter Case ID</label>
                            <label htmlFor="">
                            <input type="text" name="" value={caseId} className="form-control" onChange={(e) => setCaseId(e.target.value)}  ref={inputRef} />
                            </label>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">

                        <SubmitButton txt="ADD" loading={loading} success={success} onClick={handleSubmit} className="dk-saveBtn" />
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default JobIdModal;
