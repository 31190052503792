import React from 'react';

const PaymentsDashboard = () => {
  return (
    <>

    </>
  )
}

export default PaymentsDashboard