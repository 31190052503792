import React, { useEffect } from 'react';

import { useDispatch, useSelector } from "react-redux";
import './itresource.scss'
import BasicTable from './BasicTable';
import { list } from '../../../../slice/resources/ItResourcesSlice'
import { showError } from '../../../../slice/utils/message/messageSlice';

const ITResource = () => {
    const dispatch = useDispatch();
    const { dataList } = useSelector(state => state.itResource)

    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(list({}))
            } catch (err) {
                dispatch(showError({ msg: err }))
            }
        }
        fetchData()
    }, [dispatch])

    return (
        <>

            <div className="container-fluid dk-itresPageCon">
                {/* table Start Component*/}
                {
                    (dataList && dataList.length > -1) ? <BasicTable /> : null
                }
            </div>
        </>
    )
}

export default ITResource
