import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import { logoutUser, getCandetails } from "../../../../slice/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { FaCaretDown } from "react-icons/fa";
import "./style.scss";
// import MyProfileModal from '../modal/myprofile/MyProfileModal';
//import { FaUserCircle } from "react-icons/fa";
//import MyProfileModal from '../navbar/myaccount/elements/modal/MyProfile'
//import CandidateMyProfile from '../navbar/myaccount/elements/modal/CandidateMyProfile';
import CommonComp from './NewCommonComp';

const JobseekerNav = () => {
    const dispatch = useDispatch();

    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    //userInfo  --give who is login
    const { userInfo, isLoading, candidateUserDetails } = useSelector(state => state.auth);


    const logout = async () => {
        dispatch(logoutUser({}))
    }
    return (
        <React.Fragment>
            <Dropdown className="dk-accountDropdown headerUniqueForSearch">
                <Dropdown.Toggle id="dropdown-basic">
                    <span>
                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + userInfo.profileImage} className="img-fluid img-circle" alt="" />
                    </span>
                    <div className="title">
                        {/* {userInfo.firstName} */}
                        <FaCaretDown />
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {/* {
                        (segment1 == 'dashboard') ? <Dropdown.Item href="/dashboard"><i className="fas fa-home"></i> Dashboard</Dropdown.Item> : null
                    } */}
                     <Dropdown.Item href="/dashboard/plateform"><i className="fas fa-home"></i> Dashboard</Dropdown.Item>
                    {/* {
                        (segment1 == 'js-account') ? <CommonComp /> : null
                    } */}
                    {/* {
                        (segment1 == 'my-account') ? <MyProfileModal userInfo={userInfo} /> : null
                    } */}

                    {/* <MyProfileModal /> */}
                    {/* <Dropdown.Item href="/js-account/dashboard"><i className="fas fa-user-circle"></i>My Account</Dropdown.Item> */}
                    {/* {
                        (segment1 !== 'rc-account') ? <Dropdown.Item href="#/rc-account/dashboard"><i className="fas fa-user-circle"></i> RC Account</Dropdown.Item> : null
                    } */}
                    <Dropdown.Item href="#" onClick={logout}><i className="fas fa-power-off"></i> Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    )
}
export default JobseekerNav;