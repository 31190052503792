import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData } from "../../services";

export const getCompanyFunction = createAsyncThunk(
    "clientsCmp/getcompanylist",
    async (thunkAPI) => {
        const res = await GetData(true, "/requirnments-category");
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getAlbumCover = createAsyncThunk(
    "clientsCmp/getAlbumcompanylist",
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, "/requirnments-category/coverimg/" + id);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


const usersSlice = createSlice({
    name: "clientsCmmp",
    initialState: {
        companylist: [],
        coverAlbumList: [],
        isSuccess: false,
        isLoading: false,
        isError: false,

    },
    reducers: {
        clearState: (state) => {
            state.isSubReoder = false;
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
        },
    },
    extraReducers: {


        [getCompanyFunction.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getCompanyFunction.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            //state.isSuccess = true;
            state.companylist = payload.result;
        },
        [getCompanyFunction.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            //state.isSuccess = false;
        },

        [getAlbumCover.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getAlbumCover.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.coverAlbumList = payload.result;
        },
        [getAlbumCover.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
        },

    },
});
export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
