import React from 'react';
import ResumeLeftPanel from './leftpanel/ResumeLeftPanel';
import './moduletraining.scss';

const Resume = () => {
    return (
        <>
            <div className="dk-moduleTraining-main">
                <div className="dk-moduleTraining-cols">
                    <ResumeLeftPanel />
                    <div className="dk-moduleTraining-rightPanel">
                        <div className="title">Overview</div>
                        <div className="dk-moduleTraining-rightCols">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Resume;
