import React, { useState, useEffect, useRef, } from 'react';
import { Link } from 'react-router-dom'
import './cashflowdashboard.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from "react-redux";
import { listTable, cashoutListTable } from "../../../../../slice/accounting/cashflow";
const CashFlowDashboard = () => {
    const dispatch = useDispatch();
    const { cashflowList, cashoutHeadingList, isLoading } = useSelector(state => state.cashflowdata);
    const [listcashflow, setListcashflow] = useState([]);
    const [listCashOutHeading, setListCashOutHeading] = useState([]);
    const [openingBalance, setOpeningBalance] = useState([]);
    const [subheading, setSubHeading] = useState([]);
    const [year, setYear] = useState('2023');

    const retrieveData = async () => {
        await dispatch(listTable({ year: year }))
        await dispatch(cashoutListTable({ year: year }))
    }
    useEffect(retrieveData, [year]);

    useEffect(() => {

        if (!isLoading && cashflowList) {
            setListcashflow(cashflowList)
        }
        if (!isLoading && cashoutHeadingList) {

            setListCashOutHeading(cashoutHeadingList.filter(a => a.pid == 0))
            setOpeningBalance(cashoutHeadingList.filter(a => a.pid == -1))
            setSubHeading(cashoutHeadingList?.filter(a => a.pid != 0 && a.pid != -1))
        }
    }, [isLoading,year])

    return (
        <>
            <div className="dkg-cashFlow-dashboardMain container-fluid">
                <div className='row dkg-cashFlow-dashboardRow'>
                    <div className='col-12 dkg-cashFlow-dashboardCol'>
                        <div className='dkg-cashFlow-dashboard-topHead'>
                            <div className='dkg-cashFlow-LeftCon'>
                                <div className="dk-kpiCase-yearsDropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                          {year}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={()=>setYear('2023')}>2023</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>setYear('2022')}>2022</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>setYear('2021')}>2021</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>setYear('2020')}>2020</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>setYear('2019')}>2019</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>setYear('2018')}>2018</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>setYear('2017')}>2017</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>setYear('2016')}>2016</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className='dkg-cashFlow-RightCon'>
                                <div className='dkg-cashflow-block mr-2'>
                                    <div className='dkg-title'>Income</div>
                                    <div className='dkg-blockCon'>
                                        <div style={{ color: "#3a9469" }}>{
                                            (listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) + listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) + listcashflow.reduce((a, v) => a = a + Number(v.march), 0) + listcashflow.reduce((a, v) => a = a + Number(v.april), 0) + listcashflow.reduce((a, v) => a = a + Number(v.may), 0) + listcashflow.reduce((a, v) => a = a + Number(v.june), 0) + listcashflow.reduce((a, v) => a = a + Number(v.july), 0) + listcashflow.reduce((a, v) => a = a + Number(v.aug), 0) + listcashflow.reduce((a, v) => a = a + Number(v.sep), 0) + listcashflow.reduce((a, v) => a = a + Number(v.oct), 0) + listcashflow.reduce((a, v) => a = a + Number(v.nov), 0) + listcashflow.reduce((a, v) => a = a + Number(v.dec), 0)).toFixed(2)
                                        }€</div>
                                    </div>
                                </div>
                                <div className='dkg-cashflow-block mr-2'>
                                    <div className='dkg-title'>Expense</div>
                                    <div className='dkg-blockCon'>
                                        <div style={{ color: "#d91010" }}>
                                            {
                                                (cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.sep), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.oct), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.nov), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.dec), 0)).toFixed(2)
                                            }€
                                        </div>
                                    </div>
                                </div>
                                <div className='dkg-cashflow-block mr-2'>
                                    <div className='dkg-title'>Profit & Loss</div>
                                    <div className='dkg-blockCon'>
                                        <div style={{ color: "#26597d" }}>
                                            {
                                                (((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) + listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) + listcashflow.reduce((a, v) => a = a + Number(v.march), 0) + listcashflow.reduce((a, v) => a = a + Number(v.april), 0) + listcashflow.reduce((a, v) => a = a + Number(v.may), 0) + listcashflow.reduce((a, v) => a = a + Number(v.june), 0) + listcashflow.reduce((a, v) => a = a + Number(v.july), 0) + listcashflow.reduce((a, v) => a = a + Number(v.aug), 0) + listcashflow.reduce((a, v) => a = a + Number(v.sep), 0) + listcashflow.reduce((a, v) => a = a + Number(v.oct), 0) + listcashflow.reduce((a, v) => a = a + Number(v.nov), 0) + listcashflow.reduce((a, v) => a = a + Number(v.dec), 0))) - ((cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.sep), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.oct), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.nov), 0) + cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.dec), 0)))).toFixed(2)
                                            }
                                            €</div>
                                    </div>
                                </div>
                                <div className='dkg-cashflow-block'>
                                    <div className='dkg-title'>Closing Balance</div>
                                    <div className='dkg-blockCon'>
                                        <div style={{ color: "#26597d" }}>
                                        {
                                                Math.sign((((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.july), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0))) + (listcashflow.reduce((a, v) => a = a + Number(v.aug), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.sep), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.sep), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.oct), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.oct), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.nov), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.nov), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.dec), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.dec), 0))).toFixed(2)) > 0 ? 
                                                <span className='positiveValues'>
                                                    {
                                                        (((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.july), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0))) + (listcashflow.reduce((a, v) => a = a + Number(v.aug), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.sep), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.sep), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.oct), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.oct), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.nov), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.nov), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.dec), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.dec), 0))).toFixed(2)
                                                    }
                                                    <span className="ml-1">€</span>
                                                </span>
                                                    :
                                                    <span className='negativeValues'>
                                                        {
                                                            (((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.july), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0))) + (listcashflow.reduce((a, v) => a = a + Number(v.aug), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.sep), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.sep), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.oct), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.oct), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.nov), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.nov), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.dec), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.dec), 0))).toFixed(2)
                                                        }
                                                        <span className="ml-1">€</span>
                                                    </span>
                                            }

                                            </div>
                                    </div>
                                </div>
                            </div>
                            {/* <ul>
                                <li>
                                    <Link to="#">2023 </Link>
                                </li>
                                <li>
                                    <Link to="#" className='active'>2022 </Link>
                                </li>
                                <li>
                                    <Link to="#">2021 </Link>
                                </li>
                                <li>
                                    <Link to="#">2020 </Link>
                                </li>
                                <li>
                                    <Link to="#">2019 </Link>
                                </li>
                                <li>
                                    <Link to="#">2018 </Link>
                                </li>
                                <li>
                                    <Link to="#">2017 </Link>
                                </li>
                                <li>
                                    <Link to="#">2016 </Link>
                                </li>
                            </ul> */}
                        </div>
                        <div className="dk-cashFlow-dashboard-yearMain">
                            <div className="d-flex w-100">
                                <div className="dk-leftBoxes-main">
                                    <div className="dk-leftBoxes-panel">

                                        <div className="dk-leftBoxes-cols ml-0">
                                            <div className="title">January</div>
                                            <div className="dkg-cashflow-suMM-panel">
                                                <ul>
                                                    <li>
                                                        <span className='leftItem dkg-total-orange-color'>Income</span>
                                                        <span className='rightItem dkg-total-orange-counter'>
                                                            {
                                                                listcashflow.reduce((a, v) => a = a + Number(v.jan), 0).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-green-color'>Expense</span>
                                                        <span className='rightItem dkg-total-green-counter'>
                                                            {
                                                                (cashoutHeadingList?.filter(a => a.pid != -1 && a.pid != null).reduce((a, v) => a = a + Number(v.jan), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-blue-color'>Monthly Balance</span>
                                                        <span className='rightItem dkg-total-blue-counter'>
                                                            {
                                                                (listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <table className="table table-bordered dkg-cashflow-dashboard-table-89">
                                                    <tbody>
                                                        <tr>
                                                            <td>Opening Balance</td>
                                                            {
                                                                openingBalance.map((openingData, openingIndex) =>
                                                                    <td>
                                                                        {
                                                                            openingData.jan == "" ? openingData.jan : Number(openingData.jan).toFixed(2)
                                                                        }
                                                                    </td>
                                                                )}
                                                        </tr>
                                                        <tr>
                                                            <td>Closing Balance</td>
                                                            <td>
                                                                {
                                                                    (listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="dk-leftBoxes-cols">
                                            <div className="title">February</div>
                                            <div className="dkg-cashflow-suMM-panel">
                                                <ul>
                                                    <li>
                                                        <span className='leftItem dkg-total-orange-color'>Income</span>
                                                        <span className='rightItem dkg-total-orange-counter'>
                                                            {
                                                                listcashflow.reduce((a, v) => a = a + Number(v.feb), 0).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-green-color'>Expense</span>
                                                        <span className='rightItem dkg-total-green-counter'>
                                                            {
                                                                (cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-blue-color'>Monthly Balance</span>
                                                        <span className='rightItem dkg-total-blue-counter'>
                                                            {
                                                                (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <table className="table table-bordered dkg-cashflow-dashboard-table-89">
                                                    <tbody>
                                                        <tr>
                                                            <td>Opening Balance</td>
                                                            <td>
                                                                {
                                                                    (listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Closing Balance</td>
                                                            <td>
                                                                {
                                                                    ((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="dk-leftBoxes-cols">
                                            <div className="title">March</div>
                                            <div className="dkg-cashflow-suMM-panel">
                                                <ul>
                                                    <li>
                                                        <span className='leftItem dkg-total-orange-color'>Income</span>
                                                        <span className='rightItem dkg-total-orange-counter'>
                                                            {
                                                                listcashflow.reduce((a, v) => a = a + Number(v.march), 0).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-green-color'>Expense</span>
                                                        <span className='rightItem dkg-total-green-counter'>
                                                            {
                                                                (cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-blue-color'>Monthly Balance</span>
                                                        <span className='rightItem dkg-total-blue-counter'>
                                                            {
                                                                (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <table className="table table-bordered dkg-cashflow-dashboard-table-89">
                                                    <tbody>
                                                        <tr>
                                                            <td>Opening Balance</td>
                                                            <td>
                                                                {
                                                                    ((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Closing Balance</td>
                                                            <td>
                                                                {
                                                                    ((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="dk-leftBoxes-cols">
                                            <div className="title">April</div>
                                            <div className="dkg-cashflow-suMM-panel">
                                                <ul>
                                                    <li>
                                                        <span className='leftItem dkg-total-orange-color'>Income</span>
                                                        <span className='rightItem dkg-total-orange-counter'>
                                                            {
                                                                listcashflow.reduce((a, v) => a = a + Number(v.april), 0).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-green-color'>Expense</span>
                                                        <span className='rightItem dkg-total-green-counter'>
                                                            {
                                                                (cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-blue-color'>Monthly Balance</span>
                                                        <span className='rightItem dkg-total-blue-counter'>
                                                            {
                                                                (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <table className="table table-bordered dkg-cashflow-dashboard-table-89">
                                                    <tbody>
                                                        <tr>
                                                            <td>Opening Balance</td>
                                                            <td>
                                                                {
                                                                    ((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Closing Balance</td>
                                                            <td>
                                                                {
                                                                    ((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="dk-leftBoxes-cols">
                                            <div className="title">May</div>
                                            <div className="dkg-cashflow-suMM-panel">
                                                <ul>
                                                    <li>
                                                        <span className='leftItem dkg-total-orange-color'>Income</span>
                                                        <span className='rightItem dkg-total-orange-counter'>
                                                            {
                                                                listcashflow.reduce((a, v) => a = a + Number(v.may), 0).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-green-color'>Expense</span>
                                                        <span className='rightItem dkg-total-green-counter'>
                                                            {
                                                                (cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-blue-color'>Monthly Balance</span>
                                                        <span className='rightItem dkg-total-blue-counter'>
                                                            {
                                                                (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <table className="table table-bordered dkg-cashflow-dashboard-table-89">
                                                    <tbody>
                                                        <tr>
                                                            <td>Opening Balance</td>
                                                            <td>
                                                                {
                                                                    ((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Closing Balance</td>
                                                            <td>
                                                                {
                                                                    ((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="dk-leftBoxes-cols mr-0">
                                            <div className="title">June</div>
                                            <div className="dkg-cashflow-suMM-panel">
                                                <ul>
                                                    <li>
                                                        <span className='leftItem dkg-total-orange-color'>Income</span>
                                                        <span className='rightItem dkg-total-orange-counter'>
                                                            {
                                                                listcashflow.reduce((a, v) => a = a + Number(v.june), 0).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-green-color'>Expense</span>
                                                        <span className='rightItem dkg-total-green-counter'>
                                                            {
                                                                (cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-blue-color'>Monthly Balance</span>
                                                        <span className='rightItem dkg-total-blue-counter'>
                                                            {
                                                                (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <table className="table table-bordered dkg-cashflow-dashboard-table-89">
                                                    <tbody>
                                                        <tr>
                                                            <td>Opening Balance</td>
                                                            <td>
                                                                {
                                                                    ((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Closing Balance</td>
                                                            <td>
                                                                {
                                                                    ((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dk-leftBoxes-panel">
                                        <div className="dk-leftBoxes-cols ml-0">
                                            <div className="title">July</div>
                                            <div className="dkg-cashflow-suMM-panel">
                                                <ul>
                                                    <li>
                                                        <span className='leftItem dkg-total-orange-color'>Income</span>
                                                        <span className='rightItem dkg-total-orange-counter'>
                                                            {
                                                                listcashflow.reduce((a, v) => a = a + Number(v.july), 0).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-green-color'>Expense</span>
                                                        <span className='rightItem dkg-total-green-counter'>
                                                            {
                                                                (cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-blue-color'>Monthly Balance</span>
                                                        <span className='rightItem dkg-total-blue-counter'>
                                                            {
                                                                (listcashflow.reduce((a, v) => a = a + Number(v.july), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <table className="table table-bordered dkg-cashflow-dashboard-table-89">
                                                    <tbody>
                                                        <tr>
                                                            <td>Opening Balance</td>
                                                            <td>
                                                                {
                                                                    ((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Closing Balance</td>
                                                            <td>
                                                                {
                                                                    ((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.july), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="dk-leftBoxes-cols">
                                            <div className="title">August</div>
                                            <div className="dkg-cashflow-suMM-panel">
                                                <ul>
                                                    <li>
                                                        <span className='leftItem dkg-total-orange-color'>Income</span>
                                                        <span className='rightItem dkg-total-orange-counter'>
                                                            {
                                                                listcashflow.reduce((a, v) => a = a + Number(v.aug), 0).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-green-color'>Expense</span>
                                                        <span className='rightItem dkg-total-green-counter'>
                                                            {
                                                                (cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-blue-color'>Monthly Balance</span>
                                                        <span className='rightItem dkg-total-blue-counter'>
                                                            {
                                                                (listcashflow.reduce((a, v) => a = a + Number(v.aug), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <table className="table table-bordered dkg-cashflow-dashboard-table-89">
                                                    <tbody>
                                                        <tr>
                                                            <td>Opening Balance</td>
                                                            <td>
                                                                {
                                                                    ((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.july), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Closing Balance</td>
                                                            <td>
                                                                {
                                                                    (((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.july), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0))) + (listcashflow.reduce((a, v) => a = a + Number(v.aug), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="dk-leftBoxes-cols">
                                            <div className="title">September</div>
                                            <div className="dkg-cashflow-suMM-panel">
                                                <ul>
                                                    <li>
                                                        <span className='leftItem dkg-total-orange-color'>Income</span>
                                                        <span className='rightItem dkg-total-orange-counter'>
                                                            {
                                                                listcashflow.reduce((a, v) => a = a + Number(v.sep), 0).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-green-color'>Expense</span>
                                                        <span className='rightItem dkg-total-green-counter'>
                                                            {
                                                                (cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.sep), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-blue-color'>Monthly Balance</span>
                                                        <span className='rightItem dkg-total-blue-counter'>
                                                            {
                                                                (listcashflow.reduce((a, v) => a = a + Number(v.sep), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.sep), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <table className="table table-bordered dkg-cashflow-dashboard-table-89">
                                                    <tbody>
                                                        <tr>
                                                            <td>Opening Balance</td>
                                                            <td>
                                                                {
                                                                    (((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.july), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0))) + (listcashflow.reduce((a, v) => a = a + Number(v.aug), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Closing Balance</td>
                                                            <td>
                                                                {
                                                                    (((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.july), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0))) + (listcashflow.reduce((a, v) => a = a + Number(v.aug), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.sep), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.sep), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="dk-leftBoxes-cols">
                                            <div className="title">October</div>
                                            <div className="dkg-cashflow-suMM-panel">
                                                <ul>
                                                    <li>
                                                        <span className='leftItem dkg-total-orange-color'>Income</span>
                                                        <span className='rightItem dkg-total-orange-counter'>
                                                            {
                                                                listcashflow.reduce((a, v) => a = a + Number(v.oct), 0).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-green-color'>Expense</span>
                                                        <span className='rightItem dkg-total-green-counter'>
                                                            {
                                                                (cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.oct), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-blue-color'>Monthly Balance</span>
                                                        <span className='rightItem dkg-total-blue-counter'>
                                                            {
                                                                (listcashflow.reduce((a, v) => a = a + Number(v.oct), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.oct), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <table className="table table-bordered dkg-cashflow-dashboard-table-89">
                                                    <tbody>
                                                        <tr>
                                                            <td>Opening Balance</td>
                                                            <td>
                                                                {
                                                                    (((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.july), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0))) + (listcashflow.reduce((a, v) => a = a + Number(v.aug), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.sep), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.sep), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Closing Balance</td>
                                                            <td>
                                                                {
                                                                    (((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.july), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0))) + (listcashflow.reduce((a, v) => a = a + Number(v.aug), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.sep), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.sep), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.oct), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.oct), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="dk-leftBoxes-cols">
                                            <div className="title">November</div>
                                            <div className="dkg-cashflow-suMM-panel">
                                                <ul>
                                                    <li>
                                                        <span className='leftItem dkg-total-orange-color'>Income</span>
                                                        <span className='rightItem dkg-total-orange-counter'>
                                                            {
                                                                listcashflow.reduce((a, v) => a = a + Number(v.nov), 0).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-green-color'>Expense</span>
                                                        <span className='rightItem dkg-total-green-counter'>
                                                            {
                                                                (cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.nov), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-blue-color'>Monthly Balance</span>
                                                        <span className='rightItem dkg-total-blue-counter'>
                                                            {
                                                                (listcashflow.reduce((a, v) => a = a + Number(v.nov), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.nov), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <table className="table table-bordered dkg-cashflow-dashboard-table-89">
                                                    <tbody>
                                                        <tr>
                                                            <td>Opening Balance</td>
                                                            <td>
                                                                {
                                                                    (((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.july), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0))) + (listcashflow.reduce((a, v) => a = a + Number(v.aug), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.sep), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.sep), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.oct), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.oct), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Closing Balance</td>
                                                            <td>
                                                                {
                                                                    (((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.july), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0))) + (listcashflow.reduce((a, v) => a = a + Number(v.aug), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.sep), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.sep), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.oct), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.oct), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.nov), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.nov), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="dk-leftBoxes-cols mr-0">
                                            <div className="title">December</div>
                                            <div className="dkg-cashflow-suMM-panel">
                                                <ul>
                                                    <li>
                                                        <span className='leftItem dkg-total-orange-color'>Income</span>
                                                        <span className='rightItem dkg-total-orange-counter'>
                                                            {
                                                                listcashflow.reduce((a, v) => a = a + Number(v.dec), 0).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-green-color'>Expense</span>
                                                        <span className='rightItem dkg-total-green-counter'>
                                                            {
                                                                cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.dec), 0).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className='leftItem dkg-total-blue-color'>Monthly Balance</span>
                                                        <span className='rightItem dkg-total-blue-counter'>
                                                            {
                                                                (listcashflow.reduce((a, v) => a = a + Number(v.dev), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.dev), 0)).toFixed(2)
                                                            }
                                                            <span className='ml-1'>€</span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <table className="table table-bordered dkg-cashflow-dashboard-table-89">
                                                    <tbody>
                                                        <tr>
                                                            <td>Opening Balance</td>
                                                            <td>
                                                                {
                                                                    (((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.july), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0))) + (listcashflow.reduce((a, v) => a = a + Number(v.aug), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.sep), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.sep), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.oct), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.oct), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.nov), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.nov), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Closing Balance</td>
                                                            <td>
                                                                {
                                                                    (((listcashflow.reduce((a, v) => a = a + Number(v.jan), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + openingBalance.reduce((a, v) => a = a + Number(v.jan), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.feb), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.march), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.april), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.may), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.june), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.july), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0))) + (listcashflow.reduce((a, v) => a = a + Number(v.aug), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.sep), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.sep), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.oct), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.oct), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.nov), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.nov), 0)) + (listcashflow.reduce((a, v) => a = a + Number(v.dec), 0) - cashoutHeadingList?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.dec), 0))).toFixed(2)
                                                                }
                                                                <span className='ml-1'>€</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="dk-rightBoxes-main">
                                    <div className="dk-leftBoxes-panel">
                                        <div className="dk-leftBoxes-cols">
                                            <div className="title">Language</div>
                                            <div className="dkg-cashflow-suMM-panel"></div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CashFlowDashboard;