import React, { useEffect, useState, useMemo, lazy, Suspense } from 'react';
import './addnewrow.scss';
import { Modal, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { SYSTEM_CONSTANTS } from "../../../../constants";
import { updateRowById, clearState } from "../../../../slice/kpis/qualifiedKpisReportSlice";
import { monthsDataFetch, progressDataFetch, stageDataFetch, companyDataFetch, projectDataFetch, roleTypeDataFetch, languageDataFetch, cvSourceDataFetch } from "../../../../slice/kpis/assignKpisReportSlice";
import moment from "moment-timezone";
import UserList from "../../../ui/userList";
import Submit from '../../../ui/submitButton';
//import CustomDateFormat from "../../../ui/dateFormats/CustomDateFormat";
import { showError} from "../../../utils/messages/messageSlice";
//import { FaCalendarAlt } from "react-icons/fa";
import SelectDropsDown from "../utils/searchDropdown/";
import DatePicker from './DatePicker';

const EditQualifiedModal = ({ dataRowId, onHide }) => {
    const dispatch = useDispatch();

    const {  detailsList ,isUpdate } = useSelector(state => state.qualifiedKpiReport)

    const { monthsList,  progressList, stageList, companyList, projectList, roleTypeList, languageList, cvSourceList } = useSelector(state => state.assignKpiReport)

    const [category, setCategory] = useState('')
    const [id, setId] = useState(detailsList.id)
    const [caseId, setCaseId] = useState(detailsList.caseId)
    const [sendoutOn, setSendoutOn] = useState(detailsList.sendoutOn)
    const [months, setMonths] = useState(detailsList.months)
    const [candidateName, setCandidateName] = useState(detailsList.candidateName)
    const [status, setStatus] = useState(detailsList.status)

    const [progress, setProgress] = useState(detailsList.progress)
    const [stage, setStage] = useState(detailsList.stage)

    const [company, setCompany] = useState(detailsList.company)
    const [project, setProject] = useState(detailsList.project)
    const [roleType, setRoleType] = useState(detailsList.roleType)
    const [language, setLanguage] = useState(detailsList.language)
    const [cvSource, setCvSource] = useState(detailsList.cvSource)
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    // const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('DD MMM YYYY'))
    const [selectedDate, setSelectedDate] = useState(detailsList.sendoutOn)

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(monthsDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.monthsId }))
                await dispatch(progressDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_QUALIFIED.progressId }))
                await dispatch(stageDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_QUALIFIED.stageId }))
                await dispatch(companyDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.comapnyId }))
                await dispatch(projectDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.projectId }))
                await dispatch(roleTypeDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.roleType }))
                await dispatch(languageDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.languageId }))
                await dispatch(cvSourceDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.cvSourceId }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
        if (isUpdate) {
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }
    }, [isUpdate])

    const handleDateChange = (date) => {
        setSelectedDate(moment(date).format('DD MMM YY'));
    };
    const [RCuserId, setRCUserId] = useState(detailsList.recInfo.id)
    const [RCuserImg, setRCUserImg] = useState(process.env.REACT_APP_IMG_CLOUD_BASE_URL + detailsList.recInfo.profileImage)
    const [RCuserImgName, setRCUserImgName] = useState('')

    const selectRCUser = (e) => {
        setRCUserImgName(e.target.alt)
        setRCUserImg(e.target.src)
        setRCUserId(e.target.dataset.id)
    }

    const [AMuserId, setAMUserId] = useState(detailsList.amInfo.id)
    const [AMuserImg, setAMUserImg] = useState(process.env.REACT_APP_IMG_CLOUD_BASE_URL + detailsList.amInfo.profileImage)
    const [AMuserImgName, setAMUserImgName] = useState('')

    const selectUser = (e) => {
        setAMUserImgName(e.target.alt)
        setAMUserImg(e.target.src)
        setAMUserId(e.target.dataset.id)
    }

    const handleButtonClick = async (e) => {
        e.preventDefault()

        if (!caseId) {
            dispatch(showError({ msg: 'Please fill all fields.' }))
        } else {
            if (!loading) {
                setSuccess(false);
                setLoading(true);
                await dispatch(updateRowById({ id, sendoutOn: selectedDate, months, am: AMuserId, rc: RCuserId, candidateName, progress, stage, company, project, roleType, language, cvSource }))
            }
        }
    }
    const updateProfile = async (e) => {
        //const id = e.target.dataset.id;
        const key = e.target.dataset.key;
        const value = e.target.dataset.value;
      
        if (key === 'roleType') {
            setRoleType(value)           
        }
        if (key === 'project') {
            setProject(value)           
        }
        if (key === 'company') {
            setCompany(value)           
        }
        if (key === 'language') {
            setLanguage(value)           
        }
        if (key === 'cvSource') {
            setCvSource(value)           
        }
    }

    return (
        <>
            <Modal className="dk-addNewRowModal-main right" centered show={true} onHide={onHide}>
                <Modal.Header closeButton style={{ background: '#3c97b6' }}>
                    <Modal.Title>EDIT</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-addNewInvoiceModal-body">
                        <div className='row'>
                            <div className='col-md-12 col-12'>
                                <div className='form-group'>
                                    <label className='dk-addInvoice-title'>Case ID</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control dkg-sendout-readonly-input" value={caseId} readOnly />
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-12 col-12'>
                                <div className='form-group'>
                                    <label className='dk-addInvoice-title'>QUALIFIED ON</label>
                                    <label htmlFor="">
                                        <div className='dkg-addnew-date-timeCon'>
                                            <div className='dkg-customdateformatCon w-100'>
                                                <div className='dkg-customdateformat'>
                                                    {/* <CustomDateFormat date={detailsList.createdAt} format='DD MMM YY' /> */}
                                                <DatePicker selectedDate={selectedDate} handleDateChange={handleDateChange} />
                                                </div>
                                                {/* <div className='dkg-calendorIcon'>
                                                    <FaCalendarAlt />
                                                </div> */}
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-12 col-12'>
                                <div className='form-group'>
                                    <label className='dk-addInvoice-title'>Months</label>
                                    <label htmlFor="">
                                        <div className='dk-dropdown'>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" className="form-control">
                                                    {months}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu >
                                                    {
                                                        monthsList.map((item, index) =>
                                                            <Dropdown.Item key={index} className="form-control" onClick={() => setMonths(item.value)}>{item.value}</Dropdown.Item>
                                                        )
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-12 col-12'>
                                <div className='form-group'>
                                    <label className='dk-addInvoice-title'>AM & RC</label>
                                    <label htmlFor="" className="dk-amrcDropdown">
                                        <div className="d-flex align-items-center">
                                            <div className="title">AM</div>

                                            <input type="hidden" id="AMuserId" value="0" />
                                            <Dropdown className="dk-globalUserDropdown">
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    {
                                                        (AMuserId !== '0') ? <img src={AMuserImg} className="select-user-img" /> : <i className="fas fa-user"></i>
                                                    }
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='dk-adstracker-DrpMenu12'>
                                                    <div className="d-flex">
                                                        <UserList onClick={selectUser} />
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="title">RC</div>
                                            <input type="hidden" id="RCuserId" value="0" />
                                            <Dropdown className="dk-globalUserDropdown">
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    {
                                                        (RCuserId !== '0') ? <img src={RCuserImg} className="select-user-img" /> : <i className="fas fa-user"></i>
                                                    }
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='dk-adstracker-DrpMenu12'>
                                                    <div className="d-flex">
                                                        <UserList onClick={selectRCUser} />
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-12 col-12'>
                                <div className='form-group'>
                                    <label className='dk-addInvoice-title'>Candidate Name</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control" value={candidateName} onChange={(e) => setCandidateName(e.target.value)} />
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-12 col-12'>
                                <div className='form-group'>
                                    <label className='dk-addInvoice-title'>Status</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control dkg-sendout-readonly-input" value="Qualified" readOnly />
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-12 col-12'>
                                <div className='form-group'>
                                    <label className='dk-addInvoice-title'>Progress</label>
                                    <label className=''>
                                        <div className='dk-dropdown'>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" className="form-control">
                                                    {progress}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu >
                                                    {
                                                        progressList.map((item, index) =>
                                                            <Dropdown.Item key={index} className="form-control" onClick={() => setProgress(item.value)}>{item.value}</Dropdown.Item>
                                                        )
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-12 col-12'>
                                <div className='form-group'>
                                    <label className='dk-addInvoice-title'>Stage</label>
                                    <label className=''>
                                        <div className='dk-dropdown'>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" className="form-control">
                                                    {stage}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu >
                                                    {
                                                        stageList.map((item, index) =>
                                                            <Dropdown.Item key={index} className="form-control" onClick={() => setStage(item.value)}>{item.value}</Dropdown.Item>
                                                        )
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-12 col-12'>
                                <div className='form-group'>
                                    <label className='dk-addInvoice-title'>Company Name</label>
                                    <label htmlFor="">
                                        <div className='dk-dropdown'>
                                        <SelectDropsDown txt={company} list={companyList}  updateProfile={updateProfile} column="company" className={(category == '' ? "errorBorder" : null)} />
                                            {/* <Dropdown>
                                                <Dropdown.Toggle variant="" className="form-control">
                                                    {company}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu >
                                                    {
                                                        companyList.map((item, index) =>
                                                            <Dropdown.Item key={index} className="form-control" onClick={() => setCompany(item.value)}>{item.value}</Dropdown.Item>
                                                        )
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown> */}

                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-12 col-12'>
                                <div className='form-group'>
                                    <label className='dk-addInvoice-title'>Project</label>
                                    <label className=''>
                                        <div className='dk-dropdown'>
                                        <SelectDropsDown txt={project} list={projectList}  updateProfile={updateProfile} column="project" className={(category == '' ? "errorBorder" : null)} />
                                            {/* <Dropdown>
                                                <Dropdown.Toggle variant="" className="form-control">
                                                    {project}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu >
                                                    {
                                                        projectList.map((item, index) =>
                                                            <Dropdown.Item key={index} className="form-control" onClick={() => setProject(item.value)}>{item.value}</Dropdown.Item>
                                                        )
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-12 col-12'>
                                <div className='form-group'>
                                    <label className='dk-addInvoice-title'>Role Type</label>
                                    <label className=''>
                                        <div className='dk-dropdown'>
                                        <SelectDropsDown txt={roleType} list={roleTypeList}  updateProfile={updateProfile} column="roleType" className={(category == '' ? "errorBorder" : null)} />

                                            {/* <Dropdown>
                                                <Dropdown.Toggle variant="" className="form-control">
                                                    {roleType}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu >
                                                    {
                                                        roleTypeList.map((item, index) =>
                                                            <Dropdown.Item key={index} className="form-control" onClick={() => setRoleType(item.value)}>{item.value}</Dropdown.Item>
                                                        )
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-12 col-12'>
                                <div className='form-group'>
                                    <label className='dk-addInvoice-title'>Language</label>
                                    <label className=''>
                                        <div className='dk-dropdown'>
                                        <SelectDropsDown txt={language} list={languageList}  updateProfile={updateProfile} column="language" className={(category == '' ? "errorBorder" : null)} />
                                            {/* <Dropdown>
                                                <Dropdown.Toggle variant="" className="form-control">
                                                    {language}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu >
                                                    {
                                                        languageList.map((item, index) =>
                                                            <Dropdown.Item key={index} className="form-control" onClick={() => setLanguage(item.value)}>{item.value}</Dropdown.Item>
                                                        )
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-12 col-12'>
                                <div className='form-group'>
                                    <label className='dk-addInvoice-title'>CV Source</label>
                                    <label className=''>
                                        <div className='dk-dropdown'>
                                        <SelectDropsDown txt={cvSource} list={cvSourceList}  updateProfile={updateProfile} column="cvSource" className={(category == '' ? "errorBorder" : null)} />
                                            {/* <Dropdown>
                                                <Dropdown.Toggle variant="" className="form-control">
                                                    {cvSource}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu >
                                                    {
                                                        cvSourceList.map((item, index) =>
                                                            <Dropdown.Item key={index} className="form-control" onClick={() => setCvSource(item.value)}>{item.value}</Dropdown.Item>
                                                        )
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='col-md-12 col-12'>
                                <div className='form-group'>
                                    <div className='dkg-kpireport-savebtnCon'>
                                        <Submit txt="SAVE" loading={loading} success={success} onClick={handleButtonClick} className="dkaddBtn" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditQualifiedModal;
