import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import './createtaskidmodal.scss'

const IdModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>


        </>
    )
}

export default IdModal