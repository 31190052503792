import React from "react"
import Dropdown from 'react-bootstrap/Dropdown';
//import { useSelector } from "react-redux";
const JobTableStatus = ({ id, columnId, onItemClick }) => {

    const status = [
        {
            key: 'normal', value: 'Active', text: 'Active', Bgcolor: '#30887e',
        },
        {
            key: 'qualify', value: 'Qualify', text: 'Qualify', Bgcolor: '#d58b5d',
        },
        {
            key: 'on hold', value: 'On Hold', text: 'On Hold', Bgcolor: '#8b572a',
        },
        {
            key: 'urgent', value: 'Inactive', text: 'Inactive', Bgcolor: '#b55355',
        },
        {
            key: 'closed', value: 'Closed', text: 'Closed', Bgcolor: '#da3d5e',
        }
    ]

    const selectValue = (value, color) => {
        onItemClick(id, columnId, value, color);
    }
    return (
        <React.Fragment>
            {
                status.map((statusItem, index) =>
                    <Dropdown.Item key={`case-status-id${index}`} href="#" style={{ color: "#fff", backgroundColor: statusItem.Bgcolor, }} onClick={() => selectValue(statusItem.value, statusItem.Bgcolor)}>
                        {statusItem.value}
                    </Dropdown.Item>
                )
            }
        </React.Fragment>
    )
}
export default JobTableStatus;