import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputGroup, FormControl } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import SimpleDropDwn from "../summary/SimpleDropDwn";
import UserSearchFilter from "../summary/SearchInputDropDwn";
import AirDatepicker from "air-datepicker";
import en from "air-datepicker/locale/en";
import "air-datepicker/air-datepicker.css";
import UserList from "../../../../../ui/userList/userListByRole";

import {
  clearState,
  updateJob,
  //updateJobWithTextBox,
} from "../../../../../../slice/jobs/jobsSlice";

const Sendouts = (jobId) => {
  const dispatch = useDispatch();
  const {
    // jobsCategoryList,
    // jobsCompanyList,
    // jobsProjectList,
    // jobsRoleTypeList,
    jobsLevelIdList,
    jobsLanguageList,
    jobsLocalityList,
    // jobsNoofJobsList,
    // jobsCountryList,
    // jobsCityList,
    // jobsJobProfileList,
    // jobsWorkPermitList,
    // jobsWorkplaceList,
    // jobsWorkdaysList,
    // jobsShiftsWorkList,
    // jobsContracttypeList,
    // jobsSalarytypeList,
    // jobsAvailabilityList,
    // jobsOutsideofeuList,
    // jobsFlightticketList,
    // jobsTaxifromairportList,
    // jobsAccommodationtList,
    //jobsRelocationbudgetList,
    jobsItskillList,
    jobskillList,
    jobsExperiencetList,
    jobsRelocationList,
    //jobsWorkTypeList
    // jobsSendoutMethodLits,
    // jobsSendoutStepLits,
  } = useSelector((state) => state.common);

  const { isDetailsLoading, jobDetails, isUpdate } = useSelector(
    (state) => state.jobs
  );
  const [status, setStatus] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setData(jobDetails);
      setStatus(jobDetails.status);
    }
  }, [isDetailsLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobDetails);
    }
  }, [isUpdate]);
  let ids = data.id;

  const JobStatus = (status) => {
    let bgColor = "";
    let txtColor = "";
    switch (status) {
      case "Normal":
        bgColor = "#30887e";
        txtColor = "#fff";
        break;

      case "Urgent":
        bgColor = "#dc5539";
        txtColor = "#fff";
        break;

      case "To qualify":
        bgColor = "#ffd966";
        txtColor = "#000";
        break;

      case "On Hold":
        bgColor = "#7f5347";
        txtColor = "#fff";
        break;

      case "Closed":
        bgColor = "#e2445c";
        txtColor = "#fff";
        break;
    }

    return (
      <>
        <Dropdown.Toggle
          variant=""
          className="dk-statusDrpDwnToggle"
          style={{ backgroundColor: bgColor, color: txtColor }}
        >
          {" "}
          {status}
        </Dropdown.Toggle>
      </>
    );
  };

  const updateMyData = (e) => {
    let values = "";
    let columnId = e.target.dataset.columnid;
    if (columnId === "gross_title") {
      values = e.target.dataset.value;
    } else {
      values = e.target.value;
    }

    dispatch(updateJob({ id: ids, key: columnId, value: values }));
  };
  const updateStatus = (values) => {
    setStatus(values);
    dispatch(updateJob({ id: ids, key: "status", value: values }));
  };
  new AirDatepicker("#date1", {
    position: "bottom center",
    locale: en,
    dateFormat: "d MMM yy",
    onSelect({ date, formattedDate, datepicker }) {
      dispatch(updateJob({ id: ids, key: "date1", value: formattedDate }));
    },
  });

  new AirDatepicker("#date2", {
    position: "bottom center",
    locale: en,
    dateFormat: "d MMM yy",
    onSelect({ date, formattedDate, datepicker }) {
      dispatch(updateJob({ id: ids, key: "date2", value: formattedDate }));
    },
  });

  let gross_value = "";
  if (data.gross_title === null) {
    gross_value = "Gross Salary PM";
  } else {
    if (data.gross_title === "Gross Salary PM") {
      gross_value = "Annual Gross";
    } else {
      gross_value = "Gross Salary PM";
    }
  }
  const onItemClick = (id, columnId, value) => {
    dispatch(updateJob({ id, key: columnId, value }));
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="col-md-12 dk-jobsuMMBottomSec pl-0">
            <div className=" row">
              <div className="col-md-12 pr-0 col-sm-12 dk-jobsuMMBottomBoxBg">
                <div className="dk-boxBody row m-0 mt-3 border-0">
                  {/* <div className="col-md-6 pl-0">
                    <div className="dk-suMMformGroupCon mb-2">
                      <label style={{ background: "none", width: "185px" }}>
                        Method
                      </label>
                      <SimpleDropDwn categoryList={jobsSendoutMethodLits} text={data.sendout_method} columnId="sendout_method" />
                    </div>

                    <div className="dk-suMMformGroupCon mb-2">
                      <label style={{ background: "none", width: "185px" }}>
                        Step 1
                      </label>
                      <SimpleDropDwn categoryList={jobsSendoutStepLits} text={data.sendout_step_1} columnId="sendout_step_1" />
                    </div>

                    <div className="dk-suMMformGroupCon mb-2">
                      <label style={{ background: "none", width: "185px" }}>
                        Step 2
                      </label>
                      <SimpleDropDwn categoryList={jobsSendoutStepLits} text={data.sendout_step_2} columnId="sendout_step_2" />
                    </div>

                    <div className="dk-suMMformGroupCon mb-2">
                      <label style={{ background: "none", width: "185px" }}>
                        Step 3
                      </label>
                      <SimpleDropDwn categoryList={jobsSendoutStepLits} text={data.sendout_step_3} columnId="sendout_step_3" />
                    </div>

                    <div className="dk-suMMformGroupCon mb-2">
                      <label style={{ background: "none", width: "185px" }}>
                        Step 4
                      </label>
                      <SimpleDropDwn categoryList={jobsSendoutStepLits} text={data.sendout_step_4} columnId="sendout_step_4" />
                    </div>
                  </div> */}
                  <div className="col-md-6 pl-0">
                    <div className="dk-suMMformGroupCon pb-2">
                      <label
                        style={{
                          background: "none"
                        }}
                      >
                        Language 1
                      </label>
                      <SimpleDropDwn
                        categoryList={jobsLanguageList}
                        text={data.lang}
                        columnId="lang"
                      />
                      <div className="ml-3" style={{ width: "150px" }}>
                        <SimpleDropDwn
                          categoryList={jobsLevelIdList}
                          text={data.lang_lvl}
                          columnId="lang_lvl"
                        />
                      </div>
                    </div>
                    <div className="dk-suMMformGroupCon pb-2">
                      <label
                        style={{
                          background: "none",
                        }}
                      >
                        Language 2
                      </label>
                      <SimpleDropDwn
                        categoryList={jobsLanguageList}
                        text={data.lang2}
                        columnId="lang2"
                      />
                      <div className="ml-3" style={{ width: "150px" }}>
                        <SimpleDropDwn
                          categoryList={jobsLevelIdList}
                          text={data.lang2_lvl}
                          columnId="lang2_lvl"
                        />
                      </div>
                    </div>
                    <div className="dk-suMMformGroupCon pb-2">
                      <label
                        style={{
                          background: "none"
                        }}
                      >
                        Your Location
                      </label>
                      <SimpleDropDwn
                        categoryList={jobsLocalityList}
                        text={data.locality}
                        columnId="locality"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon pb-2">
                      <label
                        style={{
                          background: "none"
                        }}
                      >
                       Relocation
                      </label>
                      <SimpleDropDwn
                        categoryList={jobsRelocationList}
                        text={data.relocation}
                        columnId="relocation"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon pb-2">
                      <label
                        style={{
                          background: "none"
                        }}
                      >
                        Job Skills
                      </label>
                      <SimpleDropDwn
                        categoryList={jobskillList}
                        text={data.skills_set_req}
                        columnId="skills_set_req"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon pb-2">
                      <label
                        style={{
                          background: "none"
                        }}
                      >
                        Tech Skills
                      </label>
                      <SimpleDropDwn
                        categoryList={jobsItskillList}
                        text={data.skills}
                        columnId="skills"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon pb-2">
                      <label
                        style={{
                          background: "none",
                          minWidth: "inherit"
                        }}
                        className=""
                      >
                        Qualification
                      </label>
                      <SimpleDropDwn
                        categoryList={jobsExperiencetList}
                        text={data.qualifying}
                        columnId="qualifying"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="dk-suMMformGroupCon pb-2">
                      <label
                        style={{
                          background: "none",
                          minWidth: "inherit"
                        }}
                        className="dkg-detil-rightlabel"
                      >
                        Certification
                      </label>
                      <input
                        type="text"
                        style={{
                          border: "1px solid #ccc",
                          height: "calc(1.5em + 0.75rem + 0px)",
                          textAlign: "left",
                        }}
                        defaultValue={data.certification}
                        onBlur={updateMyData}
                        data-columnid="certification"
                        className="form-control"
                      />

                      {/* <SimpleDropDwn
                        categoryList={jobsExperiencetList}
                        text={data.qualifying}
                        columnId="certification"
                      /> */}

                    </div>
                    <div className="dk-suMMformGroupCon pb-2">
                      <label style={{ background: "none" }}
                        className="dkg-detil-rightlabel">
                        Typing Speed
                      </label>
                      <input
                        type="text"
                        style={{
                          border: "1px solid #ccc",
                          height: "calc(1.5em + 0.75rem + 0px)",
                          textAlign: "left",
                        }}
                        defaultValue={data.typingSpeed}
                        onBlur={updateMyData}
                        data-columnid="typingSpeed"
                        className="form-control"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon pb-2">
                      <label
                        style={{
                          background: "none",

                        }}
                        className="dkg-detil-rightlabel"
                      >
                        Work Training
                      </label>
                      <input
                        type="text"
                        style={{
                          border: "1px solid #ccc",
                          height:
                            "calc(1.5em + .75rem + 2px)",
                            textAlign: "left"
                        }}
                        defaultValue={data.experience_1}
                        onBlur={updateMyData}
                        data-columnid="experience_1"

                      />
                    </div>
                    <div className="dk-suMMformGroupCon pb-2">
                      <label
                        style={{
                          background: "none"
                        }}
                        className="dkg-detil-rightlabel"
                      >
                        Work Experience
                      </label>
                      <input
                        type="text"
                        style={{
                          border: "1px solid #ccc",
                          height:
                            "calc(1.5em + .75rem + 2px)",
                          textAlign: "left"
                        }}
                        defaultValue={data.experience_2}
                        onBlur={updateMyData}
                        data-columnid="experience_2"

                      />
                    </div>
                    <div className="dk-suMMformGroupCon pb-2">
                      <label style={{ background: "none" }}
                        className="dkg-detil-rightlabel"
                      >
                        Online Assessment
                      </label>
                      <input
                        type="text"
                        style={{
                          border: "1px solid #ccc",
                          height: "calc(1.5em + 0.75rem + 0px)",
                          textAlign: "left",
                        }}
                        className="form-control"
                        defaultValue={data.interviewMode_1}
                        onBlur={updateMyData}
                        data-columnid="interviewMode_1"

                      />
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label
                        style={{ background: "none" }}
                        className="dkg-detil-rightlabel"
                      >Phone Interview</label>
                      <input
                        type="text"
                        style={{
                          border: "1px solid #ccc",
                          height: "calc(1.5em + 0.75rem + 0px)",
                          textAlign: "left",
                        }}
                        defaultValue={data.interviewMode_2}
                        onBlur={updateMyData}
                        data-columnid="interviewMode_2"
                        className="form-control"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label
                        style={{ background: "none" }}
                        className="dkg-detil-rightlabel"
                      >
                       Video Interview
                      </label>
                      <input
                        type="text"
                        style={{
                          border: "1px solid #ccc",
                          height: "calc(1.5em + 0.75rem + 0px)",
                          textAlign: "left",
                        }}
                        defaultValue={data.interviewMode_3}
                        onBlur={updateMyData}
                        data-columnid="interviewMode_3"
                        className="form-control"
                      />
                    </div>
                    {/* <div className="dk-suMMformGroupCon mb-2">
                      <label
                        style={{ background: "none" ,
                        minWidth: "135px !important"}}
                      ></label>
                      <input
                        type="text"
                        style={{
                          border: "1px solid #ccc",
                          height: "calc(1.5em + 0.75rem + 0px)",
                          textAlign: "left",
                        }}
                        defaultValue={data.sendout_email_4}
                        onBlur={updateMyData}
                        data-columnid="sendout_email_4"
                        className="form-control"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label
                        style={{ background: "none",
                        minWidth: "135px !important" }}
                      ></label>
                      <input
                        type="text"
                        style={{
                          border: "1px solid #ccc",
                          height: "calc(1.5em + 0.75rem + 0px)",
                          textAlign: "left",
                        }}
                        defaultValue={data.sendout_email_5}
                        onBlur={updateMyData}
                        data-columnid="sendout_email_5"
                        className="form-control"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sendouts;
