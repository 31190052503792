import React, { useState, useEffect } from "react";
import StepIconOne from "../../../assets/images/resume_black.png";
import StepIconTwo from "../../../assets/images/parse-crm.png";
import StepIconThree from "../../../assets/images/apps_management_black.png";
import StepIconFour from "../../../assets/images/ad-campaign-black.png";
import StepIconFive from "../../../assets/images/summary-black.png";
import StepIconSix from "../../../assets/images/recruiter_black.png";
import './dkglobalteams.scss';
import TeamsLeftPanel from './teams/leftpanel/TeamsLeftPanel'

const DkGlobalTeamOne = () => {
    const [showResulBoxSix, setResultSixShow] = useState(false);
    const [showResulBoxFive, setResultFiveShow] = useState(false);
    const [showResulBoxFour, setResultFourShow] = useState(false);
    const [showResulBoxThree, setResultThreeShow] = useState(false);
    const [showResulBoxTwo, setResultTwoShow] = useState(false);
    const [showResulBoxOne, setResultOneShow] = useState(false);
    const showResultSix = () => {
        setResultSixShow(true)
        setResultFiveShow(false)
        setResultFourShow(false)
        setResultThreeShow(false)
        setResultTwoShow(false)
        setResultOneShow(false)
    }
    const hideResultSix = () => {
        setResultSixShow(false)
    }

    const showResultFive = () => {
        setResultSixShow(false)
        setResultFiveShow(true)
        setResultFourShow(false)
        setResultThreeShow(false)
        setResultTwoShow(false)
        setResultOneShow(false)
    }
    const hideResultFive = () => {
        setResultFiveShow(false)
    }

    const showResultFour = () => {
        setResultSixShow(false)
        setResultFiveShow(false)
        setResultFourShow(true)
        setResultThreeShow(false)
        setResultTwoShow(false)
        setResultOneShow(false)
    }
    const hideResultFour = () => {
        setResultFourShow(false)
    }

    const showResultThree = () => {
        setResultSixShow(false)
        setResultFiveShow(false)
        setResultFourShow(false)
        setResultThreeShow(true)
        setResultTwoShow(false)
        setResultOneShow(false)
    }
    const hideResultThree = () => {
        setResultThreeShow(false)
    }

    const showResultTwo = () => {
        setResultSixShow(false)
        setResultFiveShow(false)
        setResultFourShow(false)
        setResultThreeShow(false)
        setResultTwoShow(true)
        setResultOneShow(false)
    }
    const hideResultTwo = () => {
        setResultTwoShow(false)
    }

    const showResultOne = () => {
        setResultSixShow(false)
        setResultFiveShow(false)
        setResultFourShow(false)
        setResultThreeShow(false)
        setResultTwoShow(false)
        setResultOneShow(true)
    }
    const hideResultOne = () => {
        setResultOneShow(false)
    }
    return (
        <React.Fragment>
            <div className=" dk-compyteam-pagecontainerMain">
                <TeamsLeftPanel />
                {/* <div className="dk-kpiCase-containerMain" style={{ marginTop: "40px" }}>
                    <div className="dk-stepBystepMain">
                        <h3 className="title">TEAM 1 - DK GLOBAL ADMIN</h3>
                        <p className="text-center col-md-5 m-auto">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Blanditiis ipsa vel quod itaque suscipit doloremque quaerat labore. Debitis ipsam perferendis.</p>
                        <div className="dk-stepColsMain">
                            <div className="d-flex">
                                <div className="dk-stepCols">
                                    <div className="dk-stepText">
                                        <p className="title" onClick={showResultSix}>
                                            <span><img src={StepIconOne} alt="" /></span>
                                            ASSIGN JOB ID
                                        </p>
                                        <div className="dk-StepNumber">
                                            Step 6
                                        </div>



                                        {
                                            showResulBoxSix ?
                                                <div className="dk-stepDetailMain">
                                                    <div className="title" style={{ background: '#5f7b86', color: '#fff' }}>
                                                        ASSIGN JOB ID
                                                        <button className="closePanel" onClick={hideResultSix}><i className="fas fa-times"></i></button>
                                                    </div>
                                                    <div className="dk-stepDetailCols">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>

                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>
                                                    </div>
                                                </div> : null
                                        }


                                    </div>
                                    <div className="dk-stepGrayBox"></div>
                                </div>

                                <div className="dk-stepCols" style={{ marginTop: '80px' }}>
                                    <div className="dk-stepText">
                                        <p className="title" onClick={showResultFive}>
                                            <span><img src={StepIconTwo} alt="" /></span>
                                            PARSE CV IN CRM
                                        </p>
                                        <div className="dk-StepNumber">
                                            Step 5
                                        </div>



                                        {
                                            showResulBoxFive ?
                                                <div className="dk-stepDetailMain">
                                                    <div className="title" style={{ background: '#5f7b86', color: '#fff' }}>
                                                        PARSE CV IN CRM
                                                        <button className="closePanel" onClick={hideResultFive}><i className="fas fa-times"></i></button>
                                                    </div>
                                                    <div className="dk-stepDetailCols">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>

                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>
                                                    </div>
                                                </div> : null
                                        }



                                    </div>
                                    <div className="dk-stepGrayBox"></div>
                                </div>

                                <div className="dk-stepCols" style={{ marginTop: '160px' }}>
                                    <div className="dk-stepText">
                                        <p className="title" onClick={showResultFour}>
                                            <span><img src={StepIconThree} alt="" /></span>
                                            MANAGE JOBS APPS
                                        </p>
                                        <div className="dk-StepNumber">
                                            Step 4
                                        </div>



                                        {
                                            showResulBoxFour ?

                                                <div className="dk-stepDetailMain">
                                                    <div className="title" style={{ background: '#5f7b86', color: '#fff' }}>
                                                        MANAGE JOBS APPS
                                                        <button className="closePanel" onClick={hideResultFour}><i className="fas fa-times"></i></button>
                                                    </div>
                                                    <div className="dk-stepDetailCols">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>

                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>
                                                    </div>
                                                </div> : null
                                        }


                                    </div>
                                    <div className="dk-stepGrayBox"></div>

                                </div>

                                <div className="dk-stepCols" style={{ marginTop: '240px' }}>
                                    <div className="dk-stepText">
                                        <p className="title" onClick={showResultThree}>
                                            <span><img src={StepIconFour} alt="" /></span>
                                            ADS POSTING PLAN
                                        </p>
                                        <div className="dk-StepNumber">
                                            Step 3
                                        </div>



                                        {
                                            showResulBoxThree ?

                                                <div className="dk-stepDetailMain">
                                                    <div className="title" style={{ background: '#5f7b86', color: '#fff' }}>
                                                        ADS POSTING PLAN
                                                        <button className="closePanel" onClick={hideResultThree}><i className="fas fa-times"></i></button>
                                                    </div>
                                                    <div className="dk-stepDetailCols">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>

                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>
                                                    </div>
                                                </div> : null
                                        }


                                    </div>
                                    <div className="dk-stepGrayBox"></div>
                                </div>

                                <div className="dk-stepCols" style={{ marginTop: '320px' }}>
                                    <div className="dk-stepText">
                                        <p className="title" onClick={showResultTwo}>
                                            <span><img src={StepIconFive} alt="" /></span>
                                            UPDATE JOB SPECS
                                        </p>
                                        <div className="dk-StepNumber">
                                            Step 2
                                        </div>

                                        {
                                            showResulBoxTwo ?

                                                <div className="dk-stepDetailMain">
                                                    <div className="title" style={{ background: '#5f7b86', color: '#fff' }}>
                                                        UPDATE JOB SPECS
                                                        <button className="closePanel" onClick={hideResultTwo}><i className="fas fa-times"></i></button>
                                                    </div>
                                                    <div className="dk-stepDetailCols">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>

                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>
                                                    </div>
                                                </div> : null
                                        }
                                    </div>
                                    <div className="dk-stepGrayBox"></div>
                                </div>

                                <div className="dk-stepCols" style={{ marginTop: '400px' }}>
                                    <div className="dk-stepText">
                                        <p className="title" onClick={showResultOne}>
                                            <span><img src={StepIconSix} alt="" /></span>
                                            OPEN / CLOSE JOBS
                                        </p>
                                        <div className="dk-StepNumber">
                                            Step 1
                                        </div>

                                        {
                                            showResulBoxOne ?
                                                <div className="dk-stepDetailMain">
                                                    <div className="title" style={{ background: '#5f7b86', color: '#fff' }}>
                                                        OPEN / CLOSE JOBS
                                                        <button className="closePanel" onClick={hideResultOne}><i className="fas fa-times"></i></button>
                                                    </div>
                                                    <div className="dk-stepDetailCols">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>

                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>
                                                    </div>
                                                </div> : null
                                        }
                                    </div>
                                    <div className="dk-stepGrayBox"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

        </React.Fragment>
    );
}

export default DkGlobalTeamOne;
