import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import styled from 'styled-components';

const DatePicker = ({ selectedDate, handleDateChange, onClick }) => {
    const BonusTableDatepicker = styled.div`
    .MuiGrid-root.MuiGrid-container{
        .dkg-bonus-datepicker-in-table{
            margin: 0;
            .MuiInputBase-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd{
                padding: 0 3px;
                &.Mui-focused.Mui-focused{
                    border-bottom: none;
                }
                &:before{
                    border-bottom: none;
                    
                }
                &:hover{
                    border-bottom: none;
                }
                &:hover:not(.Mui-disabled):before {
                    border-bottom: none;
                }
                input{
                    min-width: 70px;
                    text-align: center;
                    padding-left: 32px;
                }
                .MuiInputAdornment-root.MuiInputAdornment-positionEnd{
                    position: absolute;
                    left: -2px;
                  .MuiButtonBase-root.MuiIconButton-root{
                    padding: 3px 5px;
                    &:focus{
                        outline: 0;
                    }
                  }
                }
            }
        }
    }
    .dkg-bonus-datepicker-Muipicker{
        color: #333;
    }
       
`;
    return (
        <>
            <BonusTableDatepicker>
                <MuiPickersUtilsProvider utils={DateFnsUtils} className="dkg-bonus-datepicker-Muipicker">
                    <Grid container >
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            className="dkg-bonus-datepicker-in-table"
                            format="dd MMM yyyy"
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
            </BonusTableDatepicker>

        </>
    )
}

export default DatePicker;