import React from 'react';
import { Link } from "react-router-dom";
import './salessendouts.scss';
import SendoutsLeftPanel from './leftpanel/SendoutsLeftPanel';
import Dropdown from 'react-bootstrap/Dropdown';

const StatsDetails = () => {
    return (
        <>
            <div className="dk-sendouts-containerMain">
                <SendoutsLeftPanel />

                <div className="dk-sendouts-rightPanel">
                    <div className="dk-sendoutsHead">
                        <div className="dk-sendouts-yearsDropdown">
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    2022
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#">2021</Dropdown.Item>
                                    <Dropdown.Item href="#">2020</Dropdown.Item>
                                    <Dropdown.Item href="#">2019</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="dk-sendouts-months">
                            <ul>
                                <li><Link to="#">Jan</Link></li>
                                <li><Link to="#">Feb</Link></li>
                                <li><Link to="#">Mar</Link></li>
                                <li><Link to="#">Apr</Link></li>
                                <li><Link to="#">May</Link></li>
                                <li><Link to="#">Jun</Link></li>
                                <li><Link to="#" className="active">Jul</Link></li>
                                <li><Link to="#">Aug</Link></li>
                                <li><Link to="#">Sep</Link></li>
                                <li><Link to="#">Oct</Link></li>
                                <li><Link to="#">Nov</Link></li>
                                <li><Link to="#">Dec</Link></li>
                            </ul>
                            <Link className="addMonthBtn" to="#" style={{ marginRight: '0' }}><i className="fas fa-plus"></i></Link>
                        </div>
                    </div>
                    <div className="dk-salesSendouts-table">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Date</th>
                                    <th>Recruiter</th>
                                    <th>Week</th>
                                    <th>Day</th>
                                    <th>Job Offer</th>
                                    <th>Refused</th>
                                    <th>Joined</th>
                                    <th>Credit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            1
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                        </div>
                                    </td>
                                    <td style={{ width: '100px' }}>06 July 2022</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td style={{ width: '100px' }}>
                                        Week 2
                                    </td>
                                    <td style={{ width: '100px' }}>Monday</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="1000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            2
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                        </div>
                                    </td>
                                    <td>06 July 2022</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Week 2</td>
                                    <td>Tuesday</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="1000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            3
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                        </div>
                                    </td>
                                    <td>06 July 2022</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Week 4</td>
                                    <td>Friday</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="1000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="10750" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="8000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            4
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                        </div>
                                    </td>
                                    <td>06 July 2022</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        Week 2
                                    </td>
                                    <td>Monday</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="1000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            5
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                        </div>
                                    </td>
                                    <td>06 July 2022</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Week 2</td>
                                    <td>Tuesday</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="1000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            6
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                        </div>
                                    </td>
                                    <td>06 July 2022</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Week 4</td>
                                    <td>Friday</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="1000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="10750" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="8000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            7
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                        </div>
                                    </td>
                                    <td>06 July 2022</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        Week 2
                                    </td>
                                    <td>Monday</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="1000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            8
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                        </div>
                                    </td>
                                    <td>06 July 2022</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Week 2</td>
                                    <td>Tuesday</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="1000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            9
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                        </div>
                                    </td>
                                    <td>06 July 2022</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>Week 4</td>
                                    <td>Friday</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="1000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="10750" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="8000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            10
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                        </div>
                                    </td>
                                    <td>06 July 2022</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        Week 2
                                    </td>
                                    <td>Monday</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="1000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr className="sumtr">
                                    <td colSpan="3" className="transparent"></td>
                                    <td className="sum countGrossSale">Week 35</td>
                                    <td className="sum countGrossSale">160</td>
                                    <td className="sum countGrossSale">18450 €</td>
                                    <td className="sum countGrossSale">18450 €</td>
                                    <td className="sum countGrossSale">35000 €</td>
                                    <td className="sum countGrossSale">28000 €</td>
                                </tr>
                            </tfoot>

                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StatsDetails
