import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const UpdateDetailsModal = ({ handleClose }) => {
    return (
        <>
            <Modal className="dk-jobUpdateDetailsModal" show={true} centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <div className="fonr-group">
                            <label htmlFor="">Title</label>
                            <label htmlFor="">
                                <input type="text" className="form-control" name="" id="" />
                            </label>
                            <label htmlFor="" className="text-center mt-3">
                                <button className="dk-updateBtn">Update</button>
                            </label>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default UpdateDetailsModal;
