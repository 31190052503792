import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const listCategory = createAsyncThunk(
    'faq/list_category',
    async ({ type, companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/admin/faqs/categories',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const listAnswer = createAsyncThunk(
    'faq/list_answer',
    async ({ catId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/admin/faqs/answers/' + catId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

const faqSlice = createSlice({
    name: 'faqs',
    initialState: {
        categoryList: {},
        answerList: [],
        catId: 0,
        isLoading: false,
        isSuccess: false,
        isError: false,
        errorMsg: ''
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.errorMsg = ''
        }
    },
    extraReducers: {
        [listCategory.pending]: (state) => {
            state.isLoading = true;
        },
        [listCategory.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.categoryList = payload.result;
            state.catId = payload.result[0].id
        },
        [listCategory.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            if (payload) {
                state.errorMsg = payload.message.msgBody;
            } else {
                state.errorMsg = 'Something went wrong';
            }
        },
        [listAnswer.pending]: (state) => {
            state.isLoading = true;
        },
        [listAnswer.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.answerList = payload.result;
        },
        [listAnswer.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            if (payload) {
                state.errorMsg = payload.message.msgBody;
            } else {
                state.errorMsg = 'Something went wrong';
            }
        },
    }
})

export const { clearState } = faqSlice.actions
export default faqSlice.reducer;