import React from "react";
import Submenu from "../Submenu";
import DetailLeftPanel from "./DetailLeftPanel";
import DetailTabs from "./DetailTabs";
import MasterboxIcon from "../../../../assets/images/masterbox_black.png";
import "../trainingdetail.scss";

const TrainingMasterbox = () => {
  return (
    <React.Fragment>
      <Submenu />
      <div className="dk-backBtnMain">
        <h3 className="dk-detailTitle">
          <img src={MasterboxIcon} className="img-fluid" alt="" />
          Resource Training
        </h3>
      </div>

      <div className="dk-trainingDetailMain">
        <div className="dk-detailsMain">
          <div className="d-md-flex">
            <div className="dk-detailsLeftPanel">
              <DetailLeftPanel />
            </div>
            <div className="dk-detailRightPanel">
              <DetailTabs />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TrainingMasterbox;
