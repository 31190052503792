import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";

import "../jobspecstatus/dropdownFilter.scss";

const StatusValues = ({ list, selectOtherFilter, jobStatusFilter, tableType }) => {
    return (
        <React.Fragment>
            <DropdownButton
                className="StatusdropdownFilter"
                id="dropdown-basic-button"
                title={jobStatusFilter}
            >
                <Dropdown.Item
                    href="#"
                    style={{ backgroundColor: "#30887e", color: "#fff" }}
                    data-type="values"
                    data-value="High"
                    onClick={selectOtherFilter}
                >
                    High
                    <span
                        className="dropdown-filter-count"
                        data-type="values"
                        data-value="High"
                        onClick={selectOtherFilter}
                    >
                        {
                            list.filter(function (val) {
                                return val.original.values === "High";
                            }).length
                        }
                    </span>
                </Dropdown.Item>
                <Dropdown.Item
                    href="#"
                    style={{ backgroundColor: "#d58b5d", color: "#fff" }}
                    data-type="values"
                    data-value="Mid"
                    onClick={selectOtherFilter}
                >
                    Mid
                    <span
                        className="dropdown-filter-count"
                        data-type="values"
                        data-value="Mid"
                        onClick={selectOtherFilter}
                    >
                        {
                            list.filter(function (val) {
                                return val.original.values === "Mid";
                            }).length
                        }
                    </span>
                </Dropdown.Item>

                <Dropdown.Item
                    href="#"
                    style={{ backgroundColor: "#a84d4d", color: "#fff" }}
                    data-type="values"
                    data-value="Low"
                    onClick={selectOtherFilter}
                >
                    Low
                    <span
                        className="dropdown-filter-count"
                        data-type="values"
                        data-value="Low"
                        onClick={selectOtherFilter}
                    >
                        {
                            list.filter(function (val) {
                                return val.original.values === "Low";
                            }).length
                        }
                    </span>
                </Dropdown.Item>


            </DropdownButton>
        </React.Fragment>
    );
};

export default StatusValues;
