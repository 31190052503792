import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/dkglobaladmin/PrMenu';

const AdminPrLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header mainheaderLink="" moduleLink="#/dkglobaladmin/dashboard/module" linkcolor="#3c4c62" mainheaderTitle=" PERSONAL RELATIONS" textcolor="#657d74" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default AdminPrLayout;