import React from 'react';
import Header from "../elements/header";
// import Navbar from '../../elements/header/navbar/mainMenu/dkglobaladmin/ClientsMenu';

const ClientsIncomeTaxLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header mainheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" linkcolor="#3c4c62" mainheaderTitle=" CLIENTS" moduleLink="/dashboard/clients" textcolor="#535255" />
            {/* <Navbar /> */}
            {children}
        </React.Fragment>
    )
}
export default ClientsIncomeTaxLayout;
