import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faTrashAlt, faStar ,faFlag } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { mailDetails, clearState, getSystemUser } from "../../../../../slice/mailbox/mailboxSlice"
import CustomDateFormat from "../../../../ui/dateFormats/CustomDateFormat";

export const ListBlock = ({ list, label, deleteMailSingle, isCheckAll, setAllCheckedMail, getAllCheckedMail, markImportant }) => {
    const dispatch = useDispatch();
    const { isLoading, isSuccess, details } = useSelector(state => state.mailbox);
    const [mailId, setMailId] = useState(0)

    useEffect(() => {
        if (mailId > 0) {
            (async function () {
                try {
                    await dispatch(mailDetails({ id: mailId, type: null }))
                    dispatch(getSystemUser());
                } catch (e) {
                    console.error(e);
                }
            })();
        }
    }, [mailId])

    useEffect(() => {
        setMailId(0)
    }, [label])

    const showMailDetails = (id) => {
        if (isCheckAll) {
            if (getAllCheckedMail.includes(id))
                setAllCheckedMail(getAllCheckedMail.filter((item) => item !== id))
            else
                setAllCheckedMail([...getAllCheckedMail, id])
        } else {
            setMailId(id)
            const getList = list.findIndex((data) => data.id == id)
            if (getList > -1)
                list[getList].is_read = true
        }
    }
    
    const isMailBoxClass = (item) => {
        if (item.flag) {
            return 'flag' + (item.is_read ? '' : ' dk-newMsg')
        }
        if (getAllCheckedMail.length > 0) {
            return (getAllCheckedMail.includes(item.id)) ? 'active' : null
        } else if (details && item.id === details.id) {
            return 'active' + (item.is_read ? '' : ' dk-newMsg')
        } else {
            return (item.is_read) ? null : 'dk-newMsg'
        }
    }

    return (
        <React.Fragment>
            <ul className="dk-mailList">
                {
                    (isLoading) ?
                        <React.Fragment>
                            <li>
                                <Skeleton />
                                <Skeleton animation="wave" />
                            </li>
                            <li>
                                <Skeleton />
                                <Skeleton animation="wave" />
                            </li>
                            <li>
                                <Skeleton />
                                <Skeleton animation="wave" />
                            </li>
                            <li>
                                <Skeleton />
                                <Skeleton animation="wave" />
                            </li>
                            <li>
                                <Skeleton />
                                <Skeleton animation="wave" />
                            </li>
                        </React.Fragment>
                        :
                        list && list.length > 0 && list.map((item, index) =>
                            <li 
                                key={`list-block-key-${index}`} 
                                className={isMailBoxClass(item)} 
                                onClick={() => showMailDetails(item.id)}
                            >
                                {/*<div className="title">{item.userInfo.firstName + " " + item.userInfo.lastName}</div>*/}
                                {item.mailbox.from}
                                {/* {
                                    (label === 'inbox' || label === 'sent') &&
                                    <div className="title">
                                        <span to="#" onClick={(e) => {
                                            e.stopPropagation();
                                            deleteMailSingle(item.id)
                                        }}><FontAwesomeIcon icon={faTrashAlt} /></span>
                                    </div>
                                } */}
                                <div className="sbTitle">{item.mailbox.subject}
                                    {/* {
                                        (item.mailbox.is_attachment) ?
                                            <span className="attach-icon"><FontAwesomeIcon icon={faPaperclip} /></span> : null
                                    } */}
                                </div>
                                <span className='dkg-email-showdatepicker date-location'> {<CustomDateFormat date={item.mailbox.date} format='HH:mm' />}</span>
                                <div className="dk-hoverFigure">
                                    {/* <Link to="#" onClick={() => markImportant(item.id)}><FontAwesomeIcon icon={faStar} /></Link> */}
                                    {
                                        (item.mailbox.is_attachment) ?
                                            <Link to="#" className="dkg-attachment-file"><FontAwesomeIcon icon={faPaperclip} /></Link> : null
                                    }
                                    {
                                        item.flag ?
                                            <span to="#" 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    markImportant(item.id, 'flag')
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faFlag} style={{color: item.flag ? '#DA3D5E' : ''}}/>
                                            </span>
                                        :
                                            <Link to="#" 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    markImportant(item.id, 'flag')
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faFlag} style={{color: item.flag ? '#DA3D5E' : ''}}/>
                                            </Link>

                                    }
                                    {
                                    (label === 'inbox' || label === 'sent') &&
                                        <Link  to="#" onClick={(e) => {
                                            e.stopPropagation();
                                            deleteMailSingle(item.id)
                                        }}><FontAwesomeIcon icon={faTrashAlt} /></Link>
                                     }
                                    {/* <input type='checkbox'  /> */}
                                </div>
                            </li>
                        )
                }
            </ul>
        </React.Fragment>
    )
}
