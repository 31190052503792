import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const MyTaskDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/business') ? 'active' : ''}`}>My Tasks</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/industry') ? 'active' : ''}`}>Other Tasks</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/services') ? 'active' : ''}`}>Group Tasks</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/clients') ? 'active' : ''}`}>Payment Tasks</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/suppliers') ? 'active' : ''}`}>Meetings</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/completion') ? 'active' : ''}`}>Calls</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>To Do</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Mailbox</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Emails</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Breaks</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Pipeline</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Account</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default MyTaskDetailLeftPanel;

