import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const SendRemindersDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkglobaladmin/admintraining/admins/send-reminders" className={`${pathname.match('/dkglobaladmin/admintraining/admins/send-reminders') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/sendreminders/situations" className={`${pathname.match('/dkglobaladmin/admintraining/admins/sendreminders/situations') ? 'active' : ''}`}>Situations</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/sendreminders/task-rules" className={`${pathname.match('/dkglobaladmin/admintraining/admins/sendreminders/task-rules') ? 'active' : ''}`}>Task Rules</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/sendreminders/how-to-do" className={`${pathname.match('/dkglobaladmin/admintraining/admins/sendreminders/how-to-do') ? 'active' : ''}`}>How To Do</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/sendreminders/1st-reminder" className={`${pathname.match('/dkglobaladmin/admintraining/admins/sendreminders/1st-reminder') ? 'active' : ''}`}>1st Reminder</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/sendreminders/2nd-reminder" className={`${pathname.match('/dkglobaladmin/admintraining/admins/sendreminders/2nd-reminder') ? 'active' : ''}`}>2nd Reminder</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/sendreminders/3rd-reminder" className={`${pathname.match('/dkglobaladmin/admintraining/admins/sendreminders/3rd-reminder') ? 'active' : ''}`}>3rd Reminder</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/sendreminders/final-reminder" className={`${pathname.match('/dkglobaladmin/admintraining/admins/sendreminders/final-reminder') ? 'active' : ''}`}>Final Reminder</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/sendreminders/case-discussion" className={`${pathname.match('/dkglobaladmin/admintraining/admins/sendreminders/case-discussion') ? 'active' : ''}`}>Case Discussion</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default SendRemindersDetailLeftPanel;

