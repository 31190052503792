import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import "./camedit.css";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "../../../../ui/tooltip";

const View = ({ onHide, id }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const { detailData } = useSelector(state => state.liveAdsTracker)
    useEffect(() => {
        setShow(true)
    }, [id])

    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-compaginsEditPop"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton className='dk-liveads-view-header'>
                    <Modal.Title>View</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-compaginsEdit">
                    <form>
                        <div className="row">
                            <div className="col-md-3 mr-auto">
                                <div className="form-group">
                                    <label htmlFor="">Recruiter</label>
                                    <label htmlFor="" className="mb-0 dk-adstrackviewRec">
                                        <Tooltip type="img" title={detailData.userInfo.firstName + " " + detailData.userInfo.lastName} value={process.env.REACT_APP_IMG_CLOUD_BASE_URL + detailData.userInfo.profileImage} className="select-user-img" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-9">
                                <div className="form-group">
                                    <label>Job Title</label>
                                    <input type="text" className="form-control broderContrl" value={detailData.jobTitle} readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Job Board</label>
                                    <input type="text" className="form-control broderContrl" value={detailData.jobBoard} readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Job Ref</label>
                                    <input type="text" className="form-control broderContrl" value={detailData.jobRef} readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Company</label>
                                    <input type="text" className="form-control broderContrl" value={detailData.company} readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Language</label>
                                    <input type="text" className="form-control broderContrl" value={detailData.language} readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Role Type</label>
                                    <input type="text" className="form-control broderContrl" value={detailData.roleType} readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>City</label>
                                    <input type="text" className="form-control broderContrl" value={detailData.city} />
                                </div>
                            </div>

                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default View;
