import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AddNewClientsInvoice from "./modal/AddNewClientsInvoice";
import { FaEye, FaDownload, FaSearch } from 'react-icons/fa';
import ContractLeftPanel from './leftpanel/ContractLeftPanel'
import './myacc-contracts.scss';

const Contract = () => {
    const [isAddNewInvoiceModal, setIsAddNewInvoiceModal] = useState(false)

    const openAddNewInvoiceModal = () => {
        setIsAddNewInvoiceModal(true)
    }
    const hideAddNewInvoiceModal = () => {
        setIsAddNewInvoiceModal(false)
    }
    return (
        <>
            {
                (isAddNewInvoiceModal) ? <AddNewClientsInvoice hideAddNewInvoiceModal={hideAddNewInvoiceModal} /> : null
            }
            <div className='dkg-myacc-contracts-main-Con container-fluid'>
                <div className='d-flex dkg-contracts-mainCon'>
                    <ContractLeftPanel />
                    <div className='dkg-contracts-boxCon dk-contractsMain-container'>
                        <div className="dk-moduleBankingProcess-rightPanel bgTransparent">
                            <div className="dk-statmentFlex">
                                <div className="dk-searchCols">
                                    <input type="text" name="" id="" placeholder="Search..." className="form-control" />
                                    <button><FaSearch /></button>
                                </div>
                                <div className="title">MY CONTRACTS</div>
                                <div className="dk-financeYears-addNew">
                                    <Link to="#" onClick={openAddNewInvoiceModal}>+ Add New</Link>
                                </div>
                            </div>
                            <div className="dk-moduleBankingProcess-rightCols">
                                <div className="dk-financeClients-tableMain">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Start Date</th>
                                                    <th>Expire On</th>
                                                    <th>Duration</th>
                                                    <th>Name of Entity/Person</th>
                                                    <th>Contract Type</th>
                                                    <th>Services Offered</th>
                                                    <th>View</th>
                                                    <th>Download</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            1
                                                            <div className="dk-checkinputIcon">
                                                                <input type="checkbox" className="chkCandidate" value="" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>02 Sep 22</td>
                                                    <td>10 Sep 22</td>
                                                    <td>10 Days</td>
                                                    <td>Rossella Casciello</td>
                                                    <td>52866547</td>
                                                    <td>Recruitment</td>
                                                    <td>
                                                        <div className="dk-financeAction">
                                                            <Link to="#" className="viewBtn"><FaEye /></Link>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dk-financeAction">
                                                            <Link to="#" className="downloadBtn"><FaDownload /></Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contract;