import React from "react";
import Chart from "react-google-charts";


const CustomPieChart = () => {
    const data = [
        ["Task", "Hours per Day"],
        ["Work", 11],
        ["Eat", 2],
        ["Commute", 2],
        ["Watch TV", 2],
        ["Sleep", 7],
    ];
    const options = {
        title: "",
        is3D: true,
        backgroundColor: '#eaeaea',
    };
    return (
        <>
            <Chart
                chartType="PieChart"
                data={data}
                options={options}
                width={"100%"}
                height={"125px"}
            />
        </>
    )
}

export default CustomPieChart;
