import React from 'react'

const Consults = () => {
  return (
    <>
        <div className="dk-myacc-myprofilesummMain">
            <div className="dkg-myacc-profile-summ-234">
                <div className="dkg-myacc-profile-Row"></div>
            </div>
        </div>
    </>
  )
}

export default Consults