import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './workflow.scss'
import WorkFlowNoUpdateModal from './WorkFlowNoUpdate'
import RecruiterFilter from './RecruiterFilter'


const Workflow = () => {
    const [isWorkFlowNoUpdate, setIsWorkFlowNoUpdate] = useState(false);

    const showreacruiterModal = () => {
        setIsWorkFlowNoUpdate(true);
        console.log("Recruiter modal is CLicked");
    }
    const hiderecruiterModal = () => {
        setIsWorkFlowNoUpdate(false);
    }
    return (
        <>
            {
                (isWorkFlowNoUpdate) ? <WorkFlowNoUpdateModal handleClose={hiderecruiterModal} /> : null
            }
            <div className="container-fluid dk-workflowCon">
                <div className="row">
                    <div className="col-md-12 dk-workFlowPageHeader">
                        <ul>
                            <li className="active">
                                <Link to="#" className="dk-workflowswitchbtn">Recruitment Admin</Link>
                            </li>
                            <li className="inactive">
                                <Link to="#" className="dk-workflowswitchbtn">Recruiters</Link>
                            </li>
                            <li className="inactive">
                                <Link to="#" className="dk-workflowswitchbtn">Account Manager</Link>
                            </li>
                        </ul>
                        <div className='dk-workflow-editbtnCon'>
                            <Link to="/recruitment/candidate/workflow" className='btn dk-workflow-editbtn'><i className="fas fa-arrow-left mr-1"></i>Back</Link>
                        </div>
                    </div>
                    <div className="dk-workFlowbottomSec">
                        <div className="dk-recAmBox">
                            <div className="dk-recAmImgCon" style={{ backgroundColor: "#e2445a" }}>
                                <RecruiterFilter />
                            </div>
                            <div className="dk-workflowBodyCon">
                                <div className="dk-workflowBodyHeader">
                                    <h2>Pipeline Today</h2>
                                </div>
                                <div className="dk-workflowBodyList">
                                    <table className="table dk-workflowTable table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>NEW CV</td>
                                                <td onClick={showreacruiterModal}>30</td>
                                            </tr>
                                            <tr>
                                                <td>SELECTED</td>
                                                <td onClick={showreacruiterModal}>20</td>
                                            </tr>
                                            <tr>
                                                <td>SCREENING</td>
                                                <td onClick={showreacruiterModal}>56</td>
                                            </tr>
                                            <tr>
                                                <td>RECRUITERS</td>
                                                <td onClick={showreacruiterModal}>30</td>
                                            </tr>
                                            <tr>
                                                <td>ACCOUNT M</td>
                                                <td onClick={showreacruiterModal}>10</td>
                                            </tr>
                                            <tr>
                                                <td>JOB OFFERS</td>
                                                <td onClick={showreacruiterModal}>6</td>
                                            </tr>
                                            <tr>
                                                <td>ESCALATED</td>
                                                <td onClick={showreacruiterModal}>20</td>
                                            </tr>
                                            <tr>
                                                <td>CLOSED CASE</td>
                                                <td onClick={showreacruiterModal}>20</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="dk-recAmBox">
                            <div className="dk-recAmImgCon" style={{ backgroundColor: "#90ee90" }}>
                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Recruiter" />
                            </div>
                            <div className="dk-workflowBodyCon">
                                <div className="dk-workflowBodyHeader">
                                    <h2>Pipeline Today</h2>
                                </div>
                                <div className="dk-workflowBodyList">
                                    <table className="table dk-workflowTable table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>NEW CV</td>
                                                <td onClick={showreacruiterModal}>30</td>
                                            </tr>
                                            <tr>
                                                <td>SELECTED</td>
                                                <td onClick={showreacruiterModal}>20</td>
                                            </tr>
                                            <tr>
                                                <td>SCREENING</td>
                                                <td onClick={showreacruiterModal}>56</td>
                                            </tr>
                                            <tr>
                                                <td>RECRUITERS</td>
                                                <td onClick={showreacruiterModal}>30</td>
                                            </tr>
                                            <tr>
                                                <td>ACCOUNT M</td>
                                                <td onClick={showreacruiterModal}>10</td>
                                            </tr>
                                            <tr>
                                                <td>JOB OFFERS</td>
                                                <td onClick={showreacruiterModal}>6</td>
                                            </tr>
                                            <tr>
                                                <td>ESCALATED</td>
                                                <td onClick={showreacruiterModal}>20</td>
                                            </tr>
                                            <tr>
                                                <td>CLOSED CASE</td>
                                                <td>32</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="dk-recAmBox">
                            <div className="dk-recAmImgCon" style={{ backgroundColor: "#01b0f0" }}>
                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Recruiter" />
                            </div>
                            <div className="dk-workflowBodyCon">
                                <div className="dk-workflowBodyHeader">
                                    <h2>Pipeline Today</h2>
                                </div>
                                <div className="dk-workflowBodyList">
                                    <table className="table dk-workflowTable table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>NEW CV</td>
                                                <td onClick={showreacruiterModal}>30</td>
                                            </tr>
                                            <tr>
                                                <td>SELECTED</td>
                                                <td onClick={showreacruiterModal}>20</td>
                                            </tr>
                                            <tr>
                                                <td>SCREENING</td>
                                                <td onClick={showreacruiterModal}>56</td>
                                            </tr>
                                            <tr>
                                                <td>RECRUITERS</td>
                                                <td onClick={showreacruiterModal}>30</td>
                                            </tr>
                                            <tr>
                                                <td>ACCOUNT M</td>
                                                <td onClick={showreacruiterModal}>10</td>
                                            </tr>
                                            <tr>
                                                <td>JOB OFFERS</td>
                                                <td onClick={showreacruiterModal}>6</td>
                                            </tr>
                                            <tr>
                                                <td>ESCALATED</td>
                                                <td onClick={showreacruiterModal}>20</td>
                                            </tr>
                                            <tr>
                                                <td>CLOSED CASE</td>
                                                <td>32</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="dk-recAmBox">
                            <div className="dk-recAmImgCon" style={{ backgroundColor: "#d29e01" }} >
                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Recruiter" />
                            </div>
                            <div className="dk-workflowBodyCon">
                                <div className="dk-workflowBodyHeader">
                                    <h2>Pipeline Today</h2>
                                </div>
                                <div className="dk-workflowBodyList">
                                    <table className="table dk-workflowTable table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>NEW CV</td>
                                                <td onClick={showreacruiterModal}>30</td>
                                            </tr>
                                            <tr>
                                                <td>SELECTED</td>
                                                <td onClick={showreacruiterModal}>20</td>
                                            </tr>
                                            <tr>
                                                <td>SCREENING</td>
                                                <td onClick={showreacruiterModal}>56</td>
                                            </tr>
                                            <tr>
                                                <td>RECRUITERS</td>
                                                <td onClick={showreacruiterModal}>30</td>
                                            </tr>
                                            <tr>
                                                <td>ACCOUNT M</td>
                                                <td onClick={showreacruiterModal}>10</td>
                                            </tr>
                                            <tr>
                                                <td>JOB OFFERS</td>
                                                <td onClick={showreacruiterModal}>6</td>
                                            </tr>
                                            <tr>
                                                <td>ESCALATED</td>
                                                <td onClick={showreacruiterModal}>20</td>
                                            </tr>
                                            <tr>
                                                <td>CLOSED CASE</td>
                                                <td>32</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="dk-recAmBox">
                            <div className="dk-recAmImgCon" style={{ backgroundColor: "#297483" }}>
                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Recruiter" />
                            </div>
                            <div className="dk-workflowBodyCon">
                                <div className="dk-workflowBodyHeader">
                                    <h2>Pipeline Today</h2>
                                </div>
                                <div className="dk-workflowBodyList">
                                    <table className="table dk-workflowTable table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>NEW CV</td>
                                                <td onClick={showreacruiterModal}>30</td>
                                            </tr>
                                            <tr>
                                                <td>SELECTED</td>
                                                <td onClick={showreacruiterModal}>20</td>
                                            </tr>
                                            <tr>
                                                <td>SCREENING</td>
                                                <td onClick={showreacruiterModal}>56</td>
                                            </tr>
                                            <tr>
                                                <td>RECRUITERS</td>
                                                <td onClick={showreacruiterModal}>30</td>
                                            </tr>
                                            <tr>
                                                <td>ACCOUNT M</td>
                                                <td onClick={showreacruiterModal}>10</td>
                                            </tr>
                                            <tr>
                                                <td>JOB OFFERS</td>
                                                <td onClick={showreacruiterModal}>6</td>
                                            </tr>
                                            <tr>
                                                <td>ESCALATED</td>
                                                <td onClick={showreacruiterModal}>20</td>
                                            </tr>
                                            <tr>
                                                <td>CLOSED CASE</td>
                                                <td>32</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Workflow;
