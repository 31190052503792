import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import IrelandFlag from '../../../../assets/images/ire-flagsvg.png';
import { FaEuroSign } from "react-icons/fa";

import './incometax.scss'


const index = () => {

    return (
        <React.Fragment>
            <div className="container-fluid dkg-income-tax-PageCont">
                <div className="row">
                    <div className="col-12 col-md-12 text-center">
                        <div className="row align-items-center">
                            <div className="col-md-4 col-sm-12 dk-dkClileftGap">
                                <div className="dk-cliLeftheaderCont d-flex pl-0">
                                    <input id="srchterm" type="text" className="form-control searchTxtBox" name="search" placeholder="Search" />
                                    <button className="btn dk-mbclientsrchInput legitRipple" type="button">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="dk-clntsPageTitle">
                                    <h2>INCOME TAX %</h2>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="d-flex justify-content-end">
                                    <button className="dk-reloadBtn"><FontAwesomeIcon icon={faRefresh} /></button>
                                    <div className="dk-ClieAddNewBtn pr-0" >
                                        <Link to="#"><i className="fa fa-plus mr-2" aria-hidden="true"></i>Add New</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="dkg-compolicytableCon table-responsive">
                            <table className="table dkg-compolicytable">
                                <thead>
                                    <tr>
                                        <th>Country</th>
                                        <th>Currency</th>
                                        <th>Income Upto	</th>
                                        <th>Basic Income Tax</th>
                                        <th>Others Tax</th>
                                        <th>Total Tax</th>
                                        <th>Net Income	</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className='dk-inComeTableIcn'>
                                                <img src={IrelandFlag} className="person-imageFlex" alt="Flag" />
                                                <div className="iconwithTitle">IRELAND</div>
                                            </div>
                                        </td>
                                        <td>
                                            €
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            <Link className="dk-calCulateBtn" to="#">Calculate</Link>
                                        </td>
                                        <td>
                                            <div className="dk-CliDetailAction">
                                                <Link className="dk-view-btn mr-2" to="#">
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </Link>
                                                <Link className="dk-edit-btn mr-2" to="#">
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </Link>
                                                <Link className="dk-delete-btn" to="#" >
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='dk-inComeTableIcn'>
                                                <img src={IrelandFlag} className="person-imageFlex" alt="Flag" />
                                                <div className="iconwithTitle">IRELAND</div>
                                            </div>
                                        </td>
                                        <td>
                                            €
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            <Link className="dk-calCulateBtn" to="#">Calculate</Link>
                                        </td>
                                        <td>
                                            <div className="dk-CliDetailAction">
                                                <Link className="dk-view-btn mr-2" to="#">
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </Link>
                                                <Link className="dk-edit-btn mr-2" to="#">
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </Link>
                                                <Link className="dk-delete-btn" to="#" >
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='dk-inComeTableIcn'>
                                                <img src={IrelandFlag} className="person-imageFlex" alt="Flag" />
                                                <div className="iconwithTitle">IRELAND</div>
                                            </div>
                                        </td>
                                        <td>
                                            €
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            <Link className="dk-calCulateBtn" to="#">Calculate</Link>
                                        </td>
                                        <td>
                                            <div className="dk-CliDetailAction">
                                                <Link className="dk-view-btn mr-2" to="#">
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </Link>
                                                <Link className="dk-edit-btn mr-2" to="#">
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </Link>
                                                <Link className="dk-delete-btn" to="#" >
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='dk-inComeTableIcn'>
                                                <img src={IrelandFlag} className="person-imageFlex" alt="Flag" />
                                                <div className="iconwithTitle">IRELAND</div>
                                            </div>
                                        </td>
                                        <td>
                                            €
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            <Link className="dk-calCulateBtn" to="#">Calculate</Link>
                                        </td>
                                        <td>
                                            <div className="dk-CliDetailAction">
                                                <Link className="dk-view-btn mr-2" to="#">
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </Link>
                                                <Link className="dk-edit-btn mr-2" to="#">
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </Link>
                                                <Link className="dk-delete-btn" to="#" >
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='dk-inComeTableIcn'>
                                                <img src={IrelandFlag} className="person-imageFlex" alt="Flag" />
                                                <div className="iconwithTitle">IRELAND</div>
                                            </div>
                                        </td>
                                        <td>
                                            €
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            <Link className="dk-calCulateBtn" to="#">Calculate</Link>
                                        </td>
                                        <td>
                                            <div className="dk-CliDetailAction">
                                                <Link className="dk-view-btn mr-2" to="#">
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </Link>
                                                <Link className="dk-edit-btn mr-2" to="#">
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </Link>
                                                <Link className="dk-delete-btn" to="#" >
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='dk-inComeTableIcn'>
                                                <img src={IrelandFlag} className="person-imageFlex" alt="Flag" />
                                                <div className="iconwithTitle">IRELAND</div>
                                            </div>
                                        </td>
                                        <td>
                                            €
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            1%
                                        </td>
                                        <td>
                                            <Link className="dk-calCulateBtn" to="#">Calculate</Link>
                                        </td>
                                        <td>
                                            <div className="dk-CliDetailAction">
                                                <Link className="dk-view-btn mr-2" to="#">
                                                    <i className="fa fa-eye " aria-hidden="true"></i>
                                                </Link>
                                                <Link className="dk-edit-btn mr-2" to="#">
                                                    <i className="fa fa-edit " aria-hidden="true"></i>
                                                </Link>
                                                <Link className="dk-delete-btn" to="#" >
                                                    <i className="fa fa-trash " aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default index;

