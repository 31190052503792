import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
// import './emailcontent.scss'
import SendEmailUser from "../../../../../../../../assets/images/deepak.png";
import CustomDateFormat from "../../../../../../../ui/dateFormats/CustomDateFormat";
import Skeleton from "@material-ui/lab/Skeleton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {mailDetails, clearMailDetail, getSingleInternalMail, updateInternalMailStatus, deleteInternalMail, clearState} from "../../../../../../../../slice/mailbox/mailboxSlice";
import {useDispatch, useSelector} from "react-redux";
import {faPaperclip} from "@fortawesome/free-solid-svg-icons";
import AttachmentPreviewModal from "./attachmentPreview";
import {showSuccess} from "../../../../../../../utils/messages/messageSlice";
import ReplyMail from "./ReplyMail";

const EmailContent = (props) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const {message, details, isDetailsLoading, singleInternalMail, isUpdateStatus, updateMailMessage, internalMails} = useSelector((state) => state.mailbox);

  useEffect(() => {
    setShow(false);
    if(updateMailMessage != null && isUpdateStatus == false){
      dispatch(showSuccess({msg: updateMailMessage}));
      dispatch(clearState());
    }
  }, [isUpdateStatus]);


  const handleClose = () => {
    // dispatch(clearMailDetail())
    setShow(false);
  };
  const handleShow = async (can_id, role, mail_id) => {
    setShow(true);
    dispatch(getSingleInternalMail({can_id, role, mail_id}));
  };

  const [shoePreview, setPreview] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);

  const openPreview = (file) => {
    setPreview(true);
    setPreviewFile(file);
  };

  const closePreview = () => {
    setPreview(false);
    setPreviewFile(null);
  };

  const updateStatus = (status, mail_id) => {
    const data = {
      mail_id: mail_id,
      updated_status: status,
    };
    dispatch(updateInternalMailStatus(data));
  };

  const deleteMail = (mail_id) => {
    dispatch(deleteInternalMail(mail_id))
  }

  const reply = (mail_id) =>{

  }

  return (
    <>
      <AttachmentPreviewModal show={shoePreview} file={previewFile} onClose={closePreview} />
      <span className="dkg-jobtitle-cols" onClick={() => handleShow(props.candidateId, props.role, props.email.id)}>
        {props.email.mailbox.subject}
      </span>
      <Modal show={show} onHide={handleClose} className="dkg-emailcontent-Modal">
        <Modal.Header closeButton className="dkg-emailcontent-ModalHeader dkg-reply-mail-newHeader">
          <ReplyMail email={props.email} />
          <Modal.Title>{props.email.mailbox.subject} </Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-emailcontent-ModalBody">
          {isDetailsLoading ? null : (
            <>
              <div className="dk-email-content">
                <div className="dk-msgHeader">
                  <div className="dk-userTitle">
                    <div className="dkg-sendemail-imgCon">
                      <img src={SendEmailUser} alt="User Icon" className="dkg-sendemail-img" />
                    </div>
                    <div className="dk-detailText">
                      <div className="title">
                        From : {singleInternalMail?.mailbox?.from_name} &lt;{singleInternalMail?.mailbox?.from}&gt;
                      </div>
                      <div className="dkg-bc-datepickerCon">
                        <div className="">
                          <div className="sbtitle mr-2">
                            <b>To : </b> {singleInternalMail?.mailbox?.to}{" "}
                          </div>
                        </div>
                        <div className="d-flex dkg-email-showdatepickerCon">
                          <span className="dkg-email-showdatepicker">{<CustomDateFormat date={details?.mailbox?.date} format="ddd DD[th] MMM YYYY [,] HH:mm A" />}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dk-msgSender-detail">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dk-detail-leftPanel">
                      <div className="dkg-email-subject-Con">
                        <div className="dkg-email-subject">
                          <span className="dkg-subtile">Subject : </span>
                          {singleInternalMail?.mailbox?.subject}
                        </div>
                      </div>
                      {singleInternalMail?.mailbox?.attachments.length > 0 && (
                        <div className="dk-mailViewAttachfile-Con">
                          {singleInternalMail?.mailbox?.attachments.map((attachment) => (
                            <div className="dk-mailViewAttach" onClick={() => openPreview(attachment.file_url)}>
                              {attachment.file_name} <FontAwesomeIcon icon={faPaperclip} />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="dkg-readmsg-mainCon">
                  <div className="dkg-readmsg-title">{isDetailsLoading ? <Skeleton animation="wave" style={{width: "50%"}} /> : <div dangerouslySetInnerHTML={{__html: singleInternalMail?.mailbox?.body}} />}</div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmailContent;
