import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";

const FaqClientsLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/knowleadge/clients/overview" className={`${pathname.match('/knowleadge/clients/overview') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/knowleadge/clients/sample-1" className={`${pathname.match('/knowleadge/clients/sample-1') ? 'active' : ''}`}>Sample 1</Link></li>

                    <li><Link to="/knowleadge/clients/sample-2" className={`${pathname.match('/knowleadge/clients/sample-2') ? 'active' : ''}`}>Sample 2</Link></li>

                    <li><Link to="/knowleadge/clients/sample-3" className={`${pathname.match('/knowleadge/clients/sample-3') ? 'active' : ''}`}>Sample 3</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default FaqClientsLeftPanel;

