import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';
import './document.scss';
import { candidateDoc } from '../../../../../../slice/candidates/profile/profileSlice';
import { Document, Page } from 'react-pdf';

const CandidateDocumentModal = ({ name, canId }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = async () => {
        setShow(true);

    }

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const [frameType, setFrameType] = useState('0')
    const [fileUrl, setFileUrl] = useState('0')

    function isEmpty(obj) {
        for (var i in obj) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (show) {
            async function fetchData() {
                const result = await dispatch(candidateDoc({ canId }))
                if (!isEmpty(result.payload.result)) {
                    const fileName = result.payload.result.fileName                   
                    if (fileName != null || '') {
                        setFrameType(fileName.split('.').pop());
                        setFileUrl(fileName.split("https://master-upload.s3.amazonaws.com").pop());
                       // console.log(process.env.REACT_APP_INTERNAL_BASE_URL + fileUrl)
                    } else {
                        setFileUrl('0')
                    }
                } else {
                    setFileUrl('0')
                }
            }
            fetchData();
        }
    }, [show])

    return (
        <>
            <span onClick={handleShow} className='cursor-pointer'>{name}</span>
            <Modal show={show} onHide={handleClose} dialogClassName="dk-pipedocs-previewModal"
                aria-labelledby="example-custom-modal-styling-title"
                centered animation={false}>
                <Modal.Header closeButton>

                </Modal.Header>
                {
                    (frameType != '0') ?
                        (fileUrl != '0') ?
                            <Modal.Body className='dk-pipedocs-preview-modalbody'>
                                {
                                    (frameType == 'pdf' || frameType == 'PDF') ?
                                        <iframe src={process.env.REACT_APP_INTERNAL_BASE_URL + fileUrl } frameBorder={0}></iframe >
                                        :
                                        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${process.env.REACT_APP_INTERNAL_BASE_URL + fileUrl}`} frameBorder={0}></iframe>
                                }
                            </Modal.Body>
                            : <Modal.Body className='dk-pipedocs-preview-modalbody'>
                                <h1>Document Not Found</h1>
                            </Modal.Body>
                        : null
                }
            </Modal>
        </>
    )
}
export default CandidateDocumentModal;