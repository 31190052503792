import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const PriorityScreeningDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkglobaladmin/admintraining/admins/priority-screening" className={`${pathname.match('/dkglobaladmin/admintraining/admins/priority-screening') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/priorityscreening/preparation" className={`${pathname.match('/dkglobaladmin/admintraining/admins/priorityscreening/preparation') ? 'active' : ''}`}>Preparation</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/priorityscreening/action-tools" className={`${pathname.match('/dkglobaladmin/admintraining/admins/priorityscreening/action-tools') ? 'active' : ''}`}>Action Tools</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/priorityscreening/phone-call" className={`${pathname.match('/dkglobaladmin/admintraining/admins/priorityscreening/phone-call') ? 'active' : ''}`}>Phone Call</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/priorityscreening/send-email" className={`${pathname.match('/dkglobaladmin/admintraining/admins/priorityscreening/send-email') ? 'active' : ''}`}>Send Email</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/priorityscreening/send-sms" className={`${pathname.match('/dkglobaladmin/admintraining/admins/priorityscreening/send-sms') ? 'active' : ''}`}>Send SMS</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default PriorityScreeningDetailLeftPanel;

