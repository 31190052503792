import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import InfoModal from './InfoModal';
import UpdateDetailsModal from './UpdateDetailsModal';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import './jobdetails.scss';
import JobCheckDetailModal from './JobCheckDetailModal';

const JobDetails = () => {

    const [showModalInfo, setInfoModalShow] = useState(false);
    const showInfoModal = () => {
        setInfoModalShow(true)
    }
    const hideInfoModal = () => {
        setInfoModalShow(false)
    }

    const [showModalUpdateDetails, setUpdateDetailModalShow] = useState(false);
    const showUpdateDetailsModal = () => {
        setUpdateDetailModalShow(true)
    }
    const hideUpdateDetailsModal = () => {
        setUpdateDetailModalShow(false)
    }

    const [showJobCheckDetails, setJobCheckModalShow] = useState(false);
    const showJobCheckDetailModal = () => {
        setJobCheckModalShow(true)
    }
    const hideJobCheckDetailModal = () => {
        setJobCheckModalShow(false)
    }
    return (
        <>
            {
                showModalInfo ?
                    <InfoModal handleClose={hideInfoModal} /> : null
            }

            {
                showModalUpdateDetails ?
                    <UpdateDetailsModal handleClose={hideUpdateDetailsModal} /> : null
            }

            {
                showJobCheckDetails ?
                    <JobCheckDetailModal handleClose={hideJobCheckDetailModal} /> : null
            }

            <div className="dk-jobDetailsMain">
                <div className="dk-jobdetails-header">
                    <h3 className="title">TEAMS JOB DETAILS <i className="fas fa-info-circle" onClick={showInfoModal}></i></h3>
                </div>
                <div className="dk-jobDetailsTable">
                    <table className="table dk-processTable table-bordered">
                        <tbody>
                            <tr>
                                <td className="tdBg1">
                                    <div className="heading">Resource Admin</div>
                                </td>
                                <td className="rowBg1">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Languages Check</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg1">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Nationality Check</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg1">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Experince Check</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg1">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Job Skills Check</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg1">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Title</div>
                                    </ContextMenuTrigger>
                                </td>
                                <td className="rowBg1">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Title</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                            </tr>
                            <tr>
                                <td className="tdBg1">
                                    <div className="heading">Recruitment Admin</div>
                                </td>
                                <td className="rowBg2">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Update Profile</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg2">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Full Job SPec</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg2">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Mini Job Spec</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg2">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Send SMS</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg2">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Title</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg2">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Title</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                            </tr>
                            <tr>
                                <td className="tdBg1">
                                    <div className="heading">Recruitment Admin</div>
                                </td>
                                <td className="rowBg3">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Candidate Queries</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg3">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Get Updated CV</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg3">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Not Reachables</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg3">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Phone Availibity</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg3">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Title</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg3">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Title</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                            </tr>
                            <tr>
                                <td className="tdBg1">
                                    <div className="heading">Recruitment Consultant</div>
                                </td>
                                <td className="rowBg4">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Call Candidate</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg4">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Not Reachable</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg4">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Prepare Sendout</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg4">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Send Interview Prep</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg4">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Title</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg4">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Title</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                            </tr>
                            <tr>
                                <td className="tdBg1">
                                    <div className="heading">Account Manage</div>
                                </td>
                                <td className="rowBg5">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Heading Name</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg5">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Heading Name</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg5">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Heading Name</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg5">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Heading Name</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg5">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Title</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg5">
                                    <ContextMenuTrigger>
                                        <div className="title" onClick={showJobCheckDetailModal}>Title</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </>
    )
}

export default JobDetails;
