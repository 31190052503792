import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, FormControl } from 'react-bootstrap'
import Deepak from '../../../../../../assets/images/deepak.png'
import { jobList, updateJob, clearState, jobsDetails } from "../../../../../../slice/jobs/jobsSlice";
// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        {/* &#x25bc; */}
    </a>
));

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');
        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className=""
                    placeholder="Search Text..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toString().toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);
const SearchInputDropDwn = (props) => {
    const dispatch = useDispatch();
    const { usersList } = useSelector(state => state.users)
    const results = props.rcList !== undefined ? usersList.filter(({ id: id1 }) => !props.rcList.some(({ user_id: id2 }) => id2 === id1)) : [];
    const handleAssign = (value) => {
        dispatch(updateJob({ id: value.id, key: 'user_id', value: value }));
    }

    return (
        <>
            <div className="dk-suMMSrchDropDwnCon">
                <Dropdown className="dk-suMMSrchDropDwn">
                    <Dropdown.Toggle as={CustomToggle} className="dk-suMMSrchDropDwnToggle">
                        <img src={props.recImg} alt={props.recName} className="recruiterimg" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu} className="dk-suMMSrchDropDwnMenu">
                        <div className="dk-assignRecruitersCon">
                            <p>Assigned</p>
                            {props.rcList !== undefined ?
                                props.rcList.map((list, index) => (
                                    <Dropdown.Item key={index + 1} className="dk-reclistItem">
                                        <div className="dk-assignRecCon">
                                            <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + list.userInfo.profileImage} alt={list.userInfo.firstName + ' ' + list.userInfo.lastName} className="recruiterimg" />
                                            <span>{list.userInfo.firstName + ' ' + list.userInfo.lastName}</span>
                                            <div className="crossIcon" onClick={() => handleAssign({ id: list.id, type: 'remove', jobid: props.job_id, value: list.user_id })}>
                                                <i className="fas fa-times"></i>
                                            </div>
                                        </div>
                                    </Dropdown.Item>
                                )) : null
                            }
                        </div>
                        <div className="dk-remaingRecruiters">
                            <p>Unassigned</p>
                            {results.map((list, index) => (
                                <Dropdown.Item key={index + 1} className="dk-reclistItem" onClick={() => handleAssign({ id: list.id, type: 'assign', jobid: props.job_id, value: list.id })}>
                                    <div className="dk-unassignRecCon">
                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + list.profileImage} alt={list.firstName + " " + list.lastName} className="recruiterimg" />
                                        <span>{list.firstName + " " + list.lastName}</span>
                                    </div>
                                </Dropdown.Item>
                            ))}
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default SearchInputDropDwn;
