import React from 'react'
import PaymentsPaid from '../../tasks/payments/Paid'

const PaymentTasksTopaid = () => {
  return (
    <>
      <div className="dkg-payment-suMM-mainCon" style={{ marginTop: "160px" }}>
        <PaymentsPaid />
      </div>
    </>

  )
}

export default PaymentTasksTopaid;