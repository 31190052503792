import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';
import "./createnew.css";
import Submit from '../../../../ui/submitButton';
import SelectInstance from '../../../../ui/SelectPicker/SelectInstance'
import Dropdown from 'react-bootstrap/Dropdown';
import UserList from "../../../../ui/userList";
import { showSuccess, showError } from "../../../../utils/messages/messageSlice";
import { addCampaign, clearState } from "../../../../../slice/campaign/campaignSlice";
import SelectDropdown from '../dropdown/SelectDropdown';

const CreateNew = ({ onHide, type }) => {
    const dispatch = useDispatch();
    const { isInsert, isError, errorMessage, jobBoardList, companyList, rolesList, languageList, projectList, categoryList } = useSelector(state => state.campaignAds)
    const [jobTitle, setJobTitle] = useState('');
    const [started, setStarted] = useState('');
    const [expireDate, setExpireDate] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [jobBoard, setJobBoard] = useState('');
    const [roleType, setRoleType] = useState('');
    const [language, setLanguage] = useState('');
    const [leads, setLeads] = useState('');
    const [budget, setBudget] = useState('');
    const [placements, setPlacements] = useState('');
    const [category, setCategory] = useState('');
    const [project, setProject] = useState('');
    const [location, setLocation] = useState('');
    const [userId, setUserId] = useState('0')
    const [userImg, setUserImg] = useState('')
    const [userImgName, setUserImgName] = useState('')
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const selectUser = (e) => {
        setUserImgName(e.target.alt)
        setUserImg(e.target.src)
        setUserId(e.target.dataset.id)
    }

    const handleChangeSelect = (event, data) => {
        const { name, value } = data
        switch (name) {
            case "company":
                setCompanyId(value)
                break;

            case "jobBoard":
                setJobBoard(value)
                break;
            case "category":
                setCategory(value)
                break;

            case "role":
                setRoleType(value)
                break;
            case "language":
                setLanguage(value)
                break;
            case "project":
                setProject(value)
                break;
            default:
                break;
        }
    }

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "jobTitle":
                setJobTitle(value)
                break;
            case "started":
                setStarted(value)
                break;
            case "leads":
                setLeads(value)
                break;
            case "budget":
                setBudget(value)
                break;
            case "placements":
                setPlacements(value)
                break;

            case "location":
                setLocation(value)
                break;
            case "expireDate":
                setExpireDate(value)
                break;
            default:
                break;
        }
    }
    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (userId == '0' || !jobTitle || !companyId || !jobBoard || !roleType || !language || !project || !started || !expireDate || !leads || !budget || !placements || !category || !location) {
            dispatch(showError({ msg: 'Please fill all fields.' }))
        } else {
            if (!loading) {
                setSuccess(false);
                setLoading(true);
                await dispatch(addCampaign({ recruiter: userId, jobTitle: jobTitle, jobBoard: jobBoard, company: companyId, roleType: roleType, language: language, leads: leads, budget: budget, placements: placements, category: category, project: project, Started: started, expired: expireDate, location: location, inTable: type }))
            }
        }
    }

    useEffect(() => {
        if (isInsert) {
            dispatch(showSuccess({ msg: 'Campaigns added successfully.' }))
            dispatch(clearState())
            setJobTitle('');
            setStarted('');
            setLeads('');
            setBudget('');
            setPlacements('');
            setLocation('');
            setCategory('');
            setExpireDate('');
            setJobBoard('');
            setRoleType('');
            setLanguage('');
            setUserId('0')
            onHide()
            setSuccess(true);
            setLoading(false);
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }
    }, [isInsert, isError])

    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-compaginsCreateNewPop"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create New</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-compaginsCreateNew">
                    <form>
                        <div className="row">
                            <div className="col-md-3 mr-auto">
                                <div className="form-group">
                                    <label htmlFor="">Recruiter</label>
                                    <input type="hidden" id="userId" value="0" />
                                    <Dropdown className="dk-globalUserDropdown">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {
                                                (userId !== '0') ? <img src={userImg} className="select-user-img" /> : <i className="fas fa-user"></i>
                                            }
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className="d-flex">
                                                <UserList onClick={selectUser} />
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-9">
                                <div className="form-group">
                                    <label>Job Title</label>
                                    <input type="text" className="form-control broderContrl" name="jobTitle" value={jobTitle} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Started</label>
                                    <input type="date" className="form-control broderContrl" name="started" value={started} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Expired</label>
                                    <input type="date" className="form-control broderContrl" name="expireDate" value={expireDate} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Job Board</label>
                                    <div className="dk-camcreateNewFilter">
                                        <SelectDropdown list={jobBoardList} type="jobBoard" placeholder="Select" onChange={handleChangeSelect} name='jobBoard' />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Leads</label>
                                    <input type="number" className="form-control broderContrl" defaultValue="" name="leads" value={leads} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Budget</label>
                                    <input type="number" className="form-control broderContrl" defaultValue="" name="budget" value={budget} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Sendouts</label>
                                    <input type="number" className="form-control broderContrl" defaultValue="" name="placements" value={placements} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Category</label>
                                    <div className="dk-camcreateNewFilter">

                                        <SelectDropdown list={categoryList} type="category" placeholder="Select" onChange={handleChangeSelect} name='category' />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Company</label>
                                    <div className="dk-camcreateNewFilter">
                                        <SelectDropdown list={companyList} type="company" placeholder="Select" onChange={handleChangeSelect} name='company' />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Project</label>
                                    <div className="dk-camcreateNewFilter">
                                        {/* <SelectInstance options={projectList} value={project} name="Select" onChange={handleChangeSelect} /> */}
                                        <SelectDropdown list={projectList} type="project" placeholder="Select" onChange={handleChangeSelect} name='project' />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Role Type</label>
                                    <div className="dk-camcreateNewFilter">
                                        <SelectDropdown list={rolesList} type="role" placeholder="Select" onChange={handleChangeSelect} name='role' />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Language</label>
                                    <div className="dk-camcreateNewFilter">
                                        <SelectDropdown list={languageList} type="language" placeholder="Select" onChange={handleChangeSelect} name='language' />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Location</label>
                                    <div className="dk-camcreateNewFilter">
                                        <input type="text" className="form-control broderContrl" defaultValue="" name="location" value={location} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="dk-adstrackbtnCont">
                                    <Submit txt="ADD" loading={loading} success={success} onClick={handleButtonClick} className="dk-adstrackaddbtn" />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default CreateNew;
