import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import Submenu from '../../subMenu/ProjectsSubmenu';
import './jobspecsmainmenu.scss';

const KnowleadgeBaseMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-recruitmentNavbar">
                <Route>
                    <ul>
                        <li><Link to="/projects/dashboard" className={`${(segment2 === 'dashboard') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="/projects/deepak/summary" className={`${(segment2 === 'deepak') ? 'gray-active' : ''}`}>Deepak</Link></li>
                        <li><Link to="/projects/mariana/summary" className={`${(segment2 === 'mariana') ? 'gray-active' : ''}`}>Mariana</Link></li>
                        {/* <li><Link to="/projects/marianatwo/project_1" className={(segment2 === "marianatwo") ? 'gray-active' : ''}>Mariana</Link></li> */}
                        <li><Link to="/projects/elis/summary" className={(segment2 === "elis") ? 'gray-active' : ''}>Elis</Link></li>
                        <li><Link to="/projects/filip/summary" className={(segment2 === "filip") ? 'gray-active' : ''}>New Hire</Link></li>
                        <li><Link to="/projects/tehreem/summary" className={(segment2 === "tehreem") ? 'gray-active' : ''}>New Hire</Link></li>
                        <li><Link to="/projects/luiza/summary" className={`${(segment2 === 'luiza') ? 'gray-active' : ''}`}>New Hire</Link></li>
                    </ul>
                </Route>
            </div>
            <Submenu path={pathname} />
        </React.Fragment>
    );
}

export default KnowleadgeBaseMenu;