import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import './style.scss';
import Modal from 'react-bootstrap/Modal';
import Submit from "../../../../../ui/submitButton";

const DeleteRow = ({ deleteItem, loading, success }) => {   
    const { isTaskDelete } = useSelector(state => state.bankingBooks);

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
    }

    useEffect(() => {
        if (isTaskDelete) {
            setShow(false);
        }
       
    }, [isTaskDelete])

    return (
        <React.Fragment>           
           
            <div className="batch-actions-item dkg-delete-tab-12" onClick={handleShow}>
                <span><i className="far fa-trash-alt"></i></span>
                Delete
            </div>
            <Modal className="dk-mlAddNewModal dk-deleteModal" show={show} onHide={handleClose}>
                <Modal.Body className="dk-mlAddModalBody">
                    <p>Are you sure wants to Delete these records ?</p>
                    <div className="d-flex justify-content-end">
                        <button onClick={handleClose}>No</button>
                        <Submit txt="Yes" loading={loading} success={success} onClick={deleteItem} position="justify-content-center" className="dk-yesBtn" />
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default DeleteRow;