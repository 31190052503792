import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/resourcedepartments/ResourceHrMenu';

const ResourceHrLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" linkcolor="#3c4c62" mainheaderTitle=" HR" textcolor="#d58b5d" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default ResourceHrLayout;
