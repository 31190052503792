import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const PreparationDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/training/managers/preparation" className={`${pathname.match('/training/managers/preparation') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/training/managers/tools-login" className={`${pathname.match('/training/managers/tools-login') ? 'active' : ''}`}>Tools to Login</Link></li>

                    <li><Link to="/training/managers/team-greeting" className={`${pathname.match('/training/managers/team-greeting') ? 'active' : ''}`}>Team Greeting</Link></li>

                    <li><Link to="/training/managers/dkg-crm-tabs" className={`${pathname.match('/training/managers/dkg-crm-tabs') ? 'active' : ''}`}>DKG CRM Tabs</Link></li>

                    <li><Link to="/training/managers/review-mailbox" className={`${pathname.match('/training/managers/review-mailbox') ? 'active' : ''}`}>Review Mailbox</Link></li>

                    <li><Link to="/training/managers/review-live-jobs" className={`${pathname.match('/training/managers/review-live-jobs') ? 'active' : ''}`}>Review Live Jobs</Link></li>

                    <li><Link to="/training/managers/review-pipelines" className={`${pathname.match('/training/managers/review-pipelines') ? 'active' : ''}`}>Review Pipelines</Link></li>

                    <li><Link to="/training/managers/review-workplan" className={`${pathname.match('/training/managers/review-workplan') ? 'active' : ''}`}>Review Workplan</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default PreparationDetailLeftPanel;

