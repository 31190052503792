import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
//import { makeStyles } from "@material-ui/core/styles";
import "./checkboxpopup.scss";
import { FaTimes } from "react-icons/fa";
import $ from "jquery";
import SubmitButton from "../../../../ui/submitButton";
import {
  getCMS,
  deleteCanditateAccount,
  clearState,
} from "../../../../../slice/cmsSlice";
import { useDispatch, useSelector } from "react-redux";
import { showError } from "../../../../utils/messages/messageSlice";
import { deleteCandidateAc } from "../../../../../slice/candidates/candidatesSlice";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& > *": {
//       marginTop: theme.spacing(0),
//     },
//   },
// }));
const CheckboxPopup = ({ checkbbox }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const [isActive, setIsActive] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const [otherReasontext, setOtherReasonData] = useState("");

  const toggleCheckbox = (e) => {
    checkbbox("blank");
    setOtherReasonData("");
    var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
    if (checkedBoxCount > 0) {
      //setCountCheck(checkedBoxCount);
      setIsActive(true);
    } else {
      //setCountCheck(0);
      setIsActive(false);
    }

    var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
    var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
    if (checkedBoxCount < totalCheckboxes) {
      //setCheckBtnTxt("Check All");
    }
    if (checkedBoxCount == totalCheckboxes) {
      //setCheckBtnTxt("Uncheck All");
    }
  };
  const handleClose = () => {
    checkbbox("blank");
    setIsActive(!isActive);
    var checkbox = document.getElementById("myCheckbox");
    checkbox.checked = !checkbox.checked;

    var radioButtons = document.querySelectorAll(
      'input[type="radio"][name="deleteAc"]'
    );
    radioButtons.forEach(function (radioButton) {
      radioButton.checked = false;
    });
    setOtherReasonData("");
  };

  const dispatch = useDispatch();
  const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);
  const [details, setDetails] = useState("");
  const [deleteAcchecked, setChecked] = React.useState(true);

  useEffect(() => {
    dispatch(getCMS({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setDetails(cmsList[9]);
    }
  }, [isSuccess]);

  const otherReason = () => {
    const t = document.querySelector('input[name="deleteAc"]:checked').value;
    if (t === "other") {
      setIsOther(true);
    } else {
      setOtherReasonData(t);
      setIsOther(false);
    }
  };

  const SubmitBtn = async () => {
    if (otherReasontext === "") {
      dispatch(showError({ msg: "Please Select reason for delete account." }));
      return false;
    } else {
      await dispatch(
        deleteCandidateAc({
          id: userInfo.id,
          key: "accountDeletedReason",
          value: otherReasontext,
        })
      );
      await dispatch(
        deleteCanditateAccount({
          canid: userInfo.canId,
          terms: deleteAcchecked,
          name: userInfo.firstName + " " + userInfo.lastName,
          from: userInfo.email,
          phone: userInfo.phone1,
          subject: "Delete Account-" + userInfo.firstName,
          message: otherReasontext,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <div className="dk-smsTableCheckbox">
        <input
          type="checkbox"
          className="chkCandidate"
          id="myCheckbox"
          name="chkCandidate[]"
          onChange={(e) => toggleCheckbox()}
        />
      </div>
      <div className={`dk-checkbox-popup-234 ${isActive ? "activePopup" : ""}`}>
        <div className="dkg-checkbox-header">
          <div className="dkg-checkbox-leftCon">
            <div className="dkg-checkbox-title">Delete Account</div>
          </div>
          <div className="dkg-checkbox-rightCon">
            <span className="dkg-cross-icon" onClick={handleClose}>
              <FaTimes />
            </span>
          </div>
        </div>
        <div className="dkg-checkbox-body">
          <div className="dkg-deactive-acc-rightCon">
            <h4 className="dkg-deactive-acc-title">Summary</h4>
            <p className="dkg-deactive-desc">
              {details != "" ? details.description : ""}
            </p>
          </div>
          <div className="dkg-deactive-acc-leftCon">
            <p className="dkg-resion-title">Reason for Delete</p>
            <ul className="dkg-checkbox-body-ul">
              <li className="dkg-checkbox-body-li">
                <div className="dkg-list-title">
                  I am getting too many emails from DK Global.
                </div>
                <div className="dkg-list-radio">
                  <input
                    type="radio"
                    className="dkg-deactive-radio"
                    id="first"
                    name="deleteAc"
                    value="I am getting too many emails from DK Global."
                    onChange={otherReason}
                  />
                </div>
              </li>
              <li className="dkg-checkbox-body-li">
                <div className="dkg-list-title">
                  I am no longer looking for a job at the moment.
                </div>
                <div className="dkg-list-radio">
                  <input
                    type="radio"
                    className="dkg-deactive-radio"
                    id="second"
                    name="deleteAc"
                    value="I am no longer looking for a job at the moment."
                    onChange={otherReason}
                  />
                </div>
              </li>
              <li className="dkg-checkbox-body-li">
                <div className="dkg-list-title">
                  I don't get a response to the jobs I've applied for
                </div>
                <div className="dkg-list-radio">
                  <input
                    type="radio"
                    className="dkg-deactive-radio"
                    id="second"
                    name="deleteAc"
                    value="I do not get a response to the jobs I've applied for"
                    onChange={otherReason}
                  />
                </div>
              </li>
              <li className="dkg-checkbox-body-li">
                <div className="dkg-list-title">
                  I am not happy with the services of DK Global Recruitemnt
                </div>
                <div className="dkg-list-radio">
                  <input
                    type="radio"
                    className="dkg-deactive-radio"
                    id="second"
                    name="deleteAc"
                    value="I am not happy with the services of DK Global Recruitemnt"
                    onChange={otherReason}
                  />
                </div>
              </li>
              <li className="dkg-checkbox-body-li">
                <div className="dkg-list-title">I do not want to disclose</div>
                <div className="dkg-list-radio">
                  <input
                    type="radio"
                    className="dkg-deactive-radio"
                    id="second"
                    name="deleteAc"
                    value="other"
                    onChange={otherReason}
                  />
                </div>
              </li>
            </ul>

            {isOther ? (
              <>
                <div className="dkg-detactive-textareaCon">
                  <textarea
                    className="dkg-detactive-textarea"
                    placeholder="Write the reason here"
                    onChange={(e) => setOtherReasonData(e.target.value)}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="dkg-checkbox-footer">
          <div className="dkg-deactive-btnCon">
            <Link
              to="#"
              className="dkg-ancel-btn btn mr-2"
              onClick={handleClose}
            >
              Cancel
            </Link>
            <SubmitButton
              txt="Delete"
              className="dkg-delete-btn btn"
              onClick={SubmitBtn}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CheckboxPopup;
