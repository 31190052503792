import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './trainingmodule.scss';

const InterviewsManagerTraining = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalAdminTrainingMainMenu dk-trainingModuleNav">
                <Route>
                    <ul>
                        <li><Link to="/dkglobaladmin/admintraining/managers" className={`${(segment3 == 'managers') ? 'active' : ''}`}>Interviews Manager</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default InterviewsManagerTraining;