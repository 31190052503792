import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { useSelector } from "react-redux";
import "./dropdownFilter.scss";

const StatusFilter = ({ list, selectOtherFilter, caseStatusFilterDDLText }) => {   
    const { caseStatusList } = useSelector(state => state.common);
    return (
        <React.Fragment>
            <DropdownButton className="dropdownFilter" id="dropdown-basic-button" title={caseStatusFilterDDLText}>
                {
                    caseStatusList.map((statusItem, index) =>
                        <Dropdown.Item key={`case-status-id${index}`} href="#" style={{ backgroundColor: statusItem.bgColor, color: statusItem.textColor }} data-type="caseStatus" data-value={statusItem.value} onClick={selectOtherFilter}>
                            {statusItem.value}
                            <span className="dropdown-filter-count">
                                {
                                    list.filter(function (val) {
                                        return val.original.caseStatus === statusItem.value;
                                    }).length
                                }
                            </span>
                        </Dropdown.Item>
                    )
                }
            </DropdownButton>
        </React.Fragment>
    )
}

export default StatusFilter;