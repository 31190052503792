import React from 'react';
//import LeftPanel from '../leftpanel/LeftPanel';
import JobProfile from "../../../dashboard/jobProfiles/index";
import './dayclosingmoods.scss';


const WorkplanJobProfile = () => {
    return (
        <React.Fragment>
            <div className="dk-dayclsoing-Main dk-dayclsoing-DetailMain" style={{ backgroundColor: "#fff" }}>
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailRightPanel dk-workPlanDailyTracking">
                            <div className="dk-detailTabsMain">
                                <div className="dk-trainingInformation-cols">
                                    <div className="dk-payDetailRightPanel">
                                        <div className="dk-workPlanJobProfile-cols">
                                            <JobProfile />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default WorkplanJobProfile;

