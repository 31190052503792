import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import './completion.scss';
import AddNewModal from './AddNewModal';
import EditNewModal from './EditNewModal';
import DeleteModal from './DeleteModal';
import AddNumberModal from './AddNumberModal';

const CompletionOverview = () => {
    const [showAddNewModal, setShowAddNew] = useState(false)
    const [showEditNewModal, setEditAddNew] = useState(false)
    const [showDeleteModal, setDeleteModal] = useState(false)
    const [showAddNumberModal, setAddNumberModal] = useState(false)

    const ShowAddNew = () => {
        setShowAddNew(true)
    }

    const EditAddNew = () => {
        setEditAddNew(true)
    }

    const AddNewNumber = () => {
        setAddNumberModal(true)
    }

    const ViewDeleteModal = () => {
        setDeleteModal(true)
    }

    const HideAddNew = () => {
        setShowAddNew(false)
    }

    const HideEditNew = () => {
        setEditAddNew(false)
    }

    const HideDeleteModal = () => {
        setDeleteModal(false)
    }

    const HideAddNumberModal = () => {
        setAddNumberModal(false)
    }

    return (
        <React.Fragment>

            {
                showAddNewModal ?
                    <AddNewModal handleClose={HideAddNew} /> : null
            }

            {
                showEditNewModal ?
                    <EditNewModal handleClose={HideEditNew} /> : null
            }

            {
                showDeleteModal ?
                    <DeleteModal handleClose={HideDeleteModal} /> : null
            }

            {
                showAddNumberModal ?
                    <AddNumberModal handleClose={HideAddNumberModal} /> : null
            }

            <div className="dk-completionMain">
                <div className="dk-completionHead">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-searchBox">
                            <input type="text" className="form-control" placeholder="Search..." name="" id="" />
                            <div className="dk-userImg mr-2">
                                <span><i className="fas fa-times"></i></span>
                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/1582733069.jpeg" className="img-fluid img-circle" alt="" />
                            </div>
                            <div className="dk-userImg">
                                <span><i className="fas fa-times"></i></span>
                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5a701dc2182ac.png" className="img-fluid img-circle" alt="" />
                            </div>
                        </div>
                        <h3 className="title">TRAINING PROGRESS STATUS</h3>
                        <div className="dk-rightPanel">
                            <button onClick={ShowAddNew}><i className="fas fa-plus"></i> Add New</button>
                        </div>
                    </div>
                </div>
                <div className="dk-completionTableMain">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Sno.</th>
                                    <th>Start Date</th>
                                    <th>Finish Date</th>
                                    <th>Trainer</th>
                                    <th>Trainee</th>
                                    <th>DKG Job Title</th>
                                    <th>All Modules</th>
                                    <th>Completed</th>
                                    <th>In Progress</th>
                                    <th>To Do</th>
                                    <th>Training Type</th>
                                    <th>Current Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>20 July 2021</td>
                                    <td>15 Dec 2021</td>
                                    <td>
                                        <div className="dk-userImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5a701dc2182ac.png" className="img-fluid img-circle" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-userImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/1582733069.jpeg" className="img-fluid img-circle" alt="" />
                                        </div>
                                    </td>
                                    <td>Developer</td>
                                    <td><span className="numberText" onClick={AddNewNumber}>6</span></td>
                                    <td><span className="numberText" onClick={AddNewNumber}>10</span></td>
                                    <td><span className="numberText" onClick={AddNewNumber}>4</span></td>
                                    <td><span className="numberText" onClick={AddNewNumber}>8</span></td>
                                    <td>Full Training</td>
                                    <td>
                                        <div className="dk-completionDropdown">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic" style={{ background: '#3a9469', color: '#fff' }}>
                                                    Completed
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" style={{ background: '#26597d', color: '#fff' }}>To Start</Dropdown.Item>
                                                    <Dropdown.Item href="#" style={{ background: '#ffa500', color: '#333' }}>In Progress</Dropdown.Item>
                                                    <Dropdown.Item href="#" style={{ background: '#3a9469', color: '#fff' }}>Complete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-action">
                                            <Link to="#" title="Edit" onClick={EditAddNew} className="dk-editBtn"><i className="far fa-edit"></i></Link>

                                            <Link to="#" title="Delete" onClick={ViewDeleteModal} className="dk-deleteBtn"><i className="far fa-trash-alt"></i></Link>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CompletionOverview;

