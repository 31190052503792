import React from 'react'
import '../mypipelines.scss'
import { Button, ButtonGroup } from 'react-bootstrap'
import RecruiterFilter from '../tableFilters/recuriters'
import CaseStausFilter from '../tableFilters/statusfilter'
import RightModalFilter from '../tableFilters/rightModalFilter'
import CaseStatusDropdown from '../dropdown/casestatus'
import CasestageFilter from '../dropdown/casestage'
import DuplicateCheckModal from '../modal/duplicate/DuplicateCheck'
import TrickFilter from '../tableFilters/trickdropdown/TrickFilter'
import ProfileIndicator from '../tableFilters/profileIndicator'
import CandidateFilter from '../tableFilters/candidatefilter'
import FlagFilter from '../tableFilters/flag'
import TrickFilterDropDown from '../dropdown/trickdropdown/TrickFilter'
import ProfileIndicatorDropDown from '../dropdown/profileIndicator';
import CandidatefilterDropDown from '../dropdown/candidatefilter';
import FlagFilterDropdown from '../dropdown/flag';
import AccountManager from '../tableFilters/users/AccountManager'
import PaginationComp from '../pagination'


const index = () => {
    return (
        <>
            <div className='container-fluid dkg-mypipelines-pageCon12'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='row dkg-mypipelinePageHead12'>
                            <div className='col-md-3 dkg-pipelineLeftCols12'>
                                <div className='dkg-mypipelineleftCon'>
                                    <div className="dk-mailshotsSearch mr-2">
                                        <div className="input-group">
                                            <input className="form-control" placeholder="Search ..." value="" />
                                            <div className="input-group-append">
                                                <button className="btn btn-secondary" type="button"><i className="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mr-2 dkg-rec-tableFilterCon12'>
                                        <RecruiterFilter title="AM" />
                                    </div>
                                    <div className='mr-2 dkg-rec-tableFilterCon12'>
                                        <RecruiterFilter title="RC" />
                                    </div>
                                    <div className='mr-2 dkg-rec-tableFilterCon12'>
                                        <RecruiterFilter title="RA" />
                                    </div>
                                    <div className="mr-2 dkg-relodbtnCon"><button className="dk-reoderBtn"><i className="fas fa-sync-alt"></i></button></div>
                                </div>
                            </div>
                            <div className="col-md-6 pl-0 pr-0 dkg-mypipelineCenterCols12">
                                <div className='dk-filterTagsCon'>
                                    {/* <ButtonGroup className='dk-filterbtnGrp mr-2'>
                                        <Button className="dk-filterbtntext">New Case</Button>
                                        <Button className="dk-filterbtnicon" ><i className="fas fa-times"></i></Button>
                                    </ButtonGroup> */}
                                    <div className='dk-pipeline-pagetitle'>New Case - <span className="countNum">
                                        12
                                    </span> </div>
                                </div>
                            </div>
                            <div className="col-md-3 dkg-pipelineRightCols12">
                                <div className="dkg-pipelineright12">
                                    <div className="dk-newCVRightFilter mr-2">
                                        <RightModalFilter />
                                    </div>
                                    <div className="dk-duplicatefilterCon mr-2">
                                        <DuplicateCheckModal />
                                    </div>
                                    <div className="dk-trickfilterIcon mr-2">
                                        <TrickFilter />
                                    </div>
                                    <div className="mr-2 dk-pipe-prfindicon-Con">
                                        <ProfileIndicator />
                                    </div>
                                    <div className=' mr-2 dkg-candfilterIconCon'>
                                        <CandidateFilter />
                                    </div>
                                    <div className="dk-flagfilterIcon mr-2">
                                        <div className='dk-useFilterNumCon'>
                                            <FlagFilter />
                                        </div>
                                    </div>
                                    <div className='dkg-mypipeline-casefileterCon'>
                                        <CaseStausFilter />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='dkg-mypipeline-TableCon12 table-responsive'>
                            <table className='dkg-mypipeline-Table table table-bordered'>
                                <thead>
                                    <tr >
                                        <th>ID &amp; Preview</th>
                                        <th></th>
                                        <th>AM</th>
                                        <th>RC</th>
                                        <th>RA</th>
                                        <th>Company</th>
                                        <th>Job Title</th>
                                        <th>Candidate Name</th>
                                        <th>Case Status</th>
                                        <th>Case Progress</th>
                                        <th>Case Stage</th>
                                        <th>Comment</th>
                                        <th>Case Updated</th>
                                        <th>Case Created</th>
                                        <th>Category</th>
                                        <th>Full Fee</th>
                                        <th>Refuse/Cr</th>
                                        <th>Bonus</th>
                                        <th>Final Fee</th>
                                        <th>Job Language</th>
                                        <th>Project</th>
                                        <th>Role Type</th>
                                        <th>CV Source</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="dkg-mypipleFilterCols pl-2 pr-2">
                                                <span className="dk-chevron-rightIcon"><i className="fa fa-chevron-right" data-canid="49190"></i>49190</span>
                                                <span className="dk-checkinputIcon"><input type="checkbox" className="chkCandidate" name="chkCandidate[]" data-id="46945" value="46945" /></span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-mypipeline-blankcols pl-2 pr-2'>
                                                <TrickFilterDropDown />
                                                <ProfileIndicatorDropDown />
                                                <CandidatefilterDropDown />
                                                <FlagFilterDropdown />
                                            </div>
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            dkglobal
                                        </td>
                                        <td>
                                            Portuguese Ads Labelling 5A
                                        </td>
                                        <td>
                                            Deepak Kumar
                                        </td>
                                        <td>
                                            <CaseStatusDropdown />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon">0</span>
                                            </span>
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>None</td>
                                        <td>1500</td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            English
                                        </td>
                                        <td>Confidential</td>
                                        <td>HR Helpdesk</td>
                                        <td>Jooble</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-mypipleFilterCols pl-2 pr-2">
                                                <span className="dk-chevron-rightIcon"><i className="fa fa-chevron-right" data-canid="49190"></i>49190</span>
                                                <span className="dk-checkinputIcon"><input type="checkbox" className="chkCandidate" name="chkCandidate[]" data-id="46945" value="46945" /></span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-mypipeline-blankcols pl-2 pr-2'>
                                                <TrickFilterDropDown />
                                                <ProfileIndicatorDropDown />
                                                <CandidatefilterDropDown />
                                                <FlagFilterDropdown />
                                            </div>
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            dkglobal
                                        </td>
                                        <td>
                                            Portuguese Ads Labelling 5A
                                        </td>
                                        <td>
                                            Deepak Kumar
                                        </td>
                                        <td>
                                            <CaseStatusDropdown />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon">0</span>
                                            </span>
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>None</td>
                                        <td>1500</td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            English
                                        </td>
                                        <td>Confidential</td>
                                        <td>HR Helpdesk</td>
                                        <td>Jooble</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-mypipleFilterCols pl-2 pr-2">
                                                <span className="dk-chevron-rightIcon"><i className="fa fa-chevron-right" data-canid="49190"></i>49190</span>
                                                <span className="dk-checkinputIcon"><input type="checkbox" className="chkCandidate" name="chkCandidate[]" data-id="46945" value="46945" /></span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-mypipeline-blankcols pl-2 pr-2'>
                                                <TrickFilterDropDown />
                                                <ProfileIndicatorDropDown />
                                                <CandidatefilterDropDown />
                                                <FlagFilterDropdown />
                                            </div>
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            dkglobal
                                        </td>
                                        <td>
                                            Portuguese Ads Labelling 5A
                                        </td>
                                        <td>
                                            Deepak Kumar
                                        </td>
                                        <td>
                                            <CaseStatusDropdown />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon">0</span>
                                            </span>
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>None</td>
                                        <td>1500</td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            English
                                        </td>
                                        <td>Confidential</td>
                                        <td>HR Helpdesk</td>
                                        <td>Jooble</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-mypipleFilterCols pl-2 pr-2">
                                                <span className="dk-chevron-rightIcon"><i className="fa fa-chevron-right" data-canid="49190"></i>49190</span>
                                                <span className="dk-checkinputIcon"><input type="checkbox" className="chkCandidate" name="chkCandidate[]" data-id="46945" value="46945" /></span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-mypipeline-blankcols pl-2 pr-2'>
                                                <TrickFilterDropDown />
                                                <ProfileIndicatorDropDown />
                                                <CandidatefilterDropDown />
                                                <FlagFilterDropdown />
                                            </div>
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            dkglobal
                                        </td>
                                        <td>
                                            Portuguese Ads Labelling 5A
                                        </td>
                                        <td>
                                            Deepak Kumar
                                        </td>
                                        <td>
                                            <CaseStatusDropdown />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon">0</span>
                                            </span>
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>None</td>
                                        <td>1500</td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            English
                                        </td>
                                        <td>Confidential</td>
                                        <td>HR Helpdesk</td>
                                        <td>Jooble</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-mypipleFilterCols pl-2 pr-2">
                                                <span className="dk-chevron-rightIcon"><i className="fa fa-chevron-right" data-canid="49190"></i>49190</span>
                                                <span className="dk-checkinputIcon"><input type="checkbox" className="chkCandidate" name="chkCandidate[]" data-id="46945" value="46945" /></span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-mypipeline-blankcols pl-2 pr-2'>
                                                <TrickFilterDropDown />
                                                <ProfileIndicatorDropDown />
                                                <CandidatefilterDropDown />
                                                <FlagFilterDropdown />
                                            </div>
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            dkglobal
                                        </td>
                                        <td>Portuguese Ads Labelling 5A	</td>
                                        <td>
                                            Deepak Kumar
                                        </td>
                                        <td>
                                            <CaseStatusDropdown />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon">0</span>
                                            </span>
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>None</td>
                                        <td>1500</td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            English
                                        </td>
                                        <td>Confidential</td>
                                        <td>HR Helpdesk</td>
                                        <td>Jooble</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-mypipleFilterCols pl-2 pr-2">
                                                <span className="dk-chevron-rightIcon"><i className="fa fa-chevron-right" data-canid="49190"></i>49190</span>
                                                <span className="dk-checkinputIcon"><input type="checkbox" className="chkCandidate" name="chkCandidate[]" data-id="46945" value="46945" /></span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-mypipeline-blankcols pl-2 pr-2'>
                                                <TrickFilterDropDown />
                                                <ProfileIndicatorDropDown />
                                                <CandidatefilterDropDown />
                                                <FlagFilterDropdown />
                                            </div>
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            dkglobal
                                        </td>
                                        <td>Portuguese Ads Labelling 5A	</td>
                                        <td>
                                            Deepak Kumar
                                        </td>
                                        <td>
                                            <CaseStatusDropdown />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon">0</span>
                                            </span>
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>None</td>
                                        <td>1500</td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            English
                                        </td>
                                        <td>Confidential</td>
                                        <td>HR Helpdesk</td>
                                        <td>Jooble</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-mypipleFilterCols pl-2 pr-2">
                                                <span className="dk-chevron-rightIcon"><i className="fa fa-chevron-right" data-canid="49190"></i>49190</span>
                                                <span className="dk-checkinputIcon"><input type="checkbox" className="chkCandidate" name="chkCandidate[]" data-id="46945" value="46945" /></span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-mypipeline-blankcols pl-2 pr-2'>
                                                <TrickFilterDropDown />
                                                <ProfileIndicatorDropDown />
                                                <CandidatefilterDropDown />
                                                <FlagFilterDropdown />
                                            </div>
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            dkglobal
                                        </td>
                                        <td>
                                            Portuguese Ads Labelling 5A
                                        </td>
                                        <td>
                                            Deepak Kumar
                                        </td>

                                        <td>
                                            <CaseStatusDropdown />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon">0</span>
                                            </span>
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>None</td>
                                        <td>1500</td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            English
                                        </td>
                                        <td>Confidential</td>
                                        <td>HR Helpdesk</td>
                                        <td>Jooble</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-mypipleFilterCols pl-2 pr-2">
                                                <span className="dk-chevron-rightIcon"><i className="fa fa-chevron-right" data-canid="49190"></i>49190</span>
                                                <span className="dk-checkinputIcon"><input type="checkbox" className="chkCandidate" name="chkCandidate[]" data-id="46945" value="46945" /></span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-mypipeline-blankcols pl-2 pr-2'>
                                                <TrickFilterDropDown />
                                                <ProfileIndicatorDropDown />
                                                <CandidatefilterDropDown />
                                                <FlagFilterDropdown />
                                            </div>
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            dkglobal
                                        </td>
                                        <td>
                                            Portuguese Ads Labelling 5A
                                        </td>
                                        <td>
                                            Deepak Kumar
                                        </td>
                                        <td>
                                            <CaseStatusDropdown />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon">0</span>
                                            </span>
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>None</td>
                                        <td>1500</td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            English
                                        </td>
                                        <td>Confidential</td>
                                        <td>HR Helpdesk</td>
                                        <td>Jooble</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-mypipleFilterCols pl-2 pr-2">
                                                <span className="dk-chevron-rightIcon"><i className="fa fa-chevron-right" data-canid="49190"></i>49190</span>
                                                <span className="dk-checkinputIcon"><input type="checkbox" className="chkCandidate" name="chkCandidate[]" data-id="46945" value="46945" /></span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-mypipeline-blankcols pl-2 pr-2'>
                                                <TrickFilterDropDown />
                                                <ProfileIndicatorDropDown />
                                                <CandidatefilterDropDown />
                                                <FlagFilterDropdown />
                                            </div>
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            <AccountManager />
                                        </td>
                                        <td>
                                            dkglobal
                                        </td>
                                        <td>
                                            Portuguese Ads Labelling 5A
                                        </td>
                                        <td>
                                            Deepak Kumar
                                        </td>
                                        <td>
                                            <CaseStatusDropdown />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <CasestageFilter />
                                        </td>
                                        <td>
                                            <span className="commentBoxCon">
                                                <span className="numCon">0</span>
                                            </span>
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>
                                            27 Apr, 17:09
                                        </td>
                                        <td>None</td>
                                        <td>1500</td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            <input className="form-control" value="0" />
                                        </td>
                                        <td>
                                            English
                                        </td>
                                        <td>Confidential</td>
                                        <td>HR Helpdesk</td>
                                        <td>Jooble</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">9000</td>
                                        <td colSpan="1">0</td>
                                        <td colSpan="1">0</td>
                                        <td colSpan="1">0</td>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">&nbsp;</td>
                                        <td colSpan="1">&nbsp;</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <PaginationComp />
                    </div>
                </div>
            </div>
        </>
    )
}

export default index