import React, { useEffect, useState } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { FaRegCalendarAlt } from 'react-icons/fa';

import styled from 'styled-components';

const MonthFilterDropdown = ({ list, caseStatusFilterText, onSelectStatusFilter, tableType,bgMonthcolor,monthcolor }) => {
     
     const [dkgMonthselector, setDkgMonthselector] = useState('')
   
    useEffect(()=>{
        if(bgMonthcolor !='#fff'){
            setDkgMonthselector('dkg-monthselector')
        }else{
            setDkgMonthselector('')
        }
    },[bgMonthcolor])

    const TaskForDropdown = styled.div`
        display: flex;
        background-color: #eaeaea;
        height: 32px;
        align-items: center;
        padding: 0;
        border: 1px solid #ccc !important;
    .dkg-bonus-righticon-Con{
        display: flex;
        background-color: #eaeaea;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #ccc !important;
        svg {
            color: #918f8f;
        }
    }
    .dropdown{
        display: flex;
        justify-content: center;
        align-items: center;
        .dropdown-toggle{
            padding: 0;
            color: #333;
            text-transform: capitalize !important;
            box-shadow: none;
            background-color: #eaeaea !important;
            border-radius: 3px;
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            margin: 0;
            margin-bottom: 0;
            text-align: left;
            padding-left: 0;
            display: flex;
            align-items: center;
            height: 30px !important;
            min-width: 100px !important;
            border: none !important;
            color: #848484 !important;;
            &::after {
                color: #848484;
                position: absolute;
                right: 3px;
                top: 14px;
            }
            &.btn-primary:not([disabled]):not(.disabled):active,
               &.btn-primary:not([disabled]):not(.disabled).active, 
               .show>.btn-primary.dropdown-toggle {
                background-color: #eaeaea !important;
                border: 1px solid #ccc !important;
                 color: #333 !important;
               }
        }
        .dropdown-menu{
            min-width: 130px !important;
            min-width: 130px !important;
            border: none;
            border-radius: 0;
            padding: 0;
            width: 100%;
            background-color: #fff;
            transform: inherit !important;
            top: 37px !important;
            left: -30px !important;
            .dropdown-item{
                align-items: center;
                background-color: #fff;
                border: none;
                border-bottom: 1px solid transparent;
                display: flex;
                font-size: 13px;
                position: relative;
                text-transform: capitalize;
                width: 100%; 
                padding: 5px 7px;
                span.dropdown-filter-count {
                    align-items: center;
                    background-color: transparent;
                    border-radius: 2px;
                    color: #629bd3;
                    display: flex;
                    font-size: 13px;
                    justify-content: center;
                    padding: 1px 0;
                    position: absolute;
                    right: 4px;
                    text-align: center;
                    top: 6px;
                    width: 20px;
                }                  
            }
        }
    .crossUserFilter {
        right: -2px  !important;
    }
`;

    return (

        <React.Fragment>
            <TaskForDropdown>
                <span className='dkg-bonus-righticon-Con'><FaRegCalendarAlt /></span>
                <DropdownButton className={`dk-cli-status-dropdown dkg-monthfilter-dropdown ${dkgMonthselector}`} title={caseStatusFilterText} onSelect={onSelectStatusFilter} style={{ background: bgMonthcolor, color: monthcolor }}>
                    <Dropdown.Item eventKey="January" href="#" className="dk-activeBtn" style={{ background: '#884c5e', color: '#fff' }}>
                        January
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.month == 'January';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="February" href="#" className="dk-inActiveBtn" style={{ background: '#26596d', color: '#fff' }}>February
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.month == 'February';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="March" href="#" className="" style={{ background: '#5a4c72', color: '#fff' }}> March
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.month == 'March';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="April" href="#" className="" style={{ background: '#3c4c62', color: '#fff' }}> April
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.month == 'April';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="May" href="#" className="" style={{ background: '#5c737d', color: '#fff' }}> May
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.month == 'May';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="June" href="#" className="" style={{ background: '#4c5f59', color: '#fff' }}> June
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.month == 'June';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="July" href="#" className="" style={{ background: '#01796f', color: '#fff' }}> July
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.month == 'July';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="August" href="#" className="" style={{ background: '#144773', color: '#fff' }}> August
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.month == 'August';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="September" href="#" className="" style={{ background: '#396a95', color: '#fff' }}> September
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.month == 'September';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="October" href="#" className="" style={{ background: '#535255', color: '#fff' }}> October
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.month == 'October';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="November" href="#" className="" style={{ background: '#b55355', color: '#fff' }}> November
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.month == 'November';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="December" href="#" className="" style={{ background: '#3c97b6', color: '#fff' }}> December
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.month == 'December';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>

                </DropdownButton>
            </TaskForDropdown>
        </React.Fragment>
    )
}

export default MonthFilterDropdown;