import React from 'react';
import './legaldashboard.scss';

const LegalDashboard = () => {
    return (
        <>
            <div className="dk-dkglobalFinance-legalMain">

            </div>
        </>
    )
}

export default LegalDashboard;