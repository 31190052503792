import React, {useEffect, useLayoutEffect, useState} from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import DashboradLayout from "../layout/DashboradLayout";
import { userslist,usersRCList } from "../../slice/userList/userSlice";
import { countAllTask } from "../../slice/task/taskSlice";
import { adsJobboard, adsCompany, adsLanguage, adsRoleType } from "../../slice/layout/layoutSlice";
import { io } from "socket.io-client";
import {showSuccess} from "../../slice/utils/message/messageSlice";

const MainPrivateRoute = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch();
    const { isAuthenticate } = useSelector(state => state.auth);
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        let sock;
        if (isAuthenticate) {
            const sock = io(process.env.REACT_APP_SOCKET_URL || "", {
                autoConnect: false,
                transports: ["websocket"],
            });

            sock.connect();

            sock.on("connect", () => {
                console.log("===========connect");
            });

            sock.on("disconnect", () => {
                console.log("===========disconnect");
            });

            sock.on("new_email", () => {
                console.log("===========new_email", 'parent');
                dispatch(showSuccess({ msg: "New Email Received" }));
            });

            setSocket(sock);
        }
        return () => {
            try {
                if (sock) {
                    sock.disconnect();
                }
                setSocket(null)
            } catch (e) {
                console.log(e);
            }
        };
    }, [isAuthenticate]);

    const fetchAdsJobboard = async () => {
        try {
            await dispatch(adsJobboard())
        } catch (err) {
            console.log(err)
        }
    }
    const fetchAdsCompany = async () => {
        try {
            await dispatch(adsCompany())
        } catch (err) {
            console.log(err)
        }
    }
    const fetchAdsLanguage = async () => {
        try {
            await dispatch(adsLanguage())
        } catch (err) {
            console.log(err)
        }
    }
    const fetchAdsRoleType = async () => {
        try {
            await dispatch(adsRoleType())
        } catch (err) {
            console.log(err)
        }
    }

    useLayoutEffect(() => {
        const fetchUsersRCList = async () => {
            try {
                await dispatch(usersRCList())
            } catch (err) {
                console.log(err)
            }
        }
        const fetchUserList = async () => {
            try {
                await dispatch(userslist())
            } catch (err) {
                console.log(err)
            }
        }
        const fetchList = async () => {
            try {
                await dispatch(countAllTask())
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
        fetchUserList();
        fetchUsersRCList()
    }, [])

    fetchAdsJobboard();
    fetchAdsCompany();
    fetchAdsLanguage();
    fetchAdsRoleType();
    
    return (
        <Route {...rest} render={props => {
            if (isAuthenticate)
                return <DashboradLayout><Component {...props} socket={socket} /></DashboradLayout>
            return <Redirect
                to={{ pathname: '/', state: { from: props.location } }}
            />
        }} />
    )
};
export default MainPrivateRoute;