import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./livejobsrightfilter.scss";
import { Modal, Nav, Tab } from "react-bootstrap";
import { MdInsertDriveFile } from "react-icons/md";
import moment from "moment-timezone";

const RightModalFilter = ({
  list,
  selectUserFilter,
  selectOtherFilter,
  show,
  onHide,
}) => {
  const [companyList, setCompanyList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [createdAtList, setCreatedAtList] = useState([]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.company)) {
        map.set(item.original.company, true);
        result.push({
          name: item.original.company,
          count: list.filter(function (val) {
            return val.original.company === item.original.company;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setCompanyList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.project)) {
        map.set(item.original.project, true);
        result.push({
          name: item.original.project,
          count: list.filter(function (val) {
            return val.original.project === item.original.project;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setProjectList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.role)) {
        map.set(item.original.role, true);
        result.push({
          name: item.original.role,
          count: list.filter(function (val) {
            return val.original.role === item.original.role;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setRoleList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.lang)) {
        map.set(item.original.lang, true);
        result.push({
          name: item.original.lang,
          count: list.filter(function (val) {
            return val.original.lang === item.original.lang;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setLanguageList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (
        !map.has(moment(moment(item.original.createdAt)).format("DD MMM, YY"))
      ) {
        map.set(
          moment(moment(item.original.createdAt)).format("DD MMM, YY"),
          true
        );
        result.push({
          name: moment(moment(item.original.createdAt)).format("DD MMM, YY"),
          count: list.filter(function (val) {
            return (
              moment(moment(val.original.createdAt)).format("DD MMM, YY") ===
              moment(moment(item.original.createdAt)).format("DD MMM, YY")
            );
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return new Date(a.name) - new Date(b.name);
    });
    setCreatedAtList(result);
  }, [list]);

  const status = [
    {
      key: "normal",
      value: "New Case",
      text: "New Case",
      bgColor: "#30887e",
      txtColor: "#fff",
    },
    {
      key: "to qualify",
      value: "In Progress",
      text: "In Progress",
      bgColor: "#D58B5D",
      txtColor: "#fff",
    },
    {
      key: "closed",
      value: "Closed",
      text: "Closed",
      bgColor: "#e2445c",
      txtColor: "#fff",
    },
  ];

  return (
    <>
      <div className="dk-jobsMainModalCon">
        <Modal
          show={show}
          onHide={onHide}
          dialogClassName="dkmb-rightFliterModal"
        >
          <Modal.Header closeButton className="dkmb-Fliterheader">
            <Modal.Title className="dkmb-FliterTitle">
              SEARCH JOB APPS
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="dkmb-Fliterbody">
            <Tab.Container
              id="dk-jobsRightFilterModal"
              defaultActiveKey="company"
            >
              <div className="row ml-0 mr-0" style={{ height: "100%" }}>
                <div
                  className="col-md-5 pl-0 pr-0"
                  style={{
                    background: "#eaeaea",
                    borderLeft: "1px solid #cccc",
                  }}
                >
                  <Nav variant="pills" className="flex-column dk-leftSideMenu">
                    <Nav.Item>
                      <Nav.Link style={{ fontSize: "12px" }} eventKey="company">
                        <i className="fa fa-building" aria-hidden="true"></i>
                        Company
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ fontSize: "12px" }} eventKey="status">
                        <i className="fa fa-check mr-2" aria-hidden="true"></i>
                        Status
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ fontSize: "12px" }} eventKey="project">
                        <MdInsertDriveFile />
                        Project
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: "12px" }}
                        eventKey="roletype"
                      >
                        <i className="fa fa-user" aria-hidden="true"></i>Role
                        Type
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: "12px" }}
                        eventKey="language"
                      >
                        <i className="fa fa-language" aria-hidden="true"></i>
                        LANGUAGE
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: "12px" }}
                        eventKey="jobcreated"
                      >
                        <i className="far fa-calendar"></i>JOB CREATED
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-md-7 pl-0 pr-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="status">
                      <div className="dk-rightMainContent">
                        <div className="dk-satusList">
                          <ul>
                            {status.map((item, index) => (
                              <li className="dk-fliterList" key={item.key}>
                                <Link
                                  to="#"
                                  className="dk-normalBg"
                                  data-type="status"
                                  data-value={item.text}
                                  onClick={selectOtherFilter}
                                  style={{ backgroundColor: item.bgColor }}
                                >
                                  <span
                                    className="dk-statusName"
                                    data-type="status"
                                    data-value={item.text}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.text}
                                  </span>
                                  <span
                                    className="dk-statusCounter"
                                    data-type="status"
                                    data-value={item.text}
                                    onClick={selectOtherFilter}
                                  >
                                    {
                                      list.filter(function (val) {
                                        return (
                                          val.original.status === item.text
                                        );
                                      }).length
                                    }
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="company">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {companyList &&
                              companyList.map((item, index) => (
                                <li key={`company${index}`}>
                                  <Link
                                    to="#"
                                    data-type="company"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="company"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="language">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {languageList &&
                              languageList.map((item, index) => (
                                <li key={`language${index}`}>
                                  <Link
                                    to="#"
                                    data-type="language"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="language"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="project">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {projectList &&
                              projectList.map((item, index) => (
                                <li key={`project${index}`}>
                                  <Link
                                    to="#"
                                    data-type="project"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="project"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="roletype">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {roleList &&
                              roleList.map((item, index) => (
                                <li key={`role${index}`}>
                                  <Link
                                    to="#"
                                    data-type="role"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="role"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="jobcreated">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {createdAtList &&
                              createdAtList.map((item, index) =>
                                item.name !== "" && item.name !== null ? (
                                  <li key={`createdat${index}`}>
                                    <Link
                                      to="#"
                                      data-type="createdAt"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {moment(moment(item.name)).format(
                                        "DD MMM, YY"
                                      )}
                                      <span
                                        className="text-orange count_ml"
                                        data-type="createdAt"
                                        data-value={item.name}
                                        onClick={selectOtherFilter}
                                      >
                                        {item.count}
                                      </span>
                                    </Link>
                                  </li>
                                ) : (
                                  ""
                                )
                              )}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default RightModalFilter;
