import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { ButtonGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import '../../../../../node_modules/react-datepicker/dist/react-datepicker.css';
import './searchcv.scss';
import { useDispatch, useSelector } from "react-redux";
import { PageLoader } from "../../../ui/pageLoader";
import DateTypeDropdown from "./dropdown/dateTypeDropdown";
import CaseStatusDropdown from "./dropdown/caseStatusDropdown";
import SearchableDropdown from "./dropdown/searchableDropdown";
import SearchableDropdownLanguage from "./dropdown/searchableDropdown/languageDropdown";
import SearchableDropdownCompany from "./dropdown/searchableDropdown/companyDropdown";
import NormalDropdown from "./dropdown/normalDropdown";
import IconsNumberDropdown from "./dropdown/iconsNumberDropdown";
import IconsNumberDropdownProfileIndicator from "./dropdown/iconsNumberDropdown/profileIndicator";
import DummyDropdown from "./dropdown/dummyDropdown";
import AutoCompleteDropdown from "./dropdown/autocompletedropdown";
import DatePicker from "react-datepicker";
import SearchKeywords from "./SearchKeywords";
import { totalRecordsCounts, recruitersCounts, categoryCounts, caseStatusCounts, caseProgressCounts, caseStageCounts, flagCounts, profileIndicatorCounts, genderCounts, nativeLanguageCounts, fluentLanguageCounts, intermediateLanguageCounts, nationalityCounts, workPermitCounts, countryCounts, cvSourceCounts, companyCounts, companyLanguageCounts, companyProjectCounts, companyRoleCounts, clearState } from "../../../../slice/searchCV/searchCvSlice"

const SearchCvs = () => {
    const dispatch = useDispatch();
    const { totalRecords, recruitersList, categoryList, caseStatusList, caseProgressList, caseStageList, flagList, profileIndicatorList, genderList, nativeLanguageList, fluentLanguageList, intermediateLanguageList, nationalityList, workPermitList, countryList, cvSourceList, companyList, companyLanguageList, companyProjectList, companyRoleList, isLoading, isError, message } = useSelector(state => state.searchCV);

    const [filterTags, setFilterTags] = useState([]);
    const [tagLength, setTagLength] = useState(0);

    const [search, setSearch] = useState([]);
    const [filter, setFilter] = useState({});
    const [candidateFilter, setCandidateFilter] = useState({});
    const [companyfilter, setCompanyfilter] = useState({});
    const [languagefilter, setLanguagefilter] = useState({});

    // const [total, setTotal] = useState(0);
    const [list, setList] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // const [rcUserList, setRcUserList] = useState([]);
    // const [cpList, setCpList] = useState([]);
    // const [csList, setCsList] = useState([]);
    // const [genderList, setGenderList] = useState([]);
    // const [flagList, setFlagList] = useState([]);
    // const [indicatorList, setIndicatorList] = useState([]);
    // const [nativeList, setNativeList] = useState([]);
    // const [fluentList, setFluentList] = useState([]);
    // const [intermediateList, setIntermediateList] = useState([]);
    // const [nationalityList, setNationalityList] = useState([]);
    // const [workPermitList, setWorkPermitList] = useState([]);
    // const [countryList, setCountryList] = useState([]);
    // const [cvSourceList, setCvSourceList] = useState([]);
    // const [jobCompanyList, setJobCompanyList] = useState([]);
    // const [jobCountryList, setJobCountryList] = useState([]);
    // const [jobLanguageList, setJobLanguageList] = useState([]);
    // const [jobProjectList, setJobProjectList] = useState([]);
    // const [jobRoleList, setJobRoleList] = useState([]);

    const [isRecFilter, setIsRecFilter] = useState(false)
    const [isCaseStatusFilter, setIsCaseStatusFilter] = useState(false)
    const [isCaseProgressFilter, setIsCaseProgressFilter] = useState(false)
    const [isCaseStageFilter, setIsCaseStagesFilter] = useState(false)
    const [isGenderFilter, setIsGenderFilter] = useState(false)
    const [isFlagFilter, setIsFlagFilter] = useState(false)
    const [isProfileIndicatorFilter, setIsProfileIndicatorFilter] = useState(false)
    const [isNativeFilter, setIsNativeFilter] = useState(false)
    const [isFluentFilter, setIsFluentFilter] = useState(false)
    const [isIntermediateFilter, setIsIntermediateFilter] = useState(false)
    const [isNationalityFilter, setIsNationalityFilter] = useState(false)
    const [isWorkPermitFilter, setIsWorkPermitFilter] = useState(false)
    const [isCountryFilter, setIsCountryFilter] = useState(false)
    const [isCVSourceFilter, setIsCVSourceFilter] = useState(false)
    const [isJobCompanyFilter, setIsJobCompanyFilter] = useState(false)
    const [isJobCountryFilter, setIsJobCountryFilter] = useState(false)
    const [isJobLanguageFilter, setIsJobLanguageFilter] = useState(false)
    const [isJobProjectFilter, setIsJobProjectFilter] = useState(false)
    const [isJobRoleFilter, setIsJobRoleFilter] = useState(false)

    const [recName, setRecName] = useState('')
    const [recImage, setRecImage] = useState('')
    const [caseStatusTxt, setCaseStatusTxt] = useState('Case Status')
    const [caseProgressTxt, setCaseProgressTxt] = useState('Case Progress')
    const [caseStageTxt, setCaseStageTxt] = useState('Case Stage')
    const [genderTxt, setGenderTxt] = useState('Profile Gender')
    const [flagTxt, setFlagTxt] = useState('Profile Flags')
    const [indicatorTxt, setIndicatorTxt] = useState('Profile Indicators')
    const [nativeTxt, setNativeTxt] = useState('Native Language')
    const [fluentTxt, setFluentTxt] = useState('Fluent Language')
    const [intermediateTxt, setIntermediateTxt] = useState('Intermediate Language')
    const [nationalityTxt, setNationalityTxt] = useState('Nationalit')
    const [workPermitTxt, setWorkPermitTxt] = useState('Work Permit')
    const [countryTxt, setCountryTxt] = useState('Country')
    const [cVSourceTxt, setCVSourceTxt] = useState('CV Source')
    const [jobCompanyTxt, setJobCompanyTxt] = useState('Job Company')
    const [jobLanguageTxt, setJobLanguageTxt] = useState('Job Language')
    const [jobProjectTxt, setJobProjectTxt] = useState('Job Project')
    const [jobRoleTxt, setJobRoleTxt] = useState('Job Role')

    const getRequestParams = (filter) => {
        let params = {};
        params["filter"] = filter;
        params["search"] = search;
        return params;
    }

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const params = getRequestParams(filter);
                dispatch(totalRecordsCounts(params))
                dispatch(recruitersCounts(params))
                dispatch(categoryCounts(params))
                dispatch(caseStatusCounts(params))
                dispatch(caseProgressCounts(params))
                dispatch(caseStageCounts(params))
                dispatch(flagCounts(params))
                dispatch(profileIndicatorCounts(params))
                dispatch(genderCounts(params))
                dispatch(nativeLanguageCounts(params))
                dispatch(fluentLanguageCounts(params))
                dispatch(intermediateLanguageCounts(params))
                dispatch(nationalityCounts(params))
                dispatch(workPermitCounts(params))
                dispatch(countryCounts(params))
                dispatch(cvSourceCounts(params))
                dispatch(companyCounts(params))
                dispatch(companyLanguageCounts(params))
                dispatch(companyProjectCounts(params))
                dispatch(companyRoleCounts(params))
            } catch (err) {
                console.log(err)
            }
        }
        if (isEmpty(filter)) {
            fetchData()
        }
    }, [filter])

    const updateFilter = async (text, columnId) => {
        switch (columnId) {
            case 'rec_id':
                setIsRecFilter(true)
                let recString = text.split('===')
                setRecImage(recString[0])
                setRecName(recString[1])
                break;

            case 'caseStatus':
                setIsCaseStatusFilter(true)
                setCaseStatusTxt(text)
                break;

            case 'case_progress':
                setIsCaseProgressFilter(true)
                setCaseProgressTxt(text)
                break;

            case 'case_stage':
                setIsCaseStagesFilter(true)
                setCaseStageTxt(text)
                break;

            case 'gender':
                setIsGenderFilter(true)
                setGenderTxt(text)
                break;

            case 'profileIndicator':
                setIsProfileIndicatorFilter(true)
                setIndicatorTxt(text)
                break;

            case 'flag_type':
                setIsFlagFilter(true)
                setFlagTxt(text)
                break;

            case 'native1':
                setIsNativeFilter(true)
                setNativeTxt(text)
                break;

            case 'fluent1':
                setIsFluentFilter(true)
                setFluentTxt(text)
                break;

            case 'intermediate1':
                setIsIntermediateFilter(true)
                setIntermediateTxt(text)
                break;

            case 'nationality':
                setIsNationalityFilter(true)
                setNationalityTxt(text)
                break;

            case 'visaStatus':
                setIsWorkPermitFilter(true)
                setWorkPermitTxt(text)
                break;

            case 'c_country':
                setIsCountryFilter(true)
                setCountryTxt(text)
                break;

            case 'cv_source':
                setIsCVSourceFilter(true)
                setCVSourceTxt(text)
                break;

            case 'company':
                setIsJobCompanyFilter(true)
                setJobCompanyTxt(text)
                break;

            case 'lang':
                setIsJobLanguageFilter(true)
                setJobLanguageTxt(text)
                break;

            case 'project':
                setIsJobProjectFilter(true)
                setJobProjectTxt(text)
                break;

            case 'role':
                setIsJobRoleFilter(true)
                setJobRoleTxt(text)
                break;

            default:
                break;
        }
    }

    const removeFilter = async (e) => {
        const key = e.target.dataset.key;
        switch (key) {
            case 'rec_id':
                setIsRecFilter(false)
                setRecImage('')
                setRecName('')
                break;

            case 'caseStatus':
                setIsCaseStatusFilter(false)
                setCaseStatusTxt('Case Status')
                break;

            case 'case_progress':
                setIsCaseProgressFilter(false)
                setCaseProgressTxt('Case Progress')
                break;

            case 'case_stage':
                setIsCaseStagesFilter(false)
                setCaseStageTxt('Case Stage')
                break;

            case 'gender':
                setIsGenderFilter(false)
                setGenderTxt('Profile Gender')
                break;

            case 'profileIndicator':
                setIsProfileIndicatorFilter(false)
                setIndicatorTxt('Profile Indicators')
                break;

            case 'flag_type':
                setIsFlagFilter(false)
                setFlagTxt('Profile Flags')
                break;

            case 'native1':
                setIsNativeFilter(false)
                setNativeTxt('Native Language')
                break;

            case 'fluent1':
                setIsFluentFilter(false)
                setFluentTxt('Fluent Language')
                break;

            case 'intermediate1':
                setIsIntermediateFilter(false)
                setIntermediateTxt('Intermediate Language')
                break;

            case 'nationality':
                setIsNationalityFilter(false)
                setNationalityTxt('Nationalit')
                break;

            case 'visaStatus':
                setIsWorkPermitFilter(false)
                setWorkPermitTxt('Work Permit')
                break;

            case 'c_country':
                setIsCountryFilter(false)
                setCountryTxt('Country')
                break;

            case 'cv_source':
                setIsCVSourceFilter(false)
                setCVSourceTxt('CV Source')
                break;

            case 'company':
                setIsJobCompanyFilter(false)
                setJobCompanyTxt('Job Company')
                break;

            case 'lang':
                setIsJobLanguageFilter(false)
                setJobLanguageTxt('Job Language')
                break;

            case 'project':
                setIsJobProjectFilter(false)
                setJobProjectTxt('Job Project')
                break;

            case 'role':
                setIsJobRoleFilter(false)
                setJobRoleTxt('Job Role')
                break;

            default:
                break;
        }
    }

    return (
        <>
            {
                <div className="dk-searchCvMain">
                    <div className="dk-filterMain">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="dk-filterMainLeft">
                                <span>Total Candidates : </span>
                                <span className="noOfCandidates">{totalRecords}</span>
                            </div>
                            <div className="dk-searchTags">
                                {/* <li className="searchTagCategory">Ana Maria Buescu
                                        <Link to="#" className="dk-close"><i className="fas fa-times"></i></Link>
                                    </li> */}
                            </div>
                            <div className="dk-filterMainRight">
                                <button><i className="fas fa-search"></i> Search Records</button>
                            </div>
                        </div>
                    </div>
                    <div className="dk-searchContainer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="dk-workerCols">
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-item-button-case-worker" variant="" className='ui fluid selection dropdown'>
                                                {(!isRecFilter) ?
                                                    <React.Fragment><i className="fas fa-user-circle"></i>  Case Worker</React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <div className='case-worker-selection d-flex justify-content-between align-items-center w-100 pl-2 pr-2'>
                                                            <img src={recImage} className='selectedImg' />
                                                            {recName}
                                                            <i className="fas fa-times-circle crossBtn" data-key="rec_id" onClick={removeFilter}></i>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu variant="" className='visible menu'>
                                                {recruitersList.map((item, index) => (
                                                    <Dropdown.Item onClick={() => updateFilter(process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.recInfo.profileImage + "===" + item.recInfo.firstName + " " + item.recInfo.lastName + "===" + item.recInfo.id, 'rec_id')}>
                                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.recInfo.profileImage} className="ui avatar image" />
                                                        <span className="text"> {item.recInfo.firstName + " " + item.recInfo.lastName}</span>
                                                        <div className="ui label"> {item.count}</div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="dk-searchCvtext dk-input-tagField">
                                        {/* <input type="text" placeholder="Search Keywords" name="" id="" className="form-control" /> */}

                                        <SearchKeywords />

                                        <button><i className="fas fa-sync-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 pr-4 mt-4">
                                    <div className="dk-categorySearch">
                                        <div className="sbtitle">Category <i className="fas fa-search"></i></div>
                                        <ul>
                                            <li>
                                                <p>ML</p>
                                                <span>
                                                    {
                                                        categoryList.filter(items => items.category == "ML").map((item, index) => {
                                                            return item.count
                                                        })
                                                    }
                                                </span>
                                            </li>
                                            <li>
                                                <p>IT</p>
                                                <span>
                                                    {
                                                        categoryList.filter(items => items.category == "IT").map((item, index) => {
                                                            return item.count
                                                        })
                                                    }
                                                </span>
                                            </li>
                                            <li>
                                                <p>None</p>
                                                <span>
                                                    {
                                                        categoryList.filter(items => items.category == "None").map((item, index) => {
                                                            return item.count
                                                        })
                                                    }
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3 pr-4 mt-4">
                                    <div className="dk-categorySearch dk-searchDropdownddl">
                                        <div className="sbtitle">Case Filters <i className="fas fa-search"></i></div>
                                        <CaseStatusDropdown id="dropdown-item-button-case-status" className={`ui fluid selection dropdown otherdropdown ${(isCaseStatusFilter) ? 'justify-content-between selectionBgColor' : null}`} isFilter={isCaseStatusFilter} defaultTxt={caseStatusTxt} list={caseStatusList} updateFilter={updateFilter} removeFilter={removeFilter} />

                                        <SearchableDropdown id="dropdown-item-button-case-progress" className={`ui fluid selection dropdown otherdropdown ${(isCaseProgressFilter) ? 'justify-content-between selectionBgColor' : null}`} isFilter={isCaseProgressFilter} defaultTxt={caseProgressTxt} list={caseProgressList} filterFor="case_progress" updateFilter={updateFilter} removeFilter={removeFilter} />

                                        <SearchableDropdown id="dropdown-item-button-case-stage" className={`ui fluid selection dropdown otherdropdown ${(isCaseStageFilter) ? 'justify-content-between selectionBgColor' : null}`} isFilter={isCaseStageFilter} defaultTxt={caseStageTxt} list={caseStageList} filterFor="case_stage" updateFilter={updateFilter} removeFilter={removeFilter} />
                                    </div>
                                </div>
                                <div className="col-md-3 pr-4 mt-4">
                                    <div className="dk-categorySearch dk-searchDropdownddl dk-caseDatesCols">
                                        <div className="sbtitle">Case Dates <i className="fas fa-search"></i></div>
                                        <DateTypeDropdown id="dropdown-item-button-case-dates" className="ui fluid selection dropdown otherdropdown justify-content-between" defaultTxt="Profile Dates" />
                                        <label htmlFor="" className="dk-searchDate">
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                placeholderText="From"
                                                dateFormat="d MMM yyyy"
                                            />
                                        </label>
                                        <label htmlFor="" className="dk-searchDate">
                                            <DatePicker
                                                selected={endDate}
                                                onChange={(date) => setEndDate(date)}
                                                placeholderText="To"
                                                dateFormat="d MMM yyyy"
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-3 pr-4 mt-4">
                                    <div className="dk-categorySearch dk-searchDropdownddl">
                                        <div className="sbtitle">Profile Filters <i className="fas fa-search"></i></div>

                                        <IconsNumberDropdown id="dropdown-item-button-gender" className={`ui fluid selection dropdown otherdropdown ${(isFlagFilter) ? 'justify-content-between selectionBgColor' : null}`} list={flagList} filterFor="flag_type" isFilter={isFlagFilter} defaultTxt={flagTxt} updateFilter={updateFilter} removeFilter={removeFilter} />

                                        <IconsNumberDropdownProfileIndicator id="dropdown-item-button-gender" className={`ui fluid selection dropdown otherdropdown ${(isProfileIndicatorFilter) ? 'justify-content-between selectionBgColor' : null}`} list={profileIndicatorList} filterFor="profileIndicator" isFilter={isProfileIndicatorFilter} defaultTxt={indicatorTxt} updateFilter={updateFilter} removeFilter={removeFilter} />
                                    </div>
                                </div>
                                <div className="col-md-3 pr-4 mt-4">
                                    <div className="dk-categorySearch dk-searchDropdownddl">
                                        <div className="sbtitle">Personal <i className="fas fa-search"></i></div>
                                        <div className="dk-flexCols-main">
                                            <div className="dk-flexCols">
                                                <span>ID</span>
                                                {/* <input type="text" placeholder="Search ID" name="" id="" className="form-con" /> */}
                                                <AutoCompleteDropdown />
                                            </div>
                                            <div className="dk-flexCols">
                                                <span>Name</span>
                                                <input type="text" placeholder="Search Name" name="" id="" className="form-con" />
                                            </div>
                                            <div className="dk-flexCols">
                                                <span>Email</span>
                                                <input type="text" placeholder="Search Email" name="" id="" className="form-con" />
                                            </div>
                                        </div>
                                        <NormalDropdown id="dropdown-item-button-gender" className={`ui fluid selection dropdown otherdropdown ${(isGenderFilter) ? 'justify-content-between selectionBgColor' : null}`} list={genderList} filterFor="gender" isFilter={isGenderFilter} defaultTxt={genderTxt} updateFilter={updateFilter} removeFilter={removeFilter} />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Age" />
                                    </div>
                                </div>
                                <div className="col-md-3 pr-4 mt-4">
                                    <div className="dk-categorySearch dk-searchDropdownddl">
                                        <div className="sbtitle">Profile Skills <i className="fas fa-search"></i></div>
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="It Skills" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="It Skill Exp" />

                                        <SearchableDropdownLanguage id="dropdown-item-button-native" className={`ui fluid selection dropdown otherdropdown ${(isNativeFilter) ? 'justify-content-between selectionBgColor' : null}`} list={nativeLanguageList} filterFor="native1" isFilter={isNativeFilter} defaultTxt={nativeTxt} updateFilter={updateFilter} removeFilter={removeFilter} />

                                        <SearchableDropdownLanguage id="dropdown-item-button-fluent" className={`ui fluid selection dropdown otherdropdown ${(isFluentFilter) ? 'justify-content-between selectionBgColor' : null}`} list={fluentLanguageList} filterFor="fluent1" isFilter={isFluentFilter} defaultTxt={fluentTxt} updateFilter={updateFilter} removeFilter={removeFilter} />

                                        <SearchableDropdownLanguage id="dropdown-item-button-native" className={`ui fluid selection dropdown otherdropdown ${(isIntermediateFilter) ? 'justify-content-between selectionBgColor' : null}`} list={intermediateLanguageList} filterFor="intermediate1" isFilter={isIntermediateFilter} defaultTxt={intermediateTxt} updateFilter={updateFilter} removeFilter={removeFilter} />
                                    </div>
                                </div>
                                <div className="col-md-3 pr-4 mt-4">
                                    <div className="dk-categorySearch dk-searchDropdownddl">
                                        <div className="sbtitle">Screening <i className="fas fa-search"></i></div>
                                        <SearchableDropdown id="dropdown-item-button-nationality" className={`ui fluid selection dropdown otherdropdown ${(isNationalityFilter) ? 'justify-content-between selectionBgColor' : null}`} list={nationalityList} filterFor="nationality" isFilter={isNationalityFilter} defaultTxt={nationalityTxt} updateFilter={updateFilter} removeFilter={removeFilter} />

                                        <SearchableDropdown id="dropdown-item-button-visaStatus" className={`ui fluid selection dropdown otherdropdown ${(isWorkPermitFilter) ? 'justify-content-between selectionBgColor' : null}`} list={workPermitList} filterFor="visaStatus" isFilter={isWorkPermitFilter} defaultTxt={workPermitTxt} updateFilter={updateFilter} removeFilter={removeFilter} />

                                        <div className="dk-flexCols-main">
                                            <div className="dk-flexCols">
                                                <span>City</span>
                                                <input type="text" placeholder="Search Current City" name="" id="" className="form-control" />
                                            </div>
                                        </div>

                                        <SearchableDropdown id="dropdown-item-button-c_country" className={`ui fluid selection dropdown otherdropdown ${(isCountryFilter) ? 'justify-content-between selectionBgColor' : null}`} list={countryList} filterFor="c_country" isFilter={isCountryFilter} defaultTxt={countryTxt} updateFilter={updateFilter} removeFilter={removeFilter} />

                                        <SearchableDropdown id="dropdown-item-button-cv_source" className={`ui fluid selection dropdown otherdropdown ${(isCVSourceFilter) ? 'justify-content-between selectionBgColor' : null}`} list={cvSourceList} filterFor="cv_source" isFilter={isCVSourceFilter} defaultTxt={cVSourceTxt} updateFilter={updateFilter} removeFilter={removeFilter} />
                                    </div>
                                </div>
                                <div className="col-md-3 pr-4 mt-4">
                                    <div className="dk-categorySearch dk-searchDropdownddl">
                                        <div className="sbtitle">Job Order <i className="fas fa-search"></i></div>
                                        <SearchableDropdownCompany id="dropdown-item-button-company" className={`ui fluid selection dropdown otherdropdown ${(isJobCompanyFilter) ? 'justify-content-between selectionBgColor' : null}`} list={companyList} filterFor="company" isFilter={isJobCompanyFilter} defaultTxt={jobCompanyTxt} updateFilter={updateFilter} removeFilter={removeFilter} />

                                        <SearchableDropdownCompany id="dropdown-item-button-jobLanguage" className={`ui fluid selection dropdown otherdropdown ${(isJobLanguageFilter) ? 'justify-content-between selectionBgColor' : null}`} list={companyLanguageList} filterFor="lang" isFilter={isJobLanguageFilter} defaultTxt={jobLanguageTxt} updateFilter={updateFilter} removeFilter={removeFilter} />

                                        <SearchableDropdownCompany id="dropdown-item-button-jobProject" className={`ui fluid selection dropdown otherdropdown ${(isJobProjectFilter) ? 'justify-content-between selectionBgColor' : null}`} list={companyProjectList} filterFor="project" isFilter={isJobProjectFilter} defaultTxt={jobProjectTxt} updateFilter={updateFilter} removeFilter={removeFilter} />

                                        <SearchableDropdownCompany id="dropdown-item-button-jobRole" className={`ui fluid selection dropdown otherdropdown ${(isJobRoleFilter) ? 'justify-content-between selectionBgColor' : null}`} list={companyRoleList} filterFor="role" isFilter={isJobRoleFilter} defaultTxt={jobRoleTxt} updateFilter={updateFilter} removeFilter={removeFilter} />
                                    </div>
                                </div>
                                <div className="col-md-3 pr-4 mt-4">
                                    <div className="dk-categorySearch dk-searchDropdownddl">
                                        <div className="sbtitle">Employement <i className="fas fa-search"></i></div>
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Job Title" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Senority" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Employer" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Experince" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Duration" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Industries" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Sectors" />
                                    </div>
                                </div>

                                <div className="col-md-3 pr-4 mt-4">
                                    <div className="dk-categorySearch dk-searchDropdownddl">
                                        <div className="sbtitle">Qualification <i className="fas fa-search"></i></div>
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Title" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Level" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Institute" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Degree" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Diplomas" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Certificates" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Training" />
                                    </div>
                                </div>

                                <div className="col-md-3 pr-4 mt-4">
                                    <div className="dk-categorySearch dk-searchDropdownddl">
                                        <div className="sbtitle">Qualification <i className="fas fa-search"></i></div>
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Salary & Benifts 1" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Salary & Benifts 2" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Salary & Benifts 3" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Salary & Benifts 4" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Salary & Benifts 5" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Salary & Benifts 6" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Salary & Benifts 7" />
                                    </div>
                                </div>

                                <div className="col-md-3 pr-4 mt-4">
                                    <div className="dk-categorySearch dk-searchDropdownddl">
                                        <div className="sbtitle">Desires <i className="fas fa-search"></i></div>
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Desired Job" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Desired Level" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Desired City" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Desired Country" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Job Notice Period" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Willing to relocate" />
                                        <DummyDropdown id="dropdown-item-button-it-skills" list={[]} defaultTxt="Hobbies & Intrest" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                (isLoading) ? <PageLoader /> : null
            }
        </>
    )
}
export default SearchCvs;
