
import  './myacc-interview.scss'

const MyaccInterview = () => {
    return (
        <>
            <div className='dkg-mayacc-interviewCon'></div>
        </>
    )
}

export default MyaccInterview