import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";

const ModelTwoLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkglobaladmin/admintraining/model-2/overview" className={`${pathname.match('/dkglobaladmin/admintraining/model-2/overview') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="#" className={`${pathname.match('#') ? 'active' : ''}`}>Day 1 - Part Time</Link></li>

                    <li><Link to="#" className={`${pathname.match('#') ? 'active' : ''}`}>Day 2 - Part Time</Link></li>

                    <li><Link to="#" className={`${pathname.match('#') ? 'active' : ''}`}>Day 3 - Part Time</Link></li>

                    <li><Link to="#" className={`${pathname.match('#') ? 'active' : ''}`}>Day 4 - Part Time</Link></li>

                    <li><Link to="#" className={`${pathname.match('#') ? 'active' : ''}`}>Day 5 - Part Time</Link></li>

                    <li><Link to="#" className={`${pathname.match('#') ? 'active' : ''}`}>Day 6 - Full Time</Link></li>

                    <li><Link to="#" className={`${pathname.match('#') ? 'active' : ''}`}>Day 7 - Full Time</Link></li>

                    <li><Link to="#" className={`${pathname.match('#') ? 'active' : ''}`}>Day 8 - Full Time</Link></li>

                    <li><Link to="#" className={`${pathname.match('#') ? 'active' : ''}`}>Day 9 - Full Time</Link></li>

                    <li><Link to="#" className={`${pathname.match('#') ? 'active' : ''}`}>Day 10 - Full Time</Link></li>

                    <li><Link to="#" className={`${pathname.match('#') ? 'active' : ''}`}>Training Progress</Link></li>
                </Route>
            </ul>
        </React.Fragment>
    )
}

export default ModelTwoLeftPanel;

