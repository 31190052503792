import React, { useMemo, useState, useEffect } from "react";
import { useTable, useSortBy, useGlobalFilter, useFilters } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import { GlobalFilter } from "./GlobalFilter";
import { Link } from "react-router-dom";
import AddNewModal from "./modals/AddNewModal";
import EditModal from "./modals/EditModal";
//import LevelModal from "./modals/LevelModal";
//import ReorderModal from "./modals/ReorderModal";
import { fethById, deleteMailShotData, clearState } from '../../../../slice/mailshots/mailshotsSlice';
import DeleteModal from "../../../ui/delete";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import mailchimp from "../../../../assets/images/mailchimp.png"
import outlookIcon from "../../../../assets/images/outlookIcon.png"
import UserFilterToggle from "../../../ui/tableUserFilter";
import SearchFilterModal from './modals/SearchFilter'

export const BasicTable = ({mailList}) => {
    const [showDelete, setShowDelete] = useState(false)
    const [isShowEdit, setIsShowEdit] = useState(false)
    const [isSrchFilter, setIsSrchFilter] = useState(false);
    const [rowId, setRowId] = useState('0')
    const dispatch = useDispatch();
    let {isDelete, isUpdate, isError, msg } = useSelector(state => state.mailshots)
    //const data = mailList
console.log(mailList);

    const [userFilterId, setUserFilterId] = useState('0')
    const [userFilterImg, setUserFilterImg] = useState('0')

    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(clearMessage())
            dispatch(showSuccess({ msg: msg }))
            setShowDelete(false)
            setRowId('0')
        }
    }, [isDelete])

    useEffect(() => {
        if (isError) {
            dispatch(clearState())
            dispatch(showError({ msg: msg }))
        }
        if (isUpdate) {
            //  setShow(false);
            dispatch(showSuccess({ msg: msg }))
            dispatch(clearState())
        }
    }, [isError, isUpdate])

    const columns = useMemo(() => [
        {
            Header: 'Sno',
            Cell: (row) => Number(row.row.id) + 1
        },
        {
            Header: 'Date',
            accessor: 'joinDate',
            Cell: (row) => (
                <>
                    {row.row.original.joinDate},  {row.row.original.joinTime}
                </>
            )
        },
        {
            Header: 'Recruiter',
            accessor: 'userInfo.profileImage',
            Cell: (row) => (<img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + row.row.original.userInfo.profileImage} alt={row.row.original.name} className="img-fluid dk-userImg" />)
        },
        {
            Header: 'Candidates',
            accessor: 'totalCandidate'
        },
        {
            Header: 'Sent Via',
            accessor: 'mailshotVia',
            Cell: (row) => (
                <>
                    <img src={row.row.original.mailshotVia == "Outlook" ? outlookIcon : mailchimp} alt={row.row.original.name} className="img-fluid mailChipImg" />
                </>
            )
        },
        {
            Header: 'Title',
            accessor: 'projectTitle'
        },
        {
            Header: 'Category',
            accessor: 'category'
        },
        {
            Header: 'Company',
            accessor: 'company'
        },
        {
            Header: 'Language',
            accessor: 'language'
        },
        {
            Header: 'Project',
            accessor: 'project'
        },
        {
            Header: 'Role Type',
            accessor: 'roleType'
        },
        {
            Header: 'Action',
            Cell: (row) => (
                <div className="dk-action d-flex justify-content-center">
                    <Link to="#" className="dk-upload"><i className="fa fa-upload" aria-hidden="true"></i></Link>
                    <Link to="#" onClick={() => editPopupShow(row.row.original.id)} className="dk-edit dk-editBtn"><i className="fas fa-pencil-alt"></i></Link>
                    <Link to="#" onClick={() => deleteList(row.row.original.id)} className="dk-trash dk-deleteBtn"><i className="far fa-trash-alt"></i></Link>
                    <Link to="#" className="dk-report"><i className="fas fa-file-alt"></i></Link>
                </div>
            )
        },
        {
            id: 'userInfo',
            Header: 'Sent By',
            accessor: 'userInfo.uid',
            show: false
        },
        {
            id: 'firstNameId',
            Header: 'firstName',
            accessor: 'userInfo.firstName',
            show: false
        },
    ], [])

    const clickFilterUser = (e) => {
        setUserFilterId(e.target.id)
        setUserFilterImg(e.target.src)
        setFilter('userInfo', e.target.id)
    }

    const deleteFilter = (name) => {
        setFilter(name, '')
        if (name === 'userInfo') {
            setUserFilterId('0')
            setUserFilterImg('0')
        }
    }

    const editPopupShow = async (mailshotId) => {
        await dispatch(fethById({ mailshotId }))
        setIsShowEdit(true)
        setRowId(mailshotId)
    }
    const handleClose = () => {
        setRowId('0')
        setIsShowEdit(false)
        setShowDelete(false)
    }
    const deleteList = async (dataId) => {
        setRowId(dataId)
        setShowDelete(true)
    }
    const deleteItem = async () => {
        dispatch(deleteMailShotData({ rowId }))
    }


    const tableInstance = useTable({
        columns: columns,
        data: mailList,
        initialState: {
            pageIndex: 0, pageSize: 100, hiddenColumns: ["userInfo", "firstNameId"]
        },
    }, useGlobalFilter, useFilters, useSortBy)

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter, setFilter } = tableInstance
    const { globalFilter } = state
    const showSerchFilterModal = () => {
        setIsSrchFilter(true);
    }
    const hideSerchFilterModal = () => {
        setIsSrchFilter(false);
    }
    const hideeditModal = () => {
        setIsShowEdit(false);
    }
    const showeditpopup = () => {
        setIsShowEdit(true);
    }
    useEffect(() => {
        if (isError) {
            setIsShowEdit(false);
            dispatch(clearState())
        }
        if (isUpdate) {
            setIsShowEdit(false);
            dispatch(clearState())
        }
    }, [isError, isUpdate])
    return (
        <React.Fragment>

            {
                (isShowEdit) ? <EditModal id={rowId} hidePopup={hideeditModal} showeditpopup={showeditpopup} /> : null
            }
            {
                (showDelete) ? <DeleteModal id={rowId} handleClose={handleClose} deleteItem={deleteItem} /> : null
            }
            {
                (isSrchFilter) ? <SearchFilterModal hidepopup={hideSerchFilterModal} /> : null
            }
            <div className="dk-mailshot-head">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-mailshotLeftCon">
                        <div className="dk-dkgHead-seach mr-2">
                            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        </div>
                        <UserFilterToggle userFilterId={userFilterId} userFilterImg={userFilterImg} list={tableInstance.flatRows} selectUser={clickFilterUser} deleteFilter={deleteFilter} />
                        <div className="mailshotIcon ml-2">
                            <Link to="#" onClick={showSerchFilterModal}><i className="fa fa-search" aria-hidden="true"></i></Link>
                        </div>
                    </div>
                    <h3 className="title">MAILSHOTS HISTORY  </h3>
                    <div className="dk-mailshotRightCon">
                        <AddNewModal />
                    </div>

                </div>
            </div>
            <div className="dk-mailshotTableCon table-responsive">
                <table className="table table-bordered dk-mailshotTable" {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}