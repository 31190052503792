import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const KnowledgeBaseDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkglobaladmin/admintraining/recruiters/knowledge-base" className={`${pathname.match('/dkglobaladmin/admintraining/recruiters/knowledge-base') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/recruiters/search-info" className={`${pathname.match('/dkglobaladmin/admintraining/recruiters/search-info') ? 'active' : ''}`}>Search Info</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/recruiters/tasks-info" className={`${pathname.match('/dkglobaladmin/admintraining/recruiters/tasks-info') ? 'active' : ''}`}>Tasks Info</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/recruiters/tools-info" className={`${pathname.match('/dkglobaladmin/admintraining/recruiters/tools-info') ? 'active' : ''}`}>Tools Info</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/recruiters/candidates" className={`${pathname.match('/dkglobaladmin/admintraining/recruiters/candidates') ? 'active' : ''}`}>Candidates</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/recruiters/clients" className={`${pathname.match('/dkglobaladmin/admintraining/recruiters/clients') ? 'active' : ''}`}>Clients</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/recruiters/jobs" className={`${pathname.match('/dkglobaladmin/admintraining/recruiters/jobs') ? 'active' : ''}`}>Jobs</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default KnowledgeBaseDetailLeftPanel;

