import React from 'react';
import './vatfilessummary.scss';

const VatFilesSummary = () => {
    return (
        <>
            <div className="dk-dkglobalFinance-vatfilesSummaryMain">

            </div>
        </>
    )
}

export default VatFilesSummary;