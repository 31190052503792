import React, { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import '../trainingdetail.scss';
import DetailLeftTabs from './DetailLeftTabs';
import MoreDetailModal from './MoreDetailModal';

const DetailTabs = () => {
    const [isShowMore, setIsShowMore] = useState(false);
    const showmoreDetails = () => {
        console.log("Show")
        setIsShowMore(true);
    }
    const hidemoreDetails = () => {
        setIsShowMore(false);
    }
    return (
        <React.Fragment>
            {
                (isShowMore) ? <MoreDetailModal handleClose={hidemoreDetails} /> : null
            }
            <div className="dk-detailTabsMain">
                <h3 className="title">Introduction</h3>
                <Tabs defaultActiveKey="tabs_1">
                    <Tab eventKey="tabs_1" title="Details">
                        <div className="d-flex">
                            <DetailLeftTabs />
                        </div>
                    </Tab>
                    <Tab eventKey="tabs_2" title="Samples">
                        <div className="d-flex">
                            <DetailLeftTabs />
                        </div>
                    </Tab>
                    <Tab eventKey="tabs_3" title="Video">
                        <div className="d-flex">
                            <DetailLeftTabs />
                        </div>
                    </Tab>
                </Tabs>
                <div title="More" className="clickMoreShow" onClick={showmoreDetails}>
                    More
                </div>
            </div>
        </React.Fragment >
    )
}

export default DetailTabs;

