import React, { useState } from 'react';
import { Link } from "react-router-dom";
import JuniorRecruiterImg from "../../../../../assets/images/recruiter_icon.png";
import SearchDatabaseImg from "../../../../../assets/images/searching-database.png";
import JobSpecsImg from "../../../../../assets/images/mailbox-management.png";
import SendreminderImg from "../../../../../assets/images/send-reminder.png";
import NotReachablesImg from "../../../../../assets/images/not-reachables.png";
import JobDetailsImg from "../../../../../assets/images/user-white.png";
import PreparationImg from "../../../../../assets/images/preparation_icon.png";
import ActionImg from "../../../../../assets/images/action_icon.png";
import PerformanceImg from "../../../../../assets/images/performance_white.png";
import KnowledgeImg from "../../../../../assets/images/knowledge-base.png";
import IdentifyImg from "../../../../../assets/images/assign-priority-white.png";
import BulkEmailImg from "../../../../../assets/images/bulk-email.png";
import DecisionImg from "../../../../../assets/images/close-decision.png";
import PrescreenCallImg from "../../../../../assets/images/qualifying-call.png";
import UpdateProfiles from "../../../../../assets/images/resume_white.png";

import './admins.scss';

const Admins = () => {
    return (
        <>
            <div className="dk-adminsTrainingMain dk-commonTrainingAdminMain">
                <div className="container-fluid">
                    <div className="dk-trainingMainCols dk-md-Container dk-trainingAdminCols">
                        <h3 className="title">TEAM 2 - RECRUITMENT ADMINS</h3>
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="/dkglobaladmin/admintraining/admins/job-details">
                                        <figure>
                                            <img src={JobDetailsImg} />
                                        </figure>
                                        <h3>Job Details</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="/dkglobaladmin/admintraining/admins/preparation">
                                        <figure>
                                            <img src={PreparationImg} />
                                        </figure>
                                        <h3>Preparation</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="/dkglobaladmin/admintraining/admins/action">
                                        <figure>
                                            <img src={ActionImg} />
                                        </figure>
                                        <h3>Action</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="/dkglobaladmin/admintraining/admins/performance">
                                        <figure>
                                            <img src={PerformanceImg} />
                                        </figure>
                                        <h3>Performance</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="/dkglobaladmin/admintraining/admins/knowledge-base">
                                        <figure>
                                            <img src={KnowledgeImg} />
                                        </figure>
                                        <h3>Knowledge Base</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="/dkglobaladmin/admintraining/admins/review-profiles">
                                        <figure>
                                            <img src={JuniorRecruiterImg} />
                                        </figure>
                                        <h3>Task 1 - Review Profiles</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="/dkglobaladmin/admintraining/admins/update-profiles">
                                        <figure>
                                            <img src={UpdateProfiles} />
                                        </figure>
                                        <h3>Task 2 - Update Profiles</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="/dkglobaladmin/admintraining/admins/assign-priority">
                                        <figure>
                                            <img src={IdentifyImg} />
                                        </figure>
                                        <h3>Task 3 - Assign Priority</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="/dkglobaladmin/admintraining/admins/mini-job-specs">
                                        <figure>
                                            <img src={JobSpecsImg} />
                                        </figure>
                                        <h3>Task 4 - Mini Job Spec</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="/dkglobaladmin/admintraining/admins/priority-screening">
                                        <figure>
                                            <img src={PrescreenCallImg} />
                                        </figure>
                                        <h3>Task 5 - Priority Screening</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="/dkglobaladmin/admintraining/admins/send-reminders">
                                        <figure>
                                            <img src={SendreminderImg} />
                                        </figure>
                                        <h3>Task 6 - Send Reminders</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="/dkglobaladmin/admintraining/admins/not-reachables">
                                        <figure>
                                            <img src={NotReachablesImg} />
                                        </figure>
                                        <h3>Task 7 - Not Reachable</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="/dkglobaladmin/admintraining/admins/search-database">
                                        <figure>
                                            <img src={SearchDatabaseImg} />
                                        </figure>
                                        <h3>Task 8 - Search Database</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="/dkglobaladmin/admintraining/admins/send-bulk-email">
                                        <figure>
                                            <img src={BulkEmailImg} />
                                        </figure>
                                        <h3>Task 9 - Send Bulk Emails</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="/dkglobaladmin/admintraining/admins/close-decision">
                                        <figure>
                                            <img src={DecisionImg} />
                                        </figure>
                                        <h3>Task 10 - Case Decision</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D', minHeight: '124px' }}>
                                    <Link to="#">
                                        <figure>

                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D', minHeight: '124px' }}>
                                    <Link to="#">
                                        <figure>

                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D', minHeight: '124px' }}>
                                    <Link to="#">
                                        <figure>

                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D', minHeight: '124px' }}>
                                    <Link to="#">
                                        <figure>

                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496D', minHeight: '124px' }}>
                                    <Link to="#">
                                        <figure>

                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Admins;
