import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
// import './hrdashboard.scss';
import CompanyIcon from "../../../../assets/images/building_icon.png";
import EmployeesIcon from "../../../../assets/images/enterprise.png";
import ContractsIcon from "../../../../assets/images/contracts.png";
import IncomeTaxIcon from "../../../../assets/images/income.png";

import FAQIcon from "../../../../assets/images/information_white.png";
import ManageClientsIcon from "../../../../assets/images/clientsmanagement_icon.png";
import CurrentClientsIcon from "../../../../assets/images/clients-info.png";
import ProjectionsIcon from "../../../../assets/images/projections.png";

import { useDispatch, useSelector } from "react-redux";
import Moduleheader from "../../../elements/header/index";

import { setMyModule } from '../../../../slice/modules/moduleSlice';
// import { showSuccess, showError } from '../../../../../components/utils/messages/messageSlice';
import { showSuccess, showError } from '../../../../components/utils/messages/messageSlice';

const BusinessModule = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        document.title = "DK Global Admin"
    }, []);
    const { userInfo } = useSelector(state => state.auth);

    const setModule = async (myModule, moduleColor) => {
        await dispatch(setMyModule({ module: myModule, color: moduleColor }))
        switch (myModule) {
            case 'newleads':
                history.push("/newbusinesss/newleads/summary");
                break;

            case 'in-progress':
                history.push("/newbusinesss/onboarding/dashboard");
                break;

            case 'closed':
                history.push("/newbusinesss/onboarding/dashboard");
                break;

            default:
                break;
        }
    }
    return (
        <>
            <Moduleheader dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" linkcolor="#3c4c62" mainheaderTitle="Clients" textcolor="#144773" />
            <div className="dk-hrModulemain">
                <div className="dk-hrModule-container">
                    <div className="title mb-2">CLIENTS DEPARTMENT</div>
                    <div className="d-flex">
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#144773' }}>

                                <img src={CurrentClientsIcon} alt="" />
                                <p>Current Clients</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#144773' }}>

                                <img src={ManageClientsIcon} alt="" />
                                <p>Manage Clients</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#144773' }}>

                                <img src={ProjectionsIcon} alt="" />
                                <p>Projections</p>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="dk-hrModule-box">
                            <Link to="#" onClick={() => setModule('newleads')} style={{ background: '#144773' }}>
                                <img src={CompanyIcon} alt="" />
                                <p>New Leads</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#144773' }}>
                                <img src={EmployeesIcon} alt="" />
                                <p>In Progress</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#144773' }}>
                                <img src={ContractsIcon} alt="" />
                                <p>Closed</p>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="dk-hrModule-box">
                            <Link to="/clients/income-tax" style={{ background: '#144773' }}>
                                <img src={IncomeTaxIcon} alt="" />
                                <p>Income Tax</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#144773' }}>

                                <p></p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#144773' }}>

                                <img src={FAQIcon} alt="" />
                                <p>FAQ</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessModule;
