import React from 'react'
import { FaCheck, FaRegCalendarAlt, FaCcVisa, FaMale, FaFemale, FaTransgenderAlt } from 'react-icons/fa';

const index = () => {
    return (
        <>
            <div className="container-fluid dk-candidatesuMMPageCon">
                <div className="dk-mo-suMM-pageTitle d-none"><h2 className="dk-mobile-title">PIPELINES SUMMARY</h2></div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="dk-suMMTopHead">
                            <div className="candSummpageTitle"><h2 className="title">PIPELINES SUMMARY</h2></div>
                            <div className="dk-rightTopHead d-none"><div className="dk-filterTagsCon"></div></div>
                        </div>
                    </div>
                    <div className="dk-candSummBoxContainer">
                        <div className="row">
                            <div className="col-md-12 mt-2">
                                <div className="dk-candtopLeftBoxCont">
                                    <div className="dk-topLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">CATEGORY</div>
                                            <span className="dk-totalCvCount">4225</span>
                                        </div>
                                        <ul>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <i className="fas fa-language mr-1"></i>ML
                                                </span>
                                                <span className="rightItem">3577</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <i className="fas fa-tv mr-1"></i>IT
                                                </span>
                                                <span className="rightItem">3</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center"><i className="far fa-circle mr-1"></i>Blank</span>
                                                <span className="rightItem">645</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-topLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">PROFILE MATCH</div>
                                            <span className="dk-totalCvCount">4549</span>
                                        </div>
                                        <ul>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaCheck style={{ color: '#048204', fontSize: '16px', marginRight: '4px' }} />
                                                    100% Matched
                                                </span>
                                                <span className="rightItem">1130</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaCheck style={{ color: '#d58b5d', fontSize: '16px', marginRight: '4px' }} />
                                                    75% Matched
                                                </span>
                                                <span className="rightItem">930</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaCheck style={{ color: '#d60101', fontSize: '16px', marginRight: '4px' }} />
                                                    0% Matched
                                                </span>
                                                <span className="rightItem">0</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaCheck style={{ color: '#d3d3d3', fontSize: '16px', marginRight: '4px' }} />
                                                    Blank
                                                </span>
                                                <span className="rightItem">0</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-topLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">PROFILE UPDATE</div>
                                            <span className="dk-totalCvCount">4549</span>
                                        </div>
                                        <ul>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <i className="fas fa-circle mr-1" style={{ color: 'green' }}></i>Updated
                                                </span>
                                                <span className="rightItem">598</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <i className="fas fa-circle mr-1" style={{ color: '#d3d3d3' }}></i>Blank
                                                </span>
                                                <span className="rightItem">3942</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-topLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">CANDIDATE</div>
                                            <span className="dk-totalCvCount">4549</span>
                                        </div>
                                        <ul>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <i className="fas fa-user" style={{ color: "#048204", fontSize: '16px', marginRight: '4px' }} />
                                                    Motivated
                                                </span>
                                                <span className="rightItem">2932</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <i className="fas fa-user" style={{ color: "#d58b5d", fontSize: '16px', marginRight: '4px' }} />
                                                    Doubtful
                                                </span>
                                                <span className="rightItem">1130</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <i className="fas fa-user" style={{ color: "#d60101", fontSize: '16px', marginRight: '4px' }} />
                                                    Unmotivated
                                                </span>
                                                <span className="rightItem">930</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <i className="fas fa-user" style={{ color: "#d3d3d3", fontSize: '16px', marginRight: '4px' }} />
                                                    Blank
                                                </span>
                                                <span className="rightItem">2932</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-topLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-dbsuMM-filterHeader">
                                                <div className="dk-colstitle">INTERVIEW</div>
                                            </div>
                                            <span className="dk-totalCvCount">4549</span>
                                        </div>
                                        <ul>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <i className="fas fa-flag mr-1" style={{ color: 'green' }}></i> On Time</span>
                                                <span className="rightItem">101</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <i className="fas fa-flag mr-1" style={{ color: '#d58b5d' }}></i> Delayed
                                                </span>
                                                <span className="rightItem">101</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <i className="fas fa-flag mr-1" style={{ color: '#d60101' }}></i>Stopped
                                                </span>
                                                <span className="rightItem">99</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <i className="fas fa-flag mr-1" style={{ color: '#d3d3d3' }}></i> Blank
                                                </span>
                                                <span className="rightItem">109</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-topLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">CASE CREATED</div>
                                            <span className="dk-totalCvCount">4549</span>
                                        </div>
                                        <ul>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaRegCalendarAlt style={{ fontSize: '16px', marginRight: '4px' }} />
                                                    Not Updated
                                                </span>
                                                <span className="rightItem">2932</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaRegCalendarAlt style={{ fontSize: '16px', marginRight: '4px' }} />
                                                    Today
                                                </span>
                                                <span className="rightItem">1130</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaRegCalendarAlt style={{ fontSize: '16px', marginRight: '4px' }} />
                                                    Yesterday
                                                </span>
                                                <span className="rightItem">930</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaRegCalendarAlt style={{ fontSize: '16px', marginRight: '4px' }} />
                                                    2 Days Ago
                                                </span>
                                                <span className="rightItem">154</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-topLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">CASE Updated</div>
                                            <span className="dk-totalCvCount">4549</span>
                                        </div>
                                        <ul>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaRegCalendarAlt style={{ fontSize: '16px', marginRight: '4px' }} />
                                                    Not Updated
                                                </span>
                                                <span className="rightItem">2932</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaRegCalendarAlt style={{ fontSize: '16px', marginRight: '4px' }} />
                                                    Today
                                                </span>
                                                <span className="rightItem">1130</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaRegCalendarAlt style={{ fontSize: '16px', marginRight: '4px' }} />
                                                    Yesterday
                                                </span>
                                                <span className="rightItem">930</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaRegCalendarAlt style={{ fontSize: '16px', marginRight: '4px' }} />
                                                    2 Days Ago
                                                </span>
                                                <span className="rightItem">154</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-topLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">Visa</div>
                                            <span className="dk-totalCvCount">4549</span>
                                        </div>
                                        <ul>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaCcVisa style={{ fontSize: '16px', marginRight: '4px' }} />
                                                    15 Jan 2019
                                                </span>
                                                <span className="rightItem">2932</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaCcVisa style={{ fontSize: '16px', marginRight: '4px' }} />
                                                    24 Apr 2019
                                                </span>
                                                <span className="rightItem">1130</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaCcVisa style={{ fontSize: '16px', marginRight: '4px' }} />
                                                    30 Nov 2021
                                                </span>
                                                <span className="rightItem">930</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-topLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">GENDER</div>
                                            <span className="dk-totalCvCount">4549</span>
                                        </div>
                                        <ul>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaMale style={{ fontSize: '16px', marginRight: '4px' }} />
                                                    Male
                                                </span>
                                                <span className="rightItem">2932</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaFemale style={{ fontSize: '16px', marginRight: '4px' }} />
                                                    Female
                                                </span>
                                                <span className="rightItem">1130</span>
                                            </li>
                                            <li>
                                                <span className="leftItem d-flex align-items-center">
                                                    <FaTransgenderAlt style={{ fontSize: '16px', marginRight: '4px' }} />
                                                    Other
                                                </span>
                                                <span className="rightItem">930</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="dk-candRightBoxCont dk-candbottomLeftBoxCont">
                                    <div className="dk-bottomLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">RECRUITERS</div>
                                            <span className="dk-totalCvCount">4225</span>
                                        </div>
                                        <ul className="dk-bottomLeftLists">
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">
                                                    <img
                                                        src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png"
                                                        alt="Mariana"
                                                        className="recruitersImg"
                                                        data-value="6"
                                                        data-name="Mariana"
                                                        data-img="/users/images/5ad5c2940e458.png"
                                                        data-type="rcId"
                                                    />
                                                    Mariana
                                                </span>
                                                <span className="rightItem">1639</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">
                                                    <img
                                                        src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png"
                                                        alt="Deepak"
                                                        className="recruitersImg"
                                                        data-value="1"
                                                        data-name="Deepak"
                                                        data-img="/users/images/5c041fb96042e.png"
                                                        data-type="rcId"
                                                    />
                                                    Deepak
                                                </span>
                                                <span className="rightItem">756</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">
                                                    <img
                                                        src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5e54fdac9c.png"
                                                        alt="Filip"
                                                        className="recruitersImg"
                                                        data-value="7"
                                                        data-name="Filip"
                                                        data-img="/users/images/5ad5e54fdac9c.png"
                                                        data-type="rcId"
                                                    />
                                                    Filip
                                                </span>
                                                <span className="rightItem">719</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="Elis" className="recruitersImg" />Elis
                                                </span>
                                                <span className="rightItem">576</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">
                                                    <img
                                                        src="https://d3eppau9to224z.cloudfront.net/users/images/tehreen.png"
                                                        alt="Tehreem"
                                                        className="recruitersImg"
                                                        data-value="40"
                                                        data-name="Tehreem"
                                                        data-img="/users/images/tehreen.png"
                                                        data-type="rcId"
                                                    />
                                                    Tehreem
                                                </span>
                                                <span className="rightItem">310</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/user.png" alt="No User" className="recruitersImg" />No
                                                    User
                                                </span>
                                                <span className="rightItem">201</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">
                                                    <img
                                                        src="https://d3eppau9to224z.cloudfront.net/users/images/1523633534-luiza.jpg"
                                                        alt="Luiza"
                                                        className="recruitersImg"
                                                        data-value="3"
                                                        data-name="Luiza"
                                                        data-img="/users/images/1523633534-luiza.jpg"
                                                        data-type="rcId"
                                                    />
                                                    Luiza
                                                </span>
                                                <span className="rightItem">21</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">
                                                    <img
                                                        src="https://d3eppau9to224z.cloudfront.net/users/images/5a701dc2182ac.png"
                                                        alt="Mukul"
                                                        className="recruitersImg"
                                                        data-value="2"
                                                        data-name="Mukul"
                                                        data-img="/users/images/5a701dc2182ac.png"
                                                        data-type="rcId"
                                                    />
                                                    Mukul
                                                </span>
                                                <span className="rightItem">3</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-bottomLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">Pipelines</div>
                                            <span className="dk-totalCvCount">4225</span>
                                        </div>
                                        <ul className="dk-bottomLeftLists">
                                            <li style={{ backgroundColor: "#306f88", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">New Case</span><span className="caseStatusCounter">35</span>
                                            </li>
                                            <li style={{ backgroundColor: "#26596d", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Selected</span>
                                                <span className="caseStatusCounter">110</span>
                                            </li>
                                            <li style={{ backgroundColor: "#715f8f", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Today Work</span><span className="caseStatusCounter">301</span>
                                            </li>
                                            <li style={{ backgroundColor: "#57496d", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Processing</span>
                                                <span className="caseStatusCounter">110</span>
                                            </li>
                                            <li style={{ backgroundColor: "#c75d85", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Not Reachable</span><span className="caseStatusCounter">21</span>
                                            </li>
                                            <li style={{ backgroundColor: "#629bd3", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Job Offer</span><span className="caseStatusCounter">3</span>
                                            </li>
                                            <li style={{ backgroundColor: "#42908c", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Queries</span>
                                                <span className="caseStatusCounter">10</span>
                                            </li>
                                            <li style={{ backgroundColor: "#316a67", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Sendouts</span>
                                                <span className="caseStatusCounter">8</span>
                                            </li>
                                            <li style={{ backgroundColor: "#629bd3", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Job Offers</span>
                                                <span className="caseStatusCounter">20</span>
                                            </li>
                                            <li style={{ backgroundColor: "#39485d", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Invoice</span><span className="caseStatusCounter">447</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-bottomLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">Case Status</div>
                                            <span className="dk-totalCvCount">4225</span>
                                        </div>
                                        <ul className="dk-bottomLeftLists">
                                            <li style={{ backgroundColor: "#5c737d", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">New Case</span><span className="caseStatusCounter">35</span>
                                            </li>
                                            <li style={{ backgroundColor: "#796698", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Job Spec</span>
                                                <span className="caseStatusCounter">110</span>
                                            </li>
                                            <li style={{ backgroundColor: "#55496b", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Calling</span><span className="caseStatusCounter">301</span>
                                            </li>
                                            <li style={{ backgroundColor: "#2d9b99", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Interested</span>
                                                <span className="caseStatusCounter">110</span>
                                            </li>
                                            <li style={{ backgroundColor: "#286967", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Sendouts</span><span className="caseStatusCounter">21</span>
                                            </li>
                                            <li style={{ backgroundColor: "#629bd3", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Job Offer</span><span className="caseStatusCounter">3</span>
                                            </li>
                                            <li style={{ backgroundColor: "#4768b0", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Placements</span>
                                                <span className="caseStatusCounter">10</span>
                                            </li>
                                            <li style={{ backgroundColor: "#a84d4d", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Refused Offer</span>
                                                <span className="caseStatusCounter">8</span>
                                            </li>
                                            <li style={{ backgroundColor: "#d9714e", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Credit</span>
                                                <span className="caseStatusCounter">20</span>
                                            </li>
                                            <li style={{ backgroundColor: "#7f5347", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Not Reachable</span><span className="caseStatusCounter">447</span>
                                            </li>
                                            <li style={{ backgroundColor: "#39485d", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Rec Admin</span>
                                                <span className="caseStatusCounter">110</span>
                                            </li>
                                            <li style={{ backgroundColor: "#e2445c", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">Closed</span><span className="caseStatusCounter">3261</span>
                                            </li>
                                            <li style={{ backgroundColor: "#670004", color: "#fff" }}>
                                                <span className="recuriterItem d-flex align-items-center">DB Ready</span>
                                                <span className="caseStatusCounter">110</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-bottomLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">CASE PROGRESS</div>
                                            <span className="dk-totalCvCount">4225</span>
                                        </div>
                                        <ul className="dk-bottomLeftLists">
                                            <li data-type="case_progress" data-value="CV Reviewed">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="CV Reviewed">CV Reviewed</span>
                                                <span className="rightItem" data-type="case_progress" data-value="CV Reviewed">616</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Full Spec Sent">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Full Spec Sent">Full Spec Sent</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Full Spec Sent">502</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Job spec Sent">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Job spec Sent">Job spec Sent</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Job spec Sent">408</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Mini spec Sent">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Mini spec Sent">Mini spec Sent</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Mini spec Sent">392</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Qualifying">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Qualifying">Qualifying</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Qualifying">389</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Blank">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Blank">Blank</span><span className="rightItem" data-type="case_progress" data-value="Blank">0</span>
                                            </li>
                                            <li data-type="case_progress" data-value="New App Stage">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="New App Stage">New App Stage</span>
                                                <span className="rightItem" data-type="case_progress" data-value="New App Stage">265</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Called by Phone">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Called by Phone">Called by Phone</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Called by Phone">252</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Not Reachable">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Not Reachable">Not Reachable</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Not Reachable">213</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Prescreening">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Prescreening">Prescreening</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Prescreening">178</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Duplicate Check">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Duplicate Check">Duplicate Check</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Duplicate Check">122</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Qualifying Stage">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Qualifying Stage">Qualifying Stage</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Qualifying Stage">121</span>
                                            </li>
                                            <li data-type="case_progress" data-value="For Applied Job">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="For Applied Job">For Applied Job</span>
                                                <span className="rightItem" data-type="case_progress" data-value="For Applied Job">75</span>
                                            </li>
                                            <li data-type="case_progress" data-value="To be Contacted">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="To be Contacted">To be Contacted</span>
                                                <span className="rightItem" data-type="case_progress" data-value="To be Contacted">64</span>
                                            </li>
                                            <li data-type="case_progress" data-value="CV Sendout">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="CV Sendout">CV Sendout</span><span className="rightItem" data-type="case_progress" data-value="CV Sendout">57</span>
                                            </li>
                                            <li data-type="case_progress" data-value="HR Interview">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="HR Interview">HR Interview</span>
                                                <span className="rightItem" data-type="case_progress" data-value="HR Interview">41</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Exchanged Emails">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Exchanged Emails">Exchanged Emails</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Exchanged Emails">40</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Not Assigned">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Not Assigned">Not Assigned</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Not Assigned">33</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Interviews Stage">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Interviews Stage">Interviews Stage</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Interviews Stage">33</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Job Spec Sent">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Job Spec Sent">Job Spec Sent</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Job Spec Sent">26</span>
                                            </li>
                                            <li data-type="case_progress" data-value="For Other Live Job">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="For Other Live Job">For Other Live Job</span>
                                                <span className="rightItem" data-type="case_progress" data-value="For Other Live Job">25</span>
                                            </li>
                                            <li data-type="case_progress" data-value="For Database Use">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="For Database Use">For Database Use</span>
                                                <span className="rightItem" data-type="case_progress" data-value="For Database Use">21</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Reviewing Spec">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Reviewing Spec">Reviewing Spec</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Reviewing Spec">18</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Final Interview">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Final Interview">Final Interview</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Final Interview">7</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Have Queries">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Have Queries">Have Queries</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Have Queries">6</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Sendout Submitted">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Sendout Submitted">Sendout Submitted</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Sendout Submitted">3</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Fully Interested">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Fully Interested">Fully Interested</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Fully Interested">3</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Online Test">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Online Test">Online Test</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Online Test">3</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Offer is Prepared">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Offer is Prepared">Offer is Prepared</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Offer is Prepared">2</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Profile">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Profile">Profile</span><span className="rightItem" data-type="case_progress" data-value="Profile">2</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Going to Join">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Going to Join">Going to Join</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Going to Join">2</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Mini Spec Sent">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Mini Spec Sent">Mini Spec Sent</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Mini Spec Sent">2</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Reviewed Offer">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Reviewed Offer">Reviewed Offer</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Reviewed Offer">1</span>
                                            </li>
                                            <li data-type="case_progress" data-value="In Communication">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="In Communication">In Communication</span>
                                                <span className="rightItem" data-type="case_progress" data-value="In Communication">1</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Candidate Profile">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Candidate Profile">Candidate Profile</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Candidate Profile">1</span>
                                            </li>
                                            <li data-type="case_progress" data-value="New Application Stage">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="New Application Stage">New Application Stage</span>
                                                <span className="rightItem" data-type="case_progress" data-value="New Application Stage">1</span>
                                            </li>
                                            <li data-type="case_progress" data-value="To be Offered">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="To be Offered">To be Offered</span>
                                                <span className="rightItem" data-type="case_progress" data-value="To be Offered">1</span>
                                            </li>
                                            <li data-type="case_progress" data-value="Client Interview">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_progress" data-value="Client Interview">Client Interview</span>
                                                <span className="rightItem" data-type="case_progress" data-value="Client Interview">1</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-bottomLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">CASE STAGE</div>
                                            <span className="dk-totalCvCount">4225</span>
                                        </div>
                                        <ul className="dk-bottomLeftLists">
                                            <li data-type="case_stage" data-value="Not Reachable">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Not Reachable">Not Reachable</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Not Reachable">756</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">Project is Filled</span>
                                                <span className="rightItem">676</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">Need Update on Job</span>
                                                <span className="rightItem">346</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">Unmatched Experience</span>
                                                <span className="rightItem">311</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Blank">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Blank">Blank</span><span className="rightItem" data-type="case_stage" data-value="Blank">0</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Will Not Relocate">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Will Not Relocate">Will Not Relocate</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Will Not Relocate">235</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Candidate is Duplicate">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Candidate is Duplicate">Candidate is Duplicate</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Candidate is Duplicate">179</span>
                                            </li>
                                            <li data-type="case_stage" data-value="No Open Job">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="No Open Job">No Open Job</span><span className="rightItem" data-type="case_stage" data-value="No Open Job">166</span>
                                            </li>
                                            <li data-type="case_stage" data-value="2nd Reminder Actioned">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="2nd Reminder Actioned">2nd Reminder Actioned</span>
                                                <span className="rightItem" data-type="case_stage" data-value="2nd Reminder Actioned">142</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Not Interested in Job">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Not Interested in Job">Not Interested in Job</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Not Interested in Job">119</span>
                                            </li>
                                            <li data-type="case_stage" data-value="1st Reminder Actioned">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="1st Reminder Actioned">1st Reminder Actioned</span>
                                                <span className="rightItem" data-type="case_stage" data-value="1st Reminder Actioned">102</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Profile to Review">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Profile to Review">Profile to Review</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Profile to Review">86</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Available in Future">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Available in Future">Available in Future</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Available in Future">86</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Non-EU">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Non-EU">Non-EU</span><span className="rightItem" data-type="case_stage" data-value="Non-EU">76</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Do not Like Salary">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Do not Like Salary">Do not Like Salary</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Do not Like Salary">71</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Candidate not Local">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Candidate not Local">Candidate not Local</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Candidate not Local">70</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Low Level English">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Low Level English">Low Level English</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Low Level English">62</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Project on Hold">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Project on Hold">Project on Hold</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Project on Hold">62</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Low Level Language">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Low Level Language">Low Level Language</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Low Level Language">59</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Over Qualified">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Over Qualified">Over Qualified</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Over Qualified">45</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Got Another Job Offer">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Got Another Job Offer">Got Another Job Offer</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Got Another Job Offer">35</span>
                                            </li>
                                            <li data-type="case_stage" data-value="To Review">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="To Review">To Review</span><span className="rightItem" data-type="case_stage" data-value="To Review">33</span>
                                            </li>
                                            <li data-type="case_stage" data-value="No Action Yet">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="No Action Yet">No Action Yet</span>
                                                <span className="rightItem" data-type="case_stage" data-value="No Action Yet">32</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Waiting for Reply">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Waiting for Reply">Waiting for Reply</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Waiting for Reply">23</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Personal Reasons">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Personal Reasons">Personal Reasons</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Personal Reasons">23</span>
                                            </li>
                                            <li data-type="case_stage" data-value="In Communication">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="In Communication">In Communication</span>
                                                <span className="rightItem" data-type="case_stage" data-value="In Communication">17</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Not Processed">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Not Processed">Not Processed</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Not Processed">12</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Reviewed &amp; Updated">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Reviewed &amp; Updated">Reviewed &amp; Updated</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Reviewed &amp; Updated">12</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Rejected by Client">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Rejected by Client">Rejected by Client</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Rejected by Client">12</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Waiting Candidate  Reply">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Waiting Candidate  Reply">Waiting Candidate Reply</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Waiting Candidate  Reply">9</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Scheduled">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Scheduled">Scheduled</span><span className="rightItem" data-type="case_stage" data-value="Scheduled">8</span>
                                            </li>
                                            <li data-type="case_stage" data-value="To be Contacted">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="To be Contacted">To be Contacted</span>
                                                <span className="rightItem" data-type="case_stage" data-value="To be Contacted">7</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Job Spec to Send">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Job Spec to Send">Job Spec to Send</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Job Spec to Send">7</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Job on Hold">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Job on Hold">Job on Hold</span><span className="rightItem" data-type="case_stage" data-value="Job on Hold">7</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Feedback Pending">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Feedback Pending">Feedback Pending</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Feedback Pending">6</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Offer Other Options">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Offer Other Options">Offer Other Options</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Offer Other Options">5</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Rejected after Assessment">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Rejected after Assessment">Rejected after Assessment</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Rejected after Assessment">5</span>
                                            </li>
                                            <li data-type="case_stage" data-value="To be Scheduled">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="To be Scheduled">To be Scheduled</span>
                                                <span className="rightItem" data-type="case_stage" data-value="To be Scheduled">4</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Sent to Review CV">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Sent to Review CV">Sent to Review CV</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Sent to Review CV">3</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Have Some Queries">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Have Some Queries">Have Some Queries</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Have Some Queries">3</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Email + SMS Sent">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Email + SMS Sent">Email + SMS Sent</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Email + SMS Sent">3</span>
                                            </li>
                                            <li data-type="case_stage" data-value="VISA Confirmation">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="VISA Confirmation">VISA Confirmation</span>
                                                <span className="rightItem" data-type="case_stage" data-value="VISA Confirmation">2</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Will Accept Job">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Will Accept Job">Will Accept Job</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Will Accept Job">2</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Happy with Job Offer">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Happy with Job Offer">Happy with Job Offer</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Happy with Job Offer">2</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Rejected After Review">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Rejected After Review">Rejected After Review</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Rejected After Review">2</span>
                                            </li>
                                            <li data-type="case_stage" data-value="To review">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="To review">To review</span><span className="rightItem" data-type="case_stage" data-value="To review">2</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Withdraw from Interviews">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Withdraw from Interviews">Withdraw from Interviews</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Withdraw from Interviews">2</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Client Put Job on Hold">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Client Put Job on Hold">Client Put Job on Hold</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Client Put Job on Hold">2</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Ask Candidate Choices">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Ask Candidate Choices">Ask Candidate Choices</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Ask Candidate Choices">2</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Do not Contact">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Do not Contact">Do not Contact</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Do not Contact">1</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Ready for Sendout">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Ready for Sendout">Ready for Sendout</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Ready for Sendout">1</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Have Some Queires">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Have Some Queires">Have Some Queires</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Have Some Queires">1</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Not Happy with Offer">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Not Happy with Offer">Not Happy with Offer</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Not Happy with Offer">1</span>
                                            </li>
                                            <li data-type="case_stage" data-value="2nd Reminder Sent">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="2nd Reminder Sent">2nd Reminder Sent</span>
                                                <span className="rightItem" data-type="case_stage" data-value="2nd Reminder Sent">1</span>
                                            </li>
                                            <li data-type="case_stage" data-value="Do not have Docs">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="Do not have Docs">Do not have Docs</span>
                                                <span className="rightItem" data-type="case_stage" data-value="Do not have Docs">1</span>
                                            </li>
                                            <li data-type="case_stage" data-value="To be Informed">
                                                <span className="recuriterItem d-flex align-items-center" data-type="case_stage" data-value="To be Informed">To be Informed</span>
                                                <span className="rightItem" data-type="case_stage" data-value="To be Informed">1</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-bottomLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">COMPANIES</div>
                                            <span className="dk-totalCvCount">4225</span>
                                        </div>
                                        <ul className="dk-bottomLeftLists">
                                            <li data-type="company" data-value="TP Greece">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="TP Greece">TP Greece</span><span className="rightItem" data-type="company" data-value="TP Greece">2177</span>
                                            </li>
                                            <li data-type="company" data-value="Genpact Lisbon">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Genpact Lisbon">Genpact Lisbon</span>
                                                <span className="rightItem" data-type="company" data-value="Genpact Lisbon">326</span>
                                            </li>
                                            <li data-type="company" data-value="Pragmatic Play">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Pragmatic Play">Pragmatic Play</span>
                                                <span className="rightItem" data-type="company" data-value="Pragmatic Play">303</span>
                                            </li>
                                            <li data-type="company" data-value="Webhelp Athens">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Webhelp Athens">Webhelp Athens</span>
                                                <span className="rightItem" data-type="company" data-value="Webhelp Athens">300</span>
                                            </li>
                                            <li data-type="company" data-value="Wipro Bucharest">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Wipro Bucharest">Wipro Bucharest</span>
                                                <span className="rightItem" data-type="company" data-value="Wipro Bucharest">202</span>
                                            </li>
                                            <li data-type="company" data-value="Genpact Bucharest">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Genpact Bucharest">Genpact Bucharest</span>
                                                <span className="rightItem" data-type="company" data-value="Genpact Bucharest">198</span>
                                            </li>
                                            <li data-type="company" data-value="Wipro Timisoara">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Wipro Timisoara">Wipro Timisoara</span>
                                                <span className="rightItem" data-type="company" data-value="Wipro Timisoara">114</span>
                                            </li>
                                            <li data-type="company" data-value="Genpact Cluj">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Genpact Cluj">Genpact Cluj</span><span className="rightItem" data-type="company" data-value="Genpact Cluj">78</span>
                                            </li>
                                            <li data-type="company" data-value="Genpact Hungary">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Genpact Hungary">Genpact Hungary</span>
                                                <span className="rightItem" data-type="company" data-value="Genpact Hungary">71</span>
                                            </li>
                                            <li data-type="company" data-value="Tech Mahindra Lisbon">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Tech Mahindra Lisbon">Tech Mahindra Lisbon</span>
                                                <span className="rightItem" data-type="company" data-value="Tech Mahindra Lisbon">65</span>
                                            </li>
                                            <li data-type="company" data-value="Carousel">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Carousel">Carousel</span><span className="rightItem" data-type="company" data-value="Carousel">65</span>
                                            </li>
                                            <li data-type="company" data-value="Wipro Dublin">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Wipro Dublin">Wipro Dublin</span><span className="rightItem" data-type="company" data-value="Wipro Dublin">61</span>
                                            </li>
                                            <li data-type="company" data-value="Genpact Krakow">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Genpact Krakow">Genpact Krakow</span><span className="rightItem" data-type="company" data-value="Genpact Krakow">59</span>
                                            </li>
                                            <li data-type="company" data-value="HCL Krakow">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="HCL Krakow">HCL Krakow</span><span className="rightItem" data-type="company" data-value="HCL Krakow">48</span>
                                            </li>
                                            <li data-type="company" data-value="Sitel">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Sitel">Sitel</span><span className="rightItem" data-type="company" data-value="Sitel">33</span>
                                            </li>
                                            <li data-type="company" data-value="Webhelp Lisbon">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Webhelp Lisbon">Webhelp Lisbon</span><span className="rightItem" data-type="company" data-value="Webhelp Lisbon">31</span>
                                            </li>
                                            <li data-type="company" data-value="Blank">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Blank">Blank</span><span className="rightItem" data-type="company" data-value="Blank">0</span>
                                            </li>
                                            <li data-type="company" data-value="Genpact Czech Republic">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Genpact Czech Republic">Genpact Czech Republic</span>
                                                <span className="rightItem" data-type="company" data-value="Genpact Czech Republic">20</span>
                                            </li>
                                            <li data-type="company" data-value="HCL Ireland">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="HCL Ireland">HCL Ireland</span><span className="rightItem" data-type="company" data-value="HCL Ireland">19</span>
                                            </li>
                                            <li data-type="company" data-value="Tech M Timisoara">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Tech M Timisoara">Tech M Timisoara</span>
                                                <span className="rightItem" data-type="company" data-value="Tech M Timisoara">13</span>
                                            </li>
                                            <li data-type="company" data-value="Tech M Bucharest">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Tech M Bucharest">Tech M Bucharest</span>
                                                <span className="rightItem" data-type="company" data-value="Tech M Bucharest">7</span>
                                            </li>
                                            <li data-type="company" data-value="LRN Ireland">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="LRN Ireland">LRN Ireland</span><span className="rightItem" data-type="company" data-value="LRN Ireland">5</span>
                                            </li>
                                            <li data-type="company" data-value="Genpact Poland">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Genpact Poland">Genpact Poland</span><span className="rightItem" data-type="company" data-value="Genpact Poland">4</span>
                                            </li>
                                            <li data-type="company" data-value="Wipro Porto">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Wipro Porto">Wipro Porto</span><span className="rightItem" data-type="company" data-value="Wipro Porto">3</span>
                                            </li>
                                            <li data-type="company" data-value="Wipro Belgium">
                                                <span className="recuriterItem d-flex align-items-center" data-type="company" data-value="Wipro Belgium">Wipro Belgium</span><span className="rightItem" data-type="company" data-value="Wipro Belgium">2</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-bottomLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">LANGUAGE</div>
                                            <span className="dk-totalCvCount">4225</span>
                                        </div>
                                        <ul className="dk-bottomLeftLists">
                                            <li data-type="lang" data-value="Italian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Italian">Italian</span><span className="rightItem" data-type="lang" data-value="Italian">908</span>
                                            </li>
                                            <li data-type="lang" data-value="Spanish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Spanish">Spanish</span><span className="rightItem" data-type="lang" data-value="Spanish">771</span>
                                            </li>
                                            <li data-type="lang" data-value="English">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="English">English</span><span className="rightItem" data-type="lang" data-value="English">376</span>
                                            </li>
                                            <li data-type="lang" data-value="Portuguese">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Portuguese">Portuguese</span><span className="rightItem" data-type="lang" data-value="Portuguese">356</span>
                                            </li>
                                            <li data-type="lang" data-value="French">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="French">French</span><span className="rightItem" data-type="lang" data-value="French">272</span>
                                            </li>
                                            <li data-type="lang" data-value="Polish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Polish">Polish</span><span className="rightItem" data-type="lang" data-value="Polish">209</span>
                                            </li>
                                            <li data-type="lang" data-value="Romanian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Romanian">Romanian</span><span className="rightItem" data-type="lang" data-value="Romanian">157</span>
                                            </li>
                                            <li data-type="lang" data-value="German">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="German">German</span><span className="rightItem" data-type="lang" data-value="German">138</span>
                                            </li>
                                            <li data-type="lang" data-value="Latin American Spanish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Latin American Spanish">Latin American Spanish</span>
                                                <span className="rightItem" data-type="lang" data-value="Latin American Spanish">132</span>
                                            </li>
                                            <li data-type="lang" data-value="Turkish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Turkish">Turkish</span><span className="rightItem" data-type="lang" data-value="Turkish">93</span>
                                            </li>
                                            <li data-type="lang" data-value="Hungarian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Hungarian">Hungarian</span><span className="rightItem" data-type="lang" data-value="Hungarian">89</span>
                                            </li>
                                            <li data-type="lang" data-value="Bulgarian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Bulgarian">Bulgarian</span><span className="rightItem" data-type="lang" data-value="Bulgarian">82</span>
                                            </li>
                                            <li data-type="lang" data-value="Russian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Russian">Russian</span><span className="rightItem" data-type="lang" data-value="Russian">71</span>
                                            </li>
                                            <li data-type="lang" data-value="Dutch">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Dutch">Dutch</span><span className="rightItem" data-type="lang" data-value="Dutch">63</span>
                                            </li>
                                            <li data-type="lang" data-value="Arabic">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Arabic">Arabic</span><span className="rightItem" data-type="lang" data-value="Arabic">54</span>
                                            </li>
                                            <li data-type="lang" data-value="Lithuanian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Lithuanian">Lithuanian</span><span className="rightItem" data-type="lang" data-value="Lithuanian">53</span>
                                            </li>
                                            <li data-type="lang" data-value="Croatian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Croatian">Croatian</span><span className="rightItem" data-type="lang" data-value="Croatian">46</span>
                                            </li>
                                            <li data-type="lang" data-value="Norwegian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Norwegian">Norwegian</span><span className="rightItem" data-type="lang" data-value="Norwegian">42</span>
                                            </li>
                                            <li data-type="lang" data-value="Swedish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Swedish">Swedish</span><span className="rightItem" data-type="lang" data-value="Swedish">37</span>
                                            </li>
                                            <li data-type="lang" data-value="Latvian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Latvian">Latvian</span><span className="rightItem" data-type="lang" data-value="Latvian">36</span>
                                            </li>
                                            <li data-type="lang" data-value="Greek">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Greek">Greek</span><span className="rightItem" data-type="lang" data-value="Greek">32</span>
                                            </li>
                                            <li data-type="lang" data-value="Czech">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Czech">Czech</span><span className="rightItem" data-type="lang" data-value="Czech">31</span>
                                            </li>
                                            <li data-type="lang" data-value="Slovakian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Slovakian">Slovakian</span><span className="rightItem" data-type="lang" data-value="Slovakian">31</span>
                                            </li>
                                            <li data-type="lang" data-value="Blank">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Blank">Blank</span><span className="rightItem" data-type="lang" data-value="Blank">0</span>
                                            </li>
                                            <li data-type="lang" data-value="Slovenian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Slovenian">Slovenian</span><span className="rightItem" data-type="lang" data-value="Slovenian">19</span>
                                            </li>
                                            <li data-type="lang" data-value="Chinese">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Chinese">Chinese</span><span className="rightItem" data-type="lang" data-value="Chinese">14</span>
                                            </li>
                                            <li data-type="lang" data-value="Korean">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Korean">Korean</span><span className="rightItem" data-type="lang" data-value="Korean">13</span>
                                            </li>
                                            <li data-type="lang" data-value="Hebrew">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Hebrew">Hebrew</span><span className="rightItem" data-type="lang" data-value="Hebrew">12</span>
                                            </li>
                                            <li data-type="lang" data-value="Finnish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Finnish">Finnish</span><span className="rightItem" data-type="lang" data-value="Finnish">10</span>
                                            </li>
                                            <li data-type="lang" data-value="Estonian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Estonian">Estonian</span><span className="rightItem" data-type="lang" data-value="Estonian">9</span>
                                            </li>
                                            <li data-type="lang" data-value="Danish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Danish">Danish</span><span className="rightItem" data-type="lang" data-value="Danish">9</span>
                                            </li>
                                            <li data-type="lang" data-value="Irish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Irish">Irish</span><span className="rightItem" data-type="lang" data-value="Irish">9</span>
                                            </li>
                                            <li data-type="lang" data-value="Swedish&nbsp;">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Swedish&nbsp;">Swedish&nbsp;</span><span className="rightItem" data-type="lang" data-value="Swedish&nbsp;">8</span>
                                            </li>
                                            <li data-type="lang" data-value="Japanese">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Japanese">Japanese</span><span className="rightItem" data-type="lang" data-value="Japanese">5</span>
                                            </li>
                                            <li data-type="lang" data-value="Dutch/Flemish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Dutch/Flemish">Dutch/Flemish</span><span className="rightItem" data-type="lang" data-value="Dutch/Flemish">5</span>
                                            </li>
                                            <li data-type="lang" data-value="Spanish + Portuguese">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Spanish + Portuguese">Spanish + Portuguese</span>
                                                <span className="rightItem" data-type="lang" data-value="Spanish + Portuguese">4</span>
                                            </li>
                                            <li data-type="lang" data-value="Icelandic">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Icelandic">Icelandic</span><span className="rightItem" data-type="lang" data-value="Icelandic">3</span>
                                            </li>
                                            <li data-type="lang" data-value="Group Language">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Group Language">Group Language</span><span className="rightItem" data-type="lang" data-value="Group Language">3</span>
                                            </li>
                                            <li data-type="lang" data-value="Maltese">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Maltese">Maltese</span><span className="rightItem" data-type="lang" data-value="Maltese">1</span>
                                            </li>
                                            <li data-type="lang" data-value="Flemish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Flemish">Flemish</span><span className="rightItem" data-type="lang" data-value="Flemish">1</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-bottomLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">CV Source</div>
                                            <span className="dk-totalCvCount">4225</span>
                                        </div>
                                        <ul className="dk-bottomLeftLists">
                                            <li data-type="lang" data-value="Italian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Italian">Italian</span><span className="rightItem" data-type="lang" data-value="Italian">908</span>
                                            </li>
                                            <li data-type="lang" data-value="Spanish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Spanish">Spanish</span><span className="rightItem" data-type="lang" data-value="Spanish">771</span>
                                            </li>
                                            <li data-type="lang" data-value="English">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="English">English</span><span className="rightItem" data-type="lang" data-value="English">376</span>
                                            </li>
                                            <li data-type="lang" data-value="Portuguese">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Portuguese">Portuguese</span><span className="rightItem" data-type="lang" data-value="Portuguese">356</span>
                                            </li>
                                            <li data-type="lang" data-value="French">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="French">French</span><span className="rightItem" data-type="lang" data-value="French">272</span>
                                            </li>
                                            <li data-type="lang" data-value="Polish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Polish">Polish</span><span className="rightItem" data-type="lang" data-value="Polish">209</span>
                                            </li>
                                            <li data-type="lang" data-value="Romanian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Romanian">Romanian</span><span className="rightItem" data-type="lang" data-value="Romanian">157</span>
                                            </li>
                                            <li data-type="lang" data-value="German">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="German">German</span><span className="rightItem" data-type="lang" data-value="German">138</span>
                                            </li>
                                            <li data-type="lang" data-value="Latin American Spanish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Latin American Spanish">Latin American Spanish</span>
                                                <span className="rightItem" data-type="lang" data-value="Latin American Spanish">132</span>
                                            </li>
                                            <li data-type="lang" data-value="Turkish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Turkish">Turkish</span><span className="rightItem" data-type="lang" data-value="Turkish">93</span>
                                            </li>
                                            <li data-type="lang" data-value="Hungarian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Hungarian">Hungarian</span><span className="rightItem" data-type="lang" data-value="Hungarian">89</span>
                                            </li>
                                            <li data-type="lang" data-value="Bulgarian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Bulgarian">Bulgarian</span><span className="rightItem" data-type="lang" data-value="Bulgarian">82</span>
                                            </li>
                                            <li data-type="lang" data-value="Russian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Russian">Russian</span><span className="rightItem" data-type="lang" data-value="Russian">71</span>
                                            </li>
                                            <li data-type="lang" data-value="Dutch">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Dutch">Dutch</span><span className="rightItem" data-type="lang" data-value="Dutch">63</span>
                                            </li>
                                            <li data-type="lang" data-value="Arabic">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Arabic">Arabic</span><span className="rightItem" data-type="lang" data-value="Arabic">54</span>
                                            </li>
                                            <li data-type="lang" data-value="Lithuanian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Lithuanian">Lithuanian</span><span className="rightItem" data-type="lang" data-value="Lithuanian">53</span>
                                            </li>
                                            <li data-type="lang" data-value="Croatian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Croatian">Croatian</span><span className="rightItem" data-type="lang" data-value="Croatian">46</span>
                                            </li>
                                            <li data-type="lang" data-value="Norwegian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Norwegian">Norwegian</span><span className="rightItem" data-type="lang" data-value="Norwegian">42</span>
                                            </li>
                                            <li data-type="lang" data-value="Swedish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Swedish">Swedish</span><span className="rightItem" data-type="lang" data-value="Swedish">37</span>
                                            </li>
                                            <li data-type="lang" data-value="Latvian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Latvian">Latvian</span><span className="rightItem" data-type="lang" data-value="Latvian">36</span>
                                            </li>
                                            <li data-type="lang" data-value="Greek">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Greek">Greek</span><span className="rightItem" data-type="lang" data-value="Greek">32</span>
                                            </li>
                                            <li data-type="lang" data-value="Czech">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Czech">Czech</span><span className="rightItem" data-type="lang" data-value="Czech">31</span>
                                            </li>
                                            <li data-type="lang" data-value="Slovakian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Slovakian">Slovakian</span><span className="rightItem" data-type="lang" data-value="Slovakian">31</span>
                                            </li>
                                            <li data-type="lang" data-value="Blank">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Blank">Blank</span><span className="rightItem" data-type="lang" data-value="Blank">0</span>
                                            </li>
                                            <li data-type="lang" data-value="Slovenian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Slovenian">Slovenian</span><span className="rightItem" data-type="lang" data-value="Slovenian">19</span>
                                            </li>
                                            <li data-type="lang" data-value="Chinese">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Chinese">Chinese</span><span className="rightItem" data-type="lang" data-value="Chinese">14</span>
                                            </li>
                                            <li data-type="lang" data-value="Korean">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Korean">Korean</span><span className="rightItem" data-type="lang" data-value="Korean">13</span>
                                            </li>
                                            <li data-type="lang" data-value="Hebrew">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Hebrew">Hebrew</span><span className="rightItem" data-type="lang" data-value="Hebrew">12</span>
                                            </li>
                                            <li data-type="lang" data-value="Finnish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Finnish">Finnish</span><span className="rightItem" data-type="lang" data-value="Finnish">10</span>
                                            </li>
                                            <li data-type="lang" data-value="Estonian">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Estonian">Estonian</span><span className="rightItem" data-type="lang" data-value="Estonian">9</span>
                                            </li>
                                            <li data-type="lang" data-value="Danish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Danish">Danish</span><span className="rightItem" data-type="lang" data-value="Danish">9</span>
                                            </li>
                                            <li data-type="lang" data-value="Irish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Irish">Irish</span><span className="rightItem" data-type="lang" data-value="Irish">9</span>
                                            </li>
                                            <li data-type="lang" data-value="Swedish&nbsp;">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Swedish&nbsp;">Swedish&nbsp;</span><span className="rightItem" data-type="lang" data-value="Swedish&nbsp;">8</span>
                                            </li>
                                            <li data-type="lang" data-value="Japanese">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Japanese">Japanese</span><span className="rightItem" data-type="lang" data-value="Japanese">5</span>
                                            </li>
                                            <li data-type="lang" data-value="Dutch/Flemish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Dutch/Flemish">Dutch/Flemish</span><span className="rightItem" data-type="lang" data-value="Dutch/Flemish">5</span>
                                            </li>
                                            <li data-type="lang" data-value="Spanish + Portuguese">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Spanish + Portuguese">Spanish + Portuguese</span>
                                                <span className="rightItem" data-type="lang" data-value="Spanish + Portuguese">4</span>
                                            </li>
                                            <li data-type="lang" data-value="Icelandic">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Icelandic">Icelandic</span><span className="rightItem" data-type="lang" data-value="Icelandic">3</span>
                                            </li>
                                            <li data-type="lang" data-value="Group Language">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Group Language">Group Language</span><span className="rightItem" data-type="lang" data-value="Group Language">3</span>
                                            </li>
                                            <li data-type="lang" data-value="Maltese">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Maltese">Maltese</span><span className="rightItem" data-type="lang" data-value="Maltese">1</span>
                                            </li>
                                            <li data-type="lang" data-value="Flemish">
                                                <span className="recuriterItem d-flex align-items-center" data-type="lang" data-value="Flemish">Flemish</span><span className="rightItem" data-type="lang" data-value="Flemish">1</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-bottomLeftboxesCols">
                                        <div className="dk-toptitle">
                                            <div className="dk-colstitle">Account M</div>
                                            <span className="dk-totalCvCount">4225</span>
                                        </div>
                                        <ul className="dk-bottomLeftLists">
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">
                                                    <img
                                                        src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png"
                                                        alt="Mariana"
                                                        className="recruitersImg"
                                                        data-value="6"
                                                        data-name="Mariana"
                                                        data-img="/users/images/5ad5c2940e458.png"
                                                        data-type="rcId"
                                                    />
                                                    Mariana
                                                </span>
                                                <span className="rightItem">1639</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">
                                                    <img
                                                        src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png"
                                                        alt="Deepak"
                                                        className="recruitersImg"
                                                        data-value="1"
                                                        data-name="Deepak"
                                                        data-img="/users/images/5c041fb96042e.png"
                                                        data-type="rcId"
                                                    />
                                                    Deepak
                                                </span>
                                                <span className="rightItem">756</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">
                                                    <img
                                                        src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5e54fdac9c.png"
                                                        alt="Filip"
                                                        className="recruitersImg"
                                                        data-value="7"
                                                        data-name="Filip"
                                                        data-img="/users/images/5ad5e54fdac9c.png"
                                                        data-type="rcId"
                                                    />
                                                    Filip
                                                </span>
                                                <span className="rightItem">719</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="Elis" className="recruitersImg" />Elis
                                                </span>
                                                <span className="rightItem">576</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">
                                                    <img
                                                        src="https://d3eppau9to224z.cloudfront.net/users/images/tehreen.png"
                                                        alt="Tehreem"
                                                        className="recruitersImg"
                                                        data-value="40"
                                                        data-name="Tehreem"
                                                        data-img="/users/images/tehreen.png"
                                                        data-type="rcId"
                                                    />
                                                    Tehreem
                                                </span>
                                                <span className="rightItem">310</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/user.png" alt="No User" className="recruitersImg" />No
                                                    User
                                                </span>
                                                <span className="rightItem">201</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">
                                                    <img
                                                        src="https://d3eppau9to224z.cloudfront.net/users/images/1523633534-luiza.jpg"
                                                        alt="Luiza"
                                                        className="recruitersImg"
                                                        data-value="3"
                                                        data-name="Luiza"
                                                        data-img="/users/images/1523633534-luiza.jpg"
                                                        data-type="rcId"
                                                    />
                                                    Luiza
                                                </span>
                                                <span className="rightItem">21</span>
                                            </li>
                                            <li>
                                                <span className="recuriterItem d-flex align-items-center">
                                                    <img
                                                        src="https://d3eppau9to224z.cloudfront.net/users/images/5a701dc2182ac.png"
                                                        alt="Mukul"
                                                        className="recruitersImg"
                                                        data-value="2"
                                                        data-name="Mukul"
                                                        data-img="/users/images/5a701dc2182ac.png"
                                                        data-type="rcId"
                                                    />
                                                    Mukul
                                                </span>
                                                <span className="rightItem">3</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default index
