import React from 'react';
import { Link } from "react-router-dom";

const MarianaProjectKpiLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="dk-moduleTraining-leftPanel">
                <ul>
                    <li><Link to="/projects/mariana/kpi/summary" className={`${pathname.match('/projects/mariana/kpi/summary') ? 'active' : ''}`}>Summary</Link></li>
                    <li><Link to="#" className={`${pathname.match('') ? '' : ''}`}>Step 1</Link></li>
                    <li><Link to="#" className={`${pathname.match('') ? '' : ''}`}>Step 2</Link></li>
                    <li><Link to="#" className={`${pathname.match('') ? '' : ''}`}>Step 3</Link></li>
                </ul>
            </div>
        </>
    )
}

export default MarianaProjectKpiLeftPanel;