import React, { useState } from 'react';
import AddNew from "./modal/AddNew";
import { Link } from "react-router-dom";
import "./consbenefit.css"



const CostbenefitsTable = (props) => {
    const [isAddNew, setIsAddNew] = useState(false);
    const hideaddNewModal = () => {
        setIsAddNew(false)
    }
    return (
        <>
            {
                (isAddNew) ? <AddNew onHide={hideaddNewModal} /> : null
            }
            <div className="row mt-3">
                <div className="col-md-12">
                    {/* <div className="dk-costbenefitPageHead">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="dk-costbenefitLeft">
                                <div className="dk-mailshotsSearch mr-2">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search..." />
                                    </div>
                                </div>
                                <button className="dk-cosfilterbtn"><i className="fas fa-plus"></i></button>
                            </div>
                            <h2 className="title">{props.tableTitle}</h2>
                            <div className="dk-costbenefitTopRight d-flex">
                                <button className="mr-2"><i className="fas fa-plus"></i></button>
                                <button className="mr-2"><i className="fas fa-arrows-alt"></i></button>
                                <div className="dk-reSLoginFilter">
                                    <SelectItem options={services} placeholder="Services" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="dk-costbenefitTableCont table-responsive">
                        <table className="table dk-costbenefitTable" cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr>
                                    <th>SNo.</th>
                                    <th>Name of Resource</th>
                                    <th>Services</th>
                                    <th>Placements</th>
                                    <th>Cost</th>
                                    <th>Income</th>
                                    <th>Up/Dowm</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Indeed</td>
                                    <td>Job posting only</td>
                                    <td>500</td>
                                    <td>500</td>
                                    <td>500</td>
                                    <td>0</td>
                                    <td>
                                        <div className="dk-costbenAction">
                                            {/* <Link className="dk-view-btn mr-2" to="#">
                                                <i className="fa fa-eye " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link> */}
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" id="6114c8059ede620015293b33" to="#">
                                                <i className="fa fa-trash " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>ELJ</td>
                                    <td>Job posting only</td>
                                    <td>500</td>
                                    <td>500</td>
                                    <td>500</td>
                                    <td>0</td>
                                    <td>
                                        <div className="dk-costbenAction">
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" id="6114c8059ede620015293b33" to="#">
                                                <i className="fa fa-trash " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>MLV</td>
                                    <td>Post+ CV Search</td>
                                    <td>700</td>
                                    <td>700</td>
                                    <td>700</td>
                                    <td>0</td>
                                    <td>
                                        <div className="dk-costbenAction">
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" id="6114c8059ede620015293b33" to="#">
                                                <i className="fa fa-trash " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Hipo</td>
                                    <td>Post+ CV Search</td>
                                    <td>100</td>
                                    <td>100</td>
                                    <td>0</td>
                                    <td>100</td>
                                    <td>
                                        <div className="dk-costbenAction">
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" id="6114c8059ede620015293b33" to="#">
                                                <i className="fa fa-trash " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Linkedin</td>
                                    <td>Post+ CV Search</td>
                                    <td>100</td>
                                    <td>100</td>
                                    <td>100</td>
                                    <td>0</td>
                                    <td>
                                        <div className="dk-costbenAction">
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" id="6114c8059ede620015293b33" to="#">
                                                <i className="fa fa-trash " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Ejobs</td>
                                    <td>Post+ CV Search</td>
                                    <td>400</td>
                                    <td>400</td>
                                    <td>150</td>
                                    <td>250</td>
                                    <td>
                                        <div className="dk-costbenAction">
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" id="6114c8059ede620015293b33" to="#">
                                                <i className="fa fa-trash " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Find jobs</td>
                                    <td>Job posting only</td>
                                    <td>200</td>
                                    <td>200</td>
                                    <td>0</td>
                                    <td>200</td>
                                    <td>
                                        <div className="dk-costbenAction">
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" id="6114c8059ede620015293b33" to="#">
                                                <i className="fa fa-trash " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Monster</td>
                                    <td>Job posting only</td>
                                    <td>300</td>
                                    <td>300</td>
                                    <td>0</td>
                                    <td>300</td>
                                    <td>
                                        <div className="dk-costbenAction">
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" id="6114c8059ede620015293b33" to="#">
                                                <i className="fa fa-trash " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Stepstone</td>
                                    <td>Job posting only</td>
                                    <td>1500</td>
                                    <td>1500</td>
                                    <td>0</td>
                                    <td>1500</td>
                                    <td>
                                        <div className="dk-costbenAction">
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" id="6114c8059ede620015293b33" to="#">
                                                <i className="fa fa-trash " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>Bakika</td>
                                    <td>Job posting only</td>
                                    <td>200</td>
                                    <td>200</td>
                                    <td>0</td>
                                    <td>200</td>
                                    <td>
                                        <div className="dk-costbenAction">
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" id="6114c8059ede620015293b33" to="#">
                                                <i className="fa fa-trash " aria-hidden="true" id="6114c8059ede620015293b33"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>200</td>
                                    <td>200</td>
                                    <td>0</td>
                                    <td>200</td>
                                    <td>
                                        &nbsp;
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CostbenefitsTable;