import React from 'react';
import { Link } from "react-router-dom";
import DeepakProjectLiveLeftPanel from "../leftpanel/deepak/DeepakProjectLiveLeftPanel";
import { FaPlus } from 'react-icons/fa';
import '../moduleprojects.scss';

const DeepakProjectLive = () => {
    return (
        <>
            <div className="dk-moduleProjects-main">
                <div className="dk-moduleTraining-cols">
                    <DeepakProjectLiveLeftPanel />
                    <div className="dk-moduleTraining-rightPanel">
                        <div className="title">Summary</div>
                        <div className="dk-addNew-btn">
                            <Link to="#"><FaPlus /> Add New</Link>
                        </div>
                        <div className="dk-moduleTraining-rightCols">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeepakProjectLive;
