import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';


const StepOneDetailLeftPanel = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box>
                <TabContext value={value}>
                    <div className="d-flex">
                        <div className="dk-detailLefttabsMain">
                            <Box>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Summary" value="1" />
                                    <Tab label="Sample 2" value="2" />
                                    <Tab label="Sample 3" value="3" />
                                    <Tab label="Sample 4" value="4" />
                                    <Tab label="Sample 5" value="5" />
                                    <Tab label="Sample 6" value="6" />
                                    <Tab label="Sample 7" value="7" />
                                </TabList>
                            </Box>
                        </div>
                        <div className="dk-tabDetail-flex">
                            <TabPanel value="1">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!
                            </TabPanel>
                            <TabPanel value="2">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!. Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati voluptates similique rerum pariatur sunt illo unde ex nostrum laudantium, recusandae quia quibusdam dolore debitis ea quod culpa deserunt sit minus!
                            </TabPanel>
                            <TabPanel value="3">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!
                            </TabPanel>
                            <TabPanel value="4">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!. Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero laborum ipsa ad dolorem atque voluptas eaque, possimus eveniet, esse, facere architecto error. Vel molestias maiores consequuntur enim, harum numquam nemo.
                            </TabPanel>
                            <TabPanel value="5">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!
                            </TabPanel>
                            <TabPanel value="6">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!. Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero laborum ipsa ad dolorem atque voluptas eaque, possimus eveniet, esse, facere architecto error. Vel molestias maiores consequuntur enim, harum numquam nemo.
                            </TabPanel>
                            <TabPanel value="7">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!
                            </TabPanel>

                        </div>
                    </div>
                </TabContext>
            </Box>


        </>
    )
}

export default StepOneDetailLeftPanel;

