import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
// import './addskils.scss';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import { clearState, listAllTags, listSelectedTags, addTechTags, removeTechTags } from "../../../../../../../slice/candidates/candidatesSlice";


const TechnicalSkills = ({ hidePopup, candidateId }) => {
    const dispatch = useDispatch();
    const { candidateProfileTechAllList } = useSelector(state => state.common);
    const { isPreTagsLoading, preTagsList, isTagsLoading, tagsList } = useSelector(state => state.candidate);

    const [category, setCategory] = useState('Developer')
    const [categoryId, setCategoryId] = useState('115')
    const [activeKey, setActiveKey] = useState('115==Developer')

    const [allTags, setAllTags] = useState([])
    const [selectedTags, setSelectedTags] = useState([])

    const handleSelect = async (eventKey) => {
        let arr = eventKey.split('==');
        setActiveKey(eventKey)
        setCategoryId(arr[0])
        setCategory(arr[1])
    }

    const selectTag = async (canId, value) => {
        await dispatch(addTechTags({ canId, category, value }))
    }

    const removeTag = async (id) => {
        await dispatch(removeTechTags({ id }))
    }

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(listAllTags({ id: categoryId }))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchSelectedList = async () => {
            try {
                await dispatch(listSelectedTags({ canId: candidateId, category }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
        fetchSelectedList();
    }, [categoryId])

    useEffect(() => {
        if (!isPreTagsLoading) {
            setAllTags(preTagsList)
        }
        if (!isTagsLoading) {
            setSelectedTags(tagsList)
        }

    }, [isPreTagsLoading, isTagsLoading])

    useEffect(() => {
        setSelectedTags(tagsList)
    }, [tagsList])
    return (
        <>
            <div className="dk-addSkillsMain">
                <Tab.Container id="left-tabs-example-tech" defaultActiveKey={activeKey}>
                    <div className="d-flex" style={{paddingLeft: "15px"}}>
                        <div className="dk-addSkills-tabNav">
                            <div className="title">Category</div>
                            <div className="nav">
                                {
                                    candidateProfileTechAllList.map((item, index) =>
                                        <div key={index} className="nav-item" onClick={() => handleSelect(item.id + "==" + item.name)}>
                                            <a href="#" role="tab" data-rb-event-key="tabs_1" id="left-tabs-example-tab-tabs_1" aria-controls="left-tabs-example-tabpane-tabs_1" aria-selected="true" className={`nav-link ${(activeKey == item.id + "==" + item.name) ? 'active' : null}`}>{item.name}</a>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="dk-addSkills-tabContainer">
                            <Tab.Content>
                                {
                                    candidateProfileTechAllList.map((item, index) =>
                                        <Tab.Pane eventKey={item.id + "==" + item.name}>
                                            <div className="dk-tabsPanel">
                                                <div className="dk-tabsPanel-flex">
                                                    <div className="dk-tabsPanle-tagsCols pr-0">
                                                        <div className="title">Select Tags</div>
                                                        <div className="dk-tabsPanel-tags dk-currentBg">
                                                            {
                                                                allTags && allTags.length > 0 && allTags.map((preTagsItem, preTagsIndex) =>
                                                                    <li onClick={() => selectTag(candidateId, preTagsItem.value)} className="dk-currentTags" key={`preTags${preTagsIndex}`}>{preTagsItem.value}
                                                                        <span>
                                                                            <FontAwesomeIcon icon={faPlus} />
                                                                        </span>
                                                                    </li>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="dk-tabsPanle-tagsCols pl-0">
                                                        <div className="title">Current Tags</div>
                                                        <div className="dk-tabsPanel-tags">
                                                            {
                                                                selectedTags && selectedTags.length > 0 && selectedTags.map((selectedTagsItem, selectedTagsIndex) =>
                                                                    <li onClick={() => removeTag(selectedTagsItem.id)} key={selectedTagsIndex} className="dk-currentUsedTags">{selectedTagsItem.value} <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    )
                                }
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default TechnicalSkills;