import React from 'react';
import CheckInIcon from "../../../../../assets/images/checkin_icon.png";
import TestToolIcon from "../../../../../assets/images/testtool_icon.png";
import ReviewMailboxIcon from "../../../../../assets/images/reviewmailbox_icon.png";
import TeamMeetingIcon from "../../../../../assets/images/teammeeting_icon.png";
import WorkPlanIcon from "../../../../../assets/images/workplan_icon_2.png";
import PreparaActionIcon from "../../../../../assets/images/preparaaction_icon.png";
import SkypeIcon from "../../../../../assets/images/skype.png";
import DkMasterIcon from "../../../../../assets/images/dk_icon.png";
import './getstartedsumm.scss';

const Summary = () => {
    return (
        <>
            <div className='container-fluid dk-getstarted-mainPageCon'>
                {/* <h3 className='text-center w-100'> Get Started Summary</h3> */}
                <div className='row dkg-getstarted-pageCon'>
                    <div className='col-md-8 pl-0'>
                        <div className='dkg-getstarted-rowCon'>
                            <div className='dkg-getstarted-leftCon'>
                                <div className='dkg-getstarted-boxCon'>
                                    <div className='dkg-getstarted-Header'>
                                        <h5 className='dkg-getstarted-Title'>STEP 1 - CHECKIN</h5>
                                    </div>
                                    <div className='dkg-getstarted-Body'>
                                        <div className="d-flex align-items-center w-100">
                                            <div className="dk-getStartedIcon">
                                                <img src={CheckInIcon} alt="" />
                                            </div>
                                            <h5 className='dk-getstartedbox-title'></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='dkg-getstarted-boxCon'>
                                    <div className='dkg-getstarted-Header'>
                                        <h5 className='dkg-getstarted-Title'>STEP 2 - TEST TOOLS</h5>
                                    </div>
                                    <div className='dkg-getstarted-Body'>
                                        <div className="d-flex align-items-center w-100">
                                            <div className="dk-getStartedIcon">
                                                <img src={TestToolIcon} alt="" />
                                            </div>
                                            <h5 className='dk-getstartedbox-title'></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='dkg-getstarted-rowCon'>
                            <div className='dkg-getstarted-leftCon'>
                                <div className='dkg-getstarted-boxCon'>
                                    <div className='dkg-getstarted-Header'>
                                        <h5 className='dkg-getstarted-Title'>STEP 4 - REVIEW MAILBOX</h5>
                                    </div>
                                    <div className='dkg-getstarted-Body'>
                                        <div className="d-flex align-items-center w-100">
                                            <div className="dk-getStartedIcon">
                                                <img src={ReviewMailboxIcon} alt="" />
                                            </div>
                                            <h5 className='dk-getstartedbox-title'></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='dkg-getstarted-boxCon'>
                                    <div className='dkg-getstarted-Header'>
                                        <h5 className='dkg-getstarted-Title'>STEP 5 - TEAM MEETING</h5>
                                    </div>
                                    <div className='dkg-getstarted-Body'>
                                        <div className="d-flex align-items-center w-100">
                                            <div className="dk-getStartedIcon">
                                                <img src={TeamMeetingIcon} alt="" />
                                            </div>
                                            <h5 className='dk-getstartedbox-title'></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='dkg-getstarted-rowCon'>
                            <div className='dkg-getstarted-leftCon'>
                                <div className='dkg-getstarted-boxCon'>
                                    <div className='dkg-getstarted-Header'>
                                        <h5 className='dkg-getstarted-Title'>STEP 6 - WORKPLAN</h5>
                                    </div>
                                    <div className='dkg-getstarted-Body'>
                                        <div className="d-flex align-items-center w-100">
                                            <div className="dk-getStartedIcon">
                                                <img src={WorkPlanIcon} alt="" />
                                            </div>
                                            <h5 className='dk-getstartedbox-title'></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='dkg-getstarted-boxCon'>
                                    <div className='dkg-getstarted-Header'>
                                        <h5 className='dkg-getstarted-Title'>STEP 7 - PREPARATION</h5>
                                    </div>
                                    <div className='dkg-getstarted-Body'>
                                        <div className="d-flex align-items-center w-100">
                                            <div className="dk-getStartedIcon">
                                                <img src={PreparaActionIcon} alt="" />
                                            </div>
                                            <h5 className='dk-getstartedbox-title'></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 pr-0'>
                        <div className='dkg-getstarted-rightCon'>
                            <div className='dkg-getstarted-rightboxCon'>
                                <div className='dkg-getstarted-Header'>
                                    <h5 className='dkg-getstarted-Title'>STEP 3 - SOFTWARE TO LOGIN</h5>
                                </div>
                                <div className='dkg-getstarted-Body'>
                                    <div className="dk-getstartedLogin-table">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>
                                                        <div className="dk-softIcon">
                                                            <img src={DkMasterIcon} alt="" />
                                                        </div>
                                                    </td>
                                                    <td>DK Masterbox</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>
                                                        <div className="dk-softIcon">
                                                            <img src={SkypeIcon} alt="" />
                                                        </div>
                                                    </td>
                                                    <td>Skype</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Summary;
