import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "./jobsrightmodal.scss";
import { Modal, Tab, Nav } from 'react-bootstrap';
import JobTitle from './details/jobtitle/Jobtitle'
import Instructions from './details/instructions/Instructions'
import Adplan from './details/adplan/Adplan'
import Adstopost from './details/adstopost/Adstopost'
import Cvsearch from './details/cvsearch/Cvsearch'
import Campaigns from './details/campaigns/Campaigns'
import Mailshot from './details/mailshot/Mailshot'
import Jdetails from './details/jobdetails/Jobdetails'

import { jobsDetails } from "../../../../../slice/jobs/jobsSlice";


const SmallDetialViewPoup = ({ show, onHide, jobId }) => {
    const dispatch = useDispatch();
    const { isDetailsLoading, jobDetails } = useSelector(state => state.jobs);
    const [Jobdata, setJobdata] = useState('')
    useEffect(() => {
        if (show) {
            const fetchRecods = async () => {
                await dispatch(jobsDetails({ id: jobId }))
            }
            fetchRecods()
        }
    }, [show])

    useEffect(() => {
        if (jobDetails && !isDetailsLoading) {
            setJobdata(jobDetails);
        }
    }, [isDetailsLoading])

    return (
        <React.Fragment>
            <div className="dk-cliDetViewPopup">
                <Modal show={show} dialogClassName="dk-livejobrightModalDailog dk-livejobrightModalDailog1"
                    aria-labelledby="example-custom-modal-styling-title" onHide={onHide}>
                    <Modal.Header closeButton className="dk-detViewheader">
                        <Modal.Title className="dk-detViewTitle" id="example-custom-modal-styling-title">
                            <div className="modalTitleCon d-block text-center">
                                Ad Posting Details
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="dk-LivejobPopCon">
                            <div className="dk-clientDetViewTopSec">
                                <div className="row mr-0 ml-0">
                                    <div className="col-md-12 col-sm-12 dk-DetViewBottomSecCon pl-0 pr-0">
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="details">
                                            <div className="row mr-0 ml-0">
                                                <div className="col-md-12 col-sm-12 dk-ClieDetViewSideMenu dk-ClieDetViewSideMenu1 pl-0 pr-0" id="dk-cliDetailsV">
                                                    <Nav variant="pills" className="flex-row">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="details">Ad to Post</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="title">Job Titles</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="adstopost">Ads to Post</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="cvsearch">CV Search</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="campaigns">Campaign</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="mailshot">Mailshot</Nav.Link>
                                                        </Nav.Item>
                                                        {/* <Nav.Item>
                                                            <Nav.Link eventKey="adplan">Ad Plan</Nav.Link>
                                                        </Nav.Item> */}
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="instructions">Instructions</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </div>
                                                <div className="col-md-12 col-sm-12 dk-ClieDetViewMainCont">
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="details">
                                                            <div className="dk-ClentViewTabPane summaryTabPane">
                                                                <Jdetails jobId={jobId} />
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="title">
                                                            <div className="dk-ClentViewTabPane summaryTabPane">
                                                                <JobTitle jobId={jobId} />
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="adstopost">
                                                            <div className="dk-ClentViewTabPane">
                                                                <Adstopost jobId={jobId} />
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="cvsearch">
                                                            <div className="dk-ClentViewTabPane">
                                                                <Cvsearch jobId={jobId} />
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="campaigns">
                                                            <div className="dk-ClentViewTabPane">
                                                                <Campaigns jobId={jobId} />
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="mailshot">
                                                            <div className="dk-ClentViewTabPane">
                                                                <Mailshot jobId={jobId} />
                                                            </div>
                                                        </Tab.Pane>
                                                        {/* <Tab.Pane eventKey="adplan">
                                                            <div className="dk-ClentViewTabPane summaryTabPane">
                                                                <Adplan jobId={jobId} />
                                                            </div>
                                                        </Tab.Pane> */}
                                                        <Tab.Pane eventKey="instructions">
                                                            <div className="dk-ClentViewTabPane summaryTabPane">
                                                                <Instructions jobId={jobId} />
                                                            </div>
                                                        </Tab.Pane>

                                                    </Tab.Content>
                                                </div>
                                            </div>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default SmallDetialViewPoup;
