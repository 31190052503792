import axios from "axios";

async function Refresh() {
  const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
  let apiUrl = "/users/refresh-token";
  let axiosConfig;
  axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      method: "POST",
      "x-api-key": "DK-52ea2d-4567-4956-9d19-35a7e75a2c17",
      platform: "web",
      Authorization: `Bearer ${token.access_token}`,
    },
  };

  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL + apiUrl,
        { refresh_token: token.refresh_token },
        axiosConfig
      );
      if (res.status === 200) {
        localStorage.setItem(
          "dkmasterBoxClient",
          JSON.stringify({
            token: res.data.token,
          })
        );
      }
      resolve({ status: res.status, payload: res.data });
    } catch (error) {
      localStorage.removeItem("dkmasterBoxClient");
      localStorage.removeItem("summaryRecFilter");
      localStorage.removeItem("persist:root");
      window.location.href = "/";
      reject(error);
    }
  });
}

export async function PostData(isAuthorized, apiUrl, data) {
  let axiosConfig;
  if (isAuthorized) {
    const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));

    const timeNow = Math.floor(Date.now() / 1000);
    if (timeNow >= token.accessTokenExpiresIn) {
      // const { payload } = await Refresh();
      // token = payload.token;
      localStorage.removeItem("dkmasterBoxClient");
      localStorage.removeItem("summaryRecFilter");
      localStorage.removeItem("persist:root");
      window.location.href = "/";
    }

    axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "DK-52ea2d-4567-4956-9d19-35a7e75a2c17",
        platform: "web",
        Authorization: `Bearer ${token.access_token}`,
      },
    };
  } else {
    axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "DK-52ea2d-4567-4956-9d19-35a7e75a2c17",
        platform: "web",
      },
    };
  }
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL + apiUrl,
        data,
        axiosConfig
      );
      resolve({ status: res.status, payload: res.data });
    } catch (error) {
      reject(error);
    }
    // axios.post(process.env.REACT_APP_BASE_URL + apiUrl, JSON.stringify(data), axiosConfig).then(function (response) {
    //     resolve(response.data.result);
    // }).catch(function (error) {
    //     reject(error);
    // });
  });
}

export async function PutData(isAuthorized, apiUrl, data) {
  let axiosConfig;
  if (isAuthorized) {
    const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));

    const timeNow = Math.floor(Date.now() / 1000);
    if (timeNow >= token.accessTokenExpiresIn) {
      // const { payload } = await Refresh();
      // token = payload.token;
      localStorage.removeItem("dkmasterBoxClient");
      localStorage.removeItem("summaryRecFilter");
      localStorage.removeItem("persist:root");
      window.location.href = "/";
    }
    axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "DK-52ea2d-4567-4956-9d19-35a7e75a2c17",
        platform: "web",
        Authorization: `Bearer ${token.access_token}`,
      },
    };
  } else {
    axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "DK-52ea2d-4567-4956-9d19-35a7e75a2c17",
        platform: "web",
      },
    };
  }
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(
        process.env.REACT_APP_BASE_URL + apiUrl,
        data,
        axiosConfig
      );
      resolve({ status: res.status, payload: res.data });
    } catch (error) {
      reject(error);
    }
    // axios.post(process.env.REACT_APP_BASE_URL + apiUrl, JSON.stringify(data), axiosConfig).then(function (response) {
    //     resolve(response.data.result);
    // }).catch(function (error) {
    //     reject(error);
    // });
  });
}

export async function PatchData(isAuthorized, apiUrl, data) {
  let axiosConfig;
  if (isAuthorized) {
    const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));

    const timeNow = Math.floor(Date.now() / 1000);
    if (timeNow >= token.accessTokenExpiresIn) {
      // const { payload } = await Refresh();
      // token = payload.token;
      localStorage.removeItem("dkmasterBoxClient");
      localStorage.removeItem("summaryRecFilter");
      localStorage.removeItem("persist:root");
      window.location.href = "/";
    }
    axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "DK-52ea2d-4567-4956-9d19-35a7e75a2c17",
        platform: "web",
        Authorization: `Bearer ${token.access_token}`,
      },
    };
  } else {
    axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "DK-52ea2d-4567-4956-9d19-35a7e75a2c17",
        platform: "web",
      },
    };
  }
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.patch(
        process.env.REACT_APP_BASE_URL + apiUrl,
        data,
        axiosConfig
      );
      resolve({ status: res.status, payload: res.data });
    } catch (error) {
      reject(error);
    }
  });
}

export async function GetData(isAuthorized, apiUrl) {
  let axiosConfig;
  if (isAuthorized) {
    const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));

    const timeNow = Math.floor(Date.now() / 1000);
    if (timeNow >= token.accessTokenExpiresIn) {
      // const { payload } = await Refresh();
      // token = payload.token;
      localStorage.removeItem("dkmasterBoxClient");
      localStorage.removeItem("summaryRecFilter");
      localStorage.removeItem("persist:root");
      window.location.href = "/";
    }

    axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "DK-52ea2d-4567-4956-9d19-35a7e75a2c17",
        platform: "web",
        Authorization: `Bearer ${token.access_token}`,
      },
    };
  } else {
    axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "DK-52ea2d-4567-4956-9d19-35a7e75a2c17",
        platform: "web",
      },
    };
  }
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_BASE_URL + apiUrl,
        axiosConfig
      );
      resolve({ status: res.status, payload: res.data });
    } catch (error) {
      reject(error);
    }
    // axios.post(process.env.REACT_APP_BASE_URL + apiUrl, JSON.stringify(data), axiosConfig).then(function (response) {
    //     resolve(response.data.result);
    // }).catch(function (error) {
    //     reject(error);
    // });
  });
}

export async function addCandidate(isAuthorized, apiUrl, data) {
  let axiosConfig;
  if (isAuthorized) {
    const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));

    const timeNow = Math.floor(Date.now() / 1000);
    if (timeNow >= token.accessTokenExpiresIn) {
      // const { payload } = await Refresh();
      // token = payload.token;
      localStorage.removeItem("dkmasterBoxClient");
      localStorage.removeItem("summaryRecFilter");
      localStorage.removeItem("persist:root");
      window.location.href = "/";
    }

    axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "DK-52ea2d-4567-4956-9d19-35a7e75a2c17",
        platform: "web",
        Authorization: `Bearer ${token.access_token}`,
      },
    };
  } else {
    axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "DK-52ea2d-4567-4956-9d19-35a7e75a2c17",
        platform: "web",
      },
    };
  }
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL + apiUrl,
        data,
        axiosConfig
      );
      resolve({ status: res.status, payload: res.data });
    } catch (error) {
      reject(error);
    }
  });
}

export async function DeleteData(isAuthorized, apiUrl) {
  let axiosConfig;
  if (isAuthorized) {
    const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));

    const timeNow = Math.floor(Date.now() / 1000);
    if (timeNow >= token.accessTokenExpiresIn) {
      // const { payload } = await Refresh();
      // token = payload.token;
      localStorage.removeItem("dkmasterBoxClient");
      localStorage.removeItem("summaryRecFilter");
      localStorage.removeItem("persist:root");
      window.location.href = "/";
    }

    axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "DK-52ea2d-4567-4956-9d19-35a7e75a2c17",
        platform: "web",
        Authorization: `Bearer ${token.access_token}`,
      },
    };
  } else {
    axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "DK-52ea2d-4567-4956-9d19-35a7e75a2c17",
        platform: "web",
      },
    };
  }
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.delete(
        process.env.REACT_APP_BASE_URL + apiUrl,
        axiosConfig
      );
      resolve({ status: res.status, payload: res.data });
    } catch (error) {
      reject(error);
    }
    // axios.post(process.env.REACT_APP_BASE_URL + apiUrl, JSON.stringify(data), axiosConfig).then(function (response) {
    //     resolve(response.data.result);
    // }).catch(function (error) {
    //     reject(error);
    // });
  });
}

export async function PostFormData(isAuthorized, apiUrl, data) {
  let axiosConfig;
  if (isAuthorized) {
    const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));

    const timeNow = Math.floor(Date.now() / 1000);
    if (timeNow >= token.accessTokenExpiresIn) {
      // const { payload } = await Refresh();
      // token = payload.token;
      localStorage.removeItem("dkmasterBoxClient");
      localStorage.removeItem("summaryRecFilter");
      localStorage.removeItem("persist:root");
      window.location.href = "/";
    }

    axiosConfig = {
      headers: {
        // 'Content-Type': 'multipart/form-data',
        "x-api-key": "DK-52ea2d-4567-4956-9d19-35a7e75a2c17",
        platform: "web",
        Authorization: `Bearer ${token.access_token}`,
      },
    };
  } else {
    axiosConfig = {
      headers: {
        // 'Content-Type': 'multipart/form-data',
        "x-api-key": "DK-52ea2d-4567-4956-9d19-35a7e75a2c17",
        platform: "web",
      },
    };
  }
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL + apiUrl,
        data,
        axiosConfig
      );
      resolve({ status: res.status, payload: res.data });
    } catch (error) {
      reject(error);
    }
    // axios.post(process.env.REACT_APP_BASE_URL + apiUrl, JSON.stringify(data), axiosConfig).then(function (response) {
    //     resolve(response.data.result);
    // }).catch(function (error) {
    //     reject(error);
    // });
  });
}
