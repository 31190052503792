import React, { useState } from 'react';
import '../tracking.scss';
import { FaSearch, FaSyncAlt } from 'react-icons/fa';
import ProgressDropdown from '../dropdown/ProgressDropdown';
import TaskCategoryDropdown from '../dropdown/TaskCategoryDropdown';
import StatusDropdown from '../dropdown/StatusDropdown';
import TableProgressDropdown from '../dropdown/TableProgressDropdown';
import ByImg from "../../../../../assets/images/deepak.png";
import Dropdown from 'react-bootstrap/Dropdown';
import { FaCheck, FaCircle, FaUserAlt, FaFlag } from 'react-icons/fa';
import TrackingComments from "../comment/TrackingComments";
import TypeIcon from "../../../../../assets/images/phone-icon.png";

const TrackingAllTracking = () => {
  const [isShowTrackingModal, setIsTrackingModal] = useState(false);

  const showTrackingModal = () => {
    setIsTrackingModal(true);
  }
  const hideTrackingModal = () => {
    setIsTrackingModal(false);
  }
  return (
    <>
      {
        (isShowTrackingModal) ? <TrackingComments hidePopup={hideTrackingModal} /> : null
      }
      <div className="dk-trackingMain">
        <div className="dk-trackingHead">
          <div className="dk-headLeftCols">
            <div className="dk-searchBox">
              <input type="text" className="form-control" placeholder="Search..." name="" id="" />
              <button><FaSearch /></button>
            </div>
            <div className="dk-circleBtn">
              <button>RC</button>
            </div>
            <div className="dk-circleBtn">
              <button><FaSyncAlt /></button>
            </div>
            <h3 className="title">CASE COMMENTS</h3>
          </div>
          <div className="dk-headRightCols">
            <ProgressDropdown className="mr-2" />
            <TaskCategoryDropdown />
          </div>
        </div>
        <div className="dk-trackingTable">
          <table className="table">
            <thead>
              <tr>
                <th>CASE ID</th>
                <th>Time & Date</th>
                <th>PROFILE</th>
                <th>Rec</th>
                <th>Campany</th>
                <th>Candidate Name</th>
                <th>Job Language</th>
                <th>Case Status</th>
                <th>Case Progress</th>
                <th>Case Stage</th>
                <th>Comment</th>
                <th>Category</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="dk-openCandidateID"><i className="fa fa-chevron-right"></i></span>12543
                  <input type="checkbox" className="dk-tdFirstCheck" name="" id="" />
                </td>
                <td>10:00, 20 Jan</td>
                <td>
                  <div className="dk-tableStatus-dropdown">
                    <Dropdown>
                      <Dropdown.Toggle variant="" id="dropdown-basic">
                        <FaCheck style={{ color: 'green' }} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <FaCheck style={{ color: 'green' }} />
                          <span>100%</span>
                          <small>Matched</small>
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <FaCheck style={{ color: '#d58b5d' }} />
                          <span>75%</span>
                          <small>Matched</small>
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <FaCheck style={{ color: '#d60101' }} />
                          <span>0%</span>
                          <small>Matched</small>
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <FaCheck style={{ color: '#d3d3d3' }} />
                          <small>Blank</small>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                      <Dropdown.Toggle variant="" id="dropdown-basic">
                        <FaCircle style={{ color: '#d3d3d3' }} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <FaCircle style={{ color: '#d3d3d3' }} />
                          <small>Incomplete</small>
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <FaCircle style={{ color: 'green' }} />
                          <small>Complete</small>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                      <Dropdown.Toggle variant="" id="dropdown-basic">
                        <FaUserAlt style={{ color: '#d3d3d3' }} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <FaUserAlt style={{ color: '#d3d3d3' }} />
                          <small>Blank</small>
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <FaUserAlt style={{ color: 'green' }} />
                          <small>Motivated</small>
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <FaUserAlt style={{ color: '#d58b5d' }} />
                          <small>Doubtful</small>
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <FaUserAlt style={{ color: '#d60101' }} />
                          <small>Unmotivated</small>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                      <Dropdown.Toggle variant="" id="dropdown-basic">
                        <FaFlag style={{ color: '#d3d3d3' }} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <FaFlag style={{ color: '#d3d3d3' }} />
                          <small>Blank</small>
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <FaFlag style={{ color: 'green' }} />
                          <small>On Time</small>
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <FaFlag style={{ color: '#d58b5d' }} />
                          <small>Concerns</small>
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <FaFlag style={{ color: '#d60101' }} />
                          <small>Stopped</small>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                  </div>
                </td>
                <td>
                  <div className="dk-byImg">
                    <img src={ByImg} alt="" />
                  </div>
                </td>
                <td>TP Greece</td>
                <td>Deepak Kumar</td>
                <td>English</td>

                <td>
                  <div className="dk-tableDropdown">
                    <StatusDropdown />
                  </div>
                </td>
                <td>
                  <div className="dk-tableDropdown">
                    <TableProgressDropdown />
                  </div>
                </td>
                <td>
                  <div className="dk-trackingTblCategory">
                    <span>Half Day Break</span>
                  </div>
                </td>
                <td>
                  <div className="dk-trackingTblComment">
                    <span onClick={showTrackingModal}>0</span>
                  </div>
                </td>
                <td>Interviews</td>
                <td>
                  <div className="dk-commentTypeTd">
                    <img src={TypeIcon} alt="" />
                    Phone Call
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default TrackingAllTracking;
