import React from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import AddNewModal from './modal/AddNew'
import EditModal from './modal/Edit';
// import ViewModal from './modal/View';
import ReorderModal from './modal/Reorder';
import DeleteModal from './modal/DeleteModal';

const index = () => {
    return (
        <>
            <div className='container-fluid dk-logins-office-mainPageCon'>
                <div className='row dk-login-officeTopHead'>
                    <div className='col-md-4 dk-login-officeTopleft'>
                        <div className="dk-resSrchInpuBbox mr-2">
                            <div className="mb-0 input-group">
                                <input type="text" className="form-control" placeholder="Search..." defaultValue="" />
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fas fa-search"></i></span>
                                </div>
                            </div>
                        </div>
                        <div className="mr-2 dk-resCrcleIconCon">
                            <AddNewModal />
                        </div>
                    </div>
                    <div className='col-md-4 dk-login-officeTopCentre'>
                        <h2 className="dk-resPageTitle">Software LOGINS</h2>
                    </div>
                    <div className='col-md-4 dk-login-officeTopRight'>
                        <div className="mr-2 dk-resCrcleIconCon">
                            <ReorderModal />
                        </div>
                        <div className='dk-logins-rightFilter'>
                            <DropdownButton className='dkg-catedropdown' title="Category">
                                <Dropdown.Item className='dkg-catedropdown-item' href="#/action-1">Category 1</Dropdown.Item>
                                <Dropdown.Item className='dkg-catedropdown-item' href="#/action-2">Category 2</Dropdown.Item>
                                <Dropdown.Item className='dkg-catedropdown-item' href="#/action-3">Category 3</Dropdown.Item>
                                <Dropdown.Item className='dkg-catedropdown-item' href="#/action-2">Category 4</Dropdown.Item>
                                <Dropdown.Item className='dkg-catedropdown-item' href="#/action-3">Category 5</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>
                    <div className='col-md-12 col-12 dk-logins-officeTableCon'>
                        <table className='dk-logins-officeTable table table-bordered' >
                            <thead>
                                <tr>
                                    <th>Sno</th>
                                    <th>Status</th>
                                    <th>Name of Company</th>
                                    <th>Category</th>
                                    <th>User Name</th>
                                    <th>Password</th>
                                    <th>Website Link</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>
                                        <DropdownButton className='dk-logins-statusdropdown' title="Active">
                                            <Dropdown.Item className='dk-statusActive' href="#">Active</Dropdown.Item>
                                            <Dropdown.Item className='dk-statusInactive' href="#">Inactive</Dropdown.Item>
                                        </DropdownButton>
                                    </td>
                                    <td>dkglobalcrm</td>
                                    <td>Job Board</td>
                                    <td>deepak@dkglobal123.com	</td>
                                    <td>dk123456</td>
                                    <td>
                                        <div className='dk-gotobtn-urlCon'>
                                            <Link to="#" className='btn dk-gotobtn-url'>Go to URL</Link>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-resAction">
                                            <EditModal />
                                            <DeleteModal />
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>
                                        <DropdownButton className='dk-logins-statusdropdown' title="Active">
                                            <Dropdown.Item className='dk-statusActive' href="#">Active</Dropdown.Item>
                                            <Dropdown.Item className='dk-statusInactive' href="#">Inactive</Dropdown.Item>
                                        </DropdownButton>
                                    </td>
                                    <td>dkglobalcrm</td>
                                    <td>Job Board</td>
                                    <td>deepak@dkglobal123.com	</td>
                                    <td>dk123456</td>
                                    <td>
                                        <div className='dk-gotobtn-urlCon'>
                                            <Link to="#" className='btn dk-gotobtn-url'>Go to URL</Link>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-resAction">
                                            <EditModal />
                                            <DeleteModal />
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>
                                        <DropdownButton className='dk-logins-statusdropdown' title="Active">
                                            <Dropdown.Item className='dk-statusActive' href="#">Active</Dropdown.Item>
                                            <Dropdown.Item className='dk-statusInactive' href="#">Inactive</Dropdown.Item>
                                        </DropdownButton>
                                    </td>
                                    <td>dkglobalcrm</td>
                                    <td>Job Board</td>
                                    <td>deepak@dkglobal123.com	</td>
                                    <td>dk123456</td>
                                    <td>
                                        <div className='dk-gotobtn-urlCon'>
                                            <Link to="#" className='btn dk-gotobtn-url'>Go to URL</Link>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-resAction">
                                            <EditModal />
                                            <DeleteModal />
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>
                                        <DropdownButton className='dk-logins-statusdropdown' title="Active">
                                            <Dropdown.Item className='dk-statusActive' href="#">Active</Dropdown.Item>
                                            <Dropdown.Item className='dk-statusInactive' href="#">Inactive</Dropdown.Item>
                                        </DropdownButton>
                                    </td>
                                    <td>dkglobalcrm</td>
                                    <td>Job Board</td>
                                    <td>deepak@dkglobal123.com	</td>
                                    <td>dk123456</td>
                                    <td>
                                        <div className='dk-gotobtn-urlCon'>
                                            <Link to="#" className='btn dk-gotobtn-url'>Go to URL</Link>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-resAction">
                                            <EditModal />
                                            <DeleteModal />
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>
                                        <DropdownButton className='dk-logins-statusdropdown' title="Active">
                                            <Dropdown.Item className='dk-statusActive' href="#">Active</Dropdown.Item>
                                            <Dropdown.Item className='dk-statusInactive' href="#">Inactive</Dropdown.Item>
                                        </DropdownButton>
                                    </td>
                                    <td>dkglobalcrm</td>
                                    <td>Job Board</td>
                                    <td>deepak@dkglobal123.com	</td>
                                    <td>dk123456</td>
                                    <td>
                                        <div className='dk-gotobtn-urlCon'>
                                            <Link to="#" className='btn dk-gotobtn-url'>Go to URL</Link>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-resAction">
                                            <EditModal />
                                            <DeleteModal />
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>
                                        <DropdownButton className='dk-logins-statusdropdown' title="Active">
                                            <Dropdown.Item className='dk-statusActive' href="#">Active</Dropdown.Item>
                                            <Dropdown.Item className='dk-statusInactive' href="#">Inactive</Dropdown.Item>
                                        </DropdownButton>
                                    </td>
                                    <td>dkglobalcrm</td>
                                    <td>Job Board</td>
                                    <td>deepak@dkglobal123.com	</td>
                                    <td>dk123456</td>
                                    <td>
                                        <div className='dk-gotobtn-urlCon'>
                                            <Link to="#" className='btn dk-gotobtn-url'>Go to URL</Link>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-resAction">
                                            <EditModal />
                                            <DeleteModal />
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>
                                        <DropdownButton className='dk-logins-statusdropdown' title="Active">
                                            <Dropdown.Item className='dk-statusActive' href="#">Active</Dropdown.Item>
                                            <Dropdown.Item className='dk-statusInactive' href="#">Inactive</Dropdown.Item>
                                        </DropdownButton>
                                    </td>
                                    <td>dkglobalcrm</td>
                                    <td>Job Board</td>
                                    <td>deepak@dkglobal123.com	</td>
                                    <td>dk123456</td>
                                    <td>
                                        <div className='dk-gotobtn-urlCon'>
                                            <Link to="#" className='btn dk-gotobtn-url'>Go to URL</Link>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-resAction">
                                            <EditModal />
                                            <DeleteModal />
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>
                                        <DropdownButton className='dk-logins-statusdropdown' title="Active">
                                            <Dropdown.Item className='dk-statusActive' href="#">Active</Dropdown.Item>
                                            <Dropdown.Item className='dk-statusInactive' href="#">Inactive</Dropdown.Item>
                                        </DropdownButton>
                                    </td>
                                    <td>dkglobalcrm</td>
                                    <td>Job Board</td>
                                    <td>deepak@dkglobal123.com	</td>
                                    <td>dk123456</td>
                                    <td>
                                        <div className='dk-gotobtn-urlCon'>
                                            <Link to="#" className='btn dk-gotobtn-url'>Go to URL</Link>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-resAction">
                                            <EditModal />
                                            <DeleteModal />
                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>

    )
}

export default index