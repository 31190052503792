import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import '../style.scss';

const ItTeamMainMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK Global CRM";
    }, [pathname]);

    // const { allBadge } = useSelector(state => state.tasks);
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-recruitmentNavbar dk-itTeamMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/dkglobaladmin/it-teams/details" className={`${pathname.match('/details') ? 'active' : ''}`}>Summary </Link></li>

                        <li><Link to="/dkglobaladmin/it-teams/all-projects" className={`${pathname.match('/all-projects') ? 'active' : ''}`}>Projects</Link></li>

                        <li><Link to="/dkglobaladmin/it-teams/stories" className={`${pathname.match('/stories') ? 'active' : ''}`}>Stories</Link></li>

                        <li><Link to="/dkglobaladmin/it-teams/backlogs" className={`${pathname.match('/backlogs') ? 'active' : ''}`}>Backlogs</Link></li>

                        <li><Link to="/dkglobaladmin/it-teams/wip" className={`${pathname.match('/wip') ? 'active' : ''}`}>WIP</Link></li>

                        <li><Link to="/dkglobaladmin/it-teams/delivered" className={`${pathname.match('/delivered') ? 'active' : ''}`}>Delivered</Link></li>


                        {/* <li><Link to="/dkglobaladmin/it-teams/develop-bugs" className={`${pathname.match('/develop-bugs') ? 'active' : ''}`}>Develop Bugs</Link></li>

                        <li><Link to="/dkglobaladmin/it-teams/design-bugs" className={`${pathname.match('/design-bugs') ? 'active' : ''}`}>Design Bugs</Link></li> */}

                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default ItTeamMainMenu;