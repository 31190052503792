import React, { useLayoutEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Header from "../elements/header";
import Navbar from '../elements/header/navbar/mainMenu/knowleadgebase';

import { resourceCategory, resourceCoverage, resourcePaidUnpaid, resourceSourceType, resourceStatus, resourceLoginCategory, resourceLoginSourceType } from "../../slice/layout/layoutSlice";

const KnowleadgeLayout = ({ children }) => {
    const dispatch = useDispatch();
    useLayoutEffect(() => {

        const fetchResourceCategoryList = async () => {
            try {
                await dispatch(resourceCategory({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchRresourceCoverageList = async () => {
            try {
                await dispatch(resourceCoverage({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchResourcePaidUnpaidList = async () => {
            try {
                await dispatch(resourcePaidUnpaid({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchResourceSourceTypeList = async () => {
            try {
                await dispatch(resourceSourceType({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchResourceStatusList = async () => {
            try {
                await dispatch(resourceStatus({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchResourceLoginCategory = async () => {
            try {
                await dispatch(resourceLoginCategory({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchResourceLoginSourceType = async () => {
            try {
                await dispatch(resourceLoginSourceType({}))
            } catch (err) {
                console.log(err)
            }
        }

        fetchResourceCategoryList()
        fetchRresourceCoverageList()
        fetchResourcePaidUnpaidList()
        fetchResourceSourceTypeList()
        fetchResourceStatusList()
        fetchResourceLoginCategory()
        fetchResourceLoginSourceType()

    }, [])

    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="Recruitment -" moduleLink="/dashboard/recruitment" linkcolor="#3c4c62" mainheaderTitle=" Knowleadge Base" textcolor="#26596d" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default KnowleadgeLayout;