import React from "react";


const Pipelines = () => {

    return (
        <>
            <div className='container-fluid' style={{ marginTop: "125px" }}>
                <div className='row'>
                    <div className="col-md-12">
                        {/* <h3 className='text-center w-100'>Pipelines</h3> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pipelines;
