import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Dropdown, Tab, Nav } from "react-bootstrap";
import './comment.scss';
import { useDispatch, useSelector } from "react-redux";
import { commentList, commentStage, commentFunSubject, addComment, commentDelete, commentDetails, updateComments, clearState } from "../../../slice/comments/commentSlice";
//import { candidateProfileDetails } from '../../../slice/candidates/list/listSlice';
import { updateCommentCount } from "../../../slice/candidates/list/listSlice";
import CommentLoader from "../../ui/commentLoader/";
import Delete from "../../ui/delete/";
import moment from "moment-timezone";
import Submit from "../../ui/submitButton";
import BubbleChat from '../../../assets/images/bubble-chat.png';
import CaseOther from "../../../components/pages/candidate/pipeline/dropdown/other";
import CaseStatus from "../../../components/pages/candidate/pipeline/dropdown/caseStatus";

import CaseStatusList from '../../ui/dropdownList/CaseStatusList';
import CaseProgressList from '../../ui/dropdownList/CaseProgressList';
import CaseStageList from '../../ui/dropdownList/CaseStageList';
// import TagsCommentsTabs from "./TagsCommentsTabs";
// import TagImg from '../../../assets/images/email-icon.png';
// import CommentCaseStage from './CaseStage'
import { Markup } from 'interweave';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
//import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from "html-to-draftjs";
//import draftToHtml from "draftjs-to-html";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FaRegClock, FaSignature } from 'react-icons/fa';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus } from '@fortawesome/free-solid-svg-icons';
import $ from "jquery";
import TimelineModal from "../../pages/candidate/pipeline/modal/candidateprofile/workflow/TimelineModal";
export const useMountEffect = (fun) => useEffect(fun, []);

const UseFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focusEditor() }
    return [htmlElRef, setFocus]
}

const CommentPopup = ({ refTable, refId, count, title, candidateStatus, candidateProgess, candidateStage, closedCommentPopup }) => {
    const dispatch = useDispatch();
    const { isCommentLoading, comments, details, isSuccess, isSubject, isUpdate, isInsert, isDelete, commentstage, commentsubjectlist, isError, message } = useSelector(state => state.comments);
    // const { profileDetails,isProfileProjectUpdate } = useSelector(state => state.candidates);

    const [input1Ref, setInput1Focus] = UseFocus()
    const { userInfo } = useSelector(state => state.auth);

    const [List, setList] = useState([]);
    const [caseStage, setCaseStage] = useState([]);
    const [commentSubject, setCommentSubject] = useState([]);
    const [show, setShow] = useState(true);

    const [value, setValue] = useState('')
    const [statusValue, setStatusValue] = useState(candidateStatus)
    const [statusProgress, setStatusProgress] = useState(candidateProgess)
    const [stageValue, setStageValue] = useState(candidateStage)

    const [defaultsubject, setSubject] = useState('')
    const [parentId, setParentId] = useState('0')
    const [btnTxt, setBtnTxt] = useState('SAVE')

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [rowId, setRowId] = useState(0)

    const [isShowDelete, setIsShowDelete] = useState(false)

    const [commentCount, setCommentCount] = useState(0)
    const [actionType, setActionType] = useState('Add')
    const [actinonBtnType, setActinonBtnType] = useState('list')
    const [dataId, setDataId] = useState(0)
    const [data, setData] = useState([]);

    useMountEffect(setInput1Focus)
    let description = details.details !== undefined && details.details !== null ? details.details : "";

    let description_2 = details.details !== undefined && details.details !== null ? details.details : "";
    const [editorState_2, setEditorState_2] = useState(EditorState.createEmpty());

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const html = description;
    const contentBlock = htmlToDraft(html);

    const html_2 = description_2;
    const contentBlock_2 = htmlToDraft(html_2);

    const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
    );

    const contentState_2 = ContentState.createFromBlockArray(
        contentBlock_2.contentBlocks
    );

    useEffect(() => {
        setEditorState(EditorState.createEmpty());
        if (description !== '') {
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, [description])

    useEffect(() => {
        setEditorState_2(EditorState.createEmpty());
        if (description_2 !== '') {
            setEditorState_2(EditorState.createWithContent(contentState_2));
        }
    }, [description_2])
    //const  [convertedContent, setConvertedContent] = useState(null);
    // const handleEditorChange = (state) => {
    //     setEditorState(state);
    //     convertContentToHTML();
    // }
    // const convertContentToHTML = () => {
    //     let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    //     setValue(currentContentAsHTML)
    //     // dispatch(updateJobWithTextBox({ id: ids, key: 'description', value: currentContentAsHTML }));
    // }
    // const handleEditorChange_2 = (state) => {
    //     setEditorState_2(state);
    //     convertContentToHTML_2();
    // }
    // const convertContentToHTML_2 = () => {
    //     let currentContentAsHTML_2 = draftToHtml(convertToRaw(editorState_2.getCurrentContent()))
    //     setValue(currentContentAsHTML_2)
    // }

    // const handleClose = () => {
    //     setShow(false);
    // }

    // const handleShow = () => {
    //     setShow(true);
    // }

    useEffect(() => {
        if (show) {
            setCommentCount(count)
            setDataId(refId)
            dispatch(commentStage({}))
            dispatch(commentList({ ref: refTable, refId }))
        }
    }, [show, isDelete])

    useEffect(() => {
        if (isSubject) {
            setCommentSubject(commentsubjectlist)
        }
    }, [isSubject])

    useEffect(() => {
        if (!isCommentLoading) {
            setList(comments)
            setCaseStage(commentstage)
        }
    }, [isCommentLoading])

    // const handleChange = e => {
    //     setValue(e.target.value)
    // }
    const submitHandleSubmit = async () => {
        try {
            setLoading(true);
            // setTexAreaData("")
            if (rowId === 0) {
                await dispatch(
                    addComment({ ref: refTable, refId, parentId, details: texAreaData, caseStatus: statusValue, caseProgress: statusProgress, caseStage: stageValue, subject: defaultsubject, date: moment().tz("Europe/Dublin").format("YYYY-MM-DD hh:mm:ss") })
                );
            } else {
                await dispatch(updateComments({ id: rowId, details: texAreaData, caseStatus: statusValue, caseProgress: statusProgress, caseStage: stageValue, subject: defaultsubject, date: moment().tz("Europe/Dublin").format("YYYY-MM-DD hh:mm:ss") }));
            }
        } catch (e) {
            console.log(e);
        }
    };
    const handleSubmit = async () => {
        try {

            setLoading(true)
            if (rowId === 0) {
                await dispatch(addComment({ ref: refTable, refId, parentId, details: value, caseStage: stageValue, subject: defaultsubject }))
            } else {
                await dispatch(updateComments({ id: rowId, details: value, caseStage: stageValue, subject: defaultsubject }))
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (isInsert) {
            dispatch(clearState())
            //  dispatch(candidateProfileDetails({ id: refId }))
            //setCountComment()
            setCommentCount(10)
            setLoading(false)
            setShowText(false);
            setSubject('Select')
            setBtnTxt('SAVE')
            setTexAreaData("")
            setValue('')
            setStageValue('')
            setStatusValue('')
            setStatusProgress('')
            setShowPopup(false);
            setEditorState('')
            setEditorState_2('')
            setList(comments)
            if (parentId === '0' && dataId > 0) {
                var today = new Date();
                var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                var dateTime = date + ' ' + time;
                dispatch(updateCommentCount({ id: dataId, value: Number(commentCount) + 1, commentAt: dateTime }))
                setCommentCount(Number(commentCount) + 1)
            }
            setParentId('0')
        }
        if (isDelete) {
            dispatch(clearState())
            //  dispatch(candidateProfileDetails({ id: refId }))
            setLoading(false)
            setList(comments)
            if (parentId === '0' && dataId > 0) {
                dispatch(updateCommentCount({ id: dataId, value: Number(commentCount) - 1 }))
                setCommentCount(Number(commentCount) - 1)
            }
            setParentId('0')
            setRowId(0)
            setIsShowDelete(false)
        }
        if (isUpdate) {
            dispatch(clearState())
            setLoading(false)
            setShowText(false);
            setShowPopup(false);
            setParentId('0')
            setRowId(0)
            setActionType("Add")
            setBtnTxt('SAVE')
            setValue('')
            setEditorState('')
            //  setEditorState_2('')
            setList(comments)
        }

    }, [isInsert, isDelete, isUpdate])



    const deleteComment = e => {
        setRowId(e.target.dataset.id)
        setParentId(e.target.dataset.parentid)
        setIsShowDelete(true)
    }

    const handleCloseDelete = () => {
        setRowId(0)
        setIsShowDelete(false)
    }

    const deleteItem = async () => {
        try {
            setLoading(true)
            dispatch(commentDelete({ id: rowId }))
        } catch (e) {
            console.log(e)
        }
    }

    const [popupTxt, setPopupTxt] = useState('');

    const [showText, setShowText] = useState(false);
    const [texAreaData, setTexAreaData] = useState('');

    const [newCaseStatus, setNewCaseStatus] = useState('');
    const [newCaseProgress, setNewCaseProgress] = useState('');
    const [newCaseStage, setNewCaseStage] = useState('');
    const [createdDt, setCreatedAt] = useState('');

    const onItemClick = async (id, columnId, value) => {
        setNewCaseStatus(value)
        setNewCaseProgress('')
        setNewCaseStage('')
        setStatusValue(value)
    };
    const onItemProgressClick = async (id, columnId, value) => {
        setNewCaseProgress(value)
        setNewCaseStage('')
        setStatusProgress(value)
    };
    const onItemStageClick = async (id, columnId, value) => {
        setNewCaseStage(value)
        setStageValue(value)
    };

    const editComment = e => {
        setActionType("Edit")
        setActinonBtnType("Edit")
        setNewCaseStatus(e.target.dataset.status)
        setNewCaseProgress(e.target.dataset.progress)
        setNewCaseStage(e.target.dataset.stage)
        setCreatedAt(e.target.dataset.created)
        setStatusValue(e.target.dataset.status)
        setStatusProgress(e.target.dataset.progress)
        setStageValue(e.target.dataset.stg)
        setSubject(e.target.dataset.sub)
        setRowId(e.target.dataset.id);
        setShowText(true)
        setTexAreaData(e.target.dataset.value)
        setPopupTxt("Update Comment");
        dispatch(commentDetails({ id: e.target.dataset.id }));
        // setRowId(e.target.dataset.id)
        // setPopupTxt("Update Comment")
        // setShowPopup(true);
        // dispatch(commentDetails({ id: e.target.dataset.id }))
        // setShowPopup(true);
        //setEditTxtComments(true)
    }

    useEffect(() => {
        if (isSuccess) {
            dispatch(clearState())
            setParentId(details.parentId)
            setValue(details.details)
            setBtnTxt('Update')
        }
    }, [isSuccess])

    const replyComment = e => {
        setShowText(true)
        setPopupTxt("Reply Comment")
        //setParentId(e.target.dataset.id)
        // setShowPopup(true);

    }

    const [isTimelinePop, setIsTimelinePop] = useState(false)
    // const openTimelinePop = () => {
    //     setIsTimelinePop(true)
    // }
    const hideTimelinePop = () => {
        setIsTimelinePop(false)
    }
    const [showPopup, setShowPopup] = useState(false);
    const handleClosePopup = () => {
        setShowPopup(false);

    }
    // const handleShowPopup = () => {
    //     setShowPopup(true);
    //     setPopupTxt('Add New Comment')
    // }


    const onClick = () => {
        setActionType("Add")
        setActinonBtnType("Add")
        setBtnTxt("SAVE")
        setTexAreaData("")
        setStatusValue(candidateStatus)
        setStatusProgress(candidateProgess)
        setStageValue(candidateStage)
        if (showText === false) {
            setShowText(true);
            setTexAreaData("")
        }
        else { setShowText(false) };
    };
    const cancleBtnFun = () => {
        setShowText(false)
        setTexAreaData("")
        setActionType("Add")
    }

    // const getSubjectfun = (e) => {
    //     const value = e.target.dataset.value;
    //     setSubject(value)
    // }
    // const updateStagecomment = async (e) => {
    //     const id = e.target.dataset.id;
    //     const key = e.target.dataset.key;
    //     const value = e.target.dataset.value;
    //     setStageValue(value)
    //     await dispatch(commentFunSubject({ id }))

    // }

    return (
        <>
            {
                (isShowDelete) ? <Delete id={rowId} handleClose={handleCloseDelete} deleteItem={deleteItem} loading={loading} success={success} /> : null
            }

            {
                (isTimelinePop) ? <TimelineModal onHide={hideTimelinePop} /> : null
            }

            {/* <div className="dk-TopCntreBox commChatBox">
                <div className="dk-comment-box">
                    <span className="chatIcon mr-2">
                        <img src={BubbleChat} className="chatimg" alt="BubbleChat_Icon" />
                    </span>
                </div>

                <div className="dk-timeline-box" onClick={handleShow}>
                    <span className="commentIcon">
                        <Link className="numCon" to="#">{count}</Link>
                    </span>
                    <span className="chatIcon mr-2 d-none">
                        <FaRegClock />
                    </span>
                    <span className="commentIcon d-none">
                        <Link className="numCon" to="#">
                            <span><FaSignature style={{ color: "#fff" }} /></span>
                        </Link>
                    </span>
                </div>
            </div> */}

            {/* <div className="dk-TopCntreBox cvtextBox">
                <div className="dk-comment-box" onClick={handleShow}>
                    <span className="chatIcon mr-2">
                        <img src={BubbleChat} className="chatimg" alt="BubbleChat_Icon" />
                    </span>
                    <span className="commentIcon">
                        <Link className="numCon" to="#">{count}</Link>
                    </span>
                </div>
                <div className="dk-cvImg">
                    <div className="dk-timeline-box" onClick={openTimelinePop}>
                        <span className="chatIcon mr-2 d-none">
                            <FaRegClock />
                        </span>
                        <span className="commentIcon d-none">
                            <Link className="numCon" to="#">
                                <span><FaSignature style={{ color: "#fff" }} /></span>
                            </Link>
                        </span>
                    </div>
                </div>
            </div> */}
            <Modal className="dk-commentModalMain right dk-candidateComment-modalMain" show={true} onHide={closedCommentPopup}>
                <Modal.Header closeButton>
                    {/* <Link to="#" className="addMonthBtn" onClick={handleShowPopup} ><i className="fas fa-plus"></i></Link> */}
                    {
                        (actionType === "Add") ? <Link to="#" className='addMonthBtn' onClick={onClick}>
                            <i className="fas fa-plus"></i>
                        </Link>
                            : null
                    }
                    <Modal show={showPopup} onHide={handleClosePopup} centered className="dkg-comment-editorPopup-234">
                        <Modal.Header closeButton className="dkg-comment-editorPopup-Header">
                            <Modal.Title>{popupTxt}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="dkg-comment-editorPopup-Body">
                            <div className="dkg-comment-editor-12">
                                {/* <Editor
                                    editorState={editorState}
                                    onEditorStateChange={handleEditorChange}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                    ref={input1Ref}
                                /> */}
                                <textarea rows="4" className="form-control w-100 dkg-addnew-comment-textArea" value={texAreaData} onChange={(e) => setTexAreaData(e.target.value)} />

                            </div><div className="dk-commentSubmitBtn">
                                <Submit txt={btnTxt} loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="dk-sendBtn" />
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showText ?
                        <>
                            <div className="dkg-addnew-scrollBox">
                                <ul className="dkg-addnew-comments-list">
                                    <li>
                                        <div className="dkg-addnew-comment-main-level">
                                            <div className="dkg-addnew-comment-avatar">
                                                <div className="tooltipMain">
                                                    <img alt="Dhirendra Raushan" src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + userInfo.profileImage} className="avatarImg" />
                                                    <div className="tooltipText"> {userInfo.firstName}</div>
                                                </div>
                                            </div>
                                            <div className="dkg-addnew-comment-box">
                                                <div className="dkg-addnew-comment-head d-flex align-items-center justify-content-between">
                                                    <span className="d-flex">
                                                        <div className="date"><i className="far fa-calendar-alt"></i>{moment().tz("Europe/Dublin").format("DD MMM YY")}</div>
                                                        <div className="date"><i className="far fa-clock"></i>{moment().tz("Europe/Dublin").format("hh:mm")}</div>
                                                    </span>
                                                    <div className="d-flex headIcons">
                                                        <i className="fas fa-reply" title="Reply" data-id="123682" style={{ cursor: "not-allowed" }}></i><i className="far fa-edit" title="Edit" data-id="123682" style={{ cursor: "not-allowed" }}></i><i className="far fa-trash-alt" title="Delete" data-parentid="0" data-id="123682" style={{ cursor: "not-allowed" }}></i>
                                                    </div>

                                                </div>
                                                {
                                                    (refTable === "candidates") ? <>

                                                        {
                                                            (actionType === "Add") ? <> {/*****Add comment***** */}
                                                                <div className="dkg-comment-sub-header">
                                                                    <div className="dkg-commsub-dropdwonCon">
                                                                        <div className="dkg-stage-dropdown">
                                                                            <div className="dkg-stage-dropdown-toogle" >
                                                                                <CaseStatus value={candidateStatus} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dkg-commsub-dropdwonCon">
                                                                        <div className="dkg-stage-dropdown">
                                                                            <div className="dkg-stage-dropdown-toogle">
                                                                                <CaseOther caseStatus={candidateStatus} value={candidateProgess} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dkg-commsub-dropdwonCon">
                                                                        <div className="dkg-stage-dropdown">
                                                                            <div className="dkg-stage-dropdown-toogle">
                                                                                <CaseOther caseStatus={candidateStatus} value={candidateStage} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </> : <> {/*****edit and reply***** */}
                                                                {
                                                                    (moment(createdDt).tz("Europe/Dublin").format(" YYYY") >= 2024) ? <>
                                                                        <div className="dkg-comment-sub-header editComments">
                                                                            <div className="dkg-commsub-dropdwonCon">
                                                                                <div className="dkg-stage-dropdown">
                                                                                    <div className="dkg-stage-dropdown-toogle" >

                                                                                        <div className="dk-caseStatusDropCon">
                                                                                            <Dropdown id={`caseStatus-1`}>
                                                                                                <Dropdown.Toggle variant="" className="dk-caseStatusfliterbtn">
                                                                                                    <CaseStatus value={newCaseStatus} />
                                                                                                </Dropdown.Toggle>
                                                                                                <Dropdown.Menu className="caseStatusMenu">
                                                                                                    <CaseStatusList id=''
                                                                                                        columnId="caseStatus" onItemClick={onItemClick}
                                                                                                    />
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="dkg-commsub-dropdwonCon">
                                                                                <div className="dkg-stage-dropdown">
                                                                                    <div className="dkg-stage-dropdown-toogle">

                                                                                        <div className="dk-caseStatusDropCon">
                                                                                            <Dropdown id={`case_progress`}>
                                                                                                <Dropdown.Toggle variant="" className="dk-caseStatusfliterbtn">
                                                                                                    <CaseOther caseStatus={newCaseStatus} value={newCaseProgress} />
                                                                                                </Dropdown.Toggle>
                                                                                                <Dropdown.Menu className="caseStatusMenu">
                                                                                                    <CaseProgressList caseStatus={newCaseStatus} id='' columnId="case_progress" onItemClick={onItemProgressClick} />
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="dkg-commsub-dropdwonCon">
                                                                                <div className="dkg-stage-dropdown">
                                                                                    <div className="dkg-stage-dropdown-toogle">
                                                                                        {/* <CaseOther caseStatus={newCaseStatus} value={newCaseStage} /> */}
                                                                                        <div className="dk-caseStatusDropCon">
                                                                                            <Dropdown id={`case_stage`}>
                                                                                                <Dropdown.Toggle variant="" className="dk-caseStatusfliterbtn">
                                                                                                    <CaseOther caseStatus={newCaseStatus} value={newCaseStage} />
                                                                                                </Dropdown.Toggle>
                                                                                                <Dropdown.Menu className="caseStatusMenu">
                                                                                                    <CaseStageList caseStatus={newCaseStatus} id='' columnId="case_stage" onItemClick={onItemStageClick} />
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </> : null
                                                                }

                                                            </>
                                                        }
                                                    </>
                                                        : null
                                                }

                                                <div className="dkg-addnew-comment-content editDrop">

                                                    <textarea rows="4" className="form-control w-100 dkg-addnew-comment-textArea" value={texAreaData} onChange={(e) => setTexAreaData(e.target.value)} />
                                                    {/* <Editor
                                                        editorState={editorState}
                                                        onEditorStateChange={handleEditorChange}
                                                        wrapperClassName="wrapper-class"
                                                        editorClassName="editor-class"
                                                        toolbarClassName="toolbar-class"
                                                    /> */}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="dkg-submitbtnCon">
                                            <Link to='#' onClick={() => cancleBtnFun()} className="dkg-cancel-btn">Cancel</Link>
                                            <Submit
                                                txt={btnTxt}
                                                loading={loading}
                                                success={success}
                                                onClick={submitHandleSubmit}
                                                position="justify-content-center"
                                                className="dk-sendBtn updateBtn-1"
                                            />

                                            {/* <Submit
                                            txt={btnTxt}
                                            loading={loading}
                                            success={success}
                                            onClick={handleSubmit}
                                            position="justify-content-center"
                                            className="dk-sendBtn"
                                            /> */}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </>
                        :
                        <>
                            <div className="comments-container 22">
                                <div className="scrollBox">
                                    <ul className="comments-list">
                                        {isCommentLoading ? (
                                            <CommentLoader />
                                        ) : (
                                            <React.Fragment>
                                                {List.map((item, index) => (
                                                    <li>
                                                        <div className="comment-main-level">
                                                            <div className="comment-avatar">
                                                                <div className="tooltipMain">
                                                                    <img
                                                                        alt={
                                                                            item.userInfo.firstName +
                                                                            " " +
                                                                            item.userInfo.lastName
                                                                        }
                                                                        src={
                                                                            process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                                                            item.userInfo.profileImage
                                                                        }
                                                                        className="avatarImg"
                                                                    />
                                                                    <div className="tooltipText">
                                                                        {item.userInfo.firstName +
                                                                            " " +
                                                                            item.userInfo.lastName}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="comment-box">
                                                                <div className="comment-head dkg-comm-read-header d-flex align-items-center justify-content-between">
                                                                    <div className="dkg-comment-header-rightCon" style={{ height: "38px" }}>
                                                                        <span className="d-flex">
                                                                            <div className="date">
                                                                                <i className="far fa-calendar-alt"></i>
                                                                                {moment(item.updatedAt).tz("Europe/Dublin").format("DD MMM YY")}
                                                                            </div>
                                                                            <div className="date">
                                                                                <i className="far fa-clock"></i>
                                                                                {moment(item.updatedAt).tz("Europe/Dublin").format("h:mm")}
                                                                            </div>
                                                                        </span>
                                                                        {/* <div className="d-flex headIcons">
                                                                        </div> */}
                                                                        <div className='dkg-comment-readdropdwn-Con d-flex'>
                                                                            <div className="date">
                                                                                <i className="fas fa-reply"
                                                                                    title="Reply"
                                                                                    data-id={item.id}
                                                                                    onClick={(e) => {
                                                                                        setParentId(e.target.dataset.id);
                                                                                        setInput1Focus();
                                                                                        replyComment(e.target.dataset.id);
                                                                                    }}
                                                                                ></i>
                                                                            </div>
                                                                            <div className="date">
                                                                                <i
                                                                                    className="far fa-edit"
                                                                                    title="Edit"
                                                                                    data-id={item.id}
                                                                                    data-stg={item.caseStage}
                                                                                    data-sub={item.subject}
                                                                                    data-value={item.details}
                                                                                    data-status={item.caseStatus}
                                                                                    data-progress={item.caseProgress}
                                                                                    data-stage={item.caseStage}
                                                                                    data-created={item.createdAt}
                                                                                    onClick={editComment}
                                                                                ></i>
                                                                            </div>
                                                                            <div className="date">
                                                                                <i
                                                                                    className="far fa-trash-alt"
                                                                                    title="Delete"
                                                                                    data-parentid={item.parentId}
                                                                                    data-id={item.id}
                                                                                    onClick={deleteComment}
                                                                                ></i>
                                                                            </div>
                                                                            <Dropdown className='dkg-comment-readdropdwn d-none'>
                                                                                <Dropdown.Toggle variant="" className='dkg-comment-readdropdwn-toggle'>
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu className='dkg-comment-readdropdwn-menu'>
                                                                                    <Dropdown.Item href="#" className='dkg-comment-readdropdwn-item'>
                                                                                        <i className="fas fa-reply"
                                                                                            title="Reply"
                                                                                            data-id={item.id}
                                                                                            onClick={(e) => {
                                                                                                setParentId(e.target.dataset.id);
                                                                                                setInput1Focus();
                                                                                                replyComment(e.target.dataset.id);
                                                                                            }}
                                                                                        ></i>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item href="#" className='dkg-comment-readdropdwn-item'>
                                                                                        <i
                                                                                            className="far fa-edit"
                                                                                            title="Edit"
                                                                                            data-id={item.id}
                                                                                            data-stg={item.caseStage}
                                                                                            data-sub={item.subject}
                                                                                            data-value={item.details}
                                                                                            data-status={item.caseStatus}
                                                                                            data-progress={item.caseProgress}
                                                                                            data-stage={item.caseStage}
                                                                                            data-created={item.createdAt}
                                                                                            onClick={editComment}
                                                                                        ></i>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item href="#" className='dkg-comment-readdropdwn-item'>
                                                                                        <i
                                                                                            className="far fa-trash-alt"
                                                                                            title="Delete"
                                                                                            data-parentid={item.parentId}
                                                                                            data-id={item.id}
                                                                                            onClick={deleteComment}
                                                                                        ></i>
                                                                                    </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    (moment(item.createdAt).tz("Europe/Dublin").format(" YYYY") >= 2024) ? <>
                                                                        <div className="dkg-comm-read-subheader">
                                                                            <div className="dkg-comm-read-subject">
                                                                                <CaseStatus value={item.caseStatus} />
                                                                            </div>
                                                                            <div className="dkg-comm-read-subject">
                                                                                <CaseOther caseStatus={item.caseStatus} value={item.caseProgress} />
                                                                            </div>
                                                                            <div className="dkg-comm-read-subject">
                                                                                <CaseOther caseStatus={item.caseStatus} value={item.caseStage} />
                                                                            </div>
                                                                        </div>
                                                                    </>

                                                                        : null
                                                                }


                                                                <div className="comment-content">
                                                                    <Markup content={item.details} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {item.children.length !== 0 ? (
                                                            <React.Fragment>
                                                                <ul className="comments-list-reply">
                                                                    {item.children.map((item, index) => (
                                                                        <li>
                                                                            <div className="comment-main-level">
                                                                                <div className="comment-avatar">
                                                                                    <div className="tooltipMain">
                                                                                        <img
                                                                                            alt={
                                                                                                item.userInfo.firstName +
                                                                                                " " +
                                                                                                item.userInfo.lastName
                                                                                            }
                                                                                            src={
                                                                                                process.env
                                                                                                    .REACT_APP_IMG_CLOUD_BASE_URL +
                                                                                                item.userInfo.profileImage
                                                                                            }
                                                                                            className="avatarImg"
                                                                                        />
                                                                                        <div className="tooltipText">
                                                                                            {item.userInfo.firstName +
                                                                                                " " +
                                                                                                item.userInfo.lastName}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="comment-box">
                                                                                    <div className="comment-head d-flex align-items-center justify-content-between">
                                                                                        <h6 className="comment-name d-none">
                                                                                            <Link to="Maria Ciocas"></Link>
                                                                                        </h6>
                                                                                        <span className="d-flex">
                                                                                            <div className="date">
                                                                                                <i className="far fa-calendar-alt"></i>
                                                                                                {moment(item.updatedAt)
                                                                                                    .tz("Europe/Dublin")
                                                                                                    .format("DD MMM YY")}
                                                                                            </div>
                                                                                            <div className="date">
                                                                                                <i className="far fa-clock"></i>
                                                                                                {moment(item.updatedAt)
                                                                                                    .tz("Europe/Dublin")
                                                                                                    .format("h:mm")}
                                                                                            </div>
                                                                                        </span>
                                                                                        <div className="d-flex headIcons">
                                                                                            <i
                                                                                                className="far fa-edit"
                                                                                                title="Edit"
                                                                                                data-id={item.id}
                                                                                                data-stg={item.caseStage}
                                                                                                data-sub={item.subject}
                                                                                                data-status={item.caseStatus}
                                                                                                data-progress={item.caseProgress}
                                                                                                data-stage={item.caseStage}
                                                                                                data-created={item.createdAt}
                                                                                                onClick={editComment}
                                                                                            ></i>
                                                                                            <i
                                                                                                className="far fa-trash-alt"
                                                                                                title="Delete"
                                                                                                data-parentid={item.parentId}
                                                                                                data-id={item.id}
                                                                                                onClick={deleteComment}
                                                                                            ></i>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="comment-content">
                                                                                        <Markup content={item.details} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </React.Fragment>
                                                        ) : null}
                                                    </li>
                                                ))}
                                            </React.Fragment>
                                        )}
                                    </ul>
                                </div>
                            </div>

                        </>}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CommentPopup;