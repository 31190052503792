import React, { useState } from 'react';
import { Link } from "react-router-dom";
import FinanceManagerImg from "../../../../../assets/images/accountmanager_icon.png";
import DirectorImg from "../../../../../assets/images/recruitmentmanager_white.png";
import TeamLeaderImg from "../../../../../assets/images/businesswoman.png";
import ManagerImg from "../../../../../assets/images/girlmanager.png";
import MarketingManagerImg from "../../../../../assets/images/juniorrecruiter_white.png";
import OperationManagerImg from "../../../../../assets/images/operation_manager.png";
import HrManagerImg from "../../../../../assets/images/hr_white.png";
import ItManagerImg from "../../../../../assets/images/it_white.png";
import ResourceManagerImg from "../../../../../assets/images/resource.png";
import '../trainingdetail.scss';

const TrainingManagement = () => {
    return (
        <React.Fragment>
            <div className="dk-adminsTrainingMain dk-commonTrainingAdminMain">
                <div className="container-fluid">
                    <div className="dk-trainingMainCols">
                        <h3 className="title">DKG MANAGEMENT TRAINING</h3>
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <div className="os-box" style={{ background: '#5d5c60' }}>
                                    <Link to="/dkglobaladmin/admintraining/management/teamleader/welcome">
                                        <figure>
                                            <img src={TeamLeaderImg} />
                                        </figure>
                                        <h3>Team Leader</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="os-box" style={{ background: '#344050' }}>
                                    <Link to="/dkglobaladmin/admintraining/management/recruitment-manager">
                                        <figure>
                                            <img src={ManagerImg} />
                                        </figure>
                                        <h3>Recruitment Manager</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="os-box" style={{ background: '#483252' }}>
                                    <Link to="/dkglobaladmin/admintraining/management/marketing-manager">
                                        <figure>
                                            <img src={MarketingManagerImg} />
                                        </figure>
                                        <h3>Marketing Manager</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="os-box" style={{ background: '#D58B5D' }}>
                                    <Link to="/dkglobaladmin/admintraining/management/hr-manager">
                                        <figure>
                                            <img src={HrManagerImg} />
                                        </figure>
                                        <h3>HR Manager</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="os-box" style={{ background: '#2e97b6' }}>
                                    <Link to="/dkglobaladmin/admintraining/management/it-manager">
                                        <figure>
                                            <img src={ItManagerImg} />
                                        </figure>
                                        <h3>IT Manager</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="os-box" style={{ background: '#5E766E' }}>
                                    <Link to="/dkglobaladmin/admintraining/management/resource-manager">
                                        <figure>
                                            <img src={ResourceManagerImg} />
                                        </figure>
                                        <h3>Resource Manager</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="os-box" style={{ background: '#a84d4e' }}>
                                    <Link to="/dkglobaladmin/admintraining/management/finance-manager">
                                        <figure>
                                            <img src={FinanceManagerImg} />
                                        </figure>
                                        <h3>Finance Manager</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="os-box" style={{ background: '#57496d' }}>
                                    <Link to="/dkglobaladmin/admintraining/management/operation-manager">
                                        <figure>
                                            <img src={OperationManagerImg} />
                                        </figure>
                                        <h3>Operation Manager</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="os-box" style={{ background: '#a43b63' }}>
                                    <Link to="/dkglobaladmin/admintraining/management/director">
                                        <figure>
                                            <img src={DirectorImg} />
                                        </figure>
                                        <h3>Director</h3>
                                    </Link>
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="#">
                                        <figure>
                                            <img src={JobSpecsImg} />
                                        </figure>
                                        <h3>Mini Job Specs</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="#">
                                        <figure>
                                            <img src={SendreminderImg} />
                                        </figure>
                                        <h3>Send Reminders</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="os-box" style={{ background: '#57496D' }}>
                                    <Link to="#">
                                        <figure>
                                            <img src={NotReachablesImg} />
                                        </figure>
                                        <h3>Not Reachables</h3>
                                    </Link>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TrainingManagement;

