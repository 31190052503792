import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './mainmenusubmenuc.scss';

const AnnoucementSubmenu = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain dkg-subMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/my-mailbox/summary" className={(segment2 == 'summary') ? 'active' : ''}>Summary</Link></li>
                        <li><Link to="#/my-mailbox/my-mailbox1" className={`${(segment2 == 'my-mailbox1') ? 'active' : ''}`}>My Mailbox 1</Link></li>
                        <li><Link to="#/my-mailbox/my-mailbox2" className={`${(segment2 == 'my-mailbox2') ? 'active' : ''}`}>My Mailbox 2</Link></li>
                        <li><Link to="#/my-mailbox/my-mailbox3" className={`${(segment2 == 'my-mailbox3') ? 'active' : ''}`}>My Mailbox 3</Link></li>
                        <li><Link to="#/my-mailbox/my-mailbox4" className={`${(segment2 == 'my-mailbox4') ? 'active' : ''}`}>My Mailbox 4</Link></li>
                        <li><Link to="#/my-mailbox/my-mailbox5" className={`${(segment2 == 'my-mailbox5') ? 'active' : ''}`}>My Mailbox 5</Link></li>
                        <li><Link to="#/my-mailbox/my-mailbox6" className={`${(segment2 == 'my-mailbox6') ? 'active' : ''}`}>My Mailbox 6</Link></li>
                        <li><Link to="#/my-mailbox/my-mailbox7" className={`${(segment2 == 'my-mailbox7') ? 'active' : ''}`}>My Mailbox 7</Link></li>
                        <li><Link to="#/my-mailbox/my-mailbox7" className={`${(segment2 == 'my-mailbox7') ? 'active' : ''}`}>My Mailbox 8</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default AnnoucementSubmenu;
