import React from 'react';
import './mainmenusumm.scss';
import { Link } from 'react-router-dom';


const Summary = () => {
    return (
        <>
            <div className='container-fluid dk-maimenu-suMMPageCon'>
                <div className='row dk-maimenu-suMMCon'>
                    <div className='col-md-12 dk-mainmenu-pagetitle d-none'>
                        <h2 className='text-center w-100'>Activities  Summary</h2>
                    </div>
                    <div className='col-md-12'>
                        <div className='dkg-mainsuMM-leftCon'>
                            <div className='dkg-mainsuMM-leftTopCon'>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>ATTENDANCE - TODAY</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count">15</div>
                                    </div>
                                </div>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>LEAVE - TODAY</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count">0</div>
                                    </div>
                                </div>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>SALES KPI - TODAY</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count">200</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='dkg-mainsuMM-leftCon'>
                            <div className='dkg-mainsuMM-leftTopCon'>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>EMAILS - TODAY</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count">150</div>
                                    </div>
                                </div>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>TASKS - TODAY</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count">25</div>
                                    </div>
                                </div>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>NOTIFICATION - TODAY</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count">70</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='dkg-mainsuMM-leftCon'>
                            <div className='dkg-mainsuMM-leftTopCon'>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>CANDIDATES - TODAY</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count">200</div>
                                    </div>
                                </div>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>JOBS - TODAY</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count">178</div>
                                    </div>
                                </div>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>DATABASE - TODAY</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count">210</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Summary;
