import React from 'react';
import "./casedrpdwn.scss";
import Dropdown from 'react-bootstrap/Dropdown'

const CaseStatusDropDwn = ({ statusBG, caseStatus, candidateId, list, updateProfile, errorClass }) => {
    return (
        <>
            <div className="dk-caseDrpDwn">
                <Dropdown className={errorClass}>
                    <Dropdown.Toggle variant="" className="caseStstoggle" style={{ backgroundColor: statusBG, borderColor: statusBG }}>
                        {caseStatus}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="caseStatusDropMenu">
                        {
                            list.map((statusItem, index) =>
                                <Dropdown.Item key={`profile-case-status-id${index}`} href="#" style={{ backgroundColor: statusItem.bgColor, color: statusItem.textColor }} data-id={candidateId} data-value={statusItem.value} data-key="caseStatus" onClick={updateProfile}>{statusItem.value}</Dropdown.Item>
                            )
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default CaseStatusDropDwn;
