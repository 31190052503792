import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './read-mail.scss';
import { Modal } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';


const ReadMessage = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <span onClick={handleShow}>Lorem Ipsum is simply dummy text of the printing and typesetting.</span>
            <Modal show={show} onHide={handleClose} className="dk-readmail-notificationsnewModal">
                <Modal.Header closeButton className='dk-readmail-notificationsnewModalHeader' style={{ background: '#396a95' }}>
                    <Modal.Title>Email Message</Modal.Title>
                </Modal.Header>
                <Modal.Body className='dk-readmail-notificationsnewModalBody'>
                    <p className='dkg-mail-message'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim, nobis laborum corrupti ratione temporibus natus perferendis beatae cupiditate incidunt a, cum nostrum, ex minus eum veniam nisi reprehenderit error quisquam aliquam accusamus earum pariatur! A modi hic ut esse? Consectetur aliquid exercitationem aut doloribus, ullam odio cum ut eius autem.</p>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReadMessage;
