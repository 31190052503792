import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './kpismainmenu.scss';

const KnowleadgeBaseMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-kpiNavbar">
                <Route>
                    <ul>
                        <li><Link to="/kpi/dashboard" className={`${(segment2 === 'dashboard') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="#/kpi/alerts" className={`${(segment2 === 'alerts') ? 'gray-active' : ''}`}>Report 2</Link></li>
                        <li><Link to="#/kpi/clients" className={`${(segment2 === 'clients') ? 'gray-active' : ''}`}>Report 3</Link></li>
                        <li><Link to="#/kpi/invoices" className={`${(segment2 === 'invoices') ? 'gray-active' : ''}`}>Report 4</Link></li>
                        <li><Link to="#/kpi/incomes" className={(segment2 === "incomes") ? 'gray-active' : ''}>Report 5</Link></li>
                        <li><Link to="#/kpi/expense" className={(segment2 === "expense") ? 'gray-active' : ''}>Report 6</Link></li>
                        <li><Link to="#/kpi/vat-file" className={(segment2 === "vat-file") ? 'gray-active' : ''}>Report 7</Link></li>
                        <li><Link to="#/kpi/bank-state" className={(segment2 === "bank-state") ? 'gray-active' : ''}>Report 8</Link></li>
                        <li><Link to="#/kpi/information" className={(segment2 === "information") ? 'gray-active' : ''}>Report 9</Link></li>
                        <li><Link to="#/kpi/Settings" className={(segment2 === "settings") ? 'gray-active' : ''}>Report 10</Link></li>
                        <li><Link to="#/kpi/training" className={(segment2 === "training") ? 'gray-active' : ''}>Report 11</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default KnowleadgeBaseMenu;