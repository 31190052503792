import React from 'react';
import './casestatus.scss';

const CaseStatusDropDwn = ({ caseStatus, value }) => {
    let bgColor = "";
 
    switch (caseStatus) {
        
        case 'In Progress':
            bgColor = '#d58b5d'
            break;       
        case 'Successful':
            bgColor = '#316a67'
            break;       
        case 'Unsucessful':
            bgColor = '#da3d5e'
            break;       

        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor }}>
                {
                    (value == "Blank") ? "" : value
                }
            </div>
        </>
    )
}

export default CaseStatusDropDwn;