import React, { useState } from "react";
import $ from "jquery";

const RadioBtn = ({ id, checkActiveExtraDiv, checkExtraDivBtnTxt, setDataRowId }) => {
 
    const toggleCheckbox = () => {
        setDataRowId(id)
        var checkedBoxCount = $('input[name="chkScreanCandidate[]"]:checked').length;
        if (checkedBoxCount > 0) {
            checkActiveExtraDiv(true, checkedBoxCount)
        } else {
            checkActiveExtraDiv(false, 0)
        }
        var checkedBoxCount = $('input[name="chkScreanCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkScreanCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            checkExtraDivBtnTxt('Check All')
        }
        if (checkedBoxCount == totalCheckboxes) {
            checkExtraDivBtnTxt('Uncheck All')
        }
    }

    return <input type="radio" className="chkScreanCandidate " name="chkScreanCandidate[]" value={id} data-id={id} onChange={toggleCheckbox} />;
};

export default RadioBtn;