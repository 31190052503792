import React from 'react';
import StatementsLeftPanel from './leftpanel/StatementsLeftPanel';
import '../bankingcustom.scss';

const BankingStatements = () => {
    return (
        <>
            <div className="dk-moduleBankingProcess-main">
                <div className="dk-moduleBankingProcess-cols">
                    <StatementsLeftPanel />
                    <div className="dk-moduleBankingProcess-rightPanel">
                        <div className="title">Overview</div>
                        <div className="dk-moduleBankingProcess-rightCols">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BankingStatements;
