import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';
import "./addnew.css";
import { addAppsTracker, updateValueApps, clearState } from "../../../../../slice/trackers/appsTracker/appsTracker"
import Submit from "../../../../ui/submitButton";
import { showSuccess, showError } from "../../../../utils/messages/messageSlice";

const AddNew = ({ onHide, rowId, value, jobBoardId, recruiter }) => {

    const dispatch = useDispatch();
    const { isUpdate, isInsert, isError, errorMessage } = useSelector(state => state.appsTracker)

    const inputRef = useRef();
    useEffect(() => {
        inputRef.current.focus();
    })

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [newValue, setNewValue] = useState("");
    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "newValue":
                setNewValue(value)
                break;
            default:
                break;
        }
    }

    const handleButtonClick = async (e) => {
        e.preventDefault()

        if (rowId == '0' && value == '0') {

            if (!loading) {
                setSuccess(false);
                setLoading(true);
                await dispatch(addAppsTracker({ 'recruiter': recruiter, 'jobBoard': jobBoardId, value: newValue }))
            }
        } else {
            if (!loading) {
                setSuccess(false);
                setLoading(true);
                await dispatch(updateValueApps({ rowId, value: newValue }))
            }
        }
    }

    useEffect(() => {
        if (isInsert) {
            dispatch(showSuccess({ msg: 'Record added successfully.' }))
            dispatch(clearState())
            onHide()
            setSuccess(true);
            setLoading(false);
        }
        if (isUpdate) {
            dispatch(showSuccess({ msg: 'Record update successfully.' }))
            dispatch(clearState())
            onHide()
            setSuccess(true);
            setLoading(false);
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }
    }, [isUpdate, isError, isInsert])

    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-adsCreAddNewPop"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                {/* <Modal.Header closeButton className="dk-detViewheader">
                <Modal.Title className="dk-detViewTitle" id="example-custom-modal-styling-title">
                </Modal.Title>
              </Modal.Header> */}
                <Modal.Body>
                    <form action="">
                        <div className="dk-adsCreditInCon">
                            <div className="firstInput">
                                <label>Current</label>
                                <input type="text" className="form-control" value={value} readOnly />
                            </div>
                            <div className="SecondInput">
                                <label>New</label>
                                <input type="text" className="form-control" defaultValue="" name="newValue" value={newValue} onChange={handleChange} ref={inputRef} />

                            </div>
                        </div>
                        <div className="dk-adsCreditBtnCon">
                            <Submit txt="SAVE" loading={loading} success={success} onClick={handleButtonClick} className="btn  dk-calcsaveBtn" position="justify-content-center" />
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default AddNew;
