import React from 'react';

const RecFaq = () => {
    return (
        <>

        </>
    )
}

export default RecFaq;
