import React, { useState } from "react";
import $ from "jquery";

const Checkbox = ({ id, checkActiveExtraDiv, checkExtraDivBtnTxt }) => {
    const toggleCheckbox = () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount > 0) {
            checkActiveExtraDiv(true, checkedBoxCount)
        } else {
            checkActiveExtraDiv(false, 0)
        }
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            checkExtraDivBtnTxt('Check All')
        }
        if (checkedBoxCount == totalCheckboxes) {
            checkExtraDivBtnTxt('Uncheck All')
        }
    }

    return <input type="checkbox" className="chkCandidate" name="chkCandidate[]" value={id} data-id={id} onChange={toggleCheckbox} />;
};

export default Checkbox;