import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData, PostData, PutData } from "../../services";

export const addNewFolder = createAsyncThunk(
  "joboffer/addnewfolder",
  async ({ name, status }, thunkAPI) => {
    const res = await PostData(true, "/offerSpec/jobsApi/add-new-folder/", {
      status,
      name,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addNewJob = createAsyncThunk(
  "joboffer/addnew",
  async (
    { job_id, category, company, project, role, lang, name, status },
    thunkAPI
  ) => {
    const res = await PostData(true, "/offerSpec/jobsApi/add-new-job/", {
      job_id,
      category,
      company,
      project,
      role,
      lang,
      name,
      status,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobsCount = createAsyncThunk(
  "joboffer/saved-inactive/specCount",
  async ({ status, job_status }, thunkAPI) => {
    const res = await PostData(true, "/offerSpec/jobsApi/job-count", {
      status,
      job_status,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const folderJobsCount = createAsyncThunk(
  "joboffer/folder/FolderspecCount",
  async ({ status }, thunkAPI) => {
    const res = await PostData(true, "/offerSpec/jobsApi/folder-job-count", {
      status,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateJobSpecFolder = createAsyncThunk(
  "jobs/folder/update",
  async ({ id, key, value }, thunkAPI) => {
    console.log(key);
    const res = await PutData(true, "/offerSpec/jobsApi/" + id, {
      key,
      value,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateJob = createAsyncThunk(
  "joboffer/active-jobs/update",
  async ({ id, key, value }, thunkAPI) => {
    const res = await PutData(true, "/offerSpec/jobsApi/update-spec/" + id, {
      key,
      value,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const closedlist = createAsyncThunk(
  "joboffer/closed-jobs/list",
  async ({ company, status }, thunkAPI) => {
    const res = await PostData(true, "/offerSpec/list/livejobs", {
      company,
      status,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

const jobsSlice = createSlice({
  name: "jobofferspeclist",
  initialState: {
    jobOfferSpecDataList: [],
    FolderjobOfferSpecDataList: [],
    jobSpecCount: [],
    folderJobSpecCount: [],
    isLoading: false,
    isUpdate: false,
    isError: false,
    closedjobOfferSpecDataList: [],
    isSuccess: false,
    message: "",
    isSpecCountLoading: false,
    isFolderSpecCountLoading: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isUpdate = false;
      state.isSpecCountLoading = false;
      state.isFolderSpecCountLoading = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addNewFolder.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isFolderSpecCountLoading = true;
    });
    builder.addCase(addNewFolder.fulfilled, (state, { payload }) => {
      if (payload.status) {
        state.FolderjobOfferSpecDataList = payload.result;
        state.isFolderSpecCountLoading = false;
        state.folderJobSpecCount.unshift(payload.result);
        state.isSuccess = payload.status;
      } else {
        state.isSuccess = "error";
      }
      state.message = payload.message;
      state.isLoading = false;
    });

    builder.addCase(addNewFolder.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
      state.isFolderSpecCountLoading = false;
    });

    builder.addCase(folderJobsCount.pending, (state) => {
      state.isFolderSpecCountLoading = true;
    });
    builder.addCase(folderJobsCount.fulfilled, (state, { payload }) => {
      state.folderJobSpecCount = payload.result;
      state.isFolderSpecCountLoading = false;
    });
    builder.addCase(folderJobsCount.rejected, (state, action) => {
      state.isFolderSpecCountLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(addNewJob.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(addNewJob.fulfilled, (state, { payload }) => {
      state.jobOfferSpecDataList = payload.result;
      state.message = payload.message;
      state.isSuccess = payload.status;
      state.isLoading = false;
    });
    builder.addCase(addNewJob.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobsCount.pending, (state) => {
      state.isSpecCountLoading = true;
    });
    builder.addCase(jobsCount.fulfilled, (state, { payload }) => {
      state.jobSpecCount = payload.result;
      state.isSpecCountLoading = false;
    });
    builder.addCase(jobsCount.rejected, (state, action) => {
      state.isSpecCountLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(updateJobSpecFolder.pending, (state) => {
      state.isFolderSpecCountLoading = true;
    });

    builder.addCase(updateJobSpecFolder.fulfilled, (state, { payload }) => {
      let mainArray = state.folderJobSpecCount;
      let finalResult = [];
      finalResult = mainArray.map((r) => {
        if (r.id == payload.result.id) {
          return {
            id: r.id,
            name: r.name,
            status: payload.result.status,
            count: r.count,
          };
        } else {
          return r;
        }
      });

      state.isFolderSpecCountLoading = false;
      state.folderJobSpecCount = finalResult;
      state.message = payload.message;
      state.isSuccess = payload.status;
      state.isLoading = false;
    });
    builder.addCase(updateJobSpecFolder.rejected, (state, action) => {
      state.isError = true;
      state.isFolderSpecCountLoading = false;
      state.message = "Something went wrong";
    });

    builder.addCase(closedlist.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(closedlist.fulfilled, (state, { payload }) => {
      state.closedjobOfferSpecDataList = payload.result;
      state.message = payload.message;
      state.isLoading = false;
    });
    builder.addCase(closedlist.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(updateJob.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateJob.fulfilled, (state, action) => {
      state.isUpdate = true;
      state.isLoading = true;
    });

    builder.addCase(updateJob.rejected, (state, action) => {
      state.isError = true;
      // state.message = action.error.message;
    });
  },
});
export const { clearState } = jobsSlice.actions;
export default jobsSlice.reducer;
