import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
//import "./document.scss";
import { mydoclistId } from "../../../../../../slice/cmsSlice";
//import { Document, Page } from 'react-pdf';
import { FaEye } from "react-icons/fa";

const CandidateDocumentModal = ({ file_name, documentid }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = async () => {
    setShow(true);
  };

  const [frameType, setFrameType] = useState("0");
  const [fileUrl, setFileUrl] = useState("0");

  function isEmpty(obj) {
    for (var i in obj) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (show) {
      async function fetchData() {
        const result = await dispatch(mydoclistId({ id: documentid }));
        if (!isEmpty(result.payload.result)) {
          const fileName = result.payload.result.fileName;
          if (fileName != null) {
            setFrameType(fileName.split(".").pop());
            setFileUrl(fileName);
          } else {
            setFileUrl("0");
          }
        } else {
          setFileUrl("0");
        }
      }
      fetchData();
    }
  }, [show]);

  return (
    <>
      <span
        onClick={handleShow}
        className="cursor-pointer dkg-action-btn open-btn mr-2"
      >
        <FaEye />
      </span>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dk-pipedocs-previewModal dkg-pipeline-candModal-234"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        animation={false}
      >
        <Modal.Header
          closeButton
          className="dkg-pipeline-candModal-header"
        ></Modal.Header>
        {frameType != "0" ? (
          fileUrl != "0" ? (
            <Modal.Body className="dk-pipedocs-preview-modalbody">
              {frameType == "pdf" ||
              frameType == "PDF" ||
              frameType == "jpg" ||
              frameType == "JPG" ||
              frameType == "PNG" ||
              frameType == "png" ? (
                <iframe
                  src={process.env.REACT_APP_INTERNAL_BASE_URL + fileUrl}
                  frameBorder={0}
                ></iframe>
              ) : (
                <iframe
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${
                    process.env.REACT_APP_INTERNAL_BASE_URL + fileUrl
                  }`}
                  frameBorder={0}
                ></iframe>
              )}
            </Modal.Body>
          ) : (
            <Modal.Body className="dk-pipedocs-preview-modalbody">
              <h1>Document Not Found</h1>
            </Modal.Body>
          )
        ) : null}
      </Modal>
    </>
  );
};
export default CandidateDocumentModal;
