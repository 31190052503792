import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import './edittodo.scss'
import SubmitButton from "../../../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { updateValue, detailsById, clearState } from "../../../../../../slice/account/todo/todoSlice";
import { showSuccess, showError, clearMessage } from "../../../../../../slice/utils/message/messageSlice";

const EditToDoList = ({ handleClose, id }) => {
    const dispatch = useDispatch();
    const { details, isDetails, isSuccess, isError, isLoading, message } = useSelector(state => state.todo);

    const [isShow, setIsShow] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [title, setTitle] = useState('')

    useEffect(async () => {
        if (id > 0) {
            await dispatch(detailsById({ id }))
        }
    }, [id])

    useEffect(() => {
        setIsShow(true)
    }, [id])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (!title) {
                dispatch(showError({ msg: "Please fill notes" }))
            } else {
                await dispatch(updateValue({ id, title }))
            }
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(clearState());
            dispatch(showError({ msg: message }))
        }
        if (isDetails) {
            dispatch(clearState());
            setTitle(details.title)
        }
        if (isSuccess) {
            handleClose()
        }
    }, [isLoading, isDetails, isError, isSuccess, dispatch, message])

    return (
        <>
            <Modal show={isShow} onHide={handleClose} className="dk-edit-todolist-Modal" centered>
                <Modal.Header closeButton className='dk-edit-todolist-ModalHeader'>
                    <Modal.Title>CREATE NEW TASK</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-edit-todolist-ModalBody">
                    <div className="dk-todolistEditFrmGrp">
                        <label>Edit Task</label>
                        <textarea className="w-100" value={title} onChange={(e) => setTitle(e.target.value)}></textarea>
                    </div>
                    <div className="dk-todolistupdatebtnCon">
                        <SubmitButton txt="Update" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="btn dk-todolistupdatebtn" />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditToDoList
