import React from 'react';
import "./clientinvoicesumm.scss";

const ClientinovoiceSummary = () => {
    return (
        <>
            <div className="dk-clientInvoiceDashboard-main">
                <div className="dk-clientInvoice-flex">
                    <div className="dk-box-cols">
                        <div className="d-flex w-100">
                            <div className="dk-invoice-box">
                                <div className="title">Years <span>509</span></div>
                                <ul></ul>
                            </div>
                            <div className="dk-invoice-box">
                                <div className="title">Month <span>509</span></div>
                                <ul></ul>
                            </div>
                            <div className="dk-invoice-box">
                                <div className="title">STATUS <span>509</span></div>
                                <ul></ul>
                            </div>
                            <div className="dk-invoice-box">
                                <div className="title">PROGRESS <span>509</span></div>
                                <ul></ul>
                            </div>
                            <div className="dk-invoice-box">
                                <div className="title">STAGE <span>509</span></div>
                                <ul></ul>
                            </div>
                        </div>
                        <div className="d-flex w-100">
                            <div className="dk-invoice-box">
                                <div className="title">Company <span>509</span></div>
                                <ul></ul>
                            </div>
                            <div className="dk-invoice-box">
                                <div className="title">Project <span>509</span></div>
                                <ul></ul>
                            </div>
                            <div className="dk-invoice-box">
                                <div className="title">Language <span>509</span></div>
                                <ul></ul>
                            </div>
                            <div className="dk-invoice-box">
                                <div className="title">Role Type <span>509</span></div>
                                <ul></ul>
                            </div>
                            <div className="dk-invoice-box">
                                <div className="title">CV Source <span>509</span></div>
                                <ul></ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClientinovoiceSummary;
