import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaCheck, FaTimes, FaQuestion } from "react-icons/fa";

const TrickFilter = () => {
    return (
        <>
            <Dropdown className="dkg-trickfilter-dropDown15 mr-2">
                <Dropdown.Toggle variant="" className="dkg-trickfilter-dropDown-toggle15" >
                    <FaCheck style={{ color: "#d3d3d3" }} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dkg-trickfilter-dropDown-menu15">
                    <Dropdown.Item href="#" className="dkg-trickfilter-dropDown-item15">
                        <div className="dk-idDropdown-cols">
                            <span>
                                <FaCheck style={{ color: "#048204" }} />
                            </span>
                            <span className='dkg-tricker'>100% </span>
                            <span className='dkg-tricker'>Matched</span>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#" className="dkg-trickfilter-dropDown-item15">
                        <div className="dk-idDropdown-cols">
                            <span>
                                <FaCheck style={{ color: "#D58B5D" }} />
                            </span>
                            <span className='dkg-tricker'>75% </span>
                            <span className='dkg-tricker'>Matched</span>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#" className="dkg-trickfilter-dropDown-item15">
                        <div className="dk-idDropdown-cols">
                            <span>
                                <FaCheck style={{ color: "#3a76b4" }} />
                            </span>
                            <span className='dkg-tricker'>50% </span>
                            <span className='dkg-tricker'>Matched</span>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#" className="dkg-trickfilter-dropDown-item15">
                        <div className="dk-idDropdown-cols">
                            <span >
                                <FaCheck style={{ color: "#d60101" }} />
                            </span>
                            <span className='dkg-tricker'>0% </span>
                            <span className='dkg-tricker'>Matched
                            </span>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#" className="dkg-trickfilter-dropDown-item15">
                        <div className="dk-idDropdown-cols">
                            <span>
                                <FaCheck style={{ color: "#d3d3d3" }} />
                            </span>
                            <span className='dkg-tricker'>Blank</span>
                        </div>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default TrickFilter