import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "./summary.scss";
// import SummarylongCols from './SummarylongCols';
// import SummaytextCols from './SummaytextCols';
import { statusCountsFun, categoryCountFun, industryCountsFun, businessCountsFun, cityCountsFun, countryCountsFun, companyNewCountsFun, accountMangerFun, geographyCountsFun, contractCountsFun, renewalCountsFun, feetypeCountsFun, clearState } from "../../../../slice/companies/clients/clientSlice";

const Summary = () => {
    const dispatch = useDispatch();
    const { statusList, industryList, renewalList, businessList, geographyList, cityList, countryList, companyCountList, accountManagerList, categoryList, contractList, feetypeList, isError } = useSelector(state => state.companies)

    const [search, setSearch] = useState([]);
    const [filter, setFilter] = useState({});

    const [filterTags, setFilterTags] = useState([]);
    const [tagLength, setTagLength] = useState(0);

    const [recruterFilter, setRecruterFilter] = useState({});
    const [companyFilter, setCompanyfilter] = useState({});

    const [isCategoryFilter, setIsCategoryFilter] = useState(false);
    const [categoryFilterText, setCategoryFilterText] = useState('');

    const [isStatusFilter, setIsStatusFilter] = useState(false);
    const [statusFilterText, setStatusFilterText] = useState('');

    const [isIndustryFilter, setIsIndustryFilter] = useState(false);
    const [industryFilterText, setIndustryFilterText] = useState('');

    const [isCompanyFilter, setIsCompanyFilter] = useState(false);
    const [companyFilterText, setCompanyFilterText] = useState('');

    const [isCityFilter, setIsCityFilter] = useState(false);
    const [cityFilterText, setCityFilterText] = useState('');

    const [isCountryFilter, setIsCountryFilter] = useState(false);
    const [countryFilterText, setCountryFilterText] = useState('');

    const [isAccountManagerFilter, setIsAccountManagerFilter] = useState(false);
    const [accountManagerFilterText, setAccountManagerFilterText] = useState('');

    const [isGeographyFilter, setIsGeographyFilter] = useState(false);
    const [geographyFilterText, setGeographyFilterText] = useState('');

    const [isFeetypeFilter, setIsFeetypeFilter] = useState(false);
    const [feetypeFilterText, setFeetypeFilterText] = useState('');

    const [isRenewalFilter, setIsRenewalFilter] = useState(false);
    const [renewalFilterText, setRenewalFilterText] = useState('');

    const [isContractFilter, setIsContractFilter] = useState(false);
    const [contractFilterText, setContractFilterText] = useState('');

    const [isBusinessFilter, setIsBusinessFilter] = useState(false);
    const [businessFilterText, setBusinessFilterText] = useState('');

    const [removeFilter, setRemoveFilter] = useState(0);

    const getRequestParams = (filter) => {
        let params = {};
        params["filter"] = filter;
        params["search"] = search;
        return params;
    }

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    const getFilterRequestParams = (recruterFilter, companyFilter) => {
        let params = {};
        params["search"] = search;
        params["filter"] = {
            "recruterFilter": recruterFilter,
            "companyFilter": companyFilter
        }
        return params;
    }

    useEffect(() => {
        if (removeFilter > 0 && isEmpty(filter)) {
            try {
                const params = getFilterRequestParams(filter);
                dispatch(statusCountsFun(params))
                dispatch(categoryCountFun(params))
                dispatch(industryCountsFun(params))
                dispatch(businessCountsFun(params))
                dispatch(geographyCountsFun(params))
                dispatch(contractCountsFun(params))
                dispatch(renewalCountsFun(params))
                dispatch(feetypeCountsFun(params))
                dispatch(cityCountsFun(params))
                dispatch(countryCountsFun(params))
                dispatch(companyNewCountsFun(params))
                dispatch(accountMangerFun(params))
            } catch (err) {
                console.log(err)
            }
        }
    }, [removeFilter])

    useEffect(() => {
        if (!isEmpty(filter)) {
            const params = getFilterRequestParams(recruterFilter, companyFilter);
            dispatch(statusCountsFun(params))
            dispatch(categoryCountFun(params))
            dispatch(industryCountsFun(params))
            dispatch(geographyCountsFun(params))
            dispatch(contractCountsFun(params))
            dispatch(renewalCountsFun(params))
            dispatch(feetypeCountsFun(params))
            dispatch(businessCountsFun(params))
            dispatch(cityCountsFun(params))
            dispatch(countryCountsFun(params))
            dispatch(companyNewCountsFun(params))
            dispatch(accountMangerFun(params))
        }
    }, [filter, removeFilter])



    useEffect(() => {
        async function fetchData() {
            try {
                const params = getRequestParams(filter);
                dispatch(statusCountsFun(params))
                dispatch(categoryCountFun(params))
                dispatch(industryCountsFun(params))
                dispatch(geographyCountsFun(params))
                dispatch(contractCountsFun(params))
                dispatch(renewalCountsFun(params))
                dispatch(feetypeCountsFun(params))
                dispatch(businessCountsFun(params))
                dispatch(cityCountsFun(params))
                dispatch(countryCountsFun(params))
                dispatch(companyNewCountsFun(params))
                dispatch(accountMangerFun(params))

            } catch (err) {
                console.log(err)
            }
        }
        if (isEmpty(filter)) {
            fetchData()
        }
    }, [filter])

    const removeTag = async (e) => {
        const type = e.target.dataset.type

        switch (type) {
            case 'feeType':
                setIsFeetypeFilter(false)
                setFeetypeFilterText('')
                delete companyFilter[type];
                delete filter[type];
                setCompanyfilter(companyFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;
            case 'renewal':
                setIsRenewalFilter(false)
                setRenewalFilterText('')
                delete companyFilter[type];
                delete filter[type];
                setCompanyfilter(companyFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;
            case 'incomeTax':
                setIsContractFilter(false)
                setContractFilterText('')
                delete companyFilter[type];
                delete filter[type];
                setCompanyfilter(companyFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;
            case 'inStatus':
                setIsStatusFilter(false)
                setStatusFilterText('')
                delete companyFilter[type];
                delete filter[type];
                setCompanyfilter(companyFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            case 'type':
                setIsCategoryFilter(false)
                setCategoryFilterText('')
                delete companyFilter[type];
                delete filter[type];
                setCompanyfilter(companyFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            case 'industry':
                setIsIndustryFilter(false)
                setIndustryFilterText('')
                delete companyFilter[type];
                delete filter[type];
                setCompanyfilter(companyFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            case 'name':
                setIsCompanyFilter(false)
                setCompanyFilterText('')
                delete companyFilter[type];
                delete filter[type];
                setCompanyfilter(companyFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            case 'city':
                setIsCityFilter(false)
                setCityFilterText('')
                delete companyFilter[type];
                delete filter[type];
                setCompanyfilter(companyFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            case 'country':
                setIsCountryFilter(false)
                setCountryFilterText('')
                delete companyFilter[type];
                delete filter[type];
                setCompanyfilter(companyFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            case 'am_id':
                setIsAccountManagerFilter(false)
                setAccountManagerFilterText('')
                delete companyFilter[type];
                delete filter[type];
                setCompanyfilter(companyFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            case 'collaboration':
                setIsGeographyFilter(false)
                setGeographyFilterText('')
                delete companyFilter[type];
                delete filter[type];
                setCompanyfilter(companyFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;
            case 'business':
                setIsBusinessFilter(false)
                setBusinessFilterText('')
                delete companyFilter[type];
                delete filter[type];
                setCompanyfilter(companyFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            default:
                break;
        }
        setTagLength(tagLength - 1)
        setFilterTags(filterTags.filter((item) => item.type !== type))
    }

    const selectFilter = (e) => {
        const type = e.target.dataset.type;
        const value = e.target.dataset.value;
        let i;
        switch (type) {

            case 'feeType':
                i = filterTags.findIndex(function (x) { return x.type === 'feeType' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                let nal = ''
                if (value == "") {
                    nal = "Blank"
                } else {
                    nal = value
                }
                setIsFeetypeFilter(true)
                setFeetypeFilterText(nal)
                setCompanyfilter({ ...companyFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;
            case 'renewal':
                i = filterTags.findIndex(function (x) { return x.type === 'renewal' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                let neal = ''
                if (value == "") {
                    neal = "Blank"
                } else {
                    neal = value
                }
                setIsRenewalFilter(true)
                setRenewalFilterText(neal)
                setCompanyfilter({ ...companyFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;
            case 'incomeTax':
                i = filterTags.findIndex(function (x) { return x.type === 'incomeTax' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setIsContractFilter(true)
                let nVal = ''
                if (value == "") {
                    nVal = "Blank"
                } else {
                    nVal = value
                }
                setContractFilterText(nVal)
                setCompanyfilter({ ...companyFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;
            case 'inStatus':
                i = filterTags.findIndex(function (x) { return x.type === 'inStatus' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setIsStatusFilter(true)
                let newVal = '';
                if (value == '1') {
                    newVal = 'Active';
                } else if (value == '0') {
                    newVal = 'Closed';
                } else {
                    newVal = 'Inactive'
                }
                setStatusFilterText(newVal)
                setCompanyfilter({ ...companyFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'type':
                i = filterTags.findIndex(function (x) { return x.type === 'type' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setIsCategoryFilter(true)
                setCategoryFilterText(value)
                setCompanyfilter({ ...companyFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'industry':
                i = filterTags.findIndex(function (x) { return x.type === 'industry' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setIsIndustryFilter(true)
                setIndustryFilterText(value)
                setCompanyfilter({ ...companyFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'name':
                i = filterTags.findIndex(function (x) { return x.type === 'name' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setIsCompanyFilter(true)
                setCompanyFilterText(value)
                setCompanyfilter({ ...companyFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'city':
                i = filterTags.findIndex(function (x) { return x.type === 'city' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setIsCityFilter(true)
                setCityFilterText(value)
                setCompanyfilter({ ...companyFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'country':
                i = filterTags.findIndex(function (x) { return x.type === 'country' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setIsCountryFilter(true)
                setCountryFilterText(value)
                setCompanyfilter({ ...companyFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'am_id':
                const text = e.target.dataset.text;
                setIsAccountManagerFilter(true)
                setAccountManagerFilterText(text)
                setCompanyfilter({ ...companyFilter, [type]: value })
                setRecruterFilter({ ...recruterFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'business':
                i = filterTags.findIndex(function (x) { return x.type === 'business' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setIsBusinessFilter(true)
                setBusinessFilterText(value)
                setCompanyfilter({ ...companyFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'collaboration':
                i = filterTags.findIndex(function (x) { return x.type === 'collaboration' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setIsGeographyFilter(true)
                setGeographyFilterText(value)
                setCompanyfilter({ ...companyFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            default:
                break;
        }

    }


    return (
        <>
            <div className="container-fluid dk-resSummaryCon">
                {/* <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="dk-reSCenterheaderCont">
                            <h2 className="dk-reSPageTitle">CLIENTS SUMMARY</h2>
                        </div>
                    </div>
                </div> */}
                <div className="row mt-2">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="dk-reSummThreeBox d-flex w-100">
                            <div className="dk-reSummThreeCols dkg-manager-cols">
                                <div className={`${(!isAccountManagerFilter) ? 'dk-reSummTwoHeader dk-reSHeaderCont78 ' : 'dk-reSummTwoHeader dk-reSHeaderCont78  dk-dbsuMM-filterHeader'}`} >
                                    {
                                        (!isAccountManagerFilter) ?
                                            <div className="dk-colstitle">AM</div>
                                            :
                                            <div className=''>
                                                {accountManagerFilterText}
                                                <span><i className='fa fa-times-circle  ml-2' data-type="am_id" onClick={removeTag}></i></span>
                                            </div>
                                    }
                                    <span className="dk-totalCvCount">
                                        {accountManagerList.reduce((total, currentValue) => total = total + currentValue.count, 0)}
                                    </span>
                                </div>
                                <div className="dk-reSummTwoContent dkg-client-info-topContent">
                                    {
                                        accountManagerList.map((data, index) => (
                                            <li key={index + 1} data-type="am_id" data-text={data.amInfo.firstName + " " + data.amInfo.lastName} data-value={data.amInfo.id} onClick={selectFilter}>
                                                <div data-type="am_id" data-text={data.amInfo.firstName + " " + data.amInfo.lastName} data-value={data.amInfo.id} onClick={selectFilter} className="dk-clisuMMLeft d-flex">
                                                    <div className="dk-CliensuMMImgCri">
                                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + data.amInfo.profileImage} className="clientSummImg" data-type="am_id" data-text={data.amInfo.firstName + " " + data.amInfo.lastName} data-value={data.amInfo.id} onClick={selectFilter} />
                                                    </div>
                                                    {data.amInfo.firstName + " " + data.amInfo.lastName}
                                                </div>
                                                <span data-type="am_id" data-text={data.amInfo.firstName + " " + data.amInfo.lastName} data-value={data.amInfo.id} onClick={selectFilter}>{data.count}</span>
                                            </li>
                                        ))
                                    }

                                </div>
                            </div>
                            <div className="dk-reSummThreeCols">
                                <div className={`${(!isStatusFilter) ? 'dk-reSummTwoHeader dk-reSHeaderCont78' : 'dk-reSummTwoHeader dk-reSHeaderCont78 dk-dbsuMM-filterHeader'}`}>
                                    {
                                        (!isStatusFilter) ?
                                            <>
                                                <div className="dk-reSHeaderTitle">Status</div>
                                            </>
                                            :
                                            <>
                                                <div className=''>
                                                    {statusFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="inStatus" onClick={removeTag}></i></span>
                                                </div>
                                            </>
                                    }
                                    <span >
                                        {
                                            statusList.reduce(function (tot, arr) {
                                                return tot + arr.count;
                                            }, 0)
                                        }
                                    </span>

                                </div>
                                <div className="dk-reSummTwoContent dkg-client-info-topContent">
                                    {

                                        (statusList.filter(function (val) {
                                            return val.inStatus == '1';
                                        }).length != 0) ?
                                            <li data-type="inStatus" data-value="1" onClick={selectFilter}>
                                                <div data-type="inStatus" data-value="1" onClick={selectFilter}>
                                                    <i className="fas mr-2 fa-circle" aria-hidden="true" style={{ color: "green" }}></i>
                                                    Active
                                                </div>
                                                <span data-type="inStatus" data-value="1" onClick={selectFilter}>
                                                    {
                                                        statusList.filter(items => items.inStatus == '1').map((item, index) =>
                                                            <span className="recNoCount" data-type="inStatus" data-value="1" onClick={selectFilter}>{item.count}</span>
                                                        )
                                                    }
                                                </span>
                                            </li>
                                            : null
                                    }
                                    {
                                        (statusList.filter(function (val) {
                                            return val.inStatus == 0;
                                        }).length != 0) ?
                                            <li data-type="inStatus" data-value="0" onClick={selectFilter}>
                                                <div data-type="inStatus" data-value="0" onClick={selectFilter}>
                                                    <i className="fas mr-2 fa-circle" aria-hidden="true" style={{ color: "red" }}></i>
                                                    Closed
                                                </div>
                                                <span className="mlCount" data-type="inStatus" data-value="0" onClick={selectFilter}>
                                                    {
                                                        statusList.filter(items => items.inStatus == 0).map((item, index) =>
                                                            <span className="recNoCount" data-type="inStatus" data-value="0" onClick={selectFilter}>{item.count}</span>
                                                        )
                                                    }
                                                </span>
                                            </li>
                                            : null

                                    }

                                    {
                                        (statusList.filter(function (val) {
                                            return val.inStatus == '2';
                                        }).length != 0) ?
                                            <li data-type="inStatus" data-value="2" onClick={selectFilter}>
                                                <div data-type="inStatus" data-value="2" onClick={selectFilter}>
                                                    <i className="fas mr-2 fa-circle" aria-hidden="true" style={{ color: "#eaa03d" }}></i>
                                                    Inactive
                                                </div>
                                                <span className="mlCount" data-type="inStatus" data-value="2" onClick={selectFilter}>
                                                    {
                                                        statusList.filter(items => items.inStatus == '2').map((item, index) =>
                                                            <span className="recNoCount" data-type="inStatus" data-value="2" onClick={selectFilter}>{item.count}</span>
                                                        )
                                                    }
                                                </span>
                                            </li>
                                            : null
                                    }

                                </div>

                            </div>
                            <div className="dk-reSummThreeCols">
                                <div className={`${(!isCategoryFilter) ? 'dk-reSummTwoHeader dk-reSHeaderCont78' : 'dk-reSummTwoHeader dk-reSHeaderCont78 dk-dbsuMM-filterHeader'}`}>
                                    {
                                        (!isCategoryFilter) ?
                                            <>
                                                <div className="dk-reSHeaderTitle">CATEGORY</div>
                                            </>
                                            :
                                            <>
                                                <div className=''>
                                                    {categoryFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="type" onClick={removeTag}></i></span>
                                                </div>
                                            </>
                                    }
                                    <span >
                                        {
                                            categoryList.reduce(function (tot, arr) {
                                                return tot + arr.count;
                                            }, 0)
                                        }
                                    </span>

                                </div>
                                <div className="dk-reSummTwoContent dkg-client-info-topContent">
                                    {
                                        (categoryList.filter(function (val) {
                                            return val.type === "ML";
                                        }).length != 0) ?
                                            <li data-type="type" data-value="ML" onClick={selectFilter}>
                                                <div data-type="type" data-value="ML" onClick={selectFilter}>
                                                    ML
                                                </div>
                                                <span data-type="type" data-value="ML" onClick={selectFilter}>
                                                    {
                                                        categoryList.filter(items => items.type === "ML").map((item, index) =>
                                                            <span className="recNoCount" data-type="type" data-value="ML" onClick={selectFilter}>{item.count}</span>
                                                        )
                                                    }
                                                </span>
                                            </li>
                                            : null
                                    }

                                    {
                                        (categoryList.filter(function (val) {
                                            return val.type === "IT";
                                        }).length != 0) ?
                                            <li data-type="type" data-value="IT" onClick={selectFilter}>
                                                <div data-type="type" data-value="IT" onClick={selectFilter}>
                                                    IT
                                                </div>
                                                <span className="mlCount" data-type="type" data-value="IT" onClick={selectFilter}>
                                                    {
                                                        categoryList.filter(items => items.type === "IT").map((item, index) =>
                                                            <span className="recNoCount" data-type="type" data-value="IT" onClick={selectFilter}>{item.count}</span>
                                                        )
                                                    }
                                                </span>
                                            </li>
                                            : null
                                    }

                                    {
                                        (categoryList.filter(function (val) {
                                            return val.type === "None";
                                        }).length != 0) ?
                                            <li data-type="type" data-value="None" onClick={selectFilter}>
                                                <div data-type="type" data-value="None" onClick={selectFilter}>
                                                    Others
                                                </div>
                                                <span className="mlCount" data-type="type" data-value="None" onClick={selectFilter}>
                                                    {
                                                        categoryList.filter(items => items.type === "None").map((item, index) =>
                                                            <span className="recNoCount" data-type="type" data-value="None" onClick={selectFilter}>{item.count}</span>
                                                        )
                                                    }
                                                </span>
                                            </li>
                                            : null
                                    }

                                </div>
                            </div>
                            <div className="dk-reSummThreeCols">
                                <div className={`${(!isGeographyFilter) ? 'dk-reSummTwoHeader dk-reSHeaderCont78' : 'dk-reSummTwoHeader dk-reSHeaderCont78 dk-dbsuMM-filterHeader'}`}>

                                    {/* <div className={`${(!isGeographyFilter) ? 'dk-reSummTwoHeader dk-reSHeaderCont78' : 'dk-reSummTwoHeader dk-reSHeaderCont78 dk-dbsuMM-filterHeader'}`}> */}
                                    {
                                        (!isGeographyFilter) ?
                                            <>
                                                <div className="dk-reSHeaderTitle">GEOGRAPHY</div>
                                            </>
                                            :
                                            <>
                                                <div className=''>
                                                    {geographyFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="collaboration" onClick={removeTag}></i></span>
                                                </div>
                                            </>
                                    }
                                    <span >
                                        {
                                            geographyList.reduce(function (tot, arr) {
                                                return tot + arr.count;
                                            }, 0)
                                        }
                                    </span>
                                </div>
                                {
                                    (geographyList.filter(function (val) {
                                        return val.collaboration !== "";
                                    }).length != 0) ?
                                        <div className="dk-reSummTwoContent dkg-client-info-topContent">
                                            {geographyList.map((item, index) => (
                                                <li key={`collaboration${index}`} data-type="collaboration" data-value={item.collaboration} onClick={selectFilter}>
                                                    {(item.collaboration !== '') ? item.collaboration : 'Blank'}
                                                    <span data-type="collaboration" data-value={item.collaboration} onClick={selectFilter}>
                                                        {
                                                            item.count
                                                        }
                                                    </span>
                                                </li>
                                            ))}
                                        </div>
                                        : null

                                }

                            </div>
                            <div className="dk-reSummThreeCols">
                                <div className={`${(!isIndustryFilter) ? 'dk-reSummThreHeader dk-reSHeaderCont78' : 'dk-reSummThreHeader dk-reSHeaderCont78 dk-dbsuMM-filterHeader'}`}>
                                    {
                                        (!isIndustryFilter) ?
                                            <>
                                                <div className="dk-reSHeaderTitle">Industry</div>
                                            </>
                                            :
                                            <>
                                                <div className=''>
                                                    {industryFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="industry" onClick={removeTag}></i></span>
                                                </div>
                                            </>
                                    }
                                    <span >
                                        {
                                            industryList.reduce(function (tot, arr) {
                                                return tot + arr.count;
                                            }, 0)
                                        }
                                    </span>
                                </div>
                                {
                                    (industryList.filter(function (val) {
                                        return val.industry !== "";
                                    }).length != 0) ?
                                        <div className="dk-reSummTwoContent dkg-client-info-topContent" >
                                            {industryList.map((item, index) => (
                                                <li key={`industry${index}`} data-type="industry" data-value={item.industry} onClick={selectFilter}>
                                                    {(item.industry !== '') ? item.industry : 'Blank'}
                                                    <span data-type="industry" data-value={item.industry} onClick={selectFilter}>
                                                        {
                                                            item.count
                                                        }
                                                    </span>
                                                </li>
                                            ))}
                                        </div>
                                        : null
                                }

                            </div>
                            <div className="dk-reSummThreeCols">
                                <div className={`${(!isBusinessFilter) ? 'dk-reSummTwoHeader dk-reSHeaderCont78' : 'dk-reSummTwoHeader dk-reSHeaderCont78 dk-dbsuMM-filterHeader'}`}>
                                    {
                                        (!isBusinessFilter) ?
                                            <>
                                                <div className="dk-reSHeaderTitle">Business</div>
                                            </>
                                            :
                                            <>
                                                <div className=''>
                                                    {businessFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="business" onClick={removeTag}></i></span>
                                                </div>
                                            </>
                                    }
                                    <span >
                                        {
                                            businessList.reduce(function (tot, arr) {
                                                return tot + arr.count;
                                            }, 0)
                                        }
                                    </span>
                                </div>
                                {
                                    (industryList.filter(function (val) {
                                        return val.business !== "";
                                    }).length != 0) ?
                                        <div className="dk-reSummTwoContent dkg-client-info-topContent">
                                            {businessList.map((item, index) => (
                                                <li key={`business${index}`} data-type="business" data-value={item.business} onClick={selectFilter}>
                                                    {(item.business !== '') ? item.business : 'Blank'}
                                                    <span data-type="business" data-value={item.business} onClick={selectFilter}>
                                                        {
                                                            item.count
                                                        }
                                                    </span>
                                                </li>
                                            ))}
                                        </div>
                                        :
                                        null
                                }

                            </div>
                        </div>
                        <div className="dk-reSummThreeBox d-flex w-100 mt-4">
                            <div className="dk-reSummThreeCols">
                                <div className={`${(!isCompanyFilter) ? 'dk-reSummThreHeader dk-reSHeaderCont78' : 'dk-reSummThreHeader dk-reSHeaderCont78 dk-dbsuMM-filterHeader'}`}>
                                    {
                                        (!isCompanyFilter) ?
                                            <>
                                                <div className="dk-reSHeaderTitle">COMPANY</div>
                                            </>
                                            :
                                            <>
                                                <div className=''>
                                                    {companyFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="name" onClick={removeTag}></i></span>
                                                </div>
                                            </>
                                    }
                                    <span >
                                        {
                                            companyCountList.reduce(function (tot, arr) {
                                                return tot + arr.count;
                                            }, 0)
                                        }
                                    </span>
                                </div>
                                {
                                    (companyCountList.filter(function (val) {
                                        return val.name !== "";
                                    }).length != 0) ?
                                        <div className="dk-reSummTwoContent dkg-clieninfo-bottom-ullist">
                                            {companyCountList.map((item, index) => (
                                                <li key={`name${index}`} data-type="name" data-value={item.name} onClick={selectFilter}>
                                                    {(item.name !== '') ? item.name : 'Blank'}
                                                    <span data-type="name" data-value={item.name} onClick={selectFilter}>
                                                        {
                                                            item.count
                                                        }
                                                    </span>
                                                </li>
                                            ))}
                                        </div>
                                        :
                                        null
                                }

                            </div>
                            <div className="dk-reSummThreeCols">
                                <div className={`${(!isCountryFilter) ? 'dk-reSummThreHeader dk-reSHeaderCont78' : 'dk-reSummThreHeader dk-reSHeaderCont78 dk-dbsuMM-filterHeader'}`}>
                                    {
                                        (!isCountryFilter) ?
                                            <>
                                                <div className="dk-reSHeaderTitle">Country</div>
                                            </>
                                            :
                                            <>
                                                <div className=''>
                                                    {countryFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="country" onClick={removeTag}></i></span>
                                                </div>
                                            </>
                                    }
                                    <span >
                                        {
                                            countryList.reduce(function (tot, arr) {
                                                return tot + arr.count;
                                            }, 0)
                                        }
                                    </span>
                                </div>
                                {
                                    (countryList.filter(function (val) {
                                        return val.country !== "";
                                    }).length != 0) ?
                                        <div className="dk-reSummTwoContent dkg-clieninfo-bottom-ullist">
                                            {countryList.map((item, index) => (
                                                <li key={`country${index}`} data-type="country" data-value={item.country} onClick={selectFilter}>
                                                    {(item.country !== '') ? item.country : 'Blank'}
                                                    <span data-type="country" data-value={item.country} onClick={selectFilter}>
                                                        {
                                                            item.count
                                                        }
                                                    </span>
                                                </li>
                                            ))}
                                        </div>
                                        : null
                                }

                            </div>
                            <div className="dk-reSummThreeCols">
                                <div className={`${(!isCityFilter) ? 'dk-reSummThreHeader dk-reSHeaderCont78' : 'dk-reSummThreHeader dk-reSHeaderCont78 dk-dbsuMM-filterHeader'}`}>
                                    {
                                        (!isCityFilter) ?
                                            <>
                                                <div className="dk-reSHeaderTitle">City/Town</div>
                                            </>
                                            :
                                            <>
                                                <div className=''>
                                                    {cityFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="city" onClick={removeTag}></i></span>
                                                </div>
                                            </>
                                    }
                                    <span >
                                        {
                                            cityList.reduce(function (tot, arr) {
                                                return tot + arr.count;
                                            }, 0)
                                        }
                                    </span>
                                </div>
                                {
                                    (cityList.filter(function (val) {
                                        return val.city !== "";
                                    }).length != 0) ?
                                        <div className="dk-reSummTwoContent dkg-clieninfo-bottom-ullist">
                                            {cityList.map((item, index) => (
                                                <li key={`city${index}`} data-type="city" data-value={item.city} onClick={selectFilter}>
                                                    {(item.city !== '') ? item.city : 'Blank'}
                                                    <span data-type="city" data-value={item.city} onClick={selectFilter}>
                                                        {
                                                            item.count
                                                        }
                                                    </span>
                                                </li>
                                            ))}
                                        </div>
                                        : null
                                }

                            </div>
                            <div className="dk-reSummThreeCols">
                                <div className={`${(!isContractFilter) ? 'dk-reSummTwoHeader dk-reSHeaderCont78' : 'dk-reSummTwoHeader dk-reSHeaderCont78 dk-dbsuMM-filterHeader'}`}>
                                    {
                                        (!isContractFilter) ?
                                            <>
                                                <div className="dk-reSHeaderTitle">Contract</div>
                                            </>
                                            :
                                            <>
                                                <div className=''>
                                                    {contractFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="incomeTax" onClick={removeTag}></i></span>
                                                </div>
                                            </>
                                    }
                                    <span >
                                        {
                                            contractList.reduce(function (tot, arr) {
                                                return tot + arr.count;
                                            }, 0)
                                        }
                                    </span>
                                </div>
                                {
                                    (contractList.filter(function (val) {
                                        return val.city !== "";
                                    }).length != 0) ?
                                        <div className="dk-reSummTwoContent dkg-clieninfo-bottom-ullist">
                                            {contractList.map((item, index) => (
                                                <li key={`incomeTax${index}`} data-type="incomeTax" data-value={item.incomeTax} onClick={selectFilter}>
                                                    {(item.incomeTax !== '') ? item.incomeTax : 'Blank'}
                                                    <span data-type="incomeTax" data-value={item.incomeTax} onClick={selectFilter}>
                                                        {
                                                            item.count
                                                        }
                                                    </span>
                                                </li>
                                            ))}
                                        </div>
                                        : null
                                }

                            </div>
                            <div className="dk-reSummThreeCols">
                                <div className={`${(!isRenewalFilter) ? 'dk-reSummThreHeader dk-reSHeaderCont78' : 'dk-reSummThreHeader dk-reSHeaderCont78 dk-dbsuMM-filterHeader'}`}>
                                    {
                                        (!isRenewalFilter) ?
                                            <>
                                                <div className="dk-reSHeaderTitle">Renewal</div>
                                            </>
                                            :
                                            <>
                                                <div className=''>
                                                    {renewalFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="renewal" onClick={removeTag}></i></span>
                                                </div>
                                            </>
                                    }
                                    <span >
                                        {
                                            renewalList.reduce(function (tot, arr) {
                                                return tot + arr.count;
                                            }, 0)
                                        }
                                    </span>
                                </div>
                                {
                                    (renewalList.filter(function (val) {
                                        return val.city !== "";
                                    }).length != 0) ?
                                        <div className="dk-reSummTwoContent dkg-clieninfo-bottom-ullist" style={{ backgroundColor: "#eaeaea" }}>
                                            {renewalList.map((item, index) => (
                                                <li key={`renewal${index}`} data-type="renewal" data-value={item.renewal} onClick={selectFilter}>
                                                    {(item.renewal !== '') ? item.renewal : 'Blank'}
                                                    <span data-type="renewal" data-value={item.renewal} onClick={selectFilter}>
                                                        {
                                                            item.count
                                                        }
                                                    </span>
                                                </li>
                                            ))}
                                        </div>
                                        : null
                                }

                            </div>
                            <div className="dk-reSummThreeCols">
                                <div className={`${(!isFeetypeFilter) ? 'dk-reSummThreHeader dk-reSHeaderCont78' : 'dk-reSummThreHeader dk-reSHeaderCont78 dk-dbsuMM-filterHeader'}`}>
                                    {
                                        (!isFeetypeFilter) ?
                                            <>
                                                <div className="dk-reSHeaderTitle">Fee Type</div>
                                            </>
                                            :
                                            <>
                                                <div className=''>
                                                    {feetypeFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="feeType" onClick={removeTag}></i></span>
                                                </div>
                                            </>
                                    }
                                    <span >
                                        {
                                            feetypeList.reduce(function (tot, arr) {
                                                return tot + arr.count;
                                            }, 0)
                                        }
                                    </span>
                                </div>
                                {
                                    (feetypeList.filter(function (val) {
                                        return val.feeType !== "";
                                    }).length != 0) ? <div className="dk-reSummTwoContent dkg-clieninfo-bottom-ullist" style={{ backgroundColor: "#eaeaea" }}>
                                        {feetypeList.map((item, index) => (
                                            <li key={`feeType${index}`} data-type="feeType" data-value={item.feeType} onClick={selectFilter}>
                                                {(item.feeType !== '') ? item.feeType : 'Blank'}
                                                <span data-type="feeType" data-value={item.feeType} onClick={selectFilter}>
                                                    {
                                                        item.count
                                                    }
                                                </span>
                                            </li>
                                        ))}
                                    </div>

                                        : null

                                }

                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-8 col-md-8 col-sm-12 pl-0">
                        <div className="dk-reSummThreBox d-flex">
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Summary;
