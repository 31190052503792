import React from "react";
import { Route, Link } from "react-router-dom";

const SettingsSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/recruitment/settings/summary" className={`${(segment3 == 'summary') ? 'active' : ''}`}>Overview</Link></li>
                        {/* <li><Link to="#" className={`${(segment3 == 'dkg-faqs') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="#" className={(segment3 == 'daily-tracking') ? 'active' : ''}>Workplan</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'sales-kpi') ? 'active' : ''}`}>Notification</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'clientskpi') ? 'active' : ''}`}>Candidate</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'clientskpi') ? 'active' : ''}`}>Jobs</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'clientskpi') ? 'active' : ''}`}>Company</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'clientskpi') ? 'active' : ''}`}>KPI</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'clientskpi') ? 'active' : ''}`}>DKG Cloud</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'clientskpi') ? 'active' : ''}`}>Database</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'clientskpi') ? 'active' : ''}`}>DK Global</Link></li> */}
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default SettingsSubmenu;
