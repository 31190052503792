import React from "react";
import IncomeSubmenu from './menu/accountingmodule/IncomeSubmenu';
// import ExpenseSubmenu from './menu/accountingmodule/ExpenseSubmenu';
// import PLSubmenu from './menu/accountingmodule/PLSubmenu';

const ExpIncomeSubmenu = ({ path }) => {
    const renderSubmenu = () => {
        switch (path.split("/")[3]) {

            case 'income':
                return <IncomeSubmenu />
                break;

            // case 'expense':
            //     return <ExpenseSubmenu />
            //     break;

            // case 'profit-loss':
            //     return <PLSubmenu />
            //     break;
            default:
                break
        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default ExpIncomeSubmenu;