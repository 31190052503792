import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import "./editor.scss";
import { useSelector, useDispatch } from "react-redux";
import SubmitButton from "../../../../ui/submitButton";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../slice/utils/message/messageSlice";
import { updateHowToApplyDetails, clearState } from "../../../../../slice/jobSpec/jobsSlice";

const copyStringToClipboard = function (str) {
  var el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const facilityMergeFields = [
  "FacilityNumber",
  "FacilityName",
  "Address",
  "MapCategory",
  "Latitude",
  "Longitude",
  "ReceivingPlant",
  "TrunkLine",
  "SiteElevation"
];
const inspectionMergeFields = [
  "InspectionCompleteDate",
  "InspectionEventType"
];
const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
  let optionGroupElement = document.createElement("optgroup");
  optionGroupElement.setAttribute("label", optionGrouplabel);
  for (let index = 0; index < mergeFields.length; index++) {
    let optionElement = document.createElement("option");
    optionElement.setAttribute("class", "merge-field-select-option");
    optionElement.setAttribute("value", mergeFields[index]);
    optionElement.text = mergeFields[index];
    optionGroupElement.appendChild(optionElement);
  }
  return optionGroupElement;
}
const buttons = [
  "undo",
  "redo",
  "|",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "superscript",
  "subscript",
  "|",
  "align",
  "|",
  "ul",
  "ol",
  "outdent",
  "indent",
  "|",
  "font",
  "fontsize",
  "brush",
  "paragraph",
  "|",
  "image",
  "link",
  "table",
  "|",
  "source",
  "|",
  {
    name: "insertMergeField",
    tooltip: "Insert Merge Field",
    iconURL: "images/merge.png",
    popup: (editor, current, self, close) => {
      function onSelected(e) {
        let mergeField = e.target.value;
        if (mergeField) {
          console.log(mergeField);
          editor.selection.insertNode(
            editor.create.inside.fromHTML("{{" + mergeField + "}}")
          );
        }
      }
      let divElement = editor.create.div("merge-field-popup");

      let labelElement = document.createElement("label");
      labelElement.setAttribute("class", "merge-field-label");
      labelElement.text = 'Merge field: ';
      divElement.appendChild(labelElement);

      let selectElement = document.createElement("select");
      selectElement.setAttribute("class", "merge-field-select");
      selectElement.appendChild(createOptionGroupElement(facilityMergeFields, "Facility"));
      selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, "Inspection"));
      selectElement.onchange = onSelected;
      divElement.appendChild(selectElement);

      console.log(divElement);
      return divElement;
    }
  },
  {
    name: "copyContent",
    tooltip: "Copy HTML to Clipboard",
    iconURL: "images/copy.png",
    exec: function (editor) {
      let html = editor.value;
      copyStringToClipboard(html);
    }
  }
];

const editorConfig = {
  readonly: false,
  toolbar: true,
  spellcheck: true,
  language: "en",
  toolbarButtonSize: "medium",
  toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: true,
  askBeforePasteFromWord: true,
  //defaultActionOnPaste: "insert_clear_html",
  buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true
  },
  width: 800,
  height: 842
};

const EditorComp = ({ value, name }) => {
  const dispatch = useDispatch();
  const { isUpdate, message, isSuccess } = useSelector((state) => state.jobSpec);
  const [data, setData] = useState(value);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const saveSignature = async (e) => {
    e.preventDefault();
    setSuccess(true);
    setLoading(true);
    if (!data || data == "") {
      dispatch(showError({ msg: 'Content is Required' }))
    }
    dispatch(
      updateHowToApplyDetails({ key: name, value: data })
    );
  }

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setSuccess(false);
      setLoading(false);
    }
  }, [isUpdate]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(showSuccess({ msg: message }));
      dispatch(clearState());
    }
  }, [isSuccess]);

  return (
    <div className="App dkg-job-editor-mainCon" style={{ maxWidth: editorConfig.width, margin: "0 auto" }}>
      <JoditEditor
        value={value}
        config={editorConfig}
        onChange={value => setData(value)}
      />
      <div className="dkg-mail-sendbtn-Con">
        <SubmitButton
          txt="Save" onClick={(e) => saveSignature(e)}
          loading={loading}
          success={success}
          className="dkg-create-payment-taskbtn"
        />
      </div>
    </div>
  );
}


export default EditorComp;