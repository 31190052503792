import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

const ReorderModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <button onClick={handleShow}><i className="fas fa-arrows-alt"></i> Reorder</button>

            <Modal className="dk-reorderModalMain" centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reorder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-reorderModal-body">
                        <div className="d-flex">
                            <div className="dk-sn">1</div>
                            <div className="dk-name">DK Global Jobs</div>
                            <div className="dk-icon"><i className="fas fa-arrows-alt"></i></div>
                        </div>
                        <div className="d-flex">
                            <div className="dk-sn">2</div>
                            <div className="dk-name">DK Masterbox</div>
                            <div className="dk-icon"><i className="fas fa-arrows-alt"></i></div>
                        </div>
                        <div className="d-flex">
                            <div className="dk-sn">3</div>
                            <div className="dk-name">DK Global Recruitment</div>
                            <div className="dk-icon"><i className="fas fa-arrows-alt"></i></div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReorderModal;
