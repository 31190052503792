import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown'
import { updateFrequency, clearState } from "../../../../../slice/qualify/qualifySlice";
import { showSuccess, showError } from "../../../../../slice/utils/message/messageSlice";

const FrequencyList = ({ id }) => {
    const dispatch = useDispatch();
    const { isUpdate, msg, isError, errorMessage, frequencyDataList } = useSelector(state => state.qualify)
    const [rowId, setRowId] = useState(id)

    const changeFrequency = async (e) => {
        await dispatch(updateFrequency({ id: rowId, value: e.target.dataset.key }))
    }

    useEffect(() => {
        if (isUpdate) {
            dispatch(showSuccess({ msg: msg }))
            dispatch(clearState())
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
        }
    }, [isUpdate, isError])
    return (
        <Dropdown.Menu className="dk-freqDrpMenu">
            {
                frequencyDataList.map((item, index) => (
                    <Dropdown.Item data-key={item._id} style={{ color: item.textColor, backgroundColor: item.bgColor }} onClick={changeFrequency}>{item.value}</Dropdown.Item>
                ))
            }

            {/* <Dropdown.Item data-key={"One Time Info"} onClick={changeFrequency}>One Time Info</Dropdown.Item>
            <Dropdown.Item data-key={"Every Time Info"} onClick={changeFrequency}>Every Time Info</Dropdown.Item> */}
        </Dropdown.Menu>
    )
}

export default FrequencyList;