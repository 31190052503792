import React from 'react';
// import  './dkg-myprofile.scss'
import CV from '../../../../../assets/images/cvimg.png'
import CVupload from '../../../../../assets/images/cvimg.png'
import { FaEye, FaUpload ,FaTimes   } from 'react-icons/fa';



const UploadCv = () => {
    
    return (
       <>
         <div className="dkg-myprofile-mainpageCon">
            <div className="dkg-myprofile-con">
               <div className="dkg-myprofile-mainRow row m-0">
                  <div className="dkg-myprofile-rightcon dkg-resume-rightcon col-md-8 m-auto">
                     <h2 className="dkg-profile-frm-title dkg-resume-frm-title">UPLOAD UPDATED RESUME</h2>
                     <div className="dkg-profile-frm-subtitle">
                        <p className="dkg-frm-subtitle-desc">lorem ipsum is simply dummy text of printing.</p>
                     </div>
                     <div className="dkg-resume-rightbody">
                        <div className="dkg-white-block">
                           <div className="dkg-myresume-cvimgCon">
                              <input type="file" accept="image/*" name="" />
                              <span className="dkg-myresume-sapnimgCon">
                                 <img src={CVupload} className='dkg-myresume-cvimg' alt="CV" />
                              </span>
                           </div>
                        </div>
                        <div className="dkg-resume-btngrp-Con">
                           <div className='dkg-resume-btn mr-2'>
                           <button className='btn dkg-resume-view-btn'>Upload<span className='dkg-resume-icon'><FaUpload /></span></button>
                           </div>
                           <div className='dkg-resume-btn mr-2'>
                              <button className='btn dkg-resume-view-btn'>Preview<span className='dkg-resume-icon'><FaEye /></span></button>
                           </div>
                           <div className='dkg-resume-btn'>
                           <button className='btn dkg-resume-view-btn'>Remove<span className='dkg-resume-icon'><FaTimes /></span></button>
                           </div>
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
       </>
    )
}

export default UploadCv