import React from "react";
import { Route, Link } from "react-router-dom";

const RecConsultantsRecDepSubmenu = () => {
    const pathname = window.location.pathname;
    const segment4 = pathname.split("/")[4];

    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/trainingmodule/recruitment-consultants/rec-dep/sample" className={`${(segment4 == 'sample') ? 'active' : ''}`}>Sample 1</Link></li>
                        <li><Link to="/trainingmodule/recruitment-consultants/rec-dep/sample-2" className={`${(segment4 == 'sample-2') ? 'active' : ''}`}>Sample 2</Link></li>
                        <li><Link to="/trainingmodule/recruitment-consultants/rec-dep/sample-3" className={`${(segment4 == 'sample-3') ? 'active' : ''}`}>Sample 3</Link></li>
                        <li><Link to="/trainingmodule/recruitment-consultants/rec-dep/sample-4" className={`${(segment4 == 'sample-4') ? 'active' : ''}`}>Sample 4</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default RecConsultantsRecDepSubmenu;
