import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const TrainingMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment3 = pathname.split("/")[3];
    const segment4 = pathname.split("/")[4];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalAdminTrainingMainMenu">
                <Route>
                    <ul>
                        {/* <li><Link to="/dkglobaladmin/admintraining/dashboard" className={(segment3 == 'dashboard') ? 'active' : ''}>Dashboard</Link></li> */}
                        {/* <li><Link to="/dkglobaladmin/admintraining/schedule/overview" className={`${(segment3 == 'schedule') ? 'active' : ''}`}>Shedule</Link></li> */}
                        {/* <li><Link to="/dkglobaladmin/admintraining/onboarding/welcome" className={`${(segment3 == 'onboarding') ? 'active' : ''}`}>Onboarding</Link></li> */}
                        {/* <li><Link to="/dkglobaladmin/admintraining/commontraining/common-training" className={`${(segment3 == 'commontraining') ? 'active' : ''}`}>Common</Link></li> */}
                        {/* <li><Link to="/dkglobaladmin/admintraining/training-resource" className={`${(segment3 == 'training-resource') ? 'active' : ''}`}>Teams 1</Link></li>
                        <li><Link to="/dkglobaladmin/admintraining/admins" className={`${(segment3 == 'admins') ? 'active' : ''}`}>Teams 2</Link></li>
                        <li><Link to="/dkglobaladmin/admintraining/recruiters" className={`${(segment3 == 'recruiters') ? 'active' : ''}`}>Teams 3</Link></li>
                        <li><Link to="/dkglobaladmin/admintraining/managers" className={`${(segment3 == 'managers') ? 'active' : ''}`}>Teams 4</Link></li>
                        <li><Link to="/dkglobaladmin/admintraining/account-manager" className={`${(segment3 == 'account-manager') ? 'active' : ''}`}>Teams 5</Link></li> */}
                        {/* <li><Link to="/dkglobaladmin/admintraining/management" className={`${(segment3 == 'management') ? 'active' : ''}`}>Management</Link></li> */}
                        <li><Link to="/dkglobaladmin/admintraining/assessments/overview" className={`${(segment3 == 'assessments') ? 'active' : ''}`}>Assesment</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default TrainingMenu;