import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ScreenShotImg from "../../../../assets/images/screenshot.jpg";

const ViewFeedback = ({ handleClose }) => {
    return (
        <>
            <Modal className="dk-viewFeedbackModal" centered show={true} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Point Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-feedbackScreenshot">
                        <img src={ScreenShotImg} alt="" />
                    </div>
                    <div className="dk-feedbackLine">
                        <li>
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis excepturi, itaque ducimus eaque corrupti quia natus cumque.
                        </li>
                        <li>
                            Lorem ipsum dolor, sit amet consectetur adipisicing itaque ducimus eaque corrupti elit. Omnis excepturi, itaque ducimus eaque corrupti quia natus cumque.
                        </li>
                        <li>
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis excepturi, itaque ducimus eaque Omnis excepturi, itaque ducimus eaque corrupti quia natus cumque.
                        </li>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ViewFeedback;
