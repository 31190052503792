import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import '../style.scss';

const DesignerTasksMainMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK Global CRM";
    }, [pathname]);

    // const { allBadge } = useSelector(state => state.tasks);
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-recruitmentNavbar dk-itTeamMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/itteams/designertasks/designer-tasks" className={(segment3 == 'designer-tasks') ? 'active' : ''}>Dashboard</Link></li>
                        <li><Link to="#" className={(segment3 == 'training') ? 'active' : ''}>Sample 1</Link></li>
                        <li><Link to="#" className={(segment3 == '') ? '' : ''}>Sample 2</Link></li>
                        <li><Link to="#" className={(segment3 == '') ? '' : ''}>Sample 3</Link></li>
                        <li><Link to="#" className={(segment3 == '') ? '' : ''}>Sample 4</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default DesignerTasksMainMenu;