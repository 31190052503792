import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "./addnew-payment.scss";
import SuperAdminUserList from "../../../../ui/userList/role/index";
//import UserList from "../../../../ui/userList/";
import Dropdown from "react-bootstrap/Dropdown";
import PriorityDropdown from "./PriorityDropdown";
import DatePicker from "./DatePicker";
//import TimePicker from './TimePicker'
//import Recruiter from '../../../../../assets/images/deepak.png';
import SubmitButton from "../../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import {
  // showSuccess,
  showError,
  //clearMessage,
} from "../../../../../slice/utils/message/messageSlice";
import moment from "moment-timezone";
import "date-fns";
import {
  clearState,
  taskTypeFetch,
  addNewTask,
  taskPayMonthFetch,
  taskPayTypeFetch,
} from "../../../../../slice/task/paymentTaskSlice";
import { SYSTEM_CONSTANTS } from "../../../../../constants";
//import { FaUpload } from "react-icons/fa";
import AWS from "aws-sdk";

const AddNewPayment = ({ isDelete }) => {
  const dispatch = useDispatch();
  const {
    // isLoading,
    // paymentTaskList,
    // isMoved,
    // isUpdate,
    // message,
    // isTaskUpdate,
    // isTaskDelete,
    isTaskInserted,
    taskTypeList,
    //iscommentCountUpdate,
    taskPaymentMonthList,
    taskPayTypeList,
  } = useSelector((state) => state.paymentTask);

  const { userInfo } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [taskByImg, setTaskByImg] = useState("");
  const [taskById, setTaskByUserId] = useState("0");

  const [userImg, setUserImg] = useState("");
  const [userId, setUserId] = useState("0");

  //const [taskBy, setTaskBy] = useState(userInfo.id);
  const [taskType, setPaymentType] = useState("Select");
  const [paymentMonth, setPaymentMonth] = useState("Select");
  const [payType, setPayType] = useState("Select");
  const [taskPriority, setTaskPriority] = useState("Select");
  const [paymentTo, setPaymentTo] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [attachment, setAttachment] = useState("");
  const [file, setFile] = useState("");
  const [fileType, setFileType] = useState("");
  //const [notes, setNotes] = useState("");
  //const [pickerDueTime, setPickerDueTime] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("DD MMM YYYY")
  );
  const [dueTime, setDueTime] = useState(moment(new Date()).format("hh:m A"));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const selectTaskByUser = (e) => {
    setTaskByImg(e.target.src);
    setTaskByUserId(e.target.dataset.id);
  };

  // const selectUser = (e) => {
  //   setUserImg(e.target.src);
  //   setUserId(e.target.dataset.id);
  // };

  // const handleTimeChange = (date) => {
  //   setPickerDueTime(date);
  //   setDueTime(moment(date).format("hh:m A"));
  // };

  const changeTaskPriority = (data) => {
    setTaskPriority(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!taskType || !totalAmount) {
        dispatch(showError({ msg: "All fileds required" }));
      } else {
        setLoading(true);
        if (attachment != "") {
          try {
            let s3bucket = new AWS.S3({
              accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
              secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
              Bucket: process.env.REACT_APP_BUCKET_NAME,
            });

            let fileNewName = Date.now() + "-" + attachment;

            s3bucket.createBucket(function () {
              var params = {
                Bucket: process.env.REACT_APP_BUCKET_NAME,
                Key: fileNewName,
                Body: file,
                ContentType: fileType,
              };

              s3bucket.upload(params, function (err, data) {
                if (err) {
                  console.log(err);
                } else {
                  dispatch(
                    addNewTask({
                      taskBy: taskById,
                      taskFor: 1,
                      status: "New Task",
                      priority: taskPriority,
                      invoiceNo,
                      paymentType: taskType,
                      paymentTo,
                      paymentAmount: totalAmount,
                      paymentNotes: "",
                      dueTime,
                      dueDate: selectedDate,
                      paymentMonth,
                      payType,
                      attachment: data.key,
                      isDeleted: isDelete,
                    })
                  );
                }
              });
            });
          } catch (err) {
            console.log(err);
          }
        } else {
          dispatch(
            addNewTask({
              taskBy: taskById,
              taskFor: 1,
              status: "New Task",
              priority: taskPriority,
              invoiceNo,
              paymentType: taskType,
              paymentTo,
              paymentAmount: totalAmount,
              paymentNotes: "",
              dueTime,
              dueDate: selectedDate,
              paymentMonth,
              payType,
              attachment,
              isDeleted: isDelete,
            })
          );
        }
      }
    } catch (err) {
      setSuccess(false);
      setLoading(false);
      dispatch(showError({ msg: "Something went wrong! Try again." }));
    }
  };

  useEffect(() => {
    const fetchList = async () => {
      try {
        await dispatch(taskTypeFetch({ id: SYSTEM_CONSTANTS.PAYMENT_TYPE.ID }));
        await dispatch(
          taskPayMonthFetch({ id: SYSTEM_CONSTANTS.TASK_PAYMENT_MONTH.ID })
        );
        await dispatch(
          taskPayTypeFetch({ id: SYSTEM_CONSTANTS.TASK_PAY_TYPE.ID })
        );
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
    if (isTaskInserted) {
      setUserId("0");
      setTaskByUserId("0");
      dispatch(clearState());
      setPaymentType("Select");
      setTaskPriority("Select");
      setPayType("Select");
      setPaymentMonth("Select");
      setInvoiceNo("");
      setPaymentTo("");
      setTotalAmount("");
      //setNotes("");
      setSuccess(false);
      setLoading(false);
      // dispatch(getPaymentList({}))
    }
  }, [isTaskInserted]);

  const handleDateChange = (date) => {
    setSelectedDate(moment(date).format("DD MMM YYYY"));
  };
  // const [taskStatusFilterText, setTaskStatusFilterText] = useState('Type')
  // const selectStatusFilter = async (e) => {
  //     const type = e.target.dataset.type;
  //     const value = e.target.dataset.value;
  //     console.log(type)
  //     return false
  // }

  const onSelectFileByAPI = async (e) => {
    setAttachment(e.target.files[0].name);
    setFileType(e.target.files[0].type);
    setFile(e.target.files[0]);
  };

  return (
    <>
      <Link to="#" onClick={handleShow}>
        <i className="fa fa-plus" aria-hidden="true"></i>
      </Link>
      <Modal
        show={show}
        onHide={handleClose}
        className="dkg-addnew-paymenttask-modal-21"
      >
        <Modal.Header closeButton className="dkg-addnew-payment-modalheader-21">
          <Modal.Title>Create New Payment Task</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-addnew-payment-modalbody-21">
          <div className="row">
            <div className="col-md-6 pr-2">
              <div className="form-group d-flex align-items-center">
                <label style={{ minWidth: "135px" }}>Task By</label>
                <label htmlFor="" className="mb-0">
                  <input type="hidden" id="taskById" value="0" />
                  <Dropdown className="dk-globalUserDropdown dkg-payment-taskbydropDown">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {taskById !== "0" ? (
                        <img src={taskByImg} className="select-user-img" />
                      ) : (
                        <i className="fas fa-user"></i>
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dk-adstracker-DrpMenu12 dkg-payment-taskbydropDownMenu">
                      <div className="d-flex">
                        <SuperAdminUserList onClick={selectTaskByUser} />
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </label>
              </div>
            </div>
            {/* <div className='col-md-6 pl-2'>
                            <div className='form-group'>
                                <label style={{ minWidth: "135px" }}>Task For</label>

                                <label htmlFor="" className="mb-0">
                                    <input type="hidden" id="userId" value="0" />
                                    <Dropdown className="dk-globalUserDropdown dkg-payment-taskfordropDown">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {
                                                (userId !== '0') ? <img src={userImg} className="select-user-img" /> : <i className="fas fa-user"></i>
                                            }
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='dk-adstracker-DrpMenu12 dkg-payment-taskfordropDownMenu'>
                                            <div className="d-flex">
                                                <UserList onClick={selectUser} />
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </label>

                            </div>
                        </div> */}
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label className="">Task Due On</label>
                <div className="dkg-addnew-date-timeCon">
                  <div className="w-100">
                    <DatePicker
                      selectedDate={selectedDate}
                      handleDateChange={handleDateChange}
                    />
                  </div>
                  {/* <div className='w-100'>
                                        <TimePicker pickerDueTime={pickerDueTime} handleTimeChange={handleTimeChange} />
                                    </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label className="">Status</label>
                {/* <div className='dkg-payment-task-drop12'>
                                    <StatusDropdown />
                                </div> */}
                <input
                  type="text"
                  className="form-control"
                  style={{ backgroundColor: "#d58b5d", color: "#fff" }}
                  defaultValue={"New Task"}
                />
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label className="">Priority</label>
                <div className="dkg-payment-task-drop12">
                  <PriorityDropdown
                    taskPriority={taskPriority}
                    changeTaskPriority={changeTaskPriority}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label className="">Category</label>
                <div className="dkg-payment-task-drop12">
                  <Dropdown>
                    <Dropdown.Toggle variant="" className="form-control">
                      {taskType}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {taskTypeList.map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          className="form-control"
                          onClick={() => setPaymentType(item.value)}
                        >
                          {item.value}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label className="">Pay Type</label>
                <div className="dkg-payment-task-drop12">
                  <Dropdown>
                    <Dropdown.Toggle variant="" className="form-control">
                      {payType}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {taskPayTypeList.map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          className="form-control"
                          onClick={() => setPayType(item.value)}
                        >
                          {item.value}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-12 pl-0 pr-0">
            <div className="form-group">
              <label className="">Payment To</label>
              <input
                type="text"
                className="form-control"
                value={paymentTo}
                onChange={(e) => setPaymentTo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-12 col-12 pl-0 pr-0">
            <div className="form-group">
              <label className="">INV Month</label>
              <div className="dkg-payment-task-drop12">
                <Dropdown>
                  <Dropdown.Toggle variant="" className="form-control">
                    {paymentMonth}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {taskPaymentMonthList.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        className="form-control"
                        onClick={() => setPaymentMonth(item.value)}
                      >
                        {item.value}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-12 pl-0 pr-0">
            <div className="form-group">
              <label className="">INV Number</label>
              <input
                type="text"
                className="form-control"
                value={invoiceNo}
                onChange={(e) => setInvoiceNo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-12 col-12 pl-0 pr-0">
            <div className="form-group">
              <label className="">Pay Amount</label>
              <div className="d-flex w-100">
                <input
                  type="text"
                  className="form-control"
                  value={totalAmount}
                  onChange={(e) => setTotalAmount(e.target.value)}
                />
                <span className="dkg-euro-btnCon">
                  <button className="dkg-euro-btn">€</button>
                </span>
              </div>
            </div>
          </div>
          {/* <div className='col-md-12 pl-0 pr-0'>
                        <div className='form-group' style={{ alignItems: "inherit" }}>
                            <label className=''>Payment Notes</label>
                            <textarea rows="4" className="form-control w-100 taskTitle" placeholder="" value={notes} onChange={(e) => setNotes(e.target.value)}></textarea>
                        </div>
                    </div> */}
          <div className="col-md-12 col-12 pl-0 pr-0">
            <div className="form-group">
              <label className="">Attachment File</label>
              {/* <div className="dkg-file-inputCon-24">
                                <input type="file" name="file-input" id="file-input" className="dkg-file-input__input" />
                                <label className="dkg-file-input-label" for="file-input">
                                    <FaUpload />
                                    <span className='dkg-uload-title'>Upload file</span></label>
                            </div> */}
              <input
                type="file"
                id="imageInputAPI"
                onChange={onSelectFileByAPI}
                className="form-control w-100 dkg-payment-attach-input"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="dkg-create-payment-taskbtncon">
              {/* <Link to="#" className='dkg-create-payment-taskbtn'> Create</Link> */}
              <SubmitButton
                txt="CREATE TASK"
                loading={loading}
                success={success}
                onClick={handleSubmit}
                className="dkg-create-payment-taskbtn"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewPayment;
