import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import { FaUserAlt, FaSpinner, FaStar, FaBriefcase, FaBuilding, FaBook, FaLanguage, FaUserCheck, FaRegCalendarAlt, FaRegFileAlt } from 'react-icons/fa';
//import CustomDateFormat from "../../../ui/dateFormats/CustomDateFormat";
//import moment from "moment-timezone";

const AdvancedFilter = ({ onHide, headerTitle, list, selectUserFilter, selectOtherFilter, tableName }) => {

    const [teamList, setTeamList] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [monthsLists, setMonthsList] = useState([]);
    const [modelList, setmodelList] = useState([]);
    const [rcUserList, setRcUserList] = useState([]);
    const [departmentsList, setdepartmentsList] = useState([]);
    const [jobPositionList, setJobPositionList] = useState([]);
    const [ratingList, setRatingList] = useState([]);


    useEffect(() => {

        const teamsResult = [];
        const teamsmap = new Map();
        for (const item of list) {
            if (!teamsmap.has(item.original.teams)) {
                teamsmap.set(item.original.teams, true);
                teamsResult.push({
                    name: (item.original.teams !== '') ? item.original.teams : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.teams === item.original.teams;
                    }).length
                });
            }
        }
        teamsResult.sort(function (a, b) {
            return b.count - a.count;
        });
        const strAscending = [...teamsResult].sort((a, b) =>
            a.name > b.name ? 1 : -1,
        );
        setTeamList(strAscending)


        const YearsResult = [];
        const Yearssmap = new Map();
        for (const item of list) {
            if (!Yearssmap.has(item.original.year)) {
                Yearssmap.set(item.original.year, true);
                YearsResult.push({
                    name: (item.original.year !== '') ? item.original.year : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.year === item.original.year;
                    }).length
                });
            }
        }
        YearsResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setYearList(YearsResult)

       // console.log(list)
        const RatingResult = [];
        const Ratingsmap = new Map();
        for (const item of list) {
            if (!Ratingsmap.has(item.original.rating)) {
                Ratingsmap.set(item.original.rating, true);
                RatingResult.push({
                    name: (item.original.rating !== '') ? item.original.rating : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.rating === item.original.rating;
                    }).length
                });
            }
        }
        RatingResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setRatingList(RatingResult)

        const MonthsResult = [];
        const Monthssmap = new Map();
        for (const item of list) {
            if (!Monthssmap.has(item.original.period)) {
                Monthssmap.set(item.original.period, true);
                MonthsResult.push({
                    name: (item.original.period !== '') ? item.original.period : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.period === item.original.period;
                    }).length
                });
            }
        }
        MonthsResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setMonthsList(MonthsResult)


        const modelResult = [];
        const modelsmap = new Map();
        for (const item of list) {
            if (!modelsmap.has(item.original.model)) {
                modelsmap.set(item.original.model, true);
                modelResult.push({
                    name: (item.original.model !== '') ? item.original.model : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.model === item.original.model;
                    }).length
                });
            }
        }
        modelResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setmodelList(modelResult)


        const jobPositionResult = [];
        const jobPositionsmap = new Map();
        for (const item of list) {
            if (!jobPositionsmap.has(item.original.jobPosition)) {
                jobPositionsmap.set(item.original.jobPosition, true);
                jobPositionResult.push({
                    name: (item.original.jobPosition !== '') ? item.original.jobPosition : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.jobPosition === item.original.jobPosition;
                    }).length
                });
            }
        }
        jobPositionResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setJobPositionList(jobPositionResult)


        const DepartmentsResult = [];
        const Departmentssmap = new Map();
        for (const item of list) {
            if (!Departmentssmap.has(item.original.departments)) {
                Departmentssmap.set(item.original.departments, true);
                DepartmentsResult.push({
                    name: (item.original.departments !== '') ? item.original.departments : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.departments === item.original.departments;
                    }).length
                });
            }
        }
        DepartmentsResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setdepartmentsList(DepartmentsResult)


        const rcResult = [];
        const rcmap = new Map();
        for (const item of list) {
            if (!rcmap.has(item.original.recInfo.id)) {
                rcmap.set(item.original.recInfo.id, true);
                rcResult.push({
                    id: item.original.recInfo.uid,
                    name: item.original.recInfo.firstName,
                    userImg: item.original.recInfo.profileImage,
                    count: list.filter(function (val) {
                        return val.original.recInfo.uid === item.original.recInfo.uid;
                    }).length
                });
            }
        }
        rcResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setRcUserList(rcResult)


    }, [list])


    return (
        <>
            <Modal className="dk-sendoutsSearch-modal right" show={true} onHide={onHide}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>{props.headerTitle}</Modal.Title> */}
                    <Modal.Title>{headerTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-sendoutFilterTabs-main">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_2">
                            <div className="dk-sendoutTabs-nav">
                                <Nav variant="">

                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_2"><FaRegCalendarAlt className="mr-2" /> Year</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="month"><FaRegCalendarAlt className="mr-2" />TIME FRAME</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_4"><FaUserAlt className="mr-2" />Employee</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="tabs_5"><FaSpinner className="mr-2"/> Team</Nav.Link>
                                    </Nav.Item> */}
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_7"><FaBuilding className="mr-2" /> Department</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_8"><FaBriefcase className="mr-2" />JOB TITLE</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_6"><FaUserAlt className="mr-2" /> Model </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="performance"><FaStar className="mr-2" />PERFORMANCE</Nav.Link>
                                    </Nav.Item>

                                </Nav>
                            </div>
                            <div className="dk-sendoutTabs-filterPanel">
                                <Tab.Content>
                                    <Tab.Pane eventKey="tabs_2">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">

                                                <ul>
                                                    {yearList.map((item, index) => (
                                                        <li key={`year${index}`} data-type="year" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="year" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="year" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="month">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'Quarter 1' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'Quarter 2' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'Quarter 3' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'Quarter 4' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li> 
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'Quarter 5' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li> 
                                                 
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="tabs_5">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">

                                                <ul>
                                                    {teamList.map((item, index) => (
                                                        <li key={`teams${index}`} data-type="teams" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="teams" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="teams" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="tabs_6">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">

                                                <ul>
                                                    {modelList.map((item, index) => (
                                                        <li key={`model${index}`} data-type="model" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="model" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="model" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="tabs_4">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-Recuriter">
                                                <ul>
                                                    {rcUserList.map((item, index) => (
                                                        <li key={`rcFilter${index}`}>
                                                            <Link to="#" data-type="rc" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} alt={item.name} className="recImg" title={item.name} data-type="rc" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                                                <span className="recuriterNo" data-type="rc" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                    {item.count}
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="tabs_7">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">

                                                <ul>
                                                    {departmentsList.map((item, index) => (
                                                        <li key={`departments${index}`} data-type="departments" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="departments" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="departments" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_8">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">

                                                <ul>
                                                    {jobPositionList.map((item, index) => (
                                                        <li key={`jobPosition${index}`} data-type="jobPosition" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="jobPosition" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="jobPosition" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="performance">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">

                                                <ul>
                                                    <li >
                                                    {ratingList.map((item, index) => (
                                                            item.name == 0.5 ?
                                                                <li key={`rating${index}`} data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" className="d-flex align-items-center justify-content-space-between" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                        <div className='d-flex dkg-startsCon'>
                                                                            <i className="fa fa-star-half-alt"></i>
                                                                        </div>
                                                                        <span className="text-orange" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}                                                        
                                                    </li> 
                                                    <li >
                                                      
                                                        {ratingList.map((item, index) => (
                                                            item.name == 1 ?
                                                                <li key={`rating${index}`} data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" className="d-flex align-items-center justify-content-space-between" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                        <div className='d-flex dkg-startsCon'>
                                                                            <i className="fa fa-star"></i>
                                                                        </div>
                                                                        <span className="text-orange" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li >
                                                    {ratingList.map((item, index) => (
                                                            item.name == 1.5 ?
                                                                <li key={`rating${index}`} data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" className="d-flex align-items-center justify-content-space-between" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                        <div className='d-flex dkg-startsCon'>
                                                                           <i className="fa fa-star"></i>
                                                                             <i className="fa fa-star-half-alt"></i>
                                                                        </div>
                                                                        <span className="text-orange" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                        {/* <Link to="#" className="d-flex align-items-center justify-content-space-between">
                                                            <div className='d-flex dkg-startsCon'>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star-half-alt"></i>
                                                            </div>
                                                            <span className="text-orange">15</span>
                                                        </Link> */}
                                                    </li>
                                                    <li >
                                                        {/* <Link to="#" className="d-flex align-items-center justify-content-space-between">
                                                            <div className='d-flex dkg-startsCon'>
                                                                <i className="fa fa-star "></i>
                                                                <i className="fa fa-star"></i>
                                                            </div>
                                                            <span className="text-orange">4</span>
                                                        </Link> */}
                                                        {ratingList.map((item, index) => (
                                                            item.name == 2 ?
                                                                <li key={`rating${index}`} data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" className="d-flex align-items-center justify-content-space-between" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                        <div className='d-flex dkg-startsCon'>
                                                                        <i className="fa fa-star"></i>
                                                                         <i className="fa fa-star"></i>
                                                                        </div>
                                                                        <span className="text-orange" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li >
                                                        {/* <Link to="#" className="d-flex align-items-center justify-content-space-between">
                                                            <div className='d-flex dkg-startsCon'>
                                                                <i className="fa fa-star "></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star-half-alt"></i>
                                                            </div>
                                                            <span className="text-orange">6</span>
                                                        </Link> */}
                                                          {ratingList.map((item, index) => (
                                                            item.name == 2.5 ?
                                                                <li key={`rating${index}`} data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" className="d-flex align-items-center justify-content-space-between" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                        <div className='d-flex dkg-startsCon'>
                                                                        <i className="fa fa-star "></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star-half-alt"></i>
                                                                        </div>
                                                                        <span className="text-orange" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li >
                                                        {/* <Link to="#" className="d-flex align-items-center justify-content-space-between">
                                                            <div className='d-flex dkg-startsCon'>
                                                                <i className="fa fa-star "></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                            </div>
                                                            <span className="text-orange">13</span>
                                                        </Link> */}
                                                         {ratingList.map((item, index) => (
                                                            item.name == 3 ?
                                                                <li key={`rating${index}`} data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" className="d-flex align-items-center justify-content-space-between" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                        <div className='d-flex dkg-startsCon'>
                                                                        <i className="fa fa-star "></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        </div>
                                                                        <span className="text-orange" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li >
                                                        {/* <Link to="#" className="d-flex align-items-center justify-content-space-between">
                                                            <div className='d-flex dkg-startsCon'>
                                                                <i className="fa fa-star "></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star-half-alt"></i>
                                                            </div>
                                                            <span className="text-orange">5</span>
                                                        </Link> */}
                                                         {ratingList.map((item, index) => (
                                                            item.name == 3.5 ?
                                                                <li key={`rating${index}`} data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" className="d-flex align-items-center justify-content-space-between" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                        <div className='d-flex dkg-startsCon'>
                                                                        <i className="fa fa-star "></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star-half-alt"></i>
                                                                        </div>
                                                                        <span className="text-orange" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li >
                                                        {/* <Link to="#" className="d-flex align-items-center justify-content-space-between">
                                                            <div className='d-flex dkg-startsCon'>
                                                                <i className="fa fa-star "></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                            </div>
                                                            <span className="text-orange">8</span>
                                                        </Link> */}
                                                          {ratingList.map((item, index) => (
                                                            item.name == 4 ?
                                                                <li key={`rating${index}`} data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" className="d-flex align-items-center justify-content-space-between" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                        <div className='d-flex dkg-startsCon'>
                                                                        <i className="fa fa-star "></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        </div>
                                                                        <span className="text-orange" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li >
                                                        {/* <Link to="#" className="d-flex align-items-center justify-content-space-between">
                                                            <div className='d-flex dkg-startsCon'>
                                                                <i className="fa fa-star "></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star-half-alt"></i>
                                                            </div>
                                                            <span className="text-orange">14</span>
                                                        </Link> */}
                                                         {ratingList.map((item, index) => (
                                                            item.name == 4.5 ?
                                                                <li key={`rating${index}`} data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" className="d-flex align-items-center justify-content-space-between" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                        <div className='d-flex dkg-startsCon'>
                                                                        <i className="fa fa-star "></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star-half-alt"></i>
                                                                        </div>
                                                                        <span className="text-orange" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li >
                                                        {/* <Link to="#" className="d-flex align-items-center justify-content-space-between">
                                                            <div className='d-flex dkg-startsCon'>
                                                                <i className="fa fa-star "></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                            </div>
                                                            <span className="text-orange">10</span>
                                                        </Link> */}
                                                         {ratingList.map((item, index) => (
                                                            item.name == 5 ?
                                                                <li key={`rating${index}`} data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" className="d-flex align-items-center justify-content-space-between" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                        <div className='d-flex dkg-startsCon'>
                                                                        <i className="fa fa-star "></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        </div>
                                                                        <span className="text-orange" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                   
                                                    <li >
                                                        {/* <Link to="#" className="d-flex align-items-center justify-content-space-between">
                                                            <div className='d-flex dkg-startsCon'>
                                                                <i className="fa fa-star-half-alt"></i>Blank
                                                            </div>
                                                            <span className="text-orange">2</span>
                                                        </Link> */}
                                                         {ratingList.map((item, index) => (
                                                            item.name == 0 ?
                                                                <li key={`rating${index}`} data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" className="d-flex align-items-center justify-content-space-between" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>
                                                                        <div className='d-flex dkg-startsCon'>
                                                                        <i className="fa fa-star-half-alt"></i>
                                                                        <i className="fa fa-star-half-alt"></i>
                                                                        <i className="fa fa-star-half-alt"></i>
                                                                        <i className="fa fa-star-half-alt"></i>
                                                                        <i className="fa fa-star-half-alt"></i>
                                                                        </div>
                                                                        <span className="text-orange" data-type="rating" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default AdvancedFilter;
