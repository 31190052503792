import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import './reordervalues.scss'

const ReorderValues = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Link to="#" onClick={handleShow} style={{ color: "#333" }}><i className="fas fa-bars"></i></Link>
            <Modal show={show} onHide={handleClose} className='dk-reorderCategoryMain' centered>
                <Modal.Header closeButton className='dk-reorderCategoryHeader'>
                    <Modal.Title>Reorder Values</Modal.Title>
                </Modal.Header>
                <Modal.Body className='dk-reorderCategoryBody'>
                    <div className="dk-toolsReorderPanel">
                        <ul>
                            <div className="Mukul">
                                <div className="dk-valueFields d-flex" id="1">
                                    <span className="dk-handle"><i className="fas fa-bars"></i></span>
                                    <input type="text" name="" id="" className="form-control" value="Task" />
                                </div>
                            </div>
                            <div className="Mukul">
                                <div className="dk-valueFields d-flex" id="6">
                                    <span className="dk-handle"><i className="fas fa-bars"></i></span>
                                    <input type="text" name="" id="" className="form-control" value="Candidates" />
                                </div>
                            </div>
                            <div className="Mukul">
                                <div className="dk-valueFields d-flex" id="27">
                                    <span className="dk-handle" ><i className="fas fa-bars"></i></span>
                                    <input type="text" name="" id="" className="form-control" value="Resources" />
                                </div>
                            </div>
                            <div className="Mukul">
                                <div className="dk-valueFields d-flex" id="50">
                                    <span className="dk-handle"><i className="fas fa-bars"></i></span>
                                    <input type="text" name="" id="" className="form-control" value="ADS" />
                                </div>
                            </div>
                            <div className="Mukul">
                                <div className="dk-valueFields d-flex" id="58">
                                    <span className="dk-handle" ><i className="fas fa-bars"></i></span>
                                    <input type="text" name="" id="" className="form-control" value="Jobs" />
                                </div>
                            </div>
                        </ul>
                        <div className="d-flex justify-content-center">
                            <button className=" dk-toolsPanelSaveBtn ">
                                Save Order
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReorderValues