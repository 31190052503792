import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { FaReplyAll } from "react-icons/fa";

import { profileMotivationStatus } from "../../../../../../helper/commonFunctions";

const index = ({ list, id, img, selectUserFilter }) => {
    return (
        <>
            <Dropdown className='dkg-candfilter-dropdown'>
                <Dropdown.Toggle variant="success" className='dkg-candfilter-dropdown-toggle'>
                    {
                        (id === '-1') ? <FaReplyAll /> : <FaReplyAll style={{ color: img }} />
                    }
                </Dropdown.Toggle>
                <Dropdown.Menu className='dkg-candfilter-dropdown-menu'>
                    <Dropdown.Item href="#/action-1" className='dkg-candfilter-dropdown-item' data-type="PMID" data-id="1" data-img="green" onClick={selectUserFilter}>
                        <FaReplyAll style={{ color: "green" }} data-type="PMID" data-id="1" data-img="green" onClick={selectUserFilter} />
                        <span className="proflieCount" data-type="PMID" data-id="1" data-img="green" onClick={selectUserFilter}>High Response</span>
                        <span className="proflieCount" data-type="PMID" data-id="1" data-img="green">
                            {
                                list.filter(function (val) {
                                    return val.original.motivationStatus === 1;
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className='dkg-candfilter-dropdown-item' data-type="PMID" data-id="2" data-img="#d58b5d" onClick={selectUserFilter}>
                        <FaReplyAll style={{ color: "#d58b5d" }} data-type="PMID" data-id="2" data-img="#d58b5d" onClick={selectUserFilter} />
                        <span className="proflieCount" data-type="PMID" data-id="2" data-img="#d58b5d" onClick={selectUserFilter}>Good Response</span>
                        <span className="proflieCount" data-type="PMID" data-id="2" data-img="#d58b5d">
                            {
                                list.filter(function (val) {
                                    return val.original.motivationStatus === 2;
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className='dkg-candfilter-dropdown-item' data-type="PMID" data-id="3" data-img="red" onClick={selectUserFilter}>
                        <FaReplyAll style={{ color: "red" }} data-type="PMID" data-id="3" data-img="red" onClick={selectUserFilter} />
                        <span className="proflieCount" data-type="PMID" data-id="3" data-img="red" onClick={selectUserFilter}>Low Response</span>
                        <span className="proflieCount" data-type="PMID" data-id="3" data-img="red" onClick={selectUserFilter}>
                            {
                                list.filter(function (val) {
                                    return val.original.motivationStatus === 3;
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1" className='dkg-candfilter-dropdown-item' data-type="PMID" data-id="0" data-img="#d3d3d3" onClick={selectUserFilter}>
                        <FaReplyAll style={{ color: "#d3d3d3" }} data-type="PMID" data-id="0" data-img="#d3d3d3" onClick={selectUserFilter} />
                        <span className="proflieCount" data-type="PMID" data-id="0" data-img="#d3d3d3" onClick={selectUserFilter}>Blank </span>
                        <span className="proflieCount" data-type="PMID" data-id="0" data-img="#d3d3d3" onClick={selectUserFilter}>
                            {
                                list.filter(function (val) {
                                    return val.original.motivationStatus === 0;
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default index;