import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
// import './addnew-payment.scss'
import AWS from 'aws-sdk'
// import { SYSTEM_CONSTANTS } from "../../../../../../constants";
// import Dropdown from 'react-bootstrap/Dropdown'
import SubmitButton from "../../../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../../utils/messages/messageSlice";
import { updatenewfile, clearState,detailsbyid } from "../../../../../../slice/companies/clients/clientSlice";
import { FaEdit } from "react-icons/fa";
import 'date-fns';

const EditfileModal = ({ onHide }) => {   
    
   const dispatch = useDispatch();
    const { isLoading, projectdetails, message, isError, isUpdate } = useSelector(state => state.companies)
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isflag, setFlg] = useState('0');

    const [uploadfileName, setUploadFileName] = useState('')
    const [file, setFile] = useState('')
    const [fileName, setFileName] = useState('')
    const [fileExt, setFileExt] = useState('')
    const [rowid, setFileid] = useState(0)
    const [fileFileType, setFileType] = useState('')

    useEffect(()=>{
        if(!isLoading && projectdetails){ 
             setFileName(projectdetails.name)
             setFileid(projectdetails.id)
             setFileExt(projectdetails.fileType)
             setFileType(projectdetails.fileType)
             setFile(projectdetails.file)            
            //console.log(projectdetails)
        }
    },[isLoading])  

    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: message }))
            onHide();
            dispatch(clearState())
        }
        if (isUpdate) {
            onHide();
            setLoading(false);
            setSuccess(true);
            dispatch(showSuccess({ msg: message }))
            dispatch(clearState())
            setFlg('0')
        }
    }, [isError, isUpdate])

    const onSelectFileByAPI = async (e) => {
         if (e.target.files && e.target.files.length > 0) {   
            setFileExt((e.target.files[0].name).split('.').pop())      //extension   
            setFile(e.target.files[0]) //file
            setUploadFileName(e.target.files[0].name)
            setFileName((e.target.files[0].name).replace(/\.[^/.]+$/, "")) //file name
            setFileType((e.target.files[0].type))            
        }
        setFlg('1')
    }

    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (!fileName || !fileExt) {
            dispatch(showError({ msg: 'All fields required' }))
        } else {
            setSuccess(false);
            setLoading(true);                    

            if(isflag === '0'){
                dispatch(updatenewfile({ id: rowid, name: fileName, fileType: fileExt,file }))                 
            }
            else
            {
            //console.log("updatenew record")
            let s3bucket = new AWS.S3({
            accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
            secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
            Bucket: process.env.REACT_APP_BUCKET_NAME
        });
            let fileNewName = Date.now() + '-' + uploadfileName;
            s3bucket.createBucket(function () {
                var params = {
                    Bucket: process.env.REACT_APP_BUCKET_NAME,
                    Key: fileNewName,
                    Body: file,
                    ContentType: fileFileType
                };

                s3bucket.upload(params, function (err, data) {
                    if (err) {
                        console.log(err)
                    } else {
                        dispatch(updatenewfile({ id: rowid, name: fileName, fileType: fileExt, file: data.key }))
                    }
                });
            });
            }       
        }
    }

    return (
        <>
             <Modal show={true} onHide={onHide} className="dkg-addnew-paymenttask-modal-21">
                <Modal.Header closeButton className="dkg-addnew-payment-modalheader-21" >
                    <Modal.Title>Update File</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-addnew-payment-modalbody-21">
                    <div className='row'>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Attachment File</label>
                                <input type="file"  className="form-control w-100 dkg-payment-attach-input" id="imageInputAPI" onChange={onSelectFileByAPI}/>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>File Name</label>
                                <input type='text' className='form-control' name='filename' value={fileName} onChange={(e)=>setFileName(e.target.value)} />
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>File Type</label>
                                 <input type='text' className='form-control' name='fileType'  value={fileExt} onChange={(e)=>setFileExt(e.target.value)} />
                               
                            </div>
                        </div>
                        
                        <div className='col-md-12 col-12'>
                            <div className='dkg-create-payment-taskbtncon'>                       
                                 <SubmitButton className="dkg-create-payment-taskbtn" txt="UPDATE" loading={loading} success={success} onClick={handleButtonClick} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditfileModal;