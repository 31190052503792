import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const DkgGuidelinesDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="#" className={`${pathname.match('/training/commontraining') ? 'active' : ''}`}>Overview</Link></li>
                    <li><Link to="#" className={`${pathname.match('/training/sample_1') ? 'active' : ''}`}>Sample 1</Link></li>
                    <li><Link to="#" className={`${pathname.match('/training/sample_2') ? 'active' : ''}`}>Sample 2</Link></li>
                    <li><Link to="#" className={`${pathname.match('/training/sample_3') ? 'active' : ''}`}>Sample 3</Link></li>
                </Route>
            </ul>
        </React.Fragment>
    )
}

export default DkgGuidelinesDetailLeftPanel;

