import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import './myacc-navbar.scss';
import { FaCaretDown } from "react-icons/fa";
import Submenu from './submenu/MyaccSubmenu';
import {Nav,Navbar} from 'react-bootstrap'


const RcaccountNav = () => {
    const pathname = window.location.pathname;

    const { userInfo } = useSelector(state => state.auth);
    const { internalMailsCount } = useSelector(state => state.mailbox);
    // const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    // const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);


  return (
    <>
        <Navbar collapseOnSelect expand="lg" className="dkg-myacc-navbar dkg-rc-account-Navbar">
            <div className="Lsidebar">
                <div className="d-flex dflex2">
                    <div className="profile-info order-md-first" style={{display: "block"}}>
                        <Navbar.Brand href="/dashboard/plateform">
                            <figure>
                                <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + userInfo.profileImage} className="img-fluid img-circle" alt="" />
                            </figure>
                        </Navbar.Brand>
                    </div>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="dkg-mynavbar-tabNav">
                        <ul className="dkg-mynavbar-tabNav-ul">
                            <li className="dkg-myacc-nav-main-li">
                                <Link to="/rc-account/dashboard" className={`${(segment2 === 'dashboard') ? 'grey-active' : ''}`}>Dashboard</Link>
                            </li>
                            <li className="dkg-myacc-nav-main-li">
                                <Link to="#/rc-account/my-profile" className={`${(segment2 === 'my-profile') ? 'grey-active' : ''}`}>My Profile</Link>
                            </li>
                            <li className="dkg-myacc-nav-main-li">
                            <Link to="#/rc-account/job-profile" className={`${(segment2 === 'job-profile') ? 'grey-active' : ''}`}>Job Profile</Link>
                            </li>
                            <li className="dkg-myacc-nav-main-li dkg-services-li">
                                <Link to="#/rc-account/sales/summary" className={`${(segment2 === 'sales') ? 'white-active' : ''}`}>Sales<span className="dkg-dropdown-toggle ml-2"><FaCaretDown /></span></Link>
                                <Submenu path={pathname} />
                            </li>
                            <li className="dkg-myacc-nav-main-li dkg-profile-li">
                                <Link to="#/rc-account/kpi/summary" className={`${(segment2 === 'kpi') ? 'white-active' : ''}`}>KPI<span className="dkg-dropdown-toggle ml-2"><FaCaretDown /></span></Link>
                                <Submenu path={pathname} />
                            </li>
                            <li className="dkg-myacc-nav-main-li dkg-setting-li">
                                <Link to="#/rc-account/holidays/contact-us" className={`${(segment2 === 'holidays') ? 'white-active' : ''}`}>Holidays<span className="dkg-dropdown-toggle ml-2"><FaCaretDown /></span></Link>
                                <Submenu path={pathname} />
                            </li>
                            <li className="dkg-myacc-nav-main-li dkg-setting-li">
                                <Link to="#/rc-account/contracts/contact-us" className={`${(segment2 === 'contracts') ? 'white-active' : ''}`}>Contracts<span className="dkg-dropdown-toggle ml-2"><FaCaretDown /></span></Link>
                                <Submenu path={pathname} />
                            </li>
                            <li className="dkg-myacc-nav-main-li dkg-setting-li">
                                <Link to="#/rc-account/training/contact-us" className={`${(segment2 === 'training') ? 'white-active' : ''}`}>Training<span className="dkg-dropdown-toggle ml-2"><FaCaretDown /></span></Link>
                                <Submenu path={pathname} />
                            </li>
                            <li className="dkg-myacc-nav-main-li dkg-setting-li">
                                <Link to="#/rc-account/payments/contact-us" className={`${(segment2 === 'payments') ? 'white-active' : ''}`}>Payments<span className="dkg-dropdown-toggle ml-2"><FaCaretDown /></span></Link>
                                <Submenu path={pathname} />
                            </li>
                            <li className="dkg-myacc-nav-main-li dkg-setting-li">
                                <Link to="#/rc-account/settings/contact-us" className={`${(segment2 === 'settings') ? 'white-active' : ''}`}>Settings<span className="dkg-dropdown-toggle ml-2"><FaCaretDown /></span></Link>
                                <Submenu path={pathname} />
                            </li>
                        </ul>
                    </Navbar.Collapse>
                </div>
            </div>       
        </Navbar>
    </>
  )
}

export default RcaccountNav;