import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import './loginofficeaddnew.scss';

const Reorder = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Link to="#" onClick={handleShow} className="dk-resCrcleIcon"> <i className="fas fa-arrows-alt"></i></Link>
            <Modal show={show} onHide={handleClose} className="dkg-office365Modal" centered>
                <Modal.Header closeButton className="dkg-office365ModalHeader dkg-reorder-office365ModalHeader">
                    <Modal.Title>Reorder</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-office365ModalBody dkg-reorder-office365ModalBody ">
                    <div className="dk-toolsReorderPanel">
                        <ul>
                            <div className="Mukul">
                                <div className="dk-valueFields d-flex" id="1">
                                    <span className="dk-handle"><i className="fas fa-bars"></i></span>
                                    <input type="text" name="" id="" className="form-control" defaultValue="Taledo" />
                                </div>
                            </div>
                            <div className="Mukul">
                                <div className="dk-valueFields d-flex" id="6">
                                    <span className="dk-handle"><i className="fas fa-bars"></i></span>
                                    <input type="text" name="" id="" className="form-control" defaultValue="Mphasis" />
                                </div>
                            </div>
                            <div className="Mukul">
                                <div className="dk-valueFields d-flex" id="27">
                                    <span className="dk-handle" ><i className="fas fa-bars"></i></span>
                                    <input type="text" name="" id="" className="form-control" defaultValue="Skype" />
                                </div>
                            </div>
                            <div className="Mukul">
                                <div className="dk-valueFields d-flex" id="50">
                                    <span className="dk-handle"><i className="fas fa-bars"></i></span>
                                    <input type="text" name="" id="" className="form-control" defaultValue="Agencies Platform" />
                                </div>
                            </div>
                            <div className="Mukul">
                                <div className="dk-valueFields d-flex" id="58">
                                    <span className="dk-handle" ><i className="fas fa-bars"></i></span>
                                    <input type="text" name="" id="" className="form-control" defaultValue="LinkedIn" />
                                </div>
                            </div>
                        </ul>
                        <div className="d-flex justify-content-center ">
                            <button className=" dk-toolsPanelSaveBtn ">
                                Save Order
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Reorder