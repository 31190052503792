import React, { useMemo, useState, useEffect } from "react";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useAsyncDebounce } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import { DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Form, InputGroup, FormControl } from 'react-bootstrap';
import './mlresource.scss'
import Dropdown from 'react-bootstrap/Dropdown'
import { GlobalSearch } from "../GlobalSearch";
import AddNewModal from './modal/AddNew'
import EditModal from './modal/Edit'
import ViewModal from './modal/View'
import ReorderModal from './modal/Reorder'
import DeleteModal from "../../../ui/delete";
import { detailsByid, deleteMLRecord, updateStatus, clearState } from "../../../../slice/resources/MlResourcesSlice";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import UserFilterToggle from "../../../ui/tableUserFilter";
import RightSideFilter from './modal/rightfilter/RightSideFilter';
//import StatusFilter from "./dropdown/StatusFilter";
import FilterSelectInstance from '../filter/SelectInstance';

const BasicTable = () => {
    const dispatch = useDispatch();
    const { dataList, isDelete, isUpdate, msg, isError } = useSelector(state => state.mlResource)
    const [userFilterId, setUserFilterId] = useState('0')
    const [userFilterImg, setUserFilterImg] = useState('0')
    const data = dataList
    const [rowId, setRowId] = useState('0')

    const [isAddNew, setIsAddNew] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [issDelete, setIsDelete] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isRorder, setIsRorder] = useState(false);

    const clickFilterUser = (e) => {
        setUserFilterId(e.target.id)
        setUserFilterImg(e.target.src)
        setFilter('userInfo', e.target.id)
    }
    const deleteFilter = (name) => {
        setFilter(name, '')
        if (name === 'userInfo') {
            setUserFilterId('0')
            setUserFilterImg('0')
        }
    }
    const showeditModal = async (rowId) => {
        await dispatch(detailsByid({ rowId }))
        setIsEdit(true);
    }
    const hideeditModal = () => {
        setIsEdit(false);
    }
    const showeditpopup = () => {
        setIsEdit(true);
    }

    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(clearMessage())
            dispatch(showSuccess({ msg: msg }))
            setIsDelete(false)
            setRowId('0')
        }
    }, [isDelete])

    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: msg }))
            setIsEdit(false);
            dispatch(clearState())
        }
        if (isUpdate) {
            setIsEdit(false);
            dispatch(showSuccess({ msg: msg }))
            dispatch(clearState())
        }
    }, [isError, isUpdate])

    const handleClose = () => {
        setRowId('0')
        setIsEdit(false)
        setIsDelete(false)
    }

    const deleteItem = async () => {
        dispatch(deleteMLRecord({ rowId }))
    }

    const deleteList = async (dataId) => {
        setRowId(dataId)
        setIsDelete(true);
    }

    const showviewModal = async (rowId) => {
        await dispatch(detailsByid({ rowId }))
        setIsView(true);
    }
    const hideviewModal = () => {
        setIsView(false);
    }

    const hidereorderModal = () => {
        setIsRorder(false);
    }
    const changeStatus = async (e) => {
        await dispatch(updateStatus({ rowId: e.target.id, status: e.target.dataset.key }))
    }

    const columns = useMemo(() => [
        {
            Header: 'Sno',
            id: 'Sno',
            Cell: (row) => Number(row.row.id) + 1
        },
        {
            Header: 'Status',
            id: 'Status',
            Cell: ({ cell }) => {
                return (
                    <div className={(cell.row.original.status == "0") ? 'dk-statusDrpCon dk-statusInactive' : 'dk-statusDrpCon'}>
                        <Dropdown>
                            <Dropdown.Toggle className="dk-statusbtn" variant="" id="dropdown-basic">
                                {(cell.row.original.status) == "0" ? 'Inactive' : 'Active'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dk-statusMenu">
                                <Dropdown.Item id={cell.row.original.id} onClick={changeStatus} data-key='1' className="dk-statusActive" >Active</Dropdown.Item>
                                <Dropdown.Item id={cell.row.original.id} onClick={changeStatus} data-key='0' className="dk-statusInactive">Inactive</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )
            }
        },
        {
            Header: 'Manager',
            id: 'Manager',
            Cell: (row) => (
                <span className="dk-recImgCon">
                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + row.row.original.userInfo.profileImage} alt={row.row.original.name} className="dk-recImg" />
                </span>
            )
        },
        {
            Header: 'Name of Source',
            accessor: 'name',
            id: 'name'
        },
        {
            Header: 'Category',
            accessor: 'category',
            id: 'category'
        },

        {
            Header: 'Source Type',
            accessor: 'source',
            id: 'source'
        },
        {
            Header: 'Paid/Unpaid',
            accessor: 'isPaid',
            id: 'isPaid'
        },
        {
            Header: 'Coverage',
            accessor: 'coverage',
            id: 'coverage',
        },
        {
            Header: 'Location',
            accessor: 'location',
            id: 'location'
        },
        {
            id: 'userInfo',
            Header: 'Id',
            accessor: 'userInfo.uid'
        },
        {
            id: 'statusId',
            Header: 'Status Id',
            accessor: 'status',
            show: false
        },
        {
            Header: 'Action',
            id: 'Action',
            Cell: (row) => (
                <div className="dk-resAction">

                    <Link to="#" className="dk-view-btn mr-2" onClick={() => showviewModal(row.row.original.id)}>
                        <i className="fas fa-eye"></i>
                    </Link>
                    <Link to="#" onClick={() => showeditModal(row.row.original.id)} className="dk-edit-btn mr-2">
                        <i className="fas fa-edit"></i>
                    </Link>
                    <Link to="#" onClick={() => deleteList(row.row.original.id)} className="dk-delete-btn"><i className="fas fa-trash-alt"></i></Link>
                </div>
            )
        }
    ], [])

    const tableInstance = useTable({
        columns: columns,
        data: data,
        initialState: {
            pageIndex: 0, pageSize: 100, hiddenColumns: ["userInfo", "statusId"]
        },
    }, useGlobalFilter, useFilters, useSortBy)

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter, setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize } } = tableInstance

    //const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter, setFilter } = tableInstance
    const { globalFilter } = state

    useEffect(() => {
        setFilter('userInfo', '')
        setUserFilterId('0')
        setUserFilterImg('0')
        setGlobalFilter('0')
    }, [])

    const [caseStatusFilterDDLText, setCaseStatusFilterDDLText] = useState('Status')
    const onSelectStatusFilter = (eventKey) => {
        console.log(eventKey)
        switch (eventKey) {
            case '1':
                setCaseStatusFilterDDLText('Active')
                setFilter('statusId', '1')
                break

            case '0':
                setCaseStatusFilterDDLText('Inactive')
                setFilter('statusId', '0')
                break
            default:
                break;
        }

    }

    const removeTag = async (e) => {
        const type = e.target.dataset.type;
        switch (type) {
            case 'status':
                setCaseStatusFilterDDLText('Status')
                setFilter('statusId', '')
                break;

            default:
                break;
        }
    }

    return (
        <React.Fragment>

            {
                (isEdit) ? <EditModal hidePopup={hideeditModal} showeditpopup={showeditpopup} /> : null
            }
            {
                (issDelete) ? <DeleteModal id={rowId} handleClose={handleClose} deleteItem={deleteItem} /> : null
            }
            {
                (isView) ? <ViewModal hidePopup={hideviewModal} /> : null
            }
            {
                (isRorder) ? <ReorderModal hidePopup={hidereorderModal} /> : null
            }
            <div className="row dk-mlreSTopHead">
                <div className="col-md-4 col-sm-12">
                    <div className="dk-mlresLeftHead">
                        <div className="dk-resSrchInpuBbox mr-2">
                            <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                                Username
                            </Form.Label>
                            <InputGroup className="mb-0">
                                <GlobalSearch filter={globalFilter} setFilter={setGlobalFilter} />

                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <i className="fas fa-search"></i>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </div>
                        <div className="mr-2 dk-mlresuserfilterCon1">
                            <UserFilterToggle userFilterId={userFilterId} userFilterImg={userFilterImg} list={tableInstance.flatRows} selectUser={clickFilterUser} deleteFilter={deleteFilter} />
                        </div>
                        {/* <div className="mr-2 dk-resCrcleIconCon">
                            <Link to="#" className="dk-resCrcleIcon">
                                <i className="fas fa-file-alt"></i>
                            </Link>
                        </div> */}
                        <div className="mr-2 dk-resCrcleIconCon">
                            <RightSideFilter />
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="dk-resPageTitleCon">
                        <h2 className="dk-resPageTitle">Posting Portals</h2>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="dk-mlresRighttHead">
                        <div className="dk-rightBtnCon">
                            <div className="mr-2">
                                <AddNewModal />
                            </div>
                            <div className="dk-resreorder-btnCon mr-2">
                                <ReorderModal dataList={data} />
                            </div>
                            <div className="dk-res-statusfilterCon">

                                <div className="dk-clients-right-statusFilter">
                                    <DropdownButton className='dk-cli-status-dropdown' title={caseStatusFilterDDLText} onSelect={onSelectStatusFilter}>
                                        <Dropdown.Item eventKey="1" href="#" className="dk-activeBtn" style={{ backgroundColor: "#368836", color: "#fff" }}>Active
                                            <span className="dkg-lv-statuscounter">
                                                {
                                                    tableInstance.flatRows.filter(function (val) {
                                                        return val.original.status === 1;
                                                    }).length
                                                }
                                            </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="0" href="#" className="dk-inActiveBtn" style={{ backgroundColor: "#dc3545", color: "#fff" }}>Inactive
                                            <span className="dkg-lv-statuscounter">
                                                {
                                                    tableInstance.flatRows.filter(function (val) {
                                                        return val.original.status === 0;
                                                    }).length
                                                }
                                            </span>
                                        </Dropdown.Item>

                                    </DropdownButton>
                                    {
                                        (caseStatusFilterDDLText !== 'Status') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="status" onClick={removeTag}></i> : null
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {/* table Start Component*/}
                    <div className="dk-mlresTableCon table-responsive">
                        <table className="table dk-mlresTable table-bordered" {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </React.Fragment>)

}
export default BasicTable