import React, { useState, useEffect } from 'react';
import { useAsyncDebounce } from 'react-table';
export const GlobalSearch = ({ filter, setFilter }) => {
    const [value, setValue] = useState(filter);
    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined)
    }, 300)

    useEffect(() => {
        if (filter === '0' || filter === undefined) {
            setValue('')
        }
    }, [filter])

    return (
        <div className="input-group">
            <input type="search" placeholder="Search..." className="form-control" value={value || ''} onChange={(e) => {
                setValue(e.target.value)
                onChange(e.target.value)
            }} />
            <div className="input-group-append">
                <button className="btn btn-secondary" type="button">
                    <i className="fa fa-search"></i>
                </button>
            </div>
        </div>
    )
}