import React from 'react';
import { Link, useHistory } from 'react-router-dom'
import ProjectManagerImg from "../../../assets/images/girlmanager.png";
import AccountManagerImg from "../../../assets/images/account-manager-training.png";
import RecruitmentManagerImg from "../../../assets/images/recruitmentmanager_white.png";
import InterviewManaerImg from "../../../assets/images/recruiters_white.png";
// import RecruitmentConsultantImg from "../../../assets/images/resource_white.png";
import RecruitmentAdminImg from "../../../assets/images/admin_white.png";
import RecruitmentDBImg from "../../../assets/images/building_icon.png";
import RecruitmentAdsImg from "../../../assets/images/advertisement-training.png";
import { useDispatch, useSelector } from "react-redux";
import { setMyModule } from "../../../slice/modules/moduleSlice";
import Moduleheader from "../../elements/header/index";
import './trainingmodule.scss';

const TrainingRecModule = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth);

    const history = useHistory();
    const setModule = async (myModule) => {
        await dispatch(setMyModule({ module: myModule }))
        switch (myModule) {
            case 'recruitment-manager':
                history.push("/training/recruitmentmodule/recruitment-manager/dashboard");
                break;

            case 'ads-post-admin':
                history.push("/training/recruitmentmodule/ads-post-admin/dashboard");
                break;

            case 'account-manager':
                history.push("/training/recruitmentmodule/account-manager/dashboard");
                break;

            case 'recruitment-consultant':
                history.push("/training/recruitmentmodule/recruitment-consultant/dashboard");
                break;

            case 'database-admin':
                history.push("/training/recruitmentmodule/database-admin/dashboard");
                break;

            case 'project-manager':
                history.push("/dkglobaladmin/admintraining/project-manager");
                break;


            default:
                break;
        }
    }
    return (
        <>
            <Moduleheader dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="Training" moduleLink="/dkglobaladmin/trainingmodule/dashboard" linkcolor="#3c4c62" mainheaderTitle=" - Recruitment" textcolor="#3c4c62" />
            <div className="trainingModule-flexMian">
                <div className="dk-trainingModule-main">
                    <h3 className="title">RECRUITMENT MODULES</h3>
                    <div className="dk-trainingModule-cols">
                        <div className="dk-trainingModule-box">
                            <Link to="#" onClick={() => setModule('database-admin')} style={{ background: '#396a95' }}>
                                <div className="dk-trainingLogo text-inherit">
                                    <span><img src={RecruitmentDBImg} alt="" /></span>
                                    Clients Manager
                                </div>
                            </Link>
                        </div>
                        <div className="dk-trainingModule-box">
                            <Link to="#" onClick={() => setModule('recruitment-manager')} style={{ background: '#396a95' }}>
                                <div className="dk-trainingLogo text-inherit">
                                    <span><img src={RecruitmentManagerImg} alt="" /></span>
                                    Recruitment Manager
                                </div>
                            </Link>
                        </div>
                        <div className="dk-trainingModule-box">
                            <Link to="#" onClick={() => setModule('account-manager')} style={{ background: '#396a95' }}>
                                <div className="dk-trainingLogo text-inherit">
                                    <span><img src={AccountManagerImg} alt="" /></span>
                                    Account Manager
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="dk-trainingModule-cols">
                        <div className="dk-trainingModule-box">
                            <Link to="#" onClick={() => setModule('recruitment-consultant')} style={{ background: '#396a95' }}>
                                <div className="dk-trainingLogo text-inherit">
                                    <span><img src={InterviewManaerImg} alt="" /></span>
                                    Recruitment Consultant
                                </div>
                            </Link>
                        </div>
                        <div className="dk-trainingModule-box">
                            <Link to="#" onClick={() => setModule('project-manager')} style={{ background: '#396a95' }}>
                                <div className="dk-trainingLogo text-inherit">
                                    <span><img src={ProjectManagerImg} alt="" /></span>
                                    Recruitment Admin
                                </div>
                            </Link>
                        </div>
                        <div className="dk-trainingModule-box">
                            <Link to="#" style={{ background: '#396a95', pointerEvents: 'none' }}>
                                <div className="dk-trainingLogo text-inherit">
                                    <span></span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrainingRecModule;