import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import {
  FaArrowRight,
  FaEye,
  FaTrashAlt,
  FaUser,
  FaTimes,
  FaCheck,
  FaEdit,
  FaCalendarAlt,
} from "react-icons/fa";
import ViewProgressModal from "./modal/ViewProgress";
import WithdrawApplication from "./modal/WithdrawApplication";
import EditWithdrawApplication from "./modal/EditWithdrawApplication";
import EditViewApplication from "./modal/EditViewApplication"; //Upload Job Application

import MainblockStatusDopdown from "./dropdown/StatusDopdown";
import { useDispatch, useSelector } from "react-redux";
// import SelectinputDropDown from './dropdown/SelectinputDropDown'
import CaseProgressCommentModal from "../../../../../../utils/comment/caseprgress/CaseProgressModal";
// import Candidate from "../../../../../../../assets/images/ajay.jpeg";
import AppcardDeleteModal from "../.././../../../../ui/Modal/deletemodal/DeleteModal";
import UserList from "../../../../../../ui/userList/UserListByRoleForCandidates";
import {
  updateApplication,
  deleteCanApplication,
  updateFuncApplication,
  unlinkedJobs,
} from "../../../../../../../slice/candidates/list/listSlice";

import { categoryDataFetch } from "../../../../../../../slice/kpis/assignKpisReportSlice";
import { SYSTEM_CONSTANTS } from "../../../../../../../constants";
// import CompanyDropdown from "../../../../../jobs/addnew/CompanyCanApp";
// import CategoryDropdown from "../../../../../jobs/addnew/CategoryDropdown";
// import SearchDropDownFilter from "../../../../../jobs/addnew/OthersCanApp";
import JobinfoTabSearch from "../../../../../jobs/addnew/JobinfoTabSearch";
import JobSpecTabSearch from "../../../../../jobs/addnew/JobSpecTabSearch";
import DetialViewPoup from "../../../../../jobs/livejobs/modal/DetialViewPoup";
import EditdetialviewPoup from "../../../../../jobs/livejobs/modal/EditdetialviewPoup";
import {
  jobList,
  //jobsDetails,
} from "../../../../../../../slice/jobs/jobsSlice";
import CaseUpdated from "./modal/CaseUpdated";
import CaseProgressModal from "./modal/CaseProgressModal";
import ViewcaseprogressModal from "./modal/ViewcaseprogressModal";
import CandidateDocModal from "./modal/CandidateDocModal";
import CaseCommentProgress from "../../../../../../utils/comment/CaseCommentProgress";
import CustomDateFormat from "../../../../../../ui/dateFormats/CustomDateFormat";
// import {
//   companyData,
//   companyProjectData,
//   companyRoleData,
// } from "../../../../../../../slice/companies/clients/clientSlice";

const ApplicationTable = ({ id, removeDiv, itemdata }) => {  
  const dispatch = useDispatch();
  const { isAppDelete, isJobunlink } = useSelector((state) => state.candidates);
 
  const {
    isLoading,
    jobseDataList,
    // isSuccess,
    // isUpdate,
    // message,
    // isDetailsLoading,
    // jobDetails,
  } = useSelector((state) => state.jobs);

  
  const [jobbInfo, setJobbInfo] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isDeleteAppModal, setIsDeleteAppModal] = useState(false);
  const [isViewProgress, setIsViewProgress] = useState(false);
  const [isWithdrawApp, setIsWithdrawApp] = useState(false);
  const [iseditApp, setIsEditApp] = useState(false);
  const [iseditViewApp, setIsEditViewApp] = useState(false);
  const [isId, setIsId] = useState(0);
  const [isCaseProgess, setIsCaseProgess] = useState(false);
  const [JobSpecName, setJobSpecName] = useState(itemdata.jobSpecTitle);


  useEffect(() => {
    if (!isLoading && jobseDataList.length > 0) {
      setTimeout(() => {
        setJobbInfo(jobseDataList);
      }, 200);
    }
  }, [isLoading]);

 
  const showDeleteAppModal = (id) => {
    setIsId(id);
    setIsDeleteAppModal(true);
  };
  const hideDeleteAppModal = () => {
    setIsDeleteAppModal(false);
  };
  // const showViewProgress = () => {
  //   setIsViewProgress(true);
  // };
  const hideViewProgress = () => {
    setIsViewProgress(false);
  };
  const showWithdrawModal = () => {
    setIsWithdrawApp(true);
  };
  const hideWithdrawModal = () => {
    setIsWithdrawApp(false);
  };

  const showEditAppModal = () => {
    setIsEditApp(true);
  };
  const hideEditAppModal = () => {
    setIsEditApp(false);
  };
  const showEditViewAppModal = () => {
    setIsEditViewApp(true);
  };
  const hideEditViewAppModal = () => {
    setIsEditViewApp(false);
  };

  // const showcaseprogessModal = () => {
  //   setIsCaseProgess(true);
  // };
  const hideprogessModal = () => {
    setIsCaseProgess(false);
  };

  //const [caseStatus, setCaseStatus] = useState("");
  const [newstatusList, setNewstatusList] = useState([]);
  

  /******comment popup start**** */
  
  const [title, setTitle] = useState("VIEW CASE PROGRESS");
  const [progessTitle, setProgessTitle] = useState("EDIT STATUS");
  const [commentPopupShow, setCommentPopupShow] = useState(false);
  const [jobinfotitle, setJobinfotitle] = useState(itemdata.jobtitle);
  const [appjob_id, setAppjob_id] = useState(itemdata.job_id);
  const [appSpecjob_id, setAppjobSpec_id] = useState(itemdata.jobSpec_id);
  const [unlinkjobspec, setUnlinkjobspec] = useState(itemdata.jobSpecId);
  const [jobspecvalue, setJobspecvalue] = useState(itemdata.jobSpecId);
  const [jobInfovalue, setJobInfovalue] = useState(itemdata.jobId);
  const [unlinkjobinfo, setUnlinkjobinfo] = useState(itemdata.jobId);

  const [clientid, setClientid] = useState(itemdata.client_id);
  const [clientCompanyName, setClientCompanyName] = useState(
    itemdata.client_name
  );

  /******comment popup end**** */
  const commentPoupShow = (e) => {
    setCommentPopupShow(true);
  };
  const commentHandleClose = () => {    
    setCommentPopupShow(false);
  };

  const onItemClick = (id, columnId, value, img) => {
    dispatch(
      updateApplication({ id, key: columnId, value, canId: itemdata.canId })
    );
  };

  useEffect(() => {
    const fetchdata = async () => {
      try {
        await dispatch(
          categoryDataFetch({
            id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.categoryyId,
          })
        );
        await dispatch(jobList({ isDelete: 1 }));
      } catch (error) {
        console.log(error);
      }
    };
    fetchdata();
  }, []);

  useEffect(() => {
    if (isAppDelete) {
      setIsDeleteAppModal(false);
    }
  }, [isAppDelete]);

  /************unlink JOB DETAIL*************** */
  const unlinkJobFunction = (id, type, key1, key2, key3) => {
    if (type == "jobspec") {
      setJobSpecName("");//default name
      setJobspecvalue(0);
      setUnlinkjobspec(0);
    } else {
      setJobinfotitle("");//default name
      setJobInfovalue(0);
      setUnlinkjobinfo(0);
    }
    dispatch(
      unlinkedJobs({
        id,
        key1,
        key2,
        key3,
        value: 0,
        canId: itemdata.canId,
      })
    );
  };

  const updateJobIdValue = async (value, id) => {
    // console.log(value)
    // console.log(id)
    // console.log(jobseDataList)
    setUnlinkjobinfo(id);
    setJobInfovalue(Number(value));
    const joobinfo = jobseDataList.filter(
      (item) => item.job_id === Number(value)
    );
    const jobbbid = joobinfo.length > 0 ? joobinfo[0].id : "Job not found";
    setAppjob_id(jobbbid);

    dispatch(
      await updateApplication({
        id,
        key: "job_id",
        value: jobbbid,
        canId: itemdata.canId,
      })
    );

    const joniffo = jobseDataList.filter(
      (item) => item.job_id === Number(value)
    );
    const jobName = joniffo.length > 0 ? joniffo[0].title_description
    : "Job not found";
    //console.log(jobName);
    setJobinfotitle(jobName);

    dispatch(
      await updateFuncApplication({
        id,
        jobId: value,
        jobtitle: jobName,
        canId: itemdata.canId,
      })
    );
  };

  const updateJobSpecValue = async (value, id) => {
    setUnlinkjobspec(id);
    setJobspecvalue(Number(value));
    //console.log(jobseDataList);

    const joniffo = jobseDataList.filter(
      (item) => item.job_id === Number(value)
    );
    const jobbbid = joniffo.length > 0 ? joniffo[0].id : 0;
    const client_id =
      joniffo.length > 0 ? joniffo[0].companyDetails.clientId : 0;
    const client_CompanyName = joniffo.length > 0 ? joniffo[0].company : 0;
    setClientid(client_id);
    setClientCompanyName(client_CompanyName);
    const jobspecname = joniffo.length > 0 ? joniffo[0].name : "Job not found";
    setJobSpecName(jobspecname);
    setAppjobSpec_id(jobbbid);

    //dispatch(await jobsDetails({ id: jobbbid }));

    dispatch(
      await updateApplication({
        id,
        key: "jobSpecTitle",
        value: jobspecname,
        canId: itemdata.canId,
      })
    );
    dispatch(
      await updateApplication({
        id,
        key: "jobSpec_id",
        value: jobbbid,
        canId: itemdata.canId,
      })
    );
    dispatch(
      await updateApplication({
        id,
        key: "jobSpecId",
        value,
        canId: itemdata.canId,
      })
    );
  };

  const deleteHandler = () => {
    dispatch(
      deleteCanApplication({
        id: isId,
      })
    );
  };

  const [isJobDetailModal, setIsJobDetailModal] = useState(false);
  // const [isEditJobDetailModal, setIsEditJobDetailModal] = useState(false);
  const [isJobInfo, setIsJobInfo] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [editJobdetail, seteditJobdetail] = useState(false);

  const handleEditClose = () => setEditShow(false);
  const handleEditJobdetailClose = () => seteditJobdetail(false);
  // const [selectedJobid, setSelectedJobid] = useState(0);

  const showjobdetailviewModal = (jobbId) => {
    // setSelectedJobid(jobbId);
    setIsJobDetailModal(true);
    seteditJobdetail(true);
  };
  // const hidejobdetailviewModal = () => {
  //   setIsJobDetailModal(false);
  // };
  const showeditjobdetailModal = () => {
    setIsJobInfo(true);
    setEditShow(true);
  };
  // const hideeditjobdetailModal = () => {
  //   setIsEditJobDetailModal(false);
  // };

  const [isCaseUpdated, setIsCaseUpdated] = useState(false);
  const [isCaseProgress, setIsCaseProgress] = useState(false);
  const [isCommentProgress, setIsCommentProgress] = useState(false);

  const showcommentprogressModal = () => {
    setIsCommentProgress(true);
  };
  const hidecommentprogressModal = () => {
    setIsCommentProgress(false);
  };
  const showcaseupdatedModal = () => {
    setIsCaseUpdated(true);
  };
  const hidecaseupdatedModal = () => {
    setIsCaseUpdated(false);
  };
  // const showcaseprogressModal = () => {
  //   setIsCaseProgress(true);
  // };
  const hidecaseprogressModal = () => {
    setIsCaseProgress(false);
  };
  return (
    <>
      {isCaseProgress ? (
        <CaseProgressModal onHide={hidecaseprogressModal} />
      ) : null}
      {isCaseUpdated ? (
        <CaseUpdated onHide={hidecaseupdatedModal} itemdata={itemdata} />
      ) : null}
      {isViewProgress ? <ViewProgressModal onHide={hideViewProgress} /> : null}
      {isWithdrawApp ? (
        <WithdrawApplication onHide={hideWithdrawModal} itemdata={itemdata} /> //this component is open when we click on Withdraw Application
      ) : null}
      {iseditApp ? (
        <EditWithdrawApplication
          onHide={hideEditAppModal}
          itemdata={itemdata}
        />
      ) : null}
      {isCommentProgress ? (
        <CaseCommentProgress
          refTable={"candidates"}
          refId={59889}
          count={2}
          title={progessTitle}
          // commentHeading={"TESTTT"}
          commentHandleClose={hidecommentprogressModal}
          candidateStatus={"New Case"}
          candidateProgess={"From Job Board"}
          candidateStage={"To be Reviewed"}
          candidateSituation={"Under Review"}
          itemdata={itemdata}
        />
      ) : null}
      {isCaseProgess ? (
        <ViewcaseprogressModal onHide={hideprogessModal} />
      ) : null}
      {iseditViewApp ? (
        <EditViewApplication
          onHide={hideEditViewAppModal}
          itemdata={itemdata}
        />
      ) : null}
      {isDeleteAppModal ? (
        <AppcardDeleteModal
          handleClose={hideDeleteAppModal}
          deleteItem={deleteHandler}
        />
      ) : null}
      {commentPopupShow ? (
        <CaseProgressCommentModal
          refTable={"candidates"}
          title={title}
          closedCommentPopup={commentHandleClose}
          itemdata={itemdata}
        />
      ) : null}
      {/* {isJobDetailModal ? (
        <DetialViewPoup
          onHide={hidejobdetailviewModal}
        />
      ) : null} */}
      {/* job spec popup */}

      {isJobDetailModal ? (
        <DetialViewPoup
          jobId={appSpecjob_id}
          show={editJobdetail}
          onHide={handleEditJobdetailClose}
          popupfor="disableEditCandidateApplied"
          clientidds={clientid}
          canjobs={appSpecjob_id}
          companyName={clientCompanyName}
        />
      ) : null}
      {isJobInfo ? (
        <EditdetialviewPoup
          jobId={appjob_id}
          show={editShow}
          onHide={handleEditClose}
          popupfor="disableEditCandidateApplied"
        />
      ) : null}
      <tbody>
        <tr>
          <td>
            <div className="dkg-jobappied-datetime">
              <CustomDateFormat date={itemdata.createdAt} format="DD MMM YY" />
            </div>
          </td>
          <td>
            <div className="dkg-job-title-cols">
              <div
                className="dkg-job-title"
                style={{ textTransform: "uppercase" }}
              >
                LIVE JOB STATUS
              </div>
              <span className="dkg-arrow-right">
                <FaArrowRight />
              </span>
            </div>
          </td>
          <td>
            <MainblockStatusDopdown
              caseStatus={itemdata.progressMList.length > 0 ? itemdata.progressMList[0].status : "-"}
              list={newstatusList}
              statusBgColour={
                itemdata.progressMList.length > 0
                  ? itemdata.progressMList[0].statusBgColour
                  : ""
              }
              statusTxColour={
                itemdata.progressMList.length > 0
                  ? itemdata.progressMList[0].statusTxColour
                  : ""
              }
            />
          </td>
          <td colSpan={2}>
            <div className="dkg-app-st-status-dropdwnCon d-flex w-100">
              <div
                className="dkg-app-st-status-dropdwn"
                style={{ borderRight: "1px solid #ddd" }}
              >
                <MainblockStatusDopdown
                  caseStatus={itemdata.progressMList.length > 0 ? itemdata.progressMList[0].stage : "-"}
                  list={newstatusList}
                  statusBgColour={
                    itemdata.progressMList.length > 0
                      ? itemdata.progressMList[0].stageBgColour
                      : ""
                  }
                  statusTxColour={
                    itemdata.progressMList.length > 0
                      ? itemdata.progressMList[0].stageTxColour
                      : ""
                  }
                />
              </div>
              <div
                className="dkg-app-st-status-dropdwn"
                style={{ borderRight: "1px solid #ddd" }}
              >
                <MainblockStatusDopdown
                  caseStatus={itemdata.progressMList.length > 0 ? itemdata.progressMList[0].progress : "-"}
                  list={newstatusList}
                  statusBgColour={
                    itemdata.progressMList.length > 0
                      ? itemdata.progressMList[0].progressBgColour
                      : ""
                  }
                  statusTxColour={
                    itemdata.progressMList.length > 0
                      ? itemdata.progressMList[0].progressTxColour
                      : ""
                  }
                />
              </div>
              <div className="dkg-app-st-status-dropdwn">
                <MainblockStatusDopdown
                  caseStatus={itemdata.progressMList.length > 0 ? itemdata.progressMList[0].situation : "-"}
                  list={newstatusList}
                  statusBgColour={
                    itemdata.progressMList.length > 0
                      ? itemdata.progressMList[0].situationBgColour
                      : ""
                  }
                  statusTxColour={
                    itemdata.progressMList.length > 0
                      ? itemdata.progressMList[0].situationTxColour
                      : ""
                  }
                />
              </div>
            </div>
          </td>
          <td className="dkg-aapiled-app-td">
            <Link
              to="#"
              className="dkg-app-viewjob-btn"
              style={{ backgroundColor: "#03a786", color: "#fff" }}
            >
              <CustomDateFormat
                date={itemdata.caseUpdated}
                format="DD MMM YYYY"
              />
              <FaCalendarAlt />
            </Link>
          </td>
          <td>
            <div className="dkg-appied-action-cols">
              <div className="dkg-appied-edit-cols">
                <FaCalendarAlt onClick={showcaseupdatedModal} />
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="dkg-app-jobinfo-cols dkg-jobapps-link-cols">
              <div className="dkg-app-select-dropdown-Con">
                {unlinkjobspec === 0 ? (
                  <JobSpecTabSearch
                    categoryList={jobbInfo}
                    catList={updateJobSpecValue}
                    isRefresh={isRefresh}
                    id={itemdata.id}
                    jobstitle={itemdata.jobSpecTitle}
                    dataValue={0}
                  />
                ) : (
                  <>
                    <span
                      className="dkg-joapplied-cross"
                      onClick={() =>
                        unlinkJobFunction(
                          itemdata.id,
                          "jobspec",
                          "jobSpecId",
                          "jobSpec_id",
                          "jobSpecTitle"
                        )
                      }
                    >
                      <FaTimes />
                    </span>
                    <JobSpecTabSearch
                      categoryList={jobbInfo}
                      catList={updateJobSpecValue}
                      isRefresh={isRefresh}
                      id={itemdata.id}
                      jobstitle={itemdata.jobSpecTitle}
                      dataValue={jobspecvalue}
                    />
                  </>
                )}
              </div>
            </div>
          </td>
          <td>
            <div className="dkg-job-title-cols">
              <div className="dkg-job-title">VIEW JOB DETAIL</div>
              <span className="dkg-arrow-right">
                <FaArrowRight />
              </span>
            </div>
          </td>
          <td colSpan={3}>
            <span
              className="dkg-blue-jobtitle"
              onClick={() => showjobdetailviewModal(itemdata.jobId)}
            >
              {JobSpecName}
            </span>
          </td>
          <td className="dkg-aapiled-app-td" onClick={commentPoupShow}>
            <Link
              to="#"
              className="dkg-app-viewjob-btn"
              style={{ backgroundColor: "#3c97b6", color: "#fff" }}
            >
              Track Progress
            </Link>
          </td>
          <td>
            <div className="dkg-appied-action-cols">
              <div className="dkg-appied-edit-cols">
                <FaEdit onClick={showcommentprogressModal} />
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="dkg-app-jobinfo-cols dkg-jobapps-link-cols">
              <div className="dkg-app-select-dropdown-Con dkg-jobapps-link-dropdown-con">
                {unlinkjobinfo === 0 ? (
                  <JobinfoTabSearch
                    categoryList={jobbInfo}
                    catList={updateJobIdValue}
                    isRefresh={isRefresh}
                    id={itemdata.id}
                    jobstitle={itemdata.jobtitle}
                    dataValue={0}
                  />
                ) : (
                  <>
                    <span
                      className="dkg-joapplied-cross"
                      onClick={() =>
                        unlinkJobFunction(
                          itemdata.id,
                          "jobinfo",
                          "jobId",
                          "job_id",
                          "jobtitle"
                        )
                      }
                    >
                      <FaTimes />
                    </span>

                    <JobinfoTabSearch
                      categoryList={jobbInfo}
                      catList={updateJobIdValue}
                      isRefresh={isRefresh}
                      id={itemdata.id}
                      jobstitle={itemdata.jobtitle}
                      dataValue={jobInfovalue}
                    />
                  </>
                )}
              </div>
            </div>
          </td>
          <td>
            <div className="dkg-job-title-cols">
              <div className="dkg-job-title">VIEW JOB SUMMARY</div>
              <span className="dkg-arrow-right">
                <FaArrowRight />
              </span>
            </div>
          </td>
          <td colSpan={3}>
            <span
              className="dkg-orange-jobtitle"
              onClick={showeditjobdetailModal}
            >
              {jobinfotitle}
            </span>
          </td>
          <td className="dkg-aapiled-app-td">
            <CandidateDocModal
              appliedApplication={itemdata ? itemdata.appliedApplicaition : []}
            />
          </td>
          <td>
            <div className="dkg-appied-action-cols">
              <div className="dkg-appied-edit-cols">
                <FaEdit onClick={showEditViewAppModal} />
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="dkg-appied-action-cols">
              {/* <div className="dkg-appied-edit-cols">
                            <FaEdit />
                        </div> */}
              <div
                className="dkg-appied-cross-cols"
                onClick={() => showDeleteAppModal(itemdata.id)}
              >
                <FaTrashAlt />
              </div>
            </div>
          </td>
          <td className="p-0">
            <div className="dkg-app-user-dropdrownCon">
              <Dropdown className="dkg-app-user-dropdrown">
                <Dropdown.Toggle
                  variant=""
                  id=""
                  className="dkg-app-user-dropdrown-toggle"
                >
                  <div className="tooltipMain">
                    <img
                      src={
                        process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                        itemdata.recInfo.profileImage
                      }
                      className="filterImg"
                    />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dkg-app-user-dropdrown-menu">
                  <div className="d-flex">
                    <UserList
                      id={itemdata.id}
                      columnId="rcId"
                      onItemClick={onItemClick}
                    />
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </td>
          <td colSpan={3}>
            <div className="dkg-app-jobinfo-four-cols">
              <div className="dkg-app-jobinfo-cols">
                <div className="dkg-jobapplied-title">Category</div>
                <div className="dkg-app-select-dropdown-Con">
                  {itemdata.category}
                </div>
              </div>
              <div className="dkg-app-jobinfo-cols">
                <div className="dkg-jobapplied-title">Company</div>

                <div className="dkg-app-select-dropdown-Con">
                  {itemdata.company}
                </div>
              </div>
              <div className="dkg-app-jobinfo-cols">
                <div className="dkg-jobapplied-title">Project</div>
                <div className="dkg-app-select-dropdown-Con">
                  {itemdata.project}
                </div>
              </div>
              <div className="dkg-app-jobinfo-cols">
                <div className="dkg-jobapplied-title">Role</div>
                <div className="dkg-app-select-dropdown-Con">
                  {itemdata.role}
                </div>
              </div>
              <div className="dkg-app-jobinfo-cols">
                <div className="dkg-jobapplied-title">Language</div>
                <div className="dkg-app-select-dropdown-Con">
                  {itemdata.language}
                </div>
              </div>
            </div>
          </td>
          <td className="dkg-aapiled-app-td" onClick={showWithdrawModal}>
            <Link
              to="#"
              className="dkg-withdrawl-app-btn"
              style={{ backgroundColor: "#CA464F", color: "#fff" }}
            >
              Withdraw Application{" "}
            </Link>
          </td>
          <td>
            <div className="dkg-appied-action-cols">
              <div className="dkg-appied-edit-cols">
                <FaEdit onClick={showEditAppModal} />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </>
  );
};

export default ApplicationTable;
