import React, { useState, useEffect } from 'react';
import './casestatus.scss'
import DatePicker from "../datepicker"

const CaseStatus = ({ data, updateProfile, updateDates, tabId }) => {
    const [id, setId] = useState(0)
    const [candidateId, setCandidateId] = useState(0)
    const [createdAt, setCreatedAt] = useState('')
    const [updatedAt, setUpdatedAt] = useState('')
    const [jobSpecSent, setJobSpecSent] = useState('')
    const [cvSendoutDate, setCvSendoutDate] = useState('')
    const [inSpecSent, setInSpecSent] = useState('')
    const [jobOfferDate, setJobOfferDate] = useState('')
    const [joiningDate, setJoiningDate] = useState('')

    function isEmpty(obj) {
        for (var i in obj) { return false; }
        return true;
    }

    useEffect(() => {
        if (tabId === 'case_status') {
            setDataValue()
        } else {
            setDataValue()
        }
    }, [tabId, data])

    const setDataValue = () => {
        if (!isEmpty(data)) {
            setId(data.id)
            setCandidateId(data.canId)
            setCreatedAt(data.createdAt)
            setUpdatedAt(data.setUpdatedAt)
            setJobSpecSent(data.jobSpecSent)
            setCvSendoutDate(data.cvSendoutDate)
            setInSpecSent(data.inSpecSent)
            setJobOfferDate(data.jobOfferDate)
            setJoiningDate(data.joiningDate)
        }
    }

    return (
        <>
            <div className="dk-caseStausTabCon">
                <div className="row justify-content-between">
                    <div className="col-md-5 dk-profileDates-br">
                        <div className="dk-caseStausFromGrp d-flex align-items-center">
                            <label>CASE CREATED ON</label>
                            <DatePicker date={createdAt} candidateId={id} updateDates={updateDates} column="createdAt" />
                        </div>
                        <div className="dk-caseStausFromGrp d-flex align-items-center">
                            <label>CASE UPDATED ON</label>
                            <DatePicker date={updatedAt} candidateId={id} updateDates={updateDates} column="updatedAt" />
                        </div>
                        <div className="dk-caseStausFromGrp d-flex align-items-center">
                            <label>CASE CLOSED ON</label>
                            <DatePicker date={jobSpecSent} candidateId={id} updateDates={updateDates} column="jobSpecSent" />
                        </div>
                        {/* <div className="dk-caseStausFromGrp d-flex align-items-center">
                            <label>CV SENDOUT</label>
                            <DatePicker date={cvSendoutDate} candidateId={id} updateDates={updateDates} column="cvSendoutDate" />
                        </div>
                        <div className="dk-caseStausFromGrp d-flex align-items-center">
                            <label>INT SPEC SENT</label>
                            <DatePicker date={inSpecSent} candidateId={id} updateDates={updateDates} column="inSpecSent" />
                        </div>
                        <div className="dk-caseStausFromGrp d-flex align-items-center">
                            <label>JOB OFFER</label>
                            <DatePicker date={jobOfferDate} candidateId={id} updateDates={updateDates} column="jobOfferDate" />
                        </div>
                        <div className="dk-caseStausFromGrp d-flex align-items-center">
                            <label>JOINING</label>
                            <DatePicker date={joiningDate} candidateId={id} updateDates={updateDates} column="joiningDate" />
                        </div> */}
                    </div>
                    <div className="col-md-5">
                        <div className="dk-caseStausFromGrp d-flex align-items-center">
                            <label>JOB SPEC SENT</label>
                            <DatePicker date={createdAt} candidateId={id} updateDates={updateDates} column="createdAt" />
                        </div>
                        <div className="dk-caseStausFromGrp d-flex align-items-center">
                            <label>CV SENDOUT ON</label>
                            <DatePicker date={updatedAt} candidateId={id} updateDates={updateDates} column="updatedAt" />
                        </div>
                        <div className="dk-caseStausFromGrp d-flex align-items-center">
                            <label>INTERVIEW SPEC</label>
                            <DatePicker date={jobSpecSent} candidateId={id} updateDates={updateDates} column="jobSpecSent" />
                        </div>
                        <div className="dk-caseStausFromGrp d-flex align-items-center">
                            <label>JOB OFFER ON</label>
                            <DatePicker date={jobSpecSent} candidateId={id} updateDates={updateDates} column="jobSpecSent" />
                        </div>
                        <div className="dk-caseStausFromGrp d-flex align-items-center">
                            <label>JOB JOINED ON</label>
                            <DatePicker date={jobSpecSent} candidateId={id} updateDates={updateDates} column="jobSpecSent" />
                        </div>
                        <div className="dk-caseStausFromGrp d-flex align-items-center">
                            <label>INVOICED ON</label>
                            <DatePicker date={jobSpecSent} candidateId={id} updateDates={updateDates} column="jobSpecSent" />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default CaseStatus;
