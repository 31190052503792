import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const AddNumberModal = ({ handleClose }) => {
    return (
        <>
            <Modal className="dk-addNumberModal" centered show={true} onHide={handleClose}>
                <Modal.Body>
                    <form action="">
                        <div className="row">
                            <div className="col-md-6 pr-2">
                                <div className="form-group">
                                    <label for="">Current</label>
                                    <label for="">
                                        <input type="text" name="" id="" className="form-control" value="5" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 pl-2">
                                <div className="form-group">
                                    <label for="">New</label>
                                    <label for="">
                                        <input type="text" name="" id="" className="form-control" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group mt-1 mb-0 text-center">
                                    <button className="dk-AddBtn">Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddNumberModal;
