import React from 'react';
import './casestatus.scss';

const CaseStatusDropDwn = ({ caseStatus, value }) => {
    let bgColor = "";
 
    switch (caseStatus) {
        
        case 'Accepeted':
            bgColor = '#629bd3'
            break;       
        case 'Refused':
            bgColor = '#da3d5e'
            break;       
          

        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor }}>
                {
                    (value == "Blank") ? "" : value
                }
            </div>
        </>
    )
}

export default CaseStatusDropDwn;