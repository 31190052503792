import React from "react";
import '../mainMenu/style.scss';
import DeepakSubmenu from './menu/projectsubmenu/DeepakSubmenu';
import LuizaSubmenu from './menu/projectsubmenu/LuizaSubmenu';
import MarianaSubmenu from './menu/projectsubmenu/MarianaSubmenu';
import MarianaTwoSubmenu from './menu/projectsubmenu/MarianaTwoSubmenu';
import FilipSubmenu from './menu/projectsubmenu/FilipSubmenu';
import ElisSubmenu from './menu/projectsubmenu/ElisSubmenu';
import TehreemSubmenu from './menu/projectsubmenu/TehreemSubmenu';
import OnurSubmenu from './menu/projectsubmenu/OnurSubmenu';


const ProjectsSubmenu = ({ path }) => {
    const renderSubmenu = () => {
        switch (path.split("/")[2]) {

            case 'deepak':
                return <DeepakSubmenu />
                break;

            case 'luiza':
                return <LuizaSubmenu />
                break;

            case 'mariana':
                return <MarianaSubmenu />
                break;

            case 'marianatwo':
                return <MarianaTwoSubmenu />
                break;

            case 'filip':
                return <FilipSubmenu />
                break;

            case 'elis':
                return <ElisSubmenu />
                break;

            case 'tehreem':
                return <TehreemSubmenu />
                break;

            case 'onur':
                return <OnurSubmenu />
                break;

            default:
                break
        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default ProjectsSubmenu;