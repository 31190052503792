import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import  './dkg-myprofile.scss'
import SubmitButton from "../../../../ui/submitButton";
// import CV from '../../../../../assets/images/cvimg.png'
// import CVupload from '../../../../../assets/images/cvimg.png'
// import { FaEye, FaUpload , FaInfo ,FaTimes   } from 'react-icons/fa';
import { Nav, Tab, Dropdown } from "react-bootstrap";
import { FaEdit, FaInfo, FaTimes, FaUpload } from "react-icons/fa";
// import  './dkg-myprofile.scss'
import DummyUser from "../../../../../assets/images/user_dummay.png";

import {
  getCMS,
  // updatecontent,
  // clearState,
} from "../../../../../slice/cmsSlice";
//import MessageBox from '../../../../utils/joditEditor/MessageBox'

const CoverLetter = () => {
  const dispatch = useDispatch();
  const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);
  const [dkgResume, setDKGResume] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [profileSummary, setProfileSummary] = useState("");
  const [workexp, setWorkexp] = useState("");
  const [qualifiction, setQualifiction] = useState("");
  const [jobskills, setJobskills] = useState("");
  const [techkills, setTechkills] = useState("");
  const [langkills, setLangkills] = useState("");
  const [achivements, setAchivements] = useState("");
  const [hobbies, setHobbies] = useState("");
  const [refrence, setRefrence] = useState("");
  const [gdprConsent, setGdprConsent] = useState("");

  useEffect(() => {
    dispatch(getCMS({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setDKGResume(cmsList[34].description);
      setContactInfo(cmsList[35].description);
      setProfileSummary(cmsList[36].description);
      setWorkexp(cmsList[37].description);
      setQualifiction(cmsList[38].description);
      setJobskills(cmsList[39].description);
      setTechkills(cmsList[40].description);
      setLangkills(cmsList[41].description);
      setAchivements(cmsList[42].description);
      setHobbies(cmsList[43].description);
      setRefrence(cmsList[44].description);
      setGdprConsent(cmsList[45].description);
    }
  }, [isSuccess]);

  const [completedCrop, setCompletedCrop] = useState(null);
  const onSelectFile = (e) => {};
  const previewCanvasRef = useRef(null);

  return (
    <>
      <div className="dkg-contact-mainCon">
        <div className="dkg-contact-main">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="dkg-cv-preview"
          >
            <div className="row justify-content-center dkg-contact-mainRow">
              <div className="col-md-2 dkg-contact-main-sideCol">
                <Nav
                  variant="pills dkg-aboutus-navpills dkg-mydesired-job-navpills"
                  className="flex-column"
                >
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-preview"
                      className="dkg-aboutus-navlink"
                    >
                      DKG Resume
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-contact-details"
                      className="dkg-aboutus-navlink"
                    >
                      Contact Info
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-summary"
                      className="dkg-aboutus-navlink"
                    >
                      Profile Summary
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-experience"
                      className="dkg-aboutus-navlink"
                    >
                      Work Experience
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-education"
                      className="dkg-aboutus-navlink"
                    >
                      Qualifications
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-language"
                      className="dkg-aboutus-navlink"
                    >
                      Jobs Skills
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-technical-skills"
                      className="dkg-aboutus-navlink"
                    >
                      Technical Skills
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-new-language"
                      className="dkg-aboutus-navlink"
                    >
                      Language Skills
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-cv-achievements"
                      className="dkg-aboutus-navlink"
                    >
                      Achievements
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-hobbies-interest"
                      className="dkg-aboutus-navlink"
                    >
                      Hobbies & Interests
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-refrences"
                      className="dkg-aboutus-navlink"
                    >
                      Employer References
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-aboutus-navitem">
                    <Nav.Link
                      eventKey="dkg-gdpr-consent"
                      className="dkg-aboutus-navlink"
                    >
                      Give GDPR Consent
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-10 col-10 dkg-contact-mainCol">
                <Tab.Content className="dkg-aboutus-tabcontent">
                  <Tab.Pane
                    eventKey="dkg-cv-preview"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">YOUR DKG RESUME</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon dkg-cv-preview-main">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {dkgResume}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-contact-details"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">CONTACT INFORMATION</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-createcv-job-mainCon">
                      <div className="dkg-createcv-textarea-Con">
                        <div className="form-control dkg-createcv-textarea">
                          {contactInfo}
                        </div>
                      </div>
                    </div>
                    <div className="dkg-create-cv-center-sec dkg-create-cv-job-centersec">
                      <div className="dkg-create-cv-job-leftCon">
                        <div className="dkg-selected-title">YOUR CV IMAGE</div>
                        <div className="dkg-uploadimg-mainCon">
                          <div className="dkg-info-uplaod-image">
                            <div className="d-flex align-items-center">
                              <div className="dk-edit-userImgCon">
                                <input
                                  type="file"
                                  accept="image/*"
                                  name=""
                                  onChange={onSelectFile}
                                />
                                <span className="dk-myprofile-userImgCon">
                                  <figure id="edit_image">
                                    {completedCrop ? (
                                      <canvas
                                        ref={previewCanvasRef}
                                        className="dkg-update-profile-234"
                                      />
                                    ) : (
                                      <img
                                        alt="Crop"
                                        id="cropImgId"
                                        className="dk-myprofile-userImg"
                                        src={DummyUser}
                                      />
                                    )}
                                  </figure>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="dkg-upload-cv-btnCon">
                            <button className="dkg-remove-cv-btn btn mr-2">
                              Remove{" "}
                              <span className="dkg-upload-icon ml-2">
                                <FaTimes />
                              </span>
                            </button>
                            <button className="dkg-upload-cv-btn btn">
                              Upload{" "}
                              <span className="dkg-upload-icon ml-2">
                                <FaUpload />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="dkg-create-cv-job-rightCon">
                        <div className="dkg-selected-title">
                          YOUR KEY INFORMATIONS
                        </div>
                        <div className="dkg-createcv-rightbody">
                          <div className="dkg-createcv-frmgrp">
                            <label htmlFor="" className="dkg-createcv-label">
                              Full Name
                            </label>
                            <input
                              type="text"
                              className="dkg-createcv-frm-control form-control"
                            />
                          </div>
                          <div className="dkg-createcv-frmgrp">
                            <label htmlFor="" className="dkg-createcv-label">
                              Home Address
                            </label>
                            <input
                              type="text"
                              className="dkg-createcv-frm-control form-control"
                            />
                          </div>
                          <div className="dkg-createcv-frmgrp">
                            <label htmlFor="" className="dkg-createcv-label">
                              City & Country
                            </label>
                            <input
                              type="text"
                              className="dkg-createcv-frm-control form-control"
                            />
                          </div>
                          <div className="dkg-createcv-frmgrp">
                            <label htmlFor="" className="dkg-createcv-label">
                              Nationality
                            </label>
                            <input
                              type="text"
                              className="dkg-createcv-frm-control form-control"
                            />
                          </div>
                          <div className="dkg-createcv-frmgrp">
                            <label htmlFor="" className="dkg-createcv-label">
                              Visa Status
                            </label>
                            <input
                              type="text"
                              className="dkg-createcv-frm-control form-control"
                            />
                          </div>
                          <div className="dkg-createcv-frmgrp">
                            <label htmlFor="" className="dkg-createcv-label">
                              Email Address
                            </label>
                            <input
                              type="email"
                              className="dkg-createcv-frm-control form-control"
                            />
                          </div>
                          <div className="dkg-createcv-frmgrp">
                            <label htmlFor="" className="dkg-createcv-label">
                              Phone Number
                            </label>
                            <input
                              type="text"
                              className="dkg-createcv-frm-control form-control"
                            />
                          </div>
                          <div className="dkg-createcv-frmgrp">
                            <label htmlFor="" className="dkg-createcv-label">
                              Date of Birth
                            </label>
                            <input
                              type="date"
                              className="dkg-createcv-frm-control form-control"
                            />
                          </div>
                          {/* <div className="dkg-createcv-frmgrp">
                                             <label htmlFor="" className='dkg-createcv-label'>Marital Status</label>
                                             <div className="dkg-createcv-dropdwnCon">
                                                <Dropdown className="dkg-createcv-dropdwn">
                                                   <Dropdown.Toggle variant="" className="dkg-ccv-dropdwn-toggle" id="">
                                                   </Dropdown.Toggle>
                                                   <Dropdown.Menu className="dkg-ccv-dropdwn-menu">
                                                      <Dropdown.Item href="#" className="dkg-ccv-dropdwn-item">Yes</Dropdown.Item>
                                                      <Dropdown.Item href="#" className="dkg-ccv-dropdwn-item">No</Dropdown.Item>
                                                   </Dropdown.Menu>
                                                </Dropdown>
                                             </div>
                                          </div> */}
                          <div className="dkg-createcv-frmgrp">
                            <label htmlFor="" className="dkg-createcv-label">
                              Gender
                            </label>
                            <div className="dkg-createcv-dropdwnCon">
                              <Dropdown className="dkg-createcv-dropdwn">
                                <Dropdown.Toggle
                                  variant=""
                                  className="dkg-ccv-dropdwn-toggle"
                                  id=""
                                ></Dropdown.Toggle>
                                <Dropdown.Menu className="dkg-ccv-dropdwn-menu">
                                  <Dropdown.Item
                                    href="#"
                                    className="dkg-ccv-dropdwn-item"
                                  >
                                    Male
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href="#"
                                    className="dkg-ccv-dropdwn-item"
                                  >
                                    Female
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-summary"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">Profile Summary</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon dkg-cv-preview-main">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {profileSummary}
                        </div>
                      </div>
                      <div className="dkg-desired-center-sec dkg-desired-job-centersec">
                        {/* <MessageBox
                                          value={
                                             coverLetter != ""
                                                ? details[11].otherProjectOverview
                                                : ""
                                          }
                                          onChange={(value) => setCoverLetter(value)}
                                       /> */}
                        <textarea className="dkg-createcv-textarea"></textarea>
                      </div>
                      <div className="dkg-desired-save-btnCon">
                        <SubmitButton
                          txt="Save"
                          className="dkg-desired-save-btn btn"
                        />
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-experience"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">Work Experience</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon dkg-cv-preview-main">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {workexp}
                        </div>
                      </div>
                      <div className="dkg-cvpreview-center-sec">
                        <div className="dkg-cvpreview-textareaCon"></div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-education"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">Qualifications</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon dkg-cv-preview-main">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {qualifiction}
                        </div>
                      </div>
                      <div className="dkg-cvpreview-center-sec">
                        <div className="dkg-cvpreview-textareaCon"></div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-language"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">Jobs Skills</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon dkg-cv-preview-main">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {jobskills}
                        </div>
                      </div>
                      <div className="dkg-cvpreview-center-sec">
                        <div className="dkg-cvpreview-textareaCon"></div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-technical-skills"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">Tech Skills</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon dkg-cv-preview-main">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {techkills}
                        </div>
                      </div>
                      <div className="dkg-cvpreview-center-sec">
                        <div className="dkg-cvpreview-textareaCon"></div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-new-language"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">Languages</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon dkg-cv-preview-main">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {langkills}
                        </div>
                      </div>
                      <div className="dkg-cvpreview-center-sec">
                        <div className="dkg-cvpreview-textareaCon"></div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-cv-achievements"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">Achievements</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon dkg-cv-preview-main">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {achivements}
                        </div>
                      </div>
                      <div className="dkg-cvpreview-center-sec">
                        <div className="dkg-cvpreview-textareaCon"></div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-hobbies-interest"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">Hobbies & Interests</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon dkg-cv-preview-main">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {hobbies}
                        </div>
                      </div>
                      <div className="dkg-cvpreview-center-sec">
                        <div className="dkg-cvpreview-textareaCon"></div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-refrences"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">Refrences</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon dkg-cv-preview-main">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {refrence}
                        </div>
                      </div>
                      <div className="dkg-cvpreview-center-sec">
                        <div className="dkg-cvpreview-textareaCon"></div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="dkg-gdpr-consent"
                    className="dkg-aboutus-tabpane"
                  >
                    <div className="dkg-desiredjob-header">
                      <h1 className="dkg-contact-title">GDPR Consent</h1>
                      <span className="dkg-desired-info d-none">
                        <FaInfo />
                      </span>
                    </div>
                    <div className="dkg-desired-job-mainCon dkg-cv-preview-main">
                      <div className="dkg-desired-textarea-Con">
                        <div className="form-control dkg-desired-textarea">
                          {gdprConsent}
                        </div>
                      </div>
                      <div className="dkg-cvpreview-center-sec">
                        <div className="dkg-cvpreview-textareaCon"></div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default CoverLetter;
