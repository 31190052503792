import React from 'react';
import "./sendoutssummary.scss";

const SendoutsSummary = () => {
    return (
        <>
            <div className="dk-sendoutsSummary-mainContainer">
                <div className="dk-Summary-col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="dk-summaryFlex-main">
                                <div className="dk-summaryBox-12">
                                    <div className="title">YEAR <span>509</span></div>
                                    <ul></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">MONTH <span>509</span></div>
                                    <ul></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">ACCOUNT M <span>509</span></div>
                                    <ul></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">STATUS <span>509</span></div>
                                    <ul></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">PROCESS <span>509</span></div>
                                    <ul></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">STAGE <span>509</span></div>
                                    <ul></ul>
                                </div>
                            </div>

                            <div className="dk-summaryFlex-main">
                                <div className="dk-summaryBox-12">
                                    <div className="title">RECRUITER <span>509</span></div>
                                    <ul className="h-lg"></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">COMPANY <span>509</span></div>
                                    <ul className="h-lg"></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">LANGUAGE <span>509</span></div>
                                    <ul className="h-lg"></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">PROJECT <span>509</span></div>
                                    <ul className="h-lg"></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">ROLE TYPE <span>509</span></div>
                                    <ul className="h-lg"></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">CV Source <span>509</span></div>
                                    <ul className="h-lg"></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SendoutsSummary;
