import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import {
  // FaUserAlt,
  //FaSpinner,
  FaBuilding,
  FaGlobe,
  FaBook,
  FaEuroSign,
  FaRegMoneyBillAlt,
  FaRegCalendarAlt,
  // FaLanguage,
  // FaUserCheck,
  // FaRegFileAlt,
} from "react-icons/fa";

const SearchBanking = ({
  onHide,
  headerTitle,
  list,
  //selectUserFilter,
  selectOtherFilter,
}) => {
  // const [cvSourceList, setcvSourceList] = useState([]);
  // const [roleTypeList, setRoleTypeList] = useState([]);
  // const [languageList, setLanguageList] = useState([]);
  // const [projectLists, setProjectLists] = useState([]);

  const [servicesLists, setServicesLists] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [vatList, setVatList] = useState(0);
  const [monthsLists, setMonthsList] = useState([]);
  const [typeLists, setTypeList] = useState([]);
  const [categoryLists, setCategoryLists] = useState([]);
  const [plateformLists, setPlateformLists] = useState([]);
  //const [companyLists, setCompanyLists] = useState([]);
  // const [rcUserList, setRcUserList] = useState([]);
  // const [amUserList, setAMUserList] = useState([]);
  // const [progressLists, setProgressLists] = useState([]);
  // const [stageLists, setStageLists] = useState([]);
  //const [sumPaidIn, setSumPaidIn] = useState([]);

  const [paidInLists, setPaidIn] = useState(0);
  const [paidOutLists, setPaidOut] = useState(0);
  const [zoneList, setZoneList] = useState([]);

  // var sumPaidInList = paidInLists.filter(function (el) {
  //     return (el != null && el !="");
  //   });
  //   var sumPaidOutList = paidOutLists.filter(function (el) {
  //       return (el != null && el !='');
  //     });

  useEffect(() => {
    //     const paidInResult = [];
    //     for (const item of list) {
    //         paidInResult.push(item.original.paidIn)
    //     }
    //     setPaidIn(paidInResult)

    //     const paidOutResult = [];
    //     for (const item of list) {
    //         paidOutResult.push(item.original.paidOut)
    //     }
    //     setPaidOut(paidOutResult)

    const totalVat = [];
    for (const item of list) {
      totalVat.push(item.original.vatNo);
    }
    const vatsum = totalVat
      .filter((value) => value !== "" && !isNaN(parseFloat(value)))
      .map((value) => parseFloat(value))
      .reduce((acc, currentValue) => acc + currentValue, 0);
    setVatList(vatsum.toFixed(2));

    const totalinPaid = [];
    for (const item of list) {
      totalinPaid.push(item.original.paidIn);
    }
    const paidinsum = totalinPaid
      .filter((value) => value !== "" && !isNaN(parseFloat(value)))
      .map((value) => parseFloat(value))
      .reduce((acc, currentValue) => acc + currentValue, 0);
    setPaidIn(paidinsum.toFixed(2));

    const totalOutPaid = [];
    for (const item of list) {
      totalOutPaid.push(item.original.paidOut);
    }
    const paidOutsum = totalOutPaid
      .filter((value) => value !== "" && !isNaN(parseFloat(value)))
      .map((value) => parseFloat(value))
      .reduce((acc, currentValue) => acc + currentValue, 0);
    setPaidOut(paidOutsum.toFixed(2));

    const zoneResult = [];
    const zonesmap = new Map();
    for (const item of list) {
      if (!zonesmap.has(item.original.zone)) {
        zonesmap.set(item.original.zone, true);
        zoneResult.push({
          name: item.original.zone !== "" ? item.original.zone : "Blank",
          count: list.filter(function (val) {
            return val.original.zone === item.original.zone;
          }).length,
        });
      }
    }
    zoneResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setZoneList(zoneResult);

    const typeResult = [];
    const Typesmap = new Map();
    for (const item of list) {
      if (!Typesmap.has(item.original.accountType)) {
        Typesmap.set(item.original.accountType, true);
        typeResult.push({
          name:
            item.original.accountType !== ""
              ? item.original.accountType
              : "Blank",
          count: list.filter(function (val) {
            return val.original.accountType === item.original.accountType;
          }).length,
        });
      }
    }
    typeResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setTypeList(typeResult);

    const MonthsResult = [];
    const Monthssmap = new Map();
    for (const item of list) {
      if (!Monthssmap.has(item.original.month)) {
        Monthssmap.set(item.original.month, true);
        MonthsResult.push({
          name: item.original.month !== "" ? item.original.month : "Blank",
          count: list.filter(function (val) {
            return val.original.month === item.original.month;
          }).length,
        });
      }
    }
    MonthsResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setMonthsList(MonthsResult);

    const categoryResult = [];
    const categorymap = new Map();
    for (const item of list) {
      if (!categorymap.has(item.original.category)) {
        categorymap.set(item.original.category, true);
        categoryResult.push({
          name:
            item.original.category !== "" ? item.original.category : "Blank",
          count: list.filter(function (val) {
            return val.original.category === item.original.category;
          }).length,
        });
      }
    }
    categoryResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setCategoryLists(categoryResult);

    const plateformResult = [];
    const plateformmap = new Map();
    for (const item of list) {
      if (!plateformmap.has(item.original.plateform)) {
        plateformmap.set(item.original.plateform, true);
        plateformResult.push({
          name:
            item.original.plateform !== "" && item.original.plateform !== null
              ? item.original.plateform
              : "Blank",
          count: list.filter(function (val) {
            return val.original.plateform === item.original.plateform;
          }).length,
        });
      }
    }
    plateformResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setPlateformLists(plateformResult);

    const servicesResult = [];
    const servicesmap = new Map();
    for (const item of list) {
      if (!servicesmap.has(item.original.services)) {
        servicesmap.set(item.original.services, true);
        servicesResult.push({
          name:
            item.original.services !== "" ? item.original.services : "Blank",
          count: list.filter(function (val) {
            return val.original.services === item.original.services;
          }).length,
        });
      }
    }
    servicesResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setServicesLists(servicesResult);

    const businessResult = [];
    const businessmap = new Map();
    for (const item of list) {
      if (!businessmap.has(item.original.business)) {
        businessmap.set(item.original.business, true);
        businessResult.push({
          name:
            item.original.business !== "" ? item.original.business : "Blank",
          count: list.filter(function (val) {
            return val.original.business === item.original.business;
          }).length,
        });
      }
    }
    businessResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setBusinessList(businessResult);

    // const vatResult = [];
    // const vatmap = new Map();
    // for (const item of list) {
    //   if (!vatmap.has(item.original.vatNo)) {
    //     vatmap.set(item.original.vatNo, true);
    //     vatResult.push({
    //       name:
    //         item.original.vatNo !== "" && item.original.vatNo !== "-"
    //           ? item.original.vatNo
    //           : "Blank",
    //       count: list.filter(function (val) {
    //         return val.original.vatNo === item.original.vatNo;
    //       }).length,
    //     });
    //   }
    // }
    // vatResult.sort(function (a, b) {
    //   return b.count - a.count;
    // });
    // setVatList(vatResult);
  }, [list]);

  return (
    <>
      <Modal
        className="dk-sendoutsSearch-modal right"
        show={true}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>{headerTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="dk-sendoutFilterTabs-main">
            <Tab.Container id="left-tabs-example" defaultActiveKey="tab_type">
              <div className="dk-sendoutTabs-nav">
                <Nav variant="">
                  <Nav.Item>
                    <Nav.Link eventKey="tab_type">
                      <FaRegMoneyBillAlt /> Type
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tabs_1">
                      <FaRegCalendarAlt /> Month
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="plateform_tabs">
                      <FaRegCalendarAlt /> Plateform
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tabs_2">
                      <FaBuilding /> Category
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tabs_3">
                      <FaBook /> Services
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="tabs_4"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <FaBuilding />
                      Name of business
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="zone-tab"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <FaGlobe />
                      Zone
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tabs_5">
                      <FaBuilding />
                      Vat
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tabs_6">
                      <FaEuroSign />
                      Paid In
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tabs_7">
                      <FaEuroSign />
                      Paid Out
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="dk-sendoutTabs-filterPanel">
                <Tab.Content>
                  <Tab.Pane eventKey="tabs_1">
                    <div className="dk-panelMainContent">
                      <div className="dk-CategoryList">
                        <ul>
                          <li>
                            {monthsLists.map((item, index) =>
                              item.name == "January" ? (
                                <li
                                  key={`month${index}`}
                                  data-type="month"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  <Link
                                    to="#"
                                    data-type="month"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange"
                                      data-type="month"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : null
                            )}
                          </li>
                          <li>
                            {monthsLists.map((item, index) =>
                              item.name == "February" ? (
                                <li
                                  key={`month${index}`}
                                  data-type="month"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  <Link
                                    to="#"
                                    data-type="month"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange"
                                      data-type="month"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : null
                            )}
                          </li>
                          <li>
                            {monthsLists.map((item, index) =>
                              item.name == "March" ? (
                                <li
                                  key={`month${index}`}
                                  data-type="month"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  <Link
                                    to="#"
                                    data-type="month"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange"
                                      data-type="month"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : null
                            )}
                          </li>
                          <li>
                            {monthsLists.map((item, index) =>
                              item.name == "April" ? (
                                <li
                                  key={`month${index}`}
                                  data-type="month"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  <Link
                                    to="#"
                                    data-type="month"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange"
                                      data-type="month"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : null
                            )}
                          </li>
                          <li>
                            {monthsLists.map((item, index) =>
                              item.name == "May" ? (
                                <li
                                  key={`month${index}`}
                                  data-type="month"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  <Link
                                    to="#"
                                    data-type="month"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange"
                                      data-type="month"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : null
                            )}
                          </li>
                          <li>
                            {monthsLists.map((item, index) =>
                              item.name == "June" ? (
                                <li
                                  key={`month${index}`}
                                  data-type="month"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  <Link
                                    to="#"
                                    data-type="month"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange"
                                      data-type="month"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : null
                            )}
                          </li>
                          <li>
                            {monthsLists.map((item, index) =>
                              item.name == "July" ? (
                                <li
                                  key={`month${index}`}
                                  data-type="month"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  <Link
                                    to="#"
                                    data-type="month"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange"
                                      data-type="month"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : null
                            )}
                          </li>
                          <li>
                            {monthsLists.map((item, index) =>
                              item.name == "August" ? (
                                <li
                                  key={`month${index}`}
                                  data-type="month"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  <Link
                                    to="#"
                                    data-type="month"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange"
                                      data-type="month"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : null
                            )}
                          </li>
                          <li>
                            {monthsLists.map((item, index) =>
                              item.name == "September" ? (
                                <li
                                  key={`month${index}`}
                                  data-type="month"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  <Link
                                    to="#"
                                    data-type="month"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange"
                                      data-type="month"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : null
                            )}
                          </li>
                          <li>
                            {monthsLists.map((item, index) =>
                              item.name == "October" ? (
                                <li
                                  key={`month${index}`}
                                  data-type="month"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  <Link
                                    to="#"
                                    data-type="month"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange"
                                      data-type="month"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : null
                            )}
                          </li>
                          <li>
                            {monthsLists.map((item, index) =>
                              item.name == "November" ? (
                                <li
                                  key={`month${index}`}
                                  data-type="month"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  <Link
                                    to="#"
                                    data-type="month"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange"
                                      data-type="month"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : null
                            )}
                          </li>
                          <li>
                            {monthsLists.map((item, index) =>
                              item.name == "December" ? (
                                <li
                                  key={`month${index}`}
                                  data-type="month"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  <Link
                                    to="#"
                                    data-type="month"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange"
                                      data-type="month"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : null
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab_type">
                    <div className="dk-panelMainContent">
                      <div className="dk-CategoryList">
                        <ul>
                          {/* <li>
                                <li>
                                    <Link to="#" data-type="month">
                                        Income
                                        <span className="text-orange" data-type="month">45</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" data-type="month">
                                        Expense
                                        <span className="text-orange" data-type="month">0</span>
                                    </Link>
                                </li>
                            </li> */}
                          <li>
                            {typeLists.map((item, index) =>
                              item.name == "Income" ? (
                                <li
                                  key={`accountType${index}`}
                                  data-type="accountType"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  <Link
                                    to="#"
                                    data-type="accountType"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange"
                                      data-type="accountType"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : null
                            )}
                          </li>
                          <li>
                            {typeLists.map((item, index) =>
                              item.name == "Expense" ? (
                                <li
                                  key={`accountType${index}`}
                                  data-type="accountType"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  <Link
                                    to="#"
                                    data-type="accountType"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange"
                                      data-type="accountType"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : null
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tabs_2">
                    <div className="dk-panelMainContent">
                      <div className="dk-CategoryList">
                        <ul>
                          {categoryLists.map((item, index) => (
                            <li
                              key={`category${index}`}
                              data-type="taskType"
                              data-value={item.name}
                              onClick={selectOtherFilter}
                            >
                              <Link
                                to="#"
                                data-type="taskType"
                                data-value={item.name}
                                onClick={selectOtherFilter}
                              >
                                {item.name}
                                <span
                                  className="text-orange"
                                  data-type="taskType"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.count}
                                </span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="plateform_tabs">
                    <div className="dk-panelMainContent">
                      <div className="dk-CategoryList">
                        <ul>
                          {plateformLists.map((item, index) => (
                            <li
                              key={`plateform${index}`}
                              data-type="plateform"
                              data-value={item.name}
                              onClick={selectOtherFilter}
                            >
                              <Link
                                to="#"
                                data-type="plateform"
                                data-value={item.name}
                                onClick={selectOtherFilter}
                              >
                                {item.name}
                                <span
                                  className="text-orange"
                                  data-type="plateform"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.count}
                                </span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tabs_3">
                    <div className="dk-panelMainContent">
                      <div className="dk-CategoryList">
                        <ul>
                          {servicesLists.map((item, index) => (
                            <li
                              key={`services${index}`}
                              data-type="services"
                              data-value={item.name}
                              onClick={selectOtherFilter}
                            >
                              <Link
                                to="#"
                                data-type="services"
                                data-value={item.name}
                                onClick={selectOtherFilter}
                              >
                                {item.name}
                                <span
                                  className="text-orange"
                                  data-type="services"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.count}
                                </span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="zone-tab">
                    <div className="dk-panelMainContent">
                      <div className="dk-CategoryList">
                        <ul>
                          {zoneList.map((item, index) => (
                            <li
                              key={`zone${index}`}
                              data-type="zone"
                              data-value={item.name}
                              onClick={selectOtherFilter}
                            >
                              <Link
                                to="#"
                                data-type="zone"
                                data-value={item.name}
                                onClick={selectOtherFilter}
                              >
                                {item.name}
                                <span
                                  className="text-orange"
                                  data-type="zone"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.count}
                                </span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tabs_4">
                    <div className="dk-panelMainContent">
                      <div className="dk-CategoryList">
                        <ul>
                          {businessList.map((item, index) => (
                            <li
                              key={`business${index}`}
                              data-type="business"
                              data-value={item.name}
                              onClick={selectOtherFilter}
                            >
                              <Link
                                to="#"
                                data-type="business"
                                data-value={item.name}
                                onClick={selectOtherFilter}
                              >
                                {item.name}
                                <span
                                  className="text-orange"
                                  data-type="business"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.count}
                                </span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tabs_5">
                    <div className="dk-panelMainContent">
                      <div className="dk-CategoryList">
                        <ul>
                          <li key={`vatNo`}>
                            <Link to="#" data-type="vatNo">
                              <span className="text-orange" data-type="vatNo">
                                {vatList}
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tabs_6">
                    <div className="dk-panelMainContent">
                      <div className="dk-CategoryList">
                        <ul>
                          <li key={`paidIn_status`}>
                            <Link to="#" data-type="paidIn_status">
                              <span
                                className="text-orange"
                                data-type="paidIn_status"
                              >
                                {paidInLists}
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tabs_7">
                    <div className="dk-panelMainContent">
                      <div className="dk-CategoryList">
                        <ul>
                          <li key={`paidOut_status`}>
                            <Link to="#" data-type="paidOut_status">
                              <span
                                className="text-orange"
                                data-type="paidOut_status"
                              >
                                {paidOutLists}
                              </span>
                            </Link>
                          </li>

                          {/* <li>
                            {paidOutLists.map((item, index) => (
                              <li
                                key={`paidOut_status${index}`}
                                data-type="paidOut_status"
                                data-value={item.name}
                                onClick={selectOtherFilter}
                              >
                                <Link
                                  to="#"
                                  data-type="paidOut_status"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange"
                                    data-type="paidOut_status"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SearchBanking;
