import React from "react";
import { Link, Route } from "react-router-dom";
import "../../trainingdetail.scss";

const JobDetailLeftPanel = () => {
  let pathname = window.location.pathname;
  return (
    <React.Fragment>
      <ul>
        <Route>
          <li>
            <Link
              to="/training/recruiters/job-details"
              className={`${
                pathname.match("/training/recruiters/job-details")
                  ? "active"
                  : ""
              }`}
            >
              Overview
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/structure"
              className={`${
                pathname.match("/training/recruiters/structure") ? "active" : ""
              }`}
            >
              Structure
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/team-info"
              className={`${
                pathname.match("/training/recruiters/team-info") ? "active" : ""
              }`}
            >
              Team Info
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/job-skills"
              className={`${
                pathname.match("/training/recruiters/job-skills")
                  ? "active"
                  : ""
              }`}
            >
              Job Skills
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/job-task"
              className={`${
                pathname.match("/training/recruiters/job-task") ? "active" : ""
              }`}
            >
              Job Task
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/job-quality"
              className={`${
                pathname.match("/training/recruiters/job-quality")
                  ? "active"
                  : ""
              }`}
            >
              Job Quality
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/job-volume"
              className={`${
                pathname.match("/training/recruiters/job-volume")
                  ? "active"
                  : ""
              }`}
            >
              Job Volume
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/job-queries"
              className={`${
                pathname.match("/training/recruiters/job-queries")
                  ? "active"
                  : ""
              }`}
            >
              Job Queries
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/job-training"
              className={`${
                pathname.match("/training/recruiters/job-training")
                  ? "active"
                  : ""
              }`}
            >
              Job Training
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/job-delegation"
              className={`${
                pathname.match("/training/recruiters/job-delegation")
                  ? "active"
                  : ""
              }`}
            >
              Job Delegation
            </Link>
          </li>
        </Route>
      </ul>
    </React.Fragment>
  );
};

export default JobDetailLeftPanel;
