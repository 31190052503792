import React from 'react'
import { Link } from 'react-router-dom';
import './matchingjobs.scss';
const MatchingJobs = () => {
    return (
        <>
            <div className="dk-matchJobsCon">
                <div className="dk-machTopHead">
                    <div className="title">Matching With Live Jobs</div>
                </div>
                <div className="dk-matchTableCon">
                    <table className="table dk-matchTable">
                        <thead>
                            <tr>
                                <th>Sn.</th>
                                <th>Job ID</th>
                                <th>Status</th>
                                <th>Company</th>
                                <th>Title</th>
                                <th>Language</th>
                                <th>Project</th>
                                <th>Role Type</th>
                                <th>Jobs</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td><Link to="#" className="matchIdlink">2536585</Link></td>
                                <td>
                                    <span className="statusColumn">
                                        <Link href="#">Active</Link>
                                    </span>
                                </td>
                                <td>Dk Global Jobs</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td><Link to="#" className="matchIdlink">2536585</Link></td>
                                <td>
                                    <span className="statusColumn">
                                        <Link href="#">Active</Link>
                                    </span>
                                </td>
                                <td>Teleperformance</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td><Link to="#" className="matchIdlink">2536585</Link></td>
                                <td>
                                    <span className="statusColumn">
                                        <Link href="#">Active</Link>
                                    </span>
                                </td>
                                <td>Wipro</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>2</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default MatchingJobs;
