import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tab, Nav, Dropdown } from "react-bootstrap";
import "./jobspec-company.scss";
import "../../../elements/header/modal/candidateprescreen/inustructions/pt-questions.scss";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../components/utils/messages/messageSlice";
import {
  getCompanyFunction,
  clearState,
} from "../../../../slice/clientCompany/clientCompanySlice";

import CompanyTab from "./CompanyTab";
import ProjectTab from "./ProjectTab";
import RoletypeTab from "./RoletypeTab";
import VideoTab from "./VideoTab";
import AlbumsTab from "./AlbumsTab";
import MediaTab from "./MediaTab";

const DkgClientCompaines = () => {
  const dispatch = useDispatch();
  const { isLoading, companylist } = useSelector((state) => state.jobspecmp);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isLoading && companylist && companylist.length > 0) {
      setData(companylist);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCompanyFunction({}));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  const [companyyName, setCompanyyName] = useState("Select Company");
  // const [catRowId, setCatRowId] = useState(0);
  // const [activeEditTab, setActiveEditTab] = useState(false);
  const [comjobsp, setComjobsp] = useState([]);
  const [comvidjobsp, setComvidjobsp] = useState([]);
  const [comalbumjobsp, setComalbumjobsp] = useState([]);
  const [comcimgjobsp, setComimgjobsp] = useState([]);

  const selectCompany = async (
    catid,
    catName,
    cjobsp,
    cvidjobsp,
    calbumjobsp,
    cimgjobsp
  ) => {
    //setActiveSTab(true);
    setCompanyyName(catName);
    //setCatRowId(catid);
    //  setCatRowName(catName);
    setComjobsp(cjobsp ? cjobsp : []);
    setComvidjobsp(cvidjobsp ? cvidjobsp : []);
    setComalbumjobsp(calbumjobsp ? calbumjobsp : []);
    setComimgjobsp(cimgjobsp ? cimgjobsp : []);
    //setActiveEditTab(true);
    //console.log(cvidjobsp)
  };
  const [eventKey, SetEventKey] = useState("dkg-company-info");

  //console.log(data);

  return (
    <>
      <div className="dkg-jobspec-company-mainCon">
        <div className="dkg-jobspec-company-header">
          <div className="dkg-jobpsec-DropdownCon">
            <Dropdown className="dkg-jobpsec-Dropdown">
              <Dropdown.Toggle
                variant=""
                className="dkg-jobpsec-Dropdown-toggle"
              >
                {companyyName}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dkg-jobpsec-Dropdown-Menu">
                {data.map((item, i) => (
                  <Dropdown.Item
                    href="#"
                    className="dkg-jobpsec-Dropdown-Item"
                    key={i + 1}
                    onClick={() =>
                      selectCompany(
                        item.id,
                        item.categoryName,
                        item.companyJobSp,
                        item.companyVideoJobSp,
                        item.companyAlbumsJobSp,
                        item.companyImageJobSp
                      )
                    }
                  >
                    {item.categoryName}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* <div className="dkg-jobspec-company-title">{data[0]}</div> */}
        </div>
        {companyyName !== "Select Company" ? (
          <>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="jobpsec-company"
            >
              <div className="row dkg-jobspec-company-mainRow">
                <div className="col-2 dkg-jobspec-company-sideMenu">
                  <Nav
                    variant=""
                    className="dkg-jobspec-company-navpills flex-column"
                    onSelect={(selectedKey) => SetEventKey(selectedKey)}
                  >
                    <Nav.Item className="dkg-jobspec-company-navitem">
                      <Nav.Link
                        eventKey="jobpsec-company"
                        className="dkg-jobspec-company-navlink"
                      >
                        Company
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-jobspec-company-navitem">
                      <Nav.Link
                        eventKey="jobpsec-location"
                        className="dkg-jobspec-company-navlink"
                      >
                        Location 
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-jobspec-company-navitem">
                      <Nav.Link
                        eventKey="jobpsec-aboutus"
                        className="dkg-jobspec-company-navlink"
                      >
                        About us
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-jobspec-company-navitem">
                      <Nav.Link
                        eventKey="jobpsec-services"
                        className="dkg-jobspec-company-navlink"
                      >
                        Services
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-jobspec-company-navitem">
                      <Nav.Link
                        eventKey="jobpsec-videos"
                        className="dkg-jobspec-company-navlink"
                      >
                        Videos
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-jobspec-company-navitem">
                      <Nav.Link
                        eventKey="jobpsec-images"
                        className="dkg-jobspec-company-navlink"
                      >
                        Albums
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-jobspec-company-navitem">
                      <Nav.Link
                        eventKey="jobpsec-media"
                        className="dkg-jobspec-company-navlink"
                      >
                        Media
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="dkg-jobspec-rightCon col-md-10">
                  <Tab.Content className="dkg-jobspec-tabcontent">
                    <Tab.Pane
                      eventKey="jobpsec-company"
                      className="dkg-jobspec-tabpane"
                    >
                      <CompanyTab companyInfo={comjobsp} />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="jobpsec-aboutus"
                      className="dkg-jobspec-tabpane"
                    >
                      <ProjectTab companyAboutus={comjobsp} />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="jobpsec-services"
                      className="dkg-jobspec-tabpane"
                    >
                      <RoletypeTab companyService={comjobsp} />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="jobpsec-videos"
                      className="dkg-jobspec-tabpane"
                    >
                      <VideoTab compVideo={comvidjobsp} />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="jobpsec-images"
                      className="dkg-jobspec-tabpane"
                    >
                      <AlbumsTab
                        compAlbum={comalbumjobsp}
                        albumImgs={comcimgjobsp}
                      />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="jobpsec-media"
                      className="dkg-jobspec-tabpane"
                    >
                      <MediaTab companySocialMedia={comjobsp} />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </>
        ) : null}
      </div>
    </>
  );
};

export default DkgClientCompaines;
