import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const CaseDecisionDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/training/admins/close-decision" className={`${pathname.match('/training/admins/close-decision') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/training/admins/casedecision/situations" className={`${pathname.match('/training/admins/casedecision/situations') ? 'active' : ''}`}>Situations</Link></li>

                    <li><Link to="/training/admins/casedecision/task-rules" className={`${pathname.match('/training/admins/casedecision/task-rules') ? 'active' : ''}`}>Task Rules</Link></li>

                    <li><Link to="/training/admins/casedecision/how-to-do" className={`${pathname.match('/training/admins/casedecision/how-to-do') ? 'active' : ''}`}>How To Do</Link></li>

                    <li><Link to="/training/admins/casedecision/discussion" className={`${pathname.match('/training/admins/casedecision/discussion') ? 'active' : ''}`}>Discussion</Link></li>

                    <li><Link to="/training/admins/casedecision/escalation" className={`${pathname.match('/training/admins/casedecision/escalation') ? 'active' : ''}`}>Escalation</Link></li>

                    <li><Link to="/training/admins/casedecision/comments" className={`${pathname.match('/training/admins/casedecision/comments') ? 'active' : ''}`}>Comments</Link></li>

                    <li><Link to="/training/admins/casedecision/update-pipeline" className={`${pathname.match('/training/admins/casedecision/update-pipeline') ? 'active' : ''}`}>Update Pipeline</Link></li>

                    <li><Link to="/training/admins/casedecision/dkg-database" className={`${pathname.match('/training/admins/casedecision/dkg-database') ? 'active' : ''}`}>DKG Database</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default CaseDecisionDetailLeftPanel;

