import React from 'react';

const creditStage = ({ value }) => {

    let bgColor = "";
    switch (value) {

        case '71':
            bgColor = '#d9714e'
            break;
        case '72':
            bgColor = '#d9714e'
            break;

        case 'Case Closed':
            bgColor = '#da3d5e'
            break;

        case '74':
            bgColor = '#da3d5e'
            break;

        default:
            bgColor = '#da3d5e'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor, color: '#fff', minWidth: '80px' }}>{value}</div>
        </>
    )
}

export default creditStage;