import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData,DeleteData } from "../../services";

export const getKpiReportsList = createAsyncThunk(
    'refusedKpiReports/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/joined-kpi-report')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const addNewTask = createAsyncThunk(
    'refusedKpiReports/add-new',
    async ({ caseId}, thunkAPI) => {
        const res = await PostData(true, '/joined-kpi-report', { caseId })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const getPaidPaymentList = createAsyncThunk(
    'refusedKpiReports/paid/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/joined-kpi-report/paid')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const archivedData = createAsyncThunk(
    'refusedKpiReports/list/archive',
    async (thunkAPI) => {
        const res = await GetData(true, '/joined-kpi-report/archived')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateTask = createAsyncThunk(
    'refusedKpiReports/update',
    async ({ id, taskBy,taskFor,status,priority,invoiceNo,paymentType,paymentTo,paymentAmount,paymentNotes,dueTime,dueDate,paymentMonth, payType, attachment }, thunkAPI) => {
        const res = await PutData(true, '/joined-kpi-report/' + id, { taskBy,taskFor,status,priority,invoiceNo,paymentType,paymentTo,paymentAmount,paymentNotes,dueTime,dueDate,paymentMonth, payType, attachment })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateRowById = createAsyncThunk(
    'refusedKpiReports/update/assign',
    async ({ id,sendoutOn,months,am,rc,candidateName,progress,stage,company,project,roleType,language,cvSource }, thunkAPI) => {
        const res = await PutData(true, '/joined-kpi-report/' + id, {sendoutOn,months,am,rc,candidateName,progress,stage,company,project,roleType,language,cvSource })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateByKey = createAsyncThunk(
    'refusedKpiReports/update/key-value',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/joined-kpi-report/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateProgress = createAsyncThunk(
    'refusedKpiReports/update/key-value/progress',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/joined-kpi-report/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

// export const countAllTask = createAsyncThunk(
//     'refusedKpiReports/count/all',
//     async ({ status }, thunkAPI) => {
//         const res = await GetData(true, '/joined-kpi-report/count-all')
//         if (res.status === 200) {
//             return res.payload
//         } else {
//             return thunkAPI.rejectWithValue();
//         }
//     }
// );

export const deleteRecords = createAsyncThunk(
    'refusedKpiReports/delete',
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, '/joined-kpi-report/'+id )
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskMoveTO = createAsyncThunk(
    'refusedKpiReports/count/task-move',
    async ({ id }, thunkAPI) => {
        const res = await PostData(true, '/joined-kpi-report/bulk-move', { id })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const moveRecordsToPaid = createAsyncThunk(
    'refusedKpiReports/count/refusedKpiReports/move-to-table',
    async ({ id, status }, thunkAPI) => {
        const res = await PostData(true, '/joined-kpi-report/move-to-table', { id, status })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const counts = createAsyncThunk(
    'refusedKpiReports/count/by-progress',
    async ({ status }, thunkAPI) => {
        const res = await GetData(true, '/joined-kpi-report/count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const paymentCounts = createAsyncThunk(
    'refusedKpiReports/count/payment',
    async (thunkAPI) => {
        const res = await GetData(true, '/joined-kpi-report/payment-count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const paidPaymentCounts = createAsyncThunk(
    'refusedKpiReports/count/paidpayment',
    async (thunkAPI) => {
        const res = await GetData(true, '/joined-kpi-report/paid-payment-count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateJoinedCommentCount = createAsyncThunk(
    'refusedKpiReports/commentlist/update-comment',
    async ({ id, value, commentAt }, thunkAPI) => {
        const res = await PutData(true, '/joined-kpi-report/update-comment/' + id, { value, commentAt })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


export const taskSummaryList = createAsyncThunk(
    'refusedKpiReports/pipeline/summary-list',
    async (thunkAPI) => {
        const res = await GetData(true, '/joined-kpi-report/summary')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const detailsById = createAsyncThunk(
    'refusedKpiReports/assign/details',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/joined-kpi-report/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const listSlice = createSlice({
    name: 'joinedKpiReport',
    initialState: {
        joinedKpiReportList: [],
        detailsList: [],
        paidassignKpiReportList: [],
        taskDataSummaryList: [],
        taskTypeList: [],
        taskPaymentMonthList: [],
        taskPayTypeList: [],
        countsProgress: [],
        paymentDataCount: [],
        paidPaymentDataCount: [],
        tasksDetails: {},
       
        countAll: 0,
        isLoading: false,
        isDetailLoading: false,
        isTaskInsert: false,
        isTaskUpdate: false,
        isTaskDelete: false,
        isUpdate: false,
        iscommentCountUpdate: false,
        isError: false,
        isSuccess: false,
        isDataMoveToPayment: false,
        isInserted: false,
        isMoved: false,
        isArchive: false,
        message: ''
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isDetailLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.issSuccess = false;
            state.isDataMoveToPayment = false;
            state.isInserted = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },
        clearTaskState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.isInserted = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },
        clearDetails: (state) => {
            state.tasksDetails = {}
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            getPaidPaymentList.pending, (state) => {
                state.isLoading = true;
                //state.isUpdate = false;
                state.isDataMoveToPayment = false;
            });
        builder.addCase(
            getPaidPaymentList.fulfilled, (state, { payload }) => {
                state.paidassignKpiReportList = payload.result
                state.isLoading = false;
               // state.isUpdate = true;
                state.isDataMoveToPayment = true;
            });
        builder.addCase(
            getPaidPaymentList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            getKpiReportsList.pending, (state) => {
                state.isLoading = true;
                state.issSuccess = false;
            });
        builder.addCase(
            getKpiReportsList.fulfilled, (state, { payload }) => {
                state.joinedKpiReportList = payload.result
                state.isLoading = false;
                state.issSuccess = true;
            });
        builder.addCase(
            getKpiReportsList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            archivedData.pending, (state) => {
                state.isLoading = true;
                state.isArchive = false;
            });
        builder.addCase(
            archivedData.fulfilled, (state, { payload }) => {
                state.joinedKpiReportList = payload.result
                state.isArchive = true;
                state.isLoading = false;
            });
        builder.addCase(
            archivedData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addNewTask.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            addNewTask.fulfilled, (state, { payload }) => {
                state.joinedKpiReportList.unshift(payload.result)
                state.isLoading = false;
                state.isSuccess = true;
                state.isInserted = true;
            });
        builder.addCase(
            addNewTask.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateRowById.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            updateRowById.fulfilled, (state, action) => {        
                state.joinedKpiReportList = state.joinedKpiReportList.filter((item) => item.id !== action.meta.arg.id)
                state.isUpdate = true

            });
        builder.addCase(
            updateRowById.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            updateTask.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            updateTask.fulfilled, (state, { payload }) => {
                let mainArray = state.joinedKpiReportList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result[0].id) {
                        return (payload.result[0])
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.isLoading = false;
                state.joinedKpiReportList = finalResult;
            });
        builder.addCase(
            updateTask.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            updateByKey.pending, (state) => {
                state.isTaskUpdate = false
            });
        builder.addCase(
            updateByKey.fulfilled, (state, { payload }) => {
                let mainArray = state.joinedKpiReportList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isTaskUpdate = true
                state.joinedKpiReportList = finalResult;
                state.message = payload.message
            });
        builder.addCase(
            updateByKey.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateProgress.pending, (state) => {
            });
        builder.addCase(
            updateProgress.fulfilled, (state, action) => {
                state.isUpdate = true                
                state.joinedKpiReportList = state.joinedKpiReportList.filter((item) => item.id !== action.meta.arg.id)
            });
        builder.addCase(
            updateProgress.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            counts.pending, (state) => {
            });
        builder.addCase(
            counts.fulfilled, (state, { payload }) => {
                state.countsProgress = payload.result
            });
        builder.addCase(
            counts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            paidPaymentCounts.pending, (state) => {

            });
        builder.addCase(
            paidPaymentCounts.fulfilled, (state, { payload }) => {
                state.paidPaymentDataCount = payload.result
                //state.isArchive = true;
            });
        builder.addCase(
            paidPaymentCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            paymentCounts.pending, (state) => {

            });
        builder.addCase(
            paymentCounts.fulfilled, (state, { payload }) => {
                state.paymentDataCount = payload.result
                //state.isArchive = true;
            });
        builder.addCase(
            paymentCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        

        builder.addCase(
            deleteRecords.pending, (state) => {
                state.isJobSpecDelete = false;
            });
        builder.addCase(
            deleteRecords.fulfilled, (state, { payload }) => {
                state.isJobSpecDelete = true;
                
            });
        builder.addCase(
            deleteRecords.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            taskMoveTO.pending, (state) => {
            });
        builder.addCase(
            taskMoveTO.fulfilled, (state, { payload }) => {
                state.isTaskDelete = true;
                state.message = payload.message
                state.isMoved = true;
            });
        builder.addCase(
            taskMoveTO.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            moveRecordsToPaid.pending, (state) => {
            });
        builder.addCase(
            moveRecordsToPaid.fulfilled, (state, { payload }) => {
                state.isMoved = true;
                state.message = payload.message
            });
        builder.addCase(
            moveRecordsToPaid.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateJoinedCommentCount.pending, (state) => {
                state.iscommentCountUpdate = false
            });
        builder.addCase(
            updateJoinedCommentCount.fulfilled, (state, action) => {
                let mainArray = state.joinedKpiReportList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === action.meta.arg.id) {
                        return ({ ...r, commentCount: action.meta.arg.value, commentAt: action.meta.arg.commentAt })
                    } else {
                        return r
                    }
                })
                state.joinedKpiReportList = finalResult             
                state.iscommentCountUpdate = true
            });
        builder.addCase(
            updateJoinedCommentCount.rejected, (state, action) => {
                state.isError = true;
            });

      
        builder.addCase(
            taskSummaryList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            taskSummaryList.fulfilled, (state, { payload }) => {
                state.taskDataSummaryList = payload.result;
                state.isLoading = false;
            });
        builder.addCase(
            taskSummaryList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            detailsById.pending, (state) => {
                state.isDetailLoading = true
            });
        builder.addCase(
            detailsById.fulfilled, (state, { payload }) => {
                state.isDetailLoading = false
                state.detailsList = payload.result;
            });
        builder.addCase(
            detailsById.rejected, (state, action) => {
                state.isDetailLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
    }
});


export const { clearState, clearTaskState, clearDetails } = listSlice.actions;
export default listSlice.reducer;