import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import RecruiterImG from "../../../../assets/images/deepak.png";
import './savings.scss';
import AddNumbers from './AddNumbers';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
//import './clientskpi.scss';
import { useTable } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import { businessSavingList, clearState } from "../../../../slice/businessSaving/businessSlice";
import TableLoader from "../../../ui/tableLoader";
import $ from "jquery";

const Savings = () => {
    const dispatch = useDispatch();
    const { businessList, isLoading, isUpdate, isError, errorMessage } = useSelector(state => state.business);

    const [addNumbersModal, setIsAddNumbersModal] = useState(false)

    const [rowId, setRowId] = useState('0')
    const [columnKey, setColumnKey] = useState('')
    const [columnValue, setColumnValue] = useState('0')
    //const [isUpdatePop, setIsUpdatePop] = useState(false)

    const showAddNumbersModal = (id, key, value) => {
        setRowId(id)
        setColumnKey(key)
        setColumnValue(value)
        setIsAddNumbersModal(true)
    }
    const hideAddNumbersModal = () => {
        setIsAddNumbersModal(false)
    }
    const [businesss, setBusinessList] = useState([]);
    const businesssRef = useRef();
    businesssRef.current = businesss;

    useEffect(() => {
        dispatch(businessSavingList())
    }, [])

    useEffect(() => {
        if (!isLoading && businessList) {
            setBusinessList(businessList)
        }
    }, [])

    return (
        <>
            {
                addNumbersModal ?
                    <AddNumbers handleClose={hideAddNumbersModal} onHide={hideAddNumbersModal} rowId={rowId} columnKey={columnKey} value={columnValue} /> : null
            }

            <div className="dk-savingsMain">
                <div className="dk-savingsHead">
                    <div className="d-flex align-items-center justify-content-center">
                        <h3 className="title">BUSINESS SAVINGS 2021</h3>
                    </div>
                </div>
                <div className="dk-savingsTable">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Sno.</th>
                                    <th>Month</th>
                                    <th>Recruiter</th>
                                    <th>No of Offers</th>
                                    <th>Job Offers</th>
                                    <th>Refused</th>
                                    <th>Credit</th>
                                    <th>Bonus</th>
                                    <th>Net Saving</th>
                                    <th>Target Saving</th>
                                </tr>
                            </thead>
                            <tbody>
                                {businessList.map((item, index) =>
                                    <tr key={index + 1}>
                                        <td>{index + 1}</td>
                                        <td>{item.month}</td>
                                        <td>
                                            <div className="dk-recruiterImG">
                                                <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + "users/5c041fb96042e.png"} alt={item.recruiters.firstName} />
                                            </div>
                                        </td>
                                        <td>
                                            <span onClick={() => showAddNumbersModal(item._id, 'noOfOffers', item.noOfOffers)}>{item.noOfOffers}</span>
                                        </td>
                                        <td>
                                            <span onClick={() => showAddNumbersModal(item._id, 'jobOffers', item.jobOffers)} >€ {item.jobOffers}</span>
                                        </td>
                                        <td>
                                            <span onClick={() => showAddNumbersModal(item._id, 'refused', item.refused)} >€ {item.refused}</span>
                                        </td>
                                        <td>
                                            <span onClick={() => showAddNumbersModal(item._id, 'credit', item.credit)} >€ {item.credit}</span>
                                        </td>
                                        <td>
                                            <span onClick={() => showAddNumbersModal(item._id, 'bonus', item.bonus)} >€ {item.bonus}</span>
                                        </td>
                                        <td>
                                            <span onClick={() => showAddNumbersModal(item._id, 'netSaving', item.netSaving)} >€ {item.netSaving}</span>
                                        </td>
                                        <td>
                                            <span onClick={() => showAddNumbersModal(item._id, 'targetSaving', item.targetSaving)} >€ {item.targetSaving}</span>
                                        </td>
                                    </tr>
                                )}

                            </tbody>
                            <tbody className="sumtr">
                                <tr>
                                    <td colSpan="3" className="transplant"></td>
                                    <td className="totalCount">
                                        {
                                            businessList.reduce((a, v) => a = a + Number(v.noOfOffers), 0)
                                        }
                                    </td>
                                    <td className="totalCount">€ {
                                        businessList.reduce((a, v) => a = a + Number(v.jobOffers), 0)
                                    }</td>
                                    <td className="totalCount">€ {
                                        businessList.reduce((a, v) => a = a + Number(v.refused), 0)
                                    }</td>
                                    <td className="totalCount">€ {
                                        businessList.reduce((a, v) => a = a + Number(v.credit), 0)
                                    }</td>
                                    <td className="totalCount">€ {
                                        businessList.reduce((a, v) => a = a + Number(v.bonus), 0)
                                    }</td>
                                    <td className="totalCount">€ {
                                        businessList.reduce((a, v) => a = a + Number(v.netSaving), 0)
                                    }</td>
                                    <td className="totalCount">€ {
                                        businessList.reduce((a, v) => a = a + Number(v.targetSaving), 0)
                                    }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Savings;
