import React from "react";
import { Route, Link } from "react-router-dom";

const BankAccSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/my-files/bank-account/bank-account1" className={`${(segment3 == 'bank-account1') ? 'active' : ''}`}>Contact 1</Link></li>
                        <li><Link to="#/my-files/bank-account/bank-account2" className={`${(segment3 == 'bank-account2') ? 'active' : ''}`}>Contact 2</Link></li>
                        <li><Link to="#/my-files/bank-account/bank-account3" className={`${(segment3 == 'bank-account3') ? 'active' : ''}`}>Contact 3</Link></li>
                        <li><Link to="#/my-files/bank-account/bank-account4" className={`${(segment3 == 'bank-account4') ? 'active' : ''}`}>Contact 4</Link></li>
                        <li><Link to="#/my-files/bank-account/bank-account5" className={`${(segment3 == 'bank-account5') ? 'active' : ''}`}>Contact 5</Link></li>
                        <li><Link to="#/my-files/bank-account/bank-account6" className={`${(segment3 == 'bank-account6') ? 'active' : ''}`}>Contact 6</Link></li>
                        <li><Link to="#/my-files/bank-account/bank-account7" className={`${(segment3 == 'bank-account7') ? 'active' : ''}`}>Contact 7</Link></li>
                        <li><Link to="#/my-files/bank-account/bank-account8" className={`${(segment3 == 'bank-account8') ? 'active' : ''}`}>Contact 8</Link></li>
                        <li><Link to="#/my-files/bank-account/bank-account9" className={`${(segment3 == 'bank-account9') ? 'active' : ''}`}>Contact 9</Link></li>
                        <li><Link to="#/my-files/bank-account/bank-account10" className={`${(segment3 == 'bank-account10') ? 'active' : ''}`}>Contact 10</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default BankAccSubmenu;
