import React from "react";
import { Route, Link } from "react-router-dom";

const BReturnSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/my-files/b1-returns/b1-return1" className={`${(segment3 == 'b1-return1') ? 'active' : ''}`}>B1 Returns 1</Link></li>
                        <li><Link to="#/my-files/b1-returns/b1-return2" className={`${(segment3 == 'b1-return2') ? 'active' : ''}`}>B1 Returns 2</Link></li>
                        <li><Link to="#/my-files/b1-returns/b1-return3" className={`${(segment3 == 'b1-return3') ? 'active' : ''}`}>B1 Returns 3</Link></li>
                        <li><Link to="#/my-files/b1-returns/b1-return4" className={`${(segment3 == 'b1-return4') ? 'active' : ''}`}>B1 Returns 4</Link></li>
                        <li><Link to="#/my-files/b1-returns/b1-return5" className={`${(segment3 == 'b1-return5') ? 'active' : ''}`}>B1 Returns 5</Link></li>
                        <li><Link to="#/my-files/b1-returns/b1-return6" className={`${(segment3 == 'b1-return6') ? 'active' : ''}`}>B1 Returns 6</Link></li>
                        <li><Link to="#/my-files/b1-returns/b1-return7" className={`${(segment3 == 'b1-return7') ? 'active' : ''}`}>B1 Returns 7</Link></li>
                        <li><Link to="#/my-files/b1-returns/b1-return8" className={`${(segment3 == 'b1-return8') ? 'active' : ''}`}>B1 Returns 8</Link></li>
                        <li><Link to="#/my-files/b1-returns/b1-return9" className={`${(segment3 == 'b1-return9') ? 'active' : ''}`}>B1 Returns 9</Link></li>
                        <li><Link to="#/my-files/b1-returns/b1-return10" className={`${(segment3 == 'b1-return10') ? 'active' : ''}`}>B1 Returns 10</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default BReturnSubmenu;
