import React from 'react';
import './marketingdashboard.scss';

const MarketingDashboard = () => {
    return (
        <>
            <div className="dk-adminMarketingDashboard-main">
                <h2>Marketing Dashboard</h2>
            </div>
        </>
    )
}

export default MarketingDashboard;
