import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const CallsDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="#" className={`${pathname.match('/training/commontraining') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/business') ? 'active' : ''}`}>Agenda</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/industry') ? 'active' : ''}`}>Preparation</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/services') ? 'active' : ''}`}>Caller Mood</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/clients') ? 'active' : ''}`}>Phone No.</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/suppliers') ? 'active' : ''}`}>Call Tools</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/completion') ? 'active' : ''}`}>Call Cost</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Call Tries</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Call Scripts</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Call Duration</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Call Demos</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Call Notes</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Recordings</Link></li>


                </Route>
            </ul>
        </React.Fragment>
    )
}

export default CallsDetailLeftPanel;

