import React from 'react';
import './otherinfo.scss';

const OtherInfo = () => {
    return (
        <>
            <div className="other-InfoMainCon">
                <div className="col-md-12 pl-0 pr-0">
                    <div className="title mb-2 ml-2">Executive summary</div>
                    <div className="dk-skillsCols mb-4 ml-2">
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, soluta repellendus! Porro obcaecati quaerat dicta sunt dolorem inventore, neque ut molestias? Corporis at natus, beatae aliquid ipsum laborum ut.
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt ipsa ex iste sit minus provident consequatur eius nisi voluptates, nam hic et laudantium assumenda aut quia odio eos.
                        </p>
                    </div>
                    <div className="title mb-2 ml-2">Management summary</div>
                    <div className="dk-skillsCols mb-4 ml-2">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, soluta repellendus! Porro obcaecati quaerat dicta sunt dolorem inventore, neque ut molestias? Corporis at natus, beatae aliquid ipsum laborum.</p>
                    </div>
                    <div className="title mb-2 ml-2">Hobbies</div>
                    <div className="dk-skillsCols mb-4 ml-2">
                        <p>
                            Up to date Knowledge with the latest industry trends in Graphic design and usability. ❖ Graphic Designing ❖ Web Designing Expert in handling the challenging projects in a professional way. ❖ E-mailer Designing ❖
                            Video Editing Strong communication, problem-solving, and planning skills, with ability to ❖ Social Media Event work independently or in a team environment. ❖ Event Arrangement ❖ Web Implementation ❖ Template
                            Designing
                        </p>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="title mb-3 ml-2">Other Informations</div>
                    <table className="table dk-otherInfoTable">
                        <tbody>
                            <tr>
                                <td>Resume File Name</td>
                                <td>:</td>
                                <td>Resume.pdf</td>
                            </tr>
                            <tr>
                                <td>Resume Language</td>
                                <td>:</td>
                                <td>English(en)</td>
                            </tr>
                            <tr>
                                <td>Parsing Date</td>
                                <td>:</td>
                                <td>22/01/2021 3:06:20</td>
                            </tr>
                            <tr>
                                <td>Category</td>
                                <td>:</td>
                                <td>Information Technology</td>
                            </tr>
                            <tr>
                                <td>SubCategory</td>
                                <td>:</td>
                                <td>Software Developers and Programmers</td>
                            </tr>
                            <tr>
                                <td>Worked Period in Month</td>
                                <td>:</td>
                                <td>61</td>
                            </tr>
                            <tr>
                                <td>Worked Period in Year</td>
                                <td>:</td>
                                <td>5.1</td>
                            </tr>
                            <tr>
                                <td>Worked Period Range</td>
                                <td>:</td>
                                <td>5-8 Year</td>
                            </tr>
                            <tr>
                                <td>Average Stay</td>
                                <td>:</td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>Longest Stay</td>
                                <td>:</td>
                                <td>31</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default OtherInfo;
