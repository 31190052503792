import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const PrMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalAdminPrMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/dkglobaladmin/pr/dashboard" className={(segment2 == 'pr') ? 'active' : ''}>Dashboard</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'structure') ? '' : ''}`}>PR 1</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'teams') ? '' : ''}`}>PR 2</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'pipelines') ? '' : ''}`}>PR 3</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-1') ? '' : ''}`}>PR 4</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-2') ? '' : ''}`}>PR 5</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-3') ? '' : ''}`}>PR 6</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-4') ? '' : ''}`}>PR 7</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-5') ? '' : ''}`}>PR 8</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-5') ? '' : ''}`}>PR 9</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-5') ? '' : ''}`}>PR 10</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default PrMenu;