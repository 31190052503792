import React, { useEffect, useLayoutEffect, useState } from 'react';
import './paytaskssummary.scss';
import { useDispatch, useSelector } from "react-redux";
import { clearState, paymentSummary } from "../../../slice/task/paymentTaskSlice";

const PayTasksSummary = () => {
    const dispatch = useDispatch();
    const { isLoading, paymentListSummary } = useSelector(state => state.paymentTask);

    const [isReasonFilter, setIsReasonFilter] = useState(false);
    const [reasonFilterText, setReasonFilterText] = useState('');

    const [isYearFilter, setIsYearFilter] = useState(false);
    const [yearFilterText, setYearFilterText] = useState('');

    const [isMonthsFilter, setIsMonthsFilter] = useState(false);
    const [monthsFilterText, setMonthsFilterText] = useState('');

    const [isCaseStatusFilter, setIsCaseStatusFilter] = useState(false);
    const [caseStatusFilterText, setCaseStatusFilterText] = useState('');

    const [isReportFilter, setIsReportFilter] = useState(false);
    const [reportFilterText, setReportFilterText] = useState('');

    const [isCaseProgressFilter, setIsCaseProgressFilter] = useState(false);
    const [caseProgressFilterText, setCaseProgressFilterText] = useState('');

    const [isStageFilter, setIsStageFilter] = useState(false);
    const [stageFilterText, setStageFilterText] = useState('');

    const [isLanguageFilter, setIsLanguageFilter] = useState(false);
    const [languageFilterText, setLanguageFilterText] = useState('');

    const [isForNameFilter, setIsForNameFilter] = useState(false);
    const [isForNameFilterText, setForNameFilterText] = useState('');

    const [isCategoryFilter, setIsCategoryFilter] = useState(false);
    const [categoryFilterText, setCategoryFilterText] = useState('');

    const [isPriorityFilter, setIsPriorityFilter] = useState(false);
    const [priorityFilterText, setPriorityText] = useState('');

    const [isTaskCreateFilter, setIsTaskCreateFilter] = useState(false);
    const [taskCreateFilterText, setTaskCreateFilterText] = useState('');

    const [isTaskDueFilter, setIsTaskDueFilter] = useState(false);
    const [taskDueFilterText, setTaskDueFilterText] = useState('');

    const [isPayTypeFilter, setIsPayTypeFilterFilter] = useState(false);
    const [paytypeFilterText, setPayTypeFilterText] = useState('');

    const [isProjectFilter, setIsProjectFilter] = useState(false);
    const [projectFilterText, setProjectFilterText] = useState('');

    const [isRoleTypeFilter, setIsRoleTypeFilter] = useState(false);
    const [roleTypeFilterText, setRoleTypeFilterText] = useState('');

    const [isCvSourceFilter, setIsCvSourceFilter] = useState(false);
    const [cvSourceFilterText, setCvSourceFilterText] = useState('');

    const [isCreatedByFilter, setIsCreatedByFilter] = useState(false);
    const [isCreatedImgFilter, setIsCreatedByImgFilter] = useState('');
    const [createdByIdFilterText, setIsCreatedByFilterText] = useState('');

    const [isPaymentToFilter, setIsPaymentToFilter] = useState(false);
    const [isPaymentToImgFilter, setIsPaymentToImgFilter] = useState('');
    const [paymentToIdFilterText, setIsPaymentToFilterText] = useState('');


    const [isRecruiterFilter, setIsRecruiterFilter] = useState(false);
    const [recruiterFilterText, setRecruiterFilterText] = useState('');
    const [recruiterImgFilter, setRecruiterImgFilter] = useState('');

    const [filter, setFilter] = useState({});
    const [filterTags, setFilterTags] = useState([]);
    const [forNameList, setForNameList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [taskCreateList, setTaskCreateList] = useState([]);

    const [taskdueList, setTaskDueList] = useState([]);
    const [paytypeList, setPayTypeList] = useState([]);
    const [priorityList, setPriorityList] = useState([]);

    const [list, setList] = useState([]);
    const [reportTypelist, setReportTypeList] = useState([]);
    const [flag, setFlag] = useState(0);

    const [byUserList, setByUserList] = useState([]);
    const [toUserList, setToUserList] = useState([]);

    const { userInfo } = useSelector(state => state.auth);

    useLayoutEffect(() => {
        if (!isLoading && paymentListSummary.length > 0) {
            setList(paymentListSummary)
            setReportTypeList(paymentListSummary)

            // if(userInfo.role==="Admin" || userInfo.role==="User"){
            //     setList(paymentListSummary.filter(e=>e.taskBy==userInfo.id))
            //     setReportTypeList(paymentListSummary.filter(e=>e.taskBy==userInfo.id))
            // }
        }

    }, [paymentListSummary, isLoading])



    useEffect(() => {
        const fetchList = async () => {
            try {
                // setList([])
                await dispatch(paymentSummary({ roletype: userInfo.role, taskById: userInfo.id }))
            } catch (err) {
            }
        }
        fetchList()

    }, [dispatch])

    function filterBy(list, criteria) {
        return list.filter(candidate =>
            Object.keys(criteria).every(key =>
                candidate[key] === criteria[key]
            )
        );
    }

    const selectFilter = (e) => {

        const type = e.target.dataset.type;
        const value = e.target.dataset.value;

        switch (type) {

            case 'byId':
                setIsCreatedByFilter(true)
                setIsCreatedByImgFilter(e.target.dataset.img)
                setIsCreatedByFilterText(e.target.dataset.name)
                setFilter({ ...filter, ['byId']: Number(e.target.dataset.value) })
                break;
            case 'toId':
                setIsPaymentToFilter(true)
                setIsPaymentToImgFilter(e.target.dataset.img)
                setIsPaymentToFilterText(e.target.dataset.name)
                setFilter({ ...filter, ['toId']: Number(e.target.dataset.value) })
                break;

            case 'recruiters':
                setIsRecruiterFilter(true)
                setRecruiterImgFilter(e.target.dataset.img)
                setRecruiterFilterText(e.target.dataset.name)
                setFilter({ ...filter, ['recruiters']: Number(e.target.dataset.value) })
                break;

            case 'reason':
                if (value == "") {
                    setReasonFilterText("Blank")
                } else {
                    setReasonFilterText(value)
                }
                setIsReasonFilter(true)
                setFilter({ ...filter, ['reason']: e.target.dataset.value })
                break;

            case 'year':
                if (value == "") {
                    setYearFilterText("Blank")
                } else {
                    setYearFilterText(value)
                }
                setIsYearFilter(true)
                setFilter({ ...filter, ['year']: e.target.dataset.value })
                break;

            case 'month':
                if (value == "") {
                    setMonthsFilterText("Blank")
                } else {
                    setMonthsFilterText(value)
                }
                setIsMonthsFilter(true)
                setFilter({ ...filter, ['month']: e.target.dataset.value })
                break;

            case 'status':
                if (value == "") {
                    setCaseStatusFilterText("Blank")
                } else {
                    setCaseStatusFilterText(value)
                }
                setIsCaseStatusFilter(true)
                setFilter({ ...filter, ['status']: e.target.dataset.value })
                break;

            case 'progress':
                if (value == "") {
                    setCaseProgressFilterText("Blank")
                } else {
                    setCaseProgressFilterText(value)
                }
                setIsCaseProgressFilter(true)
                setFilter({ ...filter, ['progress']: e.target.dataset.value })
                break;

            case 'caseStage':
                setIsStageFilter(true)
                setStageFilterText(e.target.dataset.value)
                setFilter({ ...filter, ['caseStage']: e.target.dataset.value })
                break;

            case 'language':
                setIsLanguageFilter(true)
                setLanguageFilterText(e.target.dataset.value)
                setFilter({ ...filter, ['language']: e.target.dataset.value })
                break;

            case 'paymentType':
                setIsCategoryFilter(true)
                setCategoryFilterText(e.target.dataset.value)
                setFilter({ ...filter, ['paymentType']: e.target.dataset.value })
                break;

            case 'paymentTo':
                setIsForNameFilter(true)
                setForNameFilterText(e.target.dataset.value)
                setFilter({ ...filter, ['paymentTo']: e.target.dataset.value })
                break;

            case 'createdOn':
                setIsTaskCreateFilter(true)
                setTaskCreateFilterText(e.target.dataset.value)
                setFilter({ ...filter, ['createdOn']: e.target.dataset.value })
                break;
            case 'dueDate':
                setIsTaskDueFilter(true)
                setTaskDueFilterText(e.target.dataset.value)
                setFilter({ ...filter, ['dueDate']: e.target.dataset.value })
                break;
            case 'payType':
                setIsPayTypeFilterFilter(true)
                setPayTypeFilterText(e.target.dataset.value)
                setFilter({ ...filter, ['payType']: e.target.dataset.value })
                break;
            case 'priority':
                setIsPriorityFilter(true)
                setPriorityText(e.target.dataset.value)
                setFilter({ ...filter, ['priority']: e.target.dataset.value })
                break;

            case 'project':
                setIsProjectFilter(true)
                setProjectFilterText(e.target.dataset.value)
                setFilter({ ...filter, ['project']: e.target.dataset.value })
                break;

            case 'roleType':
                setIsRoleTypeFilter(true)
                setRoleTypeFilterText(e.target.dataset.value)
                setFilter({ ...filter, ['roleType']: e.target.dataset.value })
                break;

            case 'cvSource':
                setIsCvSourceFilter(true)
                setCvSourceFilterText(e.target.dataset.value)
                setFilter({ ...filter, ['cvSource']: e.target.dataset.value })
                break;

            default:
                //setFlag(1)
                setIsReportFilter(true)
                setReportFilterText(e.target.dataset.value)
                setList(paymentListSummary)
                //  setFilter({ ...filter, ['caseStatus']: e.target.dataset.value })
                break;
        }
    }
    const resetAllFilter = async (e) => {
        setFlag(0)
        setIsReportFilter(false)
        setReportFilterText('')
        setList([])
        setFilter({})
        setFilterTags([])
        setIsCreatedByFilter(false)
        setIsCreatedByFilterText('')

        setIsRecruiterFilter(false)
        setRecruiterFilterText('')

        setIsReasonFilter(false)
        setReasonFilterText('')

        setIsYearFilter(false)
        setYearFilterText('')

        setIsMonthsFilter(false)
        setMonthsFilterText('')

        setIsCaseStatusFilter(false)
        setCaseStatusFilterText('')

        setIsCaseProgressFilter(false)
        setCaseProgressFilterText('')

        setIsStageFilter(false)
        setStageFilterText('')

        setIsLanguageFilter(false)
        setLanguageFilterText('')

        setIsCategoryFilter(false)
        setCategoryFilterText('')

        setIsForNameFilter(false)
        setForNameFilterText('')

        setIsPriorityFilter(false)
        setPriorityText('')

        setIsTaskCreateFilter(false)
        setIsTaskDueFilter(false)
        setTaskDueFilterText('')
        setTaskCreateFilterText('')
        setIsPayTypeFilterFilter(false)
        setPayTypeFilterText('')

        setIsProjectFilter(false)
        setProjectFilterText('')

        setIsRoleTypeFilter(false)
        setRoleTypeFilterText('')

        setIsCvSourceFilter(false)
        setCvSourceFilterText('')

    }
    const removeTag = async (e) => {
        const type = e.target.dataset.type
        delete filter[type]


        switch (type) {

            case 'byId':
                setIsCreatedByFilter(false)
                setIsCreatedByFilterText('')
                break;
            case 'toId':
                setIsPaymentToFilter(false)
                setIsPaymentToFilterText('')
                break;

            case 'recruiters':
                setIsRecruiterFilter(false)
                setRecruiterFilterText('')
                break;

            case 'reason':
                setIsReasonFilter(false)
                setReasonFilterText('')
                break;
            case 'year':
                setIsYearFilter(false)
                setYearFilterText('')
                break;

            case 'month':
                setIsMonthsFilter(false)
                setMonthsFilterText('')
                break;

            case 'status':
                setIsCaseStatusFilter(false)
                setCaseStatusFilterText('')
                break;

            case 'progress':
                setIsCaseProgressFilter(false)
                setCaseProgressFilterText('')
                break;

            case 'caseStage':
                setIsStageFilter(false)
                setStageFilterText('')
                break;

            case 'language':
                setIsLanguageFilter(false)
                setLanguageFilterText('')
                break;

            case 'paymentType':
                setIsCategoryFilter(false)
                setCategoryFilterText('')
                break;
            case 'paymentTo':
                setIsForNameFilter(false)
                setForNameFilterText('')
                break;

            case 'createdOn':
                setIsTaskCreateFilter(false)
                setTaskCreateFilterText('')
                break;
            case 'dueDate':
                setIsTaskDueFilter(false)
                setTaskDueFilterText('')
                break;
            case 'payType':
                setIsPayTypeFilterFilter(false)
                setPayTypeFilterText('')
                break;
            case 'priority':
                setIsPriorityFilter(false)
                setPriorityText('')
                break;

            case 'project':
                setIsProjectFilter(false)
                setProjectFilterText('')
                break;

            case 'roleType':
                setIsRoleTypeFilter(false)
                setRoleTypeFilterText('')
                break;

            case 'cvSource':
                setIsCvSourceFilter(false)
                setCvSourceFilterText('')
                break;

            default:
                break;
        }
        // setFlag(0)
        setFilter(filter)
        setList(filterBy(paymentListSummary, filter))
        setFilterTags(filterTags.filter((item) => item.type !== type))
    }

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    useEffect(() => {
        if (isEmpty(filter)) {
            setTimeout(() => {
                setList(paymentListSummary)
            }, 500)
            // setTimeout(() => {
            //     selectFilter()
            // }, 500)
        } else {
            setList(filterBy(list, filter))
        }
    }, [filter])

    const [stageList, setStage] = useState([]);

    useEffect(() => {

        const amResult = [];
        const amMap = new Map();
        for (const item of list) {
            if (!amMap.has(item.byId)) {
                amMap.set(item.byId, true);
                amResult.push({
                    id: item.byId,
                    name: item.byFirstName,
                    userImg: item.byProfileImage,
                    count: list.filter(function (val) {
                        return val.byId === item.byId;
                    }).length
                });
            }
        }
        amResult.sort(function (a, b) {
            return b.count - a.count;
        });

        setByUserList(amResult)


        const toResult = [];
        const toMap = new Map();
        for (const item of list) {
            if (!toMap.has(item.toId)) {
                toMap.set(item.toId, true);
                toResult.push({
                    id: item.toId,
                    name: item.toFirstName,
                    userImg: item.toProfileImage,
                    count: list.filter(function (val) {
                        return val.toId === item.toId;
                    }).length
                });
            }
        }
        toResult.sort(function (a, b) {
            return b.count - a.count;
        });

        setToUserList(toResult)

        const forNameResult = [];
        const forNamemap = new Map();
        for (const item of list) {
            if (!forNamemap.has(item.paymentTo)) {
                forNamemap.set(item.paymentTo, true);
                forNameResult.push({
                    name: (item.paymentTo != '') ? item.paymentTo : 'Blank',
                    count: list.filter(function (val) {
                        return val.paymentTo === item.paymentTo;
                    }).length
                });
            }
        }
        forNameResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setForNameList(forNameResult)

        const categoryResult = [];
        const categorymap = new Map();
        for (const item of list) {
            if (!categorymap.has(item.paymentType)) {
                categorymap.set(item.paymentType, true);
                categoryResult.push({
                    name: (item.paymentType != '') ? item.paymentType : 'Blank',
                    count: list.filter(function (val) {
                        return val.paymentType === item.paymentType;
                    }).length
                });
            }
        }
        categoryResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setCategoryList(categoryResult)

        const taskCreateResult = [];
        const taskCreatemap = new Map();
        for (const item of list) {
            if (!taskCreatemap.has(item.createdOn)) {
                taskCreatemap.set(item.createdOn, true);
                taskCreateResult.push({
                    name: (item.createdOn != '') ? item.createdOn : 'Blank',
                    count: list.filter(function (val) {
                        return val.createdOn === item.createdOn;
                    }).length
                });
            }
        }
        taskCreateResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setTaskCreateList(taskCreateResult)

        const taskdueResult = [];
        const taskmap = new Map();
        for (const item of list) {
            if (!taskmap.has(item.dueDate)) {
                taskmap.set(item.dueDate, true);
                taskdueResult.push({
                    name: (item.dueDate != '') ? item.dueDate : 'Blank',
                    count: list.filter(function (val) {
                        return val.dueDate === item.dueDate;
                    }).length
                });
            }
        }
        taskdueResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setTaskDueList(taskdueResult)

        const paytypeResult = [];
        const payTypemap = new Map();
        for (const item of list) {
            if (!payTypemap.has(item.payType)) {
                payTypemap.set(item.payType, true);
                paytypeResult.push({
                    name: (item.payType != '') ? item.payType : 'Blank',
                    count: list.filter(function (val) {
                        return val.payType === item.payType;
                    }).length
                });
            }
        }
        paytypeResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setPayTypeList(paytypeResult)

        const priorityResult = [];
        const prioritymap = new Map();
        for (const item of list) {
            if (!prioritymap.has(item.priority)) {
                prioritymap.set(item.priority, true);
                priorityResult.push({
                    name: (item.priority != '') ? item.priority : 'Blank',
                    count: list.filter(function (val) {
                        return val.priority === item.priority;
                    }).length
                });
            }
        }
        priorityResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setPriorityList(priorityResult)


    }, [list])


    return (
        <>
            <div className="dk-payTaskMain">
                <div className="dk-payBoxes-flex">
                    <div className="dk-payboxCols">
                        <div className="dk-payBox h-sm h-vsm">
                            <div className="title">Years</div>
                            <ul className='dkg-paymentsuMM-ul '>
                                {
                                    (!isYearFilter) ? <> {
                                        (list.filter(function (val) {
                                            return val.year == "2023";
                                        }).length != 0) ?
                                            <li data-type="year" data-value="2023" onClick={selectFilter}>
                                                <span className="leftItem d-flex align-items-center" data-type="year" data-value="2023" onClick={selectFilter}>
                                                    2023
                                                </span>
                                                <span className="rightItem" data-type="year" data-value="2023" onClick={selectFilter}>
                                                    {
                                                        list.filter(function (val) {
                                                            return val.year == "2023";
                                                        }).length
                                                    }
                                                </span>
                                            </li> : null
                                    }

                                        {
                                            (list.filter(function (val) {
                                                return val.year == "2022";
                                            }).length != 0) ? <li data-type="year" data-value="2022" onClick={selectFilter}>
                                                <span className="leftItem d-flex align-items-center" data-type="year" data-value="2022" onClick={selectFilter}>

                                                    2022
                                                </span>
                                                <span className="rightItem" data-type="year" data-value="2022" onClick={selectFilter}>
                                                    {
                                                        list.filter(function (val) {
                                                            return val.year == "2022";
                                                        }).length
                                                    }
                                                </span>
                                            </li>
                                                : null
                                        }
                                        {
                                            (list.filter(function (val) {
                                                return val.year == "2021";
                                            }).length != 0) ? <>
                                                <li data-type="year" data-value="2021" onClick={selectFilter}>
                                                    <span className="leftItem d-flex align-items-center" data-type="year" data-value="2021" onClick={selectFilter}>

                                                        2021
                                                    </span>
                                                    <span className="rightItem" data-type="year" data-value="2021" onClick={selectFilter}>
                                                        {
                                                            list.filter(function (val) {
                                                                return val.year == "2021";
                                                            }).length
                                                        }
                                                    </span>
                                                </li></> : null
                                        }
                                        {
                                            (list.filter(function (val) {
                                                return val.year == "2020";
                                            }).length != 0) ? <li data-type="year" data-value="2020" onClick={selectFilter}>
                                                <span className="leftItem d-flex align-items-center" data-type="year" data-value="2020" onClick={selectFilter}>
                                                    2020
                                                </span>
                                                <span className="rightItem" data-type="year" data-value="2020" onClick={selectFilter}>
                                                    {
                                                        list.filter(function (val) {
                                                            return val.year == "2020";
                                                        }).length
                                                    }
                                                </span>
                                            </li>
                                                : null
                                        }
                                        {
                                            (list.filter(function (val) {
                                                return val.year == "2019";
                                            }).length != 0) ? <li data-type="year" data-value="2019" onClick={selectFilter}>
                                                <span className="leftItem d-flex align-items-center" data-type="year" data-value="2019" onClick={selectFilter}>
                                                    2019
                                                </span>
                                                <span className="rightItem" data-type="year" data-value="2019" onClick={selectFilter}>
                                                    {
                                                        list.filter(function (val) {
                                                            return val.year == "2019";
                                                        }).length
                                                    }
                                                </span>
                                            </li>
                                                : null
                                        }
                                        {
                                            (list.filter(function (val) {
                                                return val.year == "2018";
                                            }).length != 0) ? <li data-type="year" data-value="2018" onClick={selectFilter}>
                                                <span className="leftItem d-flex align-items-center" data-type="year" data-value="2018" onClick={selectFilter}>
                                                    2018
                                                </span>
                                                <span className="rightItem" data-type="year" data-value="2018" onClick={selectFilter}>
                                                    {
                                                        list.filter(function (val) {
                                                            return val.year == "2018";
                                                        }).length
                                                    }
                                                </span>
                                            </li>
                                                : null
                                        }
                                        {
                                            (list.filter(function (val) {
                                                return val.year == "2017";
                                            }).length != 0) ? <li data-type="year" data-value="2017" onClick={selectFilter}>
                                                <span className="leftItem d-flex align-items-center" data-type="year" data-value="2017" onClick={selectFilter}>
                                                    2017
                                                </span>
                                                <span className="rightItem" data-type="year" data-value="2017" onClick={selectFilter}>
                                                    {
                                                        list.filter(function (val) {
                                                            return val.year == "2017";
                                                        }).length
                                                    }
                                                </span>
                                            </li>
                                                : null
                                        }

                                    </>
                                        :
                                        <>
                                            <li className='dkg-business-select-li'>
                                                <div className='dk-dbsuMM-filterHeader '>
                                                    {(yearFilterText == "None") ? "Blank" : yearFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="year" onClick={removeTag}></i></span>
                                                </div>
                                                <span className="rightItem">
                                                    {list.length}
                                                </span>
                                            </li>
                                        </>

                                }
                            </ul>
                        </div>
                        <div className="dk-payBox h-sm">
                            <div className="title">Month</div>
                            <ul className='dkg-paymentsuMM-ul dkg-payment-bottom-ullist'>
                                {
                                    (!isMonthsFilter) ? <>
                                        {
                                            (list.filter(function (val) {
                                                return val.month == "January";
                                            }).length != 0) ? <li data-type="month" data-value="January" onClick={selectFilter} >
                                                <span className="leftItem d-flex align-items-center" data-type="month" data-value="January" onClick={selectFilter}>
                                                    January
                                                </span>
                                                <span className="rightItem" data-type="month" data-value="January" onClick={selectFilter} >
                                                    {
                                                        list.filter(function (val) {
                                                            return val.month == "January";
                                                        }).length
                                                    }
                                                </span>
                                            </li> : null
                                        }
                                        {
                                            (list.filter(function (val) {
                                                return val.month == "February";
                                            }).length != 0) ? <li data-type="month" data-value="February" onClick={selectFilter} >
                                                <span className="leftItem d-flex align-items-center" data-type="month" data-value="February" onClick={selectFilter}>

                                                    February
                                                </span>
                                                <span className="rightItem" data-type="month" data-value="February" onClick={selectFilter} >
                                                    {
                                                        list.filter(function (val) {
                                                            return val.month == "February";
                                                        }).length
                                                    }
                                                </span>
                                            </li> : null
                                        }
                                        {
                                            (list.filter(function (val) {
                                                return val.month == "March";
                                            }).length != 0) ? <li data-type="month" data-value="March" onClick={selectFilter} >
                                                <span className="leftItem d-flex align-items-center" data-type="month" data-value="March" onClick={selectFilter}>
                                                    March
                                                </span>
                                                <span className="rightItem" data-type="month" data-value="March" onClick={selectFilter}>
                                                    {
                                                        list.filter(function (val) {
                                                            return val.month == "March";
                                                        }).length
                                                    }
                                                </span>
                                            </li> : null
                                        }

                                        {
                                            (list.filter(function (val) {
                                                return val.month == "April";
                                            }).length != 0) ? <li data-type="month" data-value="April" onClick={selectFilter} >
                                                <span className="leftItem d-flex align-items-center" data-type="month" data-value="April" onClick={selectFilter}>
                                                    April
                                                </span>
                                                <span className="rightItem" data-type="month" data-value="April" onClick={selectFilter} >
                                                    {
                                                        list.filter(function (val) {
                                                            return val.month == "April";
                                                        }).length
                                                    }
                                                </span>
                                            </li> : null
                                        }
                                        {
                                            list.filter(function (val) {
                                                return val.month == "May";
                                            }).length != 0 ? <li data-type="month" data-value="May" onClick={selectFilter}>
                                                <span className="leftItem d-flex align-items-center" data-type="month" data-value="May" onClick={selectFilter}>
                                                    May
                                                </span>
                                                <span className="rightItem" data-type="month" data-value="May" onClick={selectFilter} >
                                                    {
                                                        list.filter(function (val) {
                                                            return val.month == "May";
                                                        }).length
                                                    }
                                                </span>
                                            </li> : null
                                        }
                                        {
                                            list.filter(function (val) {
                                                return val.month == "June";
                                            }).length != 0 ? <li data-type="month" data-value="June" onClick={selectFilter} >
                                                <span className="leftItem d-flex align-items-center" data-type="month" data-value="June" onClick={selectFilter}>
                                                    June
                                                </span>
                                                <span className="rightItem" data-type="month" data-value="June" onClick={selectFilter}>
                                                    {
                                                        list.filter(function (val) {
                                                            return val.month == "June";
                                                        }).length
                                                    }
                                                </span>
                                            </li> : null
                                        }
                                        {
                                            list.filter(function (val) {
                                                return val.month == "July";
                                            }).length != 0 ? <li data-type="month" data-value="July" onClick={selectFilter} >
                                                <span className="leftItem d-flex align-items-center" data-type="month" data-value="July" onClick={selectFilter}>
                                                    July
                                                </span>
                                                <span className="rightItem" data-type="month" data-value="July" onClick={selectFilter} >
                                                    {
                                                        list.filter(function (val) {
                                                            return val.month == "July";
                                                        }).length
                                                    }
                                                </span>
                                            </li> : null
                                        }
                                        {
                                            list.filter(function (val) {
                                                return val.month == "August";
                                            }).length != 0 ? <li data-type="month" data-value="August" onClick={selectFilter} >
                                                <span className="leftItem d-flex align-items-center" data-type="month" data-value="August" onClick={selectFilter}>
                                                    August
                                                </span>
                                                <span className="rightItem" data-type="month" data-value="August" onClick={selectFilter} >
                                                    {
                                                        list.filter(function (val) {
                                                            return val.month == "August";
                                                        }).length
                                                    }
                                                </span>
                                            </li> : null
                                        }
                                        {
                                            (list.filter(function (val) {
                                                return val.month == "September";
                                            }).length != 0) ? <li data-type="month" data-value="September" onClick={selectFilter} >
                                                <span className="leftItem d-flex align-items-center" data-type="month" data-value="September" onClick={selectFilter}>
                                                    September
                                                </span>
                                                <span className="rightItem" data-type="month" data-value="September" onClick={selectFilter} >
                                                    {
                                                        list.filter(function (val) {
                                                            return val.month == "September";
                                                        }).length
                                                    }
                                                </span>
                                            </li> : null
                                        }
                                        {
                                            (list.filter(function (val) {
                                                return val.month == "October";
                                            }).length != 0) ? <li data-type="month" data-value="October" onClick={selectFilter} >
                                                <span className="leftItem d-flex align-items-center" data-type="month" data-value="October" onClick={selectFilter}>
                                                    October
                                                </span>
                                                <span className="rightItem" data-type="month" data-value="October" onClick={selectFilter}>
                                                    {
                                                        list.filter(function (val) {
                                                            return val.month == "October";
                                                        }).length
                                                    }
                                                </span>
                                            </li> : null
                                        }
                                        {
                                            (list.filter(function (val) {
                                                return val.month == "November";
                                            }).length != 0) ? <li data-type="month" data-value="November" onClick={selectFilter} >
                                                <span className="leftItem d-flex align-items-center" data-type="month" data-value="November" onClick={selectFilter}>
                                                    November
                                                </span>
                                                <span className="rightItem" data-type="month" data-value="November" onClick={selectFilter}>
                                                    {
                                                        list.filter(function (val) {
                                                            return val.month == "November";
                                                        }).length
                                                    }
                                                </span>
                                            </li> : null
                                        }
                                        {
                                            (list.filter(function (val) {
                                                return val.month == "December";
                                            }).length != 0) ? <li data-type="month" data-value="December" onClick={selectFilter}>
                                                <span className="leftItem d-flex align-items-center" data-type="month" data-value="December" onClick={selectFilter}>
                                                    December
                                                </span>
                                                <span className="rightItem" data-type="month" data-value="December" onClick={selectFilter}>
                                                    {
                                                        list.filter(function (val) {
                                                            return val.month == "December";
                                                        }).length
                                                    }
                                                </span>
                                            </li> : null
                                        }
                                    </>
                                        :
                                        <>
                                            <li className='dkg-business-select-li'>
                                                <div className='dk-dbsuMM-filterHeader'>
                                                    {(monthsFilterText == "None") ? "Blank" : monthsFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="month" onClick={removeTag}></i></span>
                                                </div>
                                                <span className="dk-totalCvCount">
                                                    {(flag === 1) ? list.length : 0}
                                                </span>
                                            </li>
                                        </>
                                }

                            </ul>
                        </div>
                    </div>
                    <div className="dk-payboxCols">
                        <div className="dk-payBox h-sm h-vsm">
                            <div className="title">CREATED BY</div>
                            <ul className="dkg-paymentsuMM-ul">
                                {
                                    (!isPaymentToFilter) ? <>
                                        {
                                            toUserList.map((item, index) => (
                                                <li key={`amFilter${index}`} data-value={item.id} data-name={item.name} data-img={item.userImg} data-type="toId" onClick={selectFilter}>
                                                    <span className="recuriterItem d-flex align-items-center" data-value={item.id} data-name={item.name} data-img={item.userImg} data-type="toId" onClick={selectFilter}>
                                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} alt={item.name} className="recruitersImg" data-value={item.id} data-name={item.name} data-img={item.userImg} data-type="toId" onClick={selectFilter} />
                                                        {item.name}
                                                    </span>
                                                    <span className="rightItem" data-value={item.id} data-name={item.name} data-img={item.userImg} data-type="toId" onClick={selectFilter}>
                                                        {
                                                            list.filter(function (val) {
                                                                return val.toId === item.id;
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                            ))}
                                    </>
                                        :
                                        <>
                                            <li className='dkg-business-select-li'>
                                                <div className='dk-dbsuMM-filterHeader recuriterItem d-flex align-items-center'>
                                                    {(paymentToIdFilterText == "None") ? "Blank" : <><img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + isPaymentToImgFilter} className="recruitersImg" data-type="toId" />{paymentToIdFilterText}</>}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="toId" onClick={removeTag}></i></span>
                                                </div>
                                                <span className="dk-totalCvCount">
                                                    {list.length}
                                                </span>
                                            </li>
                                        </>

                                }

                            </ul>
                        </div>
                        <div className="dk-payBox h-sm">
                            <div className="title">PAYMENT TO</div>
                            <ul className="dkg-paymentsuMM-ul dkg-payment-bottom-ullist">

                                {/* {
                                    (!isCreatedByFilter) ? <>
                                        {
                                            byUserList.map((item, index) => (
                                                <li key={`amFilter${index}`} data-value={item.id} data-name={item.name} data-img={item.userImg} data-type="byId" onClick={selectFilter}>
                                                    <span className="recuriterItem d-flex align-items-center" data-value={item.id} data-name={item.name} data-img={item.userImg} data-type="byId" onClick={selectFilter}>
                                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} alt={item.name} className="recruitersImg" data-value={item.id} data-name={item.name} data-img={item.userImg} data-type="byId" onClick={selectFilter} />
                                                        {item.name}
                                                    </span>
                                                    <span className="rightItem" data-value={item.id} data-name={item.name} data-img={item.userImg} data-type="byId" onClick={selectFilter}>
                                                        {
                                                            list.filter(function (val) {
                                                                return val.byId === item.id;
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                            ))}
                                    </>
                                        :
                                        <>
                                            <li className='dkg-business-select-li'>
                                                <div className='dk-dbsuMM-filterHeader recuriterItem d-flex align-items-center'>
                                                    {(createdByIdFilterText == "None") ? "Blank" : <><img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + isCreatedImgFilter} className="recruitersImg" data-type="byId" />{createdByIdFilterText}</>}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="byId" onClick={removeTag}></i></span>
                                                </div>
                                                <span className="dk-totalCvCount">
                                                    {list.length}
                                                </span>
                                            </li>
                                        </>

                                } */}
                                <ul className='dkg-paymentsuMM-ul dkg-payment-bottom-ullist'>
                                    {
                                        (!isForNameFilter) ? <>
                                            {
                                                forNameList.map((item, index) => (
                                                    <li key={`company${index}`} data-type="paymentTo" data-value={item.name} onClick={selectFilter}>
                                                        <span className="leftItem d-flex align-items-center" data-type="paymentTo" data-value={item.name} onClick={selectFilter}>
                                                            {item.name}
                                                        </span>
                                                        <span className="rightItem" data-type="paymentTo" data-value={item.name} onClick={selectFilter}>
                                                            {
                                                                list.filter(function (val) {
                                                                    return val.paymentTo === item.name;
                                                                }).length
                                                            }
                                                        </span>
                                                    </li>
                                                ))}
                                        </>
                                            :
                                            <>
                                                <li className='dkg-business-select-li'>
                                                    <div className='dk-dbsuMM-filterHeader'>
                                                        {(isForNameFilterText == "None") ? "Blank" : isForNameFilterText}
                                                        <span><i className='fa fa-times-circle  ml-2' data-type="paymentTo" onClick={removeTag}></i></span>
                                                    </div>
                                                    <span className="dk-totalCvCount">
                                                        {list.length}
                                                    </span>
                                                </li>
                                            </>
                                    }

                                </ul>


                            </ul>
                        </div>
                    </div>
                    <div className="dk-payboxCols">
                        <div className="dk-payBox h-sm h-sm-firstRow ">
                            <div className="title">STATUS</div>
                            <ul className="dkg-paymentsuMM-ul dkg-payment-stage-ullist">
                                <li>
                                    <span className='leftItem d-flex align-items-center'>To Pay</span>
                                    <span className='rightItem'>0</span>
                                </li>
                                <li>
                                    <span className='leftItem d-flex align-items-center'>Paid</span>
                                    <span className='rightItem'>0</span>
                                </li>
                                <li>
                                    <span className='leftItem d-flex align-items-center'>Archived</span>
                                    <span className='rightItem'>0</span>
                                </li>
                            </ul>
                        </div>
                        <div className="dk-payBox h-sm">
                            <div className="title">Category</div>
                            <ul className='dkg-paymentsuMM-ul dkg-payment-bottom-ullist'>
                                {
                                    (!isCategoryFilter) ? <>
                                        {
                                            categoryList.map((item, index) => (
                                                <li key={`company${index}`} data-type="paymentType" data-value={item.name} onClick={selectFilter}>
                                                    <span className="leftItem d-flex align-items-center" data-type="paymentType" data-value={item.name} onClick={selectFilter}>
                                                        {item.name}
                                                    </span>
                                                    <span className="rightItem" data-type="paymentType" data-value={item.name} onClick={selectFilter}>
                                                        {
                                                            list.filter(function (val) {
                                                                return val.paymentType === item.name;
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                            ))}
                                    </>
                                        :
                                        <>
                                            <li className='dkg-business-select-li'>
                                                <div className='dk-dbsuMM-filterHeader'>
                                                    {(categoryFilterText == "None") ? "Blank" : categoryFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="paymentType" onClick={removeTag}></i></span>
                                                </div>
                                                <span className="dk-totalCvCount">
                                                    {list.length}
                                                </span>
                                            </li>
                                        </>
                                }

                            </ul>
                        </div>
                    </div>
                    {/* <div className="dk-payboxCols">
                        <div className="dk-payBox h-lg">
                            <div className="title">Category</div>

                            <ul className='dkg-paymentsuMM-ul'>
                                {
                                    (!isCategoryFilter) ? <>
                                        {
                                            categoryList.map((item, index) => (
                                                <li key={`company${index}`} data-type="paymentType" data-value={item.name} onClick={selectFilter}>
                                                    <span className="leftItem d-flex align-items-center" data-type="paymentType" data-value={item.name} onClick={selectFilter}>
                                                        {item.name}
                                                    </span>
                                                    <span className="rightItem" data-type="paymentType" data-value={item.name} onClick={selectFilter}>
                                                        {
                                                            list.filter(function (val) {
                                                                return val.paymentType === item.name;
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                            ))}
                                    </>
                                        :
                                        <>
                                            <li className='dkg-business-select-li'>
                                                <div className='dk-dbsuMM-filterHeader'>
                                                    {(categoryFilterText == "None") ? "Blank" : categoryFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="paymentType" onClick={removeTag}></i></span>
                                                </div>
                                                <span className="dk-totalCvCount">
                                                    {list.length}
                                                </span>
                                            </li>
                                        </>
                                }

                            </ul>
                        </div>
                    </div> */}
                    <div className="dk-payboxCols">
                    <div className="dk-payBox h-sm h-sm-firstRow">
                            <div className="title">STAGE</div>

                            <ul className='dkg-paymentsuMM-ul'>
                                {(!isCaseStatusFilter) ? <>
                                    {
                                        (list.filter(function (val) {
                                            return val.status == "New Task";
                                        }).length != 0) ? <li data-type="status" data-value="New Task" onClick={selectFilter} style={{ backgroundColor: "#d58b5d", color: "#fff" }}>
                                            <span className="leftItem d-flex align-items-center" data-type="status" data-value="New Task" onClick={selectFilter}>
                                                New Task
                                            </span>
                                            <span className="rightItem" data-type="status" data-value="New Task" onClick={selectFilter} style={{ color: "#fff" }}>
                                                {
                                                    list.filter(function (val) {
                                                        return val.status == "New Task";
                                                    }).length
                                                }
                                            </span>
                                        </li>
                                            : null
                                    }
                                    {
                                        (list.filter(function (val) {
                                            return val.status == "Queries";
                                        }).length != 0) ? <li data-type="status" data-value="Queries" onClick={selectFilter} style={{ backgroundColor: "#625c60", color: "#fff" }}>
                                            <span className="leftItem d-flex align-items-center" data-type="status" data-value="Queries" onClick={selectFilter}>
                                                Queries
                                            </span>
                                            <span className="rightItem" data-type="status" data-value="Queries" onClick={selectFilter} style={{ color: "#fff" }}>
                                                {
                                                    list.filter(function (val) {
                                                        return val.status == "Queries";
                                                    }).length
                                                }
                                            </span>
                                        </li> : null
                                    }
                                    {
                                        (list.filter(function (val) {
                                            return val.status == "Accepted";
                                        }).length != 0) ? <li data-type="status" data-value="Accepted" onClick={selectFilter} style={{ backgroundColor: "#26597d", color: "#fff" }}>
                                            <span className="leftItem d-flex align-items-center" data-type="status" data-value="Accepted" onClick={selectFilter}>
                                                Accepted
                                            </span>
                                            <span className="rightItem" data-type="status" data-value="Accepted" onClick={selectFilter} style={{ color: "#fff" }}>
                                                {
                                                    list.filter(function (val) {
                                                        return val.status == "Accepted";
                                                    }).length
                                                }
                                            </span>
                                        </li> : null
                                    }
                                    {
                                        (list.filter(function (val) {
                                            return val.status == "Partially Paid";
                                        }).length != 0) ? <li data-type="status" data-value="Partially Paid" onClick={selectFilter} style={{ backgroundColor: "#715f8f", color: "#fff" }}>
                                            <span className="leftItem d-flex align-items-center" data-type="status" data-value="Partially Paid" onClick={selectFilter}>
                                                Partially Paid
                                            </span>
                                            <span className="rightItem" data-type="status" data-value="Partially Paid" style={{ color: "#fff" }} onClick={selectFilter}>
                                                {
                                                    list.filter(function (val) {
                                                        return val.status == "Partially Paid";
                                                    }).length
                                                }
                                            </span>
                                        </li> : null
                                    }
                                    {
                                        (list.filter(function (val) {
                                            return val.status == "Fully Paid";
                                        }).length != 0) ? <li data-type="status" data-value="Fully Paid" onClick={selectFilter} style={{ backgroundColor: "#3a9469", color: "#fff" }}>
                                            <span className="leftItem d-flex align-items-center" data-type="status" data-value="Fully Paid" onClick={selectFilter}>
                                                Fully Paid
                                            </span>
                                            <span className="rightItem" data-type="status" data-value="Fully Paid" style={{ color: "#fff" }} onClick={selectFilter}>
                                                {
                                                    list.filter(function (val) {
                                                        return val.status == "Fully Paid";
                                                    }).length
                                                }
                                            </span>
                                        </li> : null
                                    }
                                </>
                                    :
                                    <>
                                        <li className='dkg-business-select-li'>
                                            <div className='dk-dbsuMM-filterHeader'>
                                                {(caseStatusFilterText == "None") ? "Blank" : caseStatusFilterText}
                                                <span><i className='fa fa-times-circle  ml-2' data-type="status" onClick={removeTag}></i></span>
                                            </div>
                                            <span className="dk-totalCvCount">
                                                {list.length}
                                            </span>
                                        </li>
                                    </>
                                }
                            </ul>
                        </div>
                        <div className="dk-payBox h-sm">
                            <div className="title">Pay Type</div>

                            <ul className='dkg-paymentsuMM-ul dkg-payment-bottom-ullist'>
                                {
                                    (!isPayTypeFilter) ? <>
                                        {
                                            paytypeList.map((item, index) => (
                                                <li key={`company${index}`} data-type="payType" data-value={item.name} onClick={selectFilter}>
                                                    <span className="leftItem d-flex align-items-center" data-type="payType" data-value={item.name} onClick={selectFilter}>
                                                        {item.name}
                                                    </span>
                                                    <span className="rightItem" data-type="payType" data-value={item.name} onClick={selectFilter}>
                                                        {
                                                            list.filter(function (val) {
                                                                return val.payType === item.name;
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                            ))}
                                    </>
                                        :
                                        <>
                                            <li className='dkg-business-select-li'>
                                                <div className='dk-dbsuMM-filterHeader'>
                                                    {(paytypeFilterText == "None") ? "Blank" : paytypeFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="payType" onClick={removeTag}></i></span>
                                                </div>
                                                <span className="dk-totalCvCount">
                                                    {list.length}
                                                </span>
                                            </li>
                                        </>
                                }

                            </ul>
                        </div>
                    </div>
                    <div className="dk-payboxCols">
                    <div className="dk-payBox h-sm h-sm-firstRow ">
                            <div className="title">Priority</div>
                            {/* <ul className="dkg-paymentsuMM-ul">
                                <li>
                                    <span className='leftItem d-flex align-items-center'>ELJ</span>
                                    <span className='rightItem'>123</span>
                                </li>
                            </ul> */}
                            <ul className='dkg-paymentsuMM-ul dkg-payment-priority-ullist'>
                                {
                                    (!isPriorityFilter) ? <>
                                        {
                                            priorityList.map((item, index) => (
                                                <li key={`company${index}`} data-type="priority" data-value={item.name} onClick={selectFilter}>
                                                    <span className="leftItem d-flex align-items-center" data-type="priority" data-value={item.name} onClick={selectFilter}>
                                                        {item.name}
                                                    </span>
                                                    <span className="rightItem" data-type="priority" data-value={item.name} onClick={selectFilter}>
                                                        {
                                                            list.filter(function (val) {
                                                                return val.priority === item.name;
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                            ))}
                                    </>
                                        :
                                        <>
                                            <li className='dkg-business-select-li'>
                                                <div className='dk-dbsuMM-filterHeader'>
                                                    {(priorityFilterText == "None") ? "Blank" : priorityFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="priority" onClick={removeTag}></i></span>
                                                </div>
                                                <span className="dk-totalCvCount">
                                                    {list.length}
                                                </span>
                                            </li>
                                        </>
                                }
                            </ul>

                        </div>
                        <div className="dk-payBox h-sm">
                            <div className="title">TASK CREATED ON</div>
                            <ul className='dkg-paymentsuMM-ul dkg-payment-bottom-ullist'>
                                {
                                    (!isTaskCreateFilter) ? <>
                                        {
                                            taskCreateList.map((item, index) => (
                                                <li key={`company${index}`} data-type="createdOn" data-value={item.name} onClick={selectFilter}>
                                                    <span className="leftItem d-flex align-items-center" data-type="createdOn" data-value={item.name} onClick={selectFilter}>
                                                        {item.name}
                                                    </span>
                                                    <span className="rightItem" data-type="createdOn" data-value={item.name} onClick={selectFilter}>
                                                        {
                                                            list.filter(function (val) {
                                                                return val.createdOn === item.name;
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                            ))}
                                    </>
                                        :
                                        <>
                                            <li className='dkg-business-select-li'>
                                                <div className='dk-dbsuMM-filterHeader'>
                                                    {(taskCreateFilterText == "None") ? "Blank" : taskCreateFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="createdOn" onClick={removeTag}></i></span>
                                                </div>
                                                <span className="dk-totalCvCount">
                                                    {list.length}
                                                </span>
                                            </li>
                                        </>
                                }

                            </ul>
                        </div>
                    </div>
                    <div className="dk-payboxCols">
                        <div className="dk-payBox h-lg">
                            <div className="title">Task Due On</div>
                            {/* <ul className="dkg-paymentsuMM-ul">                              
                                <li>
                                    <span className='leftItem d-flex align-items-center'>EJobs</span>
                                    <span className='rightItem'>123</span>
                                </li>
                            </ul> */}
                            <ul className='dkg-paymentsuMM-ul dkg-paymentsuMM-rightlistCon'>
                                {
                                    (!isTaskDueFilter) ? <>
                                        {
                                            taskdueList.map((item, index) => (
                                                <li key={`company${index}`} data-type="dueDate" data-value={item.name} onClick={selectFilter}>
                                                    <span className="leftItem d-flex align-items-center" data-type="dueDate" data-value={item.name} onClick={selectFilter}>
                                                        {item.name}
                                                    </span>
                                                    <span className="rightItem" data-type="dueDate" data-value={item.name} onClick={selectFilter}>
                                                        {
                                                            list.filter(function (val) {
                                                                return val.dueDate === item.name;
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                            ))}
                                    </>
                                        :
                                        <>
                                            <li className='dkg-business-select-li'>
                                                <div className='dk-dbsuMM-filterHeader'>
                                                    {(taskDueFilterText == "None") ? "Blank" : taskDueFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="dueDate" onClick={removeTag}></i></span>
                                                </div>
                                                <span className="dk-totalCvCount">
                                                    {list.length}
                                                </span>
                                            </li>
                                        </>
                                }

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PayTasksSummary;