import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const PaymentsDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="#" className={`${pathname.match('/training/commontraining') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/business') ? 'active' : ''}`}>Shedule</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/industry') ? 'active' : ''}`}>Methods</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/services') ? 'active' : ''}`}>Bank Details</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/clients') ? 'active' : ''}`}>Salaries</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/suppliers') ? 'active' : ''}`}>Commisions</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/completion') ? 'active' : ''}`}>Bonus</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Advance</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Pay Task</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Invoices</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Increase</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Pendings</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Queries</Link></li>


                </Route>
            </ul>
        </React.Fragment>
    )
}

export default PaymentsDetailLeftPanel;

