import React from 'react';
//import './casestatus.scss';

const CaseStage = ({ value }) => {
    let bgColor = "";
    switch (value) {
             case 'To be Created':
            bgColor = '#d58b5d'
            break;

        case 'PO Requested':
            bgColor = '#d58b5d'
            break;
        case 'Sent to Client':
            bgColor = '#d58b5d'
            break;

        case 'Sent to Bibby':
            bgColor = '#d58b5d'
            break;
        case 'Paid by Client':
            bgColor = '#01796f'
            break;
        case 'Paid by Bibby':
            bgColor = '#01796f'
            break;
        case 'To Be Sent':
            bgColor = '#da3d5e'
            break;
        case 'Sent to Client ':
            bgColor = '#da3d5e'
            break;

        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor }}>{value}</div>
        </>
    )
}

export default CaseStage;