import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement, sortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from 'array-move';
import { useDispatch, useSelector } from "react-redux";
import Submit from "../../../ui/submitButton";
import Modal from 'react-bootstrap/Modal';
import './clientskpi.scss';
import { reorderClient, clearState } from "../../../../slice/clientKpi/clientKpiSlice";
import { showSuccess } from "../../../../slice/utils/message/messageSlice";

const DragHandle = sortableHandle(() => <span className="dk-handle" style={{ backgroundColor: "#363f4c", height: "35px !important", width: "50px", textAlign: "center", lineHeight: "35px", color: "white", cursor: "all-scroll" }}><i className="fas fa-bars"></i></span>);

const SortableItem = SortableElement(({ value }) => {
    return (
        <React.Fragment>
            <div className="Mukul">
                <div className="dk-valueFields d-flex" key={value.id} id={value.id}>
                    <DragHandle />
                    <input readonly type="text" style={{ height: "35px", borderRadius: "0", border: "1px solid #ddd", boxShadow: "none !important" }} value={value.companies} className="form-control" />
                </div>
            </div>
        </React.Fragment>
    )
})

const SortableList = SortableContainer(({ items }) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </ul>
    );
});

const ReorderModal = ({ handleClose, dataList }) => {
    const dispatch = useDispatch();
    const { isReorder, isError, errorMessage } = useSelector(state => state.clientKpi);

    const [list, setList] = useState([])

    useEffect(() => {
        setList(dataList)
    }, [dataList])

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setList(arrayMoveImmutable(list, oldIndex, newIndex))
    };

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const reorderCategoryValues = async () => {
        setLoading(true)
        setSuccess(false)
        let reorderListArray = []
        list.map((data) => {
            reorderListArray.push(data.id)
        })
        await dispatch(reorderClient({ ids: reorderListArray }))
    }

    useEffect(() => {
        if (isReorder) {
            dispatch(showSuccess({ msg: "Reorder Successfully." }))
            dispatch(clearState())
            handleClose();
        }
    }, [isReorder])

    return (
        <>
            <Modal className="dk-reorderModal" centered show={true} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reorder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-reorderModal-body">
                        <SortableList lockAxis="y" items={list} onSortEnd={onSortEnd} useDragHandle />
                    </div>
                    <div className="dk-reorderBtn">
                        <Submit txt="Update" loading={loading} success={success} onClick={reorderCategoryValues} position="justify-content-center" className="" />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReorderModal;
