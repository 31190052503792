import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const JobDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkglobaladmin/admintraining/training-resource/job-details" className={`${pathname.match('/dkglobaladmin/admintraining/training-resource/job-details') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/training-resource/structure" className={`${pathname.match('/dkglobaladmin/admintraining/training-resource/structure') ? 'active' : ''}`}>Structure</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/training-resource/team-info" className={`${pathname.match('/dkglobaladmin/admintraining/training-resource/team-info') ? 'active' : ''}`}>Team Info</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/training-resource/job-skills" className={`${pathname.match('/dkglobaladmin/admintraining/training-resource/job-skills') ? 'active' : ''}`}>Job Skills</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/training-resource/job-task" className={`${pathname.match('/dkglobaladmin/admintraining/training-resource/job-task') ? 'active' : ''}`}>Job Task</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/training-resource/job-quality" className={`${pathname.match('/dkglobaladmin/admintraining/training-resource/job-quality') ? 'active' : ''}`}>Job Quality</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/training-resource/job-volume" className={`${pathname.match('/dkglobaladmin/admintraining/training-resource/job-volume') ? 'active' : ''}`}>Job Volume</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/training-resource/job-queries" className={`${pathname.match('/dkglobaladmin/admintraining/training-resource/job-queries') ? 'active' : ''}`}>Job Queries</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/training-resource/job-training" className={`${pathname.match('/dkglobaladmin/admintraining/training-resource/job-training') ? 'active' : ''}`}>Job Training</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/training-resource/job-delegation" className={`${pathname.match('/dkglobaladmin/admintraining/training-resource/job-delegation') ? 'active' : ''}`}>Job Delegation</Link></li>
                </Route>
            </ul>
        </React.Fragment>
    )
}

export default JobDetailLeftPanel;

