import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement, sortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from 'array-move';
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import Submit from '../../../../ui/submitButton';
import { reorderCashOut, clearState } from "../../../../../slice/accounting/cashflow";
import { showSuccess } from "../../../../../slice/utils/message/messageSlice";


const DragHandle = sortableHandle(() => <span className="dk-handle" style={{ backgroundColor: "#363f4c", height: "35px !important", width: "50px", textAlign: "center", lineHeight: "35px", color: "white", cursor: "all-scroll" }}><i className="fas fa-bars"></i></span>);

const SortableItem = SortableElement(({ value }) => {
    return (
        <React.Fragment>
            <div className="Mukul">
                <div className="dk-valueFields d-flex" key={value.id} id={value.id}>
                    <DragHandle />
                    <input readonly type="text" style={{ height: "35px", borderRadius: "0", border: "1px solid #ddd", boxShadow: "none !important" }} value={value.heading} className="form-control" />
                </div>
            </div>
        </React.Fragment>
    )
})

const SortableList = SortableContainer(({ items }) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </ul>
    );
});

const CashoutHeadingReorder = ({ hideCashOutModal, dataList }) => {   
    
    const dispatch = useDispatch();
    const { isCashOutReorder, isError, errorMessage } = useSelector(state => state.cashflowdata);

    const [list, setList] = useState([])

    useEffect(() => {
        setList(dataList)
    }, [dataList])

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setList(arrayMoveImmutable(list, oldIndex, newIndex))
    };

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const reorderCategoryValues = async () => {
        setLoading(true)
        setSuccess(false)
        let reorderListArray = []
        list.map((data) => {
            reorderListArray.push(data.id)
        })
        await dispatch(reorderCashOut({ ids: reorderListArray }))
    }

    useEffect(() => {
        if (isCashOutReorder) {
            dispatch(showSuccess({ msg: "Reorder Successfully." }))
            dispatch(clearState())
            hideCashOutModal();
        }
    }, [isCashOutReorder])

    return (
        <>
        <Modal className="dk-reorderModal" show={true} onHide={hideCashOutModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>REORDER EXPENSE - CASH OUT HEADING</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-reorderModal-body">
                        <SortableList lockAxis="y" items={list} onSortEnd={onSortEnd} useDragHandle />
                    </div>
                    <div className="dk-reorderBtn">
                        <Submit txt="Update" loading={loading} success={success} onClick={reorderCategoryValues} position="justify-content-center" className="" />
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default CashoutHeadingReorder