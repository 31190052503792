import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AddTeamRoles from "./AddTeamRoles";
import AddTeamRolesUsers from "./AddTeamRolesUsers";
import './teamroles.scss';

const CommonEditTeamRoles = () => {
    const [showTeamRoles, setTeamRolesShow] = useState(false);
    const showAddTealRoles = () => {
        setTeamRolesShow(true)
    }
    const hideAddTeamRolesModal = () => {
        setTeamRolesShow(false)
    }

    const [showTeamRolesUsers, setTeamRolesUsresShow] = useState(false);
    const showAddTealRolesUsers = () => {
        setTeamRolesUsresShow(true)
    }
    const hideAddTeamRolesUsersModal = () => {
        setTeamRolesUsresShow(false)
    }

    return (
        <>


            {
                showTeamRoles ?
                    <AddTeamRoles handleClose={hideAddTeamRolesModal} /> : null
            }

            {
                showTeamRolesUsers ?
                    <AddTeamRolesUsers handleClose={hideAddTeamRolesUsersModal} /> : null
            }
            <div className="dk-rolesresponPageMain dk-edit-rolesresponPageMain dk-trainingTeamRolesMain">
                <div className="dk-pageheader">
                    <div className="dk-backBtn-cols">
                        <Link to="/training/commontraining/common-training"><i className="fas fa-arrow-left"></i> Go Back</Link>
                    </div>
                    <h3 className="title text-center">TEAMS & ROLES SUMMARY</h3>
                    <div className="dk-teamrolerightBtnCon">
                        <div className="dk-teamroleseditBtn">
                            <Link to="/training/commontraining/process"><i className="far fa-edit"></i> Back Page</Link>
                        </div>
                    </div>
                </div>
                <div className="dk-rolesresponTableCon table-responsive">
                    <table className="table dk-rolesresponTable table-bordered">
                        <thead className="dk-rolesTeamHeader">
                            <tr>
                                <th></th>
                                <th>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 1</div>
                                    <div className="dk-rolesTeamHeaderTitle">RESOURCE ADMINS - RD</div>
                                </th>
                                <th colSpan={2}>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 2</div>
                                    <div className="dk-rolesTeamHeaderTitle">RECRUITMENT ADMINS - RA</div>
                                </th>
                                <th>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 3</div>
                                    <div className="dk-rolesTeamHeaderTitle">RECRUITMENT CONSULTANT - RC</div>
                                </th>
                                <th>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 4</div>
                                    <div className="dk-rolesTeamHeaderTitle">ACCOUNT MANAGERS - AM</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>TEAM LEADER</td>
                                <td>
                                    <div className="dk-teamImgCols" onClick={showAddTealRolesUsers}>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/1623316236.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td colSpan={2} className="team2BG">
                                    <div className="dk-teamImgCols" onClick={showAddTealRolesUsers}>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team3BG">
                                    <div className="dk-teamImgCols" onClick={showAddTealRolesUsers}>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team4BG">
                                    <div className="dk-teamImgCols" onClick={showAddTealRolesUsers}>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>TEAM</td>
                                <td>
                                    <div className="dk-teamImgCols" onClick={showAddTealRolesUsers}>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c3a2270ae02e.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c3ad279ec.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/1638604796.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td colSpan={2} className="team2BG">
                                    <div className="dk-teamImgCols" onClick={showAddTealRolesUsers}>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5e54fdac9c.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team3BG">
                                    <div className="dk-teamImgCols" onClick={showAddTealRolesUsers}>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5e54fdac9c.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team4BG">
                                    <div className="dk-teamImgCols" onClick={showAddTealRolesUsers}>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>SUMMARY</td>
                                <td><p onClick={showAddTealRoles}>CREATE NEW LEADS</p></td>
                                <td><p onClick={showAddTealRoles}>FILTER NEW LEADS</p></td>
                                <td><p onClick={showAddTealRoles}>SCREEN THE LEADS</p></td>
                                <td><p onClick={showAddTealRoles}>QUALIFY THE LEADS</p></td>
                                <td><p onClick={showAddTealRoles}>CLOSE THE LEADS</p></td>
                            </tr>
                            <tr>
                                <td>MANAGE</td>
                                <td><p onClick={showAddTealRoles}>NEW CV'S PIPELINE</p></td>
                                <td><p onClick={showAddTealRoles}>SELECTED PIPELINE</p></td>
                                <td><p onClick={showAddTealRoles}>SCREENING PIPELINE</p></td>
                                <td><p onClick={showAddTealRoles}>RECRUITERS PIPELINE</p></td>
                                <td><p onClick={showAddTealRoles}>AM PIPELINE</p></td>
                            </tr>
                            <tr>
                                <td>ACTIONS</td>
                                <td><p onClick={showAddTealRoles}>NO EMAIL/SMS/CALL</p></td>
                                <td><p onClick={showAddTealRoles}>EMAIL/SMS ONLY</p></td>
                                <td><p onClick={showAddTealRoles}>EMAIL /SMS /CALL</p></td>
                                <td><p onClick={showAddTealRoles}>EMAIAL /SMS /CALL</p></td>
                                <td><p onClick={showAddTealRoles}>EMAIL /SMS /CALL</p></td>
                            </tr>
                            <tr>
                                <td>CLEARANCE</td>
                                <td><p onClick={showAddTealRoles}>MOVE TO SELECTED</p></td>
                                <td><p onClick={showAddTealRoles}>MOVE TO SCREENING</p></td>
                                <td><p onClick={showAddTealRoles}>MOVE TO RECRUITERS</p></td>
                                <td><p onClick={showAddTealRoles}>MOVE TO AM PIPELINE</p></td>
                                <td><p onClick={showAddTealRoles}>MOVE TO OFFER PIPELINE</p></td>
                            </tr>
                            <tr>
                                <td>RESPONSIBILITIES</td>
                                <td>
                                    <p onClick={showAddTealRoles}>-ADS MANAGEMENT</p>
                                    <p onClick={showAddTealRoles}>- APPS MANAGEMENT</p>
                                    <p onClick={showAddTealRoles}>- JOBS MANAGEMENT</p>
                                    <p onClick={showAddTealRoles}>- JOB BOARD INVOICE</p>
                                    <p onClick={showAddTealRoles}>- MARKETING BUDGET</p>
                                    <p onClick={showAddTealRoles}>- DK GLOBAL DATABASE</p>
                                </td>
                                <td>
                                    <p onClick={showAddTealRoles}>- REVIEW NEW CV's</p>
                                    <p onClick={showAddTealRoles}>- UPDATE PROFILES</p>
                                    <p onClick={showAddTealRoles}>- ASK CV IN ENGLISH</p>
                                    <p onClick={showAddTealRoles}>- VISA CONFIRMATION</p>
                                    <p onClick={showAddTealRoles}>- POST SOCIAL MEDIA ADS</p>
                                    <p onClick={showAddTealRoles}>- SEARCH NEW CANDIDATES</p>
                                </td>
                                <td>
                                    <p onClick={showAddTealRoles}>- MINI JOB SPEC + SMS</p>
                                    <p onClick={showAddTealRoles}>- 1st REMINDER + SMS</p>
                                    <p onClick={showAddTealRoles}>- 2nd REMINDER + SMS</p>
                                    <p onClick={showAddTealRoles}>- CHASE NOT REACHABLE</p>
                                    <p onClick={showAddTealRoles}>- PRE-SCREENING REPORT</p>
                                    <p onClick={showAddTealRoles}>- CANDIDATE PREFERENCES</p>
                                </td>
                                <td>
                                    <p onClick={showAddTealRoles}>- QUALIFYING CALL</p>
                                    <p onClick={showAddTealRoles}>- FULL JOB SPEC + SMS</p>
                                    <p onClick={showAddTealRoles}>- MAKE SEND OUT TO AM</p>
                                    <p onClick={showAddTealRoles}>- UPLOAD IN CLIENT PORTAL</p>
                                    <p onClick={showAddTealRoles}>- CREATE QUALIFYING REPORT</p>
                                    <p onClick={showAddTealRoles}>- NOT REACHABLE CANDIDATES</p>
                                </td>
                                <td>
                                    <p onClick={showAddTealRoles}>- SUBMIT THE SEND OUT</p>
                                    <p onClick={showAddTealRoles}>- SCHEDULE INTERVIEWS</p>
                                    <p onClick={showAddTealRoles}>- ASK NEW JOBS DEMAND</p>
                                    <p onClick={showAddTealRoles}>- ASK CANDIDATES FEEDBACK</p>
                                    <p onClick={showAddTealRoles}>- CANDIDATES INTERVIEW PREP</p>
                                    <p onClick={showAddTealRoles}>- HELP WITH OFFER DOCUMENTS</p>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default CommonEditTeamRoles;
