import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../trainingdetail.scss';

const DetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/training/schedule/overview" className={`${pathname.match('/training/schedule/overview') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/training/schedule/day-one" className={`${pathname.match('/training/schedule/day-one') ? 'active' : ''}`}>Day 1 - Part Time</Link></li>

                    <li><Link to="/training/schedule/day-two" className={`${pathname.match('/training/schedule/day-two') ? 'active' : ''}`}>Day 2 - Part Time</Link></li>

                    <li><Link to="/training/schedule/day-three" className={`${pathname.match('/training/schedule/day-three') ? 'active' : ''}`}>Day 3 - Part Time</Link></li>

                    <li><Link to="/training/schedule/day-four" className={`${pathname.match('/training/schedule/day-four') ? 'active' : ''}`}>Day 4 - Part Time</Link></li>

                    <li><Link to="/training/schedule/day-five" className={`${pathname.match('/training/schedule/day-five') ? 'active' : ''}`}>Day 5 - Part Time</Link></li>

                    <li><Link to="/training/schedule/day-six" className={`${pathname.match('/training/schedule/day-six') ? 'active' : ''}`}>Day 6 - Full Time</Link></li>

                    <li><Link to="/training/schedule/day-seven" className={`${pathname.match('/training/schedule/day-seven') ? 'active' : ''}`}>Day 7 - Full Time</Link></li>

                    <li><Link to="/training/schedule/day-eight" className={`${pathname.match('/training/schedule/day-eight') ? 'active' : ''}`}>Day 8 - Full Time</Link></li>

                    <li><Link to="/training/schedule/day-nine" className={`${pathname.match('/training/schedule/day-nine') ? 'active' : ''}`}>Day 9 - Full Time</Link></li>

                    <li><Link to="/training/schedule/day-ten" className={`${pathname.match('/training/schedule/day-ten') ? 'active' : ''}`}>Day 10 - Full Time</Link></li>

                    <li><Link to="/training/schedule/training-status" className={`${pathname.match('/training/schedule/training-status') ? 'active' : ''}`}>Training Progress</Link></li>

                    {/* <li><Link to="/training/schedule/final-accessment" className={`${pathname.match('/training/schedule/final-accessment') ? 'active' : ''}`}>Final Accessment</Link></li> */}

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default DetailLeftPanel;

