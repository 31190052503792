import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './adsnavbar.scss';

const ItTeamMainMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK Global CRM";
    }, [pathname]);

    // const { allBadge } = useSelector(state => state.tasks);
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-adsNavbar">
                <Route>
                    <ul>
                        <li><Link to="/recruitment/ads/summary" className={`${(segment3 === 'summary') ? 'white-active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="/recruitment/ads/templates/summary" className={`${pathname.match('/templates') ? 'white-active' : ''}`}>Templates</Link></li>
                        {/* <li><Link to="/recruitment/ads/resources/summary" className={`${pathname.match('/resources') ? 'white-active' : ''}`}>Ad Portals</Link></li> */}
                        <li><Link to="/recruitment/ads/titles/summary" className={`${pathname.match('/titles') ? 'white-active' : ''}`}>Ad Titles</Link></li>
                        {/* <li><Link to="/recruitment/ads/saved-ads/summary" className={(segment3 === "saved-ads") ? 'white-active' : ''}>Saved Ads</Link></li>
                        <li><Link to="/recruitment/ads/todays-tracker" className={`${pathname.match('/todays-tracker') ? 'white-active' : ''}`}>Today Posting</Link></li> */}
                        <li><Link to="/recruitment/ads/live-tracker" className={`${pathname.match('/live-tracker') ? 'white-active' : ''}`}>Live Ads</Link></li>
                        <li><Link to="/recruitment/ads/apps-tracker/daily-tracker" className={`${pathname.match('/apps-tracker') ? 'white-active' : ''}`}> Trackers</Link></li>
                        <li><Link to="/recruitment/ads/campaigns/live" className={(segment3 === "campaigns") ? 'white-active' : ''}>Campaigns</Link></li>
                        <li><Link to="/recruitment/ads/ads-credits" className={`${pathname.match('/ads-credits') ? 'white-active' : ''}`}>Ads Credits</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default ItTeamMainMenu;