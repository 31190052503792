import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/dkglobaladmin/KnowledgeBaseMenu';

const AdminKnowleadgeBaseLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header mainheaderLink="" moduleLink="/dkglobaladmin/dashboard/module" linkcolor="#3c4c62" mainheaderTitle="KNOWLEDGE BASE" textcolor="#144773" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default AdminKnowleadgeBaseLayout;
