import React from "react";
import { Link, Route } from "react-router-dom";
import "../../trainingdetail.scss";

const ActionDetailLeftPanel = () => {
  let pathname = window.location.pathname;
  return (
    <React.Fragment>
      <ul>
        <Route>
          <li>
            <Link
              to="/training/recruiters/action"
              className={`${
                pathname.match("/training/recruiters/action") ? "active" : ""
              }`}
            >
              Overview
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/sequence"
              className={`${
                pathname.match("/training/recruiters/sequence") ? "active" : ""
              }`}
            >
              Sequence
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/set-priority"
              className={`${
                pathname.match("/training/recruiters/set-priority")
                  ? "active"
                  : ""
              }`}
            >
              Set Priority
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/recruiters-action-priority"
              className={`${
                pathname.match(
                  "/training/recruiters/recruiters-action-priority"
                )
                  ? "active"
                  : ""
              }`}
            >
              Action Priority
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/create-new-task"
              className={`${
                pathname.match("/training/recruiters/create-new-task")
                  ? "active"
                  : ""
              }`}
            >
              Create New Task
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/follow-workplan"
              className={`${
                pathname.match("/training/recruiters/follow-workplan")
                  ? "active"
                  : ""
              }`}
            >
              Follow Workplan
            </Link>
          </li>
        </Route>
      </ul>
    </React.Fragment>
  );
};

export default ActionDetailLeftPanel;
