import React from 'react';
import './casestatus.scss';

const CaseStatusDropDwn = ({ caseStatus, value, statusBgColour, statusTxColour }) => {

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: statusBgColour, borderColor: statusBgColour, color: statusTxColour }}>
                {
                    (value == "Blank") ? "" : value
                }
            </div>
        </>
    )
}

export default CaseStatusDropDwn;