import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { clearState, summaryFunction } from "../../../../../slice/accounting/salesBooks";
import { FaSyncAlt } from "react-icons/fa";
import './summaryreport.scss';

const BusinessOverviewSummary = () => {
    const dispatch = useDispatch();
    const { isLoading, businessSummary, isDataLoading } = useSelector(state => state.salesBooks);

    //const [isFeeFilter, setIsFeeFilter] = useState(false);
    //const [feeFilterText, setFeeFilterText] = useState('');
    const [isReasonFilter, setIsReasonFilter] = useState(false);
    const [reasonFilterText, setReasonFilterText] = useState('');

    const [isYearFilter, setIsYearFilter] = useState(false);
    const [yearFilterText, setYearFilterText] = useState('');

    const [isMonthsFilter, setIsMonthsFilter] = useState(false);
    const [monthsFilterText, setMonthsFilterText] = useState('');

    const [isCaseStatusFilter, setIsCaseStatusFilter] = useState(false);
    const [caseStatusFilterText, setCaseStatusFilterText] = useState('');

    const [isReportFilter, setIsReportFilter] = useState(false);
    const [reportFilterText, setReportFilterText] = useState('');

    const [isCaseProgressFilter, setIsCaseProgressFilter] = useState(false);
    const [caseProgressFilterText, setCaseProgressFilterText] = useState('');

    const [isStageFilter, setIsStageFilter] = useState(false);
    const [stageFilterText, setStageFilterText] = useState('');

    const [isLanguageFilter, setIsLanguageFilter] = useState(false);
    const [languageFilterText, setLanguageFilterText] = useState('');

    const [isCompanyFilter, setIsCompanyFilter] = useState(false);
    const [companyFilterText, setCompanyFilterText] = useState('');

    const [isProjectFilter, setIsProjectFilter] = useState(false);
    const [projectFilterText, setProjectFilterText] = useState('');

    const [isRoleTypeFilter, setIsRoleTypeFilter] = useState(false);
    const [roleTypeFilterText, setRoleTypeFilterText] = useState('');

    const [isCvSourceFilter, setIsCvSourceFilter] = useState(false);
    const [cvSourceFilterText, setCvSourceFilterText] = useState('');

    const [isAccountManagerFilter, setIsAccountManagerFilter] = useState(false);
    const [isAccountManagerImgFilter, setIsAccountManagerImgFilter] = useState('');
    const [accountManagerFilterText, setAccountManagerFilterText] = useState('');

    const [isRecruiterFilter, setIsRecruiterFilter] = useState(false);
    const [recruiterFilterText, setRecruiterFilterText] = useState('');
    const [recruiterImgFilter, setRecruiterImgFilter] = useState('');

    const [companyList, setCompanyList] = useState([]);
    const [langList, setLangList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [roleTypeList, setRoleTypetList] = useState([]);
    const [cvSourcetList, setCvSourcetList] = useState([]);
    const [amUserList, setAmUserList] = useState([]);
    const [rcUserList, setRcUserList] = useState([]);
    const [fullfeeList, setfullfeeList] = useState([]);

    const [jobOfferList, setJobOfferList] = useState([]);
    const [refusedList, setRefusedList] = useState([]);
    const [joinedList, setJoinedList] = useState([]);
    const [creditList, setCreditList] = useState([]);
    const [finalFeeList, setFinalFeeList] = useState([]);
    const [reasonList, setReasonList] = useState([]);

    const [filter, setFilter] = useState({});
    const [filterTags, setFilterTags] = useState([]);

    const [list, setList] = useState([]);
    const [reportTypelist, setReportTypeList] = useState([]);
    const [flag, setFlag] = useState(0);


    useLayoutEffect(() => {
        if (!isLoading && businessSummary.length > 0) {
            setList([])
            setReportTypeList(businessSummary)
        }

    }, [businessSummary, isLoading, isDataLoading])

    useEffect(() => {
        const fetchList = async () => {
            try {
                setList([])
                await dispatch(summaryFunction())
            } catch (err) {
            }
        }
        fetchList()

    }, [dispatch])

     function filterBy(list, criteria) {
        return list.filter(candidate =>
            Object.keys(criteria).every(key =>
                candidate[key] === criteria[key]
            )
        );
    }


    const selectFilter = (e) => {

        const type = e.target.dataset.type;
        const value = e.target.dataset.value;

        switch (type) {

            case 'accountManager':
                setIsAccountManagerFilter(true)
                setIsAccountManagerImgFilter(e.target.dataset.img)
                setAccountManagerFilterText(e.target.dataset.name)
                setFilter({ ...filter, ['accountManager']: Number(e.target.dataset.value) })
                break;

            case 'recruiters':
                setIsRecruiterFilter(true)
                setRecruiterImgFilter(e.target.dataset.img)
                setRecruiterFilterText(e.target.dataset.name)
                setFilter({ ...filter, ['recruiters']: Number(e.target.dataset.value) })
                break;

            case 'reason':
                if (value == "") {
                    setReasonFilterText("Blank")
                } else {
                    setReasonFilterText(value)
                }
                setIsReasonFilter(true)
                setFilter({ ...filter, ['reason']: e.target.dataset.value })
                break;

            case 'year':
                if (value == "") {
                    setYearFilterText("Blank")
                } else {
                    setYearFilterText(value)
                }
                setIsYearFilter(true)
                setFilter({ ...filter, ['year']: e.target.dataset.value })
                break;

            case 'month':
                if (value == "") {
                    setMonthsFilterText("Blank")
                } else {
                    setMonthsFilterText(value)
                }
                setIsMonthsFilter(true)
                setFilter({ ...filter, ['month']: e.target.dataset.value })
                break;

            case 'caseStatus':
                if (value == "") {
                    setCaseStatusFilterText("Blank")
                } else {
                    setCaseStatusFilterText(value)
                }
                setIsCaseStatusFilter(true)
                setFilter({ ...filter, ['caseStatus']: e.target.dataset.value })
                break;

            case 'progress':
                if (value == "") {
                    setCaseProgressFilterText("Blank")
                } else {
                    setCaseProgressFilterText(value)
                }
                setIsCaseProgressFilter(true)
                setFilter({ ...filter, ['progress']: e.target.dataset.value })
                break;

            case 'caseStage':
                setIsStageFilter(true)
                setStageFilterText(e.target.dataset.value)
                setFilter({ ...filter, ['caseStage']: e.target.dataset.value })
                break;

            case 'language':
                setIsLanguageFilter(true)
                setLanguageFilterText(e.target.dataset.value)
                setFilter({ ...filter, ['language']: e.target.dataset.value })
                break;

            case 'company':
                setIsCompanyFilter(true)
                setCompanyFilterText(e.target.dataset.value)
                setFilter({ ...filter, ['company']: e.target.dataset.value })
                break;

            case 'project':
                setIsProjectFilter(true)
                setProjectFilterText(e.target.dataset.value)
                setFilter({ ...filter, ['project']: e.target.dataset.value })
                break;

            case 'roleType':
                setIsRoleTypeFilter(true)
                setRoleTypeFilterText(e.target.dataset.value)
                setFilter({ ...filter, ['roleType']: e.target.dataset.value })
                break;

            case 'cvSource':
                setIsCvSourceFilter(true)
                setCvSourceFilterText(e.target.dataset.value)
                setFilter({ ...filter, ['cvSource']: e.target.dataset.value })
                break;

            default:
                setFlag(1)
                setIsReportFilter(true)
                setReportFilterText(e.target.dataset.value)
                setList(businessSummary)
                setFilter({ ...filter, ['caseStatus']: e.target.dataset.value })
                break;
        }
    }
    const resetAllFilter = async (e) => {
        setFlag(0)
        setIsReportFilter(false)
        setReportFilterText('')
        setList([])
        setFilter({})
        setFilterTags([])
        setIsAccountManagerFilter(false)
setAccountManagerFilterText('')

        setIsRecruiterFilter(false)
        setRecruiterFilterText('')

        setIsReasonFilter(false)
        setReasonFilterText('')

        setIsYearFilter(false)
        setYearFilterText('')

        setIsMonthsFilter(false)
        setMonthsFilterText('')

        setIsCaseStatusFilter(false)
        setCaseStatusFilterText('')

        setIsCaseProgressFilter(false)
        setCaseProgressFilterText('')

        setIsStageFilter(false)
        setStageFilterText('')

        setIsLanguageFilter(false)
        setLanguageFilterText('')

        setIsCompanyFilter(false)
        setCompanyFilterText('')

        setIsProjectFilter(false)
        setProjectFilterText('')

        setIsRoleTypeFilter(false)
        setRoleTypeFilterText('')

        setIsCvSourceFilter(false)
        setCvSourceFilterText('')
       
    }
    const removeTag = async (e) => {
        const type = e.target.dataset.type
        delete filter[type];

        switch (type) {

            case 'accountManager':
                setIsAccountManagerFilter(false)
                setAccountManagerFilterText('')
                break;

            case 'recruiters':
                setIsRecruiterFilter(false)
                setRecruiterFilterText('')
                break;

            case 'reason':
                setIsReasonFilter(false)
                setReasonFilterText('')
                break;
            case 'year':
                setIsYearFilter(false)
                setYearFilterText('')
                break;

            case 'month':
                setIsMonthsFilter(false)
                setMonthsFilterText('')
                break;

            case 'caseStatus':
                setIsCaseStatusFilter(false)
                setCaseStatusFilterText('')
                break;

            case 'progress':
                setIsCaseProgressFilter(false)
                setCaseProgressFilterText('')
                break;

            case 'caseStage':
                setIsStageFilter(false)
                setStageFilterText('')
                break;

            case 'language':
                setIsLanguageFilter(false)
                setLanguageFilterText('')
                break;

            case 'company':
                setIsCompanyFilter(false)
                setCompanyFilterText('')
                break;

            case 'project':
                setIsProjectFilter(false)
                setProjectFilterText('')
                break;

            case 'roleType':
                setIsRoleTypeFilter(false)
                setRoleTypeFilterText('')
                break;

            case 'cvSource':
                setIsCvSourceFilter(false)
                setCvSourceFilterText('')
                break;

            default:
                break;
        }
       // setFlag(0)
        setFilter(filter)
        setList(filterBy(businessSummary, filter))
        setFilterTags(filterTags.filter((item) => item.type !== type))
    }

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    useEffect(() => {
        if (isEmpty(filter)) {
            setTimeout(() => {
                setList(businessSummary)
            }, 500)
            // setTimeout(() => {
            //     selectFilter()
            // }, 500)
        } else {
            setList(filterBy(list, filter))
        }
    }, [filter])

    const [stageList, setStage] = useState([]);

    useEffect(() => {

        const reasonResult = [];
        const reasonFeemap = new Map();
        for (const item of list) {
            if (!reasonFeemap.has(item.reason)) {
                reasonFeemap.set(item.reason, true);
                reasonResult.push({
                    reason: item.reason
                });
            }
        }
        reasonResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setReasonList(reasonResult)

        var totalInterviews = 0;
        var totalJobOffer = 0;
        var totalRefused = 0;
        var totalJoined = 0;
        var totalCredit = 0;
        var totalFinalFee = 0;

        for (const item of list) {
            if (item.type == "interviews") {
                if (item.fullFee != null) {
                    totalInterviews += parseInt(item.fullFee)
                }
            };
        }
        for (const item of list) {
            if (item.type == "job-offers") {
                if (item.fullFee != null) {
                    totalJobOffer += parseInt(item.fullFee)
                }
            };
        }
        for (const item of list) {
            if (item.type == "job-offers") {
                if (item.refused != null) {
                    totalRefused += parseInt(item.refused)
                }
            };
        }
        for (const item of list) {
            if (item.type == "joined") {
                if (item.fullFee != null) {
                    totalJoined += parseInt(item.fullFee)
                }
            };
        }
        for (const item of list) {
            if (item.type == "joined") {
                if (item.credit != null) {
                    totalCredit += parseInt(item.credit)
                }
            };
        }
        for (const item of list) {
            if (item.type == "final-fee") {
                if (item.fullFee != null) {
                    totalFinalFee += parseInt(item.fullFee)
                }
            };
        }

        setfullfeeList(totalInterviews)
        setJobOfferList(totalJobOffer)
        setRefusedList(totalRefused)
        setJoinedList(totalJoined)
        setCreditList(totalCredit)
        setFinalFeeList(totalFinalFee)

        const rcResult = [];
        const rcmap = new Map();
        for (const item of list) {
            if (!rcmap.has(item.rcId)) {
                rcmap.set(item.rcId, true);
                rcResult.push({
                    id: item.rcId,
                    name: item.rcFirstName,
                    userImg: item.rcProfileImage,
                    count: list.filter(function (val) {
                        return val.rcId === item.rcId;
                    }).length
                });
            }
        }
        rcResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setRcUserList(rcResult)

        const amResult = [];
        const amMap = new Map();
        for (const item of list) {
            if (!amMap.has(item.amId)) {
                amMap.set(item.amId, true);
                amResult.push({
                    id: item.amId,
                    name: item.amFirstName,
                    userImg: item.amProfileImage,
                    count: list.filter(function (val) {
                        return val.amId === item.amId;
                    }).length
                });
            }
        }
        amResult.sort(function (a, b) {
            return b.count - a.count;
        });

        setAmUserList(amResult)

        const stageResult = [];
        const stagemap = new Map();
        for (const item of list) {
            if (!stagemap.has(item.caseStage)) {
                stagemap.set(item.caseStage, true);
                stageResult.push({
                    name: (item.caseStage != '') ? item.caseStage : 'Blank',
                    count: list.filter(function (val) {
                        return val.caseStage === item.caseStage;
                    }).length
                });
            }
        }
        stageResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setStage(stageResult)

        const langResult = [];
        const langmap = new Map();
        for (const item of list) {
            if (!langmap.has(item.language)) {
                langmap.set(item.language, true);
                langResult.push({
                    name: (item.language != '') ? item.language : 'Blank',
                    count: list.filter(function (val) {
                        return val.language === item.language;
                    }).length
                });
            }
        }
        langResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setLangList(langResult)

        const companyResult = [];
        const companymap = new Map();
        for (const item of list) {
            if (!companymap.has(item.company)) {
                companymap.set(item.company, true);
                companyResult.push({
                    name: (item.company != '') ? item.company : 'Blank',
                    count: list.filter(function (val) {
                        return val.company === item.company;
                    }).length
                });
            }
        }
        companyResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setCompanyList(companyResult)

        const projectResult = [];
        const projectmap = new Map();
        for (const item of list) {
            if (!projectmap.has(item.project)) {
                projectmap.set(item.project, true);
                projectResult.push({
                    name: (item.project != '') ? item.project : 'Blank',
                    count: list.filter(function (val) {
                        return val.project === item.project;
                    }).length
                });
            }
        }
        projectResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setProjectList(projectResult)

        const roleTypeResult = [];
        const roleTypemap = new Map();
        for (const item of list) {
            if (!roleTypemap.has(item.roleType)) {
                roleTypemap.set(item.roleType, true);
                roleTypeResult.push({
                    name: (item.roleType != '') ? item.roleType : 'Blank',
                    count: list.filter(function (val) {
                        return val.roleType === item.roleType;
                    }).length
                });
            }
        }
        roleTypeResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setRoleTypetList(roleTypeResult)

        const cvSourceResult = [];
        const cvSourcemap = new Map();
        for (const item of list) {
            if (!cvSourcemap.has(item.cvSource)) {
                cvSourcemap.set(item.cvSource, true);
                cvSourceResult.push({
                    name: (item.cvSource != '') ? item.cvSource : 'Blank',
                    count: list.filter(function (val) {
                        return val.cvSource === item.cvSource;
                    }).length
                });
            }
        }
        cvSourceResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setCvSourcetList(cvSourceResult)

    }, [list])

    return (
        <>
            <div className="dk-salesReportMain">
                <div className="d-flex w-100">
                    <div className="dk-leftBoxes-main">
                        <div className="dk-leftBoxes-panel">
                            <div className="dk-leftBoxes-cols">
                                <div className={`${(!isReportFilter) ? 'title' : 'dk-toptitle-filterBg'}`}>
                                    <div className="dk-colstitle" style={{ color: "#333", position: "relative" }}>REPORT TYPE</div>
                                    {(flag === 1) ? <span  onClick={resetAllFilter} className="dk-totalCvCount dkg-business-syncIcon" style={{ color: "#848484",cursor: "pointer" }}>
                                        <FaSyncAlt />
                                    </span> : 
                                    <span className="dk-totalCvCount dkg-business-syncIcon" style={{ color: "#848484",cursor: "not-allowed" }}>
                                      <FaSyncAlt />
                                     </span>
                                    }
                                    
                                </div>
                                <ul className='dkg-businessbook-ul'>
                                    {(!isReportFilter) ? <>
                                        {
                                            (reportTypelist.filter(function (val) {
                                                return val.type == "qualified";
                                            }).length != 0) ?
                                                <li data-type="reportStatus" data-value="Qualified" style={{ backgroundColor: "#5c737d", color: "#fff" }} onClick={selectFilter}>
                                                    <span className="leftItem d-flex align-items-center" data-type="reportStatus" data-value="Qualified" onClick={selectFilter}>
                                                        Qualified
                                                    </span>
                                                    <span className="rightItem" data-type="reportStatus" data-value="Qualified" style={{ color: "#fff" }} onClick={selectFilter}>
                                                        {
                                                            reportTypelist.filter(function (val) {
                                                                return val.caseStatus == "Qualified";
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                                : null
                                        }
                                        {
                                            (reportTypelist.filter(function (val) {
                                                return val.type == "interviews";
                                            }).length != 0) ?
                                                <li data-type="reportStatus" data-value="Interviews" onClick={selectFilter} style={{ backgroundColor: "#316a67", color: "#fff" }}>
                                                    <span className="leftItem d-flex align-items-center" data-type="reportStatus" data-value="Interviews" onClick={selectFilter}>
                                                        Interviews
                                                    </span>
                                                    <span className="rightItem" data-type="reportStatus" data-value="Interviews" onClick={selectFilter} style={{ color: "#fff" }}>
                                                        {
                                                            reportTypelist.filter(function (val) {
                                                                return val.caseStatus == "Interviews";
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                                : null
                                        }
                                        {
                                            (reportTypelist.filter(function (val) {
                                                return val.type == "job-offers";
                                            }).length != 0) ? <li data-type="reportStatus" data-value="Job Offer" onClick={selectFilter} style={{ backgroundColor: "#629bd3", color: "#fff" }}>
                                                <span className="leftItem d-flex align-items-center" data-type="reportStatus" data-value="Job Offer" onClick={selectFilter}>
                                                    Job Offers
                                                </span>
                                                <span className="rightItem" data-type="reportStatus" data-value="Job Offer" onClick={selectFilter} style={{ color: "#fff" }}>
                                                    {
                                                        reportTypelist.filter(function (val) {
                                                            return val.caseStatus == "Job Offer";
                                                        }).length
                                                    }
                                                </span>
                                            </li> : null
                                        }
                                        {
                                            (reportTypelist.filter(function (val) {
                                                return val.type == "joined";
                                            }).length != 0) ? <li data-type="reportStatus" data-value="Joined" onClick={selectFilter} style={{ backgroundColor: "#4768b0", color: "#fff" }}>
                                                <span className="leftItem d-flex align-items-center" data-type="reportStatus" data-value="Joined" onClick={selectFilter}>
                                                    Joined
                                                </span>
                                                <span className="rightItem" data-type="reportStatus" data-value="Joined" onClick={selectFilter} style={{ color: "#fff" }}>
                                                    {
                                                        reportTypelist.filter(function (val) {
                                                            return val.caseStatus == "Joined";
                                                        }).length
                                                    }
                                                </span>
                                            </li> : null
                                        }
                                        {
                                            (reportTypelist.filter(function (val) {
                                                return val.type == "final-fee";
                                            }).length != 0) ? <li data-type="reportStatus" data-value="Final Fee" onClick={selectFilter} style={{ backgroundColor: "#81906c", color: "#fff" }}>
                                                <span className="leftItem d-flex align-items-center" data-type="reportStatus" data-value="Final Fee" onClick={selectFilter}>
                                                    Final Fee
                                                </span>
                                                <span className="rightItem" data-type="reportStatus" data-value="Final Fee" style={{ color: "#fff" }} onClick={selectFilter}>
                                                    {
                                                        reportTypelist.filter(function (val) {
                                                            return val.caseStatus == "Final Fee";
                                                        }).length
                                                    }
                                                </span>
                                            </li> : null
                                        }
                                    </>
                                        :
                                        <>
                                            <li className='dkg-business-select-li'>
                                                <div className='dk-dbsuMM-filterHeader'>
                                                    {(reportFilterText == "None") ? "Blank" : reportFilterText}
                                                    <span><i className='fa fa-times-circle  ml-2' data-type="reportStatus" onClick={resetAllFilter}></i></span>
                                                </div>
                                                <span className="dk-totalCvCount">
                                                    {(flag === 1) ? list.length : 0}
                                                </span>
                                            </li>
                                        </>
                                    }
                                </ul>
                            </div>
                            <div className="dk-leftBoxes-cols">
                                <div className={`${(!isYearFilter) ? 'title' : 'dk-toptitle-filterBg'}`}>
                                    <div className="dk-colstitle">Years</div>

                                    <span className="dk-totalCvCount">
                                        {(flag === 1) ? list.length : 0}
                                    </span>
                                </div>
                                {
                                    (flag === 1) ?
                                        <ul className='dkg-businessbook-ul'>
                                            {
                                                (!isYearFilter) ? <> {
                                                    (list.filter(function (val) {
                                                        return val.year == "2023";
                                                    }).length != 0) ?
                                                        <li data-type="year" data-value="2023" onClick={selectFilter}>
                                                            <span className="leftItem d-flex align-items-center" data-type="year" data-value="2023" onClick={selectFilter}>
                                                                2023
                                                            </span>
                                                            <span className="rightItem" data-type="year" data-value="2023" onClick={selectFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.year == "2023";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                }

                                                    {
                                                        (list.filter(function (val) {
                                                            return val.year == "2022";
                                                        }).length != 0) ? <li data-type="year" data-value="2022" onClick={selectFilter}>
                                                            <span className="leftItem d-flex align-items-center" data-type="year" data-value="2022" onClick={selectFilter}>

                                                                2022
                                                            </span>
                                                            <span className="rightItem" data-type="year" data-value="2022" onClick={selectFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.year == "2022";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li>
                                                            : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.year == "2021";
                                                        }).length != 0) ? <>
                                                            <li data-type="year" data-value="2021" onClick={selectFilter}>
                                                                <span className="leftItem d-flex align-items-center" data-type="year" data-value="2021" onClick={selectFilter}>

                                                                    2021
                                                                </span>
                                                                <span className="rightItem" data-type="year" data-value="2021" onClick={selectFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.year == "2021";
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </li></> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.year == "2020";
                                                        }).length != 0) ? <li data-type="year" data-value="2020" onClick={selectFilter}>
                                                            <span className="leftItem d-flex align-items-center" data-type="year" data-value="2020" onClick={selectFilter}>
                                                                2020
                                                            </span>
                                                            <span className="rightItem" data-type="year" data-value="2020" onClick={selectFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.year == "2020";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li>
                                                            : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.year == "2019";
                                                        }).length != 0) ? <li data-type="year" data-value="2019" onClick={selectFilter}>
                                                            <span className="leftItem d-flex align-items-center" data-type="year" data-value="2019" onClick={selectFilter}>
                                                                2019
                                                            </span>
                                                            <span className="rightItem" data-type="year" data-value="2019" onClick={selectFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.year == "2019";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li>
                                                            : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.year == "2018";
                                                        }).length != 0) ? <li data-type="year" data-value="2018" onClick={selectFilter}>
                                                            <span className="leftItem d-flex align-items-center" data-type="year" data-value="2018" onClick={selectFilter}>
                                                                2018
                                                            </span>
                                                            <span className="rightItem" data-type="year" data-value="2018" onClick={selectFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.year == "2018";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li>
                                                            : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.year == "2017";
                                                        }).length != 0) ? <li data-type="year" data-value="2017" onClick={selectFilter}>
                                                            <span className="leftItem d-flex align-items-center" data-type="year" data-value="2017" onClick={selectFilter}>
                                                                2017
                                                            </span>
                                                            <span className="rightItem" data-type="year" data-value="2017" onClick={selectFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.year == "2017";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li>
                                                            : null
                                                    }

                                                </>
                                                    :
                                                    <>
                                                        <li className='dkg-business-select-li'>
                                                            <div className='dk-dbsuMM-filterHeader '>
                                                                {(yearFilterText == "None") ? "Blank" : yearFilterText}
                                                                <span><i className='fa fa-times-circle  ml-2' data-type="year" onClick={removeTag}></i></span>
                                                            </div>
                                                            <span className="rightItem">
                                                                {(flag === 1) ? list.length : 0}
                                                            </span>
                                                        </li>
                                                    </>

                                            }

                                        </ul>
                                        :
                                        <ul className='dkg-businessbook-ul'></ul>
                                }
                            </div>
                            <div className="dk-leftBoxes-cols">
                                <div className={`${(!isAccountManagerFilter) ? 'title' : 'dk-toptitle-filterBg'}`}>
                                    <div className="dk-colstitle">Account M</div>
                                    <span className="dk-totalCvCount">
                                        {(flag === 1) ? list.length : 0}
                                    </span>
                                </div>
                                {
                                    (flag === 1) ?
                                        <ul className='dkg-businessbook-ul dkg-businessbook-acc-ul'>
                                            {
                                                (!isAccountManagerFilter) ? <>
                                                    {
                                                        amUserList.map((item, index) => (
                                                            <li key={`amFilter${index}`} data-value={item.id} data-name={item.name} data-img={item.userImg} data-type="accountManager" onClick={selectFilter}>
                                                                <span className="recuriterItem d-flex align-items-center" data-value={item.id} data-name={item.name} data-img={item.userImg} data-type="accountManager" onClick={selectFilter}>
                                                                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} alt={item.name} className="recruitersImg" data-value={item.id} data-name={item.name} data-img={item.userImg} data-type="accountManager" onClick={selectFilter} />
                                                                    {item.name}
                                                                </span>
                                                                <span className="rightItem" data-value={item.id} data-name={item.name} data-img={item.userImg} data-type="accountManager" onClick={selectFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.accountManager === item.id;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </li>
                                                        ))}
                                                </>
                                                    :
                                                    <>
                                                        <li className='dkg-business-select-li'>
                                                            <div className='dk-dbsuMM-filterHeader'>
                                                                {(accountManagerFilterText == "None") ? "Blank" : <><img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + isAccountManagerImgFilter} className="recruitersImg" data-type="accountManager" />{accountManagerFilterText}</>}
                                                                <span><i className='fa fa-times-circle  ml-2' data-type="accountManager" onClick={removeTag}></i></span>
                                                            </div>
                                                            <span className="dk-totalCvCount">
                                                                {(flag === 1) ? list.length : 0}
                                                            </span>
                                                        </li>
                                                    </>

                                            }

                                        </ul>
                                        :
                                        <ul className='dkg-businessbook-ul dkg-businessbook-acc-ul'></ul>
                                }
                            </div>
                            <div className="dk-leftBoxes-cols">
                                <div className={`${(!isCaseStatusFilter) ? 'title' : 'dk-toptitle-filterBg'}`}>
                                    <div className="dk-colstitle">Status</div>
                                    <span className="dk-totalCvCount">
                                        {(flag === 1) ? list.length : 0}
                                    </span>
                                </div>
                                {
                                    (flag === 1) ?
                                        <ul className='dkg-businessbook-ul'>
                                            {(!isCaseStatusFilter) ? <>
                                                {
                                                    (list.filter(function (val) {
                                                        return val.type == "interviews";
                                                    }).length != 0) ? <li data-type="caseStatus" data-value="Interviews" onClick={selectFilter} style={{ backgroundColor: "#316a67", color: "#fff" }}>
                                                        <span className="leftItem d-flex align-items-center" data-type="caseStatus" data-value="Interviews" onClick={selectFilter}>
                                                            Interviews
                                                        </span>
                                                        <span className="rightItem" data-type="caseStatus" data-value="Interviews" onClick={selectFilter} style={{ color: "#fff" }}>
                                                            {
                                                                list.filter(function (val) {
                                                                    return val.caseStatus == "Interviews";
                                                                }).length
                                                            }
                                                        </span>
                                                    </li>
                                                        : null
                                                }
                                                {
                                                    (list.filter(function (val) {
                                                        return val.type == "job-offers";
                                                    }).length != 0) ? <li data-type="caseStatus" data-value="Job Offer" onClick={selectFilter} style={{ backgroundColor: "#629bd3", color: "#fff" }}>
                                                        <span className="leftItem d-flex align-items-center" data-type="caseStatus" data-value="Job Offer" onClick={selectFilter}>
                                                            Job Offers
                                                        </span>
                                                        <span className="rightItem" data-type="caseStatus" data-value="Job Offer" onClick={selectFilter} style={{ color: "#fff" }}>
                                                            {
                                                                list.filter(function (val) {
                                                                    return val.caseStatus == "Job Offer";
                                                                }).length
                                                            }
                                                        </span>
                                                    </li> : null
                                                }
                                                {
                                                    (list.filter(function (val) {
                                                        return val.type == "joined";
                                                    }).length != 0) ? <li data-type="caseStatus" data-value="Joined" onClick={selectFilter} style={{ backgroundColor: "#4768b0", color: "#fff" }}>
                                                        <span className="leftItem d-flex align-items-center" data-type="caseStatus" data-value="Joined" onClick={selectFilter}>
                                                            Joined
                                                        </span>
                                                        <span className="rightItem" data-type="caseStatus" data-value="Joined" onClick={selectFilter} style={{ color: "#fff" }}>
                                                            {
                                                                list.filter(function (val) {
                                                                    return val.caseStatus == "Joined";
                                                                }).length
                                                            }
                                                        </span>
                                                    </li> : null
                                                }
                                                {
                                                    (list.filter(function (val) {
                                                        return val.type == "final-fee";
                                                    }).length != 0) ? <li data-type="caseStatus" data-value="Final Fee" onClick={selectFilter} style={{ backgroundColor: "#81906c", color: "#fff" }}>
                                                        <span className="leftItem d-flex align-items-center" data-type="caseStatus" data-value="Final Fee" onClick={selectFilter}>
                                                            Final Fee
                                                        </span>
                                                        <span className="rightItem" data-type="caseStatus" data-value="Final Fee" style={{ color: "#fff" }} onClick={selectFilter}>
                                                            {
                                                                list.filter(function (val) {
                                                                    return val.caseStatus == "Final Fee";
                                                                }).length
                                                            }
                                                        </span>
                                                    </li> : null
                                                }
                                            </>
                                                :
                                                <>
                                                    <li className='dkg-business-select-li'>
                                                        <div className='dk-dbsuMM-filterHeader'>
                                                            {(caseStatusFilterText == "None") ? "Blank" : caseStatusFilterText}
                                                            <span><i className='fa fa-times-circle  ml-2' data-type="caseStatus" onClick={removeTag}></i></span>
                                                        </div>
                                                        <span className="dk-totalCvCount">
                                                            {(flag === 1) ? list.length : 0}
                                                        </span>
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                        :
                                        <ul className='dkg-businessbook-ul'></ul>
                                }
                            </div>

                            <div className="dk-leftBoxes-cols">
                                <div className={`${(!isCaseProgressFilter) ? 'title' : 'dk-toptitle-filterBg'}`}>
                                    <div className="dk-colstitle">PROGRESS</div>
                                    <span className="dk-totalCvCount">
                                        {(flag === 1) ? list.length : 0}
                                    </span>
                                </div>
                                {
                                    (flag === 1) ?
                                        <ul className='dkg-businessbook-ul'>
                                            {
                                                (!isCaseProgressFilter) ? <>
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.progress == "In Progress";
                                                        }).length != 0) ? <li data-type="progress" data-value="In Progress" onClick={selectFilter} style={{ backgroundColor: "#d58b5d", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="progress" data-value="In Progress" onClick={selectFilter}>
                                                                In Progress
                                                            </span>
                                                            <span className="rightItem" data-type="progress" data-value="In Progress" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.progress == "In Progress";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.progress == "Successful";
                                                        }).length != 0 ? <li data-type="progress" data-value="Successful" onClick={selectFilter} style={{ backgroundColor: "#316a67", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="progress" data-value="Successful" onClick={selectFilter}>
                                                                Successful
                                                            </span>
                                                            <span className="rightItem" data-type="progress" data-value="Successful" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.progress == "Successful";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null)
                                                    }

                                                    {
                                                        (list.filter(function (val) {
                                                            return val.progress == "Unsucessful";
                                                        }).length != 0) ? <li data-type="progress" data-value="Unsucessful" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="progress" data-value="Unsucessful" onClick={selectFilter}>
                                                                Unsucessful
                                                            </span>
                                                            <span className="rightItem" data-type="progress" data-value="Unsucessful" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.progress == "Unsucessful";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.progress == "Accepeted";
                                                        }).length != 0) ? <li data-type="progress" data-value="Accepeted" onClick={selectFilter} style={{ backgroundColor: "#629bd3", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="progress" data-value="Accepeted" onClick={selectFilter}>
                                                                Accepeted
                                                            </span>
                                                            <span className="rightItem" data-type="progress" data-value="Accepeted" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.progress == "Accepeted";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.progress == "Refused";
                                                        }).length != 0) ? <li data-type="progress" data-value="Refused" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="progress" data-value="Refused" onClick={selectFilter}>
                                                                Refused
                                                            </span>
                                                            <span className="rightItem" data-type="progress" data-value="Refused" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.progress == "Refused";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.progress == "Full Fee Due";
                                                        }).length != 0 ? <li data-type="progress" data-value="Full Fee Due" onClick={selectFilter} style={{ backgroundColor: "#4768b0", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="progress" data-value="Full Fee Due" onClick={selectFilter}>
                                                                Full Fee Due
                                                            </span>
                                                            <span className="rightItem" data-type="progress" data-value="Full Fee Due" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.progress == "Full Fee Due";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null)
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.progress == "Credit";
                                                        }).length != 0) ? <li data-type="progress" data-value="Credit" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="progress" data-value="Credit" onClick={selectFilter}>
                                                                Credit
                                                            </span>
                                                            <span className="rightItem" data-type="progress" data-value="Credit" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.progress == "Credit";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.progress == "Full Fee";
                                                        }).length != 0) ? <li data-type="progress" data-value="Full Fee" onClick={selectFilter} style={{ backgroundColor: "#63745c", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="progress" data-value="Full Fee" onClick={selectFilter}>
                                                                Full Fee
                                                            </span>
                                                            <span className="rightItem" data-type="progress" data-value="Full Fee" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.progress == "Full Fee";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.progress == "Partial Fee";
                                                        }).length != 0) ? <li data-type="progress" data-value="Partial Fee" onClick={selectFilter} style={{ backgroundColor: "#63745c", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="progress" data-value="Partial Fee" onClick={selectFilter}>
                                                                Partial Fee
                                                            </span>
                                                            <span className="rightItem" data-type="progress" data-value="Partial Fee" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.progress == "Partial Fee";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }

                                                </>
                                                    :
                                                    <>
                                                        <li className='dkg-business-select-li'>
                                                            <div className='dk-dbsuMM-filterHeader'>
                                                                {(caseProgressFilterText == "None") ? "Blank" : caseProgressFilterText}
                                                                <span><i className='fa fa-times-circle  ml-2' data-type="progress" onClick={removeTag}></i></span>
                                                            </div>
                                                            <span className="dk-totalCvCount">
                                                                {(flag === 1) ? list.length : 0}
                                                            </span>
                                                        </li>
                                                    </>
                                            }


                                        </ul>
                                        :
                                        <ul className='dkg-businessbook-ul'></ul>
                                }
                            </div>

                            <div className="dk-leftBoxes-cols">
                                <div className={`${(!isStageFilter) ? 'title' : 'dk-toptitle-filterBg'}`}>
                                    <div className="dk-colstitle">Stage</div>
                                    <span className="dk-totalCvCount">
                                        {(flag === 1) ? list.length : 0}
                                    </span>
                                </div>
                                {
                                    (flag === 1) ?
                                        <ul className='dkg-businessbook-ul'>
                                            {
                                                (!isStageFilter) ? <>

                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "TBS";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="TBS" onClick={selectFilter} style={{ backgroundColor: "#d58b5d", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="TBS" onClick={selectFilter}>
                                                                TBS
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="TBS" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "TBS";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Scheduled";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Scheduled" onClick={selectFilter} style={{ backgroundColor: "#d58b5d", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Scheduled" onClick={selectFilter}>
                                                                Scheduled
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Scheduled" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Scheduled";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }

                                                    {(list.filter(function (val) {
                                                        return val.caseStage == "No Open Job";
                                                    }).length != 0) ? <li data-type="caseStage" data-value="No Open Job" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                        <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="No Open Job" onClick={selectFilter}>
                                                            No Open Job
                                                        </span>
                                                        <span className="rightItem" data-type="caseStage" data-value="No Open Job" onClick={selectFilter} style={{ color: "#fff" }}>
                                                            {
                                                                list.filter(function (val) {
                                                                    return val.caseStage == "No Open Job";
                                                                }).length
                                                            }
                                                        </span>
                                                    </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Failed Assessment";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Failed Assessment" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Failed Assessment" onClick={selectFilter}>
                                                                Failed Assessment
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Failed Assessment" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Failed Assessment";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Candidate Withdraw";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Candidate Withdraw" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Candidate Withdraw" onClick={selectFilter}>
                                                                Candidate Withdraw
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Candidate Withdraw" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Candidate Withdraw";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Not Responsding";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Not Responsding" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Not Responsding" onClick={selectFilter}>
                                                                Not Responsding
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Not Responsding" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Not Responsding";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Project is Filled";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Project is Filled" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Project is Filled" onClick={selectFilter}>
                                                                Project is Filled
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Project is Filled" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Project is Filled";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Project is Cancelled";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Project is Cancelled" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Project is Cancelled" onClick={selectFilter}>
                                                                Project is Cancelled
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Project is Cancelled" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Project is Cancelled";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }

                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Rejected by Client";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Rejected by Client" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Rejected by Client" onClick={selectFilter}>
                                                                Rejected by Client
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Rejected by Client" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Rejected by Client";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Job Offer";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Job Offer" onClick={selectFilter} style={{ backgroundColor: "#316a67", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Job Offer" onClick={selectFilter}>
                                                                Job Offer
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Job Offer" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Job Offer";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "To be Joined";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="To be Joined" onClick={selectFilter} style={{ backgroundColor: "#629bd3", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="To be Joined" onClick={selectFilter}>
                                                                To be Joined
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="To be Joined" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "To be Joined";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }

                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Joined";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Joined" onClick={selectFilter} style={{ backgroundColor: "#629bd3", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Joined" onClick={selectFilter}>
                                                                Joined
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Joined" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Joined";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Do not Like Salary";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Do not Like Salary" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Do not Like Salary" onClick={selectFilter}>
                                                                Do not Like Salary
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Do not Like Salary" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Do not Like Salary";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Got Another Job";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Got Another Job" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Got Another Job" onClick={selectFilter}>
                                                                Got Another Job
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Got Another Job" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Got Another Job";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Personal Reason";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Personal Reason" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Personal Reason" onClick={selectFilter}>
                                                                Personal Reason
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Personal Reason" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Personal Reason";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Changed Interest";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Changed Interest" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Changed Interest" onClick={selectFilter}>
                                                                Changed Interest
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Changed Interest" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Changed Interest";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Medical Reason";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Medical Reason" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Medical Reason" onClick={selectFilter}>
                                                                Medical Reason
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Medical Reason" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Medical Reason";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Joining Dates";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Joining Dates" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Joining Dates" onClick={selectFilter}>
                                                                Joining Dates
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Joining Dates" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Joining Dates";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "In Probation Period";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="In Probation Period" onClick={selectFilter} style={{ backgroundColor: "#d58b5d", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="In Probation Period" onClick={selectFilter}>
                                                                In Probation Period
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="In Probation Period" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "In Probation Period";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Completed Probation";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Completed Probation" onClick={selectFilter} style={{ backgroundColor: "#4768b0", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Completed Probation" onClick={selectFilter}>
                                                                Completed Probation
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Completed Probation" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Completed Probation";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "100%";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="100%" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="100%" onClick={selectFilter}>
                                                                100%
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="100%" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "100%";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "75%";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="75%" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="75%" onClick={selectFilter}>
                                                                75%
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="75%" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "75%";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "50%";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="50%" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="50%" onClick={selectFilter}>
                                                                50%
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="50%" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "50%";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "25%";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="25%" onClick={selectFilter} style={{ backgroundColor: "#da3d5e", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="25%" onClick={selectFilter}>
                                                                25%
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="25%" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "25%";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Paid";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Paid" onClick={selectFilter} style={{ backgroundColor: "#63745c", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Paid" onClick={selectFilter}>
                                                                Paid
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Paid" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Paid";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.caseStage == "Unpaid";
                                                        }).length != 0) ? <li data-type="caseStage" data-value="Unpaid" onClick={selectFilter} style={{ backgroundColor: "#63745c", color: "#fff" }}>
                                                            <span className="leftItem d-flex align-items-center" data-type="caseStage" data-value="Unpaid" onClick={selectFilter}>
                                                                Unpaid
                                                            </span>
                                                            <span className="rightItem" data-type="caseStage" data-value="Unpaid" onClick={selectFilter} style={{ color: "#fff" }}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.caseStage == "Unpaid";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }

                                                </>
                                                    :
                                                    <>
                                                        <li className='dkg-business-select-li'>
                                                            <div className='dk-dbsuMM-filterHeader'>
                                                                {(stageFilterText == "None") ? "Blank" : stageFilterText}
                                                                <span><i className='fa fa-times-circle  ml-2' data-type="caseStage" onClick={removeTag}></i></span>
                                                            </div>
                                                            <span className="dk-totalCvCount">
                                                                {(flag === 1) ? list.length : 0}
                                                            </span>
                                                        </li>
                                                    </>
                                            }

                                        </ul>
                                        :
                                        <ul className='dkg-businessbook-ul'></ul>
                                }
                            </div>

                            <div className="dk-leftBoxes-cols">
                                <div className={`${(!isReasonFilter) ? 'title' : 'dk-toptitle-filterBg'}`}>
                                    <div className="dk-colstitle">REASON</div>
                                    <span className="dk-totalCvCount">
                                        {(flag === 1) ? list.length : 0}
                                    </span>
                                </div>
                                {
                                    (flag === 1) ?
                                        <ul className='dkg-businessbook-ul'>
                                            {
                                                (!isReasonFilter) ? <>
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.reason == "N/A";
                                                        }).length != 0) ? <li data-type="reason" data-value="N/A" onClick={selectFilter} >
                                                            <span className="leftItem d-flex align-items-center" data-type="reason" data-value="N/A" onClick={selectFilter}>
                                                                N/A
                                                            </span>
                                                            <span className="rightItem" data-type="reason" data-value="N/A" onClick={selectFilter} >
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.reason == "N/A";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.reason == "Client";
                                                        }).length != 0) ? <li data-type="reason" data-value="Client" onClick={selectFilter} >
                                                            <span className="leftItem d-flex align-items-center" data-type="reason" data-value="Client" onClick={selectFilter}>
                                                                Client
                                                            </span>
                                                            <span className="rightItem" data-type="reason" data-value="Client" onClick={selectFilter} >
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.reason == "Client";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.reason == "Recruiter";
                                                        }).length != 0) ? <li data-type="reason" data-value="Recruiter" onClick={selectFilter} >
                                                            <span className="leftItem d-flex align-items-center" data-type="reason" data-value="Recruiter" onClick={selectFilter}>
                                                                Recruiter
                                                            </span>
                                                            <span className="rightItem" data-type="reason" data-value="Recruiter" onClick={selectFilter} >
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.reason == "Recruiter";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.reason == "Candidate";
                                                        }).length != 0) ? <li data-type="reason" data-value="Candidate" onClick={selectFilter} >
                                                            <span className="leftItem d-flex align-items-center" data-type="reason" data-value="Candidate" onClick={selectFilter}>
                                                                Candidate
                                                            </span>
                                                            <span className="rightItem" data-type="reason" data-value="Candidate" onClick={selectFilter} >
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.reason == "Candidate";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                </>
                                                    :
                                                    <>
                                                        <li className='dkg-business-select-li'>
                                                            <div className='dk-dbsuMM-filterHeader'>
                                                                {(reasonFilterText == "None") ? "Blank" : reasonFilterText}
                                                                <span><i className='fa fa-times-circle  ml-2' data-type="reason" onClick={removeTag}></i></span>
                                                            </div>
                                                            <span className="dk-totalCvCount">
                                                                {(flag === 1) ? list.length : 0}
                                                            </span>
                                                        </li>
                                                    </>
                                            }
                                        </ul>
                                        :
                                        <ul className='dkg-businessbook-ul'></ul>
                                }

                            </div>
                            {/* <div className="dk-leftBoxes-cols">
                                <div className={`${(!isFeeFilter) ? 'title' : 'dk-toptitle-filterBg'}`}>
                              
                                    <div className="dk-colstitle">TOTAL FEE</div>
                                    <span className="dk-totalCvCount">
                                        { (flag===1) ? list.length : 0 }
                                    </span>
                                </div>
                                <ul className='dkg-businessbook-ul'>
                                    {
                                        fullfeeList !=0 ? <li data-type="Sendouts" data-value="Full Fee" >
                                        <span className="leftItem d-flex align-items-center" data-type="Sendouts" data-value="Full Fee" >
                                            Interviews
                                        </span>
                                        <span className="rightItem" data-type="Sendouts" data-value="Full Fee" >                                           
                                            {fullfeeList}
                                        </span>
                                    </li> : null
                                    }
                                    
                                    {
                                        jobOfferList !=0 ? <li data-type="fullFee" data-value="Job Offers" >
                                        <span className="leftItem d-flex align-items-center" data-type="fullFee" data-value="Job Offers" >
                                            Job Offers
                                        </span>
                                        <span className="rightItem" data-type="fullFee" data-value="Job Offers" >
                                            {
                                                jobOfferList
                                            }
                                        </span>
                                    </li> : null
                                    }                                    
                                    {
                                        refusedList !=0 ? <li data-type="fullFee" data-value="Refused" >
                                        <span className="leftItem d-flex align-items-center" data-type="fullFee" data-value="Credit" >
                                            Refused
                                        </span>
                                        <span className="rightItem" data-type="fullFee" data-value="Credit" >
                                            {                                               
                                                refusedList
                                            }
                                        </span>
                                    </li> : null
                                    }                                    
                                    {
                                        joinedList !=0 ? <li data-type="fullFee" data-value="Joined" >
                                        <span className="leftItem d-flex align-items-center" data-type="fullFee" data-value="Joined" >
                                            Joined
                                        </span>
                                        <span className="rightItem" data-type="fullFee" data-value="Joined" >
                                            {
                                                joinedList
                                            }
                                        </span>
                                    </li> : null
                                    }
                                   
                                    {
                                        creditList !=0 ? <li data-type="fullFee" data-value="Credit" >
                                        <span className="leftItem d-flex align-items-center" data-type="fullFee" data-value="Credit" >
                                            Credit
                                        </span>
                                        <span className="rightItem" data-type="fullFee" data-value="Credit" >
                                            {
                                                creditList
                                            }
                                        </span>
                                    </li> : null
                                    }                                    
                                    {
                                        finalFeeList !=0 ? <li data-type="fullFee" data-value="Final Fee" >
                                        <span className="leftItem d-flex align-items-center" data-type="fullFee" data-value="Final Fee" >
                                            Final Fee
                                        </span>
                                        <span className="rightItem" data-type="fullFee" data-value="Final Fee" >
                                            {
                                                finalFeeList
                                            }
                                        </span>
                                    </li> : null
                                    }
                                    

                                </ul>
                            </div> */}
                        </div>
                        <div className="dk-leftBoxes-panel">
                            <div className="dk-leftBoxes-cols">
                                <div className={`${(!isCvSourceFilter) ? 'title' : 'dk-toptitle-filterBg'}`}>
                                    <div className="dk-colstitle">CV Source</div>
                                    <span className="dk-totalCvCount">
                                        {(flag === 1) ? list.length : 0}
                                    </span>
                                </div>
                                {
                                    (flag === 1) ?
                                        <ul className='dkg-businessbook-ul dkg-business-language-ul'>
                                            {
                                                (!isCvSourceFilter) ? <>
                                                    {
                                                        cvSourcetList.map((item, index) => (
                                                            <li key={`cvSource${index}`} data-type="cvSource" data-value={item.name} onClick={selectFilter}>
                                                                <span className="leftItem d-flex align-items-center" data-type="cvSource" data-value={item.name} onClick={selectFilter}>
                                                                    {item.name}
                                                                </span>
                                                                <span className="rightItem" data-type="cvSource" data-value={item.name} onClick={selectFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.cvSource === item.name;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </li>
                                                        ))}
                                                </>
                                                    :
                                                    <>
                                                        <li className='dkg-business-select-li'>
                                                            <div className='dk-dbsuMM-filterHeader'>
                                                                {(cvSourceFilterText == "None") ? "Blank" : cvSourceFilterText}
                                                                <span><i className='fa fa-times-circle  ml-2' data-type="cvSource" onClick={removeTag}></i></span>
                                                            </div>
                                                            <span className="dk-totalCvCount">
                                                                {(flag === 1) ? list.length : 0}
                                                            </span>
                                                        </li>
                                                    </>
                                            }

                                        </ul>
                                        :
                                        <ul className='dkg-businessbook-ul dkg-business-language-ul'></ul>
                                }

                            </div>
                            <div className="dk-leftBoxes-cols">
                                <div className={`${(!isMonthsFilter) ? 'title' : 'dk-toptitle-filterBg'}`}>

                                    <div className="dk-colstitle">Months</div>
                                    <span className="dk-totalCvCount">
                                        {(flag === 1) ? list.length : 0}
                                    </span>
                                </div>
                                {
                                    (flag === 1) ?
                                        <ul className='dkg-businessbook-ul dkg-business-language-ul'>
                                            {
                                                (!isMonthsFilter) ? <>
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.month == "January";
                                                        }).length != 0) ? <li data-type="month" data-value="January" onClick={selectFilter} >
                                                            <span className="leftItem d-flex align-items-center" data-type="month" data-value="January" onClick={selectFilter}>
                                                                January
                                                            </span>
                                                            <span className="rightItem" data-type="month" data-value="January" onClick={selectFilter} >
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.month == "January";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.month == "February";
                                                        }).length != 0) ? <li data-type="month" data-value="February" onClick={selectFilter} >
                                                            <span className="leftItem d-flex align-items-center" data-type="month" data-value="February" onClick={selectFilter}>

                                                                February
                                                            </span>
                                                            <span className="rightItem" data-type="month" data-value="February" onClick={selectFilter} >
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.month == "February";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.month == "March";
                                                        }).length != 0) ? <li data-type="month" data-value="March" onClick={selectFilter} >
                                                            <span className="leftItem d-flex align-items-center" data-type="month" data-value="March" onClick={selectFilter}>
                                                                March
                                                            </span>
                                                            <span className="rightItem" data-type="month" data-value="March" onClick={selectFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.month == "March";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }

                                                    {
                                                        (list.filter(function (val) {
                                                            return val.month == "April";
                                                        }).length != 0) ? <li data-type="month" data-value="April" onClick={selectFilter} >
                                                            <span className="leftItem d-flex align-items-center" data-type="month" data-value="April" onClick={selectFilter}>
                                                                April
                                                            </span>
                                                            <span className="rightItem" data-type="month" data-value="April" onClick={selectFilter} >
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.month == "April";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        list.filter(function (val) {
                                                            return val.month == "May";
                                                        }).length != 0 ? <li data-type="month" data-value="May" onClick={selectFilter}>
                                                            <span className="leftItem d-flex align-items-center" data-type="month" data-value="May" onClick={selectFilter}>
                                                                May
                                                            </span>
                                                            <span className="rightItem" data-type="month" data-value="May" onClick={selectFilter} >
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.month == "May";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        list.filter(function (val) {
                                                            return val.month == "June";
                                                        }).length != 0 ? <li data-type="month" data-value="June" onClick={selectFilter} >
                                                            <span className="leftItem d-flex align-items-center" data-type="month" data-value="June" onClick={selectFilter}>
                                                                June
                                                            </span>
                                                            <span className="rightItem" data-type="month" data-value="June" onClick={selectFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.month == "June";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        list.filter(function (val) {
                                                            return val.month == "July";
                                                        }).length != 0 ? <li data-type="month" data-value="July" onClick={selectFilter} >
                                                            <span className="leftItem d-flex align-items-center" data-type="month" data-value="July" onClick={selectFilter}>
                                                                July
                                                            </span>
                                                            <span className="rightItem" data-type="month" data-value="July" onClick={selectFilter} >
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.month == "July";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        list.filter(function (val) {
                                                            return val.month == "August";
                                                        }).length != 0 ? <li data-type="month" data-value="August" onClick={selectFilter} >
                                                            <span className="leftItem d-flex align-items-center" data-type="month" data-value="August" onClick={selectFilter}>
                                                                August
                                                            </span>
                                                            <span className="rightItem" data-type="month" data-value="August" onClick={selectFilter} >
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.month == "August";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.month == "September";
                                                        }).length != 0) ? <li data-type="month" data-value="September" onClick={selectFilter} >
                                                            <span className="leftItem d-flex align-items-center" data-type="month" data-value="September" onClick={selectFilter}>
                                                                September
                                                            </span>
                                                            <span className="rightItem" data-type="month" data-value="September" onClick={selectFilter} >
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.month == "September";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.month == "October";
                                                        }).length != 0) ? <li data-type="month" data-value="October" onClick={selectFilter} >
                                                            <span className="leftItem d-flex align-items-center" data-type="month" data-value="October" onClick={selectFilter}>
                                                                October
                                                            </span>
                                                            <span className="rightItem" data-type="month" data-value="October" onClick={selectFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.month == "October";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.month == "November";
                                                        }).length != 0) ? <li data-type="month" data-value="November" onClick={selectFilter} >
                                                            <span className="leftItem d-flex align-items-center" data-type="month" data-value="November" onClick={selectFilter}>
                                                                November
                                                            </span>
                                                            <span className="rightItem" data-type="month" data-value="November" onClick={selectFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.month == "November";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                    {
                                                        (list.filter(function (val) {
                                                            return val.month == "December";
                                                        }).length != 0) ? <li data-type="month" data-value="December" onClick={selectFilter}>
                                                            <span className="leftItem d-flex align-items-center" data-type="month" data-value="December" onClick={selectFilter}>
                                                                December
                                                            </span>
                                                            <span className="rightItem" data-type="month" data-value="December" onClick={selectFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.month == "December";
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li> : null
                                                    }
                                                </>
                                                    :
                                                    <>
                                                        <li className='dkg-business-select-li'>
                                                            <div className='dk-dbsuMM-filterHeader'>
                                                                {(monthsFilterText == "None") ? "Blank" : monthsFilterText}
                                                                <span><i className='fa fa-times-circle  ml-2' data-type="month" onClick={removeTag}></i></span>
                                                            </div>
                                                            <span className="dk-totalCvCount">
                                                                {(flag === 1) ? list.length : 0}
                                                            </span>
                                                        </li>
                                                    </>
                                            }

                                        </ul>
                                        :
                                        <ul className='dkg-businessbook-ul dkg-business-language-ul'></ul>
                                }

                            </div>


                            <div className="dk-leftBoxes-cols">
                                <div className={`${(!isRecruiterFilter) ? 'title' : 'dk-toptitle-filterBg'}`}>

                                    <div className="dk-colstitle">Recruiter</div>
                                    <span className="dk-totalCvCount">
                                        {(flag === 1) ? list.length : 0}
                                    </span>
                                </div>
                                {
                                    (flag === 1) ?
                                        <ul className='dkg-businessbook-ul dkg-business-language-ul'>
                                            {
                                                (!isRecruiterFilter) ? <>
                                                    {
                                                        rcUserList.map((item, index) => (
                                                            <li key={`rcFilter${index}`} data-value={item.id} data-name={item.name} data-img={item.userImg} data-type="recruiters" onClick={selectFilter}>
                                                                <span className="recuriterItem d-flex align-items-center" data-value={item.id} data-name={item.name} data-img={item.userImg} data-type="recruiters" onClick={selectFilter}>
                                                                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} alt={item.name} className="recruitersImg" data-value={item.id} data-name={item.name} data-img={item.userImg} data-type="recruiters" onClick={selectFilter} />
                                                                    {item.name}
                                                                </span>
                                                                <span className="rightItem" data-value={item.id} data-name={item.name} data-img={item.userImg} data-type="recruiters" onClick={selectFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.recruiters === item.id;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </li>
                                                        ))}
                                                </>
                                                    :
                                                    <>
                                                        <li className='dkg-business-select-li'>
                                                            <div className='dk-dbsuMM-filterHeader'>
                                                                {(recruiterFilterText == "None") ? "Blank" : <><img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + recruiterImgFilter} className="recruitersImg" data-type="accountManager" />{recruiterFilterText}</>}
                                                                <span><i className='fa fa-times-circle  ml-2' data-type="recruiters" onClick={removeTag}></i></span>
                                                            </div>
                                                            <span className="dk-totalCvCount">
                                                                {(flag === 1) ? list.length : 0}
                                                            </span>
                                                        </li>
                                                    </>
                                            }

                                        </ul>
                                        :
                                        <ul className='dkg-businessbook-ul dkg-business-language-ul'></ul>
                                }

                            </div>

                            <div className="dk-leftBoxes-cols">
                                <div className={`${(!isCompanyFilter) ? 'title' : 'dk-toptitle-filterBg'}`}>

                                    <div className="dk-colstitle">Company</div>
                                    <span className="dk-totalCvCount">
                                        {(flag === 1) ? list.length : 0}
                                    </span>
                                </div>
                                {
                                    (flag === 1) ? <ul className='dkg-businessbook-ul dkg-business-language-ul'>
                                        {
                                            (!isCompanyFilter) ? <>
                                                {
                                                    companyList.map((item, index) => (
                                                        <li key={`company${index}`} data-type="company" data-value={item.name} onClick={selectFilter}>
                                                            <span className="leftItem d-flex align-items-center" data-type="company" data-value={item.name} onClick={selectFilter}>
                                                                {item.name}
                                                            </span>
                                                            <span className="rightItem" data-type="company" data-value={item.name} onClick={selectFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.company === item.name;
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li>
                                                    ))}
                                            </>
                                                :
                                                <>
                                                    <li className='dkg-business-select-li'>
                                                        <div className='dk-dbsuMM-filterHeader'>
                                                            {(companyFilterText == "None") ? "Blank" : companyFilterText}
                                                            <span><i className='fa fa-times-circle  ml-2' data-type="company" onClick={removeTag}></i></span>
                                                        </div>
                                                        <span className="dk-totalCvCount">
                                                            {(flag === 1) ? list.length : 0}
                                                        </span>
                                                    </li>
                                                </>
                                        }

                                    </ul>
                                        :
                                        <ul className='dkg-businessbook-ul dkg-business-language-ul'></ul>
                                }

                            </div>
                            <div className="dk-leftBoxes-cols">
                                <div className={`${(!isLanguageFilter) ? 'title' : 'dk-toptitle-filterBg'}`}>

                                    <div className="dk-colstitle">Language</div>
                                    <span className="dk-totalCvCount">
                                        {(flag === 1) ? list.length : 0}
                                    </span>
                                </div>
                                {
                                    (flag === 1) ?
                                        <ul className='dkg-businessbook-ul dkg-business-language-ul'>
                                            {
                                                (!isLanguageFilter) ? <>
                                                    {langList.map((item, index) => (
                                                        <li key={`language${index}`} data-type="language" data-value={item.name} onClick={selectFilter}>
                                                            <span className="recuriterItem d-flex align-items-center" data-type="language" data-value={item.name} onClick={selectFilter}>
                                                                {item.name}
                                                            </span>
                                                            <span className="rightItem" data-type="language" data-value={item.name} onClick={selectFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.language == item.name;
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li>
                                                    ))}
                                                </>
                                                    :
                                                    <>
                                                        <li className='dkg-business-select-li'>
                                                            <div className='dk-dbsuMM-filterHeader'>
                                                                {(languageFilterText == "None") ? "Blank" : languageFilterText}
                                                                <span><i className='fa fa-times-circle  ml-2' data-type="language" onClick={removeTag}></i></span>
                                                            </div>
                                                            <span className="dk-totalCvCount">
                                                                {(flag === 1) ? list.length : 0}
                                                            </span>
                                                        </li>
                                                    </>
                                            }
                                        </ul>
                                        : <ul className='dkg-businessbook-ul dkg-business-language-ul'></ul>
                                }

                            </div>
                            <div className="dk-leftBoxes-cols">
                                <div className={`${(!isProjectFilter) ? 'title' : 'dk-toptitle-filterBg'}`}>

                                    <div className="dk-colstitle">Project</div>
                                    <span className="dk-totalCvCount">
                                        {(flag === 1) ? list.length : 0}
                                    </span>
                                </div>
                                {
                                    (flag === 1) ?
                                        <ul className='dkg-businessbook-ul dkg-business-language-ul'>
                                            {
                                                (!isProjectFilter) ? <>
                                                    {
                                                        projectList.map((item, index) => (
                                                            <li key={`project${index}`} data-type="project" data-value={item.name} onClick={selectFilter}>
                                                                <span className="leftItem d-flex align-items-center" data-type="project" data-value={item.name} onClick={selectFilter}>
                                                                    {item.name}
                                                                </span>
                                                                <span className="rightItem" data-type="project" data-value={item.name} onClick={selectFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.project === item.name;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </li>
                                                        ))}
                                                </>
                                                    :
                                                    <>
                                                        <li className='dkg-business-select-li'>
                                                            <div className='dk-dbsuMM-filterHeader'>
                                                                {(projectFilterText == "None") ? "Blank" : projectFilterText}
                                                                <span><i className='fa fa-times-circle  ml-2' data-type="project" onClick={removeTag}></i></span>
                                                            </div>
                                                            <span className="dk-totalCvCount">
                                                                {(flag === 1) ? list.length : 0}
                                                            </span>
                                                        </li>
                                                    </>
                                            }

                                        </ul>
                                        : <ul className='dkg-businessbook-ul dkg-business-language-ul'></ul>
                                }

                            </div>

                            <div className="dk-leftBoxes-cols">
                                <div className={`${(!isRoleTypeFilter) ? 'title' : 'dk-toptitle-filterBg'}`}>

                                    <div className="dk-colstitle">Role Type</div>
                                    <span className="dk-totalCvCount">
                                        {(flag === 1) ? list.length : 0}
                                    </span>
                                </div>
                                {
                                    (flag === 1) ? <ul className='dkg-businessbook-ul dkg-business-language-ul'>
                                        {
                                            (!isRoleTypeFilter) ? <>
                                                {
                                                    roleTypeList.map((item, index) => (
                                                        <li key={`roleType${index}`} data-type="roleType" data-value={item.name} onClick={selectFilter}>
                                                            <span className="leftItem d-flex align-items-center" data-type="roleType" data-value={item.name} onClick={selectFilter}>
                                                                {item.name}
                                                            </span>
                                                            <span className="rightItem" data-type="roleType" data-value={item.name} onClick={selectFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.roleType === item.name;
                                                                    }).length
                                                                }
                                                            </span>
                                                        </li>
                                                    ))}
                                            </>
                                                :
                                                <>
                                                    <li className='dkg-business-select-li'>
                                                        <div className='dk-dbsuMM-filterHeader'>
                                                            {(roleTypeFilterText == "None") ? "Blank" : roleTypeFilterText}
                                                            <span><i className='fa fa-times-circle  ml-2' data-type="roleType" onClick={removeTag}></i></span>
                                                        </div>
                                                        <span className="dk-totalCvCount">
                                                            {(flag === 1) ? list.length : 0}
                                                        </span>
                                                    </li>
                                                </>

                                        }

                                    </ul>
                                        : <ul className='dkg-businessbook-ul dkg-business-language-ul'></ul>
                                }

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default BusinessOverviewSummary;