import React from 'react';
import Header from "../../elements/header";
import ItTeamMainMenu from "../../elements/header/navbar/mainMenu/itteammenu/ItTeamMainMenu";

const DkGlobalCrmLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="TECHNICAL -" moduleLink="/dashboard/it-teams" linkcolor="#b55355" mainheaderTitle=" Projects" textcolor="#b55355" />
            <ItTeamMainMenu />
            {children}
        </React.Fragment>
    )
}
export default DkGlobalCrmLayout;