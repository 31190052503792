import React from "react";
import "./status.scss";

const tableStatus = ({ value }) => {
  let bgColor = "";
  switch (value) {
    case "Active":
      bgColor = "#30887e";
      break;

    case "Inactive":
      bgColor = "#b55355";
      break;

    case "Qualify":
      bgColor = "#d58b5d";
      break;

    case "On Hold":
      bgColor = "#8b572a";
      break;

    case "Closed":
      bgColor = "#da3d5e";
      break;
    default:
      bgColor = "#30887e";
      break;

  }


  return (
    <>
      <div
        className="caseDiv"
        style={{ background: bgColor, minHeight: "32px", fontSize: "11px" }}
      >
        {value}
      </div>
    </>
  );
};

export default tableStatus;
