import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const PerformanceDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/training/admins/performance" className={`${pathname.match('/training/admins/performance') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/training/admins/criteria" className={`${pathname.match('/training/admins/criteria') ? 'active' : ''}`}>Criteria</Link></li>

                    <li><Link to="/training/admins/reviews" className={`${pathname.match('/training/admins/reviews') ? 'active' : ''}`}>Reviews</Link></li>

                    <li><Link to="/training/admins/reporting" className={`${pathname.match('/training/admins/reporting') ? 'active' : ''}`}>Reporting</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default PerformanceDetailLeftPanel;

