import React, { useState } from 'react'; 
import Dropdown from 'react-bootstrap/Dropdown'
import { useSelector, useDispatch } from "react-redux";
import Pagination from '@material-ui/lab/Pagination';
import RightFilter from './rightModalFilter'
import DeleteLog from './delete/DeleteLog'
import  './vistors-logs.scss'


const JobseekerAccessLogs = (props) => {
  const dispatch = useDispatch();
  const { isLogs, logsList } = useSelector(
    (state) => state.candidates
  );
  const [pageCount, setPageCount] = useState(1)
  const handlePageChange = () =>{

  }
   console.log(logsList)
  return (
    <>
      <div className="dkg-vistor-login-logs-con">
      <div className="dkg-myreferals-header">
          <div className="dkg-myreferals-headerleftCon">
            <div className="dk-mailshotsSearch mr-2">
              <div className="input-group">
                <input
                  className="form-control"
                  placeholder="Search ..."
                  value=""
                />
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary"
                      type="button"
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
              </div>
                <div className="dkg-crossicon-Con d-none">
                  <i
                    className="fas fa-times-circle"
                    data-type="search text"
                  ></i>
                </div>
            </div>
            {/* <div className="dkg-referal-addnew-btnCon">
                <button  className="dkg-referal-addnew-btn">
                   <i className="fas fa-plus"></i>
                </button>
            </div> */}
          </div>
          <div className="dkg-myreferals-centreCon">
            <div className="dkg-docs-title">
               Visit Logs - 0
            </div>
          </div>
          <div className="dkg-myreferals-rightCon">
             <RightFilter />
            {/* <Dropdown className="dkg-referal-status-drpdwn">
              <Dropdown.Toggle variant="" id="" className="dkg-referal-status-drpdwn-toggle">
              Account Logs 
              </Dropdown.Toggle>
              <Dropdown.Menu className="dkg-referal-status-drpdwn-menu">
                <Dropdown.Item href="#" className="dkg-referal-status-item">Account Logs 1
                  <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">Account Logs 2
                <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">Account Logs 3
                <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
        </div>
        <div className="dkg-vistor-login-logs-tableCon">
          <table className='table dkg-vistor-login-logs-table'>
            <thead>
              <tr>
                <th>SNo</th>
                <th>Account ID</th>
                <th>Name of Candidate</th>
                <th>Date & Time</th>
                <th>Year</th>
                <th>Month</th>
                <th>Country</th>
                <th>City/Town</th>
                <th>Login From</th>
                <th>Duration</th>
                <th>Browser</th>
                <th>IP Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                logsList.length>0 ? logsList.map((log,index)=>(
                  <tr key={index+2}>
                  <td>{index+1}</td>
                  <td>{log.accountId}</td>
                  <td>{log.candidateName}</td>
                  <td>{log.logingdateTime}</td>
                  <td>2024</td>
                  <td>SEP</td>
                  <td>{log.country}</td>
                  <td>{log.city}</td>
                  <td>{log.deviceType} </td>
                  <td>{log.duration}</td>
                  <td>{log.browser}</td>
                  <td>{log.ipAddress}</td>
                  <td>
                      <div className='dkg-jobseeker-action-cols'>
                        <DeleteLog />
                      </div>
                  </td>
                </tr>
                ))
                :
                <tr >
                  <td>1</td>
                  <td>12345</td>
                  <td>Dhirendra (Test Profile1)</td>
                  <td>Oct 1, 2024, 03:38:52</td>
                  <td>2024</td>
                  <td>SEP</td>
                  <td>India</td>
                  <td>Lucknow	</td>
                  <td>desktop</td>
                  <td>1 min : 26 sec</td>
                  <td>Chrome</td>
                  <td>110.226.225.213</td>
                  <td>
                      <div className='dkg-jobseeker-action-cols'>
                        <DeleteLog />
                      </div>
                  </td>
              </tr>
              }
             
            </tbody>
          </table>
        </div>
        <div className="dk-resPaginationCon pagination">
                    <div className="dk-resPagination">
                        <div className="dk-resLeftPag">
                            <div className="dk-texCounter" style={{ color: '#fff !imporant' }}>
                                Showing {' '}{  1} to 4 (of 5 entries)
                            </div>
                        </div>
                        <div className="dk-resrightPag pagination-btns">
                            <Pagination count={pageCount} shape="rounded" onChange={handlePageChange} />
                        </div>
                    </div>
                </div>
      </div>
    </>
  )
}

export default JobseekerAccessLogs