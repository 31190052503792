import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
import './addnew-payment.scss'

import Dropdown from 'react-bootstrap/Dropdown'
import PriorityDropdown from './PriorityDropdown';
import DatePicker from './DatePicker'
import TimePicker from './TimePicker'
//import Recruiter from '../../../../../assets/images/deepak.png';
import SubmitButton from "../../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import moment from "moment-timezone";
import 'date-fns';
import { clearState, taskTypeFetch, addNewTask, taskDetails, updatePaidTask, taskPayMonthFetch, taskPayTypeFetch } from "../../../../../slice/task/paymentTaskSlice";
import { SYSTEM_CONSTANTS } from "../../../../../constants";
import { FaFileAlt } from "react-icons/fa";
import AWS from 'aws-sdk'


const EditPaidPayment = ({ dataRowId, handleClose }) => {

    const dispatch = useDispatch();
    const { isLoading, paymentTaskList, isMoved, message, isTaskUpdate, isTaskDelete, isUpdate, tasksDetails, isTaskInserted, taskTypeList, iscommentCountUpdate, taskPaymentMonthList, taskPayTypeList } = useSelector(state => state.paymentTask);

    const { userInfo } = useSelector(state => state.auth)
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [taskBy, setTaskBy] = useState(tasksDetails.taskBy)
    const [taskByImage, setTaskByImage] = useState(tasksDetails.taskByDetail.profileImage)
    const [taskFor, setTaskFor] = useState(tasksDetails.taskFor)
    const [taskForImage, setTaskForImage] = useState(tasksDetails.taskForDetail.profileImage)
    const [taskType, setPaymentType] = useState(tasksDetails.paymentType)
    const [taskPriority, setTaskPriority] = useState(tasksDetails.priority)
    const [taskStatus, setTaskStatus] = useState(tasksDetails.status)
    const [invoiceNo, setInvoiceNo] = useState(tasksDetails.invoiceNo)
    const [paymentTo, setPaymentTo] = useState(tasksDetails.paymentTo)
    const [paymentMonth, setPaymentMonth] = useState(tasksDetails.paymentMonth)
    const [payType, setPayType] = useState(tasksDetails.payType)

    const [totalAmount, setTotalAmount] = useState(tasksDetails.paymentAmount)
    const [notes, setNotes] = useState(tasksDetails.paymentNotes)
    const [pickerDueTime, setPickerDueTime] = useState(new Date())
    const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('DD MMM YYYY'))
    const [dueTime, setDueTime] = useState(moment(new Date()).format('hh:m A'))

    const [show, setShow] = useState(true);

    const [showAttachment, setShowAttachment] = useState(false)
    const [attachment, setAttachment] = useState('')
    const [attachmentFile, setAttachmentFile] = useState(tasksDetails.attachment)
    const [file, setFile] = useState('')
    const [fileType, setFileType] = useState('')

    const handleTimeChange = (date) => {
        setPickerDueTime(date)
        setDueTime(moment(date).format('hh:m A'));
    };

    const changeTaskPriority = (data) => {
        setTaskPriority(data)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            if (!taskType || !totalAmount) {
                dispatch(showError({ msg: 'All fileds required' }))
            } else {
                setLoading(true);
                if(attachment !=''){
                    try {
                        let s3bucket = new AWS.S3({
                            accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
                            secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
                            Bucket: process.env.REACT_APP_BUCKET_NAME
                        });
            
                        let fileNewName = Date.now() + '-' + attachment;
            
                        s3bucket.createBucket(function () {
                            var params = {
                                Bucket: process.env.REACT_APP_BUCKET_NAME,
                                Key: fileNewName,
                                Body: file,
                                ContentType: fileType
                            };
            
                            s3bucket.upload(params, function (err, data) {
                                if (err) {
                                    console.log(err)
                                } else {
                              
                                     dispatch(updatePaidTask({ id: dataRowId, taskBy, taskFor: 1, status: taskStatus, priority: taskPriority, invoiceNo, paymentType: taskType, paymentTo, paymentAmount: totalAmount, paymentNotes: '', dueTime, dueDate: selectedDate, paymentMonth, payType, attachment: data.key }))
                                }
                            });
                        });
                    } catch (err) {
                        console.log(err)
                    }
                }else{
                         dispatch(updatePaidTask({ id: dataRowId, taskBy, taskFor: 1, status: taskStatus, priority: taskPriority, invoiceNo, paymentType: taskType, paymentTo, paymentAmount: totalAmount, paymentNotes: '', dueTime, dueDate: selectedDate, paymentMonth, payType,attachment: attachmentFile}))
                    }
              
            }
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }

    }

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(taskTypeFetch({ id: SYSTEM_CONSTANTS.PAYMENT_TYPE.ID }))
                await dispatch(taskPayMonthFetch({ id: SYSTEM_CONSTANTS.TASK_PAYMENT_MONTH.ID }))
                await dispatch(taskPayTypeFetch({ id: SYSTEM_CONSTANTS.TASK_PAY_TYPE.ID }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
        if (isUpdate) {
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }
    }, [isUpdate])

    const handleDateChange = (date) => {
        setSelectedDate(moment(date).format('DD MMM YYYY'));
    };

    const onSelectFileByAPI = async (e) => {
        setAttachment(e.target.files[0].name)       
        setFileType(e.target.files[0].type)
        setFile(e.target.files[0])
    }

    const clearAttachmentBtn = ()=>{
       // setShowAttachment(true)
        setAttachmentFile('')
    }

    return (
        <>
            {/* <span onClick={handleShow}><i className="far fa-edit"></i></span> */}
            <Modal show={show} onHide={handleClose} className="dkg-addnew-paymenttask-modal-21">
                <Modal.Header closeButton className="dkg-addnew-payment-modalheader-21">
                    <Modal.Title>Edit Payment Task</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-addnew-payment-modalbody-21">
                    <div className='row'>
                        <div className='col-md-6 pr-2'>
                            <div className='form-group d-flex align-items-center'>
                                <label style={{ minWidth: "135px" }}>Task By</label>
                                <div className='dkg-taskby-u12 dkg-move-centre'>
                                    <div className='dkg-taskby-imgCon'>                                 
                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + taskByImage} alt={tasksDetails.taskByDetail.firstName} className="dkg-taskby-img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-md-6 pl-2'>
                            <div className='form-group'>
                                <label style={{ minWidth: "135px" }}>Task For</label>
                                <div className='dkg-taskby-u12 dkg-move-centre'>
                                    <div className='dkg-taskby-imgCon'>
                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + taskForImage} alt={tasksDetails.taskForDetail.firstName} className="dkg-taskby-img" />                                        
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Task Due On</label>
                                <div className='dkg-addnew-date-timeCon'>
                                    <div className='w-100'>
                                        <DatePicker selectedDate={selectedDate} handleDateChange={handleDateChange} />
                                    </div>
                                    {/* <div className='w-100'>
                                        <TimePicker pickerDueTime={pickerDueTime} handleTimeChange={handleTimeChange} />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Status</label>
                                <div className='dkg-payment-task-drop12'>
                                    <input type="text" className="form-control" style={{ backgroundColor: "#d58b5d", color: "#fff" }} defaultValue={taskStatus} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Priority</label>
                                <div className='dkg-payment-task-drop12'>
                                    <PriorityDropdown taskPriority={taskPriority} changeTaskPriority={changeTaskPriority} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Category</label>
                                <div className='dkg-payment-task-drop12'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" className="form-control">
                                            {taskType}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu >
                                            {
                                                taskTypeList.map((item, index) =>
                                                    <Dropdown.Item key={index} className="form-control" onClick={() => setPaymentType(item.value)}>{item.value}</Dropdown.Item>
                                                )
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Pay Type</label>
                                <div className='dkg-payment-task-drop12'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" className="form-control">
                                            {payType}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu >
                                            {
                                                taskPayTypeList.map((item, index) =>
                                                    <Dropdown.Item key={index} className="form-control" onClick={() => setPayType(item.value)}>{item.value}</Dropdown.Item>
                                                )
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Payment To</label>
                                <input type="text" className="form-control" value={paymentTo} onChange={(e) => setPaymentTo(e.target.value)} />
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>INV Month</label>
                                <div className='dkg-payment-task-drop12'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" className="form-control">
                                            {paymentMonth}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu >
                                            {
                                                taskPaymentMonthList.map((item, index) =>
                                                    <Dropdown.Item key={index} className="form-control" onClick={() => setPaymentMonth(item.value)}>{item.value}</Dropdown.Item>
                                                )
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>INV Number</label>
                                <input type="text" className="form-control" value={invoiceNo} onChange={(e) => setInvoiceNo(e.target.value)} />
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Pay Amount</label>
                                <div className='d-flex w-100'>
                                    <input type="text" className="form-control" value={totalAmount} onChange={(e) => setTotalAmount(e.target.value)} />
                                    <span className='dkg-euro-btnCon'><button className='dkg-euro-btn'>€</button></span>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-md-12'>
                            <div className='form-group' style={{ alignItems: "inherit" }}>
                                <label className=''>Payment Notes</label>
                                <textarea rows="4" className="form-control w-100 taskTitle" placeholder="" value={notes} onChange={(e) => setNotes(e.target.value)}></textarea>
                            </div>
                        </div> */}
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Attachment File</label>   
                                {
                                    attachmentFile ==='' ? <input type="file" id="imageInputAPI" onChange={onSelectFileByAPI}  className="form-control w-100 dkg-payment-attach-input" />
                                    :
                                    <div id="dkg-edit-FileUpload">
                                        <div className="dkg-edit-wrapper">
                                            <div className="dkg-edit-uploaded uploaded--one">
                                                <FaFileAlt />
                                                <div className="dkg-edit-file">
                                                    <div className="dkg-edit-file__name">
                                                        <p>{attachmentFile}</p>
                                                        <i className="fas fa-times" onClick={clearAttachmentBtn}></i>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }         

                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='dkg-create-payment-taskbtncon'>
                                {/* <Link to="#" className='dkg-create-payment-taskbtn'> Update</Link> */}
                                <SubmitButton txt="Update" loading={loading} success={success} onClick={handleSubmit} className="dkg-create-payment-taskbtn" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditPaidPayment