import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData, PostData, PutData, DeleteData } from "../../services";

export const update1stScreaning = createAsyncThunk(
    'prescreanning/allist/update',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/candidate-prescreening-forms/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const update2ndScreaning = createAsyncThunk(
    'prescreanning/2ndLevel/update',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/candidate-prescreening-forms/update/secondleve/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getTextLevel = createAsyncThunk(
  "prescreanning/getText/level",
  async ({ type }, thunkAPI) => {
    const res = await GetData(true, "/screening-forms-text/" + type);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteFunction = createAsyncThunk(
  "prescreanning/delete",
  async ({ id }, thunkAPI) => {
    const res = await DeleteData(true, "/candidate-prescreening-forms/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const CVSourceFilterFirst = createAsyncThunk(
  "prescreanning/CVSource/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
/************** */

export const KeyQualificationFilterFirst = createAsyncThunk(
  "prescreanning/keyQualification/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const KeyQualificationFilterSecond = createAsyncThunk(
  "prescreanning/keyQualification/second",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const KeyQualificationFilterFourth = createAsyncThunk(
  "prescreanning/keyQualification/fourth",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const KeyQualificationFilterFive = createAsyncThunk(
  "prescreanning/keyQualification/Five",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const KeyQualificationFilterThird = createAsyncThunk(
  "prescreanning/keyQualification/third",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
/************** */
export const KeyQualifyFilterFirst = createAsyncThunk(
  "prescreanning/keyQualify/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const KeyQualifyFilterSecond = createAsyncThunk(
  "prescreanning/keyQualify/second",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const KeyQualifyFilterThird = createAsyncThunk(
  "prescreanning/keyQualify/third",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
/********************************** */
export const motivationFilterFirst = createAsyncThunk(
  "prescreanning/motivation/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const motivationFilterSecond = createAsyncThunk(
  "prescreanning/motivation/second",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const motivationFilterThird = createAsyncThunk(
  "prescreanning/motivation/third",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const JobDescriptionFilterFirst = createAsyncThunk(
  "prescreanning/jobdesc/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const JobDescriptionFilterSecond = createAsyncThunk(
  "prescreanning/jobdesc/second",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const JobDescriptionFilterThird = createAsyncThunk(
  "prescreanning/jobdesc/third",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const SalaryBenifitFilterFirst = createAsyncThunk(
  "prescreanning/SalaryBenifit/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const SalaryBenifitFilterSecond = createAsyncThunk(
  "prescreanning/SalaryBenifit/second",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const SalaryBenifitFilterThird = createAsyncThunk(
  "prescreanning/SalaryBenifit/third",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const JobReqFilterFirst = createAsyncThunk(
  "prescreanning/JobReq/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const JobReqFilterSecond = createAsyncThunk(
  "prescreanning/JobReq/second",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const JobReqFilterThird = createAsyncThunk(
  "prescreanning/JobReq/third",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const ReloactionQueryFilterFirst = createAsyncThunk(
  "prescreanning/ReloactionQuery/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const ReloactionQueryFilterSecond = createAsyncThunk(
  "prescreanning/ReloactionQuery/second",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const ReloactionQueryFilterThird = createAsyncThunk(
  "prescreanning/ReloactionQuery/third",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const ProjectStartDateFilterFirst = createAsyncThunk(
  "prescreanning/ProjectStartDate/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const ProjectStartDateFilterSecond = createAsyncThunk(
  "prescreanning/ProjectStartDate/second",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const ProjectStartDateFilterThird = createAsyncThunk(
  "prescreanning/ProjectStartDate/third",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const JoiningDocumentFilterFirst = createAsyncThunk(
  "prescreanning/JoiningDocument/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const JoiningDocumentFilterSecond = createAsyncThunk(
  "prescreanning/JoiningDocument/second",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const JoiningDocumentFilterThird = createAsyncThunk(
  "prescreanning/JoiningDocument/third",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const InterviewProcessFilterFirst = createAsyncThunk(
  "prescreanning/InterviewProcess/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const InterviewProcessFilterSecond = createAsyncThunk(
  "prescreanning/InterviewProcess/second",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const InterviewProcessFilterThird = createAsyncThunk(
  "prescreanning/InterviewProcess/third",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const FullResumePreviewFilterFirst = createAsyncThunk(
  "prescreanning/FullResumePreview/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const FullResumePreviewFilterSecond = createAsyncThunk(
  "prescreanning/FullResumePreview/second",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const FullResumePreviewFilterThird = createAsyncThunk(
  "prescreanning/FullResumePreview/third",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const OtherJobInterviewFilterFirst = createAsyncThunk(
  "prescreanning/OtherJobInterview/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const OtherJobInterviewFilterSecond = createAsyncThunk(
  "prescreanning/OtherJobInterview/second",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const OtherJobInterviewFilterThird = createAsyncThunk(
  "prescreanning/OtherJobInterview/third",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const elevenFirstDropdown = createAsyncThunk(
  "prescreanning/eleven-1/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const elevenSecondDropdown = createAsyncThunk(
  "prescreanning/eleven-2/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const elevenThirdDropdown = createAsyncThunk(
  "prescreanning/eleven-3/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const twelveFirstDropdown = createAsyncThunk(
  "prescreanning/twelve-1/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const twelveSecondDropdown = createAsyncThunk(
  "prescreanning/twelve-2/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const twelveThirdDropdown = createAsyncThunk(
  "prescreanning/twelve-3/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
//
export const thirteenFirstDropdown = createAsyncThunk(
  "prescreanning/thirteen-1/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const thirteenSecondDropdown = createAsyncThunk(
  "prescreanning/thirteen-2/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const thirteenThirdDropdown = createAsyncThunk(
  "prescreanning/thirteen-3/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
//
export const fourteenFirstDropdown = createAsyncThunk(
  "prescreanning/fourteen-1/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const fourteenSecondDropdown = createAsyncThunk(
  "prescreanning/fourteen-2/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const fourteenThirdDropdown = createAsyncThunk(
  "prescreanning/fourteen-3/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
//
export const fifteenFirstDropdown = createAsyncThunk(
  "prescreanning/fifteen-1/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const fifteenSecondDropdown = createAsyncThunk(
  "prescreanning/fifteen-2/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const fifteenThirdDropdown = createAsyncThunk(
  "prescreanning/fifteen-3/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
//
export const ContactPreferenceFilterFirst = createAsyncThunk(
  "prescreanning/ContactPreference/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const ContactPreferenceFilterSecond = createAsyncThunk(
  "prescreanning/ContactPreference/second",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const ContactPreferenceFilterThird = createAsyncThunk(
  "prescreanning/ContactPreference/third",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchScreeningList = createAsyncThunk(
  "prescreanning/list/all",
  async (thunkAPI) => {
    const res = await GetData(true, "/candidate-prescreening-forms");
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const checkScreaningForm = createAsyncThunk(
  "prescreanning/checkScreaning",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/candidate-prescreening-forms/checkscreaningdata/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const secondScreaningfetchById = createAsyncThunk(
  "prescreanning/2ndFormfetchbyidlist",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/candidate-prescreening-forms/check2ndlevel/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const fetchById = createAsyncThunk(
  "prescreanning/fetchbyidlist",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/candidate-prescreening-forms/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const firstLevel5th_1 = createAsyncThunk(
  "prescreanning/1stLevelFive/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLevel5th_2 = createAsyncThunk(
  "prescreanning/1stLevelFive/two",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLevel5th_3 = createAsyncThunk(
  "prescreanning/1stLevelFive/three",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLevel6th_1 = createAsyncThunk(
  "prescreanning/1stLevelSixBlock/one",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const firstLevel8th_1 = createAsyncThunk(
  "prescreanning/1stLevelEightBlock/one",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLevel8th_2 = createAsyncThunk(
  "prescreanning/1stLevelEightBlock/two",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLevel8th_3 = createAsyncThunk(
  "prescreanning/1stLevelEightBlock/three",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
/******************new block */
export const firstLvl9th_1 = createAsyncThunk(
  "prescreanning/9th/one",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl9th_2 = createAsyncThunk(
  "prescreanning/9th/two",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl9th_3 = createAsyncThunk(
  "prescreanning/9th/three",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
//10th
export const firstLvl10th_1 = createAsyncThunk(
  "prescreanning/10th/one",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl10th_2 = createAsyncThunk(
  "prescreanning/10th/two",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl10th_3 = createAsyncThunk(
  "prescreanning/10th/three",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
//11th
export const firstLvl11th_1 = createAsyncThunk(
  "prescreanning/11th/one",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl11th_2 = createAsyncThunk(
  "prescreanning/11th/two",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl11th_3 = createAsyncThunk(
  "prescreanning/11th/three",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
//12th
export const firstLvl12th_1 = createAsyncThunk(
  "prescreanning/12th/one",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl12th_2 = createAsyncThunk(
  "prescreanning/12th/two",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl12th_3 = createAsyncThunk(
  "prescreanning/12th/three",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl12th_4 = createAsyncThunk(
  "prescreanning/12th/fourth",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
//13th
export const firstLvl13th_1 = createAsyncThunk(
  "prescreanning/13th/one",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl13th_2 = createAsyncThunk(
  "prescreanning/13th/two",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl13th_3 = createAsyncThunk(
  "prescreanning/13th/three",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
//14th
export const firstLvl14th_1 = createAsyncThunk(
  "prescreanning/14th/one",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl14th_2 = createAsyncThunk(
  "prescreanning/14th/two",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl14th_3 = createAsyncThunk(
  "prescreanning/14th/three",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
//15th
export const firstLvl15th_1 = createAsyncThunk(
  "prescreanning/15th/one",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl15th_2 = createAsyncThunk(
  "prescreanning/15th/two",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl15th_3 = createAsyncThunk(
  "prescreanning/15th/three",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl15th_4 = createAsyncThunk(
  "prescreanning/15thaa/four",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl15th_5 = createAsyncThunk(
  "prescreanning/15thdd/five",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLvl15th_6 = createAsyncThunk(
  "prescreanning/15th66/six",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
/*************** */
export const firstLevel7th_1 = createAsyncThunk(
  "prescreanning/1stLevelSevenBlock/one",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLevel7th_2 = createAsyncThunk(
  "prescreanning/1stLevelSevenBlock/two",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLevel7th_3 = createAsyncThunk(
  "prescreanning/1stLevelSevenBlock/three",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLevel7th_4 = createAsyncThunk(
  "prescreanning/1stLevelSevenBlock/four",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const firstLevel6th_2 = createAsyncThunk(
  "prescreanning/1stLevelSixBlock/two",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const firstLevel6th_3 = createAsyncThunk(
  "prescreanning/1stLevelSixBlock/three",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const screaningLevelFirst = createAsyncThunk(
  "prescreanning/screaningLevelFirst/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const screaningAvailability = createAsyncThunk(
  "prescreanning/screaningAvailability/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const screaningContactPrefered = createAsyncThunk(
  "prescreanning/screaningPrefered/first",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/admin/backend-value/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateData = createAsyncThunk(
  "prescreanning/updateform/data",
  async (
    {
      id,
      name,
      currentLocation,
      email,
      phoneNo,
      company,
      project,
      roleType,
      language,
      appDate,
      visaStatus,
      recruiter,
      am,
      cvSource,
      key_qualification_1st_field,
      key_qualification_2nd_field,
      key_qualification_3rd_field,
      key_qualification_notes,
      key_qualification_notes_2,
      key_qualification_notes_3,
      key_qualify_1st_field,
      key_qualify_2nd_field,
      key_qualify_3rd_field,
      key_qualify_notes_1,
      key_qualify_notes_2,
      key_qualify_notes_3,
      motivation_1st_field,
      motivation_2nd_field,
      motivation_3rd_field,
      motivation_notes,
      job_desc_1st_field,
      job_desc_2nd_field,
      job_desc_3rd_field,
      job_desc_notes,
      salary_benifits_1st_field,
      salary_benifits_2nd_field,
      salary_benifits_3rd_field,
      salary_benifits_notes,
      job_requirement_1st_field,
      job_requirement_2nd_field,
      job_requirement_3rd_field,
      job_requirement_notes,
      relocation_query_1st_field,
      relocation_query_2nd_field,
      relocation_query_3rd_field,
      relocation_query_notes,
      project_start_date_1st_field,
      project_start_date_2nd_field,
      project_start_date_3rd_field,
      project_start_date_notes,
      joining_docs_1st_field,
      joining_docs_2nd_field,
      joining_docs_3rd_field,
      joining_docs_notes,
      interview_process_1st_field,
      interview_process_2nd_field,
      interview_process_3rd_field,
      interview_process_notes,
      resume_overview_1st_field,
      resume_overview_2nd_field,
      resume_overview_3rd_field,
      resume_overview_notes,
      other_job_interview_1st_field,
      other_job_interview_2nd_field,
      other_job_interview_3rd_field,
      other_job_interview_notes,
      contact_preference_1st_field,
      contact_preference_2nd_field,
      contact_preference_3rd_field,
      contact_preference_notes,
      candidate_concenrs,
      recruiter_observation,
      final_decision,
      motivation_notes_2,
      motivation_notes_3,
      job_desc_notes_2,
      job_desc_notes_3,
      salary_benifits_notes_2,
      salary_benifits_notes_3,
      job_requirement_notes_2,
      job_requirement_notes_3,
      relocation_query_notes_2,
      relocation_query_notes_3,
      project_start_date_notes_2,
      project_start_date_notes_3,
      joining_docs_notes_2,
      joining_docs_notes_3,
      interview_process_notes_2,
      interview_process_notes_3,
      resume_overview_notes_2,
      resume_overview_notes_3,
      other_job_interview_notes_2,
      other_job_interview_notes_3,
      contact_preference_notes_2,
      contact_preference_notes_3,
      firstLanguage,
      secondLanguage,
      qualifications,
      workExp,
      workLocation,
      interviewModes,
      relocationPack,
      candidateForm,
      backgroundCheck,
      jobStartingDates,
      spFirstLanguage,
      spSecondLanguage,
      spQualifications,
      spWorkExp,
      spWorkLocation,
      spInterviewModes,
      spRelocationPack,
      spCandidateForm,
      spBackgroundCheck,
      spJobStartingDates,
      sendoutCanConcerns,
      sendoutCanRequirment,
      sendoutReqObserv,
      sendoutReqSendoutDec,
      screeningFirst,
      screeningAbliFirst,
      screeningPreContact,
      notesScreeningFirst,
      notesScreeningAbliFirst,
      notesScreeningPreContact,
      firstLevelFive,
      firstLevelFive_1,
      firstLevelFive_2,
      firstLevelFive_3,
      firstLevelFive_4,
      firstLevelFive_5,
      firstLevelSix,
      firstLevelSix_1,
      firstLevelSix_2,
      firstLevelSix_3,
      firstLevelSix_4,
      firstLevelSix_5,
      firstLevelSeven,
      firstLevelSeven_1,
      firstLevelSeven_2,
      firstLevelSeven_3,
      firstLevelSeven_4,
      firstLevelSeven_5,
      firstLevelEight,
      firstLevelEight_1,
      firstLevelEight_2,
      firstLevelEight_3,
      firstLevelEight_4,
      firstLevelEight_5,
    },
    thunkAPI
  ) => {
    const res = await PutData(true, "/candidate-prescreening-forms/" + id, {
      name,
      currentLocation,
      email,
      phoneNo,
      company,
      project,
      roleType,
      language,
      appDate,
      visaStatus,
      recruiter,
      am,
      cvSource,
      key_qualification_1st_field,
      key_qualification_2nd_field,
      key_qualification_3rd_field,
      key_qualification_notes,
      key_qualification_notes_2,
      key_qualification_notes_3,
      key_qualify_1st_field,
      key_qualify_2nd_field,
      key_qualify_3rd_field,
      key_qualify_notes_1,
      key_qualify_notes_2,
      key_qualify_notes_3,
      motivation_1st_field,
      motivation_2nd_field,
      motivation_3rd_field,
      motivation_notes,
      job_desc_1st_field,
      job_desc_2nd_field,
      job_desc_3rd_field,
      job_desc_notes,
      salary_benifits_1st_field,
      salary_benifits_2nd_field,
      salary_benifits_3rd_field,
      salary_benifits_notes,
      job_requirement_1st_field,
      job_requirement_2nd_field,
      job_requirement_3rd_field,
      job_requirement_notes,
      relocation_query_1st_field,
      relocation_query_2nd_field,
      relocation_query_3rd_field,
      relocation_query_notes,
      project_start_date_1st_field,
      project_start_date_2nd_field,
      project_start_date_3rd_field,
      project_start_date_notes,
      joining_docs_1st_field,
      joining_docs_2nd_field,
      joining_docs_3rd_field,
      joining_docs_notes,
      interview_process_1st_field,
      interview_process_2nd_field,
      interview_process_3rd_field,
      interview_process_notes,
      resume_overview_1st_field,
      resume_overview_2nd_field,
      resume_overview_3rd_field,
      resume_overview_notes,
      other_job_interview_1st_field,
      other_job_interview_2nd_field,
      other_job_interview_3rd_field,
      other_job_interview_notes,
      contact_preference_1st_field,
      contact_preference_2nd_field,
      contact_preference_3rd_field,
      contact_preference_notes,
      candidate_concenrs,
      recruiter_observation,
      final_decision,
      motivation_notes_2,
      motivation_notes_3,
      job_desc_notes_2,
      job_desc_notes_3,
      salary_benifits_notes_2,
      salary_benifits_notes_3,
      job_requirement_notes_2,
      job_requirement_notes_3,
      relocation_query_notes_2,
      relocation_query_notes_3,
      project_start_date_notes_2,
      project_start_date_notes_3,
      joining_docs_notes_2,
      joining_docs_notes_3,
      interview_process_notes_2,
      interview_process_notes_3,
      resume_overview_notes_2,
      resume_overview_notes_3,
      other_job_interview_notes_2,
      other_job_interview_notes_3,
      contact_preference_notes_2,
      contact_preference_notes_3,
      firstLanguage,
      secondLanguage,
      qualifications,
      workExp,
      workLocation,
      interviewModes,
      relocationPack,
      candidateForm,
      backgroundCheck,
      jobStartingDates,
      spFirstLanguage,
      spSecondLanguage,
      spQualifications,
      spWorkExp,
      spWorkLocation,
      spInterviewModes,
      spRelocationPack,
      spCandidateForm,
      spBackgroundCheck,
      spJobStartingDates,
      sendoutCanConcerns,
      sendoutCanRequirment,
      sendoutReqObserv,
      sendoutReqSendoutDec,
      screeningFirst,
      screeningAbliFirst,
      screeningPreContact,
      notesScreeningFirst,
      notesScreeningAbliFirst,
      notesScreeningPreContact,
      firstLevelFive,
       firstLevelFive_1,
      firstLevelFive_2,
      firstLevelFive_3,
      firstLevelFive_4,
      firstLevelFive_5,
      firstLevelSix,
      firstLevelSix_1,
      firstLevelSix_2,
      firstLevelSix_3,
      firstLevelSix_4,
      firstLevelSix_5,
      firstLevelSeven,
      firstLevelSeven_1,
      firstLevelSeven_2,
      firstLevelSeven_3,
      firstLevelSeven_4,
      firstLevelSeven_5,
      firstLevelEight,
      firstLevelEight_1,
      firstLevelEight_2,
      firstLevelEight_3,
      firstLevelEight_4,
      firstLevelEight_5,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addNewValue = createAsyncThunk(
  "prescreanning/add-new",
  async (
    {
      name,
      currentLocation,
      email,
      phoneNo,
      company,
      project,
      roleType,
      language,
      appDate,
      visaStatus,
      recruiter,
      am,
      cvSource,
      key_qualification_1st_field,
      key_qualification_2nd_field,
      key_qualification_3rd_field,
      key_qualification_notes,
      key_qualification_notes_2,
      key_qualification_notes_3,
      key_qualify_1st_field,
      key_qualify_2nd_field,
      key_qualify_3rd_field,
      key_qualify_notes_1,
      key_qualify_notes_2,
      key_qualify_notes_3,
      motivation_1st_field,
      motivation_2nd_field,
      motivation_3rd_field,
      motivation_notes,
      job_desc_1st_field,
      job_desc_2nd_field,
      job_desc_3rd_field,
      job_desc_notes,
      salary_benifits_1st_field,
      salary_benifits_2nd_field,
      salary_benifits_3rd_field,
      salary_benifits_notes,
      job_requirement_1st_field,
      job_requirement_2nd_field,
      job_requirement_3rd_field,
      job_requirement_notes,
      relocation_query_1st_field,
      relocation_query_2nd_field,
      relocation_query_3rd_field,
      relocation_query_notes,
      project_start_date_1st_field,
      project_start_date_2nd_field,
      project_start_date_3rd_field,
      project_start_date_notes,
      joining_docs_1st_field,
      joining_docs_2nd_field,
      joining_docs_3rd_field,
      joining_docs_notes,
      interview_process_1st_field,
      interview_process_2nd_field,
      interview_process_3rd_field,
      interview_process_notes,
      resume_overview_1st_field,
      resume_overview_2nd_field,
      resume_overview_3rd_field,
      resume_overview_notes,
      other_job_interview_1st_field,
      other_job_interview_2nd_field,
      other_job_interview_3rd_field,
      other_job_interview_notes,
      contact_preference_1st_field,
      contact_preference_2nd_field,
      contact_preference_3rd_field,
      contact_preference_notes,
      candidate_concenrs,
      recruiter_observation,
      final_decision,
      motivation_notes_2,
      motivation_notes_3,
      job_desc_notes_2,
      job_desc_notes_3,
      salary_benifits_notes_2,
      salary_benifits_notes_3,
      job_requirement_notes_2,
      job_requirement_notes_3,
      relocation_query_notes_2,
      relocation_query_notes_3,
      project_start_date_notes_2,
      project_start_date_notes_3,
      joining_docs_notes_2,
      joining_docs_notes_3,
      interview_process_notes_2,
      interview_process_notes_3,
      resume_overview_notes_2,
      resume_overview_notes_3,
      other_job_interview_notes_2,
      other_job_interview_notes_3,
      contact_preference_notes_2,
      contact_preference_notes_3,
      firstLanguage,
      secondLanguage,
      qualifications,
      workExp,
      workLocation,
      interviewModes,
      relocationPack,
      candidateForm,
      backgroundCheck,
      jobStartingDates,
      spFirstLanguage,
      spSecondLanguage,
      spQualifications,
      spWorkExp,
      spWorkLocation,
      spInterviewModes,
      spRelocationPack,
      spCandidateForm,
      spBackgroundCheck,
      spJobStartingDates,
      sendoutCanConcerns,
      sendoutCanRequirment,
      sendoutReqObserv,
      sendoutReqSendoutDec,
      screeningFirst,
      screeningAbliFirst,
      screeningPreContact,
      notesScreeningFirst,
      notesScreeningAbliFirst,
      notesScreeningPreContact,
      firstLevelFive,
      firstLevelFive_1,
      firstLevelFive_2,
      firstLevelFive_3,
      firstLevelFive_4,
      firstLevelFive_5,
      firstLevelSix,
      firstLevelSix_1,
      firstLevelSix_2,
      firstLevelSix_3,
      firstLevelSix_4,
      firstLevelSix_5,
      firstLevelSeven,
      firstLevelSeven_1,
      firstLevelSeven_2,
      firstLevelSeven_3,
      firstLevelSeven_4,
      firstLevelSeven_5,
      firstLevelEight,
      firstLevelEight_1,
      firstLevelEight_2,
      firstLevelEight_3,
      firstLevelEight_4,
      firstLevelEight_5,
    },
    thunkAPI
  ) => {
    const res = await PostData(true, "/candidate-prescreening-forms", {
      name,
      currentLocation,
      email,
      phoneNo,
      company,
      project,
      roleType,
      language,
      appDate,
      visaStatus,
      recruiter,
      am,
      cvSource,
      key_qualification_1st_field,
      key_qualification_2nd_field,
      key_qualification_3rd_field,
      key_qualification_notes,
      key_qualification_notes_2,
      key_qualification_notes_3,
      key_qualify_1st_field,
      key_qualify_2nd_field,
      key_qualify_3rd_field,
      key_qualify_notes_1,
      key_qualify_notes_2,
      key_qualify_notes_3,
      motivation_1st_field,
      motivation_2nd_field,
      motivation_3rd_field,
      motivation_notes,
      job_desc_1st_field,
      job_desc_2nd_field,
      job_desc_3rd_field,
      job_desc_notes,
      salary_benifits_1st_field,
      salary_benifits_2nd_field,
      salary_benifits_3rd_field,
      salary_benifits_notes,
      job_requirement_1st_field,
      job_requirement_2nd_field,
      job_requirement_3rd_field,
      job_requirement_notes,
      relocation_query_1st_field,
      relocation_query_2nd_field,
      relocation_query_3rd_field,
      relocation_query_notes,
      project_start_date_1st_field,
      project_start_date_2nd_field,
      project_start_date_3rd_field,
      project_start_date_notes,
      joining_docs_1st_field,
      joining_docs_2nd_field,
      joining_docs_3rd_field,
      joining_docs_notes,
      interview_process_1st_field,
      interview_process_2nd_field,
      interview_process_3rd_field,
      interview_process_notes,
      resume_overview_1st_field,
      resume_overview_2nd_field,
      resume_overview_3rd_field,
      resume_overview_notes,
      other_job_interview_1st_field,
      other_job_interview_2nd_field,
      other_job_interview_3rd_field,
      other_job_interview_notes,
      contact_preference_1st_field,
      contact_preference_2nd_field,
      contact_preference_3rd_field,
      contact_preference_notes,
      candidate_concenrs,
      recruiter_observation,
      final_decision,
      motivation_notes_2,
      motivation_notes_3,
      job_desc_notes_2,
      job_desc_notes_3,
      salary_benifits_notes_2,
      salary_benifits_notes_3,
      job_requirement_notes_2,
      job_requirement_notes_3,
      relocation_query_notes_2,
      relocation_query_notes_3,
      project_start_date_notes_2,
      project_start_date_notes_3,
      joining_docs_notes_2,
      joining_docs_notes_3,
      interview_process_notes_2,
      interview_process_notes_3,
      resume_overview_notes_2,
      resume_overview_notes_3,
      other_job_interview_notes_2,
      other_job_interview_notes_3,
      contact_preference_notes_2,
      contact_preference_notes_3,
      firstLanguage,
      secondLanguage,
      qualifications,
      workExp,
      workLocation,
      interviewModes,
      relocationPack,
      candidateForm,
      backgroundCheck,
      jobStartingDates,
      spFirstLanguage,
      spSecondLanguage,
      spQualifications,
      spWorkExp,
      spWorkLocation,
      spInterviewModes,
      spRelocationPack,
      spCandidateForm,
      spBackgroundCheck,
      spJobStartingDates,
      sendoutCanConcerns,
      sendoutCanRequirment,
      sendoutReqObserv,
      sendoutReqSendoutDec,
      screeningFirst,
      screeningAbliFirst,
      screeningPreContact,
      notesScreeningFirst,
      notesScreeningAbliFirst,
      notesScreeningPreContact,
      firstLevelFive,
       firstLevelFive_1,
      firstLevelFive_2,
      firstLevelFive_3,
      firstLevelFive_4,
      firstLevelFive_5,
      firstLevelSix,
      firstLevelSix_1,
      firstLevelSix_2,
      firstLevelSix_3,
      firstLevelSix_4,
      firstLevelSix_5,
      firstLevelSeven,
      firstLevelSeven_1,
      firstLevelSeven_2,
      firstLevelSeven_3,
      firstLevelSeven_4,
      firstLevelSeven_5,
      firstLevelEight,
      firstLevelEight_1,
      firstLevelEight_2,
      firstLevelEight_3,
      firstLevelEight_4,
      firstLevelEight_5,
    });

    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

const listSlice = createSlice({
  name: "candidatePrescreanning",
  initialState: {
    firstLevelList: [],
    CVSourceFirstList: [],
    keyQualificationFirstList: [],
    keyQualificationSecondList: [],
    keyQualificationThirdList: [],
    keyQualificationFourList: [],
    keyQualificationFiveList: [],
    keyQualifyFirstList: [],
    keyQualifySecondList: [],
    keyQualifyThirdList: [],
    motivationFirstList: [],
    motivationSecondList: [],
    motivationThirdList: [],
    jobDescFirstList: [],
    jobDescSecondList: [],
    jobDescThirdList: [],
    SalaryFirstList: [],
    SalarySecondList: [],
    SalaryThirdList: [],
    JobRequirnmentFirstList: [],
    JobRequirnmentSecondList: [],
    JobRequirnmentThirdList: [],
    RelocationQryFirstList: [],
    RelocationQrySecondList: [],
    RelocationQryThirdList: [],
    ProjectStartDateFirstList: [],
    ProjectStartDateSecondList: [],
    ProjectStartDateThirdList: [],
    JoiningDocumentFirstList: [],
    JoiningDocumentSecondList: [],
    JoiningDocumentThirdList: [],
    InterviewProcessFirstList: [],
    InterviewProcessSecondList: [],
    InterviewProcessThirdList: [],
    FullResumePreviewFirstList: [],
    FullResumePreviewSecondList: [],
    FullResumePreviewThirdList: [],
    OtherJobInterviewFirstList: [],
    OtherJobInterviewSecondList: [],
    OtherJobInterviewThirdList: [],
    ContactPreferenceFirstList: [],
    screaningLevelList: [],
    firstLevelFiveList:[],
    firstLevelFiveTwoList:[],
    firstLevelFiveThreeList:[],
    screaningListAvailability: [],
    screaningPreferedList: [],
    ContactPreferenceSecondList: [],
    ContactPreferenceThirdList: [],
    canPrescreeningList: [],
    screanningList: [],
    screanningDetails: [],
    firstLevelSix1stList:[],
    firstLevelSix2ndList:[],
    firstLevelSix3rdList:[],
    firstLevelSeven1stList:[], 
    firstLevelSeven2ndList:[],
    firstLevelSeven3rdtList:[],
    firstLevelSeven4rtList:[],
    firstLevelEight1stList:[], 
    firstLevelEight2ndList:[],
    firstLevelEight3rdtList:[], 
    firstLvlNine1stList:[],
    firstLvlNine2ndList:[],
    firstLvlNine3rdList:[],
    firstLvlTen1stList:[],
    firstLvlTen2ndList:[],
    firstLvlTen3rdList:[],
    firstLvlEleven1stList:[],
    firstLvlEleven2ndList:[],
    firstLvlEleven3rdList:[],
    firstLvlTwelve3rdList:[],
    firstLvlTwelve4rthdList:[],
    firstLvlTwelve2ndList:[],
    firstLvlTwelve1stList:[],
    firstLvlThirteen3rdList:[],
    firstLvlThirteen2ndList:[],
    firstLvlThirteen1stList:[],
    firstLvlFourteen1stList:[],
    firstLvlFourteen2ndList:[],
    firstLvlFourteen3rdList:[],
    firstLvlFifteen3rdList:[],
    firstLvlFifteen2ndList:[],
    firstLvlFifteen1stList:[],
    firstLvlFifteen4rtList:[],
    firstLvlFifteen5thList:[],
    firstLvlFifteen6thList:[],
    TenthFirstList:[], 
    TenthSecondList:[],
    TenthThirdList:[],
    ElevenFirstList:[],
    ElevenSecondList:[],
    ElevenThirdList:[],
    twelveFirstList:[],
    twelveSecondList:[],
    twelveThirdList:[],

    thirteenFirstList:[],
    thirteenSecondList:[],
    thirteenThirdList:[],
    fourteenFirstList:[],
    fourteenSecondList:[],
    fourteenThirdList:[],
    fifteenFirstList:[],
    fifteenSecondList:[],
    fifteenThirdList:[],
    countAll: 0,
    isLoading: false,
    isScreaningLoading: false,
    isDetailLoading: false,
    isTaskInsert: false,
    isTaskUpdate: false,
    isDelete: false,
    isUpdate: false,
    iscommentCountUpdate: false,
    isError: false,
    isSuccess: false,
    isDataMoveToPayment: false,
    isInserted: false,
    isMoved: false,
    isArchive: false,
    message: "",
    isClearInput: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isScreaningLoading = false;
      state.isLoading = false;
      state.isDetailLoading = false;
      state.isTaskInsert = false;
      state.isTaskUpdate = false;
      state.isDelete = false;
      state.isUpdate = false;
      state.iscommentCountUpdate = false;
      state.isSuccess = false;
      state.issSuccess = false;
      state.isDataMoveToPayment = false;
      state.isInserted = false;
      state.isMoved = false;
      state.isArchive = false;
      state.message = "";
      state.isClearInput = true;
    },
    clearTextBox: (state) => {
      state.screanningDetails = [];
    },
    clearTaskState: (state) => {
      state.isError = false;
      state.isScreaningLoading = false;
      state.isLoading = false;
      state.isTaskInsert = false;
      state.isTaskUpdate = false;
      state.isDelete = false;
      state.isUpdate = false;
      state.iscommentCountUpdate = false;
      state.isSuccess = false;
      state.isInserted = false;
      state.isMoved = false;
      state.isArchive = false;
      state.message = "";
    },
    clearDetails: (state) => {
      state.tasksDetails = {};
    },
  },
  extraReducers: (builder) => {

    builder.addCase(update1stScreaning.pending, (state) => {});
    builder.addCase(update1stScreaning.fulfilled, (state, { payload }) => {          
            state.isUpdate = true
        });
    builder.addCase(update1stScreaning.rejected, (state, action) => {
            state.isError = true;
        });
    builder.addCase(update2ndScreaning.pending, (state) => {});
    builder.addCase(update2ndScreaning.fulfilled, (state, { payload }) => {          
            state.isUpdate = true
        });
    builder.addCase(update2ndScreaning.rejected, (state, action) => {
            state.isError = true;
        });

    builder.addCase(CVSourceFilterFirst.pending, (state) => {});
    builder.addCase(CVSourceFilterFirst.fulfilled, (state, { payload }) => {
      state.CVSourceFirstList = payload.result;
    });
    builder.addCase(CVSourceFilterFirst.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(motivationFilterFirst.pending, (state) => {});
    builder.addCase(motivationFilterFirst.fulfilled, (state, { payload }) => {
      state.motivationFirstList = payload.result;
    });
    builder.addCase(motivationFilterFirst.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(motivationFilterSecond.pending, (state) => {});
    builder.addCase(motivationFilterSecond.fulfilled, (state, { payload }) => {
      state.motivationSecondList = payload.result;
    });
    builder.addCase(motivationFilterSecond.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(motivationFilterThird.pending, (state) => {});
    builder.addCase(motivationFilterThird.fulfilled, (state, { payload }) => {
      state.motivationThirdList = payload.result;
    });
    builder.addCase(motivationFilterThird.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    /********************* */
    builder.addCase(KeyQualificationFilterFirst.pending, (state) => {});
    builder.addCase(
      KeyQualificationFilterFirst.fulfilled,
      (state, { payload }) => {
        state.keyQualificationFirstList = payload.result;
      }
    );
    builder.addCase(KeyQualificationFilterFirst.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(KeyQualificationFilterSecond.pending, (state) => {});
    builder.addCase(
      KeyQualificationFilterSecond.fulfilled,
      (state, { payload }) => {
        state.keyQualificationSecondList = payload.result;
      }
    );
    builder.addCase(KeyQualificationFilterSecond.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(KeyQualificationFilterThird.pending, (state) => {});
    builder.addCase(
      KeyQualificationFilterThird.fulfilled,
      (state, { payload }) => {
        state.keyQualificationThirdList = payload.result;
      }
    );
    builder.addCase(KeyQualificationFilterThird.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(KeyQualificationFilterFourth.pending, (state) => {});
    builder.addCase(
      KeyQualificationFilterFourth.fulfilled,
      (state, { payload }) => {
        state.keyQualificationFourList = payload.result;
      }
    );
    builder.addCase(KeyQualificationFilterFourth.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(KeyQualificationFilterFive.pending, (state) => {});
    builder.addCase(
      KeyQualificationFilterFive.fulfilled,
      (state, { payload }) => {
        state.keyQualificationFiveList = payload.result;
      }
    );
    builder.addCase(KeyQualificationFilterFive.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    /********************* */
    builder.addCase(KeyQualifyFilterFirst.pending, (state) => {});
    builder.addCase(KeyQualifyFilterFirst.fulfilled, (state, { payload }) => {
      state.keyQualifyFirstList = payload.result;
    });
    builder.addCase(KeyQualifyFilterFirst.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(KeyQualifyFilterSecond.pending, (state) => {});
    builder.addCase(KeyQualifyFilterSecond.fulfilled, (state, { payload }) => {
      state.keyQualifySecondList = payload.result;
    });
    builder.addCase(KeyQualifyFilterSecond.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(KeyQualifyFilterThird.pending, (state) => {});
    builder.addCase(KeyQualifyFilterThird.fulfilled, (state, { payload }) => {
      state.keyQualifyThirdList = payload.result;
    });
    builder.addCase(KeyQualifyFilterThird.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    /********************* */
    builder.addCase(JobDescriptionFilterFirst.pending, (state) => {});
    builder.addCase(
      JobDescriptionFilterFirst.fulfilled,
      (state, { payload }) => {
        state.jobDescFirstList = payload.result;
      }
    );
    builder.addCase(JobDescriptionFilterFirst.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(JobDescriptionFilterSecond.pending, (state) => {});
    builder.addCase(
      JobDescriptionFilterSecond.fulfilled,
      (state, { payload }) => {
        state.jobDescSecondList = payload.result;
      }
    );
    builder.addCase(JobDescriptionFilterSecond.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(JobDescriptionFilterThird.pending, (state) => {});
    builder.addCase(
      JobDescriptionFilterThird.fulfilled,
      (state, { payload }) => {
        state.jobDescThirdList = payload.result;
      }
    );
    builder.addCase(JobDescriptionFilterThird.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    //start
    builder.addCase(SalaryBenifitFilterFirst.pending, (state) => {});
    builder.addCase(
      SalaryBenifitFilterFirst.fulfilled,
      (state, { payload }) => {
        state.SalaryFirstList = payload.result;
      }
    );
    builder.addCase(SalaryBenifitFilterFirst.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(SalaryBenifitFilterSecond.pending, (state) => {});
    builder.addCase(
      SalaryBenifitFilterSecond.fulfilled,
      (state, { payload }) => {
        state.SalarySecondList = payload.result;
      }
    );
    builder.addCase(SalaryBenifitFilterSecond.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(SalaryBenifitFilterThird.pending, (state) => {});
    builder.addCase(
      SalaryBenifitFilterThird.fulfilled,
      (state, { payload }) => {
        state.SalaryThirdList = payload.result;
      }
    );
    builder.addCase(SalaryBenifitFilterThird.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    //end

    //start
    builder.addCase(JobReqFilterFirst.pending, (state) => {});
    builder.addCase(JobReqFilterFirst.fulfilled, (state, { payload }) => {
      state.JobRequirnmentFirstList = payload.result;
    });
    builder.addCase(JobReqFilterFirst.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(JobReqFilterSecond.pending, (state) => {});
    builder.addCase(JobReqFilterSecond.fulfilled, (state, { payload }) => {
      state.JobRequirnmentSecondList = payload.result;
    });
    builder.addCase(JobReqFilterSecond.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(JobReqFilterThird.pending, (state) => {});
    builder.addCase(JobReqFilterThird.fulfilled, (state, { payload }) => {
      state.JobRequirnmentThirdList = payload.result;
    });
    builder.addCase(JobReqFilterThird.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    //end

    //start
    builder.addCase(ReloactionQueryFilterFirst.pending, (state) => {});
    builder.addCase(
      ReloactionQueryFilterFirst.fulfilled,
      (state, { payload }) => {
        state.RelocationQryFirstList = payload.result;
      }
    );
    builder.addCase(ReloactionQueryFilterFirst.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(ReloactionQueryFilterSecond.pending, (state) => {});
    builder.addCase(
      ReloactionQueryFilterSecond.fulfilled,
      (state, { payload }) => {
        state.RelocationQrySecondList = payload.result;
      }
    );
    builder.addCase(ReloactionQueryFilterSecond.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(ReloactionQueryFilterThird.pending, (state) => {});
    builder.addCase(
      ReloactionQueryFilterThird.fulfilled,
      (state, { payload }) => {
        state.RelocationQryThirdList = payload.result;
      }
    );
    builder.addCase(ReloactionQueryFilterThird.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    //end

    //start
    builder.addCase(ProjectStartDateFilterFirst.pending, (state) => {});
    builder.addCase(
      ProjectStartDateFilterFirst.fulfilled,
      (state, { payload }) => {
        state.ProjectStartDateFirstList = payload.result;
      }
    );
    builder.addCase(ProjectStartDateFilterFirst.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(ProjectStartDateFilterSecond.pending, (state) => {});
    builder.addCase(
      ProjectStartDateFilterSecond.fulfilled,
      (state, { payload }) => {
        state.ProjectStartDateSecondList = payload.result;
      }
    );
    builder.addCase(ProjectStartDateFilterSecond.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(ProjectStartDateFilterThird.pending, (state) => {});
    builder.addCase(
      ProjectStartDateFilterThird.fulfilled,
      (state, { payload }) => {
        state.ProjectStartDateThirdList = payload.result;
      }
    );
    builder.addCase(ProjectStartDateFilterThird.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    //end

    //start
    builder.addCase(JoiningDocumentFilterFirst.pending, (state) => {});
    builder.addCase(
      JoiningDocumentFilterFirst.fulfilled,
      (state, { payload }) => {
        state.JoiningDocumentFirstList = payload.result;
      }
    );
    builder.addCase(JoiningDocumentFilterFirst.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(JoiningDocumentFilterSecond.pending, (state) => {});
    builder.addCase(
      JoiningDocumentFilterSecond.fulfilled,
      (state, { payload }) => {
        state.JoiningDocumentSecondList = payload.result;
      }
    );
    builder.addCase(JoiningDocumentFilterSecond.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(JoiningDocumentFilterThird.pending, (state) => {});
    builder.addCase(
      JoiningDocumentFilterThird.fulfilled,
      (state, { payload }) => {
        state.JoiningDocumentThirdList = payload.result;
      }
    );
    builder.addCase(JoiningDocumentFilterThird.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    //end

    //start
    builder.addCase(InterviewProcessFilterFirst.pending, (state) => {});
    builder.addCase(
      InterviewProcessFilterFirst.fulfilled,
      (state, { payload }) => {
        state.InterviewProcessFirstList = payload.result;
      }
    );
    builder.addCase(InterviewProcessFilterFirst.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(InterviewProcessFilterSecond.pending, (state) => {});
    builder.addCase(
      InterviewProcessFilterSecond.fulfilled,
      (state, { payload }) => {
        state.InterviewProcessSecondList = payload.result;
      }
    );
    builder.addCase(InterviewProcessFilterSecond.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(InterviewProcessFilterThird.pending, (state) => {});
    builder.addCase(
      InterviewProcessFilterThird.fulfilled,
      (state, { payload }) => {
        state.InterviewProcessThirdList = payload.result;
      }
    );
    builder.addCase(InterviewProcessFilterThird.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    //end

    //start
    builder.addCase(FullResumePreviewFilterFirst.pending, (state) => {});
    builder.addCase(
      FullResumePreviewFilterFirst.fulfilled,
      (state, { payload }) => {
        state.FullResumePreviewFirstList = payload.result;
      }
    );
    builder.addCase(FullResumePreviewFilterFirst.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(FullResumePreviewFilterSecond.pending, (state) => {});
    builder.addCase(
      FullResumePreviewFilterSecond.fulfilled,
      (state, { payload }) => {
        state.FullResumePreviewSecondList = payload.result;
      }
    );
    builder.addCase(FullResumePreviewFilterSecond.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(FullResumePreviewFilterThird.pending, (state) => {});
    builder.addCase(
      FullResumePreviewFilterThird.fulfilled,
      (state, { payload }) => {
        state.FullResumePreviewThirdList = payload.result;
      }
    );
    builder.addCase(FullResumePreviewFilterThird.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    //end
    //start
    builder.addCase(OtherJobInterviewFilterFirst.pending, (state) => {});
    builder.addCase(
      OtherJobInterviewFilterFirst.fulfilled,
      (state, { payload }) => {
        state.OtherJobInterviewFirstList = payload.result;
      }
    );
    builder.addCase(OtherJobInterviewFilterFirst.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(OtherJobInterviewFilterSecond.pending, (state) => {});
    builder.addCase(
      OtherJobInterviewFilterSecond.fulfilled,
      (state, { payload }) => {
        state.OtherJobInterviewSecondList = payload.result;
      }
    );
    builder.addCase(OtherJobInterviewFilterSecond.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(OtherJobInterviewFilterThird.pending, (state) => {});
    builder.addCase(
      OtherJobInterviewFilterThird.fulfilled,
      (state, { payload }) => {
        state.OtherJobInterviewThirdList = payload.result;
      }
    );
    builder.addCase(OtherJobInterviewFilterThird.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    //end
    //start
    builder.addCase(screaningContactPrefered.pending, (state) => {});
    builder.addCase(
      screaningContactPrefered.fulfilled,
      (state, { payload }) => {
        state.screaningPreferedList = payload.result;
      }
    );
    builder.addCase(screaningContactPrefered.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(screaningAvailability.pending, (state) => {});
    builder.addCase(screaningAvailability.fulfilled, (state, { payload }) => {
      state.screaningListAvailability = payload.result;
    });
    builder.addCase(screaningAvailability.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(firstLevel7th_1.pending, (state) => {});
    builder.addCase(firstLevel7th_1.fulfilled, (state, { payload }) => {
      state.firstLevelSeven1stList = payload.result;
    });
    builder.addCase(firstLevel7th_1.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });    
    builder.addCase(firstLevel7th_2.pending, (state) => {});
    builder.addCase(firstLevel7th_2.fulfilled, (state, { payload }) => {
      state.firstLevelSeven2ndList = payload.result;
    });
    builder.addCase(firstLevel7th_2.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(firstLevel7th_3.pending, (state) => {});
    builder.addCase(firstLevel7th_3.fulfilled, (state, { payload }) => {
      state.firstLevelSeven3rdtList = payload.result;
    });
    builder.addCase(firstLevel7th_3.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(firstLevel7th_4.pending, (state) => {});
    builder.addCase(firstLevel7th_4.fulfilled, (state, { payload }) => {
      state.firstLevelSeven4rtList = payload.result;
    });
    builder.addCase(firstLevel7th_4.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    // Eight
    builder.addCase(firstLevel8th_1.pending, (state) => {});
    builder.addCase(firstLevel8th_1.fulfilled, (state, { payload }) => {
      state.firstLevelEight1stList = payload.result;
    });
    builder.addCase(firstLevel8th_1.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
    /*****new Block */ 
    builder.addCase(firstLvl9th_1.pending, (state) => {});
    builder.addCase(firstLvl9th_1.fulfilled, (state, { payload }) => {
      state.firstLvlNine1stList = payload.result;
    });
    builder.addCase(firstLvl9th_1.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
    builder.addCase(firstLvl9th_2.pending, (state) => {});
    builder.addCase(firstLvl9th_2.fulfilled, (state, { payload }) => {
      state.firstLvlNine2ndList = payload.result;
    });
    builder.addCase(firstLvl9th_2.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
    builder.addCase(firstLvl9th_3.pending, (state) => {});
    builder.addCase(firstLvl9th_3.fulfilled, (state, { payload }) => {
      state.firstLvlNine3rdList = payload.result;
    }); 
    builder.addCase(firstLvl9th_3.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    //10th
     builder.addCase(firstLvl10th_1.pending, (state) => {});
    builder.addCase(firstLvl10th_1.fulfilled, (state, { payload }) => {
      state.firstLvlTen1stList = payload.result;
    });
    builder.addCase(firstLvl10th_1.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
    builder.addCase(firstLvl10th_2.pending, (state) => {});
    builder.addCase(firstLvl10th_2.fulfilled, (state, { payload }) => {
      state.firstLvlTen2ndList = payload.result;
    });
    builder.addCase(firstLvl10th_2.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });  
    builder.addCase(firstLvl10th_3.pending, (state) => {});
    builder.addCase(firstLvl10th_3.fulfilled, (state, { payload }) => {
      state.firstLvlTen3rdList = payload.result;
    }); 
    builder.addCase(firstLvl10th_3.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
    //11th
     builder.addCase(firstLvl11th_1.pending, (state) => {});
    builder.addCase(firstLvl11th_1.fulfilled, (state, { payload }) => {
      state.firstLvlEleven1stList = payload.result;
    });
    builder.addCase(firstLvl11th_1.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
    builder.addCase(firstLvl11th_2.pending, (state) => {});
    builder.addCase(firstLvl11th_2.fulfilled, (state, { payload }) => {
      state.firstLvlEleven2ndList = payload.result;
    });
    builder.addCase(firstLvl11th_2.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });  
    builder.addCase(firstLvl11th_3.pending, (state) => {});
    builder.addCase(firstLvl11th_3.fulfilled, (state, { payload }) => {
      state.firstLvlEleven3rdList = payload.result;
    }); 
    builder.addCase(firstLvl11th_3.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
    //12th
    builder.addCase(firstLvl12th_1.pending, (state) => {});
    builder.addCase(firstLvl12th_1.fulfilled, (state, { payload }) => {
      state.firstLvlTwelve1stList = payload.result;
    });
    builder.addCase(firstLvl12th_1.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
    builder.addCase(firstLvl12th_2.pending, (state) => {});
    builder.addCase(firstLvl12th_2.fulfilled, (state, { payload }) => {
      state.firstLvlTwelve2ndList = payload.result;
    });
    builder.addCase(firstLvl12th_2.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });  
    builder.addCase(firstLvl12th_3.pending, (state) => {});
    builder.addCase(firstLvl12th_3.fulfilled, (state, { payload }) => {
      state.firstLvlTwelve3rdList = payload.result;
    }); 
    builder.addCase(firstLvl12th_3.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
    builder.addCase(firstLvl12th_4.pending, (state) => {});
    builder.addCase(firstLvl12th_4.fulfilled, (state, { payload }) => {
      state.firstLvlTwelve4rthdList = payload.result;
    }); 
    builder.addCase(firstLvl12th_4.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
    //13th
    builder.addCase(firstLvl13th_1.pending, (state) => {});
    builder.addCase(firstLvl13th_1.fulfilled, (state, { payload }) => {
      state.firstLvlThirteen1stList = payload.result;
    });
    builder.addCase(firstLvl13th_1.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
    builder.addCase(firstLvl13th_2.pending, (state) => {});
    builder.addCase(firstLvl13th_2.fulfilled, (state, { payload }) => {
      state.firstLvlThirteen2ndList = payload.result;
    });
    builder.addCase(firstLvl13th_2.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });  
    builder.addCase(firstLvl13th_3.pending, (state) => {});
    builder.addCase(firstLvl13th_3.fulfilled, (state, { payload }) => {
      state.firstLvlThirteen3rdList = payload.result;
    }); 
    builder.addCase(firstLvl13th_3.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
    //14th
    builder.addCase(firstLvl14th_1.pending, (state) => {});
    builder.addCase(firstLvl14th_1.fulfilled, (state, { payload }) => {
      state.firstLvlFourteen1stList = payload.result;
    });
    builder.addCase(firstLvl14th_1.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
    builder.addCase(firstLvl14th_2.pending, (state) => {});
    builder.addCase(firstLvl14th_2.fulfilled, (state, { payload }) => {
      state.firstLvlFourteen2ndList = payload.result;
    });
    builder.addCase(firstLvl14th_2.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });  
    builder.addCase(firstLvl14th_3.pending, (state) => {});
    builder.addCase(firstLvl14th_3.fulfilled, (state, { payload }) => {
      state.firstLvlFourteen3rdList = payload.result;
    }); 
    builder.addCase(firstLvl14th_3.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
    //15th
    builder.addCase(firstLvl15th_1.pending, (state) => {});
    builder.addCase(firstLvl15th_1.fulfilled, (state, { payload }) => {
      state.firstLvlFifteen1stList = payload.result;
    });
    builder.addCase(firstLvl15th_1.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
    builder.addCase(firstLvl15th_2.pending, (state) => {});
    builder.addCase(firstLvl15th_2.fulfilled, (state, { payload }) => {
      state.firstLvlFifteen2ndList = payload.result;
    });
    builder.addCase(firstLvl15th_2.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });  
    builder.addCase(firstLvl15th_3.pending, (state) => {});
    builder.addCase(firstLvl15th_3.fulfilled, (state, { payload }) => {
      state.firstLvlFifteen3rdList = payload.result;
    }); 
    builder.addCase(firstLvl15th_3.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 

    builder.addCase(firstLvl15th_4.pending, (state) => {});
    builder.addCase(firstLvl15th_4.fulfilled, (state, { payload }) => {
      state.firstLvlFifteen4rtList = payload.result;
    }); 
    builder.addCase(firstLvl15th_4.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 

    builder.addCase(firstLvl15th_5.pending, (state) => {});
    builder.addCase(firstLvl15th_5.fulfilled, (state, { payload }) => {
      state.firstLvlFifteen5thList = payload.result;
    }); 
    builder.addCase(firstLvl15th_5.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 

    builder.addCase(firstLvl15th_6.pending, (state) => {});
    builder.addCase(firstLvl15th_6.fulfilled, (state, { payload }) => {
      state.firstLvlFifteen6thList = payload.result;
    }); 
    builder.addCase(firstLvl15th_6.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
  /************ */
    builder.addCase(firstLevel8th_2.pending, (state) => {});
    builder.addCase(firstLevel8th_2.fulfilled, (state, { payload }) => {
      state.firstLevelEight2ndList = payload.result;
    });
    builder.addCase(firstLevel8th_2.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(firstLevel8th_3.pending, (state) => {});
    builder.addCase(firstLevel8th_3.fulfilled, (state, { payload }) => {
      state.firstLevelEight3rdtList = payload.result;
    });
    builder.addCase(firstLevel8th_3.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    //End
    builder.addCase(firstLevel6th_1.pending, (state) => {});
    builder.addCase(firstLevel6th_1.fulfilled, (state, { payload }) => {
      state.firstLevelSix1stList = payload.result;
    });
    builder.addCase(firstLevel6th_1.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(firstLevel6th_2.pending, (state) => {});
    builder.addCase(firstLevel6th_2.fulfilled, (state, { payload }) => {
      state.firstLevelSix2ndList = payload.result;
    });
    builder.addCase(firstLevel6th_2.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    }); 
    builder.addCase(firstLevel6th_3.pending, (state) => {});
    builder.addCase(firstLevel6th_3.fulfilled, (state, { payload }) => {
      state.firstLevelSix3rdList = payload.result;
    });
    builder.addCase(firstLevel6th_3.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(firstLevel5th_3.pending, (state) => {});
    builder.addCase(firstLevel5th_3.fulfilled, (state, { payload }) => {
      state.firstLevelFiveThreeList = payload.result;
    });
    builder.addCase(firstLevel5th_3.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(firstLevel5th_2.pending, (state) => {});
    builder.addCase(firstLevel5th_2.fulfilled, (state, { payload }) => {
      state.firstLevelFiveTwoList = payload.result;
    });
    builder.addCase(firstLevel5th_2.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(firstLevel5th_1.pending, (state) => {});
    builder.addCase(firstLevel5th_1.fulfilled, (state, { payload }) => {
      state.firstLevelFiveList = payload.result;
    });
    builder.addCase(firstLevel5th_1.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(screaningLevelFirst.pending, (state) => {});
    builder.addCase(screaningLevelFirst.fulfilled, (state, { payload }) => {
      state.screaningLevelList = payload.result;
    });
    builder.addCase(screaningLevelFirst.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(elevenFirstDropdown.pending, (state) => {});
    builder.addCase(elevenFirstDropdown.fulfilled, (state, { payload }) => {
        state.ElevenFirstList = payload.result;
      });
    builder.addCase(elevenFirstDropdown.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(elevenSecondDropdown.pending, (state) => {});
    builder.addCase(elevenSecondDropdown.fulfilled, (state, { payload }) => {
        state.ElevenSecondList = payload.result;
      });
    builder.addCase(elevenSecondDropdown.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(elevenThirdDropdown.pending, (state) => {});
    builder.addCase(elevenThirdDropdown.fulfilled, (state, { payload }) => {
        state.ElevenThirdList = payload.result;
      });
    builder.addCase(elevenThirdDropdown.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
//
    builder.addCase(twelveFirstDropdown.pending, (state) => {});
    builder.addCase(twelveFirstDropdown.fulfilled, (state, { payload }) => {
        state.twelveFirstList = payload.result;
      });
    builder.addCase(twelveFirstDropdown.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(twelveSecondDropdown.pending, (state) => {});
    builder.addCase( twelveSecondDropdown.fulfilled,(state, { payload }) => {
        state.twelveSecondList = payload.result; }
    );
    builder.addCase(twelveSecondDropdown.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(twelveThirdDropdown.pending, (state) => {});
    builder.addCase(twelveThirdDropdown.fulfilled,(state, { payload }) => {
        state.twelveThirdList = payload.result;
      });
    builder.addCase(twelveThirdDropdown.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    //13
    builder.addCase(thirteenFirstDropdown.pending, (state) => {});
    builder.addCase(thirteenFirstDropdown.fulfilled, (state, { payload }) => {
        state.thirteenFirstList = payload.result;
      });
    builder.addCase(thirteenFirstDropdown.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(thirteenSecondDropdown.pending, (state) => {});
    builder.addCase( thirteenSecondDropdown.fulfilled,(state, { payload }) => {
        state.thirteenSecondList = payload.result; }
    );
    builder.addCase(thirteenSecondDropdown.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(thirteenThirdDropdown.pending, (state) => {});
    builder.addCase(thirteenThirdDropdown.fulfilled,(state, { payload }) => {
        state.thirteenThirdList = payload.result;
      });
    builder.addCase(thirteenThirdDropdown.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    //14
    builder.addCase(fourteenFirstDropdown.pending, (state) => {});
    builder.addCase(fourteenFirstDropdown.fulfilled, (state, { payload }) => {
        state.fourteenFirstList = payload.result;
      });
    builder.addCase(fourteenFirstDropdown.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(fourteenSecondDropdown.pending, (state) => {});
    builder.addCase( fourteenSecondDropdown.fulfilled,(state, { payload }) => {
        state.fourteenSecondList = payload.result; }
    );
    builder.addCase(fourteenSecondDropdown.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(fourteenThirdDropdown.pending, (state) => {});
    builder.addCase(fourteenThirdDropdown.fulfilled,(state, { payload }) => {
        state.fourteenThirdList = payload.result;
      });
    builder.addCase(fourteenThirdDropdown.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    //15
    builder.addCase(fifteenFirstDropdown.pending, (state) => {});
    builder.addCase(fifteenFirstDropdown.fulfilled, (state, { payload }) => {
        state.fifteenFirstList = payload.result;
      });
    builder.addCase(fifteenFirstDropdown.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(fifteenSecondDropdown.pending, (state) => {});
    builder.addCase( fifteenSecondDropdown.fulfilled,(state, { payload }) => {
        state.fifteenSecondList = payload.result; }
    );
    builder.addCase(fifteenSecondDropdown.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(fifteenThirdDropdown.pending, (state) => {});
    builder.addCase(fifteenThirdDropdown.fulfilled,(state, { payload }) => {
        state.fifteenThirdList = payload.result;
      });
    builder.addCase(fifteenThirdDropdown.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    //end 
    //10
    builder.addCase(ContactPreferenceFilterFirst.pending, (state) => {});
    builder.addCase(ContactPreferenceFilterFirst.fulfilled, (state, { payload }) => {
        state.TenthFirstList = payload.result;
      });
    builder.addCase(ContactPreferenceFilterFirst.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(ContactPreferenceFilterSecond.pending, (state) => {});
    builder.addCase( ContactPreferenceFilterSecond.fulfilled,(state, { payload }) => {
        state.TenthSecondList = payload.result; }
    );
    builder.addCase(ContactPreferenceFilterSecond.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(ContactPreferenceFilterThird.pending, (state) => {});
    builder.addCase(ContactPreferenceFilterThird.fulfilled,(state, { payload }) => {
        state.TenthThirdList = payload.result;
      });
    builder.addCase(ContactPreferenceFilterThird.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    //end 
    builder.addCase(addNewValue.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addNewValue.fulfilled, (state, { payload }) => {
      state.isInserted = true;
      state.canPrescreeningList = payload.result;
      state.isLoading = false;
    });
    builder.addCase(addNewValue.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(fetchScreeningList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchScreeningList.fulfilled, (state, { payload }) => {
      state.screanningList = payload.result;
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(fetchScreeningList.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(checkScreaningForm.pending, (state) => {
      state.isLoading = false;
    });
    builder.addCase(checkScreaningForm.fulfilled, (state, { payload }) => {      
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(checkScreaningForm.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(secondScreaningfetchById.pending, (state) => {
      state.isScreaningLoading = false;
    });
    builder.addCase(secondScreaningfetchById.fulfilled, (state, { payload }) => {      
      state.isScreaningLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(secondScreaningfetchById.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(fetchById.pending, (state) => {
      state.isScreaningLoading = false;
    });
    builder.addCase(fetchById.fulfilled, (state, { payload }) => {
      state.screanningDetails = payload.result;
      state.isScreaningLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(fetchById.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(updateData.pending, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateData.fulfilled, (state, { payload }) => {
      // let mainArray = state.screanningList;
      // let finalResult = mainArray.map((r) => {
      //     if (r.id === payload.result[0].id) {
      //         return (payload.result[0])
      //     } else {
      //         return r
      //     }
      // })
      state.isUpdate = true;
      state.isLoading = false;
      // state.screanningList = finalResult;
    });
    builder.addCase(updateData.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(deleteFunction.pending, (state) => {});
    builder.addCase(deleteFunction.fulfilled, (state, { payload }) => {
      state.isDelete = true;
      state.isMoved = true;
    });
    builder.addCase(deleteFunction.rejected, (state, action) => {
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(getTextLevel.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(getTextLevel.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.firstLevelList = payload.result;
    });
    builder.addCase(getTextLevel.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    });
  },
});

export const { clearState, clearTaskState, clearDetails, clearTextBox } =
  listSlice.actions;
export default listSlice.reducer;
