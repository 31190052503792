import React from 'react';
const Months = ({ value }) => {

     return (
        <>
            <div className="caseDiv" style={{ backgroundColor: "#f3f3f3", color: '#000', minWidth: '80px' }}>{value}</div>
        </>
    )
}

export default Months;