import React, { useState } from 'react';
import './pipelinesmanagement.scss';
import InfoModal from './InfoModal';
import { FaCheck, FaCircle, FaFlag, FaUserAlt, FaUserCircle } from "react-icons/fa";

const DkGlobalPipelines = () => {
    const [infoModal, setShowInfoModal] = useState(false)
    const ShowInfoModal = () => {
        setShowInfoModal(true)
    }
    const HideInfoModal = () => {
        setShowInfoModal(false)
    }
    return (
        <>
            {
                infoModal ?
                    <InfoModal handleClose={HideInfoModal} /> : null
            }

            <div className="dk-pipeineNew-mainContainer">
                <div className="row">
                    <div className="col-md-6 pr-4">
                        <div className="title">PROFILE ICONS</div>
                        <div className="dk-pipelines-box-6">
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="dk-pipeline-imgCols">
                                                <FaCheck style={{ color: 'green' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <b>100% Matched</b>
                                        </td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-pipeline-imgCols">
                                                <FaCheck style={{ color: '#d58b5d' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <b>75% Matched</b>
                                        </td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-pipeline-imgCols">
                                                <FaCheck style={{ color: '#d60101' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <b>0% Matched</b>
                                        </td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-pipeline-imgCols">
                                                <FaCircle style={{ color: 'green' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <b>Profile Updated</b>
                                        </td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-pipeline-imgCols">
                                                <FaUserCircle style={{ color: 'grey' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <b>Blank Profile</b>
                                        </td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-pipeline-imgCols">
                                                <FaUserAlt style={{ color: 'green' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <b>Motivated</b>
                                        </td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-pipeline-imgCols">
                                                <FaUserAlt style={{ color: '#d58b5d' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <b>Doubtful</b>
                                        </td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-pipeline-imgCols">
                                                <FaUserAlt style={{ color: '#ff0000' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <b>Unmotivated</b>
                                        </td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <div className="dk-pipeline-imgCols">
                                                <FaCircle style={{ color: '#d3d3d3' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <b>Not Updated</b>
                                        </td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td>
                                            <div className="dk-pipeline-imgCols">
                                                <FaFlag style={{ color: 'green' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <b>On Time</b>
                                        </td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <div className="dk-pipeline-imgCols">
                                                <FaFlag style={{ color: '#2e75b6' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <b>Concerns</b>
                                        </td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td>
                                            <div className="dk-pipeline-imgCols">
                                                <FaFlag style={{ color: '#d58b5d' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <b>Delayed</b>
                                        </td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-pipeline-imgCols">
                                                <FaFlag style={{ color: '#e2445b' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <b>Stopped</b>
                                        </td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <div className="dk-pipeline-imgCols">
                                                <FaFlag style={{ color: '#d3d3d3' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <b>Blank</b>
                                        </td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-6 pl-4">
                        <div className="title">CANDIDATE PIPELINES</div>
                        <div className="dk-pipelines-box-6 dk-pipeline-specCols">
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <td className="dk-newCvBg">New Case</td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="dk-emailBg">Selected</td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="dk-callingBg">To Do</td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="dk-intrestedBg">In Progress</td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="dk-completedBg">Completed</td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="dk-sendoutBg">Sendouts</td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="dk-joboffersBg">Job Offers</td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="dk-onHoldBg">Review</td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="dk-closedBg">Closed</td>
                                        <td>
                                            <p className="dk-pipelines-para">
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quae corrupti voluptatibus facilis ullam consectetur.
                                            </p>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DkGlobalPipelines;
