import React, { useState, useEffect, useRef, } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import './kpicase.scss';
//import AddClientKpi from './AddClientKpi';
import UpdatePop from "./UpdatePop";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import { listTable, addValue, sortData, deleteClient, bulkDeleteState, clearState, updateValue } from "../../../../../slice/kpis/recruterSummarySlice";
import TableLoader from "../../../../ui/tableLoader";
import $ from "jquery";
import DeleteModal from "../../../../ui/Modal/deletemodal/DeleteModal";
import UserList from "../../../../ui/userList/";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
// import RecuitersLeftPanel from '../leftpanel/RecuitersLeftPanel';
import RecuitersLeftPanel from '../leftpanel/RecuitersLeftPanel';
//import CustomDateFormat from "../../../../ui/dateFormats/CustomDateFormat";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    tblDdlActive: {
        backgroundColor: '#31a886 !important',
        color: '#ffffff !important',
        minWidth: 90
    },
    tblDdlInActive: {
        backgroundColor: '#d8345f !important',
        color: '#ffffff !important',
        minWidth: 90
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: 'green',
    },
}));


const KpiCaseSummary = () => {
    const dispatch = useDispatch();
    const { dataList, isLoading, isUpdate, isInsert, isReorder, isSort, isBulkDelete, isError, isDelete, errorMessage } = useSelector(state => state.recruterKpiSummary);

    const [bulkLoad, setBulkLoad] = useState(false)
    const [isActive, setIsActive] = useState(false);
    const [countCheck, setCountCheck] = useState(0)
    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')

    const classes = useStyles();

    const [tutorials, setTutorials] = useState([]);
    const [addNewModal, setIsAddNewModal] = useState(false)
    const [reorderModal, setIsReorderModal] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const tutorialsRef = useRef();
    tutorialsRef.current = tutorials;

    useEffect(() => {

        if (isBulkDelete) {
            dispatch(clearState())
            dispatch(listTable({ year: yearValue, month: monthValue }))
            setIsActive(false)
            setCheckBtnTxt('Check All')
            setCountCheck(0)
            setBulkLoad(false)
            $('.chkCandidate').prop('checked', false);
            setIsActive(false)
            dispatch(showSuccess({ msg: errorMessage }))
            hideDeleteModal()
        }
    }, [isBulkDelete])

    const hideDeleteModal = () => {
        setIsDeletePop(false)
    }

    const showReorderModal = () => {
        setIsReorderModal(true)
    }
    const hideReorderModal = () => {
        setIsReorderModal(false)
    }
    const showAddNewModal = () => {
        setIsAddNewModal(true)
    }
    const hideAddNewModal = () => {
        setIsAddNewModal(false)
    }

    var today = new Date();
    const [yearValue, setYearValue] = useState(today.getFullYear())
    const [monthValue, setMonthValue] = useState(today.getMonth() + 1)

    const retrieveData = () => {
        dispatch(listTable({ year: yearValue, month: monthValue }))
    }
    useEffect(retrieveData, [yearValue, monthValue]);
    useEffect(() => {
        if (!isLoading && dataList) {
            setTutorials(dataList)
        }
        if (isUpdate) {
            setTutorials(dataList)
            dispatch(clearState())
        }
        if (isInsert) {
            setTutorials(dataList)
            dispatch(clearState())
        }
        if (isReorder) {
            setTutorials(dataList)
            dispatch(clearState())
            retrieveData()
        }
        if (isDelete) {
            setShowDelete(false)
            dispatch(showSuccess({ msg: 'Delete Successfully.' }))
            dispatch(clearState())
            setTutorials(dataList)
        }
        if (isSort) {
            setTutorials(dataList)
            dispatch(clearState())
        }
    }, [isLoading, isBulkDelete, isUpdate, isInsert, isReorder, isDelete, isSort])

    const myRef = useRef(null);
    const [showDelete, setShowDelete] = useState(false)

    const [columnKey, setColumnKey] = useState('')
    const [columnValue, setColumnValue] = useState('0')
    const [isUpdatePop, setIsUpdatePop] = useState(false)

    // const deleteSelected = (id) => {
    //     setRowId(id)
    //     setShowDelete(true)
    // }

    const handleClose = () => {
        setShowDelete(false)
    }

    const deleteItem = async () => {
        //  dispatch(deleteClient({ id: rowId }))
    }

    const sortBy = e => {
        dispatch(sortData({ key: e.target.dataset.column }));
    }

    const searchFunction = (e) => {
        setSearchValue(e.target.value)
        var value = (searchValue).toLowerCase();
        $("#data-table tbody tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    }

    const resetTable = () => {
        setSearchValue('')
        dispatch(clearState())
        retrieveData()
    }

    const openUpdateValuePop = (id, key, value) => {
        setRowId(id)
        setColumnKey(key)
        setColumnValue(value)
        setIsUpdatePop(true)
    }

    const hideUpdateValuePop = () => {
        setIsUpdatePop(false)
    }


    const addNew = () => {
        dispatch(addValue({ year: yearValue, month: monthValue, recruiter: -1, day: '', assigned: 0, qualified: 0, jobSpaces: 0, sendouts: 0, jobOffers: 0, refused: 0, joined: 0, credit: 0 }))
    }

    const toggleCheckbox = (e, id) => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount > 0) {
            setCountCheck(checkedBoxCount)
            setIsActive(true)
        } else {
            setCountCheck(0)
            setIsActive(false)
        }
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Check All')
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
        }
    }

    const [rowId, setRowId] = useState('0')
    const [isDeletePop, setIsDeletePop] = useState(false);
    const [isDeleteBulkPop, setIsDeleteBulkPop] = useState(false);

    const handleToggle = () => {
        $('.chkCandidate').prop('checked', false);
        setIsActive(false);
    };

    const toggleCheckAll = () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
            setCountCheck(totalCheckboxes)
            $('.chkCandidate').prop('checked', true);
            // setCheckedBoxes(checkedBoxes.filter((v, i, a) => a.indexOf(v) === i))
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Check All')
            setCountCheck(0)
            $('.chkCandidate').prop('checked', false);
            setIsActive(false)
        }
        console.log(checkedBoxCount)
    }

    const closeDelete = () => {
        setIsDeletePop(false)
    }

    const deleteSelected = () => {
        setIsDeleteBulkPop(true)
    }
    const closeDeleteBulk = () => {
        setIsDeleteBulkPop(false)
    }

    const deleteItemBulk = async () => {
        setBulkLoad(true)
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
        } else {
            var canIds = [];
            $.each($(".chkCandidate:checked"), function () {
                canIds.push($(this).val());
            });

            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            await fetch(process.env.REACT_APP_BASE_URL + '/kpi-report-summary/bulkdelete', {
                method: 'POST',
                body: JSON.stringify({ 'ids': canIds }),
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                    'platform': 'web',
                    'Authorization': 'Bearer' + ' ' + token.access_token
                }
            }).then(response => {
                if (response.status === 200) {
                    dispatch(showSuccess({ msg: 'Delete successfully' }))
                    async function fetchNewData() {
                        try {
                            await dispatch(clearState())
                            await dispatch(listTable({ year: yearValue, month: monthValue }))
                            await dispatch(bulkDeleteState())
                        } catch (err) {
                            console.log(err)
                        }
                    }
                    fetchNewData()
                    setBulkLoad(false)
                    closeDeleteBulk(false)
                    setIsActive(false)
                }
            })
        }
    }

    const selectUser = (e) => {
        dispatch(updateValue({ id: e.target.dataset.rowid, key: "recruiter", value: e.target.dataset.id }))
    }
    const changeYears = (data) => {
        setYearValue(data)
    }

    return (
        <>

            {
                (isUpdatePop) ? <UpdatePop onHide={hideUpdateValuePop} rowId={rowId} columnKey={columnKey} value={columnValue} /> : null
            }

            {
                (isDeletePop) ? <DeleteModal id={rowId} onHide={hideDeleteModal} deleteItem={deleteItem} handleClose={closeDelete} /> : null
            }

            {
                (isDeleteBulkPop) ? <DeleteModal id={rowId} onHide={hideDeleteModal} deleteItem={deleteItemBulk} handleClose={closeDeleteBulk} /> : null
            }
            <div className='dkg-recuiterskpiMainCon container-fluid'>
                <div className='row'>
                    <div className='col-md-12  col-12 dkg-recuiters-tabsCon'>
                        <RecuitersLeftPanel />
                    </div>
                    <div className='col-md-12 col-12 dkg-recuiters-tableCon-234'>
                        <div className="dkg-recuiters-tableCon-234" style={{ padding: "0 15px", width: "100%" }}>
                            <div className="dk-sendouts-rightPanel dkg-recuiters-suMM-rightpanel">
                                <div className="dk-kpiCase-rightPanel dk-assignedBg">
                                    <div className="dk-kpiCaseHead">
                                        <div className="dk-kpiCase-yearsDropdown">
                                            <div className="d-flex align-items-center">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                                        {yearValue}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => changeYears('2022')}>2022</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => changeYears('2021')}>2021</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => changeYears('2020')} >2020</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => changeYears('2019')} >2019</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                <div className="dk-clientsAddMonth">
                                                    <button className="addMonthBtn" onClick={addNew}><i className="fas fa-plus"></i></button>
                                                </div>
                                                {/* <Link className="addMonthBtn" to="#" style={{ marginRight: '0' }}><i className="fas fa-plus"></i></Link> */}
                                            </div>
                                        </div>
                                        <div className="dk-kpiCase-months">
                                            <ul>
                                                <li><Link to="#" onClick={() => setMonthValue('1')} className={(monthValue == '1') ? 'active' : null}>Jan</Link></li>
                                                <li><Link to="#" onClick={() => setMonthValue('2')} className={(monthValue == '2') ? 'active' : null}>Feb</Link></li>
                                                <li><Link to="#" onClick={() => setMonthValue('3')} className={(monthValue == '3') ? 'active' : null}>Mar</Link></li>
                                                <li><Link to="#" onClick={() => setMonthValue('4')} className={(monthValue == '4') ? 'active' : null}>Apr</Link></li>
                                                <li><Link to="#" onClick={() => setMonthValue('5')} className={(monthValue == '5') ? 'active' : null}>May</Link></li>
                                                <li><Link to="#" onClick={() => setMonthValue('6')} className={(monthValue == '6') ? 'active' : null}>Jun</Link></li>
                                                <li><Link to="#" onClick={() => setMonthValue('7')} className={(monthValue == '7') ? 'active' : null}>Jul</Link></li>
                                                <li><Link to="#" onClick={() => setMonthValue('8')} className={(monthValue == '8') ? 'active' : null}>Aug</Link></li>
                                                <li><Link to="#" onClick={() => setMonthValue('9')} className={(monthValue == '9') ? 'active' : null}>Sep</Link></li>
                                                <li><Link to="#" onClick={() => setMonthValue('10')} className={(monthValue == '10') ? 'active' : null}>Oct</Link></li>
                                                <li><Link to="#" onClick={() => setMonthValue('11')} className={(monthValue == '11') ? 'active' : null}>Nov</Link></li>
                                                <li><Link to="#" onClick={() => setMonthValue('12')} className={(monthValue == '12') ? 'active' : null}>Dec</Link></li>
                                            </ul>

                                        </div>
                                    </div>


                                    <div className="dk-kpiCaseSummary-table">
                                        <table id="data-table" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th onClick={sortBy} data-column="orderNo">No</th>
                                                    <th onClick={sortBy} data-column="AM">Recruiter</th>
                                                    <th onClick={sortBy} data-column="company">Date</th>
                                                    <th onClick={sortBy} data-column="company">Day</th>
                                                    <th onClick={sortBy} data-column="projects">Assigned</th>
                                                    <th onClick={sortBy} data-column="noOfJobs">Job Specs</th>
                                                    <th onClick={sortBy} data-column="role">Qualified</th>
                                                    <th onClick={sortBy} data-column="interviews">Sendouts</th>
                                                    <th onClick={sortBy} data-column="salesTarget">Job Offers</th>
                                                    <th onClick={sortBy} data-column="jobOffers">Refused</th>
                                                    <th onClick={sortBy} data-column="jobOffers">Joined</th>
                                                    <th onClick={sortBy} data-column="minus">Credit</th>

                                                </tr>
                                            </thead>
                                            {
                                                (!isLoading) ?
                                                    <tbody>
                                                        {

                                                            tutorials.map((item, index) =>
                                                                <tr key={index + 1}>
                                                                    <td>
                                                                        <div className="dk-smsTableCheckbox">
                                                                            {index + 1}
                                                                            <input type="checkbox" className="chkCandidate" name="chkCandidate[]" value={item.id} onChange={(e) => toggleCheckbox(e, item.id)} />
                                                                        </div>
                                                                    </td>
                                                                    <td>  <input type="hidden" id="userId" value="0" />
                                                                        <div className="dk-salesSendouts-users">
                                                                            <Dropdown className="dk-globalUserDropdown">
                                                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                                    {
                                                                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.recInfo.profileImage} className="select-user-img" />
                                                                                    }
                                                                                </Dropdown.Toggle>

                                                                                <Dropdown.Menu className='dk-adstracker-DrpMenu12'>
                                                                                    <div className="d-flex">
                                                                                        <UserList rowid={item.id} onClick={selectUser} />
                                                                                    </div>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </td>

                                                                    {/* <td className="cursor-p" onClick={() => openUpdateValuePop(item.id, 'createdAt', item.createdAt)}>
                                                            <CustomDateFormat date={item.createdAt} format='DD MMM YY' />
                                                            </td> */}

                                                                    <td className="cursor-p" onClick={() => openUpdateValuePop(item.id, 'date', item.date)}>
                                                                        {
                                                                            item.date
                                                                        }
                                                                    </td>
                                                                    <td className="cursor-p" onClick={() => openUpdateValuePop(item.id, 'day', item.day)}>
                                                                        {
                                                                            item.day
                                                                        }
                                                                    </td>
                                                                    <td className="cursor-p dkg-assigned-tdbg" onClick={() => openUpdateValuePop(item.id, 'assigned', item.assigned)}>
                                                                        {
                                                                            item.assigned
                                                                        }
                                                                    </td>
                                                                    <td className="cursor-p dkg-jobspecs-tdbg" onClick={() => openUpdateValuePop(item.id, 'jobSpaces', item.jobSpaces)}>
                                                                        {
                                                                            item.jobSpaces
                                                                        }
                                                                    </td>
                                                                    <td className="cursor-p dkg-qualified-tdbg" onClick={() => openUpdateValuePop(item.id, 'qualified', item.qualified)}>
                                                                        {
                                                                            item.qualified
                                                                        }
                                                                    </td>
                                                                    <td className="cursor-p dkg-sendouts-tdbg" onClick={() => openUpdateValuePop(item.id, 'sendouts', item.sendouts)}>
                                                                        {
                                                                            item.sendouts
                                                                        }
                                                                    </td>
                                                                    <td className="pointer-cursor dkg-jooffers-tdbg dkg-client-saleTarget" onClick={() => openUpdateValuePop(item.id, 'jobOffers', item.jobOffers)}>
                                                                        {
                                                                            item.jobOffers
                                                                        }

                                                                    </td>
                                                                    <td className="pointer-cursor dkg-refused-tdbg dkg-client-bluecolor" onClick={() => openUpdateValuePop(item.id, 'refused', item.refused)}>
                                                                        {
                                                                            item.refused
                                                                        }
                                                                    </td>
                                                                    <td className="pointer-cursor dkg-joined-tdbg dkg-client-refusedTarget" onClick={() => openUpdateValuePop(item.id, 'joined', item.joined)}>
                                                                        {
                                                                            item.joined
                                                                        }
                                                                    </td>
                                                                    <td className="pointer-cursor dkg-credit-tdbg dkg-client-refusedTarget" onClick={() => openUpdateValuePop(item.id, 'credit', item.credit)}>
                                                                        {
                                                                            item.credit
                                                                        }
                                                                    </td>


                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                    :
                                                    <TableLoader colSpan="13" />
                                            }
                                            <tfoot className='dkg-recuiters-footer-231'>
                                                <tr>
                                                    <td colSpan="4" style={{ border: 'none' }}></td>
                                                    <td className="sumBgBlank">
                                                        {
                                                            tutorials.reduce((a, v) => a = a + Number(v.assigned), 0)
                                                        }
                                                    </td>
                                                    <td className="sumBgBlank text-center">
                                                        {
                                                            tutorials.reduce((a, v) => a = a + Number(v.jobSpaces), 0)
                                                        }
                                                    </td>
                                                    <td className="sumBgBlank text-center" >
                                                        {
                                                            tutorials.reduce((a, v) => a = a + Number(v.qualified), 0)
                                                        }
                                                    </td>
                                                    <td className="sumBgBlank text-center">
                                                        {
                                                            tutorials.reduce((a, v) => a = a + Number(v.sendouts), 0)
                                                        }</td>
                                                    <td className="sumBgBlank">
                                                        {
                                                            tutorials.reduce((a, v) => a = a + Number(v.jobOffers), 0)
                                                        } </td>
                                                    <td className="sumBgBlank">
                                                        {
                                                            tutorials.reduce((a, v) => a = a + Number(v.refused), 0)
                                                        }</td>
                                                    <td className="sumBgBlank">
                                                        {
                                                            tutorials.reduce((a, v) => a = a + Number(v.joined), 0)
                                                        }
                                                    </td>
                                                    <td className="sumBgBlank">
                                                        {
                                                            tutorials.reduce((a, v) => a = a + Number(v.credit), 0)
                                                        }
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`dk-smsMessage-selectMsg ${isActive ? "activePopup" : ""}`}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-selectNo">{countCheck}</div>
                    <div className="dk-rowSelected">
                        <span>Row Selected</span>
                    </div>
                    <div className="dk-checkAll" onClick={toggleCheckAll}>
                        <span><i className="fas fa-check"></i></span>
                        {checkBtnTxt}
                    </div>
                    {
                        (bulkLoad) ?
                            <div className={`dk-checkAll ` + classes.backdrop}>
                                <CircularProgress color="inherit" />
                            </div>
                            :
                            <div className="dk-checkAll" onClick={deleteSelected}>
                                <span><i className="fas fa-trash-alt"></i></span>
                                Delete
                            </div>
                    }
                    <div className="dk-checkAll d-flex align-items-center justify-content-center" onClick={handleToggle}>
                        <span><i className="fas fa-times"></i></span>
                    </div>
                </div>
            </div>

        </>
    )
}

export default KpiCaseSummary;

