const STORAGE_PREFIX = 'DKG';

/**
 * set value to the storage
 *
 * @param key - key
 * @param value - value
 */
const setItem = (key, value) => {
    localStorage.setItem(`${STORAGE_PREFIX}_${key}`, value);
}

/**
 * get value from storage by given key
 *
 * @param key - key
 * @param defaultValue - default value if undefined
 * @returns value of given key
 */
const getItem = (key, defaultValue = undefined) => {
    return localStorage.getItem(`${STORAGE_PREFIX}_${key}`) || defaultValue;
}

/**
 * remove value from storage by given key
 *
 * @param key - key
 */
const removeItem = (key) => {
    localStorage.removeItem(`${STORAGE_PREFIX}_${key}`);
}

/**
 * set default mail to storage
 *
 * @param email - email
 */
export const setDefaultMail = (email) => {
    setItem('DEFAULT_MAIL', email);
}

/**
 * get default mail from storage
 *
 * @returns default email
 */
export const getDefaultMail = () => {
    return getItem('DEFAULT_MAIL', '');
}

/**
 * remove default mail from storage
 */
export const removeDefaultMail = () => {
    removeItem('DEFAULT_MAIL');
}