import React from "react";
import Header from "../elements/header";
import Navbar from "../elements/header/navbar/mainMenu/ProjectionsMenu";
import ProgressBar from "../utils/loader/linear";

const Layout = ({ children }) => {

    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="Recruitment -" moduleLink="/dashboard/recruitment" linkcolor="#3c4c62" mainheaderTitle=" Projections" textcolor="#26596d" />
            <Navbar />
            <ProgressBar />
            {children}
        </React.Fragment>
    )
}
export default Layout;