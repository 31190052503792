import React from 'react';
import { Link, Route } from "react-router-dom";

const RequestLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <ul>
                <Route>
                    <li><Link to="/training-req/summary" className={`${pathname.match('/summary') ? 'active profile-greybg' : 'profile-greybg'}`}>Summary</Link></li>
                    <li><Link to="#/training-req/recruiter">Recruiter</Link></li>
                    <li><Link to="#/training-req/rec-admin">Rec Admin</Link></li>
                    <li><Link to="#/training-req/account-manager">Account M</Link></li>
                    <li><Link to="#/training-req/accounts">Accounts</Link></li>
                    <li><Link to="#/training-req/dkg-admin">DKG Admin</Link></li>
                    <li><Link to="#/training-req/dkg-manager">DKG Manager</Link></li>
                </Route>
            </ul>
        </>
    )
}

export default RequestLeftPanel;