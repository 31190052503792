import React from 'react';
import Header from "../elements/header";
import Navbar from '../elements/header/navbar/mainMenu/MyfilesMenu';

const MyfilesLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header mainheaderLink="DK GLOBAL RECRUITMENT" moduleLink="/dashboard/recruitment" linkcolor="#2e97b6" mainheaderTitle=" - My Files" textcolor="#D58B5D" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default MyfilesLayout;