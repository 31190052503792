import React from "react";
import Header from "../elements/header";
import Navbar from "../elements/header/navbar/mainMenu/PaymentsMenu";
import ProgressBar from "../utils/loader/linear";

const Layout = ({ children }) => {

    return (
        <React.Fragment>
            <Header mainheaderLink="DK GLOBAL RECRUITMENT" moduleLink="/dashboard/recruitment" linkcolor="#2e97b6" mainheaderTitle=" - Payments" textcolor="#007D7F" />
            <Navbar />
            <ProgressBar />
            {children}
        </React.Fragment>
    )
}
export default Layout;