import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
export const GlobalFilter = ({ filter, setFilter }) => {
    const [value, setValue] = useState(filter);
    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined)
    }, 300)
    return (
        <React.Fragment>
            <input type="text" className="form-control" placeholder="Search..." 
            value={value || ''} onChange={(e) => {
                setValue(e.target.value)
                onChange(e.target.value)
            }}/>
        </React.Fragment>
    )
}