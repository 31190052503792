import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import { FaSearch, FaFilter } from 'react-icons/fa';
import EditDelete from "../modal/EditDelete";
import Table from 'react-bootstrap/Table';


const Details = () => {
    const [showEditDeleteModal, EditDeleteModal] = React.useState(false);
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(true);
        show(false);
    }
    return (
        <>
            {showEditDeleteModal &&
                <EditDelete />
            }
            <div className="dk-kpiColsMain kpiTableDetailMain">
                <div className="dk-detailTableMain">
                    <div className="dk-detailHead">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="dk-detailsLeft">
                                <div className="dk-searchCols">
                                    <input type="text" placeholder="Search..." name="" id="" className="form-control" />
                                    <button><FaSearch /></button>
                                </div>
                                <div className="dk-userDrop">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                            <small>RC</small>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className="d-flex align-items-center">
                                                <Dropdown.Item href="#/action-1">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" alt="" />
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-1">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cc993057d6b0.png" alt="" />
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-1">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5bd2c15416.png" alt="" />
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-1">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="" />
                                                </Dropdown.Item>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="dk-userDrop mr-2">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                            <small>AM</small>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className="d-flex align-items-center">
                                                <Dropdown.Item href="#/action-1">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" alt="" />
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-1">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cc993057d6b0.png" alt="" />
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-1">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5bd2c15416.png" alt="" />
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-1">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="" />
                                                </Dropdown.Item>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                            </div>

                            <div className="dk-detailsLeft">
                                <div className="dk-detailAdd">
                                    <button><FaFilter style={{ fontSize: '14px' }} /></button>
                                </div>
                                <div className="dk-addNewBtn">
                                    <Link to="#"><i className="fas fa-plus"></i> Add New</Link>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="dk-detailsTable">
                        <div className="table-responsive">
                            <Table className="table table-bordered" cellPadding={10} cellSpacing={10}>
                                <thead>
                                    <tr>
                                        <th>SNo. & Case ID</th>
                                        <th>Date & Time</th>
                                        <th>RC</th>
                                        <th>AM</th>
                                        <th>Status</th>
                                        <th>Candidate Name</th>
                                        <th>Company</th>
                                        <th>Project</th>
                                        <th>Role Type</th>
                                        <th>Language</th>
                                        <th>CV Source</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="dkg-kpispec-sno-idcon">
                                                <span className='dkg-kpi-sno'>1</span>
                                                <div className="dk-noTd">
                                                    <input type="checkbox" onClick={() => EditDeleteModal(!showEditDeleteModal)} />
                                                    <span>51503</span>
                                                </div>
                                            </div>

                                        </td>
                                        <td>01 Mar 2022</td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-status">
                                                Job Spec
                                            </div>
                                        </td>
                                        <td>Eleonora Gioia</td>
                                        <td>Teleperformance Greece</td>
                                        <td>Volkswagen</td>
                                        <td>Technical Analyst</td>
                                        <td>Polish</td>
                                        <td>ELJ</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-kpispec-sno-idcon">
                                                <span className='dkg-kpi-sno'>2</span>
                                                <div className="dk-noTd">
                                                    <input type="checkbox" onClick={() => EditDeleteModal(!showEditDeleteModal)} />
                                                    <span>51503</span>
                                                </div>
                                            </div>

                                        </td>
                                        <td>01 Mar 2022</td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-status">
                                                Job Spec
                                            </div>
                                        </td>
                                        <td>Kinga Krzak</td>
                                        <td>Teleperformance Greece</td>
                                        <td>Facebook</td>
                                        <td>Content Moderator</td>
                                        <td>German</td>
                                        <td>ELJ</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-kpispec-sno-idcon">
                                                <span className='dkg-kpi-sno'>3</span>
                                                <div className="dk-noTd">
                                                    <input type="checkbox" onClick={() => EditDeleteModal(!showEditDeleteModal)} />
                                                    <span>51503</span>
                                                </div>
                                            </div>

                                        </td>
                                        <td>01 Mar 2022</td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-status">
                                                Job Spec
                                            </div>
                                        </td>
                                        <td>Eleonora Gioia</td>
                                        <td>Teleperformance Greece</td>
                                        <td>Apple</td>
                                        <td>ITS</td>
                                        <td>Italian</td>
                                        <td>ELJ</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-kpispec-sno-idcon">
                                                <span className='dkg-kpi-sno'>4</span>
                                                <div className="dk-noTd">
                                                    <input type="checkbox" onClick={() => EditDeleteModal(!showEditDeleteModal)} />
                                                    <span>51503</span>
                                                </div>
                                            </div>

                                        </td>
                                        <td>01 Mar 2022</td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-status">
                                                Job Spec
                                            </div>
                                        </td>
                                        <td>Eleonora Gioia</td>
                                        <td>Teleperformance Greece</td>
                                        <td>Volkswagen</td>
                                        <td>Technical Analyst</td>
                                        <td>Polish</td>
                                        <td>ELJ</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-kpispec-sno-idcon">
                                                <span className='dkg-kpi-sno'>5</span>
                                                <div className="dk-noTd">
                                                    <input type="checkbox" onClick={() => EditDeleteModal(!showEditDeleteModal)} />
                                                    <span>51503</span>
                                                </div>
                                            </div>

                                        </td>
                                        <td>01 Mar 2022</td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-status">
                                                Job Spec
                                            </div>
                                        </td>
                                        <td>Kinga Krzak</td>
                                        <td>Teleperformance Greece</td>
                                        <td>Facebook</td>
                                        <td>Content Moderator</td>
                                        <td>German</td>
                                        <td>ELJ</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-kpispec-sno-idcon">
                                                <span className='dkg-kpi-sno'>6</span>
                                                <div className="dk-noTd">
                                                    <input type="checkbox" onClick={() => EditDeleteModal(!showEditDeleteModal)} />
                                                    <span>51503</span>
                                                </div>
                                            </div>

                                        </td>
                                        <td>01 Mar 2022</td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-status">
                                                Job Spec
                                            </div>
                                        </td>
                                        <td>Eleonora Gioia</td>
                                        <td>Teleperformance Greece</td>
                                        <td>Apple</td>
                                        <td>ITS</td>
                                        <td>Italian</td>
                                        <td>ELJ</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-kpispec-sno-idcon">
                                                <span className='dkg-kpi-sno'>7</span>
                                                <div className="dk-noTd">
                                                    <input type="checkbox" onClick={() => EditDeleteModal(!showEditDeleteModal)} />
                                                    <span>51503</span>
                                                </div>
                                            </div>

                                        </td>
                                        <td>01 Mar 2022</td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-status">
                                                Job Spec
                                            </div>
                                        </td>
                                        <td>Eleonora Gioia</td>
                                        <td>Teleperformance Greece</td>
                                        <td>Volkswagen</td>
                                        <td>Technical Analyst</td>
                                        <td>Polish</td>
                                        <td>ELJ</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-kpispec-sno-idcon">
                                                <span className='dkg-kpi-sno'>8</span>
                                                <div className="dk-noTd">
                                                    <input type="checkbox" onClick={() => EditDeleteModal(!showEditDeleteModal)} />
                                                    <span>51503</span>
                                                </div>
                                            </div>

                                        </td>
                                        <td>01 Mar 2022</td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-status">
                                                Job Spec
                                            </div>
                                        </td>
                                        <td>Kinga Krzak</td>
                                        <td>Teleperformance Greece</td>
                                        <td>Facebook</td>
                                        <td>Content Moderator</td>
                                        <td>German</td>
                                        <td>ELJ</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-kpispec-sno-idcon">
                                                <span className='dkg-kpi-sno'>9</span>
                                                <div className="dk-noTd">
                                                    <input type="checkbox" onClick={() => EditDeleteModal(!showEditDeleteModal)} />
                                                    <span>51503</span>
                                                </div>
                                            </div>

                                        </td>
                                        <td>01 Mar 2022</td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-status">
                                                Job Spec
                                            </div>
                                        </td>
                                        <td>Eleonora Gioia</td>
                                        <td>Teleperformance Greece</td>
                                        <td>Apple</td>
                                        <td>ITS</td>
                                        <td>Italian</td>
                                        <td>ELJ</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-kpispec-sno-idcon">
                                                <span className='dkg-kpi-sno'>10</span>
                                                <div className="dk-noTd">
                                                    <input type="checkbox" onClick={() => EditDeleteModal(!showEditDeleteModal)} />
                                                    <span>51503</span>
                                                </div>
                                            </div>

                                        </td>
                                        <td>01 Mar 2022</td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-status">
                                                Job Spec
                                            </div>
                                        </td>
                                        <td>Eleonora Gioia</td>
                                        <td>Teleperformance Greece</td>
                                        <td>Volkswagen</td>
                                        <td>Technical Analyst</td>
                                        <td>Polish</td>
                                        <td>ELJ</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-kpispec-sno-idcon">
                                                <span className='dkg-kpi-sno'>11</span>
                                                <div className="dk-noTd">
                                                    <input type="checkbox" onClick={() => EditDeleteModal(!showEditDeleteModal)} />
                                                    <span>51503</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>01 Mar 2022</td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-status">
                                                Job Spec
                                            </div>
                                        </td>
                                        <td>Kinga Krzak</td>
                                        <td>Teleperformance Greece</td>
                                        <td>Facebook</td>
                                        <td>Content Moderator</td>
                                        <td>German</td>
                                        <td>ELJ</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-kpispec-sno-idcon">
                                                <span className='dkg-kpi-sno'>12</span>
                                                <div className="dk-noTd">
                                                    <input type="checkbox" onClick={() => EditDeleteModal(!showEditDeleteModal)} />
                                                    <span>51503</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>01 Mar 2022</td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-status">
                                                Job Spec
                                            </div>
                                        </td>
                                        <td>Eleonora Gioia</td>
                                        <td>Teleperformance Greece</td>
                                        <td>Apple</td>
                                        <td>ITS</td>
                                        <td>Italian</td>
                                        <td>ELJ</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Details
