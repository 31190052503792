import React, {useState} from "react";
import { Link } from "react-router-dom";
import  Modal  from "react-bootstrap/modal";
import {  FaQuestion, FaSearch } from "react-icons/fa";
import  './notification-faq.scss'
import OverviewImg from '../../../../../assets/images/business_icon.png';
import ClientsImg from '../../../../../assets/images/clients_icon.png';
import JobsImg from '../../../../../assets/images/jobs_icon.png';
import OutlookImg from '../../../../../assets/images/outlook_icon.png';
import ProcessImg from '../../../../../assets/images/process_icon.png';
import EmailsImg from '../../../../../assets/images/emails_icon.png';
import CallsImg from '../../../../../assets/images/calls_icon.png';
import SmsImg from '../../../../../assets/images/sms_icon.png';
import CommonIcon from '../../../../../assets/images/training_black.png';
import CandidatesImg from '../../../../../assets/images/candidates_icon.png';
import StructureImg from '../../../../../assets/images/structure.png';
import TrainingImg from '../../../../../assets/images/training_icon.png';
import PipelinesImg from '../../../../../assets/images/pipeline_icon.png';
import ToolsImg from '../../../../../assets/images/tools_icon.png';
import MasterboxImg from '../../../../../assets/images/masterbox.png';
import ChatsImg from '../../../../../assets/images/chats_icon.png';
import WorkPlanImg from '../../../../../assets/images/workplan_icon.png';
import MyTaskImg from '../../../../../assets/images/task.png';
import SalesImg from '../../../../../assets/images/sales_icon.png';
import ResourcesImg from '../../../../../assets/images/resources_icon.png';
import InvoiceImg from '../../../../../assets/images/euro.png';

const NotificationFaq = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Link to="#"  className="dk-unreadEmails" title="Question"><FaQuestion  onClick ={handleShow}/></Link>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dk-candidateProfile80 dkg-main-faqlistModal"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="dk-candModalHeader dkg-srch-info-header">
          <Modal.Title
            className="dk-candModalTitle"
            id="example-custom-modal-styling-title"
          >
              KNOWLEDGE BASE
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="dk-notification-faqModalBody">
          <div className="dkg-notifaq-MainPage">
            <div className="dkg-notifaq-trainingBoxMain">
                <div className="dkg-srchinfo-keywordCon">
                    <div className="dkg-srchinfo-keyword">
                        <input type="text" className="form-control dkg-srchinfo-input" placeholder="Search Keywords ..." name="srch-keyword" id="srch-keyword"  />
                        <span className="dkg-srch-icon">
                            <FaSearch />
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={OverviewImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>Overview</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={StructureImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>DKG Structure</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={ProcessImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>DKG Process</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={TrainingImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>DKG Teams Task</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={PipelinesImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>DKG Pipelines</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={ClientsImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>DKG Clients</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={JobsImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>DKG Jobs</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={CandidatesImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>DKG Leads</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={MasterboxImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>DKG CRM</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={ToolsImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>DKG Tools</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={WorkPlanImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>Work Plan</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={MyTaskImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>My Tasks</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={ResourcesImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>Resource</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={SalesImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>Sales</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={InvoiceImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>Payments</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={OutlookImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>Outlook</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={EmailsImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>Emails</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={CallsImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>Calls</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={SmsImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>SMS</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dkg-os-training-box">
                            <Link to="#">
                                <figure>
                                    <img src={ChatsImg} className="img-fluid" alt="" />
                                </figure>
                                <h3>Chats</h3>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NotificationFaq;
