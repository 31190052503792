import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Tab, Nav } from 'react-bootstrap';
import SelectRecruiterDropDwn from "./RecruiterDropDwn";
import SelectAccountManagerDropDwn from "./AccountManagerDropDwn";
import SelectRecruiterAdminDropDwn from "./RecruiterAdminDropDwn";
import { FaRocketchat } from "react-icons/fa";
import CandTabContentSummary from "./summary/Summary";
import Dropdown from 'react-bootstrap/Dropdown';
import CandTabContentWorkflow from './workflow/Workflow';
import CandCommunication from "./communication/Communication";
import CandTabContentOverview from './overview/Overview';
import CandExperience from "./experience/Experience";
import TechSkills from "./techskills/TechSkills";
import ProfileSkills from "./profileskills/ProfileSkills";
import CandQualification from './qualification/Qualification';
import CandOtherInfo from './otherinfo/OtherInfo';
import Documents from './documents/Documents';
import SkypeImg from "../../../../../../assets/images/skype-icon.png";
import TextSearch from './textsearch/TextSearch';
import MatchingJobs from "./matchingjobs/MatchingJobs";
import CaseStatus from './casestatus/CaseStatus';
import { candidateProfileDetails, candidateProfileRecruiterDetails, candidateProfileJobDetails, updateTable, clearState, updateProfileDetails, yearCount, monthsCount } from '../../../../../../slice/candidates/list/listSlice';
import { useDispatch, useSelector } from "react-redux";
import CommentModal from "../../../../../utils/comment/profileComment";
import CandidateDocumentModal from "../../modal/documents/profileDocument";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import AddIndustry from './summary/AddIndustry';
import moment from "moment-timezone";
import DatePicker from "./datepicker";
import PhoneIcon from "../../../../../../assets/images/phone-icon.png";
import EmailIcon from "../../../../../../assets/images/email-icon.png";
import SmsIcon from "../../../../../../assets/images/sms-icon.png";
import ChatIcon from "../../../../../../assets/images/chats_icon.png";
import WhatsIcon from "../../../../../../assets/images/whatsapp-icon.png";
import SkypeIcon from "../../../../../../assets/images/skype-icon.png";
import SocialChatsModal from "./SocialChatsModal";
import CheckIcon from "../../../../../../assets/images/check.png";
import QuestionIcon from "../../../../../../assets/images/question-mark.png";
import CloseIcon from "../../../../../../assets/images/cross.png";
import { FaCheck, FaUserAlt, FaIdCard } from "react-icons/fa";
import ComposeMail from "../../../../mainmenu/mailbox/ComposeMail";
import { indicatorColor, flagColor, profileStatus, profileMotivationStatus } from "../../../../../../helper/commonFunctions";
import leftBoxUserGirl from "../../../../../../assets/images/girl-defult.png";
import leftBoxUserMen from "../../../../../../assets/images/men-default.jpg";
import leftBoxUserDefault from "../../../../../../assets/images/user-defult.jpg";
import DeleteModal from "../../../../../ui/delete";
import $ from "jquery";
import { border } from '@mui/system';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        color: "#fff",
        zIndex: theme.zIndex.drawer + 1,
    },
}));

const CandidateProfile = ({ canid, list, isProfileModal, handleCloseProfile }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { isProfileLoading, isProfileRecLoading, isProfileJobLoading, isProfileLanguageLoading, profileDetails, profileRecDetails, profileJobDetails, profileLangaugeDetails, defaultYearList, defaultMonthList, } = useSelector(state => state.candidates);

    const [open, setOpen] = React.useState(false);
    const [show, setShow] = useState(isProfileModal);

    const [candidateId, setCandidateId] = useState(0);

    const [showingCountOf, setShowingCountOf] = useState(0);
    const [showingCount, setShowingCount] = useState(0);

    const [prevId, setPrevId] = useState(0);
    const [nextId, setNextId] = useState(0);

    const [prevIndex, setPrevIndex] = useState(false);
    const [nextIndex, setNextIndex] = useState(false);

    const [id, setId] = useState(0)
    const [createdAt, setCreatedAt] = useState('')
    const [updatedAt, setUpdatedAt] = useState('')
    const [defaultYear, setDefaultYear] = useState('')
    const [defaultMonth, setDefaultMonth] = useState('')
    const [yearList, setDefaultList] = useState([])
    const [monthList, setDefaultMonthList] = useState([])

    const [name, setName] = useState('')
    const [email1, setEmail1] = useState('')
    const [email2, setEmail2] = useState('')
    const [phone1, setPhone1] = useState('')
    const [phone2, setPhone2] = useState('')
    const [skypeId, setSkypeId] = useState('')
    const [gender, setGender] = useState('')
    const [profileStatusValue, setProfileStatusValue] = useState('')
    const [motivationStatus, setMotivationStatus] = useState('')
    const [indicatorValue, setIndicatorValue] = useState('')
    const [flagTypeValue, setFlagTypeValue] = useState('')

    const [recAdminName, setRecAdminName] = useState('')
    const [recAdminImg, setRecAdminImg] = useState('')

    const [recName, setRecName] = useState('')
    const [recImg, setRecImg] = useState('')

    const [accountManagerName, setAccountManagerName] = useState('')
    const [accountManagerImg, setAccountManagerImg] = useState('')

    const [commentCount, setCommentCount] = useState(0)

    const [oldJobId, setOldJobId] = useState('')
    const [popupJobId, setPopupJobId] = useState('')
    const [jobId, setJobId] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [jobCompanyId, setJobCompanyId] = useState('')
    const [jobCompany, setJobCompany] = useState('')
    const [jobCity, setJobCity] = useState('')
    const [jobCountry, setJobCountry] = useState('')
    const [jobLocality, setJobLocality] = useState('')
    const [jobNo, setJobNo] = useState('')
    const [jobFee, setJobFee] = useState('')
    const [jobProject, setJobProject] = useState('')
    const [jobLanguage, setJobLanguage] = useState('')
    const [jobRole, setJobRole] = useState('')
    const [jobStart, setJobStart] = useState('')
    const [isJobIDShow, setJobIDShow] = useState(false);
    const handleClose = () => setJobIDShow(false);
    const handleShow = () => {
        setJobIDShow(true)
        setPopupJobId('')
    };

    const fetchDetails = async () => {
        //console.log("Modal is Shown")
        await setCandidateId(canid)
    }

    useEffect(() => {
        if (candidateId > 0) {
            findIndexOfProfile()
            const fetchCandidateRecods = async () => {
                dispatch(yearCount({}))
                dispatch(monthsCount({}))

                dispatch(candidateProfileDetails({ id: candidateId }))
                dispatch(candidateProfileRecruiterDetails({ id: candidateId }))
                dispatch(candidateProfileJobDetails({ id: candidateId }))
            }
            fetchCandidateRecods()
        }
    }, [candidateId])

    const prevRecord = () => {
        setCandidateId(prevId)
    }

    const nextRecord = () => {
        setCandidateId(nextId)
    }

    const findIndexOfProfile = () => {
        var index;
        // console.log("Here is the instance", list)
        list.findIndex(function (entry, i) {
            if (entry.original.canId == candidateId) {
                index = i;
                return true;
            }
        });
        setShowingCountOf(index + 1)
        setShowingCount(list.length)

        if (!isEmpty(list[index - 1])) {
            setPrevId(list[index - 1].original.canId)
            setPrevIndex(true)
        } else {
            setPrevIndex(false)
        }
        if (!isEmpty(list[index + 1])) {
            setNextId(list[index + 1].original.canId)
            setNextIndex(true)
        } else {
            setNextIndex(false)
        }
    }

    function isEmpty(obj) {
        for (var i in obj) { return false; }
        return true;
    }

    useEffect(() => {
        if (isProfileLoading) {
            setOpen(true)
        }
        if (!isProfileLoading && profileDetails) {
            setOpen(false)
            if (!isEmpty(profileDetails)) {
                setId(profileDetails.id)
                setCreatedAt(profileDetails.createdAt)
                setUpdatedAt(profileDetails.updatedAt)
                setDefaultYear(profileDetails.year)
                setDefaultMonth(profileDetails.month)
                setName(profileDetails.name)
                setEmail1(profileDetails.email1)
                setEmail2(profileDetails.email2)
                setPhone1(profileDetails.phone1)
                setPhone2(profileDetails.phone2)
                setGender(profileDetails.gender)
                setSkypeId(profileDetails.skypeId)
                setProfileStatusValue(profileDetails.profileStatus)
                setIndicatorValue(profileDetails.profileIndicator)
                setFlagTypeValue(profileDetails.flag_type)
                setMotivationStatus(profileDetails.motivationStatus)
                setCommentCount(profileDetails.commentCount)
                setDefaultList(defaultYearList)
                setDefaultMonthList(defaultMonthList)
            }
        }
        if (!isProfileRecLoading && !isEmpty(profileRecDetails)) {
            setRecAdminName(profileRecDetails.recAdminInfo.firstName + " " + profileRecDetails.recAdminInfo.lastName)
            setRecAdminImg(profileRecDetails.recAdminInfo.profileImage)

            setRecName(profileRecDetails.recInfo.firstName + " " + profileRecDetails.recInfo.lastName)
            setRecImg(profileRecDetails.recInfo.profileImage)

            setAccountManagerName(profileRecDetails.amInfo.firstName + " " + profileRecDetails.amInfo.lastName)
            setAccountManagerImg(profileRecDetails.amInfo.profileImage)
        }
        if (!isProfileJobLoading && !isEmpty(profileJobDetails)) {
            setOldJobId(profileJobDetails.jobDetail.job_id)
            setJobId(profileJobDetails.jobDetail.job_id)
            setJobTitle(profileJobDetails.jobDetail.name)
            setJobCompanyId(profileJobDetails.jobDetail.com_id)
            setJobCompany(profileJobDetails.jobDetail.company)
            setJobCountry(profileJobDetails.jobDetail.job_country)
            setJobLocality(profileJobDetails.jobDetail.locality)
            setJobNo(profileJobDetails.jobDetail.no_of_jobs)
            setJobFee(profileJobDetails.jobDetail.fee)
            setJobProject(profileJobDetails.jobDetail.project)
            setJobLanguage(profileJobDetails.jobDetail.lang)
            setJobRole(profileJobDetails.jobDetail.role)
            setJobStart(profileJobDetails.jobDetail.date1)
        }
    }, [isProfileLoading, isProfileRecLoading, isProfileJobLoading, isProfileLanguageLoading])

    const updateYears = async (idd, value) => {
        await dispatch(updateProfileDetails({ id, key: "year", value }))
        await dispatch(updateTable({ id, key: "year", value }))
        setDefaultYear(value)
    }
    const updateMonths = async (idd, value) => {
        await dispatch(updateProfileDetails({ id, key: "month", value }))
        await dispatch(updateTable({ id, key: "month", value }))
        setDefaultMonth(value)
    }


    const updateGender = async (id, value) => {
        await dispatch(updateProfileDetails({ id, key: "gender", value }))
        await dispatch(updateTable({ id, key: "gender", value }))
    }

    const updateJobCandidate = async (id) => {
        await dispatch(updateTable({ id: id, key: 'jobId', value: jobId }))
        await dispatch(candidateProfileJobDetails({ id: candidateId }))
        await dispatch(updateProfileDetails({ id: id, key: 'jobId', value: jobId }))
        setOpen(true)
        setJobIDShow(false)
        setOldJobId(jobId)
        setOpen(false)
    }

    const updateProfileQuick = async (valueId, value, img, columnId) => {
        switch (columnId) {
            case 'profileStatus':
                $(`#profileStatus${id} button .dk-cvClear-status svg`).css("color", img)
                break;

            case 'profileIndicator':
                $(`#profileIndicator${id} button .dk-cvClear-circle i`).css("color", img)
                break;

            case 'motivationStatus':
                $(`#motivationStatus${id} button .dk-cvClear-userIcon svg`).css("color", img)
                break;

            case 'flag_type':
                $(`#flag_type${id} button .dk-cvClear-flag i`).css("color", img)
                break;
        }
        await dispatch(updateProfileDetails({ id: valueId, key: columnId, value }))
        await dispatch(updateTable({ id: valueId, key: columnId, value }))
    }

    const updateProfile = async (e) => {
        const id = e.target.dataset.id;
        const key = e.target.dataset.key;
        const value = e.target.dataset.value;
        console.log(id + " == " + key + " == " + value)
        if (key === 'rec_id') {
            setRecName(e.target.dataset.name)
            setRecImg(e.target.dataset.img)
        }
        if (key === 'am_id') {
            setAccountManagerName(e.target.dataset.name)
            setAccountManagerImg(e.target.dataset.img)
        }
        if (key === 'rec_admin') {
            setRecAdminName(e.target.dataset.name)
            setRecAdminImg(e.target.dataset.img)
        }
        await dispatch(updateProfileDetails({ id, key, value }))
        await dispatch(updateTable({ id, key, value }))
        if (key === 'jobId') {
            setOpen(true)
            dispatch(candidateProfileJobDetails({ id: candidateId }))
            isJobIDShow(false)
            setOldJobId(value)
            setOpen(false)
        }
        switch (key) {
            case 'caseStatus':
                await dispatch(updateProfileDetails({ id, key: 'case_progress', value: '' }))
                await dispatch(updateProfileDetails({ id, key: 'case_stage', value: '' }))
                await dispatch(updateTable({ id, key: 'case_progress', value: '' }))
                await dispatch(updateTable({ id, key: 'case_stage', value: '' }))
                break;

            case 'case_progress':
                await dispatch(updateProfileDetails({ id, key: 'case_stage', value: '' }))
                await dispatch(updateTable({ id, key: 'case_stage', value: '' }))
                break;

            default:
                break;
        }
    }

    const updateDates = async (e) => {
        const myArray = e.split("==");
        const id = myArray[0];
        const value = moment(myArray[1]).tz("Europe/Dublin").format('YYYY-MM-DD HH:mm:ss');
        const key = myArray[2];
        await dispatch(updateTable({ id, key, value }))
    }

    const updateEditor = async (e) => {
        const myArray = e.split("==");
        const id = myArray[0];
        const value = myArray[1];
        const key = myArray[2];
        await dispatch(updateTable({ id, key, value }))
        await dispatch(updateProfileDetails({ id, key, value }))
    }

    const [tabId, setTabId] = useState(0)
    const tabSelect = (key) => {
        setTabId(key)
    }

    const [isAddSkills, setIsAddSkills] = useState(false);

    const showAddProfieSkills = () => {
        setIsAddSkills(true);
    }
    const hideAddProfieSkills = () => {
        setIsAddSkills(false);
    }

    const [isShowSocialModal, setIsSocialModal] = useState(false);

    const showSocialModal = () => {
        setIsSocialModal(true);
    }
    const hideSocialModal = () => {
        setIsSocialModal(false);
    }

    const [showComposeModal, setShowCompose] = useState(false);

    return (
        <>

            {
                (isAddSkills) ? <AddIndustry hidePopup={hideAddProfieSkills} /> : null
            }
            {
                (isShowSocialModal) ? <SocialChatsModal hidesocial={hideSocialModal} /> : null
            }
            <ComposeMail show={showComposeModal} onHide={() => setShowCompose(false)} />

            <Modal
                show={show} onHide={handleCloseProfile}
                dialogClassName="dk-candidateProfile80"
                aria-labelledby="example-custom-modal-styling-title"
                onEntered={fetchDetails}
                onExited={function () { console.log("onExited") }}
                onShow={function () { console.log("onShow") }}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className="dk-candModalHeader">
                    {/* <div className="dk-candidateProfile-caseID">
                        <label>Case ID - {candidateId}</label>
                    </div> */}
                    <Modal.Title className="dk-candModalTitle" 
                    id="example-custom-modal-styling-title">
                        <div className="dkg-years-DropdownCon">
                            <div className="dkg-clientid">Account ID -  {candidateId}{" "}</div>
                        </div>
                        {
                            (prevIndex) ?
                                <Link to="#" onClick={prevRecord}><i className="fa fa-chevron-left mr-2"></i></Link>
                                : null
                        }
                        {showingCountOf} of {showingCount}
                        {
                            (nextIndex) ?
                                <Link to="#" onClick={nextRecord}><i className="fa fa-chevron-right ml-2"></i></Link>
                                : null
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-candProfileModalBody">
                    <Backdrop
                        className={classes.backdrop}
                        open={open}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <div className="dk-BottomSecCon">
                        <div className="row mr-0 ml-0">
                            <div className="col-md-12 col-sm-12 dk-candidateVertcalTabCon pl-0 pr-0">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="details-overview" onSelect={tabSelect}>
                                    <div className="row mr-0 ml-0">
                                        <div className="col-md-12 col-sm-12 dk-candProfileSideMenu dkg-candProfile-horizontal pl-0 pr-0" id="dk-cliDetailsV">
                                            <Nav variant="pills" className="flex-row dk-candNavPills  dkg-horizontal-navpills">
                                            <Nav.Item>
                                                    <Nav.Link eventKey="details-overview">Overview</Nav.Link>
                                                </Nav.Item>

                                                <Nav.Item>
                                                    <Nav.Link eventKey="socialm">Email Logs</Nav.Link>
                                                </Nav.Item> 
                                                <Nav.Item>
                                                    <Nav.Link eventKey="profile_skills">Profile Skills</Nav.Link>
                                                </Nav.Item> 
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first_level">1st Level</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second_level">2nd Level</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="contacts">Actions</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="matching_jobs">Jobs Matched</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="documents">Documents</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="case_status">Profile Dates</Nav.Link>
                                                </Nav.Item>
                                                {/* <Nav.Item>
                                                    <Nav.Link eventKey="text_seaach">Text Search</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="tech_skills">TECH SKILLS</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="myemails">My Emails <span className="badge-cols">0</span></Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="trackings">TRACKINGS <span className="badge-cols">0</span></Nav.Link>
                                                </Nav.Item> */}
                                            </Nav>
                                        </div>
                                        <div className="col-md-12 col-sm-12 dk-candProfileMainCont">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="details-overview">
                                                    <div className="dk-topSectionCon">
                                                        <div className="row">
                                                            <div className="col-md-5 col-sm-12 pr-0">
                                                                <div className="dk-TopleftCon">

                                                                    <form>
                                                                        <div className="row">
                                                                            <div className="col-md-4 col-sm-12 pr-1">
                                                                                <div>
                                                                                    <div className="dk-cansuMMFromGrp">
                                                                                        <label></label>
                                                                                        <div className="dk-modalHeader cvclear_LinkMain">
                                                                                            <Dropdown className="cvclear_Link" id={`profileStatus${id}`}>
                                                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                                                        <div className="dk-cvClear-status">
                                                                                                            {
                                                                                                                <FaCheck style={{ color: profileStatus(profileStatusValue) }} />
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Dropdown.Toggle>
                                                                                                <Dropdown.Menu className="dk-statusDropdown">
                                                                                                    <Dropdown.Item href="#" onClick={() => updateProfileQuick(id, 1, '#048204', 'profileStatus')} data-id={id} data-key="profileStatus" data-value="1">
                                                                                                        <FaCheck style={{ color: '#048204' }} />
                                                                                                        {/* <small className="smallTitle">100%</small> */}
                                                                                                        <small className="smallTitle">Top Priority</small>
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item href="#" onClick={() => updateProfileQuick(id, 2, '#D58B5D', 'profileStatus')} data-id={id} data-key="profileStatus" data-value="2">
                                                                                                        <FaCheck style={{ color: '#D58B5D' }} />
                                                                                                        {/* <small className="smallTitle">75%</small> */}
                                                                                                        <small className="smallTitle">Mid Priority</small>
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item href="#" onClick={() => updateProfileQuick(id, 3, '#3a76b4', 'profileStatus')} data-id={id} data-key="profileStatus" data-value="3">
                                                                                                        <FaCheck style={{ color: '#3a76b4' }} />
                                                                                                        {/* <small className="smallTitle">50%</small> */}
                                                                                                        <small className="smallTitle">Low Priority</small>
                                                                                                    </Dropdown.Item>
                                                                                                    {/* <Dropdown.Item href="#" onClick={() => updateProfileQuick(id, 4, '#d60101', 'profileStatus')} data-id={id} data-key="profileStatus" data-value="4">
                                                                                                        <FaCheck style={{ color: '#d60101' }} />
                                                                                                        <small className="smallTitle">0%</small>
                                                                                                        <small className="smallTitle">Matched</small>
                                                                                                    </Dropdown.Item> */}
                                                                                                    <Dropdown.Item href="#" onClick={() => updateProfileQuick(id, 0, '#d3d3d3', 'profileStatus')} data-id={id} data-key="profileStatus" data-value="0">
                                                                                                        <FaCheck style={{ color: '#d3d3d3' }} />
                                                                                                        <small className="smallTitle">Blank</small>
                                                                                                    </Dropdown.Item>
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                            <Dropdown className="cvclear_Link" id={`profileIndicator${id}`}>
                                                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                                                        <div className="dk-cvClear-circle">
                                                                                                            <i className="fas fa-circle" style={{ color: indicatorColor(indicatorValue) }}></i>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Dropdown.Toggle>
                                                                                                <Dropdown.Menu>
                                                                                                    <Dropdown.Item href="#" onClick={() => updateProfileQuick(id, 2, 'green', 'profileIndicator')} data-id={id} data-key="profileIndicator" data-value="2">
                                                                                                        <span className="circleIcon green"></span>
                                                                                                        <small className="statusText">Updated</small>
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item href="#" onClick={() => updateProfileQuick(id, 1, '#d3d3d3', 'profileIndicator')} data-id={id} data-key="profileIndicator" data-value="1">
                                                                                                        <span className="circleIcon"></span>
                                                                                                        <small className="statusText">Not Updated</small>
                                                                                                    </Dropdown.Item>
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>

                                                                                            <Dropdown className="cvclear_Link dk-userDropdown" id={`motivationStatus${id}`}>
                                                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                                                        <div className="dk-cvClear-userIcon">
                                                                                                            <FaUserAlt style={{ color: profileMotivationStatus(motivationStatus) }} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Dropdown.Toggle>
                                                                                                <Dropdown.Menu>
                                                                                                    <Dropdown.Item href="#" onClick={() => updateProfileQuick(id, 1, 'green', 'motivationStatus')}>
                                                                                                        <span><FaUserAlt style={{ color: 'green' }} /></span>
                                                                                                        <small className="statusText">High Response</small>
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item href="#" onClick={() => updateProfileQuick(id, 2, '#d58b5d', 'motivationStatus')}>
                                                                                                        <span><FaUserAlt style={{ color: '#d58b5d' }} /></span>
                                                                                                        <small className="statusText">Good Response</small>
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item href="#" onClick={() => updateProfileQuick(id, 3, '#ff0000', 'motivationStatus')}>
                                                                                                        <span><FaUserAlt style={{ color: '#ff0000' }} /></span>
                                                                                                        <small className="statusText">Low Response</small>
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item href="#" onClick={() => updateProfileQuick(id, 0, '#d3d3d3', 'motivationStatus')}>
                                                                                                        <span><FaUserAlt style={{ color: '#d3d3d3' }} /></span>
                                                                                                        <small className="statusText">Blank</small>
                                                                                                    </Dropdown.Item>
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>

                                                                                            <Dropdown className="cvclear_Link flagDropDown" id={`flag_type${id}`}>
                                                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                                                        <div className="dk-cvClear-flag"><i className="fa fa-flag" style={{ color: flagColor(flagTypeValue), fontSize: '14px' }}></i></div>
                                                                                                    </div>
                                                                                                </Dropdown.Toggle>
                                                                                                <Dropdown.Menu>
                                                                                                    <Dropdown.Item href="#" onClick={() => updateProfileQuick(id, 1, '#3a9469', 'flag_type')} data-id={id} data-key="flag_type" data-value="1">
                                                                                                        <i className="fa fa-flag" style={{ color: '#3a9469' }}></i>
                                                                                                        <small className="flagText">Good Case</small>
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item href="#" onClick={() => updateProfileQuick(id, 2, '#d58b5d', 'flag_type')} data-id={id} data-key="flag_type" data-value="2">
                                                                                                        <i className="fa fa-flag" style={{ color: '#d58b5d' }}></i>
                                                                                                        <small className="flagText">Normal Case</small>
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item href="#" onClick={() => updateProfileQuick(id, 3, '#e2445b', 'flag_type')} data-id={id} data-key="flag_type" data-value="3">
                                                                                                        <i className="fa fa-flag" style={{ color: '#e2445b' }}></i>
                                                                                                        <small className="flagText">Bad Case</small>
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item href="#" onClick={() => updateProfileQuick(id, 0, '#d3d3d3', 'flag_type')} data-id={id} data-key="flag_type" data-value="0">
                                                                                                        <i className="fa fa-flag" style={{ color: '#d3d3d3' }}></i>
                                                                                                        <small className="flagText">Blank</small>
                                                                                                    </Dropdown.Item>
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="dk-topLeftIcon-user">
                                                                                        {
                                                                                            (gender == 'Male') ? <img src={leftBoxUserMen} /> : ((gender == 'Female') ? <img src={leftBoxUserGirl} /> : <img src={leftBoxUserDefault} />)
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className='dkg-month-dropdownCon'>
                                                                                    <label>Year</label>
                                                                                    <div className='dkg-years-DropdownCon'>
                                                                                        <Dropdown className='dkg-years-Dropdown mr-2'>
                                                                                            <Dropdown.Toggle variant="" className='dkg-years-dropdown-toggle'>
                                                                                                {defaultYear}
                                                                                            </Dropdown.Toggle>
                                                                                            <Dropdown.Menu className='dkg-years-DropdownMenu'>
                                                                                                {
                                                                                                    yearList.map(function (item, i) {
                                                                                                        return <>
                                                                                                            <Dropdown.Item onClick={(e) => updateYears(candidateId, item.value)} key={i} href="#" className='dkg-years-DropdownItem'>{item.value}</Dropdown.Item>
                                                                                                        </>
                                                                                                    })
                                                                                                }

                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown>
                                                                                    </div>    
                                                                                </div>
                                                                                <div className='dkg-month-dropdownCon'>
                                                                                    <label>Month</label>
                                                                                    <Dropdown className='dkg-years-Dropdown  ml-2'>
                                                                                        <Dropdown.Toggle variant="" className='dkg-years-dropdown-toggle'>
                                                                                            {defaultMonth}
                                                                                        </Dropdown.Toggle>
                                                                                        <Dropdown.Menu className='dkg-years-DropdownMenu'>
                                                                                            {
                                                                                                monthList.map(function (item, i) {
                                                                                                    return <>
                                                                                                        <Dropdown.Item onClick={(e) => updateMonths(candidateId, item.value)} key={i} href="#" className='dkg-years-DropdownItem'>{item.value}</Dropdown.Item>
                                                                                                    </>
                                                                                                })
                                                                                            }
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                </div>
                                                                                {/* <div>
                                                                                    <div className="dk-cansuMMFromGrp">
                                                                                        <label>Case ID</label>
                                                                                        <div className="dk-candidateProfile-caseID">
                                                                                            <label className="dk-caseIDbg">{candidateId} <span><FaIdCard /></span></label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="form-group dk-Candinate-fromGroup d-flex dk-sexDropdown">
                                                                                        <label>Gender</label>
                                                                                        <Dropdown className={(gender == '' ? "errorBorder" : null)}>
                                                                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                                                {(gender == '') ? 'Select' : gender}
                                                                                            </Dropdown.Toggle>
                                                                                            <Dropdown.Menu>
                                                                                                <Dropdown.Item href="#/action-1" data-id={id} data-key="gender" data-value="Male" onClick={(e) => {
                                                                                                    setGender('Male')
                                                                                                    updateGender(id, "Male")
                                                                                                }}>Male</Dropdown.Item>
                                                                                                <Dropdown.Item href="#/action-2" data-id={id} data-key="gender" data-value="Female" onClick={(e) => {
                                                                                                    setGender('Female')
                                                                                                    updateGender(id, "Female")
                                                                                                }}>Female</Dropdown.Item>
                                                                                                <Dropdown.Item href="#/action-3" data-id={id} data-key="gender" data-value="Others" onClick={(e) => {
                                                                                                    setGender('Others')
                                                                                                    updateGender(id, "Others")
                                                                                                }}>Others</Dropdown.Item>
                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown>
                                                                                    </div>
                                                                                </div> */}
                                                                                <div>
                                                                                    <div className="dk-cansuMMFromGrp">
                                                                                        <label>Created</label>
                                                                                        <div className="selDrop14 dkg-candprofile-readable">
                                                                                            <DatePicker date={createdAt} candidateId={id} updateDates={updateDates} column="createdAt" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="dk-cansuMMFromGrp">
                                                                                        <label>Updated</label>
                                                                                        <div className="selDrop14">
                                                                                            <DatePicker date={updatedAt} candidateId={id} updateDates={updateDates} column="updatedAt" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-8 col-sm-12 pl-4">
                                                                                <div className="">
                                                                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                        <div className="d-flex">
                                                                                            <label>Name</label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className={
                                                                                                name == ""
                                                                                                    ? "form-control errorBorder"
                                                                                                    : "form-control"
                                                                                                }
                                                                                                data-id={id}
                                                                                                data-key="name"
                                                                                                data-value={name}
                                                                                                value={name}
                                                                                                onChange={(e) => setName(e.target.value)}
                                                                                                onBlur={updateProfile}
                                                                                            />
                                                                                            {/* <span style={{minWidth: "28px" , maxWidth: "28px"}}>
                                                                                                <i className="fas fa-user"></i>
                                                                                            </span> */}
                                                                                            <div className="d-flex pl-4">
                                                                                                <input
                                                                                                type="text"
                                                                                                style={{ minWidth: "115px", maxWidth: "115px" }}
                                                                                                className={
                                                                                                    name == ""
                                                                                                    ? "form-control errorBorder"
                                                                                                    : "form-control"
                                                                                                }
                                                                                                // data-id={id}
                                                                                                // data-key="name"
                                                                                                // data-value={name}
                                                                                                // value={name}
                                                                                                // onChange={(e) => setName(e.target.value)}
                                                                                                // onBlur={updateProfile}
                                                                                                defaultValue=""
                                                                                                />
                                                                                                {/* <span style={{minWidth: "28px" , maxWidth: "28px"}}>
                                                                                                <i className="fas fa-user"></i>
                                                                                                </span> */}
                                                                                            </div>
                                                                                            </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                        <label>Email 1</label>
                                                                                        <input type="text" className={(email1 == '' ? "form-control errorBorder" : 'form-control')} data-id={id} data-key="email1" data-value={email1} value={email1} onChange={(e) => setEmail1(e.target.value)} onBlur={updateProfile} />
                                                                                        <span onClick={() => setShowCompose(true)}><i className="fas fa-envelope"></i></span>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                        <label>Email 2</label>
                                                                                        <input type="text" className="form-control" data-id={id} data-key="email2" data-value={email2} value={email2} onChange={(e) => setEmail2(e.target.value)} onBlur={updateProfile} />
                                                                                        <span onClick={() => setShowCompose(true)} style={{ padding: "0 0.3rem", color: "#495057" }}><i className="fas fa-envelope"></i></span>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                        <label>Phone 1</label>
                                                                                        <input type="text" className="form-control" data-id={id} data-key="phone1" data-value={phone1} value={phone1} onChange={(e) => setPhone1(e.target.value)} onBlur={updateProfile} />
                                                                                        <span style={{ padding: "0 0.3rem", color: "#495057" }}><i className="fas fa-phone"></i></span>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                        <label>Phone 2</label>
                                                                                        <input type="text" className="form-control" data-id={id} data-key="phone2" data-value={phone2} value={phone2} onChange={(e) => setPhone2(e.target.value)} onBlur={updateProfile} />
                                                                                        <span style={{ padding: "0 0.3rem", color: "#495057" }}><i className="fas fa-phone"></i></span>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                        <label>Skype</label>
                                                                                        <input type="text" className="form-control" data-id={id} data-key="skypeId" data-value={skypeId} value={skypeId} onChange={(e) => setSkypeId(e.target.value)} onBlur={updateProfile} />
                                                                                        <span style={{ padding: "0 0.3rem", color: "#495057" }}>
                                                                                            <img src={SkypeImg} />
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 col-sm-12">
                                                                <div className="dk-TopCntreCon">
                                                                    <div className="dk-TopCntreBox">
                                                                        <span className="mr-2"> <SelectRecruiterAdminDropDwn name={recAdminName} img={recAdminImg} canId={id} updateProfile={updateProfile} /></span>
                                                                        <span className='mr-2'> <SelectRecruiterDropDwn name={recName} img={recImg} canId={id} updateProfile={updateProfile} /></span>
                                                                        <SelectAccountManagerDropDwn className="mr-2" name={accountManagerName} img={accountManagerImg} canId={id} updateProfile={updateProfile} />
                                                                    </div>
                                                                    <CandidateDocumentModal name="CV" canId={candidateId} />

                                                                    <CommentModal refTable="candidates" refId={candidateId} count={commentCount} title="CASE COMMENTS" />

                                                                    <div className="dk-TopCntreBox cleartextBox pd-0">
                                                                        <div className="dk-socialFlex">
                                                                            <Link to="#" onClick={showSocialModal}><img src={EmailIcon} alt="" /></Link>
                                                                            <Link to="#" onClick={showSocialModal}><img src={PhoneIcon} alt="" /></Link>
                                                                            <Link to="#" onClick={showSocialModal}><img src={SmsIcon} alt="" /></Link>
                                                                            <Link to="#" onClick={showSocialModal}><img src={WhatsIcon} alt="" /></Link>
                                                                            <Link to="#" onClick={showSocialModal}><img src={SkypeIcon} alt="" /></Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-5 col-sm-12 pl-0">
                                                                <div className="dk-TopRightCon">
                                                                    <form>
                                                                        <div className="row">
                                                                            <div className="col-md-6 pr-0">
                                                                                <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                    <label className="">Job Title</label>
                                                                                    <input type="text" className="form-control" defaultValue={jobTitle} readOnly />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                    <label className="">Job ID</label>
                                                                                    <input type="text" placeholder="Fill Job ID" onClick={handleShow} className="form-control input-form-control-job" value={oldJobId} readOnly="true" style={{ cursor: "pointer", backgroundColor: "#e9ecef ! important" }} />
                                                                                </div>
                                                                                <Modal show={isJobIDShow} onHide={handleClose} className='dkg-canprof-modal-quz21' centered >
                                                                                    <Modal.Body className='dkg-canprof-modal-body-quz21'>
                                                                                        <div className="d-flex">
                                                                                            <div className="form-group">
                                                                                                <label for="">Current Job ID</label>
                                                                                                <label for="">
                                                                                                    <input type="text" className="form-control newValue" value={oldJobId} readOnly="true" />
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="form-group">
                                                                                                <label for="">New Job ID</label>
                                                                                                <label for="">
                                                                                                    <input type="text" className="form-control" data-id={id} data-key="jobId" data-value={jobId}
                                                                                                        value={popupJobId}
                                                                                                        onChange={
                                                                                                            (e) => {
                                                                                                                setJobId(e.target.value)
                                                                                                                setPopupJobId(e.target.value)
                                                                                                            }
                                                                                                        }
                                                                                                    />
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex justify-content-center">
                                                                                            <button className="dk-saveBtn" onClick={(e) => updateJobCandidate(id)}>Save</button>
                                                                                        </div>
                                                                                    </Modal.Body>
                                                                                </Modal>
                                                                            </div>
                                                                            <div className="col-md-6 pr-0">
                                                                                <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                    <label className="">Company</label>
                                                                                    <input type="text" className="form-control" defaultValue={jobCompany} readOnly />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                    <label className="">Company ID-1</label>
                                                                                    <input type="text" className="form-control" defaultValue={jobCompanyId} readOnly />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 pr-0 mt-4">
                                                                                <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                    <label className="">Job Location</label>
                                                                                    <input type="text" className="form-control" defaultValue={jobCountry} readOnly />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 mt-4">
                                                                                <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                    <label className="">Locality</label>
                                                                                    <input type="text" className="form-control" defaultValue={jobLocality} readOnly />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 pr-0 mb-0">
                                                                                <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                    <label className="">No. of jobs</label>
                                                                                    <input type="text" className="form-control" defaultValue={jobNo} readOnly />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 mb-0">
                                                                                <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                    <label className="">Job Fee</label>
                                                                                    <input type="text" className="form-control" defaultValue={jobFee} readOnly />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 pr-0">
                                                                                <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                    <label className="">Role Type</label>
                                                                                    <input type="text" className="form-control" defaultValue={jobRole} readOnly />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                    <label className="">Project</label>
                                                                                    <input type="text" className="form-control" defaultValue={jobProject} readOnly />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 pr-0">
                                                                                <div className="form-group dk-Candinate-fromGroup d-flex">
                                                                                    <label className="">Language</label>
                                                                                    <input type="text" className="form-control" defaultValue={jobLanguage} readOnly />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-group dk-Candinate-fromGroup d-flex dk-dateIcon">
                                                                                    <label className="">Start Date</label>
                                                                                    <input type="text" className="form-control" defaultValue={jobStart} readOnly />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dk-candidateTabPane">
                                                        <CandTabContentSummary data={profileDetails} updateProfile={updateProfile} updateDates={updateDates} updateEditor={updateEditor} tabId={tabId} />
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="socialm">
                                                    <div className="dk-candidateTabPane">
                                                        <CandTabContentWorkflow />
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="workflow">
                                                    <div className="dk-candidateTabPane">
                                                        <CandTabContentOverview />
                                                        <CandExperience />
                                                        <CandQualification />
                                                        <CandOtherInfo />
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="documents">
                                                    <div className="dk-candidateTabPane">
                                                        <Documents candidateId={candidateId} tabId={tabId} />
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="text_seaach">
                                                    <div className="dk-candidateTabPane">
                                                        <TextSearch />
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="matching_jobs">
                                                    <div className="dk-candidateTabPane">
                                                        <MatchingJobs />
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="contacts">
                                                    <div className="dk-candidateTabPane">
                                                        <CandCommunication />
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="case_status">
                                                    <div className="dk-candidateTabPane">
                                                        <CaseStatus data={profileDetails} updateProfile={updateProfile} updateDates={updateDates} tabId={tabId} />
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="tech_skills">
                                                    <div className="dk-candidateTabPane">
                                                        <TechSkills />
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="profile_skills">
                                                    <div className="dk-candidateTabPane">
                                                        <ProfileSkills />
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </div>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default CandidateProfile;
