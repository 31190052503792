import React, { useLayoutEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Header from "../elements/header";
import Navbar from '../elements/header/navbar/mainMenu/datatable/DatatableMenu';

import { resourceCategory, resourceCoverage, resourcePaidUnpaid, resourceSourceType, resourceStatus, resourceLoginCategory, resourceLoginSourceType } from "../../slice/layout/layoutSlice";

import { caseStatus, caseProgress, caseStage, candidateCategory, candidateNationality, candidateVisaStatus, candidateNative, candidateFluent, candidateIntermediate, candidateGender, candidateCVSource, candidateIndustry, candidateCareerLevel, candidateCityLevel, candidateCountryLevel, candidateLatestJob, candidateEduation, candidateCareerPriority, jobsCategory, jobsCompany, jobsProject, jobsRoleType, jobsLocality, jobsNoOfJobs, jobsCountry, jobsCity, jobsLanguages, jobsJobProfile, jobsWorkPermit, jobsWorkplace, jobsWorkdays, jobsShiftsWork, jobsContracttype, jobsSalarytype, jobsAvailability, jobsOutsideofeu, jobsFlightticket, jobsTaxifromairport, jobsAccommodationt, jobsRelocationbudget, jobsExperiencet, jobsIndustryId, jobsLevel, getFileTypeList, profileTechSkillsList, profileJobSkillsList, jobsCount } from "../../slice/layout/layoutSlice";


const DatabaseTableLayout = ({ children }) => {
    const dispatch = useDispatch();

    const [search, setSearch] = useState([]);
    const [filter, setFilter] = useState({});

    const getRequestParams = (filter) => {
        let params = {};
        params["filter"] = filter;
        params["search"] = search;
        return params;
    }

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    useLayoutEffect(() => {

        const fetchResourceCategoryList = async () => {
            try {
                await dispatch(resourceCategory({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchRresourceCoverageList = async () => {
            try {
                await dispatch(resourceCoverage({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchResourcePaidUnpaidList = async () => {
            try {
                await dispatch(resourcePaidUnpaid({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchResourceSourceTypeList = async () => {
            try {
                await dispatch(resourceSourceType({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchResourceStatusList = async () => {
            try {
                await dispatch(resourceStatus({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchResourceLoginCategory = async () => {
            try {
                await dispatch(resourceLoginCategory({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchResourceLoginSourceType = async () => {
            try {
                await dispatch(resourceLoginSourceType({}))
            } catch (err) {
                console.log(err)
            }
        }
       
     
        const fetchCaseStatusList = async () => {
            try {
                await dispatch(caseStatus({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchFileTypeList = async () => {
            try {
                await dispatch(getFileTypeList({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchCaseProgressList = async () => {
            try {
                await dispatch(caseProgress({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchCaseStageList = async () => {
            try {
                await dispatch(caseStage({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchCandidateCategoryList = async () => {
            try {
                await dispatch(candidateCategory({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchCandidateNationalityList = async () => {
            try {
                await dispatch(candidateNationality({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchCandidateVisaStatusList = async () => {
            try {
                await dispatch(candidateVisaStatus({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchCandidateNativeList = async () => {
            try {
                await dispatch(candidateNative({}))
            } catch (err) {
                console.log(err)
            }
        }
       
        const fetchCandidateCVSourceList = async () => {
            try {
                await dispatch(candidateCVSource({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchCandidateGenderList = async () => {
            try {
                await dispatch(candidateGender({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchCandidateIndustryList = async () => {
            try {
                await dispatch(candidateIndustry({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchCandidateCareerLevelList = async () => {
            try {
                await dispatch(candidateCareerLevel({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchCandidateCityLevelList = async () => {
            try {
                await dispatch(candidateCityLevel({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchCandidateCountryLevelList = async () => {
            try {
                await dispatch(candidateCountryLevel({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchCandidateLatestJobList = async () => {
            try {
                await dispatch(candidateLatestJob({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchCandidateEduationList = async () => {
            try {
                await dispatch(candidateEduation({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchCandidateCareerPriorityList = async () => {
            try {
                await dispatch(candidateCareerPriority({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsCategoryList = async () => {
            try {
                await dispatch(jobsCategory({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsCompanyList = async () => {
            try {
                await dispatch(jobsCompany({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsProjectList = async () => {
            try {
                await dispatch(jobsProject({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsRoleTypeList = async () => {
            try {
                await dispatch(jobsRoleType({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsLocalityList = async () => {
            try {
                await dispatch(jobsLocality({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsNoOfJobsList = async () => {
            try {
                await dispatch(jobsNoOfJobs({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsCountryList = async () => {
            try {
                await dispatch(jobsCountry({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsCityList = async () => {
            try {
                await dispatch(jobsCity({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsLanguagesList = async () => {
            try {
                await dispatch(jobsLanguages({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsJobProfileList = async () => {
            try {
                await dispatch(jobsJobProfile({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsWorkPermitList = async () => {
            try {
                await dispatch(jobsWorkPermit({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsWorkplaceList = async () => {
            try {
                await dispatch(jobsWorkplace({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsWorkdaysList = async () => {
            try {
                await dispatch(jobsWorkdays({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsShiftsWorkList = async () => {
            try {
                await dispatch(jobsShiftsWork({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsContracttypeList = async () => {
            try {
                await dispatch(jobsContracttype({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsSalarytypeList = async () => {
            try {
                await dispatch(jobsSalarytype({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsAvailabilityList = async () => {
            try {
                await dispatch(jobsAvailability({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsOutsideofeuList = async () => {
            try {
                await dispatch(jobsOutsideofeu({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsFlightticketList = async () => {
            try {
                await dispatch(jobsFlightticket({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsTaxifromairportList = async () => {
            try {
                await dispatch(jobsTaxifromairport({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsAccommodationtList = async () => {
            try {
                await dispatch(jobsAccommodationt({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsRelocationbudgetList = async () => {
            try {
                await dispatch(jobsRelocationbudget({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsExperiencetList = async () => {
            try {
                await dispatch(jobsExperiencet({}))
            } catch (err) {
                console.log(err)
            }
        }
        const fetchjobsIndustryIdList = async () => {
            try {
                await dispatch(jobsIndustryId({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchjobsLevelIdList = async () => {
            try {
                await dispatch(jobsLevel({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchCandidateProfileTechSkillsList = async () => {
            try {
                await dispatch(profileTechSkillsList({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchCandidateProfileJobSkillsList = async () => {
            try {
                await dispatch(profileJobSkillsList({}))
            } catch (err) {
                console.log(err)
            }
        }

        const fetchJobsCountList = async () => {
            try {
                await dispatch(jobsCount({}))
            } catch (err) {
                console.log(err)
            }
        }
            
        
            
        fetchCaseStatusList()
        fetchFileTypeList()
        fetchCaseProgressList()
        fetchCaseStageList()
        fetchCandidateCategoryList()
        fetchCandidateNationalityList()
        fetchCandidateVisaStatusList()
        fetchCandidateNativeList()
        fetchCandidateCVSourceList()
        fetchCandidateGenderList()
        fetchCandidateIndustryList()
        fetchCandidateCareerLevelList()
        fetchCandidateCityLevelList()
        fetchCandidateCountryLevelList()
        fetchCandidateLatestJobList()
        fetchCandidateEduationList()
        fetchCandidateCareerPriorityList()
        fetchjobsCategoryList()
        fetchjobsCompanyList()
        fetchjobsProjectList()
        fetchjobsRoleTypeList()
        fetchjobsLocalityList()
        fetchjobsNoOfJobsList()
        fetchjobsCountryList()
        fetchjobsCityList()
        fetchjobsLanguagesList()
        fetchjobsJobProfileList()
        fetchjobsWorkPermitList()

        fetchjobsWorkplaceList()
        fetchjobsWorkdaysList()
        fetchjobsShiftsWorkList()
        fetchjobsContracttypeList()
        fetchjobsSalarytypeList()
        fetchjobsAvailabilityList()
        fetchjobsOutsideofeuList()
        fetchjobsFlightticketList()
        fetchjobsTaxifromairportList()
        fetchjobsAccommodationtList()
        fetchjobsRelocationbudgetList()
        fetchjobsExperiencetList()
        fetchjobsIndustryIdList()
        fetchjobsLevelIdList()

        fetchCandidateProfileTechSkillsList()
        fetchCandidateProfileJobSkillsList()
        fetchJobsCountList()        

        fetchResourceCategoryList()
        fetchRresourceCoverageList()
        fetchResourcePaidUnpaidList()
        fetchResourceSourceTypeList()
        fetchResourceStatusList()
        fetchResourceLoginCategory()

    }, [])


    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="Recruitment -" moduleLink="/dashboard/recruitment" linkcolor="#3c4c62" mainheaderTitle="SEARCH DATABASE" textcolor="#26596d" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default DatabaseTableLayout;