import React from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import "./camedit.css";
import DummySelectPiker from '../../../../ui/SelectPicker/DummySelectPiker';
import Submit from '../../../../ui/submitButton';


const Edit = ({ onHide }) => {
    const options = [
        {
            key: 'year_2021', value: 'year_2017', text: 'Active',

        },
        {
            key: 'year_2020', value: 'year_2020', text: 'Inactive',
        }
    ]
    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-compaginsEditPop"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Campaign Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-compaginsEdit">
                    <form>
                        <div className="row">
                            <div className="col-md-3 mr-auto">
                                <div className="form-group">
                                    <label htmlFor="">Recruiter</label>
                                    <label htmlFor="" className="mb-0">
                                        <div className="useFilterCon">
                                            <Link to="#"><i className="fas fa-user"></i></Link>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-9">
                                <div className="form-group">
                                    <label>Job Title</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting" />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Started</label>
                                    <input type="date" className="form-control broderContrl" defaultValue="26" />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Expired</label>
                                    <input type="date" className="form-control broderContrl" defaultValue="10-09-2021" />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Job Board</label>
                                    <div className="dk-camEditFilter">
                                        <DummySelectPiker options={options} placeholder="Status" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Leads</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="30" />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Sendouts</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="30" />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Pacements</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="30" />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Category</label>
                                    <div className="dk-camEditFilter">
                                        <DummySelectPiker options={options} placeholder="Job Board" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Company</label>
                                    <div className="dk-camEditFilter">
                                        <DummySelectPiker options={options} placeholder="TP Greece" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Project</label>
                                    <div className="dk-camEditFilter">
                                        <DummySelectPiker options={options} placeholder="Philip Morris" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Role Type</label>
                                    <div className="dk-camEditFilter">
                                        <DummySelectPiker options={options} placeholder="CS" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Language</label>
                                    <div className="dk-camEditFilter">
                                        <DummySelectPiker options={options} placeholder="German" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Location</label>
                                    <div className="dk-camEditFilter">
                                        <DummySelectPiker options={options} placeholder="Germany" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="dk-adstrackbtnCont">
                                    <Submit txt="UPDATE" className="dk-adstrackaddbtn" />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default Edit;
