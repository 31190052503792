import React from 'react'
import { Select } from 'semantic-ui-react'
import "./selectinstance.css";



const DummySelectPiker = (props) => {
    return (
        <>
            <Select placeholder={props.placeholder} options={props.options} className={`dk-overdues-task-slect`} />
        </>
    )
}

export default DummySelectPiker;
