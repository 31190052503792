import React from 'react';
import FilipProjectProfileLeftPanel from "../leftpanel/filip/FilipProjectProfileLeftPanel";
import '../moduleprojects.scss';

const FilipProjectProfile = () => {
    return (
        <>
            <div className="dk-moduleProjects-main">
                <div className="dk-moduleTraining-cols">
                    <FilipProjectProfileLeftPanel />
                    <div className="dk-moduleTraining-rightPanel">
                        <div className="title">Summary</div>
                        <div className="dk-moduleTraining-rightCols">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FilipProjectProfile;
