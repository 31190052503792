import React from 'react';
import { Link } from "react-router-dom";

const LiveJobsLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="dk-moduleTraining-leftPanel">
                <ul>
                    <li><Link to="/training/live-jobs/overview" className={`${pathname.match('/training/live-jobs/overview') ? 'active' : ''}`}>Overview</Link></li>
                    <li><Link to="/training/live-jobs/sample-1" className={`${pathname.match('/training/live-jobs/sample-1') ? 'active' : ''}`}>Sample 1</Link></li>
                    <li><Link to="/training/live-jobs/sample-2" className={`${pathname.match('/training/live-jobs/sample-2') ? 'active' : ''}`}>Sample 2</Link></li>
                    <li><Link to="/training/live-jobs/sample-3" className={`${pathname.match('/training/live-jobs/sample-3') ? 'active' : ''}`}>Sample 3</Link></li>
                </ul>
            </div>
        </>
    )
}

export default LiveJobsLeftPanel;
