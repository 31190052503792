import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import HelpImg from "../../../../assets/images/help_black.png";
import HelpActiveImg from "../../../../assets/images/info_active.png";
import SearchImg from "../../../../assets/images/search_black.png";
import SearchActiveImg from "../../../../assets/images/search_active.png";
import SuggestionImg from "../../../../assets/images/link_black.png";
import SuggestionActiveImg from "../../../../assets/images/link_active.png";
import ContactActiveImg from "../../../../assets/images/call_active.png";
import ContactImg from "../../../../assets/images/call_black.png";
import '../trainingdetail.scss';

const MoreDetailModal = ({ handleClose }) => {
    const [showResults, setShowResults] = React.useState(false)
    const [activeText, setActiveText] = React.useState('')
    const clickOnShow = (txt) => {
        setShowResults(true)
        setShowGlossaryResults(false)
        setShowSuggestionResults(false)
        setShowContactResults(false)
        setActiveText(txt)
    }
    const clickOnHide = () => setShowResults(false)


    const [showGlossaryResults, setShowGlossaryResults] = React.useState(false)
    const clickOnShowGlossary = (txt) => {
        setShowResults(false)
        setShowSuggestionResults(false)
        setShowContactResults(false)
        setShowGlossaryResults(true)
        setActiveText(txt)
    }
    const clickOnHideGlossary = () => setShowGlossaryResults(false)


    const [showSuggestionResults, setShowSuggestionResults] = React.useState(false)
    const clickOnShowSuggestion = (txt) => {
        setShowResults(false)
        setShowGlossaryResults(false)
        setShowContactResults(false)
        setShowSuggestionResults(true)
        setActiveText(txt)
    }
    const clickOnHideSuggestion = () => setShowSuggestionResults(false)


    const [showContactResults, setShowContactResults] = React.useState(false)
    const clickOnShowContact = (txt) => {
        setShowResults(false)
        setShowGlossaryResults(false)
        setShowSuggestionResults(false)
        setShowContactResults(true)
        setActiveText(txt)
    }
    const clickOnHideContact = () => setShowContactResults(false)

    return (
        <>
            <Modal className="dk-moreFixedModal" show={true} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>MORE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-moreInfoFixed">
                        <ul>
                            <Link to="#" className={(activeText == 'help' ? 'active' : null)} onClick={() => clickOnShow('help')}>
                                <img src={HelpImg} className="img-fluid normalView" alt="" />
                                <img src={HelpActiveImg} className="img-fluid activeView" alt="" />
                                Help
                            </Link>

                            <Link to="#" className={(activeText == 'glossary' ? 'active' : null)} onClick={() => clickOnShowGlossary('glossary')}>
                                <img src={SearchImg} className="img-fluid normalView" alt="" />
                                <img src={SearchActiveImg} className="img-fluid activeView" alt="" />
                                GLOSSARY
                            </Link>

                            <Link to="#" className={(activeText == 'suggestion' ? 'active' : null)} onClick={() => clickOnShowSuggestion('suggestion')}>
                                <img src={SuggestionImg} className="img-fluid normalView" alt="" />
                                <img src={SuggestionActiveImg} className="img-fluid activeView" alt="" />
                                SUGGESTION
                            </Link>

                            <Link to="#" className={(activeText == 'contact' ? 'active' : null)} onClick={() => clickOnShowContact('contact')}>
                                <img src={ContactImg} className="img-fluid normalView" alt="" />
                                <img src={ContactActiveImg} className="img-fluid activeView" alt="" />
                                CONTACT US
                            </Link>
                        </ul>
                    </div>
                </Modal.Body>

                {/* fixed component */}
                {showResults ?
                    <div className="dk-detailFixedpanel">
                        <div className="dk-detailHead">
                            <h3 className="title">Help & Support</h3>
                            <button onClick={clickOnHide}><i className="fas fa-times"></i></button>
                        </div>
                        <div className="dk-detailContent">
                            <h4>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui modi tempora.</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia aperiam voluptatum sunt, accusantium id culpa odio illo vero, ipsa dolores, ratione fugiat dignissimos. Exercitationem optio culpa, aut blanditiis officia consequatur. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam animi officia sit consectetur, autem laborum numquam aspernatur nam ipsa quaerat repudiandae! Iste, ratione fuga. Corrupti dolore fuga dolorem assumenda expedita!</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo praesentium tenetur quis, quod dolores voluptates officiis at quia voluptate, magnam, voluptatem impedit mollitia blanditiis repellat suscipit pariatur a rem provident?</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia aperiam voluptatum sunt, accusantium id culpa odio illo vero, ipsa dolores, ratione fugiat dignissimos. Exercitationem optio culpa, aut blanditiis officia consequatur. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam animi officia sit consectetur, autem laborum numquam aspernatur nam ipsa quaerat repudiandae! Iste, ratione fuga. Corrupti dolore fuga dolorem assumenda expedita!</p>

                            <h4 className="mt-4">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui modi tempora.</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia aperiam voluptatum sunt, accusantium id culpa odio illo vero, ipsa dolores, ratione fugiat dignissimos. Exercitationem optio culpa, aut blanditiis officia consequatur. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam animi officia sit consectetur, autem laborum numquam aspernatur nam ipsa quaerat repudiandae! Iste, ratione fuga. Corrupti dolore fuga dolorem assumenda expedita!</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas dolorum earum eius fugiat corporis, eligendi quam fuga? Id distinctio odio dignissimos ducimus quia beatae consequatur aut? Eaque at quaerat reprehenderit!</p>
                        </div>
                    </div> : null}


                {showGlossaryResults ?
                    <div className="dk-detailFixedpanel">
                        <div className="dk-detailHead">
                            <h3 className="title">Glossary</h3>
                            <button onClick={clickOnHideGlossary}><i className="fas fa-times"></i></button>
                        </div>
                        <div className="dk-detailContent dk-glossaryMain">
                            <div className="dk-glossarySearch">
                                <input type="text" placeholder="Search..." name="" id="" className="form-control" />
                            </div>

                            <div className="dk-glossaryCols">
                                <div className="dk-glossaryTags">
                                    <div className="d-flex">
                                        <Link to="#">#</Link>
                                        <Link to="#">A</Link>
                                        <Link to="#" className="active">B</Link>
                                        <Link to="#">C</Link>
                                        <Link to="#">D</Link>
                                        <Link to="#">E</Link>
                                        <Link to="#">F</Link>
                                        <Link to="#">G</Link>
                                        <Link to="#">H</Link>
                                        <Link to="#">I</Link>
                                        <Link to="#">J</Link>
                                        <Link to="#">K</Link>
                                        <Link to="#">L</Link>
                                        <Link to="#">M</Link>
                                        <Link to="#">N</Link>
                                        <Link to="#">O</Link>
                                        <Link to="#">P</Link>
                                        <Link to="#">Q</Link>
                                        <Link to="#">R</Link>
                                        <Link to="#">S</Link>
                                        <Link to="#">T</Link>
                                        <Link to="#">U</Link>
                                        <Link to="#">V</Link>
                                        <Link to="#">W</Link>
                                        <Link to="#">X</Link>
                                        <Link to="#">Y</Link>
                                        <Link to="#">Z</Link>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="dk-glossaryLeftPanel">
                                        <li><Link to="#" className="active">Bribery</Link></li>
                                        <li><Link to="#">Bribery Act</Link></li>
                                    </div>
                                    <div className="dk-glossaryLeftPanelDetail">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt consequuntur illo voluptates voluptate nisi ab ex minus repellat quisquam! Blanditiis unde obcaecati nesciunt reprehenderit consequuntur quaerat veritatis minus at autem!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}

                {showSuggestionResults ?
                    <div className="dk-detailFixedpanel">
                        <div className="dk-detailHead">
                            <h3 className="title">Suggestion</h3>
                            <button onClick={clickOnHideSuggestion}><i className="fas fa-times"></i></button>
                        </div>
                        <div className="dk-detailContent dk-suggestionMain">
                            <div className="sbTitle">Training Suggestion</div>
                            <form action="">
                                <div className="form-group">
                                    <label htmlFor="">Subject</label>
                                    <label htmlFor="">
                                        <input type="text" name="" id="" className="form-control" />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Write Details Here</label>
                                    <label htmlFor="">
                                        <textarea name="" id="" className="form-control"></textarea>
                                    </label>
                                </div>
                                <div className="text-center">
                                    <button className="dk-sendBtn">Send</button>
                                </div>
                            </form>
                        </div>
                    </div> : null}

                {showContactResults ?
                    <div className="dk-detailFixedpanel">
                        <div className="dk-detailHead">
                            <h3 className="title">Contact Us</h3>
                            <button onClick={clickOnHideContact}><i className="fas fa-times"></i></button>
                        </div>
                        <div className="dk-detailContent">

                        </div>
                    </div> : null}
                {/* end */}
            </Modal>
        </>
    )
}

export default MoreDetailModal;

