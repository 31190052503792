import React from "react";
import '../mainMenu/style.scss';
//import DashboardSubmenu from "./menu/DashboardSubmenu";
import SpecSubmenu from './menu/mainsubmenu/SpecSubmenu'
// import TaskSubmenu from "./menu/CalendarSubmenu";
import NotificationSubmenu from './menu/NotificationSubmenu';
import WorkplanSubmenu from './menu/WorkplanSubmenu'
import CompanySubmenu from "./menu/CompanySubmenu";
import ClientInfoSubmenu from './menu/ClientInfoSubmenu'
import ProjectionSubmenu from "./menu/ProjectionSubmenu";
import DKGCloudSubmenu from './menu/DKGCloudSubmenu';
import DatabaseSubmenu from "./menu/DatabaseSubmenu";
import JobsSubmenu from "./menu/JobsSubmenu";
// import KPISubmenu from "./menu/KpiSubmenu";
import CandidateSubmenu from "./menu/CandidateSubmenu";
// import PipelinesSubmenu from "./menu/PipelinesSubmenu";
import AdsSubmenu from "./menu/AdsSubmenu";
import SettingsSubmenu from './menu/SettingsSubmenu';
import DkGlobalSubmenu from './menu/DkGlobalSubmenu';
import RecTrainingSubmenu from './menu/RecTrainingSubmenu';
import RecMypipelineSubmenu from './menu/MyPipelineSubmenu';

const Submenu = ({ path }) => {
    const renderSubmenu = () => {
        switch (path.split("/")[2]) {
            // case 'dashboard':
            //     return <DashboardSubmenu />
            //     break;

            case 'job-spec':
                return <SpecSubmenu />
                break;

            case 'candidate':
                return <CandidateSubmenu />
                break;
            // case 'pipelines':
            //     return <PipelinesSubmenu />

            // case 'task':
            //     return <TaskSubmenu />
            //     break;

            case 'workplan':
                return <WorkplanSubmenu />
                break;

            case 'notification':
                return <NotificationSubmenu />
                break;

            // case 'clients-list':
            //     return <CompanySubmenu />
            //     break;

            // case 'clientsinfo':
            //     return <ClientInfoSubmenu />
            //     break;

            case 'job-specs':
                return <JobsSubmenu />
                break;

            case 'projections':
                return <ProjectionSubmenu />
                break;

            case 'my-pipeline':
                return <RecMypipelineSubmenu />
                break;

            case 'dkg-cloud':
                return <DKGCloudSubmenu />
                break;

            case 'database':
                return <DatabaseSubmenu />
                break;

            // case 'kpis':
            //     return <KPISubmenu />
            //     break;

            case 'ads':
                return <AdsSubmenu />
                break;

            case 'dkglobal':
                return <DkGlobalSubmenu />
                break;

            case 'settings':
                return <SettingsSubmenu />
                break;

            case 'rectraining':
                return <RecTrainingSubmenu />
                break;

            default:
                break
        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default Submenu;