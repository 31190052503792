import React from 'react';

const ReportStage = ({ value }) => {

    let bgColor = "";
    switch (value) {

        case '21':
            bgColor = '#d9714e'
            break;
        case '22':
            bgColor = '#d9714e'
            break;

        case 'Moved Forward':
            bgColor = '#2d9b99'
            break;

        case '24':
            bgColor = '#2d9b99'
            break;

        default:
            bgColor = '#2d9b99'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor, color: '#fff', minWidth: '80px' }}>{value}</div>
        </>
    )
}

export default ReportStage;