import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import "./edit.scss"
// import SelectItem from "../../../../../ui/selectpicker/DummySelectPiker";


const EditModal = ({ onHide }) => {
    const status = [
        { key: 'status_1', value: 'status_1', text: 'Active' },
        { key: 'status_2', value: 'status_2', text: 'Inactive' },
    ]
    const category = [
        { key: 'status_1', value: 'status_1', text: 'category 1' },
        { key: 'status_2', value: 'status_2', text: 'category 2' },
        { key: 'status_3', value: 'status_3', text: 'category 3' },
        { key: 'status_4', value: 'status_4', text: 'category 4' },
        { key: 'status_5', value: 'status_5', text: 'category 5' }
    ]
    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-editResModalDialog modal-40w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton className="dk-resourcePopupHedaer">
                    <Modal.Title id="example-custom-modal-styling-title">
                        Edit
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Status</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        {/* <SelectItem options={status} placeholder="Active" /> */}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Name of Client</label>
                                    <input type="text" className="form-control broderContrl" name="name" placeholder="Lydia Stavropoulou" />
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Category</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        {/* <SelectItem options={category} placeholder="Category 1" /> */}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Contract</label>
                                    <input type="text" className="form-control broderContrl" name="name" placeholder="Contract 1" />
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Guarantee</label>
                                    <input type="text" className="form-control broderContrl" name="name" placeholder="Guarantee 1" />
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Duplication</label>
                                    <input type="text" className="form-control broderContrl" name="name" placeholder="Duplication 1" />
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Sendouts</label>
                                    <input type="text" className="form-control broderContrl" name="name" placeholder="Sendouts 1" />
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Invoice</label>
                                    <input type="text" className="form-control broderContrl" name="name" placeholder="Invoice 1" />
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <div className="dk-addresbtnCont">
                                    <button>UPDATE</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default EditModal;
