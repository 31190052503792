import React from 'react';
import './casestatus.scss';

const CaseStatus = ({ value }) => {
    let bgColor = "";
    let pipelineName = "";
    let textColor = "";
    switch (value) {
        case 'New CV':
            bgColor = '#5c737d'
            pipelineName="New CV"
            textColor="#fff"
            break;

        case 'Selected':
            bgColor = '#715f8f'
            pipelineName="Selected"
            textColor="#fff"
            break;

        case 'Database':
            bgColor = '#42908c'
            pipelineName="Database"
            textColor="#fff"
            break;
        case 'Archive':
            bgColor = '#da3d5e'
            pipelineName="Archive"
            textColor="#fff"
            break;
        case 'In Progress':
            bgColor = '#57496d'
            pipelineName="In Progress"
            textColor="#fff"
            break;

        case 'Completed':
            bgColor = '#57496d'
            pipelineName="Completed"
            textColor="#fff"
            break;

        case 'Sendout':
            bgColor = '#316a67'
            pipelineName="Sendout"
            textColor="#fff"
            break;

        case 'Job Offer':
            bgColor = '#629bd3'
            pipelineName="Job Offer"
            textColor="#fff"
            break;

        case 'Case Query':
            bgColor = '#26596d'
            pipelineName="Case Query"
            textColor="#fff"
            break;

        case 'Closed':
            bgColor = '#da3d5e'
            pipelineName="Closed"
            textColor="#fff"
            break;

        default:
            bgColor = '#000000'
            textColor="#fff"
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor,color:textColor }}>{pipelineName}</div>
        </>
    )
}

export default CaseStatus;