import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const loginUser = createAsyncThunk(
    'auth/login',
    async ({ role, email, password }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/login',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                    },
                    body: JSON.stringify({
                        role,
                        email,
                        password,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                localStorage.setItem(
                    "dkmasterBoxClient",
                    JSON.stringify({
                        token: data.result.token,
                        refreshToken: data.result.refreshToken
                    })
                );
                localStorage.setItem(
                    "summaryRecFilter",
                    JSON.stringify({
                        filterRecId: '',
                        RecValue: '',
                        RecName: ''
                    })
                );

                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const recruterlogin = createAsyncThunk(
    'auth/recruter/login',
    async ({ role, email, password }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/recruter-login',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                    },
                    body: JSON.stringify({
                        role,
                        email,
                        password,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                localStorage.setItem(
                    "dkmasterBoxClient",
                    JSON.stringify({
                        token: data.result.token,
                        refreshToken: data.result.refreshToken
                    })
                );
                localStorage.setItem(
                    "summaryRecFilter",
                    JSON.stringify({
                        filterRecId: '',
                        RecValue: '',
                        RecName: ''
                    })
                );

                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const adminlogin = createAsyncThunk(
    'auth/adminlogin',
    async ({ role, email, password }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/admin/auth/login',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web'
                    },
                    body: JSON.stringify({
                        role,
                        email,
                        password,
                    }),
                }
            );
            let data = await response.json();

            if (response.status === 200) {
                // localStorage.setItem(
                //     "dkmasterBoxAdmin",
                //     JSON.stringify({
                //         token: data.result.token,
                //         refreshToken: data.result.token.refreshToken
                //     })
                // );
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const changePassword = createAsyncThunk(
    'auth/password',
    async ({ email }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/auth/resetlink/forget-password',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web'
                    },
                    body: JSON.stringify({
                        email
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateUserPassword = createAsyncThunk(
    'auth/update_password',
    async ({ email, accessToken, confirmPassword, password }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/auth/changepwd/resetpassword/' + email + '/' + accessToken,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web'
                    },
                    body: JSON.stringify({
                        confirmPassword,
                        password
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const updatePassword = createAsyncThunk(
    'auth/profile/update_password',
    async ({ userId, currentPassword, password, confirmPassword }, thunkAPI) => {
        try {

            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/rest-password/' + userId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        userId, currentPassword, password, confirmPassword
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const updateUsrPassword = createAsyncThunk(
    'auth/profile/User_update_password',
    async ({ userId, email, canId, password, confirmPassword }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/rest-candidate-password/' + userId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        userId, email, canId, password, confirmPassword
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const getCandetails = createAsyncThunk(
    'users/details/cand1listById',
    async ({ userId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/cand/' + userId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const usersById = createAsyncThunk(
    'users/details/listById',
    async ({ userId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/' + userId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateDetails = createAsyncThunk(
    'users/update/profileDetails',
    async ({ file, userId, firstName, lastName, jobTitle, department, nationality, mobileNo, backupEmail, currentLocation, dateOfJoining, emergencyContact, emergencyPhone, homeAddress, imgFile, cityName, postalName, countryName }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/updateprofile/' + userId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        file, firstName, lastName, jobTitle, department, nationality, mobileNo, backupEmail, currentLocation, dateOfJoining, emergencyContact, emergencyPhone, homeAddress, imgFile, cityName, postalName, countryName
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

export const updateCanDetails = createAsyncThunk(
    'users/update/profileCanDetails',
    async ({ id, profileImage }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/update-profile/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        profileImage
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

export const updateCandidateDetails = createAsyncThunk(
    'users/update/candidateprofileDetails',
    async ({ file, userId, firstName, lastName, jobTitle, department, nationality, mobileNo, backupEmail, currentLocation, dateOfJoining, emergencyContact, emergencyPhone, homeAddress, imgFile, cityName, postalName, countryName }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/candidateProfile/' + userId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        file, firstName, lastName, jobTitle, department, nationality, mobileNo, backupEmail, currentLocation, dateOfJoining, emergencyContact, emergencyPhone, homeAddress, imgFile, cityName, postalName, countryName
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        userInfo: {},
        userDetails: {},
        candidateUserDetails: {},
        adminToken: '',
        isAuthenticate: false,
        isAdminAuthenticate: false,
        isSuccess: false,
        isError: false,
        isLoading: false,
        resendEmail: false,
        errorMessage: '',
        isAdminLoginSuccess: false,
        isUpdate: false,
        isPasswordUpdate: false

    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isUpdate = false;
            state.isSuccess = false;
            state.isLoading = false;
            state.resendEmail = false;
            state.isPasswordUpdate = false;
            state.isAdminLoginSuccess = false;
            state.errorMessage = '';
            return state;
        },
        logoutUser: (state) => {
            localStorage.removeItem("dkmasterBoxClient");
            localStorage.removeItem("summaryRecFilter");
            localStorage.removeItem("persist:root");
            state.isAuthenticate = false
            state.isAdminAuthenticate = false
            state.userInfo = {}
        },
        doLogin: (state, { payload }) => {
            state.isAuthenticate = true;
            state.isAdminAuthenticate = true
            state.userInfo = payload;
        },
        updateHeaderDetails: (state, { payload }) => {
            state.userInfo.name = payload.name;
            state.userInfo.userImg = payload.userImg;
        }
    },
    extraReducers: {
        [recruterlogin.pending]: (state) => {
            state.isLoading = true;
        },
        [recruterlogin.fulfilled]: (state, { payload }) => {
            state.isAuthenticate = true
            state.isAdminAuthenticate = true
            state.userInfo = payload.result;
        },
        [recruterlogin.rejected]: (state, { payload }) => {
            state.isError = true;
            state.errorMessage = payload.message;
            state.isLoading = false;
        },
        [loginUser.pending]: (state) => {
            state.isLoading = true;
        },
        [loginUser.fulfilled]: (state, { payload }) => {
            state.isAuthenticate = true
            state.isAdminAuthenticate = true
            state.userInfo = payload.result;
        },
        [loginUser.rejected]: (state, { payload }) => {
            state.isError = true;
            state.errorMessage = payload.message;
            state.isLoading = false;
        },
        [adminlogin.pending]: (state) => {
            state.isLoading = true;
        },
        [adminlogin.fulfilled]: (state, { payload }) => {
            state.isAdminLoginSuccess = true;
            state.adminToken = payload.result.token
            state.userInfo = payload.result;
            state.isLoading = false;
        },
        [adminlogin.rejected]: (state, { payload }) => {
            state.isError = payload.state;
            state.errorMessage = payload.message;
            state.isLoading = false;
        },
        [changePassword.pending]: (state) => {
            state.isLoading = true;
            state.resendEmail = false;
        },
        [changePassword.fulfilled]: (state, { payload }) => {
            state.resendEmail = true;
            state.isLoading = false;
        },
        [changePassword.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [updateUserPassword.pending]: (state) => {
            state.isLoading = false;
        },
        [updateUserPassword.fulfilled]: (state, { payload }) => {
            state.isSuccess = true;
            state.isLoading = false;
        },
        [updateUserPassword.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [getCandetails.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
        },
        [getCandetails.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            //state.isSuccess = true;
            state.candidateUserDetails = payload.result;
        },
        [getCandetails.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
        },

        [usersById.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
        },
        [usersById.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            // state.isSuccess = true;
            state.userDetails = payload.result;
        },
        [usersById.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateCandidateDetails.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
        },
        [updateCandidateDetails.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            state.candidateUserDetails = payload.result;
            state.userInfo = payload.result
            state.msg = payload.message.msgBody;
        },
        [updateCandidateDetails.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [updateCanDetails.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
        },
        [updateCanDetails.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            state.userInfo = payload.result
            state.msg = payload.message.msgBody;
        },
        [updateCanDetails.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },

        [updateDetails.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
        },
        [updateDetails.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            state.userInfo = payload.result
            state.msg = payload.message.msgBody;
        },
        [updateDetails.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [updatePassword.pending]: (state) => {
            state.isLoading = true;
            state.isPasswordUpdate = false;
        },
        [updatePassword.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isPasswordUpdate = true;
            state.msg = payload.message;
        },
        [updatePassword.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isPasswordUpdate = false;
            state.isError = true;
            state.msg = payload.message
        },

        [updateUsrPassword.pending]: (state) => {
            state.isLoading = true;
            state.isPasswordUpdate = false;
        },
        [updateUsrPassword.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isPasswordUpdate = true;
            state.msg = payload.message;
        },
        [updateUsrPassword.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isPasswordUpdate = false;
            state.isError = true;
            state.msg = payload.message
        }

    }
});
export const { clearState, logoutUser, doLogin, updateHeaderDetails } = authSlice.actions;
export default authSlice.reducer;