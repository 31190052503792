import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData } from "../../services";


export const jobList = createAsyncThunk(
    'inactivejobs/live-jobs/list',
    async ({ isDelete, company }, thunkAPI) => {
        const res = await PostData(true, '/jobs/list/inactive-livejobs', { isDelete, company })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const updateJob = createAsyncThunk(
    'inactivejobs/live-jobs/update',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/jobs/jobsApi/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)



const jobsSlice = createSlice({
    name: 'inactiveLivejobs',
    initialState: {
        jobseDataList: [],
        dataList: [],
        closedjobseDataList: [],
        jobDetails: {},
        isLoading: false,
        isUpdate: false,
        isTblUpdate: false,
        isError: false,
        isSuccess: false,
        message: '',
        isDetailsLoading: false
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isUpdate = false;
            state.isDetailsLoading = false;
            state.isSuccess = false;
            state.isTblUpdate = false;
            state.message = '';
        },
    },
    extraReducers: (builder) => {

        builder.addCase(
            jobList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            jobList.fulfilled, (state, { payload }) => {
                state.jobseDataList = payload.result
                state.message = payload.message;
                state.isLoading = false;
            });
        builder.addCase(
            jobList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateJob.pending, (state) => {
            });
        builder.addCase(
            updateJob.fulfilled, (state, action) => {
                let mainArray = state.jobseDataList;
                let finalResult = [];
                let finalResult1 = [];
                if (action.payload.result.status === "Closed") {
                    finalResult = state.jobseDataList = state.jobseDataList.filter((item) => item.id !== action.payload.result.id);
                } else {
                    finalResult = mainArray.map((r) => {
                        if (r.id === action.payload.result.id) {
                            return (action.payload.result)
                        } else {
                            return r
                        }
                    })
                }

                if (action.payload.result.status !== "Closed") {
                    finalResult1 = state.closedjobseDataList = state.closedjobseDataList.filter((item) => item.id !== action.payload.result.id);
                } else {
                    finalResult1 = mainArray.map((r) => {
                        if (r.id === action.payload.result.id) {
                            return (action.payload.result)
                        } else {
                            return r
                        }
                    })
                }

                state.isUpdate = true
                state.isTblUpdate = true
                state.jobseDataList = finalResult;
                state.closedjobseDataList = finalResult1;
                state.jobDetails = action.payload.result
            });
        builder.addCase(
            updateJob.rejected, (state, action) => {
                state.isError = true;
                state.isTblUpdate = false;
                // state.message = action.error.message;
            });


    }
});
function isEmpty(obj) {
    for (var i in obj) { return false; }
    return true;
}
export const { clearState } = jobsSlice.actions;
export default jobsSlice.reducer;