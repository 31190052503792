import React from "react";
import "./editCell.scss";

const EditableCell = ({ id, columnId, initialValue, updateMyData }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    const onChange = (e) => {
        setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(id, columnId, value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return <input className="form-control" value={value} onChange={onChange} onBlur={onBlur} />;
};

export default EditableCell;