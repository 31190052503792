import React from 'react';
import ModelNavtabs from './navtabs/ModelNavtabs';
import { FaCheck, FaTimes } from 'react-icons/fa';
import './gitstarted-workmodel.scss';


const GetstartedModelOverview = () => {

    return (
        <React.Fragment>
            <div className="dkg-getStarted-workModelMain">
                <ModelNavtabs />
                <div className='dkg-workModel-mainCon'>
                    <div className="dkg-workModel-tableCon">
                        <div className="dk-workModel-overviewMain">
                            <div className="dkg-workmodel-cols">
                                <div className="title" style={{ background: '#3c96b5' }}>INHOUSE EMPLOYEES - MODEL 1
                                </div>
                                <div className="workModel-panel">
                                </div>
                            </div>
                        </div>
                        <div className="dk-workModel-overviewMain">
                            <div className="dkg-workmodel-cols">
                                <div className="title" style={{ background: '#2e75b6' }}>RECRUITMENT PARTNER - MODEL 2
                                </div>
                                <div className="workModel-panel"></div>
                            </div>

                        </div>
                        <div className="dk-workModel-overviewMain">
                            <div className="dkg-workmodel-cols">
                                <div className="title" style={{ background: '#26596d' }}>FREELANCE PARTNER - MODEL 3
                                </div>
                                <div className="workModel-panel"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default GetstartedModelOverview;

