import React from "react";
import { Route, Link } from "react-router-dom";

const AlterSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/my-files/director/director1" className={`${(segment3 == 'director1') ? 'active' : ''}`}>Director 1</Link></li>
                        <li><Link to="#/my-files/director/director2" className={`${(segment3 == 'director2') ? 'active' : ''}`}>Director 2</Link></li>
                        <li><Link to="#/my-files/director/director3" className={`${(segment3 == 'director3') ? 'active' : ''}`}>Director 3</Link></li>
                        <li><Link to="#/my-files/director/director4" className={`${(segment3 == 'director4') ? 'active' : ''}`}>Director 4</Link></li>
                        <li><Link to="#/my-files/director/director5" className={`${(segment3 == 'director5') ? 'active' : ''}`}>Director 5</Link></li>
                        <li><Link to="#/my-files/director/director6" className={`${(segment3 == 'director6') ? 'active' : ''}`}>Director 6</Link></li>
                        <li><Link to="#/my-files/director/director7" className={`${(segment3 == 'director7') ? 'active' : ''}`}>Director 7</Link></li>
                        <li><Link to="#/my-files/director/director8" className={`${(segment3 == 'director8') ? 'active' : ''}`}>Director 8</Link></li>
                        <li><Link to="#/my-files/director/director9" className={`${(segment3 == 'director9') ? 'active' : ''}`}>Director 9</Link></li>
                        <li><Link to="#/my-files/director/director10" className={`${(segment3 == 'director10') ? 'active' : ''}`}>Director 10</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default AlterSubmenu;
