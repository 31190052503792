import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const addNew = createAsyncThunk(
    'user/prescreening-form/add',
    async ({ companyId, candidateName, company, jobApplied, jobDescription, jobRequirment, salary, workExperience, motivation, reasionLeave, reLocation, vaccineInfo, availibilty }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/prescreeningforms',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        companyId,
                        candidateName,
                        company,
                        jobApplied,
                        jobDescription,
                        jobRequirment,
                        salary,
                        workExperience,
                        motivation,
                        reasionLeave,
                        reLocation,
                        vaccineInfo,
                        availibilty
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const listAll = createAsyncThunk(
    'user/prescreening-form/list',
    async ({ }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/prescreeningforms',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const detailsById = createAsyncThunk(
    'user/prescreening-form/details-by-id',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/prescreeningforms/' + id,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateValue = createAsyncThunk(
    'user/prescreening-form/update',
    async ({ id, companyId, candidateName, company, jobApplied, jobDescription, jobRequirment, salary, workExperience, motivation, reasionLeave, reLocation, vaccineInfo, availibilty }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/prescreeningforms/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        companyId,
                        candidateName,
                        company,
                        jobApplied,
                        jobDescription,
                        jobRequirment,
                        salary,
                        workExperience,
                        motivation,
                        reasionLeave,
                        reLocation,
                        vaccineInfo,
                        availibilty
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteValue = createAsyncThunk(
    'user/prescreening-form/delete',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/prescreeningforms/' + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const precreeningSlice = createSlice({
    name: 'prescreeningForm',
    initialState: {
        dataList: [],
        details: {},
        isSuccess: false,
        isDelete: false,
        isError: false,
        isLoading: false,
        isDetails: false,
        message: '',
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isDelete = false;
            state.isLoading = false;
            state.isDetails = false;
            state.message = '';
            return state;
        }
    },
    extraReducers: {
        [addNew.pending]: (state) => {
            state.isLoading = true;
        },
        [addNew.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.dataList.unshift(payload.result)
            state.message = payload.message;
        },
        [addNew.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload.message;
        },
        [updateValue.pending]: (state) => {
            state.isLoading = true;
        },
        [updateValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            let mainArray = state.dataList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.dataList = finalResult
            state.message = payload.message;
        },
        [updateValue.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload.message;
        },
        [listAll.pending]: (state) => {
            state.isLoading = true;
        },
        [listAll.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.dataList = payload.result;
        },
        [listAll.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload.message;
        },
        [detailsById.pending]: (state) => {
            state.isLoading = true;
        },
        [detailsById.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isDetails = true;
            state.details = payload.result;
        },
        [detailsById.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.isDetails = false;
            state.message = payload.message;
        },
        [deleteValue.pending]: (state) => {
        },
        [deleteValue.fulfilled]: (state, action) => {
            state.isDelete = true;
            state.dataList = state.dataList.filter((item) => item.id !== action.meta.arg.id)
            state.message = "Delete successfully.";
        },
        [deleteValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isDetails = false;
            state.message = payload.message;
        },
    }
});
export const { clearState } = precreeningSlice.actions;
export default precreeningSlice.reducer;