import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import Deepak from '../../../../../../assets/images/deepak.png';

const index = (props) => {
    return (
        <>
            <Dropdown className="dkg-mypipe-rec-dropdown">
                <Dropdown.Toggle variant="" className="dkg-mypipe-rec-dropdown-toggle">
                    {props.title}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dkg-mypipe-rec-dropdown-menu">
                    <div className='d-flex'>
                        <Dropdown.Item href="#" className='dkg-mypipe-rec-dropdown-item'>
                            <img src={Deepak} className="recuiter-img" alt={props.useName} />
                            <span className='recNoCount'>12</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" className='dkg-mypipe-rec-dropdown-item'>
                            <img src={Deepak} className="recuiter-img" alt={props.useName} />
                            <span className='recNoCount'>12</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" className='dkg-mypipe-rec-dropdown-item'>
                            <img src={Deepak} className="recuiter-img" alt={props.useName} />
                            <span className='recNoCount'>12</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" className='dkg-mypipe-rec-dropdown-item'>
                            <img src={Deepak} className="recuiter-img" alt={props.useName} />
                            <span className='recNoCount'>12</span>
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default index