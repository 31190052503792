import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import ViewImg from "../../../assets/images/screenshot.jpg";
import '../itteam.scss';


const AddNewFeedback = () => {
    return (
        <>
            <div className="dk-itTeamMain ">
                <div className="dk-itTeamHead">
                    <div className="d-flex align-items-center justify-content-start">
                        <h3 className="title">Add New Feedback</h3>

                        <div className="dk-itTeamBackBtn">
                            <div className="d-flex">
                                <Link to="/it-team/mukul"><i className="fas fa-arrow-left"></i> Back</Link>
                            </div>
                        </div>
                        {/* <div className="dk-addScreenshot">
                            <div className="dk-addScreenCols">
                                <div className="dk-addImg">
                                    <span><i className="fas fa-plus"></i> Add Screenshot</span>
                                </div>
                                <input type="file" name="" id="" />
                            </div>
                        </div> */}

                    </div>
                </div>

                <div className="dk-itTeamTaskMain">
                    <div className="dk-itTeamTabsPanel">
                        {/* <div className="dk-viewScreenShot">
                            <span className="closeImg"><i className="fas fa-times"></i></span>
                            <img src={ViewImg} className="img-fluid" alt="" />
                        </div>
                        <div className="dk-textLine">
                            <div className="d-flex">
                                <input type="text" placeholder="Please type here..." className="form-control" name="" id="" />
                                <button><i className="fas fa-plus"></i></button>
                            </div>
                        </div>

                        <div className="dk-feedbackTextShow">
                            <div className="dk-feedbackTextShow-cols dk-textLine d-flex">
                                <input type="text" value="1. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis excepturi, itaque ducimus eaque corrupti quia natus cumque." name="" id="" readOnly />
                                <button><i className="far fa-edit"></i></button>
                                <button><i className="far fa-trash-alt"></i></button>
                            </div>
                            <div className="dk-feedbackTextShow-cols dk-textLine d-flex">
                                <input type="text" value="2. Lorem ipsum dolor, sit amet consectetur adipisicing itaque ducimus eaque corrupti elit. Omnis excepturi, itaque ducimus eaque corrupti quia natus cumque." name="" id="" readOnly />
                                <button><i className="far fa-edit"></i></button>
                                <button><i className="far fa-trash-alt"></i></button>
                            </div>
                            <div className="dk-feedbackTextShow-cols dk-textLine d-flex">
                                <input type="text" value="3. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis excepturi, itaque ducimus eaque Omnis excepturi, itaque ducimus eaque corrupti quia natus cumque." name="" id="" readOnly />
                                <button><i className="far fa-edit"></i></button>
                                <button><i className="far fa-trash-alt"></i></button>
                            </div>
                        </div> */}
                        <div className="dk-addNew-editor">
                            <CKEditor
                                editor={ClassicEditor}
                                data="<p>Please type here...</p>"
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                }}

                            />

                            <div className="dk-editorBtn mt-5">
                                <button className="dk-btn cancel">Cancel</button>
                                <button className="dk-btn">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddNewFeedback;
