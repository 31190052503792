import React, { useEffect, useState, useMemo, lazy, Suspense } from 'react';
import { Link } from "react-router-dom";
import { FaFilter, FaInfoCircle, FaSyncAlt } from 'react-icons/fa';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useAsyncDebounce } from 'react-table';
import Pagination from '@material-ui/lab/Pagination';
import { useDispatch, useSelector } from "react-redux";
import { clearState, getList, deleteTask, moveRecordsToPaid, updateByKey, taskDetails } from "../../../../../slice/accounting/businessBookSlice";
import { ButtonGroup, Button, DropdownButton } from 'react-bootstrap';
import TableLoader from '../../../../ui/tableLoader';
import Dropdown from 'react-bootstrap/Dropdown';
import MonthFilterDropdown from '../tableFilters/month/monthsFilter';
import UserList from "../../../../ui/userList/UserListSalesBook";
import $ from "jquery";
import DeleteModal from "../utils/delete/DeleteRow";
import MonthBGColour from "../utils/monthsBgColor/monthbg";
import { showSuccess } from '../../../../../slice/utils/message/messageSlice';
import CommentModal from '../../../../utils/comment';
import AccountManagerDropdown from '../tableFilters/users/accountManagerFilter';
import RecrutersDropdown from '../tableFilters/users/rcFilter';
import CaseStatusFilter from '../tableFilters/statusfilter/finalFee'
import "../yearscustom.scss";
import './overview-twentytwo.scss';
import AdvancedFilter from "../modal/SearchSendout";
import UpdateModal from "../modal/UpdateModal";
import AddNew from "../modal/AddNew";
import EditDetails from "../modal/EditDetails";
import ReasonStatusList from '../../../../ui/dropdownList/interviewReason';
import CaseStatus from "../utils/dropdown/caseStatus/finalFee";
import CaseStatusList from '../../../../ui/dropdownList/FinalFeeStatus';
import ValuesCaseProgress from "../utils/dropdown/progress/FinalFee";
import CaseStageVal from "../utils/dropdown/stages/FinalFee";
import CaseProgressList from '../../../../ui/dropdownList/FinalFeeProgress';
import CaseStageList from '../../../../ui/dropdownList/FinalFeeStage';
import RecruiterAdmin from '../../../../../assets/images/deepak.png'

import { finalFeeCaseStatus, finalFeeCaseProgress, finalFeeCaseStage,reasionCase } from "../../../../../slice/layout/layoutSlice";
import { usersRCList } from "../../../../../slice/userList/userSlice";
/*********************** */
import Checkbox from "../utils/checkbox/radioBtn";

const TableSuspence = lazy(() => import('../../../../ui/tableLoader/tableSuspence'));

const useInstance = (instance) => {
    if (instance && instance.getInstanceCallback) {
        instance.getInstanceCallback(instance);
    }
};

function Table({ columns, data, loading, getInstanceCallback, filter, AManagerFilterId, reasonId,reCcInfoFilterId, updateMyData, caseStatusId, caseStageId, monthsFilterId, monthsId, progressId, companyId, projectId, languageId, roleTypeId, cvSourceId, caseStatusFilterId }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        page,
        prepareRow,
        state,
        setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            updateMyData,
            getInstanceCallback,
            autoResetPage: false,
            autoResetSortBy: false,
            autoResetFilters: false,
            autoResetRowState: false,
            initialState: {
                pageIndex: 0,
                pageSize: 150,
                hiddenColumns: ["AManger", "SRecruters"]
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy, usePagination, (hooks) => hooks.useInstance.push(useInstance)
    )

    const handlePageChange = (event, value) => {
        gotoPage(Number(value) - 1);
    };
    useEffect(() => {
        if (cvSourceId === '0') {
            setTimeout(() => {
                setFilter('cvSource')
            }, 200)
        } else {
            setFilterType('cvSource')
            doFilter(cvSourceId);
        }
    }, [cvSourceId])

    useEffect(() => {
        if (monthsId === '0') {
            setTimeout(() => {
                setFilter('month')
            }, 200)
        } else {
            setFilterType('month')
            doFilter(monthsId);
        }
    }, [monthsId])

    useEffect(() => {
        if (roleTypeId === '0') {
            setTimeout(() => {
                setFilter('roleType')
            }, 200)
        } else {
            setFilterType('roleType')
            doFilter(roleTypeId);
        }
    }, [roleTypeId])

    useEffect(() => {
        if (languageId === '0') {
            setTimeout(() => {
                setFilter('language')
            }, 200)
        } else {
            setFilterType('language')
            doFilter(languageId);
        }
    }, [languageId])
    useEffect(() => {
        if (projectId === '0') {
            setTimeout(() => {
                setFilter('project')
            }, 200)
        } else {
            setFilterType('project')
            doFilter(projectId);
        }
    }, [projectId])
    useEffect(() => {
        if (companyId === '0') {
            setTimeout(() => {
                setFilter('company_id')
            }, 200)
        } else {
            setFilterType('company_id')
            doFilter(companyId);
        }
    }, [companyId])

    useEffect(() => {
        if (progressId === '0') {
            setTimeout(() => {
                setFilter('progress')
            }, 200)
        } else {
            setFilterType('progress')
            doFilter(progressId);
        }
    }, [progressId])


    useEffect(() => {
        if (AManagerFilterId === '0') {
            setTimeout(() => {
                setFilter('AManger', '')
            }, 200)
        } else {
            setFilterType('AManger')
            doFilter(AManagerFilterId);
        }
    }, [AManagerFilterId])

    useEffect(() => {
        if (reasonId === '0') {
            setTimeout(() => {
                setFilter('reason')
            }, 200)
        } else {
            setFilterType('reason')
            doFilter(reasonId);
        }
    }, [reasonId])

    useEffect(() => {
        if (caseStageId === '0') {
            setTimeout(() => {
                setFilter('caseStage')
            }, 200)
        } else {
            setFilterType('caseStage')
            doFilter(caseStageId);
        }
    }, [caseStageId])

    useEffect(() => {
        if (caseStatusId === '0') {
            setTimeout(() => {
                setFilter('caseStatus', '')
            }, 200)
        } else {
            setFilterType('caseStatus')
            doFilter(caseStatusId);
        }
    }, [caseStatusId])

    useEffect(() => {
        if (caseStatusFilterId === '0') {
            setTimeout(() => {
                setFilter('progress', '')
            }, 200)
        } else {
            setFilterType('progress')
            doFilter(caseStatusFilterId);
        }
    }, [caseStatusFilterId])

    useEffect(() => {
        if (monthsFilterId === '0') {
            setTimeout(() => {
                setFilter('month', '')
            }, 200)
        } else {
            setFilterType('month')
            doFilter(monthsFilterId);
        }
    }, [monthsFilterId])

    // useEffect(() => {
    //     if (casePayTypeId === '0') {
    //         setTimeout(() => {
    //             setFilter('payType', '')
    //         }, 200)
    //     } else {
    //         setFilterType('payType')
    //         doFilter(casePayTypeId);
    //     }
    // }, [casePayTypeId])

    // useEffect(() => {
    //     if (caseTypeId === '0') {
    //         setTimeout(() => {
    //             setFilter('caseStatus', '')
    //         }, 200)
    //     } else {
    //         setFilterType('caseStatus')
    //         doFilter(caseTypeId);
    //     }
    // }, [caseTypeId])

    useEffect(() => {
        if (reCcInfoFilterId === '0') {
            setTimeout(() => {
                setFilter('SRecruters', '')
            }, 200)
        } else {
            setFilterType('SRecruters')
            doFilter(reCcInfoFilterId);
        }
    }, [reCcInfoFilterId])

    // const handlePageChange = (event, value) => {
    //     gotoPage(Number(value) - 1);
    // };

    const [filterType, setFilterType] = useState('')

    const doFilter = React.useCallback(
        useAsyncDebounce((id) => {
            setFilter(filterType, id)
        }, 200),
        [filterType]
    );

    return (
        <React.Fragment>
            <table className="table table-bordered dkg-business-finalfee-table" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                (loading) ?
                                    [...Array(10)].map((e, i) =>
                                        <tr>
                                            <TableLoader colSpan="22" />
                                        </tr>
                                    ) :
                                    <Suspense fallback={<TableSuspence colSpan="22" />}>
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => (
                                                <td
                                                    {...cell.getCellProps([
                                                        {
                                                            className: cell.column.className, // pay attention to this
                                                            style: cell.column.style,
                                                            // set here your other custom props
                                                        },
                                                    ])}
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    </Suspense>
                            )
                        }
                    )}
                </tbody>
                <tfoot>
                    {footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()}>
                            {group.headers.map(column => (
                                <td {...column.getFooterProps()} className="dkg-suMM">{column.render('Footer')}</td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
            <div className="col-sm-12">
                <div className="dk-resPaginationCon pagination">
                    <div className="dk-resPagination">
                        <div className="dk-resLeftPag">
                            <div className="dk-texCounter" style={{ color: '#fff !imporant' }}>
                                Showing {' '}{pageIndex + 1} to {pageOptions.length} (of {rows.length} entries)
                            </div>
                        </div>
                        <div className="dk-resrightPag pagination-btns">
                            <Pagination count={pageCount} shape="rounded" onChange={handlePageChange} />
                            {/* <TablePagination count={pageCount} onChange={handlePageChange} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function debounce(func, wait, immediate) {
    var timeout;
    return (...args) => {
        var context = this;
        var later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isClearAll,
    restClearAllFilter }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState("")

    const onChangeSearchTitle = (e) => {
        const { value: nextValue } = e.target;
        setValue(e.target.value)
        debouncedSave(nextValue);
    };

    const debouncedSave = React.useCallback(
        debounce(nextValue => {
            setGlobalFilter(nextValue)
        }, 1000),
    );

    const clearSearch = () => {
        setValue('')
        setTimeout(() => {
            setGlobalFilter(undefined)
        }, 200)
    }

    useEffect(() => {
        if (isClearAll) {
            clearSearch()
            restClearAllFilter();
        }
    }, [isClearAll])



    return (
        <React.Fragment>
            <input
                value={value}
                className="form-control"
                onChange={onChangeSearchTitle}
                placeholder={`Search ...`}
            />
            {
                (value !== undefined && value.length > 0) ?
                    <div className="input-group-append">
                        <button className="" type="button" onClick={clearSearch}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div> : <div className="input-group-append">
                        <button className="" type="button">
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
            }
        </React.Fragment>
    )
}

const FinalFee = () => {
    const dispatch = useDispatch();
    const { isLoading, salesBookList, isUpdate, message, isTaskUpdate, isTaskDelete, iscommentCountUpdate } = useSelector(state => state.businessBook);

    const [list, setList] = useState([]);
    const [data, setData] = useState([])

    const [rowId, setRowId] = useState(0)
    const [dataRowId, setDataRowId] = useState(0)
    const [isViewModal, setIsViewModal] = useState(false)
    const [rowData, setRowData] = useState({})
    const lastSegment = window.location.href.split("/").pop(); //for decode use atob("zyz")

    useEffect(() => {
        document.title = "Accounting Final Fee | Dk Global CRM"
    }, []);

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(getList({ year: atob(lastSegment), type: 'final-fee' }))
                await dispatch(finalFeeCaseStatus({}))
                await dispatch(finalFeeCaseProgress({}))
                await dispatch(finalFeeCaseStage({}))
                await dispatch(reasionCase({}))
                await dispatch(usersRCList({}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
    }, [dispatch, lastSegment])


    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                setData(salesBookList)
            }, 200)
        }
        if (iscommentCountUpdate) {
            dispatch(clearState())
            setData(salesBookList)
        }
        if (isUpdate) {
            dispatch(clearState())
            setData(salesBookList)
            setIsActive(false)
            SetshowEdit(false)
        }
    }, [isLoading, isUpdate, isTaskUpdate, iscommentCountUpdate, dispatch])

    useEffect(() => {
        if (isTaskDelete) {
            setLoading(false)
            setSuccess(true)
            dispatch(clearState())
            dispatch(getList({ year: atob(lastSegment), type: 'final-fee' }))
            dispatch(showSuccess({ msg: 'Record deleted successfully' }))
        }
    }, [isTaskDelete])

    // useEffect(() => {
    //     if (isTaskUpdate) {
    //         setIsActive(false)
    //         dispatch(clearState())
    //         //dispatch(getList({year:atob(lastSegment)}))
    //         dispatch(showSuccess({ msg: 'Record updated successfully!' }))
    //     }
    // }, [isTaskUpdate])

    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')
    const [countCheck, setCountCheck] = useState(0)
    const [isActive, setIsActive] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const checkActiveExtraDiv = async (isShowExitra, checkedBoxCount) => {

        if (isShowExitra) {
            setIsActive(true)
            setCountCheck(checkedBoxCount)
        } else {
            setIsActive(false)
            setCountCheck(checkedBoxCount)
        }

    }

    const checkExtraDivBtnTxt = (btnTxt) => {
        setCheckBtnTxt(btnTxt)
    }


    const handleToggle = () => {
        $('.chkCandidate').prop('checked', false);
        setIsActive(false);
    };

    const deleteItemBulk = async () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
        } else {
            var canIds = [];
            $.each($(".chkCandidate:checked"), function () {
                canIds.push($(this).val());
            });
            setLoading(true)
            setSuccess(false)
            await dispatch(deleteTask({ id: canIds }))
            $('.chkCandidate').prop('checked', false);
            setIsActive(false);
        }
    }

    const [dataMoveTo, SetDataMoveTo] = useState('')
    const [showView, SetShowView] = useState(false);
    const [showEdit, SetshowEdit] = useState(false);

    // const callViewPopup = async (data) => {
    //     await dispatch(taskDetails({ id: data }))
    //     SetShowView(true)
    // }
    const editFunction = async (e) => {
        await dispatch(taskDetails({ id: dataRowId }))
        SetshowEdit(true)
    }
    const closeEditModal = () => {
        SetshowEdit(false)
    }

    const [profileCanId, setProfileCanId] = useState(0)
    const [isProfileModal, setIsProfileModal] = useState(false)

    const handleCloseProfile = () => {
        setIsProfileModal(false);
        //SetshowEdit(false)
    }
    const veiwHandleClose = () => {
        SetShowView(false)
    }
    const handleClose = () => {
        SetshowEdit(false)
    }

    const [isShowSearch, setShowSearchModal] = useState(false)

    const showSearch = () => {
        setShowSearchModal(true)
    }

    const SearchClose = () => {
        setShowSearchModal(false)
    }

    const [isShowAddNew, setShowAddNew] = useState(false)

    const showAddNew = () => {
        setShowAddNew(true)
    }

    const SearchAddNew = () => {
        setShowAddNew(false)
    }


    const columns = useMemo(() => [
        {
            Header: 'SNo.',
            accessor: 'id',
            Cell: ({ cell }) => {
                return (
                    <span className="dk-checkinputIconCon">
                        {Number(cell.row.id) + 1}
                        <div className='dk-checkinputIcon'>
                            <Checkbox id={cell.row.original.id} checkActiveExtraDiv={checkActiveExtraDiv} checkExtraDivBtnTxt={checkExtraDivBtnTxt} setDataRowId={setDataRowId} />
                        </div>
                        <span className='ml-2'>{cell.row.original.caseId}</span>
                    </span>
                )
            }
        },

        {
            Header: 'MONTH',
            accessor: 'month',
            Cell: ({ cell }) => {
                return (
                    <MonthBGColour value={cell.row.original.month} />
                )
            }

        },
        {
            Header: 'RA',
            accessor: 'reason',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value, img) => {
                    updateMyData(id, columnId, value, img)
                };
                return (
                    <>

                        <div className="dk-useFilterCon">
                            <Dropdown className="dk-reason-dropdown" id='am_id'>
                                <Dropdown.Toggle variant="" className="dk-fliterbtn">
                                    <div className="tooltipMain">
                                        <img src={RecruiterAdmin} className="filterImg"  alt='Recruiter Admin'/>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dk-pipleuserFilterMenu">
                                   <div className="d-flex">
                                        <UserList />
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </>
                )
            }
        },
        {
            id: 'RC',
            Header: 'RC',
            accessor: 'reCcInfo.firstName',
            Cell: ({ cell }) => {
                const onItemClick = (id, columnId, value, img) => {
                    updateMyData(id, columnId, value, img)
                };
                return (
                    <React.Fragment>
                        <div className="dk-useFilterCon">
                            <Dropdown id={`rec_id${cell.row.original.id}`}>
                                <Dropdown.Toggle variant="" className="dk-fliterbtn">
                                    <div className="tooltipMain">
                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.reCcInfo.profileImage} className="filterImg" alt={cell.row.original.reCcInfo.firstName} />
                                        <div className="tooltipText">{cell.row.original.reCcInfo.firstName + " " + cell.row.original.reCcInfo.lastName}</div>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dk-pipleuserFilterMenu">
                                    <div className="d-flex">
                                        <UserList id={cell.row.original.id} columnId="recruiters" onItemClick={onItemClick} />
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </React.Fragment>
                )
            }
        },
        {
            id: 'AM',
            Header: 'AM',
            accessor: 'amIinfo.firstName',
            Cell: ({ cell }) => {
                const onItemClick = (id, columnId, value, img) => {
                    updateMyData(id, columnId, value, img)
                };

                return (
                    <React.Fragment>
                        <div className="dk-useFilterCon">
                            <Dropdown id={`am_id${cell.row.original.id}`}>
                                <Dropdown.Toggle variant="" className="dk-fliterbtn">
                                    <div className="tooltipMain">
                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.amIinfo.profileImage} className="filterImg" alt={cell.row.original.amIinfo.firstName} />
                                        <div className="tooltipText">{cell.row.original.amIinfo.firstName + " " + cell.row.original.amIinfo.lastName}</div>

                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dk-pipleuserFilterMenu">
                                    <div className="d-flex">
                                        <UserList id={cell.row.original.id} columnId="accountManager" onItemClick={onItemClick} />
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </React.Fragment>
                )
            }
        },
        {
            Header: 'STATUS',
            accessor: 'commentCount',
            className: 'pl-2 pr-2',
            Cell: ({ cell }) => {
                return (
                    <>
                        <Dropdown className='dkg-case-stus-dropdown'>
                            <Dropdown.Toggle variant="" className='dkg-case-stus-dropdown-toggle'>
                               <div className="statusDiv" style={{backgroundColor: "#3a9469" , color: "#fff"}}>Open</div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='dkg-case-stus-dropdownMenu'>
                                <Dropdown.Item href="#" className='dkg-case-stus-dropdown-item'  style={{backgroundColor: "#3a9469" , color: "#fff"}}>Open</Dropdown.Item>
                                <Dropdown.Item href="#" className='dkg-case-stus-dropdown-item'  style={{backgroundColor: "#dc3545" , color: "#fff"}}>Closed</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                )
        }
        },
        {
            Header: 'SITUATION',
            accessor: 'caseStatus',
            id: 'caseStatus',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value, img) => {
                    updateMyData(id, columnId, value, img)
                };
                return (
                    <React.Fragment>
                        <div className="dk-caseStatusDropCon">
                            <Dropdown id={`caseStatus${cell.row.original.id}`}>
                                <Dropdown.Toggle variant="" className="dk-caseStatusfliterbtn">
                                    <CaseStatus value={cell.row.original.caseStatus} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="caseStatusMenu">
                                    <CaseStatusList id={cell.row.original.id} columnId="caseStatus" onItemClick={onItemClick} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </React.Fragment>
                )
            }
        },

        {
            id: 'progress',
            Header: 'PROGRESS',
            accessor: 'progress',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value, img) => {
                    updateMyData(id, columnId, value, img)
                };
                return (
                    <React.Fragment>
                        <div className="dk-caseStatusDropCon" onClick={() => {
                            let text = $(`#caseStatus${cell.row.original.id} button .caseDiv`).html();
                        }}>
                            <Dropdown id={`progress${cell.row.original.id}`}>
                                <Dropdown.Toggle variant="" className="dk-caseStatusfliterbtn">
                                    <ValuesCaseProgress caseStatus={cell.row.original.progress} value={cell.row.original.progress} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="caseStatusMenu">
                                    <CaseProgressList caseStatus={$(`#caseStatus${cell.row.original.id} button .caseDiv`).html()} id={cell.row.original.id} columnId="progress" onItemClick={onItemClick} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </React.Fragment>
                )
            }
        },
        {
            Header: 'STAGE',
            id: 'caseStage',
            accessor: 'caseStage',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value, img) => {
                    updateMyData(id, columnId, value, img)
                };
                return (
                    <React.Fragment>
                        <div className="dk-caseStatusDropCon" onClick={() => {
                            let text = $(`#caseStatus${cell.row.original.id} button .caseDiv`).html()
                        }}>
                            <Dropdown id={`caseStage${cell.row.original.id}`}>
                                <Dropdown.Toggle variant="" className="dk-caseStatusfliterbtn">
                                    <CaseStageVal caseStatus={cell.row.original.caseStage} value={cell.row.original.caseStage} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="caseStatusMenu">
                                    <CaseStageList caseStatus={$(`#caseStatus${cell.row.original.id} button .caseDiv`).html()} id={cell.row.original.id} columnId="caseStage" onItemClick={onItemClick} />

                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </React.Fragment>
                )
            }
        },
        {
            id: 'company_id',
            Header: 'COMPANY',
            accessor: 'company',
            className: 'pl-2 pr-2',
        },
        {
            id: 'language',
            Header: 'LANGUAGE',
            accessor: 'language'
        },
        {
            Header: 'NAME OF CANDIDATE',
            accessor: 'candidateName',
            className: 'pl-2 pr-2',
        },
        // {
        //     Header: 'Comment',
        //     accessor: 'commentCount',
        //     className: 'pl-2 pr-2',
        //     Cell: ({ cell }) => {
        //         return <CommentModal refTable="account_interviews" refId={cell.row.original.id} count={cell.row.original.commentCount} title="Accounting Interviews Comments" />

        //     }
        // },
        // {
        //     Header: 'REASON',
        //     accessor: 'reason',
        //     Cell: ({ cell }) => {
        //         const onItemClick = async (id, columnId, value, img) => {
        //             updateMyData(id, columnId, value, img)
        //         };
        //         return (
        //             <>
        //             <div className="dk-reason-dropdownCon">
        //                 <Dropdown className="dk-reason-dropdown">
        //                     <Dropdown.Toggle variant="" className="dk-reason-dropdown-toggle">
        //                         {cell.row.original.reason}
        //                     </Dropdown.Toggle>
        //                     <Dropdown.Menu className="dk-reason-dropdownMenu">
        //                         <ReasonStatusList id={cell.row.original.id} columnId="reason" onItemClick={onItemClick} />
        //                     </Dropdown.Menu>
        //                 </Dropdown>
        //             </div>
        //             </>
        //         )
        //     }
        // },
        // {
        //     Header: 'REFUSED',
        //     accessor: 'refused',
        //     Cell: ({ cell }) => {
        //         return <>
        //             <span onClick={() => openUpdateValuePop(cell.row.original.id, 'refused', cell.row.original.refused)} className="cursor-p">
        //                 {cell.row.original.refused} €
        //             </span>
        //         </>
        //     },
        //     Footer: (info) => {
        //         const total = React.useMemo(
        //             () =>
        //                 info.flatRows.reduce((sum, row) => Number(row.original.refused) + sum, 0),
        //             [info.rows]
        //         )
        //         return <><span className='totalAmtPaymentTask'>{total + ' ' + '€'} </span></>
        //     },
        // },
        // {
        //     Header: 'CREDIT',
        //     accessor: 'credit',
        //     Cell: ({ cell }) => {
        //         return <>
        //             <span onClick={() => openUpdateValuePop(cell.row.original.id, 'credit', cell.row.original.credit)} className="cursor-p">
        //                 {cell.row.original.credit} €
        //             </span>
        //         </>
        //     },
        //     Footer: (info) => {
        //         const total = React.useMemo(
        //             () =>
        //                 info.flatRows.reduce((sum, row) => Number(row.original.credit) + sum, 0),
        //             [info.rows]
        //         )
        //         return <><span className='totalAmtPaymentTask'>{total + ' ' + '€'} </span></>
        //     },
        // },
        // {
        //     Header: 'FINAL FEE',
        //     accessor: 'finalFee',
        //     Cell: ({ cell }) => {
        //         return <>
        //             <span onClick={() => openUpdateValuePop(cell.row.original.id, 'finalFee', cell.row.original.finalFee)} className="cursor-p">
        //                 {cell.row.original.finalFee} €
        //             </span>
        //         </>
        //     },
        //     Footer: (info) => {
        //         const total = React.useMemo(
        //             () =>
        //                 info.flatRows.reduce((sum, row) => Number(row.original.finalFee) + sum, 0),
        //             [info.rows]
        //         )
        //         return <><span className='totalAmtPaymentTask'>{total + ' ' + '€'} </span></>
        //     },
        // },
        {
            Header: 'PROJECT',
            id: 'project',
            accessor: 'project',

        },
        {
            Header: 'ROLE TYPE',
            id: 'roleType',
            accessor: 'roleType',

        },

        {
            Header: 'CV SOURCE',
            id: 'cvSource',
            accessor: 'cvSource',
        },
        {
            Header: 'FINAL FEE',
            accessor: 'fullFee',
            Cell: ({ cell }) => {
                return <>
                    <span onClick={() => openUpdateValuePop(cell.row.original.id, 'fullFee', cell.row.original.fullFee)} className="cursor-p">
                        {cell.row.original.fullFee} €
                    </span>
                </>
            },
            Footer: (info) => {
                const total = React.useMemo(
                    () =>
                        info.flatRows.reduce((sum, row) => Number(row.original.fullFee) + sum, 0),
                    [info.rows]
                )
                return <><span className='totalAmtPaymentTask'>{total + ' ' + '€'} </span></>
            },
        },
        {
            id: 'AManger',
            Header: 'AManger',
            accessor: 'amIinfo.uid',
        },
        {
            id: 'SRecruters',
            Header: 'SRecruters',
            accessor: 'reCcInfo.uid',
        },
        // {
        //     Header: 'CASE ID',
        //     accessor: 'caseId'
        // },
        // {
        //     Header: 'JOB ID',
        //     accessor: 'jobId',
        // },

    ], [])

    const [instanceData, setInstanceData] = useState('0')
    const reactTableInstance = (instance) => {
        if (instance) {
            setList(instance.flatRows)
            setInstanceData(instance)
        }
    };

    const updateMyData = async (id, columnId, value, img) => {

        switch (columnId) {
            case 'status':
                $(`#caseStatus${id} button .caseDiv`).html(value).css("background-color", img)
                break;

            case 'caseStatus':
                $(`#caseStatus${id} button .caseDiv`).html(value).css("background-color", img)
                // $(`#caseStage${id} button .caseDiv`).html('Select').css("background-color", img)
                //$(`#progress${id} button .caseDiv`).html('Select').css("background-color", img)
                break;

            case 'progress':
                $(`#progress${id} button .caseDiv`).html(value)
                break;

            case 'caseStage':
                $(`#caseStage${id} button .caseDiv`).html(value)
                break;
            case 'am_id':
                $(`#am_id${id} button .tooltipMain img`).attr("src", process.env.REACT_APP_IMG_CLOUD_BASE_URL + img)
                break;

            case 'rec_id':
                $(`#rec_id${id} button .tooltipMain img`).attr("src", process.env.REACT_APP_IMG_CLOUD_BASE_URL + img)
                break;

            case 'rec_admin':
                $(`#rec_admin${id} button .tooltipMain img`).attr("src", process.env.REACT_APP_IMG_CLOUD_BASE_URL + img)
                break;
            default:
                break;
        }

        await dispatch(updateByKey({ id, key: columnId, value }))
    }

    const [isClearAll, setIsClearAll] = useState(false)

    const restClearAllFilter = async () => {
        setIsClearAll(false)
    }

    const [AManagerFilterId, setAManagerFilterId] = useState('0');
    const [AManagerByFilterImg, setAManagerByFilterImg] = useState('0');

    const [reCcInfoFilterId, setreCcInfoFilterId] = useState('0');
    const [reCcInfoFilterImg, setreCcInfoFilterImg] = useState('0');

    const selectUserFilter = async (e) => {
        const type = e.target.dataset.type;
        switch (type) {
            case 'AManger':
                setAManagerFilterId(e.target.dataset.id)
                setAManagerByFilterImg(e.target.dataset.img)
                // setFilter({ ...filter, [type]: e.target.dataset.id })
                break;
            case 'SRecruters':
                setreCcInfoFilterId(e.target.dataset.id)
                setreCcInfoFilterImg(e.target.dataset.img)
                // setFilter({ ...filter, [type]: e.target.dataset.id })
                break;
        }
    }

    const clearUserFilter = async (e) => {
        const type = e.target.dataset.type;

        switch (type) {
            case 'AManger':
                setAManagerFilterId('0')
                setAManagerByFilterImg('0')
                break;
            case 'SRecruters':
                setreCcInfoFilterId('0')
                setreCcInfoFilterImg('0')
                break;

        }
    }
    const [caseStatusFilterDDLText, setCaseStatusFilterDDLText] = useState('Progress')

    const clearAllFilter = async () => {
        await dispatch(getList({ year: atob(lastSegment), type: 'final-fee' }))
        setFilterTags([])
        setAManagerFilterId('0')
        setAManagerByFilterImg('0')
        setreCcInfoFilterId('0')
        setreCcInfoFilterImg('0')
        setMonthsId('0')
        setProjectId('0')
        setProgressId('0')
        setCompanyId('0')
        setLanguageId('0')
        setRoleTypeId('0')
        setcvSourceId('0')
        setCaseStatusFilterText('Progress')
        setMonthsFilterText('Month')
        setCaseTypeId('0')
        setCaseStageId('0')
        setCaseStatusId('0')
        setCasePayTypeId('0')
        setMonthsFilterId('0')
        setMonthsId('0')
        setBgMonthcolor("#fff")
        setMonthcolor("#6c757d")
        setCaseStatusFilterId('0')
        setFilterTags([])
    }

    const [filterTags, setFilterTags] = useState([]);
    const [taskStatusFilterText, setTaskStatusFilterText] = useState('CATEGORY')
    //const [caseStatusFilterText, setCaseStatusFilterText] = useState('PAY STATUS')
    const [paymentTypeFilterText, setPaymentTypeFilterText] = useState('PAY TYPE')
    const [caseTypeId, setCaseTypeId] = useState('0');
    const [caseStatusId, setCaseStatusId] = useState('0')
    const [caseStageId, setCaseStageId] = useState('0')
    const [reasonId, setReasonId] = useState('0')
    const [casePayTypeId, setCasePayTypeId] = useState('0')
    const [monthsFilterText, setMonthsFilterText] = useState('Month')
    const [monthsFilterId, setMonthsFilterId] = useState('0')

    const [caseStatusFilterText, setCaseStatusFilterText] = useState('Progress')
    const [caseStatusFilterId, setCaseStatusFilterId] = useState('0')

    const [bgMonthcolor, setBgMonthcolor] = useState('#fff')
    const [monthcolor, setMonthcolor] = useState('#6c757d')

    const selectStatusFilter = async (e) => {
        const type = e.target.dataset.type;
        const value = e.target.dataset.value;
        let i;

        switch (type) {
            case 'paymentType':
                i = filterTags.findIndex(function (x) { return x.type === 'paymentType' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setCaseTypeId(value)
                setTaskStatusFilterText(value)
                break;
            case 'payType':
                i = filterTags.findIndex(function (x) { return x.type === 'payType' })

                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setCasePayTypeId(value)
                setPaymentTypeFilterText(value)
                break;

        }
    }

    const onSelectStatusFilter = (eventKey) => {
        setCaseStatusFilterText(eventKey)
        setCaseStatusId(eventKey)
    }

    const onStatusFilter = (e) => {
        //const type = e.target.dataset.type;
        const value = e.target.dataset.value;
        setCaseStatusFilterText(value)
        setCaseStatusFilterId(value)
    }

    const onSelectMonthFilter = (eventKey) => {
        setMonthsFilterText(eventKey)
        setMonthsFilterId(eventKey)
        switch (eventKey) {
            case 'January':
                setBgMonthcolor('#884c5e')
                setMonthcolor('#fff')
                break
            case 'February':
                setBgMonthcolor('#26596d')
                setMonthcolor('#fff')
                break
            case 'March':
                setBgMonthcolor('#5a4c72')
                setMonthcolor('#fff')
                break
            case 'April':
                setBgMonthcolor('#3c4c62')
                setMonthcolor('#fff')
                break
            case 'May':
                setBgMonthcolor('#5c737d')
                setMonthcolor('#fff')
                break
            case 'June':
                setBgMonthcolor('#4c5f59')
                setMonthcolor('#fff')
                break
            case 'July':
                setBgMonthcolor('#01796f')
                setMonthcolor('#fff')
                break
            case 'August':
                setBgMonthcolor('#144773')
                setMonthcolor('#fff')
                break
            case 'September':
                setBgMonthcolor('#396a95')
                setMonthcolor('#fff')
                break
            case 'October':
                setBgMonthcolor('#535255')
                setMonthcolor('#fff')
                break
            case 'November':
                setBgMonthcolor('#b55355')
                setMonthcolor('#fff')
                break
            case 'December':
                setBgMonthcolor('#3c97b6')
                setMonthcolor('#fff')
                break
            default:
                break;

        }
    }

    const [modalShow, setModalShow] = React.useState(false);
    const [monthsId, setMonthsId] = useState('0');
    const [projectId, setProjectId] = useState('0');
    const [progressId, setProgressId] = useState('0');
    const [companyId, setCompanyId] = useState('0');
    const [languageId, setLanguageId] = useState('0');
    const [roleTypeId, setRoleTypeId] = useState('0');
    const [cvSourceId, setcvSourceId] = useState('0');

    const selectOtherFilter = async (e) => {
        const type = e.target.dataset.type;
        const value = e.target.dataset.value;

        let i;
        switch (type) {
            case 'reason':
                i = filterTags.findIndex(function (x) { return x.type === 'reason' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setReasonId(value)
                break;
            case 'stage':
                i = filterTags.findIndex(function (x) { return x.type === 'stage' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setCaseStageId(value)
                break;
            case 'caseStatus':
                i = filterTags.findIndex(function (x) { return x.type === 'caseStatus' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setCaseStatusId(value)
                setCaseStatusFilterDDLText(value)
                break;

            case 'month':
                i = filterTags.findIndex(function (x) { return x.type === 'month' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setMonthsId(value)
                break;

            case 'language':
                i = filterTags.findIndex(function (x) { return x.type === 'language' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setLanguageId(value)
                break;
            case 'cvSource':
                i = filterTags.findIndex(function (x) { return x.type === 'cvSource' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setcvSourceId(value)
                break;

            case 'roleType':
                i = filterTags.findIndex(function (x) { return x.type === 'roleType' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setRoleTypeId(value)
                break;
            case 'project':
                i = filterTags.findIndex(function (x) { return x.type === 'project' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setProjectId(value)
                break;

            case 'progress':
                i = filterTags.findIndex(function (x) { return x.type === 'progress' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setProgressId(value)
                break;

            case 'company':
                i = filterTags.findIndex(function (x) { return x.type === 'company' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setCompanyId(value)
                break;

            default:
                i = filterTags.findIndex(function (x) { return x.type === type })
                if (i === -1) {
                    filterTags.push({ type, value })
                }
                break;
        }
    }

    const removeTag = async (e) => {
        const type = e.target.dataset.type;
        setFilterTags(filterTags.filter((item) => item.type !== type))

        switch (type) {
            case 'paymentType':
                setCaseTypeId('0')
                setTaskStatusFilterText('CATEGORY')
                break;

            case 'progress':
                setProgressId('0')
                break;
            case 'project':
                setProjectId('0')
                break;
            case 'company':
                setCompanyId('0')
                break;
            case 'language':
                setLanguageId('0')
                break;
            case 'roleType':
                setRoleTypeId('0')
                break;
            case 'cvSource':
                setcvSourceId('0')
                break;
            case 'reason':
                setReasonId('0')
                break;
            case 'stage':
                setCaseStageId('0')
                break;
            case 'status':
                setCaseStatusId('0')
                setCaseStatusFilterText('PAY STATUS')
                break;
            case 'payType':
                setCasePayTypeId('0')
                setPaymentTypeFilterText('PAY TYPE')
                break;
            case 'caseStatus':
                setCaseStatusId('0')
                setCaseStatusFilterDDLText('Progress')
                break;
            case 'month':
                setMonthsFilterId('0')
                setMonthsFilterText('Month')
                setMonthsId('0')
                setBgMonthcolor("#fff")
                setMonthcolor("#6c757d")
                break;
            case 'caseprogress':
                setCaseStatusFilterId('0')
                setCaseStatusFilterText('Progress')
                break;
            default:
                break;
        }
    }

    const moveRecords = async (e) => {
        SetDataMoveTo(e.target.dataset.moveto)
    }

    useEffect(() => {
        if (dataMoveTo !== '') {
            (async () => {
                var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
                if (checkedBoxCount == 0) {
                } else {
                    var canIds = [];
                    $.each($(".chkCandidate:checked"), function () {
                        canIds.push($(this).val());
                    });
                    await dispatch(moveRecordsToPaid({ id: dataRowId, status: "Fully Paid" }))
                    $('.chkCandidate').prop('checked', false);
                    setIsActive(false);
                }
            })();
        }
    }, [dataMoveTo])

    const [isShowUpdate, setShowUpdate] = useState(false)
    const showUpdate = () => {
        setShowUpdate(true)
    }

    const SearchUpdate = () => {
        setShowUpdate(false)
    }


    const [columnKey, setColumnKey] = useState('')
    const [columnValue, setColumnValue] = useState('0')
    const [isUpdatePop, setIsUpdatePop] = useState(false)

    const openUpdateValuePop = (id, key, value) => {
        setRowId(id)
        setColumnKey(key)
        setColumnValue(value)
        setShowUpdate(true)
    }
    const hideUpdateValuePop = () => {
        setShowUpdate(false)
    }


    return (
        <>
            {
                (isShowSearch) ? <AdvancedFilter onHide={SearchClose} headerTitle="Search Sales Books" list={list} selectUserFilter={selectUserFilter} selectOtherFilter={selectOtherFilter} pageType='final-fee' /> : null
            }

            {
                (isShowUpdate) ? <UpdateModal onHide={hideUpdateValuePop} rowId={rowId} columnKey={columnKey} value={columnValue} /> : null
            }

            {
                (isShowAddNew) ? <AddNew onHide={SearchAddNew} currentYear={atob(lastSegment)} currentType="final-fee" /> : null
            }
            {
                (showEdit) ? <EditDetails onHide={closeEditModal} currentYear={atob(lastSegment)} currentType="final-fee" /> : null
            }

            <div className='dk-bOverviewyears-containerMain dkg-business-overview-pageCon'>
                <div className='dk-bOverviewyears-rightPanel'>
                    <div className="dk-sendoutsHead">
                        <div className="dk-sendoutsyears-yearsDropdown">
                            <div className="dk-searchBox">

                                {
                                    (instanceData !== '0') ? <GlobalFilter
                                        preGlobalFilteredRows={instanceData.preGlobalFilteredRows}
                                        globalFilter={instanceData.state.globalFilter}
                                        setGlobalFilter={instanceData.setGlobalFilter}
                                        isClearAll={isClearAll}
                                        restClearAllFilter={restClearAllFilter}
                                    /> : null
                                }
                            </div>

                            <div className="dk-sendoutsAmRc-dropdown">
                                <div className='taskForCrossBtn mr-2' style={{ position: "relative" }}>
                                    <RecrutersDropdown list={list} id={reCcInfoFilterId} img={reCcInfoFilterImg} selectUserFilter={selectUserFilter} />
                                    {
                                        (reCcInfoFilterId !== '0') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="SRecruters" onClick={clearUserFilter}></i> : null
                                    }
                                </div>
                                <div className='taskForCrossBtn' style={{ position: "relative" }}>
                                    <AccountManagerDropdown list={list} id={AManagerFilterId} img={AManagerByFilterImg} selectUserFilter={selectUserFilter} />
                                    {
                                        (AManagerFilterId !== '0') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="AManger" onClick={clearUserFilter}></i> : null
                                    }
                                </div>
                            </div>
                            <Link to="#" className="addMonthBtn" onClick={showAddNew} ><i className="fas fa-plus"></i></Link>
                        </div>

                        <div className='dk-recwallet-centerCon'>
                            <div className='dk-filterTagsCon'>
                                {
                                    (filterTags.length > 0) ?
                                        filterTags.length > 0 && filterTags.map((item, index) =>

                                            <ButtonGroup className='dk-filterbtnGrp mr-2' key={`tagButton${index}`}>
                                                <Button className="dk-filterbtntext">{item.value}</Button>
                                                <Button className="dk-filterbtnicon" data-type={item.type} onClick={removeTag}><i className="fas fa-times" data-type={item.type} onClick={removeTag}></i></Button>
                                            </ButtonGroup>

                                        )
                                        :
                                        <div className='dkg-pagetitle'>Final Fee {atob(lastSegment)}
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="dk-sendoutyears-rightPanel-btn">
                            <div className="dk-sensdouts-searchCols">
                                <button onClick={clearAllFilter}><FaSyncAlt /></button>
                            </div>
                            <div className="dk-sensdouts-searchCols">
                                <button onClick={showSearch} ><FaFilter /></button>
                            </div>
                            <div className='pipeline-cafileterCon dkg-business-rightCon'>
                                <div className='dkg-monthfilterCon mr-2'>
                                    <CaseStatusFilter list={list} caseStatusFilterDDLText={caseStatusFilterText} selectOtherFilter={onStatusFilter} />
                                    {
                                        (caseStatusFilterText !== 'Progress') ? <i className="fas fa-times-circle dk-lrftCross" data-type="caseprogress" onClick={removeTag}></i> : null
                                    }
                                </div>
                                <div className='dkg-monthfilterCon'>
                                    <MonthFilterDropdown list={list} caseStatusFilterText={monthsFilterText} onSelectStatusFilter={onSelectMonthFilter} tableType={'account_interviews'} bgMonthcolor={bgMonthcolor} monthcolor={monthcolor} />
                                    {
                                        (monthsFilterText !== 'Month') ? <i className="fas fa-times-circle dk-lrftCross" data-type="month" onClick={removeTag}></i> : null
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='dk-bOverviewyears-table'>
                        <Table
                            data={data}
                            columns={columns}
                            getInstanceCallback={reactTableInstance}
                            loading={isLoading}
                            updateMyData={updateMyData}
                            AManagerFilterId={AManagerFilterId}
                            reCcInfoFilterId={reCcInfoFilterId}
                            caseTypeId={caseTypeId}
                            caseStatusId={caseStatusId}
                            caseStageId={caseStageId}
                            reasonId={reasonId}
                            casePayTypeId={casePayTypeId}
                            monthsFilterId={monthsFilterId}
                            caseStatusFilterId={caseStatusFilterId}
                            monthsId={monthsId}
                            progressId={progressId}
                            companyId={companyId}
                            projectId={projectId}
                            languageId={languageId}
                            roleTypeId={roleTypeId}
                            cvSourceId={cvSourceId}
                        />
                    </div>

                </div>
            </div>
            <div className={`batch-actions-menu-wrapper dk-action-popupMain ${isActive ? "activePopup" : ""}`} style={{ width: 'auto' }}>
                <div className="num-of-actions_wrapper">
                    <div className='num-of-actions'>{countCheck}</div>
                </div>
                <div className="batch-actions-title-section pl-0">
                    <div className='dk-rowSelected'>
                        <span>Row Selected</span>
                    </div>
                </div>

                <div className="batch-actions-item dk-checkAll" onClick={editFunction}>
                    <span onClick={editFunction}><i className="far fa-edit"></i></span>
                    Edit
                </div>

                <DeleteModal loading={loading} success={success} deleteItem={deleteItemBulk} tableName="account_interviews" />
                <div className="batch-actions-delete-item CloseAll d-flex align-items-center justify-content-center" onClick={handleToggle}>
                    <span><i className="fas fa-times"></i></span>
                </div>
            </div>
        </>
    )
}

export default FinalFee;