import React, { useEffect, useState, useCallback } from 'react'; 
import { ListBlock } from "./ListBlock";
import { FaEnvelope, FaFlag } from "react-icons/fa";

const List = ({ 
    label, 
    list, 
    deleteMailSingle, 
    setAllCheckedMail, 
    getAllCheckedMail, 
    handleUnReadIconClick,
    isGetOnlyUnread, 
    markImportant,
    handleFlaggedIconClick,
    isFlagged
}) => {

    const [isCheckAll, setIsCheckAll] = useState(false);

    const handleCheckbox = (e) => {
        setAllCheckedMail([]);
        setIsCheckAll(e.target.checked);
        if (e.target.checked) {
            setAllCheckedMail(list.map((item) => item.id));
        }
    }

    useEffect(() => {
        if (getAllCheckedMail.length === 0) {
            setIsCheckAll(false);
        } else {
            setIsCheckAll(true);
        }
    }, [getAllCheckedMail]);

    useEffect(() => {
        setIsCheckAll(false);
    }, [label]);

    return (
        <React.Fragment>
            <div className="dk-titleHead">
                <div className="sbtitle">{label}</div>
                <div className="dk-mailsIndigator">
                    {/* <small>Today</small> */}
                    <span className='dkg-checkbox-flag-Con'>
                        <span className='dkg-flag-icon'
                            onClick={handleUnReadIconClick}
                        >
                            <FaEnvelope 
                                style={{color: isGetOnlyUnread == 'unread' ? '#3199B7' : '#727272'}}
                            />
                        </span>
                        <span 
                            className='dkg-flag-icon' style={{marginRight: "12px"}}
                            onClick={handleFlaggedIconClick}
                        >
                            <FaFlag 
                                style={{color: isFlagged == 'flagged' ? '#DA3D5E' : ''}}
                            />
                        </span>
                        <input 
                            type='checkbox' 
                            className='form-control' 
                            checked={isCheckAll}
                            value={isCheckAll}
                            onChange={handleCheckbox}
                        />
                    </span>
                </div>
            </div>
            <ListBlock 
                list={list} 
                label={label} 
                deleteMailSingle={deleteMailSingle} 
                isCheckAll={isCheckAll} 
                setAllCheckedMail={setAllCheckedMail} 
                getAllCheckedMail={getAllCheckedMail}
                markImportant={markImportant}
            />
        </React.Fragment>
    )
}
export default List;
