import React from 'react';
import './casestatus.scss';

const FInalFeeCaseStatus = ({ caseStatus, value }) => {
    let bgColor = "";
 
    switch (caseStatus) {
        
        case 'Full Fee':
            bgColor = '#63745c'
            break;       
        case 'Partial Fee':
            bgColor = '#63745c'
            break; 

        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor }}>
                {
                    (value == "Blank") ? "" : value
                }
            </div>
        </>
    )
}

export default FInalFeeCaseStatus;