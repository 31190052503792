import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import './myacc-payments.scss';
import TaskBy from './tablefilters/TaskBy';
import TaskFor from './tablefilters/TaskFor';
import PaymentCategory from './tablefilters/PaymentCategory';
import StatusFilterDropdown from './tablefilters/StatusFilterDropdown';
import AddNewPaymentModal from './modal/AddNewPayment';

const Payment = () => {
    return (
        <>
            <div className='dkg-myacc-payments-main-Con container-fluid'>
                <div className='row dkg-payments-mainCon'>
                    <div className='col-md-12  dkg-payments-boxCon'>
                        <div className='dk-myacc-payments-TopHead'>
                            <div className='dk-myacc-payments-LeftTopHead'>
                                <div className="dk-SrchInputBox mr-2">
                                    <div className="input-group">
                                        <input className="form-control" placeholder="Search ..." value="" />
                                        <div className="input-group-append">
                                            <button className="btn btn-secondary" type="button">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mr-2" style={{ position: "relative" }}>
                                    <TaskBy />
                                </div>
                                <div className="mr-2" style={{ position: "relative" }}>
                                    <TaskFor />
                                </div>
                                <div className='mr-2 dkg-addnew-paymentbtn taskForCrossBtn'>
                                    <AddNewPaymentModal />
                                </div>
                            </div>
                            <div className='dk-myacc-payments-HeadertitleCon'>
                                <h2 className='dk-myacc-payments-title'> Payment Task</h2>
                            </div>
                            <div className='dk-myacc-payments-rightTopHead'>
                                <div className="mr-2 dkg-refresh-paymentbtnCon taskForCrossBtn">
                                    <button className="dk-cricleBtn">
                                        <i className="fas fa-sync-alt"></i>
                                    </button>
                                </div>
                                <div className='dk-categoryfilterCon dkg-category-drop-bg mr-2'>
                                    <StatusFilterDropdown />
                                </div>
                                <div className='dk-categoryfilterCon dkg-category-drop-bg'>
                                    <PaymentCategory />
                                </div>
                            </div>
                        </div>
                        <div className='dkg-taskpayment-tableCon-12'>
                            <table className='table table-borderd dkg-payment-table'>
                                <thead>
                                    <tr>
                                        <th>SNo.</th>
                                        <th>Created</th>
                                        <th>By</th>
                                        <th>For</th>
                                        <th>PAY STATUS</th>
                                        <th>CATEGORY</th>
                                        <th>Pay Type</th>
                                        <th>PAYMENT TO</th>
                                        <th>MONTH</th>
                                        <th>INVOICE NO.</th>
                                        <th>PAY AMOUNT</th>
                                        <th>Comment</th>
                                        <th>TASK DUE ON</th>
                                        <th>File</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="dk-checkinputIconCon">1
                                                <div className="dk-checkinputIcon">
                                                    <input type="radio" className="chkCandidate" name="chkCandidate[]" data-id="100" value="100" />
                                                </div>
                                            </span>
                                        </td>
                                        <td>
                                            18 Aug 22
                                        </td>
                                        <td>
                                            <div className="dk-payment-imgCon">
                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/1580424792.jpeg" alt="Recruiter" className="dk-payment-img" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-payment-imgCon">
                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="Recruiter" className="dk-payment-img" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-paymentstusdropdowncon-14'>
                                                <Dropdown className='dk-workplanstatus-Dropdownon12 dkg-payment-statusdropdown-24'>
                                                    <Dropdown.Toggle variant="" className='dk-wpstatus-Dropdown-Toggle dkg-payment-status-dropdown-toggle-24'>
                                                        <div className='caseDiv' style={{ backgroundColor: '#d58b5d', color: '#fff', minWidth: '80px' }}> New Task</div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className='dk-wpstatus-Dropdown-Menu'>
                                                        <Dropdown.Item href="#" className='dk-wpstatus-Dropdown-Item' style={{ backgroundColor: "#d58b5d", color: "#fff" }}>New Task</Dropdown.Item>
                                                        <Dropdown.Item href="#" className='dk-wpstatus-Dropdown-Item' style={{ backgroundColor: "#625c60", color: "#fff" }}>Queries</Dropdown.Item>
                                                        <Dropdown.Item href="#" className='dk-wpstatus-Dropdown-Item' style={{ backgroundColor: "#26597d", color: "#fff" }}>Accepted</Dropdown.Item>
                                                        <Dropdown.Item href="#" className='dk-wpstatus-Dropdown-Item' style={{ backgroundColor: "#715f8f", color: "#fff" }}>Partially Paid</Dropdown.Item>
                                                        <Dropdown.Item href="#" className='dk-wpstatus-Dropdown-Item' style={{ backgroundColor: "#3a9469", color: "#fff" }}>Fully Paid</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dkg-paymentstusdropdowncon-14'>
                                                <Dropdown className='dk-workplanstatus-Dropdownon12 dkg-payment-statusdropdown-24'>
                                                    <Dropdown.Toggle variant="" className='dk-wpstatus-Dropdown-Toggle dkg-payment-status-dropdown-toggle-24 dkg-pt-priority-textUpperCase' style={{ minWidth: "100px !important", maxWidth: "100px !important" }} >
                                                        <div className='caseDiv' style={{ backgroundColor: 'transparent', color: '#d58b5d', minWidth: '80px' }}>Normal</div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className='dk-wpstatus-Dropdown-Menu dkg-pt-priority-dropmenu-234'>
                                                        <Dropdown.Item href="#" style={{ backgroundColor: "transparent", color: "#3a9469" }}>Normal</Dropdown.Item>
                                                        <Dropdown.Item href="#" style={{ backgroundColor: "transparent", color: "#d91010" }}>Urgent</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </td>
                                        <td>
                                            DK IT Team
                                        </td>
                                        <td>
                                            Monthy Salary
                                        </td>
                                        <td>
                                            Ajay Chauhan
                                        </td>
                                        <td>
                                            DKAC220701
                                        </td>
                                        <td>
                                            50 €
                                        </td>
                                        <td>
                                            <span className="commentBoxCon"><span className="numCon">3</span></span>
                                        </td>
                                        <td>20 Aug 22</td>
                                        <td>---</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payment;