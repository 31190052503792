import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData } from "../../services";

export const addValue = createAsyncThunk(
    'kpiSendout/team/add',
    async ({ year, month, uid, clientGroup, sendoutTarget, sendoutMade }, thunkAPI) => {
        const res = await PostData(true, '/sendout-kpis', { year, month, uid, clientGroup, sendoutTarget, sendoutMade })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)


export const updateValue = createAsyncThunk(
    'kpiSendout/team/update',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/sendout-kpis/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateUser = createAsyncThunk(
    'kpiSendout/team/updateUser',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/sendout-kpis/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const recrutersListTable = createAsyncThunk(
    'kpiSendout/team/rclist',
    async ({ year, month }, thunkAPI) => {
        const res = await GetData(true, '/sendout-kpis/recruters/' + year + '/' + month)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const listAll = createAsyncThunk(
    'kpiSendout/team/list',
    async ({ year, month }, thunkAPI) => {
        const res = await GetData(true, '/sendout-kpis/' + year + '/' + month)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const annualSaleList = createAsyncThunk(
    'kpiSendout/teamAnnual/list',
    async ({ year }, thunkAPI) => {
        const res = await GetData(true, '/sendout-kpis/' + year )
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const annualMonthlySaleList = createAsyncThunk(
    'kpiSendout/teamMonthlyAnnual/list',
    async ({ year,month }, thunkAPI) => {
        const res = await GetData(true, '/sendout-kpis/monthly/' + year )
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const bulkDelete = createAsyncThunk(
    'kpiSendout/team/bulkDelete',
    async ({ year, month }, thunkAPI) => {
        const res = await GetData(true, '/sendout-kpis/' + year + '/' + month)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const SendoutKpiSlice = createSlice({
    name: 'sendout_kpis',
    initialState: {
        dataList: [],
        clientKpiDataList: [],
        annualDataList: [],
        RCDataList: [],
        annualMonthlyDataList: [],
        userData: {},
        isRCLoading: false,
        isLoading: false,
        isError: false,
        isSuccess: false,
        message: '',
        isBulkDelete: false,
    },
    reducers: {
        clearState: (state) => {
            state.isRCLoading = false;
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.message = '';
            state.isBulkDelete = false;
            state.isUpdate = false;
        },
        bulkDeleteState: (state) => {
            state.isDelete = true
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            addValue.pending, (state) => {
            });
        builder.addCase(
            addValue.fulfilled, (state, { payload }) => {
                state.dataList.push(payload.result)
                state.isSuccess = true;
            });
        builder.addCase(
            addValue.rejected, (state, action) => {
                state.isSuccess = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
  

        builder.addCase(
            updateValue.pending, (state) => {
            });
        builder.addCase(
            updateValue.fulfilled, (state, { payload }) => {
                let mainArray = state.dataList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.dataList = finalResult;
            });
        builder.addCase(
            updateValue.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            recrutersListTable.pending, (state) => {
                state.isRCLoading = true;
            });
        builder.addCase(
            recrutersListTable.fulfilled, (state, { payload }) => {
                state.RCDataList = payload.result
                state.isRCLoading = false;
            });
        builder.addCase(
            recrutersListTable.rejected, (state, action) => {
                state.isRCLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            listAll.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            listAll.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            listAll.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
       
       builder.addCase(
            annualSaleList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            annualSaleList.fulfilled, (state, { payload }) => {
                state.annualDataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            annualSaleList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

       builder.addCase(
            annualMonthlySaleList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            annualMonthlySaleList.fulfilled, (state, { payload }) => {
                state.annualMonthlyDataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            annualMonthlySaleList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateUser.pending, (state) => {
                //  state.isLoading = true;
            });
        builder.addCase(
            updateUser.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isUpdate = true;
                let mainArray = state.dataList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.dataList = finalResult
                state.msg = payload.message
            });
        builder.addCase(
            updateUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });


        builder.addCase(
            bulkDelete.pending, (state) => {
                //  state.isLoading = true;
            });
        builder.addCase(
            bulkDelete.fulfilled, (state, action) => {
                state.dataList = state.dataList.filter((item) => item.id !== action.meta.arg.id);
                state.isDelete = true;
            });
        builder.addCase(
            bulkDelete.rejected, (state, { payload }) => {
                state.isError = payload.message;
                state.errorMessage = payload.message;
            });
    }
});
export const { clearState, bulkDeleteState } = SendoutKpiSlice.actions;
export default SendoutKpiSlice.reducer;