import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import "../responsiveMenu.scss";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import './mainmenusubmenuc.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const KpiSubmenu = (props) => {
    const classes = useStyles();
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    const [toggleMenu, setToggleMenu] = useState(false);
    useEffect(() => {
        setToggleMenu(false)
    }, [pathname])

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setToggleMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);

    return (
        <React.Fragment>
            <div className="dk-subMenuMain dkg-mainModuleSubmenu res-sub-menu" ref={ref}>
                <IconButton aria-label="delete" className="res-sub-btn" onClick={() => setToggleMenu(!toggleMenu)}>
                    <i className="fas fa-bars"></i>
                </IconButton>
                <Route>
                    <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
                        {/* <li><Link to="/kpis/kpi-summary" className={(segment2 == 'kpi-summary') ? 'active' : ''}>Summary</Link></li>
                        <li><Link to="/kpis/recruiters/summary" className={`${(segment2 == 'recruiters') ? 'active recruiters-bg' : ''}`}>Recruiters</Link></li>*/}
                        <li><Link to="/kpis/summary" className={(segment2 == 'summary') ? 'active' : ''}> Summary</Link></li>
                        <li><Link to="/kpis/clients-kpi" className={`${(segment2 == 'clients-kpi') ? 'active' : ''}`}>Clients</Link></li>
                        <li><Link to="/kpis/clients-sales-kpi" className={`${(segment2 == 'clients-sales-kpi') ? 'active' : ''}`}>Sales</Link></li>
                        <li><Link to="/kpis/recuiters-sale" className={`${(segment2 == 'recuiters-sale') ? 'active' : ''}`}>AM Sale</Link></li>
                        <li><Link to="/kpis/recruiter-new" className={(segment2 == 'recruiter-new') ? 'active' : ''}>RC Sale</Link></li>
                        <li><Link to="/kpis/sendouts" className={`${(segment2 == 'sendouts') ? 'active' : ''}`}>AM Sendout</Link></li>
                        <li><Link to="/kpis/rc-sendouts" className={`${(segment2 == 'rc-sendouts') ? 'active' : ''}`}>RC Sendout</Link></li>
                        <li><Link to="/kpis/performance" className={(segment2 == 'performance') ? 'active' : ''}>PR Bonus</Link></li>
                        <li><Link to="/kpis/sendout" className={(segment2 == 'sendout') ? 'active' : ''}>SO Bonus</Link></li> 
                        <li><Link to="/kpis/placements" className={(segment2 == 'placements') ? 'active' : ''}>PL Bonus</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default KpiSubmenu;
