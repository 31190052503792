import React from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


const GetStartedLeftPanel = () => {
    let pathname = window.location.pathname;
    const dispatch = useDispatch();

    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    return (
        <>
            <div className="dk-moduleTraining-leftPanel">
                <ul>
                    {/* <li><Link to="/js-account/emp-model/overview" className={`${(segment3 === 'overview') ? 'active' : ''}`}>Overview</Link></li> */}
                    <li><Link to="/js-account/emp-model/candidates" className={`${pathname.match('/js-account/emp-model/candidates') ? 'active' : ''}`}>Model 1</Link></li>
                    <li><Link to="/js-account/emp-model/crm-license" className={`${pathname.match('/js-account/emp-model/crm-license') ? 'active' : ''}`}>Model 2</Link></li>
                    <li><Link to="/js-account/emp-model/calls-sms" className={`${pathname.match('/js-account/emp-model/calls-sms') ? 'active' : ''}`}>Model 3</Link></li>
                </ul>
            </div>
        </>
    )
}

export default GetStartedLeftPanel;
