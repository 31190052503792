import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import '../style.scss';

const ResourceHrMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-recruitmentNavbar dk-trainingMainMenu dk-mainModuleTrainingMenu">
                <Route>
                    <ul>
                        <li>
                            <Link to="/resource/resource-hr/dashboard" className={`${(segment3 === 'dashboard') ? 'active' : ''}`}>Dashboard</Link>
                        </li>
                        <li><Link to="#" className={`${(segment2 === '') ? '' : ''}`}>Employees</Link></li>
                        <li><Link to="#" className={`${(segment2 === '') ? '' : ''}`}>Clients</Link></li>
                        <li><Link to="#" className={`${(segment2 === '') ? '' : ''}`}>Resource</Link></li>
                        <li><Link to="#" className={`${(segment2 === '') ? '' : ''}`}>Contracts</Link></li>
                        <li><Link to="#" className={`${(segment2 === '') ? '' : ''}`}>Leaves</Link></li>
                        <li><Link to="#" className={`${(segment2 === '') ? '' : ''}`}>New Hire</Link></li>
                        <li><Link to="/dkglobaladmin/admintraining/onboarding/welcome" className={`${(segment3 === 'onboarding') ? 'active' : ''}`}>Onboarding</Link></li>
                        <li><Link to="#" className={`${(segment2 === '') ? '' : ''}`}>Handbook</Link></li>
                        <li><Link to="/resource/resource-hr/faq" className={`${(segment3 === 'faq') ? 'active' : ''}`}>FAQ's</Link></li>
                    </ul>
                </Route>
            </div>

        </React.Fragment>
    );
}

export default ResourceHrMenu;