import React, { useState } from 'react';
import DetailLeftPanel from "./DetailLeftPanel";
import MoreDetailModal from './MoreDetailModal';
import HeadingTitle from './HeadingTitle';
import '../trainingdetail.scss';

const Schedule = () => {
    const [isShowMore, setIsShowMore] = useState(false);
    const showmoreDetails = () => {
        console.log("Show")
        setIsShowMore(true);
    }
    const hidemoreDetails = () => {
        setIsShowMore(false);
    }

    return (
        <React.Fragment>
            {
                (isShowMore) ? <MoreDetailModal handleClose={hidemoreDetails} /> : null
            }


            <HeadingTitle />

            <div className="dk-trainingDetailMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <DetailLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                <h3 className="title">Overview</h3>
                                <div className="dk-trainingInformation-cols">
                                    <div className="dk-sheduleTableMain">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Timeline</th>
                                                    <th>Trainer</th>
                                                    <th>Days</th>
                                                    <th>Duration</th>
                                                    <th>Type</th>
                                                    <th>Module</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Week 1</td>
                                                    <td>
                                                        <div className="dk-userIcon">
                                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid img-circle" alt="" />
                                                        </div>
                                                        <div className="dk-userIcon">
                                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid img-circle" alt="" />
                                                        </div>
                                                        <div className="dk-userIcon">
                                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid img-circle" alt="" />
                                                        </div>
                                                        <div className="dk-userIcon">
                                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid img-circle" alt="" />
                                                        </div>
                                                        <div className="dk-userIcon">
                                                            <img src="https://admin.dkmasterbox.com/assets/uploads/user_images/1523633534-luiza.jpg" className="img-fluid img-circle" alt="" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dk-timelineCols">Day 1</div>
                                                        <div className="dk-timelineCols">Day 2</div>
                                                        <div className="dk-timelineCols">Day 3</div>
                                                        <div className="dk-timelineCols">Day 4</div>
                                                        <div className="dk-timelineCols">Day 5</div>
                                                    </td>
                                                    <td>
                                                        <div className="dk-timelineCols">Part Time</div>
                                                        <div className="dk-timelineCols">Part Time</div>
                                                        <div className="dk-timelineCols">Part Time</div>
                                                        <div className="dk-timelineCols">Part Time</div>
                                                        <div className="dk-timelineCols">Part Time</div>
                                                    </td>
                                                    <td>
                                                        <div className="dk-timelineCols">Theoretical</div>
                                                        <div className="dk-timelineCols">Theoretical</div>
                                                        <div className="dk-timelineCols">Theoretical</div>
                                                        <div className="dk-timelineCols">Theoretical</div>
                                                        <div className="dk-timelineCols">Assessment</div>
                                                    </td>
                                                    <td>
                                                        <div className="dk-timelineCols">Onboarding</div>
                                                        <div className="dk-timelineCols">Common Training</div>
                                                        <div className="dk-timelineCols">Common Training</div>
                                                        <div className="dk-timelineCols">Job Specific Training</div>
                                                        <div className="dk-timelineCols">Queries & 1<small>st</small> Assessment</div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>Week 2</td>
                                                    <td>
                                                        <div className="dk-userIcon">
                                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid img-circle" alt="" />
                                                        </div>
                                                        <div className="dk-userIcon">
                                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid img-circle" alt="" />
                                                        </div>
                                                        <div className="dk-userIcon">
                                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid img-circle" alt="" />
                                                        </div>
                                                        <div className="dk-userIcon">
                                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid img-circle" alt="" />
                                                        </div>
                                                        <div className="dk-userIcon">
                                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid img-circle" alt="" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dk-timelineCols">Day 6</div>
                                                        <div className="dk-timelineCols">Day 7</div>
                                                        <div className="dk-timelineCols">Day 8</div>
                                                        <div className="dk-timelineCols">Day 9</div>
                                                        <div className="dk-timelineCols">Day 10</div>
                                                    </td>
                                                    <td>
                                                        <div className="dk-timelineCols">Full Time</div>
                                                        <div className="dk-timelineCols">Full Time</div>
                                                        <div className="dk-timelineCols">Full Time</div>
                                                        <div className="dk-timelineCols">Full Time</div>
                                                        <div className="dk-timelineCols">Full Time</div>
                                                    </td>
                                                    <td>
                                                        <div className="dk-timelineCols">Practical</div>
                                                        <div className="dk-timelineCols">Practical</div>
                                                        <div className="dk-timelineCols">Practical</div>
                                                        <div className="dk-timelineCols">Practical</div>
                                                        <div className="dk-timelineCols">Assessment</div>
                                                    </td>
                                                    <td>
                                                        <div className="dk-timelineCols">Job Specific Practice & Queries</div>
                                                        <div className="dk-timelineCols">Job Specific Practice & Queries</div>
                                                        <div className="dk-timelineCols">Job Specific Practice & Queries</div>
                                                        <div className="dk-timelineCols">Job Specific Practice & Queries</div>
                                                        <div className="dk-timelineCols">Final Assessment with Decision</div>
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default Schedule;

