import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './hrdashboard.scss';
import CompanyIcon from "../../../../../assets/images/building_icon.png";
import EmployeesIcon from "../../../../../assets/images/enterprise.png";
import ContractsIcon from "../../../../../assets/images/contracts.png";
import LeavesIcon from "../../../../../assets/images/calendar-white.png";
import NewHireIcon from "../../../../../assets/images/interview-wht.png";
import OnboardingIcon from "../../../../../assets/images/onboarding.png";
import HandBookIcon from "../../../../../assets/images/job-demand-white.png";
import FaqIcon from "../../../../../assets/images/information_white.png";
import UserIcon from "../../../../../assets/images/user-white.png";
import ResourceIcon from "../../../../../assets/images/resource_white.png";
import { useDispatch, useSelector } from "react-redux";
import Moduleheader from "../../../../elements/header/index";
import { setMyModule } from '../../../../../slice/modules/moduleSlice';
import { showSuccess, showError } from '../../../../../components/utils/messages/messageSlice';
// import Moduleheader from "../../../elements/header/index";
// import ModuleNavTabs from "../../trainingmodule/navtabs/ModuleNavTabs";

const HrDashboard = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        document.title = "HR Module"
    }, []);
    const { userInfo } = useSelector(state => state.auth);

    const setModule = async (myModule, moduleColor) => {
        await dispatch(setMyModule({ module: myModule, color: moduleColor }))
        switch (myModule) {
            case 'resource':
                history.push("/dkglobaladmin/resource/summary");
                break;

            case 'onboarding':
                history.push("/dkglobaladmin/onboarding/dashboard");
                break;

            default:
                break;
        }
    }
    return (
        <>
            <Moduleheader dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" linkcolor="#3c4c62" mainheaderTitle="HR" textcolor="#cf7c49" />
            <div className="dk-hrModulemain dk-hrDashboard-main">
                <div className="dk-hrModule-container" style={{ marginTop: '60px' }}>
                    <div className="title mb-2">HR DEPARTMENT</div>
                    <div className="d-flex">
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#cf7c49' }}>
                                <img src={UserIcon} alt="Employess" />
                                <p>Employess</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#cf7c49' }}>
                                <img src={CompanyIcon} alt="" />
                                <p>Clients</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#cf7c49' }}>
                                <img src={ResourceIcon} alt="" />
                                <p>Templates</p>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#cf7c49' }}>
                                <img src={NewHireIcon} alt="" />
                                <p>New Hire</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#cf7c49' }}>
                                <img src={ContractsIcon} alt="" />
                                <p>Contracts</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            {/* <Link to="#" onClick={() => setModule('onboarding', '#D58B5D')} style={{ background: '#cf7c49' }}> */}
                            <Link to="#" style={{ background: '#cf7c49' }}>
                                <img src={OnboardingIcon} alt="" />
                                <p>Onboarding</p>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex">

                        <div className="dk-hrModule-box">
                            <Link to="#" style={{ background: '#cf7c49' }}>
                                <img src={HandBookIcon} alt="" />
                                <p>Handbook</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="/dashboard/hr/leaves" style={{ background: '#cf7c49' }}>
                                <img src={LeavesIcon} alt="" />
                                <p>Leaves</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#/hr/faq" style={{ background: '#cf7c49' }}>
                                <img src={FaqIcon} alt="" />
                                <p>FAQ's </p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HrDashboard;
