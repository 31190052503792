import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import Submenu from '../../subMenu/RecConsultantsSubmenu';
import './trainingmodule.scss';

const RecruitmentConsultants = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-recruitmentTraining-nav">
                <Route>
                    <ul>
                        <li><Link to="/training/recruitmentmodule/recruitment-consultant/dashboard" className={`${(segment3 == 'recruitment-consultant') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="/trainingmodule/recruitment-consultants/business/sample" className={`${(segment3 == 'business') ? 'gray-active' : ''}`}>Business</Link></li>
                        <li><Link to="/trainingmodule/recruitment-consultants/rec-dep/sample" className={`${(segment3 == 'rec-dep') ? 'gray-active' : ''}`}>Rec Dept</Link></li>
                        <li><Link to="/trainingmodule/recruitment-consultants/job-profile/sample" className={`${(segment3 == 'job-profile') ? 'gray-active' : ''}`}>Job Profile</Link></li>
                        <li><Link to="/trainingmodule/recruitment-consultants/process/sample" className={`${(segment3 == 'process') ? 'gray-active' : ''}`}>Process</Link></li>
                        <li><Link to="/trainingmodule/recruitment-consultants/tools/sample" className={`${(segment3 == 'tools') ? 'gray-active' : ''}`}>Tools</Link></li>
                        <li><Link to="/trainingmodule/recruitment-consultants/mailbox/sample" className={`${(segment3 == 'mailbox') ? 'gray-active' : ''}`}>Mailbox</Link></li>
                        <li><Link to="/trainingmodule/recruitment-consultants/kpi/sample" className={`${(segment3 == 'kpi') ? 'gray-active' : ''}`}>KPI's</Link></li>
                        <li><Link to="/trainingmodule/recruitment-consultants/csf/sample" className={`${(segment3 == 'csf') ? 'gray-active' : ''}`}>CSF</Link></li>
                        <li><Link to="/trainingmodule/recruitment-consultants/faq/sample" className={`${(segment3 == 'faq') ? 'gray-active' : ''}`}>FAQ</Link></li>
                    </ul>
                </Route>
            </div>
            <Submenu path={pathname} />
        </React.Fragment>
    );
}

export default RecruitmentConsultants;