import React from 'react';
import { Link } from "react-router-dom";
// import SelectItem from '../../../../ui/selectpicker/SelectItem';
import SelectItem from '../../../../ui/SelectPicker/DummySelectPiker'
import { DropdownButton, Dropdown } from 'react-bootstrap';

const Agreements = () => {
    const category = [
        { key: 'Category_1', value: 'Category_1', text: 'Category 1' },
        { key: 'Category_2', value: 'Category_2', text: 'Category 2' },
        { key: 'Category_3', value: 'Category_3', text: 'Category 3' },
        { key: 'Category_4', value: 'Category_4', text: 'Category 4' },
        { key: 'Category_5', value: 'Category_5', text: 'Category 5' }
    ]
    return (
        <React.Fragment>
            <div className="row pr-3">
                <div className="col-md-4">
                    <div className="dkclietopLeft d-flex">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search by Text" />
                            <div className="input-group-append">
                                <button className="btn btn-secondary legitRipple" type="button">
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <Link id="" to="#" className=" dk-topTbleIcon ml-2">
                            <i className="fa fa-plus" aria-hidden="true"></i>
                        </Link>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="dk-cliDetTabPaneBlkCon">
                        <div className="dk-cliDetTitle">
                            <h2 className="mb-0 mt-2">Agreements</h2>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 dk-CliContactRight">
                    <div className="dkclietopRight">
                        <Link href="#" className=" dk-topTbleIcon mr-2">
                            <i className="fas fa-arrows-alt"></i>
                        </Link>
                    </div>
                    <div className="dk-CliDetSlectFilter pr-0">
                        <SelectItem options={category} placeholder="Category" />
                    </div>
                </div>
            </div>
            <div className="row pr-3 mt-1">
                <div className="col-md-12 col-sm-12">
                    <div className="dk-cliDetailTableCon">
                        <table className="table table-bordered dk-cliDetialTable">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>AM</th>
                                    <th>Status</th>
                                    <th>Year</th>
                                    <th>File Type</th>
                                    <th>Category</th>
                                    <th>Name of Document</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>01</td>
                                    <td>
                                        <div className="dk-cliTableImgCrle">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="person-imageFlex" alt="Manager" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-cliDorpDwon">
                                            <DropdownButton id="dropdown-basic-button" title="Active">
                                                <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </td>
                                    <td>2021</td>
                                    <td>
                                        <div className="dk-cliAgreDocType">
                                            <i className="fa fa-file" aria-hidden="true"></i>
                                        </div>
                                    </td>
                                    <td>Category 1</td>
                                    <td>Name of Document</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <Link className="dk-view-btn mr-2" to="#">
                                                <i className="fa fa-download" aria-hidden="true"></i>
                                            </Link>
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" to="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>02</td>
                                    <td>
                                        <div className="dk-cliTableImgCrle">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="person-imageFlex" alt="Manager" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-cliDorpDwon">
                                            <DropdownButton id="dropdown-basic-button" title="Active">
                                                <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </td>
                                    <td>2021</td>
                                    <td>
                                        <div className="dk-cliAgreDocType">
                                            <i className="fa fa-file" aria-hidden="true"></i>
                                        </div>
                                    </td>
                                    <td>Category 2</td>
                                    <td>Name of Document</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <Link className="dk-view-btn mr-2" to="#">
                                                <i className="fa fa-download" aria-hidden="true"></i>
                                            </Link>
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" to="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>03</td>
                                    <td>
                                        <div className="dk-cliTableImgCrle">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="person-imageFlex" alt="Manager" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-cliDorpDwon">
                                            <DropdownButton id="dropdown-basic-button" title="Active">
                                                <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </td>
                                    <td>2021</td>
                                    <td>
                                        <div className="dk-cliAgreDocType">
                                            <i className="fa fa-file" aria-hidden="true"></i>
                                        </div>
                                    </td>
                                    <td>Category 3</td>
                                    <td>Name of Document</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <Link className="dk-view-btn mr-2" to="#">
                                                <i className="fa fa-download" aria-hidden="true"></i>
                                            </Link>
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" to="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>04</td>
                                    <td>
                                        <div className="dk-cliTableImgCrle">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="person-imageFlex" alt="Manager" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-cliDorpDwon">
                                            <DropdownButton id="dropdown-basic-button" title="Active">
                                                <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </td>
                                    <td>2021</td>
                                    <td>
                                        <div className="dk-cliAgreDocType">
                                            <i className="fa fa-file" aria-hidden="true"></i>
                                        </div>
                                    </td>
                                    <td>Category 4</td>
                                    <td>Name of Document</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <Link className="dk-view-btn mr-2" to="#">
                                                <i className="fa fa-download" aria-hidden="true"></i>
                                            </Link>
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" to="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>05</td>
                                    <td>
                                        <div className="dk-cliTableImgCrle">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="person-imageFlex" alt="Manager" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-cliDorpDwon">
                                            <DropdownButton id="dropdown-basic-button" title="Active">
                                                <Dropdown.Item href="#" className="dkActiveItem">Active</Dropdown.Item>
                                                <Dropdown.Item href="#" className="dkInactiveItem">Inactive</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </td>
                                    <td>2021</td>
                                    <td>
                                        <div className="dk-cliAgreDocType">
                                            <i className="fa fa-file" aria-hidden="true"></i>
                                        </div>
                                    </td>
                                    <td>Category 5</td>
                                    <td>Name of Document</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <Link className="dk-view-btn mr-2" to="#">
                                                <i className="fa fa-download" aria-hidden="true"></i>
                                            </Link>
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" to="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Agreements;
