import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import "../responsiveMenu.scss";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import './mainmenusubmenuc.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const ClientinfoSubmenu = (props) => {
    const classes = useStyles();
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    const [toggleMenu, setToggleMenu] = useState(false);
    useEffect(() => {
        setToggleMenu(false)
    }, [pathname])

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setToggleMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);

    return (
        <React.Fragment>
            <div className="dk-subMenuMain dkg-mainModuleSubmenu res-sub-menu" ref={ref}>
                <IconButton aria-label="delete" className="res-sub-btn" onClick={() => setToggleMenu(!toggleMenu)}>
                    <i className="fas fa-bars"></i>
                </IconButton>
                <Route>
                    <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
                        <li><Link to="/clients/summary" className={(segment2 == 'summary') ? 'active' : ''}>Summary</Link></li>
                        <li><Link to="/clients/clients-list" className={`${(segment2 === 'clients-list') ? 'active' : ''}`}>Clients List</Link></li>
                        <li><Link to="/clients/companies" className={`${(segment2 === 'companies') ? 'active' : ''}`}>Companies</Link></li>
                        <li><Link to="/clients/projects" className={`${(segment2 === 'projects') ? 'active' : ''}`}>Projects</Link></li>
                        <li><Link to="/clients/role-types" className={`${(segment2 === 'role-types') ? 'active' : ''}`}>Role Types</Link></li>
                        <li><Link to="/clients/language" className={`${(segment2 === 'language') ? 'active' : ''}`}>Salaries</Link></li>
                        <li><Link to="/clients/compaines" className={`${(segment2 === 'compaines') ? 'active' : ''}`}>Contracts</Link></li>
                        <li><Link to="/clients/demands" className={`${(segment2 === 'demands') ? 'active' : ''}`}>Locations</Link></li>
                        <li><Link to="/clients/salaries" className={`${(segment2 === 'salaries') ? 'active' : ''}`}>Income Tax</Link></li>
                        <li><Link to="/clients/benefits" className={`${(segment2 === 'benefits') ? 'active' : ''}`}>Accomodation</Link></li>
                        {/* <li><Link to="/clients/demands" className={`${(segment2 === 'demands') ? 'active' : ''}`}>Demands</Link></li>
                        <li><Link to="/clients/requirements" className={`${(segment2 === 'requirements') ? 'active' : ''}`}>Requirements</Link></li>
                        <li><Link to="/clients/salaries" className={`${(segment2 === 'salaries') ? 'active' : ''}`}>Salaries</Link></li>
                        <li><Link to="/clients/benefits" className={`${(segment2 === 'benefits') ? 'active' : ''}`}>Benefits</Link></li>
                        <li><Link to="/clients/portals" className={`${(segment2 === 'job-offer') ? 'active' : ''}`}>Portals</Link></li>
                        <li><Link to="/clients/compaines" className={`${(segment2 === 'sendouts') ? 'active' : ''}`}>Sendouts</Link></li>
                        <li><Link to="/clients/interviews" className={`${(segment2 === 'interviews') ? 'active' : ''}`}>Interviews</Link></li>
                        <li><Link to="/clients/relocation" className={`${(segment2 === 'relocation') ? 'active' : ''}`}>Feedbacks</Link></li> */}
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default ClientinfoSubmenu;
