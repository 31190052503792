import React, { useEffect, useState, useMemo, lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import './workplantodo.scss';
//import RecruiterFilter from '../dropdown/RcruiterFilter';
import TaskStatusFilter from '../dropdown/TaskStatusFilter';
import TaskTypeFilter from '../dropdown/TaskTypeFilter';
import TaskType from '../dropdown/TaskType'
import CustomDateFormat from "../../../ui/dateFormats/CustomDateFormat";
import ViewSection from '../modal/tasktitle/TaskTitle';
import CommentModal from '../../../utils/comment/';
import UserList from '../../../ui/userList';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useAsyncDebounce } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import { clearState, getTaskByProgress, deleteTask, taskMoveTO, updateByKey, updateProgress, taskTypeFetch } from "../../../../slice/task/taskSlice.js";
import TableLoader from '../../../ui/tableLoader';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaEnvelope, FaCheck, FaExclamationCircle, FaQuestionCircle, FaThumbsUp, FaPauseCircle } from 'react-icons/fa';
import TaskByFilter from '../tableFilters/users/taskByFilter';
import TaskForFilter from '../tableFilters/users/taskForFilter';
import '../dropdown/categoryfilter.scss';
import Checkbox from "../utils/checkbox";
import $ from "jquery";
import DeleteModal from "../utils/delete/BulkDelete";
import { showSuccess } from '../../../../slice/utils/message/messageSlice';
import StatusDDL from '../utils/dropdown/status';
import ProgressDDL from '../utils/dropdown/progress';
import moment from "moment-timezone";
import CandidateProfile from "../../candidate/pipeline/modal/candidateprofile";
const TableSuspence = lazy(() => import('../../../ui/tableLoader/tableSuspence'));

const useInstance = (instance) => {
    if (instance && instance.getInstanceCallback) {
        instance.getInstanceCallback(instance);
    }
};

function Table({ columns, data, loading, getInstanceCallback, filter, taskByFilterId, taskForFilterId, updateMyData, caseTypeId, caseStatusId, caseTaskTypeId }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        page,
        prepareRow,
        state,
        setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            updateMyData,
            getInstanceCallback,
            autoResetPage: false,
            autoResetSortBy: false,
            autoResetFilters: false,
            autoResetRowState: false,
            initialState: {
                pageIndex: 0, pageSize: 100, hiddenColumns: ["TASKBY", "TASKFOR", "caseStatus", "statusId"]
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy, usePagination, (hooks) => hooks.useInstance.push(useInstance)
    )


    useEffect(() => {
        if (taskByFilterId === '0') {
            setTimeout(() => {
                setFilter('TASKBY', '')
            }, 200)
        } else {
            setFilterType('TASKBY')
            doFilter(taskByFilterId);
        }
    }, [taskByFilterId])

    useEffect(() => {
        if (caseStatusId === '0') {
            setTimeout(() => {
                setFilter('status', '')
            }, 200)
        } else {
            setFilterType('status')
            doFilter(caseStatusId);
        }
    }, [caseStatusId])

    useEffect(() => {
        if (caseTaskTypeId === '0') {
            setTimeout(() => {
                setFilter('taskType', '')
            }, 200)
        } else {
            setFilterType('taskType')
            doFilter(caseTaskTypeId);
        }
    }, [caseTaskTypeId])

    useEffect(() => {
        if (caseTypeId === '0') {
            setTimeout(() => {
                setFilter('caseStatus', '')
            }, 200)
        } else {
            setFilterType('caseStatus')
            doFilter(caseTypeId);
        }
    }, [caseTypeId])

    useEffect(() => {
        if (taskForFilterId === '0') {
            setTimeout(() => {
                setFilter('TASKFOR', '')
            }, 200)
        } else {
            setFilterType('TASKFOR')
            doFilter(taskForFilterId);
        }
    }, [taskForFilterId])

    const handlePageChange = (event, value) => {
        gotoPage(Number(value) - 1);
    };

    const [filterType, setFilterType] = useState('')

    const doFilter = React.useCallback(
        useAsyncDebounce((id) => {
            setFilter(filterType, id)
        }, 200),
        [filterType]
    );

    return (
        <React.Fragment>


            <table className="table dk-workplan-todoTable" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                (loading) ?
                                    [...Array(10)].map((e, i) =>
                                        <tr>
                                            <TableLoader colSpan="22" />
                                        </tr>
                                    ) :
                                    <Suspense fallback={<TableSuspence colSpan="22" />}>
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => (
                                                <td
                                                    {...cell.getCellProps([
                                                        {
                                                            className: cell.column.className, // pay attention to this
                                                            style: cell.column.style,
                                                            // set here your other custom props
                                                        },
                                                    ])}
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    </Suspense>
                            )
                        }
                    )}
                </tbody>
                <tfoot>
                    {footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()}>
                            {group.headers.map(column => (
                                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
        </React.Fragment>
    )
}

function debounce(func, wait, immediate) {
    var timeout;
    return (...args) => {
        var context = this;
        var later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isClearAll,
    restClearAllFilter }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState("")

    const onChangeSearchTitle = (e) => {
        const { value: nextValue } = e.target;
        setValue(e.target.value)
        debouncedSave(nextValue);
    };

    const debouncedSave = React.useCallback(
        debounce(nextValue => {
            setGlobalFilter(nextValue)
        }, 1000),
    );

    const clearSearch = () => {
        setValue('')
        setTimeout(() => {
            setGlobalFilter(undefined)
        }, 200)
    }

    useEffect(() => {
        if (isClearAll) {
            clearSearch()
            restClearAllFilter();
        }
    }, [isClearAll])

    return (
        <React.Fragment>
            <input
                value={value}
                className="form-control"
                onChange={onChangeSearchTitle}
                placeholder={`Search ...`}
            />
            {
                (value !== undefined && value.length > 0) ?
                    <div className="input-group-append">
                        <button className="btn btn-secondary" type="button" onClick={clearSearch}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div> : <div className="input-group-append">
                        <button className="btn btn-secondary" type="button">
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
            }
        </React.Fragment>
    )
}

const ToDo = () => {
    const dispatch = useDispatch();
    const { isLoading, taskList, isMoved, isUpdate, message, isTaskUpdate, isTaskDelete, taskTypeList, iscommentCountUpdate } = useSelector(state => state.task);

    const [list, setList] = useState([]);
    const [data, setData] = useState([])


    const [rowId, setRowId] = useState(0)
    const [isViewModal, setIsViewModal] = useState(false)
    const [rowData, setRowData] = useState({})

    useEffect(() => {
        document.title = "To Do Tasks | Dk Global CRM"
    }, []);

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(getTaskByProgress({ status: 'To Do' }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
    }, [dispatch])

    useEffect(() => {
        if (isMoved) {
            dispatch(clearState())
            setLoading(false)
            setSuccess(false)
            dispatch(showSuccess({ msg: message }))
            const fetchList = async () => {
                try {
                    await dispatch(getTaskByProgress({ status: 'To Do' }))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }
    }, [isMoved])

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                setData(taskList)
            }, 200)
        }
        if (iscommentCountUpdate) {
            dispatch(clearState())
            setData(taskList)
        }

    }, [isLoading, isUpdate, iscommentCountUpdate, dispatch])


    useEffect(() => {
        if (isTaskUpdate) {
            dispatch(clearState())
            dispatch(getTaskByProgress({ status: 'To Do' }))
        }
    }, [isTaskUpdate])

    useEffect(() => {
        if (isUpdate) {
            dispatch(clearState())
            setLoading(false)
            setSuccess(false)
            dispatch(showSuccess({ msg: 'Task progress updated successfully!' }))
        }
    }, [isUpdate])

    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')
    const [countCheck, setCountCheck] = useState(0)
    const [isActive, setIsActive] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const checkActiveExtraDiv = (isShowExitra, checkedBoxCount) => {
        if (isShowExitra) {
            setIsActive(true)
            setCountCheck(checkedBoxCount)
        } else {
            setIsActive(false)
            setCountCheck(checkedBoxCount)
        }
    }

    const checkExtraDivBtnTxt = (btnTxt) => {
        setCheckBtnTxt(btnTxt)
    }

    const toggleCheckAll = () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
            setCountCheck(totalCheckboxes)
            $('.chkCandidate').prop('checked', true);
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Check All')
            setCountCheck(0)
            $('.chkCandidate').prop('checked', false);
            setIsActive(false)
        }
    }

    const handleToggle = () => {
        $('.chkCandidate').prop('checked', false);
        setIsActive(false);
    };

    const deleteItemBulk = async () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
        } else {
            var canIds = [];
            $.each($(".chkCandidate:checked"), function () {
                canIds.push($(this).val());
            });
            setLoading(true)
            setSuccess(false)
            await dispatch(deleteTask({ id: canIds }))
            $('.chkCandidate').prop('checked', false);
            setIsActive(false);
        }
    }

    const [dataMoveTo, SetDataMoveTo] = useState('')
    const moveRecords = async (e) => {
        SetDataMoveTo(e.target.dataset.moveto)
    }

    useEffect(() => {
        if (dataMoveTo !== '') {
            (async () => {
                var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
                if (checkedBoxCount == 0) {
                } else {
                    var canIds = [];
                    $.each($(".chkCandidate:checked"), function () {
                        canIds.push($(this).val());
                    });
                    setLoading(true)
                    setSuccess(false)

                    await dispatch(taskMoveTO({ id: canIds }))
                    $('.chkCandidate').prop('checked', false);
                    setIsActive(false);
                }
            })();
        }
    }, [dataMoveTo])


    const getTaskType = (type) => {
        switch (type) {
            case 'DK Global':
                dispatch(taskTypeFetch({ id: 144 }))
                break;
            case 'Others Task':
                dispatch(taskTypeFetch({ id: 145 }))
                break;
            case 'Accounting':
                dispatch(taskTypeFetch({ id: 155 }))
                break;
            case 'IT Team':
                dispatch(taskTypeFetch({ id: 161 }))
                break;
            case 'DK Admin':
                dispatch(taskTypeFetch({ id: 148 }))
                break;
            case 'Clients':
                dispatch(taskTypeFetch({ id: 149 }))
                break;
            case 'Candidates':
                dispatch(taskTypeFetch({ id: 150 }))
                break;
            case 'Suppliers':
                dispatch(taskTypeFetch({ id: 151 }))
                break;

        }
    }

    const [profileCanId, setProfileCanId] = useState(0)
    const [isProfileModal, setIsProfileModal] = useState(false)

    const handleCloseProfile = () => {
        setIsProfileModal(false);
    }

    const columns = useMemo(() => [
        {
            Header: 'SNo.',
            accessor: 'id',
            Cell: ({ cell }) => {
                return (
                    <span className="dk-checkinputIconCon">
                        {Number(cell.row.id) + 1}
                        <div className='dk-checkinputIcon'>
                            <Checkbox id={cell.row.original.id} checkActiveExtraDiv={checkActiveExtraDiv} checkExtraDivBtnTxt={checkExtraDivBtnTxt} />
                        </div>
                    </span>
                )
            }
        },
        {
            Header: 'Created',
            accessor: 'createdAt',
            Cell: ({ cell }) => {
                return <CustomDateFormat date={cell.row.original.createdAt} format='DD MMM, HH:mm' />
            }
        },
        {
            Header: 'By',
            accessor: 'taskByDetail.firstName',
            Cell: ({ cell }) => {
                return (
                    <div className='dk-workplanrec-imgCon'>
                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.taskByDetail.profileImage} alt='Recruiter' className='dk-workplanrec-img' />
                    </div>
                )
            }
        },
        {
            id: 'TASKFOR',
            Header: 'TASKFOR',
            accessor: data => {
                let output = [];
                data.taskForDetail.map(item => {
                    return output.push(item.forDetail.uid);
                });
                return output.join("\n");
            },
        },
        {
            Header: 'For',
           // accessor: 'taskForDetail[0].forDetail.firstName',
            accessor: 'task4Detail.firstName',
            Cell: ({ cell }) => {
                return (
                    <div className='dk-workplanrec-imgCon'>
                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.task4Detail?.profileImage} alt='Recruiter' className='dk-workplanrec-img' />
                    </div>
                )
            }
            
            // Cell: ({ cell }) => {      
            //     return (
            //         <div className='dk-workplanrec-imgCon'>
            //             {
            //                 cell.row.original.taskForDetail.map((item, index) => {
            //                     return (
            //                         <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.forDetail.profileImage} alt='Recruiter' className='dk-workplanrec-img' />
            //                     )
            //                 })
            //             }
            //         </div>
            //     )
            // }
        },
        {
            id: 'progress',
            Header: 'Status',
            accessor: 'progress',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value, img) => {
                    updateMyData(id, columnId, value, img)
                };
                return (
                    <React.Fragment>
                        <Dropdown className='dk-workplanstatus-Dropdownon12' id={`caseProgress${cell.row.original.id}`}>
                            <Dropdown.Toggle variant="" className='dk-wpstatus-Dropdown-Toggle' >
                                <ProgressDDL value={cell.row.original.progress} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='dk-wpstatus-Dropdown-Menu'>
                                <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'progress', 'To Do', '#d58b5d')} href="#" className='dk-wpstatus-Dropdown-Item' style={{ backgroundColor: "#d58b5d", color: "#fff" }}>To Do</Dropdown.Item>
                                <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'progress', 'In Progress', '#26597d')} href="#" className='dk-wpstatus-Dropdown-Item' style={{ backgroundColor: "#26597d", color: "#fff" }}>In Progress</Dropdown.Item>
                                <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'progress', 'Completed', '#3a9469')} href="#" className='dk-wpstatus-Dropdown-Item' style={{ backgroundColor: "#3a9469", color: "#fff" }}>Completed</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </React.Fragment>
                )
            }
        },
        {
            id: 'status',
            Header: 'PROGRESS',
            accessor: 'status',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value, img) => {
                    updateMyData(id, columnId, value, img)
                };
                return (
                    <React.Fragment>
                        <Dropdown className='dk-workplanstatus-Dropdownon12' id={`caseStatus${cell.row.original.id}`}>
                            <Dropdown.Toggle variant="" className='dk-wpstatus-Dropdown-Toggle'>
                                <StatusDDL value={cell.row.original.status} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='dk-wpstatus-Dropdown-Menu'>
                                <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'status', 'New Task', '#d58b5d')} eventKey="1" style={{ background: '#d58b5d', color: '#fff' }}><FaEnvelope className='mr-1' /> New Task</Dropdown.Item>
                                <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'status', 'In Action', '#26597d')} eventKey="1" style={{ background: '#26597d', color: '#fff' }}><FaCheck className='mr-1' />In Action</Dropdown.Item>
                                <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'status', 'Overdue', '#d91010')} eventKey="1" style={{ background: '#d91010', color: '#fff' }}><FaExclamationCircle className='mr-1' /> Overdue</Dropdown.Item>
                                <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'status', 'Pause', '#625c60')} eventKey="1" style={{ background: '#625c60', color: '#fff' }}><FaPauseCircle className='mr-1' /> Pause</Dropdown.Item>
                                <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'status', 'Success', '#3a9469')} eventKey="1" style={{ background: '#3a9469', color: '#fff' }}><FaThumbsUp className='mr-1' /> Success</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </React.Fragment>
                )
            }
        },

        {
            Header: 'CATEGORY',
            accessor: 'type',
            Cell: ({ cell }) => {
                const viewHandler = (id) => {
                    setProfileCanId(id)
                    setIsProfileModal(true)
                }
                if (cell.row.original.type === "Recruitment" && cell.row.original.taskType === "Candidate") {
                    return <Link to="#/" onClick={() => viewHandler(cell.row.original.subject.substr(0, cell.row.original.subject.indexOf(':')))}>{cell.row.original.type}</Link>
                } else {
                    return <div className="dk-statusText">{cell.row.original.type}</div>
                }
            }
        },

        {
            Header: 'TASK TYPE',
            accessor: 'taskType'
        },

        {
            Header: 'Subject',
            accessor: 'subject',
            Cell: ({ cell }) => {
                const viewHandler = (data) => {
                    setRowId(data.id)
                    setIsViewModal(true)
                    setRowData(data)
                    getTaskType(data.type)
                }
                return <Link to="#/" onClick={() => viewHandler(cell.row.original)}>{cell.row.original.subject}</Link>
            }
        },
        {
            Header: 'Comment',
            accessor: 'commentCount',
            className: 'pl-2 pr-2',
            Cell: ({ cell }) => {

                const commentPoupShow = (e) => {
                    setRefId(cell.row.original.id)
                    setCommentCount(cell.row.original.commentCount)
                    setCommentPopupShow(true)
                    // setCandidteStatus(cell.row.original.caseStatus)
                    // setCandidateProgess(cell.row.original.case_progress)
                    // setCandidateStage(cell.row.original.case_stage)
                }
                return (<>
                    {
                        cell.row.original.commentCount === 0 ? <span className="commentBoxCon zeroComment" onClick={commentPoupShow}>
                            <span className="numCon zeroCommentCount">
                                {cell.row.original.commentCount}
                            </span>
                        </span>
                            :
                            <span className="commentBoxCon" onClick={commentPoupShow}>
                                <span className="numCon">
                                    {cell.row.original.commentCount}
                                </span>
                            </span>
                    }
                </>)
            }
        },
        {
            Header: 'TASK DUE ON',
            accessor: 'taskDueDate',
            Cell: ({ cell }) => {
                return (
                    <React.Fragment>
                        {
                            cell.row.original.taskDueDate.substring(0, cell.row.original.taskDueDate.length - 5)
                        },

                        {moment(cell.row.original.dueTime, ["h:mm A"]).format("HH:mm")}
                    </React.Fragment>
                )
            }
        },
        {
            Header: 'STARTED',
            className: 'pl-3 pr-3',

        },
        {
            id: 'timer',
            Header: 'FINISHED',
        },

        {
            id: 'TASKBY',
            Header: 'TASKBY',
            accessor: 'taskByDetail.uid',
        },

        {
            id: 'caseStatus',
            accessor: 'type',
        },
        {
            id: 'statusId',
            accessor: 'status',
        }

    ], [])

    const [instanceData, setInstanceData] = useState('0')
    const reactTableInstance = (instance) => {
        if (instance) {
            setList(instance.flatRows)
            // console.log(instance)
            setInstanceData(instance)
        }
    };

    const updateMyData = async (id, columnId, value, img) => {
        switch (columnId) {
            case 'status':
                $(`#caseStatus${id} button .caseDiv`).html(value).css("background-color", img)
                break;

            case 'caseStatus':
                break;

            default:
                break;
        }

        if (columnId === "progress") {
            dispatch(updateProgress({ id, key: columnId, value }))
        } else {
            await dispatch(updateByKey({ id, key: columnId, value }))
            // dispatch(getTaskByProgress({ status: 'To Do' }))
        }
        // dispatch(updateByKey({ id, key: columnId, value }))
    }

    const [isClearAll, setIsClearAll] = useState(false)

    const restClearAllFilter = async () => {
        setIsClearAll(false)
    }

    const [taskByFilterId, setTaskByFilterId] = useState('0');
    const [taskByFilterImg, setTaskByFilterImg] = useState('0');

    const [taskForFilterId, setTaskForFilterId] = useState('0');
    const [taskForFilterImg, setTaskForFilterImg] = useState('0');

    const selectUserFilter = async (e) => {
        const type = e.target.dataset.type;

        switch (type) {
            case 'TASKBY':
                setTaskByFilterId(e.target.dataset.id)
                setTaskByFilterImg(e.target.dataset.img)
                // setFilter({ ...filter, [type]: e.target.dataset.id })
                break;

            case 'TASKFOR':
                setTaskForFilterId(e.target.dataset.id)
                setTaskForFilterImg(e.target.dataset.img)
                // setFilter({ ...filter, [type]: e.target.dataset.id })
                break;
        }
    }

    const clearUserFilter = async (e) => {
        const type = e.target.dataset.type;

        switch (type) {
            case 'TASKBY':
                setTaskByFilterId('0')
                setTaskByFilterImg('0')
                break;
            case 'TASKFOR':
                setTaskForFilterId('0')
                setTaskForFilterImg('0')
                break;

        }
    }

    const clearAllFilter = async () => {
        await dispatch(getTaskByProgress({ status: 'To Do' }))
        setTaskByFilterId('0')
        setTaskByFilterImg('0')
        setTaskForFilterId('0')
        setTaskForFilterImg('0')

    }

    const [filterTags, setFilterTags] = useState([]);
    const [taskStatusFilterText, setTaskStatusFilterText] = useState('CATEGORY')
    const [caseStatusFilterText, setCaseStatusFilterText] = useState('PROGRESS')
    const [taskTypeFilterText, setTaskTypeFilterText] = useState('Task Type')
    const [caseTypeId, setCaseTypeId] = useState('0');
    const [caseStatusId, setCaseStatusId] = useState('0')
    const [caseTaskTypeId, setTaskCaseTypeId] = useState('0')


    const selectStatusFilter = async (e) => {
        const type = e.target.dataset.type;
        const value = e.target.dataset.value;
        let i;
        switch (type) {
            case 'caseStatus':
                i = filterTags.findIndex(function (x) { return x.type === 'caseStatus' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setCaseTypeId(value)
                setTaskStatusFilterText(value)
                break;
            case 'taskType':
                i = filterTags.findIndex(function (x) { return x.type === 'taskType' })

                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setTaskCaseTypeId(value)
                setTaskTypeFilterText(value)
                break;

        }
    }

    const onSelectStatusFilter = (eventKey) => {
        setCaseStatusFilterText(eventKey)
        setCaseStatusId(eventKey)
    }

    const removeTag = async (e) => {
        const type = e.target.dataset.type;

        setFilterTags(filterTags.filter((item) => item.type !== type))

        switch (type) {
            case 'caseStatus':
                setCaseTypeId('0')
                setTaskStatusFilterText('CATEGORY')
                break;

            case 'status':
                setCaseStatusId('0')
                setCaseStatusFilterText('PROGRESS')
                break;
            case 'taskType':
                setTaskCaseTypeId('0')
                setTaskTypeFilterText('Task Type')
                break;
            default:
                break;
        }
    }
    /******comment popup start**** */
    const [refTable, setRefTable] = useState("tasks")
    const [refId, setRefId] = useState(0)
    const [commentsCount, setCommentCount] = useState(0)
    const [title, setTitle] = useState("Tasks Comments")
    const [newStatus, setNewStatus] = useState("")
    const [newProgess, setNewProgess] = useState("")
    const [newStage, setNewStage] = useState("")

    const [candidateStatus, setCandidteStatus] = useState("")
    const [candidateProgess, setCandidateProgess] = useState("")
    const [candidateStage, setCandidateStage] = useState("")
    const [commentPopupShow, setCommentPopupShow] = useState(false)
    /******comment popup end**** */

    const commentHandleClose = () => {
        setNewStatus("")
        setNewProgess("")
        setNewStage("")
        setCommentPopupShow(false)
        dispatch(clearState())
        const fetchList = async () => {
            try {
                await dispatch(getTaskByProgress({ status: 'To Do' }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
    }

    return (
        <>
            {
                (commentPopupShow) ? <CommentModal refTable={refTable} refId={refId} count={commentsCount} title={title} commentHandleClose={commentHandleClose} candidateStatus={newStatus != '' ? newStatus : candidateStatus} candidateProgess={newProgess != '' ? newProgess : candidateProgess} candidateStage={newStage != '' ? newStage : candidateStage} /> : null
            }

            <ViewSection
                show={isViewModal}
                onHide={() => setIsViewModal(false)}
                id={rowId}
                data={rowData}
            />
            {
                (isProfileModal) ? <CandidateProfile canid={profileCanId} list={[]} isProfileModal={isProfileModal} handleCloseProfile={handleCloseProfile} /> : null
            }
            <div className='container-fluid dk-workplan-todoCon'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="dk-workplantodo-TopHead">
                            <div className="dk-workplantodoLeftTopHead">
                                <div className="dk-SrchInputBox mr-2">
                                    <div className="input-group">
                                        {
                                            (instanceData !== '0') ? <GlobalFilter
                                                preGlobalFilteredRows={instanceData.preGlobalFilteredRows}
                                                globalFilter={instanceData.state.globalFilter}
                                                setGlobalFilter={instanceData.setGlobalFilter}
                                                isClearAll={isClearAll}
                                                restClearAllFilter={restClearAllFilter}
                                            /> : null
                                        }
                                    </div>
                                </div>
                                <div className='mr-2' style={{ position: "relative" }}>
                                    <TaskByFilter list={list} id={taskByFilterId} img={taskByFilterImg} selectUserFilter={selectUserFilter} />
                                    {
                                        (taskByFilterId !== '0') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="TASKBY" onClick={clearUserFilter}></i> : null
                                    }

                                </div>
                                <div className='mr-2 taskForCrossBtn' style={{ position: "relative" }}>

                                    <TaskForFilter list={list} id={taskForFilterId} img={taskForFilterImg} selectUserFilter={selectUserFilter} />
                                    {
                                        (taskForFilterId !== '0') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="TASKFOR" onClick={clearUserFilter}></i> : null
                                    }
                                </div>
                                <button className="dk-cricleBtn mr-3" onClick={clearAllFilter}>
                                    <i className="fas fa-sync-alt"></i>
                                </button>
                            </div>
                            <div className='dk-workplanHeadtitleCon'>
                                <h2 className='dk-workplanHeadtitle todotitle' style={{ color: '#26597d' }}>TO DO TASKS</h2>
                            </div>
                            <div className="dk-workplantodoRightTopHead">
                                <div className='dk-categoryfilterCon mr-2'>
                                    <TaskStatusFilter list={list} caseStatusFilterText={caseStatusFilterText} onSelectStatusFilter={onSelectStatusFilter} />
                                    {
                                        (caseStatusFilterText !== 'PROGRESS') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="status" onClick={removeTag}></i> : null
                                    }
                                </div>
                                <div className='dk-categoryfilterCon dkg-category-drop-bg mr-2'>

                                    <TaskTypeFilter list={list} taskStatusFilterText={taskStatusFilterText} selectStatusFilter={selectStatusFilter} />
                                    {
                                        (taskStatusFilterText !== 'CATEGORY') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="caseStatus" onClick={removeTag}></i> : null
                                    }
                                </div>
                                <div className='dk-categoryfilterCon dkg-tasktype-drop-bg'>
                                    <TaskType list={list} taskTypeFilterText={taskTypeFilterText} selectStatusFilter={selectStatusFilter} />
                                    {
                                        (taskTypeFilterText !== 'Task Type') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="taskType" onClick={removeTag}></i> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='dk-workplan-todoTableCon'>
                            <Table
                                data={data}
                                columns={columns}
                                getInstanceCallback={reactTableInstance}
                                loading={isLoading}
                                updateMyData={updateMyData}
                                taskByFilterId={taskByFilterId}
                                taskForFilterId={taskForFilterId}
                                caseTypeId={caseTypeId}
                                caseStatusId={caseStatusId}
                                caseTaskTypeId={caseTaskTypeId}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`batch-actions-menu-wrapper dk-action-popupMain ${isActive ? "activePopup" : ""}`} style={{ width: 'auto' }}>
                <div className="num-of-actions_wrapper">
                    <div className='num-of-actions'>{countCheck}</div>
                </div>
                <div className="batch-actions-title-section pl-0">
                    <div className='dk-rowSelected'>
                        <span>Row Selected</span>
                    </div>
                </div>

                <div className="batch-actions-item dk-checkAll" onClick={toggleCheckAll}>
                    <span><i className="fas fa-check"></i></span>
                    {checkBtnTxt}
                </div>


                <div className="batch-actions-item dk-checkAll" data-moveto="Archived" onClick={moveRecords}>
                    <span data-moveto="Archived" onClick={moveRecords}>
                        <i className="fa fa-arrow-circle-right" data-moveto="Archived" onClick={moveRecords}></i>
                    </span>
                    Move to Archived
                </div>

                <DeleteModal loading={loading} success={success} deleteItem={deleteItemBulk} tableName="ToDo" />
                <div className="batch-actions-delete-item CloseAll d-flex align-items-center justify-content-center" onClick={handleToggle}>
                    <span><i className="fas fa-times"></i></span>
                </div>
            </div>
        </>
    )
}

export default ToDo;
