import React from 'react'
import Clientcandquery from '../../../elements/header/modal/candidateprescreen/questions/Overview'

const CandidateQuery = () => {
  return (
    <>
      <div className='dkg-clientcompany-mainCon' style={{marginTop: "160px"}}>
        <Clientcandquery />
      </div>
    </>
  )
}

export default CandidateQuery