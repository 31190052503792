import React, {useState} from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import './liveprojects.scss'

const AddProject = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Link to="#" onClick={handleShow}>
                <i className="fas fa-plus"></i>
                Add New
            </Link>
            <Modal className="dk-projectAddNew-modal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>ADD NEW PROJECT</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddProject;
