import React from 'react'

const QualificationBox = (props) => {
    return (
        <>
            <div className="dk-educationBox">
                <div className="d-flex">
                    <div className="dk-educationDate pl-2"><i className="fa fa-graduation-cap"></i></div>
                    <div className="dk-educationDetails">
                        <div className="sbtitle"> {props.qualification}</div>
                        <table className="table dkg-qualification-table">
                            <tbody>
                                <tr>
                                    <td><p><i className="fa fa-calendar"></i>Year</p></td>
                                    <td>:</td>
                                    <td><p>{props.years}</p></td>
                                </tr>
                                <tr>
                                    <td><p><i className="fa fa-map-marker"></i>Location</p></td>
                                    <td>:</td>
                                    <td><p>{props.location}</p></td>
                                </tr>
                                <tr>
                                    <td><p><i className="fa fa-building"></i>Institution</p></td>
                                    <td>:</td>
                                    <td><p>{props.collegeName}</p></td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <p><i className="fa fa-calendar"></i>{props.years}</p>
                        <p><i className="fa fa-map-marker"></i>{props.location}</p>
                        <p><i className="fa fa-building"></i>{props.collegeName}</p> */}
                        <div className="dk-colsTexttag">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, aperiam dolores obcaecati cupiditate necessitatibus nam laboriosam maxime ad quasi et vero omnis, laudantium deleniti officia expedita illo
                            saepe impedit natus?
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QualificationBox;
