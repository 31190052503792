import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData,DeleteData } from "../../services";

export const getInvoiceList = createAsyncThunk(
    'invoiceAccountbooks/list',
    async ({year},thunkAPI) => {
        const res = await GetData(true, '/accounting/invoice-books/'+year)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const addNewTask = createAsyncThunk(
    'invoiceAccountbooks/add-new',
    async ({ joining,resigned,created,dueDate,year, month, company, language, candidateName, caseStatus, caseStage, progress, docNo,fullFee, ammountDue, ammountPaid, project, roleType, cvSource}, thunkAPI) => {
        const res = await PostData(true, '/accounting/invoice-books', {joining,resigned,created,dueDate,year, month, company, language, candidateName, caseStatus, caseStage, progress, docNo,fullFee, ammountDue, ammountPaid, project, roleType, cvSource})
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const updateDetails = createAsyncThunk(
    'invoiceAccountbooks/update',
    async ({ id,joining,resigned,created,dueDate, year, month,company,language,candidateName,fullFee,docNo,project, roleType, cvSource }, thunkAPI) => {
        const res = await PutData(true, '/accounting/invoice-books/' + id, { joining,resigned,created,dueDate,year, month,company,language,candidateName,fullFee,docNo,project, roleType, cvSource })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateByKey = createAsyncThunk(
    'invoiceAccountbooks/update/key-value',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/accounting/invoice-books/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const deleteFun = createAsyncThunk(
    'invoiceAccountbooks/count/delete',
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, '/accounting/invoice-books/'+id )
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const moveRecordsToPaid = createAsyncThunk(
    'invoiceAccountbooks/count/invoiceAccountbooks/move-to-table',
    async ({ id, status }, thunkAPI) => {
        const res = await PostData(true, '/accounting/invoice-books/move-to-table', { id, status })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


export const updateIvoiceBookCommentCount = createAsyncThunk(
    'salesbooks/comment-list/update-comment',
    async ({ id, value, commentAt }, thunkAPI) => {
        const res = await PutData(true, '/accounting/invoice-books/update-comment/' + id, { value, commentAt })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskDetails = createAsyncThunk(
    'invoiceAccountbooks/task/details',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/accounting/invoice-books/detail/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const fetchCaseStatus = createAsyncThunk(
    'invoiceAccountbooks/caseStatus/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const fetchCaseProgress = createAsyncThunk(
    'invoiceAccountbooks/caseProgress/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const fetchCaseStage = createAsyncThunk(
    'invoiceAccountbooks/caseStage/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const listSlice = createSlice({
    name: 'invoicebookslist',
    initialState: {
        invoiceBookList: [],
        paidsalesBookList: [],
        taskDataSummaryList: [],
        taskTypeList: [],
        taskPaymentMonthList: [],
        taskPayTypeList: [],
        countsProgressList: [],
        countsStageList: [],
        paymentDataCount: [],
        paidPaymentDataCount: [],
        invoiceCaseStatusList:[],
        caseProgressList:[],
        caseStageList:[],
        tasksDetails: {},
        countAll: 0,
        isLoading: false,
        isInvoiceLoading: false,
        isDetailLoading: false,
        isTaskInsert: false,
        isTaskUpdate: false,
        isTaskDelete: false,
        isUpdate: false,
        iscommentCountUpdate: false,
        isError: false,
        isSuccess: false,
        isDataMoveToPayment: false,
        isTaskInserted: false,
        isMoved: false,
        isArchive: false,
        message: ''
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isInvoiceLoading = false;
            state.isDetailLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.issSuccess = false;
            state.isDataMoveToPayment = false;
            state.isTaskInserted = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },
        clearTaskState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isInvoiceLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.isTaskInserted = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },
        clearDetails: (state) => {
            state.tasksDetails = {}
        }
    },
    extraReducers: (builder) => {
       
        builder.addCase(
            getInvoiceList.pending, (state) => {
                state.isLoading = true;
                state.isInvoiceLoading = true;
                state.issSuccess = false;
            });
        builder.addCase(
            getInvoiceList.fulfilled, (state, { payload }) => {
                state.invoiceBookList = payload.result
                state.isLoading = false;
                state.isInvoiceLoading = false;
                state.issSuccess = true;
            });
        builder.addCase(
            getInvoiceList.rejected, (state, action) => {
                state.isLoading = false;
                state.isInvoiceLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

       

        builder.addCase(
            addNewTask.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            addNewTask.fulfilled, (state, { payload }) => {
                state.invoiceBookList.unshift(payload.result)
                state.isLoading = false;
                state.isSuccess = true;
                state.isTaskInserted = true;
            });
        builder.addCase(
            addNewTask.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

       
        builder.addCase(
            updateDetails.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            updateDetails.fulfilled, (state, { payload }) => {
                let mainArray = state.invoiceBookList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result[0].id) {
                        return (payload.result[0])
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.isLoading = false;
                state.invoiceBookList = finalResult;
            });
        builder.addCase(
            updateDetails.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            updateByKey.pending, (state) => {
                state.isUpdate = false
                state.isLoading = false;
            });
        builder.addCase(
            updateByKey.fulfilled, (state, { payload }) => {
                let mainArray = state.invoiceBookList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.invoiceBookList = finalResult;
                state.message = payload.message
            });
        builder.addCase(
            updateByKey.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });    
            

        builder.addCase(
            deleteFun.pending, (state) => {
            });
        builder.addCase(
            deleteFun.fulfilled, (state, { payload }) => {
                state.isTaskDelete = true;
                state.isMoved = true;
            });
        builder.addCase(
            deleteFun.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
       
         builder.addCase(
            moveRecordsToPaid.pending, (state) => {
            });
        builder.addCase(
            moveRecordsToPaid.fulfilled, (state, { payload }) => {
                state.isMoved = true;
                state.message = payload.message
            });
        builder.addCase(
            moveRecordsToPaid.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateIvoiceBookCommentCount.pending, (state) => {
                state.iscommentCountUpdate = false
            });
        builder.addCase(
            updateIvoiceBookCommentCount.fulfilled, (state, action) => {
                let mainArray = state.invoiceBookList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === action.meta.arg.id) {
                        return ({ ...r, commentCount: action.meta.arg.value, commentAt: action.meta.arg.commentAt })
                    } else {
                        return r
                    }
                })
                state.invoiceBookList = finalResult

                let mainArrayPaid = state.paidsalesBookList;
                let finalResultPaid = mainArrayPaid.map((r) => {
                    if (r.id === action.meta.arg.id) {
                        return ({ ...r, commentCount: action.meta.arg.value, commentAt: action.meta.arg.commentAt })
                    } else {
                        return r
                    }
                })
                state.paidsalesBookList = finalResultPaid
                state.iscommentCountUpdate = true
            });
        builder.addCase(
            updateIvoiceBookCommentCount.rejected, (state, action) => {
                state.isError = true;
            });



        builder.addCase(
            taskDetails.pending, (state) => {
                state.isDetailLoading = true
            });
        builder.addCase(
            taskDetails.fulfilled, (state, { payload }) => {
                state.isDetailLoading = false
                state.tasksDetails = payload.result;
            });
        builder.addCase(
            taskDetails.rejected, (state, action) => {
                state.isDetailLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            fetchCaseStatus.pending, (state) => {
            });
        builder.addCase(
            fetchCaseStatus.fulfilled, (state, { payload }) => {
                state.invoiceCaseStatusList = payload.result
            });
        builder.addCase(
            fetchCaseStatus.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            fetchCaseProgress.pending, (state) => {
            });
        builder.addCase(
            fetchCaseProgress.fulfilled, (state, { payload }) => {
                state.caseProgressList = payload.result
            });
        builder.addCase(
            fetchCaseProgress.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            fetchCaseStage.pending, (state) => {
            });
        builder.addCase(
            fetchCaseStage.fulfilled, (state, { payload }) => {
                state.caseStageList = payload.result
            });
        builder.addCase(
            fetchCaseStage.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
    }
});


export const { clearState, clearTaskState, clearDetails } = listSlice.actions;
export default listSlice.reducer;