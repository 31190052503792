import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";

const TypesFilter = ({ list, caseStatusFilterText, onSelectStatusFilter }) => {
  return (
    <>
      <React.Fragment>
        <DropdownButton
          className="dropdownFilter dkg-banking-categry-234 dkg-banking-rightFilter-89 dkg-type-rightfilter"
          id="dropdown-basic-button"
          title={caseStatusFilterText}
          onSelect={onSelectStatusFilter}
        >
          <Dropdown.Item
            className="dkg-banking-categry-item dkg-type-counter"
            eventKey="Income"
            href="#"
            data-type="accountType"
            data-value="Income"
            // data-value={item.name}
            // onClick={selectStatusFilter}
            style={{ backgroundColor: "#3a9469", color: "#fff" }}
          >
            {/* {item.name} */}
            Income
            <span className="dropdown-filter-count">
              {
                list.filter(function (val) {
                  return val.original.accountType == "Income";
                }).length
              }
            </span>
            {/* <span className="dropdown-filter-count">{item.count}</span> */}
          </Dropdown.Item>

          <Dropdown.Item
            className="dkg-banking-categry-item dkg-type-counter"
            eventKey="Expense"
            href="#"
            data-type="accountType"
            data-value="Expense"
            // data-value={item.name}
            // onClick={selectStatusFilter}
            style={{ backgroundColor: "#d91010", color: "#fff" }}
          >
            {/* {item.name} */}
            Expense
            <span className="dropdown-filter-count">
              {
                list.filter(function (val) {
                  return val.original.accountType == "Expense";
                }).length
              }
            </span>
            {/* <span className="dropdown-filter-count">{item.count}</span> */}
          </Dropdown.Item>
        </DropdownButton>
      </React.Fragment>
    </>
  );
};

export default TypesFilter;
