import React from 'react';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { FaUserAlt, FaSpinner } from 'react-icons/fa';

const SendoutCaseFilterModal = ({ onHide }) => {
    return (
        <>
            <Modal className="dk-sendoutFilterModal right" show={true} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>SEARCH & REPORT</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-sendoutFilterTabs-main">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                            <div className="dk-sendoutTabs-nav">
                                <Nav variant="">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_1"><FaUserAlt /> AM</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_2"><FaUserAlt /> RC</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_3"><FaSpinner /> Status</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_4"><FaSpinner /> Progress</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_5"><FaSpinner /> Case Stage</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="dk-sendoutTabs-filterPanel">
                                <Tab.Content>
                                    <Tab.Pane eventKey="tabs_1">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-Recuriter">
                                                <ul>
                                                    <li>
                                                        <Link to="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                            <span className="recuriterNn">302</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1660469485986.png" alt="" />
                                                            <span className="recuriterNn">112</span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_2">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-Recuriter">
                                                <ul>
                                                    <li>
                                                        <Link to="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                            <span className="recuriterNn">302</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1660469485986.png" alt="" />
                                                            <span className="recuriterNn">112</span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SendoutCaseFilterModal;
