import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./trmofuse.scss";
import AccordionTab from "./Accordion";
import { gettermsdata } from "../../../../../slice/cmsSlice";

const TermsofUse = () => {
  const dispatch = useDispatch();
  const { isSuccess, isLoading, termscondtionList } = useSelector(
    (state) => state.cmsdata
  );
  const [termscondition, setTermsconditiondata] = useState([]);

  useEffect(() => {
    dispatch(gettermsdata({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && termscondtionList.length > 0) {
      setTermsconditiondata(
        termscondtionList.filter((data) => data.status == "Active")
      );
    }
  }, [isLoading]);

  return (
    <>
      <div className="dkg-trmofuse-mainCon container-fluid">
        <div className="dkg-trms-accordion-mainCon">
          <div className="dkg-myacc-priavcy-Header">
            <h2 className="dkg-privacy-title">Terms of Use</h2>
          </div>
          <div className="dkg-trms-of-use-bodyCon">
            {termscondition.length > 0
              ? termscondition.map((data, index) => (
                  <>
                    <AccordionTab
                      title={data.question}
                      faqdata={data.answer}
                      key={index + 1}
                    />
                  </>
                ))
              : ""}
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsofUse;
