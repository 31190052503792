import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import DatePicker from './DatePicker';

const AddNewClientsInvoice = ({ hideAddNewInvoiceModal }) => {
    return (
        <>
            <Modal className="dk-statmentsModalMain right" show={true} onHide={hideAddNewInvoiceModal}>
                <Modal.Header closeButton>
                    <Modal.Title>ADD NEW DOCUMENT</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-addNewInvoiceModal-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Start Date</div>
                                    <div className="dk-addInvoice-label dkg-addnew-date-timeCon">
                                        <DatePicker />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Expire On</div>
                                    <div className="dk-addInvoice-label dkg-addnew-date-timeCon">
                                        <DatePicker />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Name of Employee</div>
                                    <div className="dk-addInvoice-label">
                                        <input type="text" name="" id="" vale="Paid" className="form-control" readonly />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Contract Type</div>
                                    <div className="dk-addInvoice-label">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                Select
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">Contract Type 1</Dropdown.Item>
                                                <Dropdown.Item href="#">Contract Type 2</Dropdown.Item>
                                                <Dropdown.Item href="#">Contract Type 3</Dropdown.Item>
                                                <Dropdown.Item href="#">Contract Type 4</Dropdown.Item>
                                                <Dropdown.Item href="#">Contract Type 5</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Services Offered</div>
                                    <div className="dk-addInvoice-label">
                                        <input type="text" name="" id="" className="form-control" />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="dk-invoiceAdd-btn">
                                        <button>ADD</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddNewClientsInvoice;
