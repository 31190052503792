import React, {
  useEffect,
  useState,
} from "react";
import { Link, Route } from "react-router-dom";
import './spec-active-saved.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import ListGroup from 'react-bootstrap/ListGroup';
import CreateFolderModal from './createSpec/CreateFolderModal'
import { useDispatch, useSelector } from "react-redux";
import {
  folderJobsCount,
  updateJobSpecFolder
} from "../../../../../slice/offerSpec/jobsSlice";

const SpecSummary = () => {
  const dispatch = useDispatch();
  const { isFolderSpecCountLoading, folderJobSpecCount } = useSelector((state) => state.offerSpec);

  const pathname = window.location.pathname;
  const segment2 = pathname.split("/")[2];

  const [data, setData] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    let jobSstatus = "";
    if (segment2 === "active-spec") {
      jobSstatus = "Active";
    }
    if (segment2 === "inactive-spec") {
      jobSstatus = "Inactive";
    }
    const fetchList = async () => {
      try {
        await dispatch(folderJobsCount({ status: jobSstatus }));
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  }, [segment2]);

  useEffect(() => {
    if (!isFolderSpecCountLoading) {
      setTimeout(() => {
        setData(folderJobSpecCount);
        setFilteredList(folderJobSpecCount);
      }, 200);
    }
  }, [isFolderSpecCountLoading]);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const searchList = data.filter((item) => {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setFilteredList(searchList);
  };

  let sum = filteredList.reduce(function (prev, current) {
    return prev + +current.count
  }, 0);

  const onDateClick = (e) => {
    dispatch(updateJobSpecFolder({ id: e.target.dataset.id, key: e.target.dataset.column, value: e.target.dataset.value }));
  };

  return (
    <>

      <div className='dkg-active-saved-mainPage container-fluid'>

        <div className="d-flex justify-content-between bd-highlight mb-3">
          <div className="bd-highlight">
            <input placeholder="Search..." value={searchQuery} autoFocus="" onChange={handleSearch} type="text" className="form-control" />
          </div>
          <div className="bd-highlight"><h3>{segment2 == "active-spec" ? "Active Spec" : "Inactive Spec"}</h3></div>
          <div className="bd-highlight">
            {/* <Route>
              <CreateFolderModal />
            </Route> */}
          </div>
        </div>

        <div className='dkg-active-saved-mainRow'>
          {filteredList.map((row, index) => {

            let allContent = <div key={index} className="col-md-3 mt-3">
              <ListGroup as="ol" numbered>
                <Link style={{color:"#000"}} to={"spec/"+row.company.replace(/ /g, '-')+"/"+row.status}>
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start list-group-item p-3"
                >
                  <div className={`padding-8 box ${row.status === "Active" ? "box-active" : "box-inactive"}`}>{row.count}</div>
                  <div className="padding-8">{row.company}</div>
                  <div className="ml-auto padding-8">
                    {/* <Dropdown>
                      <Dropdown.Toggle>
                        <i className="fa fa-ellipsis-h btn-icon-wrapper"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#"
                          data-id={row.id}
                          data-column="status"
                          data-value="Active"
                          onClick={onDateClick}>Active</Dropdown.Item>
                        <Dropdown.Item href="#"
                          data-id={row.id}
                          data-column="status"
                          data-value="Inactive"
                          onClick={onDateClick}
                        >Inactive</Dropdown.Item>
                        <Dropdown.Item href="#">Remove</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </div>
                </ListGroup.Item>
                </Link>
              </ListGroup>
            </div>;
            if (segment2 === "active-spec" && row.status === "Active") {
              return (
                allContent
              );
            } else if (segment2 === "inactive-spec" && row.status === "Inactive") {
              return (
                allContent
              );
            }
          })}
        </div>
      </div>
    </>
  )
}

export default SpecSummary