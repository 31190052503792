import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const PaymentsMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalfinanceBankingMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/dkglobalfinance/finance-payments/dashboard" className={(segment3 == 'dashboard') ? 'active' : ''}>Dashboard</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'corporatetax') ? '' : ''}`}>Payments 1</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'corporatetax') ? '' : ''}`}>Payments 2</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'corporatetax') ? '' : ''}`}>Payments 3</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'corporatetax') ? '' : ''}`}>Payments 4</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'corporatetax') ? '' : ''}`}>Payments 5</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default PaymentsMenu;