import React from "react";
import { Route, Link } from "react-router-dom";
// import './myacc-submenu.scss';

const MyFileSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    return (
        <React.Fragment>
            <div className="dkg-myaccsubMenu-Main">
                <div className="dk-myaccsubMenuMain">
                    <Route>
                        <ul>
                            <li><Link to="/js-account/dkg-services/summary" className={(segment3 == 'summary') ? 'active' : ''}>Overview</Link></li>
                            <li><Link to="#/js-account/dkg-services/notifications" className={(segment3 == 'notifications') ? 'active' : ''}>Job Search</Link></li>
                            <li><Link to="#/js-account/dkg-services/compliance" className={`${(segment3 == 'compliance') ? 'active' : ''}`}>CV Resume</Link></li>
                            <li><Link to="#/js-account/dkg-services/cookies" className={`${(segment3 == 'cookies') ? 'active' : ''}`}>Job Interviews</Link></li>
                            <li><Link to="#/js-account/dkg-services/my-documents" className={`${(segment3 == 'my-documents') ? 'active' : ''}`}>Job Promotion</Link></li>
                            <li><Link to="#/js-account/dkg-services/conset" className={`${(segment3 == 'conset') ? 'active' : ''}`}>Study Aboard</Link></li>
                            <li><Link to="#/js-account/dkg-services/change-logins" className={`${(segment3 == 'change-logins') ? 'active' : ''}`}>Work Aboard</Link></li>
                            <li><Link to="#/js-account/dkg-services/disable-accounts" className={`${(segment3 == 'disable-accounts') ? 'active' : ''}`}>Career Planing</Link></li>
                            <li><Link to="#/js-account/dkg-services/delete-accounts" className={`${(segment3 == 'delete-accounts') ? 'active' : ''}`}>Soft Skills</Link></li>
                            <li><Link to="#/js-account/dkg-services/reset-login" className={`${(segment3 == 'reset-login') ? 'active' : ''}`}>Consultantion</Link></li>
                        </ul>
                    </Route>
                </div>
            </div>
        </React.Fragment>
    );
}

export default MyFileSubmenu;
