import React from 'react'
import { InputGroup, FormControl, Form, Table, Row } from "react-bootstrap";
import './like-dislike.scss'

const LikeDisLike = () => {
    return (
        <>
            <div className="dkg-like-dislike-mainCon">
                <div className="row dk-Adsbox mt-3">
                    <div className="col-md-6">
                        <div className="col-md-12 likesdislikesbox">
                            <div className="pb-2">
                                <h2
                                    className="text-center pt-2"
                                    style={{ color: "#10b04d", fontSize: "1.4rem" }}
                                >
                                    LIKED CANDIDATES
                                </h2>
                                <div className="col-md-12">
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">1</InputGroup.Text>
                                        <FormControl
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                </div>
                                <div className="col-md-12">
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">2</InputGroup.Text>
                                        <FormControl
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                </div>
                                <div className="col-md-12">
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">3</InputGroup.Text>
                                        <FormControl
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                </div>
                                <div className="col-md-12">
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">4</InputGroup.Text>
                                        <FormControl
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                </div>
                                <div className="col-md-12">
                                    <InputGroup className="mb-2">
                                        <InputGroup.Text id="basic-addon1">5</InputGroup.Text>
                                        <FormControl
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="col-md-12 likesdislikesbox">
                            <h2
                                className="text-center pt-2"
                                style={{ color: "#c45613", fontSize: "1.4rem" }}
                            >
                                DISLIKED CANDIDATES
                            </h2>
                            <div className="col-md-12">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">1</InputGroup.Text>
                                    <FormControl
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                            </div>
                            <div className="col-md-12">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">2</InputGroup.Text>
                                    <FormControl
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                            </div>
                            <div className="col-md-12">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">3</InputGroup.Text>
                                    <FormControl
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                            </div>
                            <div className="col-md-12">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">4</InputGroup.Text>
                                    <FormControl
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                            </div>
                            <div className="col-md-12">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">5</InputGroup.Text>
                                    <FormControl
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        {/* <div className="col-md-12 likesdislikesbox">
                            <div className="scrollDiv pb-2">
                                <h2 className="text-center pt-2" style={{ fontSize: "1.4rem" }}>
                                    REVIEW CV BASED ON
                                </h2>
                                <div className="col-md-12">
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text
                                            style={{ minWidth: "110px" }}
                                            id="basic-addon1"
                                        >
                                            Nationality
                                        </InputGroup.Text>
                                        <FormControl as="textarea" rows="3" name="address" />
                                    </InputGroup>
                                </div>
                                <div className="col-md-12">
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text
                                            style={{ minWidth: "110px" }}
                                            id="basic-addon1"
                                        >
                                            Location
                                        </InputGroup.Text>
                                        <FormControl as="textarea" rows="3" name="address" />
                                    </InputGroup>
                                </div>
                                <div className="col-md-12">
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text
                                            style={{ minWidth: "110px" }}
                                            id="basic-addon1"
                                        >
                                            Language
                                        </InputGroup.Text>
                                        <FormControl as="textarea" rows="3" name="address" />
                                    </InputGroup>
                                </div>
                                <div className="col-md-12">
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text
                                            style={{ minWidth: "110px" }}
                                            id="basic-addon1"
                                        >
                                            Seniority
                                        </InputGroup.Text>
                                        <FormControl as="textarea" rows="3" name="address" />
                                    </InputGroup>
                                </div>
                                <div className="col-md-12">
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text
                                            style={{ minWidth: "110px" }}
                                            id="basic-addon1"
                                        >
                                            Work EXP
                                        </InputGroup.Text>
                                        <FormControl as="textarea" rows="3" name="address" />
                                    </InputGroup>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LikeDisLike