import React, { useState, useMemo, useEffect } from 'react';
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import "./liveAds.css";
import { FaPlus, FaSearch, FaUser } from "react-icons/fa";
import CreateNewModal from './modal/CreateNew';
import EditModal from './modal/Edit';
import DeleteModal from '../../../ui/delete';
import ViewModal from './modal/View';
import StatusFilter from './dropdown/StatusFilter'
import TableLoader from "../../../ui/tableLoader";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import { livelistTable, fethById, deleteTracker, updateStatus, bulkDeleteState, clearState } from '../../../../slice/ads/liveAds/liveAdsSlice';
import Tooltip from "../../../ui/tooltip";
import { GlobalFilter } from "./GlobalFilter";
import UserFilterToggle from "../../../ui/tableUserFilter";
//import FilterSelectInstance from '../../../ui/SelectPicker/FilterSelectInstance';
import FilterSelectInstance from '../filter/SelectInstance';
import DateFormat from "../../../ui/dateFormats/DateFormat";
import CustomDateFormat from "../../../ui/dateFormats";
import { showSuccess, showError, clearMessage } from "../../../utils/messages/messageSlice";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '../../../ui/pagination';
import TodayAdsReorder from "./modal/Reorder";
import $ from "jquery";
import "./extraDiv.scss";
import { DropdownButton } from 'react-bootstrap';


function Table({ loading, columns, data, title, tableId, isInsert, isUpdate, isDataDelete }) {
    const [userFilterId, setUserFilterId] = useState('0')
    const [userFilterImg, setUserFilterImg] = useState('0')
    const [isAddNew, setIsAddNew] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isCreateNew, setIsCreateNew] = useState(false);

    const handlePageChange = (event, value) => {
        gotoPage(Number(value) - 1);
    };
    const showaddNewModal = () => {
        setIsAddNew(true)
    }
    const hideaddNewModal = () => {
        setIsAddNew(false)
    }

    const clickFilterUser = (e) => {
        setUserFilterId(e.target.id)
        setUserFilterImg(e.target.src)
        setFilter('userInfo', e.target.id)
    }

    const deleteFilter = (name) => {
        setFilter(name, '')
        if (name === 'userInfo') {
            setUserFilterId('0')
            setUserFilterImg('0')
        }
    }
    // const handleChangeSelect = (event, data) => {
    //     const { name, value } = data
    //     switch (name) {
    //         case "statusId":
    //             setFilter(name, value)
    //             break;
    //     }
    // }
    const showcreateNewModal = () => {
        setIsCreateNew(true)
    }
    const hidecreateNewModal = () => {
        setIsCreateNew(false)
    }

    const tableInstance = useTable({
        columns: columns,
        data: data,
        initialState: {
            pageIndex: 0, pageSize: 100, hiddenColumns: ["userId", "statusId", "userInfo"]
        },
    }, useGlobalFilter, useFilters, useSortBy, usePagination)

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, state, setGlobalFilter, setFilter,
        // canPreviousPage,
        // canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        // nextPage,
        // previousPage,
        // setPageSize,
        state: { pageIndex, pageSize } 
    } = tableInstance
    const { globalFilter } = state

    useEffect(() => {
        setFilter('userId', '')
        setUserFilterId('0')
        setUserFilterImg('0')
        setGlobalFilter('0')
    }, [tableId])

    useEffect(() => {
        if (isInsert || isUpdate || isDataDelete) {
            setFilter('userId', '')
            setUserFilterId('0')
            setUserFilterImg('0')
            setGlobalFilter('0')
        }
    }, [isInsert, isUpdate, isDataDelete])

    const [caseStatusFilterDDLText, setCaseStatusFilterDDLText] = useState('Status')
    const onSelectStatusFilter = (eventKey) => {
       
        switch (eventKey) {
            case '1':
                setCaseStatusFilterDDLText('Active')
                setFilter('statusId', '1')
                break

            case '0':
                setCaseStatusFilterDDLText('Inactive')
                setFilter('statusId', '0')
                break
            default:
                break;
        }

    }

    const removeTag = async (e) => {
        const type = e.target.dataset.type;
        switch (type) {
            case 'status':
                setCaseStatusFilterDDLText('Status')
                setFilter('statusId', '')
                break;

            default:
                break;
        }
    }

    return (
        <React.Fragment>
            {
                (isCreateNew) ? <CreateNewModal onHide={hidecreateNewModal} /> : null
            }
            {
                (isAddNew) ? <CreateNewModal onHide={hideaddNewModal} /> : null
            }

            <div className="dk-todayadsPageHead">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-todayadsLeft">
                        <div className="dk-mailshotsSearch">
                            <div className="input-group">
                                <span className="d-flex">
                                    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                                </span>
                            </div>
                        </div>
                        <div className="dk-filterUserTabs dropdown">
                            <UserFilterToggle userFilterId={userFilterId} userFilterImg={userFilterImg} list={tableInstance.flatRows} selectUser={clickFilterUser} deleteFilter={deleteFilter} />
                        </div>
                        <button className="filterTable ml-2">
                            <FaSearch />
                        </button>
                    </div>
                    {/* <h2 className="title">TODAY ADS TO POST</h2> */}
                    <div className="dk-todayadsTopRight d-flex">
                        <button onClick={showcreateNewModal} className="filterTable mr-2">
                            <FaPlus />
                        </button>
                        {/* <div className="dk-reSLoginFilter"> */}
                        {/* <FilterSelectInstance list={tableInstance.flatRows} type="status" placeholder="Status" name="statusId" onChange={handleChangeSelect} /> */}
                        {/* <StatusFilter liveadsstusstitle="Status" /> */}
                        {/* </div> */}
                        <div className="dk-reSLoginFilter">
                            <DropdownButton className='dk-cli-status-dropdown' title={caseStatusFilterDDLText} onSelect={onSelectStatusFilter}>
                                <Dropdown.Item eventKey="1" href="#" className="dk-activeBtn" style={{ backgroundColor: "#368836", color: "#fff" }}>Active
                                    <span className="dkg-lv-statuscounter">
                                        {
                                            tableInstance.flatRows.filter(function (val) {
                                                return val.original.status === 1;
                                            }).length
                                        }
                                    </span>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="0" href="#" className="dk-inActiveBtn" style={{ backgroundColor: "#dc3545", color: "#fff" }}>Inactive
                                    <span className="dkg-lv-statuscounter">
                                        {
                                            tableInstance.flatRows.filter(function (val) {
                                                return val.original.status === 0;
                                            }).length
                                        }
                                    </span>
                                </Dropdown.Item>

                            </DropdownButton>
                            {
                                (caseStatusFilterDDLText !== 'Status') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="status" onClick={removeTag}></i> : null
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className="dk-adsTrackTableCon table-responsive">
                <table className="table dk-adsTrackTable" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {(loading) ? <TableLoader colSpan="17" /> : <tbody {...getTableBodyProps()}>
                        {page.map(
                            (row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            }
                        )}
                    </tbody>
                    }
                </table>
            </div>
            <div className="col-md-12">
                <div className="dk-resPaginationCon">
                    <div className="dk-resPagination">
                        <div className="dk-resLeftPag">
                            <div className="dk-texCounter">Showing {' '}{pageIndex + 1} to {pageOptions.length} (of {tableInstance.rows.length} entries)</div>
                        </div>
                        <div className="dk-resrightPag">
                            <TablePagination count={pageCount} onChange={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    tblDdlActive: {
        backgroundColor: '#31a886 !important',
        color: '#ffffff !important',
        minWidth: 90
    },
    tblDdlInActive: {
        backgroundColor: '#d8345f !important',
        color: '#ffffff !important',
        minWidth: 90
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: 'green',
    },
}));


const LiveAdsTable = (props) => {
    //const [isCreateNew, setIsCreateNew] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    //const [isReorder, setIsReorder] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [rowId, setRowId] = useState('0')
    const { dataList, isDataLoading, isInsert, isUpdate, isDelete, isReoder } = useSelector(state => state.liveAdsTracker)

    // useEffect(() => {
    //     async function fetchData() {
    //         try {
    //             await dispatch(liveUserList({}))
    //             await dispatch(livelistTable({}))
    //             await dispatch(jobBoardList({}))
    //             await dispatch(companyList({}))
    //             await dispatch(roleList({}))
    //             await dispatch(languageList({}))
    //         } catch (err) {
    //             console.log(err)
    //         }
    //     }
    //     fetchData()
    // }, [])

    useEffect(() => {
        if (!isDataLoading && dataList && dataList.length > 0) {
            setData(dataList)
        } else {
            setData(dataList)
        }
    }, [isDataLoading, isInsert, isUpdate, isDelete, isReoder])


    useEffect(() => {
        if (props.tableId != '0') {
            setData(dataList.filter(dataList => dataList.jobBoard === props.tableId))
        } else {
            setData(dataList)
        }
    }, [props.tableId])

    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Delete successfully.' }))
            // if (props.tableId != '0') {
            //     setData(dataList.filter(dataList => dataList.jobBoard.id === props.tableId))
            // } else {
            //setData(dataList)
            // }
            setRowId('0')
            hideDeleteModal()
        }
    }, [isDelete, isUpdate])

    const columns = useMemo(() => [
        {
            Header: 'SNo.',
            title: 'SNo.',
            Cell: (row, { cell }) => (
                <React.Fragment>
                    <div className="dk-smsTableCheckbox">
                        <input type="checkbox" className="chkCandidate" name="chkCandidate[]" value={row.row.original.id} onChange={(e) => toggleCheckbox(e, row.row.original.id)} />
                        {Number(row.row.id) + 1}
                    </div>
                </React.Fragment>

            )
        },
        {
            id: 'user',
            Header: 'Recruiter',
            accessor: 'userInfo.firstName',
            Cell: ({ cell }) => (
                <div className="dk-adsRecImgCon">
                    <Tooltip type="img" title={cell.row.original.userInfo.firstName + " " + cell.row.original.userInfo.lastName} value={process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.userInfo.profileImage} className="dk-adsTrackImg" />
                </div>
            )
        },
        {
            id: 'status',
            Header: 'Status',
            accessor: 'status',
            Cell: ({ cell }) => (
                <div className="dk-adsStatusBtn">
                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dk-adsstatustoggle" className={(cell.row.original.status === 1 ? classes.tblDdlActive : classes.tblDdlInActive)}>
                            {(cell.row.original.status === 1 ? 'Active' : 'Inactive')}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item data-rowid={cell.row.original.id} id='1' data-text='Active' onClick={statusUpdate} data-style={{ color: '#fff' }} className={classes.tblDdlActive}>Active</Dropdown.Item>
                            <Dropdown.Item data-rowid={cell.row.original.id} id='0' data-text='Inactive' onClick={statusUpdate} data-style={{ color: '#fff' }} className={classes.tblDdlInActive}>Inactive</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )
        },
        {
            Header: 'Update On',
            accessor: 'updatedAt',
            Cell: (row, { cell }) => (
                <React.Fragment>

                    <CustomDateFormat date={row.row.original.updatedAt} />
                </React.Fragment>
            )
        },
        {
            Header: 'Posted Date',
            accessor: 'postedDate',
            Cell: (row, { cell }) => (
                <React.Fragment>
                    <DateFormat date={row.row.original.postedDate} />
                </React.Fragment>
            )
        },

        {
            id: 'userId',
            Header: 'Recruiter Id',
            accessor: 'userInfo.id',
            show: false
        },
        {
            id: 'userInfo',
            Header: 'Id',
            accessor: 'userInfo.uid'
        },
        {
            id: 'statusId',
            Header: 'Status Id',
            accessor: 'status',
            show: false
        },

        {
            Header: 'Job Ref',
            accessor: 'jobRef',
        },
        {
            Header: 'Job Title',
            accessor: 'jobTitle',
        },
        {
            Header: 'No of Apps',
            accessor: 'totalApplication',
        },
        {
            id: 'jobBoardId',
            Header: 'Job Board',
            accessor: 'jobBoard',
        },
        {
            id: 'companyId',
            Header: 'Company',
            accessor: 'company',
        },
        {
            id: 'roleType',
            Header: 'Role Type',
            accessor: 'roleType',
        },
        {
            id: 'language',
            Header: 'Language',
            accessor: 'language',
        },
        {
            Header: 'City',
            accessor: 'city',
        },
        {
            Header: 'Country',
            accessor: 'country',
        },
        {
            Header: 'Expire Date',
            accessor: 'expiredDate',
            Cell: (row, { cell }) => (
                <React.Fragment>
                    <DateFormat date={row.row.original.expiredDate} />
                </React.Fragment>
            )
        },
        {
            Header: 'Action',
            Cell: ({ cell }) => (
                <div className="dk-adstractAction">
                    <Link to="#" className="dk-view-btn mr-2" onClick={() => showViewModal(cell.row.original.id)}>
                        <i className="fa fa-eye " aria-hidden="true"></i>
                    </Link>
                    <Link to="#" className="dk-edit-btn mr-2" onClick={() => showeditModal(cell.row.original.id)}>
                        <i className="fa fa-edit " aria-hidden="true"></i>
                    </Link>
                    <Link to="#" className="dk-delete-btn" onClick={() => showDeleteModal(cell.row.original.id)}>
                        <i className="fa fa-trash " aria-hidden="true"></i>
                    </Link>
                </div>
            )
        }
    ], [])

    /** Single Checkbox Select */
    const toggleCheckbox = (e, id) => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount > 0) {
            setCountCheck(checkedBoxCount)
            setIsActive(true)
        } else {
            setCountCheck(0)
            setIsActive(false)
        }
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Check All')
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
        }
    }
    /** End Single Checkbox Select */
    const [showDelete, setShowDelete] = useState(false)
    const [isAddNew, setIsAddNew] = useState(false);
    const [isDeletePop, setIsDeletePop] = useState(false);
    const [isDeleteBulkPop, setIsDeleteBulkPop] = useState(false);

    const showaddNewModal = () => {
        setIsAddNew(true)
    }
    const hideaddNewModal = () => {
        setIsAddNew(false)
    }
    const showeditModal = async (id) => {
        await dispatch(fethById({ id }))
        setRowId(id)
        setIsEdit(true)
    }
    const hideeditModal = () => {
        setIsEdit(false)
    }
    const showViewModal = async (id) => {
        await dispatch(fethById({ id }))
        setRowId(id)
        setIsView(true)
    }
    const hideViewModal = () => {
        setIsView(false)
    }
    const showDeleteModal = async (id) => {
        setRowId(id)
        setIsDeletePop(true)
    }
    const hideDeleteModal = () => {
        setIsDeletePop(false)

    }

    const deleteSelected = () => {
        setIsDeleteBulkPop(true)
    }

    const closeDeleteBulk = () => {
        setIsDeleteBulkPop(false)
    }

    const deleteItem = async () => {
        await dispatch(deleteTracker({ id: rowId }))
    }

    const [bulkLoad, setBulkLoad] = useState(false)

    const deleteItemBulk = async () => {
        setBulkLoad(true)
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
        } else {
            var canIds = [];
            $.each($(".chkCandidate:checked"), function () {
                canIds.push($(this).val());
            });
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            await fetch(process.env.REACT_APP_BASE_URL + '/ads/live-ads/bulkdelete', {
                method: 'POST',
                body: JSON.stringify({ 'ids': canIds }),
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                    'platform': 'web',
                    'Authorization': 'Bearer' + ' ' + token.access_token
                }
            }).then(response => {
                if (response.status === 200) {
                    dispatch(showSuccess({ msg: 'Delete successfully' }))
                    async function fetchNewData() {
                        try {
                            await dispatch(clearState())
                            await dispatch(livelistTable({}))
                            await dispatch(bulkDeleteState())
                        } catch (err) {
                            console.log(err)
                        }
                    }
                    fetchNewData()
                    setBulkLoad(false)
                    closeDeleteBulk(false)
                    setIsActive(false)
                }
            })
        }
    }
    const statusUpdate = (e) => {
        dispatch(updateStatus({ id: e.target.dataset.rowid, status: e.target.id }))
    }
    const showEditModal = () => {
        setIsEdit(true)
    }
    const hideEditModal = () => {
        setIsEdit(false)
    }
    const [isActive, setIsActive] = useState(false);
    const [countCheck, setCountCheck] = useState(0)
    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')

    const handleToggle = () => {
        $('.chkCandidate').prop('checked', false);
        setIsActive(false);
    };

    const toggleCheckAll = () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
            setCountCheck(totalCheckboxes)
            $('.chkCandidate').prop('checked', true);
            // setCheckedBoxes(checkedBoxes.filter((v, i, a) => a.indexOf(v) === i))
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Check All')
            setCountCheck(0)
            $('.chkCandidate').prop('checked', false);
            setIsActive(false)
        }
    }
    const closeDelete = () => {
        setIsDeletePop(false)
    }
    return (
        <>

            {
                (isEdit) ? <EditModal onHide={hideEditModal} /> : null
            }
            {
                (isView) ? <ViewModal onHide={hideViewModal} /> : null
            }
            {
                (isDeletePop) ? <DeleteModal id={rowId} onHide={hideDeleteModal} deleteItem={deleteItem} handleClose={closeDelete} /> : null
            }

            {
                (isDeleteBulkPop) ? <DeleteModal id={rowId} onHide={hideDeleteModal} deleteItem={deleteItemBulk} handleClose={closeDeleteBulk} /> : null
            }
            <div className="container-fluid dk-todayMainPageCon">
                <div className="row">
                    <div className="col-md-12 col-sm-12 pl-0 pr-0">
                        <div className="dk-todayAdsTableCon">
                            <Table
                                data={data}
                                columns={columns}
                                title={props.tableTitle}
                                loading={isDataLoading}
                                tableId={props.tableId}
                                isInsert={isInsert}
                                isUpdate={isUpdate}
                                isDataDelete={isDelete}
                                className="table dk-todayAdsTable"
                            />

                        </div>
                    </div>
                </div>
            </div>
            <div className={`dk-smsMessage-selectMsg ${isActive ? "activePopup" : ""}`}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-selectNo">{countCheck}</div>
                    <div className="dk-rowSelected">
                        <span>Row Selected</span>
                    </div>
                    <div className="dk-checkAll" onClick={toggleCheckAll}>
                        <span><i className="fas fa-check"></i></span>
                        {checkBtnTxt}
                    </div>
                    {
                        (bulkLoad) ?
                            <div className={`dk-checkAll ` + classes.backdrop}>
                                <CircularProgress color="inherit" />
                            </div>
                            :
                            <div className="dk-checkAll" onClick={deleteSelected}>
                                <span><i className="fas fa-trash-alt"></i></span>
                                Delete
                            </div>
                    }
                    <div className="dk-checkAll d-flex align-items-center justify-content-center" onClick={handleToggle}>
                        <span><i className="fas fa-times"></i></span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LiveAdsTable;
