
import './myacc-joboffers.scss'
const MyaccJoboffer = () => {
    return (
        <>
            <div className='dkg-mailbox-pageCon'></div>
        </>
    )
}

export default MyaccJoboffer