import React, { useEffect, useState, useRef } from 'react';
import TaskForDropdown from './taskdropdown/TaskForDropdown'
import NativeDatePicker from '../../../../ui/nativedatepicker/DatePicker'
import NativeTimePicker from '../../../../ui/nativedatepicker/TimePicker'
import SubmitButton from "../../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import moment from "moment-timezone";
import AWS from 'aws-sdk'
import { clearState, addNewTask, taskTypeFetch, taskCandidateDetails, clearDetails } from "../../../../../slice/task/taskSlice";

import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import TaskTypeDropdown from "./taskdropdown/TaskTypeDropdown";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { Dropdown } from 'react-bootstrap';
import './taskdropdown/taskcategory.scss'
import IdModal from './IdModal'
import Modal from 'react-bootstrap/Modal'
import './createtaskidmodal.scss'
import { isEmptyObject } from 'jquery';

const TasksTab = (props) => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth)
    const { taskTypeList, isError, isSuccess, message, candidateDetails, candidateDetailLoading } = useSelector(state => state.task)

    const fileInputAPI = useRef();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [countUser, setCountUser] = useState(0)
    const [selectUserId, setSelectUserId] = useState(userInfo.id)
    const [selectUserImg, setSelectUserImg] = useState(userInfo.profileImage)

    const [type, setType] = useState(props.type)
    const [taskBy, setTaskBy] = useState(userInfo.id)
    const [taskType, setTaskType] = useState('')
    const [subject, setSubject] = useState('')
    const [details, setDetails] = useState('')
    const [taskDueDate, setTaskDueDate] = useState(moment(new Date()).format('DD MMM YYYY'))
    const [dueTime, setDueTime] = useState(moment(new Date()).format('hh:m A'))
    const [pickerDueTime, setPickerDueTime] = useState(new Date())
    const [isAttachment, setIsAttachment] = useState('0')
    const [attachment, setAttachment] = useState([])

    const [candidateInfor, setCandidateInfor] = useState('')
    const [isCanIdPop, setIsCanIdPop] = useState(false)
    const [canId, setCanId] = useState('Candidate ID:')
    const handleShow = () => {
        setIsCanIdPop(true)
        setCanId('')
    };
    const handleClose = () => setIsCanIdPop(false);

    const getCandidateInfo = async () => {
        await dispatch(taskCandidateDetails({ id: canId }))
    }

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    useEffect(() => {
        if (!isEmpty(candidateDetails)) {
            setCandidateInfor(candidateDetails.jobDetail.company + "/ " + candidateDetails.name)
            setSubject(canId + ": " + candidateDetails.jobDetail.company + "/ " + candidateDetails.name)
            setIsCanIdPop(false)
            dispatch(clearState())
        }
    }, [candidateDetailLoading])

    const handleDateChange = (date) => {
        setTaskDueDate(moment(date).format('DD MMM YYYY'));
    };

    const handleTimeChange = (date) => {
        setPickerDueTime(date)
        setDueTime(moment(date).format('hh:m A'));
    };

    const selectUser = (id, src, name) => {
        setSelectUserId(id)
        setSelectUserImg(src)
    }

    useEffect(() => {
        setSelectUserId(userInfo.id)
        setSelectUserImg(userInfo.profileImage)
        // const empty = arr => arr.length = 0;
        // empty(selectUserId)
        // if ((props.type != "Others Task") && (props.type != "IT Team")) {
        //     selectUserId.push(userInfo.id)
        // } else {
        //     const empty = arr => arr.length = 0;
        //     empty(selectUserId)
        // }
    }, [props.type])

    useEffect(() => {
        if (props.taskTypeId != "0") {
            const fetchList = async () => {
                try {
                    await dispatch(taskTypeFetch({ id: props.taskTypeId }))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
            setSubject('')
        }
    }, [props.taskTypeId])

    const onSelectFile = async (e) => {
        try {
            let s3bucket = new AWS.S3({
                accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
                secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
                Bucket: process.env.REACT_APP_BUCKET_NAME
            });
            const dataKey = Date.now() + '-' + (e.target.files[0].name).replace(/ /g, "_");
            s3bucket.createBucket(function () {
                var params = {
                    Bucket: process.env.REACT_APP_BUCKET_NAME + "/tasks",
                    Key: dataKey,
                    Body: e.target.files[0],
                    ContentType: e.target.files[0].type
                };
                s3bucket.upload(params, function (err, data) {
                    if (err) {
                        console.log(err)
                    } else {
                        setIsAttachment('1')
                        attachment.push("/tasks/" + dataKey)
                    }
                });
            });
        } catch (err) {
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (!subject || !taskType || !taskDueDate || !dueTime) {
                dispatch(showError({ msg: 'All fileds required' }))
            } else {
                setLoading(true);
                await dispatch(addNewTask({ type, taskType, subject, details, taskDueDate, dueTime, taskBy, taskFor: [selectUserId], isAttachment, attachment }))
            }
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }

    useEffect(() => {
        if (isSuccess) {
            dispatch(clearDetails())
            setSuccess(false);
            setLoading(false);
            dispatch(clearState())
            setSubject('')
            setCanId('Candidate ID:')
            setCandidateInfor('')
            dispatch(showSuccess({ msg: 'Task added successfully.' }))
        }
    }, [isSuccess])

    useEffect(() => {
        setSubject('')
        setCanId('Candidate ID:')
        setCandidateInfor('')
    }, [type, taskType])

    return (
        <>
            <div className='dk-task-tab-MainCon'>
                <div className='dk-create-newtask-body'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='from-group'>
                                <label className='dk-cre-newtask-label-center'>Status</label>
                                <span className="dk-taskText">To DO</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group dk-create-date-timeFrmGrp">
                                <label htmlFor="" className='dk-cre-newtask-label-center'>Progress</label>
                                <div className="dk-taskSwitch ddd" >
                                    <span className="dk-caseText">New Task</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='from-group'>
                                <label className='dk-cre-newtask-label-center'>Category</label>
                                <input type="email" className="form-control dk-tasktype-input" placeholder={props.type} readOnly />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='from-group'>
                                <label className='dk-cre-newtask-label-center'>Type <span style={{ color: "red" }}>*</span> </label>
                                <Dropdown className='dk-taskTypeDropdown-main dkg-taskdropdown-24'>
                                    <Dropdown.Toggle variant="" className='dkg-taskdropdown-toggle-24'>
                                        {taskType}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dkg-taskdropdown-menu-24'>
                                        {
                                            taskTypeList.map((item, index) =>
                                                <Dropdown.Item className='dkg-taskdropdown-item-24' key={index} onClick={() => setTaskType(item.value)}>{item.value}</Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="d-flex">
                                <div className='from-group w-100 mr-1'>
                                    <label>Task By</label>
                                    <div className="dk-taskByCon">
                                        <div className="dk-selectator-textlength">
                                            <div className="dk-selected-items">
                                                <div className="userImgCont">
                                                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + userInfo.profileImage} alt={userInfo.firstName} className="userImg" />
                                                </div>
                                                {/* <div className="dk-selecteditem-remove">X</div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='from-group w-100 ml-1'>
                                    <label>Task For</label>
                                    <TaskForDropdown taskType={props.type} selectUser={selectUser} countUser={countUser} selectUserImg={selectUserImg} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group dk-create-date-timeFrmGrp">
                                <label className='w-100 text-center'>Attachments</label>
                                <label for="" className="dk-taskChoosefile">
                                    <div className="file-select-name">No file chosen...</div>
                                    <input type="file" name="attachmentFile" onChange={onSelectFile} ref={fileInputAPI} /><span>Attach a file  <i className="ml-3 fas fa-upload"></i></span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group dk-create-date-timeFrmGrp">
                                <label htmlFor="" className='dk-meeting-date-timelabel'>Due Date</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container alignItems="center" justify="center" className='dk-nativedatepicker-grid'>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="d MMM yy"
                                            margin="normal"
                                            id="date-picker-inline dk-startDatenativepicker"
                                            className="dk-nativedatepicker"
                                            value={taskDueDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            autoOk={true}
                                            error={false}
                                            helperText={null}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group dk-create-date-timeFrmGrp">
                                <label htmlFor="" className='dk-meeting-date-timelabel'>Due Time</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container alignItems="center" justify="center" className='dk-nativetimeepicker-grid'>
                                        <KeyboardTimePicker
                                            // disableToolbar
                                            variant="inline"
                                            margin="normal"
                                            id="time-picker"
                                            className="dk-nativetimeepicker"
                                            value={pickerDueTime}
                                            onChange={handleTimeChange}
                                            keyboardIcon={<AccessTimeRoundedIcon />}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            autoOk={true}
                                            error={false}
                                            helperText={null}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        {/* {
                            (props.type == "Recruitment" && taskType == "Candidate") ?
                                <div className="col-md-12 mt-2">
                                    <div className="form-group dk-create-date-timeFrmGrp">
                                        <label for="">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Subject</span>
                                                </div>
                                                <div className="input-group-prepend dkg-crete-new-task-idCon">
                                                    <span className="input-group-text" onClick={handleShow}>{canId}</span>
                                                    <Modal show={isCanIdPop} onHide={handleClose} centered className='dkg-create-newtask-idmodal'>
                                                        <Modal.Body className='dkg-create-newtask-idmodal-body'>
                                                            <div className="d-flex">
                                                                <div className="form-group">
                                                                    <label for="">Candidate ID</label>
                                                                    <label for="">
                                                                        <input type="text" className="form-control" value={canId} onChange={(e) => setCanId(e.target.value)} />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-center">
                                                                <button className="dk-saveBtn" onClick={getCandidateInfo}>Save</button>
                                                            </div>
                                                        </Modal.Body>
                                                    </Modal>
                                                </div>
                                                <div className="form-control dk-subjectFrmControl">{candidateInfor}</div>
                                            </div>
                                        </label>
                                    </div>
                                </div> :
                                <div className="col-md-12">
                                    <div className="form-group dk-create-date-timeFrmGrp">
                                        <label for="">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Subject</span>
                                                </div>
                                                <input type="text" className="form-control dk-subjectFrmControl" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                        } */}

                            <div className="col-md-12">
                                    <div className="form-group dk-create-date-timeFrmGrp">
                                        <label for="">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Subject</span>
                                                </div>
                                                <input type="text" className="form-control dk-subjectFrmControl" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                        
                        {/* <div className="col-md-12 mt-2">
                            <div className="form-group dk-create-date-timeFrmGrp">
                                <textarea className="form-control" id="txtCreateTaskDetails" placeholder="Enter the details of the task..." cols="20" value={details} onChange={(e) => setDetails(e.target.value)}></textarea>
                            </div>
                        </div> */}
                        <div className="col-md-12 mt-2">
                            <div className="form-group dk-createNewTaskFooter">
                                <SubmitButton txt="CREATE" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="btn dk-create-new-taskbtn" />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default TasksTab;
