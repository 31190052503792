import React from "react";
import "./status.scss";

const tableValues = ({ value }) => {
  let bgColor = "";
  switch (value) {
    case "High":
      bgColor = "#30887e";
      break;

    case "Mid":
      bgColor = "#d58b5d";
      break;

    case "Low":
      bgColor = "#a84d4d";
      break;

    default:
      bgColor = "#30887e";
      break;

  }


  return (
    <>
      <div
        className="caseDiv"
        style={{ background: bgColor, minHeight: "32px", fontSize: "11px" }}
      >
        {value}
      </div>
    </>
  );
};

export default tableValues;
