import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  lazy,
  Suspense,
} from "react";
import "./tableViewJob.scss";
import "../../../../../assets/scss/extradiv.scss";
import "./jobboardtable.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  closedlist,
  clearState,
  updateJob,
} from "../../../../../slice/interviewSpec/jobsSlice";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
  useAsyncDebounce,
} from "react-table";

import {
  ButtonGroup,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import $ from "jquery";
//import Pagination from "@material-ui/lab/Pagination";
import TableLoader from "../../../../ui/tableLoader";
import moment from "moment-timezone";
import JobSpecStatus from "../../../../ui/dropdownList/jobSpecStatus";
import TableStatus from "./dropdown/status/tableStatus";

const TableSuspence = lazy(() =>
  import("../../../../ui/tableLoader/tableSuspence")
);

const useInstance = (instance) => {
  if (instance && instance.getInstanceCallback) {
    instance.getInstanceCallback(instance);
  }
};

function Table({ columns, data, getInstanceCallback, loading, createdAtId }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    prepareRow,
    // gotoPage,
    // state,
    setFilter,
    // canPreviousPage,
    // canNextPage,
    pageOptions,
    // pageCount,
    // nextPage,
    // previousPage,
    // setPageSize,
    // preGlobalFilteredRows,
    // setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      getInstanceCallback,
      autoResetFilters: false,
      autoResetRowState: false,
      initialState: {
        pageIndex: 0,
        pageSize: 400,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    (hooks) => hooks.useInstance.push(useInstance)
  );

  // const handlePageChange = (event, value) => {
  //   gotoPage(Number(value) - 1);
  // };

  const [filterType, setFilterType] = useState("");

  useEffect(() => {
    if (createdAtId === "0") {
      setTimeout(() => {
        setFilter("createdAt", "");
      }, 200);
    } else {
      setFilterType("createdAt");
      doFilter(createdAtId);
    }
  }, [createdAtId]);

  const doFilter = useCallback(
    useAsyncDebounce((id) => {
      setFilter(filterType, id);
    }, 200),
    [filterType]
  );
  return (
    <React.Fragment>
      <table
        className="table dkpipeline-newCVTable dkg-livejobs-table-235"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return loading ? (
              [...Array(10)].map((e, i) => (
                <tr>
                  <TableLoader colSpan="22" />
                </tr>
              ))
            ) : (
              <Suspense fallback={<TableSuspence colSpan="22" />}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className, // pay attention to this
                          style: cell.column.style,
                          // set here your other custom props
                        },
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              </Suspense>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td {...column.getFooterProps()}>{column.render("Footer")}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>

      <div className="col-sm-12">
        <div className="dk-resPaginationCon pagination">
          <div className="dk-resPagination">
            <div className="dk-resLeftPag">
              <div
                className="dk-texCounter"
                style={{ color: "#fff !imporant" }}
              >
                Showing {pageIndex + 1} to {pageOptions.length} (of{" "}
                {rows.length} entries)
              </div>
            </div>
            {/* <div className="dk-resrightPag pagination-btns">
                            <Pagination count={pageCount} shape="rounded" onChange={handlePageChange} />
                            <TablePagination count={pageCount} onChange={handlePageChange} />
                        </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function debounce(func, wait, immediate) {
  var timeout;

  return (...args) => {
    var context = this;

    var later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

function GlobalFilter({
  preGlobalFilteredRows,
  setGlobalFilter,
  isClearAll,
  restClearAllFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState("");

  // const [value, setValue] = React.useState(globalFilter)
  // const onChange = useAsyncDebounce(value => {
  //     setGlobalFilter(value || undefined)
  // }, 200)

  const onChangeSearchTitle = (e) => {
    const { value: nextValue } = e.target;
    setValue(e.target.value);
    debouncedSave(nextValue);
  };

  const debouncedSave = React.useCallback(
    debounce((nextValue) => {
      setGlobalFilter(nextValue);
    }, 1000)
  );

  const clearSearch = () => {
    setValue("");
    setTimeout(() => {
      setGlobalFilter(undefined);
    }, 200);
  };

  useEffect(() => {
    if (isClearAll) {
      clearSearch();
      restClearAllFilter();
    }
  }, [isClearAll]);

  return (
    <React.Fragment>
      <input
        value={value}
        className="form-control"
        onChange={onChangeSearchTitle}
        placeholder={`Search...`}
        onFocus={(e) => (e.target.placeholder = "")}
        onBlur={(e) => (e.target.placeholder = "Search...")}
      />
      {value !== undefined && value.length > 0 ? (
        <div className="input-group-append">
          <button
            className="btn btn-secondary"
            type="button"
            onClick={clearSearch}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
      ) : (
        <div className="input-group-append">
          <button className="btn btn-secondary" type="button">
            <i className="fa fa-search"></i>
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

const ClosedJob = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    isSuccess,
    isUpdate,
    message,
    closedjobInterviewSpecDataList,
  } = useSelector((state) => state.interviewSpec);

  const [data, setData] = useState([]);
  const [jobId, setjobId] = useState(0);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [columnKey, setColumnKey] = useState("");
  const [columnValue, setColumnValue] = useState("0");
  const [isUpdatePop, setIsUpdatePop] = useState(false);

  const [rowId, setRowId] = useState("0");

  const pathname = window.location.pathname;
  const segment3 = pathname.split("/")[3];
  const segment4 = pathname.split("/")[4];

  const openUpdateValuePop = (id, key, value) => {
    setRowId(id);
    setColumnKey(key);
    setColumnValue(value);
    setIsUpdatePop(true);
  };

  const hideUpdateValuePop = () => {
    setIsUpdatePop(false);
  };

  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);

  const [list, setList] = useState([]);

  useEffect(() => {
    document.title = "Jobs Spec | Recruitment | DK Global CRM";
  }, []);

  useEffect(() => {
    const fetchList = async () => {
      try {
        await dispatch(closedlist({ company: segment3, status: segment4 }));
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setData(closedjobInterviewSpecDataList);
      }, 200);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      //setData(closedjobseDataList);
      dispatch(closedlist({ company: segment3, status: segment4 }));
    }
  }, [isUpdate]);

  const [isProfile, setIsProfile] = useState(false);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [isRightFilter, setIsRightFilter] = useState(false);

  const showrightFilter = () => {
    setIsRightFilter(true);
    setShow1(true);
  };

  const [totalJobsNumber, setTotalJobsNumber] = useState([]);

  // const status = [
  //   {
  //     key: "normal",
  //     value: "Normal",
  //     text: "Normal",
  //   },
  //   {
  //     key: "urgent",
  //     value: "Urgent",
  //     text: "Urgent",
  //   },
  //   {
  //     key: "to qualify",
  //     value: "To qualify",
  //     text: "To qualify",
  //   },
  //   {
  //     key: "on hold",
  //     value: "On Hold",
  //     text: "On Hold",
  //   },
  //   {
  //     key: "closed",
  //     value: "Closed",
  //     text: "Closed",
  //   },
  // ];

  const columns = useMemo(
    () => [
      {
        id: "id",
        Header: "Job Spec ID",
        accessor: "job_id",
        Cell: ({ cell }) => {
          return (
            <React.Fragment>
              <div className="pipleFilterCols">{cell.row.original.job_id}</div>
            </React.Fragment>
          );
        },
      },

      {
        id: "status",
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }) => {
          const onItemClick = (id, columnId, value) => {
            updateMyData(id, columnId, value);
          };
          return (
            <React.Fragment>
              <div className="dk-caseStatusDropCon">
                <Dropdown id={`caseStatus${cell.row.original.id}`}>
                  <Dropdown.Toggle
                    variant=""
                    className="dk-caseStatusfliterbtn"
                  >
                    <TableStatus value={cell.row.original.status} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="caseStatusMenu">
                    <JobSpecStatus
                      id={cell.row.original.id}
                      columnId="status"
                      onItemClick={onItemClick}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },

      {
        id: "name",
        Header: "Title",
        accessor: "name",
        Cell: ({ cell }) => {
          return (
            <React.Fragment>
              <div className="jobSpecTitle">{cell.row.original.name}</div>
            </React.Fragment>
          );
        },
      },
      {
        id: "company",
        Header: "Company",
        accessor: "company",
      },
      {
        id: "category",
        Header: "Category",
        accessor: "category",
      },
      {
        id: "project",
        Header: "Project",
        accessor: "project",
      },

      {
        id: "role",
        Header: "Role Type",
        accessor: "role",
      },
      {
        id: "lang",
        Header: "Language",
        accessor: "lang",
      },
      {
        id: "createdAt",
        Header: "Created",
        accessor: (row) => moment(moment(row.createdAt)).format("DD MMM, YY"),
      },
      {
        id: "action",
        Header: "Action",
        accessor: "action",
        Cell: ({ cell }) => {
          return (
            <React.Fragment>
              <div className="pipleFilterAction">
                <Button
                  startIcon={<EditIcon />}
                  component={Link}
                  to="#"
                  size="small"
                  variant="contained"
                  color="success"
                ></Button>
                <Button
                  startIcon={<DeleteForeverIcon />}
                  component={Link}
                  to="#"
                  size="small"
                  variant="contained"
                  color="error"
                ></Button>
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  const [instanceData, setInstanceData] = useState("0");
  const reactTableInstance = (instance) => {
    if (instance) {
      setList(instance.flatRows);
      setInstanceData(instance);
    }
  };

  const updateMyData = (id, columnId, value) => {
    dispatch(updateJob({ id, key: columnId, value }));
  };

  const [showTotalJob, setShowTotalJob] = useState("0");

  useEffect(() => {
    const totalRow = totalJobsNumber.length;
    const totalJob = totalJobsNumber.reduce(
      (total, row) => (total = total + Number(row.original.no_of_jobs)),
      0
    );
    setShowTotalJob(totalJob);
  }, [totalJobsNumber]);

  const [rmFilterId, setRmFilterId] = useState("0");
  const [rmFilterImg, setRmFilterImg] = useState("0");

  const [rc3FilterId, setRc3FilterId] = useState("0");
  const [rc3FilterImg, setRc3FilterImg] = useState("0");
  const [raFilterId, setRaFilterId] = useState("0");
  const [raFilterImg, setRaFilterImg] = useState("0");

  const [r2FilterId, set2FilterId] = useState("0");
  const [r3FilterId, set3FilterId] = useState("0");
  const [rcFilterId, setRcFilterId] = useState("0");
  const [rcFilterImg, setRcFilterImg] = useState("0");

  const [amFilterId, setAmFilterId] = useState("0");
  const [amFilterImg, setAmFilterImg] = useState("0");

  const [prFilterId, setPrFilterId] = useState("0");
  const [prFilterImg, setPrFilterImg] = useState("0");

  const [statusId, setStatusId] = useState("0");
  const [valuesId, setValuesId] = useState("0");
  const [categoryId, setCategoryId] = useState("0");
  const [localityId, setLocalityId] = useState("0");
  const [companyId, setCompanyId] = useState("0");
  const [languageId, setLanguageId] = useState("0");
  const [projectId, setProjectId] = useState("0");
  const [roleId, setRoleId] = useState("0");
  const [cityId, setCityId] = useState("0");
  const [hireId, setHireId] = useState("0");
  const [dateId, setDateId] = useState("0");
  const [jobSpecId, setjobSpecId] = useState("0");
  const [jobStatusId, setjobStatusId] = useState("0");

  const [countryId, setCountryId] = useState("0");
  const [jobprofileId, setJobprofileId] = useState("0");
  const [updated_onId, setUpdated_onId] = useState("0");
  const [createdAtId, setCreatedAtId] = useState("0");

  const [checkBtnTxt, setCheckBtnTxt] = useState("Check All");
  const [countCheck, setCountCheck] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [dataMoveTo, SetDataMoveTo] = useState("");

  const handleToggle = () => {
    $(".chkCandidate").prop("checked", false);
    setIsActive(false);
  };

  const moveRecords = async (e) => {
    // console.log(e.target.dataset.moveto)
    SetDataMoveTo(e.target.dataset.moveto);
  };

  const checkExtraDivBtnTxt = (btnTxt) => {
    setCheckBtnTxt(btnTxt);
  };

  const [filterTags, setFilterTags] = useState([]);
  const [jobSpecFilter, setJobSpecFilter] = useState("Language");

  const [isClearAll, setIsClearAll] = useState(false);
  // const clearAllFilter = async () => {
  //   setRaFilterId("0");
  //   setRaFilterImg("0");
  //   setRcFilterId("0");
  //   setRcFilterImg("0");
  //   setAmFilterId("0");
  //   setAmFilterImg("0");
  //   setPrFilterId("0");
  //   setPrFilterImg("0");
  //   setCategoryId("0");
  //   setCountryId("0");
  //   setCompanyId("0");
  //   setProjectId("0");
  //   setRoleId("0");
  //   setLanguageId("0");
  //   setStatusId("0");
  //   setDateId("0");
  //   setHireId("0");
  //   setCityId("0");
  //   setJobprofileId("0");
  //   setUpdated_onId("0");
  //   setCreatedAtId("0");
  //   setjobSpecId("0");
  //   setJobSpecFilter("Language");
  //   setjobStatusId("0");
  //   setjobStatusFilter("Status");
  //   setFilterTags([]);
  //   setIsClearAll(true);
  // };

  const restClearAllFilter = async () => {
    setIsClearAll(false);
  };

  return (
    <React.Fragment>
      <div className="container-fluid dk-spec-live-cliBoardViewPageCont">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="dk-qulaifyTopHead">
              <div className="dk-qulaifyLeftTopHead">
                <div className="dk-SrchInputBox mr-2">
                  <div className="input-group">
                    {instanceData !== "0" ? (
                      <GlobalFilter
                        preGlobalFilteredRows={
                          instanceData.preGlobalFilteredRows
                        }
                        globalFilter={instanceData.state.globalFilter}
                        setGlobalFilter={instanceData.setGlobalFilter}
                        isClearAll={isClearAll}
                        restClearAllFilter={restClearAllFilter}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="mr-2 dk-reclivejobsBox">
                  {/* <CreateJobModal /> */}
                </div>
              </div>
              <div className="dk-qulaifyTopHeadTitle">
                <div className="dk-filterTagsCon">
                  <h3 className="title" style={{ fontSize: "22px" }}>
                    {segment3.replace("-", " ")} -{" "}
                    {closedjobInterviewSpecDataList.length}
                  </h3>
                </div>
              </div>
              <div className="dk-qulaifyRightTopHead">
                <div className="dk-qulaifyRightFilter mr-2">
                  <Button
                    startIcon={<ArrowBackIosIcon />}
                    component={Link}
                    to={
                      segment4 == "Active"
                        ? "/recruitment-tips/active-spec"
                        : "/recruitment-tips/inactive-spec"
                    }
                    variant="outlined"
                  >
                    Go Back
                  </Button>
                </div>

                {/* <div className="dk-qulaifyRightFilter mr-2">
                  <DropdownButton
                    className="StatusdropdownFilter"
                    id="dropdown-basic-button"
                    title="Values"
                  >
                    <Dropdown.Item href="#"
                      style={{ backgroundColor: "#30887e", color: "#fff" }}
                      data-type="values"
                      data-value="High"
                    >
                      High
                      <span
                        className="dropdown-filter-count"
                        data-type="values"
                        data-value="High"
                      >
                        10
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#"
                      style={{ backgroundColor: "#d58b5d", color: "#fff" }}
                      data-type="values"
                      data-value="Mid"
                    >
                      Mid
                      <span
                        className="dropdown-filter-count"
                        data-type="values"
                        data-value="Mid"
                      >
                        6
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#"
                      style={{ backgroundColor: "#a84d4d", color: "#fff" }}
                      data-type="values"
                      data-value="Low"
                    >
                      Low
                      <span
                        className="dropdown-filter-count"
                        data-type="values"
                        data-value="Low"
                      >
                        5
                      </span>
                    </Dropdown.Item>
                  </DropdownButton>
                </div> */}

                <div className="dk-qulaifyRightFilter"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid dk-spec-candinatePipeMain page-margin-top dkg-table-view-mainCon dkg-spec-table-view-mainCon dkg-pipelines-main-tableCon ">
        <div className="row">
          <div className="col-md-12">
            <div className="pipe-newCVTableCon table-responsive">
              <Table
                data={data}
                columns={columns}
                getInstanceCallback={reactTableInstance}
                rmFilterId={rmFilterId}
                raFilterId={raFilterId}
                rc3FilterId={rc3FilterId}
                amFilterId={amFilterId}
                prFilterId={prFilterId}
                categoryId={categoryId}
                localityId={localityId}
                companyId={companyId}
                languageId={languageId}
                projectId={projectId}
                roleId={roleId}
                cityId={cityId}
                hireId={hireId}
                statusId={statusId}
                valuesId={valuesId}
                rcFilterId={rcFilterId}
                dateId={dateId}
                jobSpecId={jobSpecId}
                jobStatusId={jobStatusId}
                loading={isLoading}
                countryId={countryId}
                jobprofileId={jobprofileId}
                updated_onId={updated_onId}
                createdAtId={createdAtId}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`batch-actions-menu-wrapper ${
          isActive ? "activePopup" : ""
        }`}
        style={{ width: "216px" }}
      >
        <div className="num-of-actions_wrapper">
          <div className="num-of-actions">{countCheck}</div>
        </div>
        <div className="batch-actions-title-section pl-0">
          <div className="dk-rowSelected">
            <span>Row Selected</span>
          </div>
        </div>
        {/* <div className="batch-actions-item dk-checkAll" onClick={toggleCheckAll}>
          <span><i className="fas fa-check"></i></span>
          {checkBtnTxt}
        </div> */}
        {/* <div className="batch-actions-item closed-checkboxbg" data-moveto="Closed" onClick={moveRecords}>
          <span data-moveto="Closed" onClick={moveRecords}>
            <i className="fa fa-arrow-circle-right" data-moveto="Closed" onClick={moveRecords}></i>
          </span>
          Archived
        </div> */}
        <div
          className="batch-actions-item archived-checkboxbg"
          data-moveto="Archive"
          style={{ backgroundColor: "#6a0707", color: "#fff" }}
          onClick={moveRecords}
        >
          <span data-moveto="Archive" onClick={moveRecords}>
            <i
              className="fa fa-arrow-circle-right"
              data-moveto="Archive"
              onClick={moveRecords}
            ></i>
          </span>
          Delete
        </div>
        <div
          className="batch-actions-delete-item CloseAll d-flex align-items-center justify-content-center"
          onClick={handleToggle}
        >
          <span>
            <i className="fas fa-times"></i>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClosedJob;
