import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { deleteDataValue,clearState } from "../../../../../../slice/companies/clients/clientSlice";
import { showSuccess, showError, clearMessage } from "../../../../../../slice/utils/message/messageSlice";

const DeleteValue = ({index,id,dataValue})=>{
  const dispatch = useDispatch();
  const { isError, message, isUpdate,isDelete } = useSelector(state => state.companies)

  const [value, setValue] = useState([])
    useEffect(() => {
        setValue(dataValue)
    }, [dataValue])

   useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(showSuccess({ message }))
        }
        if (isUpdate) {
            dispatch(clearState())
            dispatch(showSuccess({ message }))
        }
        if (isError) {
            dispatch(clearState())
            dispatch(showError({ message }))
        }
    }, [isUpdate, isError,isDelete])

  const updateValue = async (id) => {    
    dispatch(deleteDataValue({ id:id}))
    }

    return (
        <>
        <div className="d-flex align-items-center">
            <span className="dk-sno">{index}</span>
            <input type="text" name="list" className="form-control" value={value} readOnly/>            
            <div className="dk-toolsPanel-updateIcon" onClick={()=>updateValue(id)}><i className="far fa-trash-alt"></i></div>
        </div>
        </>
    )

}

export default DeleteValue;