import React, { useState } from "react";
import { Link } from "react-router-dom";
import PayInvoicesSubmenu from "../../../../elements/header/navbar/subMenu/menu/paymentsmodule/PayInvoicesSubmenu";
import PayLicenseLeftPanel from "../leftpanel/PayLicenseLeftPanel";
import TItleIcon from "../../../../../assets/images/license.png";
import '../payinvoices.scss';

const PayLicense = () => {
    return (
        <React.Fragment>
            <PayInvoicesSubmenu />

            <div className="dk-backBtnMain dk-payInvoicesBackBtn">
                <h3 className="dk-detailTitle">
                    <img src={TItleIcon} className="img-fluid" alt="" />
                    License
                </h3>
            </div>

            <div className="dk-trainingDetailMain dk-paymentJbDetailMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <PayLicenseLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                <h3 className="title">INDEED</h3>
                                <div className="dk-PayMonthCols">
                                    <div className="d-flex">
                                        <Link to="#" className="active">2022</Link>
                                        <Link to="#">2021</Link>
                                        <Link to="#">2020</Link>
                                        <Link to="#">2019</Link>
                                    </div>
                                </div>
                                <div className="dk-trainingInformation-cols">
                                    <div className="dk-payDetailRightPanel">
                                        <div className="dk-payTableSearch">
                                            <input type="text" placeholder="Search..." className="form-control" name="" id="" />
                                        </div>
                                        <div className="dk-rightPanelTable">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Sn.</th>
                                                        <th>Month</th>
                                                        <th>Status</th>
                                                        <th>Business</th>
                                                        <th>Services</th>
                                                        <th>Invoice No</th>
                                                        <th>Amount</th>
                                                        <th>Due Date</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Jan</td>
                                                        <td>
                                                            <div className="text-green">
                                                                Active
                                                            </div>
                                                        </td>
                                                        <td>Recruitment</td>
                                                        <td>Jobs</td>
                                                        <td>2358458</td>
                                                        <td>€ 200</td>
                                                        <td>10 May 2022</td>
                                                        <td>
                                                            <div className="dk-payAction">
                                                                <Link to="#" className="dk-edit"><i className="far fa-edit"></i></Link>
                                                                <Link to="#" className="dk-delete"><i className="far fa-trash-alt"></i></Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >
    );
}

export default PayLicense;