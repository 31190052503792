import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import InfoModal from './InfoModal';
import UpdateDetailsModal from './UpdateDetailsModal';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import './jobdetails.scss';
import JobCheckDetailModal from './JobCheckDetailModal';


const CommonTrainings = () => {

    const [showModalInfo, setInfoModalShow] = useState(false);
    const showInfoModal = () => {
        setInfoModalShow(true)
    }
    const hideInfoModal = () => {
        setInfoModalShow(false)
    }

    const [showModalUpdateDetails, setUpdateDetailModalShow] = useState(false);
    const showUpdateDetailsModal = () => {
        setUpdateDetailModalShow(true)
    }
    const hideUpdateDetailsModal = () => {
        setUpdateDetailModalShow(false)
    }

    const [showJobCheckDetails, setJobCheckModalShow] = useState(false);
    const showJobCheckDetailModal = () => {
        setJobCheckModalShow(true)
    }
    const hideJobCheckDetailModal = () => {
        setJobCheckModalShow(false)
    }
    return (
        <>

            {
                showModalInfo ?
                    <InfoModal handleClose={hideInfoModal} /> : null
            }

            {
                showModalUpdateDetails ?
                    <UpdateDetailsModal handleClose={hideUpdateDetailsModal} /> : null
            }

            {
                showJobCheckDetails ?
                    <JobCheckDetailModal handleClose={hideJobCheckDetailModal} /> : null
            }

            <div className="dk-jobDetailsMain dk-commonJobDetailMain">
                <div className="dk-jobdetails-header">
                    <div className="dk-backBtn-cols">
                        <Link to="/training/commontraining/common-training"><i className="fas fa-arrow-left"></i> Go Back</Link>
                    </div>
                    <h3 className="title">DKG Key TASKS SUMMARY <i className="fas fa-info-circle" onClick={showInfoModal}></i></h3>
                </div>
                <div className="dk-jobDetailsTable">
                    <table className="table dk-processTable table-bordered">
                        <tbody>
                            <tr>
                                <td className="tdBg1">
                                    <div className="heading">
                                        <small>TEAM 1</small>
                                        Resource Admin
                                    </div>
                                </td>
                                <td className="rowBg1" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Manage Ads</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg1" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Manage Apps</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg1" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Manage Jobs</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg1" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Job Boards</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg1" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Marketing</div>
                                    </ContextMenuTrigger>
                                </td>
                                <td className="rowBg1" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">DKG Database</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                            </tr>
                            <tr>
                                <td className="tdBg1">
                                    <div className="heading">
                                        <small>TEAM 2</small>
                                        Recruitment Admin
                                    </div>
                                </td>
                                <td className="rowBg2" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Review Profile</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg2" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Update Profile</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg2" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Ask Latest CV</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg2" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Check Visa</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg2" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Format CV</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg2" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Search CV's</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                            </tr>
                            <tr>
                                <td className="tdBg1">
                                    <div className="heading">
                                        <small>TEAM 3</small>
                                        Recruitment Admin
                                    </div>
                                </td>
                                <td className="rowBg3" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Mini Job Spec</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg3" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">SMS with Spec</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg3" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Spec Reminders</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg3" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Call Candidates</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg3" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Not Reachable</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg3" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Case Decision</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                            </tr>
                            <tr>
                                <td className="tdBg1">
                                    <div className="heading">
                                        <small>TEAM 4</small>
                                        REC CONSULTANT
                                    </div>
                                </td>
                                <td className="rowBg4" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Interview Call</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg4" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Full Job Spec</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg4" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Make Sendout</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg4" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Upload in Portal</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg4" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Not Reachable</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg4" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Case Decision</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                            </tr>
                            <tr>
                                <td className="tdBg1">
                                    <div className="heading">
                                        <small>TEAM 5</small>
                                        Account Manager
                                    </div>
                                </td>
                                <td className="rowBg5" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Submit Sendout</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg5" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Interviews</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg5" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Feedbacks</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg5" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Projections</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg5" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Jobs Demand</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                                <td className="rowBg5" onClick={showJobCheckDetailModal}>
                                    <ContextMenuTrigger>
                                        <div className="title">Live Jobs</div>
                                    </ContextMenuTrigger>
                                    <ContextMenu>
                                        <MenuItem onClick={showUpdateDetailsModal}>
                                            <i className="far fa-edit"></i> Edit
                                        </MenuItem>
                                    </ContextMenu>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </>
    )
}

export default CommonTrainings;
