import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const MainFaqNavbar = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    const segment4 = pathname.split("/")[4];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkGlobalFaqDepartmentsNavbar">
                <Route>
                    <ul>
                        <li><Link to="/maindepartment/main-faq/dashboard" className={(segment3 == 'dashboard') ? 'active' : ''}>Dashboard</Link></li>
                        <li><Link to="/maindepartment/main-faq/management-faq" className={`${(segment3 == 'management-faq') ? 'active' : ''}`}>B Overview</Link></li>
                        <li><Link to="/maindepartment/main-faq/recruitment-faq" className={`${(segment3 == 'recruitment-faq') ? 'active' : ''}`}>Recruitment</Link></li>
                        <li><Link to="/maindepartment/main-faq/newbusiness-faq" className={`${(segment3 == 'newbusiness-faq') ? 'active' : ''}`}>Clients</Link></li>
                        <li><Link to="/maindepartment/main-faq/marketing-faq" className={`${(segment3 == 'marketing-faq') ? 'active' : ''}`}>Advertisement</Link></li>
                        <li><Link to="/maindepartment/main-faq/resource-faq" className={`${(segment3 == 'resource-faq') ? 'active' : ''}`}>Suppliers</Link></li>
                        <li><Link to="/maindepartment/main-faq/accounting-faq" className={`${(segment3 == 'accounting-faq') ? 'active' : ''}`}>Accounting</Link></li>
                        <li><Link to="/maindepartment/main-faq/training-faq" className={`${(segment3 == 'training-faq') ? 'active' : ''}`}>HR</Link></li>
                        <li><Link to="/maindepartment/main-faq/legal-faq" className={`${(segment3 == 'legal-faq') ? 'active' : ''}`}>Training</Link></li>
                        <li><Link to="/maindepartment/main-faq/itteam-faq" className={`${(segment3 == 'itteam-faq') ? 'active' : ''}`}>IT Team</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default MainFaqNavbar;