import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";


const ForgetPassword = ({ onHide }) => {
  

  return (
    <>
      <Modal
        show={true}
        onHide={onHide}
        className="dkg-addnew-paymenttask-modal-21"
      >
        <Modal.Header closeButton className="dkg-addnew-payment-modalheader-21">
          <Modal.Title>Forget Password</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-addnew-payment-modalbody-21">
          
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ForgetPassword;
