import React from "react"
import Dropdown from 'react-bootstrap/Dropdown';
//import { useSelector } from "react-redux";
const JobTableValues = ({ id, columnId, onItemClick }) => {

    const status = [
        {
            key: 'High', value: 'High', text: 'High', Bgcolor: '#30887e',
        },
        {
            key: 'Mid', value: 'Mid', text: 'Mid', Bgcolor: '#d58b5d',
        },
        {
            key: 'Low', value: 'Low', text: 'Low', Bgcolor: '#a84d4d',
        }
    ]

    const selectValue = (value, color) => {
        onItemClick(id, columnId, value, color);
    }
    return (
        <React.Fragment>
            {
                status.map((statusItem, index) =>
                    <Dropdown.Item key={`case-status-id${index}`} href="#" style={{ color: "#fff", backgroundColor: statusItem.Bgcolor, }} onClick={() => selectValue(statusItem.value, statusItem.Bgcolor)}>
                        {statusItem.value}
                    </Dropdown.Item>
                )
            }
        </React.Fragment>
    )
}
export default JobTableValues;