import React from "react";
import { Link } from "react-router-dom";
import "./eventlogs.scss";
import { useSelector } from "react-redux";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import moment from "moment-timezone";
const EventLog = ({ refTable, refId, count, title }) => {
  //const dispatch = useDispatch();
  const { comments } = useSelector((state) => state.comments);

  return (
    <>
      {/* <div className="dkg-eventlog-mainCon">
        <ul className="dkg-eventslog-listCon">
          {comments.map((data, index) => (
            <li key={index + 1} className="dkg-eventslog-list current">
              <div className="dkg-case-stage-blockCon">
                <Link to="#" className="dkg-case-stage-block">
                  <label>Case Stage</label>
                  <div className="dkg-case-stage-blockbox">
                    <div className="date">
                      <i className="far fa-calendar-alt"></i>
                      {moment(data.updatedAt)
                        .tz("Europe/Dublin")
                        .format("DD MMM YY")}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: "&nbsp;",
                        }}
                      />
                      <i className="far fa-clock"></i>
                      {moment(data.updatedAt)
                        .tz("Europe/Dublin")
                        .format("hh:mm")}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="dkg-subject-blockCon">
                <Link to="#" className="dkg-subject-block">
                  <label>Subject</label>
                  <div className="dkg-subject-blockbox">{data.caseStage}</div>
                </Link>
              </div>
              <div className="dkg-event-desc-blockCon">
                <label className="dkg-message-label">Message Details</label>
                <div className="dkg-event-desc-blockHeader">
                  <div className="dkg-event-desc-title">
                    {" "}
                    {data.subject}
                  </div>
                </div>
                <div className="dkg-event-desc-blockBody">
                  {data.details != "" && data.details != null ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.details,
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div> */}
    </>
  );
};

export default EventLog;
