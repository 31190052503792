import React, { useMemo, useState, useEffect } from "react";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import { Form, InputGroup, FormControl } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown'
import { GlobalSearch } from "../GlobalSearch";
import AddNewModal from './modal/AddNew'
import EditModal from './modal/Edit'
import ViewModal from './modal/View'
import ReorderModal from './modal/Reorder'
import DeleteModal from "../../../ui/delete";
import './loins.scss'
import { detailsByid, deleteItRecord, updateStatus, clearState } from "../../../../slice/resources/Logins";
import { resourceLoginCategory } from "../../../../slice/layout/layoutSlice";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import FilterSelectInstance from './filter/SelectInstance';
import { DropdownButton } from 'react-bootstrap';

const BasicTable = () => {
    const dispatch = useDispatch();
    const { dataList, isDelete, msg, isError, isUpdate, isLoading } = useSelector(state => state.loginsResource)
    const data = dataList
    const { resourceLoginCategoryList } = useSelector(state => state.common)
    const [isAddNew, setIsAddNew] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [issDelete, setIsDelete] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isRorder, setIsRorder] = useState(false);
    const [userFilterId, setUserFilterId] = useState('0')
    const [rowId, setRowId] = useState('0')

    // const showaddnewModal = () => {
    //     setIsAddNew(true);
    // }
    const hideaddnewModal = () => {
        setIsAddNew(false);
    }
    const showeditModal = async (rowId) => {
        await dispatch(detailsByid({ rowId }))
        setIsEdit(true);
    }
    const hideeditModal = () => {
        setIsEdit(false);
    }
    // const showdeleteModal = () => {
    //     setIsDelete(true);
    // }
    // const hidedeleteModal = () => {
    //     setIsDelete(false);
    // }

    const showviewModal = async (rowId) => {
        await dispatch(detailsByid({ rowId }))
        setIsView(true);
    }
    const gotoUrl = async (url) => {
        window.open(url, "_blank")
    }
    const hideviewModal = () => {
        setIsView(false);
    }
    // const showreorderModal = () => {
    //     setIsRorder(true);
    // }
    const hidereorderModal = () => {
        setIsRorder(false);
    }
    const changeStatus = async (e) => {
        await dispatch(updateStatus({ rowId: e.target.id, status: e.target.dataset.key }))
    }

    const showeditpopup = () => {
        setIsEdit(true);
    }

    const deleteList = async (dataId) => {
        setRowId(dataId)
        setIsDelete(true);
    }

    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(clearMessage())
            dispatch(showSuccess({ msg: msg }))
            setIsDelete(false)
            setRowId('0')
        }
    }, [isDelete])

    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: msg }))
            setIsEdit(false);
            dispatch(clearState())
            // dispatch(clearLoading())
        }
        if (isUpdate) {
            setIsEdit(false);
            // setLoading(false);
            //   setSuccess(true);
            dispatch(showSuccess({ msg: msg }))
            dispatch(clearState())
            // dispatch(clearLoading())
        }
        dispatch(resourceLoginCategory({}))
    }, [isError, isUpdate])


    const columns = useMemo(() => [
        {
            Header: 'Sno',
            Cell: (row) => Number(row.row.id) + 1
        },
        {
            Header: 'Status',
            id: 'Status',
            Cell: ({ cell }) => {
                return (

                    <div className={(cell.row.original.status === "Inactive") ? 'dk-statusDrpCon dk-statusInactive' : 'dk-statusDrpCon'}>
                        <Dropdown>
                            <Dropdown.Toggle className="dk-statusbtn" variant="" id="dropdown-basic">
                                {(cell.row.original.status) === "Inactive" ? 'Inactive' : 'Active'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dk-statusMenu">
                                <Dropdown.Item id={cell.row.original.id} onClick={changeStatus} data-key='Active' className="dk-statusActive" >Active</Dropdown.Item>
                                <Dropdown.Item id={cell.row.original.id} onClick={changeStatus} data-key='Inactive' className="dk-statusInactive">Inactive</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )
            }
        },
        {
            Header: 'Category',
            accessor: 'category'
        },
        {
            Header: 'Name of Source',
            accessor: 'sourceName'
        },

        // {
        //     Header: 'Source Type',
        //     accessor: 'sourceType'
        // },
        {
            Header: 'User Name',
            accessor: 'userName'
        },
        {
            Header: 'Password',
            accessor: 'password'
        },
        {
            Header: 'Website Link',
            accessor: 'websiteLink',
            Cell: (row) => (
                <div className="dk-gotobtnurlCon">
                    <Link to="#" onClick={() => gotoUrl(row.row.original.websiteLink)} className="dk-goto-url-btn">
                        Go to URL
                    </Link>
                </div>
            )
        },
        {
            Header: 'Action',
            Cell: (row) => (
                <div className="dk-resAction">

                    <Link to="#" className="dk-view-btn mr-2" onClick={() => showviewModal(row.row.original.id)}>
                        <i className="fas fa-eye"></i>
                    </Link>
                    <Link to="#" onClick={() => showeditModal(row.row.original.id)} className="dk-edit-btn mr-2">
                        <i className="fas fa-edit"></i>
                    </Link>
                    <Link to="#" onClick={() => deleteList(row.row.original.id)} className="dk-delete-btn"><i className="fas fa-trash-alt"></i></Link>
                </div>
            )
        }
    ], [])

    const tableInstance = useTable({
        columns: columns,
        data: data,
        initialState: {
            pageIndex: 0, pageSize: 100
        },
    }, useGlobalFilter, useFilters, useSortBy)

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter, setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize } } = tableInstance
    const { globalFilter } = state
    useEffect(() => {
        setUserFilterId('0')
        setGlobalFilter('0')
    }, [])

    const handleClose = () => {
        setRowId('0')
        setIsEdit(false)
        setIsDelete(false)
    }

    const deleteItem = async () => {
        dispatch(deleteItRecord({ rowId }))
    }

    // const handleChangeSelect = (event, data) => {
    //     const { name, value } = data
    //     switch (name) {
    //         case "sourceType":
    //             setFilter(name, value)
    //             break;

    //         case "category":
    //             setFilter(name, value)
    //             break;
    //     }
    // }
    const [categoryFilterText, setCategoryFilterText] = useState('Category')

    const onSelectStatusFilter = (eventKey) => {
        setFilter("category", eventKey)
        setCategoryFilterText(eventKey)
    }

    const removeTag = async (e) => {
        setCategoryFilterText('Category')
        setFilter("category", '')
    }


    return (
        <>
            {
                (isAddNew) ? <AddNewModal hidePopup={hideaddnewModal} /> : null
            }
            {
                (isEdit) ? <EditModal hidePopup={hideeditModal} showeditpopup={showeditpopup} /> : null
            }
            {
                (issDelete) ? <DeleteModal id={rowId} handleClose={handleClose} deleteItem={deleteItem} /> : null
            }
            {
                (isView) ? <ViewModal hidePopup={hideviewModal} /> : null
            }
            {
                (isRorder) ? <ReorderModal hidePopup={hidereorderModal} /> : null
            }

            <div className="row dk-loginsreSTopHead">
                <div className="col-md-4 col-sm-12">
                    <div className="dk-loginsresLeftHead">
                        <div className="dk-resSrchInpuBbox mr-2">
                            <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                                Username
                            </Form.Label>
                            <InputGroup className="mb-0">
                                {/* <FormControl id="inlineFormInputGroup" placeholder="Search ..." /> */}
                                <GlobalSearch filter={globalFilter} setFilter={setGlobalFilter} />
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <i className="fas fa-search"></i>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </div>
                        <div className="mr-2 dk-resCrcleIconCon">
                            <AddNewModal />
                        </div>
                        {/* <div className="mr-2 dk-resCrcleIconCon">
                            <Link to="#" className="dk-resCrcleIcon">
                                <i className="fas fa-file-alt"></i>
                            </Link>
                        </div> */}
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="dk-resPageTitleCon">
                        <h2 className="dk-resPageTitle">ALL LOGINS - {dataList.length} </h2>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="dk-loginsresRighttHead">
                        <div className="mr-2 dk-resCrcleIconCon">
                            <ReorderModal dataList={data} />
                        </div>
                        {
                            (!isLoading && dataList.length > 1) ?
                                <React.Fragment>
                                    <div className="dk-rightFilter">
                                        <DropdownButton className='dk-cli-status-dropdown' title={categoryFilterText} onSelect={onSelectStatusFilter}>
                                            <Dropdown.Item eventKey="Job Board" href="#" className="dk-activeBtn" >Job Board
                                                <span className="dkg-lv-statuscounter">
                                                    {
                                                        tableInstance.flatRows.filter(function (val) {
                                                            return val.original.category === 'Job Board';
                                                        }).length
                                                    }
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey="Social Media" href="#" className="dk-inActiveBtn">Social Media
                                                <span className="dkg-lv-statuscounter">
                                                    {
                                                        tableInstance.flatRows.filter(function (val) {
                                                            return val.original.category === 'Social Media';
                                                        }).length
                                                    }
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey="Communication" href="#" className="">Communication
                                                <span className="dkg-lv-statuscounter">
                                                    {
                                                        tableInstance.flatRows.filter(function (val) {
                                                            return val.original.category === 'Communication';
                                                        }).length
                                                    }
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey="Clients Portals" href="#" className="">Clients Portals
                                                <span className="dkg-lv-statuscounter">
                                                    {
                                                        tableInstance.flatRows.filter(function (val) {
                                                            return val.original.category === 'Clients Portals';
                                                        }).length
                                                    }
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey="API Services" href="#" className="">API Services
                                                <span className="dkg-lv-statuscounter">
                                                    {
                                                        tableInstance.flatRows.filter(function (val) {
                                                            return val.original.category === 'API Services';
                                                        }).length
                                                    }
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey="Server Hosting" href="#" className="">Server Hosting
                                                <span className="dkg-lv-statuscounter">
                                                    {
                                                        tableInstance.flatRows.filter(function (val) {
                                                            return val.original.category === 'Server Hosting';
                                                        }).length
                                                    }
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey="Domain Hosting" href="#" className="">Domain Hosting
                                                <span className="dkg-lv-statuscounter">
                                                    {
                                                        tableInstance.flatRows.filter(function (val) {
                                                            return val.original.category === 'Domain Hosting';
                                                        }).length
                                                    }
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey="Online Payments" href="#" className="">Online Payments
                                                <span className="dkg-lv-statuscounter">
                                                    {
                                                        tableInstance.flatRows.filter(function (val) {
                                                            return val.original.category === 'Online Payments';
                                                        }).length
                                                    }
                                                </span>
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                    {
                                        (categoryFilterText !== 'Category') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="category" onClick={removeTag}></i> : null
                                    }

                                </React.Fragment>
                                : null
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="dk-loginsresTableCon table-responsive">
                        <table className="table dk-loginsresTable table-bordered" {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BasicTable
