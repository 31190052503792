import React from "react";

const OurServices = ({ servicedetails }) => {
  return (
    <>
      <div className="dkg-ourservices-mainCon">
      <div className="dkg-ourservices-secondRow">
          <div className="dkg-ourservice-header">
            <div className="dkg-ourservice-title">OUR CLIENTS SERVICES</div>
          </div>
          <div className="dkg-ourservice-body dkg-ourservice-new-bodyCon">
            <p className="dkg-service-desc dkg-ourservice-desc">
              {servicedetails != "" && servicedetails != null ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: servicedetails.serviceClient,
                  }}
                ></div>
              ) : (
                ""
              )}
            </p>
            <div className="dkg-ourservice-tableCon">
              <table className="table dkg-ourservice-table table-bordered">
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>RECRUITMENT SERVICES</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>RECRUITERS TRAINING</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>MARKET INTELLIGENCE</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>SALARY PROJECTIONS</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>HIRING PROJECTIONS</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>GUEST SPEAKER</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="dkg-services-middle-div"></div>
        <div className="dkg-ourservices-firstRow">
          <div className="dkg-ourservice-header">
            <div className="dkg-ourservice-title">OUR JOBSEEKER SERVICES</div>
          </div>
          <div className="dkg-ourservice-body dkg-ourservice-new-bodyCon">
            <p className="dkg-service-desc dkg-ourservice-desc">
              {servicedetails != "" && servicedetails != null ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: servicedetails.serviceJobseekers,
                  }}
                ></div>
              ) : (
                ""
              )}
            </p>
            <div className="dkg-ourservice-tableCon">
              <table className="table dkg-ourservice-table table-bordered">
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>CREATE NEW RESUME</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>CREATE DESIRED JOB</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>SERACH & APPLY JOB</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>LEAVE CURRENT JOB</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>CAREER ASSESSMENT</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>NEGOTIATE SALARIES</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>GET JOB PROMOTION</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>PROFESSIONAL EMAILS</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>INTERVIEWS PREPARTION</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>WORK ABROAD CONSULTATION</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>STUDY ABROAD CONSULTATION</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>CAREER PLANNING CONSULTATION</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>WORK ABROAD CONSULTATION</td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>STUDY ABROAD CONSULTATION</td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>CAREER PLANNING CONSULTATION</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurServices;
