import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData, DeleteData } from "../../services";

export const addValue = createAsyncThunk(
    'perfornancesBonus/team/add',
    async ({ updatedDate,year,month,period,uid,model,teams,departments,jobPosition,rating,bonus }, thunkAPI) => {
        const res = await PostData(true, '/performance-bonus', { updatedDate,year,month,period,uid,model,teams,departments,jobPosition,rating,bonus })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const updateValue = createAsyncThunk(
    'perfornancesBonus/team/update',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/performance-bonus/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateUser = createAsyncThunk(
    'perfornancesBonus/team/updateUser',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/performance-bonus/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const performanceFun = createAsyncThunk(
    'perfornancesBonus/team/countlist',
    async ({ year, month },thunkAPI) => {
        const res = await GetData(true, '/performance-bonus/' + year + '/' + month)      
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const listAll = createAsyncThunk(
    'perfornancesBonus/team/list',
    async ({ year, month },thunkAPI) => {
        const res = await GetData(true, '/performance-bonus/lists/'+ year + '/' + month)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const annualSaleList = createAsyncThunk(
    'perfornancesBonus/teamAnnual/list',
    async ({ year }, thunkAPI) => {
        const res = await GetData(true, '/sendout-kpis/' + year )
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const annualMonthlySaleList = createAsyncThunk(
    'perfornancesBonus/teamMonthlyAnnual/list',
    async ({ year,month }, thunkAPI) => {
        const res = await GetData(true, '/sendout-kpis/monthly/' + year )
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


export const bulkDelete = createAsyncThunk(
    'perfornancesBonus/team/bulkDelete',
    async ({id }, thunkAPI) => {
        const res = await DeleteData(true, '/performance-bonus/' + id )
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const PerformanceSlice = createSlice({
    name: 'perforne_bonus',
    initialState: {
        dataList: [],
        clientKpiDataList: [],
        annualDataList: [],
        annualMonthlyDataList: [],
        performanceDataList: [],
        userData: {},
        isLoading: false,
        isPrLoading: false,
        isError: false,
        isSuccess: false,
        isUpdate: false,
        message: '',
        isPerformanceDelete: false,
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isPrLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.message = '';
            state.isPerformanceDelete = false;
            state.isUpdate = false;
        },
        bulkDeleteState: (state) => {
            state.isDelete = true
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            addValue.pending, (state) => {
                state.isSuccess = false;
            });
        builder.addCase(
            addValue.fulfilled, (state, { payload }) => {
                state.dataList.push(payload.result)
                state.isSuccess = true;
            });
        builder.addCase(
            addValue.rejected, (state, action) => {
                state.isSuccess = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
  

        builder.addCase(
            updateValue.pending, (state) => {
                state.isUpdate = false
            });
        builder.addCase(
            updateValue.fulfilled, (state, { payload }) => {
                let mainArray = state.dataList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.dataList = finalResult;
            });
        builder.addCase(
            updateValue.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            performanceFun.pending, (state) => {
                state.isPrLoading = true;
            });
        builder.addCase(
            performanceFun.fulfilled, (state, { payload }) => {
                state.performanceDataList = payload.result
                state.isPrLoading = false;
            });
        builder.addCase(
            performanceFun.rejected, (state, action) => {
                state.isPrLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            listAll.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            listAll.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            listAll.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
       
       builder.addCase(
            annualSaleList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            annualSaleList.fulfilled, (state, { payload }) => {
                state.annualDataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            annualSaleList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

       builder.addCase(
            annualMonthlySaleList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            annualMonthlySaleList.fulfilled, (state, { payload }) => {
                state.annualMonthlyDataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            annualMonthlySaleList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateUser.pending, (state) => {
                  //state.isLoading = false;
                  state.isUpdate = false
            });
        builder.addCase(
            updateUser.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isUpdate = true;
                let mainArray = state.dataList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.dataList = finalResult
                state.msg = payload.message
            });
        builder.addCase(
            updateUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });


        builder.addCase(
            bulkDelete.pending, (state) => {
                state.isPerformanceDelete = false;
            });
        builder.addCase(
            bulkDelete.fulfilled, (state, action) => {
                //state.dataList = state.dataList.filter((item) => item.id !== action.meta.arg.id);
                       
                state.isPerformanceDelete = true;
            });
        builder.addCase(
            bulkDelete.rejected, (state, { payload }) => {
                state.isError = payload.message;
                state.errorMessage = payload.message;
            });
    }
});
export const { clearState, bulkDeleteState } = PerformanceSlice.actions;
export default PerformanceSlice.reducer;