import React from 'react';

const RefusedStage = ({ value }) => {

    let bgColor = "";
    switch (value) {

        case '51':
            bgColor = '#d9714e'
            break;
        case '52':
            bgColor = '#d9714e'
            break;

        case 'Case Closed':
            bgColor = '#a84d4d'
            break;

        case '54':
            bgColor = '#a84d4d'
            break;

        default:
            bgColor = '#a84d4d'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor, color: '#fff', minWidth: '80px' }}>{value}</div>
        </>
    )
}

export default RefusedStage;