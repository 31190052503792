import React from 'react';
import { Modal, Dropdown } from 'react-bootstrap';
import LegalDatepicker from "./LegalDatepicker";

const AddNewModal = ({ onHide }) => {
    return (
        <>
            <Modal className="dk-yearsAddNewTask-modal dk-addNewDkgStaffModal right" show={true} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>CREATE NEW Corporate Tax</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">TYPE</label>
                                <label htmlFor="">
                                    <Dropdown className='dkg-return-status-dropdown'>
                                        <Dropdown.Toggle variant="" className='dkg-return-status-dropdown-toggle'>
                                            Select
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='dkg-return-status-dropdown-Menu' style={{ borderRadius: "0", padding: "0" }}>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' style={{ backgroundColor: "#3a9469", color: "#fff" }} href="#">Invoice</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' style={{ backgroundColor: "red", color: "#fff" }} href="#">Credit</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Year</label>
                                <label htmlFor="">
                                    <Dropdown className='dkg-return-status-dropdown'>
                                        <Dropdown.Toggle variant="" className='dkg-return-status-dropdown-toggle'>
                                            Select
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='dkg-return-status-dropdown-Menu'>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">2016</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">2017</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">2018</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">2019</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">2020</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">2021</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">2022</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">2023</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">2024</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">2025</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">2026</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">2027</Dropdown.Item>
                                            
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Month</label>
                                <label htmlFor="">
                                    <Dropdown className='dkg-return-status-dropdown'>
                                        <Dropdown.Toggle variant="" className='dkg-return-status-dropdown-toggle'>
                                            Select
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='dkg-return-status-dropdown-Menu'>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">Jan</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">Feb</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">Mar</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">Apr</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">May</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">Jun</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">Jul</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">Aug</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">Sep</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">Oct</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">Nov</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' href="#">Dec</Dropdown.Item>
                                            
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Inv Issued</label>
                                <label htmlFor="">
                                    <LegalDatepicker />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Inv Due</label>
                                <label htmlFor="">
                                    <LegalDatepicker />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Name of Business</label>
                                <label htmlFor="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Services Provided</label>
                                <label htmlFor="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Amount</label>
                                <label htmlFor="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Attachment File</label>
                                <input type="file" id="imageInputAPI" className="form-control w-100 dkg-payment-attach-input" />
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group justify-content-center mt-2'>
                                <button className="dkaddBtn">Add</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default AddNewModal;
