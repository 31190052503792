import React, { useEffect, useState, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import "air-datepicker/air-datepicker.css";
import AWS from "aws-sdk";
import { Modal } from "react-bootstrap";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FaEdit, FaArrowsAlt, FaEye, FaDownload, FaTrashAlt } from "react-icons/fa";
import AddfileModal from './modal/AddfileModal';
import EditfileModal from './modal/EditfileModal'
import ReorderModal from './modal/ReorderModal'
import DeleteDummy from './modal/DeleteDummy'


import {
  clearState,
  updateJob,
  updateJobWithTextBox,
} from "../../../../../../slice/jobs/jobsSlice";

const FullDetails = (jobId) => {
  const dispatch = useDispatch();

  const { isDetailsLoading, jobDetails, isUpdate } = useSelector(
    (state) => state.jobs
  );
  const [status, setStatus] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setData(jobDetails);
      setStatus(jobDetails.status);
    }
  }, [isDetailsLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobDetails);
    }
  }, [isUpdate]);
  let ids = data.id;

  const [selectedFile, setSelectedFile] = useState(null);
  const [isImageUpdate, setIsImageUpdate] = useState(false);
  const [newImageData, setNewImageData] = useState();
  const [defaultPreview, setDefaultPreview] = useState(<h3>Browse File</h3>);

  const defaultImage = () => {
    setSelectedFile(null);
    setNewImageData("");
  };

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
      var fileToLoad = e.target.files[0];
      var fileReader = new FileReader();
      fileReader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result; // <--- data: base64
        var newImage = document.createElement("img");
        newImage.src = srcData;
        //console.log(newImage.outerHTML)
        setIsImageUpdate(true);
        setNewImageData(newImage.src);
      };
      fileReader.readAsDataURL(fileToLoad);

      let s3bucket = new AWS.S3({
        accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
        secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
        Bucket: process.env.REACT_APP_BUCKET_NAME,
      });

      s3bucket.createBucket(function () {
        var params = {
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: Date.now() + "-" + e.target.files[0].name.replace(/ /g, "_"),
          Body: e.target.files[0],
          ContentType: e.target.files[0].type,
        };
        s3bucket.upload(params, function (err, data) {
          if (err) {
            console.log(err);
          } else {
            dispatch(
              updateJob({ id: ids, key: "interview_url", value: data.Key })
            );
          }
        });
      });
    }
  };

  const [show, setShow] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const showDeleteModal = async () => {
    setIsDelete(true);
  };
  const hideDeleteModal = () => {
    setIsDelete(false);
  };

  const handleShow = async () => {
    setShow(true);
  };

  const updateMyData = (e) => {
    let values = e.target.value;
    let columnId = e.target.dataset.columnid;
    dispatch(updateJobWithTextBox({ id: ids, key: columnId, value: values }));
  };

  const deleteFile = async () => {
    dispatch(updateJob({ id: ids, key: "interview_url", value: "" }));
  };
  let description =
    data.description !== undefined && data.description !== null
      ? data.description
      : "";

  let fileExten = data.interview_url ? data.interview_url.split(".").pop() : "";

  let allFile =
    fileExten === "pdf"
      ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + "/" + data.interview_url
      : "https://view.officeapps.live.com/op/embed.aspx?src=" +
      process.env.REACT_APP_IMG_CLOUD_BASE_URL +
      "/" +
      data.interview_url;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const html = description;
  const contentBlock = htmlToDraft(html);

  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );

  useEffect(() => {
    setEditorState(EditorState.createEmpty());
    if (description !== "") {
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [description]);

  const [convertedContent, setConvertedContent] = useState(null);
  const handleEditorChange = (state) => {
    console.log(editorState.getCurrentContent());

    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    dispatch(
      updateJobWithTextBox({
        id: ids,
        key: "description",
        value: currentContentAsHTML,
      })
    );
  };

  return (
    <>
      {
        (isDelete) ? <DeleteDummy onHide={hideDeleteModal} /> : null
      }

      <div className="row mt-3">
        <div className="col-md-12 dk-suMMRightCon dk-jobsuMMBottomSec mt-0">
          {/* <div
            className="dk-suMMRightConBoxBg"
            style={{ float: "left", width: "100%" }}
          >
            <div className="dk-suMMRightBoxHeader">
              <h3>Job Description</h3>
            </div>
            <div
              className="dk-createhtmEditor"
              style={{ float: "left", width: "100%" }}
            >
              <div
                className="form-control text-left"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "0px",
                  background: "#eaeaea",
                  float: "left",
                  width: "100%",
                  height: "auto",
                }}
              >
                <div className="col-md-12 mt-2 pb-2" style={{ float: "left" }}>
                  <div className="dkaddNewRightCon">
                    <div className="addNewBox">
                      <div className="dk-FileUploadCon ">
                        <div className="dk-job-fileUldimgCon">
                          <figure id="edit_image">{defaultPreview}</figure>
                        </div>
                        <div className="dk-browsedFile">
                          <button
                            onChange={onSelectFile}
                            style={{ marginTop: "15px" }}
                          >
                            <input type="file" id="" name="" accept="image/*" />
                            <i className="fas fa-upload mr-2"></i> Upload
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {data.interview_url !== "" && data.interview_url !== null ? (
                  <>
                    <div className="col-md-12 mt-2" style={{ float: "left" }}>
                      <div className="dkaddNewRightCon">
                        <div className="addNewBox">
                          <div className="dk-FileUploadCon ">
                            <div
                              className="dk-uploadView-img m-0"
                              style={{ minHeight: "89px" }}
                            >
                              <p style={{ wordBreak: "break-all" }}>
                                {data.interview_url}
                              </p>
                            </div>
                          </div>
                          <button
                            onClick={handleShow}
                            className="btn btn-info mt-3"
                            style={{ width: "44%", padding: "9px 0px 9px 0px" }}
                          >
                            <i className="fas fa-eye mr-2"></i> View
                          </button>
                          <button
                            onClick={deleteFile}
                            className="btn btn-danger mt-3"
                            style={{ width: "44%", padding: "9px 0px 9px 0px" }}
                          >
                            <i className="fas fa-trash mr-2"></i> Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div> */}
          <div className="dkg-edit-jobfiles-mainCon">
            <div className="d-flex justify-content-end dkg-add-new-btnCon">
              <ReorderModal />
              <AddfileModal />
            </div>
            <div className="dkg-edit-jobfiles-tableCon">
              <table className="dkg-edit-jobfiles-table table table-bordered">
                <thead>
                  <tr>
                    <th>SNo.</th>
                    <th>File Name</th>
                    <th>File Type</th>
                    <th>View</th>
                    <th>Download</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>readme.txt</td>
                    <td>txt</td>
                    <td>
                      <div className="dkg-file-actions">
                        <Link to="#" className="dkg-viewfile-icon mr-2">
                          <FaEye />
                        </Link>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-file-actions">
                        <Link to="#" className="dkg-download-icon mr-2">
                          <FaDownload />
                        </Link>
                      </div>
                    </td>
                    <td>
                      <div className="dkg-file-actions">
                        <EditfileModal />
                        <Link to="#" className="dkg-deletefile-icon" onClick={showDeleteModal}>
                          <FaTrashAlt />
                        </Link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dk-pipedocs-previewModal"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        animation={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="dk-pipedocs-preview-modalbody">
          <iframe src={allFile} frameBorder={0}></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FullDetails;
