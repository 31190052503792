import React from 'react';
import ModelNavtabs from './navtabs/ModelNavtabs';
import { FaCheck, FaTimes } from 'react-icons/fa';
import './gitstarted-workmodel.scss';


const GetstartedModelTwo = () => {

    return (
        <React.Fragment>
            <div className="dkg-getStarted-workModelMain">
                <ModelNavtabs />
                <div className='dkg-workModel-mainCon'>
                    <div className="dkg-workModel-tableCon">
                        <table className="table table-bordered dkg-workModel-table-1 model-2">
                            <thead>
                                <tr>
                                    <th>Department</th>
                                    <th>Job Title</th>
                                    <th>Job Type</th>
                                    <th>Model</th>
                                    <th>BASIC SALARY PM</th>
                                    <th>Performance Bonus</th>
                                    <th>Sendout Bonus</th>
                                    <th>Placement Bonus</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Recruitment</td>
                                    <td>Recruitment Consultant</td>
                                    <td>Full Time</td>
                                    <td>Model 2</td>
                                    <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                    <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                    <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                    <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default GetstartedModelTwo;

