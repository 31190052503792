import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import "./campaigns.css";
import CreateNewModal from './modal/CreateNew';
import AddNew from "./modal/AddNew";
import ReorderModal from './modal/ReorderModal';
import EditModal from './modal/Edit';
import DeleteModal from './modal/DeleteCampaigns';
import ViewModal from './modal/View';
import AddnewTitleModal from './modal/AddnewTitle';
import SearchInputDropDwn from '../../../ui/Dropdown/SearchInputDropDwn';
import DateFormat from "../../../ui/dateFormats/DateFormat";
import { useDispatch, useSelector } from "react-redux";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { usersList, campaignJobBoardList, campaignCompanyList, campaignRoleList, campaignLanguageList, listTable, campaignProjectList, clearState, campaignMove, deleteCampaign, campaignCategory } from '../../../../slice/campaign/campaignSlice';
import $ from "jquery";
import DeleteCampModal from '../../../ui/Modal/deletemodal/DeleteModal';
import { showSuccess } from "../../../utils/messages/messageSlice";


const Campaigns = () => {
    const { dataList, isDataLoading, jobBoardList, companyList, rolesList, languageList, projectList, issDelete, categoryList } = useSelector(state => state.campaignAds)

    const dispatch = useDispatch();
    const [isCreateNew, setIsCreateNew] = useState(false);
    const [isAddNew, setIsAddNew] = useState(false);
    const [isAddNewTitle, setIsAddNewTitle] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isReorder, setIsReorder] = useState(false);
    const [data, setData] = useState([]);
    const [rowId, setRowId] = useState('');
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [isDeleteModal, setIsDeleteModal] = useState(false)

    useLayoutEffect(() => {
        dispatch(usersList({}))
        dispatch(campaignJobBoardList({}))
        dispatch(campaignCompanyList({}))
        dispatch(campaignRoleList({}))
        dispatch(campaignLanguageList({}))
        dispatch(campaignProjectList({}))
        dispatch(campaignCategory({}))
    }, [])

    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(listTable({ 'type': "Live" }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [])

    const showcreateNewModal = () => {
        setIsCreateNew(true)
    }
    const hidecreateNewModal = () => {
        setIsCreateNew(false)
    }
    const showaddNewModal = (name, title, id) => {
        setName(name)
        setTitle(title)
        setRowId(id)
        setIsAddNew(true)
    }
    const hideaddNewModal = () => {
        setIsAddNew(false)
    }
    const showaddNewTitleModal = (name, title, id) => {
        setName(name)
        setTitle(title)
        setRowId(id)
        setIsAddNewTitle(true)
    }
    const hideaddNewTitleModal = () => {
        setIsAddNewTitle(false)
    }
    const showDeleteModal = (id) => {
        setRowId(id)
        setIsDelete(true)
    }
    const deleteRowFun = (id) => {
        setRowId(id)
        setIsDeleteModal(true)
        //  console.log(id)
    }
    const deleteCampRecord = () => {
        setIsDeleteModal(false);
    }
    const closeCampModel = () => {
        setIsDeleteModal(false)
    }
    const deleteCampFun = async () => {
        await dispatch(deleteCampaign({ id: rowId }))
    }

    useEffect(() => {
        if (!isDataLoading && dataList && dataList.length > 0) {
            setData(dataList)
        } else {
            setData(dataList)
        }
    }, [isDataLoading, issDelete])

    useEffect(() => {
        if (issDelete) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Delete successfully.' }))
            setRowId('0')
            setIsDeleteModal(false);
        }
    }, [issDelete])

    const hideDeleteModal = () => {
        setIsDelete(false)
    }
    const showEditModal = () => {
        setIsEdit(true)
    }
    const hideEditModal = () => {
        setIsEdit(false)
    }
    const showViewModal = () => {
        setIsView(true)
    }
    const hideViewModal = () => {
        setIsView(false)
    }

    const closeDelete = () => {
        setIsDelete(false)
    }

    const closefunction = async () => {
        await dispatch(campaignMove({ "id": rowId, "type": "Closed" }))
    }

    const searchFunction = (e) => {
        var value = (e.target.value).toLowerCase();
        $("#myUL div.dk-campiangnsboxCon").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    }

    return (
        <>
            {
                (isDeleteModal) ? <DeleteCampModal id={rowId} onHide={deleteCampRecord} deleteItem={deleteCampFun} handleClose={closeCampModel} /> : null
            }

            {
                (isCreateNew) ? <CreateNewModal onHide={hidecreateNewModal} type={"Live"} /> : null
            }
            {
                (isAddNew) ? <AddNew onHide={hideaddNewModal} name={name} value={title} id={rowId} type="Live" /> : null
            }
            {
                (isAddNewTitle) ? <AddnewTitleModal onHide={hideaddNewTitleModal} name={name} value={title} id={rowId} type="Live" /> : null
            }
            {
                (isDelete) ? <DeleteModal onHide={hideDeleteModal} title="Are you sure wants to make close from Live ?" handleClose={closeDelete} closeFunction={closefunction} /> : null
            }
            {
                (isEdit) ? <EditModal onHide={hideEditModal} /> : null
            }
            {
                (isView) ? <ViewModal onHide={hideViewModal} /> : null
            }

            <div className="container-fluid dk-compgBlockCont">
                <div className="row">
                    <div className="col-md-12">
                        <div className="dk-topBlockCont">
                            <div className="dk-compgPageHead">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="dk-compgLeft">
                                        <div className="dk-mailshotsSearch mr-2">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search..." onChange={searchFunction} />
                                                <div className="input-group-append">
                                                    <button className="btn btn-secondary" type="button">
                                                        <i className="fa fa-search"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <Link onClick={showcreateNewModal} to="#" className="dk-campgiansIcon mr-2">
                                            <i className="fas fa-plus"></i>
                                        </Link>
                                        <ReorderModal dataList={data} type={"Live"} />
                                    </div>
                                    <h2 className="title">LIVE AD CAMPAIGNS</h2>
                                    <div className="dk-compgTopRight d-flex">
                                        <Link to="/campaigns/live" className="liveBtn mr-2">LIVE</Link>
                                        <Link to="/campaigns/closed" className="closeBtn dkactivepage">CLOSED</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-campiangnsCon">
                                <div className="dk-campiangnsSrcoll" id="myUL">
                                    {dataList.map((data, index) => (
                                        < div className="dk-campiangnsboxCon" key={index + 1}>
                                            <div className="d-none">{data.userInfo.firstName}</div>
                                            <div className="dk-campiangnsboxCon1">
                                                <div className="dk-campiagnsFboxcols" id="dk-firstBox_cols1">
                                                    <ContextMenuTrigger className="campaignDeleteItem" id={"deleteItem" + index}>
                                                        <div onClick={() => showDeleteModal(data.id)} className="boxCols redTag"></div>
                                                    </ContextMenuTrigger>
                                                    <ContextMenu className="dk-campContextMenu campaignDeleteItem" id={"deleteItem" + index}>
                                                        <MenuItem className="dk-campaignDeleteItem" onClick={() => deleteRowFun(data.id)}>
                                                            Delete Campaign
                                                        </MenuItem>
                                                    </ContextMenu>
                                                    {/* <div onClick={() => showDeleteModal(data.id)} className="boxCols redTag"></div> */}
                                                    <div className="dk-workTableRecImg">
                                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + data.userInfo.profileImage} className="img-fluid" title={data.userInfo.firstName} alt={data.userInfo.firstName} />
                                                    </div>
                                                </div>
                                                <div className="dk-campiagnsFboxcols" id="dk-firstBox_cols2">
                                                    <div onClick={() => showaddNewTitleModal('jobTitle', data.jobTitle, data.id)} className="dk-workTitle">{data.jobTitle}</div>
                                                </div>
                                                <div className="dk-campiagnsFboxcols" id="dk-firstBox_cols3">
                                                    <span className="dk-tabletdTitle">Job Board</span>
                                                    <div className="dk-campiagnsDrpDwn">
                                                        <SearchInputDropDwn type="Live" list={jobBoardList} value={data.jobBoard} id={data.id} name="jobBoard" />
                                                    </div>
                                                </div>
                                                <div className="dk-campiagnsFboxcols" id="dk-firstBox_cols4">
                                                    <span className="dk-tabletdTitle">Budget</span>
                                                    <span onClick={() => showaddNewModal('budget', data.budget, data.id)} className="textblue">€ {data.budget}</span>
                                                </div>
                                                <div className="dk-campiagnsFboxcols" id="dk-firstBox_cols5">
                                                    <span className="dk-tabletdTitle">Started</span>
                                                    <span className="textblue"> <DateFormat date={data.startDate} /></span>
                                                </div>
                                                <div className="dk-campiagnsFboxcols" id="dk-firstBox_cols6">
                                                    <span className="dk-tabletdTitle">Expired</span>
                                                    <span className="textblue"><DateFormat date={data.expired} /></span>
                                                </div>
                                                <div className="dk-campiagnsFboxcols" id="dk-firstBox_cols7">
                                                    <span className="dk-tabletdTitle">Leads</span>
                                                    <span onClick={() => showaddNewModal('leads', data.leads, data.id)} className="textblue">{data.leads}</span>
                                                </div>
                                                <div className="dk-campiagnsFboxcols" id="dk-firstBox_cols8">
                                                    <span className="dk-tabletdTitle">Sendouts</span>
                                                    <span onClick={() => showaddNewModal('placements', data.placements, data.id)} className="textblue">{data.placements}</span>
                                                </div>
                                            </div>
                                            <div className="dk-campiangnsboxCon2">
                                                <div className="dk-campiagnsSecondboxcols" id="dk-secBox_cols1">
                                                    <span className="dk-tabletdTitle">Category</span>
                                                    <div className="dk-campiagnsDrpDwn">
                                                        <SearchInputDropDwn type="Live" list={categoryList} value={data.category} id={data.id} name="category" />

                                                    </div>
                                                </div>
                                                <div className="dk-campiagnsSecondboxcols" id="dk-secBox_cols2">
                                                    <span className="dk-tabletdTitle">Company</span>
                                                    <div className="dk-campiagnsDrpDwn">
                                                        <SearchInputDropDwn type="Live" list={companyList} value={data.company} id={data.id} name="company" />
                                                    </div>
                                                </div>
                                                <div className="dk-campiagnsSecondboxcols" id="dk-secBox_cols3">
                                                    <span className="dk-tabletdTitle">Project</span>
                                                    <div className="dk-campiagnsDrpDwn">
                                                        <SearchInputDropDwn type="Live" list={projectList} value={data.project} id={data.id} name="project" />
                                                    </div>
                                                </div>
                                                <div className="dk-campiagnsSecondboxcols" id="dk-secBox_cols5">
                                                    <span className="dk-tabletdTitle">Role Type</span>
                                                    <div className="dk-campiagnsDrpDwn">
                                                        <SearchInputDropDwn type="Live" list={rolesList} value={data.roleType} id={data.id} name="roleType" />
                                                    </div>
                                                </div>
                                                <div className="dk-campiagnsSecondboxcols" id="dk-secBox_cols4">
                                                    <span className="dk-tabletdTitle">Language</span>
                                                    <div className="dk-campiagnsDrpDwn">
                                                        <SearchInputDropDwn type="Live" list={languageList} value={data.language} id={data.id} name="language" />
                                                    </div>
                                                </div>
                                                <div className="dk-campiagnsSecondboxcols" id="dk-secBox_cols6">
                                                    <span className="dk-tabletdTitle">Location</span>
                                                    <div className="dk-campiagnsDrpDwn">
                                                        <span onClick={() => showaddNewModal('location', data.location, data.id)} className="textblue">{data.location}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    }
                                    <div className="checkoboxbottom">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Campaigns;
