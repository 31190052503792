import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import sorter from 'sort-nested-json';

export const updateByKey = createAsyncThunk(
    'costBenifits/updateKeyfetchbyId',
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/advertisment-performance/update/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        key, value
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const fetchById = createAsyncThunk(
    'costBenifits/performance/fetchbyId',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/advertisment-performance/' + id,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const listTable = createAsyncThunk(
    'costBenifits/performance/listing',
    async ({ year, month, type }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/advertisment-performance/type/' + year + '/' + month + '/' + type,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateValue = createAsyncThunk(
    'costBenifits/performance/update',
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/advertisment-performance/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        key, value
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateDataValue = createAsyncThunk(
    'costBenifits/performance/update',
    async ({ id, year, month, type, resource, serviceProvided, budget, newApps, sendouts, placements, revenue }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/advertisment-performance/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        year, month, type, resource, serviceProvided, budget, newApps, sendouts, placements, revenue
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const addValue = createAsyncThunk(
    'costBenifits/performance/add',
    async ({ year, month, type, resource, serviceProvided, budget, newApps, sendouts, placements, revenue }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/advertisment-performance/',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        year, month, type, resource, serviceProvided, budget, newApps, sendouts, placements, revenue
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const reorderClient = createAsyncThunk(
    'costBenifits/performance/reorder',
    async ({ ids }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/advertisment-performance/reorder',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        ids
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const deleteFunction = createAsyncThunk(
    'costBenifits/performance/delete',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/advertisment-performance/' + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

const clientKpiSlice = createSlice({
    name: 'recrutersKpiReport',
    initialState: {
        dataList: [],
        fetchdataList: [],
        isLoading: false,
        isInsert: false,
        isError: false,
        isUpdate: false,
        isReorder: false,
        isPerformanceDelete: false,
        sortKey: '',
        isSort: false,
        errorMessage: '',
        isBulkDelete: false,
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isReorder = false;
            state.isPerformanceDelete = false;
            state.isSort = false;
            state.errorMessage = '';
            state.isBulkDelete = false;
            return state;
        },
        sortData: (state, { payload }) => {
            if (payload.key == state.sortKey) {
                state.dataList = sorter.sort(state.dataList).desc(payload.key)
                state.sortKey = ''
            } else {
                state.sortKey = payload.key;
                state.dataList = sorter.sort(state.dataList).asc(payload.key)
            }
            state.isSort = true;
        },
        bulkDeleteState: (state) => {
            state.isPerformanceDelete = true
        }
    },
    extraReducers: {
        [fetchById.pending]: (state) => {
            // state.isLoading = true;
        },
        [fetchById.fulfilled]: (state, { payload }) => {
            state.fetchdataList = payload.result;
            state.isLoading = false;
        },
        [fetchById.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },

        [listTable.pending]: (state) => {
            state.isLoading = true;
        },
        [listTable.fulfilled]: (state, { payload }) => {
            state.dataList = payload.result;
            state.isLoading = false;
        },
        [listTable.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },


        [updateByKey.pending]: (state) => {
        },
        [updateByKey.fulfilled]: (state, { payload }) => {
            let mainArray = state.dataList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.dataList = finalResult;
            state.isUpdate = true;
        },
        [updateByKey.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message;
        },

        [updateValue.pending]: (state) => {
        },
        [updateValue.fulfilled]: (state, { payload }) => {
            let mainArray = state.dataList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.dataList = finalResult;
            state.isUpdate = true;
        },
        [updateValue.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message;
        },

        [updateDataValue.pending]: (state) => {
            state.isUpdate = false;
        },
        [updateDataValue.fulfilled]: (state, { payload }) => {
            state.dataList = payload.result;
            state.isUpdate = true;
        },
        [updateDataValue.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message;
            state.isUpdate = false;
        },
        [addValue.pending]: (state) => {
        },
        [addValue.fulfilled]: (state, { payload }) => {
            // state.dataList.push(payload.result);
            state.dataList.unshift(payload.result);
            state.isInsert = true;
        },
        [addValue.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message;
            state.isInsert = false;
        },
        [reorderClient.pending]: (state) => {
        },
        [reorderClient.fulfilled]: (state, { payload }) => {
            state.isReorder = true;
        },
        [reorderClient.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message;
            state.isReorder = false;
        },
        [deleteFunction.pending]: (state) => {
        },
        [deleteFunction.fulfilled]: (state, action) => {
            state.isPerformanceDelete = true;
            state.dataList = state.dataList.filter((item) => item.id !== action.meta.arg.id);
        },
        [deleteFunction.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isPerformanceDelete = false;
        },
    }
});

export const { clearState, sortData, bulkDeleteState } = clientKpiSlice.actions;
export default clientKpiSlice.reducer;