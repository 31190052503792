import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './adminmodule.scss';
import Knowledgebase from '../../../../assets/images/knowledge.png';
import PublicRel from '../../../../assets/images/public-relation.png';
import Management from '../../../../assets/images/management.png';
import Finance from '../../../../assets/images/finance.png';
import Holidays from '../../../../assets/images/calendar-white.png';
import MyFile from '../../../../assets/images/file-folder.png';
import Clients from '../../../../assets/images/clientsmanagement_icon.png';
import Supplier from '../../../../assets/images/supplier.png';
import Planing from '../../../../assets/images/preparation_icon.png';
import Ads from '../../../../assets/images/ads.png';
import Reports from '../../../../assets/images/report_white.png';
import Euro from '../../../../assets/images/euro.png';
import FbWhite from '../../../../assets/images/socials-media.png';
import SaveWhite from '../../../../assets/images/save-white.png';
import ThinkingWhite from '../../../../assets/images/thinking.png';
import InformationIcon from '../../../../assets/images/information_white.png';
import { useDispatch, useSelector } from "react-redux";

import { setMyModule } from "../../../../slice/modules/moduleSlice";
import { showSuccess, showError } from "../../../../components/utils/messages/messageSlice";
import Moduleheader from "../../../elements/header/index";

const AdminDashboard = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        document.title = "DK Global Admin"
    }, []);
    const { userInfo } = useSelector(state => state.auth);

    const setModule = async (myModule, moduleColor) => {
        await dispatch(setMyModule({ module: myModule, color: moduleColor }))
        switch (myModule) {

            case 'budget':
                if (userInfo.role === "Super Admin" || userInfo.role === "Admin") {
                    history.push("/budget/summary");
                } else {
                    dispatch(showError({ msg: 'You are not authorized to view' }))
                }
                break;

            case 'live-ads':
                history.push("/live-ads/live-tracker");
                break;

            case 'ads-plan':
                history.push("/ads-plan/today-tracker");
                break;
            case 'saved-ads':
                history.push("/saved-ads/summary");
                break;

            case 'sample-ads':
                history.push("/sample-ads/summary");
                break;

            case 'ads-tracker':
                history.push("/ads-tracker/daily-tracker");
                break;

            case 'banners':
                history.push("/banners/summary");
                break;

            case 'campagins':
                history.push("/campaigns/live");
                break;

            case 'job-boards':
                history.push("/job-boards/summary");
                break;

            case 'knowleadge-base':
                history.push("/knowleadge-base/summary");
                break;

            default:
                break;
        }
    }
    return (
        <>
            <Moduleheader dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" linkcolor="#3c4c62" mainheaderTitle="ADVERTISEMENT" textcolor="#4c5f59" />
            <div className="dk-master-moduleCon">
                <div className="dk-module-blocksContainer dk-adminContainer">
                    <h4 className="title">ADVERTISEMENT DEPARTMENT</h4>
                    <div className="dk-portalCols">
                        <div className="dkg-adver-top-box">
                            <div className="title">MONTHLY BUDGET</div>
                            <div className="dk-textPanel">
                                <table className="table dkg-accounting-boxtable dkg-monthly-budget-table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='dkg-red-counter' style={{ color: "#333" }}>1223<span className="ml-1">€</span></div>
                                            </td>
                                            <td>
                                                <div className='dkg-green-counter'>1870<span className="ml-1">€</span></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="dkg-adver-top-box">
                            <div className="title">LIVE ADS</div>
                            <div className="dk-textPanel">
                                <table className="table dkg-accounting-boxtable">
                                    <tbody>
                                        <tr>

                                            <td>
                                                <div className='dkg-green-counter'>87</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="dkg-adver-top-box">
                            <div className="title">LIVE CAMPAIGN</div>
                            <div className="dk-textPanel">
                                <table className="table dkg-accounting-boxtable">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='dkg-red-counter'>23</div>
                                            </td>

                                        </tr>
                                    </tbody></table>
                            </div>
                        </div>
                        <div className="dkg-adver-top-box">
                            <div className="title">MONTHLY APPS</div>
                            <div className="dk-textPanel">
                                <table className="table dkg-accounting-boxtable">
                                    <tbody>
                                        <tr>

                                            <td>
                                                <div className='dkg-green-counter'>87</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* <div className="dk-portalBox dk_portalBox_9 ml-5">
                            <Link to="#" onClick={() => setModule('admintraining', '#b55355')}>
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={Training}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Training</div>
                                </div>
                            </Link>
                        </div> */}
                    </div>
                    <div className="dk-portalCols">
                        <div className="dk-portalBox dk_portalBox_5">
                            <Link to="#" onClick={() => setModule('budget', '#a43b63')}>
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={Euro}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Cost/Benefits</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_portalBox_7">
                            <Link to="#" onClick={() => setModule('live-ads', '#a43b63')}>
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={Holidays}
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Live Ads</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_portalBox_8">
                            <Link to="#" onClick={() => setModule('campagins', '#657d74')}>
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={FbWhite}
                                            alt="PR"
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Live Campaigns</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_supplierbgcolor">
                            <Link to="#" onClick={() => setModule('ads-tracker', '#a43b63')}>
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={Reports}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Job Apps</div>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="dk-portalBox dk_portalBox_itteam ml-5" style={{ background: '#258fb4' }}>
                            <Link to="#" onClick={() => setModule('it-teams', '#258fb4')}>
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={ItTeamImg}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">IT Team</div>
                                </div>
                            </Link>
                        </div> */}
                    </div>
                    <div className="dk-portalCols">
                        <div className="dk-portalBox dk_portalBox_support">
                            <Link to="#" onClick={() => setModule('job-boards', '#535255')}>
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={MyFile}
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Resource List</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_portalBox_4" style={{ background: '#4c5f59' }}>
                            <Link to="#" onClick={() => setModule('saved-ads', '#a43b63')} >
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={SaveWhite}
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Saved Ads</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_frlancerresource">
                            <Link to="#" onClick={() => setModule('ads-plan', '#a43b63')}>
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={ThinkingWhite}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Daily Ads Plan</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox dk_portalBox_support">
                            <Link to="#" onClick={() => setModule('sample-ads', '#535255')}>
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={InformationIcon}
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">Knowledge Base</div>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="dk-portalBox dk_salestips_bg ml-5" style={{ background: '#258fb4' }}>
                            <Link to="#" onClick={() => setModule('dkglobal', '#258fb4')}>
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={DkGlobal}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="dk-PortalTitle">DK Global</div>
                                </div>
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminDashboard;