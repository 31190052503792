import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import DefultProfile from "../../../../../assets/images/default_profile_empty.png";

const AddTeamRolesUsers = ({ handleClose }) => {
    const [showUserResults, setShowUser] = React.useState(false)
    const onClick = () => setShowUser(true)
    return (
        <>
            <Modal className="dk-teamrolesjobsummaryModal" show={true} centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>RECRUITMENT TEAMS & ROLES</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-editRoles-modalBoy">
                        <div className="dk-addRecuirters">
                            {
                                showUserResults ?

                                    <div className="dk-addUserDropdown">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                <img src={DefultProfile} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <div className="d-flex">
                                                    <Link to="#"><img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="" /></Link>
                                                    <Link to="#"><img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5e54fdac9c.png" alt="" /></Link>
                                                    <Link to="#"><img src="https://www.dkmasterbox.com/assets/upload/user_image/1523633534-luiza.jpg" alt="" /></Link>
                                                    <Link to="#"><img src="https://www.dkmasterbox.com/assets/upload/user_image/5c3a2270ae02e.png" alt="" /></Link>
                                                    <Link to="#"><img src="https://www.dkmasterbox.com/assets/upload/user_image/maria2.png" alt="" /></Link>
                                                    <Link to="#"><img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" alt="" /></Link>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div> : null
                            }
                            <div className="dk-addMoreUser" onClick={setShowUser}>+1</div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button className="dk-saveBtn">Save</button>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default AddTeamRolesUsers;
