import {useEffect, useState, useRef} from "react";
import Modal from "react-bootstrap/Modal";
import SubmitButton from "../../../ui/submitButton";
import {
    showSuccess,
    showError,
    clearMessage,
  } from "../../../../slice/utils/message/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    forwardMail,
    clearState
  } from "../../../../slice/mailbox/mailboxSlice";

// forwardMail

const ForwardMail = (props) => {

    const { isLoading, isBtnSuccess, message, signature } = useSelector(
        (state) => state.mailbox
    );
    const [selectedAccount, setSelectedAccount] = useState([]);
    const dispatch = useDispatch();
    
    const handleSubmit = (e) => {
        e.preventDefault();
        if (selectedAccount.length == 0) {
            dispatch(showError({ msg: "Please select at least one account" }));
            return;
        }
        dispatch(forwardMail({ mail_id: props.mailId, user_ids: selectedAccount }));
    }

    const clearForm = () => {
        setSelectedAccount([]);
    };

    useEffect(() => {
        if (isBtnSuccess) {
          clearForm()
          dispatch(showSuccess({ msg: message }));
          props.onHide();
          dispatch(clearState());
        }
      }, [isBtnSuccess]);

    return (
        <>
            <Modal className="dk-replyMailModal modelWidth" {...props}>
                <Modal.Header closeButton>
                    <Modal.Title>FORWARD</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-composeMailForm">
                        <form
                            onSubmit={handleSubmit}
                            encType="multipart/form-data"
                        >
                            <div className="row">
                                {
                                    props.myAccounts.map((account) => (
                                        account.type == 'user' && (
                                            <div className="col-md-6">
                                                <div className="checkbox">
                                                    <label className="checkbox-wrapper">
                                                        <input type="checkbox" className="checkbox-input" name="selectedAccount" value={account.id} onChange={(e) => {
                                                            if(e.target.checked)
                                                                setSelectedAccount([...selectedAccount, e.target.value]);
                                                            else
                                                                setSelectedAccount(selectedAccount.filter(item => item !== e.target.value))
                                                        }} />
                                                        <span className="checkbox-tile">
                                                            <span className="checkbox-icon">
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                                                    account.profileImage
                                                                }
                                                                alt={account.name}
                                                                title={account.domain}
                                                            />
                                                            </span>
                                                            <div className="checkbox-icon-content">
                                                                <div className="checkbox-label">{ account.name }</div>
                                                                <div className="checkbox-label">{ account.domain }</div>
                                                            </div>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        )
                                    ))
                                }
                            </div>
                            <div className="dk-textCompose-mail" style={{ marginTop: '10px' }}>
                                <div className="dkg-send-btnCon">
                                    <SubmitButton  txt="Send" loading={isLoading} success={isBtnSuccess} position="justify-content-center" className="dkg-reply-send-btn" />
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ForwardMail;
