import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import AddNew from '../../addnew/AddNew'

const AddNewListView = ({ handleClose }) => {
    return (
        <>
            <Modal className="dk-editCompanyList-modal" show={true} onHide={handleClose}>
                <Modal.Header closeButton>
                    {/* <span className="dk-idType d-none">Client ID - {clientId}</span> */}
                    <Modal.Title>ADD NEW CLIENT</Modal.Title>
                </Modal.Header>
                <Modal.Body className='dkg-listview-AddNewBody'>
                    <AddNew />
                </Modal.Body>
            </Modal>
            
        </>
    )
}

export default AddNewListView;
