import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CandTabContentOverview from './overview/Overview';
import CandExperience from "./experience/Experience";
import CandQualification from './qualification/Qualification';
import CandOtherInfo from './otherinfo/OtherInfo';

const OnlineCvModal = ({ hideOnlineCv }) => {
    return (
        <>
            <Modal className="dk-onlineCvModal" show={true} onHide={hideOnlineCv}>
                <Modal.Header closeButton>
                    <Modal.Title>Online CV</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CandTabContentOverview />
                    <CandExperience />
                    <CandQualification />
                    <CandOtherInfo />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OnlineCvModal;
