import React from 'react';
import { Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import { FaUserAlt, FaSpinner, FaRegCalendarAlt } from 'react-icons/fa';

const SearchModal = ({ onHide }) => {
    return (
        <>
            <Modal className="dk-sendoutsSearch-modal dk-dkgStafSearchModal right" show={true} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>SEARCH CLIENTS REGISTRATIONS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-sendoutFilterTabs-main dk-dkgStaff-modalBody">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                            <div className="dk-sendoutTabs-nav">
                                <Nav variant="">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_1"><FaRegCalendarAlt /> Start Date</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_2"><FaUserAlt /> Expire On</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_3"><FaUserAlt /> Contract Type</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_4"><FaUserAlt /> Name of Entity/Person</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_5"><FaSpinner /> Services Offered</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="dk-sendoutTabs-filterPanel">
                                <Tab.Content>
                                    <Tab.Pane eventKey="tabs_1">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li><Link to="#">10 July 2022 <span>0</span></Link></li>
                                                    <li><Link to="#">12 July 2022 <span>0</span></Link></li>
                                                    <li><Link to="#">15 July 2022 <span>0</span></Link></li>
                                                    <li><Link to="#">10 August 2022 <span>0</span></Link></li>
                                                    <li><Link to="#">20 August 2022 <span>0</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_2">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li><Link to="#">10 July 2022 <span>0</span></Link></li>
                                                    <li><Link to="#">12 July 2022 <span>0</span></Link></li>
                                                    <li><Link to="#">15 July 2022 <span>0</span></Link></li>
                                                    <li><Link to="#">10 August 2022 <span>0</span></Link></li>
                                                    <li><Link to="#">20 August 2022 <span>0</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_3">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li><Link to="#">56879554 <span>0</span></Link></li>
                                                    <li><Link to="#">56879554 <span>0</span></Link></li>
                                                    <li><Link to="#">56879554 <span>0</span></Link></li>
                                                    <li><Link to="#">56879554 <span>0</span></Link></li>
                                                    <li><Link to="#">56879554 <span>0</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_4">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-satusListFilter">
                                                <ul>
                                                    <li><Link to="#" style={{ background: '#629bd3', color: '#fff' }}>Job Offer <span>0</span></Link></li>
                                                    <li><Link to="#" style={{ background: '#a84d4d', color: '#fff' }}>Refused <span>0</span></Link></li>
                                                    <li><Link to="#" style={{ background: '#4768b0', color: '#fff' }}>Joined <span>0</span></Link></li>
                                                    <li><Link to="#" style={{ background: '#af0f0f', color: '#fff' }}>Credit <span>0</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_5">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li><Link to="#">To Be Reviewed <span>30</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_6">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li><Link to="#">DK Global Recruitment <span>0</span></Link></li>
                                                    <li><Link to="#">Wipro <span>0</span></Link></li>
                                                    <li><Link to="#">TCS <span>0</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_7">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li><Link to="#">Confidential <span>0</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_8">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li><Link to="#">English <span>0</span></Link></li>
                                                    <li><Link to="#">Irish <span>0</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_9">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li><Link to="#">Reporting Analyst <span>0</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_10">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li><Link to="#">MLV <span>0</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default SearchModal;
