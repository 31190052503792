
import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from "react-redux";
import { paymentCounts, paidPaymentCounts,archivePaymentCounts } from "../../../../../../slice/task/paymentTaskSlice"
import { counts, archivedCounts } from "../../../../../../slice/task/taskSlice"

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const IncomeMenu = (props) => {
    const dispatch = useDispatch();
    const { isSuccess, isMoved, isArchive, countsProgress, archivedDataCount } = useSelector(state => state.task);
    const { paymentDataCount, paidPaymentDataCount,archiveDataCount, issSuccess, isTaskUpdate, isDataMoveToPayment } = useSelector(state => state.paymentTask);
    const classes = useStyles();
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    const { userInfo } = useSelector(state => state.auth);

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);
    useEffect(() => {

        if (isSuccess) {
            const fetchList = async () => {
                try {
                    await dispatch(counts({}))

                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }
        if (isMoved) {
            const fetchList = async () => {
                try {
                    await dispatch(counts({}))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }
        if (isArchive) {
            const fetchList = async () => {
                try {
                    await dispatch(archivedCounts({}))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }

        if (issSuccess) {
            const fetchList = async () => {
                try {
                    await dispatch(paidPaymentCounts({roletype: userInfo.role}))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }
        if (isDataMoveToPayment) {
            const fetchList = async () => {
                try {
                    await dispatch(paymentCounts({roletype: userInfo.role}))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }
        dispatch(archivedCounts({}))
        dispatch(paidPaymentCounts({roletype: userInfo.role}))
        dispatch(paymentCounts({roletype: userInfo.role}))
        dispatch(archivePaymentCounts({roletype: userInfo.role}))
    }, [isSuccess, isMoved, isTaskUpdate, isDataMoveToPayment, issSuccess, isArchive])

    const ref = useRef(null);
    const { onClickOutside } = props;

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalfinanceIncomeMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/accounting/payments/summary" className={(segment3 == 'summary') ? 'active' : ''}>Summary</Link></li>
                        <li>
                            <Link to="/accounting/payments/to-pay" className={`${(segment3 == 'to-pay') ? 'active' : ''}`}>
                                {
                                    (paymentDataCount) ?
                                        <span className="dk-workplan-badgeCon dk-payments-topay-bgCon">
                                            <span className="dk-workplan-badgenumber payments-topay-bgNo">
                                                {paymentDataCount}
                                            </span>
                                        </span>
                                        :
                                        null
                                }
                                To Pay
                            </Link>
                        </li>
                        <li>
                            <Link to="/accounting/payments/paid" className={`${(segment3 == 'paid') ? 'active' : ''}`}>
                                {
                                    (paidPaymentDataCount) ?

                                        <span className="dk-workplan-badgeCon dk-payments-topay-bgCon" style={{ background: '#3a9469' }}>
                                            <span className="dk-workplan-badgenumber payments-topay-bgNo">
                                                {paidPaymentDataCount}
                                            </span>
                                        </span>
                                        : null
                                }
                                Paid
                            </Link>
                        </li>
                        <li>
                            <Link to="/accounting/payments/archived" className={`${(segment3 == 'archived') ? 'active' : ''}`}>
                                {
                                    (archiveDataCount) ?

                                        <span className="dk-workplan-badgeCon dk-payments-topay-bgCon" style={{ background: '#ff0000' }}>
                                            <span className="dk-workplan-badgenumber payments-topay-bgNo">
                                                {archiveDataCount}
                                            </span>
                                        </span>
                                        : null
                                }
                                Archived
                            </Link>
                        </li>
                        
                        {/* <li><Link to="/accounting/payments/archived" className={`${(segment3 == 'archived') ? 'active' : ''}`}>Achieved</Link></li> */}
                        {/* <li><Link to="/accounting/payments/submitted" className={`${(segment3 == 'submitted') ? 'active' : ''}`}>Client Pay</Link></li>
                        <li><Link to="/accounting/payments/bibby-fee" className={`${(segment3 == 'bibby-fee') ? 'active' : ''}`}>Bibby Fee</Link></li>
                        <li><Link to="/accounting/payments/debtors" className={`${(segment3 == 'debtors') ? 'active' : ''}`}>Debtor List</Link></li>
                        <li><Link to="/accounting/payments/contract" className={`${(segment3 == 'contract') ? 'active' : ''}`}>Agreement</Link></li>
                        <li><Link to="/accounting/payments/terms/overview" className={`${(segment3 == 'terms') ? 'active' : ''}`}>Glossary</Link></li> */}
                        <li><Link to="/accounting/payments/faq" className={`${(segment3 == 'faq') ? 'active' : ''}`}>FAQ</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default IncomeMenu;