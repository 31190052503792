import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Modal, Tab, Nav, Dropdown } from "react-bootstrap";
import "./comment.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  commentList,
  clearState,
} from "../../../slice/comments/commentSlice";
import { showSuccess } from "../../../slice/utils/message/messageSlice";
import { getApplications } from "../../../slice/candidates/list/listSlice";
import {
  addProgress,
  getprogress,
  updateProgress,
  deleteProgress,
} from "../../../slice/candidates/list/listSlice";

import { updateCommentCount } from "../../../slice/candidates/list/listSlice";
import { updateTaskCommentCount } from "../../../slice/task/taskSlice";
import { updateLiveJobsCommentCount } from "../../../slice/jobs/jobsSlice";
import CaseOther from "../../../components/pages/candidate/pipeline/dropdown/other/appliedStatus";

import {
  getJobseekarValues,
  getJobseekarStageValues,
  getJobseekarProgressValues,
  getJobseekarSituationValues,
} from "../../../slice/layout/layoutSlice";

import CommonDropdown from "../../../components/pages/candidate/pipeline/modal/candidateprofile/appications/BgStatus";

import UserList from "../../ui/userList/UserListByRoleForCandidates";
import SubmitButton from "../../ui/submitButton";
import CommentLoader from "../../ui/commentLoader/";
import Delete from "../../ui/delete/";
import moment from "moment-timezone";
import { Markup } from "interweave";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const useMountEffect = (fun) => useEffect(fun, []);


const CaseCommentProgress = ({
  refTable,
  refId,
  count,
  title,
  commentHandleClose,
  itemdata,
}) => {

  const dispatch = useDispatch();
  const {
    isCommentLoading,
    isCommentInsert,
    comments,
    details,
    isSuccess,
    isUpdate,
    isInsert,
    isDelete
  } = useSelector((state) => state.comments);
  const { isProgressAdd, progressList, isProgress, isProgressDelete } =
    useSelector((state) => state.candidates);

  const {
    jobseekerStatus,
    jobseekerStage,
    jobseekerProgress,
    jobseekerSituation,
    isStage
  } = useSelector((state) => state.common);

  const [List, setList] = useState([]);
  const [jobstage, setJobstage] = useState([]);
  const [jobSituation, setJobSituation] = useState([]);
  const [show, setShow] = useState(true);

  const [statusValue, setStatusValue] = useState("Select");
  const [stageValue, setStageValue] = useState("Select");
  const [statusProgress, setStatusProgress] = useState("Select");
  const [situationValue, setSituationValue] = useState("Select");

  const [statusBgColour, setStatusBgColour] = useState(itemdata.length > 0 ? itemdata.
    progressMList[0].statusBgColour : "#fff");
  const [statusTxColour, setStatusTxColour] = useState(itemdata.length > 0 ? itemdata.
    progressMList[0].statusTxColour : "#333");

  const [stageBgColour, setStageBgColour] = useState(itemdata.length > 0 ? itemdata.
    progressMList[0].stageBgColour : "#fff");
  const [stageTxColour, setStageTxColour] = useState(itemdata.length > 0 ? itemdata.
    progressMList[0].stageTxColour : "#333");

  const [progressBgColour, setprogressBgColour] = useState(itemdata.length > 0 ? itemdata.
    progressMList[0].progressBgColour : "#fff");
  const [progressTxColour, setprogressTxColour] = useState(itemdata.length > 0 ? itemdata.
    progressMList[0].progressTxColour : "#333");

  const [situationBgColour, setSituationBgColour] = useState(itemdata.length > 0 ? itemdata.
    progressMList[0].situationBgColour : "#fff");
  const [situationTxColour, setSituationTxColour] = useState(itemdata.length > 0 ? itemdata.
    progressMList[0].situationTxColour : "#333");

  const [messageBox, setMessageBox] = useState("");
  const [rcId, setRcId] = useState("");
  const [rcimg, setRcImage] = useState("/users/images/user.png");

  const [parentId, setParentId] = useState("0");
  const [btnTxt, setBtnTxt] = useState("SAVE");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [rowId, setRowId] = useState(0);
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [commentCount, setCommentCount] = useState(0);
  const [actionType, setActionType] = useState("Add");
  const [dataId, setDataId] = useState(0);

  const handleClose = () => {
    setDataId(0);
    commentHandleClose();
    //setShow(false);
  };


  useEffect(() => {
    if (show) {
      setCommentCount(count);
      setDataId(refId);
      dispatch(commentList({ ref: refTable, refId }));
    }
  }, [show, isDelete]);

  useEffect(() => {
    dispatch(getprogress({ appid: itemdata.id }));
    dispatch(getJobseekarValues({}));
    dispatch(getJobseekarStageValues({}));
    dispatch(getJobseekarProgressValues({}));
    dispatch(getJobseekarSituationValues({}));
  }, []);

  useEffect(() => {
    if (isProgress) {
      setList(progressList);
    }
  }, [isProgress]);

  useEffect(() => {
    if (isStage) {
      setJobstage(jobseekerProgress);
      setJobSituation(jobseekerSituation);
    }
  }, [isStage]);

  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (isInsert) {
      dispatch(clearState());
      setBtnTxt("SAVE");
      setStageValue("");
      setStatusValue("");
      setStatusProgress("");
      setSituationValue("");
      setMessageBox("");
      setShowText(false);
      setShowPopup(false);
      setLoading(false);
      setList(comments);

      if (parentId === "0" && dataId > 0) {
        var today = new Date();
        var date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        var time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        var dateTime = date + " " + time;

        if (refTable === "tasks") {
          dispatch(
            updateTaskCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        } else if (refTable === "livejobs") {
          dispatch(
            updateLiveJobsCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        } else {
          dispatch(
            updateCommentCount({
              id: dataId,
              value: Number(commentCount) + 1,
              commentAt: dateTime,
            })
          );
        }
        setCommentCount(Number(commentCount) + 1);
      }
      // setDataId(0)
      setParentId("0");
    }
    if (isDelete) {
      dispatch(clearState());
      setLoading(false);
      setShowText(false);
      setShowPopup(false);
      setList(comments);
      if (parentId === "0" && dataId > 0) {
        if (refTable === "tasks") {
          dispatch(
            updateTaskCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
            })
          );
        } else if (refTable === "livejobs") {
          dispatch(
            updateLiveJobsCommentCount({
              id: dataId,
              value: Number(commentCount) - 1,
              commentAt: dateTime,
            })
          );
        } else {
          dispatch(
            updateCommentCount({ id: dataId, value: Number(commentCount) - 1 })
          );
        }
        // setDataId(0)
        setCommentCount(Number(commentCount) - 1);
      }
      setParentId("0");
      setRowId(0);
      setIsShowDelete(false);
    }
    if (isUpdate) {
      dispatch(clearState());
      setShowText(false);
      setShowPopup(false);
      setLoading(false);
      setParentId("0");
      setRowId(0);
      setActionType("Add");
      setBtnTxt("SAVE");
      setList(comments);
    }
  }, [isInsert, isDelete, isCommentInsert, isUpdate]);

  const deleteComment = (e) => {
    setRowId(e.target.dataset.id);
    setIsShowDelete(true);
  };

  const handleCloseDelete = () => {
    setRowId(0);
    setIsShowDelete(false);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      dispatch(deleteProgress({ id: rowId, appid: itemdata.id }));
    } catch (e) {
      console.log(e);
    }
  };


  const onItemClick = (id, columnId, value, img) => {
    setRcImage(img);
    setRcId(value);
  };


  const editComment = (e) => {
    setActionType("Edit");
    setStatusValue(e.target.dataset.status);
    setStatusProgress(e.target.dataset.progress);
    setStageValue(e.target.dataset.stage);
    setSituationValue(e.target.dataset.situation);
    setMessageBox(e.target.dataset.value);
    setRcId(e.target.dataset.rcid);
    setRcImage(e.target.dataset.rcimg);
    setRowId(e.target.dataset.id);

    setStatusBgColour(itemdata ? itemdata.progressMList[0].statusBgColour : "#fff")
    setStatusTxColour(itemdata ? itemdata.progressMList[0].statusTxColour : "#333")
    setStageBgColour(itemdata ? itemdata.progressMList[0].stageBgColour : "#fff")
    setStageTxColour(itemdata ? itemdata.progressMList[0].stageTxColour : "#333")
    setprogressBgColour(itemdata ? itemdata.progressMList[0].progressBgColour : "#fff")
    setprogressTxColour(itemdata ? itemdata.progressMList[0].progressTxColour : "#333")
    setSituationBgColour(itemdata ? itemdata.progressMList[0].situationBgColour : "#fff")
    setSituationTxColour(itemdata ? itemdata.progressMList[0].situationTxColour : "#333")

    setShowText(true);
    setCommentHeading("Update Comment");
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      setParentId(details.parentId);
      setBtnTxt("Update");
    }
  }, [isSuccess]);

  const replyComment = (e) => {
    setShowText(true);
    setCommentHeading("Reply Comment");
  };
  const [commentHeading, setCommentHeading] = useState("Add New Comment");

  const [showPopup, setShowPopup] = useState(false);
  const handleClosePopup = () => {
    setShowPopup(false);
    setLoading(false);
  };

  const addOnClick = async () => {
    setActionType("Add");
    setBtnTxt("SAVE");
    setRcImage("/users/images/user.png")
    setStatusValue("Select");
    setStatusProgress("Select");
    setStageValue("Select");
    setSituationValue("Select");

    setStatusBgColour("#fff")
    setStatusTxColour("#333")
    setStageBgColour("#fff")
    setStageTxColour("#333")
    setprogressBgColour("#fff")
    setprogressTxColour("#333")
    setSituationBgColour("#fff")
    setSituationTxColour("#333")
    setMessageBox("")

    if (showText === false) {
      setShowText(true);
    } else {
      setShowText(false);
    }
  };

  const cancleBtnFun = () => {
    setShowText(false);
    setActionType("Add");
  };



  useEffect(() => {
    if (isProgressAdd) {
      dispatch(getApplications({ can_id: itemdata.canId }));
      setActionType("Add");
      dispatch(getprogress({ appid: itemdata.id }));
      setSuccess(false);
      setLoading(false);
      setShowText(false);
      setRcImage("/users/images/user.png");
      setStatusValue("Select");
      setStageValue("Select");
      setSituationValue("Select");
      setStatusProgress("Select");
      setMessageBox("");
      dispatch(showSuccess({ msg: "Record saved Successfully." }));
    }
    if (isProgressDelete) {
      dispatch(getApplications({ can_id: itemdata.canId }));
      setIsShowDelete(false);
      setLoading(false);
      dispatch(showSuccess({ msg: "Record deleted Successfully." }));
      dispatch(getprogress({ appid: itemdata.id }));
    }
  }, [isProgressAdd, isProgressDelete]);

  const updateProfile = async (e) => {
    const id = e.target.dataset.id;
    const key = e.target.dataset.key;
    const value = e.target.dataset.value;

    const bgcolor = e.target.dataset.bgcolor;
    const textcolor = e.target.dataset.textcolor;

    if (key === "status") {
      setStatusValue(value);
      setStatusBgColour(bgcolor)
      setStatusTxColour(textcolor)
    }
    if (key === "stage") {
      setStatusProgress("Select")
      setSituationValue("Select")
      setprogressBgColour("#fff")
      setSituationBgColour("#fff")
      setStageValue(value);
      setStageBgColour(bgcolor)
      setStageTxColour(textcolor)
      setJobstage(jobseekerProgress.filter(item => item.bgColor === bgcolor))
      setJobSituation(jobseekerSituation.filter(item => item.bgColor === bgcolor))
    }
    if (key === "progress") {
      setStatusProgress(value);
      setprogressBgColour(bgcolor)
      setprogressTxColour(textcolor)
    }
    if (key === "situation") {
      setSituationValue(value);
      setSituationBgColour(bgcolor)
      setSituationTxColour(textcolor)
    }
  };

  const handleUpdateButton = async () => {
    setSuccess(true);
    setLoading(true);
    await dispatch(
      updateProgress({
        id: rowId,
        appid: itemdata.id,
        rcId,
        status: statusValue,
        stage: stageValue,
        progress: statusProgress,
        situation: situationValue,
        comment: messageBox,
        rcimg,
        statusBgColour,
        statusTxColour,
        stageBgColour,
        stageTxColour,
        progressBgColour,
        progressTxColour,
        situationBgColour,
        situationTxColour
      })
    );
  };

  const handleButtonClick = async () => {
    setSuccess(true);
    setLoading(true);

    await dispatch(
      addProgress({
        appid: itemdata.id,
        canId: itemdata.canId,
        rcId,
        status: statusValue,
        stage: stageValue,
        progress: statusProgress,
        situation: situationValue,
        comment: messageBox,
        rcimg,
        statusBgColour,
        statusTxColour,
        stageBgColour,
        stageTxColour,
        progressBgColour,
        progressTxColour,
        situationBgColour,
        situationTxColour
      })
    );
  };

  return (
    <>
      {isShowDelete ? (
        <Delete
          id={rowId}
          handleClose={handleCloseDelete}
          deleteItem={deleteItem}
          loading={loading}
          success={success}
        />
      ) : null}

      <Modal
        className="dk-commentModalMain right dkg-casestatus-mainModal"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          {actionType === "Add" ? (
            <Link to="#" className="addMonthBtn" onClick={addOnClick}>
              <i className="fas fa-plus"></i>
            </Link>
          ) : null}

          <Modal
            show={showPopup}
            onHide={handleClosePopup}
            centered
            className="dkg-comment-editorPopup-234 "
          >
            <Modal.Header
              closeButton
              className="dkg-comment-editorPopup-Header"
            >
              <Modal.Title>{commentHeading}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="dkg-comment-editorPopup-Body"></Modal.Body>
          </Modal>

          <Modal.Title>
            {actionType === "Add" ? "CREATE STATUS" : "EDIT STATUS"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showText ? (
            <>
              <div className="dkg-addnew-scrollBox">
                <div className="dkg-app-progress-modal-body">
                  <div className="dkg-case-progress-form-con">
                    <div className="form-group dkg-profile-frmgrp">
                      <label htmlFor="" className="dkg-profile-label">
                        Recruiter
                      </label>
                      <div className="dkg-app-user-dropdrownCon">
                        <Dropdown className="dkg-app-user-dropdrown">
                          <Dropdown.Toggle
                            variant=""
                            id=""
                            className="dkg-app-user-dropdrown-toggle"
                          >
                            <div className="tooltipMain">
                              {rcimg != "" ? (
                                <img
                                  src={
                                    process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                    rcimg
                                  }
                                  className="filterImg"
                                />
                              ) : (
                                <img
                                  src={
                                    process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                    rcimg
                                  }
                                  className="filterImg"
                                />
                              )}
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dkg-app-user-dropdrown-menu">
                            <div className="d-flex">
                              <UserList
                                id={itemdata.id}
                                columnId="rcId"
                                onItemClick={onItemClick}
                              />
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="form-group dkg-profile-frmgrp">
                      <label htmlFor="" className="dkg-profile-label">
                        Status
                      </label>
                      <div className="dkg-app-select-dropdown-Con">
                        <CommonDropdown
                          caseStatus={statusValue}
                          statusBgColour={statusBgColour}
                          statusTxColour={statusTxColour}
                          list={jobseekerStatus}
                          candidateId={itemdata.canId}
                          updateProfile={updateProfile}
                          colKey="status"
                          errorclassName={
                            statusValue == "" ? "errorBorder" : null
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group dkg-profile-frmgrp">
                      <label htmlFor="" className="dkg-profile-label">
                        Stage
                      </label>
                      <div className="dkg-app-select-dropdown-Con">
                        <CommonDropdown
                          caseStatus={stageValue}
                          statusBgColour={stageBgColour}
                          statusTxColour={stageTxColour}
                          list={jobseekerStage}
                          candidateId={itemdata.canId}
                          updateProfile={updateProfile}
                          colKey="stage"
                          errorclassName={
                            stageValue == "" ? "errorBorder" : null
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group dkg-profile-frmgrp">
                      <label htmlFor="" className="dkg-profile-label">
                        Progress
                      </label>
                      <div className="dkg-app-select-dropdown-Con">
                        <CommonDropdown
                          caseStatus={statusProgress}
                          statusBgColour={progressBgColour}
                          statusTxColour={progressTxColour}
                          // list={jobseekerProgress}
                          list={jobstage}
                          candidateId={itemdata.canId}
                          updateProfile={updateProfile}
                          colKey="progress"
                          errorclassName={
                            statusProgress == "" ? "errorBorder" : null
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group dkg-profile-frmgrp">
                      <label htmlFor="" className="dkg-profile-label">
                        Situation
                      </label>
                      <div className="dkg-app-select-dropdown-Con">
                        <CommonDropdown
                          caseStatus={situationValue}
                          statusBgColour={situationBgColour}
                          statusTxColour={situationTxColour}
                          // list={jobseekerSituation}
                          list={jobSituation}
                          candidateId={itemdata.canId}
                          updateProfile={updateProfile}
                          colKey="situation"
                          errorclassName={
                            situationValue == "" ? "errorBorder" : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="dkg-caseprogrees-textAreaCon">
                    <div className="dkg-caseprogress-header">
                      <h2 className="dkg-caseprogress-title">WRITE COMMENT</h2>
                    </div>
                    <div className="dkg-caseprogress-subtitleCon">
                      {/* <div className="dkg-caseprogess-subtitle">Date & Time</div> */}
                      <span className="dkg-caseprogess-dflex">
                        <div className="dkg-caseprogess-date">
                          <i className="far fa-calendar-alt"></i>
                          {moment().tz("Europe/Dublin").format("DD MMM YY")}
                        </div>
                        <div className="dkg-caseprogess-date">
                          <i className="far fa-clock"></i>
                          {moment().tz("Europe/Dublin").format("hh:mm")}
                        </div>
                      </span>
                    </div>
                    <div className="dkg-caseprogrees-textarea">
                      <textarea
                        name=""
                        id=""
                        className="form-control dkg-cp-frmcontrol-textarea"
                        placeholder="Write text here"
                        onChange={(e) => setMessageBox(e.target.value)}
                      >
                        {messageBox}
                      </textarea>
                    </div>
                    <div className="dkg-cp-save-btncon">
                      <Link
                        to="#"
                        onClick={() => cancleBtnFun()}
                        className="dkg-cancel-btn"
                      >
                        Cancel
                      </Link>

                      <SubmitButton
                        txt="SAVE"
                        loading={loading}
                        success={success}
                        className="dkg-cp-save-btn"
                        onClick={
                          actionType === "Add"
                            ? handleButtonClick
                            : handleUpdateButton
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="comments-container 22">
                <div className="scrollBox">
                  <ul className="comments-list">
                    {isCommentLoading ? (
                      <CommentLoader />
                    ) : (
                      <React.Fragment>
                        {List.map((item, index) => (
                          <li>
                            <div className="comment-main-level">
                              <div className="comment-avatar">
                                <div className="tooltipMain">
                                  <img
                                    alt={
                                      item.recInfo.firstName +
                                      " " +
                                      item.recInfo.lastName
                                    }
                                    src={
                                      process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                      item.recInfo.profileImage
                                    }
                                    className="avatarImg"
                                  />
                                  <div className="tooltipText">
                                    {item.recInfo.firstName +
                                      " " +
                                      item.recInfo.lastName}
                                  </div>
                                </div>
                              </div>
                              <div className="comment-box">
                                <div className="comment-head dkg-comm-read-header d-flex align-items-center justify-content-between">
                                  <div
                                    className="dkg-comment-header-rightCon"
                                  // style={{ height: "38px" }}
                                  >
                                    <span
                                      className="d-flex"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className="date">
                                        <i className="far fa-calendar-alt"></i>

                                        {moment(item.updatedAt)
                                          .tz("Europe/Dublin")
                                          .format("DD MMM YY")}
                                      </div>
                                      <div className="date">
                                        <i className="far fa-clock"></i>
                                        {moment(item.updatedAt)
                                          .tz("Europe/Dublin")
                                          .format("h:mm")}
                                      </div>
                                    </span>

                                    <div className="dkg-comment-readdropdwn-Con d-flex">
                                      <div className="Date">
                                        <i
                                          className="far fa-edit"
                                          title="Edit"
                                          data-id={item.id}
                                          data-rcid={item.rcId}
                                          data-value={item.comment}
                                          data-status={item.status}
                                          data-progress={item.progress}
                                          data-stage={item.stage}
                                          data-situation={item.situation}
                                          data-rcimg={item.rcimg}
                                          data-created={item.createdAt}
                                          onClick={editComment}
                                        ></i>
                                      </div>
                                      <div className="Date">
                                        <i
                                          className="far fa-trash-alt"
                                          title="Delete"
                                          data-parentid={item.parentId}
                                          data-id={item.id}
                                          onClick={deleteComment}
                                        ></i>
                                      </div>
                                      <Dropdown className="dkg-comment-readdropdwn d-none">
                                        <Dropdown.Toggle
                                          variant=""
                                          className="dkg-comment-readdropdwn-toggle"
                                        ></Dropdown.Toggle>
                                        <Dropdown.Menu className="dkg-comment-readdropdwn-menu">
                                          <Dropdown.Item
                                            href="#"
                                            className="dkg-comment-readdropdwn-item"
                                          >
                                            <i
                                              className="fas fa-reply"
                                              title="Reply"
                                              data-id={item.id}
                                              onClick={(e) => {
                                                setParentId(
                                                  e.target.dataset.id
                                                );
                                                //setInput1Focus();
                                                replyComment(
                                                  e.target.dataset.id
                                                );
                                              }}
                                            ></i>
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            href="#"
                                            className="dkg-comment-readdropdwn-item"
                                          >
                                            <i
                                              className="far fa-edit"
                                              title="Edit"
                                              data-id={item.id}
                                              data-rcid={item.rcId}
                                              data-value={item.comment}
                                              data-status={item.status}
                                              data-progress={item.progress}
                                              data-stage={item.stage}
                                              data-situation={item.situation}
                                              data-rcimg={item.rcimg}
                                              data-created={item.createdAt}
                                              onClick={editComment}
                                            ></i>
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            href="#"
                                            className="dkg-comment-readdropdwn-item"
                                          >
                                            <i
                                              className="far fa-trash-alt"
                                              title="Delete"
                                              data-parentid={item.parentId}
                                              data-id={item.id}
                                              onClick={deleteComment}
                                            ></i>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                                {refTable === "candidates" ? (
                                  <>
                                    {moment(item.createdAt)
                                      .tz("Europe/Dublin")
                                      .format(" YYYY") >= 2024 ? (
                                      <>
                                        <div className="dkg-comm-read-subheader">
                                          <div className="dkg-comm-read-subject">
                                            <CaseOther
                                              caseStatus={item.status}
                                              value={item.status}
                                              statusBgColour={item.statusBgColour}
                                              statusTxColour={item.statusTxColour}
                                            />
                                          </div>
                                          <div className="dkg-comm-read-subject">
                                            <CaseOther
                                              caseStatus={item.stage}
                                              value={item.stage}
                                              statusBgColour={item.stageBgColour}
                                              statusTxColour={item.stageTxColour}
                                            />
                                          </div>
                                          <div className="dkg-comm-read-subject">
                                            <CaseOther
                                              caseStatus={item.progress}
                                              value={item.progress}
                                              statusBgColour={item.progressBgColour}
                                              statusTxColour={item.progressTxColour}
                                            />
                                          </div>
                                          <div className="dkg-comm-read-subject">
                                            <CaseOther
                                              caseStatus={item.situation}
                                              value={item.situation}
                                              statusBgColour={item.situationBgColour}
                                              statusTxColour={item.situationTxColour}
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                  </>
                                ) : null}

                                <div className="comment-content 2">
                                  <Markup content={item.comment} />
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </React.Fragment>
                    )}
                  </ul>
                </div>
                <div className="dk-commentReplyBox"></div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CaseCommentProgress;
