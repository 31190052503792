import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';
import './document.scss';
import CvImg from "../../../../../../assets/images/cv.png";
import { candidateDoc } from '../../../../../../slice/candidates/profile/profileSlice';
import OnlineCvModal from "../candidateprofile/OnlineCvModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const CandidateDocumentModal = ({ name, canId }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = async () => {
        setShow(true);

    }

    const [frameType, setFrameType] = useState('0')
    const [fileUrl, setFileUrl] = useState('0')

    function isEmpty(obj) {
        for (var i in obj) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (show) {
            async function fetchData() {
                const result = await dispatch(candidateDoc({ canId }))
                if (!isEmpty(result.payload.result)) {
                    const fileName = result.payload.result.fileName
                   // console.log(result)
                    //console.log(fileName)
                    if (fileName != null || '') {
                        setFrameType(fileName.split('.').pop());
                        setFileUrl(fileName.split("https://master-upload.s3.amazonaws.com").pop());
                    } else {
                        setFileUrl('0')
                    }
                } else {
                    setFileUrl('0')
                }
            }
            fetchData();
        }
    }, [show])

    const [isShowOnlineCvModal, setIsOnlineCvModal] = useState(false);

    const setIsShowOnlineCvModal = () => {
        setIsOnlineCvModal(true);
    }
    const hideOnlineCvModal = () => {
        setIsOnlineCvModal(false);
    }

    return (
        <>
            {
                (isShowOnlineCvModal) ? <OnlineCvModal hideOnlineCv={hideOnlineCvModal} /> : null
            }
            <div className="dk-TopCntreBox cvtextBox">
                <Link to="#" className="cvtext_Link" onClick={handleShow}>CV</Link>
                <div className="dk-cvImg" onClick={setIsShowOnlineCvModal}>
                    <img src={CvImg} alt="" />
                </div>
            </div>
            <Modal show={show} onHide={handleClose} className="dk-modalCvRight" dialogClassName="dk-pipedocs-previewModal"
                aria-labelledby="example-custom-modal-styling-title"
                centered animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                    <a href={process.env.REACT_APP_INTERNAL_BASE_URL + fileUrl} download target={"_blank"}>CV Download</a>
                        {/* <Link to="#"><FontAwesomeIcon icon={faDownload} /></Link> */}
                    </Modal.Title>
                </Modal.Header>
                {
                    (frameType != '0') ?
                        (fileUrl != '0') ?
                            <Modal.Body className='dk-pipedocs-preview-modalbody'>
                                {
                                    (frameType === 'pdf' || frameType === 'PDF') ?
                                        <iframe src={process.env.REACT_APP_INTERNAL_BASE_URL + fileUrl} frameBorder={0}></iframe>
                                        :
                                        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${process.env.REACT_APP_INTERNAL_BASE_URL + fileUrl}`} frameBorder={0}></iframe>
                                }
                            </Modal.Body>
                            : <Modal.Body className='dk-pipedocs-preview-modalbody'>
                                <h1>Document Not Found</h1>
                            </Modal.Body>
                        : null
                }
            </Modal>
        </>
    )
}
export default CandidateDocumentModal;