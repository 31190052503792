import React from 'react';
const Status = ({ value }) => {

    let bgColor = "";
    switch (value) {
        case 'New Task':
            bgColor = '#d58b5d'
            break;

        case 'In Action':
            bgColor = '#26597d'
            break;

        case 'Overdue':
            bgColor = '#d91010'
            break;

        case 'Pause':
            bgColor = '#625c60'
            break;

        case 'Success':
            bgColor = '#3a9469'
            break;

        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor, color: '#FFF', minWidth: '80px' }}>{value}</div>
        </>
    )
}

export default Status;