import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/dkglobalfinance/AccountingMenu';

const FinanceAccountingLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="ACCOUNTING" moduleLink="/dashboard/accounting" linkcolor="#3c4c62" mainheaderTitle=" - COMPANY DOC’s" textcolor="#5A4C72" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default FinanceAccountingLayout;