import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./viewpopup.scss";
// import { FaRegSadTear } from "react-icons/fa";
import CompanyDropdown from "../../../../../../jobs/addnew/CompanyCanApp";
import CategoryDropdown from "../../../../../../jobs/addnew/CategoryDropdown";
import SearchDropDownFilter from "../../../../../../jobs/addnew/OthersCanApp";
// import JobinfoTabSearch from "../../../../../../jobs/addnew/JobinfoTabSearch";
// import JobSpecTabSearch from "../../../../../../jobs/addnew/JobSpecTabSearch";
import {
  updateApplication,
  updatewithdrawn,
  // deleteCanApplication,
  // updateFuncApplication,
} from "../../../../../../../../slice/candidates/list/listSlice";

const EditApplication = ({ onHide, id, removeDiv, itemdata }) => {
  const dispatch = useDispatch();
  const { categoryList } = useSelector((state) => state.assignKpiReport);
  const { companyList } = useSelector((state) => state.companies);

  // const [blankForm, setBlankForm] = useState(
  //   itemdata.withdraw_checkbox_1 == 1 ||
  //     itemdata.withdraw_checkbox_2 == 1 ||
  //     itemdata.withdraw_checkbox_3 == 1 ||
  //     itemdata.withdraw_checkbox_4 == 1 ||
  //     itemdata.withdraw_checkbox_5 == 1 ||
  //     itemdata.withdraw_checkbox_6 == 1 ||
  //     itemdata.withdraw_checkbox_7 == 1 ||
  //     itemdata.withdraw_msg != ""
  //     ? true
  //     : false
  // );

  const {
    // isLoading,
    // isSuccess,
    // isUpdate,
    // message,
    // jobseDataList,
    isDetailsLoading,
    jobDetails,
  } = useSelector((state) => state.jobs);

  const {
    // jobsCategoryList,
    // jobsCompanyList,
    jobsProjectList,
    jobsRoleTypeList,
    jobsLanguageList,
  } = useSelector((state) => state.common);

  //const [jobbInfo, setJobbInfo] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  //const [companydata, setCompanydata] = useState("ffff");
  const [companydata, setCompanydata] = useState(
    companyList.filter((item) => item.inStatus === 1)
  );

  // useEffect(() => {
  //   if (!isLoading && jobseDataList.length > 0) {
  //     setTimeout(() => {
  //       setJobbInfo(jobseDataList);
  //     }, 200);
  //   }
  // }, [isLoading]);
  // console.log(itemdata);
  // console.log(itemdata.jobSpecTitle);
  //const [JobSpecName, setJobSpecName] = useState("");
  const [jobbidd, setJobbidd] = useState(0);

  useEffect(() => {
    if (jobDetails.length > 0 && !isDetailsLoading) {
      //setJobSpecName(jobDetails.name);
      dispatch(
        updateApplication({
          id: jobbidd,
          key: "jobSpecTitle",
          value: jobDetails.name,
          canId: itemdata.canId,
        })
      );
    }
  }, [isDetailsLoading]);

  const clearWithdrawnform = (id) => {
    //setBlankForm(0);
    dispatch(
      updatewithdrawn({
        id,
        withdraw_checkbox_1: 0,
        withdraw_checkbox_2: 0,
        withdraw_checkbox_3: 0,
        withdraw_checkbox_4: 0,
        withdraw_checkbox_5: 0,
        withdraw_checkbox_6: 0,
        withdraw_checkbox_7: 0,
        withdraw_msg: "",
        canId: itemdata.canId,
      })
    );
  };

  const getCategoryValue = (value, id) => {
    dispatch(
      updateApplication({
        id,
        key: "category",
        value,
        canId: itemdata.canId,
      })
    );
  };

  const getCompanyValue = (value, id) => {
    dispatch(
      updateApplication({
        id,
        key: "company",
        value,
        canId: itemdata.canId,
      })
    );
  };

  const getProjectValue = (value, id) => {
    dispatch(
      updateApplication({
        id,
        key: "project",
        value,
        canId: itemdata.canId,
      })
    );
  };
  const getRoleTypetValue = (value, id) => {
    dispatch(
      updateApplication({
        id,
        key: "role",
        value,
        canId: itemdata.canId,
      })
    );
  };
  const getLanguagetValue = (value, id) => {
    dispatch(
      updateApplication({
        id,
        key: "language",
        value,
        canId: itemdata.canId,
      })
    );
  };

  return (
    <React.Fragment>
      <Modal show={true} onHide={onHide} className="dkg-application-view-modal">
        <Modal.Header
          closeButton
          className="dkg-app-widthraw-modal-header dkg-app-progress-modal-header"
        >
          <Modal.Title>WITHDRAW APPLICATION / JOB INFO</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-app-progress-modal-body dkg-app-widthraw-modal-body">
          <div className="dkg-app-withdraw-ulCon">
            <ul className="dkg-app-withdraw-ul">
              <li className="dkg-app-withdraw-li">
                <span className="dkg-app-withdraw-checkbox">
                  {/* <input
                    type="checkbox"
                    className="form-control dkg-appwithdraw-checkbox checkbox-md"
                    name="job-reason-checkbox"
                    checked={blankForm}
                    onChange={(e) => clearWithdrawnform(itemdata.id)}
                  /> */}
                  <button
                    className="btn dkg-resetbtn"
                    onClick={(e) => clearWithdrawnform(itemdata.id)}
                  >
                    Reset Form
                  </button>
                </span>
                {/* <p className="dkg-app-withdraw-reson">BLANK FORM</p> */}
              </li>
              {/* <li className="dkg-app-withdraw-li">
                <span className="dkg-app-withdraw-checkbox">
                  <input
                    type="checkbox"
                    className="form-control dkg-appwithdraw-checkbox checkbox-md"
                    name="job-reason-checkbox"
                  />
                </span>
                <p className="dkg-app-withdraw-reson">SUBMITTED FORM</p>
              </li> */}
            </ul>
          </div>
          {/* <div className="dkg-appwithdraw-btnCon mt-1 mb-4">
                <Submit txt="UPDATE " className="dkg-appwithdraw-btn" />
            </div> */}
          <div
            className="dkg-case-progress-form-con "
            style={{ marginTop: "5rem" }}
          >
            <div className="form-group dkg-profile-frmgrp">
              <label htmlFor="" className="dkg-profile-label">
                Category
              </label>
              <div className="dkg-app-select-dropdown-Con">
                <CategoryDropdown
                  categoryList={categoryList}
                  catList={getCategoryValue}
                  isRefresh={isRefresh}
                  id={itemdata.id}
                  dataValue={itemdata.category}
                />
              </div>
            </div>
            <div className="form-group dkg-profile-frmgrp">
              <label htmlFor="" className="dkg-profile-label">
                Company
              </label>
              <div className="dkg-app-select-dropdown-Con">
                <CompanyDropdown
                  categoryList={companydata}
                  catList={getCompanyValue}
                  isRefresh={isRefresh}
                  id={itemdata.id}
                  dataValue={itemdata.company}
                />
              </div>
            </div>
            <div className="form-group dkg-profile-frmgrp">
              <label htmlFor="" className="dkg-profile-label">
                Project
              </label>
              <div className="dkg-app-select-dropdown-Con">
                <SearchDropDownFilter
                  categoryList={jobsProjectList}
                  catList={getProjectValue}
                  isRefresh={isRefresh}
                  id={itemdata.id}
                  dataValue={itemdata.project}
                />
              </div>
            </div>
            <div className="form-group dkg-profile-frmgrp">
              <label htmlFor="" className="dkg-profile-label">
                Role Type
              </label>
              <div className="dkg-app-select-dropdown-Con">
                <SearchDropDownFilter
                  categoryList={jobsRoleTypeList}
                  catList={getRoleTypetValue}
                  isRefresh={isRefresh}
                  id={itemdata.id}
                  dataValue={itemdata.role}
                />
              </div>
            </div>
            <div className="form-group dkg-profile-frmgrp">
              <label htmlFor="" className="dkg-profile-label">
                Language
              </label>
              <div className="dkg-app-select-dropdown-Con">
                <SearchDropDownFilter
                  categoryList={jobsLanguageList}
                  catList={getLanguagetValue}
                  isRefresh={isRefresh}
                  id={itemdata.id}
                  dataValue={itemdata.language}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default EditApplication;
