import React from "react";
import UserIcon from "../../../../../assets/images/deepak.png";
import './summary.scss';

const TrainingOverView = () => {
    return (
        <React.Fragment>
            <div className="dk-dkGlobalDashboard-Main">
                <div className="d-md-flex">
                    <div className="dk-summarySh-cols dk-summarySh-trainer">
                        <div className="title">Trainer</div>
                        <div className="dk-summarySh-panel">
                            <li style={{ minHeight: '80px' }}>
                                <div className="dk-sheduleUser">
                                    <img src={UserIcon} alt="" />
                                </div>
                            </li>
                            <li style={{ minHeight: '80px' }}>
                                <div className="dk-sheduleUser">
                                    <img src={UserIcon} alt="" />
                                </div>
                            </li>
                            <li style={{ minHeight: '160px' }}>
                                <div className="dk-sheduleUser">
                                    <img src={UserIcon} alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="dk-sheduleUser">
                                    <img src={UserIcon} alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="dk-sheduleUser">
                                    <img src={UserIcon} alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="dk-sheduleUser">
                                    <img src={UserIcon} alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="dk-sheduleUser">
                                    <img src={UserIcon} alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="dk-sheduleUser">
                                    <img src={UserIcon} alt="" />
                                </div>
                            </li>
                            <li style={{ minHeight: '80px' }}>
                                <div className="dk-sheduleUser">
                                    <img src={UserIcon} alt="" />
                                </div>
                            </li>
                        </div>
                    </div>
                    <div className="dk-summarySh-cols">
                        <div className="title">Departments</div>
                        <div className="dk-summarySh-panel">
                            <li style={{ background: '#884c5e', minHeight: '80px' }}>Management</li>
                            <li style={{ background: '#5c737d', minHeight: '80px' }}>CLIENTS</li>
                            <li style={{ background: '#26596d', minHeight: '160px' }}>Recruitment</li>
                            <li style={{ background: '#4c5f59' }}>Marketing</li>
                            <li style={{ background: '#144773' }}>ADVERTISEMENT</li>
                            <li style={{ background: '#5A4C72' }}>Accounting</li>
                            <li style={{ background: '#01796f' }}>SUPPLIERS</li>
                            <li style={{ background: '#396a95' }}>Training</li>
                            <li style={{ background: '#b55355', minHeight: '80px' }}>IT Team</li>
                        </div>
                    </div>
                    <div className="dk-summarySh-cols">
                        <div className="title">Job Profile</div>
                        <div className="dk-summarySh-panel">
                            <li style={{ background: '#884c5e', color: '#fff' }}>Director</li>
                            <li style={{ background: '#884c5e', color: '#fff' }}>Operation Manager</li>
                            <li style={{ background: '#5c737d', color: '#fff' }}>Clients Manager</li>
                            <li style={{ background: '#5c737d', color: '#fff' }}>DK Global Admin</li>
                            <li style={{ background: '#26596d', color: '#fff' }}>Recruitment Manager</li>
                            <li style={{ background: '#26596d', color: '#fff' }}>Account Manager</li>
                            <li style={{ background: '#26596d', color: '#fff' }}>Recruitment Consultant</li>
                            <li style={{ background: '#26596d', color: '#fff' }}>Recruitment Admin</li>
                            <li style={{ background: '#4c5f59', color: '#fff' }}>Marketing Manager</li>
                            <li style={{ background: '#144773', color: '#fff' }}>Advertisement Admin</li>
                            <li style={{ background: '#5A4C72', color: '#fff' }}>Accounting Manager</li>
                            <li style={{ background: '#01796f', color: '#fff' }}>Suppliers Manager</li>
                            <li style={{ background: '#396a95', color: '#fff' }}>Training Manager</li>
                            <li style={{ background: '#b55355', color: '#fff' }}>Developer</li>
                            <li style={{ background: '#b55355', color: '#fff' }}>Designer</li>
                        </div>
                    </div>
                    <div className="dk-summarySh-cols mr-0">
                        <div className="title">SCHEDULE</div>
                        <div className="dk-summarySh-panel">
                            <li style={{ background: '#884c5e', color: '#fff' }}></li>
                            <li style={{ background: '#884c5e', color: '#fff' }}></li>
                            <li style={{ background: '#5c737d', color: '#fff' }}></li>
                            <li style={{ background: '#5c737d', color: '#fff' }}></li>
                            <li style={{ background: '#26596d', color: '#fff' }}></li>
                            <li style={{ background: '#26596d', color: '#fff' }}></li>
                            <li style={{ background: '#26596d', color: '#fff' }}></li>
                            <li style={{ background: '#26596d', color: '#fff' }}></li>
                            <li style={{ background: '#4c5f59', color: '#fff' }}></li>
                            <li style={{ background: '#144773', color: '#fff' }}></li>
                            <li style={{ background: '#5A4C72', color: '#fff' }}></li>
                            <li style={{ background: '#01796f', color: '#fff' }}></li>
                            <li style={{ background: '#396a95', color: '#fff' }}></li>
                            <li style={{ background: '#b55355', color: '#fff' }}></li>
                            <li style={{ background: '#b55355', color: '#fff' }}></li>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default TrainingOverView;
