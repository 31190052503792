import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

export default function CustomizedMenus({ list, onSelectDepartment, name }) {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">{name}</Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    list.map((item, idx) =>
                        <Dropdown.Item href="#" onClick={onSelectDepartment} style={{ color: item.txtColor, background: item.bgColor }} data-bgcolor={item.bgColor} data-txtcolor={item.txtColor} data-id={item.id} data-name={item.name}>{item.name}</Dropdown.Item>
                    )
                }
            </Dropdown.Menu>
        </Dropdown>
    );
}