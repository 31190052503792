import React from 'react';
const PriorityStatus = ({ value }) => {
    let bgColor = "";
    switch (value) {

        case 'Normal':
            bgColor = '#3a9469'
            break;

        case 'Urgent':
            bgColor = '#d91010'
            break;

        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: 'transparent', color: bgColor, minWidth: '80px' }}>{value}</div>
        </>
    )
}

export default PriorityStatus;