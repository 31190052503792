import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData } from "../../services";

export const getTextGetStart = createAsyncThunk(
  "getstart/text-change",
  async (thunkAPI) => {
    const res = await GetData(true, "/get-start/");
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);


const usersSlice = createSlice({
  name: "get_Start",
  initialState: {
    getStartList: [],
    isSuccess: false,
    isUpdate: false,
    isLoading: false,
    isError: false,
    isDelete: false,
  },
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.isUpdate = false;
      state.isDelete = false;
    },
  },
  extraReducers: {
    [getTextGetStart.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [getTextGetStart.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.getStartList = payload.result;
    },
    [getTextGetStart.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },


  },
});

export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
