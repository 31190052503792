import React from 'react'
import { Link } from 'react-router-dom';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaGlobeEurope, FaFileAlt } from "react-icons/fa";
import SearchDropDown from './dropdown/SearchDropDown'
import Teleperformance from '../../../../assets/images/teleperformance.png';

const DetailViewTab = () => {
    return (
        <>
            <div className="row">
                <div className="col-xl-6 col-md-6 col-sm-12 pr-2">
                    <div className='dkg-clients-dettop-rightCon'>
                        <div className='dkg-com-img-titleCon'>
                            <div className='dkg-com-img-Con'>
                                <img src={Teleperformance} className="dkg-clientscompany" alt='Company Logo' />
                            </div>
                            <div className='dkg-comdetails-title-Con d-none'>
                                <h2>Teleperformance</h2>
                            </div>
                            <div className='dkg-client-name-idCon'>


                            </div>
                        </div>
                        <div className="dk-cliDetilFormCon mt-3">
                            <div className='row'>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label>Client Name</label>
                                        <input type="text" className="form-control" placeholder="DK Global Recruitment Limited" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-building" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-12 pl-0'>
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label>Client ID</label>
                                        <input type="text" className="form-control" placeholder="4567" readOnly />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-building" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label>Renew On</label>
                                        <input type="text" className="form-control" placeholder="28-03-2022" readOnly />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-building" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 pl-0">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label>Category</label>
                                        <SearchDropDown title="Category 1" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-building" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label>City/Town</label>
                                        {/* <input type="text" className="form-control" placeholder="Dublin" /> */}
                                        <SearchDropDown title="Dublin" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 pl-0">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label>Country</label>
                                        <SearchDropDown title="Ireland" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label>Industry</label>
                                        <SearchDropDown title="Software" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-building" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 pl-0">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label >Business</label>
                                        <SearchDropDown title="Business 1" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-building" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 mt-4">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label>Address</label>
                                        <input type="text" className="form-control" placeholder="Bloom House, Railway St, Mountjoy, Dublin, Ireland" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label>Tax No.</label>
                                        <input type="text" className="form-control" placeholder="" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-building" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 pl-0">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label>VAT No.</label>
                                        <input type="text" className="form-control" placeholder="" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-building" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label>City/Town</label>
                                        <SearchDropDown title="Dublin" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 pl-0">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label>Country</label>
                                        <SearchDropDown title="Ireland" />
                                        <span className="dk-clViewAddonIcon">
                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label>Phone 1</label>
                                        <input type="text" className="form-control" placeholder="+351 911 775 132" />
                                    </div>
                                </div>
                                <div className="col-md-6 pl-0">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label>Phone 2</label>
                                        <input type="text" className="form-control" placeholder="+351 911 775 132" />

                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 col-sm-12'>
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label>Email 1</label>
                                        <input type="text" className="form-control" placeholder="deepak123@gmail.com" />

                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-12 pl-0'>
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label>Email 2</label>
                                        <input type="text" className="form-control" placeholder="deepak123@gmail.com" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 pl-2">
                    <div className='dkg-clidet-comsuMMCon-12'>
                        <div className='dkg-clidet-comsuMMConHeader-12'>
                            <h2 className='dkg-com-title'>Company Summary</h2>
                        </div>
                        <div className='dkg-clidet-comsuMMConBody-12'></div>
                    </div>
                    <div className='dkg-clidet-comsuMMCon-12 mt-3'>
                        <div className='dkg-clidet-comsuMMConHeader-12'>
                            <h2 className='dkg-com-title'>Social Address</h2>
                        </div>
                        <div className='dkg-clidet-comsuMMConBody-12'>
                            <div className='dk-socailinputGrp-Con'>
                                <div className="dk-socailinputGrp input-group mt-2 mb-2">
                                    <div className="fb-ip-grp-prepend input-group-prepend">
                                        <span className="input-group-text"><FaFacebookF /></span>
                                    </div>
                                    <input type="text" className="dk-socail-formControl form-control" placeholder='https://www.facebook.com/' id="facebook" />
                                    <div className="dkg-edit-ip-prepend input-group-prepend">
                                        <span className="input-group-text">Edit</span>
                                    </div>
                                    <div className="dkg-open-ip-prepend input-group-prepend">
                                        <span className="input-group-text">Open</span>
                                    </div>
                                </div>
                                <div className="dk-socailinputGrp input-group mb-2">
                                    <div className="fb-ip-grp-prepend input-group-prepend">
                                        <span className="input-group-text"><FaLinkedinIn /></span>
                                    </div>
                                    <input type="text" className="dk-socail-formControl form-control" id="facebook" placeholder='https://www.linkedin.com/login' />
                                    <div className="dkg-edit-ip-prepend input-group-prepend">
                                        <span className="input-group-text">Edit</span>
                                    </div>
                                    <div className="dkg-open-ip-prepend input-group-prepend">
                                        <span className="input-group-text">Open</span>
                                    </div>
                                </div>
                                <div className="dk-socailinputGrp input-group mb-2">
                                    <div className="fb-ip-grp-prepend input-group-prepend">
                                        <span className="input-group-text"><FaInstagram /></span>
                                    </div>
                                    <input type="text" className="dk-socail-formControl form-control" placeholder='https://www.instagram.com/' id="facebook" />
                                    <div className="dkg-edit-ip-prepend input-group-prepend">
                                        <span className="input-group-text">Edit</span>
                                    </div>
                                    <div className="dkg-open-ip-prepend input-group-prepend">
                                        <span className="input-group-text">Open</span>
                                    </div>
                                </div>
                                <div className="dk-socailinputGrp input-group mb-2">
                                    <div className="fb-ip-grp-prepend input-group-prepend">
                                        <span className="input-group-text"><FaGlobeEurope /></span>
                                    </div>
                                    <input type="text" className="dk-socail-formControl form-control" placeholder='https://www.dkmasterbox.com/' id="facebook" />
                                    <div className="dkg-edit-ip-prepend input-group-prepend">
                                        <span className="input-group-text">Edit</span>
                                    </div>
                                    <div className="dkg-open-ip-prepend input-group-prepend">
                                        <span className="input-group-text">Open</span>
                                    </div>
                                </div>
                                <div className="dk-socailinputGrp input-group">
                                    <div className="fb-ip-grp-prepend input-group-prepend">
                                        <span className="input-group-text"><FaFileAlt /></span>
                                    </div>
                                    <input type="text" className="dk-socail-formControl form-control" placeholder='https://www.dkmasterbox.com/' id="facebook" />
                                    <div className="dkg-edit-ip-prepend input-group-prepend">
                                        <span className="input-group-text">Edit</span>
                                    </div>
                                    <div className="dkg-open-ip-prepend input-group-prepend">
                                        <span className="input-group-text">Open</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='col-xl-6 col-md-6 col-sm-12 pl-2 mt-3'>
                    
                </div> */}
            </div>
        </>
    )
}

export default DetailViewTab