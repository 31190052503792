import React from 'react'
import { Link } from 'react-router-dom'
import "./backedvalues.scss"
import EditMainCategory from './modal/edit/EditMainCategory'
import AddMainCategory from './modal/addnew/AddMainCategory'
import ReorderValues from './modal/reorder/ReorderValues'

const index = () => {
    return (
        <>
            <div className='container-fluid dk-backendvalues-MainCon'>
                <div className='row'>
                    <div className='col-md-12 dk-toolsBkValues'>
                        <div className='dk-toolsValues-flex'>
                            <div className='dk-toolsValues-cols'>
                                <div className='dk-valueTitle'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='dk-value-icon'>
                                            <EditMainCategory />
                                        </div>
                                        <h6 className="subTitle">Main Category</h6>
                                        <div className='dk-value-icon'>
                                            <AddMainCategory />
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-toolsValueBox">
                                    <ul>
                                        <li><div className="clickBody activeTabs">Task</div></li>
                                        <li><div className="clickBody">Candidates</div></li>
                                        <li><div className="clickBody">Resources</div></li>
                                        <li><div className="clickBody">ADS</div></li>
                                        <li><div className="clickBody">Jobs</div></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='dk-toolsValues-cols'>
                                <div className='dk-valueTitle'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='dk-value-icon'>
                                            <EditMainCategory />
                                        </div>
                                        <h6 className="subTitle">1st Sub Category</h6>
                                        <div className='dk-value-icon'>
                                            <AddMainCategory />
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-toolsValueBox">
                                    <ul>
                                        <li><div className="clickBody">My Task</div></li>
                                        <li><div className="clickBody">Others Task</div></li>
                                        <li><div className="clickBody">Group Task</div></li>
                                        <li><div className="clickBody">Payment Task</div></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='dk-toolsValues-cols'>
                                <div className='dk-valueTitle'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='dk-value-icon'>
                                            <EditMainCategory />
                                        </div>
                                        <h6 className="subTitle">2nd Sub Category</h6>
                                        <div className='dk-value-icon'>
                                            <AddMainCategory />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='dk-toolsValues-cols'>
                                <div className='dk-valueTitle'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='dk-value-icon'>
                                            <EditMainCategory />
                                        </div>
                                        <h6 className="subTitle">3rd Sub Category</h6>
                                        <div className='dk-value-icon'>
                                            <AddMainCategory />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='dk-toolsValues-cols'>
                                <div className='dk-valueTitle'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='dk-value-icon'>
                                            <EditMainCategory />
                                        </div>
                                        <h6 className="subTitle">4th Sub Category</h6>
                                        <div className='dk-value-icon'>
                                            <AddMainCategory />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='dk-toolsValues-cols dk-toolsLastValue ml-3'>
                                <div className='dk-valueTitle'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='dk-value-icon'>
                                            <ReorderValues />
                                        </div>
                                        <h6 className="subTitle">Values</h6>
                                        <div className='dk-value-icon'>
                                            <AddMainCategory />
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-toolsValueField">
                                    <ul>
                                        <div className="dk-valueFieldCols">
                                            <div className="Mukul">
                                                <div className="dk-valueFields d-flex" id="480">
                                                    <input type="text" id="480" className="form-control" value="Job Board" />
                                                    <span className="dk-toolsValueDelete"><i className="fas fa-times"></i></span>
                                                    <span className="dk-toolsValueColor">
                                                        <i className="fas fa-tint"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="Mukul">
                                                <div className="dk-valueFields d-flex" id="481">
                                                    <input type="text" id="481" className="form-control" value="Linkedin" />
                                                    <span className="dk-toolsValueDelete"><i className="fas fa-times"></i></span>
                                                    <span className="dk-toolsValueColor">
                                                        <i className="fas fa-tint"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="Mukul">
                                                <div className="dk-valueFields d-flex" id="487">
                                                    <input type="text" id="487" className="form-control" value="DKG Database" />
                                                    <span className="dk-toolsValueDelete"><i className="fas fa-times"></i></span>
                                                    <span className="dk-toolsValueColor">
                                                        <i className="fas fa-tint"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default index