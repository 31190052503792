import React from 'react';
import Header from "../elements/header";
import Navbar from '../elements/header/navbar/mainMenu/visainfo/VisainfoMainMenu'

const VisainfoLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="Recruitment -" moduleLink="/dashboard/recruitment" linkcolor="#3c4c62" mainheaderTitle="JOB OFFER SPEC" textcolor="#26596d" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default VisainfoLayout;