import React from 'react';

const Candidates = () => {
    return (
        <>
            <div className='container-fluid' style={{ marginTop: "165px" }}>
                <div className='row'>
                    <div className='col-md-12'>
                        {/* <h2 className='text-center w-100'>Notification Candidates </h2> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Candidates;
