import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import FaqAccordion from "./accordion/Accordion";
import './maindepartmentfaq.scss';

const ReProjectionsFaq = () => {
    return (
        <>
            <div className="dk-departmentFaq-main">
                <h3 className="title">Projections FAQ's</h3>
                <Tab.Container id="left-tabs-example" defaultActiveKey="tab_1">
                    <div className="dk-departmentTabs-main">
                        <div className="dk-departmentTabs-nav">
                            <Nav variant="">
                                <Nav.Item>
                                    <Nav.Link eventKey="tab_1">Company</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab_2">Members</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab_3">Benfits</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab_4">Solutions</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab_5">All Prices</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab_6">Transfers</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab_7">Payments</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="dk-departmentTabs-panel">
                            <Tab.Content>
                                <Tab.Pane eventKey="tab_1">
                                    <FaqAccordion title1="Why is company started ?" summary1="test1"
                                        title2="Members 2  ?" summary2="test2"
                                        title3="Members 3 ?" summary3="test3" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab_2">
                                    <FaqAccordion title1="What is the location of this company ?" summary1="test1"
                                        title2="What is the legal status of the company  ?" summary2="test2"
                                        title3="Is there any location restriction of using this Platform  ?" summary3="test3" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab_3">
                                    <FaqAccordion title1="What is the location of this company ?" summary1="test1"
                                        title2="What is the legal status of the company  ?" summary2="test2"
                                        title3="Is there any location restriction of using this Platform  ?" summary3="test3" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab_4">
                                    <FaqAccordion title1="What is the location of this company ?" summary1="test1"
                                        title2="What is the legal status of the company  ?" summary2="test2"
                                        title3="Is there any location restriction of using this Platform  ?" summary3="test3" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab_5">
                                    <FaqAccordion title1="What is the location of this company ?" summary1="test1"
                                        title2="What is the legal status of the company  ?" summary2="test2"
                                        title3="Is there any location restriction of using this Platform  ?" summary3="test3" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab_6">
                                    <FaqAccordion title1="What is the location of this company ?" summary1="test1"
                                        title2="What is the legal status of the company  ?" summary2="test2"
                                        title3="Is there any location restriction of using this Platform  ?" summary3="test3" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab_7">
                                    <FaqAccordion title1="What is the location of this company ?" summary1="test1"
                                        title2="What is the legal status of the company  ?" summary2="test2"
                                        title3="Is there any location restriction of using this Platform  ?" summary3="test3" />
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default ReProjectionsFaq;
