import React, { useLayoutEffect } from 'react';
import Header from "../elements/header";
import Navbar from '../elements/header/navbar/mainMenu/database';

const DatabaseLayout = ({ children }) => {

    return (
        <React.Fragment>
            <Header mainheaderLink="DK GLOBAL RECRUITMENT" moduleLink="/dashboard/recruitment" linkcolor="#2e97b6" mainheaderTitle=" - Database" textcolor="#357655" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default DatabaseLayout;