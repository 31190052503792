import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData } from "../../../services";

export const companyDoc = createAsyncThunk(
    'companyList/doc-details',
    async ({ clientId }, thunkAPI) => {
        const res = await GetData(true, '/jobs-files-company/document/' + clientId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
