import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
// import "./responsiveMenu.scss";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const MyPipelineSubmenu = (props) => {
    const classes = useStyles();
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[2];
    const segment2 = pathname.split("/")[3];
    const segment4 = pathname.split("/")[4];

    const [toggleMenu, setToggleMenu] = useState(false);
    useEffect(() => {
        setToggleMenu(false)
    }, [pathname])

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setToggleMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);



    return (
        <React.Fragment>
            <div className="dk-subMenuMain res-sub-menu" ref={ref}>
                <IconButton aria-label="delete" className="res-sub-btn" onClick={() => setToggleMenu(!toggleMenu)}>
                    <i className="fas fa-bars"></i>
                </IconButton>
                <Route>
                    <ul className={`${toggleMenu ? "res-mobileNav show dkg-pipelinesubmenu" : null}`}>
                        <li>
                            <Link to="/my-pipeline/summary" className={`${(segment1 == 'summary') ? 'active' : ''}`} style={{ borderRight: "1px solid #ccc" }}>Summary</Link>
                        </li>
                        <li>
                            <Link to="/my-pipeline/new-case" className={`${(segment1 == 'new-case') ? 'active dkg-active dkg-newcv-active-color' : 'dkg-newcv-color'}`} style={{ borderRight: "1px solid #ccc" }}>New Case
                                <span className="countNum newcv-color ml-1">6</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/my-pipeline/job-spec" className={`${(segment1 == 'job-spec') ? 'active dkg-active  dkg-email-active-color ' : 'dkg-email-color'}`} style={{ borderRight: "1px solid #ccc" }}>Selected
                                <span className="countNum email-color ml-1">6</span>
                            </Link>
                        </li> */}
                        <li>
                            <Link to="/my-pipeline/calling" className={`${(segment1 == 'calling') ? 'active dkg-active dkg-calling-active-color ' : 'dkg-calling-color'}`} style={{ borderRight: "1px solid #ccc" }}>Today Work
                                <span className="countNum calling-color ml-1">6</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/my-pipeline/intrested" className={`${(segment1 == 'intrested') ? 'active dkg-active dkg-processing-active-color ' : 'dkg-processing-color'}`} style={{ borderRight: "1px solid #ccc" }}>Processing
                                <span className="countNum processing-color ml-1">6</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/my-pipeline/sendouts" className={`${(segment1 == 'sendouts') ? 'active dkg-active dkg-sendouts-active-color' : 'dkg-db-color'}`} style={{ borderRight: "1px solid #ccc" }}>Not Reach
                                <span className="countNum db-color ml-1">6</span>
                            </Link></li>
                        <li>
                            <Link to="/my-pipeline/job-offer" className={`${(segment1 == 'job-offer') ? 'active dkg-active dkg-joboffer-active-color' : ' dkg-intersted-color'}`} style={{ borderRight: "1px solid #ccc" }}>Queries
                                <span className="countNum intersted-color ml-1">6</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/my-pipeline/not-reachable" className={`${(segment1 == 'not-reachable') ? 'active dkg-active dkg-escalated-active-color ' : 'dkg-sendouts-color'}`} style={{ borderRight: "1px solid #ccc" }}>Sendout
                                <span className="countNum sendouts-color ml-1">6</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/my-pipeline/rec-admin" className={`${(segment1 == 'rec-admin') ? 'active dkg-active dkg-escalated-active-color ' : 'dkg-joboffer-color'}`} style={{ borderRight: "1px solid #ccc" }}>Job Offer
                                <span className="countNum joboffer-color ml-1">6</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/my-pipeline/closed" className={`${(segment1 == 'closed') ? 'active dkg-active dkg-closed-active-color ' : 'dkg-escalated-color'}`} style={{ borderRight: "1px solid #ccc" }} >Invoice
                                <span className="countNum escalated-color ml-1">6</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/my-pipeline/db-ready" className={`${(segment1 == 'db-ready') ? 'active' : 'dkg-closed-color'}`} style={{ borderRight: "1px solid #ccc" }}>Closed
                                <span className="countNum closed-color ml-1">6</span>
                            </Link>
                        </li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    )
}

export default MyPipelineSubmenu;