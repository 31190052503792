import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
// import "./upload-video.scss";
import ReactPlayer from "react-player/youtube";
const ViewvideoModal = ({ onHide, companyId, vid, yurl, type, title }) => {
  //  const dispatch = useDispatch();

  return (
    <>
      <Modal
        show={true}
        onHide={onHide}
        className="dkg-pagetype-uploadModal dkg-vedioplayer-viewModal"
        centered
      >
        <Modal.Header closeButton className="dkg-video-upload-Header">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-video-upload-Body">
          <ReactPlayer url={`${yurl}&ab_channel=fotios`} width="100%" />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewvideoModal;
