import React from 'react';
import { Link } from "react-router-dom";

const ContractLeftPanel = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    return (
        <>
            <div className="dk-contractsLeftpanel">
                <ul>
                    <li><Link to="/js-account/contracts" className={`${(segment2 === 'contracts') ? 'active' : ''}`}>2022</Link></li>
                    <li><Link to="#" className={`${(segment3 === '') ? '' : ''}`}>2021</Link></li>
                    <li><Link to="#" className={`${(segment3 === '') ? '' : ''}`}>2020</Link></li>
                    <li><Link to="#" className={`${(segment3 === '') ? '' : ''}`}>2019</Link></li>
                    <li><Link to="#" className={`${(segment3 === '') ? '' : ''}`}>2018</Link></li>
                    <li><Link to="#" className={`${(segment3 === '') ? '' : ''}`}>2017</Link></li>
                    <li><Link to="#" className={`${(segment3 === '') ? '' : ''}`}>2016</Link></li>
                </ul>
            </div>
        </>
    )
}

export default ContractLeftPanel
