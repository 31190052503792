import React from "react";

const RoletypeTab = ({ companyService }) => {
  return (
    <>
      <div className="dkg-jopspec-pageheader">
        <div className="dkg-jopspec-page-title">Services</div>
      </div>
      <div className="dkg-jobspec-read-editor-Con">
        <div className="dkg-jobspec-read-editor">
          <div
            className="dkg-jobspec-htmleditor"
            dangerouslySetInnerHTML={{
              __html:
                companyService.length > 0 && companyService[0].services != null
                  ? companyService[0].services
                  : "",
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default RoletypeTab;
