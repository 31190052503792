import React from "react";
import { Route, Link } from "react-router-dom";

const PayInvoicesSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/payments/invoices/job-board" className={`${(segment3 == 'job-board') ? 'active' : ''}`}>Job Board</Link></li>
                        <li><Link to="/payments/invoices/employee" className={`${(segment3 == 'employee') ? 'active' : ''}`}>Employee</Link></li>
                        <li><Link to="/payments/invoices/software" className={(segment3 == 'software') ? 'active' : ''}>Software</Link></li>
                        <li><Link to="/payments/invoices/license" className={`${(segment3 == 'license') ? 'active' : ''}`}>License</Link></li>
                        <li><Link to="/payments/invoices/legal" className={`${(segment3 == 'legal') ? 'active' : ''}`}>Legal</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'training-jobs') ? 'active' : ''}`}>sample</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default PayInvoicesSubmenu;
