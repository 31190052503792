import React from 'react';
import BusinessLeftPanel from './leftpanel/BusinessLeftPanel';
import "../recconsultants.scss";

const RecBusinessSampleThree = () => {
    return (
        <>
            <div className="dk-consultantsTraining-main">
                <div className="dk-consultantsTraining-cols">
                    <BusinessLeftPanel />
                    <div className="dk-consultantsTraining-rightPanel">
                        <div className="title">Overview</div>
                        <div className="dk-consultantsTraining-rightCols">
                            <p>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facilis aliquid quas, delectus assumenda vel rerum. Animi, at suscipit. Enim magnam, aperiam corporis itaque deserunt soluta quia sequi. Aperiam, eaque blanditiis.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecBusinessSampleThree;
