import React, { useState, useEffect } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'

const BankingCategory = ({ list, selectStatusFilter, taskTypeFilterText }) => {
    const [taskTypeList, setTaskTypeList] = useState([]);
    useEffect(() => {
        const result = [];
        const map = new Map();
        for (const item of list) {
            if (!map.has(item.original.category)) {
                map.set(item.original.category, true);
                result.push({
                    id: item.original.id,
                    name: item.original.category,
                    count: list.filter(function (val) {
                        return val.original.category === item.original.category;
                    }).length
                });
            }
        }
        result.sort(function (a, b) {
            return b.count - a.count;
        });
        setTaskTypeList(result)
    }, [list])

    return (
        <React.Fragment>
            <DropdownButton className="dropdownFilter dkg-banking-categry-234 dkg-banking-rightFilter-89" id="dropdown-basic-button" title={taskTypeFilterText}>
                {
                    taskTypeList && taskTypeList.map((item, index) =>
                        <Dropdown.Item className="dkg-banking-categry-item" key={`case-status-id${index}`} href="#" data-type="taskType" data-value={item.name} onClick={selectStatusFilter}>
                            {item.name}
                            <span className="dropdown-filter-count">
                                {item.count}
                            </span>
                        </Dropdown.Item>
                    )
                }
            </DropdownButton>
        </React.Fragment>
    )
}

export default BankingCategory;