import React from 'react'

const CaseStatusRenderDiv = ({ value }) => {

    let bgColor = "";
    switch (value) {
        case 'Active':
            bgColor = '#3a9469'
            break;

        case 'Closed':
            bgColor = '#dc3545'
            break;

        case 'Inactive':
            bgColor = '#eaa03d'
            break;
    }

    return (
        <div className="statusDiv" style={{ backgroundColor: bgColor }}>{value}</div>
    )
}

export default CaseStatusRenderDiv