import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const ActionDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkglobaladmin/admintraining/managers/action" className={`${pathname.match('/dkglobaladmin/admintraining/managers/action') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/managers/sequence" className={`${pathname.match('/dkglobaladmin/admintraining/managers/sequence') ? 'active' : ''}`}>Sequence</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/managers/set-priority" className={`${pathname.match('/dkglobaladmin/admintraining/managers/set-priority') ? 'active' : ''}`}>Set Priority</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/managers/managers-action-priority" className={`${pathname.match('/dkglobaladmin/admintraining/managers/managers-action-priority') ? 'active' : ''}`}>Action Priority</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/managers/create-new-task" className={`${pathname.match('/dkglobaladmin/admintraining/managers/create-new-task') ? 'active' : ''}`}>Create New Task</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/managers/follow-workplan" className={`${pathname.match('/dkglobaladmin/admintraining/managers/follow-workplan') ? 'active' : ''}`}>Follow Workplan</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default ActionDetailLeftPanel;

