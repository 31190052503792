import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import Switch from "./Switch";
import {
  defaultInterviewProject,
  updateInterviewValue,
} from "../../../../../../slice/jobSpec/jobSpecSlice";
//import { showError } from "../../../../../utils/messages/messageSlice";

const InterviewsTab = ({ jobId, clientid }) => {
  const dispatch = useDispatch();
  const {
    isInterviewSuccess,
    is5Loading,
    interviewRelationData,
    InterviewList,
  } = useSelector((state) => state.jobsSpec);

  useEffect(() => {
    dispatch(
      defaultInterviewProject({
        jobsId: jobId,
        tbl: "jobs_relationship_interviews",
      })
    );
  }, []);

  const [selectedId, setSelectedId] = useState(null);
  const [intviewlist, setInterviewlist] = useState([]);

  useEffect(() => {
    if (isInterviewSuccess && InterviewList.length > 0) {
      setSelectedId(InterviewList[0].companyId);
    }
  }, [isInterviewSuccess]);

  useEffect(() => {
    if (!is5Loading && interviewRelationData.length > 0) {
      setInterviewlist(interviewRelationData);
    }
  }, [is5Loading]);

  const handleChange = (id, companyname, companyidds) => {
    setSelectedId(id);
    dispatch(
      updateInterviewValue({
        id: id,
        key: "isShowInJob",
        value: 1,
        jobsId: jobId,
        clientId: 0,
        clientname: companyname,
        tableName: "jobs_relationship_interviews",
      })
    );
  };

  return (
    <>
      <div className="dkg-company-list-mainCon">
        <div className="dkg-company-list-tableCon table-responsive">
          <table className="dkg-company-list-table table table-borderded">
            <thead>
              <tr>
                <th>Interview</th>
                <th>Linked</th>
              </tr>
            </thead>
            <tbody>
              {intviewlist.map((details, index) => (
                <tr key={index + 1}>
                  <td>{details.categoryName}</td>
                  <td>
                    <span className="dkg-clientinfo-checkbox">
                      <input
                        type="radio"
                        className="form-control"
                        name="clientinfo-checkbox-interviews"
                        data-id={details.id}
                        data-companyname={details.categoryName}
                        id={details.id}
                        checked={selectedId === details.id}
                        onChange={() =>
                          handleChange(details.id, details.categoryName)
                        }
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default InterviewsTab;
