import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const ProgressDropdown = () => {
  return (
    <>
      <Dropdown className="dk-trackingDropdown">
        <Dropdown.Toggle variant="" id="dropdown-basic">
          Category
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className="dk-trackingInput">
            <input type="text" placeholder="Search Text..." name="" id="" />
          </div>
          <Dropdown.Item href="#">My Task <span className="dropdown-filter-count">3</span></Dropdown.Item>
          <Dropdown.Item href="#">Other Task <span className="dropdown-filter-count">3</span></Dropdown.Item>
          <Dropdown.Item href="#">Group Task <span className="dropdown-filter-count">3</span></Dropdown.Item>
          <Dropdown.Item href="#">Payment Task <span className="dropdown-filter-count">3</span></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default ProgressDropdown