import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { useSelector } from "react-redux";
import "./dropdownFilter.scss";
import { FaEnvelope, FaCheck, FaExclamationCircle, FaQuestionCircle, FaThumbsUp, FaPauseCircle } from 'react-icons/fa';

const TaskStatusFilter = ({ list, caseStatusFilterText, onSelectStatusFilter }) => {
    return (
        <React.Fragment>
            <div className="dk-stcategoryDropdown">
                <DropdownButton className='dk-cli-status-dropdown' title={caseStatusFilterText} onSelect={onSelectStatusFilter}>
                    <Dropdown.Item eventKey="New Task" href="#" className="dk-activeBtn" style={{ background: '#d58b5d', color: '#fff' }} > <FaEnvelope /> New Task
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.status === 'New Task';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="In Action" href="#" className="dk-inActiveBtn" style={{ background: '#26597d', color: '#fff' }}><FaCheck /> In Action
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.status === 'In Action';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Overdue" href="#" className="" style={{ background: '#d91010', color: '#fff' }}><FaExclamationCircle /> Overdue
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.status === 'Overdue';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Pause" href="#" className="" style={{ background: '#625c60', color: '#fff' }}><FaPauseCircle /> Pause
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.status === 'Pause';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Success" href="#" className="" style={{ background: '#3a9469', color: '#fff' }}><FaThumbsUp /> Success
                        <span className="dropdown-filter-count">
                            {
                                list.filter(function (val) {
                                    return val.original.status === 'Success';
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                </DropdownButton>

            </div>
        </React.Fragment>
    )
}

export default TaskStatusFilter;