import React from 'react';
import AccInvoicesSubmenu from "../../../elements/header/navbar/subMenu/menu/accountingmodule/AccInvoicesSubmenu";

const Invoices = () => {
    return (
        <>
            <AccInvoicesSubmenu />
            <div className='container-fluid' style={{ marginTop: "125px" }}>

            </div>
        </>
    )
}

export default Invoices;
