import React from 'react';
const PaymentStatus = ({ value }) => {
    let bgColor = "";
    switch (value) {
        case 'New Task':
            bgColor = '#d58b5d'
            break;

        case 'Queries':
            bgColor = '#625c60'
            break;

        case 'Accepted':
            bgColor = '#26597d'
            break;

        case 'Partially Paid':
            bgColor = '#715f8f'
            break;

        case 'Fully Paid':
            bgColor = '#3a9469'
            break;

        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor, color: '#FFF', minWidth: '80px' }}>{value}</div>
        </>
    )
}

export default PaymentStatus;