import React from 'react';
import { Link, Route } from "react-router-dom";

const RecDepLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-consultantsTraining-leftPanel">
                <ul>
                    <Route>
                        <li><Link to="/trainingmodule/recruitment-consultants/rec-dep/sample" className={`${pathname.match('/trainingmodule/recruitment-consultants/rec-dep/sample') ? 'active' : ''}`}>Overview</Link></li>

                        <li><Link to="#" className={`${pathname.match('') ? '' : ''}`}>Sample 1</Link></li>
                        <li><Link to="#" className={`${pathname.match('') ? '' : ''}`}>Sample 2</Link></li>
                        <li><Link to="#" className={`${pathname.match('') ? '' : ''}`}>Sample 3</Link></li>
                        <li><Link to="#" className={`${pathname.match('') ? '' : ''}`}>Sample 4</Link></li>
                        <li><Link to="#" className={`${pathname.match('') ? '' : ''}`}>Sample 5</Link></li>

                    </Route>
                </ul>
            </div>
        </React.Fragment>
    )
}

export default RecDepLeftPanel;

