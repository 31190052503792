import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../jobsrightmodal.scss";
import { Modal, Tab, Nav } from "react-bootstrap";
import JobTitle from "../details/jobtitle/Jobtitle";
import Instructions from "../details/instructions/Instructions";
import Adstopost from "../details/adstopost/Adstopost";
import Cvsearch from "../details/cvsearch/Cvsearch";
import Campaigns from "../details/campaigns/Campaigns";
import Mailshot from "../details/mailshot/Mailshot";
import Jdetails from "../details/jobdetails/Jobdetails";

const AdPlan = (jobId) => {
  return (
    <>
      <div className="row dk-Adsbox dkg-livejobs-adsboxRow">
        <div className="col-md-12 dkg-livejobs-adsboxCol">
          <Tab.Container id="left-tabs-example" defaultActiveKey="details">
            <div
              className="row samplead ad-description dkg-livejobs-adsboxRow-234"
            // style={{
            //   border: "1px solid #bdbdbd",
            // }}
            >
              <div className="col-md-2 col-sm-2 specsLeftTab dkg-livejobs-adsboxleftSide pl-0 pr-0">
                <Nav variant="pills" className="d-flex edit-header nav nav-d-flex edit-header nav nav-pills dkg-livejobs-adsbox-navpills">
                  <Nav.Item>
                    <Nav.Link eventKey="details">Ad to Post</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="title">Job Titles</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="adstopost">Ads to Post</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="cvsearch">CV Search</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="campaigns">Campaign</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="mailshot">Mailshot</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="instructions">Instructions</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-10 col-sm-10 dkg-livejobs-adsboxRightSide specsLeftTab pl-0 pr-0">
                <Tab.Content>
                  <Tab.Pane eventKey="details">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div
                          className="col-md-12 interviewsTable p-0 mt-3"
                          style={{ height: "calc(100vh - 201px)" }}
                        >
                          <Jdetails jobId={jobId} />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="title">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div
                          className="col-md-12 interviewsTable mt-3 p-0"
                          style={{ height: "calc(100vh - 201px)" }}
                        >
                          <JobTitle jobId={jobId} />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="adstopost">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div
                          className="col-md-12 mt-3 p-0"
                          style={{ height: "calc(100vh - 201px)" }}
                        >
                          <Adstopost jobId={jobId} />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="cvsearch">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div
                          className="col-md-12 mt-3 p-0"
                          style={{ height: "calc(100vh - 201px)" }}
                        >
                          <Cvsearch jobId={jobId} />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="campaigns">
                    <div className="dk-ClentViewTabPane">
                      <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                        <div className="row dk-Adsbox">
                          <div
                            className="col-md-12 mt-3 p-0"
                            style={{ height: "calc(100vh - 201px)" }}
                          >
                            <Campaigns jobId={jobId} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="mailshot">
                    <div className="dk-ClentViewTabPane">
                      <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                        <div className="row dk-Adsbox">
                          <div
                            className="col-md-12 mt-3 p-0"
                            style={{ height: "calc(100vh - 201px)" }}
                          >
                            <Mailshot jobId={jobId} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="adplan">
                                        <div className="dk-ClentViewTabPane summaryTabPane">
                                            <Adplan jobId={jobId} />
                                        </div>
                                    </Tab.Pane> */}
                  <Tab.Pane eventKey="instructions">
                    <div className="dk-ClentViewTabPane">
                      <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                        <div className="row dk-Adsbox">
                          <div
                            className="col-md-12 interviewsTable mt-3 p-0"
                            style={{ height: "calc(100vh - 201px)" }}
                          >
                            <Instructions jobId={jobId} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default AdPlan;
