import React, { useEffect, useState } from 'react';
import './style.scss';
import Modal from 'react-bootstrap/Modal';
import Submit from "../../ui/submitButton";

const BulkMoveAdData = ({ id, handleClose, deleteItem, loading, success }) => {
    const [show, setShow] = useState(false);
    useEffect(() => {        
        setShow(true)
    }, [id])

    return (
        <React.Fragment>
            <Modal className="dk-mlAddNewModal dk-deleteModal" centered show={show} onHide={handleClose}>
                <Modal.Body className="dk-mlAddModalBody">
                    <p>Are you sure wants to Move these records ?</p>
                    <div className="d-flex justify-content-end">
                        <button onClick={handleClose}>No</button>
                        <Submit txt="Yes" loading={loading} success={success} onClick={deleteItem} position="justify-content-center" className="dk-yesBtn" />
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default BulkMoveAdData;
