import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Route, Link } from "react-router-dom";

const RecruitmenttipsMenu = () => {
  const pathname = window.location.pathname;
  const segment2 = pathname.split("/")[2];
  const segment4 = pathname.split("/")[4];

  useEffect(() => {
    document.title =
      pathname.split("/")["1"].charAt(0).toUpperCase() +
      pathname.split("/")["1"].slice(1) +
      " | DK Global CRM";
  }, [pathname]);

  return (
    <React.Fragment>
      <div className="dk-navBarMain dk-recruitmentNavbar">
        <Route>
          <ul>
            <li>
              <Link
                to="/recruitment-tips/summary"
                className={`${segment2 === "summary" ? "white-active" : ""}`}
              >
                Summary{" "}
              </Link>
            </li>
            <li>
              <Link
                to="/recruitment-tips/live-spec"
                className={`${segment2 === "live-spec" ? "active" : ""}`}
              >
                Live Spec
              </Link>
            </li>
            <li>
              <Link
                to="/recruitment-tips/active-spec"
                className={`${
                  segment2 === "active-spec" || segment4 === "Active"
                    ? "white-active"
                    : ""
                }`}
              >
                Active Spec
              </Link>
            </li>
            <li>
              <Link
                to="/recruitment-tips/inactive-spec"
                className={
                  segment2 === "inactive-spec" || segment4 === "Inactive"
                    ? "white-active"
                    : ""
                }
              >
                Inactive Spec
              </Link>
            </li>
            <li>
              <Link
                to="/recruitment-tips/create-spec"
                className={`${segment2 === "create-spec" ? "active" : ""}`}
              >
                Create Spec
              </Link>
            </li>
            {/* <li><Link to="#/recruitment-tips/data-table" className={`${(segment2 === 'data-table') ? 'white-active' : ''}`}>Rec Tips 4</Link></li>
                        <li><Link to="#/recruitment-tips/data-twenty-two" className={`${(segment2 === 'data-twenty-two') ? 'white-active' : ''}`}>Rec Tips 5 </Link></li>
                        <li><Link to="#/recruitment-tips/data-twenty-one" className={`${(segment2 === '/data-twenty-one') ? 'white-active' : ''}`}>Rec Tips 6 </Link></li>
                        <li><Link to="#/recruitment-tips/data-twenty-twenty" className={`${(segment2 === 'data-twenty-twenty') ? 'white-active' : ''}`}>Rec Tips 7</Link></li>
                        <li><Link to="#/recruitment-tips/data-twenty-nineteen" className={`${(segment2 === 'data-twenty-nineteen') ? 'white-active' : ''}`}> Rec Tips 8 </Link></li>
                        <li><Link to="#/recruitment-tips/data-twenty-eighteen" className={`${(segment2 === 'data-twenty-eighteen') ? 'white-active' : ''}`}>Rec Tips 9</Link></li>
                        <li><Link to="#/recruitment-tips/data-twenty-seventeen" className={`${(segment2 === 'data-twenty-seventeen') ? 'white-active' : ''}`}>Rec Tips 10</Link></li> */}
          </ul>
        </Route>
      </div>
      {/* <Submenu path={pathname} /> */}
    </React.Fragment>
  );
};

export default RecruitmenttipsMenu;
