import React from 'react';

const index = () => {
    return (
        <>
            <div className='container-fluid' style={{ marginTop: "125px" }}>
                <h2 className='w-100 text-center '>Database Summary</h2>
            </div>
        </>
    )
}

export default index;
