import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import "./responsiveMenu.scss";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const DKGCloudSubmenu = (props) => {
    const classes = useStyles();
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    const segment4 = pathname.split("/")[4];

    const [toggleMenu, setToggleMenu] = useState(false);
    useEffect(() => {
        setToggleMenu(false)
    }, [pathname])

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setToggleMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);

    return (
        <React.Fragment>
            <div className="dk-subMenuMain res-sub-menu" ref={ref}>
                <IconButton aria-label="delete" className="res-sub-btn" onClick={() => setToggleMenu(!toggleMenu)}>
                    <i className="fas fa-bars"></i>
                </IconButton>
                <Route>
                    <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
                        <li><Link to="/recruitment/dkg-cloud/summary" className={`${(segment3 == 'summary') ? 'active' : ''}`}>Summary</Link></li>
                        <li><Link to="/recruitment/dkg-cloud/dk-files/dk-global" className={`${(segment4 == 'dk-global') ? 'active' : ''}`}>DK Global</Link></li>
                        <li><Link to="/recruitment/dkg-cloud/dk-files/clinets" className={`${(segment4 == 'clinets') ? 'active' : ''}`}>Clinets</Link></li>
                        <li><Link to="/recruitment/dkg-cloud/dk-files/job-spec" className={`${(segment4 == 'job-spec') ? 'active' : ''}`}>Job Spec</Link></li>
                        <li><Link to="/recruitment/dkg-cloud/dk-files/interviews" className={`${(segment4 == 'interviews') ? 'active' : ''}`}>Interviews</Link></li>
                        <li><Link to="/recruitment/dkg-cloud/dk-files/relocation" className={`${(segment4 == 'relocation') ? 'active' : ''}`}>Relocation</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default DKGCloudSubmenu;
