import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './dkgstaffcustom.scss';
import Dropdown from 'react-bootstrap/Dropdown'
// import DkgStaffLeftPanel from './leftpanel/DkgStaffLeftPanel';
import { FaSearch, FaPlus, FaEye, FaDownload, FaFilter, FaArrowsAlt, FaTrashAlt, FaEdit } from 'react-icons/fa';
import SearchModal from "./modal/SearchModal";
import AddNewModal from "./modal/AddNewModal";
import EditModal from './modal/EditModal';
import ViewModal from './modal/ViewModal';
import DeleteModal from '../../../../ui/Modal/deletemodal/DeleteDummy'

const ContractsDkgStaff = () => {
    const [isShowSearch, setShowSearchModal] = useState(false)
    const [isShowAddNew, setShowAddNew] = useState(false)
    const [isEditNew, setEditNew] = useState(false)
    const [isViewTax, setViewTax] = useState(false)
    const [isDeleteModal, setDeleteModal] = useState(false)


    const showSearch = () => {
        setShowSearchModal(true)
    }

    const SearchClose = () => {
        setShowSearchModal(false)
    }
    const showAddNew = () => {
        setShowAddNew(true)
    }

    const SearchAddNew = () => {
        setShowAddNew(false)
    }
    const showEditModal = () => {
        setEditNew(true)
    }
    const hideEditModal = () => {
        setEditNew(false)
    }
    const showviewModal = () => {
        setViewTax(true)
    }
    const hideviewModal = () => {
        setViewTax(false)
    }
    const showdeleteModal = () =>{
        setDeleteModal(true);
    }
    const hidedeleteModal = () =>{
        setDeleteModal(false);
    }
    var today = new Date();
    const [yearValue, setYearValue] = useState(today.getFullYear());  
    return (
        <>
            {
                (isShowSearch) ? <SearchModal onHide={SearchClose} /> : null
            }
            {
                (isShowAddNew) ? <AddNewModal onHide={SearchAddNew} /> : null
            }
            {
                (isEditNew) ? <EditModal onHide={hideEditModal} /> : null
            }
            {
                (isViewTax) ? <ViewModal onHide={hideviewModal} /> : null
            }
            {
              (isDeleteModal) ? <DeleteModal onHide={hidedeleteModal} /> : null
            }
            <div className="dk-dkgStaff-containerMain">
                {/* <DkgStaffLeftPanel /> */}

                <div className="dk-dkgStaff-rightPanel">
                    <div className="dk-dkgStaffnHead">
                        <div className='dkg-leftStaffnHead'>
                            <div className="dk-searchCols">
                                <input type="text" name="" id="" placeholder="Search..." className="form-control" />
                                <button><FaSearch /></button>
                            </div>
                            <div className="dk-searchIocn" onClick={showAddNew}>
                                <Link to="#" ><FaPlus /></Link>
                            </div>
                        </div>
                        <div className="title">CLIENTS CONTRACTS</div>
                        <div className="d-flex dkg-contracts-right-con">
                            <div className="dk-searchIocn mr-2">
                                <Link to="#" onClick={showSearch}><FaFilter /></Link>
                            </div>
                            <div className="dk-searchIocn mr-2">
                                <Link to="#"><FaArrowsAlt /></Link>
                            </div>
                            <div className='dkg-contracts-dropcon'>
                                <Dropdown className='dkg-contracts-dropdown'>
                                    <Dropdown.Toggle variant="" className='dkg-contracts-dropdown-toggle'>
                                        {yearValue}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dkg-contracts-dropdown-menu'>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' onClick={() => setYearValue("2019")}>
                                        2019
                                          <span className='dkg-number-counter'>0</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' onClick={() => setYearValue("2020")}>
                                        2020
                                           <span className='dkg-number-counter'>0</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' onClick={() => setYearValue("2021")}>
                                        2021
                                           <span className='dkg-number-counter'>0</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' onClick={() => setYearValue("2022")}>
                                        2022
                                            <span className='dkg-number-counter'>0</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' onClick={() => setYearValue("2023")}>
                                        2023
                                            <span className='dkg-number-counter'>0</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' onClick={() => setYearValue("2024")}>
                                        2024
                                           <span className='dkg-number-counter'>0</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' onClick={() => setYearValue("2025")}>
                                        2025
                                            <span className='dkg-number-counter'>0</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' onClick={() => setYearValue("2026")}>
                                        2026
                                          <span className='dkg-number-counter'>0</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' onClick={() => setYearValue("2027")}>
                                        2027
                                          <span className='dkg-number-counter'>0</span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                    </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div className="dk-dkgStaff-table">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Status</th>
                                    <th>Start Date</th>
                                    <th>Expire On</th>
                                    <th>Duration</th>
                                    <th>Contract Type</th>
                                    <th>Name of Client</th>
                                    <th>Location of Client</th>
                                    <th>Services Type</th>
                                    <th>Year</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            1
                                            {/* <input type="checkbox" className="dk-chkSendouts" name="" id="" /> */}
                                        </div>
                                    </td>
                                    <td>
                                        <Dropdown className='dkg-return-status-dropdown'>
                                            <Dropdown.Toggle variant="" className='dkg-return-status-dropdown-toggle' style={{ backgroundColor: "#3a9469", color: "#fff" }}>
                                                Active
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dkg-return-status-dropdown-Menu' style={{ borderRadius: "0", padding: "0" }}>
                                                <Dropdown.Item className='dkg-return-status-dropdown-item' style={{ backgroundColor: "#3a9469", color: "#fff" }} href="#">Active</Dropdown.Item>
                                                <Dropdown.Item className='dkg-return-status-dropdown-item' style={{ backgroundColor: "red", color: "#fff" }} href="#">InActive</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    <td>02 Sep 22</td>
                                    <td>10 Sep 22</td>
                                    <td>10 Days</td>
                                    <td>Contract Type 1</td>
                                    <td>Rossella Casciello</td>
                                    <td>Ireland</td>
                                    <td>Recruitment</td>
                                    <td>
                                    {yearValue}
                                    </td>
                                    <td>
                                        <div className="dk-acc-taxreturnAction">
                                           <ViewModal />
                                            <Link to="#" className="dkg-downloadBtn mr-2"><FaDownload /></Link>
                                            <Link to="#" className="dkg-editBtn mr-2" onClick={showEditModal}><FaEdit /></Link>
                                            <Link to="#" className="dkg-deleteBtn" onClick={showdeleteModal}><FaTrashAlt /></Link>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContractsDkgStaff;
