import React from 'react';
import { Link } from 'react-router-dom'
import EngFlag from "../../../../../../../assets/images/eng.jpg";
import GermanFlag from "../../../../../../../assets/images/german.jpg";
import FrenchFlag from "../../../../../../../assets/images/french.jpg";

import './otherinfo.scss';

const OtherInfo = () => {
    return (
        <>
            <div className="other-InfoMainCon">
                <div className="col-md-12 pl-3 pr-3">
                    <div className="title dk-colsTitle mt-3">Language Skills</div>
                    <div className="dk-viewLanguage">
                        <div className='dkg-country-flag-con'>
                            <img src={EngFlag} className="img-fluid mr-3" alt="" /> English : Native
                        </div>
                        <div className='dkg-country-flag-con'>
                            <img src={GermanFlag} className="img-fluid mr-3" alt="" /> German : Fluent
                        </div>
                        <div className='dkg-country-flag-con'>
                            <img src={FrenchFlag} className="img-fluid mr-3" alt="" /> French : Intermediate
                        </div>
                    </div>
                    <div className="title dk-colsTitle ml-0 mt-3">Technical Skills</div>
                    <div className="dk-skillsCols">
                        <Link to="#">HTML</Link>
                        <Link to="#">PHP</Link>
                        <Link to="#">Photoshop</Link>
                        <Link to="#">Laravl</Link>
                        <Link to="#">Javascript</Link>
                        <Link to="#">Jquery</Link>
                        <Link to="#">React Js</Link>
                        <Link to="#">Angular Js</Link>
                        <Link to="#">Coral Draw</Link>
                    </div>
                    <div className="title dk-colsTitle ml-0">Workplace Skills</div>
                    <div className="dk-skillsCols">
                        <Link to="#">HTML</Link>
                        <Link to="#">PHP</Link>
                        <Link to="#">Photoshop</Link>
                        <Link to="#">Laravl</Link>
                        <Link to="#">Javascript</Link>
                        <Link to="#">Jquery</Link>
                    </div>
                    <div className="title dk-colsTitle ml-0">Hobbies & Interests</div>
                    <div className="dk-skillsCols">
                        <Link href="#">Cricket</Link>
                        <Link href="#">Football</Link>
                        <Link href="#">Chess</Link>
                        <Link href="#">Music</Link>
                        <Link href="#">Dance</Link>
                        <Link href="#">Wriniting</Link>
                    </div>
                    <div className="title mb-2 ml-2">Job References</div>
                    <div className="dk-skillsCols mb-4 ml-2">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, soluta repellendus! Porro obcaecati quaerat dicta sunt dolorem inventore, neque ut molestias? Corporis at natus, beatae aliquid ipsum laborum.</p>
                    </div>
                    <div className="title mb-2 ml-2">GDPR Consent</div>
                    <div className="dk-skillsCols mb-4 ml-2">
                        <p>
                            Up to date Knowledge with the latest industry trends in Graphic design and usability. ❖ Graphic Designing ❖ Web Designing Expert in handling the challenging projects in a professional way. ❖ E-mailer Designing ❖
                            Video Editing
                        </p>
                    </div>
                </div>
                <div className="col-md-12 d-none">
                    <div className="title mb-3 ml-2">Other Informations</div>
                    <table className="table dk-otherInfoTable">
                        <tbody>
                            <tr>
                                <td>Resume File Name</td>
                                <td>:</td>
                                <td>Resume.pdf</td>
                            </tr>
                            <tr>
                                <td>Resume Language</td>
                                <td>:</td>
                                <td>English(en)</td>
                            </tr>
                            <tr>
                                <td>Parsing Date</td>
                                <td>:</td>
                                <td>22/01/2021 3:06:20</td>
                            </tr>
                            <tr>
                                <td>Category</td>
                                <td>:</td>
                                <td>Information Technology</td>
                            </tr>
                            <tr>
                                <td>SubCategory</td>
                                <td>:</td>
                                <td>Software Developers and Programmers</td>
                            </tr>
                            <tr>
                                <td>Worked Period in Month</td>
                                <td>:</td>
                                <td>61</td>
                            </tr>
                            <tr>
                                <td>Worked Period in Year</td>
                                <td>:</td>
                                <td>5.1</td>
                            </tr>
                            <tr>
                                <td>Worked Period Range</td>
                                <td>:</td>
                                <td>5-8 Year</td>
                            </tr>
                            <tr>
                                <td>Average Stay</td>
                                <td>:</td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>Longest Stay</td>
                                <td>:</td>
                                <td>31</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default OtherInfo;
