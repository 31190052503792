import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'

const index = () => {
    return (
        <>
            <Dropdown className="dkg-mypipe-cs-dropdown">
                <Dropdown.Toggle variant="" className="dkg-mypipe-cs-dropdown-toggle">
                    <div className="caseDiv" style={{ backgroundColor: "rgb(92, 115, 125)" }}>To Assign</div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dkg-mypipe-cs-dropdown-menu">
                    <Dropdown.Item href="#" className='dkg-mypipe-cs-dropdown-item' style={{ backgroundColor: "#fff", color: "#333" }}>To Assign</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default index