import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import PagetypeVideos from "../../../../../../../assets/images/album.jpg";
import ViewvideoModal from "./modal/video/ViewvideoModal";
import ReactPlayer from "react-player/youtube";
// import "./company-video.scss";
const VideoTab = ({ compVideo }) => {
  //console.log(compVideo);
  const [isViewVideo, setIsViewVideo] = useState(false);
  // const [commpvideo, setCommpvideo] = useState([]);

  const [rowId, setRowId] = useState(
    compVideo.length > 0 ? compVideo[0].compId : 0
  );

  const [vId, setVid] = useState("");
  const [yId, setYid] = useState("");
  const [type, setType] = useState("");
  const [titles, setTitles] = useState("");

  const [commpvideo, setCommpvideo] = useState([]);
  useEffect(() => {
    setCommpvideo(compVideo);
  }, [compVideo]);

  const showviewvideoModal = (id, comp, youturl, title, type) => {
    setVid(id);
    setRowId(comp);
    setYid(youturl);
    setType(type);
    setTitles(title);
    setIsViewVideo(true);
  };
  const hideaddnewModal = () => {
    setIsViewVideo(false);
  };

  return (
    <>
      {isViewVideo ? (
        <ViewvideoModal
          onHide={hideaddnewModal}
          companyId={rowId}
          vid={vId}
          yurl={yId}
          type={type}
          title={titles}
        />
      ) : null}
      <div className="dkg-jopspec-pageheader">
        <div className="dkg-jopspec-page-title">Videos</div>
      </div>
      <div className="dkg-company-video-mainCon">
        <div className="dkg-company-videoCon">
          <div className="dkg-company-mainRow row">
            {commpvideo.length > 0 &&
              commpvideo.map((data, index) => {
                return (
                  <>
                    <div
                      key={index + 1}
                      className="col-lg-4 col-md-4 col-sm-12 dkg-company-video-cols"
                    >
                      <div className="dkg-company-video-box ">
                        <div className="dkg-company-album-box-header">
                          <h2 className="dkg-album-box-header-title">
                            {data.videoTitle != "" && data.videoTitle != null
                              ? data.videoTitle
                              : "Video title"}
                          </h2>
                        </div>
                        <figure className="dkg-company-video-figure">
                          <div className="dkg-company-video-imgCon">
                            {data.youtubeurl != "" &&
                            data.youtubeurl != null ? (
                              <>
                                <ReactPlayer
                                  url={`${data.youtubeurl}&ab_channel=fotios`}
                                  width="100%"
                                  height="210px"
                                />
                              </>
                            ) : (
                              <img
                                src={PagetypeVideos}
                                className="dkg-company-video-img"
                                alt="Video"
                              />
                            )}
                          </div>
                        </figure>
                        <div className="dkg-company-bottomCon">
                          <button
                            className="btn dkg-upload-mediabtn"
                            onClick={() =>
                              showviewvideoModal(
                                data.id,
                                data.compId,
                                data.youtubeurl,
                                data.videoTitle,
                                "Add"
                              )
                            }
                          >
                            VIEW
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoTab;
