import React from 'react'

const index = () => {
    return (
        <>
            <div className='container-fluid' style={{ marginTop: "160px" }}>
                <h5 className='w-100 text-center'> Onboarding Summary</h5>
            </div>
        </>
    )
}

export default index