import React from 'react'

const index = () => {
    return (
        <>
            <div className='container-fluid' style={{ marginTop: "155px" }}>
                <h3 className='text-center w-100'> Bank A/C</h3>
            </div>
        </>
    )
}

export default index