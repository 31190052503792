import React from 'react'
import second from './loginsdetails.scss'

const index = () => {
    return (
        <>
            <div className="dk-loginsdetalisMain">
                <div className="row">
                    <div className="col-md-12 dkg-logins-dashboard-boxsCon ">
                        <div className="dkg-logins-LeftCon">
                            <div className='dkg-loginsdetalis-boxRow'>
                                <div className="dk-loginsdetalisCols">
                                    <div className="title">
                                        Office 365
                                    </div>
                                    <div className="dk-itTeamBox">
                                    </div>
                                </div>
                                <div className="dk-loginsdetalisCols">
                                    <div className="title">
                                        DKG CRM
                                    </div>
                                    <div className="dk-itTeamBox">

                                    </div>
                                </div>

                            </div>
                            <div className='dkg-loginsdetalis-boxRow'>
                                <div className="dk-loginsdetalisCols">
                                    <div className="title">
                                        Email A/C
                                    </div>
                                    <div className="dk-itTeamBox">

                                    </div>
                                </div>
                                <div className="dk-loginsdetalisCols">
                                    <div className="title">
                                        Software
                                    </div>
                                    <div className="dk-itTeamBox">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dkg-logins-RightCon">
                            <div className='dkg-loginsdetalis-boxRow'>
                                <div className="dk-loginsdetalisCols">
                                    <div className="title">
                                        job Portals
                                    </div>
                                    <div className="dk-itTeamBox">

                                    </div>
                                </div>
                                <div className="dk-loginsdetalisCols">
                                    <div className="title">
                                        Clients Portal
                                    </div>
                                    <div className="dk-itTeamBox">

                                    </div>
                                </div>
                                <div className="dk-loginsdetalisCols">
                                    <div className="title">
                                        Social Media
                                    </div>
                                    <div className="dk-itTeamBox">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default index