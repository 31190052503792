import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const livelistTable = createAsyncThunk(
    'liveAdsSlice/liveadstracker',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/ads/live-ads',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);



export const addLiveAds = createAsyncThunk(
    'liveAdsSlice/add_tracker',
    async ({ recruiter, jobTitle, jobBoard, jobRef, totalApplication, company, roleType, language, city, country, postedDate, expiredDate }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/ads/live-ads',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        recruiter, jobTitle, jobBoard, jobRef, totalApplication, company, roleType, language, city, country, postedDate, expiredDate
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const fethById = createAsyncThunk(
    'liveAdsSlice/ads_tracker_details',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/ads/live-ads/' + id,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteTracker = createAsyncThunk(
    'liveAdsSlice/ads_delete',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/ads/live-ads/' + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const updateTracker = createAsyncThunk(
    'liveAdsSlice/update_tracker',
    async ({ id, recruiter, jobBoard, company, jobRef, jobTitle, roleType, language, city, totalApplication, country, postedDate, expiredDate }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/ads/live-ads/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        recruiter, jobBoard, company, jobRef, jobTitle, roleType, language, city, totalApplication, country, postedDate, expiredDate
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateStatus = createAsyncThunk(
    'liveAdsSlice/update_status_tracker',
    async ({ id, status }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/ads/live-ads/updateStatus/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        status
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);


const todaysAdsTrackerSlice = createSlice({
    name: 'liveAds_trcaker',
    initialState: {
        dataList: [],
        detailData: [],
        isLoading: false,
        isDataLoading: false,
        isInsert: false,
        isError: false,
        isUpdate: false,
        isDelete: false,
        isSuccess: false,
        isReoder: false,
        errorMessage: '',
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isDataLoading = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
            state.isSuccess = false;
            state.isReoder = false;
            state.errorMessage = '';
            return state;
        },
        bulkDeleteState: (state) => {
            state.isDelete = true
        }
    },
    extraReducers: {
        [livelistTable.pending]: (state) => {
            state.isDataLoading = true;
        },
        [livelistTable.fulfilled]: (state, { payload }) => {
            state.dataList = payload.result;
            state.isDataLoading = false;
        },
        [livelistTable.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
            state.isDataLoading = false;
        },

        [addLiveAds.pending]: (state) => {
            state.isLoading = true;
        },
        [addLiveAds.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = true;
            // state.dataList.push(payload.result)
            state.dataList.unshift(payload.result)
            state.msg = payload.message
        },
        [addLiveAds.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message
        },
        [fethById.pending]: (state) => {
        },
        [fethById.fulfilled]: (state, { payload }) => {
            state.detailData = payload.result;
        },
        [fethById.rejected]: (state, { payload }) => {
        },
        [deleteTracker.pending]: (state) => {
        },
        [deleteTracker.fulfilled]: (state, action) => {
            state.dataList = state.dataList.filter((item) => item.id !== action.meta.arg.id);
            state.isDelete = true;
        },
        [deleteTracker.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [updateTracker.pending]: (state) => {
            state.isLoading = true;
        },
        [updateTracker.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.dataList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.dataList = finalResult
            state.msg = payload.message.msgBody
        },
        [updateTracker.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [updateStatus.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
        },
        [updateStatus.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.dataList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.dataList = finalResult
            state.msg = payload.message.msgBody
        },
        [updateStatus.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;

            state.msg = payload.message.msgBody
        },
    }
});

export const { clearState, bulkDeleteState } = todaysAdsTrackerSlice.actions;
export default todaysAdsTrackerSlice.reducer;
