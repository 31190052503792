import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'

const JobCheckDetailModal = ({ handleClose }) => {
    return (
        <>
            <Modal className="dk-jobCheckDetailModal right" show={true} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>LANGUAGES CHECK DETAILS</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                </Modal.Body>

            </Modal>
        </>
    )
}

export default JobCheckDetailModal;
