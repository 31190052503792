import React, { useState } from 'react';
import { Link } from "react-router-dom";
import OnboardingImg from "../../../../assets/images/onboarding_white.png";
import CommonTrainingImg from "../../../../assets/images/commontraining_icon.png";
import AdminImg from "../../../../assets/images/recruiter_icon.png";
import RecruiterImg from "../../../../assets/images/recruiters_white.png";
import AccountManagerImg from "../../../../assets/images/accountmanager_icon.png";
import ResourceImg from "../../../../assets/images/resume_white.png";
import OpsManagerImg from "../../../../assets/images/summary.png";
import ClientsManagementImg from "../../../../assets/images/resourcemanagement_icon.png";
import MasterboxImg from "../../../../assets/images/masterbox_icon.png";
import StatusImg from "../../../../assets/images/training-status.png";
import ScheduleImg from "../../../../assets/images/shedule_white.png";
import InformationImg from "../../../../assets/images/information_white.png";
import SummaryDetailModal from "./SummaryDetailModal";
import './summary.scss';

const TrainingSummary = () => {
    const [showModalDetail, setDetailModalShow] = useState(false);
    const showDetailModal = () => {
        setDetailModalShow(true)
    }
    const hideDetailModal = () => {
        setDetailModalShow(false)
    }
    return (
        <>
            {
                showModalDetail ?
                    <SummaryDetailModal handleClose={hideDetailModal} /> : null
            }

            <div className="dk-summaryTrainingMain dk-summaryTrainingContainer">
                <div className="container-fluid">
                    <div className="dk-trainingMainCols">
                        <h3 className="title">DK GLOBAL - TRAINING OVERVIEW</h3>
                        <div className="row justify-content-start">
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#40a6d0' }} onClick={showDetailModal}>
                                    <Link to="#">
                                        <figure>
                                            <img src={InformationImg} className="img-fluid" alt="" />
                                        </figure>
                                        <h3>Overview</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#2e75b6' }} onClick={showDetailModal}>
                                    <Link to="#">
                                        <figure>
                                            <img src={ScheduleImg} className="img-fluid" alt="" />
                                        </figure>
                                        <h3>Schedule</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#37617d' }} onClick={showDetailModal}>
                                    <Link to="#">
                                        <figure>
                                            <img src={OnboardingImg} className="img-fluid" alt="" />
                                        </figure>
                                        <h3>Onboarding</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5d5c60' }} onClick={showDetailModal}>
                                    <Link to="#">
                                        <figure>
                                            <img src={CommonTrainingImg} />
                                        </figure>
                                        <h3>Common Training</h3>
                                    </Link>
                                </div>
                            </div>

                            {/* <div className="col-md-3">
                                <div className="os-box" style={{ background: '#3385a5' }} onClick={showDetailModal}>
                                    <Link to="#">
                                        <figure>
                                            <img src={MasterboxImg} />
                                        </figure>
                                        <h3>Assessment</h3>
                                    </Link>
                                </div>
                            </div> */}

                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86' }} onClick={showDetailModal}>
                                    <Link to="#">
                                        <figure>
                                            <img src={ResourceImg} />
                                        </figure>
                                        <h3>Team 1 - Resource</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#57496d' }} onClick={showDetailModal}>
                                    <Link to="#">
                                        <figure>
                                            <img src={AdminImg} />
                                        </figure>
                                        <h3>Team 2 - Admins</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b' }} onClick={showDetailModal}>
                                    <Link to="#">
                                        <figure>
                                            <img src={RecruiterImg} />
                                        </figure>
                                        <h3>Team 3 - Recruiters</h3>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#296867' }} onClick={showDetailModal}>
                                    <Link to="#">
                                        <figure>
                                            <img src={AccountManagerImg} />
                                        </figure>
                                        <h3>Team 4 - Account M</h3>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#9D265C' }} onClick={showDetailModal}>
                                    <Link to="#">
                                        <figure>
                                            <img src={ClientsManagementImg} />
                                        </figure>
                                        <h3>Management</h3>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#344050' }} onClick={showDetailModal}>
                                    <Link to="#">
                                        <figure>
                                            <img src={MasterboxImg} />
                                        </figure>
                                        <h3>Assessments</h3>
                                    </Link>
                                </div>
                            </div>
                            {/* <div className="col-md-3">
                                <div className="os-box" style={{ background: '#1d463b' }} onClick={showDetailModal}>
                                    <Link to="#">
                                        <figure>
                                            <img src={BranchManagerImg} />
                                        </figure>
                                        <h3>Branch Manager</h3>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#483252' }} onClick={showDetailModal}>
                                    <Link to="#">
                                        <figure>
                                            <img src={MarketingManagerImg} />
                                        </figure>
                                        <h3>Marketing Manager</h3>
                                    </Link>
                                </div>
                            </div> */}

                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#4c5f59' }} onClick={showDetailModal}>
                                    <Link to="#">
                                        <figure>
                                            <img src={StatusImg} />
                                        </figure>
                                        <h3>Status</h3>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#E2445B' }} onClick={showDetailModal}>
                                    <Link to="#">
                                        <figure>
                                            <img src={OpsManagerImg} />
                                        </figure>
                                        <h3>Report</h3>
                                    </Link>
                                </div>
                            </div>

                            {/* <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5d5c60' }} onClick={showDetailModal}>
                                    <Link to="#">
                                        <figure>
                                            <img src={GetstartedImg} />
                                        </figure>
                                        <h3>Get Started</h3>
                                    </Link>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrainingSummary;
