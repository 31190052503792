import React from 'react';
import DkStaffLeftPanel from './leftpanel/DkStaffLeftPanel';
import "../financecustom.scss";

const FinanceDkStaffOverview = () => {
    return (
        <div className="dk-moduledkFinanceProcess-main">
            <div className="dk-moduledkFinanceProcess-cols">
                <DkStaffLeftPanel />
                <div className="dk-moduledkFinanceProcess-rightPanel">
                    <div className="title">Overview</div>
                    <div className="dk-moduledkFinanceProcess-rightCols">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinanceDkStaffOverview;
