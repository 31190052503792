import React, { useState, useEffect } from "react"
import Dropdown from 'react-bootstrap/Dropdown';
const UserList = ({ list, onClick }) => {
    const [userList, setUserList] = useState([]);
    useEffect(() => {
        const result = [];
        const map = new Map();
        for (const item of list) {
            if (!map.has(item.original.userInfo.id)) {
                map.set(item.original.userInfo.id, true);
                result.push({
                    id: item.original.userInfo.id,
                    name: item.original.userInfo.firstName,
                    userImg: item.original.userInfo.profileImage,
                    uid: item.original.userInfo.uid,
                });
            }
        }
        setUserList(result)
    }, [list])

    return (
        <React.Fragment>
            {userList.map((list, index) => (
                <Dropdown.Item href="#" key={index + 1}>
                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + list.userImg} className="img-fluid img-circle" alt={list.userImg} title={list.name} id={list.uid} onClick={onClick} />
                </Dropdown.Item>
            ))}
        </React.Fragment>
    )
}
export default UserList;