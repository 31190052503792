import React, { useState, useCallback, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import SearchDropDownFilter from "../../../ui/SelectPicker/searchTypeSelect";
import { fetchLastClientId, jobsType, jobsIndustry, jobsBusiness, companyTaxNo, companyVatNo, jobsCountry, jobsCity, addNewClient, checkCompany, clearState } from '../../../../slice/companies/clients/clientSlice';
import { showSuccess, showError } from "../../../../slice/utils/message/messageSlice";
import { clearLoading } from "../../../../slice/utils/loader/linearSlice";
import AWS from 'aws-sdk'
import 'react-image-crop/dist/ReactCrop.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import "./compainesadnew.scss";
import Submit from '../../../ui/submitButton';
import LogoViewImg from "../../../../assets/images/dk-global-jobs-logo.png";
//import NativeDatePicker from '../../../ui/nativedatepicker/DatePicker'

const AddNew = () => {
    const dispatch = useDispatch();
    const { jobsTypeList, jobsBusinessList, jobsIndustryList, jobsCityList, jobsCountryList, isInsert, message, isError, clientsid, isDuplicate } = useSelector(state => state.companies)

    const { jobsCompanyList } = useSelector(state => state.common);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    // const [userImg, setUserImg] = useState('');
    // const [files, selectFiles] = useFileUpload();

    const [businessName, setBusinessName] = useState('');
    const [foundOn, setFoundOn] = useState('');
    const [type, setType] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [incomeTax, setIncomeTax] = useState('');
    const [vatNo, setVatNo] = useState('');
    const [address, setAddress] = useState('');
    const [phoneNo1, setPhoneNo1] = useState('');
    const [phoneNo2, setPhoneNo2] = useState('');
    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const [website1, setWebsite1] = useState('');
    const [website2, setWebsite2] = useState('');
    const [cvURL, setCvURL] = useState('');
    const [defaultPreview, setDefaultPreview] = useState(<h3>browse Image</h3>);
    const [businessItemId, setBusiness] = useState('')
    const [industryItemId, setIndustry] = useState('')

    // const [industryPlaceholder, setIndustryPlaceholder] = useState('')
    // const [businessPlaceholder, setBusinessPlaceholder] = useState('')

    // const [taxNoPlaceholder, setTaxNoPlaceholder] = useState('')
    // const [vatNoPlaceholder, setVatPlaceholder] = useState('')

    const getTypeValue = (data) => {
        setType(data)
    }
    const getCompanyValue = (data) => {
        setBusinessName(data)
    }
    const getCityValue = (data) => {
        setCity(data)
    }
    const getCountryValue = (data) => {
        setCountry(data)
    }

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {

            case "foundOn":
                setFoundOn(value)
                break;
            case "collaboration":
                setCollaboration(value)
                break;
            case "renewal":
                setRenewal(value)
                break;
            case "address":
                setAddress(value)
                break;
            case "phoneNo1":
                setPhoneNo1(value)
                break;
            case "phoneNo2":
                setPhoneNo2(value)
                break;
            case "email1":
                setEmail1(value)
                break;
            case "email2":
                setEmail2(value)
                break;
            case "website1":
                setWebsite1(value)
                break;
            case "website2":
                setWebsite2(value)
                break;
            case "cvURL":
                setCvURL(value)
                break;
            case "vatNo":
                setVatNo(value)
                break;
            case "incomeTax":
                setIncomeTax(value)
                break;
            default:
                break;
        }
    }

    const handleButtonClick = async (e) => {
        e.preventDefault();
        //checkCompany
        // dispatch(checkCompany({ name: businessName }))
        // if(iscompany==true){

        if (!type || !businessName) {
            dispatch(clearState())
            dispatch(showError({ msg: 'Fields are required.' }))
        } else {
            if (!loading) {
                setSuccess(false);
                setLoading(true);

                if (selectedFile != null) {
                    let s3bucket = new AWS.S3({
                        accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
                        secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
                        Bucket: process.env.REACT_APP_BUCKET_NAME + '/clients'
                    });
                    s3bucket.createBucket(function () {
                        var params = {
                            Bucket: process.env.REACT_APP_BUCKET_NAME + '/clients',
                            Key: Date.now() + '-' + (selectedFile.name).replace(/ /g, "_"),
                            Body: selectedFile
                        };
                        s3bucket.upload(params, function (err, data) {
                            if (err) {
                                console.log(err)
                            } else {
                                dispatch(addNewClient({ name: businessName, logo: "/" + data.key, foundedOn: foundOn, city, country, industry: industryItemId, business: businessItemId, type, incomeTax, vatNo, address, collaboration, renewal, phone1: phoneNo1, phone2: phoneNo2, email1, email2, website1, website2, cvURL }))
                            }
                        });
                    });

                } else {
                    setSelectedFile(null)
                    dispatch(addNewClient({ name: businessName, type, foundedOn: foundOn, city, country, industry: industryItemId, business: businessItemId, incomeTax, vatNo, address, collaboration, renewal, phone1: phoneNo1, phone2: phoneNo2, email1, email2, website1, website2, cvURL }))
                }

            }
        }

        // }else{
        //             dispatch(clearState())
        //             dispatch(showError({ msg: 'Company already added' }))
        // }

    }

    //image crop start
    const [isImageUpdate, setIsImageUpdate] = useState(false)
    const [newImageData, setNewImageData] = useState()
    const [profileImage, setProfileImage] = useState('')
    const [iscompany, setIscompany] = useState(false)
    const [file, setFile] = useState('');
    const [upImg, setUpImg] = useState();

    useEffect(() => {
        dispatch(clearState())
        dispatch(fetchLastClientId())
        dispatch(jobsCity())
        dispatch(jobsCountry())
        dispatch(jobsIndustry())
        dispatch(jobsBusiness())
        dispatch(companyTaxNo())
        dispatch(companyVatNo())
        dispatch(jobsType())
    }, [])

    useEffect(() => {
        if (isError) {
            dispatch(clearState())
            dispatch(showError({ msg: message }))
            dispatch(clearLoading())
            setLoading(false);
        }
        if (isDuplicate) {
            setIscompany(true)
            dispatch(clearState())
            dispatch(showSuccess({ msg: message }))
            dispatch(clearLoading())
            setLoading(false);
        } else {
            setIscompany(false)
            setLoading(false);
            dispatch(clearState())
            dispatch(showSuccess({ msg: message }))
            dispatch(clearLoading())
        }
        if (isInsert) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: message }))
            dispatch(fetchLastClientId())
            setSelectedFile(null)
            setBusinessName('')
            setType('')
            setIncomeTax('')
            setVatNo('')
            setCity('')
            setCountry('')
            setAddress('')
            setPhoneNo1('')
            setPhoneNo2('')
            setEmail1('')
            setEmail2('')
            setWebsite1('')
            setWebsite2('')
            setDefaultPreview(<h3>browse Image</h3>)
            setCvURL('')
            setIndustry('')
            setBusiness('')
            setRenewal('')
            setCollaboration('')
            setFoundOn('')
            dispatch(clearLoading())
            setLoading(false);

        }
    }, [isError, isDuplicate, isInsert])

    const [selectedFile, setSelectedFile] = useState(null);

    const defaultImage = () => {
        setSelectedFile(null)
        setNewImageData('')
    }

    const onSelectFile = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
            var fileToLoad = e.target.files[0];
            var fileReader = new FileReader();
            fileReader.onload = function (fileLoadedEvent) {
                var srcData = fileLoadedEvent.target.result; // <--- data: base64
                var newImage = document.createElement('img');
                newImage.src = srcData;
                //console.log(newImage.outerHTML)               
                setIsImageUpdate(true)
                setNewImageData(newImage.src)
            }
            fileReader.readAsDataURL(fileToLoad);
        }
    }


    // const handleChangeSelect = (event, data) => {
    //     const { name, value } = data
    //     switch (name) {
    //         case "industry":
    //             setIndustry(value)
    //             break;
    //         case "business":
    //             setBusiness(value)
    //             break;
    //         case "taxNo":
    //             setIncomeTax(value)
    //             break;
    //         case "vatNo":
    //             setVatNo(value)
    //             break;
    //     }
    // }

    //const [collaboration, setCollaboration] = useState(new Date());
    const [collaboration, setCollaboration] = useState('');
    const [renewal, setRenewal] = useState('');

    const handleDateChangeCollaboration = (date) => {
        setCollaboration(date);
    };
    const handleDateChangeRenewal = (date) => {
        setRenewal(date);
    };
    const getIndustryValue = (data) => {
        setIndustry(data)
    }

    const getBusinessValue = (data) => {
        setBusiness(data)
    }

    return (
        <React.Fragment>
            <div className="container-fluid dk-cliAdNewPageCont pl-3 pr-3">
                <div className="row">
                    <div className="col-xl-8 col-md-8 col-sm-12 pl-4 pr-4">
                        <div className="dk-clntsPageTitle text-center">
                            <h2>ADD NEW CLIENT</h2>
                        </div>
                        <div className="dk-addNClientFormCont">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-longllabel">Name of Client</label>
                                        <SearchDropDownFilter text={businessName} list={jobsCompanyList} updateValue={getCompanyValue} />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-smalllabel">Client ID</label>
                                        <input type="text" className="form-control" placeholder="" name="clientID" value={clientsid} readOnly style={{ cursor: 'not-allowed' }} />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-longllabel">Geography</label>
                                        <input type="text" className="form-control" name="collaboration" onChange={handleChange} value={collaboration} />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-smalllabel">Category</label>
                                        <SearchDropDownFilter text={type} list={jobsTypeList} updateValue={getTypeValue} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-longllabel">Country</label>
                                        <SearchDropDownFilter text={country} list={jobsCountryList} updateValue={getCountryValue} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group dk-Candinate-fromGroup d-flex w-100">
                                        <label className="dk-smalllabel">City/Town</label>
                                        <SearchDropDownFilter text={city} list={jobsCityList} updateValue={getCityValue} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 col-sm-12'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="form-group dk-Candinate-fromGroup d-flex">
                                                <label className="dk-longllabel">Industry</label>
                                                <SearchDropDownFilter text={industryItemId} list={jobsIndustryList} updateValue={getIndustryValue} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group dk-Candinate-fromGroup d-flex">
                                                <label className="dk-longllabel" style={{ minWidth: '100px' }}>Business</label>
                                                <SearchDropDownFilter text={businessItemId} list={jobsBusinessList} updateValue={getBusinessValue} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group dk-Candinate-fromGroup d-flex">
                                                <label className="dk-longllabel">Contract</label>
                                                <input type="text" className="form-control" placeholder="" name="incomeTax" onChange={handleChange} value={incomeTax} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group dk-Candinate-fromGroup d-flex">
                                                <label className="dk-longllabel" style={{ minWidth: '100px' }}>Renewal</label>
                                                <input type="text" className="form-control" name="renewal" onChange={handleChange} value={renewal} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="row">
                                        {/* <div className='col-md-6'>
                                            <div className="form-group dk-Candinate-fromGroup d-flex">
                                                <label className="dk-longllabel">Founded On</label>
                                                <input type="text" className="form-control" placeholder="" name="foundOn" onChange={handleChange} value={foundOn} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>

                                        </div> */}
                                        <div className="col-md-6">
                                            {/* <div className="form-group dk-Candinate-fromGroup d-flex">
                                                <label className="dk-longllabel">Phone No</label>
                                                <input type="text" className="form-control" placeholder="" name="phoneNo1" onChange={handleChange} value={phoneNo1} />
                                            </div> */}
                                            <div className="form-group dk-Candinate-fromGroup d-flex">
                                                <label className="dk-longllabel">VAT No.</label>
                                                <input type="text" className="form-control" placeholder="" name="vatNo" onChange={handleChange} value={vatNo} />

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group dk-Candinate-fromGroup d-flex">
                                                <label className="dk-smalllabel">Head Office</label>
                                                <input type="text" className="form-control" placeholder="" name="phoneNo2" onChange={handleChange} value={phoneNo2} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group dk-Candinate-fromGroup d-flex">
                                                <label className="dk-longllabel">Founded On</label>
                                                <input type="text" className="form-control" placeholder="" name="foundOn" onChange={handleChange} value={foundOn} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dk-Candinate-fromGroup d-flex ">
                                        <label className="dk-longllabel">Address</label>
                                        <input type="text" className="form-control" placeholder="" name="address" onChange={handleChange} value={address} />
                                        <span className="dk-clViewAddonIcon d">
                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-longllabel">Website 1</label>
                                        <input type="text" className="form-control" placeholder="" name="website1" onChange={handleChange} value={website1} />
                                        <span className="dk-clViewAddonIcon d">
                                            <i className="fa fa-globe" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-longllabel">Website 2</label>
                                        <input type="text" className="form-control" placeholder="" name="website2" onChange={handleChange} value={website2} />
                                        <span className="dk-clViewAddonIcon d">
                                            <i className="fa fa-globe" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <div className="form-group dk-Candinate-fromGroup d-flex">
                                        <label className="dk-longllabel">CV Upload Link</label>
                                        <input type="text" className="form-control" placeholder="" name="cvURL" onChange={handleChange} value={cvURL} />
                                        <span className="dk-clViewAddonIcon d">
                                            <i className="fa fa-globe" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <div className="form-group dk-Candinate-fromGroup d-flex mb-0 mt-3">
                                        <label htmlFor="" className="text-center w-100 d-flex justify-content-center">
                                            <Submit txt="Create" loading={loading} success={success} onClick={handleButtonClick} className="dk-taskCreateBtn" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-12 pl-4 pr-4">
                        <div className="dk-clntsPageTitle text-center">
                            <h2>ADD CLIENT LOGO</h2>
                        </div>
                        <div className="dkaddNewRightCon">
                            <div className="addNewBox">
                                <div className="dk-FileUploadCon">
                                    {
                                        (selectedFile === null) ?
                                            <>
                                                <div className="dk-fileUldimgCon">
                                                    <figure id="edit_image">
                                                        {defaultPreview}
                                                    </figure>
                                                </div>
                                                <div className="dk-browsedFile">
                                                    <button onChange={onSelectFile}>
                                                        <input type="file" id="" name="" accept="image/*" />
                                                        <i className="fas fa-upload mr-2"></i>  Upload
                                                    </button>
                                                </div>
                                            </>
                                            :
                                            <div className="dk-uploadView-img">
                                                {
                                                    (isImageUpdate) ?

                                                        <img src={newImageData} className="img-fluid" />
                                                        :

                                                        <img src={LogoViewImg} className="img-fluid" />
                                                }
                                                <span className="close-btn" onClick={defaultImage}>
                                                    <FontAwesomeIcon icon={faXmark} />
                                                </span>
                                            </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddNew;
