import React, { useState, useEffect } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import DatePickerCols from "../../../../ui/datepicker";
//import UserList from "../../../ui/userList";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { updateMailshots, listTable, clearState } from "../../../../../slice/mailshots/mailshotsSlice";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import { loadingStart, loadingEnd, clearLoading } from "../../../../../slice/utils/loader/linearSlice";

const EditModal = ({ id, hidePopup, showeditpopup }) => {
    const dispatch = useDispatch();
    const { isUpdate, isError, msg, singleMailList } = useSelector(state => state.mailshots)
    const { companyId } = useSelector(state => state.auth.userInfo)

    const [show, setShow] = useState(false);
    const [userId, setUserId] = useState(singleMailList.userInfo.id)
    // const handleClose = () => {
    //     setShow(false);
    //     setUserId('0')
    // }

    //const handleShow = () => setShow(true);

    const [userImg, setUserImg] = useState(singleMailList.userInfo.profileImage)
    const [userImgName, setUserImgName] = useState(singleMailList.userInfo.firstName)

    const [joinOn, setJoinOn] = useState(new Date())
    const [joinTime, setTime] = useState(singleMailList.joinTime)
    const [totalCandidate, setTotalCandidate] = useState(singleMailList.totalCandidate)
    const [mailshotVia, setMailshotVia] = useState(singleMailList.mailshotVia)
    const [projectTitle, setProjectTitle] = useState(singleMailList.projectTitle)
    const [category, setCategory] = useState(singleMailList.category)
    const [company, setCompany] = useState(singleMailList.company)
    const [project, setProject] = useState(singleMailList.project)
    const [language, setLanguage] = useState(singleMailList.language)
    const [roleType, setRoleType] = useState(singleMailList.roleType)
    const [formJoinOn, setFormJoinOn] = useState(moment(new Date()).format("DD MMM YYYY"))

    const selectDate = (date) => {
        setJoinOn(date)
        setFormJoinOn(moment(new Date(date)).format("DD MMM YYYY"));
    }

    const saveMailshots = () => {
        let joinDate = formJoinOn;

        if (!joinTime || userId == '0' || !totalCandidate || !mailshotVia || !projectTitle || !category || !company || !project || !language || !roleType) {
            dispatch(showError({ msg: 'All fields required' }))
        } else {
            dispatch(loadingStart())
            dispatch(updateMailshots({ userId, joinDate, joinTime, totalCandidate, mailshotVia, projectTitle, category, company, project, language, roleType, mailshotId: id }))
        }
    }

    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: msg }))
            setShow(false);
            dispatch(clearState())
            dispatch(clearLoading())
        }
        if (isUpdate) {
            setShow(false);
            dispatch(showSuccess({ msg: msg }))
            dispatch(clearState())
            dispatch(clearLoading())
        }
    }, [isError, isUpdate])
    return (
        <React.Fragment>
            <Modal className="dk-levelModal dk-addNewModalMailshots" show={showeditpopup} onHide={hidePopup}>
                <Modal.Header closeButton>
                    <Modal.Title>EDIT MAILSHOT</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-AddnewModal">
                    <div className="row">
                        <input type="hidden" id="insertId" value="0" />
                        <div className="col-md-6 pl-0">
                            <div className="form-group">
                                <label for="">Date</label>
                                <label for="" className="dateIcon">
                                    <DatePickerCols value={joinOn} onChange={selectDate} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6 pl-0">
                            <div className="form-group mb-2">
                                <label for="">Time</label>
                                <label for="">
                                    <input type="text" className="form-control" onChange={(e) => setTime(e.target.value)} value={joinTime} />
                                </label>
                            </div>
                        </div>

                        <div className="col-md-6 pl-0">
                            <div className="form-group mb-2">
                                <label for="">No. of Candidates</label>
                                <label for="">
                                    <input type="text" className="form-control" onChange={(e) => setTotalCandidate(e.target.value)} value={totalCandidate} />
                                </label>
                            </div>
                        </div>

                        <div className="col-md-6 pl-0">
                            <div className="form-group dk-dkgStatus">
                                <label for="">Send Mailshot Via</label>
                                <label for="">
                                    <select className="form-control" onChange={(e) => setMailshotVia(e.target.value)}>
                                        <option value="Mailchimp">Mailchimp</option>
                                        <option value="Outlook">Outlook</option>
                                    </select>
                                </label>
                            </div>
                        </div>

                        <div className="col-md-12 pl-0">
                            <div className="form-group">
                                <label for="">Project Title</label>
                                <label for="" >
                                    <input type="text" className="form-control location" onChange={(e) => setProjectTitle(e.target.value)} value={projectTitle} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6 pl-0">
                            <div className="form-group dk-dkgStatus">
                                <label for="">Category</label>
                                <label for="">
                                    <select className="form-control" onChange={(e) => setCategory(e.target.value)}>
                                        <option value="IT">IT</option>
                                        <option value="ML">ML</option>
                                    </select>
                                </label>
                            </div>
                        </div>

                        <div className="col-md-6 pl-0">
                            <div className="form-group">
                                <label for="">Company</label>
                                <label for="">
                                    <input type="text" className="form-control" onChange={(e) => setCompany(e.target.value)} value={company} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6 pl-0">
                            <div className="form-group mb-2">
                                <label for="">Project</label>
                                <label for="">
                                    <input type="text" className="form-control" onChange={(e) => setProject(e.target.value)} value={project} />
                                </label>
                            </div>
                        </div>

                        <div className="col-md-6 pl-0">
                            <div className="form-group">
                                <label for="">Language</label>
                                <label for="" >
                                    <input type="text" className="form-control location" onChange={(e) => setLanguage(e.target.value)} value={language} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6 pl-0">
                            <div className="form-group">
                                <label for="">Role Type</label>
                                <label for="" >
                                    <input type="text" className="form-control location" onChange={(e) => setRoleType(e.target.value)} value={roleType} />
                                </label>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group dk-projectrecruiter text-left">
                                <label for=""></label>
                                <input type="hidden" id="userId" value={singleMailList.userId.id} />
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="mt-1">
                                        {
                                            (userId !== '0') ? <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + userImg} className="select-user-img" title={userImgName} /> : <i className="fas fa-user"></i>
                                        }
                                    </Dropdown.Toggle>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="col-md-12 text-center">
                            <button className="dk-submitBtn" onClick={saveMailshots}>SAVE</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default EditModal;