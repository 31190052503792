import React from "react";
import { Route, Link } from "react-router-dom";

const DKGlobalSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/db-table/dkglobal/deepak" className={`${(segment3 == 'deepak') ? 'active' : ''}`}>Deepak</Link></li>
                        <li><Link to="#/db-table/dkglobal/luiza" className={`${(segment3 == 'luiza') ? 'active' : ''}`}>Luiza</Link></li>
                        <li><Link to="#/db-table/dkglobal/mariana" className={`${(segment3 == 'mariana') ? 'active' : ''}`}>Mariana</Link></li>
                        <li><Link to="#/db-table/dkglobal/filip" className={`${(segment3 == 'filip') ? 'active' : ''}`}>Filip</Link></li>
                        <li><Link to="#/db-table/dkglobal/elis" className={`${(segment3 == 'elis') ? 'active' : ''}`}>Elis</Link></li>
                        <li><Link to="#/db-table/dkglobal/tehreem" className={`${(segment3 == 'tehreem') ? 'active' : ''}`}>Tehreem</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default DKGlobalSubmenu;
