import React, { useState, useEffect } from 'react';
import { Route, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Modal, Tab, Nav } from 'react-bootstrap';
import CustomColorPicker from './ColorPicker';
import { listColors, updateData, bulkdelete, clearState } from "../../../../slice/jobProfile/jobProfileSlice";
import { showError, showSuccess } from "../../../../slice/utils/message/messageSlice";
import { Item } from 'semantic-ui-react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import $ from "jquery";
import DeleteModal from '../../../ui/delete';
import ReorderModal from './ReorderModal'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    tblDdlActive: {
        backgroundColor: '#31a886 !important',
        color: '#ffffff !important',
        minWidth: 90
    },
    tblDdlInActive: {
        backgroundColor: '#d8345f !important',
        color: '#ffffff !important',
        minWidth: 90
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: 'green',
    },
}));

const EditJobProfileModal = ({ handleClose, hidePopup, showeditpopup, profileUserId, boxId }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const classes = useStyles();
    const { dataList, isdataUpdate, isDelete, isError, msg } = useSelector(state => state.jobProfile);
    const [rowId, setRowId] = useState('0')
    const [isshow, setIsShow] = useState(false);

    useEffect(() => {
        async function fetchColors() {
            try {
                await dispatch(listColors({}))
            } catch (err) {
                dispatch(showError({ msg: err }))
            }
        }
        fetchColors()
    }, [])

    useEffect(() => {
        if (isdataUpdate) {
            dispatch(showSuccess({ msg: msg }))
            dispatch(clearState())
        }
        if (isDelete) {
            setIsDeleteBulkPop(false)
            dispatch(clearState())
        }
        if (isError) {
            dispatch(showError({ msg: msg }))
            dispatch(clearState())
        }
    }, [isdataUpdate, isError, isDelete])

    const showDeleteModal = async (id) => {
        setRowId(id)
        setIsDeletePop(true)
    }

    const onClick = (e) => {
        e.preventDefault()
        dispatch(updateData({ id: e.target.dataset.rowid, key: 'bgColor', value: e.target.dataset.color }))
    }

    const updateHeading = (e) => {
        e.preventDefault();
        dispatch(updateData({ id: e.target.id, key: "name", value: e.target.value }))
    }
    const [isActive, setIsActive] = useState(false);

    const [editPopup, setEditPopup] = useState(true);
    const [deletePopup, setDeletePopup] = useState(false);
    const [reorderPopup, setReorderPopup] = useState(false);

    const [countCheck, setCountCheck] = useState(0)
    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')

    const [bulkLoad, setBulkLoad] = useState(false)
    const [isDeleteBulkPop, setIsDeleteBulkPop] = useState(false);
    const [isDeletePop, setIsDeletePop] = useState(false);

    const deleteSelected = () => {
        setIsDeleteBulkPop(true)
    }

    /** Single Checkbox Select */
    const toggleCheckbox = (e, id) => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount > 0) {
            setCountCheck(checkedBoxCount)
            //setIsActive(true)
        } else {
            setCountCheck(0)
            // setIsActive(false)
        }
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Check All')
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
        }
    }
    /** End Single Checkbox Select */
    const hideDeleteModal = () => {
        setIsDeletePop(false)
    }

    const deleteItemBulk = async () => {
        setBulkLoad(true)
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
        } else {
            var canIds = [];
            $.each($(".chkCandidate:checked"), function () {
                canIds.push($(this).val());
            });
            await dispatch(bulkdelete({ 'ids': canIds }))
        }
    }

    const closeDeleteBulk = () => {
        setIsDeleteBulkPop(false)
    }
    const deletefun = () => {
        setEditPopup(false)
        setDeletePopup(true)
        setReorderPopup(false)
    }

    return (
        <React.Fragment>
            {
                (isDeleteBulkPop) ? <DeleteModal id={rowId} onHide={hideDeleteModal} deleteItem={deleteItemBulk} handleClose={closeDeleteBulk} /> : null
            }
            <Modal className="dk-editJobProfile" show={showeditpopup} onHide={hidePopup}>
                <Modal.Body>
                    <div className="dk-editJobProfile-container">
                        <div className="dk-editJobProfile-head">
                            <div className="d-flex align-items-center justify-content-between">
                                {/* <Link to="#" className="active">Edit</Link>
                                <Link onclick={deletefun}>Delete</Link>
                                <div className="title">Departments</div>
                                <Link to="#">Reorder</Link>
                                <Modal.Header closeButton></Modal.Header> */}
                                <Tab.Container id="left-tabs-example" defaultActiveKey="edit">
                                    <div className='row dkg-jobprofle-tabsCon'>
                                        <div className='col-md-12 p-0'>
                                            <Nav variant="pills" className="flex-row dkg-jobprofle-pills">
                                                <Nav.Item className='dkg-jobprofle-navitem'>
                                                    <Nav.Link eventKey="edit">Edit</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='dkg-jobprofle-navitem'>
                                                    <Nav.Link eventKey="delete">Delete</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='dkg-jobprofle-navitem'>
                                                    <Nav.Link>Departments</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='dkg-jobprofle-navitem'>
                                                    <Nav.Link eventKey="reorder">Reorder</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='dkg-jobprofle-navitem dgdg'>
                                                    <Nav.Link>
                                                        <Modal.Header className='ml-4' closeButton></Modal.Header>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <Tab.Content className='dkg-jobprofle-tabcontent'>
                                                <Tab.Pane eventKey="edit" className='dkg-jobprofle-tabpane'>
                                                    {
                                                        editPopup ?

                                                            <div className="dk-editJobProfile-editSec" >
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered">
                                                                        <tbody>
                                                                            {
                                                                                dataList.filter(items => items.boxId == boxId).map((item, index) => (
                                                                                    <tr key={index + 1}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>
                                                                                            <div className="dk-editSec-input">
                                                                                                <input type="text" value={item.name} name="" id={item.id} style={{ background: item.bgColor, color: item.txtColor }} className="form-control" onChange={updateHeading} />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            {/* <div className="dk-editSec-colorBox">
                                                                                                <span></span>
                                                                                            </div> */}
                                                                                            <Dropdown className="dk-jobprofile-colorDropdown">
                                                                                                <Dropdown.Toggle variant="" className="dk-jpcolorDropdown-toggle" style={{ background: item.bgColor }}>
                                                                                                </Dropdown.Toggle>
                                                                                                <Dropdown.Menu className="dk-jpcolorDropdown-menu">
                                                                                                    <CustomColorPicker rowid={item.id} onClick={onClick} />
                                                                                                </Dropdown.Menu>
                                                                                            </Dropdown>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                            }

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }

                                                </Tab.Pane>
                                                <Tab.Pane eventKey="delete" className='dkg-jobprofle-tabpane'>
                                                    <div className="dk-editJobProfile-deleteSec dk-editJobProfile-editSec">
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered">
                                                                <tbody>
                                                                    {
                                                                        dataList.filter(items => items.boxId == boxId).map((item, index) => (
                                                                            <tr>
                                                                                <td>
                                                                                    <input type="checkbox" className="chkCandidate" name="chkCandidate[]" value={item.id} onChange={(e) => toggleCheckbox(e, item.id)} />
                                                                                </td>
                                                                                <td>
                                                                                    <div className="dk-editSec-input text-center">
                                                                                        {item.name}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            <div className="dk-deleteSec-saveBtn mt-3">
                                                                <button onClick={deleteSelected}>Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="reorder" className='dkg-jobprofle-tabpane'>
                                                    <div className="dk-editJobProfile-reOrder" >
                                                        <ReorderModal rowid={profileUserId} boxNo={boxId} />
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </div>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment >
    );
}

export default EditJobProfileModal;