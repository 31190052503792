import React, { useState } from 'react';
import '../summary/summary.scss';
import UpdatePop from "../../../dashboard/clientskpi/UpdatePop";
import { FaInfoCircle } from 'react-icons/fa';

const Information = () => {
    const [isUpdatePop, setIsUpdatePop] = useState(false)
    const openUpdateValuePop = () => {
        setIsUpdatePop(true)
    }
    const hideUpdateValuePop = () => {
        setIsUpdatePop(false)
    }
    return (
        <>
            {
                (isUpdatePop) ? <UpdatePop onHide={hideUpdateValuePop} /> : null
            }
            <div className="dk-rcWalletSummary-main dk-rcInformationMain">
                <div className="dk-rcWalet-informationMain">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="dk-reWallet-summaryBox">
                                <div className="title">SENDOUTS BONUS
                                    <span className="dk-infoHover"><FaInfoCircle />
                                        <div className="dk-tooltip">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloribus maxime sapiente consequatur eligendi neque, corrupti. Lorem ipsum dolor.</div>
                                    </span>
                                </div>
                                <div className="dk-rcWallet-summaryCols">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Sendout Count</th>
                                                <th>@ Per Rate</th>
                                                <th>TOTAL BONUS</th>
                                                <th>CONDITION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">5 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">10 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">15 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">20 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">25 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">30 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">35 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">40 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">45 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">50 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="dk-reWallet-summaryBox">
                                <div className="title">PLACEMENTS BONUS
                                    <span className="dk-infoHover"><FaInfoCircle />
                                        <div className="dk-tooltip">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloribus maxime sapiente consequatur eligendi neque, corrupti. Lorem ipsum dolor.</div>
                                    </span>
                                </div>
                                <div className="dk-rcWallet-summaryCols">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>PLACEMENT COUNT</th>
                                                <th>@ Per Rate</th>
                                                <th>TOTAL BONUS</th>
                                                <th>CONDITION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">5 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">10 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>

                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">15 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>

                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">20 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>

                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">25 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>

                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">30 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>

                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">35 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>

                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">40 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>

                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">45 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>

                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">50 <span>€</span></td>
                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">130 <span>€</span></td>

                                                <td onClick={openUpdateValuePop} className="dk-cursor-p">Test</td>                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Information;
