import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import {
  getCMS,
  getCookiesNotification,
  updateCookies,
  clearState,
} from "../../../../../slice/cmsSlice";
import {
  showSuccess,
  showError,
} from "../../../../utils/messages/messageSlice";
import { useDispatch, useSelector } from "react-redux";

const MyaccCookies = () => {
  const dispatch = useDispatch();
  const { isSuccess, isCookiesN, cmsList, cookieNotification, isOperation } =
    useSelector((state) => state.cmsdata);
  const { userInfo } = useSelector((state) => state.auth);
  const [checked, setChecked] = React.useState("");
  const [details, setDetails] = useState("");
  const [cookies, setCookiesNotification] = useState("");

  const [cookiesPerformance, setCookiePerformance] = React.useState("");
  const [performanceClass, setPerformanceClass] = useState("");

  const [cookiesMarketing, setCookieMarketing] = React.useState("");
  const [marketingClass, setMarketingClass] = useState("");

  useEffect(() => {
    dispatch(getCMS({}));
    dispatch(getCookiesNotification({ canId: userInfo.canId }));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setDetails(cmsList[6]);
    }
    if (!isCookiesN && cookieNotification) {
      setChecked(cookieNotification.co === 1 ? true : false);
      setCookiesNotification(cookieNotification.co === 1 ? 1 : 0);

      setCookiePerformance(cookieNotification.cp === 1 ? true : false);
      setPerformanceClass(cookieNotification.cp === 1 ? 1 : 0);

      setCookieMarketing(cookieNotification.cp === 1 ? true : false);
      setMarketingClass(cookieNotification.cm === 1 ? 1 : 0);
    }
  }, [isSuccess, isCookiesN]);

  useEffect(() => {
    if (isOperation) {
      dispatch(clearState());
      dispatch(
        showSuccess({
          msg: "Details are updated successfully.",
        })
      );
    }
  }, [isOperation]);

  const submitCoockiesBtn = () => {
    const t = document.querySelector('input[name="coockiesBtn"]:checked').value;
    if (t === "Active") {
      setCookiesNotification(1);
      dispatch(
        updateCookies({ id: userInfo.canId, key: "operation", value: 1 })
      );
    } else {
      setCookiesNotification(0);
      dispatch(
        updateCookies({ id: userInfo.canId, key: "operation", value: 0 })
      );
    }
  };

  const submitPerformanceBtn = () => {
    const t = document.querySelector(
      'input[name="performanceBtn"]:checked'
    ).value;
    if (t === "Active") {
      setPerformanceClass(1);
      dispatch(
        updateCookies({ id: userInfo.canId, key: "performance", value: 1 })
      );
    } else {
      setPerformanceClass(0);
      dispatch(
        updateCookies({ id: userInfo.canId, key: "performance", value: 0 })
      );
    }
  };
  const submitMarketingBtn = () => {
    const t = document.querySelector(
      'input[name="marketingBtn"]:checked'
    ).value;
    if (t === "Active") {
      setMarketingClass(1);
      dispatch(
        updateCookies({ id: userInfo.canId, key: "marketing", value: 1 })
      );
    } else {
      setMarketingClass(0);
      dispatch(
        updateCookies({ id: userInfo.canId, key: "marketing", value: 0 })
      );
    }
  };

  return (
    <>
      <div className="container-fluid dkg-myacc-noti-mainpageCon">
        <div className="row dkg-myacc-noti-mainRow">
          <div className="col-11 m-auto dkg-myacc-noti-mainCol">
            <div className="row dkg-myacc-noti-subRow">
              <div className="col-md-8 m-auto dkg-myacc-noti-rightCon">
                <div className="dkg-myacc-noti-cardbody">
                  <h5 className="dkg-myacc-noti-title"> Cookies Policy</h5>
                  <p className="mb-2 Em-tx">
                    {details != "" ? details.description : ""}
                  </p>
                  <ul className="dkg-mycookies-mainCon">
                    <li className="dkg-mycookies-li">
                      <div className="dkg-mycookies-li-left">
                        <h4 className="dkg-cookies-title">Required Cookies</h4>
                        <div className="dkg-cookies-desc">
                          These type of cookies are required for websites to
                          function properly.
                        </div>
                      </div>
                      <div className="dkg-mycookies-li-right">
                        <div className="dkg-cookies-container dkg-active-btnCon">
                          <button className="dkg-active-btn btn">Active</button>
                        </div>
                      </div>
                    </li>
                    <li className="dkg-mycookies-li">
                      <div className="dkg-mycookies-li-left">
                        <h4 className="dkg-cookies-title">
                          Cookies for the optimal operation of the site
                        </h4>
                        <div className="dkg-cookies-desc">
                          Functional cookies record information about choices
                          that users make, and they also allow site operators to
                          customize the site to the user's requiements.
                        </div>
                      </div>
                      <div className="dkg-mycookies-li-right">
                        <div className="dkg-cookies-container">
                          <div className="dkg-cookies-column dkg-is-12">
                            <div className="dkg-up-in-toggle">
                              <input
                                type="radio"
                                id={`dkg-switch-left`}
                                name="coockiesBtn"
                                value="Active"
                                defaultChecked={checked}
                                onChange={submitCoockiesBtn}
                              />
                              <label
                                for="dkg-switch-left"
                                className={`dkg-active-label ${
                                  cookies === 1 ? "activClass" : ""
                                }`}
                              >
                                Active
                              </label>
                              <input
                                type="radio"
                                id="dkg-switch-right"
                                name="coockiesBtn"
                                value="Inactive"
                                defaultChecked={checked}
                                onChange={submitCoockiesBtn}
                              />
                              <label
                                for="dkg-switch-right"
                                className={`dkg-inactive-label  ${
                                  cookies === 0 ? "inActivClass" : ""
                                }`}
                              >
                                Inactive
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="dkg-mycookies-li">
                      <div className="dkg-mycookies-li-left">
                        <h4 className="dkg-cookies-title">
                          Performance cookies
                        </h4>
                        <div className="dkg-cookies-desc">
                          These types of cookies allow internet site operators
                          to monitor visits and trafic sources,how users
                          internet page or certain sections of the website.
                        </div>
                      </div>
                      <div className="dkg-mycookies-li-right">
                        <div className="dkg-cookies-container">
                          <div className="dkg-cookies-column dkg-is-12">
                            <div className="dkg-up-in-toggle">
                              <input
                                type="radio"
                                id="dkg-performance-left"
                                name="performanceBtn"
                                value="Active"
                                defaultChecked={cookiesPerformance}
                                onChange={submitPerformanceBtn}
                              />
                              <label
                                for="dkg-performance-left"
                                className={`dkg-active-label ${
                                  performanceClass === 1 ? "activClass" : ""
                                }`}
                              >
                                Active
                              </label>
                              <input
                                type="radio"
                                id="dkg-performance-right"
                                name="performanceBtn"
                                value="Inactive"
                                defaultChecked={cookiesPerformance}
                                onChange={submitPerformanceBtn}
                              />

                              <label
                                for="dkg-performance-right"
                                className={`dkg-inactive-label  ${
                                  performanceClass === 0 ? "inActivClass" : ""
                                }`}
                              >
                                Inactive
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="dkg-mycookies-li">
                      <div className="dkg-mycookies-li-left">
                        <h4 className="dkg-cookies-title">Marketing cookies</h4>
                        <div className="dkg-cookies-desc">
                          These cookies can provide the ablity to monitor user's
                          online activites and set up user profiles that can
                          then be use for marketing purposes.
                        </div>
                      </div>
                      <div className="dkg-mycookies-li-right">
                        <div className="dkg-cookies-container">
                          <div className="dkg-cookies-column dkg-is-12">
                            <div className="dkg-up-in-toggle">
                              <input
                                type="radio"
                                id="dkg-marketing-left"
                                name="marketingBtn"
                                value="Active"
                                defaultChecked={cookiesMarketing}
                                onChange={submitMarketingBtn}
                              />
                              <label
                                for="dkg-marketing-left"
                                className={`dkg-active-label ${
                                  marketingClass === 1 ? "activClass" : ""
                                }`}
                              >
                                Active
                              </label>
                              <input
                                type="radio"
                                id="dkg-marketing-switch"
                                name="marketingBtn"
                                value="Inactive"
                                defaultChecked={cookiesMarketing}
                                onChange={submitMarketingBtn}
                              />
                              <label
                                for="dkg-marketing-switch"
                                className={`dkg-inactive-label  ${
                                  marketingClass === 0 ? "inActivClass" : ""
                                }`}
                              >
                                Inactive
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <div className="dkg-myacc-noti-cardfooter">
                                <div className="dkg-cookies-btnCon">
                                    <Link to="#" className="dkg-saved-cookies-btn btn mr-3">Save Cookies</Link>
                                    <Link to="#" className="dkg-accept-cookies-btn btn">Accept All</Link>
                                </div>
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyaccCookies;
