import React from 'react'
import GuidelineOverview from '../../../elements/header/modal/candidateprescreen/inustructions/Overview'

const ClientCompany = () => {
  return (
    <>
      <div className='dkg-clientcompany-mainCon' style={{marginTop: "160px"}}>
        <GuidelineOverview />
      </div>
    </>
  )
}

export default ClientCompany