import React from 'react';
import './resourcesummary.scss';

const ResourceSummary = () => {
  return (
    <>
      <div className="dk-adminResourceSummary-main">
        <h2>Resource Dashboard</h2>
      </div>
    </>
  )
}

export default ResourceSummary