import React, { useEffect, useState } from 'react';
import { Route, Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import './salessendouts.scss';
// import TasksLeftPanel from '../../kpitasks/leftpanel/TasksLeftPanel';
import { addValue, updateValue, updateUser, listAll, annualSaleList, annualMonthlySaleList, bulkDelete, bulkDeleteState, clearState } from "../../../../slice/kpis/sendoutKpiSlice";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import TableLoader from '../../../ui/tableLoader';
import CustomDateFormat from "../../../ui/dateFormats/CustomDateFormat";
import "../../../../assets/scss/tooltip.scss";

import UserList from "../../../ui/userList/";
import $ from "jquery";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteModal from "../../../ui/delete";
import UpdatePop from "./UpdatePop";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    tblDdlActive: {
        backgroundColor: '#31a886 !important',
        color: '#ffffff !important',
        minWidth: 90
    },
    tblDdlInActive: {
        backgroundColor: '#d8345f !important',
        color: '#ffffff !important',
        minWidth: 90
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: 'green',
    },
}));


const RCSendouts = () => {
    const dispatch = useDispatch();
    const { dataList, userData, isLoading, isError, isUpdate, annualDataList, annualMonthlyDataList, isSuccess, isBulkDelete, message } = useSelector(state => state.sendoutKpi)
    const { usersList } = useSelector(state => state.users);
    const [year, setYear] = useState(new Date().getFullYear())
    const [month, setMonth] = useState(new Date().getMonth() + 1)
    const [list, setList] = useState([]);
    useEffect(() => {
        document.title = "Sendout kpi's | Recruitment | Dk Global CRM"
    }, [])
    const [userInfo, setUserInfo] = useState([])
    const [monthInfo, setMonthInfo] = useState([])
    const [netSalesInfo, setNetSalesInfo] = useState([])
    const [annualSalesInfo, setAnnualSalesInfo] = useState([])
    const [annualMonthlySalesInfo, setAnnualMonthlySalesInfo] = useState([])

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(listAll({ year, month }))
                await dispatch(annualSaleList({ year }))
                await dispatch(annualMonthlySaleList({ year }))
            } catch (err) {
                dispatch(showError({ msg: message }))
            }
        }
        fetchList()
    }, [year, month])

    useEffect(() => {
        if (!isLoading) {
            setList(dataList)
            let result = [];
            let monthlyResult = [];
            let saleResult = [];
            let annualSaleResult = [];
            let annualMonthlySaleResult = [];
            for (let i = 0; i < dataList.length; i++) {
                // result.push(dataList[i].recInfo.firstName)
                saleResult.push(dataList[i].netSale)
            }
            for (let i = 0; i < annualDataList.length; i++) {
                result.push(annualDataList[i].recInfo.firstName)
                annualSaleResult.push(annualDataList[i].annualNetSale)
            }
            for (let i = 0; i < annualMonthlyDataList.length; i++) {
                switch (annualMonthlyDataList[i].month) {
                    case 1:
                        monthlyResult.push("Jan")
                        break;
                    case 2:
                        monthlyResult.push("Feb")
                        break;
                    case 3:
                        monthlyResult.push("March")
                        break;
                    case 4:
                        monthlyResult.push("April")
                        break;
                    case 5:
                        monthlyResult.push("May")
                        break;
                    case 6:
                        monthlyResult.push("Jun")
                        break;
                    case 7:
                        monthlyResult.push("July")
                        break;
                    case 8:
                        monthlyResult.push("Aug")
                        break;
                    case 9:
                        monthlyResult.push("Sep")
                        break;
                    case 10:
                        monthlyResult.push("Oct")
                        break;
                    case 11:
                        monthlyResult.push("Nov")
                        break;
                    case 12:
                        monthlyResult.push("Dec")
                        break;
                }

                annualMonthlySaleResult.push(annualMonthlyDataList[i].annualMontlyNetSale)
            }
            setUserInfo(result)
            setNetSalesInfo(saleResult)
            setAnnualSalesInfo(annualSaleResult)
            setMonthInfo(monthlyResult)
            setAnnualMonthlySalesInfo(annualMonthlySaleResult)
        }
    }, [isLoading, isBulkDelete, dataList])

    const data = {
        labels: userInfo,
        datasets: [
            {
                label: 'Total Yearly',
                data: annualSalesInfo,
                backgroundColor: [
                    '#297483',
                    '#e2445a',
                    '#01b0f0',
                    '#90ee90',
                    '#d29e01',
                    '#ffffff',
                    '#000000',
                    '#000000',
                    '#000000',
                ],
                borderWidth: 0,
            },
        ],

    };

    const addNew = () => {
        dispatch(addValue({ year, month, uid: -1, clientGroup: '', sendoutTarget: 0, sendoutMade: 0 }))
    }

    const [userId, setUserId] = useState('0')
    const [userImg, setUserImg] = useState('')
    const [userImgName, setUserImgName] = useState('')
    const [rowsId, setRowsId] = useState('')
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [isActive, setIsActive] = useState(false);
    const [countCheck, setCountCheck] = useState(0)
    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')

    const [bulkLoad, setBulkLoad] = useState(false)
    const classes = useStyles();

    const [rowId, setRowId] = useState('0')
    const [isDeletePop, setIsDeletePop] = useState(false);
    const [isDeleteBulkPop, setIsDeleteBulkPop] = useState(false);

    const handleToggle = () => {
        $('.chkCandidate').prop('checked', false);
        setIsActive(false);
    };

    const toggleCheckAll = () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
            setCountCheck(totalCheckboxes)
            $('.chkCandidate').prop('checked', true);
            // setCheckedBoxes(checkedBoxes.filter((v, i, a) => a.indexOf(v) === i))
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Check All')
            setCountCheck(0)
            $('.chkCandidate').prop('checked', false);
            setIsActive(false)
        }
    }
    const closeDelete = () => {
        setIsDeletePop(false)
    }

    const deleteSelected = () => {
        setIsDeleteBulkPop(true)
    }
    const closeDeleteBulk = () => {
        setIsDeleteBulkPop(false)
    }

    /** Single Checkbox Select */
    const toggleCheckbox = (e, id) => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount > 0) {
            setCountCheck(checkedBoxCount)
            setIsActive(true)
        } else {
            setCountCheck(0)
            setIsActive(false)
        }
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Check All')
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
        }
    }
    /** End Single Checkbox Select */

    const tableUpdateStatus = (e) => {
        e.preventDefault()
        dispatch(updateValue({ id: e.target.dataset.id, key: e.target.dataset.column, value: e.target.value }))
    }

    const selectUser = (e) => {
        dispatch(updateUser({ id: e.target.dataset.rowid, key: "uid", value: e.target.dataset.id }))
    }

    useEffect(() => {
        if (isUpdate) {
            dispatch(showSuccess({ msg: 'Data update successfully.' }))
            dispatch(clearState())
        }
        if (isError) {
            dispatch(showError({ msg: message }))
            dispatch(clearState())
        }
    }, [isUpdate, isError])

    useEffect(() => {

        if (isBulkDelete) {
            dispatch(clearState())
            dispatch(listAll({ year, month }))
            setIsActive(false)
            setCheckBtnTxt('Check All')
            setCountCheck(0)
            setBulkLoad(false)
            $('.chkCandidate').prop('checked', false);
            setIsActive(false)
            dispatch(showSuccess({ msg: message }))
            hideDeleteModal()
        }
    }, [isBulkDelete])

    const hideDeleteModal = () => {
        setIsDeletePop(false)
    }

    const deleteItem = async () => {
        //   await dispatch(bulkDelete({ id: rowId }))
    }

    const deleteItemBulk = async () => {
        setBulkLoad(true)
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
        } else {
            var canIds = [];
            $.each($(".chkCandidate:checked"), function () {
                canIds.push($(this).val());
            });
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            await fetch(process.env.REACT_APP_BASE_URL + '/sendout-kpis/bulkdelete', {
                method: 'POST',
                body: JSON.stringify({ 'ids': canIds }),
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                    'platform': 'web',
                    'Authorization': 'Bearer' + ' ' + token.access_token
                }
            }).then(response => {
                if (response.status === 200) {
                    dispatch(showSuccess({ msg: 'Delete successfully' }))
                    async function fetchNewData() {
                        try {
                            await dispatch(clearState())
                            await dispatch(listAll({ year, month }))
                            await dispatch(bulkDeleteState())
                        } catch (err) {
                            console.log(err)
                        }
                    }
                    fetchNewData()
                    setBulkLoad(false)
                    closeDeleteBulk(false)
                    setIsActive(false)
                }
            })
        }
    }

    const [columnKey, setColumnKey] = useState('')
    const [columnValue, setColumnValue] = useState('0')
    const [isUpdatePop, setIsUpdatePop] = useState(false)

    const openUpdateValuePop = (id, key, value) => {
        setRowId(id)
        setColumnKey(key)
        setColumnValue(value)
        setIsUpdatePop(true)
    }
    const hideUpdateValuePop = () => {
        setIsUpdatePop(false)
    }

    return (
        <>
            {
                (isUpdatePop) ? <UpdatePop onHide={hideUpdateValuePop} rowId={rowId} columnKey={columnKey} value={columnValue} /> : null
            }

            {
                (isDeletePop) ? <DeleteModal id={rowId} onHide={hideDeleteModal} deleteItem={deleteItem} handleClose={closeDelete} /> : null
            }

            {
                (isDeleteBulkPop) ? <DeleteModal id={rowId} onHide={hideDeleteModal} deleteItem={deleteItemBulk} handleClose={closeDeleteBulk} /> : null
            }

            <div className="dk-slaesKpiMain dk-sendouts-containerMain">
                {/* <div className="nav-tabs-navigation">
                    <TasksLeftPanel />
                </div> */}
                <div className="dk-salesKpiContainer">
                    <div className="row" style={{ marginTop: '18px' }}>
                        <div className="col-sm-2">
                            <div className="d-flex align-items-center">
                                <Dropdown className="dk-yearBox">
                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                        {year}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#" onClick={() => setYear(2022)} className={(year === 2022) ? `active` : null}>2022</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={() => setYear(2021)} className={(year === 2021) ? `active` : null}>2021</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={() => setYear(2020)} className={(year === 2020) ? `active` : null}>2020</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={() => setYear(2019)} className={(year === 2019) ? `active` : null}>2019</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Link to="#" className="addMonthBtn mr-4" onClick={addNew}><i className="fas fa-plus"></i></Link>
                            </div>
                        </div>
                        <div className="col-sm-10">
                            <div className="monthBoxes test-class">

                                <ul>
                                    <li><Link to="#" onClick={() => setMonth(1)} className={(month === 1) ? `active` : null}>Jan</Link></li>
                                    <li><Link to="#" onClick={() => setMonth(2)} className={(month === 2) ? `active` : null}>Feb</Link></li>
                                    <li><Link to="#" onClick={() => setMonth(3)} className={(month === 3) ? `active` : null}>Mar</Link></li>
                                    <li><Link to="#" onClick={() => setMonth(4)} className={(month === 4) ? `active` : null}>Apr</Link></li>
                                    <li><Link to="#" onClick={() => setMonth(5)} className={(month === 5) ? `active` : null}>May</Link></li>
                                    <li><Link to="#" onClick={() => setMonth(6)} className={(month === 6) ? `active` : null}>Jun</Link></li>
                                    <li><Link to="#" onClick={() => setMonth(7)} className={(month === 7) ? `active` : null}>Jul</Link></li>
                                    <li><Link to="#" onClick={() => setMonth(8)} className={(month === 8) ? `active` : null}>Aug</Link></li>
                                    <li><Link to="#" onClick={() => setMonth(9)} className={(month === 9) ? `active` : null}>Sep</Link></li>
                                    <li><Link to="#" onClick={() => setMonth(10)} className={(month === 10) ? `active` : null}>Oct</Link></li>
                                    <li><Link to="#" onClick={() => setMonth(11)} className={(month === 11) ? `active` : null}>Nov</Link></li>
                                    <li><Link to="#" onClick={() => setMonth(12)} className={(month === 12) ? `active` : null}>Dec</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="dk-salesSendouts-table">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>RC</th>
                                    <th>Client Group</th>
                                    <th>Timeline</th>
                                    <th>Sendout Target</th>
                                    <th>Sendout Made</th>
                                    <th>Sendout Remained</th>
                                    <th>%</th>
                                    <th>Updated On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (isLoading) ?
                                        <tr>
                                            <TableLoader colSpan="12" />
                                        </tr>
                                        :
                                        list.map((item, index) => {
                                            return (
                                                <tr key={index + 1} rowid={item.id}>
                                                    <td>
                                                        <div className="dk-smsTableCheckbox">
                                                            <input type="checkbox" className="chkCandidate" name="chkCandidate[]" value={item.id} onChange={(e) => toggleCheckbox(e, item.id)} />
                                                            {index + 1}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="hidden" id="userId" value="0" />
                                                        <Dropdown className="dk-globalUserDropdown">
                                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                {
                                                                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.recInfo.profileImage} className="select-user-img" />
                                                                }
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu className='dk-adstracker-DrpMenu12'>
                                                                <div className="d-flex">
                                                                    <UserList rowid={item.id} onClick={selectUser} />
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                    <td className="cursor-p dkg-client-compainesName" onClick={() => openUpdateValuePop(item.id, 'clientGroup', item.clientGroup)}>
                                                        {
                                                            item.clientGroup
                                                        }
                                                    </td>
                                                    {/* <td onClick={openUpdateValuePop} className="cursor-p" onClick={() => openUpdateValuePop(item.id, 'timeline', item.timeline)}>
                                                        {
                                                            item.timeline
                                                        }
                                                    </td> */}
                                                    <td>Monthly</td>

                                                    <td onClick={() => openUpdateValuePop(item.id, 'sendoutTarget', item.sendoutTarget)} className="cursor-p">{item.sendoutTarget}</td>
                                                    <td onClick={() => openUpdateValuePop(item.id, 'sendoutMade', item.sendoutMade)} className="cursor-p">{item.sendoutMade}</td>
                                                    <td>
                                                        {
                                                            (item.sendoutMade > item.sendoutTarget) ?
                                                                <div className="text-success">+{item.sendoutMade - item.sendoutTarget}</div>
                                                                : <div className="text-danger">-{item.sendoutTarget - item.sendoutMade}</div>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            (item.sendoutTarget > item.sendoutMade) ?
                                                                <div className="text-danger d-flex align-items-center justify-content-center">
                                                                    <i className="fas fa-arrow-down mr-1"></i>
                                                                    {
                                                                        Math.round(((item.sendoutMade - item.sendoutTarget) * 100) / item.sendoutTarget)
                                                                    }
                                                                </div>
                                                                :
                                                                <div className="text-success d-flex align-items-center justify-content-center">
                                                                    <i className="fas fa-arrow-up mr-1"></i> +
                                                                    {
                                                                        Math.round(((item.sendoutMade - item.sendoutTarget) * 100) / item.sendoutTarget)
                                                                    }
                                                                </div>
                                                        }

                                                    </td>
                                                    <td><CustomDateFormat date={item.updatedAt} format='DD MMM, HH:mm' /></td>

                                                </tr>
                                            )
                                        })
                                }
                            </tbody>
                            <tfoot>
                                <tr className="sumtr">
                                    <td colSpan="4" className="transparent" style={{ backgroundColor: "transparent", border: "none" }}></td>

                                    <td className="sum countGrossSale" style={{ backgroundColor: "transparent", color: "#fff" }}>{
                                        list.reduce((a, v) => a = a + Number(v.sendoutTarget), 0)
                                    }</td>
                                    <td className="sum countGrossSale" style={{ backgroundColor: "transparent", color: "#fff" }}>{
                                        list.reduce((a, v) => a = a + Number(v.sendoutMade), 0)
                                    }</td>
                                    <td className="sum countGrossSale" style={{ backgroundColor: "transparent", color: "#fff" }}>
                                        {Math.round(list.reduce((a, v) => a = a + Number(v.sendoutTarget), 0) - list.reduce((a, v) => a = a + Number(v.sendoutMade), 0))}
                                    </td>
                                    <td className="sum countGrossSale" style={{ backgroundColor: "transparent", color: "#fff" }}>
                                        {
                                            Math.round((((list.reduce((a, v) => a = a + Number(v.sendoutTarget), 0) - list.reduce((a, v) => a = a + Number(v.sendoutMade), 0))) * 100) / list.reduce((a, v) => a = a + Number(v.sendoutTarget), 0))
                                        } %

                                    </td>
                                    <td className="transparent"></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                </div>
            </div>

            <div className={`dk-smsMessage-selectMsg ${isActive ? "activePopup" : ""}`}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="dk-selectNo">{countCheck}</div>
                    <div className="dk-rowSelected">
                        <span>Row Selected</span>
                    </div>
                    <div className="dk-checkAll" onClick={toggleCheckAll}>
                        <span><i className="fas fa-check"></i></span>
                        {checkBtnTxt}
                    </div>
                    {
                        (bulkLoad) ?
                            <div className={`dk-checkAll ` + classes.backdrop}>
                                <CircularProgress color="inherit" />
                            </div>
                            :
                            <div className="dk-checkAll" onClick={deleteSelected}>
                                <span><i className="fas fa-trash-alt"></i></span>
                                Delete
                            </div>
                    }
                    <div className="dk-checkAll d-flex align-items-center justify-content-center" onClick={handleToggle}>
                        <span><i className="fas fa-times"></i></span>
                    </div>
                </div>
            </div>



        </>
    )
}

export default RCSendouts;

