import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const firstCategoryList = createAsyncThunk(
    'candidate_backend_values/first_category_list',
    async ({ boxNo, parentId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/candidates/backend-value-category/first/' + boxNo + "/" + parentId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);
export const categoryList = createAsyncThunk(
    'candidate_backend_values/category_list',
    async ({ boxNo, parentId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/candidates/backend-value-category/' + boxNo + "/" + parentId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const categoryAdd = createAsyncThunk(
    'candidate_backend_values/category_add',
    async ({ boxNo, parentId, name }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/candidates/backend-value-category',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        boxNo,
                        parentId,
                        name
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const categoryUpdate = createAsyncThunk(
    'candidate_backend_values/update_category',
    async ({ id, parentId, boxNo, name }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/candidates/backend-value-category/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        parentId,
                        boxNo,
                        name
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const categoryDelete = createAsyncThunk(
    'candidate_backend_values/category_delete',
    async ({ boxNo, id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/candidates/backend-value-category/' + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const categoryReorder = createAsyncThunk(
    'candidate_backend_values/reorder_category',
    async ({ ids, boxNo, parentId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/candidates/backend-value-category/' + boxNo + '/' + parentId,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        ids
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const valuesList = createAsyncThunk(
    'candidate_backend_values/values',
    async ({ categoryId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/candidates/backend-value/' + categoryId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const addValue = createAsyncThunk(
    'candidate_backend_values/add_value',
    async ({ categoryId, bgColor, textColor, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/candidates/backend-value',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        categoryId,
                        bgColor,
                        textColor,
                        value
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteBkValue = createAsyncThunk(
    'candidate_backend_values/delete_values',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/candidates/backend-value/' + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const reorderValues = createAsyncThunk(
    'candidate_backend_values/reorder_values',
    async ({ ids, cateId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/candidates/backend-value/reorder/' + cateId,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        ids
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const updateValue = createAsyncThunk(
    'candidate_backend_values/update_value',
    async ({ id, categoryId, bgColor, textColor, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/candidates/backend-value/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        categoryId,
                        bgColor,
                        textColor,
                        value
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

const backendValuesSlice = createSlice({
    name: 'candidateBackendValues',
    initialState: {
        box1List: [],
        box2List: [],
        box3List: [],
        box4List: [],
        box5List: [],
        backendValuesList: [],
        isloading: false,
        isReoder: false,
        isLoadingBox1: false,
        isLoadingBox2: false,
        isLoadingBox3: false,
        isLoadingBox4: false,
        isLoadingBox5: false,
        isLoadingValues: false,
        isInsert: false,
        isUpdate: false,
        isUpdateColor: false,
        isDelete: false,
        isSuccess: false,
        isError: false,
        msg: ''
    },
    reducers: {
        clearState: (state) => {
            state.isloading = false;
            state.isLoadingBox1 = false;
            state.isLoadingBox2 = false;
            state.isLoadingBox3 = false;
            state.isLoadingBox4 = false;
            state.isLoadingBox5 = false;
            state.isLoadingValues = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isUpdateColor = false;
            state.isReoder = false;
            state.isDelete = false;
            state.isSuccess = false;
            state.isError = false;
            state.msg = '';
        },
        clearBox1: (state) => {
            state.box1List = []
        },
        clearBox2: (state) => {
            state.box2List = []
        },
        clearBox3: (state) => {
            state.box3List = []
        },
        clearBox4: (state) => {
            state.box4List = []
        },
        clearBox5: (state) => {
            state.box5List = []
        }
    },
    extraReducers: {
        [categoryList.pending]: (state, action) => {
            switch (action.meta.arg.boxNo) {
                case 1:
                    state.isLoadingBox1 = true;
                    break;

                case 2:
                    state.isLoadingBox2 = true;
                    break;

                case 3:
                    state.isLoadingBox3 = true;
                    break;

                case 4:
                    state.isLoadingBox4 = true;
                    break;

                case 5:
                    state.isLoadingBox5 = true;
                    break;
            }
        },
        [categoryList.fulfilled]: (state, action) => {
            switch (action.meta.arg.boxNo) {
                case 1:
                    state.isLoadingBox1 = false;
                    state.box1List = action.payload.result;
                    break;

                case 2:
                    state.isLoadingBox2 = false;
                    state.box2List = action.payload.result;
                    break;

                case 3:
                    state.isLoadingBox3 = false;
                    state.box3List = action.payload.result;
                    break;

                case 4:
                    state.isLoadingBox4 = false;
                    state.box4List = action.payload.result;
                    break;

                case 5:
                    state.isLoadingBox5 = false;
                    state.box5List = action.payload.result;
                    break;
            }
        },
        [categoryList.rejected]: (state, action) => {
            state.isLoadingBox1 = false;
            state.isLoadingBox2 = false;
            state.isLoadingBox3 = false;
            state.isLoadingBox4 = false;
            state.isLoadingBox5 = false;
            state.isError = action.payload.message
            state.msg = action.payload.message;
        },
        [firstCategoryList.pending]: (state, action) => {
            switch (action.meta.arg.boxNo) {
                case 1:
                    state.isLoadingBox1 = true;
                    break;

                case 2:
                    state.isLoadingBox2 = true;
                    break;

                case 3:
                    state.isLoadingBox3 = true;
                    break;

                case 4:
                    state.isLoadingBox4 = true;
                    break;

                case 5:
                    state.isLoadingBox5 = true;
                    break;
            }
        },
        [firstCategoryList.fulfilled]: (state, action) => {
            switch (action.meta.arg.boxNo) {
                case 1:
                    state.isLoadingBox1 = false;
                    state.box1List = action.payload.result;
                    break;

                case 2:
                    state.isLoadingBox2 = false;
                    state.box2List = action.payload.result;
                    break;

                case 3:
                    state.isLoadingBox3 = false;
                    state.box3List = action.payload.result;
                    break;

                case 4:
                    state.isLoadingBox4 = false;
                    state.box4List = action.payload.result;
                    break;

                case 5:
                    state.isLoadingBox5 = false;
                    state.box5List = action.payload.result;
                    break;
            }
        },
        [firstCategoryList.rejected]: (state, action) => {
            state.isLoadingBox1 = false;
            state.isLoadingBox2 = false;
            state.isLoadingBox3 = false;
            state.isLoadingBox4 = false;
            state.isLoadingBox5 = false;
            state.isError = action.payload.message
            state.msg = action.payload.message;
        },
        [categoryAdd.pending]: (state, action) => {
            state.isInsert = false;
        },
        [categoryAdd.fulfilled]: (state, action) => {
            state.isInsert = true;
            switch (action.meta.arg.boxNo) {
                case '1':
                    state.box1List.push(action.payload.result)
                    break;

                case '2':
                    state.box2List.push(action.payload.result)
                    break;

                case '3':
                    state.box3List.push(action.payload.result)
                    break;

                case '4':
                    state.box4List.push(action.payload.result)
                    break;

                case '5':
                    state.box5List.push(action.payload.result)
                    break;
            }
            state.msg = action.payload.message.msgBody;
        },
        [categoryAdd.rejected]: (state, action) => {
            state.isInsert = false;
            state.isError = action.payload.status;
            state.msg = action.payload.message;
        },
        [categoryUpdate.pending]: (state) => {
            state.isloading = true;
        },
        [categoryUpdate.fulfilled]: (state, action) => {
            state.isloading = false;
            switch (action.meta.arg.boxNo) {
                case '1':
                    var mainArray = state.box1List;
                    var finalResult = mainArray.map((r) => {
                        if (r.id === action.meta.arg.id) {
                            return (action.payload.result)
                        } else {
                            return r
                        }
                    })
                    state.box1List = finalResult
                    break;

                case '2':
                    var mainArray = state.box2List;
                    var finalResult = mainArray.map((r) => {
                        if (r.id === action.meta.arg.id) {
                            return (action.payload.result)
                        } else {
                            return r
                        }
                    })
                    state.box2List = finalResult
                    break;

                case '3':
                    var mainArray = state.box3List;
                    var finalResult = mainArray.map((r) => {
                        if (r.id === action.meta.arg.id) {
                            return (action.payload.result)
                        } else {
                            return r
                        }
                    })
                    state.box3List = finalResult
                    break;

                case '4':
                    var mainArray = state.box4List;
                    var finalResult = mainArray.map((r) => {
                        if (r.id === action.meta.arg.id) {
                            return (action.payload.result)
                        } else {
                            return r
                        }
                    })
                    state.box4List = finalResult
                    break;

                case '5':
                    var mainArray = state.box5List;
                    var finalResult = mainArray.map((r) => {
                        if (r.id === action.meta.arg.id) {
                            return (action.payload.result)
                        } else {
                            return r
                        }
                    })
                    state.box5List = finalResult
                    break;
            }
            state.isUpdate = true;
            state.msg = 'Update successfully.';
        },
        [categoryUpdate.rejected]: (state) => {
            state.isloading = false;
            state.isError = true;
            state.msg = 'Something went wrong! Try again.';
        },
        [categoryDelete.pending]: (state) => {
            state.isloading = true;
        },
        [categoryDelete.fulfilled]: (state, action) => {
            state.isloading = false;
            switch (action.meta.arg.boxNo) {
                case '1':
                    state.box1List = state.box1List.filter((item) => item.id !== action.meta.arg.id)
                    break;

                case '2':
                    state.box2List = state.box2List.filter((item) => item.id !== action.meta.arg.id)
                    break;
                case '3':
                    state.box3List = state.box3List.filter((item) => item.id !== action.meta.arg.id)
                    break;

                case '4':
                    state.box4List = state.box4List.filter((item) => item.id !== action.meta.arg.id)
                    break;

                case '5':
                    state.box5List = state.box5List.filter((item) => item.id !== action.meta.arg.id)
                    break;
            }
            state.isDelete = true;
            state.msg = 'Delete successfully.';
        },
        [categoryReorder.pending]: (state) => {
            state.isloading = true;
        },
        [categoryReorder.fulfilled]: (state, action) => {
            state.isloading = false;
            state.isReoder = true;
            switch (action.meta.arg.boxNo) {
                case '1':
                    state.box1List = action.payload.result
                    break;

                case '2':
                    state.box2List = action.payload.result
                    break;

                case '3':
                    state.box3List = action.payload.result
                    break;

                case '4':
                    state.box4List = action.payload.result
                    break;

                case '5':
                    state.box5List = action.payload.result
                    break;
            }
            state.msg = "Reorder successfully.";
        },
        [categoryReorder.rejected]: (state, { payload }) => {
            state.isloading = false;
            state.isError = payload.status;
            state.msg = payload.message;
        },
        [categoryDelete.rejected]: (state, { payload }) => {
            state.isloading = false;
            state.isError = true;
            state.msg = 'Something went wrong! Try again.';
        },
        [valuesList.pending]: (state) => {
            state.isloading = true;
        },
        [valuesList.fulfilled]: (state, { payload }) => {
            state.isloading = false;
            state.backendValuesList = payload.result;
        },
        [valuesList.rejected]: (state, { payload }) => {
            state.isloading = false;
            state.isError = payload.status;
            state.msg = payload.message;
        },
        [addValue.pending]: (state) => {
        },
        [addValue.fulfilled]: (state, { payload }) => {
            state.backendValuesList.push(payload.result)
        },
        [addValue.rejected]: (state, { payload }) => {
            state.isError = payload.status;
            state.msg = payload.message;
        },
        [deleteBkValue.pending]: (state) => {
            state.isloading = true;
        },
        [deleteBkValue.fulfilled]: (state, action) => {
            state.isloading = false;
            state.backendValuesList = state.backendValuesList.filter((item) => item.id !== action.meta.arg.id)
            state.isDelete = true;
            state.msg = 'Delete successfully.';
        },
        [deleteBkValue.rejected]: (state, { payload }) => {
            state.isloading = false;
            state.isError = true;
            state.msg = 'Something went wrong! Try again.';
        },
        [reorderValues.pending]: (state) => {
        },
        [reorderValues.fulfilled]: (state, { payload }) => {

            state.backendValuesList = payload.result;
            state.isloading = false;
            state.isReoder = true;
            state.msg = "Reorder successfully.";

        },
        [reorderValues.rejected]: (state, { payload }) => {
            state.isError = payload.status;
            state.msg = payload.message;
        },
        [updateValue.pending]: (state) => {
        },
        [updateValue.fulfilled]: (state, { payload }) => {
            var mainArray = state.backendValuesList;
            var finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.backendValuesList = finalResult
        },
        [updateValue.rejected]: (state) => {
            state.isError = true;
            state.msg = 'Something went wrong! Try again.';
        },
    }
})

export const { clearState, clearBox2, clearBox3, clearBox4, clearBox5 } = backendValuesSlice.actions
export default backendValuesSlice.reducer;