import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const RecruitmentFaqNavbar = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    const segment4 = pathname.split("/")[4];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-RecFaqDepartmentsNavbar">
                <Route>
                    <ul>
                        <li><Link to="/recruitment/faq/dashboard" className={(segment3 == 'dashboard') ? 'active' : ''}>Dashboard</Link></li>
                        <li><Link to="/recruitment/faq/clientsinfo-faq" className={`${(segment3 == 'clientsinfo-faq') ? 'active' : ''}`}>Clients Info</Link></li>
                        <li><Link to="/recruitment/faq/projects-faq" className={`${(segment3 == 'projects-faq') ? 'active' : ''}`}>Projects</Link></li>
                        <li><Link to="/recruitment/faq/jobdemand-faq" className={`${(segment3 == 'jobdemand-faq') ? 'active' : ''}`}>Job Demand</Link></li>
                        <li><Link to="/recruitment/faq/manageads-faq" className={`${(segment3 == 'manageads-faq') ? 'active' : ''}`}>Manage Ads</Link></li>
                        <li><Link to="/recruitment/faq/database-faq" className={`${(segment3 == 'database-faq') ? 'active' : ''}`}>Database</Link></li>
                        <li><Link to="/recruitment/faq/projections-faq" className={`${(segment3 == 'projections-faq') ? 'active' : ''}`}>Projections</Link></li>
                        <li><Link to="/recruitment/faq/recruitmenttips-faq" className={`${(segment3 == 'recruitmenttips-faq') ? 'active' : ''}`}>Recruitment Tips</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default RecruitmentFaqNavbar;