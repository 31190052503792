import React from 'react';
import { Modal, Dropdown } from 'react-bootstrap';
import DkfStaffDatepicker from "./DkfStaffDatepicker";
import Submit from '../../../../../ui/submitButton'

const AddNewModal = ({ onHide }) => {
    return (
        <>
            <Modal className="dk-yearsAddNewTask-modal dk-addNewDkgStaffModal right" show={true} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>ADD NEW FILE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Status</label>
                                <label htmlFor="">
                                    <Dropdown className='dkg-return-status-dropdown'>
                                        <Dropdown.Toggle variant="" className='dkg-return-status-dropdown-toggle' style={{ backgroundColor: "#3a9469", color: "#fff" }}>
                                            Active
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='dkg-return-status-dropdown-Menu' style={{ borderRadius: "0", padding: "0" }}>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' style={{ backgroundColor: "#3a9469", color: "#fff" }} href="#">Active</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' style={{ backgroundColor: "red", color: "#fff" }} href="#">InActive</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Start Date</label>
                                <label htmlFor="">
                                    <DkfStaffDatepicker />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Expire On</label>
                                <label htmlFor="">
                                    <DkfStaffDatepicker />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Duration</label>
                                <Dropdown className='dkg-contracts-dropdown'>
                                    <Dropdown.Toggle variant="" className='dkg-contracts-dropdown-toggle'>
                                        Select
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dkg-contracts-dropdown-Menu'>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Duration 1</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Duration 2</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Duration 3</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Duration 4</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Duration 5</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Contract Type</label>
                                <Dropdown className='dkg-contracts-dropdown'>
                                    <Dropdown.Toggle variant="" className='dkg-contracts-dropdown-toggle'>
                                        Select
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dkg-contracts-dropdown-Menu'>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Contract Type 1</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Contract Type 2</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Contract Type 3</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Contract Type 4</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Contract Type 5</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Name of Client</label>
                                <Dropdown className='dkg-contracts-dropdown'>
                                    <Dropdown.Toggle variant="" className='dkg-contracts-dropdown-toggle'>
                                        Select
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dkg-contracts-dropdown-Menu'>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Client 1</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Client 2</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Client 3</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Client 4</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Client 5</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Location of Client</label>
                                <Dropdown className='dkg-contracts-dropdown'>
                                    <Dropdown.Toggle variant="" className='dkg-contracts-dropdown-toggle'>
                                        Select
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dkg-contracts-dropdown-Menu'>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Dublin</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Ireland</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">India</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Grecee</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Noida</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Services Type</label>
                                <Dropdown className='dkg-contracts-dropdown'>
                                    <Dropdown.Toggle variant="" className='dkg-contracts-dropdown-toggle'>
                                        Select
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dkg-contracts-dropdown-Menu'>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Services Type 1</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Services Type 2</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Services Type 3</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Services Type 4</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">Services Type 5</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Year</label>
                                <Dropdown className='dkg-contracts-dropdown'>
                                    <Dropdown.Toggle variant="" className='dkg-contracts-dropdown-toggle'>
                                        Select
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dkg-contracts-dropdown-Menu'>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">2019</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">2020</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">2021</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">2022</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">2023</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">2024</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">2025</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">2026</Dropdown.Item>
                                        <Dropdown.Item className='dkg-contracts-dropdown-item' href="#">2027</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Attachment File</label>
                                <input type="file" id="imageInputAPI" className="form-control w-100 dkg-payment-attach-input" />
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group justify-content-center dkg-save-btnCon mt-3'>
                                {/* <button className="dkaddBtn">Add</button> */}
                                <Submit txt="Save" className="btn dk-create-new-taskbtn" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default AddNewModal;
