import React from 'react';
import LeftPanel from '../leftpanel/LeftPanel';
import DailyTracking from "../../../dashboard/dailytracking/DailyTracking";
import './dayclosingmoods.scss';


const WorkplanCheckIn = () => {
    return (
        <React.Fragment>
            <div className="dk-dayclsoing-Main dk-dayclsoing-DetailMain" style={{ backgroundColor: "#fff" }}>
                <div className="dk-detailsMain w-100">
                    <div className="d-md-flex">
                        {/* <div className="dk-detailsLeftPanel">
                            <LeftPanel />
                        </div> */}
                        <div className="dk-detailRightPanel dk-workPlanDailyTracking">
                            <div className="dk-detailTabsMain">
                                <div className="dk-trainingInformation-cols" style={{ padding: '0' }}>
                                    <div className="dk-payDetailRightPanel">
                                        <DailyTracking />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default WorkplanCheckIn;

