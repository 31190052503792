import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const AdsSubmenu = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    const segment4 = pathname.split("/")[4];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/recruitment/ads/summary" className={(segment3 == 'summary') ? 'active' : ''}>Summary</Link></li>
                        <li><Link to="/recruitment/ads/templates" className={`${(segment3 == 'templates') ? 'active' : ''}`}>Templates</Link></li>
                        <li><Link to="/recruitment/ads/ads-resources" className={`${(segment3 == 'ads-resources') ? 'active' : ''}`}>Resources</Link></li>
                        <li><Link to="/recruitment/ads/titles" className={`${(segment3 == 'titles') ? 'active' : ''}`}>Titles</Link></li>
                        <li><Link to="/recruitment/ads/saved-ads" className={`${(segment3 == 'saved-ads') ? 'active' : ''}`}>Saved Ads</Link></li>
                        <li><Link to="/recruitment/ads/todays_tracker" className={`${(segment3 == 'todays_tracker') ? 'active' : ''}`}>Today Ads</Link></li>
                        <li><Link to="/recruitment/ads/ads-credits" className={`${(segment3 == 'ads-credits') ? 'active' : ''}`}>Ads Credits</Link></li>
                        <li><Link to="/recruitment/ads/live_tracker" className={`${(segment3 == 'live_tracker') ? 'active' : ''}`}>Live Ads</Link></li>
                        <li><Link to="/recruitment/ads/campaigns/live" className={`${(segment4 == 'live' || segment4 == 'closed') ? 'active' : ''}`}>Campaigns</Link></li>
                        <li><Link to="/recruitment/ads/apps_tracker/daily_tracker" className={`${(segment4 == 'daily_tracker' || segment4 == 'weekly_tracker' || segment2 == 'monthly_tracker' || segment2 == 'yearly_tracker') ? 'active' : ''}`}>Apps Tracker</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default AdsSubmenu;
