import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const MiniJobSpecDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkglobaladmin/admintraining/admins/mini-job-specs" className={`${pathname.match('/dkglobaladmin/admintraining/admins/mini-job-specs') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/minijobspecs/create" className={`${pathname.match('/dkglobaladmin/admintraining/admins/minijobspecs/create') ? 'active' : ''}`}>Create</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/minijobspecs/outlook" className={`${pathname.match('/dkglobaladmin/admintraining/admins/minijobspecs/outlook') ? 'active' : ''}`}>Outlook</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/minijobspecs/save-spec" className={`${pathname.match('/dkglobaladmin/admintraining/admins/minijobspecs/save-spec') ? 'active' : ''}`}>Save Spec</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/minijobspecs/email-spec" className={`${pathname.match('/dkglobaladmin/admintraining/admins/minijobspecs/email-spec') ? 'active' : ''}`}>Email Spec</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/minijobspecs/send-sms" className={`${pathname.match('/dkglobaladmin/admintraining/admins/minijobspecs/send-sms') ? 'active' : ''}`}>Send SMS</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default MiniJobSpecDetailLeftPanel;

