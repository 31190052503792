import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import { FaUserAlt, FaSpinner, FaBuilding, FaBook, FaLanguage, FaUserCheck, FaCalendarAlt, FaCalendar, FaCalendarWeek, FaCalendarDay, FaRegFileAlt } from 'react-icons/fa';
//import CustomDateFormat from "../../../ui/dateFormats/CustomDateFormat";
//import moment from "moment-timezone";

const AdvancedFilter = ({ onHide, headerTitle, list, selectUserFilter, selectOtherFilter, tableName }) => {

    const [Day, setDay] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [monthsLists, setMonthsList] = useState([]);
    const [weeksList, setWeeksList] = useState([]);
    const [rcUserList, setRcUserList] = useState([]);

    const [modelList, setmodelList] = useState([]);

    const [timelineList, setTimelineList] = useState([]);
    const [progressList, setProgress] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [langList, setLangList] = useState([]);
    const [cvSourceList, setCvSourceList] = useState([]);

    useEffect(() => {

        
        const modelResult = [];
        const modelsmap = new Map();
        for (const item of list) {
            if (!modelsmap.has(item.original.model)) {
                modelsmap.set(item.original.model, true);
                modelResult.push({
                    name: (item.original.model !== '') ? item.original.model : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.model === item.original.model;
                    }).length
                });
            }
        }
        modelResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setmodelList(modelResult)
        const daysResult = [];
        const daysmap = new Map();
        for (const item of list) {
            if (!daysmap.has(item.original.day)) {
                daysmap.set(item.original.day, true);
                daysResult.push({
                    name: (item.original.day !== '') ? item.original.day : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.day === item.original.day;
                    }).length
                });
            }
        }
        daysResult.sort(function (a, b) {
            return b.count - a.count;
        });
        const strAscending = [...daysResult].sort((a, b) =>
            a.name > b.name ? 1 : -1,
        );
        setDay(strAscending)


        const YearsResult = [];
        const Yearssmap = new Map();
        for (const item of list) {
            if (!Yearssmap.has(item.original.year)) {
                Yearssmap.set(item.original.year, true);
                YearsResult.push({
                    name: (item.original.year !== '') ? item.original.year : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.year === item.original.year;
                    }).length
                });
            }
        }
        YearsResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setYearList(YearsResult)

        const MonthsResult = [];
        const Monthssmap = new Map();
        for (const item of list) {
            if (!Monthssmap.has(item.original.month)) {
                Monthssmap.set(item.original.month, true);
                MonthsResult.push({
                    name: (item.original.month !== '') ? item.original.month : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.month === item.original.month;
                    }).length
                });
            }
        }
        MonthsResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setMonthsList(MonthsResult)


        const WeeksResult = [];
        const Weekssmap = new Map();
        for (const item of list) {
            if (!Weekssmap.has(item.original.week)) {
                Weekssmap.set(item.original.week, true);
                WeeksResult.push({
                    name: (item.original.week !== '') ? item.original.week : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.week === item.original.week;
                    }).length
                });
            }
        }
        WeeksResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setWeeksList(WeeksResult)


        const TimelineResult = [];
        const Timelinesmap = new Map();
        for (const item of list) {
            if (!Timelinesmap.has(item.original.timeline)) {
                Timelinesmap.set(item.original.timeline, true);
                TimelineResult.push({
                    name: (item.original.timeline !== '') ? item.original.timeline : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.timeline === item.original.timeline;
                    }).length
                });
            }
        }
        TimelineResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setTimelineList(TimelineResult)


        const rcResult = [];
        const rcmap = new Map();
        for (const item of list) {
            if (!rcmap.has(item.original.recInfo.id)) {
                rcmap.set(item.original.recInfo.id, true);
                rcResult.push({
                    id: item.original.recInfo.uid,
                    name: item.original.recInfo.firstName,
                    userImg: item.original.recInfo.profileImage,
                    count: list.filter(function (val) {
                        return val.original.recInfo.uid === item.original.recInfo.uid;
                    }).length
                });
            }
        }
        rcResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setRcUserList(rcResult)


    }, [list])


    return (
        <>
            <Modal className="dk-sendoutsSearch-modal right" show={true} onHide={onHide}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>{props.headerTitle}</Modal.Title> */}
                    <Modal.Title>{headerTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-sendoutFilterTabs-main">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_2">
                            <div className="dk-sendoutTabs-nav">
                                <Nav variant="">

                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_2"><FaCalendar className="mr-2" /> Year</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_3"><FaCalendarAlt className="mr-2" /> Month</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_6"><FaCalendarWeek className="mr-2" /> Week </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_5"><FaCalendarDay className="mr-2" /> Day</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_4"><FaUserAlt className="mr-2" /> Recruters</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="model"><FaBuilding className="mr-2" /> Model</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_7"><FaSpinner /> Time Frame</Nav.Link>
                                    </Nav.Item>

                                </Nav>
                            </div>
                            <div className="dk-sendoutTabs-filterPanel">
                                <Tab.Content>
                                    <Tab.Pane eventKey="tabs_2">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">

                                                <ul>
                                                    {yearList.map((item, index) => (
                                                        <li key={`year${index}`} data-type="year" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="year" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="year" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_3">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">

                                                <ul>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'January' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'February' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'March' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'April' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'May' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'June' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'July' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'August' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'September' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'October' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'November' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'December' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="tabs_5">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">

                                                <ul>
                                                    {Day.map((item, index) => (
                                                        <li key={`day${index}`} data-type="day" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="day" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="day" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="tabs_6">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">

                                                <ul>
                                                    {weeksList.map((item, index) => (
                                                        <li key={`week${index}`} data-type="week" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="week" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="week" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="model">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                            <ul>
                                                    {modelList.map((item, index) => (
                                                        <li key={`model${index}`} data-type="model" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="model" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="model" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                                
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_4">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-Recuriter">
                                                <ul>
                                                    {rcUserList.map((item, index) => (
                                                        <li key={`rcFilter${index}`}>
                                                            <Link to="#" data-type="rc" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} alt={item.name} className="recImg" title={item.name} data-type="rc" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                                                <span className="recuriterNo" data-type="rc" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                    {item.count}
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_7">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">

                                                <ul>
                                                    {timelineList.map((item, index) => (
                                                        <li key={`timeline${index}`} data-type="timeline" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="timeline" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="timeline" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default AdvancedFilter;
