import React from "react";
import UserIcon from "../../../../../assets/images/deepak.png";
import './summary.scss';

const TrainingSummary = () => {
    return (
        <React.Fragment>

        </React.Fragment>
    );
}

export default TrainingSummary;
