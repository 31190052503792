import React from 'react';
import { FaTimes } from 'react-icons/fa';
import './structure.scss';

const index = () => {
    return (
        <>
            <div className="dk-structureMainFluid">
                {/* <div className="structurFlex">
                    <div className="dk-structure-box">
                        <div className="title">Company</div>
                        <div className="dk-structure-panel" style={{ background: '#94e4fe' }}>
                            <h3>
                                Dk Global Recruitment
                            </h3>
                        </div>
                    </div>
                    <div className="dk-structure-box">
                        <div className="title">Teams</div>
                        <div className="dk-structure-panel" style={{ background: '#ffe4a9' }}>
                            <div className="structure-list">
                                <li>Team 1 - Management</li>
                                <li>Team 2 - Administration</li>
                                <li>Team 3 - Tech Support</li>
                                <li>Team 4 - Production</li>
                            </div>
                        </div>
                    </div>
                    <div className="dk-structure-box">
                        <div className="title">Departments</div>
                        <div className="dk-structure-panel" style={{ background: '#cce8b5' }}>
                            <div className="structure-list lg-list">
                                <li>1 - Recruitment</li>
                                <li>2 - Resources</li>
                                <li>3 - Marketing</li>
                                <li>4 - Finance</li>
                                <li>5 - Legal</li>
                                <li>6 - HR</li>
                                <li>7 - </li>
                                <li>8 - Training</li>
                                <li>9 - IT Team</li>
                                <li>10 - Management</li>
                            </div>
                        </div>
                    </div>
                    <div className="dk-structure-box">
                        <div className="title">Job Profile</div>
                        <div className="dk-structure-panel" style={{ background: '#fff' }}></div>
                    </div>
                </div> */}

                {/* <div className="dk-structureTable-main">
                    <div className="dk-structureTable">
                        <table className="table table-bordered dk-tablehead">
                            <tr>
                                <td>Company - 1</td>
                                <td>Dk Global Recruitment</td>
                            </tr>
                        </table>
                        <table className="table table-bordered">
                            <tr>
                                <td>Teams - 4</td>
                                <td>
                                    <div className="d-flex flex-wrap justify-content-center">
                                        <div className="dk-tags">
                                            Management
                                        </div>
                                        <div className="dk-tags">
                                            Text
                                        </div>
                                        <div className="dk-tags">
                                            Text 2
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex flex-wrap justify-content-center">
                                        <div className="dk-tags">
                                            Administration
                                        </div>
                                        <div className="dk-tags">
                                            Text
                                        </div>
                                        <div className="dk-tags">
                                            Text 2
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex flex-wrap justify-content-center">
                                        <div className="dk-tags">
                                            IT Support
                                        </div>
                                        <div className="dk-tags">
                                            Text
                                        </div>
                                        <div className="dk-tags">
                                            Text 2
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex flex-wrap justify-content-center">
                                        <div className="dk-tags">
                                            Production
                                        </div>
                                        <div className="dk-tags">
                                            Text
                                        </div>
                                        <div className="dk-tags">
                                            Text 2
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Departments - 10</td>
                                <td>
                                    <div className="d-flex flex-wrap justify-content-center">
                                        <div className="dk-tags">
                                            Text
                                        </div>
                                        <div className="dk-tags">
                                            Text 2
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex flex-wrap justify-content-center">
                                        <div className="dk-tags">
                                            Text
                                        </div>
                                        <div className="dk-tags">
                                            Text 2
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex flex-wrap justify-content-center">
                                        <div className="dk-tags">
                                            Text
                                        </div>
                                        <div className="dk-tags">
                                            Text 2
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex flex-wrap justify-content-center">
                                        <div className="dk-tags">
                                            Text
                                        </div>
                                        <div className="dk-tags">
                                            Text 2
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Job Profiles - 10</td>
                                <td>
                                    <div className="d-flex flex-wrap justify-content-center">
                                        <div className="dk-tags">
                                            Text
                                        </div>
                                        <div className="dk-tags">
                                            Text 2
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex flex-wrap justify-content-center">
                                        <div className="dk-tags">
                                            Text
                                        </div>
                                        <div className="dk-tags">
                                            Text 2
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex flex-wrap justify-content-center">
                                        <div className="dk-tags">
                                            Text
                                        </div>
                                        <div className="dk-tags">
                                            Text 2
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex flex-wrap justify-content-center">
                                        <div className="dk-tags">
                                            Text
                                        </div>
                                        <div className="dk-tags">
                                            Text 2
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default index