import React from "react";
import { Route, Link } from "react-router-dom";

const KpiSubmenu = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/knowleadge/kpi" className={`${(segment2 == 'kpi') ? 'active' : ''}`}>Sample 1</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'resource2') ? 'active' : ''}`}>Sample 2</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default KpiSubmenu;
