import React, { useState } from 'react';
import VisaInfoOverViewLeftPanel from './leftpanel/VisaInfoOverViewLeftPanel'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import MoreDetailModal from '../MoreDetailModal';
import TItleIcon from "../../../../assets/images/candidate.png";
import '../faqdkglobalcrm.scss';

const VisainfoAllVisaType = () => {
    const [isShowMore, setIsShowMore] = useState(false);
    const showmoreDetails = () => {
        console.log("Show")
        setIsShowMore(true);
    }
    const hidemoreDetails = () => {
        setIsShowMore(false);
    }

    return (
        <React.Fragment>
            {
                (isShowMore) ? <MoreDetailModal handleClose={hidemoreDetails} /> : null
            }

            <div className="dk-backBtnMain dk-mainMenuFaqBtn">
                <h3 className="dk-detailTitle">
                    <img src={TItleIcon} className="img-fluid" alt="" />
                    Visa Info
                </h3>
            </div>

            <div className="dk-trainingDetailMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <VisaInfoOverViewLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain dk-detailTabsMainOverviewMain">
                                <h3 className="title">All Visa Type</h3>
                                <Tabs defaultActiveKey="tabs_1">
                                    <Tab eventKey="tabs_1" title="Details">
                                        <div className="dk-trainingInformation-cols">
                                            <p>Visa Info Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid similique dolore laboriosam reprehenderit sapiente at iusto? Soluta tempora eveniet suscipit quae aliquid provident rem exercitationem ipsa eos aspernatur? Necessitatibus, optio?</p>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tabs_2" title="Samples">
                                        <div className="dk-trainingInformation-cols">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid similique dolore laboriosam reprehenderit sapiente at iusto? Soluta tempora eveniet suscipit quae aliquid provident rem exercitationem ipsa eos aspernatur? Necessitatibus, optio?</p>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tabs_3" title="Video">
                                        <div className="dk-trainingInformation-cols">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid similique dolore laboriosam reprehenderit sapiente at iusto? Soluta tempora eveniet suscipit quae aliquid provident rem exercitationem ipsa eos aspernatur? Necessitatibus, optio?</p>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default VisainfoAllVisaType;

