import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown'
import { updateCategory, clearState } from "../../../../../slice/qualify/qualifySlice";
import { showSuccess, showError } from "../../../../../slice/utils/message/messageSlice";

const CategoryList = ({ id }) => {
    const dispatch = useDispatch();
    const { qualifyCategoryList, isUpdate, msg, isError, errorMessage } = useSelector(state => state.qualify)
    const [rowId, setRowId] = useState(id)

    const changeCategory = async (e) => {
        await dispatch(updateCategory({ id: rowId, catId: e.target.dataset.key }))
    }

    useEffect(() => {
        if (isUpdate) {
            dispatch(showSuccess({ msg: msg }))
            dispatch(clearState())
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
        }
    }, [isUpdate, isError])
    return (
        <Dropdown.Menu className="dk-freqDrpMenu 2">
            {
                qualifyCategoryList.map((item, index) => (
                    <Dropdown.Item data-key={item._id} style={{ color: item.textColor, backgroundColor: item.bgColor }} onClick={changeCategory}>{item.value}</Dropdown.Item>
                ))
            }
        </Dropdown.Menu>
    )
}

export default CategoryList;