import React from 'react';
import Header from "../../elements/header/RecuiterHeader";
import Navbar from '../../elements/header/navbar/rc-account/RcaccountNav'

const RecuiteraccLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header mainheaderLink="" dkgmainheaader="dkg-dkgmainheaader-classes" myaccheadericons="dkg-myaccheader-icons" moduleLink="/re-account/dashboard" linkcolor="#2e97b6" mainheaderTitle=" " textcolor="#1f394c" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default RecuiteraccLayout;
