import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerCols = ({ value, onChange, txtvalue }) => {
    return (
        <DatePicker selected={value} value={txtvalue} onChange={(date) => onChange(date)} dateFormat="d MMM yyyy" />
    );
};

export default DatePickerCols;