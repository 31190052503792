import React from 'react';

const sendoutStage = ({ value }) => {

    let bgColor = "";
    switch (value) {

        case '31':
            bgColor = '#d9714e'
            break;
        case '32':
            bgColor = '#d9714e'
            break;

        case 'Moved Forward':
            bgColor = '#316a67'
            break;

        case '34':
            bgColor = '#316a67'
            break;

        default:
            bgColor = '#316a67'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor, color: '#fff', minWidth: '80px' }}>{value}</div>
        </>
    )
}

export default sendoutStage;