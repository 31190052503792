import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DetailLeftPanel from "./DetailLeftPanel";
import HeadingTitle from './HeadingTitle';
import '../trainingdetail.scss';
import '../completion/completion.scss';
import AddNewModal from '../completion/AddNewModal';
import EditNewModal from '../completion/EditNewModal';
import DeleteModal from '../completion/DeleteModal';
import Dropdown from 'react-bootstrap/Dropdown';
import AddNumberModal from '../completion/AddNumberModal';
import DatePicker from "react-datepicker";
import "../../../../../node_modules/react-datepicker/dist/react-datepicker.css";

const TrainingStatus = () => {
    const [showAddNewModal, setShowAddNew] = useState(false)
    const [showEditNewModal, setEditAddNew] = useState(false)
    const [showDeleteModal, setDeleteModal] = useState(false)
    const [showAddNumberModal, setAddNumberModal] = useState(false)

    const ShowAddNew = () => {
        setShowAddNew(true)
    }

    const EditAddNew = () => {
        setEditAddNew(true)
    }

    const AddNewNumber = () => {
        setAddNumberModal(true)
    }

    const ViewDeleteModal = () => {
        setDeleteModal(true)
    }

    const HideAddNew = () => {
        setShowAddNew(false)
    }

    const HideEditNew = () => {
        setEditAddNew(false)
    }

    const HideDeleteModal = () => {
        setDeleteModal(false)
    }

    const HideAddNumberModal = () => {
        setAddNumberModal(false)
    }

    const [date, handleDateChange] = useState(new Date());


    return (
        <React.Fragment>
            {
                showAddNewModal ?
                    <AddNewModal handleClose={HideAddNew} /> : null
            }

            {
                showEditNewModal ?
                    <EditNewModal handleClose={HideEditNew} /> : null
            }

            {
                showDeleteModal ?
                    <DeleteModal handleClose={HideDeleteModal} /> : null
            }

            {
                showAddNumberModal ?
                    <AddNumberModal handleClose={HideAddNumberModal} /> : null
            }

            <HeadingTitle />

            <div className="dk-trainingDetailMain dk-ShedulecompletionMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <DetailLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                <div className="dk-completionMain">
                                    <div className="dk-completionHead">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="dk-searchBox">
                                                <input type="text" className="form-control" placeholder="Search..." name="" id="" />
                                                <div className="dk-userImg mr-2">
                                                    <span><i className="fas fa-times"></i></span>
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/1582733069.jpeg" className="img-fluid img-circle" alt="" />
                                                </div>
                                                <div className="dk-userImg">
                                                    <span><i className="fas fa-times"></i></span>
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5a701dc2182ac.png" className="img-fluid img-circle" alt="" />
                                                </div>
                                            </div>
                                            <h3 className="title">MODULE TRAINING PROGRESS</h3>
                                            <div className="dk-rightPanel">
                                                <button onClick={ShowAddNew}><i className="fas fa-plus"></i> Add New</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dk-completionTableMain">
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Sno.</th>
                                                        <th>Start Date</th>
                                                        <th>Finish Date</th>
                                                        <th>Trainer</th>
                                                        <th>Trainee</th>
                                                        <th>DKG Job Title</th>
                                                        <th>All Modules</th>
                                                        <th>Completed</th>
                                                        <th>In Progress</th>
                                                        <th>To Do</th>
                                                        <th>Training Type</th>
                                                        <th>Current Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>
                                                            <DatePicker
                                                                selected={date}
                                                                onChange={handleDateChange}
                                                                dateFormat="d MMM yyyy"
                                                            />
                                                        </td>
                                                        <td>
                                                            <DatePicker
                                                                selected={date}
                                                                onChange={handleDateChange}
                                                                dateFormat="d MMM yyyy"
                                                            />
                                                        </td>
                                                        <td>
                                                            <div className="dk-userImg">
                                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5a701dc2182ac.png" className="img-fluid img-circle" alt="" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="dk-userImg">
                                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/1582733069.jpeg" className="img-fluid img-circle" alt="" />
                                                            </div>
                                                        </td>
                                                        <td>Developer</td>
                                                        <td><span className="numberText" onClick={AddNewNumber}>6</span></td>
                                                        <td><span className="numberText" onClick={AddNewNumber}>10</span></td>
                                                        <td><span className="numberText" onClick={AddNewNumber}>4</span></td>
                                                        <td><span className="numberText" onClick={AddNewNumber}>8</span></td>
                                                        <td>Module</td>
                                                        <td>
                                                            <div className="dk-completionDropdown">
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="" id="dropdown-basic" style={{ background: '#3a9469', color: '#fff' }}>
                                                                        Completed
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item href="#" style={{ background: '#26597d', color: '#fff' }}>To Start</Dropdown.Item>
                                                                        <Dropdown.Item href="#" style={{ background: '#ffa500', color: '#333' }}>In Progress</Dropdown.Item>
                                                                        <Dropdown.Item href="#" style={{ background: '#3a9469', color: '#fff' }}>Complete</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="dk-action">
                                                                <Link to="#" title="Edit" onClick={EditAddNew} className="dk-editBtn"><i className="far fa-edit"></i></Link>

                                                                <Link to="#" title="Delete" onClick={ViewDeleteModal} className="dk-deleteBtn"><i className="far fa-trash-alt"></i></Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default TrainingStatus;

