import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData,DeleteData } from "../../services";

export const reorderCashOut = createAsyncThunk(
    'cashflow/cashout/cash-reorder',
    async ({ids}, thunkAPI) => {
        const res = await PostData(true, '/accounting/cashflow/cashOutReorder', {ids})
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const reorderIncomCash = createAsyncThunk(
    'cashflow/incom/cash-reorder',
    async ({ids}, thunkAPI) => {
        const res = await PostData(true, '/accounting/cashflow/reorder', {ids})
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const cashoutRowListTable = createAsyncThunk(
    'cashflow/cashoutRow/list',
    async ({id},thunkAPI) => {
        const res = await GetData(true, '/accounting/cashflow/cashoutrows/'+id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const cashoutListTable = createAsyncThunk(
    'cashflow/cashoutHeading/list',
    async ({year},thunkAPI) => {
        const res = await GetData(true, '/accounting/cashflow/cashout-rows/'+year)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const listTable = createAsyncThunk(
    'cashflow/list',
    async ({year},thunkAPI) => {
        const res = await GetData(true, '/accounting/cashflow/'+year)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const addExpendatureValue = createAsyncThunk(
    'cashflow/expendature/add-new',
    async ({year,pid}, thunkAPI) => {
        const res = await PostData(true, '/accounting/cashflow/cashout', {year,pid})
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const addValue = createAsyncThunk(
    'cashflow/add-new',
    async ({year,tableName}, thunkAPI) => {
        const res = await PostData(true, '/accounting/cashflow', {year,tableName})
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const updateExpendatureByKey = createAsyncThunk(
    'cashflow/updateexpendature/key-value',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/accounting/cashflow/update-expndature/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateByKey = createAsyncThunk(
    'cashflow/update/key-value',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/accounting/cashflow/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const deleteFun = createAsyncThunk(
    'cashflow/count/delete',
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, '/accounting/cashflow/'+id )
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);



const listSlice = createSlice({
    name: 'cashflowlist',
    initialState: {
        cashflowList: [],
        cashoutRowList: [], 
        cashoutHeadingList: [], 
        isLoading: false,
        isCashflowLoading: false,
        isDetailLoading: false,
        isTaskInsert: false,
        isTaskUpdate: false,
        isTaskDelete: false,
        isUpdate: false,
        iscommentCountUpdate: false,
        isError: false,
        isSuccess: false,
        isDataMoveToPayment: false,
        isInsert: false,
        isDelete: false,
        isMoved: false,
        isCashOutReorder: false,
        isReorder: false,
        isArchive: false,
        message: ''
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isDelete = false;
            state.isLoading = false;
            state.isCashflowLoading = false;
            state.isDetailLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.issSuccess = false;
            state.isDataMoveToPayment = false;
            state.isInsert = false;
            state.isMoved = false;
            state.isCashOutReorder = false; 
            state.isReorder = false; 
            state.isArchive = false; 
            state.message = '';
        },
        clearTaskState: (state) => {
            state.isError = false;
            state.isCashOutReorder = false;
            state.isReorder = false;
            state.isLoading = false;
            state.isCashflowLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.isInsert = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },       
        bulkDeleteState: (state) => {
            state.isDelete = true
        }
    },
    extraReducers: (builder) => {

        builder.addCase(
            reorderCashOut.pending, (state) => {        
            });
        builder.addCase(
            reorderCashOut.fulfilled, (state, { payload }) => {
                state.isCashOutReorder = true;
            });
        builder.addCase(
            reorderCashOut.rejected, (state, payload) => {
                state.isError = payload.message.msgError;
                state.errorMessage = payload.message;
                state.isCashOutReorder = false;
            });

        builder.addCase(
            reorderIncomCash.pending, (state) => {        
            });
        builder.addCase(
            reorderIncomCash.fulfilled, (state, { payload }) => {
                state.isReorder = true;
            });
        builder.addCase(
            reorderIncomCash.rejected, (state, payload) => {
                state.isError = payload.message.msgError;
                state.errorMessage = payload.message;
                state.isReorder = false;
            });

        builder.addCase(
            cashoutRowListTable.pending, (state) => {
                state.isLoading = true;
                state.issSuccess = false;
            });
        builder.addCase(
            cashoutRowListTable.fulfilled, (state, { payload }) => {
                state.cashoutRowList = payload.result
                state.isLoading = false;
                state.issSuccess = true;
            });
        builder.addCase(
            cashoutRowListTable.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            cashoutListTable.pending, (state) => {
                state.isLoading = true;
                state.isCashflowLoading = true;
                state.issSuccess = false;
            });
        builder.addCase(
            cashoutListTable.fulfilled, (state, { payload }) => {
                state.cashoutHeadingList = payload.result
                state.isLoading = false;
                state.isCashflowLoading = false;
                state.issSuccess = true;
            });
        builder.addCase(
            cashoutListTable.rejected, (state, action) => {
                state.isLoading = false;
                state.isCashflowLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });


        builder.addCase(
            listTable.pending, (state) => {
                state.isLoading = true;
                state.isCashflowLoading = true;
                state.issSuccess = false;
            });
        builder.addCase(
            listTable.fulfilled, (state, { payload }) => {
                state.cashflowList = payload.result
                state.isLoading = false;
                state.isCashflowLoading = false;
                state.issSuccess = true;
            });
        builder.addCase(
            listTable.rejected, (state, action) => {
                state.isLoading = false;
                state.isCashflowLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addValue.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            addValue.fulfilled, (state, { payload }) => {
                // state.cashflowList.push(payload.result);
                // state.isInsert = true;                
                state.cashflowList.unshift(payload.result)
                state.isLoading = false;
                state.isInsert = true;
            });
        builder.addCase(
            addValue.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
       
        builder.addCase(
            addExpendatureValue.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            addExpendatureValue.fulfilled, (state, { payload }) => {                            
                state.cashoutHeadingList.unshift(payload.result)
                state.isLoading = false;
                state.isInsert = true;
            });
        builder.addCase(
            addExpendatureValue.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
       
       
        builder.addCase(
            updateByKey.pending, (state) => {
                state.isUpdate = false
                state.isLoading = false;
            });
        builder.addCase(
            updateByKey.fulfilled, (state, { payload }) => {
                let mainArray = state.cashflowList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.cashflowList = finalResult;
                state.message = payload.message
            });
        builder.addCase(
            updateByKey.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });    
            
        builder.addCase(
            updateExpendatureByKey.pending, (state) => {
                state.isUpdate = false
                state.isLoading = false;
            });
        builder.addCase(
            updateExpendatureByKey.fulfilled, (state, { payload }) => {
                let mainArray = state.cashoutHeadingList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.cashoutHeadingList = finalResult;
                state.message = payload.message
            });
        builder.addCase(
            updateExpendatureByKey.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });    
            

        builder.addCase(
            deleteFun.pending, (state) => {
                state.isTaskDelete = false;
            });
        builder.addCase(
            deleteFun.fulfilled, (state, { payload }) => {
                state.isTaskDelete = true;
                state.isMoved = true;
            });
        builder.addCase(
            deleteFun.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });       
    
    }
});


export const { clearState, clearTaskState, bulkDeleteState,clearDetails } = listSlice.actions;
export default listSlice.reducer;