import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import "./myacc-feedback.scss";
//import Switch from './Switch'
import { getCMS, submitQuery, clearState } from "../../../../../slice/cmsSlice";
import {
  showError,
  clearMessage,
  showSuccess,
} from "../../../../../slice/utils/message/messageSlice";

const MyaccFeedback = () => {
  const dispatch = useDispatch();
  const { isSuccess, cmsList, isError, isInsert, msg, isLoading } = useSelector(
    (state) => state.cmsdata
  );
  const { userInfo } = useSelector((state) => state.auth);
  const [candidateName, setCandidateName] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [candidatePhone, setCandidatePhone] = useState("");
  const [candidateSubject, setCandidateSubject] = useState("");
  const [candidateMessage, setCandidateMessage] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [JobInstuctiondata, setJobInstuctiondata] = useState("");

  useEffect(() => {
    dispatch(getCMS({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setJobInstuctiondata(cmsList[2]);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      dispatch(showError({ msg: msg }));
      setLoading(false);
      setSuccess(false);
    }
    if (isInsert && !isLoading) {
      //setChecked(false);
      setCandidateName("");
      setCandidateEmail("");
      //setCandidatePhone("");
      setCandidateSubject("");
      setCandidateMessage("");
      dispatch(clearState());
      dispatch(showSuccess({ msg: msg }));
      setLoading(false);
      setSuccess(false);
    }
  }, [isError, isInsert]);

  const SubmitBtn = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);

    if (
      !checked ||
      !candidateName ||
      !candidateEmail ||
      !candidateSubject ||
      !candidateMessage
    ) {
      dispatch(showError({ msg: "All fields are required." }));
      setLoading(false);
    } else {
      try {
        setLoading(true);
        await dispatch(
          submitQuery({
            canid: userInfo.canId,
            terms: checked,
            name: candidateName,
            from: candidateEmail,
            phone: candidatePhone,
            subject: candidateSubject,
            message: candidateMessage,
          })
        );
      } catch (err) {
        setSuccess(false);
        setLoading(false);
      }
    }
  };

  return (
    <>
      {/* <div className='dkg-feedback-mainCon'>
            <div className="dkg-feedback-container container">
                <div className="row dkg-feedback-mainRow">
                    <div className="col-12 dkg-feedback-mainCol">
                        <h1 className='dkg-feedback-title'><span>GIVE US FEEDBACK</span></h1>
                        <p>This is test deepak</p>
                        <div className="card dkg-feedback-card">
                            <div className="row dkg-feedback-cardRow">
                                <div className="col-12 dkg-feedback-cardCol">
                                    <form>
                                        <div className="form-group dkg-feedback-frmgrp">
                                            <input placeholder="Your Name" className="form-control" />
                                        </div>
                                        <div className="form-group dkg-feedback-frmgrp">
                                            <input placeholder="Your Email" className="form-control" />
                                        </div>
                                        <div className="form-group dkg-feedback-frmgrp">
                                            <input placeholder="Subject" className="form-control" />
                                        </div>
                                        <div className="form-group dkg-feedback-frmgrp">
                                            <textarea placeholder="Feedback" className="form-control"></textarea>
                                        </div>
                                        <div className='dkg-priacy-trmsCon'>
                                            <p>
                                                <input type="checkbox" className="cusbox" />
                                                I agree to the
                                                <Link to="/terms-of-use" className="" >Terms of Use</Link>
                                                and
                                                <Link to="/privacy-policy" className="" >Privacy policy</Link>
                                            </p>
                                        </div>
                                        <div className="dkg-feedback-btnCon">
                                            <button type="button" className="btn btn-light dkg-feedback-btn">Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
      <div className="container-fluid dkg-myacc-noti-mainpageCon">
        <div className="row dkg-myacc-noti-mainRow">
          <div className="col-11 m-auto dkg-myacc-noti-mainCol">
            <div className="row dkg-myacc-noti-subRow">
              {/* <div className="col-md-4 dkg-myacc-noti-leftCon">
                            <div className='dkg-reset-infoLeftCard'>
                                <p  className="mb-0">Please use this form for any kind of technical queries.
                                    For example if any function is not working or site loading late etc.
                                    You can always do basic troubleshooting steps before counting us.
                                </p>
                            </div>
                        </div> */}
              <div className="col-md-8 m-auto dkg-myacc-noti-rightCon">
                <div className="dkg-myacc-noti-cardbody">
                  <h5 className="dkg-myacc-noti-title"> Give Feedback</h5>
                  <p className="mb-2 Em-tx">
                    {JobInstuctiondata != ""
                      ? JobInstuctiondata.description
                      : ""}
                  </p>
                  <div className="dkg-myfeedback-formCon">
                    <div className="dkg-feedback-card">
                      <div className="row dkg-feedback-cardRow">
                        <div className="col-12 dkg-feedback-cardCol p-0">
                          <form>
                            <div className="form-group dkg-feedback-frmgrp">
                              <label
                                htmlFor="your-name"
                                className="dkg-feedback-label"
                              >
                                Your Name
                              </label>
                              <input
                                placeholder=""
                                className="form-control"
                                value={candidateName}
                                onChange={(e) =>
                                  setCandidateName(e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group dkg-feedback-frmgrp">
                              <label
                                htmlFor="your-email"
                                className="dkg-feedback-label"
                              >
                                Your Email
                              </label>
                              <input
                                placeholder=""
                                className="form-control"
                                value={candidateEmail}
                                onChange={(e) =>
                                  setCandidateEmail(e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group dkg-feedback-frmgrp">
                              <label
                                htmlFor="subject"
                                className="dkg-feedback-label"
                              >
                                Subject
                              </label>
                              <input
                                placeholder=""
                                className="form-control"
                                value={candidateSubject}
                                onChange={(e) =>
                                  setCandidateSubject(e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group dkg-feedback-frmgrp">
                              <label
                                htmlFor="feedback"
                                className="dkg-feedback-label"
                              >
                                Message
                              </label>
                              <textarea
                                placeholder=""
                                className="form-control"
                                value={candidateMessage}
                                onChange={(e) =>
                                  setCandidateMessage(e.target.value)
                                }
                              ></textarea>
                            </div>
                            <div className="dkg-priacy-trmsCon">
                              <p>
                                <input
                                  type="checkbox"
                                  className="cusbox"
                                  defaultChecked={checked}
                                  onChange={() => setChecked((state) => !state)}
                                />
                                I agree to the
                                <Link
                                  to="/js-account/settings/terms-of-use"
                                  className=""
                                >
                                  Terms of Use
                                </Link>
                                and
                                <Link
                                  to="/js-account/settings/privacy"
                                  className=""
                                >
                                  Privacy policy
                                </Link>
                              </p>
                            </div>
                            <div className="dkg-feedback-btnCon">
                              <button
                                type="button"
                                className="btn btn-light dkg-feedback-btn"
                                loading={loading}
                                success={success}
                                onClick={SubmitBtn}
                              >
                                Send
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyaccFeedback;
