import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./teamroles.scss";

const CommonProcess = () => {
    return (
        <>

            <div className="dk-rolesresponPageMain dk-trainingTeamRolesMain">
                <div className="dk-pageheader">
                    <div className="dk-backBtn-cols">
                        <Link to="/training/commontraining/common-training"><i className="fas fa-arrow-left"></i> Go Back</Link>
                    </div>
                    <h3 className="title text-center">TEAMS & ROLES SUMMARY</h3>
                    <div className="dk-teamrolerightBtnCon">
                        <div className="dk-teamroleseditBtn">
                            <Link to="/training/commontraining/edit-process"><i className="far fa-edit"></i> Edit</Link>
                        </div>
                    </div>
                </div>
                <div className="dk-rolesresponTableCon table-responsive">
                    <table className="table dk-rolesresponTable table-bordered">
                        <thead className="dk-rolesTeamHeader">
                            <tr>
                                <th></th>
                                <th>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 1</div>
                                    <div className="dk-rolesTeamHeaderTitle">RESOURCE ADMINS - RD</div>
                                </th>
                                <th colSpan={2}>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 2</div>
                                    <div className="dk-rolesTeamHeaderTitle">RECRUITMENT ADMINS - RA</div>
                                </th>
                                <th>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 3</div>
                                    <div className="dk-rolesTeamHeaderTitle">RECRUITMENT CONSULTANT - RC</div>
                                </th>
                                <th>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 4</div>
                                    <div className="dk-rolesTeamHeaderTitle">ACCOUNT MANAGERS - AM</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>TEAM LEADER</td>
                                <td>
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/1623316236.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td colSpan={2} className="team2BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team3BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team4BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>TEAM</td>
                                <td>
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c3a2270ae02e.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c3ad279ec.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/1638604796.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td colSpan={2} className="team2BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5e54fdac9c.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team3BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5e54fdac9c.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team4BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>SUMMARY</td>
                                <td><p>CREATE NEW LEADS</p></td>
                                <td><p>FILTER NEW LEADS</p></td>
                                <td><p>SCREEN THE LEADS</p></td>
                                <td><p>QUALIFY THE LEADS</p></td>
                                <td><p>CLOSE THE LEADS</p></td>
                            </tr>
                            <tr>
                                <td>MANAGE</td>
                                <td><p>NEW CV'S PIPELINE</p></td>
                                <td><p>SELECTED PIPELINE</p></td>
                                <td><p>SCREENING PIPELINE</p></td>
                                <td><p>RECRUITERS PIPELINE</p></td>
                                <td><p>AM PIPELINE</p></td>
                            </tr>
                            <tr>
                                <td>ACTIONS</td>
                                <td><p>NO EMAIL/SMS/CALL</p></td>
                                <td><p>EMAIL/SMS ONLY</p></td>
                                <td><p>EMAIL /SMS /CALL</p></td>
                                <td><p>EMAIAL /SMS /CALL</p></td>
                                <td><p>EMAIL /SMS /CALL</p></td>
                            </tr>
                            <tr>
                                <td>CLEARANCE</td>
                                <td><p>MOVE TO SELECTED</p></td>
                                <td><p>MOVE TO SCREENING</p></td>
                                <td><p>MOVE TO RECRUITERS</p></td>
                                <td><p>MOVE TO AM PIPELINE</p></td>
                                <td><p>MOVE TO OFFER PIPELINE</p></td>
                            </tr>
                            <tr>
                                <td>RESPONSIBILITIES</td>
                                <td>
                                    <p>TASK 1 - MANAGE ADS</p>
                                    <p>TASK 2 - AD CAMPAIGN</p>
                                    <p>TASK 3 - ADS TRACKER</p>
                                    <p>TASK 4 - ADS BUDGET</p>
                                    <p>TASK 5 - PIPELINES KPI</p>
                                    <p>TASK 6 - MANAGE JOBS</p>
                                    <p>TASK 7 - MANAGE APPS</p>
                                    <p>TASK 8 - CREATE SPECS</p>
                                    <p>TASK 9 - SOCIAL MEDIA</p>
                                    <p>TASK 10 - DKG DATABASE</p>
                                </td>
                                <td>
                                    <p>TASK 1 - UPDATE PROFILES</p>
                                    <p>TASK 2 - MATCH WITH JOBS</p>
                                    <p>TASK 3 - ASSIGN PRIORITY</p>
                                    <p>TASK 4 - MINI JOBS SPECS+SMS</p>
                                    <p>TASK 5 - PRIORITY SCREENING</p>
                                </td>
                                <td>
                                    <p>TASK 6 - SEND REMINDER</p>
                                    <p>TASK 7 - NOT REACHABLE</p>
                                    <p>TASK 8 - SEARCH DATABASE</p>
                                    <p>TASK 9 - SEND BULK EMAILS</p>
                                    <p>TASK 10 - CASE DECISION</p>
                                </td>
                                <td>
                                    <p>TASK 1 - CASE QUERIES</p>
                                    <p>TASK 2 - INTERVIEW CALL</p>
                                    <p>TASK 3 - UPDATE PROFILE</p>
                                    <p>TASK 4 - FULL JOB SPEC</p>
                                    <p>TASK 5 - MAKE SENDOUT</p>
                                    <p>TASK 6 - CLIENTS PORTAL</p>
                                    <p>TASK 7 - NOT REACHABLE</p>
                                    <p>TASK 8 - LIVE NEW QUERY</p>
                                    <p>TASK 9 - CREATE JOB SPEC</p>
                                    <p>TASK 10 - CASE DECISION</p>
                                </td>
                                <td>
                                    <p>TASK 1 - REVIEW SEND OUT</p>
                                    <p>TASK 2 - SUBMIT SEND OUT</p>
                                    <p>TASK 3 - SEND INTERVIEW PREP</p>
                                    <p>TASK 4 - SHEDULE INTERVIEWS</p>
                                    <p>TASK 5 - PREPARE INTERVIEWS</p>
                                    <p>TASK 6 - ASK JOB DEMAND</p>
                                    <p>TASK 7 - CLIENTS FEEDBACK</p>
                                    <p>TASK 8 - UPDATE CANDIDATES</p>
                                    <p>TASK 9 - CLIENTS PROJECTION</p>
                                    <p>TASK 10 - CANDIDATE DOCUMENTS</p>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default CommonProcess;
