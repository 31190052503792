import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

const EditProjectModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Link to="#" className="dk-editBtn" onClick={handleShow}>
                <i className="far fa-edit"></i>
            </Link>

            <Modal className="dk-projectEditModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Projects</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="">Name of Platform</label>
                                    <label for="">
                                        <input type="text" value="Test23" name="plateformName" className="form-control" id="plateformName" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="">Login Type</label>
                                    <label for="">
                                        <select name="" id="" className="form-control">
                                            <option value="">Jobseeker Standard</option>
                                            <option value="">Jobseeker Premium</option>
                                            <option value="">Employer Standard</option>
                                            <option value="">Employer Premium</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="">Login Url</label>
                                    <label for="">
                                        <input type="text" value="https://lara-test.dkglobaljobs.com/" name="loginUrl" className="form-control" id="loginUrl" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="">User Name</label>
                                    <label for="">
                                        <input type="text" value="dktest123" name="userName" className="form-control" id="userName" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="">Password</label>
                                    <label for="">
                                        <input type="text" value="123123" name="password" className="form-control" id="password" />
                                    </label>
                                </div>
                            </div>
                            <input type="hidden" id="businessId" value="2" />
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="" className="text-center">
                                        <button onclick=" return businessEmail()" id="btnName">Update</button>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditProjectModal;
