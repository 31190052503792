import React from "react";
import BusinessOSubmenu from './menu/businessbook/2022/BusinessBookSubmenu';

const BusinessOverviewSubmenu = ({ path }) => {
    const renderSubmenu = () => {
        switch (path.split("/")[2]) {
            case 'qualified':
                return <BusinessOSubmenu />
                break;

            case 'newcase':
                return <BusinessOSubmenu />
                break;   

            case 'selected':
                return <BusinessOSubmenu />
                break;  

            case 'sendouts':
                return <BusinessOSubmenu />
                break;   
            
            case 'interviews':
                return <BusinessOSubmenu />
                break;

            case 'job-offers':
                return <BusinessOSubmenu />
                break;

            case 'joined':
                return <BusinessOSubmenu />
                break;

            case 'final-fee':
                return <BusinessOSubmenu />
                break;
            default:
                break
        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default BusinessOverviewSubmenu;