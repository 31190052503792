import React from 'react';

const TrackingJobs = () => {
  return (
    <>

    </>
  )
}

export default TrackingJobs;
