import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CommonStructureModal from './CommonStructureModal';

const CommonStructure = () => {
    const [structureModal, setStructureModal] = useState(false)
    const ShowStructureModal = () => {
        setStructureModal(true)
    }
    const HideStructureModal = () => {
        setStructureModal(false)
    }

    return (
        <React.Fragment>
            {
                structureModal ?
                    <CommonStructureModal handleClose={HideStructureModal} /> : null
            }

            <div className="dk-structureMain">
                <div className="dk-structure-head">
                    <div className="dk-backBtn-cols">
                        <Link to="/dkglobaladmin/admintraining/commontraining/common-training"><i className="fas fa-arrow-left"></i> Go Back</Link>
                    </div>
                    <h3 className="title">DK GLOBAL TEAM STRUCTURE</h3>
                </div>

                <div className="dk-structureTable">
                    <div className="dk-table-responsive">
                        <table className="table dk-structureTable table-bordered">
                            <thead>
                                <tr>
                                    <th>Position</th>
                                    <th>Levels</th>
                                    <th>Reporting To</th>
                                    <th>Department</th>
                                    <th>Summary</th>
                                    <th>Job Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Director</td>
                                    <td>Level 1</td>
                                    <td>-</td>
                                    <td>Management</td>
                                    <td>Manage Control whole company</td>
                                    <td>
                                        <button onClick={ShowStructureModal}>View Details</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Ops Manager</td>
                                    <td>Level 2</td>
                                    <td>Director</td>
                                    <td>Management</td>
                                    <td>Manage the whole company operation</td>
                                    <td>
                                        <button onClick={ShowStructureModal}>View Details</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Manager</td>
                                    <td>Level 3</td>
                                    <td>Ops Manager</td>
                                    <td>Management</td>
                                    <td>Manage all company employees</td>
                                    <td>
                                        <button onClick={ShowStructureModal}>View Details</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Team Leader</td>
                                    <td>Level 4</td>
                                    <td>Manager</td>
                                    <td>Production</td>
                                    <td>Sales Manage all employees production</td>
                                    <td>
                                        <button onClick={ShowStructureModal}>View Details</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Senior Executive</td>
                                    <td>Level 5</td>
                                    <td>Team Leader</td>
                                    <td>Production</td>
                                    <td>Sales or Admin Tasks</td>
                                    <td>
                                        <button onClick={ShowStructureModal}>View Details</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Junior Executive</td>
                                    <td>Level 6</td>
                                    <td>Team Leader</td>
                                    <td>Production</td>
                                    <td>Sales or Admin Tasks</td>
                                    <td>
                                        <button onClick={ShowStructureModal}>View Details</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Trainee/Intern</td>
                                    <td>Level 7</td>
                                    <td>Team Leader</td>
                                    <td>Production</td>
                                    <td>Support the Executives</td>
                                    <td>
                                        <button onClick={ShowStructureModal}>View Details</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CommonStructure;

