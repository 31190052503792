import React, { useState } from 'react';
import { Link } from "react-router-dom";
import AppsManagement from "../../../../assets/images/apps-management.png";
import AdsManagement from "../../../../assets/images/manage-ad-white.png";
import JobsManagementImg from "../../../../assets/images/jobs-management.png";
import AdCampaignImg from "../../../../assets/images/ad-campaign.png";
import MarketingManagementImg from "../../../../assets/images/mailbox-management.png";
import JobApplicationImg from "../../../../assets/images/resume_white.png";
import PreparationImg from "../../../../assets/images/preparation_icon.png";
import ActionImg from "../../../../assets/images/action_icon.png";
import PerformanceImg from "../../../../assets/images/performance_white.png";
import KnowledgeImg from "../../../../assets/images/knowledge-base.png";
import AdsTrackerImg from "../../../../assets/images/graph-white.png";
import AdsBudgetImg from "../../../../assets/images/ads-budget.png";
import PipelineKpi from "../../../../assets/images/pipeline-kpi.png";
import SocialMediaImg from "../../../../assets/images/social-media.png";
import DkgDatabaseImg from "../../../../assets/images/database_icon.png";
import JobDetailsImg from "../../../../assets/images/user-white.png";
import './resource.scss';

const TrainingResource = () => {
    return (
        <>
            <div className="dk-adminsTrainingMain dk-commonResourceMain">
                <div className="container-fluid">
                    <div className="dk-trainingMainCols dk-md-Container dk-trainingAdminCols">
                        <h3 className="title">TEAM 1 - RESOURCE ADMINS</h3>
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86' }}>
                                    <Link to="/training/training-resource/job-details">
                                        <figure>
                                            <img src={JobDetailsImg} />
                                        </figure>
                                        <h3>Job Details</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86' }}>
                                    <Link to="/training/training-resource/preparation">
                                        <figure>
                                            <img src={PreparationImg} />
                                        </figure>
                                        <h3>Preparation</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86' }}>
                                    <Link to="/training/training-resource/action">
                                        <figure>
                                            <img src={ActionImg} />
                                        </figure>
                                        <h3>Action</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86' }}>
                                    <Link to="/training/training-resource/performance">
                                        <figure>
                                            <img src={PerformanceImg} />
                                        </figure>
                                        <h3>Performance</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86' }}>
                                    <Link to="/training/training-resource/knowledge-base">
                                        <figure>
                                            <img src={KnowledgeImg} />
                                        </figure>
                                        <h3>Knowledge Base</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86' }}>
                                    <Link to="/training/training-resource/manage-jobs">
                                        <figure>
                                            <img src={JobsManagementImg} />
                                        </figure>
                                        <h3>Task 1 - Manage Jobs</h3>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86' }}>
                                    <Link to="/training/training-resource/create-specs">
                                        <figure>
                                            <img src={MarketingManagementImg} />
                                        </figure>
                                        <h3>Task 2 - Create Job Spec</h3>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86' }}>
                                    <Link to="/training/training-resource/manage-ads">
                                        <figure>
                                            <img src={AdsManagement} />
                                        </figure>
                                        <h3>Task 3 - Manage Ads</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86' }}>
                                    <Link to="/training/training-resource/apps-management">
                                        <figure>
                                            <img src={JobApplicationImg} />
                                        </figure>
                                        <h3>Task 4 - Job Applications</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86' }}>
                                    <Link to="/training/training-resource/ads-tracker">
                                        <figure>
                                            <img src={AdsTrackerImg} />
                                        </figure>
                                        <h3>Task 5 - Ads Tracker</h3>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86' }}>
                                    <Link to="/training/training-resource/ads-budget">
                                        <figure>
                                            <img src={AdsBudgetImg} />
                                        </figure>
                                        <h3>Task 6 - Ads Budget</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86' }}>
                                    <Link to="/training/training-resource/pipelines-kpi">
                                        <figure>
                                            <img src={PipelineKpi} />
                                        </figure>
                                        <h3>Task 7 - Candidates KPI</h3>
                                    </Link>
                                </div>
                            </div>


                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86' }}>
                                    <Link to="/training/training-resource/ad-campaign">
                                        <figure>
                                            <img src={AdCampaignImg} />
                                        </figure>
                                        <h3>Task 8 - Campaigns</h3>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86' }}>
                                    <Link to="/training/training-resource/social-media">
                                        <figure>
                                            <img src={SocialMediaImg} />
                                        </figure>
                                        <h3>Task 9 - Social Media</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86' }}>
                                    <Link to="/training/training-resource/dkg-database">
                                        <figure>
                                            <img src={DkgDatabaseImg} />
                                        </figure>
                                        <h3>Task 10 - DKG Database</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86', minHeight: '124px' }}>
                                    <Link to="#">
                                        <figure>

                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86', minHeight: '124px' }}>
                                    <Link to="#">
                                        <figure>

                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86', minHeight: '124px' }}>
                                    <Link to="#">
                                        <figure>

                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86', minHeight: '124px' }}>
                                    <Link to="#">
                                        <figure>

                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#5f7b86', minHeight: '124px' }}>
                                    <Link to="#">
                                        <figure>

                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrainingResource;
