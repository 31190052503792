import React from 'react';
import FilipProjectTrainingLeftPanel from "../leftpanel/filip/FilipProjectTrainingLeftPanel";
import '../moduleprojects.scss';

const FilipProjectTraining = () => {
    return (
        <>
            <div className="dk-moduleProjects-main">
                <div className="dk-moduleTraining-cols">
                    <FilipProjectTrainingLeftPanel />
                    <div className="dk-moduleTraining-rightPanel">
                        <div className="title">Summary</div>
                        <div className="dk-moduleTraining-rightCols">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FilipProjectTraining;
