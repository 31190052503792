import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import "./legalmodule.scss";
import Moduleheader from "../../elements/header/index";
import LegalDocs from '../../../assets/images/legal-doc.png';
import TaxIcon from '../../../assets/images/vatfile.png';
import DepIcon from '../../../assets/images/get-money.png';
import DirectorIcon from '../../../assets/images/juniorrecruiter_white.png';
import Contracts from '../../../assets/images/contracts.png';
import EuroIcon from '../../../assets/images/euro.png';

import { setMyModule } from "../../../slice/modules/moduleSlice";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError } from "../../../components/utils/messages/messageSlice";

const LegalModule = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        document.title = "DK Global Finance"
    }, []);

    const { userInfo } = useSelector(state => state.auth);

    const setModule = async (myModule, moduleColor) => {
        await dispatch(setMyModule({ module: myModule, color: moduleColor }))

        if (userInfo.role === "User" || userInfo.role === "Admin") {

            dispatch(showError({ msg: 'You are not authorized to view' }))

        } else {

            switch (myModule) {
                case 'contracts':
                    history.push("/dkglobalfinance/contracts/summary");
                    break;

                case 'legal':
                    history.push("/dkglobalfinance/legal/dashboard");
                    break;

                case 'vatfiles':
                    history.push("/dkglobalfinance/vatfiles/summary");
                    break;

                case 'debt':
                    history.push("/legalmodule/debt/dashboard");
                    break;

                case 'legal-director':
                    history.push("/legalmodule/legal-director/dashboard");
                    break;

                default:
                    break;
            }
        }
    }
    return (
        <>
            <Moduleheader dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="B OVERVIEW" moduleLink="/dashboard/manage-ment" linkcolor="#3c4c62" mainheaderTitle=" - Legal" textcolor="#535255" />
            <div className="dk-legalModule-main">
                <div className="dk-legalModule-container">
                    <h3 className="title mb-4">Legal Department</h3>
                    <div className="d-flex">
                        <div className="dk-legalBox">
                            <Link to="#" onClick={() => setModule('legal-director')} style={{ background: '#535255' }}>
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={DirectorIcon}
                                            alt="LegalDocs"
                                        />
                                    </div>
                                    <div className="dk-legalTitle">Director</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-legalBox">
                            <Link to="#" onClick={() => setModule('debt')} style={{ background: '#535255' }}>
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={DepIcon}
                                            alt="LegalDocs"
                                        />
                                    </div>
                                    <div className="dk-legalTitle">Debt</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-legalBox">
                            <Link to="#" style={{ background: '#535255' }}>
                                <div className="dk-Portalicons">
                                    <img
                                        src={EuroIcon}
                                        alt="LegalDocs"
                                    />
                                </div>
                                <div className="dk-legalTitle">Budgets</div>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="dk-legalBox">
                            <Link to="#" onClick={() => setModule('legal')} style={{ background: '#535255' }}>
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={LegalDocs}
                                            alt="LegalDocs"
                                        />
                                    </div>
                                    <div className="dk-legalTitle">Company Docs</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-legalBox">
                            <Link to="#" onClick={() => setModule('contracts')} style={{ background: '#535255' }}>
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={Contracts}
                                            alt="LegalDocs"
                                        />
                                    </div>
                                    <div className="dk-legalTitle">Contracts</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-legalBox">
                            <Link to="#" onClick={() => setModule('vatfiles')} style={{ background: '#535255' }}>
                                <div>
                                    <div className="dk-Portalicons">
                                        <img
                                            src={TaxIcon}
                                            alt="LegalDocs"
                                        />
                                    </div>
                                    <div className="dk-legalTitle">Tax Returns</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LegalModule;