import React, { useState } from 'react';
import { Link } from "react-router-dom";
import SubmitSendoutImg from "../../../../../assets/images/mailbox-management.png";
import ReviewImg from "../../../../../assets/images/review-white.png";
import JobDetailsImg from "../../../../../assets/images/user-white.png";
import PreparationImg from "../../../../../assets/images/preparation_icon.png";
import ActionImg from "../../../../../assets/images/action_icon.png";
import DemandImg from "../../../../../assets/images/demand_white.png";
import FeedbackImg from "../../../../../assets/images/feedback_white.png";
import JoiningImg from "../../../../../assets/images/joining_white.png";
import PerformanceImg from "../../../../../assets/images/performance_white.png";
import KnowledgeImg from "../../../../../assets/images/knowledge-base.png";
import SendInterviewImg from "../../../../../assets/images/send-interview.png";
import SheduleInterviewImg from "../../../../../assets/images/shedule_white.png";
import PrepareInterviewsImg from "../../../../../assets/images/prepare-interview.png";
import AskNewJobDemandImg from "../../../../../assets/images/job-demand-white.png";
import CandidateDocumentImg from "../../../../../assets/images/candidate-document.png";
import './managers.scss';

const Managers = () => {
    return (
        <>
            <div className="dk-managersTrainingMain dk-managersTrainingContainer">
                <div className="container-fluid">
                    <div className="dk-trainingMainCols dk-md-Container dk-trainingAdminCols">
                        <h3 className="title">TEAM 4 - INTERVIEWS MANAGER</h3>
                        <div className="row justify-content-center">

                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F' }}>
                                    <Link to="/dkglobaladmin/admintraining/managers/job-details">
                                        <figure>
                                            <img src={JobDetailsImg} />
                                        </figure>
                                        <h3>Job Details</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F' }}>
                                    <Link to="/dkglobaladmin/admintraining/managers/preparation">
                                        <figure>
                                            <img src={PreparationImg} />
                                        </figure>
                                        <h3>Preparation</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F' }}>
                                    <Link to="/dkglobaladmin/admintraining/managers/action">
                                        <figure>
                                            <img src={ActionImg} />
                                        </figure>
                                        <h3>Action</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F' }}>
                                    <Link to="/dkglobaladmin/admintraining/managers/performance">
                                        <figure>
                                            <img src={PerformanceImg} />
                                        </figure>
                                        <h3>Performance</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F' }}>
                                    <Link to="/dkglobaladmin/admintraining/managers/knowledge-base">
                                        <figure>
                                            <img src={KnowledgeImg} />
                                        </figure>
                                        <h3>Knowledge Base</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F' }}>
                                    <Link to="/dkglobaladmin/admintraining/managers/review-sendout">
                                        <figure>
                                            <img src={ReviewImg} />
                                        </figure>
                                        <h3>Task 1 - Review Send Out</h3>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F' }}>
                                    <Link to="/dkglobaladmin/admintraining/managers/submit-sendout">
                                        <figure>
                                            <img src={SubmitSendoutImg} />
                                        </figure>
                                        <h3>Task 2 - Submit Send Out</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F' }}>
                                    <Link to="/dkglobaladmin/admintraining/managers/shedule-interviews">
                                        <figure>
                                            <img src={SheduleInterviewImg} />
                                        </figure>
                                        <h3>Task 3 - Shedule Interview</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F' }}>
                                    <Link to="/dkglobaladmin/admintraining/managers/send-interview-prep">
                                        <figure>
                                            <img src={SendInterviewImg} />
                                        </figure>
                                        <h3>Task 4 - Send Interview prep</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F' }}>
                                    <Link to="/dkglobaladmin/admintraining/managers/prepare-interviews">
                                        <figure>
                                            <img src={PrepareInterviewsImg} />
                                        </figure>
                                        <h3>Task 5 - Prepare Interviews</h3>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F' }}>
                                    <Link to="/dkglobaladmin/admintraining/managers/ask-job-demand">
                                        <figure>
                                            <img src={AskNewJobDemandImg} />
                                        </figure>
                                        <h3>Task 6 - Ask Jobs Demand</h3>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F' }}>
                                    <Link to="/dkglobaladmin/admintraining/managers/candidates-feedback">
                                        <figure>
                                            <img src={FeedbackImg} />
                                        </figure>
                                        <h3>Task 7 - Candidate Feedback</h3>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F' }}>
                                    <Link to="/dkglobaladmin/admintraining/managers/update-candidates">
                                        <figure>
                                            <img src={JoiningImg} />
                                        </figure>
                                        <h3>Task 8 - Update Candidate</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F' }}>
                                    <Link to="/dkglobaladmin/admintraining/managers/clients-projection">
                                        <figure>
                                            <img src={DemandImg} />
                                        </figure>
                                        <h3>Task 9 - Clients Projections</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F' }}>
                                    <Link to="/dkglobaladmin/admintraining/managers/job-offer-document">
                                        <figure>
                                            <img src={CandidateDocumentImg} />
                                        </figure>
                                        <h3>Task 10 - Job Offer Documents</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F', minHeight: '124px' }}>
                                    <Link to="#">
                                        <figure>

                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F', minHeight: '124px' }}>
                                    <Link to="#">
                                        <figure>

                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F', minHeight: '124px' }}>
                                    <Link to="#">
                                        <figure>

                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F', minHeight: '124px' }}>
                                    <Link to="#">
                                        <figure>

                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#007D7F', minHeight: '124px' }}>
                                    <Link to="#">
                                        <figure>

                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Managers;
