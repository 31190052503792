import React from 'react';
import "./pipelinepagination.scss";

const PipelinePagination = () => {
    return (
        <>
            <div className="dk-pipepaginationCon">
                <ul>
                    <li>Showing 1 to 13 (of 13 entries)</li>
                </ul>
            </div>
        </>
    )
}

export default PipelinePagination;
