import React from 'react';
const ProfileStatus = ({ value }) => {
    let bgColor;
    let txtColor;

    switch (value) {
        case 'Open':
            bgColor = '#30887E'
            txtColor = '#fff'
            break;

        case 'Closed':
            bgColor = '#da3d5e'
            txtColor = '#fff'
            break;
        default:
            bgColor = '#497f62'
            txtColor = '#fff'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor, color: txtColor }}>{value}</div>
        </>
    )
}

export default ProfileStatus;