import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  // defaultWhyapply,
  updateLangValue,
} from "../../../../../../slice/jobSpec/jobSpecSlice";

const LanguageTab = ({ jobId, clientid }) => {
  const dispatch = useDispatch();
  const { LangRelationData, whyapplysList, isLoading, isWhyapplySuccess } =
    useSelector((state) => state.jobsSpec);

  const [selectedId, setSelectedId] = useState(null);
  const [clist, setclist] = useState([]);

  useEffect(() => {
    if (isWhyapplySuccess && whyapplysList.length > 0) {
      setSelectedId(whyapplysList[0].companyId);
    }
  }, [isWhyapplySuccess]);

  useEffect(() => {
    if (!isLoading && LangRelationData.length > 0) {
      setclist(LangRelationData);
    }
  }, [isLoading]);

  const handleChange = (id, companyname, companyidds) => {
    setSelectedId(id);
    dispatch(
      updateLangValue({
        id: id,
        key: "isShowInJob",
        value: 1,
        jobsId: jobId,
        clientId: 0,
        clientname: companyname,
        tableName: "jobs_relationship_whyapplys",
      })
    );
  };

  return (
    <>
      <div className="dkg-company-list-mainCon">
        <div className="dkg-company-list-tableCon table-responsive">
          <table className="dkg-company-list-table table table-borderded">
            <thead>
              <tr>
                <th>Language</th>
                <th>Linked</th>
              </tr>
            </thead>
            <tbody>
              {clist.map((details, index) => (
                <tr key={index + 1}>
                  <td>{details.categoryName}</td>
                  <td>
                    <span className="dkg-clientinfo-checkbox">
                      <input
                        type="radio"
                        className="form-control"
                        name="clientinfo-checkbox-whyapply"
                        data-id={details.id}
                        data-companyname={details.categoryName}
                        id={details.id}
                        checked={selectedId === details.id}
                        onChange={() =>
                          handleChange(details.id, details.categoryName)
                        }
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default LanguageTab;
