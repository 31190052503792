import React from 'react'
import  './comming-soon.scss'

const CommingPage = () => {
  return (
    <>
      <div className="dkg-comming-soonMain">
        <div className="dkg-comming-maintitle-Con">
          <h2 className='dkg-comming-maintitle'>This web page is currently under development.</h2>
          {/* <div className="dkg-comming-subtitleCon">
            <h2 className='dkg-comming-subtitle'>We will update you the progress</h2>
            <h2 className='dkg-comming-subtitle'>Thank You!</h2>
          </div> */}
          <div className="dkg-comming-soon-boxCon">
            <div className="dkg-comming-soon-boxcols">
              <div className="dkg-comming-soon-box">
                <h2 className="dkg-comming-message">
                  Coming<br />
                  <span className="dkg-span-message pl-4">Soon</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CommingPage