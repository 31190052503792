import React from "react";
import { Route, Link } from "react-router-dom";

const RecruitmentSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/dkg-global/recruitment/history" className={`${(segment3 == 'history') ? 'active' : ''}`}>History</Link></li>
                        <li><Link to="/dkg-global/recruitment/market-share/overview" className={`${(segment3 == 'market-share') ? 'active' : ''}`}>Market Share</Link></li>
                        <li><Link to="/dkg-global/recruitment/defination/overview" className={`${(segment3 == 'defination') ? 'active' : ''}`}>Definition</Link></li>
                        <li><Link to="/dkg-global/recruitment/industries/overview" className={`${(segment3 == 'industries') ? 'active' : ''}`}>Industries</Link></li>
                        <li><Link to="/dkg-global/recruitment/models/overview" className={`${(segment3 == 'models') ? 'active' : ''}`}>Models</Link></li>
                        <li><Link to="/dkg-global/recruitment/pricing/overview" className={`${(segment3 == 'pricing') ? 'active' : ''}`}>Pricing</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default RecruitmentSubmenu;
