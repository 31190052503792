import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './mainmenusubmenuc.scss';

const WorkPlanSubmenu = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain dkg-subMainMenu dk-borderNone">
                <Route>
                    <ul>
                        <li><Link to="/workplan/get-started" className={`${(segment2 == 'get-started') ? 'active' : ''}`}>Get Start</Link></li>
                        <li><Link to="/workplan/check-in" className={(segment2 == 'check-in') ? 'active' : ''}>Check In</Link></li>
                        <li><Link to="/workplan/moods" className={`${(segment2 == 'moods') ? 'active' : ''}`}>Moods</Link></li>
                        <li><Link to="/workplan/work-models/overview" className={`${(segment2 == 'work-models') ? 'active' : ''}`}>Work Models</Link></li>
                        <li><Link to="/workplan/workflow" className={`${(segment2 == 'workflow') ? 'active' : ''}`}>Workflow</Link></li>
                        <li><Link to="/workplan/requests/summary" className={`${(segment2 == 'requests') ? 'active' : ''}`}>Events</Link></li>
                        <li><Link to="/workplan/rules/dk-global" className={`${(segment2 == 'rules') ? 'active' : ''}`}>Rules</Link></li>
                        <li><Link to="/workplan/job-profile" className={`${(segment2 == 'job-profile') ? 'active' : ''}`}>Job Profile</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default WorkPlanSubmenu;
