import React from 'react';
import { Link } from 'react-router-dom';
import { FaCheck, FaComments, FaUserPlus, FaUsers, FaBriefcase, FaVideo, FaRegEdit, FaEuroSign, FaUserFriends, FaUserTie } from 'react-icons/fa';
import { BsGraphUp } from "react-icons/bs";

const ArchivedLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <ul>
                <li><Link to="/notifications/archived/my-tasks" className={`${pathname.match('/notifications/archived/my-tasks') ? 'active' : ''}`}><FaCheck /> My Tasks <span className="dk-notiBadge">0</span></Link></li>

                <li><Link to="/notifications/archived/new-case" className={`${pathname.match('/notifications/archived/new-case') ? 'active' : ''}`}><FaUserPlus /> New Case <span className="dk-notiBadge">4</span></Link></li>

                <li><Link to="/notifications/archived/comments" className={`${pathname.match('/notifications/archived/comments') ? 'active' : ''}`}><FaComments /> Comments <span className="dk-notiBadge">6</span></Link></li>

                <li><Link to="/notifications/archived/new-jobs" className={`${pathname.match('/notifications/archived/new-jobs') ? 'active' : ''}`}><FaBriefcase /> New Jobs <span className="dk-notiBadge">8</span></Link></li>

                <li><Link to="/notifications/archived/live-jobs" className={`${pathname.match('/notifications/archived/live-jobs') ? 'active' : ''}`}><FaVideo /> Live Jobs <span className="dk-notiBadge">11</span></Link></li>

                <li><Link to="/notifications/archived/ads-post" className={`${pathname.match('/notifications/archived/ads-post') ? 'active' : ''}`}><FaRegEdit /> Ads Post <span className="dk-notiBadge">12</span></Link></li>

                <li><Link to="/notifications/archived/resources" className={`${pathname.match('/notifications/archived/resources') ? 'active' : ''}`}><FaUsers /> Resources <span className="dk-notiBadge">18</span></Link></li>

                <li><Link to="/notifications/archived/payments" className={`${pathname.match('/notifications/archived/payments') ? 'active' : ''}`}><FaEuroSign /> Payments <span className="dk-notiBadge">28</span></Link></li>

                <li><Link to="/notifications/archived/sales-kpi" className={`${pathname.match('/notifications/archived/sales-kpi') ? 'active' : ''}`}><BsGraphUp /> Sales KPI <span className="dk-notiBadge">45</span></Link></li>

                <li><Link to="/notifications/archived/clients" className={`${pathname.match('/notifications/archived/clients') ? 'active' : ''}`}><FaUserFriends /> Clients <span className="dk-notiBadge">78</span></Link></li>

                <li><Link to="/notifications/archived/dk-global" className={`${pathname.match('/notifications/archived/dk-global') ? 'active' : ''}`}><FaUserTie /> DK Global <span className="dk-notiBadge">80</span></Link></li>
            </ul>
        </>
    )
}

export default ArchivedLeftPanel;
