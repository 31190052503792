import React from 'react';
import './payrolldashboard.scss';

const FinancePayrollDashboard = () => {
    return (
        <>
            <div className="dk-dkglobalFinance-payrollMain">
                <h2 className="text-center">Payroll Dashboard</h2>
            </div>
        </>
    )
}

export default FinancePayrollDashboard;