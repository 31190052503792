import React from 'react';
import SalesKpi from "./SalesKpi";
import './saleskpi.scss';

const SalesKpiMonthly = () => {
    return (
        <>
            <div className="dk-slaesKpiMonthlyMain">
                <div className="title">COMPANY ANNUAL SALE</div>
                <SalesKpi />
            </div>
        </>
    )
}

export default SalesKpiMonthly;
