import React from 'react';
import Header from "../elements/header";
import Navbar from "../elements/header/navbar/mainMenu/mainfaq/MainFaqNavbar";

const MainFaqLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header mainheaderLink="Dashboard" moduleLink="/dashboard/plateform" linkcolor="#3c4c62" mainheaderTitle=" - FAQ" textcolor="#3c4c62" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default MainFaqLayout;