import React from 'react';
import Header from "../../elements/header";
import Navbar from "../../elements/header/navbar/mainMenu/itteammenu/DesignerTasksMainMenu";

const ItTeamDesignerTasksLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="TECHNICAL -" moduleLink="/dashboard/it-teams" linkcolor="#b55355" mainheaderTitle=" Designer Task" textcolor="#b55355" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default ItTeamDesignerTasksLayout;