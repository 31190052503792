import React from 'react';
import { Link } from "react-router-dom";

const RulesleftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="dk-moduleTraining-leftPanel">
                <ul>
                    <li><Link to="/rules/dk-global" className={`${pathname.match('/rules/dk-global') ? 'active' : ''}`}>DK Global</Link></li>
                    <li><Link to="/rules/tasks" className={`${pathname.match('/rules/tasks') ? 'active' : ''}`}>Tasks</Link></li>
                    <li><Link to="/rules/clients" className={`${pathname.match('/rules/clients') ? 'active' : ''}`}>Clients</Link></li>
                    <li><Link to="/rules/candidates" className={`${pathname.match('/rules/candidates') ? 'active' : ''}`}>Candidates</Link></li>
                    <li><Link to="/rules/pipeline" className={`${pathname.match('/rules/pipeline') ? 'active' : ''}`}>Pipeline</Link></li>
                    <li><Link to="/rules/jobs" className={`${pathname.match('/rules/jobs') ? 'active' : ''}`}>Jobs</Link></li>
                    <li><Link to="/rules/recruitment" className={`${pathname.match('/rules/recruitment') ? 'active' : ''}`}>Recruitment</Link></li>
                    <li><Link to="/rules/ads" className={`${pathname.match('/rules/ads') ? 'active' : ''}`}>Ads</Link></li>
                    <li><Link to="/rules/partners" className={`${pathname.match('/rules/partners') ? 'active' : ''}`}>Partners</Link></li>
                </ul>
            </div>
        </>
    )
}

export default RulesleftPanel;
