import React, { useState, useEffect } from 'react'
import './workplansummary.scss'
import './tasksummary.scss'
//import ProgressBar from "./progress-bar/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
//import { taskSummaryList, clearState } from "../../../../slice/task/taskSlice";

const Summary = () => {
    const dispatch = useDispatch();
    const { isLoading, taskDataSummaryList } = useSelector(state => state.task);

    const [list, setList] = useState([]);

    useEffect(() => {
        document.title = "Tasks Summary | DK Global CRM"
    }, [])

    // useEffect(() => {
    //     const fetchList = async () => {
    //         try {
    //             await dispatch(taskSummaryList())
    //         } catch (err) {
    //         }
    //     }
    //     fetchList()
    // }, [dispatch])

    useEffect(() => {
        if (!isLoading && taskDataSummaryList.length > 0) {
            setList(taskDataSummaryList)
        }
    }, [taskDataSummaryList, isLoading])

    const [rcUserList, setRcUserList] = useState([]);
    const [progressList, setProgressList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [typeList, setTypeList] = useState([]);

    useEffect(() => {
        console.log(list)
        const rcResult = [];
        const rcmap = new Map();
        for (const item of list) {
            if (!rcmap.has(item.taskForUid)) {
                rcmap.set(item.taskForUid, true);
                rcResult.push({
                    id: item.taskForUid,
                    name: item.taskForFirstName,
                    userImg: item.taskForProfileIMage,
                    count: list.filter(function (val) {
                        return val.taskForUid === item.taskForUid;
                    }).length,
                    toDoCount: list.filter(function (val) {
                        return val.taskForUid === item.taskForUid && val.progress === "To Do"
                    }).length,
                    InProgressCount: list.filter(function (val) {
                        return val.taskForUid === item.taskForUid && val.progress === "In Progress"
                    }).length,
                    completeCount: list.filter(function (val) {
                        return val.taskForUid === item.taskForUid && val.progress === "Completed"
                    }).length
                });
            }
        }
        rcResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setRcUserList(rcResult)

        const progressResult = [];
        const progressmap = new Map();
        for (const item of list) {
            if (!progressmap.has(item.status)) {
                progressmap.set(item.status, true);
                progressResult.push({
                    name: (item.status != '') ? item.status : 'Blank',
                    count: list.filter(function (val) {
                        return val.status === item.status;
                    }).length
                });
            }
        }
        progressResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setProgressList(progressResult)

        const categoryResult = [];
        const categorymap = new Map();
        for (const item of list) {
            if (!categorymap.has(item.type)) {
                categorymap.set(item.type, true);
                categoryResult.push({
                    name: (item.type != '') ? item.type : 'Blank',
                    count: list.filter(function (val) {
                        return val.type === item.type;
                    }).length
                });
            }
        }
        categoryResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setCategoryList(categoryResult)


        const typeResult = [];
        const typemap = new Map();
        for (const item of list) {
            if (!typemap.has(item.taskType)) {
                typemap.set(item.taskType, true);
                typeResult.push({
                    name: (item.taskType != '') ? item.taskType : 'Blank',
                    count: list.filter(function (val) {
                        return val.taskType === item.taskType;
                    }).length
                });
            }
        }
        typeResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setTypeList(typeResult)
    }, [list])

    const testData = [
        { bgcolor: "#FFF", completed: 60, tasktype: "Personal" },
        { bgcolor: "#FFF", completed: 30, tasktype: "Others Task" },
        { bgcolor: "#FFF", completed: 53, tasktype: "Group Task" },
        { bgcolor: "#FFF", completed: 53, tasktype: "Payments" },
        { bgcolor: "#FFF", completed: 60, tasktype: "Company" },
        { bgcolor: "#FFF", completed: 30, tasktype: "Clients" },
        { bgcolor: "#FFF", completed: 53, tasktype: "Candidates" },
        { bgcolor: "#FFF", completed: 20, tasktype: "Suppliers" },
        { bgcolor: "#FFF", completed: 30, tasktype: "Mailbox" },
        { bgcolor: "#FFF", completed: 53, tasktype: "IT Bugs" },
        { bgcolor: "#FFF", completed: 60, tasktype: "Breaks" },
        { bgcolor: "#FFF", completed: 30, tasktype: "Other" }
    ];

    const [isUserFilter, setIsUserFilter] = useState(false)
    const [userFilterText, setUserFilterText] = useState('')

    const [isProgressFilter, setIsProgressFilter] = useState(false)
    const [progressFilterText, setProgressFilterText] = useState('')

    const [isStatusFilter, setIsStatusFilter] = useState(false)
    const [statusFilterText, setStatusFilterText] = useState('')

    const [isTypeFilter, setIsTypeFilter] = useState(false)
    const [typeFilterText, setTypeFilterText] = useState('')

    const [isTaskTypeFilter, setIsTaskTypeFilter] = useState(false)
    const [taskTypeFilterText, setTaskTypeFilterText] = useState('')

    const [filter, setFilter] = useState({});

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    const selectFilter = (value, name, type) => {
        switch (type) {
            case 'taskForUid':
                setIsUserFilter(true)
                setUserFilterText(name)
                setFilter({ ...filter, ['taskForUid']: value })
                break;

            case 'progress':
                setIsProgressFilter(true)
                setProgressFilterText(name)
                setFilter({ ...filter, ['progress']: value })
                break;

            case 'status':
                setIsStatusFilter(true)
                setStatusFilterText(name)
                setFilter({ ...filter, ['status']: value })
                break;

            case 'type':
                setIsTypeFilter(true)
                setTypeFilterText(name)
                setFilter({ ...filter, ['type']: value })
                break;

            case 'taskType':
                setIsTaskTypeFilter(true)
                setTaskTypeFilterText(name)
                setFilter({ ...filter, ['taskType']: value })
                break;
        }
    }

    const removeTag = (type) => {
        delete filter[type];
        switch (type) {
            case 'taskForUid':
                setIsUserFilter(false)
                setUserFilterText('')
                break;

            case 'progress':
                setIsProgressFilter(false)
                setProgressFilterText('')
                break;

            case 'status':
                setIsStatusFilter(false)
                setStatusFilterText('')
                break;

            case 'type':
                setIsTypeFilter(false)
                setTypeFilterText('')
                break;

            case 'taskType':
                setIsTaskTypeFilter(false)
                setTaskTypeFilterText('')
                break;
        }
        setFilter(filter)
        setList(filterBy(taskDataSummaryList, filter))
    }

    function filterBy(list, criteria) {
        return list.filter(candidate =>
            Object.keys(criteria).every(key =>
                candidate[key] === criteria[key]
            )
        );
    }

    useEffect(() => {
        if (isEmpty(filter)) {
            setTimeout(() => {
                setList(taskDataSummaryList)
            }, 500)
        } else {
            setList(filterBy(list, filter))
        }
    }, [filter])

    return (
        <>
            <div className='container-fluid dk-tasksuMMCon-21'>
                <div className='row dkg-tasksummboxModule'>
                    <div className='col-12'>
                        <div className='dkg-tasksummboxcols'>
                            <div className='dkg-tasksummbox dkg-todo-stauts-box-21'>
                                <h2 className='dkg-tasksummbox-title-21 dk-without-filter-header'>
                                    {
                                        (isUserFilter) ?
                                            <div className="dk-dbsuMM-filterHeader dk-with-filter-header-24">
                                                {userFilterText}
                                                <span style={{ cursor: "pointer" }}>
                                                    <i className="fa fa-times-circle ml-2" onClick={() => removeTag('taskForUid')}></i>
                                                </span>
                                            </div>
                                            :
                                            <React.Fragment>
                                                USERS <span className='dkg-total-taskcounter'>- {list.length}</span>
                                            </React.Fragment>
                                    }
                                </h2>
                                <div className='dkg-tasksummbox-header'>
                                    <table className=' table table-bordered dkg-tasks-table'>
                                        <tbody>
                                            {rcUserList.map((item, index) => (
                                                <tr key={`rcFilter${index}`}>
                                                    <td onClick={(e) => selectFilter(item.id, item.name, 'taskForUid')}>
                                                        <div className='dk-summ-recimgCon'>
                                                            <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} className='dk-summ-recimg' alt='Recruiter' />
                                                        </div>
                                                    </td>
                                                    <td onClick={(e) => selectFilter(item.id, item.name, 'taskForUid')}>
                                                        <span className='dkg-task-counter'>{item.count}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='dkg-tasksummbox dkg-todo-stauts-box-21'>
                                <h2 className='dkg-tasksummbox-title-21 dk-without-filter-header'>
                                    {
                                        (isProgressFilter) ?
                                            <div className="dk-dbsuMM-filterHeader dk-with-filter-header-24">
                                                {progressFilterText}
                                                <span style={{ cursor: "pointer" }}>
                                                    <i className="fa fa-times-circle ml-2" onClick={() => removeTag('progress')}></i>
                                                </span>
                                            </div>
                                            :
                                            <React.Fragment>
                                                STATUS <span className='dkg-total-taskcounter'>- {list.length}</span>
                                            </React.Fragment>
                                    }
                                </h2>
                                <div className='dkg-tasksummbox-header dkg-taskstatus-body'>
                                    {
                                        list.length > 0 ?
                                            <ul>
                                                <li style={{ backgroundColor: "#d58b5d", color: "#fff" }} onClick={(e) => selectFilter('To Do', 'To Do', 'progress')}>
                                                    <span className='dkg-status-title'>TO DO</span>
                                                    <span className='dkg-status-counter'>
                                                        {
                                                            list.filter(function (val) {
                                                                return val.progress === 'To Do';
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                                <li style={{ backgroundColor: "#26597d", color: "#fff" }} onClick={(e) => selectFilter('In Progress', 'In Progress', 'progress')}>
                                                    <span className='dkg-status-title'>IN PROGRESS</span>
                                                    <span className='dkg-status-counter'>
                                                        {
                                                            list.filter(function (val) {
                                                                return val.progress === 'In Progress';
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                                <li style={{ backgroundColor: "#3a9469", color: "#fff" }} onClick={(e) => selectFilter('Completed', 'Completed', 'progress')}>
                                                    <span className='dkg-status-title'> COMPLETED</span>
                                                    <span className='dkg-status-counter'>
                                                        {

                                                            list.filter(function (val) {
                                                                return val.progress === 'Completed';
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                            </ul>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className='dkg-tasksummbox dkg-todo-stauts-box-21'>
                                <h2 className='dkg-casecat-title-21 dk-without-filter-header'>
                                    {
                                        (isStatusFilter) ?
                                            <div className="dk-dbsuMM-filterHeader dk-with-filter-header-24">
                                                {statusFilterText}
                                                <span style={{ cursor: "pointer" }}>
                                                    <i className="fa fa-times-circle ml-2" onClick={() => removeTag('status')}></i>
                                                </span>
                                            </div>
                                            :
                                            <React.Fragment>
                                                PROGRESS <span className='dkg-total-taskcounter'>- {list.length}</span>
                                            </React.Fragment>
                                    }
                                </h2>
                                <div className='dkg-tasksummbox-header dkg-taskstatus-body'>
                                    {
                                        list.length > 0 ?
                                            <ul>
                                                <li style={{ backgroundColor: "#d58b5d", color: "#fff" }} onClick={(e) => selectFilter('New Task', 'New Task', 'status')}>
                                                    <span className='dkg-status-title'>New Task</span>
                                                    <span className='dkg-status-counter'>
                                                        {

                                                            list.filter(function (val) {
                                                                return val.status === 'New Task';
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                                <li style={{ backgroundColor: "#26597d", color: "#fff" }} onClick={(e) => selectFilter('In Action', 'In Action', 'status')}>
                                                    <span className='dkg-status-title'> In Action</span>
                                                    <span className='dkg-status-counter'>
                                                        {

                                                            list.filter(function (val) {
                                                                return val.status === 'In Action';
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                                <li style={{ backgroundColor: "#625c60", color: "#fff" }} onClick={(e) => selectFilter('Pause', 'Pause', 'status')}>
                                                    <span className='dkg-status-title'> Pause</span>
                                                    <span className='dkg-status-counter'>
                                                        {

                                                            list.filter(function (val) {
                                                                return val.status === 'Pause';
                                                            }).length
                                                        }
                                                    </span>
                                                </li>

                                                <li style={{ backgroundColor: "#d91010", color: "#fff" }} onClick={(e) => selectFilter('Overdue', 'Overdue', 'status')}>
                                                    <span className='dkg-status-title'> Overdue</span>
                                                    <span className='dkg-status-counter'>
                                                        {

                                                            list.filter(function (val) {
                                                                return val.status === 'Overdue';
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                                <li style={{ backgroundColor: "#3a9469", color: "#fff" }} onClick={(e) => selectFilter('Success', 'Success', 'status')}>
                                                    <span className='dkg-status-title'>Success</span>
                                                    <span className='dkg-status-counter'>
                                                        {

                                                            list.filter(function (val) {
                                                                return val.status === 'Success';
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                            </ul>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className='dkg-tasksummbox dkg-todo-stauts-box-21'>
                                <h2 className='dkg-casecat-title-21 dk-without-filter-header'>
                                    {
                                        (isTypeFilter) ?
                                            <div className="dk-dbsuMM-filterHeader dk-with-filter-header-24">
                                                {typeFilterText}
                                                <span style={{ cursor: "pointer" }}>
                                                    <i className="fa fa-times-circle ml-2" onClick={() => removeTag('type')}></i>
                                                </span>
                                            </div>
                                            :
                                            <React.Fragment>
                                                CATEGORY <span className='dkg-total-taskcounter'>- {list.length}</span>
                                            </React.Fragment>
                                    }
                                </h2>
                                <div className='dkg-tasksummbox-header dkg-taskstatus-body'>
                                    <ul>
                                        {categoryList.map((item, idx) => (
                                            <li style={{ backgroundColor: "#3c97b6", color: "#fff" }} onClick={(e) => selectFilter(item.name, item.name, 'type')}>
                                                <span className='dkg-status-title'>{item.name} </span>
                                                <span className='dkg-status-counter'>{item.count}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className='dkg-tasksummbox dkg-todo-stauts-box-21'>
                                <h2 className='dkg-casecat-title-21 dk-without-filter-header'>
                                    {
                                        (isTaskTypeFilter) ?
                                            <div className="dk-dbsuMM-filterHeader dk-with-filter-header-24">
                                                {taskTypeFilterText}
                                                <span style={{ cursor: "pointer" }}>
                                                    <i className="fa fa-times-circle ml-2" onClick={() => removeTag('taskType')}></i>
                                                </span>
                                            </div>
                                            :
                                            <React.Fragment>
                                                TYPE <span className='dkg-total-taskcounter'>- {list.length}</span>
                                            </React.Fragment>
                                    }
                                </h2>
                                <div className='dkg-tasksummbox-header dkg-taskstatus-body dkg-tasktype-qwe24'>
                                    <ul>
                                        {typeList.map((item, idx) => (
                                            <li style={{ backgroundColor: "#eaeaea", color: "#333" }} onClick={(e) => selectFilter(item.name, item.name, 'taskType')}>
                                                <span className='dkg-status-title'>{item.name}</span>
                                                <span className='dkg-status-counter'>{item.count}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Summary