import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const ActivityModal = ({ handleClose }) => {
    return (
        <>
            <Modal className="dk-checkInModal dk-activityModal" centered show={true} onHide={handleClose}>
                <Modal.Header closeButton style={{ backgroundColor: '#00b050' }}>
                    <Modal.Title>Activity Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-checkInBody">
                        <form action="">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="">Activities Period:</label>
                                        <label htmlFor="" className="activity_time">8 AM - 9 AM</label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="">Activities Type:</label>
                                        <label htmlFor="" className="activity_time">Done</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Activity Title:</label>
                                <label htmlFor="" className="activity_time">Sort new emails</label>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Activity Details:</label>
                                <label htmlFor="" className="activity_time"></label>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ActivityModal;
