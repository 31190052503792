import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const ManagementMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalAdminManagementMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/dkglobaladmin/adminmanagement/dashboard" className={(segment2 == 'adminmanagement') ? 'active' : ''}>Dashboard</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'structure') ? '' : ''}`}>Management 1</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'teams') ? '' : ''}`}>Management 2</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-1') ? '' : ''}`}>Management 3</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-2') ? '' : ''}`}>Management 4</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-3') ? '' : ''}`}>Management 5</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-4') ? '' : ''}`}>Management 6</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-5') ? '' : ''}`}>Management 7</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-5') ? '' : ''}`}>Management 8</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-5') ? '' : ''}`}>Management 9</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default ManagementMenu;