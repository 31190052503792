import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
// import "./candidateprofie.scss";
import { Modal, Tab, Nav } from "react-bootstrap";
import FirstScreening from './screening/FirstScreening'
import SecondScreening from './screening/SecondScreening'

import {
  candidateProfileDetails,
  candidateProfileRecruiterDetails,
  candidateProfileJobDetails,
  updateTable,
  updateCandidateProfile,
  //clearState,
  updateProfileDetails,
  yearCount,
  monthsCount,
  getEmailLogs,
  getEmailVerificationLogsForEmail1,
  getEmailVerificationLogsForEmail2,
  mailBoxEmailCategory,
  getApplications
} from "../../../../../../slice/candidates/list/listSlice";
import { fetchById } from "../../../../../../slice/candidatePrecreeningForm/candidatePrecreeningSlice";
import {
  candidateAllTechSkillsTag,
  candidateAllJobProfileSkillsTag
} from "../../../../../../slice/candidates/candidatesSlice";


import {
  companyNumbersCounts,
  languageNumbersCounts,
  titleNumbersCounts,
} from "../../../../../../slice/jobs/summary/summarySlice";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import moment from "moment-timezone";
// import "./candidateprofie.scss";
import {
  indicatorColor,
  flagColor,
  profileStatus,
  profileMotivationStatus,
} from "../../../../../../helper/commonFunctions";
// import leftBoxUserGirl from "../../../../../../assets/images/girl-defult.png";
// import leftBoxUserMen from "../../../../../../assets/images/men-default.jpg";
// import leftBoxUserDefault from "../../../../../../assets/images/user-defult.jpg";
// import CustomDateFormat from "../../../../../ui/dateFormats/CustomDateFormat";
// import { FaRegClock, FaSignature } from 'react-icons/fa';
import $ from "jquery";
// import EventLog from "./event/EventLog";
import { getInternalMail, getInternalMailCount, internalMailBoxEmailCategory } from "../../../../../../slice/mailbox/mailboxSlice";
// import Appication from "./appications/Appication";
//import { border } from '@mui/system';
export const useMountEffect = (fun) => useEffect(fun, []);


const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const UseFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };
  return [htmlElRef, setFocus];
};

const CandidatePrescreen = ({ canid, list, isProfileModal, handleCloseProfile }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { companyCount, languageCount, titleCount } = useSelector(
    (state) => state.jobsSummray
  );

  const { userInfo } = useSelector(state => state.auth);
  const { internalMailsCount } = useSelector(state => state.mailbox);

  const {
    isProfileLoading,
    isProfileRecLoading,
    isProfileJobLoading,
    isProfileLanguageLoading,
    profileDetails,
    profileRecDetails,
    profileJobDetails,
    //profileLangaugeDetails,
    //tagsListTechSkills,
    iscommentUpdate,
    defaultYearList,
    defaultMonthList,
    isUpdate,
    isProfileUpdate,
    isLoading,
    isProfileProjectUpdate
  } = useSelector((state) => state.candidates);

  // const { isCommentLoading,  isCommentDelete } = useSelector(
  //   (state) => state.comments
  // );
  const { isCommentDelete } = useSelector(
    (state) => state.comments
  );

  const [filterTags, setFilterTags] = useState([]);
  const [tagLength, setTagLength] = useState(0);

  const [filter, setFilter] = useState({});
  const [recruiterFilter, setRecruiterFilter] = useState({});
  const [companyfilter, setCompanyfilter] = useState({});
  const [updatedFilter, setUpdatedFilter] = useState({});
  const [companyFilterText, setCompanyFilterText] = useState("");
  const [languageFilterText, setLanguageFilterText] = useState("");


  const [removeFilter, setRemoveFilter] = useState(0);
  const [eventKey, SetEventKey] = useState('details');
  const getRequestParams = (filter) => {
    let params = {};
    params["filter"] = filter;
    return params;
  };
  const [input1Ref, setInput1Focus] = UseFocus();
  useMountEffect(setInput1Focus);

  const [open, setOpen] = React.useState(false);
  const [show, setShow] = useState(isProfileModal);
  const [candidateId, setCandidateId] = useState(canid);
  const [showingCountOf, setShowingCountOf] = useState(0);
  const [showingCount, setShowingCount] = useState(0);
  const [prevId, setPrevId] = useState(0);
  const [nextId, setNextId] = useState(0);
  const [prevIndex, setPrevIndex] = useState(false);
  const [nextIndex, setNextIndex] = useState(false);
  const [id, setId] = useState(0);
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [defaultYear, setDefaultYear] = useState("");
  const [defaultMonth, setDefaultMonth] = useState("");
  const [yearList, setDefaultList] = useState([]);
  const [monthList, setDefaultMonthList] = useState([]);
  const [name, setName] = useState("");
  const [last_Name, setLastName] = useState("");
  const [email1, setEmail1] = useState("");
  const [email2, setEmail2] = useState("");
  const [systemEmail, setSystemEmail] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [skypeId, setSkypeId] = useState("");
  const [gender, setGender] = useState("");
  const [profileStatusValue, setProfileStatusValue] = useState("");
  const [motivationStatus, setMotivationStatus] = useState("");
  const [indicatorValue, setIndicatorValue] = useState("");
  const [flagTypeValue, setFlagTypeValue] = useState("");

  const [recAdminName, setRecAdminName] = useState("");
  const [recAdminImg, setRecAdminImg] = useState("");

  const [recName, setRecName] = useState("");
  const [recImg, setRecImg] = useState("");

  const [accountManagerName, setAccountManagerName] = useState("");
  const [accountManagerImg, setAccountManagerImg] = useState("");
  const [oldJobId, setOldJobId] = useState("");
  const [popupJobId, setPopupJobId] = useState("");
  const [jobId, setJobId] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobCompanyId, setJobCompanyId] = useState("");
  const [jobCompany, setJobCompany] = useState("");
  const [jobCountry, setJobCountry] = useState("");
  //const [jobCity, setJobCity] = useState("");
  //const [jobLocality, setJobLocality] = useState("");
  //const [jobFee, setJobFee] = useState("");
  const [jobNo, setJobNo] = useState("");
  const [jobProject, setJobProject] = useState("");
  const [jobLanguage, setJobLanguage] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [jobStart, setJobStart] = useState("");
  const [isJobIDShow, setJobIDShow] = useState(false);
  const [commentPopup, setCommentPopup] = useState(false);
  const [newStatus, setNewStatus] = useState("")
  const [newProgess, setNewProgess] = useState("")
  const [newStage, setNewStage] = useState("")

  const handleClose = () => setJobIDShow(false);
  const handleCommentShow = () => {
    setCommentPopup(true)
  };
  const closedCommentPopup = () => {
    setCommentPopup(false)
  };

  const handleShow = () => {
    // setInput1Focus()
    setJobIDShow(true);
    setPopupJobId("");
  };

  const onfucuse = () => {
    $("#textboxID").focus();
  };

  const fetchDetails = async () => {
    await setCandidateId(canid);
  };
  const [countComment, setCountComment] = useState(0);

  useEffect(() => {
    if (isProfileProjectUpdate) setCountComment(profileDetails.commentCount);
  }, [isProfileProjectUpdate]);



  const [firstScrean, SetFirstScrean] = useState(false)
  const [secondScrean, SetSecondScrean] = useState(false)
  const [matchedJobs, SetMatchedJobs] = useState(false)


  useEffect(() => {
    if (eventKey) {
      switch (eventKey) {
        case 'first-screening':
          dispatch(fetchById({ id: candidateId }))
          SetFirstScrean(true)
          break;
        case 'second-screening':
          dispatch(fetchById({ id: candidateId }))
          SetSecondScrean(true)
          break;
        case 'matching_jobs':
          SetMatchedJobs(true)
          break;
        case 'emails-logs':
          dispatch(getEmailLogs({ can_id: candidateId }));
          break;

        case 'verify-email':
          dispatch(getEmailVerificationLogsForEmail1({ can_id: candidateId }));
          dispatch(getEmailVerificationLogsForEmail2({ can_id: candidateId }));
          break;

        case 'emails-eventkey':
          dispatch(mailBoxEmailCategory({ can_id: candidateId }));
          dispatch(internalMailBoxEmailCategory({ can_id: id, role: userInfo.role }));
          dispatch(getInternalMail({ can_id: id, role: userInfo.role, type: 'Inbox', cat_id: null }))
          dispatch(getInternalMailCount({ can_id: id, role: userInfo.role }))
          break;
        case 'profile-appications':
          //console.log("profile-appications--", candidateId)
          dispatch(getApplications({ can_id: candidateId }));
          break;
      }
    }
  }, [eventKey]);



  useEffect(() => {
    if (candidateId > 0) {
      findIndexOfProfile();
      const fetchCandidateRecods = async () => {
        dispatch(yearCount({}));
        dispatch(monthsCount({}));
        dispatch(candidateProfileDetails({ id: candidateId }));
        dispatch(candidateProfileRecruiterDetails({ id: candidateId }));
        dispatch(candidateProfileJobDetails({ id: candidateId }));
        dispatch(candidateAllTechSkillsTag({ id: candidateId }));
        dispatch(candidateAllJobProfileSkillsTag({ id: candidateId }));
        dispatch(getInternalMailCount({ can_id: id, role: userInfo.role }))
      };
      fetchCandidateRecods();
    }
  }, [candidateId]);

  useEffect(() => {
    async function fetchData() {
      try {
        const params = getRequestParams(filter);
        dispatch(companyNumbersCounts(params));
        dispatch(languageNumbersCounts(params));
        dispatch(titleNumbersCounts(params));
      } catch (err) {
        console.log(err);
      }
    }
    if (isEmpty(filter)) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    if (!isEmpty(filter)) {
      const params = getFilterRequestParams(
        recruiterFilter,
        updatedFilter,
        companyfilter
      );
      dispatch(companyNumbersCounts(params));
      dispatch(languageNumbersCounts(params));
      dispatch(titleNumbersCounts(params));
    }
  }, [filter, removeFilter]);

  const removeTag = async (e) => {
    const type = e.target.dataset.type;
    switch (type) {
      case "company":

        setCompanyFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "lang":
        //setIslanguageFilter(false);
        setLanguageFilterText("");
        delete companyfilter[type];
        delete filter[type];
        setCompanyfilter(companyfilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      default:
        delete recruiterFilter[type];
        delete filter[type];
        setCompanyfilter(recruiterFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;
    }
    setTagLength(tagLength - 1);
    setFilterTags(filterTags.filter((item) => item.type !== type));
  };

  useEffect(() => {
    if (removeFilter > 0 && isEmpty(filter)) {
      try {
        const params = getRequestParams(filter);
        dispatch(companyNumbersCounts(params));
        dispatch(languageNumbersCounts(params));
        dispatch(titleNumbersCounts(params));
      } catch (err) {
        console.log(err);
      }
    }
  }, [removeFilter]);

  const prevRecord = () => {
    setCandidateId(prevId);
  };

  const nextRecord = () => {
    setCandidateId(nextId);
  };

  const findIndexOfProfile = () => {
    var index;

    list.findIndex(function (entry, i) {
      if (entry.original.canId == candidateId) {
        index = i;
        return true;
      }
    });

    setShowingCountOf(index + 1);
    setShowingCount(list.length);

    if (!isEmpty(list[index - 1])) {
      setPrevId(list[index - 1].original.canId);
      setPrevIndex(true);
    } else {
      setPrevIndex(false);
    }
    if (!isEmpty(list[index + 1])) {
      setNextId(list[index + 1].original.canId);
      setNextIndex(true);
    } else {
      setNextIndex(false);
    }
  };

  function isEmpty(obj) {
    for (var i in obj) {
      return false;
    }
    return true;
  }

  const getFilterRequestParams = (
    recruiterFilter,
    updatedFilter,
    companyfilter
  ) => {
    let params = {};
    params["filter"] = {
      recruiterFilter: recruiterFilter,
      updatedFilter: updatedFilter,
      companyfilter: companyfilter,
    };
    return params;
  };

  const selectFilter = async (e) => {
    const type = e.target.dataset.type;
    const value = e.target.dataset.value;
    let i;
    switch (type) {
      case "company":

        setCompanyFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "lang":
        //setIslanguageFilter(true);
        setLanguageFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      default:
        i = filterTags.findIndex(function (x) {
          return x.type === type;
        });
        if (i === -1) {
          filterTags.push({ type, value });
        }
        setRecruiterFilter({ ...recruiterFilter, [type]: value });
        setUpdatedFilter({ ...updatedFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;
    }
    setTagLength(tagLength + 1);
    setFilterTags(filterTags);
  };

  useEffect(() => {
    if (isProfileLoading) {
      setOpen(true);
    }
    if (!isProfileLoading && profileDetails) {
      setOpen(false);
      if (!isEmpty(profileDetails)) {
        setId(profileDetails.id);
        setCountComment(profileDetails.commentCount);
        setCreatedAt(profileDetails.createdAt);
        setUpdatedAt(profileDetails.updatedAt);
        setDefaultYear(profileDetails.year);
        setDefaultMonth(profileDetails.month);
        setName(profileDetails.first_name);
        setLastName(profileDetails.last_name);
        setEmail1(profileDetails.email1);
        setEmail2(profileDetails.email2);
        setSystemEmail(profileDetails.system_email);
        setPhone1(profileDetails.phone1);
        setPhone2(profileDetails.phone2);
        setGender(profileDetails.gender);
        setSkypeId(profileDetails.skypeId);
        setProfileStatusValue(profileDetails.profileStatus);
        setIndicatorValue(profileDetails.profileIndicator);
        setFlagTypeValue(profileDetails.flag_type);
        setMotivationStatus(profileDetails.motivationStatus);
        //   setCommentCount(profileDetails.commentCount)
        setDefaultList(defaultYearList);
        setDefaultMonthList(defaultMonthList);
      }
    }

    if (!isProfileRecLoading && !isEmpty(profileRecDetails)) {
      setRecAdminName(
        profileRecDetails.recAdminInfo.firstName +
        " " +
        profileRecDetails.recAdminInfo.lastName
      );
      setRecAdminImg(profileRecDetails.recAdminInfo.profileImage);

      setRecName(
        profileRecDetails.recInfo.firstName +
        " " +
        profileRecDetails.recInfo.lastName
      );
      setRecImg(profileRecDetails.recInfo.profileImage);

      setAccountManagerName(profileRecDetails.amInfo.firstName + " " + profileRecDetails.amInfo.lastName);
      setAccountManagerImg(profileRecDetails.amInfo.profileImage);
    }

    if (!isProfileJobLoading && !isEmpty(profileJobDetails)) {
      setOldJobId(profileJobDetails.jobDetail.job_id);
      setJobId(profileJobDetails.jobDetail.job_id);
      setJobTitle(profileJobDetails.jobDetail.name);
      setJobCompanyId(
        profileJobDetails.jobDetail.com_id != 0
          ? profileJobDetails.jobDetail.com_id
          : ""
      );
      setJobCompany(profileJobDetails.jobDetail.company);
      setJobCountry(profileJobDetails.jobDetail.job_country);
      //setJobLocality(profileJobDetails.jobDetail.locality);
      setJobNo(
        profileJobDetails.jobDetail.no_of_jobs != 0
          ? profileJobDetails.jobDetail.no_of_jobs
          : ""
      );
      // setJobFee(
      //   profileJobDetails.jobDetail.fee != 0
      //     ? profileJobDetails.jobDetail.fee
      //     : ""
      // );
      setJobProject(profileJobDetails.jobDetail.project);
      setJobLanguage(profileJobDetails.jobDetail.lang);
      setJobRole(profileJobDetails.jobDetail.role);
      setJobStart(profileJobDetails.jobDetail.date1);
    }
    if (iscommentUpdate) {
      dispatch(candidateProfileDetails({ id: candidateId }));
      setCountComment(profileDetails.commentCount);
    }
    // if(isScreaningLoading){
    //   dispatch(clearState())
    // }
  }, [
    isProfileLoading,
    isLoading,
    isProfileUpdate,
    isUpdate,
    iscommentUpdate,
    isCommentDelete,
    isProfileRecLoading,
    isProfileJobLoading,
    isProfileLanguageLoading,
  ]);

  const updateYears = async (idd, value) => {
    await dispatch(updateProfileDetails({ id, key: "year", value }));
    await dispatch(updateTable({ id, key: "year", value }));
    setDefaultYear(value);
  };
  const updateMonths = async (idd, value) => {
    await dispatch(updateProfileDetails({ id, key: "month", value }));
    await dispatch(updateTable({ id, key: "month", value }));
    setDefaultMonth(value);
  };

  // const updateGender = async (id, value) => {
  //   await dispatch(updateProfileDetails({ id, key: "gender", value }));
  //   await dispatch(updateTable({ id, key: "gender", value }));
  // };

  const updateJobCandidate = async (id) => {
    await dispatch(updateTable({ id: id, key: "jobId", value: jobId }));
    await dispatch(candidateProfileJobDetails({ id: candidateId }));
    await dispatch(
      updateProfileDetails({ id: id, key: "jobId", value: jobId })
    );
    setOpen(true);
    setJobIDShow(false);
    setOldJobId(jobId);
    setOpen(false);
  };

  const updateJobCandidate1 = async (id, jobIds) => {
    await dispatch(updateTable({ id: id, key: "jobId", value: jobIds }));
    await dispatch(candidateProfileJobDetails({ id: candidateId }));
    await dispatch(
      updateProfileDetails({ id: id, key: "jobId", value: jobIds })
    );

    setCompanyFilterText("");

    //setIslanguageFilter(false);
    setLanguageFilterText("");

    setCompanyfilter({});
    setFilter({});
    setRemoveFilter(0);
  };

  const updateProfileQuick = async (valueId, value, img, columnId) => {
    switch (columnId) {
      case "profileStatus":
        $(`#profileStatus${id} button .dk-cvClear-status svg`).css(
          "color",
          img
        );
        break;

      case "profileIndicator":
        $(`#profileIndicator${id} button .dk-cvClear-circle i`).css(
          "color",
          img
        );
        break;

      case "motivationStatus":
        $(`#motivationStatus${id} button .dk-cvClear-userIcon svg`).css(
          "color",
          img
        );
        break;

      case "flag_type":
        $(`#flag_type${id} button .dk-cvClear-flag i`).css("color", img);
        break;
    }
    await dispatch(updateProfileDetails({ id: valueId, key: columnId, value }));
    await dispatch(
      updateCandidateProfile({ id: valueId, key: columnId, value })
    );
  };

  const changeColorType = (id, colr, dataKey) => {

    if (id != undefined && colr != undefined && dataKey != undefined) {
      dispatch(updateCandidateProfile({ id, key: dataKey, value: colr }));
    }

  }
  const updateDefault = async (id, value, img, key) => {
    //console.log(id, value, img, key)
    if (key === "am_id") {
      setRecName('No User');
      setRecImg(img);
    }
    if (key === "rec_id") {
      setRecName('No User');
      setRecImg(img);
    }
    if (key === "rec_admin") {
      setRecName('No User');
      setRecImg(img);
    }
    await dispatch(updateProfileDetails({ id, key, value }));
    await dispatch(updateCandidateProfile({ id, key, value }));

  }
  const updateProfile = async (e) => {
    const id = e.target.dataset.id;
    const key = e.target.dataset.key;
    const value = e.target.dataset.value;

    if (key === "rec_id") {
      setRecName(e.target.dataset.name);
      setRecImg(e.target.dataset.img);
    }
    if (key === "am_id") {
      setAccountManagerName(e.target.dataset.name);
      setAccountManagerImg(e.target.dataset.img);
    }
    if (key === "rec_admin") {
      setRecAdminName(e.target.dataset.name);
      setRecAdminImg(e.target.dataset.img);
    }

    if (key === "first_name" || key === "last_name") {
      let fullname = name + ' ' + last_Name
      await dispatch(updateCandidateProfile({ id, key: 'name', value: fullname }));
    }
    await dispatch(updateProfileDetails({ id, key, value }));
    await dispatch(updateCandidateProfile({ id, key, value }));


    if (key === "jobId") {
      setOpen(true);
      dispatch(candidateProfileJobDetails({ id: candidateId }));
      isJobIDShow(false);
      setOldJobId(value);
      setOpen(false);
    }

    switch (key) {
      case "caseStatus":
        setNewStatus(value)
        setNewProgess("")
        setNewStage("")
        await dispatch(
          updateProfileDetails({ id, key: "case_progress", value: "" })
        );
        await dispatch(
          updateProfileDetails({ id, key: "case_stage", value: "" })
        );
        await dispatch(
          updateCandidateProfile({ id, key: "case_progress", value: "" })
        );
        await dispatch(
          updateCandidateProfile({ id, key: "case_stage", value: "" })
        );
        break;
      case "case_progress":
        setNewProgess(value)
        setNewStage("")
        await dispatch(
          updateProfileDetails({ id, key: "case_stage", value: "" })
        );
        await dispatch(
          updateCandidateProfile({ id, key: "case_stage", value: "" })
        );
        break;
      case "case_stage":
        setNewStage(value)
        break;
      default:
        break;
    }

  };

  const updateDates = async (e) => {
    const myArray = e.split("==");
    const id = myArray[0];
    const value = moment(myArray[1])
      .tz("Europe/Dublin")
      .format("YYYY-MM-DD HH:mm:ss");
    const key = myArray[2];
    await dispatch(updateTable({ id, key, value }));
  };

  const updateEditor = async (e) => {
    const myArray = e.split("==");
    const id = myArray[0];
    const value = myArray[1];
    const key = myArray[2];
    await dispatch(updateTable({ id, key, value }));
    await dispatch(updateProfileDetails({ id, key, value }));
  };

  const [tabId, setTabId] = useState(0);
  const tabSelect = (key) => {
    setTabId(key);
  };

  const [isAddSkills, setIsAddSkills] = useState(false);

  // const showAddProfieSkills = () => {
  //   setIsAddSkills(true);
  // };
  const hideAddProfieSkills = () => {
    setIsAddSkills(false);
  };

  const [isShowSocialModal, setIsSocialModal] = useState(false);

  // const showSocialModal = () => {
  //   setIsSocialModal(true);
  // };
  const hideSocialModal = () => {
    setIsSocialModal(false);
  };

  const [showComposeModal, setShowCompose] = useState(false);
  const [to, setTo] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  return (
    <>
      <Modal
        show={true}
        onHide={handleCloseProfile}
        dialogClassName="dk-candidateProfile80"
        aria-labelledby="example-custom-modal-styling-title"
        onEntered={fetchDetails}
        onExited={function () {
        }}
        onShow={function () {
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="dk-candModalHeader">
          <Modal.Title
            className="dk-candModalTitle"
            id="example-custom-modal-styling-title"
          >
            <div className="dkg-years-DropdownCon">
              <div className="dkg-clientid" style={{ fontWeight: "normal" }}>Case ID -  {candidateId}{" "}</div>
            </div>

            {/* {prevIndex ? (
              <Link to="#" onClick={prevRecord}>
                <i className="fa fa-chevron-left mr-2"></i>
              </Link>
            ) : null}
            {showingCountOf ? showingCountOf : 1} of{" "}
            {showingCount !== 0 ? showingCount : 1}
            {nextIndex ? (
              <Link to="#" onClick={nextRecord}>
                <i className="fa fa-chevron-right ml-2"></i>
              </Link>
            ) : null} */}
            Candidate Prescreen

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="dk-candProfileModalBody">
          <Backdrop
            className={classes.backdrop}
            open={open}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="dk-BottomSecCon">
            <div className="row mr-0 ml-0">
              <div className="col-md-12 col-sm-12 dk-candidateVertcalTabCon pl-0 pr-0">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="first-screening"
                  onSelect={tabSelect}
                >
                  <div className="row mr-0 ml-0">
                    <div className="col-md-12 col-sm-12 dk-candProfileSideMenu dkg-candProfile-horizontal pl-0 pr-0"
                      id="dk-cliDetailsV2"
                    >
                      <Nav
                        variant="pills"
                        className="flex-row dk-candNavPills  dkg-horizontal-navpills dkg-cand-prescreen-navpills"
                        onSelect={(selectedKey) => SetEventKey(selectedKey)}
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="first-screening">1st Screen</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second-screening">2nd Screen</Nav.Link>
                        </Nav.Item>
                        
                      </Nav>
                    </div>
                    <div className="col-md-12 col-sm-12 dk-candProfileMainCont dk-candProfileMain-fullWidth">
                      <Tab.Content>
                        <Tab.Pane eventKey="first-screening">
                          {
                            (firstScrean === true) ? <div className="dk-candidateTabPane">
                              {
                                profileDetails.screanningDetails != undefined || profileDetails.screanningDetails != null ? <FirstScreening candidateId={canid} screaningData={profileDetails} /> : <FirstScreening candidateId={canid} screaningData={profileDetails} />
                              }

                            </div>
                              : null
                          }

                        </Tab.Pane>
                        <Tab.Pane eventKey="second-screening">
                          {
                            (secondScrean === true) ? <div className="dk-candidateTabPane">
                              {
                                profileDetails.secondScrening != undefined ? <SecondScreening candidateId={canid} secondScreaning={profileDetails} /> : null
                              }
                            </div>
                              : null
                          }

                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CandidatePrescreen;
