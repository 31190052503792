import React, { useLayoutEffect } from 'react';
import Header from "../elements/header";
import Navbar from '../elements/header/navbar/mainMenu/management/ManagementMenu';

const ManagementsLayout = ({ children }) => {

    return (
        <React.Fragment>
            <Header mainheaderLink="Dashboard -" moduleLink="/dashboard/plateform" linkcolor="#2e97b6" mainheaderTitle=" Management" textcolor="#884C5E" />
            {/* <Navbar /> */}
            {children}
        </React.Fragment>
    )
}
export default ManagementsLayout;