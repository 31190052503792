import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MyProfileModal from "../navbar/myaccount/elements/modal/MyProfile";
import CandidateMyProfile from "../navbar/myaccount/elements/modal/CandidateMyProfile";
const NewCommonComp = ({}) => {
  const { userInfo } = useSelector((state) => state.auth);

  return (
    <>
      {/* {userInfo.role === "candidate" ? (
        <CandidateMyProfile userInfo={userInfo} />
      ) : (
        <MyProfileModal userInfo={userInfo} />
      )} */}
    </>
  );
};
export default NewCommonComp;
