import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/dkglobaladmin/ClientsMenu';

const AdminClientsLayout = ({ children }) => {
    return (
        <React.Fragment>
            {/* <Header mainheaderLink="Dashboard -" moduleLink="/dashboard/plateform" linkcolor="#3c4c62" mainheaderTitle=" CLIENTS" textcolor="#535255"  /> */}
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="CLIENTS" moduleLink="/dashboard/clients" linkcolor="#3c4c62" mainheaderTitle=" - INCOME TAX" textcolor="#144773" />
            {/* <Navbar /> */}
            {children}
        </React.Fragment>
    )
}
export default AdminClientsLayout;
