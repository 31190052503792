import React, { useState, useEffect, useRef } from "react";
import { Route, Link } from "react-router-dom";
import Submenu from "../subMenu";
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const Navbar = (props) => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    const [show, setShowMenu] = React.useState();

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setShowMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);


    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK Global CRM";
    }, [pathname]);
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-recruitmentNavbar" ref={ref}>
                <button className="mobileNav-btn" onClick={() => setShowMenu(!show)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <Route>
                    <ul className={`mobileNav ${show ? "show" : ""}`}>
                        <button className="dk-closeBtn" onClick={() => setShowMenu(false)}>
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                        {/* <li><Link to="/recruitment/dashboard/daily-tracking" className={`${(segment2 === 'dashboard') ? 'active' : ''}`}>Dashboard </Link></li> */}
                        <li><Link to="/recruitment/company/summary" className={`${(segment2 === 'company') ? 'white-active' : ''}`}>Summary</Link></li>
                        {/* <li><Link to="/recruitment/clients-list/list-view" className={`${pathname.match('/clients-list') ? 'active' : ''}`}>Clients List</Link></li> */}
                        <li><Link to="/recruitment/clients-list/list-view" className={(segment3 === "list-view") ? 'white-active' : ''}>Clients List</Link></li>
                        {/* <li><Link to="/recruitment/clients-list/detail-view" className={(segment3 === "detail-view") ? 'white-active' : ''}>Detail View</Link></li>
                        <li><Link to="/recruitment/clients-list/add-new" className={(segment3 === "add-new") ? 'white-active' : ''}>Add New</Link></li>
                        <li><Link to="/recruitment/clients-list/client-details" className={(segment3 === "client-details") ? 'white-active' : ''}>Client Details</Link></li> */}
                        <li><Link to="/recruitment/clients-list/demands" className={(segment3 === "demands") ? 'white-active' : ''}>Demands</Link></li>
                        <li><Link to="/recruitment/clients-list/requirements" className={(segment3 === "requirements") ? 'white-active' : ''}>Requirements</Link></li>
                        <li><Link to="/recruitment/clients-list/salaries" className={(segment3 === "salaries") ? 'white-active' : ''}>Salaries</Link></li>
                        <li><Link to="/recruitment/clients-list/benefits" className={`${(segment3 === 'benefits') ? 'white-active' : ''}`}>Benefits</Link></li>
                        <li><Link to="/recruitment/clients-list/portals" className={`${(segment3 === 'portals') ? 'white-active' : ''}`}>Portals</Link></li>
                        <li><Link to="/recruitment/clients-list/sendouts" className={`${(segment3 === 'sendouts') ? 'white-active' : ''}`}>Sendouts</Link></li>
                        <li><Link to="/recruitment/clients-list/interviews" className={`${(segment3 === 'interviews') ? 'white-active' : ''}`}>Interviews</Link></li>
                        <li><Link to="/recruitment/clients-list/feedbacks" className={`${(segment3 === 'feedbacks') ? 'white-active' : ''}`}>Feedbacks</Link></li>
                        {/* <li>
                            {
                                (segment3 === "kpis") ?
                                    <Link to="/recruitment/kpis/kpi-summary" className={(segment2 === "kpis" && segment3 === "kpi-summary") ? 'active' : 'active'}>KPI
                                    </Link>
                                    :
                                    <Link to="/recruitment/kpis/kpi-summary" className=''>KPI
                                    </Link>
                            }
                        </li> */}
                        {/* <li><Link to="/recruitment/database/summary" className={`${(segment2 === 'database') ? 'active' : ''}`}>Database </Link></li> */}
                        {/* <li><Link to="/recruitment/dkg-cloud/summary" className={`${(segment2 === 'dkg-cloud') ? 'active' : ''}`}>DKG Cloud </Link></li>
                        <li><Link to="/recruitment/rectraining/overview" className={`${pathname.match('/rectraining') ? 'active' : ''}`}>Training</Link></li> */}
                        {/* <li><Link to="/recruitment/clientsinfo/policies" className={`${(segment3 === 'policies') ? 'active' : ''}`}>Policies</Link></li>
                        <li><Link to="/recruitment/clientsinfo/salaries" className={`${(segment3 === 'salaries') ? 'active' : ''}`}>Salaries</Link></li>
                        <li><Link to="/recruitment/clientsinfo/income-tax" className={`${(segment3 === 'income-tax') ? 'active' : ''}`}>Income Tax</Link></li>
                        <li><Link to="/recruitment/clientsinfo/benefits" className={`${(segment3 === 'benefits') ? 'active' : ''}`}>Benefits</Link></li>
                        <li><Link to="/recruitment/clientsinfo/relocation" className={`${(segment3 === 'relocation') ? 'active' : ''}`}>Relocation</Link></li>
                        <li><Link to="/recruitment/clientsinfo/interviews" className={`${(segment3 === 'interviews') ? 'active' : ''}`}>Interviews</Link></li>
                        <li><Link to="/recruitment/clientsinfo/profiling" className={`${(segment3 === 'profiling') ? 'white-active' : ''}`}>Profiling</Link></li> */}
                    </ul>
                </Route>
            </div>
            <Submenu path={pathname} />
        </React.Fragment>
    );
}

export default Navbar;