import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import Deepak from '../../../../../../assets/images/deepak.png';

const UserFilter = () => {
    return (
        <>
            <div className="mailshotUsrDrop">
                <Dropdown>
                    <Dropdown.Toggle variant="" className="mailshotUsrBtn" >
                        <i className="far fa-user-circle"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mailshotUsrMenu">
                        <div className="d-flex">
                            <Dropdown.Item href="#/action-1" className="mailshotUsrItem">
                                <img src={Deepak} alt="Recruiter" className="recruiterImg" />
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-1" className="mailshotUsrItem">
                                <img src={Deepak} alt="Recruiter" className="recruiterImg" />
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-1" className="mailshotUsrItem">
                                <img src={Deepak} alt="Recruiter" className="recruiterImg" />
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-1" className="mailshotUsrItem">
                                <img src={Deepak} alt="Recruiter" className="recruiterImg" />
                            </Dropdown.Item>

                            <Dropdown.Item href="#/action-1" className="mailshotUsrItem">
                                <img src={Deepak} alt="Recruiter" className="recruiterImg" />
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-1" className="mailshotUsrItem">
                                <img src={Deepak} alt="Recruiter" className="recruiterImg" />
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-1" className="mailshotUsrItem">
                                <img src={Deepak} alt="Recruiter" className="recruiterImg" />
                            </Dropdown.Item>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default UserFilter;
