import React from 'react';

const ProjectManagerTraining = () => {
    return (
        <>

        </>
    )
}

export default ProjectManagerTraining;
