import React from 'react'
import { Button } from "react-bootstrap";
import  './desired-job.scss'

 
const DesiredTab = () => {
  return (
    <>
        <div className="dkg-desired-job-tabCon">
            <div className="dkg-desiredjob-header">
                <h1 className="dkg-contact-title">
                YOUR DESIRED JOB PROFILE
                </h1>

            </div>
            <div className="dkg-desired-job-mainCon dkg-desired-job-summ-mainCon">
                <div className="dkg-desired-textarea-Con">
                <div className="form-control dkg-desired-textarea">
                    
                </div>
                </div>
                <div className="dkg-desired-summ-body-234">
                    <div className="dkg-desired-summ-box-cols">
                        <div className="dkg-desired-summ-1stcols">
                        <div className="dkg-desired-summ-title">
                            COVER MESSAGE
                        </div>
                        </div>
                        <div className="dkg-desired-summ-2ndcols">
                        <div className="dkg-covermsg-textCon">
                            <p className="dkg-covermsg-text">
                            Lorem ipsum dolor sit amet consectetur
                            adipisicing elit. Vero a obcaecati quod animi
                            hic ea laboriosam similique vel quia!.
                            </p>
                        </div>
                        </div>
                    </div>
                <div className="dkg-desired-summ-box-cols">
                    <div className="dkg-desired-summ-1stcols">
                    <div className="dkg-desired-summ-title">
                        Desired Countries
                    </div>
                    </div>
                    <div className="dkg-desired-summ-2ndcols">
                    <div className="dkg-myprofile-summCon">
                        <div className="dkg-myprofile-summ-btn-tagCon">
                        <Button className="dkg-desired-btntext">
                            India
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Ireland
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            UK
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            USA
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Grecee
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Germany
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Ireland
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            UK
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            USA
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Grecee
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="dkg-desired-summ-box-cols">
                    <div className="dkg-desired-summ-1stcols">
                    <div className="dkg-desired-summ-title">
                        Desired City/Town
                    </div>
                    </div>
                    <div className="dkg-desired-summ-2ndcols">
                    <div className="dkg-myprofile-summCon">
                        <div className="dkg-myprofile-summ-btn-tagCon">
                        <Button className="dkg-desired-btntext">
                            India
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Ireland
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            UK
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            USA
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Grecee
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Germany
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Ireland
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            UK
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            USA
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Grecee
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="dkg-desired-summ-box-cols">
                    <div className="dkg-desired-summ-1stcols">
                    <div className="dkg-desired-summ-title">
                        Desired Industries
                    </div>
                    </div>
                    <div className="dkg-desired-summ-2ndcols">
                    <div className="dkg-myprofile-summCon">
                        <div className="dkg-myprofile-summ-btn-tagCon">
                        <Button className="dkg-desired-btntext">
                            India
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Ireland
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            UK
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            USA
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Grecee
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Germany
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Ireland
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            UK
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            USA
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Grecee
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="dkg-desired-summ-box-cols">
                    <div className="dkg-desired-summ-1stcols">
                    <div className="dkg-desired-summ-title">
                        Desired Positions
                    </div>
                    </div>
                    <div className="dkg-desired-summ-2ndcols">
                    <div className="dkg-myprofile-summCon">
                        <div className="dkg-myprofile-summ-btn-tagCon">
                        <Button className="dkg-desired-btntext">
                            India
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Ireland
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            UK
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            USA
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Grecee
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Germany
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Ireland
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            UK
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            USA
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Grecee
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="dkg-desired-summ-box-cols">
                    <div className="dkg-desired-summ-1stcols">
                    <div className="dkg-desired-summ-title">
                        Desired Job Titles
                    </div>
                    </div>
                    <div className="dkg-desired-summ-2ndcols">
                    <div className="dkg-myprofile-summCon">
                        <div className="dkg-myprofile-summ-btn-tagCon">
                        <Button className="dkg-desired-btntext">
                            India
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Ireland
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            UK
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            USA
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Grecee
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Germany
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Ireland
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            UK
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            USA
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Grecee
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="dkg-desired-summ-box-cols">
                    <div className="dkg-desired-summ-1stcols">
                    <div className="dkg-desired-summ-title">
                        Desired Workplace
                    </div>
                    </div>
                    <div className="dkg-desired-summ-2ndcols">
                    <div className="dkg-myprofile-summCon">
                        <div className="dkg-myprofile-summ-btn-tagCon">
                        <Button className="dkg-desired-btntext">
                            India
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Ireland
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            UK
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            USA
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Grecee
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Germany
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Ireland
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            UK
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            USA
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Grecee
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="dkg-desired-summ-box-cols">
                    <div className="dkg-desired-summ-1stcols">
                    <div className="dkg-desired-summ-title">
                        Desired Salaries
                    </div>
                    </div>
                    <div className="dkg-desired-summ-2ndcols">
                    <div className="dkg-myprofile-summCon">
                        <div className="dkg-myprofile-summ-btn-tagCon">
                        <Button className="dkg-desired-btntext">
                            India
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Ireland
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            UK
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            USA
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Grecee
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Germany
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Ireland
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            UK
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            USA
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Grecee
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="dkg-desired-summ-box-cols">
                    <div className="dkg-desired-summ-1stcols">
                    <div className="dkg-desired-summ-title">
                        Desired Benefits
                    </div>
                    </div>
                    <div className="dkg-desired-summ-2ndcols">
                    <div className="dkg-myprofile-summCon">
                        <div className="dkg-myprofile-summ-btn-tagCon">
                        <Button className="dkg-desired-btntext">
                            India
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Ireland
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            UK
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            USA
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Grecee
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Germany
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Ireland
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            UK
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            USA
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        <Button className="dkg-desired-btntext">
                            Grecee
                            <span className="dkg-tag-icon">
                            
                            <i className="fas fa-times"></i>
                            </span>
                        </Button>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default DesiredTab