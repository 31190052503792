import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './projectionsnavbar.scss';

const ItTeamMainMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK Global CRM";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-recruitmentNavbar">
                <Route>
                    <ul>
                        <li><Link to="/projections/summary" className={`${(segment2 === 'summary') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="#/projections/templates" className={`${pathname.match('/templates') ? 'active' : ''}`}>Company</Link></li>
                        <li><Link to="#/projections/ads-resources" className={`${pathname.match('/ads-resources') ? 'active' : ''}`}>Job Title</Link></li>
                        <li><Link to="#/projections/titles" className={`${pathname.match('/titles') ? 'active' : ''}`}>Salaries</Link></li>
                        <li><Link to="#/projections/saved-ads" className={(segment2 === "saved-ads") ? 'active' : ''}>Benefits</Link></li>
                        <li><Link to="/projections/income-tax" className={`${pathname.match('/income-tax') ? 'active' : ''}`}>Taxes %</Link></li>
                        <li><Link to="#/projections/ads-credits" className={`${pathname.match('/ads-credits') ? 'active' : ''}`}>VISA Info</Link></li>
                        <li><Link to="#/projections/live-tracker" className={`${pathname.match('/live-tracker') ? 'active' : ''}`}>Relocation</Link></li>
                        <li><Link to="#/projections/campaigns/live" className={(segment2 === "campaigns") ? 'active' : ''}>Documents</Link></li>
                        <li><Link to="#/projections/apps-tracker/daily-tracker" className={`${pathname.match('/apps-tracker') ? 'active' : ''}`}>Saved Files</Link></li>
                        <li><Link to="#/projections/apps-tracker/daily-tracker" className={`${pathname.match('/apps-tracker') ? 'active' : ''}`}>Training</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment >
    );
}

export default ItTeamMainMenu;