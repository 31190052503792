import React from 'react';
import LeftPanel from '../leftpanel/LeftPanel';
import Box from '@mui/material/Box';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import EstablishedLeftTabs from './detaillefttabs/EstablishedLeftTabs';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import './dayclosingmoods.scss';
import RecuitersNavbar from './navtabs/RecuitersNavbar';
import RequestLeftPanel from './leftpanel/RequestLeftPanel';

const WorkplanRequiters = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <React.Fragment>
            <div className="dk-companyDetailMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <RequestLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                <h3 className="title">Management</h3>
                                <Tabs defaultActiveKey="tabs_1">
                                    <Tab eventKey="tabs_1" title="Details">
                                        <div className="d-flex">
                                            <EstablishedLeftTabs />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tabs_2" title="Samples">
                                        <div className="d-flex">
                                            <EstablishedLeftTabs />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tabs_3" title="Video">
                                        <div className="d-flex">
                                            <EstablishedLeftTabs />
                                        </div>
                                    </Tab>
                                </Tabs>
                                <div title="More" className="clickMoreShow">
                                    More
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </React.Fragment>
    )
}
export default WorkplanRequiters;

