import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
// import './workplantasktitle.scss'
import TaskForDropdown from '../../../../elements/header/modal/actionrecord/taskdropdown/TaskForDropdown'
import NativeDatePicker from '../../../../ui/nativedatepicker/DatePicker'
import NativeTimePicker from '../../../../ui/nativedatepicker/TimePicker'
import FormStatusDiv from '../../utils/statusDiv';
import FormProgressDiv from '../../utils/progressDiv';
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../../../ui/submitButton";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import moment from "moment-timezone";
import AWS from 'aws-sdk'
import { clearState, updateTask, taskTypeFetch } from "../../../../../slice/task/taskSlice";

import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import TaskTypeDropdown from "./taskdropdown/TaskTypeDropdown";
import CategoryDropdown from "./taskdropdown/CategoryDropdown";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const EditTaskModal = (props) => {
    console.log(props.data.type)
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth)
    const { isError, isSuccess, isUpdate, taskTypeList, message } = useSelector(state => state.task)

    const fileInputAPI = useRef();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [countUser, setCountUser] = useState(0)
    const [selectUserId, setSelectUserId] = useState([])
    const [selectUserImg, setSelectUserImg] = useState([])
    const [subject, setSubject] = useState(props.data.subject)

    const [taskType, setTaskType] = useState(props.data.type)
    const [type, setType] = useState(props.data.taskType)
    const [taskBy, setTaskBy] = useState(userInfo.id)
    const [details, setDetails] = useState('')
    const [taskDueDate, setTaskDueDate] = useState(moment(new Date()).format('DD MMM YYYY'))

    const [dueTime, setDueTime] = useState(moment(new Date()).format('hh:m A'))
    const [pickerDueTime, setPickerDueTime] = useState(new Date())
    const [isAttachment, setIsAttachment] = useState('0')
    const [attachment, setAttachment] = useState([])

    useEffect(() => {
        setSubject(props.data.subject)
        setType(props.data.taskType)
        //setTaskType(props.data.type)
        setTaskDueDate(props.data.taskDueDate)
        setDueTime(props.data.dueTime)
    }, [props.data.subject, props.data.taskType, props.data.dueTime, props.data.taskDueDate])

    const handleDateChange = (date) => {
        setTaskDueDate(moment(date).format('DD MMM YYYY'));
    };

    const handleTimeChange = (date) => {
        setPickerDueTime(date)
        setDueTime(moment(date).format('hh:m A'));
    };

    useEffect(() => {
        const empty = arr => arr.length = 0;
        empty(selectUserId)
        if ((props.type != "Others Task") && (props.type != "IT Team") && (props.type != "DK GLOBAL")) {
            selectUserId.push(userInfo.id)
        } else {
            const empty = arr => arr.length = 0;
            empty(selectUserId)
        }

    }, [props.type])

    // useEffect(() => {
    //     if (props.type !== "Others Task" || props.type !== "Group Task") {
    //         selectUserId.push(userInfo.id)
    //     }
    // }, [props.type])

    const onSelectFile = async (e) => {
        try {
            let s3bucket = new AWS.S3({
                accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
                secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
                Bucket: process.env.REACT_APP_BUCKET_NAME
            });
            const dataKey = Date.now() + '-' + (e.target.files[0].name).replace(/ /g, "_");
            s3bucket.createBucket(function () {
                var params = {
                    Bucket: process.env.REACT_APP_BUCKET_NAME + "/tasks",
                    Key: dataKey,
                    Body: e.target.files[0],
                    ContentType: e.target.files[0].type
                };
                s3bucket.upload(params, function (err, data) {
                    if (err) {
                        console.log(err)
                    } else {
                        setIsAttachment('1')
                        attachment.push("/tasks/" + dataKey)
                    }
                });
            });
        } catch (err) {
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }

    useEffect(() => {
        if (isUpdate) {
            dispatch(clearState())
            setLoading(false)
        }
    }, [isUpdate])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {

            setLoading(true);
            // await dispatch(addNewTask({ type, subject, details, taskDueDate, dueTime, taskBy, taskFor: selectUserId, isAttachment, attachment }))
            await dispatch(updateTask({ id: props.data.id, type, subject, taskType, taskDueDate, dueTime, taskBy, taskFor: selectUserId, isAttachment: isAttachment, attachment }))
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }

    const selectUser = (id, src, name) => {
        selectUserId.push(id)
        selectUserImg.push(src)
        setCountUser(countUser + 1)
    }

    useEffect(() => {
        if (isSuccess) {
            setSuccess(false);
            setLoading(false);
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Task added successfully.' }))
        }
    }, [isSuccess])

    const selectTaskTypeFilter = async (e) => {
        setType(e)
    }
    //    const selectTaskCategoryFilter = async (e) => {    
    //     setType("")
    //         switch(e){
    //             case 'DK Global' :
    //                 dispatch(taskTypeFetch({ id: 144 }))
    //             break;
    //             case 'Others Task':
    //                 dispatch(taskTypeFetch({ id: 145 }))
    //             break;
    //             case 'Accounting':
    //                 dispatch(taskTypeFetch({ id: 155 }))
    //             break;
    //             case 'IT Team':
    //                 dispatch(taskTypeFetch({ id: 161 }))
    //             break;
    //             case 'DK Admin':
    //                 dispatch(taskTypeFetch({ id: 148 }))
    //             break;
    //             case 'Clients':
    //                 dispatch(taskTypeFetch({ id: 149 }))
    //             break;
    //             case 'Candidates':
    //                 dispatch(taskTypeFetch({ id: 150 }))
    //             break;
    //             case 'Suppliers':
    //                 dispatch(taskTypeFetch({ id: 151 }))
    //             break;

    //         }
    //         setTaskType(e)
    //    }


    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="dk-workplantasktitleModal"
            >
                <Modal.Header closeButton className='dk-tasktitleModalHeader'>
                    <Modal.Title>EDIT TASK</Modal.Title>
                    {/* <Modal.Title>Edit {props.data.type}</Modal.Title> */}
                </Modal.Header>
                <Modal.Body className='dk-tasktitleModalBody'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='from-group'>
                                <label className='dk-cre-newtask-label-center'>Task Status</label>
                                <FormProgressDiv value={props.data.progress} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group dk-create-date-timeFrmGrp">
                                <label htmlFor="" className='dk-cre-newtask-label-center'>Task Progress</label>
                                <div className="dk-taskSwitch ddd" >
                                    <FormStatusDiv value={props.data.status} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='from-group'>
                                <label className='dk-cre-newtask-label-center'>Task Category</label>
                                <input type="text" className="form-control dk-subjectFrmControl" value={props.data.type} aria-describedby="basic-addon1" readOnly />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='from-group'>
                                <label className='dk-cre-newtask-label-center'>Task Type</label>
                                <TaskTypeDropdown value={type} selectTaskTypeFilter={selectTaskTypeFilter} />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='d-flex'>
                                <div className='from-group w-100 pr-1'>
                                    <label className='w-100 text-center'>Task By</label>
                                    <div className="dk-taskByCon">
                                        <div className="dk-selectator-textlength">
                                            <div className="dk-selected-items">
                                                <div className="userImgCont">
                                                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + userInfo.profileImage} alt={userInfo.firstName} className="userImg" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='from-group w-100 pl-1'>
                                    <label className='w-100 text-center'>Task For</label>
                                    <TaskForDropdown taskType={props.data.type} selectUser={selectUser} countUser={countUser} selectUserImg={selectUserImg} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group dk-create-date-timeFrmGrp">
                                <label className='w-100 text-center'>Attachments</label>
                                <label for="" className="dk-taskChoosefile w-100 text-center">
                                    <div className="file-select-name">No file chosen...</div>
                                    <input type="file" name="attachmentFile" /><span>Attach a file  <i className="ml-3 fas fa-upload"></i></span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group dk-create-date-timeFrmGrp">
                                <label htmlFor="" className='dk-meeting-date-timelabel'>Task Due Date</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container alignItems="center" justify="center" className='dk-nativedatepicker-grid'>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="d MMM yy"
                                            margin="normal"
                                            id="date-picker-inline dk-startDatenativepicker"
                                            className="dk-nativedatepicker"
                                            value={taskDueDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            autoOk={true}
                                            error={false}
                                            helperText={null}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group dk-create-date-timeFrmGrp">
                                <label htmlFor="" className='dk-meeting-date-timelabel'>Task Due Time</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container alignItems="center" justify="center" className='dk-nativetimeepicker-grid'>
                                        <KeyboardTimePicker
                                            // disableToolbar
                                            variant="inline"
                                            margin="normal"
                                            id="time-picker"
                                            className="dk-nativetimeepicker"
                                            value={pickerDueTime}
                                            onChange={handleTimeChange}
                                            keyboardIcon={<AccessTimeRoundedIcon />}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            autoOk={true}
                                            error={false}
                                            helperText={null}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group dk-create-date-timeFrmGrp">
                                <label for="">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Subject</span>
                                        </div>
                                        <input type="text" className="form-control dk-subjectFrmControl" value={subject} aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => setSubject(e.target.value)} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        {/* <div className="col-md-12 mt-2">
                            <div className="form-group dk-create-date-timeFrmGrp">
                                <textarea className="form-control" id="txtCreateTaskDetails" value={props.data.details} cols="20"></textarea>
                            </div>
                        </div> */}
                        <div className="col-md-12 mt-2">
                            <div className="form-group dk-createNewTaskFooter">
                                {/* <button type="button" className="btn dk-create-new-taskbtn">Update</button> */}
                                <SubmitButton txt="Save" loading={loading} success={success} onClick={handleSubmit} className="btn dk-create-new-taskbtn" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditTaskModal;

