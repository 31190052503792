import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData } from "../../services";

export const getCategories = createAsyncThunk(
    "clientSendouts/frontend/requirnment/categoryDemandlist",
    async (thunkAPI) => {
        const res = await GetData(true, "/category-europe-forms");
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getdefaultSubCategories = createAsyncThunk(
    "clientSendouts/frontend/requirnment/subcatlist",
    async ({ keys }, thunkAPI) => {
        const res = await GetData(true, "/subcategory-europe-forms/default/" + keys);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getSubCategories = createAsyncThunk(
    "clientSendouts/frontend/requirnment/subcatlist",
    async ({ categoryId, keys }, thunkAPI) => {
        const res = await GetData(true, "/subcategory-europe-forms/" + categoryId + '/' + keys);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getSubCatDetails = createAsyncThunk(
    "clientSendouts/frontend/requirnment/detailsbyid",
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, "/subcategory-europe-forms/" + id);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


const usersSlice = createSlice({
    name: "clientSendout",
    initialState: {
        categoryReqList: [],
        subCategoryReqList: [],
        subCategoryDetails: [],
        isSuccess: false,
        isTabActive: false,
        isCatInsert: false,
        isSubCatInsert: false,
        isCatUpdate: false,
        isSubCatUpdate: false,
        isLoading: false,
        issLoading: false,
        isSubLoading: false,
        isSubReoder: false,
        isError: false,
        isDelete: false,
        issDelete: false,
        isReoder: false,
    },
    reducers: {
        clearState: (state) => {
            state.isSubReoder = false;
            state.isDetails = false;
            state.isSubLoading = false;
            state.issLoading = false;
            state.isLoading = false;
            state.isSubCatInsert = false;
            state.isSuccess = false;
            state.isTabActive = false;
            state.isError = false;
            state.isCatInsert = false;
            state.isSubCatUpdate = false;
            state.isCatUpdate = false;
            state.isDelete = false;
            state.issDelete = false;
            state.isReoder = false;
        },
    },
    extraReducers: {

        [getSubCatDetails.pending]: (state) => {
            state.isError = false;
            state.isDetails = false;
        },
        [getSubCatDetails.fulfilled]: (state, { payload }) => {
            state.isDetails = true;
            state.subCategoryDetails = payload.result;
        },
        [getSubCatDetails.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isDetails = false;
        },

        [getdefaultSubCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isTabActive = false;
        },
        [getdefaultSubCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isTabActive = true;
            state.isSuccess = true;
            state.subCategoryReqList = payload.result;
        },
        [getdefaultSubCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.isTabActive = false;
        },

        [getSubCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isTabActive = false;
        },
        [getSubCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isTabActive = true;
            state.isSuccess = true;
            state.subCategoryReqList = payload.result;
        },
        [getSubCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.isTabActive = false;
        },


        [getCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.categoryReqList = payload.result;
        },
        [getCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

    },
});
export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
