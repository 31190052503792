import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import InfoModal from './InfoModal';
import './pipelinesmanagement.scss';

const CommonPipelines = () => {
    const [infoModal, setShowInfoModal] = useState(false)
    const ShowInfoModal = () => {
        setShowInfoModal(true)
    }
    const HideInfoModal = () => {
        setShowInfoModal(false)
    }
    return (
        <>
            {
                infoModal ?
                    <InfoModal handleClose={HideInfoModal} /> : null
            }

            <div className="dk-pipelinesMain dk-commonPipelinesMain">
                <div className="dk-pageheader">
                    <div className="dk-backBtn-cols">
                        <Link to="/training/commontraining/common-training"><i className="fas fa-arrow-left"></i> Go Back</Link>
                    </div>
                    <h3 className="title text-center">PIPELINES MANAGEMENT <i className="fas fa-info-circle" onClick={ShowInfoModal}></i></h3>
                    <div className="dk-teamrolerightBtnCon">
                        <div className="dk-teamroleseditBtn">
                            <Link to="/training/commontraining/edit-pipelines"><i className="far fa-edit"></i> Edit</Link>
                        </div>
                    </div>
                </div>

                <div className="dk-pipelinesTableMain">
                    <table className="table dk-processTable table-bordered">
                        <thead>
                            <tr>
                                <th>Stages</th>
                                <th>Pipelines</th>
                                <th>Team</th>
                                <th>Summary</th>
                                <th>Step By Step Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="stage1Bg">Stage 1</td>
                                <td className="stage1Bg">New CV's</td>
                                <td>
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-procssSuMMCols">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-processactCols">
                                        <ul>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="stage2Bg">Stage 2</td>
                                <td className="stage2Bg">SELECTED</td>
                                <td>
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-procssSuMMCols">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-processactCols">
                                        <ul>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="stage3Bg">Stage 3</td>
                                <td className="stage3Bg">SCREENING</td>
                                <td>
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-procssSuMMCols">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-processactCols">
                                        <ul>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="stage4Bg">Stage 4</td>
                                <td className="stage4Bg">RECRUITERS</td>
                                <td>
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-procssSuMMCols">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-processactCols">
                                        <ul>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="stage5Bg">Stage 5</td>
                                <td className="stage5Bg">ACCOUNT M</td>
                                <td>
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-procssSuMMCols">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-processactCols">
                                        <ul>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="stage6Bg">Stage 6</td>
                                <td className="stage6Bg">JOB OFFER</td>
                                <td>
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-procssSuMMCols">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-processactCols">
                                        <ul>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="stage7Bg">Stage 7</td>
                                <td className="stage7Bg">ESCALATED</td>
                                <td>
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-procssSuMMCols">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-processactCols">
                                        <ul>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="onholdBg">Stage 8</td>
                                <td className="onholdBg">ON HOLD</td>
                                <td>
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-procssSuMMCols">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-processactCols">
                                        <ul>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="stage8Bg">Stage 9</td>
                                <td className="stage8Bg">CLOSED CASE</td>
                                <td>
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                        <div className="dk-team1">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" alt="Team" className="team-img" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-procssSuMMCols">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                    </div>
                                </td>
                                <td>
                                    <div className="dk-processactCols">
                                        <ul>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                            <li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li><li>
                                                <a>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default CommonPipelines;
