import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './dbmainmenu.scss';

const KnowleadgeBaseMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-dbNavbar">
                <Route>
                    <ul>
                        <li><Link to="/database/summary" className={`${(segment2 === 'summary') ? 'active' : ''}`}>Summary</Link></li>
                        <li><Link to="#/database/alerts" className={`${(segment2 === 'alerts') ? 'gray-active' : ''}`}>Database 2</Link></li>
                        <li><Link to="#/database/clients" className={`${(segment2 === 'clients') ? 'gray-active' : ''}`}>Database 3</Link></li>
                        <li><Link to="#/database/invoices" className={`${(segment2 === 'invoices') ? 'gray-active' : ''}`}>Database 4</Link></li>
                        <li><Link to="#/database/incomes" className={(segment2 === "incomes") ? 'gray-active' : ''}>Database 5</Link></li>
                        <li><Link to="#/database/expense" className={(segment2 === "expense") ? 'gray-active' : ''}>Database 6</Link></li>
                        <li><Link to="#/database/vat-file" className={(segment2 === "vat-file") ? 'gray-active' : ''}>Database 7</Link></li>
                        <li><Link to="#/database/bank-state" className={(segment2 === "bank-state") ? 'gray-active' : ''}>Database 8</Link></li>
                        <li><Link to="#/database/information" className={(segment2 === "information") ? 'gray-active' : ''}>Database 9</Link></li>
                        <li><Link to="#/database/Settings" className={(segment2 === "settings") ? 'gray-active' : ''}>Database 10</Link></li>
                        <li><Link to="#/database/training" className={(segment2 === "training") ? 'gray-active' : ''}>Database 11</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default KnowleadgeBaseMenu;