import React from 'react';
// import "./clientinvoicesumm.scss";

const IncomeDashboard = () => {
    return (
        <>
            <div className="dk-clientInvoiceDashboard-main">
                <div className="dk-clientInvoice-flex">
                    <div className="dk-box-cols">
                        <div className="d-flex w-100">
                            <div className="dk-invoice-box">
                                <div className="title">Total Clients <span>509</span></div>
                                <ul></ul>
                            </div>
                            <div className="dk-invoice-box">
                                <div className="title">Funds Available <span>509</span></div>
                                <ul></ul>
                            </div>
                            <div className="dk-invoice-box">
                                <div className="title">Funds Locked<span>509</span></div>
                                <ul></ul>
                            </div>
                            <div className="dk-invoice-box">
                                <div className="title">Ledger balance <span>509</span></div>
                                <ul></ul>
                            </div>
                        </div>
                        <div className="d-flex w-100">
                            <div className="dk-invoice-box">
                                <div className="title">Total Collection <span>509</span></div>
                                <ul></ul>
                            </div>
                            <div className="dk-invoice-box">
                                <div className="title">Paid to DKGlobal <span>509</span></div>
                                <ul></ul>
                            </div>
                            <div className="dk-invoice-box">
                                <div className="title">Total Bibby Fee <span>509</span></div>
                                <ul></ul>
                            </div>
                            <div className="dk-invoice-box">
                                <div className="title">Services From<span>509</span></div>
                                <ul></ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IncomeDashboard;
