import React from 'react';
import { Link } from "react-router-dom";
import '../salessendouts.scss';

const TasksLeftPanel = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <>
            <div className="dk-tasksLeftpanel">
                <ul>
                    {/* <li><Link to="/kpis/tasks/summary" className={`${(segment3 === 'summary') ? 'active' : ''}`}>Summary</Link></li> */}
                    {/* <li><Link to="/kpis/tasks/clients" className={`${(segment3 === 'clients') ? 'active' : ''}`}>Clients</Link></li> */}
                    {/* <li><Link to="/kpis/tasks/assigned" className={`${(segment3 === 'assigned') ? 'active' : ''}`}>SUMMARY</Link></li> */}
                    <li><Link to="/kpis/tasks/assigned" className={`${(segment3 === 'assigned') ? 'active' : ''}`}>Assigned</Link></li>
                    <li><Link to="/kpis/tasks/qualified" className={`${(segment3 === 'qualified') ? 'active' : ''}`}>Qualified</Link></li>
                    <li><Link to="/kpis/tasks/job-specs" className={`${(segment3 === 'job-specs') ? 'active' : ''}`}>Job Specs</Link></li>
                    <li><Link to="/kpis/tasks/sendouts" className={`${(segment3 === 'sendouts') ? 'active' : ''}`}>Sendouts</Link></li>
                    <li><Link to="/kpis/tasks/team-sale" className={`${(segment3 === 'team-sale') ? 'active' : ''}`}>Job Offers</Link></li>
                    <li><Link to="/kpis/tasks/refused" className={`${(segment3 === 'refused') ? 'active' : ''}`}>Refused</Link></li>
                    <li><Link to="/kpis/tasks/joined" className={`${(segment3 === 'joined') ? 'active' : ''}`}>Joined</Link></li>
                    <li><Link to="/kpis/tasks/credit" className={`${(segment3 === 'credit') ? 'active' : ''}`}>Credit</Link></li>
                    {/* <li><Link to="/kpis/tasks/stats-report" className={`${(segment3 === 'stats-report') ? 'active' : ''}`}>Stats Report</Link></li>
                    <li><Link to="/kpis/tasks/stats-details" className={`${(segment3 === 'stats-details') ? 'active' : ''}`}>Stats Details</Link></li>
                    <li><Link to="/kpis/tasks/case-details" className={`${(segment3 === 'case-details') ? 'active' : ''}`}>Case Details</Link></li> */}
                </ul>
            </div>
        </>
    )
}

export default TasksLeftPanel
