import React from 'react';
import './dayclosingmoods.scss';
import RecuitersNavbar from './navtabs/RecuitersNavbar';
import RequestLeftPanel from './leftpanel/RequestLeftPanel';


const WorkplanRequest = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <React.Fragment>

            {/* <div className="dk-companyDetailMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <RequestLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                <h3 className="title">Overview</h3>
                                <div className="dk-trainingInformation-cols">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid similique dolore laboriosam reprehenderit sapiente at iusto? Soluta tempora eveniet suscipit quae aliquid provident rem exercitationem ipsa eos aspernatur? Necessitatibus, optio?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div> */}
        </React.Fragment>
    )
}
export default WorkplanRequest;

