import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./teamroles.scss";

const DkGlobalProcess = () => {
    return (
        <>

            <div className="dk-rolesresponPageMain dk-trainingTeamRolesMain dk-trainingTeamRolesMainRecruitment">
                <div className="dk-pageheader">
                    <h3 className="title text-center">TEAMS & ROLES SUMMARY</h3>
                    <div className="dk-teamrolerightBtnCon">
                        <div className="dk-teamroleseditBtn">
                            {/* <Link to="/dkglobal/teams/edit-teams"><i className="far fa-edit"></i> Edit</Link> */}
                        </div>
                    </div>
                </div>
                <div className="dk-rolesresponTableCon dk-mainmodule table-responsive">
                    <table className="table dk-rolesresponTable table-bordered">
                        <thead className="dk-rolesTeamHeader">
                            <tr>
                                <th></th>
                                <th>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 1</div>
                                    <div className="dk-rolesTeamHeaderTitle">DK GLOBAL ADMIN</div>
                                </th>
                                <th>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 2</div>
                                    <div className="dk-rolesTeamHeaderTitle">RECRUITMENT AMDIN</div>
                                </th>
                                <th>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 3</div>
                                    <div className="dk-rolesTeamHeaderTitle">RECRUITMENT CONSULTANT</div>
                                </th>
                                <th>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 4</div>
                                    <div className="dk-rolesTeamHeaderTitle">INTERVIEWS MANAGER</div>
                                </th>
                                <th>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 5</div>
                                    <div className="dk-rolesTeamHeaderTitle">ACCOUNTS MANAGERS</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>TEAM MANAGER</td>
                                <td>
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/1623316236.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team2BG light">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team3BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team5BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team4BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>TEAM LEADER</td>
                                <td>
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/1623316236.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team2BG light">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team3BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team5BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team4BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>TEAM MATES</td>
                                <td>
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/1623316236.jpg" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1523633534-luiza.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team2BG light">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5e54fdac9c.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team3BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5e54fdac9c.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team5BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5e54fdac9c.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team4BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>MAIN TASK</td>
                                <td><p>JOBS, ACCOUNTS</p></td>
                                <td><p>FILTER NEW LEADS</p></td>
                                <td><p>QUALIFY THE LEADS</p></td>
                                <td><p>QUALIFY THE LEADS</p></td>
                                <td><p>CLOSE THE LEADS</p></td>
                            </tr>
                            <tr>
                                <td>MOVE CASE</td>
                                <td><p>MOVE TO SELECTED</p></td>
                                <td><p>MOVE TO SCREENING</p></td>
                                <td><p>MOVE TO AM PIPELINE</p></td>
                                <td><p>MOVE TO AM PIPELINE</p></td>
                                <td><p>MOVE TO OFFER PIPELINE</p></td>
                            </tr>
                            <tr>
                                <td>RESPONSIBILITIES</td>
                                <td>
                                    {/* <p>TASK 1 - MANAGE ADS</p>
                                    <p>TASK 2 - AD CAMPAIGN</p>
                                    <p>TASK 3 - ADS TRACKER</p>
                                    <p>TASK 4 - ADS BUDGET</p>
                                    <p>TASK 5 - PIPELINES KPI</p>
                                    <p>TASK 6 - MANAGE JOBS</p>
                                    <p>TASK 7 - MANAGE APPS</p>
                                    <p>TASK 8 - CREATE SPECS</p>
                                    <p>TASK 9 - SOCIAL MEDIA</p>
                                    <p>TASK 10 - DKG DATABASE</p> */}
                                </td>
                                <td>
                                    {/* <p>TASK 1 - UPDATE PROFILES</p>
                                    <p>TASK 2 - MATCH WITH JOBS</p>
                                    <p>TASK 3 - ASSIGN PRIORITY</p>
                                    <p>TASK 4 - MINI JOBS SPECS+SMS</p>
                                    <p>TASK 5 - PRIORITY SCREENING</p> */}
                                </td>
                                <td>
                                    {/* <p>TASK 1 - CASE QUERIES</p>
                                    <p>TASK 2 - INTERVIEW CALL</p>
                                    <p>TASK 3 - UPDATE PROFILE</p>
                                    <p>TASK 4 - FULL JOB SPEC</p>
                                    <p>TASK 5 - MAKE SENDOUT</p>
                                    <p>TASK 6 - CLIENTS PORTAL</p>
                                    <p>TASK 7 - NOT REACHABLE</p>
                                    <p>TASK 8 - LIVE NEW QUERY</p>
                                    <p>TASK 9 - CREATE JOB SPEC</p>
                                    <p>TASK 10 - CASE DECISION</p> */}
                                </td>
                                <td>
                                    {/* <p>TASK 1 - CASE QUERIES</p>
                                    <p>TASK 2 - INTERVIEW CALL</p>
                                    <p>TASK 3 - UPDATE PROFILE</p>
                                    <p>TASK 4 - FULL JOB SPEC</p>
                                    <p>TASK 5 - MAKE SENDOUT</p>
                                    <p>TASK 6 - CLIENTS PORTAL</p>
                                    <p>TASK 7 - NOT REACHABLE</p>
                                    <p>TASK 8 - LIVE NEW QUERY</p>
                                    <p>TASK 9 - CREATE JOB SPEC</p>
                                    <p>TASK 10 - CASE DECISION</p> */}
                                </td>
                                <td>
                                    {/* <p>TASK 1 - REVIEW SEND OUT</p>
                                    <p>TASK 2 - SUBMIT SEND OUT</p>
                                    <p>TASK 3 - SEND INTERVIEW PREP</p>
                                    <p>TASK 4 - SHEDULE INTERVIEWS</p>
                                    <p>TASK 5 - PREPARE INTERVIEWS</p>
                                    <p>TASK 6 - ASK JOB DEMAND</p>
                                    <p>TASK 7 - CLIENTS FEEDBACK</p>
                                    <p>TASK 8 - UPDATE CANDIDATES</p>
                                    <p>TASK 9 - CLIENTS PROJECTION</p>
                                    <p>TASK 10 - CANDIDATE DOCUMENTS</p> */}
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default DkGlobalProcess;
