import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const ReviewProfilesDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/training/admins/review-profiles" className={`${pathname.match('/training/admins/review-profiles') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/training/admins/reviewprofiles/contacts" className={`${pathname.match('/training/admins/reviewprofiles/contacts') ? 'active' : ''}`}>Contact</Link></li>

                    <li><Link to="/training/admins/reviewprofiles/location" className={`${pathname.match('/training/admins/reviewprofiles/location') ? 'active' : ''}`}>Location</Link></li>

                    <li><Link to="/training/admins/reviewprofiles/languages" className={`${pathname.match('/training/admins/reviewprofiles/languages') ? 'active' : ''}`}>Languages</Link></li>

                    <li><Link to="/training/admins/reviewprofiles/job-titles" className={`${pathname.match('/training/admins/reviewprofiles/job-titles') ? 'active' : ''}`}>Job Titles</Link></li>

                    <li><Link to="/training/admins/reviewprofiles/job-experience" className={`${pathname.match('/training/admins/reviewprofiles/job-experience') ? 'active' : ''}`}>Experience</Link></li>

                    <li><Link to="/training/admins/reviewprofiles/qualifications" className={`${pathname.match('/training/admins/reviewprofiles/qualifications') ? 'active' : ''}`}>Qualifications</Link></li>

                    <li><Link to="/training/admins/reviewprofiles/technical-skills" className={`${pathname.match('/training/admins/reviewprofiles/technical-skills') ? 'active' : ''}`}>Technical Skills</Link></li>

                    <li><Link to="/training/admins/reviewprofiles/other-sections" className={`${pathname.match('/training/admins/reviewprofiles/other-sections') ? 'active' : ''}`}>Other Sections</Link></li>

                    <li><Link to="/training/admins/reviewprofiles/career-seniority" className={`${pathname.match('/training/admins/reviewprofiles/career-seniority') ? 'active' : ''}`}>Career Seniority</Link></li>

                    <li><Link to="/training/admins/reviewprofiles/live-job-suitability" className={`${pathname.match('/training/admins/reviewprofiles/live-job-suitability') ? 'active' : ''}`}>Live Jobs Suitability</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default ReviewProfilesDetailLeftPanel;

