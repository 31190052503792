import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';

import { updateValue, clearState } from "../../../../../slice/kpis/recruterSummarySlice";
import Submit from "../../../../ui/submitButton";
import { showSuccess, showError } from "../../../../../slice/utils/message/messageSlice";
import { FaTimes } from "react-icons/fa";

const UpdatePop = ({ onHide, rowId, columnKey, value }) => {

    const dispatch = useDispatch();
    const { isUpdate, isInsert, isError, errorMessage } = useSelector(state => state.recruterKpiSummary)

    const inputRef = useRef();
    useEffect(() => {
        inputRef.current.focus();
    })

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [newValue, setNewValue] = useState("");
   // const [show, setShow] = useState(false);
    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "newValue":
                setNewValue(value)
                break;
            default:
                break;
        }
    }

    const handleButtonClick = async (e) => {
        e.preventDefault()
        setSuccess(false);
        setLoading(true);
        //const dataVal = { columnKey: newValue }
        await dispatch(updateValue({ id: rowId, key: columnKey, value: newValue }))
    }

    useEffect(() => {
        if (isUpdate) {
            dispatch(showSuccess({ msg: 'Record update successfully.' }))
            dispatch(clearState())
            onHide()
            setSuccess(true);
            setLoading(false);
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }
    }, [isUpdate, isError])

    // const handleClose = () => {
    //     console.log("Closed Update Popup 12")
    //     setShow(false)
    // };
    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-adsCreAddNewPop"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >

                <Modal.Body>
                    <span className='dkg-crosssicon-con' onClick={onHide}><FaTimes /></span>
                    <form action="">
                        <div className="dk-adsCreditInCon">
                            <div className="firstInput">
                                <label>Current</label>
                                <input type="text" className="form-control" value={value} readOnly />
                            </div>
                            <div className="SecondInput">
                                <label>New</label>
                                <input type="text" className="form-control" defaultValue="" name="newValue" value={newValue} onChange={handleChange} ref={inputRef} />
                            </div>
                            <div className="dk-adsCreditBtnCon">
                                <Submit txt="SAVE" loading={loading} success={success} onClick={handleButtonClick} className="btn  dk-calcsaveBtn" position="justify-content-center" />
                            </div>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default UpdatePop;
