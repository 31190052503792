import React from 'react';
import './casestatus.scss';

const CaseStatus = ({ value }) => {
    let bgColor = "";
    switch (value) {
 
        case 'Qualified':
            bgColor = '#5c737d'
            break;
       
        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor }}>{value}</div>
        </>
    )
}

export default CaseStatus;