import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const ProfileIndicatorFilter = () => {
    return (
        <Dropdown className="dk-pipe-profileind-dropdown14">
            <Dropdown.Toggle variant="" className="dk-pipe-profileind-dropdown-toggle-14">
                <i className="fas fa-circle"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dk-pipe-profileind-dropdown-menu14">
                <div className='d-flex'>
                    <Dropdown.Item href="#" className="dk-profileind-rtDropdownItem">
                        <i className="fas fa-circle" style={{ color: "green" }}></i>
                        <span className="proflieCount">Profile Updated</span>
                        <span className="proflieCount">
                            5
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#" className="dk-profileind-rtDropdownItem" >
                        <i className="fas fa-circle" style={{ color: "#d3d3d3" }}></i>
                        <span className="proflieCount">Blank</span>
                        <span className="proflieCount">
                            4
                        </span>
                    </Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}
export default ProfileIndicatorFilter;