import React from "react";
import { Link } from "react-router-dom";
// import './myacc-submenu.scss';

const ServiceSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    return (
        <React.Fragment>
            <ul className="dkg-myacc-submenu-ul">
                <li>
                    <Link to="/js-account/dkg-services/summary" className={`${(segment3 === 'summary') ? 'grey-active' : ''}`}>Home</Link>
                </li>
                <li>
                    <Link to="/js-account/dkg-services/create-cv" className={`${(segment3 === 'create-cv') ? 'blue-active' : ''}`}>Services</Link>
                </li>
                <li>
                    <Link to="/js-account/dkg-services/consultants" className={`${(segment3 === 'consultants') ? 'blue-active' : ''}`}>Consultants</Link>
                </li>
                <li className="pbp">
                    <Link to="/js-account/dkg-services/pricing" className={`${(segment3 === 'pricing') ? 'blue-active' : ''}`}>Pricing</Link> 
                </li>
                <li className="psp">
                    <Link to="/js-account/dkg-services/testimonial" className={`${(segment3 === 'testimonial') ? 'blue-active' : ''}`}>Testimonials</Link>
                </li>
                <li className="psp">
                    <Link to="/js-account/dkg-services/feedbacks" className={`${(segment3 === 'feedbacks') ? 'blue-active' : ''}`}>Contact us</Link>
                </li>
                <li>
                    <Link to="/js-account/dkg-services/search-job" className={`${(segment3 === 'search-job') ? 'blue-active' : ''}`}>Legal</Link>
                </li>
                <li className="psp">
                    <Link to="/js-account/dkg-services/faq-s" className={`${(segment3 === 'faq-s') ? 'blue-active' : ''}`}>FAQ’s</Link>
                </li>
                {/*
                <li className="psp">
                    <Link to="/js-account/dkg-services/work-abroad" className={`${(segment3 === 'work-abroad') ? 'blue-active' : ''}`}>Work Abroad</Link>
                </li>
                <li className="psp">
                    <Link to="/js-account/dkg-services/study-abroad" className={`${(segment3 === 'study-abroad') ? 'blue-active' : ''}`}>Study Abroad</Link>
                </li>
                <li className="psp">
                    <Link to="/js-account/dkg-services/plan-carrer" className={`${(segment3 === 'plan-carrer') ? 'blue-active' : ''}`}>Plan Career</Link>
                </li> */}
            </ul>
        </React.Fragment>
    );
}

export default ServiceSubmenu;
