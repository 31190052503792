import React, { useState, useEffect } from 'react'
import { Select } from 'semantic-ui-react'
import "./selectinstance.css";

const SourceTypeDropdown = ({ list, type, placeholder, name, onChange }) => {
    const [ddlList, setDdlList] = useState([]);
    useEffect(() => {
        var result = [];
        var map = new Map();
        for (var item of list) {
            if (!map.has(item.id)) {
                map.set(item.id, true);
                result.push({
                    key: item.id,
                    value: item.value,
                    text: item.value,
                    style: {
                        color: item.textColor,
                        backgroundColor: item.bgColor
                    }
                })
            }
        }
        result.unshift({
            key: '0',
            value: '',
            text: placeholder,
            style: {
                display: 'none'
            }
        })
        setDdlList(result)
    }, [list])
    return (

        <React.Fragment>
            <Select placeholder={placeholder} clearable options={ddlList} className={`dk-overdues-task-slect`} name={name} onChange={onChange} />
        </React.Fragment>

    )
}

export default SourceTypeDropdown;
