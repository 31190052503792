import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Nav, Tab } from 'react-bootstrap';
import { MdInsertDriveFile } from "react-icons/md";
import { FaLevelUpAlt, FaCheck, FaCheckCircle, FaSpinner, FaRegDotCircle } from "react-icons/fa";

const RightModalFilter = ({ list, selectUserFilter, selectOtherFilter }) => {


    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
    }
    return (
        <React.Fragment>
            <button className="dk-cricleBtn" onClick={handleShow}>
                <i className="fa fa-filter"></i>
            </button>
            <div className="dk-jobsMainModalCon">
                <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName="dk-pipenwqrightFliterModal"
                >
                    <Modal.Header closeButton className="dkmb-Fliterheader">
                        <Modal.Title className="dkmb-FliterTitle">
                            SEARCH & REPORT
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="dkmb-Fliterbody dkg-pipelinesRightFilterBody-13">
                        <Tab.Container id="dk-jobsRightFilterModal" defaultActiveKey="pay-createddate">
                            <div className="row ml-0 mr-0">
                                <div className="col-md-4 pl-0 pr-0">
                                    <Nav variant="pills" className="flex-column dk-leftSideMenu dkg-right-sideFilter-13">
                                        <Nav.Item>
                                            <Nav.Link eventKey="pay-createddate">
                                                <i className="fa fa-calendar mr-2" aria-hidden="true"></i>Created
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="pay-duedate">
                                                <i className="fa fa-calendar mr-2" aria-hidden="true"></i>DUE ON
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="pay-by">
                                                <i className="fa fa-user mr-2" aria-hidden="true"></i>BY
                                            </Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item>
                                            <Nav.Link eventKey="pay-for">
                                                <i className="fa fa-user mr-2" aria-hidden="true"></i>FOR
                                            </Nav.Link>
                                        </Nav.Item> */}
                                        <Nav.Item>
                                            <Nav.Link eventKey="pay-status">
                                                <FaSpinner className="mr-2" />Status
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="pay-priority">
                                                <FaCheck className='mr-2' />PRIORITY
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="dkg-category-pay">
                                                <i className="fa fa-language mr-2" aria-hidden="true"></i>Category
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="dkg-pay-type">
                                                <i className="fa fa-user mr-2" aria-hidden="true"></i>Pay Type
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="dkg-pay-paymentto">
                                                <i className="fa fa-user mr-2" aria-hidden="true"></i>Payment TO
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="pay-tabMonth">
                                                <i className="fa fa-calendar mr-2" aria-hidden="true"></i>MONTH
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="pay-tabYear">
                                                <i className="fa fa-calendar mr-2" aria-hidden="true"></i>YEAR
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                <div className="col-md-8 pl-0 pr-0">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="pay-createddate">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                27 Feb 23
                                                                <span className="text-orange" data-type="company">14</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                01 Mar 23
                                                                <span className="text-orange" data-type="company">14</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="pay-duedate">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                28 Feb 23
                                                                <span className="text-orange" data-type="company">14</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                31 Mar 23
                                                                <span className="text-orange" data-type="company">14</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="pay-by">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-Recuriter">
                                                    <ul>
                                                        <li>
                                                            <Link to="#">
                                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/user.png" alt="" className="recImg" />
                                                                <span className='recuriterNo text-orange'>23</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">
                                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" className="recImg" />
                                                                <span className='recuriterNo text-orange'>23</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">
                                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/1580424792.jpeg" alt="" className="recImg" />
                                                                <span className='recuriterNo text-orange'>23</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        {/* <Tab.Pane eventKey="pay-for">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-Recuriter">
                                                    <ul>
                                                        <li>
                                                            <Link to="#">
                                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/user.png" alt="" className="recImg" />
                                                                <span className='recuriterNo text-orange'>23</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">
                                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" className="recImg" />
                                                                <span className='recuriterNo text-orange'>23</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">
                                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/1580424792.jpeg" alt="" className="recImg" />
                                                                <span className='recuriterNo text-orange'>23</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane> */}
                                        <Tab.Pane eventKey="pay-status">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-satusListFilter">
                                                    <ul>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#d58b5d", color: "#fff" }}>New Task<span className="text-orange">12</span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#625c60", color: "#fff" }}>Queries<span className="text-orange">12</span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#26597d", color: "#fff" }}>Accepted<span className="text-orange">12</span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#715f8f", color: "#fff" }}>Partially Paid<span className="text-orange">12</span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#3a9469", color: "#fff" }}>Fully Paid<span className="text-orange">12</span></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="pay-priority">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-satusListFilter">
                                                    <ul>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#3a9469", color: "#fff" }}>Normal<span className="text-orange">8</span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#d91010", color: "#fff" }}>Queries<span className="text-orange">14</span></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-category-pay">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                DK Admin Team
                                                                <span className="text-orange" data-type="company">8</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                DK Rec Team
                                                                <span className="text-orange" data-type="company">18</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                DK IT Team
                                                                <span className="text-orange" data-type="company">67</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                Job Board
                                                                <span className="text-orange" data-type="company">36</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                IT Services
                                                                <span className="text-orange" data-type="company">34</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                Candidate
                                                                <span className="text-orange" data-type="company">14</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                Social Media
                                                                <span className="text-orange" data-type="company">28</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                Accountant
                                                                <span className="text-orange" data-type="company">18</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                IRE Tax Office
                                                                <span className="text-orange" data-type="company">28</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                DKG License
                                                                <span className="text-orange" data-type="company">18</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                Business Loan
                                                                <span className="text-orange" data-type="company">5</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                DKG Credit Card
                                                                <span className="text-orange" data-type="company">6</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                Director Expense
                                                                <span className="text-orange" data-type="company">14</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-pay-type">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                Monthy Salary
                                                                <span className="text-orange" data-type="company">8</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                Sendout Bonus
                                                                <span className="text-orange" data-type="company">18</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                Placement Bonus
                                                                <span className="text-orange" data-type="company">67</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                Candidate Bonus
                                                                <span className="text-orange" data-type="company">36</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                On Demand Buy
                                                                <span className="text-orange" data-type="company">34</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                Monthly Package
                                                                <span className="text-orange" data-type="company">14</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                Annual Package
                                                                <span className="text-orange" data-type="company">28</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                Add-on Service
                                                                <span className="text-orange" data-type="company">18</span>
                                                            </Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#" data-type="company">
                                                                Ad Campaign
                                                                <span className="text-orange" data-type="company">28</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-pay-paymentto">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-flagFilter">
                                                    <ul>
                                                        <li>
                                                            <Link data-type="year">
                                                                Ajay Chauhan
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    5
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                Deepak Kumar
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    8
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                Yuliia Bunina
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    3
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                Pooja Mangla
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    3
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                Amit Kumar
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    2
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                Dhirendra Raushan
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    4
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="pay-tabMonth">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-flagFilter">
                                                    <ul>
                                                        <li>
                                                            <Link data-type="year">
                                                                Jan
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    15
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                Feb
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    15
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                Mar
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    15
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                Apr
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    15
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                May
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    15
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                Jun
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    15
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                Jul
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    15
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                Aug
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    15
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                Sep
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    15
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                Oct
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    15
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                Nov
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    15
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                Dec
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    15
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="pay-tabYear">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-flagFilter">
                                                    <ul>
                                                        <li>
                                                            <Link data-type="year">
                                                                2023
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    25
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="year">
                                                                Feb
                                                                <span className="recuriterNo text-orange" data-type="year" >
                                                                    35
                                                                </span>
                                                            </Link>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    )
}
export default RightModalFilter;