import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import './addnewclientsinvoice.scss';
import DkStaffDatepicker from '../modal/DkStaffDatepicker';

const AddNewDkStaffInvoice = ({ hideAddNewInvoiceModal }) => {
    return (
        <>
            <Modal className="dk-addNewInvoiceModal-main right" show={true} onHide={hideAddNewInvoiceModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Invoice</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="dk-addNewInvoiceModal-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">INV Status</div>
                                    <div className="dk-addInvoice-label">
                                        <input type="text" name="" id="" vale="Paid" className="form-control" readonly />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">INV Type</div>
                                    <div className="dk-addInvoice-label">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                Select
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">Invoice</Dropdown.Item>
                                                <Dropdown.Item href="#">Credit</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">INV Month</div>
                                    <div className="dk-addInvoice-label">
                                        <input type="text" name="" id="" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">INV Due Date</div>
                                    <div className="dk-addInvoice-label dk-invoiceDatepicker">
                                        <DkStaffDatepicker />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Name of Business</div>
                                    <div className="dk-addInvoice-label">
                                        <input type="text" name="" id="" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Services Description</div>
                                    <div className="dk-addInvoice-label">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                Select
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">Recruitment Services 1</Dropdown.Item>
                                                <Dropdown.Item href="#">Recruitment Services 2</Dropdown.Item>
                                                <Dropdown.Item href="#">Recruitment Services 3</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Invoice No.</div>
                                    <div className="dk-addInvoice-label">
                                        <input type="number" name="" id="" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Basic Amount</div>
                                    <div className="dk-addInvoice-label">
                                        <input type="text" name="" id="" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">VAT Amount</div>
                                    <div className="dk-addInvoice-label">
                                        <input type="text" name="" id="" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Total Amount</div>
                                    <div className="dk-addInvoice-label">
                                        <input type="text" name="" id="" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Attach File</div>
                                    <div className="dk-addInvoice-label">
                                        <input type="file" name="" id="" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-invoiceAdd-btn">
                                        <button>Create</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddNewDkStaffInvoice;
