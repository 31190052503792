import React from 'react';
import { Link } from "react-router-dom";
import LuizaProjectLiveLeftPanel from "../../leftpanel/luiza/LuizaProjectLiveLeftPanel";
import PragmaticDetailLeftPanel from "../detailleftpanel/livedetailleftpanel/PragmaticDetailLeftPanel";
import { FaPlus } from 'react-icons/fa';
import '../../moduleprojects.scss';

const LuizaProjectTwo = () => {
    return (
        <>
            <div className="dk-moduleProjects-main">
                <div className="dk-moduleTraining-cols">
                    <LuizaProjectLiveLeftPanel />
                    <div className="dk-moduleTraining-rightPanel">
                        <div className="title">IT Roles</div>
                        <div className="dk-addNew-btn">
                            <Link to="#"><FaPlus /> Add New</Link>
                        </div>
                        <div className="dk-moduleTraining-rightCols">
                            <PragmaticDetailLeftPanel />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LuizaProjectTwo;
