import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Modal, Dropdown, Tab, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import {
//   commentList,
//   commentStage,
//   commentFunSubject,
//   addComment,
//   commentDelete,
//   commentDetails,
//   updateComments,
//   clearState,
// } from "../../../../slice/comments/commentSlice";
// import { updateCommentCount } from "../../../../slice/candidates/list/listSlice";
import CommentLoader from "../../../ui/commentLoader";
// import Delete from "../../../ui/delete/";
// import Submit from "../../../ui/submitButton";
import CaseOther from "../../../../components/pages/candidate/pipeline/dropdown/other/appliedStatus";

import { Markup } from "interweave";
import moment from "moment-timezone";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TimelineModal from "../../../pages/candidate/pipeline/modal/candidateprofile/workflow/TimelineModal";
import { getprogress } from "../../../../slice/candidates/list/listSlice";

export const useMountEffect = (fun) => useEffect(fun, []);

const CaseProgressModal = ({
  refTable,
  closedCommentPopup,
  title,
  itemdata,
}) => {
  const dispatch = useDispatch();
  const { isCommentLoading } = useSelector((state) => state.comments);

  const { progressList, isProgress, isProgressDelete, isProgressAdd } =
    useSelector((state) => state.candidates);
  //   const { userInfo } = useSelector((state) => state.auth);

  const [List, setList] = useState([]);

  useEffect(() => {
    dispatch(getprogress({ appid: itemdata.id }));
  }, []);

  useEffect(() => {
    if (isProgress) {
      setList(progressList);
    }
  }, [isProgress]);

  const [isTimelinePop, setIsTimelinePop] = useState(false);
  const hideTimelinePop = () => {
    setIsTimelinePop(false);
  };

  return (
    <>
      {isTimelinePop ? <TimelineModal onHide={hideTimelinePop} /> : null}
      <Modal
        className="dk-commentModalMain right dk-candidateComment-modalMain dkg-caseprogress-mainModal"
        show={true}
        onHide={closedCommentPopup}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="comments-container 22">
            <div className="scrollBox">
              <ul className="comments-list">
                {isCommentLoading ? (
                  <CommentLoader />
                ) : (
                  <React.Fragment>
                    {List.map((item, index) => (
                      <li>
                        <div className="comment-main-level">
                          <div className="comment-avatar">
                            <div className="tooltipMain">
                              <img
                                alt={
                                  item.recInfo.firstName +
                                  " " +
                                  item.recInfo.lastName
                                }
                                src={
                                  process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                  item.recInfo.profileImage
                                }
                                className="avatarImg"
                              />
                              <div className="tooltipText">
                                {item.recInfo.firstName +
                                  " " +
                                  item.recInfo.lastName}
                              </div>
                            </div>
                          </div>
                          <div className="comment-box">
                            <div className="comment-head dkg-comm-read-header d-flex align-items-center justify-content-between">
                              <div
                                className="dkg-comment-header-rightCon"
                                // style={{ height: "38px" }}
                              >
                                <span
                                  className="d-flex"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <div className="date">
                                    <i className="far fa-calendar-alt"></i>

                                    {moment(item.updatedAt)
                                      .tz("Europe/Dublin")
                                      .format("DD MMM YY")}
                                  </div>
                                  <div className="date">
                                    <i className="far fa-clock"></i>
                                    {moment(item.updatedAt)
                                      .tz("Europe/Dublin")
                                      .format("h:mm")}
                                  </div>
                                </span>
                              </div>
                            </div>
                            {refTable === "candidates" ? (
                              <>
                                {moment(item.createdAt)
                                  .tz("Europe/Dublin")
                                  .format(" YYYY") >= 2024 ? (
                                  <>
                                    <div className="dkg-comm-read-subheader">
                                      <div className="dkg-comm-read-subject">
                                        <div
                                          className="caseDiv"
                                          style={{
                                            backgroundColor: "#fff",
                                            color: "#333",
                                          }}
                                        >
                                          Status
                                        </div>
                                        <CaseOther
                                          caseStatus={item.status}
                                          value={item.status}
                                          statusBgColour={item.statusBgColour}
                                          statusTxColour={item.statusTxColour}
                                        />
                                      </div>
                                      <div className="dkg-comm-read-subject">
                                        <div
                                          className="caseDiv"
                                          style={{
                                            backgroundColor: "#fff",
                                            color: "#333",
                                          }}
                                        >
                                          Stage
                                        </div>
                                        <CaseOther
                                          caseStatus={item.stage}
                                          value={item.stage}
                                          statusBgColour={item.stageBgColour}
                                          statusTxColour={item.stageTxColour}
                                        />
                                      </div>
                                      <div className="dkg-comm-read-subject">
                                        <div
                                          className="caseDiv"
                                          style={{
                                            backgroundColor: "#fff",
                                            color: "#333",
                                          }}
                                        >
                                          Progress
                                        </div>
                                        <CaseOther
                                          caseStatus={item.progress}
                                          value={item.progress}
                                          statusBgColour={item.progressBgColour}
                                          statusTxColour={item.progressTxColour}
                                        />
                                      </div>
                                      <div className="dkg-comm-read-subject">
                                        <div
                                          className="caseDiv"
                                          style={{
                                            backgroundColor: "#fff",
                                            color: "#333",
                                          }}
                                        >
                                          Situation
                                        </div>
                                        <CaseOther
                                          caseStatus={item.situation}
                                          value={item.situation}
                                          statusBgColour={
                                            item.situationBgColour
                                          }
                                          statusTxColour={
                                            item.situationTxColour
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </>
                            ) : null}

                            <div className="comment-content 2">
                              <Markup content={item.comment} />
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </React.Fragment>
                )}
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CaseProgressModal;
