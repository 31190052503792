import React from "react";
import { Route, Link } from "react-router-dom";

const AccTrainingSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="#" className={`${(segment3 == 'job-board') ? 'active' : ''}`} style={{ background: '#fff' }}>Sample 1</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'dashboard') ? 'active' : ''}`}>Sample 2</Link></li>
                        <li><Link to="#" className={(segment3 == 'work-plan') ? 'active' : ''}>Sample 3</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default AccTrainingSubmenu;
