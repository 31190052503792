import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { useSelector } from "react-redux";
import './view.scss'
import Dropdown from 'react-bootstrap/Dropdown'

const View = ({ hidePopup }) => {

    const { details } = useSelector(state => state.mlResource)

    const [category, setCategory] = useState(details.category);
    const [coverage, setCoverage] = useState(details.coverage);
    const [paidUnpaid, setPaidUnpaid] = useState(details.isPaid);
    const [sourceType, setSourceType] = useState(details.source);
    let statusValDB;
    if (details.status === 1) {
        statusValDB = "Active"
    } else {
        statusValDB = "Inactive"
    }
    const [status, setStatus] = useState(statusValDB);
    const [sourceName, setSourceName] = useState(details.name);
    const [location, setLocation] = useState(details.location);

    return (
        <>
            <Modal
                show={true}
                onHide={hidePopup}
                aria-labelledby="contained-modal-title-vcenter"
                className="dk-viewResModal"
                centered
            >
                <Modal.Header closeButton className="dk-viewResModalHder">
                    <Modal.Title id="contained-modal-title-vcenter">
                        View
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-viewResModalBdy">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="form-group">
                                <label className="dk-mlmangerLabel">Manager</label>
                                <div className="useFilterCon">
                                    <div className="dk-resuserFilterCon">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" className="dk-resUsrfliterbtn">
                                                <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + details.userInfo.profileImage} className="img-fluid" alt={details.userInfo.profileImage} title={details.userInfo.firstName} data-id={details.userInfo.id} style={{ borderRadius: "50%" }} />
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Status</label>
                                <div className="dk-resDropDown">
                                    <input type="text" className="form-control" defaultValue={status} readOnly />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Name of Source</label>
                                <div className="dk-resDropDown">
                                    <input type="text" className="form-control" defaultValue={sourceName} readOnly />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Category</label>
                                <div className="dk-resDropDown">
                                    <input type="text" className="form-control" defaultValue={category} readOnly />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Source Type</label>
                                <div className="dk-resDropDown">
                                    <input type="text" className="form-control" defaultValue={sourceType} readOnly />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Paid/Unpaid</label>
                                <div className="dk-resDropDown">
                                    <input type="text" className="form-control" defaultValue={paidUnpaid} readOnly />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Coverage</label>
                                <div className="dk-resDropDown">
                                    <input type="text" className="form-control" defaultValue={coverage} readOnly />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Location</label>
                                <input type="text" className="form-control" defaultValue={location} readOnly />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default View
