import React,{useState} from 'react'
import Modal from 'react-bootstrap/Modal'
// import './emailcontent.scss'
import SendEmailUser from '../../../../../../../../assets/images/deepak.png'
import CustomDateFormat from '../../../../../../../ui/dateFormats/CustomDateFormat'
import Skeleton from "@material-ui/lab/Skeleton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  mailDetails,
  clearMailDetail
} from "../../../../../../../../slice/mailbox/mailboxSlice";
import { useDispatch, useSelector } from "react-redux";
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import AttachmentPreviewModal from "../../../../../../mainmenu/mailbox/modal/attachmentPreview"

const EmailContent = (props) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const { details, isDetailsLoading } = useSelector(
    (state) => state.mailbox
  );
  const handleClose = () => {
    dispatch(clearMailDetail())
    setShow(false);
  } 
  const handleShow = async () =>{
    await dispatch(mailDetails({ id: props.details.id, type: 'logs' }))
    setShow(true)
  }

  const [shoePreview, setPreview] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);

  const openPreview = (file) => {
    setPreview(true);
    setPreviewFile(file);
  }

  const closePreview = () => {
    setPreview(false)
    setPreviewFile(null);
  }

  return (
    <>
        <AttachmentPreviewModal show={shoePreview} file={previewFile} onClose={closePreview}/>
        <span className='dkg-jobtitle-cols' onClick={handleShow}>{ props.details.mailbox.subject }</span>
        <Modal show={show} onHide={handleClose} className='dkg-emailcontent-Modal'>
        <Modal.Header closeButton className='dkg-emailcontent-ModalHeader'>
          <Modal.Title>Email Content</Modal.Title>
        </Modal.Header>
        <Modal.Body className='dkg-emailcontent-ModalBody'>
          {
            (isDetailsLoading) ? null :
            <>
              <div className="dk-msgHeader">
                <div className="dk-userTitle">
                    <div className='dkg-sendemail-imgCon'>
                        <img src={SendEmailUser} alt='User Icon' className='dkg-sendemail-img' />
                    </div>
                    <div className="dk-detailText">
                        <div className="title">From :  {details?.mailbox?.from_name} &lt;{details?.mailbox?.from}&gt;</div>
                        <div className='dkg-bc-datepickerCon'>
                            <div className="">
                              <div className="sbtitle mr-2"><b>To : </b> { details?.mailbox?.to.map(email => email.email).join(',') } </div>
                              {
                                details?.mailbox?.cc.length > 0 &&
                                <div className="sbtitle"><b>Cc: { details?.mailbox?.cc.map(email => email.email).join(',') }</b> </div>
                              }
                            </div>
                            <div className='d-flex dkg-email-showdatepickerCon'>
                                <span className='dkg-email-showdatepicker'>{<CustomDateFormat date={details?.mailbox?.date} format='ddd DD[th] MMM YYYY [,] HH:mm A' />}</span>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className="dk-msgSender-detail">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="dk-detail-leftPanel">
                        <div className='dkg-email-subject-Con'>
                        <div className='dkg-email-subject'><span className='dkg-subtile'>Subject : </span>{ details?.mailbox?.subject }</div>
                        </div>
                        {/* <div className="dk-mailViewAttachfile-Con">
                          <div className="dk-mailViewAttach">{ attachment.file_name } <FontAwesomeIcon icon={faPaperclip} /></div>
                        </div> */}
                        {
                          details?.mailbox?.attachments.length > 0 &&
                          <div className="dk-mailViewAttachfile-Con">
                            {
                              details?.mailbox?.attachments.map(attachment =>
                                <div className="dk-mailViewAttach" onClick={() => openPreview(attachment.file_url)}>{ attachment.file_name } <FontAwesomeIcon icon={faPaperclip} /></div>
                              )
                            }
                          </div>
                        }
                    </div>
                </div>
              </div>
              <div className="dkg-readmsg-mainCon">
                <div className="dkg-readmsg-title">
                  {(isDetailsLoading) ? <Skeleton animation="wave" style={{ width: '50%' }} /> :
                    <div dangerouslySetInnerHTML={{ __html: details?.mailbox?.body }} /> 
                  }
                </div>
              </div>
            </>
          }
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EmailContent