import React from 'react';
import "./dailyapps.css"
import { Tab, Nav } from 'react-bootstrap';
import DailyappsTable from "./DailyTable";
import { Link } from 'react-router-dom';
import $ from "jquery";

const DailyTracker = () => {
    const searchFunction = (e) => {
        var value = (e.target.value).toLowerCase();
        $("#filterDailyTracker tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    }
    return (
        <>
            <div className="container-fluid dk-dailyappsPageCon">
                <Tab.Container className="dk-dailyappsTabCon" id="left-tabs-example" defaultActiveKey="rec1">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div className="dk-dailyTrackTopLeft">
                                <div className="dk-appstrackTableSearch">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search..." onChange={searchFunction} />
                                        <div className="input-group-append">
                                            <button className="btn btn-secondary" type="button">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* <Link to="#" className="dk-dailytopIcon mr-2">
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </Link> 
                                <Link to="#" className="dk-dailytopIcon">
                                    <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                </Link> */}
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="dk-dailyappsTitleCon">
                                <h2 className="dk-dailyappsTitle">
                                    DAILY JOBS APPLICATIONS
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="dk-appsrightbtnCon">
                                <Link to="/ads-tracker/daily-tracker" className="active">Daily</Link>
                                <Link to="/ads-tracker/weekly-tracker" className="" >Weekly</Link>
                                <Link to="/ads-tracker/monthly-tracker" className="">Monthly</Link>
                                <Link to="/ads-tracker/yearly-tracker" className="">Yearly</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row dk-appTrackerTableBg mt-2">
                        <div className="col-md-12">
                            <DailyappsTable />
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default DailyTracker;
