import React from 'react';
import { Dropdown } from 'react-bootstrap';

const DummyDropdown = (props) => {
    return (
        <React.Fragment>
            <Dropdown>
                <Dropdown.Toggle id={props.id} className="ui fluid selection dropdown otherdropdown" variant="">
                    <React.Fragment><i className="fas fa-plus pr-2"></i>  {props.defaultTxt}</React.Fragment>
                </Dropdown.Toggle>
                <Dropdown.Menu variant="" className='visible menu'>
                    <Dropdown.Item className='justify-content-start'>Option 1</Dropdown.Item>
                    <Dropdown.Item className='justify-content-start'>Option 2</Dropdown.Item>
                    <Dropdown.Item className='justify-content-start'>Option 3</Dropdown.Item>
                    <Dropdown.Item className='justify-content-start'>Option 4</Dropdown.Item>
                    <Dropdown.Item className='justify-content-start'>Option 5</Dropdown.Item>
                    <Dropdown.Item className='justify-content-start'>Option 6</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    )
}
export default DummyDropdown;