import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const SmsDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/business') ? 'active' : ''}`}>Situations</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/industry') ? 'active' : ''}`}>Shedule</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/services') ? 'active' : ''}`}>Send Tools</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/clients') ? 'active' : ''}`}>Logins</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/suppliers') ? 'active' : ''}`}>Pricing</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/completion') ? 'active' : ''}`}>Template</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Frequency</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>SMS Reply</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Send Status</Link></li>


                </Route>
            </ul>
        </React.Fragment>
    )
}

export default SmsDetailLeftPanel;

