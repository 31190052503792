import React from 'react';
import AccVatFileSubmenu from "../../../elements/header/navbar/subMenu/menu/accountingmodule/AccVatFileSubmenu";

const VatFile = () => {
    return (
        <>
            <AccVatFileSubmenu />
            <div className='container-fluid' style={{ marginTop: "125px" }}>

            </div>
        </>
    )
}

export default VatFile;
