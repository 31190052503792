import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../trainingdetail.scss';

const DetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkglobaladmin/admintraining/onboarding/welcome" className={`${pathname.match('/dkglobaladmin/admintraining/onboarding/welcome') ? 'active' : ''}`}>Welcome</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/onboarding/about-dkg" className={`${pathname.match('/dkglobaladmin/admintraining/onboarding/about-dkg') ? 'active' : ''}`}>About DKG</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/onboarding/dkg-team" className={`${pathname.match('/dkglobaladmin/admintraining/onboarding/dkg-team') ? 'active' : ''}`}>DKG Team</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/onboarding/timetable" className={`${pathname.match('/dkglobaladmin/admintraining/onboarding/timetable') ? 'active' : ''}`}>Timetable</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/onboarding/technical" className={`${pathname.match('/dkglobaladmin/admintraining/onboarding/technical') ? 'active' : ''}`}>Technical</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/onboarding/account" className={`${pathname.match('/dkglobaladmin/admintraining/onboarding/account') ? 'active' : ''}`}>Accounts</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/onboarding/conducts" className={`${pathname.match('/dkglobaladmin/admintraining/onboarding/conducts') ? 'active' : ''}`}>Conducts</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/onboarding/kpi-csf" className={`${pathname.match('/dkglobaladmin/admintraining/onboarding/kpi-csf') ? 'active' : ''}`}>KPI & CSF</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/onboarding/training" className={`${pathname.match('/dkglobaladmin/admintraining/onboarding/training') ? 'active' : ''}`}>Training</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/onboarding/contracts" className={`${pathname.match('/dkglobaladmin/admintraining/onboarding/contracts') ? 'active' : ''}`}>Contracts</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/onboarding/introduction" className={`${pathname.match('/dkglobaladmin/admintraining/onboarding/introduction') ? 'active' : ''}`}>Introduction</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/onboarding/onboarding-training-progress" className={`${pathname.match('/dkglobaladmin/admintraining/onboarding/onboarding-training-progress') ? 'active' : ''}`}>Training Progress</Link></li>
                </Route>
            </ul>
        </React.Fragment>
    )
}

export default DetailLeftPanel;

