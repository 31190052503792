import React from 'react';
import './profitlossdashboard.scss';

const IncomeDashboard = () => {
    return (
        <>
            <div className="dk-dkglobalFinance-profitLossMain">
                <h2 className="text-center">Profit & Loss Dashboard</h2>
            </div>
        </>
    )
}

export default IncomeDashboard;