import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import "../responsiveMenu.scss";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import './mainmenusubmenuc.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const TrackingSubMenu = (props) => {
    const classes = useStyles();
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];

    const [toggleMenu, setToggleMenu] = useState(false);
    useEffect(() => {
        setToggleMenu(false)
    }, [pathname])

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setToggleMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);

    return (
        <React.Fragment>
            <div className="dk-subMenuMain dkg-mainModuleSubmenu res-sub-menu" ref={ref}>
                <IconButton aria-label="delete" className="res-sub-btn" onClick={() => setToggleMenu(!toggleMenu)}>
                    <i className="fas fa-bars"></i>
                </IconButton>
                <Route>
                    <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
                        <li><Link to="/tracking/summary" className={`${(segment2 == 'summary') ? 'active' : ''}`}>Summary</Link></li>
                        <li><Link to="/tracking/all-trackings" className={`${(segment2 == 'all-trackings') ? 'active' : ''}`}><span className="dk-badgeTracking">0</span> All Recruiter</Link></li>
                        <li><Link to="/tracking/candidates" className={`${(segment2 == 'candidates') ? 'active' : ''}`}><span className="dk-badgeTracking">0</span> Deepak</Link></li>
                        <li><Link to="/tracking/accounting" className={`${(segment2 == 'accounting') ? 'active' : ''}`}><span className="dk-badgeTracking">0</span> Onur</Link></li>
                        <li><Link to="/tracking/it" className={`${(segment2 == 'it') ? 'active' : ''}`}><span className="dk-badgeTracking">0</span> Mariana</Link></li>
                        <li><Link to="/tracking/clients" className={`${(segment2 == 'clients') ? 'active' : ''}`}><span className="dk-badgeTracking">0</span> Elis</Link></li>
                        <li><Link to="/tracking/jobs" className={`${(segment2 == 'jobs') ? 'active' : ''}`}><span className="dk-badgeTracking">0</span> Tehreem</Link></li>
                        <li><Link to="/tracking/apps" className={`${(segment2 == 'apps') ? 'active' : ''}`}><span className="dk-badgeTracking">0</span> New Hire</Link></li>
                        <li><Link to="/tracking/ads" className={`${(segment2 == 'ads') ? 'active' : ''}`}><span className="dk-badgeTracking">0</span> New Hire</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default TrackingSubMenu;
