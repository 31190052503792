import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import '../../trainingdetail.scss';


const SixDayLeftTabs = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box>
                <TabContext value={value}>
                    <div className="d-flex">
                        <div className="dk-detailLefttabsMain">
                            <Box>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Overview" value="1" />
                                    <Tab label="Job Specific" value="2" />

                                </TabList>
                            </Box>
                        </div>
                        <div className="dk-tabDetail-flex">
                            <TabPanel value="1">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!
                            </TabPanel>
                            <TabPanel value="2">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut quam deserunt quidem recusandae similique ad corporis ducimus in temporibus labore doloribus excepturi aliquam odit, optio inventore exercitationem tempore voluptates culpa.
                            </TabPanel>

                        </div>
                    </div>
                </TabContext>
            </Box>


        </>
    )
}

export default SixDayLeftTabs;

