import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../trainingdetail.scss';

const DetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkglobaladmin/admintraining/assessments/overview" className={`${pathname.match('/dkglobaladmin/admintraining/assessments/overview') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/assessments/assessment-1" className={`${pathname.match('/dkglobaladmin/admintraining/assessments/assessment-1') ? 'active' : ''}`}>Assessment 1</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/assessments/assessment-2" className={`${pathname.match('/dkglobaladmin/admintraining/assessments/assessment-2') ? 'active' : ''}`}>Assessment 2</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/assessments/preparation" className={`${pathname.match('/dkglobaladmin/admintraining/assessments/preparation') ? 'active' : ''}`}>Preparation</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/assessments/decision" className={`${pathname.match('/dkglobaladmin/admintraining/assessments/decision') ? 'active' : ''}`}>Decision</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/assessments/faq" className={`${pathname.match('/dkglobaladmin/admintraining/assessments/faq') ? 'active' : ''}`}>FAQ</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default DetailLeftPanel;

