import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaEye, FaDownload } from 'react-icons/fa';
import "./myacc-invoices.scss";
import AddNewClientsInvoice from "./modal/AddNewClientsInvoice";
const Invoice = () => {
    const [isAddNewInvoiceModal, setIsAddNewInvoiceModal] = useState(false)

    const openAddNewInvoiceModal = () => {
        setIsAddNewInvoiceModal(true)
    }
    const hideAddNewInvoiceModal = () => {
        setIsAddNewInvoiceModal(false)
    }
    return (
        <>
            {
                (isAddNewInvoiceModal) ? <AddNewClientsInvoice hideAddNewInvoiceModal={hideAddNewInvoiceModal} /> : null
            }
            <div className='dkg-myacc-invoices-main-Con container-fluid'>
                <div className='row dkg-invoices-mainCon'>
                    <div className='col-md-12 dkg-invoices-boxCon'>
                        <div className="dk-moduledkFinanceProcess-rightPanel">
                            <div className="dk-financeYears-main">
                                <div className="dk-financeYears-cols"><Link to="#" className="active">2022</Link></div>
                                <div className="dk-financeYears-cols"><Link to="#">2021</Link></div>
                                <div className="dk-financeYears-cols"><Link to="#">2020</Link></div>
                            </div>
                            <div className="title">MY INVOICES</div>
                            <div className="dk-financeYears-addNew">
                                <Link to="#" onClick={openAddNewInvoiceModal}>+ Add New</Link>
                            </div>
                            <div className="dk-moduledkFinanceProcess-rightCols dk-financeClientsBgTransparent">
                                <div className="dk-financeClients-tableMain">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>SNo.</th>
                                                    <th>Status</th>
                                                    <th>INV Month</th>
                                                    <th>INV ISSUED</th>
                                                    <th>INV Due On</th>
                                                    <th>Name of Business</th>
                                                    <th>Services Description</th>
                                                    <th>Invoice No</th>
                                                    <th>Amount</th>
                                                    <th>VAT</th>
                                                    <th>Total</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            1
                                                            <div className="dk-checkinputIcon">
                                                                <input type="checkbox" className="chkCandidate" value="" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dk-invoiceStatus">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                    <div style={{ background: '#3a9469' }}>Paid</div>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#" style={{ background: '#d58b5d' }}>Unpaid</Dropdown.Item>
                                                                    <Dropdown.Item href="#" style={{ background: '#3a9469' }}>Paid</Dropdown.Item>
                                                                    <Dropdown.Item href="#" style={{ background: '#d91010' }}>Overdue</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                    <td>Aug 2022</td>
                                                    <td>2 Sep 22</td>
                                                    <td>12 July 22</td>
                                                    <td>Rossella Casciello</td>
                                                    <td>IT</td>
                                                    <td>1500</td>
                                                    <td>0 <span>€</span></td>
                                                    <td>0 <span>€</span></td>
                                                    <td>1500 <span>€</span></td>
                                                    <td>
                                                        <div className="dk-financeAction">
                                                            <Link to="#"><FaEye /></Link>
                                                            <Link to="#"><FaDownload /></Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            2
                                                            <div className="dk-checkinputIcon">
                                                                <input type="checkbox" className="chkCandidate" value="" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dk-invoiceStatus">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                    <div style={{ background: '#d58b5d' }}>Unpaid</div>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#" style={{ background: '#d58b5d' }}>Unpaid</Dropdown.Item>
                                                                    <Dropdown.Item href="#" style={{ background: '#3a9469' }}>Paid</Dropdown.Item>
                                                                    <Dropdown.Item href="#" style={{ background: '#d91010' }}>Overdue</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                    <td>Aug 2022</td>
                                                    <td>2 Sep 22</td>
                                                    <td>12 July 22</td>
                                                    <td>Rossella Casciello</td>
                                                    <td>IT</td>
                                                    <td>1500</td>
                                                    <td>0 <span>€</span></td>
                                                    <td>0 <span>€</span></td>
                                                    <td>1500 <span>€</span></td>
                                                    <td>
                                                        <div className="dk-financeAction">
                                                            <Link to="#"><FaEye /></Link>
                                                            <Link to="#"><FaDownload /></Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            3
                                                            <div className="dk-checkinputIcon">
                                                                <input type="checkbox" className="chkCandidate" value="" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dk-invoiceStatus">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                    <div style={{ background: '#d91010' }}>Overdue</div>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#" style={{ background: '#d58b5d' }}>Unpaid</Dropdown.Item>
                                                                    <Dropdown.Item href="#" style={{ background: '#3a9469' }}>Paid</Dropdown.Item>
                                                                    <Dropdown.Item href="#" style={{ background: '#d91010' }}>Overdue</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                    <td>Aug 2022</td>
                                                    <td>2 Sep 22</td>
                                                    <td>12 July 22</td>
                                                    <td>Rossella Casciello</td>
                                                    <td>IT</td>
                                                    <td>1500</td>
                                                    <td>0 <span>€</span></td>
                                                    <td>0 <span>€</span></td>
                                                    <td>1500 <span>€</span></td>
                                                    <td>
                                                        <div className="dk-financeAction">
                                                            <Link to="#"><FaEye /></Link>
                                                            <Link to="#"><FaDownload /></Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan="8" style={{ border: 'none', background: 'transparent' }}></td>
                                                    <td>0 <span>€</span></td>
                                                    <td className="text-center">0 <span>€</span></td>
                                                    <td>4500 <span>€</span></td>
                                                    <td style={{ border: 'none', background: 'transparent' }}></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Invoice;