import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/trainingmodule/CommonTrainingNav';

const TrainingCommonTabsLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="Training" moduleLink="/dashboard/training" linkcolor="#3c4c62" mainheaderTitle=" - Common" textcolor="#396a95" />
            {/* <Navbar /> */}
            {children}
        </React.Fragment>
    )
}
export default TrainingCommonTabsLayout;
