import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

const LevelModal = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <React.Fragment>
            <Link to="#" onClick={handleShow}><i className="far fa-star"></i></Link>
            <Modal className="dk-levelModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Level Explanations</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-reorderModal">
                    <div className="table-responisve">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td>Level 1</td>
                                    <td>Director/CEO</td>
                                </tr>
                                <tr>
                                    <td>Level 2</td>
                                    <td>Ops Manager/Dept Head</td>
                                </tr>
                                <tr>
                                    <td>Level 3</td>
                                    <td>Team/Branch Manager</td>
                                </tr>
                                <tr>
                                    <td>Level 4</td>
                                    <td>Team Leader/QA /Trainer</td>
                                </tr>
                                <tr>
                                    <td>Level 5</td>
                                    <td>Recruiters/Developer</td>
                                </tr>
                                <tr>
                                    <td>Level 6</td>
                                    <td>Admins/Designer</td>
                                </tr>
                                <tr>
                                    <td>Level 7</td>
                                    <td>Trainee/Interns</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default LevelModal;