import React from 'react';
import { Link } from "react-router-dom";
import './salessendouts.scss';
import TasksLeftPanel from './leftpanel/TasksLeftPanel';
import { FaSearch } from 'react-icons/fa';

const TasksCaseDetails = () => {
    return (
        <>
            <div className="dk-tasks-containerMain dk-tasksModuleMain-container">
                <TasksLeftPanel />

                <div className="dk-sendouts-rightPanel">
                    <div className="dk-sendoutsHead">
                        <div className="dk-sendouts-yearsDropdown">
                            <div className="dk-searchBox">
                                <input type="text" placeholder="Search..." className="form-control" name="" id="" />
                                <button><FaSearch /></button>
                            </div>
                        </div>
                        <div className="dk-sendouts-months">
                            <Link className="addMonthBtn" to="#" style={{ marginLeft: '0' }}><i className="fas fa-plus"></i></Link>
                            <ul>
                                <li><Link to="#">Jan</Link></li>
                                <li><Link to="#">Feb</Link></li>
                                <li><Link to="#">Mar</Link></li>
                                <li><Link to="#">Apr</Link></li>
                                <li><Link to="#">May</Link></li>
                                <li><Link to="#">Jun</Link></li>
                                <li><Link to="#" className="active">Jul</Link></li>
                                <li><Link to="#">Aug</Link></li>
                                <li><Link to="#">Sep</Link></li>
                                <li><Link to="#">Oct</Link></li>
                                <li><Link to="#">Nov</Link></li>
                                <li><Link to="#">Dec</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="dk-salesSendouts-table">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Case ID</th>
                                    <th>Date & Time</th>
                                    <th>AM</th>
                                    <th>RC</th>
                                    <th>Status</th>
                                    <th>Candidate Name</th>
                                    <th>Company</th>
                                    <th>Project</th>
                                    <th>Role Type</th>
                                    <th>Language</th>
                                    <th>CV Source</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>1</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>2</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>3</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>4</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>5</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>6</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>7</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>8</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>9</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>10</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TasksCaseDetails;
