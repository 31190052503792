import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './salesstatscustom.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaSearch, FaFilter } from 'react-icons/fa';
import SalesKpiNav from "./SalesKpiNav";

const SalesCaseDetails = () => {
    const [isAddNewRow, setIsAddNewRow] = useState(false)
    const openAddnewRow = () => {
        setIsAddNewRow(true)
    }
    return (
        <>
            <div className="dk-sendouts-containerMain">
                <div className="dk-sendoutsLeftpanel">
                    <SalesKpiNav />
                </div>
                <div className="dk-sendouts-rightPanel">
                    <div className="dk-sendoutsHead" style={{ marginTop: '0', marginBottom: '12px' }}>
                        <div className="dk-sendouts-yearsDropdown">
                            <div className="dk-searchBox">
                                <input type="text" placeholder="Search..." className="form-control" name="" id="" />
                                <button><FaSearch /></button>
                            </div>
                            <Link className="addMonthBtn" to="#" onClick={openAddnewRow}><i className="fas fa-plus"></i></Link>
                        </div>

                        <div className="dk-sendoutCaseSt-rightPanel">
                            <button className="dk-filterBtn"><FaFilter /></button>
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    2022
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#">2021</Dropdown.Item>
                                    <Dropdown.Item href="#">2020</Dropdown.Item>
                                    <Dropdown.Item href="#">2019</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                    </div>
                    <div className="dk-salesSendouts-table">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Case ID</th>
                                    <th>Status</th>
                                    <th>Date & Time</th>
                                    <th>AM</th>
                                    <th>RC</th>
                                    <th>Candidate Name</th>
                                    <th>Company</th>
                                    <th>Project</th>
                                    <th>Role Type</th>
                                    <th>Language</th>
                                    <th>CV Source</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>1</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>2</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>3</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>4</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>5</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>6</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>7</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>8</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>9</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <small>10</small>
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            <span>51503</span>
                                        </div>
                                    </td>
                                    <td><div className="dk-status">Job Spec</div></td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-tdImg">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" alt="" />
                                        </div>
                                    </td>
                                    <td>Eleonora Gioia</td>
                                    <td>Teleperformance Greece</td>
                                    <td>Volkswagen</td>
                                    <td>Technical Analyst</td>
                                    <td>Polish</td>
                                    <td>ELJ</td>
                                </tr>
                                {
                                    isAddNewRow ?
                                        <tr>
                                            <td>
                                                <div className="dk-smsTableCheckbox">
                                                    <small>11</small>
                                                    <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                                    <span>51503</span>
                                                </div>
                                            </td>
                                            <td><div className="dk-status">Job Spec</div></td>
                                            <td>06 Jul, 07:18</td>
                                            <td>
                                                <div className="dk-tdImg">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/user.png" alt="" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dk-tdImg">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/user.png" alt="" />
                                                </div>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr> : null
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SalesCaseDetails
