import React from 'react';
import { Modal } from 'react-bootstrap';
import './addnew.scss';


const View = ({ onHide }) => {
    return (
        <>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-qualifyPopup"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >   <Modal.Header closeButton className="dk-qualifyViewheader">
                    <Modal.Title className="dkqualifyviewtitle">View</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-qualifyAddNewBody">
                    <form>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Category</label>
                                    <input type="text" id="userId" className="form-control" placeholder="Job Board" readOnly />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Frequency</label>
                                    <input type="text" id="userId" className="form-control" placeholder="One Time Info" readOnly />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="">Questions</label>
                                    <input type="text" id="userId" className="form-control" placeholder="" readOnly />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="">Answer</label>
                                    <input type="text" id="userId" className="form-control" placeholder="" readOnly />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default View;
