import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from "react-redux";

const CaseStatusDropdown = (props) => {
    const { caseStatusList } = useSelector(state => state.common);
    return (
        <React.Fragment>
            <Dropdown onSelect={function (evt, value) {
                props.updateFilter(evt, 'caseStatus');
            }}>
                <Dropdown.Toggle id={props.id} className={props.className} variant="">
                    {(!props.isFilter) ?
                        <React.Fragment><i className="fas fa-plus pr-2"></i>  {props.defaultTxt}</React.Fragment>
                        :
                        <React.Fragment>
                            <div className='dropdown-selection d-flex align-items-center w-100'>
                                <i className="fas fa-plus pr-2"></i> {props.defaultTxt}
                            </div>
                            <i className="fas fa-times-circle crossBtn" data-key="caseStatus" onClick={props.removeFilter}></i>
                        </React.Fragment>
                    }
                </Dropdown.Toggle>
                <Dropdown.Menu variant="" className='visible menu'>
                    {
                        caseStatusList.map((statusItem, index) =>
                            <Dropdown.Item key={`case-status-id${index}`} eventKey={statusItem.value} style={{ backgroundColor: statusItem.bgColor, color: statusItem.textColor }}>{statusItem.value}
                                {
                                    props.list.filter(items => items.caseStatus == statusItem.value).map((item, index) => {
                                        return <span className='count'>{item.count}</span>
                                    })
                                }
                            </Dropdown.Item>
                        )
                    }
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    )
}
export default CaseStatusDropdown;