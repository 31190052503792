import React from 'react'
// import PayTasksSummary from './components/pages/paytasks/PayTasksSummary';
import PayTasksSummary from '../../paytasks/PayTasksSummary'
import './paymentssumm.scss'

const PaymentTasksSummary = () => {
  return (
    <>
      <div className="dkg-payment-suMM-mainCon" style={{ marginTop: "160px" }}>
        <PayTasksSummary />
      </div>
    </>
  )
}

export default PaymentTasksSummary