import React from 'react';
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import './jobspec.scss';
import SalesKpiNav from '../SalesKpiNav';
import DatePicker from '../calendar/DatePicker';
import Recruiter from '../../../../../assets/images/deepak.png'


const JobSpec = () => {
    return (
        <>
            <div className="dk-sales-jobspecMain container-fluid">
                <div className="nav-tabs-navigation">
                    <SalesKpiNav />
                </div>
                <div className="row dk-jobspecContainer">
                    <div className="col-md-12 col-sm-12">
                        <div className='dk-jobspec-table-Header'>
                            <div className='dk-jobspec-LeftCon'>
                                <div className="dk-searchBoxGroup">
                                    <div className="input-group">
                                        <input id="srchterm" type="text" className="form-control searchTxtBox" name="search" placeholder="Search by Text" />
                                    </div>
                                </div>
                                <Link to="#" className='dk-document-iconCon'><i className="fas fa-file-alt"></i></Link>
                                <div className='dk-user-dropdownCon'>
                                    <Dropdown className='dk-user-dropdown'>
                                        <Dropdown.Toggle variant="" className='dk-user-dropdown-toggle'>
                                            <i className="fas fa-user"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='dk-user-dropdownMenu'>
                                            <div className="d-flex">
                                                <Link to="#">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" alt="" />
                                                </Link>
                                                <Link to="#">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/1580424792.jpeg" alt="" />
                                                </Link>
                                                <Link to="#">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/Alia.png" alt="" />
                                                </Link>
                                                <Link to="#">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/1590537975.jpeg" alt="" />
                                                </Link>
                                                <Link to="#">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cc993057d6b0.png" alt="" />
                                                </Link>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <Link className='dk-pluseIconCon'>
                                    <i className="fas fa-plus"></i>
                                </Link>
                            </div>
                            <div className='dk-caseopend-RightCon'>
                                <div className='dk-month-dropdownCon'>
                                    <Dropdown className="dk-monthBox">
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                            Dec
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#">Jan</Dropdown.Item>
                                            <Dropdown.Item href="#">Feb</Dropdown.Item>
                                            <Dropdown.Item href="#">Mar</Dropdown.Item>
                                            <Dropdown.Item href="#">Apr</Dropdown.Item>
                                            <Dropdown.Item href="#">May</Dropdown.Item>
                                            <Dropdown.Item href="#">Jun</Dropdown.Item>
                                            <Dropdown.Item href="#">Jul</Dropdown.Item>
                                            <Dropdown.Item href="#">Aug</Dropdown.Item>
                                            <Dropdown.Item href="#">Sep</Dropdown.Item>
                                            <Dropdown.Item href="#">Oct</Dropdown.Item>
                                            <Dropdown.Item href="#">Nov</Dropdown.Item>
                                            <Dropdown.Item href="#" className="active">Dec</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className='dk-date-formCon mr-2'>
                                    <DatePicker />
                                </div>
                                <div className='dk-date-toCon mr-2'>
                                    <DatePicker />
                                </div>
                                <div className='dk-calendCon'>
                                    <Link to="#">
                                        Go
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div className="dk-jobspec-tableCon">
                            <table className="dk-jobspec-table table table-bordered">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>ID</th>
                                        <th>Date & Time</th>
                                        <th>Status</th>
                                        <th>Rec</th>
                                        <th>Candidate Name</th>
                                        <th>Full Fee</th>
                                        <th>Company</th>
                                        <th>Language</th>
                                        <th>Project</th>
                                        <th>Role Type</th>
                                        <th>CV Source</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-jobspec-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-jobspec-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-jobspec-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-jobspec-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-jobspec-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-jobspec-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-jobspec-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-jobspec-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-jobspec-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-jobspec-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-jobspec-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-jobspec-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-jobspec-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-jobspec-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-jobspec-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-jobspec-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-jobspec-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-jobspec-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobSpec;
