import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import JoditEditor from "../../../../../ui/JoditEditor";
import {
  clearState,
  // updateJob,
  // updateJobWithTextBox,
} from "../../../../../../slice/jobs/jobsSlice";

const Salary = (jobId) => {
  const dispatch = useDispatch();

  const { isDetailsLoading, jobDetails, isUpdate } = useSelector(
    (state) => state.jobs
  );
  //const [status, setStatus] = useState("");
  const [taxCalculator, setTaxCalculator] = useState("");
  // const [costOfLiving, setCostOfLiving] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setData(jobDetails);
      setTaxCalculator(jobDetails.taxCalculator);
      //setCostOfLiving(jobDetails.costOfLiving);
      //setStatus(jobDetails.status);
    }
  }, [isDetailsLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobDetails);
    }
  }, [isUpdate]);
  let ids = data.id;

  let gross_value = "";
  if (data.gross_title === null) {
    gross_value = "Monthly Gross Salary";
  } else {
    if (data.gross_title === "Monthly Gross Salary") {
      gross_value = "Annual Gross";
    } else {
      gross_value = "Monthly Gross Salary";
    }
  }

  const openUrl = (url) => {
    window.open(url, "_blank");
  };
  return (
    <>
      <div className="row">
        <div className="col-md-5 pr-2">
          <div className="dk-suMMRightCon dk-jobsuMMBottomSec w-100">
            <div className="dk-suMMRightConBoxBg">
              <div className="dk-suMMRightBoxHeader">
                <h3>Salary Package</h3>
              </div>
              <div className="dk-suMMRightboxBody">
                <div className="dk-suMMformGroupCon">
                  <label style={{ minWidth: "175px" }}>
                    {data.gross_title !== null
                      ? data.gross_title
                      : "Monthly Gross Salary"}
                  </label>
                  <span>{data.annual_gross}</span>
                  {/* <span style={{ width: "120px", textAlign: "center" }}>{data.gross_currency}</span> */}
                </div>
                <div className="dk-suMMformGroupCon">
                  <label style={{ minWidth: "175px" }}>Performance Bonus</label>
                  <span>{data.performance}</span>
                  {/* <span style={{ width: "120px", textAlign: "center" }}>{data.other_currency}</span> */}
                </div>
                <div className="dk-suMMformGroupCon">
                  <label style={{ minWidth: "175px" }}>Overtime Bonus</label>
                  <span>{data.overtime_bonus}</span>
                  {/* <span style={{ width: "120px", textAlign: "center" }}>{data.overtime_currency}</span> */}
                </div>
                <div className="dk-suMMformGroupCon">
                  <label style={{ minWidth: "175px" }}>Meal Vouchers</label>
                  <span>{data.meal_ticket}</span>
                  {/* <span style={{ width: "120px", textAlign: "center" }}>{data.meal_currency}</span> */}
                </div>
                {/* <div className="dk-suMMformGroupCon">
                  <label style={{ minWidth: "175px" }}>
                    Performance Bonus
                  </label>
                  <span>{data.performance}</span>
                  <span style={{ width: "120px", textAlign: "center" }}>{data.performance_currency}</span>
                </div> */}
                <div className="dk-suMMformGroupCon">
                  <label style={{ minWidth: "175px" }}>Joining Bonus</label>
                  <span>{data.joining_bonus}</span>
                  {/* <span style={{ width: "120px", textAlign: "center" }}>{data.joining_currency}</span> */}
                </div>
                <div className="dk-suMMformGroupCon">
                  <label style={{ minWidth: "175px" }}>Retained Bonus</label>
                  <span>{data.average_net_pm}</span>
                  {/* <span style={{ width: "120px", textAlign: "center" }}>{data.avgNetSal_currency}</span> */}
                </div>
                <div className="dk-suMMformGroupCon">
                  <label style={{ minWidth: "175px" }}>Rent Allowance</label>
                  <span>{data.accomodation_bonus}</span>
                  {/* <span style={{ width: "120px", textAlign: "center" }}>{data.accomodation_currency}</span> */}
                </div>
                <div className="dk-suMMformGroupCon">
                  <label style={{ minWidth: "175px" }}>2 Extra Salaries</label>
                  <span>{data.extra_salary_pm}</span>
                  {/* <span style={{ width: "120px", textAlign: "center" }}>{data.extra_currency}</span> */}
                </div>
                <div className="dk-suMMformGroupCon">
                  <label style={{ minWidth: "175px" }}>Income Tax %</label>
                  <span>{data.tax}</span>
                  {/* <span style={{ width: "120px", textAlign: "center" }}>{data.incomeTax_currency}</span> */}
                </div>
              </div>
            </div>
          </div>
          <div className="dk-suMMRightCon dk-jobsuMMBottomSec mt-3 w-100">
            <div className="col-md-12 col-sm-12 dk-jobsuMMBottomBoxBg p-0">
              <div className="dkg-salary-tab-box">
                <div className="dkg-salary-tab-header">
                  <div className="dkg-salary-header-title">
                    Cost of Living - Explained
                  </div>
                </div>
                <div className="dkg-salary-tabbody">
                  {data.costOfLiving != "" && data.costOfLiving != null ? (
                    <>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.costOfLiving,
                        }}
                      ></div>
                      {/* <JoditEditor
                        datavalue={data.costOfLiving}
                        toolbaarControl={false}
                        readonlyControl={true}
                        spellcheckControll={false}
                        showCharsCounterControll={false}
                        showWordsCounterControll={false}
                        widthControll={800}
                        heightControll={250}
                      /> */}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 ">
          <div className="dk-suMMRightCon dk-jobsuMMBottomSec w-100">
            <div className="col-md-12 col-sm-12 dk-jobsuMMBottomBoxBg p-0">
              <div className="dk-BoxHeader">
                <h3>Job Benefits</h3>
              </div>
              <div className="dk-boxKpi">
                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    1
                  </label>
                  <span>{data.benefits_1}</span>
                </div>

                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    2
                  </label>
                  <span>{data.benefits_2}</span>
                </div>

                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    3
                  </label>
                  <span>{data.benefits_3}</span>
                </div>

                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    4
                  </label>
                  <span>{data.benefits_4}</span>
                </div>

                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    5
                  </label>
                  <span>{data.benefits_5}</span>
                </div>

                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    6
                  </label>
                  <span>{data.benefits_6}</span>
                </div>

                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    7
                  </label>
                  <span>{data.benefits_7}</span>
                </div>

                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    8
                  </label>
                  <span>{data.benefits_8}</span>
                </div>
                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    9
                  </label>
                  <span>{data.benefits_9}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="dkg-salary-tab-rightCon">
            <div className="dkg-salary-tab-box">
              <div className="dkg-salary-tab-header">
                <div className="dkg-salary-header-title">
                  Salary Negotiation
                </div>
              </div>
              <div className="dkg-salary-tabbody">
                {data.negotiation != "" && data.negotiation != null ? (
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.negotiation,
                      }}
                    ></div>
                    {/* <JoditEditor
                      datavalue={data.negotiation}
                      toolbaarControl={false}
                      readonlyControl={true}
                      spellcheckControll={false}
                      showCharsCounterControll={false}
                      showWordsCounterControll={false}
                      widthControll={800}
                      heightControll={250}
                    /> */}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="dkg-salary-tab-box">
              <div className="dkg-salary-tab-header">
                <div className="dkg-salary-header-title">
                  Income Tax calculator
                </div>
              </div>
              <div className="dkg-salary-tabbody">
                <div className="dkg-income-inputgrp">
                  <input
                    type="text"
                    name="url"
                    id="incometax"
                    className="dkg-input-url form-control"
                    defaultValue={taxCalculator}
                    readOnly
                  />
                  <button
                    className="btn dkg-open-url-btn"
                    onClick={() => openUrl(taxCalculator)}
                  >
                    Open Url
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Salary;
