import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";

const FaqTechnicalBugsLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/knowleadge/tech-bugs/overview" className={`${pathname.match('/knowleadge/tech-bugs/overview') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/knowleadge/tech-bugs/sample-1" className={`${pathname.match('/knowleadge/tech-bugs/sample-1') ? 'active' : ''}`}>Sample 1</Link></li>

                    <li><Link to="/knowleadge/tech-bugs/sample-2" className={`${pathname.match('/knowleadge/tech-bugs/sample-2') ? 'active' : ''}`}>Sample 2</Link></li>

                    <li><Link to="/knowleadge/tech-bugs/sample-3" className={`${pathname.match('/knowleadge/tech-bugs/sample-3') ? 'active' : ''}`}>Sample 3</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default FaqTechnicalBugsLeftPanel;

