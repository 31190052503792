import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import './addskils.scss';

const AddIndustry = ({ hidePopup }) => {
    return (
        <>
            <Modal
                show={true}
                onHide={hidePopup}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="addskillsModalDialog"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add New Industry
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <div className="input-group">
                            <input type="text" placeholder="Type here..." name="" id="" className="form-control" />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="dk-addSkilsFooter">
                    <Button variant="" className="dk-cancel-btn">
                        Cancel
                    </Button>
                    <Button variant="" className="dk-save-btn">Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddIndustry;
