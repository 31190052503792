import React from 'react';
import moment from "moment";
const DateCalculation = ({ date }) => {
    let date1 = moment(); //current date & time
    const date2 = date;
    let a = moment(date1);
    let b = moment(date2);
    let years = a.diff(b, 'year');
    b.add(years, 'years');
    let months = a.diff(b, 'months');
    b.add(months, 'months');
    let days = a.diff(b, 'days');
    return (
        <React.Fragment>
            {years} years, {months} months, {days} days
        </React.Fragment>
    );
}
export default DateCalculation;