import React from 'react'
import { Link } from 'react-router-dom'
import './workglow.scss'
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { Dropdown, Nav, Tab } from 'react-bootstrap';
import DatePicker from './datepicker/DatePicker';

const myworkload = () => {
    return (
        <>
            <div className='dkg-myworkload-mainCon'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="recuiter">
                    <div className="row dkg-myworkload-Row">
                        <div className="col-12 dkg-myworkload-Col">
                            <Nav variant="pills" className="flex-row dkg-myworkload-navpills">
                                <Nav.Item className="dkg-myworkload-navitem">
                                    <Nav.Link eventKey="recuiter" className="dkg-myworkload-navlink">Recruiter</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="dkg-myworkload-navitem">
                                    <Nav.Link eventKey="admins" className="dkg-myworkload-navlink">Admins</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content className="dkg-myworkload-Tabcontent">
                                <Tab.Pane eventKey="admins" className="dkg-myworkload-tabpane">
                                    <div className='dkg-myworkload-pageMain dkg-myworkload-adminTabCon'>
                                        <div className='dkg-myworkload-topHeader'>
                                            <Tab.Container id="left-tabs-example" defaultActiveKey="deepak">
                                                <div className="row dkg-myoverloaded-mainRow">
                                                    <div className="col-12 dkg-myoverloaded-mainCol">
                                                        <Nav variant="pills" className="flex-row dkg-myoverloaded-navpills">
                                                            <Nav.Item className="dkg-myoverloaded-navitem">
                                                                <Nav.Link eventKey="deepak" className="dkg-myoverloaded-navlink">
                                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="dkg-myworkload-user-img" />
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item className="dkg-myoverloaded-navitem">
                                                                <Nav.Link eventKey="julia" className="dkg-myoverloaded-navlink">
                                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/1668693896533.png" className="dkg-myworkload-user-img" title="Yuliia Bunina" />

                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item className="dkg-myoverloaded-navitem">
                                                                <Nav.Link eventKey="pooja" className="dkg-myoverloaded-navlink">
                                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="dkg-myworkload-user-img" title="Pooja Mangla" />

                                                                </Nav.Link>
                                                            </Nav.Item> 
                                                            <Nav.Item className="dkg-myoverloaded-navitem">
                                                                <Nav.Link eventKey="amit" className="dkg-myoverloaded-navlink">
                                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/1670076250420.png" className="dkg-myworkload-user-img" title="Amit Kumar" />
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                        <div className='dk-jobProfile-FiltersCon'>
                                                            <div className='dk-jobProfile-Filter mr-2'>
                                                                <Dropdown className='dk-jobProfile-drop'>
                                                                    <Dropdown.Toggle variant="" className='dk-jobProfile-day-toggle'>
                                                                        Monday
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className='dk-jobProfile-dropMenu'>
                                                                        <Dropdown.Item href="#">Monday</Dropdown.Item>
                                                                        <Dropdown.Item href="#">Tuesday</Dropdown.Item>
                                                                        <Dropdown.Item href="#">Wednesday</Dropdown.Item>
                                                                        <Dropdown.Item href="#">Thursday</Dropdown.Item>
                                                                        <Dropdown.Item href="#">Friday</Dropdown.Item>
                                                                        <Dropdown.Item href="#">Saturday</Dropdown.Item>
                                                                        <Dropdown.Item href="#">Sunday</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                            <div className='dk-jobProfile-Filter mr-2'>
                                                                <DatePicker />
                                                            </div>
                                                            <div className='dk-jobProfile-Filter '>
                                                                <DatePicker />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 dkg-myoverloaded-mainCol-Content">
                                                        <Tab.Content className="dkg-myoverloaded-mainContent">
                                                            <Tab.Pane eventKey="deepak" className="dkg-myoverloaded-tabpane">
                                                                <div className='dkg-myoverloaded-morn-afterLabelCon'>
                                                                    <div className='dkg-morning-labelCon'>
                                                                        <div className='dkg-myoverloaded-labelName'>MORNING TASKS</div>
                                                                    </div>
                                                                    <div className='dkg-afternoon-labelCon'>
                                                                        <div className='dkg-myoverloaded-labelName'>AFTERNOON TASKS</div>
                                                                    </div>
                                                                </div>
                                                                <ul className='dkg-prescreen-listCon'>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Daily KPI's</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon dkg-myoverloaded-firstCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon dkg-myoverloaded-firstCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Job Offers</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Job Joiners</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Accounting</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Payments</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Resource Plan</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Ads Post Plan</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Apps Tracking</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">All Contracts</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Clients Projections</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 1</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 2</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 3</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 4</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 5</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="julia" className="dkg-myoverloaded-tabpane">
                                                                <ul className='dkg-prescreen-listCon'>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Group B Clients</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-myoverloaded-morn-afterLabelCon'>
                                                                                <div className='dkg-morning-labelCon'>
                                                                                    <div className='dkg-myoverloaded-labelName'>MORNING TASKS</div>
                                                                                </div>
                                                                                <div className='dkg-afternoon-labelCon'>
                                                                                    <div className='dkg-myoverloaded-labelName'>AFTERNOON TASKS</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Morning Workplan</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Review Sales Target</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Learn Jobs Information</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">How to Process a Case</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Candidate Management</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Update Daily Recruiter KPI's</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Update & Review Case Pipeline</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Collect & Update - Client Job Spec</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Collect & Update - Email Template</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="Write Notes" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="Write Notes" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 1</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 2</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 3</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 4</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 5</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="pooja" className="dkg-myoverloaded-tabpane">
                                                                <ul className='dkg-prescreen-listCon'>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Daily KPI's</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-myoverloaded-morn-afterLabelCon'>
                                                                                <div className='dkg-morning-labelCon'>
                                                                                    <div className='dkg-myoverloaded-labelName'>MORNING TASKS</div>
                                                                                </div>
                                                                                <div className='dkg-afternoon-labelCon'>
                                                                                    <div className='dkg-myoverloaded-labelName'>AFTERNOON TASKS</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon dkg-myoverloaded-firstCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon dkg-myoverloaded-firstCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Job Offers</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Job Joiners</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Accounting</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Payments</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Resource Plan</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Ads Post Plan</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Apps Tracking</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">All Contracts</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Clients Projections</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 1</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 2</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 3</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 4</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 5</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="amit" className="dkg-myoverloaded-tabpane">
                                                                <ul className='dkg-prescreen-listCon'>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Live Jobs</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-myoverloaded-morn-afterLabelCon'>
                                                                                <div className='dkg-morning-labelCon'>
                                                                                    <div className='dkg-myoverloaded-labelName'>MORNING TASKS</div>
                                                                                </div>
                                                                                <div className='dkg-afternoon-labelCon'>
                                                                                    <div className='dkg-myoverloaded-labelName'>AFTERNOON TASKS</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Jobs KPI's</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Job Specs</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">CV Parsing</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Ad Posting</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Ads Review</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Ad Campain</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Social Media</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">DKG Database</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Suppliers A/C</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 1</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 2</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 3</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 4</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 5</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </Tab.Pane>

                                                        </Tab.Content>
                                                    </div>
                                                </div>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="recuiter" className="dkg-myworkload-tabpane">
                                    <div className='dkg-myworkload-pageMain dkg-myworkload-adminTabCon'>
                                        <div className='dkg-myworkload-topHeader'>
                                            <Tab.Container id="left-tabs-example" defaultActiveKey="deepak">
                                                <div className="row dkg-myoverloaded-mainRow">
                                                    <div className="col-12 dkg-myoverloaded-mainCol">
                                                        <Nav variant="pills" className="flex-row dkg-myoverloaded-navpills">
                                                            <Nav.Item className="dkg-myoverloaded-navitem">
                                                                <Nav.Link eventKey="deepak" className="dkg-myoverloaded-navlink">
                                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="dkg-myworkload-user-img" />
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item className="dkg-myoverloaded-navitem">
                                                                <Nav.Link eventKey="julia" className="dkg-myoverloaded-navlink">
                                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/1668693896533.png" className="dkg-myworkload-user-img" title="Yuliia Bunina" />

                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item className="dkg-myoverloaded-navitem">
                                                                <Nav.Link eventKey="pooja" className="dkg-myoverloaded-navlink">
                                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/1692254146553.png" className="dkg-myworkload-user-img" title="Tasa Palchyk" />
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item className="dkg-myoverloaded-navitem">
                                                                <Nav.Link eventKey="amit" className="dkg-myoverloaded-navlink">
                                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/1670076250420.png" className="dkg-myworkload-user-img" title="Amit Kumar" />
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item className="dkg-myoverloaded-navitem">
                                                                <Nav.Link eventKey="amit" className="dkg-myoverloaded-navlink">
                                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/1678982002678.png" className="dkg-myworkload-user-img" title="Prakash Shaw" />
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                        <div className='dk-jobProfile-FiltersCon'>
                                                            <div className='dk-jobProfile-Filter mr-2'>
                                                                <Dropdown className='dk-jobProfile-drop'>
                                                                    <Dropdown.Toggle variant="" className='dk-jobProfile-day-toggle'>
                                                                        Monday
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className='dk-jobProfile-dropMenu'>
                                                                        <Dropdown.Item href="#">Monday</Dropdown.Item>
                                                                        <Dropdown.Item href="#">Tuesday</Dropdown.Item>
                                                                        <Dropdown.Item href="#">Wednesday</Dropdown.Item>
                                                                        <Dropdown.Item href="#">Thursday</Dropdown.Item>
                                                                        <Dropdown.Item href="#">Friday</Dropdown.Item>
                                                                        <Dropdown.Item href="#">Saturday</Dropdown.Item>
                                                                        <Dropdown.Item href="#">Sunday</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                            <div className='dk-jobProfile-Filter mr-2'>
                                                                <DatePicker />
                                                            </div>
                                                            <div className='dk-jobProfile-Filter '>
                                                                <DatePicker />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 dkg-myoverloaded-mainCol-Content">
                                                        <Tab.Content className="dkg-myoverloaded-mainContent">
                                                            <Tab.Pane eventKey="deepak" className="dkg-myoverloaded-tabpane">
                                                                {/* <div className='dkg-myoverloaded-morn-afterLabelCon'>
                                                                    <div className='dkg-morning-labelCon'>
                                                                        <div className='dkg-myoverloaded-labelName'>MORNING TASKS</div>
                                                                    </div>
                                                                    <div className='dkg-afternoon-labelCon'>
                                                                        <div className='dkg-myoverloaded-labelName'>AFTERNOON TASKS</div>
                                                                    </div>
                                                                </div>
                                                                <ul className='dkg-prescreen-listCon'>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Daily KPI's</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon dkg-myoverloaded-firstCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon dkg-myoverloaded-firstCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Job Offers</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Job Joiners</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Accounting</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Payments</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Resource Plan</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Ads Post Plan</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Apps Tracking</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">All Contracts</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Clients Projections</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 1</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 2</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 3</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 4</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 5</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul> */}
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="julia" className="dkg-myoverloaded-tabpane">
                                                                {/* <ul className='dkg-prescreen-listCon'>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Group B Clients</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-myoverloaded-morn-afterLabelCon'>
                                                                                <div className='dkg-morning-labelCon'>
                                                                                    <div className='dkg-myoverloaded-labelName'>MORNING TASKS</div>
                                                                                </div>
                                                                                <div className='dkg-afternoon-labelCon'>
                                                                                    <div className='dkg-myoverloaded-labelName'>AFTERNOON TASKS</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Morning Workplan</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Review Sales Target</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Learn Jobs Information</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">How to Process a Case</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Candidate Management</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Update Daily Recruiter KPI's</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Update & Review Case Pipeline</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Collect & Update - Client Job Spec</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Collect & Update - Email Template</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="Write Notes" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="Write Notes" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 1</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 2</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 3</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 4</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 5</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul> */}
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="pooja" className="dkg-myoverloaded-tabpane">
                                                                {/* <ul className='dkg-prescreen-listCon'>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Daily KPI's</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-myoverloaded-morn-afterLabelCon'>
                                                                                <div className='dkg-morning-labelCon'>
                                                                                    <div className='dkg-myoverloaded-labelName'>MORNING TASKS</div>
                                                                                </div>
                                                                                <div className='dkg-afternoon-labelCon'>
                                                                                    <div className='dkg-myoverloaded-labelName'>AFTERNOON TASKS</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon dkg-myoverloaded-firstCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon dkg-myoverloaded-firstCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Job Offers</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Job Joiners</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Accounting</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Payments</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Resource Plan</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Ads Post Plan</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Apps Tracking</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">All Contracts</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Clients Projections</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 1</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 2</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 3</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 4</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 5</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul> */}
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="amit" className="dkg-myoverloaded-tabpane">
                                                                {/* <ul className='dkg-prescreen-listCon'>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Live Jobs</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-myoverloaded-morn-afterLabelCon'>
                                                                                <div className='dkg-morning-labelCon'>
                                                                                    <div className='dkg-myoverloaded-labelName'>MORNING TASKS</div>
                                                                                </div>
                                                                                <div className='dkg-afternoon-labelCon'>
                                                                                    <div className='dkg-myoverloaded-labelName'>AFTERNOON TASKS</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Jobs KPI's</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Job Specs</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">CV Parsing</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Ad Posting</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Ads Review</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Ad Campain</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Social Media</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">DKG Database</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Suppliers A/C</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 1</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 2</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 3</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 4</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className='dkg-prescreen-list current'>
                                                                        <div className='dkg-prescreen-form-leftCon'>
                                                                            <Link to="#">Additional Task 5</Link>
                                                                        </div>
                                                                        <div className='dkg-prescreen-form-rightCon'>
                                                                            <div className='dkg-candidate-formCon-24'>
                                                                                <div className='row'>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-morningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-6'>
                                                                                        <div className='dkg-myoverloaded-eveningCon'>
                                                                                            <textarea placeholder="" className='form-control dkg-myoverloaded-textarea' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul> */}
                                                            </Tab.Pane>

                                                        </Tab.Content>
                                                    </div>
                                                </div>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default myworkload