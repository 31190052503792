import React from 'react';
import './casestatus.scss';

const CaseStatus = ({ value }) => {
    let bgColor = "";
    switch (value) {
        
        case 'Job Offer':
            bgColor = '#629bd3'
            break;
       
        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor }}>{value}</div>
        </>
    )
}

export default CaseStatus;