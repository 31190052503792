import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import { FaUserAlt, FaSpinner, FaBuilding, FaBook, FaLanguage, FaUserCheck, FaRegCalendarAlt, FaRegFileAlt, FaEuroSign } from 'react-icons/fa';

const SearchSendout = ({ onHide, headerTitle, list, selectUserFilter, selectOtherFilter, pageType }) => {

    const [cvSourceList, setcvSourceList] = useState([]);
    const [roleTypeList, setRoleTypeList] = useState([]);
    const [languageList, setLanguageList] = useState([]);
    const [projectLists, setProjectLists] = useState([]);
    const [monthsLists, setMonthsList] = useState([]);
    const [companyLists, setCompanyLists] = useState([]);
    const [rcUserList, setRcUserList] = useState([]);
    const [amUserList, setAMUserList] = useState([]);
    const [progressLists, setProgressLists] = useState([]);
    const [stageLists, setStageLists] = useState([]);
    const [reasonLists, setReasonLists] = useState([]);
    const [totalFees, setTotalFee] = useState([]);

    useEffect(() => {

        var totalFee = 0;
        for (const item of list) {
            if (item.original.fullFee != null) {
                totalFee += parseInt(item.original.fullFee)
            }
        }
        setTotalFee(totalFee)

        const ReasonResult = [];
        const reasonmap = new Map();
        for (const item of list) {
            if (!reasonmap.has(item.original.reason)) {
                reasonmap.set(item.original.reason, true);
                ReasonResult.push({
                    name: (item.original.reason !== '') ? item.original.reason : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.reason == item.original.reason;
                    }).length
                });
            }
        }
        ReasonResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setReasonLists(ReasonResult)

        const StageResult = [];
        const stagemap = new Map();
        for (const item of list) {
            if (!stagemap.has(item.original.caseStage)) {
                stagemap.set(item.original.caseStage, true);
                StageResult.push({
                    name: (item.original.caseStage !== '') ? item.original.caseStage : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.caseStage == item.original.caseStage;
                    }).length
                });
            }
        }
        StageResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setStageLists(StageResult)

        const cvSourceResult = [];
        const cvSourcemap = new Map();
        for (const item of list) {
            if (!cvSourcemap.has(item.original.cvSource)) {
                cvSourcemap.set(item.original.cvSource, true);
                cvSourceResult.push({
                    name: (item.original.cvSource !== '') ? item.original.cvSource : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.cvSource === item.original.cvSource;
                    }).length
                });
            }
        }
        cvSourceResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setcvSourceList(cvSourceResult)

        const roleTypeResult = [];
        const roletypemap = new Map();
        for (const item of list) {
            if (!roletypemap.has(item.original.roleType)) {
                roletypemap.set(item.original.roleType, true);
                roleTypeResult.push({
                    name: (item.original.roleType !== '') ? item.original.roleType : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.roleType === item.original.roleType;
                    }).length
                });
            }
        }
        roleTypeResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setRoleTypeList(roleTypeResult)

        const LangResult = [];
        const langsmap = new Map();
        for (const item of list) {
            if (!langsmap.has(item.original.language)) {
                langsmap.set(item.original.language, true);
                LangResult.push({
                    name: (item.original.language !== '') ? item.original.language : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.language === item.original.language;
                    }).length
                });
            }
        }
        LangResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setLanguageList(LangResult)

        const ProjectResult = [];
        const projectsmap = new Map();
        for (const item of list) {
            if (!projectsmap.has(item.original.project)) {
                projectsmap.set(item.original.project, true);
                ProjectResult.push({
                    name: (item.original.project !== '') ? item.original.project : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.project === item.original.project;
                    }).length
                });
            }
        }
        ProjectResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setProjectLists(ProjectResult)

        const CompanyResult = [];
        const companymap = new Map();
        for (const item of list) {
            if (!companymap.has(item.original.company)) {
                companymap.set(item.original.company, true);
                CompanyResult.push({
                    name: (item.original.company !== '') ? item.original.company : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.company === item.original.company;
                    }).length
                });
            }
        }
        CompanyResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setCompanyLists(CompanyResult)

        const ProgressResult = [];
        const progressmap = new Map();
        for (const item of list) {
            if (!progressmap.has(item.original.progress)) {
                progressmap.set(item.original.progress, true);
                ProgressResult.push({
                    name: (item.original.progress !== '') ? item.original.progress : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.progress === item.original.progress;
                    }).length
                });
            }
        }
        ProgressResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setProgressLists(ProgressResult)

        const MonthsResult = [];
        const Monthssmap = new Map();
        for (const item of list) {
            if (!Monthssmap.has(item.original.month)) {
                Monthssmap.set(item.original.month, true);
                MonthsResult.push({
                    name: (item.original.month !== '') ? item.original.month : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.month === item.original.month;
                    }).length
                });
            }
        }
        MonthsResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setMonthsList(MonthsResult)


        const amResult = [];
        const ammap = new Map();
        for (const item of list) {
            if (!ammap.has(item.original.amIinfo.id)) {
                ammap.set(item.original.amIinfo.id, true);
                amResult.push({
                    id: item.original.amIinfo.uid,
                    name: item.original.amIinfo.firstName,
                    userImg: item.original.amIinfo.profileImage,
                    count: list.filter(function (val) {
                        return val.original.amIinfo.uid === item.original.amIinfo.uid;
                    }).length
                });
            }
        }
        amResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setAMUserList(amResult)

        const rcResult = [];
        const rcmap = new Map();
        for (const item of list) {
            if (!rcmap.has(item.original.reCcInfo.id)) {
                rcmap.set(item.original.reCcInfo.id, true);
                rcResult.push({
                    id: item.original.reCcInfo.uid,
                    name: item.original.reCcInfo.firstName,
                    userImg: item.original.reCcInfo.profileImage,
                    count: list.filter(function (val) {
                        return val.original.reCcInfo.uid === item.original.reCcInfo.uid;
                    }).length
                });
            }
        }
        rcResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setRcUserList(rcResult)


    }, [list])

    return (
        <>
            <Modal className="dk-sendoutsSearch-modal right" show={true} onHide={onHide}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>{headerTitle}</Modal.Title> */}
                    <Modal.Title>SEARCH BUSINESS OVERVIEW</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div className="dk-sendoutFilterTabs-main">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                            <div className="dk-sendoutTabs-nav">
                                <Nav variant="">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_1"><FaRegCalendarAlt /> Month</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_3"><FaUserAlt /> RC</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_2"><FaUserAlt /> AM</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_4"><FaSpinner /> Status</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_5"><FaSpinner /> Progress</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_6"><FaSpinner /> Stage</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_12"><FaSpinner /> Reason</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_7"><FaBuilding /> Company</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_9"><FaLanguage /> Language</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_8"><FaBook /> Project</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_10"><FaUserCheck /> Role Type</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_11"><FaRegFileAlt /> CV Source</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_fee"><FaEuroSign /> CASE FEE </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="dk-sendoutTabs-filterPanel">
                                <Tab.Content>
                                    <Tab.Pane eventKey="tabs_1">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'January' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'February' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'March' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'April' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'May' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'June' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'July' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'August' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'September' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'October' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'November' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>
                                                    <li>
                                                        {monthsLists.map((item, index) => (
                                                            item.name == 'December' ?
                                                                <li key={`month${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                                :
                                                                null
                                                        ))}
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_2">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-Recuriter">
                                                <ul>
                                                    {amUserList.map((item, index) => (
                                                        <li key={`rcFilter${index}`}>
                                                            <Link to="#" data-type="AManger" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} alt={item.name} className="recImg" title={item.name} data-type="AManger" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                                                <span className="recuriterNo" data-type="AManger" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                    {item.count}
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_3">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-Recuriter">
                                                <ul>
                                                    {rcUserList.map((item, index) => (
                                                        <li key={`rcFilter${index}`}>
                                                            <Link to="#" data-type="SRecruters" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} alt={item.name} className="recImg" title={item.name} data-type="SRecruters" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                                                <span className="recuriterNo" data-type="SRecruters" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                    {item.count}
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_4">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-satusListFilter">
                                                <ul>
                                                    {
                                                        (pageType == "qualified") ?
                                                            <li>
                                                                <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#5c737d", color: "#fff" }} data-value="qualified" onClick={selectOtherFilter}>Qualified <span className="text-orange" >
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.caseStatus == 'Qualified';
                                                                        }).length
                                                                    }
                                                                </span></Link> 
                                                            </li>
                                                            : null
                                                    }
                                                    {
                                                        (pageType == "joined") ?
                                                            <li>
                                                                <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#4768b0", color: "#fff" }} data-value="joined" onClick={selectOtherFilter}>Joined <span className="text-orange" >
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.caseStatus == 'Joined';
                                                                        }).length
                                                                    }
                                                                </span></Link>
                                                            </li>
                                                            : null
                                                    }
                                                    {
                                                        (pageType == "job-offers") ? <li>
                                                            <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#629bd3", color: "#fff" }} data-value="Job Offer" onClick={selectOtherFilter}>Job Offers <span className="text-orange" >
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.original.caseStatus == 'Job Offer';
                                                                    }).length
                                                                }
                                                            </span></Link>
                                                        </li>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        (pageType == "interviews") ? <li>
                                                            <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#316a67", color: "#fff" }} data-value="Interviews" onClick={selectOtherFilter}>Interviews <span className="text-orange" >
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.original.caseStatus == 'Interviews';
                                                                    }).length
                                                                }
                                                            </span></Link>
                                                        </li>
                                                            : null
                                                    }
                                                    {
                                                        (pageType == "final-fee") ? <li>
                                                            <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#4C5F59", color: "#fff" }} data-value="Final Fee" onClick={selectOtherFilter}>Final Fee <span className="text-orange" >
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.original.caseStatus == 'Final Fee';
                                                                    }).length
                                                                }
                                                            </span></Link>
                                                        </li>
                                                            : null
                                                    }


                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_5">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>

                                                    {progressLists.map((item, index) => (
                                                        <li key={`progress${index}`} data-type="progress" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="progress" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="progress" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>

                                                    ))}
                                                </ul>

                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_6">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>

                                                    {stageLists.map((item, index) => (
                                                        <li key={`stage${index}`} data-type="stage" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="stage" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="stage" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>

                                                    ))}
                                                </ul>

                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_7">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>

                                                    {companyLists.map((item, index) => (
                                                        <li key={`company${index}`} data-type="company" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="company" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="company" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>

                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_8">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>

                                                    {projectLists.map((item, index) => (
                                                        <li key={`project${index}`} data-type="project" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="project" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="project" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>

                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_9">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    {languageList.map((item, index) => (
                                                        <li key={`language${index}`} data-type="language" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="language" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="language" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>

                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_10">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>

                                                    {roleTypeList.map((item, index) => (
                                                        <li key={`roleType${index}`} data-type="roleType" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="roleType" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="roleType" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>

                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_11">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    {cvSourceList.map((item, index) => (
                                                        <li key={`cvSource${index}`} data-type="cvSource" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="cvSource" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="cvSource" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>

                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_12">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>

                                                    {reasonLists.map((item, index) => (
                                                        <li key={`reason${index}`} data-type="reason" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="reason" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="reason" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>

                                                    ))}
                                                </ul>

                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_fee">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList" >
                                                <div className='dkg-total-fee-title'>
                                                    Total Fee
                                                    <span className="text-orange">{totalFees}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default SearchSendout;
