import React from "react";

const ProjectTab = ({ companyAboutus }) => {
  return (
    <>
      <div className="dkg-jopspec-pageheader">
        <div className="dkg-jopspec-page-title">About US</div>
      </div>
      <div className="dkg-jobspec-read-editor-Con">
        <div className="dkg-jobspec-read-editor">
          <div
            className="dkg-jobspec-htmleditor"
            dangerouslySetInnerHTML={{
              __html:
                companyAboutus.length > 0 && companyAboutus[0].aboutUs != null
                  ? companyAboutus[0].aboutUs
                  : "",
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default ProjectTab;
