import React from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from "react-redux";
const CaseStageInvoiceBook = ({ id, columnId, onItemClick }) => {
    const { caseStageList } = useSelector(state => state.invoiceBooks);  

    const selectValue = (value, color) => {
        onItemClick(id, columnId, value, color);
    }
    return (
        <React.Fragment>
            {
                caseStageList.map((statusItem, index) =>
                    <Dropdown.Item key={`case-status-id${index}`} href="#" style={{ backgroundColor: statusItem.bgColor, color: statusItem.textColor }} onClick={() => selectValue(statusItem.value, statusItem.bgColor)}>
                        {statusItem.value}
                    </Dropdown.Item>
                )
            }
        </React.Fragment>
    )
}
export default CaseStageInvoiceBook;