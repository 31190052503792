import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../trainingdetail.scss';

const DetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/training/commontraining/overview" className={`${pathname.match('/training/commontraining/overview') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/training/commontraining/established" className={`${pathname.match('/training/commontraining/established') ? 'active' : ''}`}>Established</Link></li>

                    <li><Link to="/training/commontraining/business" className={`${pathname.match('/training/commontraining/business') ? 'active' : ''}`}>Business</Link></li>

                    <li><Link to="/training/commontraining/industry" className={`${pathname.match('/training/commontraining/industry') ? 'active' : ''}`}>Industry</Link></li>

                    <li><Link to="/training/commontraining/services" className={`${pathname.match('/training/commontraining/services') ? 'active' : ''}`}>Services</Link></li>

                    <li><Link to="/training/commontraining/clients" className={`${pathname.match('/training/commontraining/clients') ? 'active' : ''}`}>Clients</Link></li>

                    <li><Link to="/training/commontraining/suppliers" className={`${pathname.match('/training/commontraining/suppliers') ? 'active' : ''}`}>Suppliers</Link></li>

                    <li><Link to="/training/commontraining/completion" className={`${pathname.match('/training/commontraining/completion') ? 'active' : ''}`}>Completion</Link></li>

                    <li><Link to="/training/commontraining/employees" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Employees</Link></li>

                    <li><Link to="/training/commontraining/future-plans" className={`${pathname.match('/training/commontraining/future-plans') ? 'active' : ''}`}>Future Plans</Link></li>

                    <li><Link to="/training/commontraining/social-media" className={`${pathname.match('/training/commontraining/social-media') ? 'active' : ''}`}>Social Media</Link></li>

                    <li><Link to="/training/commontraining/contact-details" className={`${pathname.match('/training/commontraining/contact-details') ? 'active' : ''}`}>Contact Details</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default DetailLeftPanel;

