import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';
import './document.scss';
import { candidateDoc } from '../../../../../../slice/candidates/profile/profileSlice';

const CandidateDocumentModal = ({ name, canId, file }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = async () => {
        setShow(true);
    }

    const [frameType, setFrameType] = useState('pdf')
    const [fileUrl, setFileUrl] = useState('')

    useEffect(() => {
        if (show) {
            if (file !== "" || '') {
                setFrameType(file.split('.').pop());
                setFileUrl(file);
            }
            else {
                setFileUrl('0')
            }
        }
    }, [show])

    return (
        <>
            <Link to="#" className="mr-2" onClick={handleShow}><i className="fa fa-eye"></i></Link>
            <Modal show={show} onHide={handleClose} dialogClassName="dk-pipedocs-previewModal"
                aria-labelledby="example-custom-modal-styling-title"
                centered animation={false}>
                {
                    (frameType != '0') ?
                        (fileUrl != '0') ?
                            <Modal.Body className='dk-pipedocs-preview-modalbody'>
                                {
                                    (frameType === 'pdf' || frameType === 'PDF') ?
                                        <iframe src={fileUrl} frameBorder={0}></iframe>
                                        :
                                        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`} frameBorder={0}></iframe>
                                }
                            </Modal.Body>
                            : <Modal.Body className='dk-pipedocs-preview-modalbody'>
                                <h1>Document Not Found</h1>
                            </Modal.Body>
                        : null
                }
            </Modal>
        </>
    )
}
export default CandidateDocumentModal;