import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Modal, Dropdown } from 'react-bootstrap';
import "./edit.scss"

const EditModal = ({ onHide }) => {
    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-editResModalDialog modal-40w dkg-clientinfo-RightModal"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton className="dk-resourcePopupHedaer">
                    <Modal.Title id="example-custom-modal-styling-title">
                        Edit Company
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Status</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" style={{ backgroundColor: "#31a886", color: "#fff" }}>
                                                Active
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#" style={{ backgroundColor: "#31a886", color: "#fff" }}>Active</Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ backgroundColor: "#d8345f", color: "#fff" }}>Inactive</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Name of Client</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="">
                                                Client 1
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">Active</Dropdown.Item>
                                                <Dropdown.Item href="#">Inactive</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Country</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="">
                                                India
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">Country 1</Dropdown.Item>
                                                <Dropdown.Item href="#">Country 2</Dropdown.Item>
                                                <Dropdown.Item href="#">Country 3</Dropdown.Item>
                                                <Dropdown.Item href="#">Country 4</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">City</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="">
                                                Noida
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">City 1</Dropdown.Item>
                                                <Dropdown.Item href="#">City 2</Dropdown.Item>
                                                <Dropdown.Item href="#">City 3</Dropdown.Item>
                                                <Dropdown.Item href="#">City 4</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Category</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="">
                                                Category 1
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">Category 1</Dropdown.Item>
                                                <Dropdown.Item href="#">Category 2</Dropdown.Item>
                                                <Dropdown.Item href="#">Category 3</Dropdown.Item>
                                                <Dropdown.Item href="#">Category 4</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Contract</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="">
                                                Contract 1
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">Active</Dropdown.Item>
                                                <Dropdown.Item href="#">Inactive</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Key Points</label>
                                    <textarea name="keytemrs" id="keyterms" rows="6" className="form-control dkg-clientinfo-textArea" value='Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate maxime.' />
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Address</label>
                                    <textarea name="address" id="address" rows="6" className="form-control dkg-clientinfo-textArea" value='Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate maxime.' />
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <div className="dk-addresbtnCont">
                                    <button className="dk-addres-editbtn">UPDATE</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default EditModal;
