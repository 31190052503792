import React from 'react';
import Recuiter from '../../../../../assets/images/deepak.png';
import Dropdown from 'react-bootstrap/Dropdown'
import "./userfilter.scss";


const UserFilter = () => {
    return (
        <>
            <div className="dk-useFilterCon">
                <Dropdown>
                    <Dropdown.Toggle variant="" className="dk-fliterbtn">
                        <img src={Recuiter} className="filterImg" alt="RecuiterImg" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dk-pipleuserFilterMenu">
                        <div className="d-flex">
                            <Dropdown.Item href="#"><img src={Recuiter} className="filterImg" alt="RecuiterImg" /></Dropdown.Item>
                            <Dropdown.Item href="#"><img src={Recuiter} className="filterImg" alt="RecuiterImg" /></Dropdown.Item>
                            <Dropdown.Item href="#"><img src={Recuiter} className="filterImg" alt="RecuiterImg" /></Dropdown.Item>
                            <Dropdown.Item href="#"><img src={Recuiter} className="filterImg" alt="RecuiterImg" /></Dropdown.Item>
                            <Dropdown.Item href="#"><img src={Recuiter} className="filterImg" alt="RecuiterImg" /></Dropdown.Item>
                            <Dropdown.Item href="#"><img src={Recuiter} className="filterImg" alt="RecuiterImg" /></Dropdown.Item>
                            <Dropdown.Item href="#"><img src={Recuiter} className="filterImg" alt="RecuiterImg" /></Dropdown.Item>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default UserFilter;
