import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const businessSavingList = createAsyncThunk(
    'businessSaving/business/listing',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/business/',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateValue = createAsyncThunk(
    'businessSaving/business/update',
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/business/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    },
                    body: JSON.stringify({
                        key, value
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);


const businessSlice = createSlice({
    name: 'business',
    initialState: {
        businessList: [],
        isLoading: false,
        isInsert: false,
        isError: false,
        isUpdate: false,
        errorMessage: '',
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.errorMessage = '';
            return state;
        },

    },
    extraReducers: {
        [businessSavingList.pending]: (state) => {
            state.isLoading = true;
        },
        [businessSavingList.fulfilled]: (state, { payload }) => {
            state.businessList = payload.list;
            state.isLoading = false;
        },
        [businessSavingList.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [updateValue.pending]: (state) => {
        },
        [updateValue.fulfilled]: (state, { payload }) => {
            let mainArray = state.businessList;
            let finalResult = mainArray.map((r) => {
                if (r._id === payload.data._id) {
                    return (payload.data)
                } else {
                    return r
                }
            })
            state.businessList = finalResult;
            state.isUpdate = true;
        },
        [updateValue.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        }

    }
});

export const { clearState, sortData } = businessSlice.actions;
export default businessSlice.reducer;