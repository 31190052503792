import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const projectlist = createAsyncThunk(
    'project/projectlist',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/projects',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const exportdata = createAsyncThunk(
    'project/export',
    async ({ projectId, status }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/projects/candidate/export/' + projectId + '/' + status,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const projectDetails = createAsyncThunk(
    'project/singledata',
    async ({ projectId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/projects/' + projectId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const fetchById = createAsyncThunk(
    'project/fetchbyid',
    async ({ projectId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/projects/fetchById/' + projectId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);


export const moveProjectRecords = createAsyncThunk(
    'project/list/update/projectupdateDetails',
    async ({ ids, status, projectId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/projects/candidate/moveProjectData/',
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        ids, status, projectId
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const moveSingleData = createAsyncThunk(
    'project/list/update/moveSingleData',
    async ({ ids, status, projectId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/projects/candidate/moveSingleData/',
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        ids, status, projectId
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const moveCandidateRecords = createAsyncThunk(
    'project/list/update/profileupdateDetails',
    async ({ ids, status }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/projects/candidate/movedata/',
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        ids, status
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateTable = createAsyncThunk(
    'project/list/update/profileupdateDetails',
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/projects/candidate/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        id, key, value
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);


export const updateProjects = createAsyncThunk(
    'project/updateDetails',
    async ({ uid, title, category, companies, project, language, roleType, rowId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/projects/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        uid, title, category, companies, project, language, roleType
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteProject = createAsyncThunk(
    'project/delete',
    async ({ rowId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/projects/' + rowId,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const deleteSingleData = createAsyncThunk(
    'project/singledelete',
    async ({ ids }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/projects/prodelete/' + ids,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const addProjects = createAsyncThunk(
    'project/addProject',
    async ({ uid, title, category, companies, project, language, roleType, canId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/projects',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        uid, title, category, companies, project, language, roleType, canId
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const candidateProjectListFun = createAsyncThunk(
    'project/candidate_project_List',
    async ({ projectId, type }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/projects/candidate/' + projectId + '/' + type,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const deleteCandidate = createAsyncThunk(
    'project/candidate_delete',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/projects/candidate/' + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const projectCandidateDataMove = createAsyncThunk(
    'project/datamove',
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/projects/candidate/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({ key, value })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const addNewCandidate = createAsyncThunk(
    'project/addCandidate',
    async ({ canId, projectId, status, uid }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/projects/candidate',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        canId, projectId, status, uid
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);


const projectsSlice = createSlice({
    name: 'project',
    initialState: {
        exportcsv: {},
        projectList: [],
        candidateProjecttList: [],
        projectDetails: {},
        projectRecDetails: {},
        isLoading: false,
        isInsert: false,
        isError: false,
        isUpdate: false,
        isDelete: false,
        errorMessage: '',
        isDataMove: false,
        isMoved: false,
        isProjectMoved: false,
        isProjectDeleted: false,
        isExport: false,
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isMoved = false;
            state.isProjectMoved = false;
            state.isProjectDeleted = false;
            state.isDelete = false;
            state.isProjectDeleted = false;
            state.errorMessage = '';
            state.isDataMove = false;
            state.isExport = false;
            return state;
        }
    },
    extraReducers: {
        [exportdata.pending]: (state) => {
            // state.isLoading = true;
        },
        [exportdata.fulfilled]: (state, { payload }) => {
            state.isExport = true;
            state.exportcsv = payload.result;
        },
        [exportdata.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
        },
        [projectlist.pending]: (state) => {
            state.isLoading = true;
        },
        [projectlist.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.projectList = payload.result;
        },
        [projectlist.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
        },

        [projectDetails.pending]: (state) => {
            //  state.isLoading = true;
        },
        [projectDetails.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.projectDetails = payload.result;
        },
        [projectDetails.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
        },
        [fetchById.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchById.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.projectRecDetails = payload.result;
        },
        [fetchById.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
        },

        [moveCandidateRecords.pending]: (state) => {
            //state.isLoading = true;
            state.isMoved = false;
        },
        [moveCandidateRecords.fulfilled]: (state, { payload }) => {
            state.isMoved = true;
            state.message = 'Records moved successfully'
        },
        [moveCandidateRecords.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isMoved = false;
            state.isError = true;
            state.msg = payload.msgBody
        },

        [moveSingleData.pending]: (state) => {
            state.isProjectMoved = false;
        },
        [moveSingleData.fulfilled]: (state, { payload }) => {
            state.isProjectMoved = true;
            state.message = 'Records moved successfully'
        },
        [moveSingleData.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isProjectMoved = false;
            state.isError = true;
            state.msg = payload.msgBody
        },
        [moveProjectRecords.pending]: (state) => {
            state.isProjectMoved = false;
        },
        [moveProjectRecords.fulfilled]: (state, { payload }) => {
            state.isProjectMoved = true;
            state.message = 'Records moved successfully'
        },
        [moveProjectRecords.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isProjectMoved = false;
            state.isError = true;
            state.msg = payload.msgBody
        },

        [updateTable.pending]: (state) => {
            //state.isLoading = true;
            state.isUpdate = false;
        },
        [updateTable.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            // state.projectRecDetails = payload.result;
            // let mainArray = state.projectList;
            // let finalResult = mainArray.map((r) => {
            //     if (r.id === payload.result.id) {
            //         return (payload.result)
            //     } else {
            //         return r
            //     }
            // })
            // state.projectList = finalResult
            // state.msg = payload.message
        },
        [updateTable.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = false;
            state.isError = true;
            state.msg = payload.msgBody
        },

        [updateProjects.pending]: (state) => {
            //state.isLoading = true;
        },
        [updateProjects.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            state.projectRecDetails = payload.result;
            let mainArray = state.projectList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.projectList = finalResult
            state.msg = payload.message
        },
        [updateProjects.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = false;
            state.isError = true;
            state.msg = payload.msgBody
        },
        [deleteSingleData.pending]: (state) => {
        },
        [deleteSingleData.fulfilled]: (state, action) => {
            state.isProjectDeleted = true;
            state.projectList = state.projectList.filter((item) => item.id !== action.meta.arg.rowId)
            state.msg = 'Delete Successfully';
        },
        [deleteSingleData.rejected]: (state, { payload }) => {
            state.isProjectDeleted = false;
            state.isError = true;
            state.msg = payload.message
        },
        [deleteProject.pending]: (state) => {
        },
        [deleteProject.fulfilled]: (state, action) => {
            state.isDelete = true;
            state.projectList = state.projectList.filter((item) => item.id !== action.meta.arg.rowId)
            state.msg = 'Delete Successfully';
        },
        [deleteProject.rejected]: (state, { payload }) => {
            state.isDelete = false;
            state.isError = true;
            state.msg = payload.message
        },
        [addProjects.pending]: (state) => {
            state.isInsert = false;
        },
        [addProjects.fulfilled]: (state, { payload }) => {
            state.isInsert = true;
            //  state.projectList.unshift(payload.result)
            state.msg = payload.message;
        },
        [addProjects.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
        },
        [addNewCandidate.pending]: (state) => {
        },
        [addNewCandidate.fulfilled]: (state, { payload }) => {
            state.isInsert = true;
            state.candidateProjecttList.push(payload.result)
            state.msg = payload.message;
        },
        [addNewCandidate.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.msg = payload.message;
        },

        [candidateProjectListFun.pending]: (state) => {
            state.isLoading = true;
            state.isUpdate = false;
            state.isProjectMoved = false;
            state.isProjectDeleted = false;
        },
        [candidateProjectListFun.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.candidateProjecttList = payload.result;
        },
        [candidateProjectListFun.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
        },
        [deleteCandidate.pending]: (state) => {
        },
        [deleteCandidate.fulfilled]: (state, action) => {
            state.candidateProjecttList = state.candidateProjecttList.filter((item) => item.id !== action.meta.arg.id);
            state.isDelete = true;
            state.msg = 'Delete Successfully';
        },
        [deleteCandidate.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
        },
        [projectCandidateDataMove.pending]: (state) => {
            state.isDataLoading = false;
        },
        [projectCandidateDataMove.fulfilled]: (state, { payload }) => {
            state.isDataLoading = false;
            state.isDataMove = true;
            state.msg = payload.message;
        },
        [projectCandidateDataMove.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.msg = payload.message;
            state.isDataLoading = false;
        },
    }
});

export const { clearState, updateProfileDetails } = projectsSlice.actions;
export default projectsSlice.reducer;