import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import JobDetails from '../../../../assets/images/job-detail.png';
import InterviewSteps from '../../../../assets/images/interview-steps.png';
import JobSpec from '../../../../assets/images/job-spec.png';
import InterviewSpec from '../../../../assets/images/interview-spec.png';
import JobResources from '../../../../assets/images/job-resource.png';
import JobLink from '../../../../assets/images/job-link.png';
import CvLink from '../../../../assets/images/job-link.png';
import AddProject from './AddProject';
import EditProjects from './EditProjects';
import './liveprojects.scss';



const LiveProjects = () => {
    const [show, setShow] = useState();
    const [chartshow, setChartShow] = useState();

    const [showModalEdit, setEditShow] = useState(false);
    const showEditModal = () => {
        setEditShow(true)
    }
    const hideEditModal = () => {
        setEditShow(false)
    }

    return (
        <>
            {
                showModalEdit ?
                    <EditProjects handleClose={hideEditModal} /> : null
            }

            <div className="dk-workPlan-main">
                <div className="dk-workPlan-head">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-workPlanHead-leftpanel">
                            <div className="dk-workHead-right-btn">
                                <AddProject />
                                <div className="dk-reportCheckbox">
                                    <div className="dk-reportCheckbox-cols">
                                        <i className="far fa-file-alt"></i>
                                    </div>
                                    <div className="dk-reportCheckbox-cols dk-circleBtn">
                                        <i className="fas fa-filter"></i>
                                    </div>
                                    <div className="dk-reportCheckbox-cols dk-circleBtn">
                                        <i className="fas fa-user"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dk-workPlanTags">
                            <div className="d-flex">
                                <Link to="#">
                                    Active
                                    <span><i className="fas fa-times"></i></span>
                                </Link>
                            </div>
                        </div>
                        <div className="dk-workPlanHead-right">
                            <div className="dk-workHead-right-btn">
                                <Link to="#" className="dkgreen">LIVE - 45</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dk-workPlan-conatiner">
                    <div className="dk-workPlan-table">
                        <div className="dk-liveProjectSrcoll">
                            <div className="dk-workPlanTable-main">
                                <table className="table table-bordered dk-borderBtm">
                                    <tbody>
                                        <tr>
                                            <td className="dk-hotTags">
                                                <div className="boxCols dkred"></div>
                                                <span>11</span>
                                                <span className="dk-blue">ID : 1235</span>
                                                <div className="dk-checkBox">
                                                    <Link to="#" onClick={showEditModal}><i className="far fa-edit"></i></Link>
                                                    <Link to="#"><i className="far fa-trash-alt"></i></Link>
                                                    <input type="checkbox" onClick={() => setShow(!show)} className="dkClick" name="" id="" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center flex-wrap justify-content-center">
                                                    <div className="dk-workTitle">Content Reviewer Job in Krakow, Poland - Social Media</div>
                                                    <div className="dk-workTableImg">
                                                        <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" className="img-fluid" alt="" />
                                                        <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="dk-tabletdTitle">Jobs</span>
                                                <span className="textblue">30</span>
                                            </td>
                                            <td>
                                                <span className="dk-tabletdTitle">Target</span>
                                                <span className="textblue">30</span>
                                            </td>
                                            <td>
                                                <span className="dk-tabletdTitle">Filled</span>
                                                <span className="textblue">10</span>
                                            </td>
                                            <td>
                                                <span className="dk-tabletdTitle">Remains</span>
                                                <span className="textblue">20</span>
                                            </td>
                                            <td>
                                                <span className="dk-tabletdTitle">Sale</span>
                                                <span className="textblue"><i className="fas fa-euro-sign"></i> 8000</span>
                                            </td>
                                            <td>
                                                <span className="dk-tabletdTitle">Fee Per Hire</span>
                                                <span className="textblue"><i className="fas fa-euro-sign"></i> 170</span>
                                            </td>
                                            <td>
                                                <div className="dk-commentBox">
                                                    <span className="dk-tabletdTitle tableClick" onClick={() => setChartShow(!chartshow)}><i className="fas fa-chart-line"></i></span>
                                                    <span className="commentBox-icon">
                                                        <small>0</small>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className={`dk-tableTabs ${show ? "show" : ""}`}>
                                    <ul>
                                        <Link to="#">
                                            <img src={JobDetails} className="img-fluid" alt="" />
                                            Job Details
                                        </Link>
                                        <Link to="#" className="active">
                                            <img src={InterviewSteps} className="img-fluid" alt="" />
                                            Interview Steps
                                        </Link>
                                        <Link to="#">
                                            <img src={JobSpec} className="img-fluid" alt="" />
                                            Job Spec
                                        </Link>
                                        <Link to="#">
                                            <img src={InterviewSpec} className="img-fluid" alt="" />
                                            Interview Spec
                                        </Link>
                                        <Link to="#">
                                            <img src={JobResources} className="img-fluid" alt="" />
                                            Job Resources
                                        </Link>
                                        <Link to="#">
                                            <img src={JobLink} className="img-fluid" alt="" />
                                            Job Link
                                        </Link>
                                        <Link to="#">
                                            <img src={CvLink} className="img-fluid" alt="" />
                                            CV's Link
                                        </Link>
                                    </ul>
                                </div>

                                <table className="table table-bordered dk-tableTd-main dk-tpHeaTableMain">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Category
                                                <span>10</span>
                                            </td>
                                            <td>
                                                Company
                                                <span>13</span>
                                            </td>
                                            <td>
                                                Project
                                                <span>30</span>
                                            </td>
                                            <td>
                                                Role Type
                                                <span>22</span>
                                            </td>
                                            <td>
                                                Language
                                                <span>17</span>
                                            </td>
                                            <td>
                                                Location
                                                <span>33</span>
                                            </td>
                                            <td>
                                                Start Dates
                                                <span>25</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className={`dk-tableChartShow ${chartshow ? "show" : ""}`}>
                                    <table className="table table-bordered dk-tableTd-main">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Qualifying
                                                    <span className="textyellow">30</span>
                                                </td>
                                                <td>
                                                    Job Specs
                                                    <span className="textyellow">30</span>
                                                </td>
                                                <td>
                                                    Sendouts
                                                    <span className="textgreen">10</span>
                                                </td>
                                                <td>
                                                    Interview Specs
                                                    <span className="textgreen">34</span>
                                                </td>
                                                <td>
                                                    Interviews
                                                    <span className="textgreen">8</span>
                                                </td>
                                                <td>
                                                    Job Offers
                                                    <span className="textblue">6</span>
                                                </td>
                                                <td>
                                                    Joined
                                                    <span className="textblue">5</span>
                                                </td>
                                                <td>
                                                    Rejected
                                                    <span className="textred">5</span>
                                                </td>
                                                <td>
                                                    Withdrew
                                                    <span className="textred">1</span>
                                                </td>
                                                <td>
                                                    Not Reachable
                                                    <span className="textred">2</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="dk-workPlanTable-main">
                                <table className="table table-bordered dk-borderBtm">
                                    <tbody>
                                        <tr>
                                            <td className="dk-hotTags">
                                                <div className="boxCols dkgreen"></div>
                                                <span>11</span>
                                                <span className="dk-blue">ID : 1235</span>
                                                <div className="dk-checkBox">
                                                    <Link to="#" onClick={showEditModal}><i className="far fa-edit"></i></Link>
                                                    <Link to="#"><i className="far fa-trash-alt"></i></Link>
                                                    <input type="checkbox" onClick={() => setShow(!show)} className="dkClick" name="" id="" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center flex-wrap justify-content-center">
                                                    <div className="dk-workTitle">Content Reviewer Job in Krakow, Poland - Social Media</div>
                                                    <div className="dk-workTableImg">
                                                        <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" className="img-fluid" alt="" />
                                                        <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="dk-tabletdTitle">Jobs</span>
                                                <span className="textblue">30</span>
                                            </td>
                                            <td>
                                                <span className="dk-tabletdTitle">Target</span>
                                                <span className="textblue">30</span>
                                            </td>
                                            <td>
                                                <span className="dk-tabletdTitle">Filled</span>
                                                <span className="textblue">10</span>
                                            </td>
                                            <td>
                                                <span className="dk-tabletdTitle">Remains</span>
                                                <span className="textblue">20</span>
                                            </td>
                                            <td>
                                                <span className="dk-tabletdTitle">Sale</span>
                                                <span className="textblue"><i className="fas fa-euro-sign"></i> 8000</span>
                                            </td>
                                            <td>
                                                <span className="dk-tabletdTitle">Fee Per Hire</span>
                                                <span className="textblue"><i className="fas fa-euro-sign"></i> 170</span>
                                            </td>
                                            <td>
                                                <div className="dk-commentBox">
                                                    <span className="dk-tabletdTitle tableClick" onClick={() => setChartShow(!chartshow)}><i className="fas fa-chart-line"></i></span>
                                                    <span className="commentBox-icon">
                                                        <small>0</small>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className={`dk-tableTabs ${show ? "show" : ""}`}>
                                    <ul>
                                        <Link to="#">
                                            <img src={JobDetails} className="img-fluid" alt="" />
                                            Job Details
                                        </Link>
                                        <Link to="#" className="active">
                                            <img src={InterviewSteps} className="img-fluid" alt="" />
                                            Interview Steps
                                        </Link>
                                        <Link to="#">
                                            <img src={JobSpec} className="img-fluid" alt="" />
                                            Job Spec
                                        </Link>
                                        <Link to="#">
                                            <img src={InterviewSpec} className="img-fluid" alt="" />
                                            Interview Spec
                                        </Link>
                                        <Link to="#">
                                            <img src={JobResources} className="img-fluid" alt="" />
                                            Job Resources
                                        </Link>
                                        <Link to="#">
                                            <img src={JobLink} className="img-fluid" alt="" />
                                            Job Link
                                        </Link>
                                        <Link to="#">
                                            <img src={CvLink} className="img-fluid" alt="" />
                                            CV's Link
                                        </Link>
                                    </ul>
                                </div>

                                <table className="table table-bordered dk-tableTd-main dk-tpHeaTableMain">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Category
                                                <span>10</span>
                                            </td>
                                            <td>
                                                Company
                                                <span>13</span>
                                            </td>
                                            <td>
                                                Project
                                                <span>30</span>
                                            </td>
                                            <td>
                                                Role Type
                                                <span>22</span>
                                            </td>
                                            <td>
                                                Language
                                                <span>17</span>
                                            </td>
                                            <td>
                                                Location
                                                <span>33</span>
                                            </td>
                                            <td>
                                                Start Dates
                                                <span>25</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className={`dk-tableChartShow ${chartshow ? "show" : ""}`}>
                                    <table className="table table-bordered dk-tableTd-main">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Qualifying
                                                    <span className="textyellow">30</span>
                                                </td>
                                                <td>
                                                    Job Specs
                                                    <span className="textyellow">30</span>
                                                </td>
                                                <td>
                                                    Sendouts
                                                    <span className="textgreen">10</span>
                                                </td>
                                                <td>
                                                    Interview Specs
                                                    <span className="textgreen">34</span>
                                                </td>
                                                <td>
                                                    Interviews
                                                    <span className="textgreen">8</span>
                                                </td>
                                                <td>
                                                    Job Offers
                                                    <span className="textblue">6</span>
                                                </td>
                                                <td>
                                                    Joined
                                                    <span className="textblue">5</span>
                                                </td>
                                                <td>
                                                    Rejected
                                                    <span className="textred">5</span>
                                                </td>
                                                <td>
                                                    Withdrew
                                                    <span className="textred">1</span>
                                                </td>
                                                <td>
                                                    Not Reachable
                                                    <span className="textred">2</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LiveProjects;
