import React, { useLayoutEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import RecuiteraccLayout from '../layout/rcaccount/RecuiteraccLayout'


import { clearMessage } from "../../slice/utils/message/messageSlice";
import { logoutUser } from "../../slice/auth/authSlice";
import { userslist } from "../../slice/userList/userSlice";


const RcaccountRoute = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch();
    const { isAuthenticate } = useSelector(state => state.auth)
    const { myModule } = useSelector(state => state.module)
    // const companyId = process.env.REACT_APP_DEFAULT_COMPANY_ID;

    useLayoutEffect(() => {
        if (JSON.parse(localStorage.getItem("dkmasterBoxClient"))) {

        } else {
            dispatch(clearMessage())
            dispatch(logoutUser())
        }
        const fetchUserList = async () => {
            try {
                await dispatch(userslist({}))
            } catch (err) {
                console.log(err)
            }
        }

        fetchUserList()
    }, [])

    return (
        <Route {...rest} render={props => {
            if (isAuthenticate) {
                // console.log("Check my Account Navbar " + myModule);

                switch ("rc-account") {
                    
                    case 'rc-account':
                        return <RecuiteraccLayout><Component {...props} /></RecuiteraccLayout>


                }
            } else {
                // return <Component {...props} />
                return <Redirect
                    to={{
                        pathname: "/"
                        , state: { from: props.location }
                    }}
                />
            }
        }} />
    )
};
export default RcaccountRoute;