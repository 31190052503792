import React from "react";
import { Route, Link } from "react-router-dom";

const BusinessBookSubmenu = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    // const segment3 = pathname.split("/")[3];
    // const segment4 = pathname.split("/")[4];
    const lastSegment = window.location.href.split("/").pop(); //for decode use atob("zyz")
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        {/* <li><Link to="/accounting/businessoverview/2022/summary" className={`${(segment4 == 'summary') ? 'active' : ''}`}>Summary</Link></li> */}
                        <li><Link to={`/business-overview/newcase/` + atob(lastSegment) + '/' + lastSegment} className={(segment2 == 'newcase') ? 'active' : ''}>New Case</Link></li>
                        <li><Link to={`/business-overview/selected/` + atob(lastSegment) + '/' + lastSegment} className={(segment2 == 'selected') ? 'active' : ''}>Selected</Link></li>
                        <li><Link to={`/business-overview/qualified/` + atob(lastSegment) + '/' + lastSegment} className={(segment2 == 'qualified') ? 'active' : ''}>Qualifying</Link></li>
                        <li><Link to={`/business-overview/sendouts/` + atob(lastSegment) + '/' + lastSegment} className={(segment2 == 'sendouts') ? 'active' : ''}>Sendouts</Link></li>
                        <li><Link to={`/business-overview/interviews/` + atob(lastSegment) + '/' + lastSegment} className={(segment2 == 'interviews') ? 'active' : ''}>Interviews</Link></li>
                        <li><Link to={`/business-overview/job-offers/` + atob(lastSegment) + '/' + lastSegment} className={`${(segment2 == 'job-offers') ? 'active' : ''}`}>Job Offers</Link></li>
                        <li><Link to={`/business-overview/joined/` + atob(lastSegment) + '/' + lastSegment} className={(segment2 == 'joined') ? 'active' : ''}>Joined</Link></li>
                        <li><Link to={`/business-overview/final-fee/` + atob(lastSegment) + '/' + lastSegment} className={(segment2 == 'final-fee') ? 'active' : ''}>Invoiced</Link></li>

                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default BusinessBookSubmenu;
