import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { useSelector } from "react-redux";

const StatusFilter = ({ list, selectOtherFilter, caseStatusFilterDDLText }) => {
    const { caseStatusList } = useSelector(state => state.common);
    return (
        <React.Fragment>
            <DropdownButton className="dropdownFilter" id="dropdown-basic-button" title={caseStatusFilterDDLText}>
                {
                    caseStatusList.map((statusItem, index) =>
                        <Dropdown.Item key={`case-status-id${index}`} href="#" style={{ backgroundColor: statusItem.bgColor, color: statusItem.textColor }} data-type="caseStatus" data-value={statusItem.value} onClick={selectOtherFilter}>
                            {statusItem.value}
                            {
                                list.filter(items => items.caseStatus === statusItem.value).map((item, index) =>
                                    <span className="dropdown-filter-count">{item.count}</span>
                                )
                            }
                        </Dropdown.Item>
                    )
                }
            </DropdownButton>
        </React.Fragment>
    )
}

export default StatusFilter;