import React from 'react';
// import RulesNavbar from '../navtabs/RulesNavbar';
import DkGlobalLeftPanel from "../leftpanel/DkGlobalLeftPanel";


const WorkplanRulesTasks = () => {

    return (
        <React.Fragment>
            <div className="dk-dayclsoing-Main dk-dayclsoing-DetailMain">
                {/* <RulesNavbar /> */}
                <div className='dkg-started-content-u234'>
                    <div className="d-flex">
                        <DkGlobalLeftPanel />
                        <div className="dk-trainingReqRight-main">
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut quam deserunt quidem recusandae similique ad corporis ducimus in temporibus labore doloribus excepturi aliquam odit, optio inventore exercitationem tempore voluptates culpa.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}
export default WorkplanRulesTasks;

