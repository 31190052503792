import React from 'react';
import { Link } from 'react-router-dom';

const Preferences = () => {
    return (
        <React.Fragment>
            <div className="row pr-3">
                <div className="col-md-6 col-sm-12">
                    <div className="dk-InterviewRightCon">
                        <div className="dk-RightTophder d-flex">
                            <h2 className="topTitle">Likes of Candidates</h2>
                            <Link to="#"><i className="fa fa-plus" aria-hidden="true"></i> Add New</Link>
                        </div>
                        <div className="dk-InterviewRightContent mt-1">
                            <p></p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="dk-InterviewRightCon">
                        <div className="dk-RightTophder d-flex">
                            <h2 className="topTitle">Dislikes of Candidates</h2>
                            <Link to="#"><i className="fa fa-plus" aria-hidden="true"></i> Add New</Link>
                        </div>
                        <div className="dk-InterviewRightContent mt-1">
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Preferences;
