import React from 'react';
import OverviewLeftPanel from "./leftpanel/OverviewLeftPanel";
import TemplatesDetailLeftTabs from "./detaillefttabs/TemplatesDetailLeftTabs";
import './templates.scss';

const Templates = () => {
    return (
        <>
            <div className="dk-backBtnMain">
                <h3 className="dk-detailTitle">
                    Templates
                </h3>
            </div>
            <div className="dk-templatesDetailMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-templatesDetailsLeftPanel">
                            <OverviewLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-templatesDetailTabsMain">
                                <TemplatesDetailLeftTabs />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Templates;
