import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { InputGroup, FormControl, Button, Table } from 'react-bootstrap';
import { clearState, updateMiniSpecTab, updateTitleBlocks, addInstructions } from "../../../../../../../slice/jobs/jobsSlice";
import { showSuccess, showError, clearMessage } from "../../../../../../../slice/utils/message/messageSlice";

const Adstopost = (jobId) => {
    const dispatch = useDispatch();

    const { isDetailsLoading, jobDetails, isUpdate } = useSelector(state => state.jobs);

    const [data, setData] = useState('');
    useEffect(() => {
        if (jobDetails && !isDetailsLoading) {
            setData(jobDetails);
        }
    }, [isDetailsLoading])

    useEffect(() => {
        if (isUpdate) {
            dispatch(clearState())
            setData(jobDetails);
        }
    }, [isUpdate])

    return (
        <>
            <div className="row dk-Adsbox">
                <div className="col-md-12">
                    <div className="col-md-12 p-0 resourcesTable">
                        <h3>Ads to Post <i className="fa fa-plus" aria-hidden="true"></i></h3>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Name of Portal</th>
                                    <th>No of Ads</th>
                                    <th>Repost</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Text</td>
                                    <td>Text</td>
                                    <td>Text</td>
                                    <td><i className="fa fa-trash" aria-hidden="true"></i></td>
                                </tr>
                                <tr>
                                    <td>Text</td>
                                    <td>Text</td>
                                    <td>Text</td>
                                    <td><i className="fa fa-trash" aria-hidden="true"></i></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                </div>

            </div>
        </>
    )
}

export default Adstopost
