import React from 'react';
import SalesKpi from "./SalesKpi";
import './saleskpi.scss';

const SalesKpiDashboard = () => {
    return (
        <>
            <div className="dk-slaesKpiDashboardMain">
                <div className="title">RECRUITES ANNUAL SALE</div>
                <SalesKpi />
            </div>
        </>
    )
}

export default SalesKpiDashboard;
