import React from 'react';
import ItServicesLeftPanel from './leftpanel/ItServicesLeftPanel';
import "../financecustom.scss";

const FinanceItServicesOverview = () => {
    return (
        <div className="dk-moduledkFinanceProcess-main">
            <div className="dk-moduledkFinanceProcess-cols">
                <ItServicesLeftPanel />
                <div className="dk-moduledkFinanceProcess-rightPanel">
                    <div className="title">Overview</div>
                    <div className="dk-moduledkFinanceProcess-rightCols">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinanceItServicesOverview;
