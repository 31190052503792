import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const DkGlobalStructureModal = ({ handleClose }) => {
    return (
        <>
            <Modal className="dk-structureModal right" show={true} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal Title</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default DkGlobalStructureModal;
