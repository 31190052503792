import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft, faPaperclip, faClock } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import CustomDateFormat from "../../../../ui/dateFormats/CustomDateFormat"; 
import AvatarLoad from '../../../../ui/avatarLoad';
import PreviewModal from "../PreviewModal";
import SendEmailUser from '../../../../../assets/images/ajay.jpg'
// import { FaCalendar, FaClock } from "react-icons/fa";
import MailInfo from './mailInfo'
import AttachmentPreviewModal from "../modal/attachmentPreview"

export const MailDetails = () => {
    const { isDetailsLoading, details } = useSelector(state => state.mailbox);

    const [shoePreview, setPreview] = useState(false);
    const [previewFile, setPreviewFile] = useState(null);

    const openPreview = (file) => {
        setPreview(true);
        setPreviewFile(file);
    }

    const closePreview = () => {
        setPreview(false)
        setPreviewFile(null);
    }

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    const [showAttachmentModal, setShowPreviewAttachment] = useState(false);
    return (
        <React.Fragment>
            <AttachmentPreviewModal show={shoePreview} file={previewFile} onClose={closePreview}/>
            <PreviewModal show={showAttachmentModal} onHide={() => setShowPreviewAttachment(false)} />
            {
                (isDetailsLoading || !details) ? null :
                
                    <div className="w-100 dkg-mailbox-rightmainCon">
                        <div className="dk-detailText">
                            <MailInfo replies={details?.mailbox?.Replies} onShow={openPreview}/>
                        </div>
                        <React.Fragment>
                            <div className="dk-msgRight-panel">
                                <div className="dk-msgHeader">
                                    {/* <div className="dk-userTitle">Sub: {details.subject} */}
                                    <div className="dk-userTitle">
                                        <div className='dkg-sendemail-imgCon'>
                                            <img src={SendEmailUser} alt='User Icon' className='dkg-sendemail-img' />
                                        </div>
                                        <div className="dk-detailText">
                                            <div className="title">From : {details?.mailbox?.from_name} &lt;{details?.mailbox?.from}&gt;
                                            </div>
                                            <div className='dkg-bc-datepickerCon'>
                                                <div className="d-flex align-?items-center">
                                                    <div className="sbtitle mr-2"><b>To : </b> 
                                                        {
                                                            details?.mailbox?.to.map((email) => email.email).join(',')
                                                        }                                            
                                                    </div>
                                                    { 
                                                        details?.mailbox?.cc.length > 0 &&
                                                        <div className="sbtitle"><b>Cc:</b> 
                                                            {
                                                                details?.mailbox?.cc.map((email) => email.email).join(',')
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <div className='d-flex dkg-email-showdatepickerCon?'>
                                                    <span className='dkg-email-showdatepicker'> {<CustomDateFormat date={details?.mailbox?.date} format='ddd DD[th] MMM YYYY [,] HH:mm A' />}</span>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        {/* <span className='dkg-email-showdatetimer'><FontAwesomeIcon icon={faClock} /> {<CustomDateFormat date={details.createdAt} format='ddd MMM DD[th] YYYY [, at] HH:mm A' />}</span> */}
                                    </div>
                                </div>
                                <div className="dk-msgSender-detail">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="dk-detail-leftPanel">
                                            {/* <div className="userImg">
                                                <div className="imgCircle">
                                                    {<AvatarLoad name="Luiza User" />}
                                                </div>
                                            </div> */}
                                            <div className='dkg-email-subject-Con'>
                                            <div className='dkg-email-subject'><span className='dkg-subtile'>Subject : </span>{details?.mailbox?.subject}</div>
                                            </div>
                                            {
                                                details?.mailbox?.attachments.length > 0 &&
                                                <div className="dk-mailViewAttachfile-Con">
                                                    {
                                                        details?.mailbox?.attachments.map(attachment =>
                                                            <div className="dk-mailViewAttach" onClick={() => openPreview(attachment.file_url)}>{ attachment.file_name } <FontAwesomeIcon icon={faPaperclip} /></div>
                                                        )
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="dk-readMsg-main">
                                    {(isDetailsLoading) ? <Skeleton animation="wave" style={{ width: '50%' }} /> : details.body}
                                </div> */}
                                <div className='dkg-readmsg-mainCon' style={{
                                    backgroundColor: details?.mailbox?.Replies.length > 0 ? '#f1f6fc' : '#fff'
                                }}>
                                {(isDetailsLoading) ? <Skeleton animation="wave" style={{ width: '50%' }} /> :
                                    <div dangerouslySetInnerHTML={{ __html: details?.mailbox?.body }} /> 
                                }
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
            }
        </React.Fragment>
    )
}
