import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './salestips.scss';

const SalestipsMainMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK Global CRM";
    }, [pathname]);

    // const { allBadge } = useSelector(state => state.tasks);
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-salestips-navbar">
                <Route>
                    <ul>
                        <li><Link to="/sales-tips/summary" className={`${(segment2 === 'summary') ? 'active' : ''}`}>Summary</Link></li>
                        <li><Link to="#/sales-tips/salaries" className={`${pathname.match('/salaries') ? 'active' : ''}`}>Sales Tips 2</Link></li>
                        <li><Link to="#/sales-tips/income-tax" className={`${pathname.match('/income-tax') ? 'active' : ''}`}>Sales Tips 3</Link></li>
                        <li><Link to="#/sales-tips/benefits" className={`${pathname.match('/benefits') ? 'active' : ''}`}>Sales Tips 4</Link></li>
                        <li><Link to="#/sales-tips/relocation" className={(segment2 === "relocation") ? 'active' : ''}>Sales Tips 5</Link></li>
                        <li><Link to="#/sales-tips/interviews" className={`${pathname.match('/interviews') ? 'active' : ''}`}>Sales Tips 6</Link></li>
                        <li><Link to="#/sales-tips/profiling" className={`${pathname.match('/profiling') ? 'active' : ''}`}>Sales Tips 7</Link></li>
                        <li><Link to="#/sales-tips/profiling" className={`${pathname.match('/profiling') ? 'active' : ''}`}>Sales Tips 8</Link></li>
                        <li><Link to="#/sales-tips/profiling" className={`${pathname.match('/profiling') ? 'active' : ''}`}>Sales Tips 9</Link></li>
                        <li><Link to="#/sales-tips/profiling" className={`${pathname.match('/profiling') ? 'active' : ''}`}>Sales Tips 10</Link></li>
                        <li><Link to="#/sales-tips/profiling" className={`${pathname.match('/profiling') ? 'active' : ''}`}>Sales Tips 11</Link></li>                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default SalestipsMainMenu;