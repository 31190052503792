import  './resume.scss'

const MyaccMyresume = () => {
    return (
        <>
            <div className='dkg-myacc-resumeMainCon'></div>
        </>
    )
}

export default MyaccMyresume