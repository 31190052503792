import React, { useMemo, useState, useEffect } from "react";
import { useTable, useSortBy, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import './savedproject.scss';
import SearchFilterModal from './modal/SearchFilter';
import ReportModal from './modal/report/ReportModal';
import EditModal from './modal/edit/EditModal';
import CreateNewModal from './modal/createnew/CreateNew';
import DeleteModal from "../../../../ui/delete";
import { GlobalSearch } from "./GlobalSearch";
import UserFilterToggle from "../../../../ui/tableUserFilter";
import { SYSTEM_CONSTANTS } from "../../../../../constants";
import TablePagination from '../../../../ui/pagination/';
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import { projectDetails, deleteProject, clearState } from '../../../../../slice/projects/projctsSlice';
import { companyDataFetch, projectDataFetch, roleTypeDataFetch, languageDataFetch, cvSourceDataFetch, categoryDataFetch } from "../../../../../slice/kpis/assignKpisReportSlice";
export const BasicTable = () => {
    const dispatch = useDispatch();
    const { projectList, isUpdate, isInsert, isError, isDelete, msg } = useSelector(state => state.projects)
    const data = projectList

    const [isSearchFilter, setIsSearchFilter] = useState(false);
    const [isCreateNew, setIsCreateNew] = useState(false);
    const [isRportOpen, setIsRportOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [issDelete, setIsDelete] = useState(false);
    const [rowId, setRowId] = useState('0')

    const [userFilterId, setUserFilterId] = useState('0')
    const [userFilterImg, setUserFilterImg] = useState('0')

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(companyDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.comapnyId }))
                await dispatch(projectDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.projectId }))
                await dispatch(roleTypeDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.roleType }))
                await dispatch(languageDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.languageId }))
              //  await dispatch(cvSourceDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.cvSourceId }))
              await dispatch(categoryDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.categoryyId }))

            } catch (err) {
                console.log(err)
            }
        }
        fetchList();      
    }, [])

    const handlePageChange = (event, value) => {
        gotoPage(Number(value) - 1);
    };

    const showSearchFilterModal = () => {
        setIsSearchFilter(true);
    }
    const hideSearchFilterModal = () => {
        setIsSearchFilter(false);
    }
    const showcreatenewModal = () => {
        setIsCreateNew(true);
    }
    const hidecreatenewModal = () => {
        setIsCreateNew(false);
    }

    const showAddpopup = () => {
        setIsCreateNew(true);
    }
    const showreportModal = () => {
        setIsRportOpen(true);
    }

    const hidereportModal = () => {
        setIsRportOpen(false);
    }

    const showeditpopup = () => {
        isEditOpen(true);
    }

    const handleClose = () => {
        setRowId('0')
        isEditOpen(false)
        setIsDelete(false)
    }

    const showEditModal = async (projectId) => {
        await dispatch(projectDetails({ projectId }))
        setIsEditOpen(true);
        setRowId(projectId)
    }

    const deleteItem = async () => {
        dispatch(deleteProject({ rowId }))
    }

    const hideEditModal = () => {
        setIsEditOpen(false);
    }
    const showDeleteModal = async (projectId) => {
        setRowId(projectId)
        setIsDelete(true);
    }
    const hideDeleteModal = () => {
        setIsDelete(false);
    }

    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(clearMessage())
            dispatch(showSuccess({ msg: msg }))
            setIsDelete(false)
            setRowId('0')
        }
    }, [isDelete])

    const clickFilterUser = (e) => {
        setUserFilterId(e.target.id)
        setUserFilterImg(e.target.src)
        setFilter('userInfo', e.target.id)
    }

    const deleteFilter = (name) => {
        setFilter(name, '')
        if (name === 'userInfo') {
            setUserFilterId('0')
            setUserFilterImg('0')
        }
    }

    const columns = useMemo(() => [

        {
            Header: 'Recruiter',
            accessor: 'userInfo.profileImage',
            Cell: (row) => (
                <div className="dk-recrImg">
                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + row.row.original.userInfo.profileImage} alt={row.row.original.name} className="img-fluid dk-userImg" />
                </div>
            )
        },
        {
            Header: 'Project Title',
            accessor: 'title',
            Cell: (row) => (
                <span className="projectTitle">
                    {row.row.original.title}
                </span>
            )
        },
        {
            Header: 'Candidates',
            accessor: 'candidatesCount',
            Cell: (row) => (
                <>
                    <div className="dk-candidate">
                        <Link to={"/db-table/projects/talent-pool/" + btoa(row.row.original.id)}><i className="fas fa-users"></i> {row.row.original.candidatesCount} Candidates</Link>
                    </div>
                </>
            )
        },
        {
            Header: 'Company',
            accessor: 'companies'
        },
        {
            Header: 'Language',
            accessor: 'language'
        },
        {
            Header: 'Project',
            accessor: 'project'
        },
        {
            Header: 'Role Type',
            accessor: 'roleType'
        },
        {
            Header: 'Category',
            accessor: 'category'
        },
        {
            Header: 'Action',
            Cell: (row) => (
                <div className="dk-action">
                    {/* <Link to="#" onClick={showreportModal} className="dk-report mr-2" title="Report">
                        <i className="fas fa-file"></i>
                    </Link> */}
                    <Link to="#" onClick={() => showEditModal(row.row.original.id)} className="mr-2 dk-edit">
                        <i className="fas fa-edit"></i>
                    </Link>
                    <Link to="#" onClick={() => showDeleteModal(row.row.original.id)} className="dk-delete">
                        <i className="fas fa-trash-alt"></i>
                    </Link>
                </div>
            )
        },
        {
            id: 'userInfo',
            accessor: 'userInfo.uid',
            show: false
        }

    ], [])

    const tableInstance = useTable({
        columns: columns,
        data: data,
        initialState: {
            pageIndex: 0, pageSize: 100, hiddenColumns: ["userInfo"]
        },
    }, useGlobalFilter, useFilters, useSortBy, usePagination)

    const { getTableProps, getTableBodyProps, headerGroups, rows, page, prepareRow, state, setGlobalFilter, setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize } } = tableInstance
    const { globalFilter } = state

    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: msg }))
            setIsEditOpen(false);
            dispatch(clearState())
        }
        if (isUpdate) {
            setIsEditOpen(false);
            dispatch(showSuccess({ msg: msg }))
            dispatch(clearState())
        }
    }, [isError, isUpdate])

    useEffect(() => {
        if (isError) {
            // setUserId('0')
            dispatch(showError({ msg: msg }))
            setIsCreateNew(false);
            dispatch(clearState())
            setSuccess(true);
            setLoading(false);
            //dispatch(clearLoading())
        }
        if (isInsert) {
            setIsCreateNew(false);
            dispatch(showSuccess({ msg: msg }))
            dispatch(clearState())
            setSuccess(true);
            setLoading(false);
            // dispatch(clearLoading())
        }
    }, [isError, isInsert])

   
    
    return (
        <>
            {
                (isSearchFilter) ? <SearchFilterModal hidepopup={hideSearchFilterModal} /> : null
            }
            {
                (isCreateNew) ? <CreateNewModal hideModal={hidecreatenewModal} showAddpopup={showAddpopup} canIdList="" allCandidate='0' /> : null
            }
            {
                (isRportOpen) ? <ReportModal hidereportModal={hidereportModal} /> : null
            }
            {
                (isEditOpen) ? <EditModal rowId={rowId} hidePopup={hideEditModal} showeditpopup={showeditpopup} /> : null
            }
            {
                (issDelete) ? <DeleteModal id={rowId} handleClose={handleClose} deleteItem={deleteItem} /> : null
            }
            <div className="container-fluid dk-savedprojectCon">
                <div className="row">
                    <div className="col-12">
                        <div className="dk-savedTopHead">
                            <div className="dk-savedLeftTopHead">
                                <div className="dk-SrchInputBox mr-2">
                                    <GlobalSearch filter={globalFilter} setFilter={setGlobalFilter} />
                                </div>
                                <div className="mr-2 dk-usrCricelIcon">
                                    <UserFilterToggle userFilterId={userFilterId} userFilterImg={userFilterImg} list={tableInstance.flatRows} selectUser={clickFilterUser} deleteFilter={deleteFilter} />
                                </div>
                                <div className="mr-2 dk-srchCricelIcon">
                                    <Link to="#" onClick={showSearchFilterModal}><i className="fas fa-search"></i></Link>
                                </div>
                            </div>
                            <div className="dk-savedCentreTopHead">
                                <h3 className="title">PROJECTS LIST</h3>
                            </div>
                            <div className="dk-savedRightTopHead">
                                <div className="dk-createbtnCon d-none">
                                    <Link to="#" onClick={showcreatenewModal}><i className="fa fa-plus mr-1" aria-hidden="true"></i>Create New Project</Link>
                                </div>
                            </div>
                        </div>

                        <div className="dk-savedProTableCon table-responsive">
                            <table className="table talbe-bordered dk-savedProTable" {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map(
                                        (row, i) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => (
                                                        <td
                                                            {...cell.getCellProps([
                                                                {
                                                                    className: cell.column.className, // pay attention to this
                                                                    style: cell.column.style,
                                                                    // set here your other custom props
                                                                },
                                                            ])}
                                                        >
                                                            {cell.render('Cell')}
                                                        </td>
                                                    ))}
                                                </tr>
                                            )
                                        }
                                    )}
                                </tbody>

                            </table>
                        </div>

                        <div className="col-md-12">
                            <div className="dk-resPaginationCon">
                                <div className="dk-resPagination">
                                    <div className="dk-resLeftPag">
                                        <div className="dk-texCounter">Showing {' '}{pageIndex + 1} to {pageOptions.length} (of {tableInstance.rows.length} entries)</div>
                                    </div>
                                    <div className="dk-resrightPag">
                                        <TablePagination count={pageCount} onChange={handlePageChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


