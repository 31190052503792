import React from 'react';
import './departments.scss';
import DepartmentLeftPanel from "./leftpanel/DepartmentLeftPanel";

const DepartmentSummary = () => {
    return (
        <>
            <div className="dk-departmentsDetailMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <DepartmentLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                <h3 className="title">Overview</h3>
                                <div className="dk-departments-cols">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid similique dolore laboriosam reprehenderit sapiente at iusto? Soluta tempora eveniet suscipit quae aliquid provident rem exercitationem ipsa eos aspernatur? Necessitatibus, optio?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default DepartmentSummary;
