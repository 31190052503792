import React, { useEffect, useState, useRef } from 'react';
import { Modal, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
//import Submit from '../../../../../../../ui/submitButton'
import { updateDocuments, clearState } from '../../../../../../../../slice/candidates/candidatesSlice';
import AWS from 'aws-sdk'
import { SYSTEM_CONSTANTS } from "../../../../../../../../constants";
import { showSuccess, showError, clearMessage } from "../../../../../../../utils/messages/messageSlice";
// import { FaEdit, FaPlus } from 'react-icons/fa';
// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const AddNewFile = ({ onHide,canIdd }) => {
    const fileInputAPI = useRef();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { isProfileTabLoading, isDocumentUpdate, candidateDocumentsList, isDocumentDelete, isFileTypeUpdate } = useSelector(state => state.candidate);
     const { candidateDocTypeList } = useSelector(state => state.common);

    const [localPath, setLocalPath] = useState('');

    const [fileType, setFileType] = useState('CV')
    const [onlyfileName, setonlyFileName] = useState('')

     const changeFileType = async (data) => {
        setFileType(data)
    }    
   
    const onSelectFileByAPI = async (e) => {
        if (e.target.files && e.target.files.length > 0) {           
           // setFile(e.target.files[0])
            setonlyFileName(e.target.files[0].name) 
            let tmppath = URL.createObjectURL(e.target.files[0]);
            setLocalPath(tmppath)
        }

        try {
            if(fileType=="Select Doc Type"){
                dispatch(showError({ msg: "Please select document type" }))
                 dispatch(clearState())
            }
       
            let s3bucket = new AWS.S3({
                accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
                secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
                Bucket: process.env.REACT_APP_BUCKET_NAME
            });

            let fileNewName = Date.now() + '-' + e.target.files[0].name;
            s3bucket.createBucket(function () {
                var params = {
                    Bucket: process.env.REACT_APP_BUCKET_NAME,
                    Key: fileNewName,
                    Body: e.target.files[0],
                    ContentType: e.target.files[0].type
                };

                s3bucket.upload(params, function (err, data) {
                    if (err) {
                        console.log(err)
                    } else {
                        dispatch(updateDocuments({ id: canIdd, fileName: SYSTEM_CONSTANTS.awsS3.Url + data.key,fileType:fileType,onlyfileName:e.target.files[0].name }))
                    }
                });
            });

        } catch (err) {
            console.log(err)
        }
    }

    // const [loading, setLoading] = useState(false);
    // const [success, setSuccess] = useState(false);

    //  const handleButtonClick = async (e) => {
    //     e.preventDefault();
    //     if (fileType=="Select Doc Type" ) {
    //         dispatch(showError({ msg: 'All fields required' }))
    //     } else {
    //         setSuccess(false);
    //         setLoading(true);
           
    //     }
    // }


    return (
        <>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dkg-addnewdocs-Modal"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton className='dk-addnewdocs-ModalHeader'>
                    <Modal.Title>ADD NEW DOCUMENT</Modal.Title>
                </Modal.Header>
                <Modal.Body className='dk-addnewdocs-ModalBody'>
                    <div className="dkg-docs-topheaderCon">
                        <div className="dkcabparseFormCon">
                            <div className=" dkg-addnew-doc-dropdownCon">
                                <Dropdown className='dkg-addnew-doc-dropdown'>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {fileType}
                                    </Dropdown.Toggle>
                                    
                                        
                                         <Dropdown.Menu className='dkg-addnew-doc-dropdown-menu'>
                                            {
                                                candidateDocTypeList.map((data, index) =>
                                                    <Dropdown.Item key={index + 1} href="#" className='dkg-addnew-doc-item' onClick={() => changeFileType(data.value)}>{data.value}</Dropdown.Item>
                                                )
                                            }

                                         </Dropdown.Menu>
                                        
                                    
                                </Dropdown>
                            </div>
                            <form>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="dk-parseImgBrowserCon hv-auto">
                                            <div className="image-input">
                                                <input type="file" id="imageInputAPI" onChange={onSelectFileByAPI} />
                                                <label for="imageInputAPI" className="image-button">
                                                    {
                                                        localPath=="" ? <><i className="fa fa-upload mr-2"></i>Select New Doc *</> : <>{localPath}  <CircularProgress size={40} className={classes.buttonProgress} /> </>
                                                    }
                                                     </label>
                                                <img src="" className="image-preview" alt="resume" />
                                                <span className="change-image">Choose different Resume</span>
                                               
                                            </div>
                                        </div>
                                        {/* <div className="dkg-docs-savebtn-Con">
                                            <Submit txt="Save" position="justify-content-center" className="btn dkg-docs-savebtn" loading={loading} success={success} onClick={handleButtonClick}/>
                                        </div> */}
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddNewFile