import React, { useState } from 'react';
import { Link } from "react-router-dom";
import CaseQueriesImg from "../../../../assets/images/case-queries.png";
import InterviewCallImg from "../../../../assets/images/interview-call.png";
import SendOutImg from "../../../../assets/images/sendout_white.png";
import UploadPortalsImg from "../../../../assets/images/portals_white.png";
import LiveNewQueryImg from "../../../../assets/images/leads.png";
import ReachableImg from "../../../../assets/images/not-reachables.png";
import JobDetailsImg from "../../../../assets/images/user-white.png";
import PreparationImg from "../../../../assets/images/preparation_icon.png";
import PerformanceImg from "../../../../assets/images/performance_white.png";
import KnowledgeImg from "../../../../assets/images/knowledge-base.png";
import ActionImg from "../../../../assets/images/action_icon.png";
import UpdateProfileImg from "../../../../assets/images/resume_white.png";
import FullJobSpecImg from "../../../../assets/images/mailbox-management.png";
import CreateJobImg from "../../../../assets/images/summary.png";
import CaseDecisionImg from "../../../../assets/images/close-decision.png";
import './recruiters.scss';

const Recruiters = () => {
    return (
        <>
            <div className="dk-recruitersTrainingMain dk-recruitersTrainingContainer">
                <div className="container-fluid">
                    <div className="dk-trainingMainCols dk-md-Container dk-trainingAdminCols">
                        <h3 className="title">TEAM 3 - RECRUITMENT CONSULTANTS</h3>
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b' }}>
                                    <Link to="/training/recruiters/job-details">
                                        <figure>
                                            <img src={JobDetailsImg} />
                                        </figure>
                                        <h3>Job Details</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b' }}>
                                    <Link to="/training/recruiters/preparation">
                                        <figure>
                                            <img src={PreparationImg} />
                                        </figure>
                                        <h3>Preparation</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b' }}>
                                    <Link to="/training/recruiters/action">
                                        <figure>
                                            <img src={ActionImg} />
                                        </figure>
                                        <h3>Action</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b' }}>
                                    <Link to="/training/recruiters/performance">
                                        <figure>
                                            <img src={PerformanceImg} />
                                        </figure>
                                        <h3>Performance</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b' }}>
                                    <Link to="/training/recruiters/knowledge-base">
                                        <figure>
                                            <img src={KnowledgeImg} />
                                        </figure>
                                        <h3>Knowledge Base</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b' }}>
                                    <Link to="/training/recruiters/case-queries">
                                        <figure>
                                            <img src={CaseQueriesImg} />
                                        </figure>
                                        <h3>Task 1 - Candidate Queries</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b' }}>
                                    <Link to="/training/recruiters/interview-call">
                                        <figure>
                                            <img src={InterviewCallImg} />
                                        </figure>
                                        <h3>Task 2 - Interview Call</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b' }}>
                                    <Link to="/training/recruiters/update-profile">
                                        <figure>
                                            <img src={UpdateProfileImg} />
                                        </figure>
                                        <h3>Task 3 - Update Profile</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b' }}>
                                    <Link to="/training/recruiters/full-job-spec">
                                        <figure>
                                            <img src={FullJobSpecImg} />
                                        </figure>
                                        <h3>Task 4 - Full Job Spec</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b' }}>
                                    <Link to="/training/recruiters/make-sendout">
                                        <figure>
                                            <img src={SendOutImg} />
                                        </figure>
                                        <h3>Task 5 - Make Send Out</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b' }}>
                                    <Link to="/training/recruiters/clients-portals">
                                        <figure>
                                            <img src={UploadPortalsImg} />
                                        </figure>
                                        <h3>Task 6 - Client Portal</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b' }}>
                                    <Link to="/training/recruiters/not-reachable">
                                        <figure>
                                            <img src={ReachableImg} />
                                        </figure>
                                        <h3>Task 7 - Not Reachable</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b' }}>
                                    <Link to="/training/recruiters/live-new-query">
                                        <figure>
                                            <img src={LiveNewQueryImg} />
                                        </figure>
                                        <h3>Task 8 - Live New Query</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b' }}>
                                    <Link to="/training/recruiters/create-job-spec">
                                        <figure>
                                            <img src={CreateJobImg} />
                                        </figure>
                                        <h3>Task 9 - Create Job Spec</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b' }}>
                                    <Link to="/training/recruiters/case-decision">
                                        <figure>
                                            <img src={CaseDecisionImg} />
                                        </figure>
                                        <h3>Task 10 - Case Decision</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b', minHeight:'124px' }}>
                                    <Link to="#">
                                        <figure>
                                            
                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b', minHeight:'124px' }}>
                                    <Link to="#">
                                        <figure>
                                            
                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b', minHeight:'124px' }}>
                                    <Link to="#">
                                        <figure>
                                            
                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b', minHeight:'124px' }}>
                                    <Link to="#">
                                        <figure>
                                            
                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="os-box" style={{ background: '#409a9b', minHeight:'124px' }}>
                                    <Link to="#">
                                        <figure>
                                            
                                        </figure>
                                        <h3></h3>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Recruiters;
