import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import Submenu from "../../subMenu/CommonTrainingSubmenu";

const CommonTraining = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalAdminTrainingMainMenu commonMainNav">
                <Route>
                    <ul>
                        <li><Link to="/dkglobaladmin/commonadmintraining/commondashboard" className={(segment3 == 'commondashboard') ? 'active' : ''}>Dashboard</Link></li>
                        <li><Link to="/trainingmodule/recruitment/History" className={`${(segment2 == 'recruitment') ? 'gray-active' : ''}`}>Recruitment</Link></li>
                        <li><Link to="/commontraining/dkglobal/company/overview" className={`${(segment2 == 'dkglobal') ? 'gray-active' : ''}`}>Company</Link></li>
                        <li><Link to="/trainingmodule/business/summary" className={`${(segment2 == 'business') ? 'gray-active' : ''}`}>Business</Link></li>
                        <li><Link to="/trainingmodule/suppliers-training/sample-1" className={`${(segment2 == 'suppliers-training') ? 'gray-active' : ''}`}>Suppliers</Link></li>
                        <li><Link to="/trainingmodule/clients-training/sample-1" className={`${(segment2 == 'clients-training') ? 'gray-active' : ''}`}>Clients</Link></li>
                        <li><Link to="/trainingmodule/products-training/sample-1" className={`${(segment2 == 'products-training') ? 'gray-active' : ''}`}>Products</Link></li>
                        <li><Link to="/trainingmodule/customers-training/sample-1" className={`${(segment2 == 'customers-training') ? 'gray-active' : ''}`}>Customers</Link></li>
                        <li><Link to="/trainingmodule/worktools-training/sample-1" className={`${(segment2 == 'worktools-training') ? 'gray-active' : ''}`}>Work Tools</Link></li>
                        <li><Link to="/trainingmodule/conducts-training/sample-1" className={`${(segment2 == 'conducts-training') ? 'gray-active' : ''}`}>Conducts</Link></li>
                    </ul>
                </Route>
            </div>
            <Submenu path={pathname} />
        </React.Fragment>
    );
}

export default CommonTraining;