import React from 'react'
const AllDisplayResponsbility = ({ list,activeFirstIndex }) => {
    return (
        <React.Fragment>
            {
               list.split('\n').map(str => <li className='AllData'>{str}</li>) 
            }
        </React.Fragment>
    )
}
export default AllDisplayResponsbility;