import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const LegalMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalfinanceLegalMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/dkglobalfinance/legal/dashboard" className={(segment3 == 'dashboard') ? 'active' : ''}>Dashboard</Link></li>
                        <li><Link to="/dkglobalfinance/legal/registration/2022" className={`${(segment3 == 'registration') ? 'active' : ''}`}>Registration</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'teams') ? '' : ''}`}>Licenses</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'pipelines') ? '' : ''}`}>Residence Cert</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-1') ? '' : ''}`}>Tax Clearance</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-5') ? '' : ''}`}>Director Docs</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-5') ? '' : ''}`}>Contacts</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default LegalMenu;