import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from '../../../../ui/submitButton'
import { Modal, Dropdown } from 'react-bootstrap'
import SelectDropsDown from "../../../../pages/sendouts/utils/searchDropdown";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import { addValue } from "../../../../../slice/costBenifits/advPerformance";

const PerformanceAddNew = ({year,month}) => {
    const dispatch = useDispatch();
    const { isInsert } = useSelector(state => state.performancedata);

    const { cvSourceList } = useSelector(state => state.assignKpiReport)
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [cvSource, setCvSource] = useState('')
    const [serviceProvider, setServiceProvider] = useState('')
    const [budget, setBudget] = useState('')
    const [newApps, setNewApps] = useState('')
    const [sendouts, setSendouts] = useState('')
    const [placements, setPlacements] = useState('')
    const [revenue, setRevenue] = useState('')
    
    const [category, setCategory] = useState('')
    const [type, setType] = useState('Paid')
   
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };
    
    const updateProfile = async (e) => {
        const key = e.target.dataset.key;
        const value = e.target.dataset.value;      
        if (key === 'cvSource') {
            setCvSource(value)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (!year) {
                dispatch(showError({ msg: 'All fileds required' }))
            } else {
                setLoading(true);
                try {                   
                    dispatch(addValue({ year, month,type,resource: cvSource,serviceProvided: serviceProvider, budget, newApps:newApps, sendouts,placements: placements,revenue: revenue }))
                } catch (err) {
                    console.log(err)
                }
            }
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }

    useEffect(() => {      
        if (isInsert) {
            setLoading(false)
            setShow(false)
            dispatch(showSuccess({ msg: 'Record added successfully' }))
            setType("Paid")
            setCvSource("")
            setServiceProvider("")
            setBudget("")
            setNewApps("")
            setSendouts("")
            setPlacements("")
            setRevenue("")
        }        

    }, [isInsert])


    return (
        <>
            {/* <Link to="#" ><i className="fa fa-plus" aria-hidden="true"></i></Link> */}
            <button className="dk-budgetfilter" onClick={handleShow}><i className="fas fa-plus"></i></button>

            <Modal show={show} onHide={handleClose} className="dkg-addnew-paymenttask-modal-21">
                <Modal.Header closeButton className="dkg-addnew-payment-modalheader-21">
                    <Modal.Title>Create New Performance</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-addnew-payment-modalbody-21">
                    <div className='row'>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Type</label>
                                <div className='dkg-payment-task-drop12'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" className="form-control" style={{ backgroundColor: type ==='Paid' ? "#31a886" : "#dc3545" , color: "#fff" }}>
                                            {type}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu >                                           
                                        <Dropdown.Item href="#" onClick={() => setType('Paid')} style={{ backgroundColor: "#31a886", color: "#fff" }}>PAID</Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={() => setType('Unpaid')} style={{ backgroundColor: "#dc3545", color: "#fff" }}>UNPAID</Dropdown.Item>                                            
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Name of Resource</label>
                                <div className='dkg-payment-task-drop12'>
                                    <div className='dkg-payment-task-drop12'>                                      
                                         <SelectDropsDown txt={cvSource} list={cvSourceList} updateProfile={updateProfile} column="cvSource" className={(category == '' ? "errorBorder" : null)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Services Provided</label>
                                <input type="text" className="form-control" value={serviceProvider}  onChange={(e) => setServiceProvider(e.target.value)} />

                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 col-12 pl-0 pr-0'>
                        <div className='form-group'>
                            <label className=''>Budget</label>
                            <input type="text" className="form-control" value={budget}  onChange={(e) => setBudget(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-md-12 col-12 pl-0 pr-0'>
                        <div className='form-group'>
                            <label className=''>New APPS</label>
                            <input type="text" className="form-control" value={newApps}  onChange={(e) => setNewApps(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-md-12 col-12 pl-0 pr-0'>
                        <div className='form-group'>
                            <label className=''>Sendouts</label>
                            <input type="text" className="form-control" value={sendouts}  onChange={(e) => setSendouts(e.target.value)} />

                        </div>
                    </div>
                    <div className='col-md-12 col-12 pl-0 pr-0'>
                        <div className='form-group'>
                            <label className=''>Placements</label>
                            <input type="text" className="form-control" value={placements}  onChange={(e) => setPlacements(e.target.value)} />

                        </div>
                    </div>
                    <div className='col-md-12 col-12 pl-0 pr-0'>
                        <div className='form-group'>
                            <label className=''>Revenue</label>
                            <input type="text" className="form-control" value={revenue}  onChange={(e) => setRevenue(e.target.value)} />

                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='dkg-create-payment-taskbtncon'>
                            <SubmitButton txt="CREATE" loading={loading} success={success} onClick={handleSubmit} className="dkg-create-payment-taskbtn" />                            
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default PerformanceAddNew