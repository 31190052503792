import React from 'react';
import "./consbenefit.css";
import { Tab, Nav } from 'react-bootstrap';
import CostbenefitsTable from './CostbenefitsTable';
import ServicesFilter from '../budget/TypeFilter'


const CostBenefits = () => {
    return (
        <React.Fragment>
            <div className="container-fluid dk-costBenfitMainPageCon">
                <div className="row">
                    <h2 className="dk-adsCreditPTitle w-100 text-center">
                        COST & BENEFITS
                    </h2>
                </div>
            </div>
            <div className="container-fluid dk-costbenefitBG">
                <Tab.Container className="a" id="left-tabs-example" defaultActiveKey="jan">
                    <div className="row">
                        <div className="col-md-2">
                            <div className="dk-costbenefitSrch">
                                <div className="dk-mailshotsSearch mr-2">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search..." />
                                    </div>
                                </div>
                                <button className="dk-cosfilterbtn"><i className="fas fa-plus"></i></button>
                            </div>
                        </div>
                        <div className="col-md-8 dk-costbenhrTabsCon">
                            <div className="dk-costbenTabsCon">
                                <Nav variant="pills dk-costbenTabs" className="flex-row">
                                    <Nav.Item className="dk-costbenTab">
                                        <Nav.Link eventKey="jan">Jan</Nav.Link></Nav.Item>
                                    <Nav.Item className="dk-costbenTab">
                                        <Nav.Link eventKey="feb">Feb</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dk-costbenTab">
                                        <Nav.Link eventKey="mar">Mar</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dk-costbenTab">
                                        <Nav.Link eventKey="apr">Apr</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dk-costbenTab">
                                        <Nav.Link eventKey="may">May</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dk-costbenTab">
                                        <Nav.Link eventKey="jun">Jun</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className="dk-costbenTab">
                                        <Nav.Link eventKey="jul">Jul</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dk-costbenTab">
                                        <Nav.Link eventKey="aug">Aug</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dk-costbenTab">
                                        <Nav.Link eventKey="sep">Sep</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dk-costbenTab">
                                        <Nav.Link eventKey="oct">Oct</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dk-costbenTab">
                                        <Nav.Link eventKey="nov">Nov</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dk-costbenTab">
                                        <Nav.Link className="mNLast" eventKey="dec">Dec</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                        <div className="col-md-2 dk-castbenfrightCon">
                            <div className="dk-costbenefitTopRight d-flex">
                                <button className="mr-2"><i className="fas fa-arrows-alt"></i></button>
                                <div className="dk-reSLoginFilter">
                                    < ServicesFilter resloginstitle="Services" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row dk-costbenfitsTableBG">
                        <div className="col-md-12">
                            <Tab.Content>
                                <Tab.Pane eventKey="jan">
                                    <CostbenefitsTable tableTitle="JAN" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="feb">
                                    <CostbenefitsTable tableTitle="FEB" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="mar">
                                    <CostbenefitsTable tableTitle="MAR" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="apr">
                                    <CostbenefitsTable tableTitle="APR" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="may">
                                    <CostbenefitsTable tableTitle="MAY" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="jun">
                                    <CostbenefitsTable tableTitle="JUN" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="jul">
                                    <CostbenefitsTable tableTitle="JUL" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="aug">
                                    <CostbenefitsTable tableTitle="AUG" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="sep">
                                    <CostbenefitsTable tableTitle="SEP" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="oct">
                                    <CostbenefitsTable tableTitle="OCT" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="nov">
                                    <CostbenefitsTable tableTitle="NOV" />
                                </Tab.Pane>
                                <Tab.Pane eventKey="dec">
                                    <CostbenefitsTable tableTitle="DEC" />
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </React.Fragment>
    )
}
export default CostBenefits;