import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import TextareaAutosize from "@material-ui/core/TextareaAutosize";


const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export default function CustomizedAccordions() {

    const [loginFaqs, setLoginFaqs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch data from an API
        const API_KEY = process.env.REACT_APP_WEB_API_KEY;
        const headers = new Headers({
            'Content-Type': 'application/json',
            'x-api-key': API_KEY,
            'platform': 'web',
            'Authorization': `Bearer ${API_KEY}`
        });
        fetch(process.env.REACT_APP_BASE_URL + `/faqs-login/loginfaq`, { headers })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (Array.isArray(data.result)) {
                    setLoginFaqs(data.result);
                } else {
                    throw new Error('Data is not an array');
                }
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);


    const [expanded, setExpanded] = React.useState("panel0");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const textRef = React.useRef();
    const [value, setValue] = React.useState();
    // const onChnage = (event) => {
    //     setValue(event.target.value);
    // };
    React.useEffect(() => {
        if (textRef && textRef.current) {
            textRef.current.style.height = "0px";
            const taHeight = textRef.current.scrollHeight;
            textRef.current.style.height = taHeight + "px";
        }
    }, [value]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <>
            <div className='dkg-screen-faq-accodionCon'>
                {
                    loginFaqs.map((data, index) => (
                        <Accordion
                            expanded={expanded == `panel` + index}
                            onChange={handleChange(`panel` + index)}
                            className="dkg-screen-faq-accodion"
                        >
                            <AccordionSummary
                                aria-controls={`panel` + index + `d-content`}
                                id={`panel` + index + `d-header`}
                                className="dkg-screen-faq-accodion-suMM"
                            >
                                <Typography>
                                    {data.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{ backgroundColor: "#24475f", color: "#fff" }}
                            >
                                <Typography>

                                    {data.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))

                }


            </div>
        </>
    );
}