import React from 'react';
import './profileskills.scss';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons';

const ProfileSkills = () => {
  return (
    <>
        <div className="dk-skillsMain">
            <div className="title">PROFILE SKILLS TAGS</div>
            <div className="dk-addSkillsMain">
                <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                    <div className="d-flex">
                        <div className="dk-addSkills-tabNav">
                            <div className="title">Category</div>
                            <Nav variant="">
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_1">Category 1</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_2">Category 2</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_3">Category 3</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_4">Category 4</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_5">Category 5</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="dk-addSkills-tabContainer">
                            <Tab.Content>
                                <Tab.Pane eventKey="tabs_1">
                                    <div className="dk-tabsPanel">
                                        <div className="dk-tabsPanel-flex">
                                            <div className="dk-tabsPanle-tagsCols pr-0">
                                                <div className="title">Select Tags</div>
                                                <div className="dk-tabsPanel-tags dk-currentBg">
                                                    <li className="dk-currentTags">tags 1 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 2 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 3 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 4 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 5 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 6 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 7 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 8 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 9 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 10 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                </div>
                                            </div>
                                            <div className="dk-tabsPanle-tagsCols pl-0">
                                                <div className="title">Current Tags</div>
                                                <div className="dk-tabsPanel-tags">
                                                <li className="dk-currentUsedTags">tags 1 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 2 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 3 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 4 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 5 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 6 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 7 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 8 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 9 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 10 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_2">
                                <div className="dk-tabsPanel">
                                        <div className="dk-tabsPanel-flex">
                                            <div className="dk-tabsPanle-tagsCols pr-0">
                                                <div className="title">Select Tags</div>
                                                <div className="dk-tabsPanel-tags dk-currentBg">
                                                    <li className="dk-currentTags">tags 1 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 2 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 3 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 4 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 5 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 6 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 7 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 8 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 9 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 10 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                </div>
                                            </div>
                                            <div className="dk-tabsPanle-tagsCols pl-0">
                                                <div className="title">Current Tags</div>
                                                <div className="dk-tabsPanel-tags">
                                                <li className="dk-currentUsedTags">tags 1 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 2 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 3 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 4 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 5 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 6 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 7 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 8 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 9 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 10 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_3">
                                <div className="dk-tabsPanel">
                                        <div className="dk-tabsPanel-flex">
                                            <div className="dk-tabsPanle-tagsCols pr-0">
                                                <div className="title">Select Tags</div>
                                                <div className="dk-tabsPanel-tags dk-currentBg">
                                                    <li className="dk-currentTags">tags 1 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 2 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 3 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 4 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 5 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 6 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 7 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 8 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 9 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 10 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                </div>
                                            </div>
                                            <div className="dk-tabsPanle-tagsCols pl-0">
                                                <div className="title">Current Tags</div>
                                                <div className="dk-tabsPanel-tags">
                                                <li className="dk-currentUsedTags">tags 1 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 2 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 3 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 4 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 5 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 6 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 7 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 8 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 9 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 10 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_4">
                                <div className="dk-tabsPanel">
                                        <div className="dk-tabsPanel-flex">
                                            <div className="dk-tabsPanle-tagsCols pr-0">
                                                <div className="title">Select Tags</div>
                                                <div className="dk-tabsPanel-tags dk-currentBg">
                                                    <li className="dk-currentTags">tags 1 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 2 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 3 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 4 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 5 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 6 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 7 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 8 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 9 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 10 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                </div>
                                            </div>
                                            <div className="dk-tabsPanle-tagsCols pl-0">
                                                <div className="title">Current Tags</div>
                                                <div className="dk-tabsPanel-tags">
                                                <li className="dk-currentUsedTags">tags 1 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 2 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 3 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 4 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 5 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 6 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 7 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 8 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 9 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 10 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_5">
                                <div className="dk-tabsPanel">
                                        <div className="dk-tabsPanel-flex">
                                            <div className="dk-tabsPanle-tagsCols pr-0">
                                                <div className="title">Select Tags</div>
                                                <div className="dk-tabsPanel-tags dk-currentBg">
                                                    <li className="dk-currentTags">tags 1 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 2 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 3 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 4 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 5 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 6 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 7 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 8 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 9 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                    <li className="dk-currentTags">tags 10 <span><FontAwesomeIcon icon={faPlus} /></span></li>
                                                </div>
                                            </div>
                                            <div className="dk-tabsPanle-tagsCols pl-0">
                                                <div className="title">Current Tags</div>
                                                <div className="dk-tabsPanel-tags">
                                                <li className="dk-currentUsedTags">tags 1 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 2 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 3 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 4 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 5 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 6 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 7 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 8 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 9 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                <li className="dk-currentUsedTags">tags 10 <span><FontAwesomeIcon icon={faClose} /></span></li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </div>
    </>
  )
}

export default ProfileSkills;
