import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import "./responsiveMenu.scss";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const DatabaseSubmenu = (props) => {
    const classes = useStyles();
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    const [toggleMenu, setToggleMenu] = useState(false);
    useEffect(() => {
        setToggleMenu(false)
    }, [pathname])

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setToggleMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);


    return (
        <React.Fragment>
            <div className="dk-subMenuMain res-sub-menu" ref={ref}>
                <IconButton aria-label="delete" className="res-sub-btn" onClick={() => setToggleMenu(!toggleMenu)}>
                    <i className="fas fa-bars"></i>
                </IconButton>
                <Route>
                    <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
                        <li><Link to="/recruitment/database/summary" className={(segment3 == 'summary') ? 'active' : ''}>Summary</Link></li>
                        {/* <li><Link to="/recruitment/database/datatable" className={`${(segment3 == 'datatable') ? 'active' : ''}`}>DataTable</Link></li> */}
                        {/* <li><Link to="/recruitment/database/search-cvs" className={`${(segment3 == 'search-cvs') ? 'active' : ''}`}>Search CV's</Link></li> */}
                        <li><Link to="/recruitment/database/search-cv" className={`${(segment3 == 'search-cv') ? 'active' : ''}`}>Search</Link></li>
                        <li><Link to="/recruitment/database/projects/saved-projects" className={`${(segment3 == 'projects') ? 'active' : ''}`}>Projects</Link></li>
                        <li><Link to="/recruitment/database/mailshots" className={`${(segment3 == 'mailshots') ? 'active' : ''}`}>Mailshots</Link></li>
                        <li><Link to="/recruitment/database/deepak" className={`${(segment3 == 'deepak') ? 'active' : ''}`}>Deepak</Link></li>
                        <li><Link to="/recruitment/database/mariana" className={`${(segment3 == 'mariana') ? 'active' : ''}`}>Mariana</Link></li>
                        <li><Link to="/recruitment/database/filip" className={`${(segment3 == 'filip') ? 'active' : ''}`}>Filip</Link></li>
                        <li><Link to="/recruitment/database/elis" className={`${(segment3 == 'elis') ? 'active' : ''}`}>Elis</Link></li>
                        <li><Link to="/recruitment/database/tehreem" className={`${(segment3 == 'tehreem') ? 'active' : ''}`}>Tehreem</Link></li>
                        <li><Link to="/recruitment/database/new-hire" className={`${(segment3 == 'new-hire') ? 'active' : ''}`}>New Hire</Link></li>
                        <li><Link to="/recruitment/database/dk-global" className={`${(segment3 == 'dk-global') ? 'active' : ''}`}>DK Global</Link></li>

                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default DatabaseSubmenu;
