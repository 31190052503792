import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';


const ResumeSampleDetailTabs = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box>
                <TabContext value={value}>
                    <div className="d-flex">
                        <div className="dk-detailLefttabsMain">
                            <Box>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Sample Tabs 1" value="1" />
                                </TabList>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Sample Tabs 2" value="2" />
                                </TabList>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Sample Tabs 3" value="3" />
                                </TabList>
                            </Box>
                        </div>
                        <div className="dk-tabDetail-flex">
                            <TabPanel value="1">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!
                            </TabPanel>
                            <TabPanel value="2">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!
                            </TabPanel>
                            <TabPanel value="3">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!
                            </TabPanel>
                        </div>
                    </div>
                </TabContext>
            </Box>


        </>
    )
}

export default ResumeSampleDetailTabs;

