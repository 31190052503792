import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const PerformanceDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkglobaladmin/admintraining/managers/performance" className={`${pathname.match('/dkglobaladmin/admintraining/managers/performance') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/managers/criteria" className={`${pathname.match('/dkglobaladmin/admintraining/managers/criteria') ? 'active' : ''}`}>Criteria</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/managers/reviews" className={`${pathname.match('/dkglobaladmin/admintraining/managers/reviews') ? 'active' : ''}`}>Reviews</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/managers/reporting" className={`${pathname.match('/dkglobaladmin/admintraining/managers/reporting') ? 'active' : ''}`}>Reporting</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default PerformanceDetailLeftPanel;

