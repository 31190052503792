import React from 'react';
import moment from "moment-timezone";
const AMPM = ({ todaydate, checkintime, checckouttime }) => {
    
    var inTime = (checkintime.toString()) ;
    var outTime = (checckouttime.toString());

    // var now = moment(`${todaydate} ${inTime}`);
    // var then = moment(`${todaydate} ${outTime}`);

    var hours = parseFloat(outTime) - parseFloat(inTime);

var miins = moment.utc(moment(outTime, "HH:mm").diff(moment(inTime, "HH:mm"))).format("mm")

  //  var hr = moment.utc(moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"))).format("HH");
   // var min = moment.utc(moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"))).format("mm")

    return (
        <React.Fragment>
            <div className="dkg-total-hours"><span className='hours-text'>Hours &nbsp; &nbsp;:  &nbsp;</span>
            {
               hours  ? hours : '00'
            }
            </div>
            <div className="dkg-total-minutes"><span className='minutes-text'>Minutes :  &nbsp;</span>
            {
                (miins !='Invalid date' && miins > 29 )? miins : '00'
            }

            </div>
        </React.Fragment>
    )
}
export default AMPM;