import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "./mailbox.scss";
import {FaEnvelope, FaPaperPlane, FaRegListAlt, FaRegStickyNote, FaRegClone, FaRegWindowClose, FaSearch, FaTrashAlt, FaFlag} from "react-icons/fa";
import SenderImg from "../../../../assets/images/deepak.png";
import CheckboxPopup from "./CheckboxPopup";
import Dropdown from "react-bootstrap/Dropdown";
import ComposeMail from "./modal/ComposeMail";
import EmailContent from "./modal/EmailContent";
import {useDispatch, useSelector} from "react-redux";
import {getInternalMail, internalMailBoxEmailCategory, getInternalMailCount} from "../../../../slice/mailbox/mailboxSlice";
import { candidateProfileDetails } from "../../../../slice/candidates/list/listSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPaperclip} from "@fortawesome/free-solid-svg-icons";

const MyaccMailbox = () => {
  const {userInfo} = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {isInternalEmailCategory, internalEmailCategoryList, isLoading, internalMails, internalMailsCount, singleInternalMail, isUpdateStatus, isMailDelete, isSingleMail} = useSelector((state) => state.mailbox);

  const [emailCategory, setEmailCategory] = useState([]);
  const [internalMail, setInternalMail] = useState([]);
  const [internalMailCount, setInternalMailCount] = useState([]);
  const [type, setType] = useState("Inbox");
  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const [filterCategory, setFilterCategory] = useState("All");
  const [filterCategoryId, setFilterCategoryId] = useState("");
  const [checkedValue, setCheckedValue] = useState(0);
  const [mailDetail, setMailDetail] = useState(0);
  const [dropdownCategoryCount, setDropdownCategoryCount] = useState([]);


  useEffect(() => {
    if(userInfo.role == 'candidate'){
        dispatch(candidateProfileDetails({ id: userInfo.canId }));
    } 
    dispatch(internalMailBoxEmailCategory({can_id: userInfo.id, role: userInfo.role}));
    dispatch(getInternalMailCount({can_id: userInfo.id, role: userInfo.role}))
    countCategoryEmail()
    
  },[])

  const countCategoryEmail = () => {
    const categoryCounts = {};

    internalMails.forEach((email) => {
      const categoryName = email.category.name;

      if (!categoryCounts[categoryName]) {
        categoryCounts[categoryName] = 1;
      } else {
        categoryCounts[categoryName]++;
      }
    });

    setDropdownCategoryCount(categoryCounts)
  }

  useEffect(() => {
    if (isInternalEmailCategory && internalEmailCategoryList.length > 0) {
      setEmailCategory(internalEmailCategoryList);
    }
  }, [isInternalEmailCategory]);

  useEffect(() => {
    getInternalEmails(type);
  }, [internalMailsCount,filterCategoryId]);

  useEffect(() => {
    countCategoryEmail()
  }, [internalMails]);

  useEffect(() => {
    dispatch(getInternalMailCount({can_id: userInfo.id, role: userInfo.role}))
    dispatch(internalMailBoxEmailCategory({can_id: userInfo.id, role: userInfo.role}));
  }, [isUpdateStatus, isMailDelete, isSingleMail, type,categoryId]);

  const getInternalEmails = async () => {
    await dispatch(getInternalMail({can_id: userInfo.id, role: userInfo.role, type: type, cat_id: categoryId, filter_cat:filterCategoryId, search:""}));
  };

  const selectType = (typeName) => {
    setCategory("")
    setCategoryId("");
    setFilterCategory("All")
    setFilterCategoryId("")
    setType(typeName);
  };

  const selectCategory = (cat, cat_id) => {
    setType("categorzied")
    setFilterCategory("All")
    setFilterCategoryId("")
    setCategory(cat)
    setCategoryId(cat_id)
  }

  const selectFilter = (name, id) => {
    setFilterCategory(name)
    setFilterCategoryId(id)
  }

  const convertDate = (date) => {
    const originalDate = new Date(date);
    const options = {year: "2-digit", month: "short", day: "2-digit"};
    const formattedDate = new Intl.DateTimeFormat("en-IN", options).format(originalDate);
    return formattedDate; // Output: "14 Dec 23"
  };

  const convertTime = (date) => {
    const originalDate = new Date(date);
    const options = {hour: "numeric", minute: "numeric", hour12: true};
    const formattedTime = new Intl.DateTimeFormat("en-US", options).format(originalDate);
    return formattedTime;
  };

  const readMail = (mail_id) => {
    internalMails.forEach((el, index) => {
      if(el.id == mail_id){
        el.is_read = 1;
      }
    })
  }

  const toggleCheckbox = (data) => {
    setCheckedValue(document.querySelectorAll('input[name="chkCandidate[]"]:checked'))
    setMailDetail(data)
  }

  const countt = (categoryCount) => {
    return Object.values(categoryCount).reduce((acc, curr) => acc + curr, 0);
  };

  return (
    <>
      <div className="dkg-mailbox-page-mainCon">
        <div className="dk-mailboxMain">
          <div className="row d-flex justify-content-between align-items-start">
            <div className="col-md-3 dkg-mailbox-cols-3 p-0 pl-3">
              <div className="dkg-mailbox-leftPanel">
                <div className="dk-mailbox-composemail">
                  <ComposeMail />
                </div>
                <div className="dkg-mailbox-itemsCols">
                  <div className="dkg-empmailbox-leftPanel">
                    <div className="dkg-mailbox-headerCols">
                      <div className="dkg-title">Mailbox</div>
                      <ul className="dkg-main-mailbox-ul">
                        <li className="dkg-main-mailbox-li" onClick={() => selectType("Inbox")}>
                          <Link to="#" className={`dkg-main-mailbox-link ${type == "Inbox" ? "active" : ""}`}>
                            <span className="dkg-mailbox-list-icon">
                              <FaEnvelope />
                              Inbox
                            </span>
                            <span className="dkg-mailbox-no-badge">{internalMailsCount?.inbox > 0 ? internalMailsCount?.inbox : ''}</span>
                          </Link>
                        </li>
                        <li className="dkg-main-mailbox-li" onClick={() => selectType("Sent")}>
                          <Link to="#" className={`dkg-main-mailbox-link ${type == "Sent" ? "active" : ""}`}>
                            <span className="dkg-mailbox-list-icon">
                              <FaPaperPlane />
                              Sent
                            </span>
                            <span className="dkg-mailbox-no-badge"></span>
                          </Link>
                        </li>
                        <li className="dkg-main-mailbox-li" onClick={() => selectType("Archive")}>
                          <Link to="#" className={`dkg-main-mailbox-link ${type == "Archive" ? "active" : ""}`}>
                            <span className="dkg-mailbox-list-icon">
                              <FaFlag />
                              Archived
                            </span>
                            <span className="dkg-mailbox-no-badge">{internalMailsCount?.archive > 0 ? internalMailsCount?.archive : ''}</span>
                          </Link>
                        </li>
                        <li className="dkg-main-mailbox-li" onClick={() => selectType("Trash")}>
                          <Link to="#" className={`dkg-main-mailbox-link ${type == "Trash" ? "active" : ""}`}>
                            <span className="dkg-mailbox-list-icon">
                              <FaTrashAlt />
                              Deleted
                            </span>
                            <span className="dkg-mailbox-no-badge">{internalMailsCount?.trash > 0 ? internalMailsCount?.trash : ''}</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="dkg-mailbox-itemsCols">
                  <div className="dkg-empmailbox-leftPanel">
                    <div className="dkg-mailbox-headerCols">
                      <div className="dkg-title">Caterories</div>
                      <ul className="dkg-main-mailbox-ul">
                        {emailCategory.map((data, index) => (
                          <li className="dkg-main-mailbox-li" key={index + 1}  onClick={() => selectCategory(data.name, data.id)}>
                            <Link to="#"  className={`dkg-main-mailbox-link ${category == data.name ? "active" : ""}`}>
                              <span className="dkg-mailbox-list-icon">
                                <FaRegListAlt />
                                {data.name}
                              </span>
                              <span className="dkg-mailbox-no-badge"> {data.count > 0 ? data.count : '' }</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9 dkg-mailbox-cols-9 p-0">
              <div className="dkg-mailbox-right-cols">
                <div className="dkg-mailbox-cols-foot mt-0">
                  <div className="dkg-mailbox-header d-flex align-items-center justify-content-between">
                    <div className="dkg-mailbox-leftPanel">
                      <div className="dkg-mailbox-inputCon">
                        <input type="text" placeholder="Search..." className="form-control" />
                        <span className="dkg-srch-icon">
                          <FaSearch />
                        </span>
                      </div>
                    </div>
                    <div className="dkg-mailbox-centrePanel">
                      <div className="dkg-mailbox-title">{type != 'categorzied' ? type : category} - { internalMails.length }</div>
                    </div>
                    <div className="dkg-mailbox-rightPanel">
                      {categoryId == "" ? 
                      <div className="dkg-mailbox-drpdwnCon">
                        <Dropdown className="dkg-mailbox-drpdwn">
                          <Dropdown.Toggle variant="" className="dkg-mailbox-drpdwn-toggle">
                            {filterCategory}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dkg-mailbox-drpdwn-Menu">
                            <Dropdown.Item href="#" 
                            className="dkg-mailbox-drpdwn-item"
                             onClick={() => selectFilter('All', '')}
                             >
                              <div class="d-flex justify-content-between">
                               <span class="dkg-mailbox-drpdwn-item-text">All</span>
                               <span>{countt(dropdownCategoryCount)}</span>
                               </div>
                            </Dropdown.Item>
                          {emailCategory.map((data, index) => (
                            <Dropdown.Item href="#" className="dkg-mailbox-drpdwn-item" onClick={() => selectFilter(data.name, data.id)}>
                              <div className="d-flex justify-content-between">
                              <span>{data.name}</span>
                              <span>
                              {dropdownCategoryCount[data.name]}
                              </span>
                              </div>
                            </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      : ""}
                    </div>
                  </div>
                  <div className="dkg-mailbox-tableCon">
                    <table className="dkg-mailbox-table table table-bordered">
                      <thead>
                        <tr>
                          <th>SNo</th>
                          <th>Sender</th>
                          <th>Category</th>
                          <th>Subject</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Attached</th>
                        </tr>
                      </thead>
                      <tbody>
                        {internalMails.map((data, index) => (
                          <tr>
                            <td>
                              <div className="dkg-mailbox-firstcols">
                              <div className="dk-smsTableCheckbox">
                                <input type="checkbox" className="chkCandidate" name="chkCandidate[]" value={data.id} onChange={(e) => toggleCheckbox(data)} />
                              </div>
                              </div>
                            </td>
                            <td>
                              <div className="dkg-mailbox-imgCon">
                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/jobsAdmin.jpeg" className="dkg-mailbox-img" alt="Sender" />
                              </div>
                            </td>
                            <td className={data.is_read == 0 ? 'font-weight-bold' : ''}>{data.category.name}</td>
                            <td className={data.is_read == 0 ? 'font-weight-bold' : ''}>
                              <EmailContent email={data} candidateId={userInfo.id} role={userInfo.role} type={type} readMail={readMail}/>
                            </td>
                            <td className={data.is_read == 0 ? 'font-weight-bold' : ''}>{convertDate(data.mailbox.date)}</td>
                            <td className={data.is_read == 0 ? 'font-weight-bold' : ''}>{convertTime(data.mailbox.date)}</td>
                            <td className={data.is_read == 0 ? 'font-weight-bold' : ''}>{data.mailbox.attachments.length > 0 ? <FontAwesomeIcon icon={faPaperclip} /> : "-"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {internalMails.length == 0 ? "No Email found" : ""}
                    <CheckboxPopup checkedValue={checkedValue} type={type} email={mailDetail} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyaccMailbox;
