import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Dropdown } from 'react-bootstrap'
import CompanyDropdown from "./SelectDropDown";
import SelectDropDown2 from "./SelectDropDown2";
import SelectDropDown3 from "./SelectDropDown3";
import SubmitButton from "../../../../../ui/submitButton";
import { updateJobSpec, clearState } from "../../../../../../slice/jobSpec/jobsSlice";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../../slice/utils/message/messageSlice";

const EditJobSpec = ({ onHide,allDetails}) => {

    const dispatch = useDispatch();
    const { isLoading, companyList, companyProjtList, companyRolList } =
      useSelector((state) => state.companies);
  
    const {
      jobsCategoryList,
      jobsProjectList,
      jobsRoleTypeList,
      jobsLanguageList,
    } = useSelector((state) => state.common);
    const { message, isSuccess } = useSelector((state) => state.jobSpec);
    //  console.log(jobsIndustryIdList)
    const [isRefresh, setIsRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [jobid, setJobid] = useState(allDetails.job_id);
    const [title, setTitle] = useState(allDetails.name);
    const [categoryList, setCategoryList] = useState(allDetails.category);
    const [companyesList, setCompanyList] = useState(allDetails.company);
    const [projectList, setProjectList] = useState(allDetails.project);
    const [roleTypeList, setRoleTypeList] = useState(allDetails.role);
    const [statusListValue, setStatusListValue] = useState(allDetails.status);
    const [languageList, setLanguageList] = useState(allDetails.lang);
    //console.log(companyList)
      
    useEffect(() => {
      if (isSuccess) {
        setLoading(false);
        setSuccess(true);
        if(message == "Job spec updated successfully"){
          dispatch(showSuccess({ msg: message }));
        }else{
          dispatch(showError({msg: message }));
        }
        dispatch(clearState());
      }
    }, [isSuccess]);
  
    const formRefresh = () => {
      setIsRefresh(true);
    };
  
    const handleButtonClick = async (e) => {
      e.preventDefault();
      if (
        !categoryList ||
        !companyesList ||
        !projectList ||
        !roleTypeList ||
        !languageList ||
        !title ||
        !jobid ||
        !statusListValue
      ) {
        dispatch(showError({ msg: "All fields are required." }));
      } else {
        setSuccess(false);
        setLoading(true);
        dispatch(
          updateJobSpec({
            id: allDetails.id,
            job_id: jobid,
            name: title,
            category: categoryList,
            company: companyesList,
            project: projectList,
            role: roleTypeList,
            lang: languageList,
            status: statusListValue,
          })
        );
        setIsRefresh(false);
      }
    };
  
    const getCatValue = (data) => {
      setCategoryList(data);
    };
    const getCompanyValue = (data) => {
      setCompanyList(data);
    };
    const getProjectValue = (data) => {
      console.log(data);
      setProjectList(data);
    };
    const getRoleTypetValue = (data) => {
      setRoleTypeList(data);
    };
    const getLanguagetValue = (data) => {
      setLanguageList(data);
    };
    const getStatusValue = (data) => {
      setStatusListValue(data);
    };
  
    const statusList = [
      {
        id: 1,
        value: "Active",
      },
      {
        id: 0,
        value: "Inactive",
      },
    ];

    return (
        <>

            <Modal show={true} onHide={onHide} className="dk-addInvoiceModal-main dkg-addnew-paymenttask-modal-21">

                <Modal.Header closeButton className="dkg-addnew-payment-modalheader-21" style={{ background: '#5c737d' }}>
                    <Modal.Title>EDIT JOB SPEC</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-addnew-payment-modalbody-21">
                    <div className='row'>

                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Job Spec ID</label>
                                <input
                                  type="text"
                                  className="form-control text-center"
                                  style={{color:"#000"}}
                                  value={jobid}
                                  onChange={(e) => setJobid(e.target.value)}
                                  defaultValue=""
                                />
                            </div>
                        </div>

                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Status</label>
                                <div className='dkg-payment-task-drop12'>
                                <SelectDropDown3
                                    categoryList={statusList}
                                    catList={getStatusValue}
                                    isRefresh={isRefresh}
                                    txt={allDetails.status}
                          />
                                </div>
                            </div>
                        </div>


                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Job Spec Title</label>
                                <input
                                  type="text"
                                  className="form-control text-center"
                                  style={{color:"#000"}}
                                  value={title}
                                  onChange={(e) => setTitle(e.target.value)}
                                  defaultValue=""
                                />
                            </div>
                        </div>
                    
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Company</label>
                                <div className='dkg-payment-task-drop12'>
                                <CompanyDropdown
                                  categoryList={companyList}
                                  catList={getCompanyValue}
                                  isRefresh={isRefresh}
                                  txt={allDetails.company}
                                />
                                </div>
                            </div>
                        </div>

                        
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Category</label>
                                <div className='dkg-payment-task-drop12'>
                                <SelectDropDown2
                             categoryList={jobsCategoryList}
                             catList={getCatValue}
                             isRefresh={isRefresh}
                             txt={allDetails.category}
                          />
                                </div>
                            </div>
                        </div>

                          
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Project</label>
                                <div className='dkg-payment-task-drop12'>
                                <SelectDropDown2
                                 categoryList={jobsProjectList}
                                 catList={getProjectValue}
                                 isRefresh={isRefresh}
                                 txt={allDetails.project}
                          />
                                </div>
                            </div>
                        </div>

                               
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Role Type</label>
                                <div className='dkg-payment-task-drop12'>
                                <SelectDropDown2
                                  categoryList={jobsRoleTypeList}
                                  catList={getRoleTypetValue}
                                  isRefresh={isRefresh}
                                  txt={allDetails.role}
                          />
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Language</label>
                                <div className='dkg-payment-task-drop12'>
                                <SelectDropDown2
                                     categoryList={jobsLanguageList}
                                     catList={getLanguagetValue}
                                     isRefresh={isRefresh}
                                     txt={allDetails.lang}
                          />
                                </div>
                            </div>
                        </div>

                    
                        <div className='col-md-12 col-12'>
                            <div className='form-group justify-content-center mt-2'>
                                <SubmitButton onClick={handleButtonClick} txt="UPDATE" loading={loading} success={success}  className="dkaddBtn" />
                            </div>
                        </div>
                    
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditJobSpec