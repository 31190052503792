import React, {
    useEffect,
    useState,
} from "react";
import { Link } from 'react-router-dom'
import { Tab, Nav } from "react-bootstrap";
import EditorComponents from './Editor'
import { useDispatch, useSelector } from "react-redux";

import {
    HowToApplyDetails,
    QuestionsList,
} from "../../../../../slice/jobSpec/jobsSlice";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';

const ApplyJob = () => {
    const dispatch = useDispatch();

    const { howToApplyDetails, questionsList } = useSelector((state) => state.jobSpec);

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(HowToApplyDetails({}));
            } catch (err) {
                console.log(err);
            }
        };
        fetchList();
    }, []);

    useEffect(() => {
        const fetchList1 = async () => {
            try {
                await dispatch(QuestionsList({}));
            } catch (err) {
                console.log(err);
            }
        };
        fetchList1();
    }, []);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    let id = questionsList.length > 0 ? questionsList[0].id : "";

    const instructions = howToApplyDetails.instructions != undefined ? howToApplyDetails.instructions : "";
    const agree_tob_s = howToApplyDetails.agree_tob_s != undefined ? howToApplyDetails.agree_tob_s : "";
    const gdpr_consent = howToApplyDetails.gdpr_consent != undefined ? howToApplyDetails.gdpr_consent : "";
    const contact_details = howToApplyDetails.contact_details !== undefined ? howToApplyDetails.contact_details : "";
    const submission_confirmation = howToApplyDetails.submission_confirmation ? howToApplyDetails.submission_confirmation : "";

    const [instructionsText, setInstructionsText] = useState(instructions);
    const [agree_tob_sText, setagree_tob_sText] = useState(agree_tob_s);
    const [gdpr_consentText, setgdpr_consentText] = useState(gdpr_consent);
    const [contact_detailsText, setcontact_detailsText] = useState(contact_details);
    const [submission_confirmationText, setsubmission_confirmationText] = useState(submission_confirmation);

    useEffect(() => {
        if (instructions !== '') {
            setInstructionsText(instructions);
        }
    }, [instructions])

    useEffect(() => {
        if (agree_tob_s !== '') {
            setagree_tob_sText(agree_tob_s);
        }
    }, [agree_tob_s])

    useEffect(() => {
        if (gdpr_consent !== '') {
            setgdpr_consentText(gdpr_consent);
        }
    }, [gdpr_consent])

    useEffect(() => {
        if (contact_details !== '') {
            setcontact_detailsText(contact_details);
        }
    }, [contact_details])

    useEffect(() => {
        if (submission_confirmation !== '') {
            setsubmission_confirmationText(submission_confirmation);
        }
    }, [submission_confirmation])

    return (
        <>
            <div className="dkg-livespec-mainCon">
                <div className="dkg-livespec-main">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="dkg-instructions">
                        <div className="row justify-content-center dkg-livespec-mainRow">
                            <div className="col-md-2 dkg-livespec-main-sideCol">
                                <Nav variant="pills dkg-aboutus-navpills" className="flex-column">
                                    <Nav.Item className="dkg-aboutus-navitem">
                                        <Nav.Link eventKey="dkg-instructions" className="dkg-aboutus-navlink">Instructions</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-aboutus-navitem">
                                        <Nav.Link eventKey="dkg-question" className="dkg-aboutus-navlink">Screening Questions</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-aboutus-navitem">
                                        <Nav.Link eventKey="dkg-contact" className="dkg-aboutus-navlink">Contact Details</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-aboutus-navitem">
                                        <Nav.Link eventKey="dkg-GDPR" className="dkg-aboutus-navlink">GDPR Consent</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-aboutus-navitem">
                                        <Nav.Link eventKey="dkg-TOB" className="dkg-aboutus-navlink">Agree TOB's</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-aboutus-navitem">
                                        <Nav.Link eventKey="dkg-confirmation" className="dkg-aboutus-navlink">Confirmation</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="col-md-10 col-10 dkg-livespec-mainCol">
                                <Tab.Content className='dkg-aboutus-tabcontent'>
                                    <Tab.Pane eventKey="dkg-instructions" className='dkg-aboutus-tabpane'>
                                        <div className="dkg-aboutus-header">
                                            <h1 className='dkg-livespec-title' >Instructions</h1>
                                        </div>
                                        <div style={{ background: "#fff" }}>
                                            <EditorComponents value={instructionsText} name='instructions' />
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="dkg-question" className='dkg-aboutus-tabpane'>
                                        <div className="dkg-aboutus-header">
                                            <h1 className='dkg-livespec-title' >Screening Questions</h1>
                                        </div>
                                        <div style={{ background: "#fff" }}>

                                            {id != "" ? (
                                                <Tab.Container id="left-tabs-example" defaultActiveKey={"dkg-" + id}>
                                                    <div className="row justify-content-center dkg-livespec-mainRow" style={{ paddingBottom: "20px" }}>
                                                        <div className="col-md-2 dkg-livespec-main-sideCol">
                                                            <Nav variant="pills dkg-aboutus-navpills" className="flex-column">
                                                                {questionsList.map((row) => (
                                                                    row.cat_type == 1
                                                                        ? (<Nav.Item key={row.id} className="dkg-aboutus-navitem">
                                                                            <Nav.Link eventKey={"dkg-" + row.id} className="dkg-aboutus-navlink">{row.name}</Nav.Link>
                                                                        </Nav.Item>)
                                                                        : null
                                                                ))}
                                                            </Nav>
                                                        </div>
                                                        <div className="col-10">
                                                            <Tab.Content className='dkg-aboutus-tabcontent'>
                                                                {questionsList.map((row) => (
                                                                    row.cat_type == 1
                                                                        ? (<Tab.Pane key={row.id} eventKey={"dkg-" + row.id} className='dkg-aboutus-tabpane' style={{ width: "97%" }}>
                                                                            <div style={{ background: "#fff" }}>
                                                                                <TableContainer component={Paper}>
                                                                                    <Table aria-label="customized table">
                                                                                        <TableHead>
                                                                                            <StyledTableRow>
                                                                                                <StyledTableCell>S No.</StyledTableCell>
                                                                                                <StyledTableCell>Question Name</StyledTableCell>
                                                                                                <StyledTableCell>Status</StyledTableCell>
                                                                                            </StyledTableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {row.questions.map((row1, index) => (
                                                                                                <StyledTableRow key={row1.id}>
                                                                                                    <StyledTableCell component="th" scope="row">
                                                                                                        {index + 1}
                                                                                                    </StyledTableCell>
                                                                                                    <StyledTableCell >
                                                                                                        {row1.question}
                                                                                                    </StyledTableCell>
                                                                                                    <StyledTableCell>
                                                                                                        <Chip label={row1.status} size="small" color="success" />
                                                                                                    </StyledTableCell>
                                                                                                </StyledTableRow>
                                                                                            ))}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </TableContainer>
                                                                            </div>
                                                                        </Tab.Pane>)
                                                                        : null
                                                                ))}
                                                            </Tab.Content>
                                                        </div>
                                                    </div>
                                                </Tab.Container>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="dkg-contact" className='dkg-aboutus-tabpane'>
                                        <div className="dkg-aboutus-header">
                                            <h1 className='dkg-livespec-title' >Contact Details</h1>
                                        </div>
                                        <div style={{ background: "#fff" }}>
                                            {id != "" ? (
                                                <Tab.Container id="left-tabs-example" defaultActiveKey="information">
                                                    <div className="row justify-content-center dkg-livespec-mainRow" style={{ paddingBottom: "20px" }}>
                                                        <div className="col-md-2 dkg-livespec-main-sideCol">
                                                            <Nav variant="pills dkg-aboutus-navpills" className="flex-column">
                                                                <Nav.Item className="dkg-aboutus-navitem">
                                                                    <Nav.Link eventKey="information" className="dkg-aboutus-navlink">Instructions</Nav.Link>
                                                                </Nav.Item>
                                                                {questionsList.map((row) => (
                                                                    row.cat_type == 2
                                                                        ? (<Nav.Item key={row.id} className="dkg-aboutus-navitem">
                                                                            <Nav.Link eventKey={"dkg-" + row.id} className="dkg-aboutus-navlink">{row.name}</Nav.Link>
                                                                        </Nav.Item>)
                                                                        : null
                                                                ))}
                                                            </Nav>
                                                        </div>
                                                        <div className="col-10">
                                                            <Tab.Content className='dkg-aboutus-tabcontent'>
                                                                <Tab.Pane eventKey="information" className='dkg-aboutus-tabpane' style={{ width: "97%" }}>
                                                                    <div style={{ background: "#fff" }}>
                                                                        <TableContainer component={Paper}>
                                                                            <div style={{ background: "#fff" }}>
                                                                                <EditorComponents value={contact_detailsText} name='contact_details' />
                                                                            </div>
                                                                        </TableContainer>
                                                                    </div>
                                                                </Tab.Pane>
                                                                {questionsList.map((row) => (
                                                                    row.cat_type == 2
                                                                        ? (<Tab.Pane key={row.id} eventKey={"dkg-" + row.id} className='dkg-aboutus-tabpane' style={{ width: "97%" }}>
                                                                            <div style={{ background: "#fff" }}>
                                                                                <TableContainer component={Paper}>
                                                                                    <Table aria-label="customized table">
                                                                                        <TableHead>
                                                                                            <StyledTableRow>
                                                                                                <StyledTableCell>S No.</StyledTableCell>
                                                                                                <StyledTableCell>Question Name</StyledTableCell>
                                                                                                <StyledTableCell>Status</StyledTableCell>
                                                                                            </StyledTableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {row.questions.map((row1, index) => (
                                                                                                <StyledTableRow key={row1.id}>
                                                                                                    <StyledTableCell component="th" scope="row">
                                                                                                        {index + 1}
                                                                                                    </StyledTableCell>
                                                                                                    <StyledTableCell >
                                                                                                        {row1.question}
                                                                                                    </StyledTableCell>
                                                                                                    <StyledTableCell>
                                                                                                        <Chip label={row1.status} size="small" color="success" />
                                                                                                    </StyledTableCell>
                                                                                                </StyledTableRow>
                                                                                            ))}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </TableContainer>
                                                                            </div>
                                                                        </Tab.Pane>)
                                                                        : null
                                                                ))}

                                                            </Tab.Content>
                                                        </div>
                                                    </div>
                                                </Tab.Container>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="dkg-GDPR" className='dkg-aboutus-tabpane'>
                                        <div className="dkg-aboutus-header">
                                            <h1 className='dkg-livespec-title' >GDPR Consent</h1>
                                        </div>
                                        <div style={{ background: "#fff" }}>
                                            <EditorComponents value={gdpr_consentText} name='gdpr_consent' />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="dkg-TOB" className='dkg-aboutus-tabpane'>
                                        <div className="dkg-aboutus-header">
                                            <h1 className='dkg-livespec-title' >Agree TOB's</h1>
                                        </div>
                                        <div style={{ background: "#fff" }}>
                                            <EditorComponents value={agree_tob_sText} name='agree_tob_s' />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="dkg-confirmation" className='dkg-aboutus-tabpane'>
                                        <div className="dkg-aboutus-header">
                                            <h1 className='dkg-livespec-title' >Confirmation</h1>
                                        </div>
                                        <div style={{ background: "#fff" }}>
                                            <EditorComponents value={submission_confirmationText} name='submission_confirmation' />
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
            </div>
        </>
    )
}

export default ApplyJob