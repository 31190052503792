import React from 'react';
import { Modal } from 'react-bootstrap';
import "./addnew.css";


const AddNew = ({ onHide }) => {
    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-adsCreAddNewPop"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                {/* <Modal.Header closeButton className="dk-detViewheader">
                <Modal.Title className="dk-detViewTitle" id="example-custom-modal-styling-title">
                </Modal.Title>
              </Modal.Header> */}
                <Modal.Body>
                    <div className="dk-adsCreditInCon">
                        <div className="firstInput">
                            <label>Current</label>
                            <input type="text" className="form-control" value="15" readOnly />
                        </div>
                        <div className="SecondInput">
                            <label>New</label>
                            <input type="text" className="form-control" value="" />
                        </div>
                    </div>
                    <div className="dk-adsCreditBtnCon">
                        <button type="button" className="btn  dk-calcsaveBtn">SAVE</button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default AddNew;
