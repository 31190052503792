import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import "./payment-faqs.scss";
import FaqAccordion from "./Accordion";
import { useDispatch, useSelector } from "react-redux";
// import {
//   showSuccess,
//   showError,
//   clearMessage,
// } from "../../../../../../slice/utils/message/messageSlice";

import {
  getCompanyList,
  getCategory,
  getQueAns,
  getQuesAnsByCompanyId,
  //getdefaultCategory,
  getOnClickQuesAns,
  //getdefaultQuesAns,
  //clearState,
} from "../../../../../../slice/faqs/newFaqSlice";

import $ from "jquery";

const Overview = () => {
  const dispatch = useDispatch();
  const {
    isError,
    isSuccess,
    isLoading,
    companiesList,
    categoryList,
    questionAnsList,
    isTabshow,
  } = useSelector((state) => state.faqData);

  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [quesAnswer, setQuesAnswer] = useState([]);
  const [isCompanyName, isSetCompanyName] = useState("");
  const [defaultActiveCompany, setDefaultActiveCompany] = useState(0);
  const [defaultCategoryActive, setDefaultCategoryActive] = useState(0);

  useEffect(() => {
    if (!isLoading && companiesList && companiesList.length > 0) {
      setData(companiesList);
      isSetCompanyName(companiesList[0].name);
      setCategoryData(categoryList);
      setQuesAnswer(questionAnsList);
    } else {
      setData([]);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess) {
      setQuesAnswer(questionAnsList);
    }
  }, [isSuccess]);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       await dispatch(getCompanyList({}));
  //       await dispatch(getdefaultCategory({}));
  //       await dispatch(getdefaultQuesAns({}));
  //     } catch (err) {
  //       dispatch(showError({ msg: "Somthings went wrong" }));
  //     }
  //   }
  //   fetchData();
  // }, [dispatch]);

  const changeTabName = async (index, compid, comName) => {
    setDefaultCategoryActive(0);
    setQuesAnswer([]);
    setDefaultActiveCompany(index);
    await dispatch(getCategory({ id: compid }));
    await dispatch(getOnClickQuesAns({ compid }));
    isSetCompanyName("");
    isSetCompanyName(comName);
  };
  const changeCategoryTab = async (index, catid) => {
    setDefaultCategoryActive(catid);
    $(".removeActive").removeClass("active");
    $(".addActive" + catid).addClass("active");

    await dispatch(getQueAns({ id: catid }));
  };

  return (
    <>
      <div className="dkg-payment-faq-pageCon">
        <div className="row dkg-candprescreen-mainRow">
          <div className="col-md-2 pl-0 dkg-cand-prescreen-faq-tabCon">
            <Nav
              variant="pills"
              className="flex-column dkg-cand-prescreen-faq-tab-navpills"
            >
              {data &&
                data
                  .filter((item) => item.status === "Active")
                  .map((comp, index) => {
                    return (
                      <Nav.Item
                        key={index}
                        className="dkg-cand-prescreen-faq-tab-nav-item"
                      >
                        <Link
                          to="#"
                          onClick={() =>
                            changeTabName(index, comp.id, comp.name)
                          }
                          className={
                            index === defaultActiveCompany
                              ? `dkg-cand-prescreen-faq-tab-navlink active`
                              : `dkg-cand-prescreen-faq-tab-navlink`
                          }
                        >
                          {comp.name}
                        </Link>
                      </Nav.Item>
                    );
                  })}
            </Nav>
          </div>
          <div className="col-md-10 dkg-cand-prescreen-centre-tabCon">
            <Tab.Container defaultActiveKey="question-pt-overview">
              <div className="row m-0 dkg-payment-faq-tab-mainCon">
                <div className="dkg-faq-catgory-activeTitle">
                  {isCompanyName}
                </div>
                <div className="col-md-2 pl-0 dkg-payment-faq-tabCon dkg-faq-sideMenuCon-234">
                  <Nav
                    variant="pills"
                    className="flex-column dkg-payment-faq-tab-navpills"
                  >
                    {categoryData &&
                      categoryData
                        .filter((item) => item.status === "Active")
                        .map((cat, index) => {
                          return (
                            <Nav.Item className="dkg-payment-faq-tab-nav-item">
                              {
                                <Link
                                  to="#"
                                  onClick={() =>
                                    changeCategoryTab(index, cat.id)
                                  }
                                  className={
                                    index === defaultCategoryActive
                                      ? `dkg-payment-faq-tab-navlink removeActive active`
                                      : `dkg-payment-faq-tab-navlink removeActive addActive` +
                                      cat.id
                                  }
                                >
                                  {cat.name}
                                </Link>
                              }
                            </Nav.Item>
                          );
                        })}
                  </Nav>
                </div>
                <div className="col-md-10 dkg-payment-faq-tabCon-10 dkg-faq-rightMenuCon-234">
                  <Tab.Content className="dkg-payment-faq-tabcontent ddd">
                    <FaqAccordion faqdata={quesAnswer} />
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
