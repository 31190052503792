import React from "react";
import { Link } from "react-router-dom";
// import './myacc-submenu.scss';

const ServiceSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    return (
        <React.Fragment>
            <ul className="dkg-myacc-submenu-ul">
                <li>
                    <Link to="/js-account/dkg-services/summary" className={`${(segment3 === 'summary') ? 'grey-active' : ''}`}>Overview</Link>
                </li>
                <li>
                    <Link to="/js-account/dkg-services/create-cv" className={`${(segment3 === 'create-cv') ? 'blue-active' : ''}`}>Resume</Link>
                </li>
                <li>
                    <Link to="/js-account/dkg-services/search-job" className={`${(segment3 === 'search-job') ? 'blue-active' : ''}`}>Job Search</Link>
                </li>
                <li className="pbp">
                    <Link to="/js-account/dkg-services/pass-interview" className={`${(segment3 === 'pass-interview') ? 'blue-active' : ''}`}>Interviews</Link> 
                </li>
                <li className="psp">
                    <Link to="/js-account/dkg-services/assessment" className={`${(segment3 === 'assessment') ? 'blue-active' : ''}`}>Assessment</Link>
                </li>
                <li className="psp">
                    <Link to="/js-account/dkg-services/communication" className={`${(segment3 === 'communication') ? 'blue-active' : ''}`}>Communication</Link>
                </li>
                <li className="psp">
                    <Link to="/js-account/dkg-services/using-internet" className={`${(segment3 === 'using-internet') ? 'blue-active' : ''}`}>Using Internet</Link>
                </li>
                <li className="psp">
                    <Link to="/js-account/dkg-services/work-abroad" className={`${(segment3 === 'work-abroad') ? 'blue-active' : ''}`}>Work Abroad</Link>
                </li>
                <li className="psp">
                    <Link to="/js-account/dkg-services/study-abroad" className={`${(segment3 === 'study-abroad') ? 'blue-active' : ''}`}>Study Abroad</Link>
                </li>
                <li className="psp">
                    <Link to="/js-account/dkg-services/plan-carrer" className={`${(segment3 === 'plan-carrer') ? 'blue-active' : ''}`}>Plan Career</Link>
                </li>
            </ul>
        </React.Fragment>
    );
}

export default ServiceSubmenu;
