import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/dkglobaladmin/ManagementMenu';

const AdminManagementLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header mainheaderLink="Dashboard -" moduleLink="/dashboard/plateform" linkcolor="#3c4c62" mainheaderTitle=" MANAGEMENT" textcolor="#a43b63" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default AdminManagementLayout;
