import React from 'react';
import { Modal } from 'react-bootstrap';
import "./viewpopup.scss";
import Candidate from '../../../../../../../../assets/images/deepak.png'

const ViewProgress = ({ onHide }) => {

    return (
        <React.Fragment> 
            <Modal show={true} onHide={onHide} className="dkg-application-view-modal">
                 <Modal.Header closeButton className="dkg-app-progress-modal-header">
                    <Modal.Title>Candidate Progress</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-app-progress-modal-body">
                    <div className="dkg-cand-progress-app-mainCon">
                        <ul className="dkg-progress-app-listCon">
                            <li className="dkg-progress-app-list current">
                                <div className="dkg-progress-app-card-Con">
                                    <div className="dkg-app-progress-imgcols">
                                         <img src={Candidate} className='dkg-app-progress-img' alt='Candidate' />
                                    </div>
                                    <div className="dkg-app-porgess-statuscols">
                                        <div className="dkg-app-status-firstCols">
                                            <div className="dkg-status-title">Open</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                        <div className="dkg-app-status-seocndCols">
                                            <div className="dkg-status-title">Interviews</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                        <div className="dkg-app-status-thirdCols">
                                           <div className="dkg-status-title">Filled the Project</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                    </div>
                                    <div className="dkg-app-progress-date-cols">
                                        <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                    </div>
                                </div>
                            </li>
                            <li className="dkg-progress-app-list current">
                                <div className="dkg-progress-app-card-Con">
                                    <div className="dkg-app-progress-imgcols">
                                         <img src={Candidate} className='dkg-app-progress-img' alt='Candidate' />
                                    </div>
                                    <div className="dkg-app-porgess-statuscols">
                                        <div className="dkg-app-status-firstCols">
                                            <div className="dkg-status-title">Open</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                        <div className="dkg-app-status-seocndCols">
                                            <div className="dkg-status-title">Interviews</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                        <div className="dkg-app-status-thirdCols">
                                           <div className="dkg-status-title">Filled the Project</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                    </div>
                                    <div className="dkg-app-progress-date-cols">
                                        <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                    </div>
                                </div>
                            </li>
                            <li className="dkg-progress-app-list current">
                                <div className="dkg-progress-app-card-Con">
                                    <div className="dkg-app-progress-imgcols">
                                         <img src={Candidate} className='dkg-app-progress-img' alt='Candidate' />
                                    </div>
                                    <div className="dkg-app-porgess-statuscols">
                                        <div className="dkg-app-status-firstCols">
                                            <div className="dkg-status-title">Open</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                        <div className="dkg-app-status-seocndCols">
                                            <div className="dkg-status-title">Interviews</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                        <div className="dkg-app-status-thirdCols">
                                           <div className="dkg-status-title">Filled the Project</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                    </div>
                                    <div className="dkg-app-progress-date-cols">
                                        <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                    </div>
                                </div>
                            </li>
                            <li className="dkg-progress-app-list current">
                                <div className="dkg-progress-app-card-Con">
                                    <div className="dkg-app-progress-imgcols">
                                         <img src={Candidate} className='dkg-app-progress-img' alt='Candidate' />
                                    </div>
                                    <div className="dkg-app-porgess-statuscols">
                                        <div className="dkg-app-status-firstCols">
                                            <div className="dkg-status-title">Open</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                        <div className="dkg-app-status-seocndCols">
                                            <div className="dkg-status-title">Interviews</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                        <div className="dkg-app-status-thirdCols">
                                           <div className="dkg-status-title">Filled the Project</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                    </div>
                                    <div className="dkg-app-progress-date-cols">
                                        <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                    </div>
                                </div>
                            </li>
                            <li className="dkg-progress-app-list current">
                                <div className="dkg-progress-app-card-Con">
                                    <div className="dkg-app-progress-imgcols">
                                         <img src={Candidate} className='dkg-app-progress-img' alt='Candidate' />
                                    </div>
                                    <div className="dkg-app-porgess-statuscols">
                                        <div className="dkg-app-status-firstCols">
                                            <div className="dkg-status-title">Open</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                        <div className="dkg-app-status-seocndCols">
                                            <div className="dkg-status-title">Interviews</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                        <div className="dkg-app-status-thirdCols">
                                           <div className="dkg-status-title">Filled the Project</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                    </div>
                                    <div className="dkg-app-progress-date-cols">
                                        <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                    </div>
                                </div>
                            </li>
                            <li className="dkg-progress-app-list current">
                                <div className="dkg-progress-app-card-Con">
                                    <div className="dkg-app-progress-imgcols">
                                         <img src={Candidate} className='dkg-app-progress-img' alt='Candidate' />
                                    </div>
                                    <div className="dkg-app-porgess-statuscols">
                                        <div className="dkg-app-status-firstCols">
                                            <div className="dkg-status-title">Open</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                        <div className="dkg-app-status-seocndCols">
                                            <div className="dkg-status-title">Interviews</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                        <div className="dkg-app-status-thirdCols">
                                           <div className="dkg-status-title">Filled the Project</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                    </div>
                                    <div className="dkg-app-progress-date-cols">
                                        <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                    </div>
                                </div>
                            </li>
                            <li className="dkg-progress-app-list current">
                                <div className="dkg-progress-app-card-Con">
                                    <div className="dkg-app-progress-imgcols">
                                         <img src={Candidate} className='dkg-app-progress-img' alt='Candidate' />
                                    </div>
                                    <div className="dkg-app-porgess-statuscols">
                                        <div className="dkg-app-status-firstCols">
                                            <div className="dkg-status-title">Open</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                        <div className="dkg-app-status-seocndCols">
                                            <div className="dkg-status-title">Interviews</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                            <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                        <div className="dkg-app-status-thirdCols">
                                           <div className="dkg-status-title">Filled the Project</div>
                                           <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                           <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                        </div>
                                    </div>
                                    <div className="dkg-app-progress-date-cols">
                                        <div className="dkg-progess-app-date">06 Feb 2024, 10:42</div>
                                    </div>
                                </div>
                            </li>
                        </ul> 
                    </div>       
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default ViewProgress;
