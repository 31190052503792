import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import RecOne from "../../../../../../assets/images/rec-1.png";
import RecTwo from "../../../../../../assets/images/rec-2.png";
import RecThree from "../../../../../../assets/images/rec-3.png";
import RecFour from "../../../../../../assets/images/rec-4.png";
import RecFive from "../../../../../../assets/images/rec-5.png";
import RecSix from "../../../../../../assets/images/rec-6.png";
import './rechistory.scss';

const RecHistoryTraining = () => {
    return (
        <>
            <div className="dk-historyMain">
                <div className="container">
                    {/* <h3 className="title">EVOLUTION OF RECRUITMENT CONSULTANT</h3> */}

                    <div className="dk-history-container">
                        <div className="title"><span><FaArrowLeft /> Evolution Of Recruitment Consultant <FaArrowRight /></span></div>
                        <div className="dk-historyBoxes">
                            <div className="dk-history-cols">
                                <div className="dk-history-img">
                                    <img src={RecOne} alt="" />
                                </div>
                                <div className="sbtitle">
                                    1970'
                                    <small>s</small>
                                </div>
                            </div>
                            <div className="dk-history-cols">
                                <div className="dk-history-img">
                                    <img src={RecTwo} alt="" />
                                </div>
                                <div className="sbtitle">
                                    1980'
                                    <small>s</small>
                                </div>
                            </div>
                            <div className="dk-history-cols">
                                <div className="dk-history-img">
                                    <img src={RecThree} alt="" />
                                </div>
                                <div className="sbtitle">
                                    1990'
                                    <small>s</small>
                                </div>
                            </div>
                            <div className="dk-history-cols">
                                <div className="dk-history-img">
                                    <img src={RecFour} alt="" />
                                </div>
                                <div className="sbtitle">
                                    2000'
                                    <small>s</small>
                                </div>
                            </div>
                            <div className="dk-history-cols">
                                <div className="dk-history-img">
                                    <img src={RecFive} alt="" />
                                </div>
                                <div className="sbtitle">
                                    2010'
                                    <small>s</small>
                                </div>
                            </div>
                            <div className="dk-history-cols">
                                <div className="dk-history-img">
                                    <img src={RecSix} alt="" />
                                </div>
                                <div className="sbtitle">
                                    2020'
                                    <small>s</small>
                                </div>
                            </div>
                        </div>
                        <p className="text-center mt-4 pt-2">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolores neque nobis sapiente maiores commodi beatae, quam assumenda omnis suscipit.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecHistoryTraining;
