import React from "react";
import { Route, Link } from "react-router-dom";

const CertificateSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/my-files/certificates/certificate1" className={`${(segment3 == 'certificate1') ? 'active' : ''}`}>Certificate 1</Link></li>
                        <li><Link to="#/my-files/certificates/certificate2" className={`${(segment3 == 'certificate2') ? 'active' : ''}`}>Certificate 2</Link></li>
                        <li><Link to="#/my-files/certificates/certificate3" className={`${(segment3 == 'certificate3') ? 'active' : ''}`}>Certificate 3</Link></li>
                        <li><Link to="#/my-files/certificates/certificate4" className={`${(segment3 == 'certificate4') ? 'active' : ''}`}>Certificate 4</Link></li>
                        <li><Link to="#/my-files/certificates/certificate5" className={`${(segment3 == 'certificate5') ? 'active' : ''}`}>Certificate 5</Link></li>
                        <li><Link to="#/my-files/certificates/certificate6" className={`${(segment3 == 'certificate6') ? 'active' : ''}`}>Certificate 6</Link></li>
                        <li><Link to="#/my-files/certificates/certificate7" className={`${(segment3 == 'certificate7') ? 'active' : ''}`}>Certificate 7</Link></li>
                        <li><Link to="#/my-files/certificates/certificate8" className={`${(segment3 == 'certificate8') ? 'active' : ''}`}>Certificate 8</Link></li>
                        <li><Link to="#/my-files/certificates/certificate9" className={`${(segment3 == 'certificate9') ? 'active' : ''}`}>Certificate 9</Link></li>
                        <li><Link to="#/my-files/certificates/certificate10" className={`${(segment3 == 'certificate10') ? 'active' : ''}`}>Certificate 10</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default CertificateSubmenu;
