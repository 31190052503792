import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/dkglobalfinance/LegalDebtMenu';

const LegalDebtLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Management -" dashboardLink="/dashboard/manage-ment" mainheaderLink="Legal" moduleLink="/legalmodule/legal-module" linkcolor="#3c4c62" mainheaderTitle=" - Debt" textcolor="#535255" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default LegalDebtLayout;