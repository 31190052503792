import React from 'react'
import RecuitersLeftPanel from '../leftpanel/RecuitersLeftPanel'

const RecuitersClients = () => {
    return (
        <>
            <div className="dk-tasks-containerMain dk-tasksModuleMain-container dkg-recuiterskpiMainCon container-fluid">
                <div className='row'>
                    <div className='col-md-1 pl-0'>
                        <RecuitersLeftPanel />
                    </div>
                </div>

                <div className='col-md-11 pr-0'>
                    <div className="dk-sendouts-rightPanel">
                        <div className='text-center text-white mt-5'> Recuiters Clients</div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default RecuitersClients