import React from 'react';
import { Link, Route } from "react-router-dom";
import TItleIcon from "../../../../assets/images/company.png";
import './comprofiling.scss';
import ComProfilingLeftPanel from './ComProfilingLeftPanel';
import ReorderModal from './modal/ReorderModal';





const WhAthens = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-backBtnMain dk-payInvoicesBackBtn">
                <h3 className="dk-detailTitle">
                    <img src={TItleIcon} className="img-fluid" alt="" />
                    Profiling
                </h3>
            </div>
            <div className="dk-company-profileMain dk-paymentJbDetailMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            {/* <ComProfilingLeftPanel /> */}
                            <ul>
                                <Route>
                                    <li><Link to="/recruitment/company/profiling/tp-greece">TP Greece</Link></li>
                                    <li><Link to="/recruitment/company/profiling/wh-athens" className="profile-greybg">WH Athens</Link></li>
                                </Route>
                            </ul>
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                <div className="dk-PayMonthCols d-none">
                                    <div className="d-flex">
                                        <Link to="#" className="active">2022</Link>
                                        <Link to="#">2021</Link>
                                        <Link to="#">2020</Link>
                                        <Link to="#">2019</Link>
                                    </div>
                                </div>
                                <div className="dk-trainingInformation-cols">
                                    <div className="dk-payDetailRightPanel">
                                        <div className='dk-comp-tableHeaderCon'>
                                            <div className='dk-comp-tableHeader-left'>
                                                <div className="dk-payTableSearch">
                                                    <input type="text" placeholder="Search..." className="form-control" name="" id="" />
                                                </div>
                                            </div>
                                            <div className='dk-comp-tableHeader-center'>
                                                <div className='dk-comp-profiling-title'>
                                                    <h3 className='profile-title'>TP Greece</h3>
                                                </div>
                                            </div>
                                            <div className='dk-comp-tableHeader-right'>
                                                <ReorderModal />
                                                <Link to="#">
                                                    Add New <i className='fa fa-plus ml-1'></i>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="dk-rightPanelTable">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Sn.</th>
                                                        <th>Category</th>
                                                        <th>Flexibility</th>
                                                        <th>Candidates Preferences </th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Job Board</td>
                                                        <td>Recruitment</td>
                                                        <td>Preference 1</td>
                                                        <td>
                                                            <div className="dk-payAction">
                                                                <Link to="#" className="dk-edit"><i className="far fa-edit"></i></Link>
                                                                <Link to="#" className="dk-delete"><i className="far fa-trash-alt"></i></Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default WhAthens;

