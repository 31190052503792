import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
import './apply-job.scss'
import { FaShareSquare } from "react-icons/fa";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from "react-redux";
import {
  HowToApplyDetails,
  updateFileMail,
  QuestionsList,
  addJobApplicationForm
} from "../../../../../../slice/jobSpec/jobsSlice";
import { sendInternalMail } from "../../../../../../slice/mailbox/mailboxSlice";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
const steps = ['Instructions', 'Screening Questions', 'Contact Details', 'GDPR Consent', "Agree TOB's", 'Confirmation'];

const emailBody = `<p style="color: rgb(51, 51, 51); font-family: Verdana, sans-serif;font-size:14px">Dear Applicant, </p>
<p style="color: rgb(51, 51, 51); font-family: Verdana, sans-serif;font-size:14px">Thank you for your job application.
</p>
<p style="color: rgb(51, 51, 51); font-family: Verdana, sans-serif;font-size:14px;margin:0;margin-bottom:5px">Please
    find attached PDF
    file for
    your reference.</p>
<p style="color: rgb(51, 51, 51); font-family: Verdana, sans-serif;font-size:14px;margin:0;margin-bottom:5px">We will
    review your
    job
    application
    within 24 hours and get back to you as soon as possible. </p>
<p style="color: rgb(51, 51, 51); font-family: Verdana, sans-serif;font-size:14px;margin:0;">If you are in hurry, please
    feel free
    to login into
    your account and start live chat with us.
    We would be happy to assist you immediately. </p>
<p style="color: rgb(51, 51, 51); font-family: Verdana, sans-serif;font-size:14px;margin-bottom:30px;margin-top:20px;">
    Wish you all the
    best in your job
    search! </p>
<p style="color: rgb(51, 51, 51); font-family: Verdana, sans-serif;font-size:14px;margin-bottom:5px">Regards, <br>
    DK Global Recruitment </p>
<p style="margin:0;margin-bottom:30px;"><img src="https://www.dkglobalrecruitment.com/sites/default/files/logo_0.png"
        width="120" /></p>`;

const ApplyjobPoup = ({ jobId, show, how, onHide, stepStatus }) => {
  const dispatch = useDispatch();
  const { howToApplyDetails, questionsList, doc, isSuccess } = useSelector((state) => state.jobSpec);

  const [files, setFiles] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setFiles(doc);
    }
  }, [isSuccess]);

  useEffect(() => {
    setActiveStep(0)
  }, [onHide]);

  const [activeStep, setActiveStep] = useState(stepStatus);

  useEffect(() => {
    const fetchList = async () => {
      try {
        await dispatch(HowToApplyDetails({}));
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  }, []);

  useEffect(() => {
    const fetchList1 = async () => {
      try {
        await dispatch(QuestionsList({}));
      } catch (err) {
        console.log(err);
      }
    };
    fetchList1();
  }, []);

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason == 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [state, setState] = useState({
    confirm1: false,
    confirm2: false,
    confirm3: false,
    confirm4: false,
    confirm5: false,
    confirm6: false,
    confirm7: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const { confirm1, confirm2, confirm3, confirm4, confirm5, confirm6, confirm7 } = state;

  const [screeningQuestions, setScreeningQuestions] = useState({});
  const screeningQuestionshandleForm = e => {
    setScreeningQuestions({ ...screeningQuestions, [e.target.id]: e.target.value });
  };

  const [contactDetailsQuestions, setContactDetailsQuestions] = useState({});
  const contactDetailsQuestionshandleForm = e => {
    setContactDetailsQuestions({ ...contactDetailsQuestions, [e.target.id]: e.target.value });
  };

  const handleNext = () => {
    if (activeStep == 0) {
      if (confirm1 == true && confirm2 == true && confirm3 == true) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setOpen(true);
      }
    }
    if (activeStep == 1) {
      if (Object.keys(screeningQuestions).length > 0) {
        let checkValue = 0;
        Object.keys(screeningQuestions).map(function (key) {
          if (screeningQuestions[key] == "" || screeningQuestions[key] == " ") {
            checkValue = 1
          }
        });
        if (checkValue == 0) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          setOpen(true);
        }
      } else {
        setOpen(true);
      }
    }
    if (activeStep == 2) {
      if (Object.keys(contactDetailsQuestions).length == 6 && confirm7 == true) {
        let checkValue1 = 0;
        Object.keys(contactDetailsQuestions).map(function (key) {
          if (contactDetailsQuestions[key] == "" || contactDetailsQuestions[key] == " ") {
            checkValue1 = 1
          }
        });
        if (checkValue1 == 0) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          setOpen(true);
        }
      } else {
        setOpen(true);
      }
    }
    if (activeStep == 3) {
      if (confirm4 == true && confirm5 == true) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setOpen(true);
      }
    }
  };

  const handleNext1 = async () => {
    if (activeStep == 4) {
      if (confirm6 == true) {
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
        let applicationData = await dispatch(
          addJobApplicationForm({
            values: jobId,
            screeningQuestions: screeningQuestions,
            contactDetailsQuestions: contactDetailsQuestions,
          })
        );

        const formData = new FormData();
        formData.append("from", "recruitment@dkgrecruitment.com");
        formData.append("to", jobId.email);
        formData.append("subject", "Your Job Application to - DK Global Recruitment");
        formData.append("body", emailBody);
        formData.append("category_id", 6);
        formData.append("candidate_id", jobId.user_id);
        let internal_mailbox_id = await dispatch(sendInternalMail(formData));
        await dispatch(updateFileMail({ internal_mailbox_id: internal_mailbox_id.payload.id, type: applicationData.payload.type, filename: applicationData.payload.filename, file_url: applicationData.payload.file_url }));
        setState({});
        setContactDetailsQuestions({});
        setScreeningQuestions({});
      } else {
        setOpen(true);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  let aaa = `<div className="success-checkmark">
  <div className="check-icon">
    <span className="icon-line line-tip"></span>
    <span className="icon-line line-long"></span>
    <div className="icon-circle"></div>
    <div className="icon-fix"></div>
  </div>
</div>`;
  let i = 0;
  let question_1 = "";
  let question_2 = "";
  let question_3 = "";
  if (activeStep == 2) {
    question_1 = <div style={{ paddingLeft: "16px", paddingRight: "16px", paddingBottom: "7px" }} dangerouslySetInnerHTML={{ __html: howToApplyDetails.contact_details }}></div>;
    question_2 = questionsList.map((row, number) => {
      return (
        row.cat_type == 2 ?
          row.questions.map((row1, index) => {
            i++;
            let catTitle = <>{number != 0 ? <hr className='cat-line'></hr> : ""}<Typography sx={{ mt: 1 }} variant="h6" gutterBottom>
              {row.name}
            </Typography></>;
            return (
              <div className="col-12 pt-2 pb-2">
                {/* {index == 0 ? catTitle : ""} */}

                {
                  row1.id == "50" ? <><InputLabel sx={{ fontSize: "14px", color: "#000", fontWeight: "bold", whiteSpace: "break-spaces" }} htmlFor="input-with-icon-adornment">
                    {`Q${i} - ${row1.question}`}
                  </InputLabel>
                    <Input
                      id={row1.id}
                      defaultValue={contactDetailsQuestions[row1.id]}
                      className='question-input'
                      onChange={contactDetailsQuestionshandleForm}
                      sx={{ pl: 4, color: "#1976d2" }}
                      label="Multiline"
                      multiline
                      rows={4}
                      fullWidth
                    /></> : <>   <InputLabel sx={{ fontSize: "14px", color: "#000", whiteSpace: "break-spaces" }} htmlFor="input-with-icon-adornment">
                      {`Q${i} - ${row1.question}`}
                    </InputLabel>
                    <Input
                      id={row1.id}
                      defaultValue={contactDetailsQuestions[row1.id]}
                      className='question-input'
                      onChange={contactDetailsQuestionshandleForm}
                      sx={{ pl: 4, color: "#1976d2" }}
                      placeholder='Enter your answer'
                      fullWidth
                    /></>
                }

              </div>
            )
          })
          : ""
      )
    }

    );
  }

  return (
    <>

      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={2000} open={open} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Please check all the mandatory fields.
        </Alert>
      </Snackbar>
      <Modal
        show={show}
        dialogClassName="dkg-applyjob-ModalDailog"
        aria-labelledby="example-custom-modal-styling-title"
        onHide={onHide}
      >
        <Modal.Header closeButton className="dkg-applyjob-header">
          <Modal.Title className="dk-detViewTitle" id="example-custom-modal-styling-title">
            JOB APPLICATION FORM
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='dkg-applyjob-modal-body'>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ background: "#fff", padding: "16px 0px 16px 0px" }}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep == steps.length ? (
              <React.Fragment>
                <Typography component="div" sx={{ pl: 3, pr: 3, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "100%" }}>
                  <Typography variant="body1" align="center">
                    <div
                      dangerouslySetInnerHTML={{ __html: aaa }}
                    ></div>
                  </Typography>
                  <Typography variant="h5" sx={{ pb: 1 }} align="center" gutterBottom>
                    Thank you!
                  </Typography>
                  <Typography variant="h5" sx={{ pb: 2 }} align="center" gutterBottom>
                    Your application has been submitted successfully.<br></br>
                    Our recruiters will get in touch with you as soon as possible!
                  </Typography>

                  <Typography variant="body1" align="center" gutterBottom>
                    <Button sx={{ cursor: "unset !important" }} variant="outlined" size="large">
                      You will receive a copy of your application via email
                    </Button>
                  </Typography>
                  <Grid item md={12} xs={12} sm={12} sx={{ p: 0 }}>
                    <div className="confetti" style={{ margin: 0 }}>
                      <div className="confetti__wrapper">
                        <div className="confetti-left-50"></div>
                        <div className="confetti-left-49"></div>
                        <div className="confetti-left-48"></div>
                        <div className="confetti-left-47"></div>
                        <div className="confetti-left-46"></div>
                        <div className="confetti-left-45"></div>
                        <div className="confetti-left-44"></div>
                        <div className="confetti-left-43"></div>
                        <div className="confetti-left-42"></div>
                        <div className="confetti-left-41"></div>
                        <div className="confetti-left-40"></div>
                        <div className="confetti-left-39"></div>
                        <div className="confetti-left-38"></div>
                        <div className="confetti-left-37"></div>
                        <div className="confetti-left-36"></div>
                        <div className="confetti-left-35"></div>
                        <div className="confetti-left-34"></div>
                        <div className="confetti-left-33"></div>
                        <div className="confetti-left-32"></div>
                        <div className="confetti-left-31"></div>
                        <div className="confetti-left-30"></div>
                        <div className="confetti-left-29"></div>
                        <div className="confetti-left-28"></div>
                        <div className="confetti-left-27"></div>
                        <div className="confetti-left-26"></div>
                        <div className="confetti-left-25"></div>
                        <div className="confetti-left-24"></div>
                        <div className="confetti-left-23"></div>
                        <div className="confetti-left-22"></div>
                        <div className="confetti-left-21"></div>
                        <div className="confetti-left-20"></div>
                        <div className="confetti-left-19"></div>
                        <div className="confetti-left-18"></div>
                        <div className="confetti-left-17"></div>
                        <div className="confetti-left-16"></div>
                        <div className="confetti-left-15"></div>
                        <div className="confetti-left-14"></div>
                        <div className="confetti-left-13"></div>
                        <div className="confetti-left-12"></div>
                        <div className="confetti-left-11"></div>
                        <div className="confetti-left-10"></div>
                        <div className="confetti-left-9"></div>
                        <div className="confetti-left-8"></div>
                        <div className="confetti-left-7"></div>
                        <div className="confetti-left-6"></div>
                        <div className="confetti-left-5"></div>
                        <div className="confetti-left-4"></div>
                        <div className="confetti-left-3"></div>
                        <div className="confetti-left-2"></div>
                        <div className="confetti-left-1"></div>
                        <div className="confetti-left-0"></div>
                        <div className="confetti-right-40"></div>
                        <div className="confetti-right-49"></div>
                        <div className="confetti-right-48"></div>
                        <div className="confetti-right-47"></div>
                        <div className="confetti-right-46"></div>
                        <div className="confetti-right-45"></div>
                        <div className="confetti-right-44"></div>
                        <div className="confetti-right-43"></div>
                        <div className="confetti-right-42"></div>
                        <div className="confetti-right-41"></div>
                        <div className="confetti-right-40"></div>
                        <div className="confetti-right-39"></div>
                        <div className="confetti-right-38"></div>
                        <div className="confetti-right-37"></div>
                        <div className="confetti-right-36"></div>
                        <div className="confetti-right-35"></div>
                        <div className="confetti-right-34"></div>
                        <div className="confetti-right-33"></div>
                        <div className="confetti-right-32"></div>
                        <div className="confetti-right-31"></div>
                        <div className="confetti-right-30"></div>
                        <div className="confetti-right-29"></div>
                        <div className="confetti-right-28"></div>
                        <div className="confetti-right-27"></div>
                        <div className="confetti-right-26"></div>
                        <div className="confetti-right-25"></div>
                        <div className="confetti-right-24"></div>
                        <div className="confetti-right-23"></div>
                        <div className="confetti-right-22"></div>
                        <div className="confetti-right-21"></div>
                        <div className="confetti-right-20"></div>
                        <div className="confetti-right-19"></div>
                        <div className="confetti-right-18"></div>
                        <div className="confetti-right-17"></div>
                        <div className="confetti-right-16"></div>
                        <div className="confetti-right-15"></div>
                        <div className="confetti-right-14"></div>
                        <div className="confetti-right-13"></div>
                        <div className="confetti-right-12"></div>
                        <div className="confetti-right-11"></div>
                        <div className="confetti-right-10"></div>
                        <div className="confetti-right-9"></div>
                        <div className="confetti-right-8"></div>
                        <div className="confetti-right-7"></div>
                        <div className="confetti-right-6"></div>
                        <div className="confetti-right-5"></div>
                        <div className="confetti-right-4"></div>
                        <div className="confetti-right-3"></div>
                        <div className="confetti-right-2"></div>
                        <div className="confetti-right-1"></div>
                        <div className="confetti-right-0"></div>
                      </div>
                      <div className="banner"></div>
                    </div>
                  </Grid>
                </Typography>
                {/* <Button onClick={handleReset}>Reset</Button> */}
              </React.Fragment>
            ) : (
              <React.Fragment>

                <Typography component="div" className="step-div">
                  {activeStep == 0 ?
                    <>
                      <div className='step-white-bg' style={{ height: "calc(100vh - 328px)", overflowY: "auto", paddingLeft: "16px", paddingRight: "16px", paddingTop: "10px" }} dangerouslySetInnerHTML={{ __html: activeStep == 0 ? howToApplyDetails.instructions : "" }}></div>
                      <FormGroup sx={{ background: "#fff", pl: 2, pr: 2, borderTop: "1px solid #bdbdbd" }}>
                        <FormControlLabel sx={{ mb: 0, marginLeft: "-24px" }} required control={<Checkbox checked={confirm1} onChange={handleChange} name="confirm1" />} label="I confirm that i have read the full job information carefully." />
                        <FormControlLabel sx={{ mb: 0, marginLeft: "-24px" }} required control={<Checkbox checked={confirm2} onChange={handleChange} name="confirm2" />} label="I confirm that i have no objection about salary and benefits and job location." />
                        <FormControlLabel sx={{ mb: 0, marginLeft: "-24px" }} required control={<Checkbox checked={confirm3} onChange={handleChange} name="confirm3" />} label="I confirm that i am willing to take interviews and accpet the job offer if selected to join." />
                      </FormGroup>
                    </>
                    : ""}
                  {activeStep == 1 ? (
                    questionsList.map((row, number) => {
                      return (
                        row.cat_type == 1 ?
                          row.questions.map((row1, index) => {
                            i++;
                            let catTitle = <>{number != 0 ? <hr className='cat-line'></hr> : ""}<Typography sx={{ mt: 1 }} variant="h6" gutterBottom>
                              {row.name}
                            </Typography></>;
                            return (
                              <div className="col-12 pt-3 pb-1" key={row1.id}>
                                {/* {index == 0 ? catTitle : ""} */}
                                <InputLabel sx={{ fontSize: "14px", color: "#000", whiteSpace: "break-spaces" }} htmlFor="input-with-icon-adornment">
                                  {`Q${i} - ${row1.question}`}
                                </InputLabel>
                                <Input
                                  id={row1.id}
                                  defaultValue={screeningQuestions[row1.id]}
                                  className='question-input'
                                  onChange={screeningQuestionshandleForm}
                                  placeholder='Enter your answer'
                                  sx={{ pl: 4, color: "#1976d2" }}
                                  fullWidth
                                />
                              </div>
                            )
                          })
                          : ""
                      )
                    }

                    )
                  ) : (
                    ''
                  )}
                  {activeStep == 2 ? (
                    <div style={{ height: "calc(100vh - 268px)", overflowY: "auto", }}>
                      {question_1}
                      {question_2}
                      {question_3}
                    </div>
                  ) : (
                    ''
                  )}
                  {activeStep == 2 ? (
                    <FormGroup sx={{ background: "#fff", borderTop: "1px solid #bdbdbd", paddingTop: "9px", paddingBottom: "9px", }}>
                      <FormControlLabel sx={{ mb: 0 }} required control={<Checkbox checked={confirm7} onChange={handleChange} name="confirm7" />} label="I agree that my phone, emails, chats with DKG can be recorded for training & quality purposes. " />
                    </FormGroup>
                  ) : (
                    ''
                  )}
                  {activeStep == 3 ?
                    <>
                      <div style={{ height: "calc(100vh - 289px)", overflowY: "auto", paddingLeft: "16px", paddingRight: "16px" }} dangerouslySetInnerHTML={{ __html: activeStep == 3 ? howToApplyDetails.gdpr_consent : "" }}></div>
                      <FormGroup sx={{ background: "#fff", pl: 2, pr: 2, borderTop: "1px solid #bdbdbd" }}>
                        <FormControlLabel sx={{ mb: 0, marginLeft: "-24px" }} required control={<Checkbox checked={confirm4} onChange={handleChange} name="confirm4" />} label={<span>I agree to the <a href="https://www.dkglobalrecruitment.com/privacy_policy" target='_blank'>privacy policy</a> of GDPR.</span>} />
                        <FormControlLabel sx={{ mb: 0, marginLeft: "-24px" }} required control={<Checkbox checked={confirm5} onChange={handleChange} name="confirm5" />} label={<span>I agree to the <a href="https://www.dkglobalrecruitment.com/privacy_policy" target='_blank'>cookies policies</a> of GDPR.</span>} />
                      </FormGroup>
                    </>
                    : ""}
                  {activeStep == 4 ?
                    <>
                      <div className='step-white-bg' style={{ height: "calc(100vh - 268px)", overflowY: "auto", paddingLeft: "16px", paddingRight: "16px", paddingTop: "10px" }} dangerouslySetInnerHTML={{ __html: activeStep == 4 ? howToApplyDetails.agree_tob_s : "" }}></div>
                      <FormGroup sx={{ background: "#fff", borderTop: "1px solid #bdbdbd", paddingTop: "9px", paddingBottom: "9px", }}>
                        <FormControlLabel sx={{ mb: 0 }} required control={<Checkbox checked={confirm6} onChange={handleChange} name="confirm6" />} label="I agree to the terms & conditions of DK Global services." />
                      </FormGroup>
                    </>
                    : ""}
                  {activeStep == 5 ? '' : ""}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: "center", flexDirection: 'row', padding: "18px", background: "#fff", borderTop: "1px solid #bdbdbd" }}>

                  {activeStep != 0 ? (
                    <Button
                      sx={{ color: "#000", mr: 3 }}
                      disabled={activeStep == 0}
                      onClick={handleBack}
                      startIcon={<ArrowBackIosIcon />}
                    >
                      Go Back
                    </Button>
                  ) : ''}

                  {activeStep >= 4 ? (
                    <Button className='step-btn' endIcon={<ArrowForwardIosIcon />} variant="contained" onClick={handleNext1}>
                      Next
                    </Button>

                  ) : <Button className='step-btn' endIcon={<ArrowForwardIosIcon />} variant="contained" onClick={handleNext}>
                    Next
                  </Button>}
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default ApplyjobPoup