import React from 'react';
import { Link, Route } from "react-router-dom";


const DepartmentLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkg-global/company/departments/summary" className={`${pathname.match('/dkg-global/company/departments/summary') ? 'active' : ''}`}>Overview</Link></li>
                    <li><Link to="/dkg-global/company/departments/recruitment" className={`${pathname.match('/dkg-global/company/departments/recruitment') ? 'active' : ''}`}>Recruitment</Link></li>
                    <li><Link to="/dkg-global/company/departments/advertisement" className={`${pathname.match('/dkg-global/company/departments/advertisement') ? 'active' : ''}`}>Resource</Link></li>
                    <li><Link to="/dkg-global/company/departments/accounting-finance" className={`${pathname.match('/dkg-global/company/departments/accounting-finance') ? 'active' : ''}`}>Finance</Link></li>
                    <li><Link to="/dkg-global/company/departments/suppliers" className={`${pathname.match('/dkg-global/company/departments/suppliers') ? 'active' : ''}`}>Marketing</Link></li>
                    <li><Link to="/dkg-global/company/departments/hr" className={`${pathname.match('/dkg-global/company/departments/hr') ? 'active' : ''}`}>HR</Link></li>
                    <li><Link to="/dkg-global/company/departments/legal" className={`${pathname.match('/dkg-global/company/departments/legal') ? 'active' : ''}`}>Legal</Link></li>
                    <li><Link to="/dkg-global/company/departments/training" className={`${pathname.match('/dkg-global/company/departments/training') ? 'active' : ''}`}>Training</Link></li>
                    <li><Link to="/dkg-global/company/departments/it-team" className={`${pathname.match('/dkg-global/company/departments/it-team') ? 'active' : ''}`}>IT Team</Link></li>
                    <li><Link to="/dkg-global/company/departments/business-development" className={`${pathname.match('/dkg-global/company/departments/business-development') ? 'active' : ''}`}>New Business</Link></li>
                    <li><Link to="/dkg-global/company/departments/management" className={`${pathname.match('/dkg-global/company/departments/management') ? 'active' : ''}`}>Management</Link></li>
                </Route>
            </ul>
        </React.Fragment>
    )
}

export default DepartmentLeftPanel;

