import React from "react";
import { Route, Link } from "react-router-dom";


const AccountSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/my-files/accounts/account1" className={`${(segment3 == 'account1') ? 'active' : ''}`}>Financial 1</Link></li>
                        <li><Link to="#/my-files/accounts/account2" className={`${(segment3 == 'account2') ? 'active' : ''}`}>Financial 2</Link></li>
                        <li><Link to="#/my-files/accounts/account3" className={`${(segment3 == 'account3') ? 'active' : ''}`}>Financial 3</Link></li>
                        <li><Link to="#/my-files/accounts/account4" className={`${(segment3 == 'account4') ? 'active' : ''}`}>Financial 4</Link></li>
                        <li><Link to="#/my-files/accounts/account5" className={`${(segment3 == 'account5') ? 'active' : ''}`}>Financial 5</Link></li>
                        <li><Link to="#/my-files/accounts/account6" className={`${(segment3 == 'account6') ? 'active' : ''}`}>Financial 6</Link></li>
                        <li><Link to="#/my-files/accounts/account7" className={`${(segment3 == 'account7') ? 'active' : ''}`}>Financial 7</Link></li>
                        <li><Link to="#/my-files/accounts/account8" className={`${(segment3 == 'account8') ? 'active' : ''}`}>Financial 8</Link></li>
                        <li><Link to="#/my-files/accounts/account9" className={`${(segment3 == 'account9') ? 'active' : ''}`}>Financial 9</Link></li>
                        <li><Link to="#/my-files/accounts/account10" className={`${(segment3 == 'account10') ? 'active' : ''}`}>Financial 10</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default AccountSubmenu;
