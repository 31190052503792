import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";

const PayLicenseLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/payments/invoices/license" className={`${pathname.match('/payments/invoices/license') ? 'active' : ''}`}>Indeed</Link></li>

                    {/* <li><Link to="/training/admins/sequence" className={`${pathname.match('/training/admins/sequence') ? 'active' : ''}`}>Sequence</Link></li>

                    <li><Link to="/training/admins/set-priority" className={`${pathname.match('/training/admins/set-priority') ? 'active' : ''}`}>Set Priority</Link></li>

                    <li><Link to="/training/admins/admins-action-priority" className={`${pathname.match('/training/admins/admins-action-priority') ? 'active' : ''}`}>Action Priority</Link></li>

                    <li><Link to="/training/admins/create-new-task" className={`${pathname.match('/training/admins/create-new-task') ? 'active' : ''}`}>Create New Task</Link></li>

                    <li><Link to="/training/admins/follow-workplan" className={`${pathname.match('/training/admins/follow-workplan') ? 'active' : ''}`}>Follow Workplan</Link></li> */}

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default PayLicenseLeftPanel;

