
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PophoverIcon from "./PophoverIcon";
import {
    // clearState,
    // clearTextBox,
    CVSourceFilterFirst,
    KeyQualificationFilterFirst,
    KeyQualificationFilterSecond,
    KeyQualificationFilterThird,
    KeyQualificationFilterFourth,
    KeyQualificationFilterFive,
    KeyQualifyFilterFirst,
    KeyQualifyFilterSecond,
    KeyQualifyFilterThird,
    motivationFilterFirst,
    motivationFilterSecond,
    motivationFilterThird,
    JobDescriptionFilterFirst,
    JobDescriptionFilterSecond,
    JobDescriptionFilterThird,
    SalaryBenifitFilterFirst,
    SalaryBenifitFilterSecond,
    SalaryBenifitFilterThird,
    JobReqFilterFirst,
    JobReqFilterSecond,
    JobReqFilterThird,
    ReloactionQueryFilterFirst,
    ReloactionQueryFilterSecond,
    ReloactionQueryFilterThird,
    ProjectStartDateFilterFirst,
    ProjectStartDateFilterSecond,
    ProjectStartDateFilterThird,
    JoiningDocumentFilterFirst,
    JoiningDocumentFilterSecond,
    JoiningDocumentFilterThird,
    InterviewProcessFilterFirst,
    InterviewProcessFilterSecond,
    InterviewProcessFilterThird,
    FullResumePreviewFilterFirst,
    FullResumePreviewFilterSecond,
    FullResumePreviewFilterThird,
    OtherJobInterviewFilterFirst,
    OtherJobInterviewFilterSecond,
    OtherJobInterviewFilterThird,
    ContactPreferenceFilterFirst,
    ContactPreferenceFilterSecond,
    ContactPreferenceFilterThird,
    addNewValue,
    updateData,
    //fetchScreeningList,
    screaningLevelFirst,
    screaningAvailability,
    screaningContactPrefered,
    getTextLevel,
    firstLevel5th_1,
    firstLevel5th_2,
    firstLevel5th_3,
    firstLevel6th_1,
    firstLevel6th_2,
    firstLevel6th_3,
    firstLevel7th_1,
    firstLevel7th_2,
    firstLevel7th_3,
    firstLevel7th_4,
    firstLevel8th_1,
    firstLevel8th_2,
    firstLevel8th_3,
    /*******************new block */
    firstLvl9th_1,
    firstLvl9th_2,
    firstLvl9th_3,
    firstLvl10th_1,
    firstLvl10th_2,
    firstLvl10th_3,
    firstLvl11th_1,
    firstLvl11th_2,
    firstLvl11th_3,
    firstLvl12th_1,
    firstLvl12th_2,
    firstLvl12th_3,
    firstLvl12th_4,
    firstLvl13th_1,
    firstLvl13th_2,
    firstLvl13th_3,
    firstLvl14th_1,
    firstLvl14th_2,
    firstLvl14th_3,
    firstLvl15th_1,
    firstLvl15th_2,
    firstLvl15th_3,
    firstLvl15th_4,
    firstLvl15th_5,
    firstLvl15th_6,
    update1stScreaning
} from "../../../../../../../slice/candidatePrecreeningForm/candidatePrecreeningSlice";
import {  fetchById } from "../../../../../../../slice/candidatePrecreeningForm/candidatePrecreeningSlice";
import { SYSTEM_CONSTANTS } from "../../../../../../../constants";
import Dropdown from "react-bootstrap/Dropdown";
import {
    showSuccess,
    showError,
    clearMessage,
} from "../../../../../../../slice/utils/message/messageSlice";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const FirstScreening = ({ candidateId,screaningData }) => {
    const dispatch = useDispatch();
    const {
        isLoading,isScreaningLoading, keyQualificationFirstList,
        keyQualificationSecondList,
        keyQualificationThirdList,
        keyQualificationFourList,
        keyQualificationFiveList,
        keyQualifyFirstList,
        keyQualifySecondList,
        keyQualifyThirdList,
        motivationFirstList,
        motivationSecondList,
        motivationThirdList,     
        screaningLevelList,
        screaningListAvailability,
        screaningPreferedList,
        firstLevelList,
        firstLevelFiveList,
        firstLevelFiveTwoList,
        firstLevelFiveThreeList,
        firstLevelSix1stList,
        firstLevelSix2ndList,
        firstLevelSix3rdList,
        firstLevelSeven1stList,
        firstLevelSeven2ndList,
        firstLevelSeven3rdtList,
        firstLevelSeven4rtList,
        firstLevelEight1stList,
        firstLevelEight2ndList,
        firstLevelEight3rdtList,
        firstLvlNine1stList, firstLvlNine2ndList, firstLvlNine3rdList, firstLvlTen1stList, firstLvlTen2ndList, firstLvlTen3rdList, firstLvlEleven1stList, firstLvlEleven2ndList, firstLvlEleven3rdList, firstLvlTwelve3rdList, firstLvlTwelve4rthdList,firstLvlTwelve2ndList, firstLvlTwelve1stList, firstLvlThirteen3rdList, firstLvlThirteen2ndList, firstLvlThirteen1stList, firstLvlFourteen1stList, firstLvlFourteen2ndList, firstLvlFourteen3rdList, firstLvlFifteen3rdList,firstLvlFifteen4rtList,firstLvlFifteen5thList,firstLvlFifteen6thList, firstLvlFifteen2ndList, firstLvlFifteen1stList
    } = useSelector((state) => state.candidatePrecreening);
 

    useEffect(() => {
        async function fetchData() {
            try {
               
                await dispatch(fetchById({ id:candidateId }));
                await dispatch(getTextLevel({ type: "1stLevel" }));
            } catch (err) {
                dispatch(showError({ msg: "Somthings went wrong" }));
            }
        }
        fetchData();
    }, [dispatch,candidateId]);
    const [data, setData] = useState([]);
   // const [screanningDetails, setScreanningDetails] = useState([]);
    
const [keyQualificationFirst, setKeyQualificationFirst] = useState('');
const [keyQualificationSecond, setKeyQualificationSecond] = useState('');
const [keyQualificationThird, setKeyQualificationThird] = useState('');
const [keyQualificationFour, setKeyQualificationFour] = useState('');
const [keyQualificationFive, setKeyQualificationFive] = useState('');
const [keyQualificationNotes, setKeyQualificationNotes] = useState('');
const [key_qualification_notes_2, setKeyQualificationNotes_2] = useState('');
const [key_qualification_notes_3, setKeyQualificationNotes_3] = useState('');
const [key_qualification_notes_4, setKeyQualificationNotes_4] = useState('');
const [key_qualification_notes_5, setKeyQualificationNotes_5] = useState('');
const [keyQualifyFirst, setKeyQualifyFirst] = useState('');
const [keyQualifySecond, setKeyQualifySecond] = useState('');
const [keyQualifyThird, setKeyQualifyThird] = useState('');
const [keyQualifyNotes, setKeyQualifyNotes] = useState('');
const [key_qualify_notes_2, setKeyQualifyNotes_2] = useState('');
const [key_qualify_notes_3, setKeyQualifyNotes_3] = useState('');
const [motivationFirst, setMotivationFirst] = useState('');
const [motivationSecond, setMotivationSecond] = useState('');
const [motivationThird, setMotivationThird] = useState('');
const [motivationNotes, setMotivationNotes] = useState('');
const [motivation_notes_2, setMotivationNotes_2] = useState('');
const [motivation_notes_3, setMotivationNotes_3] = useState('');
const [screeningFirst, setScreeningFirst] = useState('');
const [firstLevelFive, setFirstLevelFive] = useState('');
const [firstLevelFive_1, setFirstLevelFive_1] = useState('');
const [firstLevelFive_2, setFirstLevelFive_2] = useState('');
const [firstLevelFive_3, setFirstLevelFive_3] = useState('');
const [firstLevelFive_4, setFirstLevelFive_4] = useState('');
const [firstLevelFive_5, setFirstLevelFive_5] = useState('');
const [firstLevelSix, setFirstLevelSix] = useState('');
const [firstLevelSix_1, setFirstLevelSix_1] = useState('');
const [firstLevelSix_2, setFirstLevelSix_2] = useState('');
const [firstLevelSix_3, setFirstLevelSix_3] = useState('');
const [firstLevelSix_4, setFirstLevelSix_4] = useState('');
const [firstLevelSix_5, setFirstLevelSix_5] = useState('');
const [firstLevelSeven, setFirstLevelSeven] = useState('');
const [firstLevelSeven_1, setFirstLevelSeven_1] = useState('');
const [firstLevelSeven_2, setFirstLevelSeven_2] = useState('');
const [firstLevelSeven_3, setFirstLevelSeven_3] = useState('');
const [firstLevelSeven_4, setFirstLevelSeven_4] = useState('');
const [firstLevelSeven_5, setFirstLevelSeven_5] = useState('');
const [firstLevelSeven_6, setFirstLevelSeven_6] = useState('');
const [firstLevelSeven_7, setFirstLevelSeven_7] = useState('');
const [firstLevelEight, setFirstLevelEight] = useState('');
const [firstLevelEight_1, setFirstLevelEight_1] = useState('');
const [firstLevelEight_2, setFirstLevelEight_2] = useState('');
const [firstLevelEight_3, setFirstLevelEight_3] = useState('');
const [firstLevelEight_4, setFirstLevelEight_4] = useState('');
const [firstLevelEight_5, setFirstLevelEight_5] = useState('');
const [firstLevelNine, setFirstLevelNine] = useState('');
const [firstLevelNine_1, setFirstLevelNine_1] = useState('');
const [firstLevelNine_2, setFirstLevelNine_2] = useState('');
const [firstLevelNine_txt, setFirstLevelNine_3] = useState('');
const [firstLevelNine_1_txt, setFirstLevelNine_4] = useState('');
const [firstLevelNine_2_txt, setFirstLevelNine_5] = useState('');
const [firstLevelTen, setFirstLevelTen] = useState('');
const [firstLevelTen_1, setFirstLevelTen_1] = useState('');
const [firstLevelTen_2, setFirstLevelTen_2] = useState('');
const [firstLevelTen_txt, setFirstLevelTen_3] = useState('');
const [firstLevelTen_1_txt, setFirstLevelTen_4] = useState('');
const [firstLevelTen_2_txt, setFirstLevelTen_5] = useState('');
const [firstLevelEleven, setFirstLevelEleven] = useState('');
const [firstLevelEleven_1, setFirstLevelEleven_1] = useState('');
const [firstLevelEleven_2, setFirstLevelEleven_2] = useState('');
const [firstLevelEleven_txt, setFirstLevelEleven_3] = useState('');
const [firstLevelEleven_1_txt, setFirstLevelEleven_4] = useState('');
const [firstLevelEleven_2_txt, setFirstLevelEleven_5] = useState('');
const [firstLevelTwelve, setFirstLevelTwelve] = useState('');
const [firstLevelTwelve_1, setFirstLevelTwelve_1] = useState('');
const [firstLevelTwelve_2, setFirstLevelTwelve_2] = useState('');
const [firstLevelTwelve_7, setFirstLevelTwelve_7] = useState('');
const [firstLevelTwelve_txt, setFirstLevelTwelve_3] = useState('');
const [firstLevelTwelve_1_txt, setFirstLevelTwelve_4] = useState('');
const [firstLevelTwelve_2_txt, setFirstLevelTwelve_5] = useState('');
const [firstLevelTwelve_7_txt, setFirstLevelTwelve_6] = useState('');
const [firstLevelThirteen, setFirstLevelThirteen] = useState('');
const [firstLevelThirteen_1, setFirstLevelThirteen_1] = useState('');
const [firstLevelThirteen_2, setFirstLevelThirteen_2] = useState('');
const [firstLevelThirteen_txt, setFirstLevelThirteen_3] = useState('');
const [firstLevelThirteen_1_txt, setFirstLevelThirteen_4] = useState('');
const [firstLevelThirteen_2_txt, setFirstLevelThirteen_5] = useState('');
const [firstLevelFourteen, setFirstLevelFourteen] = useState('');
const [firstLevelFourteen_1, setFirstLevelFourteen_1] = useState('');
const [firstLevelFourteen_2, setFirstLevelFourteen_2] = useState('');
const [firstLevelFourteen_txt, setFirstLevelFourteen_3] = useState('');
const [firstLevelFourteen_1_txt, setFirstLevelFourteen_4] = useState('');
const [firstLevelFourteen_2_txt, setFirstLevelFourteen_5] = useState('');
const [firstLevelFifteen, setFirstLevelFifteen] = useState('');
const [firstLevelFifteen_1, setFirstLevelFifteen_1] = useState('');
const [firstLevelFifteen_txt, setFirstLevelFifteen_3] = useState('');
const [firstLevelFifteen_1_txt, setFirstLevelFifteen1txt] = useState('');
const [firstLevelFifteen_2, setFirstLevelFifteen_2] = useState('');
const [firstLevelFifteen_2_txt, setFirstLevelFifteen2txt] = useState('');
// new block

const [firstLevelFifteen_4, setFirstLevelFifteen_4] = useState('');
const [firstLevelFifteen4_txt, setFirstLevelFifteen4txt] = useState('');

const [firstLevelFifteen_5, setFirstLevelFifteen_5] = useState('');
const [firstLevelFifteen_5_txt, setFirstLevelFifteen5txt] = useState('');

const [firstLevelFifteen_6, setFirstLevelFifteen_6] = useState('');
const [firstLevelFifteen_6_txt, setFirstLevelFifteen6txt] = useState('');
//end 
const [screeningAbliFirst, setScreeningAbliFirst] = useState('');
const [screeningPreContact, setScreeningPreContact] = useState('');
const [notesScreeningFirst, setNotesScreeningFirst] = useState('');
const [notesScreeningAbliFirst, setNotesScreeningAbliFirst] = useState('');
const [notesScreeningPreContact, setNotesScreeningPreContact] = useState('');

    useEffect(() => {      
        if (screaningData.screanningDetails !== undefined) {

            setNotesScreeningPreContact(screaningData.screanningDetails.notesScreeningPreContact ? screaningData.screanningDetails.notesScreeningPreContact :"")
            setNotesScreeningAbliFirst(screaningData.screanningDetails.notesScreeningAbliFirst ? screaningData.screanningDetails.notesScreeningAbliFirst :"")
            setNotesScreeningFirst(screaningData.screanningDetails.notesScreeningFirst ? screaningData.screanningDetails.notesScreeningFirst :"")
            setScreeningPreContact(screaningData.screanningDetails.screeningPreContact ? screaningData.screanningDetails.screeningPreContact :"Select")
            setScreeningAbliFirst(screaningData.screanningDetails.screeningAbliFirst ? screaningData.screanningDetails.screeningAbliFirst :"Select")
            
            setFirstLevelFifteen2txt(screaningData.screanningDetails.firstLevelFifteen_2_txt ? screaningData.screanningDetails.firstLevelFifteen_2_txt :"")
            setFirstLevelFifteen1txt(screaningData.screanningDetails.firstLevelFifteen_1_txt ? screaningData.screanningDetails.firstLevelFifteen_1_txt :"")
            setFirstLevelFifteen_3(screaningData.screanningDetails.firstLevelFifteen_txt ? screaningData.screanningDetails.firstLevelFifteen_txt :"")

            setFirstLevelFifteen4txt(screaningData.screanningDetails.firstLevelFifteen_4_txt ? screaningData.screanningDetails.firstLevelFifteen_4_txt :"")
            setFirstLevelFifteen_4(screaningData.screanningDetails.firstLevelFifteen_4 ? screaningData.screanningDetails.firstLevelFifteen_4 :"Select")

            setFirstLevelFifteen5txt(screaningData.screanningDetails.firstLevelFifteen_5_txt ? screaningData.screanningDetails.firstLevelFifteen_5_txt :"")
            setFirstLevelFifteen_5(screaningData.screanningDetails.firstLevelFifteen_5 ? screaningData.screanningDetails.firstLevelFifteen_5 :"Select")

            setFirstLevelFifteen6txt(screaningData.screanningDetails.firstLevelFifteen_6_txt ? screaningData.screanningDetails.firstLevelFifteen_6_txt :"")
            setFirstLevelFifteen_6(screaningData.screanningDetails.firstLevelFifteen_6 ? screaningData.screanningDetails.firstLevelFifteen_6 :"Select")

            setFirstLevelFifteen_2(screaningData.screanningDetails.firstLevelFifteen_2 ? screaningData.screanningDetails.firstLevelFifteen_2 :"Select")
            setFirstLevelFifteen_1(screaningData.screanningDetails.firstLevelFifteen_1 ? screaningData.screanningDetails.firstLevelFifteen_1 :"Select")
            setFirstLevelFifteen(screaningData.screanningDetails.firstLevelFifteen ? screaningData.screanningDetails.firstLevelFifteen :"Select")
            setFirstLevelFourteen_5(screaningData.screanningDetails.firstLevelFourteen_2_txt ? screaningData.screanningDetails.firstLevelFourteen_2_txt :"")
            setFirstLevelFourteen_4(screaningData.screanningDetails.firstLevelFourteen_1_txt ? screaningData.screanningDetails.firstLevelFourteen_1_txt :"")
            setFirstLevelFourteen_3(screaningData.screanningDetails.firstLevelFourteen_txt ? screaningData.screanningDetails.firstLevelFourteen_txt :"")
            setFirstLevelFourteen_2(screaningData.screanningDetails.firstLevelFourteen_2 ? screaningData.screanningDetails.firstLevelFourteen_2 :"Select")
            setFirstLevelFourteen_1(screaningData.screanningDetails.firstLevelFourteen_1 ? screaningData.screanningDetails.firstLevelFourteen_1 :"Select")
            setFirstLevelFourteen(screaningData.screanningDetails.firstLevelFourteen ? screaningData.screanningDetails.firstLevelFourteen :"Select")
            setFirstLevelThirteen_5(screaningData.screanningDetails.firstLevelThirteen_2_txt ? screaningData.screanningDetails.firstLevelThirteen_2_txt :"")
            setFirstLevelThirteen_4(screaningData.screanningDetails.firstLevelThirteen_1_txt ? screaningData.screanningDetails.firstLevelThirteen_1_txt :"")

            setFirstLevelThirteen_3(screaningData.screanningDetails.firstLevelThirteen_txt ? screaningData.screanningDetails.firstLevelThirteen_txt :"")
            setFirstLevelThirteen_2(screaningData.screanningDetails.firstLevelThirteen_2 ? screaningData.screanningDetails.firstLevelThirteen_2 :"Select")

            setFirstLevelThirteen_1(screaningData.screanningDetails.firstLevelThirteen_1 ? screaningData.screanningDetails.firstLevelThirteen_1 :"Select")
            setFirstLevelThirteen(screaningData.screanningDetails.firstLevelThirteen ? screaningData.screanningDetails.firstLevelThirteen :"Select")
            setFirstLevelTwelve_6(screaningData.screanningDetails.firstLevelTwelve_7_txt ? screaningData.screanningDetails.firstLevelTwelve_7_txt :"")
            setFirstLevelTwelve_5(screaningData.screanningDetails.firstLevelTwelve_2_txt ? screaningData.screanningDetails.firstLevelTwelve_2_txt :"")
            setFirstLevelTwelve_4(screaningData.screanningDetails.firstLevelTwelve_1_txt ? screaningData.screanningDetails.firstLevelTwelve_1_txt :"")
            setFirstLevelTwelve_3(screaningData.screanningDetails.firstLevelTwelve_txt ? screaningData.screanningDetails.firstLevelTwelve_txt :"")
            setFirstLevelTwelve_2(screaningData.screanningDetails.firstLevelTwelve_7 ? screaningData.screanningDetails.firstLevelTwelve_7 :"Select")
            setFirstLevelTwelve_7(screaningData.screanningDetails.firstLevelTwelve_2 ? screaningData.screanningDetails.firstLevelTwelve_2 :"Select")
            setFirstLevelTwelve_1(screaningData.screanningDetails.firstLevelTwelve_1 ? screaningData.screanningDetails.firstLevelTwelve_1 :"Select")
            setFirstLevelTwelve(screaningData.screanningDetails.firstLevelTwelve ? screaningData.screanningDetails.firstLevelTwelve :"Select")
            setFirstLevelEleven_5(screaningData.screanningDetails.firstLevelEleven_2_txt ? screaningData.screanningDetails.firstLevelEleven_2_txt :"")
            setFirstLevelEleven_4(screaningData.screanningDetails.firstLevelEleven_1_txt ? screaningData.screanningDetails.firstLevelEleven_1_txt :"")
            setFirstLevelEleven_3(screaningData.screanningDetails.firstLevelEleven_txt ? screaningData.screanningDetails.firstLevelEleven_txt :"")
            setFirstLevelEleven_2(screaningData.screanningDetails.firstLevelEleven_2 ? screaningData.screanningDetails.firstLevelEleven_2 :"Select")
            setFirstLevelEleven_1(screaningData.screanningDetails.firstLevelEleven_1 ? screaningData.screanningDetails.firstLevelEleven_1 :"Select")
            setFirstLevelEleven(screaningData.screanningDetails.firstLevelEleven ? screaningData.screanningDetails.firstLevelEleven :"Select")
            setFirstLevelTen_5(screaningData.screanningDetails.firstLevelTen_2_txt ? screaningData.screanningDetails.firstLevelTen_2_txt :"")
            setFirstLevelTen_4(screaningData.screanningDetails.firstLevelTen_1_txt ? screaningData.screanningDetails.firstLevelTen_1_txt :"")
            setFirstLevelTen_3(screaningData.screanningDetails.firstLevelTen_txt ? screaningData.screanningDetails.firstLevelTen_txt :"")
            setFirstLevelTen_2(screaningData.screanningDetails.firstLevelTen_2 ? screaningData.screanningDetails.firstLevelTen_2 :"Select")
            setFirstLevelTen_1(screaningData.screanningDetails.firstLevelTen_1 ? screaningData.screanningDetails.firstLevelTen_1 :"Select")
            setFirstLevelTen(screaningData.screanningDetails.firstLevelTen ? screaningData.screanningDetails.firstLevelTen :"Select")
            setFirstLevelNine_5(screaningData.screanningDetails.firstLevelNine_2_txt ? screaningData.screanningDetails.firstLevelNine_2_txt :"")
            setFirstLevelNine_4(screaningData.screanningDetails.firstLevelNine_1_txt ? screaningData.screanningDetails.firstLevelNine_1_txt :"")
            setFirstLevelNine_3(screaningData.screanningDetails.firstLevelNine_txt ? screaningData.screanningDetails.firstLevelNine_txt :"")
            setFirstLevelNine_2(screaningData.screanningDetails.firstLevelNine_2 ? screaningData.screanningDetails.firstLevelNine_2 :"Select")
            setFirstLevelNine_1(screaningData.screanningDetails.firstLevelNine_1 ? screaningData.screanningDetails.firstLevelNine_1 :"Select")
            setFirstLevelNine(screaningData.screanningDetails.firstLevelNine ? screaningData.screanningDetails.firstLevelNine :"Select")
            setFirstLevelEight_5(screaningData.screanningDetails.firstLevelEight_5 ? screaningData.screanningDetails.firstLevelEight_5 :"")
            setFirstLevelEight_4(screaningData.screanningDetails.firstLevelEight_4 ? screaningData.screanningDetails.firstLevelEight_4 :"")
            setFirstLevelEight_3(screaningData.screanningDetails.firstLevelEight_3 ? screaningData.screanningDetails.firstLevelEight_3 :"")
            setFirstLevelEight_2(screaningData.screanningDetails.firstLevelEight_2 ? screaningData.screanningDetails.firstLevelEight_2 :"Select")
            setFirstLevelEight_1(screaningData.screanningDetails.firstLevelEight_1 ? screaningData.screanningDetails.firstLevelEight_1 :"Select")
            setFirstLevelEight(screaningData.screanningDetails.firstLevelEight ? screaningData.screanningDetails.firstLevelEight :"Select")
            setFirstLevelSeven_6(screaningData.screanningDetails.firstLevelSeven_6 ? screaningData.screanningDetails.firstLevelSeven_6 :"")
            setFirstLevelSeven_7(screaningData.screanningDetails.firstLevelSeven_7 ? screaningData.screanningDetails.firstLevelSeven_7 :"Select")
            setFirstLevelSeven_5(screaningData.screanningDetails.firstLevelSeven_5 ? screaningData.screanningDetails.firstLevelSeven_5 :"")
            setFirstLevelSeven_4(screaningData.screanningDetails.firstLevelSeven_4 ? screaningData.screanningDetails.firstLevelSeven_4 :"")
            setFirstLevelSeven_3(screaningData.screanningDetails.firstLevelSeven_3 ? screaningData.screanningDetails.firstLevelSeven_3 :"")
            setFirstLevelSeven_2(screaningData.screanningDetails.firstLevelSeven_2 ? screaningData.screanningDetails.firstLevelSeven_2 :"Select")
            setFirstLevelSeven_1(screaningData.screanningDetails.firstLevelSeven_1 ? screaningData.screanningDetails.firstLevelSeven_1 :"Select")
            setFirstLevelSeven(screaningData.screanningDetails.firstLevelSeven ? screaningData.screanningDetails.firstLevelSeven :"Select")
            setFirstLevelSix_5(screaningData.screanningDetails.firstLevelSix_5 ? screaningData.screanningDetails.firstLevelSix_5 :"")
            setFirstLevelSix_4(screaningData.screanningDetails.firstLevelSix_4 ? screaningData.screanningDetails.firstLevelSix_4 :"")
            setFirstLevelSix_3(screaningData.screanningDetails.firstLevelSix_3 ? screaningData.screanningDetails.firstLevelSix_3 :"")
            setFirstLevelSix_2(screaningData.screanningDetails.firstLevelSix_2 ? screaningData.screanningDetails.firstLevelSix_2 :"Select")
            setFirstLevelSix_1(screaningData.screanningDetails.firstLevelSix_1 ? screaningData.screanningDetails.firstLevelSix_1 :"Select")
            setFirstLevelSix(screaningData.screanningDetails.firstLevelSix ? screaningData.screanningDetails.firstLevelSix :"Select")
            setFirstLevelFive_5(screaningData.screanningDetails.firstLevelFive_5 ? screaningData.screanningDetails.firstLevelFive_5 :"")
            setFirstLevelFive_4(screaningData.screanningDetails.firstLevelFive_4 ? screaningData.screanningDetails.firstLevelFive_4 :"")
            setFirstLevelFive_3(screaningData.screanningDetails.firstLevelFive_3 ? screaningData.screanningDetails.firstLevelFive_3 :"")
            setFirstLevelFive_2(screaningData.screanningDetails.firstLevelFive_2 ? screaningData.screanningDetails.firstLevelFive_2 :"Select")
            setFirstLevelFive_1(screaningData.screanningDetails.firstLevelFive_1 ? screaningData.screanningDetails.firstLevelFive_1 :"Select")
            setFirstLevelFive(screaningData.screanningDetails.firstLevelFive ? screaningData.screanningDetails.firstLevelFive :"Select")
            setScreeningFirst(screaningData.screanningDetails.screeningFirst ? screaningData.screanningDetails.screeningFirst :"Select")
            setMotivationNotes_3(screaningData.screanningDetails.motivation_notes_3 ? screaningData.screanningDetails.motivation_notes_3 :"")
            setMotivationThird(screaningData.screanningDetails.motivation_3rd_field ? screaningData.screanningDetails.motivation_3rd_field :"Select")
            setMotivationNotes_2(screaningData.screanningDetails.motivation_notes_2 ? screaningData.screanningDetails.motivation_notes_2 :"")
            setMotivationNotes(screaningData.screanningDetails.motivation_notes ? screaningData.screanningDetails.motivation_notes :"")
            setMotivationSecond(screaningData.screanningDetails.motivation_2nd_field ? screaningData.screanningDetails.motivation_2nd_field :"Select")
            setMotivationFirst(screaningData.screanningDetails.motivation_1st_field ? screaningData.screanningDetails.motivation_1st_field :"Select")
            setKeyQualifyNotes_3(screaningData.screanningDetails.key_qualify_notes_3 ? screaningData.screanningDetails.key_qualify_notes_3 :"")
            setKeyQualifyNotes_2(screaningData.screanningDetails.key_qualify_notes_2 ? screaningData.screanningDetails.key_qualify_notes_2 :"")
            setKeyQualifyNotes(screaningData.screanningDetails.key_qualify_notes_1 ? screaningData.screanningDetails.key_qualify_notes_1 :"")
            setKeyQualifyThird(screaningData.screanningDetails.key_qualify_3rd_field ? screaningData.screanningDetails.key_qualify_3rd_field :"Select")
            setKeyQualifySecond(screaningData.screanningDetails.key_qualify_2nd_field ? screaningData.screanningDetails.key_qualify_2nd_field :"Select")
            setKeyQualifyFirst(screaningData.screanningDetails.key_qualify_1st_field ? screaningData.screanningDetails.key_qualify_1st_field :"Select")
            setKeyQualificationNotes_5(screaningData.screanningDetails.key_qualification_notes_5 ? screaningData.screanningDetails.key_qualification_notes_5 :"")
            setKeyQualificationNotes_4(screaningData.screanningDetails.key_qualification_notes_4 ? screaningData.screanningDetails.key_qualification_notes_4 :"")
            setKeyQualificationNotes_3(screaningData.screanningDetails.key_qualification_notes_3 ? screaningData.screanningDetails.key_qualification_notes_3 :"")
            setKeyQualificationNotes_2(screaningData.screanningDetails.key_qualification_notes_2 ? screaningData.screanningDetails.key_qualification_notes_2 :"")
            setKeyQualificationNotes(screaningData.screanningDetails.key_qualification_notes ? screaningData.screanningDetails.key_qualification_notes :"")
            setKeyQualificationFive(screaningData.screanningDetails.key_qualification_5th_field ? screaningData.screanningDetails.key_qualification_5th_field :"Select")
            setKeyQualificationFour(screaningData.screanningDetails.key_qualification_4rt_field ? screaningData.screanningDetails.key_qualification_4rt_field :"Select")            
            setKeyQualificationFirst(screaningData.screanningDetails.key_qualification_1st_field ? screaningData.screanningDetails.key_qualification_1st_field :"Select")
            setKeyQualificationSecond(screaningData.screanningDetails.key_qualification_2nd_field ?screaningData.screanningDetails.key_qualification_2nd_field : "Select")
            setKeyQualificationThird(screaningData.screanningDetails.key_qualification_3rd_field ? screaningData.screanningDetails.key_qualification_3rd_field : "Select")
        }
    }, [screaningData.screanningDetails]);

    useEffect(() => {
        if (!isLoading && firstLevelList && firstLevelList.length > 0) {
            setData(firstLevelList);
        }
        
    }, [isLoading]);

    useEffect(() => {
        const fetchList = async () => {
            try {
               
                await dispatch(
                    KeyQualificationFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualification
                            .firstFilterId,
                    })
                );
                await dispatch(
                    KeyQualificationFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualification
                            .secondFilterId,
                    })
                );
                await dispatch(
                    KeyQualificationFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualification
                            .thirdFilterId,
                    })
                );
                await dispatch(
                    KeyQualificationFilterFourth({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualification
                            .fourFilterId,
                    })
                );
                await dispatch(
                    KeyQualificationFilterFive({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualification
                            .fiveFilterId,
                    })
                );
                await dispatch(
                    KeyQualifyFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualify
                            .firstFilterId,
                    })
                );
                await dispatch(
                    KeyQualifyFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualify
                            .secondFilterId,
                    })
                );
                await dispatch(
                    KeyQualifyFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualify
                            .thirdFilterId,
                    })
                );
                await dispatch(
                    motivationFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.Motivation
                            .firstFilterId,
                    })
                );
                await dispatch(
                    motivationFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.Motivation
                            .secondFilterId,
                    })
                );
                await dispatch(
                    motivationFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.Motivation
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    JobDescriptionFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobDescription
                            .firstFilterId,
                    })
                );
                await dispatch(
                    JobDescriptionFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobDescription
                            .secondFilterId,
                    })
                );
                await dispatch(
                    JobDescriptionFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobDescription
                            .thirdFilterId,
                    })
                );
                await dispatch(
                    SalaryBenifitFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.SalaryBenifit
                            .firstFilterId,
                    })
                );
                await dispatch(
                    SalaryBenifitFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.SalaryBenifit
                            .secondFilterId,
                    })
                );
                await dispatch(
                    SalaryBenifitFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.SalaryBenifit
                            .thirdFilterId,
                    })
                );
                await dispatch(
                    JobReqFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
                            .firstFilterId,
                    })
                );
                await dispatch(
                    JobReqFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
                            .secondFilterId,
                    })
                );
                await dispatch(
                    JobReqFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
                            .thirdFilterId,
                    })
                );
                await dispatch(
                    JobReqFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
                            .firstFilterId,
                    })
                );
                await dispatch(
                    JobReqFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
                            .secondFilterId,
                    })
                );
                await dispatch(
                    JobReqFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    ReloactionQueryFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ReloactionQuery
                            .firstFilterId,
                    })
                );
                await dispatch(
                    ReloactionQueryFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ReloactionQuery
                            .secondFilterId,
                    })
                );
                await dispatch(
                    ReloactionQueryFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ReloactionQuery
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    ProjectStartDateFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ProjectStartDate
                            .firstFilterId,
                    })
                );
                await dispatch(
                    ProjectStartDateFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ProjectStartDate
                            .secondFilterId,
                    })
                );
                await dispatch(
                    ProjectStartDateFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ProjectStartDate
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    JoiningDocumentFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JoiningDocument
                            .firstFilterId,
                    })
                );
                await dispatch(
                    JoiningDocumentFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JoiningDocument
                            .secondFilterId,
                    })
                );
                await dispatch(
                    JoiningDocumentFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JoiningDocument
                            .thirdFilterId,
                    })
                );
                await dispatch(
                    InterviewProcessFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.InterviewProcess
                            .firstFilterId,
                    })
                );
                await dispatch(
                    InterviewProcessFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.InterviewProcess
                            .secondFilterId,
                    })
                );
                await dispatch(
                    InterviewProcessFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.InterviewProcess
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    FullResumePreviewFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.FullResumePreview
                            .firstFilterId,
                    })
                );
                await dispatch(
                    FullResumePreviewFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.FullResumePreview
                            .secondFilterId,
                    })
                );
                await dispatch(
                    FullResumePreviewFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.FullResumePreview
                            .thirdFilterId,
                    })
                );
                await dispatch(
                    OtherJobInterviewFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.OtherJobInterview
                            .firstFilterId,
                    })
                );
                await dispatch(
                    OtherJobInterviewFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.OtherJobInterview
                            .secondFilterId,
                    })
                );
                await dispatch(
                    OtherJobInterviewFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.OtherJobInterview
                            .thirdFilterId,
                    })
                );
                await dispatch(
                    ContactPreferenceFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ContactPreference
                            .firstFilterId,
                    })
                );
                await dispatch(
                    ContactPreferenceFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ContactPreference
                            .secondFilterId,
                    })
                );
                await dispatch(
                    ContactPreferenceFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ContactPreference
                            .thirdFilterId,
                    })
                );
                await dispatch(
                    screaningLevelFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.screningForm
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLevel5th_1({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fiveBlock
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLevel5th_2({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fiveBlock
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    firstLevel5th_3({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fiveBlock
                            .thirdDropdownId,
                    })
                );
                await dispatch(
                    firstLevel6th_1({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sixBlock
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLevel6th_2({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sixBlock
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    firstLevel6th_3({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sixBlock
                            .thirdDropdownId,
                    })
                );
                await dispatch(
                    firstLevel7th_1({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sevenBlock
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLevel7th_2({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sevenBlock
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    firstLevel7th_3({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sevenBlock
                            .thirdDropdownId,
                    })
                );
                await dispatch(
                    firstLevel7th_4({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sevenBlock
                            .fourthDropdownId,
                    })
                );
                await dispatch(
                    firstLevel8th_1({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.eightBlock
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLevel8th_2({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.eightBlock
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    firstLevel8th_3({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.eightBlock
                            .thirdDropdownId,
                    })
                );

                await dispatch(
                    screaningAvailability({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.screningForm
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    screaningContactPrefered({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.screningForm
                            .thirdDropdownId,
                    })
                );
                /*******New Blocks**** */
                await dispatch(
                    firstLvl9th_1({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ninethBlock
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLvl9th_2({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ninethBlock
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    firstLvl9th_3({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ninethBlock
                            .thirdDropdownId,
                    })
                );
                await dispatch(
                    firstLvl10th_1({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.tenthBlock
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLvl10th_2({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.tenthBlock
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    firstLvl10th_3({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.tenthBlock
                            .thirdDropdownId,
                    })
                );
                await dispatch(
                    firstLvl11th_1({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.eleventhBlock
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLvl11th_2({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.eleventhBlock
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    firstLvl11th_3({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.eleventhBlock
                            .thirdDropdownId,
                    })
                );
                await dispatch(
                    firstLvl12th_1({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.twelvethBlock
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLvl12th_2({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.twelvethBlock
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    firstLvl12th_3({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.twelvethBlock
                            .thirdDropdownId,
                    })
                );
                await dispatch(
                    firstLvl12th_4({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.twelvethBlock
                            .fourthDropdownId,
                    })
                );
                await dispatch(
                    firstLvl13th_1({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.thirteenBlock
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLvl13th_2({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.thirteenBlock
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    firstLvl13th_3({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.thirteenBlock
                            .thirdDropdownId,
                    })
                );
                await dispatch(
                    firstLvl14th_1({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fourteenBlock
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLvl14th_2({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fourteenBlock
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    firstLvl14th_3({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fourteenBlock
                            .thirdDropdownId,
                    })
                );
                await dispatch(
                    firstLvl15th_1({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fifteenBlock
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLvl15th_2({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fifteenBlock
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    firstLvl15th_3({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fifteenBlock
                            .thirdDropdownId,
                    })
                );
                await dispatch(
                    firstLvl15th_4({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fifteenBlock
                            .fourDropdownId,
                    })
                );
                await dispatch(
                    firstLvl15th_5({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fifteenBlock
                            .fiveDropdownId,
                    })
                );
                await dispatch(
                    firstLvl15th_6({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fifteenBlock
                            .sixDropdownId,
                    })
                );
                /****************** */
            } catch (err) {
                console.log(err);
            }
        };
        fetchList();
    }, []);  
   

    
const updateTextProfile = async(e) => {  
     const key = e.target.dataset.key;
    const value = e.target.value;  
    await dispatch( update1stScreaning({ id:candidateId, key, value }));
}
const updateProfile =  async (e) => {    
    const key = e.target.dataset.key;
    const value = e.target.dataset.value;    
    await dispatch( update1stScreaning({ id:candidateId, key, value }));
}


    return (
        <>
            <div className="dkg-cand-screening-mainCon ">
                <ul className="dkg-prescreen-listCon">
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[0].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[1].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[4].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${keyQualificationFirst == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {keyQualificationFirst}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {keyQualificationFirstList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "recruiter-introduction" + index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="key_qualification_1st_field"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setKeyQualificationFirst(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}     
                                                                
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[2].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[5].title : ""
                                                }
                                            />
                                            {/* <label className='dkg-mdiumsize-label'>Reason for this Phone Call</label> */}
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${keyQualificationSecond == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {keyQualificationSecond}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {keyQualificationSecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"applying-for-job" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="key_qualification_2nd_field"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setKeyQualificationSecond(
                                                                        item.value
                                                                    );
                                                                     updateProfile(e)
                                                                    }
                                                                }
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[3].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[6].title : ""
                                                }
                                            />
                                            {/* <label className='dkg-mdiumsize-label'>Confirm Interest in the Job</label> */}
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${keyQualificationThird == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {keyQualificationThird}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {keyQualificationThirdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "not-responsive-situation" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="key_qualification_3rd_field"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setKeyQualificationThird(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                  }
                                                                }
                                                                
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[210].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[212].title : ""
                                                }
                                            />
                                            {/* <label className='dkg-mdiumsize-label'>Confirm Interest in the Job</label> */}
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${keyQualificationFour == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {keyQualificationFour}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {keyQualificationFourList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "not-responsive-situation" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="key_qualification_4rt_field"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setKeyQualificationFour(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }
                                                                }
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[211].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[213].title : ""
                                                }
                                            />
                                            {/* <label className='dkg-mdiumsize-label'>Confirm Interest in the Job</label> */}
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${keyQualificationFive == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                > 
                                                    {keyQualificationFive}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {keyQualificationFiveList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "not-responsive-situation" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="key_qualification_5th_field"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setKeyQualificationFive(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }
                                                             }
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input                                                
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${keyQualificationNotes == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="key_qualification_notes"
                                                    data-value={keyQualificationNotes}
                                                onChange={(e) => {
                                                    setKeyQualificationNotes(e.target.value);
                                                    updateTextProfile(e)
                                                }
                                                }
                                                value={keyQualificationNotes}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={key_qualification_notes_2}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${key_qualification_notes_2 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="key_qualification_notes_2"
                                                    data-value={key_qualification_notes_2}
                                                    onChange={(e) => {
                                                    setKeyQualificationNotes_2(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />                                              
                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={key_qualification_notes_3}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${key_qualification_notes_3 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="key_qualification_notes_3"
                                                    data-value={key_qualification_notes_3}
                                                    onChange={(e) => {
                                                    setKeyQualificationNotes_3(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={key_qualification_notes_4}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${key_qualification_notes_4 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="key_qualification_notes_4"
                                                    data-value={key_qualification_notes_4}
                                                    onChange={(e) => {
                                                    setKeyQualificationNotes_4(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />                                                
                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={key_qualification_notes_5}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${key_qualification_notes_5 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="key_qualification_notes_5"
                                                    data-value={key_qualification_notes_5}
                                                    onChange={(e) => {
                                                    setKeyQualificationNotes_5(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            /> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[7].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[8].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[11].title : ""
                                                }
                                            />
                                            {/* <label className='dkg-mdiumsize-label'>Confirm Main Language Level</label> */}
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${keyQualifyFirst == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {keyQualifyFirst}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {keyQualifyFirstList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"main-lang-level" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="key_qualify_1st_field"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setKeyQualifyFirst(item.value);
                                                                    updateProfile(e)
                                                                    }
                                                                }
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[9].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[12].title : ""
                                                }
                                            />
                                            {/* <label className='dkg-mdiumsize-label'>Confirm the Nationality / VISA</label> */}
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${keyQualifySecond == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {keyQualifySecond}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {keyQualifySecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"nationality-visa" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="key_qualify_2nd_field"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setKeyQualifySecond(item.value);
                                                                    updateProfile(e)
                                                                    }
                                                                }
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[10].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[13].title : ""
                                                }
                                            />

                                            {/* <label className='dkg-mdiumsize-label'>Confirm the Current Location</label> */}

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${keyQualifyThird == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {keyQualifyThird}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {keyQualifyThirdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"current-location" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="key_qualify_3rd_field"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setKeyQualifyThird(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={keyQualifyNotes}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${keyQualifyNotes == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="key_qualify_notes_1"
                                                    data-value={keyQualifyNotes}
                                                    onChange={(e) => {
                                                    setKeyQualifyNotes(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />                                               
                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={key_qualify_notes_2}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${key_qualify_notes_2 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="key_qualify_notes_2"
                                                    data-value={key_qualify_notes_2}
                                                    onChange={(e) => {
                                                    setKeyQualifyNotes_2(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />                                                
                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={key_qualify_notes_3}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${key_qualify_notes_3 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="key_qualify_notes_3"
                                                    data-value={key_qualify_notes_3}
                                                    onChange={(e) => {
                                                    setKeyQualifyNotes_3(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />                                                
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[14].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[15].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[18].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${motivationFirst == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {motivationFirst}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {motivationFirstList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "leveing-current-job" + index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="motivation_1st_field"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setMotivationFirst(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[16].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[19].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${motivationSecond == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {motivationSecond}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {motivationSecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"urgently-need-job" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="motivation_2nd_field"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setMotivationSecond(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[17].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[20].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${motivationThird == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {motivationThird}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {motivationThirdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"long-looking-job" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="motivation_3rd_field"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setMotivationThird(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={motivationNotes}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${motivationNotes == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="motivation_notes"
                                                    data-value={motivationNotes}
                                                    onChange={(e) => {
                                                    setMotivationNotes(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />                                                
                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={motivation_notes_2}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${motivation_notes_2 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="motivation_notes_2"
                                                    data-value={motivation_notes_2}
                                                    onChange={(e) => {
                                                    setMotivationNotes_2(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />                                                
                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={motivation_notes_3}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${motivation_notes_3 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="motivation_notes_3"
                                                    data-value={motivation_notes_3}
                                                    onChange={(e) => {
                                                    setMotivationNotes_3(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />                                               
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[21].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[22].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[25].title : ""
                                                }
                                            />
                                            {/* <label className='dkg-mdiumsize-label'>Explain 2nd Level Screening</label> */}
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${screeningFirst == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {screeningFirst}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {screaningLevelList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "2nd-level-screening" + index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="screeningFirst"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setScreeningFirst(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[23].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[26].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${screeningAbliFirst == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {screeningAbliFirst}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {screaningListAvailability.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"screening-availity" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="screeningAbliFirst"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setScreeningAbliFirst(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[24].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[27].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${screeningPreContact == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {screeningPreContact}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {screaningPreferedList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="screeningPreContact"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setScreeningPreContact(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={notesScreeningFirst}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${notesScreeningFirst == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="notesScreeningFirst"
                                                    data-value={notesScreeningFirst}
                                                    onChange={(e) => {
                                                    setNotesScreeningFirst(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />                                                
                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={notesScreeningAbliFirst}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${notesScreeningAbliFirst == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="notesScreeningAbliFirst"
                                                    data-value={notesScreeningAbliFirst}
                                                    onChange={(e) => {
                                                    setNotesScreeningAbliFirst(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={notesScreeningPreContact}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${notesScreeningPreContact == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="notesScreeningPreContact"
                                                    data-value={notesScreeningPreContact}
                                                    onChange={(e) => {
                                                    setNotesScreeningPreContact(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />                                                
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[98].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[99].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[102].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelFive == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelFive}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLevelFiveList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "2nd-level-screening" + index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelFive"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelFive(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[100].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[103].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelFive_1 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelFive_1}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLevelFiveTwoList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"screening-availity" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelFive_1"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelFive_1(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[101].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[104].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelFive_2 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelFive_2}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLevelFiveThreeList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelFive_2"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelFive_2(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelFive_3}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelFive_3 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelFive_3"
                                                    data-value={firstLevelFive_3}
                                                    onChange={(e) => {
                                                    setFirstLevelFive_3(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />                                            
                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelFive_4}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelFive_4 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelFive_4"
                                                    data-value={firstLevelFive_4}
                                                    onChange={(e) => {
                                                    setFirstLevelFive_4(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />                                                
                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={firstLevelFive_5}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelFive_5 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelFive_5"
                                                    data-value={firstLevelFive_5}
                                                    onChange={(e) => {
                                                    setFirstLevelFive_5(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />                                                
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[105].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[106].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[109].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelSix == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelSix}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLevelSix1stList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "2nd-level-screening" + index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelSix"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelSix(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[107].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[110].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelSix_1 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelSix_1}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLevelSix2ndList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"screening-availity" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelSix_1"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelSix_1(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[108].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[111].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelSix_2 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelSix_2}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLevelSix3rdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelSix_2"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelSix_2(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelSix_3}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelSix_3 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelSix_3"
                                                    data-value={firstLevelSix_3}
                                                    onChange={(e) => {
                                                    setFirstLevelSix_3(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />                                               
                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelSix_4}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelSix_4 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelSix_4"
                                                    data-value={firstLevelSix_4}
                                                    onChange={(e) => {
                                                    setFirstLevelSix_4(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={firstLevelSix_5}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelSix_5 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelSix_5"
                                                    data-value={firstLevelSix_5}
                                                    onChange={(e) => {
                                                    setFirstLevelSix_5(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />                                               
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[112].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[113].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[116].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelSeven == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelSeven}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLevelSeven1stList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "2nd-level-screening" + index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelSeven"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelSeven(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[114].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[117].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelSeven_1 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelSeven_1}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLevelSeven2ndList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"screening-availity" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelSeven_1"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelSeven_1(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[115].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[118].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelSeven_2 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelSeven_2}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLevelSeven3rdtList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelSeven_2"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelSeven_2(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[214].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[215].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelSeven_7 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelSeven_7}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLevelSeven4rtList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelSeven_7"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelSeven_7(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelSeven_3}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelSeven_3 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelSeven_3"
                                                    data-value={firstLevelSeven_3}
                                                    onChange={(e) => {
                                                    setFirstLevelSeven_3(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />                                              
                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelSeven_4}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelSeven_4 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelSeven_4"
                                                    data-value={firstLevelSeven_4}
                                                    onChange={(e) => {
                                                    setFirstLevelSeven_4(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            /> 
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={firstLevelSeven_5}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelSeven_5 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelSeven_5"
                                                    data-value={firstLevelSeven_5}
                                                    onChange={(e) => {
                                                    setFirstLevelSeven_5(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={firstLevelSeven_6}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelSeven_6 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelSeven_6"
                                                    data-value={firstLevelSeven_6}
                                                    onChange={(e) => {
                                                    setFirstLevelSeven_6(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[119].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[120].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[123].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelEight}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLevelEight1stList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "2nd-level-screening" + index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelEight"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelEight(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[121].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[124].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_1 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelEight_1}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLevelEight2ndList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"screening-availity" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelEight_1"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelEight_1(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[122].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[125].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_2 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelEight_2}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLevelEight3rdtList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelEight_2"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelEight_2(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelEight_3}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelEight_3 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelEight_3"
                                                    data-value={firstLevelEight_3}
                                                onChange={(e) => {
                                                    setFirstLevelEight_3(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />                                            
                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelEight_4}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelEight_4 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelEight_4"
                                                    data-value={firstLevelEight_4}
                                                    onChange={(e) => {
                                                    setFirstLevelEight_4(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={firstLevelEight_5}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelEight_5 == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelEight_5"
                                                    data-value={firstLevelEight_5}
                                                onChange={(e) => {
                                                    setFirstLevelEight_5(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[126].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[127].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[130].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelNine == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelNine}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlNine1stList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "2nd-level-screening" + index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelNine"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelNine(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[128].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[131].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelNine_1 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelNine_1}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlNine2ndList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"screening-availity" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelNine_1"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelNine_1(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[129].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[132].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelNine_2 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelNine_2}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlNine3rdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelNine_2"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelNine_2(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelNine_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelNine_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelNine_txt"
                                                    data-value={firstLevelNine_txt}
                                                    onChange={(e) => {
                                                    setFirstLevelNine_3(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelNine_1_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelNine_1_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelNine_1_txt"
                                                    data-value={firstLevelNine_1_txt}
                                                    onChange={(e) => {
                                                    setFirstLevelNine_4(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={firstLevelNine_2_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelNine_2_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelNine_2_txt"
                                                    data-value={firstLevelNine_2_txt}
                                                onChange={(e) => {
                                                    setFirstLevelNine_5(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[133].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[134].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[137].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelTen == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelTen}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlTen1stList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "2nd-level-screening" + index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelTen"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelTen(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[135].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[138].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelTen_1 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelTen_1}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlTen2ndList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"screening-availity" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelTen_1"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelTen_1(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[136].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[139].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelTen_2 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelTen_2}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlTen3rdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelTen_2"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelTen_2(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelTen_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelTen_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelTen_txt"
                                                    data-value={firstLevelTen_txt}
                                                    onChange={(e) => {
                                                    setFirstLevelTen_3(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelTen_1_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelTen_1_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelTen_1_txt"
                                                    data-value={firstLevelTen_1_txt}
                                                    onChange={(e) => {
                                                    setFirstLevelTen_4(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={firstLevelTen_2_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelTen_2_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelTen_2_txt"
                                                    data-value={firstLevelTen_2_txt}
                                                onChange={(e) => {
                                                    setFirstLevelTen_5(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[140].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[141].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[144].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEleven == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelEleven}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlEleven1stList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "2nd-level-screening" + index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelEleven"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelEleven(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[142].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[145].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEleven_1 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelEleven_1}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlEleven2ndList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"screening-availity" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelEleven_1"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelEleven_1(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[143].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[146].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEleven_2 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelEleven_2}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlEleven3rdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelEleven_2"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelEleven_2(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelEleven_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelEleven_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelEleven_txt"
                                                    data-value={firstLevelEleven_txt}
                                                onChange={(e) => {
                                                    setFirstLevelEleven_3(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelEleven_1_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelEleven_1_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelEleven_1_txt"
                                                    data-value={firstLevelEleven_1_txt}
                                                onChange={(e) => {
                                                    setFirstLevelEleven_4(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={firstLevelEleven_2_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelEleven_2_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelEleven_2_txt"
                                                    data-value={firstLevelEleven_2_txt}
                                                onChange={(e) => {
                                                    setFirstLevelEleven_5(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[147].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[148].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[151].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelTwelve == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelTwelve}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlTwelve1stList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "2nd-level-screening" + index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelTwelve"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelTwelve(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[149].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[152].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelTwelve_1 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelTwelve_1}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlTwelve2ndList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"screening-availity" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelTwelve_1"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelTwelve_1(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[150].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[153].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelTwelve_2 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelTwelve_2}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlTwelve3rdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelTwelve_2"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelTwelve_2(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[216].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[217].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelTwelve_7 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelTwelve_7}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlTwelve4rthdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelTwelve_7"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelTwelve_7(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelTwelve_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelTwelve_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelTwelve_txt"
                                                    data-value={firstLevelTwelve_txt}
                                                onChange={(e) => {
                                                    setFirstLevelTwelve_3(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelTwelve_1_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelTwelve_1_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelTwelve_1_txt"
                                                    data-value={firstLevelTwelve_1_txt}
                                                onChange={(e) => {
                                                    setFirstLevelTwelve_4(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={firstLevelTwelve_2_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelTwelve_2_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelTwelve_2_txt"
                                                    data-value={firstLevelTwelve_2_txt}
                                                onChange={(e) => {
                                                    setFirstLevelTwelve_5(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                         <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={firstLevelTwelve_7_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelTwelve_7_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelTwelve_7_txt"
                                                    data-value={firstLevelTwelve_7_txt}
                                                onChange={(e) => {
                                                    setFirstLevelTwelve_6(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[154].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[155].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[158].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelThirteen == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelThirteen}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlThirteen1stList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "2nd-level-screening" + index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelThirteen"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelThirteen(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[156].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[159].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelThirteen_1 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelThirteen_1}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlThirteen2ndList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"screening-availity" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelThirteen_1"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelThirteen_1(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[157].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[160].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelThirteen_2 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelThirteen_2}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlThirteen3rdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelThirteen_2"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelThirteen_2(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelThirteen_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelThirteen_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelThirteen_txt"
                                                    data-value={firstLevelThirteen_txt}
                                                onChange={(e) => {
                                                    setFirstLevelThirteen_3(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelThirteen_1_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelThirteen_1_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelThirteen_1_txt"
                                                    data-value={firstLevelThirteen_1_txt}
                                                onChange={(e) => {
                                                    setFirstLevelThirteen_4(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={firstLevelThirteen_2_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelThirteen_2_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelThirteen_2_txt"
                                                    data-value={firstLevelThirteen_2_txt}
                                                onChange={(e) => {
                                                    setFirstLevelThirteen_5(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[161].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[162].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[165].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelFourteen == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelFourteen}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlFourteen1stList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "2nd-level-screening" + index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelFourteen"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelFourteen(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[163].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[166].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelFourteen_1 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelFourteen_1}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlFourteen2ndList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"screening-availity" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelFourteen_1"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelFourteen_1(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[164].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[167].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelFourteen_2 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelFourteen_2}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlFourteen3rdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelFourteen_2"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelFourteen_2(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelFourteen_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelFourteen_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelFourteen_txt"
                                                    data-value={firstLevelFourteen_txt}
                                                    onChange={(e) => {
                                                    setFirstLevelFourteen_3(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelFourteen_1_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelFourteen_1_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelFourteen_1_txt"
                                                    data-value={firstLevelFourteen_1_txt}
                                                onChange={(e) => {
                                                    setFirstLevelFourteen_4(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={firstLevelFourteen_2_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelFourteen_2_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelFourteen_2_txt"
                                                    data-value={firstLevelFourteen_2_txt}
                                                onChange={(e) => {
                                                    setFirstLevelFourteen_5(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[168].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[169].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[172].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelFifteen == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelFifteen}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlFifteen1stList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "2nd-level-screening" + index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelFifteen"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelFifteen(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[170].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[173].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelFifteen_1 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelFifteen_1}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlFifteen2ndList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"screening-availity" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelFifteen_1"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelFifteen_1(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[171].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[174].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelFifteen_2 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelFifteen_2}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlFifteen3rdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelFifteen_2"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelFifteen_2(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        {/* new block */}
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[220].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[221].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelFifteen_4 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelFifteen_4}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlFifteen4rtList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelFifteen_4"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelFifteen_4(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[222].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[223].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelFifteen_5 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelFifteen_5}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlFifteen5thList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelFifteen_5"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelFifteen_5(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[224].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[225].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelFifteen_6 == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {firstLevelFifteen_6}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {firstLvlFifteen6thList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "prefrred-contact-method" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="firstLevelFifteen_6"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setFirstLevelFifteen_6(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        {/*  */}
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelFifteen_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelFifteen_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelFifteen_txt"
                                                    data-value={firstLevelFifteen_txt}
                                                    onChange={(e) => {
                                                    setFirstLevelFifteen_3(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelFifteen_1_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelFifteen_1_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelFifteen_1_txt"
                                                    data-value={firstLevelFifteen_1_txt}
                                                onChange={(e) => {
                                                    setFirstLevelFifteen1txt(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelFifteen_2_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelFifteen_2_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelFifteen_2_txt"
                                                    data-value={firstLevelFifteen_2_txt}
                                                onChange={(e) => {
                                                    setFirstLevelFifteen2txt(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        {/* new Block */}
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelFifteen4_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelFifteen4_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelFifteen_4_txt"
                                                    data-value={firstLevelFifteen4_txt}
                                                onChange={(e) => {
                                                    setFirstLevelFifteen4txt(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                value={firstLevelFifteen_5_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelFifteen_5_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelFifteen_5_txt"
                                                    data-value={firstLevelFifteen_5_txt}
                                                onChange={(e) => {
                                                    setFirstLevelFifteen5txt(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                value={firstLevelFifteen_6_txt}
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${firstLevelFifteen_6_txt == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="firstLevelFifteen_6_txt"
                                                    data-value={firstLevelFifteen_6_txt}
                                                onChange={(e) => {
                                                    setFirstLevelFifteen6txt(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    
                </ul>
            </div>
        </>
    )
}

export default FirstScreening