import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const FlagFilter = ({ list, id, img, selectUserFilter }) => {
    return (
        <React.Fragment>
            <Dropdown>
                <Dropdown.Toggle variant="" className="tableFilterRoundButton">
                    {
                        (id === '0') ? <i className='fas fa-flag'></i> : <i className='fas fa-flag' style={{ color: img }}></i>
                    }
                </Dropdown.Toggle>
                <Dropdown.Menu className='dk-lhsflagnoDropMenu'>
                    <div className="d-flex">
                        <Dropdown.Item href="#" data-type="FlagID" data-id="1" data-img="#d0cece" onClick={selectUserFilter}>
                            <i className="fas fa-flag" style={{ color: "#d0cece" }} data-type="FlagID" data-id="1" data-img="#d0cece" onClick={selectUserFilter}></i>
                            <span className="recNoCount" data-type="FlagID" data-id="1" data-img="#d0cece" onClick={selectUserFilter}>
                                {
                                    list.filter(function (val) {
                                        return val.original.flag_type === 1;
                                    }).length
                                }
                            </span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" data-type="FlagID" data-id="2" data-img="green" onClick={selectUserFilter}>
                            <i className="fas fa-flag" style={{ color: "green" }} data-type="FlagID" data-id="2" data-img="green" onClick={selectUserFilter}></i>
                            <span className="recNoCount" data-type="FlagID" data-id="2" data-img="green" onClick={selectUserFilter}>
                                {
                                    list.filter(function (val) {
                                        return val.original.flag_type === 2;
                                    }).length
                                }
                            </span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" data-id="3" data-img="red" onClick={selectUserFilter}>
                            <i className="fas fa-flag" style={{ color: "red" }} data-id="3" data-img="red" onClick={selectUserFilter}></i>
                            <span className="recNoCount" data-id="3" data-img="red" onClick={selectUserFilter}>
                                {
                                    list.filter(function (val) {
                                        return val.original.flag_type === 3;
                                    }).length
                                }
                            </span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" data-id="4" data-img="eaa03d" onClick={selectUserFilter}>
                            <i className="fas fa-flag" style={{ color: "#eaa03d" }} data-id="4" data-img="eaa03d" onClick={selectUserFilter}></i>
                            <span className="recNoCount" data-id="4" data-img="eaa03d" onClick={selectUserFilter}>
                                {
                                    list.filter(function (val) {
                                        return val.original.flag_type === 4;
                                    }).length
                                }
                            </span>
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    )
}
export default FlagFilter;