import React from 'react';
import Header from "../elements/header";
import ClientsInfoMainMenu from "../elements/header/navbar/mainMenu/clientsinfomenu/ClientsInfoMainMenu";

const ClienstInfoLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header mainheaderLink="DK GLOBAL RECRUITMENT" moduleLink="/dashboard/recruitment" linkcolor="#2e97b6" mainheaderTitle=" - Client Info" textcolor="#258FB4" />
            <ClientsInfoMainMenu />
            {children}
        </React.Fragment>
    )
}
export default ClienstInfoLayout;