import React from 'react';
import { Modal } from 'react-bootstrap';
import Submit from '../../../../../../ui/submitButton'

const DeleteDummy = ({ onHide }) => {
    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-adstarckDeletePop"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Body className="dk-adstrackDelBody">
                    <p>Are you sure wants to Delete these records ?</p>
                </Modal.Body>
                <Modal.Footer className="dk-adstrackDelFooter">
                    <button className="btn dk-nodelBtn">No</button>
                    <Submit txt="Yes" position="justify-content-center" className="btn dk-yesdelBtn" />
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default DeleteDummy;
