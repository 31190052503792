import React from "react";
import "./status.scss";

const tableStatus = ({ value }) => {
  let bgColor = "";
  switch (value) {
    case "Active":
      bgColor = "#30887e";
      break;

    case "Inactive":
      bgColor = "#e2445c";
      break;
  }

  return (
    <>
      <div
        className="caseDiv"
        style={{ background: bgColor, minHeight: "32px", fontSize: "11px" }}
      >
        {value}
      </div>
    </>
  );
};

export default tableStatus;
