import React from 'react';
import Header from "../elements/header";
import Navbar from "../elements/header/navbar/mainMenu/DKGCloudMenu/index.js";

const DKGCloudLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" linkcolor="#3c97b6" mainheaderTitle="DK Global" textcolor="#3c4c62" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default DKGCloudLayout;