import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  lazy,
  Suspense,
} from "react";
import { Link } from "react-router-dom";
import {
  ButtonGroup,
  Button,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "../livejobs/jobboardtable.scss";
import "../livejobs/userpicker/grid-ampicker.scss";
import Logo from "../../../../assets/images/default-company-logo.png";
import DetialViewPoup from "../livejobs/modal/DetialViewPoup.js";
import EditdetialviewPoup from "../livejobs/modal/EditdetialviewPoup.jsx";
import SmallDetialViewPoup from "../livejobs/modal/SmallDetialViewPoup";
import RightModalFilter1 from "../livejobs/RightModalFilter1";
import { useDispatch, useSelector } from "react-redux";
import {
  jobList,
  updateJob,
  clearState,
} from "../../../../slice/jobs/inactiveJobsSlice";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
  useAsyncDebounce,
} from "react-table";
//import Pagination from "@material-ui/lab/Pagination";
import UserList from "../../../ui/userList/userListByRole";
//import Status from "./dropdown/status";
import GridStatus from "../livejobs/dropdown//status/gridStatus";
import JobStatusList from "../../../ui/dropdownList/JobStatusList";
import { FaEye } from "react-icons/fa";
//import RMFilter from "./tableFilter/users/rm";
// import AMFilter from "./tableFilter/users/am";
// import RAFilter from "./tableFilter/users/ra";
// import RCFilter from "./tableFilter/users/rc";
//import CustomDateFormat from "../../../ui/dateFormats/CustomDateFormat";
import TableLoader from "../../../ui/tableLoader";
import moment from "moment-timezone";
import ApplyjobPoup from "../livejobs/modal/apply/ApplyjobPoup";
import JobsFaqPopup from "../jobfaq/JobsFaqPopup.jsx";

//import CommentModal from "../../../utils/comment";

const TableSuspence = lazy(() =>
  import("../../../ui/tableLoader/tableSuspence")
);

const useInstance = (instance) => {
  if (instance && instance.getInstanceCallback) {
    instance.getInstanceCallback(instance);
  }
};

function Table({
  columns,
  data,
  getInstanceCallback,
  rmFilterId,
  raFilterId,
  amFilterId,
  prFilterId,
  categoryId,
  localityId,
  companyId,
  industryId,
  languageId,
  workFromId,
  workPermitId,
  workTypeId,
  projectId,
  roleId,
  cityId,
  hireId,
  statusId,
  rcFilterId,
  dateId,
  jobSpecId,
  jobStatusId,
  loading,
  countryId,
  jobprofileId,
  updated_onId,
  createdAtId,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    prepareRow,
    //state,
    setFilter,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      getInstanceCallback,
      autoResetFilters: false,
      autoResetRowState: false,
      initialState: {
        pageIndex: 0,
        pageSize: 400,
        hiddenColumns: [
          "RMId",
          "RAId",
          "AMId",
          "PRId",
          "RCId",
          "date",
          "sn",
          "status",
          "no_of_jobs",
          "company",
          "industry",
          "lang",
          "project",
          "role",
          "category",
          "locality",
          "country",
          "location",
          "fee",
          "job_id",
          "job_profile",
          "updated_on",
          "createdAt",
          "workplace",
          "work_permit",
          "worktype",
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    (hooks) => hooks.useInstance.push(useInstance)
  );

  // const handlePageChange = (event, value) => {
  //   gotoPage(Number(value) - 1);
  // };

  const [filterType, setFilterType] = useState("");
  useEffect(() => {
    if (rmFilterId === "0") {
      setTimeout(() => {
        setFilter("RMId", "");
      }, 200);
    } else {
      setFilterType("RMId");
      doFilter(rmFilterId);
    }
  }, [rmFilterId]);

  useEffect(() => {
    if (raFilterId === "0") {
      setTimeout(() => {
        setFilter("RAId", "");
      }, 200);
    } else {
      setFilterType("RAId");
      doFilter(raFilterId);
    }
  }, [raFilterId]);

  useEffect(() => {
    if (amFilterId === "0") {
      setTimeout(() => {
        setFilter("AMId", "");
      }, 200);
    } else {
      setFilterType("AMId");
      doFilter(amFilterId);
    }
  }, [amFilterId]);

  useEffect(() => {
    if (prFilterId === "0") {
      setTimeout(() => {
        setFilter("PRId", "");
      }, 200);
    } else {
      setFilterType("PRId");
      doFilter(prFilterId);
    }
  }, [prFilterId]);

  useEffect(() => {
    if (categoryId === "0") {
      setTimeout(() => {
        setFilter("category", "");
      }, 200);
    } else {
      setFilterType("category");
      doFilter(categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    if (countryId === "0") {
      setTimeout(() => {
        setFilterType("country");
        setFilter("country", "");
      }, 200);
    } else {
      setFilterType("country");
      doFilter(countryId);
    }
  }, [countryId]);

  useEffect(() => {
    if (localityId === "0") {
      setTimeout(() => {
        setFilter("locality", "");
      }, 200);
    } else {
      setFilterType("locality");
      doFilter(localityId);
    }
  }, [localityId]);

  useEffect(() => {
    if (industryId === "0") {
      setTimeout(() => {
        setFilter("industry", "");
      }, 200);
    } else {
      setFilterType("industry");
      doFilter(industryId);
    }
  }, [industryId]);

  useEffect(() => {
    if (companyId === "0") {
      setTimeout(() => {
        setFilter("company", "");
      }, 200);
    } else {
      setFilterType("company");
      doFilter(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (workTypeId === 0) {
      setTimeout(() => {
        setFilter("worktype", "");
      }, 200);
    } else {
      setFilterType("worktype");
      doFilter(workTypeId);
    }
  }, [workTypeId]);

  useEffect(() => {
    if (workPermitId === 0) {
      setTimeout(() => {
        setFilter("work_permit", "");
      }, 200);
    } else {
      setFilterType("work_permit");
      doFilter(workPermitId);
    }
  }, [workPermitId]);

  useEffect(() => {
    if (workFromId === "0") {
      setTimeout(() => {
        setFilter("workplace", "");
      }, 200);
    } else {
      setFilterType("workplace");
      doFilter(workFromId);
    }
  }, [workFromId]);

  useEffect(() => {
    if (languageId === "0") {
      setTimeout(() => {
        setFilter("lang", "");
      }, 200);
    } else {
      setFilterType("lang");
      doFilter(languageId);
    }
  }, [languageId]);

  useEffect(() => {
    if (projectId === "0") {
      setTimeout(() => {
        setFilter("project", "");
      }, 200);
    } else {
      setFilterType("project");
      doFilter(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (roleId === "0") {
      setTimeout(() => {
        setFilter("role", "");
      }, 200);
    } else {
      setFilterType("role");
      doFilter(roleId);
    }
  }, [roleId]);

  useEffect(() => {
    if (cityId === "0") {
      setTimeout(() => {
        setFilter("location", "");
      }, 200);
    } else {
      setFilterType("location");
      doFilter(cityId);
    }
  }, [cityId]);

  useEffect(() => {
    if (jobprofileId === "0") {
      setTimeout(() => {
        setFilter("job_profile", "");
      }, 200);
    } else {
      setFilterType("job_profile");
      doFilter(jobprofileId);
    }
  }, [jobprofileId]);

  useEffect(() => {
    if (updated_onId === "0") {
      setTimeout(() => {
        setFilter("updated_on", "");
      }, 200);
    } else {
      setFilterType("updated_on");
      doFilter(updated_onId);
    }
  }, [updated_onId]);

  useEffect(() => {
    if (createdAtId === "0") {
      setTimeout(() => {
        setFilter("createdAt", "");
      }, 200);
    } else {
      setFilterType("createdAt");
      doFilter(createdAtId);
    }
  }, [createdAtId]);

  useEffect(() => {
    if (hireId === "0") {
      setTimeout(() => {
        setFilter("fee", "");
      }, 200);
    } else {
      setFilterType("fee");
      doFilter(hireId);
    }
  }, [hireId]);

  useEffect(() => {
    if (statusId === "0") {
      setTimeout(() => {
        setFilter("status", "");
      }, 200);
    } else {
      setFilterType("status");
      doFilter(statusId);
    }
  }, [statusId]);

  useEffect(() => {
    if (rcFilterId === "0") {
      setTimeout(() => {
        setFilter("RCId", "");
      }, 200);
    } else {
      setFilterType("RCId");
      doFilter(rcFilterId);
    }
  }, [rcFilterId]);

  useEffect(() => {
    if (dateId === "0") {
      setTimeout(() => {
        setFilter("date", "");
      }, 200);
    } else {
      setFilterType("date");
      doFilter(dateId);
    }
  }, [dateId]);

  useEffect(() => {
    if (jobStatusId === "0") {
      setTimeout(() => {
        setFilter("status", "");
      }, 200);
    } else {
      setFilterType("status");
      doFilter(jobStatusId);
    }
  }, [jobStatusId]);

  const doFilter = useCallback(
    useAsyncDebounce((id) => {
      setFilter(filterType, id);
    }, 200),
    [filterType]
  );
  return (
    <React.Fragment>
      <table className="table dk-clBoardViewTable" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return loading ? (
              [...Array(10)].map((e, i) => (
                <tr>
                  <TableLoader colSpan="20" />
                </tr>
              ))
            ) : (
              <Suspense fallback={<TableSuspence colSpan="20" />}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className, // pay attention to this
                          style: cell.column.style,
                          // set here your other custom props
                        },
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              </Suspense>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td {...column.getFooterProps()}>{column.render("Footer")}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      {/* <div className="col-sm-12">
                <div className="dk-resPaginationCon pagination">
                    <div className="dk-resPagination">
                        <div className="dk-resLeftPag">
                            <div className="dk-texCounter" style={{ color: '#fff !imporant' }}>
                                Showing {' '}{pageIndex + 1} to {pageOptions.length} (of {rows.length} entries)
                            </div>
                        </div>
                        <div className="dk-resrightPag pagination-btns">
                            <Pagination count={pageCount} shape="rounded" onChange={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div> */}
    </React.Fragment>
  );
}

function debounce(func, wait, immediate) {
  var timeout;

  return (...args) => {
    var context = this;

    var later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isClearAll,
  restClearAllFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState("");

  // const [value, setValue] = React.useState(globalFilter)
  // const onChange = useAsyncDebounce(value => {
  //     setGlobalFilter(value || undefined)
  // }, 200)

  const onChangeSearchTitle = (e) => {
    const { value: nextValue } = e.target;
    setValue(e.target.value);
    debouncedSave(nextValue);
  };

  const debouncedSave = React.useCallback(
    debounce((nextValue) => {
      setGlobalFilter(nextValue);
    }, 1000)
  );

  const clearSearch = () => {
    setValue("");
    setTimeout(() => {
      setGlobalFilter(undefined);
    }, 200);
  };

  useEffect(() => {
    if (isClearAll) {
      clearSearch();
      restClearAllFilter();
    }
  }, [isClearAll]);

  return (
    <React.Fragment>
      <input
        value={value}
        className="form-control"
        onChange={onChangeSearchTitle}
        placeholder={`Search...`}
        onFocus={(e) => (e.target.placeholder = "")}
        onBlur={(e) => (e.target.placeholder = "Search...")}
      />
      {value !== undefined && value.length > 0 ? (
        <div className="input-group-append">
          <button
            className="btn btn-secondary"
            type="button"
            onClick={clearSearch}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
      ) : (
        <div className="input-group-append">
          <button className="btn btn-secondary" type="button">
            <i className="fa fa-search"></i>
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

const LiveJobs = () => {
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isUpdate, message, jobseDataList } =
    useSelector((state) => state.inactivejobs);

  const [data, setData] = useState([]);

  const [jobId, setjobId] = useState(0);

  const [show, setShow] = useState(false);

  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);

  const [list, setList] = useState([]);

  useEffect(() => {
    document.title = "Live Jobs | Recruitment | DK Global CRM";
  }, []);

  useEffect(() => {
    const fetchList = async () => {
      try {
        await dispatch(jobList({ isDelete: 1 }));
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  }, []);

  useEffect(() => {
    if (!isLoading && jobseDataList.length > 0) {
      setTimeout(() => {
        setData(jobseDataList);
      }, 200);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobseDataList);
    }
  }, [isUpdate]);

  const [isProfile, setIsProfile] = useState(false);
  const [isJobInfo, setIsJobInfo] = useState(false);
  const [editShow, setEditShow] = useState(false);

  const [isRightFilter, setIsRightFilter] = useState(false);

  const [totalJobsNumber, setTotalJobsNumber] = useState([]);
  const handleEditClose = () => setEditShow(false);

  // const status = [
  //   {
  //     key: "normal",
  //     value: "Active",
  //     text: "Active",
  //   },
  //   {
  //     key: "urgent",
  //     value: "Urgent",
  //     text: "Urgent",
  //   },
  //   {
  //     key: "to qualify",
  //     value: "To qualify",
  //     text: "To qualify",
  //   },
  //   {
  //     key: "on hold",
  //     value: "On Hold",
  //     text: "On Hold",
  //   },
  //   {
  //     key: "closed",
  //     value: "Closed",
  //     text: "Closed",
  //   },
  // ];

  const columns = useMemo(
    () => [
      {
        id: "RMId",
        Header: "RM Id",
        accessor: "recRM.uid",
      },
      {
        id: "RAId",
        Header: "RA Id",
        accessor: "recRA.uid",
      },
      {
        id: "AMId",
        Header: "AM Id",
        accessor: "recAM.uid",
      },
      {
        id: "PRId",
        Header: "PR Id",
        accessor: "priority",
      },
      {
        id: "date",
        Header: "Date",
        accessor: (row) => row.date1 + " " + row.date1,
      },
      {
        id: "RCId",
        Header: "RC ID",
        accessor: (row) =>
          row.assignWithjob.map((item, index) => item.userInfo.uid),
      },
      {
        id: "status",
        Header: "Status",
        accessor: "status",
      },
      {
        id: "job_id",
        Header: "Job ID",
        accessor: "job_id",
      },
      {
        id: "workplace",
        Header: "Work From",
        accessor: (row) => (row.workplace == "" ? "Blank" : row.workplace),
      },
      {
        id: "worktype",
        Header: "Work Type",
        accessor: (row) => (row.worktype == "" ? "Blank" : row.worktype),
      },
      {
        id: "work_permit",
        Header: "Work Permit",
        accessor: (row) => (row.work_permit == "" ? "Blank" : row.work_permit),
      },
      {
        id: "job_profile",
        Header: "Job Profile",
        accessor: (row) => (row.job_profile == "" ? "Blank" : row.job_profile),
      },
      {
        id: "updated_on",
        Header: "updated_on",
        accessor: (row) => moment(moment(row.updated_on)).format("DD MMM, YY"),
      },
      {
        id: "createdAt",
        Header: "createdAt",
        accessor: (row) => moment(moment(row.createdAt)).format("DD MMM, YY"),
      },
      {
        id: "image",
        Header: "",
        accessor: "name",
        Footer: (info) => {
          setTotalJobsNumber(info.page);
          return "";
        },
        Cell: ({ cell }) => {
          const handleShow = (e) => {
            console.log(e.target.dataset.id);
            setjobId(e.target.dataset.id);
            setIsProfile(true);
            setShow(true);
          };
          const handleShow3 = (e) => {
            // setjobId(e.target.dataset.id);
            // setCompanyName(cell.row.original.company);
            // setCanjobs(cell.row.original.job_id);
            // setClientidds(cell.row.original.companyDetails.clientId);
            setIsJobInfo(true);
            setEditShow(true);
            // dispatch(fetechById({ id: cell.row.original.job_id }));
          };
          const showrightFilter = (e) => {
            setjobId(e.target.dataset.id);
            setIsRightFilter(true);
            setShow1(true);
          };
          const onItemClick = (id, columnId, value) => {
            updateMyData(id, columnId, value);
          };

          const onDateClick = (e) => {
            updateMyData(
              e.target.dataset.id,
              e.target.dataset.column,
              moment().utc().format("YYYY-MM-DD HH:mm:ss")
            );
          };

          let allvalue = {};
          const onItemClick2 = (id, columnId, value) => {
            allvalue = {
              id: value,
              type: "assignUpdate",
              jobid: cell.row.original.id,
              value: value,
            };
            updateMyData(id, columnId, allvalue);
          };
          const onItemClick1 = (id, columnId, value) => {
            allvalue = {
              id: value,
              type: "assign",
              jobid: cell.row.original.id,
              value: value,
            };
            updateMyData(allvalue.id, columnId, allvalue);
          };
          var erf = "";
          if (cell.row.original.erf != "-" && cell.row.original.erf != "") {
            erf = " - ERF " + cell.row.original.erf;
          }

          const listItems = cell.row.original.assignWithjob.length ? (
            cell.row.original.assignWithjob.map((item, index) =>
              index === 0 ? (
                <Dropdown className="dk-AmDropDwn" key={index}>
                  <Dropdown.Toggle
                    variant=""
                    className="dk-AmDropDwnToggle mr-1"
                  >
                    <div className="tooltipMain">
                      {/* <OverlayTrigger
                        overlay={(props) => <Tooltip {...props}>RC 1</Tooltip>}
                        placement="bottom"
                      >
                        <div>
                          <img
                            src={
                              process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              item.userInfo.profileImage
                            }
                            className="filterImg"
                            alt={
                              item.userInfo.firstName + item.userInfo.lastName
                            }
                          />{" "}
                        </div>
                      </OverlayTrigger> */}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dk-AmDropDwnMenu">
                    <div className="d-flex">
                      <UserList
                        id={item.id}
                        columnId="user_id"
                        onItemClick={onItemClick2}
                      />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                ""
              )
            )
          ) : (
            <React.Fragment>
              <Dropdown className="dk-AmDropDwn">
                <Dropdown.Toggle variant="" className="dk-AmDropDwnToggle mr-1">
                  <div className="tooltipMain">
                    <OverlayTrigger
                      overlay={(props) => <Tooltip {...props}>RC 1</Tooltip>}
                      placement="bottom"
                    >
                      <div>
                        <img
                          src={
                            process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            "/users/images/user.png"
                          }
                          className="filterImg"
                          alt="No User Selected"
                        />{" "}
                      </div>
                    </OverlayTrigger>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dk-AmDropDwnMenu">
                  <div className="d-flex">
                    <UserList
                      id="-1"
                      columnId="user_id"
                      onItemClick={onItemClick1}
                    />
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </React.Fragment>
          );
          return (
            <React.Fragment>
              <div className="d-flex justify-content-center m-0 row">
                <div className="col-md-12">
                  <div className="row grid-table-row border rounded dkg-livejobs-two-card">
                    <div className="col-md-2 pl-0">
                      <div className="align-items-center logoBox">
                        <div className="d-flex align-items-center dk-job-status-back justify-content-center logoBoxH">
                          <img
                            className="img-fluid img-responsive rounded company-image"
                            src={
                              cell.row.original.companyDetails
                                ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                  cell.row.original.companyDetails.logo
                                : Logo
                            }
                          />
                          {/* <img className="img-fluid img-responsive rounded company-image" src={Logo} /> */}
                        </div>
                        <div className="d-flex align-items-center justify-content-center logoBoxText ddd">
                          <div className="col-md-12 p-0  d-flex align-items-center justify-content-center">
                            <Dropdown
                              className="dk-freqDropDwon"
                              style={{ position: "absolute", bottom: "0" }}
                            >
                              <GridStatus value={cell.row.original.status} />
                              <Dropdown.Menu className="dk-freqDrpMenu">
                                <JobStatusList
                                  id={cell.row.original.id}
                                  columnId="status"
                                  onItemClick={onItemClick}
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          {/* <div className="col-md-6 p-0  d-flex align-items-center justify-content-center"> */}
                          {/* <OverlayTrigger
                              overlay={(props) => (
                                <Tooltip {...props}>Total Jobs</Tooltip>
                              )}
                              placement="left"
                            >
                              <div>
                                <span className="date-hover">
                                  {" "}
                                  {cell.row.original.no_of_jobs}
                                </span>
                              </div>
                            </OverlayTrigger> */}

                          {/* <CommentModal
                              refTable="livejobs"
                              refId={cell.row.original.id}
                              count={cell.row.original.commentCount}
                              title="Live Jobs Comments"
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7 p-0 mt-2 mb-1">
                      <h5
                        className="pb-1 d-flex w-100"
                        style={{
                          color: "#396A95",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          className="job-title mb-2 text-black font-weight-bold"
                          data-id={cell.row.original.id}
                          onClick={handleShow3}

                        >
                          {cell.row.original.name + erf}

                          <OverlayTrigger
                            overlay={(props) => (
                              <Tooltip {...props}>Total Jobs</Tooltip>
                            )}
                            placement="left"
                          >
                            <div>
                              <span className="date-hover">
                                {" "}
                                ({cell.row.original.no_of_jobs})
                              </span>
                            </div>
                          </OverlayTrigger>
                        </span>

                        <OverlayTrigger
                          overlay={(props) => (
                            <Tooltip {...props}>Job Updated</Tooltip>
                          )}
                          placement="top"
                        >
                          <span
                            className="float-right mr-4"
                            style={{
                              fontSize: "13px",
                              color: "#396a95",
                              cursor: "pointer",
                            }}
                            data-id={cell.row.original.id}
                            data-column="updated_on"
                            onClick={onDateClick}
                          >
                            Updated -{" "}
                            {moment(
                              moment(cell.row.original.updated_on)
                            ).fromNow()}
                          </span>
                        </OverlayTrigger>
                      </h5>
                      {/* <span className="float-right mr-2" onClick={handleShow} data-id={cell.row.original.id} style={{ fontSize: "14px", color: "#000" }}><CustomDateFormat date={cell.row.original.createdAt} format='DD MMM, YY' /></span> */}

                      {/* - <span className="badge" onClick={handleShow} data-id={cell.row.original.id} style={{ background: "#396a95", boxShadow: "none", borderRadius: "3px", position: "relative", top: "-2px" }}>{cell.row.original.no_of_jobs}</span> */}

                      <div className="row">
                        <div className="col-md-3 pr-0 mb-2 spec-1">
                          <OverlayTrigger
                            overlay={(props) => (
                              <Tooltip {...props}>Company</Tooltip>
                            )}
                            placement="left"
                          >
                            <div>
                              {" "}
                              <span>
                                <i className="far fa fa-building"></i>
                              </span>
                              <span className="date-hover">
                                {" "}
                                {cell.row.original.company}
                              </span>
                            </div>
                          </OverlayTrigger>
                        </div>
                        <div className="col-md-3 p-0 mb-2 spec-1">
                          <OverlayTrigger
                            overlay={(props) => (
                              <Tooltip {...props}>Project</Tooltip>
                            )}
                            placement="left"
                          >
                            <div>
                              {" "}
                              <span>
                                <i className="far fa fa-suitcase"></i>
                              </span>
                              <span className="date-hover">
                                {" "}
                                {cell.row.original.project}
                              </span>
                            </div>
                          </OverlayTrigger>
                        </div>
                        <div className="col-md-3 p-0 mb-2 spec-1">
                          <OverlayTrigger
                            overlay={(props) => (
                              <Tooltip {...props}>Role Type</Tooltip>
                            )}
                            placement="left"
                          >
                            <div>
                              {" "}
                              <span>
                                <i className="far fa fa-user"></i>
                              </span>
                              <span className="date-hover">
                                {" "}
                                {cell.row.original.role}
                              </span>
                            </div>
                          </OverlayTrigger>
                        </div>
                        <div className="col-md-3 p-0 mb-2 spec-1">
                          <OverlayTrigger
                            overlay={(props) => (
                              <Tooltip {...props}>Language</Tooltip>
                            )}
                            placement="left"
                          >
                            <div>
                              {" "}
                              <span>
                                <i className="far fa fa-language"></i>
                              </span>
                              <span className="date-hover">
                                {" "}
                                {cell.row.original.lang}
                              </span>
                            </div>
                          </OverlayTrigger>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-3 mb-2 spec-1">
                          <OverlayTrigger
                            overlay={(props) => (
                              <Tooltip {...props}>Gross Salary</Tooltip>
                            )}
                            placement="left"
                          >
                            <div>
                              {" "}
                              <span>
                                <i className="far fa-money-bill-alt"></i>
                              </span>
                              <span className="date-hover">
                                {" "}
                                {cell.row.original.annual_gross}
                              </span>
                            </div>
                          </OverlayTrigger>
                        </div>

                        <div className="col-md-3 p-0 mb-2 spec-1">
                          <OverlayTrigger
                            overlay={(props) => (
                              <Tooltip {...props}>Net Salary</Tooltip>
                            )}
                            placement="left"
                          >
                            <div>
                              {" "}
                              <span>
                                <i className="far fa-money-bill-alt"></i>
                              </span>
                              <span className="date-hover">
                                {" "}
                                {cell.row.original.average_net_pm}
                              </span>
                            </div>
                          </OverlayTrigger>
                        </div>

                        <div className="col-md-3 p-0 mb-2 spec-1">
                          <OverlayTrigger
                            overlay={(props) => (
                              <Tooltip {...props}>Work From</Tooltip>
                            )}
                            placement="left"
                          >
                            <div>
                              {" "}
                              <span>
                                <i className="fa fa-map-marker-alt"></i>
                              </span>
                              <span className="date-hover">
                                {" "}
                                {cell.row.original.workplace}
                              </span>
                            </div>
                          </OverlayTrigger>
                        </div>

                        <div className="col-md-3 p-0 mb-2 spec-1">
                          <OverlayTrigger
                            overlay={(props) => (
                              <Tooltip {...props}>Start Date 1</Tooltip>
                            )}
                            placement="left"
                          >
                            <div>
                              {" "}
                              <span>
                                <i className="far fa fa-calendar"></i>
                              </span>
                              <span className="date-hover">
                                {" "}
                                {cell.row.original.date1}
                              </span>
                            </div>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                    <div className="align-items-center align-content-center col-md-1 p-0">
                      <div
                        className="d-flex align-items-center justify-content-center grid-table-box"
                        style={{ cursor: "unset" }}
                      >
                        {/* <CommentModal
                          refTable="livejobs"
                          refId={cell.row.original.id}
                          count={cell.row.original.commentCount}
                          title="Live Jobs Comments"
                        /> */}
                        <Link
                          to="#"
                          data-id={cell.row.original.id}
                          className="dkg-livejobs-View-btn"
                          onClick={handleShow}
                        >
                          <span className="dkg-livejobs-icon"> </span> View
                        </Link>
                      </div>

                      {/* <div onClick={showrightFilter} data-id={cell.row.original.id} className="grid-table-box d-flex align-items-center justify-content-center">
                                            Ad Posting Plan
                                        </div> */}
                      <div className="grid-table-box2 d-flex align-items-center justify-content-center">
                        <ApplyjobPoup />
                        {/* <div className="dk-AmDropDwnCon">
                            <Dropdown className="dk-AmDropDwn">
                                <Dropdown.Toggle variant="" className="dk-AmDropDwnToggle">
                                    <div className="tooltipMain">
                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.recRM.profileImage} className="filterImg" alt={cell.row.original.recRM.firstName + ' ' + cell.row.original.recRM.lastName} />
                                        <div className="tooltipText">{cell.row.original.recRM.firstName + ' ' + cell.row.original.recRM.lastName}</div>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dk-AmDropDwnMenu">
                                    <div className="d-flex">
                                        <UserList id={cell.row.original.id} columnId="rec_rm" onItemClick={onItemClick} />
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="dk-AmDropDwnCon mr-2">
                          <Dropdown className="dk-AmDropDwn">
                            <Dropdown.Toggle
                              variant=""
                              className="dk-AmDropDwnToggle"
                            >
                              <div className="tooltipMain">
                                <OverlayTrigger
                                  overlay={(props) => (
                                    <Tooltip {...props}>AM</Tooltip>
                                  )}
                                  placement="bottom"
                                >
                                  <div>
                                    <img
                                      src={
                                        process.env
                                          .REACT_APP_IMG_CLOUD_BASE_URL +
                                        cell.row.original.recAM.profileImage
                                      }
                                      className="filterImg"
                                      alt={
                                        cell.row.original.recAM.firstName +
                                        " " +
                                        cell.row.original.recAM.lastName
                                      }
                                    />{" "}
                                  </div>
                                </OverlayTrigger>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dk-AmDropDwnMenu">
                              <div className="d-flex">
                                <UserList
                                  id={cell.row.original.id}
                                  columnId="rec_am"
                                  onItemClick={onItemClick}
                                />
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="dk-AmDropDwnCon mr-2">{listItems}</div>
                        <div className="dk-AmDropDwnCon mr-2">
                          <Dropdown className="dk-AmDropDwn">
                            <Dropdown.Toggle
                              variant=""
                              className="dk-AmDropDwnToggle"
                            >
                              <div className="tooltipMain">
                                <OverlayTrigger
                                  overlay={(props) => (
                                    <Tooltip {...props}>RC 2</Tooltip>
                                  )}
                                  placement="bottom"
                                >
                                  <div>
                                    <img
                                      src={
                                        process.env
                                          .REACT_APP_IMG_CLOUD_BASE_URL +
                                        cell.row.original.recRA.profileImage
                                      }
                                      className="filterImg"
                                      alt={
                                        cell.row.original.recRA.firstName +
                                        " " +
                                        cell.row.original.recRA.lastName
                                      }
                                    />{" "}
                                  </div>
                                </OverlayTrigger>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dk-AmDropDwnMenu">
                              <div className="d-flex">
                                <UserList
                                  id={cell.row.original.id}
                                  columnId="rec_ra"
                                  onItemClick={onItemClick}
                                />
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "no_of_jobs",
        Header: "Jobs",
        accessor: "no_of_jobs",
        // Footer: (info) => {
        //     setTotalJobsNumber(info.page)

        //     const total = React.useMemo(
        //         () =>
        //             info.page.reduce((sum, row) => Number(row.original.no_of_jobs) + sum, 0),
        //         [info.page]
        //     )
        //     return <>{total}</>
        // },
      },
      {
        id: "company",
        Header: "Company",
        accessor: "company",
      },
      {
        id: "industry",
        Header: "industry",
        accessor: "industry",
      },
      {
        id: "lang",
        Header: "Language",
        accessor: "lang",
      },
      {
        id: "project",
        Header: "Project",
        accessor: "project",
      },
      {
        id: "role",
        Header: "Role Type",
        accessor: "role",
      },
      {
        id: "category",
        Header: "Category",
        accessor: "category",
      },
      {
        id: "locality",
        Header: "Locality",
        accessor: (row) => (row.locality == "" ? "Blank" : row.locality),
      },
      {
        id: "country",
        Header: "Country",
        accessor: (row) => (row.job_country == "" ? "Blank" : row.job_country),
      },
      {
        id: "location",
        Header: "City",
        accessor: (row) => (row.location == "" ? "Blank" : row.location),
      },
      {
        id: "fee",
        Header: "Job Fee",
        accessor: "fee",
        accessor: (row) =>
          row.fee == "" || row.fee == "0" ? "Blank" : row.fee,
      },
    ],
    []
  );

  const [instanceData, setInstanceData] = useState("0");
  const reactTableInstance = (instance) => {
    if (instance) {
      setList(instance.flatRows);
      setInstanceData(instance);
    }
  };

  const updateMyData = (id, columnId, value) => {
    dispatch(updateJob({ id, key: columnId, value }));
  };

  const [showTotalJob, setShowTotalJob] = useState("0/0");

  useEffect(() => {
    const totalRow = totalJobsNumber.length;
    const totalJob = totalJobsNumber.reduce(
      (total, row) => (total = total + Number(row.original.no_of_jobs)),
      0
    );
    setShowTotalJob(totalRow + "/" + totalJob);
  }, [totalJobsNumber]);

  const [rmFilterId, setRmFilterId] = useState("0");
  const [rmFilterImg, setRmFilterImg] = useState("0");

  const [raFilterId, setRaFilterId] = useState("0");
  const [raFilterImg, setRaFilterImg] = useState("0");

  const [rcFilterId, setRcFilterId] = useState("0");
  const [rcFilterImg, setRcFilterImg] = useState("0");

  const [amFilterId, setAmFilterId] = useState("0");
  const [amFilterImg, setAmFilterImg] = useState("0");

  const [prFilterId, setPrFilterId] = useState("0");
  const [prFilterImg, setPrFilterImg] = useState("0");

  const [statusId, setStatusId] = useState("0");
  const [categoryId, setCategoryId] = useState("0");
  const [localityId, setLocalityId] = useState("0");
  const [companyId, setCompanyId] = useState("0");
  const [industryId, setIndustryId] = useState("0");
  const [languageId, setLanguageId] = useState("0");
  const [workFromId, setWorkFromId] = useState("0");
  const [workPermitId, setWorkPermitId] = useState(0);
  const [workTypeId, setWorkTypeId] = useState(0);
  const [projectId, setProjectId] = useState("0");
  const [roleId, setRoleId] = useState("0");
  const [cityId, setCityId] = useState("0");
  const [hireId, setHireId] = useState("0");
  const [dateId, setDateId] = useState("0");
  const [jobSpecId, setjobSpecId] = useState("0");
  const [jobStatusId, setjobStatusId] = useState("0");
  const [countryId, setCountryId] = useState("0");
  const [jobprofileId, setJobprofileId] = useState("0");
  const [updated_onId, setUpdated_onId] = useState("0");
  const [createdAtId, setCreatedAtId] = useState("0");

  const selectUserFilter = async (e) => {
    const type = e.target.dataset.type;
    switch (type) {
      case "RMID":
        setRmFilterId(e.target.dataset.id);
        setRmFilterImg(e.target.dataset.img);
        break;

      case "RAID":
        setRaFilterId(e.target.dataset.id);
        setRaFilterImg(e.target.dataset.img);
        break;

      case "RCID":
        setRcFilterId(e.target.dataset.id);
        setRcFilterImg(e.target.dataset.img);
        break;

      case "AMID":
        setAmFilterId(e.target.dataset.id);
        setAmFilterImg(e.target.dataset.img);
        break;

      case "priority":
        setPrFilterId(e.target.dataset.id);
        setPrFilterImg(e.target.dataset.img);
        break;

      default:
        break;
    }
  };

  const clearUserFilter = (e) => {
    const type = e.target.dataset.type;
    switch (type) {
      case "RMID":
        setRmFilterId("0");
        setRmFilterImg("0");
        break;

      case "RAID":
        setRaFilterId("0");
        setRaFilterImg("0");
        break;

      case "RCID":
        setRcFilterId("0");
        setRcFilterImg("0");
        break;

      case "AMID":
        setAmFilterId("0");
        setAmFilterImg("0");
        break;

      case "priority":
        setPrFilterId("0");
        setPrFilterImg("0");
        break;

      default:
        break;
    }
  };

  const [filterTags, setFilterTags] = useState([]);
  const [jobSpecFilter, setJobSpecFilter] = useState("Job Spec");
  const [jobStatusFilter, setjobStatusFilter] = useState("Status");

  const selectOtherFilter = async (e) => {
    const type = e.target.dataset.type;
    const value = e.target.dataset.value;
    let i;

    switch (type) {
      case "status":
        i = filterTags.findIndex(function (x) {
          return x.type === "status";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setjobStatusId(value);
        setjobStatusFilter(value);
        break;

      case "jobSpec":
        i = filterTags.findIndex(function (x) {
          return x.type === "jobSpec";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setjobSpecId(value);
        setJobSpecFilter(value);
        break;

      case "status":
        i = filterTags.findIndex(function (x) {
          return x.type === "status";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setStatusId(value);
        break;

      case "category":
        i = filterTags.findIndex(function (x) {
          return x.type === "category";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCategoryId(value);
        break;

      case "country":
        i = filterTags.findIndex(function (x) {
          return x.type === "country";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCountryId(value);
        break;

      case "locality":
        i = filterTags.findIndex(function (x) {
          return x.type === "locality";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setLocalityId(value);
        break;

      case "industry":
        i = filterTags.findIndex(function (x) {
          return x.type === "industry";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setIndustryId(value);
        break;
      case "company":
        i = filterTags.findIndex(function (x) {
          return x.type === "company";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCompanyId(value);
        break;

      case "language":
        i = filterTags.findIndex(function (x) {
          return x.type === "language";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setLanguageId(value);
        break;

      case "work_permit":
        i = filterTags.findIndex(function (x) {
          return x.type === "work_permit";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setWorkPermitId(value);
        break;
      case "worktype":
        i = filterTags.findIndex(function (x) {
          return x.type === "worktype";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setWorkTypeId(value);
        break;

      case "workplace":
        i = filterTags.findIndex(function (x) {
          return x.type === "workplace";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setWorkFromId(value);
        break;

      case "project":
        i = filterTags.findIndex(function (x) {
          return x.type === "project";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setProjectId(value);
        break;

      case "role":
        i = filterTags.findIndex(function (x) {
          return x.type === "role";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setRoleId(value);
        break;

      case "city":
        i = filterTags.findIndex(function (x) {
          return x.type === "city";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCityId(value);
        break;

      case "job_profile":
        i = filterTags.findIndex(function (x) {
          return x.type === "job_profile";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setJobprofileId(value);
        break;

      case "updated_on":
        i = filterTags.findIndex(function (x) {
          return x.type === "updated_on";
        });
        if (i === -1) {
          filterTags.push({ type, value });
          console.log(filterTags);
          console.log(i);
        } else {
          console.log(filterTags);
          console.log(i);
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }

        setUpdated_onId(value);
        break;

      case "createdAt":
        i = filterTags.findIndex(function (x) {
          return x.type === "createdAt";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCreatedAtId(value);
        break;

      case "hire":
        i = filterTags.findIndex(function (x) {
          return x.type === "hire";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setHireId(value);
        break;

      case "date":
        i = filterTags.findIndex(function (x) {
          return x.type === "date";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setDateId(value);
        break;

      default:
        i = filterTags.findIndex(function (x) {
          return x.type === type;
        });
        if (i === -1) {
          filterTags.push({ type, value });
        }
        break;
    }
  };

  const removeTag = async (e) => {
    const type = e.target.dataset.type;
    setFilterTags(filterTags.filter((item) => item.type !== type));
    switch (type) {
      case "status":
        setjobStatusId("0");
        setjobStatusFilter("Status");
        break;

      case "jobSpec":
        setjobSpecId("0");
        setJobSpecFilter("Job Spec");
        break;

      case "category":
        setCategoryId("0");
        break;

      case "country":
        setCountryId("0");
        break;

      case "locality":
        setLocalityId("0");
        break;

      case "company":
        setCompanyId("0");
        break;
      case "industry":
        setIndustryId("0");
        break;

      case "language":
        setLanguageId("0");
        break;
      case "worktype":
        setWorkTypeId("0");
        break;
      case "work_permit":
        setWorkPermitId("0");
        break;
      case "workplace":
        setWorkFromId("0");
        break;

      case "project":
        setProjectId("0");
        break;

      case "role":
        setRoleId("0");
        break;

      case "city":
        setCityId("0");
        break;

      case "job_profile":
        setJobprofileId("0");
        break;

      case "updated_on":
        setUpdated_onId("0");
        break;

      case "createdAt":
        setCreatedAtId("0");
        break;

      case "hire":
        setHireId("0");
        break;

      case "date":
        setDateId("0");
        break;

      case "status":
        setStatusId("0");
        break;

      default:
        break;
    }
  };

  const [isClearAll, setIsClearAll] = useState(false);
  const clearAllFilter = async () => {
    setRaFilterId("0");
    setRaFilterImg("0");
    setRcFilterId("0");
    setRcFilterImg("0");
    setAmFilterId("0");
    setAmFilterImg("0");
    setPrFilterId("0");
    setPrFilterImg("0");
    setCategoryId("0");
    setCountryId("0");
    setCompanyId("0");
    setIndustryId("0");
    setProjectId("0");
    setRoleId("0");
    setLanguageId("0");
    setWorkFromId("0");
    setWorkPermitId("0");
    setWorkTypeId("0");
    setStatusId("0");
    setDateId("0");
    setHireId("0");
    setCityId("0");
    setJobprofileId("0");
    setUpdated_onId("0");
    setCreatedAtId("0");
    setjobSpecId("0");
    setJobSpecFilter("Job Spec");
    setjobStatusId("0");
    setjobStatusFilter("Status");
    setFilterTags([]);
    setIsClearAll(true);
  };

  const restClearAllFilter = async () => {
    setIsClearAll(false);
  };

  return (
    <React.Fragment>
      {isProfile ? (
        <DetialViewPoup
          jobId={jobId}
          show={show}
          onHide={handleClose}
          popupfor="candidatelivejobs"
        />
      ) : null}
      {isJobInfo ? (
        <EditdetialviewPoup
          jobId={jobId}
          show={editShow}
          onHide={handleEditClose}
          popupfor="livejobs"
          // clientidds={clientidds}
          // canjobs={canjobs}
          // companyName={companyName}
        />
      ) : null}

      {isRightFilter ? (
        <SmallDetialViewPoup jobId={jobId} show={show1} onHide={handleClose1} />
      ) : null}
      <div className="container-fluid dk-cliBoardViewPageCont">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="dk-qulaifyTopHead">
              <div className="dk-qulaifyLeftTopHead">
                <div className="dk-SrchInputBox mr-2">
                  <div className="input-group">
                    {instanceData !== "0" ? (
                      <GlobalFilter
                        preGlobalFilteredRows={
                          instanceData.preGlobalFilteredRows
                        }
                        globalFilter={instanceData.state.globalFilter}
                        setGlobalFilter={instanceData.setGlobalFilter}
                        isClearAll={isClearAll}
                        restClearAllFilter={restClearAllFilter}
                      />
                    ) : null}
                  </div>
                </div>
                {/* <div className="mr-2 dk-reclivejobsBox">
                                    <div className="dk-AccwithNnoDropDwnCon">
                                        <RMFilter list={list} id={rmFilterId} img={rmFilterImg} selectUserFilter={selectUserFilter} />
                                        {
                                            (rmFilterId !== '0') ? <i className="fas fa-times-circle dk-lrftCross" data-type="RMID" onClick={clearUserFilter}></i> : null
                                        }
                                    </div>
                                </div> */}

                {/* <div className="mr-2 dk-reclivejobsBox">
                  <div className="dk-AccwithNnoDropDwnCon">
                    <AMFilter
                      list={list}
                      id={amFilterId}
                      img={amFilterImg}
                      selectUserFilter={selectUserFilter}
                    />
                    {amFilterId !== "0" ? (
                      <i
                        className="fas fa-times-circle dk-lrftCross"
                        data-type="AMID"
                        onClick={clearUserFilter}
                      ></i>
                    ) : null}
                  </div>
                </div>

                <div className="mr-2 dk-reclivejobsBox">
                  <div className="dk-AccwithNnoDropDwnCon">
                    <RCFilter
                      list={list}
                      id={rcFilterId}
                      img={rcFilterImg}
                      selectUserFilter={selectUserFilter}
                    />
                    {rcFilterId !== "0" ? (
                      <i
                        className="fas fa-times-circle dk-lrftCross"
                        data-type="RCID"
                        onClick={clearUserFilter}
                      ></i>
                    ) : null}
                  </div>
                </div>

                <div className="mr-2 dk-reclivejobsBox">
                  <div className="dk-AccwithNnoDropDwnCon">
                    <RAFilter
                      list={list}
                      id={raFilterId}
                      img={raFilterImg}
                      selectUserFilter={selectUserFilter}
                    />
                    {raFilterId !== "0" ? (
                      <i
                        className="fas fa-times-circle dk-lrftCross"
                        data-type="RAID"
                        onClick={clearUserFilter}
                      ></i>
                    ) : null}
                  </div>
                </div> */}
              </div>
              <div className="dk-qulaifyTopHeadTitle">
                <div className="dk-filterTagsCon">
                  {filterTags.length > 0 ? (
                    filterTags.length > 0 &&
                    filterTags.map((item, index) => (
                      <ButtonGroup
                        className="dk-filterbtnGrp mr-2"
                        key={`tagButton${index}`}
                      >
                        <Button className="dk-filterbtntext">
                          {item.type !== "updated_on" &&
                          item.type !== "createdAt"
                            ? item.value
                            : // <CustomDateFormat
                              //   date={item.value}
                              //   format="DD MMM, YY"
                              // />
                              item.value}{" "}
                        </Button>
                        <Button
                          className="dk-filterbtnicon"
                          data-type={item.type}
                          onClick={removeTag}
                        >
                          <i
                            className="fas fa-times"
                            data-type={item.type}
                            onClick={removeTag}
                          ></i>
                        </Button>
                      </ButtonGroup>
                    ))
                  ) : (
                    <h3 className="title">Live Jobs - {showTotalJob}</h3>
                  )}
                </div>
              </div>
              <div className="dk-qulaifyRightTopHead">
                <JobsFaqPopup />
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-3 dk-left-search-bar pr-0">
            <RightModalFilter1
              list={list}
              selectUserFilter={selectUserFilter}
              selectOtherFilter={selectOtherFilter}
            />
          </div>
          <div className="col-md-9 col-sm-9 pl-0">
            <div className="dk-jobboardTableCon table-responsive">
              <Table
                data={data}
                columns={columns}
                getInstanceCallback={reactTableInstance}
                rmFilterId={rmFilterId}
                raFilterId={raFilterId}
                amFilterId={amFilterId}
                prFilterId={prFilterId}
                categoryId={categoryId}
                localityId={localityId}
                companyId={companyId}
                industryId={industryId}
                languageId={languageId}
                workFromId={workFromId}
                workPermitId={workPermitId}
                workTypeId={workTypeId}
                projectId={projectId}
                roleId={roleId}
                cityId={cityId}
                hireId={hireId}
                statusId={statusId}
                rcFilterId={rcFilterId}
                dateId={dateId}
                jobSpecId={jobSpecId}
                jobStatusId={jobStatusId}
                loading={isLoading}
                countryId={countryId}
                jobprofileId={jobprofileId}
                updated_onId={updated_onId}
                createdAtId={createdAtId}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LiveJobs;
