import React, { useState, useCallback, useRef, useEffect } from "react";
import { Modal, Dropdown, Nav, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ReactCrop from "react-image-crop";
import SubmitButton from "../../../ui/submitButton";
import { showSuccess, showError } from "../../../utils/messages/messageSlice";
import {
  updateHeaderDetails,
  updateUsrPassword,
  clearState,
  logoutUser,
} from "../../../../slice/auth/authSlice";
import { getCMS } from "../../../../slice/cmsSlice";

const ChangeLogin = () => {
  const dispatch = useDispatch();
  const { userDetails, userInfo, isUpdate, isPasswordUpdate, isError, msg } =
    useSelector((state) => state.auth);
  const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);
  const [userId, setUserId] = useState(userInfo.id);
  const [details, setDetails] = useState("");

  useEffect(() => {
    dispatch(getCMS({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setDetails(cmsList[5]);
    }
  }, [isSuccess]);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [file, setFile] = useState(userDetails.profileImage);

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "px",
    width: 150,
    height: 150,
    aspect: 1 / 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [cropModal, setCropModal] = useState(false);

  useEffect(() => {
    if (userDetails.profileImage != "users/images/user.png") {
      setFile(userDetails.profileImage);
    }
  }, []);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    const base64Image = canvas.toDataURL("image/jpeg");
    //setIsImageUpdate(true);
    // setNewImageData(base64Image);
  }, [completedCrop]);

  const handleCropDone = () => {
    setCropModal(false);
  };
  const cancelCrop = () => {
    setCropModal(false);
    //setNewImageData("");
    //setIsImageUpdate(false);
    setCompletedCrop(null);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const pathname = window.location.pathname;
  const segment1 = pathname.split("/")[1];

  const submitChangePassword = (e) => {
    e.preventDefault();
    setSuccess(false);

    if (!password || !confirmPassword) {
      dispatch(showError({ msg: "All fields required" }));
      setLoading(false);
    } else {
      if (password != confirmPassword) {
        dispatch(
          showError({ msg: "Password and confirm password not matched" })
        );
        setLoading(false);
      } else {
        setLoading(true);
        dispatch(
          updateUsrPassword({
            userId,
            email: userInfo.email,
            canId: userInfo.canId,
            password,
            confirmPassword,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
      dispatch(showError({ msg: msg }));
      setLoading(false);
      setSuccess(false);
    }
    if (isUpdate) {
      dispatch(clearState());
      setLoading(false);
      setSuccess(false);
      setCompletedCrop(null);
      dispatch(
        showSuccess({ msg: "Profile information updated successfully" })
      );
      if (userInfo.id === userDetails.id) {
        dispatch(
          updateHeaderDetails({
            name: userDetails.firstName + " " + userDetails.lastName,
            userImg: userDetails.userImg,
          })
        );
      }
    }
    if (isPasswordUpdate) {
      dispatch(clearState());
      setLoading(false);
      setSuccess(false);
      setPassword("");
      setConfirmPassword("");
      //setCurrentPassword("");
      dispatch(showSuccess({ msg: msg }));
      const delay = 3;
      setTimeout(() => dispatch(logoutUser()), delay * 1000);
    }
  }, [isUpdate, isPasswordUpdate, isError]);

  const getFiles = (e) => {
    setFile(e.base64);
  };
  return (
    <>
      {cropModal ? (
        <Modal
          className="dk-uploadImgModal"
          centered
          show={true}
          onHide={handleClose}
        >
          <Modal.Body>
            <ReactCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="col-md-12">
              <div className="d-flex justify-content-center btn-container">
                <button className="dk-cancelBtn" onClick={cancelCrop}>
                  Cancel
                </button>
                <SubmitButton
                  txt="Use This"
                  loading={loading}
                  success={success}
                  onClick={handleCropDone}
                  position=""
                  className="dk-updateBtn"
                />
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      ) : null}
      <div
        className="dkg-myprofile-mainpageCon container-fluid dkg-restlogin-mainpageCon"
        style={{ height: "calc(100vh - 130px)", marginTop: "125px" }}
      >
        <div className="row dk-billingHistroy-fluid">
          <div className="col-9 m-auto dk-contactPerson-form dkg-resetform-Con-234">
            <div className="row">
              <div className="col-md-7 m-auto dkg-reset-rightCon">
                <form action="">
                  <div className="rtitle">Reset Logins</div>
                  <div className="row m-0 dkg-reset-login-body">
                    <div className="dkg-delete-acc-header">
                      <p>{details != "" ? details.description : ""}</p>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group dkg-reset-form-group">
                        <label for="">New Password</label>

                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control myacc-resetinput-type"
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          placeholder="*****"
                        />

                        <span
                          className="dk-eyeICon dk-passHideShow"
                          onClick={handleClickShowPassword}
                          style={{ backgroundColor: "#e1e1e4" }}
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group dkg-reset-form-group dkg-reset-last-form-group">
                        <label for="">Confirm Password</label>

                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          className="form-control myacc-resetinput-type"
                          name="password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          value={confirmPassword}
                          placeholder="*****"
                        />

                        <span
                          className="dk-eyeICon dk-passHideShow"
                          onClick={handleClickShowConfirmPassword}
                          style={{ backgroundColor: "#e1e1e4" }}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="dkg-resetbtn-Con-24">
                        {/* <button className="btn dkg-cancelbtn">Cancel</button> */}
                        <SubmitButton
                          txt="Update"
                          loading={loading}
                          success={success}
                          onClick={submitChangePassword}
                          className="btn dkg-resetbtn"
                          position="justify-content-center"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeLogin;
