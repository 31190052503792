import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './rightmodalfilter.scss';
import { Modal, Nav, Tab } from 'react-bootstrap';
import { MdInsertDriveFile } from "react-icons/md";
import { FaLevelUpAlt, FaCheck, FaCheckCircle, FaSpinner, FaRegDotCircle } from "react-icons/fa";

const RightModalFilter = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);
    }


    return (
        <React.Fragment>
            <button className="dk-reoderBtn" onClick={handleShow}>
                <i className="fa fa-filter"></i>
            </button>
            <div className="dk-jobsMainModalCon">
                <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName="dk-pipenwqrightFliterModal"
                >
                    <Modal.Header closeButton className="dkmb-Fliterheader">
                        <Modal.Title className="dkmb-FliterTitle">
                            SEARCH & REPORT dfdgf
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="dkmb-Fliterbody dkg-pipelinesRightFilterBody-13">
                        <Tab.Container id="dk-jobsRightFilterModal" defaultActiveKey="account-manager">
                            <div className="row ml-0 mr-0">
                                <div className="col-md-4 pl-0 pr-0">
                                    <Nav variant="pills" className="flex-column dk-leftSideMenu dkg-right-sideFilter-13">
                                        <Nav.Item>
                                            <Nav.Link eventKey="account-manager">
                                                <i className="fa fa-user mr-2" aria-hidden="true"></i>AM
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="rc">
                                                <i className="fa fa-user mr-2" aria-hidden="true"></i>RC
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="rec-admin">
                                                <i className="fa fa-user mr-2" aria-hidden="true"></i>RA
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="approval">
                                                <FaCheck className='mr-2' />MATCHING
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="priority">
                                                <i className="fa fa-circle mr-2" aria-hidden="true"></i>UPDATED
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="motivation">
                                                <i className="fa fa-user mr-2" aria-hidden="true"></i>MOTIVATION
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="flags">
                                                <i className="fa fa-flag mr-2" aria-hidden="true"></i>INTERVIEWS
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="status">
                                                <FaSpinner className="mr-2" />Status
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="progress">
                                                <FaSpinner className="mr-2" />Progress
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="casestage">
                                                <FaSpinner className="mr-2" />Case Stage
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="category">
                                                <i className="fa fa-language mr-2" aria-hidden="true"></i>Category
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="company">
                                                <i className="fa fa-building mr-2" aria-hidden="true"></i>Company
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="project">
                                                <MdInsertDriveFile className="mr-3" />Project
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="roletype">
                                                <i className="fa fa-user mr-2" aria-hidden="true"></i>Role Type
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="languages">
                                                <i className="fa fa-language mr-2" aria-hidden="true"></i>Languages
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="resources">
                                                <i className="fa fa-sitemap mr-2" aria-hidden="true"></i>Resources
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="created-on">
                                                <i className="fa fa-calendar mr-2" aria-hidden="true"></i>CREATED ON
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="bydate">
                                                <i className="fa fa-calendar mr-2" aria-hidden="true"></i>UPDATED ON
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                <div className="col-md-8 pl-0 pr-0">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="rec-admin">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-Recuriter">
                                                    <ul>
                                                        <li>
                                                            <Link to="#">
                                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="Deepak" className="recImg" title="Deepak" />
                                                                <span className="recuriterNo" >47</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">
                                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/user.png" alt="No User" className="recImg" title="No User" />
                                                                <span className="recuriterNo">17</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="rc">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-Recuriter">
                                                    <ul>
                                                        <li>
                                                            <Link to="#">
                                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="Deepak" className="recImg" title="Deepak" />
                                                                <span className="recuriterNo" >47</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">
                                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/user.png" alt="No User" className="recImg" title="No User" />
                                                                <span className="recuriterNo">17</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="account-manager">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-Recuriter">
                                                    <ul>
                                                        <li>
                                                            <Link to="#">
                                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="Deepak" className="recImg" title="Deepak" />
                                                                <span className="recuriterNo" >47</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">
                                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/user.png" alt="No User" className="recImg" title="No User" />
                                                                <span className="recuriterNo">17</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="motivation">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-flagFilter">
                                                    <ul>
                                                        <li>
                                                            <Link to="#">
                                                                <i className="fa fa-user mr-2" aria-hidden="true" style={{ color: "#048204" }}></i>
                                                                <span className="text-orange">12</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">
                                                                <i className="fa fa-user mr-2" style={{ color: "#d58b5d" }}></i>
                                                                <span className="text-orange">12</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">
                                                                <i className="fa fa-user mr-2" style={{ color: "#d60101" }}></i>
                                                                <span className="text-orange">12</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">
                                                                <i className="fa fa-user mr-2" aria-hidden="true" style={{ color: "#d3d3d3" }} ></i>
                                                                <span className="text-orange">12</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="flags">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-flagFilter">
                                                    <ul>
                                                        <li>
                                                            <Link to="#">
                                                                <i className="fa fa-flag mr-2" aria-hidden="true" style={{ color: "#048204" }}></i>
                                                                <span className="text-orange">12</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">
                                                                <i className="fa fa-flag mr-2" style={{ color: "#d58b5d" }}></i>
                                                                <span className="text-orange">12</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">
                                                                <i className="fa fa-flag mr-2" style={{ color: "#d60101" }}></i>
                                                                <span className="text-orange">12</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">
                                                                <i className="fa fa-flag mr-2" aria-hidden="true" style={{ color: "#d3d3d3" }} ></i>
                                                                <span className="text-orange">12</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="company">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        <li data-type="company" data-value="Genpact Bucharest">
                                                            <Link data-type="company" data-value="Genpact Bucharest" to="#">Genpact Bucharest<span className="text-orange" data-type="company" data-value="Genpact Bucharest">25</span></Link>
                                                        </li>
                                                        <li data-type="company" data-value="Pragmatic Play">
                                                            <Link data-type="company" data-value="Pragmatic Play" to="#">Pragmatic Play<span className="text-orange" data-type="company" data-value="Pragmatic Play">11</span></Link>
                                                        </li>
                                                        <li data-type="company" data-value="Wipro Dublin">
                                                            <Link data-type="company" data-value="Wipro Dublin" to="#">Wipro Dublin<span className="text-orange" data-type="company" data-value="Wipro Dublin">5</span></Link>
                                                        </li>
                                                        <li data-type="company" data-value="Blank">
                                                            <Link data-type="company" data-value="Blank" to="#">Blank<span className="text-orange" data-type="company" data-value="Blank">3</span></Link>
                                                        </li>
                                                        <li data-type="company" data-value="Wipro Bucharest">
                                                            <Link data-type="company" data-value="Wipro Bucharest" to="#">Wipro Bucharest<span className="text-orange" data-type="company" data-value="Wipro Bucharest">2</span></Link>
                                                        </li>
                                                        <li data-type="company" data-value="Wipro Timisoara">
                                                            <Link data-type="company" data-value="Wipro Timisoara" to="#">Wipro Timisoara<span className="text-orange" data-type="company" data-value="Wipro Timisoara">1</span></Link>
                                                        </li>
                                                        <li data-type="company" data-value="Wipro Porto">
                                                            <Link data-type="company" data-value="Wipro Porto" to="#">Wipro Porto<span className="text-orange" data-type="company" data-value="Wipro Porto">1</span></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="status">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-satusListFilter">
                                                    <ul>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#5c737d", color: "#fff" }}>New Case<span className="text-orange" data-value="New Case">0</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#796698", color: "#fff" }}>
                                                                Screening<span className="text-orange" data-value="Screening">3</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#55496b", color: "#fff" }}>
                                                                Reminders<span className="text-orange">5</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#009a9a", color: "#fff" }}>
                                                                DKG Process <span className="text-orange">6</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#1b6867", color: "#fff" }}>
                                                                Client Process <span className="text-orange">10</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#599ad5", color: "#fff" }}>
                                                                Job Offer <span className="text-orange">14</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#a84d4d", color: "#fff" }}>
                                                                Refused Offer <span className="text-orange">5</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#df744a", color: "#fff" }}>Credit
                                                                <span className="text-orange" data-value="Credit">4</span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#e2445c", color: "#fff" }}>Closed
                                                                <span className="text-orange" data-value="Closed">8</span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" style={{ backgroundColor: "#670004", color: "#fff" }}>
                                                                Escalated<span className="text-orange" >10</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="progress">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        <li data-value="To Review">
                                                            <Link to="#">To Review<span className="text-orange" data-value="To Review">44</span></Link>
                                                        </li>
                                                        <li data-value="Blank">
                                                            <Link to="#">Blank<span className="text-orange" data-value="Blank">3</span></Link>
                                                        </li>
                                                        <li data-value="For Applied Job">
                                                            <Link to="#">For Applied Job<span className="text-orange" data-value="For Applied Job">1</span></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="casestage">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        <li data-value="To Review">
                                                            <Link to="#">To Review<span className="text-orange" data-value="To Review">44</span></Link>
                                                        </li>
                                                        <li data-value="Blank">
                                                            <Link to="#">Blank<span className="text-orange" data-value="Blank">3</span></Link>
                                                        </li>
                                                        <li data-value="For Applied Job">
                                                            <Link to="#">For Applied Job<span className="text-orange" data-value="For Applied Job">1</span></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="category">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-CategoryList">
                                                    <ul>
                                                        <li>
                                                            <Link data-type="category" data-value="ML" to="#">ML
                                                                <span className="text-orange" data-value="ML">3</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="category" data-value="IT" to="#">IT
                                                                <span className="text-orange" data-value="IT">0</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="category" data-value="None" to="#">None
                                                                <span className="text-orange" data-value="None">45</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="project">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        <li data-type="project" data-value="Confidential">
                                                            <Link data-type="project" data-value="Confidential" to="#">Confidential<span className="text-orange" data-type="project" data-value="Confidential">28</span></Link>
                                                        </li>
                                                        <li data-type="project" data-value="Intel">
                                                            <Link data-type="project" data-value="Intel" to="#">Intel<span className="text-orange" data-type="project" data-value="Intel">17</span></Link>
                                                        </li>
                                                        <li data-type="project" data-value="Blank">
                                                            <Link data-type="project" data-value="Blank" to="#">Blank<span className="text-orange" data-type="project" data-value="Blank">3</span></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="roletype">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList dkg-roletypeFilterlist12">
                                                    <ul>
                                                        <li data-type="role" data-value="IT Support">
                                                            <Link data-type="role" data-value="IT Support" to="#">IT Support<span className="text-orange" data-type="role" data-value="IT Support">17</span></Link>
                                                        </li>
                                                        <li data-type="role" data-value="Online Dealer">
                                                            <Link data-type="role" data-value="Online Dealer" to="#">Online Dealer<span className="text-orange" data-type="role" data-value="Online Dealer">11</span></Link>
                                                        </li>
                                                        <li data-type="role" data-value="Ads Labelling">
                                                            <Link data-type="role" data-value="Ads Labelling" to="#">Ads Labelling<span className="text-orange" data-type="role" data-value="Ads Labelling">8</span></Link>
                                                        </li>
                                                        <li data-type="role" data-value="QA">
                                                            <Link data-type="role" data-value="QA" to="#">QA<span className="text-orange" data-type="role" data-value="QA">5</span></Link>
                                                        </li>
                                                        <li data-type="role" data-value="Blank">
                                                            <Link data-type="role" data-value="Blank" to="#">Blank<span className="text-orange" data-type="role" data-value="Blank">3</span></Link>
                                                        </li>
                                                        <li data-type="role" data-value="Senior Recruitment Partner">
                                                            <Link data-type="role" data-value="Senior Recruitment Partner" to="#">Senior Recruitment Partner<span className="text-orange" data-type="role" data-value="Senior Recruitment Partner">1</span></Link>
                                                        </li>
                                                        <li data-type="role" data-value="Travel&amp;Expense Analyst">
                                                            <Link data-type="role" data-value="Travel&amp;Expense Analyst" to="#">Travel&amp;Expense Analyst<span className="text-orange" data-type="role" data-value="Travel&amp;Expense Analyst">1</span></Link>
                                                        </li>
                                                        <li data-type="role" data-value="HR Helpdesk">
                                                            <Link data-type="role" data-value="HR Helpdesk" to="#">HR Helpdesk<span className="text-orange" data-type="role" data-value="HR Helpdesk">1</span></Link>
                                                        </li>
                                                        <li data-type="role" data-value="Media Content Review">
                                                            <Link data-type="role" data-value="Media Content Review" to="#">Media Content Review<span className="text-orange" data-type="role" data-value="Media Content Review">1</span></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="languages">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        <li data-type="lang" data-value="Italian">
                                                            <Link data-type="lang" data-value="Italian" to="#">Italian<span className="text-orange" data-type="lang" data-value="Italian">20</span></Link>
                                                        </li>
                                                        <li data-type="lang" data-value="Portuguese">
                                                            <Link data-type="lang" data-value="Portuguese" to="#">Portuguese<span className="text-orange" data-type="lang" data-value="Portuguese">6</span></Link>
                                                        </li>
                                                        <li data-type="lang" data-value="French">
                                                            <Link data-type="lang" data-value="French" to="#">French<span className="text-orange" data-type="lang" data-value="French">4</span></Link>
                                                        </li>
                                                        <li data-type="lang" data-value="Blank">
                                                            <Link data-type="lang" data-value="Blank" to="#">Blank<span className="text-orange" data-type="lang" data-value="Blank">3</span></Link>
                                                        </li>
                                                        <li data-type="lang" data-value="Greek">
                                                            <Link data-type="lang" data-value="Greek" to="#">Greek<span className="text-orange" data-type="lang" data-value="Greek">3</span></Link>
                                                        </li>
                                                        <li data-type="lang" data-value="Spanish">
                                                            <Link data-type="lang" data-value="Spanish" to="#">Spanish<span className="text-orange" data-type="lang" data-value="Spanish">3</span></Link>
                                                        </li>
                                                        <li data-type="lang" data-value="English">
                                                            <Link data-type="lang" data-value="English" to="#">English<span className="text-orange" data-type="lang" data-value="English">2</span></Link>
                                                        </li>
                                                        <li data-type="lang" data-value="Polish">
                                                            <Link data-type="lang" data-value="Polish" to="#">Polish<span className="text-orange" data-type="lang" data-value="Polish">2</span></Link>
                                                        </li>
                                                        <li data-type="lang" data-value="Hebrew">
                                                            <Link data-type="lang" data-value="Hebrew" to="#">Hebrew<span className="text-orange" data-type="lang" data-value="Hebrew">1</span></Link>
                                                        </li>
                                                        <li data-type="lang" data-value="Czech">
                                                            <Link data-type="lang" data-value="Czech" to="#">Czech<span className="text-orange" data-type="lang" data-value="Czech">1</span></Link>
                                                        </li>
                                                        <li data-type="lang" data-value="Korean">
                                                            <Link data-type="lang" data-value="Korean" to="#">Korean<span className="text-orange" data-type="lang" data-value="Korean">1</span></Link>
                                                        </li>
                                                        <li data-type="lang" data-value="Turkish">
                                                            <Link data-type="lang" data-value="Turkish" to="#">Turkish<span className="text-orange" data-type="lang" data-value="Turkish">1</span></Link>
                                                        </li>
                                                        <li data-type="lang" data-value="Russian">
                                                            <Link data-type="lang" data-value="Russian" to="#">Russian<span className="text-orange" data-type="lang" data-value="Russian">1</span></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="resources">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        <li data-type="cv_source" data-value="Jooble">
                                                            <Link data-type="cv_source" data-value="Jooble" to="#">Jooble<span className="text-orange" data-type="cv_source" data-value="Jooble">16</span></Link>
                                                        </li>
                                                        <li data-type="cv_source" data-value="ELJ">
                                                            <Link data-type="cv_source" data-value="ELJ" to="#">ELJ<span className="text-orange" data-type="cv_source" data-value="ELJ">12</span></Link>
                                                        </li>
                                                        <li data-type="cv_source" data-value="Hipo">
                                                            <Link data-type="cv_source" data-value="Hipo" to="#">Hipo<span className="text-orange" data-type="cv_source" data-value="Hipo">7</span></Link>
                                                        </li>
                                                        <li data-type="cv_source" data-value="Indeed">
                                                            <Link data-type="cv_source" data-value="Indeed" to="#">Indeed<span className="text-orange" data-type="cv_source" data-value="Indeed">6</span></Link>
                                                        </li>
                                                        <li data-type="cv_source" data-value="MLV">
                                                            <Link data-type="cv_source" data-value="MLV" to="#">MLV<span className="text-orange" data-type="cv_source" data-value="MLV">3</span></Link>
                                                        </li>
                                                        <li data-type="cv_source" data-value="DK Website">
                                                            <Link data-type="cv_source" data-value="DK Website" to="#">DK Website<span className="text-orange" data-type="cv_source" data-value="DK Website">3</span></Link>
                                                        </li>
                                                        <li data-type="cv_source" data-value="Blank">
                                                            <Link data-type="cv_source" data-value="Blank" to="#">Blank<span className="text-orange" data-type="cv_source" data-value="Blank">1</span></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    )
}
export default RightModalFilter;