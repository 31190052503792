import React from 'react';
import { Link } from "react-router-dom";

const SendoutsLeftPanel = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <>
            <div className="dk-sendoutsLeftpanel">
                <ul>
                    <li><Link to="/kpis/jobs/summary" className={`${(segment3 === 'summary') ? 'active' : ''}`}>Summary</Link></li>
                    <li><Link to="#/kpis/jobs/stats-report" className={`${(segment3 === 'stats-report') ? 'active' : ''}`}>Sample 1</Link></li>
                    <li><Link to="#/kpis/jobs/stats-details" className={`${(segment3 === 'stats-details') ? 'active' : ''}`}>Sample 2</Link></li>
                    <li><Link to="#/kpis/jobs/case-details" className={`${(segment3 === 'case-details') ? 'active' : ''}`}>Sample 3</Link></li>
                </ul>
            </div>
        </>
    )
}

export default SendoutsLeftPanel
