import React from "react";
import FounderImg from "../../../../assets/images/deepak.png";
import DkGlobalRecruitmentImg from "../../../../assets/images/dk-global-rec-logo.png";
import DkGlobalRecruitersImg from "../../../../assets/images/dk-global-recruiters-logo.png";
import DkGlobalJobsImg from "../../../../assets/images/dk-global-jobs-logo.png";
import DkGlobalCrmImg from "../../../../assets/images/dkgcrmlogo.png";
import DkGlobalErpImg from "../../../../assets/images/dkglobal-erp.png";
import DkGlobalAcademyImg from "../../../../assets/images/dk-global-academy-logo.png";
import './dkglobalsummary.scss';

const DkGlobalSummary = () => {
    return (
        <React.Fragment>
            <div className="dk-dkGlobalSummary-Main">
                <div className="row">
                    <div className="col-md-3">
                        <div className="dk-information-cols">
                            <div className="title">Founder</div>
                            <div className="dk-userImg">
                                <img src={FounderImg} alt="" />
                            </div>

                            <div className="dk-infoTable">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="dk-information-cols">
                            <div className="title">Business Details</div>

                            <div className="dk-infoTable dk-businessDetailTable">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="dk-information-cols">
                            <div className="title">DK GLOBAL PROJECTS</div>

                            <div className="dk-infoTable dk-businessOverallTable">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="dk-logo">
                                                    <a href="https://www.dkglobalrecruitment.com/" target="_blank">
                                                        <img src={DkGlobalRecruitmentImg} alt="" />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="dk-logo">
                                                    <a href="https://www.dkglobalrecruiters.com/" target="_blank">
                                                        <img src={DkGlobalRecruitersImg} alt="" style={{ maxWidth: '140px' }} />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="dk-logo">
                                                    <a href="https://www.dkglobaljobs.com/" target="_blank">
                                                        <img src={DkGlobalJobsImg} alt="" style={{ maxWidth: '140px' }} />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="dk-logo">
                                                    <a href="https://www.dkglobalcrm.com/" target="_blank">
                                                        <img src={DkGlobalCrmImg} alt="" style={{ maxWidth: '162px' }} />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="dk-logo">
                                                    <a href="https://www.dkglobalerp.com/" target="_blank">
                                                        <img src={DkGlobalErpImg} alt="" />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="dk-logo">
                                                    <a href="https://dkglobalacademy.com/" target="_blank">
                                                        <img src={DkGlobalAcademyImg} alt="" style={{ maxWidth: '140px' }} />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DkGlobalSummary;
