import React from 'react';
import './adssummary.scss';

const KpiSummary = () => {
    return (
        <>
            {/* <div className='container-fluid dk-kpiSummaryMain-container'>
                <div className='row dk-maimenu-suMMCon'>
                    <div className='col-md-12'>
                        <div className='dkg-mainsuMM-leftCon'>
                            <div className='dkg-mainsuMM-leftTopCon'>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>CLIENTS</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count"></div>
                                    </div>
                                </div>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>JOBS</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count"></div>
                                    </div>
                                </div>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>TASKS</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count"></div>
                                    </div>
                                </div>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>CASES</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='dkg-mainsuMM-leftCon'>
                            <div className='dkg-mainsuMM-leftTopCon'>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>JOB SPECS</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count"></div>
                                    </div>
                                </div>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>SENDOUTS</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count"></div>
                                    </div>
                                </div>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>SALES</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count"></div>
                                    </div>
                                </div>
                                <div className='dkg-main-menu-suMMBox-Con'>
                                    <div className='dkg-main-menusuMM-Header'>
                                        <h5 className='dkg-main-menusuMM-Title'>INVOICE</h5>
                                    </div>
                                    <div className='dkg-main-menusuMM-Body'>
                                        <div className="dk-count"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="dk-sendoutsSummary-mainContainer">
                <div className="dk-Summary-col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="dk-summaryFlex-main">
                                <div className="dk-summaryBox-12">
                                    <div className="title">YEAR <span>509</span></div>
                                    <ul></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">MONTH <span>509</span></div>
                                    <ul></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">ACCOUNT M <span>509</span></div>
                                    <ul></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">STATUS <span>509</span></div>
                                    <ul></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">PROCESS <span>509</span></div>
                                    <ul></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">STAGE <span>509</span></div>
                                    <ul></ul>
                                </div>
                            </div>

                            <div className="dk-summaryFlex-main">
                                <div className="dk-summaryBox-12">
                                    <div className="title">RECRUITER <span>509</span></div>
                                    <ul className="h-lg"></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">COMPANY <span>509</span></div>
                                    <ul className="h-lg"></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">LANGUAGE <span>509</span></div>
                                    <ul className="h-lg"></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">PROJECT <span>509</span></div>
                                    <ul className="h-lg"></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">ROLE TYPE <span>509</span></div>
                                    <ul className="h-lg"></ul>
                                </div>
                                <div className="dk-summaryBox-12">
                                    <div className="title">CV Source <span>509</span></div>
                                    <ul className="h-lg"></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KpiSummary
