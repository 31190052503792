import React from 'react';
import { Link, Route } from "react-router-dom";


const JobProfileLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkg-global/company/job-profiles/overview" className={`${pathname.match('/dkg-global/company/job-profiles/overview') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/dkg-global/company/job-profiles/established" className={`${pathname.match('/dkg-global/company/job-profiles/established') ? 'active' : ''}`}>Established</Link></li>

                    <li><Link to="/dkg-global/company/job-profiles/business" className={`${pathname.match('/dkg-global/company/job-profiles/business') ? 'active' : ''}`}>Business</Link></li>

                    <li><Link to="/dkg-global/company/job-profiles/industry" className={`${pathname.match('/dkg-global/company/job-profiles/industry') ? 'active' : ''}`}>Industry</Link></li>

                    <li><Link to="/dkg-global/company/job-profiles/services" className={`${pathname.match('/dkg-global/company/job-profiles/services') ? 'active' : ''}`}>Services</Link></li>

                    <li><Link to="/dkg-global/company/job-profiles/clients" className={`${pathname.match('/dkg-global/company/job-profiles/clients') ? 'active' : ''}`}>Clients</Link></li>

                    <li><Link to="/dkg-global/company/job-profiles/suppliers" className={`${pathname.match('/dkg-global/company/job-profiles/suppliers') ? 'active' : ''}`}>Suppliers</Link></li>

                    <li><Link to="/dkg-global/company/job-profiles/completion" className={`${pathname.match('/dkg-global/company/job-profiles/completion') ? 'active' : ''}`}>Completion</Link></li>

                    <li><Link to="/dkg-global/company/job-profiles/employees" className={`${pathname.match('/dkg-global/company/job-profiles/employees') ? 'active' : ''}`}>Employees</Link></li>

                    <li><Link to="/dkg-global/company/job-profiles/future-plans" className={`${pathname.match('/dkg-global/company/job-profiles/future-plans') ? 'active' : ''}`}>Future Plans</Link></li>

                    <li><Link to="/dkg-global/company/job-profiles/social-media" className={`${pathname.match('/dkg-global/company/job-profiles/social-media') ? 'active' : ''}`}>Social Media</Link></li>
                </Route>
            </ul>
        </React.Fragment>
    )
}

export default JobProfileLeftPanel;

