import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

const DeleteProjectModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Link to="#" className="dk-deleteBtn" onClick={handleShow}>
                <i className="far fa-trash-alt"></i>
            </Link>

            <Modal className="dk-projectDeleteModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Projects</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Please confirm to continue.</p>
                </Modal.Body>
                <div className="modal-footer d-flex justify-content-center">
                    <a href="#">Continue</a>
                </div>
            </Modal>
        </>
    )
}

export default DeleteProjectModal;
