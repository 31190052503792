import React from 'react';

const EditDelete = () => {
    return (
        <>
            <div className="dk-batch-actions-menu-wrapper">
                <div className="dk-num-of-actions_wrapper">
                    <div className="num-of-actions">1</div>
                </div>
                <div className="dk-batch-actions-item">
                    <span><i className="fas fa-edit"></i></span>
                    Edit
                </div>
                <div className="dk-batch-actions-item">
                    <span><i className="far fa-trash-alt"></i></span>
                    Delete
                </div>
                <div className="dk-batch-actions-delete-item CloseAll d-flex align-items-center justify-content-center">
                    <span><i className="fas fa-times"></i></span>
                </div>

            </div>
        </>
    )
}

export default EditDelete;