import React from "react";
import '../mainMenu/style.scss';
// import AppTrackerSubmenu from "./menu/Adssubmenu/AppTrackerSubmenu";
import RecConsultantsBusinessSubmenu from './menu/trainingmodule/recruitmentconsultants/RecConsultantsBusinessSubmenu';
import RecConsultantsRecDepSubmenu from './menu/trainingmodule/recruitmentconsultants/RecConsultantsRecDepSubmenu';
import RecConsultantsJobProfileSubmenu from './menu/trainingmodule/recruitmentconsultants/RecConsultantsJobProfileSubmenu';
import RecConsultantsProcessSubmenu from './menu/trainingmodule/recruitmentconsultants/RecConsultantsProcessSubmenu';
import RecConsultantsToolsSubmenu from './menu/trainingmodule/recruitmentconsultants/RecConsultantsToolsSubmenu';
import RecConsultantsMailboxSubmenu from './menu/trainingmodule/recruitmentconsultants/RecConsultantsMailboxSubmenu';
import RecConsultantsKpiSubmenu from './menu/trainingmodule/recruitmentconsultants/RecConsultantsKpiSubmenu';
import RecConsultantscsfSubmenu from './menu/trainingmodule/recruitmentconsultants/RecConsultantscsfSubmenu';
import RecConsultantsfaqSubmenu from './menu/trainingmodule/recruitmentconsultants/RecConsultantsfaqSubmenu';


const RecConsultantsSubmenu = ({ path }) => {
    const renderSubmenu = () => {
        switch (path.split("/")[3]) {

            case 'business':
                return <RecConsultantsBusinessSubmenu />
                break;

            case 'rec-dep':
                return <RecConsultantsRecDepSubmenu />
                break;

            case 'job-profile':
                return <RecConsultantsJobProfileSubmenu />
                break;

            case 'process':
                return <RecConsultantsProcessSubmenu />
                break;

            case 'tools':
                return <RecConsultantsToolsSubmenu />
                break;

            case 'mailbox':
                return <RecConsultantsMailboxSubmenu />
                break;

            case 'kpi':
                return <RecConsultantsKpiSubmenu />
                break;

            case 'csf':
                return <RecConsultantscsfSubmenu />
                break;

            case 'faq':
                return <RecConsultantsfaqSubmenu />
                break;

            default:
                break
        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default RecConsultantsSubmenu;