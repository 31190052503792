import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
// import '../comprofreordermodal.scss';



const ReorderModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Link to="#" onClick={handleShow} className='mr-2'>
                Reorder <i className='fa fa-arrows-alt ml-1'></i>
            </Link>
            <Modal show={show} onHide={handleClose} centered className='dk-comprof-reorderModal'>
                <Modal.Header closeButton className='dk-comprof-reorderModalHeader'>
                    <Modal.Title>Reorder</Modal.Title>
                </Modal.Header>
                <Modal.Body className='dk-comprof-reorderModalBody'>Woohoo, you're reading this text in a modal!</Modal.Body>
            </Modal>
        </>
    );
};
export default ReorderModal;
