import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData } from "../../services";


export const getFaqList = createAsyncThunk(
    "faqs11Faq/categoryList",
    async ({ }, thunkAPI) => {
        const res = await GetData(true, "/faqs-login");
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


const usersSlice = createSlice({
    name: "login1Slice",
    initialState: {
        loginfaqsdata: [],
        isSuccess: false,
        isLoading: false,
        isError: false,
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.isData = false;
        },
    },
    extraReducers: {

        [getFaqList.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getFaqList.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.loginfaqsdata = payload.result;
        },
        [getFaqList.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

    },
});

export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
