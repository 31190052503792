import React from 'react';
import './contractsdashboard.scss';

const ExpenseDashboard = () => {
    return (
        <>
            <div className="dk-dkglobalFinance-contractsMain">

            </div>
        </>
    )
}

export default ExpenseDashboard;