import React from 'react'
import Logo from "../../../assets/images/dkgcrmlogo-loader.png";
import "../../../assets/css/loader.css"
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export const PageLoader = () => {
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open={true}>
            <div className="initial-load-animation">
                <div className="linkedin-image">
                    <img src={Logo} alt="" />
                </div>
                <div className="loading-bar">
                    <div className="blue-bar"></div>
                </div>
            </div>
        </Backdrop>
    )
}
