import React from "react";
import { Route, Link } from "react-router-dom";

const AgreementSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/my-files/agreements/agreement1" className={`${(segment3 == 'agreement1') ? 'active' : ''}`}>Agreement 1</Link></li>
                        <li><Link to="#/my-files/agreements/agreement2" className={`${(segment3 == 'agreement2') ? 'active' : ''}`}>Agreement 2</Link></li>
                        <li><Link to="#/my-files/agreements/agreement3" className={`${(segment3 == 'agreement3') ? 'active' : ''}`}>Agreement 3</Link></li>
                        <li><Link to="#/my-files/agreements/agreement4" className={`${(segment3 == 'agreement4') ? 'active' : ''}`}>Agreement 4</Link></li>
                        <li><Link to="#/my-files/agreements/agreement5" className={`${(segment3 == 'agreement5') ? 'active' : ''}`}>Agreement 5</Link></li>
                        <li><Link to="#/my-files/agreements/agreement6" className={`${(segment3 == 'agreement6') ? 'active' : ''}`}>Agreement 6</Link></li>
                        <li><Link to="#/my-files/agreements/agreement7" className={`${(segment3 == 'agreement7') ? 'active' : ''}`}>Agreement 7</Link></li>
                        <li><Link to="#/my-files/agreements/agreement8" className={`${(segment3 == 'agreement8') ? 'active' : ''}`}>Agreement 8</Link></li>
                        <li><Link to="#/my-files/agreements/agreement9" className={`${(segment3 == 'agreement9') ? 'active' : ''}`}>Agreement 9</Link></li>
                        <li><Link to="#/my-files/agreements/agreement10" className={`${(segment3 == 'agreement10') ? 'active' : ''}`}>Agreement 10</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default AgreementSubmenu;
