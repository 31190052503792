import React, { useState } from 'react';
import { Link } from "react-router-dom";
import '../financelegalcustom.scss';
import RegistrationLeftPanel from './leftpanel/RegistrationLeftPanel';
import { FaSearch, FaPlus, FaEye, FaDownload, FaFilter } from 'react-icons/fa';
import SearchModal from "./modal/SearchModal";
import AddNewModal from "./modal/AddNewModal";

const LegalRegistration = () => {
    const [isShowSearch, setShowSearchModal] = useState(false)
    const showSearch = () => {
        setShowSearchModal(true)
    }

    const SearchClose = () => {
        setShowSearchModal(false)
    }

    const [isShowAddNew, setShowAddNew] = useState(false)
    const showAddNew = () => {
        setShowAddNew(true)
    }

    const SearchAddNew = () => {
        setShowAddNew(false)
    }
    return (
        <>
            {
                (isShowSearch) ? <SearchModal onHide={SearchClose} /> : null
            }
            {
                (isShowAddNew) ? <AddNewModal onHide={SearchAddNew} /> : null
            }
            <div className="dk-registration-containerMain">
                <RegistrationLeftPanel />

                <div className="dk-registration-rightPanel">
                    <div className="dk-registrationHead">
                        <div className="dk-searchCols">
                            <input type="text" name="" id="" placeholder="Search..." className="form-control" />
                            <button><FaSearch /></button>
                        </div>
                        <div className="title">Registration</div>
                        <div className="d-flex">
                            <div className="dk-searchIocn">
                                <Link to="#" onClick={showSearch}><FaFilter /></Link>
                            </div>
                            <div className="dk-registration-addNew">
                                <Link to="#" onClick={showAddNew}><FaPlus /> Add New</Link>
                            </div>
                        </div>
                    </div>
                    <div className="dk-registration-table">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Start Date</th>
                                    <th>Expire On</th>
                                    <th>Duration</th>
                                    <th>Document Type</th>
                                    <th>Name of Entity/Person</th>
                                    <th>Services Offered</th>
                                    <th>View</th>
                                    <th>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            1
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                        </div>
                                    </td>
                                    <td>02 Sep 22</td>
                                    <td>10 Sep 22</td>
                                    <td>10 Days</td>
                                    <td>test.pdf</td>
                                    <td>Rossella Casciello</td>
                                    <td>Recruitment</td>
                                    <td>
                                        <div className="dk-financeAction">
                                            <Link to="#" className="viewBtn"><FaEye /></Link>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-financeAction">
                                            <Link to="#" className="downloadBtn"><FaDownload /></Link>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LegalRegistration;
