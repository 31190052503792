import React from 'react';
import Submenu from '../../../elements/header/navbar/subMenu/menu/KpiSubmenu';

const KpiSales = () => {
    return (
        <>
            <Submenu />
            <div className="container-fluid dk-adsSummPageCon">
                <div className="row">
                    <h2 className="dk-adspageTitle w-100 text-center">Kpi's Summary</h2>
                </div>
            </div>
        </>
    )
}

export default KpiSales
