import React from 'react';
import ElisProjectTrainingLeftPanel from "../leftpanel/elis/ElisProjectTrainingLeftPanel";
import '../moduleprojects.scss';

const ElisProjectTraining = () => {
    return (
        <>
            <div className="dk-moduleProjects-main">
                <div className="dk-moduleTraining-cols">
                    <ElisProjectTrainingLeftPanel />
                    <div className="dk-moduleTraining-rightPanel">
                        <div className="title">Summary</div>
                        <div className="dk-moduleTraining-rightCols">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ElisProjectTraining;
