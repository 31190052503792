import React from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import "./camedit.css";
// import DummySelectPiker from '../../../ui/SelectPicker/DummySelectPiker';
// import Submit from '../../../ui/submitButton';

const View = ({ onHide }) => {

    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-compaginsEditPop"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Campaign View</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-compaginsEdit">
                    <form>
                        <div className="row">
                            <div className="col-md-3 mr-auto">
                                <div className="form-group">
                                    <label htmlFor="">Recruiter</label>
                                    <label htmlFor="" className="mb-0">
                                        <div className="useFilterCon">
                                            <Link to="#"><i className="fas fa-user"></i></Link>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-9">
                                <div className="form-group">
                                    <label>Job Title</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting" readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Started</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="25-08-2021" readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Expired</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="10-09-2021" readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Job Board</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="EJobs" readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Leads</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="30" readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Sendouts</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="30" readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Pacements</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="30" readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Category</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="Job Board" readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Company</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="TP Greece" readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Project</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="Philip" readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Role Type</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="CS" readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Language</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="German" readOnly />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Location</label>
                                    <input type="text" className="form-control broderContrl" defaultValue="Germany" readOnly />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default View;
