import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../trainingdetail.scss';

const DetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="#" className={`${pathname.match('/training/management') ? 'active' : ''}`}>Overview</Link></li>

                    {/* <li><Link to="#">Step 1</Link></li>
                    <li><Link to="#">Step 2</Link></li>
                    <li><Link to="#">Step 3</Link></li>
                    <li><Link to="#">Step 4</Link></li>
                    <li><Link to="#">Step 5</Link></li>
                    <li><Link to="#">Step 6</Link></li> */}
                </Route>
            </ul>
        </React.Fragment>
    )
}

export default DetailLeftPanel;

