import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown'
import Recruiter from '../../../../../assets/images/deepak.png';

const TaskFor = () => {
    const TaskForDropdown = styled.div`
        .dropdown{
            display: flex;
            justify-content: center;
            align-items: center;
            .dropdown-toggle{
                box-shadow: none;
                padding: 0 !important;
                margin: 0;
                border-radius: 50%;
                height: 35px;
                width: 35px;
                line-height: 30px;
                font-size: 0.81rem;
                cursor: pointer;
                position: relative;
                border: 1px solid #ddd;
                color: #333 !important;
                background-color: #fff !important;
                &.btn-primary:not([disabled]):not(.disabled):active,
               &.btn-primary:not([disabled]):not(.disabled).active, 
               .show>.btn-primary.dropdown-toggle {
                background-color: #fff !important;
                border: 1px solid #ddd !important;
                color: #333 !important;
               }
                img{
                    border-radius: 50%;
                height: 35px;
                width: 35px;
                }
                &:after{
                    display: none;
                }
            }
            .dropdown-menu{
                padding: 0 5px;
                left: 45px !important;
                top: -5px !important;
                -webkit-transform: inherit !important;
                background-color: #234158;
                transform: inherit !important;
                display: flex !important;
                align-items: center;
                width: -moz-fit-content;
                width: fit-content;
                min-width: -moz-fit-content;
                min-width: fit-content;
                &:after{
                    content: "";
                    width: 0;
                    height: 0;
                    border-color: transparent #ccc transparent transparent;
                    border-style: solid;
                    border-width: 8.5px 12px 8.5px 0;
                    line-height: 0;
                    _border-color: #000 #ccc #000 #000;
                    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                    position: absolute;
                    left: -12px;
                    top: 13px;
                }
                .dropdown-item{
                    display: block;
                    width: 100%;
                    padding: 5px !important;
                    clear: both;
                    font-weight: 400;
                    color: #212529;
                    text-align: inherit;
                    white-space: nowrap;
                    background-color: transparent;
                    border: 0;
                    img {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                    }
                }
            }
        }
    `;
    return (
        <>
            <TaskForDropdown>
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        For
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dk-workplanrecDropdownMenu">
                        <Dropdown.Item href="#/action-1">
                            <img src={Recruiter} alt="User" className='dkg-recruiter-img' />
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                            <img src={Recruiter} alt="User" className='dkg-recruiter-img' />
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                            <img src={Recruiter} alt="User" className='dkg-recruiter-img' />
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </TaskForDropdown>
        </>
    )
}

export default TaskFor;