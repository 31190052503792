import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom';
import './view-payment.scss'
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import moment from "moment-timezone";
import 'date-fns';
import { clearState, taskTypeFetch, addNewTask, taskDetails, updateTask } from "../../../../../slice/task/paymentTaskSlice";
import { SYSTEM_CONSTANTS } from "../../../../../constants";
import { FaTimes } from "react-icons/fa";

const ViewPayment = ({ dataRowId, veiwHandleClose }) => {

    const dispatch = useDispatch();
    const { isLoading, paymentTaskList, isMoved, message, isTaskUpdate, isTaskDelete, isUpdate, tasksDetails, isTaskInserted, taskTypeList, iscommentCountUpdate } = useSelector(state => state.paymentTask);

    const { userInfo } = useSelector(state => state.auth)
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [taskBy, setTaskBy] = useState(userInfo.id)
    const [taskType, setPaymentType] = useState(tasksDetails.paymentType)
    const [taskPriority, setTaskPriority] = useState(tasksDetails.priority)
    const [invoiceNo, setInvoiceNo] = useState(tasksDetails.invoiceNo)
    const [paymentTo, setPaymentTo] = useState(tasksDetails.paymentTo)
    const [totalAmount, setTotalAmount] = useState(tasksDetails.paymentAmount)
    const [notes, setNotes] = useState(tasksDetails.paymentNotes)
    const [pickerDueTime, setPickerDueTime] = useState(new Date())
    const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('DD MMM YYYY'))
    const [dueTime, setDueTime] = useState(moment(new Date()).format('hh:m A'))
    const [taskStatus, setTaskStatus] = useState(tasksDetails.status)

    const [show, setShow] = useState(true);

    const handleTimeChange = (date) => {
        setPickerDueTime(date)
        setDueTime(moment(date).format('hh:m A'));
    };

    const changeTaskPriority = (data) => {
        setTaskPriority(data)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            if (!notes || !taskType || !totalAmount) {
                dispatch(showError({ msg: 'All fileds required' }))
            } else {
                setLoading(true);
                await dispatch(updateTask({ id: dataRowId, taskBy, taskFor: 1, status: tasksDetails.status, priority: taskPriority, invoiceNo, paymentType: taskType, paymentTo, paymentAmount: totalAmount, paymentNotes: notes, dueTime, dueDate: selectedDate }))
            }
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }

    }

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(taskTypeFetch({ id: SYSTEM_CONSTANTS.PAYMENT_TYPE.ID }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
        if (isUpdate) {
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }

    }, [isUpdate])

    return (
        <>
            <Modal show={show} onHide={veiwHandleClose} className="dkg-view-paymenttask-modal-21" centered>
                <Modal.Body className="dkg-view-payment-modalbody-21">
                    <Link to="#" onClick={veiwHandleClose} className='closeicon'>
                        <FaTimes />
                    </Link>
                    <div className='form-group' style={{ alignItems: "inherit" }}>
                        <textarea rows="4" className="form-control w-100 taskTitle dkg-readonly-paymentnotes" placeholder="" value={notes} readonly disabled></textarea>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ViewPayment