import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'
import "./dropdownFilter.scss";

const StatusFilter = () => {
    return (
        <React.Fragment>
            <DropdownButton className=" dkg-casestaus-dropdown12" id="dropdown-basic-button" title="Case Status">
                <Dropdown.Item href="#" className='dkg-casestuts-item12' style={{ backgroundColor: "#5c737d", color: "#fff" }}>New Case
                    <span className="dropdown-filter-count">5</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className='dkg-casestuts-item12' style={{ backgroundColor: "#796698", color: "#fff" }}>Job Spec
                    <span className="dropdown-filter-count">5</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className='dkg-casestuts-item12' style={{ backgroundColor: "#55496b", color: "#fff" }}>Calling
                    <span className="dropdown-filter-count">5</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className='dkg-casestuts-item12' style={{ backgroundColor: "#2d9b99", color: "#fff" }}>interested
                    <span className="dropdown-filter-count">5</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className='dkg-casestuts-item12' style={{ backgroundColor: "#286967", color: "#fff" }}>Sendouts
                    <span className="dropdown-filter-count">5</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className='dkg-casestuts-item12' style={{ backgroundColor: "#629bd3", color: "#fff" }}>Job Offer
                    <span className="dropdown-filter-count">0</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className='dkg-casestuts-item12' style={{ backgroundColor: "#4768b0", color: "#fff" }}>Placement
                    <span className="dropdown-filter-count">5</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className='dkg-casestuts-item12' style={{ backgroundColor: "#a84d4d", color: "#fff" }}>Refused Offer
                    <span className="dropdown-filter-count">5</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className='dkg-casestuts-item12' style={{ backgroundColor: "#d9714e", color: "#fff" }}>Credit
                    <span className="dropdown-filter-count">5</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className='dkg-casestuts-item12' style={{ backgroundColor: "#670004", color: "#fff" }}>Not Reachable
                    <span className="dropdown-filter-count">5</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className='dkg-casestuts-item12' style={{ backgroundColor: "#39485d", color: "#fff" }}>Rec Admin
                    <span className="dropdown-filter-count">5</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className='dkg-casestuts-item12' style={{ backgroundColor: "#da3d5e", color: "#fff" }}>Closed
                    <span className="dropdown-filter-count">5</span>
                </Dropdown.Item>
            </DropdownButton>
        </React.Fragment>
    )
}

export default StatusFilter;