import React from 'react';
//import './casestatus.scss';

const CaseProgress = ({ value }) => {
  
    let bgColor = "";
    switch (value) {
        case 'In Progress':
            bgColor = '#d58b5d'
            break;
        case 'Completed':
            bgColor = '#01796f'
            break;
        case 'Open':
            bgColor = '#da3d5e'
            break;
        case 'Closed':
            bgColor = '#da3d5e'
            break;
        case '100% of Fee':
            bgColor = '#da3d5e'
            break;
        case '75% of Fee':
            bgColor = '#da3d5e'
            break;
        case '50% of Fee':
            bgColor = '#da3d5e'
            break;
        case '25% of Fee':
            bgColor = '#da3d5e'
            break;

        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor }}>{value}</div>
        </>
    )
}

export default CaseProgress