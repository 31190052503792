import React, { useState, useEffect } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import Dropdown from 'react-bootstrap/Dropdown';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import './selectdropdwn.scss';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}

    </a>
));

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        useEffect(() => {
            const timer = window.setInterval(() => {
                setValue('')
            }, 10000);
            return () => { // Return callback to run on unmount.
                window.clearInterval(timer);
            };
        }, [])

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className=" w-100"
                    placeholder="Search Text..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);
const SelectDropsDown = (props) => {

    return (
        <>
            <div className="dk-suMMCaseStatusDropDwn">
                <Dropdown className={`dk-candsMMToggleBtn dkg-sendouts-kpi-toggle ${props.class}`}>
                    <ContextMenuTrigger id={`same_unique_identifier${props.column}`}>
                        <Dropdown.Toggle as={CustomToggle}>
                            {props.txt}
                        </Dropdown.Toggle>
                    </ContextMenuTrigger>

                    <Dropdown.Menu as={CustomMenu} className="dk-Drop14Menu dk-dropdown-menuCols dkg-sendouts-kpi-dropdown-menu">
                        {
                            props.list.map((filteredItems, index) =>
                                <Dropdown.Item className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" style={{ backgroundColor: '#000', color: '#fff', borderBottom: '1px solid #ddd', textAlign: 'left' }} data-value={filteredItems.value} data-key={props.column} data-refreshtype={props.refreshType} onClick={props.updateProfile}>{filteredItems.value}</Dropdown.Item>
                            )
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}
export default SelectDropsDown;
