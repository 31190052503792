import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import './style.scss';
import Modal from 'react-bootstrap/Modal';
import { FaTrashAlt } from "react-icons/fa";

import Submit from "../../../../../../../ui/submitButton";

const Delete = ({ deleteItem, loading, success }) => {

    const { screanningDetails, isDelete } = useSelector(state => state.candidatePrecreening);
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
    }

    useEffect(() => {
        if (isDelete) {
            setShow(false);
        }
    }, [isDelete])

    return (
        <React.Fragment>

            <div className="batch-actions-item dk-checkAll dkg-delete-tab-12" onClick={handleShow} style={{ borderRight: "none" }}>
                <span onClick={handleShow}><i className="far fa-trash-alt"></i></span>
                Delete
            </div>
            {/* <Link to="#" className='dkg-delete-btn' onClick={handleShow}><FaTrashAlt /></Link> */}
            <Modal className="dk-mlAddNewModal dk-deleteModal" show={show} onHide={handleClose}>
                <Modal.Body className="dk-mlAddModalBody">
                    <p>Are you sure wants to Delete these records ?</p>
                    <div className="d-flex justify-content-end">
                        <button onClick={handleClose}>No</button>
                        <Submit txt="Yes" loading={loading} success={success} onClick={deleteItem} position="justify-content-center" className="dk-yesBtn" />
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default Delete;