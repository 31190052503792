import React from 'react';
import { Link } from 'react-router-dom'
import './adssummary.scss'

const Summary = () => {
    return (
        <>
            <div className="dk-adsSummary-main">
                <div className="dk-adsSummary">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="dk-adsSummaryCols">
                                <div className="title">Ad Posting Portals</div>
                                <div className="dk-adsSummaryPanel">
                                    50
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-adsSummaryCols">
                                <div className="title">CV Search Portals</div>
                                <div className="dk-adsSummaryPanel">
                                    30
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-adsSummaryCols">
                                <div className="title">SOCIAL MEDIA ADS</div>
                                <div className="dk-adsSummaryPanel">
                                    20
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-adsSummaryCols">
                                <div className="title">SOCIAL GROUPS</div>
                                <div className="dk-adsSummaryPanel">
                                    60
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-adsSummaryCols">
                                <div className="title">TODAY POSTINGS</div>
                                <div className="dk-adsSummaryPanel">
                                    25
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-adsSummaryCols">
                                <div className="title">Live ADS</div>
                                <div className="dk-adsSummaryPanel">
                                    35
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-adsSummaryCols">
                                <div className="title">Expired ADS</div>
                                <div className="dk-adsSummaryPanel">
                                    80
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-adsSummaryCols">
                                <div className="title">Saved ADS</div>
                                <div className="dk-adsSummaryPanel">
                                    70
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-adsSummaryCols">
                                <div className="title">Indeed Credits</div>
                                <div className="dk-adsSummaryPanel">
                                    90
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-adsSummaryCols">
                                <div className="title">ELJ redits</div>
                                <div className="dk-adsSummaryPanel">
                                    75
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-adsSummaryCols">
                                <div className="title">MLV Credits</div>
                                <div className="dk-adsSummaryPanel">
                                    40
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-adsSummaryCols">
                                <div className="title">Ejobs Credits</div>
                                <div className="dk-adsSummaryPanel">
                                    10
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Summary
