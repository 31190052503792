import React, { useState, useEffect } from 'react'
//import { useDispatch, useSelector } from "react-redux";
import { Modal, Tabs, Tab } from 'react-bootstrap'
//import { Link } from 'react-router-dom'
import './projects.scss'
import EditRoleList from './EditRoleType'
import ReorderRoleList from './ReorderRoleList'
import DeleteRoleList from './DeleteRoleType'

const ProjectList = ({ list, id }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>


            {/* <Link to="#">
                <i className="fa fa-plus" aria-hidden="true"></i>
            </Link> */}
            {/* <i className="far fa-bar" ></i> */}
            <i className="fa fa-bars" aria-hidden="true" onClick={handleShow}></i>
            <Modal show={show} onHide={handleClose} className="dkg-listview-compysumm-Modal" centered>
                <Modal.Header closeButton className="dkg-listview-compysumm-ModalHeader">
                    <Modal.Title>Role Type List</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-projectslist-modalBody">
                    <Tabs defaultActiveKey="edit" id="uncontrolled-tab-example" className="mb-3">
                        <Tab eventKey="edit" title="Edit">
                            <EditRoleList list={list} />
                        </Tab>
                        <Tab eventKey="reorder" title="Reorder">
                            <div className="dk-toolsValuesPanel dk-toolsReorderPanel">
                                <ReorderRoleList dataList={list} id={id} />
                            </div>
                        </Tab>
                        <Tab eventKey="delete" title="Delete">
                            <DeleteRoleList list={list} />
                        </Tab>
                    </Tabs>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default ProjectList