import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData, DeleteData } from "../../services";
import { SYSTEM_CONSTANTS } from "../../constants";

export const commentStage = createAsyncThunk(
    'comment/list/comment',
    async (thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value-category/4/' + SYSTEM_CONSTANTS.COMMENT.StageId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const commentFunSubject = createAsyncThunk(
    'comment/list/commentsubject',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const commentList = createAsyncThunk(
    'comment/list',
    async ({ ref, refId }, thunkAPI) => {
        const res = await GetData(true, '/comments/' + ref + '/' + refId)
        if (res.status == 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const addComment = createAsyncThunk(
    'comment/add',
    async ({ ref, refId, parentId, details, caseStatus: statusValue, caseProgress: statusProgress, caseStage, subject, date }, thunkAPI) => {
        const res = await PostData(true, '/comments', { ref, refId, parentId, details, caseStatus: statusValue, caseProgress: statusProgress, caseStage, subject, date })
        if (res.status == 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const commentDelete = createAsyncThunk(
    'comment/delete',
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, '/comments/' + id)
        if (res.status == 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const commentDetails = createAsyncThunk(
    'comment/details',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/comments/' + id)
        if (res.status == 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateComments = createAsyncThunk(
    'comment/update',
    async ({ id, details, caseStatus, caseProgress, caseStage, subject, date }, thunkAPI) => {
        const res = await PutData(true, '/comments/' + id, { details, caseStatus, caseProgress, caseStage, subject, date })
        if (res.status == 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const CommentSlice = createSlice({
    name: 'Comments',
    initialState: {
        orinalComment: [],
        comments_1: [],
        comments: [],
        commentstage: [],
        commentsubjectlist: [],
        details: {},
        isCommentLoading: false,
        isSuccess: false,
        isUpdate: false,
        isInsert: false,
        isCommentInsert: false,
        isCommentDelete: false,
        isDelete: false,
        isSubject: false,
        isError: false,
        message: ''
    },
    reducers: {
        clearState: (state) => {
            state.isCommentLoading = false;
            state.isError = false;
            state.isSubject = false;
            state.isSuccess = false;
            state.isUpdate = false;
            state.isInsert = false;
            state.isCommentDelete = false;
            state.isCommentInsert = false;
            state.isDelete = false;
            state.message = '';
        }
    },
    extraReducers: (builder) => {

        builder.addCase(
            commentFunSubject.pending, (state) => {
                state.isSubject = false;
            });
        builder.addCase(
            commentFunSubject.fulfilled, (state, { payload }) => {
                state.isSubject = true;
                state.commentsubjectlist = payload.result
            });
        builder.addCase(
            commentFunSubject.rejected, (state, action) => {
                state.isSubject = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            commentStage.pending, (state) => {
            });
        builder.addCase(
            commentStage.fulfilled, (state, { payload }) => {
                state.commentstage = payload.result
            });
        builder.addCase(
            commentStage.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            commentList.pending, (state) => {
                state.isCommentLoading = true;
            });
        builder.addCase(
            commentList.fulfilled, (state, { payload }) => {
                state.isCommentLoading = false;
                state.orinalComment = payload.result;
                state.comments_1 = payload.result;
                state.comments = getSubItems(state.comments_1)
            });
        builder.addCase(
            commentList.rejected, (state, action) => {
                state.isCommentLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addComment.pending, (state) => {
                state.isCommentInsert = false;
                state.isInsert = false;
            });
        builder.addCase(
            addComment.fulfilled, (state, { payload }) => {
                state.isInsert = true;
                state.isCommentInsert = true;
                state.orinalComment.unshift(payload.result)
                state.comments = getSubItems(state.orinalComment);
                state.message = 'Comment added';
            });
        builder.addCase(
            addComment.rejected, (state, action) => {
                state.isInsert = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            commentDelete.pending, (state) => {
                state.isDelete = false;
                state.isCommentDelete = false;
            });
        builder.addCase(
            commentDelete.fulfilled, (state, action) => {
                state.isDelete = true;
                state.isCommentDelete = true;
                state.orinalComment = state.orinalComment.filter((item) => item.id !== action.meta.arg.id)
                state.comments = getSubItems(state.orinalComment)
            });
        builder.addCase(
            commentDelete.rejected, (state, action) => {
                state.isDelete = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            commentDetails.pending, (state) => {
            });
        builder.addCase(
            commentDetails.fulfilled, (state, { payload }) => {
                state.isSuccess = true;
                state.details = payload.result
            });
        builder.addCase(
            commentDetails.rejected, (state, action) => {
                state.isSuccess = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateComments.pending, (state) => {
            });
        builder.addCase(
            updateComments.fulfilled, (state, { payload }) => {
                state.isUpdate = true;
                let mainArray = state.orinalComment;
                let finalResult = mainArray.map((r) => {
                    if (r.id == payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.orinalComment = finalResult
                state.comments = getSubItems(state.orinalComment)
                state.details = {}
            });
        builder.addCase(
            updateComments.rejected, (state, action) => {
                state.isUpdate = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
    }
});

function getSubItems(categories, parentId = '0') {
    const itemsList = []
    let item;
    if (parentId == '0') {
        item = categories.filter(cat => cat.parentId == '0')
    } else {
        item = categories.filter(cat => cat.parentId == parentId)
    }
    for (let cate of item) {
        itemsList.push({
            id: cate.id,
            parentId: cate.parentId,
            details: cate.details,
            caseStatus: cate.caseStatus,
            caseProgress: cate.caseProgress,
            caseStage: cate.caseStage,
            subject: cate.subject,
            createdAt: cate.createdAt,
            updatedAt: cate.updatedAt,
            userInfo: cate.userInfo,
            children: getSubItems(categories, cate.id)
        })
    }
    return itemsList;
}

export const { clearState } = CommentSlice.actions;
export default CommentSlice.reducer;