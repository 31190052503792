import React, { useState, useEffect } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { useSelector } from "react-redux";
import "./dropdownFilter.scss";

const TaskTypeFilter = ({ list, selectStatusFilter, taskStatusFilterText }) => {
    const [userList, setUserList] = useState([]);
    useEffect(() => {
        const result = [];
        const map = new Map();
        for (const item of list) {
            if (!map.has(item.original.type)) {
                map.set(item.original.type, true);
                result.push({
                    id: item.original.type,
                    name: item.original.type,
                    count: list.filter(function (val) {
                        return val.original.type === item.original.type;
                    }).length
                });
            }
        }
        result.sort(function (a, b) {
            return b.count - a.count;
        });
        setUserList(result)
    }, [list])

    //  console.log(userList);


    return (
        <React.Fragment>
            <DropdownButton className="dropdownFilter" id="dropdown-basic-button" title={taskStatusFilterText}>
                {
                    userList && userList.map((item, index) =>
                        <Dropdown.Item key={`case-status-id${index}`} href="#" data-type="caseStatus" data-value={item.name} onClick={selectStatusFilter}>
                            {item.name}
                            <span className="dropdown-filter-count">
                                {item.count}
                            </span>
                        </Dropdown.Item>
                    )
                }
            </DropdownButton>
        </React.Fragment>
    )
}

export default TaskTypeFilter;