import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const OnurSubmenu = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    const segment4 = pathname.split("/")[4];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/projects/deepak/project_1" className={(segment3 == 'project_1') ? 'active' : ''}>Project 1</Link></li>
                        <li><Link to="/projects/deepak/project_2" className={`${(segment3 == 'project_2') ? 'active' : ''}`}>Project 2</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default OnurSubmenu;
