import React from 'react';
import MailboxSubmenu from '../../../elements/header/navbar/subMenu/menu/mainsubmenu/MailboxSubmenu';

const Template = () => {
    return (
        <>
            <MailboxSubmenu />
            <div className='container-fluid' style={{ marginTop: "155px" }}>
                <h3 className='text-center w-100'> </h3>
            </div>
        </>
    )
}

export default Template;
