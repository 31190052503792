import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  //defaultJobofferProject,
  updateJobofferValue,
} from "../../../../../../slice/jobSpec/jobSpecSlice";
//import { showError } from "../../../../../utils/messages/messageSlice";
const JobOfferTab = ({ jobId, clientid }) => {
  const dispatch = useDispatch();
  const { isJobofferSuccess, isLoading, jobfferRelationData, jobofferList } =
    useSelector((state) => state.jobsSpec);
  //const { companyDetails } = useSelector((state) => state.companies);
  const [selectedId, setSelectedId] = useState(null);
  const [joboferlist, setJoboffer] = useState([]);

  useEffect(() => {
    if (isJobofferSuccess && jobofferList.length > 0) {
      setSelectedId(jobofferList[0].companyId);
    }
  }, [isJobofferSuccess]);

  useEffect(() => {
    if (!isLoading && jobfferRelationData.length > 0) {
      setJoboffer(jobfferRelationData);
    }
  }, [isLoading]);

  const handleChange = (id, companyname, companyidds) => {
    setSelectedId(id);
    dispatch(
      updateJobofferValue({
        id: id,
        key: "isShowInJob",
        value: 1,
        jobsId: jobId,
        clientId: 0,
        clientname: companyname,
        tableName: "jobs_relationship_joboffers",
      })
    );
  };

  return (
    <>
      <div className="dkg-company-list-mainCon">
        <div className="dkg-company-list-tableCon table-responsive">
          <table className="dkg-company-list-table table table-borderded">
            <thead>
              <tr>
                <th>Job Offer</th>
                <th>Linked</th>
              </tr>
            </thead>
            <tbody>
              {joboferlist.map((details, index) => (
                <tr key={index + 1}>
                  <td>{details.categoryName}</td>
                  <td>
                    <span className="dkg-clientinfo-checkbox">
                      <input
                        type="radio"
                        className="form-control"
                        name="clientinfo-checkbox-jobboffers"
                        data-id={details.id}
                        data-companyname={details.categoryName}
                        id={details.id}
                        checked={selectedId === details.id}
                        onChange={() =>
                          handleChange(details.id, details.categoryName)
                        }
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default JobOfferTab;
