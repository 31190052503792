import React, { useState } from 'react';
import "./dailyapps.css";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import Constant from "../../../../constants";

const TRACKER_JOB_BOARD_ELJ = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.ELJ;
const TRACKER_JOB_BOARD_MLV = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.MLV;
const TRACKER_JOB_BOARD_HIPO = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.HIPO;
const TRACKER_JOB_BOARD_DK_WEBSITE = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.DK_WEBSITE;
const TRACKER_JOB_BOARD_LINKEDIN = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.LINKEDIN;
const TRACKER_JOB_BOARD_INDEED = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.INDEED;
const TRACKER_JOB_BOARD_EJOBS = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.EJOBS;
const TRACKER_JOB_BOARD_FACEBOOK = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.FACEBOOK;
const TRACKER_JOB_BOARD_DIRECT_EMAIL = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.DIRECT_EMAIL;
const TRACKER_JOB_BOARD_LEARN4GOOD = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.LEARN4GOOD;
const TRACKER_JOB_BOARD_EUROJOBS = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.EUROJOBS;
const TRACKER_JOB_BOARD_CAREERS4A = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.CAREERS4A;
const TRACKER_JOB_BOARD_JOBFIND = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.JOBFIND;
const TRACKER_JOB_BOARD_BAKECA = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.BAKECA;
const TRACKER_JOB_BOARD_BESTJOBS = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.BESTJOBS;
const TRACKER_JOB_BOARD_PROFESIA_CZ = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.PROFESIA_CZ;
const TRACKER_JOB_BOARD_PROFESIA_SK = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.PROFESIA_SK;
const TRACKER_JOB_BOARD_JOOBLE = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.JOOBLE;
const TRACKER_JOB_BOARD_NET_EMPREGOS = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.NET_EMPREGOS;
const TRACKER_JOB_BOARD_PRACUJ_PL = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.PRACUJ_PL;
const TRACKER_JOB_BOARD_PURE_CHAT = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.PURE_CHAT;
const TRACKER_JOB_BOARD_CAREER_BUILDER = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.CAREER_BUILDER;
const TRACKER_JOB_BOARD_3_MIN_JOB = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.MIN_JOB;
const TRACKER_JOB_BOARD_SOF_TEST = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.SOF_TEST;

const DailyappsTable = () => {
    const dispatch = useDispatch();
    const { appsTrackerWeekly } = useSelector(state => state.appsTracker)

    const weeklyTrackerFunction = (array, jobBoard, day) => {
        let mainArray = array;
        let finalResult = mainArray.map((r) => {
            if (r.jobBoard === jobBoard && r.day === day) {
                return ({ val: r.value, id: r.id })
            } else {
                return ({ val: null, id: null })
            }
        })
        let non_zero = finalResult.filter((item) => item.val !== null);
        if (non_zero.length > 0) {
            return <td>{non_zero.reduce((a, b) => +a + +b.val, 0)}</td>
        } else {
            return <td></td>
        }
    }

    const weeklyTrackerFooterFum = (array, day) => {
        if (day == "") {
            let mainArray = array.filter((item) => item.day != '')
            return mainArray.reduce((a, b) => +a + +b.value, 0);
        } else {
            let mainArray = array.filter((item) => item.day === day)
            return mainArray.reduce((a, b) => +a + +b.value, 0);
        }
    }

    const dailyTrackerSideFun = (array, jobBoard) => {
        let mainArray = array.filter((item) => item.jobBoard === jobBoard)
        return mainArray.reduce((a, b) => +a + +b.value, 0);
    }

    const searchFunction = (e) => {
        var value = (e.target.value).toLowerCase();
        $("#filterTracker tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    }

    return (
        <>
            <div className="dk-dailyAppstableCont table-responsive">
                <table className="table dk-dailyAppstable">
                    <thead>
                        <tr>
                            {/* <th>&nbsp;</th> */}
                            <th>
                                <div className="dk-appstrackTableSearch">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search..." onChange={searchFunction} />
                                        <div className="input-group-append">
                                            <button className="btn btn-secondary" type="button">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </th>
                            <th>Monday</th>
                            <th>Tuesday </th>
                            <th>Wednesday </th>
                            <th>Thursday</th>
                            <th>Friday</th>
                            <th>Saturday</th>
                            <th>Sunday </th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody id="filterTracker">
                        <tr>
                            <td>ELJ</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_ELJ, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_ELJ, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_ELJ, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_ELJ, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_ELJ, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_ELJ, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_ELJ, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_ELJ)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>MLV</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_MLV, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_MLV, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_MLV, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_MLV, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_MLV, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_MLV, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_MLV, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_MLV)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>HIPO</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_HIPO, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_HIPO, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_HIPO, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_HIPO, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_HIPO, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_HIPO, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_HIPO, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_HIPO)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>DK Website</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_DK_WEBSITE)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Linkedln</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_LINKEDIN, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_LINKEDIN, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_LINKEDIN, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_LINKEDIN, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_LINKEDIN, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_LINKEDIN, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_LINKEDIN, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_LINKEDIN)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Indeed</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_INDEED, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_INDEED, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_INDEED, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_INDEED, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_INDEED, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_INDEED, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_INDEED, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_INDEED)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Ejobs</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_EJOBS, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_EJOBS, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_EJOBS, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_EJOBS, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_EJOBS, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_EJOBS, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_EJOBS, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_EJOBS)
                                }
                            </td>
                        </tr>

                        <tr>
                            <td>Facebook</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_FACEBOOK, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_FACEBOOK, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_FACEBOOK, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_FACEBOOK, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_FACEBOOK, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_FACEBOOK, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_FACEBOOK, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_FACEBOOK)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Direct Email</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_DIRECT_EMAIL)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Learn4Good</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_LEARN4GOOD)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Eurojobs</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_EUROJOBS, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_EUROJOBS, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_EUROJOBS, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_EUROJOBS, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_EUROJOBS, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_EUROJOBS, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_EUROJOBS, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_EUROJOBS)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Careers4A</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_CAREERS4A, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_CAREERS4A, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_CAREERS4A, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_CAREERS4A, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_CAREERS4A, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_CAREERS4A, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_CAREERS4A, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_CAREERS4A)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Jobfind</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_JOBFIND, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_JOBFIND, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_JOBFIND, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_JOBFIND, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_JOBFIND, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_JOBFIND, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_JOBFIND, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_JOBFIND)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Bakeca</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_BAKECA, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_BAKECA, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_BAKECA, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_BAKECA, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_BAKECA, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_BAKECA, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_BAKECA, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_BAKECA)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>BestJobs</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_BESTJOBS, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_BESTJOBS, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_BESTJOBS, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_BESTJOBS, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_BESTJOBS, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_BESTJOBS, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_BESTJOBS, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_BESTJOBS)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Profesia.cz</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_PROFESIA_CZ)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Profesia.sk</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_PROFESIA_SK)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Jooble</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_JOOBLE, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_JOOBLE, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_JOOBLE, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_JOOBLE, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_JOOBLE, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_JOOBLE, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_JOOBLE, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_JOOBLE)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>NetEmpregos</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_NET_EMPREGOS)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Pracuj.pl</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_PRACUJ_PL)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Purechat</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PURE_CHAT, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PURE_CHAT, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PURE_CHAT, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PURE_CHAT, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PURE_CHAT, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PURE_CHAT, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_PURE_CHAT, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_PURE_CHAT)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Careerbuilder</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_CAREER_BUILDER)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>3min job</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_3_MIN_JOB)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Softest</td>
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_SOF_TEST, 'Monday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_SOF_TEST, 'Tuesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_SOF_TEST, 'Wednesday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_SOF_TEST, 'Thursday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_SOF_TEST, 'Friday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_SOF_TEST, 'Saturday')
                            }
                            {
                                weeklyTrackerFunction(appsTrackerWeekly, TRACKER_JOB_BOARD_SOF_TEST, 'Sunday')
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerWeekly, TRACKER_JOB_BOARD_SOF_TEST)
                                }
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>
                                {
                                    weeklyTrackerFooterFum(appsTrackerWeekly, 'Monday')
                                }
                            </td>
                            <td>
                                {
                                    weeklyTrackerFooterFum(appsTrackerWeekly, 'Tuesday')
                                }
                            </td>
                            <td>
                                {
                                    weeklyTrackerFooterFum(appsTrackerWeekly, 'Wednesday')
                                }
                            </td>
                            <td>
                                {
                                    weeklyTrackerFooterFum(appsTrackerWeekly, 'Thursday')
                                }
                            </td>
                            <td>
                                {
                                    weeklyTrackerFooterFum(appsTrackerWeekly, 'Friday')
                                }
                            </td>
                            <td>
                                {
                                    weeklyTrackerFooterFum(appsTrackerWeekly, 'Saturday')
                                }
                            </td>
                            <td>
                                {
                                    weeklyTrackerFooterFum(appsTrackerWeekly, 'Sunday')
                                }
                            </td>
                            <td>
                                {
                                    weeklyTrackerFooterFum(appsTrackerWeekly, '')
                                }
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    )
}

export default DailyappsTable;
