import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import "./visainfonavbar.scss";

const VisainfoMainMenu = () => {
  const pathname = window.location.pathname;
  const segment2 = pathname.split("/")[2];
  const segment4 = pathname.split("/")[4];

  useEffect(() => {
    document.title =
      pathname.split("/")["1"].charAt(0).toUpperCase() +
      pathname.split("/")["1"].slice(1) +
      " | DK Global CRM";
  }, [pathname]);

  // const { allBadge } = useSelector(state => state.tasks);
  return (
    <React.Fragment>
      <div className="dk-navBarMain dk-recruitmentNavbar">
        <Route>
          <ul>
            <li>
              <Link
                to="/visa-info/summary"
                className={`${segment2 === "summary" ? "white-active" : ""}`}
              >
                Summary
              </Link>
            </li>
            <li>
              <Link
                to="/visa-info/live-specs"
                className={`${segment2 === "live-specs" ? "white-active" : ""}`}
              >
                Live Specs
              </Link>
            </li>
            <li>
              <Link
                to="/visa-info/active-spec"
                className={`${
                  segment2 === "active-spec" || segment4 === "Active"
                    ? "white-active"
                    : ""
                }`}
              >
                Active Specs
              </Link>
            </li>
            <li>
              <Link
                to="/visa-info/inactive-spec"
                className={
                  segment2 === "inactive-spec" || segment4 === "Inactive"
                    ? "white-active"
                    : ""
                }
              >
                Inactive Specs
              </Link>
            </li>
            <li>
              <Link
                to="/visa-info/create-spec"
                className={`${segment2 === "create-spec" ? "active" : ""}`}
              >
                Create Spec
              </Link>
            </li>
            <li>
              <Link
                to="#/visa-info/profiling"
                className={`${pathname.match("/profiling") ? "active" : ""}`}
              ></Link>
            </li>
            <li>
              <Link
                to="#/visa-info/residency"
                className={`${pathname.match("/residency") ? "active" : ""}`}
              ></Link>
            </li>
            <li>
              <Link
                to="#/visa-info/married"
                className={`${pathname.match("/married") ? "active" : ""}`}
              ></Link>
            </li>
            <li>
              <Link
                to="#/visa-info/profiling"
                className={`${pathname.match("/profiling") ? "active" : ""}`}
              ></Link>
            </li>
            <li>
              <Link
                to="#/visa-info/profiling"
                className={`${pathname.match("/profiling") ? "active" : ""}`}
              ></Link>
            </li>
            <li>
              <Link
                to="#/visa-info/countries"
                className={`${pathname.match("/countries") ? "active" : ""}`}
              ></Link>
            </li>
          </ul>
        </Route>
      </div>
    </React.Fragment>
  );
};

export default VisainfoMainMenu;
