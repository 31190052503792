import React, { useState } from 'react';
import { Link } from "react-router-dom";
import OverviewImg from '../../../../../assets/images/business_icon.png';
import ClientsImg from '../../../../../assets/images/clients_icon.png';
import JobsImg from '../../../../../assets/images/jobs_icon.png';
import OutlookImg from '../../../../../assets/images/outlook_icon.png';
import ProcessImg from '../../../../../assets/images/process_icon.png';
import EmailsImg from '../../../../../assets/images/emails_icon.png';
import CallsImg from '../../../../../assets/images/calls_icon.png';
import SmsImg from '../../../../../assets/images/sms_icon.png';
import CommonIcon from '../../../../../assets/images/training_black.png';
import CandidatesImg from '../../../../../assets/images/candidates_icon.png';
import StructureImg from '../../../../../assets/images/structure.png';
import TrainingImg from '../../../../../assets/images/dkg-guideline.png';
import PipelinesImg from '../../../../../assets/images/pipeline_icon.png';
import ToolsImg from '../../../../../assets/images/tools_icon.png';
import MasterboxImg from '../../../../../assets/images/masterbox.png';
import ChatsImg from '../../../../../assets/images/chats_icon.png';
import WorkPlanImg from '../../../../../assets/images/workplan_icon.png';
import MyTaskImg from '../../../../../assets/images/task.png';
import SalesImg from '../../../../../assets/images/sales_icon.png';
import ResourcesImg from '../../../../../assets/images/resources_icon.png';
import InvoiceImg from '../../../../../assets/images/euro-bluebg.png';

const CommonTraining = () => {
    return (
        <>
            <div className="dk-commonTrainingMain dk-dkgCommonTrainingHv">
                <div className="page-header">
                    <h3 className="title">
                        <img src={CommonIcon} className="img-fluid" alt="" />
                        DKG COMMON TRAINING
                    </h3>
                </div>
                <div className="dk-trainingBoxMain">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/overview">
                                    <figure>
                                        <img src={OverviewImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Overview</h3>
                                </Link>
                            </div>
                        </div>
                        {/* <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/structure">
                                    <figure>
                                        <img src={StructureImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG Structure</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/process">
                                    <figure>
                                        <img src={ProcessImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG Process</h3>
                                </Link>
                            </div>
                        </div> */}
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/dkg-pipelines">
                                    <figure>
                                        <img src={PipelinesImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG Pipelines</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/dkg-guidelines">
                                    <figure>
                                        <img src={TrainingImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG Guidelines</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/common-clients">
                                    <figure>
                                        <img src={ClientsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG Clients</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/jobs">
                                    <figure>
                                        <img src={JobsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG Jobs</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/candidates">
                                    <figure>
                                        <img src={CandidatesImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG Leads</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/masterbox">
                                    <figure>
                                        <img src={MasterboxImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG CRM</h3>
                                </Link>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/tools">
                                    <figure>
                                        <img src={ToolsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG Tools</h3>
                                </Link>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/work-plan">
                                    <figure>
                                        <img src={WorkPlanImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Work Plan</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/my-task">
                                    <figure>
                                        <img src={MyTaskImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>My Tasks</h3>
                                </Link>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/resource">
                                    <figure>
                                        <img src={ResourcesImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Resource</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/sales">
                                    <figure>
                                        <img src={SalesImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Sales</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/payments">
                                    <figure>
                                        <img src={InvoiceImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Payments</h3>
                                </Link>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/outlook">
                                    <figure>
                                        <img src={OutlookImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Outlook</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/emails">
                                    <figure>
                                        <img src={EmailsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Emails</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/calls">
                                    <figure>
                                        <img src={CallsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Calls</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/sms">
                                    <figure>
                                        <img src={SmsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>SMS</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="/dkglobaladmin/admintraining/commontraining/chats">
                                    <figure>
                                        <img src={ChatsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Chats</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="#">
                                    <figure>

                                    </figure>
                                    <h3></h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="#">
                                    <figure>

                                    </figure>
                                    <h3></h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="#">
                                    <figure>

                                    </figure>
                                    <h3></h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="#">
                                    <figure>

                                    </figure>
                                    <h3></h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="os-box">
                                <Link to="#">
                                    <figure>

                                    </figure>
                                    <h3></h3>
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </>
    )
}

export default CommonTraining;
