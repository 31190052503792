import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown'
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import './qualify.scss';
import AddNewModal from './modal/AddNew';
import ViewModal from './modal/View';
import EditModal from './modal/Edit';
import DeleteModal from '../../../ui/Modal/deletemodal/DeleteModal';
import { showSuccess } from "../../../../slice/utils/message/messageSlice";
import TableLoader from "../../../ui/tableLoader";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import { listTable, qualifyCatList, qualifyFrequencyList, clearState, deleteQualify, fethById } from '../../../../slice/qualify/qualifySlice';
import { GlobalSearch } from "./modal/GlobalSearch";
import FilterSelectInstance from '../../../ui/SelectPicker/FilterSelectInstance';
import CategoryList from "./modal/CategoryList"
import FrequencyModel from "./modal/FrequencyList"
import ReorderModal from "./modal/Reorder";


function Table({ loading, columns, data, title, isInsert, isUpdate, isDataDelete }) {
    const [isAddNew, setIsAddNew] = useState(false);
    const [isReorderModal, setIReorderModal] = useState(false);

    const showreorderModal = () => {
        setIReorderModal(true);
    }
    const closeReorderModal = () => {
        setIReorderModal(false);
    }

    const handleChangeSelect = (event, data) => {
        const { name, value } = data
        switch (name) {
            case "categoryId":
                setFilter(name, value)
                break;
        }
    }


    const tableInstance = useTable({
        columns: columns,
        data: data,
        initialState: {
            pageIndex: 0, pageSize: 100, hiddenColumns: ["categoryId"]
        },
    }, useGlobalFilter, useFilters, useSortBy, usePagination)

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, state, setGlobalFilter, setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize } } = tableInstance
    const { globalFilter } = state

    useEffect(() => {
        setGlobalFilter('0')
    }, [])

    useEffect(() => {
        if (isInsert || isUpdate || isDataDelete) {
            setGlobalFilter('0')
        }
    }, [isInsert, isUpdate, isDataDelete])

    const showNewAddModal = () => {
        setIsAddNew(true)
    }
    const hideaddnewModal = () => {
        setIsAddNew(false);
    }

    return (
        <React.Fragment>
            {
                (isAddNew) ? <AddNewModal onHide={hideaddnewModal} /> : null
            }
            {
                (isReorderModal) ? <ReorderModal onHide={closeReorderModal} dataList={data} /> : null
            }
            <div className="dk-qulaifyTopHead">
                <div className="dk-qulaifyLeftTopHead">
                    <div className="dk-SrchInputBox mr-2">
                        <GlobalSearch filter={globalFilter} setFilter={setGlobalFilter} />
                    </div>
                    <button className="dk-cricleBtn" onClick={showNewAddModal}>
                        <i className="fa fa-plus"></i>
                    </button>
                </div>
                <div className="dk-qulaifyTopHeadTitle">
                    <h2 className="title">Qualify</h2>
                </div>
                <div className="dk-qulaifyRightTopHead">
                    <button className="dk-reoderBtn mr-2" onClick={showreorderModal}>
                        <i className="fa fa-arrows-alt"></i>
                    </button>
                    <div className="dk-qulaifyRightFilter">
                        <FilterSelectInstance list={tableInstance.flatRows} type="qualifyFilterCategory" placeholder="Category" name="categoryId" onChange={handleChangeSelect} />
                    </div>
                </div>
            </div>
            <div className="dk-qualifyTableCon">
                <div className="dk-adsTrackTableCon table-responsive">
                    <table className="table dk-qualifyTable" {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        // Add the sorting props to control sorting. For this example
                                        // we can add them into the header props
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        {(loading) ? <TableLoader colSpan="6" /> : <tbody {...getTableBodyProps()}>
                            {page.map(
                                (row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                )
                                            })}
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>
                        }
                    </table>
                </div>
            </div>

        </React.Fragment>
    )
}


const Qualify = () => {
    const [isAddNew, setisAddNew] = useState(false);
    const [isReorderModal, setIsReorderModal] = useState(false);
    const [isViewModal, setIsViewModal] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false)
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const { dataList, qualifyCategoryList, frequencyDataList, isDataLoading, isLoading, isInsert, isUpdate, isError, isDelete, isReoder, msg } = useSelector(state => state.qualify)
    const [categoryList, setCategoryList] = useState([]);
    const [rowId, setRowId] = useState('0')

    useLayoutEffect(() => {
        async function fetchData() {
            try {
                await dispatch(qualifyCatList({}))
                await dispatch(qualifyFrequencyList({}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [])
    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(listTable({}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (!isDataLoading && dataList && dataList.length > 0) {
            setData(dataList)
        } else {
            setData(dataList)
        }
        if (isReoder) {
            setData(dataList)
        }
    }, [isDataLoading, isInsert, isUpdate, isDelete, isReoder])

    useEffect(() => {
        if (!isLoading) {
            setCategoryList(qualifyCategoryList)
        }
    }, [isLoading])

    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Delete successfully.' }))
            setRowId('0')
            setIsDeleteModal(false);
        }
    }, [isDelete])

    // const showviewModal = () => {
    //     setIsViewModal(true);
    // }
    const hideviewModal = () => {
        setIsViewModal(false);
    }
    const showeditModal = async (id) => {
        await dispatch(fethById({ id }))
        setRowId(id)
        setIsEditModal(true)
    }
    const hideeditModal = () => {
        setIsEditModal(false);
    }
    const showdeleteModal = (id) => {
        setRowId(id)
        setIsDeleteModal(true);
    }
    const hidedeleteModal = () => {
        setIsDeleteModal(false);
    }
    const hideReorderModel = () => {
        setIsReorderModal(false);
    }
    const deleteItem = async () => {
        await dispatch(deleteQualify({ id: rowId }))
    }
    const closeDelete = () => {
        setIsDeleteModal(false)
    }

    const columns = useMemo(() => [
        {
            Header: 'SNo.',
            title: 'SNo.',
            Cell: (row, { cell }) => (
                <React.Fragment>
                    {Number(row.row.id) + 1}
                </React.Fragment >
            )
        },
        {
            id: 'categoryId',
            Header: 'Category id',
            accessor: 'categoryId._id',
            show: false
        },
        {
            Header: 'Category',
            accessor: 'categoryId.value',
            Cell: ({ cell }) => (
                <div className="dk-freqDrpdwnCon">
                    <Dropdown className="dk-freqDropDwon">
                        <Dropdown.Toggle variant="" id="dropdown-basic" style={{ backgroundColor: cell.row.original.categoryId.bgColor, color: cell.row.original.categoryId.textColor }}>
                            {cell.row.original.categoryId.value}
                        </Dropdown.Toggle>

                        <CategoryList id={cell.row.original._id} />

                    </Dropdown>
                </div>
            )
        },
        {
            Header: 'Frequency',
            accessor: 'frequency.value',
            Cell: ({ cell }) => (
                <div className="dk-freqDrpdwnCon">
                    <Dropdown className="dk-freqDropDwon">
                        <Dropdown.Toggle variant="" id="dropdown-basic" style={{ backgroundColor: cell.row.original.frequency.bgColor, color: cell.row.original.frequency.textColor }}>
                            {cell.row.original.frequency.value}
                        </Dropdown.Toggle>
                        <FrequencyModel id={cell.row.original._id} />
                    </Dropdown>
                </div >
            )
        },
        {
            Header: 'Questions',
            accessor: 'question',
            Cell: ({ cell }) => (
                <>
                    <span>{cell.row.original.question}</span>
                </>
            )
        },
        {
            Header: 'Answer Hints',
            accessor: 'answer',
            Cell: ({ cell }) => (
                <>
                    <span>{cell.row.original.answer}</span>
                </>
            )
        },
        {
            Header: 'Actions',
            accessor: '_id',
            Cell: ({ cell }) => (
                <div className="dk-qualifyAction">
                    {/* <Link className="dk-view-btn mr-2" to="#" onClick={showviewModal}><i className="fa fa-eye" aria-hidden="true"></i></Link> */}
                    <Link className="dk-edit-btn mr-2" to="#" onClick={() => showeditModal(cell.row.original._id)}><i className="fa fa-edit " aria-hidden="true"></i></Link>
                    <Link className="dk-delete-btn" to="#" onClick={() => showdeleteModal(cell.row.original._id)} ><i className="fa fa-trash " aria-hidden="true"></i></Link>
                </div>
            )
        }
    ], [])

    return (
        <>


            {
                (isViewModal) ? <ViewModal onHide={hideviewModal} /> : null
            }
            {
                (isEditModal) ? <EditModal onHide={hideeditModal} /> : null
            }
            {/* {
                (isDeleteModal) ? <DeleteDummy onHide={hidedeleteModal} /> : null
            } */}
            {
                (isDeleteModal) ? <DeleteModal id={rowId} onHide={hidedeleteModal} deleteItem={deleteItem} handleClose={closeDelete} /> : null
            }

            {/* <Header />
            <NavBar />
            <Submenu /> */}
            {/* show={show} onHide={handleClose} */}
            {
                (isReorderModal) ?
                    <Modal className="dk-toolsBkVlueseditModal" >
                        <Modal.Header closeButton>
                            <Modal.Title>Reorder</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="dk-toolsModalbody">
                                <Tabs defaultActiveKey="edit" id="uncontrolled-tab-example" className="mb-3">

                                    <ReorderModal dataList={data} onHide={hideReorderModel} />
                                </Tabs>
                            </div>
                        </Modal.Body>
                    </Modal>
                    : null
            }

            <div className="container-fluid dk-QuailfyMainPageCon">
                <div className="row">
                    <div className="col-md-12">
                        <Table
                            data={data}
                            columns={columns}
                            loading={isDataLoading}
                            isInsert={isInsert}
                            isUpdate={isUpdate}
                            isDataDelete={isDelete}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Qualify;
