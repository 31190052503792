import React from 'react';
import { Link, Route } from "react-router-dom";
import './dayclosingmoods.scss';


const Moods3 = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-dayclsoing-Main dk-dayclsoing-DetailMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <ul>
                                <Route>
                                    <li><Link to="/closure/moods">Moods</Link></li>
                                    <li><Link to="/closure/moods-2">Simple Moods 2</Link></li>
                                    <li><Link to="/closure/moods-3" className={`${pathname.match('/moods-3') ? 'active profile-greybg' : 'profile-greybg'}`}>Simple Moods 3</Link></li>
                                    <li><Link to="/closure/moods-4">Simple Moods 4</Link></li>
                                    <li><Link to="/closure/moods-5">Simple Moods 5</Link></li>
                                </Route>
                            </ul>
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                <div className="dk-trainingInformation-cols">
                                    <div className="dk-payDetailRightPanel row">
                                        <div className='col-md-12 pl-0 pr-0'>
                                            <div className='dkg-mainsuMM-RightCon'>
                                                <div className='dk-workplansimileCon' style={{ marginTop: "0", marginBottom: "25px" }}>
                                                    <div className='dk-wokplan-emoHeader'>
                                                        <h2 className='dk-wokplan-emoTitle'>CLOSING DAY REPORT</h2>
                                                    </div>
                                                    <div className='dkg-workplanemo-Body'>
                                                        <div className="monthBoxes">
                                                            <ul>
                                                                <li><Link to="#/recruitment/dashboard/sales-kpi/team-sale">Jan</Link></li>
                                                                <li><Link to="#/recruitment/dashboard/sales-kpi/team-sale">Feb</Link></li>
                                                                <li><Link to="#/recruitment/dashboard/sales-kpi/team-sale">Mar</Link></li>
                                                                <li><Link to="#/recruitment/dashboard/sales-kpi/team-sale">Apr</Link></li>
                                                                <li><Link to="#/recruitment/dashboard/sales-kpi/team-sale">May</Link></li>
                                                                <li><Link to="#/recruitment/dashboard/sales-kpi/team-sale">Jun</Link></li>
                                                                <li><Link to="#/recruitment/dashboard/sales-kpi/team-sale" className="active">Jul</Link></li>
                                                                <li><Link to="#/recruitment/dashboard/sales-kpi/team-sale">Aug</Link></li>
                                                                <li><Link to="#/recruitment/dashboard/sales-kpi/team-sale">Sep</Link></li>
                                                                <li><Link to="#/recruitment/dashboard/sales-kpi/team-sale">Oct</Link></li>
                                                                <li><Link to="#/recruitment/dashboard/sales-kpi/team-sale">Nov</Link></li>
                                                                <li><Link to="#/recruitment/dashboard/sales-kpi/team-sale">Dec</Link></li>
                                                            </ul>
                                                        </div>
                                                        <ul className='dkg-summemojelistCon'>
                                                            <li className='dkg-summemojelist'>
                                                                <div className='dk-summ-recimgCon'>
                                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className='dk-summ-recimg active' alt='Recruiter' />
                                                                </div>
                                                            </li>
                                                            <li className='dkg-summemojelist'>
                                                                <div className='dk-summ-recimgCon'>
                                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className='dk-summ-recimg' alt='Recruiter' />
                                                                </div>
                                                            </li>
                                                            <li className='dkg-summemojelist'>
                                                                <div className='dk-summ-recimgCon'>
                                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/1523633534-luiza.jpg" className='dk-summ-recimg' alt='Recruiter' />
                                                                </div>
                                                            </li>
                                                            <li className='dkg-summemojelist'>
                                                                <div className='dk-summ-recimgCon'>
                                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className='dk-summ-recimg' alt='Recruiter' />
                                                                </div>
                                                            </li>
                                                            <li className='dkg-summemojelist'>
                                                                <div className='dk-summ-recimgCon'>
                                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5e54fdac9c.png" className='dk-summ-recimg' alt='Recruiter' />
                                                                </div>
                                                            </li>
                                                            <li className='dkg-summemojelist'>
                                                                <div className='dk-summ-recimgCon'>
                                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c3ad279ec.png" className='dk-summ-recimg' alt='Recruiter' />
                                                                </div>
                                                            </li>
                                                            <li className='dkg-summemojelist'>
                                                                <div className='dk-summ-recimgCon'>
                                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/1623316236.jpg" className='dk-summ-recimg' alt='Recruiter' />
                                                                </div>
                                                            </li>
                                                            <li className='dkg-summemojelist'>
                                                                <div className='dk-summ-recimgCon'>
                                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c3a2270ae02e.png" className='dk-summ-recimg' alt='Recruiter' />
                                                                </div>
                                                            </li>
                                                            <li className='dkg-summemojelist'>
                                                                <div className='dk-summ-recimgCon'>
                                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" className='dk-summ-recimg' alt='Recruiter' />
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <div className='dk-days-blocksContainer'>
                                                            <div className='dk-days-portalCols'>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                            </div>
                                                            <div className='dk-days-portalCols'>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                            </div>
                                                            <div className='dk-days-portalCols'>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                            </div>
                                                            <div className='dk-days-portalCols'>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                            </div>
                                                            <div className='dk-days-portalCols'>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                                <div className='dk-days-portalBox'></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Moods3;

