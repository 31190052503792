import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../utils/messages/messageSlice";
import { updateLunchTime, clearState } from '../../../../slice/attendance/attendanceSlice'
import SubmitButton from "../../../ui/submitButton";
import DatePicker from './datepicker/DatePicker'
import TimePicker from './timepicker/TimePicker'
import moment from "moment-timezone"

const LunchModal = ({ handleClose, id, userid, todaydate }) => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth);
    const { isSuccess, isUpdate, isLoading, isError, msg } = useSelector(state => state.attendance)

    const [startTime, setStartTime] = useState(new Date())
    const [startValTime, setStartValTime] = useState(moment(new Date()).format('HH:mm'))
    const [endTime, setEndTime] = useState(new Date())
    const [endValTime, setEndValTime] = useState(moment(new Date()).format('HH:mm'))

    const [idd, setIdd] = useState('')

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);    

    useEffect(() => {
        setIdd(id.pop())
    }, [id])

    const startTimeChange = (date) => {
        setStartTime(date)
        setStartValTime(moment(date).format('HH:mm'))
    };
    const endTimeChange = (date) => {
        setEndTime(date)
        setEndValTime(moment(date).format('HH:mm'))
    };

    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: msg }))
            handleClose();
            setLoading(false);
            setSuccess(true);
            dispatch(clearState())
        }
        if (isUpdate) {
            handleClose();
            setLoading(false);
            setSuccess(true);
            dispatch(showSuccess({ msg: "Lunch time recorded" }))
            dispatch(clearState())
        }
    }, [isError, isUpdate])

    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (!endValTime || !startValTime) {
            dispatch(showError({ msg: 'All fields required' }))
        } else {
            setSuccess(false);
            setLoading(true);
            let totaltime = startValTime + ' - ' + endValTime;
            dispatch(updateLunchTime({ id: idd, uid: userid, checkinDate: todaydate, key: 'lunchTime', value: totaltime, startTime: startValTime, endTime: endValTime }))
        }
    }

    return (
        <>
            <Modal className="dk-checkInModal" centered show={true} onHide={handleClose}>
                <Modal.Header closeButton style={{ backgroundColor: '#cf7c49' }}>
                    <Modal.Title>Daily Lunch</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-checkInBody">
                        <form action="">
                            <div className="dk-checkInForm">
                                <div className="row">
                                    <div className="col-md-1">
                                        <div className="form-group">
                                            <label htmlFor=""> </label>
                                            <div className="dk-userFilter">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + userInfo.profileImage} className="img-fluid img-circle" alt={`${userInfo.firstName} ${userInfo.lastName}`} title={`${userInfo.firstName} ${userInfo.lastName}`} />
                                                    </Dropdown.Toggle>

                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">Start Lunch Time</label>
                                            {/* <label htmlFor="">
                                                <input type="time" name="" id="" className="form-control" onChange={(e) => setStartTime(e.target.value)} />
                                            </label> */}
                                            <div className="dkg-timepicker-Con">
                                                <TimePicker pickerDueTime={startTime} handleTimeChange={startTimeChange}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label htmlFor="">End Lunch Time</label>
                                            {/* <label htmlFor="">
                                                <input type="time" name="" id="" className="form-control" onChange={(e) => setEndTime(e.target.value)} />
                                            </label> */}
                                            <div className="dkg-timepicker-Con">
                                                <TimePicker pickerDueTime={endTime} handleTimeChange={endTimeChange}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-checkInOutBtn">
                                {/* <input type="button" value="Submit" style={{ backgroundColor: '#cf7c49' }} /> */}
                                <SubmitButton className="dkg-create-payment-taskbtn dkg-lunch-btn" txt="Submit" loading={loading} success={success} onClick={handleButtonClick} />
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LunchModal;
