import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'

const TypeFilter = (props) => {
    return (
        <>
            <Dropdown className='dk-resloginfilterDrop'>
                <Dropdown.Toggle variant="" className='dk-resloginfilter-toggle'>
                    {props.resloginstitle}
                </Dropdown.Toggle>
                <Dropdown.Menu className='dk-resloginfilterDropMenu'>
                    <Dropdown.Item href="#" style={{ backgroundColor: "#31a886", color: "#fff" }}>Fixed</Dropdown.Item>
                    <Dropdown.Item href="#" style={{ backgroundColor: "#dc3545", color: "#fff" }}>Variable</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default TypeFilter
