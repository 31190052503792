import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown'

const PriorityDropdown = ({taskPriority,changeTaskPriority}) => {
    const TaskForDropdown = styled.div`
        .dropdown{
            display: flex;
            justify-content: center;
            align-items: center;
            display: flex;
            align-items: center;
            .dropdown-toggle{
                box-shadow: none;
                background-color: #fff;
                padding: 7px 10px;
                width: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                text-transform: capitalize;
                font-size: 13px;
                color: gray;
                margin-left: 0;
                margin-right: 0;
                border: 1px solid #ddd;
                margin: 0;
                &:after {
                    color: gray;
                    display: inline-block;
                    font-size: 16px;
                    position: absolute;
                    right: 11px;
                    top: 16px;
                }
            }
            .dropdown-menu{
                border-radius: 0;
                padding: 0;
                width: 100%;
                .dropdown-item{
                    border-bottom: 1px solid #ddd;
                    padding : 5px 7px ;
                    text-align: center;
                    &:last-child{
                        border-bottom: none;
                    }
                }
            }
        }
    `;
    return (
        <>
            <TaskForDropdown>
                <Dropdown>
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                        {taskPriority}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item  style={{ backgroundColor: "#3a9469", color: "#fff" }} onClick={() => changeTaskPriority('Normal')} >
                            Normal
                        </Dropdown.Item>
                        <Dropdown.Item  style={{ backgroundColor: "#d91010", color: "#fff" }} onClick={() => changeTaskPriority('Urgent')}>
                            Urgent
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </TaskForDropdown>
        </>
    )
}

export default PriorityDropdown;