import React, { useEffect } from "react";
import { BasicTable } from "./BasicTable";
import { listTable, clearState } from '../../../../../slice/dkgTeam/teamSlice';
import { useDispatch, useSelector } from "react-redux";
import { showError, clearMessage } from '../../../../../slice/utils/message/messageSlice'
import { userslist } from "../../../../../slice/userList/userSlice";
import './empdkgteam.scss';

const DkgTeam = () => {
    const dispatch = useDispatch();
    const { dataList } = useSelector(state => state.teamData)
    const { companyId } = useSelector(state => state.auth.userInfo)

    useEffect(() => {
        dispatch(clearMessage())
        dispatch(clearState())
    }, [])

    useEffect(() => {
        async function fetchData() {
            try {
                //await dispatch(listTable({ companyId }))
                await dispatch(listTable({}))
                await dispatch(userslist({}))
            } catch (err) {
                dispatch(showError({ msg: err }))
            }
        }
        fetchData()
    }, [dispatch])

    return (

        <React.Fragment>
            <div className="dk-dkgTeamMain dk-hr-dkgTeamMain">
                {
                    (dataList && dataList.length > -1) ? <BasicTable /> : null
                }
            </div>
        </React.Fragment>
    )
}
export default DkgTeam;