import React from 'react';
import "./casedrpdwn.scss";
import Dropdown from 'react-bootstrap/Dropdown'

const Status = ({ caseStatus, candidateId, list, updateProfile, errorClass }) => {
    let bgcolur ;
    let txtColor ;
    
if(caseStatus=="Open"){
    bgcolur="#316a67"
    txtColor = 'fff'
}else if(caseStatus=="On Hold"){
    bgcolur="#8b572a"
    txtColor = '#fff'
}else{
    bgcolur="#da3d5e"
    txtColor = '#fff'
}
    return (
        <>
            
            <div className="dk-caseDrpDwn">
                <Dropdown className={errorClass}>
                    <Dropdown.Toggle variant="" className="caseStstoggle" style={{ backgroundColor: bgcolur, borderColor: bgcolur,color: txtColor }}>
                        {caseStatus}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="caseStatusDropMenu">
                        {
                            list.map((statusItem, index) =>
                                <Dropdown.Item key={`profile-case-status-id${index}`} href="#" style={{ backgroundColor: statusItem.bgColor, color: statusItem.textColor }} data-id={candidateId} data-value={statusItem.value} data-key="newprofilestatus" onClick={updateProfile}>{statusItem.value}</Dropdown.Item>
                            )
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default Status;
