import React from "react";
import { Link } from "react-router-dom";

const CompanyTab = ({ comapnydetails }) => {
  return (
    <>
      <div className="dkg-myacc-companytab">
        <div className="dkg-other-project-header">
          <p className="dkg-other-prjects-title">
            {comapnydetails != "" ? comapnydetails.description : ""}
          </p>
        </div>
        <div className="dkg-company-table-con">
          <table className="table dkg-company-table table-bordered">
            <tbody>
              <tr>
                <td className="table_txt">Name of Company</td>
                <td>
                  {comapnydetails != "" ? comapnydetails.companyName : ""}
                </td>
              </tr>
              <tr>
                <td className="table_txt">Registration At</td>
                <td>
                  {" "}
                  {comapnydetails != "" ? comapnydetails.registrationAt : ""}
                </td>
              </tr>
              <tr>
                <td className="table_txt">Registration No.</td>
                <td>
                  {comapnydetails != "" ? comapnydetails.registrationNo : ""}
                </td>
              </tr>
              <tr>
                <td className="table_txt">Company VAT No.</td>
                <td> {comapnydetails != "" ? comapnydetails.vatNo : ""}</td>
              </tr>
              <tr>
                <td className="table_txt">Head Office</td>
                <td>{comapnydetails != "" ? comapnydetails.headOffice : ""}</td>
              </tr>
              <tr>
                <td className="table_txt">Work Industry</td>
                <td>{comapnydetails != "" ? comapnydetails.industry : ""}</td>
              </tr>
              <tr>
                <td className="table_txt">Business Type</td>
                <td>
                  {comapnydetails != "" ? comapnydetails.businessType : ""}
                </td>
              </tr>
              <tr>
                <td className="table_txt">Recruitment In</td>
                <td>
                  {comapnydetails != "" ? comapnydetails.recruitmentIn : ""}
                </td>
              </tr>
              <tr>
                <td className="table_txt">Other Services</td>
                <td>
                  {comapnydetails != "" ? comapnydetails.otherServices : ""}
                </td>
              </tr>
              {/* <tr>
                <td className="table_txt">Clients Location</td>
                <td>
                  {comapnydetails != "" ? comapnydetails.clientsLocation : ""}
                </td>
              </tr> */}
              <tr>
                <td className="table_txt">Services Location</td>
                <td>
                  {comapnydetails != "" ? comapnydetails.servicesLocation : ""}
                </td>
              </tr>
              <tr>
                <td className="table_txt">Company Website</td>
                <td>
                  <Link
                    to={
                      comapnydetails != "" ? comapnydetails.companyWebsite : ""
                    }
                    target="_blank"
                  >
                    {comapnydetails != "" ? comapnydetails.companyWebsite : ""}
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CompanyTab;
