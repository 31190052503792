import React from 'react';
import "./dailyapps.css";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import Constant from "../../../../constants";

const TRACKER_JOB_BOARD_ELJ = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.ELJ;
const TRACKER_JOB_BOARD_MLV = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.MLV;
const TRACKER_JOB_BOARD_HIPO = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.HIPO;
const TRACKER_JOB_BOARD_DK_WEBSITE = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.DK_WEBSITE;
const TRACKER_JOB_BOARD_LINKEDIN = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.LINKEDIN;
const TRACKER_JOB_BOARD_INDEED = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.INDEED;
const TRACKER_JOB_BOARD_EJOBS = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.EJOBS;
const TRACKER_JOB_BOARD_FACEBOOK = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.FACEBOOK;
const TRACKER_JOB_BOARD_DIRECT_EMAIL = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.DIRECT_EMAIL;
const TRACKER_JOB_BOARD_LEARN4GOOD = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.LEARN4GOOD;
const TRACKER_JOB_BOARD_EUROJOBS = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.EUROJOBS;
const TRACKER_JOB_BOARD_CAREERS4A = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.CAREERS4A;
const TRACKER_JOB_BOARD_JOBFIND = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.JOBFIND;
const TRACKER_JOB_BOARD_BAKECA = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.BAKECA;
const TRACKER_JOB_BOARD_BESTJOBS = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.BESTJOBS;
const TRACKER_JOB_BOARD_PROFESIA_CZ = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.PROFESIA_CZ;
const TRACKER_JOB_BOARD_PROFESIA_SK = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.PROFESIA_SK;
const TRACKER_JOB_BOARD_JOOBLE = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.JOOBLE;
const TRACKER_JOB_BOARD_NET_EMPREGOS = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.NET_EMPREGOS;
const TRACKER_JOB_BOARD_PRACUJ_PL = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.PRACUJ_PL;
const TRACKER_JOB_BOARD_PURE_CHAT = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.PURE_CHAT;
const TRACKER_JOB_BOARD_CAREER_BUILDER = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.CAREER_BUILDER;
const TRACKER_JOB_BOARD_3_MIN_JOB = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.MIN_JOB;
const TRACKER_JOB_BOARD_SOF_TEST = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.SOF_TEST;


const DailyappsTable = () => {

    const currentYear = new Date().getFullYear();
    const previous1stYear = currentYear - 1;

    const dispatch = useDispatch();
    const { appsTrackerYearly } = useSelector(state => state.appsTracker)

    const yearlyTrackerFunction = (array, jobBoard, year) => {
        let mainArray = array;
        let finalResult = mainArray.map((r) => {
            if (r.jobBoard === jobBoard && r.year == year) {
                return ({ val: r.value, id: r._id })
            } else {
                return ({ val: null, id: null })
            }
        })

        let non_zero = finalResult.filter((item) => item.val !== null);
        if (non_zero.length > 0) {
            return <td>{non_zero.reduce((a, b) => +a + +b.val, 0)}</td>
        } else {
            return <td></td>
        }
    }

    const weeklyTrackerFooterFum = (array, year) => {
        if (year == "") {
            let mainArray = array.filter((item) => item.year != '')
            return mainArray.reduce((a, b) => +a + +b.value, 0);
        } else {
            let mainArray = array.filter((item) => item.year == year)
            return mainArray.reduce((a, b) => +a + +b.value, 0);
        }
    }

    const yearlyTrackerSideFun = (array, jobBoard) => {
        let mainArray = array.filter((item) => item.jobBoard === jobBoard)
        return mainArray.reduce((a, b) => +a + +b.value, 0);
    }

    const searchFunction = (e) => {
        var value = (e.target.value).toLowerCase();
        $("#filterTracker tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    }

    return (
        <>

            <div className="dk-dailyAppstableCont table-responsive">
                <table className="table dk-dailyAppstable">
                    <thead>
                        <tr>
                            <th>
                                <div className="dk-appstrackTableSearch">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search..." onChange={searchFunction} />
                                        <div className="input-group-append">
                                            <button className="btn btn-secondary" type="button">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </th>
                            <th>{previous1stYear}</th>
                            <th>{currentYear}</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody id="filterTracker">
                        <tr>
                            <td>ELJ</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_ELJ, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_ELJ, currentYear)
                            }

                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_ELJ)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>MLV</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_MLV, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_MLV, currentYear)
                            }

                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_MLV)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>HIPO</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_HIPO, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_HIPO, currentYear)
                            }
                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_HIPO)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>DK Website</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_DK_WEBSITE, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_DK_WEBSITE, currentYear)
                            }

                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_DK_WEBSITE)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Linkedln</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_LINKEDIN, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_LINKEDIN, currentYear)
                            }

                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_LINKEDIN)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Indeed</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_INDEED, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_INDEED, currentYear)
                            }

                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_INDEED)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Ejobs</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_EJOBS, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_EJOBS, currentYear)
                            }

                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_EJOBS)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Facebook</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_FACEBOOK, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_FACEBOOK, currentYear)
                            }

                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_FACEBOOK)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Direct Email</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_DIRECT_EMAIL, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_DIRECT_EMAIL, currentYear)
                            }

                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_DIRECT_EMAIL)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Learn4Good</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_LEARN4GOOD, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_LEARN4GOOD, currentYear)
                            }

                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_LEARN4GOOD)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Eurojobs</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_EUROJOBS, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_EUROJOBS, currentYear)
                            }

                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_EUROJOBS)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Careers4A</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_CAREERS4A, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_CAREERS4A, currentYear)
                            }

                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_CAREERS4A)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Jobfind</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_JOBFIND, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_JOBFIND, currentYear)
                            }
                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_JOBFIND)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Bakeca</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_BAKECA, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_BAKECA, currentYear)
                            }
                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_BAKECA)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>BestJobs</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_BESTJOBS, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_BESTJOBS, currentYear)
                            }

                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_BESTJOBS)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Profesia.cz</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_PROFESIA_CZ, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_PROFESIA_CZ, currentYear)
                            }

                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_PROFESIA_CZ)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Profesia.sk</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_PROFESIA_SK, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_PROFESIA_SK, currentYear)
                            }

                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_PROFESIA_SK)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Jooble</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_JOOBLE, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_JOOBLE, currentYear)
                            }

                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_JOOBLE)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>NetEmpregos</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_NET_EMPREGOS, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_NET_EMPREGOS, currentYear)
                            }
                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_NET_EMPREGOS)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Pracuj.pl</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_PRACUJ_PL, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_PRACUJ_PL, currentYear)
                            }
                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_PRACUJ_PL)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Purechat</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_PURE_CHAT, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_PURE_CHAT, currentYear)
                            }
                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_PURE_CHAT)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Careerbuilder</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_CAREER_BUILDER, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_CAREER_BUILDER, currentYear)
                            }
                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_CAREER_BUILDER)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>3min job</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_3_MIN_JOB, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_3_MIN_JOB, currentYear)
                            }
                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_3_MIN_JOB)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Softest</td>
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_SOF_TEST, previous1stYear)
                            }
                            {
                                yearlyTrackerFunction(appsTrackerYearly, TRACKER_JOB_BOARD_SOF_TEST, currentYear)
                            }

                            <td>
                                {
                                    yearlyTrackerSideFun(appsTrackerYearly, TRACKER_JOB_BOARD_SOF_TEST)
                                }
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>
                                {
                                    weeklyTrackerFooterFum(appsTrackerYearly, previous1stYear)
                                }
                            </td>
                            <td>
                                {
                                    weeklyTrackerFooterFum(appsTrackerYearly, currentYear)
                                }
                            </td>
                            <td>
                                {
                                    weeklyTrackerFooterFum(appsTrackerYearly, '')
                                }
                            </td>

                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    )
}

export default DailyappsTable;
