import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CandTabContentWorkflow from "./Workflow";

const TimelineModal = ({ onHide }) => {
    return (
        <>
            <Modal className="dk-timelineModal-main right" show={true} onHide={onHide}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <CandTabContentWorkflow />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TimelineModal;