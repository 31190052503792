import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DkgPipelinesDetailLeftPanel from "../leftpanel/DkgPipelinesDetailLeftPanel";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import OnHoldLeftTabs from '../detaillefttabs/dkgpipelinesdetaillefttabs/OnHoldLeftTabs';
import MoreDetailModal from '../MoreDetailModal';
import TitleIcon from '../../../../../assets/images/pipeline_icon.png';
import '../../trainingdetail.scss';


const DkgPipelinesOnHold = () => {
    const [isShowMore, setIsShowMore] = useState(false);
    const showmoreDetails = () => {
        console.log("Show")
        setIsShowMore(true);
    }
    const hidemoreDetails = () => {
        setIsShowMore(false);
    }

    return (
        <React.Fragment>
            {
                (isShowMore) ? <MoreDetailModal handleClose={hidemoreDetails} /> : null
            }


            <div className="dk-backBtnMain">
                <Link to="/training/commontraining/common-training"><i className="fas fa-arrow-left"></i> Go Back</Link>
                <h3 className="dk-detailTitle mt-0 pt-0 pb-0">
                    <img src={TitleIcon} className="img-fluid" alt="" />
                    DKG Pipelines
                </h3>
            </div>

            <div className="dk-trainingDetailMain mt-3">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <DkgPipelinesDetailLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                <h3 className="title">On Hold</h3>
                                <Tabs defaultActiveKey="tabs_1">
                                    <Tab eventKey="tabs_1" title="Details">
                                        <div className="d-flex">
                                            <OnHoldLeftTabs />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tabs_2" title="Samples">
                                        <div className="d-flex">
                                            <OnHoldLeftTabs />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tabs_3" title="Video">
                                        <div className="d-flex">
                                            <OnHoldLeftTabs />
                                        </div>
                                    </Tab>
                                </Tabs>
                                <div title="More" className="clickMoreShow" onClick={showmoreDetails}>
                                    More
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default DkgPipelinesOnHold;

