import React from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

function AutoCompleteDropdown() {
    const items = [
        {
            id: 0,
            name: "Cobol",
        },
        {
            id: 1,
            name: "JavaScript",
        },
        {
            id: 2,
            name: "Basic",
        },
        {
            id: 3,
            name: "PHP",
        },
        {
            id: 4,
            name: "Java",
        },
    ];

    const manyItems = [...new Array(10000)].map((_, i) => ({
        id: i,
        name: `something${i}`,
        description:
            "Some description text, where the search will be performed too.",
    }));

    const movieItems = [
        {
            id: 0,
            title: "Deepak",
            description: "DK Global",
        },
        {
            id: 1,
            title: "Mukul",
            description: "DK Masterbox",
        },
        {
            id: 2,
            title: "Ajay",
            description: "DK Global Jobs",
        },
        {
            id: 3,
            title: "Dhirendra",
            description: "DK Global CRM",
        },
    ];

    const handleOnSearch = (string, results) => {
        console.log(string, results);
    };

    const handleOnHover = (result) => {
        console.log(result);
    };

    const handleOnSelect = (item) => {
        console.log(item);
    };

    const handleOnFocus = () => {
        console.log("Focused");
    };

    const handleOnClear = () => {
        console.log("Cleared");
    };

    const formatResult = (item) => {
        console.log(item);
        return (
            <div className="result-wrapper">
                <span className="result-span">id: {item.id}</span>
                <span className="result-span">name: {item.name}</span>
            </div>
        );
    };

    return (
        <div className="dk-autoCompleteSearch">
            <header className="App-header">
                <div>
                    <ReactSearchAutocomplete
                        items={movieItems}
                        fuseOptions={{ keys: ["title"] }}
                        resultStringKeyName="title"
                        onSearch={handleOnSearch}
                        placeholder="Search ID"
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        onClear={handleOnClear}
                        showIcon={false}
                        styling={{
                            height: "34px",
                            backgroundColor: "white",
                            boxShadow: "none",
                            fontSize: "14px",
                            lineColor: "#fff",
                            placeholderColor: "#988682",
                            clearIconMargin: "3px 8px 0 0",
                            borderBottom: "none",
                            zIndex: 2,
                        }}
                    />
                </div>

            </header>
        </div>
    );
}

export default AutoCompleteDropdown;