import React, { useEffect, useState } from "react";
import { Link, Route } from "react-router-dom";
import Logo from "../../../assets/images/dkgcrmlogo.png";
import "./style.scss";
import { FaQuestion, FaInfoCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import UserNav from "./userNav/JobseekerNav";
import LoginFaqModal from "../../pages/login/faq/LoginFaq";
import { getFaqList } from "../../../slice/faqs/loginFaqSlice";

const RecuiterHeader = (props) => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = React.useState(false);
  const [isLogin, setIsLogin] = React.useState(false);
  const showShowAlert = () => {
    setShowAlert(true);
  };
  const { userInfo } = useSelector((state) => state.auth);
  const showloginModal = () => {
    setIsLogin(true);
    //dispatch(getFaqList({}));
  };
  const hideloginModal = () => {
    setIsLogin(false);
  };

  return (
    <React.Fragment>
      {isLogin ? <LoginFaqModal onHide={hideloginModal} /> : null}
      <div
        className={`dk-mdHeaderMain dk-dkgcrm-header dkg-logincard-Header ${props.dkgmainheaader}`}
        style={{ height: "52px" }}
      >
        <div className="d-flex align-items-center justify-content-between dk-mbtopheaderCon">
          <div className="dk-moduleLogo">
            <Link to="/">
              <img src={Logo} className="img-fluid" alt="" />
            </Link>
          </div>
          <div className="headerTitle" style={{ color: "#333" }}>
            {props.headerTitle}
          </div>
          <div className="dk-mdHeader-rightPanel">
            <div className="dkg-login-faq-btnCon">
              <button
                className="dkg-login-faq-btn btn"
                onClick={showloginModal}
              >
                Login FAQ's{" "}
                <span className="dkg-login-info-icon">
                  {/* <FaInfoCircle  /> */}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RecuiterHeader;
