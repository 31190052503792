import React from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from "react-redux";
import DefaultProfile from '../../../assets/images/default_profile_empty.png'

const UserListByRoleForCandidates = ({ id, columnId, onItemClick }) => {

    const { usersList } = useSelector(state => state.users)

    const removeValFromIndex = [9, 10, 11]; //not to see users in pipeline, remove user from usersList
    const indexSet = new Set(removeValFromIndex);
    const newUsers = usersList.filter((value, i) => !indexSet.has(i));

    const selectValue = (value, color) => {
        //console.log(color)
        onItemClick(id, columnId, value, color);
    }

    return (
        <React.Fragment>
            {newUsers.map((list, index) => (
                <Dropdown.Item onClick={() => selectValue(list.id, list.profileImage)} key={columnId + index}>
                    <div className="tooltipMain">
                        {/* <img title={list.firstName + " " + list.lastName} src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + list.profileImage} className="filterImg" alt={list.firstName + " " + list.lastName} /> */}
                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + list.profileImage} className="filterImg" alt={list.firstName + " " + list.lastName} />
                        <div className="tooltipText">{list.firstName + " " + list.lastName}</div>
                    </div>
                </Dropdown.Item>
            ))}
            <Dropdown.Item onClick={() => selectValue(-1, '/users/images/user.png')} key={columnId + "-1"}>
                <div className="tooltipMain">
                    <img src={DefaultProfile} className="filterImg" alt="No User" />
                    <div className="tooltipText">No User</div>
                </div>
            </Dropdown.Item>
        </React.Fragment>
    )
}
export default UserListByRoleForCandidates;