import React from "react";
import SummaySubmenu from './menu/accountingmodule/businessbook/2022/SummaySubmenu';

const BusinessBookSubmenu = ({ path }) => {
    const renderSubmenu = () => {
        switch (path.split("/")[3]) {
            case 'qualified':
                return <SummaySubmenu />
                break;
            case 'interviews':
                return <SummaySubmenu />
                break;

            case 'job-offers':
                return <SummaySubmenu />
                break;

            case 'joined':
                return <SummaySubmenu />
                break;

            case 'final-fee':
                return <SummaySubmenu />
                break;
            default:
                break

        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default BusinessBookSubmenu;