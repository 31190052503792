import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const MarketingMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalAdminMarketingMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/dkglobaladmin/marketing/dashboard" className={(segment2 == 'marketing') ? 'active' : ''}>Dashboard</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'structure') ? '' : ''}`}>Tasks</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'teams') ? '' : ''}`}>Alerts</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'pipelines') ? '' : ''}`}>Platforms</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-1') ? '' : ''}`}>Banners</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-2') ? '' : ''}`}>Campaigns</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-3') ? '' : ''}`}>Frontend</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-4') ? '' : ''}`}>Backend</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-5') ? '' : ''}`}>Shedule</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-5') ? '' : ''}`}>Saved Files</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-5') ? '' : ''}`}>Training</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default MarketingMenu;