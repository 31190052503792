import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import Switch from "./Switch";

import {
  updateProjectValue,
  defaultJobsProject,
} from "../../../../../../slice/jobSpec/jobSpecSlice";

//import { showError } from "../../../../../utils/messages/messageSlice";

const ClientProjectTab = ({ jobId, clientid }) => {
  const dispatch = useDispatch();
  const {
    isSuccess,
    iss2Loading,
    projectRelationData,
    projectsList,
    is6Loading,
  } = useSelector((state) => state.jobsSpec);
  //projectsList come from defaultJobsProject()
  //projectRelationData come from allclientsproject()
  const { activeprojecList, isProjectLoading } = useSelector(
    (state) => state.clientproject
  );

  const [selectedId, setSelectedId] = useState(null);
  const [projectlist, setProjectclist] = useState([]);

  useEffect(() => {
    dispatch(
      defaultJobsProject({
        jobsId: jobId,
        tbl: "client_project_categories",
      })
    );
  }, []);

  useEffect(() => {
    if (is6Loading && projectsList.length > 0) {
      setSelectedId(projectsList[0].companyId);
    }
  }, [is6Loading]);

  useEffect(() => {
    if (!isSuccess && projectRelationData && projectRelationData.length > 0) {
      setProjectclist(projectRelationData);
    }
  }, [isSuccess]);

  const handleChange = (id, companyname) => {
    setSelectedId(id);
    dispatch(
      updateProjectValue({
        id: id,
        key: "isShowInJob",
        value: 1,
        jobsId: jobId,
        clientId: clientid,
        clientname: companyname,
        tableName: "jobs_relationship_projects",
      })
    );
  };

  return (
    <>
      <div className="dkg-company-list-mainCon">
        <div className="dkg-company-list-tableCon table-responsive">
          <table className="dkg-company-list-table table table-borderded">
            <thead>
              <tr>
                <th>Project</th>
                <th>Linked</th>
              </tr>
            </thead>
            <tbody>
              {projectlist.map((details, index) => (
                <tr key={index + 1}>
                  <td>{details.categoryName}</td>
                  <td>
                    <span className="dkg-clientinfo-checkbox">
                      <input
                        type="radio"
                        className="form-control"
                        name="clientinfo-checkbox-projectss"
                        data-id={details.id}
                        data-companyname={details.categoryName}
                        id={details.id}
                        checked={selectedId === details.id}
                        onChange={() =>
                          handleChange(details.id, details.categoryName)
                        }
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ClientProjectTab;
