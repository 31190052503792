import React, { useState } from 'react'
import { FaSearch, FaFilter, FaInfoCircle, FaSync } from 'react-icons/fa';
import { ButtonGroup, Dropdown, Button, DropdownButton } from 'react-bootstrap';
import './expincome-22.scss';
import MonthFilter from './tablefilter/MonthFilter';
import AddNewInvoice from './modal/AddNewClientsInvoice';


const IncomeTwentyTwo = () => {
    const [isShowAddNew, setShowAddNew] = useState(false)
    const showAddNewModal = () => {
        setShowAddNew(true)
    }
    const hideAddNewModal = () => {
        setShowAddNew(false)
    }
    return (
        <>
            {
                (isShowAddNew) ? <AddNewInvoice hideAddNewModal={hideAddNewModal} /> : null
            }
            <div className='dk-exp-income-banking-main'>
                <div className="dk-bankingYearsHead-main">
                    <div className="dk-bankingSearch-cols">
                        <div className='d-flex'>
                            <div className="dk-search-input">
                                <input className="form-control" placeholder="Search ..." value="" />
                                <div className="input-group-append">
                                    <button className="" type="button">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="dk-reloadBtn">
                                <button className="addMonthBtn"><FaSync /></button>
                            </div>
                            <div className='dk-reloadBtn' onClick={showAddNewModal}>
                                <button className="addMonthBtn"><i className="fas fa-plus"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='dk-recwallet-centerCon'>
                        {/* <div className='dk-filterTagsCon'>
                           
                                        <ButtonGroup className='dk-filterbtnGrp mr-2'>
                                            <Button className="dk-filterbtntext">Text</Button>
                                            <Button className="dk-filterbtnicon"><i className="fas fa-times" ></i></Button>
                                        </ButtonGroup>

                                  
                                   
                        </div> */}
                        <div className='dk-filterTagsCon'>
                            <div className='dkg-pagetitle'>INCOME 2022</div>
                        </div>
                    </div>
                    <div className="dk-sendoutyears-rightPanel-btn">
                        <div className="dk-sensdouts-searchCols">
                            <button ><FaFilter /></button>
                        </div>
                        <div className="mr-2 dkg-banking-categry-filter-234">
                            <Dropdown className='dropdownFilter dkg-banking-categry-234'>
                                <Dropdown.Toggle variant="" >
                                    Category
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='dropdown-menu show'>
                                    <Dropdown.Item href="#" className="dkg-banking-categry-item">Category 1
                                        <span className="dropdown-filter-count">9</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#" className="dkg-banking-categry-item">Category 2
                                        <span className="dropdown-filter-count">6</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#" className="dkg-banking-categry-item">Category 3
                                        <span className="dropdown-filter-count">12</span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="mr-2" style={{ position: "relative" }}>
                            <MonthFilter />
                        </div>
                    </div>
                </div>
                <div className='dk-bankingYearsTable-main'>
                    <div className='table-responsive'>
                        <table className='table table-bordered dkg-acc-banking-table45 dkg-acc-invoice-table'>
                            <thead>
                                <tr>
                                    <th>SNo.</th>
                                    <th>MONTH</th>
                                    <th>TRANSACTION</th>
                                    <th>SOURCE</th>
                                    <th>CATEGORY</th>
                                    <th>SERVICES</th>
                                    <th>NAME OF BUSINESS</th>
                                    <th>NOTES</th>
                                    <th>VAT</th>
                                    <th>AMOUNT €</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>
                                        <div className='caseDiv' style={{ backgroundColor: "#b55355", color: "#fff", minWidth: "80px", minHeight: "37px", lineHeight: "37px" }}>
                                            NOVEMBER
                                        </div>
                                    </td>
                                    <td>
                                        11 Nov 22
                                    </td>
                                    <td>
                                        Wise Bank
                                    </td>
                                    <td>Director Expense</td>
                                    <td>Car Parking</td>
                                    <td>RYAN SHERDIAN</td>
                                    <td>
                                        <span className="commentBoxCon zeroComment">
                                            <span className="numCon zeroCommentCount">0</span>
                                        </span>
                                    </td>
                                    <td>-</td>
                                    <td style={{ color: "#3a9469" }}>200</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </>
    )
}

export default IncomeTwentyTwo