import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import '../style.scss';

const TrainingMainMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    // const { allBadge } = useSelector(state => state.tasks);
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-recruitmentNavbar dk-trainingMainMenu dk-mainModuleTrainingMenu">
                <Route>
                    <ul>
                        <li>
                            <Link to="/training/get-started/overview" className={`${(segment2 === 'get-started') ? 'active' : ''}`}>Dashboard</Link>
                        </li>
                        <li>
                            <Link to="/training/work-plan/overview" className={`${(segment2 === 'work-plan') ? 'active' : ''}`}>Work Plan</Link>
                        </li>
                        <li>
                            <Link to="/training/live-jobs/overview" className={`${(segment2 === 'live-jobs') ? 'active' : ''}`}>Live Jobs</Link>
                        </li>
                        <li>
                            <Link to="/training/job-specs/overview" className={`${(segment2 === 'job-specs') ? 'active' : ''}`}>Job Specs</Link>
                        </li>

                        <li>
                            <Link to="/training/resume/overview" className={`${(segment2 === 'resume') ? 'active' : ''}`}>Resume</Link>
                        </li>
                        <li>
                            <Link to="/training/pipelines/overview" className={`${(segment2 === 'pipelines') ? 'active' : ''}`}>Pipelines</Link>
                        </li>
                        <li>
                            <Link to="/training/sendouts/overview" className={`${(segment2 === 'sendouts') ? 'active' : ''}`}>Sendouts</Link>
                        </li>
                        <li>
                            <Link to="/training/post-ads/overview" className={`${(segment2 === 'post-ads') ? 'active' : ''}`}>Post Ads</Link>
                        </li>
                        <li>
                            <Link to="/training/make-calls/overview" className={`${(segment2 === 'make-calls') ? 'active' : ''}`}>Make Calls</Link>
                        </li>
                    </ul>
                </Route>
            </div>

        </React.Fragment>
    );
}

export default TrainingMainMenu;