import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from "react-redux";
const LoginRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticate, isAdminAuthenticate, userInfo } = useSelector(state => state.auth)
    const checkRole = userInfo.role

    return (
        <Route {...rest} render={props => {
            // if (isAdminAuthenticate) {
            //     return <Redirect to={{ pathname: '/dashboard/plateform', state: { from: props.location } }} />
            // } else {
            //     return <Component {...props} />
            // }

            if (isAdminAuthenticate == true && checkRole != "candidate") {
                return <Redirect to={{ pathname: '/dashboard/plateform', state: { from: props.location } }} />
            } else if (isAdminAuthenticate == true && checkRole == "candidate") {
                return <Redirect to={{ pathname: '/js-account/dashboard', state: { from: props.location } }} />
            }
            else {
                return <Component {...props} />
            }
        }} />
    )
};
export default LoginRoute;