import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './knowleadgemenu.scss';
//import Submenu from '../../subMenu/KnowleadgeSubmenu';

const KnowleadgeBaseMenu = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-knowleadgeNavbar">
                <Route>
                    <ul>
                        <li><Link to="/knowleadge/summary" className={`${(segment2 === 'summary') ? 'white-active' : ''}`}>Dashboard </Link></li>
                        <li><Link to="/knowleadge/dk-global/overview" className={`${(segment2 === 'dk-global') ? 'white-active' : ''}`}>DK Global</Link></li>
                        <li><Link to="/knowleadge/kpi/overview" className={`${(segment2 === 'kpi') ? 'white-active' : ''}`}>KPI</Link></li>
                        <li><Link to="/knowleadge/csf/overview" className={`${(segment2 === 'csf') ? 'white-active' : ''}`}>CSF</Link></li>
                        <li><Link to="/knowleadge-base/visa-info/overview" className={`${(segment2 === 'visa-info') ? 'white-active' : ''}`}>VISA Info</Link></li>
                        <li><Link to="/knowleadge/process/overview" className={`${(segment2 === 'process') ? 'white-active' : ''}`}>Process</Link></li>
                        <li><Link to="/knowleadge/clients/overview" className={`${(segment2 === 'clients') ? 'white-active' : ''}`}>Clients</Link></li>
                        <li><Link to="/knowleadge/demands/overview" className={`${(segment2 === 'demands') ? 'white-active' : ''}`}>Demands</Link></li>
                        <li><Link to="/knowleadge/candidates/overview" className={`${(segment2 === 'candidates') ? 'white-active' : ''}`}>Candidates</Link></li>
                        <li><Link to="/knowleadge/tech-bugs/overview" className={`${(segment2 === 'tech-bugs') ? 'white-active' : ''}`}>Tech Bugs</Link></li>
                        <li><Link to="/knowleadge/support/overview" className={`${(segment2 === 'support') ? 'white-active' : ''}`}>Support</Link></li>
                    </ul>
                </Route>
            </div>
            {/* <Submenu path={pathname} /> */}
        </React.Fragment >
    );
}

export default KnowleadgeBaseMenu;