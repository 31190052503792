import React from 'react';
import { Link } from 'react-router-dom'
import { Modal, Dropdown } from "react-bootstrap";
import "./viewpopup.scss";
import Candidate from '../../../../../../../../assets/images/deepak.png'

const ViewcaseprogressModal = ({ onHide }) => {

    return (
        <React.Fragment> 
            <Modal show={true} onHide={onHide} className="dkg-application-view-modal dkg-app-progress-modal-234">
                 <Modal.Header closeButton className="dkg-app-progress-modal-header">
                    <Link to="#" className="dkg-app-progress-plusicon"><i class="fas fa-plus"></i></Link>
                    <Modal.Title>VIEW CASE PROGRESS</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-app-progress-modal-body">
                    <div className="dkg-cand-progress-app-mainCon">
                        <ul className="dkg-progress-app-listCon">
                            <li className="dkg-progress-app-list current">
                                <div className="dkg-progress-app-card-Con">
                                    <div className="dkg-app-porgess-statuscols">
                                        <div className="dkg-app-status-firstCols">
                                            <div className="dkg-status-title">Date & Time</div>
                                            <div className="dkg-progess-app-date">10 Jun 24</div>
                                            <div className="dkg-progess-app-time">20:29 PM</div>
                                        </div>
                                        <div className="dkg-app-status-seocndCols">
                                            <div className="dkg-status-title">Status</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                        </div>
                                        <div className="dkg-app-status-thirdCols">
                                           <div className="dkg-status-title">Stage</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                        </div>
                                        <div className="dkg-app-status-thirdCols">
                                           <div className="dkg-status-title">Progress</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                        </div>
                                        <div className="dkg-app-status-thirdCols">
                                           <div className="dkg-status-title">Situation</div>
                                            <div className="dkg-progress-status-item" style={{backgroundColor: "#3F9E6F" , color: "#fff"}}></div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul> 
                    </div> 
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default ViewcaseprogressModal;
