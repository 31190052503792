import React from 'react';
import LuizaProjectProfileLeftPanel from "../leftpanel/luiza/LuizaProjectProfileLeftPanel";
import '../moduleprojects.scss';

const LuizaProjectProfile = () => {
    return (
        <>
            <div className="dk-moduleProjects-main">
                <div className="dk-moduleTraining-cols">
                    <LuizaProjectProfileLeftPanel />
                    <div className="dk-moduleTraining-rightPanel">
                        <div className="title">Summary</div>
                        <div className="dk-moduleTraining-rightCols">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LuizaProjectProfile;
