import React from 'react';
import './casestatus.scss';

const CaseStatusDropDwn = ({ caseStatus, value }) => {
    let bgColor = "";
    switch (caseStatus) {
        
        case 'Interviews':
            bgColor = '#316a67'
            break;       
        case 'Closed - Duplicate':
            bgColor = '#da3d5e'
            break;       
        case 'Closed - Not Responding':
            bgColor = '#da3d5e'
            break;       
        case 'Closed - Not Interested':
            bgColor = '#da3d5e'
            break;       
        case 'Closed - Salary is Low':
            bgColor = '#da3d5e'
            break;       
        case 'Closed - Got Other Job':
            bgColor = '#da3d5e'
            break;       
        case 'Closed - Project Filled':
            bgColor = '#da3d5e'
            break;       
        case 'Closed - Project Cancelled':
            bgColor = '#da3d5e'
            break;       
        case 'Closed - Personal Reason':
            bgColor = '#da3d5e'
            break;       
        case 'Closed - Under Age':
            bgColor = '#da3d5e'
            break;       
        case 'Closed - Not Serious':
            bgColor = '#da3d5e'
            break;       
        case 'Closed - Overqualified':
            bgColor = '#da3d5e'
            break;       
        case 'Closed - Will Not Relocate':
            bgColor = '#da3d5e'
            break;       
        case 'Closed - Do not Need Job':
            bgColor = '#da3d5e'
            break;       
        case 'Closed - Low level language':
            bgColor = '#da3d5e'
            break;       
        case 'Closed - Non EU':
            bgColor = '#da3d5e'
            break;       
        case 'Closed - Failed Test':
            bgColor = '#da3d5e'
            break;       
        case 'Closed - Docs Unavailable':
            bgColor = '#da3d5e'
            break;       
       
        default:
            bgColor = '#da3d5e'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor }}>
                {
                    (value == "Blank") ? "" : value
                }
            </div>
        </>
    )
}

export default CaseStatusDropDwn;