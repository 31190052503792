import React, { useState } from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from "react-redux";
import DefaultProfile from '../../../assets/images/default_profile_empty.png'
const UserList = ({ id, columnId, onItemClick }) => {
    const { usersList } = useSelector(state => state.users)
    
    const removeValFromIndex = [9,10,11,12]; //not to see users in pipeline, remove user from usersList
    const indexSet = new Set(removeValFromIndex);
    const newUsers = usersList.filter((value, i) => !indexSet.has(i));

    const selectValue = (value) => {
        onItemClick(id, columnId, value);
    }
    return (
        <React.Fragment>
            {newUsers.map((list, index) => (
                <Dropdown.Item href="#" onClick={() => selectValue(list.id)} key={columnId + index}>
                    <div className="tooltipMain">
                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + list.profileImage} className="filterImg" alt={list.firstName + " " + list.lastName} />
                        <div className="tooltipText">{list.firstName + " " + list.lastName}</div>
                    </div>
                </Dropdown.Item>
            ))}
            <Dropdown.Item href="#" onClick={() => selectValue(-1)} key={columnId + "-1"}>
                <div className="tooltipMain">
                    <img src={DefaultProfile} className="filterImg" alt="No User" />
                    <div className="tooltipText">No User</div>
                </div>
            </Dropdown.Item>
        </React.Fragment>
    )
}
export default UserList;