import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions({ title, faqdata }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(faqdata);
  }, [faqdata]);

  const [expanded, setExpanded] = React.useState("panel0");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const textRef = React.useRef();
  const [value, setValue] = React.useState();
  const onChnage = (event) => {
    setValue(event.target.value);
  };
  React.useEffect(() => {
    if (textRef && textRef.current) {
      textRef.current.style.height = "0px";
      const taHeight = textRef.current.scrollHeight;
      textRef.current.style.height = taHeight + "px";
    }
  }, [value]);
  const index = 1;
  return (
    <>
      <Accordion
        expanded={expanded == `panel` + index}
        onChange={handleChange(`panel` + index)}
        className="dkg-screen-faq-accodionCon"
      >
        <AccordionSummary
          aria-controls={`panel` + index + `d-content`}
          id={`panel` + index + `d-header`}
          className="dkg-screen-faq-accodion"
        >
          <Typography className="dkg-screen-faq-accodion-suMM">
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: "#f2f2f2", color: "#333" }}>
          <Typography>
            <div className="dkg-trms-ofuse-card">
              {data != "" && data != null ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data,
                  }}
                ></div>
              ) : (
                ""
              )}
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
