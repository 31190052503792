import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "./send-query.scss";

const SendqueryModal = ({ onHide }) => {
  return (
    <>
      <Modal
       show={true}
       onHide={onHide}
        className="dkg-sendquery-modal-21"
      >
        <Modal.Header closeButton className="dkg-send-query-modalheader-21">
          <Modal.Title>Send Your Query</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-send-query-modalbody-21">
          
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SendqueryModal;
