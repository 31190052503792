import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const MarianaTwoSubmenu = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    const segment4 = pathname.split("/")[4];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain d-none">
                <Route>
                    <ul>
                        <li><Link to="/projects/marianatwo/project_1" className={(segment3 == 'project_1') ? 'active' : ''}>Croatian</Link></li>
                        <li><Link to="/projects/marianatwo/project_2" className={`${(segment3 == 'project_2') ? 'active' : ''}`}>Flamish</Link></li>
                        <li><Link to="/projects/marianatwo/project_3" className={`${(segment3 == 'project_3') ? 'active' : ''}`}>Greek</Link></li>
                        <li><Link to="/projects/marianatwo/project_4" className={`${(segment3 == 'project_4') ? 'active' : ''}`}>Cyprist</Link></li>
                        <li><Link to="/projects/marianatwo/project_5" className={`${(segment3 == 'project_5') ? 'active' : ''}`}>Icelandic</Link></li>
                        <li><Link to="/projects/marianatwo/project_6" className={`${(segment3 == 'project_6') ? 'active' : ''}`}>Maltese</Link></li>
                        <li><Link to="/projects/marianatwo/project_7" className={`${(segment3 == 'project_7') ? 'active' : ''}`}>Irish</Link></li>
                        <li><Link to="/projects/marianatwo/project_8" className={`${(segment3 == 'project_8') ? 'active' : ''}`}>Asian</Link></li>
                        <li><Link to="/projects/marianatwo/project_9" className={`${(segment3 == 'project_9') ? 'active' : ''}`}>Others</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default MarianaTwoSubmenu;
