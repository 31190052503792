import React, { useEffect, useState, useMemo, lazy, Suspense } from 'react';
import { Route, Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import '../summary/summary.scss';
import UserList from "../../../../ui/userList/";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useAsyncDebounce } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { addValue, updateValue, updateUser, listAll, bulkDelete, clearState } from "../../../../../slice/kpis/performanceBonusSlice";
import AdvancedFilter from "./modal/advancedFilter";
import TableLoader from '../../../../ui/tableLoader';
//import '../dropdown/categoryfilter.scss';
import Checkbox from "../../../../pages/tasks/utils/checkbox/radioBtn";
import $ from "jquery";
import DeleteModal from "../../../../pages/tasks/utils/delete/PaymentDelete";
import { showSuccess } from '../../../../../slice/utils/message/messageSlice';
import InformationModal from '../modal/InformationModal';
import { FaRegStar, FaInfoCircle, FaFilter } from "react-icons/fa";
//import { FaSearch, FaRegCalendarAlt } from 'react-icons/fa';
import UpdatePop from "./UpdatePop";
import TaskByDropdown from '../sendouts/tablefilters/TaskBy';
//import WeekFilterDropdown from '../sendouts/tablefilters/WeekFilterDropdown'
//import MonthFilterDropdown from './modal/TimeFram'
//import MonthFilterDropdown from '../sendouts/tablefilters/MonthFilterDropdown'
import YearFilterDropdown from '../sendouts/tablefilters/YearFilterDropdown'
import DatePicker from '../sendouts/datepicker/DatePicker';
import moment from "moment-timezone";
const TableSuspence = lazy(() => import('../../../../ui/tableLoader/tableSuspence'));
const useInstance = (instance) => {
    if (instance && instance.getInstanceCallback) {
        instance.getInstanceCallback(instance);
    }
};

function Table({ columns, data, loading, getInstanceCallback, filter, RecFilterId, taskForFilterId, updateMyData, caseTypeId, casePayTypeId, caseStatusId, monthsFilterId, yearFilterId, yearsId, monthsId, modelssId, recruterFilterId, DepartmentsId, jobPositionId, newModelId, ratingCountId }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        page,
        prepareRow,
        state,
        setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            updateMyData,
            getInstanceCallback,
            autoResetPage: false,
            autoResetSortBy: false,
            autoResetFilters: false,
            autoResetRowState: false,
            initialState: {
                pageIndex: 0, pageSize: 100,
                hiddenColumns: ["Recruters"]
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy, usePagination, (hooks) => hooks.useInstance.push(useInstance)
    )


    useEffect(() => {
        if (RecFilterId === '0') {
            setTimeout(() => {
                setFilter('Recruters')
            }, 200)
        } else {
            setFilterType('Recruters')
            doFilter(RecFilterId);
        }
    }, [RecFilterId])

    // useEffect(() => {
    //     if (caseStatusId === '0') {
    //         setTimeout(() => {
    //             setFilter('week')
    //         }, 200)
    //     } else {
    //         setFilterType('week')
    //         doFilter(caseStatusId);
    //     }
    // }, [caseStatusId])


    useEffect(() => {
        if (monthsFilterId === '0') {
            setTimeout(() => {
                setFilter('month', '')
            }, 200)
        } else {
            setFilterType('month')
            doFilter(monthsFilterId);
        }
    }, [monthsFilterId])
    // useEffect(() => {
    //     if (monthsFilterId === '0') {
    //         setTimeout(() => {
    //             setFilter('period', '')
    //         }, 200)
    //     } else {
    //         setFilterType('period')
    //         doFilter(monthsFilterId);
    //     }
    // }, [monthsFilterId])

    useEffect(() => {
        if (yearFilterId === '0') {
            setTimeout(() => {
                setFilter('year', '')
            }, 200)
        } else {
            setFilterType('year')
            doFilter(yearFilterId);
        }
    }, [yearFilterId])

    // useEffect(() => {
    //     if (teamssId === '0') {
    //         setTimeout(() => {
    //             setFilter('teams')
    //         }, 200)
    //     } else {
    //         setFilterType('teams')
    //         doFilter(teamssId);
    //     }
    // }, [teamssId])

    useEffect(() => {
        if (yearsId === '0') {
            setTimeout(() => {
                setFilter('year')
            }, 200)
        } else {
            setFilterType('year')
            doFilter(yearsId);
        }
    }, [yearsId])

    useEffect(() => {
        if (monthsId === '0') {
            setTimeout(() => {
                setFilter('period')
            }, 200)
        } else {
            setFilterType('period')
            doFilter(monthsId);
        }
    }, [monthsId])

    useEffect(() => {
        if (modelssId === '0') {
            setTimeout(() => {
                setFilter('model')
            }, 200)
        } else {
            setFilterType('model')
            doFilter(modelssId);
        }
    }, [modelssId])

    useEffect(() => {
        if (ratingCountId === '0') {
            setTimeout(() => {
                setFilter('rating')
            }, 200)
        } else {
            setFilterType('rating')
            doFilter(ratingCountId);
        }
    }, [ratingCountId])

    useEffect(() => {
        if (newModelId === '0') {
            setTimeout(() => {
                setFilter('model')
            }, 200)
        } else {
            setFilterType('model')
            doFilter(newModelId);
        }
    }, [newModelId])

    useEffect(() => {
        if (jobPositionId === '0') {
            setTimeout(() => {
                setFilter('jobPosition')
            }, 200)
        } else {
            setFilterType('jobPosition')
            doFilter(jobPositionId);
        }
    }, [jobPositionId])

    useEffect(() => {
        if (DepartmentsId === '0') {
            setTimeout(() => {
                setFilter('departments')
            }, 200)
        } else {
            setFilterType('departments')
            doFilter(DepartmentsId);
        }
    }, [DepartmentsId])

    const [filterType, setFilterType] = useState('')

    const doFilter = React.useCallback(
        useAsyncDebounce((id) => {
            setFilter(filterType, id)
        }, 200),
        [filterType]
    );


    return (
        <React.Fragment>
            <table className="table table-borderd dkg-payment-table" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                (loading) ?
                                    [...Array(10)].map((e, i) =>
                                        <tr>
                                            <TableLoader colSpan="22" />
                                        </tr>
                                    ) :
                                    <Suspense fallback={<TableSuspence colSpan="22" />}>
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => (
                                                <td
                                                    {...cell.getCellProps([
                                                        {
                                                            className: cell.column.className, // pay attention to this
                                                            style: cell.column.style,
                                                            // set here your other custom props
                                                        },
                                                    ])}
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    </Suspense>
                            )
                        }
                    )}
                </tbody>
                <tfoot>
                    {footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()}>
                            {group.headers.map(column => (
                                <td {...column.getFooterProps()} className="dkg-suMM">{column.render('Footer')}</td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
        </React.Fragment>
    )
}

function debounce(func, wait, immediate) {
    var timeout;
    return (...args) => {
        var context = this;
        var later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isClearAll,
    restClearAllFilter }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState("")

    const onChangeSearchTitle = (e) => {
        const { value: nextValue } = e.target;
        setValue(e.target.value)
        debouncedSave(nextValue);
    };

    const debouncedSave = React.useCallback(
        debounce(nextValue => {
            setGlobalFilter(nextValue)
        }, 1000),
    );

    const clearSearch = () => {
        setValue('')
        setTimeout(() => {
            setGlobalFilter(undefined)
        }, 200)
    }

    useEffect(() => {
        if (isClearAll) {
            clearSearch()
            restClearAllFilter();
        }
    }, [isClearAll])

    return (
        <React.Fragment>
            <input
                value={value}
                className="form-control"
                onChange={onChangeSearchTitle}
                placeholder={`Search ...`}
            />
            {
                (value !== undefined && value.length > 0) ?
                    <div className="input-group-append">
                        <button className="btn btn-secondary" type="button" onClick={clearSearch}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div> : <div className="input-group-append">
                        <button className="btn btn-secondary" type="button">
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
            }
        </React.Fragment>
    )
}

const RcPerformance = () => {

    const dispatch = useDispatch();
    const { dataList, userData, isLoading, isError, isUpdate, annualDataList, annualMonthlyDataList, isSuccess, isPerformanceDelete, message } = useSelector(state => state.perfornanceBonus)


    const [list, setList] = useState([]);
    const [data, setData] = useState([])

    const [rowId, setRowId] = useState(0)
    const [dataRowId, setDataRowId] = useState(0)
    const [isViewModal, setIsViewModal] = useState(false)
    const [rowData, setRowData] = useState({})

    useEffect(() => {
        document.title = "Performance Bonus | Dk Global CRM"
    }, []);

    var today = new Date();
    const [yearValue, setYearValue] = useState(today.getFullYear())
    const [monthValue, setMonthValue] = useState(today.getMonth() + 1)

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(listAll({year: yearValue, month: monthValue}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
    }, [dispatch,yearValue, monthValue])


    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                setData(dataList)
            }, 200)
        }

        if (isUpdate) {
            setIsActive(false)
        }
    }, [isLoading, isUpdate, isSuccess, dispatch])

    useEffect(() => {
        if (isSuccess) {
            dispatch(clearState())
            dispatch(listAll({year: yearValue, month: monthValue}))
            dispatch(showSuccess({ msg: 'Record added successfully' }))
        }
    }, [isSuccess])

    useEffect(() => {
        if (isPerformanceDelete) {
            setLoading(false)
            setSuccess(true)
            dispatch(clearState())
            dispatch(listAll({year: yearValue, month: monthValue}))
            dispatch(showSuccess({ msg: 'Record deleted successfully' }))
        }
    }, [isPerformanceDelete])


    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')
    const [countCheck, setCountCheck] = useState(0)
    const [isActive, setIsActive] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const checkActiveExtraDiv = async (isShowExitra, checkedBoxCount) => {

        if (isShowExitra) {
            setIsActive(true)
            setCountCheck(checkedBoxCount)
        } else {
            setIsActive(false)
            setCountCheck(checkedBoxCount)
        }
    }

    const checkExtraDivBtnTxt = (btnTxt) => {
        setCheckBtnTxt(btnTxt)
    }

    const handleToggle = () => {
        $('.chkCandidate').prop('checked', false);
        setIsActive(false);
    };

    const deleteItemBulk = async () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
        } else {
            var canIds = [];
            $.each($(".chkCandidate:checked"), function () {
                canIds.push($(this).val());
            });
            setLoading(true)
            setSuccess(false)
            await dispatch(bulkDelete({ id: canIds }))
            $('.chkCandidate').prop('checked', false);
            setIsActive(false);
        }
    }

    const [dataMoveTo, SetDataMoveTo] = useState('')
    const [showView, SetShowView] = useState(false);
    const [showEdit, SetshowEdit] = useState(false);


    const closeEditModal = () => {
        SetshowEdit(false)
    }

    const [profileCanId, setProfileCanId] = useState(0)
    const [isProfileModal, setIsProfileModal] = useState(false)

    const handleCloseProfile = () => {
        setIsProfileModal(false);
        //SetshowEdit(false)
    }
    const veiwHandleClose = () => {
        SetShowView(false)
    }
    const handleClose = () => {
        SetshowEdit(false)
    }

    const [columnKey, setColumnKey] = useState('')
    const [columnValue, setColumnValue] = useState('0')
    const [isUpdatePop, setIsUpdatePop] = useState(false)
    const [isRowIdds, setIsRowIdds] = useState('')

    const [ratingCount, setRatingCount] = useState('')

    const openUpdateValuePop = (id, key, value) => {
        setRowId(id)
        setColumnKey(key)
        setColumnValue(value)
        setIsUpdatePop(true)
    }
    const hideUpdateValuePop = () => {
        setIsUpdatePop(false)
    }

    const ratingChanged = async (e, id) => {
        await dispatch(updateValue({ id: id, key: 'rating', value: e }))
    };

    const handleDateChange = async (e, idd) => {
        await dispatch(updateValue({ id: idd, key: "updatedDate", value: moment(e).format('DD MMM YY') }))
    };


    const columns = useMemo(() => [
        {
            Header: 'SNo.',
            accessor: 'id',
            Cell: ({ cell }) => {
                return (

                    <div className='dk-smsTableCheckbox'>
                        <Checkbox id={cell.row.original.id} checkActiveExtraDiv={checkActiveExtraDiv} checkExtraDivBtnTxt={checkExtraDivBtnTxt} setDataRowId={setDataRowId} />
                        {Number(cell.row.id) + 1}
                    </div>
                )
            }
        },
        {
            Header: 'Created On',
            accessor: 'updatedDate',
            Cell: ({ cell }) => {
                return <>
                    <DatePicker handleDateChange={(e) => handleDateChange(e, cell.row.original.id)} selectedDate={cell.row.original.updatedDate} />
                </>
            }
        },
        {
            Header: 'Year',
            accessor: 'year',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value) => {
                    updateMyData(id, columnId, value)
                };

                return <>
                    <Dropdown className="dkg-bonus-modelDropDown">
                        <Dropdown.Toggle variant="" className="dkg-model-dropdown-toggle">
                            {cell.row.original.year}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dkg-model-dropdown-menu">
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'year', '2022')}  >2022</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'year', '2023')} >2023</Dropdown.Item>
                            {/* <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'year', '2022')}  >2022</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'year', '2021')} >2021</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'year', '2020')} >2020</Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            }
        },
        {
            Header: 'Month',
            accessor: 'month',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value) => {
                    updateMyData(id, columnId, value)
                };

                return (
                    <Dropdown className="dkg-bonus-modelDropDown">
                        <Dropdown.Toggle variant="" className="dkg-model-dropdown-toggle">
                            {cell.row.original.month}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dkg-model-dropdown-menu">
                            <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'month', 'January')} >January</Dropdown.Item>
                            <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'month', 'February')} >February</Dropdown.Item>
                            <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'month', 'March')} >March</Dropdown.Item>
                            <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'month', 'April')} >April</Dropdown.Item>
                            <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'month', 'May')} >May</Dropdown.Item>
                            <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'month', 'June')} >June</Dropdown.Item>
                            <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'month', 'July')} >July</Dropdown.Item>
                            <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'month', 'August')} >August</Dropdown.Item>
                            <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'month', 'September')} >September</Dropdown.Item>
                            <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'month', 'October')} >October</Dropdown.Item>
                            <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'month', 'November')} >November</Dropdown.Item>
                            <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'month', 'December')} >December</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            }
        },
        {
            Header: 'Timeline',
            accessor: 'period',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value) => {
                    updateMyData(id, columnId, value)
                };

                return (
                    <>
                        Monthly
                    </>
                    // <Dropdown className="dkg-bonus-modelDropDown">
                    //     <Dropdown.Toggle variant="" className="dkg-model-dropdown-toggle">
                    //         {cell.row.original.period}
                    //     </Dropdown.Toggle>
                    //     <Dropdown.Menu className="dkg-model-dropdown-menu">
                    //         <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'period', 'Quarter 1')} >Quarter 1</Dropdown.Item>
                    //         <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'period', 'Quarter 2')} >Quarter 2</Dropdown.Item>
                    //         <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'period', 'Quarter 3')} >Quarter 3</Dropdown.Item>
                    //         <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'period', 'Quarter 4')} >Quarter 4</Dropdown.Item>
                    //         <Dropdown.Item onClick={() => onItemClick(cell.row.original.id, 'period', 'Quarter 5')} >Quarter 5</Dropdown.Item>
                    //     </Dropdown.Menu>
                    // </Dropdown>
                )
            }
        },
        {
            Header: 'Employee',
            Cell: ({ cell }) => {
                return (
                    <React.Fragment>
                        <input type="hidden" id="userId" value="0" />
                        <Dropdown className="dk-globalUserDropdown">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {
                                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.recInfo.profileImage} alt='Recruiter' className='select-user-img' />
                                }
                            </Dropdown.Toggle>

                            <Dropdown.Menu className='dk-adstracker-DrpMenu12'>
                                <div className="d-flex">
                                    <UserList rowid={cell.row.original.id} onClick={selectUser} />
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </React.Fragment>
                )
            }
        },
        // {
        //     Header: 'Job Title',
        //     accessor: 'teams',
        //     Cell: ({ cell }) => {
        //         return <>
        //             <Dropdown className="dkg-bonus-modelDropDown">
        //                 <Dropdown.Toggle variant="" className="dkg-model-dropdown-toggle">
        //                     Job Title 1
        //                 </Dropdown.Toggle>
        //                 <Dropdown.Menu className="dkg-model-dropdown-menu">
        //                     <Dropdown.Item className="dkg-model-dropdown-item">Job Title 1</Dropdown.Item>
        //                     <Dropdown.Item className="dkg-model-dropdown-item">Job Title 2</Dropdown.Item>
        //                     <Dropdown.Item className="dkg-model-dropdown-item">Job Title 3</Dropdown.Item>
        //                     <Dropdown.Item className="dkg-model-dropdown-item">Job Title 4</Dropdown.Item>
        //                     <Dropdown.Item className="dkg-model-dropdown-item">Job Title 5</Dropdown.Item>
        //                 </Dropdown.Menu>
        //             </Dropdown>
        //         </>
        //     }
        // },
        {
            Header: 'Department',
            id: 'departments',
            accessor: 'departments',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value) => {
                    updateMyData(id, columnId, value)
                };

                return (
                    // <div className='dk-cursor-p' onClick={() => openUpdateValuePop(cell.row.original.id, 'departments', cell.row.original.departments)}>
                    //     {
                    //         cell.row.original.departments
                    //     }
                    // </div>
                    <Dropdown className="dkg-bonus-modelDropDown">
                        <Dropdown.Toggle variant="" className="dkg-model-dropdown-toggle">
                            {
                                cell.row.original.departments
                            }
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dkg-model-dropdown-menu">
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'departments', 'Management')} >Management</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'departments', 'Recruitment')} >Recruitment</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'departments', 'Accounting')} >Accounting</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'departments', 'New Clients')} >New Clients</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'departments', 'Marketing')} >Marketing</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'departments', 'Resource')} >Resource</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'departments', 'Training')} >Training</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'departments', 'Legal')} >Legal</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'departments', 'IT')} >IT</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            }
        },
        {
            Header: 'Job Title',
            accessor: 'jobPosition',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value) => {
                    updateMyData(id, columnId, value)
                };
                return (
                    // <div className='dk-cursor-p' onClick={() => openUpdateValuePop(cell.row.original.id, 'jobPosition', cell.row.original.jobPosition)}>
                    //     {
                    //         cell.row.original.jobPosition
                    //     }
                    // </div>
                    <Dropdown className="dkg-bonus-modelDropDown">
                        <Dropdown.Toggle variant="" className="dkg-model-dropdown-toggle">
                            {cell.row.original.jobPosition}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dkg-model-dropdown-menu dkg-perfor-jobtitledropdown-menu">
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'jobPosition', 'Director')} >Director</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'jobPosition', 'Operations Manager')} >Operations Manager</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'jobPosition', 'Business Developer')} >Business Developer</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'jobPosition', 'Accounting Manager')} >Accounting Manager</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'jobPosition', 'Resource Manager')} >Resource Manager</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'jobPosition', 'Marketing Executive')} >Marketing Executive</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'jobPosition', 'Account Manager')} >Account Manager</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'jobPosition', 'Recruitment Consultant')} >Recruitment Consultant</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'jobPosition', 'Database Admin')} >Database Admin</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'jobPosition', 'Ads Posting Admin')} >Ads Posting Admin</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'jobPosition', 'IT Developer')} >IT Developer</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'jobPosition', 'IT Designer')} >IT Designer</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            }
        },
        {
            Header: 'Model',
            accessor: 'model',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value) => {
                    updateMyData(id, columnId, value)
                };
                return (
                    <Dropdown className="dkg-bonus-modelDropDown">
                        <Dropdown.Toggle variant="" className="dkg-model-dropdown-toggle">
                            {cell.row.original.model}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dkg-model-dropdown-menu">
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'model', 'Model 1')} >Model 1</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'model', 'Model 2')} >Model 2</Dropdown.Item>
                            <Dropdown.Item className="dkg-model-dropdown-item" onClick={() => onItemClick(cell.row.original.id, 'model', 'Model 3')} >Model 3</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )
            }
        },
        {
            Header: 'Performance',
            accessor: 'rating',
            Cell: ({ cell }) => {
                // const onItemClick = async (id) => {                    
                //      setIsRowIdds(id)
                //     await dispatch(updateValue({ id:id, key: 'rating', value:ratingCount }))
                // };
                return (
                    <div className='dkg-per-rartingCon'>
                        <div className='dkg-per-rarting-dropdown'>
                            <div className='dkg-per-rarting-dropdown-menu' >
                                <ReactStars
                                    count={5}
                                    onChange={(e) => { ratingChanged(e, cell.row.original.id); }}
                                    size={23}
                                    value={cell.row.original.rating}
                                    isHalf={true}
                                    emptyIcon={<i className="far fa-star"></i>}
                                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                                    fullIcon={<i className="fa fa-star"></i>}
                                    activeColor="#e99212"
                                    inactiveColor={"#ddd"}
                                    classNames={'dkg-react-starts'}
                                />
                            </div>
                        </div>

                    </div>
                )
            }

        },
        {
            Header: 'Bonus',
            accessor: 'bonus',
            Cell: ({ cell }) => {
                return (
                    <div className='dk-cursor-p' onClick={() => openUpdateValuePop(cell.row.original.id, 'bonus', cell.row.original.bonus)}>
                        {
                            cell.row.original.bonus
                        }
                        <span className='ml-1'>€</span>
                    </div>
                )
            },
            Footer: (info) => {
                const total = React.useMemo(
                    () =>
                        info.flatRows.reduce((sum, row) => Number(row.original.bonus) + sum, 0),
                    [info.rows]
                )
                return <><span className="sum countGrossSale" style={{ fontWeight: "600" }}>{total}<span className='ml-1'>€</span></span></>
            },
        },
        {
            id: 'Recruters',
            Header: 'Recruters',
            accessor: 'recInfo.uid',
        },

    ], [])

    const [instanceData, setInstanceData] = useState('0')
    const reactTableInstance = (instance) => {
        if (instance) {
            setList(instance.flatRows)
            setInstanceData(instance)
        }
    };

    const updateMyData = async (id, columnId, value) => {
        await dispatch(updateValue({ id, key: columnId, value }))
    }

    const [isClearAll, setIsClearAll] = useState(false)

    const restClearAllFilter = async () => {
        setIsClearAll(false)
    }

    const [RecFilterId, setRecFilterId] = useState('0');
    const [taskByFilterImg, setTaskByFilterImg] = useState('0');

    const [taskForFilterId, setTaskForFilterId] = useState('0');
    const [taskForFilterImg, setTaskForFilterImg] = useState('0');

    const selectUserFilter = async (e) => {
        // const type = e.target.dataset.type;
        setRecFilterId(e.target.dataset.id)
        setTaskByFilterImg(e.target.dataset.img)
    }

    const clearUserFilter = async (e) => {
        //const type = e.target.dataset.type;
        setRecFilterId('0')
        setTaskByFilterImg('0')
    }

    const clearAllFilter = async () => {
        await dispatch(listAll({year: yearValue, month: monthValue}))
        setRecFilterId('0')
        setTaskByFilterImg('0')
        setTaskForFilterId('0')
        setTaskForFilterImg('0')

    }

    /********** */
    const [modalShow, setModalShow] = React.useState(false);
    const selectUser = (e) => {
        dispatch(updateUser({ id: e.target.dataset.rowid, key: "uid", value: e.target.dataset.id }))
    }
    /********** */
    // const [teamssId, setTeamsId] = useState('0');
    const [yearsId, setYearsId] = useState('0');
    const [monthsId, setMonthsId] = useState('0');
    const [modelssId, setModelsId] = useState('0');
    const [jobPositionId, setJobPositionId] = useState('0');
    const [newModelId, setNewModelsId] = useState('0');
    const [DepartmentsId, setDepartmentsId] = useState('0');
    const [ratingCountId, setRatingCountId] = useState('0');
    const [recruterFilterId, setRecruterFilterId] = useState('0');

    const [filterTags, setFilterTags] = useState([]);
    const [taskStatusFilterText, setTaskStatusFilterText] = useState('CATEGORY')
    const [caseStatusFilterText, setCaseStatusFilterText] = useState('Week')
    const [monthsFilterText, setMonthsFilterText] = useState('Month')
    const [monthsFilterId, setMonthsFilterId] = useState('0')
    const [caseStatusId, setCaseStatusId] = useState('0')

    const [paymentTypeFilterText, setPaymentTypeFilterText] = useState('PAY TYPE')
    const [caseTypeId, setCaseTypeId] = useState('0');
    const [casePayTypeId, setCasePayTypeId] = useState('0')

    const [yearFilterText, setYearsFilterText] = useState('Year')
    const [yearFilterId, setYearsFilterId] = useState('0')

    const selectStatusFilter = async (e) => {
        const type = e.target.dataset.type;
        const value = e.target.dataset.value;
        let i;

        switch (type) {
            case 'paymentType':
                i = filterTags.findIndex(function (x) { return x.type === 'paymentType' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setCaseTypeId(value)
                setTaskStatusFilterText(value)
                break;
            case 'payType':
                i = filterTags.findIndex(function (x) { return x.type === 'payType' })

                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setCasePayTypeId(value)
                setPaymentTypeFilterText(value)
                break;

        }
    }

    const onSelectStatusFilter = (eventKey) => {
        setCaseStatusFilterText(eventKey)
        setCaseStatusId(eventKey)
    }

    const onSelectMonthFilter = (eventKey) => {
        setMonthsFilterText(eventKey)
        setMonthsFilterId(eventKey)
    }
    const onSelectYearFilter = (eventKey) => {
      
        setYearValue(eventKey)
        dispatch(listAll({year: yearValue, month: monthValue}))
        setYearsFilterText(eventKey)
        setYearsFilterId(eventKey)
    }

    const removeTag = async (e) => {
        const type = e.target.dataset.type;
        setFilterTags(filterTags.filter((item) => item.type !== type))

        switch (type) {
            case 'paymentType':
                setCaseTypeId('0')
                setTaskStatusFilterText('CATEGORY')
                break;

            case 'model':
                setCaseStatusId('0')
                setCaseStatusFilterText('model')
                setModelsId('0')
                break;
            case 'month':
                setMonthsFilterId('0')
                setMonthsFilterText('Month')
                setMonthsId('0')
                break;
            case 'year':
                setYearValue(today.getFullYear())
                setYearsFilterId('0')
                setYearsFilterText('Year')
                setYearsId('0')
                break;
            // case 'teams':
            //     setTeamsId('0')
            //     break;
            case 'departments':
                setDepartmentsId('0')
                break;
            case 'jobPosition':
                setJobPositionId('0')
                break;
            default:
                break;
        }
    }

    const moveRecords = async (e) => {
        SetDataMoveTo(e.target.dataset.moveto)
    }

    const addNew = () => {
        dispatch(addValue({ updatedDate: '', year: yearValue, month: monthValue, period: '', uid: -1, model: '', departments: '-', jobPosition: '-', rating: 0, bonus: 0 }))
    }

    const [isShowSearch, setShowSearchModal] = useState(false)
    const showSearch = () => {
        setShowSearchModal(true)
    }

    const SearchClose = () => {
        setShowSearchModal(false)
    }


    const selectOtherFilter = async (e) => {
        const type = e.target.dataset.type;
        const value = e.target.dataset.value;
        let i;
        switch (type) {

            case 'rating':
                i = filterTags.findIndex(function (x) { return x.type === 'rating' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setRatingCountId(value)
                break;

            case 'year':
                i = filterTags.findIndex(function (x) { return x.type === 'year' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setYearsId(value)
                break;

            case 'month':
                i = filterTags.findIndex(function (x) { return x.type === 'month' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setMonthsId(value)
                break;

            case 'week':
                i = filterTags.findIndex(function (x) { return x.type === 'week' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setModelsId(value)
                break;

            case 'jobPosition':
                i = filterTags.findIndex(function (x) { return x.type === 'jobPosition' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setJobPositionId(value)
                break;
            case 'model':
                i = filterTags.findIndex(function (x) { return x.type === 'model' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setNewModelsId(value)
                break;
            case 'departments':
                i = filterTags.findIndex(function (x) { return x.type === 'departments' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setDepartmentsId(value)
                break;

            default:
                i = filterTags.findIndex(function (x) { return x.type === type })
                if (i === -1) {
                    filterTags.push({ type, value })
                }
                break;
        }
    }

    return (
        <>
            {
                (isShowSearch) ? <AdvancedFilter onHide={SearchClose} headerTitle="Search Performance Bonus" list={list} selectUserFilter={selectUserFilter} selectOtherFilter={selectOtherFilter} tableName="Search Performance Bonus" /> : null
            }

            {
                (isUpdatePop) ? <UpdatePop onHide={hideUpdateValuePop} rowId={rowId} columnKey={columnKey} value={columnValue} /> : null
            }
            <InformationModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

            <div className='dk-kpiCase-containerMain container-fluid'>
                <div className='dk-rcWalletSummary-main p-0'>
                    <div className='col-md-12 p-0'>
                        <div className="dk-recwalletHead-12">
                            <div className="dk-recwallet-leftCon">
                                <div className="dkg-rec-collect-srchinput">
                                    <div className="input-group">
                                        {
                                            (instanceData !== '0') ? <GlobalFilter
                                                preGlobalFilteredRows={instanceData.preGlobalFilteredRows}
                                                globalFilter={instanceData.state.globalFilter}
                                                setGlobalFilter={instanceData.setGlobalFilter}
                                                isClearAll={isClearAll}
                                                restClearAllFilter={restClearAllFilter}
                                            /> : null
                                        }

                                    </div>
                                </div>
                                <Link className="addMonthBtn dk-rcwallec-addnew ml-2 mr-2" to="#" onClick={addNew}><i className="fas fa-plus"></i></Link>
                                <div className='dkg' style={{ position: "relative" }}>
                                    <TaskByDropdown list={list} id={RecFilterId} img={taskByFilterImg} selectUserFilter={selectUserFilter} />
                                    {
                                        (RecFilterId !== '0') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="TASKBY" onClick={clearUserFilter}></i> : null
                                    }

                                </div>
                                <Link className='addMonthBtn dk-rcwallec-addnew' to="#" onClick={showSearch}>
                                    <i className="fa fa-filter" aria-hidden="true"></i>
                                </Link>
                            </div>
                            {/* <div className='dk-recwallet-centerCon'>
                                <div className='dk-filterTagsCon'>
                                    {
                                        (filterTags.length > 0) ?
                                            filterTags.length > 0 && filterTags.map((item, index) =>

                                                <ButtonGroup className='dk-filterbtnGrp mr-2' key={`tagButton${index}`}>
                                                    <Button className="dk-filterbtntext">{item.value}</Button>
                                                    <Button className="dk-filterbtnicon" data-type={item.type} onClick={removeTag}><i className="fas fa-times" data-type={item.type} onClick={removeTag}></i></Button>
                                                </ButtonGroup>

                                            )
                                            :
                                            <div className='dkg-pagetitle'>PERFORMACNE BONUS
                                                <span onClick={() => setModalShow(true)} className='ml-2' style={{ cursor: 'pointer' }}>
                                                    <FaInfoCircle />
                                                </span>
                                            </div>
                                    }
                                </div>
                            </div> */}
                            {/* <div className="dk-recwallet-rightCon">
                                <div className='dk-rcWallet-filter'>
                                    <div className="mr-2 dkg-bonus-rightFilter-btnCon">
                                        <button className='dkg-bonus-rightFilterIcon' onClick={showSearch}>
                                            <i className="fa fa-filter" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <div className="dk-reWallet-filterCols">
                                        <WeekFilterDropdown list={list} caseStatusFilterText={caseStatusFilterText} onSelectStatusFilter={onSelectStatusFilter} tableType={'sendouts_bonus'} />
                                        {
                                            (caseStatusFilterText !== 'Week') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="week" onClick={removeTag}></i> : null
                                        }
                                    </div>
                                    <div className="dk-reWallet-filterCols dkg-rcper-timeframeFilter">

                                        <MonthFilterDropdown list={list} caseStatusFilterText={monthsFilterText} onSelectStatusFilter={onSelectMonthFilter} tableType={'sendouts_bonus'} />
                                        {
                                            (monthsFilterText !== 'Month') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="month" onClick={removeTag}></i> : null
                                        }

                                        <MonthFilterDropdown list={list} caseStatusFilterText={monthsFilterText} onSelectStatusFilter={onSelectMonthFilter} tableType={'sendouts_bonus'} />
                                        {
                                            (monthsFilterText !== 'Month') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="month" onClick={removeTag}></i> : null
                                        }

                                    </div>
                                    <div className="dk-reWallet-filterCols dkg-rcper-timeframeFilter">

                                        <YearFilterDropdown list={list} caseStatusFilterText={yearFilterText} onSelectStatusFilter={onSelectYearFilter} tableType={'sendouts_bonus'} />
                                        {
                                            (yearFilterText !== 'Year') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="year" onClick={removeTag}></i> : null
                                        }

                                    </div>
                                </div>
                            </div> */}
                            <div className="dk-kpiCase-months dkg-performance-months">
                            <ul>
                                <li onClick={() => setMonthValue(1)}><Link to="#" className={monthValue == 1 ? 'active' : ''}  >Jan</Link></li>
                                <li onClick={() => setMonthValue(2)}><Link to="#" className={monthValue == 2 ? 'active' : ''}  >Feb</Link></li>
                                <li onClick={() => setMonthValue(3)}><Link to="#" className={monthValue == 3 ? 'active' : ''}  >Mar</Link></li>
                                <li onClick={() => setMonthValue(4)}><Link to="#" className={monthValue == 4 ? 'active' : ''}  >Apr</Link></li>
                                <li onClick={() => setMonthValue(5)}><Link to="#" className={monthValue == 5 ? 'active' : ''}  >May</Link></li>
                                <li onClick={() => setMonthValue(6)}><Link to="#" className={monthValue == 6 ? 'active' : ''} >Jun</Link></li>
                                <li onClick={() => setMonthValue(7)}><Link to="#" className={monthValue == 7 ? 'active' : ''}  >Jul</Link></li>
                                <li onClick={() => setMonthValue(8)}><Link to="#" className={monthValue == 8 ? 'active' : ''}  >Aug</Link></li>
                                <li onClick={() => setMonthValue(9)}><Link to="#" className={monthValue == 9 ? 'active' : ''} >Sep</Link></li>
                                <li onClick={() => setMonthValue(10)}><Link to="#" className={monthValue == 10 ? 'active' : ''} >Oct</Link></li>
                                <li onClick={() => setMonthValue(11)}><Link to="#" className={monthValue == 11 ? 'active' : ''}>Nov</Link></li>
                                <li onClick={() => setMonthValue(12)}><Link to="#" className={monthValue == 12 ? 'active' : ''} >Dec</Link></li>
                                </ul>
                                <div className="dk-reWallet-filterCols dkg-rcper-timeframeFilter">
                                    <YearFilterDropdown list={list} caseStatusFilterText={yearFilterText} onSelectStatusFilter={onSelectYearFilter} tableType={'sendouts_bonus'} />
                                    {
                                        (yearFilterText !== 'Year') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="year" onClick={removeTag}></i> : null
                                    }

                                </div>
                            </div>
                        </div>
                        <div className='dk-rcWallet-summaryTable-main dk-rcWalletPlacement-table dkg-placementbonus-table234'>
                            <div className='table-responsive'>
                                <Table
                                    data={data}
                                    columns={columns}
                                    getInstanceCallback={reactTableInstance}
                                    loading={isLoading}
                                    updateMyData={updateMyData}
                                    RecFilterId={RecFilterId}
                                    taskForFilterId={taskForFilterId}
                                    caseTypeId={caseTypeId}
                                    caseStatusId={caseStatusId}
                                    casePayTypeId={casePayTypeId}
                                    monthsFilterId={monthsFilterId}
                                    yearFilterId={yearFilterId}
                                    // teamssId={teamssId}
                                    yearsId={yearsId}
                                    monthsId={monthsId}
                                    modelssId={modelssId}
                                    jobPositionId={jobPositionId}
                                    DepartmentsId={DepartmentsId}
                                    recruterFilterId={recruterFilterId}
                                    newModelId={newModelId}
                                    ratingCountId={ratingCountId}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`batch-actions-menu-wrapper dk-action-popupMain ${isActive ? "activePopup" : ""}`} style={{ width: 'auto' }}>
                <div className="num-of-actions_wrapper">
                    <div className='num-of-actions'>{countCheck}</div>
                </div>
                <div className="batch-actions-title-section pl-0">
                    <div className='dk-rowSelected'>
                        <span>Row Selected</span>
                    </div>
                </div>

                <DeleteModal loading={loading} success={success} deleteItem={deleteItemBulk} tableName="sendouts_bonus" />
                <div className="batch-actions-delete-item CloseAll d-flex align-items-center justify-content-center" onClick={handleToggle}>
                    <span><i className="fas fa-times"></i></span>
                </div>
            </div>
        </>
    )
}

export default RcPerformance;
