import React, { useEffect, useState, useMemo, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import { FaSearch, FaFilter, FaInfoCircle, FaSync } from "react-icons/fa";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
  useAsyncDebounce,
} from "react-table";
import { useDispatch, useSelector } from "react-redux";
import "./moduleclientsinocustom.scss";
import {
  clearState,
  getInvoiceList,
  deleteFun,
  moveRecordsToPaid,
  updateByKey,
  taskDetails,
  fetchCaseStatus,
  fetchCaseProgress,
  fetchCaseStage,
} from "../../../../../slice/accounting/invoiceBooks";
import { ButtonGroup, Button, DropdownButton } from "react-bootstrap";
import TableLoader from "../../../../ui/tableLoader";
import Dropdown from "react-bootstrap/Dropdown";
import MonthFilterDropdown from "../tableFilters/month/monthsFilter";
import $ from "jquery";
import DeleteModal from "../utils/delete/DeleteRow";
import MonthBGColour from "../utils/monthsBgColor/monthbg";
import { showSuccess } from "../../../../../slice/utils/message/messageSlice";
import CommentModal from "../../../../utils/comment";
import CaseStatusFilter from "../tableFilters/statusfilter";
import AdvancedFilter from "../modal/SearchSendout";
import UpdateModal from "../modal/UpdateModal";
import AddNew from "./modal/AddNewInvoice";
import EditDetails from "./modal/EditDetails";

import CaseStatus from "../utils/dropdown/CaseStatus";
import CaseProgress from "../utils/dropdown/CaseProgress";
import CaseStage from "../utils/dropdown/CaseStage";
import CaseStatusList from "../../../../ui/dropdownList/CaseStatusInvoiceBook";

import CaseProgressList from "../../../../ui/dropdownList/CaseProgressInvoiceBook";
import CaseStageList from "../../../../ui/dropdownList/CaseStageInvoiceBook";

//import CaseOther from "../../../../pages/candidate/pipeline/dropdown/other";

import { SYSTEM_CONSTANTS } from "../../../../../constants";
//import { caseStatus, caseProgress, caseStage } from "../../../../../slice/layout/layoutSlice";

import moment from "moment-timezone";
import Checkbox from "../utils/checkbox/radioBtn";
//import DatePicker from './datepicker/DatePickerNew'
//import DatePicker from '../../../../ui/datepicker/DatePicker'
const TableSuspence = lazy(() =>
  import("../../../../ui/tableLoader/tableSuspence")
);

const useInstance = (instance) => {
  if (instance && instance.getInstanceCallback) {
    instance.getInstanceCallback(instance);
  }
};

function Table({
  columns,
  data,
  loading,
  getInstanceCallback,
  filter,
  updateMyData,
  caseTypeId,
  casePayTypeId,
  caseStatusId,
  monthsFilterId,
  monthsId,
  progressId,
  stageId,
  companyId,
  projectId,
  languageId,
  roleTypeId,
  cvSourceId,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    prepareRow,
    state,
    setFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      getInstanceCallback,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
      initialState: {
        pageIndex: 0,
        pageSize: 100000000000,
        // hiddenColumns: ["AManger", "SRecruters"]
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    (hooks) => hooks.useInstance.push(useInstance)
  );

  useEffect(() => {
    if (cvSourceId === "0") {
      setTimeout(() => {
        setFilter("cvSource");
      }, 200);
    } else {
      setFilterType("cvSource");
      doFilter(cvSourceId);
    }
  }, [cvSourceId]);

  useEffect(() => {
    if (monthsId === "0") {
      setTimeout(() => {
        setFilter("month");
      }, 200);
    } else {
      setFilterType("month");
      doFilter(monthsId);
    }
  }, [monthsId]);

  useEffect(() => {
    if (roleTypeId === "0") {
      setTimeout(() => {
        setFilter("roleType");
      }, 200);
    } else {
      setFilterType("roleType");
      doFilter(roleTypeId);
    }
  }, [roleTypeId]);

  useEffect(() => {
    if (languageId === "0") {
      setTimeout(() => {
        setFilter("language");
      }, 200);
    } else {
      setFilterType("language");
      doFilter(languageId);
    }
  }, [languageId]);
  useEffect(() => {
    if (projectId === "0") {
      setTimeout(() => {
        setFilter("project");
      }, 200);
    } else {
      setFilterType("project");
      doFilter(projectId);
    }
  }, [projectId]);
  useEffect(() => {
    if (companyId === "0") {
      setTimeout(() => {
        setFilter("company_id");
      }, 200);
    } else {
      setFilterType("company_id");
      doFilter(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (progressId === "0") {
      setTimeout(() => {
        setFilter("progress");
      }, 200);
    } else {
      setFilterType("progress");
      doFilter(progressId);
    }
  }, [progressId]);

  useEffect(() => {
    if (stageId === "0") {
      setTimeout(() => {
        setFilter("caseStage");
      }, 200);
    } else {
      setFilterType("caseStage");
      doFilter(stageId);
    }
  }, [stageId]);

  useEffect(() => {
    if (caseStatusId === "0") {
      setTimeout(() => {
        setFilter("caseStatus", "");
      }, 200);
    } else {
      setFilterType("caseStatus");
      doFilter(caseStatusId);
    }
  }, [caseStatusId]);

  useEffect(() => {
    if (monthsFilterId === "0") {
      setTimeout(() => {
        setFilter("month", "");
      }, 200);
    } else {
      setFilterType("month");
      doFilter(monthsFilterId);
    }
  }, [monthsFilterId]);

  // useEffect(() => {
  //     if (casePayTypeId === '0') {
  //         setTimeout(() => {
  //             setFilter('payType', '')
  //         }, 200)
  //     } else {
  //         setFilterType('payType')
  //         doFilter(casePayTypeId);
  //     }
  // }, [casePayTypeId])

  // useEffect(() => {
  //     if (caseTypeId === '0') {
  //         setTimeout(() => {
  //             setFilter('caseStatus', '')
  //         }, 200)
  //     } else {
  //         setFilterType('caseStatus')
  //         doFilter(caseTypeId);
  //     }
  // }, [caseTypeId])

  // const handlePageChange = (event, value) => {
  //     gotoPage(Number(value) - 1);
  // };

  const [filterType, setFilterType] = useState("");

  const doFilter = React.useCallback(
    useAsyncDebounce((id) => {
      setFilter(filterType, id);
    }, 200),
    [filterType]
  );

  return (
    <React.Fragment>
      <table
        className="table table-bordered dkg-acc-invoice-table dkg-clientinvo-banking-ui123"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return loading ? (
              [...Array(10)].map((e, i) => (
                <tr>
                  <TableLoader colSpan="22" />
                </tr>
              ))
            ) : (
              <Suspense fallback={<TableSuspence colSpan="22" />}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className, // pay attention to this
                          style: cell.column.style,
                          // set here your other custom props
                        },
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              </Suspense>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td {...column.getFooterProps()} className="dkg-suMM">
                  {column.render("Footer")}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </React.Fragment>
  );
}

function debounce(func, wait, immediate) {
  var timeout;
  return (...args) => {
    var context = this;
    var later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isClearAll,
  restClearAllFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState("");

  const onChangeSearchTitle = (e) => {
    const { value: nextValue } = e.target;
    setValue(e.target.value);
    debouncedSave(nextValue);
  };

  const debouncedSave = React.useCallback(
    debounce((nextValue) => {
      setGlobalFilter(nextValue);
    }, 1000)
  );

  const clearSearch = () => {
    setValue("");
    setTimeout(() => {
      setGlobalFilter(undefined);
    }, 200);
  };

  useEffect(() => {
    if (isClearAll) {
      clearSearch();
      restClearAllFilter();
    }
  }, [isClearAll]);

  return (
    <React.Fragment>
      <input
        value={value}
        className="form-control"
        onChange={onChangeSearchTitle}
        placeholder={`Search ...`}
      />
      {value !== undefined && value.length > 0 ? (
        <div className="input-group-append">
          <button className="" type="button" onClick={clearSearch}>
            <i className="fa fa-times"></i>
          </button>
        </div>
      ) : (
        <div className="input-group-append">
          <button className="" type="button">
            <i className="fa fa-search"></i>
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

const InvoiceTwentyTwo = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    invoiceBookList,
    isUpdate,
    message,
    isTaskUpdate,
    isTaskInserted,
    isTaskDelete,
    taskTypeList,
    iscommentCountUpdate,
  } = useSelector((state) => state.invoiceBooks);

  const [list, setList] = useState([]);
  const [data, setData] = useState([]);
  const [rowId, setRowId] = useState(0);
  const [dataRowId, setDataRowId] = useState(0);
  const lastSegment = window.location.href.split("/").pop(); //for decode use atob("zyz")

  useEffect(() => {
    document.title = "Accounting Invoice Book | Dk Global CRM";
  }, []);

  useEffect(() => {
    const fetchList = async () => {
      try {
        await dispatch(getInvoiceList({ year: atob(lastSegment) }));
        await dispatch(
          fetchCaseStatus({
            id: SYSTEM_CONSTANTS.INVOICE_SALES_BOOK.caseStatusID,
          })
        );
        await dispatch(
          fetchCaseProgress({
            id: SYSTEM_CONSTANTS.INVOICE_SALES_BOOK.caseProgressID,
          })
        );
        await dispatch(
          fetchCaseStage({
            id: SYSTEM_CONSTANTS.INVOICE_SALES_BOOK.caseStageID,
          })
        );
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  }, [dispatch, lastSegment]);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setData(invoiceBookList);
      }, 200);
    }
    if (iscommentCountUpdate) {
      dispatch(clearState());
      setData(invoiceBookList);
    }
    if (isUpdate) {
      dispatch(clearState());
      setData(invoiceBookList);
      setIsActive(false);
      SetshowEdit(false);
    }
  }, [
    isLoading,
    isUpdate,
    isTaskUpdate,
    iscommentCountUpdate,
    lastSegment,
    dispatch,
  ]);

  useEffect(() => {
    if (isTaskDelete) {
      setLoading(false);
      setSuccess(true);
      dispatch(clearState());
      dispatch(getInvoiceList({ year: atob(lastSegment) }));
      dispatch(showSuccess({ msg: "Record deleted successfully" }));
    }
  }, [isTaskDelete]);

  // useEffect(() => {
  //     if (isTaskUpdate) {
  //         setIsActive(false)
  //         dispatch(clearState())
  //         //dispatch(getInvoiceList({year:atob(lastSegment)}))
  //         dispatch(showSuccess({ msg: 'Record updated successfully!' }))
  //     }
  // }, [isTaskUpdate])

  const [checkBtnTxt, setCheckBtnTxt] = useState("Check All");
  const [countCheck, setCountCheck] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const checkActiveExtraDiv = async (isShowExitra, checkedBoxCount) => {
    if (isShowExitra) {
      setIsActive(true);
      setCountCheck(checkedBoxCount);
    } else {
      setIsActive(false);
      setCountCheck(checkedBoxCount);
    }
  };

  const checkExtraDivBtnTxt = (btnTxt) => {
    setCheckBtnTxt(btnTxt);
  };

  const handleToggle = () => {
    $(".chkCandidate").prop("checked", false);
    setIsActive(false);
  };

  const deleteItemBulk = async () => {
    var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
    if (checkedBoxCount == 0) {
    } else {
      var canIds = [];
      $.each($(".chkCandidate:checked"), function () {
        canIds.push($(this).val());
      });
      setLoading(true);
      setSuccess(false);
      await dispatch(deleteFun({ id: canIds }));
      $(".chkCandidate").prop("checked", false);
      setIsActive(false);
    }
  };

  const [dataMoveTo, SetDataMoveTo] = useState("");
  const [showView, SetShowView] = useState(false);
  const [showEdit, SetshowEdit] = useState(false);

  // const callViewPopup = async (data) => {
  //     await dispatch(taskDetails({ id: data }))
  //     SetShowView(true)
  // }
  const editFunction = async (e) => {
    await dispatch(taskDetails({ id: dataRowId }));
    SetshowEdit(true);
  };
  const closeEditModal = () => {
    SetshowEdit(false);
  };

  const [profileCanId, setProfileCanId] = useState(0);
  const [isProfileModal, setIsProfileModal] = useState(false);

  const handleCloseProfile = () => {
    setIsProfileModal(false);
    //SetshowEdit(false)
  };
  const veiwHandleClose = () => {
    SetShowView(false);
  };
  const handleClose = () => {
    SetshowEdit(false);
  };

  const [isShowSearch, setShowSearchModal] = useState(false);

  const showSearch = () => {
    setShowSearchModal(true);
  };

  const SearchClose = () => {
    setShowSearchModal(false);
  };

  const [isShowAddNew, setShowAddNew] = useState(false);

  const showAddNew = () => {
    setShowAddNew(true);
  };

  const SearchAddNew = () => {
    setShowAddNew(false);
  };

  const handleDateChange = async (e, idd, column) => {
    await dispatch(
      updateByKey({
        id: idd,
        key: column,
        value: moment(e).format("DD MMM YY"),
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "SNo.",
        accessor: "id",
        Cell: ({ cell }) => {
          return (
            <span className="dk-checkinputIconCon">
              {Number(cell.row.id) + 1}
              <div className="dk-checkinputIcon">
                <Checkbox
                  id={cell.row.original.id}
                  checkActiveExtraDiv={checkActiveExtraDiv}
                  checkExtraDivBtnTxt={checkExtraDivBtnTxt}
                  setDataRowId={setDataRowId}
                />
              </div>
            </span>
          );
        },
      },
      {
        Header: "MONTH",
        accessor: "month",
        Cell: ({ cell }) => {
          return <MonthBGColour value={cell.row.original.month} />;
        },
      },
      {
        Header: "Joining",
        accessor: "joining",
      },
      {
        Header: "Resigned",
        accessor: "resigned",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Due Date",
        accessor: "dueDate",
      },
      {
        id: "company_id",
        Header: "COMPANY",
        accessor: "company",
      },
      {
        Header: "NAME OF CANDIDATE",
        accessor: "candidateName",
      },
      {
        Header: "STATUS",
        accessor: "caseStatus",
        id: "caseStatus",
        Cell: ({ cell }) => {
          const onItemClick = async (id, columnId, value, img) => {
            updateMyData(id, columnId, value, img);
          };
          return (
            <React.Fragment>
              <div className="dk-caseStatusDropCon">
                <Dropdown id={`caseStatus${cell.row.original.id}`}>
                  <Dropdown.Toggle
                    variant=""
                    className="dk-caseStatusfliterbtn"
                  >
                    <CaseStatus value={cell.row.original.caseStatus} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="caseStatusMenu">
                    <CaseStatusList
                      id={cell.row.original.id}
                      columnId="caseStatus"
                      onItemClick={onItemClick}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "progress",
        Header: "PROGRESS",
        accessor: "progress",
        Cell: ({ cell }) => {
          const onItemClick = async (id, columnId, value, img) => {
            updateMyData(id, columnId, value, img);
          };
          return (
            <React.Fragment>
              <div
                className="dk-caseStatusDropCon"
                onClick={() => {
                  let text = $(
                    `#caseStatus${cell.row.original.id} button .caseDiv`
                  ).html();
                }}
              >
                <Dropdown id={`progress${cell.row.original.id}`}>
                  <Dropdown.Toggle
                    variant=""
                    className="dk-caseStatusfliterbtn"
                  >
                    <CaseProgress value={cell.row.original.progress} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="caseStatusMenu">
                    <CaseProgressList
                      caseStatus={$(
                        `#caseStatus${cell.row.original.id} button .caseDiv`
                      ).html()}
                      id={cell.row.original.id}
                      columnId="progress"
                      onItemClick={onItemClick}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        Header: "STAGE",
        id: "caseStage",
        accessor: "caseStage",
        Cell: ({ cell }) => {
          const onItemClick = async (id, columnId, value, img) => {
            updateMyData(id, columnId, value, img);
          };
          return (
            <React.Fragment>
              <div
                className="dk-caseStatusDropCon"
                onClick={() => {
                  let text = $(
                    `#caseStatus${cell.row.original.id} button .caseDiv`
                  ).html();
                }}
              >
                <Dropdown id={`caseStage${cell.row.original.id}`}>
                  <Dropdown.Toggle
                    variant=""
                    className="dk-caseStatusfliterbtn"
                  >
                    <CaseStage value={cell.row.original.caseStage} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="caseStatusMenu">
                    <CaseStageList
                      caseStatus={$(
                        `#caseStatus${cell.row.original.id} button .caseDiv`
                      ).html()}
                      id={cell.row.original.id}
                      columnId="caseStage"
                      onItemClick={onItemClick}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Comment",
        accessor: "commentCount",
        className: "pl-2 pr-2",
        Cell: ({ cell }) => {
          const commentPoupShow = (e) => {
            setRefId(cell.row.original.canId);
            setCommentCount(cell.row.original.commentCount);
            setCandidteStatus(cell.row.original.caseStatus);
            setCandidateProgess(cell.row.original.case_progress);
            setCandidateStage(cell.row.original.case_stage);
            setCommentPopupShow(true);
          };
          return (
            <>
              {cell.row.original.commentCount === 0 ? (
                <span
                  className="commentBoxCon zeroComment"
                  onClick={commentPoupShow}
                >
                  <span className="numCon zeroCommentCount">
                    {cell.row.original.commentCount}
                  </span>
                </span>
              ) : (
                <span className="commentBoxCon" onClick={commentPoupShow}>
                  <span className="numCon">
                    {cell.row.original.commentCount}
                  </span>
                </span>
              )}
            </>
          );
        },
      },
      {
        Header: "Full Fee",
        accessor: "fullFee",
        Cell: ({ cell }) => {
          return (
            <>
              <span
                onClick={() =>
                  openUpdateValuePop(
                    cell.row.original.id,
                    "fullFee",
                    cell.row.original.fullFee
                  )
                }
                className="cursor-p"
              >
                {cell.row.original.fullFee != ""
                  ? cell.row.original.fullFee
                  : "0"}{" "}
                €
              </span>
            </>
          );
        },
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.flatRows.reduce(
                (sum, row) => Number(row.original.fullFee) + sum,
                0
              ),
            [info.rows]
          );
          return (
            <>
              <span className="totalAmtPaymentTask">{total + " " + "€"} </span>
            </>
          );
        },
      },
      {
        Header: "AMOUNT DUE",
        accessor: "ammountDue",
        Cell: ({ cell }) => {
          return (
            <>
              <span
                onClick={() =>
                  openUpdateValuePop(
                    cell.row.original.id,
                    "ammountDue",
                    cell.row.original.ammountDue
                  )
                }
                className="cursor-p"
              >
                {cell.row.original.ammountDue} €
              </span>
            </>
          );
        },
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.flatRows.reduce(
                (sum, row) => Number(row.original.ammountDue) + sum,
                0
              ),
            [info.rows]
          );
          return (
            <>
              <span className="totalAmtPaymentTask">{total + " " + "€"} </span>
            </>
          );
        },
      },
      {
        Header: "AMOUNT PAID",
        accessor: "ammountPaid",
        Cell: ({ cell }) => {
          return (
            <>
              <span
                onClick={() =>
                  openUpdateValuePop(
                    cell.row.original.id,
                    "ammountPaid",
                    cell.row.original.ammountPaid
                  )
                }
                className="cursor-p"
              >
                {cell.row.original.ammountPaid} €
              </span>
            </>
          );
        },
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.flatRows.reduce(
                (sum, row) => Number(row.original.ammountPaid) + sum,
                0
              ),
            [info.rows]
          );
          return (
            <>
              <span className="totalAmtPaymentTask">{total + " " + "€"} </span>
            </>
          );
        },
      },
      {
        id: "docno",
        Header: "INV/CR No",
        accessor: "docNo",
      },
      {
        id: "language",
        Header: "LANGUAGE",
        accessor: "language",
      },
      {
        Header: "PROJECT",
        id: "project",
        accessor: "project",
      },
      {
        Header: "ROLE TYPE",
        id: "roleType",
        accessor: "roleType",
      },
      {
        Header: "CV SOURCE",
        id: "cvSource",
        accessor: "cvSource",
      },
    ],
    []
  );

  const [instanceData, setInstanceData] = useState("0");
  const reactTableInstance = (instance) => {
    if (instance) {
      setList(instance.flatRows);
      setInstanceData(instance);
    }
  };

  const updateMyData = async (id, columnId, value, img) => {
    switch (columnId) {
      case "status":
        $(`#caseStatus${id} button .caseDiv`)
          .html(value)
          .css("background-color", img);
        break;

      case "caseStatus":
        $(`#caseStatus${id} button .caseDiv`)
          .html(value)
          .css("background-color", img);
        // $(`#caseStage${id} button .caseDiv`).html('Select').css("background-color", img)
        //$(`#progress${id} button .caseDiv`).html('Select').css("background-color", img)
        break;

      case "progress":
        $(`#progress${id} button .caseDiv`).html(value);
        break;

      case "caseStage":
        $(`#caseStage${id} button .caseDiv`).html(value);
        break;
      case "am_id":
        $(`#am_id${id} button .tooltipMain img`).attr(
          "src",
          process.env.REACT_APP_IMG_CLOUD_BASE_URL + img
        );
        break;

      case "rec_id":
        $(`#rec_id${id} button .tooltipMain img`).attr(
          "src",
          process.env.REACT_APP_IMG_CLOUD_BASE_URL + img
        );
        break;

      case "rec_admin":
        $(`#rec_admin${id} button .tooltipMain img`).attr(
          "src",
          process.env.REACT_APP_IMG_CLOUD_BASE_URL + img
        );
        break;
      default:
        break;
    }

    await dispatch(updateByKey({ id, key: columnId, value }));
  };

  const [isClearAll, setIsClearAll] = useState(false);
  const clearAllFilter = async () => {
    setFilterTags([]);
    setMonthsId("0");
    setProjectId("0");
    setCaseStatusId("0");
    setMonthsFilterId("0");
    setProgressId("0");
    setStageId("0");
    setCompanyId("0");
    setLanguageId("0");
    setRoleTypeId("0");
    setcvSourceId("0");
    setCaseStatusFilterDDLText("Case Status");
    setMonthsFilterText("Month");
    await dispatch(getInvoiceList({ year: atob(lastSegment) }));
  };

  const restClearAllFilter = async () => {
    setIsClearAll(false);
  };

  const [caseStatusFilterDDLText, setCaseStatusFilterDDLText] =
    useState("Case Status");

  const [filterTags, setFilterTags] = useState([]);
  const [taskStatusFilterText, setTaskStatusFilterText] = useState("CATEGORY");
  const [caseStatusFilterText, setCaseStatusFilterText] =
    useState("PAY STATUS");
  const [paymentTypeFilterText, setPaymentTypeFilterText] =
    useState("PAY TYPE");
  const [caseTypeId, setCaseTypeId] = useState("0");
  const [caseStatusId, setCaseStatusId] = useState("0");
  const [casePayTypeId, setCasePayTypeId] = useState("0");
  const [monthsFilterText, setMonthsFilterText] = useState("Month");
  const [monthsFilterId, setMonthsFilterId] = useState("0");

  const selectStatusFilter = async (e) => {
    const type = e.target.dataset.type;
    const value = e.target.dataset.value;
    let i;

    switch (type) {
      case "paymentType":
        i = filterTags.findIndex(function (x) {
          return x.type === "paymentType";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCaseTypeId(value);
        setTaskStatusFilterText(value);
        break;
      case "payType":
        i = filterTags.findIndex(function (x) {
          return x.type === "payType";
        });

        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCasePayTypeId(value);
        setPaymentTypeFilterText(value);
        break;
    }
  };

  const onSelectStatusFilter = (eventKey) => {
    setCaseStatusFilterText(eventKey);
    setCaseStatusId(eventKey);
  };

  const onSelectMonthFilter = (eventKey) => {
    setMonthsFilterText(eventKey);
    setMonthsFilterId(eventKey);
  };

  const [modalShow, setModalShow] = React.useState(false);
  const [monthsId, setMonthsId] = useState("0");
  const [projectId, setProjectId] = useState("0");
  const [progressId, setProgressId] = useState("0");
  const [stageId, setStageId] = useState("0");
  const [companyId, setCompanyId] = useState("0");
  const [languageId, setLanguageId] = useState("0");
  const [roleTypeId, setRoleTypeId] = useState("0");
  const [cvSourceId, setcvSourceId] = useState("0");

  const selectOtherFilter = async (e) => {
    const type = e.target.dataset.type;
    const value = e.target.dataset.value;

    let i;
    switch (type) {
      case "caseStatus":
        i = filterTags.findIndex(function (x) {
          return x.type === "caseStatus";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCaseStatusId(value);
        setCaseStatusFilterDDLText(value);
        break;

      case "month":
        i = filterTags.findIndex(function (x) {
          return x.type === "month";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setMonthsId(value);
        break;

      case "language":
        i = filterTags.findIndex(function (x) {
          return x.type === "language";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setLanguageId(value);
        break;
      case "cvSource":
        i = filterTags.findIndex(function (x) {
          return x.type === "cvSource";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setcvSourceId(value);
        break;

      case "roleType":
        i = filterTags.findIndex(function (x) {
          return x.type === "roleType";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setRoleTypeId(value);
        break;
      case "project":
        i = filterTags.findIndex(function (x) {
          return x.type === "project";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setProjectId(value);
        break;

      case "progress":
        i = filterTags.findIndex(function (x) {
          return x.type === "progress";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setProgressId(value);
        break;

      case "stage":
        i = filterTags.findIndex(function (x) {
          return x.type === "stage";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setStageId(value);
        break;

      case "company":
        i = filterTags.findIndex(function (x) {
          return x.type === "company";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCompanyId(value);
        break;

      default:
        i = filterTags.findIndex(function (x) {
          return x.type === type;
        });
        if (i === -1) {
          filterTags.push({ type, value });
        }
        break;
    }
  };

  const removeTag = async (e) => {
    const type = e.target.dataset.type;
    setFilterTags(filterTags.filter((item) => item.type !== type));

    switch (type) {
      case "paymentType":
        setCaseTypeId("0");
        setTaskStatusFilterText("CATEGORY");
        break;

      case "progress":
        setProgressId("0");
        break;
      case "stage":
        setStageId("0");
        break;
      case "project":
        setProjectId("0");
        break;
      case "company":
        setCompanyId("0");
        break;
      case "language":
        setLanguageId("0");
        break;
      case "roleType":
        setRoleTypeId("0");
        break;
      case "cvSource":
        setcvSourceId("0");
        break;
      case "status":
        setCaseStatusId("0");
        setCaseStatusFilterText("PAY STATUS");
        break;
      case "payType":
        setCasePayTypeId("0");
        setPaymentTypeFilterText("PAY TYPE");
        break;
      case "caseStatus":
        setCaseStatusId("0");
        setCaseStatusFilterDDLText("Case Status");
        break;
      case "month":
        setMonthsFilterId("0");
        setMonthsFilterText("Month");
        setMonthsId("0");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (dataMoveTo !== "") {
      (async () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
        } else {
          var canIds = [];
          $.each($(".chkCandidate:checked"), function () {
            canIds.push($(this).val());
          });
          await dispatch(
            moveRecordsToPaid({ id: dataRowId, status: "Fully Paid" })
          );
          $(".chkCandidate").prop("checked", false);
          setIsActive(false);
        }
      })();
    }
  }, [dataMoveTo]);

  const [isShowUpdate, setShowUpdate] = useState(false);
  const showUpdate = () => {
    setShowUpdate(true);
  };

  const SearchUpdate = () => {
    setShowUpdate(false);
  };

  const [columnKey, setColumnKey] = useState("");
  const [columnValue, setColumnValue] = useState("0");
  const [isUpdatePop, setIsUpdatePop] = useState(false);
  /******comment popup start**** */
  const [refTable, setRefTable] = useState("accounting_invoice_books");
  const [refId, setRefId] = useState(0);
  const [commentsCount, setCommentCount] = useState(0);
  const [title, setTitle] = useState("Accounting Invoice Books Comments");
  const [newStatus, setNewStatus] = useState("");
  const [newProgess, setNewProgess] = useState("");
  const [newStage, setNewStage] = useState("");
  const [candidateStatus, setCandidteStatus] = useState("");
  const [candidateProgess, setCandidateProgess] = useState("");
  const [candidateStage, setCandidateStage] = useState("");
  const [commentPopupShow, setCommentPopupShow] = useState(false);
  /******comment popup end**** */

  const openUpdateValuePop = (id, key, value) => {
    setRowId(id);
    setColumnKey(key);
    setColumnValue(value);
    setShowUpdate(true);
  };
  const hideUpdateValuePop = () => {
    setShowUpdate(false);
  };

  const commentHandleClose = () => {
    // setNewStatus("");
    // setNewProgess("");
    // setNewStage("");
    setCommentPopupShow(false);
  };
  return (
    <>
      {commentPopupShow ? (
        <CommentModal
          refTable={refTable}
          refId={refId}
          count={commentsCount}
          title={title}
          commentHandleClose={commentHandleClose}
          candidateStatus={newStatus != "" ? newStatus : candidateStatus}
          candidateProgess={newProgess != "" ? newProgess : candidateProgess}
          candidateStage={newStage != "" ? newStage : candidateStage}
        />
      ) : null}
      {isShowSearch ? (
        <AdvancedFilter
          onHide={SearchClose}
          headerTitle="Search Invoice Books"
          list={list}
          selectOtherFilter={selectOtherFilter}
        />
      ) : null}

      {isShowUpdate ? (
        <UpdateModal
          onHide={hideUpdateValuePop}
          rowId={rowId}
          columnKey={columnKey}
          value={columnValue}
        />
      ) : null}

      {isShowAddNew ? (
        <AddNew onHide={SearchAddNew} currentYear={atob(lastSegment)} />
      ) : null}
      {showEdit ? (
        <EditDetails onHide={closeEditModal} currentYear={atob(lastSegment)} />
      ) : null}

      <div className="dk-bOverviewyears-containerMain">
        <div className="dk-bOverviewyears-rightPanel">
          <div className="dk-sendoutsHead">
            <div className="dk-sendoutsyears-yearsDropdown">
              <div className="dk-searchBox">
                {instanceData !== "0" ? (
                  <GlobalFilter
                    preGlobalFilteredRows={instanceData.preGlobalFilteredRows}
                    globalFilter={instanceData.state.globalFilter}
                    setGlobalFilter={instanceData.setGlobalFilter}
                    isClearAll={isClearAll}
                    restClearAllFilter={restClearAllFilter}
                  />
                ) : null}
              </div>

              <Link to="#" className="addMonthBtn" onClick={showAddNew}>
                <i className="fas fa-plus"></i>
              </Link>
              <div className="dk-reloadPage" onClick={clearAllFilter}>
                <button className="addMonthBtn" onClick={clearAllFilter}>
                  <FaSync />
                </button>
              </div>
            </div>

            <div className="dk-recwallet-centerCon">
              <div className="dk-filterTagsCon">
                {filterTags.length > 0 ? (
                  filterTags.length > 0 &&
                  filterTags.map((item, index) => (
                    <ButtonGroup
                      className="dk-filterbtnGrp mr-2"
                      key={`tagButton${index}`}
                    >
                      <Button className="dk-filterbtntext">{item.value}</Button>
                      <Button
                        className="dk-filterbtnicon"
                        data-type={item.type}
                        onClick={removeTag}
                      >
                        <i
                          className="fas fa-times"
                          data-type={item.type}
                          onClick={removeTag}
                        ></i>
                      </Button>
                    </ButtonGroup>
                  ))
                ) : (
                  <div className="dkg-pagetitle">
                    INVOICE BOOK {atob(lastSegment)}
                  </div>
                )}
              </div>
            </div>
            <div className="dk-sendoutyears-rightPanel-btn">
              <div className="dk-sensdouts-searchCols">
                <button onClick={showSearch}>
                  <FaFilter />
                </button>
              </div>

              <div className="pipeline-cafileterCon">
                <CaseStatusFilter
                  list={list}
                  caseStatusFilterDDLText={caseStatusFilterDDLText}
                  selectOtherFilter={selectOtherFilter}
                />
                {caseStatusFilterDDLText !== "Case Status" ? (
                  <i
                    className="fas fa-times-circle dk-lrftCross"
                    data-type="caseStatus"
                    onClick={removeTag}
                  ></i>
                ) : null}
              </div>

              <MonthFilterDropdown
                list={list}
                caseStatusFilterText={monthsFilterText}
                onSelectStatusFilter={onSelectMonthFilter}
                tableType={"accounting_invoice_books"}
              />
              {monthsFilterText !== "Month" ? (
                <i
                  className="fas fa-times-circle dk-lrftCross"
                  data-type="month"
                  onClick={removeTag}
                ></i>
              ) : null}
            </div>
          </div>
          <div className="dk-bOverviewyears-table">
            <Table
              data={data}
              columns={columns}
              getInstanceCallback={reactTableInstance}
              loading={isLoading}
              updateMyData={updateMyData}
              caseTypeId={caseTypeId}
              caseStatusId={caseStatusId}
              casePayTypeId={casePayTypeId}
              monthsFilterId={monthsFilterId}
              monthsId={monthsId}
              progressId={progressId}
              stageId={stageId}
              companyId={companyId}
              projectId={projectId}
              languageId={languageId}
              roleTypeId={roleTypeId}
              cvSourceId={cvSourceId}
            />
          </div>
        </div>
      </div>
      <div
        className={`batch-actions-menu-wrapper dk-action-popupMain ${
          isActive ? "activePopup" : ""
        }`}
        style={{ width: "auto" }}
      >
        <div className="num-of-actions_wrapper">
          <div className="num-of-actions">{countCheck}</div>
        </div>
        <div className="batch-actions-title-section pl-0">
          <div className="dk-rowSelected">
            <span>Row Selected</span>
          </div>
        </div>

        <div className="batch-actions-item dk-checkAll" onClick={editFunction}>
          <span onClick={editFunction}>
            <i className="far fa-edit"></i>
          </span>
          Edit
        </div>

        <DeleteModal
          loading={loading}
          success={success}
          deleteItem={deleteItemBulk}
          tableName="accounting_invoice_books"
        />
        <div
          className="batch-actions-delete-item CloseAll d-flex align-items-center justify-content-center"
          onClick={handleToggle}
        >
          <span>
            <i className="fas fa-times"></i>
          </span>
        </div>
      </div>
    </>
  );
};

export default InvoiceTwentyTwo;
