import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { FaArrowsAlt } from "react-icons/fa";

import Submit from '../../../../../../ui/submitButton'



const ReorderModal = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <React.Fragment>
            {/* <Link to="#" onClick={handleShow} className="dk-campgiansIcon mr-2" ><i className="fas fa-arrows-alt"></i></Link> */}
            <Link to="#" onClick={handleShow} className="dkg-addnew-btn mr-2"><FaArrowsAlt /></Link>
            <Modal className="dkg-campagins-reorderModal" show={show} onHide={handleClose} centered>
                <Modal.Header className="dkg-campagins-reorderModalheader" closeButton >
                    <Modal.Title>Reorder</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-campagins-reorderModalBody">
                    <div className="Mukul">
                        <div className="dk-valueFields d-flex">
                            <span className="dk-handle" style={{ backgroundColor: "#363f4c", height: "35px !important", width: "50px", textAlign: "center", lineHeight: "35px", color: "white", cursor: "all-scroll" }}><i className="fas fa-bars"></i></span>
                            <input type="text" style={{ height: "35px", borderRadius: "0", border: "1px solid #ddd", boxShadow: "none !important" }} value="Readme.Txt" name="" id="" className="form-control" readOnly />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center dk-res-reorder-save-btnCon mt-4">
                        <Submit txt="Save Order" position="justify-content-center" className="dk-toolsPanelSaveBtn" />
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default ReorderModal;