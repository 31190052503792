import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";

const KpiLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/training/common-training/kpi/overview" className={`${pathname.match('/training/common-training/kpi/overview') ? 'active' : ''}`}>Overview</Link></li>
                    <li><Link to="#" className={`${pathname.match('#') ? 'active' : ''}`}>Sample 1</Link></li>
                    <li><Link to="#" className={`${pathname.match('#') ? 'active' : ''}`}>Sample 2</Link></li>
                    <li><Link to="#" className={`${pathname.match('#') ? 'active' : ''}`}>Sample 3</Link></li>
                    <li><Link to="#" className={`${pathname.match('#') ? 'active' : ''}`}>Sample 4</Link></li>
                    <li><Link to="#" className={`${pathname.match('#') ? 'active' : ''}`}>Sample 5</Link></li>
                </Route>
            </ul>
        </React.Fragment>
    )
}

export default KpiLeftPanel;

