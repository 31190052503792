import React, { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import "./myacc-faq.scss";
import FaqAccordion from "./Accordion";
import { useDispatch, useSelector } from "react-redux";
import {
  getCandidateFaqCategory,
  getdefaultQuesAns,
  getQueAns,
} from "../../../../../src/slice/faqs/candidateFaqSlice";

const Compliance = () => {
  const dispatch = useDispatch();
  const { isLoading, CandidateFaqCategoryList, CandidateFaqQuesAnsList } =
    useSelector((state) => state.candidatefaqData);

  const [activeLink, setActiveLink] = useState(null);
  const handleClick = async (id) => {
    //console.log(id);
    dispatch(getQueAns({ id }));
    setActiveLink(id);
  };

  useEffect(() => {
    dispatch(getCandidateFaqCategory({}));
    dispatch(getdefaultQuesAns({}));
  }, []);

  useEffect(() => {
    if (
      !isLoading &&
      CandidateFaqQuesAnsList &&
      CandidateFaqCategoryList &&
      CandidateFaqCategoryList.length > 0
    ) {
      setActiveLink(CandidateFaqCategoryList[0].id);
    }
  }, [isLoading]);

  return (
    <>
      <div className="dkg-myacc-faqmain-Con container-fluid">
        <div className="row dkg-faq-mainCon">
          <div className="col-md-12 dkg-faq-boxCon">
            <div className="dkg-myaccount-faq-pageCon">
              <Tab.Container defaultActiveKey="question-pt-overview">
                <div className="row dkg-myaccount-faq-tab-mainCon">
                  <div className="col-md-2 pr-2 dkg-myaccount-faq-tabCon-10">
                    <Nav
                      variant="pills"
                      className="flex-column dkg-myaccount-faq-tab-navpills"
                    >
                      {CandidateFaqCategoryList &&
                        CandidateFaqCategoryList.filter(
                          (item) => item.status === "Active"
                        ).map((link, index) => {
                          return (
                            <Nav.Item className="dkg-myaccount-faq-tab-nav-item">
                              {
                                <Nav.Link
                                  to="#"
                                  key={link.id}
                                  data-id={link.id}
                                  onClick={() => handleClick(link.id)}
                                  className={
                                    activeLink === link.id
                                      ? "dkg-myaccount-faq-tab-navlink active"
                                      : "dkg-myaccount-faq-tab-navlink "
                                  }
                                >
                                  {link.name}
                                </Nav.Link>
                              }
                            </Nav.Item>
                          );
                        })}
                    </Nav>
                  </div>
                  <div className="col-md-10 dkg-myaccount-faq-tabCon-10 pl-5">
                    {CandidateFaqQuesAnsList.length > 0 ? (
                      <>
                        <Tab.Content className="dkg-myaccount-faq-tabcontent">
                          <FaqAccordion quesAnswer={CandidateFaqQuesAnsList} />
                        </Tab.Content>
                      </>
                    ) : null}
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Compliance;
