import React, { useState } from "react";
import { Link } from "react-router-dom";
import DetailLeftPanel from "./DetailLeftPanel";
import MoreDetailModal from "./MoreDetailModal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TItleIcon from "../../../../assets/images/summary-black.png";
import "../trainingdetail.scss";

const RecruitersCreateJobSpec = () => {
  const [isShowMore, setIsShowMore] = useState(false);
  // const showmoreDetails = () => {
  //     console.log("Show")
  //     setIsShowMore(true);
  // }
  const hidemoreDetails = () => {
    setIsShowMore(false);
  };

  return (
    <React.Fragment>
      {isShowMore ? <MoreDetailModal handleClose={hidemoreDetails} /> : null}

      <div className="dk-backBtnMain">
        <Link to="/training/recruiters">
          <i className="fas fa-arrow-left"></i> Go Back
        </Link>
        <h3 className="dk-detailTitle">
          <img src={TItleIcon} className="img-fluid" alt="" />
          Create Job Spec
        </h3>
      </div>

      <div className="dk-trainingDetailMain">
        <div className="dk-detailsMain">
          <div className="d-md-flex">
            <div className="dk-detailsLeftPanel">
              <DetailLeftPanel />
            </div>
            <div className="dk-detailRightPanel">
              <div className="dk-detailTabsMain dk-detailTabsMainOverviewMain">
                <h3 className="title">Overview</h3>
                <Tabs defaultActiveKey="tabs_1">
                  <Tab eventKey="tabs_1" title="Details">
                    <div className="dk-trainingInformation-cols">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Aliquid similique dolore laboriosam reprehenderit
                        sapiente at iusto? Soluta tempora eveniet suscipit quae
                        aliquid provident rem exercitationem ipsa eos
                        aspernatur? Necessitatibus, optio?
                      </p>
                    </div>
                  </Tab>
                  <Tab eventKey="tabs_2" title="Samples">
                    <div className="dk-trainingInformation-cols">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Aliquid similique dolore laboriosam reprehenderit
                        sapiente at iusto? Soluta tempora eveniet suscipit quae
                        aliquid provident rem exercitationem ipsa eos
                        aspernatur? Necessitatibus, optio?
                      </p>
                    </div>
                  </Tab>
                  <Tab eventKey="tabs_3" title="Video">
                    <div className="dk-trainingInformation-cols">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Aliquid similique dolore laboriosam reprehenderit
                        sapiente at iusto? Soluta tempora eveniet suscipit quae
                        aliquid provident rem exercitationem ipsa eos
                        aspernatur? Necessitatibus, optio?
                      </p>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RecruitersCreateJobSpec;
