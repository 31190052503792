import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./myacc-navbar.scss";
import { FaCaretDown } from "react-icons/fa";
import Submenu from "./submenu/MyaccSubmenu";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { jobList } from "../../../../../slice/jobs/jobsSlice";

const MyAccountNav = () => {
  const dispatch = useDispatch();
  const { isLoading, jobseDataList } = useSelector((state) => state.jobs);
  const [data, setData] = useState([]);

  const pathname = window.location.pathname;

  const { userInfo } = useSelector((state) => state.auth);
  const { internalMailsCount } = useSelector((state) => state.mailbox);
  const segment2 = pathname.split("/")[2];
  // const segment1 = pathname.split("/")[1];
  // const segment3 = pathname.split("/")[3];
  //console.log(userInfo);

  useEffect(() => {
    document.title =
      pathname.split("/")["1"].charAt(0).toUpperCase() +
      pathname.split("/")["1"].slice(1) +
      " | DK MasterBox";

    dispatch(jobList({ isDelete: 1 }));
  }, [pathname]);

  useEffect(() => {
    if (!isLoading && jobseDataList.length > 0) {
      setTimeout(() => {
        setData(jobseDataList);
      }, 200);
    }
  }, [isLoading]);

  return (
    <>
      {/* <div className='dkg-myacc-navbar'>
        <div className="Lsidebar">
            <div className="d-flex dflex2">
                <div className="profile-info order-md-first">
                    <Link to="/elite/view-profile" className="">
                        <figure>
                           <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + userInfo.profileImage} className="img-fluid img-circle" alt="" />
                        </figure>
                    </Link>
                </div>
                <button type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation" className="navbar-toggler order-last collapsed">
                    <i className="fa fa-bars"></i>
                </button>
                <div id="navbar" className="dkg-mynavbar-tabNav navbar-collapse collapse">
                    <ul className="dkg-mynavbar-tabNav-ul">
                        <li className="dkg-myacc-nav-main-li">
                            <Link to="/js-account/dashboard" className={`${(segment2 === 'dashboard') ? 'grey-active' : ''}`}>Dashboard</Link>
                        </li>
                        <li className="dkg-myacc-nav-main-li">
                            <Link to="/js-account/mailbox" className={`${(segment2 === 'mailbox') ? 'grey-active' : ''}`}>Mailbox <span className="dkg-taskbadgecon">5</span></Link>
                        </li>
                        <li className="dkg-myacc-nav-main-li">
                        <Link to="/js-account/job-profile" className={`${(segment2 === 'job-profile') ? 'grey-active' : ''}`}>Live Jobs <span className="dkg-taskbadgecon">5</span></Link>
                        </li>
                        <li className="dkg-myacc-nav-main-li">
                            <Link to="/js-account/kpi" className={`${(segment2 === 'kpi') ? 'grey-active' : ''}`}>Applied <span className="dkg-taskbadgecon">5</span></Link>
                        </li>
                        <li className="dkg-myacc-nav-main-li">
                            <Link to="/js-account/interviews" className={`${(segment2 === 'interviews') ? 'grey-active' : ''}`}>Interviews <span className="dkg-taskbadgecon">5</span></Link>
                        </li>
                        <li className="dkg-myacc-nav-main-li">
                            <Link to="/js-account/job-offers" className={`${(segment2 === 'job-offers') ? 'grey-active' : ''}`}>Job Offers <span className="dkg-taskbadgecon">5</span></Link>
                        </li>
                        <li className="dkg-myacc-nav-main-li">
                            <Link to="/js-account/offers" className={`${(segment2 === 'offers') ? 'grey-active' : ''}`}>Promotions</Link>
                        </li>
                        <li className="dkg-myacc-nav-main-li dkg-services-li">
                            <Link to="/js-account/dkg-services/summary" className={`${(segment2 === 'dkg-services') ? 'grey-active' : ''}`}>Buy Service <span className="dkg-dropdown-toggle ml-2"><FaCaretDown /></span></Link>
                            <Submenu path={pathname} />
                        </li>
                        <li className="dkg-myacc-nav-main-li dkg-profile-li">
                            <Link to="/js-account/my-profile/summary" className={`${(segment2 === 'my-profile') ? 'grey-active' : ''}`}>My Profile<span className="dkg-dropdown-toggle ml-2"><FaCaretDown /></span></Link>
                            <Submenu path={pathname} />

                        </li>
                        <li className="dkg-myacc-nav-main-li dkg-setting-li">
                            <Link to="/js-account/settings/faq" className={`${(segment2 === 'settings') ? 'grey-active' : ''}`}>Setting<span className="dkg-dropdown-toggle ml-2"><FaCaretDown /></span></Link>
                            <Submenu path={pathname} />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
      </div> */}
      <Navbar collapseOnSelect expand="lg" className="dkg-myacc-navbar">
        <div className="Lsidebar">
          <div className="d-flex dflex2">
            <div className="profile-info order-md-first">
              <Navbar.Brand href="/js-account/dashboard">
                <figure>
                  <img
                    src={
                      userInfo
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          userInfo.profileImage
                        : ""
                    }
                    className="img-fluid img-circle"
                    alt=""
                  />
                </figure>
              </Navbar.Brand>
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="dkg-mynavbar-tabNav"
            >
              <ul className="dkg-mynavbar-tabNav-ul">
                <li className="dkg-myacc-nav-main-li">
                  <Link
                    to="/js-account/dashboard"
                    className={`${
                      segment2 === "dashboard" ? "grey-active" : ""
                    }`}
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="dkg-myacc-nav-main-li">
                  <Link
                    to="/js-account/mailbox"
                    className={`${segment2 === "mailbox" ? "grey-active" : ""}`}
                  >
                    Mailbox{" "}
                    {internalMailsCount?.inbox > 0 ? (
                      <span className="dkg-taskbadgecon">
                        {" "}
                        {internalMailsCount?.inbox}{" "}
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>
                <li className="dkg-myacc-nav-main-li">
                  <Link
                    to="/js-account/job-profile"
                    className={`${
                      segment2 === "job-profile" ? "grey-active" : ""
                    }`}
                  >
                    Open Jobs
                    {data.length > 0 ? (
                      <span className="dkg-taskbadgecon">{data.length}</span>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>
                <li className="dkg-myacc-nav-main-li">
                  <Link
                    to="/js-account/kpi"
                    className={`${segment2 === "kpi" ? "grey-active" : ""}`}
                  >
                    Applied
                    {/* <span className="dkg-taskbadgecon">0</span> */}
                  </Link>
                </li>
                <li className="dkg-myacc-nav-main-li">
                  <Link
                    to="/js-account/interviews"
                    className={`${
                      segment2 === "interviews" ? "grey-active" : ""
                    }`}
                  >
                    Interviews
                    {/* <span className="dkg-taskbadgecon">0</span> */}
                  </Link>
                </li>
                <li className="dkg-myacc-nav-main-li">
                  <Link
                    to="/js-account/job-offers"
                    className={`${
                      segment2 === "job-offers" ? "grey-active" : ""
                    }`}
                  >
                    Offered
                    {/* <span className="dkg-taskbadgecon">0</span> */}
                  </Link>
                </li>
                <li className="dkg-myacc-nav-main-li">
                  <Link
                    to="/js-account/offers"
                    className={`${segment2 === "offers" ? "grey-active" : ""}`}
                  >
                    Referrals
                    {/* <span className="dkg-taskbadgecon">0</span> */}
                  </Link>
                </li>
                <li className="dkg-myacc-nav-main-li dkg-services-li">
                  <Link
                    to="/js-account/dkg-services/summary"
                    className={`${
                      segment2 === "dkg-services" ? "white-active" : ""
                    }`}
                  >
                    Paid Services
                    {/* <span className="dkg-taskbadgecon">12</span> */}
                    <span className="dkg-dropdown-toggle ml-2">
                      <FaCaretDown />
                    </span>
                  </Link>
                  <Submenu path={pathname} />
                </li>
                <li className="dkg-myacc-nav-main-li dkg-profile-li">
                  <Link
                    to="/js-account/my-profile/summary"
                    className={`${
                      segment2 === "my-profile" ? "white-active" : ""
                    }`}
                  >
                    My Profile
                    <span className="dkg-dropdown-toggle ml-2">
                      <FaCaretDown />
                    </span>
                  </Link>
                  <Submenu path={pathname} />
                </li>
                <li className="dkg-myacc-nav-main-li dkg-setting-li">
                  <Link
                    to="/js-account/settings/faq"
                    className={`${
                      segment2 === "settings" ? "white-active" : ""
                    }`}
                  >
                    Setting
                    <span className="dkg-dropdown-toggle ml-2">
                      <FaCaretDown />
                    </span>
                  </Link>
                  <Submenu path={pathname} />
                </li>
              </ul>
            </Navbar.Collapse>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default MyAccountNav;
