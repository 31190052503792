import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import "./responsiveMenu.scss";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const RecTrainingSubmenu = (props) => {
    const classes = useStyles();
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    const segment2 = pathname.split("/")[2];

    const [toggleMenu, setToggleMenu] = useState(false);
    useEffect(() => {
        setToggleMenu(false)
    }, [pathname])

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setToggleMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);


    return (
        <React.Fragment>
            <div className="dk-subMenuMain res-sub-menu" ref={ref}>
                <IconButton aria-label="delete" className="res-sub-btn" onClick={() => setToggleMenu(!toggleMenu)}>
                    <i className="fas fa-bars"></i>
                </IconButton>
                <Route>
                    <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
                        <li><Link to="/recruitment/rectraining/overview" className={`${(segment3 == 'overview') ? 'active' : ''}`}>Overview</Link></li>
                        <li><Link to="/recruitment/rectraining/dashboard" className={`${(segment3 == 'dashboard') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="/recruitment/rectraining/work-plan" className={(segment3 == 'work-plan') ? 'active' : ''}>Workplan</Link></li>
                        <li><Link to="/recruitment/rectraining/training-notifications" className={`${(segment3 == 'training-notifications') ? 'active' : ''}`}>Notification</Link></li>
                        <li><Link to="/recruitment/rectraining/candidate" className={`${(segment3 == 'candidate') ? 'active' : ''}`}>Candidate</Link></li>
                        <li><Link to="/recruitment/rectraining/training-jobs" className={`${(segment3 == 'training-jobs') ? 'active' : ''}`}>Jobs</Link></li>
                        <li><Link to="/recruitment/rectraining/company" className={`${(segment3 == 'company') ? 'active' : ''}`}>Company</Link></li>
                        <li><Link to="/recruitment/rectraining/kpi" className={`${(segment3 == 'kpi') ? 'active' : ''}`}>KPI</Link></li>
                        <li><Link to="/recruitment/rectraining/dkg-cloud" className={`${(segment3 == 'dkg-cloud') ? 'active' : ''}`}>DKG Cloud</Link></li>
                        <li><Link to="/recruitment/rectraining/training-database" className={`${(segment3 == 'training-database') ? 'active' : ''}`}>Database</Link></li>
                        <li><Link to="/recruitment/rectraining/training-dkglobal" className={`${(segment3 == 'training-dkglobal') ? 'active' : ''}`}>DK Global</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default RecTrainingSubmenu;
