import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import Submenu from "../../subMenu/BusinessBookSubmenu";


const BusinessOverviewMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    const segment4 = pathname.split("/")[4];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalfinanceBusinessOverviewMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/accounting/businessoverview/dashboard" className={(segment3 == 'dashboard') ? 'active' : ''}>Dashboard</Link></li>
                        <li><Link to={`/accounting/businessoverview/qualified/2023/` + btoa(2023)} className={(segment4 == '2023') ? 'gray-active' : ''}>2023</Link></li>
                        <li><Link to={`/accounting/businessoverview/qualified/2022/` + btoa(2022)} className={(segment4 == '2022') ? 'gray-active' : ''}>2022</Link></li>
                        <li><Link to={`/accounting/businessoverview/qualified/2021/` + btoa(2021)} className={(segment4 == '2021') ? 'gray-active' : ''}>2021</Link></li>
                        <li><Link to={`/accounting/businessoverview/qualified/2020/` + btoa(2020)} className={(segment4 == '2020') ? 'gray-active' : ''}>2020</Link></li>
                        <li><Link to={`/accounting/businessoverview/qualified/2019/` + btoa(2019)} className={(segment4 == '2019') ? 'gray-active' : ''}>2019</Link></li>
                        <li><Link to={`/accounting/businessoverview/qualified/2018/` + btoa(2018)} className={(segment4 == '2018') ? 'gray-active' : ''}>2018</Link></li>
                        <li><Link to={`/accounting/businessoverview/qualified/2017/` + btoa(2017)} className={(segment4 == '2017') ? 'gray-active' : ''}>2017</Link></li>
                        <li><Link to={`/accounting/businessoverview/qualified/2016/` + btoa(2016)} className={(segment4 == '2016') ? 'gray-active' : ''}>2016</Link></li>

                        {/* <li><Link to="/accounting/businessoverview/summary" className={(segment3 == 'summary') ? 'active' : ''}>Summary</Link></li> */}

                    </ul>
                </Route>
            </div>
            <Submenu path={pathname} />

        </React.Fragment>
    );
}

export default BusinessOverviewMenu;