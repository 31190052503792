import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputGroup, FormControl } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import SimpleDropDwn from "../summary/SimpleDropDwn";
import UserSearchFilter from "../summary/SearchInputDropDwn";
import AirDatepicker from "air-datepicker";
import en from "air-datepicker/locale/en";
import "air-datepicker/air-datepicker.css";
import UserList from "../../../../../ui/userList/userListByRole";

import {
  clearState,
  updateJob,
  updateJobWithTextBox,
} from "../../../../../../slice/jobs/jobsSlice";

const Relocation = (jobId) => {
  const dispatch = useDispatch();
  const {
    jobsCategoryList,
    jobsCompanyList,
    jobsProjectList,
    jobsRoleTypeList,
    jobsLanguageList,
    jobsLocalityList,
    jobsNoofJobsList,
    jobsCountryList,
    jobsCityList,
    jobsJobProfileList,
    jobsWorkPermitList,
    jobsWorkplaceList,
    jobsWorkdaysList,
    jobsShiftsWorkList,
    jobsContracttypeList,
    jobsSalarytypeList,
    jobsAvailabilityList,
    jobsOutsideofeuList,
    jobsFlightticketList,
    jobsTaxifromairportList,
    jobsAccommodationtList,
    jobsRelocationbudgetList,
    jobsExperiencetList,
  } = useSelector((state) => state.common);

  const { isDetailsLoading, jobDetails, isUpdate } = useSelector(
    (state) => state.jobs
  );
  const [status, setStatus] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setData(jobDetails);
      setStatus(jobDetails.status);
    }
  }, [isDetailsLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobDetails);
    }
  }, [isUpdate]);
  let ids = data.id;

  const JobStatus = (status) => {
    let bgColor = "";
    let txtColor = "";
    switch (status) {
      case "Normal":
        bgColor = "#30887e";
        txtColor = "#fff";
        break;

      case "Urgent":
        bgColor = "#dc5539";
        txtColor = "#fff";
        break;

      case "To qualify":
        bgColor = "#ffd966";
        txtColor = "#000";
        break;

      case "On Hold":
        bgColor = "#7f5347";
        txtColor = "#fff";
        break;

      case "Closed":
        bgColor = "#e2445c";
        txtColor = "#fff";
        break;
    }

    return (
      <>
        <Dropdown.Toggle
          variant=""
          className="dk-statusDrpDwnToggle"
          style={{ backgroundColor: bgColor, color: txtColor }}
        >
          {" "}
          {status}
        </Dropdown.Toggle>
      </>
    );
  };

  const updateMyData = (e) => {
    let values = "";
    let columnId = e.target.dataset.columnid;
    if (columnId === "gross_title") {
      values = e.target.dataset.value;
    } else {
      values = e.target.value;
    }
    dispatch(updateJob({ id: ids, key: columnId, value: values }));
  };
  const updateStatus = (values) => {
    setStatus(values);
    dispatch(updateJob({ id: ids, key: "status", value: values }));
  };
  new AirDatepicker("#date1", {
    position: "bottom center",
    locale: en,
    dateFormat: "d MMM yy",
    onSelect({ date, formattedDate, datepicker }) {
      dispatch(updateJob({ id: ids, key: "date1", value: formattedDate }));
    },
  });

  new AirDatepicker("#date2", {
    position: "bottom center",
    locale: en,
    dateFormat: "d MMM yy",
    onSelect({ date, formattedDate, datepicker }) {
      dispatch(updateJob({ id: ids, key: "date2", value: formattedDate }));
    },
  });

  let gross_value = "";
  if (data.gross_title === null) {
    gross_value = "Monthly Gross";
  } else {
    if (data.gross_title === "Monthly Gross") {
      gross_value = "Annual Gross";
    } else {
      gross_value = "Monthly Gross";
    }
  }
  const onItemClick = (id, columnId, value) => {
    dispatch(updateJob({ id, key: columnId, value }));
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="col-md-12 dk-jobsuMMBottomSec pl-0">
            <div className=" row">
              <div className="col-md-12 col-sm-12 dk-jobsuMMBottomBoxBg pr-0">
                <div className="dk-boxBody mt-3 row m-0 border-0">
                  <div className="col-md-6 pl-0">
                    <div className="dk-suMMformGroupCon mb-2">
                      <label
                        style={{ background: "none" }}
                        className="relocationlabel"
                      >
                        Relocation Pack
                      </label>
                      <SimpleDropDwn
                        categoryList={jobsAvailabilityList}
                        text={data.availability}
                        columnId="availability"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label
                        style={{ background: "none" }}
                        className="relocationlabel"
                      >
                        Outside of EU
                      </label>
                      <SimpleDropDwn
                        categoryList={jobsOutsideofeuList}
                        text={data.outside_of_eu}
                        columnId="outside_of_eu"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label
                        style={{ background: "none" }}
                        className="relocationlabel"
                      >
                        Flight Ticket
                      </label>
                      <SimpleDropDwn
                        categoryList={jobsFlightticketList}
                        text={data.flight_ticket}
                        columnId="flight_ticket"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label
                        style={{ background: "none" }}
                        className="relocationlabel"
                      >
                        Taxi from Airport
                      </label>
                      <SimpleDropDwn
                        categoryList={jobsTaxifromairportList}
                        text={data.taxi_from_airport}
                        columnId="taxi_from_airport"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label
                        style={{ background: "none" }}
                        className="relocationlabel"
                      >
                        Accommodation
                      </label>
                      <SimpleDropDwn
                        categoryList={jobsAccommodationtList}
                        text={data.accommodation}
                        columnId="accommodation"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label
                        style={{ background: "none" }}
                        className="relocationlabel"
                      >
                        Client Fixed Budget
                      </label>
                      <SimpleDropDwn
                        categoryList={jobsRelocationbudgetList}
                        text={data.relocation_budget}
                        columnId="relocation_budget"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label
                        style={{ background: "none" }}
                        className="relocationlabel"
                      >
                        Simple Dummy 1
                      </label>
                      <SimpleDropDwn
                        categoryList={jobsRelocationbudgetList}
                        text={data.relocation_budget}
                        columnId="relocation_budget"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon">
                      <label
                        style={{ background: "none" }}
                        className="relocationlabel"
                      >
                       Simple Dummy 1
                      </label>
                      <SimpleDropDwn
                        categoryList={jobsRelocationbudgetList}
                        text={data.relocation_budget}
                        columnId="relocation_budget"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Relocation;
