import React from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from "react-redux";
const UserList = ({ rowid = 0, onClick }) => {
    const { usersList } = useSelector(state => state.users)
    //const removeValFromIndex = [1,2,3,4,6,7,8,9,10]; //not to see users in payment task, remove user from usersList
    //const indexSet = new Set(removeValFromIndex);
    //const newUsers = usersList.filter((value, i) => !indexSet.has(i));
   
    return (
        <React.Fragment>
            {usersList.map((list, index) => (
                <Dropdown.Item href="#">
                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + list.profileImage} className="img-fluid" alt={list.profileImage} data-id={list.id} data-rowid={rowid} onClick={onClick} />
                </Dropdown.Item>
            ))}
        </React.Fragment>
    )
}
export default UserList;