import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './aadnew-notifications.scss';
import { Dropdown,Modal } from 'react-bootstrap';
import UserImg from "../../../../../assets/images/deepak.png";
import { FaPlus } from 'react-icons/fa';
import SubmitButton from '../../../../ui/submitButton'
import TimePicker from "./datepicker/TimePicker";
import DatePicker from "./datepicker/DatePicker";



const AddNewNotification = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Link to="#" onClick={handleShow} className="dk-unreadEmails" title="Add New Notification"><FaPlus /> </Link>
            <Modal show={show} onHide={handleClose} className="dk-add-new-notificationsnewModal" centered>
                <Modal.Header closeButton className='dk-add-new-notificationsnewModalHeader' style={{ background: '#396a95' }}>
                    <Modal.Title>ADD NEW NOTIFICATION</Modal.Title>
                </Modal.Header>
                <Modal.Body className='dk-add-new-notificationsnewModalBody'>
                    <div className='dk-warningMain'>
                        <form action="">
                            <div className='row'>
                                <div className='col-12 col-md-12'>
                                    <div className="form-group">
                                        <label className="dkg-noti-label">Created By</label>
                                        <div className="dk-recuiter-drop-con12">
                                            <Dropdown className="dk-recuiter-dropdown">
                                                <Dropdown.Toggle variant="" className="dk-recuiter-dropdown-toggle">
                                                    <img src={UserImg} className="dkg-noti-recuiter-img" alt="Recruiter" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dk-noti-recuiter-dropdown-menu">
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src={UserImg} alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src={UserImg} alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src={UserImg} alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src={UserImg} alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="dkg-noti-label">Created Date</label>

                                        <div className='dkg-addnew-date-timeCon'>
                                            <DatePicker />
                                        </div>
                                    </div>
                                    {/* <div className="form-group">
                                        <label className="dkg-noti-label">Created Time</label>
                                        <div className='dkg-addnew-date-timeCon'>
                                            <TimePicker />
                                        </div>
                                    </div> */}
                                    <div className="form-group">
                                        <label className="dkg-noti-label">Select Category</label>
                                        <div className='dkg-notification-task-drop12'>
                                            <Dropdown className='dkg-notification-task-dropdown'>
                                                <Dropdown.Toggle variant="" className='dkg-noti-task-dropdown-toggle'>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='dkg-noti-task-dropdown-menu'>
                                                    <Dropdown.Item className='dkg-noti-task-dropdown-item'>Category 1</Dropdown.Item>
                                                    <Dropdown.Item className='dkg-noti-task-dropdown-item'>Category 2</Dropdown.Item>
                                                    <Dropdown.Item className='dkg-noti-task-dropdown-item'>Category 3</Dropdown.Item>
                                                    <Dropdown.Item className='dkg-noti-task-dropdown-item'>Category 4</Dropdown.Item>
                                                    <Dropdown.Item className='dkg-noti-task-dropdown-item'>Category 5</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="dkg-noti-label">Select Task Type</label>
                                        <div className='dkg-notification-task-drop12'>
                                            <Dropdown className='dkg-notification-task-dropdown'>
                                                <Dropdown.Toggle variant="" className='dkg-noti-task-dropdown-toggle'>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='dkg-noti-task-dropdown-menu'>
                                                    <Dropdown.Item className='dkg-noti-task-dropdown-item'>Task Type 1</Dropdown.Item>
                                                    <Dropdown.Item className='dkg-noti-task-dropdown-item'>Task Type 2</Dropdown.Item>
                                                    <Dropdown.Item className='dkg-noti-task-dropdown-item'>Task Type 3</Dropdown.Item>
                                                    <Dropdown.Item className='dkg-noti-task-dropdown-item'>Task Type 4</Dropdown.Item>
                                                    <Dropdown.Item className='dkg-noti-task-dropdown-item'>Task Type 5</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" placeholder="Write subject here..." name="" id="" className="form-control dkg-sub-form-control" />
                                    </div>
                                    <div className="form-group mt-0">
                                        <textarea name="" id="" placeholder="Write details here..." className="form-control"></textarea>
                                    </div>
                                    <div className="form-group mt-4 ">
                                        <div className='dk-notiAddnewBtn-Con'>
                                            <button className="dk-notiAddnewBtn">Create</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddNewNotification;
