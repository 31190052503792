import React from 'react';
import { Dropdown } from 'react-bootstrap';

const NormalDropdown = (props) => {

    const getReactDomComponent = (dom) => {
        const internalInstance = dom[Object.keys(dom).find(key =>
            key.startsWith('__reactProps$')
        )];
        if (!internalInstance) return null;
        return internalInstance.value;
    }

    return (
        <React.Fragment>
            <Dropdown onSelect={function (evt, value) {
                props.updateFilter(evt, getReactDomComponent(value.target));
            }}>
                <Dropdown.Toggle id={props.id} className={props.className} variant="">
                    {(!props.isFilter) ?
                        <React.Fragment><i className="fas fa-plus pr-2"></i>  {props.defaultTxt}</React.Fragment>
                        :
                        <React.Fragment>
                            <div className='dropdown-selection d-flex align-items-center w-100'>
                                <i className="fas fa-plus pr-2"></i> {props.defaultTxt}
                            </div>
                            <i className="fas fa-times-circle crossBtn" data-key={props.filterFor} onClick={props.removeFilter}></i>
                        </React.Fragment>
                    }
                </Dropdown.Toggle>
                <Dropdown.Menu variant="" className='visible menu'>
                    {props.list.map((item, index) => (
                        <Dropdown.Item className='justify-content-between' eventKey={(item.name !== '' ? item.name : 'Blank')} value={props.filterFor} key={index + 1}>
                            {(item.name !== '' ? item.name : 'Blank')}<span className='count'>{item.count}</span>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    )
}
export default NormalDropdown;