import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./verify-email.scss";
import SubmitButton from "../../../../../../ui/submitButton";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../../../../slice/utils/message/messageSlice";
import {
  getEmailVerificationLogsForEmail1,
  getEmailVerificationLogsForEmail2,
  verifyCandidateEmail,
  updateEmailVerifyStatus
} from "../../../../../../../slice/candidates/list/listSlice";

const VerifyEmail = ({ firstEmail, secondEmail, candidateId }) => {

  const { verifyEmail1, verifyEmail2, isLoadingEmail1Verification, isLoadingEmail2Verification, isVerifyEmailSucces, message, isLoadingVerifyBtn } = useSelector(
    (state) => state.candidates
  );

  const dispatch = useDispatch();
  const [whichEmailVerify, setEmailVerify] = useState(0)

  const [verifyEmailStatus, setVerifyEmailStatus] = useState([
    {
      status: 'Cleared',
      bgColor: '#02a786',
      textColor: '#fff',
    },
    {
      status: 'Not Cleared',
      bgColor: '#d58b5d',
      textColor: '#fff',
    },
    {
      status: 'Not Available',
      bgColor: '#da3d5e',
      textColor: '#fff',
    }
  ])

  const isCheckValidation = (verification_type) => {
    if (verification_type == 1 && firstEmail) {
      return true;
    } 
    if (verification_type == 2 && secondEmail) {
      return true;
    } 
    return false
  }

  const handleEmail1 = (e) => {
    e.preventDefault();
    verifyEmail(1)
  }

  const handleEmail2 = (e) => {
    e.preventDefault();
    verifyEmail(2)
  }

  const verifyEmail = (verification_type) => {
    const isValidForm = isCheckValidation(verification_type)
    if (!isValidForm) {
      dispatch(showError({ msg: 'Email is required field' }))
      return;
    }
    setEmailVerify(verification_type)
    dispatch(verifyCandidateEmail({
      canId: candidateId,
      email: verification_type == 1 ? firstEmail : secondEmail
    }))
  }

  useEffect(() => {
    if (isVerifyEmailSucces) {
      dispatch(showSuccess({ msg: message }))
      if (whichEmailVerify == 1) {
        dispatch(getEmailVerificationLogsForEmail1({ can_id: candidateId }));
      } else if (whichEmailVerify == 2) {
        dispatch(getEmailVerificationLogsForEmail2({ can_id: candidateId }));
      }
      setEmailVerify(0)
    }
  }, [isVerifyEmailSucces]);

  const changeStatus = (listId, status, whichEmailTab) => {
    console.log({ listId, status, whichEmailTab })
    setEmailVerify(whichEmailTab)
    dispatch(updateEmailVerifyStatus({
      id: listId,
      status: status
    }))
  }


  return (
    <>
      <div className="dkg-verifyemail-mainCon">
        <div className="dkg-verifyemail-topsec">
          <div className="dkg-verifyemail-Header">
            {/* <label className="dkg-emailverify-label">VERIFY EMAIL 1</label> */}
            <form onSubmit={handleEmail1}>
              <div className="dkg-ev-inputfiledCon">
                <div className="dkg-verify-email-btnCon">
                  <SubmitButton
                    txt="Verify Email 1"
                    className="dkg-verify-email-btn"
                  />
                </div>
                <input
                  type="text"
                  className="form-control dkg-input-emailverify"
                  name="email-input"
                  id="email-input"
                  value={firstEmail}
                />
                <div className="dkg-verify-email-btnCon">
                  <SubmitButton
                    txt="Click to Verify"
                    className="dkg-without-bg-btn"
                    loading={isLoadingVerifyBtn}
                    success={isVerifyEmailSucces}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="dkg-verifyemail-tableCon">
            <table className="table table-bordered dkg-verifyemail-table">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>address</th>
                  <th>is_disposable_address</th>
                  <th>is_role_address</th>
                  <th>reason</th>
                  <th>results</th>
                  <th>risk</th>
                </tr>
              </thead>
              <tbody>
                {
                  verifyEmail1.length > 0 ? 
                    verifyEmail1.map((data) => (
                      <tr>
                        <td>
                          <div className="dkg-status-drpcon">
                            <div
                              className="caseDiv"
                              style={{
                                backgroundColor: verifyEmailStatus.find((list) => list.status == data.status).bgColor,
                                color: "#fff",
                                padding: '10px'
                              }}
                            >
                              { data.status }
                            </div>
                          </div>
                        </td>
                        <td>{ data.address }</td>
                        <td>{ data.is_disposable_address ? "true" : "false" }</td>
                        <td>{ data.is_role_address ? "true" : "false" }</td>
                        <td>{ data.reason }</td>
                        <td>{ data.result }</td>
                        <td>{ data.risk }</td>
                      </tr>
                    ))
                  :
                  <tr align="center">
                    <td colSpan="7">No Data Found</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="dkg-verifyemail-devideline"></div>
        <div className="dkg-verifyemail-bottomsec">
          <div className="dkg-verifyemail-Header">
            {/* <label className="dkg-emailverify-label">VERIFY EMAIL 2</label> */}
            <form onSubmit={handleEmail2}>
              <div className="dkg-ev-inputfiledCon">
                <div className="dkg-verify-email-btnCon">
                  <SubmitButton
                    txt="Verify Email 2"
                    className="dkg-verify-email-btn"
                  />
                </div>
                <input
                  type="text"
                  className="form-control dkg-input-emailverify"
                  name="email-input"
                  id="email-input"
                  value={secondEmail}
                />
                <div className="dkg-verify-email-btnCon">
                  <SubmitButton
                    txt="Click to Verify"
                    className="dkg-without-bg-btn"
                    loading={isLoadingVerifyBtn}
                    success={isVerifyEmailSucces}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="dkg-verifyemail-tableCon">
            <table className="table table-bordered dkg-verifyemail-table dkg-verifyemail-table-2">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>address</th>
                  <th>is_disposable_address</th>
                  <th>is_role_address</th>
                  <th>reason</th>
                  <th>results</th>
                  <th>risk</th>
                </tr>
              </thead>
              <tbody>
                {
                  verifyEmail2.length > 0 ? 
                    verifyEmail2.map((data) => (
                      <tr>
                        <td>
                          <div className="dkg-status-drpcon">
                            <div
                              className="caseDiv"
                              style={{
                                backgroundColor: verifyEmailStatus.find((list) => list.status == data.status).bgColor,
                                color: "#fff", padding: "10px"
                              }}
                            >
                              { data.status }
                            </div>
                          </div>
                        </td>
                        <td>{ data.address }</td>
                        <td>{ data.is_disposable_address ? "true" : "false" }</td>
                        <td>{ data.is_role_address ? "true" : "false" }</td>
                        <td>{ data.reason }</td>
                        <td>{ data.result }</td>
                        <td>{ data.risk }</td>
                      </tr>
                    ))
                  :
                  <tr align="center">
                    <td colSpan="7">No Data Found</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
