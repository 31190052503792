import React, { useState, useEffect } from "react";
import { Route, Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import './jobprofilecolorpicker.scss';
import { useSelector } from "react-redux";
const ColorPicker = ({ rowid = 0, onClick }) => {
    const { colors } = useSelector(state => state.jobProfile)
    return (
        <React.Fragment>
            {colors.map((list, index) => (
                <Dropdown.Item className="dk-colorDropdown-Item" style={{ backgroundColor: list.color }} data-color={list.color} data-rowid={rowid} onClick={onClick}></Dropdown.Item>
            ))}
        </React.Fragment>

    );
}

export default ColorPicker;
