import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
// import './document.scss';

const AttachmentPreviewModal = ({ show, file, onClose }) => {
    const handleClose = () => {
        setFileUrl('')
        onClose();
    }

    const [frameType, setFrameType] = useState('pdf')
    const [fileUrl, setFileUrl] = useState('')

    useEffect(() => {
        if (show) {
            if (file !== "" || '') {
                setFrameType(file.split('.').pop());
                const urls = [
                    "https://resumes-dkglobalrecruitment.s3.eu-west-1.amazonaws.com",
                    "https://master-upload.s3.amazonaws.com"
                ]
                const splitFileUrl = new URL(file).origin;
                const splitUrl = urls.find(url => splitFileUrl.includes(url));
                setFileUrl(file.split(splitUrl).pop())
            }
            else {
                setFileUrl('0')
            }
        }
    }, [show])


    return (
        <>
            <Modal show={show} onHide={handleClose} dialogClassName="dk-pipedocs-previewModal dkg-pipeline-candModal-234"
                aria-labelledby="example-custom-modal-styling-title"
                centered animation={false}>
                <Modal.Header closeButton className='dkg-mailbox-preview-header' >
                   File Preview 
                </Modal.Header>
                {
                    (frameType != '0') ?
                        (fileUrl != '0') ?
                            <Modal.Body className='dk-pipedocs-preview-modalbody'>
                                {
                                    (frameType === 'pdf' || frameType === 'PDF' || frameType === 'png' || frameType === 'PNG' || frameType === 'JPG' || frameType === 'jpg' || frameType === 'jpeg' || frameType === 'JPEG') ?
                                        <iframe src={process.env.REACT_APP_INTERNAL_BASE_URL + fileUrl} frameBorder={0}></iframe>
                                        :
                                        <>
                                            {
                                                frameType === 'xlsx' &&
                                                    <a href={process.env.REACT_APP_INTERNAL_BASE_URL + fileUrl} download="file.xlsx">Download XLSX</a>
                                            }
                                            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${process.env.REACT_APP_INTERNAL_BASE_URL + fileUrl}`} frameBorder={0}></iframe>
                                        </>
                                }
                            </Modal.Body>
                            : <Modal.Body className='dk-pipedocs-preview-modalbody'>
                                <h1>Document Not Found</h1>
                            </Modal.Body>
                        : null
                }
            </Modal>
        </>
    )
}
export default AttachmentPreviewModal;