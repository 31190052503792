import React from 'react';
import LiveJobsLeftPanel from './leftpanel/LiveJobsLeftPanel';
import './moduletraining.scss';

const LiveJobs = () => {
    return (
        <>
            <div className="dk-moduleTraining-main">
                <div className="dk-moduleTraining-cols">
                    <LiveJobsLeftPanel />
                    <div className="dk-moduleTraining-rightPanel">
                        <div className="title">Overview</div>
                        <div className="dk-moduleTraining-rightCols">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LiveJobs;
