import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './dkgcloud.scss';
// import Submenu from '../../subMenu/CommonTrainingSubmenu'


const KnowleadgeBaseMenu = () => {
    const pathname = window.location.pathname;
    // const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-onboardingNavbar dkgCloudMainNavbar">
                <Route>
                    <ul>
                        <li><Link to="/departs/dkg-global/dashboard" className={`${(segment3 === 'dashboard') ? 'active' : ''}`}>Overview</Link></li>
                        {/* <li><Link to="/dkg-global/recruitment/history" className={`${(segment2 === 'recruitment') ? 'gray-active' : ''}`}>Recruitment</Link></li> */}
                        <li><Link to="#/dkg-global/dkglobal/overview" className={`${(segment2 === 'second') ? 'active' : ''}`}>Company</Link></li>
                        <li><Link to="/dkg-global/business/summary" className={`${(segment2 === 'business') ? 'active' : ''}`}>Structure</Link></li>
                        <li><Link to="/dkg-global/hierarchy" className={(segment2 === "hierarchy") ? 'active' : ''}>Hierarchy</Link></li>
                        <li><Link to="#/dkg-global/clients" className={(segment2 === "clients") ? 'active' : ''}>Principles</Link></li>
                        <li><Link to="#/dkg-global/products" className={(segment2 === "products") ? 'active' : ''}>Objectives</Link></li>
                        <li><Link to="/dkg-global/work-model/summary" className={(segment2 === "work-model") ? 'active' : ''}>Work Models</Link></li>
                        <li><Link to="/dkg-global/dkglobal/overview" className={(segment2 === "dkglobal") ? 'active' : ''}>DK Global</Link></li>
                        <li><Link to="/dkg-global/departments/summary" className={(segment2 === "departments") ? 'active' : ''}>Departments</Link></li>
                        <li><Link to="/dkg-global/job-profiles/overview" className={(segment2 === "job-profiles") ? 'active' : ''}>Job Profile</Link></li>
                    </ul>
                </Route>
            </div>
            {/* <Submenu path={pathname} /> */}
        </React.Fragment>
    );
}

export default KnowleadgeBaseMenu;