import React from 'react';
import AccTrainingSubmenu from "../../../elements/header/navbar/subMenu/menu/accountingmodule/AccTrainingSubmenu";

const Training = () => {
    return (
        <>
            <AccTrainingSubmenu />
            <div className='container-fluid' style={{ marginTop: "125px" }}>

            </div>
        </>
    )
}

export default Training;
