import React, { useState, useEffect } from "react";
import "./myacc-contact.scss";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown'


const MyaccContact = () => {
  const { userInfo } = useSelector((state) => state.auth);
  return (
    <>
      <div className="dkg-myprofile-mainpageCon">
        <div className="dkg-myprofile-con">
          <div className="dkg-myprofile-mainRow row m-0">
            <div className="dkg-myprofile-leftcon col-md-6">
              <h2 className="dkg-profile-frm-title">REQUIRED INFORMATION</h2>
              <div className="dkg-profile-frm-subtitle">
                <p className="dkg-frm-subtitle-desc">
                  lorem ipsum is simply dummy text of printing.
                </p>
              </div>
              <div className="form-group dkg-profile-frmgrp">
                <label htmlFor="" className="dkg-profile-label">
                  My Contact Days<span className="dkg-profile-star">*</span>
                </label>
                <div className="dkg-app-select-dropdown-Con">
                  <Dropdown className="dkg-app-select-dropdown">
                      <Dropdown.Toggle variant="" id="" className="dkg-app-select-dropdown-toggle">
                      Click here to fill
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                        <Dropdown.Item href="#" className='dkg-app-select-dropdown-item'>Moday - Staurday</Dropdown.Item>
                        <Dropdown.Item href="#" className='dkg-app-select-dropdown-item'>Moday - Firday</Dropdown.Item>
                        <Dropdown.Item href="#" className='dkg-app-select-dropdown-item'>Staurday - Sunday</Dropdown.Item>
                      </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="form-group dkg-profile-frmgrp">
                <label htmlFor="" className="dkg-profile-label">
                  My Contact Times<span className="dkg-profile-star">*</span>
                </label>
                <div className="dkg-app-select-dropdown-Con">
                  <Dropdown className="dkg-app-select-dropdown">
                      <Dropdown.Toggle variant="" id="" className="dkg-app-select-dropdown-toggle">
                      Click here to fill
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                        <Dropdown.Item href="#" className='dkg-app-select-dropdown-item'>8:00 - 18:00</Dropdown.Item>
                        <Dropdown.Item href="#" className='dkg-app-select-dropdown-item'>9:00 - 19:30</Dropdown.Item>
                        <Dropdown.Item href="#" className='dkg-app-select-dropdown-item'>10:30 - 21:00</Dropdown.Item>
                      </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="dkg-myprofile-rightcon col-md-6">
              <h2 className="dkg-profile-frm-title">OPTIONAL INFORMATION</h2>
              <div className="dkg-profile-frm-subtitle">
                <p className="dkg-frm-subtitle-desc">
                  lorem ipsum is simply dummy text of printing.
                </p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyaccContact;
