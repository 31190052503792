import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
//import { InputGroup, FormControl } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
// import SimpleDropDwn from '../summary/SimpleDropDwn'
// import UserSearchFilter from '../summary/SearchInputDropDwn'
import AirDatepicker from "air-datepicker";
import en from "air-datepicker/locale/en";
import "air-datepicker/air-datepicker.css";
//import UserList from '../../../../../ui/userList/userListByRole';

import { clearState, updateJob } from "../../../../../../slice/jobs/jobsSlice";

const Benefits = (jobId) => {
    const dispatch = useDispatch();
    // const { jobsCategoryList, jobsCompanyList, jobsProjectList, jobsRoleTypeList, jobsLanguageList, jobsLocalityList, jobsNoofJobsList, jobsCountryList, jobsCityList, jobsJobProfileList, jobsWorkPermitList, jobsWorkplaceList, jobsWorkdaysList, jobsShiftsWorkList, jobsContracttypeList, jobsSalarytypeList, jobsAvailabilityList, jobsOutsideofeuList, jobsFlightticketList, jobsTaxifromairportList, jobsAccommodationtList, jobsRelocationbudgetList, jobsExperiencetList } = useSelector(state => state.common);

    const { isDetailsLoading, jobDetails, isUpdate } = useSelector(state => state.jobs);
    const [status, setStatus] = useState('');
    const [data, setData] = useState([]);

    useEffect(() => {
        if (jobDetails && !isDetailsLoading) {
            setData(jobDetails);
            setStatus(jobDetails.status);
        }
    }, [isDetailsLoading])

    useEffect(() => {
        if (isUpdate) {
            dispatch(clearState())
            setData(jobDetails);
        }
    }, [isUpdate])
    let ids = data.id;

    // const JobStatus = (status) => {
    //     let bgColor = "";
    //     let txtColor = "";
    //     switch (status) {
    //         case 'Normal':
    //             bgColor = '#30887e'
    //             txtColor = '#fff'
    //             break;

    //         case 'Urgent':
    //             bgColor = '#dc5539'
    //             txtColor = '#fff'
    //             break;

    //         case 'To qualify':
    //             bgColor = '#ffd966'
    //             txtColor = '#000'
    //             break;

    //         case 'On Hold':
    //             bgColor = '#7f5347'
    //             txtColor = '#fff'
    //             break;

    //         case 'Closed':
    //             bgColor = '#e2445c'
    //             txtColor = '#fff'
    //             break;
    //     }

    //     return (
    //         <>
    //             <Dropdown.Toggle variant="" className="dk-statusDrpDwnToggle" style={{ backgroundColor: bgColor, color: txtColor }}> {status}</Dropdown.Toggle>
    //         </>
    //     )
    // }

    const updateMyData = (e) => {
        let values = "";
        let columnId = e.target.dataset.columnid;
        if (columnId === "gross_title") {
            values = e.target.dataset.value;
        } else {
            values = e.target.value;
        }
        dispatch(updateJob({ id: ids, key: columnId, value: values }));

    }
    // const updateStatus = (values) => {
    //     setStatus(values)
    //     dispatch(updateJob({ id: ids, key: 'status', value: values }));
    // }
    new AirDatepicker('#date1', {
        position: 'bottom center',
        locale: en,
        dateFormat: 'd MMM yy',
        onSelect({ date, formattedDate, datepicker }) {
            dispatch(updateJob({ id: ids, key: 'date1', value: formattedDate }));
        }
    })

    new AirDatepicker('#date2', {
        position: 'bottom center',
        locale: en,
        dateFormat: 'd MMM yy',
        onSelect({ date, formattedDate, datepicker }) {
            dispatch(updateJob({ id: ids, key: 'date2', value: formattedDate }));
        }
    })

    let gross_value = "";
    if (data.gross_title === null) {
        gross_value = "Monthly Gross";

    } else {
        if (data.gross_title === "Monthly Gross") {
            gross_value = "Annual Gross"
        } else {
            gross_value = "Monthly Gross"
        }
    }
    // const onItemClick = (id, columnId, value) => {
    //     dispatch(updateJob({ id, key: columnId, value }));
    // };
    return (
        <>
            <div className="row dk-Adsbox">
                <div className="col-md-12">
                    <div className="col-md-12 dk-jobsuMMBottomSec pl-0">
                        <div className=" row">
                            <div className="col-md-12 pr-0 col-sm-12 dk-jobsuMMBottomBoxBg">
                                <div className="dk-boxBody row m-0 border-0">
                                    <div className="col-md-12 col-12">
                                        <div className="dk-suMMformGroupCon mb-2">
                                            <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>1</label>
                                            <input type="text" defaultValue={data.benefits_1} style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput" onBlur={updateMyData} data-columnid="benefits_1" />
                                        </div>
                                        <div className="dk-suMMformGroupCon mb-2">
                                            <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>2</label>
                                            <input type="text" defaultValue={data.benefits_2} style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput" onBlur={updateMyData} data-columnid="benefits_2" />
                                        </div>
                                        <div className="dk-suMMformGroupCon mb-2">
                                            <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>3</label>
                                            <input type="text" defaultValue={data.benefits_3} style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput" onBlur={updateMyData} data-columnid="benefits_3" />
                                        </div>
                                        <div className="dk-suMMformGroupCon mb-2">
                                            <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>4</label>
                                            <input type="text" defaultValue={data.benefits_4} style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput" onBlur={updateMyData} data-columnid="benefits_4" />
                                        </div>
                                        <div className="dk-suMMformGroupCon mb-2">
                                            <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>5</label>
                                            <input type="text" defaultValue={data.benefits_5} style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput" onBlur={updateMyData} data-columnid="benefits_5" />
                                        </div>
                                        <div className="dk-suMMformGroupCon mb-2">
                                            <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>6</label>
                                            <input type="text" defaultValue={data.benefits_6} style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput" onBlur={updateMyData} data-columnid="benefits_6" />
                                        </div>
                                        <div className="dk-suMMformGroupCon mb-2">
                                            <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>7</label>
                                            <input type="text" defaultValue={data.benefits_7} style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput" data-columnid="benefits_7" onBlur={updateMyData} />
                                        </div>
                                        <div className="dk-suMMformGroupCon mb-2">
                                            <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>8</label>
                                            <input type="text" defaultValue={data.benefits_8} style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput" data-columnid="benefits_8" onBlur={updateMyData} />
                                        </div>
                                        <div className="dk-suMMformGroupCon mb-2">
                                            <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>9</label>
                                            <input type="text" defaultValue={data.benefits_9} style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput" data-columnid="benefits_9" onBlur={updateMyData} />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-5 offset-1">
                                        <div className="mb-2">
                                            <p className='text-center m-0' style={{ border: "1px solid #ccc", borderBottom: "0px", padding: "8px" }}>Benefits Explained</p>
                                            <InputGroup size="sm" className="dk-inputGrp">
                                                <textarea className='form-control text-left' style={{ border: "1px solid #ccc", height: "211px", borderRadius: "0px" }} onBlur={updateMyData} data-columnid="benefits_explained" defaultValue={data.benefits_explained}></textarea>
                                            </InputGroup>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Benefits
