import React, { useEffect, useRef, useState } from "react";
import './hrdashboard.scss';
import PieChart from './chart/PieChart';
import CustomPieChart from './chart/CustomPieChart'


const Index = () => {
    return (
        <>
            <div className='container-fluid dkg-hrdashboard-pageCon pl-5 pr-5' style={{ marginTop: "125px" }}>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='dkg-hrtopboxCon'>
                            <div className='dkg-hrtopbox-Header'>
                                <h5 className='dkg-hrtopbox-Title'>DEPARTMENTS</h5>
                            </div>
                            <div className='dkg-hrtopbox-Body'>
                                {/* <h5 className='dk-hrtopbox-title'>Management or Production</h5> */}
                                <CustomPieChart />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='dkg-hrtopboxCon'>
                            <div className='dkg-hrtopbox-Header'>
                                <h5 className='dkg-hrtopbox-Title'>BUSINESS TEAM</h5>
                            </div>
                            <div className='dkg-hrtopbox-Body'>
                                <CustomPieChart />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='dkg-hrtopboxCon'>
                            <div className='dkg-hrtopbox-Header'>
                                <h5 className='dkg-hrtopbox-Title'>OPERATION TEAMS</h5>
                            </div>
                            <div className='dkg-hrtopbox-Body'>
                                {/* <h5 className='dk-hrtopbox-title'>RESOURCE/ADMIN/RECRUITER/AM</h5> */}
                                <CustomPieChart />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='dkg-hrbottomboxCon'>
                            <div className='dkg-hrbottombox-Header'>
                                <h5 className='dkg-hrbottombox-Title'>NATIONALITY</h5>
                            </div>
                            <div className='dkg-hrbottombox-Body'>
                                {/* <h5 className='dk-hrbottombox-title d-none'>Management or Production</h5> */}
                                <CustomPieChart />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='dkg-hrbottomboxCon'>
                            <div className='dkg-hrbottombox-Header'>
                                <h5 className='dkg-hrbottombox-Title'>CITY/TOWN</h5>
                            </div>
                            <div className='dkg-hrbottombox-Body'>
                                {/* <h5 className='dk-hrbottombox-title d-none'>RECRUITMENT OR IT</h5> */}
                                <CustomPieChart />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='dkg-hrbottomboxCon'>
                            <div className='dkg-hrbottombox-Header'>
                                <h5 className='dkg-hrbottombox-Title'>COUNTRY</h5>
                            </div>
                            <div className='dkg-hrbottombox-Body'>
                                {/* <h5 className='dk-hrbottombox-title d-none'>RESOURCE/ADMIN/RECRUITER/AM</h5> */}
                                <CustomPieChart />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index
