import React from 'react';
import Recuiter from '../../../../../assets/images/deepak.png';
import Dropdown from 'react-bootstrap/Dropdown'
import "./userfilternumber.scss";


const UserfilterNumber = () => {
    return (
        <>
            <div className="dk-useFilterNumCon">
                <Dropdown>
                    <Dropdown.Toggle variant="" className="dk-userNumfliterbtn">
                        <i className="fas fa-user"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dk-userNumfliterMenu">
                        <div className="d-flex">
                            <Dropdown.Item href="#">
                                <img src={Recuiter} className="filterImg" alt="RecuiterImg" />
                                <span className="recNoCount">65</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                                <img src={Recuiter} className="filterImg" alt="RecuiterImg" />
                                <span className="recNoCount">65</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                                <img src={Recuiter} className="filterImg" alt="RecuiterImg" />
                                <span className="recNoCount">65</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                                <img src={Recuiter} className="filterImg" alt="RecuiterImg" />
                                <span className="recNoCount">65</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                                <img src={Recuiter} className="filterImg" alt="RecuiterImg" />
                                <span className="recNoCount">65</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                                <img src={Recuiter} className="filterImg" alt="RecuiterImg" />
                                <span className="recNoCount">65</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                                <img src={Recuiter} className="filterImg" alt="RecuiterImg" />
                                <span className="recNoCount">65</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                                <img src={Recuiter} className="filterImg" alt="RecuiterImg" />
                                <span className="recNoCount">65</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                                <img src={Recuiter} className="filterImg" alt="RecuiterImg" />
                                <span className="recNoCount">65</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                                <img src={Recuiter} className="filterImg" alt="RecuiterImg" />
                                <span className="recNoCount">65</span>
                            </Dropdown.Item>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default UserfilterNumber;
