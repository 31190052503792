import React from "react";
import moment from "moment-timezone";
// export function daysAgo(dateTime) {
//     if (dateTime != null) {
//         let date = moment(moment(dateTime.replace('-','/'))).tz("Europe/Dublin");
//         // console.log(date)
//         if (moment().diff(date, 'days') >= 1) {
//             // console.log(date.fromNow())
//             return date.fromNow();
//         } else {
//             return date.calendar().split(' ')[0]; // 'Yesterday', 'Today', 'Tomorrow'
//         }
//     } else {
//         return null
//     }
// }

export function daysAgo(dateTime) {
  if (dateTime != null) {
    let date = moment(moment(dateTime)).tz("Europe/Dublin").fromNow(true);
    // console.log(date)
    return date + " ago";
  } else {
    return null;
  }
}
