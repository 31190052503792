import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Tab, Nav, Dropdown } from 'react-bootstrap'
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import DeleteModal from "../../../../ui/delete";
import EditToDoListModal from './modal/EditToDoList';
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../../../ui/submitButton";
import { addNew, updateValue, updateStatus, listAll, detailsById, deleteValue, clearState } from "../../../../../slice/account/todo/todoSlice";
import { clearTaskState } from "../../../../../slice/task/taskSlice";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import JobssTask from '../../../../../assets/images/jobtask.png'
import Resourceblue from '../../../../../assets/images/resource-blue.png'
import Candidates from '../../../../../assets/images/candidates.png'
import TasksTab from './TasksTab'
import Dkglobal from '../../../../../assets/images/business_icon.png';
import Candidate from '../../../../../assets/images/candidate-bluebg.png'
import LegalDocsTask from '../../../../../assets/images/legals.png'
// import MyTask from '../../../../../assets/images/userrec.png'
// import OtherTask from '../../../../../assets/images/other-task.png'
// import GroupTask from '../../../../../assets/images/group-task.png'
// import CandidateTask from '../../../../../assets/images/candidate-task.png'
// import CompanyMeeting from '../../../../../assets/images/ads-task.png'
// import DKGlobalMeeting from '../../../../../assets/images/business_icon.png'
// import MailboxOutlook from '../../../../../assets/images/mailbox-outlook.png'
// import FinanceTask from '../../../../../assets/images/finances.png'
// import Accounting from '../../../../../assets/images/accounts.png'
import ITBugs from '../../../../../assets/images/itbugs.png'
// import Shortbreak from '../../../../../assets/images/short-break.png'
// import Lunchbreak from '../../../../../assets/images/lunchbreak.png'
// import Hrimg from '../../../../../assets/images/hum-res.png'
// import Adposting from '../../../../../assets/images/adsposting_icon.png'
import ClientsTask from '../../../../../assets/images/client-buildings.png'
// import OtherTaskss from '../../../../../assets/images/others.png'
// import Recruitment from '../../../../../assets/images/humanres-blubg.png'
// import Databasetask from '../../../../../assets/images/database.png'
import PaymentTaskss from '../../../../../assets/images/euro-bluebg.png'
// import InoviceTasks from '../../../../../assets/images/invoice.png'
// import Admin from '../../../../../assets/images/accountings.png';
// import CreateNewTaskModal from './modal/CreateNewTask'

const TodoTrackingTab = ({ keyId }) => {
    const dispatch = useDispatch();
    const { dataList, details, isDetails, isSuccess, isDelete, isError, isLoading, message } = useSelector(state => state.todo);
    const { isTaskInserted } = useSelector(state => state.task)

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [showDelete, setShowDelete] = useState(false)

    const [rowId, setRowId] = useState(0)
    const [title, setTitle] = useState('')

    const [allDataListCount, setAllDataListCount] = useState(0)
    const [toDoDataListCount, setToDoDataListCount] = useState(0)
    const [inProgressDataListCount, setInProgressDataListCount] = useState(0)
    const [completedDataListCount, setCompletedDataListCount] = useState(0)

    const [allDataList, setAllDataList] = useState([])
    const [toDoDataList, setToDoDataList] = useState([])
    const [inProgressDataList, setInProgressDataList] = useState([])
    const [completedDataList, setCompletedDataList] = useState([])

    const [isEditToDoList, setIsEditToDoList] = useState(false);
    const [isCreateNewTask, setIsCreateNewTask] = useState(false);
    const [taskType, setTaskType] = useState('')
    const [taskTypeId, setTaskTypeId] = useState(0)

    useEffect(async () => {
        if (keyId == '1') {
            setRowId(0)
            await dispatch(listAll({}))
        }
    }, [keyId])

    const handleSelect = async (key) => {
        console.log(key)
        setRowId(0)
        switch (key) {
            case 'all':
                setAllDataList(dataList)
                break;

            case 'todolist':
                setToDoDataList(dataList.filter(items => items.progress == "To Do"))
                break;

            case 'in-progress':
                setInProgressDataList(dataList.filter(items => items.progress == "In Progress"))
                break;

            case 'completed':
                setCompletedDataList(dataList.filter(items => items.progress == "Completed"))
                break;

            case 'addnew':
                // setRowId(1)
                break;
        }
    }

    const handleCloseDelete = () => {
        setRowId(0)
        setShowDelete(false)
    }

    const deleteItem = async () => {
        await dispatch(deleteValue({ id: rowId }))
    }

    const hideedittolistModal = () => {
        setIsEditToDoList(false);
    }

    async function getDetails(e, data) {
        if (data.type == 'edit') {
            setRowId(data.id)
            setIsEditToDoList(true);
        }
        if (data.type == 'delete') {
            setRowId(data.id)
            setShowDelete(true)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (!title) {
                dispatch(showError({ msg: "Please fill notes" }))
            } else {
                setSuccess(false);
                setLoading(true);
                if (rowId == '0') {
                    await dispatch(addNew({ title }))
                }
            }
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }

    const updateToDoStatus = async (id, progress, textColor) => {
        await dispatch(updateStatus({ id, progress, textColor }))
    }

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(clearState());
            dispatch(showError({ msg: message }))
        }
        if (isSuccess) {
            dispatch(clearState());
            setRowId(0)
            setTitle('')
            setSuccess(true);
            setLoading(false);
            setAllDataListCount(dataList.length)
            setToDoDataListCount(dataList.filter(items => items.progress == "To Do").length)
            setInProgressDataListCount(dataList.filter(items => items.progress == "In Progress").length)
            setCompletedDataListCount(dataList.filter(items => items.progress == "Completed").length)
            dispatch(showSuccess({ msg: message }))
        }
        if (isDelete) {
            setRowId(0)
            setShowDelete(false)
            dispatch(clearState());
            dispatch(showSuccess({ msg: message }))
            setAllDataList(dataList)
            setToDoDataList(dataList.filter(items => items.progress == "To Do"))
            setInProgressDataList(dataList.filter(items => items.progress == "In Progress"))
            setCompletedDataList(dataList.filter(items => items.progress == "Completed"))
        }
        if (!isLoading) {
            setAllDataListCount(dataList.length)
            setToDoDataListCount(dataList.filter(items => items.progress == "To Do").length)
            setInProgressDataListCount(dataList.filter(items => items.progress == "In Progress").length)
            setCompletedDataListCount(dataList.filter(items => items.progress == "Completed").length)
        }
    }, [isLoading, isDetails, isError, isSuccess, dispatch, message])

    const showtaskModal = (type, id) => {
        console.log("id--" + id)
        setTaskType(type)
        setTaskTypeId(id)
        setIsCreateNewTask(true);
    }

    const hidetaskModal = () => {
        setIsCreateNewTask(false);
    }

    useEffect(() => {
        if (isTaskInserted) {
            dispatch(clearTaskState())
            setIsCreateNewTask(false);
        }
    }, [isTaskInserted])

    return (
        <>
            {
                (showDelete) ? <DeleteModal loading={loading} success={success} handleClose={handleCloseDelete} deleteItem={deleteItem} /> : null
            }
            {
                (isEditToDoList) ? <EditToDoListModal handleClose={hideedittolistModal} id={rowId} /> : null
            }
            {/* {
                (isCreateNewTask) ? <CreateNewTaskModal handleClose={hidetaskModal} type={taskType} taskTypeId={taskTypeId} /> : null
            } */}
            <Tab.Container id="left-tabs-example" defaultActiveKey="all" onSelect={handleSelect}>
                <div className='row'>
                    <div className='col-md-12 col-12 dk-todolistPillsCon'>
                        <Nav variant="dk-todolistPills pills" className="flex-row">
                            {/* <Nav.Item className='dk-todolistNavItem'>
                                <Nav.Link eventKey="todo-summary" className='dk-todoallBg'>Add Task<i className="fas fa-plus ml-2"></i></Nav.Link>
                            </Nav.Item> */}
                            <Nav.Item className='dk-todolistNavItem dkg-task-modal-nav-item'>
                                <Nav.Link eventKey="all" className='dk-todoallBg'>All Task {allDataListCount}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='dk-todolistNavItem dkg-task-modal-nav-item'>
                                <Nav.Link eventKey="todolist" className='todoListBg'>To Do<span className="dk-todolistNo">{toDoDataListCount}</span> </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='dk-todolistNavItem dkg-task-modal-nav-item'>
                                <Nav.Link eventKey="in-progress" className='inprogressBg'>In Progress <span className="dk-inprohressNo">{inProgressDataListCount}</span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='dk-todolistNavItem dkg-task-modal-nav-item'>
                                <Nav.Link eventKey="completed" className='completedBg'>Completed <span className="dk-compeletedNo">{completedDataListCount}</span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='dk-todolistNavItem dkg-adnew-notes-tab'>
                                <Nav.Link eventKey="addnew" className='todoaddNewBg'><i className="fas fa-plus"></i></Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className='col-md-12 col-12'>
                        <Tab.Content className='dk-todolistContent'>
                            <Tab.Pane className='dk-todolistpane sumary-Tabpane' eventKey="todo-summary">
                                <div className="dk-createnewtask-Con">
                                    <div className="dk-creatnewtask-boxCon">
                                        <div className="dk-createnewtaskTitle d-none">
                                            <h2>CREATE NEW TASK</h2>
                                        </div>
                                        <div className="dk-creatnewtask-boxs">
                                            <div className={`dk-creatnewtask-box ${taskType == "Company" ? 'active-task-box' : null}`} onClick={() => showtaskModal('Company', 144)}>
                                                <Link to="#">
                                                    <div className="dk-tasktype-imgcon">
                                                        <img src={Dkglobal} className="dk-tasktype-img" alt="Task type Image" />
                                                    </div>
                                                    <div className="dk-tasktype-title">Company</div>
                                                </Link>
                                            </div>
                                            <div className={`dk-creatnewtask-box ${taskType == "Legal" ? 'active-task-box' : null}`} onClick={() => showtaskModal('Legal', 145)}>
                                                <Link to="#">
                                                    <div className="dk-tasktype-imgcon">
                                                        <img src={LegalDocsTask} className="dk-tasktype-img" alt="Task type Image" />
                                                    </div>
                                                    <div className="dk-tasktype-title">Legal</div>
                                                </Link>
                                            </div>
                                            <div className={`dk-creatnewtask-box ${taskType == "Suppliers" ? 'active-task-box' : null}`} onClick={() => showtaskModal('Suppliers', 151)}>
                                                <Link to="#">
                                                    <div className="dk-tasktype-imgcon">
                                                        <img src={JobssTask} className="dk-tasktype-img" alt="Task type Image" />
                                                    </div>
                                                    <div className="dk-tasktype-title">Suppliers</div>
                                                </Link>
                                            </div>
                                            <div className={`dk-creatnewtask-box ${taskType == "Clients" ? 'active-task-box' : null}`} onClick={() => showtaskModal('Clients', 145)}>
                                                <Link to="#">
                                                    <div className="dk-tasktype-imgcon">
                                                        <img src={ClientsTask} className="dk-tasktype-img" alt="Task type Image" />
                                                    </div>
                                                    <div className="dk-tasktype-title">Clients</div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="dk-creatnewtask-boxs">
                                            <div className={`dk-creatnewtask-box ${taskType == "Resource" ? 'active-task-box' : null}`} onClick={() => showtaskModal('Resource', 148)}>
                                                <Link to="#">
                                                    <div className="dk-tasktype-imgcon">
                                                        <img src={Resourceblue} className="dk-tasktype-img" alt="Task type Image" />
                                                    </div>
                                                    <div className="dk-tasktype-title">Resource</div>
                                                </Link>
                                            </div>
                                            <div className={`dk-creatnewtask-box ${taskType == "Candidate" ? 'active-task-box' : null}`} onClick={() => showtaskModal('Candidate', 150)}>
                                                <Link to="#">
                                                    <div className="dk-tasktype-imgcon">
                                                        <img src={Candidate} className="dk-tasktype-img" alt="Task type Image" />
                                                    </div>
                                                    <div className="dk-tasktype-title">Candidate</div>
                                                </Link>
                                            </div>
                                            <div className={`dk-creatnewtask-box ${taskType == "Accounting" ? 'active-task-box' : null}`} onClick={() => showtaskModal('Accounting', 155)}>
                                                <Link to="#">
                                                    <div className="dk-tasktype-imgcon">
                                                        <img src={PaymentTaskss} className="dk-tasktype-img" alt="Task type Image" />
                                                    </div>
                                                    <div className="dk-tasktype-title">Accounting</div>
                                                </Link>
                                            </div>
                                            <div className={`dk-creatnewtask-box ${taskType == "IT Team" ? 'active-task-box' : null}`} onClick={() => showtaskModal('IT Team', 161)}>
                                                <Link to="#">
                                                    <div className="dk-tasktype-imgcon">
                                                        <img src={ITBugs} className="dk-tasktype-img" alt="Task type Image" />
                                                    </div>
                                                    <div className="dk-tasktype-title">IT Team</div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='dkg-createnewtaskbox-89'>
                                        {
                                            (isCreateNewTask) ? <TasksTab type={taskType} taskTypeId={taskTypeId} /> : null
                                        }
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className='dk-todolistpane' eventKey="all">
                                <ul className='dk-todolistContainer'>
                                    {
                                        dataList.map((item, index) =>
                                            <li key={item.id} id={item.id}>
                                                <div className="dk-tasklist inner-wrap-activity">
                                                    <div className="dk-taslistCon">
                                                        <span className="dk-totasktitle">{item.title}</span>
                                                        <ContextMenuTrigger className="dk-todo-dropdowncon" id={`same_unique_identifier_todoAll${item.id}`}>
                                                            <span className="dk-todo-dropdowncon">
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="" className="dk-todo-dropdownbtn" style={{ color: item.textColor }}>
                                                                        {item.progress}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dk-todo-dropdown-menu">
                                                                        <Dropdown.Item href="#" style={{ backgroundColor: "#2685a5", color: "#fff" }} onClick={() => updateToDoStatus(item.id, 'To Do', '#2685a5')}>To Do</Dropdown.Item>
                                                                        <Dropdown.Item href="#" style={{ backgroundColor: "#D58B5D", color: "#fff" }} onClick={() => updateToDoStatus(item.id, 'In Progress', '#D58B5D')}>In Progress</Dropdown.Item>
                                                                        <Dropdown.Item href="#" style={{ backgroundColor: "#3A9469", color: "#fff" }} onClick={() => updateToDoStatus(item.id, 'Completed', '#3A9469')}>Completed</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </span>
                                                        </ContextMenuTrigger>
                                                    </div>
                                                    <ContextMenu className="todorightDropmenu" id={`same_unique_identifier_todoAll${item.id}`}>
                                                        <MenuItem data={{ id: item.id, type: 'edit' }} onClick={getDetails}>
                                                            <i className="far fa-edit"></i> Edit
                                                        </MenuItem>
                                                        <MenuItem data={{ id: item.id, type: 'delete' }} onClick={getDetails}>
                                                            <i className="far fa-trash-alt"></i> Delete
                                                        </MenuItem>
                                                    </ContextMenu>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                            </Tab.Pane>
                            <Tab.Pane className='dk-todolistpane' eventKey="todolist">
                                <ul className='dk-todolistContainer'>
                                    {
                                        toDoDataList.map((item, index) =>
                                            <li key={item.id} id={item.id}>
                                                <div className="dk-tasklist inner-wrap-activity">
                                                    <div className="dk-taslistCon">
                                                        <span className="dk-totasktitle">{item.title}</span>
                                                        <ContextMenuTrigger className="dk-todo-dropdowncon" id={`same_unique_identifier_todo${item.id}`}>
                                                            <span className="dk-todo-dropdowncon">
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="" className="dk-todo-dropdownbtn" style={{ color: item.textColor }}>
                                                                        {item.progress}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dk-todo-dropdown-menu">
                                                                        <Dropdown.Item href="#" style={{ backgroundColor: "#2685a5", color: "#fff" }} onClick={() => updateToDoStatus(item.id, 'To Do', '#2685a5')}>To Do</Dropdown.Item>
                                                                        <Dropdown.Item href="#" style={{ backgroundColor: "#D58B5D", color: "#fff" }} onClick={() => updateToDoStatus(item.id, 'In Progress', '#D58B5D')}>In Progress</Dropdown.Item>
                                                                        <Dropdown.Item href="#" style={{ backgroundColor: "#3A9469", color: "#fff" }} onClick={() => updateToDoStatus(item.id, 'Completed', '#3A9469')}>Completed</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </span>
                                                        </ContextMenuTrigger>
                                                    </div>
                                                    <ContextMenu className="todorightDropmenu" id={`same_unique_identifier_todo${item.id}`}>
                                                        <MenuItem data={{ id: item.id, type: 'edit' }} onClick={getDetails}>
                                                            <i className="far fa-edit"></i> Edit
                                                        </MenuItem>
                                                        <MenuItem data={{ id: item.id, type: 'delete' }} onClick={getDetails}>
                                                            <i className="far fa-trash-alt"></i> Delete
                                                        </MenuItem>
                                                    </ContextMenu>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                            </Tab.Pane>
                            <Tab.Pane className='dk-todolistpane' eventKey="in-progress">
                                <ul className='dk-todolistContainer'>
                                    {
                                        inProgressDataList.map((item, index) =>
                                            <li key={item.id} id={item.id}>
                                                <div className="dk-tasklist inner-wrap-activity">
                                                    <div className="dk-taslistCon">
                                                        <span className="dk-totasktitle">{item.title}</span>
                                                        <ContextMenuTrigger className="dk-todo-dropdowncon" id={`same_unique_identifier_todoInProgress${item.id}`}>
                                                            <span className="dk-todo-dropdowncon">
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="" className="dk-todo-dropdownbtn" style={{ color: item.textColor }}>
                                                                        {item.progress}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dk-todo-dropdown-menu">
                                                                        <Dropdown.Item href="#" style={{ backgroundColor: "#2685a5", color: "#fff" }} onClick={() => updateToDoStatus(item.id, 'To Do', '#2685a5')}>To Do</Dropdown.Item>
                                                                        <Dropdown.Item href="#" style={{ backgroundColor: "#D58B5D", color: "#fff" }} onClick={() => updateToDoStatus(item.id, 'In Progress', '#D58B5D')}>In Progress</Dropdown.Item>
                                                                        <Dropdown.Item href="#" style={{ backgroundColor: "#3A9469", color: "#fff" }} onClick={() => updateToDoStatus(item.id, 'Completed', '#3A9469')}>Completed</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </span>
                                                        </ContextMenuTrigger>
                                                    </div>
                                                    <ContextMenu className="todorightDropmenu" id={`same_unique_identifier_todoInProgress${item.id}`}>
                                                        <MenuItem data={{ id: item.id, type: 'edit' }} onClick={getDetails}>
                                                            <i className="far fa-edit"></i> Edit
                                                        </MenuItem>
                                                        <MenuItem data={{ id: item.id, type: 'delete' }} onClick={getDetails}>
                                                            <i className="far fa-trash-alt"></i> Delete
                                                        </MenuItem>
                                                    </ContextMenu>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                            </Tab.Pane>
                            <Tab.Pane className='dk-todolistpane' eventKey="completed">
                                <ul className='dk-todolistContainer'>
                                    {
                                        completedDataList.map((item, index) =>
                                            <li key={item.id} id={item.id}>
                                                <div className="dk-tasklist inner-wrap-activity">
                                                    <div className="dk-taslistCon">
                                                        <span className="dk-totasktitle">{item.title}</span>
                                                        <ContextMenuTrigger className="dk-todo-dropdowncon" id={`same_unique_identifier_todoCompleted${item.id}`}>
                                                            <span className="dk-todo-dropdowncon">
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="" className="dk-todo-dropdownbtn" style={{ color: item.textColor }}>
                                                                        {item.progress}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dk-todo-dropdown-menu">
                                                                        <Dropdown.Item href="#" style={{ backgroundColor: "#2685a5", color: "#fff" }} onClick={() => updateToDoStatus(item.id, 'To Do', '#2685a5')}>To Do</Dropdown.Item>
                                                                        <Dropdown.Item href="#" style={{ backgroundColor: "#D58B5D", color: "#fff" }} onClick={() => updateToDoStatus(item.id, 'In Progress', '#D58B5D')}>In Progress</Dropdown.Item>
                                                                        <Dropdown.Item href="#" style={{ backgroundColor: "#3A9469", color: "#fff" }} onClick={() => updateToDoStatus(item.id, 'Completed', '#3A9469')}>Completed</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </span>
                                                        </ContextMenuTrigger>
                                                    </div>
                                                    <ContextMenu className="todorightDropmenu" id={`same_unique_identifier_todoCompleted${item.id}`}>
                                                        <MenuItem data={{ id: item.id, type: 'edit' }} onClick={getDetails}>
                                                            <i className="far fa-edit"></i> Edit
                                                        </MenuItem>
                                                        <MenuItem data={{ id: item.id, type: 'delete' }} onClick={getDetails}>
                                                            <i className="far fa-trash-alt"></i> Delete
                                                        </MenuItem>
                                                    </ContextMenu>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                            </Tab.Pane>
                            <Tab.Pane className='dk-todolistpane dk-todolistpaneAddNew ' eventKey="addnew">
                                <div className=" dk-addnewFrmGrp">
                                    <label>Add New Task</label>
                                    <textarea rows="4" className="form-control w-100 taskTitle" placeholder="" value={title} onChange={(e) => setTitle(e.target.value)} />
                                </div>
                                <div className="dk-taskactsavebtnCon">
                                    <SubmitButton txt="Save" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="btn dk-taskactsavebtn" />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </div>
            </Tab.Container>
        </>
    )
}

export default TodoTrackingTab
