import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./testmonial.scss";
import RatingBar from "./RatingBar";
import User from "../../../../../assets/images/deepak.png";
import Dropdown from "react-bootstrap/Dropdown";
import SubmitButton from "../../../../ui/submitButton";
import { getCMSHeadingText } from "../../../../../slice/frontendCms/frontendCmsSlice";

const Index = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList } = useSelector((state) => state.cmslist);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setData(getcmsList);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCMSHeadingText({}));
      } catch (err) {}
    }
    fetchData();
  }, [dispatch]);

  return (
    <>
      <div className="dkg-testimonial-pageCon">
        <div className="dkg-testimonial-maincon">
          <div className="dkg-testimonial-mainRow row">
            <div className="dkg-testimonial-header">
              <h2 className="dkg-services-page-title">
                {data.length > 0 ? data[17].page_description : ""}
              </h2>
              <p className="dkg-services-page-text">
                {data.length > 0 ? data[18].page_description : ""}
              </p>
            </div>
           
          </div>
          <div className="dkg-testimonial-content row">
          <div className="col-md-3 col-sm-12 col-12 dk-test-pr-0 dkg-testimonial-leftCon">
              <div className="dk-testmonial-filter">
                <div className="dk-testmonial-filter-search">
                  <div className="input-group dk-testmonials-search-box">
                    <input
                      type="text"
                      className="dk-test-search-input form-control"
                      placeholder="Search Keywords"
                    />
                    <div className="input-group-append dkg-input-append">
                      <button
                        className="btn btn-secondary dk-test-search-box-addon"
                        type="button"
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="dk-testmonial-filter-cols">
                  <div className="dkg-filtertitle">Timeline</div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="timeline1"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="timeline1"
                      >
                        All Time
                      </label>
                    </div>
                    <span>233</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="timeline2"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="timeline2"
                      >
                        Last 30 days
                      </label>
                    </div>
                    <span>333</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="timeline3"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="timeline3"
                      >
                        Last 6 months
                      </label>
                    </div>
                    <span>93</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="timeline4"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="timeline4"
                      >
                        Last 1 years
                      </label>
                    </div>
                    <span>23</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="timeline5"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="timeline5"
                      >
                        Last 2 years
                      </label>
                    </div>
                    <span>52</span>
                  </div>
                </div>
                <div className="dk-testmonial-filter-cols">
                  <div className="dkg-filtertitle">Services</div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="Services1"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="Services1"
                      >
                        Services
                      </label>
                    </div>
                    <span>73</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="Services2"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="Services2"
                      >
                        Services
                      </label>
                    </div>
                    <span>86</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="Services3"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="Services3"
                      >
                        Services
                      </label>
                    </div>
                    <span>63</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="Services4"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="Services4"
                      >
                        Services
                      </label>
                    </div>
                    <span>28</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="Services5"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="Services5"
                      >
                        Services
                      </label>
                    </div>
                    <span>35</span>
                  </div>
                </div>
                <div className="dk-testmonial-filter-cols">
                  <div className="dkg-filtertitle">Country</div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="location-checkbox-1"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="location-checkbox-1"
                      >
                        Ireland
                      </label>
                    </div>
                    <span>33</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="location-checkbox-2"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="location-checkbox-2"
                      >
                        India
                      </label>
                    </div>
                    <span>44</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="location-checkbox-3"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="location-checkbox-3"
                      >
                        Germany
                      </label>
                    </div>
                    <span>55</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="location-checkbox-4"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="location-checkbox-4"
                      >
                        Greece
                      </label>
                    </div>
                    <span>66</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="location-checkbox-5"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="location-checkbox-5"
                      >
                        Norway
                      </label>
                    </div>
                    <span>77</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12 dkg-testimonial-rightCon">
              <div className="dk-testimonial-clints-container">
                <div className="dk-testmonials-rating d-flex">
                  <RatingBar />
                </div>
                <blockquote>
                  <span>
                    Bianca was awesome. Not only did she do her work
                    professionally and made things run smoothly, she also cares
                    a lot about you as a person. This personal interaction and a
                    ton of patience made me write this review. Surely wish more
                    agencies would be lucky to have such a great person. Thanks
                    Bianca, you made it happen!
                  </span>
                </blockquote>
                <div className="dk-testi-bottom-sec">
                  <div className="dk-testi-bottom-leftsec">
                    <div className="dkg-testiImage">
                      <img src={User} alt="" className="img-fluid" />
                      <div className="dkg-testiTitle">DEEPAK KUMAR</div>
                    </div>
                  </div>
                  <div className="dk-testi-bottom-rightsec">
                    <div className="dkg-testiText">
                      <small className="dkg-countryname">Ireland</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dk-testimonial-clints-container">
                <div className="dk-testmonials-rating d-flex">
                  <RatingBar />
                </div>
                <blockquote>
                  <span>
                    Maria is AWESOME! I can't even describe how much I
                    appreciate her work. She was ready to help every minute of
                    our cooperation providing me with tips and hints and when
                    some issue comes up by the side of an potential employer she
                    took action to solve it as fast as it could be done. Once
                    more thanks a lot and I wish there are more people like You
                    in my life to cooperate with.
                  </span>
                </blockquote>
                <div className="dk-testi-bottom-sec">
                  <div className="dk-testi-bottom-leftsec">
                    <div className="dkg-testiImage">
                      <img src={User} alt="" className="img-fluid" />
                      <div className="dkg-testiTitle">DEEPAK KUMAR</div>
                    </div>
                  </div>
                  <div className="dk-testi-bottom-rightsec">
                    <div className="dkg-testiText">
                      <small className="dkg-countryname">Ireland</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dk-testimonial-clints-container">
                <div className="dk-testmonials-rating d-flex">
                  <RatingBar />
                </div>
                <blockquote>
                  <span>
                    The service you get from this agency is great! They will go
                    beyond and further in helping you to get a job that fits
                    you. Not just that, here they also keep you motivated to
                    keep on going, because you are worth it
                  </span>
                </blockquote>
                <div className="dk-testi-bottom-sec">
                  <div className="dk-testi-bottom-leftsec">
                    <div className="dkg-testiImage">
                      <img src={User} alt="" className="img-fluid" />
                      <div className="dkg-testiTitle">DEEPAK KUMAR</div>
                    </div>
                  </div>
                  <div className="dk-testi-bottom-rightsec">
                    <div className="dkg-testiText">
                      <small className="dkg-countryname">Ireland</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dkg-pagination-mainCon text-center mt-4 mb-4">
                <ul className="dkg-pagination-circle-ul pagination justify-content-center">
                  <li className="dkg-pagination-circle-li">
                    <Link to="#" className="dkg-page-link">
                      «
                    </Link>
                  </li>
                  <li className="dkg-pagination-circle-li active">
                    <Link to="#" className="dkg-page-link">
                      1
                    </Link>
                  </li>
                  <li className="dkg-pagination-circle-li">
                    <Link to="#" className="dkg-page-link">
                      2
                    </Link>
                  </li>
                  <li className="dkg-pagination-circle-li">
                    <Link to="#" className="dkg-page-link">
                      3
                    </Link>
                  </li>
                  <li className="dkg-pagination-circle-li">
                    <Link to="#" className="dkg-page-link">
                      4
                    </Link>
                  </li>
                  <li className="dkg-pagination-circle-li">
                    <Link to="#" className="dkg-page-link">
                      5
                    </Link>
                  </li>
                  <li className="dkg-pagination-circle-li">
                    <Link to="#" className="dkg-page-link">
                      »
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 col-xs-12 dkg-testimonial-form-Con">
              <div className="dkg-testimonial-form">
                <div className="dkg-testmonial-form-title">
                  <h5 className="pt-2">Add Your Testimonial</h5>
                  <form className="" action="#">
                    <div className="form-group dkg-testimonial-frmgrp">
                      <label className="dkg-testimonial-label" htmlFor="name">
                        Name<span className="dkg-red-star">*</span>
                      </label>
                      <div className="input-group dkg-testimonial-inputgrp">
                        <input
                          type="text"
                          className="form-control dkg-testimonial-input"
                          name="name"
                          id="name"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="form-group dkg-testimonial-frmgrp">
                      <label className="dkg-testimonial-label" htmlFor="email">
                        E-mail<span className="dkg-red-star">*</span>
                      </label>
                      <div className="input-group dkg-testimonial-inputgrp">
                        <input
                          type="text"
                          className="form-control dkg-testimonial-input"
                          name="email"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="form-group dkg-testimonial-frmgrp">
                      <label
                        className="dkg-testimonial-label"
                        htmlFor="country"
                      >
                        Country<span className="dkg-red-star">*</span>
                      </label>
                      <div className="input-group dkg-testimonial-inputgrp">
                        <input
                          type="text"
                          className="form-control dkg-testimonial-input"
                          name="country"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="form-group dkg-testimonial-frmgrp">
                      <label
                        className="dkg-testimonial-label"
                        htmlFor="recruiter"
                      >
                        Select Services<span className="dkg-red-star">*</span>
                      </label>
                      <Dropdown className="dkg-testimonial-drpdwn">
                        <Dropdown.Toggle
                          variant=""
                          id=""
                          className="dkg-testimonial-drpdwn-toggle"
                        ></Dropdown.Toggle>
                        <Dropdown.Menu className="dkg-testimonial-drpdwn-menu">
                          <Dropdown.Item
                            href="#/"
                            className="dkg-testimonial-drpdwn-item"
                          >
                            Select Services 1
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/"
                            className="dkg-testimonial-drpdwn-item"
                          >
                            Select Services 2
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/"
                            className="dkg-testimonial-drpdwn-item"
                          >
                            Select Services 3
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="form-group dkg-testimonial-frmgrp">
                      <label
                        className="dkg-testimonial-label"
                        htmlFor="your-testimonial"
                      >
                        Your testimonial <span className="dkg-red-star">*</span>
                      </label>
                      <textarea
                        className="form-control dkg-testimonial-textArea"
                        id="your-testimonial"
                        rows="3"
                      ></textarea>
                    </div>
                    <div className="form-group dkg-testimonial-frmgrp">
                      <p className="dkg-testimonial-rating-titlle mb-0">
                        Rate our services <span className="dkg-red-star">*</span>
                      </p>
                      <div className="dkg-rating-service-radio">
                        <div className="input-group dkg-testimonial-inputgrp">
                          <input
                            type="radio"
                            className="form-control dkg-testimonial-radio"
                            name="name"
                            value="1"
                            id="rating_one"
                          />
                          <span className="dkg-test-rating-no">1</span>
                        </div>
                        <div className="input-group dkg-testimonial-inputgrp">
                          <input
                            type="radio"
                            className="form-control dkg-testimonial-radio"
                            name="name"
                            value="2"
                            id="two"
                          />
                          <span className="dkg-test-rating-no">2</span>
                        </div>
                        <div className="input-group dkg-testimonial-inputgrp">
                          <input
                            type="radio"
                            className="form-control dkg-testimonial-radio"
                            name="name"
                            value="3"
                            id="rating_three"
                          />
                          <span className="dkg-test-rating-no">3</span>
                        </div>
                        <div className="input-group dkg-testimonial-inputgrp">
                          <input
                            type="radio"
                            className="form-control dkg-testimonial-radio"
                            name="name"
                            value="4"
                            id="rating_four"
                          />
                          <span className="dkg-test-rating-no">4</span>
                        </div>
                        <div className="input-group dkg-testimonial-inputgrp">
                          <input
                            type="radio"
                            className="form-control dkg-testimonial-radio"
                            name="name"
                            value="5"
                            id="rating_five"
                          />
                          <span className="dkg-test-rating-no">5</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-3 dkg-testimonial-frmgrp">
                      <div className="dkg-testimoninal-btn-Con">
                        <SubmitButton
                          txt="SUBMIT"
                          className="dkg-testmonial-sub-btn"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
