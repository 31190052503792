import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./teamroles.scss";

const TeamRoles = () => {
    return (
        <>
            <div className="dk-rolesresponPageMain">
                <div className="dk-pageheader">
                    <h3 className="title text-center">TEAMS & ROLES SUMMARY</h3>
                    <div className="dk-teamrolerightBtnCon">
                        <div className="dk-teamroleseditBtn">
                            <Link to="/dashboard/team-and-roles/edit-team-and-roles"><i className="far fa-edit"></i> Edit</Link>
                        </div>
                    </div>
                </div>
                <div className="dk-rolesresponTableCon table-responsive">
                    <table className="table dk-rolesresponTable table-bordered">
                        <thead className="dk-rolesTeamHeader">
                            <tr>
                                <th></th>
                                <th>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 1</div>
                                    <div className="dk-rolesTeamHeaderTitle">RESOURCE ADMINS - RD</div>
                                </th>
                                <th colSpan={2}>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 2</div>
                                    <div className="dk-rolesTeamHeaderTitle">RECRUITMENT ADMINS - RA</div>
                                </th>
                                <th>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 3</div>
                                    <div className="dk-rolesTeamHeaderTitle">RECRUITMENT CONSULTANT - RC</div>
                                </th>
                                <th>
                                    <div className="dk-rolesTeamHeaderTitle">TEAM 4</div>
                                    <div className="dk-rolesTeamHeaderTitle">ACCOUNT MANAGERS - AM</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>TEAM LEADER</td>
                                <td>
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/1623316236.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td colSpan={2} className="team2BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team3BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team4BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>TEAM</td>
                                <td>
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c3a2270ae02e.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c3ad279ec.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/1638604796.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td colSpan={2} className="team2BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5e54fdac9c.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team3BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5e54fdac9c.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                                <td className="team4BG">
                                    <div className="dk-teamImgCols">
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="dk-team1 mr-2">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>SUMMARY</td>
                                <td><p>CREATE NEW LEADS</p></td>
                                <td><p>FILTER NEW LEADS</p></td>
                                <td><p>SCREEN THE LEADS</p></td>
                                <td><p>QUALIFY THE LEADS</p></td>
                                <td><p>CLOSE THE LEADS</p></td>
                            </tr>
                            <tr>
                                <td>MANAGE</td>
                                <td><p>NEW CV'S PIPELINE</p></td>
                                <td><p>SELECTED PIPELINE</p></td>
                                <td><p>SCREENING PIPELINE</p></td>
                                <td><p>RECRUITERS PIPELINE</p></td>
                                <td><p>AM PIPELINE</p></td>
                            </tr>
                            <tr>
                                <td>ACTIONS</td>
                                <td><p>NO EMAIL/SMS/CALL</p></td>
                                <td><p>EMAIL/SMS ONLY</p></td>
                                <td><p>EMAIL /SMS /CALL</p></td>
                                <td><p>EMAIAL /SMS /CALL</p></td>
                                <td><p>EMAIL /SMS /CALL</p></td>
                            </tr>
                            <tr>
                                <td>CLEARANCE</td>
                                <td><p>MOVE TO SELECTED</p></td>
                                <td><p>MOVE TO SCREENING</p></td>
                                <td><p>MOVE TO RECRUITERS</p></td>
                                <td><p>MOVE TO AM PIPELINE</p></td>
                                <td><p>MOVE TO OFFER PIPELINE</p></td>
                            </tr>
                            <tr>
                                <td>RESPONSIBILITIES</td>
                                <td>
                                    <p>- ADS MANAGEMENT</p>
                                    <p>- APPS MANAGEMENT</p>
                                    <p>- JOBS MANAGEMENT</p>
                                    <p>- JOB BOARD INVOICE</p>
                                    <p>- MARKETING BUDGET</p>
                                    <p>- DK GLOBAL DATABASE</p>
                                </td>
                                <td>
                                    <p>- REVIEW NEW CV's</p>
                                    <p>- UPDATE PROFILES</p>
                                    <p>- ASK CV IN ENGLISH</p>
                                    <p>- VISA CONFIRMATION</p>
                                    <p>- POST SOCIAL MEDIA ADS</p>
                                    <p>- SEARCH NEW CANDIDATES</p>
                                </td>
                                <td>
                                    <p>- MINI JOB SPEC + SMS</p>
                                    <p>- 1st REMINDER + SMS</p>
                                    <p>- 2nd REMINDER + SMS</p>
                                    <p>- CHASE NOT REACHABLE</p>
                                    <p>- PRE-SCREENING REPORT</p>
                                    <p>- CANDIDATE PREFERENCES</p>
                                </td>
                                <td>
                                    <p>- QUALIFYING CALL</p>
                                    <p>- FULL JOB SPEC + SMS</p>
                                    <p>- MAKE SEND OUT TO AM</p>
                                    <p>- UPLOAD IN CLIENT PORTAL</p>
                                    <p>- CREATE QUALIFYING REPORT</p>
                                    <p>- NOT REACHABLE CANDIDATES</p>
                                </td>
                                <td>
                                    <p>- SUBMIT THE SEND OUT</p>
                                    <p>- SCHEDULE INTERVIEWS</p>
                                    <p>- ASK NEW JOBS DEMAND</p>
                                    <p>- ASK CANDIDATES FEEDBACK</p>
                                    <p>- CANDIDATES INTERVIEW PREP</p>
                                    <p>- HELP WITH OFFER DOCUMENTS</p>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default TeamRoles;
