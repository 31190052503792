import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';
import "./addnewtitle.css";
import Submit from '../../../../ui/submitButton';
import { showSuccess, showError, clearMessage } from "../../../../utils/messages/messageSlice";
import { updateDetails, clearState } from "../../../../../slice/campaign/campaignSlice";

const AddnewTitle = ({ onHide, name, value, id, type }) => {

    const dispatch = useDispatch();
    const { dataList, isDataLoading, isInsert, isUpdate, isError, isDelete, errorMessage, jobBoardList, companyList, rolesList, languageList, userList, projectList } = useSelector(state => state.campaignAds)

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [rowId, setRowId] = useState(id);
    const [title, setTitle] = useState("");

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "title":
                setTitle(value)
                break;
            default:
                break;
        }
    }

    const handleButtonClick = async () => {
        if (id == '0' || !title) {
            dispatch(showError({ msg: 'Please fill all fields.' }))
        } else {
            if (!loading) {
                setSuccess(false);
                setLoading(true);
                await dispatch(updateDetails({ id: rowId, name: name, value: title, type }))
            }
        }
    }
    useEffect(() => {
        if (isUpdate) {
            dispatch(showSuccess({ msg: 'Campaigns update successfully.' }))
            dispatch(clearState())
            setTitle('');
            setRowId('0')
            onHide()
            setSuccess(true);
            setLoading(false);
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }
    }, [isUpdate, isError])

    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-addnewtitlecampaign"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Body className="camp-addnewTitlebody">
                    <div className="dk-newtitleinputCon">
                        <div className="firstInput">
                            <label>Current Title</label>
                            <input type="text" className="form-control" value={value} readOnly />
                        </div>
                        <div className="SecondInput">
                            <label>New Title</label>
                            <input type="text" className="form-control" defaultValue="" name="title" value={title} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="dk-adsCreditBtnCon d-flex justify-content-center">
                        <Submit txt="SAVE" loading={loading} success={success} onClick={handleButtonClick} className="btn  dk-calcsaveBtn" />
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default AddnewTitle;
