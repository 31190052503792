import React from 'react';
import "./communication.scss";
import Deepak from "../../../../../../../assets/images/deepak.png";


const Communication = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="dk-coMMTophead">
                        <h2 className="title">Calls, Emails, SMS, Chats - Tracking</h2>
                    </div>
                    <div className="dk-commnTableCon">
                        <table className="table dk-commnTable">
                            <thead>
                                <tr>
                                    <th>S No.</th>
                                    <th>Recruiter</th>
                                    <th>Date &amp; Time</th>
                                    <th>Type</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>
                                        <span className="recImgCon">
                                            <img src={Deepak} alt="Recruiter" />
                                        </span>
                                    </td>
                                    <td>08 Oct 21</td>
                                    <td>
                                        <span className="typebodyColumn">
                                            <i className="fa fa-envelope fa-2x" aria-hidden="true"></i>
                                        </span>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>
                                        <span className="recImgCon">
                                            <img src={Deepak} alt="Recruiter" />
                                        </span>
                                    </td>
                                    <td>08 Oct 21</td>
                                    <td>
                                        <span className="typebodyColumn">
                                            <i className="fa fa-envelope fa-2x" aria-hidden="true"></i>
                                        </span>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>
                                        <span className="recImgCon">
                                            <img src={Deepak} alt="Recruiter" />
                                        </span>
                                    </td>
                                    <td>08 Oct 21</td>
                                    <td>
                                        <span className="typebodyColumn">
                                            <i className="fa fa-envelope fa-2x" aria-hidden="true"></i>
                                        </span>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>
                                        <span className="recImgCon">
                                            <img src={Deepak} alt="Recruiter" />
                                        </span>
                                    </td>
                                    <td>08 Oct 21</td>
                                    <td>
                                        <span className="typebodyColumn">
                                            <i className="fa fa-envelope fa-2x" aria-hidden="true"></i>
                                        </span>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>
                                        <span className="recImgCon">
                                            <img src={Deepak} alt="Recruiter" />
                                        </span>
                                    </td>
                                    <td>08 Oct 21</td>
                                    <td>
                                        <span className="typebodyColumn">
                                            <i className="fa fa-envelope fa-2x" aria-hidden="true"></i>
                                        </span>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Communication;
