import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/trainingmodule/RecruitmentTraining';

const RecruitmentTrainingLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Training -" dashboardLink="/dashboard/training" mainheaderLink="Recruitment" moduleLink="/trainingmodule/recruitment-module" linkcolor="#3c4c62" mainheaderTitle=" - Recruitment Manager" textcolor="#396a95" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default RecruitmentTrainingLayout;
