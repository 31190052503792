import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const DkgToolsDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/business') ? 'active' : ''}`}>DKG CRM</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/industry') ? 'active' : ''}`}>Office 365</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/services') ? 'active' : ''}`}>Skype</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/clients') ? 'active' : ''}`}>Essendex</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/suppliers') ? 'active' : ''}`}>Pure Chat</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/completion') ? 'active' : ''}`}>Monday</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Yesware</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Godaddy</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Rchilli</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>AWS</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Bibby</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default DkgToolsDetailLeftPanel;

