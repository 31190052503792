import React from "react";
import { Route, Link } from "react-router-dom";

const NotificationSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/recruitment/notification/summary" className={`${(segment3 == 'summary') ? 'active' : ''}`}>Summary</Link></li>
                        <li><Link to="/recruitment/notification/live-jobs" className={`${(segment3 == 'live-jobs') ? 'active' : ''}`}>Live Jobs</Link></li>
                        <li><Link to="/recruitment/notification/candidates" className={`${(segment3 == 'candidates') ? 'active' : ''}`}>Candidates</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default NotificationSubmenu;
