import React, { useEffect, useState } from 'react';
import '../../accmanager.scss'
import Dropdown from 'react-bootstrap/Dropdown'

const RMFilter = ({ list, id, img, selectUserFilter }) => {
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        const result = [];
        const map = new Map();
        for (const item of list) {
            if (!map.has(item.original.recRM.uid)) {
                map.set(item.original.recRM.uid, true);
                result.push({
                    id: item.original.recRM.uid,
                    name: item.original.recRM.firstName,
                    userImg: item.original.recRM.profileImage,
                    count: list.filter(function (val) {
                        return val.original.recRM.uid === item.original.recRM.uid;
                    }).length
                });
            }
        }
        result.sort(function (a, b) {
            return b.count - a.count;
        });
        setUserList(result)
    }, [list])
    const totalCount = userList.reduce((total, row) => total = total + Number(row.count), 0);
    return (
        <>
            <Dropdown className="dk-AmDropDwn">
                <Dropdown.Toggle variant="" className="dk-AmDropDwnToggle">
                    {
                        (id === '0') ? <React.Fragment>
                            <div className="tooltipMain">
                                RM
                                <div className="tooltipText">Resource - {totalCount}</div>
                            </div></React.Fragment> : <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + img} />
                    }
                </Dropdown.Toggle>
                <Dropdown.Menu className="dk-AmDropDwnMenu">
                    <div className="d-flex">
                        {
                            userList && userList.map((item, index) =>
                                <Dropdown.Item key={index} data-type="RMID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} alt="AM" className="accManImg" data-type="RMID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                    <span className="counterNo" data-type="RMID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>{item.count}</span>
                                </Dropdown.Item>
                            )
                        }
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default RMFilter;
