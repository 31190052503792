import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PostData } from "../../services";

export const showAllRecordsCounts = createAsyncThunk(
    'candidate/database/search-cv/defaultalltotal',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/total', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const totalRecordsCounts = createAsyncThunk(
    'candidate/database/search-cv/total',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/total', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const recruitersCounts = createAsyncThunk(
    'candidate/database/search-cv/recruiters',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/recruiters', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const recordYearCounts = createAsyncThunk(
    'candidate/database/search-cv/recordyear',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/recordYear', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const latestJobCounts = createAsyncThunk(
    'candidate/database/search-cv/latestjobs',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/latestJob', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const motivationCount = createAsyncThunk(
    'candidate/database/search-cv/motivation',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/motivation', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const casePriorityCount = createAsyncThunk(
    'candidate/database/search-cv/case/priority',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/casepriority', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const priorityCount = createAsyncThunk(
    'candidate/database/search-cv/priority',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/crmProfile', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const categoryCounts = createAsyncThunk(
    'candidate/database/search-cv/category',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/category', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const caseStatusCounts = createAsyncThunk(
    'candidate/database/search-cv/case-status',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/case-status', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const caseProgressCounts = createAsyncThunk(
    'candidate/database/search-cv/case-progress',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/case-progress', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const caseStageCounts = createAsyncThunk(
    'candidate/database/search-cv/case-stage',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/case-stage', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const flagCounts = createAsyncThunk(
    'candidate/database/search-cv/flag',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/flags', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const profileIndicatorCounts = createAsyncThunk(
    'candidate/database/search-cv/profile-indicator',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/profile-indicators', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const genderCounts = createAsyncThunk(
    'candidate/database/search-cv/gender',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/gender', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const nativeLanguageCounts = createAsyncThunk(
    'candidate/database/search-cv/native-language',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/native-language', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const fluentLanguageCounts = createAsyncThunk(
    'candidate/database/search-cv/fluent-language',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/fluent-language', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const intermediateLanguageCounts = createAsyncThunk(
    'candidate/database/search-cv/intermediate-language',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/intermediate-language', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const nationalityCounts = createAsyncThunk(
    'candidate/database/search-cv/nationality',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/nationality', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const workPermitCounts = createAsyncThunk(
    'candidate/database/search-cv/work-permit',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/work-permit', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const cvSourceCounts = createAsyncThunk(
    'candidate/database/search-cv/cv-source',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/cv-source', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)


export const companyCounts = createAsyncThunk(
    'candidate/database/search-cv/company',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/company', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const companyLanguageCounts = createAsyncThunk(
    'candidate/database/search-cv/company-language',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/language', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const companyProjectCounts = createAsyncThunk(
    'candidate/database/search-cv/company-project',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/project', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const companyRoleCounts = createAsyncThunk(
    'candidate/database/search-cv/company-role',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/role', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const profileStatusCounts = createAsyncThunk(
    'candidate/database/search-cv/profile-status',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/search-cvs/profile-status', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const accountMangerCounts = createAsyncThunk(
    'candidate/database/search-cv/account-manger',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/account-manager', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const recAdminCounts = createAsyncThunk(
    'candidate/database/search-cv/rec-admin',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/rec-admin', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const cityCounts = createAsyncThunk(
    'candidate/database/search-cv/city',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/city', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const countryCounts = createAsyncThunk(
    'candidate/database/search-cv/country',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/country', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const visaCounts = createAsyncThunk(
    'candidate/database/search-cv/visa',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/visa', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const careerCounts = createAsyncThunk(
    'candidate/database/search-cv/career-level',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/career-level', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const domainCounts = createAsyncThunk(
    'candidate/database/search-cv/domain',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/domain', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const jobTitleCounts = createAsyncThunk(
    'candidate/database/search-cv/jobTitle',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/job-title', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const industriesCounts = createAsyncThunk(
    'candidate/database/search-cv/industries',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/industries', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const itSkillsCounts = createAsyncThunk(
    'candidate/database/search-cv/itskilles',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/it-skills', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const jobSkillsCounts = createAsyncThunk(
    'candidate/database/search-cv/jobskills',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/job-skills', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const pipelineCount = createAsyncThunk(
    'candidate/database/search-cv/pipeline',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/pipeline', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)


export const desiredSalaryCount = createAsyncThunk(
    'candidate/database/search-cv/desired-salary',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/desired-salary', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const desiredLocationCount = createAsyncThunk(
    'candidate/database/search-cv/desired-location',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/desired-location', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const desiredBenefitsCount = createAsyncThunk(
    'candidate/database/search-cv/desired-benefits',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/desired-benefits', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const workingLocationgCount = createAsyncThunk(
    'candidate/database/search-cv/working-location',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/working-location', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const relocationAbroadCount = createAsyncThunk(
    'candidate/database/search-cv/relocation-abroad',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/relocation-abroad', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const jobCityCount = createAsyncThunk(
    'candidate/database/search-cv/job-city',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/job-city', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const jobCountryCount = createAsyncThunk(
    'candidate/database/search-cv/job-country',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/job-country', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const jobLocalityCount = createAsyncThunk(
    'candidate/database/search-cv/job-locality',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/job-locality', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const jobProjectCount = createAsyncThunk(
    'candidate/database/search-cv/job-project',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/job-project', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const jobLanguage1Count = createAsyncThunk(
    'candidate/database/search-cv/job-lang1',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/job-lang1', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const jobLanguage2Count = createAsyncThunk(
    'candidate/database/search-cv/job-lang2',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/job-lang2', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const educationsCount = createAsyncThunk(
    'candidate/database/search-cv/educations',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/educations', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const getCanIdCreateProject = createAsyncThunk(
    'candidate/database/search-cv/create-project',
    async ({ filter, search }, thunkAPI) => {
        const res = await PostData(true, '/candidates/database/summary/create-project', { filter, search })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

const SearchCVSlice = createSlice({
    name: 'databaseSearchCv',
    initialState: {
        totalRecords: 0,
        recruitersList: [],
        categoryList: [],
        priorityList: [],
        casePriorityList: [],
        recordYearList: [],
        latestJobList: [],
        motivationList: [],
        caseStatusList: [],
        caseProgressList: [],
        caseStageList: [],
        flagList: [],
        profileIndicatorList: [],
        genderList: [],
        nativeLanguageList: [],
        fluentLanguageList: [],
        intermediateLanguageList: [],
        nationalityList: [],
        workPermitList: [],
        countryList: [],
        cvSourceList: [],
        companyList: [],
        companyLanguageList: [],
        companyProjectList: [],
        companyRoleList: [],
        profileStatusList: [],
        isLoading: false,
        isCompleteLoading: false,
        isAllCount: false,
        isShowAllCount: false,
        isloading1: false,
        isSuccess: false,
        isError: false,
        message: '',
        accountManagerList: [],
        recAdminList: [],
        cityList: [],
        visaList: [],
        careerList: [],
        domainList: [],
        industriesList: [],
        itSkillsList: [],
        jobskillsList: [],
        jobTitleList: [],
        pipelineList: [],
        desiredSalaryList: [],
        desiredLocationList: [],
        desiredBenefitsList: [],
        workingLocationgList: [],
        relocationAbroadList: [],
        jobCityList: [],
        jobCountryList: [],
        jobLocalityList: [],
        jobProjectList: [],
        jobLang1: [],
        jobLang2: [],
        educationList: [],
        canIdList: [],
        allRecordsList: [],
       
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isCompleteLoading = false;
            state.isShowAllCount = false;
            state.isAllCount = false;
            state.isloading1 = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            showAllRecordsCounts.pending, (state) => {
                state.isShowAllCount = true;
            });
        builder.addCase(
            showAllRecordsCounts.fulfilled, (state, { payload }) => {
                state.allRecordsList = payload.result
                state.isShowAllCount = false;
            });
        builder.addCase(
            showAllRecordsCounts.rejected, (state, action) => {
                state.isShowAllCount = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            totalRecordsCounts.pending, (state) => {
                state.isAllCount = true;
            });
        builder.addCase(
            totalRecordsCounts.fulfilled, (state, { payload }) => {
                state.totalRecords = payload.result
                state.isAllCount = false;
            });
        builder.addCase(
            totalRecordsCounts.rejected, (state, action) => {
                state.isAllCount = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            recruitersCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            recruitersCounts.fulfilled, (state, { payload }) => {
                state.recruitersList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            recruitersCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            recordYearCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            recordYearCounts.fulfilled, (state, { payload }) => {
                state.recordYearList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            recordYearCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            latestJobCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            latestJobCounts.fulfilled, (state, { payload }) => {
                state.latestJobList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            latestJobCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            motivationCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            motivationCount.fulfilled, (state, { payload }) => {
                state.motivationList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            motivationCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            casePriorityCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            casePriorityCount.fulfilled, (state, { payload }) => {
                state.casePriorityList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            casePriorityCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            priorityCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            priorityCount.fulfilled, (state, { payload }) => {
                state.priorityList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            priorityCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            categoryCounts.pending, (state) => {
                state.isLoading = true;
                state.isloading1 = true;
            });
        builder.addCase(
            categoryCounts.fulfilled, (state, { payload }) => {
                state.categoryList = payload.result
                state.isLoading = false;
                state.isloading1 = false;
            });
        builder.addCase(
            categoryCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isloading1 = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            caseStatusCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            caseStatusCounts.fulfilled, (state, { payload }) => {
                state.caseStatusList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            caseStatusCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            caseProgressCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            caseProgressCounts.fulfilled, (state, { payload }) => {
                state.caseProgressList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            caseProgressCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            caseStageCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            caseStageCounts.fulfilled, (state, { payload }) => {
                state.caseStageList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            caseStageCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            flagCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            flagCounts.fulfilled, (state, { payload }) => {
                state.flagList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            flagCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            profileIndicatorCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            profileIndicatorCounts.fulfilled, (state, { payload }) => {
                state.profileIndicatorList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            profileIndicatorCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            genderCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            genderCounts.fulfilled, (state, { payload }) => {
                state.genderList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            genderCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            nativeLanguageCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            nativeLanguageCounts.fulfilled, (state, { payload }) => {
                state.nativeLanguageList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            nativeLanguageCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            fluentLanguageCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            fluentLanguageCounts.fulfilled, (state, { payload }) => {
                state.fluentLanguageList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            fluentLanguageCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            intermediateLanguageCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            intermediateLanguageCounts.fulfilled, (state, { payload }) => {
                state.intermediateLanguageList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            intermediateLanguageCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            nationalityCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            nationalityCounts.fulfilled, (state, { payload }) => {
                state.nationalityList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            nationalityCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            workPermitCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            workPermitCounts.fulfilled, (state, { payload }) => {
                state.workPermitList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            workPermitCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            countryCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            countryCounts.fulfilled, (state, { payload }) => {
                state.countryList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            countryCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            cvSourceCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            cvSourceCounts.fulfilled, (state, { payload }) => {
                state.cvSourceList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            cvSourceCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            companyCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            companyCounts.fulfilled, (state, { payload }) => {
                state.companyList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            companyCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            companyLanguageCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            companyLanguageCounts.fulfilled, (state, { payload }) => {
                state.companyLanguageList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            companyLanguageCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            companyProjectCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            companyProjectCounts.fulfilled, (state, { payload }) => {
                state.companyProjectList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            companyProjectCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            companyRoleCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            companyRoleCounts.fulfilled, (state, { payload }) => {
                state.companyRoleList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            companyRoleCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            profileStatusCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            profileStatusCounts.fulfilled, (state, { payload }) => {
                state.profileStatusList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            profileStatusCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            accountMangerCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            accountMangerCounts.fulfilled, (state, { payload }) => {
                state.accountManagerList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            accountMangerCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });  

        builder.addCase(
            recAdminCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            recAdminCounts.fulfilled, (state, { payload }) => {
                state.recAdminList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            recAdminCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });    
        builder.addCase(
            cityCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            cityCounts.fulfilled, (state, { payload }) => {                
                state.cityList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            cityCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            }); 

        builder.addCase(
            visaCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            visaCounts.fulfilled, (state, { payload }) => {                
                state.visaList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            visaCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });  

        builder.addCase(
            careerCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            careerCounts.fulfilled, (state, { payload }) => {                
                state.careerList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            careerCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });    

        builder.addCase(
            domainCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            domainCounts.fulfilled, (state, { payload }) => {                
                state.domainList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            domainCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            }); 

        builder.addCase(
            jobTitleCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            jobTitleCounts.fulfilled, (state, { payload }) => {                
                state.jobTitleList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            jobTitleCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            }); 

        builder.addCase(
            industriesCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            industriesCounts.fulfilled, (state, { payload }) => {                
                state.industriesList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            industriesCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });  

        builder.addCase(
            itSkillsCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            itSkillsCounts.fulfilled, (state, { payload }) => {                
                state.itSkillsList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            itSkillsCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });    
        builder.addCase(
            jobSkillsCounts.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            jobSkillsCounts.fulfilled, (state, { payload }) => {                
                state.jobskillsList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            jobSkillsCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });  

        builder.addCase(
            pipelineCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            pipelineCount.fulfilled, (state, { payload }) => {                
                state.pipelineList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            pipelineCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });    

        builder.addCase(
            desiredSalaryCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            desiredSalaryCount.fulfilled, (state, { payload }) => {                
                state.desiredSalaryList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            desiredSalaryCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            }); 

        builder.addCase(
            desiredLocationCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            desiredLocationCount.fulfilled, (state, { payload }) => {                
                state.desiredLocationList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            desiredLocationCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            }); 

        builder.addCase(
            desiredBenefitsCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            desiredBenefitsCount.fulfilled, (state, { payload }) => {                
                state.desiredBenefitsList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            desiredBenefitsCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            }); 

        builder.addCase(
            workingLocationgCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            workingLocationgCount.fulfilled, (state, { payload }) => {                
                state.workingLocationgList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            workingLocationgCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            jobCityCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            jobCityCount.fulfilled, (state, { payload }) => {                
                state.jobCityList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            jobCityCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });    

        builder.addCase(
            jobCountryCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            jobCountryCount.fulfilled, (state, { payload }) => {                
                state.jobCountryList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            jobCountryCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });    

        builder.addCase(
            jobLocalityCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            jobLocalityCount.fulfilled, (state, { payload }) => {                
                state.jobLocalityList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            jobLocalityCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });    

        builder.addCase(
            jobProjectCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            jobProjectCount.fulfilled, (state, { payload }) => {                
                state.jobProjectList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            jobProjectCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });    
   
        builder.addCase(
            jobLanguage1Count.pending, (state) => {
                state.isLoading = true;
                state.isCompleteLoading = true;
            });
        builder.addCase(
            jobLanguage1Count.fulfilled, (state, { payload }) => {                
                state.jobLang1 = payload.result
                state.isLoading = false;
                state.isCompleteLoading = false;
            });
        builder.addCase(
            jobLanguage1Count.rejected, (state, action) => {
                state.isLoading = false;
                state.isCompleteLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });    

        builder.addCase(
            jobLanguage2Count.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            jobLanguage2Count.fulfilled, (state, { payload }) => {                
                state.jobLang2 = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            jobLanguage2Count.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });  

        builder.addCase(
            educationsCount.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            educationsCount.fulfilled, (state, { payload }) => {                
                state.educationList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            educationsCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            }); 

        builder.addCase(
            getCanIdCreateProject.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            getCanIdCreateProject.fulfilled, (state, { payload }) => {                
                state.canIdList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            getCanIdCreateProject.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });    
         
    }
});
export const { clearState } = SearchCVSlice.actions;
export default SearchCVSlice.reducer;