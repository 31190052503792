import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DeleteProjectModal from './DeleteProjectModal';
import EditProjectModal from './EditProjectModal';
import AddProjectModal from './AddProjectModal';
import "./teamprojects.scss";
import ReorderModal from './ReorderModal';

const TeamProjects = () => {
    return (
        <>
            <div className="dk-itTeamMain dk-itTeamProject">
                <div className="dk-itTeamHead">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-itHeadLeft">
                            <div className="dk-itLeftSearch">
                                <input type="text" placeholder="Search by Text" name="" id="" className="form-control" />
                            </div>
                        </div>
                        <h3 className="title">Projects List</h3>
                        <div className="dk-itHeadRight">
                            <ReorderModal />
                            <AddProjectModal />
                        </div>
                    </div>
                </div>

                <div className="dk-projectsTableMain">
                    <table className="table table-bordred">
                        <thead>
                            <tr>
                                <th>SNo.</th>
                                <th>Name of Projects</th>
                                <th>Login Type</th>
                                <th>Login URL</th>
                                <th>User Name</th>
                                <th>Password</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Dk Global Jobs</td>
                                <td>Jobseeker Standard</td>
                                <td>https://dkglobaljobs.com/</td>
                                <td>deepak123</td>
                                <td>123456</td>
                                <td>
                                    <div className="dk-action">
                                        <EditProjectModal />
                                        <DeleteProjectModal />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default TeamProjects;
