import React from 'react'
import AdPlan from '../../../jobs/livejobs/modal/ad_plan/AdPlan'
import  './adsplan.scss'

const AdsPlan = ({  jobId }) => {
  return (
    <>
      <div className='dkg-savedads-mainpageCon'>
        <AdPlan  jobId={jobId}/>
      </div>
    </>
  )
}

export default AdsPlan