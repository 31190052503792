import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { InputGroup, FormControl } from "react-bootstrap";
//import Dropdown from "react-bootstrap/Dropdown";
//import SimpleDropDwn from "../summary/SimpleDropDwn";
//import UserSearchFilter from "../summary/SearchInputDropDwn";
import AirDatepicker from "air-datepicker";
import en from "air-datepicker/locale/en";
import "air-datepicker/air-datepicker.css";
//import UserList from "../../../../../ui/userList/userListByRole";

import {
  clearState,
  updateJob,
  updateJobWithTextBox,
} from "../../../../../../slice/jobs/jobsSlice";

const Interviews = (jobId) => {
  const dispatch = useDispatch();
  // const {
  //   jobsCategoryList,
  //   jobsCompanyList,
  //   jobsProjectList,
  //   jobsRoleTypeList,
  //   jobsLanguageList,
  //   jobsLevelIdList,
  //   jobsLocalityList,
  //   jobsNoofJobsList,
  //   jobsCountryList,
  //   jobsCityList,
  //   jobsJobProfileList,
  //   jobsWorkPermitList,
  //   jobsWorkplaceList,
  //   jobsWorkdaysList,
  //   jobsShiftsWorkList,
  //   jobsContracttypeList,
  //   jobsSalarytypeList,
  //   jobsAvailabilityList,
  //   jobsOutsideofeuList,
  //   jobsFlightticketList,
  //   jobsTaxifromairportList,
  //   jobsAccommodationtList,
  //   jobsRelocationbudgetList,
  //   jobsExperiencetList,
  //   jobssInterviewsLit,
  //   jobsMethodInterviewsLit
  // } = useSelector((state) => state.common);

  const { isDetailsLoading, jobDetails, isUpdate } = useSelector(
    (state) => state.jobs
  );
  const [status, setStatus] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setData(jobDetails);
      setStatus(jobDetails.status);
    }
  }, [isDetailsLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobDetails);
    }
  }, [isUpdate]);
  let ids = data.id;

  // const JobStatus = (status) => {
  //   let bgColor = "";
  //   let txtColor = "";
  //   switch (status) {
  //     case "Normal":
  //       bgColor = "#30887e";
  //       txtColor = "#fff";
  //       break;

  //     case "Urgent":
  //       bgColor = "#dc5539";
  //       txtColor = "#fff";
  //       break;

  //     case "To qualify":
  //       bgColor = "#ffd966";
  //       txtColor = "#000";
  //       break;

  //     case "On Hold":
  //       bgColor = "#7f5347";
  //       txtColor = "#fff";
  //       break;

  //     case "Closed":
  //       bgColor = "#e2445c";
  //       txtColor = "#fff";
  //       break;
  //   }

  //   return (
  //     <>
  //       <Dropdown.Toggle
  //         variant=""
  //         className="dk-statusDrpDwnToggle"
  //         style={{ backgroundColor: bgColor, color: txtColor }}
  //       >
  //         {" "}
  //         {status}
  //       </Dropdown.Toggle>
  //     </>
  //   );
  // };

  const updateMyData = (e) => {
    let values = e.target.value;
    let columnId = e.target.dataset.columnid;
    dispatch(updateJob({ id: ids, key: columnId, value: values }));
  };
  // const updateStatus = (values) => {
  //   setStatus(values);
  //   dispatch(updateJob({ id: ids, key: "status", value: values }));
  // };

  new AirDatepicker("#date1", {
    position: "bottom center",
    locale: en,
    dateFormat: "d MMM yy",
    onSelect({ date, formattedDate, datepicker }) {
      dispatch(updateJob({ id: ids, key: "date1", value: formattedDate }));
    },
  });

  new AirDatepicker("#date2", {
    position: "bottom center",
    locale: en,
    dateFormat: "d MMM yy",
    onSelect({ date, formattedDate, datepicker }) {
      dispatch(updateJob({ id: ids, key: "date2", value: formattedDate }));
    },
  });

  let gross_value = "";
  if (data.gross_title === null) {
    gross_value = "Gross Salary PM";
  } else {
    if (data.gross_title === "Gross Salary PM") {
      gross_value = "Annual Gross";
    } else {
      gross_value = "Gross Salary PM";
    }
  }
  // const onItemClick = (id, columnId, value) => {
  //   dispatch(updateJob({ id, key: columnId, value }));
  // };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="col-md-12 dk-jobsuMMBottomSec pl-0">
            <div className=" row">
              <div className="col-md-12 pr-0 col-sm-12 dk-jobsuMMBottomBoxBg">
                <div className="dk-boxBody row m-0 mt-3 border-0">
                  <div className="col-md-6 pl-0">
                    <div className="dk-suMMformGroupCon mb-2">
                      {/* <label style={{ background: "none", width: "220px" }}>
                        Method
                      </label> */}
                      {/* <SimpleDropDwn categoryList={jobsMethodInterviewsLit} text={data.interview_method} columnId="interview_method"/> */}
                      {/* <input type="text" className="form-control" placeholder="" style={{border: "1px solid #ddd"}} /> */}
                      <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>1</label>
                      <input type="text" style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput"
                        defaultValue={data.hiringProcess_1}
                        onBlur={updateMyData}
                        data-columnid="hiringProcess_1"
                      />

                    </div>

                    <div className="dk-suMMformGroupCon mb-2">
                      {/* <label style={{ background: "none", width: "220px" }}>
                        Interview 1
                      </label>
                      <SimpleDropDwn categoryList={jobssInterviewsLit} text={data.interview_1} columnId="interview_1" />
                      <input type="text" className="form-control" placeholder="" style={{border: "1px solid #ddd"}} /> */}
                      <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>2</label>
                      <input type="text" style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput"
                        defaultValue={data.hiringProcess_2}
                        onBlur={updateMyData}
                        data-columnid="hiringProcess_2" />
                    </div>

                    <div className="dk-suMMformGroupCon mb-2">
                      {/* <label style={{ background: "none", width: "220px" }}>
                        Interview 2
                      </label>
                      <SimpleDropDwn categoryList={jobssInterviewsLit} text={data.interview_2} columnId="interview_2" />
                      <input type="text" className="form-control" placeholder="" style={{border: "1px solid #ddd"}} /> */}
                      <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>3</label>
                      <input type="text" style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput"
                        defaultValue={data.hiringProcess_3}
                        onBlur={updateMyData}
                        data-columnid="hiringProcess_3"
                      />
                    </div>

                    <div className="dk-suMMformGroupCon mb-2">
                      {/* <label style={{ background: "none", width: "220px" }}>
                        Interview 3
                      </label>
                      <SimpleDropDwn categoryList={jobssInterviewsLit} text={data.interview_3} columnId="interview_3" />
                      <input type="text" className="form-control" placeholder="" style={{border: "1px solid #ddd"}} /> */}
                      <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>4</label>
                      <input type="text" style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput"
                        defaultValue={data.hiringProcess_4}
                        onBlur={updateMyData}
                        data-columnid="hiringProcess_4"
                      />
                    </div>

                    <div className="dk-suMMformGroupCon mb-2">
                      {/* <label style={{ background: "none", width: "220px" }}>
                        Interview 4
                      </label>
                      <SimpleDropDwn categoryList={jobssInterviewsLit} text={data.interview_4} columnId="interview_4" />
                      <input type="text" className="form-control" placeholder="" style={{border: "1px solid #ddd"}} /> */}
                      <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>5</label>
                      <input type="text" style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput"
                        defaultValue={data.hiringProcess_5}
                        onBlur={updateMyData}
                        data-columnid="hiringProcess_5"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      {/* <label style={{ background: "none", width: "220px" }}>
                        Interview 5
                      </label>
                      <SimpleDropDwn categoryList={jobssInterviewsLit} text={data.interview_5} columnId="interview_5" />
                      <input type="text" className="form-control" placeholder="" style={{border: "1px solid #ddd"}} /> */}
                      <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>6</label>
                      <input type="text" style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput"
                        defaultValue={data.hiringProcess_6}
                        onBlur={updateMyData}
                        data-columnid="hiringProcess_6"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>7</label>
                      <input type="text" style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput"
                        defaultValue={data.hiringProcess_7}
                        onBlur={updateMyData}
                        data-columnid="hiringProcess_7"
                      />
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label className="dk-benfitslabel text-center" style={{ minWidth: "30px", padding: "8px" }}>8</label>
                      <input type="text" style={{ padding: "1.2rem 0.75rem", border: "1px solid #ccc" }} className="form-control dk-benfitsInput"
                        defaultValue={data.hiringProcess_8}
                        onBlur={updateMyData}
                        data-columnid="hiringProcess_8"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Interviews;
