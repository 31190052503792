import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData, DeleteData } from "../../services";

export const updatedataValue = createAsyncThunk(
    'companyClient/updatekeypair',
    async ({ id, key, value, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/relation/' + id, { key, value, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updatePortalsValue = createAsyncThunk(
    'companyClient/updatePortalskeypair',
    async ({ id, key, value, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/relationportal/' + id, { key, value, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateRelocationValue = createAsyncThunk(
    'companyClient/updateRelocationkeypair',
    async ({ id, key, value, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/relationrelocation/' + id, { key, value, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateJobofferValue = createAsyncThunk(
    'companyClient/updateJobofferkeypair',
    async ({ id, key, value, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/relationjoboffer/' + id, { key, value, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateInterviewValue = createAsyncThunk(
    'companyClient/updateInterviewkeypair',
    async ({ id, key, value, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/relationinterview/' + id, { key, value, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateSendoutValue = createAsyncThunk(
    'companyClient/updateSendoutkeypair',
    async ({ id, key, value, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/relationsendout/' + id, { key, value, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateRoleValue = createAsyncThunk(
    'companyClient/updateRolekeypair',
    async ({ id, key, value, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/relationrole/' + id, { key, value, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateLangValue = createAsyncThunk(
    'companyClient/updateLangkeypair',
    async ({ id, key, value, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/relationlang/' + id, { key, value, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


export const updateProjectValue = createAsyncThunk(
    'companyClient/updateProjectkeypair',
    async ({ id, key, value, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/relationproject/' + id, { key, value, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const allclientscomp = createAsyncThunk(
    'companyClient/allfetechByIdcompany',
    async ({ id, clientname, tableName }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/companyIdRelation/' + id + '/' + clientname + '/' + tableName)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const allclientsproject = createAsyncThunk(
    'companyClient/allfetechByIdproject',
    async ({ id, clientname, tableName }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/projectIdRelation/' + id + '/' + clientname + '/' + tableName)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const allclientsRoletype = createAsyncThunk(
    'companyClient/allfetechByIdrole',
    async ({ id, clientname, tableName }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/roleIdRelation/' + id + '/' + clientname + '/' + tableName)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const allclientsPortals = createAsyncThunk(
    'companyClient/allPortalsfetechById',
    async ({ id, clientname, tableName }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/portalRelation/' + id + '/' + clientname + '/' + tableName)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const allclientsRelocation = createAsyncThunk(
    'companyClient/allRelocationfetechById',
    async ({ id, clientname, tableName }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/relocationIdRelation/' + id + '/' + clientname + '/' + tableName)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const allclientsJobOffer = createAsyncThunk(
    'companyClient/allJobofferfetechById',
    async ({ id, clientname, tableName }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/jobofferIdRelation/' + id + '/' + clientname + '/' + tableName)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const allclientsInterview = createAsyncThunk(
    'companyClient/allInterviewfetechById',
    async ({ id, clientname, tableName }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/interviewIdRelation/' + id + '/' + clientname + '/' + tableName)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const allclientsSendout = createAsyncThunk(
    'companyClient/allSendoutfetechById',
    async ({ id, clientname, tableName }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/sendoutIdRelation/' + id + '/' + clientname + '/' + tableName)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const allclientsLanguge = createAsyncThunk(
    'companyClient/allfetechByIdLang',
    async ({ id, clientname, tableName }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/langIdRelation/' + id + '/' + clientname + '/' + tableName)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const clientscomp = createAsyncThunk(
    'companyClient/fetechByIdcompany',
    async ({ id, clientname }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/clientcomp/' + id + '/' + clientname)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const getCategories = createAsyncThunk(
    'script/allCategory',
    async (thunkAPI) => {
        const res = await GetData(true, '/requirnments-category/')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getdefaultSubCategories = createAsyncThunk(
    "script/frontend/demands/subcatlist",
    async ({ keys }, thunkAPI) => {
        const res = await GetData(true, "/requirnments-sub-category/default/" + keys);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getSubCategories = createAsyncThunk(
    'script/allSubCategory',
    async ({ categoryId, keys }, thunkAPI) => {
        const res = await GetData(true, '/requirnments-sub-category/' + categoryId + '/' + keys)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getSubCatDetails = createAsyncThunk(
    'script/detailsSubCategory',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/requirnments-sub-category/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


const usersSlice = createSlice({
    name: 'screeningForm',
    initialState: {
        companyRelationData: [],
        projectRelationData: [],
        roletypeRelationData: [],
        sendoutRelationData: [],
        interviewRelationData: [],
        jobfferRelationData: [],
        relocationRelationData: [],
        portalsRelationData: [],
        LangRelationData: [],
        clientAllData: [],
        categoryList: [],
        subCategoryList: [],
        subCategoryDetails: [],
        subDetails: [],
        isSuccess: false,
        isLoading: false,
        isSubLoading: false,
        isError: false,
        isData: false,
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSubCatInsert = false;
            state.isSuccess = false;
            state.isError = false;
            state.isData = false;
        }
    },
    extraReducers: {

        [getdefaultSubCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isTabActive = false;
        },
        [getdefaultSubCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isTabActive = true;
            state.isSuccess = true;
            state.subCategoryList = payload.result;
        },
        [getdefaultSubCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.isTabActive = false;
        },


        [allclientsLanguge.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [allclientsLanguge.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.LangRelationData = payload.result;
        },
        [allclientsLanguge.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [allclientsPortals.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [allclientsPortals.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.portalsRelationData = payload.result;
        },
        [allclientsPortals.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [allclientsRelocation.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [allclientsRelocation.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.relocationRelationData = payload.result;
        },
        [allclientsRelocation.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [allclientsJobOffer.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [allclientsJobOffer.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.jobfferRelationData = payload.result;
        },
        [allclientsJobOffer.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [allclientsInterview.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [allclientsInterview.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.interviewRelationData = payload.result;
        },
        [allclientsInterview.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [allclientsSendout.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [allclientsSendout.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.sendoutRelationData = payload.result;
        },
        [allclientsSendout.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [allclientsRoletype.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [allclientsRoletype.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.roletypeRelationData = payload.result;
        },
        [allclientsRoletype.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },


        [allclientsproject.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            // state.isData = true;
        },
        [allclientsproject.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.projectRelationData = payload.result;
        },
        [allclientsproject.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [allclientscomp.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            // state.isData = true;
        },
        [allclientscomp.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.companyRelationData = payload.result;
        },
        [allclientscomp.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateLangValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updateLangValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.LangRelationData = payload.result;
        },
        [updateLangValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateRelocationValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updateRelocationValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.relocationRelationData = payload.result;
        },
        [updateRelocationValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updatePortalsValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updatePortalsValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.portalsRelationData = payload.result;
        },
        [updatePortalsValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateJobofferValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updateJobofferValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.jobfferRelationData = payload.result;
        },
        [updateJobofferValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateInterviewValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updateInterviewValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.interviewRelationData = payload.result;
        },
        [updateInterviewValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },


        [updateSendoutValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updateSendoutValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.sendoutRelationData = payload.result;
        },
        [updateSendoutValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateRoleValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updateRoleValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.roletypeRelationData = payload.result;
        },
        [updateRoleValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateProjectValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updateProjectValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.projectRelationData = payload.result;
        },
        [updateProjectValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updatedataValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            // state.isData = true;
        },
        [updatedataValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.companyRelationData = payload.result;
        },
        [updatedataValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [clientscomp.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            // state.isData = true;
        },
        [clientscomp.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.clientAllData = payload.result;
        },
        [clientscomp.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [getCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            // state.isData = true;
        },
        [getCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.categoryList = payload.result;
        },
        [getCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [getSubCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getSubCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.subCategoryList = payload.result;
        },
        [getSubCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [getSubCatDetails.pending]: (state) => {
            state.isData = false;
            state.isError = false;
        },
        [getSubCatDetails.fulfilled]: (state, { payload }) => {
            state.isData = true;
            state.subDetails = payload.result;
        },
        [getSubCatDetails.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isData = false;
        }


    }
})
export const { clearState } = usersSlice.actions
export default usersSlice.reducer;