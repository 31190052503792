import React from "react"
import Dropdown from 'react-bootstrap/Dropdown';

const JobStatusList = ({ id, columnId, onItemClick }) => {
    const status = [
        {
            key: 'Active', value: 'Active', text: 'Active', Bgcolor: '#30887e',
        },

        {
            key: 'Inactive', value: 'Inactive', text: 'Inactive', Bgcolor: '#B55355',
        },
        {
            key: 'Qualify', value: 'Qualify', text: 'Qualify', Bgcolor: '#D58B5D',
        },
        {
            key: 'on hold', value: 'On Hold', text: 'On Hold', Bgcolor: '#7f5347',
        },
        {
            key: 'closed', value: 'Closed', text: 'Closed', Bgcolor: '#e2445c',
        }
    ]
    const selectValue = (value) => {
        onItemClick(id, columnId, value);
    }
    return (
        <React.Fragment>
            {
                status.map((filteredItems, index) =>
                    <Dropdown.Item key={filteredItems.key} href="#" style={{ backgroundColor: filteredItems.Bgcolor }} onClick={() => selectValue(filteredItems.value)}>
                        {filteredItems.value}
                    </Dropdown.Item>
                )
            }
        </React.Fragment>
    )
}
export default JobStatusList;