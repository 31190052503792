import { createSlice } from '@reduxjs/toolkit';

const moduleSlice = createSlice({
    name: "setModule",
    initialState: {
        myModule: '',
        moduleColor: ''
    },
    reducers: {
        setMyModule: (state, { payload }) => {
            state.myModule = payload.module;
            state.moduleColor = payload.color;
        },
        clearModule: (state) => {
            state.myModule = '';
            state.moduleColor = '';
        }
    }
});
export const { setMyModule, clearModule } = moduleSlice.actions;

export default moduleSlice.reducer;