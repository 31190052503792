import React from "react";
import { Link } from "react-router-dom";
// import './myacc-submenu.scss';

const MyProfileSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    return (
        <React.Fragment>
            <ul className="dkg-myacc-submenu-ul">
                <li>
                    <Link to="/js-account/my-profile/summary" className={`${(segment3 === 'summary') ? 'grey-active' : ''}`}>Summary</Link>
                </li>
                <li>
                    <Link to="/js-account/my-profile/contact" className={`${(segment3 === 'contact') ? 'grey-active' : ''}`}>Contact Info</Link>
                </li>
                <li>
                    <Link to="/js-account/my-profile/profile" className={`${(segment3 === 'profile') ? 'grey-active' : ''}`}>Profile Info</Link>
                </li>
                <li className="pbp">
                    <Link to="/js-account/my-profile/resume" className={`${(segment3 === 'resume') ? 'grey-active' : ''}`}>Desired Job</Link>
                </li> 
                <li className="psp">
                    <Link to="/js-account/my-profile/cover-letter" className={`${(segment3 === 'cover-letter') ? 'grey-active' : ''}`}>My Resume</Link>
                </li>
                <li className="psp">
                    <Link to="/js-account/my-profile/my-documents" className={`${(segment3 === 'my-documents') ? 'grey-active' : ''}`}>My Documents</Link>
                </li>
            </ul>
        </React.Fragment>
    );
}

export default MyProfileSubmenu;
