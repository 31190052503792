// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
import React, { useEffect, useState } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import Dropdown from 'react-bootstrap/Dropdown'
import './selectdropdwn.scss';


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        style={{ ref }}
    >
        {children}
    </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy, props }, ref) => {
        const [value, setValue] = useState('');

        useEffect(() => {
            const timer = window.setInterval(() => {
                setValue('')
            }, 10000);
            return () => { // Return callback to run on unmount.
                window.clearInterval(timer);
            };
        }, [])

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className=" w-100"
                    placeholder="Search Text..."
                    onChange={(e) => setValue(e.target.value.toLowerCase().trim())}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);

const SelectDropDown = (props) => {
    return (
        <>
            <div className="dk-suMMCaseStatusDropDwn">
                <Dropdown className={`dk-candsMMToggleBtn ${props.class}`} style={{ backgroundColor: props.statusBG, borderColor: props.statusBG }}>
                    <Dropdown.Toggle as={CustomToggle} style={{ backgroundColor: props.statusBG, borderColor: props.statusBG }}>
                        {props.txt}
                    </Dropdown.Toggle>
                    {/* <Dropdown.Menu as={CustomMenu} className="dk-progress-stageMenu"> */}
                    <Dropdown.Menu className="dk-progress-stageMenu">
                        {
                            props.list.filter(statusItem => statusItem.bgColor === props.statusBG).map((filteredItems, index) =>
                                <Dropdown.Item className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" style={{ backgroundColor: '#FFF', color: '#333', borderBottom: '1px solid #ddd' }} data-id={props.candidateId} data-value={filteredItems.value} data-key={props.column} data-refreshtype={props.refreshType} onClick={props.updateProfile}>{filteredItems.value}</Dropdown.Item>
                            )
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}
export default SelectDropDown;
