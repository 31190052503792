import React from 'react'

const ExpCard = (props) => {
    return (
        <>
            <div className="dk-expCard">
                <span className="jobSn">{props.stepCounter}</span>
                <div className="dk-viewtable">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="dk-viewExpbox">
                                <div className="Exptitle">{props.jobTitle}</div>
                                <table className="table">
                                    <tbody><tr>
                                        <td><i className="fa fa-user"></i> Employer</td>
                                        <td>:</td>
                                        <td>Karmatech Media works Pvt. Ltd</td>
                                    </tr>
                                        <tr>
                                            <td><i className="fa fa-map-marker"></i> Location</td>
                                            <td>:</td>
                                            <td>New Delhi,New delhi, India</td>
                                        </tr>
                                        <tr>
                                            <td><i className="fa fa-industry"></i> Industry</td>
                                            <td>:</td>
                                            <td>May 2016 to Till</td>
                                        </tr>
                                        <tr>
                                            <td><i className="fa fa-calendar"></i> Start Date</td>
                                            <td>:</td>
                                            <td>01 May 2016</td>
                                        </tr>
                                        <tr>
                                            <td><i className="fa fa-calendar"></i> End Date</td>
                                            <td>:</td>
                                            <td>05 Jan 2021</td>
                                        </tr>
                                    </tbody></table>
                            </div>
                        </div>

                        <div className="col-md-12 col-12">
                            <div className="dk-viewExpbox br-none">
                                <div className="Exptitle right" style={{fontWeight: "normal"}}>Job Responsibilities</div>
                                <ul className="mb-0" style={{paddingLeft: "20px"}}>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
                                </ul>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpCard;
