import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './holidaysmenu.scss';

const ItTeamMainMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-holidaysNavbar">
                <Route>
                    <ul>
                        <li><Link to="/hr/dashboard" className={`${(segment2 === 'dashboard') ? 'active' : ''}`}>Dashboard</Link></li>
                        {/* <li><Link to="#/hr/templates" className={`${pathname.match('/templates') ? 'active' : ''}`}>HR 2</Link></li> */}
                        <li><Link to="#/hr/titles" className={`${pathname.match('/titles') ? 'active' : ''}`}> Highlights</Link></li>
                        <li><Link to="#/hr/saved-ads" className={(segment2 === "saved-ads") ? 'active' : ''}>Company</Link></li>
                        <li><Link to="/hr/employee/dkg-team" className={`${(segment2 === 'employee') ? 'active' : ''}`}>Employee</Link></li>
                        <li><Link to="#/hr/ads-credits" className={`${pathname.match('/ads-credits') ? 'active' : ''}`}>New Hire</Link></li>
                        <li><Link to="#/hr/live-tracker" className={`${pathname.match('/live-tracker') ? 'active' : ''}`}>Contracts</Link></li>
                        <li><Link to="#/hr/campaigns/live" className={(segment2 === "campaigns") ? 'active' : ''}>Appraisals</Link></li>
                        <li><Link to="/hr/holidays" className={`${(segment2 === 'holidays') ? 'active' : ''}`}>Leaves</Link></li>
                        <li><Link to="#/hr/apps-tracker/daily-tracker" className={`${pathname.match('/apps-tracker') ? 'active' : ''}`}>Handbook</Link></li>
                        <li><Link to="#/hr/apps-tracker/daily-tracker" className={`${pathname.match('/apps-tracker') ? 'active' : ''}`}>Compliance</Link></li>
                        <li><Link to="#/hr/training" className={(segment2 === "training") ? 'active' : ''}>Training</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment >
    );
}

export default ItTeamMainMenu;