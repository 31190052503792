import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData,DeleteData } from "../../../services";

export const getList = createAsyncThunk(
    'account/joined/list',
    async ({year},thunkAPI) => {
        const res = await GetData(true, '/accounting/joined/'+year)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const deleteFunction = createAsyncThunk(
    'account/joined/count/delete',
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, '/accounting/joined/'+id )
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateByKey = createAsyncThunk(
    'account/joined/update/key-value',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/accounting/joined/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const detailsFunction = createAsyncThunk(
    'account/joined/task/details',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/accounting/joined/detail/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const addNewTask = createAsyncThunk(
    'account/joined/add-new',
    async ({ caseId, jobId, year, month, accountManager, recruiters, company,language, candidateName, caseStatus, caseStage,progress, invoiceNo, project, roleType, cvSource}, thunkAPI) => {
        const res = await PostData(true, '/accounting/joined', { caseId, jobId, year, month, accountManager, recruiters, company,language, candidateName, caseStatus,caseStage, progress, invoiceNo, project, roleType, cvSource})
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const updateDetails = createAsyncThunk(
    'account/joined/update',
    async ({ id, caseId, jobId, year, month, accountManager, recruiters, company, language, candidateName, caseStatus, caseStage, progress, invoiceNo, project, roleType, cvSource }, thunkAPI) => {
        const res = await PutData(true, '/accounting/joined/' + id, { caseId, jobId, year, month, accountManager, recruiters, company, language, candidateName, caseStatus, caseStage, progress, invoiceNo, project, roleType, cvSource })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateAccountJoinedCommentCount = createAsyncThunk(
    'account/joined/commentlist/update-comment',
    async ({ id, value, commentAt }, thunkAPI) => {
        const res = await PutData(true, '/accounting/joined/update-comment/' + id, { value, commentAt })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

//end of  calling slice

const listSlice = createSlice({
    name: 'accountJoined',
    initialState: {
        salesBookList: [],
        paidsalesBookList: [],
        taskDataSummaryList: [],
        taskTypeList: [],
        taskPaymentMonthList: [],
        taskPayTypeList: [],
        countsProgress: [],
        paymentDataCount: [],
        paidPaymentDataCount: [],
        tasksDetails: {},
        countAll: 0,
        isLoading: false,
        isDetailLoading: false,
        isTaskInsert: false,
        isTaskUpdate: false,
        isTaskDelete: false,
        isUpdate: false,
        iscommentCountUpdate: false,
        isError: false,
        isSuccess: false,
        isDataMoveToPayment: false,
        isTaskInserted: false,
        isMoved: false,
        isArchive: false,
        message: ''
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isDetailLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.issSuccess = false;
            state.isDataMoveToPayment = false;
            state.isTaskInserted = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },
        clearTaskState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.isTaskInserted = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },
        clearDetails: (state) => {
            state.tasksDetails = {}
        }
    },
    extraReducers: (builder) => {
    
        builder.addCase(
            getList.pending, (state) => {
                state.isLoading = true;
                state.issSuccess = false;
            });
        builder.addCase(
            getList.fulfilled, (state, { payload }) => {
                state.salesBookList = payload.result
                state.isLoading = false;
                state.issSuccess = true;
            });
        builder.addCase(
            getList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

       

        builder.addCase(
            addNewTask.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            addNewTask.fulfilled, (state, { payload }) => {
               // state.salesBookList.unshift(payload.result)
             //  state.salesBookList=payload.result;
                state.isLoading = false;
                state.isSuccess = true;
                state.isTaskInserted = true;
            });
        builder.addCase(
            addNewTask.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });


        builder.addCase(
            updateDetails.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            updateDetails.fulfilled, (state, { payload }) => {
                let mainArray = state.salesBookList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result[0].id) {
                        return (payload.result[0])
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.isLoading = false;
                state.salesBookList = finalResult;
            });
        builder.addCase(
            updateDetails.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            updateByKey.pending, (state) => {
                state.isUpdate = false
                state.isLoading = false;
            });
        builder.addCase(
            updateByKey.fulfilled, (state, { payload }) => {
                let mainArray = state.salesBookList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.salesBookList = finalResult;
                state.message = payload.message
            });
        builder.addCase(
            updateByKey.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
      

        builder.addCase(
            deleteFunction.pending, (state) => {
            });
        builder.addCase(
            deleteFunction.fulfilled, (state, { payload }) => {
                state.isTaskDelete = true;
                state.isMoved = true;
            });
        builder.addCase(
            deleteFunction.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateAccountJoinedCommentCount.pending, (state) => {
                state.iscommentCountUpdate = false
            });
        builder.addCase(
            updateAccountJoinedCommentCount.fulfilled, (state, action) => {
                let mainArray = state.salesBookList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === action.meta.arg.id) {
                        return ({ ...r, commentCount: action.meta.arg.value, commentAt: action.meta.arg.commentAt })
                    } else {
                        return r
                    }
                })
                state.salesBookList = finalResult

                let mainArrayPaid = state.paidsalesBookList;
                let finalResultPaid = mainArrayPaid.map((r) => {
                    if (r.id === action.meta.arg.id) {
                        return ({ ...r, commentCount: action.meta.arg.value, commentAt: action.meta.arg.commentAt })
                    } else {
                        return r
                    }
                })
                state.paidsalesBookList = finalResultPaid
                state.iscommentCountUpdate = true
            });
        builder.addCase(
            updateAccountJoinedCommentCount.rejected, (state, action) => {
                state.isError = true;
            });

       
        builder.addCase(
            detailsFunction.pending, (state) => {
                state.isDetailLoading = true
            });
        builder.addCase(
            detailsFunction.fulfilled, (state, { payload }) => {
                state.isDetailLoading = false
                state.tasksDetails = payload.result;
            });
        builder.addCase(
            detailsFunction.rejected, (state, action) => {
                state.isDetailLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

      
    }
});


export const { clearState, clearTaskState, clearDetails } = listSlice.actions;
export default listSlice.reducer;