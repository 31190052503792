import React from "react";
import { Route, Link } from "react-router-dom";

const SummaySubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    const segment4 = pathname.split("/")[4];
    const lastSegment = window.location.href.split("/").pop(); //for decode use atob("zyz")
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        {/* <li><Link to="/accounting/businessoverview/2022/summary" className={`${(segment4 == 'summary') ? 'active' : ''}`}>Summary</Link></li> */}
                        <li><Link to={`/accounting/businessoverview/qualified/` + atob(lastSegment) + '/' + lastSegment} className={(segment3 == 'qualified') ? 'active' : ''}>Qualified</Link></li>
                        <li><Link to={`/accounting/businessoverview/interviews/` + atob(lastSegment) + '/' + lastSegment} className={(segment3 == 'interviews') ? 'active' : ''}>Interviews</Link></li>
                        <li><Link to={`/accounting/businessoverview/job-offers/` + atob(lastSegment) + '/' + lastSegment} className={`${(segment3 == 'job-offers') ? 'active' : ''}`}>Job Offers</Link></li>
                        <li><Link to={`/accounting/businessoverview/joined/` + atob(lastSegment) + '/' + lastSegment} className={(segment3 == 'joined') ? 'active' : ''}>Joined</Link></li>
                        <li><Link to={`/accounting/businessoverview/final-fee/` + atob(lastSegment) + '/' + lastSegment} className={(segment3 == 'final-fee') ? 'active' : ''}>Final Fee</Link></li>

                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default SummaySubmenu;
