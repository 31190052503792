import React from 'react';
import { Link } from "react-router-dom";

const LuizaProjectLiveLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="dk-moduleTraining-leftPanel">
                <ul>
                    <li><Link to="/projects/luiza/live/summary" className={`${pathname.match('/projects/luiza/live/summary') ? 'active' : ''}`}>Summary</Link></li>
                    <li><Link to="/projects/luiza/live/project-1" className={`${pathname.match('/projects/luiza/live/project-1') ? 'active' : ''}`}>Pragmatic</Link></li>
                    <li><Link to="/projects/luiza/live/project-2" className={`${pathname.match('/projects/luiza/live/project-2') ? 'active' : ''}`}>IT Roles</Link></li>
                </ul>
            </div>
        </>
    )
}

export default LuizaProjectLiveLeftPanel;