import React, { useEffect } from "react";
import { projectlist, clearState } from '../../../../../slice/projects/projctsSlice';
import { useDispatch, useSelector } from "react-redux";
import { showError, clearMessage } from '../../../../../slice/utils/message/messageSlice'
import { BasicTable } from "./BasicTable";

const Projects = () => {
    const dispatch = useDispatch();
    const { projectList } = useSelector(state => state.projects)   
    
    useEffect(() => {
        dispatch(clearMessage())
        dispatch(clearState())
    }, [])

    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(projectlist({}))
            } catch (err) {
                dispatch(showError({ msg: err }))
            }
        }
        fetchData()
    }, [dispatch])
    return (
        <React.Fragment>
            {
                (projectList && projectList.length > -1) ? <BasicTable /> : null
            }
        </React.Fragment>
    )
}
export default Projects;