import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './paymentnavbar.scss';

const ItTeamMainMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-paymentsNavbar">
                <Route>
                    <ul>
                        <li><Link to="/payments/summary" className={`${(segment2 === 'summary') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="#/payments/templates" className={`${pathname.match('/templates') ? 'active' : ''}`}><span className="dk-badge">0</span> Tasks</Link></li>
                        <li><Link to="#/payments/ads-resources" className={`${pathname.match('/ads-resources') ? 'active' : ''}`}><span className="dk-badge">0</span> Alerts</Link></li>
                        <li><Link to="/payments/invoices/job-board" className={(segment2 === "invoices") ? 'gray-active' : ''}> Invoices</Link></li>
                        <li><Link to="#/payments/saved-ads" className={(segment2 === "saved-ads") ? 'active' : ''}>Salaries</Link></li>
                        <li><Link to="#/payments/todays-tracker" className={`${pathname.match('/todays-tracker') ? 'active' : ''}`}>Commission</Link></li>
                        <li><Link to="#/payments/ads-credits" className={`${pathname.match('/ads-credits') ? 'active' : ''}`}>Cashflow</Link></li>
                        <li><Link to="#/payments/live-tracker" className={`${pathname.match('/live-tracker') ? 'active' : ''}`}>Overview</Link></li>
                        <li><Link to="#/payments/campaigns/live" className={(segment2 === "campaigns") ? 'active' : ''}>Payments</Link></li>
                        <li><Link to="#/payments/apps-tracker/daily-tracker" className={`${pathname.match('/apps-tracker') ? 'active' : ''}`}>Renewals</Link></li>
                        <li><Link to="/payments/training/overview" className={(segment2 === "training") ? 'gray-active' : ''}>Training</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment >
    );
}

export default ItTeamMainMenu;