import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import '../../dropdown/workpanrecfilter.scss'
import "../../../../../assets/scss/tooltip.scss";
import RecruiterDefult from '../../../../../assets/images/default_profile.png';

const TASKFORFilter = ({ list, id, img, selectUserFilter }) => {
    const [userList, setUserList] = useState([]);
    const [userCount, setUsersCount] = useState([]);
    useEffect(() => {
        const result = [];
        const map = new Map();
        for (const item of list) {           
            for (const dataItem of item.original.taskForDetail) {             
                if (!map.has(dataItem.forDetail.uid)) {
                    map.set(dataItem.forDetail.uid, true);
                    result.push({
                        id: dataItem.forDetail.uid,
                        name: dataItem.forDetail.firstName,
                        userImg: dataItem.forDetail.profileImage,
                        count: list.filter(function (val) {
                            for (const getTaskForData of val.original.taskForDetail) {                              
                                return getTaskForData.forDetail.uid === dataItem.forDetail.uid;
                            }
                        }).length
                    });
                }
            }

        }
        result.sort(function (a, b) {
            return b.count - a.count;
        });      
        setUserList(result)
    }, [list])

    
    return (
        <React.Fragment>
            <Dropdown className='dk-workplanrecDropdown'>
                <Dropdown.Toggle variant="" className="dk-workplanrecDropdown-btn">
                    {
                        (id === '0') ? <span className='workplanrecimg'>FOR</span> : <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + img} />
                    }
                    <span className='dk-toogleFilter'>Task For</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dk-workplanrecDropdownMenu">
                    <div className="d-flex">
                        {
                            userList && userList.map((item, index) =>
                                <Dropdown.Item href="#">
                                    <div className="tooltipMain" data-type="TASKFOR" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} className="filterImg" alt={item.name} data-id={item.id} data-type="TASKFOR" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                       
                                    <span className="dk-taskCount" data-type="TASKFOR" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                        {item.count}
                                    </span>
                                        <div className="tooltipText">{item.name}</div>
                                    </div>
                                </Dropdown.Item>
                            )
                        }
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    )
}
export default TASKFORFilter;