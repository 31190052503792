import React from 'react';
import './casestatus.scss';

const CaseStatusDropDwn = ({ caseStatus, value }) => {
    let bgColor = "";
    switch (caseStatus) {
        
        case 'TBS':
            bgColor = '#d58b5d'
            break;       
        case 'Scheduled':
            bgColor = '#d58b5d'
            break;       
        case 'No Open Job':
            bgColor = '#da3d5e'
            break;               
        case 'Failed Assessment':
            bgColor = '#da3d5e'
            break;   
        case 'Rejected by Client':
            bgColor = '#da3d5e'
            break;           
        case 'Candidate Withdraw':
            bgColor = '#da3d5e'
            break;           
        case 'Not Responsding':
            bgColor = '#da3d5e'
            break;           
        case 'Project is Filled':
            bgColor = '#da3d5e'
            break;           
        case 'Project is Cancelled':
            bgColor = '#da3d5e'
            break;       
        case 'Job Offer':
            bgColor = '#316a67'
            break;       

        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor }}>
                {
                    (value == "Blank") ? "" : value
                }
            </div>
        </>
    )
}

export default CaseStatusDropDwn;