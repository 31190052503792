import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import Constant from "../../../../constants";
import "./monthlytable.css";

const TRACKER_JOB_BOARD_ELJ = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.ELJ;
const TRACKER_JOB_BOARD_MLV = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.MLV;
const TRACKER_JOB_BOARD_HIPO = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.HIPO;
const TRACKER_JOB_BOARD_DK_WEBSITE = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.DK_WEBSITE;
const TRACKER_JOB_BOARD_LINKEDIN = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.LINKEDIN;
const TRACKER_JOB_BOARD_INDEED = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.INDEED;
const TRACKER_JOB_BOARD_EJOBS = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.EJOBS;
const TRACKER_JOB_BOARD_FACEBOOK = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.FACEBOOK;
const TRACKER_JOB_BOARD_DIRECT_EMAIL = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.DIRECT_EMAIL;
const TRACKER_JOB_BOARD_LEARN4GOOD = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.LEARN4GOOD;
const TRACKER_JOB_BOARD_EUROJOBS = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.EUROJOBS;
const TRACKER_JOB_BOARD_CAREERS4A = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.CAREERS4A;
const TRACKER_JOB_BOARD_JOBFIND = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.JOBFIND;
const TRACKER_JOB_BOARD_BAKECA = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.BAKECA;
const TRACKER_JOB_BOARD_BESTJOBS = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.BESTJOBS;
const TRACKER_JOB_BOARD_PROFESIA_CZ = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.PROFESIA_CZ;
const TRACKER_JOB_BOARD_PROFESIA_SK = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.PROFESIA_SK;
const TRACKER_JOB_BOARD_JOOBLE = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.JOOBLE;
const TRACKER_JOB_BOARD_NET_EMPREGOS = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.NET_EMPREGOS;
const TRACKER_JOB_BOARD_PRACUJ_PL = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.PRACUJ_PL;
const TRACKER_JOB_BOARD_PURE_CHAT = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.PURE_CHAT;
const TRACKER_JOB_BOARD_CAREER_BUILDER = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.CAREER_BUILDER;
const TRACKER_JOB_BOARD_3_MIN_JOB = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.MIN_JOB;
const TRACKER_JOB_BOARD_SOF_TEST = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.SOF_TEST;

const MonthlyappsTable = () => {

    const dispatch = useDispatch();
    const { appsTrackerMonthly } = useSelector(state => state.appsTracker)

    const monthlyTrackerFunction = (array, jobBoard, month) => {
        let mainArray = array;
        let finalResult = mainArray.map((r) => {
            if (r.jobBoard === jobBoard && r.month === month && r.year === '2022') {
                return ({ val: r.value, id: r._id, month: r.month })
            } else {
                return ({ val: null, id: null, month: null })
            }
        })

        let non_zero = finalResult.filter((item) => item.val !== null);
        if (non_zero.length > 0) {
            return <td>{non_zero.reduce((a, b) => +a + +b.val, 0)}</td>
        } else {
            return <td></td>
        }
    }

    const monthlyTrackerFooterFum = (array, month) => {
        if (month == "") {
            let mainArray = array.filter((item) => item.month != '')
            return mainArray.reduce((a, b) => +a + +b.value, 0);
        } else {
            let mainArray = array.filter((item) => item.month === month)
            return mainArray.reduce((a, b) => +a + +b.value, 0);
        }
    }

    const monthlyTrackerSideFun = (array, jobBoard) => {
        let mainArray = array.filter((item) => item.jobBoard === jobBoard)
        return mainArray.reduce((a, b) => +a + +b.value, 0);
    }

    const searchFunction = (e) => {
        var value = (e.target.value).toLowerCase();
        $("#filterTracker tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    }

    return (
        <>
            <div className="dk-MonthlyTableCont table-responsive">
                <table className="table dk-MonthlyTable">
                    <thead>
                        <tr>
                            <th>
                                <div className="dk-appstrackTableSearch">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search..." onChange={searchFunction} />
                                        <div className="input-group-append">
                                            <button className="btn btn-secondary" type="button">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </th>
                            <th>Jan</th>
                            <th>Feb</th>
                            <th>Mar</th>
                            <th>Apr</th>
                            <th>May</th>
                            <th>Jun</th>
                            <th>Jul</th>
                            <th>Aug</th>
                            <th>Sep</th>
                            <th>Oct</th>
                            <th>Nov</th>
                            <th>Dec</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody id="filterTracker">
                        <tr>
                            <td>ELJ</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_ELJ, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_ELJ, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_ELJ, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_ELJ, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_ELJ, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_ELJ, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_ELJ, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_ELJ, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_ELJ, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_ELJ, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_ELJ, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_ELJ, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_ELJ)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>MLV</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_MLV, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_MLV, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_MLV, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_MLV, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_MLV, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_MLV, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_MLV, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_MLV, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_MLV, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_MLV, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_MLV, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_MLV, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_MLV)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>HIPO</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_HIPO, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_HIPO, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_HIPO, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_HIPO, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_HIPO, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_HIPO, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_HIPO, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_HIPO, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_HIPO, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_HIPO, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_HIPO, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_HIPO, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_HIPO)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>DK Website</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DK_WEBSITE, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DK_WEBSITE, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_DK_WEBSITE)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Linkedln</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LINKEDIN, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LINKEDIN, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LINKEDIN, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LINKEDIN, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LINKEDIN, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LINKEDIN, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LINKEDIN, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LINKEDIN, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LINKEDIN, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LINKEDIN, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LINKEDIN, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LINKEDIN, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_LINKEDIN)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Indeed</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_INDEED, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_INDEED, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_INDEED, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_INDEED, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_INDEED, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_INDEED, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_INDEED, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_INDEED, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_INDEED, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_INDEED, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_INDEED, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_INDEED, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_INDEED)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Ejobs</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EJOBS, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EJOBS, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EJOBS, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EJOBS, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EJOBS, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EJOBS, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EJOBS, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EJOBS, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EJOBS, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EJOBS, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EJOBS, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EJOBS, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_EJOBS)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Facebook</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_FACEBOOK, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_FACEBOOK, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_FACEBOOK, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_FACEBOOK, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_FACEBOOK, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_FACEBOOK, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_FACEBOOK, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_FACEBOOK, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_FACEBOOK, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_FACEBOOK, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_FACEBOOK, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_FACEBOOK, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_FACEBOOK)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Direct Email</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_DIRECT_EMAIL, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_DIRECT_EMAIL)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Learn4Good</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LEARN4GOOD, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_LEARN4GOOD, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_LEARN4GOOD)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Eurojobs</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EUROJOBS, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EUROJOBS, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EUROJOBS, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EUROJOBS, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EUROJOBS, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EUROJOBS, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EUROJOBS, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EUROJOBS, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EUROJOBS, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EUROJOBS, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EUROJOBS, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_EUROJOBS, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_EUROJOBS)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Careers4A</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREERS4A, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREERS4A, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREERS4A, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREERS4A, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREERS4A, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREERS4A, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREERS4A, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREERS4A, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREERS4A, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREERS4A, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREERS4A, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREERS4A, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREERS4A)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Jobfind</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOBFIND, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOBFIND, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOBFIND, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOBFIND, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOBFIND, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOBFIND, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOBFIND, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOBFIND, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOBFIND, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOBFIND, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOBFIND, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOBFIND, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_JOBFIND)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Bakeca</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BAKECA, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BAKECA, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BAKECA, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BAKECA, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BAKECA, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BAKECA, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BAKECA, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BAKECA, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BAKECA, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BAKECA, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BAKECA, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BAKECA, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_BAKECA)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>BestJobs</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BESTJOBS, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BESTJOBS, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BESTJOBS, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BESTJOBS, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BESTJOBS, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BESTJOBS, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BESTJOBS, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BESTJOBS, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BESTJOBS, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BESTJOBS, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BESTJOBS, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_BESTJOBS, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_BESTJOBS)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Profesia.cz</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_CZ, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_CZ)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Profesia.sk</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_SK, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_SK, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_PROFESIA_SK)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Jooble</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOOBLE, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOOBLE, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOOBLE, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOOBLE, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOOBLE, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOOBLE, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOOBLE, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOOBLE, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOOBLE, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOOBLE, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOOBLE, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_JOOBLE, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_JOOBLE)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>NetEmpregos</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_NET_EMPREGOS, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_NET_EMPREGOS)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Pracuj.pl</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PRACUJ_PL, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PRACUJ_PL, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_PRACUJ_PL)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Purechat</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PURE_CHAT, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PURE_CHAT, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PURE_CHAT, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PURE_CHAT, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PURE_CHAT, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PURE_CHAT, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PURE_CHAT, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PURE_CHAT, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PURE_CHAT, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PURE_CHAT, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PURE_CHAT, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_PURE_CHAT, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_PURE_CHAT)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Careerbuilder</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREER_BUILDER, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_CAREER_BUILDER)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>3min job</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_3_MIN_JOB, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_3_MIN_JOB, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_3_MIN_JOB)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Softest</td>
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_SOF_TEST, 'Jan')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_SOF_TEST, 'Feb')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_SOF_TEST, 'Mar')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_SOF_TEST, 'Apr')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_SOF_TEST, 'May')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_SOF_TEST, 'Jun')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_SOF_TEST, 'Jul')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_SOF_TEST, 'Aug')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_SOF_TEST, 'Sep')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_SOF_TEST, 'Oct')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_SOF_TEST, 'Nov')
                            }
                            {
                                monthlyTrackerFunction(appsTrackerMonthly, TRACKER_JOB_BOARD_SOF_TEST, 'Dec')
                            }
                            <td>
                                {
                                    monthlyTrackerSideFun(appsTrackerMonthly, TRACKER_JOB_BOARD_SOF_TEST)
                                }
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>
                                {
                                    monthlyTrackerFooterFum(appsTrackerMonthly, 'Jan')
                                }
                            </td>
                            <td>
                                {
                                    monthlyTrackerFooterFum(appsTrackerMonthly, 'Feb')
                                }
                            </td>
                            <td>
                                {
                                    monthlyTrackerFooterFum(appsTrackerMonthly, 'Mar')
                                }
                            </td>
                            <td>
                                {
                                    monthlyTrackerFooterFum(appsTrackerMonthly, 'Apr')
                                }
                            </td>
                            <td>
                                {
                                    monthlyTrackerFooterFum(appsTrackerMonthly, 'May')
                                }
                            </td>
                            <td>
                                {
                                    monthlyTrackerFooterFum(appsTrackerMonthly, 'Jun')
                                }
                            </td>
                            <td>
                                {
                                    monthlyTrackerFooterFum(appsTrackerMonthly, 'Jul')
                                }
                            </td>
                            <td>
                                {
                                    monthlyTrackerFooterFum(appsTrackerMonthly, 'Aug')
                                }
                            </td>
                            <td>
                                {
                                    monthlyTrackerFooterFum(appsTrackerMonthly, 'Sep')
                                }
                            </td>
                            <td>
                                {
                                    monthlyTrackerFooterFum(appsTrackerMonthly, 'Oct')
                                }
                            </td>
                            <td>
                                {
                                    monthlyTrackerFooterFum(appsTrackerMonthly, 'Nov')
                                }
                            </td>
                            <td>
                                {
                                    monthlyTrackerFooterFum(appsTrackerMonthly, 'Dec')
                                }
                            </td>
                            <td>
                                {
                                    monthlyTrackerFooterFum(appsTrackerMonthly, '')
                                }
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    )
}

export default MonthlyappsTable;
