import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Modal, Dropdown } from 'react-bootstrap';
import "./addnewres.scss"


const AddnewModal = ({ onHide }) => {

    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-addNewResModalDialog modal-40w dkg-clientinfo-RightModal"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton className="dk-resourcePopupHedaer">
                    <Modal.Title id="example-custom-modal-styling-title">
                        Add New Company
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Status</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" style={{ backgroundColor: "#fff", color: "#797474" }}>
                                                Select
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#" style={{ backgroundColor: "#31a886", color: "#fff" }}>Active</Dropdown.Item>
                                                <Dropdown.Item href="#" style={{ backgroundColor: "#d8345f", color: "#fff" }}>Inactive</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Name of Client</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="">
                                                Select
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">Client 1</Dropdown.Item>
                                                <Dropdown.Item href="#">Client 2</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Country</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="">
                                                Select
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">Active</Dropdown.Item>
                                                <Dropdown.Item href="#">Inactive</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">City</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="">
                                                Select
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">Active</Dropdown.Item>
                                                <Dropdown.Item href="#">Inactive</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Category</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="">
                                                Select
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">Active</Dropdown.Item>
                                                <Dropdown.Item href="#">Inactive</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Contract</label>
                                    <div className="dk-addNewSlectDropDwn">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="">
                                                Select
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">Contract 1</Dropdown.Item>
                                                <Dropdown.Item href="#">Contract 2</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Key Points</label>
                                    <textarea name="keytemrs" id="keyterms" rows="6" className="form-control dkg-clientinfo-textArea" />
                                </div>
                                <div className="form-group">
                                    <label className="dk-mlmangerLabel">Address</label>
                                    <textarea name="address" id="address" rows="6" className="form-control dkg-clientinfo-textArea" />

                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <div className="dk-addresbtnCont">
                                    <button>Add </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default AddnewModal;
