import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import MailboxSubmenu from "../../../elements/header/navbar/subMenu/menu/mainsubmenu/MailboxSubmenu";
// import UserImg from "../../../../assets/images/deepak.png";
// import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CVParseModal from "./modal/cvparsing/CVParse";
import {
  faEdit,
  faReply,
  faShare,
  faTrashAlt,
  faArchive,
  faFolder,
  faReplyAll,
  faSearch,
  faExclamationTriangle,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
//import userImg from "../../../../assets/images/deepak.png";
import MailboxLeftPanel from "./MailboxLeftPanel";
import "./mailbox.scss";
import ComposeMail from "./ComposeMail";
import ReplyMail from "./ReplyMail";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import List from "./list";
import { MailDetails } from "./details";
import { useDispatch, useSelector } from "react-redux";
import {
  sentList,
  clearState,
  clearMailDetail,
  mailDetails,
} from "../../../../slice/mailbox/mailboxSlice";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import { DeleteData, GetData, PatchData } from "../../../../services";
import { useParams, useLocation } from "react-router-dom";
import {
  setDefaultMail,
  getDefaultMail,
} from "../../../helpers/storage-helper";

const Mailbox = (props) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const [showComposeModal, setShowCompose] = useState(false);
  const [showReplyModal, setShowReply] = useState(false);
  const [isReplyAll, setIsReplyAll] = useState(false);
  const [isForward, setIsForward] = useState(false);
  const [myAccounts, setMyAccounts] = useState([]);
  const [userImge, setUserImge] = useState(userInfo.profileImage);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const { isLoading, isSuccess, listSent, details } = useSelector(
    (state) => state.mailbox
  );
  const { userDetails } = useSelector((state) => state.auth);
  const [inboxList, setInboxList] = useState([]);
  const [count, setMailBoxCount] = useState({});
  const { routeType } = useParams();
  const location = useLocation();
  const { socket } = props;

  //const selectedAccountRef = React.useRef(selectedAccount);

  const getInboxMails = async (id, type, search = '', is_read = null, flagged = null) => {
    if (type === "draft") {
      setInboxList([]);
      return;
    }
    try {
      let url = `/mailbox/list/${type}/${id}?search=${search}`;
      if (is_read !== null) {
        url += `&is_read=${is_read}`;
      }
      if (flagged !== null) {
        url += `&flagged=${flagged}`;
      }
      const res = await GetData(true, url);
      const mailList = res?.payload?.result;
      setInboxList(mailList || []);
    } catch (e) {
      console.error(e);
    }
  };

  const getMailBoxCount = async (id) => {
    try {
      const res = await GetData(true, `/mailbox/count/${id}`);
      const resCount = res?.payload?.result;
      setMailBoxCount(resCount || {});
    } catch (e) {
      console.log(e);
    }
  };

  const onNewMail = () => {
    console.log("===========new_email", { selectedAccount, routeType });
    if (selectedAccount && routeType) {
      getMailBoxCount(selectedAccount.id);
      getInboxMails(selectedAccount.id, routeType);
    }
  };

  const handleReceiveMessageRef = React.useRef();
  handleReceiveMessageRef.current = onNewMail;

  useEffect(() => {
    console.log("=================socket", socket);
    if (socket) {
      socket.on("new_email", handleReceiveMessageRef.current);
    }

    return () => {
      if (socket) {
        console.log("=================remove");
        socket.off("new_email", handleReceiveMessageRef.current);
      }
    };
  }, [socket]);

  useEffect(() => {
    document.title = "Inbox Mails | Mailbox | DK Global CRM";
  }, []);

  useEffect(async () => {
    if (selectedAccount) {
      getInboxMails(selectedAccount.id, routeType);
      getMailBoxCount(selectedAccount.id);
      dispatch(clearMailDetail());
    }
  }, [location]);

  const getPreviouslySelectedMailAccount = (mailList) => {
    const mail = getDefaultMail();
    if (mail) {
      return (
        mailList.find(
          (item) => item.domain.toLowerCase() === mail.toLowerCase()
        ) || mailList[0]
      );
    }
    return mailList[0];
  };

  useEffect(async () => { 
    try {
      // dispatch(clearMailDetail());
      const res = await GetData(true, "/mail/list");
      console.log("res", res);
      const mailList = res.payload.result;
      if (mailList.length > 0) {
        setMyAccounts(mailList);
        const selected = getPreviouslySelectedMailAccount(mailList);
        setSelectedAccount(selected);
        getInboxMails(selected.id, routeType);
        getMailBoxCount(selected.id);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  // useEffect(() => {
  //     if (!showComposeModal && newAccount) {
  //         getInboxMails(newAccount.id, routeType)
  //     }
  // }, [showComposeModal])

  // useEffect(() => {
  //     if (!isLoading && listSent) {
  //         setList(listSent);
  //     }
  // }, [isLoading])

  const changeSelectedAccount = (newAccount) => {
    setSelectedAccount(newAccount);
    getInboxMails(newAccount.id, routeType);
    getMailBoxCount(newAccount.id);
    setUserImge(newAccount.profileImage);
    setDefaultMail(newAccount.domain);
  };

  const hideComposeModal = () => {
    setShowCompose(false);
    dispatch(clearState());
    if (selectedAccount) {
      getInboxMails(selectedAccount.id, routeType);
      getMailBoxCount(selectedAccount.id);
    }
  };

  const hideReplyModal = async () => {
    setShowReply(false);
    if (selectedAccount) {
      getInboxMails(selectedAccount.id, routeType);
      getMailBoxCount(selectedAccount.id);
    }

    if (details) {
      await dispatch(mailDetails({ id: details.id, type: null }));
    }
  };

  const deleteOrArchive = async (status) => {
    if (!details || !selectedAccount) return;
    try {
      const nextMailBoxId = inboxList.length > 1 ? inboxList[1].id : null;
      await PatchData(
        true,
        `/mailbox/${status}/${details.id}/${selectedAccount.id}`
      );
      getInboxMails(selectedAccount.id, routeType);
      getMailBoxCount(selectedAccount.id);
      if (nextMailBoxId)
        dispatch(mailDetails({ id: nextMailBoxId, type: null }));
      dispatch(clearMailDetail());
    } catch (e) {
      console.log(e);
    }
  };

  const deleteMail = async (id, type = 'trash') => {
    if (!selectedAccount) return;
    try {
      const nextMailBoxId = inboxList.length > 1 ? inboxList[1].id : null;
      await PatchData(
        true,
        `/mailbox/${type}/${id}/${selectedAccount.id}`
      );
      getInboxMails(selectedAccount.id, routeType);
      getMailBoxCount(selectedAccount.id);
      if (nextMailBoxId)
        dispatch(mailDetails({ id: nextMailBoxId, type: null }));
      dispatch(clearMailDetail());
    } catch (e) {
      console.log(e);
    }
  };

  const markAsUnReadEmail = async () => {
    if (!details || !selectedAccount) return;
    try {
      await PatchData(true, `/mailbox/mark-as-unread/${details.id}`);
      getInboxMails(selectedAccount.id, routeType);
      getMailBoxCount(selectedAccount.id);
    } catch (e) {
      console.log(e);
    }
  };

  const [searchClearSetInterval, setSearchClearSetInterval] = useState(null);

  const handleSearch = (e) => {
    if (searchClearSetInterval) {
      clearTimeout(searchClearSetInterval);
    }
    setSearchClearSetInterval(
      setTimeout(() => {
        getInboxMails(selectedAccount.id, routeType, e.target.value);
      }, 1000)
    );
  }

  const handleBulkAction = async (action) => {
    try {
      const response = await PatchData(
        true,
        `/bulk/action/${action}/${selectedAccount.id}`,
        { 
          ids: getAllCheckedMail,
          routeType: routeType
        }
      );
      if (response.status === 200) {
        setAllCheckedMail([]);
        getInboxMails(selectedAccount.id, routeType);
        getMailBoxCount(selectedAccount.id);
        dispatch(clearMailDetail());
      }
    } catch (e) {
      console.log(e);
    }
  }

  const permanentDelete = async () => {
    if (!details || !selectedAccount) return;
    try {
      await DeleteData(true, `/mailbox/${details.id}`);
      getInboxMails(selectedAccount.id, routeType);
      getMailBoxCount(selectedAccount.id);
      dispatch(clearMailDetail());
      const nextMailBoxId = inboxList.length > 1 ? inboxList[1].id : null;
      if (nextMailBoxId)
        dispatch(mailDetails({ id: nextMailBoxId, type: null }));
    } catch (e) {
      console.log(e);
    }
  }

  const [getAllCheckedMail, setAllCheckedMail] = useState([]);

  useEffect(() => {
    dispatch(clearMailDetail());
  }, [getAllCheckedMail])

  const [isGetOnlyUnread, setIsGetOnlyUnread] = useState('all');
  const handleUnReadIconClick = () => {
    if (isGetOnlyUnread == 'all') {
      setIsGetOnlyUnread('unread');
    } else {
      setIsGetOnlyUnread('all');
      getInboxMails(selectedAccount.id, routeType, '', null, isFlagged == 'flagged' ? 'flagged' : null);
    }
  }

  const [isFlagged, setIsFlagged] = useState('all');
  const handleFlaggedIconClick = () => {
    if (isFlagged == 'all') {
      setIsFlagged('flagged');
    } else {
      setIsFlagged('all');
      getInboxMails(selectedAccount.id, routeType, '', isGetOnlyUnread == 'unread' ? 'unread' : null);
    }
  }

  useEffect(() => {
    if (isFlagged == 'flagged') {
      getInboxMails(selectedAccount.id, routeType, '', isGetOnlyUnread == 'unread' ? 'unread' : null, 'flagged');
    }
  } , [isFlagged])

  useEffect(() => {
    if (isGetOnlyUnread == 'unread') {
      getInboxMails(selectedAccount.id, routeType, '', 'unread', isFlagged == 'flagged' ? 'flagged' : null);
    }
  } , [isGetOnlyUnread])

  return (
    <>
      {/* <MailboxSubmenu /> */}
      <ComposeMail
        show={showComposeModal}
        onHide={() => hideComposeModal()}
        currentAccount={selectedAccount}
        toList={[]}
        type="mailbox"
      />
      <ReplyMail
        show={showReplyModal}
        onHide={() => hideReplyModal()}
        currentAccount={selectedAccount}
        isReplyAll={isReplyAll}
        isForward={isForward}
      />
      <div className="dk-moduleMailbox-fluid">
        <div className="dk-mailHeader">
          <div className="d-flex align-items-center justify-content-between">
            <div className="dk-mailHeadLeftPanel">
              <div className="dkg-mail-userbox-Con">
                <div className="dk-mailUserProfile">
                  {/* <img src={userImg} alt="" title="deepak@dkmasterbox.com" /> */}
                  {/* <figure>
                                        <div className="title">Deepak Kumar</div>
                                        <div className="dk-emailTitle"><Link to="mailto:deepak@dkglobalrecruitment.com">deepak@dkglobalrecruitment.com</Link></div>
                                    </figure> */}
                  <div className="dkg-acc-dropdownCon">
                    <Dropdown className="dkg-acc-dropdown">
                      <Dropdown.Toggle
                        variant=""
                        className="dkg-acc-user-toggle"
                      >
                        <div className="dkg-acc-usericon">
                          <img
                            src={
                              process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              selectedAccount?.profileImage
                            }
                            alt=""
                            title={selectedAccount?.domain}
                          />
                          {selectedAccount && (
                            <span className="dkg-acc-username">
                              {selectedAccount.name}{" "}
                              {selectedAccount.last_name
                                ? selectedAccount.last_name
                                : null}
                            </span>
                          )}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dkg-acc-user-dropdown-menu">
                        {myAccounts.map((item, index) => (
                          item.type == 'user' && 
                          <Dropdown.Item
                            key={`mail-dd-key-${index}`}
                            href="#"
                            className="dkg-acc-user-dropdown-item"
                            onClick={() => changeSelectedAccount(item)}
                          >
                            <img
                              src={
                                process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                item.profileImage
                              }
                              alt={item.name}
                              title={item.domain}
                              className="dkg-acc-userimg"
                            />
                            <div className="dkg-acc-username">
                              <span>
                                {item.name} {item.last_name}
                              </span>
                              <sub>{item.domain}</sub>
                            </div>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="dk-mailSearch">
                <input
                  type="text"
                  placeholder="Search..."
                  name=""
                  id=""
                  className="form-control"
                  onKeyUp={handleSearch}
                />
                <button className="dk-searchBtn">
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>
            </div>
            <div className="dk-mailHeadRightpanel">
              <div className="d-flex align-items-center">
                <div className="dk-maildelforwMain">
                {
                    getAllCheckedMail.length > 0 && (
                      <div className="d-flex">
                        {
                          (routeType == "inbox" || routeType == "sent" || routeType == "archive") && (
                            <>
                              <span
                                onClick={() => handleBulkAction("trash")}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} /> Delete
                              </span>
                            </>
                          )
                        }
                        {
                          (routeType == "archive" || routeType == "trash") && (
                            <>
                              <span
                                onClick={() => handleBulkAction("inbox")}
                              >
                                <FontAwesomeIcon icon={faArchive} /> Move to Inbox
                              </span>
                              {
                                routeType == "trash" && (
                                  <>
                                    <span
                                      onClick={() => handleBulkAction("archive")}
                                    >
                                      <FontAwesomeIcon icon={faArchive} /> Move to Archive
                                    </span>
                                    <span
                                      onClick={() => handleBulkAction("delete")}
                                    >
                                      <FontAwesomeIcon icon={faExclamationTriangle} style={{color: "#cea022",}}/> Delete Permanentl
                                    </span>
                                  </>
                                )
                              }
                            </>
                          )
                        }
                        {
                          (routeType != "trash" && routeType != "archive") && (
                            <>
                              <span
                                onClick={() => handleBulkAction("read")}
                              >
                                <FontAwesomeIcon icon={faFolder} /> Mark Read
                              </span>
                              <span
                                className="dk-markUnread"
                                onClick={() => handleBulkAction("unread")}
                              >
                                <FontAwesomeIcon icon={faFolder} />
                                  Make Unread
                              </span>
                              <span
                                onClick={() => {
                                  handleBulkAction("archive");
                                }}
                              >
                                <FontAwesomeIcon icon={faArchive} />{" "}
                                Archive
                              </span>
                            </>
                          )
                        }
                      </div>
                    )
                  }
                  {details && (
                    <div className="d-flex">
                      {routeType != "trash" && routeType != "archive" && (
                        <>
                          <span
                            onClick={() => {
                              if (details) {
                                setIsForward(false);
                                setIsReplyAll(false);
                                setShowReply(true);
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faReply} /> Reply
                          </span>
                          <span
                            onClick={() => {
                              if (details) {
                                setIsForward(false);
                                setIsReplyAll(true);
                                setShowReply(true);
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faReplyAll} /> Reply All
                          </span>
                          <span
                            onClick={() => {
                              if (details) {
                                setIsForward(true);
                                setIsReplyAll(false);
                                setShowReply(true);
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faShare} /> Forward
                          </span>
                        </>
                      )}
                      {routeType != "trash" && (
                        <span
                          onClick={() => {
                            deleteOrArchive("trash");
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} /> Delete
                        </span>
                      )}
                      {(routeType == "inbox" || routeType == "archive") && (
                        <span
                          onClick={() => {
                            deleteOrArchive(routeType == "archive" ? "inbox" : "archive");
                          }}
                        >
                          <FontAwesomeIcon icon={faArchive} />{" "}
                          {routeType == "archive" ? "Move to Inbox" : "Archive"}
                        </span>
                      )}
                      {routeType != "trash" && routeType != "archive" && (
                        <>
                          <span>
                            <FontAwesomeIcon icon={faFolder} /> Mark Read
                          </span>
                          <span
                            className="dk-markUnread"
                            onClick={() => markAsUnReadEmail()}
                          >
                            <FontAwesomeIcon icon={faFolder} />
                            Make Unread
                          </span>
                          <span>
                            <ViewComfyIcon
                              style={{ marginTop: "-4px", marginBottom: "0" }}
                            />{" "}
                            Categories
                          </span>
                        </>
                      )}
                      {routeType != "trash" && routeType != "archive" && (
                        <CVParseModal />
                      )}
                      {routeType == "trash" && (
                        <>
                          <div className="dkg-trash-tab-btnCon">
                            <span to="#" className="dkg-trash-tab-btn mr-2"
                              onClick={() => {
                                deleteOrArchive("inbox");
                              }}
                            >
                              Move to Inbox
                            </span>
                            <span to="#" className="dkg-trash-tab-btn mr-2" 
                              onClick={() => {
                                deleteOrArchive("archive");
                              }}
                            >
                              Move to Archive
                            </span>
                            <span to="#" className="dkg-trash-tab-btn mr-2"
                              onClick={() => permanentDelete()}
                            >
                              Delete Permanently
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div className="dk-mail-items">
                  <div className="dk-newCompose">
                    <Link to="#" onClick={() => setShowCompose(true)}>
                      <FontAwesomeIcon icon={faEdit} /> Compose Email
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dk-moduleMailbox-fluid mt-0 pt-0">
        <div className="dk-mailBox-main">
          <div className="d-flex">
            <div className="dk-mailLeftPanel">
              <MailboxLeftPanel counter={count} type={routeType}/>
            </div>
            <div className="dk-mailRightPanel">
              <div className="dk-rightPanel-mails">
                <List 
                  label={routeType} 
                  list={inboxList} 
                  deleteMailSingle={deleteMail}
                  setAllCheckedMail={setAllCheckedMail}
                  handleUnReadIconClick={handleUnReadIconClick}
                  getAllCheckedMail={getAllCheckedMail}
                  isGetOnlyUnread={isGetOnlyUnread}
                  markImportant={deleteMail}
                  handleFlaggedIconClick={handleFlaggedIconClick}
                  isFlagged={isFlagged}
                />
              </div>
              <MailDetails />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mailbox;
