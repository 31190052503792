import React from "react";
//import { Tab, Nav, Dropdown } from 'react-bootstrap'
import "./myacc-jobprofile.scss";
//import DatePicker from './modal/DatePicker';
import LiveJobs2 from "../../../../components/pages/jobs/livejobs/LiveJobs";
import { useSelector } from "react-redux";

const JobProfile = () => {
  const { userInfo } = useSelector((state) => state.auth);
  return (
    <>
      <div className="dkg-myacc-jobprofile-main-Con container-fluid">
        <LiveJobs2 id={userInfo.canId} email={userInfo.systemEmail} />
      </div>
    </>
  );
};

export default JobProfile;
