import React from "react";
import '../mainMenu/style.scss';
import DkGlobalSubmenu from './menu/knowleadge/DkGlobalSubmenu';
import KpiSubmenu from './menu/knowleadge/KpiSubmenu';
import DKGlobalSubmenu from './menu/datatablesubmenu/DKGlobalSubmenu'


const DatatableSubmenu = ({ path }) => {
    const renderSubmenu = () => {
        switch (path.split("/")[2]) {

            case 'dk-global':
                return <DkGlobalSubmenu />
                break;

            case 'kpi':
                return <KpiSubmenu />
                break;

            case 'dkglobal':
                return <DKGlobalSubmenu />
                break;

            default:
                break
        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default DatatableSubmenu;