import React from 'react'
import './ads-rersource.scss'

const index = () => {
    return (
        <>
            <div className='dkg-ads-reslist-mainCon'>
                <div className='d-flex w-100'>
                    <div className='dkg-ads-reslistBoxes-main'>
                        <div className='dkg-ads-reslist-panel'>
                            <div className='dkg-ads-reslist-cols ml-0'>
                                <div className="title">Job Portals</div>
                                <div className='dkg-ads-reslist-suMM-panel'></div>
                            </div>
                            <div className='dkg-ads-reslist-cols'>
                                <div className="title">CV Database</div>
                                <div className='dkg-ads-reslist-suMM-panel'></div>
                            </div>
                            <div className='dkg-ads-reslist-cols'>
                                <div className="title">DKG Website</div>
                                <div className='dkg-ads-reslist-suMM-panel'></div>
                            </div>
                            <div className='dkg-ads-reslist-cols'>
                                <div className="title">Social Media</div>
                                <div className='dkg-ads-reslist-suMM-panel'></div>
                            </div>
                            <div className='dkg-ads-reslist-cols'>
                                <div className="title">Partners</div>
                                <div className='dkg-ads-reslist-suMM-panel'></div>
                            </div>
                            <div className='dkg-ads-reslist-cols'>
                                <div className="title">Referrals</div>
                                <div className='dkg-ads-reslist-suMM-panel'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default index