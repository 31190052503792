import React, { useState, useEffect } from "react";
import { Route, Link } from "react-router-dom";
import EditJobProfileModal1 from "./EditJobProfileModal1";
import AddJobProfileModal1 from "./AddJobProfileModal";
import AddJobProfileModal2 from "./AddJobProfileModal2";
import AddJobProfileModal3 from "./AddJobProfileModal3";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "../../../ui/tooltip";
import { showError, showSuccess, clearMessage } from "../../../../slice/utils/message/messageSlice";
import { listTable, reorderListTable, clearState, addHeadings, updateHeadings } from "../../../../slice/jobProfile/jobProfileSlice";
import DisplayResponsbility from "./DisplayResponsbility";
import AllDisplayResponsbility from "./AllDisplayResponsbility";

const JobProfile = () => {
    const dispatch = useDispatch();
    const { usersList } = useSelector(state => state.users);  

    const { dataList, isInsert, isDelete, isUpdate, isReoder, msg } = useSelector(state => state.jobProfile);
    const [userId, setUserId] = useState(0);

    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false)
    const [show3, setShow3] = useState(false)

    const [showEdit1, setShowEdit1] = useState(false)
    const [showEdit2, setShowEdit2] = useState(false)
    const [showEdit3, setShowEdit3] = useState(false)
    
    const [showEditResponsibilities, setShowEditResponsibilities] = useState(false)
    const [responbility, setResponbility] = useState('')
    const [responbilityId, setResponbilityId] = useState(0)

    const [subCategoryBox3Id, setSubCategoryBox3Id] = useState(0)
    const [activeFirstTab, setActiveFirstTab] = useState(0)
    const [showwEdit, setShowwEdit] = useState(false)
    const [individualData, setIndividualData] = useState(true)
    
    useEffect(() => {
        (usersList && usersList.length > 0) ?
            setUserId(usersList[0].id)
            :
            setUserId('0')
    }, [usersList])

    useEffect(() => {
        async function fetchRules() {
            try {
                await dispatch(listTable({ userId: userId }))
                // await dispatch(reorderListTable({ boxId: '1', userId: userId }))
            } catch (err) {
                dispatch(showError({ msg: err }))
            }
        }
        fetchRules()
    }, [userId])

    useEffect(() => {
        async function fetchRules() {
            try {
                await dispatch(listTable({ userId: userId }))
                // await dispatch(reorderListTable({ boxId: '1', userId: userId }))
            } catch (err) {
                dispatch(showError({ msg: err }))
            }
        }
        if (isReoder) {
            dispatch(clearState())
            fetchRules()
        }
    }, [isReoder])

    const handleShow1 = () => {
        setShow1(true)
    }

    const handleClose1 = () => {
        setShow1(false)
    }

    const handleShow2 = () => {
        setShow2(true)
    }

    const handleClose2 = () => {
        setShow2(false)
    }

    const handleShow3 = () => {
        setShow3(true)
    }

    const handleClose3 = () => {
        setShow3(false)
    }

    const handleShowEdit1 = () => {
        setShowEdit1(true)
    }

    const handleShowEdit2 = () => {
        setShowEdit2(true)
    }
    const handleShowEdit3 = () => {
        setShowEdit3(true)
    }

    const handleCloseEdit1 = () => {
        setShowEdit1(false)
    }
    const handleCloseEdit2 = () => {
        setShowEdit2(false)
    }
    const handleCloseEdit3 = () => {
        setShowEdit3(false)
    }
    const showeditpopup = () => {
        setShowEdit1(true);
    }

    const showeditpopup2 = () => {
        setShowEdit2(true);
    }
    const showeditpopup3 = () => {
        setShowEdit3(true);
    }

    const getProfile = (id) => {
        setUserId(id)
        setResponbility('')
        setSubCategoryBox3Id(0)
        setShowwEdit(false) 
        setIndividualData(true) 
        setActiveFirstTab(0)
        setShowEditResponsibilities(false)   
        
        let list = dataList.filter(items => (items.boxId == 4 || items.parentId == subCategoryBox3Id))
        
        if (list.length > 0) {
            setResponbilityId(list[0].id)
            list = list[0].name;
            setResponbility(list)
        }else
        {
            setResponbility('')
        }


    }

    const handleEditResponsibilities = () => {
        setShowEditResponsibilities(true)
        setResponbilityId(0)
        let list = dataList.filter(items => (items.boxId == 4 && items.parentId == subCategoryBox3Id))
        if (list.length > 0) {
            setResponbilityId(list[0].id)
            list = list[0].name;
            setResponbility(list)
        }else
        {
            setResponbility('')
        }
       
        // list.split('\n').map((str =>
        //     responbility.push(str)
        // ))
       
        // dataList.filter(items => items.boxId == 4).map(filteredItems => (
        //     responbility.push(filteredItems.name)
        // ))
    }

    const handleSaveResponsibilities = async () => {
        if (responbilityId === 0) {
            await dispatch(addHeadings({ name: responbility, userId, bgColor: '#fff', txtColor: '#333', boxId: '4', parentId: subCategoryBox3Id }))
        } else {
            await dispatch(updateHeadings({ id: responbilityId, name: responbility, userId, bgColor: '#fff', txtColor: '#333', boxId: '4', parentId:subCategoryBox3Id }))
        }
        setShowEditResponsibilities(false)
        //setResponbility('')
    }

    useEffect(() => {
        if (isInsert) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Data added suucessfully' }))
            setShowEditResponsibilities(false)
        }
        if (isUpdate) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Data apdated suucessfully' }))
            setShowEditResponsibilities(false)
        }
        if (isDelete) {
            dispatch(showSuccess({ msg: msg }))
            dispatch(listTable({ userId: userId }))
            dispatch(clearState())
        }
    }, [isInsert, isUpdate, isDelete])

    

    const jobdescription = (id)=>{  
        setShowwEdit(true)    
        setActiveFirstTab(-1)
        setShowEditResponsibilities(false)
        setIndividualData(false)
        setSubCategoryBox3Id(id)
        let list = dataList.filter(items => (items.boxId == 4 && items.parentId == subCategoryBox3Id))
        if (list.length > 0) {
            setResponbilityId(list[0].id)
            list = list[0].name;
            setResponbility(list)
        }
        
    }
     const removeValFromIndex = [9,10,11]; //not to see users in pipeline, remove user from usersList
    const indexSet = new Set(removeValFromIndex);
    const newUsers = usersList.filter((value, i) => !indexSet.has(i));

    return (
        <React.Fragment>
            {/* <Authentication /> */}
            <div className="dk-jobProfileMain">
                <div className='dk-jobprofiles-mo-pagetitleCon d-none'>
                    <h3 className="dk-jobprofiles-mo-title">Job Profiles</h3>
                </div>
                <div className="dk-jobProfile-users">
                    <div className="d-flex justify-content-between">
                        {
                            (newUsers) ?
                                newUsers.map((item, index) =>
                                    <Link to="#" className={(item.id === userId) ? 'dk-jobProfile-users-box active' : 'dk-jobProfile-users-box'} onClick={() => getProfile(item.id)}>
                                        <Tooltip type="img" title={item.firstName + " " + item.lastName} value={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.profileImage} className="img-fluid img-circle" />
                                    </Link>
                                )
                                :
                                null
                        }
                    </div>
                </div>
                {
                    (show1) ? <AddJobProfileModal1 handleClose={handleClose1} profileUserId={userId} /> : null
                }
                {
                    (show2) ? <AddJobProfileModal2 handleClose={handleClose2} profileUserId={userId} /> : null
                }
                {
                    (show3) ? <AddJobProfileModal3 handleClose={handleClose3} profileUserId={userId} /> : null
                }
                {
                    (showEdit1) ? <EditJobProfileModal1 hidePopup={handleCloseEdit1} profileUserId={userId} boxId="1" showeditpopup={showeditpopup} /> : null
                }

                {
                    (showEdit2) ? <EditJobProfileModal1 hidePopup={handleCloseEdit2} profileUserId={userId} boxId="2" showeditpopup={showeditpopup2} /> : null
                }
                {
                    (showEdit3) ? <EditJobProfileModal1 hidePopup={handleCloseEdit3} profileUserId={userId} boxId="3" showeditpopup={showeditpopup3} /> : null
                }
                <div className="dk-jobProfile-container">
                    <div className="d-md-flex">
                        <div className="dk-jobProfile-cols cols_2">
                            <div className="dk-head">
                                <div className="d-flex align-items-center justify-content-between">
                                    <i className="far fa-edit" onClick={handleShowEdit1}></i>
                                    <div className="title">Departments</div>
                                    <i className="fas fa-plus" onClick={handleShow1}></i>
                                </div>
                            </div>
                            <div className="dk-jobProfileSec">
                                <ul>
                                    {dataList.filter(items => items.boxId == 1).map(filteredItems => (
                                        <li style={{ background: filteredItems.bgColor, color: filteredItems.txtColor }}>{filteredItems.name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="dk-jobProfile-cols cols_2">
                            <div className="dk-head">
                                <div className="d-flex align-items-center justify-content-between">
                                    <i className="far fa-edit" onClick={handleShowEdit2}></i>
                                    <div className="title">Job Title</div>
                                    <i className="fas fa-plus" onClick={handleShow2}></i>
                                </div>
                            </div>
                            <div className="dk-jobProfileSec">
                                <ul>
                                    {dataList.filter(items => items.boxId == 2).map(filteredItems => (
                                        <li style={{ background: filteredItems.bgColor, color: filteredItems.txtColor }}>{filteredItems.name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="dk-jobProfile-cols cols_2">
                            <div className="dk-head">
                                <div className="d-flex align-items-center justify-content-between">
                                    <i className="far fa-edit" onClick={handleShowEdit3}></i>
                                    <div className="title">Job Responsibilities</div>
                                    <i className="fas fa-plus" onClick={handleShow3}></i>
                                </div>
                            </div>
                            <div className="dk-jobProfileSec dkg-jobresponseabilty-cols">
                                <ul>
                                    {dataList.filter(items => items.boxId == 3).map((filteredItems,index) => (
                                        <li style={{ background: filteredItems.bgColor, color: filteredItems.txtColor }} onClick={()=>jobdescription(filteredItems.id) } className={((subCategoryBox3Id === filteredItems.id) || (index==activeFirstTab)) ? 'clickBody activeTabs' :'clickBody' } >{filteredItems.name} </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="dk-jobProfile-cols cols_4">
                            <div className="dk-head">
                                <div className="d-flex align-items-center justify-content-between">
                                    {
                                        showwEdit ? <>                                    
                                        {
                                            (!showEditResponsibilities) ?
                                               
                                                <i className="far fa-edit" onClick={handleEditResponsibilities}></i>
                                                :
                                                <i className="far fa-save" onClick={handleSaveResponsibilities}></i>
                                        }
                                        </>
                                        :
                                        ''
                                    }
                                    
                                    <div className="title">Job Description</div>
                                    <span></span>
                                </div>
                            </div>

                              {
                                (individualData===true) ? 
                                   
                            <div className="dk-jobProfileSec">
                                {                                    
                                    (!showEditResponsibilities) ?
                                        <ul className="responbilityBullets test1">
                                            {dataList.filter(items => (items.boxId === 4 )).map((filteredItems,index) => (
                                              <>
                                              <DisplayResponsbility list={filteredItems.name}  indexZero = {index}/>                                               
                                              </>
                                            ))}
                                        </ul>
                                        :
                                        <ul className="responbilityBullets test2">
                                            <textarea value={responbility} onChange={(e) => setResponbility(e.target.value)} />
                                        </ul>
                                }
                            </div>
                                :
                                <div className="dk-jobProfileSec">
                                {                                    
                                    (!showEditResponsibilities) ?
                                        <ul className="responbilityBullets test1">
                                            {dataList.filter(items => (items.boxId === 4 && items.parentId === subCategoryBox3Id)).map(filteredItems => (
                                              <>
                                              <AllDisplayResponsbility list={filteredItems.name} />                                               
                                              </>
                                            ))}
                                        </ul>
                                        :
                                        <ul className="responbilityBullets test2">
                                            <textarea value={responbility} onChange={(e) => setResponbility(e.target.value)} />
                                        </ul>
                                }
                             </div>
                                }


                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default JobProfile;
