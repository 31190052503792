import React from 'react';
import { Modal, Dropdown } from 'react-bootstrap';

const ViewModal = ({ onHide }) => {
    return (
        <>
            <Modal className="dk-yearsAddNewTask-modal dk-addNewDkgStaffModal right" show={true} onHide={onHide}>
                <Modal.Header closeButton className='dkg-ittax-ViewHeader'>
                    <Modal.Title>View Corporate Tax</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Tax Year</label>
                                <label htmlFor="">
                                    <input type="text" className='form-control' defaultValue="2022" readOnly style={{ backgroundColor: "#f1f1f1" }} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Tax Period</label>
                                <label htmlFor="">
                                    <input type="text" className='form-control' defaultValue="2021 - 2022" readOnly style={{ backgroundColor: "#f1f1f1" }} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">File Date</label>
                                <label htmlFor="">
                                    <input type="text" className='form-control' defaultValue="07-12-2022" readOnly style={{ backgroundColor: "#f1f1f1" }} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Status</label>
                                <label htmlFor="">
                                    <input type="text" className='form-control' defaultValue="Active" readOnly style={{ backgroundColor: "#f1f1f1" }} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Name of Return</label>
                                <label htmlFor="">
                                    <input type="text" className='form-control' defaultValue="Rossella Casciello" readOnly style={{ backgroundColor: "#f1f1f1" }} />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Return Purpose</label>
                                <label htmlFor="">
                                    <input type="text" className='form-control' defaultValue="Recruitment" readOnly style={{ backgroundColor: "#f1f1f1" }} />
                                </label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ViewModal;
