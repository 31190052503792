import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const userslist = createAsyncThunk(
    'users/list',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const usersRCList = createAsyncThunk(
    'users/rclist',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users/allRCList',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 304) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);


const usersSlice = createSlice({
    name: 'users',
    initialState: {
        usersList: [],
        allRCSalesBookList: [],
        isSuccess: false,
        isUserLoading: false,
        isError: false
    },
    reducers: {
        clearState: (state) => {
            state.isUserLoading = false;
            state.isSuccess = false;
            state.isError = false;
        }
    },
    extraReducers: {
        [userslist.pending]: (state) => {
            state.isUserLoading = true;
        },
        [userslist.fulfilled]: (state, { payload }) => {
            state.isUserLoading = false;
            state.isSuccess = true;
            state.usersList = payload.result;
        },
        [userslist.rejected]: (state, { payload }) => {
            state.isUserLoading = false;
            state.isSuccess = false;
        },
        [usersRCList.pending]: (state) => {
            state.isUserLoading = true;
        },
        [usersRCList.fulfilled]: (state, { payload }) => {
            state.isUserLoading = false;
            state.isSuccess = true;
            state.allRCSalesBookList = payload.result;
        },
        [usersRCList.rejected]: (state, { payload }) => {
            state.isUserLoading = false;
            state.isSuccess = false;
        },
    }
})
export const { clearState } = usersSlice.actions
export default usersSlice.reducer;