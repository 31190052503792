import React from 'react';
import Recuiter from '../../../../../assets/images/deepak.png';
import Dropdown from 'react-bootstrap/Dropdown'
import "./flagfilternumber.scss";


const FlagfilterNumber = () => {
    return (
        <>
            <div className="dk-flagfilterNumberCon">
                <Dropdown>
                    <Dropdown.Toggle variant="" className="dk-userNumfliterbtn">
                        <i className="fas fa-flag"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dk-userNumfliterMenu">
                        <div className="d-flex">
                            <Dropdown.Item href="#">
                                <i className="fas fa-flag" style={{ color: "#d0cece" }}></i>
                                <span className="recNoCount">65</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                                <i className="fas fa-flag" style={{ color: "green" }}></i>
                                <span className="recNoCount">65</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                                <i className="fas fa-flag" style={{ color: "red" }}></i>
                                <span className="recNoCount">65</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                                <i className="fas fa-flag" style={{ color: "#eaa03d" }}></i>
                                <span className="recNoCount">65</span>
                            </Dropdown.Item>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default FlagfilterNumber;
