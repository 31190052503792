import React from 'react';
import { Link } from "react-router-dom";

const ElisProjectLiveLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="dk-moduleTraining-leftPanel">
                <ul>
                    <li><Link to="/projects/elis/live/summary" className={`${pathname.match('/projects/elis/live/summary') ? 'active' : ''}`}>Summary</Link></li>
                    <li><Link to="/projects/elis/live/project-1" className={`${pathname.match('/projects/elis/live/project-1') ? 'active' : ''}`}>Italian</Link></li>
                    <li><Link to="/projects/elis/live/project-2" className={`${pathname.match('/projects/elis/live/project-2') ? 'active' : ''}`}>Spanish</Link></li>
                    <li><Link to="/projects/elis/live/project-3" className={`${pathname.match('/projects/elis/live/project-3') ? 'active' : ''}`}>Czech</Link></li>
                    <li><Link to="/projects/elis/live/project-4" className={`${pathname.match('/projects/elis/live/project-4') ? 'active' : ''}`}>Slovak</Link></li>
                    <li><Link to="/projects/elis/live/project-5" className={`${pathname.match('/projects/elis/live/project-5') ? 'active' : ''}`}>Hebrew</Link></li>
                </ul>
            </div>
        </>
    )
}

export default ElisProjectLiveLeftPanel;