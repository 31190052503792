import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/marketing/KnowleadgeMenu';

const KnowleadgeLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="ADVERTISEMENTS" moduleLink="/dashboard/resource" linkcolor="#3c4c62" mainheaderTitle=" - Knowledge Base" textcolor="#4c5f59" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default KnowleadgeLayout;

