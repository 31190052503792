import React from 'react';
import Modal from 'react-bootstrap/Modal';
import userIcon from "../../../../../../assets/images/deepak.png";

const SocialChatsModal = ({ hidesocial }) => {
    return (
        <>
            <Modal className="dk-candidateSocialDetail-modal" show={true} onHide={hidesocial}>
                <Modal.Header closeButton>
                    <Modal.Title>Social Messeges Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Recruiter</label>
                                    <label htmlFor="">
                                        <div className="dk-user">
                                            <img src={userIcon} alt="" />
                                        </div>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SocialChatsModal;
