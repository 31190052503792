import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";

const RecCandidateLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/recruitment/rectraining/candidate" className={`${pathname.match('/recruitment/rectraining/candidate') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/admins/sequence') ? 'active' : ''}`}>Sample 1</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/admins/set-priority') ? 'active' : ''}`}>Sample 2</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/admins/admins-action-priority') ? 'active' : ''}`}>Sample 3</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default RecCandidateLeftPanel;

