import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const ResourcesDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="#" className={`${pathname.match('/training/commontraining') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/business') ? 'active' : ''}`}>Logins</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/industry') ? 'active' : ''}`}>Types</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/services') ? 'active' : ''}`}>Job Boards</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/clients') ? 'active' : ''}`}>Global Jobs</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/suppliers') ? 'active' : ''}`}>DK Recruiters</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/completion') ? 'active' : ''}`}>Phone Calls</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Social Media</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Networking</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Headhunt</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Promotion</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Referral</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>DKG Cloud</Link></li>
                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>DKG Database</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default ResourcesDetailLeftPanel;

