import React, { useState, useEffect, useRef } from "react";
import { Route, Link } from "react-router-dom";
// import Submenu from "../subMenu";

const TaxreturnsMenu = (props) => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK Global CRM";
    }, [pathname]);
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalfinanceExpenseMainMenu">

                <Route>
                    <ul>
                        <li><Link to="/dkglobalfinance/tax-returns/summary" className={(segment3 == 'summary') ? 'active' : ''}>Summary</Link></li>
                        <li><Link to="/dkglobalfinance/tax-returns/corporatetax/2022" className={`${(segment3 == 'corporatetax') ? 'active' : ''}`}>Corporate Tax</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'structure') ? '' : ''}`}>Accounts</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'teams') ? '' : ''}`}>B1 Returns</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'teams') ? '' : ''}`}>Payroll</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'pipelines') ? '' : ''}`}>Director</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'team-1') ? '' : ''}`}>VAT</Link></li>
                    </ul>
                </Route>
            </div>
            {/* <Submenu path={pathname} /> */}
        </React.Fragment>
    );
}

export default TaxreturnsMenu;