import React from "react";
import { Link } from "react-router-dom";
import "./rightmodalfilter.scss";
import { Modal, Nav, Tab } from "react-bootstrap";
import { MdInsertDriveFile } from "react-icons/md";
import Recuriter from "../../../../assets/images/deepak.png";

const RightModalFilter = ({ onHide }) => {
  return (
    <>
      <div className="dk-jobsMainModalCon">
        <Modal
          show={true}
          onHide={onHide}
          dialogClassName="dk-pipenwqrightFliterModal"
        >
          <Modal.Header closeButton className="dkmb-Fliterheader">
            <Modal.Title className="dkmb-FliterTitle">
              SEARCH & REPORT
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="dkmb-Fliterbody">
            <Tab.Container
              id="dk-jobsRightFilterModal"
              defaultActiveKey="recruiters"
            >
              <div className="row ml-0 mr-0">
                <div className="col-md-4 pl-0 pr-0">
                  <Nav variant="pills" className="flex-column dk-leftSideMenu">
                    <Nav.Item>
                      <Nav.Link eventKey="recruiters">
                        <i className="fa fa-user mr-2" aria-hidden="true"></i>
                        Recruiters
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="flags">
                        <i className="fa fa-flag mr-2" aria-hidden="true"></i>
                        Flags
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="category">
                        <i
                          className="fa fa-language mr-2"
                          aria-hidden="true"
                        ></i>
                        Category
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="status">
                        <i className="fa fa-check mr-2" aria-hidden="true"></i>
                        Status
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="progress">
                        <i className="fas fa-chart-line mr-2"></i>Progress
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="casestage">
                        <i className="far fa-check-square mr-2"></i>Case Stage
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="company">
                        <i
                          className="fa fa-building mr-2"
                          aria-hidden="true"
                        ></i>
                        Company
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="project">
                        <MdInsertDriveFile className="mr-3" />
                        Project
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="roletype">
                        <i className="fa fa-user mr-2" aria-hidden="true"></i>
                        Role Type
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="languages">
                        <i
                          className="fa fa-language mr-2"
                          aria-hidden="true"
                        ></i>
                        Languages
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="resources">
                        <i
                          className="fa fa-sitemap mr-2"
                          aria-hidden="true"
                        ></i>
                        Resources
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="bydate">
                        <i
                          className="fa fa-calendar mr-2"
                          aria-hidden="true"
                        ></i>
                        By Date
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-md-8 pl-0 pr-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="recruiters">
                      <div className="dk-rightMainContent">
                        <div className="dk-Recuriter">
                          <ul>
                            <li>
                              <Link to="#" className="">
                                <img
                                  src={Recuriter}
                                  alt="Recuriter"
                                  className="recImg"
                                />
                                <span className="recuriterNo">64</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="">
                                <img
                                  src={Recuriter}
                                  alt="Recuriter"
                                  className="recImg"
                                />
                                <span className="recuriterNo">32</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="">
                                <img
                                  src={Recuriter}
                                  alt="Recuriter"
                                  className="recImg"
                                />
                                <span className="recuriterNo">64</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="">
                                <img
                                  src={Recuriter}
                                  alt="Recuriter"
                                  className="recImg"
                                />
                                <span className="recuriterNo">32</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="">
                                <img
                                  src={Recuriter}
                                  alt="Recuriter"
                                  className="recImg"
                                />
                                <span className="recuriterNo">64</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="">
                                <img
                                  src={Recuriter}
                                  alt="Recuriter"
                                  className="recImg"
                                />
                                <span className="recuriterNo">32</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="">
                                <img
                                  src={Recuriter}
                                  alt="Recuriter"
                                  className="recImg"
                                />
                                <span className="recuriterNo">64</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="">
                                <img
                                  src={Recuriter}
                                  alt="Recuriter"
                                  className="recImg"
                                />
                                <span className="recuriterNo">32</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="flags">
                      <div className="dk-rightMainContent">
                        <div className="dk-flagFilter">
                          <ul>
                            <li>
                              <Link to="#">
                                <i
                                  className="fa fa-flag mr-2"
                                  aria-hidden="true"
                                  style={{ color: "#eaeaea" }}
                                ></i>
                                <span className="text-orange">54</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <i
                                  className="fa fa-flag mr-2"
                                  aria-hidden="true"
                                  style={{ color: "green" }}
                                ></i>
                                <span className="text-orange">234</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <i
                                  className="fa fa-flag mr-2"
                                  aria-hidden="true"
                                  style={{ color: "red" }}
                                ></i>
                                <span className="text-orange">4</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="category">
                      <div className="dk-rightMainContent">
                        <div className="dk-CategoryList">
                          <ul>
                            <li>
                              <Link to="#">
                                ML
                                <span className="text-orange">36</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                IT
                                <span className="text-orange">36</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                None
                                <span className="text-orange">36</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="status">
                      <div className="dk-rightMainContent">
                        <div className="dk-satusListFilter">
                          <ul>
                            <li>
                              <Link
                                to="#"
                                style={{
                                  backgroundColor: "#5c737d",
                                  color: "#fff",
                                }}
                              >
                                New Apps<span className="text-orange">21</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                style={{
                                  backgroundColor: "#56496C",
                                  color: "#fff",
                                }}
                              >
                                Selected<span className="text-orange">21</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                style={{
                                  backgroundColor: "#009a9a",
                                  color: "#fff",
                                }}
                              >
                                DKG Process{" "}
                                <span className="text-orange">21</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                style={{
                                  backgroundColor: "#1b6867",
                                  color: "#fff",
                                }}
                              >
                                Client Process{" "}
                                <span className="text-orange">7</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                style={{
                                  backgroundColor: "#599ad5",
                                  color: "#fff",
                                }}
                              >
                                Job Offer <span className="text-orange">6</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                style={{
                                  backgroundColor: "#a84d4d",
                                  color: "#fff",
                                }}
                              >
                                Refused Offer{" "}
                                <span className="text-orange">4</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                style={{
                                  backgroundColor: "#df744a",
                                  color: "#fff",
                                }}
                              >
                                Credit <span className="text-orange">3</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                style={{
                                  backgroundColor: "#e2445c",
                                  color: "#fff",
                                }}
                              >
                                Closed<span className="text-orange">3</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                style={{
                                  backgroundColor: "#670004",
                                  color: "#fff",
                                }}
                              >
                                Escalated<span className="text-orange">3</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="progress">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            <li>
                              <Link to="#">
                                ITS<span className="text-orange">26</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                CS<span className="text-orange">32</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Junior CS<span className="text-orange">6</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                QM<span className="text-orange">4</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Media Content Review
                                <span className="text-orange">13</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                GL<span className="text-orange">3</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Quality Analyst
                                <span className="text-orange">2</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="casestage">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            <li>
                              <Link to="#">
                                Symantec<span className="text-orange">26</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Pandora<span className="text-orange">32</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Netflix<span className="text-orange">6</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Microsoft<span className="text-orange">4</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Philip Morris
                                <span className="text-orange">13</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                QA-Confidential
                                <span className="text-orange">3</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Technical Analyst
                                <span className="text-orange">2</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="company">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            <li>
                              <Link to="#">
                                Company 1<span className="text-orange">26</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Pandora<span className="text-orange">32</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Netflix<span className="text-orange">6</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Microsoft<span className="text-orange">4</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Philip Morris
                                <span className="text-orange">13</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                QA-Confidential
                                <span className="text-orange">3</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Technical Analyst
                                <span className="text-orange">2</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="project">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            <li>
                              <Link to="#">
                                Project 1<span className="text-orange">26</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Pandora<span className="text-orange">32</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Netflix<span className="text-orange">6</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Microsoft<span className="text-orange">4</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Philip Morris
                                <span className="text-orange">13</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                QA-Confidential
                                <span className="text-orange">3</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Technical Analyst
                                <span className="text-orange">2</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="roletype">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            <li>
                              <Link to="#">
                                Role Type<span className="text-orange">26</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Pandora<span className="text-orange">32</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Netflix<span className="text-orange">6</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Microsoft<span className="text-orange">4</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Philip Morris
                                <span className="text-orange">13</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                QA-Confidential
                                <span className="text-orange">3</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Technical Analyst
                                <span className="text-orange">2</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="languages">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            <li>
                              <Link to="#">
                                Symantec<span className="text-orange">26</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Pandora<span className="text-orange">32</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Netflix<span className="text-orange">6</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Microsoft<span className="text-orange">4</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Philip Morris
                                <span className="text-orange">13</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                QA-Confidential
                                <span className="text-orange">3</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Technical Analyst
                                <span className="text-orange">2</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="resources">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            <li>
                              <Link to="#">
                                Symantec<span className="text-orange">26</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Pandora<span className="text-orange">32</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Netflix<span className="text-orange">6</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Microsoft<span className="text-orange">4</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Philip Morris
                                <span className="text-orange">13</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                QA-Confidential
                                <span className="text-orange">3</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                Technical Analyst
                                <span className="text-orange">2</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="bydate">
                      <div className="dk-rightMainContent">
                        <div className="dk-ByDatefilterCon">
                          <div className="title">PROFILE CREATED</div>
                          <div className="dk-topFormCon mb-5">
                            <div className="dkformgroup">
                              <input
                                type="number"
                                name=""
                                id=""
                                className="form-control"
                              />
                            </div>
                            <div className="dkformgroup">
                              <input
                                type="date"
                                className="form-control broderContrl"
                                name="postedDate"
                                value=""
                              />
                            </div>
                            <div className="dkformgroup">
                              <input
                                type="date"
                                className="form-control broderContrl"
                                name="postedDate"
                                value=""
                              />
                            </div>
                            <div className="dkformgroup">
                              <button className="btn gobtn">Go</button>
                            </div>
                          </div>
                          <div className="dk-topFormCon">
                            <div className="dkformgroup">
                              <input
                                type="number"
                                name=""
                                id=""
                                className="form-control"
                              />
                            </div>
                            <div className="dkformgroup">
                              <input
                                type="date"
                                className="form-control broderContrl"
                                name="postedDate"
                                value=""
                              />
                            </div>
                            <div className="dkformgroup">
                              <input
                                type="date"
                                className="form-control broderContrl"
                                name="postedDate"
                                value=""
                              />
                            </div>
                            <div className="dkformgroup">
                              <button className="btn gobtn">Go</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default RightModalFilter;
