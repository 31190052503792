import React, { useState, useEffect } from "react";
import StepIconOne from "../../../assets/images/sendout_black.png";
import StepIconTwo from "../../../assets/images/duplicate-check.png";
import StepIconThree from "../../../assets/images/record-interview.png";
import StepIconFour from "../../../assets/images/qualifying-call-black.png";
import StepIconFive from "../../../assets/images/reply-queries.png";
import StepIconSix from "../../../assets/images/recruiter_black.png";
import TeamsLeftPanel from './teams/leftpanel/TeamsLeftPanel'
import './dkglobalteams.scss';

const DkGlobalTeamThree = () => {
    const [showResulBoxSix, setResultSixShow] = useState(false);
    const [showResulBoxFive, setResultFiveShow] = useState(false);
    const [showResulBoxFour, setResultFourShow] = useState(false);
    const [showResulBoxThree, setResultThreeShow] = useState(false);
    const [showResulBoxTwo, setResultTwoShow] = useState(false);
    const [showResulBoxOne, setResultOneShow] = useState(false);
    const showResultSix = () => {
        setResultSixShow(true)
        setResultFiveShow(false)
        setResultFourShow(false)
        setResultThreeShow(false)
        setResultTwoShow(false)
        setResultOneShow(false)
    }
    const hideResultSix = () => {
        setResultSixShow(false)
    }

    const showResultFive = () => {
        setResultSixShow(false)
        setResultFiveShow(true)
        setResultFourShow(false)
        setResultThreeShow(false)
        setResultTwoShow(false)
        setResultOneShow(false)
    }
    const hideResultFive = () => {
        setResultFiveShow(false)
    }

    const showResultFour = () => {
        setResultSixShow(false)
        setResultFiveShow(false)
        setResultFourShow(true)
        setResultThreeShow(false)
        setResultTwoShow(false)
        setResultOneShow(false)
    }
    const hideResultFour = () => {
        setResultFourShow(false)
    }

    const showResultThree = () => {
        setResultSixShow(false)
        setResultFiveShow(false)
        setResultFourShow(false)
        setResultThreeShow(true)
        setResultTwoShow(false)
        setResultOneShow(false)
    }
    const hideResultThree = () => {
        setResultThreeShow(false)
    }

    const showResultTwo = () => {
        setResultSixShow(false)
        setResultFiveShow(false)
        setResultFourShow(false)
        setResultThreeShow(false)
        setResultTwoShow(true)
        setResultOneShow(false)
    }
    const hideResultTwo = () => {
        setResultTwoShow(false)
    }

    const showResultOne = () => {
        setResultSixShow(false)
        setResultFiveShow(false)
        setResultFourShow(false)
        setResultThreeShow(false)
        setResultTwoShow(false)
        setResultOneShow(true)
    }
    const hideResultOne = () => {
        setResultOneShow(false)
    }
    return (
        <React.Fragment>
            <div className="dk-compyteam-pagecontainerMain">
                <TeamsLeftPanel />
                {/* <div className="dk-kpiCase-containerMain" style={{ marginTop: "40px" }}>
                    <div className="dk-stepBystepMain">
                        <h3 className="title">TEAM 4 - INTERVIEWS MANAGER</h3>
                        <p className="text-center col-md-5 m-auto">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Blanditiis ipsa vel quod itaque suscipit doloremque quaerat labore. Debitis ipsam perferendis.</p>
                        <div className="dk-stepColsMain">
                            <div className="d-flex">
                                <div className="dk-stepCols" style={{ background: '#007274' }}>
                                    <div className="dk-stepText">
                                        <p className="title" onClick={showResultSix}>
                                            <span><img src={StepIconOne} alt="" /></span>
                                            PREPARE SENDOUT
                                        </p>
                                        <div className="dk-StepNumber">
                                            Step 6
                                        </div>



                                        {
                                            showResulBoxSix ?
                                                <div className="dk-stepDetailMain">
                                                    <div className="title" style={{ background: '#007274', color: '#fff' }}>
                                                        Make Sendout
                                                        <button className="closePanel" onClick={hideResultSix}><i className="fas fa-times"></i></button>
                                                    </div>
                                                    <div className="dk-stepDetailCols">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>

                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>
                                                    </div>
                                                </div> : null
                                        }


                                    </div>
                                    <div className="dk-stepGrayBox" style={{ background: '#007274' }}></div>
                                </div>

                                <div className="dk-stepCols" style={{ marginTop: '80px', background: '#007274' }}>
                                    <div className="dk-stepText">
                                        <p className="title" onClick={showResultFive}>
                                            <span><img src={StepIconThree} alt="" /></span>
                                            Record Interview
                                        </p>
                                        <div className="dk-StepNumber">
                                            Step 5
                                        </div>



                                        {
                                            showResulBoxFive ?
                                                <div className="dk-stepDetailMain">
                                                    <div className="title" style={{ background: '#007274', color: '#fff' }}>
                                                        Record Interview
                                                        <button className="closePanel" onClick={hideResultFive}><i className="fas fa-times"></i></button>
                                                    </div>
                                                    <div className="dk-stepDetailCols">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>

                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>
                                                    </div>
                                                </div> : null
                                        }


                                    </div>
                                    <div className="dk-stepGrayBox" style={{ background: '#007274' }}></div>
                                </div>

                                <div className="dk-stepCols" style={{ marginTop: '160px', background: '#007274' }}>
                                    <div className="dk-stepText">
                                        <p className="title" onClick={showResultFour}>
                                            <span><img src={StepIconFour} alt="" /></span>
                                            Prepare For Call
                                        </p>
                                        <div className="dk-StepNumber">
                                            Step 4
                                        </div>



                                        {
                                            showResulBoxFour ?
                                                <div className="dk-stepDetailMain">
                                                    <div className="title" style={{ background: '#007274', color: '#fff' }}>
                                                        Prepare For Call
                                                        <button className="closePanel" onClick={hideResultFour}><i className="fas fa-times"></i></button>
                                                    </div>
                                                    <div className="dk-stepDetailCols">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>

                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>
                                                    </div>
                                                </div> : null
                                        }


                                    </div>
                                    <div className="dk-stepGrayBox" style={{ background: '#007274' }}></div>

                                </div>

                                <div className="dk-stepCols" style={{ marginTop: '240px', background: '#007274' }}>
                                    <div className="dk-stepText">
                                        <p className="title" onClick={showResultThree}>
                                            <span><img src={StepIconFive} alt="" /></span>
                                            Reply Queries
                                        </p>
                                        <div className="dk-StepNumber">
                                            Step 3
                                        </div>



                                        {
                                            showResulBoxThree ?
                                                <div className="dk-stepDetailMain">
                                                    <div className="title" style={{ background: '#007274', color: '#fff' }}>
                                                        Reply Queries
                                                        <button className="closePanel" onClick={hideResultThree}><i className="fas fa-times"></i></button>
                                                    </div>
                                                    <div className="dk-stepDetailCols">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>

                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>
                                                    </div>
                                                </div> : null
                                        }


                                    </div>
                                    <div className="dk-stepGrayBox" style={{ background: '#007274' }}></div>
                                </div>

                                <div className="dk-stepCols" style={{ marginTop: '320px', background: '#007274' }}>
                                    <div className="dk-stepText">
                                        <p className="title" onClick={showResultTwo}>
                                            <span><img src={StepIconSix} alt="" /></span>
                                            Review Full Case
                                        </p>
                                        <div className="dk-StepNumber">
                                            Step 2
                                        </div>



                                        {
                                            showResulBoxTwo ?
                                                <div className="dk-stepDetailMain">
                                                    <div className="title" style={{ background: '#007274', color: '#fff' }}>
                                                        Review Full Case
                                                        <button className="closePanel" onClick={hideResultTwo}><i className="fas fa-times"></i></button>
                                                    </div>
                                                    <div className="dk-stepDetailCols">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>

                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>
                                                    </div>
                                                </div> : null
                                        }


                                    </div>
                                    <div className="dk-stepGrayBox" style={{ background: '#007274' }}></div>
                                </div>

                                <div className="dk-stepCols" style={{ marginTop: '400px', background: '#007274' }}>
                                    <div className="dk-stepText">
                                        <p className="title" onClick={showResultOne}>
                                            <span><img src={StepIconTwo} alt="" /></span>
                                            DUPLICATE CHECK
                                        </p>
                                        <div className="dk-StepNumber">
                                            Step 1
                                        </div>



                                        {
                                            showResulBoxOne ?
                                                <div className="dk-stepDetailMain">
                                                    <div className="title" style={{ background: '#007274', color: '#fff' }}>
                                                        DUPLICATE CHECK
                                                        <button className="closePanel" onClick={hideResultOne}><i className="fas fa-times"></i></button>
                                                    </div>
                                                    <div className="dk-stepDetailCols">
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>

                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit recusandae aliquid consequatur ab, officia exercitationem, aliquam ex natus at dicta.</p>
                                                    </div>
                                                </div> : null
                                        }


                                    </div>
                                    <div className="dk-stepGrayBox" style={{ background: '#007274' }}></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

        </React.Fragment>
    );
}

export default DkGlobalTeamThree;
