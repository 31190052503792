import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import EditPaymentModal from '../EditPayment'
import DeleteDummyModal from '../deletemodal/DeleteDummy'
import $ from "jquery";
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(0),
        },
    },
}));
const CheckboxDummy = () => {
    const [showDelete, setShowDelete] = useState(false)
    const [isActive, setIsActive] = useState(false);
    const [countCheck, setCountCheck] = useState(0)
    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')

    const handleToggle = () => {
        $('.chkCandidate').prop('checked', false);
        setIsActive(false);
    };

    const toggleCheckAll = () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
            setCountCheck(totalCheckboxes)
            $('.chkCandidate').prop('checked', true);
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Check All')
            setCountCheck(0)
            $('.chkCandidate').prop('checked', false);
            setIsActive(false)
        }
    }
    const deleteSelected = () => {
        setShowDelete(true)
    }
    const toggleCheckbox = (e) => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount > 0) {
            setCountCheck(checkedBoxCount)
            setIsActive(true)
        } else {
            setCountCheck(0)
            setIsActive(false)
        }
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            setCheckBtnTxt('Check All')
        }
        if (checkedBoxCount == totalCheckboxes) {
            setCheckBtnTxt('Uncheck All')
        }
    }
    const handleClose = () => {
        setShowDelete(false)
    }
    return (
        <React.Fragment>
            <div className="dk-smsTableCheckbox">
                <input type="checkbox" className="chkCandidate" name="chkCandidate[]" onChange={(e) => toggleCheckbox()} />
            </div>
            <div className={`dk-smsMessage-selectMsg ${isActive ? "activePopup" : ""}`} style={{ maxWidth: "450px" }}>
                <div className="d-flex align-items-center justify-content-between">
                    {/* <div className="dk-selectNo">{countCheck}</div> */}
                    <div className="dk-selectNo">1</div>
                    {/*<div className="dk-rowSelected">
                        <span>Row Selected</span>
                    </div> */}
                    <div className="dk-checkAll" onClick={deleteSelected}>
                        <EditPaymentModal />
                        Edit
                    </div>
                    <div className="dk-checkAll" onClick={deleteSelected}>
                        <DeleteDummyModal />
                        Delete
                    </div>
                    <div className="dk-checkAll d-flex align-items-center justify-content-center" onClick={handleToggle}>
                        <span><i className="fas fa-times"></i></span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CheckboxDummy;