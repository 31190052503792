import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const SearchFilterModal = ({ handleClose }) => {
    return (
        <>
            <div>
                <Modal className="dk-searchFilerModal-main" show={true} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Search CV  - Filters</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="dk-tagsBody">
                            <li><Link to="#">Tags 1 <span className="dk-closeTags"><FontAwesomeIcon icon={faXmark} /></span></Link></li>
                            <li><Link to="#">Tags 2 <span className="dk-closeTags"><FontAwesomeIcon icon={faXmark} /></span></Link></li>
                            <li><Link to="#">Tags 3 <span className="dk-closeTags"><FontAwesomeIcon icon={faXmark} /></span></Link></li>
                            <li><Link to="#">Tags 4 <span className="dk-closeTags"><FontAwesomeIcon icon={faXmark} /></span></Link></li>
                            <li><Link to="#">Tags 5 <span className="dk-closeTags"><FontAwesomeIcon icon={faXmark} /></span></Link></li>
                            <li><Link to="#">Tags 6 <span className="dk-closeTags"><FontAwesomeIcon icon={faXmark} /></span></Link></li>
                            <li><Link to="#">Tags 7 <span className="dk-closeTags"><FontAwesomeIcon icon={faXmark} /></span></Link></li>
                            <li><Link to="#">Tags 8 <span className="dk-closeTags"><FontAwesomeIcon icon={faXmark} /></span></Link></li>
                            <li><Link to="#">Tags 9 <span className="dk-closeTags"><FontAwesomeIcon icon={faXmark} /></span></Link></li>
                            <li><Link to="#">Tags 10 <span className="dk-closeTags"><FontAwesomeIcon icon={faXmark} /></span></Link></li>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default SearchFilterModal;
