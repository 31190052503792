import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import ProcessLeftPanel from './leftpanel/ProcessLeftPanel';
import StepOneDetailLeftPanel from "./detailleftpanel/StepOneDetailLeftPanel";
import "./financeprocess.scss";

const FinanceProcessStepNine = () => {
    return (
        <div className="dk-moduledkFinanceProcess-main">
            <div className="dk-moduledkFinanceProcess-cols">
                <ProcessLeftPanel />
                <div className="dk-moduledkFinanceProcess-rightPanel">
                    <div className="title">Mphasis</div>
                    <div className="dk-moduledkFinanceProcess-rightCols">
                        <div className="dk-getStartedRight-detail">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                                <Nav variant="">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_1">Details</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_2">Audio</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_3">Video</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="tabs_1">
                                        <div className="dk-rightDetail-panel">
                                            <StepOneDetailLeftPanel />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_2">
                                        <div className="dk-rightDetail-panel">
                                            <StepOneDetailLeftPanel />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_3">
                                        <div className="dk-rightDetail-panel">
                                            <StepOneDetailLeftPanel />
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinanceProcessStepNine;
