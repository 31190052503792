import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './documents.scss';
import { useDispatch, useSelector } from "react-redux";
import { getDocments, updateDocuments, deleteCv, clearState, updateFileType } from '../../../../../../../slice/candidates/candidatesSlice';
import moment from "moment-timezone";
import DocumentModal from "../../documents/documentTab";
import AWS from 'aws-sdk'
import { SYSTEM_CONSTANTS } from "../../../../../../../constants";
import DeleteModal from "../../../../../../ui/delete";
//import { showSuccess, showError, clearMessage } from "../../../../../../utils/messages/messageSlice";
import { FaEdit, FaPlus } from 'react-icons/fa';
import EditDocuments from "./EditDocuments";
import Dropdown from 'react-bootstrap/Dropdown';
import AddNewFile from './modal/AddNewFile';


const Documents = ({ candidateId, tabId }) => {
    const fileInputAPI = useRef();
    const dispatch = useDispatch();
    const { isProfileTabLoading, isDocumentUpdate, candidateDocumentsList, isDocumentDelete, isFileTypeUpdate } = useSelector(state => state.candidate);
    const { candidateDocTypeList } = useSelector(state => state.common);

    useEffect(() => {
        if (tabId === 'documents') {
            const fetchRecods = async () => {
                await dispatch(getDocments({ id: candidateId }))
            }
            fetchRecods()
        } else {
            const fetchRecods = async () => {
                // await dispatch(getDocments({ id: candidateId }))
            }
            fetchRecods()
        }
    }, [tabId, candidateId])

    useEffect(() => {
        if (isFileTypeUpdate) {
            dispatch(clearState())
            dispatch(getDocments({ id: candidateId }))
        }
    }, [isFileTypeUpdate])

    useEffect(() => {
        if (isDocumentDelete) {
            dispatch(clearState())
            //   dispatch(showSuccess({ msg: 'Delete successfully.' }))
            setRowId('0')
            hideDeleteModal()
            dispatch(getDocments({ id: candidateId }))
        }
    }, [isDocumentDelete])

    useEffect(() => {
        if (isDocumentUpdate) {
            // fileInputAPI.current.value = ''
            dispatch(clearState())
            const fetchRecods = async () => {
                await dispatch(getDocments({ id: candidateId }))
            }
            fetchRecods()
            hideaddnewdocModal()
        }
    }, [isDocumentUpdate])


    const [file, setFile] = useState('')
    const [fileName, setFileName] = useState('')

    const onSelectFileByAPI = async (e) => {
        // if (e.target.files && e.target.files.length > 0) {
        //     setFile(e.target.files[0])
        //     setFileName(e.target.files[0].name)
        // }

        try {
            let s3bucket = new AWS.S3({
                accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
                secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
                Bucket: process.env.REACT_APP_BUCKET_NAME
            });

            let fileNewName = Date.now() + '-' + e.target.files[0].name;
            s3bucket.createBucket(function () {
                var params = {
                    Bucket: process.env.REACT_APP_BUCKET_NAME,
                    Key: fileNewName,
                    Body: e.target.files[0],
                    ContentType: e.target.files[0].type
                };

                s3bucket.upload(params, function (err, data) {
                    if (err) {
                        console.log(err)
                    } else {
                        dispatch(updateDocuments({ id: candidateId, fileName: SYSTEM_CONSTANTS.awsS3.Url + data.key, }))
                        // fileUrl: process.env.REACT_APP_INTERNAL_BASE_URL + "/" + dataKey
                    }
                });
            });

        } catch (err) {
            console.log(err)
        }
    }
    const [isDeletePop, setIsDeletePop] = useState(false);
    const [rowId, setRowId] = useState('0')
    const showDeleteModal = async (id) => {
        setRowId(id)
        setIsDeletePop(true)
    }

    const hideDeleteModal = () => {
        setIsDeletePop(false)

    }
    const closeDelete = () => {
        setIsDeletePop(false)
    }
    const moveSelected = async () => {
        //console.log(rowId)
        await dispatch(deleteCv({ 'id': rowId }))
    }

    const [isEditComments, setIsEditComments] = useState(false);

    const showEditComments = () => {
        setIsEditComments(true);
    }
    const hideEditComments = () => {
        setIsEditComments(false);
    }
    const changeFileType = async (id, data) => {
        await dispatch(updateFileType({ 'id': id, 'fileType': data }))
    }
    const [isaddnewDoc, setIsaddnewDoc] = useState(false);
    const showaddnewdocModal = () => {
        setIsaddnewDoc(true)
        console.log("Shoe Add New Doc Modal")
    }
    const hideaddnewdocModal = () => {
        console.log("Shoe Add New Doc Modal")
        setIsaddnewDoc(false);
    }
    return (
        <>
            {
                (isDeletePop) ? <DeleteModal id={rowId} onHide={hideDeleteModal} deleteItem={moveSelected} handleClose={closeDelete} /> : null
            }

            {
                (isEditComments) ? <EditDocuments hidePopup={hideEditComments} /> : null
            }
            {
                (isaddnewDoc) ? <AddNewFile onHide={hideaddnewdocModal} canIdd={candidateId} /> : null
            }
            <div className="dk-ducMCon">
                <div className="dk-ducMTopHead">
                    <div className="title">My Documents</div>
                    <div className="btn-Con d-flex">
                        {/* <div className="dk-selectFiles">
                            <input type="file" id="imageInputAPI" onChange={onSelectFileByAPI} ref={fileInputAPI} />
                            <span>
                                <i className="fas fa-plus"></i>
                            </span>
                        </div> */}
                        {/* <div className="dk-addnewBtnSelect">
                            <input type="file" id="imageInputAPI" onChange={onSelectFileByAPI} ref={fileInputAPI} />
                        </div> */}
                        <div className="dkg-addnewbtn-Con">
                            <span onClick={showaddnewdocModal}><FaPlus /> Add New Doc</span>
                        </div>
                    </div>
                </div>
                <div className="dk-ducMTableCon">
                    <table className="table dk-ducMTable">
                        <thead>
                            <tr>
                                <th>Sn.</th>
                                <th>Name of Document</th>
                                <th>Uploaded Date</th>
                                <th>File Type</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                candidateDocumentsList.map((item, index) =>
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{item.onlyfileName != "" ? item.onlyfileName : process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.fileName.split("https://master-upload.s3.amazonaws.com").pop()}</td>
                                        {/* <td>{moment(item.updatedAt).tz("Europe/Dublin").format('YYYY-MM-DD HH:mm:ss')}</td> */}
                                        <td>{moment(item.updatedAt).tz("Europe/Dublin").format('DD MMM YY')}</td>
                                        {/* <td>{item.fileType}</td> */}

                                        <td>
                                            <Dropdown className="dk-fileTypeDropdown">
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    {item.fileType}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {
                                                        candidateDocTypeList.map((data, index) =>
                                                            <Dropdown.Item key={index + 1} href="#" onClick={() => changeFileType(item.id, data.value)}>{data.value}</Dropdown.Item>
                                                        )
                                                    }

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>

                                        <td>
                                            <div className="actionColumn">
                                                <DocumentModal name="cv" canId={item.canId} file={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.fileName.split("https://master-upload.s3.amazonaws.com").pop()} />
                                                <Link to="#" className="mr-2" onClick={showEditComments}><FaEdit /></Link>
                                                <Link to="#" onClick={() => showDeleteModal(item.id)}><i className="fa fa-trash"></i></Link>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Documents;
