import React from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from "react-redux";
//import { interviewCaseStatusColor } from "../../../helper/commonFunctions";

const InterviewCaseStage = ({ caseStatus, id, columnId, onItemClick }) => {
    const { interviewStageList } = useSelector(state => state.common);
    const selectValue = (value, color) => {
        onItemClick(id, columnId, value, color);
    }
 
    return (
        <React.Fragment>
            {
                interviewStageList.map((filteredItems, index) =>
                    <Dropdown.Item key={`case-stage-id${index}`} href="#" style={{ backgroundColor: filteredItems.bgColor, color: '#ffff'  }} onClick={() => selectValue(filteredItems.value, '')}>
                        {filteredItems.value}
                    </Dropdown.Item>
                )
            }
        </React.Fragment>
    )
}
export default InterviewCaseStage;