import React from 'react';
import Header from "../elements/header";
import TrainingMainMenu from "../elements/header/navbar/mainMenu/trainingmenu/TrainingMainMenu";

const TrainingLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="RESOURCE" moduleLink="/dkglobaladmin/supplier/dashboard" linkcolor="#3c4c62" mainheaderTitle=" - Job Boards" textcolor="#01796f" />
            <TrainingMainMenu />
            {children}
        </React.Fragment>
    )
}
export default TrainingLayout;