import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const SearchDatabaseDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkglobaladmin/admintraining/admins/search-database" className={`${pathname.match('/dkglobaladmin/admintraining/admins/search-database') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/searchdatabase/situations" className={`${pathname.match('/dkglobaladmin/admintraining/admins/searchdatabase/situations') ? 'active' : ''}`}>Situations</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/searchdatabase/task-rules" className={`${pathname.match('/dkglobaladmin/admintraining/admins/searchdatabase/task-rules') ? 'active' : ''}`}>Task Rules</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/searchdatabase/how-to-do" className={`${pathname.match('/dkglobaladmin/admintraining/admins/searchdatabase/how-to-do') ? 'active' : ''}`}>How To Do</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/searchdatabase/work-plan" className={`${pathname.match('/dkglobaladmin/admintraining/admins/searchdatabase/work-plan') ? 'active' : ''}`}>Work Plan</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/searchdatabase/resource" className={`${pathname.match('/dkglobaladmin/admintraining/admins/searchdatabase/resource') ? 'active' : ''}`}>Resource</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/searchdatabase/how-to-search" className={`${pathname.match('/dkglobaladmin/admintraining/admins/searchdatabase/how-to-search') ? 'active' : ''}`}>How to Search</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/searchdatabase/create-project" className={`${pathname.match('/dkglobaladmin/admintraining/admins/searchdatabase/create-project') ? 'active' : ''}`}>Create Project</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/searchdatabase/send-mini-specs" className={`${pathname.match('/dkglobaladmin/admintraining/admins/searchdatabase/send-mini-specs') ? 'active' : ''}`}>Send Mini Specs</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default SearchDatabaseDetailLeftPanel;

