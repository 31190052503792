import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData } from "../../../services";

export const candidateDoc = createAsyncThunk(
    'candidate/doc-details',
    async ({ canId }, thunkAPI) => {
        const res = await GetData(true, '/candidates/profile/document/' + canId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

