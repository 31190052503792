import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import "./responsiveMenu.scss";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const KpiSubmenu = (props) => {
    const classes = useStyles();
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    const segment4 = pathname.split("/")[4];

    const [toggleMenu, setToggleMenu] = useState(false);
    useEffect(() => {
        setToggleMenu(false)
    }, [pathname])

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setToggleMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);


    return (
        <React.Fragment>
            <div className={`dk-subMenuMain res-sub-menu${(segment1 == 'kpis' && segment2 == 'kpi-summary') ? '' : ' '}`} ref={ref}>
                <IconButton aria-label="delete" className="res-sub-btn" onClick={() => setToggleMenu(!toggleMenu)}>
                    <i className="fas fa-bars"></i>
                </IconButton>
                <Route>
                    <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
                        <li><Link to="/kpis/kpi-summary" className={(segment2 == 'kpi-summary') ? 'active' : ''}>Summary</Link></li>
                        {/* <li><Link to="/kpis/candidates/dashboard" className={`${(segment2 == 'candidates') ? 'active' : ''}`}>Candidates</Link></li> */}
                        <li><Link to="/kpis/sales-kpi/team-sale" className={`${(segment2 == 'sales-kpi') ? 'active' : ''}`}>Sales</Link></li>
                        <li><Link to="/kpis/recruiters" className={`${(segment2 == 'recruiters') ? 'active' : ''}`}>Sendouts</Link></li>
                        <li><Link to="/kpis/tasks" className={`${(segment2 == 'tasks') ? 'active' : ''}`}>Tasks</Link></li>
                        <li><Link to="/kpis/kpi-jobs" className={`${(segment2 == 'kpi-jobs') ? 'active' : ''}`}>Jobs</Link></li>
                        <li><Link to="/kpis/candidates/dashboard" className={`${(segment2 == 'candidates') ? 'active' : ''}`}>Case</Link></li>
                        <li><Link to="/kpis/clients" className={`${(segment2 == 'clients') ? 'active' : ''}`}>Clients</Link></li>
                        {/* <li><Link to="#/kpis/ads-post" className={`${(segment2 == 'ads-post') ? 'active' : ''}`}>Ads Post</Link></li> */}
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default KpiSubmenu;
