import React from 'react';
import './personalrelationsdashboard.scss';

const PersonalRelationsDashboard = () => {
    return (
        <>
            <div className="dk-adminPrDashboard-main">
                <h2>Personal Relations</h2>
            </div>
        </>
    )
}

export default PersonalRelationsDashboard;
