import React, { useState } from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import { FaCheck, FaTimes, FaQuestion } from "react-icons/fa";

const ProfileStatusOptions = ({ id, columnId, updateMyData }) => {
    const selectValue = (value, color) => {
        // const setValue = e.target.dataset.value;
        updateMyData(id, columnId, value, color);
    }
    return (
        <React.Fragment>

            <Dropdown.Item href="#" data-value="1" data-column="profileStatus" onClick={() => selectValue(1, '#048204')}>
                <div className="dk-idDropdown-cols">
                    <span>
                        <FaCheck style={{ color: "#048204" }} />
                    </span>
                    {/* <span className="dkg-tricker">100%</span> */}
                    <span className="dkg-tricker">High Priority</span>
                </div>
            </Dropdown.Item>
            <Dropdown.Item href="#" data-value="2" data-column="profileStatus" onClick={() => selectValue(2, '#D58B5D')}>
                <div className="dk-idDropdown-cols">
                    <span>
                        <FaCheck style={{ color: "#D58B5D" }} />
                    </span>
                    {/* <span className="dkg-tricker">75%</span> */}
                    <span className="dkg-tricker">Mid Priority</span>
                </div>
            </Dropdown.Item>
            {/* <Dropdown.Item href="#" data-value="3" data-column="profileStatus" onClick={() => selectValue(3, '#3a76b4')}>
                <div className="dk-idDropdown-cols">
                    <span>
                        <FaCheck style={{ color: "#3a76b4" }} />
                    </span>
                    <span className="dkg-tricker">50%</span>
                    <span className="dkg-tricker">Matched</span>
                </div>
            </Dropdown.Item> */}
            <Dropdown.Item href="#" data-value="4" data-column="profileStatus" onClick={() => selectValue(4, '#d60101')}>
                <div className="dk-idDropdown-cols">
                    <span>
                        <FaCheck style={{ color: "#d60101" }} />
                    </span>
                    {/* <span className="dkg-tricker">0%</span> */}
                    <span className="dkg-tricker">Low Priority</span>
                </div>
            </Dropdown.Item>
            <Dropdown.Item href="#" data-value="0" data-column="profileStatus" onClick={() => selectValue(0, '#d3d3d3')}>
                <div className="dk-idDropdown-cols">
                    <span>
                        <FaCheck style={{ color: "#d3d3d3" }} />
                    </span>
                    <span className="dkg-tricker">Blank</span>
                </div>
            </Dropdown.Item>
        </React.Fragment>
    )
}
export default ProfileStatusOptions;