import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData, DeleteData } from "../../services";

export const updatedataValue = createAsyncThunk(
    'jobsSpecClient/updatekeypair',
    async ({ id, key, value, jobsId, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/jobrelation/' + id, { key, value, jobsId, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updatePortalsValue = createAsyncThunk(
    'jobsSpecClient/updatePortalskeypair',
    async ({ id, key, value, jobsId, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/jobrelation/' + id, { key, value, jobsId, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateJoinningValue = createAsyncThunk(
    'jobsSpecClient/updateJoinning',
    async ({ id, key, value, jobsId, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/jobrelation/' + id, { key, value, jobsId, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateRelocationValue = createAsyncThunk(
    'jobsSpecClient/updateRelocationkeypair',
    async ({ id, key, value, jobsId, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/jobrelation/' + id, { key, value, jobsId, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateJobofferValue = createAsyncThunk(
    'jobsSpecClient/updateJobofferkeypair',
    async ({ id, key, value, jobsId, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/jobrelation/' + id, { key, value, jobsId, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateInterviewValue = createAsyncThunk(
    'jobsSpecClient/updateInterviewkeypair',
    async ({ id, key, value, jobsId, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/jobrelation/' + id, { key, value, jobsId, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateSendoutValue = createAsyncThunk(
    'jobsSpecClient/updateSendoutkeypair',
    async ({ id, key, value, jobsId, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/jobrelation/' + id, { key, value, jobsId, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateRoleValue = createAsyncThunk(
    'jobsSpecClient/updateRolekeypair',
    async ({ id, key, value, jobsId, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/jobrelation/' + id, { key, value, jobsId, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateFaqsValue = createAsyncThunk(
    'jobsSpecClient/updatefaqs',
    async ({ id, key, value, jobsId, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/jobrelation/' + id, { key, value, jobsId, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateLangValue = createAsyncThunk(
    'jobsSpecClient/updateLangkeypair',
    async ({ id, key, value, jobsId, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/jobrelation/' + id, { key, value, jobsId, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateProjectValue = createAsyncThunk(
    'jobsSpecClient/updateProjectkeypair',
    async ({ id, key, value, jobsId, clientId, clientname, tableName }, thunkAPI) => {
        const res = await PutData(true, '/jobs-company/jobrelation/' + id, { key, value, jobsId, clientId, clientname, tableName })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const defaultJobsRole = createAsyncThunk(
    'jobsSpecClient/fetchRoletype',
    async ({ jobsId, tbl }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/defultcompany/' + jobsId + '/' + tbl)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const defaultJobsProject = createAsyncThunk(
    'jobsSpecClient/fetchByProject',
    async ({ jobsId, tbl }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/defultcompany/' + jobsId + '/' + tbl)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const defaultSendoutProject = createAsyncThunk(
    'jobsSpecClient/sendout',
    async ({ jobsId, tbl }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/defultcompany/' + jobsId + '/' + tbl)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const defaultPortalProject = createAsyncThunk(
    'jobsSpecClient/fetchByPortal',
    async ({ jobsId, tbl }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/defultcompany/' + jobsId + '/' + tbl)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const defaultJobofferProject = createAsyncThunk(
    'jobsSpecClient/defaultJoboffer',
    async ({ jobsId, tbl }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/defultcompany/' + jobsId + '/' + tbl)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const defaultWhyapply = createAsyncThunk(
    'jobsSpecClient/default/whyapply',
    async ({ jobsId, tbl }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/defultcompany/' + jobsId + '/' + tbl)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const defaultFaqs = createAsyncThunk(
    'jobsSpecClient/defaultfaqs',
    async ({ jobsId, tbl }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/defultcompany/' + jobsId + '/' + tbl)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const defaultJobsCompnay = createAsyncThunk(
    'jobsSpecClient/fetchByComp',
    async ({ jobsId, tbl }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/defultcompany/' + jobsId + '/' + tbl)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const defaultJoinning = createAsyncThunk(
    'jobsSpecClient/fetchByJoinning',
    async ({ jobsId, tbl }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/defultcompany/' + jobsId + '/' + tbl)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const defaultJoboRelocation = createAsyncThunk(
    'jobsSpecClient/fetchByyJobrelocation',
    async ({ jobsId, tbl }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/defultcompany/' + jobsId + '/' + tbl)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const defaultInterviewProject = createAsyncThunk(
    'jobsSpecClient/fetchByInterview',
    async ({ jobsId, tbl }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/defultcompany/' + jobsId + '/' + tbl)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const allJobsSpecCompnay = createAsyncThunk(
    'jobsSpecClient/allfetechByIdcompany',
    async (thunkAPI) => {
        const res = await GetData(true, '/requirnments-category')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const allclientsproject = createAsyncThunk(
    'jobsSpecClient/allfetechByIdproject',
    async ({ }, thunkAPI) => {
        const res = await GetData(true, '/category-project-forms')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const allclientsRoletype = createAsyncThunk(
    'jobsSpecClient/allfetechByIdrole',
    async ({ }, thunkAPI) => {
        const res = await GetData(true, '/category-client-forms')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const allclientsPortals = createAsyncThunk(
    'jobsSpecClient/allPortalsfetechById',
    async ({ }, thunkAPI) => {
        const res = await GetData(true, '/client-portals-category')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const allJoinning = createAsyncThunk(
    'jobsSpecClient/allJoiningfetechById',
    async ({ }, thunkAPI) => {
        const res = await GetData(true, '/category-europe-forms')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const allclientsRelocation = createAsyncThunk(
    'jobsSpecClient/aRelocationfetechById',
    async (thunkAPI) => {
        const res = await GetData(true, '/relocation-category')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const allclientsJobOffer = createAsyncThunk(
    'jobsSpecClient/allJobofferfetechById',
    async ({ }, thunkAPI) => {
        const res = await GetData(true, '/client-roletype-category')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const allclientsInterview = createAsyncThunk(
    'jobsSpecClient/allInterviewfetechById',
    async ({ }, thunkAPI) => {
        const res = await GetData(true, '/common-process-category')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)
export const allclientsSendout = createAsyncThunk(
    'jobsSpecClient/allSendoutfetechById',
    async ({ }, thunkAPI) => {
        const res = await GetData(true, '/category-europe-forms')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const allclientsLanguge = createAsyncThunk(
    'jobsSpecClient/allfetechByIdLang',
    async ({ }, thunkAPI) => {
        const res = await GetData(true, '/category-screening-forms')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const allFaqs = createAsyncThunk(
    'jobsSpecClient/allFaqs',
    async ({ }, thunkAPI) => {
        const res = await GetData(true, '/faqs-company')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const projectcomp = createAsyncThunk(
    'jobsSpecClient/fetechByIdcompany',
    async ({ jobId }, thunkAPI) => {
        const res = await GetData(true, '/jobs-company/projectcomp/' + jobId)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const getCategories = createAsyncThunk(
    'script/allCategory',
    async (thunkAPI) => {
        const res = await GetData(true, '/requirnments-category/')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getdefaultSubCategories = createAsyncThunk(
    "script2/frontend/demands/subcatlist",
    async ({ keys }, thunkAPI) => {
        const res = await GetData(true, "/requirnments-sub-category/default/" + keys);
        if (res.status === 200) {
            return res.payload;
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getSubCategories = createAsyncThunk(
    'script/allSubCategory',
    async ({ categoryId, keys }, thunkAPI) => {
        const res = await GetData(true, '/requirnments-sub-category/' + categoryId + '/' + keys)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getSubCatDetails = createAsyncThunk(
    'script/detailsSubCategory',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/requirnments-sub-category/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


const usersSlice = createSlice({
    name: 'jobsSpec',
    initialState: {
        allCompanyList: [],
        companyList: [],
        whyapplysList: [],
        joinningData: [],
        defaultJoinningList: [],
        faqssList: [],
        jobofferList: [],
        InterviewList: [],
        jobrelocationList: [],
        projectsList: [],
        portalsList: [],
        sendoutsList: [],
        roletypeList: [],
        projectRelationData: [],
        roletypeRelationData: [],
        sendoutRelationData: [],
        interviewRelationData: [],
        jobfferRelationData: [],
        relocationRelationData: [],
        portalsRelationData: [],
        LangRelationData: [],
        jobfaqssdata: [],
        clientAllData: [],
        categoryList: [],
        subCategoryList: [],
        subCategoryDetails: [],
        subDetails: [],
        isSuccess: false,
        isLoading: false,
        isSubLoading: false,
        isError: false,
        isData: false,
        isjobLoading: false,
        isFaqSuccess: false,
        isWhyapplySuccess: false,
        isJoinningSuccess: false,
        isRelocationSuccess: false,
        isJobofferSuccess: false,
        isInterviewSuccess: false,
        iss2Loading: false,
        is3Loading: false,
        is4Loading: false,
        is5Loading: false,
        is6Loading: false,
    },
    reducers: {
        clearState: (state) => {
            state.is6Loading = false;
            state.is5Loading = false;
            state.is4Loading = false;
            state.is3Loading = false;
            state.iss2Loading = false;
            state.isLoading = false;
            state.isSubCatInsert = false;
            state.isSuccess = false;
            state.isJoinningSuccess = false;
            state.isFaqSuccess = false;
            state.isError = false;
            state.isData = false;
            state.isjobLoading = false;
            state.isWhyapplySuccess = false;
            state.isRelocationSuccess = false;
            state.isJobofferSuccess = false;
            state.isInterviewSuccess = false;
        }
    },
    extraReducers: {

        [getdefaultSubCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isTabActive = false;
        },
        [getdefaultSubCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isTabActive = true;
            state.isSuccess = true;
            state.subCategoryList = payload.result;
        },
        [getdefaultSubCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.isTabActive = false;
        },


        [allclientsLanguge.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [allclientsLanguge.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.LangRelationData = payload.result;
        },
        [allclientsLanguge.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [allFaqs.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [allFaqs.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.jobfaqssdata = payload.result;
        },
        [allFaqs.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [allclientsPortals.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [allclientsPortals.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.portalsRelationData = payload.result;
        },
        [allclientsPortals.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [allclientsRelocation.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [allclientsRelocation.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.relocationRelationData = payload.result;
        },
        [allclientsRelocation.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [allJoinning.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [allJoinning.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.joinningData = payload.result;
        },
        [allJoinning.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [allclientsJobOffer.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [allclientsJobOffer.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.jobfferRelationData = payload.result;
        },
        [allclientsJobOffer.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [allclientsInterview.pending]: (state) => {
            state.is5Loading = true;
            state.isError = false;
        },
        [allclientsInterview.fulfilled]: (state, { payload }) => {
            state.is5Loading = false;
            state.isSuccess = true;
            state.interviewRelationData = payload.result;
        },
        [allclientsInterview.rejected]: (state, { payload }) => {
            state.isError = true;
            state.is5Loading = false;
            state.isSuccess = false;
        },
        [allclientsSendout.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [allclientsSendout.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.sendoutRelationData = payload.result;
        },
        [allclientsSendout.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [allclientsRoletype.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [allclientsRoletype.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.roletypeRelationData = payload.result;
        },
        [allclientsRoletype.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [allclientsproject.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = true;
            // state.isData = true;
        },
        [allclientsproject.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.projectRelationData = payload.result;
        },
        [allclientsproject.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [defaultSendoutProject.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            // state.isData = true;
        },
        [defaultSendoutProject.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.sendoutsList = payload.result;
        },
        [defaultSendoutProject.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [defaultPortalProject.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            // state.isData = true;
        },
        [defaultPortalProject.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.portalsList = payload.result;
        },
        [defaultPortalProject.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [defaultJobsProject.pending]: (state) => {
            state.is6Loading = false;
            state.isError = false;
            // state.isData = true;
        },
        [defaultJobsProject.fulfilled]: (state, { payload }) => {
            state.is6Loading = true;
            state.projectsList = payload.result;
        },
        [defaultJobsProject.rejected]: (state, { payload }) => {
            state.isError = true;
            state.is6Loading = false;
        },
        [defaultJobsRole.pending]: (state) => {
            state.is3Loading = false;
            state.isError = false;
            // state.isData = true;
        },
        [defaultJobsRole.fulfilled]: (state, { payload }) => {
            state.is3Loading = true;
            state.roletypeList = payload.result;
        },
        [defaultJobsRole.rejected]: (state, { payload }) => {
            state.isError = true;
            state.is3Loading = false;
        },
        [defaultJobofferProject.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isJobofferSuccess = false;
        },
        [defaultJobofferProject.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isJobofferSuccess = true;
            state.jobofferList = payload.result;
        },
        [defaultJobofferProject.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isJobofferSuccess = false;
        },
        [defaultFaqs.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isFaqSuccess = false;
        },
        [defaultFaqs.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isFaqSuccess = true;
            state.faqssList = payload.result;
        },
        [defaultFaqs.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isFaqSuccess = false;
        },
        [defaultWhyapply.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isWhyapplySuccess = false;
        },
        [defaultWhyapply.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isWhyapplySuccess = true;
            state.whyapplysList = payload.result;
        },
        [defaultWhyapply.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isWhyapplySuccess = false;
        },
        [defaultJobsCompnay.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            // state.isData = true;
        },
        [defaultJobsCompnay.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.companyList = payload.result;
        },
        [defaultJobsCompnay.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [defaultJoinning.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isJoinningSuccess = false;
        },
        [defaultJoinning.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isJoinningSuccess = true;
            state.defaultJoinningList = payload.result;
        },
        [defaultJoinning.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isJoinningSuccess = false;
        },

        [defaultJoboRelocation.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isRelocationSuccess = false;
            // state.isData = true;
        },
        [defaultJoboRelocation.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isRelocationSuccess = true;
            state.jobrelocationList = payload.result;
        },
        [defaultJoboRelocation.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isRelocationSuccess = false;
        },
        [defaultInterviewProject.pending]: (state) => {
            state.is4Loading = true;
            state.isError = false;
            state.isInterviewSuccess = false;

        },
        [defaultInterviewProject.fulfilled]: (state, { payload }) => {
            state.is4Loading = false;
            state.isInterviewSuccess = true;
            state.InterviewList = payload.result;
        },
        [defaultInterviewProject.rejected]: (state, { payload }) => {
            state.isError = true;
            state.is4Loading = false;
            state.isInterviewSuccess = false;
        },
        [allJobsSpecCompnay.pending]: (state) => {
            state.isLoading = true;
            state.isjobLoading = true;
            state.isError = false;
            // state.isData = true;
        },
        [allJobsSpecCompnay.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isjobLoading = false;
            state.allCompanyList = payload.result;
        },
        [allJobsSpecCompnay.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isjobLoading = false;
            state.isSuccess = false;
        },

        [updateFaqsValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
        },
        [updateFaqsValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.faqssList = payload.result;
        },
        [updateFaqsValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateLangValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updateLangValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.whyapplysList = payload.result;
        },
        [updateLangValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateJoinningValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updateJoinningValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.defaultJoinningList = payload.result;
        },
        [updateJoinningValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateRelocationValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updateRelocationValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.jobrelocationList = payload.result;
        },
        [updateRelocationValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updatePortalsValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updatePortalsValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.portalsList = payload.result;
        },
        [updatePortalsValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateJobofferValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updateJobofferValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.jobofferList = payload.result;
        },
        [updateJobofferValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateInterviewValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updateInterviewValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.InterviewList = payload.result;
        },
        [updateInterviewValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateSendoutValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updateSendoutValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.sendoutsList = payload.result;
        },
        [updateSendoutValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [updateRoleValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updateRoleValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.roletypeList = payload.result;
        },
        [updateRoleValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updateProjectValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [updateProjectValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.projectsList = payload.result;
        },
        [updateProjectValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [updatedataValue.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
        },
        [updatedataValue.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.companyList = payload.result;
        },
        [updatedataValue.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [projectcomp.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            // state.isData = true;
        },
        [projectcomp.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.clientAllData = payload.result;
        },
        [projectcomp.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [getCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
            // state.isData = true;
        },
        [getCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.categoryList = payload.result;
        },
        [getCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },

        [getSubCategories.pending]: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        [getSubCategories.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.subCategoryList = payload.result;
        },
        [getSubCategories.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isLoading = false;
            state.isSuccess = false;
        },
        [getSubCatDetails.pending]: (state) => {
            state.isData = false;
            state.isError = false;
        },
        [getSubCatDetails.fulfilled]: (state, { payload }) => {
            state.isData = true;
            state.subDetails = payload.result;
        },
        [getSubCatDetails.rejected]: (state, { payload }) => {
            state.isError = true;
            state.isData = false;
        }


    }
})
export const { clearState } = usersSlice.actions
export default usersSlice.reducer;