import React from 'react';
import './myacc-facilites.scss'
import { Tab, Nav } from 'react-bootstrap';
import GetStartedLeftPanel from './leftpanel/GetStartedLeftPanel';
import GetStartedStepOneDetailTabs from './leftpanel/GetStartedStepOneDetailTabs';


const MSMailbox = () => {
    return (
        <>
            <div className='dkg-myacc-facilities-main-Con container-fluid'>
                <div className='row dkg-facilities-mainCon'>
                    <div className='col-md-12 dkg-facilities-boxCon'>
                        <div className="dk-moduleTraining-cols">
                            <GetStartedLeftPanel />
                            <div className="dk-moduleTraining-rightPanel">
                                <div className="title">Microsoft Mailbox</div>
                                <div className="dk-moduleTraining-rightCols">
                                    <div className="dk-getStartedRight-detail">
                                        <div className="dk-rightDetail-panel">
                                            <GetStartedStepOneDetailTabs />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MSMailbox;