import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
const GridStatus = ({ value }) => {
    let bgColor = "";
    let txtColor = "";
    switch (value) {
        case 'Active':
            bgColor = '#30887e'
            txtColor = "#fff"
            break;
        case 'Inactive':
            bgColor = '#b55355'
            txtColor = "#fff"
            break;

        case 'Urgent':
            bgColor = '#B55355'
            txtColor = "#fff"
            break;

        case 'Qualify':
            bgColor = '#D58B5D'
            txtColor = "#fff"
            break;

        case 'On Hold':
            bgColor = '#7f5347'
            txtColor = "#fff"
            break;

        case 'Closed':
            bgColor = '#e2445c'
            txtColor = "#fff"
            break;
    }


    return (
        <>
            <Dropdown.Toggle variant="" style={{ backgroundColor: bgColor , color: txtColor}} className="dkg-livejob-status-boxCols">{ value }</Dropdown.Toggle>

        </>
    )
}

export default GridStatus;