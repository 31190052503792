import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData, addCandidate } from "../../services";

export const addValue = createAsyncThunk(
    'kpi/team/add',
    async ({ year, month, uid, sale, refusedOffers,candidate, credit, bonus, netSale, salesTarget }, thunkAPI) => {
        const res = await PostData(true, '/kpis', { year, month, uid, sale, refusedOffers,candidate, credit, bonus, netSale, salesTarget })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const addClientKpiValue = createAsyncThunk(
    'kpi/client/add',
    async ({ year, month, uid, sale, refusedOffers, credit, bonus, netSale, salesTarget }, thunkAPI) => {
        const res = await PostData(true, '/clients-kpis', { year, month, uid, sale, refusedOffers, credit, bonus, netSale, salesTarget })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const updateValue = createAsyncThunk(
    'kpi/team/update',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/kpis/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updateUser = createAsyncThunk(
    'kpi/team/updateUser',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/kpis/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


export const accountManagerListTable = createAsyncThunk(
    'kpi/team/AMlist',
    async ({ year, month }, thunkAPI) => {
        const res = await GetData(true, '/kpis/accountManager/' + year + '/' + month)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const listAll = createAsyncThunk(
    'kpi/team/list',
    async ({ year, month }, thunkAPI) => {
        const res = await GetData(true, '/kpis/' + year + '/' + month)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const annualSaleList = createAsyncThunk(
    'kpi/teamAnnual/list',
    async ({ year }, thunkAPI) => {
        const res = await GetData(true, '/kpis/' + year )
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const annualMonthlySaleList = createAsyncThunk(
    'kpi/teamMonthlyAnnual/list',
    async ({ year,month }, thunkAPI) => {
        const res = await GetData(true, '/kpis/monthly/' + year )
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const bulkDelete = createAsyncThunk(
    'kpi/team/bulkDelete',
    async ({ year, month }, thunkAPI) => {
        const res = await GetData(true, '/kpis/' + year + '/' + month)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const TeamKpiSlice = createSlice({
    name: 'teamKpi',
    initialState: {
        dataList: [],
        clientKpiDataList: [],
        AMDataList: [],
        annualDataList: [],
        annualMonthlyDataList: [],
        userData: {},
        isAMLoading: false,
        isLoading: false,
        isError: false,
        isSuccess: false,
        message: '',
        isBulkDelete: false,
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.isUpdate = false;
            state.message = '';
            state.isBulkDelete = false;
        },
        bulkDeleteState: (state) => {
            state.isDelete = true
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            addValue.pending, (state) => {
            });
        builder.addCase(
            addValue.fulfilled, (state, { payload }) => {
                state.dataList.push(payload.result)
                state.isSuccess = true;
            });
        builder.addCase(
            addValue.rejected, (state, action) => {
                state.isSuccess = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            addClientKpiValue.pending, (state) => {
            });
        builder.addCase(
            addClientKpiValue.fulfilled, (state, { payload }) => {
                state.clientKpiDataList.push(payload.result)
                state.isSuccess = true;
            });
        builder.addCase(
            addClientKpiValue.rejected, (state, action) => {
                state.isSuccess = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateValue.pending, (state) => {
                state.isUpdate = false
            });
        builder.addCase(
            updateValue.fulfilled, (state, { payload }) => {
                let mainArray = state.dataList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.dataList = finalResult;
            });
        builder.addCase(
            updateValue.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            accountManagerListTable.pending, (state) => {
                state.isAMLoading = true;
            });
        builder.addCase(
            accountManagerListTable.fulfilled, (state, { payload }) => {
                state.AMDataList = payload.result
                state.isAMLoading = false;
            });
        builder.addCase(
            accountManagerListTable.rejected, (state, action) => {
                state.isAMLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            listAll.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            listAll.fulfilled, (state, { payload }) => {
                state.dataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            listAll.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
       
       builder.addCase(
            annualSaleList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            annualSaleList.fulfilled, (state, { payload }) => {
                state.annualDataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            annualSaleList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

       builder.addCase(
            annualMonthlySaleList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            annualMonthlySaleList.fulfilled, (state, { payload }) => {
                state.annualMonthlyDataList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            annualMonthlySaleList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateUser.pending, (state) => {
                //  state.isLoading = true;
            });
        builder.addCase(
            updateUser.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isUpdate = true;
                let mainArray = state.dataList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.dataList = finalResult
                state.msg = payload.message
            });
        builder.addCase(
            updateUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });


        builder.addCase(
            bulkDelete.pending, (state) => {
                //  state.isLoading = true;
            });
        builder.addCase(
            bulkDelete.fulfilled, (state, action) => {
                state.dataList = state.dataList.filter((item) => item.id !== action.meta.arg.id);
                state.isDelete = true;
            });
        builder.addCase(
            bulkDelete.rejected, (state, { payload }) => {
                state.isError = payload.message;
                state.errorMessage = payload.message;
            });
    }
});
export const { clearState, bulkDeleteState } = TeamKpiSlice.actions;
export default TeamKpiSlice.reducer;