import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ViewFeedback from "../viewfeedback/ViewFeedback";
import { Nav, Tab } from 'react-bootstrap';
import '../itteam.scss';

const Designer = () => {
    const [ViewFeedbackModal, setViewFeebackstate] = useState(false)
    const showViewFeedbackModal = () => {
        setViewFeebackstate(true)
    }
    const hideViewFeedbackModal = () => {
        setViewFeebackstate(false)
    }
    return (
        <>
            <div className="dk-itTeamTaskMain">
                <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                    <div className="d-flex w-100">
                        <div className="dk-itTeamTabs">
                            <Nav variant="pills">
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_1" className='dkg-team-todo-bg-24'>TO DO
                                        <span className='dkg-task-counter'>8</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_2" className='dkg-team-progress-bg-24'>IN PROGRESS
                                        <span className='dkg-task-counter'>8</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_3" className='dkg-team-completed-bg-24'>COMPLETED
                                        <span className='dkg-task-counter'>8</span>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>

                        <Tab.Content>
                            <Tab.Pane eventKey="tabs_1">
                                <div className="dk-itTeamTabsPanel">
                                    <div className="dk-teamtoDoList">
                                        <span onClick={showViewFeedbackModal}>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ullam omnis totam porro quaerat officia, sint fugiat delectus. Consequatur nesciunt obcaecati totam, cupiditate repellat alias? Non, reprehenderit dolore! Ipsa, eligendi.
                                        </span>
                                        <Dropdown>
                                            <ContextMenuTrigger id="same_unique_identifier">
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    To Do
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Link to="#" style={{ background: '#2685a5', color: '#fff' }}>To Do</Link>
                                                    <Link to="#" style={{ background: '#D58B5D', color: '#fff' }}>In Progress</Link>
                                                    <Link to="#" style={{ background: '#3A9469', color: '#fff' }}>Completed</Link>
                                                </Dropdown.Menu>
                                            </ContextMenuTrigger>
                                        </Dropdown>
                                        <ContextMenu id="same_unique_identifier">
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-edit"></i> Edit
                                            </MenuItem>
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-trash-alt"></i> Delete
                                            </MenuItem>
                                        </ContextMenu>

                                    </div>
                                    <div className="dk-teamtoDoList">
                                        <span onClick={showViewFeedbackModal}>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ullam omnis totam porro quaerat officia, sint fugiat delectus. Consequatur nesciunt obcaecati totam, cupiditate repellat alias? Non, reprehenderit dolore! Ipsa, eligendi.
                                        </span>
                                        <Dropdown>
                                            <ContextMenuTrigger id="same_unique_identifier">
                                                <Dropdown.Toggle variant="" id="dropdown-basic" style={{ color: '#D58B5D' }}>
                                                    In Progress
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Link to="#" style={{ background: '#2685a5', color: '#fff' }}>To Do</Link>
                                                    <Link to="#" style={{ background: '#D58B5D', color: '#fff' }}>In Progress</Link>
                                                    <Link to="#" style={{ background: '#3A9469', color: '#fff' }}>Completed</Link>
                                                </Dropdown.Menu>
                                            </ContextMenuTrigger>
                                        </Dropdown>
                                        <ContextMenu id="same_unique_identifier">
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-edit"></i> Edit
                                            </MenuItem>
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-trash-alt"></i> Delete
                                            </MenuItem>
                                        </ContextMenu>
                                    </div>
                                    <div className="dk-teamtoDoList">
                                        <span onClick={showViewFeedbackModal}>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ullam omnis totam porro quaerat officia, sint fugiat delectus. Consequatur nesciunt obcaecati totam, cupiditate repellat alias? Non, reprehenderit dolore! Ipsa, eligendi.
                                        </span>
                                        <Dropdown>
                                            <ContextMenuTrigger id="same_unique_identifier">
                                                <Dropdown.Toggle variant="" id="dropdown-basic" style={{ color: '#3A9469' }}>
                                                    Complete
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Link to="#" style={{ background: '#2685a5', color: '#fff' }}>To Do</Link>
                                                    <Link to="#" style={{ background: '#D58B5D', color: '#fff' }}>In Progress</Link>
                                                    <Link to="#" style={{ background: '#3A9469', color: '#fff' }}>Completed</Link>
                                                </Dropdown.Menu>
                                            </ContextMenuTrigger>
                                        </Dropdown>
                                        <ContextMenu id="same_unique_identifier">
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-edit"></i> Edit
                                            </MenuItem>
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-trash-alt"></i> Delete
                                            </MenuItem>
                                        </ContextMenu>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tabs_2">
                                <div className="dk-itTeamTabsPanel">
                                    <div className="dk-teamtoDoList">
                                        <span onClick={showViewFeedbackModal}>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ullam omnis totam porro quaerat officia, sint fugiat delectus. Consequatur nesciunt obcaecati totam, cupiditate repellat alias? Non, reprehenderit dolore! Ipsa, eligendi.
                                        </span>
                                        <Dropdown>
                                            <ContextMenuTrigger id="same_unique_identifier">
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    To Do
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Link to="#" style={{ background: '#2685a5', color: '#fff' }}>To Do</Link>
                                                    <Link to="#" style={{ background: '#D58B5D', color: '#fff' }}>In Progress</Link>
                                                    <Link to="#" style={{ background: '#3A9469', color: '#fff' }}>Completed</Link>
                                                </Dropdown.Menu>
                                            </ContextMenuTrigger>
                                        </Dropdown>
                                        <ContextMenu id="same_unique_identifier">
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-edit"></i> Edit
                                            </MenuItem>
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-trash-alt"></i> Delete
                                            </MenuItem>
                                        </ContextMenu>

                                    </div>
                                    <div className="dk-teamtoDoList">
                                        <span onClick={showViewFeedbackModal}>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ullam omnis totam porro quaerat officia, sint fugiat delectus. Consequatur nesciunt obcaecati totam, cupiditate repellat alias? Non, reprehenderit dolore! Ipsa, eligendi.
                                        </span>
                                        <Dropdown>
                                            <ContextMenuTrigger id="same_unique_identifier">
                                                <Dropdown.Toggle variant="" id="dropdown-basic" style={{ color: '#D58B5D' }}>
                                                    In Progress
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Link to="#" style={{ background: '#2685a5', color: '#fff' }}>To Do</Link>
                                                    <Link to="#" style={{ background: '#D58B5D', color: '#fff' }}>In Progress</Link>
                                                    <Link to="#" style={{ background: '#3A9469', color: '#fff' }}>Completed</Link>
                                                </Dropdown.Menu>
                                            </ContextMenuTrigger>
                                        </Dropdown>
                                        <ContextMenu id="same_unique_identifier">
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-edit"></i> Edit
                                            </MenuItem>
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-trash-alt"></i> Delete
                                            </MenuItem>
                                        </ContextMenu>
                                    </div>
                                    <div className="dk-teamtoDoList">
                                        <span onClick={showViewFeedbackModal}>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ullam omnis totam porro quaerat officia, sint fugiat delectus. Consequatur nesciunt obcaecati totam, cupiditate repellat alias? Non, reprehenderit dolore! Ipsa, eligendi.
                                        </span>
                                        <Dropdown>
                                            <ContextMenuTrigger id="same_unique_identifier">
                                                <Dropdown.Toggle variant="" id="dropdown-basic" style={{ color: '#3A9469' }}>
                                                    Complete
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Link to="#" style={{ background: '#2685a5', color: '#fff' }}>To Do</Link>
                                                    <Link to="#" style={{ background: '#D58B5D', color: '#fff' }}>In Progress</Link>
                                                    <Link to="#" style={{ background: '#3A9469', color: '#fff' }}>Completed</Link>
                                                </Dropdown.Menu>
                                            </ContextMenuTrigger>
                                        </Dropdown>
                                        <ContextMenu id="same_unique_identifier">
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-edit"></i> Edit
                                            </MenuItem>
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-trash-alt"></i> Delete
                                            </MenuItem>
                                        </ContextMenu>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tabs_3">
                                <div className="dk-itTeamTabsPanel">
                                    <div className="dk-teamtoDoList">
                                        <span onClick={showViewFeedbackModal}>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ullam omnis totam porro quaerat officia, sint fugiat delectus. Consequatur nesciunt obcaecati totam, cupiditate repellat alias? Non, reprehenderit dolore! Ipsa, eligendi.
                                        </span>
                                        <Dropdown>
                                            <ContextMenuTrigger id="same_unique_identifier">
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    To Do
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Link to="#" style={{ background: '#2685a5', color: '#fff' }}>To Do</Link>
                                                    <Link to="#" style={{ background: '#D58B5D', color: '#fff' }}>In Progress</Link>
                                                    <Link to="#" style={{ background: '#3A9469', color: '#fff' }}>Completed</Link>
                                                </Dropdown.Menu>
                                            </ContextMenuTrigger>
                                        </Dropdown>
                                        <ContextMenu id="same_unique_identifier">
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-edit"></i> Edit
                                            </MenuItem>
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-trash-alt"></i> Delete
                                            </MenuItem>
                                        </ContextMenu>

                                    </div>
                                    <div className="dk-teamtoDoList">
                                        <span onClick={showViewFeedbackModal}>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ullam omnis totam porro quaerat officia, sint fugiat delectus. Consequatur nesciunt obcaecati totam, cupiditate repellat alias? Non, reprehenderit dolore! Ipsa, eligendi.
                                        </span>
                                        <Dropdown>
                                            <ContextMenuTrigger id="same_unique_identifier">
                                                <Dropdown.Toggle variant="" id="dropdown-basic" style={{ color: '#D58B5D' }}>
                                                    In Progress
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Link to="#" style={{ background: '#2685a5', color: '#fff' }}>To Do</Link>
                                                    <Link to="#" style={{ background: '#D58B5D', color: '#fff' }}>In Progress</Link>
                                                    <Link to="#" style={{ background: '#3A9469', color: '#fff' }}>Completed</Link>
                                                </Dropdown.Menu>
                                            </ContextMenuTrigger>
                                        </Dropdown>
                                        <ContextMenu id="same_unique_identifier">
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-edit"></i> Edit
                                            </MenuItem>
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-trash-alt"></i> Delete
                                            </MenuItem>
                                        </ContextMenu>
                                    </div>
                                    <div className="dk-teamtoDoList">
                                        <span onClick={showViewFeedbackModal}>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ullam omnis totam porro quaerat officia, sint fugiat delectus. Consequatur nesciunt obcaecati totam, cupiditate repellat alias? Non, reprehenderit dolore! Ipsa, eligendi.
                                        </span>
                                        <Dropdown>
                                            <ContextMenuTrigger id="same_unique_identifier">
                                                <Dropdown.Toggle variant="" id="dropdown-basic" style={{ color: '#3A9469' }}>
                                                    Complete
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Link to="#" style={{ background: '#2685a5', color: '#fff' }}>To Do</Link>
                                                    <Link to="#" style={{ background: '#D58B5D', color: '#fff' }}>In Progress</Link>
                                                    <Link to="#" style={{ background: '#3A9469', color: '#fff' }}>Completed</Link>
                                                </Dropdown.Menu>
                                            </ContextMenuTrigger>
                                        </Dropdown>
                                        <ContextMenu id="same_unique_identifier">
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-edit"></i> Edit
                                            </MenuItem>
                                            <MenuItem data={{ foo: 'bar' }}>
                                                <i className="far fa-trash-alt"></i> Delete
                                            </MenuItem>
                                        </ContextMenu>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tabs_4">
                                <div className="dk-itTeamTabsPanel">
                                    <div className="dk-addNew-editor">
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data="<p>Please type here...</p>"
                                            onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                                console.log('Editor is ready to use!', editor);
                                            }}

                                        />

                                        <div className="dk-editorBtn mt-4">
                                            <button className="dk-btn cancel">Cancel</button>
                                            <button className="dk-btn">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default Designer;