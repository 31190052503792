import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './marketingnavbar.scss';

const ItTeamMainMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK Global CRM";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-marketingNavbar">
                <Route>
                    <ul>
                        <li><Link to="/marketing/summary" className={`${(segment2 === 'summary') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="#/marketing/templates" className={`${pathname.match('/templates') ? 'active' : ''}`}>Tasks</Link></li>
                        <li><Link to="#/marketing/ads-resources" className={`${pathname.match('/ads-resources') ? 'active' : ''}`}>Alerts</Link></li>
                        <li><Link to="#/marketing/titles" className={`${pathname.match('/titles') ? 'active' : ''}`}> Platforms</Link></li>
                        <li><Link to="#/marketing/saved-ads" className={(segment2 === "saved-ads") ? 'active' : ''}>Banners</Link></li>
                        <li><Link to="#/marketing/todays-tracker" className={`${pathname.match('/todays-tracker') ? 'active' : ''}`}>Campaigns</Link></li>
                        <li><Link to="#/marketing/ads-credits" className={`${pathname.match('/ads-credits') ? 'active' : ''}`}>Frontend</Link></li>
                        <li><Link to="#/marketing/live-tracker" className={`${pathname.match('/live-tracker') ? 'active' : ''}`}>Backend</Link></li>
                        <li><Link to="#/marketing/campaigns/live" className={(segment2 === "campaigns") ? 'active' : ''}>Schedules</Link></li>
                        <li><Link to="#/marketing/apps-tracker/daily-tracker" className={`${pathname.match('/apps-tracker') ? 'active' : ''}`}>Saved Files</Link></li>
                        <li><Link to="#/marketing/apps-tracker/daily-tracker" className={`${pathname.match('/apps-tracker') ? 'active' : ''}`}>Training</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default ItTeamMainMenu;