import React from 'react';
import { Dropdown } from 'react-bootstrap';

const DateTypeDropdown = (props) => {
    return (
        <React.Fragment>
            <Dropdown>
                <Dropdown.Toggle id={props.id} className={props.className} variant="">
                    <React.Fragment>
                        <div className='dropdown-selection d-flex align-items-center w-100'>
                            <i className="fas fa-calendar-alt pr-2"></i> {props.defaultTxt}
                        </div>
                        <i className="fas fa-chevron-down crossBtn"></i>
                    </React.Fragment>
                </Dropdown.Toggle>
                <Dropdown.Menu variant="" className='visible menu'>
                    <Dropdown.Item className='justify-content-start'><i className="fas fa-calendar-alt pr-2"></i>Created On</Dropdown.Item>
                    <Dropdown.Item className='justify-content-start'><i className="fas fa-calendar-alt pr-2"></i>Job Spec Sent</Dropdown.Item>
                    <Dropdown.Item className='justify-content-start'><i className="fas fa-calendar-alt pr-2"></i>CV Sendout</Dropdown.Item>
                    <Dropdown.Item className='justify-content-start'><i className="fas fa-calendar-alt pr-2"></i>Interview Prep Sent</Dropdown.Item>
                    <Dropdown.Item className='justify-content-start'><i className="fas fa-calendar-alt pr-2"></i>Placement</Dropdown.Item>
                    <Dropdown.Item className='justify-content-start'><i className="fas fa-calendar-alt pr-2"></i>Joining</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    )
}
export default DateTypeDropdown;