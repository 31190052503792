import React, { useState } from 'react';
import './dkglobalcompany.scss';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import EstablishedLeftTabs from './detaillefttabs/EstablishedLeftTabs';
import MoreDetailModal from './MoreDetailModal';
import CompanyLeftPanel from "./leftpanel/CompanyLeftPanel";

const DKGlobalIndustry = () => {
    const [isShowMore, setIsShowMore] = useState(false);
    const showmoreDetails = () => {
        console.log("Show")
        setIsShowMore(true);
    }
    const hidemoreDetails = () => {
        setIsShowMore(false);
    }
    return (
        <>
            {
                (isShowMore) ? <MoreDetailModal handleClose={hidemoreDetails} /> : null
            }

            <div className="dk-companyDetailMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <CompanyLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                <h3 className="title">Industry</h3>
                                <Tabs defaultActiveKey="tabs_1">
                                    <Tab eventKey="tabs_1" title="Details">
                                        <div className="d-flex">
                                            <EstablishedLeftTabs />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tabs_2" title="Samples">
                                        <div className="d-flex">
                                            <EstablishedLeftTabs />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tabs_3" title="Video">
                                        <div className="d-flex">
                                            <EstablishedLeftTabs />
                                        </div>
                                    </Tab>
                                </Tabs>
                                <div title="More" className="clickMoreShow" onClick={showmoreDetails}>
                                    More
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default DKGlobalIndustry;
