import React from 'react';
import { Link } from "react-router-dom";

const TeamsLeftPanel = () => {
    const pathname = window.location.pathname;
    const segment4 = pathname.split("/")[4];
    return (
        <>
            <div className="dk-compyteam-Leftpanel">
                <ul>
                    <li><Link to="/commontraining/dkglobal/teams/team-1" className={`${(segment4 === 'team-1') ? 'active' : ''}`}>TEAM 1 - MANAGEMENT</Link></li>
                    <li><Link to="/commontraining/dkglobal/teams/team-2" className={`${(segment4 === 'team-2') ? 'active' : ''}`}>TEAM 2 - ADMINISTRATION</Link></li>
                    <li><Link to="/commontraining/dkglobal/teams/team-3" className={`${(segment4 === 'team-3') ? 'active' : ''}`}>TEAM 3 - TECH SUPPORT</Link></li>
                    <li><Link to="/commontraining/dkglobal/teams/team-4" className={`${(segment4 === 'team-4') ? 'active' : ''}`}>TEAM 4 - PRODUCTION</Link></li>
                </ul>
            </div>
        </>
    )
}

export default TeamsLeftPanel;
