import React, { useState } from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from "react-redux";
//import DefaultProfile from '../../../assets/images/default_profile_empty.png';
//import "./userlist.scss";
import Tooltip from "../../ui/tooltip";
const UserList = ({ rowid = 0, onClick }) => {
    const { usersList } = useSelector(state => state.users)
    // const [usersList, setUsersList] = useState([])
     const removeValFromIndex = [9,10,11]; //not to see users in pipeline, remove user from usersList
    const indexSet = new Set(removeValFromIndex);
    const newUsers = usersList.filter((value, i) => !indexSet.has(i));

    return (

        <React.Fragment>
            {newUsers.map((list, index) => (
                <Dropdown.Item href="#">
                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + list.profileImage} className="img-fluid" alt={list.profileImage} data-id={list.id} data-rowid={rowid} onClick={onClick} />
                </Dropdown.Item>
            ))}
        </React.Fragment>

    )
}
export default UserList;