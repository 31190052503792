import React, { useEffect, useState } from 'react'
import "./selectinstance.css";
import { Select } from 'semantic-ui-react';

const SelectInstance = (props) => {
    const [optionList, setOptionList] = useState([])
    useEffect(() => {
        props.options && props.options.length > 0 && props.options.map((value, index) => {
            optionList.push({ key: value._id, value: value._id, text: value.value, style: { color: value.textColor, backgroundColor: value.bgColor } })
        })
    }, [props.value])

    return (
        <React.Fragment>
            <Select placeholder={props.placeholder} options={optionList} className={`dk-overdues-task-slect`} name={props.name} onChange={props.onChange} />
        </React.Fragment>
    )
}

export default SelectInstance;
