import React, { useState } from 'react'
import FormControl from 'react-bootstrap/FormControl';
import Dropdown from 'react-bootstrap/Dropdown'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className=""
                    placeholder="Search Text..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toString().toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);

const SearchinputDropdwn = (props) => {
    return (
        <>
            <div className="dk-jobssearchFilter">
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-custom-components" as={CustomToggle} className="dkcreatetoggle">
                        {props.dropdwonTitle}
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu} className="dk-mailshotDroMenu">
                        <Dropdown.Item eventKey="1">Genpact Bucharest
                            <span className="counterNum">14</span>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="2">Wipro Dublin
                            <span className="counterNum">8</span>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="3">TP Greece
                            <span className="counterNum">4</span>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="4">Sitel
                            <span className="counterNum">6</span>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="5">Genpact Bucharest
                            <span className="counterNum">24</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default SearchinputDropdwn;
