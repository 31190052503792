import React from 'react';
import LuizaProjectClosedLeftPanel from "../leftpanel/luiza/LuizaProjectClosedLeftPanel";
import '../moduleprojects.scss';

const LuizaProjectClosed = () => {
    return (
        <>
            <div className="dk-moduleProjects-main">
                <div className="dk-moduleTraining-cols">
                    <LuizaProjectClosedLeftPanel />
                    <div className="dk-moduleTraining-rightPanel">
                        <div className="title">Summary</div>
                        <div className="dk-moduleTraining-rightCols">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LuizaProjectClosed;
