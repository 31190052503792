import React from 'react';
import { Link } from "react-router-dom";

const SalesKpiNav = () => {
    let pathname = window.location.pathname;
    // const segment2 = pathname.split("/")[1];
    return (
        <>
            <ul>
                <li><Link to="/kpis/sales-kpi/team-sale" className={`${pathname.match('/kpis/sales-kpi/team-sale') ? 'active' : ''}`}>SUMMARY</Link></li>
                <li><Link to="/kpis/sales-kpi/dashboard" className={`${pathname.match('/kpis/sales-kpi/dashboard') ? 'active' : ''}`}>RECRUITERS</Link></li>
                <li><Link to="/kpis/sales-kpi/monthly" className={`${pathname.match('/kpis/sales-kpi/monthly') ? 'active' : ''}`}>ANNUAL SALE</Link></li>
                {/* <li><Link to="/kpis/sales-kpi/stats-details" className={`${pathname.match('/kpis/sales-kpi/stats-details') ? 'active' : ''}`}>Stats Details</Link></li> */}
                <li><Link to="/kpis/sales-kpi/case-details" className={`${pathname.match('/kpis/sales-kpi/case-details') ? 'active' : ''}`}>CASE DETAILS</Link></li>
                {/* <li><Link to="/kpis/sales-kpi/case-opened" className={`${pathname.match('/kpis/sales-kpi/case-opened') ? 'active' : ''}`}>Case Opened</Link></li>
                <li><Link to="/kpis/sales-kpi/job-spec" className={`${pathname.match('/kpis/sales-kpi/job-spec') ? 'active' : ''}`}>Job Spec</Link></li>
                <li><Link to="/kpis/sales-kpi/sendout" className={`${pathname.match('/kpis/sales-kpi/sendout') ? 'active' : ''}`}>Sendout</Link></li>
                <li><Link to="/kpis/sales-kpi/refused-offer" className={`${pathname.match('/kpis/sales-kpi/refused-offer') ? 'active' : ''}`}>Refused Offer</Link></li>
                <li><Link to="/kpis/sales-kpi/credit" className={`${pathname.match('/kpis/sales-kpi/credit') ? 'active' : ''}`}>Credit</Link></li>
                <li><Link to="/kpis/sales-kpi/placements" className={`${pathname.match('/kpis/sales-kpi/placements') ? 'active' : ''}`}>Placements</Link></li>
                <li><Link to="/kpis/sales-kpi/annual-sale" className={`${pathname.match('/kpis/sales-kpi/annual-sale') ? 'active' : ''}`}>Annual Stats</Link></li> */}
            </ul>
        </>
    )
}

export default SalesKpiNav;
