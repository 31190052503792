import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const BankingMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalfinanceBankingMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/dkglobalfinance/banking/dashboard" className={(segment3 == 'dashboard') ? 'active' : ''}>Dashboard</Link></li>

                        <li><Link to={"/dkglobalfinance/banking/2025/" + btoa(2025)} className={`${(segment3 == '2025') ? 'active' : ''}`}>2025</Link></li>
                        <li><Link to={"/dkglobalfinance/banking/2024/" + btoa(2024)} className={`${(segment3 == '2024') ? 'active' : ''}`}>2024</Link></li>
                        <li><Link to={"/dkglobalfinance/banking/2023/" + btoa(2023)} className={`${(segment3 == '2023') ? 'active' : ''}`}>2023</Link></li>
                        <li><Link to={"/dkglobalfinance/banking/2022/" + btoa(2022)} className={`${(segment3 == '2022') ? 'active' : ''}`}>2022</Link></li>
                        <li><Link to={"/dkglobalfinance/banking/2021/" + btoa(2021)} className={`${(segment3 == '2021') ? 'active' : ''}`}>2021</Link></li>
                        <li><Link to={"/dkglobalfinance/banking/2020/" + btoa(2020)} className={`${(segment3 == '2020') ? 'active' : ''}`}>2020</Link></li>
                        <li><Link to={"/dkglobalfinance/banking/2019/" + btoa(2019)} className={`${(segment3 == '2019') ? 'active' : ''}`}>2019</Link></li>
                        <li><Link to={"/dkglobalfinance/banking/2018/" + btoa(2018)} className={`${(segment3 == '2018') ? 'active' : ''}`}>2018</Link></li>
                        <li><Link to={"/dkglobalfinance/banking/2017/" + btoa(2017)} className={`${(segment3 == '2017') ? 'active' : ''}`}>2017</Link></li>
                        <li><Link to="/dkglobalfinance/banking/statements/2022" className={`${(segment3 == 'statements') ? 'grey-active' : ''}`}>Statements</Link></li>

                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default BankingMenu;