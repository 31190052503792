import React, { useState, useEffect } from 'react'
import "./selectinstance.css";
import { Select } from 'semantic-ui-react'

const SelectInstance = ({ list, type, placeholder, name, onChange }) => {
    const [ddlList, setDdlList] = useState([]);
    useEffect(() => {
        switch (type) {
            case 'status':
                var result = [];
                var map = new Map();
                result.push({
                    key: '611f8b92c21f8c28f4699ae3',
                    value: '611f8b92c21f8c28f4699ae3',
                    text: 'Active',
                    description: "0",
                    style: {
                        color: '#fff',
                        backgroundColor: '#31a886'
                    }
                })
                result.push({
                    key: '611f8b92c21f8c28f4699ae4',
                    value: '611f8b92c21f8c28f4699ae4',
                    text: 'Inctive',
                    description: "0",
                    style: {
                        color: '#fff',
                        backgroundColor: '#d8345f'
                    }
                })
                result.unshift({
                    key: '0',
                    value: '',
                    text: placeholder,
                    style: {
                        display: 'none'
                    }
                })
                break;

            case 'company':
                var result = [];
                var map = new Map();
                for (var item of list) {
                    if (!map.has(item.original.companyId._id)) {
                        map.set(item.original.companyId._id, true);
                        result.push({
                            key: item.original.companyId._id,
                            value: item.original.companyId._id,
                            text: item.original.companyId.name,
                            style: {
                                color: item.original.companyId.textColor,
                                backgroundColor: item.original.companyId.bgColor
                            }
                        })
                    }
                }
                result.unshift({
                    key: '0',
                    value: '',
                    text: placeholder,
                    style: {
                        display: 'none'
                    }
                })
                break;

            case 'adsCreditCompany':
                var result = [];
                var map = new Map();
                for (var item of list) {
                    if (!map.has(item.original.company)) {
                        map.set(item.original.company, true);
                        result.push({
                            key: item.original.company,
                            value: item.original.company,
                            text: item.original.company,
                            style: {
                                color: item.original.company.textColor,
                                backgroundColor: item.original.company.bgColor
                            }
                        })
                    }
                }
                result.unshift({
                    key: '0',
                    value: '',
                    text: placeholder,
                    style: {
                        display: 'none'
                    }
                })
                break;

            case 'adsCreditLanguage':
                var result = [];
                var map = new Map();
                for (var item of list) {
                    if (!map.has(item.original.language)) {
                        map.set(item.original.language, true);
                        result.push({
                            key: item.original.language,
                            value: item.original.language,
                            text: item.original.language,
                            style: {
                                color: item.original.language.textColor,
                                backgroundColor: item.original.language.bgColor
                            }
                        })
                    }
                }
                result.unshift({
                    key: '0',
                    value: '',
                    text: placeholder,
                    style: {
                        display: 'none'
                    }
                })
                break;

            case 'language':
                var result = [];
                var map = new Map();
                for (var item of list) {
                    if (!map.has(item.original.languageId._id)) {
                        map.set(item.original.languageId._id, true);
                        result.push({
                            key: item.original.languageId._id,
                            value: item.original.languageId._id,
                            text: item.original.languageId.name,
                            style: {
                                color: item.original.languageId.textColor,
                                backgroundColor: item.original.languageId.bgColor
                            }
                        })
                    }
                }
                result.unshift({
                    key: '0',
                    value: '',
                    text: placeholder,
                    style: {
                        display: 'none'
                    }
                })
                break;

            case 'planStatus':
                var result = [];
                var map = new Map();
                for (var item of list) {
                    if (!map.has(item.original.statusId._id)) {
                        map.set(item.original.statusId._id, true);
                        result.push({
                            key: item.original.statusId._id,
                            value: item.original.statusId._id,
                            text: item.original.statusId.name,
                            style: {
                                color: item.original.statusId.textColor,
                                backgroundColor: item.original.statusId.bgColor
                            }
                        })
                    }
                }
                result.unshift({
                    key: '0',
                    value: '',
                    text: placeholder,
                    style: {
                        display: 'none'
                    }
                })
                break;

            case 'category':
                var result = [];
                var map = new Map();
                for (var item of list) {
                    if (!map.has(item.original.id)) {
                        map.set(item.original.id, true);
                        result.push({
                            key: item.original.id,
                            value: item.original.id,
                            text: item.original.category,
                            style: {
                                color: item.original.textColor,
                                backgroundColor: item.original.bgColor
                            }
                        })
                    }
                }
                result.unshift({
                    key: '0',
                    value: '',
                    text: placeholder,
                    style: {
                        display: 'none'
                    }
                })
                break;

            case 'qualifyFilterCategory':
                var result = [];
                var map = new Map();
                for (var item of list) {
                    if (!map.has(item.original.categoryId._id)) {
                        map.set(item.original.categoryId._id, true);
                        result.push({
                            key: item.original.categoryId._id,
                            value: item.original.categoryId._id,
                            text: item.original.categoryId.value,
                            style: {
                                color: item.original.categoryId.textColor,
                                backgroundColor: item.original.categoryId.bgColor
                            }
                        })
                    }
                }
                result.unshift({
                    key: '0',
                    value: '',
                    text: placeholder,
                    style: {
                        display: 'none'
                    }
                })
                break;

            case 'frequency':
                var result = [];
                var map = new Map();
                for (var item of list) {
                    if (!map.has(item.original.statusId._id)) {
                        map.set(item.original.statusId._id, true);
                        result.push({
                            key: item.original.statusId._id,
                            value: item.original.statusId._id,
                            text: item.original.statusId.name,
                            style: {
                                color: item.original.statusId.textColor,
                                backgroundColor: item.original.statusId.bgColor
                            }
                        })
                    }
                }
                result.unshift({
                    key: '0',
                    value: '',
                    text: placeholder,
                    style: {
                        display: 'none'
                    }
                })
                break;

            case 'loginCategory':
                var result = [];
                var map = new Map();
                for (var item of list) {
                    if (!map.has(item.original.category._id)) {
                        map.set(item.original.category._id, true);
                        result.push({
                            key: item.original.category._id,
                            value: item.original.category._id,
                            text: item.original.category.name,
                            style: {
                                color: item.original.category.textColor,
                                backgroundColor: item.original.category.bgColor
                            }
                        })
                    }
                }
                result.unshift({
                    key: '0',
                    value: '',
                    text: placeholder,
                    style: {
                        display: 'none'
                    }
                })
                break;

            case 'loginSourceType':
                var result = [];
                var map = new Map();
                for (var item of list) {
                    if (!map.has(item.original.sourceType._id)) {
                        map.set(item.original.sourceType._id, true);
                        result.push({
                            key: item.original.sourceType._id,
                            value: item.original.sourceType._id,
                            text: item.original.sourceType.name,
                            style: {
                                color: item.original.sourceType.textColor,
                                backgroundColor: item.original.sourceType.bgColor
                            }
                        })
                    }
                }
                result.unshift({
                    key: '0',
                    value: '',
                    text: placeholder,
                    style: {
                        display: 'none'
                    }
                })
                break;

            default:
                var result = list;
                result.unshift({
                    key: '0',
                    value: '',
                    text: placeholder,
                    style: {
                        display: 'none'
                    }
                })
                break

        }
        setDdlList(result)
    }, [list])
    return (
        <React.Fragment>
            <Select placeholder={placeholder} clearable options={ddlList} className={`dk-overdues-task-slect`} name={name} onChange={onChange} />
        </React.Fragment>
    )
}
export default SelectInstance;
// placeholder='Select Type'
