import React, { useState, useEffect } from 'react'
//import { useDispatch, useSelector } from "react-redux";
import EditValue from "./EditRole";

const EditRoleList = ({list}) => {
     const [dataList, setDataList] = useState([])    
     useEffect(() => {
       setDataList(list)
    }, [list]);   
    return (
        <>
            <div className="dk-toolsValuesPanel">
                {dataList.length > 0 && dataList.map((item,index) =>
                 <EditValue index={index+1} id={item.id} dataValue ={item.name} />
                )}              
            </div>
        </>
    )
}

export default EditRoleList