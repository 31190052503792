import React, { useState } from "react"
import Dropdown from 'react-bootstrap/Dropdown';
const FlagOptions = ({ id, columnId, updateMyData }) => {
    const selectValue = (value, color) => {
        // const setValue = e.target.dataset.value;
        updateMyData(id, columnId, value, color);
    }
    return (
        <React.Fragment>
            <Dropdown.Item href="#" data-value="5" data-column="flag_type" onClick={() => selectValue(5, '#3A9469')}>
                <i className="fa fa-flag" aria-hidden="true" style={{ color: "#3A9469" }}></i>
                <div className="dk-flag-select-text">Good Case</div>
            </Dropdown.Item>
            <Dropdown.Item href="#" data-value="3" data-column="flag_type" onClick={() => selectValue(3, '#D58B5D')}>
                <i className="fa fa-flag" aria-hidden="true" style={{ color: "#D58B5D" }}></i>
                <div className="dk-flag-select-text">Normal Case</div>
            </Dropdown.Item>
            <Dropdown.Item href="#" data-value="2" data-column="flag_type" onClick={() => selectValue(2, '#E2445B')}>
                <i className="fa fa-flag" aria-hidden="true" style={{ color: "#E2445B" }}></i>
                <div className="dk-flag-select-text">Bad Case</div>
            </Dropdown.Item>
            <Dropdown.Item href="#" data-value="1" data-column="flag_type" onClick={() => selectValue(1, '#d3d3d3')}>
                <i className="fa fa-flag" aria-hidden="true" style={{ color: "#d3d3d3" }}></i>
                <div className="dk-flag-select-text">Blank</div>
            </Dropdown.Item>
        </React.Fragment>
    )
}
export default FlagOptions;