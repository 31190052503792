import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import './main-pricipales.scss'
import ChangeIcon from '../../../../assets/images/complain.png'
import { getText } from "../../../../slice/principals/principalsSlice"
import { showError } from "../../../../components/utils/messages/messageSlice";

const Index = () => {

    const dispatch = useDispatch();
    const { isLoading, getPrincipalList } = useSelector(
        (state) => state.principals
    );
    const [data, setData] = useState([]);

    useEffect(() => {
        if (!isLoading && getPrincipalList && getPrincipalList.length > 0) {
            setData(getPrincipalList);
        }
    }, [isLoading]);

    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(getText({}));
            } catch (err) {
                dispatch(showError({ msg: "Somthings went wrong" }));
            }
        }
        fetchData();
    }, [dispatch]);

    return (
        <>
            <div className="container-fluid dkg-mainprinciple-mainCon">
                <div className="row dkg-mainprinciple-Row">
                    <div className="col-12 dkg-mainprinciple-Col">
                        <div className="dkg-principale-header">
                            <h2 className='dkg-principale-title'>{data != "" ? data[0].title : ""}</h2>
                        </div>
                        <div className="dkg-principale-tableCon">
                            <table className="table table-bordered dkg-principale-table">
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <div className="dkg-principleImgCon">
                                                <img src={data != "" ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/' + data[6].iconsName : ChangeIcon} alt="Icon" className="dkg-principleImg" />
                                            </div>
                                        </td>
                                        <td>{data != "" ? data[6].title : ""}</td>
                                        <td>
                                            <p className="dkg-pricipale-desc">
                                                {data != "" ? data[6].description : ""}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>
                                            <div className="dkg-principleImgCon">
                                                <img src={data != "" ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/' + data[1].iconsName : ChangeIcon} alt="Icon" className="dkg-principleImg" />
                                            </div>
                                        </td>
                                        <td>{data != "" ? data[1].title : ""}</td>
                                        <td>
                                            <p className="dkg-pricipale-desc">
                                                {data != "" ? data[1].description : ""}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>
                                            <div className="dkg-principleImgCon">
                                                <img src={data != "" ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/' + data[2].iconsName : ChangeIcon} alt="Icon" className="dkg-principleImg" />
                                            </div>
                                        </td>
                                        <td>{data != "" ? data[2].title : ""}</td>
                                        <td>
                                            <p className="dkg-pricipale-desc">
                                                {data != "" ? data[2].description : ""}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>
                                            <div className="dkg-principleImgCon">
                                                <img src={data != "" ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/' + data[3].iconsName : ChangeIcon} alt="Icon" className="dkg-principleImg" />
                                            </div>
                                        </td>
                                        <td>{data != "" ? data[3].title : ""}</td>
                                        <td>
                                            <p className="dkg-pricipale-desc">
                                                {data != "" ? data[3].description : ""}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>
                                            <div className="dkg-principleImgCon">
                                                <img src={data != "" ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/' + data[7].iconsName : ChangeIcon} alt="Icon" className="dkg-principleImg" />
                                            </div>
                                        </td>
                                        <td>{data != "" ? data[7].title : ""}</td>
                                        <td>
                                            <p className="dkg-pricipale-desc">
                                                {data != "" ? data[7].description : ""}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>
                                            <div className="dkg-principleImgCon">
                                                <img src={data != "" ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/' + data[4].iconsName : ChangeIcon} alt="Icon" className="dkg-principleImg" />
                                            </div>
                                        </td>
                                        <td>{data != "" ? data[4].title : ""}</td>
                                        <td>
                                            <p className="dkg-pricipale-desc">
                                                {data != "" ? data[4].description : ""}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>
                                            <div className="dkg-principleImgCon">
                                                <img src={data != "" ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/' + data[5].iconsName : ChangeIcon} alt="Icon" className="dkg-principleImg" />
                                            </div>
                                        </td>
                                        <td>{data != "" ? data[5].title : ""}</td>
                                        <td>
                                            <p className="dkg-pricipale-desc">
                                                {data != "" ? data[5].description : ""}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>
                                            <div className="dkg-principleImgCon">
                                                <img src={data != "" ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/' + data[8].iconsName : ChangeIcon} alt="Icon" className="dkg-principleImg" />
                                            </div>
                                        </td>
                                        <td>{data != "" ? data[8].title : ""}</td>
                                        <td>
                                            <p className="dkg-pricipale-desc">
                                                {data != "" ? data[8].description : ""}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>
                                            <div className="dkg-principleImgCon">
                                                <img src={data != "" ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/' + data[9].iconsName : ChangeIcon} alt="Icon" className="dkg-principleImg" />
                                            </div>
                                        </td>
                                        <td>{data != "" ? data[9].title : ""}</td>
                                        <td>
                                            <p className="dkg-pricipale-desc">
                                                {data != "" ? data[9].description : ""}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td>
                                            <div className="dkg-principleImgCon">
                                                <img src={data != "" ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/' + data[10].iconsName : ChangeIcon} alt="Icon" className="dkg-principleImg" />
                                            </div>
                                        </td>
                                        <td>{data != "" ? data[10].title : ""}</td>
                                        <td>
                                            <p className="dkg-pricipale-desc">
                                                {data != "" ? data[10].description : ""}
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index