import React from 'react';
import Modal from 'react-bootstrap/Modal';
// import DefulatImg from "../../../../../assets/images/default_profile.png";
import DefulatImg from "../../../../../../assets/images/deepak.png";
// import DefulatImg from '../../../../../../assets/images/images/default_profile.png'

const NotificationSeen = ({ handleClose }) => {
    return (
        <>
            <Modal show={true} className="dk-notificationSeenModal right" onHide={handleClose}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-moduleNoti-rightPanel">
                        {/* <div className="moduleNoti-title">Lorem Ipsum is simply dummy text of the printing</div> */}
                        <div className="dk-moduleNoti-body">
                            <div className="dk-moduleBody-text">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Hic minima eos ipsam asperiores aspernatur distinctio reiciendis itaque maxime culpa totam enim alias eum ipsa excepturi officia, dolorum tempore libero ea?</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>

                            <div className="dk-commentCategoryMain">
                                <div className="title">
                                    Notification Seen By
                                    <button>Confirm Seen</button>
                                </div>

                                <div className="dk-commentCategory-cols">
                                    <div className="d-flex flex-wrap justify-content-center">
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                        <div className="dk-categoryComment-img">
                                            <img src={DefulatImg} alt="" title="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default NotificationSeen;
