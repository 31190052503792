import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { InputGroup, FormControl, Dropdown } from 'react-bootstrap';
import InterviewDropDwn from '../../interviews/InterviewDropDwn'

import { clearState, updateTitleBlocks } from "../../../../../../../slice/jobs/jobsSlice";

const Jobtitle = (jobId) => {
    const dispatch = useDispatch();

    const { isDetailsLoading, jobDetails, isUpdate } = useSelector(state => state.jobs);

    const [data, setData] = useState('');
    useEffect(() => {
        if (jobDetails && !isDetailsLoading) {
            setData(jobDetails);
        }
    }, [isDetailsLoading])

    useEffect(() => {
        if (isUpdate) {
            dispatch(clearState())
            setData(jobDetails);
        }
    }, [isUpdate])

    const ad_title_1 = data.titleBlocks !== undefined && data.titleBlocks.length ? data.titleBlocks[0].ad_title_1 : "";
    const ad_title_2 = data.titleBlocks !== undefined && data.titleBlocks.length ? data.titleBlocks[0].ad_title_2 : "";
    const ad_title_3 = data.titleBlocks !== undefined && data.titleBlocks.length ? data.titleBlocks[0].ad_title_3 : "";
    const ad_title_4 = data.titleBlocks !== undefined && data.titleBlocks.length ? data.titleBlocks[0].ad_title_4 : "";

    const updateSubjectData = (e) => {
        let values = e.target.value;
        let columnId = e.target.dataset.columnid;
        dispatch(updateTitleBlocks({ id: jobId.jobId, key: columnId, value: values }));
    }

    return (
        <>
            <div className="row dk-Adsbox">
                <div className="col-md-12">
                    <div className="col-md-12 titlebox">
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-default" className='col-md-2'>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                        Job Board
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="dark">
                                        <Dropdown.Item href="#/action-2">Value 1</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 2</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 3</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup.Text>
                            <FormControl className='text-left col-md-10' defaultValue={ad_title_1} onBlur={updateSubjectData} data-columnid="ad_title_1" aria-describedby="ad_title_1" />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-default" className='col-md-2'>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                        Job Board
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="dark">
                                        <Dropdown.Item href="#/action-2">Value 1</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 2</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 3</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup.Text>
                            <FormControl className='text-left col-md-10' defaultValue={ad_title_1} onBlur={updateSubjectData} data-columnid="ad_title_1" aria-describedby="ad_title_1" />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-default" className='col-md-2'>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                        Job Board
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="dark">
                                        <Dropdown.Item href="#/action-2">Value 1</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 2</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 3</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup.Text>
                            <FormControl className='text-left col-md-10' defaultValue={ad_title_1} onBlur={updateSubjectData} data-columnid="ad_title_1" aria-describedby="ad_title_1" />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-default" className='col-md-2'>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                        Job Board
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="dark">
                                        <Dropdown.Item href="#/action-2">Value 1</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 2</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 3</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup.Text>
                            <FormControl className='text-left col-md-10' defaultValue={ad_title_1} onBlur={updateSubjectData} data-columnid="ad_title_1" aria-describedby="ad_title_1" />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-default" className='col-md-2'>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                        Job Board
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="dark">
                                        <Dropdown.Item href="#/action-2">Value 1</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 2</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 3</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup.Text>
                            <FormControl className='text-left col-md-10' defaultValue={ad_title_1} onBlur={updateSubjectData} data-columnid="ad_title_1" aria-describedby="ad_title_1" />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-default" className='col-md-2'>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                        Job Board
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="dark">
                                        <Dropdown.Item href="#/action-2">Value 1</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 2</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 3</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup.Text>
                            <FormControl className='text-left col-md-10' defaultValue={ad_title_1} onBlur={updateSubjectData} data-columnid="ad_title_1" aria-describedby="ad_title_1" />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-default" className='col-md-2'>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                        Job Board
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="dark">
                                        <Dropdown.Item href="#/action-2">Value 1</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 2</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 3</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup.Text>
                            <FormControl className='text-left col-md-10' defaultValue={ad_title_1} onBlur={updateSubjectData} data-columnid="ad_title_1" aria-describedby="ad_title_1" />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-default" className='col-md-2'>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                        Job Board
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="dark">
                                        <Dropdown.Item href="#/action-2">Value 1</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 2</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 3</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup.Text>
                            <FormControl className='text-left col-md-10' defaultValue={ad_title_1} onBlur={updateSubjectData} data-columnid="ad_title_1" aria-describedby="ad_title_1" />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-default" className='col-md-2'>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                        Job Board
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="dark">
                                        <Dropdown.Item href="#/action-2">Value 1</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 2</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 3</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup.Text>
                            <FormControl className='text-left col-md-10' defaultValue={ad_title_1} onBlur={updateSubjectData} data-columnid="ad_title_1" aria-describedby="ad_title_1" />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-default" className='col-md-2'>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                        Job Board
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="dark">
                                        <Dropdown.Item href="#/action-2">Value 1</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 2</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Value 3</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup.Text>
                            <FormControl className='text-left col-md-10' defaultValue={ad_title_1} onBlur={updateSubjectData} data-columnid="ad_title_1" aria-describedby="ad_title_1" />
                        </InputGroup>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Jobtitle
