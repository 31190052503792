import React, { useState } from 'react';
import { Link } from "react-router-dom";
import UpdateProfilesDetailLeftPanel from "../leftpanel/UpdateProfilesDetailLeftPanel";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import UpdateLanguagesLeftTabs from '../detaillefttabs/updateprofiles/UpdateLanguagesLeftTabs';
import MoreDetailModal from '../MoreDetailModal';
import TItleIcon from "../../../../../../assets/images/resume_black.png";
import '../../trainingdetail.scss';

const AdminsUpdateLanguages = () => {
    const [isShowMore, setIsShowMore] = useState(false);
    const showmoreDetails = () => {
        console.log("Show")
        setIsShowMore(true);
    }
    const hidemoreDetails = () => {
        setIsShowMore(false);
    }

    return (
        <React.Fragment>
            {
                (isShowMore) ? <MoreDetailModal handleClose={hidemoreDetails} /> : null
            }

            <div className="dk-backBtnMain">
                <Link to="/dkglobaladmin/admintraining/admins"><i className="fas fa-arrow-left"></i> Go Back</Link>
                <h3 className="dk-detailTitle">
                    <img src={TItleIcon} className="img-fluid" alt="" />
                    Update Profiles
                </h3>
            </div>

            <div className="dk-trainingDetailMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <UpdateProfilesDetailLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                <h3 className="title">Languages</h3>
                                <Tabs defaultActiveKey="tabs_1">
                                    <Tab eventKey="tabs_1" title="Details">
                                        <div className="d-flex">
                                            <UpdateLanguagesLeftTabs />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tabs_2" title="Samples">
                                        <div className="d-flex">
                                            <UpdateLanguagesLeftTabs />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tabs_3" title="Video">
                                        <div className="d-flex">
                                            <UpdateLanguagesLeftTabs />
                                        </div>
                                    </Tab>
                                </Tabs>
                                <div title="More" className="clickMoreShow" onClick={showmoreDetails}>
                                    More
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default AdminsUpdateLanguages;

