import React,{useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import { FaEdit } from "react-icons/fa";
import  './edit-job.scss';
import Dropdown from 'react-bootstrap/Dropdown'

import SubmitButton from '../../../../../ui/submitButton'

const EditJobPpopup = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
    
      <div className="batch-actions-item archived-checkboxbg" onClick={handleShow} style={{ backgroundColor: "#26597d", color: "#fff" }}>
        <span >
          <FaEdit onClick={handleShow} />
        </span>
        Edit
      </div>
     <Modal show={show} onHide={handleClose}  dialogClassName="dk-candidateProfile80 dkg-edit-livejobs-Modal-234">
        <Modal.Header closeButton className="dk-candModalHeader">
          <Modal.Title className="dk-candModalTitle" >Edit Job</Modal.Title>
        </Modal.Header>
        <Modal.Body className='dkg-editjobs-modalbody'>
          <div className="row dkg-editjobsRow">
            <div className="col-12 col-md-12 dkg-editjobsCol">
              <div className="dkg-editjobs-formgrp form-group">
                <label className="dkg-editjob-label">Job ID</label>
                <input type="text" className="form-control dkg-editjob-input" value="4004"  />
              </div>
              <div className="dkg-editjobs-formgrp form-group">
                <label className="dkg-editjob-label">Company</label>
                <div className='dkg-editjobs-dropdownCon'>
                  <Dropdown className='dkg-editjobs-dropdown'>
                      <Dropdown.Toggle variant="" className="dkg-editjobs-dropdown-toggle">
                      TP Greece	
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dkg-editjobs-dropdownMenu" >
                        <Dropdown.Item className="dkg-editjobs-dropdown-item">Simple Text 1</Dropdown.Item>    
                        <Dropdown.Item className="form-control">Simple Text 2</Dropdown.Item>                                              
                        <Dropdown.Item className="form-control">Simple Text 3</Dropdown.Item>                                              

                      </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="dkg-editjobs-formgrp form-group">
                <label className="dkg-editjob-label">Category</label>
                <div className='dkg-editjobs-dropdownCon'>
                  <Dropdown className='dkg-editjobs-dropdown'>
                      <Dropdown.Toggle variant="" className="dkg-editjobs-dropdown-toggle">
                      ML
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dkg-editjobs-dropdownMenu" >
                        <Dropdown.Item className="dkg-editjobs-dropdown-item">Simple Text 1</Dropdown.Item>    
                        <Dropdown.Item className="form-control">Simple Text 2</Dropdown.Item>                                              
                        <Dropdown.Item className="form-control">Simple Text 3</Dropdown.Item>                                              

                      </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="dkg-editjobs-formgrp form-group">
                <label className="dkg-editjob-label">Country</label>
                <div className='dkg-editjobs-dropdownCon'>
                  <Dropdown className='dkg-editjobs-dropdown'>
                      <Dropdown.Toggle variant="" className="dkg-editjobs-dropdown-toggle">
                      Greece
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dkg-editjobs-dropdownMenu" >
                        <Dropdown.Item className="dkg-editjobs-dropdown-item">Simple Text 1</Dropdown.Item>    
                        <Dropdown.Item className="form-control">Simple Text 2</Dropdown.Item>                                              
                        <Dropdown.Item className="form-control">Simple Text 3</Dropdown.Item>                                              

                      </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="dkg-editjobs-formgrp form-group">
                <label className="dkg-editjob-label">City/Town</label>
                <div className='dkg-editjobs-dropdownCon'>
                  <Dropdown className='dkg-editjobs-dropdown'>
                      <Dropdown.Toggle variant="" className="dkg-editjobs-dropdown-toggle">
                      Athens
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dkg-editjobs-dropdownMenu" >
                        <Dropdown.Item className="dkg-editjobs-dropdown-item">Simple Text 1</Dropdown.Item>    
                        <Dropdown.Item className="form-control">Simple Text 2</Dropdown.Item>                                              
                        <Dropdown.Item className="form-control">Simple Text 3</Dropdown.Item>                                              

                      </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="dkg-editjobs-formgrp form-group">
                <label className="dkg-editjob-label">Language</label>
                <div className='dkg-editjobs-dropdownCon'>
                  <Dropdown className='dkg-editjobs-dropdown'>
                      <Dropdown.Toggle variant="" className="dkg-editjobs-dropdown-toggle">
                      Ducth
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dkg-editjobs-dropdownMenu" >
                        <Dropdown.Item className="dkg-editjobs-dropdown-item">Simple Text 1</Dropdown.Item>    
                        <Dropdown.Item className="form-control">Simple Text 2</Dropdown.Item>                                              
                        <Dropdown.Item className="form-control">Simple Text 3</Dropdown.Item>                                              

                      </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="dkg-editjobs-formgrp form-group">
                <label className="dkg-editjob-label">Project Type</label>
                <div className='dkg-editjobs-dropdownCon'>
                  <Dropdown className='dkg-editjobs-dropdown'>
                      <Dropdown.Toggle variant="" className="dkg-editjobs-dropdown-toggle">
                      Viva Walllet	
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dkg-editjobs-dropdownMenu" >
                        <Dropdown.Item className="dkg-editjobs-dropdown-item">Simple Text 1</Dropdown.Item>    
                        <Dropdown.Item className="form-control">Simple Text 2</Dropdown.Item>                                              
                        <Dropdown.Item className="form-control">Simple Text 3</Dropdown.Item>                                              

                      </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="dkg-editjobs-formgrp form-group">
                <label className="dkg-editjob-label">Role Type</label>
                <div className='dkg-editjobs-dropdownCon'>
                  <Dropdown className='dkg-editjobs-dropdown'>
                      <Dropdown.Toggle variant="" className="dkg-editjobs-dropdown-toggle">
                      Customer Service	
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dkg-editjobs-dropdownMenu" >
                        <Dropdown.Item className="dkg-editjobs-dropdown-item">Simple Text 1</Dropdown.Item>    
                        <Dropdown.Item className="form-control">Simple Text 2</Dropdown.Item>                                              
                        <Dropdown.Item className="form-control">Simple Text 3</Dropdown.Item>                                              

                      </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="dkg-editjobs-formgrp form-group">
                <div className="dkg-editjobs-btnCon">
                  <SubmitButton txt="UPDATE" className="dkg-editjob-btn btn"/>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditJobPpopup