
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import moment from "moment-timezone";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
//import Requirement from '../requirements/Requirement';
import PophoverIcon from "./PophoverIcon";
import {  secondScreaningfetchById } from "../../../../../../../slice/candidatePrecreeningForm/candidatePrecreeningSlice";
import {
    clearState,
    clearTextBox,
    CVSourceFilterFirst,
    KeyQualificationFilterFirst,
    KeyQualificationFilterSecond,
    KeyQualificationFilterThird,
    KeyQualifyFilterFirst,
    KeyQualifyFilterSecond,
    KeyQualifyFilterThird,
    motivationFilterFirst,
    motivationFilterSecond,
    motivationFilterThird,
    JobDescriptionFilterFirst,
    JobDescriptionFilterSecond,
    JobDescriptionFilterThird,
    SalaryBenifitFilterFirst,
    SalaryBenifitFilterSecond,
    SalaryBenifitFilterThird,
    JobReqFilterFirst,
    JobReqFilterSecond,
    JobReqFilterThird,
    ReloactionQueryFilterFirst,
    ReloactionQueryFilterSecond,
    ReloactionQueryFilterThird,
    ProjectStartDateFilterFirst,
    ProjectStartDateFilterSecond,
    ProjectStartDateFilterThird,
    JoiningDocumentFilterFirst,
    JoiningDocumentFilterSecond,
    JoiningDocumentFilterThird,
    InterviewProcessFilterFirst,
    InterviewProcessFilterSecond,
    InterviewProcessFilterThird,
    FullResumePreviewFilterFirst,
    FullResumePreviewFilterSecond,
    FullResumePreviewFilterThird,
    OtherJobInterviewFilterFirst,
    OtherJobInterviewFilterSecond,
    OtherJobInterviewFilterThird,
    ContactPreferenceFilterFirst,
    ContactPreferenceFilterSecond,
    ContactPreferenceFilterThird,
    //addNewValue,
    updateData,
    fetchScreeningList,
    screaningLevelFirst,
    screaningAvailability,
    screaningContactPrefered,
    getTextLevel,
    firstLevel5th_1,
    firstLevel5th_2,
    firstLevel5th_3,
    firstLevel6th_1,
    firstLevel6th_2,
    firstLevel6th_3,
    firstLevel7th_1,
    firstLevel7th_2,
    firstLevel7th_3,
    firstLevel8th_1,
    firstLevel8th_2,
    firstLevel8th_3,
    elevenFirstDropdown,
    elevenSecondDropdown,
    elevenThirdDropdown,
    twelveFirstDropdown,twelveSecondDropdown,twelveThirdDropdown,
    thirteenFirstDropdown,thirteenSecondDropdown,thirteenThirdDropdown,
    fourteenFirstDropdown,fourteenSecondDropdown,fourteenThirdDropdown,
    fifteenFirstDropdown,fifteenSecondDropdown,fifteenThirdDropdown,
    update2ndScreaning

} from "../../../../../../../slice/candidatePrecreeningForm/candidatePrecreeningSlice";

import {
    monthsDataFetch,
    progressDataFetch,
    stageDataFetch,
    companyDataFetch,
    projectDataFetch,
    roleTypeDataFetch,
    languageDataFetch,
    cvSourceDataFetch,
} from "../../../../../../../slice/kpis/assignKpisReportSlice";
import { SYSTEM_CONSTANTS } from "../../../../../../../constants";
import Dropdown from "react-bootstrap/Dropdown";
import {
    showSuccess,
    showError,
    clearMessage,
} from "../../../../../../../slice/utils/message/messageSlice";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const SecondScreening = ({candidateId,secondScreaning}) => {
    const dispatch = useDispatch();
    const {
        isLoading,
        
        jobDescFirstList,
        jobDescSecondList,
        jobDescThirdList,
        SalaryFirstList,
        SalarySecondList,
        SalaryThirdList,
        JobRequirnmentFirstList,
        JobRequirnmentSecondList,
        JobRequirnmentThirdList,
        RelocationQryFirstList,
        RelocationQrySecondList,
        RelocationQryThirdList,
        ProjectStartDateFirstList,
        ProjectStartDateSecondList,
        ProjectStartDateThirdList,
        JoiningDocumentFirstList,
        JoiningDocumentSecondList,
        JoiningDocumentThirdList,
        InterviewProcessFirstList,
        InterviewProcessSecondList,
        InterviewProcessThirdList,
        FullResumePreviewFirstList,
        FullResumePreviewSecondList,
        FullResumePreviewThirdList,
        OtherJobInterviewFirstList,
        OtherJobInterviewSecondList,
        OtherJobInterviewThirdList,
        ContactPreferenceFirstList,
        ContactPreferenceSecondList,
        ContactPreferenceThirdList,
        secondScrening,      
        firstLevelList,
        TenthFirstList,TenthSecondList,TenthThirdList,
        //11thblock
        ElevenFirstList,
        ElevenSecondList,
        ElevenThirdList,twelveFirstList,twelveSecondList,twelveThirdList,
        thirteenFirstList,thirteenSecondList,thirteenThirdList,
        fourteenFirstList,fourteenSecondList,fourteenThirdList,
        fifteenFirstList,fifteenSecondList,fifteenThirdList

    } = useSelector((state) => state.candidatePrecreening);
    const {
        monthsList,
        companyList,
        projectList,
        roleTypeList,
        languageList,
        cvSourceList,
    } = useSelector((state) => state.assignKpiReport);

    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(secondScreaningfetchById({ id:candidateId }));
                await dispatch(getTextLevel({ type: "2stLevel" }));
            } catch (err) {
                dispatch(showError({ msg: "Somthings went wrong" }));
            }
        }
        fetchData();
    }, [dispatch,candidateId]);

const [block_1a, setBlock_1a] = useState('');
const [block_1b, setBlock_1b] = useState('');
const [block_1c, setBlock_1c] = useState('');
const [block_1a_text, setBlock_1a_text] = useState('');
const [block_1b_text, setBlock_1b_text] = useState('');
const [block_1c_text, setBlock_1c_text] = useState('');
const [block_2a, setBlock_2a] = useState('');
const [block_2b, setBlock_2b] = useState('');
const [block_2c, setBlock_2c] = useState('');
const [block_2a_text, setBlock_2a_text] = useState('');
const [block_2b_text, setBlock_2b_text] = useState('');
const [block_2c_text, setBlock_2c_text] = useState('');
const [block_3a, setBlock_3a] = useState('');
const [block_3b, setBlock_3b] = useState('');
const [block_3c, setBlock_3c] = useState('');
const [block_3a_text, setBlock_3a_text] = useState('');
const [block_3b_text, setBlock_3b_text] = useState('');
const [block_3c_text, setBlock_3c_text] = useState('');
const [block_4a, setBlock_4a] = useState('');
const [block_4b, setBlock_4b] = useState('');
const [block_4c, setBlock_4c] = useState('');
const [block_4a_text, setBlock_4a_text] = useState('');
const [block_4b_text, setBlock_4b_text] = useState('');
const [block_4c_text, setBlock_4c_text] = useState('');
const [block_5a, setBlock_5a] = useState('');
const [block_5b, setBlock_5b] = useState('');
const [block_5c, setBlock_5c] = useState('');
const [block_5a_text, setBlock_5a_text] = useState('');
const [block_5b_text, setBlock_5b_text] = useState('');
const [block_5c_text, setBlock_5c_text] = useState('');
const [block_6a, setBlock_6a] = useState('');
const [block_6b, setBlock_6b] = useState('');
const [block_6c, setBlock_6c] = useState('');
const [block_6a_text, setBlock_6a_text] = useState('');
const [block_6b_text, setBlock_6b_text] = useState('');
const [block_6c_text, setBlock_6c_text] = useState('');
const [block_7a, setBlock_7a] = useState('');
const [block_7b, setBlock_7b] = useState('');
const [block_7c, setBlock_7c] = useState('');
const [block_7a_text, setBlock_7a_text] = useState('');
const [block_7b_text, setBlock_7b_text] = useState('');
const [block_7c_text, setBlock_7c_text] = useState('');
const [block_8a, setBlock_8a] = useState('');
const [block_8b, setBlock_8b] = useState('');
const [block_8c, setBlock_8c] = useState('');
const [block_8a_text, setBlock_8a_text] = useState('');
const [block_8b_text, setBlock_8b_text] = useState('');
const [block_8c_text, setBlock_8c_text] = useState('');
const [block_9a, setBlock_9a] = useState('');
const [block_9b, setBlock_9b] = useState('');
const [block_9c, setBlock_9c] = useState('');
const [block_9a_text, setBlock_9a_text] = useState('');
const [block_9b_text, setBlock_9b_text] = useState('');
const [block_9c_text, setBlock_9c_text] = useState('');
const [block_10a, setBlock_10a] = useState('');
const [block_10b, setBlock_10b] = useState('');
const [block_10c, setBlock_10c] = useState('');
const [block_10a_text, setBlock_10a_text] = useState('');
const [block_10b_text, setBlock_10b_text] = useState('');
const [block_10c_text, setBlock_10c_text] = useState('');
const [block_11a, setBlock_11a] = useState('');
const [block_11b, setBlock_11b] = useState('');
const [block_11c, setBlock_11c] = useState('');
const [block_11a_text, setBlock_11a_text] = useState('');
const [block_11b_text, setBlock_11b_text] = useState('');
const [block_11c_text, setBlock_11c_text] = useState('');
const [block_12a, setBlock_12a] = useState('');
const [block_12b, setBlock_12b] = useState('');
const [block_12c, setBlock_12c] = useState('');
const [block_12a_text, setBlock_12a_text] = useState('');
const [block_12b_text, setBlock_12b_text] = useState('');
const [block_12c_text, setBlock_12c_text] = useState('');
const [block_13a, setBlock_13a] = useState('');
const [block_13b, setBlock_13b] = useState('');
const [block_13c, setBlock_13c] = useState('');
const [block_13a_text, setBlock_13a_text] = useState('');
const [block_13b_text, setBlock_13b_text] = useState('');
const [block_13c_text, setBlock_13c_text] = useState('');
const [block_14a, setBlock_14a] = useState('');
const [block_14b, setBlock_14b] = useState('');
const [block_14c, setBlock_14c] = useState('');
const [block_14a_text, setBlock_14a_text] = useState('');
const [block_14b_text, setBlock_14b_text] = useState('');
const [block_14c_text, setBlock_14c_text] = useState('');
const [block_15a, setBlock_15a] = useState('');
const [block_15b, setBlock_15b] = useState('');
const [block_15c, setBlock_15c] = useState('');
const [block_15a_text, setBlock_15a_text] = useState('');
const [block_15b_text, setBlock_15b_text] = useState('');
const [block_15c_text, setBlock_15c_text] = useState('');

 useEffect(() => {      
    if (secondScreaning.secondScrening !== undefined) {
        
            setBlock_15c_text(secondScreaning.secondScrening.block_15c_text ? secondScreaning.secondScrening.block_15c_text :"")
            setBlock_15b_text(secondScreaning.secondScrening.block_15b_text ? secondScreaning.secondScrening.block_15b_text :"")
            setBlock_15a_text(secondScreaning.secondScrening.block_15a_text ? secondScreaning.secondScrening.block_15a_text :"")
            setBlock_15c(secondScreaning.secondScrening.block_15c ? secondScreaning.secondScrening.block_15c :"Select")
            setBlock_15b(secondScreaning.secondScrening.block_15b ? secondScreaning.secondScrening.block_15b :"Select")
            setBlock_15a(secondScreaning.secondScrening.block_15a ? secondScreaning.secondScrening.block_15a :"Select")
            setBlock_14c_text(secondScreaning.secondScrening.block_14c_text ? secondScreaning.secondScrening.block_14c_text :"")
            setBlock_14b_text(secondScreaning.secondScrening.block_14b_text ? secondScreaning.secondScrening.block_14b_text :"")
            setBlock_14a_text(secondScreaning.secondScrening.block_14a_text ? secondScreaning.secondScrening.block_14a_text :"")
            setBlock_14c(secondScreaning.secondScrening.block_14c ? secondScreaning.secondScrening.block_14c :"Select")
            setBlock_14b(secondScreaning.secondScrening.block_14b ? secondScreaning.secondScrening.block_14b :"Select")
            setBlock_14a(secondScreaning.secondScrening.block_14a ? secondScreaning.secondScrening.block_14a :"Select")

            setBlock_13c_text(secondScreaning.secondScrening.block_13c_text ? secondScreaning.secondScrening.block_13c_text :"")
            setBlock_13b_text(secondScreaning.secondScrening.block_13b_text ? secondScreaning.secondScrening.block_13b_text :"")
            setBlock_13a_text(secondScreaning.secondScrening.block_13a_text ? secondScreaning.secondScrening.block_13a_text :"")

            setBlock_13c(secondScreaning.secondScrening.block_13c ? secondScreaning.secondScrening.block_13c :"Select")
            setBlock_13b(secondScreaning.secondScrening.block_13b ? secondScreaning.secondScrening.block_13b :"Select")
            setBlock_13a(secondScreaning.secondScrening.block_13a ? secondScreaning.secondScrening.block_13a :"Select")            
            setBlock_12c_text(secondScreaning.secondScrening.block_12c_text ? secondScreaning.secondScrening.block_12c_text :"")
            setBlock_12b_text(secondScreaning.secondScrening.block_12b_text ? secondScreaning.secondScrening.block_12b_text :"")
            setBlock_12a_text(secondScreaning.secondScrening.block_12a_text ? secondScreaning.secondScrening.block_12a_text :"")

            setBlock_12c(secondScreaning.secondScrening.block_12c ? secondScreaning.secondScrening.block_12c :"Select")
            setBlock_12b(secondScreaning.secondScrening.block_12b ? secondScreaning.secondScrening.block_12b :"Select")
            setBlock_12a(secondScreaning.secondScrening.block_12a ? secondScreaning.secondScrening.block_12a :"Select")

            setBlock_11c_text(secondScreaning.secondScrening.block_11c_text ? secondScreaning.secondScrening.block_11c_text :"")
            setBlock_11b_text(secondScreaning.secondScrening.block_11b_text ? secondScreaning.secondScrening.block_11b_text :"")
            setBlock_11a_text(secondScreaning.secondScrening.block_11a_text ? secondScreaning.secondScrening.block_11a_text :"")

            setBlock_11c(secondScreaning.secondScrening.block_11c ? secondScreaning.secondScrening.block_11c :"Select")
            setBlock_11b(secondScreaning.secondScrening.block_11b ? secondScreaning.secondScrening.block_11b :"Select")
            setBlock_11a(secondScreaning.secondScrening.block_11a ? secondScreaning.secondScrening.block_11a :"Select")

            setBlock_10c_text(secondScreaning.secondScrening.block_10c_text ? secondScreaning.secondScrening.block_10c_text :"")
            setBlock_10b_text(secondScreaning.secondScrening.block_10b_text ? secondScreaning.secondScrening.block_10b_text :"")
            setBlock_10a_text(secondScreaning.secondScrening.block_10a_text ? secondScreaning.secondScrening.block_10a_text :"")

            setBlock_10c(secondScreaning.secondScrening.block_10c ? secondScreaning.secondScrening.block_10c :"Select")
            setBlock_10b(secondScreaning.secondScrening.block_10b ? secondScreaning.secondScrening.block_10b :"Select")
            setBlock_10a(secondScreaning.secondScrening.block_10a ? secondScreaning.secondScrening.block_10a :"Select")
            setBlock_9c_text(secondScreaning.secondScrening.block_9c_text ? secondScreaning.secondScrening.block_9c_text :"")
            setBlock_9b_text(secondScreaning.secondScrening.block_9b_text ? secondScreaning.secondScrening.block_9b_text :"")
            setBlock_9a_text(secondScreaning.secondScrening.block_9a_text ? secondScreaning.secondScrening.block_9a_text :"")
            setBlock_9c(secondScreaning.secondScrening.block_9c ? secondScreaning.secondScrening.block_9c :"Select")
            setBlock_9b(secondScreaning.secondScrening.block_9b ? secondScreaning.secondScrening.block_9b :"Select")
            setBlock_9a(secondScreaning.secondScrening.block_9a ? secondScreaning.secondScrening.block_9a :"Select")
           
            setBlock_8c_text(secondScreaning.secondScrening.block_8c_text ? secondScreaning.secondScrening.block_8c_text :"")
            setBlock_8b_text(secondScreaning.secondScrening.block_8b_text ? secondScreaning.secondScrening.block_8b_text :"")
            setBlock_8a_text(secondScreaning.secondScrening.block_8a_text ? secondScreaning.secondScrening.block_8a_text :"")

            setBlock_8c(secondScreaning.secondScrening.block_8c ? secondScreaning.secondScrening.block_8c :"Select")
            setBlock_8b(secondScreaning.secondScrening.block_8b ? secondScreaning.secondScrening.block_8b :"Select")
            setBlock_8a(secondScreaning.secondScrening.block_8a ? secondScreaning.secondScrening.block_8a :"Select")
           
            setBlock_7c_text(secondScreaning.secondScrening.block_7c_text ? secondScreaning.secondScrening.block_7c_text :"")            
            setBlock_7b_text(secondScreaning.secondScrening.block_7b_text ? secondScreaning.secondScrening.block_7b_text :"")
            setBlock_7a_text(secondScreaning.secondScrening.block_7a_text ? secondScreaning.secondScrening.block_7a_text :"")

            setBlock_7c(secondScreaning.secondScrening.block_7c ? secondScreaning.secondScrening.block_7c :"Select")
            setBlock_7b(secondScreaning.secondScrening.block_7b ? secondScreaning.secondScrening.block_7b :"Select")
            setBlock_7a(secondScreaning.secondScrening.block_7a ? secondScreaning.secondScrening.block_7a :"Select")

            setBlock_6c_text(secondScreaning.secondScrening.block_6c_text ? secondScreaning.secondScrening.block_6c_text :"")
            setBlock_6b_text(secondScreaning.secondScrening.block_6b_text ? secondScreaning.secondScrening.block_6b_text :"")
            setBlock_6a_text(secondScreaning.secondScrening.block_6a_text ? secondScreaning.secondScrening.block_6a_text :"")
            setBlock_6c(secondScreaning.secondScrening.block_6c ? secondScreaning.secondScrening.block_6c :"Select")
            setBlock_6b(secondScreaning.secondScrening.block_6b ? secondScreaning.secondScrening.block_6b :"Select")
            setBlock_6a(secondScreaning.secondScrening.block_6a ? secondScreaning.secondScrening.block_6a :"Select")

            setBlock_5c_text(secondScreaning.secondScrening.block_5c_text ? secondScreaning.secondScrening.block_5c_text :"")
            setBlock_5b_text(secondScreaning.secondScrening.block_5b_text ? secondScreaning.secondScrening.block_5b_text :"")
            setBlock_5a_text(secondScreaning.secondScrening.block_5a_text ? secondScreaning.secondScrening.block_5a_text :"")
            setBlock_5c(secondScreaning.secondScrening.block_5c ? secondScreaning.secondScrening.block_5c :"Select")
            setBlock_5b(secondScreaning.secondScrening.block_5b ? secondScreaning.secondScrening.block_5b :"Select")
            setBlock_5a(secondScreaning.secondScrening.block_5a ? secondScreaning.secondScrening.block_5a :"Select")

            setBlock_4c_text(secondScreaning.secondScrening.block_4c_text ? secondScreaning.secondScrening.block_4c_text :"")
            setBlock_4b_text(secondScreaning.secondScrening.block_4b_text ? secondScreaning.secondScrening.block_4b_text :"")
            setBlock_4a_text(secondScreaning.secondScrening.block_4a_text ? secondScreaning.secondScrening.block_4a_text :"")

            setBlock_4c(secondScreaning.secondScrening.block_4c ? secondScreaning.secondScrening.block_4c :"Select")
            setBlock_4b(secondScreaning.secondScrening.block_4b ? secondScreaning.secondScrening.block_4b :"Select")
            setBlock_4a(secondScreaning.secondScrening.block_4a ? secondScreaning.secondScrening.block_4a :"Select")
            setBlock_3c_text(secondScreaning.secondScrening.block_3c_text ? secondScreaning.secondScrening.block_3c_text :"")
            setBlock_3b_text(secondScreaning.secondScrening.block_3b_text ? secondScreaning.secondScrening.block_3b_text :"")
            setBlock_3a_text(secondScreaning.secondScrening.block_3a_text ? secondScreaning.secondScrening.block_3a_text :"")
            setBlock_3c(secondScreaning.secondScrening.block_3c ? secondScreaning.secondScrening.block_3c :"Select")
            setBlock_3b(secondScreaning.secondScrening.block_3b ? secondScreaning.secondScrening.block_3b :"Select")
            setBlock_3a(secondScreaning.secondScrening.block_3a ? secondScreaning.secondScrening.block_3a :"Select")

            setBlock_2c_text(secondScreaning.secondScrening.block_2c_text ? secondScreaning.secondScrening.block_2c_text :"")
            setBlock_2b_text(secondScreaning.secondScrening.block_2b_text ? secondScreaning.secondScrening.block_2b_text :"")
            setBlock_2a_text(secondScreaning.secondScrening.block_2a_text ? secondScreaning.secondScrening.block_2a_text :"")

            setBlock_2c(secondScreaning.secondScrening.block_2c ? secondScreaning.secondScrening.block_2c :"Select")
            setBlock_2b(secondScreaning.secondScrening.block_2b ? secondScreaning.secondScrening.block_2b :"Select")
            setBlock_2a(secondScreaning.secondScrening.block_2a ? secondScreaning.secondScrening.block_2a :"Select")

            setBlock_1c_text(secondScreaning.secondScrening.block_1c_text ? secondScreaning.secondScrening.block_1c_text :"")
            setBlock_1b_text(secondScreaning.secondScrening.block_1b_text ? secondScreaning.secondScrening.block_1b_text :"")
            setBlock_1a_text(secondScreaning.secondScrening.block_1a_text ? secondScreaning.secondScrening.block_1a_text :"")  

            setBlock_1c(secondScreaning.secondScrening.block_1c ? secondScreaning.secondScrening.block_1c : "Select")
            setBlock_1b(secondScreaning.secondScrening.block_1b ?secondScreaning.secondScrening.block_1b : "Select")
            setBlock_1a(secondScreaning.secondScrening.block_1a ? secondScreaning.secondScrening.block_1a :"Select")
    }
    }, [secondScreaning.secondScrening]);

    const [data, setData] = useState([]);


    useEffect(() => {
        if (!isLoading && firstLevelList && firstLevelList.length > 0) {
            setData(firstLevelList);
        }
    }, [isLoading]);

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(
                    companyDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.comapnyId })
                );
                await dispatch(
                    projectDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.projectId })
                );
                await dispatch(
                    roleTypeDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.roleType })
                );
                await dispatch(
                    languageDataFetch({
                        id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.languageId,
                    })
                );
                await dispatch(
                    cvSourceDataFetch({
                        id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.cvSourceId,
                    })
                );

                await dispatch(
                    CVSourceFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.CvSource
                            .firstFilterId,
                    })
                );

                await dispatch(
                    KeyQualificationFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualification
                            .firstFilterId,
                    })
                );
                await dispatch(
                    KeyQualificationFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualification
                            .secondFilterId,
                    })
                );
                await dispatch(
                    KeyQualificationFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualification
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    KeyQualifyFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualify
                            .firstFilterId,
                    })
                );
                await dispatch(
                    KeyQualifyFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualify
                            .secondFilterId,
                    })
                );
                await dispatch(
                    KeyQualifyFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualify
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    motivationFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.Motivation
                            .firstFilterId,
                    })
                );
                await dispatch(
                    motivationFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.Motivation
                            .secondFilterId,
                    })
                );
                await dispatch(
                    motivationFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.Motivation
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    JobDescriptionFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobDescription
                            .firstFilterId,
                    })
                );
                await dispatch(
                    JobDescriptionFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobDescription
                            .secondFilterId,
                    })
                );
                await dispatch(
                    JobDescriptionFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobDescription
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    SalaryBenifitFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.SalaryBenifit
                            .firstFilterId,
                    })
                );
                await dispatch(
                    SalaryBenifitFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.SalaryBenifit
                            .secondFilterId,
                    })
                );
                await dispatch(
                    SalaryBenifitFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.SalaryBenifit
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    JobReqFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
                            .firstFilterId,
                    })
                );
                await dispatch(
                    JobReqFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
                            .secondFilterId,
                    })
                );
                await dispatch(
                    JobReqFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    JobReqFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
                            .firstFilterId,
                    })
                );
                await dispatch(
                    JobReqFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
                            .secondFilterId,
                    })
                );
                await dispatch(
                    JobReqFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    ReloactionQueryFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ReloactionQuery
                            .firstFilterId,
                    })
                );
                await dispatch(
                    ReloactionQueryFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ReloactionQuery
                            .secondFilterId,
                    })
                );
                await dispatch(
                    ReloactionQueryFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ReloactionQuery
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    ProjectStartDateFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ProjectStartDate
                            .firstFilterId,
                    })
                );
                await dispatch(
                    ProjectStartDateFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ProjectStartDate
                            .secondFilterId,
                    })
                );
                await dispatch(
                    ProjectStartDateFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ProjectStartDate
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    JoiningDocumentFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JoiningDocument
                            .firstFilterId,
                    })
                );
                await dispatch(
                    JoiningDocumentFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JoiningDocument
                            .secondFilterId,
                    })
                );
                await dispatch(
                    JoiningDocumentFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JoiningDocument
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    InterviewProcessFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.InterviewProcess
                            .firstFilterId,
                    })
                );
                await dispatch(
                    InterviewProcessFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.InterviewProcess
                            .secondFilterId,
                    })
                );
                await dispatch(
                    InterviewProcessFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.InterviewProcess
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    FullResumePreviewFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.FullResumePreview
                            .firstFilterId,
                    })
                );
                await dispatch(
                    FullResumePreviewFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.FullResumePreview
                            .secondFilterId,
                    })
                );
                await dispatch(
                    FullResumePreviewFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.FullResumePreview
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    OtherJobInterviewFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.OtherJobInterview
                            .firstFilterId,
                    })
                );
                await dispatch(
                    OtherJobInterviewFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.OtherJobInterview
                            .secondFilterId,
                    })
                );
                await dispatch(
                    OtherJobInterviewFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.OtherJobInterview
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    ContactPreferenceFilterFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ContactPreference
                            .firstFilterId,
                    })
                );
                await dispatch(
                    ContactPreferenceFilterSecond({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ContactPreference
                            .secondFilterId,
                    })
                );
                await dispatch(
                    ContactPreferenceFilterThird({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ContactPreference
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    screaningLevelFirst({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.screningForm
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLevel5th_1({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fiveBlock
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLevel5th_2({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fiveBlock
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    firstLevel5th_3({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fiveBlock
                            .thirdDropdownId,
                    })
                );

                await dispatch(
                    firstLevel6th_1({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sixBlock
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLevel6th_2({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sixBlock
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    firstLevel6th_3({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sixBlock
                            .thirdDropdownId,
                    })
                );
                await dispatch(
                    firstLevel7th_1({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sevenBlock
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLevel7th_2({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sevenBlock
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    firstLevel7th_3({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sevenBlock
                            .thirdDropdownId,
                    })
                );
                await dispatch(
                    firstLevel8th_1({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.eightBlock
                            .firstDropdownId,
                    })
                );
                await dispatch(
                    firstLevel8th_2({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.eightBlock
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    firstLevel8th_3({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.eightBlock
                            .thirdDropdownId,
                    })
                );
                await dispatch(
                    screaningAvailability({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.screningForm
                            .secondDropdownId,
                    })
                );
                await dispatch(
                    screaningContactPrefered({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.screningForm
                            .thirdDropdownId,
                    })
                );
                await dispatch(
                    elevenFirstDropdown({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.eleventh
                            .firstFilterId,
                    })
                );
                 await dispatch(
                    elevenSecondDropdown({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.eleventh
                            .secondFilterId,
                    })
                );
                await dispatch(
                    elevenThirdDropdown({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.eleventh
                            .thirdFilterId,
                    })
                );
                await dispatch(
                    twelveFirstDropdown({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.twelveth
                            .firstFilterId,
                    })
                );
                 await dispatch(
                    twelveSecondDropdown({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.twelveth
                            .secondFilterId,
                    })
                );
                await dispatch(
                    twelveThirdDropdown({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.twelveth
                            .thirdFilterId,
                    })
                );

                await dispatch(
                    thirteenFirstDropdown({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.thirteen
                            .firstFilterId,
                    })
                );
                 await dispatch(
                    thirteenSecondDropdown({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.thirteen
                            .secondFilterId,
                    })
                );
                await dispatch(
                    thirteenThirdDropdown({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.thirteen
                            .thirdFilterId,
                    })
                );
                await dispatch(
                    fourteenFirstDropdown({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fourteen
                            .firstFilterId,
                    })
                );
                 await dispatch(
                    fourteenSecondDropdown({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fourteen
                            .secondFilterId,
                    })
                );
                await dispatch(
                    fourteenThirdDropdown({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fourteen
                            .thirdFilterId,
                    })
                );
                await dispatch(
                    fifteenFirstDropdown({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fifteen
                            .firstFilterId,
                    })
                );
                 await dispatch(
                    fifteenSecondDropdown({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fifteen
                            .secondFilterId,
                    })
                );
                await dispatch(
                    fifteenThirdDropdown({
                        id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fifteen
                            .thirdFilterId,
                    })
                );
               
            } catch (err) {
                console.log(err);
            }
        };
        fetchList();
    }, []);

  const updateTextProfile = async(e) => {  
     const key = e.target.dataset.key;
    const value = e.target.value;  
    await dispatch( update2ndScreaning({ id:candidateId, key, value }));
}
const updateProfile =  async (e) => {    
    const key = e.target.dataset.key;
    const value = e.target.dataset.value;    
    await dispatch( update2ndScreaning({ id:candidateId, key, value }));
}

    return (
        <>
            <div className="dkg-cand-screening-mainCon">
                <ul className="dkg-prescreen-listCon">
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {" "}
                                {data != "" ? data[28].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[29].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[32].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_1a == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_1a}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {jobDescFirstList.map((item, index) => (
                                                        <Dropdown.Item
                                                            key={index}
                                                            className="dkg-prescreen-dropdown-item"
                                                            data-key="block_1a"
                                                            data-value={item.value}
                                                            onClick={(e) =>{
                                                                setBlock_1a(item.value);
                                                                updateProfile(e)
                                                            }}
                                                        >
                                                {item.value}                                                            
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[30].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[33].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_1b == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_1b}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {jobDescSecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_1b"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_1b(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            > 
                                                      {item.value}                                                      
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[31].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[34].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_1c == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_1c}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {jobDescThirdList.map((item, index) => (
                                                        <Dropdown.Item
                                                            key={index}
                                                            className="dkg-prescreen-dropdown-item"
                                                            data-key="block_1c"
                                                            data-value={item.value}
                                                            onClick={(e) =>{
                                                                setBlock_1c(item.value);
                                                                updateProfile(e)
                                                            }}
                                                        >
                                                            {item.value}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_1a_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_1a_text"
                                                    data-value={block_1a_text}
                                                    value={block_1a_text}
                                                     onChange={(e) => {
                                                    setBlock_1a_text(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />
                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_1b_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_1b_text"
                                                    data-value={block_1b_text}
                                                    value={block_1b_text}
                                                    onChange={(e) => {
                                                    setBlock_1b_text(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />
                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_1c_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_1c_text"
                                                    data-value={block_1c_text}
                                                    value={block_1c_text}
                                                    onChange={(e) => {
                                                    setBlock_1c_text(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[35].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[36].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[39].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_2a == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_2a}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {SalaryFirstList.map((item, index) => (
                                                        <Dropdown.Item
                                                            key={index}
                                                            className="dkg-prescreen-dropdown-item"
                                                            data-key="block_2a"
                                                            data-value={item.value}
                                                            onClick={(e) =>{
                                                                setBlock_2a(item.value);
                                                                updateProfile(e)
                                                            }}
                                                        >
                                                            {item.value}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[37].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[40].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_2b == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_2b}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {SalarySecondList.map((item, index) => (
                                                        <Dropdown.Item
                                                            key={index}
                                                            className="dkg-prescreen-dropdown-item"
                                                            data-key="block_2b"
                                                            data-value={item.value}
                                                            onClick={(e) =>{
                                                                setBlock_2b(item.value);
                                                                updateProfile(e)
                                                            }}
                                                        >
                                                            {item.value}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[38].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[41].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_2c == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_2c}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {SalaryThirdList.map((item, index) => (
                                                        <Dropdown.Item
                                                            key={index}
                                                            className="dkg-prescreen-dropdown-item"
                                                            data-key="block_2c"
                                                            data-value={item.value}
                                                            onClick={(e) =>{
                                                                setBlock_2c(item.value);
                                                                updateProfile(e)
                                                            }}
                                                        >
                                                            {item.value}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_2a_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_2a_text"
                                                    data-value={block_2a_text}
                                                    value={block_2a_text}
                                                    onChange={(e) => {
                                                    setBlock_2a_text(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />
                                                                                            
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_2b_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_2b_text"
                                                    data-value={block_2b_text}
                                                    value={block_2b_text}
                                                    onChange={(e) => {
                                                    setBlock_2b_text(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_2c_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_2c_text"
                                                    data-value={block_2c_text}
                                                    value={block_2c_text}
                                                    onChange={(e) => {
                                                    setBlock_2c_text(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[42].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[43].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[46].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_3a == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_3a}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {JobRequirnmentFirstList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_3a"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_3a(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[44].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[47].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_3b == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_3b}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {JobRequirnmentSecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_3b"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_3b(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[45].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[48].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_3c == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_3c}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {JobRequirnmentThirdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_3c"
                                                               data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_3c(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_3a_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_3a_text"
                                                    data-value={block_3a_text}
                                                    value={block_3a_text}
                                                    onChange={(e) => {
                                                    setBlock_3a_text(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_3b_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_3b_text"
                                                    data-value={block_3b_text}
                                                    value={block_3b_text}
                                                    onChange={(e) => {
                                                    setBlock_3b_text(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_3c_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_3c_text"
                                                    data-value={block_3c_text}
                                                    value={block_3c_text}
                                                    onChange={(e) => {
                                                    setBlock_3c_text(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[49].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[50].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[53].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_4a == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_4a}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {RelocationQryFirstList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_4a"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_4a(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[51].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[54].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_4b == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_4b}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {RelocationQrySecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_4b"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_4b(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[52].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[55].title : ""
                                                }
                                            />

                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_4c == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_4c}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {RelocationQryThirdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"recolcation-funds" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_4c"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_4c(item.value);
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_4a_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_4a_text"
                                                    data-value={block_4a_text}
                                                    value={block_4a_text}
                                                    onChange={(e) => {
                                                    setBlock_4a_text(e.target.value);
                                                    updateTextProfile(e)
                                                }}
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_4b_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_4b_text"
                                                    data-value={block_4b_text}
                                                    value={block_4b_text}
                                                    onChange={(e) => {
                                                    setBlock_4b_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_4c_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_4c_text"
                                                    data-value={block_4c_text}
                                                    value={block_4c_text}
                                                    onChange={(e) => {
                                                    setBlock_4c_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                               
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[56].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[57].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[60].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_5a == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_5a}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {ProjectStartDateFirstList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"notice-period" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_5a"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_5a(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[58].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[61].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_5b == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_5b}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {ProjectStartDateSecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"discuss-start-date" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_5b"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_5b(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[59].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[62].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_5c == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_5c}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {ProjectStartDateThirdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "negotiate-start-dates" + index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_5c"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_5c(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_5a_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_5a_text"
                                                    data-value={block_5a_text}
                                                    value={block_5a_text}
                                                    onChange={(e) => {
                                                    setBlock_5a_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_5b_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_5b_text"
                                                    data-value={block_5b_text}
                                                    value={block_5b_text}
                                                    onChange={(e) => {
                                                    setBlock_5b_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_5c_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_5c_text"
                                                    data-value={block_5c_text}
                                                    value={block_5c_text}
                                                    onChange={(e) => {
                                                    setBlock_5c_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[63].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[64].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[67].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_6a == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_6a}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {JoiningDocumentFirstList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"id-pass-validity" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_6a"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_6a(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[65].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[68].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_6b == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_6b}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {JoiningDocumentSecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"birth-certificate" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_6b"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_6b(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[66].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[69].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_6c == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_6c}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {JoiningDocumentThirdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"job-refrences" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_6c"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_6c(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_6a_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_6a_text"
                                                    data-value={block_6a_text}
                                                    value={block_6a_text}
                                                    onChange={(e) => {
                                                    setBlock_6a_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                               
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_6b_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_6b_text"
                                                    data-value={block_6b_text}
                                                    value={block_6b_text}
                                                    onChange={(e) => {
                                                    setBlock_6b_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_6c_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_6c_text"
                                                    data-value={block_6c_text}
                                                    value={block_6c_text}
                                                    onChange={(e) => {
                                                    setBlock_6c_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[70].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[71].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[74].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_7a == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_7a}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {InterviewProcessFirstList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={
                                                                    "explain-interview-process" +
                                                                    index
                                                                }
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_7a"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_7a(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[72].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[75].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_7b == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_7b}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {InterviewProcessSecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"interview-timeline" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_7b"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_7b(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[73].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[76].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_7c == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_7c}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {InterviewProcessThirdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"inter-availability" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_7c"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_7c(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_7a_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_7a_text"
                                                    data-value={block_7a_text}
                                                    value={block_7a_text}
                                                    onChange={(e) => {
                                                    setBlock_7a_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_7b_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_7b_text"
                                                    data-value={block_7b_text}
                                                    value={block_7b_text}
                                                    onChange={(e) => {
                                                    setBlock_7b_text(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_7c_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_7c_text"
                                                    data-value={block_7c_text}
                                                    value={block_7c_text}
                                                    onChange={(e) => {
                                                    setBlock_7c_text(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[77].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[78].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[81].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_8a == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_8a}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {FullResumePreviewFirstList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"gap-in-resume" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_8a"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_8a(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[79].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[82].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_8b == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_8b}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {FullResumePreviewSecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"relavent-experince" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_8b"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_8b(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[80].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[83].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_8c == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_8c}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {FullResumePreviewThirdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"resume-layout" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_8c"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_8c(                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_8a_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_8a_text"
                                                    data-value={block_8a_text}
                                                    value={block_8a_text}
                                                    onChange={(e) => {
                                                    setBlock_8a_text(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                               
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_8b_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_8b_text"
                                                    data-value={block_8b_text}
                                                    value={block_8b_text}
                                                    onChange={(e) => {
                                                    setBlock_8b_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                              
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_8c_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_8c_text"
                                                    data-value={block_8c_text}
                                                    value={block_8c_text}
                                                    onChange={(e) => {
                                                    setBlock_8c_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[84].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[85].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[88].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_9a == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_9a}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {OtherJobInterviewFirstList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"other-interviews" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_9a"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_9a(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[86].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[89].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_9b == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_9b}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {OtherJobInterviewSecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"other-joboffer" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_9b"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_9b(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[87].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[90].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_9c == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_9c}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {OtherJobInterviewThirdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"accepted-this-job" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_9c"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_9c(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_9a_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_9a_text"
                                                    data-value={block_9a_text}
                                                    value={block_9a_text}
                                                    onChange={(e) => {
                                                    setBlock_9a_text(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_9b_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_9b_text"
                                                    data-value={block_9b_text}
                                                    value={block_9b_text}
                                                    onChange={(e) => {
                                                    setBlock_9b_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_9c_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_9c_text"
                                                    data-value={block_9c_text}
                                                    value={block_9c_text}
                                                    onChange={(e) => {
                                                    setBlock_9c_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[91].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[92].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[95].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_10a == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_10a}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {TenthFirstList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-methods" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_10a"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_10a(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[93].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[96].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_10b == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_10b}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {TenthSecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-channel" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_10b"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_10b(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[94].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[97].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_10c == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_10c}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {TenthThirdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-avail" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_10c"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_10c(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_10a_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_10a_text"
                                                    data-value={block_10a_text}
                                                    value={block_10a_text}
                                                    onChange={(e) => {
                                                    setBlock_10a_text(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_10b_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_10b_text"
                                                    data-value={block_10b_text}
                                                    value={block_10b_text}
                                                    onChange={(e) => {
                                                    setBlock_10b_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_10c_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_10c_text"
                                                    data-value={block_10c_text}
                                                    value={block_10c_text}
                                                    onChange={(e) => {
                                                    setBlock_10c_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[175].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[176].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[179].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_11a == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_11a}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {ElevenFirstList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-methods" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_11a"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_11a(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[177].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[180].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_11b == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_11b}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {ElevenSecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-channel" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_11b"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_11b(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[178].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[181].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_11c == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_11c}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {ElevenThirdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-avail" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_11c"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_11c(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_11a_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_11a_text"
                                                    data-value={block_11a_text}
                                                    value={block_11a_text}
                                                    onChange={(e) => {
                                                    setBlock_11a_text(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                               
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_11b_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_11b_text"
                                                    data-value={block_11b_text}
                                                    value={block_11b_text}
                                                    onChange={(e) => {
                                                    setBlock_11b_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_11c_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_11c_text"
                                                    data-value={block_11c_text}
                                                    value={block_11c_text}
                                                    onChange={(e) => {
                                                    setBlock_11c_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[182].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[183].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[186].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_12a == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_12a}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {twelveFirstList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-methods" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_12a"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_12a(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[184].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[187].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_12b == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_12b}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {twelveSecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-channel" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_12b"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_12b(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[185].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[188].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_12c == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_12c}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {twelveThirdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-avail" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_12c"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_12c(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_12a_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_12a_text"
                                                    data-value={block_12a_text}
                                                    value={block_12a_text}
                                                    onChange={(e) => {
                                                    setBlock_12a_text(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_12b_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_12b_text"
                                                    data-value={block_12b_text}
                                                    value={block_12b_text}
                                                    onChange={(e) => {
                                                    setBlock_12b_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_12c_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_12c_text"
                                                    data-value={block_12c_text}
                                                    value={block_12c_text}
                                                    onChange={(e) => {
                                                    setBlock_12c_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[189].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[190].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[193].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_13a == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_13a}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {thirteenFirstList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-methods" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_13a"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_13a(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[191].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[194].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_13b == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_13b}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {thirteenSecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-channel" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_13b"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_13b(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[192].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[195].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_13c == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_13c}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {thirteenThirdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-avail" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_13c"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_13c(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_13a_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_13a_text"
                                                    data-value={block_13a_text}
                                                    value={block_13a_text}
                                                    onChange={(e) => {
                                                    setBlock_13a_text(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                               
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_13b_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_13b_text"
                                                    data-value={block_13b_text}
                                                    value={block_13b_text}
                                                    onChange={(e) => {
                                                    setBlock_13b_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_13c_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_13c_text"
                                                    data-value={block_13c_text}
                                                    value={block_13c_text}
                                                    onChange={(e) => {
                                                    setBlock_13c_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[196].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[197].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[200].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_14a == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_14a}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {fourteenFirstList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-methods" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_14a"
                                                                data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_14a(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[198].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[201].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_14b == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_14b}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {fourteenSecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-channel" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_14b"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_14b(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[199].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[202].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_14c == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_14c}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {fourteenThirdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-avail" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_14c"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_14c(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_14a_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_14a_text"
                                                    data-value={block_14a_text}
                                                    value={block_14a_text}
                                                    onChange={(e) => {
                                                    setBlock_14a_text(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                                
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_14b_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_14b_text"
                                                    data-value={block_14b_text}
                                                    value={block_14b_text}
                                                    onChange={(e) => {
                                                    setBlock_14b_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_14c_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_14c_text"
                                                    data-value={block_14c_text}
                                                    value={block_14c_text}
                                                    onChange={(e) => {
                                                    setBlock_14c_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dkg-prescreen-list current">
                        <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                                {data != "" ? data[203].title : ""}
                            </Link>
                        </div>
                        <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                                <div className="row">
                                    <div className="col-md-7 pr-2">
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[204].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[207].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_15a == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_15a}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {fifteenFirstList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-methods" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_15a"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_15a(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[205].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[208].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_15b == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_15b}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {fifteenSecondList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-channel" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_15b"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_15b(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="form-group mb-0">
                                            <PophoverIcon
                                                dkglabeltitle={
                                                    data != "" ? data[206].title : ""
                                                }
                                                hovertitle={
                                                    data != "" ? data[209].title : ""
                                                }
                                            />
                                            <Dropdown className="dkg-cand-prescreen-dropdown">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className={`dkg-cand-prescreen-dropdown-toggle ${block_15c == "Select"
                                                        ? "addBgColourInput"
                                                        : "removeAddBgColourInput"
                                                        }`}
                                                >
                                                    {block_15c}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                                    {fifteenThirdList.map(
                                                        (item, index) => (
                                                            <Dropdown.Item
                                                                key={"preferred-avail" + index}
                                                                className="dkg-prescreen-dropdown-item"
                                                                data-key="block_15c"
                                                            data-value={item.value}
                                                                onClick={(e) =>{
                                                                    setBlock_15c(
                                                                        item.value
                                                                    );
                                                                    updateProfile(e)
                                                                }}
                                                            >
                                                                {item.value}
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-md-5 pl-2">
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_15a_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_15a_text"
                                                    data-value={block_15a_text}
                                                    value={block_15a_text}
                                                    onChange={(e) => {
                                                    setBlock_15a_text(
                                                        e.target.value
                                                    );
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_15b_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_15b_text"
                                                    data-value={block_15b_text}
                                                    value={block_15b_text}
                                                    onChange={(e) => {
                                                    setBlock_15b_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                            <input
                                                placeholder="Notes...."
                                                className={`form-control dkg-salary-joining-textarea ${block_15c_text == ""
                                                    ? "addBgColourInput"
                                                    : "removeAddBgColourInput"
                                                    }`}
                                                    data-key="block_15c_text"
                                                    data-value={block_15c_text}
                                                    value={block_15c_text}
                                                    onChange={(e) => {
                                                    setBlock_15c_text(e.target.value);
                                                    updateTextProfile(e)
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                </ul>
            </div>
        </>
    )
}

export default SecondScreening