import React, { useState } from 'react';
import Submenu from '../../training/Submenu';
import DetailLeftPanel from "./DetailLeftPanel";
import DetailTabs from './DetailTabs';
import ToolsIcon from '../../../../assets/images/tools_black.png';
import '../trainingdetail.scss';

const TrainingTools = () => {
    return (
        <React.Fragment>
            <Submenu />
            <div className="dk-backBtnMain">
                <h3 className="dk-detailTitle">
                    <img src={ToolsIcon} className="img-fluid" alt="" />
                    Tools
                </h3>
            </div>

            <div className="dk-trainingDetailMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <DetailLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <DetailTabs />
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default TrainingTools;

