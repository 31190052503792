import React from 'react'
import { Link } from 'react-router-dom'
import { FaSearch, FaExpandArrowsAlt , FaPlus, FaEye ,FaDownload , FaRegTrashAlt } from "react-icons/fa";
import { FaPenToSquare } from "react-icons/fa6";


const SavedAds = () => {
  return (
    <>
        <div className="dkg-savedads-mainCon">
            <div className='dkg-savedads-header'>
                <div className='dkg-left-savedads-Con'>
                    <div className='dk-searchCols'>
                        <input type="text" name="" id="" placeholder="Search..." className="form-control" value="" />
                        <button><FaSearch /></button>
                    </div>
                </div>
                {/* <div className="title">CLIENTS FILES</div> */}
                <div className='dkg-right-savedads-Con'>
                    <Link to="#" className='dkg-addnew-btn mr-2'><FaExpandArrowsAlt /> </Link>
                    <Link to="#" className='dkg-addnew-btn'>Add New <FaPlus className='ml-2' /> </Link>
                </div>
            </div> 
            <div className='dkg-edit-jobfiles-tableCon'>
                <table className='dkg-edit-jobfiles-table table table-bordered'>
                    <thead>
                        <tr>
                            <th>SNo.</th>
                            <th>File Name</th>
                            <th>File Type</th>
                            <th>View</th>
                            <th>Download</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Teleperformance Greece.pdf</td>
                            <td>PROFILE</td>
                            <td>
                                <div className='dkg-file-actions'>
                                    <Link to="#" className="dkg-viewfile-icon"><FaEye /></Link>
                                </div>
                            </td>
                            <td>
                                <div className='dkg-file-actions'>
                                    <Link to="#" className="dkg-downfile-icon"><FaDownload /></Link>
                                </div>
                            </td>
                            <td>
                                <div className='dkg-file-actions'>
                                    <Link to="#" className="dkg-edit-icon mr-2"><FaPenToSquare /></Link>
                                    <Link to="#" className="dkg-delete-icon"><FaRegTrashAlt /></Link>

                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>   
        </div>
    </>
  )
}

export default SavedAds