import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { showError, showSuccess } from "../../../../slice/utils/message/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import { listColors, addHeadings, clearState } from "../../../../slice/jobProfile/jobProfileSlice";
import $ from "jquery";
import SubmitButton from "../../../ui/submitButton";

const AddJobProfileModal = ({ handleClose, profileUserId }) => {
    const dispatch = useDispatch();
    const { colors, isColorSuccess, isInsert } = useSelector(state => state.jobProfile);
    const [colorList, setColorList] = useState([]);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [name, setName] = useState('');
    const [userId] = useState(profileUserId);
    const [bgColor, setBgColor] = useState('');
    const [txtColor] = useState('#FFFFFF');
    const [boxId] = useState('1');

    useEffect(() => {
        async function fetchColors() {
            try {
                await dispatch(listColors({}))
            } catch (err) {
                dispatch(showError({ msg: err }))
            }
        }
        fetchColors()
    }, [])

    useEffect(() => {
        if (isColorSuccess) {
            setColorList(colors)
        }
    }, [isColorSuccess])

    const selectColor = (e) => {
        $('.newColorDepartment').html('')
        $('#' + e.target.id).html('<i className="fa fa-check" aria-hidden="true"></i>')
        setBgColor(e.target.dataset.value)
    }

    const handleButtonClick = async () => {
        if (!name) {
            dispatch(showError({ msg: 'Please fill departments.' }))
        } else {
            setSuccess(false);
            setLoading(true);
            dispatch(addHeadings({ name, userId, bgColor, txtColor, boxId, parentId: '0' }))
        }
    }

    useEffect(() => {
        if (isInsert) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Department added suucessfully' }))
            setSuccess(true);
            setLoading(false);
            handleClose()
        }
    }, [isInsert])

    return (
        <React.Fragment>
            <Modal className="dk-editJobProfile" show={true} onHide={handleClose}>
                <Modal.Body>
                    <div className="dk-editJobProfile-container">
                        <div className="dk-editJobProfile-head" style={{ background: '#5e5e5e' }}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="title">Departments</div>
                                <Modal.Header closeButton></Modal.Header>
                            </div>
                        </div>
                        <div className="dk-editJobProfile-editSec">
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td colSpan="2">
                                                {
                                                    colorList.map((item, idx) =>
                                                        <div id={item.id} className="newColorDepartment" data-value={item.color} style={{ background: item.color }} onClick={selectColor}></div>
                                                    )
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <div className="dk-editSec-input" style={{ width: '80%', margin: 'auto' }}>
                                                    <input type="text" className="form-control" placeholder="Enter Heading..." onChange={(e) => setName(e.target.value)} />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <SubmitButton txt="SAVE" loading={loading} success={success} onClick={handleButtonClick} className="btnAddResponsbility mt-3" position="justify-content-center" />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default AddJobProfileModal;