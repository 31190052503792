import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import "./edit.scss"
import FlagImg from '../../../../../../assets/images/austria.jpg';


const EditModal = ({ onHide }) => {

    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-addNewResModalDialog modal-40w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton className="dk-resourcePopupHedaer">
                    <Modal.Title id="example-custom-modal-styling-title">
                        Edit
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group dk-imgRowDiv">
                                    <div className="dk-flagUploadFilter">
                                        <div className="dk-imgincFlagCon">
                                            <input type="file" />
                                            <img className="previewImglass" src={FlagImg} alt="preview" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Name of Country</label>
                                    <input type="text" className="form-control broderContrl" name="countryName" placeholder="AUSTRIA" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Currency</label>
                                    <input type="text" className="form-control broderContrl" name="currency" placeholder="$" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Income Upto</label>
                                    <input type="text" className="form-control broderContrl" name="income" placeholder="1" />
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label>Basic Income Tax</label>
                                    <input type="text" className="form-control broderContrl" name="basicIncome" placeholder="1" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Others Tax</label>
                                    <input type="text" className="form-control broderContrl" name="securityTax" placeholder="1" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Total Tax</label>
                                    <input type="text" className="form-control broderContrl" name="healthTax" placeholder="1" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Net Income</label>
                                    <input type="text" className="form-control broderContrl" name="payableTax" placeholder="1" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="dk-resourcePopupbtnCont">
                                    <button className="">update</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default EditModal;
