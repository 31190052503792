import React from 'react';
import WorkPlanLeftPanel from '../leftpanel/WorkPlanLeftPanel';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import WorkPlanSampleDetailTabs from "../detailleftpanel/workplan/WorkPlanSampleDetailTabs";
import '../moduletraining.scss';

const WorkPlanSampleOne = () => {
    return (
        <>
            <div className="dk-moduleTraining-main">
                <div className="dk-moduleTraining-cols">
                    <WorkPlanLeftPanel />
                    <div className="dk-moduleTraining-rightPanel">
                        <div className="title">Sample 1</div>
                        <div className="dk-moduleTraining-rightCols">
                            <div className="dk-getStartedRight-detail">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                                    <Nav variant="">
                                        <Nav.Item>
                                            <Nav.Link eventKey="tabs_1">Video</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tabs_2">Audio</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tabs_3">Details</Nav.Link>
                                        </Nav.Item>
                                    </Nav>

                                    <Tab.Content>
                                        <Tab.Pane eventKey="tabs_1">
                                            <div className="dk-rightDetail-panel">
                                                <WorkPlanSampleDetailTabs />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tabs_2">
                                            <div className="dk-rightDetail-panel">
                                                <WorkPlanSampleDetailTabs />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tabs_3">
                                            <div className="dk-rightDetail-panel">
                                                <WorkPlanSampleDetailTabs />
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WorkPlanSampleOne;
