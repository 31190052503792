import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../responsiveMenu.scss";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import "./mainmenusubmenuc.scss";
import { getSystemUser } from "../../../../../../../slice/mailbox/mailboxSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const MailboxSubmenu = (props) => {
  const classes = useStyles();
  const pathname = window.location.pathname;
  const segment2 = pathname.split("/")[2];
  const segment3 = pathname.split("/")[3];
  const dispatch = useDispatch();

  const {
    getSystemUsersData,
  } = useSelector((state) => state.mailbox);

  // useEffect(() => {
  //   dispatch(getSystemUser());
  // }, [dispatch]);

  const [toggleMenu, setToggleMenu] = useState(false);
  useEffect(() => {
    setToggleMenu(false);
  }, [pathname]);

  const ref = useRef(null);
  const { onClickOutside } = props;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
        setToggleMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <React.Fragment>
      <div
        className="dk-subMenuMain dkg-mainModuleSubmenu res-sub-menu"
        ref={ref}
      >
        <IconButton
          aria-label="delete"
          className="res-sub-btn"
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <i className="fas fa-bars"></i>
        </IconButton>
        <Route>
          <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
            <li>
              <Link
                to="/mailbox/mail/inbox"
                className={segment2 == "mail" ? "active" : ""}
              >
                <span
                  className="dk-workplan-badgeCon dk-payments-achived-bgCon"
                  style={{ backgroundColor: "#26597d" }}
                >
                  <span className="dk-workplan-badgenumber payments-achived-bgNo">
                    {
                      getSystemUsersData.filter((items) => items.type == "user").map((items) => items.mailbox_count).reduce((a, b) => a + b, 0)
                    }
                  </span>
                </span>
                My Emails
              </Link>
            </li>
            {getSystemUsersData.map((items) => (
              items.type == "system-user" && 
                <li key={items.id}>
                  <Link
                    to={`/mailbox/system-user/${items.name.toLowerCase()}/inbox`}
                    className={`${segment3 === items.name.toLowerCase() ? "active" : ""}`}
                  >
                    <span
                      className="dk-workplan-badgeCon dk-payments-achived-bgCon"
                      style={{ backgroundColor: "#26597d" }}
                    >
                      <span className="dk-workplan-badgenumber payments-achived-bgNo">
                        {items.mailbox_count}
                      </span>
                    </span>
                    {items.name}
                  </Link>
                </li>
            ))}
          </ul>
        </Route>
      </div>
    </React.Fragment>
  );
};

export default MailboxSubmenu;
