import React from 'react'
import { Link } from 'react-router-dom'
import './dkg-reopts.scss'
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { Dropdown, Nav, Tab } from 'react-bootstrap';
import DatePicker from './datepicker/DatePicker';
import Deepak from '../../../../assets/images/deepak.png';

const DkgReoprts = () => {
    return (
        <>
            <div className='dkg-workflow-pageMain'>
                <div className='dkg-workflow-topHeader'>
                    <div className="row dkg-overflow-mainRow">
                        <div className="col-12 dkg-overflow-mainCol">
                            {/* <Nav variant="pills" className="flex-row dkg-overflow-navpills">
                                    <Nav.Item className="dkg-overflow-navitem">
                                        <Nav.Link eventKey="deepak" className="dkg-overflow-navlink">
                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="dkg-workflow-user-img" />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-overflow-navitem">
                                        <Nav.Link eventKey="julia" className="dkg-overflow-navlink">
                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1668693896533.png" className="dkg-workflow-user-img" title="Yuliia Bunina" />

                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-overflow-navitem">
                                        <Nav.Link eventKey="pooja" className="dkg-overflow-navlink">
                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="dkg-workflow-user-img" title="Pooja Mangla" />

                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-overflow-navitem">
                                        <Nav.Link eventKey="amit" className="dkg-overflow-navlink">
                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1670076250420.png" className="dkg-workflow-user-img" title="Amit Kumar" />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dkg-overflow-navitem">
                                        <Nav.Link eventKey="angela" className="dkg-overflow-navlink">
                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1664396351247.png" className="dkg-workflow-user-img" title="Angela  Nidia" />
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav> */}
                            <div className='dkg-reports-leftCon'>
                                <Dropdown className='dkg-reports-userDropdown'>
                                    <Dropdown.Toggle variant="" className='dkg-reports-userDropdown-toggle'>
                                        <img src={Deepak} className="dk-reports-user" alt='Users' />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dkg-reports-userDropdown-Menu'>
                                        <div className='d-flex'>
                                            <Dropdown.Item href="#" className='dkg-reports-userDropdown-Item'>
                                                <img src={Deepak} className="dk-reports-img" alt='Users' />
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#" className='dkg-reports-userDropdown-Item'>
                                                <img src={Deepak} className="dk-reports-img" alt='Users' />
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#" className='dkg-reports-userDropdown-Item'>
                                                <img src={Deepak} className="dk-reports-img" alt='Users' />
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#" className='dkg-reports-userDropdown-Item'>
                                                <img src={Deepak} className="dk-reports-img" alt='Users' />
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#" className='dkg-reports-userDropdown-Item'>
                                                <img src={Deepak} className="dk-reports-img" alt='Users' />
                                            </Dropdown.Item>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className='dkg-reports-centereCon'>
                                <Dropdown className='dkg-reports-selectDropdown'>
                                    <Dropdown.Toggle variant="" className='dkg-reports-selectDropdown-toggle'>
                                        Select Report
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dkg-reports-selectDropdown-Menu'>
                                        <Dropdown.Item href="#" className='dkg-reports-selectDropdown-Item'>
                                            Employees
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" className='dkg-reports-selectDropdown-Item'>
                                            All Clients
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" className='dkg-reports-selectDropdown-Item'>
                                            Job Orders
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" className='dkg-reports-selectDropdown-Item'>
                                            Candidates
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" className='dkg-reports-selectDropdown-Item'>
                                            Resources
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" className='dkg-reports-selectDropdown-Item'>
                                            Suppliers
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" className='dkg-reports-selectDropdown-Item'>
                                            IT Projects
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" className='dkg-reports-selectDropdown-Item'>
                                            Accounting
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" className='dkg-reports-selectDropdown-Item'>
                                            Legal Doc
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" className='dkg-reports-selectDropdown-Item'>
                                            Profit & Loss
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className='dk-reports-rightCon'>
                                <div className='dk-jobProfile-Filter mr-2'>
                                    <Dropdown className='dk-jobProfile-drop'>
                                        <Dropdown.Toggle variant="" className='dk-jobProfile-day-toggle'>
                                            Today
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='dk-jobProfile-dropMenu'>
                                            <Dropdown.Item href="#">Today</Dropdown.Item>
                                            <Dropdown.Item href="#">This Week</Dropdown.Item>
                                            <Dropdown.Item href="#">This Month</Dropdown.Item>
                                            <Dropdown.Item href="#">3 Months</Dropdown.Item>
                                            <Dropdown.Item href="#">6 Months</Dropdown.Item>
                                            <Dropdown.Item href="#">This Year</Dropdown.Item>
                                            <Dropdown.Item href="#">All Times</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className='dk-jobProfile-Filter mr-2'>
                                    <DatePicker />
                                </div>
                                <div className='dk-jobProfile-Filter'>
                                    <DatePicker />
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-12 dkg-reports-pageCol'>
                            <div className='dkg-reports-main-pageCon'></div>

                        </div>
                        {/* <div className="col-12 dkg-overflow-mainCol-Content">
                                <Tab.Content className="dkg-overflow-mainContent">
                                    <Tab.Pane eventKey="deepak" className="dkg-overflow-tabpane dkg-reports-tabpane">
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="julia" className="dkg-overflow-tabpane dkg-reports-tabpane">

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="pooja" className="dkg-overflow-tabpane dkg-reports-tabpane">

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="amit" className="dkg-overflow-tabpane dkg-reports-tabpane">

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="angela" className="dkg-overflow-tabpane dkg-reports-tabpane">

                                    </Tab.Pane>
                                </Tab.Content>
                            </div> */}
                    </div>
                </div>

            </div>
        </>
    )
}

export default DkgReoprts