import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import EstablishedLeftTabs from '../detaillefttabs/EstablishedLeftTabs';

import RecuitersNavbar from '../navtabs/RecuitersNavbar';
import RequestLeftPanel from '../leftpanel/RequestLeftPanel';

const WorkplanAccManager = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <React.Fragment>
            {/* <div className="dk-dayclsoing-Main dk-dayclsoing-DetailMain dk-requestMain-container">
                <div className='dkg-started-content-u234'>
                    <div className="d-flex">
                        <RequestLeftPanel />
                        <div className="dk-trainingReqRight-main mt-0">
                            <div className="row">
                                <div className='col-12'>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut quam deserunt quidem recusandae similique ad corporis ducimus in temporibus labore doloribus excepturi aliquam odit, optio inventore exercitationem tempore voluptates culpa.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div> */}
            <div className="dk-companyDetailMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <RequestLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                <h3 className="title">Accounting</h3>
                                <Tabs defaultActiveKey="tabs_1">
                                    <Tab eventKey="tabs_1" title="Details">
                                        <div className="d-flex">
                                            <EstablishedLeftTabs />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tabs_2" title="Samples">
                                        <div className="d-flex">
                                            <EstablishedLeftTabs />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tabs_3" title="Video">
                                        <div className="d-flex">
                                            <EstablishedLeftTabs />
                                        </div>
                                    </Tab>
                                </Tabs>
                                <div title="More" className="clickMoreShow">
                                    More
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}
export default WorkplanAccManager;

