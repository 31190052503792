import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { FaUserAlt } from "react-icons/fa";

const index = () => {
    return (
        <>
            <Dropdown className='dkg-candfilter-dropdown14'>
                <Dropdown.Toggle variant="success" className='dkg-candfilter-dropdown-toggle14'>
                    <FaUserAlt />
                </Dropdown.Toggle>
                <Dropdown.Menu className='dkg-candfilter-dropdown-menu14'>
                    <Dropdown.Item href="#/action-1" className='dkg-candfilter-dropdown-item14'>
                        <FaUserAlt style={{ color: "green" }} />
                        <span className="proflieCount">Motivated </span>
                        <span className="proflieCount">14</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className='dkg-candfilter-dropdown-item14'>
                        <FaUserAlt style={{ color: "#d58b5d" }} />
                        <span className="proflieCount">Doubtful </span>
                        <span className="proflieCount">6</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className='dkg-candfilter-dropdown-item14' >
                        <FaUserAlt style={{ color: "red" }} />
                        <span className="proflieCount">Unmotivated</span>
                        <span className="proflieCount">4</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1" className='dkg-candfilter-dropdown-item14'>
                        <FaUserAlt style={{ color: "#d3d3d3" }} />
                        <span className="proflieCount">Blank </span>
                        <span className="proflieCount">5 </span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default index;