import React from 'react';
import OnboardingIcon from '../../../../../assets/images/onboarding_black.png';
const HeadingTitle = () => {
    return (
        <>
            <div className="dk-backBtnMain">
                <h3 className="dk-detailTitle">
                    <img src={OnboardingIcon} className="img-fluid" alt="" />
                    ONBOARDING
                </h3>
            </div>
        </>
    )
}

export default HeadingTitle;
