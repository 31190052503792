import React from "react";
import { Link, Route } from "react-router-dom";
import "../../trainingdetail.scss";

const KnowledgeBaseDetailLeftPanel = () => {
  let pathname = window.location.pathname;
  return (
    <React.Fragment>
      <ul>
        <Route>
          <li>
            <Link
              to="/training/recruiters/knowledge-base"
              className={`${
                pathname.match("/training/recruiters/knowledge-base")
                  ? "active"
                  : ""
              }`}
            >
              Overview
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/search-info"
              className={`${
                pathname.match("/training/recruiters/search-info")
                  ? "active"
                  : ""
              }`}
            >
              Search Info
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/tasks-info"
              className={`${
                pathname.match("/training/recruiters/tasks-info")
                  ? "active"
                  : ""
              }`}
            >
              Tasks Info
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/tools-info"
              className={`${
                pathname.match("/training/recruiters/tools-info")
                  ? "active"
                  : ""
              }`}
            >
              Tools Info
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/candidates"
              className={`${
                pathname.match("/training/recruiters/candidates")
                  ? "active"
                  : ""
              }`}
            >
              Candidates
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/clients"
              className={`${
                pathname.match("/training/recruiters/clients") ? "active" : ""
              }`}
            >
              Clients
            </Link>
          </li>

          <li>
            <Link
              to="/training/recruiters/jobs"
              className={`${
                pathname.match("/training/recruiters/jobs") ? "active" : ""
              }`}
            >
              Jobs
            </Link>
          </li>
        </Route>
      </ul>
    </React.Fragment>
  );
};

export default KnowledgeBaseDetailLeftPanel;
