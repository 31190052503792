import React from "react";
import "./casestatus.scss";

const CaseStatus = ({ value }) => {
  let bgColor = "";
  switch (value) {
    case "New Case":
      bgColor = "#5c737d";
      break;

    case "Selected":
      bgColor = "#715f8f";
      break;

    case "Qualifying":
      bgColor = "#57496d";
      break;
    case "Database":
      bgColor = "#26596d";
      break;
    case "Sendout":
      bgColor = "#02A786";
      break;

    case "Interviews":
      bgColor = "#316a67";
      break;

    case "Job Offers":
      bgColor = "#629bd3";
      break;

    case "Joiners":
      bgColor = "#4768b0";
      break;

    case "Database":
      bgColor = "#42908c";
      break;

    // case 'Refused':
    //     bgColor = '#a84d4d'
    //     break;

    case "Blacklist":
      bgColor = "#3C3939";
      break;

    case "Closed":
      bgColor = "#da3d5e";
      break;

    case "Invoice":
      bgColor = "#39485d";
      break;
    case "Closed - L1":
      bgColor = "#da3d5e";
      break;
    case "Closed - L2":
      bgColor = "#d0021b";
      break;
    case "Job Joining":
      bgColor = "#4768b0";
      break;
    case "Invoicing":
      bgColor = "#470000";
      break;

    default:
      bgColor = "#000000";
      break;
  }

  return (
    <>
      <div className="caseDiv" style={{ backgroundColor: bgColor }}>
        {value}
      </div>
    </>
  );
};

export default CaseStatus;
