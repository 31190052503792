import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./jobsrightmodal.scss";
import { Modal, Tab, Nav } from "react-bootstrap";
import JobSummaryDetails from "./jobsummarydetails/jobsummary";
import Jobdetails from "./jobdetail/Jobdetails";
import { jobsDetails } from "../../../../../slice/jobs/jobsSlice";
import { fetechById } from "../../../../../slice/companies/clients/clientSlice";
import { clientscomp } from "../../../../../slice/candidatePrecreeningForm/scripts";

const EditdetialviewPoup = ({
  show,
  onHide,
  jobId,
  popupfor,
  // clientidds,
  companyName,
}) => {
  const dispatch = useDispatch();
  const { isDetailsLoading, jobDetails } = useSelector((state) => state.jobs);
  const [Jobdata, setJobdata] = useState("");
  useEffect(() => {
    if (show) {
      const fetchRecods = async () => {
        await dispatch(jobsDetails({ id: jobId }));
      };
      fetchRecods();
    }
  }, [show]);

  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setJobdata(jobDetails);
    }
  }, [isDetailsLoading]);

  useEffect(() => {
    if (jobDetails > 0 && !isDetailsLoading) {
      dispatch(fetechById({ id: Jobdata.companyDetails.clientId }));
      dispatch(
        clientscomp({
          id: Jobdata.companyDetails.clientId,
          clientname: Jobdata.companyDetails.name,
        })
      );
    }
  }, [isDetailsLoading]);

  const [eventKey, SetEventKey] = useState("jobSummaryOverview");
  return (
    <React.Fragment>
      <div className="dk-cliDetViewPopup">
        <Modal
          show={show}
          dialogClassName="dk-livejobrightModalDailog"
          aria-labelledby="example-custom-modal-styling-title"
          onHide={onHide}
        >
          <Modal.Header
            closeButton
            className="dk-detViewheader dkg-edit-jobinfo-modal-header"
            style={{ backgroundColor: "#ddd" }}
          >
            <Modal.Title
              className="dk-detViewTitle"
              id="example-custom-modal-styling-title"
            >
              <div className="modalTitleCon" style={{ color: "#333" }}>
                JOB ID - {Jobdata.job_id}{" "}
              </div>
              <div
                className="modalTitleCon"
                // data-id={Jobdata.id}
                // data-column="updated_on"
                // onClick={onDateClick}
              >
                {/* Edit Full Job Details */}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="dkg-full-jobdetail-modalmody">
            <div className="dkg-full-jobdetail-maincon">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="full-job-details"
              >
                <div className="dkg-full-jobdetail-mainRow row">
                  <div className="dkg-full-jobdetail-mainCol col-4 m-auto">
                    <Nav
                      variant="pills"
                      className="flex-row dkg-full-jobdetail-navpills"
                    >
                      <Nav.Item className="dkg-full-jobdetail-navitem">
                        <Nav.Link
                          eventKey="full-job-details"
                          className="dkg-full-jobdetail-navlink"
                        >
                          Job Information
                        </Nav.Link>
                      </Nav.Item>
                      {
                        //disableEdit call from candidate profile applied
                        popupfor === "disableEditCandidateApplied" ? (
                          <></>
                        ) : (
                          <Nav.Item className="dkg-full-jobdetail-navitem">
                            <Nav.Link
                              eventKey="edit-jobdetails"
                              className="dkg-full-jobdetail-navlink"
                            >
                              Edit
                            </Nav.Link>
                          </Nav.Item>
                        )
                      }
                    </Nav>
                  </div>
                  <div className="dkg-full-jobdetail-mainCol-12 col-12">
                    <Tab.Content className="dkg-full-jobdetail-tabcontent">
                      <Tab.Pane
                        eventKey="full-job-details"
                        className="dkg-full-jobdetail-tabpane"
                      >
                        <div className="dk-ClentViewTabPane jobSummaryDetails dkg-livejobs-suMMTabpane pl-0 pr-0">
                          <JobSummaryDetails jobId={jobId} />
                        </div>
                      </Tab.Pane>
                      {
                        //disableEdit call from candidate profile applied
                        popupfor === "disableEditCandidateApplied" ? (
                          <></>
                        ) : (
                          <Tab.Pane
                            eventKey="edit-jobdetails"
                            className="dkg-full-jobdetail-tabpane dkg-edit-full-jobdetail-tabpane"
                          >
                            <Jobdetails
                              jobId={Jobdata.job_id}
                              company_name={companyName}
                            />
                          </Tab.Pane>
                        )
                      }
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default EditdetialviewPoup;
