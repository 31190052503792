import React, { useEffect, useState } from 'react';
import './holidays.scss';
import Modal from 'react-bootstrap/Modal';

const HolidayDetailModal = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <React.Fragment>
            <button onClick={handleShow}><i className="far fa-file-alt"></i></button>
            <Modal className="dk-holidayDetailMain" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <h4 className="modal-title">Holiday Detail</h4>
                </Modal.Header>
                <Modal.Body className="dk-detailModal">

                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default HolidayDetailModal;
