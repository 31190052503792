import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const NotReachableDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkglobaladmin/admintraining/admins/not-reachables" className={`${pathname.match('/dkglobaladmin/admintraining/admins/not-reachables') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/notreachables/situations" className={`${pathname.match('/dkglobaladmin/admintraining/admins/notreachables/situations') ? 'active' : ''}`}>Situations</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/notreachables/task-rules" className={`${pathname.match('/dkglobaladmin/admintraining/admins/notreachables/task-rules') ? 'active' : ''}`}>Task Rules</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/notreachables/how-to-do" className={`${pathname.match('/dkglobaladmin/admintraining/admins/notreachables/how-to-do') ? 'active' : ''}`}>How To Do</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/notreachables/preparation" className={`${pathname.match('/dkglobaladmin/admintraining/admins/notreachables/preparation') ? 'active' : ''}`}>Preparation</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/notreachables/action-tools" className={`${pathname.match('/dkglobaladmin/admintraining/admins/notreachables/action-tools') ? 'active' : ''}`}>Action Tools</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/notreachables/phone-call" className={`${pathname.match('/dkglobaladmin/admintraining/admins/notreachables/phone-call') ? 'active' : ''}`}>Phone Call</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/notreachables/send-email" className={`${pathname.match('/dkglobaladmin/admintraining/admins/notreachables/send-email') ? 'active' : ''}`}>Send Email</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/notreachables/send-sms" className={`${pathname.match('/dkglobaladmin/admintraining/admins/notreachables/send-sms') ? 'active' : ''}`}>Send SMS</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default NotReachableDetailLeftPanel;

