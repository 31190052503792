import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SYSTEM_CONSTANTS } from "../../../constants";

export const getJobBoardList = createAsyncThunk(
    'appsTracker/job_board_list',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/admin/backend-value/' + SYSTEM_CONSTANTS.ADS.JobBoardId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const getDailyTracker = createAsyncThunk(
    'appsTracker/dailyTrackerList',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/ads/apps-tracker/daily',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getUserList = createAsyncThunk(
    'appsTracker/list',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateValueApps = createAsyncThunk(
    'appsTracker/updateValue',
    async ({ rowId, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + '/ads/apps-tracker/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        value
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const addAppsTracker = createAsyncThunk(
    'appsTracker/add',
    async ({ recruiter, jobBoard, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + '/ads/apps-tracker',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                    body: JSON.stringify({
                        recruiter, jobBoard, value
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getWeeklyTracker = createAsyncThunk(
    'appsTracker/weeklyTrackerList',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + '/ads/apps-tracker/weekly',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const getMonthlyTracker = createAsyncThunk(
    'appsTracker/monthlyTrackerList',
    async (thunkAPI) => {
        try {
            let today = new Date();
            let year = today.getFullYear();
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + '/ads/apps-tracker/monthly/' + year,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const fetchByIdMonthly = createAsyncThunk(
    'appsTracker/monthlylist',
    async ({ userId }, thunkAPI) => {
        try {
            let today = new Date();
            let year = today.getFullYear();
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + '/ads/apps-tracker/fetchById/' + userId + "/" + year,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const fetchByIdYearly = createAsyncThunk(
    'appsTracker/yearlylist',
    async ({ userId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + '/ads/apps-tracker/fetchByIdYearly/' + userId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const weeklyfetchById = createAsyncThunk(
    'appsTracker/weeklyFetchbyidlist',
    async ({ recruiter }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + '/ads/apps-tracker/weeklyfetchById/' + recruiter,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getYearlyTracker = createAsyncThunk(
    'appsTracker/yearlyTrackerList',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + '/ads/apps-tracker/yearly',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const adsTrackerSlice = createSlice({
    name: 'ads_tracker',
    initialState: {
        userList: [],
        dataList: [],
        detailData: [],
        jobBoardList: [],
        appsTrackerDaily: [],
        appsTrackerWeekly: [],
        appsTrackerYearly: [],
        appsTrackerMonthly: [],
        isLoading: false,
        isDataLoading: true,
        isInsert: false,
        isError: false,
        isUpdate: false,
        isDelete: false,
        isSuccess: false,
        isFetching: false,
        errorMessage: '',
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isDataLoading = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorMessage = '';
            return state;
        },
        bulkDeleteState: (state) => {
            state.isDelete = true
        }
    },
    extraReducers: {
        [getJobBoardList.pending]: (state) => {
            state.isLoading = true;
        },
        [getJobBoardList.fulfilled]: (state, { payload }) => {
            state.jobBoardList = payload.result;
            state.isLoading = false;
        },
        [getJobBoardList.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
            state.isLoading = false;
        },
        [getUserList.pending]: (state) => {
            state.isLoading = true;
        },
        [getUserList.fulfilled]: (state, { payload }) => {
            state.userList = payload.result;
            state.isSuccess = true;
            state.isFetching = true;
        },
        [getUserList.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
            state.isFetching = false;
        },
        [weeklyfetchById.pending]: (state) => {
            state.isLoading = true;
        },
        [weeklyfetchById.fulfilled]: (state, { payload }) => {
            state.appsTrackerWeekly = payload.result;
            state.isSuccess = true;
            state.isFetching = true;
        },
        [weeklyfetchById.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
            state.isFetching = false;
        },
        [addAppsTracker.pending]: (state) => {
            state.isLoading = true;
        },
        [addAppsTracker.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = true;
            state.appsTrackerDaily.unshift(payload.result)
            state.msg = payload.message
        },
        [addAppsTracker.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message
        },
        [getDailyTracker.pending]: (state) => {
            state.isLoading = true;
        },
        [getDailyTracker.fulfilled]: (state, { payload }) => {
            state.appsTrackerDaily = payload.result;
            state.isLoading = false;
        },
        [getDailyTracker.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
            state.isLoading = false;
        },
        [updateValueApps.pending]: (state) => {
        },
        [updateValueApps.fulfilled]: (state, { payload }) => {
            let mainArray = state.appsTrackerDaily;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.appsTrackerDaily = finalResult
            state.isUpdate = true;
        },
        [updateValueApps.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isUpdate = false;
        },
        [getWeeklyTracker.pending]: (state) => {
            state.isLoading = true;
        },
        [getWeeklyTracker.fulfilled]: (state, { payload }) => {
            state.appsTrackerWeekly = payload.result;
            state.isLoading = false;
        },
        [getWeeklyTracker.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
            state.isLoading = false;
        },
        [getYearlyTracker.pending]: (state) => {
            state.isLoading = true;
        },
        [getYearlyTracker.fulfilled]: (state, { payload }) => {
            state.appsTrackerYearly = payload.result;
            state.isLoading = false;
        },
        [getYearlyTracker.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
            state.isLoading = false;
        },
        [getMonthlyTracker.pending]: (state) => {
            state.isLoading = true;
        },
        [getMonthlyTracker.fulfilled]: (state, { payload }) => {
            state.appsTrackerMonthly = payload.result;
            state.isLoading = false;
        },
        [getMonthlyTracker.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
            state.isLoading = false;
        },
        [fetchByIdMonthly.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchByIdMonthly.fulfilled]: (state, { payload }) => {
            state.appsTrackerMonthly = payload.result;
            state.isSuccess = true;
            state.isFetching = true;
        },
        [fetchByIdMonthly.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
            state.isFetching = false;
        },
        [fetchByIdYearly.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchByIdYearly.fulfilled]: (state, { payload }) => {
            state.appsTrackerYearly = payload.result;
            state.isSuccess = true;
            state.isFetching = true;
        },
        [fetchByIdYearly.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
            state.isFetching = false;
        },

    }
});

export const { clearState, bulkDeleteState } = adsTrackerSlice.actions;
export default adsTrackerSlice.reducer;
