import React from 'react';
import moment from "moment-timezone";
export function daysAgo(dateTime) {
    if (dateTime != null) {
        let date = moment(dateTime);
        if (moment().diff(date, 'days') >= 1) {
            // console.log(date.fromNow())
            return date.fromNow();
        } else {
            return date.calendar().split(' ')[0]; // 'Yesterday', 'Today', 'Tomorrow'
        }
    } else {
        return null
    }
}