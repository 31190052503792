import React, { useEffect, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import "./adstracker.css";
//import { Link } from "react-router-dom";
import LiveAdsTable from "./LiveAdsTable";
import { useDispatch, useSelector } from "react-redux";
import {  livelistTable, clearState } from '../../../../slice/ads/liveAds/liveAdsSlice';
import Slider from "react-slick";
import "./customslider.scss";

const TRACER_JOB_BOARD_ELJ = process.env.REACT_APP_TRACER_JOB_BOARD_ELJ;
const TRACER_JOB_BOARD_MLV = process.env.REACT_APP_TRACER_JOB_BOARD_MLV;
const TRACER_JOB_BOARD_HIPO = process.env.REACT_APP_TRACER_JOB_BOARD_HIPO;
const TRACER_JOB_BOARD_DK_WEBSITE = process.env.REACT_APP_TRACER_JOB_BOARD_DK_WEBSITE;
const TRACER_JOB_BOARD_LINKEDIN = process.env.REACT_APP_TRACER_JOB_BOARD_LINKEDIN;
const TRACER_JOB_BOARD_INDEED = process.env.REACT_APP_TRACER_JOB_BOARD_INDEED;
const TRACER_JOB_BOARD_EJOBS = process.env.REACT_APP_TRACER_JOB_BOARD_EJOBS;

const LiveAds = () => {
    const dispatch = useDispatch();
   // const { adsJobboardList, adsCompanyList, adsLanguageList, adsRoleTypeList } = useSelector(state => state.common)

    const [tableId, setTableId] = useState('')
    const [tableName, setTableName] = useState('ALL JOB BOARDS')

    const { dataList, isDataLoading } = useSelector(state => state.liveAdsTracker)

    const [allDataCount, setAllDataCount] = useState('0')
    const [eljDataCount, setEljDataCount] = useState('0')
    const [mlvDataCount, setMlvDataCount] = useState('0')
    const [indeedDataCount, setIndeedDataCount] = useState('0')
    const [hipoDataCount, setHipoDataCount] = useState('0')
    const [ejobsDataCount, setEjobsDataCount] = useState('0')
    const [dkWebDataCount, setDkWebDataCount] = useState('0')
    const [linkedinDataCount, setLinkedinDataCount] = useState('0')
    const [isDone, setIsDone] = useState(false)

    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(livelistTable({}))
                setIsDone(true)
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        if (isDone && !isDataLoading && dataList && dataList.length > 0) {
            setAllDataCount(dataList.length)
            setEljDataCount(dataList.filter(dataList => dataList.jobBoard === TRACER_JOB_BOARD_ELJ).length)
            setMlvDataCount(dataList.filter(dataList => dataList.jobBoard === TRACER_JOB_BOARD_MLV).length)
            setIndeedDataCount(dataList.filter(dataList => dataList.jobBoard === TRACER_JOB_BOARD_INDEED).length)
            setHipoDataCount(dataList.filter(dataList => dataList.jobBoard === TRACER_JOB_BOARD_HIPO).length)
            setEjobsDataCount(dataList.filter(dataList => dataList.jobBoard === TRACER_JOB_BOARD_EJOBS).length)
            setDkWebDataCount(dataList.filter(dataList => dataList.jobBoard === TRACER_JOB_BOARD_DK_WEBSITE).length)
            setLinkedinDataCount(dataList.filter(dataList => dataList.jobBoard === TRACER_JOB_BOARD_LINKEDIN).length)
            dispatch(clearState())
        }
    }, [isDone, isDataLoading, dataList])

    const setTableFilter = (id, title) => {
        setTableId(id)
        setTableName(title)
    }

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div className="container-fluid dk-adsTrackPageCon dk-liveAdsMainContainer">
                <Tab.Container className="" id="left-tabs-example" defaultActiveKey="all">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 m-auto" style={{ position: 'absolute', left: '25%', zIndex: 9 }}>
                            <div className="dk-liveadsTabsCon">
                                <Slider {...settings}>
                                    <div className={(tableId == '0') ? 'liveadsTabCon active' : 'liveadsTabCon'}>
                                        <span onClick={() => setTableFilter(0, 'ALL JOB BOARDS')}>All - {allDataCount}</span>
                                    </div>
                                    <div className={(tableId == TRACER_JOB_BOARD_ELJ) ? 'liveadsTabCon active' : 'liveadsTabCon'}>
                                        <span onClick={() => setTableFilter(TRACER_JOB_BOARD_ELJ, 'ELJ')}>ELJ - {eljDataCount}</span>
                                    </div>
                                    <div className={(tableId == TRACER_JOB_BOARD_MLV) ? 'liveadsTabCon active' : 'liveadsTabCon'}>
                                        <span onClick={() => setTableFilter(TRACER_JOB_BOARD_MLV, 'MLV')}>MLV - {mlvDataCount}</span>
                                    </div>
                                    <div className={(tableId == TRACER_JOB_BOARD_INDEED) ? 'liveadsTabCon active' : 'liveadsTabCon'}>
                                        <span onClick={() => setTableFilter(TRACER_JOB_BOARD_INDEED, 'INDEED')} style={{ padding: "6px 18px" }}>INDEED - {indeedDataCount}</span>
                                    </div>
                                    <div className={(tableId == TRACER_JOB_BOARD_HIPO) ? 'liveadsTabCon active' : 'liveadsTabCon'}>
                                        <span onClick={() => setTableFilter(TRACER_JOB_BOARD_HIPO, 'HIPO')}>HIPO -  {hipoDataCount}</span>
                                    </div>
                                    <div className={(tableId == TRACER_JOB_BOARD_EJOBS) ? 'liveadsTabCon active' : 'liveadsTabCon'}>
                                        <span onClick={() => setTableFilter(TRACER_JOB_BOARD_EJOBS, 'EJOBS')}>EJOBS - {ejobsDataCount}</span>
                                    </div>
                                    <div className={(tableId == TRACER_JOB_BOARD_DK_WEBSITE) ? 'liveadsTabCon active' : 'liveadsTabCon'}>
                                        <span onClick={() => setTableFilter(TRACER_JOB_BOARD_DK_WEBSITE, 'DK WEBSITE')} style={{ padding: "6px 5px" }}>DKWEBSITE - {dkWebDataCount}</span>
                                    </div>
                                    <div className={(tableId == TRACER_JOB_BOARD_LINKEDIN) ? 'liveadsTabCon active' : 'liveadsTabCon'}>
                                        <span onClick={() => setTableFilter(TRACER_JOB_BOARD_LINKEDIN, 'LINKEDIN')} style={{ padding: "6px 22px" }}>LINKEDIN - {linkedinDataCount}</span>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                        <div className="col-md-12 dk-adsTrackerPageBg">
                            <Tab.Content>
                                <LiveAdsTable tableTitle={tableName} tableId={tableId} />
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default LiveAds;