import React from 'react'
import  './referrals.scss'
import { Link } from 'react-router-dom'
import {Nav,Tab} from 'react-bootstrap'
import { FaSearch , FaBuilding  , FaCalendarAlt, 
    FaCheck, FaUser, FaBriefcase, FaEuroSign, FaGlobeEurope,
     FaLanguage, FaIdCard , FaInfo  } from 'react-icons/fa'
import TeleperformanceLogo from '../../../../assets/images/teleperformance.png'     


const Referrals = () => {
  return (
    <>
      <div className="dkg-referrals-mainCon">
        <div className="dkg-referrals-content">
            <Tab.Container id="left-tabs-example" defaultActiveKey="">
                <div className='dkg-referrals-mainRow'>
                    <div  className='col-md-12 col-12 dkg-referrals-mainCol'>
                        <div className="dkg-refreral-srchinputCon">
                            <div className='dkg-refreral-srchinput'>
                                <input type="text" className='dkg-search-input' placeholder='Search…' />
                                <span className='dkg-search-icon'>
                                    <FaSearch />
                                </span>
                            </div>
                        </div>
                        <Nav variant="pills" className="flex-row dkg-referrals-navpills">
                        <Nav.Item className="dkg-referrals-navitem">
                               <Nav.Link eventKey="second" className="dkg-referrals-navlink">
                                    <div className="dkg-referal-navicon"><FaCheck /></div> 
                                    Status 
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="dkg-referrals-navitem">
                                <Nav.Link eventKey="first" className="dkg-referrals-navlink">
                                    <div className="dkg-referal-navicon"><FaCalendarAlt  /></div> 
                                    Posted
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="dkg-referrals-navitem">
                                <Nav.Link eventKey="three" className="dkg-referrals-navlink">
                                    <div className="dkg-referal-navicon"><FaBuilding /></div> 
                                    Company 
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="dkg-referrals-navitem">
                                <Nav.Link eventKey="four" className="dkg-referrals-navlink">
                                    <div className="dkg-referal-navicon"><FaBuilding /></div> 
                                    Industry 
                                    </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="dkg-referrals-navitem">
                              <Nav.Link eventKey="five" className="dkg-referrals-navlink">
                                <div className="dkg-referal-navicon"><FaBriefcase /></div> 
                                 Job Title
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="dkg-referrals-navitem">
                               <Nav.Link eventKey="six" className="dkg-referrals-navlink">
                                  <div className="dkg-referal-navicon"><FaBriefcase /></div> 
                                    Project 
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="dkg-referrals-navitem">
                                <Nav.Link eventKey="seven" className="dkg-referrals-navlink">
                                   <div className="dkg-referal-navicon"><FaBriefcase /></div> 
                                   Role Type 
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="dkg-referrals-navitem">
                                <Nav.Link eventKey="eight" className="dkg-referrals-navlink">
                                    <div className="dkg-referal-navicon"><FaLanguage /></div> 
                                    Language  
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="dkg-referrals-navitem">
                                <Nav.Link eventKey="nine" className="dkg-referrals-navlink">
                                    <div className="dkg-referal-navicon"><FaEuroSign /></div> 
                                    Bonus  
                                    </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="dkg-referrals-navitem">
                                <Nav.Link eventKey="ten" className="dkg-referrals-navlink">
                                    <div className="dkg-referal-navicon"><FaIdCard /></div> 
                                    Conditions
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="col-md-12 col-12 dkg-referrals-Cols">
                        <Tab.Content className="dkg-referrals-tabcontent">
                            <Tab.Pane eventKey="first" className="dkg-referrals-tabpane">
                            </Tab.Pane>
                            <Tab.Pane eventKey="second"  className="dkg-referrals-tabpane">
                                <div className="dkg-referal-tabpane-ul">
                                    <div className="dkg-referal-tabpane-li">
                                        <Link to="#" className="dkg-referal-link">Expired
                                         <span className='dkg-referal-nocounter'>35</span>
                                        </Link>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="three" className="dkg-referrals-tabpane">
                            </Tab.Pane>
                            <Tab.Pane eventKey="four"  className="dkg-referrals-tabpane">
                            </Tab.Pane>
                            <Tab.Pane eventKey="five" className="dkg-referrals-tabpane">
                            </Tab.Pane>
                            <Tab.Pane eventKey="six"  className="dkg-referrals-tabpane">
                            </Tab.Pane>
                            <Tab.Pane eventKey="seven" className="dkg-referrals-tabpane">
                            </Tab.Pane>
                            <Tab.Pane eventKey="eight"  className="dkg-referrals-tabpane">
                            </Tab.Pane>
                            <Tab.Pane eventKey="nine"  className="dkg-referrals-tabpane">
                            </Tab.Pane>
                            <Tab.Pane eventKey="ten"  className="dkg-referrals-tabpane">
                            </Tab.Pane>
                        </Tab.Content>
                        <div className="dkg-referrals-tableCon">
                            <table className="table dkg-referrals-table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Posted</th>
                                        <th>Company</th>
                                        <th>Job Title</th>
                                        <th>Language</th>
                                        <th>Bonus</th>
                                        <th>Payments</th>
                                        <th>Conditions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="dkg-referal-status-bg-cols" 
                                            style={{backgroundColor: "#03A786" , color: "#fff"}}>
                                                <div className="dkg-referal-status-title">Active</div>
                                            </div>
                                        </td>
                                        <td>
                                            30 min
                                        </td>
                                        <td>
                                            <div className="dkg-refreral-comapny-logoCon">
                                                <img  src={TeleperformanceLogo} className='dkg-refreral-comapny-logo' alt ="Company Logo"/>
                                            </div>
                                        </td>
                                        <td>
                                        <div className="dkg-referal-jobtitle-cols">
                                               <h5 className="dkg-referal-jobtitle">German Technical Support Role in Greece</h5>  
                                               <div className="dkg-referal-bottom-sec">
                                                  <ul className="dkg-referal-bottom-ul">
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-building"></i>
                                                        </span>
                                                        WH Greece
                                                    </li>
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-building"></i>
                                                        </span>
                                                        Elastic Search
                                                    </li>
                                                  </ul>
                                                  <ul className="dkg-referal-bottom-ul">
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-user"></i>
                                                        </span>
                                                        Sales Advisor
                                                    </li>
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-language"></i>
                                                        </span>
                                                        French
                                                    </li>
                                                  </ul>
                                               </div>
                                            </div>
                                        </td>
                                        <td>
                                            12345
                                        </td>
                                        <td>
                                            3 Months
                                        </td>
                                        <td>
                                        3 Installment
                                        </td>
                                        <td>
                                           <div className="dkg-buy-btn-con text-center w-100">
                                                <Link to="#" className="btn dkg-buy-btn">
                                                View
                                                <span className="dkg-invoice-icon">
                                                    <FaInfo  />
                                                </span>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-referal-status-bg-cols" 
                                            style={{backgroundColor: "#D58B5D" , color: "#fff"}}>
                                                <div className="dkg-referal-status-title">On Hold</div>
                                            </div>
                                        </td>
                                        <td>
                                            4 hours
                                        </td>
                                        <td>
                                            <div className="dkg-refreral-comapny-logoCon">
                                                <img  src={TeleperformanceLogo} className='dkg-refreral-comapny-logo' alt ="Company Logo"/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dkg-referal-jobtitle-cols">
                                               <h5 className="dkg-referal-jobtitle">German Technical Support Role in Greece</h5>  
                                               <div className="dkg-referal-bottom-sec">
                                                  <ul className="dkg-referal-bottom-ul">
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-building"></i>
                                                        </span>
                                                        WH Greece
                                                    </li>
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-building"></i>
                                                        </span>
                                                        Elastic Search
                                                    </li>
                                                  </ul>
                                                  <ul className="dkg-referal-bottom-ul">
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-user"></i>
                                                        </span>
                                                        Sales Advisor
                                                    </li>
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-language"></i>
                                                        </span>
                                                        French
                                                    </li>
                                                  </ul>
                                               </div>
                                            </div>
                                        </td>
                                        <td>
                                            12345
                                        </td>
                                        <td>
                                            3 Months
                                        </td>
                                        <td>
                                        3 Installment
                                        </td>
                                        <td>
                                           <div className="dkg-buy-btn-con text-center w-100">
                                                <Link to="#" className="btn dkg-buy-btn">
                                                View
                                                <span className="dkg-invoice-icon">
                                                    <FaInfo  />
                                                </span>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-referal-status-bg-cols" 
                                            style={{backgroundColor: "#D58B5D" , color: "#fff"}}>
                                                <div className="dkg-referal-status-title">On Hold</div>
                                            </div>
                                        </td>
                                        <td>
                                            4 hours
                                        </td>
                                        <td>
                                            <div className="dkg-refreral-comapny-logoCon">
                                                <img  src={TeleperformanceLogo} className='dkg-refreral-comapny-logo' alt ="Company Logo"/>
                                            </div>
                                        </td>
                                        <td>
                                        <div className="dkg-referal-jobtitle-cols">
                                               <h5 className="dkg-referal-jobtitle">German Technical Support Role in Greece</h5>  
                                               <div className="dkg-referal-bottom-sec">
                                                  <ul className="dkg-referal-bottom-ul">
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-building"></i>
                                                        </span>
                                                        WH Greece
                                                    </li>
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-building"></i>
                                                        </span>
                                                        Elastic Search
                                                    </li>
                                                  </ul>
                                                  <ul className="dkg-referal-bottom-ul">
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-user"></i>
                                                        </span>
                                                        Sales Advisor
                                                    </li>
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-language"></i>
                                                        </span>
                                                        French
                                                    </li>
                                                  </ul>
                                               </div>
                                            </div>
                                        </td>
                                        <td>
                                            12345
                                        </td>
                                        <td>
                                            3 Months
                                        </td>
                                        <td>
                                        3 Installment
                                        </td>
                                        <td>
                                           <div className="dkg-buy-btn-con text-center w-100">
                                                <Link to="#" className="btn dkg-buy-btn">
                                                View
                                                <span className="dkg-invoice-icon">
                                                    <FaInfo  />
                                                </span>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-referal-status-bg-cols" 
                                            style={{backgroundColor: "#CA464F" , color: "#fff"}}>
                                                <div className="dkg-referal-status-title">Closed</div>
                                            </div>
                                        </td>
                                        <td>
                                            Yesterday
                                        </td>
                                        <td>
                                            <div className="dkg-refreral-comapny-logoCon">
                                                <img  src={TeleperformanceLogo} className='dkg-refreral-comapny-logo' alt ="Company Logo"/>
                                            </div>
                                        </td>
                                        <td>
                                        <div className="dkg-referal-jobtitle-cols">
                                               <h5 className="dkg-referal-jobtitle">German Technical Support Role in Greece</h5>  
                                               <div className="dkg-referal-bottom-sec">
                                                  <ul className="dkg-referal-bottom-ul">
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-building"></i>
                                                        </span>
                                                        WH Greece
                                                    </li>
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-building"></i>
                                                        </span>
                                                        Elastic Search
                                                    </li>
                                                  </ul>
                                                  <ul className="dkg-referal-bottom-ul">
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-user"></i>
                                                        </span>
                                                        Sales Advisor
                                                    </li>
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-language"></i>
                                                        </span>
                                                        French
                                                    </li>
                                                  </ul>
                                               </div>
                                            </div>
                                        </td>
                                        <td>
                                            12345
                                        </td>
                                        <td>
                                            3 Months
                                        </td>
                                        <td>
                                        3 Installment
                                        </td>
                                        <td>
                                           <div className="dkg-buy-btn-con text-center w-100">
                                                <Link to="#" className="btn dkg-buy-btn">
                                                View
                                                <span className="dkg-invoice-icon">
                                                    <FaInfo  />
                                                </span>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dkg-referal-status-bg-cols" 
                                            style={{backgroundColor: "#CA464F" , color: "#fff"}}>
                                                <div className="dkg-referal-status-title">Closed</div>
                                            </div>
                                        </td>
                                        <td>
                                            5 days
                                        </td>
                                        <td>
                                            <div className="dkg-refreral-comapny-logoCon">
                                                <img  src={TeleperformanceLogo} className='dkg-refreral-comapny-logo' alt ="Company Logo"/>
                                            </div>
                                        </td>
                                        <td>
                                        <div className="dkg-referal-jobtitle-cols">
                                               <h5 className="dkg-referal-jobtitle">German Technical Support Role in Greece</h5>  
                                               <div className="dkg-referal-bottom-sec">
                                                  <ul className="dkg-referal-bottom-ul">
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-building"></i>
                                                        </span>
                                                        WH Greece
                                                    </li>
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-building"></i>
                                                        </span>
                                                        Elastic Search
                                                    </li>
                                                  </ul>
                                                  <ul className="dkg-referal-bottom-ul">
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-user"></i>
                                                        </span>
                                                        Sales Advisor
                                                    </li>
                                                    <li className="dkg-referal-bottom-li">
                                                        <span className="dkg-referal-li-icon">
                                                          <i className="far fa fa-language"></i>
                                                        </span>
                                                        French
                                                    </li>
                                                  </ul>
                                               </div>
                                            </div>
                                        </td>
                                        <td>
                                            12345
                                        </td>
                                        <td>
                                            3 Months
                                        </td>
                                        <td>
                                        3 Installment
                                        </td>
                                        <td>
                                           <div className="dkg-buy-btn-con text-center w-100">
                                                <Link to="#" className="btn dkg-buy-btn">
                                                View
                                                <span className="dkg-invoice-icon">
                                                    <FaInfo  />
                                                </span>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Tab.Container>
             
        </div>
      </div>
    </>
  )
}

export default Referrals