import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import "../responsiveMenu.scss";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import './mainmenusubmenuc.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const FaqSubmenu = (props) => {
    const classes = useStyles();
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];

    const [toggleMenu, setToggleMenu] = useState(false);
    useEffect(() => {
        setToggleMenu(false)
    }, [pathname])

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setToggleMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);

    return (
        <React.Fragment>
            <div className="dk-subMenuMain dkg-mainModuleSubmenu res-sub-menu" ref={ref}>
                <IconButton aria-label="delete" className="res-sub-btn" onClick={() => setToggleMenu(!toggleMenu)}>
                    <i className="fas fa-bars"></i>
                </IconButton>
                <Route>
                    <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
                        <li><Link to="/faq/dk-global" className={(segment2 == 'dk-global') ? 'active' : ''}>DK Global</Link></li>
                        <li><Link to="/faq/kpi-csf" className={`${(segment2 == 'kpi-csf') ? 'active' : ''}`}>KPI/CSF</Link></li>
                        <li><Link to="/faq/clients" className={`${(segment2 == 'clients') ? 'active' : ''}`}>Clients</Link></li>
                        <li><Link to="/faq/demands" className={`${(segment2 == 'demands') ? 'active' : ''}`}>Demands</Link></li>
                        <li><Link to="/faq/contracts" className={`${(segment2 == 'contracts') ? 'active' : ''}`}>Contracts</Link></li>
                        <li><Link to="/faq/payments" className={`${(segment2 == 'payments') ? 'active' : ''}`}>Payments</Link></li>
                        <li><Link to="/faq/job-training" className={`${(segment2 == 'job-training') ? 'active' : ''}`}>Job Training</Link></li>
                        <li><Link to="/faq/technical-bugs" className={`${(segment2 == 'technical-bugs') ? 'active' : ''}`}>Technical Bugs</Link></li>
                        <li><Link to="/faq/support" className={`${(segment2 == 'support') ? 'active' : ''}`}>Support</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default FaqSubmenu;
