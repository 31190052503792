import React, { useState, useEffect } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import Dropdown from 'react-bootstrap/Dropdown'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    < a
        href="/#"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a >
));

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');
        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className=""
                    placeholder="Search Text..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toString().toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);

function SearchDropDownFilter(props) {

    //const [categoryList, setCategoryList] = useState(props.categoryList);
    const [data, setData] = useState('Select');

    useEffect(() => {
        if (props.isRefresh) {
            setData('Select')
        }
    }, [props.isRefresh])

    return (
        <>
            <div className="dk-jobssearchFilter">
                <Dropdown onSelect={function (evt) {
                    props.catList(evt)// get value in parent component
                    setData(evt)
                }}>
                    <Dropdown.Toggle id="dropdown-custom-components" as={CustomToggle} className="dkcreatetoggle">
                        {data}
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                        {props.categoryList.length > 0 ?
                            props.categoryList.map((data, index) => (
                                <Dropdown.Item eventKey={data.value} key={index + 1}>{data.value} </Dropdown.Item>
                            )) : null
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default SearchDropDownFilter = React.memo(SearchDropDownFilter);