import React, { useState, useCallback, useRef, useEffect } from "react";
import "./dkg-myprofile.scss";
import { useDispatch, useSelector } from "react-redux";
import CVComponent from "../../../../assets/images/cv-blue.png";
import MyDoccomponent from "../../../../assets/images/my-documents.png";
import Updatedcomponent from "../../../../assets/images/updated.png";
import ReferalIcon from "../../../../assets/images/referal-icon.png";
import { candidateProfileDetails } from "../../../../slice/candidates/list/listSlice";
import { getMyDocumentData } from "../../../../slice/cmsSlice";
import { candidateDoc } from "../../../../slice/candidates/profile/profileSlice";

const MyProfile = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const [documentData, setDocumentData] = useState([]);

  const { mydocumentsList, isLoading } = useSelector((state) => state.cmsdata);
  const { isProfileLoading, profileDetails } = useSelector(
    (state) => state.candidates
  );

  useEffect(() => {
    if (!isLoading && mydocumentsList.length > 0) {
      setDocumentData(mydocumentsList);
    }
  }, [isLoading]);

  const [fullname, setFullName] = useState("");
  const [phone1, setPhone1] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [systemEmail, setSystemEmail] = useState("");
  const [nativelang, setNativeLang] = useState("");
  const [fluentlang, setFluentLang] = useState("");

  const [phone2, setPhone2] = useState("");
  const [email2, setEmail2] = useState("");
  // const [homeAddress, setHomeAddress] = useState("");
  // const [Ccity, setCcity] = useState("");
  // const [Ccountry, setCcountry] = useState("");
  // const [state, setState] = useState("");
  // const [postal, setPostal] = useState("");
  // const [dobdate, setDobdate] = useState("");
  // const [dobmonth, setDobmonth] = useState("");
  // const [dobyear, setDobyear] = useState("");
  const [cvText, setCVText] = useState("");

  useEffect(() => {
    const fetchCandidateRecods = async () => {
      await dispatch(candidateProfileDetails({ id: userInfo.canId }));
      await dispatch(getMyDocumentData({ canId: userInfo.canId }));
      const result = await dispatch(candidateDoc({ canId: userInfo.canId }));
      if (!isEmpty(result.payload.result)) {
        setCVText("Uploaded");
      } else {
        setCVText("Not Uploaded");
      }
    };
    fetchCandidateRecods();
  }, []);

  useEffect(() => {
    if (!isProfileLoading && profileDetails) {
      setFullName(profileDetails.first_name + " " + profileDetails.last_name);
      setPhone1(profileDetails.phone1);
      setPrimaryEmail(profileDetails.email1);
      setSystemEmail(profileDetails.system_email);
      setNativeLang(profileDetails.native1);
      setFluentLang(profileDetails.fluent1);

      setPhone2(profileDetails.phone2);
      setEmail2(profileDetails.email2);
      //setHomeAddress(profileDetails.address);
      //setCcity(profileDetails.c_city);
      //setState(profileDetails.state);
      //setCcountry(profileDetails.c_country);
      //setPostal(profileDetails.postal);
      //setDobdate(profileDetails.dob_date);
      //setDobmonth(profileDetails.dob_month);
      //setDobyear(profileDetails.dob_year);
    }
  }, [isProfileLoading]);

  function isEmpty(obj) {
    for (var i in obj) {
      return false;
    }
    return true;
  }

  return (
    <>
      <div className="dk-myacc-myprofilesummMain">
        <div className="dkg-myacc-profile-summ-234">
          <div className="dkg-myacc-profile-Row">
            <div className="dkg-logins-dashboard-boxsCon ">
              <div className="dkg-logins-LeftCon">
                <div className="dkg-loginsdetalis-boxRow">
                  <div
                    className="dk-loginsdetalisCols"
                    style={{ marginLeft: "0" }}
                  >
                    <div className="title">MY ACCOUNT INFO</div>
                    <div className="dk-itTeamBox">
                      <ul className="dkg-acc-status-ul">
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Status :
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            {userInfo.accountStatus === 1
                              ? "Active"
                              : "Inactive"}
                          </span>
                        </li>
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Account ID :
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            {userInfo.canId}
                          </span>
                        </li>
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Account Type :
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            Jobseeker
                          </span>
                        </li>
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Subscription :
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            Basic [Free]
                          </span>
                        </li>
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Account Created :
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            01/01/2024
                          </span>
                        </li>
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Account Accessed :
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            {userInfo.lastVisit}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="dk-loginsdetalisCols">
                    <div className="title">MY CONTACT INFO</div>
                    <div className="dk-itTeamBox">
                      <ul className="dkg-acc-status-ul">
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Full Name :
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            {fullname}
                          </span>
                        </li>
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Phone No :
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            {phone1}
                          </span>
                        </li>
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Backup Ph. :
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            {phone2}
                          </span>
                        </li>
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Main Email :
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            {primaryEmail}
                          </span>
                        </li>
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Backup Email :
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            {email2}
                          </span>
                        </li>
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Account Email :{" "}
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            {systemEmail}
                          </span>
                        </li>
                        {/* <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            My Contact Days :
                          </span>
                          <span
                            className="dkg-acc-status-righttitle"

                          >
                            Not Filled
                          </span>
                        </li>
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            My Contact Times :
                          </span>
                          <span
                            className="dkg-acc-status-righttitle"

                          >
                            Not Filled
                          </span>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dkg-logins-RightCon">
                <div className="dkg-loginsdetalis-boxRow">
                  <div className="dk-loginsdetalisCols">
                    <div className="title">MY PROFILE INFO</div>
                    <div className="dk-itTeamBox">
                      <ul className="dkg-acc-status-ul">
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Country :
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            {userInfo.c_country != ""
                              ? userInfo.c_country
                              : "-"}
                          </span>
                        </li>
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            City/Town :
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            {userInfo.c_city != "" ? userInfo.c_city : "-"}
                          </span>
                        </li>
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Nationality :
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            {userInfo.nationality != ""
                              ? userInfo.nationality
                              : "-"}
                          </span>
                        </li>
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Visa Status :{" "}
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            {userInfo.visaStatus != ""
                              ? userInfo.visaStatus
                              : "-"}
                          </span>
                        </li>
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Native Language :
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            {nativelang}
                          </span>
                        </li>
                        <li className="dkg-acc-status-il">
                          <span className="dkg-acc-status-lefttitle">
                            Fluent Language :
                          </span>
                          <span className="dkg-acc-status-righttitle">
                            {fluentlang}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="dk-loginsdetalisCols"
                    style={{ marginRight: "0" }}
                  >
                    <div className="title">MY REFERRALS</div>
                    <div className="dk-itTeamBox">
                      <div className="dkg-unread-body-cols">
                        <div className="dkg-profile-cvicon-con">
                          <img
                            src={ReferalIcon}
                            className="dkg-profile-cvicon"
                            alt="Referal Icon"
                          />
                        </div>
                        <div
                          className="dkg-unread-blockNo"
                          style={{ fontSize: "25px" }}
                        >
                          0
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dkg-logins-dashboard-boxsCon ">
              <div className="dkg-logins-LeftCon">
                <div className="dkg-loginsdetalis-boxRow">
                  <div
                    className="dk-loginsdetalisCols"
                    style={{ marginLeft: "0" }}
                  >
                    <div className="title">MY DOCUMENTS</div>
                    <div className="dk-itTeamBox">
                      <div className="dkg-unread-body-cols">
                        <div className="dkg-profile-cvicon-con">
                          <img
                            src={MyDoccomponent}
                            className="dkg-profile-cvicon"
                            alt="CV"
                          />
                        </div>
                        <div
                          className="dkg-unread-blockNo"
                          style={{ fontSize: "25px" }}
                        >
                          {documentData.length}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dk-loginsdetalisCols">
                    <div className="title">MY RESUME</div>
                    <div className="dk-itTeamBox">
                      <div className="dkg-unread-body-cols">
                        <div className="dkg-profile-cvicon-con">
                          <img
                            src={CVComponent}
                            className="dkg-profile-cvicon"
                            alt="CV"
                          />
                        </div>
                        {cvText === "Uploaded" ? (
                          <>
                            <div className="dkg-unread-blockNo">Uploaded</div>
                          </>
                        ) : (
                          <>
                            <div
                              className="dkg-unread-blockNo"
                              style={{ color: "red" }}
                            >
                              Not Uploaded
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dkg-logins-RightCon">
                <div className="dkg-loginsdetalis-boxRow">
                  <div className="dk-loginsdetalisCols">
                    <div className="title">MY DKG RESUME</div>
                    <div className="dk-itTeamBox">
                      <div className="dkg-unread-body-cols">
                        <div className="dkg-profile-cvicon-con">
                          <img
                            src={CVComponent}
                            className="dkg-profile-cvicon"
                            alt="CV"
                          />
                        </div>
                        <div
                          className="dkg-unread-blockNo"
                          style={{ color: "red" }}
                        >
                          Not Created
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="dk-loginsdetalisCols"
                    style={{ marginRight: "0" }}
                  >
                    <div className="title">MY DESIRED JOB</div>
                    <div className="dk-itTeamBox">
                      <div className="dkg-unread-body-cols">
                        <div className="dkg-profile-cvicon-con">
                          <img
                            src={Updatedcomponent}
                            className="dkg-profile-cvicon"
                            alt="CV"
                          />
                        </div>
                        <div
                          className="dkg-unread-blockNo"
                          style={{ color: "red" }}
                        >
                          Not Updated
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
