import React from 'react';
import { Link } from "react-router-dom";
import MarianaProjectLiveLeftPanel from "../../leftpanel/mariana/MarianaProjectLiveLeftPanel";
import GermanDetailLeftPanel from "../detailleftpanel/livedetailleftpanel/GermanDetailLeftPanel";
import { FaPlus } from 'react-icons/fa';
import '../../moduleprojects.scss';

const MarianProjectOne = () => {
    return (
        <>
            <div className="dk-moduleProjects-main">
                <div className="dk-moduleTraining-cols">
                    <MarianaProjectLiveLeftPanel />
                    <div className="dk-moduleTraining-rightPanel">
                        <div className="title">German</div>
                        <div className="dk-addNew-btn">
                            <Link to="#"><FaPlus /> Add New</Link>
                        </div>
                        <div className="dk-moduleTraining-rightCols">
                            <GermanDetailLeftPanel />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MarianProjectOne;
