import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../files.scss'
import AddFileModal from './FileModal';
import WordFilePreviewModal from './WordFilePreviewModal';

const DkgFiles = () => {
    const [showAddModalStatus, setAddFileModal] = useState(false);
    const showAddFileModal = () => {
        setAddFileModal(true)
    }
    const hideAddFileModal = () => {
        setAddFileModal(false)
    }

    const [showWordFilePreviewModalStatus, setWordFilePreviewModal] = useState(false);
    const showWordFilePreviewModal = () => {
        setWordFilePreviewModal(true)
    }
    const hideWordFilePreviewModal = () => {
        setWordFilePreviewModal(false)
    }
    const n = 15;
    return (
        <>
            {
                showAddModalStatus ?
                    <AddFileModal handleClose={hideAddFileModal} /> : null
            }

            {
                showWordFilePreviewModalStatus ?
                    <WordFilePreviewModal handleClose={hideWordFilePreviewModal} /> : null
            }
            <div className='container-fluid dk-cloud-files' style={{ marginTop: "125px" }}>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="dk-cloud-TopHead">
                            <div className="dk-cloudLeftTopHead">
                                <div className="dk-SrchInputBox mr-2">
                                    <div className="input-group">
                                        <input type="text" className="form-control text-left" placeholder="Search" />
                                    </div>
                                </div>
                            </div>
                            <div className='dk-cloudHeadtitleCon'>
                                <h2 className='dk-cloudHeadtitle'>DK GLOBAL</h2>
                            </div>
                            <div className="dk-cloudRightTopHead">
                                <div className='dk-categoryfilterCon'>
                                    <Link to="#" onClick={showAddFileModal} className="btn btn-dark btn-md m-0"><i className="fas fa-plus-circle"></i> Add File</Link>
                                </div>
                            </div>
                        </div>

                        <div className='dk-cloud-todoTableCon'>
                            <table className='table dk-cloud-todoTable'>
                                <thead>
                                    <tr>
                                        <th>SNo.</th>
                                        <th>Name of File</th>
                                        <th>Spec Type</th>
                                        <th>Company</th>
                                        <th>Projects</th>
                                        <th>Role Type</th>
                                        <th>Languages</th>
                                        <th>Word</th>
                                        <th>PDF</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[...Array(n)].map((elementInArray, index) => (
                                        <tr key={index}>
                                            <td></td>
                                            <td>DK Global Profile</td>
                                            <td>Full Spec</td>
                                            <td>DK Global Recruitment</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td><Link to="#" onClick={showWordFilePreviewModal}><img src="https://dvirde2b6zhne.cloudfront.net/docx-images.png" style={{ maxWidth: "26px" }} /></Link></td>
                                            <td><img src="https://dvirde2b6zhne.cloudfront.net/pdf-logo.png" style={{ maxWidth: "26px" }} /></td>
                                            <td>
                                                <Link to="#" id="47" className="btn btn-info btn-sm mr-2"><i className="fas fa-pencil-alt"></i></Link>
                                                <Link to="#" id="47" className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></Link>
                                            </td>
                                        </tr>
                                    )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DkgFiles;
