import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import Switch from "./Switch";
import {
  //defaultFaqs,
  updateFaqsValue,
} from "../../../../../../slice/jobSpec/jobSpecSlice";
//import { showError } from "../../../../../utils/messages/messageSlice";

const FaqsTab = ({ jobId, clientid }) => {
  const dispatch = useDispatch();
  const { isSuccess, isLoading, jobfaqssdata, faqssList, isFaqSuccess } =
    useSelector((state) => state.jobsSpec);

  const [selectedId, setSelectedId] = useState(null);
  const [faqlist, setFaqclist] = useState([]);

  useEffect(() => {
    if (isFaqSuccess && faqssList.length > 0) {
      setSelectedId(faqssList[0].companyId);
    }
  }, [isFaqSuccess]);

  useEffect(() => {
    if (!isLoading && jobfaqssdata.length > 0) {
      setFaqclist(jobfaqssdata);
    }
  }, [isLoading]);

  const handleChange = (id, companyname, companyidds) => {
    setSelectedId(id);
    dispatch(
      updateFaqsValue({
        id: id,
        key: "isShowInJob",
        value: 1,
        jobsId: jobId,
        clientId: companyidds,
        clientname: companyname,
        tableName: "jobs_relationship_faqs",
      })
    );
  };

  return (
    <>
      <div className="dkg-company-list-mainCon">
        <div className="dkg-company-list-tableCon table-responsive">
          <table className="dkg-company-list-table table table-borderded">
            <thead>
              <tr>
                <th>FAQs</th>
                <th>Linked</th>
              </tr>
            </thead>
            <tbody>
              {faqlist.map((details, index) => (
                <tr key={index + 1}>
                  <td>{details.name}</td>
                  <td>
                    <span className="dkg-clientinfo-checkbox">
                      <input
                        type="radio"
                        className="form-control"
                        name="clientinfo-checkbox-jobsfaq"
                        data-id={details.id}
                        data-companyname={details.name}
                        id={details.id}
                        checked={selectedId === details.id}
                        onChange={() =>
                          handleChange(
                            details.id,
                            details.name,
                            details.companyId
                          )
                        }
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default FaqsTab;
