import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData,DeleteData } from "../../services";

export const paymentSummary = createAsyncThunk(
    'paymenttask/summary-list',
    async ({roletype,taskById},thunkAPI) => {
        const res = await GetData(true, '/payment-tasks/summaryList/'+roletype+'/'+taskById)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getPaymentList = createAsyncThunk(
    'paymenttask/list',
    async ({roletype},thunkAPI) => {
        const res = await GetData(true, '/payment-tasks/role/'+roletype)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const getPaidPaymentList = createAsyncThunk(
    'paymenttask/paid/list',
    async ({roletype},thunkAPI) => {
        const res = await GetData(true, '/payment-tasks/paid/'+roletype)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const archivedData = createAsyncThunk(
    'paymenttask/list/archive',
    async ({roletype},thunkAPI) => {
        const res = await GetData(true, '/payment-tasks/archived/'+roletype)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const addNewTask = createAsyncThunk(
    'paymenttask/add-new',
    async ({ taskBy,taskFor,status,priority,invoiceNo,paymentType,paymentTo,paymentAmount,paymentNotes,dueTime,dueDate,paymentMonth, payType,attachment,isDeleted }, thunkAPI) => {
        const res = await PostData(true, '/payment-tasks', { taskBy,taskFor,status,priority,invoiceNo,paymentType,paymentTo,paymentAmount,paymentNotes,dueTime,dueDate,paymentMonth, payType,attachment,isDeleted })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const updateTask = createAsyncThunk(
    'paymenttask/update',
    async ({ id, taskBy,taskFor,status,priority,invoiceNo,paymentType,paymentTo,paymentAmount,paymentNotes,dueTime,dueDate,paymentMonth, payType, attachment }, thunkAPI) => {
        const res = await PutData(true, '/payment-tasks/' + id, { taskBy,taskFor,status,priority,invoiceNo,paymentType,paymentTo,paymentAmount,paymentNotes,dueTime,dueDate,paymentMonth, payType, attachment })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updatePaidTask = createAsyncThunk(
    'paymenttask/update/paid',
    async ({ id, taskBy,taskFor,status,priority,invoiceNo,paymentType,paymentTo,paymentAmount,paymentNotes,dueTime,dueDate,paymentMonth, payType, attachment }, thunkAPI) => {
        const res = await PutData(true, '/payment-tasks/' + id, { taskBy,taskFor,status,priority,invoiceNo,paymentType,paymentTo,paymentAmount,paymentNotes,dueTime,dueDate,paymentMonth, payType, attachment })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateByKey = createAsyncThunk(
    'paymenttask/update/key-value',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/payment-tasks/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateProgress = createAsyncThunk(
    'paymenttask/update/key-value/progress',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/payment-tasks/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const countAllTask = createAsyncThunk(
    'paymenttask/count/all',
    async ({ status }, thunkAPI) => {
        const res = await GetData(true, '/payment-tasks/count-all')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const deleteTask = createAsyncThunk(
    'paymenttask/count/delete',
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, '/payment-tasks/'+id )
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskMoveTO = createAsyncThunk(
    'paymenttask/count/task-move',
    async ({ id }, thunkAPI) => {
        const res = await PostData(true, '/payment-tasks/bulk-move', { id })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const moveRecordsToPaid = createAsyncThunk(
    'paymenttask/count/paymenttask/move-to-table',
    async ({ id, status }, thunkAPI) => {
        const res = await PostData(true, '/payment-tasks/move-to-table', { id, status })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const counts = createAsyncThunk(
    'paymenttask/count/by-progress',
    async ({ status }, thunkAPI) => {
        const res = await GetData(true, '/payment-tasks/count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const paymentCounts = createAsyncThunk(
    'paymenttask/count/payment',
    async ({roletype},thunkAPI) => {
        const res = await GetData(true, '/payment-tasks/payment-count/'+roletype)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const paidPaymentCounts = createAsyncThunk(
    'paymenttask/count/paidpayment',
    async ({roletype},thunkAPI) => {
        const res = await GetData(true, '/payment-tasks/paid-payment-count/'+roletype)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const archivePaymentCounts = createAsyncThunk(
    'paymenttask/count/archivepayment',
    async ({roletype},thunkAPI) => {
        const res = await GetData(true, '/payment-tasks/archive-count/'+roletype)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updatePaymentTaskCommentCount = createAsyncThunk(
    'paymenttask/commentlist/update-comment',
    async ({ id, value, commentAt }, thunkAPI) => {
        const res = await PutData(true, '/payment-tasks/update-comment/' + id, { value, commentAt })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskTypeFetch = createAsyncThunk(
    'paymenttask/type/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskPayMonthFetch = createAsyncThunk(
    'paymenttask/payMonth/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


export const taskPayTypeFetch = createAsyncThunk(
    'paymenttask/payType/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskSummaryList = createAsyncThunk(
    'paymenttask/pipeline/summary-list',
    async (thunkAPI) => {
        const res = await GetData(true, '/payment-tasks/summary')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskDetails = createAsyncThunk(
    'paymenttask/task/details',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/payment-tasks/detail/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const listSlice = createSlice({
    name: 'paymenttasksList',
    initialState: {
        paymentTaskList: [],
        paidPaymentTaskList: [],
        taskDataSummaryList: [],
        taskTypeList: [],
        taskPaymentMonthList: [],
        taskPayTypeList: [],
        countsProgress: [],
        paymentDataCount: [],
        paidPaymentDataCount: [],
        archiveDataCount: [],
        paymentListSummary: [],
        tasksDetails: {},
        countAll: 0,
        isLoading: false,
        isDetailLoading: false,
        isTaskInsert: false,
        isTaskUpdate: false,
        isTaskDelete: false,
        isUpdate: false,
        iscommentCountUpdate: false,
        isError: false,
        isSuccess: false,
        isDataMoveToPayment: false,
        isTaskInserted: false,
        isMoved: false,
        isArchive: false,
        message: ''
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isDetailLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.issSuccess = false;
            state.isDataMoveToPayment = false;
            state.isTaskInserted = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },
        clearTaskState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.isTaskInserted = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },
        clearDetails: (state) => {
            state.tasksDetails = {}
        }
    },
    extraReducers: (builder) => {

        builder.addCase(
            paymentSummary.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            paymentSummary.fulfilled, (state, { payload }) => {
                state.paymentListSummary = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            paymentSummary.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            getPaidPaymentList.pending, (state) => {
                state.isLoading = true;
                state.isUpdate = false;
                state.isDataMoveToPayment = false;
            });
        builder.addCase(
            getPaidPaymentList.fulfilled, (state, { payload }) => {
                state.paidPaymentTaskList = payload.result
                state.isLoading = false;
                state.isUpdate = true;
                state.isDataMoveToPayment = true;
            });
        builder.addCase(
            getPaidPaymentList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            getPaymentList.pending, (state) => {
                state.isLoading = true;
                state.issSuccess = false;
            });
        builder.addCase(
            getPaymentList.fulfilled, (state, { payload }) => {
                state.paymentTaskList = payload.result
                state.isLoading = false;
                state.issSuccess = true;
            });
        builder.addCase(
            getPaymentList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            archivedData.pending, (state) => {
                state.isLoading = true;
                state.isArchive = false;
            });
        builder.addCase(
            archivedData.fulfilled, (state, { payload }) => {
                state.paymentTaskList = payload.result
                state.isArchive = true;
                state.isLoading = false;
            });
        builder.addCase(
            archivedData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addNewTask.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            addNewTask.fulfilled, (state, { payload }) => {
                state.paymentTaskList.unshift(payload.result)
                state.isLoading = false;
                state.isSuccess = true;
                state.isTaskInserted = true;
            });
        builder.addCase(
            addNewTask.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updatePaidTask.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            updatePaidTask.fulfilled, (state, { payload }) => {
                let mainArray = state.paidPaymentTaskList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result[0].id) {
                        return (payload.result[0])
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.isLoading = false;
                state.paidPaymentTaskList = finalResult;
            });
        builder.addCase(
            updatePaidTask.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            updateTask.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            updateTask.fulfilled, (state, { payload }) => {
                let mainArray = state.paymentTaskList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result[0].id) {
                        return (payload.result[0])
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.isLoading = false;
                state.paymentTaskList = finalResult;
            });
        builder.addCase(
            updateTask.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            updateByKey.pending, (state) => {
                state.isTaskUpdate = false
            });
        builder.addCase(
            updateByKey.fulfilled, (state, { payload }) => {
                let mainArray = state.paymentTaskList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isTaskUpdate = true
                state.paymentTaskList = finalResult;
                state.message = payload.message
            });
        builder.addCase(
            updateByKey.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateProgress.pending, (state) => {
            });
        builder.addCase(
            updateProgress.fulfilled, (state, action) => {
                state.isUpdate = true
                state.isMoved = true
                state.paymentTaskList = state.paymentTaskList.filter((item) => item.id !== action.meta.arg.id)
            });
        builder.addCase(
            updateProgress.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            countAllTask.pending, (state) => {
            });
        builder.addCase(
            countAllTask.fulfilled, (state, { payload }) => {
                state.countAll = payload.result
            });
        builder.addCase(
            countAllTask.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            counts.pending, (state) => {
            });
        builder.addCase(
            counts.fulfilled, (state, { payload }) => {
                state.countsProgress = payload.result
            });
        builder.addCase(
            counts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            archivePaymentCounts.pending, (state) => {

            });
        builder.addCase(
            archivePaymentCounts.fulfilled, (state, { payload }) => {
                state.archiveDataCount = payload.result
            });
        builder.addCase(
            archivePaymentCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            paidPaymentCounts.pending, (state) => {

            });
        builder.addCase(
            paidPaymentCounts.fulfilled, (state, { payload }) => {
                state.paidPaymentDataCount = payload.result
                //state.isArchive = true;
            });
        builder.addCase(
            paidPaymentCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            paymentCounts.pending, (state) => {

            });
        builder.addCase(
            paymentCounts.fulfilled, (state, { payload }) => {
                state.paymentDataCount = payload.result
                //state.isArchive = true;
            });
        builder.addCase(
            paymentCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        

        builder.addCase(
            deleteTask.pending, (state) => {
            });
        builder.addCase(
            deleteTask.fulfilled, (state, { payload }) => {
                state.isTaskDelete = true;
                state.isMoved = true;
            });
        builder.addCase(
            deleteTask.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            taskMoveTO.pending, (state) => {
            });
        builder.addCase(
            taskMoveTO.fulfilled, (state, { payload }) => {
                state.isTaskDelete = true;
                state.message = payload.message
                state.isMoved = true;
            });
        builder.addCase(
            taskMoveTO.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            moveRecordsToPaid.pending, (state) => {
            });
        builder.addCase(
            moveRecordsToPaid.fulfilled, (state, { payload }) => {
                state.isMoved = true;
                state.message = payload.message
            });
        builder.addCase(
            moveRecordsToPaid.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updatePaymentTaskCommentCount.pending, (state) => {
                state.iscommentCountUpdate = false
            });
        builder.addCase(
            updatePaymentTaskCommentCount.fulfilled, (state, action) => {
                let mainArray = state.paymentTaskList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === action.meta.arg.id) {
                        return ({ ...r, commentCount: action.meta.arg.value, commentAt: action.meta.arg.commentAt })
                    } else {
                        return r
                    }
                })
                state.paymentTaskList = finalResult

                let mainArrayPaid = state.paidPaymentTaskList;
                let finalResultPaid = mainArrayPaid.map((r) => {
                    if (r.id === action.meta.arg.id) {
                        return ({ ...r, commentCount: action.meta.arg.value, commentAt: action.meta.arg.commentAt })
                    } else {
                        return r
                    }
                })
                state.paidPaymentTaskList = finalResultPaid
                state.iscommentCountUpdate = true
            });
        builder.addCase(
            updatePaymentTaskCommentCount.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            taskTypeFetch.pending, (state) => {
            });
        builder.addCase(
            taskTypeFetch.fulfilled, (state, { payload }) => {
                state.taskTypeList = payload.result
            });
        builder.addCase(
            taskTypeFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            taskPayMonthFetch.pending, (state) => {
            });
        builder.addCase(
            taskPayMonthFetch.fulfilled, (state, { payload }) => {
                state.taskPaymentMonthList = payload.result
            });
        builder.addCase(
            taskPayMonthFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            taskPayTypeFetch.pending, (state) => {
            });
        builder.addCase(
            taskPayTypeFetch.fulfilled, (state, { payload }) => {
                state.taskPayTypeList = payload.result
            });
        builder.addCase(
            taskPayTypeFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            taskSummaryList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            taskSummaryList.fulfilled, (state, { payload }) => {
                state.taskDataSummaryList = payload.result;
                state.isLoading = false;
            });
        builder.addCase(
            taskSummaryList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            taskDetails.pending, (state) => {
                state.isDetailLoading = true
            });
        builder.addCase(
            taskDetails.fulfilled, (state, { payload }) => {
                state.isDetailLoading = false
                state.tasksDetails = payload.result;
            });
        builder.addCase(
            taskDetails.rejected, (state, action) => {
                state.isDetailLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
    }
});


export const { clearState, clearTaskState, clearDetails } = listSlice.actions;
export default listSlice.reducer;