import React, { useState } from 'react'; 
import { Link } from 'react-router-dom';
import  './emaillogs.scss'
import { FaEdit ,FaTrashAlt } from "react-icons/fa"; 
import IncomingEmailIcon from '../../../../../../../assets/images/incoming-mail.png'
import OutgoingEmailIcon from '../../../../../../../assets/images/mail-out.png'
import UserTo from "../../../../../../../assets/images/user-defult.jpg";
import { useSelector, useDispatch } from "react-redux";
import CustomDateFormat from "../../../../../../ui/dateFormats/CustomDateFormat";
import EmailContent from './modal/EmailContent';
import InternalEmailContent from './modal/InternalEmailContent';
import { DeleteData } from "../../../../../../../services";
import { getEmailLogs } from "../../../../../../../slice/candidates/list/listSlice";
import { showSuccess, showError, clearMessage } from "../../../../../../../slice/utils/message/messageSlice";
import { Modal , Tab , Nav } from 'react-bootstrap'

const EmailLogs = (props) => {
  const dispatch = useDispatch();
  const [isDeleteConfirmPopUp, setIsDeleteConfirmPopUp] = useState(false);
  const [deleteAttachmentId, setDeleteAttachmentId] = useState(0)
  const [deleteLogType, setDeleteLogType] = useState('log')

  const deleteAttachment = async () => {
    try {
      if (deleteAttachmentId == 0) return;
      const url = deleteLogType === 'log' ? '/candidates/profile/email-logs/' : deleteLogType === 'template' ? '/delete/logs/template/' : '/internal-mails/delete/mailbox/logs/'
      const deleteAttachment = await DeleteData(true, url + deleteAttachmentId)
      if (deleteAttachment.payload.status) {
        dispatch(getEmailLogs({ can_id: props.candidateId }));
        setDeleteAttachmentId(0)
        setIsDeleteConfirmPopUp(false)
        dispatch(showSuccess({ msg: 'Log deleted successfully' }))
      }
    } catch (error) {
      dispatch(showError({ msg: error.message }))
    }
  }

  const confirmDelete = (id, type = 'log') => {
    setDeleteAttachmentId(id)
    setDeleteLogType(type)
    setIsDeleteConfirmPopUp(true)
  }

  const statusColor = (status) => {
   if (status.is_incoming) {
    return {backgroundColor: "#316a67", color: "#fff"}
   }
   else if(status.status === 'opened'){
    return { backgroundColor: "#84f473", color: "#000" };
   }
   else {
    if (status.status === 'pending') {
      return {backgroundColor: "#f4d03f", color: "#000"}
    }
    return {backgroundColor: "#d58b5d", color: "#fff"}
   }
  }
  
  const { emailLogs, isLoadingEmailLogs } = useSelector(
    (state) => state.candidates
  );
  return (
    <>
      <div className='dkg-emaillogs-mainCon'>
        <div className='dkg-emaillogs-Header'>
            <div className='dkg-emaillogs-LeftCon'></div>
            <div className='dkg-emaillogs-centreCon'></div>
            <div className='dkg-emaillogs-rightCon'></div>
        </div>
        <Modal className="dk-addNewCompletionModal dk-deleteModalMain" centered show={isDeleteConfirmPopUp} onHide={() => {
          setIsDeleteConfirmPopUp(false)
          setDeleteAttachmentId(0)
        }}>
            <Modal.Body>
              <h4>Are you sure wants to delete this records ?</h4>
              <div className="d-flex justify-content-end">
                <button onClick={() => {
                  setDeleteAttachmentId(0)
                  setIsDeleteConfirmPopUp(false)
                }}>No</button>
                <button className="btnRed ml-3" onClick={() => deleteAttachment()}>Yes</button>
              </div>
            </Modal.Body>
          </Modal>
        <div className="dkg-emaillogs-tabmain-con">
          <Tab.Container id="left-tabs-example" defaultActiveKey="auto-template">
              <div className='row dkg-emaillogs-mainRow'>
                <div className='col-12 dkg-emaillogs-mainCol'>
                  <Nav variant="pills" className="flex-row dkg-emaillogs-navpills">
                    <Nav.Item className="dkg-emaillogs-navitem">
                      <Nav.Link eventKey="auto-template" className="dkg-emaillogs-navlink">Auto Template</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-emaillogs-navitem">
                      <Nav.Link eventKey="first" className="dkg-emaillogs-navlink">Internal Emails</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-emaillogs-navitem">
                      <Nav.Link eventKey="second" className="dkg-emaillogs-navlink">External Emails</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className='dkg-emaillogs-tabcontent'>
                    <Tab.Pane eventKey="auto-template" className='dkg-emaillogs-tabpane'>
                      <div className='dkg-emaillogs-tableCon table-responsive'>
                        <table className='table table-bordered dkg-emaillogs-table'>
                            <thead>
                              <tr>
                                  <th>Flow</th>
                                  <th>Date & Time</th>
                                  <th>Status</th>
                                  <th>Category</th>
                                  <th>Email Subject </th>
                                  <th>Sender</th>
                                  <th>Receiver</th>
                                  <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                emailLogs.template_email_logs.length > 0 ? 
                                  emailLogs.template_email_logs.map((log) => (
                                    <tr>
                                      <td>
                                        <div className='dk-out-incom-emailicon'>
                                          <div className='dk-out-emailicon'>
                                            <img src={log.is_incoming ? IncomingEmailIcon : OutgoingEmailIcon} alt='Email Incoming' className='dk-out-email-img' />
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <CustomDateFormat
                                          date={log.createdAt}
                                          format="DD MMM YY , h:mm A"
                                        />
                                      </td>
                                      <td>
                                        <div className='dkg-email-log-status' style={statusColor(log)}>{ log.status }</div>
                                      </td>
                                      <td>{ log.category }</td>
                                      <td>
                                      <span className='dkg-jobtitle-cols'>{ log.subject }</span>
                                      </td>
                                      <td>
                                        <div className='dkg-userimg-Con'>
                                        {/* process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                            item.profileImage */}
                                          <img src={
                                            log.is_incoming ? UserTo : process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/users/images/jobsAdmin.jpeg'
                                          } alt='User From' className='dkg-userimg' />
                                          <span className='dkg-user-email'>{ log.sender }</span>
                                        </div>
                                      </td>
                                      <td>
                                      <div className='dkg-userimg-Con'>
                                          <img src={log.is_incoming ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/users/images/jobsAdmin.jpeg' : UserTo } alt='User To' className='dkg-userimg' />
                                          <span className='dkg-user-email'>{ log.receiver }</span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='dkg-email-actions-cols'>
                                          <div className="dkg-email-action-icon delete-icon" onClick={() => confirmDelete(log.id, 'template')}>
                                            <FaTrashAlt />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                :
                                <tr align="center">
                                  <td colSpan="8">No data found</td>
                                </tr>
                              }
                            </tbody>
                        </table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second" className='dkg-emaillogs-tabpane'>
                      <div className='dkg-emaillogs-tableCon table-responsive'>
                        <table className='table table-bordered dkg-emaillogs-table'>
                            <thead>
                              <tr>
                                  <th>Flow</th>
                                  <th>Date & Time</th>
                                  <th>Status</th>
                                  <th>Category</th>
                                  <th>Email Subject </th>
                                  <th>Sender</th>
                                  <th>Receiver</th>
                                  <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                emailLogs.email_logs.length > 0 ? 
                                  emailLogs.email_logs.map((log) => (
                                    <tr>
                                      <td>
                                        <div className='dk-out-incom-emailicon'>
                                          <div className='dk-out-emailicon'>
                                            <img src={log.is_incoming ? IncomingEmailIcon : OutgoingEmailIcon} alt='Email Incoming' className='dk-out-email-img' />
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <CustomDateFormat
                                          date={log.createdAt}
                                          format="DD MMM YY , h:mm A"
                                        />
                                      </td>
                                      <td>
                                        <div className='dkg-email-log-status' style={statusColor(log)}>{ log.status }</div>
                                      </td>
                                      <td>Welcome</td>
                                      <td>
                                      <EmailContent details={log}/>
                                      </td>
                                      <td>
                                        <div className='dkg-userimg-Con'>
                                        {/* process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                            item.profileImage */}
                                          <img src={
                                            log.is_incoming ? UserTo : process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/users/images/jobsAdmin.jpeg'
                                          } alt='User From' className='dkg-userimg' />
                                          <span className='dkg-user-email'>{ log.sender }</span>
                                        </div>
                                      </td>
                                      <td>
                                      <div className='dkg-userimg-Con'>
                                          <img src={log.is_incoming ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/users/images/jobsAdmin.jpeg' : UserTo } alt='User To' className='dkg-userimg' />
                                          <span className='dkg-user-email'>{ log.recipient }</span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='dkg-email-actions-cols'>
                                          <div className="dkg-email-action-icon delete-icon" onClick={() => confirmDelete(log.id)}>
                                            <FaTrashAlt />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                :
                                <tr align="center">
                                  <td colSpan="8">No data found</td>
                                </tr>
                              }
                            </tbody>
                        </table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="first" className='dkg-emaillogs-tabpane'>
                       <div className='dkg-emaillogs-tableCon table-responsive'>
                        <table className='table table-bordered dkg-emaillogs-table'>
                            <thead>
                              <tr>
                                  <th>Flow</th>
                                  <th>Date & Time</th>
                                  <th>Status</th>
                                  <th>Category</th>
                                  <th>Email Subject </th>
                                  <th>Sender</th>
                                  <th>Receiver</th>
                                  <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                emailLogs.internal_email_logs.length > 0 ? 
                                  emailLogs.internal_email_logs.map((log) => (
                                    <tr>
                                      <td>
                                        <div className='dk-out-incom-emailicon'>
                                          <div className='dk-out-emailicon'>
                                            <img src={log.is_incoming ? IncomingEmailIcon : OutgoingEmailIcon} alt='Email Incoming' className='dk-out-email-img' />
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <CustomDateFormat
                                          date={log.createdAt}
                                          format="DD MMM YY , h:mm A"
                                        />
                                      </td>
                                      <td>
                                        <div className='dkg-email-log-status' style={statusColor(log)}>{ log.status }</div>
                                      </td>
                                      <td>{ log.category.name }</td>
                                      <td>
                                      <InternalEmailContent details={log}/>
                                      </td>
                                      <td>
                                        <div className='dkg-userimg-Con'>
                                        {/* process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                            item.profileImage */}
                                          <img src={
                                            log.is_incoming ? UserTo : process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/users/images/jobsAdmin.jpeg'
                                          } alt='User From' className='dkg-userimg' />
                                          <span className='dkg-user-email'>{ log.sender }</span>
                                        </div>
                                      </td>
                                      <td>
                                      <div className='dkg-userimg-Con'>
                                          <img src={log.is_incoming ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + '/users/images/jobsAdmin.jpeg' : UserTo } alt='User To' className='dkg-userimg' />
                                          <span className='dkg-user-email'>{ log.receiver }</span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='dkg-email-actions-cols'>
                                          <div className="dkg-email-action-icon delete-icon" onClick={() => confirmDelete(log.id, 'internal-log')}>
                                            <FaTrashAlt />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                :
                                <tr align="center">
                                  <td colSpan="8">No data found</td>
                                </tr>
                              }
                            </tbody>
                        </table>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>                  
              </div>
          </Tab.Container>  
        </div>  
      </div>
    
    </>
  )
}

export default EmailLogs