import React from 'react';
import { Dropdown } from 'react-bootstrap';

const IconsNumberDropdown = (props) => {

    const getReactDomComponent = (dom) => {
        const internalInstance = dom[Object.keys(dom).find(key =>
            key.startsWith('__reactProps$')
        )];
        if (!internalInstance) return null;
        return internalInstance.value;
    }

    return (
        <React.Fragment>
            <Dropdown onSelect={function (evt, value) {
                props.updateFilter(evt, getReactDomComponent(value.target));
            }}>
                <Dropdown.Toggle id={props.id} className={props.className} variant="">
                    {(!props.isFilter) ?
                        <React.Fragment><i className="fas fa-plus pr-2"></i>  {props.defaultTxt}</React.Fragment>
                        :
                        <React.Fragment>
                            <div className='dropdown-selection d-flex align-items-center w-100'>
                                <i className="fas fa-plus pr-2"></i> {props.defaultTxt}
                            </div>
                            <i className="fas fa-times-circle crossBtn" data-key={props.filterFor} onClick={props.removeFilter}></i>
                        </React.Fragment>
                    }
                </Dropdown.Toggle>
                <Dropdown.Menu variant="" className='visible menu'>
                    <Dropdown.Item className='justify-content-between' eventKey="Blank" value={props.filterFor}>
                        <div className='d-flex justify-content-start align-items-center'>
                            <i className="fas fa-flag pr-2"></i>Blank</div>
                        {
                            props.list.filter(items => items.name === 1).map((item, index) => {
                                return <span className='count'>{item.count}</span>
                            })
                        }
                    </Dropdown.Item>
                    <Dropdown.Item className='justify-content-between' eventKey="Green" value={props.filterFor}>
                        <div className='d-flex justify-content-start align-items-center'>
                            <i className="fas fa-flag pr-2" style={{ color: "green" }}></i>Green</div>
                        {
                            props.list.filter(items => items.name === 2).map((item, index) => {
                                return <span className='count'>{item.count}</span>
                            })
                        }
                    </Dropdown.Item>
                    <Dropdown.Item className='justify-content-between' eventKey="Red" value={props.filterFor}>
                        <div className='d-flex justify-content-start align-items-center'>
                            <i className="fas fa-flag pr-2" style={{ color: "red" }}></i>Red</div>
                        {
                            props.list.filter(items => items.name === 3).map((item, index) => {
                                return <span className='count'>{item.count}</span>
                            })
                        }
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    )
}
export default IconsNumberDropdown;