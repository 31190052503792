import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const listColors = createAsyncThunk(
    'job_profile/colors',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/colors',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const listTable = createAsyncThunk(
    'job_profile/list',
    async ({ userId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/jobprofile/' + userId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);
export const reorderListTable = createAsyncThunk(
    'job_profile/listreorder',
    async ({ userId, boxId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/jobprofile/' + boxId + "/" + userId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const addHeadings = createAsyncThunk(
    'job_profile/add_heading',
    async ({ userId, name, bgColor, txtColor, boxId, parentId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/jobprofile/',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        userId,
                        name,
                        bgColor,
                        txtColor,
                        boxId,
                        parentId
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const updateHeadings = createAsyncThunk(
    'job_profile/update_heading',
    async ({ id, userId, name, bgColor, txtColor, boxId, parentId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/jobprofile/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        userId,
                        name,
                        bgColor,
                        txtColor,
                        boxId,
                        parentId
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const updateData = createAsyncThunk(
    'job_profile/update',
    async ({ id, key, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/jobprofile/heading/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        key, value
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const bulkdelete = createAsyncThunk(
    'job_profile/bulkdelte',
    async ({ ids }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/jobprofile/bulkdelete',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        ids
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const reorderProfile = createAsyncThunk(
    'job_profile/reorder',
    async ({ ids, boxId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/jobprofile/reorder/' + boxId,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        ids
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

const jobProfileSlice = createSlice({
    name: 'jobProfile',
    initialState: {
        colors: [],
        dataList: [],
        reorderDataList: [],
        isSuccess: false,
        isInsert: false,
        isUpdate: false,
        isdataUpdate: false,
        isDelete: false,
        isLoading: false,
        isError: false,
        isColorLoading: false,
        isColorSuccess: false,
        isReoder: false,
        msg: ''
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isdataUpdate = false;
            state.isDelete = false;
            state.isError = false;
            state.isColorLoading = false;
            state.isReoder = false;
            state.msg = '';
        }
    },
    extraReducers: {
        [listColors.pending]: (state) => {
            state.isColorLoading = true;
        },
        [listColors.fulfilled]: (state, { payload }) => {
            state.isColorLoading = false;
            state.isColorSuccess = true;
            state.colors = payload.result;
        },
        [listColors.rejected]: (state, { payload }) => {
            state.isColorLoading = false;
        },
        [listTable.pending]: (state) => {
            state.isLoading = true;
        },
        [listTable.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.dataList = payload.result;
        },
        [listTable.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        [reorderListTable.pending]: (state) => {
            state.isLoading = true;
            //  state.isReoder = false;
        },
        [reorderListTable.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.reorderDataList = payload.result;
        },
        [reorderListTable.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        [addHeadings.pending]: (state) => {
            state.isLoading = true;
        },
        [addHeadings.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = true;
            state.dataList.push(payload.result)
        },
        [addHeadings.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
        },
        [updateHeadings.pending]: (state) => {
            state.isLoading = true;
        },
        [updateHeadings.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.dataList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.dataList = finalResult
        },
        [updateHeadings.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
        },
        [updateData.pending]: (state) => {
            state.isLoading = true;
        },
        [updateData.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isdataUpdate = true;
            let mainArray = state.dataList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.dataList = finalResult;
            state.msg = payload.message
        },
        [updateData.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
        },
        [bulkdelete.pending]: (state) => {
        },
        [bulkdelete.fulfilled]: (state, action) => {
            state.dataList = state.dataList.filter((item) => item.id !== action.meta.arg.id);
            state.isDelete = true;
            state.msg = "Data deleted successfully";
        },
        [bulkdelete.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
        },
        [reorderProfile.pending]: (state) => {
            // state.isloading = true;

        },
        [reorderProfile.fulfilled]: (state, { payload }) => {
            state.isReoder = true;
            state.isloading = false;
            state.msg = payload.message;
        },
        [reorderProfile.rejected]: (state, { payload }) => {
            state.isloading = false;
            state.isError = payload.message;
            state.msg = payload.message;
        },
    }
})

export const { clearState } = jobProfileSlice.actions
export default jobProfileSlice.reducer;