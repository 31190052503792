import React from 'react';
import { Link } from "react-router-dom";

const DkGlobalLeftPanel = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <>
            <div className="dk-workPlanLeftPanel">
                <ul>
                    <li><Link to="/workplan/rules/dk-global" className={`${(segment3 === 'dk-global') ? 'active' : ''}`}>DK Global</Link></li>
                    <li><Link to="#/workplan/rules/employees" className={`${(segment3 === 'employees') ? 'active' : ''}`}>Employees</Link></li>
                    <li><Link to="#/workplan/rules/clients" className={`${(segment3 === 'clients') ? 'active' : ''}`}>Clients</Link></li>
                    <li><Link to="#/workplan/rules/candidates" className={`${(segment3 === 'candidates') ? 'active' : ''}`}>Candidates</Link></li>
                    <li><Link to="#/workplan/rules/suppliers" className={`${(segment3 === 'suppliers') ? 'active' : ''}`}>Suppliers</Link></li>
                    <li><Link to="#/workplan/rules/jobs" className={`${(segment3 === 'jobs') ? 'active' : ''}`}>Jobs</Link></li>
                    <li><Link to="#/workplan/rules/recruitment" className={`${(segment3 === 'recruitment') ? 'active' : ''}`}>Recruitment</Link></li>
                    <li><Link to="#/workplan/rules/ads" className={`${(segment3 === 'ads') ? 'active' : ''}`}>Ads</Link></li>
                    <li><Link to="#/workplan/rules/dkg-crm" className={`${(segment3 === 'dkg-crm') ? 'active' : ''}`}>DKG CRM</Link></li>

                </ul>
            </div>
        </>
    )
}

export default DkGlobalLeftPanel
