import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Constants from "../../constants";

export const listTable = createAsyncThunk(
    'qualify/listing',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/qualify/list',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const qualifyCatList = createAsyncThunk(
    'qualify/qualifyCategory',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/admin/backend-values/' + Constants.SYSTEM_CONSTANTS.QUALIFY.CATEGORY_ID_QUALIFY,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const qualifyFrequencyList = createAsyncThunk(
    'qualify/frequencyListing',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/admin/backend-values/' + Constants.SYSTEM_CONSTANTS.QUALIFY.FREQUENCY_LIST,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const addQualify = createAsyncThunk(
    'qualify/add_qualify',
    async ({ companyId, categoryId, frequency, question, answer }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/qualify',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    },
                    body: JSON.stringify({
                        companyId, categoryId, frequency, question, answer
                    })
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteQualify = createAsyncThunk(
    'qualify/delete',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/qualify/' + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const fethById = createAsyncThunk(
    'qualify/detailsById',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/qualify/details/' + id,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateQualify = createAsyncThunk(
    'qualify/update_qualify',
    async ({ id, companyId, categoryId, frequency, question, answer }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/qualify/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    },
                    body: JSON.stringify({
                        id, companyId, categoryId, frequency, question, answer
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateCategory = createAsyncThunk(
    'qualify/changeCategory',
    async ({ id, catId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/qualify/updateCategory/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    },
                    body: JSON.stringify({
                        catId
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200 || response.status === 302) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateFrequency = createAsyncThunk(
    'qualify/changeFrequncy',
    async ({ id, value }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/qualify/updateFrequncy/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    },
                    body: JSON.stringify({
                        value
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const reorderQualify = createAsyncThunk(
    'qualify/reorder_qualify',
    async ({ ids }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/qualify/reorder',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token
                    },
                    body: JSON.stringify({
                        ids
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

const qualifySlice = createSlice({
    name: 'qualify',
    initialState: {
        dataList: [],
        qualifyCategoryList: [],
        frequencyDataList: [],
        detailData: [],
        isLoading: false,
        isDataLoading: false,
        isInsert: false,
        isError: false,
        isUpdate: false,
        isDelete: false,
        isSuccess: false,
        isReoder: false,
        errorMessage: '',
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isDataLoading = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
            state.isSuccess = false;
            state.isReoder = false;
            state.errorMessage = '';
            return state;
        }
    },
    extraReducers: {

        [listTable.pending]: (state) => {
            state.isDataLoading = true;
        },
        [listTable.fulfilled]: (state, { payload }) => {
            state.dataList = payload.list;
            state.isDataLoading = false;
        },
        [listTable.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isDataLoading = false;
        },
        [qualifyCatList.pending]: (state) => {
            state.isLoading = true;
        },
        [qualifyCatList.fulfilled]: (state, { payload }) => {
            state.qualifyCategoryList = payload.result;
            state.isLoading = false;
        },
        [qualifyCatList.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [qualifyFrequencyList.pending]: (state) => {
            state.isLoading = true;
        },
        [qualifyFrequencyList.fulfilled]: (state, { payload }) => {
            state.frequencyDataList = payload.result;
            state.isLoading = false;
        },
        [qualifyFrequencyList.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [addQualify.pending]: (state) => {
            state.isLoading = true;
        },
        [addQualify.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = true;
            state.dataList.push(payload.data)
            state.msg = payload.message.msgBody
        },
        [addQualify.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [deleteQualify.pending]: (state) => {
        },
        [deleteQualify.fulfilled]: (state, action) => {
            state.dataList = state.dataList.filter((item) => item._id !== action.meta.arg.id);
            state.isDelete = true;
        },
        [deleteQualify.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [fethById.pending]: (state) => {
        },
        [fethById.fulfilled]: (state, { payload }) => {
            state.detailData = payload.details;
        },
        [fethById.rejected]: (state, { payload }) => {
        },
        [updateQualify.pending]: (state) => {
            state.isLoading = true;
        },
        [updateQualify.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.dataList;
            let finalResult = mainArray.map((r) => {
                if (r._id === payload.data._id) {
                    return (payload.data)
                } else {
                    return r
                }
            })
            state.dataList = finalResult
            state.msg = payload.message.msgBody
        },
        [updateQualify.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [updateCategory.pending]: (state) => {
            state.isLoading = true;
        },
        [updateCategory.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.dataList;
            let finalResult = mainArray.map((r) => {
                if (r._id === payload.data._id) {
                    return (payload.data)
                } else {
                    return r
                }
            })
            state.dataList = finalResult
            state.msg = payload.message.msgBody
        },
        [updateCategory.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
        },
        [updateFrequency.pending]: (state) => {
            state.isLoading = true;
        },
        [updateFrequency.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.dataList;
            let finalResult = mainArray.map((r) => {
                if (r._id === payload.data._id) {
                    return (payload.data)
                } else {
                    return r
                }
            })
            state.dataList = finalResult
            state.msg = payload.message.msgBody
        },
        [updateFrequency.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
        },
        [reorderQualify.pending]: (state) => {
            state.isloading = true;
        },
        [reorderQualify.fulfilled]: (state, { payload }) => {
            state.isloading = false;
            state.isReoder = true;
            state.dataList = payload.list;
            state.msg = "Reorder successfully.";
        },
        [reorderQualify.rejected]: (state, { payload }) => {
            state.isloading = false;
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
        },
    }
});

export const { clearState } = qualifySlice.actions;
export default qualifySlice.reducer;
