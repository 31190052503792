import React, { useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';
import { clearLoading } from "../../../slice/utils/loader/linearSlice";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function Linear() {
    const classes = useStyles();
    const { loading } = useSelector(state => state.progressBar);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(clearLoading())
    }, [dispatch])
    return (
        <div className={classes.root}>
            {
                loading ? <Backdrop className={classes.backdrop} open={true}><LinearProgress /></Backdrop> : null
            }
        </div>
    );
}