import React from 'react';
import { Modal, Dropdown } from 'react-bootstrap';
import LegalDatepicker from "./LegalDatepicker";

const EditModal = ({ onHide }) => {
    return (
        <>
            <Modal className="dk-yearsAddNewTask-modal dk-addNewDkgStaffModal right" show={true} onHide={onHide}>
                <Modal.Header closeButton className='dkg-ittax-editHeader'>
                    <Modal.Title>Edit Corporate Tax</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                    <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Status</label>
                                <label htmlFor="">
                                    <Dropdown className='dkg-return-status-dropdown'>
                                        <Dropdown.Toggle variant="" className='dkg-return-status-dropdown-toggle' style={{ backgroundColor: "#3a9469", color: "#fff" }}>
                                           Submitted
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='dkg-return-status-dropdown-Menu' style={{ borderRadius: "0", padding: "0" }}>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' style={{ backgroundColor: "#3a9469", color: "#fff" }} href="#">Submitted</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' style={{ backgroundColor: "#d58b5d", color: "#fff" }} href="#">Pending</Dropdown.Item>
                                            <Dropdown.Item className='dkg-return-status-dropdown-item' style={{ backgroundColor: "red", color: "#fff" }} href="#">Overdue</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Tax Year</label>
                                <label htmlFor="">
                                    <LegalDatepicker />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Tax Period</label>
                                <label htmlFor="">
                                    <LegalDatepicker />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">File Date</label>
                                <label htmlFor="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Status</label>
                                <label htmlFor="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Name of Return</label>
                                <label htmlFor="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="">Return Purpose</label>
                                <label htmlFor="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group justify-content-center mt-2'>
                                <button className="dkaddBtn">Update</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default EditModal;
