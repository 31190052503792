import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import "./documents-modal.scss";
import DatePicker from "./datepicker/DatePicker";
import { FaEdit, FaTimes } from "react-icons/fa";
//import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import AWS from "aws-sdk";
import SubmitButton from "../../../../../../ui/submitButton";
import { showError } from "../../../../../../../slice/utils/message/messageSlice";
import moment from "moment-timezone";
import {
  updateMydocument,
  clearState,
} from "../../../../../../../slice/cmsSlice";
import { useDispatch, useSelector } from "react-redux";

const EditModal = ({ addedDate, filename, fileUrl, rowid, catNm }) => {
  const dispatch = useDispatch();
  const { isUpdate, filesCategoryList, isLoading } = useSelector(
    (state) => state.cmsdata
  );

  const [show, setShow] = useState(false);
  const [attachment, setAttachment] = useState("");
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState("");
  const [filesUrl, setFileUrl] = useState(fileUrl);
  const [fileName, setFileName] = useState(filename);
  const { userInfo } = useSelector((state) => state.auth);
  const [categoryS, setCategoryD] = useState(catNm);

  const [selecteddate, setSelectedDate] = useState(addedDate);
  const [defaultDt, setDefaultDt] = useState(addedDate);
  const [categoryData, setCategoryData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setFileUrl(fileUrl);
    //setdocpreview(true);
  };

  useEffect(() => {
    if (!isLoading && filesCategoryList.length > 0) {
      setCategoryData(filesCategoryList);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isUpdate) {
      setLoading(false);
      setSuccess(false);
      //setShow(false);
    }
  }, [isUpdate]);

  const onSelectFile = async (e) => {
    setAttachment(e.target.files[0].name);
    setFileType(e.target.files[0].type);
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!fileName || !selecteddate) {
        dispatch(showError({ msg: "All fileds required" }));
      } else {
        setLoading(true);
        try {
          if (filesUrl == "") {
            let s3bucket = new AWS.S3({
              accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
              secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
              Bucket: process.env.REACT_APP_BUCKET_NAME,
            });

            let fileNewName = Date.now() + "-" + attachment;

            s3bucket.createBucket(function () {
              var params = {
                Bucket: process.env.REACT_APP_BUCKET_NAME,
                Key: fileNewName,
                Body: file,
                ContentType: fileType,
              };

              s3bucket.upload(params, function (err, data) {
                if (err) {
                  console.log(err);
                } else {
                  console.log(data.key);
                  dispatch(
                    updateMydocument({
                      id: rowid,
                      canId: userInfo.canId,
                      fileName: "/" + data.key,
                      onlyfileName: fileName,
                      fileType: fileType,
                      fileCategory: categoryS,
                      docDate: selecteddate,
                    })
                  );
                }
              });
            });
          } else {
            dispatch(
              updateMydocument({
                id: rowid,
                canId: userInfo.canId,
                onlyfileName: fileName,
                fileCategory: categoryS,
                docDate: selecteddate,
              })
            );
          }
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      setSuccess(false);
      setLoading(false);
      dispatch(showError({ msg: "Something went wrong! Try again." }));
    }
  };

  const selectedDate = (date) => {
    setSelectedDate(moment(date).format("DD MMM YYYY"));
  };
  const defaultBrowse = (e) => {
    e.preventDefault();
    setFileUrl("");
  };

  return (
    <>
      <Link to className="dkg-action-btn edit-btn mr-2" onClick={handleShow}>
        <FaEdit />
      </Link>
      <Modal show={show} onHide={handleClose} className="dk-notificationsModal">
        <Modal.Header closeButton className="dk-notificationsModalHeader">
          <Modal.Title>Edit Document</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-addnew-doc-modalbody">
          <div className="dk-warningMain">
            <form>
              <div className="row">
                <div className="col-12 col-md-12">
                  <div className="form-group">
                    <label className="dkg-noti-label">Date Added</label>
                    <div className="dkg-addnew-date-timeCon">
                      <DatePicker
                        selectedDate={selectedDate}
                        defaultDt={defaultDt}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="dkg-noti-label">File Category</label>
                    <div className="dkg-notification-task-drop12">
                      <Dropdown className="dkg-notification-task-dropdown">
                        <Dropdown.Toggle
                          variant=""
                          className="dkg-noti-task-dropdown-toggle"
                        >
                          {categoryS}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {categoryData.map((item, index) => (
                            <Dropdown.Item
                              key={index}
                              className="form-control"
                              onClick={() => setCategoryD(item.value)}
                            >
                              {item.value}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="dkg-noti-label">File Name</label>
                    <input
                      type="text"
                      placeholder=""
                      value={fileName}
                      className="form-control dkg-sub-form-control"
                      onChange={(e) => setFileName(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label className="dkg-noti-label">File Type</label>
                    {filesUrl == "" ? (
                      <input
                        type="file"
                        id="imageInputAPI"
                        onChange={onSelectFile}
                        className="form-control dkg-sub-form-control"
                      />
                    ) : (
                      <div className="form-group">
                        {/* <label className="dkg-noti-label"></label> */}
                        <div className="dkg-mydoc-file-name-con">
                          {/* <span
                            className="dkg-cross-icon"
                            onClick={(e) => setFileUrl("")}
                          >
                            <FaTimes style={{ cursor: "pointer" }} />
                          </span> */}
                          <Link
                            // to={
                            //   process.env.REACT_APP_INTERNAL_BASE_URL + filesUrl
                            // }
                            to="#"
                            className="dkg-mydoc-file-name"
                          >
                            {filesUrl.slice(1)}
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="form-group mt-4 ">
                    <div className="dk-notiAddnewBtn-Con">
                      {filesUrl !== "" ? (
                        <button
                          className="dk-notiAddnewBtn"
                          onClick={defaultBrowse}
                          style={{
                            background: "#e5dcdc",
                            padding: "8px 10px",
                            margin: "5px",
                            color: "red",
                          }}
                        >
                          Remove File
                        </button>
                      ) : (
                        ""
                      )}

                      <SubmitButton
                        txt="Update Info"
                        loading={loading}
                        success={success}
                        onClick={handleSubmit}
                        className="dk-notiAddnewBtn"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditModal;
