import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  clearState,
  // updateJob,
  // updateJobWithTextBox,
} from "../../../../../../slice/jobs/jobsSlice";

const Jobbenfits = (jobId) => {
  const dispatch = useDispatch();

  const { isDetailsLoading, jobDetails, isUpdate } = useSelector(
    (state) => state.jobs
  );
  const [status, setStatus] = useState("");
  const [taxCalculator, setTaxCalculator] = useState('');
  const [costOfLiving, setCostOfLiving] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setData(jobDetails);
      setTaxCalculator(jobDetails.taxCalculator);
      setCostOfLiving(jobDetails.costOfLiving);
      setStatus(jobDetails.status);
    }
  }, [isDetailsLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobDetails);
    }
  }, [isUpdate]);
  let ids = data.id;

  let gross_value = "";
  if (data.gross_title === null) {
    gross_value = "Monthly Gross Salary";
  } else {
    if (data.gross_title === "Monthly Gross Salary") {
      gross_value = "Annual Gross";
    } else {
      gross_value = "Monthly Gross Salary";
    }
  }

  const openUrl = (url) => {
    window.open(url, "_blank");
  }
  return (
    <>
      <div className="row">
        <div className="col-md-7 m-right-auto">
          
          <div className="dk-suMMRightCon dk-jobsuMMBottomSec mt-3 w-100">
            <div className="col-md-12 col-sm-12 dk-jobsuMMBottomBoxBg p-0">
              <div className="dk-BoxHeader">
                <h3>Job Benefits</h3>
              </div>
              <div className="dk-boxKpi">
                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    1
                  </label>
                  <span>{data.benefits_1}</span>
                </div>

                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    2
                  </label>
                  <span>{data.benefits_2}</span>
                </div>

                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    3
                  </label>
                  <span>{data.benefits_3}</span>
                </div>

                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    4
                  </label>
                  <span>{data.benefits_4}</span>
                </div>

                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    5
                  </label>
                  <span>{data.benefits_5}</span>
                </div>

                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    6
                  </label>
                  <span>{data.benefits_6}</span>
                </div>

                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    7
                  </label>
                  <span></span>
                </div>

                {/* <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    8
                  </label>
                  <span></span>
                </div>
                <div className="dk-suMMformGroupCon">
                  <label className="text-center" style={{ minWidth: "32px" }}>
                    9
                  </label>
                  <span></span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default Jobbenfits;
