import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { ImUsers } from "react-icons/im";
import { FaTimesCircle } from "react-icons/fa";
import './mypipeduplicate.scss'


const DuplicateCheck = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <button className='dkg-topright-cricleBtn' onClick={handleShow}>
                <ImUsers />
            </button>
            <Modal show={show} onHide={handleClose} className="dk-mypipecheckduplicateModal" centered>
                <Modal.Header closeButton className='dkg-myduplicateMdoal-header-12' >
                    <Modal.Title>Find Duplicate Candidate</Modal.Title>
                </Modal.Header>
                <Modal.Body className='dkg-myduplicateMdoal-Body-12'>
                    <div className="dk-duplicateFormCon">
                        <form action="">
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <div className="dk-duplicateFormGruop">
                                        <input type="text" className="form-control" />
                                        <span className='dkg-crosssbtnCon14'>

                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="dk-duplicateFormGruop df">
                                        <input type="text" className="form-control" />

                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-12'>
                                    <div className="dk-duplicateFormGruop">
                                        <input type="text" className="form-control" />

                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="duplicateBtnCon">
                                        {/* <Submit txt="Search" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="btn dk-srchBtn" /> */}
                                        <button className='btn dk-srchBtn clear'>Clear</button>
                                    </div>
                                </div>


                                <div className='col-md-12'>
                                    <div className='dk-check-dupplicate-tableCon'>
                                        <table className="dk-check-dupplicate-table table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Recruiter</th>
                                                    <th>Profile ID</th>
                                                    <th>Name of Candidate</th>
                                                    <th>Case Status</th>
                                                    <th>Case Progess</th>
                                                    <th>Case Stage</th>
                                                    <th>Case Pipeline</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td>12</td>
                                                    <td>
                                                        <div className="dk-dup-recimgCon">

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="profile-id">12</div>
                                                    </td>
                                                    {/* <td>{item.candidateDetails.name}</td> */}
                                                    <td>Deepak Kumar</td>
                                                    <td>
                                                        <div className="caseDiv" style={{ backgroundColor: "rgb(45, 155, 153)" }}>Interested</div>
                                                    </td>
                                                    <td>
                                                        <div className="caseDiv" style={{ backgroundColor: "rgb(45, 155, 153)" }}>New Profile</div>
                                                    </td>
                                                    <td>
                                                        <div className="caseDiv" style={{ backgroundColor: "rgb(45, 155, 153)" }}>New Profile</div>
                                                    </td>
                                                    <td>
                                                        <span className='profile-location-text'>
                                                            New Case
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default DuplicateCheck