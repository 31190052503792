import React from 'react';
import { Link } from "react-router-dom";
import './salessendouts.scss';
import SendoutsLeftPanel from './leftpanel/SendoutsLeftPanel';
import Dropdown from 'react-bootstrap/Dropdown';

const KpiSalesSendouts = () => {
    return (
        <>
            <div className="dk-sendouts-containerMain">
                <SendoutsLeftPanel />

                <div className="dk-sendouts-rightPanel">
                    <div className="dk-sendoutsHead">
                        <div className="dk-sendouts-yearsDropdown">
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    2022
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#">2021</Dropdown.Item>
                                    <Dropdown.Item href="#">2020</Dropdown.Item>
                                    <Dropdown.Item href="#">2019</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="dk-sendouts-months">
                            <ul>
                                <li><Link to="#">Jan</Link></li>
                                <li><Link to="#">Feb</Link></li>
                                <li><Link to="#">Mar</Link></li>
                                <li><Link to="#">Apr</Link></li>
                                <li><Link to="#">May</Link></li>
                                <li><Link to="#">Jun</Link></li>
                                <li><Link to="#" className="active">Jul</Link></li>
                                <li><Link to="#">Aug</Link></li>
                                <li><Link to="#">Sep</Link></li>
                                <li><Link to="#">Oct</Link></li>
                                <li><Link to="#">Nov</Link></li>
                                <li><Link to="#">Dec</Link></li>
                            </ul>
                            <Link className="addMonthBtn" to="#"><i className="fas fa-plus"></i></Link>
                        </div>
                    </div>
                    <div className="dk-salesSendouts-table">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Date & Time</th>
                                    <th>Recruiter</th>
                                    <th>Week</th>
                                    <th>Day</th>
                                    <th>Job Offers</th>
                                    <th>Refused Offer</th>
                                    <th>Credit</th>
                                    <th>Bonus</th>
                                    <th>Net Sale</th>
                                    <th>Sale Target</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            1
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        2
                                    </td>
                                    <td>5</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            2
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            3
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>4</td>
                                    <td>12</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="10750" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="8000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            4
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        2
                                    </td>
                                    <td>5</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            5
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            6
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>4</td>
                                    <td>12</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="10750" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="8000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            7
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        2
                                    </td>
                                    <td>5</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            8
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            9
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>4</td>
                                    <td>12</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="10750" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="8000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            10
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        2
                                    </td>
                                    <td>5</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            11
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            12
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>4</td>
                                    <td>12</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="10750" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="8000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            13
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        2
                                    </td>
                                    <td>5</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            14
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            15
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>4</td>
                                    <td>12</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="10750" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="8000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            16
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        2
                                    </td>
                                    <td>5</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            17
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            18
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>4</td>
                                    <td>12</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="10750" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="8000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            19
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        2
                                    </td>
                                    <td>5</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            20
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            21
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>4</td>
                                    <td>12</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="10750" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="8000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            22
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        2
                                    </td>
                                    <td>5</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            23
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            24
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>4</td>
                                    <td>12</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="10750" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="8000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            25
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>4</td>
                                    <td>12</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="10750" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="8000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            26
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        2
                                    </td>
                                    <td>5</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            27
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            28
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>4</td>
                                    <td>12</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="10750" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="8000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            29
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>
                                        2
                                    </td>
                                    <td>5</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            30
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="12000" name="" id="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            31
                                        </div>
                                    </td>
                                    <td>06 Jul, 07:18</td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td>4</td>
                                    <td>12</td>
                                    <td>
                                        <input type="text" className="form-control" value="3000" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="0" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="10750" name="" id="" />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" value="8000" name="" id="" />
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr className="sumtr">
                                    <td colSpan="3" className="transparent"></td>
                                    <td className="sum countGrossSale">35</td>
                                    <td className="sum countGrossSale">160</td>
                                    <td className="sum countGrossSale">18450 €</td>
                                    <td className="sum countGrossSale">0 €</td>
                                    <td className="sum countGrossSale">0 €</td>
                                    <td className="sum countGrossSale">0 €</td>
                                    <td className="sum countGrossSale">18450 €</td>
                                    <td className="sum countGrossSale">35000 €</td>
                                </tr>
                            </tfoot>

                        </table>
                    </div>
                </div>

            </div>
        </>
    )
}

export default KpiSalesSendouts
