import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './notifications.scss';
import Modal from 'react-bootstrap/Modal';
import UserImg from "../../../../../assets/images/deepak.png";
import {FaRegCalendarAlt, FaPlus } from 'react-icons/fa';
import AddNewNotification from "./AddNewNotification";
import ReadMessageModal from './ReadMessage'


const NotificationModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Link to="#" onClick={handleShow} className="dk-unreadEmails" title="Unread Message"><i className="fas fa-exclamation-triangle"></i></Link>
            <Modal show={show} onHide={handleClose} className="dk-notificationsnewModal">
                <Modal.Header closeButton className='dk-notificationsnewModalHeader' style={{ background: '#396a95' }}>
                    <AddNewNotification />
                    <Modal.Title>NOTIFICATIONS</Modal.Title>
                </Modal.Header>
                <Modal.Body className='dk-notificationsnewModalBody'>
                    <div className='dkg-compy-noti-left-boxcon'>
                        <div className='dkg-compy-noti-box-cols'>
                            <div className="dk-moduleNoti-box active">
                                <div className="dk-notificationImg">
                                    <img src={UserImg} alt="" />
                                </div>
                                <div className="dk-notificationText">
                                <div className="d-flex dkg-compy-noti-boxs-Header">
                                        <div className="d-timeflex">
                                            <small><FaRegCalendarAlt /></small>
                                            <small>20 Jan 2022</small>
                                        </div>
                                        <div className="d-timeflex dkg-notification-twoblock">
                                            <small>Category</small>
                                            <small>Category 1</small>
                                        </div>
                                        <div className="d-timeflex dkg-notification-twoblock">
                                            <small>Type</small>
                                            <small>Type 1</small>
                                        </div>
                                    </div>
                                    <div className="dk-notification-w100">
                                        <p>
                                            <ReadMessageModal /> 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-moduleNoti-box">
                                <div className="dk-notificationImg">
                                    <img src={UserImg} alt="" />
                                </div>
                                <div className="dk-notificationText">
                                <div className="d-flex dkg-compy-noti-boxs-Header">
                                        <div className="d-timeflex">
                                            <small><FaRegCalendarAlt /></small>
                                            <small>20 Jan 2022</small>
                                        </div>
                                        <div className="d-timeflex dkg-notification-twoblock">
                                            <small>Category</small>
                                            <small>Category 1</small>
                                        </div>
                                        <div className="d-timeflex dkg-notification-twoblock">
                                            <small>Type</small>
                                            <small>Type 1</small>
                                        </div>
                                    </div>
                                    <div className="dk-notification-w100">
                                        <p>
                                            <ReadMessageModal /> 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-moduleNoti-box">
                                <div className="dk-notificationImg">
                                    <img src={UserImg} alt="" />
                                </div>
                                <div className="dk-notificationText">
                                <div className="d-flex dkg-compy-noti-boxs-Header">
                                        <div className="d-timeflex">
                                            <small><FaRegCalendarAlt /></small>
                                            <small>20 Jan 2022</small>
                                        </div>
                                        <div className="d-timeflex dkg-notification-twoblock">
                                            <small>Category</small>
                                            <small>Category 1</small>
                                        </div>
                                        <div className="d-timeflex dkg-notification-twoblock">
                                            <small>Type</small>
                                            <small>Type 1</small>
                                        </div>
                                    </div>
                                    <div className="dk-notification-w100">
                                        <p>
                                            <ReadMessageModal />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-moduleNoti-box">
                                <div className="dk-notificationImg">
                                    <img src={UserImg} alt="" />
                                </div>
                                <div className="dk-notificationText">
                                <div className="d-flex dkg-compy-noti-boxs-Header">
                                        <div className="d-timeflex">
                                            <small><FaRegCalendarAlt /></small>
                                            <small>20 Jan 2022</small>
                                        </div>
                                        <div className="d-timeflex dkg-notification-twoblock">
                                            <small>Category</small>
                                            <small>Category 1</small>
                                        </div>
                                        <div className="d-timeflex dkg-notification-twoblock">
                                            <small>Type</small>
                                            <small>Type 1</small>
                                        </div>
                                    </div>
                                    <div className="dk-notification-w100">
                                        <p>
                                            <ReadMessageModal /> 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-moduleNoti-box">
                                <div className="dk-notificationImg">
                                    <img src={UserImg} alt="" />
                                </div>
                                <div className="dk-notificationText">
                                <div className="d-flex dkg-compy-noti-boxs-Header">
                                        <div className="d-timeflex">
                                            <small><FaRegCalendarAlt /></small>
                                            <small>20 Jan 2022</small>
                                        </div>
                                        <div className="d-timeflex dkg-notification-twoblock">
                                            <small>Category</small>
                                            <small>Category 1</small>
                                        </div>
                                        <div className="d-timeflex dkg-notification-twoblock">
                                            <small>Type</small>
                                            <small>Type 1</small>
                                        </div>
                                    </div>
                                    <div className="dk-notification-w100">
                                        <p>
                                            <ReadMessageModal /> 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-moduleNoti-box">
                                <div className="dk-notificationImg">
                                    <img src={UserImg} alt="" />
                                </div>
                                <div className="dk-notificationText">
                                <div className="d-flex dkg-compy-noti-boxs-Header">
                                        <div className="d-timeflex">
                                            <small><FaRegCalendarAlt /></small>
                                            <small>20 Jan 2022</small>
                                        </div>
                                        <div className="d-timeflex dkg-notification-twoblock">
                                            <small>Category</small>
                                            <small>Category 1</small>
                                        </div>
                                        <div className="d-timeflex dkg-notification-twoblock">
                                            <small>Type</small>
                                            <small>Type 1</small>
                                        </div>
                                    </div>
                                    <div className="dk-notification-w100">
                                        <p>
                                            <ReadMessageModal /> 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default NotificationModal;
