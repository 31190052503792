import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import './read-mail.scss';
import { Modal } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';


const ReadMessage = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <span onClick={handleShow}>{props?.data?.mailbox?.subject}</span>
            <Modal show={show} onHide={handleClose} className="dk-readmail-notificationsnewModal">
                <Modal.Header closeButton className='dk-readmail-notificationsnewModalHeader' style={{ background: '#396a95' }}>
                    <Modal.Title>{props?.data?.mailbox?.subject}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='dk-readmail-notificationsnewModalBody'>
                    <p className='dkg-mail-message'> <div dangerouslySetInnerHTML={{__html: props?.data?.mailbox?.body}} /></p>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReadMessage;
