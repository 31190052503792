import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import "../responsiveMenu.scss";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './mainmenusubmenuc.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const NotificatoinSubmenu = (props) => {
    const classes = useStyles();
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];

    const [toggleMenu, setToggleMenu] = useState(false);
    useEffect(() => {
        setToggleMenu(false)
    }, [pathname])

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setToggleMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);


    return (
        <React.Fragment>
            <div className="dk-subMenuMain dkg-mainModuleSubmenu res-sub-menu" ref={ref}>
                <IconButton aria-label="delete" className="res-sub-btn" onClick={() => setToggleMenu(!toggleMenu)}>
                    <i className="fas fa-bars"></i>
                </IconButton>
                <Route>
                    <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
                        <li><Link to="/notifications/summary" className={(segment2 == 'summary') ? 'active' : ''}>Summary</Link></li>
                        <li><Link to="#/notifications/today/my-tasks" className={`${(segment2 == 'today') ? 'active' : ''}`}>Targets</Link></li>
                        <li><Link to="#/notifications/onedayago/my-tasks" className={`${(segment2 == 'onedayago') ? 'active' : ''}`}>Jobs</Link></li>
                        <li><Link to="#/notifications/twodayago/my-tasks" className={`${(segment2 == 'twodayago') ? 'active' : ''}`}>Pipeline</Link></li>
                        <li><Link to="#/notifications/resource" className={`${(segment2 == 'resource') ? 'active' : ''}`}>KPI</Link></li>
                        <li><Link to="#/notifications/threedayago/my-tasks" className={`${(segment2 == 'threedayago') ? 'active' : ''}`}>Results</Link></li>
                        <li><Link to="#/notifications/fourdayago/my-tasks" className={`${(segment2 == 'fourdayago') ? 'active' : ''}`}>Bonus</Link></li>
                        <li><Link to="#/notifications/fivedayago/my-tasks" className={`${(segment2 == 'fivedayago') ? 'active' : ''}`}></Link></li>
                        <li><Link to="#/notifications/training" className={`${(segment2 == 'training') ? 'active' : ''}`}></Link></li>
                        <li><Link to="#/notifications/add-new" className={`${(segment2 == 'add-new') ? 'active' : ''}`}></Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default NotificatoinSubmenu;
