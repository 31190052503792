import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import './addnew.scss';
import { useDispatch, useSelector } from "react-redux";
import SelectInstance from '../../../../ui/SelectPicker/SelectInstance';
import Submit from '../../../../ui/submitButton';
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import { updateQualify, clearState } from "../../../../../slice/qualify/qualifySlice";
import Constants from "../../../../../constants";

const Edit = ({ onHide }) => {

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const { dataList, detailData, qualifyCategoryList, frequencyDataList, isDataLoading, isLoading, isUpdate, isError, isDelete, errorMessage } = useSelector(state => state.qualify)
    const [qualifyCategory, setQualifyCategory] = useState(detailData.categoryId._id);
    const [frequency, setFrequency] = useState(detailData.frequency);
    const [questions, setQuestions] = useState(detailData.question);
    const [answer, setAnswer] = useState(detailData.answer);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [rowId, setRowId] = useState(detailData._id);

    const handleChangeSelect = (event, data) => {
        const { name, value } = data
        switch (name) {
            case "qualifyCategory":
                setQualifyCategory(value)
                break;
            case "frequency":
                setFrequency(value)
                break;

        }
    }


    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "questions":
                setQuestions(value)
                break;
            case "answer":
                setAnswer(value)
                break;
            default:
                break;
        }
    }

    const handleButtonClick = async () => {
        if (!qualifyCategory || !frequency || !questions || !answer) {
            dispatch(showError({ msg: 'Please fill all fields.' }))
        } else {
            if (!loading) {
                setSuccess(false);
                setLoading(true);
                await dispatch(updateQualify({ id: rowId, companyId: Constants.SYSTEM_CONSTANTS.QUALIFY.CATEGORY_ID_QUALIFY, categoryId: qualifyCategory, qualifyCategory, frequency, question: questions, answer }))
            }
        }
    }

    useEffect(() => {
        if (isUpdate) {
            dispatch(showSuccess({ msg: 'Qualify updated successfully.' }))
            dispatch(clearState())
            setQualifyCategory('');
            setFrequency('');
            setQuestions('');
            setAnswer('');
            onHide()
            setSuccess(true);
            setLoading(false);
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }
    }, [isUpdate, isError])


    return (
        <>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-qualifyPopup"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >   <Modal.Header closeButton className="dk-qualifyEditHeader">
                    <Modal.Title className="dkqualifyedittitle">Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-qualifyAddNewBody">
                    <form>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Category</label>
                                    <div className="dk-qualifyDrpDwn">
                                        <SelectInstance options={qualifyCategoryList} name="qualifyCategory" value={qualifyCategory} onChange={handleChangeSelect} placeholder={detailData.categoryId.value} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Frequency</label>
                                    <div className="dk-qualifyDrpDwn">
                                        <SelectInstance options={frequencyDataList} name="frequency" value={frequency} onChange={handleChangeSelect} placeholder={detailData.frequency.value} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="">Questions</label>
                                    <input type="text" id="questions" className="form-control" name="questions" value={questions} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="">Answer</label>
                                    <input type="text" id="answer" className="form-control" name="answer" value={answer} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="dk-adstrackbtnCont">
                                    <Submit txt="Update" loading={loading} success={success} onClick={handleButtonClick} className="dk-adstrackaddbtn" />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Edit;
