import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const KnowledgeBaseMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalfinanceKnowledgeBaseMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/dkglobalfinance/knowledgebase/dashboard" className={(segment2 == 'knowledgebase') ? 'active' : ''}>Business Book</Link></li>
                        <li><Link to="#/dkglobalfinance/faq/invoice-book" className={`${(segment2 == 'invoice-book') ? '' : ''}`}>Invoice Book</Link></li>
                        <li><Link to="#/dkglobalfinance/faq/banking" className={`${(segment2 == 'banking') ? '' : ''}`}>Banking</Link></li>
                        <li><Link to="#/dkglobalfinance/faq/cashflow" className={`${(segment2 == 'cashflow') ? '' : ''}`}>Cashflow</Link></li>
                        <li><Link to="#/dkglobalfinance/faq/bibby-financial" className={`${(segment2 == 'bibby-financial') ? '' : ''}`}>Bibby Financial</Link></li>

                        <li><Link to="#/dkglobalfinance/faq/cashflow" className={`${(segment2 == 'cashflow') ? '' : ''}`}>Company Doc’s </Link></li>

                        <li><Link to="#/dkglobalfinance/faq/cashflow" className={`${(segment2 == 'cashflow') ? '' : ''}`}>Tax Returns</Link></li>

                        <li><Link to="#/dkglobalfinance/faq/cashflow" className={`${(segment2 == 'saved-invoices') ? '' : ''}`}>Saved Invoices</Link></li>

                        <li><Link to="#/dkglobalfinance/faq/contracts" className={`${(segment2 == 'contracts') ? '' : ''}`}>Contracts</Link></li>


                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default KnowledgeBaseMenu;