import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData } from "../../services";

export const getText = createAsyncThunk(
  "getPrincipal/text-change",
  async (thunkAPI) => {
    const res = await GetData(true, "/principals/");
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);


const usersSlice = createSlice({
 name: "principals",
  initialState: {
    getPrincipalList: [],
    isSuccess: false,
    isUpdate: false,
    isLoading: false,
    isError: false,
    isDelete: false,
  },
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.isUpdate = false;
      state.isDelete = false;
    },
  },
  extraReducers: {
    [getText.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [getText.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.getPrincipalList = payload.result;
    },
    [getText.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },

  
  },
});

export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
