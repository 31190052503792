import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData, PostData, PutData, DeleteData } from "../../services";

export const getCategories = createAsyncThunk(
  "clients/frontend/demands/categoryDemandlist",
  async (thunkAPI) => {
    const res = await GetData(true, "/demands-category");
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getdefaultSubCategories = createAsyncThunk(
  "clients/frontend/demands/subcatlist",
  async ({keys},thunkAPI) => {
    const res = await GetData(true, "/demands-sub-category/default/"+keys);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getSubCategories = createAsyncThunk(
  "clients/frontend/demands/subcatlist",
  async ({categoryId,keys},thunkAPI) => {
    const res = await GetData(true, "/demands-sub-category/"+categoryId+'/'+keys);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSubCatDetails = createAsyncThunk(
  "clients/frontend/demands/detailsbyid",
  async ({id},thunkAPI) => {
    const res = await GetData(true, "/demands-sub-category/"+id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);


const usersSlice = createSlice({
  name: "clientsInfoDemands",
  initialState: {
    categoryDemandList: [],
    subCategoryDemandsList: [],
    subCategoryDetails: [],
    isSuccess: false,
    isTabActive: false,
    isCatInsert: false,
    isSubCatInsert: false,
    isCatUpdate: false,
    isSubCatUpdate: false,
    isLoading: false,
    issLoading: false,
    isSubLoading: false,
    isSubReoder: false,
    isError: false,
    isDelete: false,
    issDelete: false,
    isReoder: false,
  },
  reducers: {
    clearState: (state) => {
      state.isSubReoder = false;
      state.isDetails = false;
      state.isSubLoading = false;
      state.issLoading = false;
      state.isLoading = false;
      state.isSubCatInsert = false;
      state.isSuccess = false;
      state.isTabActive = false;
      state.isError = false;
      state.isCatInsert = false;
      state.isSubCatUpdate = false;
      state.isCatUpdate = false;
      state.isDelete = false;
      state.issDelete = false;
      state.isReoder = false;
    },
  },
  extraReducers: {
  
     [getSubCatDetails.pending]: (state) => {
      state.isError = false;
      state.isDetails = false;
    },
    [getSubCatDetails.fulfilled]: (state, { payload }) => {
      state.isDetails = true;
      state.subCategoryDetails = payload.result;
    },
    [getSubCatDetails.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isDetails = false;
    },

     [getdefaultSubCategories.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isTabActive = false;
    },
    [getdefaultSubCategories.fulfilled]: (state, { payload }) => {
     state.isLoading = false;
      state.isTabActive = true;
      state.isSuccess = true;
      state.subCategoryDemandsList = payload.result;
    },
    [getdefaultSubCategories.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
      state.isTabActive = false;
    },

     [getSubCategories.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isTabActive = false;
    },
    [getSubCategories.fulfilled]: (state, { payload }) => {
     state.isLoading = false;
      state.isTabActive = true;
      state.isSuccess = true;
      state.subCategoryDemandsList = payload.result;
    },
    [getSubCategories.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
      state.isTabActive = false;
    },


     [getCategories.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [getCategories.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.categoryDemandList = payload.result;
    },
    [getCategories.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },
    
  },
});
export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
