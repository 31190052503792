import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';


const ItalianDetailLeftPanel = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box>
                <TabContext value={value}>
                    <div className="d-flex">
                        <div className="dk-detailLefttabsMain">
                            <Box>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Sample Tabs 1" value="1" />
                                </TabList>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Sample Tabs 2" value="2" />
                                </TabList>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Sample Tabs 3" value="3" />
                                </TabList>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Sample Tabs 4" value="4" />
                                </TabList>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Sample Tabs 5" value="5" />
                                </TabList>
                            </Box>
                        </div>
                        <div className="dk-tabDetail-flex">
                            <TabPanel value="1">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!
                            </TabPanel>
                            <TabPanel value="2">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique, quibusdam? Velit, corrupti libero illo nobis, consequatur aperiam labore earum id architecto error facilis sequi soluta! Adipisci recusandae unde quod molestiae!
                            </TabPanel>
                            <TabPanel value="3">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!
                            </TabPanel>
                            <TabPanel value="4">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique, quibusdam? Velit, corrupti libero illo nobis, consequatur aperiam labore earum id architecto error facilis sequi soluta! Adipisci recusandae unde quod molestiae!
                            </TabPanel>
                            <TabPanel value="5">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!
                            </TabPanel>
                        </div>
                    </div>
                </TabContext>
            </Box>


        </>
    )
}

export default ItalianDetailLeftPanel;

