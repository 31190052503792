import React from "react";
import { Route, Link } from "react-router-dom";

const MembershipSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/my-files/membership/membership1" className={`${(segment3 == 'membership1') ? 'active' : ''}`}>Companies 1</Link></li>
                        <li><Link to="#/my-files/memberships/membership2" className={`${(segment3 == 'membership2') ? 'active' : ''}`}>Companies 2</Link></li>
                        <li><Link to="#/my-files/memberships/membership3" className={`${(segment3 == 'membership3') ? 'active' : ''}`}>Companies 3</Link></li>
                        <li><Link to="#/my-files/memberships/membership4" className={`${(segment3 == 'membership4') ? 'active' : ''}`}>Companies 4</Link></li>
                        <li><Link to="#/my-files/memberships/membership5" className={`${(segment3 == 'membership5') ? 'active' : ''}`}>Companies 5</Link></li>
                        <li><Link to="#/my-files/memberships/membership6" className={`${(segment3 == 'membership6') ? 'active' : ''}`}>Companies 6</Link></li>
                        <li><Link to="#/my-files/memberships/membership7" className={`${(segment3 == 'membership7') ? 'active' : ''}`}>Companies 7</Link></li>
                        <li><Link to="#/my-files/memberships/membership8" className={`${(segment3 == 'membership8') ? 'active' : ''}`}>Companies 8</Link></li>
                        <li><Link to="#/my-files/memberships/membership9" className={`${(segment3 == 'membership9') ? 'active' : ''}`}>Companies 9</Link></li>
                        <li><Link to="#/my-files/memberships/membership10" className={`${(segment3 == 'membership10') ? 'active' : ''}`}>Companies 10</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default MembershipSubmenu;
