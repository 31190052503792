import React from 'react';
import { Link } from "react-router-dom";
import './experience.scss';
import ExpCard from "./ExpCard";
const TrainnigAchiment = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="title mb-2 ml-0 mt-3 pl-3 pr-3" style={{fontSize: "18px" , fontWeight: "600"}}>Job Trainings</div>
                    <div className="dk-expMainCon dkg-train-achivment-con pl-3 pr-3">
                        <div className="dk-expCardCon dkg-train-achivment-card-con">
                            <div className="dk-expCard dkg-train-achivment-card">
                                <div className="dk-viewtable">
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <div className="dk-viewExpbox">
                                                <div className="Exptitle">Job Trainings</div>
                                                <table className="table">
                                                    <tbody><tr>
                                                        <td><i className="fa fa-user"></i> Employer</td>
                                                        <td>:</td>
                                                        <td>Karmatech Media works Pvt. Ltd</td>
                                                    </tr>
                                                        <tr>
                                                            <td><i className="fa fa-calendar"></i> Start Date</td>
                                                            <td>:</td>
                                                            <td>01 May 2016</td>
                                                        </tr>
                                                        <tr>
                                                            <td><i className="fa fa-calendar"></i> End Date</td>
                                                            <td>:</td>
                                                            <td>05 Jan 2021</td>
                                                        </tr>
                                                    </tbody></table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="title mb-2 ml-0 mt-3 pl-3 pr-3" style={{fontSize: "18px" , fontWeight: "600"}}>Achievements</div>
                    <div className="dk-expMainCon dkg-train-achivment-con pl-3 pr-3">
                        <div className="dk-expCardCon dkg-train-achivment-card-con">
                            <div className="dk-expCard dkg-train-achivment-card">
                                <div className="dk-viewtable">
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <div className="dk-viewExpbox">
                                                <div className="Exptitle">Achivments</div>
                                                <table className="table">
                                                    <tbody><tr>
                                                        <td><i className="fa fa-user"></i> Employer</td>
                                                        <td>:</td>
                                                        <td>Karmatech Media works Pvt. Ltd</td>
                                                    </tr>
                                                        <tr>
                                                            <td><i className="fa fa-calendar"></i> Start Date</td>
                                                            <td>:</td>
                                                            <td>01 May 2016</td>
                                                        </tr>
                                                        <tr>
                                                            <td><i className="fa fa-calendar"></i> End Date</td>
                                                            <td>:</td>
                                                            <td>05 Jan 2021</td>
                                                        </tr>
                                                    </tbody></table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrainnigAchiment;
