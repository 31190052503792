// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import FormControl from 'react-bootstrap/FormControl';
import Dropdown from 'react-bootstrap/Dropdown'
import './select-srchdbdropdwn.scss';
import { RotatingLines } from 'react-loader-spinner'
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        style={{ ref }}
        className="dkg-srchdb-plus-dropdown"
    >
        {children}
        {/* &#xf067; */}

        <span className="dkg-plusIcon">
            <i className="fa fa-plus"></i>
        </span>
    </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy, props }, ref) => {
        const [value, setValue] = useState('');

        useEffect(() => {
            const timer = window.setInterval(() => {
                setValue('')
            }, 10000);
            return () => { // Return callback to run on unmount.
                window.clearInterval(timer);
            };
        }, [])

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className=" w-100"
                    placeholder="Search Text..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);

const SelectWithSearchDropdown = (props) => {
    // console.log(props);
    const type = props.column;
    let className = "";

    switch (type) {

        case 'accountManager':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'recordYear':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'rcAdmin':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'visaStatus':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'caseStatus':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'latest_job':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'education':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'careerLevel':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'industries':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'native1':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'fluent1':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'intermediate1':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'itSkill':
            className = "dk-candsMMToggleBtnred";
            break;

        case 'jobskill':
            className = "dk-candsMMToggleBtnred";
            break;

        case 'nationality':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'c_country':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'c_city':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'case_progress':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'case_stage':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'category':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'cv_source':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'name':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'location':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'job_country':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'locality':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'project':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'company':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'role':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'lang':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'crmProfile':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'priority':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'profileStatus':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'flag_type':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'gender':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'profileIndicator':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;

        case 'motivationStatus':
            className = props.isFilter ? "dk-candsMMToggleBtnActive" : "";
            break;


        default:
            break;
    }
    return (
        <>

            <div className="dkg-searchdb-dropdown-u12">
                <Dropdown className={`dk-candsMMToggleBtn ${className}`}>
                    <Dropdown.Toggle as={CustomToggle}>
                        {props.title}
                        {/* for show count only */}
                        {/* <span className='dkg-srch-db-headerCounter'> { props.totalCount }</span> */}

                        {
                            (() => {
                                if (props.column == "accountManager") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="am_id" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "recordYear") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="year" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "rcAdmin") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="rec_id" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "visaStatus") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="visaStatus" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "caseStatus") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="caseStatus" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "latest_job") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="latest_job" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "education") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="education" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "careerLevel") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="career_level" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "industries") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="industries" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "native1") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="native1" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "fluent1") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="fluent1" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "intermediate1") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="intermediate1" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "itSkill") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount}</span>)
                                }
                                else if (props.column == "gender") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="gender" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "jobskill") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount}</span>)
                                }
                                else if (props.column == "nationality") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="nationality" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "c_country") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="c_country" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "c_city") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="c_city" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "case_progress") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="case_progress" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "case_stage") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="case_stage" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "category") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="category" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "cv_source") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="cv_source" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "name") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="name" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "location") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="location" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "job_country") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="job_country" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "locality") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="locality" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "project") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="project" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "company") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="company" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "role") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="role" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "lang") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="lang" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "profileIndicator") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="profileIndicator" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "motivationStatus") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="motivationStatus" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "priority") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="priority" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "profileStatus") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="profileStatus" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else if (props.column == "flag_type") {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} {props.isFilter ? <i className="fa fa-times-circle  ml-2" data-type="flag_type" onClick={props.removeTag}></i> : ""}</span>)
                                }
                                else {
                                    return (<span className='dkg-srch-db-headerCounter'>{props.totalCount} </span>)
                                }
                            })()
                        }
                    </Dropdown.Toggle>
                    {
                        (() => {
                            if (props.column == "accountManager") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item onClick={props.selectFilter} data-value={filteredItems.id} data-name={filteredItems.name} data-img={filteredItems.userImg} data-type="am_id" className="dk-progress-stageItem active mb-2" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count}>
                                                    <img onClick={props.selectFilter} data-value={filteredItems.id} data-name={filteredItems.name} data-img={filteredItems.userImg} data-type="am_id" src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + filteredItems.userImg} alt={filteredItems.name} className="recruitersImg" style={{ maxWidth: "32px", maxHeight: "32px", borderRadius: "50%", border: "1px solid #ddd" }} /> {filteredItems.name ? filteredItems.name : "Blank"}
                                                    <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "rcAdmin") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item onClick={props.selectFilter} data-value={filteredItems.id} data-name={filteredItems.name} data-img={filteredItems.userImg} data-type="rec_id" className="dk-progress-stageItem active mb-2" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count}>
                                                    <img onClick={props.selectFilter} data-value={filteredItems.id} data-name={filteredItems.name} data-img={filteredItems.userImg} data-type="rec_id" src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + filteredItems.userImg} alt={filteredItems.name} className="recruitersImg" style={{ maxWidth: "32px", maxHeight: "32px", borderRadius: "50%", border: "1px solid #ddd" }} /> {filteredItems.name ? filteredItems.name : "Blank"}
                                                    <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "caseStatus") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="caseStatus" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "recordYear") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="year" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )


                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "latest_job") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="latest_job" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "visaStatus") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="visaStatus" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "education") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="education" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "careerLevel") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="career_level" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "industries") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="industries" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "native1") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item onClick={props.selectFilter} data-type="native1" className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "fluent1") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="fluent1" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "intermediate1") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="intermediate1" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "itSkill") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.technical_skills}  >{filteredItems.technical_skills} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "gender") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="gender" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "jobskill") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.job_profile_skills}  >{filteredItems.job_profile_skills} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "nationality") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="nationality" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "c_country") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name} data-type="c_country">{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "c_city") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name} data-type="c_city" >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter' >{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>)

                            }
                            else if (props.column == "case_progress") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="case_progress" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}>{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>

                                            )
                                        }
                                    </Dropdown.Menu>)
                            }
                            else if (props.column == "case_stage") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="case_stage" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}>{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>

                                            )
                                        }
                                    </Dropdown.Menu>)
                            }
                            else if (props.column == "category") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="category" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}   >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>)
                            }
                            else if (props.column == "cv_source") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="cv_source" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>)
                            }
                            else if (props.column == "name") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="name" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>)
                            }
                            else if (props.column == "location") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="location" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>)
                            }
                            else if (props.column == "job_country") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type='job_country' onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>)
                            }
                            else if (props.column == "locality") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="locality" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>)
                            }
                            else if (props.column == "project") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="project" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>)
                            }
                            else if (props.column == "company") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="company" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>)
                            }
                            else if (props.column == "role") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="role" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>)
                            }
                            else if (props.column == "lang") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="lang" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>)
                            }
                            else if (props.column == "profileIndicator") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>

                                        {
                                            props.list.map((filteredItems, index) => {
                                                let name = "";
                                                if (filteredItems.name === 1) {
                                                    name = "Not Updated";
                                                }
                                                if (filteredItems.name === 2) {
                                                    name = "Updated";
                                                }
                                                if (filteredItems.name === "") {
                                                    name = "Blank";
                                                }

                                                return <Dropdown.Item data-type="profileIndicator" onClick={props.selectFilter} data-name={name} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={(filteredItems.name)}  >{name} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            })
                                        }
                                    </Dropdown.Menu>)
                            }
                            else if (props.column == "motivationStatus") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>

                                        {
                                            props.list.map((filteredItems, index) => {
                                                let name = "";
                                                if (filteredItems.name === 1) {
                                                    name = "Motivated";
                                                }
                                                if (filteredItems.name === 2) {
                                                    name = "Doubtful";
                                                }
                                                if (filteredItems.name === 3) {
                                                    name = "Unmotivated";
                                                }
                                                if (filteredItems.name === 4) {
                                                    name = "Unmotivated";
                                                }
                                                if (filteredItems.name === 0) {
                                                    name = "Blank";
                                                }

                                                return <Dropdown.Item data-type="motivationStatus" onClick={props.selectFilter} data-name={name} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={(filteredItems.name)}  >{name} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            })
                                        }
                                    </Dropdown.Menu>)
                            }
                            else if (props.column == "priority") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item data-type="priority" onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={(filteredItems.name)}  >{filteredItems.name ? filteredItems.name : "Blank"} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>)
                            }
                            else if (props.column == "profileStatus") {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) => {
                                                let name = "";
                                                if (filteredItems.name === 1) {
                                                    name = "Top Priority";
                                                }
                                                if (filteredItems.name === 2) {
                                                    name = "Mid Priority";
                                                }
                                                if (filteredItems.name === 4) {
                                                    name = "Low Priority";
                                                }
                                                if (filteredItems.name === 0) {
                                                    name = "Blank";
                                                }
                                                return <Dropdown.Item data-type="profileStatus" onClick={props.selectFilter} data-name={name} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={(filteredItems.name)}  >{name} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            })
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else if (props.column == "flag_type") {
                                return (

                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) => {
                                                let name = "";
                                                if (filteredItems.name === 1) {
                                                    name = "Blank";
                                                }
                                                if (filteredItems.name === 2) {
                                                    name = "Stopped";
                                                }
                                                if (filteredItems.name === 3) {
                                                    name = "Delayed";
                                                }
                                                if (filteredItems.name === 4) {
                                                    name = "Concerns";
                                                }
                                                if (filteredItems.name === 5) {
                                                    name = "On Time";
                                                }

                                                return <Dropdown.Item data-type="flag_type" onClick={props.selectFilter} data-name={name} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={(filteredItems.name)}  >{name} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            })
                                        }
                                    </Dropdown.Menu>
                                )
                            }
                            else {
                                return (
                                    <Dropdown.Menu className="dk-progress-stageMenu" as={CustomMenu}>
                                        {
                                            props.list.map((filteredItems, index) =>
                                                <Dropdown.Item onClick={props.selectFilter} className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" data-id={filteredItems.count} data-value={filteredItems.name}  >{filteredItems.name} <span className='dkg-srchdb-counter'>{filteredItems.count}</span>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                )
                            }

                        })()
                    }


                </Dropdown>
            </div>
        </>
    )
}
export default SelectWithSearchDropdown;
