import React from "react";
import { Route, Link } from "react-router-dom";

const TaxOfficeSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/my-files/tax-office/tax-office1" className={`${(segment3 == 'tax-office1') ? 'active' : ''}`}>Tax Office 1</Link></li>
                        <li><Link to="#/my-files/tax-office/tax-office2" className={`${(segment3 == 'tax-office2') ? 'active' : ''}`}>Tax Office 2</Link></li>
                        <li><Link to="#/my-files/tax-office/tax-office3" className={`${(segment3 == 'tax-office3') ? 'active' : ''}`}>Tax Office 3</Link></li>
                        <li><Link to="#/my-files/tax-office/tax-office4" className={`${(segment3 == 'tax-office4') ? 'active' : ''}`}>Tax Office 4</Link></li>
                        <li><Link to="#/my-files/tax-office/tax-office5" className={`${(segment3 == 'tax-office5') ? 'active' : ''}`}>Tax Office 5</Link></li>
                        <li><Link to="#/my-files/tax-office/tax-office6" className={`${(segment3 == 'tax-office6') ? 'active' : ''}`}>Tax Office 6</Link></li>
                        <li><Link to="#/my-files/tax-office/tax-office7" className={`${(segment3 == 'tax-office7') ? 'active' : ''}`}>Tax Office 7</Link></li>
                        <li><Link to="#/my-files/tax-office/tax-office8" className={`${(segment3 == 'tax-office8') ? 'active' : ''}`}>Tax Office 8</Link></li>
                        <li><Link to="#/my-files/tax-office/tax-office9" className={`${(segment3 == 'tax-office9') ? 'active' : ''}`}>Tax Office 9</Link></li>
                        <li><Link to="#/my-files/tax-office/tax-office10" className={`${(segment3 == 'tax-office10') ? 'active' : ''}`}>Tax Office 10</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default TaxOfficeSubmenu;
