import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SYSTEM_CONSTANTS } from "../../constants";

export const listTable = createAsyncThunk(
    'campaign/listing',
    async ({ type }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/ads/campaigns/' + type,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const usersList = createAsyncThunk(
    'campaign/camp/userslisting',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/users',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const addCampaign = createAsyncThunk(
    'campaign/add',
    async ({ recruiter, jobTitle, jobBoard, Started, expired, leads, budget, placements, category, company, project, roleType, language, location, inTable }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/ads/campaigns/' + inTable,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        recruiter, jobTitle, jobBoard, Started, expired, leads, budget, placements, category, company, project, roleType, language, location, inTable
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const campaignMove = createAsyncThunk(
    'campaign/movetoclose',
    async ({ id, type }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/ads/campaigns/datamove/' + id + '/' + type,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);
export const updateDetails = createAsyncThunk(
    'campaign/update',
    async ({ id, name, value, type }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/ads/campaigns/' + type + '/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        name, value
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);
export const deleteCampaign = createAsyncThunk(
    'campaign/delete',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/ads/campaigns/' + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const campaignCompanyList = createAsyncThunk(
    'campaign/company',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/admin/backend-value/' + SYSTEM_CONSTANTS.CAMPAIGNS.CompanyId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);
export const campaignRoleList = createAsyncThunk(
    'campaign/roles',
    async (thunkAPI) => {
        try {

            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/admin/backend-value/' + SYSTEM_CONSTANTS.CAMPAIGNS.RoleTypeId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);
export const campaignLanguageList = createAsyncThunk(
    'campaign/languages',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/admin/backend-value/' + SYSTEM_CONSTANTS.CAMPAIGNS.LanguageId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);
export const campaignJobBoardList = createAsyncThunk(
    'campaign/job_board_list',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/admin/backend-value/' + SYSTEM_CONSTANTS.CAMPAIGNS.JobBoardId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);
export const campaignProjectList = createAsyncThunk(
    'campaign/project_list',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/admin/backend-value/' + SYSTEM_CONSTANTS.CAMPAIGNS.ProjectId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);
export const campaignCategory = createAsyncThunk(
    'campaign/category',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/admin/backend-value/' + SYSTEM_CONSTANTS.CAMPAIGNS.CategoryId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const reorderCampaign = createAsyncThunk(
    'campaign/reorder',
    async ({ ids, type }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/ads/campaigns/reorder/' + type,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        ids
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

const campaignSlice = createSlice({
    name: 'campaign',
    initialState: {
        userList: [],
        categoryList: [],
        jobBoardList: [],
        companyList: [],
        rolesList: [],
        languageList: [],
        dataList: [],
        projectList: [],
        isLoading: false,
        isDataLoading: false,
        isInsert: false,
        isError: false,
        isUpdate: false,
        isDelete: false,
        issDelete: false,
        isSuccess: false,
        isReoder: false,
        errorMessage: '',
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isDataLoading = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
            state.issDelete = false;
            state.isSuccess = false;
            state.isReoder = false;
            state.errorMessage = '';
            return state;
        }
    },
    extraReducers: {
        [listTable.pending]: (state) => {
            state.isDataLoading = true;
        },
        [listTable.fulfilled]: (state, { payload }) => {
            state.dataList = payload.result;
            state.isDataLoading = false;
        },
        [listTable.rejected]: (state, { payload }) => {
            state.isError = payload.message;
            state.errorMessage = payload.message;
            state.isDataLoading = false;
        },
        [usersList.pending]: (state) => {
            state.isLoading = true;
        },
        [usersList.fulfilled]: (state, { payload }) => {
            state.userList = payload.users;
            state.isLoading = false;
        },
        [usersList.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [campaignCompanyList.pending]: (state) => {
            state.isLoading = true;
        },
        [campaignCompanyList.fulfilled]: (state, { payload }) => {
            state.companyList = payload.result;
            state.isLoading = false;
        },
        [campaignCompanyList.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [campaignRoleList.pending]: (state) => {
            state.isLoading = true;
        },
        [campaignRoleList.fulfilled]: (state, { payload }) => {
            state.rolesList = payload.result;
            state.isLoading = false;
        },
        [campaignRoleList.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [campaignLanguageList.pending]: (state) => {
            state.isLoading = true;
        },
        [campaignLanguageList.fulfilled]: (state, { payload }) => {
            state.languageList = payload.result;
            state.isLoading = false;
        },
        [campaignLanguageList.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [campaignJobBoardList.pending]: (state) => {
            state.isLoading = true;
        },
        [campaignJobBoardList.fulfilled]: (state, { payload }) => {
            state.jobBoardList = payload.result;
            state.isLoading = false;
        },
        [campaignJobBoardList.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [campaignProjectList.pending]: (state) => {
            state.isLoading = true;
        },
        [campaignProjectList.fulfilled]: (state, { payload }) => {
            state.projectList = payload.result;
            state.isLoading = false;
        },
        [campaignProjectList.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [campaignCategory.pending]: (state) => {
            state.isLoading = true;
        },
        [campaignCategory.fulfilled]: (state, { payload }) => {
            state.categoryList = payload.result;
            state.isLoading = false;
        },
        [campaignCategory.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [addCampaign.pending]: (state) => {
            state.isLoading = true;
        },
        [addCampaign.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = true;
            //state.dataList.push(payload.result)
            state.dataList.unshift(payload.result)
            state.msg = payload.message
        },
        [addCampaign.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.errorMessage = payload.message
        },
        [updateDetails.pending]: (state) => {
            state.isLoading = true;
        },
        [updateDetails.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.dataList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.dataList = finalResult
            state.msg = payload.message
        },
        [updateDetails.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.msg = payload.message.msgBody
        },
        [campaignMove.pending]: (state) => {
        },
        [campaignMove.fulfilled]: (state, action) => {
            state.dataList = state.dataList.filter((data) => data.id !== action.meta.arg.id);
            state.isDelete = true;
            state.msg = "Campaigns moved successfully.";
        },
        [campaignMove.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [deleteCampaign.pending]: (state) => {
        },
        [deleteCampaign.fulfilled]: (state, action) => {
            state.dataList = state.dataList.filter((item) => item.id !== action.meta.arg.id);
            state.issDelete = true;
        },
        [deleteCampaign.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [reorderCampaign.pending]: (state) => {
            state.isloading = true;
            state.isReoder = false;
        },
        [reorderCampaign.fulfilled]: (state, { payload }) => {
            state.isloading = false;
            state.isReoder = true;
            state.msg = "Reorder successfully.";
        },
        [reorderCampaign.rejected]: (state, { payload }) => {
            state.isloading = false;
            state.isError = payload.message.msgError;
            state.msg = payload.message.msgBody;
        },
    }
});

export const { clearState } = campaignSlice.actions;
export default campaignSlice.reducer;
