import React, { useState, useEffect, useRef } from 'react';
import "./parse.scss";
//import SearchDropDownFilter from './SearchDropDownFilter';
import { useDispatch, useSelector } from "react-redux";
import Submit from "../../../../components/ui/submitButton";
import { addNewCandidate, apiParsing, clearState, apiduplicateCheck, apiDppduplicateCheck } from "../../../../slice/candidates/list/listSlice";
import { showSuccess, showError } from "../../../../slice/utils/message/messageSlice";
import AWS from 'aws-sdk'
import { SYSTEM_CONSTANTS } from "../../../../constants";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import { FaTimesCircle, FaUserFriends } from "react-icons/fa";
//import { duplicateCheck } from "../../../../slice/candidates/candidatesSlice";
import DataTable from '../../../pages/candidate/pipeline/modal/duplicateCheck/ApiDataTable';
import { set } from 'date-fns';

const AddNew = () => {
    const fileInputAPI = useRef();
    const dispatch = useDispatch();
    const { isLoading, isDuplicateLoading, isAPIParseSuccess, isduplicateMoved, apiDppDuplicateList, isManualParseSuccess, message, isSuccess, isError, isApiduplicateMoved, apiDuplicateList } = useSelector(state => state.candidates)
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [apiParseButton, setApiParseButton] = useState(false);
    const [isParseDuplicate, setIsParseDuplicate] = useState('none');
    const [listParse, setListParse] = useState([]);


    const [file, setFile] = useState('')
    const [fileName, setFileName] = useState('')

    // const [categoryList, setCategoryList] = useState('');
    // const [nationlityList, setNationlity] = useState('');
    // const [country, setCountry] = useState('');
    // const [city, setCity] = useState('');

    //const [visaStatus, setVisaStatus] = useState('');
    // const [nativLanguage, setNativLanguage] = useState('');
    // const [fluentLanguge, setFluentLanguge] = useState('');
    // const [cvSource, setCvSource] = useState('');

    const [localPath, setLocalPath] = useState('');
    const [base64data, setBase64data] = useState('');
    const [localPathApiParse, setLocalPathApiParse] = useState('');

    const [isRefresh, setIsRefresh] = useState(false)

    const [cvParsEmail, setCvParsEmail] = useState('');



    useEffect(() => {
        if (isSuccess) {
            setLoading(false);
            setSuccess(true);
            //setCountry('')
            // setCity('')
            setFileName('')
            formRefresh()
            dispatch(showSuccess({ msg: message }))
            dispatch(clearState())
            // setIsRefresh(false)
        }
        if (isApiduplicateMoved) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: message }))
            setLoading(false)
            setSuccess(false)
            const fetchList = async () => {
                try {
                    await dispatch(apiduplicateCheck({ name, email, phone }))
                } catch (err) {
                    console.log(err)
                }
            }
            fetchList();
        }

    }, [isSuccess, isApiduplicateMoved]);

    const formRefresh = () => {
        setIsRefresh(true)
    }
    const [timeoutId, setTimeoutId] = useState(null);

    const handleCheckDuplicateEmail = (e) => {
        const emailValue = e.target.value;
        setCvParsEmail(emailValue);
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        const newTimeoutId = setTimeout(() => {
            dispatch(apiDppduplicateCheck({ name: null, email: emailValue, phone: null }));
        }, 1000);
        setTimeoutId(newTimeoutId);
    };

    useEffect(() => {
        if (isduplicateMoved) {
            if (apiDppDuplicateList.length > 0) {
                setIsParseDuplicate('duplicate')
                setListParse(apiDppDuplicateList)
            } else {
                setIsParseDuplicate('no-duplicate')
                setListParse([])
            }
        }
    }, [isduplicateMoved])

    const onSelectFile = (e) => {
        setLocalPathApiParse('')
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0])
            setFileName(e.target.files[0].name)
            let tmppath = URL.createObjectURL(e.target.files[0]);
            setLocalPath(tmppath)
        }
    }

    // const manualButtonClick = async (e) => {
    //     e.preventDefault();
    //     dispatch(addNewCandidate({ fileName: '' }))
    // }
    const [cvParsFirstName, setCvParsFirstName] = useState('');
    const [cvParsLastName, setCvParsLastName] = useState('');

    const cvparsingButtonClick = async (e) => {
        e.preventDefault();
        if (!fileName && !cvParsFirstName && !cvParsLastName && !cvParsEmail) {
            dispatch(showError({ msg: 'All fields are required.' }))
        } else {
            if (isParseDuplicate == 'duplicate') {
                dispatch(showError({ msg: 'Email already exists in CRM' }))
                return;
            }
            setSuccess(false);
            setLoading(true);

            let s3bucket = new AWS.S3({
                accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
                secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
                Bucket: process.env.REACT_APP_BUCKET_NAME
            });

            s3bucket.createBucket(function () {
                var params = {
                    Bucket: process.env.REACT_APP_BUCKET_NAME,
                    Key: Date.now() + '-' + Date.now() + '-' + fileName,
                    Body: file,
                    ContentType: file.type
                };
                s3bucket.upload(params, function (err, data) {
                    if (err) {
                        console.log(err)
                    } else {
                        setFileName(data.key)
                        //console.log(data.key)
                        // dispatch(addNewCandidate({ category: categoryList, c_country: country, c_city: city, nationality: nationlityList, visaStatus: visaStatus, native1: nativLanguage, fluent1: fluentLanguge, cv_source: cvSource, fileName: SYSTEM_CONSTANTS.awsS3.Url + data.key }))
                        // console.log(cvParsFirstName)
                        // console.log(cvParsLastName)
                        // console.log(cvParsEmail)
                        dispatch(addNewCandidate({ fileName: SYSTEM_CONSTANTS.awsS3.Url + data.key, first_name: cvParsFirstName, last_name: cvParsLastName, email1: cvParsEmail }))
                        setIsRefresh(false)
                    }
                });
            })

        }
    }

    // const getCatValue = (data) => {
    //     setCategoryList(data)
    // }
    // const getNationalityValue = (data) => {
    //     setNationlity(data)
    // }
    // const getVistaStatusValue = (data) => {
    //     setVisaStatus(data)
    // }
    // const getNativeValue = (data) => {
    //     setNativLanguage(data)
    // }
    // const getFluentValue = (data) => {
    //     setFluentLanguge(data)
    // }
    // const getCVSourceValue = (data) => {
    //     setCvSource(data)
    // }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = error => reject(error);
    });


    /** Start API Parsing */
    const [selectedFileApi, setSelectedFileApi] = useState(null);
    const onSelectFileByAPI = async (e) => {
        setLocalPath('')
        let tmppath = URL.createObjectURL(e.target.files[0]);

        const convertedToBase64data = await toBase64(e.target.files[0]);
        setBase64data(convertedToBase64data)

        setLocalPathApiParse(tmppath)
        setSelectedFileApi(e.target.files[0]);
        setApiParseButton(true)
    }

    const handleButtonClickApi = async (e) => {
        e.preventDefault()
        try {
            const d = new Date();
            const randomNumber = d.getTime()
            //console.log(base64data) //base64 decoded string
            //return false
            const decodeFileBase64 = (responseData) => {
                return decodeURIComponent(
                    atob(responseData)
                        .split("")
                        .map(function (c) {
                            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                        })
                        .join("")
                );
            };

            setSuccess(false);
            setLoading(true);

            let s3bucket = new AWS.S3({
                accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
                secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
                Bucket: process.env.REACT_APP_BUCKET_NAME
            });
            const dataKey = Date.now() + '-' + (selectedFileApi.name).replace(/ /g, "_");
            s3bucket.createBucket(function () {
                var params = {
                    Bucket: process.env.REACT_APP_BUCKET_NAME,
                    Key: dataKey,
                    Body: selectedFileApi,
                    ContentType: selectedFileApi.type
                };
                s3bucket.upload(params, function (err, data) {
                    if (err) {
                        console.log(err)
                    } else {
                        // var data = JSON.stringify({
                        //     "url": process.env.REACT_APP_INTERNAL_BASE_URL + "/" + dataKey,
                        //     "userkey": "YSLSDJ22U3E",
                        //     "version": "8.0.0",
                        //     "subuserid": "Deepak Kumar"
                        // });
                        var data = {
                            "index": {
                                "indexType": "Resume",
                                "indexKey": "YSLSDJ22U3E",
                                //  "subUserId": "unique subuser id"
                            },
                            "indexDetailText": true,
                            "data": {
                                "id": "dkg" + randomNumber,
                                "fileName": dataKey,
                                "fileContent": base64data
                            },
                            "customValue": {
                                "Shift": "Day",
                                "WorkOnRemote": "Yes",
                                "PhysicalyChallenged": true,
                                "OfficeSystems": [
                                    "Laptop",
                                    "Mouse"
                                ],
                                "ExtraActivities": [
                                    {
                                        "Dance": "Classic",
                                        "Sports": [
                                            "Cricket",
                                            "Chess",
                                            "PlayStation",
                                            "Ludo"
                                        ]
                                    }
                                ]
                            },
                            "commit": true,
                            "deDuplication": {
                                "checkDuplicate": true,
                                "fields": [
                                    "Email"
                                ],
                                "update": true
                            }
                        }

                        var config = {
                            method: 'post',
                            // url: 'https://dkglobalrecruitment7.rchilli.com/RChilliParser/Rchilli/parseResume',
                            url: 'https://searchengine.rchilli.com/RChilliSearchEngineAPI/RChilli/parseAndIndex',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            data: data
                        };

                        axios(config)
                            .then(function (response) {

                                // console.log(response['data']['doc'])
                                const checkStatus = response['data']['status'];
                                if (checkStatus == 200) {
                                    const responseData = response['data']['doc'];
                                    const getdata = decodeFileBase64(responseData)
                                    const jsonData = JSON.parse(getdata);
                                    const fullname = jsonData["ResumeParserData"]["Name"]
                                    const emailid = jsonData["ResumeParserData"]["Email"]
                                    const phoneNum = jsonData["ResumeParserData"]["PhoneNumber"]

                                    //  const responseData = response.data;
                                    dispatch(apiParsing({
                                        profileIndicator: 1,
                                        flag_type: 1,
                                        profileStatus: 1,
                                        jobId: 0,
                                        resource_team: -1,
                                        rec_admin: -1,
                                        rec_id: -1,
                                        am_id: -1,
                                        // name: (responseData.ResumeParserData) ? responseData.ResumeParserData.Name.FullName : '',
                                        // email1: (responseData.ResumeParserData && responseData.ResumeParserData.Email.length > 0) ? responseData.ResumeParserData.Email[0].EmailAddress : '',
                                        // phone1: (responseData.ResumeParserData && responseData.ResumeParserData.PhoneNumber.length > 0) ? responseData.ResumeParserData.PhoneNumber[0].Number : '',
                                        name: fullname.FullName,
                                        email1: (emailid.length > 0 ? emailid['0']['EmailAddress'] : ''),
                                        phone1: (phoneNum.length > 0 ? phoneNum['0']['Number'] : ''),
                                        caseStatus: 'New CV',
                                        case_progress: 'Not Assigned',
                                        case_stage: 'To Review',
                                        category: '',
                                        //fileUrl: process.env.REACT_APP_INTERNAL_BASE_URL + "/" + dataKey
                                        fileUrl: process.env.REACT_APP_INTERNAL_BASE_URL + "/" + jsonData["ResumeParserData"]["ResumeFileName"]
                                    }))
                                } else {
                                    console.log("errror")
                                    //{"error": {    "errorcode": 1021,    "errormsg": "File Conversion Error."}}
                                }

                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                });
            });
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }

    useEffect(() => {
        if (isAPIParseSuccess) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Candidate parse successfully' }))
            setApiParseButton(false)
            setSuccess(false);
            setLoading(false);
        }
        if (isManualParseSuccess) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Candidate parse successfully' }))
            setSuccess(false);
            setLoading(false);
        }
        if (isError) {
            dispatch(clearState())
            dispatch(showError({ msg: message }))
            setSuccess(false);
            setLoading(false);
        }
    }, [isAPIParseSuccess, isError])
    /** End API Parsing */

    /***************duplicate************* */
    const [show, setShow] = useState(false);
    const [isDuplicate, setIsDuplicate] = useState(true);
    const [list, setList] = useState([]);

    // const [loading, setLoading] = useState(false);
    // const [success, setSuccess] = useState(false);

    const [name, setName] = useState('');
    const [namePlaceholder, setNamePlaceholder] = useState('Enter candidate name');
    const [email, setEmail] = useState('');
    const [phone, setPhoneNo] = useState('');
    const [emailPlaceholder, setEmailPlaceholder] = useState('Enter candidate email');
    const [phonePlaceholder, setPhonePlaceholder] = useState('Enter candidate phone numder');

    // const handleClose = () => {
    //     setShow(false);
    // }

    const searchByName = async (text) => {
        setName(text)
        //setNamePlaceholder('')
        //setEmailPlaceholder('')
        setPhonePlaceholder('')
    }

    const searchByEmail = async (text) => {
        setEmail(text)
        //setNamePlaceholder('')
        setPhonePlaceholder('')
    }

    const searchByPhone = async (text) => {
        setPhoneNo(text)
        //setNamePlaceholder('')
        //setEmailPlaceholder('')

    }

    // const handleShow = () => {
    //     setIsDuplicate(true)
    //     setList([])
    //     setName('')
    //     setEmail('')
    //     setPhoneNo('')
    //     setLoading(false)
    //     setShow(true);
    // }

    const handleClear = (e) => {
        e.preventDefault()
        setName('')
        setEmail('')
        setPhoneNo('')
        //setNamePlaceholder('Enter candidate name')
        //setEmailPlaceholder('Enter candidate email')
        setPhonePlaceholder('Enter Candidate phone numder')
        setIsDuplicate(true)
        setList([])
        setLoading(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!name && !email && !phone) {
            dispatch(showError({ msg: 'Please fill either name, email or phone' }))
        } else {
            try {
                setLoading(true)
                await dispatch(apiduplicateCheck({ name, email, phone }))
            } catch (e) {
                dispatch(clearState())
                dispatch(showError({ msg: e }))
            }

        }
    }

    useEffect(() => {
        if (isDuplicateLoading && apiDuplicateList) {
            setLoading(false)
            dispatch(clearState())
            if (apiDuplicateList.length > 0) {
                setIsDuplicate(true)
                setList(apiDuplicateList)
            } else {
                setIsDuplicate(false)
                setList([])
            }
        }
    }, [isDuplicateLoading])


    /***************duplicate************* */

    return (
        <>
            <div className="container-fluid dk-candinateParseMain">
                <div className="row">
                    <div className="col-md-11 m-auto dk-addnewTabsMain">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="api_parsing">
                            <Nav variant="">
                                <Nav.Item>
                                    <Nav.Link eventKey="api_parsing">Duplicate Check</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_2">Api Parsing</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_1">Manual Parsing</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="tabs_1">
                                    <div className="dkcabparseFormCon dkg-manulparsing-mo-bottom dkg-manualpasring-tab">
                                        <div className="dkcabparseFormTitle"><h3>ADD NEW CANDIDATE BY MANUAL PARSING</h3></div>

                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 m-auto">
                                                <div className="dk-parseImgBrowserCon mt-4">
                                                    <div className="image-input">
                                                        {/* <input type="file" accept="image/*" id="imageInput" onChange={onSelectFile} /> */}
                                                        <input type="file" id="imageInput" onChange={onSelectFile} />
                                                        <label htmlFor="imageInput" className="image-button">
                                                            <small><i className="fa fa-upload"></i>
                                                                {
                                                                    (localPath == "") ? "Select Resume * " : "Select Resume * " + localPath
                                                                }

                                                            </small>
                                                        </label>
                                                        <img src="" className="image-preview" alt="resume" />
                                                        <span className="change-image">Choose different Resume</span>
                                                    </div>
                                                </div>
                                                {/* {
                                                        (localPath != "") ?

                                                            <div className="dk-parsesaveBtnCon">
                                                                <Submit className="btn dk-pasrsereSBtn" txt="Create Blank Record" loading={loading} success={success} onClick={handleButtonClick} />
                                                            </div>
                                                            : null
                                                    } */}
                                            </div>
                                            <div className="col-md-6 col-sm-12 mb-3">
                                                <input type="text" className='form-control manual-frmcontrol' name='firstName' id='firstName' placeholder='First Name' value={cvParsFirstName} onChange={(e) => setCvParsFirstName(e.target.value)} />
                                            </div>
                                            <div className="col-md-6 col-sm-12 mb-3">
                                                <input type="text" className='form-control manual-frmcontrol' name='lastName' id='lastName' placeholder='Last Name' value={cvParsLastName} onChange={(e) => setCvParsLastName(e.target.value)} />
                                            </div>
                                            {/* <div className="col-md-12 col-sm-12 mb-3">
                                                <input type="email" className='form-control manual-frmcontrol' name='email' id='email' placeholder='Email' value={cvParsEmail} onChange={(e) => setCvParsEmail(e.target.value)} />
                                            </div> */}
                                            <div className='col-md-12 col-12'>
                                                <div className='form-group d-flex align-items-start'>
                                                    <div className='w-100'>
                                                        <input type="email" className='form-control manual-frmcontrol' placeholder='Email' value={cvParsEmail} onChange={handleCheckDuplicateEmail} />
                                                        {
                                                            isParseDuplicate == 'duplicate' && (
                                                                <span className='error'>
                                                                    Email already exists in CRM
                                                                </span>
                                                            )
                                                        }
                                                        {
                                                            isParseDuplicate == 'no-duplicate' && (
                                                                <span className='success'>
                                                                    No duplicate found
                                                                </span>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='col-md-12 col-sm-12 dkg-manual-vertical-Con  m-auto'>
                                                    <div className="dk-parsesaveBtnCon">
                                                        <Submit onClick={manualButtonClick} className="btn dk-pasrsereSBtn" txt="Create Blank Record" />
                                                    </div>
                                                </div> */}
                                            {
                                                (localPath != "") ?

                                                    <div className="dk-parsesaveBtnCon">
                                                        <Submit className="btn dk-pasrsereSBtn" txt="Create Blank Record" loading={loading} success={success} onClick={cvparsingButtonClick} />
                                                    </div>
                                                    : null
                                            }
                                        </div>

                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="api_parsing">
                                    <div className='dkg-cvparsing-boxCon'>
                                        <div className="dk-duplicateFormCon">
                                            <form action="">
                                                <div className="row">
                                                    <div className="col-md-4 col-sm-12">
                                                        <div className="dk-duplicateFormGruop">
                                                            <label className='dkg-duplicate-title'>Enter Candidate Name</label>
                                                            <input type="text" className="form-control" placeholder="" value={name} onChange={(e) => searchByName(e.target.value)} />
                                                            {
                                                                (name != "") ?

                                                                    <span className='dkg-crosssbtnCon14'>
                                                                        {
                                                                            (list.length > 0) ?
                                                                                <FaTimesCircle onClick={handleClear} />
                                                                                :
                                                                                null
                                                                        }
                                                                    </span>
                                                                    : null
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-12">
                                                        <div className="dk-duplicateFormGruop df">
                                                            <label className='dkg-duplicate-title'>Enter candidate email</label>

                                                            <input type="text" className="form-control" placeholder="" value={email} onChange={(e) => searchByEmail(e.target.value)} />

                                                            {
                                                                (email != "") ?

                                                                    <span className='dkg-crosssbtnCon14'>
                                                                        {
                                                                            (list.length > 0) ?
                                                                                <FaTimesCircle onClick={handleClear} />
                                                                                :
                                                                                null
                                                                        }
                                                                    </span>
                                                                    : null
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-sm-12'>
                                                        <div className="dk-duplicateFormGruop">
                                                            <label className='dkg-duplicate-title'>Enter Candidate Phone Numder</label>
                                                            <input type="text" className="form-control" placeholder="" value={phone} onChange={(e) => searchByPhone(e.target.value)} />
                                                            {
                                                                (phone != "") ?

                                                                    <span className='dkg-crosssbtnCon14'>
                                                                        {
                                                                            (list.length > 0) ?
                                                                                <FaTimesCircle onClick={handleClear} />
                                                                                :
                                                                                null
                                                                        }
                                                                    </span>
                                                                    : null
                                                            }

                                                        </div>
                                                    </div>

                                                    {
                                                        (!isDuplicate) ?
                                                            <div className="col-12">
                                                                <div className="no-duplicate"><b>No Duplicate</b></div>
                                                            </div> : null
                                                    }
                                                    {
                                                        (list.length > 0) ? null :

                                                            <div className="col-12">
                                                                <div className="duplicateBtnCon">
                                                                    <Submit txt="Search" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="btn dk-srchBtn" />
                                                                    <button className='btn dk-srchBtn clear' onClick={handleClear}>Clear</button>
                                                                </div>
                                                            </div>

                                                    }
                                                </div>
                                            </form>
                                        </div>

                                        {
                                            (list.length > 0) ?
                                                <div className='dk-check-dupplicate-tableCon'>
                                                    <DataTable dataList={list} />
                                                </div>
                                                : null
                                        }

                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_2">
                                    <div className="dkcabparseFormCon">
                                        <div className="dkcabparseFormTitle">
                                            <h3>ADD NEW CANDIDATE BY API PARSING</h3>
                                        </div>
                                        <form>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="dk-parseImgBrowserCon hv-auto">
                                                        <div className="image-input">
                                                            <input type="file" id="imageInputAPI" onChange={onSelectFileByAPI} ref={fileInputAPI} />
                                                            <label htmlFor="imageInputAPI" className="image-button"><i className="fa fa-upload"></i>

                                                                {
                                                                    (localPathApiParse == "") ? "Select Resume * " : "Select Resume * " + localPathApiParse
                                                                }
                                                            </label>
                                                            <img src="" className="image-preview" alt="resume" />
                                                            <span className="change-image">Choose different Resume</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        (apiParseButton) ?
                                                            <div className="dk-parsesaveBtnCon mt-2">
                                                                <Submit className="btn dk-pasrsereSBtn" txt="PARSE RESUME" loading={loading} success={success} onClick={handleButtonClickApi} />
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddNew;