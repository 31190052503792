import React from 'react';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import './srchfilter.scss'

const SearchFilter = ({ hidepopup }) => {
    return (
        <>
            <Modal
                show={true}
                onHide={hidepopup}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="dk-mailShotSrchDialog"
            >
                <Modal.Header closeButton>
                    <Modal.Title>SEARCH MAILSHOTS</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-srchModalBody">
                    <div className="dk-searchTags">
                        <Link to="#">Search Tags <span><i className="fa fa-times" aria-hidden="true"></i></span></Link>
                    </div>
                    <div className="dk-searchBox">
                        <input type="text" className="form-control" placeholder="Search Keywords" id="" name="" />
                        <span><i className="fas fa-sync-alt"></i></span>
                    </div>
                    <div className="dk-searchcol">
                        <div className="d-flex justify-content-between">
                            <div className="dk-colTable mr-3">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th>Date</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <i className="far fa-calendar-alt"></i>
                                                    </div>
                                                    <input type="form-control txtfilterDate" />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="dk-colTable">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th>Time</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <i className="far fa-clock"></i>
                                                    </div>
                                                    <input type="form-control txtfilterDate" />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-main">
                                <div className="dk-col cursorPointer">
                                    <i className="fas fa-user-circle"></i>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="dk-searchDropdown">
                        <div className="row">
                            <div className="col-md-6">
                                <Dropdown className="dk-dropDownCols mb-3">
                                    <Dropdown.Toggle id="dropdown-custom-components">
                                        Category
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="searchCols">
                                            <input type="text" placeholder="Search" />
                                        </div>
                                        <Dropdown.Item className="item-1">
                                            Category 1
                                            <span className="badge">0</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="item-2">
                                            Category 2
                                            <span className="badge">0</span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="col-md-6">
                                <Dropdown className="dk-dropDownCols mb-3">
                                    <Dropdown.Toggle id="dropdown-custom-components">
                                        Company
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="searchCols">
                                            <input type="text" placeholder="Search" />
                                        </div>
                                        <Dropdown.Item className="bg-none">
                                            Company 1
                                            <span className="badge">0</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="bg-none">
                                            Company 2
                                            <span className="badge">0</span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="col-md-6">
                                <Dropdown className="dk-dropDownCols">
                                    <Dropdown.Toggle id="dropdown-custom-components">
                                        Language
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="searchCols">
                                            <input type="text" placeholder="Search" />
                                        </div>
                                        <Dropdown.Item className="bg-none">
                                            Language 1
                                            <span className="badge">0</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="bg-none">
                                            Language 2
                                            <span className="badge">0</span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="col-md-6">
                                <Dropdown className="dk-dropDownCols">
                                    <Dropdown.Toggle id="dropdown-custom-components">
                                        Role Type
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="searchCols">
                                            <input type="text" placeholder="Search" />
                                        </div>
                                        <Dropdown.Item className="bg-none">
                                            Role Type 1
                                            <span className="badge">0</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="bg-none">
                                            Role Type 2
                                            <span className="badge">0</span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>


                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SearchFilter;