import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'


const UserLogs = () => {
  return (
    <>
      <div className="dkg-jsacc-login-logs-con">
      <div className="dkg-myreferals-header">
          <div className="dkg-myreferals-headerleftCon">
            <div className="dk-mailshotsSearch mr-2">
              <div className="input-group">
                <input
                  className="form-control"
                  placeholder="Search ..."
                  value=""
                />
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary"
                      type="button"
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
              </div>
                <div className="dkg-crossicon-Con d-none">
                  <i
                    className="fas fa-times-circle"
                    data-type="search text"
                  ></i>
                </div>
            </div>
            {/* <div className="dkg-referal-addnew-btnCon">
                <button  className="dkg-referal-addnew-btn">
                   <i className="fas fa-plus"></i>
                </button>
            </div> */}
          </div>
          <div className="dkg-myreferals-centreCon">
            <div className="dkg-docs-title">
               Logs Table
            </div>
          </div>
          <div className="dkg-myreferals-rightCon">
            <Dropdown className="dkg-referal-status-drpdwn">
              <Dropdown.Toggle variant="" id="" className="dkg-referal-status-drpdwn-toggle">
              Account Logs 
              </Dropdown.Toggle>
              <Dropdown.Menu className="dkg-referal-status-drpdwn-menu">
                <Dropdown.Item href="#" className="dkg-referal-status-item">Account Logs 1
                 <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">Account Logs 2
                 <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-referal-status-item">Account Logs 3
                 <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="dkg-jsacc-login-logs-tableCon">
          <table className='table dkg-jsacc-login-logs-table'>
            <thead>
              <tr>
                <th>SNo</th>
                <th>Account ID</th>
                <th>Data & Time</th>
                <th>Name of Candidate</th>
                <th>Duration</th>
                <th>IP Address</th>
                <th>Browser</th>
                <th>Website</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>01</td>
                <td>53235</td>
                <td>18 Sep 24, 19:40</td>
                <td>Philip Brunotte</td>
                <td>00:02:34:10</td>
                <td>192.134.123.113</td>
                <td>https://dkglobalcrm.com/</td>
                <td>DKG - Goggle Chorme </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default UserLogs