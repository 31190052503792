import React from 'react';
import PipelinesLeftPanel from './leftpanel/PipelinesLeftPanel';
import './moduletraining.scss';

const Pipelines = () => {
    return (
        <>
            <div className="dk-moduleTraining-main">
                <div className="dk-moduleTraining-cols">
                    <PipelinesLeftPanel />
                    <div className="dk-moduleTraining-rightPanel">
                        <div className="title">Overview</div>
                        <div className="dk-moduleTraining-rightCols">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pipelines;
