import React, { useState } from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from "react-redux";
//import DefaultProfile from '../../../assets/images/default_profile_empty.png'

const UserListSalesBook = ({ id, columnId, onItemClick }) => {

    const { usersList,allRCSalesBookList } = useSelector(state => state.users)

    //const removeValFromIndex = [ 9]; //not to see users in pipeline, remove user from usersList
    //const indexSet = new Set(removeValFromIndex);
    //const newUsers = usersList.filter((value, i) => !indexSet.has(i));


    const selectValue = (value, color) => {       
        onItemClick(id, columnId, value, color);
    }

    return (
        <React.Fragment>
            {allRCSalesBookList.map((list, index) => (
                <Dropdown.Item  onClick={() => selectValue(list.id, list.profileImage)} key={columnId + index}>
                    <div className="tooltipMain">                       
                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + list.profileImage} className="filterImg" alt={list.firstName + " " + list.lastName} />
                        <div className="tooltipText">{list.firstName + " " + list.lastName}</div>
                    </div>
                </Dropdown.Item>
            ))}
           
        </React.Fragment>
    )
}
export default UserListSalesBook;