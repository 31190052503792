import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { showError, showSuccess } from "../../../../slice/utils/message/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import { addHeadings, clearState } from "../../../../slice/jobProfile/jobProfileSlice";
import SubmitButton from "../../../ui/submitButton";
import Dropdown from "./Dropdown";

const AddJobProfileModal = ({ handleClose, profileUserId }) => {
    const dispatch = useDispatch();
    const { dataList, isInsert } = useSelector(state => state.jobProfile);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [box1List, setBox1List] = useState([])

    const [name, setName] = useState('');
    const [userId] = useState(profileUserId);
    const [bgColor, setBgColor] = useState('');
    const [txtColor] = useState('#FFFFFF');
    const [boxId] = useState('2');
    const [parentId, setParentId] = useState('0');
    const [ddl1Name, setDdl1Name] = useState('Select')

    useEffect(() => {
        setBox1List(dataList.filter(items => items.boxId == 1))
    }, [])

    const handleButtonClick = async () => {
        if (parentId == '0') {
            dispatch(showError({ msg: 'Please select departments first.' }))
        } else {
            if (!name) {
                dispatch(showError({ msg: 'Please fill role in departments.' }))
            } else {
                setSuccess(false);
                setLoading(true);
                dispatch(addHeadings({ name, userId, bgColor, txtColor, boxId, parentId: '1' }))
            }
        }
    }

    const onSelectDepartment = (e) => {
        console.log(e.target.dataset.id)
        setParentId(e.target.dataset.id)
        setBgColor(e.target.dataset.bgcolor)
        setDdl1Name(e.target.dataset.name)
    }

    useEffect(() => {
        if (isInsert) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Role added suucessfully' }))
            setSuccess(true);
            setLoading(false);
            handleClose()
        }
    }, [isInsert])

    return (
        <React.Fragment>
            <Modal className="dk-editJobProfile" show={true} onHide={handleClose}>
                <Modal.Body>
                    <div className="dk-editJobProfile-container">
                        <div className="dk-editJobProfile-head" style={{ background: '#5e5e5e' }}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="title">Role in Departments</div>
                                <Modal.Header closeButton></Modal.Header>
                            </div>
                        </div>
                        <div className="dk-editJobProfile-editSec">
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Departments</td>
                                            <td>
                                                <div className="dk-colorDropdown">
                                                    <Dropdown list={box1List} onSelectDepartment={onSelectDepartment} name={ddl1Name} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <div className="dk-editSec-input" style={{ width: '80%', margin: 'auto' }}>
                                                    <input type="text" className="form-control" placeholder="Enter Heading..." onChange={(e) => setName(e.target.value)} />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <SubmitButton txt="SAVE" loading={loading} success={success} onClick={handleButtonClick} className="btnAddResponsbility mt-3" position="justify-content-center" />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default AddJobProfileModal;