import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const AssignPriorityDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkglobaladmin/admintraining/admins/assign-priority" className={`${pathname.match('/dkglobaladmin/admintraining/admins/assign-priority') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/assignpriority/indicators" className={`${pathname.match('/dkglobaladmin/admintraining/admins/assignpriority/indicators') ? 'active' : ''}`}>Indicators</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/assignpriority/situations" className={`${pathname.match('/dkglobaladmin/admintraining/admins/assignpriority/situations') ? 'active' : ''}`}>Situations</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/assignpriority/today-based" className={`${pathname.match('/dkglobaladmin/admintraining/admins/assignpriority/today-based') ? 'active' : ''}`}>Today Based</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/assignpriority/live-job-based" className={`${pathname.match('/dkglobaladmin/admintraining/admins/assignpriority/live-job-based') ? 'active' : ''}`}>Live Job Based</Link></li>

                    <li><Link to="/dkglobaladmin/admintraining/admins/assignpriority/language-based" className={`${pathname.match('/dkglobaladmin/admintraining/admins/assignpriority/language-based') ? 'active' : ''}`}>Language Based</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default AssignPriorityDetailLeftPanel;

