import React from "react";
import { Modal, Dropdown } from "react-bootstrap";
import SubmitButton from '../../../../../../../ui/submitButton'
import DatePicker from "./DatePicker";

const AddNewReferrals = ({ onHide }) => {

  return (
    <>
      <Modal
        show={true}
        onHide={onHide}
        className="dk-addInvoiceModal-main dkg-addnew-paymenttask-modal-21"
      >
        <Modal.Header
          closeButton
          className="dkg-addnew-payment-modalheader-21"
          style={{backgroundColor: "#1f394c" }}
        >
          <Modal.Title>ADD NEW REFERRALS</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-addnew-payment-modalbody-21">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label className="">Status</label>
                <div  className="dkg-payment-task-drop12">
                  <Dropdown>
                    <Dropdown.Toggle variant="" className="form-control">
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dkg-typecol-dropdown-menu">
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item"
                        style={{ backgroundColor: "#3a9469", color: "#fff" }} >
                        Open
                      </Dropdown.Item>
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item"
                        style={{ backgroundColor: "#d91010", color: "#fff" }} >
                        Closed
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label className="">Date</label>
                <div className="dkg-payment-task-drop12 dkg-invoice-addnewPopup dkg-hidelabel-text">
                  <DatePicker dateFormate="dd MMM yy" />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label className="">Case ID	</label>
                <div className="dkg-payment-task-drop12 dkg-banking-addnew-dropdown">
                  <input
                    type="text"
                    className="form-control dkg-addnew-banking-input"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label htmlFor="">Name of Candidate	</label>
                <div className="dkg-payment-task-drop12">
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control dkg-addnew-banking-input"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>	
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label className="">Date of Joining	</label>
                <div className="dkg-payment-task-drop12 dkg-invoice-addnewPopup dkg-hidelabel-text">
                  <DatePicker dateFormate="dd MMM yy" />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label htmlFor="">Resignation</label>
                <div className="dkg-payment-task-drop12">
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control dkg-addnew-banking-input"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label htmlFor="">Job ID</label>
                <div className="dkg-payment-task-drop12">
                  <input
                    type="text"
                    name=""
                    id=""
                    className="form-control dkg-addnew-banking-input"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label className="">Company</label>
                <div  className="dkg-payment-task-drop12">
                  <Dropdown>
                    <Dropdown.Toggle variant="" className="form-control">
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dkg-typecol-dropdown-menu">
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item">
                          TP Greece
                      </Dropdown.Item> 
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item" >
                         TTEC Greece
                      </Dropdown.Item>
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item" >
                         TM Romania
                      </Dropdown.Item>
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item" >
                         Tech M Lisbon
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label className="">Language</label>
                <div  className="dkg-payment-task-drop12">
                  <Dropdown>
                    <Dropdown.Toggle variant="" className="form-control">
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dkg-typecol-dropdown-menu">
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item">
                          TP Greece
                      </Dropdown.Item> 
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item" >
                         TTEC Greece
                      </Dropdown.Item>
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item" >
                         TM Romania
                      </Dropdown.Item>
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item" >
                         Tech M Lisbon
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label className="">Project</label>
                <div  className="dkg-payment-task-drop12">
                  <Dropdown>
                    <Dropdown.Toggle variant="" className="form-control">
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dkg-typecol-dropdown-menu">
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item">
                          TP Greece
                      </Dropdown.Item> 
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item" >
                         TTEC Greece
                      </Dropdown.Item>
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item" >
                         TM Romania
                      </Dropdown.Item>
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item" >
                         Tech M Lisbon
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label className="">Role Type</label>
                <div  className="dkg-payment-task-drop12">
                  <Dropdown>
                    <Dropdown.Toggle variant="" className="form-control">
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dkg-typecol-dropdown-menu">
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item">
                          TP Greece
                      </Dropdown.Item> 
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item" >
                         TTEC Greece
                      </Dropdown.Item>
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item" >
                         TM Romania
                      </Dropdown.Item>
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item" >
                         Tech M Lisbon
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label className="">Country</label>
                <div  className="dkg-payment-task-drop12">
                  <Dropdown>
                    <Dropdown.Toggle variant="" className="form-control">
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dkg-typecol-dropdown-menu">
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item">
                          TP Greece
                      </Dropdown.Item> 
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item" >
                         TTEC Greece
                      </Dropdown.Item>
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item" >
                         TM Romania
                      </Dropdown.Item>
                      <Dropdown.Item href="#"
                        className="dkg-typecol-dropdown-item" >
                         Tech M Lisbon
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label htmlFor="">Invoices</label>
                <div className="dkg-payment-task-drop12">
                  <input
                    type="file"
                    name=""
                    id=""
                    className="form-control dkg-addnew-banking-input"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label htmlFor="">Payments</label>
                <div className="dkg-payment-task-drop12">
                  <input
                    type="file"
                    name=""
                    id=""
                    className="form-control dkg-addnew-banking-input"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group">
                <label htmlFor="">Agreements</label>
                <div className="dkg-payment-task-drop12">
                  <input
                   type="file"
                    name=""
                    id=""
                    className="form-control dkg-addnew-banking-textarea"
                    defaultValue=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-12">
              <div className="form-group justify-content-center mt-2">
                <SubmitButton
                  txt="Add"
                  className="dkaddBtn dkg-referral-addnew-btn"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewReferrals;
