import React from 'react';
import RecuitersLeftPanel from './leftpanel/RecuitersLeftPanel'
// import TasksLeftPanel from './leftpanel/TasksLeftPanel';
import ReportQualified from '../../sendouts/ReportQualified'

const QualifiedKpi = () => {
    return (
        <>
            <div className="dkg-recuiterskpiMainCon container-fluid">
                <div className='row'>
                    <div className='col-md-12  col-12 dkg-recuiters-tabsCon'>
                        <RecuitersLeftPanel />
                    </div>
                    <div className='col-md-12 col-12 dkg-recuiters-tableCon-234'>
                        <div className="dk-sendouts-rightPanel">
                            <ReportQualified />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='dk-sendouts-containerMain dk-jobSpecsContainer-main dkg-new-recuiterskpi-pagecon89'>
                <TasksLeftPanel />
                <div className='dk-sendouts-rightPanel'>
                    <div className='row dkg-new-recuiterskpi-main89'>
                        <div className='col-md-12 dkg-new-recuiterskpi-con89'>
                            <ReportQualified />
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default QualifiedKpi;
