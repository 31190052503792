import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import "./responsiveMenu.scss";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         '& > *': {
//             margin: theme.spacing(1),
//         },
//     },
// }));

const CandidateSubmenu = (props) => {
    //const classes = useStyles();
    const pathname = window.location.pathname;
    // const segment1 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    //const segment4 = pathname.split("/")[4];

    const [toggleMenu, setToggleMenu] = useState(false);
    useEffect(() => {
        setToggleMenu(false)
    }, [pathname])

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setToggleMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);



    return (
        <React.Fragment>
            <div className="dk-subMenuMain res-sub-menu" ref={ref}>
                <IconButton aria-label="delete" className="res-sub-btn" onClick={() => setToggleMenu(!toggleMenu)}>
                    <i className="fas fa-bars"></i>
                </IconButton>
                <Route>
                    <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
                        {/* <li><Link to="/recruitment/candidate/summary" className={`${(segment3 == 'summary') ? 'active' : ''}`}>Summary</Link></li> */}
                        {/* <li><Link to="/recruitment/candidate/pipelines" className={`${(segment3 == 'pipelines') ? 'active' : ''}`}>Pipelines</Link></li> */}
                        <li><Link to="/recruitment/candidate/workflow" className={`${(segment3 == 'workflow') ? 'active' : ''}`}>Workflow</Link></li>
                        <li><Link to="/recruitment/candidate/duplicate" className={`${(segment3 == 'duplicate') ? 'active' : ''}`}>Duplicate</Link></li>
                        {/* <li><Link to="/recruitment/candidate/add-new" className={`${(segment3 == 'add-new') ? 'active' : ''}`}>Add New</Link></li> */}
                        <li><Link to="/recruitment/candidate/backend-values" className={`${(segment3 == 'backend-values') ? 'active' : ''}`}>Backend Values</Link></li>
                        {/*<li><Link to="/recruitment/candidate/archived" className={`${(segment3 == 'archived') ? 'active' : ''}`}>Archived</Link></li>
                        <li><Link to="/recruitment/candidate/database/summary" className={`${(segment3 == 'candidate' && segment4 == 'database' || segment4 == 'summary') ? 'active' : ''}`}>DB Summary</Link></li>
                        <li><Link to="/recruitment/candidate/database/search-cvs" className={`${(segment3 == 'candidate' && segment4 == 'database' || segment4 == 'search-cvs') ? 'active' : ''}`}>Search CV's</Link></li>

                        <li><Link to="/recruitment/candidate/projects/saved-projects" className={`${(segment3 == 'candidate' && segment4 == 'projects' || segment4 == 'saved-projects') ? 'active' : ''}`}>Projects</Link></li>

                        <li><Link to="/recruitment/candidate/database/mailshots" className={`${(segment3 == 'candidate' && segment4 == 'database' || segment4 == 'mailshots') ? 'active' : ''}`}>Mailshots</Link></li>
                         */}
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    )
}

export default CandidateSubmenu;
