import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData } from "../../services";

export const getCompanyList = createAsyncThunk(
  "faqsData/allCompany",
  async (thunkAPI) => {
    const res = await GetData(true, "/faqs-company/");
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getCategory = createAsyncThunk(
  "faqsData/categoryList",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/faqs-category/list/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getOnClickQuesAns = createAsyncThunk(
  "faqsData/onclick-default/categoryList",
  async ({ compid }, thunkAPI) => {
    const res = await GetData(true, "/faqs-question-answer/tab/list/" + compid);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getdefaultCategory = createAsyncThunk(
  "faqsData/default/categoryList",
  async ({ compid }, thunkAPI) => {
    const res = await GetData(true, "/faqs-category/default/catlist/" + compid);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getQuesAnsByCompanyId = createAsyncThunk(
  "faqsData/get-by-company-ques-ans",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/faqs-question-answer/listByCompid/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getQueAns = createAsyncThunk(
  "faqsData/get-ques-ans",
  async ({ id }, thunkAPI) => {
    const res = await GetData(true, "/faqs-question-answer/list/" + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getdefaultQuesAns = createAsyncThunk(
  "faqsData/default/get-ques-ans",
  async ({ compid }, thunkAPI) => {
    const res = await GetData(true, "/faqs-question-answer/default/list/" + compid);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

const usersSlice = createSlice({
  name: "faqSlice",
  initialState: {
    companiesList: [],
    categoryList: [],
    questionAnsList: [],
    isSuccess: false,
    isTabshow: false,
    isLoading: false,
    isSubLoading: false,
    isError: false,
    isData: false,
    iscat: false,
    isQuesAns: false,
  },
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isQuesAns = false;
      state.iscat = false;
      state.isSubCatInsert = false;
      state.isSuccess = false;
      state.isTabshow = false;
      state.isError = false;
      state.isData = false;
    },
  },
  extraReducers: {
    [getCompanyList.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [getCompanyList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.companiesList = payload.result;
    },
    [getCompanyList.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },

    [getOnClickQuesAns.pending]: (state) => {
      state.isError = false;
      state.isQuesAns = false;
    },
    [getOnClickQuesAns.fulfilled]: (state, { payload }) => {
      state.isQuesAns = false;
      state.questionAnsList = payload.result;
    },
    [getOnClickQuesAns.rejected]: (state, { payload }) => {
      state.isQuesAns = false;
      state.isError = true;

      state.isSuccess = false;
    },

    [getdefaultCategory.pending]: (state) => {
      state.isError = false;
    },
    [getdefaultCategory.fulfilled]: (state, { payload }) => {
      state.iscat = false;
      state.categoryList = payload.result;
    },
    [getdefaultCategory.rejected]: (state, { payload }) => {
      state.isError = true;

      state.isSuccess = false;
    },

    [getCategory.pending]: (state) => {
      state.iscat = true;
      state.isError = false;
    },
    [getCategory.fulfilled]: (state, { payload }) => {
      state.iscat = false;
      state.categoryList = payload.result;
    },
    [getCategory.rejected]: (state, { payload }) => {
      state.isError = true;
      state.iscat = false;
    },
    [getdefaultQuesAns.pending]: (state) => {
      state.isSuccess = false;
      //state.isLoading = true;
      state.isError = false;
    },
    [getdefaultQuesAns.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      //state.isLoading = false;
      state.questionAnsList = payload.result;
    },
    [getdefaultQuesAns.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      //state.isSuccess = false;
    },

    [getQueAns.pending]: (state) => {
      state.isSuccess = false;
      state.isTabshow = false;
      state.isError = false;
    },
    [getQueAns.fulfilled]: (state, { payload }) => {
      state.isTabshow = true;
      state.isSuccess = true;
      state.questionAnsList = payload.result;
    },
    [getQueAns.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isTabshow = false;
      state.isSuccess = false;
    },

    [getQuesAnsByCompanyId.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [getQuesAnsByCompanyId.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.questionAnsList = payload.result;
    },
    [getQuesAnsByCompanyId.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});

export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
