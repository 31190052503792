import React, { useState } from 'react';
import { Link } from "react-router-dom";
import DkgCrmDetailLeftPanel from "./leftpanel/DkgCrmDetailLeftPanel";
import TitleIcon from '../../../../assets/images/masterbox.png';

const CommonMasterbox = () => {
    return (
        <React.Fragment>
            <div className="dk-backBtnMain">
                <Link to="/training/commontraining/common-training"><i className="fas fa-arrow-left"></i> Go Back</Link>
                <h3 className="dk-detailTitle mt-0 pt-0 pb-0">
                    <img src={TitleIcon} className="img-fluid" alt="" />
                    DKG CRM
                </h3>
            </div>

            <div className="dk-trainingDetailMain mt-3">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <DkgCrmDetailLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                <h3 className="title">Overview</h3>
                                <div className="dk-trainingInformation-cols">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid similique dolore laboriosam reprehenderit sapiente at iusto? Soluta tempora eveniet suscipit quae aliquid provident rem exercitationem ipsa eos aspernatur? Necessitatibus, optio?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default CommonMasterbox;

