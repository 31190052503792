import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './accountingmenu.scss';

const KnowleadgeBaseMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar">
                <Route>
                    <ul>
                        <li><Link to="/accounting/summary" className={`${(segment2 === 'summary') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="/accounting/alerts" className={`${(segment2 === 'alerts') ? 'gray-active' : ''}`}>Alerts</Link></li>
                        <li><Link to="/accounting/clients" className={`${(segment2 === 'clients') ? 'gray-active' : ''}`}>Clients</Link></li>
                        <li><Link to="/accounting/invoices" className={`${(segment2 === 'invoices') ? 'gray-active' : ''}`}>Invoices</Link></li>
                        <li><Link to="/accounting/incomes" className={(segment2 === "incomes") ? 'gray-active' : ''}>Incomes</Link></li>
                        <li><Link to="/accounting/expense" className={(segment2 === "expense") ? 'gray-active' : ''}>Expense</Link></li>
                        <li><Link to="/accounting/vat-file" className={(segment2 === "vat-file") ? 'gray-active' : ''}>VAT Files</Link></li>
                        <li><Link to="/accounting/bank-state" className={(segment2 === "bank-state") ? 'gray-active' : ''}>Bank State</Link></li>
                        <li><Link to="/accounting/information" className={(segment2 === "information") ? 'gray-active' : ''}>Information</Link></li>
                        <li><Link to="/accounting/Settings" className={(segment2 === "settings") ? 'gray-active' : ''}>Settings</Link></li>
                        <li><Link to="/accounting/training" className={(segment2 === "training") ? 'gray-active' : ''}>Training</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default KnowleadgeBaseMenu;