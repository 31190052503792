import React from 'react';
import { Link } from "react-router-dom";
import SelectItem from '../../../../ui/SelectPicker/DummySelectPiker'

const Contacts = () => {
    const department = [
        { key: 'department_1', value: 'department_1', text: 'Department 1' },
        { key: 'department_2', value: 'department_2', text: 'Department 2' },
        { key: 'department_3', value: 'department_3', text: 'Department 3' },
        { key: 'department_4', value: 'department_4', text: 'Department 4' },
        { key: 'department_5', value: 'department_5', text: 'Department 5' }
    ]
    return (
        <React.Fragment>
            <div className="row mt-4">
                <div className="col-md-4">
                    <div className="dkclietopLeft d-flex">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search by Text" />
                            <div className="input-group-append">
                                <button className="btn btn-secondary legitRipple" type="button">
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <Link id="" to="#" className=" dk-topTbleIcon ml-2">
                            <i className="fa fa-plus" aria-hidden="true"></i>
                        </Link>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="dk-cliDetTabPaneBlkCon">
                        <div className="dk-cliDetTitle">
                            <h2 className="mb-0 mt-2">Client Contacts</h2>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 dk-CliContactRight ">
                    <div className="dkclietopRight">
                        <Link href="#" className=" dk-topTbleIcon mr-2">
                            <i className="fas fa-arrows-alt"></i>
                        </Link>
                    </div>
                    <div className="dk-CliDetSlectFilter pr-0">
                        <SelectItem options={department} placeholder="Department" />
                    </div>
                </div>
                <div className="col-md-12 mt-2 col-sm-12">
                    <div className="dk-cliDetailTableCon table-responsive">
                        <table className="table table-bordered dk-cliDetialTable">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>AM</th>
                                    <th>Department</th>
                                    <th>Job Title</th>
                                    <th>Name of Contact</th>
                                    <th>Email Address</th>
                                    <th>Phone No.</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>01</td>
                                    <td>
                                        <div className="dk-cliTableImgCrle">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="person-imageFlex" alt="Manager" />
                                        </div>
                                    </td>
                                    <td>IT Department</td>
                                    <td>Software Developer</td>
                                    <td>Name of Contact 1</td>
                                    <td>deepak123@gmail.com</td>
                                    <td>+351 911 775 132</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" to="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>02</td>
                                    <td>
                                        <div className="dk-cliTableImgCrle">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="person-imageFlex" alt="Manager" />
                                        </div>
                                    </td>
                                    <td>IT Department</td>
                                    <td>Software Developer</td>
                                    <td>Name of Contact 1</td>
                                    <td>deepak123@gmail.com</td>
                                    <td>+351 911 775 132</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" to="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>03</td>
                                    <td>
                                        <div className="dk-cliTableImgCrle">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="person-imageFlex" alt="Manager" />
                                        </div>
                                    </td>
                                    <td>IT Department</td>
                                    <td>Software Developer</td>
                                    <td>Name of Contact 1</td>
                                    <td>deepak123@gmail.com</td>
                                    <td>+351 911 775 132</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" to="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>04</td>
                                    <td>
                                        <div className="dk-cliTableImgCrle">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="person-imageFlex" alt="Manager" />
                                        </div>
                                    </td>
                                    <td>IT Department</td>
                                    <td>Software Developer</td>
                                    <td>Name of Contact 1</td>
                                    <td>deepak123@gmail.com</td>
                                    <td>+351 911 775 132</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" to="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>05</td>
                                    <td>
                                        <div className="dk-cliTableImgCrle">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="person-imageFlex" alt="Manager" />
                                        </div>
                                    </td>
                                    <td>IT Department</td>
                                    <td>Software Developer</td>
                                    <td>Name of Contact 1</td>
                                    <td>deepak123@gmail.com</td>
                                    <td>+351 911 775 132</td>
                                    <td>
                                        <div className="dk-CliDetailAction">
                                            <Link className="dk-edit-btn mr-2" to="#">
                                                <i className="fa fa-edit " aria-hidden="true"></i>
                                            </Link>
                                            <Link className="dk-delete-btn" to="#">
                                                <i className="fa fa-trash " aria-hidden="true"></i>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Contacts;
