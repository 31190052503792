import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PostFormData, PostData, GetData } from "../../services";
import { useSelector } from "react-redux";

export const sendMail = createAsyncThunk(
  "mailbox/send-mail",
  async (formData, thunkAPI) => {
    // const res = await PostFormData(true, "/mailbox/send", formData);
    const res = await PostFormData(true, "/send-mail", formData);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const sendBulkMail = createAsyncThunk(
  "mailbox/send/build/mail",
  async (formData, thunkAPI) => {
    // const res = await PostFormData(true, "/mailbox/send", formData);
    const res = await PostFormData(true, "/send/bulk/mail", formData); 
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
// export const sendMail = createAsyncThunk(
//   "mailbox/send-mail",
//   async ({ from, to, cc, bcc, subject, body }, thunkAPI) => {
//     const res = await PostData(true, "/smtp-mailgun", { from, to, cc, bcc, subject, body });
//     if (res.status === 200) {
//       return res.payload;
//     } else {
//       return thunkAPI.rejectWithValue();
//     }
//   }
// );

export const forwardMail = createAsyncThunk(
  "mailbox/forward-mail",
  async ({ user_ids, mail_id }, thunkAPI) => {
    const res = await PostFormData(true, "/forward-mail/" + mail_id, {
      user_ids : user_ids
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const sentList = createAsyncThunk(
  "mailbox/list/sent",
  async ({ }, thunkAPI) => {
    const res = await GetData(true, "/mailbox/sent");
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const mailDetails = createAsyncThunk(
  "mailbox/details",
  async ({ id, type }, thunkAPI) => {
    const res = type ? await GetData(true, '/mailbox/' + id + '/' + type) : await GetData(true, '/mailbox/' + id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getUserMailboxSignature = createAsyncThunk(
  "mailbox/details/users/signature",
  async ({ id, type }, thunkAPI) => {
    const res = await GetData(true, "/mail/user/signature/" + id + '/' + type);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
)

export const saveUserSignature = createAsyncThunk(
  "mailbox/details/users/signature/save",
  async (payload, thunkAPI) => {
    const res = await PostData(true, "/mail/user/signature", payload);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
)

export const internalMailBoxEmailCategory = createAsyncThunk(
  "internal-mailbox/internal-mails/get-categories",
  async ({can_id, role }, thunkAPI) => {
  const userRole = role == 'Super Admin' ? 'admin' : role
    const res = await GetData(true, "/internal-mails/get-categories/"+userRole+"/"+can_id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const sendInternalMail = createAsyncThunk(
  "mailbox/internal-mails/send-mail",
  async (formData, thunkAPI) => {
    const res = await PostData(true, "/internal-mails/send-mail", formData);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getInternalMail = createAsyncThunk(
  "mailbox/internal-mails/get-mailbox/role/type/candidate_id",
  async ({can_id, role, type, cat_id , filter_cat = "", search = "" }, thunkAPI) => {
    const userRole = role == 'Super Admin' ? 'admin' : role
    const res = await GetData(true, "/internal-mails/get-mailbox/"+userRole+"/"+type+"/"+can_id+"/"+(cat_id != null ? cat_id : '')+"?category_id="+filter_cat+"&search="+search);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getInternalMailCount = createAsyncThunk(
  "mailbox/internal-mails/get-mailbox-count/admin/17272",
  async ({can_id, role }, thunkAPI) => {
    const userRole = role == 'Super Admin' ? 'admin' : role
    const res = await GetData(true, "/internal-mails/get-mailbox-count/"+userRole+"/"+can_id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSingleInternalMail = createAsyncThunk(
  "mailbox/internal-mails/get-mail",
  async ({can_id, role, mail_id }, thunkAPI) => {
    const userRole = role == 'Super Admin' ? 'admin' : role
    const res = await GetData(true, "/internal-mails/get-mail/"+userRole+"/"+can_id+"/"+mail_id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getLogSingleInternalMail = createAsyncThunk(
  "/internal-mails/get/mailbox/logs",
  async ({mail_id }, thunkAPI) => {
    const res = await GetData(true, "/internal-mails/get/mailbox/logs/"+mail_id);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateInternalMailStatus = createAsyncThunk(
  "mailbox/internal-mails/update-status",
  async (data, thunkAPI) => {
    const res = await PostData(true, "/internal-mails/update-status", data);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteInternalMail = createAsyncThunk(
  "mailbox/internal-mails/delete-mail",
  async (data, thunkAPI) => {
    const res = await PostData(true, "/internal-mails/delete-mail", data);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getInternalMailNotification = createAsyncThunk(
  "mailbox/internal-mails/notification",
  async (thunkAPI) => {
    const res = await GetData(true, "/internal-mails/notification");
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

// fetching all system-users
export const getSystemUser = createAsyncThunk(
  "/mailbox/system-user",
  async (thunkAPI) => {
    const res = await GetData(true, "/mail/list/system-user");
    if (res.status === 200) {
      return res.payload;
    }else{
      return thunkAPI.rejectWithValue();
    }
  }
)

export const deleteInternalMailNotification = createAsyncThunk(
  "mailbox/internal-mails/delete-notification",
  async (data, thunkAPI) => {
    const res = await PostData(true, "/internal-mails/delete-notification", data);
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);



const mailboxSlice = createSlice({
  name: "mailbox",
  initialState: {
    listSent: [],
    details: "",
    isLoading: false,
    isDetailsLoading: false,
    isError: false,
    isSuccess: false,
    isBtnSuccess: false,
    message: "",
    signature: null,
    postSignature: {
      isLoading: false,
      isError: false,
      message: null,
      isSuccess: false,
    },
    isInternalEmailCategory: false,
    internalEmailCategoryList: [],
    internalMails: [],
    internalMailsCount: [],
    singleInternalMail: null,
    isUpdateStatus: false,
    isMailDelete: false,
    isSingleMail: false,
    updateMailMessage: null,
    deleteMailMessage: null,
    internalMailNotification: [],
    isInternalMailNotification: false,
    internalMailNotificationMessage : null,
    //new slices
    getSystemUsersData: [],
    getSystemUserLoading: false,
    getSystemUserError: null,
    logDetail: null,
    isLogDetailLoading: false
  },
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.isBtnSuccess = false;
      state.message = "";
      state.signature = null;
      state.isInternalEmailCategory = false;
      state.isUpdateStatus = false;
      state.updateMailMessage= null;
      state.isMailDelete = false;
      state.deleteMailMessage= null;
      state.internalMailNotification = [];
      state.isInternalMailNotification = false;
      state.internalMailNotificationMessage = null
      // state.isSingleMail = false;
    },
    clearMailDetail: (state) => {
      state.details = null;
    },
    clearSignature: (state) => {
      state.signature = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(sendMail.pending, (state) => {
      state.isLoading = true;
      state.isBtnSuccess = false;
      state.isError = false;
    });
    builder.addCase(sendMail.fulfilled, (state, { payload }) => {
      state.isBtnSuccess = true;
      state.isLoading = false;
      state.message = payload.message;
      state.isError = false;
    });
    builder.addCase(sendMail.rejected, (state, action) => {
      state.isBtnSuccess = false;
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(sendBulkMail.pending, (state) => {
      state.isLoading = true;
      state.isBtnSuccess = false;
      state.isError = false;
    });
    builder.addCase(sendBulkMail.fulfilled, (state, { payload }) => {
      state.isBtnSuccess = true;
      state.isLoading = false;
      state.message = payload.message;
      state.isError = false;
    });
    builder.addCase(sendBulkMail.rejected, (state, action) => {
      state.isBtnSuccess = false;
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(mailDetails.pending, (state) => {
      state.isDetailsLoading = true;
    });
    builder.addCase(mailDetails.fulfilled, (state, { payload }) => {
      state.details = payload.result;
      state.isSuccess = true;
      state.isDetailsLoading = false;
    });
    builder.addCase(mailDetails.rejected, (state, action) => {
      state.isDetailsLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(sentList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sentList.fulfilled, (state, { payload }) => {
      state.listSent = payload.result;
      state.isSuccess = true;
      state.isLoading = false;
    });
    builder.addCase(sentList.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(getUserMailboxSignature.pending, (state, { payload }) => {
      state.signature = null
    });
    builder.addCase(getUserMailboxSignature.fulfilled, (state, { payload }) => {
      state.signature = payload.result.signature || null
    });
    builder.addCase(saveUserSignature.pending, (state) => {
      state.postSignature.isLoading = true;
    });
    builder.addCase(saveUserSignature.fulfilled, (state, { payload }) => {
      state.postSignature.message = payload.message;
      state.postSignature.isSuccess = true;
      state.postSignature.isLoading = false;
    });
    builder.addCase(saveUserSignature.rejected, (state, action) => {
      state.postSignature.isLoading = false;
      state.postSignature.isSuccess = false;
      state.postSignature.isError = true;
      state.postSignature.message = "Something went wrong";
    });

    builder.addCase(
      internalMailBoxEmailCategory.pending, (state) => {
          state.isInternalEmailCategory = false;
      });
    builder.addCase(
        internalMailBoxEmailCategory.fulfilled, (state, { payload }) => {
            state.isInternalEmailCategory = true;
            state.internalEmailCategoryList = payload.data
        });
    builder.addCase(
        internalMailBoxEmailCategory.rejected, (state, action) => {
            state.isInternalEmailCategory = true;
            state.isError = true;
            state.message = 'Something went wrong';
        });

    builder.addCase(sendInternalMail.pending, (state) => {
      state.isLoading = true;
      state.isBtnSuccess = false;
      state.isError = false;
    });
    builder.addCase(sendInternalMail.fulfilled, (state, { payload }) => {
      state.isBtnSuccess = true;
      state.isLoading = false;
      state.message = payload.message;
      state.isError = false;
    });
    builder.addCase(sendInternalMail.rejected, (state, action) => {
      state.isBtnSuccess = false;
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(getInternalMail.pending, (state) => {
      state.internalMails = [];
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(getInternalMail.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.internalMails = payload.data;
      state.isError = false;
    });
    builder.addCase(getInternalMail.rejected, (state, action) => {
      state.internalMails = [];
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(getInternalMailCount.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(getInternalMailCount.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.internalMailsCount = payload.data;
      state.isError = false;
    });
    builder.addCase(getInternalMailCount.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(getSingleInternalMail.pending, (state) => {
      state.isSingleMail = true;
      state.isError = false;
    });
    builder.addCase(getSingleInternalMail.fulfilled, (state, { payload }) => {
      state.isSingleMail = false;
      state.singleInternalMail = payload.data;
      state.isError = false;
    });
    builder.addCase(getSingleInternalMail.rejected, (state, action) => {
      state.isSingleMail = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(getLogSingleInternalMail.pending, (state) => {
      state.isLogDetailLoading = true;
    });

    builder.addCase(getLogSingleInternalMail.fulfilled, (state, { payload }) => {
      state.logDetail = payload.data;
      state.isLogDetailLoading = false;
    });

    builder.addCase(getLogSingleInternalMail.rejected, (state, action) => {
      state.isLogDetailLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(updateInternalMailStatus.pending, (state) => {
      state.isUpdateStatus = true;
    });
    builder.addCase(updateInternalMailStatus.fulfilled, (state, { payload }) => {
      state.isUpdateStatus = false;
      state.updateMailMessage = payload.message;
    });
    builder.addCase(updateInternalMailStatus.rejected, (state, action) => {
      state.isUpdateStatus = false;
      state.updateMailMessage = "Something went wrong";
    });

    builder.addCase(deleteInternalMail.pending, (state) => {
      state.isUpdateStatus = true;
    });
    builder.addCase(deleteInternalMail.fulfilled, (state, { payload }) => {
      state.isUpdateStatus = false;
      state.updateMailMessage = payload.message;
    });
    builder.addCase(deleteInternalMail.rejected, (state, action) => {
      state.isUpdateStatus = false;
      state.updateMailMessage = "Something went wrong";
    });

    builder.addCase(getInternalMailNotification.pending, (state) => {
      state.isInternalMailNotification = true;
    });
    builder.addCase(getInternalMailNotification.fulfilled, (state, { payload }) => {
      state.isInternalMailNotification = false;
      state.internalMailNotification = payload.data;
      state.isError = false;
    });
    builder.addCase(getInternalMailNotification.rejected, (state, action) => {
      state.isInternalMailNotification = false;
      state.isError = false;
      state.message = "Something went wrong";
    });

    builder.addCase(deleteInternalMailNotification.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteInternalMailNotification.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.internalMailNotificationMessage = payload.message;
      state.isError = false;
    });
    builder.addCase(deleteInternalMailNotification.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "Something went wrong";
    });

    // new slices

    builder.addCase(getSystemUser.pending, (state) => {
      state.getSystemUserLoading = true;
      state.getSystemUserError = null;
    })

    builder.addCase(getSystemUser.fulfilled, (state, {payload}) => {
      state.getSystemUserLoading = true;
      state.getSystemUsersData = payload.result
    })

    builder.addCase(getSystemUser.rejected, (state) => {
      state.getSystemUserLoading = false;
      state.getSystemUserError = true;
    })

    builder.addCase(forwardMail.pending, (state) => {
      state.isLoading = true;
      state.isBtnSuccess = false;
      state.isError = false;
    })

    builder.addCase(forwardMail.fulfilled, (state, {payload}) => {
      state.isBtnSuccess = true;
      state.isLoading = false;
      state.message = payload.message;
      state.isError = false;
    })

    builder.addCase(forwardMail.rejected, (state) => {
      state.isBtnSuccess = false;
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    })
  },
});
export const { clearState, clearMailDetail, clearSignature } = mailboxSlice.actions;
export default mailboxSlice.reducer;
