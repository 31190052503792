import React from 'react';
import { Link } from "react-router-dom";

const GetStartedLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="dk-moduleTraining-leftPanel">
                <ul>
                    <li><Link to="/training/get-started/overview" className={`${pathname.match('/training/get-started/overview') ? 'active' : ''}`}>Overview</Link></li>
                    <li><Link to="/training/get-started/stpe-1" className={`${pathname.match('/training/get-started/stpe-1') ? 'active' : ''}`}>Step 1</Link></li>
                    <li><Link to="/training/get-started/stpe-2" className={`${pathname.match('/training/get-started/stpe-2') ? 'active' : ''}`}>Step 2</Link></li>
                    <li><Link to="/training/get-started/stpe-3" className={`${pathname.match('/training/get-started/stpe-3') ? 'active' : ''}`}>Step 3</Link></li>
                    <li><Link to="/training/get-started/stpe-4" className={`${pathname.match('/training/get-started/stpe-4') ? 'active' : ''}`}>Step 4</Link></li>
                    <li><Link to="/training/get-started/stpe-5" className={`${pathname.match('/training/get-started/stpe-5') ? 'active' : ''}`}>Step 5</Link></li>
                    <li><Link to="/training/get-started/stpe-6" className={`${pathname.match('/training/get-started/stpe-6') ? 'active' : ''}`}>Step 6</Link></li>
                    <li><Link to="/training/get-started/stpe-7" className={`${pathname.match('/training/get-started/stpe-7') ? 'active' : ''}`}>Step 7</Link></li>
                    <li><Link to="/training/get-started/stpe-8" className={`${pathname.match('/training/get-started/stpe-8') ? 'active' : ''}`}>Step 8</Link></li>
                </ul>
            </div>
        </>
    )
}

export default GetStartedLeftPanel;
