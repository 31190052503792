import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "./dailytrack.css";
import { Link } from 'react-router-dom';
import DKIcon from '../../../../assets/images/dk_icon.png';
import DefaultIcon from '../../../../assets/images/default_profile.png';
import AddNew from "./modal/AddNew";
import { getUserList, getJobBoardList, getDailyTracker, clearState } from "../../../../slice/trackers/appsTracker/appsTracker"
import Constant from "../../../../constants";
import $ from "jquery";

const DailyTable = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const { dataList, userList, isLoading, appsTrackerDaily, isUpdate, isInsert } = useSelector(state => state.appsTracker)

    const [isAddNew, setIsAddNew] = useState(false);
    const [rowId, setRowId] = useState('');
    const [jobBoardId, setJobBoardId] = useState('');
    const [isValue, setIsValue] = useState('');
    const [recruiter, setrecruiter] = useState('');
    const showaddNewModal = (id, val, jobBoardId, recruiter) => {
        setRowId(id)
        setIsValue(val)
        setJobBoardId(jobBoardId)
        setrecruiter(recruiter)
        setIsAddNew(true)
    }
    const hideaddNewModal = (id, val, jobBoard, recruiter) => {
        setIsAddNew(false)
    }

    useEffect(() => {
        if (!isLoading && dataList) {
            setData(dataList)
        }
    }, [isLoading])

    useEffect(() => {
        if (isUpdate) {
            setData(dataList)
        }
        if (isInsert) {
            setData(dataList)
            dispatch(clearState())
        }
    }, [isUpdate, isInsert])

    useEffect(() => {
        async function fetchListData() {
            try {
                await dispatch(getUserList({}))
                await dispatch(getJobBoardList({}))
                await dispatch(getDailyTracker({}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchListData()
    }, [])

    const dailyTrackerFunction = (array, jobBoard, recruiter) => {
        let mainArray = array;
        let finalResult = mainArray.map((r) => {
            if (r.jobBoard === jobBoard && r.recruiter === recruiter) {
                return ({ val: r.value, id: r.id })
            } else {
                return ({ val: null, id: null })
            }
        })
        let non_zero = finalResult.filter((item) => item.val !== null);
        if (non_zero.length > 0) {
            return <td onClick={() => showaddNewModal(non_zero[0].id, non_zero[0].val, jobBoard, recruiter)}>{non_zero[0].val}</td>
        } else {
            return <td onClick={() => showaddNewModal(0, 0, jobBoard, recruiter)}></td>
        }
    }

    const dailyTrackerFooterFum = (array, recruiter) => {
        if (recruiter == "") {
            let mainArray = array.filter((item) => item.recruiter != '')
            return mainArray.reduce((a, b) => +a + +b.value, 0);
        } else {

            let mainArray = array.filter((item) => item.recruiter === recruiter)
            return mainArray.reduce((a, b) => +a + +b.value, 0);
        }
    }

    const dailyTrackerSideFun = (array, jobBoard) => {
        let mainArray = array.filter((item) => item.jobBoard === jobBoard)
        return mainArray.reduce((a, b) => +a + +b.value, 0);
    }

    const searchFunction = (e) => {
        var value = (e.target.value).toLowerCase();
        $("#filterDailyTracker tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    }

    const SHOW_IN_APPS_TRACKER = Constant.SYSTEM_CONSTANTS.Apps_Tracker.USERS_IN_TRACKER;

    const TRACKER_JOB_BOARD_ELJ = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.ELJ;
    const TRACKER_JOB_BOARD_MLV = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.MLV;
    const TRACKER_JOB_BOARD_HIPO = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.HIPO;
    const TRACKER_JOB_BOARD_DK_WEBSITE = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.DK_WEBSITE;
    const TRACKER_JOB_BOARD_LINKEDIN = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.LINKEDIN;
    const TRACKER_JOB_BOARD_INDEED = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.INDEED;
    const TRACKER_JOB_BOARD_EJOBS = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.EJOBS;
    const TRACKER_JOB_BOARD_FACEBOOK = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.FACEBOOK;
    const TRACKER_JOB_BOARD_DIRECT_EMAIL = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.DIRECT_EMAIL;
    const TRACKER_JOB_BOARD_LEARN4GOOD = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.LEARN4GOOD;
    const TRACKER_JOB_BOARD_EUROJOBS = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.EUROJOBS;
    const TRACKER_JOB_BOARD_CAREERS4A = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.CAREERS4A;
    const TRACKER_JOB_BOARD_JOBFIND = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.JOBFIND;
    const TRACKER_JOB_BOARD_BAKECA = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.BAKECA;
    const TRACKER_JOB_BOARD_BESTJOBS = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.BESTJOBS;
    const TRACKER_JOB_BOARD_PROFESIA_CZ = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.PROFESIA_CZ;
    const TRACKER_JOB_BOARD_PROFESIA_SK = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.PROFESIA_SK;
    const TRACKER_JOB_BOARD_JOOBLE = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.JOOBLE;
    const TRACKER_JOB_BOARD_NET_EMPREGOS = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.NET_EMPREGOS;
    const TRACKER_JOB_BOARD_PRACUJ_PL = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.PRACUJ_PL;
    const TRACKER_JOB_BOARD_PURE_CHAT = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.PURE_CHAT;
    const TRACKER_JOB_BOARD_CAREER_BUILDER = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.CAREER_BUILDER;
    const TRACKER_JOB_BOARD_3_MIN_JOB = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.MIN_JOB;
    const TRACKER_JOB_BOARD_SOF_TEST = Constant.SYSTEM_CONSTANTS.Apps_Tracker.JOBBOARD_LIST.SOF_TEST;

    const deepakId = 1;
    const mukulId = 2;
    const luziaId = 3;
    const mirunaId = 4;
    const onurId = 8;
    const marianId = 6;
    const filipId = 7;

    return (
        <>
            {
                (isAddNew) ? <AddNew onHide={hideaddNewModal} rowId={rowId} value={isValue} jobBoardId={jobBoardId} recruiter={recruiter} /> : null
            }
            <div className="dk-dailyTrackTableCon table-responsive">
                <table className="table dk-dailyTrackTable">
                    <thead>
                        <tr>
                            <th>
                                {/* <div className="dk-dailyappsTableSearch mr-2">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search..." onChange={searchFunction} />
                                        <div className="input-group-append">
                                            <button className="btn btn-secondary" type="button">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div> */}
                            </th>
                            <th>
                                {/* <div className="dkg-app-trackerimg-Con"> */}
                                <img src={DKIcon} className="dk-dailytablehimg" alt="DK Global" style={{ borderRadius: "0 !important" }} />
                                {/* </div> */}
                            </th>
                            {
                                userList.filter(items => SHOW_IN_APPS_TRACKER.includes(items.id)).map(result => (
                                    <th>
                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + result.profileImage} className="dk-dailytablehimg" alt={result.firstName} title={result.firstName} />
                                    </th>
                                ))
                            }
                            <th>
                                <img src={DefaultIcon} className="dk-dailytablehimg" alt="DK Global" style={{ borderRadius: "0" }} />
                            </th>
                        </tr>
                    </thead>
                    <tbody id="filterDailyTracker">
                        <tr>
                            <td>ELJ</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_ELJ, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_ELJ, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_ELJ, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_ELJ, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_ELJ, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_ELJ, filipId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_ELJ, onurId)
                            }

                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_ELJ)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>MLV</td>

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_MLV, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_MLV, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_MLV, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_MLV, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_MLV, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_MLV, filipId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_MLV, onurId)
                            }

                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_MLV)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>HIPO</td>

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_HIPO, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_HIPO, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_HIPO, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_HIPO, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_HIPO, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_HIPO, filipId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_HIPO, onurId)
                            }

                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_HIPO)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>DK Website</td>

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_DK_WEBSITE, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_DK_WEBSITE, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_DK_WEBSITE, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_DK_WEBSITE, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_DK_WEBSITE, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_DK_WEBSITE, filipId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_DK_WEBSITE, onurId)
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_DK_WEBSITE)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Linkedln</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_LINKEDIN, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_LINKEDIN, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_LINKEDIN, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_LINKEDIN, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_LINKEDIN, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_LINKEDIN, filipId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_LINKEDIN, onurId)
                            }

                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_LINKEDIN)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Indeed</td>

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_INDEED, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_INDEED, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_INDEED, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_INDEED, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_INDEED, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_INDEED, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_INDEED, onurId)
                            }

                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_INDEED)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Ejobs</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_EJOBS, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_EJOBS, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_EJOBS, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_EJOBS, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_EJOBS, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_EJOBS, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_EJOBS, onurId)
                            }

                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_EJOBS)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Facebook</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_FACEBOOK, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_FACEBOOK, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_FACEBOOK, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_FACEBOOK, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_FACEBOOK, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_FACEBOOK, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_FACEBOOK, onurId)
                            }


                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_FACEBOOK)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Direct Email</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_DIRECT_EMAIL, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_DIRECT_EMAIL, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_DIRECT_EMAIL, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_DIRECT_EMAIL, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_DIRECT_EMAIL, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_DIRECT_EMAIL, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_DIRECT_EMAIL, onurId)
                            }


                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_DIRECT_EMAIL)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Learn4Good</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_LEARN4GOOD, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_LEARN4GOOD, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_LEARN4GOOD, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_LEARN4GOOD, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_LEARN4GOOD, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_LEARN4GOOD, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_LEARN4GOOD, onurId)
                            }

                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_LEARN4GOOD)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Eurojobs</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_EUROJOBS, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_EUROJOBS, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_EUROJOBS, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_EUROJOBS, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_EUROJOBS, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_EUROJOBS, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_EUROJOBS, onurId)
                            }

                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_EUROJOBS)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Careers4A</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_CAREERS4A, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_CAREERS4A, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_CAREERS4A, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_CAREERS4A, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_CAREERS4A, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_CAREERS4A, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_CAREERS4A, onurId)
                            }

                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_CAREERS4A)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Jobfind</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_JOBFIND, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_JOBFIND, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_JOBFIND, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_JOBFIND, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_JOBFIND, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_JOBFIND, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_JOBFIND, onurId)
                            }

                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_JOBFIND)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Bakeca</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_BAKECA, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_BAKECA, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_BAKECA, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_BAKECA, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_BAKECA, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_BAKECA, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_BAKECA, onurId)
                            }

                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_BAKECA)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>BestJobs</td>

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_BESTJOBS, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_BESTJOBS, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_BESTJOBS, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_BESTJOBS, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_BESTJOBS, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_BESTJOBS, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_BESTJOBS, onurId)
                            }


                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_BESTJOBS)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Profesia.cz</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PROFESIA_CZ, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PROFESIA_CZ, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PROFESIA_CZ, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PROFESIA_CZ, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PROFESIA_CZ, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PROFESIA_CZ, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PROFESIA_CZ, onurId)
                            }

                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_PROFESIA_CZ)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Profesia.sk</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PROFESIA_SK, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PROFESIA_SK, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PROFESIA_SK, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PROFESIA_SK, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PROFESIA_SK, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PROFESIA_SK, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PROFESIA_SK, onurId)
                            }

                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_PROFESIA_SK)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Jooble</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_JOOBLE, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_JOOBLE, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_JOOBLE, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_JOOBLE, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_JOOBLE, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_JOOBLE, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_JOOBLE, onurId)
                            }


                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_JOOBLE)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>NetEmpregos</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_NET_EMPREGOS, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_NET_EMPREGOS, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_NET_EMPREGOS, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_NET_EMPREGOS, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_NET_EMPREGOS, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_NET_EMPREGOS, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_NET_EMPREGOS, onurId)
                            }


                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_NET_EMPREGOS)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Pracuj.pl</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, onurId)
                            }

                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Purechat</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, onurId)
                            }

                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_PURE_CHAT)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Careerbuilder</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_PRACUJ_PL, onurId)
                            }


                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_CAREER_BUILDER)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>3min job</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_3_MIN_JOB, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_3_MIN_JOB, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_3_MIN_JOB, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_3_MIN_JOB, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_3_MIN_JOB, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_3_MIN_JOB, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_3_MIN_JOB, onurId)
                            }
                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_3_MIN_JOB)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Softest</td>
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_SOF_TEST, deepakId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_SOF_TEST, mukulId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_SOF_TEST, luziaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_SOF_TEST, mirunaId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_SOF_TEST, marianId)
                            }
                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_SOF_TEST, filipId)
                            }

                            {
                                dailyTrackerFunction(appsTrackerDaily, TRACKER_JOB_BOARD_SOF_TEST, onurId)
                            }

                            <td>
                                {
                                    dailyTrackerSideFun(appsTrackerDaily, TRACKER_JOB_BOARD_SOF_TEST)
                                }
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>&nbsp;</td>
                            <td>
                                {
                                    dailyTrackerFooterFum(appsTrackerDaily, deepakId)
                                }
                            </td>

                            <td>
                                {
                                    dailyTrackerFooterFum(appsTrackerDaily, mukulId)
                                }
                            </td>
                            <td>
                                {
                                    dailyTrackerFooterFum(appsTrackerDaily, luziaId)
                                }
                            </td>
                            <td>
                                {
                                    dailyTrackerFooterFum(appsTrackerDaily, mirunaId)
                                }
                            </td>
                            <td>
                                {
                                    dailyTrackerFooterFum(appsTrackerDaily, marianId)
                                }
                            </td>
                            <td>
                                {
                                    dailyTrackerFooterFum(appsTrackerDaily, filipId)
                                }
                            </td>

                            <td>
                                {
                                    dailyTrackerFooterFum(appsTrackerDaily, onurId)
                                }
                            </td>
                            <td>
                                {
                                    dailyTrackerFooterFum(appsTrackerDaily, '')
                                }
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    )
}

export default DailyTable;
