import React from 'react'
const DisplayResponsbility = ({ list,indexZero }) => {
    return (
        <React.Fragment>
            {
                indexZero === 0 ? list.split('\n').map(str => <li>{str}</li>) : ''
            }
        </React.Fragment>
    )
}
export default DisplayResponsbility;