import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import { Modal } from "react-bootstrap";
import "./viewpopup.scss";
import ClockPicker from "./ClockPicker";
import SubmitButton from "../../../../../../../ui/submitButton";

import { updateApplication } from "../../../../../../../../slice/candidates/list/listSlice";

const CaseUpdated = ({ onHide, itemdata }) => {
  //console.log(itemdata);
  const dispatch = useDispatch();
  const [date, setDate] = useState(moment());
  const [focused, setFocused] = useState(true); // Set focused to true initially
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  //const [time, setTime] = useState('10:00'); // Initialize with a default time or current time
  const handleButtonClick = async () => {
    //  setSuccess(true);
    //  setLoading(true);
    dispatch(
      await updateApplication({
        id: itemdata.id,
        key: "caseUpdated",
        value: date,
        canId: itemdata.canId,
      })
    );
  };

  return (
    <React.Fragment>
      <Modal show={true} onHide={onHide} className="dkg-application-view-modal">
        <Modal.Header
          closeButton
          className="dkg-app-progress-modal-header dkg-caseupdated-header"
        >
          <Modal.Title>CASE STATUS UPDATED</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-app-progress-modal-body dkg-datetime-modalbody">
          <div className="dkg-caseupdated-calenderCon">
            <SingleDatePicker
              date={date} // momentPropTypes.momentObj or null
              onDateChange={(date) => setDate(date)} // PropTypes.func.isRequired
              focused={focused} // PropTypes.bool
              onFocusChange={({ focused }) => setFocused(true)} // Keep the calendar always open
              numberOfMonths={1}
              isOutsideRange={() => false} // Allow all dates
              id="your_unique_id" // PropTypes.string.isRequired,
              readOnly
              format="YYYY-MM-DD"
            />
          </div>
          <div className="dkg-case-updated-timepickerCon">
            {/* <TimePicker
                            onChange={setTime}
                            value={time}
                            clockIcon={null} // Removes the clock icon
                            disableClock={true} // Disable the clock
                            openClockOnFocus={false} // Prevents closing on focus loss
                        /> */}
            <ClockPicker />
          </div>
          <div className="dkg-caseupdated-savebtncon">
            <SubmitButton
              txt="SAVE"
              loading={loading}
              success={success}
              className="dkg-caseupdated-savebtn"
              onClick={handleButtonClick}
            />
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default CaseUpdated;
