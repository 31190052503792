import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const InfoModal = ({ handleClose }) => {
    return (
        <>
            <Modal className="dk-pipelineInfoModal" show={true} centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident, itaque. Neque consequuntur non distinctio ex, rerum aliquid soluta omnis laboriosam unde cumque enim porro facere nulla? Quas possimus obcaecati at.</p>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam culpa sed dignissimos accusantium, eligendi eveniet quaerat labore unde dicta animi architecto veritatis qui officiis. Ut debitis possimus cupiditate recusandae vel. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Totam voluptatem temporibus, soluta nam exercitationem.</p>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default InfoModal;
