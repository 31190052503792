import React from 'react';
import { Link } from "react-router-dom";
import './qualyeducation.scss';
import QualificationBox from "./QualificationBox";
const Qualification = () => {
    return (
        <>
            <div className="dk-qualyeduConMain">
                <div className="dk-qualyTopHead">
                    <div className="title">Qualification Summary</div>
                    <div className="btn-Con">
                        <Link to="#"><i className="fa fa-plus mr-2"></i> Add New Qualification</Link>
                    </div>
                </div>
                <div className="description">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                </div>
                <div className="dk-qualyeduBoxCon">
                    <QualificationBox years="2006-2007" qualification="High School (University of Delhi)" collegeName="Bharti College" location="New Delhi (India)" />
                    <QualificationBox years="2007-2010" qualification="GRADUATION (UNIVERSITY OF HYDERABAD)" collegeName="University P.O Gachibowli" location="Hyderabad (India)" />
                    <QualificationBox years="2010-2013" qualification="POST GRADUATION (UNIVERSITY OF MUMBAI)" collegeName="Indian Institute of Technology" location="Mumbai (India)" />
                </div>
            </div>
        </>
    )
}

export default Qualification;
