import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import "../../jobs/addnew/addnewjob.scss";
import SearchFilter from '../../jobs/addnew/SearchFilter';
import { WordFileUpload } from "./WordFileUpload";
import { PdfFileUpload } from "./PdfFileUpload";
const FileModal = ({ handleClose }) => {
    return (
        <>
            <Modal className="dk-cloudFilesModal" centered show={true} onHide={handleClose}>
                <Modal.Header className='dk-cloudFilesModalHead' closeButton>
                    <Modal.Title>Add File</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                <div className="dk-jobsCreatePageCon mt-0">
                {/* <div className="row">
                    <div className="col-md-5">
                        <h2 className="dk-todayadsMainPTitle w-100 text-center">
                            SELECT PATH
                        </h2>
                    </div>
                    <div className="col-md-7">
                        <div className="dk-jobCreateRightHead">
                            <h2 className="dk-todayadsMainPTitle w-100 text-center">
                                JOB DETAILS
                            </h2>
                        </div>
                    </div>
                </div> */}
                <div className="dkcreate-FromSection" style={{height:'auto'}}>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="dk-rightTopCon" style={{background:'none'}}>
                            <WordFileUpload
                                onDrop={(files) => {
                               
                                }}
                            />

                            <PdfFileUpload 
                                onDrop={(files) => {
                                
                                }}
                            />
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <div className="dk-leftTopformCon" style={{background:'none'}}>
                                <form>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="dk-formInputCon">
                                                <label>Company<span>*</span></label>
                                                <div className="dk-createjobsDrpoDwn">
                                                    <SearchFilter />
                                                </div>
                                            </div>
                                            <div className="dk-formInputCon">
                                                <label>Project<span>*</span></label>
                                                <div className="dk-createjobsDrpoDwn">
                                                    <SearchFilter />
                                                </div>
                                            </div>
                                            <div className="dk-formInputCon">
                                                <label>Role Type<span>*</span></label>
                                                <div className="dk-createjobsDrpoDwn">
                                                    <SearchFilter />
                                                </div>
                                            </div>
                                            <div className="dk-formInputCon">
                                                <label>Language<span>*</span></label>
                                                <div className="dk-createjobsDrpoDwn">
                                                    <SearchFilter />
                                                </div>
                                            </div>

                                            <div className="dk-formInputCon">
                                                <label>Spec Type<span>*</span></label>
                                                <div className="dk-createjobsDrpoDwn">
                                                    <SearchFilter />
                                                </div>
                                            </div>

                                            <div className="dk-formInputCon mb-0">
                                                <label>File Name<span>*</span></label>
                                                <div className="dk-createjobsDrpoDwn">
                                                <input type="text" defaultValue="" id="questions" style={{border:'1px solid #ced4da'}} className="form-control" name="questions" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12 text-center pt-3 pb-3" style={{borderTop:'1px solid #ddd'}}>
                        <button type="submit" className="btn btn-success btn-md m-0"> Save</button>
                        </div>
                    </div>
                </div>
            </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default FileModal;
