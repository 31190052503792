import React from 'react';
import MailboxSubmenu from '../../../../elements/header/navbar/subMenu/menu/mainsubmenu/MailboxSubmenu';
import './reporting.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';

const Report = () => {
    return (
        <>
        <MailboxSubmenu />
            <div className="dk-reportMain">
                <div className="dk-reportHead">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-reportLeft-panel">
                            <div className="dk-reportSearch">
                                <input type="text" placeholder="Search..." name="" id="" className="form-control" />
                                <button className="dk-searchBtn"><FontAwesomeIcon icon={faSearch} /></button>
                            </div>
                        </div>
                        <div className="title">Reporting</div>
                        <div className="dk-reportLeft-panel">
                            <div className="dk-reportFilter">
                                <Dropdown>
                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                        Status
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#" style={{background:'green', color:'#fff'}}>Accepted</Dropdown.Item>
                                        <Dropdown.Item href="#" style={{background:'#20a2c1', color:'#fff'}}>Delivered</Dropdown.Item>
                                        <Dropdown.Item href="#" style={{background:'#03414e', color:'#fff'}}>Opened</Dropdown.Item>
                                        <Dropdown.Item href="#" style={{background:'#e37452', color:'#fff'}}>Failed</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dk-reportTable-main">
                    <div className="table-responsive">
                        <table className="table table-bordred">
                            <thead>
                                <tr>
                                    <th>Sn.</th>
                                    <th>Accepted</th>
                                    <th>Delivered</th>
                                    <th>Opened</th>
                                    <th>Failed</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Report;
