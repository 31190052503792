import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputGroup, FormControl } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import SimpleDropDwn from "../summary/SimpleDropDwn";
import UserSearchFilter from "../summary/SearchInputDropDwn";
import AirDatepicker from "air-datepicker";
import en from "air-datepicker/locale/en";
import "air-datepicker/air-datepicker.css";
import { FaEuroSign, FaDollarSign, FaRupeeSign, FaCaretDown } from "react-icons/fa";
import UserList from "../../../../../ui/userList/userListByRole";

import {
  clearState,
  updateJob,
  updateJobWithTextBox,
} from "../../../../../../slice/jobs/jobsSlice";

const Salary = (jobId) => {
  const dispatch = useDispatch();
  const {
    jobsCategoryList,
    jobsCompanyList,
    jobsProjectList,
    jobsRoleTypeList,
    jobsLanguageList,
    jobsLocalityList,
    jobsNoofJobsList,
    jobsCountryList,
    jobsCityList,
    jobsJobProfileList,
    jobsWorkPermitList,
    jobsWorkplaceList,
    jobsWorkdaysList,
    jobsShiftsWorkList,
    jobsContracttypeList,
    jobsSalarytypeList,
    jobsAvailabilityList,
    jobsOutsideofeuList,
    jobsFlightticketList,
    jobsTaxifromairportList,
    jobsAccommodationtList,
    jobsRelocationbudgetList,
    jobsExperiencetList,
    jobsCurrencyList,
    
  } = useSelector((state) => state.common);

  const { isDetailsLoading, jobDetails, isUpdate } = useSelector(
    (state) => state.jobs
  );
  const [status, setStatus] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setData(jobDetails);
      setStatus(jobDetails.status);
    }
  }, [isDetailsLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobDetails);
    }
  }, [isUpdate]);
  let ids = data.id;

  const JobStatus = (status) => {
    let bgColor = "";
    let txtColor = "";
    switch (status) {
      case "Normal":
        bgColor = "#30887e";
        txtColor = "#fff";
        break;

      case "Urgent":
        bgColor = "#dc5539";
        txtColor = "#fff";
        break;

      case "To qualify":
        bgColor = "#ffd966";
        txtColor = "#000";
        break;

      case "On Hold":
        bgColor = "#7f5347";
        txtColor = "#fff";
        break;

      case "Closed":
        bgColor = "#e2445c";
        txtColor = "#fff";
        break;
    }

    return (
      <>
        <Dropdown.Toggle
          variant=""
          className="dk-statusDrpDwnToggle"
          style={{ backgroundColor: bgColor, color: txtColor }}
        >
          {" "}
          {status}
        </Dropdown.Toggle>
      </>
    );
  };

  const updateMyData = (e) => {
    let values = "";
    let columnId = e.target.dataset.columnid;
    if (columnId === "gross_title") {
      values = e.target.dataset.value;
    } else {
      values = e.target.value;
    }

    dispatch(updateJob({ id: ids, key: columnId, value: values }));
  };
  const updateStatus = (values) => {
    setStatus(values);
    dispatch(updateJob({ id: ids, key: "status", value: values }));
  };
  new AirDatepicker("#date1", {
    position: "bottom center",
    locale: en,
    dateFormat: "d MMM yy",
    onSelect({ date, formattedDate, datepicker }) {
      dispatch(updateJob({ id: ids, key: "date1", value: formattedDate }));
    },
  });

  new AirDatepicker("#date2", {
    position: "bottom center",
    locale: en,
    dateFormat: "d MMM yy",
    onSelect({ date, formattedDate, datepicker }) {
      dispatch(updateJob({ id: ids, key: "date2", value: formattedDate }));
    },
  });

  let gross_value = "";
  if (data.gross_title === null) {
    gross_value = "Monthly Gross Salary";
  } else {
    if (data.gross_title === "Monthly Gross Salary") {
      gross_value = "Annual Gross Salary";
    } else {
      gross_value = "Monthly Gross Salary";
    }
  }
  const onItemClick = (id, columnId, value) => {
    dispatch(updateJob({ id, key: columnId, value }));
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="col-md-12 dk-jobsuMMBottomSec pl-0">
            <div className=" row">
              <div className="col-md-12 pr-0 col-sm-12 dk-jobsuMMBottomBoxBg">
                <div className="dk-boxBody dkg-livejobs-boxbody row m-0 border-0">
                  <div className="col-md-12">
                    <div className="dk-suMMformGroupCon mb-2">
                      <label
                        style={{ background: "none", width: "220px" }}
                        onClick={updateMyData}
                        className="label-hover dkg-gross-slary-label"
                        data-columnid="gross_title"
                        data-value={gross_value}
                      >
                        {data.gross_title !== null
                          ? data.gross_title
                          : "Monthly Gross Salary"}
                        <FaCaretDown />
                      </label>
                      <InputGroup size="sm" className="dk-inputGrp">
                        <FormControl
                          aria-label="Small"
                          style={{
                            padding: "0.6rem 1.75rem",
                            border: "1px solid #ccc",
                            borderRight: "0px",
                          }}
                          aria-describedby="inputGroup-sizing-sm"
                          onBlur={updateMyData}
                          data-columnid="annual_gross"
                          defaultValue={data.annual_gross}
                        />
                        {/* <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroup-sizing-sm">
                            <i className="fas fa-euro-sign"></i>
                          </InputGroup.Text>
                        </InputGroup.Prepend> */}
                        
                        {/* <Dropdown className="dkg-salary-curr-dropdown">
                          <Dropdown.Toggle variant="" className="dkg-salary-curr-dropdown-toggle">
                            EUR
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dkg-salary-curr-dropdown-menu">
                            <Dropdown.Item href="#" className="dkg-salary-curr-dropdown-item">EUR</Dropdown.Item>
                            <Dropdown.Item href="#" className="dkg-salary-curr-dropdown-item">USD</Dropdown.Item>
                            <Dropdown.Item href="#" className="dkg-salary-curr-dropdown-item">GBP</Dropdown.Item>
                            <Dropdown.Item href="#" className="dkg-salary-curr-dropdown-item">KWD</Dropdown.Item>
                            <Dropdown.Item href="#" className="dkg-salary-curr-dropdown-item">GBP</Dropdown.Item>
                            <Dropdown.Item href="#" className="dkg-salary-curr-dropdown-item">AUD</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> */}
                        <div className="dkg-salary-curr-dropdown-toggle">
                         <SimpleDropDwn
                            categoryList={jobsCurrencyList}
                            text={data.gross_currency}
                            columnId="gross_currency"
                          />
                        </div>

                      </InputGroup>
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label style={{ background: "none", width: "220px" }}>
                        Performance Bonus
                      </label>
                      <InputGroup size="sm" className="dk-inputGrp">
                        <FormControl
                          aria-label="Small"
                          style={{
                            padding: "0.6rem 1.75rem",
                            border: "1px solid #ccc",
                            borderRight: "0px",
                          }}
                          aria-describedby="inputGroup-sizing-sm"
                          onBlur={updateMyData}
                          data-columnid="performance"
                          defaultValue={data.performance}
                        />
                       
                        <div className="dkg-salary-curr-dropdown-toggle">
                         <SimpleDropDwn
                            categoryList={jobsCurrencyList}
                            text={data.performance_currency}
                            columnId="performance_currency"
                          />
                        </div>

                      </InputGroup>
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label style={{ background: "none", width: "220px" }}>
                        Overtime Bonus
                      </label>
                      <InputGroup size="sm" className="dk-inputGrp">
                        <FormControl
                          aria-label="Small"
                          style={{
                            padding: "0.6rem 1.75rem",
                            border: "1px solid #ccc",
                            borderRight: "0px",
                          }}
                          aria-describedby="inputGroup-sizing-sm"
                          onBlur={updateMyData}
                          data-columnid="overtime_bonus"
                          defaultValue={data.overtime_bonus}
                        />
                       
                        <div className="dkg-salary-curr-dropdown-toggle">
                         <SimpleDropDwn
                            categoryList={jobsCurrencyList}
                            text={data.overtime_currency}
                            columnId="overtime_currency"
                          />
                        </div>

                      </InputGroup>
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label style={{ background: "none", width: "220px" }}>
                        Meal Tickets
                      </label>
                      <InputGroup size="sm" className="dk-inputGrp">
                        <FormControl
                          aria-label="Small"
                          style={{
                            padding: "0.6rem 1.75rem",
                            border: "1px solid #ccc",
                            borderRight: "0px",
                          }}
                          aria-describedby="inputGroup-sizing-sm"
                          onBlur={updateMyData}
                          data-columnid="meal_ticket"
                          defaultValue={data.meal_ticket}
                        />
                        
                        <div className="dkg-salary-curr-dropdown-toggle">
                         <SimpleDropDwn
                            categoryList={jobsCurrencyList}
                            text={data.meal_currency}
                            columnId="meal_currency"
                          />
                        </div>
                      </InputGroup>
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label style={{ background: "none", width: "220px" }}>
                        Joining Bonus
                      </label>
                      <InputGroup size="sm" className="dk-inputGrp">
                        <FormControl
                          aria-label="Small"
                          style={{
                            padding: "0.6rem 1.75rem",
                            border: "1px solid #ccc",
                            borderRight: "0px",
                          }}
                          aria-describedby="inputGroup-sizing-sm"
                          onBlur={updateMyData}
                          data-columnid="joining_bonus"
                          defaultValue={data.joining_bonus}
                        />
                       
                        <div className="dkg-salary-curr-dropdown-toggle">
                         <SimpleDropDwn
                            categoryList={jobsCurrencyList}
                            text={data.joining_currency}
                            columnId="joining_currency"
                          />
                        </div>

                      </InputGroup>
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label style={{ background: "none", width: "220px" }}>
                      Retained Bonus
                      </label>
                      <InputGroup size="sm" className="dk-inputGrp">
                        <FormControl
                          aria-label="Small"
                          style={{
                            padding: "0.6rem 1.75rem",
                            border: "1px solid #ccc",
                            borderRight: "0px",
                          }}
                          aria-describedby="inputGroup-sizing-sm"
                          onBlur={updateMyData}
                          data-columnid="average_net_pm"
                          defaultValue={data.average_net_pm}
                        />
                       
                        <div className="dkg-salary-curr-dropdown-toggle">
                         <SimpleDropDwn
                            categoryList={jobsCurrencyList}
                            text={data.avgNetSal_currency}
                            columnId="avgNetSal_currency"
                          />
                        </div>
                      </InputGroup>
                    </div>
                    {/* <div className="dk-suMMformGroupCon mb-2">
                      <label style={{ background: "none", width: "220px" }}>
                        Other Bonus
                      </label>
                      <InputGroup size="sm" className="dk-inputGrp">
                        <FormControl
                          aria-label="Small"
                          style={{
                            padding: "0.6rem 1.75rem",
                            border: "1px solid #ccc",
                            borderRight: "0px",
                          }}
                          aria-describedby="inputGroup-sizing-sm"
                          onBlur={updateMyData}
                          data-columnid="other_bonus"
                          defaultValue={data.other_bonus}
                        />
                        
                        <div className="dkg-salary-curr-dropdown-toggle">
                         <SimpleDropDwn
                            categoryList={jobsCurrencyList}
                            text={data.other_currency}
                            columnId="other_currency"
                          />
                        </div>

                      </InputGroup>
                    </div> */}
                    <div className="dk-suMMformGroupCon mb-2">
                      <label style={{ background: "none", width: "220px" }}>
                      Rent Allowance
                      </label>
                      <InputGroup size="sm" className="dk-inputGrp">
                        <FormControl
                          aria-label="Small"
                          style={{
                            padding: "0.6rem 1.75rem",
                            border: "1px solid #ccc",
                            borderRight: "0px",
                          }}
                          aria-describedby="inputGroup-sizing-sm"
                          onBlur={updateMyData}
                          data-columnid="accomodation_bonus"
                          defaultValue={data.accomodation_bonus}
                        />
                        
                        <div className="dkg-salary-curr-dropdown-toggle">
                         <SimpleDropDwn
                            categoryList={jobsCurrencyList}
                            text={data.accomodation_currency}
                            columnId="accomodation_currency"
                          />
                        </div>

                      </InputGroup>
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label style={{ background: "none", width: "220px" }}>
                          2 Extra Salaries
                      </label>
                      <InputGroup size="sm" className="dk-inputGrp">
                        <FormControl
                          aria-label="Small"
                          style={{
                            padding: "0.6rem 1.75rem",
                            border: "1px solid #ccc",
                            borderRight: "0px",
                          }}
                          aria-describedby="inputGroup-sizing-sm"
                          onBlur={updateMyData}
                          data-columnid="extra_salary_pm"
                          defaultValue={data.extra_salary_pm}
                        />
                       
                        <div className="dkg-salary-curr-dropdown-toggle">
                         <SimpleDropDwn
                            categoryList={jobsCurrencyList}
                            text={data.extra_currency}
                            columnId="extra_currency"
                          />
                        </div>

                      </InputGroup>
                    </div>
                    <div className="dk-suMMformGroupCon mb-2">
                      <label style={{ background: "none", width: "220px" }}>
                        Income Tax %
                      </label>
                      <InputGroup size="sm" className="dk-inputGrp">
                        <FormControl
                          aria-label="Small"
                          style={{
                            padding: "0.6rem 1.75rem",
                            border: "1px solid #ccc",
                            borderRight: "0px",
                          }}
                          aria-describedby="inputGroup-sizing-sm"
                          onBlur={updateMyData}
                          data-columnid="tax"
                          defaultValue={data.tax}
                        />
                        
                        <div className="dkg-salary-curr-dropdown-toggle">
                         <SimpleDropDwn
                            categoryList={jobsCurrencyList}
                            text={data.incomeTax_currency}
                            columnId="incomeTax_currency"
                          />
                        </div>
                       
                      </InputGroup>
                    </div>
                  </div>
                  {/* <div className="col-md-6 pl-5">
                    <div className="mb-2">
                      <p
                        className="text-center m-0"
                        style={{
                          border: "1px solid #ccc",
                          borderBottom: "0px",
                          padding: "8px",
                        }}
                      >
                        Income Tax Calculator
                      </p>
                      <InputGroup size="sm" className="dk-inputGrp">
                        <FormControl
                          aria-label="Small"
                          style={{
                            padding: "1.8rem 0.75rem",
                            border: "1px solid #ccc",
                            borderRadius: "0px",
                          }}
                          aria-describedby="inputGroup-sizing-sm"
                          className="text-left"
                          onBlur={updateMyData}
                          data-columnid="i_t_c"
                          defaultValue={data.i_t_c}
                        />
                      </InputGroup>
                    </div>

                    <div className="mb-2 mt-3">
                      <p
                        className="text-center m-0"
                        style={{
                          border: "1px solid #ccc",
                          borderBottom: "0px",
                          padding: "8px",
                        }}
                      >
                        Salary Explained
                      </p>
                      <InputGroup size="sm" className="dk-inputGrp">
                        <textarea
                          className="form-control text-left"
                          style={{
                            border: "1px solid #ccc",
                            height: "238px",
                            borderRadius: "0px",
                          }}
                          onBlur={updateMyData}
                          data-columnid="salary_explained"
                          defaultValue={data.salary_explained}
                        ></textarea>
                      </InputGroup>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Salary;
