import React from 'react'

const EditTab = () => {
    return (
        <>
            <div className="dk-toolsValuesPanel">
                <div className="d-flex align-items-center dk-edit-row-list">
                    <span className="dk-sno">1</span><input type="text" name="list" className="form-control" value="Task" />
                    <div className="dk-toolsPanel-updateIcon"><i className="far fa-save"></i></div>
                </div>
                <div className="d-flex align-items-center dk-edit-row-list">
                    <span className="dk-sno">2</span><input type="text" name="list" className="form-control" value="Candidates" />
                    <div className="dk-toolsPanel-updateIcon"><i className="far fa-save"></i></div>
                </div>
                <div className="d-flex align-items-center dk-edit-row-list">
                    <span className="dk-sno">3</span><input type="text" name="list" className="form-control" value="Resources" />
                    <div className="dk-toolsPanel-updateIcon"><i className="far fa-save"></i></div>
                </div>
                <div className="d-flex align-items-center dk-edit-row-list">
                    <span className="dk-sno">4</span><input type="text" name="list" className="form-control" value="ADS" />
                    <div className="dk-toolsPanel-updateIcon"><i className="far fa-save"></i></div>
                </div>
                <div className="d-flex align-items-center dk-edit-row-list">
                    <span className="dk-sno">5</span><input type="text" name="list" className="form-control" value="Jobs" />
                    <div className="dk-toolsPanel-updateIcon"><i className="far fa-save"></i></div>
                </div>
            </div>
        </>
    )
}

export default EditTab