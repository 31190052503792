import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./appocation.scss";
import { Dropdown, Nav, Tab } from "react-bootstrap";
import { FaPlus, FaEdit, FaTimes, FaUser, FaSearch } from "react-icons/fa";
import ApplicationTable from "./ApplicationTable";
import {
  addApplication,
  getApplications,
} from "../../../../../../../slice/candidates/list/listSlice";
//import { jobsDetails } from "../../../../../../../slice/jobs/jobsSlice";
//manage Status
//src\components\pages\candidate\pipeline\dropdown\other\appliedStatus.js
//src\components\pages\candidate\pipeline\modal\candidateprofile\appications\BgStatus.js
//src\components\pages\candidate\pipeline\modal\candidateprofile\appications\dropdown\StatusDopdown.jsx
import { showSuccess } from "../../../../../../../slice/utils/message/messageSlice";
const Appication = ({ canId, jobId }) => {
  const dispatch = useDispatch();
  const {
    applicationList,
    isApplication,
    isAppUpdate,
    isAppDelete,
    isJobunlink,
    isWithdrwan,
    isApplicationLoading,
    isProgressAdd,
  } = useSelector((state) => state.candidates);

  //const { isDetailsLoading, jobDetails } = useSelector((state) => state.jobs);
 
  const [items, setItems] = useState([]);
  const [applicationData, setApplicationData] = useState([]);

  const [eventKey, SetEventKey] = useState("app-applied");
  const [titleName, setTitleName] = useState("Applied");
  const [dynamicTitleName, setDynamicTitleName] = useState("JOBS APPLIED");
  const [applicationType, setApplicationType] = useState("app-applied");
  const [apptitle, setApptitle] = useState(
    `JOBS APPLIED - ` +
      applicationData.filter((data) => data.applicationType === "Applied")
        .length
  );


  useEffect(() => {
    if (isApplication) {
      setApplicationData(applicationList);
      setApptitle(
        dynamicTitleName +
          ` - ` +
          applicationList.filter((data) => data.applicationType === titleName)
            .length
      );
    }
    if (isAppUpdate) {
      dispatch(showSuccess({ msg: "Record updated Successfully." }));
    }
    if (isAppDelete) {
      dispatch(showSuccess({ msg: "Record Deleted Successfully." }));
      dispatch(getApplications({ can_id: canId }));
    }

    if (isJobunlink) {
      dispatch(getApplications({ can_id: canId }));
    }
    if (isWithdrwan) {
      dispatch(showSuccess({ msg: "Form Updated Successfully." }));
      dispatch(getApplications({ can_id: canId }));
    }

    if (isApplicationLoading) {
      dispatch(showSuccess({ msg: "Data Updated Successfully." }));
      dispatch(getApplications({ can_id: canId }));
    }
  }, [
    isApplication,
    isAppUpdate,
    isAppDelete,
    isJobunlink,
    isApplicationLoading,
    isWithdrwan,
    isProgressAdd,
  ]);

  //const [Jobdata, setJobdata] = useState("");

  //console.log(jobDetails);

  // useEffect(() => {
  //   const fetchdata = async () => {
  //     try {
  //       await dispatch(jobsDetails({ id: jobId }));
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchdata();
  // }, []);

  // const addDiv = useCallback(() => {
  //   // using timestamp as a unique ID
  //   setItems([...items, new Date().getTime()]);
  // }, [items]);

  useEffect(() => {
    if (eventKey) {
      switch (eventKey) {
        case "app-applied":
          setApplicationType("Applied");
          setApptitle(
            `JOBS APPLIED - ` +
              applicationData.filter(
                (data) => data.applicationType === "Applied"
              ).length
          );
          break;
        case "app-interviews":
          setApplicationType("Interviewed");
          setApptitle(
            `JOBS INTERVIEWED - ` +
              applicationData.filter(
                (data) => data.applicationType === "Interviewed"
              ).length
          );
          break;
        case "app-joboffers":
          setApplicationType("Offered");
          setApptitle(
            `JOBS OFFERED - ` +
              applicationData.filter(
                (data) => data.applicationType === "Offered"
              ).length
          );
          break;
        case "app-joined":
          setApplicationType("Joined");
          setApptitle(
            `JOBS JOINED - ` +
              applicationData.filter(
                (data) => data.applicationType === "Joined"
              ).length
          );
          break;
      }
    }
  }, [eventKey]);

  const addNewApplications = (type) => {
    let dyt = "";
    if (type === "Applied") {
      dyt = "JOBS APPLIED";
    } else if (type === "Interviewed") {
      dyt = "JOBS INTERVIEWED";
    } else if (type === "Joined") {
      dyt = "JOBS OFFERED";
    } else {
      dyt = "JOBS JOINED";
    }
    setDynamicTitleName(dyt);
    setTitleName(type);
    dispatch(addApplication({ canId: canId, type }));
  };

  const removeDiv = useCallback(
    (itemId) => {
      // filter out the div which matches the ID
      setItems(items.filter((id) => id !== itemId));
    },
    [items]
  );

  const[filterYear, setFilterYear] =useState("Year")
  const filterRecord = async(v)=>{   
    setFilterYear(v)
   setApplicationData(applicationData.length > 0 ? applicationData.filter(item => item.year === v): 0)
  }

  const removeTag = async (e) => { 
    setFilterYear("Year")  
    setApplicationData(applicationList);

  };

  return (
    <>
      <div className="dkg-appication-mainCon">
    
        <div className="dkg-appication-header">
        <div className="dkg-appication-leftCon">
          {   
            applicationType==="Applied" ? <> 
            <Dropdown className="dkg-appication-dropdown">
              <Dropdown.Toggle variant="" className="dkg-app-dropdown-toggle">
                {filterYear}
                
              </Dropdown.Toggle>
              {
                filterYear ==="Year" ?  
                <Dropdown.Menu className="dkg-app-dropdown-menu">
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2024)}>
                  2024  
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) => data.applicationType === "Applied" && data.year === 2024).length):0}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2023)}>
                  2023
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) => data.applicationType === "Applied" && data.year === 2023).length):0}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2022)}>
                  2022
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) => data.applicationType === "Applied" && data.year === 2022).length):0}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2021)}>
                  2021
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) => data.applicationType === "Applied" && data.year === 2021).length):0}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2020)}>
                  2020
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) => data.applicationType === "Applied" && data.year === 2020).length):0}</span>
                </Dropdown.Item>
                </Dropdown.Menu> :null
              }
             
            </Dropdown>
            <div className="dkg-search-icon-con">
                   <div className="dkg-search-icon">
                   {
                    filterYear!=="Year" ? <><i
                    className="fas fa-times-circle"                    
                    data-type="Year"
                    onClick={removeTag}
                  ></i></>
                   : 
                     <FaSearch />                   
                  }
              </div>
              </div></>
           :null 
          }

          {
            applicationType==="Interviewed" ?<> 
            <Dropdown className="dkg-appication-dropdown">
              <Dropdown.Toggle variant="" className="dkg-app-dropdown-toggle">
                {filterYear}
                
              </Dropdown.Toggle> 
              {
                filterYear ==="Year" ?  
                <Dropdown.Menu className="dkg-app-dropdown-menu">
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2024)}>
                  2024  
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) =>data.applicationType === "Interviewed" && data.year === 2024).length):0}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2023)}>
                  2023
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) =>data.applicationType === "Interviewed" && data.year === 2023).length):0}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2022)}>
                  2022
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) =>data.applicationType === "Interviewed" && data.year === 2022).length):0}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2021)}>
                  2021
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) =>data.applicationType === "Interviewed" && data.year === 2021).length):0}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2020)}>
                  2020
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) =>data.applicationType === "Interviewed" && data.year === 2020).length):0}</span>
                </Dropdown.Item>
                </Dropdown.Menu> :null
              }
             
            </Dropdown>
            <div className="dkg-search-icon-con">
                   <div className="dkg-search-icon">
                   {
                    filterYear!=="Year" ? <><i
                    className="fas fa-times-circle"                    
                    data-type="Year"
                    onClick={removeTag}
                  ></i></>
                   : 
                     <FaSearch />                   
                  }
              </div>
              </div></>
           :null 
          }
          {
            applicationType==="Offered" ?<> 
            <Dropdown className="dkg-appication-dropdown">
              <Dropdown.Toggle variant="" className="dkg-app-dropdown-toggle">
                {filterYear}
                
              </Dropdown.Toggle> 
              {
                filterYear ==="Year" ?  
                <Dropdown.Menu className="dkg-app-dropdown-menu">
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2024)}>
                  2024  
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) => data.applicationType === "Offered" && data.year === 2024).length):0}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2023)}>
                  2023
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) => data.applicationType === "Offered" && data.year === 2023).length):0}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2022)}>
                  2022
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) => data.applicationType === "Offered" && data.year === 2022).length):0}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2021)}>
                  2021
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) => data.applicationType === "Offered" && data.year === 2021).length):0}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2020)}>
                  2020
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) => data.applicationType === "Offered" && data.year === 2020).length):0}</span>
                </Dropdown.Item>
                </Dropdown.Menu> :null
              }
             
            </Dropdown>
            <div className="dkg-search-icon-con">
                   <div className="dkg-search-icon">
                   {
                    filterYear!=="Year" ? <><i
                    className="fas fa-times-circle"                    
                    data-type="Year"
                    onClick={removeTag}
                  ></i></>
                   : 
                     <FaSearch />                   
                  }
              </div>
              </div></>
           :null 
          }
           {
            applicationType==="Joined" ?<> 
            <Dropdown className="dkg-appication-dropdown">
              <Dropdown.Toggle variant="" className="dkg-app-dropdown-toggle">
                {filterYear}
                
              </Dropdown.Toggle> 
              {
                filterYear ==="Year" ?  
                <Dropdown.Menu className="dkg-app-dropdown-menu">
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2024)}>
                  2024  
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) => data.applicationType === "Joined" && data.year === 2024).length):0}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2023)}>
                  2023
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) => data.applicationType === "Joined" && data.year === 2023).length):0}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2022)}>
                  2022
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) => data.applicationType === "Joined" && data.year === 2022).length):0}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2021)}>
                  2021
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) => data.applicationType === "Joined" && data.year === 2021).length):0}</span>
                </Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-app-dropdown-item" onClick={()=>filterRecord(2020)}>
                  2020
                  <span className ="dkg-jobsapp-count">{applicationData.length > 0 ? (applicationData.filter((data) => data.applicationType === "Joined" && data.year === 2020).length):0}</span>
                </Dropdown.Item>
                </Dropdown.Menu> :null
              }
             
            </Dropdown>
            <div className="dkg-search-icon-con">
                   <div className="dkg-search-icon">
                   {
                    filterYear!=="Year" ? <><i
                    className="fas fa-times-circle"                    
                    data-type="Year"
                    onClick={removeTag}
                  ></i></>
                   : 
                     <FaSearch />                   
                  }
              </div>
              </div></>
           :null 
          }

          </div>
          

          <div className="dkg-application-centreCon">
            <h2 className="dkg-application-title">{apptitle}</h2>
          </div>
          <div className="dkg-application-rightCon">
            <div className="dkg-addnew-appbtnCon">
              <div className="dkg-plus-icon-con" 
                onClick={() => addNewApplications(applicationType)}
              >
                <div className="dkg-plus-icon">
                 <FaPlus  /> 
                </div>
              </div>
              <Link
                to="#"
                className="dkg-addnew-appbtn btn"
                onClick={() => addNewApplications(applicationType)}
              >
                Add New
              </Link>
            </div>
          </div>
        </div>

        <div className="dkg-appication-mainbox">
          <Tab.Container id="left-tabs-example" defaultActiveKey="app-applied">
            <div className="row dkg-appication-mainRow">
              <div className="col-12 col-md-12 dkg-appication-leftCol">
                <Nav
                  variant="pills dkg-appication-navpills"
                  className="flex-row"
                  onSelect={(selectedKey) => SetEventKey(selectedKey)}
                >
                  <Nav.Item className="dkg-appication-navitem">
                    <Nav.Link
                      eventKey="app-applied"
                      className="dkg-appication-navlink"
                    >
                      Jobs Applied
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-appication-navitem">
                    <Nav.Link
                      eventKey="app-interviews"
                      className="dkg-appication-navlink"
                    >
                      Interviewed
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-appication-navitem">
                    <Nav.Link
                      eventKey="app-joboffers"
                      className="dkg-appication-navlink"
                    >
                      Jobs Offered
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-appication-navitem">
                    <Nav.Link
                      eventKey="app-joined"
                      className="dkg-appication-navlink"
                    >
                      Jobs Joined
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>

              <div
                className="col-12 col-md-12 dkg-appication-rightCol"
                id={applicationData.length}
              >
                <Tab.Content className="dkg-appication-tabContent">
                  <Tab.Pane
                    eventKey="app-applied"
                    className="dkg-appication-tabpane"
                  >
                    <div className="dkg-app-applied-tableCon">
                      {applicationData
                        .filter((data) => data.applicationType === "Applied")
                        .map((item, index) => (
                          <table
                            className="table dkg-app-applied-table mt-0 mb-0"
                            key={index + 1}
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th></th>
                                <th>Status</th>
                                <th colSpan={2}>
                                  <div className="dkg-jobapplied-th-cols">
                                    <div className="dkg-job-applied-th-title">
                                      Stage
                                    </div>
                                    <div className="dkg-job-applied-th-title">
                                      Progress
                                    </div>
                                    <div className="dkg-job-applied-th-title">
                                      Situation
                                    </div>
                                  </div>
                                </th>
                                <th>CASE UPDATED ON</th>
                              </tr>
                            </thead>
                            <ApplicationTable
                              key={index + 1}
                              id={index + 2}
                              removeDiv={removeDiv}
                              itemdata={item}
                            />
                          </table>
                        ))}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="app-interviews"
                    className="dkg-appication-tabpane"
                  > 
                   <div className="dkg-app-applied-tableCon">
                      {applicationData
                        .filter((data) => data.applicationType === "Interviewed")
                        .map((item, index) => (
                            <table
                              className="table dkg-app-applied-table mt-0 mb-0"
                              key={index + 1}
                            >
                              <thead>
                                <tr>
                                  <th>Job Applied</th>
                                  <th>Job Status</th>
                                  <th>Job Stage</th>
                                  <th>Job Progress</th>
                                  <th>Last Updated</th>
                                  <th></th>
                                </tr>
                              </thead>

                              <ApplicationTable
                                key={index + 1}
                                id={index + 2}
                                removeDiv={removeDiv}
                                itemdata={item}
                              />
                            </table>
                        ))}
                    </div>  
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="app-joboffers"
                    className="dkg-appication-tabpane"
                  > 
                    <div className="dkg-app-applied-tableCon">
                    {applicationData
                      .filter((data) => data.applicationType === "Offered")
                      .map((item, index) => (
                          <table
                            className="table dkg-app-applied-table mt-0 mb-0"
                            key={index + 1}
                          >
                            <thead>
                              <tr>
                                <th>Job Applied</th>
                                <th>Job Status</th>
                                <th>Job Stage</th>
                                <th>Job Progress</th>
                                <th>Last Updated</th>
                                <th></th>
                              </tr>
                            </thead>

                            <ApplicationTable
                              key={index + 1}
                              id={index + 2}
                              removeDiv={removeDiv}
                              itemdata={item}
                            />
                          </table>
                      ))}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="app-joined"
                    className="dkg-appication-tabpane"
                  > 
                    <div className="dkg-app-applied-tableCon">
                    {applicationData
                      .filter((data) => data.applicationType === "Joined")
                      .map((item, index) => (
                          <table
                            className="table dkg-app-applied-table mt-0 mb-0"
                            key={index + 1}
                          >
                            <thead>
                              <tr>
                                <th>Job Applied</th>
                                <th>Job Status</th>
                                <th>Job Stage</th>
                                <th>Job Progress</th>
                                <th>Last Updated</th>
                                <th></th>
                              </tr>
                            </thead>

                            <ApplicationTable
                              key={index + 1}
                              id={index + 2}
                              removeDiv={removeDiv}
                              itemdata={item}
                            />
                          </table>
                      ))}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default Appication;
