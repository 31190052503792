import React from 'react';
import { Link } from "react-router-dom";

const RecuitersLeftPanel = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <>
            <div className="dk-recuiters-kpi-Leftpanel">
                <ul>
                    <li><Link to="/kpis/recruiters/summary" className={`${(segment3 === 'summary') ? 'active' : ''}`}>Summary</Link></li>
                    <li><Link to="/kpis/recruiters/reports-assigned" className={`${(segment3 === 'reports-assigned') ? 'active' : ''}`}>Assigned</Link></li>
                    <li><Link to="/kpis/recruiters/reports-job-specs" className={`${(segment3 === 'reports-job-specs') ? 'active' : ''}`}>Job Specs</Link></li>
                    <li><Link to="/kpis/recruiters/reports-qualified" className={`${(segment3 === 'reports-qualified') ? 'active' : ''}`}>Qualified</Link></li>
                    <li><Link to="/kpis/recruiters/sendouts" className={`${(segment3 === 'sendouts') ? 'active' : ''}`}>Sendouts</Link></li>
                    <li><Link to="/kpis/recruiters/job-offers" className={`${(segment3 === 'job-offers') ? 'active' : ''}`}>Job Offers</Link></li>
                    <li><Link to="/kpis/recruiters/refused" className={`${(segment3 === 'refused') ? 'active' : ''}`}>Refused</Link></li>
                    <li><Link to="/kpis/recruiters/joined" className={`${(segment3 === 'joined') ? 'active' : ''}`}>Joined</Link></li>
                    <li><Link to="/kpis/recruiters/credit" className={`${(segment3 === 'credit') ? 'active' : ''}`}>Credit</Link></li>
                </ul>
            </div>
        </>
    )
}

export default RecuitersLeftPanel
