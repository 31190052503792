import React from 'react';
import UserImg from "../../../../../assets/images/deepak.png";
import '../summary/mainnotification.scss';
import { FaRegClock, FaRegCalendarAlt, FaRegBuilding, FaTasks, FaRegListAlt, FaEyeSlash } from 'react-icons/fa';
import OneDayLeftPanel from '../leftpanel/onedayleftpanel/OneDayLeftPanel';
import NotificationSeen from "../notificationseen/NotificationSeen";

const OneDayAgoSalesKpi = () => {
    return (
        <>
            <div className="dk-moduleNotification-main">
                <div className="dk-moduleNoti-Cols">
                    <div className="row">
                        <div className="col-md-2">
                            <div className="dk-moduleNotiLeftPanel">
                                <OneDayLeftPanel />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="dk-notiMiddleSec">
                                <div className="dk-moduleNoti-box active">
                                    <div className="dk-notificationImg">
                                        <img src={UserImg} alt="" />
                                    </div>
                                    <div className="dk-notificationText">
                                        {/* <div className="sbtitle">John Doe <span>reacted to your post</span></div> */}
                                        <div className="dk-notification-w100">
                                            <p>
                                                <span>Lorem Ipsum is simply dummy text of the printing</span>
                                            </p>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="dk-notification-tags">
                                                    <div className="sbtitle"><FaRegBuilding /> Company</div>
                                                    <div className="sbtitle"><FaRegListAlt /> Category</div>
                                                    <div className="sbtitle"><FaTasks /> Task</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-timeflex">
                                            <small><FaRegClock /> 10 Mins ago</small>
                                            <small><FaRegCalendarAlt /> 20 Jan 2022</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-moduleNoti-box">
                                    <div className="dk-notificationImg">
                                        <img src={UserImg} alt="" />
                                    </div>
                                    <div className="dk-notificationText">
                                        {/* <div className="sbtitle">John Doe <span>reacted to your post</span></div> */}
                                        <div className="dk-notification-w100">
                                            <p>
                                                <span>Lorem Ipsum is simply dummy text of the printing</span>
                                            </p>

                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="dk-notification-tags">
                                                    <div className="sbtitle"><FaRegBuilding /> Company</div>
                                                    <div className="sbtitle"><FaRegListAlt /> Category</div>
                                                    <div className="sbtitle"><FaTasks /> Task</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-timeflex">
                                            <small><FaRegClock /> 10 Mins ago</small>
                                            <small><FaRegCalendarAlt /> 20 Jan 2022</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-moduleNoti-box">
                                    <div className="dk-notificationImg">
                                        <img src={UserImg} alt="" />
                                    </div>
                                    <div className="dk-notificationText">
                                        {/* <div className="sbtitle">John Doe <span>reacted to your post</span></div> */}
                                        <div className="dk-notification-w100">
                                            <p>
                                                <span>Lorem Ipsum is simply dummy text of the printing</span>
                                            </p>

                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="dk-notification-tags">
                                                    <div className="sbtitle"><FaRegBuilding /> Company</div>
                                                    <div className="sbtitle"><FaRegListAlt /> Category</div>
                                                    <div className="sbtitle"><FaTasks /> Task</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-timeflex">
                                            <small><FaRegClock /> 10 Mins ago</small>
                                            <small><FaRegCalendarAlt /> 20 Jan 2022</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-moduleNoti-box">
                                    <div className="dk-notificationImg">
                                        <img src={UserImg} alt="" />
                                    </div>
                                    <div className="dk-notificationText">
                                        {/* <div className="sbtitle">John Doe <span>reacted to your post</span></div> */}
                                        <div className="dk-notification-w100">
                                            <p>
                                                <span>Lorem Ipsum is simply dummy text of the printing</span>
                                            </p>

                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="dk-notification-tags">
                                                    <div className="sbtitle"><FaRegBuilding /> Company</div>
                                                    <div className="sbtitle"><FaRegListAlt /> Category</div>
                                                    <div className="sbtitle"><FaTasks /> Task</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-timeflex">
                                            <small><FaRegClock /> 10 Mins ago</small>
                                            <small><FaRegCalendarAlt /> 20 Jan 2022</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-moduleNoti-box">
                                    <div className="dk-notificationImg">
                                        <img src={UserImg} alt="" />
                                    </div>
                                    <div className="dk-notificationText">
                                        {/* <div className="sbtitle">John Doe <span>reacted to your post</span></div> */}
                                        <div className="dk-notification-w100">
                                            <p>
                                                <span>Lorem Ipsum is simply dummy text of the printing</span>
                                            </p>

                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="dk-notification-tags">
                                                    <div className="sbtitle"><FaRegBuilding /> Company</div>
                                                    <div className="sbtitle"><FaRegListAlt /> Category</div>
                                                    <div className="sbtitle"><FaTasks /> Task</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-timeflex">
                                            <small><FaRegClock /> 10 Mins ago</small>
                                            <small><FaRegCalendarAlt /> 20 Jan 2022</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-moduleNoti-box">
                                    <div className="dk-notificationImg">
                                        <img src={UserImg} alt="" />
                                    </div>
                                    <div className="dk-notificationText">
                                        {/* <div className="sbtitle">John Doe <span>reacted to your post</span></div> */}
                                        <div className="dk-notification-w100">
                                            <p>
                                                <span>Lorem Ipsum is simply dummy text of the printing</span>
                                            </p>

                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="dk-notification-tags">
                                                    <div className="sbtitle"><FaRegBuilding /> Company</div>
                                                    <div className="sbtitle"><FaRegListAlt /> Category</div>
                                                    <div className="sbtitle"><FaTasks /> Task</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-timeflex">
                                            <small><FaRegClock /> 10 Mins ago</small>
                                            <small><FaRegCalendarAlt /> 20 Jan 2022</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-moduleNoti-box">
                                    <div className="dk-notificationImg">
                                        <img src={UserImg} alt="" />
                                    </div>
                                    <div className="dk-notificationText">
                                        {/* <div className="sbtitle">John Doe <span>reacted to your post</span></div> */}
                                        <div className="dk-notification-w100">
                                            <p>
                                                <span>Lorem Ipsum is simply dummy text of the printing</span>
                                            </p>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="dk-notification-tags">
                                                    <div className="sbtitle"><FaRegBuilding /> Company</div>
                                                    <div className="sbtitle"><FaRegListAlt /> Category</div>
                                                    <div className="sbtitle"><FaTasks /> Task</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-timeflex">
                                            <small><FaRegClock /> 10 Mins ago</small>
                                            <small><FaRegCalendarAlt /> 20 Jan 2022</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-moduleNoti-box">
                                    <div className="dk-notificationImg">
                                        <img src={UserImg} alt="" />
                                    </div>
                                    <div className="dk-notificationText">
                                        {/* <div className="sbtitle">John Doe <span>reacted to your post</span></div> */}
                                        <div className="dk-notification-w100">
                                            <p>
                                                <span>Lorem Ipsum is simply dummy text of the printing</span>
                                            </p>

                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="dk-notification-tags">
                                                    <div className="sbtitle"><FaRegBuilding /> Company</div>
                                                    <div className="sbtitle"><FaRegListAlt /> Category</div>
                                                    <div className="sbtitle"><FaTasks /> Task</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-timeflex">
                                            <small><FaRegClock /> 10 Mins ago</small>
                                            <small><FaRegCalendarAlt /> 20 Jan 2022</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-moduleNoti-box">
                                    <div className="dk-notificationImg">
                                        <img src={UserImg} alt="" />
                                    </div>
                                    <div className="dk-notificationText">
                                        {/* <div className="sbtitle">John Doe <span>reacted to your post</span></div> */}
                                        <div className="dk-notification-w100">
                                            <p>
                                                <span>Lorem Ipsum is simply dummy text of the printing</span>
                                            </p>

                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="dk-notification-tags">
                                                    <div className="sbtitle"><FaRegBuilding /> Company</div>
                                                    <div className="sbtitle"><FaRegListAlt /> Category</div>
                                                    <div className="sbtitle"><FaTasks /> Task</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-timeflex">
                                            <small><FaRegClock /> 10 Mins ago</small>
                                            <small><FaRegCalendarAlt /> 20 Jan 2022</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-moduleNoti-box">
                                    <div className="dk-notificationImg">
                                        <img src={UserImg} alt="" />
                                    </div>
                                    <div className="dk-notificationText">
                                        {/* <div className="sbtitle">John Doe <span>reacted to your post</span></div> */}
                                        <div className="dk-notification-w100">
                                            <p>
                                                <span>Lorem Ipsum is simply dummy text of the printing</span>
                                            </p>

                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="dk-notification-tags">
                                                    <div className="sbtitle"><FaRegBuilding /> Company</div>
                                                    <div className="sbtitle"><FaRegListAlt /> Category</div>
                                                    <div className="sbtitle"><FaTasks /> Task</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-timeflex">
                                            <small><FaRegClock /> 10 Mins ago</small>
                                            <small><FaRegCalendarAlt /> 20 Jan 2022</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-moduleNoti-box">
                                    <div className="dk-notificationImg">
                                        <img src={UserImg} alt="" />
                                    </div>
                                    <div className="dk-notificationText">
                                        {/* <div className="sbtitle">John Doe <span>reacted to your post</span></div> */}
                                        <div className="dk-notification-w100">
                                            <p>
                                                <span>Lorem Ipsum is simply dummy text of the printing</span>
                                            </p>

                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="dk-notification-tags">
                                                    <div className="sbtitle"><FaRegBuilding /> Company</div>
                                                    <div className="sbtitle"><FaRegListAlt /> Category</div>
                                                    <div className="sbtitle"><FaTasks /> Task</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-timeflex">
                                            <small><FaRegClock /> 10 Mins ago</small>
                                            <small><FaRegCalendarAlt /> 20 Jan 2022</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-moduleNoti-box">
                                    <div className="dk-notificationImg">
                                        <img src={UserImg} alt="" />
                                    </div>
                                    <div className="dk-notificationText">
                                        {/* <div className="sbtitle">John Doe <span>reacted to your post</span></div> */}
                                        <div className="dk-notification-w100">
                                            <p>
                                                <span>Lorem Ipsum is simply dummy text of the printing</span>
                                            </p>

                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="dk-notification-tags">
                                                    <div className="sbtitle"><FaRegBuilding /> Company</div>
                                                    <div className="sbtitle"><FaRegListAlt /> Category</div>
                                                    <div className="sbtitle"><FaTasks /> Task</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-timeflex">
                                            <small><FaRegClock /> 10 Mins ago</small>
                                            <small><FaRegCalendarAlt /> 20 Jan 2022</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <NotificationSeen />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default OneDayAgoSalesKpi;
