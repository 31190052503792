import React from "react";
// import PayTasksSubmenu from "./menu/mainsubmenu/PayTasksSubmenu";
import SettingsSubmenu from './SettingsSubmenu';
import ServiceSubmenu from './ServiceSubmenu'
import MyFileSubmenu from './MyFileSubmenu'
import MyProfileSubmenu from './MyProfileSubmenu'

const MyaccSubmenu = ({ path }) => {
    const renderSubmenu = () => {
        switch (path.split("/")[2]) { 

            case 'settings':
                return <SettingsSubmenu /> 
             
            case 'dkg-services':
                return <ServiceSubmenu />    

            case 'dkg-services':
                return <MyFileSubmenu />      

            case 'my-profile':
                return <MyProfileSubmenu /> 

            default:
                break
        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default MyaccSubmenu;