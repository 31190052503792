import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const DkgJobsDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="#" className={`${pathname.match('/training/commontraining') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/business') ? 'active' : ''}`}>Industry</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/industry') ? 'active' : ''}`}>Locations</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/services') ? 'active' : ''}`}>Conditions</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/clients') ? 'active' : ''}`}>Retentions</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/suppliers') ? 'active' : ''}`}>Roles Type</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/completion') ? 'active' : ''}`}>Experience</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Flexibility</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Complexity</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Descriptions</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Understanding</Link></li>

                    <li><Link to="#" className={`${pathname.match('/training/commontraining/employees') ? 'active' : ''}`}>Explainations</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default DkgJobsDetailLeftPanel;

