import React from 'react';
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import './anualstateskpi.scss';
import SalesKpiNav from '../SalesKpiNav';
import Recruiter from '../../../../../assets/images/deepak.png'

const AnnualSale = () => {
    return (
        <>
            <div className="dk-sales-anualkpiMain container-fluid">
                <div className="nav-tabs-navigation">
                    <SalesKpiNav />
                </div>
                <div className="row dk-anualkpiContainer">
                    <div className="col-md-2 col-sm-12">
                        <div className='dk-anualkpi-LeftCon'>
                            <div className="dk-searchBoxGroup">
                                <div className="input-group">
                                    <input id="srchterm" type="text" className="form-control searchTxtBox" name="search" placeholder="Search by Text" />
                                </div>
                            </div>
                            <Link to="#" className='dk-document-iconCon'><i className="fas fa-file-alt"></i></Link>
                            <div className='dk-user-dropdownCon'>
                                <Dropdown className='dk-user-dropdown'>
                                    <Dropdown.Toggle variant="" className='dk-user-dropdown-toggle'>
                                        <i className="fas fa-user"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dk-user-dropdownMenu'>
                                        <div className="d-flex">
                                            <Link to="#">
                                                <img src={Recruiter} alt="" />
                                            </Link>
                                            <Link to="#">
                                                <img src={Recruiter} alt="" />
                                            </Link>
                                            <Link to="#">
                                                <img src={Recruiter} alt="" />
                                            </Link>
                                            <Link to="#">
                                                <img src={Recruiter} alt="" />
                                            </Link>
                                            <Link to="#">
                                                <img src={Recruiter} alt="" />
                                            </Link>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className='dk-month-dropdownCon'>
                            <Dropdown className="dk-monthBox">
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    2022
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#">2016</Dropdown.Item>
                                    <Dropdown.Item href="#">2017</Dropdown.Item>
                                    <Dropdown.Item href="#">2018</Dropdown.Item>
                                    <Dropdown.Item href="#">2019</Dropdown.Item>
                                    <Dropdown.Item href="#">2020</Dropdown.Item>
                                    <Dropdown.Item href="#">2021</Dropdown.Item>
                                    <Dropdown.Item href="#" className="active">2022</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className='dk-anualkpi-saleskpi-RightCon'>
                            <div className='dk-reused-monthsCon mr-2'>
                                <ul>
                                    <li><Link to="#" className='active' >Year Sale</Link></li>
                                    <li><Link to="#">Case Opened</Link></li>
                                    <li><Link to="#">Job Spec Sent</Link></li>
                                    <li><Link to="#">Sendout</Link></li>
                                    <li><Link to="#">Refused Offers</Link></li>
                                    <li><Link to="#">Credit</Link></li>
                                    <li><Link to="#">Placement</Link></li>
                                </ul>
                            </div>
                            <Link className='dk-pluseIconCon'>
                                <i className="fas fa-plus"></i>
                            </Link>
                        </div>
                    </div>
                    <div className='col-md-12 col-sm-12'>
                        <div className="dk-anualkpi-tableCon">
                            <table className="dk-anualkpi-table table table-bordered">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>ID</th>
                                        <th>Date & Time</th>
                                        <th>Status</th>
                                        <th>Rec</th>
                                        <th>Candidate Name</th>
                                        <th>Full Fee</th>
                                        <th>Company</th>
                                        <th>Language</th>
                                        <th>Project</th>
                                        <th>Role Type</th>
                                        <th>CV Source</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-anualkpi-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-anualkpi-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-anualkpi-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-anualkpi-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-anualkpi-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-anualkpi-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-anualkpi-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-anualkpi-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-anualkpi-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-anualkpi-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-anualkpi-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-anualkpi-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-anualkpi-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-anualkpi-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-anualkpi-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-anualkpi-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="checkNo">
                                                <input type="checkbox" name="" id="" />
                                            </span>
                                            1
                                        </td>
                                        <td>42926</td>
                                        <td>16-Nov, 08:39</td>
                                        <td>
                                            <div className='dk-anualkpi-status-cols'>
                                                Case Opened
                                            </div>
                                        </td>
                                        <td>
                                            <div className="dk-anualkpi-rec-cols">
                                                <img src={Recruiter} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            Violeta Mihaela Dinu
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnnualSale;
