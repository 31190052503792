import React from 'react';

const DkgAdmins = () => {
    return (
        <>

        </>
    )
}

export default DkgAdmins;