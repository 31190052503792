import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import './deleteloginsoffice.scss';

const DeleteModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Link to="#" onClick={handleShow} className="dk-delete-btn" ><i className="fas fa-trash-alt"></i></Link>
            <Modal show={show} onHide={handleClose} className="dkg-delelte-office365Modal" centered>
                <Modal.Body className="dkg-delelte-office365ModalBody">
                    <p>Are you sure wants to Delete these records ?</p>
                    <div className="d-flex justify-content-end">
                        <button className='btn dkg-office-no-del-btn' onClick={handleClose}>No</button>
                        <button className=" btn dkg-office-yes-del-btn" type="submit">Yes</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DeleteModal