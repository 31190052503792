import React from 'react';
const Monthbg = ({ value }) => {
    let bgColor = "";
    switch (value) {
        case 'January':
            bgColor = '#884c5e'
            break;

        case 'February':
            bgColor = '#26596d'
            break;

        case 'March':
            bgColor = '#5a4c72'
            break;

        case 'April':
            bgColor = '#3c4c62'
            break;

        case 'May':
            bgColor = '#5c737d'
            break;
        case 'June':
            bgColor = '#4c5f59'
            break;
        case 'July':
            bgColor = '#2d9b99'
            break;
        case 'August':
            bgColor = '#144773'
            break;
        case 'September':
            bgColor = '#396a95'
            break;
        case 'October':
            bgColor = '#535255'
            break;
        case 'November':
            bgColor = '#b55355'
            break;
        case 'December':
            bgColor = '#3c97b6'
            break;

        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor, color: '#FFF', minWidth: '80px', minHeight: "37px", lineHeight: "37px" }}>{value}</div>
        </>
    )
}

export default Monthbg;