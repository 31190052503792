import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import "./viewpopup.scss";

import SubmitButton from "../../../../../../../ui/submitButton";
import { FaTimes } from "react-icons/fa";
import AWS from "aws-sdk";
import {
  updateAppliedApplication,
  clearState,
} from "../../../../../../../../slice/candidates/list/listSlice";
import { SYSTEM_CONSTANTS } from "../../../../../../../../constants";

const EditViewApplication = ({ onHide, itemdata }) => {
  const dispatch = useDispatch();
  const { isApplicationLoading } = useSelector((state) => state.candidates);

  const [selectedFileName, setSelectedFileName] = useState("");

  useEffect(() => {
    if (
      itemdata &&
      itemdata.appliedApplicaition &&
      itemdata.appliedApplicaition.length > 0
    ) {
      setSelectedFileName(itemdata.appliedApplicaition[0].onlyfileName);
    }
  }, [itemdata]);

  const [localPath, setLocalPath] = useState("");
  const [fileType, setFileType] = useState("appliedAapplication");
  const [onlyfileName, setonlyFileName] = useState("");
  const [fileName, setFile] = useState("");
  const [file_Type, setFile_Type] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const clearupload = () => {
    setSelectedFileName("");
  };
  const onSelectFileByAPI = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setFile_Type(e.target.files[0].type);
      setonlyFileName(e.target.files[0].name);
      let tmppath = URL.createObjectURL(e.target.files[0]);
      setLocalPath(tmppath);
    }
  };

  const handleButtonClick = async () => {
    setSuccess(true);
    setLoading(true);

    try {
      let s3bucket = new AWS.S3({
        accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
        secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
        Bucket: process.env.REACT_APP_BUCKET_NAME,
      });

      let fileNewName = Date.now() + "-" + onlyfileName;
      s3bucket.createBucket(function () {
        var params = {
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: fileNewName,
          Body: fileName,
          ContentType: file_Type,
        };

        s3bucket.upload(params, function (err, data) {
          if (err) {
            console.log(err);
          } else {
            setSelectedFileName(onlyfileName);
            dispatch(
              updateAppliedApplication({
                id: itemdata.canId,
                application_id: itemdata.id,
                fileName: SYSTEM_CONSTANTS.awsS3.Url + data.key,
                fileType: fileType,
                onlyfileName: onlyfileName,
              })
            );
          }
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isApplicationLoading) {
      setSuccess(false);
      setLoading(false);
    }
  }, [isApplicationLoading]);

  return (
    <React.Fragment>
      <Modal show={true} onHide={onHide} className="dkg-application-view-modal">
        <Modal.Header
          closeButton
          className="dkg-app-progress-modal-header dkg-job-application-modal-header"
        >
          <Modal.Title>JOB APPLICATION</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-app-widthraw-modal-body">
          <label htmlFor="" className="dkg-jobapp-label">
            Upload Job Application
          </label>
          {selectedFileName == "" ? (
            <div className="dkg-editview-app-con">
              <input
                type="file"
                id="imageInputAPI"
                onChange={onSelectFileByAPI}
                className="form-control w-100 dkg-payment-attach-input"
              />

              {/* {selectedFileName && (
                <span className="dkg-view-app-icon" onClick={clearupload}>
                  <FaTimes onClick={clearupload} />
                </span>
              )} */}
            </div>
          ) : (
            ""
          )}
           <div className="dkg-editview-app-con">
            {selectedFileName && <p className="form-control w-100 dkg-payment-attach-input mb-0" style={{backgroundColor: "#f2f2f2", lineHeight: "22px"}}>Selected file: {selectedFileName}</p>}{" "}
            {selectedFileName && (
              <span className="dkg-view-app-icon" onClick={clearupload}>
                <FaTimes onClick={clearupload} />
              </span>
            )}
           </div>
          {selectedFileName == "" ? (
            <div className="dkg-appwithdraw-btnCon dkg-viewapp-btnCon">
              <SubmitButton
                txt="SAVE"
                loading={loading}
                success={success}
                className="dkg-appwithdraw-btn dkg-view-app-btn"
                onClick={handleButtonClick}
              />
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default EditViewApplication;
