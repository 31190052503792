import React from 'react';
import AccIncomesSubmenu from "../../../elements/header/navbar/subMenu/menu/accountingmodule/AccIncomesSubmenu";

const Incomes = () => {
    return (
        <>
            <AccIncomesSubmenu />
            <div className='container-fluid' style={{ marginTop: "125px" }}>

            </div>
        </>
    )
}

export default Incomes;
