import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './myfilesmenu.scss';
import Submenu from "../../subMenu/MyFIlesSubmenu";
const MyFilesMenu = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK Global CRM";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-myfilesNavbar">
                <Route>
                    <ul>
                        <li><Link to="/my-files/dashboard" className={`${(segment2 === 'dashboard') ? 'active' : ''}`}>Dashboard </Link></li>
                        <li><Link to="/my-files/alerts/alerts1" className={`${pathname.match('/alerts') ? 'active' : ''}`}>Alerts</Link></li>
                        <li><Link to="/my-files/director/director1" className={`${pathname.match('/director') ? 'active' : ''}`}>Director</Link></li>
                        <li><Link to="/my-files/licenses/license1" className={`${pathname.match('/licenses') ? 'active' : ''}`}>Licenses</Link></li>
                        <li><Link to="/my-files/bank-account/bank-account1" className={(segment2 === "bank-account") ? 'active' : ''}>Contacts</Link></li>
                        <li><Link to="/my-files/accounts/account1" className={`${pathname.match('/accounts') ? 'active' : ''}`}>Financial</Link></li>
                        <li><Link to="/my-files/b1-returns/b1-return1" className={`${pathname.match('/b1-returns') ? 'active' : ''}`}>B1 Returns</Link></li>
                        <li><Link to="/my-files/tax-office/tax-office1" className={`${pathname.match('/tax-office') ? 'active' : ''}`}>Tax Office</Link></li>
                        <li><Link to="/my-files/certificates/certificate1" className={`${pathname.match('/certificates') ? 'active' : ''}`}>Certificates</Link></li>
                        <li><Link to="/my-files/membership/membership1" className={(segment2 === "membership") ? 'active' : ''}>Companies</Link></li>
                        <li><Link to="/my-files/agreements/agreement1" className={`${pathname.match('/agreements') ? 'active' : ''}`}>Agreements</Link></li>
                    </ul>
                </Route>
            </div>
            <Submenu path={pathname} />
        </React.Fragment>
    );
}

export default MyFilesMenu;