import React, { useEffect, useState, useRef } from "react";
import { Modal, Carousel } from "react-bootstrap";
import Pagetypealbums from "../../../../../../assets/images/full-image.png";
import "./full-image.scss";
import { FaTimes } from "react-icons/fa";

const FullImageModal = ({ hideFullImage, imglist }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const handleSelect = (selectedIndex, e) => {
    setCurrentPage(selectedIndex + 1);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={hideFullImage}
        className="dkg-fullimage-view-modal-21"
        centered
      >
        <Modal.Header closeButton className="dkg-fullimage-modalheader-21">
          <Modal.Title>Full Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-fullimage-modalbody-21">
          <div className="dkg-fullimage-crossicon" onClick={hideFullImage}>
            <FaTimes />
          </div>
          <Carousel
            className="dkg-fullimage-carousel"
            activeIndex={currentPage - 1}
            onSelect={handleSelect}
          >
            {imglist.map((item, index) => (
              <Carousel.Item
                key={index + 1}
                className="dkg-image-carousel-item"
              >
                <img
                  className="d-block w-100 dkg-carousel-image"
                  alt="First"
                  src={
                    item.imageName != "" && item.imageName != null
                      ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                        "/" +
                        item.imageName
                      : Pagetypealbums
                  }
                />
              </Carousel.Item>
            ))}
          </Carousel>
          <div className="dkg-fullimage-pagination">
            <div className="dkg-pagination-title">
              {currentPage} of {imglist.length} images
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FullImageModal;
