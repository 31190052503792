import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const SendBulkEmailDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/training/admins/send-bulk-email" className={`${pathname.match('/training/admins/send-bulk-email') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/training/admins/sendbulkemail/situations" className={`${pathname.match('/training/admins/sendbulkemail/situations') ? 'active' : ''}`}>Situations</Link></li>

                    <li><Link to="/training/admins/sendbulkemail/task-rules" className={`${pathname.match('/training/admins/sendbulkemail/task-rules') ? 'active' : ''}`}>Task Rules</Link></li>

                    <li><Link to="/training/admins/sendbulkemail/how-to-do" className={`${pathname.match('/training/admins/sendbulkemail/how-to-do') ? 'active' : ''}`}>How To Do</Link></li>

                    <li><Link to="/training/admins/sendbulkemail/create-list" className={`${pathname.match('/training/admins/sendbulkemail/create-list') ? 'active' : ''}`}>Create List</Link></li>

                    <li><Link to="/training/admins/sendbulkemail/create-template" className={`${pathname.match('/training/admins/sendbulkemail/create-template') ? 'active' : ''}`}>Create Template</Link></li>

                    <li><Link to="/training/admins/sendbulkemail/review-template" className={`${pathname.match('/training/admins/sendbulkemail/review-template') ? 'active' : ''}`}>Review Template</Link></li>

                    <li><Link to="/training/admins/sendbulkemail/send-bulk-emails" className={`${pathname.match('/training/admins/sendbulkemail/send-bulk-emails') ? 'active' : ''}`}>Send Bulk Emails</Link></li>

                    <li><Link to="/training/admins/sendbulkemail/track-responses" className={`${pathname.match('/training/admins/sendbulkemail/track-responses') ? 'active' : ''}`}>Track Responses</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default SendBulkEmailDetailLeftPanel;

