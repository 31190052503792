import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/trainingmodule/RecruitmentAdminTraining';

const RecruitmentAdminTrainingLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header mainheaderLink="Training" moduleLink="/dashboard/training" linkcolor="#2e97b6" mainheaderTitle=" - RECRUITMENT ADMIN" textcolor="#3c4c62" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default RecruitmentAdminTrainingLayout;
