import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import FaqImgIcon from "../../../assets/images/information_white.png";
import DkGlobalIcon from "../../../assets/images/dk_icon_white.png";
import RecruitmentImg from "../../../assets/images/recruitment.png";
import SuppliersImg from "../../../assets/images/supplier.png";
import HrIcon from "../../../assets/images/hr_white.png";
import BusinessDevelopmentImg from "../../../assets/images/businessmanagement_icon.png";
import AccountingFinanceImg from "../../../assets/images/finance.png";
import ManagementImg from "../../../assets/images/management-team.png";
import CommonImgIcon from "../../../assets/images/onboarding_white.png";
import CalendarImg from "../../../assets/images/calendar-white.png";
import ResourceImg from "../../../assets/images/resourcemanagement_icon.png";
import ITteamImg from "../../../assets/images/itteam.png";
import LegalDoc from "../../../assets/images/legal-doc.png";
import ClientsImg from "../../../assets/images/clientsmanagement_icon.png";
import { useDispatch, useSelector } from "react-redux";
import { setMyModule } from "../../../slice/modules/moduleSlice";
import Moduleheader from "../../elements/header/index";
import './trainingmodule.scss';

const TrainingModule = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth);

    const history = useHistory();
    const setModule = async (myModule) => {
        await dispatch(setMyModule({ module: myModule }))
        switch (myModule) {
            case 'onboardingadmintraining':
                history.push("/dkglobaladmin/onboardingadmintraining/onboardingdashboard");
                break;

            case 'commonadmintraining':
                history.push("/dkglobaladmin/commonadmintraining/commondashboard");
                break;

            case 'managementadmintraining':
                history.push("/dkglobaladmin/managementadmintraining/managementdashboard");
                break;

            case 'dashboard':
                history.push("/dkglobaladmin/admintraining/dashboard");
                break;

            case 'statusadmintraining':
                history.push("/dkglobaladmin/statusadmintraining/statusdashboard");
                break;

            case 'training-faq':
                history.push("/trainingmodule/training-faq/dashboard");
                break;

            case 'common-training':
                history.push("/training/common-training/dashboard");
                break;

            case 'recruitment-training':
                history.push("/dashboard/training/recruitment-training");
                break;

            default:
                break;
        }
    }
    return (
        <>
            <Moduleheader dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" linkcolor="#396a95" mainheaderTitle=" Training" textcolor="#396a95" />
            <div className="dk-master-moduleCon">
                <div className="dk-module-blocksContainer dkg-newtraining-department-234">
                    <h4 className="title">Training Department</h4>
                    <div className="dk-portalCols">
                        <div className="dk-portalBox" style={{ background: '#3c97b6' }}>
                            <Link to="#" onClick={() => setModule('statusadmintraining')}>
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={CalendarImg} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}>Schedule</div>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="dk-portalBox" style={{ background: '#3c97b6' }}>
                            <Link to="#" onClick={() => setModule('commonadmintraining')}>
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }} >
                                        <img src={DkGlobalIcon} alt="" />

                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}> DK Global</div>
                                </div>
                            </Link>
                        </div> */}
                        <div className="dk-portalBox" style={{ background: '#3c97b6' }}>
                            <Link to="#" onClick={() => setModule('common-training')}>
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={CommonImgIcon} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}> Common</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox" style={{ background: '#3c97b6' }}>
                            <Link to="#" onClick={() => setModule('training-faq')}>
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={FaqImgIcon} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}> FAQ</div>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="dk-portalBox">
                            <Link to="#" onClick={() => setModule('managementadmintraining')} style={{ background: '#396a95' }}>
                                <div className="dk-Portalicons"  style={{ textTransform: "capitalize" }}>
                                    <img src={ManagementImg} alt="" />
                                    Management
                                </div>
                            </Link>
                        </div> */}
                    </div>
                    <div className="dk-portalCols">
                        <div className="dk-portalBox" style={{ background: '#396a95' }}>
                            <Link to="#" onClick={() => setModule('recruitment-training')}>
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={RecruitmentImg} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}> Recruitment</div>
                                </div>
                            </Link>
                        </div>

                        <div className="dk-portalBox" style={{ background: '#396a95' }}>
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={AccountingFinanceImg} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}> Accounting</div>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="dk-portalBox mr-5" style={{ background: '#396a95' }}>
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={ResourceImg} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}>Suppliers</div>
                                </div>
                            </Link>
                        </div> */}
                        <div className="dk-portalBox" style={{ background: '#396a95' }}>
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={SuppliersImg} alt="" />

                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}>Suppliers</div>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="dk-portalBox">
                            <Link to="#" style={{ background: '#396a95' }}>
                                <div className="dk-Portalicons"  style={{ textTransform: "capitalize" }}>
                                    <img src={ItTeamImg} alt="" />
                                    IT Team
                                </div>
                            </Link>
                        </div> */}
                    </div>
                    <div className="dk-portalCols">
                        <div className="dk-portalBox" style={{ background: '#396a95' }}>
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={ClientsImg} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}>Clients</div>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="dk-portalBox" style={{ background: '#396a95' }}>
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={BusinessDevelopmentImg} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}> CLIENTS</div>
                                </div>
                            </Link>
                        </div> */}
                        <div className="dk-portalBox" style={{ background: '#396a95' }}>
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={HrIcon} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}>HR</div>
                                </div>
                            </Link>
                        </div>
                        <div className="dk-portalBox" style={{ background: '#396a95' }}>
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={LegalDoc} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}>Legal</div>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="dk-portalBox ml-2" style={{ background: '#396a95' }}>
                            <Link to="#">
                                <div>
                                    <div className="dk-Portalicons" style={{ textTransform: "capitalize" }}>
                                        <img src={ITteamImg} alt="" />
                                    </div>
                                    <div className='dk-PortalTitle' style={{ color: '#fff' }}>IT Team</div>
                                </div>
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrainingModule;