import React, { useEffect, useState, useRef } from 'react';
import { Modal, Dropdown } from 'react-bootstrap'
import { clearState, updateDetails } from "../../../../../../slice/accounting/invoiceBooks";
import { SYSTEM_CONSTANTS } from "../../../../../../constants";
import SubmitButton from "../../../../../ui/submitButton";
import { showSuccess, showError, clearMessage } from "../../../../../../slice/utils/message/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import { companyDataFetch, projectDataFetch, roleTypeDataFetch, languageDataFetch, cvSourceDataFetch } from "../../../../../../slice/kpis/assignKpisReportSlice";
import DatePicker from './DatePicker';
import moment from "moment-timezone";
import SelectDropsDown from "../../../../../pages/sendouts/utils/searchDropdown";
import { FaTrashAlt } from "react-icons/fa";


const EditDetails = ({ onHide }) => {

    const dispatch = useDispatch();

    const { isLoading, tasksDetails, isUpdate, message, isTaskUpdate, isTaskDelete, isTaskInserted } = useSelector(state => state.invoiceBooks);
    const { companyList, projectList, roleTypeList, languageList, cvSourceList } = useSelector(state => state.assignKpiReport)

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [category, setCategory] = useState('')

    const [joining, setJoining] = useState(tasksDetails.joining)
    const [resigned, setResigned] = useState(tasksDetails.resigned)
    const [created, setCreated] = useState(tasksDetails.created)
    const [dueDate, setDueDate] = useState(tasksDetails.dueDate)

    const [year, setYear] = useState(tasksDetails.year)
    const [month, setMonth] = useState(tasksDetails.month)
    const [company, setCompany] = useState(tasksDetails.company)
    const [candidateName, setCandidateName] = useState(tasksDetails.candidateName)
    const [fullFee, setFullFee] = useState(tasksDetails.fullFee)
    const [docNo, setDocNo] = useState(tasksDetails.docNo)
    const [language, setLanguage] = useState(tasksDetails.language)
    const [project, setProject] = useState(tasksDetails.project)
    const [roleType, setRoleType] = useState(tasksDetails.roleType)
    const [cvSource, setCvSource] = useState(tasksDetails.cvSource)

    useEffect(() => {
        const fetchList = async () => {
            try {

                await dispatch(companyDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.comapnyId }))
                await dispatch(projectDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.projectId }))
                await dispatch(roleTypeDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.roleType }))
                await dispatch(languageDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.languageId }))
                await dispatch(cvSourceDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.cvSourceId }))

            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
        if (isUpdate) {
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }
    }, [isUpdate])

    const updateData = async (e) => {

        const key = e.target.dataset.key;
        const value = e.target.dataset.value;

        if (key === 'roleType') {
            setRoleType(value)
        }
        if (key === 'project') {
            setProject(value)
        }
        if (key === 'company') {
            setCompany(value)
        }
        if (key === 'language') {
            setLanguage(value)
        }
        if (key === 'cvSource') {
            setCvSource(value)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (!candidateName) {
                dispatch(showError({ msg: 'All fileds required' }))
            } else {
                setLoading(true);

                try {
                    dispatch(updateDetails({ id: tasksDetails.id, joining, resigned, created, dueDate, year, month, company, language, candidateName,fullFee, docNo, project, roleType, cvSource }))
                } catch (err) {
                    console.log(err)
                }
            }
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }

    const handleDateChange = async (e, col) => {
        switch (col) {
            case 'joining':
                setJoining(moment(e).format('DD MMM YY'))
                break;
            case 'resigned':
                setResigned(moment(e).format('DD MMM YY'))
                break;
            case 'created':
                setCreated(moment(e).format('DD MMM YY'))
                break;
            default:
                setDueDate(moment(e).format('DD MMM YY'))
        }
    };

const clearinput = (e,col)=>{
   
    switch (col) {
        case 'joining':
            setJoining(null)
            break;
        case 'resigned':
            setResigned(null)
            break;
        case 'created':
            setCreated(null)
            break;
        default:
            setDueDate(null)
    }
}
    return (
        <>

            <Modal show={true} onHide={onHide} className="dk-addInvoiceModal-main dkg-addnew-paymenttask-modal-21">

                <Modal.Header closeButton className="dkg-addnew-payment-modalheader-21" style={{ background: '#5c737d' }}>
                    <Modal.Title>EDIT INVOICE/CREDIT</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-addnew-payment-modalbody-21">
                    <div className='row'>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Joining</label>
                                <div className='dkg-payment-task-drop12 dkg-invoice-addnewPopup dkg-edit-sales-books-calender'>
                                    <DatePicker handleDateChange={(e) => handleDateChange(e, 'joining')} selectedDate={joining} dateFormate="dd MMM yy" />
                                    <span className="dkg-invo-trashIcon">
                                        <FaTrashAlt onClick={(e)=>clearinput(e, 'joining')} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Resigned</label>
                                <div className='dkg-payment-task-drop12 dkg-invoice-addnewPopup dkg-edit-sales-books-calender'>
                                    <DatePicker handleDateChange={(e) => handleDateChange(e, 'resigned')} selectedDate={resigned} dateFormate="dd MMM yy" />
                                    <span className="dkg-invo-trashIcon">
                                        <FaTrashAlt onClick={(e)=>clearinput(e, 'resigned')} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Created</label>
                                <div className='dkg-payment-task-drop12 dkg-invoice-addnewPopup dkg-edit-sales-books-calender'>
                                    <DatePicker handleDateChange={(e) => handleDateChange(e, 'created')} selectedDate={created} dateFormate="dd MMM yy" />
                                    <span className="dkg-invo-trashIcon">
                                        <FaTrashAlt onClick={(e)=>clearinput(e, 'created')} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Due Date</label>
                                <div className='dkg-payment-task-drop12 dkg-invoice-addnewPopup dkg-edit-sales-books-calender'>
                                    <DatePicker handleDateChange={(e) => handleDateChange(e, 'dueDate')} selectedDate={dueDate} dateFormate="dd MMM yy" />
                                    <span className="dkg-invo-trashIcon">
                                        <FaTrashAlt onClick={(e)=>clearinput(e, 'dueDate')} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Month</label>
                                <div className='dkg-payment-task-drop12'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" className="form-control">
                                            {month}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu >
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#884c5e", color: "#fff" }} onClick={() => setMonth("January")} >January</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#26596d", color: "#fff" }} onClick={() => setMonth("February")} >February</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#5a4c72", color: "#fff" }} onClick={() => setMonth("March")} >March</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#3c4c62", color: "#fff" }} onClick={() => setMonth("April")} >April</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#5c737d", color: "#fff" }} onClick={() => setMonth("May")} >May</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#4c5f59", color: "#fff" }} onClick={() => setMonth("June")} >June</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#01796f", color: "#fff" }} onClick={() => setMonth("July")} >July</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#144773", color: "#fff" }} onClick={() => setMonth("August")} >August</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#396a95", color: "#fff" }} onClick={() => setMonth("September")} >September</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#535255", color: "#fff" }} onClick={() => setMonth("October")} >October</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#b55355", color: "#fff" }} onClick={() => setMonth("November")} >November</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#3c97b6", color: "#fff" }} onClick={() => setMonth("December")} >December</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>COMPANY</label>
                                <div className='dkg-payment-task-drop12'>
                                    <SelectDropsDown txt={company} list={companyList} updateProfile={updateData} column="company" className={(category == '' ? "errorBorder" : null)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>CANDIDATE NAME</label>
                                <input type="text" name="" id="" className="form-control" value={candidateName} onChange={(e) => setCandidateName(e.target.value)} />
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>DOCUMENT NO.</label>
                                <input type="text" name="" id="" className="form-control" value={docNo} onChange={(e) => setDocNo(e.target.value)} />
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Project</label>
                                <div className='dkg-payment-task-drop12'>
                                    <SelectDropsDown txt={project} list={projectList} updateProfile={updateData} column="project" className={(category == '' ? "errorBorder" : null)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Role Type</label>
                                <div className='dkg-payment-task-drop12'>
                                    <SelectDropsDown txt={roleType} list={roleTypeList} updateProfile={updateData} column="roleType" className={(category == '' ? "errorBorder" : null)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Language</label>
                                <div className='dkg-payment-task-drop12'>
                                    <SelectDropsDown txt={language} list={languageList} updateProfile={updateData} column="language" className={(category == '' ? "errorBorder" : null)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>CV Source</label>
                                <div className='dkg-payment-task-drop12'>
                                    <SelectDropsDown txt={cvSource} list={cvSourceList} updateProfile={updateData} column="cvSource" className={(category == '' ? "errorBorder" : null)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Full Fee</label>
                                <input type="text" name="" id="" className="form-control" value={fullFee} onChange={(e) => setFullFee(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-md-12 col-12'>
                            <div className='form-group justify-content-center mt-2'>
                                <SubmitButton txt="Update" loading={loading} success={success} onClick={handleSubmit} className="dkaddBtn" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditDetails