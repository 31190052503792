import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import './taskcategory.scss'


const CategoryDropdown = ({value,selectTaskTypeFilter}) => {
    const { taskTypeList, isError, isSuccess, message } = useSelector(state => state.task)
    const [taskType, setTaskType] = useState(value)

    useEffect(() => {
        setTaskType(value)       
    }, [value])

    return (
        <>
            <Dropdown className='dk-taskTypeDropdown-main'>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  {taskType}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                {
                    taskTypeList.map((item, index) =>
                        <Dropdown.Item className='dkg-taskdropdown-item-24'  key={index} onClick={() => {setTaskType(item.value); selectTaskTypeFilter(item.value)}}>{item.value}</Dropdown.Item>
                    )
                }
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default CategoryDropdown
