import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
export const GlobalSearch = ({ filter, setFilter }) => {
    const [value, setValue] = useState(filter);
    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined)
    }, 300)
    return (
        <React.Fragment>
            <div className="input-group">
                <input type="text" className="form-control" placeholder="Search..."
                    value={value || ''} onChange={(e) => {
                        setValue(e.target.value)
                        onChange(e.target.value)
                    }} />
                <div className="input-group-append">
                    <button className="btn btn-secondary" type="button"><i className="fa fa-search"></i></button>
                </div>
            </div>

        </React.Fragment>
    )
}

