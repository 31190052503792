import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const list = createAsyncThunk(
    'itResource/list',
    async (thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/resources/it-resources',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const addItResource = createAsyncThunk(
    'iTResource/add',
    async ({ uid, category, coverage, isPaid, source: sourceType, status, name, location }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/resources/it-resources',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        uid, category, coverage, isPaid, source: sourceType, status, name, location
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

export const detailsByid = createAsyncThunk(
    'iTResource/singleRecord',
    async ({ rowId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/resources/it-resources/' + rowId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

export const updateDetails = createAsyncThunk(
    'iTResource/update',
    async ({ rowId, uid, category, coverage, isPaid, source: sourceType, status, name, location }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/resources/it-resources/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        uid, category, coverage, isPaid, source: sourceType, status, name, location
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

export const deleteItRecord = createAsyncThunk(
    'iTResource/delete',
    async ({ rowId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/resources/it-resources/' + rowId,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

export const reorderRecord = createAsyncThunk(
    'iTResource/reorder',
    async ({ ids }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/resources/it-resources/reorder',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        ids
                    })
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

export const updateStatus = createAsyncThunk(
    'iTResource/changeStatus',
    async ({ rowId, status }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/resources/it-resources/updateStatus/' + rowId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer' + ' ' + token.access_token
                    },
                    body: JSON.stringify({
                        status
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);


const itResource = createSlice({
    name: 'itResources',
    initialState: {
        dataList: [],
        details: {},
        isSuccess: false,
        isInsert: false,
        isUpdate: false,
        isDelete: false,
        isLoading: false,
        isError: false,
        isReoder: false,
        msg: ''
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
            state.isError = false;
            state.isReoder = false;
            state.msg = '';
        }
    },
    extraReducers: {
        [list.pending]: (state) => {
            state.isLoading = true;
        },
        [list.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.dataList = payload.result;
        },
        [list.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        [addItResource.pending]: (state) => {
            state.isLoading = true;
        },
        [addItResource.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = payload.status;
            state.dataList.push(payload.result)
            state.msg = payload.message
        },
        [addItResource.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message
        },

        [detailsByid.pending]: (state) => {
            state.isLoading = true;
        },
        [detailsByid.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.details = payload.result;
        },
        [detailsByid.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
        },
        [updateDetails.pending]: (state) => {
            state.isLoading = true;
        },
        [updateDetails.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = payload.status;
            let mainArray = state.dataList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.dataList = finalResult
            state.msg = payload.message
        },
        [updateDetails.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.message
        },
        [deleteItRecord.pending]: (state) => {

        },
        [deleteItRecord.fulfilled]: (state, action) => {
            state.isDelete = true;
            state.dataList = state.dataList.filter((item) => item.id !== action.meta.arg.rowId)
            state.msg = 'Delete Successfully';
        },
        [deleteItRecord.rejected]: (state, { payload }) => {
            state.isDelete = false;
            state.isError = true;
            state.msg = payload.message
        },
        [reorderRecord.pending]: (state) => {
            state.isloading = true;
            state.isReoder = false;
        },
        [reorderRecord.fulfilled]: (state, { payload }) => {
            state.isloading = false;
            state.isReoder = true;
            state.msg = "Reorder successfully.";
        },
        [reorderRecord.rejected]: (state, { payload }) => {
            state.isloading = false;
            state.isError = payload.message;
            state.msg = payload.message;
        },
        [updateStatus.pending]: (state) => {
        },
        [updateStatus.fulfilled]: (state, { payload }) => {
            let mainArray = state.dataList;
            let finalResult = mainArray.map((r) => {
                if (r.id === payload.result.id) {
                    return (payload.result)
                } else {
                    return r
                }
            })
            state.isUpdate = true;
            state.dataList = finalResult
            state.msg = payload.message
        },
        [updateStatus.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = false;
            state.isError = true;
            state.msg = payload.msgBody
        },

    }
})
export const { clearState } = itResource.actions
export default itResource.reducer;
