import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import "./screennewform.scss";
import "../interviewnotes/interviewnotes.scss";
import moment from "moment-timezone";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
//import Requirement from '../requirements/Requirement';
import PophoverIcon from "./PophoverIcon";

import {
  clearState,
  clearTextBox,
  CVSourceFilterFirst,
  KeyQualificationFilterFirst,
  KeyQualificationFilterSecond,
  KeyQualificationFilterThird,
  KeyQualifyFilterFirst,
  KeyQualifyFilterSecond,
  KeyQualifyFilterThird,
  motivationFilterFirst,
  motivationFilterSecond,
  motivationFilterThird,
  JobDescriptionFilterFirst,
  JobDescriptionFilterSecond,
  JobDescriptionFilterThird,
  SalaryBenifitFilterFirst,
  SalaryBenifitFilterSecond,
  SalaryBenifitFilterThird,
  JobReqFilterFirst,
  JobReqFilterSecond,
  JobReqFilterThird,
  ReloactionQueryFilterFirst,
  ReloactionQueryFilterSecond,
  ReloactionQueryFilterThird,
  ProjectStartDateFilterFirst,
  ProjectStartDateFilterSecond,
  ProjectStartDateFilterThird,
  JoiningDocumentFilterFirst,
  JoiningDocumentFilterSecond,
  JoiningDocumentFilterThird,
  InterviewProcessFilterFirst,
  InterviewProcessFilterSecond,
  InterviewProcessFilterThird,
  FullResumePreviewFilterFirst,
  FullResumePreviewFilterSecond,
  FullResumePreviewFilterThird,
  OtherJobInterviewFilterFirst,
  OtherJobInterviewFilterSecond,
  OtherJobInterviewFilterThird,
  ContactPreferenceFilterFirst,
  ContactPreferenceFilterSecond,
  ContactPreferenceFilterThird,
  addNewValue,
  updateData,
  fetchScreeningList,
  screaningLevelFirst,
  screaningAvailability,
  screaningContactPrefered,
  getTextLevel,
  firstLevel5th_1,
  firstLevel5th_2,
  firstLevel5th_3,
  firstLevel6th_1,
  firstLevel6th_2,
  firstLevel6th_3,
  firstLevel7th_1,
  firstLevel7th_2,
  firstLevel7th_3,
  firstLevel8th_1,
  firstLevel8th_2,
  firstLevel8th_3,
} from "../../../../../../slice/candidatePrecreeningForm/candidatePrecreeningSlice";

import {
  monthsDataFetch,
  progressDataFetch,
  stageDataFetch,
  companyDataFetch,
  projectDataFetch,
  roleTypeDataFetch,
  languageDataFetch,
  cvSourceDataFetch,
} from "../../../../../../slice/kpis/assignKpisReportSlice";

import { SYSTEM_CONSTANTS } from "../../../../../../constants";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "./dropdown/DatePicker";
import UserList from "../../../../../ui/userList";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../../slice/utils/message/messageSlice";
import SubmitButton from "../../../../../ui/submitButton";
//import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  FaMobileAlt,
  FaEnvelope,
  FaGlobeEurope,
  FaUserAlt,
} from "react-icons/fa";
import SelectDropsDown from "./searchDropdown/";

const ScreenForm = ({ handleClose, dataRowId, isClearInput }) => {
  const dispatch = useDispatch();
  const {
    isLoading,
    keyQualificationFirstList,
    keyQualificationSecondList,
    keyQualificationThirdList,
    keyQualifyFirstList,
    keyQualifySecondList,
    keyQualifyThirdList,
    motivationFirstList,
    motivationSecondList,
    motivationThirdList,
    jobDescFirstList,
    jobDescSecondList,
    jobDescThirdList,
    SalaryFirstList,
    SalarySecondList,
    SalaryThirdList,
    JobRequirnmentFirstList,
    JobRequirnmentSecondList,
    JobRequirnmentThirdList,
    RelocationQryFirstList,
    RelocationQrySecondList,
    RelocationQryThirdList,
    ProjectStartDateFirstList,
    ProjectStartDateSecondList,
    ProjectStartDateThirdList,
    JoiningDocumentFirstList,
    JoiningDocumentSecondList,
    JoiningDocumentThirdList,
    InterviewProcessFirstList,
    InterviewProcessSecondList,
    InterviewProcessThirdList,
    FullResumePreviewFirstList,
    FullResumePreviewSecondList,
    FullResumePreviewThirdList,
    OtherJobInterviewFirstList,
    OtherJobInterviewSecondList,
    OtherJobInterviewThirdList,
    ContactPreferenceFirstList,
    ContactPreferenceSecondList,
    ContactPreferenceThirdList,
    CVSourceFirstList,
    isInserted,
    screanningDetails,
    screaningLevelList,
    screaningListAvailability,
    screaningPreferedList,
    firstLevelList,
    firstLevelFiveList,
    firstLevelFiveTwoList,
    firstLevelFiveThreeList,
    firstLevelSix1stList,
    firstLevelSix2ndList,
    firstLevelSix3rdList,
    firstLevelSeven1stList,
    firstLevelSeven2ndList,
    firstLevelSeven3rdtList,
    firstLevelEight1stList,
    firstLevelEight2ndList,
    firstLevelEight3rdtList
  } = useSelector((state) => state.candidatePrecreening);
  const {
    monthsList,
    companyList,
    projectList,
    roleTypeList,
    languageList,
    cvSourceList,
  } = useSelector((state) => state.assignKpiReport);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getTextLevel({ type: "1stLevel" }));
      } catch (err) {
        dispatch(showError({ msg: "Somthings went wrong" }));
      }
    }
    fetchData();
  }, [dispatch]);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!isLoading && firstLevelList && firstLevelList.length > 0) {
      setData(firstLevelList);
    }
  }, [isLoading]);

  useEffect(() => {
    const fetchList = async () => {
      try {
        await dispatch(
          companyDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.comapnyId })
        );
        await dispatch(
          projectDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.projectId })
        );
        await dispatch(
          roleTypeDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.roleType })
        );
        await dispatch(
          languageDataFetch({
            id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.languageId,
          })
        );
        await dispatch(
          cvSourceDataFetch({
            id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.cvSourceId,
          })
        );

        await dispatch(
          CVSourceFilterFirst({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.CvSource
              .firstFilterId,
          })
        );

        await dispatch(
          KeyQualificationFilterFirst({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualification
              .firstFilterId,
          })
        );
        await dispatch(
          KeyQualificationFilterSecond({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualification
              .secondFilterId,
          })
        );
        await dispatch(
          KeyQualificationFilterThird({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualification
              .thirdFilterId,
          })
        );

        await dispatch(
          KeyQualifyFilterFirst({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualify
              .firstFilterId,
          })
        );
        await dispatch(
          KeyQualifyFilterSecond({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualify
              .secondFilterId,
          })
        );
        await dispatch(
          KeyQualifyFilterThird({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.KeyQualify
              .thirdFilterId,
          })
        );

        await dispatch(
          motivationFilterFirst({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.Motivation
              .firstFilterId,
          })
        );
        await dispatch(
          motivationFilterSecond({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.Motivation
              .secondFilterId,
          })
        );
        await dispatch(
          motivationFilterThird({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.Motivation
              .thirdFilterId,
          })
        );

        await dispatch(
          JobDescriptionFilterFirst({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobDescription
              .firstFilterId,
          })
        );
        await dispatch(
          JobDescriptionFilterSecond({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobDescription
              .secondFilterId,
          })
        );
        await dispatch(
          JobDescriptionFilterThird({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobDescription
              .thirdFilterId,
          })
        );

        await dispatch(
          SalaryBenifitFilterFirst({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.SalaryBenifit
              .firstFilterId,
          })
        );
        await dispatch(
          SalaryBenifitFilterSecond({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.SalaryBenifit
              .secondFilterId,
          })
        );
        await dispatch(
          SalaryBenifitFilterThird({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.SalaryBenifit
              .thirdFilterId,
          })
        );

        await dispatch(
          JobReqFilterFirst({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
              .firstFilterId,
          })
        );
        await dispatch(
          JobReqFilterSecond({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
              .secondFilterId,
          })
        );
        await dispatch(
          JobReqFilterThird({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
              .thirdFilterId,
          })
        );

        await dispatch(
          JobReqFilterFirst({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
              .firstFilterId,
          })
        );
        await dispatch(
          JobReqFilterSecond({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
              .secondFilterId,
          })
        );
        await dispatch(
          JobReqFilterThird({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JobRequirnment
              .thirdFilterId,
          })
        );

        await dispatch(
          ReloactionQueryFilterFirst({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ReloactionQuery
              .firstFilterId,
          })
        );
        await dispatch(
          ReloactionQueryFilterSecond({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ReloactionQuery
              .secondFilterId,
          })
        );
        await dispatch(
          ReloactionQueryFilterThird({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ReloactionQuery
              .thirdFilterId,
          })
        );

        await dispatch(
          ProjectStartDateFilterFirst({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ProjectStartDate
              .firstFilterId,
          })
        );
        await dispatch(
          ProjectStartDateFilterSecond({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ProjectStartDate
              .secondFilterId,
          })
        );
        await dispatch(
          ProjectStartDateFilterThird({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ProjectStartDate
              .thirdFilterId,
          })
        );

        await dispatch(
          JoiningDocumentFilterFirst({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JoiningDocument
              .firstFilterId,
          })
        );
        await dispatch(
          JoiningDocumentFilterSecond({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JoiningDocument
              .secondFilterId,
          })
        );
        await dispatch(
          JoiningDocumentFilterThird({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.JoiningDocument
              .thirdFilterId,
          })
        );

        await dispatch(
          InterviewProcessFilterFirst({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.InterviewProcess
              .firstFilterId,
          })
        );
        await dispatch(
          InterviewProcessFilterSecond({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.InterviewProcess
              .secondFilterId,
          })
        );
        await dispatch(
          InterviewProcessFilterThird({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.InterviewProcess
              .thirdFilterId,
          })
        );

        await dispatch(
          FullResumePreviewFilterFirst({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.FullResumePreview
              .firstFilterId,
          })
        );
        await dispatch(
          FullResumePreviewFilterSecond({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.FullResumePreview
              .secondFilterId,
          })
        );
        await dispatch(
          FullResumePreviewFilterThird({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.FullResumePreview
              .thirdFilterId,
          })
        );

        await dispatch(
          OtherJobInterviewFilterFirst({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.OtherJobInterview
              .firstFilterId,
          })
        );
        await dispatch(
          OtherJobInterviewFilterSecond({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.OtherJobInterview
              .secondFilterId,
          })
        );
        await dispatch(
          OtherJobInterviewFilterThird({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.OtherJobInterview
              .thirdFilterId,
          })
        );

        await dispatch(
          ContactPreferenceFilterFirst({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ContactPreference
              .firstFilterId,
          })
        );
        await dispatch(
          ContactPreferenceFilterSecond({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ContactPreference
              .secondFilterId,
          })
        );
        await dispatch(
          ContactPreferenceFilterThird({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.ContactPreference
              .thirdFilterId,
          })
        );

        await dispatch(
          screaningLevelFirst({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.screningForm
              .firstDropdownId,
          })
        );
        await dispatch(
          firstLevel5th_1({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fiveBlock
              .firstDropdownId,
          })
        );
        await dispatch(
          firstLevel5th_2({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fiveBlock
              .secondDropdownId,
          })
        );
        await dispatch(
          firstLevel5th_3({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.fiveBlock
              .thirdDropdownId,
          })
        );

        await dispatch(
          firstLevel6th_1({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sixBlock
              .firstDropdownId,
          })
        );
        await dispatch(
          firstLevel6th_2({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sixBlock
              .secondDropdownId,
          })
        );
        await dispatch(
          firstLevel6th_3({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sixBlock
              .thirdDropdownId,
          })
        );
        await dispatch(
          firstLevel7th_1({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sevenBlock
              .firstDropdownId,
          })
        );
        await dispatch(
          firstLevel7th_2({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sevenBlock
              .secondDropdownId,
          })
        );
        await dispatch(
          firstLevel7th_3({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.sevenBlock
              .thirdDropdownId,
          })
        );
           await dispatch(
          firstLevel8th_1({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.eightBlock
              .firstDropdownId,
          })
        );
        await dispatch(
          firstLevel8th_2({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.eightBlock
              .secondDropdownId,
          })
        );
        await dispatch(
          firstLevel8th_3({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.eightBlock
              .thirdDropdownId,
          })
        );
        await dispatch(
          screaningAvailability({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.screningForm
              .secondDropdownId,
          })
        );
        await dispatch(
          screaningContactPrefered({
            id: SYSTEM_CONSTANTS.CANDIDATE_SCREANNING_FORM.screningForm
              .thirdDropdownId,
          })
        );
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  }, []);

  const reloadData = () => {
    setName("");
    setCompany("");
    setLocation("");
    setProject("");
    setRoleType("");
    setLanguage("");
    setEmail("");
    setPhone("");
    setVisaStatus("");
    setUserId("-1");
    setUserAMId("-1");
    setCVSourceFirst("");
    setKeyQualificationFirst("Select");
    setKeyQualificationSecond("Select");
    setKeyQualificationThird("Select");
    setKeyQualificationNotes("");
    setKeyQualificationNotes_2("");
    setKeyQualificationNotes_3("");

    setKeyQualifyFirst("Select");
    setKeyQualifySecond("Select");
    setKeyQualifyThird("Select");
    setKeyQualifyNotes("");
    setKeyQualifyNotes_2("");
    setKeyQualifyNotes_3("");

    setMotivationFirst("Select");
    setMotivationSecond("Select");
    setMotivationThird("Select");
    setMotivationNotes("");
    setMotivationNotes_2("");
    setMotivationNotes_3("");

    setjobDescFirst("Select");
    setjobDescSecond("Select");
    setjobDescThird("Select");
    setJobDescNotes("");
    setJobDescNotes_2("");
    setJobDescNotes_3("");

    setsalaryFirst("Select");
    setsalarySecond("Select");
    setsalaryThird("Select");
    setsalaryNotes("");
    setSalaryNotes_2("");
    setSalaryNotes_3("");

    setjobReqFirst("Select");
    setjobReqSecond("Select");
    setjobReqThird("Select");
    setJobReqNotes("");
    setJobReqNotes_2("");
    setJobReqNotes_3("");

    setRelocationFirst("Select");
    setRelocationSecond("Select");
    setRelocationThird("Select");
    setRelocationNotes("");
    setRelocationNotes_2("");
    setRelocationNotes_3("");

    setProjectStartDateFirst("Select");
    setProjectStartDateSecond("Select");
    setProjectStartDateThird("Select");
    setProjectStarNotes("");
    setProjectStartNotes_2("");
    setProjectStartNotes_3("");

    setJoiningDocumentFirst("Select");
    setJoiningDocumentSecond("Select");
    setJoiningDocumentThird("Select");
    setJoiningDocumentNotes("");
    setJoingingNotes_2("");
    setJoingingNotes_3("");

    setInterviewProcessFirst("Select");
    setInterviewProcessSecond("Select");
    setInterviewProcessThird("Select");
    setInterviewProcessNotes("");
    setInterviewProcessNotes_2("");
    setInterviewProcessNotes_3("");

    setFullResumePreviewFirst("Select");
    setFullResumePreviewSecond("Select");
    setFullResumePreviewThird("Select");
    setFullResumePreviewNotes("");
    setResumeOverviewNotes_2("");
    setResumeOverviewNotes_3("");

    setOtherJobInterviewFirst("Select");
    setOtherJobInterviewSecond("Select");
    setOtherJobInterviewThird("Select");
    setOtherJobInterviewNotes("");
    setOtherJobNotes_2("");
    setOtherJobNotes_3("");

    setContactPreferenceFirst("Select");
    setContactPreferenceSecond("Select");
    setContactPreferenceThird("Select");
    setContactPreferenceNotes("");
    setContactPreNotes_2("");
    setContactPreNotes_3("");

    setNotesScreeningFirst("");
    setNotesScreeningAbliFirst("");
    setNotesScreeningPreContact("");

    setFirstLanguage("");
    setSecondLanguage("");
    setQualifications("");
    setWorkExp("");
    setWorkLocation("");
    setInterviewModes("");
    setRelocationPack("");
    setCandidateForm("");
    setBackgroundCheck("");
    setJobStartingDates("");

    setSpFirstLanguage("");
    setSpSecondLanguage("");
    setSpQualifications("");
    setspWorkExp("");
    setSpWorkLocation("");
    setSpInterviewModes("");
    setSpRelocationPack("");
    setSpCandidateForm("");
    setSpBackgroundCheck("");
    setSpJobStartingDates("");
    setSendoutCanConcerns("");
    setSendoutCanRequirment("");
    setSendoutReqObserv("");
    setSendoutReqSendoutDec("");
    setScreeningFirst("");
    setScreeningAbliFirst("");
    setScreeningPreContact("");
    setFirstLevelFive("");
    setFirstLevelFive_1("");
    setFirstLevelFive_2("");
    setFirstLevelFive_3("");
    setFirstLevelFive_4("");
    setFirstLevelFive_5("");

    setFirstLevelSix("");
    setFirstLevelSix_1("");
    setFirstLevelSix_2("");
    setFirstLevelSix_3("");
    setFirstLevelSix_4("");
    setFirstLevelSix_5("");

    setFirstLevelSeven("");
    setFirstLevelSeven_1("");
    setFirstLevelSeven_2("");
    setFirstLevelSeven_3("");
    setFirstLevelSeven_4("");
    setFirstLevelSeven_5("");

    setFirstLevelEight("");
    setFirstLevelEight_1("");
    setFirstLevelEight_2("");
    setFirstLevelEight_3("");
    setFirstLevelEight_4("");
    setFirstLevelEight_5("");
  };

  useEffect(() => {
    // const fetchAllData = async () => {
    //     try {
    //         await dispatch(fetchScreeningList({}))
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    if (isInserted) {
      // fetchAllData();
      // setName('')
      // setLocation('')
      // setEmail('')
      // setPhone('')
      // setCompany('')
      // setProject('')
      // setRoleType('')
      // setLanguage('')
      // setVisaStatus('')
      setSuccess(false);
      setLoading(false);
      // setCVSourceFirst("Select")
      // setMotivationFirst("Select")
      // setMotivationSecond("Select")
      // setMotivationThird("Select")
      // setMotivationNotes("")
      // setjobDescFirst("Select")
      // setjobDescSecond("Select")
      // setjobDescThird("Select")
      // setJobDescNotes("")
      // setsalaryFirst("Select")
      // setsalarySecond("Select")
      // setsalaryThird("Select")
      // setsalaryNotes("")
      // setjobReqFirst("Select")
      // setjobReqSecond("Select")
      // setjobReqThird("Select")
      // setJobReqNotes("")
      // setRelocationFirst("Select")
      // setRelocationSecond("Select")
      // setRelocationThird("Select")
      // setRelocationNotes("")
      // setProjectStartDateFirst("Select")
      // setProjectStartDateSecond("Select")
      // setProjectStartDateThird("Select")
      // setProjectStarNotes("")
      // setJoiningDocumentFirst("Select")
      // setJoiningDocumentSecond("Select")
      // setJoiningDocumentThird("Select")
      // setJoiningDocumentNotes("")
      // setInterviewProcessFirst("Select")
      // setInterviewProcessSecond("Select")
      // setInterviewProcessThird("Select")
      // setInterviewProcessNotes("")
      // setFullResumePreviewFirst("Select")
      // setFullResumePreviewSecond("Select")
      // setFullResumePreviewThird("Select")
      // setFullResumePreviewNotes("")
      // setOtherJobInterviewFirst("Select")
      // setOtherJobInterviewSecond("Select")
      // setOtherJobInterviewThird("Select")
      // setOtherJobInterviewNotes("")
      // setContactPreferenceFirst("Select")
      // setContactPreferenceSecond("Select")
      // setContactPreferenceThird("Select")
      // setContactPreferenceNotes("")
      handleClose();
      dispatch(clearState());
      // dispatch(showSuccess({ msg: 'Data submitted successfully' }))
    }
  }, [isInserted]);

  const [name, setName] = useState(
    screanningDetails.name ? screanningDetails.name : ""
  );
  const [currentLocation, setLocation] = useState(
    screanningDetails.currentLocation ? screanningDetails.currentLocation : ""
  );
  const [email, setEmail] = useState(
    screanningDetails.email ? screanningDetails.email : ""
  );
  const [phone, setPhone] = useState(
    screanningDetails.phoneNo ? screanningDetails.phoneNo : ""
  );
  const [company, setCompany] = useState(
    screanningDetails.company ? screanningDetails.company : ""
  );
  const [project, setProject] = useState(
    screanningDetails.project ? screanningDetails.project : ""
  );
  const [roleType, setRoleType] = useState(
    screanningDetails.roleType ? screanningDetails.roleType : ""
  );
  const [language, setLanguage] = useState(
    screanningDetails.language ? screanningDetails.language : ""
  );
  
  const [visaStatus, setVisaStatus] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [userId, setUserId] = useState(
    screanningDetails.recruiter ? screanningDetails.recruiter : "-1"
  );
  const [userImg, setUserImg] = useState(
    screanningDetails.recruiterDetail
      ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
      screanningDetails.recruiterDetail.profileImage
      : ""
  );
  const [userImgName, setUserImgName] = useState(
    screanningDetails.recruiterDetail
      ? screanningDetails.recruiterDetail.firstName
      : ""
  );

  const [userAMId, setUserAMId] = useState(
    screanningDetails.am ? screanningDetails.am : "-1"
  );
  const [userAMImgName, setUserAMImgName] = useState(
    screanningDetails.amDetail ? screanningDetails.amDetail.firstName : ""
  );
  const [userAMImg, setUserAMImg] = useState(
    screanningDetails.amDetail
      ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
      screanningDetails.amDetail.profileImage
      : ""
  );

  const [CVSourceFirst, setCVSourceFirst] = useState(
    screanningDetails.cvSource ? screanningDetails.cvSource : ""
  );

  const [keyQualificationFirst, setKeyQualificationFirst] = useState(
    screanningDetails.key_qualification_1st_field
      ? screanningDetails.key_qualification_1st_field
      : "Select"
  );
  const [keyQualificationSecond, setKeyQualificationSecond] = useState(
    screanningDetails.key_qualification_2nd_field
      ? screanningDetails.key_qualification_2nd_field
      : "Select"
  );
  const [keyQualificationThird, setKeyQualificationThird] = useState(
    screanningDetails.key_qualification_3rd_field
      ? screanningDetails.key_qualification_3rd_field
      : "Select"
  );
  const [keyQualificationNotes, setKeyQualificationNotes] = useState(
    screanningDetails.key_qualification_notes
      ? screanningDetails.key_qualification_notes
      : ""
  );
  const [key_qualification_notes_2, setKeyQualificationNotes_2] = useState(
    screanningDetails.key_qualification_notes_2
      ? screanningDetails.key_qualification_notes_2
      : ""
  );
  const [key_qualification_notes_3, setKeyQualificationNotes_3] = useState(
    screanningDetails.key_qualification_notes_3
      ? screanningDetails.key_qualification_notes_3
      : ""
  );

  const [keyQualifyFirst, setKeyQualifyFirst] = useState(
    screanningDetails.key_qualify_1st_field
      ? screanningDetails.key_qualify_1st_field
      : "Select"
  );
  const [keyQualifySecond, setKeyQualifySecond] = useState(
    screanningDetails.key_qualify_2nd_field
      ? screanningDetails.key_qualify_2nd_field
      : "Select"
  );
  const [keyQualifyThird, setKeyQualifyThird] = useState(
    screanningDetails.key_qualify_3rd_field
      ? screanningDetails.key_qualify_3rd_field
      : "Select"
  );
  const [keyQualifyNotes, setKeyQualifyNotes] = useState(
    screanningDetails.key_qualify_notes_1
      ? screanningDetails.key_qualify_notes_1
      : ""
  );
  const [key_qualify_notes_2, setKeyQualifyNotes_2] = useState(
    screanningDetails.key_qualify_notes_2
      ? screanningDetails.key_qualify_notes_2
      : ""
  );
  const [key_qualify_notes_3, setKeyQualifyNotes_3] = useState(
    screanningDetails.key_qualify_notes_3
      ? screanningDetails.key_qualify_notes_3
      : ""
  );

  const [motivationFirst, setMotivationFirst] = useState(
    screanningDetails.motivation_1st_field
      ? screanningDetails.motivation_1st_field
      : "Select"
  );
  const [motivationSecond, setMotivationSecond] = useState(
    screanningDetails.motivation_2nd_field
      ? screanningDetails.motivation_2nd_field
      : "Select"
  );
  const [motivationThird, setMotivationThird] = useState(
    screanningDetails.motivation_3rd_field
      ? screanningDetails.motivation_3rd_field
      : "Select"
  );
  const [motivationNotes, setMotivationNotes] = useState(
    screanningDetails.motivation_notes ? screanningDetails.motivation_notes : ""
  );
  const [motivation_notes_2, setMotivationNotes_2] = useState(
    screanningDetails.motivation_notes_2
      ? screanningDetails.motivation_notes_2
      : ""
  );
  const [motivation_notes_3, setMotivationNotes_3] = useState(
    screanningDetails.motivation_notes_3
      ? screanningDetails.motivation_notes_3
      : ""
  );

  const [jobDescFirst, setjobDescFirst] = useState(
    screanningDetails.job_desc_1st_field
      ? screanningDetails.job_desc_1st_field
      : "Select"
  );
  const [jobDescSecond, setjobDescSecond] = useState(
    screanningDetails.job_desc_2nd_field
      ? screanningDetails.job_desc_2nd_field
      : "Select"
  );
  const [jobDescThird, setjobDescThird] = useState(
    screanningDetails.job_desc_3rd_field
      ? screanningDetails.job_desc_3rd_field
      : "Select"
  );
  const [jobDescNotes, setJobDescNotes] = useState(
    screanningDetails.job_desc_notes ? screanningDetails.job_desc_notes : ""
  );
  const [job_desc_notes_2, setJobDescNotes_2] = useState(
    screanningDetails.job_desc_notes_2 ? screanningDetails.job_desc_notes_2 : ""
  );
  const [job_desc_notes_3, setJobDescNotes_3] = useState(
    screanningDetails.job_desc_notes_3 ? screanningDetails.job_desc_notes_3 : ""
  );

  const [salaryFirst, setsalaryFirst] = useState(
    screanningDetails.salary_benifits_1st_field
      ? screanningDetails.salary_benifits_1st_field
      : "Select"
  );
  const [salarySecond, setsalarySecond] = useState(
    screanningDetails.salary_benifits_2nd_field
      ? screanningDetails.salary_benifits_2nd_field
      : "Select"
  );
  const [salaryThird, setsalaryThird] = useState(
    screanningDetails.salary_benifits_3rd_field
      ? screanningDetails.salary_benifits_3rd_field
      : "Select"
  );
  const [salaryNotes, setsalaryNotes] = useState(
    screanningDetails.salary_benifits_notes
      ? screanningDetails.salary_benifits_notes
      : ""
  );
  const [salary_benifits_notes_2, setSalaryNotes_2] = useState(
    screanningDetails.salary_benifits_notes_2
      ? screanningDetails.salary_benifits_notes_2
      : ""
  );
  const [salary_benifits_notes_3, setSalaryNotes_3] = useState(
    screanningDetails.salary_benifits_notes_3
      ? screanningDetails.salary_benifits_notes_3
      : ""
  );

  const [jobReqFirst, setjobReqFirst] = useState(
    screanningDetails.job_requirement_1st_field
      ? screanningDetails.job_requirement_1st_field
      : "Select"
  );
  const [jobReqSecond, setjobReqSecond] = useState(
    screanningDetails.job_requirement_2nd_field
      ? screanningDetails.job_requirement_2nd_field
      : "Select"
  );
  const [jobReqThird, setjobReqThird] = useState(
    screanningDetails.job_requirement_3rd_field
      ? screanningDetails.job_requirement_3rd_field
      : "Select"
  );
  const [jobReqNotes, setJobReqNotes] = useState(
    screanningDetails.job_requirement_notes
      ? screanningDetails.job_requirement_notes
      : ""
  );
  const [job_requirement_notes_2, setJobReqNotes_2] = useState(
    screanningDetails.job_requirement_notes_2
      ? screanningDetails.job_requirement_notes_2
      : ""
  );
  const [job_requirement_notes_3, setJobReqNotes_3] = useState(
    screanningDetails.job_requirement_notes_3
      ? screanningDetails.job_requirement_notes_3
      : ""
  );

  const [RelocationFirst, setRelocationFirst] = useState(
    screanningDetails.relocation_query_1st_field
      ? screanningDetails.relocation_query_1st_field
      : "Select"
  );
  const [RelocationSecond, setRelocationSecond] = useState(
    screanningDetails.relocation_query_2nd_field
      ? screanningDetails.relocation_query_2nd_field
      : "Select"
  );
  const [RelocationThird, setRelocationThird] = useState(
    screanningDetails.relocation_query_3rd_field
      ? screanningDetails.relocation_query_3rd_field
      : "Select"
  );
  const [RelocationNotes, setRelocationNotes] = useState(
    screanningDetails.relocation_query_notes
      ? screanningDetails.relocation_query_notes
      : ""
  );
  const [relocation_query_notes_2, setRelocationNotes_2] = useState(
    screanningDetails.relocation_query_notes_2
      ? screanningDetails.relocation_query_notes_2
      : ""
  );
  const [relocation_query_notes_3, setRelocationNotes_3] = useState(
    screanningDetails.relocation_query_notes_3
      ? screanningDetails.relocation_query_notes_3
      : ""
  );

  const [ProjectStartDateFirst, setProjectStartDateFirst] = useState(
    screanningDetails.project_start_date_1st_field
      ? screanningDetails.project_start_date_1st_field
      : "Select"
  );
  const [ProjectStartDateSecond, setProjectStartDateSecond] = useState(
    screanningDetails.project_start_date_2nd_field
      ? screanningDetails.project_start_date_2nd_field
      : "Select"
  );
  const [ProjectStartDateThird, setProjectStartDateThird] = useState(
    screanningDetails.project_start_date_3rd_field
      ? screanningDetails.project_start_date_3rd_field
      : "Select"
  );
  const [ProjectStarNotes, setProjectStarNotes] = useState(
    screanningDetails.project_start_date_notes
      ? screanningDetails.project_start_date_notes
      : ""
  );
  const [project_start_date_notes_2, setProjectStartNotes_2] = useState(
    screanningDetails.project_start_date_notes_2
      ? screanningDetails.project_start_date_notes_2
      : ""
  );
  const [project_start_date_notes_3, setProjectStartNotes_3] = useState(
    screanningDetails.project_start_date_notes_3
      ? screanningDetails.project_start_date_notes_3
      : ""
  );

  const [JoiningDocumentFirst, setJoiningDocumentFirst] = useState(
    screanningDetails.joining_docs_1st_field
      ? screanningDetails.joining_docs_1st_field
      : "Select"
  );
  const [JoiningDocumentSecond, setJoiningDocumentSecond] = useState(
    screanningDetails.joining_docs_2nd_field
      ? screanningDetails.joining_docs_2nd_field
      : "Select"
  );
  const [JoiningDocumentThird, setJoiningDocumentThird] = useState(
    screanningDetails.joining_docs_3rd_field
      ? screanningDetails.joining_docs_3rd_field
      : "Select"
  );
  const [JoiningDocumentNotes, setJoiningDocumentNotes] = useState(
    screanningDetails.joining_docs_notes
      ? screanningDetails.joining_docs_notes
      : ""
  );
  const [joining_docs_notes_2, setJoingingNotes_2] = useState(
    screanningDetails.joining_docs_notes_2
      ? screanningDetails.joining_docs_notes_2
      : ""
  );
  const [joining_docs_notes_3, setJoingingNotes_3] = useState(
    screanningDetails.joining_docs_notes_3
      ? screanningDetails.joining_docs_notes_3
      : ""
  );

  const [InterviewProcessFirst, setInterviewProcessFirst] = useState(
    screanningDetails.interview_process_1st_field
      ? screanningDetails.interview_process_1st_field
      : "Select"
  );
  const [InterviewProcessSecond, setInterviewProcessSecond] = useState(
    screanningDetails.interview_process_2nd_field
      ? screanningDetails.interview_process_2nd_field
      : "Select"
  );
  const [InterviewProcessThird, setInterviewProcessThird] = useState(
    screanningDetails.interview_process_3rd_field
      ? screanningDetails.interview_process_3rd_field
      : "Select"
  );
  const [InterviewProcessNotes, setInterviewProcessNotes] = useState(
    screanningDetails.interview_process_notes
      ? screanningDetails.interview_process_notes
      : ""
  );
  const [interview_process_notes_2, setInterviewProcessNotes_2] = useState(
    screanningDetails.interview_process_notes_2
      ? screanningDetails.interview_process_notes_2
      : ""
  );
  const [interview_process_notes_3, setInterviewProcessNotes_3] = useState(
    screanningDetails.interview_process_notes_3
      ? screanningDetails.interview_process_notes_3
      : ""
  );

  const [FullResumePreviewFirst, setFullResumePreviewFirst] = useState(
    screanningDetails.resume_overview_1st_field
      ? screanningDetails.resume_overview_1st_field
      : "Select"
  );
  const [FullResumePreviewSecond, setFullResumePreviewSecond] = useState(
    screanningDetails.resume_overview_2nd_field
      ? screanningDetails.resume_overview_2nd_field
      : "Select"
  );
  const [FullResumePreviewThird, setFullResumePreviewThird] = useState(
    screanningDetails.resume_overview_3rd_field
      ? screanningDetails.resume_overview_3rd_field
      : "Select"
  );
  const [FullResumePreviewNotes, setFullResumePreviewNotes] = useState(
    screanningDetails.resume_overview_notes
      ? screanningDetails.resume_overview_notes
      : ""
  );
  const [resume_overview_notes_2, setResumeOverviewNotes_2] = useState(
    screanningDetails.resume_overview_notes_2
      ? screanningDetails.resume_overview_notes_2
      : ""
  );
  const [resume_overview_notes_3, setResumeOverviewNotes_3] = useState(
    screanningDetails.resume_overview_notes_3
      ? screanningDetails.resume_overview_notes_3
      : ""
  );

  const [OtherJobInterviewFirst, setOtherJobInterviewFirst] = useState(
    screanningDetails.other_job_interview_1st_field
      ? screanningDetails.other_job_interview_1st_field
      : "Select"
  );
  const [OtherJobInterviewSecond, setOtherJobInterviewSecond] = useState(
    screanningDetails.other_job_interview_2nd_field
      ? screanningDetails.other_job_interview_2nd_field
      : "Select"
  );
  const [OtherJobInterviewThird, setOtherJobInterviewThird] = useState(
    screanningDetails.other_job_interview_3rd_field
      ? screanningDetails.other_job_interview_3rd_field
      : "Select"
  );
  const [OtherJobInterviewNotes, setOtherJobInterviewNotes] = useState(
    screanningDetails.other_job_interview_notes
      ? screanningDetails.other_job_interview_notes
      : ""
  );
  const [other_job_interview_notes_2, setOtherJobNotes_2] = useState(
    screanningDetails.other_job_interview_notes_2
      ? screanningDetails.other_job_interview_notes_2
      : ""
  );
  const [other_job_interview_notes_3, setOtherJobNotes_3] = useState(
    screanningDetails.other_job_interview_notes_3
      ? screanningDetails.other_job_interview_notes_3
      : ""
  );

  const [ContactPreferenceFirst, setContactPreferenceFirst] = useState(
    screanningDetails.contact_preference_1st_field
      ? screanningDetails.contact_preference_1st_field
      : "Select"
  );
  const [ContactPreferenceSecond, setContactPreferenceSecond] = useState(
    screanningDetails.contact_preference_2nd_field
      ? screanningDetails.contact_preference_2nd_field
      : "Select"
  );
  const [ContactPreferenceThird, setContactPreferenceThird] = useState(
    screanningDetails.contact_preference_3rd_field
      ? screanningDetails.contact_preference_3rd_field
      : "Select"
  );
  const [ContactPreferenceNotes, setContactPreferenceNotes] = useState(
    screanningDetails.contact_preference_notes
      ? screanningDetails.contact_preference_notes
      : ""
  );
  const [contact_preference_notes_2, setContactPreNotes_2] = useState(
    screanningDetails.contact_preference_notes_2
      ? screanningDetails.contact_preference_notes_2
      : ""
  );
  const [contact_preference_notes_3, setContactPreNotes_3] = useState(
    screanningDetails.contact_preference_notes_3
      ? screanningDetails.contact_preference_notes_3
      : ""
  );

  const [firstLanguage, setFirstLanguage] = useState(
    screanningDetails.standardFormDetail
      ? screanningDetails.standardFormDetail.firstLanguage
      : ""
  );
  const [secondLanguage, setSecondLanguage] = useState(
    screanningDetails.standardFormDetail
      ? screanningDetails.standardFormDetail.secondLanguage
      : ""
  );
  const [qualifications, setQualifications] = useState(
    screanningDetails.standardFormDetail
      ? screanningDetails.standardFormDetail.qualifications
      : ""
  );
  const [workExp, setWorkExp] = useState(
    screanningDetails.standardFormDetail
      ? screanningDetails.standardFormDetail.workExp
      : ""
  );
  const [workLocation, setWorkLocation] = useState(
    screanningDetails.standardFormDetail
      ? screanningDetails.standardFormDetail.workLocation
      : ""
  );
  const [interviewModes, setInterviewModes] = useState(
    screanningDetails.standardFormDetail
      ? screanningDetails.standardFormDetail.interviewModes
      : ""
  );
  const [relocationPack, setRelocationPack] = useState(
    screanningDetails.standardFormDetail
      ? screanningDetails.standardFormDetail.relocationPack
      : ""
  );
  const [candidateForm, setCandidateForm] = useState(
    screanningDetails.standardFormDetail
      ? screanningDetails.standardFormDetail.candidateForm
      : ""
  );
  const [backgroundCheck, setBackgroundCheck] = useState(
    screanningDetails.standardFormDetail
      ? screanningDetails.standardFormDetail.backgroundCheck
      : ""
  );
  const [jobStartingDates, setJobStartingDates] = useState(
    screanningDetails.standardFormDetail
      ? screanningDetails.standardFormDetail.jobStartingDates
      : ""
  );

  const [spFirstLanguage, setSpFirstLanguage] = useState(
    screanningDetails.specialFormDetail
      ? screanningDetails.specialFormDetail.firstLanguage
      : ""
  );
  const [spSecondLanguage, setSpSecondLanguage] = useState(
    screanningDetails.specialFormDetail
      ? screanningDetails.specialFormDetail.secondLanguage
      : ""
  );
  const [spQualifications, setSpQualifications] = useState(
    screanningDetails.specialFormDetail
      ? screanningDetails.specialFormDetail.qualifications
      : ""
  );
  const [spWorkExp, setspWorkExp] = useState(
    screanningDetails.specialFormDetail
      ? screanningDetails.specialFormDetail.workExp
      : ""
  );
  const [spWorkLocation, setSpWorkLocation] = useState(
    screanningDetails.specialFormDetail
      ? screanningDetails.specialFormDetail.workLocation
      : ""
  );
  const [spInterviewModes, setSpInterviewModes] = useState(
    screanningDetails.specialFormDetail
      ? screanningDetails.specialFormDetail.interviewModes
      : ""
  );
  const [spRelocationPack, setSpRelocationPack] = useState(
    screanningDetails.specialFormDetail
      ? screanningDetails.specialFormDetail.relocationPack
      : ""
  );
  const [spCandidateForm, setSpCandidateForm] = useState(
    screanningDetails.specialFormDetail
      ? screanningDetails.specialFormDetail.candidateForm
      : ""
  );
  const [spBackgroundCheck, setSpBackgroundCheck] = useState(
    screanningDetails.specialFormDetail
      ? screanningDetails.specialFormDetail.backgroundCheck
      : ""
  );
  const [spJobStartingDates, setSpJobStartingDates] = useState(
    screanningDetails.specialFormDetail
      ? screanningDetails.specialFormDetail.jobStartingDates
      : ""
  );
  const [sendoutCanConcerns, setSendoutCanConcerns] = useState(
    screanningDetails.sendoutCanConcerns
      ? screanningDetails.sendoutCanConcerns
      : ""
  );
  const [sendoutCanRequirment, setSendoutCanRequirment] = useState(
    screanningDetails.sendoutCanRequirment
      ? screanningDetails.sendoutCanRequirment
      : ""
  );
  const [sendoutReqObserv, setSendoutReqObserv] = useState(
    screanningDetails.sendoutReqObserv ? screanningDetails.sendoutReqObserv : ""
  );
  const [sendoutReqSendoutDec, setSendoutReqSendoutDec] = useState(
    screanningDetails.sendoutReqSendoutDec
      ? screanningDetails.sendoutReqSendoutDec
      : ""
  );

  const [screeningFirst, setScreeningFirst] = useState(
    screanningDetails.screeningFirst
      ? screanningDetails.screeningFirst
      : "Select"
  );

  const [firstLevelFive, setFirstLevelFive] = useState(
    screanningDetails.firstLevelFive
      ? screanningDetails.firstLevelFive
      : "Select"
  );
  const [firstLevelFive_1, setFirstLevelFive_1] = useState(
    screanningDetails.firstLevelFive_1
      ? screanningDetails.firstLevelFive_1
      : "Select"
  );
  const [firstLevelFive_2, setFirstLevelFive_2] = useState(
    screanningDetails.firstLevelFive_2
      ? screanningDetails.firstLevelFive_2
      : "Select"
  );
   const [firstLevelFive_3, setFirstLevelFive_3] = useState(
    screanningDetails.firstLevelFive_3
      ? screanningDetails.firstLevelFive_3
      : ""
  );
  const [firstLevelFive_4, setFirstLevelFive_4] = useState(
    screanningDetails.firstLevelFive_4
      ? screanningDetails.firstLevelFive_4
      : ""
  );
  const [firstLevelFive_5, setFirstLevelFive_5] = useState(
    screanningDetails.firstLevelFive_5
      ? screanningDetails.firstLevelFive_5
      : ""
  );

  const [firstLevelSix, setFirstLevelSix] = useState(
    screanningDetails.firstLevelSix
      ? screanningDetails.firstLevelSix
      : "Select"
  );
  const [firstLevelSix_1, setFirstLevelSix_1] = useState(
    screanningDetails.firstLevelSix_1
      ? screanningDetails.firstLevelSix_1
      : "Select"
  );
  const [firstLevelSix_2, setFirstLevelSix_2] = useState(
    screanningDetails.firstLevelSix_2
      ? screanningDetails.firstLevelSix_2
      : "Select"
  );
   const [firstLevelSix_3, setFirstLevelSix_3] = useState(
    screanningDetails.firstLevelSix_3
      ? screanningDetails.firstLevelSix_3
      : ""
  );
  const [firstLevelSix_4, setFirstLevelSix_4] = useState(
    screanningDetails.firstLevelSix_4
      ? screanningDetails.firstLevelSix_4
      : ""
  );
  const [firstLevelSix_5, setFirstLevelSix_5] = useState(
    screanningDetails.firstLevelSix_5
      ? screanningDetails.firstLevelSix_5
      : ""
  );

  const [firstLevelSeven, setFirstLevelSeven] = useState(
    screanningDetails.firstLevelSeven
      ? screanningDetails.firstLevelSeven
      : "Select"
  );
  const [firstLevelSeven_1, setFirstLevelSeven_1] = useState(
    screanningDetails.firstLevelSeven_1
      ? screanningDetails.firstLevelSeven_1
      : "Select"
  );
  const [firstLevelSeven_2, setFirstLevelSeven_2] = useState(
    screanningDetails.firstLevelSeven_2
      ? screanningDetails.firstLevelSeven_2
      : "Select"
  );
   const [firstLevelSeven_3, setFirstLevelSeven_3] = useState(
    screanningDetails.firstLevelSeven_3
      ? screanningDetails.firstLevelSeven_3
      : ""
  );
  const [firstLevelSeven_4, setFirstLevelSeven_4] = useState(
    screanningDetails.firstLevelSeven_4
      ? screanningDetails.firstLevelSeven_4
      : ""
  );
  const [firstLevelSeven_5, setFirstLevelSeven_5] = useState(
    screanningDetails.firstLevelSeven_5
      ? screanningDetails.firstLevelSeven_5
      : ""
  );

  const [firstLevelEight, setFirstLevelEight] = useState(
    screanningDetails.firstLevelEight
      ? screanningDetails.firstLevelEight
      : "Select"
  );
  const [firstLevelEight_1, setFirstLevelEight_1] = useState(
    screanningDetails.firstLevelEight_1
      ? screanningDetails.firstLevelEight_1
      : "Select"
  );
  const [firstLevelEight_2, setFirstLevelEight_2] = useState(
    screanningDetails.firstLevelEight_2
      ? screanningDetails.firstLevelEight_2
      : "Select"
  );
   const [firstLevelEight_3, setFirstLevelEight_3] = useState(
    screanningDetails.firstLevelEight_3
      ? screanningDetails.firstLevelEight_3
      : ""
  );
  const [firstLevelEight_4, setFirstLevelEight_4] = useState(
    screanningDetails.firstLevelEight_4
      ? screanningDetails.firstLevelEight_4
      : ""
  );
  const [firstLevelEight_5, setFirstLevelEight_5] = useState(
    screanningDetails.firstLevelEight_5
      ? screanningDetails.firstLevelEight_5
      : ""
  );
  
  const [screeningAbliFirst, setScreeningAbliFirst] = useState(
    screanningDetails.screeningAbliFirst
      ? screanningDetails.screeningAbliFirst
      : "Select"
  );
  const [screeningPreContact, setScreeningPreContact] = useState(
    screanningDetails.screeningPreContact
      ? screanningDetails.screeningPreContact
      : "Select"
  );

  const [notesScreeningFirst, setNotesScreeningFirst] = useState(
    screanningDetails.notesScreeningFirst
      ? screanningDetails.notesScreeningFirst
      : ""
  );
  const [notesScreeningAbliFirst, setNotesScreeningAbliFirst] = useState(
    screanningDetails.notesScreeningAbliFirst
      ? screanningDetails.notesScreeningAbliFirst
      : ""
  );
  const [notesScreeningPreContact, setNotesScreeningPreContact] = useState(
    screanningDetails.notesScreeningPreContact
      ? screanningDetails.notesScreeningPreContact
      : ""
  );

  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("DD MMM YYYY")
  );
  const handleDateChange = (date) => {
    setSelectedDate(moment(date).format("DD MMM YYYY"));
  };

  const selectAMUser = (e) => {
    setUserAMImgName(e.target.alt);
    setUserAMImg(e.target.src);
    setUserAMId(e.target.dataset.id);
  };
  const selectUser = (e) => {
    setUserImgName(e.target.alt);
    setUserImg(e.target.src);
    setUserId(e.target.dataset.id);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (userId === 0) {
        dispatch(showError({ msg: "Recruiter fileds is required" }));
      } else {
        setLoading(true);
        if (screanningDetails.id) {
          //key_qualification_1st_field,key_qualification_2nd_field,key_qualification_3rd_field,key_qualification_notes,
          await dispatch(
            updateData({
              id: screanningDetails.id,
              name,
              currentLocation,
              email,
              phoneNo: phone,
              company,
              project,
              roleType,
              language,
              appDate: selectedDate,
              visaStatus,
              recruiter: userId,
              am: userAMId,
              cvSource: CVSourceFirst,
              key_qualification_1st_field: keyQualificationFirst,
              key_qualification_2nd_field: keyQualificationSecond,
              key_qualification_3rd_field: keyQualificationThird,
              key_qualification_notes: keyQualificationNotes,
              key_qualification_notes_2: key_qualification_notes_2,
              key_qualification_notes_3: key_qualification_notes_3,
              key_qualify_1st_field: keyQualifyFirst,
              key_qualify_2nd_field: keyQualifySecond,
              key_qualify_3rd_field: keyQualifyThird,
              key_qualify_notes_1: keyQualifyNotes,
              key_qualify_notes_2: key_qualify_notes_2,
              key_qualify_notes_3: key_qualify_notes_3,
              motivation_1st_field: motivationFirst,
              motivation_2nd_field: motivationSecond,
              motivation_3rd_field: motivationThird,
              motivation_notes: motivationNotes,
              job_desc_1st_field: jobDescFirst,
              job_desc_2nd_field: jobDescSecond,
              job_desc_3rd_field: jobDescThird,
              job_desc_notes: jobDescNotes,
              salary_benifits_1st_field: salaryFirst,
              salary_benifits_2nd_field: salarySecond,
              salary_benifits_3rd_field: salaryThird,
              salary_benifits_notes: salaryNotes,
              job_requirement_1st_field: jobReqFirst,
              job_requirement_2nd_field: jobReqSecond,
              job_requirement_3rd_field: jobReqThird,
              job_requirement_notes: jobReqNotes,
              relocation_query_1st_field: RelocationFirst,
              relocation_query_2nd_field: RelocationSecond,
              relocation_query_3rd_field: RelocationThird,
              relocation_query_notes: RelocationNotes,
              project_start_date_1st_field: ProjectStartDateFirst,
              project_start_date_2nd_field: ProjectStartDateSecond,
              project_start_date_3rd_field: ProjectStartDateThird,
              project_start_date_notes: ProjectStarNotes,
              joining_docs_1st_field: JoiningDocumentFirst,
              joining_docs_2nd_field: JoiningDocumentSecond,
              joining_docs_3rd_field: JoiningDocumentThird,
              joining_docs_notes: JoiningDocumentNotes,
              interview_process_1st_field: InterviewProcessFirst,
              interview_process_2nd_field: InterviewProcessSecond,
              interview_process_3rd_field: InterviewProcessThird,
              interview_process_notes: InterviewProcessNotes,
              resume_overview_1st_field: FullResumePreviewFirst,
              resume_overview_2nd_field: FullResumePreviewSecond,
              resume_overview_3rd_field: FullResumePreviewThird,
              resume_overview_notes: FullResumePreviewNotes,
              other_job_interview_1st_field: OtherJobInterviewFirst,
              other_job_interview_2nd_field: OtherJobInterviewSecond,
              other_job_interview_3rd_field: OtherJobInterviewThird,
              other_job_interview_notes: OtherJobInterviewNotes,
              contact_preference_1st_field: ContactPreferenceFirst,
              contact_preference_2nd_field: ContactPreferenceSecond,
              contact_preference_3rd_field: ContactPreferenceThird,
              contact_preference_notes: ContactPreferenceNotes,
              candidate_concenrs,
              recruiter_observation,
              final_decision,
              key_qualification_notes_2,
              key_qualification_notes_3,
              motivation_notes_2,
              motivation_notes_3,
              job_desc_notes_2,
              job_desc_notes_3,
              salary_benifits_notes_2,
              salary_benifits_notes_3,
              job_requirement_notes_2,
              job_requirement_notes_3,
              relocation_query_notes_2,
              relocation_query_notes_3,
              project_start_date_notes_2,
              project_start_date_notes_3,
              joining_docs_notes_2,
              joining_docs_notes_3,
              interview_process_notes_2,
              interview_process_notes_3,
              resume_overview_notes_2,
              resume_overview_notes_3,
              other_job_interview_notes_2,
              other_job_interview_notes_3,
              contact_preference_notes_2,
              contact_preference_notes_3,
              firstLanguage,
              secondLanguage,
              qualifications,
              workExp,
              workLocation,
              interviewModes,
              relocationPack,
              candidateForm,
              backgroundCheck,
              jobStartingDates,
              spFirstLanguage,
              spSecondLanguage,
              spQualifications,
              spWorkExp,
              spWorkLocation,
              spInterviewModes,
              spRelocationPack,
              spCandidateForm,
              spBackgroundCheck,
              spJobStartingDates,
              sendoutCanConcerns,
              sendoutCanRequirment,
              sendoutReqObserv,
              sendoutReqSendoutDec,
              screeningFirst,              
              screeningAbliFirst,
              screeningPreContact,
              notesScreeningFirst,
              notesScreeningAbliFirst,
              notesScreeningPreContact,
              firstLevelFive,
              firstLevelFive_1,
              firstLevelFive_2,
              firstLevelFive_3,
              firstLevelFive_4,
              firstLevelFive_5,
              firstLevelSix,
              firstLevelSix_1,
              firstLevelSix_2,
              firstLevelSix_3,
              firstLevelSix_4,
              firstLevelSix_5,
              firstLevelSeven,
              firstLevelSeven_1,
              firstLevelSeven_2,
              firstLevelSeven_3,
              firstLevelSeven_4,
              firstLevelSeven_5,
              firstLevelEight,
              firstLevelEight_1,
              firstLevelEight_2,
              firstLevelEight_3,
              firstLevelEight_4,
              firstLevelEight_5,
            })
          );
        } else {
          await dispatch(
            addNewValue({
              name,
              currentLocation,
              email,
              phoneNo: phone,
              company,
              project,
              roleType,
              language,
              appDate: selectedDate,
              visaStatus,
              recruiter: userId,
              am: userAMId,
              cvSource: CVSourceFirst,
              key_qualification_1st_field: keyQualificationFirst,
              key_qualification_2nd_field: keyQualificationSecond,
              key_qualification_3rd_field: keyQualificationThird,
              key_qualification_notes: keyQualificationNotes,
              key_qualification_notes_2: key_qualification_notes_2,
              key_qualification_notes_3: key_qualification_notes_3,
              key_qualify_1st_field: keyQualifyFirst,
              key_qualify_2nd_field: keyQualifySecond,
              key_qualify_3rd_field: keyQualifyThird,
              key_qualify_notes_1: keyQualifyNotes,
              key_qualify_notes_2: key_qualify_notes_2,
              key_qualify_notes_3: key_qualify_notes_3,
              motivation_1st_field: motivationFirst,
              motivation_2nd_field: motivationSecond,
              motivation_3rd_field: motivationThird,
              motivation_notes: motivationNotes,
              job_desc_1st_field: jobDescFirst,
              job_desc_2nd_field: jobDescSecond,
              job_desc_3rd_field: jobDescThird,
              job_desc_notes: jobDescNotes,
              salary_benifits_1st_field: salaryFirst,
              salary_benifits_2nd_field: salarySecond,
              salary_benifits_3rd_field: salaryThird,
              salary_benifits_notes: salaryNotes,
              job_requirement_1st_field: jobReqFirst,
              job_requirement_2nd_field: jobReqSecond,
              job_requirement_3rd_field: jobReqThird,
              job_requirement_notes: jobReqNotes,
              relocation_query_1st_field: RelocationFirst,
              relocation_query_2nd_field: RelocationSecond,
              relocation_query_3rd_field: RelocationThird,
              relocation_query_notes: RelocationNotes,
              project_start_date_1st_field: ProjectStartDateFirst,
              project_start_date_2nd_field: ProjectStartDateSecond,
              project_start_date_3rd_field: ProjectStartDateThird,
              project_start_date_notes: ProjectStarNotes,
              joining_docs_1st_field: JoiningDocumentFirst,
              joining_docs_2nd_field: JoiningDocumentSecond,
              joining_docs_3rd_field: JoiningDocumentThird,
              joining_docs_notes: JoiningDocumentNotes,
              interview_process_1st_field: InterviewProcessFirst,
              interview_process_2nd_field: InterviewProcessSecond,
              interview_process_3rd_field: InterviewProcessThird,
              interview_process_notes: InterviewProcessNotes,
              resume_overview_1st_field: FullResumePreviewFirst,
              resume_overview_2nd_field: FullResumePreviewSecond,
              resume_overview_3rd_field: FullResumePreviewThird,
              resume_overview_notes: FullResumePreviewNotes,
              other_job_interview_1st_field: OtherJobInterviewFirst,
              other_job_interview_2nd_field: OtherJobInterviewSecond,
              other_job_interview_3rd_field: OtherJobInterviewThird,
              other_job_interview_notes: OtherJobInterviewNotes,
              contact_preference_1st_field: ContactPreferenceFirst,
              contact_preference_2nd_field: ContactPreferenceSecond,
              contact_preference_3rd_field: ContactPreferenceThird,
              contact_preference_notes: ContactPreferenceNotes,
              candidate_concenrs,
              recruiter_observation,
              final_decision,
              key_qualification_notes_2,
              key_qualification_notes_3,
              motivation_notes_2,
              motivation_notes_3,
              job_desc_notes_2,
              job_desc_notes_3,
              salary_benifits_notes_2,
              salary_benifits_notes_3,
              job_requirement_notes_2,
              job_requirement_notes_3,
              relocation_query_notes_2,
              relocation_query_notes_3,
              project_start_date_notes_2,
              project_start_date_notes_3,
              joining_docs_notes_2,
              joining_docs_notes_3,
              interview_process_notes_2,
              interview_process_notes_3,
              resume_overview_notes_2,
              resume_overview_notes_3,
              other_job_interview_notes_2,
              other_job_interview_notes_3,
              contact_preference_notes_2,
              contact_preference_notes_3,
              firstLanguage,
              secondLanguage,
              qualifications,
              workExp,
              workLocation,
              interviewModes,
              relocationPack,
              candidateForm,
              backgroundCheck,
              jobStartingDates,
              spFirstLanguage,
              spSecondLanguage,
              spQualifications,
              spWorkExp,
              spWorkLocation,
              spInterviewModes,
              spRelocationPack,
              spCandidateForm,
              spBackgroundCheck,
              spJobStartingDates,
              sendoutCanConcerns,
              sendoutCanRequirment,
              sendoutReqObserv,
              sendoutReqSendoutDec,
              screeningFirst,
              screeningAbliFirst,
              screeningPreContact,
              notesScreeningFirst,
              notesScreeningAbliFirst,
              notesScreeningPreContact,
              firstLevelFive,
              firstLevelFive_1,
              firstLevelFive_2,
              firstLevelFive_3,
              firstLevelFive_4,
              firstLevelFive_5,
              firstLevelSix,
              firstLevelSix_1,
              firstLevelSix_2,
              firstLevelSix_3,
              firstLevelSix_4,
              firstLevelSix_5,
              firstLevelSeven,
              firstLevelSeven_1,
              firstLevelSeven_2,
              firstLevelSeven_3,
              firstLevelSeven_4,
              firstLevelSeven_5,
              firstLevelEight,
              firstLevelEight_1,
              firstLevelEight_2,
              firstLevelEight_3,
              firstLevelEight_4,
              firstLevelEight_5,
            })
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [candidate_concenrs, setCandidate_concenrs] = useState(
    screanningDetails.candidate_concenrs
      ? screanningDetails.candidate_concenrs
      : ""
  );
  const [recruiter_observation, setRecruiter_observation] = useState(
    screanningDetails.recruiter_observation
      ? screanningDetails.recruiter_observation
      : ""
  );
  const [final_decision, setFinal_decision] = useState(
    screanningDetails.final_decision ? screanningDetails.final_decision : ""
  );

  let description =
    screanningDetails.candidate_concenrs !== undefined &&
      screanningDetails.candidate_concenrs !== null
      ? screanningDetails.candidate_concenrs
      : "";
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const html = description;
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  useEffect(() => {
    setEditorState(EditorState.createEmpty());
    if (description !== "") {
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [description]);
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setCandidate_concenrs(currentContentAsHTML);
  };

  //recruiter_observation
  let editor2 =
    screanningDetails.recruiter_observation !== undefined &&
      screanningDetails.recruiter_observation !== null
      ? screanningDetails.recruiter_observation
      : "";
  const [editorTxt2, setEditorTxt2] = useState(EditorState.createEmpty());
  const html2 = editor2;
  const contentBlock2 = htmlToDraft(html2);
  const contentEditor2State = ContentState.createFromBlockArray(
    contentBlock2.contentBlocks
  );
  useEffect(() => {
    setEditorTxt2(EditorState.createEmpty());
    if (editor2 !== "") {
      setEditorTxt2(EditorState.createWithContent(contentEditor2State));
    }
  }, [editor2]);
  const handleEditor2Change = (state) => {
    setEditorTxt2(state);
    convert2EditorContentToHTML();
  };
  const convert2EditorContentToHTML = () => {
    let currentContentAsHTML_2 = draftToHtml(
      convertToRaw(editorTxt2.getCurrentContent())
    );
    setRecruiter_observation(currentContentAsHTML_2);
  };

  let editor3 =
    screanningDetails.final_decision !== undefined &&
      screanningDetails.final_decision !== null
      ? screanningDetails.final_decision
      : "";
  const [editorTxt3, setEditorTxt3] = useState(EditorState.createEmpty());
  const html3 = editor3;
  const contentBlock3 = htmlToDraft(html3);
  const contentEditor3State = ContentState.createFromBlockArray(
    contentBlock3.contentBlocks
  );
  useEffect(() => {
    setEditorTxt3(EditorState.createEmpty());
    if (editor3 !== "") {
      setEditorTxt3(EditorState.createWithContent(contentEditor3State));
    }
  }, [editor3]);
  const handleEditor3Change = (state) => {
    setEditorTxt3(state);
    convert3EditorContentToHTML();
  };
  const convert3EditorContentToHTML = () => {
    let currentContentAsHTML_3 = draftToHtml(
      convertToRaw(editorTxt3.getCurrentContent())
    );
    setFinal_decision(currentContentAsHTML_3);
  };

  const updateProfile = async (e) => {
    //const id = e.target.dataset.id;
    const key = e.target.dataset.key;
    const value = e.target.dataset.value;
    if (key === "roleType") {
      setRoleType(value);
    }
    if (key === "project") {
      setProject(value);
    }
    if (key === "company") {
      setCompany(value);
    }
    if (key === "language") {
      setLanguage(value);
    }
    if (key === "cvSource") {
      setCVSourceFirst(value);
    }
  };
  const [istitleShownPophover, setIstitleShownPophover] = useState(false);

  return (
    <>
      <div className="dkg-cand-prescreen-uqw234">
        <div className="dkg-candidate-prescreen-form">
          <div className="dkg-candprescreen-middleseC">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="dkg-prescreen-candidate"
            >
              <div className="row dkg-candprescreen-tabCon m-0">
                <div className="col-md-12 dkg-candprescreen-tabs21 p-0">
                  <Nav
                    variant="pills dkg-candprescreen-navpills21"
                    className="flex-row"
                  >
                    <Nav.Item className="dkg-candprescreen-navitem-21">
                      <Nav.Link eventKey="dkg-prescreen-candidate">
                        Candidate Details
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-candprescreen-navitem-21">
                      <Nav.Link eventKey="dkg-prescreen-requirements">
                        Job Requirements
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-candprescreen-navitem-21">
                      <Nav.Link eventKey="dkg-qualifying-form">
                        1st Level Screening
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-candprescreen-navitem-21">
                      <Nav.Link eventKey="dkg-prescreen-form">
                        2nd Level Screening
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="dkg-candprescreen-navitem-21 dkg-rec-decision-item">
                      <Nav.Link eventKey="dkg-prescreen-reports">
                        Case Report
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="dkg-candpres-tabContent">
                    <Tab.Pane
                      eventKey="dkg-prescreen-candidate"
                      className="dkg-candpres-tabpane"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="dkg-screen-topsecton">
                        <div className="row m-0 p-3">
                          <div className="col-md-6 pl-0 pr-3">
                            <div className="form-group">
                              <label>Case Recruiter</label>
                              <div className="dkg-recuiter-dropdwnCon-12  dkg-rightopen-dropdownCon">
                                <input type="hidden" id="userId" value="-1" />
                                <Dropdown className="dk-globalUserDropdown todays12 dkg-cand-recuiter-dropdown-123">
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                    className="todyAddToggle dkg-cand-recuiter-dropdown-toggle-123"
                                  >
                                    {userId !== "-1" ? (
                                      <img
                                        src={userImg}
                                        className="select-user-img"
                                        alt={userImgName}
                                      />
                                    ) : (
                                      <i className="fas fa-user"></i>
                                    )}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="dk-adstracker-DrpMenu12 dkg-cand-recuiter-dropdownmenu-123">
                                    <div className="d-flex">
                                      <UserList onClick={selectUser} />
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <label>Account Manager</label>
                              <div className="dkg-recuiter-dropdwnCon-12  dkg-rightopen-dropdownCon">
                                <input type="hidden" id="userAMId" value="-1" />
                                <Dropdown className="dk-globalUserDropdown todays12 dkg-cand-recuiter-dropdown-123">
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                    className="todyAddToggle dkg-cand-recuiter-dropdown-toggle-123"
                                  >
                                    {userAMId !== "-1" ? (
                                      <img
                                        src={userAMImg}
                                        className="select-user-img"
                                        alt={userAMImgName}
                                      />
                                    ) : (
                                      <i className="fas fa-user"></i>
                                    )}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="dk-adstracker-DrpMenu12 dkg-cand-recuiter-dropdownmenu-123">
                                    <div className="d-flex">
                                      <UserList onClick={selectAMUser} />
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Screening On</label>
                              <div className="w-100 d-flex aligns-item-center">
                                <DatePicker
                                  selectedDate={selectedDate}
                                  handleDateChange={handleDateChange}
                                />
                              </div>
                            </div>
                            {/* <div className='form-group'>
                                                            <label>Select Account M</label>
                                                            <div className='dkg-recuiter-dropdwnCon-12  dkg-rightopen-dropdownCon'>
                                                                <input type="hidden" id="userAMId" value="-1" />
                                                                <Dropdown className="dk-globalUserDropdown todays12 dkg-cand-recuiter-dropdown-123">
                                                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="todyAddToggle dkg-cand-recuiter-dropdown-toggle-123">
                                                                        {
                                                                            (userAMId !== '-1') ? <img src={userAMImg} className="select-user-img" alt={userAMImgName} /> : <i className="fas fa-user"></i>

                                                                        }

                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className='dk-adstracker-DrpMenu12 dkg-cand-recuiter-dropdownmenu-123'>
                                                                        <div className="d-flex">
                                                                            <UserList onClick={selectAMUser} />
                                                                        </div>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </div> */}
                            <div className="form-group">
                              <label>Name of Candidate</label>
                              <div className="dkg-cand-inputCon">
                                <input
                                  className="form-control"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                />
                                <span>
                                  <FaUserAlt />
                                </span>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Current Location</label>
                              <div className="dkg-cand-inputCon">
                                <input
                                  className="form-control"
                                  value={currentLocation}
                                  onChange={(e) => setLocation(e.target.value)}
                                />
                                <span>
                                  <FaGlobeEurope />
                                </span>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Email Address</label>
                              <div className="dkg-cand-inputCon">
                                <input
                                  className="form-control"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                                <span>
                                  <FaEnvelope />
                                </span>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Phone No.</label>
                              <div className="dkg-cand-inputCon">
                                <input
                                  className="form-control"
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                />
                                <span>
                                  <FaMobileAlt />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 pl-4 pr-0">
                            <div className="form-group">
                              <label>Company</label>
                              <SelectDropsDown
                                txt={company}
                                list={companyList}
                                updateProfile={updateProfile}
                                column="company"
                              />
                            </div>
                            <div className="form-group">
                              <label>Language</label>
                              <SelectDropsDown
                                txt={language}
                                list={languageList}
                                updateProfile={updateProfile}
                                column="language"
                              />
                            </div>
                            <div className="form-group">
                              <label>Project</label>
                              <SelectDropsDown
                                txt={project}
                                list={projectList}
                                updateProfile={updateProfile}
                                column="project"
                              />
                            </div>
                            <div className="form-group">
                              <label>Role Type</label>

                              <SelectDropsDown
                                txt={roleType}
                                list={roleTypeList}
                                updateProfile={updateProfile}
                                column="roleType"
                              />
                            </div>
                            <div className="form-group">
                              <label>CV Source</label>

                              <SelectDropsDown
                                txt={CVSourceFirst}
                                list={cvSourceList}
                                updateProfile={updateProfile}
                                column="cvSource"
                              />
                            </div>
                          </div>
                          <div className="col-md-12 col-12">
                            <div className="dk-prescreenbtnCon">
                              <div
                                className="dk-prescrenrefreshIcon btn-bg mr-3 cursor-pointer MuiButton-contained dkg-prescreen-reset"
                                title=""
                                onClick={reloadData}
                              >
                                RESET
                              </div>
                              <SubmitButton
                                txt="SAVE"
                                loading={loading}
                                success={success}
                                onClick={handleSubmit}
                                className="btn dk-prescreenbtn"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="dkg-prescreen-form"
                      className="dkg-candpres-tabpane"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <ul className="dkg-prescreen-listCon">
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {" "}
                              {data != "" ? data[28].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[29].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[32].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${jobDescFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {jobDescFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {jobDescFirstList.map((item, index) => (
                                          <Dropdown.Item
                                            key={index}
                                            className="dkg-prescreen-dropdown-item"
                                            onClick={() =>
                                              setjobDescFirst(item.value)
                                            }
                                          >
                                            {item.value}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[30].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[33].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${jobDescSecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {jobDescSecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {jobDescSecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setjobDescSecond(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[31].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[34].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${jobDescThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {jobDescThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {jobDescThirdList.map((item, index) => (
                                          <Dropdown.Item
                                            key={index}
                                            className="dkg-prescreen-dropdown-item"
                                            onClick={() =>
                                              setjobDescThird(item.value)
                                            }
                                          >
                                            {item.value}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${jobDescNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setJobDescNotes(e.target.value)
                                      }
                                    >
                                      {jobDescNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${job_desc_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setJobDescNotes_2(e.target.value)
                                      }
                                    >
                                      {job_desc_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${job_desc_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setJobDescNotes_3(e.target.value)
                                      }
                                    >
                                      {job_desc_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[35].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[36].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[39].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${salaryFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {salaryFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {SalaryFirstList.map((item, index) => (
                                          <Dropdown.Item
                                            key={index}
                                            className="dkg-prescreen-dropdown-item"
                                            onClick={() =>
                                              setsalaryFirst(item.value)
                                            }
                                          >
                                            {item.value}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[37].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[40].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${salarySecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {salarySecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {SalarySecondList.map((item, index) => (
                                          <Dropdown.Item
                                            key={index}
                                            className="dkg-prescreen-dropdown-item"
                                            onClick={() =>
                                              setsalarySecond(item.value)
                                            }
                                          >
                                            {item.value}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[38].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[41].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${salaryThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {salaryThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {SalaryThirdList.map((item, index) => (
                                          <Dropdown.Item
                                            key={index}
                                            className="dkg-prescreen-dropdown-item"
                                            onClick={() =>
                                              setsalaryThird(item.value)
                                            }
                                          >
                                            {item.value}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${salaryNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setsalaryNotes(e.target.value)
                                      }
                                    >
                                      {salaryNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${salary_benifits_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setSalaryNotes_2(e.target.value)
                                      }
                                    >
                                      {salary_benifits_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${salary_benifits_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setSalaryNotes_3(e.target.value)
                                      }
                                    >
                                      {salary_benifits_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[42].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[43].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[46].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${jobReqFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {jobReqFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {JobRequirnmentFirstList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setjobReqFirst(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[44].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[47].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${jobReqSecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {jobReqSecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {JobRequirnmentSecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setjobReqSecond(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[45].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[48].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${jobReqThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {jobReqThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {JobRequirnmentThirdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setjobReqThird(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${jobReqNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setJobReqNotes(e.target.value)
                                      }
                                    >
                                      {jobReqNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${job_requirement_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setJobReqNotes_2(e.target.value)
                                      }
                                    >
                                      {job_requirement_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${job_requirement_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setJobReqNotes_3(e.target.value)
                                      }
                                    >
                                      {job_requirement_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[49].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[50].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[53].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${RelocationFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {RelocationFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {RelocationQryFirstList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setRelocationFirst(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[51].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[54].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${RelocationSecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {RelocationSecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {RelocationQrySecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setRelocationSecond(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[52].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[55].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${RelocationThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {RelocationThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {RelocationQryThirdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"recolcation-funds" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setRelocationThird(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${RelocationNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setRelocationNotes(e.target.value)
                                      }
                                    >
                                      {RelocationNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${relocation_query_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setRelocationNotes_2(e.target.value)
                                      }
                                    >
                                      {relocation_query_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${relocation_query_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setRelocationNotes_3(e.target.value)
                                      }
                                    >
                                      {relocation_query_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[56].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[57].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[60].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ProjectStartDateFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ProjectStartDateFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ProjectStartDateFirstList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"notice-period" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setProjectStartDateFirst(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[58].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[61].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ProjectStartDateSecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ProjectStartDateSecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ProjectStartDateSecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"discuss-start-date" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setProjectStartDateSecond(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[59].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[62].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ProjectStartDateThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ProjectStartDateThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ProjectStartDateThirdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "negotiate-start-dates" + index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setProjectStartDateThird(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${ProjectStarNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setProjectStarNotes(e.target.value)
                                      }
                                    >
                                      {ProjectStarNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${project_start_date_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setProjectStartNotes_2(e.target.value)
                                      }
                                    >
                                      {project_start_date_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${project_start_date_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setProjectStartNotes_3(e.target.value)
                                      }
                                    >
                                      {project_start_date_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[63].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[64].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[67].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${JoiningDocumentFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {JoiningDocumentFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {JoiningDocumentFirstList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"id-pass-validity" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setJoiningDocumentFirst(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[65].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[68].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${JoiningDocumentSecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {JoiningDocumentSecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {JoiningDocumentSecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"birth-certificate" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setJoiningDocumentSecond(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[66].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[69].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${JoiningDocumentThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {JoiningDocumentThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {JoiningDocumentThirdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"job-refrences" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setJoiningDocumentThird(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${JoiningDocumentNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setJoiningDocumentNotes(e.target.value)
                                      }
                                    >
                                      {JoiningDocumentNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${joining_docs_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setJoingingNotes_2(e.target.value)
                                      }
                                    >
                                      {joining_docs_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${joining_docs_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setJoingingNotes_3(e.target.value)
                                      }
                                    >
                                      {joining_docs_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[70].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[71].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[74].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${InterviewProcessFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {InterviewProcessFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {InterviewProcessFirstList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "explain-interview-process" +
                                                index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setInterviewProcessFirst(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[72].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[75].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${InterviewProcessSecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {InterviewProcessSecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {InterviewProcessSecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"interview-timeline" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setInterviewProcessSecond(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[73].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[76].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${InterviewProcessThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {InterviewProcessThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {InterviewProcessThirdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"inter-availability" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setInterviewProcessThird(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${InterviewProcessNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setInterviewProcessNotes(e.target.value)
                                      }
                                    >
                                      {InterviewProcessNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${interview_process_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setInterviewProcessNotes_2(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {interview_process_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${interview_process_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setInterviewProcessNotes_3(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {interview_process_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[77].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[78].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[81].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${FullResumePreviewFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {FullResumePreviewFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {FullResumePreviewFirstList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"gap-in-resume" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFullResumePreviewFirst(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[79].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[82].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${FullResumePreviewSecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {FullResumePreviewSecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {FullResumePreviewSecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"relavent-experince" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFullResumePreviewSecond(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[80].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[83].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${FullResumePreviewThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {FullResumePreviewThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {FullResumePreviewThirdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"resume-layout" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFullResumePreviewThird(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${FullResumePreviewNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFullResumePreviewNotes(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {FullResumePreviewNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${resume_overview_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setResumeOverviewNotes_2(e.target.value)
                                      }
                                    >
                                      {resume_overview_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${resume_overview_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setResumeOverviewNotes_3(e.target.value)
                                      }
                                    >
                                      {resume_overview_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[84].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[85].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[88].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${OtherJobInterviewFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {OtherJobInterviewFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {OtherJobInterviewFirstList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"other-interviews" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setOtherJobInterviewFirst(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[86].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[89].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${OtherJobInterviewSecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {OtherJobInterviewSecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {OtherJobInterviewSecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"other-joboffer" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setOtherJobInterviewSecond(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[87].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[90].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${OtherJobInterviewThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {OtherJobInterviewThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {OtherJobInterviewThirdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"accepted-this-job" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setOtherJobInterviewThird(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${OtherJobInterviewNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setOtherJobInterviewNotes(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {OtherJobInterviewNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${other_job_interview_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setOtherJobNotes_2(e.target.value)
                                      }
                                    >
                                      {other_job_interview_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${other_job_interview_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setOtherJobNotes_3(e.target.value)
                                      }
                                    >
                                      {other_job_interview_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[91].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[92].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[95].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceFirstList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-methods" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceFirst(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[93].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[96].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceSecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceSecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceSecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-channel" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceSecond(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[94].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[97].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceThirdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-avail" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceThird(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${ContactPreferenceNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreferenceNotes(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {ContactPreferenceNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${contact_preference_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreNotes_2(e.target.value)
                                      }
                                    >
                                      {contact_preference_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${contact_preference_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreNotes_3(e.target.value)
                                      }
                                    >
                                      {contact_preference_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[175].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[176].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[179].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceFirstList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-methods" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceFirst(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[177].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[180].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceSecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceSecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceSecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-channel" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceSecond(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[178].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[181].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceThirdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-avail" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceThird(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${ContactPreferenceNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreferenceNotes(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {ContactPreferenceNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${contact_preference_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreNotes_2(e.target.value)
                                      }
                                    >
                                      {contact_preference_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${contact_preference_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreNotes_3(e.target.value)
                                      }
                                    >
                                      {contact_preference_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[182].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[183].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[186].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceFirstList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-methods" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceFirst(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[184].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[187].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceSecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceSecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceSecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-channel" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceSecond(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[185].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[188].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceThirdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-avail" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceThird(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${ContactPreferenceNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreferenceNotes(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {ContactPreferenceNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${contact_preference_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreNotes_2(e.target.value)
                                      }
                                    >
                                      {contact_preference_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${contact_preference_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreNotes_3(e.target.value)
                                      }
                                    >
                                      {contact_preference_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[189].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[190].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[193].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceFirstList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-methods" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceFirst(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[191].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[194].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceSecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceSecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceSecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-channel" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceSecond(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[192].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[195].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceThirdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-avail" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceThird(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${ContactPreferenceNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreferenceNotes(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {ContactPreferenceNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${contact_preference_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreNotes_2(e.target.value)
                                      }
                                    >
                                      {contact_preference_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${contact_preference_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreNotes_3(e.target.value)
                                      }
                                    >
                                      {contact_preference_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[196].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[197].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[200].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceFirstList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-methods" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceFirst(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[198].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[201].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceSecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceSecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceSecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-channel" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceSecond(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[199].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[202].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceThirdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-avail" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceThird(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${ContactPreferenceNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreferenceNotes(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {ContactPreferenceNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${contact_preference_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreNotes_2(e.target.value)
                                      }
                                    >
                                      {contact_preference_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${contact_preference_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreNotes_3(e.target.value)
                                      }
                                    >
                                      {contact_preference_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[203].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[204].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[207].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceFirstList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-methods" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceFirst(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[205].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[208].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceSecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceSecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceSecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-channel" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceSecond(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[206].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[209].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${ContactPreferenceThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {ContactPreferenceThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {ContactPreferenceThirdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"preferred-avail" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setContactPreferenceThird(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${ContactPreferenceNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreferenceNotes(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {ContactPreferenceNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${contact_preference_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreNotes_2(e.target.value)
                                      }
                                    >
                                      {contact_preference_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${contact_preference_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setContactPreNotes_3(e.target.value)
                                      }
                                    >
                                      {contact_preference_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>

                        <div className="dk-prescreenbtnCon">
                          <div
                            className="dk-prescrenrefreshIcon btn-bg dkg-prescreen-reset mr-3 cursor-pointer MuiButton-contained"
                            title=""
                            onClick={reloadData}
                          >
                            RESET
                          </div>
                          <SubmitButton
                            txt="SAVE"
                            loading={loading}
                            success={success}
                            onClick={handleSubmit}
                            className="btn dk-prescreenbtn"
                          />
                        </div>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="dkg-qualifying-form"
                      className="dkg-candpres-tabpane"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <ul className="dkg-prescreen-listCon">
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[0].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[1].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[4].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${keyQualificationFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {keyQualificationFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {keyQualificationFirstList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "recruiter-introduction" + index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setKeyQualificationFirst(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[2].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[5].title : ""
                                      }
                                    />
                                    {/* <label className='dkg-mdiumsize-label'>Reason for this Phone Call</label> */}
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${keyQualificationSecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {keyQualificationSecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {keyQualificationSecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"applying-for-job" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setKeyQualificationSecond(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[3].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[6].title : ""
                                      }
                                    />
                                    {/* <label className='dkg-mdiumsize-label'>Confirm Interest in the Job</label> */}
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${keyQualificationThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {keyQualificationThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {keyQualificationThirdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "not-responsive-situation" +
                                                index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setKeyQualificationThird(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${keyQualificationNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setKeyQualificationNotes(e.target.value)
                                      }
                                    >
                                      {keyQualificationNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${key_qualification_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setKeyQualificationNotes_2(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {key_qualification_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${key_qualification_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setKeyQualificationNotes_3(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {key_qualification_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[7].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[8].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[11].title : ""
                                      }
                                    />
                                    {/* <label className='dkg-mdiumsize-label'>Confirm Main Language Level</label> */}
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${keyQualifyFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {keyQualifyFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {keyQualifyFirstList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"main-lang-level" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setKeyQualifyFirst(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[9].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[12].title : ""
                                      }
                                    />
                                    {/* <label className='dkg-mdiumsize-label'>Confirm the Nationality / VISA</label> */}
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${keyQualifySecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {keyQualifySecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {keyQualifySecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"nationality-visa" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setKeyQualifySecond(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[10].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[13].title : ""
                                      }
                                    />

                                    {/* <label className='dkg-mdiumsize-label'>Confirm the Current Location</label> */}

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${keyQualifyThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {keyQualifyThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {keyQualifyThirdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"current-location" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setKeyQualifyThird(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${keyQualifyNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setKeyQualifyNotes(e.target.value)
                                      }
                                    >
                                      {keyQualifyNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${key_qualify_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setKeyQualifyNotes_2(e.target.value)
                                      }
                                    >
                                      {key_qualify_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${key_qualify_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setKeyQualifyNotes_3(e.target.value)
                                      }
                                    >
                                      {key_qualify_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[14].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[15].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[18].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${motivationFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {motivationFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {motivationFirstList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "leveing-current-job" + index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setMotivationFirst(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[16].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[19].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${motivationSecond == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {motivationSecond}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {motivationSecondList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"urgently-need-job" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setMotivationSecond(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[17].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[20].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${motivationThird == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {motivationThird}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {motivationThirdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"long-looking-job" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setMotivationThird(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${motivationNotes == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setMotivationNotes(e.target.value)
                                      }
                                    >
                                      {motivationNotes}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${motivation_notes_2 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setMotivationNotes_2(e.target.value)
                                      }
                                    >
                                      {motivation_notes_2}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${motivation_notes_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setMotivationNotes_3(e.target.value)
                                      }
                                    >
                                      {motivation_notes_3}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[21].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[22].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[25].title : ""
                                      }
                                    />
                                    {/* <label className='dkg-mdiumsize-label'>Explain 2nd Level Screening</label> */}
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${screeningFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {screeningFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {screaningLevelList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "2nd-level-screening" + index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setScreeningFirst(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[23].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[26].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${screeningAbliFirst == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {screeningAbliFirst}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {screaningListAvailability.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"screening-availity" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setScreeningAbliFirst(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[24].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[27].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${screeningPreContact == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {screeningPreContact}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {screaningPreferedList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "prefrred-contact-method" +
                                                index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setScreeningPreContact(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${notesScreeningFirst == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setNotesScreeningFirst(e.target.value)
                                      }
                                    >
                                      {notesScreeningFirst}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${notesScreeningAbliFirst == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setNotesScreeningAbliFirst(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {notesScreeningAbliFirst}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${notesScreeningPreContact == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setNotesScreeningPreContact(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {notesScreeningPreContact}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[98].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[99].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[102].title : ""
                                      }
                                    />
                                  
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelFive == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelFive}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelFiveList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "2nd-level-screening" + index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelFive(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[100].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[103].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelFive_1 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelFive_1}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelFiveTwoList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"screening-availity" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelFive_1(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[101].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[104].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelFive_2 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelFive_2}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelFiveThreeList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "prefrred-contact-method" +
                                                index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelFive_2(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelFive_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelFive_3(e.target.value)
                                      }
                                    >
                                      {firstLevelFive_3}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelFive_4 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelFive_4(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelFive_4}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelFive_5 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelFive_5(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelFive_5}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[105].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[106].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[109].title : ""
                                      }
                                    />
                                  
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelSix == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelSix}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelSix1stList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "2nd-level-screening" + index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelSix(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[107].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[110].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelSix_1 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelSix_1}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelSix2ndList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"screening-availity" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelSix_1(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[108].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[111].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelSix_2 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelSix_2}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelSix3rdList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "prefrred-contact-method" +
                                                index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelSix_2(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelSix_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelSix_3(e.target.value)
                                      }
                                    >
                                      {firstLevelSix_3}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelSix_4 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelSix_4(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelSix_4}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelSix_5 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelSix_5(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelSix_5}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[112].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[113].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[116].title : ""
                                      }
                                    />
                                  
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelSeven == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelSeven}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelSeven1stList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "2nd-level-screening" + index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelSeven(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[114].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[117].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelSeven_1 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelSeven_1}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelSeven2ndList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"screening-availity" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelSeven_1(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[115].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[118].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelSeven_2 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelSeven_2}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelSeven3rdtList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "prefrred-contact-method" +
                                                index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelSeven_2(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelSeven_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`} 
                                      onChange={(e) =>
                                        setFirstLevelSeven_3(e.target.value)
                                      }
                                    >
                                      {firstLevelSeven_3}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelSeven_4 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelSeven_4(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelSeven_4}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelSeven_5 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelSeven_5(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelSeven_5}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[119].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[120].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[123].title : ""
                                      }
                                    />
                                  
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight1stList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "2nd-level-screening" + index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[121].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[124].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_1 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight_1}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight2ndList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"screening-availity" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight_1(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[122].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[125].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_2 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight_2}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight3rdtList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "prefrred-contact-method" +
                                                index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight_2(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`} 
                                      onChange={(e) =>
                                        setFirstLevelEight_3(e.target.value)
                                      }
                                    >
                                      {firstLevelEight_3}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_4 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelEight_4(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelEight_4}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_5 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelEight_5(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelEight_5}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>

                         <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[126].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[127].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[130].title : ""
                                      }
                                    />
                                  
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight1stList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "2nd-level-screening" + index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[128].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[131].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_1 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight_1}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight2ndList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"screening-availity" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight_1(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[129].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[132].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_2 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight_2}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight3rdtList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "prefrred-contact-method" +
                                                index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight_2(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`} 
                                      onChange={(e) =>
                                        setFirstLevelEight_3(e.target.value)
                                      }
                                    >
                                      {firstLevelEight_3}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_4 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelEight_4(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelEight_4}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_5 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelEight_5(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelEight_5}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[133].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[134].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[137].title : ""
                                      }
                                    />
                                  
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight1stList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "2nd-level-screening" + index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[135].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[138].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_1 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight_1}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight2ndList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"screening-availity" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight_1(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[136].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[139].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_2 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight_2}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight3rdtList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "prefrred-contact-method" +
                                                index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight_2(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`} 
                                      onChange={(e) =>
                                        setFirstLevelEight_3(e.target.value)
                                      }
                                    >
                                      {firstLevelEight_3}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_4 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelEight_4(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelEight_4}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_5 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelEight_5(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelEight_5}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[140].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[141].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[144].title : ""
                                      }
                                    />
                                  
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight1stList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "2nd-level-screening" + index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[142].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[145].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_1 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight_1}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight2ndList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"screening-availity" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight_1(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[143].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[146].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_2 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight_2}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight3rdtList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "prefrred-contact-method" +
                                                index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight_2(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`} 
                                      onChange={(e) =>
                                        setFirstLevelEight_3(e.target.value)
                                      }
                                    >
                                      {firstLevelEight_3}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_4 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelEight_4(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelEight_4}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_5 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelEight_5(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelEight_5}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[147].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[148].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[151].title : ""
                                      }
                                    />
                                  
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight1stList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "2nd-level-screening" + index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[149].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[152].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_1 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight_1}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight2ndList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"screening-availity" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight_1(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[150].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[153].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_2 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight_2}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight3rdtList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "prefrred-contact-method" +
                                                index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight_2(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`} 
                                      onChange={(e) =>
                                        setFirstLevelEight_3(e.target.value)
                                      }
                                    >
                                      {firstLevelEight_3}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_4 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelEight_4(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelEight_4}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_5 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelEight_5(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelEight_5}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[154].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[155].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[158].title : ""
                                      }
                                    />
                                  
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight1stList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "2nd-level-screening" + index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[156].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[159].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_1 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight_1}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight2ndList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"screening-availity" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight_1(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[157].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[160].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_2 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight_2}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight3rdtList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "prefrred-contact-method" +
                                                index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight_2(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`} 
                                      onChange={(e) =>
                                        setFirstLevelEight_3(e.target.value)
                                      }
                                    >
                                      {firstLevelEight_3}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_4 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelEight_4(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelEight_4}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_5 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelEight_5(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelEight_5}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[161].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[162].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[165].title : ""
                                      }
                                    />
                                  
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight1stList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "2nd-level-screening" + index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[163].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[166].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_1 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight_1}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight2ndList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"screening-availity" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight_1(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[164].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[167].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_2 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight_2}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight3rdtList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "prefrred-contact-method" +
                                                index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight_2(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`} 
                                      onChange={(e) =>
                                        setFirstLevelEight_3(e.target.value)
                                      }
                                    >
                                      {firstLevelEight_3}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_4 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelEight_4(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelEight_4}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_5 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelEight_5(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelEight_5}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">
                              {data != "" ? data[168].title : ""}
                            </Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="dkg-candidate-formCon-24">
                              <div className="row">
                                <div className="col-md-6 pr-2">
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[169].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[172].title : ""
                                      }
                                    />
                                  
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight1stList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "2nd-level-screening" + index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight(item.value)
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[170].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[173].title : ""
                                      }
                                    />

                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_1 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight_1}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight2ndList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={"screening-availity" + index}
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight_1(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="form-group mb-0">
                                    <PophoverIcon
                                      dkglabeltitle={
                                        data != "" ? data[171].title : ""
                                      }
                                      hovertitle={
                                        data != "" ? data[174].title : ""
                                      }
                                    />
                                    <Dropdown className="dkg-cand-prescreen-dropdown">
                                      <Dropdown.Toggle
                                        variant=""
                                        className={`dkg-cand-prescreen-dropdown-toggle ${firstLevelEight_2 == "Select"
                                            ? "addBgColourInput"
                                            : "removeAddBgColourInput"
                                          }`}
                                      >
                                        {firstLevelEight_2}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dkg-cand-prescreen-dropdown-menu">
                                        {firstLevelEight3rdtList.map(
                                          (item, index) => (
                                            <Dropdown.Item
                                              key={
                                                "prefrred-contact-method" +
                                                index
                                              }
                                              className="dkg-prescreen-dropdown-item"
                                              onClick={() =>
                                                setFirstLevelEight_2(
                                                  item.value
                                                )
                                              }
                                            >
                                              {item.value}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="col-md-6 pl-2">
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_3 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`} 
                                      onChange={(e) =>
                                        setFirstLevelEight_3(e.target.value)
                                      }
                                    >
                                      {firstLevelEight_3}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_4 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelEight_4(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelEight_4}
                                    </textarea>
                                  </div>
                                  <div className="form-group dkg-candpress-frmgrp-21 mb-0">
                                    <textarea
                                      
                                      placeholder="Notes...."
                                      className={`form-control dkg-salary-joining-textarea ${firstLevelEight_5 == ""
                                          ? "addBgColourInput"
                                          : "removeAddBgColourInput"
                                        }`}
                                      onChange={(e) =>
                                        setFirstLevelEight_5(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {firstLevelEight_5}
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                       

                        <div className="dk-prescreenbtnCon">
                          <div
                            className="dk-prescrenrefreshIcon dkg-prescreen-reset btn-bg mr-3 cursor-pointer MuiButton-contained"
                            title=""
                            onClick={reloadData}
                          >
                            RESET
                          </div>
                          <SubmitButton
                            txt="SAVE"
                            loading={loading}
                            success={success}
                            onClick={handleSubmit}
                            className="btn dk-prescreenbtn"
                          />
                        </div>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="dkg-prescreen-requirements"
                      className="dkg-candpres-tabpane"
                    >
                      <div className="dkg-requriments-screen-mainCon">
                        <div className="row m-0 dkg-requriments-blockCon">
                          <div className="col-md-6 pr-4 dkg-requriments-blockCols dkg-left-requriments-boxCon">
                            <div className="dkg-requriments-headerCon">
                              <div className="dkg-requriments-title">
                                STANDARD - JOB REQUIREMENTS
                              </div>
                            </div>
                            <div className="dkg-requriments-boxCon">
                              <div className="dkg-requriments-tableCon">
                                <table className="table table-bordered dkg-requriments-table">
                                  <tbody>
                                    <tr>
                                      <td>1st Language</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={firstLanguage}
                                          onChange={(e) =>
                                            setFirstLanguage(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>2nd Language</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={secondLanguage}
                                          onChange={(e) =>
                                            setSecondLanguage(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>WORK LOCATION</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={workLocation}
                                          onChange={(e) =>
                                            setWorkLocation(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Qualifications</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={qualifications}
                                          onChange={(e) =>
                                            setQualifications(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Work Experience</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={workExp}
                                          onChange={(e) =>
                                            setWorkExp(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Interview Modes</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={interviewModes}
                                          onChange={(e) =>
                                            setInterviewModes(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>RELOCATION PACK</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={relocationPack}
                                          onChange={(e) =>
                                            setRelocationPack(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>CANDIDATE LOCATION</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={candidateForm}
                                          onChange={(e) =>
                                            setCandidateForm(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>JOB STARTING DATES</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={jobStartingDates}
                                          onChange={(e) =>
                                            setJobStartingDates(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 pl-4 dkg-requriments-blockCols dkg-right-requriments-boxCon">
                            <div className="dkg-requriments-headerCon">
                              <div className="dkg-requriments-title dkg-jobreq-right-title">
                                SPECIAL - JOB REQUIREMENTS
                              </div>
                            </div>
                            <div className="dkg-requriments-boxCon">
                              <div className="dkg-requriments-tableCon">
                                <table className="table table-bordered dkg-jobrequriments-table-2">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={spFirstLanguage}
                                          onChange={(e) =>
                                            setSpFirstLanguage(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={spSecondLanguage}
                                          onChange={(e) =>
                                            setSpSecondLanguage(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={spQualifications}
                                          onChange={(e) =>
                                            setSpQualifications(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={spWorkExp}
                                          onChange={(e) =>
                                            setspWorkExp(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={spWorkLocation}
                                          onChange={(e) =>
                                            setSpWorkLocation(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={spInterviewModes}
                                          onChange={(e) =>
                                            setSpInterviewModes(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={spRelocationPack}
                                          onChange={(e) =>
                                            setSpRelocationPack(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={spCandidateForm}
                                          onChange={(e) =>
                                            setSpCandidateForm(e.target.value)
                                          }
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={spJobStartingDates}
                                          onChange={(e) =>
                                            setSpJobStartingDates(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-12">
                            <div className="dk-prescreenbtnCon dkg-jobreuitment-btnCon">
                              <div
                                className="dk-prescrenrefreshIcon dkg-prescreen-reset btn-bg mr-3 cursor-pointer MuiButton-contained"
                                title=""
                                onClick={reloadData}
                              >
                                RESET
                              </div>
                              <SubmitButton
                                txt="SAVE"
                                loading={loading}
                                success={success}
                                onClick={handleSubmit}
                                className="btn dk-prescreenbtn"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="dkg-prescreen-reports"
                      className="dkg-candpres-tabpane"
                    >
                      <ul
                        className="dkg-prescreen-listCon"
                        style={{ marginTop: "10px" }}
                      >
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">Candidate Concerns</Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="form-group">
                              <div className="dkg-interviewnotes-editor12">
                                <textarea
                                  
                                  className="form-control dkg-interviewnotes-area"
                                  onChange={(e) =>
                                    setSendoutCanConcerns(e.target.value)
                                  }
                                >
                                  {sendoutCanConcerns}
                                </textarea>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">Candidate Requirements</Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="form-group">
                              <div className="dkg-interviewnotes-editor12">
                                {/* <textarea
                                   placeholder="Notes...." className={`form-control dkg-interviewnotes-area ${job_desc_notes_3 == '' ? 'addBgColourInput' : 'removeAddBgColourInput'}`} onChange={(e) => setJobDescNotes_3(e.target.value)}>{job_desc_notes_3}</textarea> */}

                                <textarea
                                  
                                  className="form-control dkg-interviewnotes-area"
                                  onChange={(e) =>
                                    setSendoutCanRequirment(e.target.value)
                                  }
                                >
                                  {sendoutCanRequirment}
                                </textarea>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">Recruiter Observations</Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="form-group">
                              <div className="dkg-interviewnotes-editor12">
                                <textarea
                                  
                                  className="form-control dkg-interviewnotes-area"
                                  onChange={(e) =>
                                    setSendoutReqObserv(e.target.value)
                                  }
                                >
                                  {sendoutReqObserv}
                                </textarea>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="dkg-prescreen-list current">
                          <div className="dkg-prescreen-form-leftCon">
                            <Link to="#">Recruiter Sendout Decision</Link>
                          </div>
                          <div className="dkg-prescreen-form-rightCon">
                            <div className="form-group">
                              <div className="dkg-interviewnotes-editor12">
                                <textarea
                                  
                                  className="form-control dkg-interviewnotes-area"
                                  onChange={(e) =>
                                    setSendoutReqSendoutDec(e.target.value)
                                  }
                                >
                                  {sendoutReqSendoutDec}
                                </textarea>
                              </div>
                            </div>
                          </div>
                        </li>

                        <div className="dk-prescreenbtnCon dkg-requiter-decison-btn">
                          <div
                            className="dk-prescrenrefreshIcon btn-bg dkg-prescreen-reset mr-3 cursor-pointer MuiButton-contained"
                            title=""
                            onClick={reloadData}
                          >
                            RESET
                          </div>
                          <SubmitButton
                            txt="SAVE"
                            loading={loading}
                            success={success}
                            onClick={handleSubmit}
                            className="btn dk-prescreenbtn"
                          />
                          {/* <SubmitButton txt="SAVE"  onClick={handleSubmit} className="btn dk-prescreenbtn" /> */}
                        </div>
                      </ul>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
        {/* <div className='dk-prescreenbtnCon'>
                    <div className="dk-prescrenrefreshIcon btn-bg mr-3 cursor-pointer MuiButton-contained" title="" onClick={reloadData}>
                        RESET
                    </div>
                    <SubmitButton txt="SAVE" loading={loading} success={success} onClick={handleSubmit} className="btn dk-prescreenbtn" />
                </div> */}
      </div>
    </>
  );
};

export default ScreenForm;
