import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import "./responsiveMenu.scss";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const CompanySubmenu = (props) => {
    const classes = useStyles();
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    const [toggleMenu, setToggleMenu] = useState(false);
    useEffect(() => {
        setToggleMenu(false)
    }, [pathname])

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setToggleMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);
    return (
        <React.Fragment>
            <div className="dk-subMenuMain res-sub-menu" ref={ref}>
                <IconButton aria-label="delete" className="res-sub-btn" onClick={() => setToggleMenu(!toggleMenu)}>
                    <i className="fas fa-bars"></i>
                </IconButton>
                <Route>
                    <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
                        {/* <li><Link to="/recruitment/company/summary" className={(segment3 == 'summary') ? 'active' : ''}>Summary</Link></li> */}
                        <li><Link to="/recruitment/clients-list/list-view" className={`${(segment3 == 'list-view') ? 'active' : ''}`}>List View</Link></li>
                        <li><Link to="/recruitment/clients-list/detail-view" className={`${(segment3 == 'detail-view') ? 'active' : ''}`}>Detail View</Link></li>
                        <li><Link to="/recruitment/clients-list/add-new" className={`${(segment3 == 'add-new') ? 'active' : ''}`}>Add New</Link></li>
                        <li><Link to="/recruitment/clients-list/client-details" className={`${(segment3 == 'client-details') ? 'active' : ''}`}>Client Details</Link></li>
                        {/*<li><Link to="/recruitment/company/salaries" className={`${(segment3 == 'salaries') ? 'active' : ''}`}>Salaries</Link></li>
                        <li><Link to="/recruitment/company/income-tax" className={`${(segment3 == 'income-tax') ? 'active' : ''}`}>Income Tax</Link></li>
                        <li><Link to="/recruitment/company/benefits" className={`${(segment3 == 'benefits') ? 'active' : ''}`}>Benefits</Link></li>
                        <li><Link to="/recruitment/company/relocation" className={`${(segment3 == 'relocation') ? 'active' : ''}`}>Relocation</Link></li>
                        <li><Link to="/recruitment/company/interviews" className={`${(segment3 == 'interviews') ? 'active' : ''}`}>Interviews</Link></li>
                        <li><Link to="/recruitment/company/profiling" className={`${(segment3 == 'profiling') ? 'active' : ''}`}>Profiling</Link></li> */}
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default CompanySubmenu;
