import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const TableProgressDropdown = () => {
  return (
    <>
        <Dropdown className="dk-trackingTblDropdown">
            <Dropdown.Toggle variant="" id="dropdown-basic">
                <span style={{background:'#286967'}}>Client Progress</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item href="#" style={{background:'#5c737d', color:'#fff'}}>New CV</Dropdown.Item>
                <Dropdown.Item href="#" style={{background:'#796698', color:'#fff'}}>Selected</Dropdown.Item>
                <Dropdown.Item href="#" style={{background:'#55496b', color:'#fff'}}>Screening</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </>
  )
}

export default TableProgressDropdown;