import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Modal, Tab, Nav } from 'react-bootstrap'
import EditTab from './EditTab'
import ReorderTab from './ReorderTab'
import DeleteTab from './DeleteTab'
import './editmaincat.scss'

const EditMainCategory = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Link to="#" onClick={handleShow}><i className="far fa-edit"></i></Link>
            <Modal show={show} onHide={handleClose} className='dk-editCategoryMain' centered>
                <Modal.Header closeButton className='dk-editCategoryHeader'>
                    <Modal.Title>Main Category</Modal.Title>
                </Modal.Header>
                <Modal.Body className='dk-editCategoryBody'>
                    <Tab.Container className="dk-editCategory-TabCon" defaultActiveKey="editdkg">
                        <div className='row'>
                            <div className='col-md-12'>
                                <Nav variant="pills dk-editCategory-pills" className="row-column">
                                    <Nav.Item className='dk-editCategory-navitem'>
                                        <Nav.Link eventKey="editdkg">Edit</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='dk-editCategory-navitem'>
                                        <Nav.Link eventKey="reorder">Reorder</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='dk-editCategory-navitem'>
                                        <Nav.Link eventKey="delete">Delete</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className='col-md-12'>
                                <Tab.Content className='dk-editCategory-tabcontent'>
                                    <Tab.Pane eventKey="editdkg" className='dk-editCategory-tabpane'>
                                        <EditTab />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="reorder" className='dk-editCategory-tabpane'>
                                        <ReorderTab />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="delete" className='dk-editCategory-tabpane'>
                                        <DeleteTab />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditMainCategory