import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'

const CreateJob = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
    <Link to="#" className="dk-cricleBtn" onClick={handleShow}><i className="fas fa-plus"></i></Link>
     <Modal show={show} onHide={handleClose}  dialogClassName="dk-candidateProfile80">
        <Modal.Header closeButton className="dk-candModalHeader">
          <Modal.Title className="dk-candModalTitle" >Create New Job</Modal.Title>
        </Modal.Header>
        <Modal.Body className='dkg-createnewjob-modalbody'></Modal.Body>
      </Modal>
    </>
  )
}

export default CreateJob