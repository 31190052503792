import React from 'react';
import AccBankStateSubmenu from "../../../elements/header/navbar/subMenu/menu/accountingmodule/AccBankStateSubmenu";

const BankState = () => {
    return (
        <>
            <AccBankStateSubmenu />
            <div className='container-fluid' style={{ marginTop: "125px" }}>

            </div>
        </>
    )
}

export default BankState;
