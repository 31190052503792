import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import UserImg from "../../../../../assets/images/deepak.png";
import './blackbook.scss';
import { FaRegClock, FaRegCalendarAlt, FaTasks, FaRegListAlt, FaEyeSlash } from 'react-icons/fa';
import NotificationSeen from "./notificationseen/NotificationSeen";
import { Nav, Tab ,Dropdown } from 'react-bootstrap';


const BlackBook = () => {
    const [showNotification, setShowNotification] = useState(false);
    const shoNotificationModal = () => {
        setShowNotification(true);
    }
    const handleClose = () => {
        setShowNotification(false)
    }
    return (
        <>
            {
                (showNotification) ? <NotificationSeen handleClose={handleClose} /> : null
            }
            <div className="dk-moduleblackbook-main">
                <div className="dk-moduleNoti-Cols">
                    <div className="row">
                        <div className="col-md-12">
                            <div className='dkg-blackbook-header'>
                                <div className='dkg-blackbook-LeftCon'>
                                    <div className="dk-mailshotsSearch mr-2">
                                        <div className="input-group">
                                            <input className="form-control" placeholder="Search ..." value="" />
                                            <div className="input-group-append">
                                                <button className="btn btn-secondary" type="button"><i className="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <Link to="#" className="dkg-plusicon"><i className="fa fa-plus"></i></Link>
                                </div>
                                <div className='dkg-blackbook-titleCon'>
                                    <h2>Candidate Stories</h2>
                                </div>
                                <div className='dkg-blackbook-rightCon'>
                                    <div className='dkg-dropdown-filter mr-2'>
                                        <Dropdown className='dkg-blackbook-dropdown'>
                                            <Dropdown.Toggle variant="" className='dkg-blackbook-dropdown-toggle'>
                                                Select
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu  className='dkg-blackbook-dropdown-menu'>
                                                <Dropdown.Item href="#">Action</Dropdown.Item>
                                                <Dropdown.Item href="#">Another action</Dropdown.Item>
                                                <Dropdown.Item href="#">Something else</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className='dkg-dropdown-filter'>
                                        <Dropdown className='dkg-blackbook-dropdown'>
                                            <Dropdown.Toggle variant="" className='dkg-blackbook-dropdown-toggle'>
                                                Select
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu  className='dkg-blackbook-dropdown-menu'>
                                                <Dropdown.Item href="#">Action</Dropdown.Item>
                                                <Dropdown.Item href="#">Another action</Dropdown.Item>
                                                <Dropdown.Item href="#">Something else</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className='dkg-compy-noti-boxcon-234'>
                                <div className='dkg-compy-noti-left-boxcon'>
                                    <div className='dkg-compy-noti-box-cols'>
                                        <div className="dk-moduleNoti-box active">
                                            <div className="dk-notificationImg">
                                                <img src={UserImg} alt="" />
                                            </div>
                                            <div className="dk-notificationText">
                                                <div className="dk-notification-w100">
                                                    <p>
                                                        <span>Lorem Ipsum is simply dummy text of the printing</span>
                                                    </p>
                                                </div>
                                                <div className="d-flex dkg-compy-noti-boxs-Header">
                                                    <div className="d-timeflex">
                                                        <small>DEPARTMENT</small>
                                                        <small>10:00 AM</small>
                                                    </div>
                                                    <div className="d-timeflex dkg-notification-twoblock">
                                                        <small>Candidate Stories</small>
                                                        <small> stories</small>
                                                    </div>
                                                    <div className="d-timeflex dkg-notification-twoblock">
                                                        <small>Type</small>
                                                        <small>Type 1</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='dkg-compy-noti-right-boxcon'>
                                    <div className='dkg-compy-noti-boxs-cols'>
                                        <div className='dkg-compy-noti-boxs-Body'>
                                            <div className="dkg-cumm-log-maintabCon">
                                                <Tab.Container id="left-tabs-example" defaultActiveKey="dkg-phone">
                                                    <div className='row dkg-cumm-log-mainRow'>
                                                        <div className="col-12 col-md-12  dkg-cumm-log-navmainCol">
                                                            <Nav variant="pills" className="flex-row  dkg-cumm-log-navpills">
                                                                <Nav.Item className='dkg-cumm-log-navitem'>
                                                                    <Nav.Link eventKey="dkg-phone" className='dkg-cumm-log-navlink'>Situation</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item className='dkg-cumm-log-navitem'>
                                                                    <Nav.Link eventKey="dkg-email" className='dkg-cumm-log-navlink'>Action</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item className='dkg-cumm-log-navitem'>
                                                                    <Nav.Link eventKey="dkg-sms" className='dkg-cumm-log-navlink'>Result</Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                        </div>
                                                        <div className='col-12 col-md-12 dkg-cumm-log-tabcontantentCol'>
                                                            <Tab.Content className='dkg-cumm-log-tabcontantent'>
                                                                <Tab.Pane eventKey="dkg-phone" className='dkg-cumm-log-tabcontabpane'>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="dkg-email" className='dkg-cumm-log-tabcontabpane'>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="dkg-sms" className='dkg-cumm-log-tabcontabpane'>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="dkg-whatsapp" className='dkg-cumm-log-tabcontabpane'>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="dkg-skype" className='dkg-cumm-log-tabcontabpane'>
                                                                </Tab.Pane>
                                                            </Tab.Content>
                                                        </div>
                                                    </div>
                                                </Tab.Container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default BlackBook;
