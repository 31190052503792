import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

const jobSpecStatus = ({ id, columnId, onItemClick }) => {
  const status = [
    {
      key: "Active",
      value: "Active",
      text: "Active",
      Bgcolor: "#30887e",
    },
    {
      key: "Inactive",
      value: "Inactive",
      text: "Inactive",
      Bgcolor: "#e2445c",
    },
  ];
  const selectValue = (value) => {
    onItemClick(id, columnId, value);
  };
  return (
    <React.Fragment>
      {status.map((filteredItems, index) => (
        <Dropdown.Item
          key={filteredItems.key}
          href="#"
          style={{ backgroundColor: filteredItems.Bgcolor, color: "#fff" }}
          onClick={() => selectValue(filteredItems.value)}
        >
          {filteredItems.value}
        </Dropdown.Item>
      ))}
    </React.Fragment>
  );
};
export default jobSpecStatus;
