import React from 'react'

const Onboarding = () => {
    return (
        <>

        </>
    )
}

export default Onboarding