import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./jobsrightmodal.scss";
import { Modal, Tab, Nav } from "react-bootstrap";
import Summary from "./summary/Summary";
import SalaryTab from "./salarynew/Salary";
import JobbenfitsTab from "./salarynew/Jobbenfits";
import JobSummaryDetails from "./jobsummarydetails/jobsummary";
import Jobdetail from "./jobdetail/Jobdetail";
import CandidateView from "./candidates/Candidate";
import {
  projectcomp,
  allJobsSpecCompnay,
  defaultJobsCompnay,
  defaultJobsProject,
  allclientsproject,
  defaultJobsRole,
  defaultWhyapply,
  defaultJoinning,
  defaultJoboRelocation,
  defaultJobofferProject,
  defaultInterviewProject,
  allclientsRoletype,
  allclientsInterview,
  allclientsJobOffer,
  allclientsRelocation,
  allJoinning,
  allclientsLanguge,
  allFaqs,
  defaultFaqs,
} from "../../../../../slice/jobSpec/jobSpecSlice";

import { jobsDetails } from "../../../../../slice/jobs/jobsSlice";

import {
  getproject,
  getroletype,
  getinterview,
  getjoboffer,
  getrelocation,
  getjoinning,
  getwhyapply,
} from "../../../../../slice/clientProject/clientprojectSlice";

import { getCMS } from "../../../../../slice/cmsSlice";

import {
  getCategory,
  //  getOnClickQuesAns
} from "../../../../../slice/faqs/newFaqSlice";

import DkgClientCompaines from "../../../companies/listview/modal/liveJobTab/company/DkgClientCompaines";
import DkgClientProject from "../../../companies/listview/modal/liveJobTab/company/DkgClientProject";
import InterviewsTab from "../../../companies/listview/modal/liveJobTab/company/Interviews";
import JoboffersOfferTab from "../../../companies/listview/modal/liveJobTab/company/Joboffers";
import RelocationTabb from "../../../companies/listview/modal/liveJobTab/company/RelocationTab";
import JoinninggTab from "../../../companies/listview/modal/liveJobTab/company/JoinningTab";
import WhyapplyTabb from "../../../companies/listview/modal/liveJobTab/company/WhyapplyTab";
import DkgRoletype from "../../../companies/listview/modal/liveJobTab/company/Roletype";
import ClientInterview from "../../../companies/listview/modal/liveJobTab/interviews/ClientInterviews";
import FaqOveriew from "../../../companies/listview/modal/faq/Overview";
//import JoditEditor from "../../../../ui/JoditEditor";

const DetialViewPoup = memo(
  ({ show, onHide, jobId, popupfor, clientidds, canjobs, companyName }) => {
    const dispatch = useDispatch();
    const { isDetailsLoading, jobDetails } = useSelector((state) => state.jobs);
    const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);

    const { iscat } = useSelector((state) => state.faqData);

    const [Jobdata, setJobdata] = useState("");
    const [JobInstuctiondata, setJobInstuctiondata] = useState("");

    useEffect(() => {
      if (show) {
        const fetchRecods = async () => {
          dispatch(jobsDetails({ id: jobId }));
          await dispatch(getCMS({}));
          //await dispatch(getCategory({ id: Jobdata.job_id }));
          await dispatch(
            defaultJobsCompnay({
              jobsId: Jobdata.job_id,
              tbl: "clientinfo_requirnment_categories",
            })
          );
          await dispatch(
            defaultJobsProject({
              jobsId: Jobdata.job_id,
              tbl: "client_project_categories",
            })
          );
          await dispatch(
            defaultJobsRole({
              jobsId: Jobdata.job_id,
              tbl: "client_roletype_categories",
            })
          );
          await dispatch(
            defaultWhyapply({
              jobsId: Jobdata.job_id,
              tbl: "jobs_relationship_whyapplys",
            })
          );
          await dispatch(
            defaultJoinning({
              jobsId: Jobdata.job_id,
              tbl: "jobs_client_europe_joinnings",
            })
          );
          await dispatch(
            defaultJoboRelocation({
              jobsId: Jobdata.job_id,
              tbl: "jobs_relationship_relocations",
            })
          );
          await dispatch(
            defaultJobofferProject({
              jobsId: Jobdata.job_id,
              tbl: "jobs_relationship_joboffers",
            })
          );
          await dispatch(
            defaultInterviewProject({
              jobsId: Jobdata.job_id,
              tbl: "jobs_relationship_interviews",
            })
          );
          await dispatch(allJobsSpecCompnay({}));
          await dispatch(allclientsproject({}));
          await dispatch(allclientsRoletype({}));
          await dispatch(allclientsInterview({}));
          await dispatch(allclientsJobOffer({}));
          await dispatch(allclientsRelocation({}));
          await dispatch(allJoinning({}));
          await dispatch(allclientsLanguge({}));
          await dispatch(
            getproject({ jobId: Jobdata.job_id, clientId: clientidds })
          );
          await dispatch(
            defaultFaqs({ jobsId: canjobs, tbl: "jobs_relationship_faqs" })
          );
          await dispatch(allFaqs({}));
        };
        fetchRecods();
      }
    }, [show]);

    useEffect(() => {
      dispatch(jobsDetails({ id: jobId }));
    }, []);
    useEffect(() => {
      if (jobDetails && !isDetailsLoading) {
        setJobdata(jobDetails);
      }
      if (!isSuccess) {
        setJobInstuctiondata(cmsList);
      }
    }, [isDetailsLoading, isSuccess, iscat]);

    const [eventKey, SetEventKey] = useState("jobSummaryOverview");
    const [changeTabKey, SetChangeTabKey] = useState("full-job-details");

    useEffect(() => {
      if (changeTabKey) {
        switch (changeTabKey) {
          case "full-job-details":
            dispatch(
              getproject({ jobId: Jobdata.job_id, clientId: clientidds })
            );
            break;

          default:
            //console.log("default");
            break;
        }
      }
    }, [changeTabKey]);

    useEffect(() => {
      if (eventKey) {
        dispatch(projectcomp({ jobId: canjobs }));
        switch (eventKey) {
          case "jobSummaryOverview":
            //console.log("jobSummaryOverview")
            break;
          case "company":
            dispatch(allJobsSpecCompnay({}));
            break;
          case "jobs-project":
            dispatch(
              getproject({ jobId: Jobdata.job_id, clientId: clientidds })
            );
            break;
          case "job-new-role":
            dispatch(
              getroletype({ jobId: Jobdata.job_id, clientId: clientidds })
            );
            break;
          case "dkg-interves":
            dispatch(getinterview({ jobId: Jobdata.job_id }));
            break;
          case "job-offerss":
            dispatch(getjoboffer({ jobId: Jobdata.job_id }));
            break;
          case "dkg-new-relocation":
            dispatch(getrelocation({ jobId: Jobdata.job_id }));
            break;
          case "dkg-joinning":
            dispatch(getjoinning({ jobId: Jobdata.job_id }));
            break;
          case "job-whyapply":
            dispatch(getwhyapply({ jobId: Jobdata.job_id }));
            break;
          case "live-jobs-faq":
            dispatch(getCategory({ id: Jobdata.job_idd }));
            break;
        }
      }
    }, [eventKey]);

    return (
      <React.Fragment>
        <div className="dk-cliDetViewPopup">
          <Modal
            show={show}
            dialogClassName="dk-livejobrightModalDailog"
            aria-labelledby="example-custom-modal-styling-title"
            onHide={onHide}
          >
            <Modal.Header closeButton className="dk-detViewheader">
              <Modal.Title
                className="dk-detViewTitle"
                id="example-custom-modal-styling-title"
              >
                <div className="modalTitleCon">JOB ID - {Jobdata.job_id} </div>

                <div className="modalTitleCon">{/* Full Job Details */}</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="dkg-full-jobdetail-modalmody dkg-edit-full-jobdetail-modalmody">
              <div className="dkg-full-jobdetail-maincon dkg-full-edit-jobdetail-maincon">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="full-job-details"
                >
                  <div className="dkg-full-jobdetail-mainRow dkg-full-jobdetail-mainRow row">
                    <div className="dkg-full-jobdetail-mainCol dkg-full-edit-jobdetail-mainCol col-9 m-auto">
                      <Nav
                        variant="pills"
                        onSelect={(selectedKey) => SetChangeTabKey(selectedKey)}
                        className="flex-row dkg-full-jobdetail-navpills dkg-full-edit-jobdetail-navpills"
                      >
                        <Nav.Item className="dkg-full-jobdetail-navitem">
                          <Nav.Link
                            eventKey="full-job-details"
                            className="dkg-full-jobdetail-navlink"
                          >
                            {Jobdata.name}
                          </Nav.Link>
                        </Nav.Item>
                        {
                          //disableEdit call from candidate profile applied
                          popupfor === "disableEditCandidateApplied" ? (
                            <></>
                          ) : (
                            <Nav.Item className="dkg-full-jobdetail-navitem">
                              <Nav.Link
                                eventKey="edit-jobdetails"
                                className="dkg-full-jobdetail-navlink"
                              >
                                Edit
                              </Nav.Link>
                            </Nav.Item>
                          )
                        }
                      </Nav>
                    </div>
                    <div className="dkg-full-jobdetail-mainCol-12 dkg-full-edit-jobdetail-mainCol-12 col-12">
                      <Tab.Content className="dkg-full-jobdetail-tabcontent dkg-full-edit-jobdetail-tabcontent">
                        <Tab.Pane
                          eventKey="full-job-details"
                          className="dkg-full-jobdetail-tabpane dkg-full-edit-jobdetail-tabpane"
                        >
                          <div className="dk-LivejobPopCon dk-edit-LivejobPopCon">
                            <div className="dk-clientDetViewTopSec dk-edit-clientDetViewTopSec">
                              <div className="row mr-0 ml-0">
                                <div className="col-md-12 col-sm-12 dk-edit-DetViewBottomSecCon dk-DetViewBottomSecCon pl-0 pr-0">
                                  <Tab.Container
                                    id="left-tabs-example"
                                    defaultActiveKey="jobSummaryOverview"
                                  >
                                    <div className="row mr-0 ml-0">
                                      <div
                                        className="col-md-12 col-sm-12 dk-ClieDetViewSideMenu pl-0 pr-0"
                                        id="dk-cliDetailsV"
                                      >
                                        <Nav
                                          variant="pills"
                                          className="flex-row dkg-detailview-navpills dkg-edit-detailview-navpills"
                                          onSelect={(selectedKey) =>
                                            SetEventKey(selectedKey)
                                          }
                                        >
                                          <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                            <Nav.Link eventKey="jobSummaryOverview">
                                              Instructions
                                            </Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                            <Nav.Link eventKey="details">
                                              Overview
                                            </Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                            <Nav.Link eventKey="dkg-livejob-salary">
                                              Salary
                                            </Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                            <Nav.Link eventKey="company">
                                              Company
                                            </Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                            <Nav.Link eventKey="jobs-project">
                                              Project
                                            </Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                            <Nav.Link eventKey="job-new-role">
                                              Role Type
                                            </Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                            <Nav.Link eventKey="dkg-interves">
                                              Interviews
                                            </Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                            <Nav.Link eventKey="job-offerss">
                                              Job Offer
                                            </Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                            <Nav.Link eventKey="dkg-new-relocation">
                                              Relocation
                                            </Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                            <Nav.Link eventKey="dkg-joinning">
                                              Joining
                                            </Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                            <Nav.Link eventKey="job-whyapply">
                                              Why Apply
                                            </Nav.Link>
                                          </Nav.Item>

                                          <Nav.Item className="dkg-detailview-navitem dkg-edit-detailview-navitem">
                                            <Nav.Link eventKey="live-jobs-faq">
                                              FAQ
                                            </Nav.Link>
                                          </Nav.Item>
                                        </Nav>
                                      </div>
                                      <div className="col-md-12 col-sm-12 dk-ClieDetViewMainCont ttt dkg-jobspecpopup-col-12">
                                        <Tab.Content className="dkg-jobspecpopup-tabContent">
                                          <Tab.Pane
                                            eventKey="jobSummaryDetails"
                                            className="dkg-jobspecpopup-tabpane"
                                          >
                                            <div className="dk-ClentViewTabPane jobSummaryDetails dkg-livejobs-suMMTabpane pl-0 pr-0">
                                              <JobSummaryDetails
                                                jobId={jobId}
                                              />
                                            </div>
                                          </Tab.Pane>
                                          <Tab.Pane
                                            eventKey="jobSummaryOverview"
                                            className="dkg-jobspecpopup-tabpane"
                                          >
                                            <div className="dk-ClentViewTabPane summaryTabPane dkg-new-jobdet-tbpane-234">
                                              <div className="instructionTabs">
                                                {JobInstuctiondata != "" &&
                                                  JobInstuctiondata != null ? (
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        JobInstuctiondata[0]
                                                          .description,
                                                    }}
                                                  ></div>
                                                ) : (
                                                  // <JoditEditor
                                                  //   datavalue={
                                                  //     JobInstuctiondata[0]
                                                  //       .description
                                                  //   }
                                                  //   toolbaarControl={false}
                                                  //   readonlyControl={true}
                                                  //   spellcheckControll={false}
                                                  //   showCharsCounterControll={
                                                  //     false
                                                  //   }
                                                  //   showWordsCounterControll={
                                                  //     false
                                                  //   }
                                                  //   widthControll={800}
                                                  //   heightControll={455}
                                                  // />
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </Tab.Pane>
                                          <Tab.Pane
                                            eventKey="details"
                                            className="dkg-jobspecpopup-tabpane"
                                          >
                                            <div className="dk-ClentViewTabPane summaryTabPane dkg-new-jobdet-tbpane-234">
                                              <Summary jobId={jobId} />
                                            </div>
                                          </Tab.Pane>
                                          <Tab.Pane
                                            eventKey="dkg-livejob-salary"
                                            className="dkg-jobspecpopup-tabpane"
                                          >
                                            <div className="dk-ClentViewTabPane summaryTabPane dkg-new-jobdet-tbpane-234">
                                              <SalaryTab jobId={jobId} />
                                            </div>
                                          </Tab.Pane>
                                          <Tab.Pane
                                            eventKey="job-offerss"
                                            className="dkg-jobspecpopup-tabpane"
                                          >
                                            <div className="dk-ClentViewTabPane  dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                              <JoboffersOfferTab />
                                            </div>
                                          </Tab.Pane>
                                          <Tab.Pane
                                            eventKey="company"
                                            className="dkg-jobspecpopup-tabpane"
                                          >
                                            <div className="dk-ClentViewTabPane  dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                              <DkgClientCompaines
                                                client_idd={clientidds}
                                                jobId={Jobdata.job_id}
                                              />
                                            </div>
                                          </Tab.Pane>

                                          <Tab.Pane
                                            eventKey="jobs-project"
                                            className="dkg-jobspecpopup-tabpane"
                                          >
                                            <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                              <DkgClientProject />
                                            </div>
                                          </Tab.Pane>
                                          <Tab.Pane
                                            eventKey="job-new-role"
                                            className="dkg-jobspecpopup-tabpane"
                                          >
                                            <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                              <DkgRoletype
                                                client_idd={clientidds}
                                                jobId={Jobdata.job_id}
                                              />
                                            </div>
                                          </Tab.Pane>
                                          <Tab.Pane
                                            eventKey="job-whyapply"
                                            className="dkg-jobspecpopup-tabpane"
                                          >
                                            <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                              <WhyapplyTabb />
                                            </div>
                                          </Tab.Pane>
                                          <Tab.Pane
                                            eventKey="job-new-benfits"
                                            className="dkg-jobspecpopup-tabpane"
                                          >
                                            <div className="dk-ClentViewTabPane summaryTabPane dkg-new-jobdet-tbpane-234">
                                              <JobbenfitsTab jobId={jobId} />
                                            </div>
                                          </Tab.Pane>

                                          <Tab.Pane
                                            eventKey="interviews"
                                            className="dkg-jobspecpopup-tabpane"
                                          >
                                            <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78">
                                              <ClientInterview
                                                client_idd={clientidds}
                                                jobId={Jobdata.job_id}
                                              />
                                            </div>
                                          </Tab.Pane>
                                          <Tab.Pane
                                            eventKey="candidate"
                                            className="dkg-jobspecpopup-tabpane"
                                          >
                                            <div className="dk-ClentViewTabPane dkg-likedislike-tabpane">
                                              <CandidateView jobId={jobId} />
                                            </div>
                                          </Tab.Pane>
                                          <Tab.Pane
                                            eventKey="dkg-interves"
                                            className="dkg-jobspecpopup-tabpane"
                                          >
                                            <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                              <InterviewsTab
                                                client_idd={clientidds}
                                                jobId={Jobdata.job_id}
                                              />
                                            </div>
                                          </Tab.Pane>

                                          <Tab.Pane
                                            eventKey="dkg-new-relocation"
                                            className="dkg-jobspecpopup-tabpane"
                                          >
                                            <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                              <RelocationTabb />
                                            </div>
                                          </Tab.Pane>

                                          <Tab.Pane
                                            eventKey="dkg-joinning"
                                            className="dkg-jobspecpopup-tabpane"
                                          >
                                            <div className="dk-ClentViewTabPane dkg-jobs-sendout-tabpane-78 dkg-jobspecpopup-contentMain">
                                              <JoinninggTab />
                                            </div>
                                          </Tab.Pane>

                                          <Tab.Pane
                                            eventKey="live-jobs-faq"
                                            className="dkg-livejobs-faq=tabpane"
                                          >
                                            <FaqOveriew jobId={canjobs} />
                                          </Tab.Pane>
                                        </Tab.Content>
                                      </div>
                                    </div>
                                  </Tab.Container>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane
                          eventKey="edit-jobdetails"
                          className="dkg-full-jobdetail-tabpane"
                        >
                          {
                            //disableEdit call from candidate profile applied
                            popupfor === "disableEditCandidateApplied" ? (
                              <></>
                            ) : (
                              <Jobdetail
                                jobId={jobId}
                                canjobs={canjobs}
                                company_name={companyName}
                                clientid={clientidds}
                              />
                            )
                          }
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
);

export default DetialViewPoup;
