import React, { useState } from 'react';
import { Link } from "react-router-dom";
// import Submenu from '../../training/Submenu';
import './comman-training.scss'
import OverviewImg from '../../../../assets/images/business_icon.png';
import ClientsImg from '../../../../assets/images/clients_icon.png';
import JobsImg from '../../../../assets/images/jobs_icon.png';
import OutlookImg from '../../../../assets/images/outlook_icon.png';
import ProcessImg from '../../../../assets/images/process_icon.png';
import EmailsImg from '../../../../assets/images/emails_icon.png';
import CallsImg from '../../../../assets/images/calls_icon.png';
import SmsImg from '../../../../assets/images/sms_icon.png';
import CommonIcon from '../../../../assets/images/training_black.png';
import CandidatesImg from '../../../../assets/images/candidates_icon.png';
import StructureImg from '../../../../assets/images/structure.png';
import TrainingImg from '../../../../assets/images/training_icon.png';
import PipelinesImg from '../../../../assets/images/pipeline_icon.png';
import ToolsImg from '../../../../assets/images/tools_icon.png';
import MasterboxImg from '../../../../assets/images/masterbox.png';
import ChatsImg from '../../../../assets/images/chats_icon.png';
import WorkPlanImg from '../../../../assets/images/workplan_icon.png';
import MyTaskImg from '../../../../assets/images/task.png';
import SalesImg from '../../../../assets/images/sales_icon.png';
import ResourcesImg from '../../../../assets/images/resources_icon.png';
import InvoiceImg from '../../../../assets/images/euro.png';

const DashboardCommon = () => {
    return (
        <>
            {/* <Submenu /> */}
            <div className="dkg-commonTrainingMainPage">
                {/* <div className="page-header">
                    <h3 className="title">
                        <img src={CommonIcon} className="img-fluid" alt="" />
                        COMMON TRAINING
                    </h3>
                </div> */}
                <div className="dkg-comman-trainingBoxMain">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/overview"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={OverviewImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Overview</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/structure"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={StructureImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG Structure</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/process"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={ProcessImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG Process</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/trainings"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={TrainingImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG Teams Task</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/pipelines"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={PipelinesImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG Pipelines</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/common-clients"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={ClientsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG Clients</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/jobs"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={JobsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG Jobs</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/candidates"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={CandidatesImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG Leads</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/masterbox"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={MasterboxImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG CRM</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/tools"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={ToolsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>DKG Tools</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/work-plan"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={WorkPlanImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Work Plan</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/my-task"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={MyTaskImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>My Tasks</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/resource"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={ResourcesImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Resource</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/sales"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={SalesImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Sales</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/payments"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={InvoiceImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Payments</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/outlook"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={OutlookImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Outlook</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/emails"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={EmailsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Emails</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/calls"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={CallsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Calls</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/sms"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={SmsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>SMS</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/chats"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={ChatsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Chats</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/outlook"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={OutlookImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Outlook</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/emails"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={EmailsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Emails</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/calls"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={CallsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Calls</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/sms"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={SmsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>SMS</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dkg-os-training-box">
                                {/* <Link to="/training/commontraining/chats"> */}
                                <Link to="#">
                                    <figure>
                                        <img src={ChatsImg} className="img-fluid" alt="" />
                                    </figure>
                                    <h3>Chats</h3>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardCommon;
