import React, {  useEffect, useState} from 'react';
import { Modal } from 'react-bootstrap'
import SubmitButton from '../../../../../ui/submitButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUpload} from '@fortawesome/free-solid-svg-icons'; 
import  './cvparse.scss'
import DatePicker from './DatePicker'
import { FaEye,FaUserFriends ,FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Select, FormControl, MenuItem } from '@mui/material';
import { showSuccess, showError, clearMessage } from "../../../../../../slice/utils/message/messageSlice";
import { apiDppduplicateCheck } from "../../../../../../slice/candidates/list/listSlice"
import { PostData, DeleteData, GetData } from "../../../../../../services";
import AttachmentPreviewModal from "../attachmentPreview"


const CVParse = () => {

  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  // form payload
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [cvSource, setCvSource] = useState("")
  const [category, setCategory] = useState("");
  const [cvId, setCvId] = useState("");
  const [createdDate, setCreatedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [isDeleteConfirmPopUp, setIsDeleteConfirmPopUp] = useState(false);
  const [deleteAttachmentId, setDeleteAttachmentId] = useState(0)

  const [cvList, setcvList] = useState([]);

  const { details } = useSelector((state) => state.mailbox);
  const handleShow = async () => {
    await getList();
    setList([])
    setCreatedDate(new Date())
    setShow(true)
  };

  const getList = async () => {
    const list = await GetData(true, "/mailbox/attachment/list/" + details?.id)
    if (list.payload.status) {
        setcvList(list.payload.result)
    }
  }

    const [shoePreview, setPreview] = useState(false);
    const [previewFile, setPreviewFile] = useState(null);

    const openPreview = (file) => {
        setPreview(true);
        setPreviewFile(file);
    }

    const closePreview = () => {
        setPreview(false)
        setPreviewFile(null);
    }

  const { candidateCategoryList, candidateCVSourceList } = useSelector(state => state.common);
  const { isduplicateMoved, apiDppDuplicateList } = useSelector(state => state.candidates);
  const [list, setList] = useState([]);
    const [isDuplicate, setIsDuplicate] = useState('none');

  const isCheckValidation = () => {
    if (!firstName || !lastName || !email || !cvSource || !category || !cvId) {
        return false
    }
    return true
  }

  const resetForm = () => {
    setFirstname("")
    setLastname("")
    setEmail("")
    setPhoneNo("")
    setCvSource("")
    setCategory("")
    setCvId("")
    setCreatedDate("")
  }

  const handleClose = () => {
    setShow(false)
    resetForm();
  }

  const deleteAttachment = async () => {
    try {
        if (deleteAttachmentId == 0) return;
        const deleteAttachment = await DeleteData(true, '/mailbox/attachment/' + deleteAttachmentId)
        if (deleteAttachment.payload.status) {
            await getList();
            setDeleteAttachmentId(0)
            setIsDeleteConfirmPopUp(false)
            dispatch(showSuccess({ msg: 'Attachment deleted successfully' }))
        }
    } catch (error) {
        dispatch(showError({ msg: error.message }))
    }
  }

  const confirmDelete = (id) => {
    setDeleteAttachmentId(id)
    setIsDeleteConfirmPopUp(true)
  }

    const handleCheckDuplicateEmail = async (e) => {
        e.preventDefault()
        setIsDuplicate('none')
        if (!email) {
            dispatch(showError({ msg: 'Email is required' }))
            return;
        }
        dispatch(apiDppduplicateCheck({ name: null, email, phone: null }))
    }

    // useEffect(() => {
    //     setList(apiDppDuplicateList)
    // }, [isduplicateMoved])

    useEffect(() => {
        if (isduplicateMoved) {
            if (apiDppDuplicateList.length > 0) {
                setIsDuplicate('duplicate')
                setList(apiDppDuplicateList)
            } else {
                setIsDuplicate('no-duplicate')
                setList([])
            }
        }
    }, [isduplicateMoved])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (list.length > 0) return;
    setLoading(true)
    setSuccess(false)
    try {
        const isValidForm = isCheckValidation()
        if (!isValidForm) {
            dispatch(showError({ msg: 'All Fields are required' }))
            return;
        }
        const response = await PostData(true, '/mail/parse/cv', {
            first_name: firstName,
            last_name: lastName,
            email: email,
            cv_source: cvSource,
            category: category,
            cv_id: cvId,
            mobile: phoneNo,
            created_at: createdDate
        })
        if (response.payload.status) {
            dispatch(showSuccess({ msg: response.payload.message }))
            setLoading(false)
            handleClose();
        } else {
            dispatch(showError({ msg: response.payload.message }))
            setLoading(false)
        }
    } catch (err) {
        if (err.response.status) {
            if (err.response.data.errors && err.response.data.errors.length > 0) {
                dispatch(showError({ msg: err.response.data.errors[0] }))
                setLoading(false)
            } else {
                dispatch(showError({ msg: err.response.data.message }))
                setLoading(false)
            }
        } else {
            dispatch(showError({ msg: 'Oops! Something wrong' }))
            setLoading(false)
        }
    }
  }

    return (
        <>
            <AttachmentPreviewModal show={shoePreview} file={previewFile} onClose={closePreview}/>
            <span onClick={() => {
                if (details) {
                    handleShow()
                }
            }}><FontAwesomeIcon icon={faUpload} /> Parse CV</span>
            <Modal className="dk-addNewCompletionModal dk-deleteModalMain" centered show={isDeleteConfirmPopUp} onHide={() => {
                setIsDeleteConfirmPopUp(false)
                setDeleteAttachmentId(0)
            }}>
                <Modal.Body>
                    <h4>Are you sure wants to delete these records !</h4>
                    <div className="d-flex justify-content-end">
                        <button className="btnRed" onClick={() => deleteAttachment()}>Yes</button>
                        <button onClick={() => {
                            setDeleteAttachmentId(0)
                            setIsDeleteConfirmPopUp(false)
                        }}>No</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={show} onHide={handleClose} className="dkg-addnew-paymenttask-modal-21">
                <Modal.Header closeButton className="dkg-addnew-payment-modalheader-21 dkg-cvparse-modal-header">
                    <Modal.Title>CV PARSE IN CRM</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-addnew-payment-modalbody-21 dkg-mailbox-cvsrc-modalbody">
                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='
                            col-md-5 col-lg-5 col-sm-12'>
                                <div className='form-group'>
                                    <input type="text" className="form-control" placeholder='First Name'  onChange={(e) => setFirstname(e.target.value)} />
                                </div>
                            </div>
                            <div className='col-md-7 col-lg-7 col-sm-12'>
                                <div className='form-group'>
                                    <input type="text" className="form-control" placeholder='Last Name'  onChange={(e) => setLastname(e.target.value)} />
                                </div>
                            </div>
                            <div className='col-md-12 col-12'>
                                <div className='form-group d-flex align-items-start'>
                                    <div className='w-100'>
                                        <input type="text" className="form-control" placeholder='Email Address'  onChange={(e) => setEmail(e.target.value)} />
                                        {
                                            isDuplicate == 'duplicate' && (
                                                <span className='error'>
                                                    Email already exists in CRM
                                                </span>
                                            )
                                        }
                                        {
                                            isDuplicate == 'no-duplicate' && (
                                                <span className='success'>
                                                    No duplicate found
                                                </span>
                                            )
                                        }
                                    </div>
                                    <button onClick={handleCheckDuplicateEmail} type='button' className="dkg-duplicate-btn">
                                        <FaUserFriends />
                                    </button>
                                </div>
                            </div>
                            <div className='col-md-5 col-lg-5 col-sm-12'>
                                <div className='form-group'>
                                    <input type="text" className="form-control" placeholder='Phone No.'  onChange={(e) => setPhoneNo(e.target.value)} />
                                </div>
                            </div>
                            <div className='col-md-7 col-lg-7 col-sm-12'>
                                <div className='form-group'>
                                <div className='dkg-payment-task-drop12'>
                                    <FormControl fullWidth size="small">
                                        <Select
                                            value={cvSource}
                                            displayEmpty
                                            onChange={(event) => setCvSource(event.target.value)}
                                        >
                                            <MenuItem disabled value="">
                                                <em>CV Source</em>
                                            </MenuItem>
                                            {
                                                candidateCVSourceList.map((candidatecvSouce) => (
                                                    <MenuItem value={candidatecvSouce.value}>{ candidatecvSouce.value }</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                </div>
                            </div>
                            <div className='col-md-5 col-lg-5 col-sm-12'>
                                <div className='form-group'>
                                    <div className='dkg-payment-task-drop12'>
                                        <FormControl fullWidth size="small">
                                            <Select
                                                value={category}
                                                displayEmpty
                                                onChange={(event) => setCategory(event.target.value)}
                                            >
                                                <MenuItem disabled value="">
                                                    <em>Category</em>
                                                </MenuItem>
                                                {
                                                    candidateCategoryList.map((candidateCategory) => (
                                                        <MenuItem value={candidateCategory.value}>{ candidateCategory.value }</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-7 col-lg-7 col-sm-12'>
                                <div className='form-group'>
                                    <div className='dkg-addnew-date-timeCon'>
                                        <div className='w-100'>
                                            <DatePicker selectedDate={createdDate} handleDateChange={(date) => setCreatedDate(date) }/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 dkg-parse-cv-bottomsec'>
                                <h2 className='dkg-select-filetitle'>Select a File to Parse</h2>
                                <div className='dkg-select-fileCon'>
                                    {
                                        cvList.map((cv, index) => (
                                            <li className='dkg-select-file-list'>
                                                <span className='dkg-file-list-leftCon'>
                                                    <input type='radio' name='select-file' id={`select-file-${index}`} className='form-control' value={ cv.id } onChange={(e) => setCvId(e.target.value)}/>
                                                </span>
                                                <span className='dkg-file-list-rightCon'>{ cv.file_name }</span>
                                                <span className='dkg-file-list-actions'> 
                                                    <div className="action-icon dkg-viewicon mr-2" onClick={() => openPreview(cv.file_url)}>
                                                        <FaEye />
                                                    </div>
                                                    <div className="action-icon dkg-deleteicon" onClick={() => confirmDelete(cv.id)}>
                                                        <FaTrashAlt />
                                                    </div>
                                                </span>
                                            </li>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className='dkg-parse-cv-bottom-parsebtn'>
                                <div className='dkg-create-payment-taskbtncon'>
                                    <SubmitButton 
                                        txt="PARSE CV" 
                                        className="dkg-create-payment-taskbtn dkg-parsecv-btn" 
                                        loading={loading}
                                        success={success}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default CVParse