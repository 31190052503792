import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faClock } from '@fortawesome/free-solid-svg-icons';
import Skeleton from "@material-ui/lab/Skeleton";
import CustomDateFormat from "../../../../ui/dateFormats/CustomDateFormat";
import SendEmailUser from '../../../../../assets/images/ajay.jpg'

const MailInfo = (props) => {
    const replies = props.replies.length > 0 ? props.replies : []

    //console.log(replies);

    return (
        <React.Fragment>
            {
                replies.map((reply, index) =>
                    <div className="dk-msgRight-panel" key={`mail-reply-${index}`}>
                        <div className="dk-msgHeader">
                            {/* <div className="dk-userTitle">Sub: {replies.subject} */}
                            <div className="dk-userTitle">
                                <div className='dkg-sendemail-imgCon'>
                                    <img src={SendEmailUser} alt='User Icon' className='dkg-sendemail-img' />
                                </div>
                                <div className="dk-detailText">
                                    <div className="title">From : {reply.from_name} &lt;{reply.from}&gt;
                                    </div>
                                    <div className='dkg-bc-datepickerCon'>
                                        <div className="d-flex align-?items-center">
                                            <div className="sbtitle mr-2"><b>To:</b>
                                                {
                                                    reply.to.map((email) => email.email).join(',')
                                                }
                                            </div>
                                            {
                                                reply?.cc.length > 0 &&
                                                <div className="sbtitle"><b>Cc:</b>
                                                    {
                                                        reply.cc.map((email) => email.email).join(',')
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div className='d-flex dkg-email-showdatepickerCon?'>
                                            <span className='dkg-email-showdatepicker'><FontAwesomeIcon icon={faClock} /> {<CustomDateFormat date={reply.date} format='ddd DD[th] MMM YYYY [,] HH:mm A' />}</span>
                                        </div>
                                    </div>

                                </div>
                                {/* <span className='dkg-email-showdatetimer'><FontAwesomeIcon icon={faClock} /> {<CustomDateFormat date={reply.createdAt} format='ddd MMM DD[th] YYYY [, at] HH:mm A' />}</span> */}
                            </div>
                        </div>
                        <div className="dk-msgSender-detail">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="dk-detail-leftPanel">
                                    {/* <div className="userImg">
                                        <div className="imgCircle">
                                            {<AvatarLoad name="Luiza User" />}
                                        </div>
                                    </div> */}
                                    <div className='dkg-email-subject-Con'>
                                        <div className='dkg-email-subject'><span className='dkg-subtile'>Subject : </span>{reply.subject}</div>
                                    </div>
                                    {
                                        reply.attachments.length > 0 &&
                                        <div className="dk-mailViewAttachfile-Con">
                                            {
                                                reply.attachments.map(attachment =>
                                                    <div className="dk-mailViewAttach" onClick={() => props.onShow(attachment.file_url)}>{attachment.file_name} <FontAwesomeIcon icon={faPaperclip} /></div>
                                                )
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='dkg-readmsg-mainCon' style={{
                            backgroundColor: index > 0 ? '#f1f6fc' : '#fff'
                        }}>
                            <div dangerouslySetInnerHTML={{ __html: reply.body }} />
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    )
}

export default MailInfo;
