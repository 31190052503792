import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import "../responsiveMenu.scss";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import './mainmenusubmenuc.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const DKGlobalSubmenu = (props) => {
    const classes = useStyles();
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    const [toggleMenu, setToggleMenu] = useState(false);
    useEffect(() => {
        setToggleMenu(false)
    }, [pathname])

    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                setToggleMenu(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);

    return (
        <React.Fragment>
            <div className="dk-subMenuMain dkg-mainModuleSubmenu res-sub-menu" ref={ref}>
                <IconButton aria-label="delete" className="res-sub-btn" onClick={() => setToggleMenu(!toggleMenu)}>
                    <i className="fas fa-bars"></i>
                </IconButton>
                <Route>
                    <ul className={`${toggleMenu ? "res-mobileNav show" : null}`}>
                        {/* <li><Link to="/dkg-global/company/information" className={(segment3 == 'information') ? 'active' : ''}>Summary</Link></li> */}
                        <li><Link to="/dkg-global/company/dkglobal/overview" className={`${(segment3 == 'dkglobal') ? 'active' : ''}`}>DK Global</Link></li>
                        <li><Link to="/dkg-global/company/emp-model/summary" className={`${(segment3 == 'emp-model') ? 'active' : ''}`}>Work Models</Link></li>
                        <li><Link to="/dkg-global/company/hierarchy" className={`${(segment3 == 'hierarchy') ? 'active' : ''}`}> Hierarchy</Link></li>
                        <li><Link to="/dkg-global/company/structure" className={`${(segment3 == 'structure') ? 'active' : ''}`}>Structure</Link></li>
                        {/* <li><Link to="/dkg-global/company/teams/team-1" className={`${(segment3 == 'teams') ? 'active' : ''}`}>Teams</Link></li> */}
                        <li><Link to="/dkg-global/company/departments/summary" className={`${(segment3 == 'departments') ? 'active' : ''}`}>Departments</Link></li>
                        <li><Link to="/dkg-global/company/job-profiles/overview" className={`${(segment3 == 'job-profiles') ? 'active' : ''}`}>Job Profiles</Link></li>
                        {/* <li><Link to="/dkg-global/company/dkclients/overview" className={`${(segment3 == 'dkclients') ? 'active' : ''}`}>Clients</Link></li>
                        <li><Link to="/dkg-global/company/suppliers/overview" className={`${(segment3 == 'suppliers') ? 'active' : ''}`}>Suppliers</Link></li>
                        <li><Link to="/dkglobal/pipelines" className={`${(segment2 == 'pipelines') ? 'active' : ''}`}>Pipelines</Link></li>
                        <li><Link to="/dkg-global/company/candidates/overview" className={`${(segment3 == 'candidates') ? 'active' : ''}`}>Candidates</Link></li>
                        <li><Link to="/dkg-global/company/dkg-crm/overview" className={`${(segment3 == 'dkg-crm') ? 'active' : ''}`}>DKG CRM</Link></li> */}
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default DKGlobalSubmenu;
