import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/trainingmodule/InterviewsManagerTraining';

const InterviewsManagerTrainingLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header mainheaderLink="Training" moduleLink="/trainingmodule/recruitment-module" linkcolor="#2e97b6" mainheaderTitle=" - INTERVIEWS MANAGER" textcolor="#3c4c62" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default InterviewsManagerTrainingLayout;
