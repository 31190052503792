import React from 'react'

const DeleteTab = () => {
    return (
        <>
            <div className="dk-toolsDeletePanel">
                <div className="d-flex align-items-center">
                    <input type="text" className="form-control" readOnly value="Task" />
                    <div className="dk-toolsPanel-updateIcon"><i className="far fa-trash-alt"></i></div>
                </div>
                <div className="d-flex align-items-center">
                    <input type="text" className="form-control" readOnly value="Candidates" />
                    <div className="dk-toolsPanel-updateIcon"><i className="far fa-trash-alt"></i></div>
                </div>
                <div className="d-flex align-items-center">
                    <input type="text" className="form-control" readOnly value="Resources" />
                    <div className="dk-toolsPanel-updateIcon"><i className="far fa-trash-alt"></i></div>
                </div>
                <div className="d-flex align-items-center">
                    <input type="text" className="form-control" readOnly value="ADS" />
                    <div className="dk-toolsPanel-updateIcon"><i className="far fa-trash-alt"></i></div>
                </div>
                <div className="d-flex align-items-center">
                    <input type="text" className="form-control" readOnly value="Jobs" />
                    <div className="dk-toolsPanel-updateIcon"><i className="far fa-trash-alt"></i></div>
                </div>
            </div>

        </>
    )
}

export default DeleteTab