import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './mainmenusubmenuc.scss';

const RcWalletSubmenu = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain dkg-subMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/rc-wallet/summary" className={(segment2 == 'summary') ? 'active' : ''}>Summary</Link></li>
                        <li><Link to="/rc-wallet/performance" className={(segment2 == 'performance') ? 'active' : ''}>Performance</Link></li>
                        <li><Link to="/rc-wallet/sendouts" className={`${(segment2 == 'sendouts') ? 'active' : ''}`}>Sendouts</Link></li>
                        <li><Link to="/rc-wallet/placements" className={`${(segment2 == 'placements') ? 'active' : ''}`}>Placements</Link></li>
                        {/* <li><Link to="/rc-wallet/information" className={`${(segment2 == 'information') ? 'active' : ''}`}>Information</Link></li> */}
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default RcWalletSubmenu;
