import React from "react";
import { Link, Route } from "react-router-dom";
import "../trainingdetail.scss";

const DetailLeftPanel = () => {
  let pathname = window.location.pathname;
  return (
    <React.Fragment>
      <ul>
        <Route>
          <li>
            <Link to="/" className={`${pathname.match("/") ? "active" : ""}`}>
              Introduction
            </Link>
          </li>

          <li>
            <Link to="#">Dashboard</Link>
          </li>
          <li>
            <Link to="#">Calendar</Link>
          </li>
          <li>
            <Link to="#">Companies</Link>
          </li>
          <li>
            <Link to="#">Jobs</Link>
          </li>
          <li>
            <Link to="#">Specs</Link>
          </li>
          <li>
            <Link to="#">Candidates</Link>
          </li>
          <li>
            <Link to="#">KPI</Link>
          </li>
          <li>
            <Link to="#">ADS</Link>
          </li>
          <li>
            <Link to="#">Resources</Link>
          </li>
          <li>
            <Link to="#">Training</Link>
          </li>
          <li>
            <Link to="#">Knowledge</Link>
          </li>
        </Route>
      </ul>
    </React.Fragment>
  );
};

export default DetailLeftPanel;
