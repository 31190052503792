import React from "react";
import SubmitButton from "../../../../../ui/submitButton";
import Facebook from "../../../../../../assets/images/facebook.png";
import Linkedin from "../../../../../../assets/images/linkedin.png";
import Instagram from "../../../../../../assets/images/instagram.png";
import Youtube from "../../../../../../assets/images/youtube.png";
import DKglobal from "../../../../../../assets/images/business_icon.png";

const SocialmediaTab = ({ socialmediadetails }) => {
  //   const openUrl = (url) => {
  //     console.log(url);
  //   };
  return (
    <>
      <div className="dkg-socialmedia-tab">
        <div className="dkg-social-box-cols">
          <div className="dkg-social-media-box">
            <div className="dkg-socail-media-icon">
              <div className="dkg-sm-imgcon">
                <img src={Facebook} alt="Facebook" className="dkg-sm-img" />
              </div>
            </div>
            <div className="dkg-sm-nameurl-con">
              <div className="dkg-timeflex">
                <small className="dkg-social-media-name">
                  {socialmediadetails != "" ? socialmediadetails.fbTitle : ""}
                </small>
                <small className="dkg-social-media-url">
                  {socialmediadetails != "" ? socialmediadetails.fbUrl : ""}
                </small>
              </div>
            </div>
            <div className="dkg-send-urlbtn-con">
              <SubmitButton
                txt="VIEW PAGE"
                className="dkg-send-urlbtn btn"
                onClick={() => window.open(socialmediadetails.fbUrl, "_blank")}
              />
            </div>
          </div>
          <div className="dkg-social-media-box">
            <div className="dkg-socail-media-icon">
              <div className="dkg-sm-imgcon">
                <img src={Linkedin} alt="Linkedin" className="dkg-sm-img" />
              </div>
            </div>
            <div className="dkg-sm-nameurl-con">
              <div className="dkg-timeflex">
                <small className="dkg-social-media-name">
                  {" "}
                  {socialmediadetails != ""
                    ? socialmediadetails.linkdinTitle
                    : ""}
                </small>
                <small className="dkg-social-media-url">
                  {socialmediadetails != ""
                    ? socialmediadetails.linkdinUrl
                    : ""}
                </small>
              </div>
            </div>
            <div className="dkg-send-urlbtn-con">
              <SubmitButton
                txt="VIEW PAGE"
                className="dkg-send-urlbtn btn"
                onClick={() =>
                  window.open(socialmediadetails.linkdinUrl, "_blank")
                }
              />
            </div>
          </div>
          <div className="dkg-social-media-box">
            <div className="dkg-socail-media-icon">
              <div className="dkg-sm-imgcon">
                <img src={Instagram} alt="Youtube" className="dkg-sm-img" />
              </div>
            </div>
            <div className="dkg-sm-nameurl-con">
              <div className="dkg-timeflex">
                <small className="dkg-social-media-name">
                  {" "}
                  {socialmediadetails != "" ? socialmediadetails.insta : ""}
                </small>
                <small className="dkg-social-media-url">
                  {socialmediadetails != "" ? socialmediadetails.instaUrl : ""}
                </small>
              </div>
            </div>
            <div className="dkg-send-urlbtn-con">
              <SubmitButton
                txt="VIEW PAGE"
                className="dkg-send-urlbtn btn"
                onClick={() =>
                  window.open(socialmediadetails.instaUrl, "_blank")
                }
              />
            </div>
          </div>
          <div className="dkg-social-media-box">
            <div className="dkg-socail-media-icon">
              <div className="dkg-sm-imgcon">
                <img src={Youtube} alt="Youtube" className="dkg-sm-img" />
              </div>
            </div>
            <div className="dkg-sm-nameurl-con">
              <div className="dkg-timeflex">
                <small className="dkg-social-media-name">
                  {socialmediadetails != ""
                    ? socialmediadetails.youtubeTitle
                    : ""}
                </small>
                <small className="dkg-social-media-url">
                  {socialmediadetails != ""
                    ? socialmediadetails.youtubeUrl
                    : ""}
                </small>
              </div>
            </div>
            <div className="dkg-send-urlbtn-con">
              <SubmitButton
                txt="VIEW PAGE"
                className="dkg-send-urlbtn btn"
                onClick={() =>
                  window.open(socialmediadetails.youtubeUrl, "_blank")
                }
              />
            </div>
          </div>
          <div className="dkg-social-media-box">
            <div className="dkg-socail-media-icon">
              <div className="dkg-sm-imgcon">
                <img
                  src={DKglobal}
                  alt="DK Global Website"
                  className="dkg-sm-img"
                />
              </div>
            </div>
            <div className="dkg-sm-nameurl-con">
              <div className="dkg-timeflex">
                <small className="dkg-social-media-name">
                  {socialmediadetails != ""
                    ? socialmediadetails.otherTitle
                    : ""}
                </small>
                <small className="dkg-social-media-url">
                  {socialmediadetails != ""
                    ? socialmediadetails.otherTitleUrl
                    : ""}
                </small>
              </div>
            </div>
            <div className="dkg-send-urlbtn-con">
              <SubmitButton
                txt="VIEW PAGE"
                className="dkg-send-urlbtn btn"
                onClick={() =>
                  window.open(socialmediadetails.otherTitleUrl, "_blank")
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialmediaTab;
