import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import Submenu from "../../subMenu/ProjectsSubmenu";
import "./jobspecsmainmenu.scss";

const KnowleadgeBaseMenu = () => {
  const pathname = window.location.pathname;
  const segment4 = pathname.split("/")[4];
  const segment2 = pathname.split("/")[2];

  useEffect(() => {
    document.title =
      pathname.split("/")["1"].charAt(0).toUpperCase() +
      pathname.split("/")["1"].slice(1) +
      " | DK MasterBox";
  }, [pathname]);

  return (
    <React.Fragment>
      <div className="dk-navBarMain dk-recruitmentNavbar">
        <Route>
          <ul>
            <li>
              <Link
                to="/job-spec/dashboard"
                className={`${segment2 === "dashboard" ? "white-active" : ""}`}
              >
                Summary
              </Link>
            </li>
            <li>
              <Link
                to="/job-spec/live-spec"
                className={`${segment2 === "live-spec" ? "white-active" : ""}`}
              >
                Live Spec
              </Link>
            </li>
            <li>
              <Link
                to="/job-spec/active-spec"
                className={`${
                  segment2 === "active-spec" || segment4 === "Active"
                    ? "white-active"
                    : ""
                }`}
              >
                Active Spec
              </Link>
            </li>
            <li>
              <Link
                to="/job-spec/inactive-spec"
                className={
                  segment2 === "inactive-spec" || segment4 === "Inactive"
                    ? "white-active"
                    : ""
                }
              >
                Inactive Spec
              </Link>
            </li>
            <li>
              <Link
                to="/job-spec/create-spec"
                className={segment2 === "create-spec" ? "white-active" : ""}
              >
                Create Spec
              </Link>
            </li>
            <li>
              <Link
                to="/job-spec/apply-job"
                className={segment2 === "apply-job" ? "white-active" : ""}
              >
                Apply Job
              </Link>
            </li>
            {/* <li>
              <Link
                to="/job-spec/create-spec"
                className={segment2 === "create-spec" ? "active" : ""}
              >
                Create Spec
              </Link>
            </li>
            <li>
              <Link
                to="/projects/tehreem/summary"
                className={segment2 === "tehreem" ? "gray-active" : ""}
              >
                Live Archived 
              </Link>
            </li>
            <li>
              <Link
                to="/projects/luiza/summary"
                className={`${segment2 === "luiza" ? "gray-active" : ""}`}
              >
                Saved Archived
              </Link>
            </li> */}
          </ul>
        </Route>
      </div>
      <Submenu path={pathname} />
    </React.Fragment>
  );
};

export default KnowleadgeBaseMenu;
