import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
// import { Tab, Nav, Dropdown } from 'react-bootstrap'
// import DatePicker from './modal/DatePicker';
import './myacc-fashboard.scss';
import { FaCalendarAlt, FaFileInvoice, FaSuitcase, FaInfoCircle, FaShareSquare, FaUser, FaPhoneSquareAlt, FaHandshake, FaGift, FaEnvelope, FaCheck } from "react-icons/fa";
import {
    getCMSHeadingText, getService, getBenifits
} from "../../../../slice/frontendCms/frontendCmsSlice";
import { getInternalMailCount } from "../../../../slice/mailbox/mailboxSlice";
import { getCMS, getCookiesNotification } from "../../../../slice/cmsSlice";
//import Benefit from '../../../../assets/images/benefit.png'

const Index = () => {
    const dispatch = useDispatch();
    const { isLoading, getcmsList, serviceList, benifitslist, isBenifits, isSuccess } = useSelector((state) => state.cmslist);
    const { jobseDataList } = useSelector((state) => state.jobs);
    const { userInfo } = useSelector((state) => state.auth);
    const [data, setData] = useState([]);
    const [pageHeading, setPageHeading] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [benifitsData, setBenifitsData] = useState([]);

    useEffect(() => {
        dispatch(getInternalMailCount({ can_id: userInfo.id, role: userInfo.role }))
    }, [])
    useEffect(() => {
        if (!isLoading && getcmsList && getcmsList.length > 0) {
            setData(getcmsList);
            setPageHeading(getcmsList[0]);
        }
        if (!isSuccess && serviceList && serviceList.length > 0) {
            setServiceData(serviceList);
        }
        if (!isBenifits && benifitslist && benifitslist.length > 0) {
            setBenifitsData(benifitslist);
        }

    }, [isLoading, isSuccess, isBenifits]);


    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(getCMSHeadingText({}));
                await dispatch(getService({}));
                await dispatch(getBenifits({}));
                await dispatch(getCMS({}));
                await dispatch(getCookiesNotification({ canId: userInfo.canId }));
            } catch (err) {

            }
        }
        fetchData();
    }, [dispatch]);


    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
        var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/65f0650d8d261e1b5f6c9ab5/1hopglf4g';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
    })();

    return (
        <>
            <div className="dk-myAccountDashboard">
                <div className='dkg-page-header'>
                    <p className='dkg-page-title'>{pageHeading != "" ? pageHeading.page_description : ""}</p>
                </div>
                <div className="d-flex dkg-cn-acc-dashboard-col dkg-cnacc-dash-mainCon">
                    <div className="dkg-cn-acc-dashboard-leftcol dk-dashboard-box dkg-cnacc-dash-firstCols">
                        <div className="dkg-cnacc-firstbox-Col">
                            {/* <div className="dkg-first-rowbox" style={{marginBottom: "20px"}}>
                                <div className="title">JOBS INFO<span className='dkg-iconCon'><FaFileInvoice /> </span></div>
                                <div className="dk-dashboard-panel">
                                    <ul className="dkg-cn-dashboar-ul">
                                        <li className="dkg-cn-dashboar-li">
                                            <span className="dkg-leftItem">Open Jobs</span>

                                            <span className="dkg-rightItem"><a href="/js-account/job-profile" style={{ 'fontWeight': 600 }}>{jobseDataList.length > 0 ? jobseDataList.length : '0'} </a></span>
                                        </li>
                                        <li className="dkg-cn-dashboar-li">
                                            <span className="dkg-leftItem">Applied</span>
                                            <span className="dkg-rightItem"><a href="/js-account/kpi" style={{ 'fontWeight': 600 }}>0</a></span>
                                        </li>
                                        <li className="dkg-cn-dashboar-li">
                                            <span className="dkg-leftItem">Interviews</span>
                                            <span className="dkg-rightItem"><a href="/js-account/interviews" style={{ 'fontWeight': 600 }}>0</a></span>
                                        </li>
                                        <li className="dkg-cn-dashboar-li">
                                            <span className="dkg-leftItem">Offered</span>
                                            <span className="dkg-rightItem"><a href="/js-account/job-offers" style={{ 'fontWeight': 600 }}>0</a></span>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className="dkg-first-rowbox" style={{ marginBottom: "20px" }}>
                                <div className="title">OPEN JOBS<span className='dkg-iconCon'><FaUser /> </span></div>
                                <div className="dk-dashboard-panel">
                                    <div className='dkg-unread-body-cols'>
                                        <Link to="/js-account/kpi" className='dkg-unread-blockNo'>{jobseDataList.length > 0 ? jobseDataList.length : '0'}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="dkg-first-rowbox" style={{ marginBottom: "20px" }}>
                                <div className="title">INTERVIEWS<span className='dkg-iconCon'><FaUser /> </span></div>
                                <div className="dk-dashboard-panel">
                                    <div className='dkg-unread-body-cols'>
                                        <Link to="/js-account/kpi" className='dkg-unread-blockNo'>0</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="dkg-first-rowbox">
                                <div className="title">REFERRAL BONUS<span className='dkg-iconCon'><FaEnvelope /> </span></div>
                                <div className="dk-dashboard-panel">
                                    <div className='dkg-unread-body-cols'>
                                        <Link to="/js-account/kpi" className='dkg-unread-blockNo dkg-green-color'>0</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dkg-cn-acc-dashboard-leftcol dk-dashboard-box dkg-cnacc-dash-firstCols">
                        <div className="dkg-cnacc-firstbox-Col">
                            <div className="dkg-first-rowbox" style={{ marginBottom: "20px" }}>
                                <div className="title">APPLIED JOBS<span className='dkg-iconCon'><FaUser /> </span></div>
                                <div className="dk-dashboard-panel">
                                    <div className='dkg-unread-body-cols'>
                                        <Link to="/js-account/kpi" className='dkg-unread-blockNo'>0</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="dkg-first-rowbox" style={{ marginBottom: "20px" }}>
                                <div className="title">OFFERED JOBS<span className='dkg-iconCon'><FaUser /> </span></div>
                                <div className="dk-dashboard-panel">
                                    <div className='dkg-unread-body-cols'>
                                        <Link to="/js-account/kpi" className='dkg-unread-blockNo'>0</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="dkg-first-rowbox">
                                <div className="title">PAID SERVICES<span className='dkg-iconCon'><FaEnvelope /> </span></div>
                                <div className="dk-dashboard-panel">
                                    <div className='dkg-unread-body-cols'>
                                        <Link to="/js-account/dkg-services/summary" className='dkg-unread-blockNo dkg-green-color'>12</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="dkg-cn-acc-dashboard-leftcol dk-dashboard-box  dkg-cnacc-dash-secondCols">
                        <div className="title">PAID SERVICES<span className='dkg-iconCon'><FaSuitcase /> </span></div>
                        <div className="dk-dashboard-panel dkg-account-benfits-panel">
                            <div className="dkg-account-benfits-table-con">
                                <table className="table dkg-account-benfits-table table-bordered">
                                    <tbody>
                                        {
                                            serviceData.map((item, index) => <tr key={index + 1}>
                                                {
                                                    (item.status === 1) ?
                                                        <>

                                                            <td className='dkg-acc-status-righttitle' style={{ color: "green" }}>Available</td></> : <><td className='dkg-acc-status-righttitle' style={{ color: "red" }}>Unavailable</td>
                                                        </>
                                                }
                                                <td>
                                                    <div className='dkg-acc-status-lefttitle'>
                                                        <div className="dkg-paid-service-title">{item.service_name}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='dkg-acc-status-price-cols'>
                                                        <span className="dkg-paid-amoun-col">{item.price}</span>
                                                    </div>
                                                </td>
                                            </tr>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> */}
                    <div className="dkg-cn-acc-dashboard-rightcol dk-dashboard-box dkg-account-benfits-box dkg-cnacc-dash-threeCols">
                        <div className="title">JOBSEEKER ACCOUNT BENEFITS<span className='dkg-iconCon'><FaGift /> </span></div>
                        <div className="dk-dashboard-panel dkg-account-benfits-panel">
                            <div className="dkg-account-benfits-table-con">
                                <table className="table dkg-account-benfits-table table-bordered">
                                    <tbody>
                                        {
                                            benifitsData.map((item, index) => <tr key={index + 1}>
                                                <td>
                                                    <div className="dkg-benifts-iconCon">
                                                        <img src={process.env
                                                            .REACT_APP_IMG_CLOUD_BASE_URL + "/" + item.image_name} className="dkg-benifts-icon" />
                                                    </div>
                                                </td>
                                                <td className="table_txt">{item.heading}</td>
                                                <td>
                                                    <div className="dkg-benifts-tf-iconCon">
                                                        <div className="dkg-benifts-tf-icon">
                                                            {/* <FaCheck /> */}
                                                            <img src={process.env
                                                                .REACT_APP_IMG_CLOUD_BASE_URL + "/" + item.second_image} className="dkg-benifts-icon" />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{item.descriptions}</td>
                                            </tr>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index
