import React from "react";
import '../mainMenu/style.scss';
import DirectorSubmenu from "./menu/myfilesmodule/DirectorSubmenu";
import LicenseSubmenu from './menu/myfilesmodule/LicenseSubmenu';
import BankAccSubmenu from './menu/myfilesmodule/BankAccSubmenu';
import AccountSubmenu from './menu/myfilesmodule/AccountSubmenu';
import BReturnSubmenu from './menu/myfilesmodule/BReturnSubmenu';
import TaxOfficeSubmenu from './menu/myfilesmodule/TaxOfficeSubmenu';
import CertificateSubmenu from './menu/myfilesmodule/CertificateSubmenu';
import MembershipSubmenu from './menu/myfilesmodule/MembershipSubmenu';
import AgreementSubmenu from './menu/myfilesmodule/AgreementSubmenu';


const Submenu = ({ path }) => {
    const renderSubmenu = () => {
        switch (path.split("/")[2]) {
            // case 'alerts':
            //     return <AlertSubmenu />
            //     break;

            case 'director':
                return <DirectorSubmenu />
                break;

            case 'licenses':
                return <LicenseSubmenu />
                break;

            case 'bank-account':
                return <BankAccSubmenu />
                break;

            case 'accounts':
                return <AccountSubmenu />
                break;

            case 'b1-returns':
                return <BReturnSubmenu />
                break;

            case 'tax-office':
                return <TaxOfficeSubmenu />
                break;

            case 'certificates':
                return <CertificateSubmenu />
                break;

            case 'membership':
                return <MembershipSubmenu />
                break;

            case 'agreements':
                return <AgreementSubmenu />
                break;

            default:
                break
        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default Submenu;