import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import  './reports-dash.scss'

// import { setMyModule } from "../../../../../slice/modules/moduleSlice";
import { getList } from "../../../../../../slice/accounting/salesBooks";
import { getInvoiceList, fetchCaseStatus } from "../../../../../../slice/accounting/invoiceBooks";
import { getBankingList } from "../../../../../../slice/accounting/accountBanking";
import { listTable, cashoutListTable } from "../../../../../../slice/accounting/cashflow";
import { useDispatch, useSelector } from "react-redux";
// import { showSuccess, showError } from "../../../../components/utils/messages/messageSlice";
// import Moduleheader from "../../../elements/header/index";
// import { FaEuroSign } from "react-icons/fa";
import { SYSTEM_CONSTANTS } from "../../../../../../constants";

const ReportsDashboard = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        document.title = "DK Global Finance"
    }, []);

    const { userInfo } = useSelector(state => state.auth);

    const { caseStatusList } = useSelector(state => state.common);
    const { salesBookList, isLoading } = useSelector(state => state.salesBooks);
    const { invoiceBookList, invoiceCaseStatusList, isInvoiceLoading } = useSelector(state => state.invoiceBooks);
    const { bankingBookList, isbankingLoading } = useSelector(state => state.bankingBooks);
    const { cashflowList, cashoutHeadingList, isCashflowLoading } = useSelector(state => state.cashflowdata);

    const [year, setYear] = useState('2022');
    const [dataList, setData] = useState([])
    const [invoiceDataList, setDataList] = useState([])
    const [bankingDataList, setBankingDataList] = useState([])
    const [listcashflowIncome, setListcashflowIncome] = useState([]);
    const [listCashOutExpense, setListCashOutExpense] = useState([]);

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(getList({}))
                await dispatch(getInvoiceList({}))
                await dispatch(fetchCaseStatus({ id: SYSTEM_CONSTANTS.INVOICE_SALES_BOOK.caseStatusID }))
                await dispatch(getBankingList({}))

                await dispatch(listTable({ year: year }))
                await dispatch(cashoutListTable({ year: year }))

            } catch (err) {
                console.log(err)
            }
        }

        fetchList();

    }, [dispatch])

    useEffect(() => {
        setData(salesBookList)
        setDataList(invoiceBookList)
        setBankingDataList(bankingBookList)
        setListcashflowIncome(cashflowList)
        setListCashOutExpense(cashoutHeadingList)

    }, [isLoading, isInvoiceLoading, isbankingLoading, isCashflowLoading, dispatch])


    const removeValFromIndex = [0, 1, 2, 3, 4, 8, 9, 10]; // remove status index from usersList
    const indexSet = new Set(removeValFromIndex);
    const businessBook = caseStatusList.filter((value, i) => !indexSet.has(i));


  return (
    <>
      <div className="dkg-report-dashboard-main">
        <div className="dk-portalCols">
            <div className="dk-portalBox dkg-account-grey-boxs" style={{ backgroundColor: "#fff", border: "1px solid #ddd" }}>
                <div className="title">Sales Book</div>
                <div className="dk-textPanel">
                    <table className="table dkg-accounting-boxtable">

                        <tbody>
                            {
                                invoiceCaseStatusList.map((statusItem, index) =>
                                    <tr>
                                        {
                                            (() => {
                                                if (statusItem.value == "Open Invoice")
                                                    return (<>
                                                        <td className="dkg-orange-color">{statusItem.value} </td>
                                                        {
                                                            userInfo.role === "Super Admin" ? <><td className="dkg-orange-color">
                                                            {
                                                                invoiceDataList.filter(function (val) {
                                                                    return val.caseStatus === statusItem.value;
                                                                }).length
                                                            }
                                                        </td></> : 
                                                            <>
                                                            <td className="dkg-orange-color">xxxx</td>
                                                            </>
                                                        }
                                                        
                                                    </>)
                                                else if (statusItem.value == "Closed Invoice")
                                                    return (<>
                                                        <td className="dkg-green-color">{statusItem.value}</td>
                                                        {/* <td className="dkg-green-color">
                                                            {
                                                                invoiceDataList.filter(function (val) {
                                                                    return val.caseStatus === statusItem.value;
                                                                }).length
                                                            }
                                                        </td> */}
                                                        {
                                                            userInfo.role === "Super Admin" ? <><td className="dkg-green-color">
                                                            {
                                                                invoiceDataList.filter(function (val) {
                                                                    return val.caseStatus === statusItem.value;
                                                                }).length
                                                            }
                                                        </td></> : 
                                                            <>
                                                            <td className="dkg-green-color">xxxx</td>
                                                            </>
                                                        }
                                                    </>)
                                                else
                                                    return (<>
                                                        <td className="dkg-red-color">{statusItem.value}</td>
                                                        {/* <td className="dkg-red-color">
                                                            {
                                                                invoiceDataList.filter(function (val) {
                                                                    return val.caseStatus === statusItem.value;
                                                                }).length
                                                            }
                                                        </td> */}
                                                            {
                                                            userInfo.role === "Super Admin" ? <><td className="dkg-red-color">
                                                            {
                                                                invoiceDataList.filter(function (val) {
                                                                    return val.caseStatus === statusItem.value;
                                                                }).length
                                                            }
                                                        </td></> : 
                                                            <>
                                                            <td className="dkg-red-color">xxxx</td>
                                                            </>
                                                        }

                                                    </>)
                                            }
                                            )()
                                        }

                                    </tr>
                                )
                            }

                        </tbody>
                    </table>
                </div>
            </div>
            <div className="dk-portalBox dkg-account-grey-boxs" style={{ backgroundColor: "#fff", border: "1px solid #ddd" }}>
                <div className="title">BANK STATEMENT</div>
                <div className="dk-textPanel">
                    <table className="table dkg-accounting-boxtable">
                        {/* <thead>
                            <tr>
                                <th>Total Paid In</th>
                                <th>Total Paid Out</th>
                            </tr>
                        </thead> */}
                        <tbody>

                            <tr>
                                <td className="dkg-green-color">Total Paid In</td>
                                {/* <td className="dkg-green-color">
                                    {
                                        (bankingDataList.reduce((a, v) => a = a + Number(v.paidIn), 0)).toFixed(2)
                                    }
                                </td> */}
                                    {
                                    userInfo.role === "Super Admin" ? 
                                    <><td className="dkg-green-color">
                                    {
                                        (bankingDataList.reduce((a, v) => a = a + Number(v.paidIn), 0)).toFixed(2)
                                    }
                                        </td></> : 
                                    <>
                                    <td className="dkg-green-color">xxxx</td>
                                    </>
                                }
                            </tr>
                            <tr>
                                <td className="dkg-red-color">Total Paid Out</td>
                                {/* <td className="dkg-red-color">
                                    {
                                        (bankingDataList.reduce((a, v) => a = a + Number(v.paidOut), 0)).toFixed(2)
                                    }
                                </td> */}
                                    {
                                    userInfo.role === "Super Admin" ? 
                                    <><td className="dkg-red-color">
                                    {
                                        (bankingDataList.reduce((a, v) => a = a + Number(v.paidOut), 0)).toFixed(2)
                                    }
                                        </td></> : 
                                    <>
                                    <td className="dkg-red-color">xxxx</td>
                                    </>
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="dk-portalBox dkg-account-grey-boxs" style={{ backgroundColor: "#fff", border: "1px solid #ddd" }}>
                <div className="title">Cashflow</div>
                <div className="dk-textPanel">
                    <table className="table dkg-accounting-boxtable">

                        <tbody>
                            <tr>
                                <td className="dkg-green-color">Total Income</td>
                                {
                                        userInfo.role === "Super Admin" ? 
                                        <>
                                        <td className="dkg-green-color">
                                    {
                                        (listcashflowIncome.reduce((a, v) => a = a + Number(v.jan), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.feb), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.march), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.april), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.may), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.june), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.july), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.aug), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.sep), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.oct), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.nov), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.dec), 0)).toFixed(2)
                                    }€
                                </td>
                                        </>
                                        :
                                        <>
                                        <td className="dkg-green-color">xxxx</td>
                                        </>
                                }
                                
                            </tr>
                            <tr>
                                <td className="dkg-red-color">Total Expense</td>
                                {
                                    userInfo.role === "Super Admin" ? <>
                                        <td className="dkg-red-color">
                                        {
                                            (listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.sep), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.oct), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.nov), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.dec), 0)).toFixed(2)
                                        }€
                                </td>
                                    </>
                                    :
                                    <>
                                    <td className="dkg-red-color">xxxx</td>
                                    </>
                                }                                              

                            </tr>

                            {/* <tr>
                                <td className="dkg-grey-color">Balance</td>
                                <td className="dkg-grey-color">87.00</td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="dk-portalBox dkg-account-grey-boxs" style={{ backgroundColor: "#fff", border: "1px solid #ddd" }}>
                <div className="title">PAYMENTS</div>
                <div className="dk-textPanel">
                    <table className="table dkg-accounting-boxtable">
                        {/* <thead>
                            <tr>
                                <th>Open Inv</th>
                                <th>Amount</th>
                                <th>Available</th>
                            </tr>
                        </thead> */}
                        <tbody>
                            <tr>
                                <td className="dkg-orange-color">New Task</td>
                                {
                                    userInfo.role === "Super Admin" ? <>
                                    <td className="dkg-orange-color">8</td>
                                    </>
                                    :
                                    <><td className="dkg-orange-color">xxxx</td></>
                                }
                                
                            </tr>
                            <tr>
                                <td className="dkg-green-color">Partial Paid</td>
                                
                                {
                                    userInfo.role === "Super Admin" ? <>
                                    <td className="dkg-green-color">29.00</td>
                                    </>
                                    :
                                    <><td className="dkg-green-color">xxxx</td></>
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default ReportsDashboard