import React from 'react';
import ProcessLeftPanel from './leftpanel/ProcessLeftPanel';

const BibbytermsProcessOverview = () => {
    return (
        <div className="dk-moduledkFinanceProcess-main" style={{ backgroundColor: "#fff" }}>
            <div className="dk-moduledkFinanceProcess-cols">
                <ProcessLeftPanel />
                <div className="dk-moduledkFinanceProcess-rightPanel">
                    <div className="title">Overview</div>
                    <div className="dk-moduledkFinanceProcess-rightCols">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BibbytermsProcessOverview;
