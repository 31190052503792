import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./services-overview.scss";
import { getCMSHeadingText } from "../../../../../slice/frontendCms/frontendCmsSlice";
import ViewMoreModal from "./modal/ViewMoreModal";

const ServicesOverview = () => {
  const dispatch = useDispatch();
  const { isLoading, getcmsList } = useSelector((state) => state.cmslist);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!isLoading && getcmsList && getcmsList.length > 0) {
      setData(getcmsList);
    }
  }, [isLoading]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getCMSHeadingText({}));
      } catch (err) {}
    }
    fetchData();
  }, [dispatch]);
  const [isViewmoreModal, setIsViewmoreModal] = useState(false);

  const showviewmoreModal = () => {
    setIsViewmoreModal(true);
  };
  const hideviewmoreModal = () => {
    setIsViewmoreModal(false);
  };

  return (
    <>
      {isViewmoreModal ? <ViewMoreModal onHide={hideviewmoreModal} /> : null}
      <div className="dkg-services-mainpageCon container-fluid">
        <div className="dkg-services-container">
          <div className="row dkg-services-mainRow">
            <div className="col-12 dkg-services-mainCol">
              <h2 className="dkg-services-page-title">
                {data.length > 0 ? data[1].page_description : ""}
              </h2>

              <p className="dkg-services-page-text">
                {data.length > 0 ? data[15].page_description : ""}
              </p>
            </div>
          </div>
          <div className="dkg-services-boxsCon">
            <div className="dkg-myservices-boxs">
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[2].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[2].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[2].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[3].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[3].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[3].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[4].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[4].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[4].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="dkg-myservices-boxs">
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[5].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[5].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[5].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[6].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[6].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[6].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[7].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[7].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[7].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="dkg-myservices-boxs">
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[8].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[8].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[8].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[9].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[9].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[9].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[10].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[10].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[10].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="dkg-myservices-boxs">
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[12].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[12].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[12].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[13].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[13].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[13].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[14].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[14].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[14].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="dkg-myservices-boxs">
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[12].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[12].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[12].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[13].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[13].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[13].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[14].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[14].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[14].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="dkg-myservices-boxs">
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[23].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[23].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[23].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[24].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[24].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[24].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
              <div className="dkg-services-box">
                <div className="dkg-services-imgCon">
                  <img
                    src={
                      data.length > 0
                        ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          "/" +
                          data[25].image
                        : ""
                    }
                    alt="Service"
                    className="dkg-services-img"
                  />
                </div>
                <div className="dkg-service-desc-textCon">
                  <h2 className="dkg-service-desc">
                    {data.length > 0 ? data[25].heading : ""}
                  </h2>
                  <p className="dkg-service-text">
                    {data.length > 0 ? data[25].heading_decription : ""}
                  </p>
                </div>
                <div className="dkg-service-box-btnCon">
                  <Link
                    to="#"
                    className="dkg-service-box-btn btn"
                    onClick={showviewmoreModal}
                  >
                    View More Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesOverview;
