import React from 'react'
import './spec-summary.scss';

const SpecSummary = () => {
    return (
        <>
            <div className='dkg-specsumm-mainPage container-fluid'>
                <div className='dkg-specsumm-mainRow'>
                    <div className='dkg-specsumm-mainCols'>
                        <div className="dkg-spec-header">
                            <div className="dkg-colstitle">ACTIVE SPEC</div>
                            <span className="dkg-totalCvCount">138</span>
                        </div>
                        <div className='dkg-spec-body'>
                            <ul>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                    <div className='dkg-specsumm-mainCols'>
                        <div className="dkg-spec-header">
                            <div className="dkg-colstitle">SAVED SPEC</div>
                            <span className="dkg-totalCvCount">138</span>
                        </div>
                        <div className='dkg-spec-body'>
                            <ul>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                    <div className='dkg-specsumm-mainCols'>
                        <div className="dkg-spec-header">
                            <div className="dkg-colstitle">COMPANY</div>
                            <span className="dkg-totalCvCount">138</span>
                        </div>
                        <div className='dkg-spec-body'>
                            <ul>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                    <div className='dkg-specsumm-mainCols'>
                        <div className="dkg-spec-header">
                            <div className="dkg-colstitle">PROJECT</div>
                            <span className="dkg-totalCvCount">138</span>
                        </div>
                        <div className='dkg-spec-body'>
                            <ul>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                    <div className='dkg-specsumm-mainCols'>
                        <div className="dkg-spec-header">
                            <div className="dkg-colstitle">ROLE TYPE</div>
                            <span className="dkg-totalCvCount">138</span>
                        </div>
                        <div className='dkg-spec-body'>
                            <ul>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                    <div className='dkg-specsumm-mainCols'>
                        <div className="dkg-spec-header">
                            <div className="dkg-colstitle">LANGUAGE</div>
                            <span className="dkg-totalCvCount">138</span>
                        </div>
                        <div className='dkg-spec-body'>
                            <ul>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SpecSummary