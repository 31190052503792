import React from 'react';

const index = () => {

    return (
        <React.Fragment>
            <div className="container-fluid">
                <h2 className='w-100 text-center'> Interviews</h2>
            </div>
        </React.Fragment>
    )
}
export default index;

