import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './duplicate.scss';
import "../../../../assets/scss/tooltip.scss";
import IconButton from '../../../ui/IconButton';
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError } from "../../../../slice/utils/message/messageSlice";
import { duplicateCheck, clearState } from "../../../../slice/candidates/candidatesSlice";
import CaseStatus from '../../candidate/pipeline/dropdown/caseStatus';
import CaseOther from '../../candidate/pipeline/dropdown/other';

const Duplicate = () => {
    const dispatch = useDispatch();
    const { isDuplicateLoading, duplicateList } = useSelector(state => state.candidate);

    const [isDuplicate, setIsDuplicate] = useState(true);
    const [list, setList] = useState([]);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        document.title = "Duplicate Check | DK Global CRM"
        setList([])
        setName('')
        setEmail('')
        setLoading(false)
        setIsDuplicate(true)
        console.log(isDuplicate)
    }, [])

    const handleClear = (e) => {
        e.preventDefault()
        setName('')
        setEmail('')
        setIsDuplicate(true)
        setList([])
        setLoading(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!name && !email) {
            dispatch(showError({ msg: 'Please fill either name or email' }))
        } else {
            try {
                setLoading(true)
                await dispatch(duplicateCheck({ name, email }))
            } catch (e) {
                dispatch(clearState())
                dispatch(showError({ msg: e }))
            }

        }
    }

    useEffect(() => {
        if (!isDuplicateLoading && duplicateList) {
            setLoading(false)
            dispatch(clearState())
            if (duplicateList.length > 0) {
                setIsDuplicate(true)
                setList(duplicateList)
            } else {
                setIsDuplicate(false)
                setList([])
            }
        }
    }, [isDuplicateLoading])

    return (
        <>
            <div className="dk-duplicateMain">
                <h3 className="title">Duplicate Check</h3>
                <div className="dk-hadForm">
                    <div className="d-flex align-items-center justify-content-center">
                        <form action="" className="col-12 d-flex dkg-mo-duplicateFromCon">
                            <div className='dk-duplicate-inputCon mr-2'>
                                <label for="">Search by Name</label>
                                <input type="text" className="form-control" placeholder="" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className='dk-duplicate-inputCon mr-2'>
                                <label for="">Search by Email</label>
                                <input type="text" className="form-control" placeholder="" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className='dk-duplicate-inputCon no-lastinputCon'>
                                <label for="">Search by Phone</label>
                                <input type="text" className="form-control" placeholder="" />
                            </div>
                            <div className='dkg-srh-dupli-btnCon'>
                                <IconButton txt="Search" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="btn dk-srchBtn" />
                                <button className='btn dk-dupli-clearbtn clear ml-2' onClick={handleClear}>Clear</button>
                            </div>
                        </form>
                    </div>
                    {
                        (!isDuplicate) ?
                            <div className="col-12">
                                <div className="no-duplicate"><b>No Duplicate</b></div>
                            </div> : null
                    }
                </div>
                {
                    (list.length > 0) ?
                        <div className='dk-duplicate-filterpageCon'>
                            <div className="dk-duplicateTableCon table-responsive">
                                <table className="dk-duplicateTable table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sn.</th>
                                            <th>Recruiter</th>
                                            <th>Profile ID</th>
                                            <th>Candidate Name</th>
                                            <th>Case Status</th>
                                            <th>Case Progress</th>
                                            <th>Case Stage</th>
                                            <th>Profile Table</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            list.map((item, index) =>
                                                <tr key={`duplicateList${index}`}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <div className="dk-recImg">
                                                            <div className="tooltipMain">
                                                                <img alt={item.recDetails.firstName + " " + item.recDetails.lastName} src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.recDetails.profileImage} className="tableImg" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                                                                <div className="tooltipText">{item.recDetails.firstName + " " + item.recDetails.lastName}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{item.candidateDetails.canId}</td>
                                                    <td>{item.candidateDetails.name}</td>
                                                    <td className='p-0 statusClass'><CaseStatus value={item.candidateDetails.caseStatus} /></td>
                                                    <td className='p-0 statusClass'><CaseOther caseStatus={item.candidateDetails.caseStatus} value={item.candidateDetails.case_progress} /></td>
                                                    <td className='p-0 statusClass'><CaseOther caseStatus={item.candidateDetails.caseStatus} value={item.candidateDetails.case_progress} /></td>
                                                    <td>
                                                        <span className='profile-location-text'>{item.candidateDetails.status}</span>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div >
                        </div>
                        : null
                }
            </div >
        </>
    )
}

export default Duplicate;
