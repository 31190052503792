import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CandTabContentOverview from './overview/Overview';
import CandExperience from "./experience/Experience";
import CandTrainnigAchiment from './experience/TrainnigAchiment'
import CandQualification from './qualification/Qualification';
import CandOtherInfo from './otherinfo/OtherInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';


const OnlineCvModal = ({ hideOnlineCv }) => {
    return (
        <>
            <Modal className="dk-onlineCvModal" show={true} onHide={hideOnlineCv}>
                <Modal.Header closeButton>
                    <Modal.Title className="dkg-resume-title-con">
                        <a href="#"  target={"_blank"} className='dkg-resume-download-icon'>
                        <FontAwesomeIcon icon={faDownload} />
                        </a>
                        <h4 className='dkg-cvdownload-title'>DKG RESUME</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='dkg-online-cv-modal-body'>
                    <CandTabContentOverview />  
                    <CandExperience />
                    <CandQualification />
                    <CandTrainnigAchiment />
                    <CandOtherInfo />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OnlineCvModal;
