import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import Recruiter from '../../../../assets/images/deepak.png'

const RecruiterFilter = () => {
    return (
        <>
            <div className='dk-wf-rec-dropdownCon'>
                <Dropdown className='dk-wf-rec-dropdown'>
                    <Dropdown.Toggle variant="" className='dk-wf-rec-dropdown-toggle' >
                        <img src={Recruiter} alt='Recruiter' className='dk-recimg' />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='dk-wf-rec-dropdownMenu'>
                        <Dropdown.Item href="#">
                            <img src={Recruiter} alt='Recruiter' className='dk-recimg' />
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                            <img src={Recruiter} alt='Recruiter' className='dk-recimg' />
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                            <img src={Recruiter} alt='Recruiter' className='dk-recimg' />
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                            <img src={Recruiter} alt='Recruiter' className='dk-recimg' />
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                            <img src={Recruiter} alt='Recruiter' className='dk-recimg' />
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                            <img src={Recruiter} alt='Recruiter' className='dk-recimg' />
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                            <img src={Recruiter} alt='Recruiter' className='dk-recimg' />
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                            <img src={Recruiter} alt='Recruiter' className='dk-recimg' />
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                            <img src={Recruiter} alt='Recruiter' className='dk-recimg' />
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                            <img src={Recruiter} alt='Recruiter' className='dk-recimg' />
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                            <img src={Recruiter} alt='Recruiter' className='dk-recimg' />
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default RecruiterFilter;
