import React from 'react';
const Stage = ({ value }) => {

    let bgColor = "";
    switch (value) {

        case '11':
            bgColor = '#d9714e'
            break;
        case '12':
            bgColor = '#d9714e'
            break;

        case 'Moved Forward':
            bgColor = '#26596d'
            break;

        case '14':
            bgColor = '#26596d'
            break;

        default:
            bgColor = '#26596d'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor, color: '#fff', minWidth: '80px' }}>{value}</div>
        </>
    )
}

export default Stage;