import React, {useEffect, useState, useRef} from "react";
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPaperclip,
    faSignature,
} from "@fortawesome/free-solid-svg-icons";
import {useSelector, useDispatch} from "react-redux";
import {Form} from "react-bootstrap";
import {Dropdown , Tab , Nav} from "react-bootstrap";
import SubmitButton from "../../../ui/submitButton";
import {Autocomplete, TextField} from '@mui/material';
import {GetData, PostData} from "../../../../services";
import {createFilterOptions} from "@material-ui/lab";
import MessageBox from "./editor/ReplyMessageBox";
import { getUserMailboxSignature } from "../../../../slice/mailbox/mailboxSlice";

const ReplyMail = (props) => {
    const {isLoading, isBtnSuccess, details, signature} = useSelector((state) => state.mailbox);
    const fileInput = useRef();

    const [mailList, setMailList] = useState([]);
    const [tempMailList, setTempMailList] = useState([]);
    const [to, setTo] = useState([]);
    const [cc, setCc] = useState([]);
    const [bcc, setBCc] = useState([]);
    const [body, setBody] = useState("");
    const [subject, setSubject] = useState(null);
    const [attachment, setAttachment] = useState([]);
    const [fileName, setFileName] = useState("");
    const [currentAccount, setCurrentAccount] = useState("Form");
    const {isReplyAll, isForward} = props;
    const dispatch = useDispatch();

    const filterOptions = createFilterOptions({
        limit: 30,
    });

    useEffect(() => {
        if (props.show) {
            clearForm();
            getMailList()
            dispatch(getUserMailboxSignature({ id: currentAccount.id, type: 'reply' }));
        }
    }, [props.show]);

    useEffect(() => {
        if (details && props.show) {
            if (isForward) {
                setBody(details.mailbox?.body || '')
                setSubject(`Frwd: ${details.mailbox.subject}`)
            } else {
                if (details?.mailbox?.Replies.length > 0) {
                    const lastReply = details?.mailbox?.Replies[details?.mailbox?.Replies.length - 1];
                    const from = {
                        email: lastReply.from,
                        name: lastReply.from_name
                    }
    
                    if (from.email == currentAccount.domain) {
                        const toList = lastReply.to || [];
                        setTo([...new Set(toList)])
                    } else {
                        setTo([from])
                    }
                    const ccList = lastReply.cc || [];
                    const bccList = lastReply.bcc || [];
                    if (isReplyAll) {
                        setCc([...new Set(ccList)])
                        setBCc([...new Set(bccList)])
                    }
                    setSubject(`${lastReply.subject.includes('Re:') ? '' : 'Re:'} ${lastReply.subject}`)
                } else {
                    const from = {
                        email: details.mailbox?.from,
                        name: details.mailbox?.from_name
                    }
    
                    if (from.email == currentAccount.domain) {
                        const toList = details.mailbox?.to || [];
                        setTo([...new Set(toList)])
                    } else {
                        setTo([from])
                    }
                    const ccList = details.mailbox?.cc || [];
                    const bccList = details.mailbox?.bcc || [];
                    if (isReplyAll) {
                        setCc([...new Set(ccList)])
                        setBCc([...new Set(bccList)])
                    }
                    setSubject(`Re: ${details.mailbox.subject}`)
                }
                console.log('useEffect ->', to)
            }
        }
    }, [details, props.show, props.isForward, props.isReplyAll]);

    useEffect(() => {
        if (props.currentAccount) {
            setCurrentAccount(props.currentAccount)
        }
    }, [props.currentAccount]);

    const getMailList = async () => {
        try {
            const res = await GetData(true, '/mail/candidates/list');
            const mailList = res.payload.result
            if (mailList.length > 0) {
                setTempMailList(mailList)
                setMailList(mailList.map(item => item.email1));
            }
        } catch (e) {
            console.error(e);
        }
    }

    const fileUploadHandler = (e) => {
        for (const item of e.target.files) {
            attachment.push(item);
        }
        if (attachment.length > 1) {
            setFileName(attachment.length + " files");
        } else {
            setFileName(e.target.files[0].name);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (currentAccount && details) {
            console.log('to ->', to)
            console.log('body ->', body)
            if (!to.length || !body.trim().length) {
                return;
            }
            const formData = new FormData();
            formData.append("from", `${currentAccount.domain}`);
            formData.append("mailbox_id", details.mailbox.id);
            formData.append("subject", subject);
            to.forEach((item, index) => {
                formData.append('to['+index+'][email]', item.email);
                formData.append('to['+index+'][name]', item.name);
            })
            cc.forEach((item, index) => {
                formData.append('cc['+index+'][email]', item.email);
                formData.append('cc['+index+'][name]', item.name);
            })
            bcc.forEach((item, index) => {
                formData.append('bcc['+index+'][email]', item.email);
                formData.append('bcc['+index+'][name]', item.name);
            })
            formData.append("html", body);
            if (attachment.length > 0) {
                formData.append("isAttachment", 1);
                for (let i = 0; i < attachment.length; i++) {
                    formData.append("attachments", attachment[i]);
                }
            }

            try {
                const response = await PostData(true, '/reply-mail', formData)
                console.log(response);
                props.onHide();
            } catch (e) {
                console.error(e);
            }
        }
    };

    const clearForm = () => {
        // setLoading(false);
        setFileName("");
        setTo([]);
        setCc([]);
        setBCc([]);
        setBody("");
        setSubject("");
        setAttachment([]);
    };

    const deleteImg = () => {
        setFileName("");
        fileInput.current.value = "";
        setAttachment([]);
    };

    useEffect(() => {
        if (isBtnSuccess) {
            setFileName("");
            setTo([]);
            setCc([]);
            setBCc([]);
            setBody("");
            setSubject("");
            setAttachment([]);
            props.onHide();
        }
    }, [isBtnSuccess]);

    const createNewFilterOptions = (options, params) => {
        const filtered = filterOptions(options, params)
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option);
        if (inputValue !== '' && !isExisting) {
          filtered.push(inputValue);
        }
        return filtered;
    }

    const selectTo = (event, selectedList) => {
        const data = selectedList.map((sEmail) => {
          const findName = tempMailList.find((email) => sEmail == email.email1);
          return {
            email: sEmail,
            name: findName ? findName.name : sEmail
          }
        })
        console.log(data);
        setTo(data)
    }
    
    const selectCc = (event, selectedList) => {
        const data = selectedList.map((sEmail) => {
          const findName = tempMailList.find((email) => sEmail == email.email1);
          return {
            email: sEmail,
            name: findName ? findName.name : sEmail
          }
        })
        setCc(data)
    }
    
    const selectBcc = (event, selectedList) => {
        const data = selectedList.map((sEmail) => {
          const findName = tempMailList.find((email) => sEmail == email.email1);
          return {
            email: sEmail,
            name: findName ? findName.name : sEmail
          }
        })
        setBCc(data)
    }
    const [eventKey, SetEventKey] = useState("tabs_1");


    return (
        <>
            <Modal className="dk-composeMailModal right" {...props}>
                <Modal.Header closeButton>
                    {isForward ? <Modal.Title>FORWARD</Modal.Title> : 
                    <Modal.Title className="dkg-email-reply-title">
                        <span className="dkg-attachment-con dkg-replay-attach-con">
                            <div className="dk-composeMail-PanelCols">
                                <div className="dk-mailAttach-file">
                                    <span>
                                        <FontAwesomeIcon icon={faPaperclip}/>
                                    </span>
                                    <Form.File
                                        type="file"
                                        className="custom-file-label"
                                        ref={fileInput}
                                        id="inputGroupFile01"
                                        label={fileName}
                                        onClick={(e) => setAttachment([])}
                                        onChange={fileUploadHandler}
                                        multiple
                                        custom
                                    />
                                </div>
                            </div>
                        </span>
                        REPLY {isReplyAll && 'ALL'}
                    </Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                        <div className="d-flex">
                        <div className="dk-mailboxLeft-panel">
                            <Nav
                            variant=""
                            onSelect={(selectedKey) => SetEventKey(selectedKey)}
                            >
                            <Nav.Item>
                                <Nav.Link eventKey="tabs_1">New Email</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tabs_2">Mailshot</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tabs_3">Templates</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="send_email">New Signature</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="receive_email">
                                Reply Signature
                                </Nav.Link>
                            </Nav.Item>
                            </Nav>
                        </div>
                        <div className="dk-mailboxRight-panel">
                            <Tab.Content>
                            <Tab.Pane eventKey="tabs_1">
                                <div className="dk-composeMailForm">
                                    <form
                                        onSubmit={handleSubmit}
                                        encType="multipart/form-data"
                                    >
                                        <div className="d-flex">
                                            <div className="dk-mailField">
                                                <div className="dk-mailSendOption">
                                                    <div className="dk-mailOption-items">To...</div>
                                                    <Autocomplete
                                                        disablePortal
                                                        freeSolo
                                                        multiple
                                                        getOptionLabel={(option) => option}
                                                        className="form-control dk-autocomplete-input m-lb-5x"
                                                        options={mailList}
                                                        value={to.map((to) => to.email)}
                                                        filterOptions={createNewFilterOptions}
                                                        onChange={selectTo}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </div>
                                                <div className="dk-mailSendOption">
                                                    <div className="dk-mailOption-items">Cc...</div>
                                                    <Autocomplete
                                                        disablePortal
                                                        multiple
                                                        freeSolo
                                                        getOptionLabel={(option) => option}
                                                        className="form-control dk-autocomplete-input m-lb-5x"
                                                        options={mailList}
                                                        value={cc.map((cc) => cc.email)}
                                                        filterOptions={createNewFilterOptions}
                                                        onChange={selectCc}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </div>
                                                <div className="dk-mailSendOption">
                                                    <div className="dk-mailOption-items">Bcc...</div>
                                                    <Autocomplete
                                                        disablePortal
                                                        multiple
                                                        freeSolo
                                                        getOptionLabel={(option) => option}
                                                        className="form-control dk-autocomplete-input m-lb-5x"
                                                        options={mailList}
                                                        value={bcc.map((bcc) => bcc.email)}
                                                        filterOptions={createNewFilterOptions}
                                                        onChange={selectBcc}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </div>
                                                <div className="dk-mailSendOption">
                                                    <div className="dk-mailOption-items">Subject</div>
                                                    <input type="text" className="form-control dk-autocomplete-input m-lb-5x subject-input" value={subject} disabled/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="dk-textCompose-mail">
                                            <div className="dk-mailAttach-file d-none">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                                        <FontAwesomeIcon icon={faSignature}/> Add
                                                        Signature
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#/action-1">
                                                            DK Global
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#/action-2">
                                                            Deepak Kumar
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3">
                                                            DK Masterbox
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className="dkg-reply-editor-con234">
                                                <MessageBox 
                                                    signature={signature}
                                                    onChange={value => setBody(value)}
                                                    details={details}
                                                />
                                            </div>
                                            <div className="dkg-send-btnCon dkg-reply-attach-btn-con">
                                                <SubmitButton  txt="Send" loading={isLoading} success={isBtnSuccess} position="justify-content-center" className="dkg-reply-send-btn" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tabs_2"></Tab.Pane>
                            <Tab.Pane eventKey="tabs_2"></Tab.Pane>
                            <Tab.Pane eventKey="send_email">
                                {/* <SendEmail mailboxUserId={currentAccount?.id} /> */}
                            </Tab.Pane>
                            <Tab.Pane eventKey="receive_email">
                                {/* <ReciveEmail mailboxUserId={currentAccount?.id} /> */}
                            </Tab.Pane>
                            </Tab.Content>
                        </div>
                        </div>
                    </Tab.Container>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ReplyMail;
