import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import { FaUserAlt, FaSpinner, FaBuilding, FaBook, FaLanguage, FaUserCheck, FaRegCalendarAlt, FaRegFileAlt } from 'react-icons/fa';
//import CustomDateFormat from "../../../ui/dateFormats/CustomDateFormat";
//import moment from "moment-timezone";

const SearchSendout = ({ onHide, headerTitle, list, selectUserFilter, selectOtherFilter, tableName }) => {
    
    const [sendoutDatesList, setSendoutDatesList] = useState([]);
    const [monthsLists, setMonthsList] = useState([]);
    const [amUserList, setAmUserList] = useState([]);
    const [rcUserList, setRcUserList] = useState([]);
    const [caseStage, setCaseStage] = useState([]);
    const [progressList, setProgress] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [langList, setLangList] = useState([]);
    const [cvSourceList, setCvSourceList] = useState([]);

    useEffect(() => {

        const sendoutResult = [];
        const sendoutmap = new Map();
        for (const item of list) {
            if (!sendoutmap.has(item.original.sendoutOn)) {
                sendoutmap.set(item.original.sendoutOn, true);
                sendoutResult.push({
                    name: (item.original.sendoutOn !== '') ? item.original.sendoutOn : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.sendoutOn === item.original.sendoutOn;
                    }).length
                });
            }
        }
        sendoutResult.sort(function (a, b) {
            return b.count - a.count;
        });

        const strAscending = [...sendoutResult].sort((a, b) =>
            a.name > b.name ? 1 : -1,
        );
        setSendoutDatesList(strAscending)

        const monthResult = [];
        const monthsmap = new Map();
        for (const item of list) {
            if (!monthsmap.has(item.original.months)) {
                monthsmap.set(item.original.months, true);
                monthResult.push({
                    name: (item.original.months !== '') ? item.original.months : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.months === item.original.months;
                    }).length
                });
            }
        }
        monthResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setMonthsList(monthResult)

        //     const strAscending = [...monthsLists].sort((a, b) =>
        //     a.name > b.name ? 1 : -1,
        //   );
        // console.log(strAscending)


        const rcResult = [];
        const rcmap = new Map();
        for (const item of list) {
            if (!rcmap.has(item.original.recInfo.id)) {
                rcmap.set(item.original.recInfo.id, true);
                rcResult.push({
                    id: item.original.recInfo.uid,
                    name: item.original.recInfo.firstName,
                    userImg: item.original.recInfo.profileImage,
                    count: list.filter(function (val) {
                        return val.original.recInfo.uid === item.original.recInfo.uid;
                    }).length
                });
            }
        }
        rcResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setRcUserList(rcResult)

        const amResult = [];
        const ammap = new Map();
        for (const item of list) {
            if (!ammap.has(item.original.amInfo.id)) {
                ammap.set(item.original.amInfo.id, true);
                amResult.push({
                    id: item.original.amInfo.uid,
                    name: item.original.amInfo.firstName,
                    userImg: item.original.amInfo.profileImage,
                    count: list.filter(function (val) {
                        return val.original.amInfo.uid === item.original.amInfo.uid;
                    }).length
                });
            }
        }
        amResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setAmUserList(amResult)

        const proressResult = [];
        const progressmap = new Map();
        for (const item of list) {
            if (!progressmap.has(item.original.progress)) {
                progressmap.set(item.original.progress, true);
                proressResult.push({
                    name: (item.original.progress !== '') ? item.original.progress : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.progress === item.original.progress;
                    }).length
                });
            }
        }
        proressResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setProgress(proressResult)


        const caseStageResult = [];
        const casestagemap = new Map();
        for (const item of list) {
            if (!casestagemap.has(item.original.stage)) {
                casestagemap.set(item.original.stage, true);
                caseStageResult.push({
                    name: (item.original.stage !== '') ? item.original.stage : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.stage === item.original.stage;
                    }).length
                });
            }
        }
        caseStageResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setCaseStage(caseStageResult)


        const companyResult = [];
        const companymap = new Map();
        for (const item of list) {
            if (!companymap.has(item.original.company)) {
                companymap.set(item.original.company, true);
                companyResult.push({
                    name: (item.original.company !== '') ? item.original.company : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.company === item.original.company;
                    }).length
                });
            }
        }
        companyResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setCompanyList(companyResult)

        const projectResult = [];
        const projectmap = new Map();
        for (const item of list) {
            if (!projectmap.has(item.original.project)) {
                projectmap.set(item.original.project, true);
                projectResult.push({
                    name: (item.original.project) ? item.original.project : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.project === item.original.project;
                    }).length
                });
            }
        }
        projectResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setProjectList(projectResult)

        const roleResult = [];
        const rolemap = new Map();
        for (const item of list) {
            if (!rolemap.has(item.original.roleType)) {
                rolemap.set(item.original.roleType, true);
                roleResult.push({
                    name: (item.original.roleType !== '') ? item.original.roleType : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.roleType === item.original.roleType;
                    }).length
                });
            }
        }
        roleResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setRoleList(roleResult)

        const langResult = [];
        const langmap = new Map();
        for (const item of list) {
            if (!langmap.has(item.original.language)) {
                langmap.set(item.original.language, true);
                langResult.push({
                    name: (item.original.language !== '') ? item.original.language : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.language === item.original.language;
                    }).length
                });
            }
        }
        langResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setLangList(langResult)

        const cvSourceResult = [];
        const cvSourcemap = new Map();
        for (const item of list) {
            if (!cvSourcemap.has(item.original.cvSource)) {
                cvSourcemap.set(item.original.cvSource, true);
                cvSourceResult.push({
                    name: (item.original.cvSource !== '') ? item.original.cvSource : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.cvSource === item.original.cvSource;
                    }).length
                });
            }
        }
        cvSourceResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setCvSourceList(cvSourceResult)


    }, [list])


    return (
        <>
            <Modal className="dk-sendoutsSearch-modal right" show={true} onHide={onHide}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>{props.headerTitle}</Modal.Title> */}
                    <Modal.Title>{headerTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-sendoutFilterTabs-main">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                            <div className="dk-sendoutTabs-nav">
                                <Nav variant="">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_1"><FaRegCalendarAlt className='mr-1' />{tableName}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_2"><FaRegCalendarAlt /> Month</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_3"><FaUserAlt /> AM</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_4"><FaUserAlt /> RC</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_5"><FaSpinner /> Status</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_6"><FaSpinner /> Progress</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_7"><FaSpinner /> Case Stage</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_8"><FaBuilding /> Company</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_9"><FaBook /> Project</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_10"><FaLanguage /> Language</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_11"><FaUserCheck /> Role Type</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_12"><FaRegFileAlt /> CV Source</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="dk-sendoutTabs-filterPanel">
                                <Tab.Content>
                                    <Tab.Pane eventKey="tabs_1">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                {/* <ul>
                                                    <li><Link to="#">15 July <span>30</span></Link></li>
                                                    <li><Link to="#">18 July <span>30</span></Link></li>
                                                    <li><Link to="#">05 August<span>0</span></Link></li>
                                                </ul> */}
                                                <ul>
                                                    {sendoutDatesList.map((item, index) => (
                                                        <li key={`sendoutOn${index}`} data-type="sendoutOn" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="sendoutOn" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="sendoutOn" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_2">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                {/* <ul>
                                                    {monthsLists.map((item, index) => (
                                                        <li key={`months${index}`} data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="months" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul> */}
                                                <ul>
                                                    <li>                                                        
                                                        {monthsLists.map((item, index) => (  
                                                            item.name == 'Jan' ?                                                         
                                                            <li key={`months${index}`} data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="months" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                            :
                                                            null
                                                        ))}
                                                    </li>
                                                    <li>                                                        
                                                        {monthsLists.map((item, index) => (  
                                                            item.name == 'Feb' ?                                                         
                                                            <li key={`months${index}`} data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="months" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                            :
                                                            null
                                                        ))}
                                                    </li>
                                                    <li>                                                        
                                                        {monthsLists.map((item, index) => (  
                                                            item.name == 'Mar' ?                                                         
                                                            <li key={`months${index}`} data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="months" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                            :
                                                            null
                                                        ))}
                                                    </li>
                                                    <li>                                                        
                                                        {monthsLists.map((item, index) => (  
                                                            item.name == 'Apr' ?                                                         
                                                            <li key={`months${index}`} data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="months" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                            :
                                                            null
                                                        ))}
                                                    </li>
                                                    <li>                                                        
                                                        {monthsLists.map((item, index) => (  
                                                            item.name == 'May' ?                                                         
                                                            <li key={`months${index}`} data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="months" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                            :
                                                            null
                                                        ))}
                                                    </li>
                                                    <li>                                                        
                                                        {monthsLists.map((item, index) => (  
                                                            item.name == 'Jun' ?                                                         
                                                            <li key={`months${index}`} data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="months" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                            :
                                                            null
                                                        ))}
                                                    </li>
                                                    <li>                                                        
                                                        {monthsLists.map((item, index) => (  
                                                            item.name == 'Jul' ?                                                         
                                                            <li key={`months${index}`} data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="months" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                            :
                                                            null
                                                        ))}
                                                    </li>
                                                    <li>                                                        
                                                        {monthsLists.map((item, index) => (  
                                                            item.name == 'Aug' ?                                                         
                                                            <li key={`months${index}`} data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="months" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                            :
                                                            null
                                                        ))}
                                                    </li>
                                                    <li>                                                        
                                                        {monthsLists.map((item, index) => (  
                                                            item.name == 'Sep' ?                                                         
                                                            <li key={`months${index}`} data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="months" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                            :
                                                            null
                                                        ))}
                                                    </li>
                                                    <li>                                                        
                                                        {monthsLists.map((item, index) => (  
                                                            item.name == 'Oct' ?                                                         
                                                            <li key={`months${index}`} data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="months" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                            :
                                                            null
                                                        ))}
                                                    </li>
                                                    <li>                                                        
                                                        {monthsLists.map((item, index) => (  
                                                            item.name == 'Nov' ?                                                         
                                                            <li key={`months${index}`} data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="months" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                            :
                                                            null
                                                        ))}
                                                    </li>
                                                    <li>                                                        
                                                        {monthsLists.map((item, index) => (  
                                                            item.name == 'Dec' ?                                                         
                                                            <li key={`months${index}`} data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="months" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="months" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                            :
                                                            null
                                                        ))}
                                                    </li>
                                                    
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_3">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-Recuriter">
                                                <ul>
                                                    {amUserList.map((item, index) => (
                                                        <li key={`amFilter${index}`}>
                                                            <Link to="#" data-type="am" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} alt={item.name} className="recImg" title={item.name} data-type="am" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                                                <span className="recuriterNo" data-type="am" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                    {item.count}
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_4">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-Recuriter">
                                                <ul>
                                                    {rcUserList.map((item, index) => (
                                                        <li key={`rcFilter${index}`}>
                                                            <Link to="#" data-type="rc" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} alt={item.name} className="recImg" title={item.name} data-type="rc" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                                                <span className="recuriterNo" data-type="rc" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                    {item.count}
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_5">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-satusListFilter">

                                                <ul>
                                                    <li><Link to="#" style={{ background: '#306f88', color: '#fff' }}>{tableName} <span>{list.length}</span></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_6">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    {progressList.map((item, index) => (
                                                        <li key={`progress${index}`} data-type="progress" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="progress" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="progress" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_7">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    {caseStage.map((item, index) => (
                                                        <li key={`stage${index}`} data-type="stage" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="stage" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="stage" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_8">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    {companyList.map((item, index) => (
                                                        <li key={`company${index}`} data-type="company" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="company" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="company" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_9">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    {projectList.map((item, index) => (
                                                        <li key={`project${index}`} data-type="project" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="project" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="project" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_10">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    {langList.map((item, index) => (
                                                        <li key={`lang${index}`} data-type="language" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="language" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="language" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_11">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    {roleList.map((item, index) => (
                                                        <li key={`role${index}`} data-type="roleType" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="roleType" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="roleType" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_12">
                                        <div className="dk-panelMainContent">
                                            <div className="dk-CategoryList">
                                                <ul>
                                                    {cvSourceList.map((item, index) => (
                                                        <li key={`cv_source${index}`} data-type="cvSource" data-value={item.name} onClick={selectOtherFilter}>
                                                            <Link to="#" data-type="cvSource" data-value={item.name} onClick={selectOtherFilter}>
                                                                {item.name}
                                                                <span className="text-orange" data-type="cvSource" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default SearchSendout;
