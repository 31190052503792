import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

import './accountingdashboard.scss';

const AccountingDashboard = () => {
    return (
        <>

        </>
    )
}

export default AccountingDashboard;