import React from 'react';
import { Link } from "react-router-dom";
import "./overview.scss";
import CVImg from "../../../../../../../assets/images/cvimg.png";
import EngFlag from "../../../../../../../assets/images/eng.jpg";
import GermanFlag from "../../../../../../../assets/images/german.jpg";
import FrenchFlag from "../../../../../../../assets/images/french.jpg";


const Overview = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="dk-overViewCon">
                        <div className="dk-panelAbout">
                            <div className="leftCon">
                                <div className="title">Career Objective</div>
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iste dignissimos ea ipsa molestias perferendis. Sequi rem repellat laboriosam necessitatibus illo accusamus nulla nemo placeat quae
                                    vero repellendus, non dolor alias. Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                            </div>
                            <div className="leftRight">
                                <div className="dk-resumeDownload">
                                    <input type="file" name="" id="" />
                                    <span>
                                        <img src={CVImg} className="img-fluid" alt="" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 suMMBlock">
                                <div className="title">Experience Summary</div>
                                <div className="suMMListCon">
                                    <ul>
                                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 suMMBlock">
                                <div className="title">Qualification Summary</div>
                                <div className="suMMListCon">
                                    <ul>
                                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="title dk-colsTitle ml-3">Technical Skills</div>
                        <div className="dk-skillsCols">
                            <Link to="#">HTML</Link>
                            <Link to="#">PHP</Link>
                            <Link to="#">Photoshop</Link>
                            <Link to="#">Laravl</Link>
                            <Link to="#">Javascript</Link>
                            <Link to="#">Jquery</Link>
                            <Link to="#">React Js</Link>
                            <Link to="#">Angular Js</Link>
                            <Link to="#">Coral Draw</Link>
                        </div>
                        <div className="title dk-colsTitle ml-3">Language Skills</div>
                        <div className="dk-viewLanguage">
                            <Link href="#">
                                <img src={EngFlag} className="img-fluid" alt="" /> English
                            </Link>
                            <Link href="#">
                                <img src={GermanFlag} className="img-fluid" alt="" /> German
                            </Link>
                            <Link href="#">
                                <img src={FrenchFlag} className="img-fluid" alt="" /> French
                            </Link>
                        </div>
                        <div className="title dk-colsTitle ml-3">Other Skills &amp; Knowledge</div>
                        <div className="dk-skillsCols">
                            <Link to="#">HTML</Link>
                            <Link to="#">PHP</Link>
                            <Link to="#">Photoshop</Link>
                            <Link to="#">Laravl</Link>
                            <Link to="#">Javascript</Link>
                            <Link to="#">Jquery</Link>
                        </div>
                        <div className="title dk-colsTitle ml-3">Personal Hobbies &amp; Interests</div>
                        <div className="dk-skillsCols">
                            <Link href="#">Cricket</Link>
                            <Link href="#">Football</Link>
                            <Link href="#">Chess</Link>
                            <Link href="#">Music</Link>
                            <Link href="#">Dance</Link>
                            <Link href="#">Wriniting</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Overview;
