import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/trainingmodule/CommonTraining';

const CommonTrainingLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="Training" moduleLink="/dashboard/training" linkcolor="#3c4c62" mainheaderTitle=" - DK GLOBAL" textcolor="#3c97b6" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default CommonTrainingLayout;
