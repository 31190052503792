import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import FormControl from 'react-bootstrap/FormControl';
import Dropdown from 'react-bootstrap/Dropdown'
import { updateJob, jobsDetails } from "../../../../../../slice/jobs/jobsSlice";

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');
        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className=""
                    placeholder="Search Text..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toString().toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);

function AllCompany(props) {
    const [categoryList, setCategoryList] = useState(props.categoryList);
    const [data, setData] = useState('Select');

    const dispatch = useDispatch();
    const { isDetailsLoading, jobDetails } = useSelector(state => state.jobs);
    const [Jobdata, setJobdata] = useState('')

    useEffect(() => {
        if (jobDetails && !isDetailsLoading) {
            setJobdata(jobDetails);
        }
    }, [isDetailsLoading])

    const id = Jobdata.id;
    useEffect(() => {
        if (!props.text) {
            setData('Select')
        } else {
            setData(props.text)
        }
    }, [props.text])

    return (
        <>
            <div className="dk-simpleDrpDwnCon">
                <Dropdown onSelect={function (evt) {
                    setData(evt)
                    dispatch(updateJob({ id: id, key: props.columnId, value: evt }));
                }} className="dk-simpleDrpDwn">
                    <Dropdown.Toggle variant="" className="dk-simpleDrpDwnToggle">
                        {data}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dk-simpleDrpDwnMenu" as={CustomMenu}>
                        {props.categoryList.length > 0 ?
                            props.categoryList.filter((data) => data.inStatus === 1).map((data, index) => (
                                <Dropdown.Item eventKey={data.name} key={index + 1}>{data.name} </Dropdown.Item>
                            )) : null
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default AllCompany = React.memo(AllCompany);