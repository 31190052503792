// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
import React, { useState, useEffect } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import Dropdown from 'react-bootstrap/Dropdown';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { FaTimes } from 'react-icons/fa';
import './selectdropdwn.scss';
import { updateTable, updateProfileDetails } from '../../../../../../../slice/candidates/list/listSlice';
import { useDispatch, useSelector } from "react-redux";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}

    </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        useEffect(() => {
            const timer = window.setInterval(() => {
                setValue('')
            }, 10000);
            return () => { // Return callback to run on unmount.
                window.clearInterval(timer);
            };
        }, [])

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className=" w-100"
                    placeholder="Search Text..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);
const SelectDropsDown = (props) => {
    const dispatch = useDispatch();

    const deleteValue = (e, data) => {
        dispatch(updateProfileDetails({ id: props.candidateId, key: props.column, value: '' }))
        dispatch(updateTable({ id: props.candidateId, key: props.column, value: '' }))
    }

    return (
        <>
            <div className="dk-suMMCaseStatusDropDwn">
                <Dropdown className={`dk-candsMMToggleBtn ${props.class}`} style={{ backgroundColor: '#FFF' }}>
                    <ContextMenuTrigger id={`same_unique_identifier${props.column}`}>
                        <Dropdown.Toggle as={CustomToggle} style={{ backgroundColor: '#FFF' }}>
                            {props.txt}
                        </Dropdown.Toggle>
                    </ContextMenuTrigger>
                    <ContextMenu id={`same_unique_identifier${props.column}`}>
                        <MenuItem data={{ foo: 'bar' }} onClick={deleteValue}>
                            <i className="far fa-trash-alt"></i> Delete
                        </MenuItem>
                    </ContextMenu>

                    {
                        (props.column === "category" || props.column === "visaStatus" || props.column === "career_level" || props.column === "education" || props.column === "priority") ?

                            <Dropdown.Menu className="dk-Drop14Menu">
                                {
                                    props.list.map((filteredItems, index) =>
                                        <Dropdown.Item className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" style={{ backgroundColor: '#FFF', color: '#333', borderBottom: '1px solid #ddd', textAlign: 'left' }} data-id={props.candidateId} data-value={filteredItems.value} data-key={props.column} data-refreshtype={props.refreshType} onClick={props.updateProfile}>{filteredItems.value}</Dropdown.Item>
                                    )
                                }
                            </Dropdown.Menu>
                            :
                            <Dropdown.Menu as={CustomMenu} className="dk-Drop14Menu dk-dropdown-menuCols">
                                {
                                    props.list.map((filteredItems, index) =>
                                        <Dropdown.Item className="dk-progress-stageItem active" id={props.column} key={`profile-case-${props.column}-id${index}`} href="#" style={{ backgroundColor: '#FFF', color: '#333', borderBottom: '1px solid #ddd', textAlign: 'left' }} data-id={props.candidateId} data-value={filteredItems.value} data-key={props.column} data-refreshtype={props.refreshType} onClick={props.updateProfile}>{filteredItems.value}</Dropdown.Item>
                                    )
                                }
                            </Dropdown.Menu>
                    }
                </Dropdown>
            </div>
        </>
    )
}
export default SelectDropsDown;
