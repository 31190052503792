import React from 'react';
import Modal from 'react-bootstrap/Modal';
import  './edit-myfile.scss'

const EditDocuments = ({ hidePopup }) => {
    return (
        <>
            <Modal className="dkg-cand-edit-file-modal" show={true}  onHide={hidePopup}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="">Uploaded File Name</label>
                                    <label htmlFor="">
                                        <input type="text" value="test.csv" className="form-control" name="" id="" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="">Date</label>
                                    <label htmlFor="">
                                        <input type="date" className="form-control" name="" id="" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="">File Type</label>
                                    <label htmlFor="">
                                        <select name="" id="" className="form-control">
                                            <option value="">Csv</option>
                                            <option value="">Pdf</option>
                                            <option value="">Doc</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group text-center mt-2 mb-0">
                                    <button className="dk-saveBtn">Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditDocuments;
