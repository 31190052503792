import React from "react";
import Modal from "react-bootstrap/Modal";
import "./viewmore-detail.scss";

const ViewMoreModal = ({ onHide }) => {
  return (
    <>
      <Modal
       show={true}
       onHide={onHide}
        className="dkg-viewdetailstask-modal-21"
      >
        <Modal.Header closeButton className="dkg-viewdetails-modalheader-21">
          <Modal.Title>View More Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-viewdetails-modalbody-21">
          
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewMoreModal;
