import React from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'

const AttachDropdown = () => {
    return (
        <>
            <DropdownButton className="dkg-task-achtmentDropDown" title="Attachments">
                <Dropdown.Item href="#" className="dkg-task-achtment-drop-item"></Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-task-achtment-drop-item"></Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-task-achtment-drop-item"></Dropdown.Item>
            </DropdownButton>
        </>
    )
}

export default AttachDropdown