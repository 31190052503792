import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import './taskmodal.scss'
import Modal from 'react-bootstrap/Modal'
import TodoTrackingTab from './TodoTrackingTab'
//import Recruiter from '../../../../../assets/images/deepak.png'
import moment from "moment-timezone";
const TaskModal = () => {
    const { userInfo } = useSelector(state => state.auth)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [renderTodo, setRenderTodo] = useState(1)
    const [rowId, setRowId] = useState(0)
    const handleSelect = (key) => {
        setRowId(0)
        switch (key) {
            case 'todo':
                setRenderTodo(1)
                break;

            case 'notes':
                setRowId(1)
                break;
        }
    }

    return (
        <>
            <Link to="#" onClick={handleShow}><i className="fa fa-tasks"></i></Link>
            <Modal show={show} onHide={handleClose} className="dk-actonrecordModal" >
                <Modal.Header closeButton className='dk-actonrecordModalHeader'>

                    <Modal.Title>My Private Tasks</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-actonrecordModalBody">
                    <TodoTrackingTab keyId="1" />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TaskModal