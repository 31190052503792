import React, { useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import "./budget.css";
import "./performance.scss"
import Dropdown from 'react-bootstrap/Dropdown';
import PerformanceAddNew from './modal/PerformanceAddNew';
import PerformanceEdit from './modal/PerformanceEdit';
import DeleteModal from "./modal/DeleteModal";
import { showSuccess } from "../../../../slice/utils/message/messageSlice";
import { listTable,deleteFunction,clearState,fetchById,updateByKey } from "../../../../slice/costBenifits/advPerformance";
import TableLoader from "../../../ui/tableLoader";
import { cvSourceDataFetch } from "../../../../slice/kpis/assignKpisReportSlice";
import { SYSTEM_CONSTANTS } from "../../../../constants";

const BudgetPerformance = () => {
    const dispatch = useDispatch();
    const { dataList, isLoading, isUpdate, isInsert, isReorder, isSort, isBulkDelete, isPerformanceDelete } = useSelector(state => state.performancedata);
    const [list, setList] = useState([]);
    const [type, setType] = useState('Type')
    const [columnKey, setColumnKey] = useState('')
    const [columnValue, setColumnValue] = useState('0')
    const [rowId, setRowId] = useState('0')
    const [isUpdatePop, setIsUpdatePop] = useState(false)
    const [issDelete, setIssDelete] = useState(false)

    var today = new Date();
    const [yearValue, setYearValue] = useState(today.getFullYear())
    const [monthValue, setMonthValue] = useState(today.getMonth() + 1)
    const changeYears = (data) => {
        setYearValue(data)
    }

    const retrieveData = () => {
        dispatch(listTable({ year: yearValue, month: monthValue, type: type }))
    }
    useEffect(retrieveData, [yearValue, monthValue, type]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const clearFilter = () => {
        setType('Type')
    }

    useEffect(() => {
        if (!isLoading && dataList) {
            setList(dataList)
        }
        if (isUpdate) {
            setList(dataList)
            dispatch(clearState())
            handleClose()
        }
        if (isInsert) {
            setList(dataList)
            dispatch(clearState())
        }
        if (isReorder) {
            setList(dataList)
            dispatch(clearState())
            retrieveData()
        }
        if (isPerformanceDelete) {
            setIssDelete(false)
            setLoading(false)
            dispatch(showSuccess({ msg: 'Delete Successfully.' }))
            dispatch(clearState())
            setList(dataList)
        }
        if (isSort) {
            setList(dataList)
            dispatch(clearState())
        }
        const fetchList = async () => {
            try {
                await dispatch(cvSourceDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.cvSourceId }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();

    }, [isLoading, isBulkDelete, isUpdate, isInsert, isReorder, isPerformanceDelete, isSort])

    const openUpdateValuePop = (id, key, value) => {
        setRowId(id)
        setColumnKey(key)
        setColumnValue(value)
    }

    const functionType = async (type,idd) => {     
        await dispatch(updateByKey({ id:idd, key: 'type', value:type }))
    }

    const editFunction = async (idd) => {       
        await dispatch(fetchById({id:idd}))
        setIsUpdatePop(true)        
    }
    const handleClose = () => {
        setIsUpdatePop(false)
    }
    const deletehandleClose = () => {
        setIssDelete(false)
    }
    const deleteDataFunction = (idd) => {
        setIssDelete(true)
        setRowId(idd)
    }

    const deletesItem = async () => {        
        await dispatch(deleteFunction({ id: rowId }))                  
        setLoading(true)
        setSuccess(false)
    }
    
    return (
        <React.Fragment>
            {
                isUpdatePop ? <PerformanceEdit year={yearValue} month={monthValue}  handleClose={handleClose}/> : null
            }
           {
               issDelete ? <DeleteModal loading={loading} success={success} deleteItem={deletesItem}  handleClose={deletehandleClose} tableName="advertisment_performances" /> : null
           }
            <div className="container-fluid dk-budgetPage-mainCon">
                <div className="row">
                    <h2 className="dk-budgetPageTitle w-100 text-center">
                        RESOURCES PERFORMANCE
                    </h2>
                </div>
                <div className='dkg-budget-top-headerCon'>
                    <div className="dk-budgetTopLeft">
                        {/* <div className="dk-mailshotsSearch mr-2">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search..." />
                            </div>
                        </div> */}
                        <div className='dkg-budget-yearsDropdownCon mr-2'>

                            <Dropdown className='dkg-budget-yearsDropdown'>
                                <Dropdown.Toggle variant="success" className='dkg-budget-years-toggle'>
                                    {yearValue}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='dkg-budget-dropdownMenu'>
                                    <Dropdown.Item onClick={() => changeYears('2023')}>2023</Dropdown.Item>
                                    <Dropdown.Item onClick={() => changeYears('2022')}>2022</Dropdown.Item>
                                    <Dropdown.Item onClick={() => changeYears('2021')}>2021</Dropdown.Item>
                                    <Dropdown.Item onClick={() => changeYears('2020')} >2020</Dropdown.Item>
                                    <Dropdown.Item onClick={() => changeYears('2019')} >2019</Dropdown.Item>
                                    <Dropdown.Item onClick={() => changeYears('2018')} >2018</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>
                        <PerformanceAddNew year={yearValue} month={monthValue} />
                    </div>
                    <div className="dk-creTrackTopCon">
                        <div className="dk-creTrackTabsCon">

                            <ul className="dk-budgetYears-Tabs">
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue('1')} className={(monthValue == '1') ? 'dkg-months-link active' : 'dkg-months-link'}>Jan</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue('2')} className={(monthValue == '2') ? 'dkg-months-link active' : 'dkg-months-link'}>Feb</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue('3')} className={(monthValue == '3') ? 'dkg-months-link active' : 'dkg-months-link'}>Mar</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue('4')} className={(monthValue == '4') ? 'dkg-months-link active' : 'dkg-months-link'}>Apr</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue('5')} className={(monthValue == '5') ? 'dkg-months-link active' : 'dkg-months-link'}>May</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue('6')} className={(monthValue == '6') ? 'dkg-months-link active' : 'dkg-months-link'}>Jun</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue('7')} className={(monthValue == '7') ? 'dkg-months-link active' : 'dkg-months-link'}>Jul</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue('8')} className={(monthValue == '8') ? 'dkg-months-link active' : 'dkg-months-link'}>Aug</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue('9')} className={(monthValue == '9') ? 'dkg-months-link active' : 'dkg-months-link'}>Sep</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue('10')} className={(monthValue == '10') ? 'dkg-months-link active' : 'dkg-months-link'}>Oct</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue('11')} className={(monthValue == '11') ? 'dkg-months-link active' : 'dkg-months-link'}>Nov</Link></li>
                                <li className="dk-budgetYear"><Link to="#" onClick={() => setMonthValue('12')} className={(monthValue == '12') ? 'dkg-months-link active' : 'dkg-months-link'}>Dec</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="dk-budgetTopRight-Con">
                        <div className="dk-budgetTopRight">                            
                            <div className="dk-reSLoginFilter">
                                {
                                    (type != "Type") ? <i className="fas fa-times-circle" data-type="type" onClick={clearFilter}></i> : null
                                }
                                <Dropdown className='dk-resloginfilterDrop'>
                                    <Dropdown.Toggle variant="" className='dk-resloginfilter-toggle'>
                                        {type}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dk-resloginfilterDropMenu'>
                                        <Dropdown.Item href="#" onClick={() => setType('Paid')} style={{ backgroundColor: "#31a886", color: "#fff" }}>PAID</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={() => setType('Unpaid')} style={{ backgroundColor: "#dc3545", color: "#fff" }}>UNPAID</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dk-budgetTableCont">
                    <table className="table dk-budgetTable" cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th>SNo.</th>
                                <th>Type</th>
                                <th>Name of Resource</th>
                                <th>SERVICES PROVIDED</th>
                                <th>Budget</th>
                                <th>NEW APPS</th>
                                <th>SENDOUTS</th>
                                <th>PLACEMENTS</th>
                                <th>REVENUE</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        {
                            (!isLoading) ?
                                <tbody>
                                    {
                                        list.map((item, index) =>
                                            <tr key={index + 1}>
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    <div className="dk-adsStatusBtn">
                                                        <Dropdown id={`type${index + 1}`}>
                                                            <Dropdown.Toggle variant="" id="dk-adsstatustoggle" style={{ backgroundColor: item.type == 'Paid' ? "#31a886" : "#dc3545", color: "#fff" }}>
                                                                {item.type}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#" onClick={() => functionType('Paid',item.id)} style={{ backgroundColor: "#31a886", color: "#fff" }}>PAID</Dropdown.Item>
                                                                <Dropdown.Item href="#" onClick={() => functionType('Unpaid',item.id)} style={{ backgroundColor: "#dc3545", color: "#fff" }}>UNPAID</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </td>

                                                <td onClick={() => openUpdateValuePop(item.id, 'resource', item.resource)}>
                                                    {
                                                        item.resource
                                                    }
                                                </td>
                                                <td onClick={() => openUpdateValuePop(item.id, 'serviceProvided', item.serviceProvided)}>
                                                    {
                                                        item.serviceProvided
                                                    }
                                                </td>
                                                <td onClick={() => openUpdateValuePop(item.id, 'budget', item.budget)}>
                                                    {
                                                        item.budget
                                                    }<span className='ml-1'>€</span>
                                                </td>
                                                <td onClick={() => openUpdateValuePop(item.id, 'newApps', item.newApps)}>
                                                    {
                                                        item.newApps
                                                    }
                                                </td>
                                                <td onClick={() => openUpdateValuePop(item.id, 'sendouts', item.sendouts)}>
                                                    {
                                                        item.sendouts
                                                    }
                                                </td>
                                                <td onClick={() => openUpdateValuePop(item.id, 'placements', item.placements)}>
                                                    {
                                                        item.placements
                                                    }
                                                </td>
                                                <td onClick={() => openUpdateValuePop(item.id, 'revenue', item.revenue)}>
                                                    {
                                                        item.revenue
                                                    } <span className='ml-1'>€</span>
                                                </td>
                                                <td>
                                                    <div className="dk-adstractAction">
                                                        <Link className="dk-edit-btn mr-2" to="#" onClick={() => editFunction(item.id)}>
                                                            <i className="fa fa-edit " aria-hidden="true" ></i>
                                                        </Link>
                                                        <Link className="dk-delete-btn" onClick={() => deleteDataFunction(item.id)}>
                                                            <i className="fa fa-trash " aria-hidden="true"></i>
                                                        </Link>                                                     
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                                :
                                <TableLoader colSpan="10" />
                        }

                        <tfoot>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    &nbsp;
                                </td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>
                                {
                                    list.reduce((a, v) => a = a + Number(v.budget), 0)
                                }
                                <span className='ml-1'>€</span></td>
                                <td> {
                                    list.reduce((a, v) => a = a + Number(v.newApps), 0)
                                }</td>
                                <td> {
                                    list.reduce((a, v) => a = a + Number(v.sendouts), 0)
                                }</td>
                                <td> {
                                    list.reduce((a, v) => a = a + Number(v.placements), 0)
                                }</td>
                                <td> {
                                    list.reduce((a, v) => a = a + Number(v.revenue), 0)
                                }<span className='ml-1'>€</span></td>
                                <td>&nbsp;</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}
export default BudgetPerformance;
