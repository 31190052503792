import React, { useEffect, useState, useRef } from "react";
import { Route, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./responsiveMenu.scss";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {
  countAll,
  countClosedAll,
} from "../../../../../../slice/candidates/list/listSlice";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         '& > *': {
//             margin: theme.spacing(1),
//         },
//     },
// }));

const CandidateSubmenu = (props) => {
  const dispatch = useDispatch();
  const { isMoved, totalCount, totalClosedCount } = useSelector(
    (state) => state.candidates
  );
  //const classes = useStyles();
  //console.log(totalCount)
  const pathname = window.location.pathname;
  const segment1 = pathname.split("/")[2];
  // const segment3 = pathname.split("/")[3];
  //const segment4 = pathname.split("/")[4];

  const [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    dispatch(countAll());
    dispatch(countClosedAll());
  }, [dispatch]);

  useEffect(() => {
    if (isMoved) {
      const fetchList = async () => {
        try {
          await dispatch(countAll());
          await dispatch(countClosedAll());
        } catch (err) {
          console.log(err);
        }
      };
      fetchList();
    }
  }, [isMoved]);

  useEffect(() => {
    setToggleMenu(false);
  }, [pathname]);

  const ref = useRef(null);
  const { onClickOutside } = props;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
        setToggleMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <React.Fragment>
      <div className="dk-subMenuMain res-sub-menu" ref={ref}>
        <IconButton
          aria-label="delete"
          className="res-sub-btn"
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <i className="fas fa-bars"></i>
        </IconButton>
        <Route>
          <ul
            className={`${
              toggleMenu ? "res-mobileNav show dkg-pipelinesubmenu" : null
            }`}
          >
            <li>
              <Link
                to="/pipelines/summary"
                className={`${segment1 == "summary" ? "active" : ""}`}
                style={{ borderRight: "1px solid #ccc" }}
              >
                Summary
              </Link>
            </li>
            {/* <li><Link to="/pipelines/all" className={`${(segment1 == 'all') ? 'active dkg-' : ''}`}>
                            All <span className="countNum all-color">
                                {
                                    totalCount.reduce(function (prev, current) {
                                        return prev + +current.count
                                    }, 0)
                                }
                            </span></Link></li> */}
            <li>
              <Link
                to="/pipelines/new-cv"
                className={`${
                  segment1 == "new-cv"
                    ? "active dkg-active dkg-newcv-active-color"
                    : "dkg-newcv-color"
                }`}
                style={{ borderRight: "1px solid #ccc" }}
              >
                New Case
                <span className="countNum newcv-color">
                  {totalCount
                    .filter((count) => count.status === "New CV")
                    .map((data) => (
                      <span> {data.count}</span>
                    ))}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/pipelines/rec-admin"
                className={`${
                  segment1 == "rec-admin"
                    ? "active dkg-active dkg-escalated-active-color"
                    : "dkg-escalated-color"
                }`}
                style={{ borderRight: "1px solid #ccc" }}
              >
                Selected{" "}
                <span className="countNum escalated-color">
                  {totalCount
                    .filter((count) => count.status === "Selected")
                    .map((data) => (
                      <span> {data.count}</span>
                    ))}
                </span>
              </Link>
            </li>

            {/* <li>
                            <Link to="/pipelines/job-spec" className={`${(segment1 == 'job-spec') ? 'active dkg-active  dkg-email-active-color' : 'dkg-email-color'}`} style={{ borderRight: "1px solid #ccc" }}>Selected <span className="countNum email-color">
                                {
                                    totalCount.filter(count => count.status === "Selected").map(data => (
                                        <span> {data.count}</span>
                                    ))
                                }
                            </span></Link></li> */}

            <li>
              <Link
                to="/pipelines/on-hold"
                className={`${
                  segment1 == "on-hold"
                    ? "active dkg-active dkg-processing-active-color"
                    : "dkg-processing-color"
                }`}
                style={{ borderRight: "1px solid #ccc" }}
              >
                Qualifying
                <span className="countNum processing-color">
                  {totalCount
                    .filter((count) => count.status === "To Progress")
                    .map((data) => (
                      <span> {data.count}</span>
                    ))}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/pipelines/db-ready"
                className={`${
                  segment1 == "db-ready"
                    ? "active dkg-active dkg-joboffer-active-color"
                    : "dkg-joboffer-color"
                }`}
                style={{
                  borderRight: "1px solid #ccc",
                  color: "#3ebb88",
                }}
              >
                Sendouts
                <span className="countNum db-color">
                  {totalCount
                    .filter((count) => count.status === "In Progress")
                    .map((data) => (
                      <span> {data.count}</span>
                    ))}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/pipelines/sendouts"
                className={`${
                  segment1 == "sendouts"
                    ? "active dkg-active dkg-sendouts-active-color"
                    : "dkg-sendouts-color"
                }`}
                style={{ borderRight: "1px solid #ccc" }}
              >
                {" "}
                Interviews{" "}
                <span className="countNum sendouts-color">
                  {totalCount
                    .filter((count) => count.status === "Sendout")
                    .map((data) => (
                      <span> {data.count}</span>
                    ))}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/pipelines/job-offer"
                className={`${
                  segment1 == "job-offer"
                    ? "active dkg-active dkg-joboffer-active-color"
                    : "dkg-joboffer-color"
                }`}
                style={{ borderRight: "1px solid #ccc" }}
              >
                Job Offers
                <span className="countNum joboffer-color">
                  {totalCount
                    .filter((count) => count.status === "Job Offer")
                    .map((data) => (
                      <span> {data.count}</span>
                    ))}
                </span>
              </Link>
            </li>

            <li>
              <Link
                to="/pipelines/calling"
                className={`${
                  segment1 == "calling"
                    ? "active dkg-active dkg-calling-active-color"
                    : "dkg-calling-color"
                }`}
                style={{ borderRight: "1px solid #ccc" }}
              >
                In Progress
                <span className="countNum calling-color">
                  {totalCount
                    .filter((count) => count.status === "Case Query")
                    .map((data) => (
                      <span> {data.count}</span>
                    ))}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/pipelines/interested"
                className={`${
                  segment1 == "interested"
                    ? "active dkg-active dkg-intersted-active-color"
                    : " dkg-intersted-color"
                }`}
                style={{ borderRight: "1px solid #ccc" }}
              >
                Completed{" "}
                <span className="countNum intersted-color">
                  {totalCount
                    .filter((count) => count.status === "Completed")
                    .map((data) => (
                      <span> {data.count}</span>
                    ))}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/pipelines/closed"
                className={`${
                  segment1 == "closed"
                    ? "active dkg-active dkg-closed-active-color"
                    : "dkg-closed-color"
                }`}
                style={{ borderRight: "1px solid #ccc" }}
              >
                Closed{" "}
                <span className="countNum closed-color">
                  {totalClosedCount
                    .filter((count) => count.status === "Closed")
                    .map((data) => (
                      <span> {data.count}</span>
                    ))}
                </span>
              </Link>
            </li>
          </ul>
        </Route>
      </div>
    </React.Fragment>
  );
};

export default CandidateSubmenu;
