import React from 'react';
import { Link } from "react-router-dom";
import "./overview.scss";
import CVImg from "../../../../../../../assets/images/cvimg.png";
import EngFlag from "../../../../../../../assets/images/eng.jpg";
import GermanFlag from "../../../../../../../assets/images/german.jpg";
import FrenchFlag from "../../../../../../../assets/images/french.jpg";
import ResumeProfile from "../../../../../../../assets/images/user-defult.jpg";


const Overview = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="dk-overViewCon">
                        <div className="dk-panelAbout">
                            <div className="leftCon">
                                <div className='dkg-resume-frm-grp'>
                                    {/* <label className='dkg-resume-label'>Name</label> */}
                                    <div className='dkg-resume-frm'>Ajay Chauhan</div>
                                </div>  
                                <div className='dkg-resume-frm-grp'>
                                    <label className='dkg-resume-label'>Location :</label>
                                    <div className='dkg-resume-frm'>Noida , India</div>
                                </div>   
                                <div className='dkg-resume-frm-grp'>
                                    <label className='dkg-resume-label'>Phone No :</label>
                                    <div className='dkg-resume-frm'>+91 7518724208</div>
                                </div>   
                                <div className='dkg-resume-frm-grp'>
                                    <label className='dkg-resume-label'>Email Address :</label>
                                    <div className='dkg-resume-frm'>ajaychauhanpp44@gmail.com</div>
                                </div>   
                                <div className='dkg-resume-frm-grp'>
                                    <label className='dkg-resume-label'>Nationality :</label>
                                    <div className='dkg-resume-frm'>Indian</div>
                                </div>  
                                <div className='dkg-resume-frm-grp'>
                                    <label className='dkg-resume-label'>Visa Status :</label>
                                    <div className='dkg-resume-frm dkg-resume-add-filed'>
                                      Yes
                                    </div>
                                </div>
                                <div className='dkg-resume-frm-grp'>
                                    <label className='dkg-resume-label'>Date of Birth :</label>
                                    <div className='dkg-resume-frm'>10 Aug 1998</div>
                                </div>    
                            </div>
                            <div className="leftRight">
                                <div className='dkg-resume-profile-con'>
                                    <img src={ResumeProfile} className='dkg-resume-profile' alt="RESUME PROFILE" />
                                </div>
                            </div>
                        </div>
                        <div className="title mb-2 ml-0 mt-3 pl-3 pr-3" style={{fontSize: "18px" , fontWeight: "600"}}>Profile Summary</div>
                        <div className="dk-skillsCols mb-4 ml-0  pl-3 pr-3">
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, soluta repellendus! Porro obcaecati quaerat dicta sunt dolorem inventore, neque ut molestias? Corporis at natus, beatae aliquid ipsum laborum ut.
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt ipsa ex iste sit minus provident consequatur eius nisi voluptates, nam hic et laudantium assumenda aut quia odio eos.
                            </p>
                        </div>
                        <div className="title mb-2 ml-0 mt-3 pl-3 pr-3" style={{fontSize: "18px" , fontWeight: "600"}}>Work Experience</div>
                        {/* <div className="row">
                            <div className="col-md-6 suMMBlock">
                                <div className="title">Experience Summary</div>
                                <div className="suMMListCon">
                                    <ul>
                                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 suMMBlock">
                                <div className="title">Qualification Summary</div>
                                <div className="suMMListCon">
                                    <ul>
                                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                                        <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="title dk-colsTitle ml-3">Technical Skills</div>
                        <div className="dk-skillsCols">
                            <Link to="#">HTML</Link>
                            <Link to="#">PHP</Link>
                            <Link to="#">Photoshop</Link>
                            <Link to="#">Laravl</Link>
                            <Link to="#">Javascript</Link>
                            <Link to="#">Jquery</Link>
                            <Link to="#">React Js</Link>
                            <Link to="#">Angular Js</Link>
                            <Link to="#">Coral Draw</Link>
                        </div>
                        <div className="title dk-colsTitle ml-3">Language Skills</div>
                        <div className="dk-viewLanguage">
                            <Link href="#">
                                <img src={EngFlag} className="img-fluid" alt="" /> English
                            </Link>
                            <Link href="#">
                                <img src={GermanFlag} className="img-fluid" alt="" /> German
                            </Link>
                            <Link href="#">
                                <img src={FrenchFlag} className="img-fluid" alt="" /> French
                            </Link>
                        </div>
                        <div className="title dk-colsTitle ml-3">Other Skills &amp; Knowledge</div>
                        <div className="dk-skillsCols">
                            <Link to="#">HTML</Link>
                            <Link to="#">PHP</Link>
                            <Link to="#">Photoshop</Link>
                            <Link to="#">Laravl</Link>
                            <Link to="#">Javascript</Link>
                            <Link to="#">Jquery</Link>
                        </div>
                        <div className="title dk-colsTitle ml-3">Personal Hobbies &amp; Interests</div>
                        <div className="dk-skillsCols">
                            <Link href="#">Cricket</Link>
                            <Link href="#">Football</Link>
                            <Link href="#">Chess</Link>
                            <Link href="#">Music</Link>
                            <Link href="#">Dance</Link>
                            <Link href="#">Wriniting</Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Overview;
