import React, { useEffect, useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FullSpecsEditor from "./fullspec/SpecsEditor";
import InterviewSpecEditor from "./interviewspec/SpecsEditor";
import { Tab, Nav } from "react-bootstrap";
import { InputGroup, FormControl, Form, Table, Row } from "react-bootstrap";
import {
  clearState,
  updateJob,
  updateMiniSpecTab,
  updateTitleBlocks,
  addInstructions,
} from "../../../../../../slice/jobs/jobsSlice";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../../slice/utils/message/messageSlice";
import { PdfFileUpload } from "./PdfFileUpload";
import "../../../addnew/addnewjob.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilSquare, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const Specs = (jobId) => {
  const dispatch = useDispatch();

  const { isDetailsLoading, jobDetails, isUpdate } = useSelector(
    (state) => state.jobs
  );

  const [data, setData] = useState("");
  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setData(jobDetails);
    }
  }, [isDetailsLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobDetails);
    }
  }, [isUpdate]);

  const sampleAd =
    data.miniSpecTab !== undefined && data.miniSpecTab.length
      ? data.miniSpecTab[0].sample_ad
      : "";
  const [sampleAdText, setSampleAdText] = useState(sampleAd);

  useEffect(() => {
    if (sampleAd !== "") {
      setSampleAdText(sampleAd);
    }
  }, [sampleAd]);

  const handleModelChange = (values) => {
    setSampleAdText(values);
    dispatch(
      updateMiniSpecTab({ id: jobId.jobId, key: "sample_ad", value: values })
    );
  };

  const ad_title_1 =
    data.titleBlocks !== undefined && data.titleBlocks.length
      ? data.titleBlocks[0].ad_title_1
      : "";
  const ad_title_2 =
    data.titleBlocks !== undefined && data.titleBlocks.length
      ? data.titleBlocks[0].ad_title_2
      : "";
  const ad_title_3 =
    data.titleBlocks !== undefined && data.titleBlocks.length
      ? data.titleBlocks[0].ad_title_3
      : "";
  const ad_title_4 =
    data.titleBlocks !== undefined && data.titleBlocks.length
      ? data.titleBlocks[0].ad_title_4
      : "";

  const updateSubjectData = (e) => {
    let values = e.target.value;
    let columnId = e.target.dataset.columnid;
    dispatch(
      updateTitleBlocks({ id: jobId.jobId, key: columnId, value: values })
    );
  };

  const [title, setTitle] = useState("");

  const handleButtonClick = async (e) => {
    e.preventDefault();
    if (title == "") {
      dispatch(showError({ msg: "All fields are required." }));
    } else {
      dispatch(addInstructions({ job_id: jobId.jobId, description: title }));
      setTitle("");
    }
  };
  const [fileName, setFileName] = useState("CV UPLOAD");

  return (
    <>
      <div className="row dk-Adsbox dkg-livejobs-adsboxRow">
        <div className="col-md-12 dkg-livejobs-adsboxCol">
          <Tab.Container id="left-tabs-example" defaultActiveKey="SendoutTo">
            <div
              className="row samplead ad-description dkg-livejobs-adsboxRow-234"
            // style={{
            //   border: "1px solid #bdbdbd",
            // }}
            >
              <div className="col-md-2 col-sm-2 specsLeftTab dkg-livejobs-adsboxleftSide pl-0 pr-0">
                <Nav variant="pills" className="d-flex edit-header nav nav-d-flex edit-header nav nav-pills dkg-livejobs-adsbox-navpills">
                  <Nav.Item>
                    <Nav.Link eventKey="dkg-global-process">DK Global Process</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Instructions">Clients Process</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="SendoutTo">Sendout To</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="cv-upload">Upload Link</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="SendoutEmail">Sample</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="col-md-10 col-sm-10 dkg-livejobs-adsboxRightSide specsLeftTab pl-0 pr-0">
                <Tab.Content>
                  {/* <Tab.Pane eventKey="details">
                                        <div className="dk-ClentViewTabPane summaryTabPane">
                                            <FullSpecsEditor jobId={jobId.jobId} />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="contacts">
                                        <div className="dk-ClentViewTabPane">
                                            <InterviewSpecEditor jobId={jobId.jobId} />
                                        </div>
                                    </Tab.Pane> */}
                  <Tab.Pane eventKey="SendoutTo">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div
                          className="col-md-12 interviewsTable mt-3 p-0"
                          style={{ height: "calc(100vh - 201px)" }}
                        >
                          <button
                            type="button"
                            className="btn dk-createInterview float-right mb-3"
                          >
                            Add New
                          </button>

                          <Table responsive>
                            <thead>
                              <tr>
                                <th>Position</th>
                                <th>Name of Person</th>
                                <th>Email Address</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Test</td>
                                <td>John Doe</td>
                                <td>abc@gmail.com</td>
                                <td>
                                  <div className="d-flex justify-content-center">
                                    <Link
                                      to="#"
                                      style={{ fontSize: "20px" }}
                                      className="mr-3 text-primary"
                                    >
                                      <FontAwesomeIcon icon={faPencilSquare} />
                                    </Link>
                                    <Link
                                      to="#"
                                      style={{
                                        fontSize: "18px",
                                        marginTop: "2px",
                                      }}
                                      className="text-danger"
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Test</td>
                                <td>John Doe</td>
                                <td>abc@gmail.com</td>
                                <td>
                                  <div className="d-flex justify-content-center">
                                    <Link
                                      to="#"
                                      style={{ fontSize: "20px" }}
                                      className="mr-3 text-primary"
                                    >
                                      <FontAwesomeIcon icon={faPencilSquare} />
                                    </Link>
                                    <Link
                                      to="#"
                                      style={{
                                        fontSize: "18px",
                                        marginTop: "2px",
                                      }}
                                      className="text-danger"
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Test</td>
                                <td>John Doe</td>
                                <td>abc@gmail.com</td>
                                <td>
                                  <div className="d-flex justify-content-center">
                                    <Link
                                      to="#"
                                      style={{ fontSize: "20px" }}
                                      className="mr-3 text-primary"
                                    >
                                      <FontAwesomeIcon icon={faPencilSquare} />
                                    </Link>
                                    <Link
                                      to="#"
                                      style={{
                                        fontSize: "18px",
                                        marginTop: "2px",
                                      }}
                                      className="text-danger"
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Test</td>
                                <td>John Doe</td>
                                <td>abc@gmail.com</td>
                                <td>
                                  <div className="d-flex justify-content-center">
                                    <Link
                                      to="#"
                                      style={{ fontSize: "20px" }}
                                      className="mr-3 text-primary"
                                    >
                                      <FontAwesomeIcon icon={faPencilSquare} />
                                    </Link>
                                    <Link
                                      to="#"
                                      style={{
                                        fontSize: "18px",
                                        marginTop: "2px",
                                      }}
                                      className="text-danger"
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Test</td>
                                <td>John Doe</td>
                                <td>abc@gmail.com</td>
                                <td>
                                  <div className="d-flex justify-content-center">
                                    <Link
                                      to="#"
                                      style={{ fontSize: "20px" }}
                                      className="mr-3 text-primary"
                                    >
                                      <FontAwesomeIcon icon={faPencilSquare} />
                                    </Link>
                                    <Link
                                      to="#"
                                      style={{
                                        fontSize: "18px",
                                        marginTop: "2px",
                                      }}
                                      className="text-danger"
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="dkg-global-process">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div className="col-md-12">
                          <div className="col-md-12 sendoutbox mt-3">
                            <h3>DK Global Process</h3>
                            <div className="scrollDiv pb-2">
                              {data.AdInstructions !== undefined &&
                                data.AdInstructions.length
                                ? data.AdInstructions.map((Ins, index) => (
                                  <p key={index} className="pl-3 pt-1">
                                    {Ins.description}
                                  </p>
                                ))
                                : null}
                            </div>
                          </div>
                          <InputGroup className="">
                            <FormControl
                              clas
                              sName="text-left insInput"
                              data-columnid="description"
                              aria-describedby="description"
                            />
                            <button type="submit" className="btn insSubmit">
                              Submit
                            </button>
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Instructions">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div className="col-md-12">
                          <div className="col-md-12 sendoutbox mt-3">
                            <h3>instructions for sendout</h3>
                            <div className="scrollDiv pb-2">
                              {data.AdInstructions !== undefined &&
                                data.AdInstructions.length
                                ? data.AdInstructions.map((Ins, index) => (
                                  <p key={index} className="pl-3 pt-1">
                                    {Ins.description}
                                  </p>
                                ))
                                : null}
                            </div>
                          </div>
                          <InputGroup className="">
                            <FormControl
                              className="text-left insInput"
                              data-columnid="description"
                              aria-describedby="description"
                            />
                            <button type="submit" className="btn insSubmit">
                              Submit
                            </button>
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="cv-upload">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div className="col-md-12 mt-4">
                          <div className="col-md-12 sendout-file mt-5 pt-5">
                            <InputGroup className="mb-3">
                              <FormControl
                                className="text-left cvInput"
                                data-columnid="description"
                                aria-describedby="description"
                              />
                              <button type="submit" className="btn insSubmit">
                                SAVE
                              </button>
                            </InputGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="SendoutEmail">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div className="col-md-12 p-0">
                          <div className="col-md-12 sendoutsecondbox mt-3">
                            <h3 className="dkg-sample-senout-title">Sample Sendout</h3>
                            <textarea className="scrollDiv pb-2"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default Specs;
