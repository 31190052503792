import React from 'react';
import './managementdashboard.scss';

const ManagementDashboard = () => {
    return (
        <>
            <div className="dk-adminManagementDashboard-main">
                <h2>Management Dashboard</h2>
            </div>
        </>
    )
}

export default ManagementDashboard;
