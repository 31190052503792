import React from 'react';
import './casestatus.scss';

const CaseStatusDropDwn = ({ caseStatus, value }) => {
    let bgColor = "";
    switch (caseStatus) {
        
        case 'Completed Probation':
            bgColor = '#4768b0'
            break;       
        case 'In Probation Period':
            bgColor = '#d58b5d'
            break;       
        case '100%':
            bgColor = '#da3d5e'
            break;       
        case '75%':
            bgColor = '#da3d5e'
            break;       
        case '50%':
            bgColor = '#da3d5e'
            break;       
        case '25%':
            bgColor = '#da3d5e'
            break;        
    
            default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor }}>
                {
                    (value == "Blank") ? "" : value
                }
            </div>
        </>
    )
}

export default CaseStatusDropDwn;