import React from 'react'
import { Link } from 'react-router-dom'
// import JobsAdminSubmenu from '../JobsAdminSubmenu'
import '../jobsadmin.scss'

const JobsCategory = () => {
    return (
        <>
            {/* <JobsAdminSubmenu /> */}
            <div className="dk-jobsAdminSubmenuCon">
                <div className="dk-jobsAdminNavCon">
                    <ul>
                        <li>
                            <Link className="active" to="/jobs/admin/category">Category</Link>
                        </li>
                        <li>
                            <Link className="" to="/jobs/admin/company">Company</Link>
                        </li>
                        <li>
                            <Link className="" to="#/jobs/admin/project">Project</Link>
                        </li>
                        <li>
                            <Link className="" to="#/jobs/admin/role-type">Role Type</Link>
                        </li>
                        <li>
                            <Link className="" to="#/jobs/admin/locality">Locality</Link>
                        </li>
                        <li>
                            <Link className="" to="#/jobs/admin/no_of_jobs">No of Jobs</Link>
                        </li>
                        <li>
                            <Link className="" to="#/jobs/admin/country">Country</Link>
                        </li>
                        <li>
                            <Link className="" to="#/jobs/admin/city">City/Town</Link>
                        </li>
                        <li>
                            <Link className="" to="#/jobs/admin/languages">Languages</Link>
                        </li>
                        <li>
                            <Link className="" to="#/jobs/admin/job-profile">Job Profile</Link>
                        </li>
                        <li>
                            <Link className="" to="#/jobs/admin/work-permit">Work Permit</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="container-fluid dk-jobsadmin-pageCon">
                <div className="row dkjobs-admin-categoryPageCon">
                    <div className="col-md-9 col-12 m-auto dkjobs-categoryblockCon">
                        <div className="dk-jobsadminblock-header">
                            <div className="dk-jobsadmin-leftCon">
                                <input id="search" placeholder="Search..." type="text" className="form-control" autofocus="autofocus" />
                            </div>
                            <div className="dk-jobsadmin-RightCon">
                                <Link to="#"><i className="fas fa-plus"></i></Link>
                            </div>
                        </div>
                        <div className="dk-backend-categorytableCon">
                            <table className="dk-backend-categorytable table table-bordered">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Category</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <input placeholder="IT" className="form-control" type="text" />
                                        </td>
                                        <td><i className="fas fa-trash-alt"></i></td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <input placeholder="ML" className="form-control" type="text" />
                                        </td>
                                        <td><i className="fas fa-trash-alt"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobsCategory
