import React from 'react';
const sendoutProgress = ({ value }) => {

    let bgColor = "";
    switch (value) {

        case 'In Progress':
            bgColor = '#d9714e'
            break;

        case 'Completed':
            bgColor = '#316a67'
            break;

        default:
            bgColor = '#5c737d'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor, color: '#fff', minWidth: '80px' }}>{value}</div>
        </>
    )
}

export default sendoutProgress;