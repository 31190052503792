import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown'

const RcFilter = ({ list, id, img, selectUserFilter }) => {
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        const result = [];
        const map = new Map();
        for (const item of list) {
            if (!map.has(item.original.reCcInfo.uid)) {
                map.set(item.original.reCcInfo.uid, true);
                result.push({
                    id: item.original.reCcInfo.uid,
                    name: item.original.reCcInfo.firstName,
                    userImg: item.original.reCcInfo.profileImage,
                    count: list.filter(function (val) {
                        return val.original.reCcInfo.uid === item.original.reCcInfo.uid;
                    }).length
                });
            }
        }
        result.sort(function (a, b) {
            return b.count - a.count;
        });
        setUserList(result)
    }, [list])

    return (
        <React.Fragment>
        <Dropdown className='dk-workplanrecDropdown'>
            <Dropdown.Toggle variant="" className="dk-workplanrecDropdown-btn">
                {
                    (id === '0') ? <span className='workplanrecimg'>RC</span> : <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + img} />
                }
            </Dropdown.Toggle>
            <Dropdown.Menu >
                <div className="d-flex">
                    {
                        userList && userList.map((item, index) =>
                            <Dropdown.Item href="#">
                                <div className="tooltipMain" data-type="SRecruters" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} className="filterImg" alt={item.name} data-id={item.id} data-type="SRecruters"  data-img={item.userImg} onClick={selectUserFilter} />
                                   
                                <span className="recNoCount" data-type="SRecruters" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                    {item.count}
                                </span>                               
                                </div>
                            </Dropdown.Item>
                        )
                    }
                </div>
            </Dropdown.Menu>
        </Dropdown>
    </React.Fragment>
    )
}
export default RcFilter;