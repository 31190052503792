import React from "react";
import '../mainMenu/style.scss';
import SuppliersSubmenu from "../subMenu/menu/trainingmodule/SuppliersSubmenu";
import ClientsSubmenu from "../subMenu/menu/trainingmodule/ClientsSubmenu";
import ProductsSubmenu from "../subMenu/menu/trainingmodule/ProductsSubmenu";
import CustomersSubmenu from "../subMenu/menu/trainingmodule/CustomersSubmenu";
import WorkToolsSubmenu from "../subMenu/menu/trainingmodule/WorkToolsSubmenu";
import ConductsSubmenu from "../subMenu/menu/trainingmodule/ConductsSubmenu";
import DKGlobalSubmenu from "../subMenu/menu/mainsubmenu/DKGlobalSubmenu";
import RecruitmentSubmenu from "../subMenu/menu/trainingmodule/RecruitmentSubmenu";
import BusinessSubmenu from "../subMenu/menu/trainingmodule/BusinessSubmenu";

const CommonTrainingSubmenu = ({ path }) => {
    const renderSubmenu = () => {
        switch (path.split("/")[2]) {
            case 'suppliers-training':
                return <SuppliersSubmenu />
                break;

            case 'clients-training':
                return <ClientsSubmenu />
                break;

            case 'products-training':
                return <ProductsSubmenu />
                break;

            case 'customers-training':
                return <CustomersSubmenu />
                break;

            case 'worktools-training':
                return <WorkToolsSubmenu />
                break;

            case 'conducts-training':
                return <ConductsSubmenu />
                break;

            case 'company':
                return <DKGlobalSubmenu />
                break;

            case 'recruitment':
                return <RecruitmentSubmenu />
                break;

            case 'business':
                return <BusinessSubmenu />
                break;


            default:
                break
        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default CommonTrainingSubmenu;