import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const EmailsDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/business') ? 'active' : ''}`}>Compose</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/industry') ? 'active' : ''}`}>Repiles</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/services') ? 'active' : ''}`}>Greetings</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/clients') ? 'active' : ''}`}>Signature</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/suppliers') ? 'active' : ''}`}>Structure</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/completion') ? 'active' : ''}`}>Length</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>CC/BCC</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Action</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Emotions</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Conducts</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Formatting</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Reply Speed</Link></li>


                </Route>
            </ul>
        </React.Fragment>
    )
}

export default EmailsDetailLeftPanel;

