import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
//import Modal from 'react-bootstrap/Modal';
import './savings.scss';
import { Modal } from 'react-bootstrap';
import { updateValue, clearState } from "../../../../slice/businessSaving/businessSlice";
import Submit from "../../../ui/submitButton";
import { showSuccess, showError } from "../../../../slice/utils/message/messageSlice";

const AddNumbers = ({ handleClose, onHide, rowId, columnKey, value }) => {
    const dispatch = useDispatch();
    const { isUpdate, isInsert, isError, errorMessage } = useSelector(state => state.business)

    const inputRef = useRef();
    useEffect(() => {
        inputRef.current.focus();
    })

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [newValue, setNewValue] = useState("");
    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "newValue":
                setNewValue(value)
                break;
            default:
                break;
        }
    }

    const handleButtonClick = async (e) => {
        e.preventDefault()
        setSuccess(false);
        setLoading(true);
        await dispatch(updateValue({ id: rowId, key: columnKey, value: newValue }))
    }

    useEffect(() => {
        if (isUpdate) {
            dispatch(showSuccess({ msg: 'Record update successfully.' }))
            dispatch(clearState())
            onHide()
            setSuccess(true);
            setLoading(false);
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }
    }, [isUpdate, isError])

    return (
        <>
            <Modal show={true}
                onHide={onHide}
                className="dk-addNumbersModal"
                centered

            >
                <Modal.Body>
                    <div className="d-flex">
                        <div className="form-group">
                            <label htmlFor="">Current</label>
                            <label htmlFor="">
                                <input type="text" className="form-control currentValue" value={value} readOnly />
                            </label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">New</label>
                            <label htmlFor="">
                                <input type="text" className="form-control" defaultValue="" name="newValue" value={newValue} onChange={handleChange} ref={inputRef} />
                            </label>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        {/* <button className="dk-saveBtn">SAVE</button> */}
                        <Submit txt="SAVE" loading={loading} success={success} onClick={handleButtonClick} className="dk-saveBtn" position="justify-content-center" />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddNumbers;
