import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/dkglobaladmin/HrMenu';

const OnboardingLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="HR" moduleLink="/dkglobaladmin/hr/dashboard" linkcolor="#3c4c62" mainheaderTitle=" - On Boarding" textcolor="#d58b5d" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default OnboardingLayout;
