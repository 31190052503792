import React from "react"
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from "react-redux";
//import { interviewCaseStatusColor } from "../../../helper/commonFunctions";

const JobOffersCaseProgress = ({ caseStatus, id, columnId, onItemClick }) => {

     const { jobOfferProgressList } = useSelector(state => state.common);
     const selectValue = (value, color) => {
         onItemClick(id, columnId, value, color);
        }
     
    return (
        <React.Fragment>
            {
                jobOfferProgressList.map((filteredItems, index) =>
                    <Dropdown.Item key={`case-progress-id${index}`} href="#" style={{ backgroundColor: filteredItems.bgColor, color: '#ffff' }} onClick={() => selectValue(filteredItems.value, '')}>
                        {filteredItems.value}
                    </Dropdown.Item>
                )
            }
        </React.Fragment>
    )
}
export default JobOffersCaseProgress;