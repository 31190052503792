import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { InputGroup, FormControl, Button } from 'react-bootstrap';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const Jobdetails = (jobId) => {
    return (
        <>
            <div className="row dk-Adsbox">
                <div className="col-md-12">
                    <div className="dk-job-details-createhtmEditor">
                        <CKEditor
                            editor={ClassicEditor}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log({ event, editor, data });
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                        />
                    </div>
                </div>

            </div>
        </>
    )
}

export default Jobdetails
