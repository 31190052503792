import React from 'react';
import './knowledgebase.scss';

const KnowledgeBaseDashboard = () => {
    return (
        <>
            <div className="dk-adminKnowleadgeBaseDashboard-main">
                <h2>Knowleadge Base Dashboard</h2>
            </div>
        </>
    )
}

export default KnowledgeBaseDashboard;
