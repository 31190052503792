import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFileUpload } from "use-file-upload";
import Modal from "react-bootstrap/Modal";
import AWS from "aws-sdk";
import { SYSTEM_CONSTANTS } from "../../../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  //   faBuilding,
  //   faLocationPin,
  //   faPhoneAlt,
  //   faPencil,
  //   faEnvelope,
  //   faIdCard,
  faGlobeAsia,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import SearchDropDownFilter from "../../../../ui/SelectPicker/searchTypeSelect";
import {
  jobsType,
  jobsIndustry,
  jobsBusiness,
  //   companyTaxNo,
  //   companyVatNo,
  jobsCountry,
  jobsCity,
  updateDetails,
  clearState,
} from "../../../../../slice/companies/clients/clientSlice";
//import SelectDropDown from "../../addnew/dropdown/SelectDropDown";
import Submit from "../../../../ui/submitButton";
import { showError } from "../../../../../slice/utils/message/messageSlice";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
//import NativeDatePicker from "../../../../ui/nativedatepicker/DatePicker";
import CompanyDropdown from "../../../../ui/SelectPicker/searchTypeSelect/companyLisst";
import { companyDataFetch } from "../../../../../slice/kpis/assignKpisReportSlice";

const EditListView = ({ handleClose }) => {
  const dispatch = useDispatch();
  const {
    // companyTaxList,
    // companyVatList,
    companyDetails,
    jobsTypeList,
    jobsBusinessList,
    jobsIndustryList,
    jobsCityList,
    jobsCountryList,
    // isUpdate,
    // message,
    // isError,
    //companyList,
  } = useSelector((state) => state.companies);

  const { companyList } = useSelector((state) => state.assignKpiReport);

  const { jobsCompanyList } = useSelector((state) => state.common);

  // const [companydata, setCompanydata] = useState(
  //   companyList.filter((item) => item.inStatus === 1)
  // );
  console.log(companyList);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [userImg, setUserImg] = useState('');
  // const [files, selectFiles] = useFileUpload();
  const [newImageData, setNewImageData] = useState();
  const [isImageUpdate, setIsImageUpdate] = useState(false);
  const [foundOn, setFoundOn] = useState("");
  const [clientId, setClientId] = useState(companyDetails.clientId);
  const [rowId, setRowId] = useState(companyDetails.id);
  const [businessName, setBusinessName] = useState(companyDetails.name);
  const [type, setType] = useState(companyDetails.type);
  const [city, setCity] = useState(companyDetails.city);
  const [country, setCountry] = useState(companyDetails.country);
  const [incomeTax, setIncomeTax] = useState(companyDetails.incomeTax);
  const [vatNo, setVatNo] = useState(companyDetails.vatNo);
  const [address, setAddress] = useState(companyDetails.address);
  const [phoneNo1, setPhoneNo1] = useState(companyDetails.phone1);
  const [phoneNo2, setPhoneNo2] = useState(companyDetails.phone2);
  const [email1, setEmail1] = useState(companyDetails.email1);
  const [email2, setEmail2] = useState(companyDetails.email2);
  const [website1, setWebsite1] = useState(companyDetails.website1);
  const [website2, setWebsite2] = useState(companyDetails.website2);
  const [cvURL, setCvURL] = useState(companyDetails.cvURL);
  const [defaultPreview, setDefaultPreview] = useState(<h3>browse Image</h3>);
  const [businessItemId, setBusiness] = useState(companyDetails.business);
  const [industryItemId, setIndustry] = useState(companyDetails.industry);

  // const [industryPlaceholder, setIndustryPlaceholder] = useState(companyDetails.industry)
  // const [businessPlaceholder, setBusinessPlaceholder] = useState(companyDetails.business)

  // const [taxNoPlaceholder, setTaxNoPlaceholder] = useState('')
  // const [vatNoPlaceholder, setVatPlaceholder] = useState('')

  const [collaboration, setCollaboration] = useState(
    companyDetails.collaboration
  );
  const [renewal, setRenewal] = useState(companyDetails.renewal);

  // const handleDateChangeCollaboration = (date) => {
  //     setCollaboration(date);
  // };
  // const handleDateChangeRenewal = (date) => {
  //     setRenewal(date);
  // };

  const [isLogo, setIsLogo] = useState(companyDetails.logo);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "foundOn":
        setFoundOn(value);
        break;
      case "collaboration":
        setCollaboration(value);
        break;
      case "renewal":
        setRenewal(value);
        break;
      case "address":
        setAddress(value);
        break;

      case "phoneNo1":
        setPhoneNo1(value);
        break;

      case "phoneNo2":
        setPhoneNo2(value);
        break;

      case "email1":
        setEmail1(value);
        break;

      case "email2":
        setEmail2(value);
        break;

      case "website1":
        setWebsite1(value);
        break;

      case "website2":
        setWebsite2(value);
        break;

      case "cvURL":
        setCvURL(value);
        break;

      case "vatNo":
        setVatNo(value);
        break;

      case "incomeTax":
        setIncomeTax(value);
        break;

      default:
        break;
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const getTypeValue = (data) => {
    setType(data);
  };
  const getCompanyValue = (data) => {
    setBusinessName(data);
  };
  const getCityValue = (data) => {
    setCity(data);
  };
  const getCountryValue = (data) => {
    setCountry(data);
  };
  const getIndustryValue = (data) => {
    setIndustry(data);
  };
  const getBusinessValue = (data) => {
    setBusiness(data);
  };

  const defaultImage = () => {
    setSelectedFile(null);
    setIsLogo("");
    setNewImageData("");
  };

  useEffect(() => {
    dispatch(clearState());
    dispatch(jobsCity());
    dispatch(jobsCountry());
    dispatch(
      companyDataFetch({ id: SYSTEM_CONSTANTS.KPI_REPORT_ASSIGN.comapnyId })
    );
    dispatch(jobsIndustry());
    dispatch(jobsBusiness());
    dispatch(jobsType());
    // dispatch(companyTaxNo())
    //dispatch(companyVatNo())
  }, []);

  // const handleChangeSelect = (event, data) => {
  //     const { name, value } = data
  //     switch (name) {
  //         case "industry":
  //             setIndustry(value)
  //             break;
  //         case "business":
  //             setBusiness(value)
  //             break;
  //     }
  // }

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsLogo(e.target.files[0]);
      setSelectedFile(e.target.files[0]);
      var fileToLoad = e.target.files[0];
      var fileReader = new FileReader();
      fileReader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result; // <--- data: base64
        var newImage = document.createElement("img");
        newImage.src = srcData;
        //console.log(newImage.outerHTML)
        setIsImageUpdate(true);
        setNewImageData(newImage.src);
      };
      fileReader.readAsDataURL(fileToLoad);
    }
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();

    if (!type || !businessName) {
      dispatch(clearState());
      dispatch(showError({ msg: "Fields are required." }));
    } else {
      if (!loading) {
        setSuccess(false);
        setLoading(true);

        if (selectedFile != null) {
          let s3bucket = new AWS.S3({
            accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
            secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
            Bucket: process.env.REACT_APP_BUCKET_NAME + "/clients",
          });
          s3bucket.createBucket(function () {
            var params = {
              Bucket: process.env.REACT_APP_BUCKET_NAME + "/clients",
              Key: Date.now() + "-" + selectedFile.name.replace(/ /g, "_"),
              Body: selectedFile,
            };
            s3bucket.upload(params, function (err, data) {
              if (err) {
                console.log(err);
              } else {
                dispatch(
                  updateDetails({
                    id: rowId,
                    name: businessName,
                    logo: "/" + data.key,
                    foundedOn: foundOn,
                    city,
                    country,
                    industry: industryItemId,
                    business: businessItemId,
                    type,
                    incomeTax,
                    vatNo,
                    address,
                    collaboration,
                    renewal,
                    phone1: phoneNo1,
                    phone2: phoneNo2,
                    email1,
                    email2,
                    website1,
                    website2,
                    cvURL,
                  })
                );
              }
            });
          });
        } else {
          setSelectedFile(null);
          let clientLogo;
          if (isLogo === companyDetails.logo) {
            clientLogo = companyDetails.logo;
          } else {
            clientLogo = "";
          }
          dispatch(
            updateDetails({
              id: rowId,
              name: businessName,
              logo: clientLogo,
              type,
              foundedOn: foundOn,
              city,
              country,
              industry: industryItemId,
              business: businessItemId,
              incomeTax,
              vatNo,
              address,
              collaboration,
              renewal,
              phone1: phoneNo1,
              phone2: phoneNo2,
              email1,
              email2,
              website1,
              website2,
              cvURL,
            })
          );
        }
      }
    }
  };

  return (
    <>
      <Modal
        className="dk-editCompanyList-modal"
        show={true}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <span className="dk-idType d-none">Client ID - {clientId}</span>
          <Modal.Title>EDIT CLIENT PROFILE</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-8">
              <div className="dk-addNClientFormCont">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel">Name of Client</label>
                      <CompanyDropdown
                        text={businessName}
                        list={companyList}
                        updateValue={getCompanyValue}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel">Client ID</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={clientId}
                        name="clientId"
                        readOnly
                        style={{ cursor: "not-allowed" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel">Geography</label>

                      <input
                        type="text"
                        className="form-control"
                        name="collaboration"
                        onChange={handleChange}
                        value={collaboration}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel">Category</label>
                      <SearchDropDownFilter
                        text={type}
                        list={jobsTypeList}
                        updateValue={getTypeValue}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel">Country</label>
                      <SearchDropDownFilter
                        text={country}
                        list={jobsCountryList}
                        updateValue={getCountryValue}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel">City/Town</label>
                      <SearchDropDownFilter
                        text={city}
                        list={jobsCityList}
                        updateValue={getCityValue}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel">Industry</label>

                      <SearchDropDownFilter
                        text={industryItemId}
                        list={jobsIndustryList}
                        updateValue={getIndustryValue}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel">Business</label>
                      <SearchDropDownFilter
                        text={businessItemId}
                        list={jobsBusinessList}
                        updateValue={getBusinessValue}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel">Contract</label>

                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="incomeTax"
                        onChange={handleChange}
                        value={incomeTax}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel dkg-calenderinput-type">
                        Renewal
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="renewal"
                        onChange={handleChange}
                        value={renewal}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel">VAT No.</label>

                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="vatNo"
                        onChange={handleChange}
                        value={vatNo}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6 col-sm-12">
                                        <div className="form-group dk-Candinate-fromGroup d-flex">
                                            <label className="dk-longllabel">Phone No</label>
                                            <input type="text" className="form-control" placeholder="" name="phoneNo1" onChange={handleChange} value={phoneNo1} />
                                        </div>
                                    </div> */}
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel">Head Office</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="phoneNo2"
                        onChange={handleChange}
                        value={phoneNo2}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel">Founded On</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="foundOn"
                        onChange={handleChange}
                        value={foundOn}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="address"
                        onChange={handleChange}
                        value={address}
                      />
                      <span className="dk-clViewAddonIcon d">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel">Website 1</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="website1"
                        onChange={handleChange}
                        value={website1}
                      />
                      <span className="dk-clViewAddonIcon d">
                        <FontAwesomeIcon icon={faGlobeAsia} />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel">Website 2</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="website2"
                        onChange={handleChange}
                        value={website2}
                      />
                      <span className="dk-clViewAddonIcon d">
                        <FontAwesomeIcon icon={faGlobeAsia} />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <div className="form-group dk-Candinate-fromGroup d-flex">
                      <label className="dk-longllabel">CV Upload Link</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="cvURL"
                        onChange={handleChange}
                        value={cvURL}
                      />
                      <span className="dk-clViewAddonIcon d">
                        <FontAwesomeIcon icon={faUpload} />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <div className="form-group dk-Candinate-fromGroup d-flex mb-0 mt-3">
                      <label className="dk-longllabel"></label>
                      <label
                        htmlFor=""
                        className="text-center w-100 d-flex justify-content-center"
                      >
                        <Submit
                          txt="Save"
                          loading={loading}
                          success={success}
                          onClick={handleButtonClick}
                          className="dk-createBtn"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="dkaddNewRightCon">
                <div className="addNewBox">
                  <div className="dk-FileUploadCon">
                    {isLogo === "" ? (
                      <>
                        <div className="dk-fileUldimgCon">
                          <figure id="edit_image">{defaultPreview}</figure>
                        </div>
                        <div className="dk-browsedFile">
                          <button onChange={onSelectFile}>
                            <input type="file" id="" name="" accept="image/*" />
                            <i className="fas fa-upload mr-2"></i> Upload
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="dk-uploadView-img">
                        {isImageUpdate ? (
                          <img src={newImageData} className="img-fluid" />
                        ) : (
                          <img
                            src={
                              process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              companyDetails.logo
                            }
                            className="img-fluid"
                          />
                        )}
                        <span className="close-btn" onClick={defaultImage}>
                          <FontAwesomeIcon icon={faXmark} />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditListView;
