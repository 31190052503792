import React from 'react';
import MarianaProjectClosedLeftPanel from "../leftpanel/mariana/MarianaProjectClosedLeftPanel";

const MarianaProjectClosed = () => {
    return (
        <>
            <div className="dk-moduleProjects-main">
                <div className="dk-moduleTraining-cols">
                    <MarianaProjectClosedLeftPanel />
                    <div className="dk-moduleTraining-rightPanel">
                        <div className="title">Summary</div>
                        <div className="dk-moduleTraining-rightCols">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MarianaProjectClosed;
