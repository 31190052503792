import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import StatementsLeftPanel from './leftpanel/StatementsLeftPanel';
import '../bankingcustom.scss';
import AddNewClientsInvoice from "../modal/AddNewClientsInvoice";
import { FaEye, FaDownload, FaSearch } from 'react-icons/fa';

const StatementTwentyTwenty = () => {
    const [isAddNewInvoiceModal, setIsAddNewInvoiceModal] = useState(false)

    const openAddNewInvoiceModal = () => {
        setIsAddNewInvoiceModal(true)
    }
    const hideAddNewInvoiceModal = () => {
        setIsAddNewInvoiceModal(false)
    }
    return (
        <>
            {
                (isAddNewInvoiceModal) ? <AddNewClientsInvoice hideAddNewInvoiceModal={hideAddNewInvoiceModal} /> : null
            }
            <div className="dk-moduleBankingProcess-main">
                <div className="dk-moduleBankingProcess-cols">
                    <StatementsLeftPanel />
                    <div className="dk-moduleBankingProcess-rightPanel bgTransparent">
                        <div className="dk-statmentFlex">
                            <div className="dk-searchCols">
                                <input type="text" name="" id="" placeholder="Search..." className="form-control" />
                                <button><FaSearch /></button>
                            </div>
                            <div className="title">Statement 2020</div>
                            <div className="dk-financeYears-addNew">
                                <Link to="#" onClick={openAddNewInvoiceModal}>+ Add New</Link>
                            </div>
                        </div>
                        <div className="dk-moduleBankingProcess-rightCols">
                            <div className="dk-financeClients-tableMain">
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Name of Bank</th>
                                                <th>Account No</th>
                                                <th>Statement Months</th>
                                                <th>View</th>
                                                <th>Download</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        1
                                                        <div className="dk-checkinputIcon">
                                                            <input type="checkbox" className="chkCandidate" value="" />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>Rossella Casciello</td>
                                                <td>52866547</td>
                                                <td>Recruitment</td>
                                                <td>
                                                    <div className="dk-financeAction">
                                                        <Link to="#" className="viewBtn"><FaEye /></Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dk-financeAction">
                                                        <Link to="#" className="downloadBtn"><FaDownload /></Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StatementTwentyTwenty;
