import React, { useState, useEffect } from 'react';
import SelectDropDown from './SelectDropDown';
import CaseStatusDropDwn from './CaseStatusDropDwn';
import SelectDropsDown from "./SelectDropsDown";
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import AddSkilsModal from './AddSkils';
import AddTechSkilsModal from './AddTechSkils';
import { SYSTEM_CONSTANTS } from "../../../../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "../datepicker"
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { clearState, removeJobProfileTags, removeTechTags, candidateAllTechSkillsTag, candidateAllJobProfileSkillsTag } from "../../../../../../../slice/candidates/candidatesSlice";

const Summary = ({ data, languages, updateProfile, updateDates, updateEditor, tabId }) => {
    const { caseStatusList, caseProgressList, caseStageList, candidateCategoryList, candidateNationalityList, candidateVisaStatusList, candidateNativeList, candidateFluentList, candidateIntermediateList, candidateGenderList, candidateIndustryList, candidateCareerLevelList, candidateCityList, candidateCountryList, candidateCVSourceList, candidateLatestJobList, candidateEduationList, candidateCareerPriorityList } = useSelector(state => state.common);

    const { isPreTagsLoading, preTagsList, isTagsLoading, jobProfiletagsList, tagsListTechSkills, tagsList } = useSelector(state => state.candidate);
    const dispatch = useDispatch();
    const [caseStatussList, setCaseStatussList] = useState([])
    const [case_progressList, setCase_progressList] = useState([])
    const [case_stageList, setCase_stageList] = useState([])

    const [candidateId, setCandidateId] = useState(0)
    const [id, setId] = useState(0)

    const [statusBG, setStatusBG] = useState('#FFFFFF')
    const [caseStatus, setCaseStatus] = useState('')
    const [case_progress, setCase_progress] = useState('')
    const [case_stage, setCase_stage] = useState('')
    const [category, setCategory] = useState('')
    const [cv_source, setCv_source] = useState('')
    const [nationality, setNationality] = useState('')
    const [visaStatus, setVisaStatus] = useState('')
    const [gender, setGender] = useState('')

    const [c_city, setC_city] = useState('')
    const [c_country, setC_country] = useState('')

    const [native1, setNative1] = useState('')
    const [native2, setNative2] = useState('')
    const [fluent1, setFluent1] = useState('')
    const [fluent2, setFluent2] = useState('')
    const [intermediate1, setIntermediate1] = useState('')
    const [intermediate2, setIntermediate2] = useState('')

    const [industry, setIndustry] = useState('')
    const [industry2, setIndustry2] = useState('')
    const [industry3, setIndustry3] = useState('')
    const [career_level, setCareer_level] = useState('')

    const [createdAt, setCreatedAt] = useState('')
    const [profile_notes, setProfile_notes] = useState('')
    const [desired_salary, setDesired_salary] = useState('')
    const [desired_benefits, setDesired_benefits] = useState('')
    const [desired_location, setDesired_location] = useState('')
    const [working_location, setWorking_location] = useState('')
    const [relocation_abroad, setRelocation_abroad] = useState('')

    const [latest_job, setLatest_job] = useState('')
    const [candidate_eduation, setCandidate_eduation] = useState('')

    const [career_priority, setCareer_priority] = useState('')
    const [career_priority2, setCareer_priority2] = useState('')
    const [career_priority3, setCareer_priority3] = useState('')

    function isEmpty(obj) {
        for (var i in obj) { return false; }
        return true;
    }

    const [allTechTags, setTechAllTags] = useState([])
    const [allProfileTags, setProfileAllTags] = useState([])

    useEffect(() => {

        if (!isTagsLoading) {
            setTechAllTags(tagsList)
            setProfileAllTags(jobProfiletagsList)
        }

    }, [isTagsLoading])

    useEffect(() => {
        const fetchTechTagList = async () => {
            try {
                await dispatch(candidateAllTechSkillsTag({ id: data.canId }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchTechTagList();
    }, [candidateAllTechSkillsTag])

    useEffect(() => {
        const fetchJobProfileTagList = async () => {
            try {
                await dispatch(candidateAllJobProfileSkillsTag({ id: data.canId }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchJobProfileTagList();
    }, [candidateAllJobProfileSkillsTag])

    useEffect(() => {
        setTechAllTags(tagsList)
    }, [tagsList])

    useEffect(() => {
        setProfileAllTags(jobProfiletagsList)
    }, [jobProfiletagsList])

    useEffect(() => {
        if (tabId === 'details') {
            setDataValue()
        } else {
            setDataValue()
        }
    }, [tabId, data, languages])

    const setDataValue = () => {
        if (!isEmpty(data)) {

            setCaseStatussList(caseStatusList)
            setCase_progressList(caseProgressList)
            setCase_stageList(caseStageList)
            setId(data.id)
            setCandidateId(data.canId)
            setC_city(data.c_city)
            setC_country(data.c_country)
            setStatusBG(getCaseStatusColor(data.caseStatus))
            setCaseStatus(data.caseStatus)
            setCase_progress(data.case_progress)
            setCase_stage(data.case_stage)
            setCategory(data.category)
            setCv_source(data.cv_source)
            setNationality(data.nationality)
            setVisaStatus(data.visaStatus)
            setGender(data.gender)

            setIndustry(data.industries)
            setIndustry2(data.industry2)
            setIndustry3(data.industry3)
            setCareer_level(data.career_level)

            setCreatedAt(data.createdAt)
            data.profile_notes != null ? setProfile_notes(data.profile_notes) : setProfile_notes('')
            data.desired_salary != null ? setDesired_salary(data.desired_salary) : setDesired_salary('')
            data.desired_benefits != null ? setDesired_benefits(data.desired_benefits) : setDesired_benefits('')
            data.desired_location != null ? setDesired_location(data.desired_location) : setDesired_location('')
            data.working_location != null ? setWorking_location(data.working_location) : setWorking_location('')
            data.relocation_abroad != null ? setRelocation_abroad(data.relocation_abroad) : setRelocation_abroad('')

            setNative1(data.native1)
            setNative2(data.native2)
            setFluent1(data.fluent1)
            setFluent2(data.fluent2)
            setIntermediate1(data.intermediate1)
            setIntermediate2(data.intermediate2)

            data.latest_job != null ? setLatest_job(data.latest_job) : setLatest_job('')
            setCandidate_eduation(data.education)
            setCareer_priority(data.priority)
            setCareer_priority2(data.priority2)
            setCareer_priority3(data.priority3)
        }
    }

    const getCaseStatusColor = (caseStatusValue) => {
        switch (caseStatusValue) {
            case 'New Case':
                return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.NewCase

            case 'Selected':
                return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Selected

            case 'To Process':
                return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Screening

            case 'Database':
                return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Queries

            case 'Interviews':
                return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Sendout

            case 'Job Offer':
                return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.JobOffer

            case 'Joined':
                return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Joined

            case 'Refused':
                return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.RefusedOffer

            case 'Blacklist':
                return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Blacklist

            case 'Qualifying':
                return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Processing

            case 'Invoice':
                return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Invoice

            case 'Closed':
                return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.Closed

            case 'Promotion':
                return SYSTEM_CONSTANTS.CANDIDATES.CaseStatusColor.NotReachable

            default:
                return '#ffffff';

        }
    }

    const [isAddSkills, setIsAddSkills] = useState(false);

    const showAddProfieSkills = () => {
        setIsAddSkills(true);
    }
    const hideAddProfieSkills = () => {
        setIsAddSkills(false);
    }

    const [isAddTechSkills, setIsAddTechSkills] = useState(false);

    const showAddTechSkills = () => {
        setIsAddTechSkills(true);
    }
    const hideAddTechSkills = () => {
        setIsAddTechSkills(false);
    }

    const removeTechSkillsTag = async (id) => {
        await dispatch(removeTechTags({ id }))
    }

    const removeJobsProfileTag = async (id) => {
        await dispatch(removeJobProfileTags({ id }))
    }

    return (
        <>
            {
                (isAddSkills) ? <AddSkilsModal hidePopup={hideAddProfieSkills} candidateId={data.canId} /> : null
            }

            {
                (isAddTechSkills) ? <AddTechSkilsModal hidePopup={hideAddTechSkills} candidateId={data.canId} /> : null
            }
            <div className="dk-candSuMMainCont">
                {/* <div className="col-md-2 pl-2">
                        <div className="calenderboxCon">
                            <label>JOINING</label>
                            <input type="date" className="form-control" name="started" value="" />
                        </div>
                    </div> */}
                <div className="row dk-middleSec">
                    <div className="col-md-7 col-sm-12 pl-4 pr-4">
                        <div className="case-rowCon mb-3">
                            <div className="dk-dropboxcols">
                                <div className="newCVSelectDropDwn">
                                    <label>CASE STATUS</label>
                                    <CaseStatusDropDwn statusBG={statusBG} caseStatus={caseStatus} list={caseStatussList} candidateId={id} updateProfile={updateProfile} column="caseStatus" errorclassName={(caseStatus == '' ? "errorBorder" : null)} />
                                </div>
                            </div>
                            <div className="dk-dropboxcols">
                                <div className="newCVSelectDropDwn">
                                    <label>CASE STAGE</label>
                                    <SelectDropDown statusBG={statusBG} txt={case_progress} list={case_progressList} candidateId={id} updateProfile={updateProfile} column="case_progress" className={(case_progress == '' ? "errorBorder" : null)} />
                                </div>
                            </div>
                            <div className="dk-dropboxcols">
                                <div className="newCVSelectDropDwn">
                                    <label>CASE PROGRESS</label>
                                    <SelectDropDown statusBG={statusBG} txt={case_stage} list={caseStageList} candidateId={id} updateProfile={updateProfile} column="case_stage" className={(case_stage == '' ? "errorBorder" : null)} />
                                </div>
                            </div>
                        </div>
                        <div className="row dk-middleLeftSec">
                            <div className="col-md-6">

                                <div className="dk-cansuMMFromGrp">
                                    <label>Category</label>
                                    <div className="selDrop14 ">
                                        <SelectDropsDown txt={category} list={candidateCategoryList} candidateId={id} updateProfile={updateProfile} column="category" className={(category == '' ? "errorBorder" : null)} />
                                    </div>
                                </div>

                                <div className="dk-cansuMMFromGrp">
                                    <label>Town/City</label>
                                    <div className="selDrop14">
                                        <SelectDropsDown txt={c_city} list={candidateCityList} candidateId={id} updateProfile={updateProfile} column="c_city" className={(c_city == '' ? "errorBorder" : null)} />
                                    </div>
                                </div>

                                <div className="dk-cansuMMFromGrp">
                                    <label>Nationality</label>
                                    <div className="selDrop14">
                                        <SelectDropsDown txt={nationality} list={candidateNationalityList} candidateId={id} updateProfile={updateProfile} column="nationality" className={(nationality == '' ? "errorBorder" : null)} />
                                    </div>
                                </div>
                                <div className="dk-cansuMMFromGrp dk-educationPriorityDropdown">
                                    <label>Education</label>
                                    <div className="selDrop14 dkg-visa-statusdropdown-14 dk-upperDropdown">
                                        <SelectDropsDown txt={candidate_eduation} list={candidateEduationList} candidateId={id} updateProfile={updateProfile} column="education" className={(candidate_eduation == '' || candidate_eduation == null ? "errorBorder" : null)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">

                                <div className="dk-cansuMMFromGrp">
                                    <label>CV Source</label>
                                    <div className="selDrop14">
                                        <SelectDropsDown txt={cv_source} list={candidateCVSourceList} candidateId={id} updateProfile={updateProfile} column="cv_source" className={(cv_source == '' ? "errorBorder" : null)} />
                                    </div>
                                </div>
                                {/* <div className="dk-cansuMMFromGrp">
                                    <label>Nationality</label>
                                    <div className="selDrop14">
                                        <SelectDropsDown txt={nationality} list={candidateNationalityList} candidateId={id} updateProfile={updateProfile} column="nationality" />
                                    </div>
                                </div> */}

                                <div className="dk-cansuMMFromGrp">
                                    <label>Country</label>
                                    <div className="selDrop14">
                                        <SelectDropsDown txt={c_country} list={candidateCountryList} candidateId={id} updateProfile={updateProfile} column="c_country" className={(c_country == '' ? "errorBorder" : null)} />
                                        {/* <input type="text" className="form-control" data-id={id} data-key="c_country" data-value={c_country} value={c_country} onChange={(e) => setC_country(e.target.value)} onBlur={updateProfile} /> */}
                                    </div>
                                </div>
                                <div className="dk-cansuMMFromGrp">
                                    <label>VISA Status</label>
                                    <div className="selDrop14 dkg-visa-statusdropdown-14">
                                        <SelectDropsDown txt={visaStatus} list={candidateVisaStatusList} candidateId={id} updateProfile={updateProfile} column="visaStatus" className={(visaStatus == '' ? "errorBorder" : null)} />
                                    </div>
                                </div>
                                <div className="dk-cansuMMFromGrp">
                                    <label>Career Level</label>
                                    <div className="selDrop14 dk-upperDropdown dkg-visa-statusdropdown-14 dkg-carrerlevel-dropdown-u14">
                                        <SelectDropsDown txt={career_level} list={candidateCareerLevelList} candidateId={id} updateProfile={updateProfile} column="career_level" className={(career_level == '' ? "errorBorder" : null)} />
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-12">
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="dk-cansuMMFromGrp">
                                            <label>Current Job</label>
                                            <div className="selDrop14 dk-upperDropdown">
                                                {/* <SelectDropsDown txt={latest_job} list={candidateLatestJobList} candidateId={id} updateProfile={updateProfile} column="latest_job" className={(latest_job == '' || latest_job == null ? "errorBorder" : null)} /> */}
                                                <input type="text" className="form-control" value={latest_job} data-value={latest_job} data-id={id} onChange={(e) => setLatest_job(e.target.value)} onBlur={updateProfile} data-key="latest_job" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="dk-cansuMMFromGrp">
                                            <label>Industries</label>
                                            <div className="selDrop14 dk-upperDropdown">
                                                {/* <SelectDropsDown txt={latest_job} list={candidateLatestJobList} candidateId={id} updateProfile={updateProfile} column="latest_job" className={(latest_job == '' || latest_job == null ? "errorBorder" : null)} /> */}
                                                {/* <input type="text" className="form-control" /> */}
                                                <SelectDropsDown txt={industry} list={candidateIndustryList} candidateId={id} updateProfile={updateProfile} column="industries" className={(industry == '' || industry == null ? "errorBorder" : null)} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="dk-cansuMMFromGrp dk-educationPriorityDropdown">
                                            <label>Job Domain</label>
                                            <div className="selDrop14 dk-upperDropdown dkg-jobdomain-dropCon">
                                                <SelectDropsDown txt={career_priority} list={candidateCareerPriorityList} candidateId={id} updateProfile={updateProfile} column="priority" className={(career_priority == '' || career_priority == null ? "errorBorder" : null)} />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="dk-cansuMMTableGrp">
                                    <label>Languages</label>
                                    <div className="dk-tableSummCon mt-1">
                                        <table className="table dk-languageTable">
                                            <thead>
                                                <tr>
                                                    <th>Native</th>
                                                    <th className="borderLwhite borderRwhite">Fluent - C1</th>
                                                    <th>Intermediate - B2</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="selDrop15">
                                                            <SelectDropsDown txt={native1} list={candidateNativeList} candidateId={id} updateProfile={updateProfile} column="native1" className={(native1 == '' ? "errorBorder" : null)} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="selDrop15">
                                                            <SelectDropsDown txt={fluent1} list={candidateNativeList} candidateId={id} updateProfile={updateProfile} column="fluent1" className={(fluent1 == '' ? "errorBorder" : null)} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="selDrop15">
                                                            <SelectDropsDown txt={intermediate1} list={candidateNativeList} candidateId={id} updateProfile={updateProfile} column="intermediate1" className={null} />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="selDrop15 downIcon-none">
                                                            <SelectDropsDown txt={native2} list={candidateNativeList} candidateId={id} updateProfile={updateProfile} column="native2" className={null} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="selDrop15 downIcon-none">
                                                            <SelectDropsDown txt={fluent2} list={candidateNativeList} candidateId={id} updateProfile={updateProfile} column="fluent2" className={null} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="selDrop15 downIcon-none">
                                                            <SelectDropsDown txt={intermediate2} list={candidateNativeList} candidateId={id} updateProfile={updateProfile} column="intermediate2" className={null} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="dk-cansuMMFromGrp dk-brNone">
                                            <label>Experience In</label>
                                            <div className="selDrop14 dkg-indust-table-dropdownCon">
                                                {/* <SelectDropsDown txt={industry} list={candidateIndustryList} candidateId={id} updateProfile={updateProfile} column="industries" className={(industry == '' || industry == null ? "errorBorder" : null)} /> */}
                                                <SelectDropsDown txt={career_priority} list={candidateCareerPriorityList} candidateId={id} updateProfile={updateProfile} column="priority" className={(career_priority == '' || career_priority == null ? "errorBorder" : null)} />
                                            </div>
                                            <div className="selDrop14 dkg-indust-table-dropdownCon">
                                                {/* <SelectDropsDown txt={industry2} list={candidateIndustryList} candidateId={id} updateProfile={updateProfile} column="industry2" className={null} /> */}
                                                <SelectDropsDown txt={career_priority2} list={candidateCareerPriorityList} candidateId={id} updateProfile={updateProfile} column="priority2" className={(career_priority == '' || career_priority == null ? "errorBorder" : null)} />
                                            </div>
                                            <div className="selDrop14 dkg-indust-table-dropdownCon">
                                                {/* <SelectDropsDown txt={industry3} list={candidateIndustryList} candidateId={id} updateProfile={updateProfile} column="industry3" className={null} /> */}
                                                <SelectDropsDown txt={career_priority3} list={candidateCareerPriorityList} candidateId={id} updateProfile={updateProfile} column="priority3" className={(career_priority == '' || career_priority == null ? "errorBorder" : null)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 col-sm-12 dk-middleRightSec">
                        <div className="dk-createhtmEditor">
                            {/* <div className="profileNotesHeader">
                                <div className="title">Profile Skills</div>
                            </div> */}
                            {/* <CKEditor
                                editor={ClassicEditor}
                                data={profile_notes}
                                onReady={editor => {
                                    // You can store the "editor" and use when it's needed.
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setProfile_notes(data)
                                }}
                                onBlur={(event, editor) => updateEditor(`${id}==${profile_notes}==profile_notes`)}
                            /> */}

                            <textarea className="form-control dk-recCandProfile-textarea" placeholder="Write profile notes here…." data-value={profile_notes} value={profile_notes} data-id={id} onChange={(e) => setProfile_notes(e.target.value)} onBlur={updateProfile} data-key="profile_notes"></textarea>
                            <div className="dk-insideBlock-flex">
                                <label>Desired Salary?</label>
                                <input type="text" className="form-control" value={desired_salary} data-value={desired_salary} data-id={id} onChange={(e) => setDesired_salary(e.target.value)} onBlur={updateProfile} data-key="desired_salary" />
                            </div>
                            <div className="dk-insideBlock-flex">
                                <label>Desired Benefits?</label>
                                <input type="text" className="form-control" value={desired_benefits} data-value={desired_benefits} data-id={id} onChange={(e) => setDesired_benefits(e.target.value)} onBlur={updateProfile} data-key="desired_benefits" />
                            </div>
                            <div className="dk-insideBlock-flex">
                                <label>Desired Location?</label>
                                <input type="text" className="form-control" value={desired_location} data-value={desired_location} data-id={id} onChange={(e) => setDesired_location(e.target.value)} onBlur={updateProfile} data-key="desired_location" />
                            </div>
                            <div className="dk-insideBlock-flex">
                                <label>Working Location?</label>
                                <input type="text" className="form-control" value={working_location} data-value={working_location} data-id={id} onChange={(e) => setWorking_location(e.target.value)} onBlur={updateProfile} data-key="working_location" />
                            </div>
                            <div className="dk-insideBlock-flex">
                                <label>Relocation Abroad?</label>
                                <input type="text" className="form-control" value={relocation_abroad} data-value={relocation_abroad} data-id={id} onChange={(e) => setRelocation_abroad(e.target.value)} onBlur={updateProfile} data-key="relocation_abroad" />
                            </div>
                            <div className="dk-insideBlock-flex">
                                <label className='dkg-over-profile-label'>Technical Skills
                                    <button className="dk-addKills-btn" onClick={showAddTechSkills}><FontAwesomeIcon icon={faPlus} /></button>
                                </label>
                                <div className="dkg-prof-skillsCon">
                                    {
                                        allTechTags.length > 0 && allTechTags.map((data, index) =>
                                            <li key={index + 1} onClick={() => removeTechSkillsTag(data.id)}>
                                                {data.value} <span><i className="fa fa-times" aria-hidden="true"></i></span>
                                            </li>
                                        )
                                    }

                                </div>
                            </div>
                            <div className="dk-insideBlock-flex">
                                <label className='dkg-over-profile-label'>Job Profile Skills
                                    <button className="dk-addKills-btn" onClick={showAddProfieSkills}><FontAwesomeIcon icon={faPlus} /></button>
                                </label>
                                <div className="dkg-prof-skillsCon">
                                    {
                                        allProfileTags.length > 0 && allProfileTags.map((data, index) =>
                                            <li key={index + 1} onClick={() => removeJobsProfileTag(data.id)}>
                                                {data.value} <span><i className="fa fa-times" aria-hidden="true"></i></span>
                                            </li>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        {/* <div className="profileSkilsCon">
                            <div className="profileSkilsHeader">
                                <div className="title">Profile Industries</div>
                                <span className="pluseIconCon" onClick={showAddProfieSkills}><i className="fas fa-plus"></i></span>
                            </div>
                            <div className="profileSkilsBoxCon">
                                <div className="skills-tagCon">
                                    <li>hello<span><i className="fa fa-times" aria-hidden="true"></i></span></li>
                                    <li>world<span><i className="fa fa-times" aria-hidden="true"></i></span></li>
                                    <li>dkjobs<span><i className="fa fa-times" aria-hidden="true"></i></span></li>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Summary;
