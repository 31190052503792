import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, Dropdown } from "react-bootstrap";
import "./viewpopup.scss";
import Candidate from '../../../../../../../../assets/images/deepak.png'
import UserList from "../../../../../../../ui/userList/UserListByRoleForCandidates";
import SubmitButton from '../../../../../../../ui/submitButton'
import { FaPlus } from 'react-icons/fa';


const CaseProgressModal = ({ onHide }) => {

    return (
        <React.Fragment> 
            <Modal show={true} onHide={onHide} className="dkg-application-view-modal">
                 <Modal.Header closeButton className="dkg-app-progress-modal-header">
                    <Link to="#" className="dkg-app-progress-plus-btn"><FaPlus /> </Link>
                    <Modal.Title>CREATE CASE PROGRESS</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-app-progress-modal-body">
                    <div className="dkg-case-progress-form-con">
                        <div className="form-group dkg-profile-frmgrp">
                            <label htmlFor="" className="dkg-profile-label">Recruiter</label>
                            <div className="dkg-app-user-dropdrownCon">
                                <Dropdown className="dkg-app-user-dropdrown">
                                    <Dropdown.Toggle
                                    variant=""
                                    id=""
                                    className="dkg-app-user-dropdrown-toggle"
                                    >
                                    <div className="tooltipMain">
                                        <img
                                        src={Candidate}
                                        className="filterImg"
                                        />
                                    </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dkg-app-user-dropdrown-menu">
                                    <div className="d-flex">
                                        <UserList
                                        columnId="rcId"                                        />
                                    </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="form-group dkg-profile-frmgrp">
                            <label htmlFor="" className="dkg-profile-label">Status</label>
                            <div className="dkg-app-select-dropdown-Con">
                                <Dropdown className="dkg-app-select-dropdown">
                                    <Dropdown.Toggle
                                        variant=""
                                        id=""
                                        className="dkg-app-select-dropdown-toggle"
                                    >
                                        Select
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                                        <Dropdown.Item href="#" className="dkg-app-select-dropdown-item">Active</Dropdown.Item>
                                        <Dropdown.Item href="#" className="dkg-app-select-dropdown-item">Inactive</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="form-group dkg-profile-frmgrp">
                            <label htmlFor="" className="dkg-profile-label">Stage</label>
                            <div className="dkg-app-select-dropdown-Con">
                                <Dropdown className="dkg-app-select-dropdown">
                                    <Dropdown.Toggle
                                        variant=""
                                        id=""
                                        className="dkg-app-select-dropdown-toggle"
                                    >
                                        Select
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                                        <Dropdown.Item href="#" className="dkg-app-select-dropdown-item">Active</Dropdown.Item>
                                        <Dropdown.Item href="#" className="dkg-app-select-dropdown-item">Inactive</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="form-group dkg-profile-frmgrp">
                            <label htmlFor="" className="dkg-profile-label">Progress</label>
                            <div className="dkg-app-select-dropdown-Con">
                                <Dropdown className="dkg-app-select-dropdown">
                                    <Dropdown.Toggle
                                        variant=""
                                        id=""
                                        className="dkg-app-select-dropdown-toggle"
                                    >
                                        Select
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                                        <Dropdown.Item href="#" className="dkg-app-select-dropdown-item">Active</Dropdown.Item>
                                        <Dropdown.Item href="#" className="dkg-app-select-dropdown-item">Inactive</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="form-group dkg-profile-frmgrp">
                            <label htmlFor="" className="dkg-profile-label">Situation</label>
                            <div className="dkg-app-select-dropdown-Con">
                                <Dropdown className="dkg-app-select-dropdown">
                                    <Dropdown.Toggle
                                        variant=""
                                        id=""
                                        className="dkg-app-select-dropdown-toggle"
                                    >
                                        Select
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dkg-app-select-dropdown-menu">
                                        <Dropdown.Item href="#" className="dkg-app-select-dropdown-item">Active</Dropdown.Item>
                                        <Dropdown.Item href="#" className="dkg-app-select-dropdown-item">Inactive</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div className="dkg-caseprogrees-textAreaCon">
                        <div className="dkg-caseprogress-header">
                            <h2 className="dkg-caseprogress-title">WRITE CLOMMENT</h2>
                        </div>
                        <div className="dkg-caseprogress-subtitleCon">
                          {/* <div className="dkg-caseprogess-subtitle">Date & Time</div> */}
                            <span className="dkg-caseprogess-dflex">
                                <div className="dkg-caseprogess-date">
                                    <i className="far fa-calendar-alt"></i>14 Jun 24
                                </div>
                                <div className="dkg-caseprogess-date">
                                    <i className="far fa-clock"></i>18:11
                                </div>
                            </span>
                        </div>
                        <div className="dkg-caseprogrees-textarea">
                            <textarea name="" id="" className='form-control dkg-cp-frmcontrol-textarea' placeholder='Write text here'></textarea>
                        </div>
                        <div className="dkg-cp-save-btncon">
                            <SubmitButton txt="SAVE" className="dkg-cp-save-btn" />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default CaseProgressModal;
