import React, { useState } from "react";
//import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Facebook from "../../../../../../../assets/images/facebook.png";
import Linkedin from "../../../../../../../assets/images/linkedin.png";
import Instagram from "../../../../../../../assets/images/instagram.png";
import Youtube from "../../../../../../../assets/images/youtube.png";
import Twitter from "../../../../../../../assets/images/twitter-new.png";

const CompanyTab = ({ companyInfo }) => {
  return (
    <>
      <div className="dkg-jopspec-pageheader">
        <div className="dkg-jopspec-page-title">Overview</div>
      </div>
      <div className="dkg-companynew-info-mainCon" style={{paddingBottom: "85px" , backgroundColor: "#fff"}}>
        <div className="dkg-companynew-info-con">
          <div className="dkg-comany-banner-mainCon">
            <div className="dkg-comany-banner-cols">
              <div className="dkg-comany-banner-box">
                <img
                  alt="Banner"
                  className="dkg-changIcon dkg-comany-banner changeImageDynamic"
                  src={
                    companyInfo.length > 0
                      ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                        "/" +
                        companyInfo[0].logoImg
                      : ""
                  }
                />
              </div>
              <div className="dkg-cp-info-bottomcon">
                <div className="dkg-cp-info-leftCon">
                  <div className="dkg-cp-info-box">
                    <img
                      alt=""
                      className="dkg-changIcon changeSecondImageDynamic dkg-cp-info-logo"
                      src={
                        companyInfo.length > 0
                          ? process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                            "/" +
                            companyInfo[0].bannerImg
                          : ""
                      }
                    />
                  </div>
                  <div className="dkg-cp-info-nameCon">
                    <div className="dkg-cp-info-name">
                      {companyInfo.length > 0 ? companyInfo[0].comapnyName : ""}
                    </div>
                  </div>
                </div>
                <div className="dkg-cp-info-rightCon">
                  <div className="dkg-socailmedia-box">
                    <a
                      className="dkg-socailmedia-tab mr-2"
                      href={companyInfo.length > 0 ? companyInfo[0].fbUrl : "#"}
                      target="_blank"
                    >
                      <img
                        src={Facebook}
                        className="dkg-socailmedia-img"
                        alt="Facebook"
                      />
                    </a>
                    <a
                      className="dkg-socailmedia-tab mr-2"
                      href={
                        companyInfo.length > 0 ? companyInfo[0].linkdinUrl : "#"
                      }
                      target="_blank"
                    >
                      <img
                        src={Linkedin}
                        className="dkg-socailmedia-img"
                        alt="Linkedin"
                      />
                    </a>
                    <a
                      className="dkg-socailmedia-tab mr-2"
                      href={
                        companyInfo.length > 0
                          ? companyInfo[0].instagramUrl
                          : "#"
                      }
                      target="_blank"
                    >
                      <img
                        src={Instagram}
                        className="dkg-socailmedia-img"
                        alt="Instagram"
                      />
                    </a>
                    <a
                      className="dkg-socailmedia-tab mr-2"
                      href={
                        companyInfo.length > 0 ? companyInfo[0].youtubeUrl : "#"
                      }
                      target="_blank"
                    >
                      <img
                        src={Youtube}
                        className="dkg-socailmedia-img"
                        alt="Youtube"
                      />
                    </a>
                    <a
                      className="dkg-socailmedia-tab"
                      href={
                        companyInfo.length > 0 ? companyInfo[0].twitterUrl : "#"
                      }
                      target="_blank"
                    >
                      <img
                        src={Twitter}
                        className="dkg-socailmedia-img"
                        alt="Twitter"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dkg-companynew-banner-tableCon">
            <div className="dkg-companynew-info-tablecon">
              <table className="table table-bordered dkg-companynew-info-table">
                <tbody>
                  <tr>
                    <td>Founder</td>
                    <td>
                      {companyInfo.length > 0 ? companyInfo[0].founder : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Industry</td>
                    <td>
                      {companyInfo.length > 0 ? companyInfo[0].industry : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Company Size</td>
                    <td>
                      {companyInfo.length > 0 ? companyInfo[0].companySize : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Headquarters</td>
                    <td>
                      {companyInfo.length > 0
                        ? companyInfo[0].headquarters
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Company Type</td>
                    <td>
                      {companyInfo.length > 0 ? companyInfo[0].companyType : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Area Served</td>
                    <td>
                      {companyInfo.length > 0 ? companyInfo[0].areaServed : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Web Address</td>
                    <td>
                      <Link
                        to={
                          companyInfo.length > 0
                            ? companyInfo[0].webAddress
                            : ""
                        }
                        style={{ color: "rgb(98, 155, 211)" }}
                      >
                        {companyInfo.length > 0
                          ? companyInfo[0].webAddress
                          : ""}
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyTab;
