import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { Link } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import styled from "styled-components";

//import AddfileModal from './modal/AddfileModal';
import EditfileModal from "./modal/EditfileModal";
//import ReorderModal from './modal/ReorderModal'
import DeleteDummy from "./modal/DeleteDummy";
import NewDocumentModal from "../../listview/DocumentNwModal";
// import { FaEdit, FaArrowsAlt, FaEye, FaDownload, FaTrashAlt } from "react-icons/fa";
import {
  FaSearch,
  // FaPlus,
  // FaEye,
  FaDownload,
  // FaFilter,
  // FaArrowsAlt,
  // FaTrashAlt,
  // FaEdit,
} from "react-icons/fa";

import {
  showSuccess,
  // showError,
  // clearMessage,
} from "../../../../utils/messages/messageSlice";
import {
  getprojectlist,
  //deleteProjectValue,
  clearState,
  detailsbyid,
} from "../../../../../slice/companies/clients/clientSlice";

const JobDetails = ({ companyid, fileList }) => {
  const TaskForDropdown = styled.div`
    .dropdown {
      display: flex;
      justify-content: center;
      align-items: center;
      .dropdown-toggle {
        background-color: #fff !important;
        border: 1px solid #ddd !important;
        color: #333;
        font-size: 12px;
        padding: 7px 5px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        width: 195px;
        min-width: 195px;
        box-shadow: none !important;
        margin: 0;
        &::after {
          display: inline-block !important;
          font-family: " Font Awesome 5 Free";
          font-style: normal;
          font-weight: 700;
          margin: 0 0 0 10px;
          font-size: 13px;
          position: absolute;
          top: 16px;
          text-decoration: none;
          border-top: 0.33em solid;
          border-right: 0.33em solid transparent;
          border-bottom: 0;
          border-left: 0.33em solid transparent;
        }
        &.btn-primary:not([disabled]):not(.disabled):active,
        &.btn-primary:not([disabled]):not(.disabled).active,
        .show > .btn-primary.dropdown-toggle {
          background-color: #fff !important;
          border: 1px solid #ddd !important;
          color: #333 !important;
        }
      }
      .dropdown-menu {
        border: none;
        border-radius: 0;
        padding: 0;
        width: 100%;
        max-height: 300px;
        overflow-y: auto;
        background-color: #eaeaea;
        transform: inherit !important;
        top: 37px !important;
        .dropdown-item {
          align-items: center;
          border: none;
          background-color: #eaeaea;
          border-bottom: 1px solid #919191;
          display: flex;
          font-size: 12px;
          position: relative;
          text-transform: capitalize;
          width: 100%;
          color: #333;
          padding: 5px 7px;
          &:hover {
            background-color: #f2f2f2;
          }
          span.dropdown-filter-count {
            align-items: center;
            background-color: #3c4c62;
            border-radius: 2px;
            color: #fff;
            display: flex;
            font-size: 11px;
            justify-content: center;
            padding: 1px 0;
            position: absolute;
            right: 4px;
            text-align: center;
            top: 6px;
            width: 20px;
          }
        }
      }
    }
    .crossUserFilter {
      right: -2px !important;
    }
  `;

  const dispatch = useDispatch();
  const {
    isLoading,
    isDelete,
    isUpdate,
    isError,
    message,
    newProjectList,
    isProjectInsert,
    //projectdetails,
  } = useSelector((state) => state.companies);

  const [isPopupDelete, setIsDelete] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [rowid, setRowid] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const submitSearch = () => {
    console.log(dataList);
    console.log(searchInput);
  };

  useEffect(() => {
    if (!isLoading && newProjectList && newProjectList.length > 0) {
      setDataList(newProjectList);
    }
  }, [isLoading]);

  const [showEdit, setShowEdit] = useState(false);

  // useEffect(() => {
  //   const fetchdata = async () => {
  //     console.log("JobDetails", companyid);
  //     await dispatch(getprojectlist({ companyId: companyid }));
  //   };
  //   fetchdata();
  // }, [companyid, isDelete, isProjectInsert, isUpdate]);

  const showDeleteModal = async (id) => {
    setRowid(id);
    setIsDelete(true);
  };
  const hideDeleteModal = () => {
    setRowid(0);
    setIsDelete(false);
  };

  useEffect(() => {
    if (isUpdate) {
      dispatch(showSuccess({ msg: message }));
      dispatch(clearState());
      setDataList(newProjectList);
    }
    if (isProjectInsert) {
      dispatch(getprojectlist({ companyId: companyid }));
      dispatch(showSuccess({ msg: message }));
      dispatch(clearState());
    }
    if (isDelete) {
      dispatch(showSuccess({ msg: message }));
      dispatch(getprojectlist({ companyId: companyid }));
      dispatch(clearState());
    }
  }, [isError, isProjectInsert, isUpdate]);

  const editFunction = (id) => {
    dispatch(detailsbyid({ companyId: id }));
    setShowEdit(true);
  };
  const hideEdit = () => {
    setShowEdit(false);
  };

  const [statusList, setStatusList] = useState([]);
  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of newProjectList) {
      if (!map.has(item.fileType)) {
        map.set(item.fileType, true);
        result.push({
          companyId: item.companyId,
          createdAt: item.createdAt,
          file: item.file,
          fileType: item.fileType,
          id: item.id,
          name: item.name,
          orderNo: item.orderNo,
          updatedAt: item.updatedAt,
          count: newProjectList.filter(function (val) {
            return val.fileType === item.fileType;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setStatusList(result);
  }, [newProjectList]);

  const [statusFilterText, setStatusFilterText] = useState("File Type");

  const selectStatusFilter = async (e) => {
    //const type = e.target.dataset.type;
    const value = e.target.dataset.value;

    setDataList(newProjectList.filter((data) => data.fileType === value));
    setStatusFilterText(value);
  };
  const removeTag = async (e) => {
    setDataList(newProjectList);
    setStatusFilterText("File Type");
  };

  return (
    <>
      {isPopupDelete ? (
        <DeleteDummy
          onHide={hideDeleteModal}
          id={rowid}
          companyId={companyid}
        />
      ) : null}
      {showEdit ? <EditfileModal onHide={hideEdit} /> : null}
      <div className="dkg-edit-jobfiles-mainCon">
        <div className="dkg-jobfiles-header">
          <div className="dkg-leftStaffnHead">
            <div className="dk-searchCols">
              <input
                type="text"
                name=""
                id=""
                placeholder="Search..."
                className="form-control"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <button onClick={submitSearch}>
                <FaSearch />
              </button>
            </div>
          </div>
          <div className="title">CLIENTS FILES</div>
          <div className="d-flex justify-content-end dkg-add-new-btnCon dkg-file-type-filterCon">
            {/* <ReorderModal dataList={newProjectList} clientid={companyid} />
                        <AddfileModal companyid={companyid} /> */}
            {/* {!isLoading && dataList.length > 1 ? (
              <React.Fragment>
                <Dropdown className="dkg-file-typedropdown">
                  <Dropdown.Toggle
                    variant=""
                    className="dkg-typedropdown-toggle"
                  >
                    File Type
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dkg-typedropdown-Menu">
                    <Dropdown.Item href="#" className="dkg-typedropdown-item">
                      Profile
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </React.Fragment>
            ) : null} */}
            <TaskForDropdown>
              <DropdownButton
                id="dropdown-basic-button"
                title={statusFilterText}
              >
                {statusList &&
                  statusList.map((item, index) => (
                    <Dropdown.Item
                      key={`case-status-id${index}`}
                      href="#"
                      data-type="fileType"
                      data-value={item.fileType}
                      onClick={selectStatusFilter}
                    >
                      {item.fileType}
                      <span className="dropdown-filter-count">
                        {item.count}
                      </span>
                    </Dropdown.Item>
                  ))}
              </DropdownButton>
            </TaskForDropdown>
            {statusFilterText !== "File Type" ? (
              <i
                className="fas fa-times-circle crossUserFilter dk-lrftCross"
                data-type="fileType"
                onClick={removeTag}
                style={{ top: "8px", right: "28px" }}
              ></i>
            ) : null}
          </div>
        </div>
        <div className="dkg-edit-jobfiles-tableCon">
          <table className="dkg-edit-jobfiles-table table table-bordered">
            <thead>
              <tr>
                <th>SNo.</th>
                <th>File Name</th>
                <th>File Type</th>
                <th>View</th>
                <th>Download</th>
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {dataList.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.fileType}</td>
                  <td>
                    <NewDocumentModal name={item.file} canId={item.id} />
                  </td>
                  <td>
                    <div className="dkg-file-actions">
                      <a
                        href={
                          process.env.REACT_APP_INTERNAL_BASE_URL +
                          "/" +
                          item.file
                        }
                        download
                        target="_blank"
                        className="dkg-download-icon mr-2"
                      >
                        <FaDownload />
                      </a>
                    </div>
                  </td>
                  {/* <td>
                                            <div className="dkg-file-actions">

                                              <Link to="#" onClick={(e)=>{editFunction(item.id)}} className="dkg-editfile-icon mr-2"><FaEdit /></Link>

                                                <Link to="#" className="dkg-deletefile-icon" onClick={(e) => showDeleteModal(item.id)}>
                                                    <FaTrashAlt />
                                                </Link>
                                            </div>
                                        </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default JobDetails;
