import React from 'react';
import MarianaTwoProjectFourLeftPanel from "../leftpanel/marianatwo/MarianaTwoProjectFourLeftPanel";

const MarianaTwoProjectFour = () => {
    return (
        <>
            <div className="dk-moduleProjects-main">
                <div className="dk-moduleTraining-cols">
                    <MarianaTwoProjectFourLeftPanel />
                    <div className="dk-moduleTraining-rightPanel">
                        <div className="title">Summary</div>
                        <div className="dk-moduleTraining-rightCols">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MarianaTwoProjectFour;
