import React from 'react';
import Recuiter from '../../../../../assets/images/deepak.png';
import Dropdown from 'react-bootstrap/Dropdown'
import "./flagfilter.scss";


const FlagFilter = () => {
    return (
        <>
            <div className="dk-pipeFlagFilterCon">
                <Dropdown>
                    <Dropdown.Toggle variant="" className="dk-pipeflagfliterbtn">
                        <i className="fa fa-flag" aria-hidden="true"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dk-pipleFlagFilterMenu">
                        <div className="d-flex">
                            <Dropdown.Item href="#"><i className="fa fa-flag" aria-hidden="true" style={{ color: "#d0cece" }}></i></Dropdown.Item>
                            <Dropdown.Item href="#"><i className="fa fa-flag" aria-hidden="true" style={{ color: "green" }}></i></Dropdown.Item>
                            <Dropdown.Item href="#"><i className="fa fa-flag" aria-hidden="true" style={{ color: "red" }}></i></Dropdown.Item>
                            <Dropdown.Item href="#"><i className="fa fa-flag" aria-hidden="true" style={{ color: "#eaa03d" }}></i></Dropdown.Item>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default FlagFilter;
