import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DkGlobalStructureModal from './DkGlobalStructureModal';
import "./dkglobalstructure.scss";

const DkGlobalStructure = () => {
    const [structureModal, setStructureModal] = useState(false)
    const ShowStructureModal = () => {
        setStructureModal(true)
    }
    const HideStructureModal = () => {
        setStructureModal(false)
    }

    return (
        <React.Fragment>
            {
                structureModal ?
                    <DkGlobalStructureModal handleClose={HideStructureModal} /> : null
            }

            <div className="dk-structureMain dk-dkGlobalStructureMain">
                {/* <div className="dk-structure-head">
                    <h3 className="title">DK GLOBAL TEAM STRUCTURE</h3>
                </div> */}

                <div className="dk-structureTable">
                    <div className="dk-table-responsive">
                        <table className="table dk-structureTable table-bordered">
                            <thead>
                                <tr>
                                    <th>Position</th>
                                    <th>Levels</th>
                                    <th>Reporting To</th>
                                    <th>Department</th>
                                    <th>Summary</th>
                                    <th>Job Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Director/CEO</td>
                                    <td>Level 1</td>
                                    <td>Founder</td>
                                    <td>Top Management</td>
                                    <td>Manage Whole Company</td>
                                    <td>
                                        <button onClick={ShowStructureModal}>View Job Summary</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Ops Manager</td>
                                    <td>Level 2</td>
                                    <td>Director</td>
                                    <td>Management</td>
                                    <td>Manage Whole Operation</td>
                                    <td>
                                        <button onClick={ShowStructureModal}>View Job Summary</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Team Manager</td>
                                    <td>Level 3</td>
                                    <td>Ops Manager</td>
                                    <td>Management</td>
                                    <td>Manage All the Teams</td>
                                    <td>
                                        <button onClick={ShowStructureModal}>View Job Summary</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Team Leader</td>
                                    <td>Level 4</td>
                                    <td>Team Manager</td>
                                    <td>Production</td>
                                    <td>Manage Your Team</td>
                                    <td>
                                        <button onClick={ShowStructureModal}>View Job Summary</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sr Executive</td>
                                    <td>Level 5</td>
                                    <td>Team Leader</td>
                                    <td>Production</td>
                                    <td>Sales or Admin Tasks</td>
                                    <td>
                                        <button onClick={ShowStructureModal}>View Job Summary</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Jr Executive</td>
                                    <td>Level 6</td>
                                    <td>Team Leader</td>
                                    <td>Production</td>
                                    <td>Sales or Admin Tasks</td>
                                    <td>
                                        <button onClick={ShowStructureModal}>View Job Summary</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Trainee/Intern</td>
                                    <td>Level 7</td>
                                    <td>Team Leader</td>
                                    <td>Production</td>
                                    <td>Support the Executives</td>
                                    <td>
                                        <button onClick={ShowStructureModal}>View Job Summary</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default DkGlobalStructure;

