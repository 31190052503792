import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import './workplantasktitle.scss'
import AttachmentDropdown from './AttachDropdown'
import { FaEdit } from "react-icons/fa";
import { useSelector } from "react-redux";
import EditSection from '../edittask/EditTaskModal';
import FormStatusDiv from '../../utils/statusDiv';
import FormProgressDiv from '../../utils/progressDiv';
import TaskTypeDropdown from "./taskdropdown/TaskTypeDropdown";


const TaskTitle = (props) => {
    const { userInfo } = useSelector(state => state.auth)

    const [rowId, setRowId] = useState(0)
    const [isEditModal, setIsEditModal] = useState(false)
    const [rowData, setRowData] = useState({})

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    const editHandler = (data) => {
        props.onHide()
        setRowId(data.id)
        setRowData(data)
        setIsEditModal(true)
    }
    return (
        <>
            <EditSection
                show={isEditModal}
                onHide={() => setIsEditModal(false)}
                id={rowId}
                data={rowData}
            />
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="dk-workplantasktitleModal"
            >
                <Modal.Header closeButton className='dk-tasktitleModalHeader'>
                    {
                        (!isEmpty(props.data) && props.data.taskByDetail.id === userInfo.id) ?
                            <span className='dkg-tasktitle-editicon-Con' onClick={() => editHandler(props.data)}>
                                <FaEdit />
                            </span>
                            : null
                    }
                    <Modal.Title>{props.data.type}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='dk-tasktitleModalBody'>
                    <div className='row'>
                        {/* <div className='col-md-4'>
                            <div className='from-group'>
                                <label className='dk-cre-newtask-label-center'>Task Category</label>
                                <input type="text" className="form-control dk-tasktype-input" style={{ backgroundColor: "#e9ecef", color: '#333' }} placeholder={props.data.type} readOnly />
                            </div>
                        </div> */}
                        <div className='col-md-6'>
                            <div className='from-group'>
                                <label htmlFor="" className='w-100 text-center' style={{ color: "#737373", fontSize: "13px" }}>Status</label>
                                <FormProgressDiv value={props.data.progress} />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='from-group'>
                                <label htmlFor="" className='w-100 text-center' style={{ color: "#737373", fontSize: "13px" }}>Progress</label>
                                <FormStatusDiv value={props.data.status} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group dk-create-date-timeFrmGrp">
                                <div className="dk-taskSwitch ddd" >
                                    <label htmlFor="" className='w-100 text-center' style={{ color: "#737373", fontSize: "13px" }}>Category</label>
                                    <input type="text" className="form-control dk-tasktype-input" style={{ backgroundColor: "#e9ecef", color: '#333' }} placeholder={props.data.type} readOnly />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='from-group'>
                                <label htmlFor="" className='w-100 text-center' style={{ color: "#737373", fontSize: "13px" }}>Type </label>
                                <input type="text" className="form-control dk-tasktype-input" placeholder={props.data.taskType} readOnly />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='d-flex'>
                                <div className='from-group dkg-tsk-cat-frmgrp-12 w-100 pr-1'>
                                    <label className='w-100 text-center' style={{ color: "#737373", fontSize: "13px" }}>Task By</label>
                                    <div className='dkg-taskbyfor-u12 d-flex' >
                                        <div className="dk-taskByCon dkg-task-123 w-100">
                                            <div className="dk-selectator-textlength">
                                                <div className="dk-selected-items">
                                                    <div className="userImgCont">
                                                        {
                                                            !isEmpty(props.data) ?
                                                                <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + props.data.taskByDetail.profileImage} alt={props.data.taskByDetail.firstName + " " + props.data.taskByDetail.lastName} className="userImg" />
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='from-group dkg-tsk-cat-frmgrp-12 w-100 pl-1'>
                                    <label className='w-100 text-center' style={{ color: "#737373", fontSize: "13px" }}>Task  For</label>
                                    <div className='dkg-taskbyfor-u12 d-flex' >
                                        <div className="dk-taskByCon dkg-taskforLeft124 w-100">
                                            <div className="dk-selectator-textlength">
                                                {
                                                    props.data.taskForDetail && props.data.taskForDetail.map((item, index) =>
                                                        <div className="dk-selected-items mr-1" key={index}>
                                                            <div className="userImgCont">
                                                                <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.forDetail.profileImage} alt={item.forDetail.firstName + " " + item.forDetail.lastName} className="userImg" />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group dk-create-date-timeFrmGrp dkg-dropdownicon-Frmgrp">
                                <label className='w-100 text-center' style={{ color: "#737373", fontSize: "13px" }}>Attachments
                                </label>
                                <AttachmentDropdown />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group dk-create-date-timeFrmGrp w-100">
                                <label htmlFor="" className='w-100 text-center' style={{ color: "#737373", fontSize: "13px" }}>Task Due Date </label>
                                <div className='dkg-tiitle-cal12 d-flex'>
                                    <input type="text" className="form-control dk-tasktype-input" placeholder={props.data.taskDueDate} readOnly />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group dk-create-date-timeFrmGrp w-100">
                                <label htmlFor="" className='w-100 text-center' style={{ color: "#737373", fontSize: "13px" }}>Task Due Time</label>
                                <div className='dkg-tiitle-cal12 d-flex'>
                                    <input type="text" className="form-control dk-tasktype-input" placeholder={props.data.dueTime} readOnly />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group dk-create-date-timeFrmGrp">
                                <label for="">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Subject</span>
                                        </div>
                                        <input type="text" className="form-control dk-subjectFrmControl" value={props.data.subject} aria-label="Username" aria-describedby="basic-addon1" readOnly={true} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        {/* <div className="col-md-12 mt-2">
                            <div className="form-group dk-create-date-timeFrmGrp">
                                <textarea className="form-control" id="txtCreateTaskDetails" value={props.data.details} cols="20" readOnly={true}></textarea>
                            </div>
                        </div> */}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TaskTitle;