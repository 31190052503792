import React, { useEffect, useState } from "react";
import { SortableContainer, SortableElement, sortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from 'array-move';
import './reorder.scss';
import { Modal } from 'react-bootstrap';
import SubmitButton from "../../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { reorderQualify, clearState } from "../../../../../slice/qualify/qualifySlice";
import { showSuccess, showError } from "../../../../../slice/utils/message/messageSlice";

const DragHandle = sortableHandle(() => <span className="dk-handle" style={{ backgroundColor: "#363f4c", height: "35px !important", width: "50px", textAlign: "center", lineHeight: "35px", color: "white", cursor: "all-scroll" }}><i className="fas fa-bars"></i></span>);

const SortableItem = SortableElement(({ value }) => {
    return (
        <React.Fragment>
            <div className="Mukul">
                <div className="dk-valueFields d-flex" key={value._id} id={value._id}>
                    <DragHandle />
                    <input type="text" style={{ background: value.bgColor, color: value.textColor, height: "35px", borderRadius: "0", border: "1px solid #ddd", boxShadow: "none !important" }} value={value.question} name="" id="" className="form-control" />
                </div>
            </div>
        </React.Fragment>
    )
})

const SortableList = SortableContainer(({ items }) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </ul>
    );
});

const ReorderQualify = ({ dataList, onHide }) => {
    const dispatch = useDispatch();

    const { isReoder, isError, msg } = useSelector(state => state.qualify)

    const [list, setList] = useState([])

    useEffect(() => {
        setList(dataList)
    }, [dataList])

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setList(arrayMoveImmutable(list, oldIndex, newIndex))
    };

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const reorderQualifyValues = async () => {
        setLoading(true)
        setSuccess(false)
        let reorderListArray = []
        list.map((data) => {
            reorderListArray.push(data._id)
        })
        await dispatch(reorderQualify({ ids: reorderListArray }))
    }

    useEffect(() => {
        if (isReoder) {
            dispatch(showSuccess({ msg }))
            dispatch(clearState())
            setLoading(false)
            setSuccess(false)
            onHide();
        }
        if (isError) {
            dispatch(showError({ msg }))
            dispatch(clearState())
            setLoading(false)
            setSuccess(false)
            onHide();
        }
    }, [isReoder, isError, dispatch, msg, onHide])

    return (
        <>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-qualifyPopup"
                aria-labelledby="example-custom-modal-styling-title"
                centered  >
                <Modal.Header closeButton className="dk-qualifyAddnewheader">
                    <Modal.Title className="dkqualifyaddtitle">Reorder</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-qualifyAddNewBody">
                    <React.Fragment>
                        <SortableList lockAxis="y" items={list} onSortEnd={onSortEnd} useDragHandle />
                        <div className="d-flex justify-content-center">
                            <SubmitButton txt="Save Order" loading={loading} success={success} onClick={reorderQualifyValues} position="justify-content-center" className="dk-addCliBtn  mt-4 mb-3" />
                        </div>
                    </React.Fragment>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReorderQualify;
