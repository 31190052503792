import React, { useState, useEffect, useRef } from "react";
import { Route, Link } from "react-router-dom";
// import Submenu from "../subMenu";

const AccContractsMenu = (props) => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK Global CRM";
    }, [pathname]);
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalfinanceExpenseMainMenu">

                <Route>
                    <ul>
                        <li><Link to="/dkglobalfinance/acc-contracts/summary" className={(segment3 == 'summary') ? 'active' : ''}>Summary</Link></li>
                        <li><Link to="/dkglobalfinance/acc-contracts/dkgstaff/2022" className={`${(segment3 == 'dkgstaff') ? 'active' : ''}`}>Clients</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'structure') ? '' : ''}`}>Suppliers</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'teams') ? '' : ''}`}>Job Boards</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'teams') ? '' : ''}`}>Employees</Link></li>
                        <li><Link to="#" className={`${(segment2 == 'pipelines') ? '' : ''}`}>Others</Link></li>
                    </ul>
                </Route>
            </div>
            {/* <Submenu path={pathname} /> */}
        </React.Fragment>
    );
}

export default AccContractsMenu;