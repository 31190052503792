import React from 'react';
import AccExpenseSubmenu from "../../../elements/header/navbar/subMenu/menu/accountingmodule/AccExpenseSubmenu";

const Expense = () => {
    return (
        <>
            <AccExpenseSubmenu />
            <div className='container-fluid' style={{ marginTop: "125px" }}>

            </div>
        </>
    )
}

export default Expense;
