import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const TimePicker = ({ pickerDueTime, handleTimeChange }) => {

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container className='w-100' >
                    <KeyboardTimePicker
                        placeholder="08:00 AM"
                        mask="__:__ _M"
                        value={pickerDueTime}
                        onChange={date => handleTimeChange(date)}                       
                    />
                </Grid>
            </MuiPickersUtilsProvider>

        </>
    )
}

export default TimePicker;