import React from 'react';
import UserImg from "../../../../../assets/images/deepak.png";
import DefulatImg from "../../../../../assets/images/default_profile.png";
import '../summary/mainnotification.scss';
import { FaRegClock, FaRegCalendarAlt, FaTasks, FaRegListAlt, FaEyeSlash } from 'react-icons/fa';
import { ImOffice } from "react-icons/im";
import OneDayLeftPanel from '../leftpanel/onedayleftpanel/OneDayLeftPanel';
import NotificationSeen from "../notificationseen/NotificationSeen";
import { Nav, Tab } from 'react-bootstrap';


const OneDayAgoMyTasks = () => {
    return (
        <>
            <div className="dk-moduleNotification-main">
                <div className="dk-moduleNoti-Cols">
                    <div className="row">
                        <div className="col-md-12">
                            <div className='dkg-compy-noti-boxcon-234'>
                                <div className='dkg-compy-noti-left-boxcon'>
                                    <div className='dkg-compy-noti-box-cols'>
                                        <div className="dk-moduleNoti-box active">
                                            <div className="dk-notificationImg">
                                                <img src={UserImg} alt="" />
                                            </div>
                                            <div className="dk-notificationText">
                                                <div className="dk-notification-w100">
                                                    <p>
                                                        <span>Lorem Ipsum is simply dummy text of the printing</span>
                                                    </p>
                                                </div>
                                                <div className="d-flex dkg-compy-noti-boxs-Header">
                                                    <div className="d-timeflex dkg-notification-twoblock">
                                                        <small>Category</small>
                                                        <small>Category 1</small>
                                                    </div>
                                                    <div className="d-timeflex dkg-notification-twoblock">
                                                        <small>Type</small>
                                                        <small>Type 1</small>
                                                    </div>
                                                    <div className="d-timeflex">
                                                        <small><FaRegCalendarAlt /></small>
                                                        <small>20 Jan 2022</small>
                                                    </div>
                                                    <div className="d-timeflex">
                                                        <small><FaRegClock /></small>
                                                        <small>10:00 AM</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dk-moduleNoti-box ">
                                            <div className="dk-notificationImg">
                                                <img src={UserImg} alt="" />
                                            </div>
                                            <div className="dk-notificationText">
                                                <div className="dk-notification-w100">
                                                    <p>
                                                        <span>Lorem Ipsum is simply dummy text of the printing</span>
                                                    </p>
                                                </div>
                                                <div className="d-flex dkg-compy-noti-boxs-Header">
                                                    <div className="d-timeflex dkg-notification-twoblock">
                                                        <small>Category</small>
                                                        <small>Category 2</small>
                                                    </div>
                                                    <div className="d-timeflex dkg-notification-twoblock">
                                                        <small>Type</small>
                                                        <small>Type 2</small>
                                                    </div>
                                                    <div className="d-timeflex">
                                                        <small><FaRegCalendarAlt /></small>
                                                        <small>20 Jan 2022</small>
                                                    </div>
                                                    <div className="d-timeflex">
                                                        <small><FaRegClock /></small>
                                                        <small>10:00 AM</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dk-moduleNoti-box">
                                            <div className="dk-notificationImg">
                                                <img src={UserImg} alt="" />
                                            </div>
                                            <div className="dk-notificationText">
                                                <div className="dk-notification-w100">
                                                    <p>
                                                        <span>Lorem Ipsum is simply dummy text of the printing</span>
                                                    </p>
                                                </div>
                                                <div className="d-flex dkg-compy-noti-boxs-Header">
                                                    <div className="d-timeflex dkg-notification-twoblock">
                                                        <small>Category</small>
                                                        <small>Category 3</small>
                                                    </div>
                                                    <div className="d-timeflex dkg-notification-twoblock">
                                                        <small>Type</small>
                                                        <small>Type 3</small>
                                                    </div>
                                                    <div className="d-timeflex">
                                                        <small><FaRegCalendarAlt /></small>
                                                        <small>20 Jan 2022</small>
                                                    </div>
                                                    <div className="d-timeflex">
                                                        <small><FaRegClock /></small>
                                                        <small>10:00 AM</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dk-moduleNoti-box">
                                            <div className="dk-notificationImg">
                                                <img src={UserImg} alt="" />
                                            </div>
                                            <div className="dk-notificationText">
                                                <div className="dk-notification-w100">
                                                    <p>
                                                        <span>Lorem Ipsum is simply dummy text of the printing</span>
                                                    </p>
                                                </div>
                                                <div className="d-flex dkg-compy-noti-boxs-Header">
                                                    <div className="d-timeflex dkg-notification-twoblock">
                                                        <small>Category</small>
                                                        <small>Category 4</small>
                                                    </div>
                                                    <div className="d-timeflex dkg-notification-twoblock">
                                                        <small>Type</small>
                                                        <small>Type 4</small>
                                                    </div>
                                                    <div className="d-timeflex">
                                                        <small><FaRegCalendarAlt /></small>
                                                        <small>20 Jan 2022</small>
                                                    </div>
                                                    <div className="d-timeflex">
                                                        <small><FaRegClock /></small>
                                                        <small>10:00 AM</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dk-moduleNoti-box">
                                            <div className="dk-notificationImg">
                                                <img src={UserImg} alt="" />
                                            </div>
                                            <div className="dk-notificationText">
                                                <div className="dk-notification-w100">
                                                    <p>
                                                        <span>Lorem Ipsum is simply dummy text of the printing</span>
                                                    </p>
                                                </div>
                                                <div className="d-flex dkg-compy-noti-boxs-Header">
                                                    <div className="d-timeflex dkg-notification-twoblock">
                                                        <small>Category</small>
                                                        <small>Category 5</small>
                                                    </div>
                                                    <div className="d-timeflex dkg-notification-twoblock">
                                                        <small>Type</small>
                                                        <small>Type 5</small>
                                                    </div>
                                                    <div className="d-timeflex">
                                                        <small><FaRegCalendarAlt /></small>
                                                        <small>20 Jan 2022</small>
                                                    </div>
                                                    <div className="d-timeflex">
                                                        <small><FaRegClock /></small>
                                                        <small>10:00 AM</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='dkg-compy-noti-right-boxcon'>
                                    <div className='dkg-compy-noti-boxs-cols'>
                                        {/* <div className="d-flex dkg-compy-noti-boxs-Header">
                                            <div className="d-timeflex justify-content-center align-items-center">
                                                <div className="sbtitle"><ImOffice /> Company</div>
                                            </div>
                                            <div className="d-timeflex justify-content-center align-items-center">
                                                <div className="sbtitle"><FaRegListAlt /> Category</div>
                                            </div>
                                            <div className="d-timeflex justify-content-center align-items-center">
                                                <div className="sbtitle"><FaTasks /> Task</div>
                                            </div>
                                            <div className="d-timeflex">
                                                <small><FaRegCalendarAlt /> Date</small>
                                                <small>20 Jan 2022</small>
                                            </div>
                                            <div className="d-timeflex">
                                                <small><FaRegClock /> Time</small>
                                                <small>10:00 AM</small>
                                            </div>
                                        </div> */}
                                        <div className='dkg-compy-noti-boxs-Body'>
                                            <div className="dkg-cumm-log-maintabCon">
                                                <Tab.Container id="left-tabs-example" defaultActiveKey="dkg-phone">
                                                    <div className='row dkg-cumm-log-mainRow'>
                                                        <div className="col-12 col-md-12  dkg-cumm-log-navmainCol">
                                                            <Nav variant="pills" className="flex-row  dkg-cumm-log-navpills">
                                                                <Nav.Item className='dkg-cumm-log-navitem'>
                                                                    <Nav.Link eventKey="dkg-phone" className='dkg-cumm-log-navlink'>Message</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item className='dkg-cumm-log-navitem'>
                                                                    <Nav.Link eventKey="dkg-email" className='dkg-cumm-log-navlink'>Comment</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item className='dkg-cumm-log-navitem'>
                                                                    <Nav.Link eventKey="dkg-sms" className='dkg-cumm-log-navlink'>Information</Nav.Link>
                                                                </Nav.Item>

                                                            </Nav>
                                                        </div>
                                                        <div className='col-12 col-md-12 dkg-cumm-log-tabcontantentCol'>
                                                            <Tab.Content className='dkg-cumm-log-tabcontantent'>
                                                                <Tab.Pane eventKey="dkg-phone" className='dkg-cumm-log-tabcontabpane'>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="dkg-email" className='dkg-cumm-log-tabcontabpane'>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="dkg-sms" className='dkg-cumm-log-tabcontabpane'>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="dkg-whatsapp" className='dkg-cumm-log-tabcontabpane'>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="dkg-skype" className='dkg-cumm-log-tabcontabpane'>
                                                                </Tab.Pane>
                                                            </Tab.Content>
                                                        </div>
                                                    </div>
                                                </Tab.Container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default OneDayAgoMyTasks;
