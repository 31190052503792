import React from 'react';
import './jobprofile.scss';
import JobProfileLeftPanel from "./leftpanel/JobProfileLeftPanel";

const index = () => {
    return (
        <>
            <div className="dk-jobProfilesDetailMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <JobProfileLeftPanel />
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                <h3 className="title">Overview</h3>
                                <div className="dk-jobProfiles-cols">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid similique dolore laboriosam reprehenderit sapiente at iusto? Soluta tempora eveniet suscipit quae aliquid provident rem exercitationem ipsa eos aspernatur? Necessitatibus, optio?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default index;
