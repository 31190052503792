import React from 'react';
import { Link } from 'react-router-dom';
import './textsearch.scss';
import DummySelectPiker from '../../../../../../ui/SelectPicker/DummySelectPiker'

const TextSearch = () => {
    return (
        <>
            <div className="dk-serchTextCon">
                <div className="row topRow">
                    <div className="col-md-4">
                        <div className="dk-boxCols">
                            <label>Search Text</label>
                            <input type="text" className="form-control broderContrl" name="jobTitle" value="" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="dk-boxCols">
                            <label>Choose Color</label>
                            <div className="dk-chooseColorDrpdwn">
                                <DummySelectPiker placeholder="Yellow" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 pr-0">
                        <div className="dk-boxCols">
                            <label>Result</label>
                            <input type="text" className="form-control broderContrl" name="result" value="" />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="dk-boxCols">
                            <div className="dk-btnCon">
                                <Link to="#" className="mr-2"><i className="fa fa-angle-left mr-2" aria-hidden="true"></i>Back</Link>
                                <Link to="#">Next<i className="fa fa-angle-right ml-2" aria-hidden="true"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row dk-textAreaRow">
                    <div className="col-md-12 dk-textAreaCon">
                        <textarea className="form-control form-control1" style={{ height: "200px" }}></textarea>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TextSearch;
