import React from 'react';
import { Link, Route } from "react-router-dom";


const CompanyLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkg-global/dkglobal/overview" className={`${pathname.match('/dkg-global/dkglobal/overview') ? 'active' : ''}`}>Overview</Link></li>
                    <li><Link to="/dkg-global/dkglobal/established" className={`${pathname.match('/dkg-global/dkglobal/established') ? 'active' : ''}`}>Established</Link></li>
                    <li><Link to="/dkg-global/dkglobal/business" className={`${pathname.match('/dkg-global/dkglobal/business') ? 'active' : ''}`}>Business</Link></li>
                    <li><Link to="/dkg-global/dkglobal/industry" className={`${pathname.match('/dkg-global/dkglobal/industry') ? 'active' : ''}`}>Industry</Link></li>
                    <li><Link to="/dkg-global/dkglobal/services" className={`${pathname.match('/dkg-global/dkglobal/services') ? 'active' : ''}`}>Services</Link></li>
                    <li><Link to="/dkg-global/dkglobal/clients" className={`${pathname.match('/dkg-global/dkglobal/clients') ? 'active' : ''}`}>Clients</Link></li>
                    <li><Link to="/dkg-global/dkglobal/suppliers" className={`${pathname.match('/dkg-global/dkglobal/suppliers') ? 'active' : ''}`}>Suppliers</Link></li>
                    <li><Link to="/dkg-global/dkglobal/completion" className={`${pathname.match('/dkg-global/dkglobal/completion') ? 'active' : ''}`}>Completion</Link></li>
                    <li><Link to="/dkg-global/dkglobal/employees" className={`${pathname.match('/dkg-global/dkglobal/employees') ? 'active' : ''}`}>Employees</Link></li>
                    <li><Link to="/dkg-global/dkglobal/future-plans" className={`${pathname.match('/dkg-global/dkglobal/future-plans') ? 'active' : ''}`}>Future Plans</Link></li>
                    <li><Link to="/dkg-global/dkglobal/social-media" className={`${pathname.match('/dkg-global/dkglobal/social-media') ? 'active' : ''}`}>Social Media</Link></li>
                    <li><Link to="/dkg-global/dkglobal/contact-details" className={`${pathname.match('/dkg-global/dkglobal/contact-details') ? 'active' : ''}`}>Contact Details</Link></li>
                </Route>
            </ul>
        </React.Fragment>
    )
}

export default CompanyLeftPanel;

