import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const DatePicker = (props) => {
    // The first commit of Material-UI
    const [pickDate, setPickDate] = React.useState(props.selectedDate);

    const handleDateChange = (date) => {
        
        setPickDate(date);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container alignItems="center" justify="center" className='dk-nativedatepicker-grid'>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="d MMM yy"
                    margin="normal"
                    id="date-picker-inline dk-startDatenativepicker"
                    className="dk-nativedatepicker"
                    value={pickDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    onClose={() => props.selectedDate(`${pickDate}`)}
                    autoOk={true}
                    error={false}
                    helperText={null}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
}
export default DatePicker;