import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./livejobsrightfilter.scss";
import "./refreral.scss";
import { Modal, Nav, Tab } from "react-bootstrap";
import { MdInsertDriveFile } from "react-icons/md";
//import CustomDateFormat from "../../../ui/dateFormats/CustomDateFormat";
import moment from "moment-timezone";

const RightModalFilter = ({
  list,
  selectUserFilter,
  selectOtherFilter,
  show,
  onHide,
}) => {
  //const [statusList, setStatusList] = useState([]);
  const [rmList, setRmList] = useState([]);
  const [raList, setRaList] = useState([]);
  const [rcList, setRcList] = useState([]);
  const [amList, setAmList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [workfromList, setWorkfromList] = useState([]);
  const [workpermitList, setWorkperminList] = useState([]);
  const [demandsList, setDemandsList] = useState([]);
  const [jobConfirmedList, setJobConfirmedList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [hireList, setHireList] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [dateList2, setDateList2] = useState([]);
  const [countryList, setCountryList] = useState([]);

  // const [jobProfileList, setJobProfileList] = useState([]);
  // const [updated_onList, setUpdated_onList] = useState([]);
  const [createdAtList, setCreatedAtList] = useState([]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.job_country)) {
        map.set(item.original.job_country, true);
        result.push({
          name:
            item.original.job_country == ""
              ? "Blank"
              : item.original.job_country,
          count: list.filter(function (val) {
            return val.original.job_country === item.original.job_country;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setCountryList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.recAM.uid)) {
        map.set(item.original.recAM.uid, true);
        result.push({
          id: item.original.recAM.uid,
          name: item.original.recAM.firstName,
          userImg: item.original.recAM.profileImage,
          count: list.filter(function (val) {
            return val.original.recAM.uid === item.original.recAM.uid;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setAmList(result);
  }, [list]);

  useEffect(() => {
    let types1 = [];
    for (const fulllist of list) {
      for (const userlist of fulllist.original.assignWithjob) {
        types1.push({
          id: userlist.userInfo.uid,
          name: userlist.userInfo.firstName,
          userImg: userlist.userInfo.profileImage,
        });
      }
    }
    const result = [];
    const map = new Map();
    for (const item of types1) {
      if (!map.has(item.id)) {
        map.set(item.id, true);
        result.push({
          id: item.id,
          name: item.name,
          userImg: item.userImg,
          count: types1.filter(function (val) {
            return val.id === item.id;
          }).length,
        });
      }
    }

    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setRcList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.recRA.uid)) {
        map.set(item.original.recRA.uid, true);
        result.push({
          id: item.original.recRA.uid,
          name: item.original.recRA.firstName,
          userImg: item.original.recRA.profileImage,
          count: list.filter(function (val) {
            return val.original.recRA.uid === item.original.recRA.uid;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setRaList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.recRM.uid)) {
        map.set(item.original.recRM.uid, true);
        result.push({
          id: item.original.recRM.uid,
          name: item.original.recRM.firstName,
          userImg: item.original.recRM.profileImage,
          count: list.filter(function (val) {
            return val.original.recRM.uid === item.original.recRM.uid;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setRmList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.category)) {
        map.set(item.original.category, true);
        result.push({
          name: item.original.category,
          count: list.filter(function (val) {
            return val.original.category === item.original.category;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setCategoryList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.company)) {
        map.set(item.original.company, true);
        result.push({
          name: item.original.company,
          count: list.filter(function (val) {
            return val.original.company === item.original.company;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setCompanyList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.project)) {
        map.set(item.original.project, true);
        result.push({
          name: item.original.project,
          count: list.filter(function (val) {
            return val.original.project === item.original.project;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setProjectList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.role)) {
        map.set(item.original.role, true);
        result.push({
          name: item.original.role,
          count: list.filter(function (val) {
            return val.original.role === item.original.role;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setRoleList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.lang)) {
        map.set(item.original.lang, true);
        result.push({
          name: item.original.lang,
          count: list.filter(function (val) {
            return val.original.lang === item.original.lang;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setLanguageList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.location)) {
        map.set(item.original.location, true);
        result.push({
          name: item.original.location == "" ? "Blank" : item.original.location,
          count: list.filter(function (val) {
            return val.original.location === item.original.location;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setCityList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.fee)) {
        map.set(item.original.fee, true);
        result.push({
          name:
            item.original.fee == "" || item.original.fee == "0"
              ? "Blank"
              : item.original.fee,
          count: list.filter(function (val) {
            return val.original.fee === item.original.fee;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setHireList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.work_permit)) {
        map.set(item.original.work_permit, true);
        result.push({
          name: item.original.work_permit == "" ? "Blank" : item.original.work_permit,
          count: list.filter(function (val) {
            return val.original.work_permit === item.original.work_permit;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setWorkperminList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.demand)) {
        map.set(item.original.demand, true);
        result.push({
          name: item.original.demand == "" ? "Blank" : item.original.demand,
          count: list.filter(function (val) {
            return val.original.demand === item.original.demand;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setDemandsList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.updated_on)) {
        map.set(item.original.updated_on, true);
        result.push({
          name: item.original.updated_on == "" ? "Blank" : item.original.updated_on,
          count: list.filter(function (val) {
            return val.original.updated_on === item.original.updated_on;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setJobConfirmedList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.workplace)) {
        map.set(item.original.workplace, true);
        result.push({
          name: item.original.workplace == "" ? "Blank" : item.original.workplace,
          count: list.filter(function (val) {
            return val.original.workplace === item.original.workplace;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setWorkfromList(result);
  }, [list]);

  useEffect(() => {
    let types1 = [];
    for (const fulllist of list) {
      if (fulllist.original.date1 !== "" && fulllist.original.date1 !== null) {
        types1.push({
          name: fulllist.original.date1,
        });
      }
    }
    const result = [];
    let result1 = [];
    const map = new Map();
    for (const item of types1) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        if (item.name !== "ASAP") {
          result.push({
            name: item.name,
            count: types1.filter(function (val) {
              return val.name === item.name;
            }).length,
          });
        } else {
          result1.push({
            name: item.name,
            count: types1.filter(function (val) {
              return val.name === item.name;
            }).length,
          });
        }
      }
    }
    result.sort(function (a, b) {
      return new Date(a.name) - new Date(b.name);
    });
    if (result1.length > 0) {
      result.unshift({
        name: result1[0].name,
        count: result1[0].count,
      });
    }
    setDateList(result);
  }, [list]);

  useEffect(() => {
    let types1 = [];
    for (const fulllist of list) {
      if (fulllist.original.date2 !== "" && fulllist.original.date2 !== null) {
        types1.push({
          name: fulllist.original.date2,
        });
      }
    }
    const result = [];
    let result1 = [];
    const map = new Map();
    for (const item of types1) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        if (item.name !== "ASAP") {
          result.push({
            name: item.name,
            count: types1.filter(function (val) {
              return val.name === item.name;
            }).length,
          });
        } else {
          result1.push({
            name: item.name,
            count: types1.filter(function (val) {
              return val.name === item.name;
            }).length,
          });
        }
      }
    }
    result.sort(function (a, b) {
      return new Date(a.name) - new Date(b.name);
    });
    if (result1.length > 0) {
      result.unshift({
        name: result1[0].name,
        count: result1[0].count,
      });
    }
    setDateList2(result);
  }, [list]);

  // useEffect(() => {
  //   const result = [];
  //   const map = new Map();
  //   for (const item of list) {
  //     if (!map.has(item.original.job_profile)) {
  //       map.set(item.original.job_profile, true);
  //       result.push({
  //         name:
  //           item.original.job_profile == ""
  //             ? "Blank"
  //             : item.original.job_profile,
  //         count: list.filter(function (val) {
  //           return val.original.job_profile === item.original.job_profile;
  //         }).length,
  //       });
  //     }
  //   }
  //   result.sort(function (a, b) {
  //     return b.count - a.count;
  //   });
  //   setJobProfileList(result);
  // }, [list]);

  // useEffect(() => {
  //   const result = [];
  //   const map = new Map();
  //   for (const item of list) {
  //     if (
  //       !map.has(moment(moment(item.original.updated_on)).format("DD MMM, YY"))
  //     ) {
  //       map.set(
  //         moment(moment(item.original.updated_on)).format("DD MMM, YY"),
  //         true
  //       );
  //       result.push({
  //         name: moment(moment(item.original.updated_on)).format("DD MMM, YY"),
  //         count: list.filter(function (val) {
  //           return (
  //             moment(moment(val.original.updated_on)).format("DD MMM, YY") ===
  //             moment(moment(item.original.updated_on)).format("DD MMM, YY")
  //           );
  //         }).length,
  //       });
  //     }
  //   }
  //   result.sort(function (a, b) {
  //     return new Date(a.name) - new Date(b.name);
  //   });
  //   setUpdated_onList(result);
  // }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (
        !map.has(moment(moment(item.original.createdAt)).format("DD MMM, YY"))
      ) {
        map.set(
          moment(moment(item.original.createdAt)).format("DD MMM, YY"),
          true
        );
        result.push({
          name: moment(moment(item.original.createdAt)).format("DD MMM, YY"),
          count: list.filter(function (val) {
            return (
              moment(moment(val.original.createdAt)).format("DD MMM, YY") ===
              moment(moment(item.original.createdAt)).format("DD MMM, YY")
            );
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return new Date(a.name) - new Date(b.name);
    });
    setCreatedAtList(result);
  }, [list]);

  const status = [
    {
      key: "normal",
      value: "Active",
      text: "Active",
      bgColor: "#30887e",
      txtColor: "#fff",
    },
    {
      key: "to qualify",
      value: "Qualify",
      text: "Qualify",
      bgColor: "#D58B5D",
      txtColor: "#fff",
    },
    {
      key: "on hold",
      value: "On Hold",
      text: "On Hold",
      bgColor: "#7f5347",
      txtColor: "#fff",
    },
    {
      key: "urgent",
      value: "Inactive",
      text: "Inactive",
      bgColor: "#B55355",
      txtColor: "#fff",
    },
    {
      key: "closed",
      value: "Closed",
      text: "Closed",
      bgColor: "#e2445c",
      txtColor: "#fff",
    },
  ];

  const jobArrayValues = [
    {
      key: "High",
      value: "High",
      text: "High",
      bgColor: "#30887e",
      txtColor: "#fff",
    },
    {
      key: "Mid",
      value: "Mid",
      text: "Mid",
      bgColor: "#d58b5d",
      txtColor: "#fff",
    },
    {
      key: "Low",
      value: "Low",
      text: "Low",
      bgColor: "#a84d4d",
      txtColor: "#fff",
    },
  ];

  return (
    <>
      <div className="dk-jobsMainModalCon">
        <Modal
          show={show}
          onHide={onHide}
          dialogClassName="dkmb-rightFliterModal"
        >
          <Modal.Header closeButton className="dkmb-Fliterheader">
            <Modal.Title className="dkmb-FliterTitle">
              SEARCH & SUMMARY
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="dkmb-Fliterbody">
            <Tab.Container
              id="dk-jobsRightFilterModal"
              defaultActiveKey="status"
            >
              <div className="row ml-0 mr-0" style={{ height: "100%" }}>
                <div
                  className="col-md-5 pl-0 pr-0"
                  style={{
                    background: "#eaeaea",
                    borderLeft: "1px solid #cccc",
                  }}
                >
                  <Nav variant="pills" className="flex-column dk-leftSideMenu">
                    <Nav.Item>
                      <Nav.Link style={{ fontSize: "12px" }} eventKey="status">
                        <i className="fa fa-check mr-2" aria-hidden="true"></i>
                        Status
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ fontSize: "12px" }} eventKey="values">
                        <i className="fa fa-check mr-2" aria-hidden="true"></i>
                        Values
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link style={{ fontSize: "12px" }} eventKey="am">
                        <i className="fa fa-user mr-2" aria-hidden="true"></i>
                        AM
                      </Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item>
                      <Nav.Link style={{ fontSize: "12px" }} eventKey="ra">
                        <i className="fa fa-user mr-2" aria-hidden="true"></i>
                        RA
                      </Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item>
                      <Nav.Link style={{ fontSize: "12px" }} eventKey="rc">
                        <i className="fa fa-user mr-2" aria-hidden="true"></i>
                        RC1
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: "12px" }}
                        eventKey="recruiter-two"
                      >
                        <i className="fa fa-user mr-2" aria-hidden="true"></i>
                        RC2
                      </Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link style={{ fontSize: "12px" }} eventKey="company">
                        <i className="fa fa-building" aria-hidden="true"></i>
                        Company
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: "12px" }}
                        eventKey="category"
                      >
                        <i className="fa fa-bars" aria-hidden="true"></i>
                        Category
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ fontSize: "12px" }} eventKey="project">
                        <MdInsertDriveFile />
                        Project
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: "12px" }}
                        eventKey="roletype"
                      >
                        <i className="fa fa-user" aria-hidden="true"></i>Role
                        Type
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: "12px" }}
                        eventKey="language"
                      >
                        <i className="fa fa-language" aria-hidden="true"></i>
                        LANGUAGE
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ fontSize: "12px" }} eventKey="country">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        Country
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link style={{ fontSize: "12px" }} eventKey="city">
                        <i className="fas fa-map-marker-alt"></i>CITY/TOWN
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: "12px" }}
                        eventKey="Locailty"
                      >
                        <i className="fas fa-map-marker-alt"></i>WORK FROM
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: "12px" }}
                        eventKey="work-permit"
                      >
                        <i className="fas fa-map-marker-alt"></i>WORK PERMIT
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: "12px" }}
                        eventKey="startdate"
                      >
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                        START DATE 1
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: "12px" }}
                        eventKey="startdate-two"
                      >
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                        START DATE 2
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link style={{ fontSize: "12px" }} eventKey="hirefee">
                        <i className="far fa-money-bill-alt"></i>HIRING FEE
                      </Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: "12px" }}
                        eventKey="jobscame"
                      >
                        <i className="far fa-user"></i>JOBS CAME
                      </Nav.Link>
                    </Nav.Item>

                    {/* <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: "12px" }}
                        eventKey="jobprofile"
                      >
                        <i className="far fa-user"></i>JOB PROFILE
                      </Nav.Link>
                    </Nav.Item> */}

                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: "12px" }}
                        eventKey="jobupdated"
                      >
                        <i className="far fa-calendar"></i>JOB UPDATED
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: "12px" }}
                        eventKey="jobcreated"
                      >
                        <i className="far fa-calendar"></i>JOB CREATED
                      </Nav.Link>
                    </Nav.Item>

                    {/* <Nav.Item>
                                            <Nav.Link style={{ fontSize: "12px" }} eventKey="recource-admin">
                                                <i className="fa fa-user" aria-hidden="true"></i>RESOURCE
                                            </Nav.Link>
                                        </Nav.Item> */}

                    {/* <Nav.Item>
                                            <Nav.Link style={{ fontSize: "12px" }} eventKey="account-m">
                                                <i className="fa fa-user" aria-hidden="true"></i>Account M
                                            </Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link style={{ fontSize: "12px" }} eventKey="recruiters">
                                                <i className="fa fa-user" aria-hidden="true"></i>RECRUITER
                                            </Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link style={{ fontSize: "12px" }} eventKey="recruiter-m">
                                                <i className="fa fa-user" aria-hidden="true"></i>REC ADMIN
                                            </Nav.Link>
                                        </Nav.Item> */}
                  </Nav>
                </div>
                <div className="col-md-7 pl-0 pr-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="status">
                      <div className="dk-rightMainContent">
                        <div className="dk-satusList">
                          <ul>
                            {status.map((item, index) => (
                              <li className="dk-fliterList" key={item.key}>
                                <Link
                                  to="#"
                                  className="dk-normalBg"
                                  data-type="status"
                                  data-value={item.text}
                                  onClick={selectOtherFilter}
                                  style={{ backgroundColor: item.bgColor }}
                                >
                                  <span
                                    className="dk-statusName"
                                    data-type="status"
                                    data-value={item.text}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.text}
                                  </span>
                                  <span
                                    className="dk-statusCounter"
                                    data-type="status"
                                    data-value={item.text}
                                    onClick={selectOtherFilter}
                                  >
                                    {
                                      list.filter(function (val) {
                                        return (
                                          val.original.status === item.text
                                        );
                                      }).length
                                    }
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="values">
                      <div className="dk-rightMainContent">
                        <div className="dk-satusList">
                          <ul>
                            {jobArrayValues.map((item, index) => (
                              <li className="dk-fliterList" key={item.key}>
                                <Link
                                  to="#"
                                  className="dk-normalBg"
                                  data-type="values"
                                  data-value={item.text}
                                  onClick={selectOtherFilter}
                                  style={{ backgroundColor: item.bgColor }}
                                >
                                  <span
                                    className="dk-statusName"
                                    data-type="values"
                                    data-value={item.text}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.text}
                                  </span>
                                  <span
                                    className="dk-statusCounter"
                                    data-type="values"
                                    data-value={item.text}
                                    onClick={selectOtherFilter}
                                  >
                                    {
                                      list.filter(function (val) {
                                        return (
                                          val.original.values === item.text
                                        );
                                      }).length
                                    }
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="am">
                      <div className="dk-rightMainContent">
                        <div className="dk-recruitersfilterList">
                          <ul>
                            <li className="dk-fliterList">
                              <Link to="#">
                                <img
                                  src="https://d3eppau9to224z.cloudfront.net/users/images/user.png"
                                  alt="No User"
                                  className="recImg"
                                />
                                <span className="recuriterNo text-orange">
                                  52
                                </span>
                              </Link>
                              <Link to="#">
                                <img
                                  src="https://d3eppau9to224z.cloudfront.net/users/images/1678810397372.png"
                                  alt="No User"
                                  className="recImg"
                                />
                                <span className="recuriterNo text-orange">
                                  52
                                </span>
                              </Link>
                            </li>
                            <li className="dk-fliterList">
                              <Link to="#">
                                <img
                                  src="https://d3eppau9to224z.cloudfront.net/users/images/user.png"
                                  alt="No User"
                                  className="recImg"
                                />
                                <span className="recuriterNo text-orange">
                                  52
                                </span>
                              </Link>
                              <Link to="#">
                                <img
                                  src="https://d3eppau9to224z.cloudfront.net/users/images/1678810397372.png"
                                  alt="No User"
                                  className="recImg"
                                />
                                <span className="recuriterNo text-orange">
                                  52
                                </span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="rc">
                      <div className="dk-rightMainContent">
                        <div className="dk-recruitersfilterList">
                          <ul>
                            <li className="dk-fliterList">
                              <Link to="#">
                                <img
                                  src="https://d3eppau9to224z.cloudfront.net/users/images/user.png"
                                  alt="No User"
                                  className="recImg"
                                />
                                <span className="recuriterNo text-orange">
                                  52
                                </span>
                              </Link>
                              <Link to="#">
                                <img
                                  src="https://d3eppau9to224z.cloudfront.net/users/images/1678810397372.png"
                                  alt="No User"
                                  className="recImg"
                                />
                                <span className="recuriterNo text-orange">
                                  52
                                </span>
                              </Link>
                            </li>
                            <li className="dk-fliterList">
                              <Link to="#">
                                <img
                                  src="https://d3eppau9to224z.cloudfront.net/users/images/user.png"
                                  alt="No User"
                                  className="recImg"
                                />
                                <span className="recuriterNo text-orange">
                                  52
                                </span>
                              </Link>
                              <Link to="#">
                                <img
                                  src="https://d3eppau9to224z.cloudfront.net/users/images/1678810397372.png"
                                  alt="No User"
                                  className="recImg"
                                />
                                <span className="recuriterNo text-orange">
                                  52
                                </span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="recruiter-two">
                      <div className="dk-rightMainContent">
                        <div className="dk-recruitersfilterList">
                          <ul>
                            <li className="dk-fliterList">
                              <Link to="#">
                                <img
                                  src="https://d3eppau9to224z.cloudfront.net/users/images/user.png"
                                  alt="No User"
                                  className="recImg"
                                />
                                <span className="recuriterNo text-orange">
                                  52
                                </span>
                              </Link>
                              <Link to="#">
                                <img
                                  src="https://d3eppau9to224z.cloudfront.net/users/images/1678810397372.png"
                                  alt="No User"
                                  className="recImg"
                                />
                                <span className="recuriterNo text-orange">
                                  52
                                </span>
                              </Link>
                            </li>
                            <li className="dk-fliterList">
                              <Link to="#">
                                <img
                                  src="https://d3eppau9to224z.cloudfront.net/users/images/user.png"
                                  alt="No User"
                                  className="recImg"
                                />
                                <span className="recuriterNo text-orange">
                                  52
                                </span>
                              </Link>
                              <Link to="#">
                                <img
                                  src="https://d3eppau9to224z.cloudfront.net/users/images/1678810397372.png"
                                  alt="No User"
                                  className="recImg"
                                />
                                <span className="recuriterNo text-orange">
                                  52
                                </span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="recource-admin">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-recruitersfilterList">
                                                    <ul>
                                                        {
                                                            rmList && rmList.map((item, index) =>
                                                                <li key={`rm${index}`}>
                                                                    <Link to="#" data-type="RMID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} className="recruiterImg" alt="Recruiter" data-type="RMID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                                                        <span className="text-orange count_ml" data-type="RMID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane> */}
                    {/* <Tab.Pane eventKey="recruiter-m">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-recruitersfilterList">
                                                    <ul>
                                                        {
                                                            raList && raList.map((item, index) =>
                                                                <li key={`ra${index}`}>
                                                                    <Link to="#" data-type="RAID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} className="recruiterImg" alt="Recruiter" data-type="RAID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                                                        <span className="text-orange count_ml" data-type="RAID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="recruiters">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-recruitersfilterList">
                                                    <ul>
                                                        {
                                                            rcList && rcList.map((item, index) =>
                                                                <li key={`rc${index}`}>
                                                                    <Link to="#" data-type="RCID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} className="recruiterImg" alt="Recruiter" data-type="RCID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                                                        <span className="text-orange count_ml" data-type="RCID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="account-m">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-recruitersfilterList">
                                                    <ul>
                                                        {
                                                            amList && amList.map((item, index) =>
                                                                <li key={`am${index}`}>
                                                                    <Link to="#" data-type="AMID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} className="recruiterImg" alt="Recruiter" data-type="AMID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                                                        <span className="text-orange count_ml" data-type="AMID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>{item.count}</span>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane> */}
                    <Tab.Pane eventKey="category">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {categoryList &&
                              categoryList.map((item, index) => (
                                <li key={`category${index}`}>
                                  <Link
                                    to="#"
                                    data-type="category"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="category"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Locailty">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {workfromList &&
                              workfromList.map((item, index) => (
                                <li key={`workplace${index}`}>
                                  <Link
                                    to="#"
                                    data-type="workplace"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="workplace"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="work-permit">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {workpermitList &&
                              workpermitList.map((item, index) => (
                                <li key={`work_permit${index}`}>
                                  <Link
                                    to="#"
                                    data-type="work_permit"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="work_permit"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="jobscame">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {demandsList &&
                              demandsList.map((item, index) => (
                                <li key={`demand${index}`}>
                                  <Link
                                    to="#"
                                    data-type="demand"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {moment(moment(item.name)).tz("Europe/Dublin").fromNow(true)}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="demand"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="jobupdated">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {jobConfirmedList &&
                              jobConfirmedList.map((item, index) => (
                                <li key={`updated_on${index}`}>
                                  <Link
                                    to="#"
                                    data-type="updated_on"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                 
                                    {moment(moment(item.name)).tz("Europe/Dublin").fromNow(true)}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="updated_on"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="company">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {companyList &&
                              companyList.map((item, index) => (
                                <li key={`company${index}`}>
                                  <Link
                                    to="#"
                                    data-type="company"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="company"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="language">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {languageList &&
                              languageList.map((item, index) => (
                                <li key={`language${index}`}>
                                  <Link
                                    to="#"
                                    data-type="language"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="language"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="project">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {projectList &&
                              projectList.map((item, index) => (
                                <li key={`project${index}`}>
                                  <Link
                                    to="#"
                                    data-type="project"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="project"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="roletype">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {roleList &&
                              roleList.map((item, index) => (
                                <li key={`role${index}`}>
                                  <Link
                                    to="#"
                                    data-type="role"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="role"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="city">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {cityList &&
                              cityList.map((item, index) =>
                                item.name !== "" && item.name !== null ? (
                                  <li key={`city${index}`}>
                                    <Link
                                      to="#"
                                      data-type="city"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.name}
                                      <span
                                        className="text-orange count_ml"
                                        data-type="city"
                                        data-value={item.name}
                                        onClick={selectOtherFilter}
                                      >
                                        {item.count}
                                      </span>
                                    </Link>
                                  </li>
                                ) : (
                                  ""
                                )
                              )}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="country">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {countryList &&
                              countryList.map((item, index) => (
                                <li key={`country${index}`}>
                                  <Link
                                    to="#"
                                    data-type="country"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="country"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="hirefee">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {
                              // hireList &&
                              //   hireList.map((item, index) =>
                              //     item.name !== "" && item.name !== null ? (
                              //       <li key={`hire${index}`}>
                              //         <Link
                              //           to="#"
                              //           data-type="hire"
                              //           data-value={item.name}
                              //           onClick={selectOtherFilter}
                              //         >
                              //           {item.name}
                              //           <span
                              //             className="text-orange count_ml"
                              //             data-type="hire"
                              //             data-value={item.name}
                              //             onClick={selectOtherFilter}
                              //           >
                              //             {item.count}
                              //           </span>
                              //         </Link>
                              //       </li>
                              //     ) : (
                              //       ""
                              //     )
                              //   )
                            }
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="startdate">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {dateList &&
                              dateList.map((item, index) =>
                                item.name !== "" && item.name !== null ? (
                                  <li key={`date${index}`}>
                                    <Link
                                      to="#"
                                      data-type="date"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.name}
                                      <span
                                        className="text-orange count_ml"
                                        data-type="date"
                                        data-value={item.name}
                                        onClick={selectOtherFilter}
                                      >
                                        {item.count}
                                      </span>
                                    </Link>
                                  </li>
                                ) : (
                                  ""
                                )
                              )}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="startdate-two">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {dateList2 &&
                              dateList2.map((item, index) =>
                                item.name !== "" && item.name !== null ? (
                                  <li key={`date${index}`}>
                                    <Link
                                      to="#"
                                      data-type="date2"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.name}
                                      <span
                                        className="text-orange count_ml"
                                        data-type="date2"
                                        data-value={item.name}
                                        onClick={selectOtherFilter}
                                      >
                                        {item.count}
                                      </span>
                                    </Link>
                                  </li>
                                ) : (
                                  ""
                                )
                              )}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>

                   
                    {/* <Tab.Pane eventKey="jobupdated">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {updated_onList &&
                              updated_onList.map((item, index) =>
                                item.name !== "" && item.name !== null ? (
                                  <li key={`updatedate${index}`}>
                                    <Link
                                      to="#"
                                      data-type="updated_on"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {moment(moment(item.name)).format(
                                        "DD MMM, YY"
                                      )}
                                      <span
                                        className="text-orange count_ml"
                                        data-type="updated_on"
                                        data-value={item.name}
                                        onClick={selectOtherFilter}
                                      >
                                        {item.count}
                                      </span>
                                    </Link>
                                  </li>
                                ) : (
                                  ""
                                )
                              )}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane> */}

                    <Tab.Pane eventKey="jobcreated">
                      <div className="dk-rightMainContent">
                        <div className="dk-filterList">
                          <ul>
                            {createdAtList &&
                              createdAtList.map((item, index) =>
                                item.name !== "" && item.name !== null ? (
                                  <li key={`createdat${index}`}>
                                    <Link
                                      to="#"
                                      data-type="createdAt"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {moment(moment(item.name)).format(
                                        "DD MMM, YY"
                                      )}
                                      <span
                                        className="text-orange count_ml"
                                        data-type="createdAt"
                                        data-value={item.name}
                                        onClick={selectOtherFilter}
                                      >
                                        {item.count}
                                      </span>
                                    </Link>
                                  </li>
                                ) : (
                                  ""
                                )
                              )}
                          </ul>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default RightModalFilter;
