import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';

const AddNewModal = ({ handleClose }) => {
    return (
        <>
            <Modal className="dk-addNewCompletionModal" centered show={true} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Add New COMPLETION</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <div className="row">
                            <div className="col-md-6 pr-md-2">
                                <div className="form-group">
                                    <label htmlFor="">DKG Job Title</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control" name="" id="" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 pl-md-2">
                                <div className="form-group">
                                    <label htmlFor="">Training Type</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control" name="" id="" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 pr-md-2">
                                <div className="form-group">
                                    <label htmlFor="">Start Date</label>
                                    <label htmlFor="">
                                        <input type="date" className="form-control" name="" id="" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 pl-md-2">
                                <div className="form-group">
                                    <label htmlFor="">Finish Date</label>
                                    <label htmlFor="">
                                        <input type="date" className="form-control" name="" id="" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 pr-md-2">
                                <div className="form-group">
                                    <label htmlFor="">Trainer</label>
                                    <label htmlFor="">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/5a701dc2182ac.png" className="img-circle" alt="" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className="d-flex">
                                                <Link to="#"><img src="https://www.dkmasterbox.com/assets/upload/user_image/5a701dc2182ac.png" className="img-circle" alt="" /></Link>
                                                <Link to="#"><img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" className="img-circle" alt="" /></Link>
                                                <Link to="#"><img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-circle" alt="" /></Link>
                                                <Link to="#"><img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" className="img-circle" alt="" /></Link>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 pr-md-2">
                                <div className="form-group">
                                    <label htmlFor="">Employee</label>
                                    <label htmlFor="">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                            <img src="https://www.dkmasterbox.com/assets/upload/user_image/1582733069.jpeg" className="img-circle" alt="" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className="d-flex">
                                                <Link to="#"><img src="https://www.dkmasterbox.com/assets/upload/user_image/5a701dc2182ac.png" className="img-circle" alt="" /></Link>
                                                <Link to="#"><img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" className="img-circle" alt="" /></Link>
                                                <Link to="#"><img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-circle" alt="" /></Link>
                                                <Link to="#"><img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" className="img-circle" alt="" /></Link>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-12 mt-3">
                                <label htmlFor="" className="text-center">
                                    <button className="dk-adBtn">Add</button>
                                </label>
                            </div>
                            
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddNewModal;
