import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';

const DeleteModal = ({ handleClose }) => {
    return (
        <>
            <Modal className="dk-addNewCompletionModal dk-deleteModalMain" centered show={true} onHide={handleClose}>
                <Modal.Body>
                    <h4>Are you sure wants to archive these records !</h4>
                    <div className="d-flex justify-content-end">
                        <button className="btnRed">Yes</button>
                        <button onClick={handleClose}>No</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DeleteModal;
