import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../utils/messages/messageSlice";
import { checkoutUser, clearState } from '../../../../slice/attendance/attendanceSlice'
import SubmitButton from "../../../ui/submitButton";
import DatePicker from './datepicker/DatePicker'
import TimePicker from './timepicker/TimePicker'
import moment from "moment-timezone"

const CheckOutModal = ({ handleClose, id, userid, todaydate }) => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth);
    const { isSuccess, isUpdate, isLoading, isError } = useSelector(state => state.attendance)
    const [idd, setIdd] = useState('')
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('DD MMM YYYY'))
    const [pickerDueTime, setPickerTime] = useState(new Date())

    const [todayDate, setTodayDate] = useState(moment(new Date()).format('DD/MM/YYYY'))
    const [checkOutTime, setCheckOutTime] = useState(moment(new Date()).format('HH:mm'))
    

    const handleDateChange = (date) => {   
        setSelectedDate(moment(date).format('DD MMM YYYY')); 
        setTodayDate(moment(date).format('DD/MM/YYYY'))
    };
    const handleTimeChange = (date) => {
        setPickerTime(date)
        setCheckOutTime(moment(date).format('HH:mm'))
    };


    useEffect(() => {
        setIdd(id.pop())
    }, [id])

    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: "Please try again later" }))
            dispatch(clearState())
        }
        if (isUpdate) {
            handleClose();
            setLoading(false);
            setSuccess(true);
            dispatch(showSuccess({ msg: "Lunch time recorded" }))
            dispatch(clearState())
        }
    }, [isError, isUpdate])

    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (!todayDate || !checkOutTime) {
            dispatch(showError({ msg: 'All fields required' }))
        } else {
            setSuccess(false);
            setLoading(true);
            dispatch(checkoutUser({ id: idd, uid: userid, checkinDate: todaydate, key: 'checkoutTime', value: checkOutTime }))
        }
    }

    return (
        <>
            <Modal className="dk-checkInModal" centered show={true} onHide={handleClose}>
                <Modal.Header closeButton style={{ backgroundColor: '#dc3545' }}>
                    <Modal.Title>Daily Check-Out</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-checkInBody">
                        <form action="">
                            <div className="dk-checkInForm">
                                <div className="row">
                                    <div className="col-md-1">
                                        <div className="form-group">
                                            <label htmlFor=""> </label>
                                            <div className="dk-userFilter">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + userInfo.profileImage} className="img-fluid img-circle" alt={`${userInfo.firstName} ${userInfo.lastName}`} title={`${userInfo.firstName} ${userInfo.lastName}`} />
                                                    </Dropdown.Toggle>

                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">Check-Out Date</label>
                                            {/* <label htmlFor="">
                                                <input type="date" name="" id="" className="form-control" onChange={(e) => setTodayDate(e.target.value)} />
                                            </label> */}
                                            <div className="dkg-timepicker-Con">
                                                <DatePicker handleDateChange={handleDateChange}  selectedDate={selectedDate} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label htmlFor="">Check-Out Time</label>
                                            {/* <label htmlFor="">
                                                <input type="time" name="" id="" className="form-control" onChange={(e) => setCheckOutTime(e.target.value)} />
                                            </label> */}
                                            <div className="dkg-timepicker-Con">
                                                 <TimePicker pickerDueTime={pickerDueTime} handleTimeChange={handleTimeChange}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-checkInOutBtn">
                                {/* <input type="button" value="Submit" style={{ backgroundColor: '#dc3545' }} /> */}
                                <SubmitButton className="dkg-create-payment-taskbtn dkg-checkout-btn" txt="Submit" loading={loading} success={success} onClick={handleButtonClick} />
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CheckOutModal;
