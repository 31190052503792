import React from "react";
import { Route, Link } from "react-router-dom";

const RecTrainingSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/resources/training/overview" className={`${(segment3 == 'overview') ? 'active' : ''}`}>Overview</Link></li>
                        <li><Link to="/resources/training/dashboard" className={`${(segment3 == 'dashboard') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="/resources/training/work-plan" className={(segment3 == 'work-plan') ? 'active' : ''}>ML Resource</Link></li>
                        <li><Link to="/resources/training/training-notifications" className={`${(segment3 == 'training-notifications') ? 'active' : ''}`}>IT Resource</Link></li>
                        <li><Link to="/resources/training/candidate" className={`${(segment3 == 'candidate') ? 'active' : ''}`}>Logins</Link></li>
                        <li><Link to="/resources/training/training-jobs" className={`${(segment3 == 'training-jobs') ? 'active' : ''}`}>Budget</Link></li>
                        <li><Link to="/resources/training/company" className={`${(segment3 == 'company') ? 'active' : ''}`}>Cost/Benefit</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default RecTrainingSubmenu;
