import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import './addnewclientsinvoice.scss';
import DatePicker from './InvoiceDatepicker';

const AddNewClientsInvoice = ({ hideAddNewInvoiceModal }) => {
    return (
        <>
            <Modal className="dk-statmentsModalMain right" show={true} onHide={hideAddNewInvoiceModal}>
                <Modal.Header closeButton>
                    <Modal.Title>ADD NEW BANKING</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="dk-addNewInvoiceModal-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Transaction</div>
                                    <div className="dk-addInvoice-label dk-invoiceDatepicker">
                                        <DatePicker />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Month</div>
                                    <div className="dk-addInvoice-label">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                Select
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">January</Dropdown.Item>
                                                <Dropdown.Item href="#">February</Dropdown.Item>
                                                <Dropdown.Item href="#">March</Dropdown.Item>
                                                <Dropdown.Item href="#">April</Dropdown.Item>
                                                <Dropdown.Item href="#">May</Dropdown.Item>
                                                <Dropdown.Item href="#">June</Dropdown.Item>
                                                <Dropdown.Item href="#">July</Dropdown.Item>
                                                <Dropdown.Item href="#">August</Dropdown.Item>
                                                <Dropdown.Item href="#">September</Dropdown.Item>
                                                <Dropdown.Item href="#">October</Dropdown.Item>
                                                <Dropdown.Item href="#">November</Dropdown.Item>
                                                <Dropdown.Item href="#">December</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Category</div>
                                    <div className="dk-addInvoice-label">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                Select
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">IT Services</Dropdown.Item>
                                                <Dropdown.Item href="#">Recruitment</Dropdown.Item>
                                                <Dropdown.Item href="#">Accounting</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Services</div>
                                    <div className="dk-addInvoice-label">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                Select
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">IT Services</Dropdown.Item>
                                                <Dropdown.Item href="#">Recruitment</Dropdown.Item>
                                                <Dropdown.Item href="#">Accounting</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Name of Business</div>
                                    <div className="dk-addInvoice-label">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                Select
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">DK Global Jobs</Dropdown.Item>
                                                <Dropdown.Item href="#">TCS</Dropdown.Item>
                                                <Dropdown.Item href="#">Wipro</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">VAT</div>
                                    <div className="dk-addInvoice-label">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                Select
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">Vat 1</Dropdown.Item>
                                                <Dropdown.Item href="#">Vat 2</Dropdown.Item>
                                                <Dropdown.Item href="#">Vat 3</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Paid Out </div>
                                    <div className="dk-addInvoice-label">
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Paid IN </div>
                                    <div className="dk-addInvoice-label">
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-addInvoice-title">Balance</div>
                                    <div className="dk-addInvoice-label">
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="dk-invoiceAdd-btn">
                                        <button>ADD</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddNewClientsInvoice;
