import React from 'react';
import { Link } from "react-router-dom";

const ProcessLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="dk-moduledkFinanceProcess-leftPanel">
                <ul>
                    <li><Link to="/dkglobalfinance/invoices/process/overview" className={`${pathname.match('/dkglobalfinance/invoices/process/overview') ? 'active' : ''}`}>Overview</Link></li>
                    <li><Link to="/dkglobalfinance/invoices/process/step-01" className={`${pathname.match('/dkglobalfinance/invoices/process/step-01') ? 'active' : ''}`}>TP Greece</Link></li>
                    <li><Link to="/dkglobalfinance/invoices/process/step-2" className={`${pathname.match('/dkglobalfinance/invoices/process/step-2') ? 'active' : ''}`}>Genpact</Link></li>
                    <li><Link to="/dkglobalfinance/invoices/process/step-3" className={`${pathname.match('/dkglobalfinance/invoices/process/step-3') ? 'active' : ''}`}>Tech M</Link></li>
                    <li><Link to="/dkglobalfinance/invoices/process/step-4" className={`${pathname.match('/dkglobalfinance/invoices/process/step-4') ? 'active' : ''}`}>Wipro</Link></li>
                    <li><Link to="/dkglobalfinance/invoices/process/step-5" className={`${pathname.match('/dkglobalfinance/invoices/process/step-5') ? 'active' : ''}`}>GEP</Link></li>
                    <li><Link to="/dkglobalfinance/invoices/process/step-6" className={`${pathname.match('/dkglobalfinance/invoices/process/step-6') ? 'active' : ''}`}>Webhelp GR</Link></li>
                    <li><Link to="/dkglobalfinance/invoices/process/step-7" className={`${pathname.match('/dkglobalfinance/invoices/process/step-7') ? 'active' : ''}`}>Webhelp PT</Link></li>
                    <li><Link to="/dkglobalfinance/invoices/process/step-8" className={`${pathname.match('/dkglobalfinance/invoices/process/step-8') ? 'active' : ''}`}>Pragmatic</Link></li>
                    <li><Link to="/dkglobalfinance/invoices/process/step-9" className={`${pathname.match('/dkglobalfinance/invoices/process/step-9') ? 'active' : ''}`}>Mphasis</Link></li>
                    <li><Link to="/dkglobalfinance/invoices/process/step-10" className={`${pathname.match('/dkglobalfinance/invoices/process/step-10') ? 'active' : ''}`}>Cxfort</Link></li>
                </ul>
            </div>
        </>
    )
}

export default ProcessLeftPanel;
