import React from "react";
import '../mainMenu/style.scss';
import TasksSubmenu from "./menu/mainsubmenu/TasksSubmenu";
import NotificatoinSubmenu from './menu/mainsubmenu/NotificatoinSubmenu';
import DKGlobalSubmenu from "./menu/mainsubmenu/DKGlobalSubmenu";
import AnnoucementSubmenu from './menu/mainsubmenu/AnnoucementSubmenu';
import GetStartedtSubmenu from './menu/mainsubmenu/GetStartedtSubmenu';
import FaqSubmenu from './menu/mainsubmenu/FaqSubmenu';
import TrackingSubMenu from "./menu/mainsubmenu/TrackingSubMenu";
import DashboardSubmenu from "./menu/mainsubmenu/DashboardSubmenu";
import PipelinesSubmenu from "./menu/PipelinesSubmenu";
import JobsSubmenu from "./menu/JobsSubmenu";
import MainAdsSubmenu from "./menu/mainsubmenu/MainAdsSubmenu";
// import DatabaseSubmenu from "./menu/mainsubmenu/DatabaseSubmenu";
import KPISubmenu from "./menu/mainsubmenu/KpiSubmenu";
import MyPipelineSubmenu from './menu/mainsubmenu/MyPipelineSubmenu';
import RulesSubmenu from './menu/mainsubmenu/RulesSubmenu';
import RcWalletSubmenu from './menu/mainsubmenu/RcWalletSubmenu';
import SendoutsSubmenu from "./menu/mainsubmenu/SendoutsSubmenu";
import PayTasksSubmenu from "./menu/mainsubmenu/PayTasksSubmenu";
import WorkPlanSubmenu from "./menu/mainsubmenu/WorkPlanSubmenu";
import TargetsSubmenu from './menu/mainsubmenu/TargetsSubmenu';
import ClientInfoSubmenu from './menu/mainsubmenu/ClientinfoSubmenu';
import SpecSubmenu from './menu/mainsubmenu/SpecSubmenu'
import JobSpecSubmenu from './menu/mainsubmenu/JobSpecSubmenu'
import IntSpecSubmenu from "./menu/mainsubmenu/IntSpecSubmenu";
// import ClientInfoSubmenu from './menu/ClientInfoSubmenu'
import JobappsSubmenu from './menu/mainsubmenu/JobappsSubmenu'
import MailboxSubmenu from './menu/mainsubmenu/MailboxSubmenu'



const Submenu = ({ path }) => {
    const renderSubmenu = () => {
        switch (path.split("/")[1]) {
            case 'tasks':
                return <TasksSubmenu />
                break;

            case 'pipelines':
                return <PipelinesSubmenu />


            case 'paytasks':
                return <PayTasksSubmenu />

            case 'jobs':
                return <JobsSubmenu />

            case 'notifications':
                return <NotificatoinSubmenu />
                break;

            case 'dkglobal':
                return <DKGlobalSubmenu />
                break;

            case 'annoucements':
                return <AnnoucementSubmenu />
                break;

            case 'clients':
                return <ClientInfoSubmenu />
                break;
            case 'mailbox':
                return <MailboxSubmenu />
                break;

            case 'spec':
                return <SpecSubmenu />
                break;
            
            case 'job-specs':
                return <JobSpecSubmenu />
                break;

            case 'int-spec':
                return <IntSpecSubmenu />
                break;

            // case 'clients':
            //     return <IntSpecSubmenu />
            //     break;

            case 'tracking':
                return <TrackingSubMenu />
                break;

            case 'get-started':
                return <GetStartedtSubmenu />
                break;

            case 'faq':
                return <FaqSubmenu />
                break;

            case 'dashboard':
                return <DashboardSubmenu />
                break;

            case 'ads':
                return <MainAdsSubmenu />
                break;

            // case 'database':
            //     return <DatabaseSubmenu />
            //     break;

            case 'my-pipeline':
                return <MyPipelineSubmenu />
                break;

            case 'kpis':
                return <KPISubmenu />
                break;

            case 'reports':
                return <SendoutsSubmenu />
                break;

            case 'rules':
                return <RulesSubmenu />
                break; 

            case 'rc-wallet':
                return <RcWalletSubmenu />
                break;

            case 'workplan':
                return <WorkPlanSubmenu />
                break;

            case 'job-apps':
            return <JobappsSubmenu />
            break;

            case 'targets':
                return <TargetsSubmenu />
                break;

            default:
                break
        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default Submenu;