import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

const FlagFilter = ({ list, id, img, selectUserFilter }) => {

    return (
        <React.Fragment>
            <Dropdown>
                <Dropdown.Toggle variant="" className="tableFilterRoundButton">
                    {
                        (id === '0') ? <i className='fas fa-flag'></i> : <i className='fas fa-flag' style={{ color: img }}></i>
                    }
                </Dropdown.Toggle>
                <Dropdown.Menu className='dk-lhsflagnoDropMenu'>
                    <div className="d-flex">
                        <Dropdown.Item data-type="FlagID" data-id="5" data-img="#3A9469" onClick={selectUserFilter}>
                            <i className="fas fa-flag" style={{ color: "#3A9469" }} data-type="FlagID" data-id="5" data-img="#3A9469" onClick={selectUserFilter}></i>
                            <span className="recNoCount" data-type="FlagID" data-id="5" data-img="#3A9469" onClick={selectUserFilter}>
                                Good Case
                            </span>
                            <span className="recNoCount" data-type="FlagID" data-id="0" data-img="#3A9469" onClick={selectUserFilter}>
                                {
                                    list.filter(function (val) {
                                        return val.original.flag_type === 5;
                                    }).length
                                }
                            </span>
                        </Dropdown.Item>
                        <Dropdown.Item data-id="FlagID" data-img="#D58B5D" onClick={selectUserFilter}>
                            <i className="fas fa-flag" style={{ color: "#D58B5D" }} data-id="FlagID" data-img="#D58B5D" onClick={selectUserFilter}></i>
                            <span className="recNoCount" data-id="FlagID" data-img="#D58B5D" onClick={selectUserFilter}>
                                Normal Case
                            </span>
                            <span className="recNoCount" data-id="FlagID" data-img="#D58B5D" onClick={selectUserFilter}>
                                {
                                    list.filter(function (val) {
                                        return val.original.flag_type === 3;
                                    }).length
                                }
                            </span>
                        </Dropdown.Item>
                        <Dropdown.Item data-id="FlagID" data-img="#E2445B" onClick={selectUserFilter}>
                            <i className="fas fa-flag" style={{ color: "#E2445B" }} data-id="FlagID" data-img="#E2445B" onClick={selectUserFilter}></i>
                            <span className="recNoCount" data-id="FlagID" data-img="#E2445B" onClick={selectUserFilter}>
                                Bad Case
                            </span>
                            <span className="recNoCount" data-id="FlagID" data-img="#E2445B" onClick={selectUserFilter}>
                                {
                                    list.filter(function (val) {
                                        return val.original.flag_type === 2;
                                    }).length
                                }
                            </span>
                        </Dropdown.Item>
                        <Dropdown.Item data-type="FlagID" data-id="1" data-img="#d3d3d3" onClick={selectUserFilter}>
                            <i className="fas fa-flag" style={{ color: "#d3d3d3" }} data-type="FlagID" data-id="1" data-img="#d3d3d3" onClick={selectUserFilter}></i>
                            <span className="recNoCount" data-type="FlagID" data-id="1" data-img="#d3d3d3" onClick={selectUserFilter}>
                                Blank
                            </span>
                            <span className="recNoCount" data-type="FlagID" data-id="1" data-img="#d3d3d3" onClick={selectUserFilter}>
                                {
                                    list.filter(function (val) {
                                        return val.original.flag_type === 1;
                                    }).length
                                }
                            </span>
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    )
}
export default FlagFilter;