import React from "react";
import "./status.scss";

const tableStatus = ({ value }) => {
  let bgColor = "";
  switch (value) {
    case "Normal":
      bgColor = "#30887e";
      break;

    case "Urgent":
      bgColor = "#B55355";
      break;

    case "To qualify":
      bgColor = "#D58B5D";
      break;

    case "On Hold":
      bgColor = "#7f5347";
      break;

    case "Closed":
      bgColor = "#e2445c";
      break;
  }

  console.log(bgColor);
  return (
    <>
      <div
        className="caseDiv"
        style={{ background: bgColor, minHeight: "32px", fontSize: "11px" }}
      >
        {value}
      </div>
    </>
  );
};

export default tableStatus;
