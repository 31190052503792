import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import '../../trainingdetail.scss';


const AssessmentOneDetailLeftTabs = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box>
                <TabContext value={value}>
                    <div className="d-flex">
                        <div className="dk-detailLefttabsMain">
                            <Box>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="About DK Global" value="1" />
                                    <Tab label="Teams & Roles" value="2" />
                                    <Tab label="Clients & Jobs" value="3" />
                                    <Tab label="Workplan & Tasks" value="4" />
                                    <Tab label="Technical Checks" value="5" />
                                    <Tab label="Outlook Setup Test" value="6" />
                                    <Tab label="DKG CRM & Pipelines" value="7" />
                                    <Tab label="Phone Call Intro Script" value="8" />
                                    <Tab label="Preparation Checklist" value="9" />
                                    <Tab label="Job Interest Questions" value="10" />
                                </TabList>
                            </Box>
                        </div>
                        <div className="dk-tabDetail-flex">
                            <TabPanel value="1">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!
                            </TabPanel>
                            <TabPanel value="2">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut quam deserunt quidem recusandae similique ad corporis ducimus in temporibus labore doloribus excepturi aliquam odit, optio inventore exercitationem tempore voluptates culpa.
                            </TabPanel>
                            <TabPanel value="3">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!
                            </TabPanel>
                            <TabPanel value="4">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut quam deserunt quidem recusandae similique ad corporis ducimus in temporibus labore doloribus excepturi aliquam odit, optio inventore exercitationem tempore voluptates culpa.
                            </TabPanel>
                            <TabPanel value="5">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!
                            </TabPanel>
                            <TabPanel value="6">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut quam deserunt quidem recusandae similique ad corporis ducimus in temporibus labore doloribus excepturi aliquam odit, optio inventore exercitationem tempore voluptates culpa.
                            </TabPanel>
                            <TabPanel value="7">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!
                            </TabPanel>
                            <TabPanel value="8">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut quam deserunt quidem recusandae similique ad corporis ducimus in temporibus labore doloribus excepturi aliquam odit, optio inventore exercitationem tempore voluptates culpa.
                            </TabPanel>
                            <TabPanel value="9">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea!
                            </TabPanel>
                            <TabPanel value="10">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus iusto quasi dolores cumque ut placeat voluptas vero! Qui itaque at similique, accusantium architecto ratione obcaecati sint dolores, neque vel ea! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut quam deserunt quidem recusandae similique ad corporis ducimus in temporibus labore doloribus excepturi aliquam odit, optio inventore exercitationem tempore voluptates culpa.
                            </TabPanel>
                        </div>
                    </div>
                </TabContext>
            </Box>


        </>
    )
}

export default AssessmentOneDetailLeftTabs;

