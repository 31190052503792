import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData } from "../../services";

export const getTaskByProgress = createAsyncThunk(
    'task/list/by-progress',
    async ({ status }, thunkAPI) => {
        const res = await GetData(true, '/tasks/' + status)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const archivedData = createAsyncThunk(
    'task/list/archive',
    async (thunkAPI) => {
        const res = await GetData(true, '/tasks/archived')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const addNewTask = createAsyncThunk(
    'task/add-new',
    async ({ type, taskType, subject, details, taskDueDate, dueTime, taskBy, taskFor, isAttachment, attachment }, thunkAPI) => {
        const res = await PostData(true, '/tasks', { type, taskType, subject, details, taskDueDate, dueTime, taskBy, taskFor, isAttachment, attachment })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const updateTask = createAsyncThunk(
    'task/update',
    async ({ id, type, subject, taskType, taskDueDate, dueTime, taskBy, taskFor, isAttachment, attachment }, thunkAPI) => {
        const res = await PutData(true, '/tasks/' + id, { type, subject, taskType, taskDueDate, dueTime, taskBy, taskFor, isAttachment, attachment })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateByKey = createAsyncThunk(
    'task/update/key-value',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/tasks/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateProgress = createAsyncThunk(
    'task/update/key-value/progress',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/tasks/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const countAllTask = createAsyncThunk(
    'task/count/all',
    async ({ status }, thunkAPI) => {
        const res = await GetData(true, '/tasks/count-all')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const deleteTask = createAsyncThunk(
    'task/count/delete',
    async ({ id }, thunkAPI) => {
        const res = await PostData(true, '/tasks/bulk-delete/', { id })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskMoveTO = createAsyncThunk(
    'task/count/task-move',
    async ({ id }, thunkAPI) => {
        const res = await PostData(true, '/tasks/bulk-move', { id })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskMoveToTable = createAsyncThunk(
    'task/count/task/move-to-table',
    async ({ id, progress }, thunkAPI) => {
        const res = await PostData(true, '/tasks/move-to-table', { id, progress })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const counts = createAsyncThunk(
    'task/count/by-progress',
    async ({ status }, thunkAPI) => {
        const res = await GetData(true, '/tasks/count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const archivedCounts = createAsyncThunk(
    'task/count/archive',
    async (thunkAPI) => {
        const res = await GetData(true, '/tasks/archive-count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateTaskCommentCount = createAsyncThunk(
    'task/commentlist/update-comment',
    async ({ id, value, commentAt }, thunkAPI) => {
        const res = await PutData(true, '/tasks/update-comment/' + id, { value, commentAt })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskTypeFetch = createAsyncThunk(
    'task/type/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskSummaryList = createAsyncThunk(
    'task/pipeline/summary-list',
    async (thunkAPI) => {
        const res = await GetData(true, '/tasks/summary')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskCandidateDetails = createAsyncThunk(
    'task/candidate/details',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/candidates/list/pipeline/candidate-details/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const listSlice = createSlice({
    name: 'tasksList',
    initialState: {
        taskList: [],
        taskDataSummaryList: [],
        taskTypeList: [],
        countsProgress: [],
        archivedDataCount: [],
        candidateDetails: {},
        countAll: 0,
        isLoading: false,
        candidateDetailLoading: false,
        isTaskInsert: false,
        isTaskUpdate: false,
        isTaskDelete: false,
        isUpdate: false,
        iscommentCountUpdate: false,
        isError: false,
        isSuccess: false,
        isTaskInserted: false,
        isMoved: false,
        isArchive: false,
        message: ''
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.candidateDetailLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.isTaskInserted = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },
        clearTaskState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.isTaskInserted = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },
        clearDetails: (state) => {
            state.candidateDetails = {}
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            getTaskByProgress.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            getTaskByProgress.fulfilled, (state, { payload }) => {
                state.taskList = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            getTaskByProgress.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            archivedData.pending, (state) => {
                state.isLoading = true;
                state.isArchive = false;
            });
        builder.addCase(
            archivedData.fulfilled, (state, { payload }) => {
                state.taskList = payload.result
                state.isArchive = true;
                state.isLoading = false;
            });
        builder.addCase(
            archivedData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addNewTask.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            addNewTask.fulfilled, (state, { payload }) => {
                state.taskList.unshift(payload.result)
                state.isLoading = false;
                state.isSuccess = true;
                state.isTaskInserted = true;
            });
        builder.addCase(
            addNewTask.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateTask.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            updateTask.fulfilled, (state, { payload }) => {
                let mainArray = state.taskList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result[0].id) {
                        return (payload.result[0])
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.isLoading = false;
                state.taskList = finalResult;
            });
        builder.addCase(
            updateTask.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            updateByKey.pending, (state) => {
                state.isTaskUpdate = false
            });
        builder.addCase(
            updateByKey.fulfilled, (state, { payload }) => {
                let mainArray = state.taskList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.taskList = finalResult;
                state.message = payload.message
            });
        builder.addCase(
            updateByKey.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateProgress.pending, (state) => {
            });
        builder.addCase(
            updateProgress.fulfilled, (state, action) => {
                state.isUpdate = true
                state.isMoved = true
                state.taskList = state.taskList.filter((item) => item.id !== action.meta.arg.id)
            });
        builder.addCase(
            updateProgress.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            countAllTask.pending, (state) => {
            });
        builder.addCase(
            countAllTask.fulfilled, (state, { payload }) => {
                state.countAll = payload.result
            });
        builder.addCase(
            countAllTask.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            counts.pending, (state) => {
            });
        builder.addCase(
            counts.fulfilled, (state, { payload }) => {
                state.countsProgress = payload.result
            });
        builder.addCase(
            counts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            archivedCounts.pending, (state) => {

            });
        builder.addCase(
            archivedCounts.fulfilled, (state, { payload }) => {
                state.archivedDataCount = payload.result
                //state.isArchive = true;
            });
        builder.addCase(
            archivedCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            deleteTask.pending, (state) => {
            });
        builder.addCase(
            deleteTask.fulfilled, (state, { payload }) => {
                state.isTaskDelete = true;
                state.isMoved = true;
            });
        builder.addCase(
            deleteTask.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            taskMoveTO.pending, (state) => {
            });
        builder.addCase(
            taskMoveTO.fulfilled, (state, { payload }) => {
                state.isTaskDelete = true;
                state.message = payload.message
                state.isMoved = true;
            });
        builder.addCase(
            taskMoveTO.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            taskMoveToTable.pending, (state) => {
            });
        builder.addCase(
            taskMoveToTable.fulfilled, (state, { payload }) => {
                state.isMoved = true;
                state.message = payload.message
            });
        builder.addCase(
            taskMoveToTable.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateTaskCommentCount.pending, (state) => {
                state.iscommentCountUpdate = false
            });
        builder.addCase(
            updateTaskCommentCount.fulfilled, (state, action) => {
                let mainArray = state.taskList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === action.meta.arg.id) {
                        return ({ ...r, commentCount: action.meta.arg.value, commentAt: action.meta.arg.commentAt })
                    } else {
                        return r
                    }
                })
                state.taskList = finalResult
                state.iscommentCountUpdate = true
            });
        builder.addCase(
            updateTaskCommentCount.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            taskTypeFetch.pending, (state) => {
            });
        builder.addCase(
            taskTypeFetch.fulfilled, (state, { payload }) => {
                state.taskTypeList = payload.result
            });
        builder.addCase(
            taskTypeFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            taskSummaryList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            taskSummaryList.fulfilled, (state, { payload }) => {
                state.taskDataSummaryList = payload.result;
                state.isLoading = false;
            });
        builder.addCase(
            taskSummaryList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            taskCandidateDetails.pending, (state) => {
                state.candidateDetailLoading = true
            });
        builder.addCase(
            taskCandidateDetails.fulfilled, (state, { payload }) => {
                state.candidateDetailLoading = false
                state.candidateDetails = payload.result;
            });
        builder.addCase(
            taskCandidateDetails.rejected, (state, action) => {
                state.candidateDetailLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
    }
});


export const { clearState, clearTaskState, clearDetails } = listSlice.actions;
export default listSlice.reducer;