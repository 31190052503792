import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from "react-redux";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { showSuccess, showError, clearMessage } from "../../../utils/messages/messageSlice";
import { checkintime, clearState } from '../../../../slice/attendance/attendanceSlice'
import SubmitButton from "../../../ui/submitButton";
import DatePicker from './datepicker/DatePicker'
import TimePicker from './timepicker/TimePicker'
import moment from "moment-timezone"

const CheckInModal = ({ handleClose }) => {
    const dispatch = useDispatch();
    const { attendanceList, isSuccess, isUpdate, isLoading, isError, isInsert, msg } = useSelector(state => state.attendance)
    const { userInfo } = useSelector(state => state.auth);

    // let today = new Date();
    // let year = today.getFullYear();
    // let mes = today.getMonth()+1;
    // let dia = today.getDate();
    // let fecha =dia+"/"+dia+"/"+year;
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
   

    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: msg }))
            handleClose();
            setLoading(false);
            setSuccess(true);
            dispatch(clearState())
        }
        if (isInsert) {
            handleClose();
            setLoading(false);
            setSuccess(true);
            setCheckinTime('');
            dispatch(showSuccess({ msg: "Checkin Successfull" }))
            dispatch(clearState())
        }
        if (isUpdate) {
            handleClose();
            setLoading(false);
            setSuccess(true);
            setCheckinTime('');
            dispatch(showSuccess({ msg: "Checkin Successfull" }))
            dispatch(clearState())
        }
    }, [isError, isInsert, isUpdate])

    const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('DD MMM YYYY'))
    const [pickerDueTime, setPickerTime] = useState(new Date())
    
     const [userId, setUserId] = useState(userInfo.id)

    const [todayDate, setTodayDate] = useState(moment(new Date()).format('DD/MM/YYYY'))
    const [checkinTime, setCheckinTime] = useState(moment(new Date()).format('HH:mm'))

    const handleDateChange = (date) => {   
        setSelectedDate(moment(date).format('DD MMM YYYY')); 
        setTodayDate(moment(date).format('DD/MM/YYYY'))
    };
    const handleTimeChange = (date) => {
        setPickerTime(date)
        setCheckinTime(moment(date).format('HH:mm'))
    };

    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (!userId || !todayDate || !checkinTime) {
            dispatch(showError({ msg: 'All fields required' }))
        } else {
            setSuccess(false);
            setLoading(true);
            dispatch(checkintime({ uid: userId, checkinDate: todayDate, checkinTime: checkinTime }))
        }
    }
    return (
        <>
            <Modal className="dk-checkInModal" centered show={true} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Daily Check-In</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-checkInBody">
                        <form action="">
                            <div className="dk-checkInForm">
                                <div className="row">
                                    <div className="col-md-1">
                                        <div className="form-group">
                                            <label htmlFor=""> </label>
                                            <div className="dk-userFilter">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + userInfo.profileImage} className="img-fluid img-circle" alt={`${userInfo.firstName} ${userInfo.lastName}`} title={`${userInfo.firstName} ${userInfo.lastName}`} />
                                                    </Dropdown.Toggle>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">Check-In Date</label>
                                            {/* <label htmlFor="">
                                                <input type="date" name="" id="" className="form-control" onChange={(e) => setTodayDate(e.target.value)} />
                                            </label> */}
                                            <div className="dkg-timepicker-Con">
                                                <DatePicker handleDateChange={handleDateChange}  selectedDate={selectedDate} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label htmlFor="">Check-In Time</label>
                                            {/* <label htmlFor="">
                                                <input type="time" name="" id="" className="form-control" onChange={(e) => setCheckinTime(e.target.value)} />
                                            </label> */}
                                            <div className="dkg-timepicker-Con">
                                                <TimePicker pickerDueTime={pickerDueTime} handleTimeChange={handleTimeChange}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-checkInOutBtn">
                                <SubmitButton className="dkg-create-payment-taskbtn dkg-checkin-btn" txt="Submit" loading={loading} success={success} onClick={handleButtonClick} />
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CheckInModal;
