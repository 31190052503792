import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
const Status = ({ value }) => {
    let bgColor = "";
    let txtColor = "";
    switch (value) {
        case 'Normal':
            txtColor = '#30887e'
            break;

        case 'Urgent':
            txtColor = '#B55355'
            break;

        case 'To qualify':
            txtColor = '#5C737D'
            break;

        case 'On Hold':
            txtColor = '#7f5347'
            break;

        case 'Closed':
            txtColor = '#e2445c'
            break;
    }

    return (
        <>
            <Dropdown.Toggle variant="" style={{ color: txtColor }}> {value}</Dropdown.Toggle>
        </>
    )
}

export default Status;