import React from 'react';
//import NavTabs from "../NavTabs";
import { Link } from "react-router-dom";
import { Tab, Nav } from 'react-bootstrap';
import './kipdash.scss';
import './summary.scss';
import KPIDetails from './Details';
import KpiJobSpecsLeftPanel from './KpiJobSpecsLeftPanel';
import Dropdown from 'react-bootstrap/Dropdown';

const Sendouts = (props) => {
    return (
        <>
            {/* <div className="dk-jobsSpecSubmenuCon">
                <div className="dk-kpiNav">
                    <NavTabs />
                </div>
            </div> */}
            <div className="container-fluid dk-kipdashMainCon">
                <Tab.Container id="left-tabs-example" defaultActiveKey="details">
                    <div className="row">
                        <div className="col-md-12 dk-kpidashMainCon">
                            <div className="d-flex">
                                <KpiJobSpecsLeftPanel />
                                <div className="dk-kpiRightPanel">
                                    <div className="dashsideMenuCon">
                                        <div className="dk-monthList">
                                            <div className="dk-candidateSearch-box">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                                        2022
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#">2021</Dropdown.Item>
                                                        <Dropdown.Item href="#">2020</Dropdown.Item>
                                                        <Dropdown.Item href="#">2019</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <ul>
                                                <li><Link to="#">Jan</Link></li>
                                                <li><Link to="#">Feb</Link></li>
                                                <li><Link to="#">Mar</Link></li>
                                                <li><Link to="#">Apr</Link></li>
                                                <li><Link to="#">May</Link></li>
                                                <li><Link to="#">Jun</Link></li>
                                                <li><Link to="#" className="active">Jul</Link></li>
                                                <li><Link to="#">Aug</Link></li>
                                                <li><Link to="#">Sep</Link></li>
                                                <li><Link to="#">Oct</Link></li>
                                                <li><Link to="#">Nov</Link></li>
                                                <li><Link to="#">Dec</Link></li>
                                            </ul>
                                        </div>
                                        <Nav variant="pills" className="dk-kpidash-nav-pills justify-content-end">
                                            {/* <Nav.Item className="dk-kpidashTab">
                                                <Nav.Link eventKey="details">Details</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="dk-kpidashTab">
                                                <Nav.Link eventKey="third">Add New</Nav.Link>
                                            </Nav.Item> */}
                                        </Nav>
                                    </div>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="details">
                                            <div className="dk-tabPaneCon">
                                                <KPIDetails />
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default Sendouts;