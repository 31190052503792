import React, {useState} from "react";
import { Link } from "react-router-dom";
import  Modal  from "react-bootstrap/modal";
import {  FaUserAlt } from "react-icons/fa";


const UserPopup = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Link to="#" className="dkg-pipeline-usericon"><FaUserAlt onClick ={handleShow}/></Link>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dk-candidateProfile80"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="dk-candModalHeader">
          <Modal.Title
            className="dk-candModalTitle"
            id="example-custom-modal-styling-title"
          >
            <div className="dkg-years-DropdownCon">
              <div className="dkg-clientid">Client ID -  23456</div>
            </div>
              <Link to="#">
                <i className="fa fa-chevron-left mr-2"></i>
              </Link>
              <Link to="#" >
                <i className="fa fa-chevron-right ml-2"></i>
              </Link>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="dk-candProfileModalBody">
          
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserPopup;
