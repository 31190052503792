import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faAngleRight, faAngleLeft, faBars, faPaperclip, faReply, faShare, faTrashAlt, faClock, faArchive, faFolder, faReplyAll, faPaperPlane, faInbox, faExclamationTriangle, faFile, faStar } from '@fortawesome/free-solid-svg-icons';
import Collapse from 'react-bootstrap/Collapse';

const MailboxLeftPanel = (props) => {
    const segment2 = props.type
    const systemUser = props.systemUser;
    const [open, setOpen] = useState(false);
    const [openGroup, setOpenGroup] = useState(false);

    const base_url = systemUser ? '/mailbox/system-user/' + systemUser : '/mailbox/mail';
    
    return (
        <>
            <ul>
                <li>
                    <Link to={`${base_url}/inbox`} className={(segment2 == 'inbox') ? 'active' : ''}><FontAwesomeIcon icon={faInbox} />
                        Inbox
                        <span className="dk-mailboxBadge">{ props.counter.inbox ? props.counter.inbox : '0' }</span>
                    </Link>
                </li>
                <Collapse in={open} className="dk-mailGroup">
                    <div id="example-collapse-text">
                        <Link to="#" onClick={() => setOpenGroup(!openGroup)} aria-controls="example-collapse-text-group" aria-expanded={openGroup}><FontAwesomeIcon icon={faFolder} /> My Mails</Link>
                    </div>
                </Collapse>
                <Collapse in={openGroup} className="dk-mailsGroup-items">
                    <div id="example-collapse-text-group">
                        <div className="dk-mailGroup-list">
                            <li><Link to="#">Lorem ipsum dolor sit amet</Link></li>
                        </div>
                    </div>
                </Collapse>
                <li>
                    <Link to={`${base_url}/sent`}  className={(segment2 == 'sent') ? 'active' : ''}><FontAwesomeIcon icon={faPaperPlane} />Sent
                    </Link>
                </li>
                <li>
                    <Link to={`${base_url}/archive`} className={(segment2 == 'archive') ? 'active' : ''}><FontAwesomeIcon icon={faArchive} /> Archive
                        <span className="dk-mailboxBadge">{ props.counter.archive ? props.counter.archive : '0' }</span>
                    </Link>
                </li>
                <li>
                    <Link to={`${base_url}/trash`} className={(segment2 == 'trash') ? 'active' : ''}><FontAwesomeIcon icon={faTrashAlt} /> Deleted
                        <span className="dk-mailboxBadge">{ props.counter.trash ? props.counter.trash : '0' }</span>
                    </Link>
                </li>
            </ul>
        </>
    )
}

export default MailboxLeftPanel;
