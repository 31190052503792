import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputGroup, FormControl, Tab, Nav } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import SimpleDropDwn from "../summary/SimpleDropDwn";
import JobStatus from "../summary/JobStatus";
import AllCompany from "../summary/AllCompany";
import UserSearchFilter from "../summary/SearchInputDropDwn";
import AirDatepicker from "air-datepicker";
import en from "air-datepicker/locale/en";
import "air-datepicker/air-datepicker.css";
import "../../../addnew/addnewjob.scss";
import UserList from "../../../../../ui/userList/userListByRole";
//import Status from "../../dropdown/status/tableStatus";
//import CompanyDropdown from "../summary/CompanyDropdown";
import Salary from "../salary/Salary";
import Relocation from "../relocation/Relocation";
import Sendouts from "../sendouts/Sendouts";
import Benefits from "../benefits/Benefits";
//import Company from "../company/EditCompany";
import Editjobdetails from "./editjobdetails/Editjobdetails";
import LikesDislikes from "../likes_dislikes/Likes_dislikes";
import Specs from "../specs/Specs";
import Interviews from "../interviews/Interviews";
import Fulljobdetails from "../fulljobdetails/FullDetails";
import AdPlan from "../ad_plan/AdPlan";
import InterviewsDetails from "../interview-details/Interviews";
import EditorComponents from './Editor'
//import EditorNew from '../editor/EditorNew'
import JobSpecTab from '../jobsepec/JobSpecTab'

import { clearState, updateJob } from "../../../../../../slice/jobs/jobsSlice";
import { companyProjectData, companyRoleData } from "../../../../../../slice/companies/clients/clientSlice";
import {
  allJobsSpecCompnay,
  defaultJobsCompnay,
  defaultJobsProject,
  allclientsproject,
  defaultJobsRole,
  allclientsRoletype,
  allclientsInterview,
  defaultInterviewProject
} from "../../../../../../slice/jobSpec/jobSpecSlice";
import ClientinfoTab from "../clientinfo/ClientinfoTab";
import ClientProject from "../clientinfo/ClientProjectTab";
import ClientRoleTab from "../clientinfo/RoletypeTab";
import SendoutTab from "../clientinfo/SendoutTab";
import InterviewsTab from "../clientinfo/InterviewsTab";
import LanguageTab from "../clientinfo/LanguageTab";
import JobOfferTab from "../clientinfo/JobOfferTab";
import RelocationTab from "../clientinfo/RelocationTab";
import PortalTab from "../clientinfo/PortalTab";
import SavedAds from "../saved_ads/SavedAds";

const Jobdetail = ({ jobId, canjobs, company_name, clientid }) => {

  const dispatch = useDispatch();
  const {
    jobsProjectList,
    jobsRoleTypeList,
    jobsLanguageList,
    // jobsLocalityList,
    jobsNoofJobsList,
    // jobsJobProfileList,
    jobStatusList,
    jobsWorkPermitList,
    jobsWorkplaceList,
    jobsWorkdaysList,
    jobsContracttypeList,
    jobsWorkTypeList,
    // jobsExperiencetList,
    // jobsLevelIdList,
    // JobStatusList,
    // jobssInterviewsLit,
    // jobsItskillList,
    // jobskillList,
    jobsIndustryIdList,
    //jobsCurrencyList,
    jobsCountryList,
    jobsCategoryList,
    jobsCityList,
    //jobsCompanyList
  } = useSelector((state) => state.common);

  const {
    //companyProjtList,
    companyList,
    //companyRolList
  } = useSelector(state => state.companies);

  const { isDetailsLoading, jobDetails, isUpdate } = useSelector(
    (state) => state.jobs
  );

  const [data, setData] = useState([]);
  const [newStatus, setNewStatus] = useState('')
  const [taxcal, setTaxCal] = useState(jobDetails ? jobDetails.taxCalculator : '')

  //console.log(companyList)
  //const [status, setStatus] = useState("");
  //const [caseStatus, setCaseStatus] = useState('')
  // const [isRefresh, setIsRefresh] = useState(true);

  // const formRefresh = () => {
  //   setIsRefresh(true);
  // };

  //const [livingCost, setLivingCost] = useState(jobDetails.costOfLiving)

  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setData(jobDetails);
      setNewStatus(jobDetails.status);
      setTaxCal(jobDetails.taxCalculator);
      //setLivingCost(jobDetails.costOfLiving);
      //setStatus(jobDetails.status);
      dispatch(companyProjectData({ name: company_name }))
      dispatch(companyRoleData({ name: company_name }))
    }
  }, [isDetailsLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobDetails);
      setNewStatus(jobDetails.status);
    }
  }, [isUpdate, newStatus]);

  let ids = data.id;

  const updateMyData = (e) => {
    let values = "";
    let columnId = e.target.dataset.columnid;
    if (columnId === "gross_title") {
      values = e.target.dataset.value;
    } else {
      values = e.target.value;
    }

    dispatch(updateJob({ id: ids, key: columnId, value: values }));
  };

  const saveUrl = () => {
    dispatch(updateJob({ id: ids, key: "taxCalculator", value: taxcal }));
  };

  new AirDatepicker("#date1", {
    position: "bottom center",
    locale: en,
    dateFormat: "d MMM yy",
    onSelect({ date, formattedDate, datepicker }) {
      dispatch(updateJob({ id: ids, key: "date1", value: formattedDate }));
    },
  });

  new AirDatepicker("#date2", {
    position: "bottom center",
    locale: en,
    dateFormat: "d MMM yy",
    onSelect({ date, formattedDate, datepicker }) {
      dispatch(updateJob({ id: ids, key: "date2", value: formattedDate }));
    },
  });

  new AirDatepicker("#date3", {
    position: "bottom center",
    locale: en,
    dateFormat: "d MMM yy",
    onSelect({ date, formattedDate, datepicker }) {
      dispatch(updateJob({ id: ids, key: "date3", value: formattedDate }));
    },
  });

  let gross_value = "";
  if (data.gross_title === null) {
    gross_value = "Monthly Gross";
  } else {
    if (data.gross_title === "Monthly Gross") {
      gross_value = "Annual Gross";
    } else {
      gross_value = "Monthly Gross";
    }
  }
  const onItemClick = (id, columnId, value) => {
    dispatch(updateJob({ id, key: columnId, value }));
  };
  const updateProfile = async (e) => {
    //const id = e.target.dataset.id;
    const keys = e.target.dataset.key;
    const values = e.target.dataset.value;
    await dispatch(updateJob({ id: jobId.jobId, key: keys, value: values }));
  };

  const [eventKey, SetEventKey] = useState("dkg-clientinfo-company");

  useEffect(() => {
    if (eventKey) {

      switch (eventKey) {
        case "dkg-clientinfo-company":
          //console.log("company-1")
          dispatch(allJobsSpecCompnay({}));
          dispatch(defaultJobsCompnay({ jobsId: jobId, tbl: "clientinfo_requirnment_categories" }));
          break;
        case "dkg-clientinfo-project":
          console.log("projec tab")
          dispatch(allclientsproject({}))
          dispatch(defaultJobsProject({ jobsId: jobId, tbl: "client_project_categories" }));
          break;

        case "dkg-clientinfo-roletype":
          console.log("role tab")
          dispatch(defaultJobsRole({ jobsId: jobId, tbl: "client_roletype_categories" }));
          dispatch(allclientsRoletype({}));
          break;
        case "dkg-interves":
          console.log("interview tab-1")
          dispatch(defaultInterviewProject({ jobsId: jobId, tbl: "jobs_relationship_interviews" }));
          dispatch(allclientsInterview({}));
          break;
        case "job-offerss":
          console.log("job offers tab")
          break;
        case "dkg-new-relocation":
          console.log("relocaion tab")
          break;
        case "job-whyapply":
          console.log("joblanguage tab")
          break;
        case "live-jobs-faq":
          // dispatch(getCategory({ id: companyDetails.clientId }))
          // dispatch(getOnClickQuesAns({ compid: companyDetails.clientId }))
          // setFaqTab(true);
          break;

      }
    }
  }, [eventKey]);

  return (
    <>
      <div className="row dkg-edit-livejobs-mainRow-23">
        <div className="col-md-12 dkg-edit-livejobs-mainCol-23 pr-0">
          <Tab.Container id="left-tabs-example" defaultActiveKey="EditSummary">
            <div className="row mr-0 ml-0 mainSpecTab dkg-edit-livejobs-maintabCon">
              <div className="col-md-12 col-sm-12 dkg-edit-livejobs-maintabs-pills pl-0 pr-0">
                <Nav variant="pills" className="flex-row">

                  <Nav.Item>
                    <Nav.Link className="text-left" eventKey="EditSummary">
                      OVERVIEW
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="text-left" eventKey="dkg-new-salary-tab">
                      SALARY
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link className="text-left" eventKey="EditJobspec">
                      JOB SPEC
                    </Nav.Link>
                  </Nav.Item>

                </Nav>
              </div>
              <div className="col-md-12 col-12 dkg-edit-livejobs-col-234">
                <Tab.Content className="dkg-edit-livejobs-tabcontent">
                  <Tab.Pane eventKey="EditStatus" className="dkg-edit-jobdetail-tabpane">
                    <div className="dk-ClentViewTabPane mt-3 pl-3 pr-3 summaryTabPane dkg-livejob-status-tabpane  pr-0"
                      style={{ height: "calc(100vh - 94px)" }}
                    >
                      <div className="row dk-Adsbox">
                        <div className="col-md-12 dkg-edit-jobstus-leftcol dkg-job-staus-col pl-0 pr-3" style={{ marginRight: "auto" }}>
                          <div className="dk-suMMformGroupCon pb-2 mb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "125px",
                              }}
                            >
                              AM
                            </label>
                            <div className="dk-AmDropDwnCon" style={{ marginLeft: "4px" }} >
                              <Dropdown className="dk-AmDropDwn">
                                <Dropdown.Toggle
                                  variant=""
                                  className="dk-AmDropDwnToggle"
                                >
                                  <div className="tooltipMain">
                                    <img
                                      title="AM"
                                      style={{
                                        width: "37px",
                                        height: "37px",
                                        border: "2px dotted #999",
                                      }}
                                      src={
                                        data.recAM !== undefined
                                          ? process.env
                                            .REACT_APP_IMG_CLOUD_BASE_URL +
                                          data.recAM.profileImage
                                          : ""
                                      }
                                      className="filterImg"
                                      alt={
                                        data.recAM !== undefined
                                          ? data.recAM.firstName +
                                          " " +
                                          data.recAM.lastName
                                          : ""
                                      }
                                    />
                                    <div className="tooltipText">
                                      {data.recAM !== undefined
                                        ? data.recAM.firstName +
                                        " " +
                                        data.recAM.lastName
                                        : ""}
                                    </div>
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dk-AmDropDwnMenu">
                                  <div className="d-flex">
                                    <UserList
                                      id={data.id}
                                      columnId="rec_am"
                                      onItemClick={onItemClick}
                                    />
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>

                          </div>
                          <div className="dk-suMMformGroupCon pb-2 mb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "125px",
                              }}
                            >
                              RC1
                            </label>
                            {
                              data.assignWithjob !== undefined
                                ?
                                data.assignWithjob.map(
                                  (recdata, index) => (
                                    <div
                                      className="thridBox"
                                      style={{
                                        paddingLeft: "0.30rem",
                                      }}
                                      key={recdata.id}
                                    >
                                      <div
                                        title="RC"
                                        className="AmImgConDrop"
                                      >
                                        <UserSearchFilter
                                          job_id={recdata.job_id}
                                          recImg={
                                            process.env
                                              .REACT_APP_IMG_CLOUD_BASE_URL +
                                            recdata.userInfo
                                              .profileImage
                                          }
                                          recName={
                                            recdata.userInfo
                                              .firstName +
                                            recdata.userInfo.lastName
                                          }
                                          rcList={data.assignWithjob}
                                        />
                                      </div>
                                    </div>
                                  )
                                )
                                : null}

                            {data.assignWithjob !== undefined &&
                              data.assignWithjob.length === 0 ? (
                              <div
                                className="thridBox"
                                style={{ paddingLeft: "0.30rem" }}
                                key={data.id}
                              >
                                <div
                                  title="RC"
                                  className="AmImgConDrop"
                                >
                                  <UserSearchFilter
                                    job_id={data.id}
                                    recImg={
                                      process.env
                                        .REACT_APP_IMG_CLOUD_BASE_URL +
                                      "/users/images/user.png"
                                    }
                                    recName=""
                                    rcList={data.assignWithjob}
                                  />
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="dk-suMMformGroupCon pb-2 mb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "125px",
                              }}
                            >
                              RC2
                            </label>
                            <div
                              className="dk-AmDropDwnCon"
                              style={{ marginLeft: "4px" }}
                            >
                              <Dropdown className="dk-AmDropDwn">
                                <Dropdown.Toggle
                                  variant=""
                                  className="dk-AmDropDwnToggle"
                                >
                                  <div className="tooltipMain">
                                    <img
                                      title="RA"
                                      style={{
                                        width: "37px",
                                        height: "37px",
                                        border: "2px dotted #999",
                                      }}
                                      src={
                                        data.recRA !== undefined
                                          ? process.env
                                            .REACT_APP_IMG_CLOUD_BASE_URL +
                                          data.recRA.profileImage
                                          : ""
                                      }
                                      className="filterImg"
                                      alt={
                                        data.recRA !== undefined
                                          ? data.recRA.firstName +
                                          " " +
                                          data.recRA.lastName
                                          : ""
                                      }
                                    />
                                    <div className="tooltipText">
                                      {data.recRA !== undefined
                                        ? data.recRA.firstName +
                                        " " +
                                        data.recRA.lastName
                                        : ""}
                                    </div>
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dk-AmDropDwnMenu">
                                  <div className="d-flex">
                                    <UserList
                                      id={data.id}
                                      columnId="rec_ra"
                                      onItemClick={onItemClick}
                                    />
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                          <div className="dk-suMMformGroupCon pb-2 mb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "125px",
                              }}
                            >
                              RC3
                            </label>
                            <div
                              className="dk-AmDropDwnCon"
                              style={{ marginLeft: "4px" }}
                            >
                              <Dropdown className="dk-AmDropDwn">
                                <Dropdown.Toggle
                                  variant=""
                                  className="dk-AmDropDwnToggle"
                                >
                                  <div className="tooltipMain">
                                    <img
                                      title="RA"
                                      style={{
                                        width: "37px",
                                        height: "37px",
                                        border: "2px dotted #999",
                                      }}
                                      src={
                                        data.rec3rc !== undefined
                                          ? process.env
                                            .REACT_APP_IMG_CLOUD_BASE_URL +
                                          data.rec3rc.profileImage
                                          : ""
                                      }
                                      className="filterImg"
                                      alt={
                                        data.rec3rc !== undefined
                                          ? data.rec3rc.firstName +
                                          " " +
                                          data.rec3rc.lastName
                                          : ""
                                      }
                                    />
                                    <div className="tooltipText">
                                      {data.rec3rc !== undefined
                                        ? data.rec3rc.firstName +
                                        " " +
                                        data.rec3rc.lastName
                                        : ""}
                                    </div>
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dk-AmDropDwnMenu">
                                  <div className="d-flex">
                                    <UserList
                                      id={data.id}
                                      columnId="rc3"
                                      onItemClick={onItemClick}
                                    />
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                          <div className="dk-suMMformGroupCon pb-2 mb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "125px",
                              }}
                            >
                              Job ID
                            </label>

                            <FormControl
                              style={{
                                border: "1px solid #ccc",
                                borderRight: "0px",
                                height:
                                  "calc(1.5em + .75rem + 6px)",
                                backgroundColor: "#fff"
                              }}
                              aria-label="Small"
                              aria-describedby="inputGroup-sizing-sm"
                              // onBlur={updateMyData}
                              // data-columnid="fee"
                              defaultValue={data.job_id}
                              readOnly
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2 mb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "125px",
                              }}
                            >
                              Per Hire
                            </label>
                            <InputGroup
                              size="sm"
                              className="dk-inputGrp"
                            >
                              <FormControl
                                style={{
                                  border: "1px solid #ccc",
                                  borderRight: "0px",
                                  height:
                                    "calc(1.5em + .75rem + 6px)",
                                }}
                                aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"
                                onBlur={updateMyData}
                                data-columnid="fee"
                                defaultValue={data.fee}
                              />
                              <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-sm">
                                  <i className="fas fa-euro-sign"></i>
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                            </InputGroup>
                          </div>
                        </div>

                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="EditAbdsPlan" className="dkg-edit-jobdetail-tabpane">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div
                          className="col-md-12 interviewsTable mt-3 pl-0"
                          style={{ height: "calc(100vh - 94px)" }}
                        >
                          <Editjobdetails jobId={jobId} />

                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="dkg-new-salary-tab" className="dkg-edit-jobdetail-tabpane">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="dkg-new-lj-salary"
                    >
                      <div
                        className="col-md-12 dkg-edit-jobdetail-tabpaneCon"
                        style={{ height: "calc(100vh - 135px)" }}
                      >
                        <div className="row dkg-edit-livejobs-adsboxRow">
                          <div className="col-md-12 dkg-edit-livejobs-adsboxCol">
                            <div className="row dkg-edit-livejobs-adsboxRow-234">
                              <div className="col-md-2 col-sm-2 dkg-edit-livejobs-sidemenu pl-0 pr-0">
                                <Nav variant="d-flex nav nav-pills " className="d-flex dkg-edit-adsbox-navpills">
                                  <Nav.Item className="dkg-edit-adsbox-navitem">
                                    <Nav.Link eventKey="dkg-new-lj-salary" className="dkg-edit-adsbox-nav-link">Salary</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item className="dkg-edit-adsbox-navitem">
                                    <Nav.Link eventKey="dkg-new-lj-benfits" className="dkg-edit-adsbox-nav-link">Job Benefits</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item className="dkg-edit-adsbox-navitem">
                                    <Nav.Link eventKey="dkg-new-lj-taxcalculator" className="dkg-edit-adsbox-nav-link">Tax Calculator</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item className="dkg-edit-adsbox-navitem">
                                    <Nav.Link eventKey="dkg-new-lj-negotiation" className="dkg-edit-adsbox-nav-link">Negotiation</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item className="dkg-edit-adsbox-navitem">
                                    <Nav.Link eventKey="dkg-new-lj-costof-living" className="dkg-edit-adsbox-nav-link">Cost of living</Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </div>
                              <div className="col-md-10 col-sm-10 dkg-editjob-adsboxRightSide pl-0 pr-0">
                                <Tab.Content className="dkg-editjob-adsbox-jobcontent">
                                  <Tab.Pane eventKey="dkg-new-lj-salary" className="dkg-editjob-adsbox-tabpane">
                                    <div
                                      className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                      style={{ height: "calc(100vh - 189px)" }}>
                                      <div className="row dk-Adsbox">
                                        <div className="col-md-12">
                                          <Salary jobId={jobId} />

                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="dkg-new-lj-benfits" className="dkg-editjob-adsbox-tabpane">
                                    <div
                                      className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                      style={{ height: "calc(100vh - 189px)" }}>
                                      <div className="row dk-Adsbox">
                                        <div className="col-md-12">
                                          <Benefits jobId={jobId} />

                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="dkg-new-lj-taxcalculator" className="dkg-taxcalculator-tabpane dkg-editjob-adsbox-tabpane">
                                    <div className="dkg-salary-tab-box">
                                      <div className="dkg-salary-tab-header d-none">
                                        <div className="dkg-salary-header-title">Income Tax calculator</div>
                                      </div>

                                      <div className="dkg-salary-tabbody">
                                        <div className="dkg-income-inputgrp">
                                          <input type="text" name="url" id="incometax" className="dkg-input-url form-control" value={taxcal} onChange={(e) => setTaxCal(e.target.value)} />
                                          <button className="btn dkg-open-url-btn" onClick={saveUrl}>Save Url</button>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="dkg-new-lj-negotiation" className="dkg-editjob-adsbox-tabpane">
                                    <div
                                      className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                      style={{ height: "calc(100vh - 189px)" }}>

                                      <div className="dkg-interviewnotes-editor12">
                                        <EditorComponents jobId={jobId} name='negotiation' details={data.negotiation} />
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="dkg-new-lj-costof-living" className="dkg-editjob-adsbox-tabpane">
                                    <div
                                      className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                      style={{ height: "calc(100vh - 189px)" }}>

                                      <div className="dkg-interviewnotes-editor12">
                                        <EditorComponents jobId={jobId} name='costOfLiving' details={data.costOfLiving} />
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="dkg-benefits-tab" className="dkg-edit-jobdetail-tabpane">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div className="col-md-12 interviewsTable pl-0"
                          style={{ height: "calc(100vh - 94px)" }}>
                          <Benefits jobId={jobId} />
                        </div>
                      </div>
                    </div>

                  </Tab.Pane>
                  <Tab.Pane eventKey="dkg-client-info-tab" className="dkg-edit-jobdetail-tabpane">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div
                          className="col-md-12 interviewsTable pl-0"
                          style={{ height: "calc(100vh - 94px)" }}
                        >
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="dkg-clientinfo-company"
                          >
                            <div
                              className="col-md-12 interviewsTable pl-0"
                              style={{ height: "calc(100vh - 135px)" }}
                            >
                              <div className="row dk-Adsbox dkg-livejobs-adsboxRow">
                                <div className="col-md-12 dkg-livejobs-adsboxCol">
                                  <div className="row samplead ad-description dkg-livejobs-adsboxRow-234">
                                    <div className="col-md-2 col-sm-2 specsLeftTab dkg-livejobs-adsboxleftSide pl-0 pr-0">
                                      <Nav variant="d-flex edit-header nav nav-pills dkg-livejobs-adsbox-navpills" className="d-flex edit-header" onSelect={(selectedKey) => SetEventKey(selectedKey)}>
                                        <Nav.Item>
                                          <Nav.Link eventKey="dkg-clientinfo-company">Company</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="dkg-clientinfo-project">Project</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="dkg-clientinfo-roletype">Role Type1</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="dkg-clientinfo-language">Language</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="dkg-clientinfo-sendout">Sendout</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="dkg-clientinfo-interview">Interviews</Nav.Link>
                                        </Nav.Item>


                                        <Nav.Item>
                                          <Nav.Link eventKey="dkg-clientinfo-joboffers">Job Offers</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="dkg-applied-relocations">Relocations</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="dkg-applied-portals">Portals</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="dkg-applied-faq">FAQ</Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </div>
                                    <div className="col-md-10 col-sm-10 dkg-livejobs-adsboxRightSide specsLeftTab pl-0 pr-0">
                                      <Tab.Content>
                                        <Tab.Pane eventKey="dkg-clientinfo-company">
                                          <div
                                            className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane" style={{ height: "calc(100vh - 189px)" }}>
                                            <ClientinfoTab />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-clientinfo-project">
                                          <div
                                            className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                            style={{ height: "calc(100vh - 189px)" }}>
                                            <ClientProject />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-clientinfo-roletype" className="dkg-taxcalculator-tabpane">
                                          <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                                            <ClientRoleTab />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-clientinfo-sendout" className="dkg-taxcalculator-tabpane">
                                          <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                                            <SendoutTab />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-clientinfo-language" className="dkg-taxcalculator-tabpane">
                                          <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                                            <LanguageTab />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-clientinfo-interview" className="dkg-taxcalculator-tabpane">
                                          <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                                            <InterviewsTab />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-clientinfo-joboffers">
                                          <div
                                            className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                            style={{ height: "calc(100vh - 189px)" }}>
                                            <JobOfferTab />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-applied-relocations">
                                          <div
                                            className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                            style={{ height: "calc(100vh - 189px)" }}>
                                            <RelocationTab />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-applied-portals">
                                          <div
                                            className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                            style={{ height: "calc(100vh - 189px)" }}>
                                            <PortalTab />
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-applied-faq">
                                          <div
                                            className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                            style={{ height: "calc(100vh - 189px)" }}>
                                            <ClientinfoTab />
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="dkg-new-applyjob-tab" className="dkg-edit-jobdetail-tabpane">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div
                          className="col-md-12 interviewsTable pl-0"
                          style={{ height: "calc(100vh - 94px)" }}
                        >
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="dkg-clientinfo-instruction"
                          >
                            <div
                              className="col-md-12 interviewsTable pl-0"
                              style={{ height: "calc(100vh - 135px)" }}
                            >
                              <div className="row dk-Adsbox dkg-livejobs-adsboxRow">
                                <div className="col-md-12 dkg-livejobs-adsboxCol">
                                  <div className="row samplead ad-description dkg-livejobs-adsboxRow-234">
                                    <div className="col-md-2 col-sm-2 specsLeftTab dkg-livejobs-adsboxleftSide pl-0 pr-0">
                                      <Nav variant="d-flex edit-header nav nav-pills dkg-livejobs-adsbox-navpills" className="d-flex edit-header">
                                        <Nav.Item>
                                          <Nav.Link eventKey="dkg-clientinfo-instruction">Instructions</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="dkg-clientinfo-confirmation">Confirmation</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="dkg-clientinfo-consents">Consents</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="dkg-clientinfo-questions">Questions</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="dkg-clientinfo-contact-details">Contact Details</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="dkg-applied-confrimation">Applied Confirmation</Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </div>
                                    <div className="col-md-10 col-sm-10 dkg-livejobs-adsboxRightSide specsLeftTab pl-0 pr-0">
                                      <Tab.Content>
                                        <Tab.Pane eventKey="dkg-clientinfo-instruction">
                                          <div
                                            className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                            style={{ height: "calc(100vh - 189px)" }}>
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-new-lj-benfits">
                                          <div
                                            className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                            style={{ height: "calc(100vh - 189px)" }}>
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-new-lj-taxcalculator" className="dkg-taxcalculator-tabpane">
                                          <div className="dkg-salary-tab-box">

                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-clientinfo-questions" className="dkg-taxcalculator-tabpane">
                                          <div className="dkg-salary-tab-box">

                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dkg-new-lj-costof-living">
                                          <div
                                            className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                            style={{ height: "calc(100vh - 189px)" }}>
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="EditSummary" className="dkg-edit-jobdetail-tabpane">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="Details"
                    >
                      <div
                        className="col-md-12 dkg-edit-jobdetail-tabpaneCon"
                        style={{ height: "calc(100vh - 135px)" }}
                      >
                        <div className="row dkg-edit-livejobs-adsboxRow">
                          <div className="col-md-12 dkg-edit-livejobs-adsboxCol">
                            <div className="row dkg-edit-livejobs-adsboxRow-234">
                              <div className="col-md-2 col-sm-2 dkg-edit-livejobs-sidemenu pl-0 pr-0">
                                <Nav variant="d-flex nav nav-pills" className="d-flex dkg-edit-adsbox-navpills">
                                  <Nav.Item className="dkg-edit-adsbox-navitem">
                                    <Nav.Link eventKey="Details" className="dkg-edit-adsbox-nav-link">Job Spec Summary</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item className="dkg-edit-adsbox-navitem">
                                    <Nav.Link eventKey="Sendouts" className="dkg-edit-adsbox-nav-link">Requirements</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item className="dkg-edit-adsbox-navitem">
                                    <Nav.Link eventKey="hiring-process" className="dkg-edit-adsbox-nav-link">Hiring Process</Nav.Link>
                                  </Nav.Item>
                                  {/* <Nav.Item>
                                    <Nav.Link eventKey="Salary">Salary</Nav.Link>
                                  </Nav.Item> */}
                                  {/* <Nav.Item>
                                    <Nav.Link eventKey="Benefits">Benefits</Nav.Link>
                                  </Nav.Item> */}
                                  <Nav.Item className="dkg-edit-adsbox-navitem">
                                    <Nav.Link eventKey="Relocation" className="dkg-edit-adsbox-nav-link">Relocation</Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </div>
                              <div className="col-md-10 col-sm-10 dkg-editjob-adsboxRightSide pl-0 pr-0">
                                <Tab.Content className="dkg-editjob-adsbox-jobcontent">
                                  <Tab.Pane eventKey="Details" className="dkg-editjob-adsbox-tabpane">
                                    <div
                                      className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                      style={{ height: "calc(100vh - 186px)", overflowY: "auto", overflowX: "hidden" }}
                                    >
                                      <div className="row dk-Adsbox">
                                        <div className="col-md-12">
                                          <div
                                            className="row dk-suMMLeftCon border-0"
                                            style={{
                                              marginTop: "0",
                                              height: "inherit",
                                              overflowY: "inherit"
                                            }}
                                          >
                                            <div className="col-md-12 col-sm-12">
                                              <div className="dk-topinputGroup">
                                                <InputGroup size="sm" className="mb-3">
                                                  <InputGroup.Text id="inputGroup-sizing-sm" style={{ minWidth: "125px", backgroundColor: "#eaeaea" }}>
                                                    Job Spec Title
                                                  </InputGroup.Text>
                                                  <FormControl
                                                    aria-label="jobtitle"
                                                    className="text-left"
                                                    defaultValue={data.name}
                                                    onBlur={updateMyData}
                                                    data-columnid="name"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                  />
                                                </InputGroup>
                                              </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  Job Status
                                                </label>
                                                <div className="dk-statusDrpDwnCon">
                                                  {/* <SimpleDropDwn
                                                    categoryList={jobStatusList}
                                                    text={data.status}
                                                    columnId="status"
                                                  /> */}
                                                  <JobStatus caseStatus={newStatus} list={jobStatusList} jobId={jobId} updateStatus={updateProfile} column="status" />

                                                </div>
                                              </div>
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  Total Jobs
                                                </label>

                                                <SimpleDropDwn
                                                  categoryList={jobsNoofJobsList}
                                                  text={data.no_of_jobs}
                                                  columnId="no_of_jobs"
                                                />
                                              </div>
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  Industry
                                                </label>
                                                <SimpleDropDwn
                                                  categoryList={jobsIndustryIdList}
                                                  text={data.industry}
                                                  columnId="industry"
                                                />
                                              </div>
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  Company
                                                </label>
                                                <AllCompany
                                                  categoryList={companyList}
                                                  text={data.company}
                                                  columnId="company"
                                                />


                                              </div>
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  Category
                                                </label>
                                                <SimpleDropDwn
                                                  categoryList={jobsCategoryList}
                                                  text={data.job_category}
                                                  columnId="job_category"
                                                />
                                              </div>
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  Country
                                                </label>
                                                <SimpleDropDwn
                                                  categoryList={jobsCountryList}
                                                  text={data.job_country}
                                                  columnId="job_country"
                                                />
                                              </div>
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  City/Town
                                                </label>
                                                <SimpleDropDwn
                                                  categoryList={jobsCityList}
                                                  text={data.location}
                                                  columnId="location"
                                                />
                                              </div>
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  Project
                                                </label>
                                                <SimpleDropDwn
                                                  categoryList={jobsProjectList}
                                                  text={data.project}
                                                  columnId="project"
                                                />
                                              </div>
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  Role Type
                                                </label>


                                                {/* <CompanyDropdown
                                                    categoryList={companyRolList}
                                                    text={data.role}
                                                    columnId="role"
                                                  /> */}

                                                <SimpleDropDwn
                                                  categoryList={jobsRoleTypeList}
                                                  text={data.role}
                                                  columnId="role"
                                                />
                                              </div>
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  Language
                                                </label>
                                                <SimpleDropDwn
                                                  categoryList={jobsLanguageList}
                                                  text={data.lang}
                                                  columnId="lang"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">

                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  Contract
                                                </label>

                                                <SimpleDropDwn
                                                  categoryList={jobsContracttypeList}
                                                  text={data.contract}
                                                  columnId="contract"
                                                />
                                              </div>
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                  className="relocationlabel"
                                                >
                                                  Work Permit
                                                </label>

                                                <SimpleDropDwn
                                                  categoryList={jobsWorkPermitList}
                                                  text={data.work_permit}
                                                  columnId="work_permit"
                                                />
                                              </div>
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  Work From
                                                </label>
                                                <SimpleDropDwn
                                                  categoryList={jobsWorkplaceList}
                                                  text={data.workplace}
                                                  columnId="workplace"
                                                />
                                              </div>
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  Work Type
                                                </label>

                                                <SimpleDropDwn
                                                  categoryList={jobsWorkTypeList}
                                                  text={data.worktype}
                                                  columnId="worktype"
                                                />
                                              </div>
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  Work Days
                                                </label>

                                                <SimpleDropDwn
                                                  categoryList={jobsWorkdaysList}
                                                  text={data.workdays}
                                                  columnId="workdays"
                                                />
                                              </div>
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  Work Hours
                                                </label>

                                                <input
                                                  type="text"
                                                  style={{
                                                    border: "1px solid #ccc",
                                                    height:
                                                      "calc(1.5em + .75rem + 6px)",
                                                  }}
                                                  defaultValue={data.shifts_work}
                                                  onBlur={updateMyData}
                                                  data-columnid="shifts_work"
                                                  className="form-control"
                                                />
                                              </div>
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  Start Date 1
                                                </label>

                                                <input
                                                  type="text"
                                                  style={{
                                                    border: "1px solid #ccc",
                                                    borderRight: "0px",
                                                    height:
                                                      "calc(1.5em + .75rem + 6px)",
                                                  }}
                                                  defaultValue={data.date1}
                                                  onBlur={updateMyData}
                                                  data-columnid="date1"
                                                  className="form-control"
                                                />

                                              </div>
                                              <div className="dk-suMMformGroupCon pb-2">
                                                <label
                                                  style={{
                                                    background: "#eaeaea",
                                                    border: "1px solid #ddd",
                                                    borderRight: "none",
                                                    minWidth: "125px",
                                                  }}
                                                >
                                                  Start Date 2
                                                </label>

                                                <input
                                                  type="text"
                                                  style={{
                                                    border: "1px solid #ccc",
                                                    borderRight: "0px",
                                                    height:
                                                      "calc(1.5em + .75rem + 6px)",
                                                  }}
                                                  defaultValue={data.date2}
                                                  onBlur={updateMyData}
                                                  data-columnid="date2"
                                                  className="form-control"
                                                />

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="Benefits" className="dkg-editjob-adsbox-tabpane">
                                    <div
                                      className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                      style={{ height: "calc(100vh - 189px)" }}>
                                      <div className="row dk-Adsbox">
                                        <div className="col-md-12">
                                          <Benefits jobId={jobId} />

                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="Salary" className="dkg-editjob-adsbox-tabpane">
                                    <div
                                      className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                      style={{ height: "calc(100vh - 189px)" }}
                                    >
                                      <Salary jobId={jobId} />
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="Relocation" className="dkg-editjob-adsbox-tabpane">
                                    <div
                                      className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                      style={{ height: "calc(100vh - 189px)" }}
                                    >
                                      <Relocation jobId={jobId} />
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="Sendouts" className="dkg-editjob-adsbox-tabpane">
                                    <div
                                      className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                      style={{ height: "calc(100vh - 189px)" }}
                                    >
                                      <Sendouts jobId={jobId} />
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="hiring-process" className="dkg-editjob-adsbox-tabpane">
                                    <div
                                      className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                                      style={{ height: "calc(100vh - 189px)" }}
                                    >
                                      <InterviewsDetails jobId={jobId} />
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="EditCandidate" className="dkg-edit-jobdetail-tabpane">
                    <div
                      className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                      style={{ height: "calc(100vh - 94px)" }}
                    >
                      <LikesDislikes jobId={jobId} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="EditInstructions" className="dkg-edit-jobdetail-tabpane">
                    <div
                      className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                      style={{ height: "calc(100vh - 94px)" }}
                    >
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="EditSendOuts" className="dkg-edit-jobdetail-tabpane">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div
                          className="col-md-12 interviewsTable mt-3 pl-0"
                          style={{ height: "calc(100vh - 94px)" }}
                        >
                          {/* <div
                      className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                      style={{ height: "calc(100vh - 120px)" }}
                    > */}
                          <Specs jobId={jobId} />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="EditInterviews" className="dkg-edit-jobdetail-tabpane">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div
                          className="col-md-12 interviewsTable mt-3 pl-0"
                          style={{ height: "calc(100vh - 94px)" }}
                        >
                          <Interviews jobId={jobId} />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="EditJobFiles" className="dkg-edit-jobdetail-tabpane">
                    <div
                      className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane"
                      style={{ height: "calc(100vh - 94px)" }}
                    >
                      <Fulljobdetails jobId={jobId} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="EditAdsPlan" className="dkg-edit-jobdetail-tabpane">
                    <div className="dk-ClentViewTabPane pl-3 pr-3 summaryTabPane">
                      <div className="row dk-Adsbox">
                        <div
                          className="col-md-12 interviewsTable mt-3 pl-0"
                          style={{ height: "calc(100vh - 94px)" }}
                        >
                          <AdPlan jobId={jobId} />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="EditJobspec" className="dkg-edit-jobdetail-tabpane">
                    <JobSpecTab jobId={jobId} canjobs={canjobs} clientid={clientid} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="saved-ads" className="dkg-savedads-tabpane dkg-edit-jobdetail-tabpane">
                    <SavedAds />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default Jobdetail;
