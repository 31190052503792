import React, { useEffect } from "react";
//import { mailshotlist } from '../../../../slice/mailshots/mailshotsSlice';
import { useDispatch, useSelector } from "react-redux";
import { showError } from '../../../../slice/utils/message/messageSlice'
import { BasicTable } from "./BasicTable";
import './mailshot.scss'

const DkgTeam = () => {
    const dispatch = useDispatch();
    const { mailList } = useSelector(state => state.mailshots)


    return (

        <React.Fragment>
            <div className="dk-dbMailShot">
                {
                    (mailList && mailList.length > -1) ? <BasicTable mailList={mailList} /> : null
                }
            </div>
        </React.Fragment>
    )
}
export default DkgTeam;