import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../jobspecstatus/dropdownFilter.scss";

const StatusFilter = ({ list, selectOtherFilter, jobStatusFilter, tableType }) => {
  return (
    <React.Fragment>
      <DropdownButton
        className="StatusdropdownFilter"
        id="dropdown-basic-button"
        title={jobStatusFilter}
      >
        <Dropdown.Item
          href="#"
          style={{ backgroundColor: "#30887e", color: "#fff" }}
          data-type="status"
          data-value="Active"
          onClick={selectOtherFilter}
        >
          Active
          <span
            className="dropdown-filter-count"
            data-type="status"
            data-value="Active"
            onClick={selectOtherFilter}
          >
            {
              list.filter(function (val) {
                return val.original.status === "Active";
              }).length
            }
          </span>
        </Dropdown.Item>
        <Dropdown.Item
          href="#"
          style={{ backgroundColor: "#d58b5d", color: "#fff" }}
          data-type="status"
          data-value="Qualify"
          onClick={selectOtherFilter}
        >
          Qualify
          <span
            className="dropdown-filter-count"
            data-type="status"
            data-value="Qualify"
            onClick={selectOtherFilter}
          >
            {
              list.filter(function (val) {
                return val.original.status === "Qualify";
              }).length
            }
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          href="#"
          style={{ backgroundColor: "#7f5347", color: "#fff" }}
          data-type="status"
          data-value="On Hold"
          onClick={selectOtherFilter}
        >
          On Hold
          <span
            className="dropdown-filter-count"
            data-type="status"
            data-value="On Hold"
            onClick={selectOtherFilter}
          >
            {
              list.filter(function (val) {
                return val.original.status === "On Hold";
              }).length
            }
          </span>
        </Dropdown.Item>
        {
          tableType != "LiveJobs" ?
            <>
              <Dropdown.Item
                href="#"
                style={{ backgroundColor: "#A84D4D", color: "#fff" }}
                data-type="status"
                data-value="Inactive"
                onClick={selectOtherFilter}
              >
                Inactive
                <span
                  className="dropdown-filter-count"
                  data-type="status"
                  data-value="Inactive"
                  onClick={selectOtherFilter}
                >
                  {
                    list.filter(function (val) {
                      return val.original.status === "Inactive";
                    }).length
                  }
                </span>
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                style={{ backgroundColor: "#e2445c", color: "#fff" }}
                data-type="status"
                data-value="Closed"
                onClick={selectOtherFilter}
              >
                Closed
                <span
                  className="dropdown-filter-count"
                  data-type="status"
                  data-value="Closed"
                  onClick={selectOtherFilter}
                >
                  {
                    list.filter(function (val) {
                      return val.original.status === "Closed";
                    }).length
                  }
                </span>
              </Dropdown.Item>
            </>
            : null
        }


      </DropdownButton>
    </React.Fragment>
  );
};

export default StatusFilter;
