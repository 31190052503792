import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const ManagementTraining = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalAdminTrainingMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/dkglobaladmin/managementadmintraining/managementdashboard" className={(segment3 == 'managementdashboard') ? 'active' : ''}>Dashboard</Link></li>
                        <li><Link to="/dkglobaladmin/admintraining/management" className={`${(segment3 == 'management') ? 'active' : ''}`}>Management</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default ManagementTraining;