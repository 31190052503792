import React from 'react';
import { Link } from 'react-router-dom';
import './workflow.scss';
// import Deepak from "../../../../../../../assets/images/deepak.png";

const Workflow = () => {
    return (
        <>
            <div className="dk-candWorkFlowMainCon">
                <div className="dk-workFlowTopHead">
                    <div className="dk-LeftHead">
                        <Link to="#">Workflow 1</Link>
                    </div>
                    <div className="dk-CentreHead">
                        <h2 className="title">Profile Create on -  28 Febuary 2023</h2>
                    </div>
                    <div className="dk-RightHead">
                        <Link to="#"><i className="fas fa-plus mr-2"></i> Add Workflow</Link>
                    </div>
                </div>
                <div className="dk-workFlowTableCon">
                    <table className="table dk-workFlowTable dkworkflowTable-main">
                        <thead>
                            <tr>
                                <th>Updated On</th>
                                <th>Case Status</th>
                                <th style={{ backgroundColor: "#5c737d" }}>New Case</th>
                                <th style={{ backgroundColor: "#715f8f" }}>Selected</th>
                                <th style={{ backgroundColor: "#57496d" }}>Qualifying</th>
                                <th style={{ backgroundColor: "#316a67" }}>Interviews</th>
                                <th style={{ backgroundColor: "#629bd3" }}>Job Offer</th>
                                <th style={{ backgroundColor: "#4768b0" }}>Joined</th>
                                <th style={{ backgroundColor: "#5c737d" }}>Case Stages</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td>08-Oct,06:46</td>
                                <td style={{ background: '#5c737d' }}>
                                    <span className="DKGProcess">New Case</span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>To be Updated</td>
                            </tr>
                            <tr>

                                <td>08-Oct,06:46</td>
                                <td style={{ background: '#715f8f' }}>
                                    <span className="DKGProcess">Selected</span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>To be Updated</td>
                            </tr>
                            <tr>

                                <td>08-Oct,06:46</td>
                                <td style={{ background: '#57496d' }}>
                                    <span className="DKGProcess">Qualifying</span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>To be Updated</td>
                            </tr>
                            <tr>

                                <td>08-Oct,06:46</td>
                                <td style={{ background: '#316a67' }}>
                                    <span className="DKGProcess">Interviews</span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>To be Updated</td>
                            </tr>
                            <tr>
                                <td>08-Oct,06:46</td>
                                <td style={{ background: '#629bd3' }}>
                                    <span className="DKGProcess">Job Offer</span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>To be Updated</td>
                            </tr>

                            <tr>

                                <td>08-Oct,06:46</td>
                                <td style={{ background: '#4768b0' }}>
                                    <span className="DKGProcess">Joined</span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>To be Updated</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Workflow;
