import React from 'react';
import './screen-requirements.scss'

const Requirement = () => {
    return (
        <>
            <div className='dkg-requriments-screen-mainCon'>
                <div className='row m-0 dkg-requriments-blockCon'>
                    <div className='col-md-6 pr-4 dkg-requriments-blockCols dkg-left-requriments-boxCon'>
                        <div className='dkg-requriments-headerCon'>
                            <div className='dkg-requriments-title'>STANDARD - JOB REQUIREMENTS</div>
                        </div>
                        <div className='dkg-requriments-boxCon'>
                            <div className='dkg-requriments-tableCon'>
                                <table className='table table-bordered dkg-requriments-table'>
                                    <tbody>
                                        <tr>
                                            <td>1st Language</td>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2nd Language</td>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Qualifications</td>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Work Experience</td>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td >WORK LOCATION</td>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Interview Modes</td>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>RELOCATION PACK</td>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>CANDIDATE FROM</td>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>JOB STARTING DATES</td>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 pl-4 dkg-requriments-blockCols dkg-right-requriments-boxCon'>
                        <div className='dkg-requriments-headerCon'>
                            <div className='dkg-requriments-title dkg-jobreq-right-title'>SPECIAL - JOB REQUIREMENTS</div>
                        </div>
                        <div className='dkg-requriments-boxCon'>
                            <div className='dkg-requriments-tableCon'>
                                <table className='table table-bordered dkg-jobrequriments-table-2'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="text" className="form-control" defaultValue={""} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Requirement