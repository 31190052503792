import React from 'react';
import { Link } from "react-router-dom";

const RequestLeftPanel = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    return (
        <>
            <div className="dk-workPlanLeftPanel">
                <ul>
                    <li><Link to="/workplan/requests/summary" className={`${(segment3 === 'summary') ? 'active' : ''}`}>Overview</Link></li>
                    <li><Link to="/workplan/requests/recruiter" className={`${(segment3 === 'recruiter') ? 'active' : ''}`}>Management</Link></li>
                    <li><Link to="/workplan/requests/rec-admin" className={`${(segment3 === 'rec-admin') ? 'active' : ''}`}>Recruitment</Link></li>
                    <li><Link to="/workplan/requests/account-manager" className={`${(segment3 === 'account-manager') ? 'active' : ''}`}>Accounting</Link></li>
                    <li><Link to="/workplan/requests/dkg-admin" className={`${(segment3 === 'dkg-admin') ? 'active' : ''}`}>Vendrors</Link></li>
                    <li><Link to="#/workplan/requests/accounts" className={`${(segment3 === 'accounts') ? 'active' : ''}`}>Training</Link></li>
                    <li><Link to="#/workplan/requests/dkg-manager" className={`${(segment3 === 'dkg-manager') ? 'active' : ''}`}>Finance</Link></li>
                    <li><Link to="#/workplan/requests/dkg-manager" className={`${(segment3 === 'clients') ? 'active' : ''}`}>Clients</Link></li>
                    <li><Link to="#/workplan/requests/dkg-manager" className={`${(segment3 === 'legal') ? 'active' : ''}`}>Legal</Link></li>
                    <li><Link to="#/workplan/requests/dkg-manager" className={`${(segment3 === 'hr') ? 'active' : ''}`}>HR</Link></li>
                    <li><Link to="#/workplan/requests/dkg-manager" className={`${(segment3 === 'it') ? 'active' : ''}`}>IT</Link></li>



                </ul>
            </div>
        </>
    )
}

export default RequestLeftPanel
