import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './clientinfonavbar.scss';

const ClientsInfoMainMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK Global CRM";
    }, [pathname]);

    // const { allBadge } = useSelector(state => state.tasks);
    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-clientinfo-navbar">
                <Route>
                    <ul>
                        <li><Link to="/clientsinfo/policies" className={`${(segment2 === 'policies') ? 'active' : ''}`}>Policies </Link></li>

                        <li><Link to="/clientsinfo/salaries" className={`${pathname.match('/salaries') ? 'active' : ''}`}>Salaries</Link></li>

                        <li><Link to="/clientsinfo/income-tax" className={`${pathname.match('/income-tax') ? 'active' : ''}`}>Income Tax</Link></li>

                        <li><Link to="/clientsinfo/benefits" className={`${pathname.match('/benefits') ? 'active' : ''}`}>Benefits</Link></li>

                        <li><Link to="/clientsinfo/relocation" className={(segment2 === "relocation") ? 'active' : ''}>Relocation</Link></li>

                        <li><Link to="/clientsinfo/interviews" className={`${pathname.match('/interviews') ? 'active' : ''}`}>Interviews</Link></li>

                        <li><Link to="/clientsinfo/profiling" className={`${pathname.match('/profiling') ? 'active' : ''}`}>Profiling</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default ClientsInfoMainMenu;