import React from 'react';
import { Link } from "react-router-dom";

const AccountantLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="dk-moduledkFinanceProcess-leftPanel">
                <ul>
                    <li><Link to="/dkglobalfinance/invoices/accountant/overview" className={`${pathname.match('/dkglobalfinance/invoices/accountant/overview') ? 'active' : ''}`}>Overview</Link></li>
                    <li><Link to="/dkglobalfinance/invoices/accountant/step-1" className={`${pathname.match('/dkglobalfinance/invoices/accountant/step-1') ? 'active' : ''}`}>TP Greece</Link></li>
                    <li><Link to="#" className={`${pathname.match('/dkglobalfinance/invoices/job-board/step-2') ? 'active' : ''}`}>Genpact</Link></li>
                    <li><Link to="#" className={`${pathname.match('/dkglobalfinance/invoices/job-board/step-3') ? 'active' : ''}`}>Tech M</Link></li>
                    <li><Link to="#" className={`${pathname.match('/dkglobalfinance/invoices/job-board/step-4') ? 'active' : ''}`}>Wipro</Link></li>
                    <li><Link to="#" className={`${pathname.match('/dkglobalfinance/invoices/job-board/step-5') ? 'active' : ''}`}>GEP</Link></li>
                    <li><Link to="#" className={`${pathname.match('/dkglobalfinance/invoices/job-board/step-6') ? 'active' : ''}`}>Webhelp GR</Link></li>
                    <li><Link to="#" className={`${pathname.match('/dkglobalfinance/invoices/job-board/step-7') ? 'active' : ''}`}>Webhelp PT</Link></li>
                    <li><Link to="#" className={`${pathname.match('/dkglobalfinance/invoices/job-board/step-8') ? 'active' : ''}`}>Pragmatic</Link></li>
                    <li><Link to="#" className={`${pathname.match('/dkglobalfinance/invoices/job-board/step-9') ? 'active' : ''}`}>Mphasis</Link></li>
                    <li><Link to="#" className={`${pathname.match('/dkglobalfinance/invoices/job-board/step-10') ? 'active' : ''}`}>Cxfort</Link></li>
                </ul>
            </div>
        </>
    )
}

export default AccountantLeftPanel;
