import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import FormControl from 'react-bootstrap/FormControl';
import Dropdown from 'react-bootstrap/Dropdown'
import './searchinputnew.scss';
import { showSuccess, showError } from "../../utils/messages/messageSlice";
import { updateDetails, clearState } from "../../../slice/campaign/campaignSlice";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className=""
                    placeholder="Search Text..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);

const SearchInputDropDwn = ({ list, value, id, name, type }) => {
    const dispatch = useDispatch();

    const { isUpdate, isError, errorMessage } = useSelector(state => state.campaignAds)
    const updateDdl = async (name, value, id) => {
        await dispatch(updateDetails({ id, name, value, type }))
    }

    useEffect(() => {
        if (isUpdate) {
            dispatch(showSuccess({ msg: 'Campaigns update successfully.' }))
            dispatch(clearState())
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
        }
    }, [isUpdate, isError])
    return (
        <>
            <div className="dk-searchInlineDropDwn">
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-custom-components" as={CustomToggle}>
                        {value}
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                        {list.map((listData, idx) =>
                            <Dropdown.Item eventKey={idx + 1} onClick={() => updateDdl(name, listData.value, id)}>{listData.value}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default SearchInputDropDwn;
