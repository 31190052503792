import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./myacc-privacy.scss";
import { getCMS } from "../../../../slice/cmsSlice";

const GdprPrivacy = () => {
  const dispatch = useDispatch();
  const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);

  const [JobInstuctiondata, setJobInstuctiondata] = useState("");

  useEffect(() => {
    dispatch(getCMS({}));
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      setJobInstuctiondata(cmsList[4]);
    }
  }, [isSuccess]);

  return (
    <>
      <div className="dkg-myacc-priavcy-Con container-fluid">
        <div className="dkg-myacc-priavcy-mainRow">
          <div className="dkg-myacc-priavcy-mainCol">
            <div className="dkg-myacc-privacy-cardCon">
              <div className="dkg-myacc-priavcy-Header">
                <h2 className="dkg-privacy-title">GDPR Explained</h2>
              </div>
              <div className="dkg-myacc-priavcy-Body">
                {JobInstuctiondata != "" && JobInstuctiondata != null ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: JobInstuctiondata.description,
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GdprPrivacy;
