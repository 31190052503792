import React from 'react';
import './casestatus.scss';

const CaseStatusDropDwn = ({ caseStatus, value }) => {
    let bgColor = "";
    switch (caseStatus) {
        
        case 'To be Joined':
            bgColor = '#629bd3'
            break;       
        case 'Joined':
            bgColor = '#629bd3'
            break;       
        case 'Do not Like Salary':
            bgColor = '#da3d5e'
            break;       
        case 'Got Another Job':
            bgColor = '#da3d5e'
            break;       
        case 'Personal Reason':
            bgColor = '#da3d5e'
            break;       
        case 'Changed Interest':
            bgColor = '#da3d5e'
            break;       
        case 'Medical Reason':
            bgColor = '#da3d5e'
            break;       
        case 'Joining Dates':
            bgColor = '#da3d5e'
            break;  
           
        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor }}>
                {
                    (value == "Blank") ? "" : value
                }
            </div>
        </>
    )
}

export default CaseStatusDropDwn;