import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const WorkPlanDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/business') ? 'active' : ''}`}>Morning</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/industry') ? 'active' : ''}`}>Afternoon</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/services') ? 'active' : ''}`}>Evening</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/clients') ? 'active' : ''}`}>Late Hours</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/suppliers') ? 'active' : ''}`}>Weekends</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/completion') ? 'active' : ''}`}>Summary</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>To Do</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>In Progress</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Completed</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Timer</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/commontraining/employees') ? 'active' : ''}`}>Speed</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default WorkPlanDetailLeftPanel;

