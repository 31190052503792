import React from 'react';
//import './casestatus.scss';

const CaseStatus = ({ value }) => {
    let bgColor = "";
    switch (value) {
             case 'Open Invoice':
            bgColor = '#d58b5d'
            break;

        case 'Closed Invoice':
            bgColor = '#01796f'
            break;
        case 'Credit Note':
            bgColor = '#da3d5e'
            break;

        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="caseDiv" style={{ backgroundColor: bgColor }}>{value}</div>
        </>
    )
}

export default CaseStatus;