import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Route, Link } from "react-router-dom";
import './datatablemenu.scss';
import Submenu from '../../subMenu/DatatableSubmenu';
//import { candidate2021DatabaseCount, candidate2020DatabaseCount, candidate2019DatabaseCount, candidate2018DatabaseCount, candidate2017DatabaseCount, candidate2016DatabaseCount } from "../../../../../../slice/candidates/list/listSlice";

const DatatableMenu = () => {
    const dispatch = useDispatch();
    const { isLoading, database2021count, database2020count, database2019count, database2018count, database2017count, database2016count } = useSelector(state => state.candidates);
    const [data2021, setData2021] = useState([]);
    const [data2020, setData2020] = useState([]);
    const [data2019, setData2019] = useState([]);
    const [data2018, setData2018] = useState([]);
    const [data2017, setData2017] = useState([]);
    const [data2016, setData2016] = useState([]);

    useEffect(() => {
        const fetchList = async () => {
            try {
                // await dispatch(candidate2021DatabaseCount())
                // await dispatch(candidate2020DatabaseCount())
                // await dispatch(candidate2019DatabaseCount())
                // await dispatch(candidate2018DatabaseCount())
                // await dispatch(candidate2017DatabaseCount())
                // await dispatch(candidate2016DatabaseCount())
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
    }, [dispatch])

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                // setData2021(database2021count[0].count)
                // setData2020(database2020count[0].count)
                // setData2019(database2019count[0].count)
                // setData2018(database2018count[0].count)
                // setData2017(database2017count[0].count)
                // setData2016(database2016count[0].count)
            }, 200)
        }


    }, [isLoading, database2021count, database2020count, database2019count, database2018count, database2017count, database2016count])

    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-datatableNavbar">
                <Route>
                    <ul>
                        <li><Link to="/db-table/summary" className={`${(segment2 === 'summary') ? 'white-active' : ''}`}>Summary </Link></li>
                        <li><Link to="/db-table/search-db" className={`${(segment2 === 'search-db') ? 'active' : ''}`}>CV Database</Link></li>
                        <li><Link to="/db-table/projects/saved-projects" className={`${(segment2 === 'projects') ? 'white-active' : ''}`}>Projects <span className='tweentyTwentyOne'>0</span></Link></li>
                        <li><Link to="/db-table/mailshots" className={`${(segment2 === 'mailshots') ? 'white-active' : ''}`}>Mailshots<span className='tweentyTwentyOne'>0</span></Link></li>
                        {/* <li><Link to="/db-table/dkglobal/deepak" className={`${(segment2 === 'dkglobal') ? 'white-active' : ''}`}>DK Global</Link></li> */}
                        {/* <li><Link to="/db-table/data-table" className={`${(segment2 === 'data-table') ? 'white-active' : ''}`}>Data Table <span className='tweentyTwentyOne'>{data2021}</span></Link></li> */}
                        {/* <li><Link to="/db-table/data-twenty-one" className={`${(segment2 === 'data-twenty-one') ? 'white-active' : ''}`}>2021 <span className='tweentyTwentyOne'>{data2021}</span></Link></li>
                        <li><Link to="/db-table/data-twenty-twenty" className={`${(segment2 === 'data-twenty-twenty') ? 'white-active' : ''}`}>2020  <span className='tweentyTwenty'>{data2020}</span></Link></li>
                        <li><Link to="/db-table/data-twenty-nineteen" className={`${(segment2 === 'data-twenty-nineteen') ? 'white-active' : ''}`}> 2019 <span className='tweentyNineteen'>{data2019}</span></Link></li>
                        <li><Link to="/db-table/data-twenty-eighteen" className={`${(segment2 === 'data-twenty-eighteen') ? 'white-active' : ''}`}>2018 <span className='tweentyEighteen'>{data2018}</span> </Link></li>
                        <li><Link to="/db-table/data-twenty-seventeen" className={`${(segment2 === 'data-twenty-seventeen') ? 'white-active' : ''}`}>2017 <span className='tweentySeventeen'>{data2017}</span></Link></li>
                        <li><Link to="/db-table/data-twenty-sixteen" className={`${(segment2 === 'data-twenty-sixteen') ? 'white-active' : ''}`}>2016 <span className='tweentySixteen'>{data2016}</span></Link></li> */}
                        {/* <li><Link to="/db-table/archived" className={`${(segment2 === 'archived') ? 'active' : ''}`}>Archived <span className='tweentyTwentyOne'>0</span> </Link></li> */}
                        <li><Link to="/db-table/data-twenty-two" className={`${(segment2 === 'data-twenty-two') ? 'white-active' : ''}`}>2022 <span className='tweentyTwentyOne'>{data2021}</span></Link></li>

                        {/* <li><Link to="/db-table/blocked" className={`${(segment2 === 'blocked') ? 'active' : ''}`}>Blocked</Link></li> */}
                    </ul>
                </Route>
            </div>
            <Submenu path={pathname} />
        </React.Fragment>
    );
}

export default DatatableMenu;