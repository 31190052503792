import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./livejobsrightfilter.scss";

import { Modal, Nav, Tab } from "react-bootstrap";
import {
  FaFilter,
  FaBuilding,
  FaCalendarAlt,
  FaCheck,
  FaBriefcase,
  FaEuroSign,
  FaLanguage,
  FaIdCard,
  FaInfo,
  FaArrowRight,
} from "react-icons/fa";

import { MdInsertDriveFile } from "react-icons/md";
import moment from "moment-timezone";

const RightModalFilter = ({ list, selectUserFilter, selectOtherFilter }) => {
  console.log(list);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const [categoryList, setCategoryList] = useState([]);
  const [localityList, setLocalityList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [workFromList, setWorkFromList] = useState([]);
  const [workPermitList, setWorkPermitList] = useState([]);
  const [workTypeList, setWorkTypeList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [hireList, setHireList] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [date1List, setDate1List] = useState([]);
  const [date2List, setDate2List] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const [jobProfileList, setJobProfileList] = useState([]);
  const [updated_onList, setUpdated_onList] = useState([]);
  const [createdAtList, setCreatedAtList] = useState([]);
  const [grossSalary, setGrossSalaryList] = useState([]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.date1)) {
        map.set(item.original.date1, true);
        result.push({
          name: item.original.date1 == "" ? "Blank" : item.original.date1,
          count: list.filter(function (val) {
            return val.original.date1 === item.original.date1;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setDate1List(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.date2)) {
        map.set(item.original.date2, true);
        result.push({
          name: item.original.date2 == "" ? "Blank" : item.original.date2,
          count: list.filter(function (val) {
            return val.original.date2 === item.original.date2;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setDate2List(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.annual_gross)) {
        map.set(item.original.annual_gross, true);
        result.push({
          name:
            item.original.annual_gross == ""
              ? "Blank"
              : item.original.annual_gross,
          count: list.filter(function (val) {
            return val.original.annual_gross === item.original.annual_gross;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setGrossSalaryList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.job_country)) {
        map.set(item.original.job_country, true);
        result.push({
          name:
            item.original.job_country == ""
              ? "Blank"
              : item.original.job_country,
          count: list.filter(function (val) {
            return val.original.job_country === item.original.job_country;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setCountryList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.category)) {
        map.set(item.original.category, true);
        result.push({
          name: item.original.category,
          count: list.filter(function (val) {
            return val.original.category === item.original.category;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setCategoryList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.industry)) {
        map.set(item.original.industry, true);
        result.push({
          name: item.original.industry,
          count: list.filter(function (val) {
            return val.original.industry === item.original.industry;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setIndustryList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.company)) {
        map.set(item.original.company, true);
        result.push({
          name: item.original.company,
          count: list.filter(function (val) {
            return val.original.company === item.original.company;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setCompanyList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.project)) {
        map.set(item.original.project, true);
        result.push({
          name: item.original.project,
          count: list.filter(function (val) {
            return val.original.project === item.original.project;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setProjectList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.role)) {
        map.set(item.original.role, true);
        result.push({
          name: item.original.role,
          count: list.filter(function (val) {
            return val.original.role === item.original.role;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setRoleList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.worktype)) {
        map.set(item.original.worktype, true);
        result.push({
          name: item.original.worktype,
          count: list.filter(function (val) {
            return val.original.worktype === item.original.worktype;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setWorkTypeList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.work_permit)) {
        map.set(item.original.work_permit, true);
        result.push({
          name: item.original.work_permit,
          count: list.filter(function (val) {
            return val.original.work_permit === item.original.work_permit;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setWorkPermitList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.workplace)) {
        map.set(item.original.workplace, true);
        result.push({
          name: item.original.workplace,
          count: list.filter(function (val) {
            return val.original.workplace === item.original.workplace;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setWorkFromList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.lang)) {
        map.set(item.original.lang, true);
        result.push({
          name: item.original.lang,
          count: list.filter(function (val) {
            return val.original.lang === item.original.lang;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setLanguageList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.location)) {
        map.set(item.original.location, true);
        result.push({
          name: item.original.location == "" ? "Blank" : item.original.location,
          count: list.filter(function (val) {
            return val.original.location === item.original.location;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setCityList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.fee)) {
        map.set(item.original.fee, true);
        result.push({
          name:
            item.original.fee == "" || item.original.fee == "0"
              ? "Blank"
              : item.original.fee,
          count: list.filter(function (val) {
            return val.original.fee === item.original.fee;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setHireList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.locality)) {
        map.set(item.original.locality, true);
        result.push({
          name: item.original.locality == "" ? "Blank" : item.original.locality,
          count: list.filter(function (val) {
            return val.original.locality === item.original.locality;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setLocalityList(result);
  }, [list]);

  useEffect(() => {
    let types1 = [];
    for (const fulllist of list) {
      if (fulllist.original.date1 !== "" && fulllist.original.date1 !== null) {
        types1.push({
          name: fulllist.original.date1,
        });
      }
      if (fulllist.original.date2 != "" && fulllist.original.date2 != null) {
        types1.push({
          name: fulllist.original.date2,
        });
      }
    }
    const result = [];
    let result1 = [];
    const map = new Map();
    for (const item of types1) {
      if (!map.has(item.name)) {
        map.set(item.name, true);
        if (item.name !== "ASAP") {
          result.push({
            name: item.name,
            count: types1.filter(function (val) {
              return val.name === item.name;
            }).length,
          });
        } else {
          result1.push({
            name: item.name,
            count: types1.filter(function (val) {
              return val.name === item.name;
            }).length,
          });
        }
      }
    }
    result.sort(function (a, b) {
      return new Date(a.name) - new Date(b.name);
    });
    if (result1.length > 0) {
      result.unshift({
        name: result1[0].name,
        count: result1[0].count,
      });
    }
    setDateList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (!map.has(item.original.job_profile)) {
        map.set(item.original.job_profile, true);
        result.push({
          name:
            item.original.job_profile == ""
              ? "Blank"
              : item.original.job_profile,
          count: list.filter(function (val) {
            return val.original.job_profile === item.original.job_profile;
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return b.count - a.count;
    });
    setJobProfileList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (
        !map.has(moment(moment(item.original.updated_on)).format("DD MMM, YY"))
      ) {
        map.set(
          moment(moment(item.original.updated_on)).format("DD MMM, YY"),
          true
        );
        result.push({
          name: moment(moment(item.original.updated_on)).format("DD MMM, YY"),
          count: list.filter(function (val) {
            return (
              moment(moment(val.original.updated_on)).format("DD MMM, YY") ===
              moment(moment(item.original.updated_on)).format("DD MMM, YY")
            );
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return new Date(a.name) - new Date(b.name);
    });
    setUpdated_onList(result);
  }, [list]);

  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of list) {
      if (
        !map.has(moment(moment(item.original.createdAt)).format("DD MMM, YY"))
      ) {
        map.set(
          moment(moment(item.original.createdAt)).format("DD MMM, YY"),
          true
        );
        result.push({
          name: moment(moment(item.original.createdAt)).format("DD MMM, YY"),
          count: list.filter(function (val) {
            return (
              moment(moment(val.original.createdAt)).format("DD MMM, YY") ===
              moment(moment(item.original.createdAt)).format("DD MMM, YY")
            );
          }).length,
        });
      }
    }
    result.sort(function (a, b) {
      return new Date(a.name) - new Date(b.name);
    });
    setCreatedAtList(result);
  }, [list]);

  const status = [
    {
      key: "active",
      value: "Active",
      text: "Active",
      bgColor: "#30887e",
      txtColor: "#fff",
    },
    {
      key: "Qualify",
      value: "Qualify",
      text: "Qualify",
      bgColor: "#D58B5D",
      txtColor: "#fff",
    },
    {
      key: "on hold",
      value: "On Hold",
      text: "On Hold",
      bgColor: "#7f5347",
      txtColor: "#fff",
    },
    {
      key: "Inactive",
      value: "Inactive",
      text: "Inactive",
      bgColor: "#B55355",
      txtColor: "#fff",
    },
    {
      key: "closed",
      value: "Closed",
      text: "Closed",
      bgColor: "#e2445c",
      txtColor: "#fff",
    },
  ];
  return (
    <React.Fragment>
      <div className="dkg-openjobs-filter-btn">
        <FaFilter />
      </div>
      <button className="dk-refreral-filter-btn" onClick={handleShow}>
        JOBS FILTERS
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dk-prerefreal-FliterModal"
      >
        <Modal.Header closeButton className="dkg-prereferal-header">
          <Modal.Title className="dkg-prereferal-Title">
             SEARCH & SUMMARY
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-prereferal-body dkg-pipelinesRightFilterBody-13">
          <Tab.Container id="dk-jobsRightFilterModal" defaultActiveKey="status">
            <div className="row ml-0 mr-0">
              <div className="col-md-4 pl-0 pr-0">
                <Nav
                  variant="pills"
                  className="flex-column dk-leftSideMenu dkg-right-sideFilter-13 dkg-livejobs-flexcolumn"
                >
                  <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                    <Nav.Link
                      className="dkg-referrals-navlink"
                      eventKey="status"
                    >
                      <i className="fa fa-check mr-2" aria-hidden="true"></i>
                      JOB STATUS
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                      <Nav.Link
                         className="dkg-referrals-navlink"
                        eventKey="category"
                      >
                        <i className="fa fa-bars" aria-hidden="true"></i>
                        Category
                      </Nav.Link>
                    </Nav.Item> */}
                  <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                    <Nav.Link
                      className="dkg-referrals-navlink"
                      eventKey="dkg-industry-tab"
                    >
                      <i className="fa fa-building" aria-hidden="true"></i>
                      INDUSTRY
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                    <Nav.Link
                      className="dkg-referrals-navlink"
                      eventKey="company"
                    >
                      <i className="fa fa-building" aria-hidden="true"></i>
                      Company
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                    <Nav.Link
                      className="dkg-referrals-navlink"
                      eventKey="country"
                    >
                      <i className="fa fa-globe" aria-hidden="true"></i>
                      Country
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                    <Nav.Link className="dkg-referrals-navlink" eventKey="city">
                      <i className="fas fa-map-marker-alt"></i>CITY/TOWN
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                    <Nav.Link
                      className="dkg-referrals-navlink"
                      eventKey="project"
                    >
                      <MdInsertDriveFile />
                      Project
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                    <Nav.Link
                      className="dkg-referrals-navlink"
                      eventKey="roletype"
                    >
                      <i className="fa fa-user" aria-hidden="true"></i>Role Type
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                    <Nav.Link
                      className="dkg-referrals-navlink"
                      eventKey="language"
                    >
                      <i className="fa fa-language" aria-hidden="true"></i>
                      LANGUAGE
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                    <Nav.Link
                      className="dkg-referrals-navlink"
                      eventKey="salary-jobs"
                    >
                      <FaEuroSign />
                      SALARY
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                      <Nav.Link
                         className="dkg-referrals-navlink"
                        eventKey="Locailty"
                      >
                        <i className="fas fa-map-marker-alt"></i>Locality
                      </Nav.Link>
                    </Nav.Item> */}
                  {/* <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                      <Nav.Link  className="dkg-referrals-navlink" eventKey="hirefee">
                        <i className="far fa-money-bill-alt"></i>HIRING FEE
                      </Nav.Link>
                    </Nav.Item> */}
                  {/* <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                      <Nav.Link
                         className="dkg-referrals-navlink"
                        eventKey="jobprofile"
                      >
                        <i className="far fa-user"></i>JOB PROFILE
                      </Nav.Link>
                    </Nav.Item> */}
                  <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                    <Nav.Link
                      className="dkg-referrals-navlink"
                      eventKey="dkg-workform-tab"
                    >
                      <i className="far fa-calendar"></i>WORK FROM
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                    <Nav.Link
                      className="dkg-referrals-navlink"
                      eventKey="dkg-workType-tab"
                    >
                      <i className="far fa-calendar"></i>WORK TYPE
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                    <Nav.Link
                      className="dkg-referrals-navlink"
                      eventKey="dkg-workpermit-tab"
                    >
                      <i className="far fa-calendar"></i>WORK PERMIT
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                    <Nav.Link
                      className="dkg-referrals-navlink"
                      eventKey="startdate"
                    >
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                      START DATE 1
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                    <Nav.Link
                      className="dkg-referrals-navlink"
                      eventKey="startdatetwo"
                    >
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                      START DATE 2
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                    <Nav.Link
                      className="dkg-referrals-navlink"
                      eventKey="jobupdated"
                    >
                      <i className="far fa-calendar"></i>JOB UPDATED
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                      <Nav.Link
                         className="dkg-referrals-navlink"
                        eventKey="jobcreated"
                      >
                        <i className="far fa-calendar"></i>JOB CREATED
                      </Nav.Link>
                    </Nav.Item> */}
                  {/* <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                                            <Nav.Link  className="dkg-referrals-navlink" eventKey="recource-admin">
                                                <i className="fa fa-user" aria-hidden="true"></i>RESOURCE
                                            </Nav.Link>
                                        </Nav.Item> */}

                  {/* <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                                            <Nav.Link  className="dkg-referrals-navlink" eventKey="account-m">
                                                <i className="fa fa-user" aria-hidden="true"></i>Account M
                                            </Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                                            <Nav.Link  className="dkg-referrals-navlink" eventKey="recruiters">
                                                <i className="fa fa-user" aria-hidden="true"></i>RECRUITER
                                            </Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item className="dkg-referrals-navitem dkg-livejobs-navitem">
                                            <Nav.Link  className="dkg-referrals-navlink" eventKey="recruiter-m">
                                                <i className="fa fa-user" aria-hidden="true"></i>REC ADMIN
                                            </Nav.Link>
                                        </Nav.Item> */}
                </Nav>
              </div>
              <div className="col-md-8 pl-0 pr-0">
                <Tab.Content>
                  <Tab.Pane eventKey="status">
                    <div className="dk-rightMainContent">
                      <div className="dk-satusListFilter">
                        <ul>
                          {status.map((item, index) => (
                            <li className="dk-fliterList" key={item.key}>
                              <Link
                                to="#"
                                className="dk-normalBg"
                                data-type="status"
                                data-value={item.text}
                                onClick={selectOtherFilter}
                                style={{ backgroundColor: item.bgColor }}
                              >
                                <span
                                  className="dk-statusName"
                                  data-type="status"
                                  data-value={item.text}
                                  onClick={selectOtherFilter}
                                >
                                  {item.text}
                                </span>
                                <span
                                  className="dk-statusCounter"
                                  data-type="status"
                                  data-value={item.text}
                                  onClick={selectOtherFilter}
                                >
                                  {
                                    list.filter(function (val) {
                                      return val.original.status === item.text;
                                    }).length
                                  }
                                </span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="category">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {categoryList &&
                            categoryList.map((item, index) => (
                              <li key={`category${index}`}>
                                <Link
                                  to="#"
                                  data-type="category"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange count_ml"
                                    data-type="category"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Locailty">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {localityList &&
                            localityList.map((item, index) => (
                              <li key={`locality${index}`}>
                                <Link
                                  to="#"
                                  data-type="locality"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange count_ml"
                                    data-type="locality"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="dkg-industry-tab">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {industryList &&
                            industryList.map((item, index) => (
                              <li key={`industry${index}`}>
                                <Link
                                  to="#"
                                  data-type="industry"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange count_ml"
                                    data-type="industry"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="company">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {companyList &&
                            companyList.map((item, index) => (
                              <li key={`company${index}`}>
                                <Link
                                  to="#"
                                  data-type="company"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange count_ml"
                                    data-type="company"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="language">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {languageList &&
                            languageList.map((item, index) => (
                              <li key={`language${index}`}>
                                <Link
                                  to="#"
                                  data-type="language"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange count_ml"
                                    data-type="language"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="project">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {projectList &&
                            projectList.map((item, index) => (
                              <li key={`project${index}`}>
                                <Link
                                  to="#"
                                  data-type="project"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange count_ml"
                                    data-type="project"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="roletype">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {roleList &&
                            roleList.map((item, index) => (
                              <li key={`role${index}`}>
                                <Link
                                  to="#"
                                  data-type="role"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange count_ml"
                                    data-type="role"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="salary-jobs">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {grossSalary &&
                            grossSalary.map((item, index) => (
                              <li key={`role${index}`}>
                                <Link
                                  to="#"
                                  data-type="annual_gross"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange count_ml"
                                    data-type="annual_gross"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="city">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {cityList &&
                            cityList.map((item, index) =>
                              item.name !== "" && item.name !== null ? (
                                <li key={`city${index}`}>
                                  <Link
                                    to="#"
                                    data-type="city"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="city"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : (
                                ""
                              )
                            )}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="country">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {countryList &&
                            countryList.map((item, index) => (
                              <li key={`country${index}`}>
                                <Link
                                  to="#"
                                  data-type="country"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange count_ml"
                                    data-type="country"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="hirefee">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {hireList &&
                            hireList.map((item, index) =>
                              item.name !== "" && item.name !== null ? (
                                <li key={`hire${index}`}>
                                  <Link
                                    to="#"
                                    data-type="hire"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="hire"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : (
                                ""
                              )
                            )}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="startdate">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {date1List &&
                            date1List.map((item, index) =>
                              item.name !== "" && item.name !== null ? (
                                <li key={`date${index}`}>
                                  <Link
                                    to="#"
                                    data-type="date"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="date"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : (
                                ""
                              )
                            )}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="startdatetwo">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {date2List &&
                            date2List.map((item, index) =>
                              item.name !== "" && item.name !== null ? (
                                <li key={`date${index}`}>
                                  <Link
                                    to="#"
                                    data-type="date2"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="date"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : (
                                ""
                              )
                            )}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="dkg-workform-tab">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {workFromList &&
                            workFromList.map((item, index) => (
                              <li key={`workplace${index}`}>
                                <Link
                                  to="#"
                                  data-type="workplace"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange count_ml"
                                    data-type="workplace"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="dkg-workpermit-tab">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {workPermitList &&
                            workPermitList.map((item, index) => (
                              <li key={`work_permit${index}`}>
                                <Link
                                  to="#"
                                  data-type="work_permit"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange count_ml"
                                    data-type="work_permit"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="dkg-workType-tab">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {workTypeList &&
                            workTypeList.map((item, index) => (
                              <li key={`worktype${index}`}>
                                <Link
                                  to="#"
                                  data-type="worktype"
                                  data-value={item.name}
                                  onClick={selectOtherFilter}
                                >
                                  {item.name}
                                  <span
                                    className="text-orange count_ml"
                                    data-type="worktype"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.count}
                                  </span>
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="jobprofile">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {jobProfileList &&
                            jobProfileList.map((item, index) =>
                              item.name !== "" && item.name !== null ? (
                                <li key={`jobprofile${index}`}>
                                  <Link
                                    to="#"
                                    data-type="job_profile"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {item.name}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="job_profile"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : (
                                ""
                              )
                            )}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="jobupdated">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {updated_onList &&
                            updated_onList.map((item, index) =>
                              item.name !== "" && item.name !== null ? (
                                <li key={`updatedate${index}`}>
                                  <Link
                                    to="#"
                                    data-type="updated_on"
                                    data-value={item.name}
                                    data-name={moment(
                                      moment(item.name)
                                    ).fromNow()}
                                    onClick={selectOtherFilter}
                                  >
                                    {/* {moment(moment(item.name)).format(
                                      "DD MMM, YY"
                                    )} */}
                                    {moment(moment(item.name)).fromNow()}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="updated_on"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : (
                                ""
                              )
                            )}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="jobcreated">
                    <div className="dk-rightMainContent">
                      <div className="dk-filterList">
                        <ul>
                          {createdAtList &&
                            createdAtList.map((item, index) =>
                              item.name !== "" && item.name !== null ? (
                                <li key={`createdat${index}`}>
                                  <Link
                                    to="#"
                                    data-type="createdAt"
                                    data-value={item.name}
                                    onClick={selectOtherFilter}
                                  >
                                    {moment(moment(item.name)).format(
                                      "DD MMM, YY"
                                    )}
                                    <span
                                      className="text-orange count_ml"
                                      data-type="createdAt"
                                      data-value={item.name}
                                      onClick={selectOtherFilter}
                                    >
                                      {item.count}
                                    </span>
                                  </Link>
                                </li>
                              ) : (
                                ""
                              )
                            )}
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default RightModalFilter;
