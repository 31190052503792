import React from 'react';
import { Link } from "react-router-dom";

const ModelNavtabs = () => {
    const pathname = window.location.pathname;
    // const segment3 = pathname.split("/")[3];
    const segment3 = pathname.split("/")[3];

    return (
        <>
            <div className="dk-recuiters-kpi-Leftpanel">
                <ul>
                    <li><Link to="/dkg-global/work-model/summary" className={`${(segment3 === 'summary') ? 'active' : ''}`}>Overview</Link></li>
                    <li><Link to="/dkg-global/work-model/model-1" className={`${(segment3 === 'model-1') ? 'active' : ''}`}>Inhouse</Link></li>
                    <li><Link to="/dkg-global/work-model/model-2" className={`${(segment3 === 'model-2') ? 'active' : ''}`}>Partner</Link></li>
                    <li><Link to="/dkg-global/work-model/model-3" className={`${(segment3 === 'model-3') ? 'active' : ''}`}>Freelance</Link></li>
                </ul>
            </div>
        </>
    )
}

export default ModelNavtabs
