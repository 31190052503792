import React, { useLayoutEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../elements/header";
import Navbar from "../elements/header/navbar/mainMenu/jobspecs";

const JobspecsLayout = ({ children }) => {
  return (
    <React.Fragment>
      <Header
        dashboardheaderLink="Dashboard -"
        dashboardLink="/dashboard/plateform"
        mainheaderLink="Recruitment -"
        moduleLink="/dashboard/recruitment"
        linkcolor="#3c4c62"
        mainheaderTitle=" Job Spec"
        textcolor="#26596d"
      />
      <Navbar />
      {children}
    </React.Fragment>
  );
};
export default JobspecsLayout;
