import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import "./reorder.scss"
import { SortablePane, Pane } from 'react-sortable-pane';




const AddnewModal = ({ onHide }) => {
    const department = [
        { key: 'department_1', value: 'department_1', text: 'Department 1' },
        { key: 'department_2', value: 'department_2', text: 'Department 2' },
        { key: 'department_3', value: 'department_3', text: 'Department 3' },
        { key: 'department_4', value: 'department_4', text: 'Department 4' },
        { key: 'department_5', value: 'department_5', text: 'Department 5' }
    ]
    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-reorderResModalDialog modal-40w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton className="dk-resourcePopupHedaer">
                    <Modal.Title id="example-custom-modal-styling-title">
                        REORDER
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="dk-SortableCon">
                        <SortablePane
                            direction="vertical"
                            margin={0}
                            className="dkSortable"
                        >
                            <Pane id={0} key={0} className="dk-sortableList">
                                <div className="dk-reordertableCon table-responisve">
                                    <div className="dk-reorderTable table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td className="dk-dragBtn">
                                                    <span>
                                                        <i className="fa fa-arrows-alt"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    Mphasis
                                                </td>
                                                <td>
                                                    Portal Logins
                                                </td>
                                                <td>
                                                    Clients Portals
                                                </td>
                                            </tr>
                                        </tbody>
                                    </div>
                                </div>
                            </Pane>
                            <Pane id={1} key={1} className="dk-sortableList">
                                <div className="dk-reordertableCon table-responisve">
                                    <div className="dk-reorderTable table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td className="dk-dragBtn">
                                                    <span>
                                                        <i className="fa fa-arrows-alt"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    Mphasis
                                                </td>
                                                <td>
                                                    Portal Logins
                                                </td>
                                                <td>
                                                    Clients Portals
                                                </td>
                                            </tr>
                                        </tbody>
                                    </div>
                                </div>
                            </Pane>
                            <Pane id={2} key={2} className="dk-sortableList">
                                <div className="dk-reordertableCon table-responisve">
                                    <div className="dk-reorderTable table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td className="dk-dragBtn">
                                                    <span>
                                                        <i className="fa fa-arrows-alt"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    Mphasis
                                                </td>
                                                <td>
                                                    Portal Logins
                                                </td>
                                                <td>
                                                    Clients Portals
                                                </td>
                                            </tr>
                                        </tbody>
                                    </div>
                                </div>
                            </Pane>
                            <Pane id={3} key={3} className="dk-sortableList">
                                <div className="dk-reordertableCon table-responisve">
                                    <div className="dk-reorderTable table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td className="dk-dragBtn">
                                                    <span>
                                                        <i className="fa fa-arrows-alt"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    Mphasis
                                                </td>
                                                <td>
                                                    Portal Logins
                                                </td>
                                                <td>
                                                    Clients Portals
                                                </td>
                                            </tr>
                                        </tbody>
                                    </div>
                                </div>
                            </Pane>
                            <Pane id={4} key={4} className="dk-sortableList">
                                <div className="dk-reordertableCon table-responisve">
                                    <div className="dk-reorderTable table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td className="dk-dragBtn">
                                                    <span>
                                                        <i className="fa fa-arrows-alt"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    Mphasis
                                                </td>
                                                <td>
                                                    Portal Logins
                                                </td>
                                                <td>
                                                    Clients Portals
                                                </td>
                                            </tr>
                                        </tbody>
                                    </div>
                                </div>
                            </Pane>
                        </SortablePane>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default AddnewModal;
