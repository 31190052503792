import React, { useState, useLayoutEffect } from "react";
import Header from "../elements/header";
import Navbar from "../elements/header/navbar/mainMenu/module";
import { useDispatch, useSelector } from "react-redux";
import { candidateList } from "../../slice/candidates/candidatesSlice";
import {
  caseStatus,
  caseProgress,
  caseStage,
  candidateCategory,
  candidateNationality,
  candidateVisaStatus,
  candidateNative,
  candidateFluent,
  candidateIntermediate,
  candidateGender,
  candidateCVSource,
  candidateIndustry,
  candidateCareerLevel,
  candidateCityLevel,
  candidateCountryLevel,
  candidateLatestJob,
  candidateEduation,
  candidateCareerPriority,
  jobsCategory,
  jobsCompany,
  jobsProject,
  jobsRoleType,
  jobsLocality,
  jobsNoOfJobs,
  jobsCountry,
  jobsCity,
  jobsLanguages,
  jobsJobProfile,
  jobsWorkPermit,
  jobsWorkplace,
  jobsWorkdays,
  jobsShiftsWork,
  jobsContracttype,
  jobsSalarytype,
  jobsAvailability,
  jobsOutsideofeu,
  jobsFlightticket,
  jobsTaxifromairport,
  jobsAccommodationt,
  jobsRelocationbudget,
  jobRelocation,
  jobWorktype,
  jobsExperiencet,
  jobsIndustryId,
  jobsLevel,
  getFileTypeList,
  jobsStatusId,
  profileTechSkillsList,
  profileJobSkillsList,
  jobsCount,
  jobssInterviews,
  jobitskills,
  jobsskills,
  jobsInterviewsMethod,
  jobsSendoutsMethod,
  jobssendoutStepId,
  countryCurrency,
  profilesStatus,
  getNotificationCount,
  applicationList,
  jobComp
} from "../../slice/layout/layoutSlice";

import {
  categoryCounts,
  priorityCount,
  motivationCount,
  latestJobCounts,
  recordYearCounts,
  casePriorityCount,
  caseStatusCounts,
  caseProgressCounts,
  caseStageCounts,
  flagCounts,
  profileIndicatorCounts,
  genderCounts,
  nativeLanguageCounts,
  fluentLanguageCounts,
  intermediateLanguageCounts,
  nationalityCounts,
  countryCounts,
  cvSourceCounts,
  companyCounts,
  companyRoleCounts,
  accountMangerCounts,
  recAdminCounts,
  cityCounts,
  visaCounts,
  careerCounts,
  jobTitleCounts,
  industriesCounts,
  itSkillsCounts,
  jobSkillsCounts,
  jobCityCount,
  jobCountryCount,
  jobLocalityCount,
  jobProjectCount,
  jobLanguage1Count,
  educationsCount,
} from "../../slice/searchCV/searchCvSlice";

import {
  paidPaymentCounts,
  paymentCounts,
  archivePaymentCounts,
} from "../../slice/task/paymentTaskSlice";

import { taskSummaryList, clearState } from "../../slice/task/taskSlice";
import {
  candidateSummaryList,
  databaseSearchList,
} from "../../slice/candidates/candidatesSlice";

import { mailshotlist } from "../../slice/mailshots/mailshotsSlice";
//import { usersById } from '../../slice/auth/authSlice';

const DashboradLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);

  const [search, setSearch] = useState([]);
  const [filter, setFilter] = useState({});

  const getRequestParams = (filter) => {
    let params = {};
    params["filter"] = filter;
    params["search"] = search;
    return params;
  };

  const pathname = window.location.pathname;
  const segment1 = pathname.split("/")[1];
  let mainTitle;
  if (segment1 === "dkglobalfinance") {
    mainTitle = "dk global finance";
  } else if (segment1 === "dkglobaladmin") {
    mainTitle = "";
  } else {
    mainTitle = "";
  }

  useLayoutEffect(() => {
    const fetchMailshotlist = async () => {
      try {
        await dispatch(mailshotlist());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCandidateList = async () => {
      try {
        await dispatch(candidateList());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCaseStatusList = async () => {
      try {
        await dispatch(caseStatus());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchFileTypeList = async () => {
      try {
        await dispatch(getFileTypeList());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchjobsStatusIdList = async () => {
      try {
        await dispatch(jobsStatusId());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCaseProgressList = async () => {
      try {
        await dispatch(caseProgress());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCaseStageList = async () => {
      try {
        await dispatch(caseStage());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchCandidateCategoryList = async () => {
      try {
        await dispatch(candidateCategory());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchCandidateNationalityList = async () => {
      try {
        await dispatch(candidateNationality());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCandidateVisaStatusList = async () => {
      try {
        await dispatch(candidateVisaStatus());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCandidateNativeList = async () => {
      try {
        await dispatch(candidateNative());
      } catch (err) {
        console.log(err);
      }
    };
    // const fetchCandidateFluentList = async () => {
    //     try {
    //         await dispatch(candidateFluent())
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }
    // const fetchCandidateIntermediateList = async () => {
    //     try {
    //         await dispatch(candidateIntermediate())
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }
    const fetchCandidateCVSourceList = async () => {
      try {
        await dispatch(candidateCVSource());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCandidateGenderList = async () => {
      try {
        await dispatch(candidateGender());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCandidateIndustryList = async () => {
      try {
        await dispatch(candidateIndustry());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCandidateCareerLevelList = async () => {
      try {
        await dispatch(candidateCareerLevel());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCandidateCityLevelList = async () => {
      try {
        await dispatch(candidateCityLevel());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCandidateCountryLevelList = async () => {
      try {
        await dispatch(candidateCountryLevel());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCandidateLatestJobList = async () => {
      try {
        await dispatch(candidateLatestJob());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCandidateEduationList = async () => {
      try {
        await dispatch(candidateEduation());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCandidateCareerPriorityList = async () => {
      try {
        await dispatch(candidateCareerPriority());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsCategoryList = async () => {
      try {
        await dispatch(jobsCategory());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsCompList = async () => {
      try {
        await dispatch(jobComp());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchjobsCompanyList = async () => {
      try {
        await dispatch(jobsCompany());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsProjectList = async () => {
      try {
        await dispatch(jobsProject());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsRoleTypeList = async () => {
      try {
        await dispatch(jobsRoleType());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsLocalityList = async () => {
      try {
        await dispatch(jobsLocality());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsNoOfJobsList = async () => {
      try {
        await dispatch(jobsNoOfJobs());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsCountryList = async () => {
      try {
        await dispatch(jobsCountry());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsCityList = async () => {
      try {
        await dispatch(jobsCity());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsLanguagesList = async () => {
      try {
        await dispatch(jobsLanguages());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsJobProfileList = async () => {
      try {
        await dispatch(jobsJobProfile());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsWorkPermitList = async () => {
      try {
        await dispatch(jobsWorkPermit());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsWorkplaceList = async () => {
      try {
        await dispatch(jobsWorkplace());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsWorkdaysList = async () => {
      try {
        await dispatch(jobsWorkdays());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsShiftsWorkList = async () => {
      try {
        await dispatch(jobsShiftsWork());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsContracttypeList = async () => {
      try {
        await dispatch(jobsContracttype());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsSalarytypeList = async () => {
      try {
        await dispatch(jobsSalarytype());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsAvailabilityList = async () => {
      try {
        await dispatch(jobsAvailability());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsOutsideofeuList = async () => {
      try {
        await dispatch(jobsOutsideofeu());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsFlightticketList = async () => {
      try {
        await dispatch(jobsFlightticket());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsTaxifromairportList = async () => {
      try {
        await dispatch(jobsTaxifromairport());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsAccommodationtList = async () => {
      try {
        await dispatch(jobsAccommodationt());
      } catch (err) {
        console.log(err);
      }
    };

    const jobworktypeFunList = async () => {
      try {
        await dispatch(jobWorktype());
      } catch (err) {
        console.log(err);
      }
    };
    const relocationFunList = async () => {
      try {
        await dispatch(jobRelocation());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchjobsRelocationbudgetList = async () => {
      try {
        await dispatch(jobsRelocationbudget());
      } catch (err) {
        console.log(err);
      }
    };

    const profilesStatusList = async () => {
      try {
        await dispatch(profilesStatus());
      } catch (err) {
        console.log(err);
      }
    };
    const jobsCountryCurrencyList = async () => {
      try {
        await dispatch(countryCurrency());
      } catch (err) {
        console.log(err);
      }
    };
    const jobsSendoutStepList = async () => {
      try {
        await dispatch(jobssendoutStepId());
      } catch (err) {
        console.log(err);
      }
    };
    const jobsSendoutsMethodList = async () => {
      try {
        await dispatch(jobsSendoutsMethod());
      } catch (err) {
        console.log(err);
      }
    };
    const jobsInterviewsMethodList = async () => {
      try {
        await dispatch(jobsInterviewsMethod());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchjobsskillsList = async () => {
      try {
        await dispatch(jobsskills());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchjobitskillsList = async () => {
      try {
        await dispatch(jobitskills());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsInterviewsList = async () => {
      try {
        await dispatch(jobssInterviews());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsExperiencetList = async () => {
      try {
        await dispatch(jobsExperiencet());
      } catch (err) {
        console.log(err);
      }
    };
    const fetchjobsIndustryIdList = async () => {
      try {
        await dispatch(jobsIndustryId());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsLevelIdList = async () => {
      try {
        await dispatch(jobsLevel());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchCandidateProfileTechSkillsList = async () => {
      try {
        await dispatch(profileTechSkillsList());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchCandidateProfileJobSkillsList = async () => {
      try {
        await dispatch(profileJobSkillsList());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchNotificationCount = async () => {
      try {
        await dispatch(getNotificationCount());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchJobsCountList = async () => {
      try {
        await dispatch(jobsCount());
      } catch (err) {
        console.log(err);
      }
    };

    const fetchjobsApplicationList = async () => {
      try {
        await dispatch(applicationList({ status: "New Case" }));
      } catch (err) {
        console.log(err);
      }
    };

    const fetchTaskSummaryList = async () => {
      try {
        await dispatch(taskSummaryList());
      } catch (err) {
        console.log(err);
      }
    };

    // const fetchDatabaseSearchList = async () => {
    //     try {
    //         await dispatch(databaseSearchList())
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }
    const fetchCandidateSummaryList = async () => {
      try {
        await dispatch(candidateSummaryList());
      } catch (err) {
        console.log(err);
      }
    };

    // const loggedInUserDetails = async () => {
    //     try {
    //         await dispatch(usersById({ userId: userInfo.id }))
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    // const allPaymentTaskCounts = async () => {
    //     try {
    //         await dispatch(countAllTask())
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }
    const AllarchivePaymentCounts = async () => {
      try {
        await dispatch(archivePaymentCounts());
      } catch (err) {
        console.log(err);
      }
    };
    const allPaidPaymentCounts = async () => {
      try {
        await dispatch(paidPaymentCounts());
      } catch (err) {
        console.log(err);
      }
    };
    const allPaymentCounts = async () => {
      try {
        await dispatch(paymentCounts());
      } catch (err) {
        console.log(err);
      }
    };

    // search database load
    const params = getRequestParams(filter);

    const fetchAccountManagerCountlist = async () => {
      try {
        await dispatch(accountMangerCounts(params));
      } catch (err) {
        console.log(err);
      }
    };

    const fetchRcadminCountlist = async () => {
      try {
        await dispatch(recAdminCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCategoryCountlist = async () => {
      try {
        await dispatch(categoryCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchPriorityCountlist = async () => {
      try {
        await dispatch(priorityCount(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCaseStatusCountslist = async () => {
      try {
        await dispatch(caseStatusCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchMotivationCountlist = async () => {
      try {
        await dispatch(motivationCount(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCasePriorityCountlist = async () => {
      try {
        await dispatch(casePriorityCount(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCaseProgressCountslist = async () => {
      try {
        await dispatch(caseProgressCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCaseStageCountslist = async () => {
      try {
        await dispatch(caseStageCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchFlagCountslist = async () => {
      try {
        await dispatch(flagCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchProfileIndicatorCountslist = async () => {
      try {
        await dispatch(profileIndicatorCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchGenderCountslist = async () => {
      try {
        await dispatch(genderCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchNativeLanguageCountslist = async () => {
      try {
        await dispatch(nativeLanguageCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchFluentLanguageCountslist = async () => {
      try {
        await dispatch(fluentLanguageCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchEducationsCountlist = async () => {
      try {
        await dispatch(educationsCount(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchIntermediateLanguageCountslist = async () => {
      try {
        await dispatch(intermediateLanguageCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchNationalityCountslist = async () => {
      try {
        await dispatch(nationalityCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCityCountslist = async () => {
      try {
        await dispatch(cityCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCountryCountslist = async () => {
      try {
        await dispatch(countryCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCvSourceCountslist = async () => {
      try {
        await dispatch(cvSourceCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCompanyCountslist = async () => {
      try {
        await dispatch(companyCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCompanyRoleCountslist = async () => {
      try {
        await dispatch(companyRoleCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchLatestJobCountslist = async () => {
      try {
        await dispatch(latestJobCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchRecordYearCountstlist = async () => {
      try {
        await dispatch(recordYearCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchVisaCountslist = async () => {
      try {
        await dispatch(visaCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCareerCountslist = async () => {
      try {
        await dispatch(careerCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchJobTitleCountslist = async () => {
      try {
        await dispatch(jobTitleCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchIndustriesCountslist = async () => {
      try {
        await dispatch(industriesCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchItSkillsCountslist = async () => {
      try {
        await dispatch(itSkillsCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchJobSkillsCountslist = async () => {
      try {
        await dispatch(jobSkillsCounts(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchJobCityCountlist = async () => {
      try {
        await dispatch(jobCityCount(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchJobCountryCountlist = async () => {
      try {
        await dispatch(jobCountryCount(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchJobLocalityCountlist = async () => {
      try {
        await dispatch(jobLocalityCount(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchJobProjectCountlist = async () => {
      try {
        await dispatch(jobProjectCount(params));
      } catch (err) {
        console.log(err);
      }
    };
    const fetchJobLanguage1Countlist = async () => {
      try {
        await dispatch(jobLanguage1Count(params));
      } catch (err) {
        console.log(err);
      }
    };

    profilesStatusList();
    AllarchivePaymentCounts();
    allPaidPaymentCounts();
    allPaymentCounts();
    //allPaymentTaskCounts()
    // loggedInUserDetails()
    fetchTaskSummaryList();
    fetchCandidateSummaryList();
    fetchMailshotlist();
    fetchCandidateList();
    fetchCaseStatusList();
    fetchFileTypeList();
    fetchjobsStatusIdList();
    fetchCaseProgressList();
    fetchCaseStageList();
    fetchCandidateCategoryList();
    fetchCandidateNationalityList();
    fetchCandidateVisaStatusList();
    fetchCandidateNativeList();
    // fetchCandidateFluentList()
    // fetchCandidateIntermediateList()
    fetchCandidateCVSourceList();
    fetchCandidateGenderList();
    fetchCandidateIndustryList();
    fetchCandidateCareerLevelList();
    fetchCandidateCityLevelList();
    fetchCandidateCountryLevelList();
    fetchCandidateLatestJobList();
    fetchCandidateEduationList();
    fetchCandidateCareerPriorityList();
    fetchjobsCategoryList();
    fetchjobsCompanyList();
    fetchjobsCompList();
    fetchjobsProjectList();
    fetchjobsRoleTypeList();
    fetchjobsLocalityList();
    fetchjobsNoOfJobsList();
    fetchjobsCountryList();
    fetchjobsCityList();
    fetchjobsLanguagesList();
    fetchjobsJobProfileList();
    fetchjobsWorkPermitList();

    fetchjobsWorkplaceList();
    fetchjobsWorkdaysList();
    fetchjobsShiftsWorkList();
    fetchjobsContracttypeList();
    fetchjobsSalarytypeList();
    fetchjobsAvailabilityList();
    fetchjobsOutsideofeuList();
    fetchjobsFlightticketList();
    fetchjobsTaxifromairportList();
    fetchjobsAccommodationtList();
    jobworktypeFunList();
    relocationFunList();
    fetchjobsRelocationbudgetList();
    fetchjobsExperiencetList();
    fetchjobsIndustryIdList();
    fetchjobsLevelIdList();
    fetchjobsInterviewsList();
    fetchjobitskillsList();
    fetchjobsskillsList();
    jobsInterviewsMethodList();
    jobsSendoutsMethodList();
    jobsSendoutStepList();
    jobsCountryCurrencyList();
    // fetchDatabaseSearchList()
    //fetchCandidateDatabaseList()
    fetchCandidateProfileTechSkillsList();
    fetchCandidateProfileJobSkillsList();
    fetchNotificationCount();
    fetchJobsCountList();
    fetchjobsApplicationList();

    //searchDB
    // fetchAccountManagerCountlist()
    // fetchRcadminCountlist()
    // fetchCategoryCountlist()
    // fetchPriorityCountlist()
    // fetchCaseStatusCountslist()
    // fetchMotivationCountlist()
    // fetchCasePriorityCountlist()
    // fetchCaseProgressCountslist()
    // fetchCaseStageCountslist()
    // fetchFlagCountslist()
    // fetchProfileIndicatorCountslist()
    // fetchGenderCountslist()
    // fetchNativeLanguageCountslist()
    // fetchFluentLanguageCountslist()
    // fetchEducationsCountlist()
    // fetchIntermediateLanguageCountslist()
    // fetchNationalityCountslist()
    // fetchCityCountslist()
    // fetchCountryCountslist()
    // fetchCvSourceCountslist()
    // fetchCompanyCountslist()
    // fetchCompanyRoleCountslist()
    // fetchLatestJobCountslist()
    // fetchRecordYearCountstlist()
    // fetchVisaCountslist()
    // fetchCareerCountslist()
    // fetchJobTitleCountslist()
    // fetchIndustriesCountslist()
    // fetchItSkillsCountslist()
    // fetchJobSkillsCountslist()
    // fetchJobCityCountlist()
    // fetchJobCountryCountlist()
    // fetchJobLocalityCountlist()
    // fetchJobProjectCountlist()
    // fetchJobLanguage1Countlist()
  }, []);

  return (
    <React.Fragment>
      {/* <Header mainheaderTitle="DK GLOBAL CRM" textcolor="#3c97b6" /> */}
      <Header mainheaderTitle="RECRUITER ACCOUNT" textcolor="#3c97b6" />
      <Navbar />
      {children}
    </React.Fragment>
  );
};
export default DashboradLayout;
