import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "./dailyapps.css"
import { Tab, Nav } from 'react-bootstrap';
import MonthlyappsTable from "./MonthlyappsTable";
import { Link } from 'react-router-dom';
import { getUserList, getJobBoardList, getMonthlyTracker, fetchByIdMonthly, clearState } from "../../../../slice/trackers/appsTracker/appsTracker";
import Constant from "../../../../constants";
const SHOW_IN_APPS_TRACKER = Constant.SYSTEM_CONSTANTS.Apps_Tracker.USERS_IN_TRACKER;

const MonthTracker = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const { userList } = useSelector(state => state.appsTracker)

    useEffect(() => {
        async function fetchListData() {
            try {
                await dispatch(getUserList({}))
                await dispatch(getMonthlyTracker({}))
                await dispatch(getJobBoardList({}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchListData()
    }, [])

    const deepakId = 1;
    const mukulId = 2;
    const luziaId = 3;
    const mirunaId = 4;
    const onurId = 8;
    const marianId = 6;
    const filipId = 7;

    const handleSelect = async (key) => {
        switch (key) {
            case "rec0":
                await dispatch(fetchByIdMonthly({ userId: deepakId }))// deepak
                break;

            case "rec1":
                await dispatch(fetchByIdMonthly({ userId: mukulId }))
                break;

            case "rec2":
                await dispatch(fetchByIdMonthly({ userId: luziaId }))
                break;

            case "rec3":
                await dispatch(fetchByIdMonthly({ userId: mirunaId }))
                break;
            case "rec4":
                await dispatch(fetchByIdMonthly({ userId: marianId }))
                break;

            case "rec5":
                await dispatch(fetchByIdMonthly({ userId: filipId }))
                break;
            case "rec6":
                await dispatch(fetchByIdMonthly({ userId: onurId }))
                break;

        }
        return false
    }

    return (
        <>
            <div className="container-fluid dk-dailyappsPageCon">
                <Tab.Container className="dk-dailyappsTabCon" id="left-tabs-example" defaultActiveKey="rec-1" onSelect={handleSelect}>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div className="dk-appsleftbtnCon">
                                <Nav variant="pills dk-dailyappsTabs" className="flex-row">
                                    {
                                        userList.filter(items => SHOW_IN_APPS_TRACKER.includes(items.id)).map((result, index) => (
                                            <Nav.Item className="dk-dailyappsTab">
                                                <Nav.Link eventKey={"rec" + index} >
                                                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + result.profileImage} className="dk-dalilrecIMG" alt={result.firstName} title={result.firstName} />
                                                </Nav.Link>
                                            </Nav.Item>
                                        ))
                                    }
                                </Nav>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="dk-dailyappsTitleCon">
                                <h2 className="dk-dailyappsTitle">
                                    MONTHLY JOBS APPLICATIONS
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="dk-appsrightbtnCon">
                                <Link to="/ads-tracker/daily-tracker" className="">Daily</Link>
                                <Link to="/ads-tracker/weekly-tracker" className="" >Weekly</Link>
                                <Link to="/ads-tracker/monthly-tracker" className="active">Monthly</Link>
                                <Link to="/ads-tracker/yearly-tracker" className="">Yearly</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row dk-appTrackerTableBg mt-2">
                        <div className="col-md-12">
                            <Tab.Content>
                                <Tab.Pane eventKey="rec-1">
                                    <MonthlyappsTable />
                                </Tab.Pane>
                                <Tab.Pane eventKey="rec0">
                                    <MonthlyappsTable />
                                </Tab.Pane>
                                <Tab.Pane eventKey="rec1">
                                    <MonthlyappsTable />
                                </Tab.Pane>
                                <Tab.Pane eventKey="rec2">
                                    <MonthlyappsTable />
                                </Tab.Pane>
                                <Tab.Pane eventKey="rec3">
                                    <MonthlyappsTable />
                                </Tab.Pane>
                                <Tab.Pane eventKey="rec4">
                                    <MonthlyappsTable />
                                </Tab.Pane>
                                <Tab.Pane eventKey="rec5">
                                    <MonthlyappsTable />
                                </Tab.Pane>
                                <Tab.Pane eventKey="rec6">
                                    <MonthlyappsTable />
                                </Tab.Pane>

                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default MonthTracker;
