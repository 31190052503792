import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const TimePicker = (props) => {
    // The first commit of Material-UI
    // const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [pickDate, setPickDate] = React.useState(new Date());

    const handleDateChange = (date) => {
        setPickDate(date);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container alignItems="center" justify="center" className='dk-nativetimeepicker-grid'>
                <KeyboardTimePicker
                    // disableToolbar
                    variant="inline"
                    format="hh:mm a"
                    margin="normal"
                    id="date-picker-inline dk-startclocknativepicker"
                    className="dk-nativetimeepicker"
                    value={pickDate}
                    onChange={handleDateChange}
                    keyboardIcon={<AccessTimeRoundedIcon />}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    onClose={() => props.selectedDate(`${pickDate}`)}
                    autoOk={true}
                    error={false}
                    helperText={null}
                />

            </Grid>
        </MuiPickersUtilsProvider>
    );
}
export default TimePicker;