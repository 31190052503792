import React, { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../../../../../node_modules/froala-editor/css/froala_style.min.css";
import "froala-editor/js/plugins.pkgd.min";
import "../../../../../../../node_modules/froala-editor/css/froala_editor.pkgd.min.css";
import { InputGroup, FormControl, Form, Table, Row } from "react-bootstrap";

import {
  clearState,
  updateJob,
  updateMiniSpecTab,
  updateTitleBlocks,
  addInstructions,
} from "../../../../../../slice/jobs/jobsSlice";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../../slice/utils/message/messageSlice";

const Candidate = (jobId) => {
  const dispatch = useDispatch();

  const { isDetailsLoading, jobDetails, isUpdate } = useSelector(
    (state) => state.jobs
  );

  const [data, setData] = useState("");
  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setData(jobDetails);
    }
  }, [isDetailsLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobDetails);
    }
  }, [isUpdate]);

  const sampleAd =
    data.miniSpecTab !== undefined && data.miniSpecTab.length
      ? data.miniSpecTab[0].sample_ad
      : "";
  const [sampleAdText, setSampleAdText] = useState(sampleAd);

  useEffect(() => {
    if (sampleAd !== "") {
      setSampleAdText(sampleAd);
    }
  }, [sampleAd]);

  const handleModelChange = (values) => {
    setSampleAdText(values);
    dispatch(
      updateMiniSpecTab({ id: jobId.jobId, key: "sample_ad", value: values })
    );
  };

  const ad_title_1 =
    data.titleBlocks !== undefined && data.titleBlocks.length
      ? data.titleBlocks[0].ad_title_1
      : "";
  const ad_title_2 =
    data.titleBlocks !== undefined && data.titleBlocks.length
      ? data.titleBlocks[0].ad_title_2
      : "";
  const ad_title_3 =
    data.titleBlocks !== undefined && data.titleBlocks.length
      ? data.titleBlocks[0].ad_title_3
      : "";
  const ad_title_4 =
    data.titleBlocks !== undefined && data.titleBlocks.length
      ? data.titleBlocks[0].ad_title_4
      : "";

  const updateSubjectData = (e) => {
    let values = e.target.value;
    let columnId = e.target.dataset.columnid;
    dispatch(
      updateTitleBlocks({ id: jobId.jobId, key: columnId, value: values })
    );
  };

  const [title, setTitle] = useState("");

  const handleButtonClick = async (e) => {
    e.preventDefault();
    if (title == "") {
      dispatch(showError({ msg: "All fields are required." }));
    } else {
      dispatch(addInstructions({ job_id: jobId.jobId, description: title }));
      setTitle("");
    }
  };

  return (
    <>
      <div className="row dk-Adsbox mt-3 dkg-likedislike-adsboxCon  dkg-likedislike-Con-234">
        <div className="col-md-6">
          <div className="col-md-12 likesdislikesbox">
            <div className="pb-2">
              <h2
                className="text-center pt-2"
                style={{ color: "#10b04d", fontSize: "1.4rem" }}
              >
                LIKED CANDIDATES
              </h2>
              <div className="col-md-12">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">1</InputGroup.Text>
                  <FormControl
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    readOnly style={{ backgroundColor: "#fff", outline: "0", boxShadow: "none" }}
                  />
                </InputGroup>
              </div>
              <div className="col-md-12">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">2</InputGroup.Text>
                  <FormControl
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    readOnly style={{ backgroundColor: "#fff", outline: "0", boxShadow: "none" }}
                  />
                </InputGroup>
              </div>
              <div className="col-md-12">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">3</InputGroup.Text>
                  <FormControl
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    readOnly style={{ backgroundColor: "#fff", outline: "0", boxShadow: "none" }}
                  />
                </InputGroup>
              </div>
              <div className="col-md-12">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">4</InputGroup.Text>
                  <FormControl
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    readOnly style={{ backgroundColor: "#fff", outline: "0", boxShadow: "none" }}
                  />
                </InputGroup>
              </div>
              <div className="col-md-12">
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon1">5</InputGroup.Text>
                  <FormControl
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    readOnly style={{ backgroundColor: "#fff", outline: "0", boxShadow: "none" }}

                  />
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="col-md-12 likesdislikesbox">
            <h2
              className="text-center pt-2"
              style={{ color: "#c45613", fontSize: "1.4rem" }}
            >
              DISLIKED CANDIDATES
            </h2>
            <div className="col-md-12">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">1</InputGroup.Text>
                <FormControl
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  readOnly style={{ backgroundColor: "#fff", outline: "0", boxShadow: "none" }}
                />
              </InputGroup>
            </div>
            <div className="col-md-12">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">2</InputGroup.Text>
                <FormControl
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  readOnly style={{ backgroundColor: "#fff", outline: "0", boxShadow: "none" }}
                />
              </InputGroup>
            </div>
            <div className="col-md-12">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">3</InputGroup.Text>
                <FormControl
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  readOnly style={{ backgroundColor: "#fff", outline: "0", boxShadow: "none" }}
                />
              </InputGroup>
            </div>
            <div className="col-md-12">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">4</InputGroup.Text>
                <FormControl
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  readOnly style={{ backgroundColor: "#fff", outline: "0", boxShadow: "none" }}
                />
              </InputGroup>
            </div>
            <div className="col-md-12">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">5</InputGroup.Text>
                <FormControl
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  readOnly style={{ backgroundColor: "#fff", outline: "0", boxShadow: "none" }}
                />
              </InputGroup>
            </div>
          </div>
          {/* <div className="col-md-12 likesdislikesbox">
            <div className="scrollDiv pb-2">
              <h2 className="text-center pt-2" style={{ fontSize: "1.4rem" }}>
                REVIEW CV BASED ON
              </h2>
              <div className="col-md-12">
                <InputGroup className="mb-3">
                  <InputGroup.Text
                    style={{ minWidth: "110px" }}
                    id="basic-addon1"
                  >
                    Nationality
                  </InputGroup.Text>
                  <FormControl as="textarea" rows="3" name="address" readOnly style={{ backgroundColor: "#fff", outline: "0", boxShadow: "none" }} />
                </InputGroup>
              </div>
              <div className="col-md-12">
                <InputGroup className="mb-3">
                  <InputGroup.Text
                    style={{ minWidth: "110px" }}
                    id="basic-addon1"
                  >
                    Location
                  </InputGroup.Text>
                  <FormControl as="textarea" rows="3" name="address" readOnly style={{ backgroundColor: "#fff", outline: "0", boxShadow: "none" }} />
                </InputGroup>
              </div>
              <div className="col-md-12">
                <InputGroup className="mb-3">
                  <InputGroup.Text
                    style={{ minWidth: "110px" }}
                    id="basic-addon1"
                  >
                    Language
                  </InputGroup.Text>
                  <FormControl as="textarea" rows="3" name="address" readOnly style={{ backgroundColor: "#fff", outline: "0", boxShadow: "none" }} />
                </InputGroup>
              </div>
              <div className="col-md-12">
                <InputGroup className="mb-3">
                  <InputGroup.Text
                    style={{ minWidth: "110px" }}
                    id="basic-addon1"
                  >
                    Seniority
                  </InputGroup.Text>
                  <FormControl as="textarea" rows="3" name="address" readOnly style={{ backgroundColor: "#fff", outline: "0", boxShadow: "none" }} />
                </InputGroup>
              </div>
              <div className="col-md-12">
                <InputGroup className="mb-3">
                  <InputGroup.Text
                    style={{ minWidth: "110px" }}
                    id="basic-addon1"
                  >
                    Work EXP
                  </InputGroup.Text>
                  <FormControl as="textarea" rows="3" name="address" readOnly style={{ backgroundColor: "#fff", outline: "0", boxShadow: "none" }} />
                </InputGroup>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Candidate;
