import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../trainingdetail.scss';

const DetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/admins') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/training-resource/about-dkg') ? 'active' : ''}`}>Team 1</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/training-resource/dkg-team') ? 'active' : ''}`}>Team 2</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/training-resource/timetable') ? 'active' : ''}`}>Team 3</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default DetailLeftPanel;

