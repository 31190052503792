import React from 'react';
import { Link } from 'react-router-dom';
import { BsGraphUp } from "react-icons/bs";
import { SiBmcsoftware } from "react-icons/si";
import { FaCheck, FaUsers, FaBriefcase, FaDesktop, FaPhoneAlt, FaUserAlt, FaEuroSign, FaRegBuilding } from 'react-icons/fa';

const AlertsLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <ul>
                <li><Link to="/dkglobalfinance/invoices/alerts/my-tasks" className={`${pathname.match('/dkglobalfinance/invoices/alerts/my-tasks') ? 'active' : ''}`}><FaCheck /> Inv Process <span className="dk-notiBadge">0</span></Link></li>

                <li><Link to="#" className={`${pathname.match('/notifications/today/new-case') ? 'active' : ''}`}><FaUsers /> Clients <span className="dk-notiBadge">4</span></Link></li>

                <li><Link to="#" className={`${pathname.match('/notifications/today/comments') ? 'active' : ''}`}><FaBriefcase /> Job Board <span className="dk-notiBadge">6</span></Link></li>

                <li><Link to="#" className={`${pathname.match('/notifications/today/new-jobs') ? 'active' : ''}`}><FaDesktop /> IT Services <span className="dk-notiBadge">8</span></Link></li>

                <li><Link to="#" className={`${pathname.match('/notifications/today/live-jobs') ? 'active' : ''}`}><SiBmcsoftware /> Software <span className="dk-notiBadge">11</span></Link></li>

                <li><Link to="#" className={`${pathname.match('/notifications/today/ads-post') ? 'active' : ''}`}><FaPhoneAlt /> Calls & SMS <span className="dk-notiBadge">12</span></Link></li>

                <li><Link to="#" className={`${pathname.match('/notifications/today/resources') ? 'active' : ''}`}><FaEuroSign /> Accountant <span className="dk-notiBadge">18</span></Link></li>

                <li><Link to="#" className={`${pathname.match('/notifications/today/payments') ? 'active' : ''}`}><FaUserAlt /> DK Staff <span className="dk-notiBadge">28</span></Link></li>

                <li><Link to="#" className={`${pathname.match('/notifications/today/sales-kpi') ? 'active' : ''}`}><FaRegBuilding /> DK Global <span className="dk-notiBadge">45</span></Link></li>
            </ul>
        </>
    )
}

export default AlertsLeftPanel;
