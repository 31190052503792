import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import OnlineCvModal from '../../OnlineCvModal';
import { FaDownload } from 'react-icons/fa';


const CandidateDocModal = ({ appliedApplication }) => {

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = async () => {
        setShow(true);
    }
    const [frameType, setFrameType] = useState('0')
    const [fileUrl, setFileUrl] = useState('0')

    function isEmpty(obj) {
        for (var i in obj) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (show) {
            async function fetchData() {

                if (!isEmpty(appliedApplication)) {
                    const fileName = appliedApplication[0].fileName

                    if (fileName != null || '') {
                        setFrameType(fileName.split('.').pop());
                        setFileUrl(fileName.split("https://master-upload.s3.amazonaws.com").pop());
                    } else {
                        setFileUrl('0')
                    }
                } else {
                    setFileUrl('0')
                }
            }
            fetchData();
        }
    }, [show])

    const [isShowOnlineCvModal, setIsOnlineCvModal] = useState(false);
    const hideOnlineCvModal = () => {
        setIsOnlineCvModal(false);
    }

    return (
        <>
            {
                (isShowOnlineCvModal) ? <OnlineCvModal hideOnlineCv={hideOnlineCvModal} /> : null
            }

            <Link to="#" className="dkg-app-viewspec-btn" style={{ backgroundColor: "#d58b5d", color: "#fff" }} onClick={handleShow}>View Application</Link>
            <Modal show={show} onHide={handleClose} className="dk-modalCvRight" dialogClassName="dk-pipedocs-previewModal"
                aria-labelledby="example-custom-modal-styling-title"
                centered animation={false}>
                {/* <Modal.Header closeButton className='dkg-candprof-cvdownload-header'>
                    <Modal.Title>
                        <a href={process.env.REACT_APP_INTERNAL_BASE_URL + fileUrl} download target={"_blank"} className='dkg-cvdownload-title'>Application Download</a>
                    </Modal.Title>
                </Modal.Header> */}
                <Modal.Header closeButton className='dkg-candprof-cvdownload-header dkg-js-reviewapp-header'>
                    <Modal.Title className='dkg-js-reviewapp-title'>
                        <a href={process.env.REACT_APP_INTERNAL_BASE_URL + fileUrl} download target={"_blank"} title='Download' className='dkg-reviewapp-dwnld-icon'><FaDownload /></a>
                        Review Job Application
                    </Modal.Title>
                </Modal.Header>
                {
                    (frameType != '0') ?
                        (fileUrl != '0') ?
                            <Modal.Body className='dk-pipedocs-preview-modalbody'>
                                {
                                    (frameType === 'pdf' || frameType === 'PDF') ?
                                        <iframe src={process.env.REACT_APP_INTERNAL_BASE_URL + fileUrl} frameBorder={0}></iframe>
                                        :
                                        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${process.env.REACT_APP_INTERNAL_BASE_URL + fileUrl}`} frameBorder={0}></iframe>
                                }
                            </Modal.Body>
                            : <Modal.Body className='dk-pipedocs-preview-modalbody'>
                                <h1>Document Not Found</h1>
                            </Modal.Body>
                        : <iframe src={process.env.REACT_APP_INTERNAL_BASE_URL + fileUrl} frameBorder={0} style={{ height: "calc(100vh - 40px)" }}></iframe>
                }
            </Modal>
        </>
    )
}
export default CandidateDocModal;