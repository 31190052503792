import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const ProjectionSubmenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[2];
    const segment2 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/recruitment/projections/salaries" className={(segment1 == 'projections' && segment2 == 'salaries') ? 'active' : ''}>Salaries</Link></li>
                        <li><Link to="/recruitment/projections/income-tax" className={`${(segment2 == 'income-tax') ? 'active' : ''}`}>Income Tax</Link></li>
                        <li><Link to="/recruitment/projections/cost-of-living" className={`${(segment2 == 'cost-of-living') ? 'active' : ''}`}>Cost of Living</Link></li>
                        <li><Link to="/recruitment/projections/job-profiles" className={`${(segment2 == 'job-profiles') ? 'active' : ''}`}>Job Profiles</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default ProjectionSubmenu;
