import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import "../../jobs/addnew/addnewjob.scss";
import SearchFilter from '../../jobs/addnew/SearchFilter';
import { WordFileUpload } from "./WordFileUpload";
import { PdfFileUpload } from "./PdfFileUpload";
const FileModal = ({ handleClose }) => {
    return (
        <>
            <Modal className="dk-cloudFilesModal" centered show={true} onHide={handleClose}>
                <Modal.Header className='dk-cloudFilesModalHead' closeButton>
                    <Modal.Title>DK Global Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                <div className="dk-jobsCreatePageCon mt-0">
                <div className="dkcreate-FromSection mt-0" style={{height:'auto'}}>
                <iframe style={{display: 'block !important',height:'90vh',width:'100%',border:'0'}} src='https://view.officeapps.live.com/op/embed.aspx?src=https://dvirde2b6zhne.cloudfront.net/1638543272-About-DKGlobalRecruitmentLimited.docx'></iframe>
                </div>
            </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default FileModal;
