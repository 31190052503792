import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
import { DropdownButton } from 'react-bootstrap';
//import { Link } from 'react-router-dom';
import "./listview.scss";
import DetialViewPoup from "./modal/DetialViewPoup";
import AddNewListView from './modal/AddNewListView'
import EditListView from "./modal/EditListView";

import DeleteModal from "../../../pages/tasks/utils/delete/PaymentDelete";
import Pagination from '@material-ui/lab/Pagination';
///import FlagImg from "../../../../assets/images/clientflag.jpg";
//import UserIcon from "../../../../assets/images/deepak.png";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useAsyncDebounce } from 'react-table';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faPencilAlt, faTrashAlt, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { companyData, getprojectlist, deleteCompany, fetechById, updateValue, clearState, fetchCompanyProject } from "../../../../slice/companies/clients/clientSlice";
//import CustomDateFormat from "../../../ui/dateFormats/CustomDateFormat";
import { showSuccess, showError } from "../../../../slice/utils/message/messageSlice";
import { clearLoading } from "../../../../slice/utils/loader/linearSlice";
import CaseStatusRenderDiv from './CaseStatusRenderDiv';
import ReorderModal from './modal/ReorderModal'
//import CheckboxDummy from './modal/checkbox/CheckboxDummy'
import RadioButton from "./modal/checkbox/radioBtn";
import $ from "jquery";

const useInstance = (instance) => {
    if (instance && instance.getInstanceCallback) {
        instance.getInstanceCallback(instance);
    }
};

function Table({ columns, data, getInstanceCallback, caseStatusId, updateMyData }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        page,
        prepareRow,
        state,
        setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            updateMyData,
            getInstanceCallback,
            initialState: {
                pageIndex: 0, pageSize: 100, hiddenColumns: ["statusId"]
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy, usePagination, (hooks) => hooks.useInstance.push(useInstance)
    )

    const [filterType, setFilterType] = useState('All')

    useEffect(() => {
        if (caseStatusId === 0) {
            setTimeout(() => {
                setFilter('inStatus', '')
            }, 200)
        } else {
            setFilterType('inStatus')
            doFilter(caseStatusId);
        }
    }, [caseStatusId])

    const doFilter = React.useCallback(
        useAsyncDebounce((id) => { setFilter(filterType, id) }, 200), [filterType]
    );


    const handlePageChange = (event, value) => {
        gotoPage(Number(value) - 1);
    };

    return (
        <React.Fragment>
            <table {...getTableProps()} className="w-100 dk-clListViewTable">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, index) => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                // (index === 0) ?
                                //     <React.Fragment>
                                //         <div className='d-flex company-list'>
                                //             <input
                                //                 className="form-control"
                                //                 placeholder={`Search`}
                                //             />
                                //             <div className="input-group-append">
                                //                 <button className="btn btn-secondary" type="button">
                                //                     <i className="fa fa-search"></i>
                                //                 </button>
                                //             </div>
                                //         </div>
                                //     </React.Fragment> :
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td
                                            {...cell.getCellProps([
                                                {
                                                    className: cell.column.className, // pay attention to this
                                                    style: cell.column.style,
                                                    // set here your other custom props
                                                },
                                            ])}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            )
                        }
                    )}
                </tbody>
                <tfoot>
                    {footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()}>
                            {group.headers.map(column => (
                                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
            <div className="col-sm-12">
                <div className="dk-resPaginationCon pagination">
                    <div className="dk-resPagination">
                        <div className="dk-resLeftPag">
                            <div className="dk-texCounter" style={{ color: '#fff !imporant' }}>
                                Showing {' '}{pageIndex + 1} to {pageOptions.length} (of {rows.length} entries)
                            </div>
                        </div>
                        <div className="dk-resrightPag pagination-btns">
                            <Pagination count={pageCount} shape="rounded" onChange={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function debounce(func, wait, immediate) {
    var timeout;
    return (...args) => {
        var context = this;
        var later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isClearAll
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState("")

    // const [value, setValue] = React.useState(globalFilter)
    // const onChange = useAsyncDebounce(value => {
    //     setGlobalFilter(value || undefined)
    // }, 200)

    const onChangeSearchTitle = (e) => {
        const { value: nextValue } = e.target;
        setValue(e.target.value)
        debouncedSave(nextValue);
    };

    const debouncedSave = React.useCallback(
        debounce(nextValue => {
            setGlobalFilter(nextValue)
        }, 1000),
    );

    const clearSearch = () => {
        setValue('')
        setTimeout(() => {
            setGlobalFilter(undefined)
        }, 200)
    }

    useEffect(() => {
        if (isClearAll) {
            clearSearch()
        }
    }, [isClearAll])

    return (
        <React.Fragment>
            <input
                value={value}
                className="form-control"
                onChange={onChangeSearchTitle}
                placeholder={`Search`}
            />
            {
                (value !== undefined && value.length > 0) ?
                    <div className="input-group-append">
                        <button className="btn btn-secondary" type="button" onClick={clearSearch}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div> : <div className="input-group-append">
                        <button className="btn btn-secondary" type="button">
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
            }
        </React.Fragment>
    )
}

const ListView = () => {
    const dispatch = useDispatch();
    const { isLoading, companyList, isError, isUpdate, message, isDelete, isInsert, isCompanyDelete } = useSelector(state => state.companies);

    const [dataList, setDataList] = useState([])
    const [list, setList] = useState([]);
    //const [projectList, setProjectList] = useState([]);
    const [rowId, setRowId] = useState('0')

    const [clientidd, setClientidd] = useState('0')
    //const [defaulImg, setDefaultImg] = useState('')

    useEffect(() => {
        dispatch(clearState())
        const fetchList = async () => {
            try {
                await dispatch(companyData())
            } catch (err) {
                console.log(err)
            }
        }
        fetchList()
    }, [isCompanyDelete])

    // useEffect(() => {
    //     if (!isLoading && companyProjectList && companyProjectList.length > 0) {
    //         setProjectList(companyProjectList)
    //     } else {
    //         setProjectList(companyProjectList)
    //     }
    // }, [companyProjectList])

    useEffect(() => {
        if (!isLoading && companyList && companyList.length > 0) {
            setDataList(companyList)
        } else {
            setDataList(companyList)
        }
    }, [isLoading, companyList, isDelete])


    const data = useMemo(() => dataList, [dataList])

    const [isProfile, setIsProfile] = useState(false);
    const [compname, setCompname] = useState('');

    const showProfile = (id, clientid, compname) => {
        setClientidd(clientid)
        setRowId(id)
        setCompname(compname)
        dispatch(fetechById({ id: clientid }));
        dispatch(fetchCompanyProject({ id: id }));
        dispatch(getprojectlist({ companyId: clientid }))

        setTimeout(() => {
            setIsProfile(true)
        }, 2000)
    }
    const hideProfile = () => {
        setIsProfile(false)
        dispatch(clearState())
        const fetchList = async () => {
            try {
                await dispatch(companyData())
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
    }

    const [isListEdit, setIsListEdit] = useState(false);
    const [isAddNewList, setIsAddNewList] = useState(false);
    // const showListEdit = async (id) => {
    //     await dispatch(fetechById({ id }))
    //     setIsListEdit(true)
    // }

    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Delete successfully.' }))
            setRowId('0')
            hideDeleteModal()
            $('.chkCandidate').prop('checked', false);
            setIsActive(false);
        }

    }, [isDelete])

    useEffect(() => {
        if (isError) {
            //dispatch(showError({ msg: message }))
            dispatch(clearLoading())
            setIsListEdit(false);
        }
        if (isUpdate) {
            dispatch(clearState())
            setIsListEdit(false);
            $('.chkCandidate').prop('checked', false);
            setIsActive(false);
        }
        if (isInsert) {
            setDataList(companyList)
        }
    }, [isError, isUpdate, isInsert])

    const hideListEdit = () => {
        setIsListEdit(false)
    }
    const showAddNewListView = () => {

        setIsAddNewList(true)
    }
    const hideAddNewListView = () => {
        setIsAddNewList(false)
    }
    const [isDeleteModal, setIsDeleteModal] = useState(false);

    // const showDeleteModal = (id) => {
    //     setRowId(id)
    //     setIsDeleteModal(true)
    // }
    const hideDeleteModal = () => {
        setIsDeleteModal(false)
    }

    const deleteItem = async () => {
        //await dispatch(deleteCompany({ id: rowId }))
    }

    const closeDelete = () => {
        setIsDeleteModal(false)
    }

    const [dataRowId, setDataRowId] = useState(0)
    const [countCheck, setCountCheck] = useState(0)
    const [isActive, setIsActive] = useState(false);
    //const [showEdit, SetshowEdit] = useState(false);

    const checkActiveExtraDiv = async (isShowExitra, checkedBoxCount) => {

        if (isShowExitra) {
            setIsActive(true)
            setCountCheck(checkedBoxCount)
        } else {
            setIsActive(false)
            setCountCheck(checkedBoxCount)
        }

    }

    const taskFunction = async (e) => {

        await dispatch(fetechById({ id: dataRowId }))
        setIsListEdit(true)
        //SetshowEdit(true)
    }

    const handleToggle = () => {
        $('.chkCandidate').prop('checked', false);
        setIsActive(false);
    };

    const columns = useMemo(() => [
        {
            Header: 'Case ID',
            accessor: 'clientId',
            Cell: ({ cell }) => {
                return (
                    <React.Fragment>
                        <div className="d-flex">
                            {/* {
                                Number(cell.row.id) + 1
                            } */}
                            <span className="dk-clupdeProfile ml-2 mr-2" onClick={(e) => showProfile(cell.row.original.id, cell.row.original.clientId, cell.row.original.name)}>
                                <i className="fa fa-chevron-right" ></i>
                            </span>

                            <span className="dkg-id-number">{cell.row.original.clientId}</span>
                            <RadioButton id={cell.row.original.clientId} checkActiveExtraDiv={checkActiveExtraDiv} setDataRowId={setDataRowId} />
                        </div>
                    </React.Fragment>
                )
            }
        },

        {
            Header: 'Status',
            accessor: 'inStatus',
            Cell: ({ cell }) => {
                const onItemClick = (eventkey) => {
                    let data = eventkey.split('=')
                    updateMyData(data[0], data[1], data[2], data[3])
                };
                let statusVal = '';
                switch (cell.row.original.inStatus) {
                    case 0:
                        statusVal = 'Closed'
                        break;
                    case 1:
                        statusVal = 'Active'
                        break;
                    case 2:
                        statusVal = 'Inactive'
                        break;

                }
                return (
                    <React.Fragment>
                        <div className="dk-statusDropdown">
                            <DropdownButton title={<CaseStatusRenderDiv value={statusVal} />}
                                onSelect={onItemClick}>
                                <Dropdown.Item eventKey={`1=#368836=${cell.row.original.clientId}=inStatus`} href="#" className="dk-activeBtn">Active</Dropdown.Item>
                                <Dropdown.Item eventKey={`2=#eaa03d=${cell.row.original.clientId}=inStatus`} href="#" className="" style={{ backgroundColor: "#eaa03d", color: "#fff" }}>Inactive</Dropdown.Item>
                                <Dropdown.Item eventKey={`0=#dc3545=${cell.row.original.clientId}=inStatus`} href="#" className="dk-inActiveBtn">Closed</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </React.Fragment>
                )
            }
        },
        {
            Header: 'AM',
            accessor: 'amInfo.firstName',
            Cell: ({ cell }) => {
                // setDefaultImg(cell.row.original.amInfo ?  cell.row.original.amInfo.profileImage : 'image not calll')
                return (
                    <React.Fragment>
                        <div className="d-flex justify-content-center">
                            <div className="dk-userIcon">
                                {/* <img src={UserIcon} alt="" /> */}

                                <img src={cell.row.original.amInfo ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.amInfo.profileImage : ''} className="filterImg" />
                            </div>
                            {/* {cell.row.original.manager} */}
                        </div>
                    </React.Fragment>
                )
            }
        },
        {
            Header: 'Category',
            accessor: 'type',
        },
        {
            Header: 'Name of Client',
            accessor: 'name',
        },
        {
            id: 'statusId',
            Header: 'Status Id',
            accessor: 'status',
            show: false
        },
        {
            Header: 'Country',
            accessor: 'country',
        },
        {
            Header: 'City/Town',
            accessor: 'city',
        },
        {
            Header: 'GEOGRAPHY',
            accessor: 'collaboration',
            // Cell: ({ cell }) => {
            //     return <CustomDateFormat date={cell.row.original.collaboration} format='DD MMM YYYY' />
            // }
        },
        {
            Header: 'Industry',
            accessor: 'industry',
        },
        {
            Header: 'Business',
            accessor: 'business'
        },
        {
            Header: 'CONTRACT',
            accessor: 'incomeTax'
        },
        {
            Header: 'RENEWAL',
            accessor: 'renewal',
            // Cell: ({ cell }) => {
            //     return <CustomDateFormat date={cell.row.original.renewal} format='DD MMM YYYY' />
            // }
        },
        {
            Header: 'Fee Type',
            accessor: 'feeType',

        },
        // {
        //     //Header: 'Logo',
        //     accessor: 'logo',
        //     Cell: ({ cell }) => {
        //         return (
        //             <div className="dk-clistViewTableIcn">
        //                 <img className="dk-cllistViewTabImg" src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.logo} alt={cell.row.original.name + "-logo"} />
        //             </div>
        //         )
        //     }
        // },
        // {
        //     Header: 'Action',
        //     accessor: 'action',
        //     Cell: ({ cell }) => {
        //         return (
        //             <React.Fragment>
        //                 <div className="d-flex justify-content-center">
        //                     <Link to="#" className="dk-editBtn mr-2" onClick={(e) => showListEdit(cell.row.original.id)}><FontAwesomeIcon icon={faPencilAlt} /></Link>
        //                     <Link to="#" className="dk-deleteBtn" onClick={() => showDeleteModal(cell.row.original.id)} ><FontAwesomeIcon icon={faTrashAlt} /></Link>
        //                     {cell.row.original.action}
        //                 </div>
        //             </React.Fragment>
        //         )
        //     }
        // }
    ], [])

    const [isRorder, setIsRorder] = useState(false);

    const hidereorderModal = () => {
        setIsRorder(false);
    }

    const [instanceData, setInstanceData] = useState('0')
    const reactTableInstance = (instance) => {
        if (instance) {
            setList(instance.flatRows)
            setInstanceData(instance)
        }
    };

    const [isClearAll, setIsClearAll] = useState(false)

    const updateMyData = async (value, style, id, columnId) => {
        dispatch(updateValue({ id, key: columnId, value }))
    }

    const [caseStatusFilterDDLText, setCaseStatusFilterDDLText] = useState('Status')
    const [caseStatusId, setCaseStatusId] = useState(0)

    const onSelectStatusFilter = (eventKey) => {

        switch (eventKey) {
            case '0':
                setCaseStatusId(eventKey)
                setCaseStatusFilterDDLText('Inactive')
                break
            case '1':
                setCaseStatusId(eventKey)
                setCaseStatusFilterDDLText('Active')
                break
            default:
                setCaseStatusId(eventKey)
                setCaseStatusFilterDDLText('Qualifying')
                break;
        }

    }

    const removeTag = async (e) => {
        const type = e.target.dataset.type;
        setCaseStatusId('')
        setCaseStatusFilterDDLText('Status')
        // switch (type) {
        //     case 'status':
        //         setCaseStatusId('')
        //         setCaseStatusFilterDDLText('Status')
        //         break;

        //     default:
        //         break;
        // }
    }

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const deleteItemBulk = async () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
        } else {
            var canIds = [];
            $.each($(".chkCandidate:checked"), function () {
                canIds.push($(this).val());
            });
            setLoading(true)
            setSuccess(false)
            await dispatch(deleteCompany({ id: canIds }))
            $('.chkCandidate').prop('checked', false);
            setIsActive(false);
        }
    }

    return (
        <React.Fragment>
            {
                (isProfile) ? <DetialViewPoup onHide={hideProfile} id={rowId} clientidd={clientidd} list={list} compname={compname} /> : null
            }

            {
                (isAddNewList) ? <AddNewListView handleClose={hideAddNewListView} /> : null
            }
            {
                (isListEdit) ? <EditListView handleClose={hideListEdit} /> : null
            }
            {
                (isDeleteModal) ? <DeleteModal id={rowId} onHide={hideDeleteModal} deleteItem={deleteItem} handleClose={closeDelete} /> : null
            }

            <div className="container-fluid dk-cliListViewPageCont">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="d-flex dkg-clientslistHeader justify-content-between align-items-center">
                            <div className="dkg-listviewleftCon">
                                <div className="dk-clientslistSearch mr-2">
                                    <div className="input-group">
                                        {
                                            (instanceData !== '0') ? <GlobalFilter
                                                preGlobalFilteredRows={instanceData.preGlobalFilteredRows}
                                                globalFilter={instanceData.state.globalFilter}
                                                setGlobalFilter={instanceData.setGlobalFilter}
                                                isClearAll={isClearAll}
                                            /> : null
                                        }
                                    </div>
                                </div>
                                {/* <button className="dk-reloadBtn" onClick={showAddNewListView}><i className="fas fa-plus" aria-hidden="true"></i></button> */}
                            </div>
                            <div className="dk-clntsPageTitle">
                                <div className=''>CLIENTS LIST</div>
                            </div>
                            <div className="dk-clients-right-statusFilter">
                                {
                                    (isRorder) ? <ReorderModal hidePopup={hidereorderModal} /> : null
                                }
                                {/* <div className="mr-2 dk-resCrcleIconCon">
                                    <Link to="#" className="dk-reloadBtn"><i className="fas fa-refresh"></i> </Link>
                                </div> */}
                                <div className="mr-2 dk-resCrcleIconCon">
                                    <ReorderModal dataList={data} />
                                </div>
                                <DropdownButton className='dk-cli-status-dropdown' title={caseStatusFilterDDLText} onSelect={onSelectStatusFilter}>
                                    <Dropdown.Item eventKey="1" href="#" className="dk-activeBtn" style={{ backgroundColor: "#3a9469", color: "#fff" }}>Active
                                        <span className="dkg-lv-statuscounter">
                                            {
                                                list.filter(function (val) {
                                                    return val.original.inStatus === 1;
                                                }).length
                                            }
                                        </span>
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="2" href="#" className="" style={{ backgroundColor: "#eaa03d", color: "#fff" }}>Inactive
                                        <span className="dkg-lv-statuscounter">
                                            {
                                                list.filter(function (val) {
                                                    return val.original.inStatus === 2;
                                                }).length
                                            }
                                        </span>
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="0" href="#" className="dk-inActiveBtn" style={{ backgroundColor: "#dc3545", color: "#fff" }}> Closed
                                        <span className="dkg-lv-statuscounter">
                                            {
                                                list.filter(function (val) {
                                                    return val.original.inStatus === 0;
                                                }).length
                                            }
                                        </span>
                                    </Dropdown.Item>
                                </DropdownButton>
                                {
                                    (caseStatusFilterDDLText !== 'Status') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="inStatus" onClick={removeTag}></i> : null
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="listViewTableCon table-responsive">
                            <Table
                                columns={columns}
                                data={data}
                                getInstanceCallback={reactTableInstance}
                                caseStatusId={caseStatusId}
                                updateMyData={updateMyData}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={`batch-actions-menu-wrapper dk-action-popupMain ${isActive ? "activePopup" : ""}`} style={{ width: 'auto' }}>
                <div className="num-of-actions_wrapper">
                    <div className='num-of-actions'>{countCheck}</div>
                </div>
                <div className="batch-actions-title-section pl-0">
                    <div className='dk-rowSelected'>
                        <span>Row Selected</span>
                    </div>
                </div>

                <div className="batch-actions-item dk-checkAll" onClick={taskFunction}>
                    <span onClick={taskFunction}><i className="far fa-edit"></i></span>
                    Edit
                </div>


                <DeleteModal loading={loading} success={success} deleteItem={deleteItemBulk} tableName="jobs_companies" />
                <div className="batch-actions-delete-item CloseAll d-flex align-items-center justify-content-center" onClick={handleToggle}>
                    <span><i className="fas fa-times"></i></span>
                </div>
            </div>

        </React.Fragment>
    )
}

export default ListView;
