import React, { useState } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Select } from 'semantic-ui-react';
import './liveprojects.scss';

const jobStatusOptions = [
    {
        key: 'normal',
        value: 'normal',
        text: 'Normal',
        style: {
            background: '#3f9e6f',
            color: '#fff',
        }
    },
    {
        key: 'qualify',
        value: 'qualify',
        text: 'Qualify',
        style: {
            background: '#fbc916',
            color: '#333',
        }
    },
    {
        key: 'urgent',
        value: 'urgent',
        text: 'Urgent',
        style: {
            background: '#e2445b',
            color: '#fff',
        }
    },
]

const jobStageOptions = [
    {
        key: 'live',
        value: 'live',
        text: 'Live',
        style: {
            background: '#3f9e6f',
            color: '#fff',
        }
    },
    {
        key: 'closed',
        value: 'closed',
        text: 'Closed',
        style: {
            background: '#e2445b',
            color: '#fff',
        }
    },
]

const EditProjects = ({ handleClose }) => {
    return (
        <>
            <Modal className="dk-liveProject-editModal" centered show={true} onHide={handleClose}>
                <Modal.Body>
                    <div className="dk-editModal-tabs">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                            <div className="dk-editModal-tabsNav">
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_1">Summary</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_2">Job Details</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_3">Company</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_4">Preference</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_5">Interviews</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_6">Job Plan</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_7">Ads</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_8">Resources</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="dk-editModal-tabsPanel">
                                <Tab.Content>
                                    <Tab.Pane eventKey="tabs_1">
                                        <div className="dk-editPanelcols">
                                            <div className="dk-editPanel-box">
                                                <div className="row">
                                                    <div className="col-md-11 ml-auto">
                                                        <div className="dk-editModal-textCols">
                                                            <div className="dk-jobIDTitle">Job ID</div>
                                                            <div className="dk-editModal-text">
                                                                <div className="title">Content Reviewer Job in Krakow, Poland - Social Media</div>
                                                            </div>
                                                            <div className="dk-editModal-titleBtn">
                                                                <Link to="#" title="Comment">0</Link>
                                                            </div>
                                                        </div>
                                                        <div className="dk-editModalUser-main">
                                                            <div className="d-flex align-items-center">
                                                                <div className="dk-editModalUser-user">
                                                                    <div className="d-flex align-items-center">
                                                                        <span className="dk-dropText">AM</span>
                                                                        <button><i className="fas fa-user"></i></button>
                                                                    </div>
                                                                </div>
                                                                <div className="dk-editModalUser-img">
                                                                    <Link to="#">
                                                                        <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid" alt="" />
                                                                    </Link>
                                                                    <Link to="#">
                                                                        <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" className="img-fluid" alt="" />
                                                                    </Link>
                                                                    <Link to="#">
                                                                        <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5e54fdac9c.png" className="img-fluid" alt="" />
                                                                    </Link>
                                                                    <Link to="#">
                                                                        <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                                    </Link>
                                                                    <Link to="#" className="dk-addRec">
                                                                        <i className="fas fa-plus"></i>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dk-editModalListing">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="dk-editModalListing-cols">
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Job Status</label>
                                                                <label htmlFor="" className="dkdropDown">
                                                                    <Select placeholder='Normal' options={jobStatusOptions} />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Job Stage</label>
                                                                <label htmlFor="" className="dkdropDown">
                                                                    <Select placeholder='Live' options={jobStageOptions} />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Job Stage</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="10" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Target</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="2" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Filled</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="10" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Pending</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="0" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Total Sale</label>
                                                                <label htmlFor="" className="dk-date">
                                                                    <span><i className="fas fa-euro-sign"></i></span>
                                                                    <input type="text" value="0" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Fee Per Hire</label>
                                                                <label htmlFor="" className="dk-date">
                                                                    <span><i className="fas fa-euro-sign"></i></span>
                                                                    <input type="text" value="0" className="form-control" />
                                                                </label>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="dk-editModalListing-cols">
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Category</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="Standard" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Company</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="DK Global" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Project</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="Software" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Role Type</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="UI Designer" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Language 1</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="English" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Language 2</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="Irish" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Job City</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="Dublin" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Job Country</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="Ireland" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Start Date 1</label>
                                                                <label htmlFor="" className="dk-date">
                                                                    <span><i className="far fa-calendar-alt"></i></span>
                                                                    <input type="text" value="25 Jun 2021" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Start Date 2</label>
                                                                <label htmlFor="" className="dk-date">
                                                                    <span><i className="far fa-calendar-alt"></i></span>
                                                                    <input type="text" value="25 Aug 2021" className="form-control" />
                                                                </label>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="dk-editModalListing-cols">
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Qualifying</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="0" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Job Specs</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="0" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Sendouts</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="0" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Int Specs</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="0" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Interviews</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="0" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Job Offers</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="2" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Joined</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="0" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Rejected</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="0" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Withdrew</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="0" className="form-control" />
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-1">
                                                                <label htmlFor="">Not Reachable</label>
                                                                <label htmlFor="">
                                                                    <input type="text" value="0" className="form-control" />
                                                                </label>
                                                            </div>


                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_2">
                                        2
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_3">
                                        3
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_4">
                                        4
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_5">
                                        5
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_6">
                                        6
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_7">
                                        7
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_8">
                                        8
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditProjects;
