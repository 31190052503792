import React, { useEffect, useState, useRef } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import { Dropdown } from 'react-bootstrap';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    < a
        href="/#"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a >
));

const CustomMenu = React.forwardRef(
    ({ isSelected, children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const searchInput = useRef(null);
        const [value, setValue] = useState('');

        useEffect(() => {
            if (isSelected) {
                setValue('')
                searchInput.current.focus();
            }
        }, [isSelected])

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className=""
                    placeholder="Search Text..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    ref={searchInput}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toString().toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);

const getReactDomComponent = (dom) => {
    const internalInstance = dom[Object.keys(dom).find(key =>
        key.startsWith('__reactProps$')
    )];
    if (!internalInstance) return null;
    return internalInstance.value;
}

function SearchableDropdownLanguage(props) {
    const [data, setData] = useState(false)
    return (
        <>
            <Dropdown onSelect={function (evt, value) {
                setData(true)
                props.updateFilter(evt, getReactDomComponent(value.target));
            }}>
                <Dropdown.Toggle id={props.id} className={props.className} variant="">
                    {(!props.isFilter) ?
                        <React.Fragment><i className="fas fa-plus pr-2"></i>  {props.defaultTxt}</React.Fragment>
                        :
                        <React.Fragment>
                            <div className='dropdown-selection d-flex align-items-center w-100'>
                                <i className="fas fa-plus pr-2"></i> {props.defaultTxt}
                            </div>
                            <i className="fas fa-times-circle crossBtn" data-key={props.filterFor} onClick={props.removeFilter}></i>
                        </React.Fragment>
                    }
                </Dropdown.Toggle>
                <Dropdown.Menu variant="" className='visible menu dkg-rec-srch-drop-menu' as={CustomMenu} isSelected={data}>
                    {props.list.map((item, index) => (
                        <Dropdown.Item eventKey={(item.languageDetails[0].value !== '' ? item.languageDetails[0].value : 'Blank')} value={props.filterFor} key={index + 1}>
                            {(item.languageDetails[0].value !== '' ? item.languageDetails[0].value : 'Blank')}<span className='count'>{item.count}</span>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default SearchableDropdownLanguage = React.memo(SearchableDropdownLanguage);