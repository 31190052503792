import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import "./pipeprofileind.scss";

const profileIndicatorProject = ({ list, id, img, selectUserFilter }) => {
    return (
        <Dropdown className="dk-pipe-profileind-rtDropdown">
            <Dropdown.Toggle variant="" className="dk-pipe-profileind-Dropdown-toggle">
                {
                    (id === '0') ? <i className="fas fa-circle"></i> : <i className="fas fa-circle profile-indicator-selector" style={{ color: img }}></i>
                }
            </Dropdown.Toggle>
            <Dropdown.Menu className="dk-pipe-profileind-rtDropdownMenu">
                <div className='d-flex'>
                    <Dropdown.Item href="#" className="dk-profileind-rtDropdownItem" style={{ color: "green" }} data-type="PIID" data-id="2" data-img="green" onClick={selectUserFilter}>
                        <i className="fas fa-circle" data-type="PIID" data-id="2" data-img="green" onClick={selectUserFilter}></i>
                        <span className="proflieCount" data-type="PIID" data-id="2" data-img="green" onClick={selectUserFilter}>Updated</span>
                        <span className="proflieCount" data-type="PIID" data-id="2" data-img="green" onClick={selectUserFilter}>
                            {
                                list.filter(function (val) {
                                    return val.original.candidateInfo.profileIndicator === 2;
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#" className="dk-profileind-rtDropdownItem" style={{ color: "#d3d3d3" }} data-type="PIID" data-id="1" data-img="#d3d3d3" onClick={selectUserFilter}>
                        <i className="fas fa-circle" data-type="PIID" data-id="1" data-img="#d3d3d3" onClick={selectUserFilter}></i>
                        <span className="proflieCount" data-type="PIID" data-id="1" data-img="#d3d3d3" onClick={selectUserFilter}>Not Updated</span>
                        <span className="proflieCount" data-type="PIID" data-id="1" data-img="#d3d3d3" onClick={selectUserFilter}>
                            {
                                list.filter(function (val) {
                                    return val.original.candidateInfo.profileIndicator === 1;
                                }).length
                            }
                        </span>
                    </Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}
export default profileIndicatorProject;