import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import './mainnotification.scss';

const Summary = () => {
    return (
        <>
            {/* <div className="container-fluid dk-maimenu-todoTaskMain dk-notificationSummary-main">
                <h3 className="title">TODAY’S NOTIFICATIONS</h3>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="dk-notiSummaryCols">
                                <div className="title">Company</div>
                                <div className="dk-panel">
                                    <div className='dkg-bigsizenum'>
                                        <h2>1</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-notiSummaryCols">
                                <div className="title">Employee</div>
                                <div className="dk-panel">
                                    <div className='dkg-bigsizenum'>
                                        <h2>7</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-notiSummaryCols">
                                <div className="title">Supplier</div>
                                <div className="dk-panel">
                                    <div className='dkg-bigsizenum'>
                                        <h2>8</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-notiSummaryCols">
                                <div className="title">RESOURCE</div>
                                <div className="dk-panel">
                                    <div className='dkg-bigsizenum'>
                                        <h2>3</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-notiSummaryCols">
                                <div className="title">Clients</div>
                                <div className="dk-panel">
                                    <div className='dkg-bigsizenum'>
                                        <h2>3</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-notiSummaryCols">
                                <div className="title">Jobs</div>
                                <div className="dk-panel">
                                    <div className='dkg-bigsizenum'>
                                        <h2>4</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-notiSummaryCols">
                                <div className="title">CRM</div>
                                <div className="dk-panel">
                                    <div className='dkg-bigsizenum'>
                                        <h2>2</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="dk-notiSummaryCols">
                                <div className="title">TRAINING</div>
                                <div className="dk-panel">
                                    <div className='dkg-bigsizenum'>
                                        <h2>2</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Summary;
