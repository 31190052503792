import React from 'react';
import { Link } from "react-router-dom";
import './experience.scss';
import ExpCard from "./ExpCard";
const Experience = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="dk-expMainCon">
                        <div className="topHead">
                            <div className="leftCon">
                                <div className="title">Work & Experience <span className="sbtitle">(Total Working Exp. 5.7 years)</span></div>
                            </div>
                            <div className="rightCon">
                                <div className="btnCon">
                                    <Link to="#" className="mr-3"><i className="fas fa-plus mr-2"></i> Fill Gap Period</Link>
                                    <Link to="#" ><i className="fas fa-plus mr-2"></i> New Experience</Link>
                                </div>
                            </div>
                        </div>
                        <ul className="description">
                            <li>3 year and 7 months experience as PHP Developer in Karmatech Media works Pvt. Ltd (karmatech) (May 2016 to Till Now) New Delhi .</li>
                            <li>1 year 6 Month experience as PHP Developer in CNT Technologies Pvt. Ltd. (Dec 2014 to June 2016) New Delhi.</li>
                            <li>6 Month experience (as a Trainee) in IT Industry as a junior PHP Developer in XPERIA Technologies Pvt. Ltd (April 2014-sep 2014) New Delhi.</li>
                        </ul>
                        <div className="dk-expCardCon">
                            <ExpCard stepCounter="1" jobTitle="Java Developer" />
                            <ExpCard stepCounter="2" jobTitle="React Developer" />
                            <ExpCard stepCounter="3" jobTitle="Node Developer" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Experience;
