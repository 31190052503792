import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const TehreemSubmenu = () => {
    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    const segment4 = pathname.split("/")[4];
    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/projects/tehreem/summary" className={(segment3 == 'summary') ? 'active' : ''}>Summary</Link></li>
                        <li><Link to="/projects/tehreem/live/summary" className={`${(segment3 == 'live') ? 'active' : ''}`}>Live</Link></li>
                        <li><Link to="/projects/tehreem/closed/summary" className={`${(segment3 == 'closed') ? 'active' : ''}`}>Closed</Link></li>
                        <li><Link to="/projects/tehreem/sales/summary" className={`${(segment3 == 'sales') ? 'active' : ''}`}>Sales</Link></li>
                        <li><Link to="/projects/tehreem/kpi/summary" className={`${(segment3 == 'kpi') ? 'active' : ''}`}>KPI's</Link></li>
                        <li><Link to="/projects/tehreem/training/summary" className={`${(segment3 == 'training') ? 'active' : ''}`}>Training</Link></li>
                        <li><Link to="/projects/tehreem/profile/summary" className={`${(segment3 == 'profile') ? 'active' : ''}`}>Profile</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default TehreemSubmenu;
