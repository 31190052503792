import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import DeleteValue from './DeleteValue'

const DeleteProjectList = ({list}) => {
    const [dataList, setDataList] = useState([])    
     useEffect(() => {
       setDataList(list)
    }, [list]); 

    return (
        <>
         <div className="dk-toolsValuesPanel dk-toolsReorderPanel">
                {dataList.length > 0 && dataList.map((item,index) =>
                 <DeleteValue index={index+1} id={item.id} dataValue ={item.name} />
                )}              
            </div>
        </>
    )
}

export default DeleteProjectList