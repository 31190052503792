import React from 'react'
import './myacc-kpi.scss';

const MyaccKpi = () => {
    return (
        <>
            <div className='dkg-admin-kpiMain'></div>
        </>
    )
}

export default MyaccKpi