import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export default function CustomizedAccordions(props) {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>
            <div className='dkg-screen-faq-accodionCon'>
                <Accordion className='dkg-screen-faq-accodion' square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary className='dkg-screen-faq-accodion-suMM' expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>{props.title1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {props.summary1}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='dkg-screen-faq-accodion' square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary className='dkg-screen-faq-accodion-suMM' expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>{props.title2}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {props.summary2}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className='dkg-screen-faq-accodion' square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary className='dkg-screen-faq-accodion-suMM' expandIcon={<ExpandMoreIcon />} aria-controls="panel3d-content" id="panel3d-header">
                        <Typography>{props.title2}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {props.summary3}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
}