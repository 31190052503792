import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import './rightmodalfilter.scss';
import { Modal, Nav, Tab } from 'react-bootstrap';
import { MdInsertDriveFile } from "react-icons/md";
import { FaCheck, FaSpinner , FaReplyAll } from "react-icons/fa";

const RightModalFilter = () => {

//take pull
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
    }
    return (
        <React.Fragment>
            <div className='dkg-cand-logs-filtercon'>
                <div className="dkg-candlogs-filter-icon" onClick={handleShow}>
                    <i className="fa fa-filter"></i>
                </div>
                <button class="dk-refreral-filter-btn" onClick={handleShow}>FILTERS</button>
            </div>
            <div className="dk-jobsMainModalCon">
                <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName="dk-pipenwqrightFliterModal"
                >
                    <Modal.Header closeButton className="dkmb-Fliterheader">
                        <Modal.Title className="dkmb-FliterTitle">
                          JOBSEEKER A/C LOGS FILTER
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="dkmb-Fliterbody dkg-pipelinesRightFilterBody-13">
                        <Tab.Container id="dk-jobsRightFilterModal" defaultActiveKey="tabYear">
                            <div className="row ml-0 mr-0">
                                <div className="col-md-4 pl-0 pr-0">
                                    <Nav variant="pills" className="flex-column dk-leftSideMenu dkg-right-sideFilter-13">
                                        {/* <Nav.Item>
                                            <Nav.Link eventKey="name-of-candidate">
                                                <i className="fa fa-user mr-2" aria-hidden="true"></i>Candidate
                                            </Nav.Link>
                                        </Nav.Item> */}
                                        <Nav.Item>
                                            <Nav.Link eventKey="tabYear">
                                              <i className="fa fa-calendar mr-2" aria-hidden="true"></i>Year
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tabMonth">
                                            <i className="fa fa-calendar mr-2" aria-hidden="true"></i>Month
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tabCountry">
                                                <i className="fa fa-map-marker mr-2" aria-hidden="true"></i>Country
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tabCity">
                                                <i className="fa fa-map-marker mr-2" aria-hidden="true"></i>City
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                <div className="col-md-8 pl-0 pr-0">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="tabYear">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-flagFilter">
                                                    <ul>
                                                        <li key={`yearFilter `} data-type="year" data-value="">
                                                            {
                                                                <Link to="#" data-type="year" data-value="">
                                                                        2024
                                                                    <span className="recuriterNo text-orange" data-type="year" data-value="">
                                                                        5
                                                                    </span>
                                                                </Link>
                                                            }
                                                        </li>
                                                      
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tabMonth">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-flagFilter">
                                                    <ul>
                                                        <li key={`monthFilter `} data-type="month" data-value="">
                                                            {
                                                                <Link to="#" data-type="month" data-value="">
                                                                        SEP
                                                                    <span className="recuriterNo text-orange" data-type="month" data-value="">
                                                                        5
                                                                    </span>
                                                                </Link>
                                                            }
                                                        </li>
                                                      
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tabCountry">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-flagFilter">
                                                    <ul>
                                                        <li key={`countryFilter `} data-type="country" data-value="">
                                                            {
                                                                <Link to="#" data-type="country" data-value="">
                                                                        Ireland
                                                                    <span className="recuriterNo text-orange" data-type="country" data-value="">
                                                                        5
                                                                    </span>
                                                                </Link>
                                                            }
                                                        </li>
                                                      
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tabCity">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-flagFilter">
                                                    <ul>
                                                        <li key={`cityFilter `} data-type="city" data-value="">
                                                            {
                                                                <Link to="#" data-type="city" data-value="">
                                                                        Dublin
                                                                    <span className="recuriterNo text-orange" data-type="city" data-value="">
                                                                        5
                                                                    </span>
                                                                </Link>
                                                            }
                                                        </li>
                                                      
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    )
}
export default RightModalFilter;