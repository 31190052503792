import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";

const HrLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/dkglobaladmin/admintraining/joboffer/Overview" className={`${pathname.match('/dkglobaladmin/admintraining/joboffer/Overview') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/onboarding/about-dkg') ? '' : ''}`}>Sample 1</Link></li>

                    <li><Link to="#" className={`${pathname.match('/dkglobaladmin/admintraining/onboarding/dkg-team') ? '' : ''}`}>Sample 2</Link></li>
                </Route>
            </ul>
        </React.Fragment>
    )
}

export default HrLeftPanel;

