import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/dkglobaladmin/TrainingMenu';

const AdminTrainingLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header mainheaderLink="" moduleLink="/dkglobaladmin/dashboard/module" linkcolor="#3c4c62" mainheaderTitle="TRAINING" textcolor="#B55355" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default AdminTrainingLayout;
