import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from '../../../../ui/submitButton'
import { Modal, Dropdown } from 'react-bootstrap'
import SelectDropsDown from "../../../../pages/sendouts/utils/searchDropdown";
import { showSuccess, showError, clearMessage } from "../../../../../slice/utils/message/messageSlice";
import { addValue } from "../../../../../slice/costBenifits/advBudgets";

const BudgetsAdd = ({ year }) => {
    const dispatch = useDispatch();
    const { isInsert } = useSelector(state => state.budgetsdata);

    const [month, setMonth] = useState('Select')
    const { cvSourceList } = useSelector(state => state.assignKpiReport)
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [cvSource, setCvSource] = useState('')
    const [serviceNotes, setServiceNotes] = useState('')
    const [budget, setBudget] = useState('')
    const [newApps, setNewApps] = useState('')
    const [sendouts, setSendouts] = useState('')
    const [spent, setSents] = useState('')
    const [remaining, setRemaining] = useState('')

    const [category, setCategory] = useState('')
    const [type, setType] = useState('Fixed')

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };

    const updateProfile = async (e) => {
        const key = e.target.dataset.key;
        const value = e.target.dataset.value;
        if (key === 'cvSource') {
            setCvSource(value)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (!year) {
                dispatch(showError({ msg: 'All fileds required' }))
            } else {
                setLoading(true);
                try {                 
                   // dispatch(addValue({ year, month, cost: type, resource: cvSource, serviceNotes: serviceNotes, budget, spent: spent, remaining: remaining }))
                    dispatch(addValue({ year, month, cost: type, resource: cvSource, serviceNotes: serviceNotes }))
                } catch (err) {
                    console.log(err)
                }
            }
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }

    useEffect(() => {
        if (isInsert) {
            setLoading(false)
            setShow(false)
            dispatch(showSuccess({ msg: 'Record added successfully' }))
            setType("Fixed")
            setCvSource("")
            setServiceNotes("")
            setBudget("")
            setNewApps("")
            setSendouts("")
            setSents("")
            setRemaining("")
            setMonth("Select")
        }

    }, [isInsert])


    return (
        <>
            {/* <Link to="#" ><i className="fa fa-plus" aria-hidden="true"></i></Link> */}
            <button className="dk-budgetfilter" onClick={handleShow}><i className="fas fa-plus"></i></button>

            <Modal show={show} onHide={handleClose} className="dkg-addnew-paymenttask-modal-21">
                <Modal.Header closeButton className="dkg-addnew-payment-modalheader-21">
                    <Modal.Title>Create RESOURCE BUDGET</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-addnew-payment-modalbody-21">
                    <div className='row'>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Month</label>
                                <div className='dkg-payment-task-drop12'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" className="form-control">
                                            {month}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu >
                                           <Dropdown.Item className="form-control" style={{ backgroundColor: "#884c5e", color: "#fff" }} onClick={() => setMonth("January")} >January</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#26596d", color: "#fff" }} onClick={() => setMonth("February")} >February</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#5a4c72", color: "#fff" }} onClick={() => setMonth("March")} >March</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#3c4c62", color: "#fff" }} onClick={() => setMonth("April")} >April</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#5c737d", color: "#fff" }} onClick={() => setMonth("May")} >May</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#4c5f59", color: "#fff" }} onClick={() => setMonth("June")} >June</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#01796f", color: "#fff" }} onClick={() => setMonth("July")} >July</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#144773", color: "#fff" }} onClick={() => setMonth("August")} >August</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#396a95", color: "#fff" }} onClick={() => setMonth("September")} >September</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#535255", color: "#fff" }} onClick={() => setMonth("October")} >October</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#b55355", color: "#fff" }} onClick={() => setMonth("November")} >November</Dropdown.Item>
                                            <Dropdown.Item className="form-control" style={{ backgroundColor: "#3c97b6", color: "#fff" }} onClick={() => setMonth("December")} >December</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Cost</label>
                                <div className='dkg-payment-task-drop12'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" className="form-control" style={{ backgroundColor: type === 'Fixed' ? "#31a886" : "#dc3545", color: "#fff" }}>
                                            {type}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu >
                                            <Dropdown.Item href="#" onClick={() => setType('Fixed')} style={{ backgroundColor: "#31a886", color: "#fff" }}>Fixed</Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={() => setType('Variable')} style={{ backgroundColor: "#dc3545", color: "#fff" }}>Variable</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Name of Resource</label>
                                <div className='dkg-payment-task-drop12'>
                                    <div className='dkg-payment-task-drop12'>
                                        <SelectDropsDown txt={cvSource} list={cvSourceList} updateProfile={updateProfile} column="cvSource" className={(category == '' ? "errorBorder" : null)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-12'>
                            <div className='form-group'>
                                <label className=''>Services Notes</label>
                                <input type="text" className="form-control" value={serviceNotes} onChange={(e) => setServiceNotes(e.target.value)} />

                            </div>
                        </div>
                    </div>
                    {/* <div className='col-md-12 col-12 pl-0 pr-0'>
                        <div className='form-group'>
                            <label className=''>Budget</label>
                            <input type="text" className="form-control" value={budget} onChange={(e) => setBudget(e.target.value)} />
                        </div>
                    </div>

                    <div className='col-md-12 col-12 pl-0 pr-0'>
                        <div className='form-group'>
                            <label className=''>Spent</label>
                            <input type="text" className="form-control" value={spent} onChange={(e) => setSents(e.target.value)} />

                        </div>
                    </div>
                    <div className='col-md-12 col-12 pl-0 pr-0'>
                        <div className='form-group'>
                            <label className=''>Remaining</label>
                            <input type="text" className="form-control" value={remaining} onChange={(e) => setRemaining(e.target.value)} />

                        </div>
                    </div> */}
                    <div className='col-md-12'>
                        <div className='dkg-create-payment-taskbtncon'>
                            <SubmitButton txt="CREATE" loading={loading} success={success} onClick={handleSubmit} className="dkg-create-payment-taskbtn" />
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default BudgetsAdd