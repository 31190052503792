import React from 'react';
import { Link } from "react-router-dom";

const FilipProjectLiveLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="dk-moduleTraining-leftPanel">
                <ul>
                    <li><Link to="/projects/filip/live/summary" className={`${pathname.match('/projects/filip/live/summary') ? 'active' : ''}`}>Summary</Link></li>
                    <li><Link to="/projects/filip/live/project-1" className={`${pathname.match('/projects/filip/live/project-1') ? 'active' : ''}`}>Wipro</Link></li>
                    <li><Link to="/projects/filip/live/project-2" className={`${pathname.match('/projects/filip/live/project-2') ? 'active' : ''}`}>Tech Mahindra</Link></li>
                </ul>
            </div>
        </>
    )
}

export default FilipProjectLiveLeftPanel;