import React from 'react';
import './clientsdashboard.scss';

const ClientsDashboard = () => {
    return (
        <>
            <div className="dk-adminClientsDashboard-main">
                <h2>Clients Dashboard</h2>
            </div>
        </>
    )
}

export default ClientsDashboard;
