import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import Tele from "../../../../../assets/images/teleperformance.png";
import Facebook from "../../../../../assets/images/facebook.png";
import LinkedIn from "../../../../../assets/images/linkedin.png";
import Instagram from "../../../../../assets/images/instagram.png";
import Tiktok from "../../../../../assets/images/tiktok.png";
import Twitter from "../../../../../assets/images/twitter.png";
import Youtube from "../../../../../assets/images/youtube.png";
import Web from "../../../../../assets/images/web.png";
import { updateValue } from "../../../../../slice/companies/clients/clientSlice";

const Social = ({ id }) => {
    const dispatch = useDispatch();
    const { companyDetails } = useSelector(state => state.companies)

    const updateMyData = (e) => {
        dispatch(updateValue({ id: id, key: e.target.dataset.columnid, value: e.target.value }));
    };

    return (
        <React.Fragment>
            <div className="row pr-3 mt-2">
                <div className="col-md-8">
                    <div className="dkcli-socialMCon">
                        <div className="row pr-3 pl-3">
                            <div className="form-group dk-CliDetailSocailView d-flex w-100">
                                <span className="dk-cliSocailMAddonIcon">
                                    <img src={Facebook} alt='Socail' className='dkg-social-img' />
                                </span>
                                <input type="text" onBlur={updateMyData} data-columnid="facebookUrl" className="form-control" defaultValue={companyDetails ? companyDetails.facebookUrl : ''} />
                                <span className="dk-cliSocailMAddonBtn">
                                    <button>Open</button>
                                </span>
                            </div>
                            <div className="form-group dk-CliDetailSocailView d-flex w-100">
                                <span className="dk-cliSocailMAddonIcon">
                                    <img src={LinkedIn} alt='Socail' className='dkg-social-img' />
                                </span>
                                <input type="text" onBlur={updateMyData} data-columnid="linkdinUrl" className="form-control" defaultValue={companyDetails ? companyDetails.linkdinUrl : ''} />
                                <span className="dk-cliSocailMAddonBtn">
                                    <button>Open</button>
                                </span>
                            </div>
                            <div className="form-group dk-CliDetailSocailView d-flex w-100">
                                <span className="dk-cliSocailMAddonIcon">
                                    <img src={Instagram} alt='Socail' className='dkg-social-img' />
                                </span>
                                <input type="text" onBlur={updateMyData} data-columnid="instagramUrl" className="form-control" defaultValue={companyDetails ? companyDetails.instagramUrl : ''} />
                                <span className="dk-cliSocailMAddonBtn">
                                    <button>Open</button>
                                </span>
                            </div>
                            <div className="form-group dk-CliDetailSocailView d-flex w-100">
                                <span className="dk-cliSocailMAddonIcon">
                                    <img src={Tiktok} alt='Socail' className='dkg-social-img' />
                                </span>
                                <input type="text" onBlur={updateMyData} data-columnid="instagramUrl" className="form-control" defaultValue={companyDetails ? companyDetails.instagramUrl : ''} />
                                <span className="dk-cliSocailMAddonBtn">
                                    <button>Open</button>
                                </span>
                            </div>
                            <div className="form-group dk-CliDetailSocailView d-flex w-100">
                                <span className="dk-cliSocailMAddonIcon">
                                    <img src={Twitter} alt='Socail' className='dkg-social-img' />
                                </span>
                                <input type="text" onBlur={updateMyData} data-columnid="instagramUrl" className="form-control" defaultValue={companyDetails ? companyDetails.instagramUrl : ''} />
                                <span className="dk-cliSocailMAddonBtn">
                                    <button>Open</button>
                                </span>
                            </div>
                            <div className="form-group dk-CliDetailSocailView d-flex w-100">
                                <span className="dk-cliSocailMAddonIcon">
                                    <img src={Youtube} alt='Socail' className='dkg-social-img' />
                                </span>
                                <input type="text" onBlur={updateMyData} data-columnid="instagramUrl" className="form-control" defaultValue={companyDetails ? companyDetails.instagramUrl : ''} />
                                <span className="dk-cliSocailMAddonBtn">
                                    <button>Open</button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 pl-3">
                    <div className="dk-socailMrightCon mb-4">
                        <div className="dk-socailHeader">
                            <h2>Company Addresses</h2>
                            <Link id="" to="#" className=" dk-topTbleIcon">
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </Link>
                        </div>
                        <div className="dk-socailConten">
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Social;
