import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import './trainingmodule.scss';

const RecruitmentTraining = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-recruitmentTraining-nav">
                <Route>
                    <ul>
                        <li><Link to="/training/recruitmentmodule/recruitment-manager/dashboard" className={`${(segment3 == 'recruitment-manager') ? 'active' : ''}`}>Dashboard</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'ads-post-admin') ? '' : ''}`}>Sample 1</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'ads-post-admin') ? '' : ''}`}>Sample 2</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'ads-post-admin') ? '' : ''}`}>Sample 3</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'ads-post-admin') ? '' : ''}`}>Sample 4</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'ads-post-admin') ? '' : ''}`}>Sample 5</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'ads-post-admin') ? '' : ''}`}>Sample 6</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'ads-post-admin') ? '' : ''}`}>Sample 7</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'ads-post-admin') ? '' : ''}`}>Sample 8</Link></li>
                        <li><Link to="#" className={`${(segment3 == 'ads-post-admin') ? '' : ''}`}>Sample 9</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default RecruitmentTraining;