import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";

export const Tags = ({ filterTags, length, removeTag }) => {
    const [list, setList] = useState([])
    useEffect(() => {
        setList(filterTags)
    }, [length])
    return (
        <React.Fragment>
            {
                list.length > 0 && list.map((item, index) =>
                    <div className="dk-filterTagsCon mr-1 d-none">
                        <Link className="searchTagStatus" to="#">{item.value}
                            <span data-type={item.type} onClick={removeTag}><i className="fa fa-times" aria-hidden="true" data-type={item.type} onClick={removeTag}></i></span>
                        </Link>
                    </div>
                )
            }
        </React.Fragment>
    )
}