import React, { useState } from "react";
import DetailLeftPanel from "./DetailLeftPanel";
import MoreDetailModal from "./MoreDetailModal";
import HeadingTitle from "./HeadingTitle";
import "../trainingdetail.scss";

const TrainingOnboarding = () => {
  const [isShowMore, setIsShowMore] = useState(false);
  // const showmoreDetails = () => {
  //     console.log("Show")
  //     setIsShowMore(true);
  // }
  const hidemoreDetails = () => {
    setIsShowMore(false);
  };

  return (
    <React.Fragment>
      {isShowMore ? <MoreDetailModal handleClose={hidemoreDetails} /> : null}

      <HeadingTitle />

      <div className="dk-trainingDetailMain">
        <div className="dk-detailsMain">
          <div className="d-md-flex">
            <div className="dk-detailsLeftPanel">
              <DetailLeftPanel />
            </div>
            <div className="dk-detailRightPanel">
              <div className="dk-detailTabsMain">
                <h3 className="title">Welcome</h3>
                <div className="dk-trainingInformation-cols">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Aliquid similique dolore laboriosam reprehenderit sapiente
                    at iusto? Soluta tempora eveniet suscipit quae aliquid
                    provident rem exercitationem ipsa eos aspernatur?
                    Necessitatibus, optio?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TrainingOnboarding;
