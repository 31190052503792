import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import ActivityModal from './ActivityModal';
import CheckInModal from './CheckInModal';
import LunchModal from './LunchModal';
import CheckOutModal from './CheckOutModal';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaTimesCircle } from "react-icons/fa";
import LunchTimeInterval from './LunchTime'
//import AddNewActivityModal from './AddNewActivityModal';
//import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import './dailytracking.scss';
//import Deeapk from '../../../../assets/images/deepak.png';
import { MdFastfood } from "react-icons/md";
import { FaSignOutAlt, FaCalculator, FaCalendarTimes } from "react-icons/fa";
import DatePicker from './datepicker/DatePicker';
import { getdata, updatekey, clearState, filterBydate, leaveAllDay } from '../../../../slice/attendance/attendanceSlice'
// import Lunchwhite from '../../../../assets/images/lunch-white.png';
import ConvertTime from "./AMPM";
import TotalTime from "./CalculateTime";
import DayOff from "./DayOffTime";
import { showSuccess, showError, clearMessage } from "../../../utils/messages/messageSlice";
import moment from "moment-timezone"

const DailyTracking = () => {

    const dispatch = useDispatch();
    const { usersList } = useSelector(state => state.users)
    const { userInfo } = useSelector(state => state.auth);
    const { attendanceList, isSuccess, msg, isUpdate, isLoading, isError, isInsert } = useSelector(state => state.attendance)


    const removeValueFromIndex = [9, 10, 11, 12];
    const indexSet = new Set(removeValueFromIndex);
    const newList = usersList.filter((value, i) => !indexSet.has(i))

    const [list, setList] = useState([])

    useEffect(() => {
        if (!isLoading && attendanceList && attendanceList.length > 0) {
            setList(attendanceList)
        } else {
            setList([])
        }
    }, [isLoading])

    useEffect(() => {
        const fetchdata = async () => {
            await dispatch(getdata({}))
        }
        fetchdata();
    }, [isInsert, isUpdate])


    const [showModalCheckIn, setCheckInShow] = useState(false);
    const [showModalCheckOut, setCheckOutShow] = useState(false);
    const [showModalLunch, setLunchShow] = useState(false);
    const [showModalActivity, setActivityShow] = useState(false);
    const [showModalAddNewActivity, setAddNewActivityShow] = useState(false);

    // Check iIn Start
    const showCheckInModal = () => {
        setCheckInShow(true)
    }
    const hideCheckInModal = () => {
        setCheckInShow(false)
    }

    // end

    // Lunch Start
    const showLunchModal = () => {
        setLunchShow(true)
    }
    const hideLunchModal = () => {
        setLunchShow(false)
    }

    // end

    // Checkout Modal Start


    const showCheckOutModal = () => {
        setCheckOutShow(true)
    }
    const hideCheckOutModal = () => {
        setCheckOutShow(false)
    }

    // end

    // Add new activity Start
    const showActivityModal = () => {
        setActivityShow(true)
    }
    const hideActivityModal = () => {
        setActivityShow(false)
    }
    // end

    // Add new activity Start
    // const showAddNewActivityModal = () => {
    //     setAddNewActivityShow(true)
    // }
    // const hideAddNewActivityModal = () => {
    //     setAddNewActivityShow(false)
    // }
    // end

    useEffect(() => {
        if (isError) {
            dispatch(showError({ msg: msg }))
            dispatch(clearState())
        }
        if (isUpdate) {
            dispatch(showSuccess({ msg: "Record updated successfully" }))
            dispatch(clearState())
        }
    }, [isError, isUpdate])

    const errorfunction = () => {
        dispatch(showError({ msg: "Modification not allowed for old date" }))
        dispatch(clearState())
    }

    const dayOffChecking = (id) => {
        dispatch(updatekey({ id, key: 'dayOff', value: '' }))
    }
    const clearChecking = (id) => {
        dispatch(updatekey({ id, key: 'checkinTime', value: '' }))
    }
    const clearLunchTime = (id) => {
        dispatch(updatekey({ id, key: 'lunchTimeStart', value: '' }))
    }
    const clearCheckout = (id) => {
        dispatch(updatekey({ id, key: 'checkoutTime', value: '' }))
    }

    const getDayName = (dateStr) => {
        var date = new Date(dateStr);
        return date.toLocaleDateString("en-US", { weekday: 'long' });
    }
    const [todydate, setTodydate] = useState(moment(new Date()).format('DD/MM/YYYY'))
    const [fiterDate, setFilterDate] = useState(moment(new Date()).format('DD/MM/YYYY'))

    const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('DD MMM YYYY'))
    const handleDateChange = (date) => {
        setSelectedDate(moment(date).format('DD MMM YYYY'));
        setFilterDate(moment(date).format('DD/MM/YYYY'))
        dispatch(filterBydate({ checkinDate: moment(date).format('DD/MM/YYYY') }))
    };

    const dayOffFun = () => {
        dispatch(leaveAllDay({ uid: userInfo.id, checkinDate: todydate }))
    }

    return (
        <>
            {showModalCheckIn ?
                <CheckInModal handleClose={hideCheckInModal} /> : null
            }

            {showModalLunch ?
                <LunchModal handleClose={hideLunchModal} id={list.filter(obj => obj.uid === userInfo.id).map(data => data.id)} userid={list.filter(obj => obj.uid === userInfo.id).map(data => data.uid)} todaydate={list.filter(obj => obj.uid === userInfo.id).map(data => data.checkinDate)} /> : null
            }

            {
                showModalActivity ?
                    <ActivityModal handleClose={hideActivityModal} /> : null
            }

            {
                showModalCheckOut ?
                    <CheckOutModal handleClose={hideCheckOutModal} id={list.filter(obj => obj.uid === userInfo.id).map(data => data.id)} userid={list.filter(obj => obj.uid === userInfo.id).map(data => data.uid)} todaydate={list.filter(obj => obj.uid === userInfo.id).map(data => data.checkinDate)} /> : null
            }

            {/* {
                showModalAddNewActivity ?
                    <AddNewActivityModal handleClose={hideAddNewActivityModal} /> : null
            } */}


            <div className="dk-dailyTrackingMain">
                <div className="dk-dailyTracker-head">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="col-md-3 pl-0">
                            <div className="d-flex">
                                {/* <div className="dk-searchBox">
                                </div> */}
                                <div className='dkg-daily-leftFilterCon'>
                                    <div className='dk-jobProfile-Filter mr-2'>
                                        <DatePicker handleDateChange={handleDateChange} selectedDate={selectedDate} />
                                    </div>
                                    <div className='dk-jobProfile-Filter mr-2'>
                                        <Dropdown className='dk-jobProfile-drop'>
                                            <Dropdown.Toggle variant="" className='dk-jobProfile-day-toggle'>
                                                {getDayName(selectedDate)}
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                    </div>
                                </div>
                                {/* <div className="dk-addNew">
                                    <Link to="#" onClick={showAddNewActivityModal}><i className="fas fa-plus"></i></Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dk-dailyTitle">WORK SCHEDULE</div>
                        </div>
                        <div className="col-md-4 pr-0">
                            <div className="d-flex justify-content-end dk-checkInOut-btn">

                                {
                                    todydate == fiterDate ? <>
                                        <Link to="#" onClick={showCheckInModal} className="mr-2"><i className="far fa-edit"></i> Check In</Link>
                                        <Link to="#" onClick={showLunchModal} className="dkg-lunchbg mr-2"><span className='dkg-lunch-icon'><MdFastfood /></span>Lunch</Link>
                                        <Link to="#" onClick={showCheckOutModal} className="checkOut mr-2"><span className='dkg-lunch-icon'><FaSignOutAlt /></span> Check Out</Link>
                                        <Link to="#" onClick={dayOffFun} className="dkg-dayoff-btn"><span className='dkg-lunch-icon'><FaCalendarTimes /></span> Day Off</Link>
                                    </>
                                        :
                                        <>
                                            <Link to="#" onClick={errorfunction} className="mr-2"><i className="far fa-edit"></i> Check In</Link>
                                            <Link to="#" onClick={errorfunction} className="dkg-lunchbg mr-2"><span className='dkg-lunch-icon'><MdFastfood /></span>Lunch</Link>
                                            <Link to="#" onClick={errorfunction} className="checkOut mr-2"><span className='dkg-lunch-icon'><FaSignOutAlt /></span> Check Out</Link>
                                            <Link to="#" onClick={dayOffFun} className="dkg-dayoff-btn"><span className='dkg-lunch-icon'><FaCalendarTimes /></span> Day Off</Link>

                                        </>
                                }



                            </div>
                        </div>
                    </div>
                </div>
                <div className="dk-dailyTrackingTable">
                    <div className="table-responsive">
                        <table className="table table-borderd">
                            <thead>
                                <tr>
                                    <th></th>
                                    {
                                        newList.length > 0 && newList.map((item, index) =>
                                            <th key={index + 1}>
                                                <div className="dk-personImg">
                                                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.profileImage} className="filterImg" alt={item.firstName + " " + item.lastName} title={item.firstName + " " + item.lastName} />
                                                </div>
                                            </th>

                                        )}

                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td>
                                        <span className='dkg-checkicon-Con'><i className="far fa-edit"></i></span>
                                        Check In
                                    </td>
                                    {
                                        newList.length > 0 && newList.map((item, index) =>

                                            <ConvertTime currentdate={fiterDate} timeTo12hour={list.filter(obj => obj.uid === item.id).map(data => data.checkinTime)} userid={item.id} deleteFunction={clearChecking} id={list.filter(obj => obj.uid === item.id).map(data => data.id)} dayoff={list.filter(obj => obj.uid === item.id).map(data => data.dayOff)} dayOffChecking={dayOffChecking} />

                                        )}

                                </tr>

                                <tr className='dkg-lunch-tbody-tr'>
                                    <td>
                                        <span className='dkg-checkicon-Con'><MdFastfood /></span>
                                        Lunch
                                    </td>
                                    {
                                        newList.length > 0 && newList.map((item, index) =>
                                            <LunchTimeInterval currentdate={fiterDate} lunchStartFrom={list.filter(obj => obj.uid === item.id).map(data => data.lunchTimeStart)} lunchEnd={list.filter(obj => obj.uid === item.id).map(data => data.lunchTimeEnd)} deleteFunction={clearLunchTime} userid={item.id} id={list.filter(obj => obj.uid === item.id).map(data => data.id)} dayoff={list.filter(obj => obj.uid === item.id).map(data => data.dayOff)} dayOffChecking={dayOffChecking} />
                                        )}


                                </tr>
                                <tr>
                                    <td>
                                        <span className='dkg-checkicon-Con'><FaSignOutAlt /></span>
                                        Check Out
                                    </td>
                                    {
                                        newList.length > 0 && newList.map((item, index) =>
                                            <ConvertTime currentdate={fiterDate} timeTo12hour={list.filter(obj => obj.uid === item.id).map(data => data.checkoutTime)} deleteFunction={clearCheckout} userid={item.id} id={list.filter(obj => obj.uid === item.id).map(data => data.id)} dayoff={list.filter(obj => obj.uid === item.id).map(data => data.dayOff)} dayOffChecking={dayOffChecking} />

                                        )}

                                </tr>

                                <tr>
                                    <td>
                                        <span className='dkg-checkicon-Con'><FaCalculator /></span>
                                        Total Hours
                                    </td>
                                    {
                                        newList.length > 0 && newList.map((item, index) =>
                                            <td key={index + 1}><TotalTime todaydate={todydate} checkintime={list.filter(obj => obj.uid === item.id).map(data => data.checkinTime)} checckouttime={list.filter(obj => obj.uid === item.id).map(data => data.checkoutTime)} lunchtime='' /></td>
                                        )}


                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DailyTracking;

