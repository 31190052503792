import React, { useState, useEffect } from "react";
//import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { Tab, Nav } from 'react-bootstrap';
import Step1 from './Step1'
import { getCategories, getSubCategories, getdefaultSubCategories } from '../../../../slice/solutions/solutionSlice'
import { showError } from '../../../utils/messages/messageSlice'
import $ from 'jquery'

const DkgProcess = () => {
    const dispatch = useDispatch();
    const { isSuccess, isLoading, categoryProcessList } = useSelector(state => state.solutions);
    const [data, setData] = useState([]);
    const [catActiveRow, setCatActiveRow] = useState('active');
    const [defaultOverview, setDefaultOverview] = useState(true);
    const [othersTab, setOthersTab] = useState(false);

    useEffect(() => {
        if (!isLoading && categoryProcessList && categoryProcessList.length > 0) {
            setData(categoryProcessList)
        }
    }, [isLoading])

    useEffect(() => {
        async function fetchData() {
            try {
                setOthersTab(false)
                await dispatch(getCategories({}))
                await dispatch(getdefaultSubCategories({ keys: 'Overview' }))
            } catch (err) {
                dispatch(showError({ msg: "Somthings went wrong" }))
            }
        }
        fetchData()
    }, [dispatch])

    const selectCat = async (catid, catName) => {

        if (catName == "Overview") {
            setCatActiveRow('active')
            setDefaultOverview(true)
            setOthersTab(false)
            await dispatch(getSubCategories({ categoryId: catid, keys: 'Overview' }))
        } else {
            setCatActiveRow('')
            setDefaultOverview(false)
            setOthersTab(true)
            $('.tab_1').addClass('activeSubTab')
            $('.tab_2').removeClass('activeSubTab')
            $('.tab_3').removeClass('activeSubTab')
            $('.addnewClass').addClass('activesSubTab')
            await dispatch(getSubCategories({ categoryId: catid, keys: 'details' }))
        }
    }

    return (
        <>
            <div className='dkg-payment-qt-pageCon dkg-comman-training-pageCon dkg-solutions-mainpageCon'>
                {/* <div className="dkg-profies-linksCon">
                    <Link to="#" className="dkg-profile-link active">Recruitment</Link>
                    <Link to="#" className="dkg-profile-link">Accounting</Link>
                    <Link to="#" className="dkg-profile-link">Resource</Link>
                    <Link to="#" className="dkg-profile-link">Clients</Link>
                    <Link to="#" className="dkg-profile-link">Legal</Link>
                    <Link to="#" className="dkg-profile-link">HR</Link>
                    <Link to="#" className="dkg-profile-link">IT</Link>
                    <Link to="#" className="dkg-profile-link ">Management</Link>

                </div> */}
                <Tab.Container defaultActiveKey="question-pt-new-overview">
                    <div className='row m-0 dkg-payment-qt-tab-mainCon'>
                        <div className='col-md-2 pl-0 dkg-payment-qt-tabCon dkg-solutions-side-menuCon'>
                            <Nav variant="pills" className="flex-column dkg-payment-qt-tab-navpills dkg-solutions-navpills">
                                {
                                    data.map((item, i) =>

                                        i === 0 ?
                                            <>
                                                <Nav.Item className='dkg-payment-qt-tab-nav-item dkg-solutions-navitem' key={i} onClick={() => selectCat(item.id, item.categoryName)} >
                                                    <Nav.Link eventKey={item.categoryName + i} className={`dkg-payment-qt-tab-navlink addCattClass ` + catActiveRow}>{item.categoryName}</Nav.Link>
                                                </Nav.Item>
                                            </>
                                            :
                                            <>
                                                <Nav.Item className='dkg-payment-qt-tab-nav-item dkg-solutions-navitem' key={i} onClick={() => selectCat(item.id, item.categoryName)} >
                                                    <Nav.Link eventKey={item.categoryName + i} className='dkg-payment-qt-tab-navlink'>{item.categoryName}</Nav.Link>
                                                </Nav.Item>
                                            </>

                                    )
                                }
                            </Nav>
                        </div>
                        {
                            defaultOverview === true ? <>
                                <div className='col-md-10 dkg-payment-rt-tabCon-10 dkg-solutions-right-sideCon'>
                                    <Tab.Content className='dkg-payment-qt-tabcontent'>
                                        {
                                            data.map((item, i) =>
                                                item.categoryName === "Overview" ?
                                                    <Tab.Pane eventKey={item.categoryName + i} className={`dkg-payment-qt-tabpane active show`}>
                                                        <Step1 pagetitle={item.categoryName} catIdds={item.id} />
                                                    </Tab.Pane>
                                                    :
                                                    null
                                            )
                                        }

                                    </Tab.Content>
                                </div>
                            </>
                                :
                                null
                        }

                        {
                            othersTab === true ? <>
                                <div className='col-md-10 dkg-payment-rt-tabCon-10 dkg-solutions-right-sideCon'>
                                    <Tab.Content className='dkg-payment-qt-tabcontent'>
                                        {
                                            data.map((item, i) =>
                                                <Tab.Pane eventKey={item.categoryName + i} className={`dkg-payment-qt-tabpane`}>
                                                    <Step1 pagetitle={item.categoryName} catIdds={item.id} />
                                                </Tab.Pane>
                                            )
                                        }

                                    </Tab.Content>
                                </div>
                            </>
                                : null
                        }

                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default DkgProcess;
