import React from 'react';
import { Link, Route } from "react-router-dom";
import TItleIcon from "../../../../assets/images/company.png";
// import '../salaries/clieninfosalary.scss'

const index = () => {
    let pathname = window.location.pathname;

    return (
        <React.Fragment>
            <div className="container-fluid dk-clientinfo-salaryMain">
                <h3 className="dk-detailTitle">
                    <img src={TItleIcon} className="img-fluid" alt="" />Profiling
                </h3>
            </div>
            <div className="dk-clientinfo-profileMain">
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailsLeftPanel">
                            <ul>
                                <Route>
                                    <li><Link to="#/recruitment/clientsinfo/salaries" className={`${pathname.match('/tp-greece') ? 'active profile-greybg' : 'profile-greybg'}`}>Relocation 1</Link></li>
                                    <li><Link to="#/recruitment/clientsinfo/salaries/salaries-2">Relocation 2</Link></li>
                                    <li><Link to="#/recruitment/clientsinfo/salaries/salaries-2">Relocation 3</Link></li>
                                    <li><Link to="#/recruitment/clientsinfo/salaries/salaries-2">Relocation 4</Link></li>
                                    <li><Link to="#/recruitment/clientsinfo/salaries/salaries-2">Relocation 5</Link></li>
                                    <li><Link to="#/recruitment/clientsinfo/salaries/salaries-2">Relocation 6</Link></li>
                                    <li><Link to="#/recruitment/clientsinfo/salaries/salaries-2">Relocation 7</Link></li>
                                </Route>
                            </ul>
                        </div>
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                <div className="dk-trainingInformation-cols">
                                    <div className="dk-payDetailRightPanel">
                                        <div className='dk-ci-salary-tableHeaderCon'>
                                            <div className='dk-ci-salary-tableHeader-left'>
                                                <div className="dk-payTableSearch">
                                                    <input type="text" placeholder="Search" className="form-control" name="" id="" />
                                                </div>
                                                <Link to="#" className='ml-2 dkg-src-rightFliter'>
                                                    <i className='fa fa-search'></i>
                                                </Link>
                                            </div>
                                            <div className='dk-ci-salary-tableHeader-center'>
                                                <div className='dk-ci-salary-profiling-title'>
                                                    <h3 className='profile-title'>TP Greece</h3>
                                                </div>
                                            </div>
                                            <div className='dk-ci-salary-tableHeader-right'>
                                                {/* <ReorderModal /> */}
                                                <Link to="#" className='mr-2'>
                                                    <i className='fa fa-arrows-alt'></i>
                                                </Link>
                                                <Link to="#" >
                                                    <i className='fa fa-plus'></i>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="dk-clientinfo-salariesTable table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Sn.</th>
                                                        <th>Project</th>
                                                        <th>Role Type</th>
                                                        <th>Language</th>
                                                        <th>Gross salary</th>
                                                        <th>Tax %</th>
                                                        <th>Net Salary</th>
                                                        <th>Ext Salary</th>
                                                        <th>Per Bonus</th>
                                                        <th>Total Net</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>DKG CRM</td>
                                                        <td>Recruitment</td>
                                                        <td>English</td>
                                                        <td>24000</td>
                                                        <td>2%</td>
                                                        <td>22000</td>
                                                        <td>12000</td>
                                                        <td>1500</td>
                                                        <td>38000</td>
                                                        <td>
                                                            <div className="dk-payAction">
                                                                <Link to="#" className="dk-edit"><i className="far fa-edit"></i></Link>
                                                                <Link to="#" className="dk-delete"><i className="far fa-trash-alt"></i></Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>DKG CRM</td>
                                                        <td>Recruitment</td>
                                                        <td>English</td>
                                                        <td>24000</td>
                                                        <td>2%</td>
                                                        <td>22000</td>
                                                        <td>12000</td>
                                                        <td>1500</td>
                                                        <td>38000</td>
                                                        <td>
                                                            <div className="dk-payAction">
                                                                <Link to="#" className="dk-edit"><i className="far fa-edit"></i></Link>
                                                                <Link to="#" className="dk-delete"><i className="far fa-trash-alt"></i></Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>DKG CRM</td>
                                                        <td>Recruitment</td>
                                                        <td>English</td>
                                                        <td>24000</td>
                                                        <td>2%</td>
                                                        <td>22000</td>
                                                        <td>12000</td>
                                                        <td>1500</td>
                                                        <td>38000</td>
                                                        <td>
                                                            <div className="dk-payAction">
                                                                <Link to="#" className="dk-edit"><i className="far fa-edit"></i></Link>
                                                                <Link to="#" className="dk-delete"><i className="far fa-trash-alt"></i></Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>DKG CRM</td>
                                                        <td>Recruitment</td>
                                                        <td>English</td>
                                                        <td>24000</td>
                                                        <td>2%</td>
                                                        <td>22000</td>
                                                        <td>12000</td>
                                                        <td>1500</td>
                                                        <td>38000</td>
                                                        <td>
                                                            <div className="dk-payAction">
                                                                <Link to="#" className="dk-edit"><i className="far fa-edit"></i></Link>
                                                                <Link to="#" className="dk-delete"><i className="far fa-trash-alt"></i></Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>DKG CRM</td>
                                                        <td>Recruitment</td>
                                                        <td>English</td>
                                                        <td>24000</td>
                                                        <td>2%</td>
                                                        <td>22000</td>
                                                        <td>12000</td>
                                                        <td>1500</td>
                                                        <td>38000</td>
                                                        <td>
                                                            <div className="dk-payAction">
                                                                <Link to="#" className="dk-edit"><i className="far fa-edit"></i></Link>
                                                                <Link to="#" className="dk-delete"><i className="far fa-trash-alt"></i></Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>DKG CRM</td>
                                                        <td>Recruitment</td>
                                                        <td>English</td>
                                                        <td>24000</td>
                                                        <td>2%</td>
                                                        <td>22000</td>
                                                        <td>12000</td>
                                                        <td>1500</td>
                                                        <td>38000</td>
                                                        <td>
                                                            <div className="dk-payAction">
                                                                <Link to="#" className="dk-edit"><i className="far fa-edit"></i></Link>
                                                                <Link to="#" className="dk-delete"><i className="far fa-trash-alt"></i></Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default index;

