import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const templeteList = createAsyncThunk(
    'sms/templete_list',
    async ({ companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/sms/template/' + companyId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const templeteAdd = createAsyncThunk(
    'sms/templete_add',
    async ({ userId, name, body, companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/sms/template',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify({
                        userId,
                        name,
                        body,
                        companyId
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const templeteDelete = createAsyncThunk(
    'sms/templete_delete',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch('/api/sms/template/',
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
);

export const fethTemplateById = createAsyncThunk(
    'sms/template_details',
    async ({ templateId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/sms/template/details/' + templateId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)
export const updateTemplate = createAsyncThunk(
    'sms/update',
    async ({ name, body, userId, templateId, companyId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkmasterBoxClient"));
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/' + process.env.REACT_APP_API_VERSION + '/sms/template/' + templateId,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                        'platform': 'web',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify({
                        name, body, userId, companyId
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 201) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

const templateSlice = createSlice({
    name: 'sms_template',
    initialState: {
        templates: [],
        isLoading: false,
        isInsert: false,
        isError: false,
        isUpdate: false,
        isDelete: false,
        isSuccess: false,
        errorMessage: '',
        templateDetails: {},
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
            state.isSuccess = false;
            state.errorMessage = '';
            state.templateDetails = {};
            return state;
        }
    },
    extraReducers: {
        [templeteList.pending]: (state) => {
            state.isLoading = true;
        },
        [templeteList.fulfilled]: (state, { payload }) => {
            state.templates = payload.templates;
            state.isLoading = false;
        },
        [templeteList.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
            state.isLoading = false;
        },
        [templeteAdd.pending]: (state) => {
        },
        [templeteAdd.fulfilled]: (state, { payload }) => {
            state.templates.unshift(payload.template);
            state.isInsert = true;
        },
        [templeteAdd.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [templeteDelete.pending]: (state) => {
        },
        [templeteDelete.fulfilled]: (state, action) => {
            state.templates = state.templates.filter((item) => item._id !== action.meta.arg.id);
            state.isDelete = true;
        },
        [templeteDelete.rejected]: (state, { payload }) => {
            state.isError = payload.message.msgError;
            state.errorMessage = payload.message.msgBody;
        },
        [fethTemplateById.pending]: (state) => {
        },
        [fethTemplateById.fulfilled]: (state, { payload }) => {
            state.templateDetails = payload.template;
        },
        [fethTemplateById.rejected]: (state, { payload }) => {
            state.isError = true;
            state.errorMessage = payload.message.msgBody;
        },
        [updateTemplate.pending]: (state) => {
            //state.isLoading = true;
        },
        [updateTemplate.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = true;
            let mainArray = state.templates;
            let finalResult = mainArray.map((r) => {
                if (r._id === payload.template._id) {
                    return (payload.template)
                } else {
                    return r
                }
            })
            state.templates = finalResult
            state.msg = payload.message.msgBody
        },
        [updateTemplate.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isUpdate = false;
            state.isError = true;
            state.msg = payload.msgBody
        },
    }
});

export const { clearState } = templateSlice.actions;
export default templateSlice.reducer;