import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData, PostData } from "../../../services";

export const workpermitCounts = createAsyncThunk(
  "jobs/summary/workpermitCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/workpermit", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const statusNumbersCounts = createAsyncThunk(
  "jobs/summary/statusCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/status", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const localityNumbersCounts = createAsyncThunk(
  "jobs/summary/localityCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/locality", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const rmNumbersCounts = createAsyncThunk(
  "jobs/summary/rmCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/rm", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const rc3NumbersCounts = createAsyncThunk(
  "jobs/summary/rc3NCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/rc3", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const raNumbersCounts = createAsyncThunk(
  "jobs/summary/raCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/ra", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const rcNumbersCounts = createAsyncThunk(
  "jobs/summary/rcCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/rc", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const amNumbersCounts = createAsyncThunk(
  "jobs/summary/amCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/am", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const categoryNumbersCounts = createAsyncThunk(
  "jobs/summary/categoryCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/category", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const companyNumbersCounts = createAsyncThunk(
  "jobs/summary/companyCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/company", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const locationNumbersCounts = createAsyncThunk(
  "jobs/summary/locationCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/location", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const languageNumbersCounts = createAsyncThunk(
  "jobs/summary/languageCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/language", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const projectNumbersCounts = createAsyncThunk(
  "jobs/summary/projectCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/project", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const roleNumbersCounts = createAsyncThunk(
  "jobs/summary/roleCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/role", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const totalJobsNumbersCounts = createAsyncThunk(
  "jobs/summary/totaljobsCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/totaljobs", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const jobProfileNumbersCounts = createAsyncThunk(
  "jobs/summary/jobProfileCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/job-profile", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const countryNumbersCounts = createAsyncThunk(
  "jobs/summary/jobCountryCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/country", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatedNumbersCounts = createAsyncThunk(
  "jobs/summary/updatedCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/updated", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const titleNumbersCounts = createAsyncThunk(
  "jobs/summary/titleCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/title", { filter });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const candidateNumbersCounts = createAsyncThunk(
  "jobs/summary/candidateCount",
  async ({ filter }, thunkAPI) => {
    const res = await PostData(true, "/jobs/summary/candidate-count", {
      filter,
    });
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

const JobsSummarySlice = createSlice({
  name: "JobsSummary",
  initialState: {
    statusCount: [],
    workpermitListCounts: [],
    candidateCount: [],
    localityCount: [],
    rmCount: [],
    raCount: [],
    rc3Count: [],
    rcCount: [],
    amCount: [],
    categoryCount: [],
    companyCount: [],
    locationCount: [],
    languageCount: [],
    projectCount: [],
    roleCount: [],
    totaljobsCount: [0],
    jobProfileCount: [],
    jobCountryCount: [],
    updatedCount: [],
    titleCount: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(candidateNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(candidateNumbersCounts.fulfilled, (state, { payload }) => {
      state.candidateCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(candidateNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(workpermitCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(workpermitCounts.fulfilled, (state, { payload }) => {
      state.workpermitListCounts = payload.result;
      state.isLoading = false;
    });
    builder.addCase(workpermitCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(statusNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(statusNumbersCounts.fulfilled, (state, { payload }) => {
      state.statusCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(statusNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(localityNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(localityNumbersCounts.fulfilled, (state, { payload }) => {
      state.localityCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(localityNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(rmNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(rmNumbersCounts.fulfilled, (state, { payload }) => {
      state.rmCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(rmNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(rc3NumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(rc3NumbersCounts.fulfilled, (state, { payload }) => {
      state.rc3Count = payload.result;
      state.isLoading = false;
    });
    builder.addCase(rc3NumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });
    builder.addCase(raNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(raNumbersCounts.fulfilled, (state, { payload }) => {
      state.raCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(raNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(rcNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(rcNumbersCounts.fulfilled, (state, { payload }) => {
      state.rcCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(rcNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(amNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(amNumbersCounts.fulfilled, (state, { payload }) => {
      state.amCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(amNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(categoryNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(categoryNumbersCounts.fulfilled, (state, { payload }) => {
      state.categoryCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(categoryNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(companyNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(companyNumbersCounts.fulfilled, (state, { payload }) => {
      state.companyCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(companyNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(locationNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(locationNumbersCounts.fulfilled, (state, { payload }) => {
      state.locationCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(locationNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(languageNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(languageNumbersCounts.fulfilled, (state, { payload }) => {
      state.languageCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(languageNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(projectNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(projectNumbersCounts.fulfilled, (state, { payload }) => {
      state.projectCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(projectNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(roleNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(roleNumbersCounts.fulfilled, (state, { payload }) => {
      state.roleCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(roleNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(totalJobsNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(totalJobsNumbersCounts.fulfilled, (state, { payload }) => {
      state.totaljobsCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(totalJobsNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(jobProfileNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(jobProfileNumbersCounts.fulfilled, (state, { payload }) => {
      state.jobProfileCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(jobProfileNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(countryNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(countryNumbersCounts.fulfilled, (state, { payload }) => {
      state.jobCountryCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(countryNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(updatedNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updatedNumbersCounts.fulfilled, (state, { payload }) => {
      state.updatedCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(updatedNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });

    builder.addCase(titleNumbersCounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(titleNumbersCounts.fulfilled, (state, { payload }) => {
      state.titleCount = payload.result;
      state.isLoading = false;
    });
    builder.addCase(titleNumbersCounts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = "Something went wrong";
    });
  },
});
export const { clearState } = JobsSummarySlice.actions;
export default JobsSummarySlice.reducer;
