import React from "react";
import { Link, Route } from "react-router-dom";
import "../../trainingdetail.scss";

const KnowledgeBaseDetailLeftPanel = () => {
  let pathname = window.location.pathname;
  return (
    <React.Fragment>
      <ul>
        <Route>
          <li>
            <Link
              to="/training/managers/knowledge-base"
              className={`${
                pathname.match("/training/managers/knowledge-base")
                  ? "active"
                  : ""
              }`}
            >
              Overview
            </Link>
          </li>

          <li>
            <Link
              to="/training/managers/search-info"
              className={`${
                pathname.match("/training/managers/search-info") ? "active" : ""
              }`}
            >
              Search Info
            </Link>
          </li>

          <li>
            <Link
              to="/training/managers/tasks-info"
              className={`${
                pathname.match("/training/managers/tasks-info") ? "active" : ""
              }`}
            >
              Tasks Info
            </Link>
          </li>

          <li>
            <Link
              to="/training/managers/tools-info"
              className={`${
                pathname.match("/training/managers/tools-info") ? "active" : ""
              }`}
            >
              Tools Info
            </Link>
          </li>

          <li>
            <Link
              to="/training/managers/candidates"
              className={`${
                pathname.match("/training/managers/candidates") ? "active" : ""
              }`}
            >
              Candidates
            </Link>
          </li>

          <li>
            <Link
              to="/training/managers/clients"
              className={`${
                pathname.match("/training/managers/clients") ? "active" : ""
              }`}
            >
              Clients
            </Link>
          </li>

          <li>
            <Link
              to="/training/managers/jobs"
              className={`${
                pathname.match("/training/managers/jobs") ? "active" : ""
              }`}
            >
              Jobs
            </Link>
          </li>
        </Route>
      </ul>
    </React.Fragment>
  );
};

export default KnowledgeBaseDetailLeftPanel;
