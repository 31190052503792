import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import './businessoverviewdashboard.scss';

const BusinessOverviewDashboard = () => {
    return (
        <>
            <div className="dk-salesbookes-dashMain">
                <div className="dk-kpiCaseHead">
                    <div className="dk-kpiCase-yearsDropdown">
                        <div className="d-flex align-items-center">
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    2022
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>2022</Dropdown.Item>
                                    <Dropdown.Item>2021</Dropdown.Item>
                                    <Dropdown.Item>2020</Dropdown.Item>
                                    <Dropdown.Item>2019</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* <div className="dk-clientsAddMonth">
                                        <button className="addMonthBtn"><i className="fas fa-plus"></i></button>
                                    </div> */}
                        </div>
                    </div>
                    <div className="dk-kpiCase-months">
                        <ul>
                            <li><Link to="#" >Jan</Link></li>
                            <li><Link to="#" >Feb</Link></li>
                            <li><Link to="#" >Mar</Link></li>
                            <li><Link to="#" >Apr</Link></li>
                            <li><Link to="#" >May</Link></li>
                            <li><Link to="#" >Jun</Link></li>
                            <li><Link to="#" >Jul</Link></li>
                            <li><Link to="#" >Aug</Link></li>
                            <li><Link to="#" >Sep</Link></li>
                            <li><Link to="#" >Oct</Link></li>
                            <li><Link to="#" className='active'>Nov</Link></li>
                            <li><Link to="#" >Dec</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="d-flex w-100">
                    <div className="dk-rightBoxes-main">
                        <div className="dk-leftBoxes-panel">
                            <div className="dk-leftBoxes-cols">
                                <div className="title">CLIENTS</div>
                                <div className="dk-textPanel">
                                    <ul className='dk-bottomLeftLists'>
                                        <li>
                                            <span className='left-item d-flex align-items-center'>Wipro Bucharest</span>
                                            <span className='rightItem'>89</span>
                                        </li>
                                        <li>
                                            <span className='left-item d-flex align-items-center'>TP Greece</span>
                                            <span className='rightItem'>75</span>
                                        </li>
                                        <li>
                                            <span className='left-item d-flex align-items-center'>Tech M Bucharest</span>
                                            <span className='rightItem'>184</span>
                                        </li>
                                        <li>
                                            <span className='left-item d-flex align-items-center'>Tech M Lisbon</span>
                                            <span className='rightItem'>235</span>
                                        </li>
                                        <li>
                                            <span className='left-item d-flex align-items-center'>Tech M Sofia</span>
                                            <span className='rightItem'>85</span>
                                        </li>
                                        <li>
                                            <span className='left-item d-flex align-items-center'>Genpact Lisbon</span>
                                            <span className='rightItem'>35</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="dk-leftBoxes-panel">
                            <div className="dk-leftBoxes-cols">
                                <div className="title">RECRUITER</div>
                                <div className="dk-textPanel">
                                    <ul className='dk-bottomLeftLists'>
                                        <li>
                                            <span className='recuriterItem d-flex align-items-center'>
                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className='dkg-recuiter-img' alt='Recruiter' />
                                                Deepak
                                            </span>
                                            <span className='rightItem'>130</span>
                                        </li>
                                        <li>
                                            <span className='recuriterItem d-flex align-items-center'>
                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/user.png" className='dkg-recuiter-img' alt='Recruiter' />
                                                No User
                                            </span>
                                            <span className='rightItem'>220</span>
                                        </li>
                                        <li>
                                            <span className='recuriterItem d-flex align-items-center'>
                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/1667217631720.png" className='dkg-recuiter-img' alt='Recruiter' />
                                                Berta
                                            </span>
                                            <span className='rightItem'>88</span>
                                        </li>
                                        <li>
                                            <span className='recuriterItem d-flex align-items-center'>
                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/1668693896533.png" className='dkg-recuiter-img' alt='Recruiter' />
                                                Yuliia
                                            </span>
                                            <span className='rightItem'>70</span>
                                        </li>
                                        <li>
                                            <span className='recuriterItem d-flex align-items-center'>
                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className='dkg-recuiter-img' alt='Recruiter' />
                                                Onur
                                            </span>
                                            <span className='rightItem'>113</span>
                                        </li>
                                        <li>
                                            <span className='recuriterItem d-flex align-items-center'>
                                                <img src="https://d3eppau9to224z.cloudfront.net/users/images/1664396351247.png" className='dkg-recuiter-img' alt='Recruiter' />
                                                Angela
                                            </span>
                                            <span className='rightItem'>10</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dk-leftBoxes-main">
                        <div className="dk-leftBoxes-panel">
                            <div className="dk-leftBoxes-cols">
                                <div className="title">SALE</div>
                                <div className="dk-textPanel sm-height">
                                    <table className='table dkg-target-summ-boxtable dkg-clientsales-cols'>
                                        <thead>
                                            <tr>
                                                <th>Job Offer</th>
                                                <th>Refused</th>
                                                <th>Net Sale</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='dkg-joboffers'>15300 <span className='ml-1'>€</span></td>
                                                <td className='dkg-targets-refused'>1500 <span className='ml-1'>€</span></td>
                                                <td className='dkg-targets-netsale'>13800 <span className='ml-1'>€</span></td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="dk-leftBoxes-cols">
                                <div className="title">JOB OFFERS</div>
                                <div className="dk-textPanel sm-height">

                                </div>
                            </div>
                        </div>
                        <div className="dk-leftBoxes-panel">
                            <div className="dk-leftBoxes-cols">
                                <div className="title">REFUSED</div>
                                <div className="dk-textPanel"></div>
                            </div>
                            <div className="dk-leftBoxes-cols">
                                <div className="title">JOINED</div>
                                <div className="dk-textPanel"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessOverviewDashboard;