import React from 'react';
import { Link } from "react-router-dom";

const StatementsLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="dk-moduleBankingProcess-leftPanel">
                <ul>
                    {/* <li><Link to="/dkglobalfinance/banking/statements/overview" className={`${pathname.match('/dkglobalfinance/banking/statements/overview') ? 'active' : ''}`}>Overview</Link></li> */}
                    <li><Link to="/dkglobalfinance/banking/statements/2022" className={`${pathname.match('/dkglobalfinance/banking/statements/2022') ? 'active' : ''}`}>2022</Link></li>
                    <li><Link to="/dkglobalfinance/banking/statements/2021" className={`${pathname.match('/dkglobalfinance/banking/statements/2021') ? 'active' : ''}`}>2021</Link></li>
                    <li><Link to="/dkglobalfinance/banking/statements/2020" className={`${pathname.match('/dkglobalfinance/banking/statements/2020') ? 'active' : ''}`}>2020</Link></li>
                    <li><Link to="/dkglobalfinance/banking/statements/2019" className={`${pathname.match('/dkglobalfinance/banking/statements/2019') ? 'active' : ''}`}>2019</Link></li>
                    <li><Link to="/dkglobalfinance/banking/statements/2018" className={`${pathname.match('/dkglobalfinance/banking/statements/2018') ? 'active' : ''}`}>2018</Link></li>
                    <li><Link to="/dkglobalfinance/banking/statements/2017" className={`${pathname.match('/dkglobalfinance/banking/statements/2017') ? 'active' : ''}`}>2017</Link></li>
                    <li><Link to="/dkglobalfinance/banking/statements/2016" className={`${pathname.match('/dkglobalfinance/banking/statements/2016') ? 'active' : ''}`}>2016</Link></li>
                </ul>
            </div>
        </>
    )
}

export default StatementsLeftPanel;
