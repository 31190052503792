import React, { useState } from 'react';
import { Link, Route } from "react-router-dom";
import '../../trainingdetail.scss';

const UpdateProfilesDetailLeftPanel = () => {
    let pathname = window.location.pathname;
    return (
        <React.Fragment>
            <ul>
                <Route>
                    <li><Link to="/training/admins/update-profiles" className={`${pathname.match('/training/admins/update-profiles') ? 'active' : ''}`}>Overview</Link></li>

                    <li><Link to="/training/admins/updateprofiles/status" className={`${pathname.match('/training/admins/updateprofiles/status') ? 'active' : ''}`}>Status</Link></li>

                    <li><Link to="/training/admins/updateprofiles/contacts" className={`${pathname.match('/training/admins/updateprofiles/contacts') ? 'active' : ''}`}>Contacts</Link></li>

                    <li><Link to="/training/admins/updateprofiles/category" className={`${pathname.match('/training/admins/updateprofiles/category') ? 'active' : ''}`}>Category</Link></li>

                    <li><Link to="/training/admins/updateprofiles/cv-source" className={`${pathname.match('/training/admins/updateprofiles/cv-source') ? 'active' : ''}`}>CV Source</Link></li>

                    <li><Link to="/training/admins/updateprofiles/locations" className={`${pathname.match('/training/admins/updateprofiles/locations') ? 'active' : ''}`}>Locations</Link></li>

                    <li><Link to="/training/admins/updateprofiles/nationality" className={`${pathname.match('/training/admins/updateprofiles/nationality') ? 'active' : ''}`}>Nationality</Link></li>

                    <li><Link to="/training/admins/updateprofiles/languages" className={`${pathname.match('/training/admins/updateprofiles/languages') ? 'active' : ''}`}>Languages</Link></li>

                    <li><Link to="/training/admins/updateprofiles/assign-job" className={`${pathname.match('/training/admins/updateprofiles/assign-job') ? 'active' : ''}`}>Assign Job</Link></li>

                    <li><Link to="/training/admins/updateprofiles/case-comment" className={`${pathname.match('/training/admins/updateprofiles/case-comment') ? 'active' : ''}`}>Case Comment</Link></li>

                </Route>
            </ul>
        </React.Fragment>
    )
}

export default UpdateProfilesDetailLeftPanel;

