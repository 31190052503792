import React, { useState, useEffect } from 'react'
//import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import CVParingComponent from '../../../addnew/AddNew'
import './cvparsing.scss';
import { useDispatch, useSelector } from "react-redux";

const CvParsing = () => {
    const { isAPIParseSuccess, isManualParseSuccess } = useSelector(state => state.candidates);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (isAPIParseSuccess) {
            setShow(false)
        }
        if (isManualParseSuccess) {
            setShow(false)
        }
    }, [isAPIParseSuccess, isManualParseSuccess])

    return (
        <>
            <button onClick={handleShow} className="dk-reoderBtn">
                <i className="fas fa-plus"></i>
            </button>
            <Modal show={show} onHide={handleClose} className="dkg-pipe-cvparsingModal-11" centered>
                <Modal.Header closeButton className="dkg-pipe-cvparsingModalHeader-11">
                    <Modal.Title>Parse new CV</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-pipe-cvparsingModalBody-11">
                    <CVParingComponent />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CvParsing