import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import "./info.scss"


const KeytermsModal = ({ onHide }) => {

    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dkg-clientinfo-Modal-234"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton className="dkg-clientinfo-ModalHedaer">
                    <Modal.Title id="example-custom-modal-styling-title">
                        KEY TERMS VIEW
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='dkg-clientinfo-ModalBody'>
                    <div className="dkg-mody-title">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate maxime libero iste nobis quaerat! Beatae!</div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default KeytermsModal;
