import React, { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputGroup, FormControl, Button } from "react-bootstrap";

import {
  clearState,
  addInstructions,
} from "../../../../../../../slice/jobs/jobsSlice";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../../../slice/utils/message/messageSlice";

const Instructions = (jobId) => {
  const dispatch = useDispatch();

  const { isDetailsLoading, jobDetails, isUpdate } = useSelector(
    (state) => state.jobs
  );

  const [data, setData] = useState("");
  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setData(jobDetails);
    }
  }, [isDetailsLoading]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobDetails);
    }
  }, [isUpdate]);

  const [title, setTitle] = useState("");
  const handleButtonClick = async (e) => {
    e.preventDefault();
    if (title == "") {
      dispatch(showError({ msg: "All fields are required." }));
    } else {
      dispatch(addInstructions({ job_id: jobId.jobId, description: title }));
      setTitle("");
    }
  };

  return (
    <>
      <div className="row dk-Adsbox">
        <div className="col-md-12">
          <div className="col-md-12 instructionebox">
            <h3 className="font-weight-bold">INSTRUCTIONS TO FOLLOW</h3>
            <div
              className="scrollDiv pb-2"
              style={{ height: "calc(100vh - 301px)" }}
            >
              {data.AdInstructions !== undefined && data.AdInstructions.length
                ? data.AdInstructions.map((Ins, index) => (
                    <p key={index} className="pl-3 pt-1">
                      {Ins.description}
                    </p>
                  ))
                : null}
            </div>
          </div>

          <InputGroup className="mb-3">
            <FormControl
              className="text-left insInput"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              data-columnid="description"
              aria-describedby="description"
            />
            <button
              type="submit"
              onClick={handleButtonClick}
              className="btn insSubmit"
            >
              Submit
            </button>
          </InputGroup>
        </div>
      </div>
    </>
  );
};

export default Instructions;
