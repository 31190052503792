import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ViewFeedback from "./viewfeedback/ViewFeedback";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import './itteam.scss';
import Designer from './designers/Designer';


const TeamAmit = () => {
    const [ViewFeedbackModal, setViewFeebackstate] = useState(false)
    const showViewFeedbackModal = () => {
        setViewFeebackstate(true)
    }
    const hideViewFeedbackModal = () => {
        setViewFeebackstate(false)
    }
    return (
        <>
            {
                ViewFeedbackModal ?
                    <ViewFeedback handleClose={hideViewFeedbackModal} /> : null
            }
            <div className="dk-itTeamMain">
                <div className="dk-itTeamHead">
                    <div className="d-flex align-items-center justify-content-center dk-itTeam-topHeadtabCon">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                            <div className="row">
                                <div className='col-md-12'>
                                    <div className="dk-itTeamTabs-24">
                                        <Nav variant="pills dkg-itteam-navpills " className="flex-row">
                                            <Nav.Item className='dkg-itteam-navitem'>
                                                <Nav.Link eventKey="tabs_1">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/1580424792.jpeg" className='dkg-iteam-img' alt="" />
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className='dkg-itteam-navitem'>
                                                <Nav.Link eventKey="tabs_2">
                                                    <img src="https://www.dkmasterbox.com/assets/upload/user_image/1590537975.jpeg" className='dkg-iteam-img' alt="" />
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <div className='dkg-addnew-btnCon'>
                                            <Link to="#" className='dkg-addnew-btn'>
                                                <i className="fa fa-plus mr-2" aria-hidden="true"></i>
                                                Add New</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="tabs_1">
                                            <Designer />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tabs_2">
                                            <Designer />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </div>

            </div>
        </>
    )
}

export default TeamAmit;
