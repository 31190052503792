import React, { useEffect, useState } from "react";

const Switch = ({
  handleToggle,
  id,
  value,
  isChecked,
  fieldname,
  isDisabled,
}) => {
  const [chcked, setIsChecked] = useState(true);
  const handleOnChange = (e) => {
    setIsChecked((current) => !current);
    handleToggle(e);
  };
  return (
    <>
      <div className="dkg-feature-column">
        <span className="dkg-off-label mr-2">Deactivate</span>
        {isDisabled === "Yes" ? (
          <>
            <input
              className="react-switch-checkbox defaultActiveCheckbox"
              type="checkbox"
              disabled
              checked
              style={{ cursor: "text" }}
            />
          </>
        ) : (
          <>
            <input
              onChange={handleOnChange}
              id={`react-switch-new` + id + value}
              className="react-switch-checkbox"
              type="checkbox"
              data-id={id}
              data-value={value}
              data-name={fieldname}
              key={id + 1 + value}
              checked={isChecked === 1 ? chcked : ""}
            />
          </>
        )}

        <label
          className="react-switch-label"
          htmlFor={`react-switch-new` + id + value}
        >
          <span className="react-switch-button" />
        </label>
        <span className="dkg-off-label ml-2">Activate</span>
      </div>
    </>
  );
};

export default Switch;
