import React,{useState} from 'react'
import Modal from 'react-bootstrap/Modal'

const Summary = () => {
        const [showAddDate, setShow] = useState(false);
    const [showAddNumber, setShow2] = useState(false);

    const handleClose = () => setShow(false);
    const handleClose2 = () => setShow2(false);
    const handleShow = () => setShow(true);
    const handleShow2 = () => setShow2(true);

    return (
        <>
           <div className="dk-kpiColsMain">
                <div className="dk-kpitableMain">
                    <div className="dk-kpiTable">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>

                                        </th>
                                        <th>Week</th>
                                        <th>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad49d2ca540d.png" className="img-fluid img-circle" alt="" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c2940e458.png" className="img-fluid img-circle" alt="" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ca355dac8269.png" className="img-fluid img-circle" alt="" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5e54fdac9c.png" className="img-fluid img-circle" alt="" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/olga1623316236.jpg" className="img-fluid img-circle" alt="" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5c041fb96042e.png" className="img-fluid img-circle" alt="" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="dk-recrImg">
                                                <img src="https://www.dkmasterbox.com/assets/upload/user_image/5ad5c3ad279ec.png" className="img-fluid img-circle" alt="" />
                                            </div>
                                        </th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                20-Aug-2021 -<span>Mon</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>1</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>1</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                23-Aug-2021 -<span>Wed</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                24-Aug-2021 -<span>Thu</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>1</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                25-Aug-2021 -<span>Fri</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                27-Aug-2021 -<span>Mon</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                28-Aug-2021 -<span>Tue</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                20-Aug-2021 -<span>Mon</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>1</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>1</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                23-Aug-2021 -<span>Wed</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                24-Aug-2021 -<span>Thu</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>1</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                25-Aug-2021 -<span>Fri</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                27-Aug-2021 -<span>Mon</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                28-Aug-2021 -<span>Tue</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                20-Aug-2021 -<span>Mon</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>1</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>1</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                23-Aug-2021 -<span>Wed</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                24-Aug-2021 -<span>Thu</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>1</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                25-Aug-2021 -<span>Fri</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                27-Aug-2021 -<span>Mon</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="dk-dateDayCols" onClick={handleShow}>
                                                28-Aug-2021 -<span>Tue</span>
                                            </div>
                                        </td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>3</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>8</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>5</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>2</span></td>
                                        <td><span className="addNumberCols" onClick={handleShow2}>4</span></td>
                                        <td>62</td>
                                    </tr>

                                    <tr>
                                        <td colSpan="2" className="dk-brBgNone"></td>
                                        <td className="dk-brNone">92</td>
                                        <td className="dk-brNone">96</td>
                                        <td className="dk-brNone">184</td>
                                        <td className="dk-brNone">115</td>
                                        <td className="dk-brNone">46</td>
                                        <td className="dk-brNone">91</td>
                                        <td className="dk-brNone">713</td>
                                        <td className="dk-brNone">1426</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* Date and Day Modal */}

            <Modal className="dk-dateAndDayModal" show={showAddDate} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Date & Day</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <div className="row">
                            <div className="col-md-6 pr-2">
                                <div className="form-group">
                                    <label htmlFor="">Select Date</label>
                                    <label htmlFor="">
                                        <input type="date" name="" id="" className="form-control" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 pl-2">
                                <div className="form-group">
                                    <label htmlFor="">Select Day</label>
                                    <label htmlFor="">
                                        <select name="" id="" className="form-control">
                                            <option value="">Monday</option>
                                            <option value="">Tuesday</option>
                                            <option value="">Wednesday</option>
                                            <option value="">Thursday</option>
                                            <option value="">Friday</option>
                                            <option value="">Saturday</option>
                                            <option value="">Sunday</option>
                                        </select>
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group mt-2 mb-2 text-center">
                                    <button className="dk-AddBtn">Add</button>
                                </div>
                            </div>

                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            {/* End */}


            {/* Add Number Modal */}

            <Modal className="dk-addNumberModal" show={showAddNumber} onHide={handleClose2}>
                <Modal.Body>
                    <form action="">
                        <div className="row">
                            <div className="col-md-6 pr-2">
                                <div className="form-group">
                                    <label htmlFor="">Current</label>
                                    <label htmlFor="">
                                        <input type="text" value="5" name="" id="" className="form-control" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 pl-2">
                                <div className="form-group">
                                    <label htmlFor="">New</label>
                                    <label htmlFor="">
                                        <input type="text" name="" id="" className="form-control" />
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group mt-1 mb-0 text-center">
                                    <button className="dk-AddBtn">Save</button>
                                </div>
                            </div>

                        </div>
                    </form>
                </Modal.Body>
            </Modal> 
        </>
    )
}

export default Summary
