import React, { useEffect, useState } from 'react'

const ValueInput = ({ id, background, color, value, onBlur }) => {
    const [valueTxt, setValueTxt] = useState('')

    useEffect(() => {
        setValueTxt(value)
    }, [id])

    return (
        <input type="text" id={id} style={{ background: background, color: color, height: "35px", borderRadius: "0", border: "1px solid #ddd", boxShadow: "none !important" }} value={valueTxt} onChange={(e) => setValueTxt(e.target.value)} onBlur={onBlur} className="form-control" />
    )
}

export default ValueInput