import React from 'react';
import { Link } from "react-router-dom";

const RegistrationLeftPanel = () => {
    const pathname = window.location.pathname;
    const segment4 = pathname.split("/")[4];
    return (
        <>
            <div className="dk-registrationLeftpanel">
                <ul>
                    <li><Link to="/dkglobalfinance/legal/registration/2022" className={`${(segment4 === '2022') ? 'active' : ''}`}>2022</Link></li>
                    <li><Link to="#" className={`${(segment4 === 'assigned') ? '' : ''}`}>2021</Link></li>
                    <li><Link to="#" className={`${(segment4 === 'qualified') ? '' : ''}`}>2020</Link></li>
                    <li><Link to="#" className={`${(segment4 === 'job-specs') ? '' : ''}`}>2019</Link></li>
                    <li><Link to="#" className={`${(segment4 === 'job-specs') ? '' : ''}`}>2018</Link></li>
                    <li><Link to="#" className={`${(segment4 === 'sendouts') ? '' : ''}`}>2017</Link></li>
                    <li><Link to="#" className={`${(segment4 === 'sendouts') ? '' : ''}`}>2016</Link></li>
                </ul>
            </div>
        </>
    )
}

export default RegistrationLeftPanel
