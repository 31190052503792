import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './mainmenusubmenuc.scss';

const GetStartedtSubmenu = () => {
    const [show, setHideMenu] = React.useState();

    const pathname = window.location.pathname;
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];
    return (
        <React.Fragment>
            <div className={`dk-subMenuMain dkg-subMainMenu ${show ? "hide" : ""}`} style={{ display: 'none' }}>
                <button className="dk-closeSubmenuBtn" onClick={() => setHideMenu(true)}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
                <Route>
                    <ul>
                        <li><Link to="/get-started/summary" className={(segment2 == 'summary') ? 'active' : ''}>Summary</Link></li>
                        <li><Link to="/get-started/preparation/" className={`${(segment2 == 'preparation') ? 'active' : ''}`}>Preparation</Link></li>
                        <li><Link to="/get-started/morning/" className={`${(segment2 == 'morning') ? 'active' : ''}`}>Morning</Link></li>
                        <li><Link to="/get-started/lunch/" className={`${(segment2 == 'lunch') ? 'active' : ''}`}>Lunch</Link></li>
                        <li><Link to="/get-started/afternoon/" className={`${(segment2 == 'afternoon') ? 'active' : ''}`}>Afternoon</Link></li>
                        <li><Link to="/get-started/closing/" className={`${(segment2 == 'closing') ? 'active' : ''}`}>Closing</Link></li>
                        <li><Link to="/get-started/meetings/" className={`${(segment2 == 'meetings') ? 'active' : ''}`}>Meetings</Link></li>
                        <li><Link to="/get-started/workplan" className={`${(segment2 == 'workplan') ? 'active' : ''}`}>Workplan</Link></li>
                        <li><Link to="/get-started/pipelines/" className={`${(segment2 == 'pipelines') ? 'active' : ''}`}>Pipelines</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default GetStartedtSubmenu;
