import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./view-project.scss";

const ViewProject = ({ summary }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <span className="dkg-info-icon" onClick={handleShow}>
        View Summary
      </span>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="dkg-projects-summModal"
      >
        <Modal.Header closeButton className="dkg-projects-summModalHeader">
          <Modal.Title>Project Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-projects-summModalBody">
          {summary}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewProject;
