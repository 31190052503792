import React from 'react'
import { Link } from "react-router-dom";
import './trainingdetail.scss';

const Submenu = () => {
    let pathname = window.location.pathname;
    

    return (
        <div> className={`${pathname.match('#') ? 'active' : ''}`}
            <div className="dksubMenu dk-subMenuMain">
                <ul className="d-flex">
                    <li>
                        <Link to="/recruitment/training/summary" className={`${pathname.match('/training/summary') ? 'active' : ''}`}>Overview</Link>
                    </li>
                    <li>
                        <Link to="/recruitment/training/schedule/overview" className={`${pathname.match('/training/schedule') ? 'active' : ''}`}>Schedule</Link>
                    </li>
                    <li>
                        <Link to="/recruitment/training/onboarding/welcome" className={`${pathname.match('/training/onboarding') ? 'active' : ''}`}>Onboarding</Link>
                    </li>

                    {/* <li>
                        <Link to="/recruitment/training/tools" className={`${pathname.match('/training/tools') ? 'active' : ''}`}>Tools</Link>
                    </li> */}

                    <li>
                        <Link to="/recruitment/training/commontraining/common-training" className={`${pathname.match('/training/commontraining') ? 'active' : ''}`}>Common</Link>
                    </li>

                    <li>
                        <Link to="/recruitment/training/training-resource" className={`${pathname.match('/training/training-resource') ? 'active' : ''}`}>Team 1</Link>
                    </li>
                    <li>
                        <Link to="/recruitment/training/admins" className={`${pathname.match('/training/admins') ? 'active' : ''}`}>Team 2</Link>
                    </li>
                    <li>
                        <Link to="/recruitment/training/recruiters" className={`${pathname.match('/training/recruiters') ? 'active' : ''}`}>Team 3</Link>
                    </li>
                    <li>
                        <Link to="/recruitment/training/managers" className={`${pathname.match('/training/managers') ? 'active' : ''}`}>Team 4</Link>
                    </li>
                    <li>
                        <Link to="/recruitment/training/management" className={`${pathname.match('/training/management') ? 'active' : ''}`}>Management</Link>
                    </li>

                    <li>
                        <Link to="/recruitment/training/assessments/overview" className={`${pathname.match('/training/assessments') ? 'active' : ''}`}>Assessments</Link>
                    </li>

                    <li>
                        <Link to="/recruitment/training/completion/overview" className={`${pathname.match('/training/completion') ? 'active' : ''}`}>Status</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default Submenu;
