import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

const RightTabs = () => {
    return (
        <>
            <div className="dk-rightContentTabs">
                <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                    <div className="leftPanelTabs">
                        <Nav variant="pills">
                            <Nav.Item>
                                <Nav.Link eventKey="tabs_1">Heading 1</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tabs_2">Heading 2</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tabs_3">Heading 3</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tabs_4">Heading 4</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tabs_5">Heading 5</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tabs_6">Heading 6</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tabs_7">Heading 7</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tabs_8">Heading 8</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>

                    <div className="dk-rightPanelBody">
                        <Tab.Content>
                            <Tab.Pane eventKey="tabs_1">
                                <div className="dk-addNew-editor">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus quos at possimus, molestiae, sapiente nulla modi molestias earum, porro optio aut. Quas, saepe deserunt enim aut vitae doloribus illo mollitia?
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tabs_2">
                                <div className="dk-addNew-editor">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus quos at possimus, molestiae, sapiente nulla modi molestias earum, porro optio aut. Quas, saepe deserunt enim aut vitae doloribus illo mollitia?
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default RightTabs;
