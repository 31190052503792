import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
}));

const renderType = (type, value, className) => {
    switch (type) {
        case 'img':
            return <img src={value} className={className} />
            break;
    }
}

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const CustomTooltip = ({ type, title, value, className }) => {
    return (
        <BootstrapTooltip title={title}>
            {renderType(type, value, className)}
        </BootstrapTooltip>
    );
}
export default CustomTooltip;