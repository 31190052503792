import React from 'react';
const FormProgressDiv = ({ value }) => {
    let bgColor = "";
    switch (value) {
        case 'To Do':
            bgColor = '#d58b5d'
            break;

        case 'In Progress':
            bgColor = '#26597d'
            break;

        case 'Completed':
            bgColor = '#3a9469'
            break;

        default:
            bgColor = '#000000'
            break
    }

    return (
        <>
            <div className="dk-taskText" style={{ backgroundColor: bgColor, color: '#FFF', minWidth: '80px' }}>{value}</div>
        </>
    )
}

export default FormProgressDiv;