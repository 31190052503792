import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
// import 'semantic-ui-css/semantic.min.css';
import Success from "./components/utils/messages/success";
// import 'semantic-ui-css/semantic.min.css';
import Error from "./components/utils/messages/error";
import ProgressBar from "./components/utils/loader/linear";
import { PageLoader } from "./components/ui/pageLoader";
//const { PageLoader } = lazy(() => import('./components/ui/pageLoader'));

let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ProgressBar />
          <Success />
          <Error />
          <Suspense fallback={<PageLoader />}>
            <App />
          </Suspense>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
