import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DropdownButton, Dropdown } from 'react-bootstrap'

const PaymentCategory = () => {
    const TaskForDropdown = styled.div`
        .dropdown{
            display: flex;
            justify-content: center;
            align-items: center;
            .dropdown-toggle{
                background-color: #fff !important;
                border: 1px solid #ddd !important;
                color: #333;
                font-size: 12px;
                padding: 7px 5px;
                text-align: center;
                text-transform: uppercase;
                width: 100%;
                width: 145px;
                min-width: 145px;
                box-shadow: none !important;
                margin: 0;
                &::after {
                    display: inline-block !important;
                    font-family: ' Font Awesome 5 Free';
                    font-style: normal;
                    font-weight: 700;
                    margin: 0 0 0 10px;
                    font-size: 13px;
                    position: absolute;
                    top: 16px;
                    text-decoration: none;
                    border-top: 0.33em solid;
                    border-right: 0.33em solid transparent;
                    border-bottom: 0;
                    border-left: 0.33em solid transparent;
                }
                &.btn-primary:not([disabled]):not(.disabled):active,
               &.btn-primary:not([disabled]):not(.disabled).active, 
               .show>.btn-primary.dropdown-toggle {
                background-color: #fff !important;
                border: 1px solid #ddd !important;
                color: #333 !important;
               }
            }
            .dropdown-menu{
                border: none;
                border-radius: 0;
                padding: 0;
                width: 100%;
                max-height: 300px;
                overflow-y: auto;
                background-color: #26597d;
                transform: inherit !important;
                top: 37px !important;
                .dropdown-item{
                    align-items: center;
                    border: none;
                    background-color: #26597d;
                    border-bottom: 1px solid #919191;
                    display: flex;
                    font-size: 13px;
                    position: relative;
                    text-transform: capitalize;
                    width: 100%; 
                    color: #fff;
                    padding: 5px 7px;
                    span.dropdown-filter-count {
                        align-items: center;
                        background-color: #3c4c62;
                        border-radius: 2px;
                        color: #fff;
                        display: flex;
                        font-size: 11px;
                        justify-content: center;
                        padding: 1px 0;
                        position: absolute;
                        right: 4px;
                        text-align: center;
                        top: 6px;
                        width: 20px;
                    }                  
                }
            }
        }
        .crossUserFilter {
            right: -2px  !important;
        }
    `;

    return (
        <>
            <TaskForDropdown>
                <DropdownButton id="dropdown-basic-button" title="Category">

                    <Dropdown.Item href="#" data-type="paymentType" >Category 1
                        <span className="dropdown-filter-count">5</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#" data-type="paymentType" >Category 2
                        <span className="dropdown-filter-count">5</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#" data-type="paymentType" >Category 3
                        <span className="dropdown-filter-count">5</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#" data-type="paymentType" >Category 4
                        <span className="dropdown-filter-count">5</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#" data-type="paymentType" >Category 5
                        <span className="dropdown-filter-count">5</span>
                    </Dropdown.Item>
                </DropdownButton>
            </TaskForDropdown>
        </>
    )
}

export default PaymentCategory;