import React, { useEffect, useState, useMemo } from 'react'
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useAsyncDebounce } from 'react-table';
import CaseStatus from '../../dropdown/caseStatus';
import CaseOther from '../../dropdown/other';
import './duplicatecheck.scss';
import Dropdown from 'react-bootstrap/Dropdown'
import { useDispatch, useSelector } from "react-redux";
import "../../pipeline.scss";
import CandidateProfile from "../candidateprofile";
import Checkbox from '../../../../../pages/candidate/pipeline/utils/checkbox/duplicateCheck'
import { moveDuplicateCandidateRecords } from "../../../../../../slice/candidates/list/listSlice";
import $ from "jquery";

const useInstance = (instance) => {
  if (instance && instance.getInstanceCallback) {
    instance.getInstanceCallback(instance);
  }
};

function Table({ columns, data, getInstanceCallback }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    prepareRow,
    state,
    setFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      getInstanceCallback,
      initialState: {
        pageIndex: 0, pageSize: 2000
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy, usePagination, (hooks) => hooks.useInstance.push(useInstance)
  )

  return (
    <React.Fragment>
      <table className="dk-check-dupplicate-table table table-bordered" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className, // pay attention to this
                          style: cell.column.style,
                          // set here your other custom props
                        },
                      ])}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            }
          )}
        </tbody>
      </table>
    </React.Fragment>
  )
}

const DataTable = ({ dataList }) => {
  const dispatch = useDispatch();
  const { isLoading, isUpdate, isdataUpdate, isApiduplicateMoved, message } = useSelector(state => state.candidates);

  const [list, setList] = useState([]);
  const [filterList, setFilterList] = useState([]);

  useEffect(() => {
    setList(dataList)
  }, [dataList])

  useEffect(() => {
    if (isApiduplicateMoved) {
      setList([])
    }
  },[isApiduplicateMoved]);

  const data = useMemo(() => list, [list])

  const [canId, setCanId] = useState(0)
  const [isProfileModal, setIsProfileModal] = useState(false)

  const handleCloseProfile = () => {
    setIsProfileModal(false);
  }


  const columns = useMemo(() => [
    {
      Header: '#',
      accessor: 'canId',
      Cell: ({ cell }) => {
        const handleShow = (canId) => {
          setCanId(canId)
          setIsProfileModal(true)
        }

        return (
          <>
            <div className='dkg-dup-id-prviewCon'>
              <div className='dkg-dup-sn-counter mr-2'>
                {/* {Number(cell.row.id) + 1} */}
                <div className="pipleFilterCols" style={{ color: 'blue', cursor: 'pointer' }} onClick={() => handleShow(cell.row.original.canId)}>{cell.row.original.canId}                 
                </div>
                <span className="dk-checkinputIcon">
                <Checkbox id={cell.row.original.canId} duplicateCheckActiveExtraDiv={duplicateCheckActiveExtraDiv} duplicateCheckExtraDivBtnTxt={duplicateCheckExtraDivBtnTxt} />
              </span>

              </div>             
            </div>
          </>
        )
      }
    },
    // {
    //   Header: 'Candidate Id',
    //   accessor: 'canId',
    //   className: 'pl-2 pr-2 text-center',
    //   Cell: ({ cell }) => {
    //     const handleShow = (canId) => {
    //       setCanId(canId)
    //       setIsProfileModal(true)
    //     }
    //     return (
    //       <React.Fragment>
    //         <div className="pipleFilterCols" style={{ color: 'blue', cursor: 'pointer' }} onClick={() => handleShow(cell.row.original.canId)}>{cell.row.original.canId} </div>
    //         <span className="dk-checkinputIcon">
    //             <Checkbox id={cell.row.original.canId} duplicateCheckActiveExtraDiv={duplicateCheckActiveExtraDiv} duplicateCheckExtraDivBtnTxt={duplicateCheckExtraDivBtnTxt} />
    //           </span>
    //       </React.Fragment>
    //     )
    //   }
    // },
    {
      id: 'RC',
      Header: 'RC',
      accessor: 'recDetails.firstName',
      Cell: ({ cell }) => {
        return (
          <React.Fragment>
            <div className="dk-dup-recimgCon">
              <div className="tooltipMain">
                <img title={cell.row.original.recDetails.firstName} src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.recDetails.profileImage} className="filterImg" alt={cell.row.original.recDetails.firstName} />
                <div className="tooltipText">{cell.row.original.recDetails.firstName + " " + cell.row.original.recDetails.lastName}</div>
              </div>
            </div>
          </React.Fragment>
        )
      }
    },
    {
      Header: 'Candidate Name',
      accessor: 'name',
      className: 'pl-2 pr-2 text-left'
    },
    {
      Header: 'Status',
      accessor: 'dkg-case-status',
      className: '',
      Cell: () => {
        return (
          <> 
            <Dropdown className="dkg-caseopen-dropdown">
              <Dropdown.Toggle variant="" className="dkg-caseopen-dropdown-toggle" style={{backgroundColor: "#316a67" , color: "#fff"}}>
                Open
              </Dropdown.Toggle>
              <Dropdown.Menu className="dkg-caseopen-dropdown-menu">
                <Dropdown.Item href="#" className="dkg-caseopen-dropdown-item" style={{backgroundColor: "#eaeaea" , color: "#008000"}}>Open</Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-caseopen-dropdown-item" style={{backgroundColor: "#8b572a" , color: "#fff"}}>On Hold</Dropdown.Item>
                <Dropdown.Item href="#" className="dkg-caseopen-dropdown-item" style={{backgroundColor: "#da3d5e" , color: "#fff"}}>Closed</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )
      }
    },
    {
      id: 'case_status',
      Header: 'Case Stage',
      accessor: 'caseStatus',
      Cell: ({ cell }) => {
        return <CaseStatus value={cell.row.original.caseStatus} />
      }
    },
    {
      id: 'case_progress',
      Header: 'Case Progress',
      accessor: 'case_progress',
      Cell: ({ cell }) => {
        return <CaseOther caseStatus={cell.row.original.caseStatus} value={cell.row.original.case_progress} />
      }
    },
    {
      id: 'case_stage',
      Header: 'Case Situation',
      accessor: 'case_stage',
      Cell: ({ cell }) => {
        return <CaseOther caseStatus={cell.row.original.caseStatus} value={cell.row.original.case_stage} />
      }
    },
    {
      Header: 'Pipeline',
      accessor: 'status'
    },
  ], [])

  const [instanceData, setInstanceData] = useState('0')
  const reactTableInstance = (instance) => {
    if (instance) {
      setFilterList(instance.flatRows)
      // console.log(instance)
      setInstanceData(instance)
    }
  };



  const [duplicateCheckBtnTxt, setDuplicateCheckBtnTxt] = useState('Check All')
  const [duplicateCountCheck, setDuplicateCountCheck] = useState(0)
  const [duplicateIsActive, setDuplicateIsActive] = useState(false);
  const [duplicateDataMoveTo, setDuplicateDataMoveTo] = useState('')

  const duplicateCheckActiveExtraDiv = (isShowExitra, checkedBoxCount) => {
    if (isShowExitra) {
      setDuplicateIsActive(true)
      setDuplicateCountCheck(checkedBoxCount)
    } else {
      setDuplicateIsActive(false)
      setDuplicateCountCheck(checkedBoxCount)
    }
  }
  useEffect(() => {
    if (duplicateDataMoveTo !== '') {
      (async () => {
        var checkedBoxCount = $('input[name="chkDuplicateCandidate[]"]:checked').length;

        if (checkedBoxCount == 0) {
        } else {
          var canIds = [];
          $.each($(".chkDuplicateCandidate:checked"), function () {           
            canIds.push($(this).val());
          });
            
         await dispatch(moveDuplicateCandidateRecords({ ids: canIds, status: duplicateDataMoveTo }))
          $('.chkDuplicateCandidate').prop('checked', false);
          setDuplicateIsActive(false);
        }
      })();
    }
  }, [duplicateDataMoveTo])

  const duplicateCheckExtraDivBtnTxt = (btnTxt) => {
    setDuplicateCheckBtnTxt(btnTxt)
  }

  const toggleDuplicateCheckAll = () => {
    var checkedBoxCount = $('input[name="chkDuplicateCandidate[]"]:checked').length;
    var totalCheckboxes = $('input[name="chkDuplicateCandidate[]"]').length;
    if (checkedBoxCount < totalCheckboxes) {
      setDuplicateCheckBtnTxt('Uncheck All')
      setDuplicateCountCheck(totalCheckboxes)
      $('.chkDuplicateCandidate').prop('checked', true);
    }
    if (checkedBoxCount == totalCheckboxes) {
      setDuplicateCheckBtnTxt('Check All')
      setDuplicateCountCheck(0)
      $('.chkDuplicateCandidate').prop('checked', false);
      setDuplicateIsActive(false)
    }
  }

  const moveDuplicateRecords = async (e) => {
    setDuplicateDataMoveTo(e.target.dataset.moveto)
  }

  const duplicateHandleToggle = () => {
    $('.chkDuplicateCandidate').prop('checked', false);
    setDuplicateIsActive(false);
  };


  return (
    <React.Fragment>
      {
        (isProfileModal) ? <CandidateProfile canid={canId} list={filterList} isProfileModal={isProfileModal} handleCloseProfile={handleCloseProfile} /> : null
      }
      <Table
        columns={columns}
        data={data}
        getInstanceCallback={reactTableInstance}
      />
      <div className={`batch-actions-menu-wrapper ${duplicateIsActive ? "activePopup" : ""}`} style={{ width: '1116px' }}>
        <div className="num-of-actions_wrapper">
          <div className='num-of-actions'>{duplicateCountCheck}</div>
        </div>
        <div className="batch-actions-title-section pl-0">
          <div className='dk-rowSelected'>
            <span>Row Selected</span>
          </div>
        </div>
        {/* <div className="batch-actions-item dk-checkAll" onClick={toggleDuplicateCheckAll}>
                    <span><i className="fas fa-check"></i></span>
                    {duplicateCheckBtnTxt}
                </div> */}

        <div className="batch-actions-item recadmin-checkboxbg" data-moveto="Selected" onClick={moveDuplicateRecords}>
          <span data-moveto="Selected" onClick={moveDuplicateRecords}>
            <i className="fa fa-arrow-circle-right" data-moveto="Selected" onClick={moveDuplicateRecords}></i>
          </span>
          Selected
        </div>

        <div className="batch-actions-item processing-checkboxbg" data-moveto="To Progress" onClick={moveDuplicateRecords}>
          <span data-moveto="To Progress" onClick={moveDuplicateRecords}>
            <i className="fa fa-arrow-circle-right" data-moveto="To Progress" onClick={moveDuplicateRecords}></i>
          </span>
          To Do
        </div>
        <div className="batch-actions-item notreachable-checkboxbg" data-moveto="In Progress" onClick={moveDuplicateRecords}>
          <span data-moveto="In Progress" onClick={moveDuplicateRecords}>
            <i className="fa fa-arrow-circle-right" data-moveto="In Progress" onClick={moveDuplicateRecords}></i>
          </span>
          In Progress
        </div>
        <div className="batch-actions-item interseted-checkboxbg" data-moveto="Completed" onClick={moveDuplicateRecords}>
          <span data-moveto="Completed" onClick={moveDuplicateRecords}>
            <i className="fa fa-arrow-circle-right" data-moveto="Completed" onClick={moveDuplicateRecords}></i>
          </span>
          Completed
        </div>
        <div className="batch-actions-item sendouts-checkboxbg" data-moveto="Sendout" onClick={moveDuplicateRecords}>
          <span data-moveto="Sendout" onClick={moveDuplicateRecords}>
            <i className="fa fa-arrow-circle-right" data-moveto="Sendout" onClick={moveDuplicateRecords}></i>
          </span>
          Sendout
        </div>
        <div className="batch-actions-item joboffer-checkboxbg" data-moveto="Job Offer" onClick={moveDuplicateRecords}>
          <span data-moveto="Job Offer" onClick={moveDuplicateRecords}>
            <i className="fa fa-arrow-circle-right" data-moveto="Job Offer" onClick={moveDuplicateRecords}></i>
          </span>
          Job Offer
        </div>

        <div className="batch-actions-item calling-checkboxbg" data-moveto="Case Query" onClick={moveDuplicateRecords}>
          <span data-moveto="Case Query" onClick={moveDuplicateRecords}>
            <i className="fa fa-arrow-circle-right" data-moveto="Case Query" onClick={moveDuplicateRecords}></i>
          </span>
          Review Case
        </div>
        <div className="batch-actions-item closed-checkboxbg" data-moveto="Closed" onClick={moveDuplicateRecords}>
          <span data-moveto="Closed" onClick={moveDuplicateRecords}>
            <i className="fa fa-arrow-circle-right" data-moveto="Closed" onClick={moveDuplicateRecords}></i>
          </span>
          Closed
        </div>
        <div className="batch-actions-item dkg-newdb-checkboxbg" data-moveto="Database" onClick={moveDuplicateRecords}>
          <span data-moveto="Database" onClick={moveDuplicateRecords}>
            <i className="fa fa-arrow-circle-right" data-moveto="Database" onClick={moveDuplicateRecords}></i>
          </span>
          Database
        </div>
        <div className="batch-actions-item archived-checkboxbg" data-moveto="Archive" style={{ backgroundColor: "#6a0707", color: "#fff" }} onClick={moveDuplicateRecords}>
          <span data-moveto="Archive" onClick={moveDuplicateRecords}>
            <i className="fa fa-arrow-circle-right" data-moveto="Archive" onClick={moveDuplicateRecords}></i>
          </span>
          Archived
        </div>

        <div className="batch-actions-delete-item CloseAll d-flex align-items-center justify-content-center" onClick={duplicateHandleToggle}>
          <span><i className="fas fa-times"></i></span>
        </div>
      </div>

    </React.Fragment>
  )
}

export default DataTable