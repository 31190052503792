import React from "react";
import { Route, Link } from "react-router-dom";

const IncomeSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];
    const segment4 = pathname.split("/")[4];

    return (
        <React.Fragment>
            <div className="dk-subMenuMain">
                <Route>
                    <ul>
                        <li><Link to="/dkglobalfinance/accounting/income/summary" className={`${(segment4 == 'summary') ? 'active' : ''}`}> Test Summary</Link></li>
                        <li><Link to="/dkglobalfinance/accounting/income/2023" className={`${(segment4 == '2023') ? 'active' : ''}`}>2023</Link></li>
                        <li><Link to="/dkglobalfinance/accounting/income/2022" className={(segment4 == '2022') ? 'active' : ''}>2022</Link></li>
                        <li><Link to="#/dkglobalfinance/accounting/income/2021" className={(segment4 == '2021') ? 'active' : ''}>2021</Link></li>
                        <li><Link to="#/dkglobalfinance/accounting/income/2020" className={(segment4 == '2020') ? 'active' : ''}>2020</Link></li>
                        <li><Link to="#/dkglobalfinance/accounting/income/2019" className={(segment4 == '2019') ? 'active' : ''}>2019</Link></li>
                        <li><Link to="#/dkglobalfinance/accounting/income/2018" className={(segment4 == '2018') ? 'active' : ''}>2018</Link></li>
                        <li><Link to="#/dkglobalfinance/accounting/income/2017" className={(segment4 == '2017') ? 'active' : ''}>2017</Link></li>
                        <li><Link to="#/dkglobalfinance/accounting/income/2016" className={(segment4 == '2016') ? 'active' : ''}>2016</Link></li>
                        <li><Link to="#/dkglobalfinance/accounting/income/others" className={(segment4 == 'others') ? 'active' : ''}>Others</Link></li>

                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default IncomeSubmenu;
