import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal, Dropdown, DropdownButton } from 'react-bootstrap'
import './loginofficeaddnew.scss';

const AddNew = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Link to="#" onClick={handleShow} className="dk-resCrcleIcon"> <i className="fas fa-plus"></i></Link>
            <Modal show={show} onHide={handleClose} className="dkg-office365Modal" centered>
                <Modal.Header closeButton className="dkg-office365ModalHeader">
                    <Modal.Title>Add New</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dkg-office365ModalBody">
                    <form>
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="form-group">
                                    <label>Name of Company</label>
                                    <div className="dk-resDropDown">
                                        <input type="text" className="form-control" defaultValue="" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Category</label>
                                    <div className="dk-resDropDown">
                                        <DropdownButton className='dk-logins-categoryDropdown' title="Select Category">
                                            <Dropdown.Item className='dk-logins-categoryDropdown-Item' href="#">Job Board</Dropdown.Item>
                                            <Dropdown.Item className='dk-logins-categoryDropdown-Item' href="#">Clients Portals</Dropdown.Item>
                                            <Dropdown.Item className='dk-logins-categoryDropdown-Item' href="#">Communication</Dropdown.Item>
                                            <Dropdown.Item className='dk-logins-categoryDropdown-Item' href="#">Social Media</Dropdown.Item>
                                            <Dropdown.Item className='dk-logins-categoryDropdown-Item' href="#">Domain Hosting</Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>User Name</label>
                                    <div className="dk-resDropDown">
                                        <input type="text" className="form-control" defaultValue="" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <div className="dk-resDropDown">
                                        <input type="text" className="form-control" defaultValue="" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Website Link</label>
                                    <div className="dk-resDropDown">
                                        <input type="text" className="form-control" defaultValue="" />
                                    </div>
                                </div>
                                <div className="dk-sbmitbtnCon">
                                    <button className=" dk-sbmitbtn btn " type="submit">SAVE</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddNew