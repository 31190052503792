import React from "react";
const TypeValue = ({ value }) => {
  let bgColor = "";
  switch (value) {
    case "Income":
      bgColor = "#3a9469";
      break;

    case "Expense":
      bgColor = "#d91010";
      break;

    default:
      bgColor = "#3a9469";
      break;
  }

  return (
    <>
      <div
        className="caseDiv"
        style={{ backgroundColor: bgColor, color: "#FFF", minWidth: "80px" }}
      >
        {value}
      </div>
    </>
  );
};

export default TypeValue;
