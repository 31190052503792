import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, FormControl } from 'react-bootstrap'
import './createtaskfor.scss'
import './createtaskdrop2.scss'
import { useSelector } from "react-redux";
import $ from "jquery";
const TaskForDropdown = ({ taskType, selectUser, countUser, selectUserImg }) => {
    const { usersList } = useSelector(state => state.users)
    const { userInfo } = useSelector(state => state.auth);
    const myFunction = () => {
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        ul = document.getElementById("myUL");
        li = ul.getElementsByTagName("li");
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("a")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    }

    useEffect(() => {
        if (countUser > 0) {
            $('#myInput').val('')
            myFunction()
        }
    }, [countUser])

    return (
        <>
            {
                <Dropdown className='dk-task-for-dropdown dk-task-for-dropdown-24'>
                    <Dropdown.Toggle className='dk-task-for-dropdown-toggle'>
                        <div className="dk-taskByCon">
                            <div className="dk-selectator-textlength">
                                {
                                    // (countUser > 0) ?
                                    //     selectUserImg.map((item, index) => {
                                    //         return (
                                    //             <div className="dk-selected-items mr-1" key={index}>
                                    //                 <div className="userImgCont">
                                    //                     <img src={item} alt="Deepak Kumar" className="userImg" />
                                    //                 </div>
                                    //                 <div className="dk-selecteditem-remove">X</div>
                                    //             </div>
                                    //         )
                                    //     })
                                    //     : null
                                    <div className="dk-selected-items mr-1">
                                        <div className="userImgCont">
                                            <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + selectUserImg} className="userImg" />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='dk-task-for-dropdown-menu'>
                        <FormControl autoFocus className="dk-searchInut" placeholder="Search here..." onKeyUp={myFunction} id="myInput" />
                        <ul className="list-unstyled dkg-task-ul-con-24" id="myUL">
                            {usersList.map((list, index) => (
                                <li className="dropdown-item" key={index} onClick={() => selectUser(list.id, list.profileImage, list.firstName + " " + list.lastName)}>
                                    <span className='dk-userCon'>
                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + list.profileImage} alt={list.firstName + " " + list.lastName} className='dk-userimg' />
                                    </span>
                                    <a className='dk-taskforuserName'>{list.firstName + " " + list.lastName}</a>
                                </li>
                            ))}
                        </ul>
                    </Dropdown.Menu>
                </Dropdown>
            }
        </>
    )
}

export default TaskForDropdown;
