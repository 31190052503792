import React, { useState, useEffect, useRef } from "react";
import "./addnewjob.scss";
import { useDispatch, useSelector } from "react-redux";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SearchDropDownFilter from "../../../jobs/addnew/SearchFilter";
import CompanyDropdown from "../../../jobs/addnew/CompanyDropdown";
import StatusDropDown from "./SearchFilter";
import { CKEditor } from "@ckeditor/ckeditor5-react";
//import DatePicker from './DatePicker'

import {
  addNewJob,
  clearState,
} from "../../../../../slice/interviewSpec/jobsSlice";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../slice/utils/message/messageSlice";

import {
  companyData,
  companyProjectData,
  companyRoleData,
} from "../../../../../slice/companies/clients/clientSlice";

const AddNew = () => {
  const dispatch = useDispatch();
  const { isLoading, companyList, companyProjtList, companyRolList } =
    useSelector((state) => state.companies);

  const {
    jobsCategoryList,
    jobsProjectList,
    jobsRoleTypeList,
    jobsLanguageList,
  } = useSelector((state) => state.common);
  const { message, isSuccess } = useSelector((state) => state.interviewSpec);
  //  console.log(jobsIndustryIdList)
  const [isRefresh, setIsRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [jobid, setJobid] = useState("");
  const [title, setTitle] = useState("");
  const [categoryList, setCategoryList] = useState("");
  const [companyesList, setCompanyList] = useState("");
  const [projectList, setProjectList] = useState("");
  const [roleTypeList, setRoleTypeList] = useState("");
  const [statusListValue, setStatusListValue] = useState("");
  const [languageList, setLanguageList] = useState("");
  const [companydata, setCompanydata] = useState(
    companyList.filter((item) => item.inStatus === 1)
  );
  //console.log(companyList)

  useEffect(() => {
    dispatch(clearState());
    const fetchList = async () => {
      try {
        await dispatch(companyData());
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setLoading(false);
      setSuccess(true);
      setJobid("");
      setTitle("");
      setCategoryList("");
      setCompanyList("");
      setProjectList("");
      setRoleTypeList("");
      setStatusListValue("");
      setLanguageList("");
      formRefresh();
      dispatch(showSuccess({ msg: message }));
      dispatch(clearState());
    }
  }, [isSuccess]);

  const formRefresh = () => {
    setIsRefresh(true);
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();
    if (
      !categoryList ||
      !companyesList ||
      !projectList ||
      !roleTypeList ||
      !languageList ||
      !title ||
      !jobid ||
      !statusListValue
    ) {
      dispatch(showError({ msg: "All fields are required." }));
    } else {
      setSuccess(false);
      setLoading(true);
      dispatch(
        addNewJob({
          job_id: jobid,
          name: title,
          category: categoryList,
          company: companyesList,
          project: projectList,
          role: roleTypeList,
          lang: languageList,
          status: statusListValue,
        })
      );
      setIsRefresh(false);
    }
  };

  const getCatValue = (data) => {
    setCategoryList(data);
  };
  const getCompanyValue = (data) => {
    setCompanyList(data);
    console.log("Pdtaa3")
    console.log(data)
    dispatch(companyProjectData({ name: data }));
    dispatch(companyRoleData({ name: data }));
  };
  const getProjectValue = (data) => {
    console.log(data);
    setProjectList(data);
  };
  const getRoleTypetValue = (data) => {
    setRoleTypeList(data);
  };
  const getLanguagetValue = (data) => {
    setLanguageList(data);
  };
  const getStatusValue = (data) => {
    setStatusListValue(data);
  };

  const statusList = [
    {
      id: 1,
      value: "Active",
    },
    {
      id: 0,
      value: "Inactive",
    },
  ];
  return (
    <>
      <div className="container-fluid dk-req-jobsCreatePageCon">
        {/* <div className="row">
                    <div className="col-md-5">
                        <h2 className="dk-todayadsMainPTitle w-100 text-center">
                            SELECT PATH
                        </h2>
                    </div>
                    <div className="col-md-7">
                        <div className="dk-jobCreateRightHead">
                            <h2 className="dk-todayadsMainPTitle w-100 text-center">
                                JOB DETAILS
                            </h2>
                        </div>
                    </div>
                </div> */}
        <div className="dkcreate-FromSection">
          <div className="row mt-4">
            <div className="col-md-7 col-sm-12 m-auto">
              <div className="dk-leftTopformCon">
                <form>
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="dk-formInputCon dk-jobtitleCon">
                        <label>
                          Job Spec ID<span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control text-left"
                          value={jobid}
                          onChange={(e) => setJobid(e.target.value)}
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-12">
                      <div className="dk-formInputCon dk-jobtitleCon">
                        <label>
                          Job Spec Title<span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control text-left"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <div className="dk-formInputCon">
                        <label>
                          Company<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          {/* <SearchDropDownFilter
                            categoryList={jobsCompanyList}
                            catList={getCompanyValue}
                            isRefresh={isRefresh}
                          /> */}
                          <CompanyDropdown
                            categoryList={companydata}
                            catList={getCompanyValue}
                            isRefresh={isRefresh}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12">
                      <div className="dk-formInputCon">
                        <label>
                          Category<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsCategoryList}
                            catList={getCatValue}
                            isRefresh={isRefresh}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12">
                      <div className="dk-formInputCon">
                        <label>
                          Project<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsProjectList}
                            catList={getProjectValue}
                            isRefresh={isRefresh}
                          />
                          {/* <CompanyDropdown
                            categoryList={companyProjtList}
                            catList={getProjectValue}
                            isRefresh={isRefresh}
                          /> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12">
                      <div className="dk-formInputCon">
                        <label>
                          Role Type<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsRoleTypeList}
                            catList={getRoleTypetValue}
                            isRefresh={isRefresh}
                          />
                          {/* <CompanyDropdown
                            categoryList={companyRolList}
                            catList={getRoleTypetValue}
                            isRefresh={isRefresh}
                          /> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12">
                      <div className="dk-formInputCon">
                        <label>
                          Language<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <SearchDropDownFilter
                            categoryList={jobsLanguageList}
                            catList={getLanguagetValue}
                            isRefresh={isRefresh}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-sm-12">
                      <div className="dk-formInputCon">
                        <label>
                          Status<span>*</span>
                        </label>
                        <div className="dk-createjobsDrpoDwn">
                          <StatusDropDown
                            categoryList={statusList}
                            catList={getStatusValue}
                            isRefresh={isRefresh}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="dk-saveresetbtnCon">
                <button className="btn dk-resetjobBtn">RESET FORM</button>
                <button
                  type="submit"
                  loading={loading}
                  success={success}
                  onClick={handleButtonClick}
                  className="btn dk-createjobBtn"
                >
                  CREATE JOB SPEC
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNew;
