import React, { useState } from 'react';
import './addnewrow.scss';
import { FaCheck, FaEdit, FaRegTrashAlt, FaTimes } from 'react-icons/fa';
import EditRowModal from './EditRowModal';

const AddEditModal = () => {
    const [isShowEditModal, setShowEditModal] = useState(false)
    const showEditModal = () => {
        setShowEditModal(true)
    }

    const hideEditModal = () => {
        setShowEditModal(false)
    }
    return (
        <>
            {
                (isShowEditModal) ? <EditRowModal onHide={hideEditModal} /> : null
            }
            <div className="dk-addEditMain-modal">
                <div className="d-flex">
                    <div className="dk-addEdit-no">1</div>
                    <div className="dk-addEdit-cols">
                        <span><FaCheck /></span>
                        Check All
                    </div>
                    <div className="dk-addEdit-cols" onClick={showEditModal}>
                        <span><FaEdit /></span>
                        Edit
                    </div>
                    <div className="dk-addEdit-cols">
                        <span><FaRegTrashAlt /></span>
                        Delete
                    </div>
                    <div className="dk-addEdit-close">
                        <button><FaTimes /></button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddEditModal;
