import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetData, PostData, PutData,DeleteData } from "../../services";

export const summaryFunction = createAsyncThunk(
    'salesbooks/summary-list',
    async (thunkAPI) => {
        const res = await PostData(true, '/accounting/interviews/summary-list')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getList = createAsyncThunk(
    'salesbooks/list',
    async ({year,type},thunkAPI) => {
        const res = await GetData(true, '/accounting/interviews/'+year+'/'+type)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const getPaidPaymentList = createAsyncThunk(
    'salesbooks/paid/list',
    async (thunkAPI) => {
        const res = await GetData(true, '/accounting/interviews/paid')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const archivedData = createAsyncThunk(
    'salesbooks/list/archive',
    async (thunkAPI) => {
        const res = await GetData(true, '/accounting/interviews/archived')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const addNewTask = createAsyncThunk(
    'salesbooks/add-new',
    async ({ caseId,type, jobId, year, month, accountManager, recruiters, company,language, candidateName, caseStatus, caseStage,progress, invoiceNo, project, roleType, cvSource}, thunkAPI) => {
        const res = await PostData(true, '/accounting/interviews', { caseId,type, jobId, year, month, accountManager, recruiters, company,language, candidateName, caseStatus,caseStage, progress, invoiceNo, project, roleType, cvSource})
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
)

export const updateDetails = createAsyncThunk(
    'salesbooks/update',
    async ({ id, caseId, jobId, year, month, accountManager, recruiters, company, language, candidateName, caseStatus, caseStage, progress, invoiceNo, project, roleType, cvSource }, thunkAPI) => {
        const res = await PutData(true, '/accounting/interviews/' + id, { caseId, jobId, year, month, accountManager, recruiters, company, language, candidateName, caseStatus, caseStage, progress, invoiceNo, project, roleType, cvSource })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updatePaidTask = createAsyncThunk(
    'salesbooks/update/paid',
    async ({ id, taskBy,taskFor,status,priority,invoiceNo,paymentType,paymentTo,paymentAmount,paymentNotes,dueTime,dueDate,paymentMonth, payType, attachment }, thunkAPI) => {
        const res = await PutData(true, '/accounting/interviews/' + id, { taskBy,taskFor,status,priority,invoiceNo,paymentType,paymentTo,paymentAmount,paymentNotes,dueTime,dueDate,paymentMonth, payType, attachment })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateByKey = createAsyncThunk(
    'salesbooks/update/key-value',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/accounting/interviews/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateProgress = createAsyncThunk(
    'salesbooks/update/key-value/progress',
    async ({ id, key, value }, thunkAPI) => {
        const res = await PutData(true, '/accounting/interviews/update/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const countAllTask = createAsyncThunk(
    'salesbooks/count/all',
    async ({ status }, thunkAPI) => {
        const res = await GetData(true, '/accounting/interviews/count-all')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const deleteTask = createAsyncThunk(
    'salesbooks/count/delete',
    async ({ id }, thunkAPI) => {
        const res = await DeleteData(true, '/accounting/interviews/'+id )
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskMoveTO = createAsyncThunk(
    'salesbooks/count/task-move',
    async ({ id }, thunkAPI) => {
        const res = await PostData(true, '/accounting/interviews/bulk-move', { id })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const moveRecordsToPaid = createAsyncThunk(
    'salesbooks/count/salesbooks/move-to-table',
    async ({ id, status }, thunkAPI) => {
        const res = await PostData(true, '/accounting/interviews/move-to-table', { id, status })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const counts = createAsyncThunk(
    'salesbooks/count/by-progress',
    async ({ status }, thunkAPI) => {
        const res = await GetData(true, '/accounting/interviews/count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const paymentCounts = createAsyncThunk(
    'salesbooks/count/payment',
    async (thunkAPI) => {
        const res = await GetData(true, '/accounting/interviews/payment-count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const paidPaymentCounts = createAsyncThunk(
    'salesbooks/count/paidpayment',
    async (thunkAPI) => {
        const res = await GetData(true, '/accounting/interviews/paid-payment-count')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const updateSalesBookCommentCount = createAsyncThunk(
    'salesbooks/commentlist/update-comment',
    async ({ id, value, commentAt }, thunkAPI) => {
        const res = await PutData(true, '/accounting/interviews/update-comment/' + id, { value, commentAt })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskTypeFetch = createAsyncThunk(
    'salesbooks/type/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskPayMonthFetch = createAsyncThunk(
    'salesbooks/payMonth/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);


export const taskPayTypeFetch = createAsyncThunk(
    'salesbooks/payType/all',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/admin/backend-value/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskSummaryList = createAsyncThunk(
    'salesbooks/pipeline/summary-list',
    async (thunkAPI) => {
        const res = await GetData(true, '/accounting/interviews/summary')
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const taskDetails = createAsyncThunk(
    'salesbooks/task/details',
    async ({ id }, thunkAPI) => {
        const res = await GetData(true, '/accounting/interviews/detail/' + id)
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);



const listSlice = createSlice({
    name: 'salesbookslist',
    initialState: {
        salesBookList: [],
        businessSummary: [],
        paidsalesBookList: [],
        taskDataSummaryList: [],
        taskTypeList: [],
        taskPaymentMonthList: [],
        taskPayTypeList: [],
        countsProgress: [],
        paymentDataCount: [],
        paidPaymentDataCount: [],        
        tasksDetails: {},
        countAll: 0,
        isLoading: false,
        isDataLoading: false,
        isDetailLoading: false,
        isTaskInsert: false,
        isTaskUpdate: false,
        isTaskDelete: false,
        isUpdate: false,
        iscommentCountUpdate: false,
        isError: false,
        isSuccess: false,
        isDataMoveToPayment: false,
        isTaskInserted: false,
        isMoved: false,
        isArchive: false,
        message: ''
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isDataLoading = false;
            state.isLoading = false;
            state.isDetailLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.issSuccess = false;
            state.isDataMoveToPayment = false;
            state.isTaskInserted = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },
        clearTaskState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isTaskInsert = false;
            state.isTaskUpdate = false;
            state.isTaskDelete = false;
            state.isUpdate = false;
            state.iscommentCountUpdate = false;
            state.isSuccess = false;
            state.isTaskInserted = false;
            state.isMoved = false;
            state.isArchive = false;
            state.message = '';
        },
        clearDetails: (state) => {
            state.tasksDetails = {}
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            getPaidPaymentList.pending, (state) => {
                state.isLoading = true;
                state.isUpdate = false;
                state.isDataMoveToPayment = false;
            });
        builder.addCase(
            getPaidPaymentList.fulfilled, (state, { payload }) => {
                state.paidsalesBookList = payload.result
                state.isLoading = false;
                state.isUpdate = true;
                state.isDataMoveToPayment = true;
            });
            
        builder.addCase(
            getPaidPaymentList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });
       
        builder.addCase(
            summaryFunction.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            summaryFunction.fulfilled, (state, { payload }) => {
                state.businessSummary = payload.result
                state.isLoading = false;
            });
        builder.addCase(
            summaryFunction.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });


        builder.addCase(
            getList.pending, (state) => {
                state.isLoading = true;
                state.issSuccess = false;
            });
        builder.addCase(
            getList.fulfilled, (state, { payload }) => {
                state.salesBookList = payload.result
                state.isLoading = false;
                state.issSuccess = true;
            });
        builder.addCase(
            getList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            archivedData.pending, (state) => {
                state.isLoading = true;
                state.isArchive = false;
            });
        builder.addCase(
            archivedData.fulfilled, (state, { payload }) => {
                state.salesBookList = payload.result
                state.isArchive = true;
                state.isLoading = false;
            });
        builder.addCase(
            archivedData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            addNewTask.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            addNewTask.fulfilled, (state, { payload }) => {
               // state.salesBookList.unshift(payload.result)
             //  state.salesBookList=payload.result;
                state.isLoading = false;
                state.isSuccess = true;
                state.isTaskInserted = true;
            });
        builder.addCase(
            addNewTask.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updatePaidTask.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            updatePaidTask.fulfilled, (state, { payload }) => {
                let mainArray = state.paidsalesBookList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result[0].id) {
                        return (payload.result[0])
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.isLoading = false;
                state.paidsalesBookList = finalResult;
            });
        builder.addCase(
            updatePaidTask.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            updateDetails.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            updateDetails.fulfilled, (state, { payload }) => {
                let mainArray = state.salesBookList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result[0].id) {
                        return (payload.result[0])
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.isLoading = false;
                state.salesBookList = finalResult;
            });
        builder.addCase(
            updateDetails.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            updateByKey.pending, (state) => {
                state.isUpdate = false
                state.isLoading = false;
            });
        builder.addCase(
            updateByKey.fulfilled, (state, { payload }) => {
                let mainArray = state.salesBookList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === payload.result.id) {
                        return (payload.result)
                    } else {
                        return r
                    }
                })
                state.isUpdate = true
                state.salesBookList = finalResult;
                state.message = payload.message
            });
        builder.addCase(
            updateByKey.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateProgress.pending, (state) => {
                state.isLoading = false;
            });
        builder.addCase(
            updateProgress.fulfilled, (state, action) => {
                state.isUpdate = true
                state.salesBookList = state.salesBookList.filter((item) => item.id !== action.meta.arg.id)
            });
        builder.addCase(
            updateProgress.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            countAllTask.pending, (state) => {
            });
        builder.addCase(
            countAllTask.fulfilled, (state, { payload }) => {
                state.countAll = payload.result
            });
        builder.addCase(
            countAllTask.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            counts.pending, (state) => {
            });
        builder.addCase(
            counts.fulfilled, (state, { payload }) => {
                state.countsProgress = payload.result
            });
        builder.addCase(
            counts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            paidPaymentCounts.pending, (state) => {

            });
        builder.addCase(
            paidPaymentCounts.fulfilled, (state, { payload }) => {
                state.paidPaymentDataCount = payload.result
                //state.isArchive = true;
            });
        builder.addCase(
            paidPaymentCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            paymentCounts.pending, (state) => {

            });
        builder.addCase(
            paymentCounts.fulfilled, (state, { payload }) => {
                state.paymentDataCount = payload.result
                //state.isArchive = true;
            });
        builder.addCase(
            paymentCounts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        

        builder.addCase(
            deleteTask.pending, (state) => {
            });
        builder.addCase(
            deleteTask.fulfilled, (state, { payload }) => {
                state.isTaskDelete = true;
                state.isMoved = true;
            });
        builder.addCase(
            deleteTask.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            taskMoveTO.pending, (state) => {
            });
        builder.addCase(
            taskMoveTO.fulfilled, (state, { payload }) => {
                state.isTaskDelete = true;
                state.message = payload.message
                state.isMoved = true;
            });
        builder.addCase(
            taskMoveTO.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });
        builder.addCase(
            moveRecordsToPaid.pending, (state) => {
            });
        builder.addCase(
            moveRecordsToPaid.fulfilled, (state, { payload }) => {
                state.isMoved = true;
                state.message = payload.message
            });
        builder.addCase(
            moveRecordsToPaid.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            updateSalesBookCommentCount.pending, (state) => {
                state.iscommentCountUpdate = false
            });
        builder.addCase(
            updateSalesBookCommentCount.fulfilled, (state, action) => {
                let mainArray = state.salesBookList;
                let finalResult = mainArray.map((r) => {
                    if (r.id === action.meta.arg.id) {
                        return ({ ...r, commentCount: action.meta.arg.value, commentAt: action.meta.arg.commentAt })
                    } else {
                        return r
                    }
                })
                state.salesBookList = finalResult

                let mainArrayPaid = state.paidsalesBookList;
                let finalResultPaid = mainArrayPaid.map((r) => {
                    if (r.id === action.meta.arg.id) {
                        return ({ ...r, commentCount: action.meta.arg.value, commentAt: action.meta.arg.commentAt })
                    } else {
                        return r
                    }
                })
                state.paidsalesBookList = finalResultPaid
                state.iscommentCountUpdate = true
            });
        builder.addCase(
            updateSalesBookCommentCount.rejected, (state, action) => {
                state.isError = true;
            });

        builder.addCase(
            taskTypeFetch.pending, (state) => {
            });
        builder.addCase(
            taskTypeFetch.fulfilled, (state, { payload }) => {
                state.taskTypeList = payload.result
            });
        builder.addCase(
            taskTypeFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            taskPayMonthFetch.pending, (state) => {
            });
        builder.addCase(
            taskPayMonthFetch.fulfilled, (state, { payload }) => {
                state.taskPaymentMonthList = payload.result
            });
        builder.addCase(
            taskPayMonthFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            taskPayTypeFetch.pending, (state) => {
            });
        builder.addCase(
            taskPayTypeFetch.fulfilled, (state, { payload }) => {
                state.taskPayTypeList = payload.result
            });
        builder.addCase(
            taskPayTypeFetch.rejected, (state, action) => {
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            taskSummaryList.pending, (state) => {
                state.isLoading = true;
            });
        builder.addCase(
            taskSummaryList.fulfilled, (state, { payload }) => {
                state.taskDataSummaryList = payload.result;
                state.isLoading = false;
            });
        builder.addCase(
            taskSummaryList.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

        builder.addCase(
            taskDetails.pending, (state) => {
                state.isDetailLoading = true
            });
        builder.addCase(
            taskDetails.fulfilled, (state, { payload }) => {
                state.isDetailLoading = false
                state.tasksDetails = payload.result;
            });
        builder.addCase(
            taskDetails.rejected, (state, action) => {
                state.isDetailLoading = false;
                state.isError = true;
                state.message = 'Something went wrong';
            });

      
    }
});


export const { clearState, clearTaskState, clearDetails } = listSlice.actions;
export default listSlice.reducer;