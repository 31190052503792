import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import './addnewcategory.scss'

const AddMainCategory = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Link to="#" onClick={handleShow}><i className="fas fa-plus"></i></Link>
            <Modal show={show} onHide={handleClose} className='dk-addnewCategoryMain' centered>
                <Modal.Header closeButton className='dk-addnewCategoryHeader'>
                    <Modal.Title>Add Main Category</Modal.Title>
                </Modal.Header>
                <Modal.Body className='dk-addnewCategoryBody pl-0 pr-0'>
                    <form action="">
                        <div className="form-group">
                            <label for="">Name of category</label>
                            <label for="">
                                <input type="text" name="" id="" className="form-control" value="" />
                            </label>
                        </div>
                        <div className="form-footer mb-0">
                            <button className="closeBtn mr-2">Close</button>
                            <button className="dk-submit-btn">Submit</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddMainCategory