import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const DatePicker = ({ selectedDate, handleDateChange }) => {

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container >
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format="dd-MM-yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        disableFuture={true}
                    />
                </Grid>
            </MuiPickersUtilsProvider>

        </>
    )
}

export default DatePicker;