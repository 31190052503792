import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { FaSignOutAlt, FaCalculator } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
const AMPM = ({ currentdate, lunchStartFrom, lunchEnd, userid, id, deleteFunction,dayoff,dayOffChecking }) => {
  const { userInfo } = useSelector(state => state.auth);

  const todayDate = moment(new Date()).format('DD/MM/YYYY')
 const stringFromArr = dayoff[0];

  return (
    <React.Fragment>
       <td className={stringFromArr !="Day Off" ? '': 'dayOffClass'} >
      {
      stringFromArr !="Day Off" ? <>
      <div className='dkg-lunch-start-time'>
        {
          lunchStartFrom != '' ? <>Start : {moment(lunchStartFrom, ["HH:mm"]).format("hh:mm A")}
            {userInfo.id == userid ? <>{todayDate == currentdate ? <span className='dkg-cross-icon' onClick={(e) => deleteFunction(id)}><FaTimesCircle /></span> : null}
            </> : null
            }
          </>
            : null
        }
      </div>
      <div className='dkg-lunch-end-time'>
        {
          lunchEnd != '' ? <>End &nbsp;: {moment(lunchEnd, ["HH:mm"]).format("hh:mm A")}
            {userInfo.id == userid ? <>{todayDate == currentdate ? <span className='dkg-cross-icon' onClick={(e) => deleteFunction(id)}><FaTimesCircle /></span> : null}
            </> : null
            }
          </>
            : null
        }
      </div>
      </>
      :
       <>
          {stringFromArr } <span className='dkg-cross-icon' onClick={(e)=>dayOffChecking(id)}><FaTimesCircle /></span>
        </>
      }        
  </td>
    </React.Fragment>
  )
}
export default AMPM;