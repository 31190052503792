import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetData, PostData, PutData, DeleteData } from "../../services";

export const getdata = createAsyncThunk(
  "attendance/list",
  async (thunkAPI) => {
    const res = await GetData(true, "/user/attendances/");
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const filterBydate = createAsyncThunk(
  "attendance/filterlist",
  async ({checkinDate},thunkAPI) => {
    const res = await PostData(true, "/user/attendances/filter",{checkinDate});
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const leaveAllDay = createAsyncThunk(
  "attendance/leaveadd",
  async ({uid,checkinDate},thunkAPI) => {
    const res = await PostData(true, "/user/attendances/dayoff",{uid,checkinDate});
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const checkintime = createAsyncThunk(
  "attendance/add",
  async ({uid,checkinDate,checkinTime},thunkAPI) => {
    const res = await PostData(true, "/user/attendances",{uid,checkinDate,checkinTime});
    if (res.status === 200) {
      return res.payload;
    } else {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateLunchTime = createAsyncThunk(
    'attendance/updateLunchkey',
    async ({ id,uid,checkinDate, key, value,startTime,endTime }, thunkAPI) => {
        const res = await PutData(true, '/user/attendances/update/' + id, { uid,checkinDate,key, value,startTime,endTime })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const checkoutUser = createAsyncThunk(
    'attendance/updateCheckoutkey',
    async ({ id, uid,checkinDate,key, value }, thunkAPI) => {
        const res = await PutData(true, '/user/attendances/update/' + id, { uid,checkinDate, key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);
export const updatekey = createAsyncThunk(
    'attendance/keyval',
    async ({ id,key, value }, thunkAPI) => {
        const res = await PutData(true, '/user/attendances/' + id, { key, value })
        if (res.status === 200) {
            return res.payload
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const usersSlice = createSlice({
 name: "attendance",
  initialState: {
    attendanceList: [],
    isSuccess: false,
    isUpdate: false,
    isLoading: false,
    isInsert: false,
    isError: false,
    msg:''
  },
  reducers: {
    clearState: (state) => {
      state.isLoading = false;
      state.isInsert = false;
      state.isSuccess = false;
      state.isError = false;
      state.isUpdate = false;
      state.msg = '';
    },
  },
  extraReducers: {
    [filterBydate.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [filterBydate.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.attendanceList = payload.result;
    },
    [filterBydate.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
    },
    [getdata.pending]: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    [getdata.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.attendanceList = payload.result;
    },
    [getdata.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isLoading = false;
    },
    [leaveAllDay.pending]: (state) => {
      state.isLoading = true;
      state.isInsert = false;
      state.isError = false;
    },
    [leaveAllDay.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isInsert = payload.insert;
      state.isError = payload.error;
      state.attendanceList = payload.result;
      state.msg = payload.message;
    },
    [leaveAllDay.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isInsert = false;
      state.isLoading = false;
      state.msg = payload.message;
    },
    [checkintime.pending]: (state) => {
      state.isLoading = true;
      state.isInsert = false;
      state.isError = false;
    },
    [checkintime.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isInsert = payload.insert;
      state.isError = payload.error;
      state.attendanceList = payload.result;
      state.msg = payload.message;
    },
    [checkintime.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isInsert = false;
      state.isLoading = false;
      state.msg = payload.message;
    },

    [updateLunchTime.pending]: (state) => {
      state.isUpdate = false
    },
    [updateLunchTime.fulfilled]: (state, { payload }) => {
      let mainArray = state.attendanceList;

      if(payload.error==false){
        let finalResult = mainArray.map((r) => {
            if (r.id === payload.result.id) {
                return (payload.result)
            } else {
                return r
            }
        })
        state.isUpdate = payload.update
        state.attendanceList = finalResult;
      }
        state.isError = payload.error
        state.msg = payload.message
    },
    [updateLunchTime.rejected]: (state, { payload }) => {
        state.isError = true;
        state.msg = 'Something went wrong';
    },
    [checkoutUser.pending]: (state) => {
      state.isUpdate = false
    },
    [checkoutUser.fulfilled]: (state, { payload }) => {
      let mainArray = state.attendanceList;
        let finalResult = mainArray.map((r) => {
            if (r.id === payload.result.id) {
                return (payload.result)
            } else {
                return r
            }
        })
        state.isUpdate = true
        state.attendanceList = finalResult;
        state.message = payload.message
    },
    [checkoutUser.rejected]: (state, { payload }) => {
        state.isError = true;
        state.message = 'Something went wrong';
    },
    [updatekey.pending]: (state) => {
      state.isUpdate = false
    },
    [updatekey.fulfilled]: (state, { payload }) => {
      let mainArray = state.attendanceList;
        let finalResult = mainArray.map((r) => {
            if (r.id === payload.result.id) {
                return (payload.result)
            } else {
                return r
            }
        })
        state.isUpdate = true
        state.attendanceList = finalResult;
        state.message = payload.message
    },
    [updatekey.rejected]: (state, { payload }) => {
        state.isError = true;
        state.message = 'Something went wrong';
    },

  
  },
});

export const { clearState } = usersSlice.actions;
export default usersSlice.reducer;
