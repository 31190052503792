import React from 'react'

const Myfiles = () => {
  return (
    <>
       <div className='dkg-myacc-fileMainCon' style={{marginTop: "160px"}}>
        My Fles
       </div>
    </>
  )
}

export default Myfiles