import React from 'react';
import { Link } from 'react-router-dom'
import './adssummary.scss'

const SavedAds = () => {
    return (
        <>
            <div className="container-fluid dk-adsSummPageCon">
                <div className="row">
                    <h2 className="dk-adspageTitle w-100 text-center">Saved Ads</h2>
                </div>
            </div>
        </>
    )
}

export default SavedAds;
