import React, { useState } from "react";
import $ from "jquery";

const DuplicateCheck = ({ id, duplicateCheckActiveExtraDiv, duplicateCheckExtraDivBtnTxt }) => {

    const toggleCheckbox = () => {
        var checkedBoxCount = $('input[name="chkDuplicateCandidate[]"]:checked').length;
        if (checkedBoxCount > 0) {
            duplicateCheckActiveExtraDiv(true, checkedBoxCount)
        } else {
            duplicateCheckActiveExtraDiv(false, 0)
        }
        var checkedBoxCount = $('input[name="chkDuplicateCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkDuplicateCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            duplicateCheckExtraDivBtnTxt('Check All')
        }
        if (checkedBoxCount == totalCheckboxes) {
            duplicateCheckExtraDivBtnTxt('Uncheck All')
        }
    }

    return <input type="checkbox" className="chkDuplicateCandidate" name="chkDuplicateCandidate[]" value={id} data-id={id} onChange={toggleCheckbox} />;
};

export default DuplicateCheck;