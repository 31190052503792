import React from 'react';
import AccInformationSubmenu from "../../../elements/header/navbar/subMenu/menu/accountingmodule/AccInformationSubmenu";

const Information = () => {
    return (
        <>
            <AccInformationSubmenu />
            <div className='container-fluid' style={{ marginTop: "125px" }}>

            </div>
        </>
    )
}

export default Information;
