import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import './addnew.scss';
import { useDispatch, useSelector } from "react-redux";
import SelectInstance from '../../../../ui/SelectPicker/SelectInstance';
import Submit from '../../../../ui/submitButton';
import { showSuccess, showError } from "../../../../../slice/utils/message/messageSlice";
import { addQualify, clearState } from "../../../../../slice/qualify/qualifySlice";

const AddNew = ({ onHide }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const { dataList, qualifyCategoryList, frequencyDataList, isDataLoading, isLoading, isInsert, isUpdate, isError, isDelete, errorMessage } = useSelector(state => state.qualify)
    const [qualifyCategory, setQualifyCategory] = useState('');
    const [frequency, setFrequency] = useState('');
    const [questions, setQuestions] = useState('');
    const [answer, setAnswer] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleChangeSelect = (event, data) => {
        const { name, value } = data
        switch (name) {
            case "qualifyCategory":
                setQualifyCategory(value)
                break;
            case "frequency":
                setFrequency(value)
                break;

        }
    }

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "questions":
                setQuestions(value)
                break;
            case "answer":
                setAnswer(value)
                break;
            default:
                break;
        }
    }

    const handleButtonClick = async (e) => {
        e.preventDefault()
        if (!qualifyCategory || !frequency || !questions || !answer) {
            dispatch(showError({ msg: 'Please fill all fields.' }))
        } else {
            if (!loading) {
                setSuccess(false);
                setLoading(true);
                await dispatch(addQualify({ companyId: process.env.REACT_APP_DEFAULT_COMPANY_ID, categoryId: qualifyCategory, qualifyCategory, frequency, question: questions, answer }))
            }
        }
    }

    useEffect(() => {
        if (isInsert) {
            dispatch(showSuccess({ msg: 'Qualify added successfully.' }))
            dispatch(clearState())
            setQualifyCategory('');
            setFrequency('');
            setQuestions('');
            setAnswer('');
            onHide()
            setSuccess(true);
            setLoading(false);
        }
        if (isError) {
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState())
            setSuccess(false);
            setLoading(false);
        }
    }, [isInsert, isError])

    return (
        <>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dk-qualifyPopup"
                aria-labelledby="example-custom-modal-styling-title"
                centered  >
                <Modal.Header closeButton className="dk-qualifyAddnewheader">
                    <Modal.Title className="dkqualifyaddtitle">Add New</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-qualifyAddNewBody">
                    <form>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Category</label>
                                    <div className="dk-qualifyDrpDwn">
                                        <SelectInstance options={qualifyCategoryList} name="qualifyCategory" value={qualifyCategory} onChange={handleChangeSelect} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Frequency</label>
                                    <div className="dk-qualifyDrpDwn">
                                        <SelectInstance options={frequencyDataList} name="frequency" value={frequency} onChange={handleChangeSelect} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="">Questions</label>
                                    <input type="text" id="questions" className="form-control" name="questions" value={questions} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="">Answer</label>
                                    <input type="text" id="answer" className="form-control" name="answer" value={answer} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="dk-adstrackbtnCont">
                                    <Submit txt="ADD" loading={loading} success={success} onClick={handleButtonClick} className="dk-adstrackaddbtn" />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddNew;
