import React, { useState } from "react"
import Dropdown from 'react-bootstrap/Dropdown';
const ProfileIndicatorOptions = ({ id, columnId, updateMyData }) => {
    const selectValue = (value, color) => {
        // const setValue = e.target.dataset.value;
        updateMyData(id, columnId, value, color);
    }

    return (
        <React.Fragment>
            <Dropdown.Item href="#" data-value="2" data-column="profileIndicator" onClick={() => selectValue(2, 'green')}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: "green" }}></i>
                <span className="dkg-prof-ind-counter">Updated</span>
            </Dropdown.Item>
            <Dropdown.Item href="#" data-value="1" data-column="profileIndicator" onClick={() => selectValue(1, '#d3d3d3')}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: "#d3d3d3" }}></i>
                <span className="dkg-prof-ind-counter">Not Updated</span>
            </Dropdown.Item>
        </React.Fragment>
    )
}
export default ProfileIndicatorOptions;