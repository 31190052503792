import React from "react";
import { Link } from "react-router-dom";
import './myacc-submenu.scss';

const SettingsSubmenu = () => {
    const pathname = window.location.pathname;
    const segment3 = pathname.split("/")[3];

    return (
        <React.Fragment>
            {/* <div className="dkg-myaccsubMenu-Main">
                <div className="dk-myaccsubMenuMain">
                    <Route>
                        <ul>
                            <li><Link to="/js-account/settings/faq" className={`${(segment3 == 'faq') ? 'active' : ''}`}>FAQ</Link></li>
                            <li><Link to="/js-account/settings/privacy" className={`${(segment3 == 'privacy') ? 'active' : ''}`}>Privacy</Link></li>
                            <li><Link to="/js-account/settings/gdpr-explained" className={`${(segment3 == 'gdpr-explained') ? 'active' : ''}`}>GDPR</Link></li>
                            <li><Link to="#/js-account/settings/cookies" className={`${(segment3 == 'cookies') ? 'active' : ''}`}>Cookies</Link></li>
                            <li><Link to="/js-account/settings/terms-of-use" className={`${(segment3 == 'terms-of-use') ? 'active' : ''}`}>Terms of Use</Link></li>
                            <li><Link to="/js-account/settings/notifications" className={(segment3 == 'notifications') ? 'active' : ''}>Notifications</Link></li>
                            <li><Link to="/js-account/settings/change-logins" className={`${(segment3 == 'change-logins') ? 'active' : ''}`}>Reset Logins</Link></li>
                            <li><Link to="/js-account/settings/delete-account" className={`${(segment3 == 'delete-account') ? 'active' : ''}`}>Delete A/C</Link></li>
                            <li><Link to="/js-account/settings/feedback" className={`${(segment3 == 'feedback') ? 'active' : ''}`}>Feedback</Link></li>
                            <li><Link to="/js-account/settings/contact-us" className={`${(segment3 == 'contact-us') ? 'active' : ''}`}>About us</Link></li>
                        </ul>
                    </Route>
                </div>
            </div> */}
            <ul className="dkg-myacc-submenu-ul">
                <li>
                    <Link to="/js-account/settings/faq" className={`${(segment3 === 'faq') ? 'grey-active' : ''}`}>FAQ</Link>
                </li>

                <li>
                    <Link to="/js-account/settings/privacy" className={`${(segment3 === 'privacy') ? 'grey-active' : ''}`}>Privacy</Link>
                </li>
                <li>
                    <Link to="/js-account/settings/gdpr-explained" className={`${(segment3 === 'gdpr-explained') ? 'grey-active' : ''}`}>GDPR</Link>
                </li>
                <li className="psp">
                    <Link to="/js-account/settings/terms-of-use" className={`${(segment3 === 'terms-of-use') ? 'grey-active' : ''}`}>Terms of Use</Link>
                </li>
                <li className="pbp">
                    <Link to="/js-account/settings/cookies" className={`${(segment3 === 'cookies') ? 'grey-active' : ''}`}>Cookies</Link>
                </li>
                <li>
                    <Link to="/js-account/settings/notifications" className={`${(segment3 === 'notifications') ? 'grey-active' : ''}`}>Notifications</Link>
                </li>
                <li>
                    <Link to="/js-account/settings/feedback" className={`${(segment3 === 'feedback') ? 'grey-active' : ''}`}>Give Feedback</Link>
                </li>
                <li>
                    <Link to="/js-account/settings/change-logins" className={`${(segment3 === 'change-logins') ? 'grey-active' : ''}`}>Reset Logins</Link>
                </li>
                <li>
                    <Link to="/js-account/settings/delete-account" className={`${(segment3 === 'delete-account') ? 'grey-active' : ''}`}>Delete A/C</Link>
                </li>

                <li>
                    <Link to="/js-account/settings/contact-us" className={`${(segment3 === 'contact-us') ? 'grey-active' : ''}`}>About us</Link>
                </li>
            </ul>
        </React.Fragment>
    );
}

export default SettingsSubmenu;
