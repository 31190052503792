import React, { useState } from 'react';
import { Link } from "react-router-dom";
import '../jobspecs/kpijobspecs.scss';
import TasksLeftPanel from '../../kpitasks/leftpanel/TasksLeftPanel';
import Dropdown from 'react-bootstrap/Dropdown';
import UpdatePop from "../sendouts/UpdatePop";

const KpiSalesRefused = () => {
    const [isUpdatePop, setIsUpdatePop] = useState(false)

    const openUpdateValuePop = () => {
        setIsUpdatePop(true)
    }
    const hideUpdateValuePop = () => {
        setIsUpdatePop(false)
    }
    return (
        <>
            {
                (isUpdatePop) ? <UpdatePop onHide={hideUpdateValuePop} /> : null
            }
            <div className="dk-sendouts-containerMain dk-jobSpecsContainer-main">
                <TasksLeftPanel />

                <div className="dk-sendouts-rightPanel dk-refusedBg">
                    <div className="dk-sendoutsHead">
                        <div className="dk-sendouts-yearsDropdown">
                            <div className="d-flex align-items-center">
                                <Dropdown>
                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                        2022
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#">2021</Dropdown.Item>
                                        <Dropdown.Item href="#">2020</Dropdown.Item>
                                        <Dropdown.Item href="#">2019</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Link className="addMonthBtn" to="#" style={{ marginRight: '0' }}><i className="fas fa-plus"></i></Link>
                            </div>
                        </div>
                        <div className="dk-sendouts-months">
                            <ul>
                                <li><Link to="#">Jan</Link></li>
                                <li><Link to="#">Feb</Link></li>
                                <li><Link to="#">Mar</Link></li>
                                <li><Link to="#">Apr</Link></li>
                                <li><Link to="#">May</Link></li>
                                <li><Link to="#">Jun</Link></li>
                                <li><Link to="#" className="active">Jul</Link></li>
                                <li><Link to="#">Aug</Link></li>
                                <li><Link to="#">Sep</Link></li>
                                <li><Link to="#">Oct</Link></li>
                                <li><Link to="#">Nov</Link></li>
                                <li><Link to="#">Dec</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="dk-salesSendouts-table dk-kpijobsSummaryTable">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Recruiter</th>
                                    <th>Client Group</th>
                                    <th>Timeline</th>
                                    <th>Sendout Target</th>
                                    <th>Sendout Made</th>
                                    <th>Sendout Remained</th>
                                    <th>%</th>
                                    <th>Updated On</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            1
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td className="cursor-p" onClick={openUpdateValuePop}>Group B</td>
                                    <td className="cursor-p" onClick={openUpdateValuePop}>
                                        1 Month
                                    </td>
                                    <td className="cursor-p" onClick={openUpdateValuePop}>80</td>
                                    <td className="cursor-p text-center" onClick={openUpdateValuePop}>20</td>
                                    <td className="text-danger">-60</td>
                                    <td className="text-danger"><i className="fas fa-arrow-down mr-1"></i> -75</td>
                                    <td>12 Aug, 7:36</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            2
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td className="cursor-p" onClick={openUpdateValuePop}>Group A</td>
                                    <td className="cursor-p" onClick={openUpdateValuePop}>
                                        1 Month
                                    </td>
                                    <td className="cursor-p" onClick={openUpdateValuePop}>40</td>
                                    <td className="cursor-p text-center" onClick={openUpdateValuePop}>8</td>
                                    <td className="text-danger">-32</td>
                                    <td className="text-danger"><i className="fas fa-arrow-down mr-1"></i> -80</td>
                                    <td>12 Aug, 7:36</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            3
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/tehreen.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td className="cursor-p" onClick={openUpdateValuePop}>Group A</td>
                                    <td className="cursor-p" onClick={openUpdateValuePop}>
                                        1 Month
                                    </td>
                                    <td className="cursor-p" onClick={openUpdateValuePop}>35</td>
                                    <td className="cursor-p text-center" onClick={openUpdateValuePop}>5</td>
                                    <td className="text-danger">-30</td>
                                    <td className="text-danger"><i className="fas fa-arrow-down mr-1"></i> -86</td>
                                    <td>12 Aug, 7:36</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="dk-smsTableCheckbox">
                                            <input type="checkbox" className="dk-chkSendouts" name="" id="" />
                                            4
                                        </div>
                                    </td>
                                    <td>
                                        <div className="dk-salesSendouts-users">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" alt="" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className="d-flex">
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5c041fb96042e.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5cee3198c2bd9.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/5ad5c2940e458.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/elis.png" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item href="#">
                                                            <img src="https://d3eppau9to224z.cloudfront.net/users/images/1623316236.jpg" className="img-fluid" alt="" />
                                                        </Dropdown.Item>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                    <td className="cursor-p" onClick={openUpdateValuePop}>Group A</td>
                                    <td className="cursor-p" onClick={openUpdateValuePop}>
                                        1 Month
                                    </td>
                                    <td className="cursor-p" onClick={openUpdateValuePop}>5</td>
                                    <td className="cursor-p text-center" onClick={openUpdateValuePop}>2</td>
                                    <td className="text-danger">-3</td>
                                    <td className="text-danger"><i className="fas fa-arrow-down mr-1"></i> -60</td>
                                    <td>12 Aug, 7:36</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr className="sumtr">
                                    <td colSpan="4" className="transparent"></td>
                                    <td className="sum countGrossSale">160</td>
                                    <td className="sum countGrossSale">35</td>
                                    <td className="sum countGrossSale">125</td>
                                    <td className="sum countGrossSale">78%</td>
                                    <td className="transparent"></td>
                                </tr>
                            </tfoot>

                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KpiSalesRefused;
