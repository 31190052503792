import React, { useEffect, useState, useMemo, lazy, Suspense } from 'react';
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { ButtonGroup, Button } from 'react-bootstrap';
import { FaSearch, FaFilter } from 'react-icons/fa';
import "./modulesendoutscustom.scss";
import SearchSendout from "./modal/SearchSendout";
import AddModal from "./modal/AddJoined";
import AddEditModal from "./modal/AddEditModal";
/*******************************/
import MonthsFilterDropdown from './utils/monthsFilter';
import Checkbox from "./utils/checkbox/radioBtn";
import UpdateModal from './modal/EditJoined';
import CommentModal from '../../utils/comment/';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination, useAsyncDebounce } from 'react-table';
import Pagination from '@material-ui/lab/Pagination';
import { useDispatch, useSelector } from "react-redux";
import { clearState, getKpiReportsList, updateByKey, detailsById, deleteRecords } from "../../../slice/kpis/joinedKpiReportSlice"
import TableLoader from '../../ui/tableLoader';
import $ from "jquery";
import DeleteModal from "./utils/delete";
import { showSuccess } from '../../../slice/utils/message/messageSlice';
import DropdownMonths from './utils/months';
import ReportProgress from './utils/joinedProgress';
import StageDropdownValues from './utils/joinedStage';
import CandidateProfile from "../candidate/pipeline/modal/candidateprofile";
/*********************** */
import CustomDateFormat from "../../ui/dateFormats/CustomDateFormat";
import AccountManage from './utils/amFilter';
import Recruters from './utils/rcFilter';
import ProgressFilterDropdown from './utils/progressFilter';
const TableSuspence = lazy(() => import('../../ui/tableLoader/tableSuspence'));
const useInstance = (instance) => {
    if (instance && instance.getInstanceCallback) {
        instance.getInstanceCallback(instance);
    }
};

function Table({ columns, data, loading, getInstanceCallback, filter, amFilterId, monthFilterId, taskForFilterId, updateMyData, caseTypeId, casePayTypeId, progressStatusId, companyId, projectId, roleId, languageId, cvSourceId, caseStageId, caseProgressId, monthsId, sendoutOnId }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        page,
        prepareRow,
        state,
        setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            updateMyData,
            getInstanceCallback,
            autoResetPage: false,
            autoResetSortBy: false,
            autoResetFilters: false,
            autoResetRowState: false,
            initialState: {
                pageIndex: 0, pageSize: 100, hiddenColumns: ["am", "rc"]

            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy, usePagination, (hooks) => hooks.useInstance.push(useInstance)
    )

    /******************* */
    useEffect(() => {
        if (monthsId === '0') {
            setTimeout(() => {
                setFilter('months', '')
            }, 200)
        } else {
            setFilterType('months')
            doFilter(monthsId);
        }
    }, [monthsId])

    useEffect(() => {
        if (sendoutOnId === '0') {
            setTimeout(() => {
                setFilter('sendoutOn', '')
            }, 200)
        } else {
            setFilterType('sendoutOn')
            doFilter(sendoutOnId);
        }
    }, [sendoutOnId])

    useEffect(() => {
        if (caseProgressId === '0') {
            setTimeout(() => {
                setFilter('progress', '')
            }, 200)
        } else {
            setFilterType('progress')
            doFilter(caseProgressId);
        }
    }, [caseProgressId])

    useEffect(() => {
        if (companyId === '0') {
            setTimeout(() => {
                setFilter('company', '')
            }, 200)
        } else {
            setFilterType('company')
            doFilter(companyId);
        }
    }, [companyId])

    useEffect(() => {
        if (projectId === '0') {
            setTimeout(() => {
                setFilter('project', '')
            }, 200)
        } else {
            setFilterType('project')
            doFilter(projectId);
        }
    }, [projectId])

    useEffect(() => {
        if (roleId === '0') {
            setTimeout(() => {
                setFilter('roleType', '')
            }, 200)
        } else {
            setFilterType('roleType')
            doFilter(roleId);
        }
    }, [roleId])

    useEffect(() => {
        if (languageId === '0') {
            setTimeout(() => {
                setFilter('language', '')
            }, 200)
        } else {
            setFilterType('language')
            doFilter(languageId);
        }
    }, [languageId])

    useEffect(() => {
        if (cvSourceId === '0') {
            setTimeout(() => {
                setFilter('cvSource', '')
            }, 200)
        } else {
            setFilterType('cvSource')
            doFilter(cvSourceId);
        }
    }, [cvSourceId])

    useEffect(() => {
        if (caseStageId === '0') {
            setTimeout(() => {
                setFilter('stage', '')
            }, 200)
        } else {
            setFilterType('stage')
            doFilter(caseStageId);
        }
    }, [caseStageId])

    /******************* */

    useEffect(() => {
        if (amFilterId === '0') {
            setTimeout(() => {
                setFilter('am', '')
            }, 200)
        } else {
            setFilterType('am')
            doFilter(amFilterId);
        }
    }, [amFilterId])

    useEffect(() => {
        if (progressStatusId === '0') {
            setTimeout(() => {
                setFilter('progress', '')
            }, 200)
        } else {
            setFilterType('progress')
            doFilter(progressStatusId);
        }
    }, [progressStatusId])

    useEffect(() => {
        if (monthFilterId === '0') {
            setTimeout(() => {
                setFilter('months', '')
            }, 200)
        } else {
            setFilterType('months')
            doFilter(monthFilterId);
        }
    }, [monthFilterId])

    useEffect(() => {
        if (taskForFilterId === '0') {
            setTimeout(() => {
                setFilter('rc', '')
            }, 200)
        } else {
            setFilterType('rc')
            doFilter(taskForFilterId);
        }
    }, [taskForFilterId])

    const handlePageChange = (event, value) => {
        gotoPage(Number(value) - 1);
    };

    const [filterType, setFilterType] = useState('')

    const doFilter = React.useCallback(
        useAsyncDebounce((id) => {
            setFilter(filterType, id)
        }, 200),
        [filterType]
    );

    return (
        <React.Fragment>
            <table className="table table-bordered" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                (loading) ?
                                    [...Array(10)].map((e, i) =>
                                        <tr>
                                            <TableLoader colSpan="22" />
                                        </tr>
                                    ) :
                                    <Suspense fallback={<TableSuspence colSpan="22" />}>
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => (
                                                <td
                                                    {...cell.getCellProps([
                                                        {
                                                            className: cell.column.className, // pay attention to this
                                                            style: cell.column.style,
                                                            // set here your other custom props
                                                        },
                                                    ])}
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    </Suspense>
                            )
                        }
                    )}
                </tbody>
                <tfoot>
                    {footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()}>
                            {group.headers.map(column => (
                                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
            <div className="col-sm-12">
                <div className="dk-resPaginationCon pagination">
                    <div className="dk-resPagination">
                        <div className="dk-resLeftPag">
                            <div className="dk-texCounter" style={{ color: '#fff !imporant' }}>
                                Showing {' '}{pageIndex + 1} to {pageOptions.length} (of {rows.length} entries)
                            </div>
                        </div>
                        <div className="dk-resrightPag pagination-btns">
                            <Pagination count={pageCount} shape="rounded" onChange={handlePageChange} />
                            {/* <TablePagination count={pageCount} onChange={handlePageChange} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function debounce(func, wait, immediate) {
    var timeout;
    return (...args) => {
        var context = this;
        var later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function GlobalSearchFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isClearAll,
    restClearAllFilter }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState("")

    const onChangeSearchTitle = (e) => {
        const { value: nextValue } = e.target;
        setValue(e.target.value)
        debouncedSave(nextValue);
    };

    const debouncedSave = React.useCallback(
        debounce(nextValue => {
            setGlobalFilter(nextValue)
        }, 1000),
    );

    const clearSearch = () => {
        setValue('')
        setTimeout(() => {
            setGlobalFilter(undefined)
        }, 200)
    }

    useEffect(() => {
        if (isClearAll) {
            clearSearch()
            restClearAllFilter();
        }
    }, [isClearAll])

    return (
        <React.Fragment>
            <input
                value={value}
                className="form-control"
                onChange={onChangeSearchTitle}
                placeholder={`Search ...`}
            />
            {
                (value !== undefined && value.length > 0) ?
                    <div className="input-group-append">
                        <button type="button" onClick={clearSearch}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div> : <div className="input-group-append">
                        <button type="button">
                            <FaSearch />
                        </button>
                    </div>
            }
        </React.Fragment>
    )
}

const ReportJoined = () => {
    const dispatch = useDispatch();
    const { isLoading, joinedKpiReportList, isInserted, isUpdate, iscommentCountUpdate, isJobSpecDelete } = useSelector(state => state.joinedKpiReport)

    const [list, setList] = useState([]);
    const [data, setData] = useState([])

    const [rowId, setRowId] = useState(0)
    const [isViewModal, setIsViewModal] = useState(false)
    const [rowData, setRowData] = useState({})
    const [progress, setProgress] = useState([])
    const [dataRowId, setDataRowId] = useState(0)
    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(getKpiReportsList({}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchList();
    }, [dispatch])

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                setData(joinedKpiReportList)
            }, 200)
        }
        if (iscommentCountUpdate) {
            dispatch(clearState())
            setData(joinedKpiReportList)
        }
        if (isUpdate) {
            setIsActive(false)
            SetshowEdit(false)
        }
    }, [isLoading, isUpdate, iscommentCountUpdate, isInserted, dispatch])


    useEffect(() => {
        if (isInserted) {
            setShowAddNewRow(false)
            // setLoading(false)
            // setSuccess(true)
            dispatch(clearState())
            dispatch(showSuccess({ msg: 'Record inserted successfully' }))
        }
    }, [isInserted])

    useEffect(() => {
        if (isJobSpecDelete) {
            setLoading(false)
            setSuccess(true)
            dispatch(clearState())
            dispatch(getKpiReportsList({}))
            dispatch(showSuccess({ msg: 'Record deleted successfully' }))
        }
    }, [isJobSpecDelete])

    useEffect(() => {
        if (isUpdate) {
            setIsActive(false)
            dispatch(clearState())
            setLoading(false)
            setSuccess(false)
            dispatch(getKpiReportsList({}))
            dispatch(showSuccess({ msg: 'Record updated successfully!' }))
        }
    }, [isUpdate])

    const [checkBtnTxt, setCheckBtnTxt] = useState('Check All')
    const [countCheck, setCountCheck] = useState(0)
    const [isActive, setIsActive] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const checkActiveExtraDiv = async (isShowExitra, checkedBoxCount) => {
        if (isShowExitra) {
            setIsActive(true)
            setCountCheck(checkedBoxCount)
        } else {
            setIsActive(false)
            setCountCheck(checkedBoxCount)
        }
    }

    const checkExtraDivBtnTxt = (btnTxt) => {
        setCheckBtnTxt(btnTxt)
    }

    const handleToggle = () => {
        $('.chkCandidate').prop('checked', false);
        setIsActive(false);
    };

    const deleteSingleData = async () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
        } else {
            var canIds = [];
            $.each($(".chkCandidate:checked"), function () {
                canIds.push($(this).val());
            });
            setLoading(true)
            setSuccess(false)
            await dispatch(deleteRecords({ id: canIds }))
            $('.chkCandidate').prop('checked', false);
            setIsActive(false);
        }
    }

    const [dataMoveTo, SetDataMoveTo] = useState('')
    const [showView, SetShowView] = useState(false);
    const [showEdit, SetshowEdit] = useState(false);

    // const callViewPopup = async (data) => {
    //     await dispatch(taskDetails({ id: data }))
    //     SetShowView(true)
    // }
    const fetchSingleRecordFunction = async (e) => {
        await dispatch(detailsById({ id: dataRowId }))
        SetshowEdit(true)
    }

    const [profileCanId, setProfileCanId] = useState(0)
    const [isProfileModal, setIsProfileModal] = useState(false)

    const handleCloseProfile = () => {
        setIsProfileModal(false);
        //SetshowEdit(false)
    }

    const handleClose = () => {
        SetshowEdit(false)
    }

    const columns = useMemo(() => [
        {
            Header: 'S.No',
            accessor: 'id',
            Cell: ({ cell }) => {

                return (
                    <React.Fragment>
                        <div className="dk-smsTableCheckbox">
                            <small className="dk-checkinputIconCon">
                                {Number(cell.row.id) + 1}
                            </small>
                            <Checkbox id={cell.row.original.id} checkActiveExtraDiv={checkActiveExtraDiv} checkExtraDivBtnTxt={checkExtraDivBtnTxt} setDataRowId={setDataRowId} />
                        </div>
                    </React.Fragment>
                )
            }
        },
        {
            Header: 'Case ID',
            accessor: 'caseId',
            Cell: ({ cell }) => {
                const handleShow = (e) => {
                    console.log(e.target.dataset.canid)
                    setProfileCanId(e.target.dataset.canid)
                    setIsProfileModal(true)
                }
                return (
                    <React.Fragment>
                        <span className='dkg-kpireport-caseid' data-canid={cell.row.original.caseId} onClick={handleShow}>
                            {cell.row.original.caseId}
                        </span>
                    </React.Fragment>
                )
            }
        },
        {
            Header: 'JOINED ON	',
            accessor: 'sendoutOn',
            // Cell: ({ cell }) => {
            //     return <CustomDateFormat date={cell.row.original.createdAt} format='DD MMM YY' />
            // }
        },
        {
            Header: 'MONTHS',
            accessor: 'months',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value, img) => {
                    updateMyData(id, columnId, value, img)
                };
                return <>
                    <div className="dk-stageDropdown">
                        <Dropdown id={`months${cell.row.original.id}`}>
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                <DropdownMonths value={cell.row.original.months} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'months', 'Jan', '#f3f3f3')} >Jan</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'months', 'Feb', '#f3f3f3')} >Feb</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'months', 'Mar', '#f3f3f3')} >Mar</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'months', 'Apr', '#f3f3f3')} >Apr</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'months', 'May', '#f3f3f3')} >May</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'months', 'Jun', '#f3f3f3')} >Jun</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'months', 'Jul', '#f3f3f3')} >Jul</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'months', 'Aug', '#f3f3f3')} >Aug</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'months', 'Sep', '#f3f3f3')} >Sep</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'months', 'Oct', '#f3f3f3')} >Oct</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'months', 'Nov', '#f3f3f3')} >Nov</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'months', 'Dec', '#f3f3f3')} >Dec</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </>
            }
        },
        {
            Header: 'AM',
            id: 'amInfo',
            accessor: 'amInfo.firstName',
            Cell: ({ cell }) => {
                return (
                    <div className='dk-tdImg'>
                        {
                            <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.amInfo.profileImage} alt='Recruiter' />
                        }
                    </div>
                )
            }
        },
        {
            Header: 'RC',
            className: 'pl-3 pr-3',
            accessor: 'recInfo.firstName',
            Cell: ({ cell }) => {
                return (
                    <div className="dk-tdImg">
                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + cell.row.original.recInfo.profileImage} alt='Recruiter' />
                    </div>
                )
            }
        },
        {
            Header: 'Candidate Name',
            className: 'pl-3 pr-3',
            accessor: 'candidateName',
            Cell: ({ cell }) => {
                return <>
                    <span className="dk-clickable-link">{cell.row.original.candidateName} </span>
                </>
            }
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ cell }) => {
                return <>
                    <div className="dk-status" style={{ background: '#4768b0' }}>Joined</div>
                </>
            }
        },
        {
            Header: 'Progress',
            accessor: 'progress',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value, img) => {
                    updateMyData(id, columnId, value, img)
                };
                return <>
                    <div className="dk-stageDropdown">
                        <Dropdown id={`progress${cell.row.original.id}`}>
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                <ReportProgress value={cell.row.original.progress} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'progress', 'In Progress', '#d9714e')} style={{ backgroundColor: '#d9714e', color: '#fff' }}>In Progress</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'progress', 'Completed', '#4768b0')} style={{ backgroundColor: '#4768b0', color: '#fff' }}>Completed</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </>
            }
        },
        {
            Header: 'Stage',
            accessor: 'stage',
            Cell: ({ cell }) => {
                const onItemClick = async (id, columnId, value, img) => {
                    updateMyData(id, columnId, value, img)
                };
                return <>
                    <div className="dk-stageDropdown">
                        <Dropdown id={`stage${cell.row.original.id}`}>
                            <Dropdown.Toggle variant="" id="dropdown-basic " className='dkg-statage-dropdown-toggle-24'>
                                <StageDropdownValues value={cell.row.original.stage} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'stage', 'Documentation', '#d9714e')} style={{ backgroundColor: '#d9714e', color: '#ffffff' }}>Documentation</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'stage', 'Waiting to Relocate', '#d9714e')} style={{ backgroundColor: '#d9714e', color: '#ffffff' }}>Waiting to Relocate</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'stage', 'Relocated to Country', '#d9714e')} style={{ backgroundColor: '#d9714e', color: '#ffffff' }}>Relocated to Country</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'stage', 'Waiting for Join Date', '#d9714e')} style={{ backgroundColor: '#d9714e', color: '#ffffff' }}>Waiting for Join Date</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'stage', 'Not Reachable', '#d9714e')} style={{ backgroundColor: '#d9714e', color: '#ffffff' }}>Not Reachable</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'stage', 'Confirmed Joining', '#4768b0')} style={{ backgroundColor: '#4768b0', color: '#ffffff' }}>Confirmed Joining</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'stage', 'Invoice Sent', '#4768b0')} style={{ backgroundColor: '#4768b0', color: '#ffffff' }}>Invoice Sent</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'stage', 'Invoice Paid', '#4768b0')} style={{ backgroundColor: '#4768b0', color: '#ffffff' }}>Invoice Paid</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => onItemClick(cell.row.original.id, 'stage', 'Moved Forward', '#4768b0')} style={{ backgroundColor: '#4768b0', color: '#ffffff' }}>Moved Forward</Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </>
            }

        },
        {
            Header: 'Comment',
            accessor: 'commentCount',
            className: 'pl-2 pr-2',
            Cell: ({ cell }) => {
                return <CommentModal refTable="joined_kpi_reports" refId={cell.row.original.id} count={cell.row.original.commentCount} title="Joined Comments" />

            }
        },
        {
            Header: 'Company',
            accessor: 'company',
            Cell: ({ cell }) => {
                return <>
                    {cell.row.original.company}
                </>
            }
        },
        {
            Header: 'Language',
            accessor: 'language'

        },
        {
            Header: 'Project',
            accessor: 'project'
        },
        {
            Header: 'Role Type',
            accessor: 'roleType',

        },
        {
            Header: 'CV Source',
            accessor: 'cvSource',
        },
        {
            id: 'am',
            accessor: 'amInfo.uid',
        },
        {
            id: 'rc',
            accessor: 'recInfo.uid',
        },

    ], [])

    const [instanceData, setInstanceData] = useState('0')
    const reactTableInstance = (instance) => {
        if (instance) {
            setList(instance.flatRows)
            setInstanceData(instance)
        }
    };

    const updateMyData = async (id, columnId, value, img) => {
        switch (columnId) {
            case 'progress':
                $(`#progress${id} button .caseDiv`).html(value).css("background-color", img)
                break;

            case 'stage':
                $(`#stage${id} button .caseDiv`).html(value).css("background-color", img)
                break;
            case 'months':
                $(`#months${id} button .caseDiv`).html(value).css("background-color", img)
                break;

            default:
                break;
        }

        if (columnId === "progress") {
            dispatch(updateByKey({ id, key: columnId, value }))
        } else {
            await dispatch(updateByKey({ id, key: columnId, value }))
        }
    }

    const [isClearAll, setIsClearAll] = useState(false)

    const restClearAllFilter = async () => {
        setIsClearAll(false)
    }

    const clearAllFilter = async () => {
        await dispatch(getKpiReportsList({}))
    }

    const [amFilterId, setAmFilterId] = useState('0');
    const [taskByFilterImg, setTaskByFilterImg] = useState('0');

    const [taskForFilterId, setTaskForFilterId] = useState('0');
    const [taskForFilterImg, setTaskForFilterImg] = useState('0');

    const [sendoutOnId, setsendoutOnId] = useState('0');
    const [monthsId, setMonthsId] = useState('0');
    const [caseProgressId, setCaseProgressId] = useState('0');
    const [caseStageId, setCaseStageId] = useState('0');
    const [companyId, setCompanyId] = useState('0');
    const [projectId, setProjectId] = useState('0');
    const [roleId, setRoleId] = useState('0');
    const [languageId, setLanguageId] = useState('0');
    const [cvSourceId, setCvSourceId] = useState('0');

    const selectOtherFilter = async (e) => {
        const type = e.target.dataset.type;
        const value = e.target.dataset.value;
        let i;
        switch (type) {

            case 'months':
                i = filterTags.findIndex(function (x) { return x.type === 'months' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setMonthsId(value)
                break;

            case 'sendoutOn':
                i = filterTags.findIndex(function (x) { return x.type === 'sendoutOn' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setsendoutOnId(value)
                break;

            case 'progress':
                i = filterTags.findIndex(function (x) { return x.type === 'progress' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setCaseProgressId(value)
                break;

            case 'stage':
                i = filterTags.findIndex(function (x) { return x.type === 'stage' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setCaseStageId(value)
                break;

            case 'company':
                i = filterTags.findIndex(function (x) { return x.type === 'company' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setCompanyId(value)
                break;

            case 'project':
                i = filterTags.findIndex(function (x) { return x.type === 'project' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setProjectId(value)
                break;

            case 'roleType':
                i = filterTags.findIndex(function (x) { return x.type === 'roleType' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setRoleId(value)
                break;

            case 'language':
                i = filterTags.findIndex(function (x) { return x.type === 'language' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setLanguageId(value)
                break;

            case 'cvSource':
                i = filterTags.findIndex(function (x) { return x.type === 'cvSource' })
                if (i === -1) {
                    filterTags.push({ type, value })
                } else {
                    filterTags.splice(i, 1);
                    filterTags.push({ type, value })
                }
                setCvSourceId(value)
                break;

            default:
                i = filterTags.findIndex(function (x) { return x.type === type })
                if (i === -1) {
                    filterTags.push({ type, value })
                }
                break;
        }
    }

    const selectUserFilter = async (e) => {
        const type = e.target.dataset.type;
        switch (type) {
            case 'am':
                setAmFilterId(e.target.dataset.id)
                setTaskByFilterImg(e.target.dataset.img)
                break;
            case 'rc':
                setTaskForFilterId(e.target.dataset.id)
                setTaskForFilterImg(e.target.dataset.img)
                break;
        }
    }

    const clearUserFilter = async (e) => {
        const type = e.target.dataset.type;

        switch (type) {
            case 'am':
                setAmFilterId('0')
                setTaskByFilterImg('0')
                break;
            case 'rc':
                setTaskForFilterId('0')
                setTaskForFilterImg('0')
                break;
            case 'progress':
                setProgressStatusFilterText('PROGRESS')
                setprogressStatusId('0')
                break;

        }
    }

    const [filterTags, setFilterTags] = useState([]);
    const [taskStatusFilterText, setTaskStatusFilterText] = useState('CATEGORY')
    const [ProgressStatusFilterText, setProgressStatusFilterText] = useState('PROGRESS')
    const [paymentTypeFilterText, setPaymentTypeFilterText] = useState('PAY TYPE')
    const [caseTypeId, setCaseTypeId] = useState('0');
    const [progressStatusId, setprogressStatusId] = useState('0')
    const [casePayTypeId, setCasePayTypeId] = useState('0')

    const [monthsFilterText, setMonthsFilterText] = useState('MONTH')
    const [monthFilterId, setMonthFilterId] = useState('0')

    const onSelectMonthFilter = (eventKey) => {
        setMonthsFilterText(eventKey)
        setMonthFilterId(eventKey)
    }
    const clearMonthsFilter = async (e) => {
        const type = e.target.dataset.type;
        if (type === "months") {
            setMonthsFilterText('MONTH')
            setMonthFilterId('0')
        }
    }

    const onSelectStatusFilter = (eventKey) => {
        setProgressStatusFilterText(eventKey)
        setprogressStatusId(eventKey)
    }

    const removeTag = async (e) => {
        const type = e.target.dataset.type;
        setFilterTags(filterTags.filter((item) => item.type !== type))

        switch (type) {

            case 'progress':
                setCaseProgressId('0')
                break;

            case 'sendoutOn':
                setsendoutOnId('0')
                break;

            case 'months':
                setMonthsId('0')
                break;

            case 'company':
                setCompanyId('0')
                break;
            case 'project':
                setProjectId('0')
                break;

            case 'roleType':
                setRoleId('0')
                break;

            case 'language':
                setLanguageId('0')
                break;

            case 'cvSource':
                setCvSourceId('0')
                break;
            default:
                break;
        }
    }


    const [isShowSearch, setShowSearchModal] = useState(false)
    const showSearch = () => {
        setShowSearchModal(true)
    }

    const SearchClose = () => {
        setShowSearchModal(false)
    }

    const [isShowAddNewRow, setShowAddNewRow] = useState(false)
    const showAddNewRow = () => {
        setShowAddNewRow(true)
    }

    const SearchAddNewRow = () => {
        setShowAddNewRow(false)
    }

    const [isShowAddEdit, setShowAddEdit] = useState(false)
    const showAddEdit = () => {
        setShowAddEdit(true)
    }

    const hideAddEdit = () => {
        setShowAddEdit(false)
    }

    return (
        <>
            {
                (isShowSearch) ? <SearchSendout onHide={SearchClose} headerTitle="Search Joined" list={list} selectUserFilter={selectUserFilter} selectOtherFilter={selectOtherFilter} tableName="Joined" /> : null
            }
            {
                (isShowAddNewRow) ? <AddModal onHide={SearchAddNewRow} /> : null
            }
            {
                (isShowAddEdit) ? <AddEditModal onHide={hideAddEdit} /> : null
            }
            {
                (showEdit) ? <UpdateModal dataRowId={dataRowId} onHide={handleClose} /> : null
            }


            {
                (isProfileModal) ? <CandidateProfile canid={profileCanId} list={[]} isProfileModal={isProfileModal} handleCloseProfile={handleCloseProfile} /> : null
            }


            <div className='dk-sendoutsyears-containerMain'>

                <div className='dk-sendoutsyears-rightPanel'>
                    <div className="dk-sendoutsHead">
                        <div className="dk-sendoutsyears-yearsDropdown">

                            <div className="dk-searchBox">

                                {
                                    (instanceData !== '0') ? <GlobalSearchFilter
                                        preGlobalFilteredRows={instanceData.preGlobalFilteredRows}
                                        globalFilter={instanceData.state.globalFilter}
                                        setGlobalFilter={instanceData.setGlobalFilter}
                                        isClearAll={isClearAll}
                                        restClearAllFilter={restClearAllFilter}
                                    /> : null
                                }
                            </div>

                            <div className="dk-sendoutsAmRc-dropdown">

                                <div className='mr-2' style={{ position: "relative" }}>
                                    <AccountManage list={list} id={amFilterId} img={taskByFilterImg} selectUserFilter={selectUserFilter} />
                                    {
                                        (amFilterId !== '0') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="am" onClick={clearUserFilter}></i> : null
                                    }
                                </div>

                                <div className='taskForCrossBtn' style={{ position: "relative", zIndex: "9" }}>

                                    <Recruters list={list} id={taskForFilterId} img={taskForFilterImg} selectUserFilter={selectUserFilter} />
                                    {
                                        (taskForFilterId !== '0') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="rc" onClick={clearUserFilter}></i> : null
                                    }
                                </div>


                            </div>
                            <Link className="addMonthBtn" to="#" onClick={showAddNewRow}><i className="fas fa-plus"></i></Link>

                        </div>

                        {
                            (filterTags.length > 0) ?
                                filterTags.length > 0 && filterTags.map((item, index) =>
                                    <div className="col-md-6 pl-0 pr-0 dk-pipelineCenterCols">
                                        <div className='dk-filterTagsCon'>
                                            <ButtonGroup className='dk-filterbtnGrp mr-2' key={`tagButton${index}`}>
                                                <Button className="dk-filterbtntext">{item.value}</Button>
                                                <Button className="dk-filterbtnicon" data-type={item.type} onClick={removeTag}><i className="fas fa-times" data-type={item.type} onClick={removeTag}></i></Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                )
                                : <h3 className="title">JOINING CASES</h3>
                        }

                        <div className="dk-sendoutyears-rightPanel-btn">

                            <div className="dk-sensdouts-searchCols">
                                <button className="dk-cricleBtn" onClick={clearAllFilter}>
                                    <i className="fas fa-sync-alt"></i>
                                </button>
                            </div>

                            <div className="dk-sensdouts-searchCols">
                                <button onClick={showSearch}><FaFilter /></button>
                            </div>


                            <div className='dk-categoryfilterCon mr-2'>
                                <MonthsFilterDropdown list={list} monthsFilterText={monthsFilterText} onSelectMonthFilter={onSelectMonthFilter} tableType={'months'} />
                                {
                                    (monthsFilterText !== 'MONTH') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="months" onClick={clearMonthsFilter}></i> : null
                                }
                            </div>

                            <div className='dk-categoryfilterCon'>
                                <ProgressFilterDropdown list={list} ProgressStatusFilterText={ProgressStatusFilterText} onSelectStatusFilter={onSelectStatusFilter} tableType={'progress'} />
                                {
                                    (ProgressStatusFilterText !== 'PROGRESS') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="progress" onClick={clearUserFilter}></i> : null
                                }
                            </div>

                        </div>
                    </div>
                    <div className='dk-sendoutsyears-table'>
                        <Table
                            data={data}
                            columns={columns}
                            getInstanceCallback={reactTableInstance}
                            loading={isLoading}
                            updateMyData={updateMyData}
                            amFilterId={amFilterId}
                            progressStatusId={progressStatusId}
                            taskForFilterId={taskForFilterId}
                            monthFilterId={monthFilterId}
                            caseTypeId={caseTypeId}
                            casePayTypeId={casePayTypeId}
                            companyId={companyId}
                            projectId={projectId}
                            roleId={roleId}
                            languageId={languageId}
                            cvSourceId={cvSourceId}
                            caseStageId={caseStageId}
                            caseProgressId={caseProgressId}
                            monthsId={monthsId}
                            sendoutOnId={sendoutOnId}
                        />
                    </div>
                </div>

            </div>
            <div className={`batch-actions-menu-wrapper dkg-sendout-checkbox-bottom dk-action-popupMain ${isActive ? "activePopup" : ""}`} style={{ width: 'auto' }}>
                <div className="num-of-actions_wrapper">
                    <div className='num-of-actions'>{countCheck}</div>
                </div>
                <div className="batch-actions-title-section pl-0">
                    <div className='dk-rowSelected'>
                        <span>Row Selected</span>
                    </div>
                </div>

                <div className="batch-actions-item dk-checkAll" onClick={fetchSingleRecordFunction}>
                    <span onClick={fetchSingleRecordFunction}><i className="far fa-edit"></i></span>
                    Edit
                </div>

                <DeleteModal loading={loading} success={success} deleteItem={deleteSingleData} tableName="payment_tasks" />
                <div className="batch-actions-delete-item CloseAll d-flex align-items-center justify-content-center" onClick={handleToggle}>
                    <span><i className="fas fa-times"></i></span>
                </div>
            </div>
        </>
    )
}

export default ReportJoined;
