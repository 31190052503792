import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './clientskpi.scss';
import SubmitButton from '../../../ui/submitButton';
import { addValue, clearState } from '../../../../slice/clientKpi/clientKpiSlice';
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";

const AddSaleKpi = ({ handleClose }) => {
    const dispatch = useDispatch();
    const { isInsert, isError, errorMessage } = useSelector(state => state.clientKpi);
    const { usersList } = useSelector(state => state.users)

    const [companies, setCompany] = useState('')
    const [jobs, setNoOfJobs] = useState('0')

    const [startDate, setCreatedAt] = useState(Date.now())

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setCompany('')
        setNoOfJobs('0')
        setCreatedAt('')
    }, [])

    const addNew = async () => {
        setLoading(true)
        setSuccess(false)
        let array = startDate.split('-')
        const year = array[0];
        const month = array[1];
        await dispatch(addValue({ companies, jobs, year, month, startDate }))
    }

    useEffect(() => {
        if (isInsert) {
            setLoading(false)
            setSuccess(false)
            dispatch(showSuccess({ msg: "Added Successfully." }))
            dispatch(clearState())
            handleClose();
        }
    }, [isInsert])

    return (
        <>
            <Modal className="dk-reorderModal dk-addClientKpiModal" centered show={true} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <div className="row">
                            <div className="col-md-6 pr-2">
                                <div className="form-group">
                                    <label htmlFor="">Company</label>
                                    <label htmlFor="">
                                        <input type="text" value={companies} className="form-control" onChange={(e) => setCompany(e.target.value)} />
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-6 pl-2">
                                <div className="form-group">
                                    <label htmlFor="">Number of Jobs</label>
                                    <label htmlFor="">
                                        <input type="text" value={jobs} className="form-control" onChange={(e) => setNoOfJobs(e.target.value)} />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group mb-0">
                                    <label htmlFor="">Start Date</label>
                                    <label htmlFor="">
                                        <input type="date" value={startDate} className="form-control" onChange={(e) => setCreatedAt(e.target.value)} />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="dk-reorderBtn d-flex justify-content-center mt-3">
                            <SubmitButton txt="Save" loading={loading} success={success} onClick={addNew} position="justify-content-center" className="" />
                            <button className="cancelBtn">Cancel</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddSaleKpi;
