import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { FaUserAlt } from "react-icons/fa";
import DefaultProfile from '../../../../../../assets/images/default_profile.png'
import Deepak from '../../../../../../assets/images/deepak.png'

const AccountManager = () => {
    return (
        <>
            <Dropdown className='dkg-recuriters-dropdown-qw12'>
                <Dropdown.Toggle variant="success" className='dkg-recuriters-dropdown-toggle-qw12'>
                    <span>
                        <img src={DefaultProfile} alt="Recruiter" className='dkg-rec-img' />
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className='dkg-recuriters-dropdown-menu-qw12'>
                    <div className='d-flex'>
                        <Dropdown.Item href="#/action-1" className='dkg-recuriters-dropdown-item-qw12'>
                            <img src={Deepak} alt="Recruiter" className='dkg-rec-img' />
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-1" className='dkg-recuriters-dropdown-item-qw12'>
                            <img src={Deepak} alt="Recruiter" className='dkg-rec-img' />
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2" className='dkg-recuriters-dropdown-item-qw12'>
                            <img src={Deepak} alt="Recruiter" className='dkg-rec-img' />
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3" className='dkg-recuriters-dropdown-item-qw12' >
                            <img src={Deepak} alt="Recruiter" className='dkg-rec-img' />
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default AccountManager


