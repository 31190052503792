import React from 'react';
import './dkg-teamssumm.scss';
import TeamsLeftPanel from './teams/leftpanel/TeamsLeftPanel'

const DkGlobalTeamSummary = () => {
    return (
        <>
            <div className="dk-compyteam-pagecontainerMain">
                <TeamsLeftPanel />
                <div className="dk-dkglobalTeam-summaryMain">
                </div>
            </div>

        </>
    )
}

export default DkGlobalTeamSummary;
