import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import './summary.scss';


const RcSummary = () => {
    return (
        <>
            <div className="dk-kpiCase-containerMain dkg-traing-workmodel-pageCon-789">
                <div className="dk-rcWalletSummary-main">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="tab_1">
                        <div className="dkg-workmodel-pageCon-234">
                            <div className="dk-rcSummary-tabCon">
                                <Nav variant="" className="dk-workmodel-navpills">
                                    <Nav.Item className="dk-workmodel-navitem">
                                        <Nav.Link eventKey="tab_1" className="dk-workmodel-navlink">Overview</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dk-workmodel-navitem">
                                        <Nav.Link eventKey="tab_2" className="dk-workmodel-navlink">MODEL 1</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dk-workmodel-navitem">
                                        <Nav.Link eventKey="tab_3" className="dk-workmodel-navlink">Model 2</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="dk-workmodel-navitem">
                                        <Nav.Link eventKey="tab_4" className="dk-workmodel-navlink">Model 3</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="dk-rcSummary-tabPanel">
                                <Tab.Content className="dk-workmodel-tabCon">
                                    <Tab.Pane eventKey="tab_1" className="dk-workmodel-tabpane">
                                        <div className="dk-rcSummary-main blue">
                                            <div className='dkg-workModel-mainCon'>
                                                <div className="dkg-workModel-tableCon">
                                                    <div className="dk-workModel-overviewMain">
                                                        <div className="dkg-workmodel-cols">
                                                            <div className="title" style={{ background: '#3c96b5' }}>INHOUSE EMPLOYEES - MODEL 1
                                                            </div>
                                                            <div className="workModel-panel">

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dk-workModel-overviewMain">
                                                        <div className="dkg-workmodel-cols">
                                                            <div className="title" style={{ background: '#2e75b6' }}>RECRUITMENT PARTNER - MODEL 2
                                                            </div>
                                                            <div className="workModel-panel"></div>
                                                        </div>

                                                    </div>
                                                    <div className="dk-workModel-overviewMain">
                                                        <div className="dkg-workmodel-cols">
                                                            <div className="title" style={{ background: '#26596d' }}>FREELANCE PARTNER - MODEL 3
                                                            </div>
                                                            <div className="workModel-panel"></div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab_2" className="dk-workmodel-tabpane">
                                        <div className="dk-rcSummary-main blue">
                                            <h2 className="title">MODEL 1 - DK GLOBAL INHOUSE</h2>
                                            <div className="dk-rcSummary-flex">
                                                <table className="table table-bordetext-red">
                                                    <thead>
                                                        <tr>
                                                            <th>Department</th>
                                                            <th>Job Title</th>
                                                            <th>Job Type</th>
                                                            <th>BASIC SALARY PM</th>
                                                            <th>Performance Bonus</th>
                                                            <th>Sendout Bonus</th>
                                                            <th>Placement Bonus</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Recruitment</td>
                                                            <td>Director</td>
                                                            <td>Full Time</td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Recruitment</td>
                                                            <td>Operation Manager</td>
                                                            <td>Full Time</td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Recruitment</td>
                                                            <td>Accountant</td>
                                                            <td>Full Time</td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Recruitment</td>
                                                            <td>Business Dev</td>
                                                            <td>Full Time</td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Recruitment</td>
                                                            <td>Marketing Exe</td>
                                                            <td>Full Time</td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Recruitment</td>
                                                            <td>Resource Manager</td>
                                                            <td>Full Time</td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Recruitment</td>
                                                            <td>Database Admin</td>
                                                            <td>Full Time</td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Recruitment</td>
                                                            <td>Advertise Admin</td>
                                                            <td>Full Time</td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Recruitment</td>
                                                            <td>Recruitment Manager</td>
                                                            <td>Full Time</td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Recruitment</td>
                                                            <td>Accounts Manager</td>
                                                            <td>Full Time</td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Recruitment</td>
                                                            <td>Recruitment Consultant</td>
                                                            <td>Full Time</td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Recruitment</td>
                                                            <td>Recruitment Admin</td>
                                                            <td>Full Time</td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Recruitment</td>
                                                            <td>IT Developer</td>
                                                            <td>Full Time</td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Recruitment</td>
                                                            <td>IT Designer</td>
                                                            <td>Full Time</td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab_3" className="dk-workmodel-tabpane">
                                        <div className="dk-rcSummary-main semiblue">
                                            <h2 className="title">MODEL 2 - RECRUITMENT PARTNER</h2>
                                            <div className="dk-rcSummary-flex">
                                                <table className="table table-bordetext-red">
                                                    <thead>
                                                        <tr>
                                                            <th>Department</th>
                                                            <th>Job Title</th>
                                                            <th>Job Type</th>
                                                            <th>BASIC SALARY PM</th>
                                                            <th>Performance Bonus</th>
                                                            <th>Sendout Bonus</th>
                                                            <th>Placement Bonus</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Recruitment</td>
                                                            <td>Recruitment Consultant</td>
                                                            <td>Full Time</td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab_4" className="dk-workmodel-tabpane">
                                        <div className="dk-rcSummary-main darkblue">
                                            <h2 className="title">MODEL 3 - RECRUITMENT PARTNER</h2>
                                            <div className="dk-rcSummary-flex">
                                                <table className="table table-bordetext-red">
                                                    <thead>
                                                        <tr>
                                                            <th>Department</th>
                                                            <th>Job Title</th>
                                                            <th>Job Type</th>
                                                            <th>Basic Salary</th>
                                                            <th>Performance Bonus</th>
                                                            <th>Sendout Bonus</th>
                                                            <th>Placement Bonus</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Recruitment</td>
                                                            <td>Recruitment Consultant</td>
                                                            <td>Part Time</td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-red"><FaTimes /></span></td>
                                                            <td><span className="checkCross-icon text-green"><FaCheck /></span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
            </div>
        </>
    )
}

export default RcSummary;
