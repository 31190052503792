import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import './dkg-teams.scss';
import {
    getteamMembses,
    clearState,
} from "../../../../slice/teams/teamMembersSlice";

const DkglobalJobprofile = () => {
    const dispatch = useDispatch();
    const {
        isSuccess,
        isLoading,
        isError,
        teammemberlist,
    } = useSelector((state) => state.teammembers);

    const [data, setData] = useState([]);
    useEffect(() => {
        if (!isLoading && teammemberlist && teammemberlist.length > 0) {
            setData(teammemberlist);
        }
    }, [isLoading]);

    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(getteamMembses({}));
            } catch (err) {
                //dispatch(showError({ msg: "Somthings went wrong" }));
            }
        }
        fetchData();
    }, [dispatch]);

    return (
        <React.Fragment>
            <div className=" dkg-teams-mainpageCon">
                <div className="dkg-teams-mainbox-flex">
                    <div className="dkg-teams-cols">
                        <div className="d-flex w-100">
                            <div className="dkg-teams-box dkg-first-box ">
                                <div className="title" style={{ backgroundColor: "#fff", color: "#333" }}>
                                    RECRUITMENT TEAM - 1
                                </div>
                                <ul style={{ backgroundColor: "#89298D", color: "#fff" }}>
                                    <div className="dkg-content-con">
                                        <div className='dkg-content-title' style={{ backgroundColor: "#89298D", color: "#fff" }}>Clients List</div>
                                        <div className="dkg-teams-tableCon teams-first-cols">
                                            <table className='table table-borderded dkg-teams-table'>
                                                <tbody>
                                                    {
                                                        data && data.filter(rec => rec.title === "RECRUITMENT TEAM - 1").map((item, index) => {
                                                            return (
                                                                <tr key={index + 1} rowid={item.id}>
                                                                    <td>{item.name}</td>
                                                                    <td>
                                                                        {
                                                                            item.values
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="dkg-subcontent-con">
                                        <div className='dkg-content-subtitle' style={{ backgroundColor: "#89298D", color: "#fff" }}>Team Members</div>
                                        <div className="dkg-teams-recuiterCon teams-first-cols">
                                            <table className='table table-borderded dkg-recuiter-table'>
                                                <tbody>
                                                    {
                                                        data && data.filter(rec => rec.title === "TEAM MEMBERS-1").map((item, index) => {
                                                            return (
                                                                <tr key={index + 1} rowid={item.id}>
                                                                    <td>{item.name}</td>
                                                                    <td>
                                                                        {
                                                                            item.values
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <div className="dkg-teams-box dkg-sec-box">
                                <div className="title" style={{ backgroundColor: "#fff", color: "#333" }}>
                                    RECRUITMENT TEAM - 2
                                </div>
                                <ul style={{ backgroundColor: "#1D4851", color: "#fff" }}>
                                    <div className="dkg-content-con">
                                        <div className='dkg-content-title' style={{ backgroundColor: "#1D4851", color: "#fff" }}>Clients List</div>
                                        <div className="dkg-teams-tableCon teams-sconds-cols">
                                            <table className='table table-borderded dkg-teams-table'>
                                                <tbody>
                                                    {
                                                        data && data.filter(rec => rec.title === "RECRUITMENT TEAM - 2").map((item, index) => {
                                                            return (
                                                                <tr key={index + 1} rowid={item.id}>
                                                                    <td>{item.name}</td>
                                                                    <td>
                                                                        {
                                                                            item.values
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="dkg-subcontent-con">
                                        <div className='dkg-content-subtitle' style={{ backgroundColor: "#1D4851", color: "#fff" }}>Team Members</div>
                                        <div className="dkg-teams-recuiterCon teams-sconds-cols">
                                            <table className='table table-borderded dkg-recuiter-table'>
                                                <tbody>
                                                    {
                                                        data && data.filter(rec => rec.title === "TEAM MEMBERS-2").map((item, index) => {
                                                            return (
                                                                <tr key={index + 1} rowid={item.id}>
                                                                    <td>{item.name}</td>
                                                                    <td>
                                                                        {
                                                                            item.values
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <div className="dkg-teams-box dkg-three-box">
                                <div className="title" style={{ backgroundColor: "#fff", color: "#333" }}>
                                    RECRUITMENT TEAM - 3
                                </div>
                                <ul style={{ backgroundColor: "#01ADCF", color: "#fff" }}>
                                    <div className="dkg-content-con">
                                        <div className='dkg-content-title' style={{ backgroundColor: "#01ADCF", color: "#fff" }}>Clients List</div>
                                        <div className="dkg-teams-tableCon teams-thrid-cols">
                                            <table className='table table-borderded dkg-teams-table'>
                                                <tbody>
                                                    {
                                                        data && data.filter(rec => rec.title === "RECRUITMENT TEAM - 3").map((item, index) => {
                                                            return (
                                                                <tr key={index + 1} rowid={item.id}>
                                                                    <td>{item.name}</td>
                                                                    <td>
                                                                        {
                                                                            item.values
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="dkg-subcontent-con">
                                        <div className='dkg-content-subtitle' style={{ backgroundColor: "#01ADCF", color: "#fff" }}>Team Members</div>
                                        <div className="dkg-teams-recuiterCon teams-thrid-cols">
                                            <table className='table table-borderded dkg-recuiter-table'>
                                                <tbody>
                                                    {
                                                        data && data.filter(rec => rec.title === "TEAM MEMBERS-3").map((item, index) => {
                                                            return (
                                                                <tr key={index + 1} rowid={item.id}>
                                                                    <td>{item.name}</td>
                                                                    <td>
                                                                        {
                                                                            item.values
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment >
    )
}

export default DkglobalJobprofile;

